import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";

const initialState = {
  applicantAdmission: [],
  transactionList: [],
  studentList: [],
  isFetchStudentList: false,
  PendingList: [],
  isFetch: false,
  year: [],
  isFetchYear: false,
  studentTransactionList: [],
  isFetchStudentTransaction: false,
  transactionById: {},
  isFetchTransactionById: false,
  studentList: [],
  isFetchStudentList: false,
  transactionByAdmRegIdAndFromFeesId: [],
  isFetchTransactionByAdmRegIdAndFromFeesId: false,
};
let URL = endpoints.admissionList;
let URL1 = endpoints.receiptList;
let URL2 = endpoints.saveStudent;
let studentTransaction = endpoints.studentTransaction;
const applicantAdmissionSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    applicantAdmissionSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        applicantAdmission: data,
        isFetch: true,
      };
    },
    StudListForGovSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        studentList: data,
        isFetchStudentList: true,
      };
    },
    pendingFeesSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        PendingList: data,
        isFetch: true,
      };
    },

    transactionListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        transactionList: row,
        isFetch: true,
      };
    },

    transactionByIdListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        transactionById: row,
        isFetchTransactionById: true,
      };
    },

    transactionByAdmRegIdAndFromFeesIdListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        transactionByAdmRegIdAndFromFeesId: row,
        isFetchTransactionByAdmRegIdAndFromFeesId: true,
      };
    },

    studentTransactionListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentTransactionList: row,
        isFetchStudentTransaction: true,
      };
    },

    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        transactionById: row,
        isFetchTransactionById: true,
      };
    },

    studentTransactionListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentTransactionList: row,
        isFetchStudentTransaction: true,
      };
    },

    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        transactionById: row,
        isFetchTransactionById: true,
      };
    },

    studentTransactionListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentTransactionList: row,
        isFetchStudentTransaction: true,
      };
    },

    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentList: row,
        isFetchStudentList: true,
      };
    },

    yearSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        year: row,
        isFetchYear: true,
      };
    },
  },
});

export const {
  applicantAdmissionSuccess,
  StudListForGovSuccess,
  pendingFeesSuccess,
  transactionListSuccess,
  studentListSuccess,
  yearSuccess,
  studentTransactionListSuccess,
  transactionByIdListSuccess,
  transactionByAdmRegIdAndFromFeesIdListSuccess,
} = applicantAdmissionSlice.actions;

export default applicantAdmissionSlice.reducer;

export const getApplicantAdmission =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/admission-registration-detail-by-id",
      }).then(({ data, success }) => {
        if (success) {
          const applicant = data.data;

          let applicantData = {
            academicYearId: applicant.admissionRegistration.academicYearId,
            prn:
              applicant.admissionRegistration.getUserRegistration === null
                ? ""
                : applicant.admissionRegistration.getUserRegistration.prnNo,
            academicYearName:
              applicant.admissionRegistration.getAcademicYear.name,
            studentName:
              applicant.admissionRegistration.getUserRegistration === null
                ? ""
                : applicant.admissionRegistration.getUserRegistration.id,
            yearDetailId: applicant.yearDetailId,
            yearDetailName:
              applicant.getYearDetailId.programTitle.programType.name +
              " " +
              applicant.getYearDetailId.programTitle.brName +
              " " +
              applicant.getYearDetailId.year.className,
            scholarshipApplicable: applicant.scholarshipApplicable,
            scholarshipFormSubmit: applicant.scholarshipFormSubmit,
            scholarshipType: applicant.scholarshipType,
            admissionStatus: applicant.admissionStatus,
            admissionStatusName:
              applicant.admissionStatus == "0"
                ? "Admission Confirmed"
                : applicant.admissionStatus == "1"
                ? "Admission Cancelled"
                : applicant.admissionStatus == "2"
                ? "Admission Transfer"
                : "-",
            admissionDate: applicant.admissionDate,
            brName: applicant.getYearDetailId.programTitle.brName,
            studentEmail:
              applicant.admissionRegistration.getUserRegistration === null
                ? ""
                : applicant.admissionRegistration.getUserRegistration
                    .studentEmail,
            studentMobile:
              applicant.admissionRegistration.getUserRegistration === null
                ? ""
                : applicant.admissionRegistration.getUserRegistration
                    .studentMobile,
            studName:
              applicant.admissionRegistration.getUserRegistration === null
                ? ""
                : applicant.admissionRegistration.getUserRegistration.lastName +
                    " " +
                    applicant.admissionRegistration.getUserRegistration ===
                  null
                ? ""
                : applicant.admissionRegistration.getUserRegistration
                    .firstName +
                  " " +
                  (applicant.admissionRegistration.getUserRegistration === null
                    ? ""
                    : applicant.admissionRegistration.getUserRegistration
                        .fatherName == null ||
                      applicant.admissionRegistration.getUserRegistration
                        .fatherName == " "
                    ? " - "
                    : applicant.admissionRegistration.getUserRegistration
                        .fatherName) +
                  " " +
                  (applicant.admissionRegistration.getUserRegistration === null
                    ? ""
                    : applicant.admissionRegistration.getUserRegistration
                        .motherName == null ||
                      applicant.admissionRegistration.getUserRegistration
                        .motherName == " "
                    ? " - "
                    : applicant.admissionRegistration.getUserRegistration
                        .motherName),
            id: applicant.id,
            applicableAmt: applicant.setAdmissionFeesDetails.applicableAmt,
            receivedAmt: applicant.setAdmissionFeesDetails.receivedAmt,
            feesStructureHeaderId: applicant.feesStructureHeaderId,
            userRegistrationId:
              applicant.admissionRegistration.getUserRegistration === null
                ? ""
                : applicant.admissionRegistration.getUserRegistration.id,
            admissionRegistration: applicant.admissionRegistration.id,
            academicYearName:
              applicant.admissionRegistration.getAcademicYear.name,
            totalApplicableFees: applicant.totalApplicableFees,
            totalFeesPaid: applicant.totalFeesPaid,
            admissionDate: applicant.admissionDate,
            setAdmissionFeesDetails: applicant.setAdmissionFeesDetails.map(
              (data) => {
                let feeStructureHead = {
                  id: data.id,
                  applicableAmt: data.applicableAmt,
                  // name: data.feesHead.name,
                  receivedAmt: data.receivedAmt,
                };

                if (data.feesHead && data.feesHead.name) {
                  feeStructureHead.name = data.feesHead.name;
                } else {
                  feeStructureHead.name = "-"; 
                }

                return feeStructureHead;
              }
            ),
          };
          dispatch(applicantAdmissionSuccess({ data: applicantData }));
          return data;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// changes
export const getStudentListForGovPayment =
  ({ fromFeesId, yearDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-list-by-year-detail-and-from-fees?yearDetailId=" +
          yearDetailId +
          "&fromFeesId=" +
          fromFeesId,
      }).then(({ data, success }) => {
        if (success) {
          const applicant = data.data;
          console.log(applicant);
          let row = [];
          row = applicant.map((data, index) => {
            let applicantData = {
              index: index + 1,
              id: data.id,
              applicantName: data.studentName,
              lastName: data.lastName,
              firstName: data.firstName,
              fatherName: data.fatherName,
              motherName: data.motherName,
              yearDetailId: data.yearDetailId,
              totalFees: data.totalFees,
              totalOutstanding: data.totalOutstanding,
              totalPaid: data.totalPaid,
              yearDetailName:
                data.getYearDetail === null
                  ? ""
                  : data.getYearDetail.year.className +
                    " - " +
                    data.getYearDetail.programTitle.brName +
                    " - " +
                    data.getYearDetail.programTitle.programType.name,
              fromFeesName: data.fromFeesName,
            };
            return applicantData;
          });
          dispatch(StudListForGovSuccess({ data: row }));
          return data;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getPendingFees =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-user-pending-amount?userRegistrationId=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const applicant = data.data;

          let applicantData = {
            // id: applicant.admissionRegistration.id,
            pendingAmount: applicant.pendingAmount,
            totalApplicableFees: applicant.totalApplicableFees,
            userRegistration: applicant.userRegistration,
          };
          dispatch(pendingFeesSuccess({ data: applicantData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByAcademicYear =
  ({ academicYearId }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url: URL2 + "/by-academic-year?academicYearId=" + academicYearId,
      }).then(({ data, success }) => {
        if (success) {
          const studList = data.data;
          // let index = 0;
          let row = [];
          row = studList.map((data, index) => {
            let studListData = {
              index: index + 1,
              id: data.id,

              lastName: data.lastName,
              firstName: data.firstName,
              fatherName: data.fatherName,
              motherName: data.motherName,
              prnNo: data.prnNo,
              studContact: data.studentMobile,
              studEmail: data.studentEmail,
              yearDetail:
                data.getYearDetail !== null
                  ? data.getYearDetail.programTitle.programType.name +
                    " - " +
                    data.getYearDetail.programTitle.brName +
                    " - " +
                    data.getYearDetail.year.className
                  : "",
            };
            return studListData;
          });
          dispatch(studentListSuccess({ row }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByYearDetail =
  ({ academicYearId, yearDetail }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url:
          URL2 +
          "/by-year-detail?yearDetailId=" +
          yearDetail +
          "&academicYearId=" +
          academicYearId,
      }).then(({ data, success }) => {
        if (success) {
          const studList = data.data;
          // let index = 0;
          let row = [];
          row = studList.map((data, index) => {
            let studListData = {
              index: index + 1,
              id: data.id,
              lastName: data.lastName,
              firstName: data.firstName,
              fatherName: data.fatherName,
              motherName: data.motherName,
              prnNo: data.prnNo,
              studContact: data.studentMobile,
              studEmail: data.studentEmail,
              yearDetail:
                data.getYearDetail !== null
                  ? data.getYearDetail.programTitle.programType.name +
                    " - " +
                    data.getYearDetail.programTitle.brName +
                    " - " +
                    data.getYearDetail.year.className
                  : "",
            };
            return studListData;
          });
          dispatch(studentListSuccess({ row }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByStudName =
  ({ studName }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?studName=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url: URL2 + "/by-name?name=" + studName,
      }).then(({ data, success }) => {
        if (success) {
          const studList = data.data;
          // let index = 0;
          let row = [];
          // let index = 0;
          row = studList.map((data, index) => {
            let studListData = {
              index: index + 1,
              id: data.id,
              studentName:
                data.lastName +
                " " +
                data.firstName +
                " " +
                (data.fatherName == null || data.fatherName == " "
                  ? " - "
                  : data.fatherName) +
                " " +
                (data.motherName == null || data.motherName == " "
                  ? " - "
                  : data.motherName),
              lastName: data.lastName,
              firstName: data.firstName,
              fatherName: data.fatherName,
              motherName: data.motherName,
              prnNo: data.prnNo,
              studContact: data.studentMobile,
              studEmail: data.studentEmail,
              yearDetail:
                data.getYearDetail !== null
                  ? data.getYearDetail.programTitle.programType.name +
                    " - " +
                    data.getYearDetail.programTitle.brName +
                    " - " +
                    data.getYearDetail.year.className
                  : "",
            };
            return studListData;
          });
          dispatch(studentListSuccess({ row }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByPRN =
  ({ prnNo }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url: URL2 + "/by-prnno?prnno=" + prnNo,
      }).then(({ data, success }) => {
        if (success) {
          const studList = data.data;
          // let index = 0;
          let row = [];
          row = studList.map((data, index) => {
            let studListData = {
              index: index + 1,
              id: data.id,
              studentName:
                data.lastName +
                " " +
                data.firstName +
                " " +
                (data.fatherName == null || data.fatherName == " "
                  ? " - "
                  : data.fatherName) +
                " " +
                (data.motherName == null || data.motherName == " "
                  ? " - "
                  : data.motherName),
              lastName: data.lastName,
              firstName: data.firstName,
              fatherName: data.fatherName,
              motherName: data.motherName,
              prnNo: data.prnNo,
              studContact: data.studentMobile,
              studEmail: data.studentEmail,
              yearDetail:
                data.getYearDetail !== null
                  ? data.getYearDetail.programTitle.programType.name +
                    " - " +
                    data.getYearDetail.programTitle.brName +
                    " - " +
                    data.getYearDetail.year.className
                  : "",
            };
            return studListData;
          });
          dispatch(studentListSuccess({ row }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/user-registration/by-academic-year?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0
// http://localhost:8088/api/acdmc/v1/user-registration/by-prnno?prnno=2022097103000005
// http://localhost:8088/api/acdmc/v1/user-registration/by-name?name=vijay

// http://localhost:8088/api/admission/v1/admission-registration/get-admission-registration-detail-by-User-registration
export const getYears =
  ({ flag = false, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-admission-registration-detail-by-User-registration?userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let row = [];
          content.map((data1, index) => {
            let bData = {
              index: index + 1,
              id: flag ? data1.getYearDetailId.id : data1.id,
              name:
                data1.getYearDetailId !== null
                  ? data1.admissionRegistration.getAcademicYear.name +
                    "  " +
                    data1.getYearDetailId.programTitle.programType.name +
                    " - " +
                    data1.getYearDetailId.programTitle.brName +
                    " - " +
                    data1.getYearDetailId.year.className
                  : "",
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(yearSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentTransactionList =
  ({ yearId, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentTransaction +
          "/get-transaction-list-by-user-and-admission_registration-detail-id?admissionRegistrationDetailId=" +
          yearId +
          "&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const feesList = data.data;
          let row = [];
          row = feesList.map((data, index) => {
            let feesCollectionData = {
              index: index + 1,
              id: data.id,
              transactionNo: data.transactionNo,
              transactionDate: data.transactionDate,
              payType:
                data.payType === 0
                  ? "Cash"
                  : data.payType === 1
                  ? "DD"
                  : data.payType === 2
                  ? "Cheque"
                  : data.payType === 3
                  ? "Online"
                  : "Transfer",
              amount: data.transactionType == 0 ? data.amt : 0,
              refund: data.transactionType == 1 ? data.amt : 0,
            };
            return feesCollectionData;
          });
          dispatch(studentTransactionListSuccess({ row }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTransactionDetailById =
  ({ transactionId }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url: URL1 + "/" + transactionId,
      }).then(({ data, success }) => {
        if (success) {
          const feesList = data.data;
          // let index = 0;
          let row = [];
          // row = feesList.map((data) =>{
          //   let feesCollectionData = {
          //     index: index+1,
          //     id: data.id,
          //     transactionNo:data.transactionNo,
          //     receiptType:data.receiptType.name,
          //     transactionDate:data.transactionDate,
          //     payType:data.payType === 0 ? "Offline" : "Online",
          //     amount:data.transactionType == 0 ? data.amt : 0,
          //     refund: data.transactionType == 1 ? data.amt : 0,
          //   }
          //   return feesCollectionData;
          // })
          dispatch(transactionByIdListSuccess({ row: feesList }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTransactionDetailByAdmiRegIdAndFromFeesId =
  ({ admissionRegId, fromFeesId }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url:
          URL1 +
          "/by-user-registration-and-admission_registration-detail-and-from-fees?admissionRegistrationDetailId=" +
          admissionRegId +
          "&fromFeesId=" +
          fromFeesId,
      }).then(({ data, success }) => {
        if (success) {
          const transactionList = data.data;
          // let index = 0;
          let row = [];
          row = transactionList.map((data, index) => {
            let transactionData = {
              index: index + 1,
              id: data.id,
              transactionNo: data.transactionNo,
              receiptType:
                data.receiptType === null ? "" : data.receiptType.name,
              transactionDate: data.transactionDate,
              transactionType: data.transactionType === 0 ? "Credit" : "Debit",
              amount: data.amt,
              totalFee: data.totalFee,
              paymentType:
                data.payType === 0
                  ? "Cash"
                  : data.payType === 1
                  ? "Online"
                  : data.payType === 2
                  ? "Cheque"
                  : data.payType === 3
                  ? "DD"
                  : "Transfer",
              refund: data.transactionType == 1 ? data.amt : 0,
              userRegistrationId: data.userRegistrationId,
              academicYearId: data.academicYearId,
              admissionRegistrationDetailId: data.admissionRegistrationDetailId,
              payBank: data.payBank,
              payDate: data.payDate,
              payRefNo: data.payRefNo,
              receiptTypeId: data.receiptTypeId,
              entryDateTime: data.entryDateTime,
              financeYearId: data.financeYearId,
              isActive: data.isActive,
            };
            return transactionData;
          });
          dispatch(transactionByAdmRegIdAndFromFeesIdListSuccess({ row }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTransactionList =
  ({ academicYearId, userRegistrationId, admissionRegistrationDetailId }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url:
          URL1 +
          "/get-transaction-list-by-user-academic-year-id?academicYearId=" +
          academicYearId +
          "&userRegistrationId=" +
          userRegistrationId +
          "&admissionRegistrationDetailId=" +
          admissionRegistrationDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const feesList = data.data;
          // let index = 0;
          let row = [];
          row = feesList.map((data, index) => {
            let feesCollectionData = {
              index: index + 1,
              id: data.id,
              transactionNo: data.transactionNo,
              receiptType: data.receiptType.name,
              transactionDate: data.transactionDate,
              payType:
                data.payType === 0
                  ? "Cash"
                  : data.payType === 1
                  ? "DD"
                  : data.payType === 2
                  ? "Cheque"
                  : data.payType === 3
                  ? "Online"
                  : "Transfer",
              amount: data.transactionType == 0 ? data.amt : 0,
              refund: data.transactionType == 1 ? data.amt : 0,
            };
            return feesCollectionData;
          });
          dispatch(transactionListSuccess({ row }));
          return data;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveApplicantFee =
  ({ saveToData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL1 + "/new-save",
        postBody: saveToData,
      }).then(({ data, success }) => {
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
