import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";
const initialState = {
  committeeHandle: [],
  isFetch: false,
};
let URL = endpoints.committeeHandle;
const CommitteeHandleSlice = createSlice({
  name: "committeeHandle",
  initialState,
  reducers: {
    committeeHandleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        committeeHandle: row,
        isFetch: true,
      };
    },
  },
});

export const { committeeHandleSuccess } = CommitteeHandleSlice.actions;

export default CommitteeHandleSlice.reducer;

export const getCommitteeHandle = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            committeeName: data1.committeeName,
            description: data1.description,
            employeeActivityName:
              data1.employeeActivity != null ? data1.employeeActivity.name : "",
            fromDate: data1.fromDate,
            noParticipant: data1.noParticipant,
            level: data1.level,
            isActive: data1.isActive,
            department: data1.department !== null ? data1.department.id : "",
            employeeActivity:
              data1.employeeActivity != null ? data1.employeeActivity.id : "",
            employeeCommitteesHandleDocuments:
              data1.employeeCommitteesHandleDocuments.map((folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }),
          };
          row.push(bData);
          return data1;
        });
        dispatch(committeeHandleSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCommitteeHandleWithFilter =
  ({ activityId, listOfCommittee }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-committee-and-employee-activity?committeeName=" +
          listOfCommittee +
          "&employeeActivityId=" +
          activityId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              committeeName: data1.committeeName,
              description: data1.description,
              employeeActivityName:
                data1.employeeActivity != null
                  ? data1.employeeActivity.name
                  : "",
              fromDate: data1.fromDate,
              noParticipant: data1.noParticipant,
              level: data1.level,
              isActive: data1.isActive,
              department: data1.department !== null ? data1.department.id : "",
              employeeActivity:
                data1.employeeActivity != null ? data1.employeeActivity.id : "",
              employeeCommitteesHandleDocuments:
                data1.employeeCommitteesHandleDocuments.map((folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(committeeHandleSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCommitteeHandleByAcademicYear =
  ({ academicYearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-committees-handle-list-by-academic-year-id?academicYearId=" +
          academicYearId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              committeeName: data1.committeeName,
              committeeDesignation: data1.committeeDesignation,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              level: data1.level,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(committeeHandleSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
