import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { pink } from "@mui/material/colors";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "./StudentAttendanceTable";
import { getStudentAttendanceList } from "../StudentAttendanceForms/studentAttendance.slice";
import StudentAttendForm from "./StudentAttendForm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link as RouterLink } from "react-router-dom";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import moment from "moment";
import endpoint from "../../../config/endpoints";
import { getAlert } from "../../../CommonActions/alert.slice";
import { apiPost } from "../../../utils/api_service";
import { findLastKey } from "lodash";
import CompletedSyllabus from "./CompletedSyllabus";

function StudentAttendanceForm({
  editData,
  teachingPlanList,
  studentAttendanceList,
  showNotification,
  getAlert,
  saveFormData,
  getStudentAttendanceList,
  backToList,
}) {
  const [values, setValues] = React.useState("");
  const [attendanceDate, setAttendanceDate] = React.useState("");
  const [topic, setTopic] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [formErrors, setformErrors] = React.useState([]);
  const [syllabus, setSyllabus] = React.useState([]);
  const [valuesLength, setValuesLength] = React.useState("");
  const [saveToData, setSaveData] = React.useState(saveFormData);
  const [presentCount, setPresentCount] = React.useState("");
  const [absentCount, setAbsentCount] = React.useState("");
  const [dataToSave, setDataToSave] = React.useState("");
  const [rollNoCount, setRollNoCount] = React.useState("");
  const [present, setPresent] = React.useState("");
  const [values1Error, setValues1Error] = React.useState(false);
  const [disabledFlag, setDisabledFlag] = React.useState(false);
  const [values1, setValues1] = React.useState(
    studentAttendanceList?.studentAttendanceList
  );
  console.log(studentAttendanceList?.studentAttendanceList);
  console.log(values1);
  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name === "topic") {
      setTopic(value);
    } else {
      setRemark(value);
    }
  };
  useEffect(() => {
    if (values1.length === 0) {
      if (
        studentAttendanceList?.studentAttendanceList.length === 0 &&
        saveToData !== undefined
      ) {
        setDisabledFlag(true);
        setValues1(saveToData.studentAttendanceDetails);
        setTopic(saveToData.topicTaught);

        const AttendanceDateArr =
          saveToData.attendanceDate == null
            ? ""
            : saveToData.attendanceDate.split("-");
        const AttendanceDate1 =
          AttendanceDateArr === ""
            ? ""
            : new Date(
                AttendanceDateArr[2],
                AttendanceDateArr[1] - 1,
                AttendanceDateArr[0]
              );
        console.log(AttendanceDate1);

        setAttendanceDate(AttendanceDate1);
        setRemark(saveToData.remark);
        console.log(saveToData);
      } else {
        setValues1(studentAttendanceList?.studentAttendanceList);
      }
    } else {
      const valuesLength1 = values1.length;
      console.log(valuesLength1);
      setValuesLength(valuesLength1);

      const varData1 = values1.filter((row) => row.attendance === true);
      console.log(varData1);

      const varDataLength = varData1.length;
      console.log(varDataLength);
      setPresentCount(varDataLength);

      const result1 = valuesLength1 - varDataLength;
      console.log(result1);
      setAbsentCount(result1);

      const rollNo1 = varData1.map((data) => {
        return data.rollNo;
      });
      console.log(rollNo1.join());
      setRollNoCount(rollNo1.join());
    }
    if (syllabus.length === 0 && teachingPlanList.isFetchSyllabus) {
      setSyllabus(
        Object.keys(teachingPlanList?.syllabus).length !== 0
          ? teachingPlanList?.syllabus.details
          : []
      );
    }
  });

  const handleFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (attendanceDate === "" || attendanceDate == null) {
      formIsValid = false;
      formErrors["attendanceDateError"] = "Date is required";
    }

    if (topic.toString().trim() === "" || topic == null) {
      formIsValid = false;
      formErrors["topicError"] = "Topic taught is required";
    }
    setformErrors(formErrors);
    return formIsValid;
  };

  // const setQuaData = (data) => {
  //   console.log(data);
  //   setValues(data);

  //   const valuesLength1 = data.length;
  //   console.log(valuesLength1);
  //   setValuesLength(valuesLength1);

  //   const varData1 = data.filter((row) => row.attendance === true);
  //   console.log(varData1);

  //   const varDataLength = varData1.length;
  //   console.log(varDataLength);
  //   setPresentCount(varDataLength);

  //   const result1 = valuesLength1 - varDataLength;
  //   console.log(result1);
  //   setAbsentCount(result1);

  //   const rollNo1 = varData1.map((data) => {
  //     return data.rollNo;
  //   });
  //   console.log(rollNo1.join());
  //   setRollNoCount(rollNo1.join());
  // };

  const saveData = (data) => {
    console.log(data);
    setDataToSave(data);
  };

  const saveHandler = () => {
    if (handleFormValidation()) {
      if (values1.length !== 0) {
        setValues1Error(false);
        let attendanceDateChanged = moment(attendanceDate).format("DD-MM-YYYY");
        console.log(studentAttendanceList?.studentAttendanceList);

        swal({
          title: "Are you sure?",
          text: "Do you want to save information?",
          icon: "warning",
          buttons: ["Cancel", true],
        }).then((res) => {
          if (res) {
            const subjectList = [];
            syllabus.map((chapterData) => {
              chapterData.sectionList.map((section) => {
                section.subSectionList.map((subSection) => {
                  if (subSection.checked) {
                    const subsectionData = {
                      syllabusSubSection: {
                        id: subSection.id,
                      },
                      isActive: 1,
                    };
                    subjectList.push(subsectionData);
                  }
                });
              });
            });
            if (dataToSave.length !== 0) {
              let dataToSaveFinal = {
                employeeWorkLoad: {
                  id: dataToSave.subject,
                },
                attendanceDate: attendanceDateChanged,
                timeSlot: {
                  id: dataToSave.timeSlot,
                },
                batch: {
                  id: dataToSave.batch,
                },
                topicTaught: topic,
                presentRollNos: rollNoCount,
                remark: remark,
                presentCount: presentCount,
                absentCount: absentCount,
                isActive: 1,
                studentAttendanceDetails: values1.map((data) => {
                  let bData = {
                    student: {
                      id: data.userRegistrationId,
                    },
                    reportingDetail: {
                      id: data.reportingDetailId,
                    },
                    rollNo: data.rollNo,
                    status: data.attendance === true ? 1 : 0,
                    excuse: data.excuse === true ? 1 : 0,
                    remark: data.answer,
                  };
                  if (data.id) {
                    bData = {
                      ...bData,
                      id: data.id,
                    };
                  }
                  return bData;
                }),
                studentCompletedSubsections: subjectList,
              };
              dataToSaveFinal = {
                ...dataToSaveFinal,
                id: dataToSave.id,
              };
              console.log(dataToSaveFinal);

              apiPost({
                url: endpoint.studentAttendance,
                postBody: dataToSaveFinal,
              }).then(({ success }) => {
                if (success) {
                  showNotification({ msg: "Data saved successfully" });

                  window.location.replace("/present-student-details");
                } else {
                  getAlert({
                    message: "Failed to save",
                  });
                }
              });
            } else {
              console.log(values1);
              let dataToSaveFinal = {
                id: saveToData.id,
                employeeWorkLoad: {
                  id: saveToData.subjectId,
                },
                attendanceDate: attendanceDate,
                timeSlot: {
                  id: saveToData.timeSlotId,
                },
                batch: {
                  id: saveToData.batchId,
                },
                topicTaught: topic,
                presentRollNos: rollNoCount,
                remark: remark,
                presentCount: presentCount,
                absentCount: absentCount,
                isActive: 1,
                studentAttendanceDetails: values1.map((data) => {
                  let bData = {
                    id: data.id,
                    student: {
                      id: data.studentId,
                    },
                    reportingDetail: {
                      id: data.reportingDetailId,
                    },
                    rollNo: data.rollNo,
                    status: data.attendance === true ? 1 : 0,
                    excuse: data.excuse === true ? 1 : 0,
                    remark: data.answer,
                  };
                  return bData;
                }),
              };
              console.log(dataToSaveFinal);
              apiPost({
                url: endpoint.studentAttendance,
                postBody: dataToSaveFinal,
              }).then(({ success }) => {
                if (success) {
                  showNotification({ msg: "Data saved successfully" });

                  window.location.replace("/present-student-details");
                } else {
                  getAlert({
                    message: "Failed to save",
                  });
                }
              });
            }
          }
        });
      } else {
        setValues1Error(true);
      }
    }
  };

  const onDateChange = (dataKey) => (date) => {
    console.log(date);
    if (date !== null) {
      setAttendanceDate(date);
    }
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },

    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },

    {
      name: "prnNumber",
      title: "PRN Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },

    {
      name: "attendance",
      title: "Present",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },

    {
      name: "excuse",
      title: "Excuse",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },

    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];
  console.log("studentAttendanceList?.studentAttendanceList");
  console.log(studentAttendanceList?.studentAttendanceList);

  const changeHandler1 = (e, id) => {
    console.log(e.target.checked);
    setValues1((data) =>
      data?.map((list, index) =>
        list.userRegistrationId === id
          ? { ...list, attendance: e.target.checked, answer: "" }
          : list
      )
    );
  };

  const changeHandler2 = (e, id) => {
    console.log(e.target.checked);
    setValues1((data) =>
      data?.map((list, index) =>
        list.userRegistrationId === id
          ? { ...list, excuse: e.target.checked }
          : list
      )
    );
  };

  const changeHandler3 = (e, id) => {
    const { value } = e.target;
    setValues1((data) =>
      data?.map((list, index) =>
        list.id === id ? { ...list, answer: value } : list
      )
    );
  };

  const attendanceChangeHandler = (e) => {
    console.log(e.target.checked);
    setPresent(e.target.checked);
    console.log(studentAttendanceList?.studentAttendanceList);

    const val = values1.map((data) => {
      let bData = {
        ...data,
        attendance: e.target.checked,
        excuse: e.target.checked ? !e.target.checked : data.excuse,
        answer: e.target.checked ? "" : data.answer,
      };
      return bData;
    });
    console.log(val);
    setValues1(val);
  };

  const changeHandler = (e) => {
    console.log(e.target.checked);

    // setValues1((data) =>
    //   data?.map((list, index) => {
    //     list.excuse = e.target.checked;
    //   })
    // );
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Student Attendance"
            />
          </Grid>
          <Grid
            sx={{ mt: 1 }}
            item
            container
            justifyContent="right"
            xs={12}
            md={6}
            lg={6}
          >
            <Link
              color="primary"
              variant="body2"
              component={RouterLink}
              to="/present-student-details"
              sx={{ textDecoration: "none" }}
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back To List"
                onClick={backToList}
              />
            </Link>
          </Grid>
        </Grid>
        <br />
        <StudentAttendForm
          Onsave={saveData}
          resetvalues={setValues1}
          saveFormData={saveToData}
        />
        {values1Error && (
          <FormHelperText error>{"Select atleast 1 student"}</FormHelperText>
        )}
        {dataToSave !== "" && (
          <>
            <br />

            <TableContainer>
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">Student Name</TableCell>
                    <TableCell align="center">Roll No</TableCell>
                    <TableCell align="center">PRN Number</TableCell>
                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"Course"}
                              onChange={(e) => attendanceChangeHandler(e)}
                              checked={present}
                              sx={{
                                color: pink[800],
                                "&.Mui-checked": {
                                  color: pink[600],
                                },
                                padding: "0px",
                              }}
                            />
                          }
                          label={"Present"}
                          labelPlacement="top"
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell align="center">
                      Excuse
                      <br />
                    </TableCell>
                    <TableCell align="center">Remark</TableCell>
                  </TableRow>
                </TableHead>
                {console.log(values1)}
                <TableBody>
                  {values1.map((data) => {
                    return (
                      <TableRow sx={{}}>
                        <TableCell align="center">{data.index}</TableCell>
                        <TableCell align="left">{data.name}</TableCell>
                        <TableCell align="center">{data.rollNo}</TableCell>
                        <TableCell align="center">{data.prnNo}</TableCell>
                        <TableCell align="center">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={"attendance"}
                                  checked={data.attendance}
                                />
                              }
                              onChange={(e) =>
                                changeHandler1(e, data.userRegistrationId)
                              }
                              value={data.attendance}
                              labelPlacement="bottom"
                              disabled={data.excuse}
                            />
                          </FormGroup>
                        </TableCell>

                        <TableCell align="center">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={"excuse"}
                                  checked={data.excuse}
                                />
                              }
                              onChange={(e) =>
                                changeHandler2(e, data.userRegistrationId)
                              }
                              value={data.excuse}
                              labelPlacement="bottom"
                              disabled={data.attendance}
                            />
                          </FormGroup>
                        </TableCell>

                        <TableCell>
                          <FormGroup>
                            <TextField
                              color="primary"
                              name={"Percentage"}
                              size="small"
                              value={data.answer}
                              disabled={data.attendance}
                              onChange={(e) => changeHandler3(e, data.id)}
                              sx={{ width: 150 }}
                            />
                          </FormGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={2}
                xs={12}
                sm={4}
                md={4}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Total Student : "
                  />
                  {valuesLength === "" ? 0 : valuesLength}
                </Grid>

                <Grid item xs={12} sm={12} md={12}></Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={2}
                xs={12}
                sm={4}
                md={4}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Present Student : "
                  />
                  {presentCount === "" ? 0 : presentCount}
                </Grid>

                <Grid item xs={12} sm={12} md={12}></Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={4}
                md={4}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Absent Student : "
                  />
                  {absentCount === "" ? 0 : absentCount}
                </Grid>

                <Grid item xs={12} sm={12} md={12}></Grid>
              </Grid>
            </Grid>
            <br />
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                sx={{ marginBottom: 2 }}
                xs={12}
                sm={12}
                md={12}
              >
                <CompletedSyllabus
                  changeSyllabus={setSyllabus}
                  syllabus={syllabus}
                  editSyllabus={saveToData}
                />
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Topic Taught"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="topic"
                    size="small"
                    value={topic}
                    multiline
                    fullWidth
                    onChange={changedHandler}
                    helperText={
                      formErrors.topicError ? formErrors.topicError : " "
                    }
                    error={formErrors.topicError ? true : false}
                    disabled={disabledFlag}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Remark"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="remark"
                      size="small"
                      value={remark}
                      fullWidth
                      multiline
                      onChange={changedHandler}
                      helperText={" "}
                      disabled={disabledFlag}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={2}
                xs={12}
                sm={12}
                md={12}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={2}
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Attendance Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="attendanceDate"
                      size="small"
                      value={attendanceDate}
                      errorText={
                        formErrors.attendanceDateError
                          ? formErrors.attendanceDateError
                          : " "
                      }
                      isError={formErrors.attendanceDateError ? true : false}
                      fullWidth
                      onChange={onDateChange("attendanceDate")}
                      disabled={disabledFlag}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={2}
                  xs={12}
                  sm={6}
                  md={6}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Save"
                    onClick={saveHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
  teachingPlanList: state.teachingPlan,
});
const mapDispatchToProps = {
  showNotification,
  getStudentAttendanceList,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAttendanceForm);
