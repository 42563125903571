import { FormHelperText, Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { FilterSave } from "../../../components/FilterSave";
import endpoints from "../../../config/endpoints";
import { UnitTestDeclareTableTeacherSideJson } from "../../../DynamicFormsJson/UnitTestDeclareTeacherSide";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../utils/api_service";
import {
  getStudentListByBatchIdOrderBy,
  resetTableListValues,
} from "../../Batches/BatchList/batchlist.slice";
import { getDistinctDivision } from "../../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getSubjectInfoBySemisterDetailId,
  getSubjectInfoByYearDetailId,
  getSubjectSchemeBySubjectId,
} from "../../Masters/Admission/Subject Info/subjectInfo.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getBatch,
  getDivision,
  getSubject,
} from "../../StudentAttendance/StudentAttendanceForms/studentAttendance.slice";
import {
  getAttendanceYearDetails,
  getDivisionForAttendance,
  getSemesterDetails,
  getSubjectByDivisionAttendance,
} from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import {
  getUnitTest,
  getUnitTestDetailsTeacherBySemDivAndSub,
  resetValues,
  UnitTestTeacherFillMarks,
  resetValuesById,
} from "../UnitTestMaster/unitTestMaster.slice";
import TeacherUnitTestFillMarks from "./TeacherUnitTestFillMarks";
import TeacherUnitTestView from "./TeacherUnitTestView";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};
class UnitTestDeclare extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "unitTestHead",
      title: "Unit Test",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      name: "maxMarks",
      title: "Marks",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "date",
      title: "Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "declareBy",
      title: "Declare By",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  StudentCol = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },

    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
  ];
  StudentViewCol = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },

    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    {
      name: "marks",
      title: "Marks",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.employeeWorkList?.attendanceYearDetails,
        semisterMaster: this.props.employeeWorkList?.attendanceSemesterDetails,
        semisterTableMaster:
          this.props.employeeWorkList?.attendanceSemesterDetails,
        divisionMaster: this.props.studentAttendanceList?.division,
        divisionMasterTable: this.props.employeeWorkList?.attendanceDivision,
        subjectMasterTable: this.props.employeeWorkList?.attendanceSubjects,
        unitTestMaster: this.props.unitTestList?.unitTest,
        subjectMaster: this.props.studentAttendanceList?.subject,
        batchMaster: this.props.studentAttendanceList?.batch,
      },
      showLoader: false,
      open: false,
      openView: false,
      openFillMarks: false,
      timeTableHeaderData: {},
      fieldData: {},
      yearDetail: "",
      semesterDetail: "",
      division: "",
      subject: "",
      subjectScheme: "",
      maxMarks: "",
      date: "",
      unitTest: "",
      batchId: "",
      studListEmpty: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceYearDetails !==
        nextProps.employeeWorkList?.attendanceYearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.employeeWorkList?.attendanceYearDetails,
          },
        });
      }
      if (
        this.props.employeeWorkList?.attendanceSemesterDetails !==
        nextProps.employeeWorkList?.attendanceSemesterDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster:
              nextProps.employeeWorkList?.attendanceSemesterDetails,
            semisterTableMaster:
              nextProps.employeeWorkList?.attendanceSemesterDetails,
          },
        });
      }
      if (
        this.props.employeeWorkList?.attendanceSubjects !==
        nextProps.employeeWorkList?.attendanceSubjects
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMasterTable: nextProps.employeeWorkList?.attendanceSubjects,
          },
        });
      }
      if (
        this.props.employeeWorkList?.attendanceDivision !==
        nextProps.employeeWorkList?.attendanceDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMasterTable: nextProps.employeeWorkList?.attendanceDivision,
          },
        });
      }
    }

    if (this.props.subjectsList !== nextProps.subjectsList) {
      if (
        this.props.subjectsList.subjectById !==
        nextProps.subjectsList.subjectById
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectSchemeMaster:
              nextProps.subjectsList.subjectById.subjectDetails,
          },
        });
      }
    }

    if (this.props.unitTestList !== nextProps.unitTestList) {
      if (
        this.props.unitTestList.unitTest !== nextProps.unitTestList.unitTest
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            unitTestMaster: nextProps.unitTestList.unitTest.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }

    if (this.props.studentAttendanceList !== nextProps.studentAttendanceList) {
      if (
        this.props.studentAttendanceList?.batch !==
        nextProps.studentAttendanceList?.batch
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchMaster: nextProps.studentAttendanceList?.batch,
          },
        });
      }
      if (
        this.props.studentAttendanceList?.subject !==
        nextProps.studentAttendanceList?.subject
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.studentAttendanceList?.subject,
          },
        });
      }
      if (
        this.props.studentAttendanceList?.division !==
        nextProps.studentAttendanceList?.division
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster: nextProps.studentAttendanceList?.division,
          },
        });
      }
      if (
        this.props.studentAttendanceList?.subjectByDivision !==
        nextProps.studentAttendanceList?.subjectByDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.studentAttendanceList?.subjectByDivision,
          },
        });
      }
    }
  }

  componentDidMount() {
    this.props.resetValues();

    const ay = localStorage.getItem("acadamicYearId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    if (ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getAttendanceYearDetails({ academicYear: ay })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    this.props.getUnitTest().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: yearDetailId,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: semisterDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId && divisionId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: semisterDetailId,
          division: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props.getDivision({ id: data.semister }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
    if (data.division && data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: data.semister,
          division: data.division,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (data.division && data.semister && data.batch) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSubjectByDivisionAttendance({
          semister: data.semister,
          division: data.division,
          batchId: data.batch,
          subjectDetailId: true,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.yearDetail && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: data.yearDetail,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (data.semesterDetail) {
      this.setState({
        showLoader: true,
      });
      this.props.getDivision({ id: data.semesterDetail }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
    if (data.divisionId && data.semesterDetail && data.batchId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSubject({
          semister: data.semesterDetail,
          division: data.divisionId,
          batch: data.batchId,
          subjectDetailId: true,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.divisionId && data.semesterDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: data.semesterDetail,
          division: data.divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.resetValues();
  };

  toggleFormTableVisibility = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      openSnackbar: true,
      showLoader: false,
    });
  };
  condition = [
    {
      title: "declareType",
      value: 0,
      iconHide: "showHeadDelete",
    },
  ];
  onSearchData = (dataToSearch) => {
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", dataToSearch.division);
    localStorage.setItem("divisionIds", "");
    localStorage.setItem("acadamicYearId", ay);
    FilterSave();
    this.setState({
      showLoader: true,
      batchId: dataToSearch.batch,
    });

    this.props
      .getUnitTestDetailsTeacherBySemDivAndSub({
        semesterId: dataToSearch.semister,
        divisionId: dataToSearch.division,
        subjectId: dataToSearch.subject,
        batchId: dataToSearch.batch,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    this.props.resetTableListValues();
    this.props.getUnitTest().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    this.setState({
      open: true,
      fieldData: fieldData,
      studListEmpty: false,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onDelete = (rowData) => {
    this.setState({
      isLoading: true,
    });
    console.log(rowData);
    apiDelete({
      url: UnitTestDeclareTableTeacherSideJson.apiBaseURL + "/" + rowData.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: true,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props
          .getUnitTestDetailsTeacherBySemDivAndSub({
            semesterId: rowData.semister,
            divisionId: rowData.division,
            subjectId: rowData.subjectDetailId,
            batchId: this.state.batchId,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  changeStatus = (row) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const tableHeader = {
          id: row.id,
          semisterDetail: {
            id: row.semisterDetail,
          },
          division: {
            id: row.division,
          },
          fromDate: row.fromDate,
          toDate: row.toDate,
          remark: row.remark,
          isActive: row.isActive === 1 ? 0 : 1,
        };
        this.setState({
          showLoader: true,
        });
        apiPost({
          url: endpoints.timeTableHeader,
          postBody: tableHeader,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  onSearchStudentList = (dataToSearch) => {
    this.setState({
      showLoader: true,
      yearDetail: dataToSearch.yearDetailId,
      semesterDetail: dataToSearch.semesterDetail,
      division: dataToSearch.divisionId,
      subject: dataToSearch.subject,
      subjectScheme: dataToSearch.subjectScheme,
      unitTest: dataToSearch.unitTest,
      maxMarks: dataToSearch.maxMarks,
      date: dataToSearch.date,
    });
    this.props
      .getStudentListByBatchIdOrderBy({
        batchId: dataToSearch.batchId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };
  goToForm = () => {
    this.setState({
      open: false,
      openView: false,
      openFillMarks: false,
      studListEmpty: false,
    });
  };
  onClickView = (rowData) => {
    this.setState({
      open: false,
      openView: true,
      openFillMarks: false,
      studListEmpty: false,
    });
    this.setState({
      timeTableHeaderData: rowData,
    });
  };
  onClickFillMarks = (rowData) => {
    this.props.resetValuesById();
    this.props
      .UnitTestTeacherFillMarks({
        id: rowData.id,
        batchId: this.state.batchId,
      })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    this.setState({
      open: false,
      openView: false,
      openFillMarks: true,
      studListEmpty: false,
    });
    this.setState({
      timeTableHeaderData: rowData,
    });
  };
  FillMarks = (data, data1) => {
    const divisionId = localStorage.getItem("divisionId");
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    let studentUnitTestDetails = [];
    const dataToPush = data.map((rowData) => {
      let studData = {
        id: rowData.id,
        userRegistration: {
          id: rowData.userRegistration.id,
        },
        reportingDetail: {
          id: rowData.reportingDetail.id,
        },
        marks: rowData.marks,
        fillDateTime: "",
      };
      studentUnitTestDetails.push(studData);
    });
    let docs = {
      date: data1.date,
      id: data1.id,
      academicYearId: data1.academicYearId,
      declareBy: {
        id: data1.declareBy.id,
      },
      division: {
        id: data1.division.id,
      },
      isActive: data1.isActive,
      maxMarks: data1.maxMarks,
      semisterDetail: {
        id: data1.semisterDetail.id,
      },
      subjectDetail: {
        id: data1.subjectDetail.id,
      },
      unitTestHead: {
        id: data1.unitTestHead.id,
      },
      declareType: data1.declareType,
      declareStatus: data1.declareStatus,
      departmentId: "",
      studentUnitTestDetails: studentUnitTestDetails,
    };
    apiPost({
      url: endpoints.unitTestDeclare + "/fill-marks-for-admin",
      postBody: docs,
    }).then(({ success }) => {
      if (success) {
        showNotification({ msg: "Data saved successfully" });
        this.handleClickCloseFillMarks();
      }
    });
  };
  handleClickOpen = () => {
    // this.props.resetValues();
    this.setState({
      open: false,
      openView: false,
      openFillMarks: false,
      studListEmpty: false,
    });
  };

  handleClickCloseFillMarks = () => {
    this.props.resetValues();
    this.setState({
      open: false,
      openView: false,
      openFillMarks: false,
      studListEmpty: false,
    });
  };
  submitHandler = (e) => {
    e.preventDefault();
    const {
      yearDetail,
      division,
      semesterDetail,
      unitTest,
      date,
      subject,
      maxMarks,
      subjectScheme,
    } = this.state;
    let studentUnitTestDetails = [];
    this.props.batchList?.studentDataOrderBy.map((rowData) => {
      let studData = {
        userRegistration: {
          id: rowData.userRegistrationId,
        },
        reportingDetail: {
          id: rowData.id,
        },
        marks: "",
        fillDateTime: "",
      };
      studentUnitTestDetails.push(studData);
    });
    console.log(studentUnitTestDetails);
    let dArr = date.split("-");
    let dateChanged = dArr[2] + "-" + dArr[1] + "-" + dArr[0];
    if (studentUnitTestDetails.length !== 0) {
      let docs = {
        date: dateChanged,
        division: {
          id: division,
        },
        isActive: 1,
        maxMarks: maxMarks,
        semisterDetail: {
          id: semesterDetail,
        },
        subjectDetail: {
          id: subject,
        },
        unitTestHead: {
          id: unitTest,
        },
        declareType: 1,
        declareStatus: 0,
        departmentId: "",
        studentUnitTestDetails: studentUnitTestDetails,
      };
      swal({
        title: "Are you sure?",
        text: "Would you like to continue with your action?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((success) => {
        if (success) {
          this.onSave(docs);
        }
      });
    } else {
      this.setState({
        studListEmpty: true,
      });
    }
  };
  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      showLoader: true,
    });
    apiPost({
      url: UnitTestDeclareTableTeacherSideJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };
  render() {
    const { unitTestList } = this.props;
    const {
      dynamicMasterData,
      open,
      openView,
      showLoader,
      fieldData,
      openFillMarks,
      studListEmpty,
    } = this.state;
    console.log(this.props.studentAttendanceList?.division);
    return (
      <>
        {!open && !openView && !openFillMarks && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={UnitTestDeclareTableTeacherSideJson.screenTitle}
            fieldMeta={UnitTestDeclareTableTeacherSideJson.fieldMetaForTable}
            buttonCenter={false}
            showPdfDownload={
              UnitTestDeclareTableTeacherSideJson.showPdfDownload
            }
            showExcelDownload={
              UnitTestDeclareTableTeacherSideJson.showExcelDownload
            }
            pdfFileName={UnitTestDeclareTableTeacherSideJson.pdfFileName}
            excelFileName={UnitTestDeclareTableTeacherSideJson.excelFileName}
            tableColumnsToFilter={
              UnitTestDeclareTableTeacherSideJson.tableColumnsToFilter
            }
            showAddButton={UnitTestDeclareTableTeacherSideJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={this.columns}
            tableData={unitTestList?.unitTestListTeacher}
            showLoader={showLoader}
            getListById={this.getListById}
            showPegination={false}
            showView={true}
            showFillMarks={true}
            condition={this.condition}
            showHeadDelete={true}
            rowViewData={this.onClickView}
            rowDelete={this.rowDelete}
            rowFillMarks={this.onClickFillMarks}
            rowStatus={this.changeStatus}
            onDelete={this.onDelete}
            onSearchData={this.onSearchData}
            baseIdColumn={UnitTestDeclareTableTeacherSideJson.baseIdColumn}
            apiBaseURL={UnitTestDeclareTableTeacherSideJson.apiBaseURL}
          />
        )}
        {open && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={UnitTestDeclareTableTeacherSideJson.screenTitle}
              fieldMeta={UnitTestDeclareTableTeacherSideJson.fieldMeta}
              buttonCenter={UnitTestDeclareTableTeacherSideJson.buttonCenter}
              showPdfDownload={
                UnitTestDeclareTableTeacherSideJson.showPdfDownload
              }
              showExcelDownload={
                UnitTestDeclareTableTeacherSideJson.showExcelDownload
              }
              pdfFileName={UnitTestDeclareTableTeacherSideJson.pdfFileName}
              excelFileName={UnitTestDeclareTableTeacherSideJson.excelFileName}
              tableColumnsToFilter={
                UnitTestDeclareTableTeacherSideJson.tableColumnsToFilter
              }
              showAddButton={false}
              showSearch={false}
              dynamicMasterData={dynamicMasterData}
              tableColumns={this.StudentCol}
              tableData={this.props.batchList?.studentDataOrderBy}
              showLoader={showLoader}
              getListById={this.getListById}
              showPegination={false}
              showView={false}
              showHeadEdit={false}
              showHeadDelete={true}
              rowViewData={this.onClickView}
              rowDelete={this.rowDelete}
              rowStatus={this.changeStatus}
              onDelete={this.onDelete}
              isActionColActive={false}
              onSearchData={this.onSearchStudentList}
              baseIdColumn={UnitTestDeclareTableTeacherSideJson.baseIdColumn}
              apiBaseURL={UnitTestDeclareTableTeacherSideJson.apiBaseURL}
              studListEmpty={studListEmpty}
              msg={"*Student List Empty"}
            />
            {/* {studListEmpty === true && (
                <Grid sm={12} md={12} xs={12} sx={{ ml: 4, mt: -3 }}>
                  <FormHelperText error>{"*Student List Empty"}</FormHelperText>
                </Grid>
              )} */}
            <Paper sx={{ p: 2, borderRadius: 2, mt: -2 }} elevation={0}>
              <Grid sm={12} md={12} xs={12} container justifyContent="right">
                <Grid sm={1} md={1} xs={12} container justifyContent="right">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Submit"
                    onClick={this.submitHandler}
                  />
                </Grid>
                <Grid sm={1} md={1} xs={12} container justifyContent="right">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={this.goToForm}
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {openView && (
          <TeacherUnitTestView
            columns={this.StudentViewCol}
            onCancle={this.handleClickOpen}
            timeTableHeaderData={this.state.timeTableHeaderData}
          />
        )}
        {openFillMarks && (
          <Paper sx={{ p: 2, borderRadius: 2, mt: -2 }} elevation={0}>
            <TeacherUnitTestFillMarks
              fillMarksData={this.FillMarks}
              columns={this.StudentViewCol}
              onCancle={this.handleClickCloseFillMarks}
              timeTableHeaderData={this.state.timeTableHeaderData}
              batchId={this.state.batchId}
              studentList={this.props.unitTestList?.unitTestTeacherFillMarks}
            />
          </Paper>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  subjectsList: state.subjectInfo,
  unitTestList: state.unitTestStore,
  studentAttendanceList: state.studentAttendance,
  batchList: state.batch,
  employeeWorkList: state.employeeWorkload,
});
const mapDispatchToProps = {
  getAlert,
  getDistinctDivision,
  getDivision,
  getAcademicYear,
  getSubjectInfoByYearDetailId,
  getUnitTestDetailsTeacherBySemDivAndSub,
  getUnitTest,
  showNotification,
  getSubjectInfoBySemisterDetailId,
  resetValues,
  getBatch,
  getStudentListByBatchIdOrderBy,
  getSubject,
  getSubjectSchemeBySubjectId,
  UnitTestTeacherFillMarks,
  getAttendanceYearDetails,
  getSemesterDetails,
  getDivisionForAttendance,
  getSubjectByDivisionAttendance,
  resetTableListValues,
  resetValuesById,
};
export default connect(mapStateToProps, mapDispatchToProps)(UnitTestDeclare);
