import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Link, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiDelete, apiPost } from "../../../utils/api_service";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getTimeSlotByProgramType } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getTimeTable,
  getTimeTableDetailByHeaderId,
} from "../GenerateTimeTable/generateTimeTable.slice";
import GenerateTimeTableForm from "../GenerateTimeTable/index";
import ViewOfTimeTable from "./ViewOfTimeTable";

class RollList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      division: "",
      yearDetail: "",
      semisterDetails: "",
      academicYear: "",
      open: false,
      openView: false,
      formErrors: {},
      timeTableHeaderData: {},
    };
  }

  rowEdit = (params) => {
    console.log(params);

    this.props
      .getTimeTableDetailByHeaderId({ timeTableHeaderId: params.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            timeTableHeaderData: params,
          });
        }
      });
  };
  columnExtensions = [
    { columnName: "index", width: 100 },
    { columnName: "action", width: 130 },
    { columnName: "isActive", width: 90 },
    { columnName: "fDTd", width: 200 },
  ];

  columns = [
    {
      name: "index",
      title: "Sr.no ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "fDTd",
      title: "From Date - To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "remark",
      title: "Remark",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "isActive",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  onClickEdit = (row) => {
    this.setState({
      open: true,
    });
    this.rowEdit(row);
  };
  onClickView = (row) => {
    this.setState({
      openView: true,
    });
    getTimeSlotByProgramType({ programTypeId: row.programType }).then(
      (response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      }
    );
    console.log(row.programType);
    this.rowEdit(row);
  };
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoints.timeTableHeader + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getTimeTable({
                divisionId: this.state.division,
                semisterId: this.state.semisterDetails,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  changeStatus = (row) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const tableHeader = {
          id: row.id,
          semisterDetail: {
            id: row.semisterDetail,
          },
          division: {
            id: row.division,
          },
          fromDate: row.fromDate,
          toDate: row.toDate,
          remark: row.remark,
          isActive: row.isActive === 1 ? 0 : 1,
        };
        console.log(tableHeader);
        apiPost({
          url: endpoints.timeTableHeader,
          postBody: tableHeader,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getTimeTable({
                divisionId: this.state.division,
                semisterId: this.state.semisterDetails,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  handleClickOpen = () => {
    window.location.replace("/view-time-table");
  };

  componentDidMount() {
    const { resetPage, getYearDetails, getAcademicYear, getAlert } = this.props;
    resetPage();

    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    getAcademicYear().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  handleFormValidation() {
    const { division, yearDetail, semisterDetails, academicYear } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (academicYear.toString().trim() === "" || academicYear === null) {
      formIsValid = false;
      formErrors["academicYearError"] = myConstClass.academicYearMessage;
    }

    if (semisterDetails.toString().trim() === "" || semisterDetails === null) {
      formIsValid = false;
      formErrors["semisterDetailsError"] = myConstClass.semisterNameMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { division, yearDetail, semisterDetails } = this.state;
    const { getAlert, getTimeTable } = this.props;
    if (this.handleFormValidation()) {
      getTimeTable({
        divisionId: division,
        semisterId: semisterDetails,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    const { yearDetail, academicYear } = this.state;
    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name == "yearDetail") {
        this.setState({
          division: "",
        });
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });

        if (academicYear !== "") {
          this.setState({
            semisterDetail: "",
          });
          getSemesterDetailsByYearAndAcademic({
            yearId: newValue,
            academicId: yearDetail,
          }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      }
      if (name == "academicYear" && yearDetail !== "") {
        this.setState({
          semisterDetail: "",
        });
        getSemesterDetailsByYearAndAcademic({
          yearId: yearDetail,
          academicId: newValue,
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };
  render() {
    const {
      division,
      yearDetail,
      semisterDetails,
      open,
      openView,
      academicYear,
    } = this.state;
    const {
      divisionError,
      yearDetailError,
      semisterDetailsError,
      academicYearError,
    } = this.state.formErrors;
    const {
      internshipDeclarationList,
      academicYearList,
      rollNoData,
      timeTableList,
    } = this.props;
    return (
      <>
        {!open && !openView && (
          <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
            <Grid sx={{ mb: 4 }} item xs={12} sm={12} md={12} rowSpacing={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Viewing Time Table List"
              />
            </Grid>
            <Grid item xs={2} md={12} container justifyContent="flex-end">
              <Link
                color="primary"
                variant="body2"
                component={RouterLink}
                to="/genrate-time-table"
              >
                <MyComponentWithIconProps
                  statusImage={AddCircleIcon}
                  color="primary"
                  fontSize="large"
                  title="Generate time table"
                />
              </Link>
            </Grid>
            <form
              onSubmit={this.submitHandler}
              autoComplete="off"
              noValidate={true}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={1}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Year Detail"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={internshipDeclarationList?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Academic Year"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={academicYear}
                      name={"academicYear"}
                      options={academicYearList?.academicYear}
                      onChange={this.ChangeHandlerSearch}
                      isError={academicYearError ? true : false}
                      errorText={academicYearError ? academicYearError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Semister Details"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={semisterDetails}
                      name={"semisterDetails"}
                      options={rollNoData?.semisterDetailsList}
                      onChange={this.ChangeHandlerSearch}
                      isError={semisterDetailsError ? true : false}
                      errorText={
                        semisterDetailsError ? semisterDetailsError : " "
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Division"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={division}
                      name={"division"}
                      options={internshipDeclarationList?.distinctDivision}
                      onChange={this.ChangeHandlerSearch}
                      isError={divisionError ? true : false}
                      errorText={divisionError ? divisionError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  m={1}
                  rowSpacing={1}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Search"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>

            <Grid item xs={12} sm={12} md={12}>
              <DynamicTable
                data={timeTableList?.timeTableList}
                tableHead={this.columns}
                showView={true}
                showHeadEdit={true}
                showHeadDelete={true}
                showPegination={false}
                rowViewData={this.onClickView}
                rowDelete={this.rowDelete}
                rowEdit={this.onClickEdit}
                rowStatus={this.changeStatus}
                isActionColActive={true}
              />
            </Grid>
          </Paper>
        )}
        {open && (
          <GenerateTimeTableForm
            onCancle={this.handleClickOpen}
            timeTableHeaderData={this.state.timeTableHeaderData}
          />
        )}
        {openView && (
          <ViewOfTimeTable
            onCancle={this.handleClickOpen}
            timeTableHeaderData={this.state.timeTableHeaderData}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  getSemesterDetailsByYearAndAcademic,
  getTimeTable,
  getTimeSlotByProgramType,
  getTimeTableDetailByHeaderId,
};
export default connect(mapStateToProps, mapDispatchToProps)(RollList);
