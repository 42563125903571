import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import { apiGet } from "../../../../utils/api_service";


const initialState = {
  feeType: [],
  isFetchFeeType: false,
};
let URL = endpoints.feeType;
const feeTypeSlice = createSlice({
  name: "feeType",
  initialState,
  reducers: {
    feeTypeListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload
      return {
        ...state,
        feeType: row,
        isFetchFeeType: true
      };
    },
    feeTypeListFail: (state = cloneDeep(initialState), action) => {

      return {
        ...state,
        feeType: [],
        isFetchFeeType: false,
      };
    },

  },
});

export const { feeTypeListSuccess, feeTypeListFail } = feeTypeSlice.actions;

export default feeTypeSlice.reducer;


export const getFeeType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}'
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,

          };
          row.push(bData);
          return data1;
        });
        dispatch(feeTypeListSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {

    return console.error(e.message);
  }
};


