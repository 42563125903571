import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as myConstClass from "../../../../../config/messageconstant";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../../components/Comman/AutoComplete";
import { LabelCompo } from "../../../../../components/Comman/Label";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../../../components/dynamicscreens/DynamicForm";
import { ComplaintAdminJson } from "../../../../../DynamicFormsJson/ComplaintAdmin";
import { resetReducList } from "../../../../../CommonActions/User.action";
import { GrievanceOrComplaintAdminColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiPost } from "../../../../../utils/api_service";
import { showNotification } from "../../../../Landing/Landing.slice";
import {
  getGrievanceAdminByFilter,
  getGrievanceAdminFilter,
} from "./GrievanceOrComplaint.slice";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { getActivityByCourseAttended } from "../Activity/activity.slice";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import moment from "moment";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Loading1 } from "../../../../../components/Loading1";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class ComplaintAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      formErrors: {},
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      rowData: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      type: "",
      showLoader: false,
      dynamicMasterData: {
        status: [
          { id: "0", name: "Pending" },
          { id: "1", name: "In Process" },
          { id: "2", name: "Completed" },
          { id: "3", name: "Rejected" },
        ],
      },
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    console.log(rowData);

    this.toggleFormTableVisibility();
    let fieldData = {};
    ComplaintAdminJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
      rowData: rowData,
    });
  };

  componentDidMount() {
    this.props.resetReducList();

    this.setState({ showLoader: true });
    this.props.getGrievanceAdminFilter().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode, date, rowData } = this.state;
    console.log(fieldData);
    console.log(rowData);

    const dataToSave = {
      type: 2,
      userRegistration: {
        id: rowData.userRegistration,
      },
      date: rowData.complaintDate,
      // contactNo: rowData.contactNo,
      // emailId: rowData.emailId,
      // id: rowData.id,
      // problemLocation: rowData.problemLocation,
      // status: rowData.status,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    ComplaintAdminJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    swal({
      title: "Are you sure?",
      text: "Once Submitted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((response) => {
      if (response) {
        this.onSave(dataToSave, isToggle);
      }
    });
  };

  onSave = (dataToSave, isToggle = true) => {
    console.log(this.state.fromDate);
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: ComplaintAdminJson.apiBaseURL + "/update",
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.props.resetReducList();
        this.setState({
          fromDate: "",
          toDate: "",
        });

        this.props.getGrievanceAdminFilter().then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });

        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: ComplaintAdminJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const { fromDate, toDate } = this.state;
        let fromDt = moment(fromDate).format("YYYY-MM-DD");
        let toDt = moment(toDate).format("YYYY-MM-DD");
        // this.props
        //   .getGrievanceAdminByFilter( {
        //     fromDate: fromDt,
        //     toDate: toDt,
        //   } )
        //   .then( ( response ) =>
        //   {
        //     if ( !response )
        //     {
        //       getAlert( { message: "Something went wrong" } );
        //     }
        //   } );

        this.props.getGrievanceAdminFilter().then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });

        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (fromDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
    const { fromDate, toDate } = this.state;
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    if (this.handleFormValidation()) {
      this.props
        .getGrievanceAdminByFilter({
          fromDate: fromDt,
          toDate: toDt,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  getFilteredTableData = () => {
    const { greivanceList } = this.props;
    const { searchValue } = this.state;
    const columsFil = GrievanceOrComplaintAdminColumns.filter((item) => {
      return item.canSearch;
    });
    return greivanceList?.grievanceAdmin.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  generatePDF = () => {
    const { excelFileName = "Complaint Admin PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      GrievanceOrComplaintAdminColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    GrievanceOrComplaintAdminColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  generateExcel = () => {
    const { excelFileName = "Complaint Admin Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      GrievanceOrComplaintAdminColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fieldData,
      dynamicMasterData,
      academicYear,
      fromDate,
      toDate,
      type,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;

    return (
      <>
        {this.state.showLoader && <Loading1 />}
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={ComplaintAdminJson.screenTitle}
          showPdfDownload={ComplaintAdminJson.showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={ComplaintAdminJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={false}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br></br>

        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <DynamicForm
                fieldMeta={ComplaintAdminJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showSaveNextBtn={false}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave(true)}
                onSaveAndNext={this.onFormSave(false)}
              />
            </>
          )}

          {showTable && (
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange("fromDate")}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.onDateChange("toDate")}
                        isError={toDateError ? true : false}
                        errorText={toDateError ? toDateError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.submitHandler}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ mt: 2 }}>
                <RTTable
                  columns={GrievanceOrComplaintAdminColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  showAddButtonInTable={showAddButtonInTable}
                  rowEdit={this.rowEdit}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                  rowAdd={this.rowAdd}
                  tableData={this.getFilteredTableData()}
                  rows={this.getFilteredTableData()}
                  // rows={this.props.greivanceList?.grievance}
                  // tableData={this.props.greivanceList?.grievance}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  researchProjectList: state.researchProject,
  greivanceList: state.grievance,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getActivityByCourseAttended,
  getGrievanceAdminByFilter,
  resetReducList,
  getGrievanceAdminFilter,
};
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintAdmin);
