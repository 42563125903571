import endpoint from "../config/endpoints";

export const innovativeTeachingJson = {
  apiBaseURL: endpoint.innovative,
  deleteUrl: endpoint.empInnovativeDoc,
  screenTitle: "Innovative Teaching",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Innovative Teaching",
  showExcelDownload: true,
  excelFileName: "Innovative Teaching",
  baseIdColumn: "id",
  DocumentUpload: "employeeInnovativeTeachingDocument",
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromDate",
      // isMAxDate: false,
      // maxDate: new Date(),
      // isMinDate: true,
      // minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "toDate",
      // isMAxDate: false,
      // maxDate: new Date(),
      // isMinDate: true,
      // minDate: new Date(),
      isMandatory: true,
    },
  ],
  fieldMeta: [
    {
      label: "Activity",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "employeeActivity",
      isRootLevelKey: false,
      isMandatory: true,
    },
    // {
    //   label: "Date",
    //   controlType: "datepicker",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "date",
    //   isMAxDate: false,
    //   maxDate: new Date(),
    //   isMinDate: false,
    //   minDate: new Date(),
    //   isMandatory: true,
    // },
    {
      label: "Subject",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "subjectIds",
      isMandatory: true,
    },
    {
      label: "Activity Details",
      controlType: "textarea",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "activityDetail",
      isMandatory: true,
    },
  ],
};
