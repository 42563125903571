import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { ListOfInternshipDynamicJson } from "../../DynamicFormsJson/ListOfInternshipDynamic";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete } from "../../utils/api_service";
import { getInternshipDeclareById } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import {
  changeInternshipDeclareStatus,
  getAllCompanyList,
  getCompanyList,
  resetTableList,
  resetValues,
} from "./CompanyList.slice";
import InterestedStudent from "./InterestedStudent";
import Preview from "./Preview";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "companyName",
    title: "Company Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
    canSearch: true,
  },
  {
    name: "venue",
    title: "Address",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
    canSearch: true,
  },
  {
    name: "date",
    title: "Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "15%",
    canSearch: true,
  },
  {
    name: "isActive",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
    canSearch: true,
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "15%",
  },
];

class ListOfCompany extends React.Component {
  state = {
    id: 0,
    companyData: "",
    formErrors: [],
    interestedStudent: false,
    preview: false,
    listOfComp: true,
    fromDate: "",
    toDate: "",
    internType: "",
    searchValue: "",
    showLoader: false,
    dynamicMasterData: {
      internshipMaster: this.props.internshipTypeList?.internshipType,
    },
  };

  componentDidMount() {
    const { getInternshipType, getAlert, resetReducList, resetValues } =
      this.props;
    this.setState({
      showLoader: true,
    });

    getInternshipType().then((response) => {
      if (!response) {
        // this.setState({
        //   showLoader: false,
        // });
        getAlert({ message: "Something went wrong" });
      } else {
        // this.setState({
        //   showLoader: false,
        // });
      }
    });

    this.props.getAllCompanyList().then((response) => {
      this.setState({
        showLoader: true,
      });
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    // resetValues();
    // resetReducList();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.internshipTypeList !== nextProps.internshipTypeList) {
      if (
        this.props.internshipTypeList.internshipType !==
        nextProps.internshipTypeList.internshipType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            internshipMaster: nextProps.internshipTypeList.internshipType,
          },
        });
      }
    }
  }

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { fromDate, toDate, internType } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (internType.toString().trim() === "" || internType === null) {
      formIsValid = false;
      formErrors["internTypeError"] = myConstClass.internTypeMsg;
    }

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onSearchData = (data) => {
    const { fromDate, toDate, internType } = this.state;
    const { getCompanyList, getAlert } = this.props;
    this.setState({
      showLoader: true,
    });
    getCompanyList({
      fromDate: data.fromDate,
      toDate: data.toDate,
      internType: data.internship,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  rowDetails = (data) => {
    this.setState({
      showLoader: true,
    });
    const { getInternshipDeclareById } = this.props;
    getInternshipDeclareById({
      id: data.internshipDeclareId,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    this.setState({
      preview: true,
      interestedStudent: false,
      listOfComp: false,
      paymentDetailsData: data,
    });
  };
  GoBack = () => {
    this.setState({
      preview: false,
      interestedStudent: false,
      listOfComp: true,
    });
  };
  onChangeStatus = (data) => {
    const { fromDate, toDate, internType } = this.state;

    const { getCompanyList, changeInternshipDeclareStatus, showNotification } =
      this.props;
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");

    this.setState({
      showLoader: true,
    });
    swal({
      title: "Are you sure?",
      text: "Do you want to change status?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        changeInternshipDeclareStatus({
          internshipDeclareId: data.internshipDeclareId,
          status: data.isActive ? 0 : 1,
        }).then((success) => {
          if (success) {
            this.props.getAllCompanyList().then((response) => {
              showNotification({ msg: "Status updated" });
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          }
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };
  rowAppliedList = (data) => {
    const { comanyData } = this.state;
    this.setState({
      interestedStudent: true,
      listOfComp: false,
      companyData: data,
    });
  };

  rowDelete = (data) => {
    const { fromDate, toDate, internType } = this.state;
    const { getCompanyList } = this.props;
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiDelete({
          url: endpoints.internshipDeclare + "/" + data.internshipDeclareId,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showNotification({ msg: "Data deleted successfully" });
            this.props.getAllCompanyList().then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          }
        });
        // getAppliedEventList(data);
        //   this.onSave(data);
      }
    });
  };

  onAddButtonClick = () => {
    window.location.replace("/declarationOfInternship");
  };

  closeStudentFeesDetails = () => {
    this.setState({ interestedStudent: false, listOfComp: true });
    const { resetValues } = this.props;

    // resetValues();
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    const { internshipDeclareCompanyList } = this.props;
    return internshipDeclareCompanyList?.companyListStatus.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["companyName"] &&
            currentRow["companyName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["venue"] &&
            currentRow["venue"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["date"] &&
            currentRow["date"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  rowDelete = (data) => {
    const { fromDate, toDate, internType } = this.state;
    const { getCompanyList } = this.props;
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    this.setState({
      showLoader: true,
    });
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiDelete({
          url: endpoints.internshipDeclare + "/" + data.internshipDeclareId,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({
              showLoader: false,
            });
            this.props.showNotification({ msg: "Data deleted successfully" });
            this.props.getAllCompanyList().then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          }
        });
        // getAppliedEventList(data);
        //   this.onSave(data);
      }
    });
  };
  render() {
    const {
      interestedStudent,
      listOfComp,
      fromDate,
      toDate,
      companyData,
      internType,
      dynamicMasterData,
      preview,
    } = this.state;
    const { internTypeError, fromDateError, toDateError } =
      this.state.formErrors;
    const {
      internshipTypeList,
      internshipDeclareCompanyList,
      internshipDeclarationList,
    } = this.props;
    return (
      <>
        <div>
          {/* <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}> */}
          {listOfComp && !interestedStudent && (
            <>
              {/* <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="List of Internship Declared"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    container
                    justifyContent="flex-end"
                  >
                    <Link
                      color="primary"
                      variant="body2"
                      component={RouterLink}
                      to="/declarationOfInternship"
                    >
                      <MyComponentWithIconProps
                        statusImage={AddCircleIcon}
                        color="primary"
                        fontSize="large"
                        title="Declare Internship"
                      />
                    </Link>
                  </Grid>
                  <br />
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange( "fromDate" )}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.onDateChange( "toDate" )}
                        isError={toDateError ? true : false}
                        errorText={toDateError ? toDateError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      rowSpacing={2}
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid sx={{ mb: -2 }} item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Intern Type"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={internType}
                          name={"internType"}
                          options={internshipTypeList?.internshipType}
                          onChange={this.ChangeHandlerSearch}
                          isError={internTypeError ? true : false}
                          errorText={internTypeError ? internTypeError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ marginTop: 3 }}
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="left"
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Search"
                          onClick={this.searchData}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  sx={{ marginTop: 1, marginBottom: 2 }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <SearchCompanyList
                    onSearch={this.onSearch}
                  />
                </Grid>
                <Grid item sx={{ mr: 1 }} xs={12} sm={12} md={12}>
                  <DynamicTable
                    data={this.getFilteredTableData()}
                    tableHead={columns}
                    showHeadDelete={true}
                    showAppliedList={true}
                    showDetails={true}
                    rowDelete={this.rowDelete}
                    rowAppliedList={this.rowAppliedList}
                    rowDetails={this.rowDetails}
                    rowStatus={this.onChangeStatus}
                    showPegination={false}
                  />
                </Grid>
 */}

              <DynamicMainScreen
                onAddButtonClick={this.onAddButtonClick}
                screenTitle={ListOfInternshipDynamicJson.screenTitle}
                fieldMeta={ListOfInternshipDynamicJson.fieldMeta}
                buttonCenter={ListOfInternshipDynamicJson.buttonCenter}
                showPdfDownload={ListOfInternshipDynamicJson.showPdfDownload}
                showExcelDownload={
                  ListOfInternshipDynamicJson.showExcelDownload
                }
                pdfFileName={ListOfInternshipDynamicJson.pdfFileName}
                excelFileName={ListOfInternshipDynamicJson.excelFileName}
                tableColumnsToFilter={
                  ListOfInternshipDynamicJson.tableColumnsToFilter
                }
                showAddButton={ListOfInternshipDynamicJson.showAddButton}
                dynamicMasterData={dynamicMasterData}
                tableColumns={columns}
                tableData={internshipDeclareCompanyList?.companyListStatus}
                getListById={this.getListById}
                showPegination={true}
                showHeadEdit={false}
                showHeadDelete={true}
                // showPegination={false}
                pdfDetailsId={this.pdfDetails}
                rowAppliedList={this.rowAppliedList}
                rowViewData={this.onClickView}
                rowDelete={this.rowDelete}
                showAppliedList={true}
                rowDetails={this.rowDetails}
                showLoader={this.state.showLoader}
                onDelete={this.onDelete}
                showDetails={true}
                rowStatus={this.onChangeStatus}
                onSearchData={this.onSearchData}
                baseIdColumn={ListOfInternshipDynamicJson.baseIdColumn}
                apiBaseURL={ListOfInternshipDynamicJson.apiBaseURL}
              />
            </>
          )}
          {!listOfComp && interestedStudent && (
            <InterestedStudent
              onCancel={this.closeStudentFeesDetails}
              companyData={companyData}
            />
          )}

          {preview && (
            <Preview
              GoBack={this.GoBack}
              DeclarationData={internshipDeclarationList?.internshipDeclaration}
            />
          )}
          {/* </Paper> */}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipTypeList: state.internshipType,
  internshipDeclareCompanyList: state.companyList,
  internshipDeclarationList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  getInternshipType,
  resetTableList,
  getAlert,
  getCompanyList,
  getInternshipDeclareById,
  changeInternshipDeclareStatus,
  showNotification,
  resetValues,
  resetReducList,
  getAllCompanyList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOfCompany);

//

// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import { Grid, Link, Paper } from "@mui/material";
// import moment from "moment";
// import React from "react";
// import { connect } from "react-redux";
// import { showNotification } from "../../Pages/Landing/Landing.slice";
// import { downloadF } from "../PDF/PDFDownload";
// import { Link as RouterLink } from "react-router-dom";
// import swal from "sweetalert";
// import { getAlert } from "../../CommonActions/alert.slice";
// import AutoComplete from "../../components/Comman/AutoComplete";
// import { ButtonCompo } from "../../components/Comman/Button";
// import BasicDatePicker from "../../components/Comman/DatePickerEx";
// import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
// import { resetReducList } from "../../CommonActions/User.action";
// import { LabelCompo } from "../../components/Comman/Label";
// import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
// import * as myConstClass from "../../config/messageconstant";
// import { getInternshipDeclareById } from "../InternshipDeclaration/InternshipDeclataration.slice";
// import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
// import SearchCompanyList from "../../components/SearchTable";
// import
// {
//   changeInternshipDeclareStatus,
//   getCompanyList,
//   resetTableList,
//   resetValues,
// } from "./CompanyList.slice";
// import InterestedStudent from "./InterestedStudent";
// import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
// import { ListOfInternshipDynamicJson } from "../../DynamicFormsJson/ListOfInternshipDynamic";
// import Preview from "./Preview";
// const columns = [
//   {
//     name: "index",
//     title: "Sr. No.",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "10%",
//   },
//   {
//     name: "companyName",
//     title: "Company Name",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "20%",
//   },
//   {
//     name: "venue",
//     title: "Address",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "20%",
//   },
//   {
//     name: "date",
//     title: "Date",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "15%",
//   },
//   {
//     name: "isActive",
//     title: "Status",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "10%",
//   },
//   {
//     name: "action",
//     title: "Action",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "right",
//     width: "15%",
//   },
// ];

// class ListOfCompany extends React.Component
// {
//   state = {
//     id: 0,
//     companyData: "",
//     formErrors: [],
//     interestedStudent: false,
//     preview: false,
//     listOfComp: true,
//     fromDate: "",
//     toDate: "",
//     internType: "",
//     searchValue: "",
//   };

//   componentDidMount()
//   {
//     const { getInternshipType, getAlert, resetReducList, resetValues } =
//       this.props;

//     getInternshipType().then( ( response ) =>
//     {
//       if ( !response )
//       {
//         getAlert( { message: "Something went wrong" } );
//       }
//     } );
//     resetValues();
//     resetReducList();
//   }

//   onDateChange = ( dataKey ) => ( date ) =>
//   {
//     if ( date !== null )
//     {
//       this.setState( {
//         [ dataKey ]: date,
//       } );
//     }
//   };

//   changeHandler = ( event ) =>
//   {
//     const { name, value } = event.target;
//     this.setState( {
//       ...this.state,
//       [ name ]: value,
//     } );
//   };

//   ChangeHandlerSearch = ( name, newValue ) =>
//   {
//     if ( newValue !== null )
//     {
//       this.setState( { [ name ]: newValue } );
//     } else
//     {
//       this.setState( { [ name ]: "" } );
//     }
//   };

//   handleFormValidation()
//   {
//     const { fromDate, toDate, internType } = this.state;
//     let formErrors = {};
//     let formIsValid = true;

//     if ( internType.toString().trim() === "" || internType === null )
//     {
//       formIsValid = false;
//       formErrors[ "internTypeError" ] = myConstClass.internTypeMsg;
//     }

//     if ( fromDate.toString().trim() === "" || fromDate === null )
//     {
//       formIsValid = false;
//       formErrors[ "fromDateError" ] = myConstClass.fromDateMsg;
//     }

//     if ( toDate.toString().trim() === "" || toDate === null )
//     {
//       formIsValid = false;
//       formErrors[ "toDateError" ] = myConstClass.toDateMsg;
//     }

//     if ( fromDate > toDate )
//     {
//       formIsValid = false;
//       formErrors[ "toDateError" ] = myConstClass.displaytoMsg;
//     }
//     this.setState( { formErrors: formErrors } );
//     return formIsValid;
//   }

//   searchData = () =>
//   {
//     const { fromDate, toDate, internType } = this.state;
//     const { getCompanyList, getAlert } = this.props;
//     let fromDt = moment( fromDate ).format( "YYYY-MM-DD" );
//     let toDt = moment( toDate ).format( "YYYY-MM-DD" );
//     if ( this.handleFormValidation() )
//     {
//       getCompanyList( {
//         fromDate: fromDt,
//         toDate: toDt,
//         internType: internType,
//       } ).then( ( response ) =>
//       {
//         if ( !response )
//         {
//           getAlert( { message: "Something went wrong" } );
//         }
//       } );
//     }
//   };

//   rowDetails = ( data ) =>
//   {

//     const { getInternshipDeclareById } = this.props;
//     getInternshipDeclareById( {
//       id: data.internshipDeclareId,
//     } ).then( ( response ) => { } );

//     this.setState( {
//       preview: true,
//       interestedStudent: false,
//       listOfComp: false,
//       paymentDetailsData: data,
//     } );
//   };
//   GoBack = () =>
//   {
//     this.setState( {
//       preview: false,
//       interestedStudent: false,
//       listOfComp: true,
//     } );
//   };
//   onChangeStatus = ( data ) =>
//   {
//     const { fromDate, toDate, internType } = this.state;

//     const { getCompanyList, changeInternshipDeclareStatus, showNotification } =
//       this.props;
//     let fromDt = moment( fromDate ).format( "YYYY-MM-DD" );
//     let toDt = moment( toDate ).format( "YYYY-MM-DD" );

//     swal( {
//       title: "Are you sure?",
//       text: "Do you want to change status?",
//       icon: "warning",
//       buttons: [ "Cancel", true ],
//     } ).then( ( res ) =>
//     {

//       if ( res )
//       {
//         changeInternshipDeclareStatus( {
//           internshipDeclareId: data.internshipDeclareId,
//           status: data.isActive ? 0 : 1,
//         } ).then( ( success ) =>
//         {
//           if ( success )
//           {
//             showNotification( { msg: "Status updated" } );

//             getCompanyList( {
//               fromDate: fromDt,
//               toDate: toDt,
//               internType: internType,
//             } ).then( ( response ) =>
//             {
//               if ( !response )
//               {

//                 getAlert( { message: "Something went wrong" } );
//               }
//             } );
//           }
//         } );
//       }
//     } );
//   };
//   rowAppliedList = ( data ) =>
//   {

//     const { comanyData } = this.state;
//     this.setState( {
//       interestedStudent: true,
//       listOfComp: false,
//       companyData: data,
//     } );
//   };

//   closeStudentFeesDetails = () =>
//   {

//     this.setState( { interestedStudent: false, listOfComp: true } );
//     const { resetValues } = this.props;

//     resetValues();
//   };
//   getFilteredTableData = () =>
//   {
//     const { searchValue } = this.state;
//     const { internshipDeclareCompanyList } = this.props;
//     return internshipDeclareCompanyList?.companyListStatus.filter( ( currentRow ) =>
//     {
//       let isValid = false;
//       if (
//         ( currentRow[ "companyName" ] &&
//           currentRow[ "companyName" ]
//             .toString()
//             .toLowerCase()
//             .includes( searchValue.toLowerCase() ) ) ||
//         ( currentRow[ "venue" ] &&
//           currentRow[ "venue" ]
//             .toString()
//             .toLowerCase()
//             .includes( searchValue.toLowerCase() ) ) ||
//         ( currentRow[ "date" ] &&
//           currentRow[ "date" ]
//             .toString()
//             .toLowerCase()
//             .includes( searchValue.toLowerCase() ) )
//       )
//       {
//         isValid = true;
//         return true;
//       }
//       return isValid;
//     } );
//   };
//   onSearch = ( value ) =>
//   {
//     this.setState( {
//       searchValue: value,
//     } );
//   };
//   render()
//   {
//     const {
//       interestedStudent,
//       listOfComp,
//       fromDate,
//       toDate,
//       companyData,
//       internType,
//       preview,
//     } = this.state;
//     const { internTypeError, fromDateError, toDateError } =
//       this.state.formErrors;
//     const {
//       internshipTypeList,
//       internshipDeclareCompanyList,
//       internshipDeclarationList,
//     } = this.props;
//     return (
//       <>
//         <div>
//           <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
//             {listOfComp && !interestedStudent && (
//               <>
//                 <Grid
//                   item
//                   sm={12}
//                   xs={12}
//                   md={12}
//                   lg={12}
//                   rowSpacing={1}
//                   columnSpacing={1}
//                   container
//                   justifyContent="space-evenly"
//                 >
//                   <Grid item xs={12} sm={6} md={6}>
//                     <LabelCompo
//                       className="text-black"
//                       style={{ fontSize: 22 }}
//                       label="List of Internship Declared"
//                     />
//                   </Grid>
//                   <Grid
//                     item
//                     xs={12}
//                     sm={6}
//                     md={6}
//                     container
//                     justifyContent="flex-end"
//                   >
//                     <Link
//                       color="primary"
//                       variant="body2"
//                       component={RouterLink}
//                       to="/declarationOfInternship"
//                     >
//                       <MyComponentWithIconProps
//                         statusImage={AddCircleIcon}
//                         color="primary"
//                         fontSize="large"
//                         title="Declare Internship"
//                       />
//                     </Link>
//                   </Grid>
//                   <br />
//                   <Grid
//                     container
//                     justifyContent="space-evenly"
//                     direction="row"
//                     columnSpacing={3}
//                     xs={12}
//                     sm={6}
//                     md={6}
//                     sx={{ marginTop: 2 }}
//                   >
//                     <Grid item xs={12} sm={12} md={12}>
//                       <LabelCompo
//                         className="text-black"
//                         style={{ fontSize: 16 }}
//                         label="*From Date"
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12}>
//                       <BasicDatePicker
//                         color="primary"
//                         name="fromDate"
//                         size="small"
//                         value={fromDate}
//                         fullWidth
//                         onChange={this.onDateChange( "fromDate" )}
//                         isError={fromDateError ? true : false}
//                         errorText={fromDateError ? fromDateError : " "}
//                       />
//                     </Grid>
//                   </Grid>
//                   <Grid
//                     container
//                     justifyContent="space-evenly"
//                     direction="row"
//                     xs={12}
//                     sm={6}
//                     md={6}
//                     sx={{ marginTop: 2 }}
//                   >
//                     <Grid item xs={12} sm={12} md={12}>
//                       <LabelCompo
//                         className="text-black"
//                         style={{ fontSize: 16 }}
//                         label="*To Date"
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12}>
//                       <BasicDatePicker
//                         color="primary"
//                         name="toDate"
//                         size="small"
//                         value={toDate}
//                         fullWidth
//                         onChange={this.onDateChange( "toDate" )}
//                         isError={toDateError ? true : false}
//                         errorText={toDateError ? toDateError : " "}
//                       />
//                     </Grid>
//                   </Grid>

//                   <Grid
//                     container
//                     justifyContent="space-evenly"
//                     direction="row"
//                     xs={12}
//                     sm={12}
//                     md={12}
//                   >
//                     <Grid
//                       container
//                       justifyContent="space-evenly"
//                       direction="row"
//                       rowSpacing={2}
//                       columnSpacing={3}
//                       xs={12}
//                       sm={6}
//                       md={6}
//                     >
//                       <Grid sx={{ mb: -2 }} item xs={12} sm={12} md={12}>
//                         <LabelCompo
//                           className="text-black"
//                           style={{ fontSize: 16 }}
//                           label="*Intern Type"
//                         />
//                       </Grid>

//                       <Grid item xs={12} sm={12} md={12}>
//                         <AutoComplete
//                           keyColName={"id"}
//                           value={internType}
//                           name={"internType"}
//                           options={internshipTypeList?.internshipType}
//                           onChange={this.ChangeHandlerSearch}
//                           isError={internTypeError ? true : false}
//                           errorText={internTypeError ? internTypeError : " "}
//                         />
//                       </Grid>
//                     </Grid>
//                     <Grid
//                       justifyContent="space-evenly"
//                       direction="row"
//                       xs={12}
//                       sm={6}
//                       md={6}
//                       sx={{ marginTop: 3 }}
//                     >
//                       <Grid
//                         item
//                         sm={12}
//                         xs={12}
//                         md={12}
//                         lg={12}
//                         container
//                         justifyContent="left"
//                       >
//                         <ButtonCompo
//                           size="medium"
//                           type="button"
//                           variant="contained"
//                           name="Search"
//                           onClick={this.searchData}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 {/* <br /> */}
//                 <Grid
//                   sx={{ marginTop: 1, marginBottom: 2 }}
//                   item
//                   xs={12}
//                   sm={12}
//                   md={12}
//                 >
//                   <SearchCompanyList
//                     onSearch={this.onSearch}
//                   />
//                 </Grid>
//                 <Grid item sx={{ mr: 1 }} xs={12} sm={12} md={12}>
//                   <DynamicTable
//                     data={this.getFilteredTableData()}
//                     tableHead={columns}
//                     showHeadDelete={true}
//                     showAppliedList={true}
//                     showDetails={true}
//                     rowDelete={this.rowDelete}
//                     rowAppliedList={this.rowAppliedList}
//                     rowDetails={this.rowDetails}
//                     rowStatus={this.onChangeStatus}
//                     showPegination={false}
//                   />
//                 </Grid>

//                 <Grid
//                   container
//                   justifyContent="space-evenly"
//                   direction="row"
//                   xs={12}
//                   sm={12}
//                   md={12}
//                 >
//                   <Grid
//                     item
//                     sm={12}
//                     xs={12}
//                     md={12}
//                     lg={12}
//                     rowSpacing={2}
//                     container
//                     justifyContent="right"
//                     m={1}
//                   ></Grid>
//                 </Grid>
//               </>
//             )}
//             {!listOfComp && interestedStudent && (
//               <InterestedStudent
//                 onCancel={this.closeStudentFeesDetails}
//                 companyData={companyData}
//               />
//             )}

//             {preview && (
//               <Preview
//                 GoBack={this.GoBack}
//                 DeclarationData={
//                   internshipDeclarationList?.internshipDeclaration
//                 }
//               />
//             )}
//           </Paper>
//         </div>
//       </>
//     );
//   }
// }
// const mapStateToProps = ( state ) => ( {
//   internshipTypeList: state.internshipType,
//   internshipDeclareCompanyList: state.companyList,
//   internshipDeclarationList: state.internshipDeclaration,
// } );
// const mapDispatchToProps = {
//   getInternshipType,
//   resetTableList,
//   getAlert,
//   getCompanyList,
//   getInternshipDeclareById,
//   changeInternshipDeclareStatus,
//   showNotification,
//   resetValues,
//   resetReducList,
// };
// export default connect( mapStateToProps, mapDispatchToProps )( ListOfCompany );
