import { Grid } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "./StudentFeedBackDetailTable";

function StudentFeedBackDetails({
  yearDetail,
  onCancel,
  editData,
  studentFeedbackDetailsList,
}) {
  const [values, setValues] = React.useState("");
  const [teacherId, setTeacherId] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");
  const [teacherError, setTeacherError] = React.useState(false);
  const [subjectError, setSubjectError] = React.useState(false);
  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue != null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  const handleFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (subjectId === "") {
      setSubjectError(true);
    }

    if (teacherId === "") {
      setTeacherError(true);
    }

    return formIsValid;
  };

  const setQuaData = (data) => {
    const dataToPush = data.map((data1) => {
      let bData = {
        feedbackHeadDetails: {
          id: data1.id,
        },
        value: data1.answer,
      };
      return bData;
    });
    let pushData = {
      studentFeedback: {
        id: editData.id,
      },
      studentFeedbackTransactionDetails: dataToPush,
    };

    if (editData.isSubject === 1) {
      if (handleFormValidation()) {
        pushData = {
          ...pushData,
          subject: {
            id: "",
          },
          facultyRegistration: {
            id: "",
          },
        };
      }
    } else {
      pushData = {
        ...pushData,
        subject: {
          id: "",
        },
        facultyRegistration: {
          id: "",
        },
      };
    }
    setValues(pushData);
    console.log(pushData);
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Questions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  return (
    <>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid sx={{ marginBottom: 2 }} item xs={12} sm={10.5} md={10.5}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 22 }}
            label="Feedbacks"
          />
        </Grid>
        <Grid sx={{ marginBottom: 2 }} item xs={12} sm={1.5} md={1.5}>
          <ButtonCompo
            size="medium"
            type="button"
            variant="outlined"
            name="Back to List"
            onClick={onCancel}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid item xs={12} sm={2} md={2}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Student Name"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label={editData.studentName}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Feedback Date"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label={editData.feedbackDate}
          />
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid item xs={12} sm={2} md={2}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Email-Id"
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label={editData.emailId}
          />
        </Grid>
      </Grid>

      <br />
      <DynamicTable
        data={editData.studentFeedbackTransactionDetails}
        tableHead={columns}
        showPegination={false}
        rowData={setQuaData}
        isActionColActive={false}
        onCancel={onCancel}
        //   rowStatus={onChangeAction}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  studentFeedbackDetailsList: state.studentFeedbackDetails,
});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFeedBackDetails);
