import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";
const initialState = {
  activity: [],
  activityByType: [],
  activityByType1: [],
  isActivityByType1Fetch: false,
  isActivityByTypeFetch: false,
  isActivityFetch: false,
  seminarWebinarType: [],
  activityByCourseAttended: [],
  activityByCourseAttendedFetch: false,
  isSeminarWebinarFetch: false,
  extendedActivity: [],
  isExtendedActivityFetch: false,
  committeeHandle: [],
  isCommitteeHandleFetch: false,
};
let URL = endpoints.activity;
const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    activitySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activity: row,
        isActivityFetch: true,
      };
    },
    activityByTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activityByType: row,
        isActivityByTypeFetch: true,
      };
    },
    activityByType1Success: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activityByType1: row,
        isActivityByType1Fetch: true,
      };
    },

    activityByType1Success: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activityByType1: row,
        isActivityByType1Fetch: true,
      };
    },
    seminarWebinarTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        seminarWebinarType: row,
        isSeminarWebinarFetch: true,
      };
    },
    getActivityByCourseAttendedSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        activityByCourseAttended: row,
        activityByCourseAttendedFetch: true,
      };
    },

    extendedActivitySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        extendedActivity: row,
        isExtendedActivityFetch: true,
      };
    },

    committeeHandleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        committeeHandle: row,
        isCommitteeHandleFetch: true,
      };
    },
  },
});

export const {
  activitySuccess,
  activityByTypeSuccess,
  activityByType1Success,
  seminarWebinarTypeSuccess,
  getActivityByCourseAttendedSuccess,
  extendedActivitySuccess,
  committeeHandleSuccess,
} = activitySlice.actions;

export default activitySlice.reducer;

export const getActivity = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            typeName:
              data1.type === 0
                ? "Co-curricular Activity"
                : data1.type === 1
                ? "Innovative Teaching Learning Strategies"
                : data1.type === 2
                ? "Seminar/Webinar"
                : data1.type === 3
                ? "Courses Attended"
                : data1.type === 4
                ? "Participation Details"
                : data1.type === 5
                ? "Extended Activity"
                : data1.type === 6
                ? "Committee Handle"
                : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(activitySuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActivityByType1 = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "0" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            typeName:
              data1.type === 0
                ? "Co-curricular Activity"
                : data1.type === 1
                ? "Innovative Teaching Learning Strategies"
                : data1.type === 2
                ? "Seminar/Webinar"
                : data1.type === 3
                ? "Courses Attended"
                : data1.type === 4
                ? "Participation Details"
                : data1.type === 5
                ? "Extended Activity"
                : data1.type === 6
                ? "Committee Handle"
                : "",
            // typeName:
            //   data1.type === 0
            //     ? "Co-curricular Activity"
            //     : "Innovative Teaching Learning Strategies",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(activityByType1Success({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActivityByType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "1" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            // typeName:
            //   data1.type === 0
            //     ? "Co-curricular Activity"
            //     : "Innovative Teaching Learning Strategies",
            typeName:
              data1.type === 0
                ? "Co-curricular Activity"
                : data1.type === 1
                ? "Innovative Teaching Learning Strategies"
                : data1.type === 2
                ? "Seminar/Webinar"
                : data1.type === 3
                ? "Courses Attended"
                : data1.type === 4
                ? "Participation Details"
                : data1.type === 5
                ? "Extended Activity"
                : data1.type === 6
                ? "Committee Handle"
                : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(activityByTypeSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActivityBySeminarType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "2" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            // typeName:
            //   data1.type == 0
            //     ? "Co-curricular Activity"
            //     : data1.type == 1
            //       ? "Innovative Teaching Learning Strategies"
            //       : data1.type == 2
            //         ? "Seminar/Webinar "
            //         : "",
            typeName:
              data1.type === 0
                ? "Co-curricular Activity"
                : data1.type === 1
                ? "Innovative Teaching Learning Strategies"
                : data1.type === 2
                ? "Seminar/Webinar"
                : data1.type === 3
                ? "Courses Attended"
                : data1.type === 4
                ? "Participation Details"
                : data1.type === 5
                ? "Extended Activity"
                : data1.type === 6
                ? "Committee Handle"
                : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(seminarWebinarTypeSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActivityByCourseAttended = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "3" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            // typeName:
            //   data1.type === 0
            //     ? "Co-curricular Activity"
            //     : "Innovative Teaching Learning Strategies",
            typeName:
              data1.type === 0
                ? "Co-curricular Activity"
                : data1.type === 1
                ? "Innovative Teaching Learning Strategies"
                : data1.type === 2
                ? "Seminar/Webinar"
                : data1.type === 3
                ? "Courses Attended"
                : data1.type === 4
                ? "Participation Details"
                : data1.type === 5
                ? "Extended Activity"
                : data1.type === 6
                ? "Committee Handle"
                : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(getActivityByCourseAttendedSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActivityByExtendedActivity = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "5" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            typeName:
              data1.type === 0
                ? "Co-curricular Activity"
                : data1.type === 1
                ? "Innovative Teaching Learning Strategies"
                : data1.type === 2
                ? "Seminar/Webinar"
                : data1.type === 3
                ? "Courses Attended"
                : data1.type === 4
                ? "Participation Details"
                : data1.type === 5
                ? "Extended Activity"
                : data1.type === 6
                ? "Committee Handle"
                : "",
            isActive: data1.isActive,
            activityType: data1.activityType,
          };
          row.push(bData);
          return data1;
        });
        dispatch(extendedActivitySuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActivityByCommitteeHandle = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "6" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            typeName:
              data1.type === 0
                ? "Co-curricular Activity"
                : data1.type === 1
                ? "Innovative Teaching Learning Strategies"
                : data1.type === 2
                ? "Seminar/Webinar"
                : data1.type === 3
                ? "Courses Attended"
                : data1.type === 4
                ? "Participation Details"
                : data1.type === 5
                ? "Extended Activity"
                : data1.type === 6
                ? "Committee Handle"
                : "",
            isActive: data1.isActive,
            activityType: data1.activityType,
          };
          row.push(bData);
          return data1;
        });
        dispatch(committeeHandleSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
