import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  pattern: [],
  isPatternFetch: false,
};
let URL = endpoints.pattern;
const patternSlice = createSlice({
  name: "pattern",
  initialState,
  reducers: {
    patternSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        pattern: row,
        isPatternFetch: true,
      };
    },
  },
});

export const { patternSuccess } = patternSlice.actions;

export default patternSlice.reducer;

export const getPattern = () => async (dispatch) => {
  try {
    const response= apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            markingSystem: data1.markingSystem,
            markingSystemName:
              data1.markingSystem === 1
                ? "Credit"
                : data1.markingSystem === 2
                ? "Marks"
                : data1.markingSystem === 3
                ? "Grade"
                : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(patternSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
