export const DeemedReportJson = {
  apiBaseURL: "",
  screenTitle: "Deemed Report",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: false,
  marginTop: 3,
  fieldMeta: [
    {
      label: "Committee",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "committeeMaster",
      dataKey: "committee",
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-l",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-g",
    },
  ],
};
