import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  teachingPlan: [],
  isFetch: false,

  subject: [],
  isFetchsubject: false,

  syllabus: {},
  isFetchSyllabus: false,

  teacherRecords: [],
  isFetchRecords: false,

  teacherPlan: {},
  isFetchTeacherPlan: false,
};
let URL = endpoints.teachingPlan;
let URL1 = endpoints.teachingSyllabus;

const teachingPlanSlice = createSlice({
  name: "teachingPlan",
  initialState,
  reducers: {
    teacherPlanSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        teacherPlan: row,
        isFetchTeacherPlan: true,
      };
    },

    teachingPlanSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        teachingPlan: row,
        isFetch: true,
      };
    },

    subjectList: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subject: row,
        isFetchsubject: true,
      };
    },
    syllabusSuccess: (state = cloneDeep(initialState), action) => {
      const { data2 } = action.payload;
      return {
        ...state,
        syllabus: data2,
        isFetchSyllabus: true,
      };
    },

    teacherRecordSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        teacherRecords: row,
        isFetchRecords: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        // ...state,
        // teachingPlan: [],
        // isFetch: false,
        ...initialState,
      };
    },
  },
});

export const {
  teachingPlanSuccess,
  subjectList,
  resetSuccess,
  syllabusSuccess,
  teacherRecordSuccess,
  teacherPlanSuccess,
} = teachingPlanSlice.actions;

export default teachingPlanSlice.reducer;

export const getTeachingPlan = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "3" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            date: data1.date,
            complaintDetail: data1.complaintDetail,
            id: data1.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(teachingPlanSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTeachingPlanBySemister =
  ({ semister }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/" + semister + "/get-subject-teaching-plan-by-semsiterDetail",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            const shortName = data1.subject.shortName.split("~");
            let bData = {
              id: data1.id,
              index: index,
              departmentName:
                data1.department !== null ? data1.department.name : "-",
              subject: data1.subject.name + " (" + shortName[1] + " )",
              date: data1.date ? data1.date : "-",
              toDate: data1.toDate ? data1.toDate : "-",
              remark: data1.remark ? data1.remark : "-",
              semister: data1.semisterDetail.id,
              yearDetail: data1.semisterDetail.semister.yearDetailId,
              topicTought: data1.topicTaught ? data1.topicTaught : "-",
              remark: data1.remark ? data1.remark : "-",
              noOfLectures: data1.noOfLectures ? data1.noOfLectures : "-",
              mediaUsed: data1.mediaUsed ? data1.mediaUsed : "-",
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectList({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getteachingPlanByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-from-to-date-type?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&type=" +
          3,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              date: data1.date,
              complaintDetail: data1.complaintDetail,
              id: data1.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(teachingPlanSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSyllabus =
  ({ subjectDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/by-subject-detail?subjectDetailId=" + subjectDetailId,
      }).then(({ data, success }) => {
        if (success && data.data !== null) {
          let index = 0;
          let row = [];
          let data2 = {
            id: data.data.id,
            details: data.data.getSyllabusChapters.map((data1, index) => {
              let syllabus = {
                index: index,
                id: data1.id,
                syllabusId: data.data.id,
                chapter: data1.chapterName,
                sortNo: data1.orderNo,
                checked: false,
                sectionList: data1.syllabusSections.map((docData, index) => {
                  let sectionListDetails = {
                    index: index + 1,
                    id: docData.id,
                    chapterId: data1.id,
                    sectionName: docData.sectionName,
                    sortNo: docData.orderNo,
                    checked: false,
                    subSectionList: docData.getSyllabusSubSections.map(
                      (docData1, index) => {
                        let subSectionListDetails = {
                          index: index + 1,
                          sectionId: docData.id,
                          id: docData1.id,
                          subSectionName: docData1.subsectionName,
                          sortNo: docData1.orderNo,
                          documentName: docData1.documentName,
                          checked: false,
                        };
                        return subSectionListDetails;
                      }
                    ),
                  };
                  return sectionListDetails;
                }),
              };
              return syllabus;
            }),
          };
          dispatch(syllabusSuccess({ data2 }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/subject-teaching-plan/by-semisterDetail-subjectDetail-division-batch-and-user-id?semisterDetailId=&subjectDetailId=&divisionId=&batchId=&fromDate=2022-06-05&toDate=2022-06-09
export const getRecordsByFilters =
  ({
    semesterDetailId,
    subjectDetailId,
    divisionId,
    batchId,
    fromDate,
    toDate,
  }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-semisterDetail-subjectDetail-division-batch-and-user-id?semisterDetailId=" +
          semesterDetailId +
          "&subjectDetailId=" +
          subjectDetailId +
          "&divisionId=" +
          divisionId +
          "&batchId=" +
          batchId +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.userRegistration !== null
                  ? data1.userRegistration.firstName +
                    " " +
                    data1.userRegistration.middleName +
                    " " +
                    data1.userRegistration.lastName
                  : "",
              
              lastName: data1.userRegistration.lastName,
              firstName:   data1.userRegistration.firstName ,
              fatherName: data1.userRegistration.fatherName,
              motherName: data1.userRegistration.motherName,
              middleName:data1.userRegistration.middleName,

              date: data1.date,
              topicTaught: data1.topicTaught,
              remark: data1.remark,
              batchName: data1.batch.batchName,
              semisterDetail: data1.semisterDetail.semister.name,
              subjectDetailId: data1.subjectDetail.id,
              division: data1.division.name,
              yearDetailId: data1.division.yearDetailId,
              semisterDetailId: data1.semisterDetail.id,
              divisionId: data1.division.id,
              batchId: data1.batch.id,
              toDate: data1.toDate,
              noOfLectures: data1.noOfLectures,
              mediaUsed: data1.mediaUsed,
            };
            row.push(bData);
            return data1;
          });
          dispatch(teacherRecordSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getByIdTeacherPlan =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const teacherPlan = data.data;
          let teacherPlanData = {
            id: teacherPlan.id,
            yearDetailId: teacherPlan.yearDetail.id,
            semisterDetailId: teacherPlan.semisterDetail.id,
            semisterId: teacherPlan.semisterDetail.semister.id,
            subjectDetailId: teacherPlan.subjectDetail.id,
            divisionId: teacherPlan.division.id,
            batchId: teacherPlan.batch.id,
            topicTaught: teacherPlan.topicTaught,
            remark: teacherPlan.remark,
            date: teacherPlan.date,
            toDate: teacherPlan.toDate,
            noOfLectures: teacherPlan.noOfLectures,
            mediaUsed: teacherPlan.mediaUsed,
          };
          dispatch(teacherPlanSuccess({ row: teacherPlanData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
