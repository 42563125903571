export const StudentFeesCollectionReportJson = {
    apiBaseURL:
        "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
    screenTitle: "Student Fees Collection Report",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    searchButton: false,
    PDFButton: true,
    EXCELButton: true,
    marginTop: 3,
    fieldMeta: [
        {
            label: "Academic Year",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "academicYear",
            dataKey: "academicYearId",
            isMandatory: true,
        },
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearDetails",
            dataKey: "yearDetailId",
            isMandatory: true,
            getListId: "yearDetailId"
        },
        {
            label: "Division",
            controlType: "multiSelect",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "division",
            dataKey: "division",
            isMandatory: true,
        },
        {
            "label": "Fees Type",
            "controlType": "radiogroup",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "feesType",
            "defaultValue": 1,
            "radioGroupItems": [
                {
                    "label": "Pending",
                    "value": 1
                },
                {
                    "label": "All",
                    "value": 0
                }
            ],
            "isRootLevelKey": false,
            "isMandatory": true
        }
    ],
};
