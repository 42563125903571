import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet, apiPost } from "../../../utils/api_service";
const initialState = {
  rollNoList: [],
  isFetchRollNoList: false,
  studentListByYear: [],
  isFetchStudentListByYear: false,

  rollNoListNew: [],
  isFetchRollNoListNew:false
};
let URL = endpoints.studentRollNo;
const rollNoListSlice = createSlice({
  name: "rollNoList",
  initialState,
  reducers: {
    rollNoListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        rollNoList: row,
        isFetchRollNoList: true,
      };
    },
    studentListByYearSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentListByYear: row,
        isFetchStudentListByYear: true,
      };
    },


    studentListNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        rollNoListNew: row,
        isFetchRollNoListNew: true,
      };
    },


    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { rollNoListSuccess, studentListByYearSuccess, resetSuccess ,studentListNewSuccess} =
  rollNoListSlice.actions;

export default rollNoListSlice.reducer;

export const getRollNoList =
  ({ semisterDetail, yearDetail, sortOrder }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-list-by-year-detail-semister-detail?yearDetailId=" +
          yearDetail +
          "&semisterDetailId=" +
          semisterDetail +
          "&orderBy=" +
          sortOrder,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;
            let fatherName = data1.middleName !== null ? data1.middleName : "";
            let motherName = data1.motherName !== null ? data1.motherName : "";
            let bData = {
              index: index,
              id: data1.id,
              studentName:
                data1.lastName +
                " " +
                data1.firstName +
                " " +
                fatherName +
                " - " +
                motherName,
              prnNo: data1.prnNo,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              rollNo: index,
              reportingDate: new Date(),
            };
            return bData;
          });
          dispatch(rollNoListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByYear =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-student-list-by-year-detail?yearDetailId=" + yearDetail,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              fatherName: data1.fatherName,
              name:
                data1.lastName +
                " " +
                data1.firstName +
                " " +
                (data1.fatherName == null || data1.fatherName == ""
                  ? " - "
                  : data1.fatherName) +
                " " +
                (data1.motherName == null || data1.motherName == ""
                  ? " - "
                  : data1.motherName),
              id: data1.id,
              motherName: data1.motherName,
              prnNo: data1.prnNo,
            };
            return bData;
          });
          dispatch(studentListByYearSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
    };
  


// new method added

export const getPreviousStudentRollList =
  ({ yearDetail,semisterDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-previous-semister-student-list-by-year-detail-semister-detail?yearDetailId=" + yearDetail +"&semisterDetailId="+semisterDetail,
      }).then(({ data, success }) => {
console.log(data)
        if ( success )
        {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              fatherName: data1.fatherName,
              studentName:
                data1.lastName +
                " " +
                data1.firstName +
                " " +
                (data1.fatherName == null || data1.fatherName == ""
                  ? " - "
                  : data1.fatherName) +
                " " +
                (data1.motherName == null || data1.motherName == ""
                  ? " - "
                  : data1.motherName),
              id: data1.id,
              motherName: data1.motherName,
              prnNo: data1.prnNo,
              rollNo:data1.rollNo,
              reportingDate: new Date(),
              isChecked:false
            };
            return bData;
          });
          dispatch(studentListNewSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//new method added end















export const saveRollNoList =
  ({ rollNoList }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL,
        postBody: rollNoList,
      }).then(({ data, success }) => {
        if (success) {
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
