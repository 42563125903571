import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { FormHelperText, Grid, Link, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import endpoints from "../../config/endpoints";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { showNotification } from "../Landing/Landing.slice";
import StudentPreview1 from "../StudentPreview1/index";
import { getStudentById } from "../BasicDetailsPost/basicDetails.slice";
import * as myConstClass from "../../config/messageconstant";
import { getAdmissionStatus } from "../Masters/Admission/AdmissionStatus/admissionStatus.slice";

import {
  getStudentList,
  saveStudentList,
  updateAdmission,
  resetError,
  checkPrn,
} from "./Promotional.slice";
class PromotionalAdmissionForm extends React.Component {
  state = {
    id: 0,
    typeOfAdmission: "",
    allotmentStatus: [],
    formErrors: [],
    academicYear: "",
    studentName: "",
    viewStudentName: "",
    yearDetail: "",
    scholarshipApplicable: "0",
    scholarshipType: "0",
    scholarshipFormSubmit: "0",
    admissionStatus: "",
    feeStructuredHeaderId: "",
    totalApplicableFees: "0",
    totalfeesPaid: "0",
    paidStatus: "0",
    admissionDate: "",
    detail: false,
    promotionalAdmissionDetail: [],
    open: false,
    prn: "",
    viewPrn: "",
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.yearDetail !== nextProps.yearDetail) {
      if (this.props.classList.class !== nextProps.classList.class) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.classList.class,
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYearData: nextProps.academicYearList.academicYear,
          },
        });
      }
    }

    if (
      this.props.applicantAdmissionList !== nextProps.applicantAdmissionList
    ) {
      if (
        this.props.applicantAdmissionList.applicantAdmission !==
        nextProps.applicantAdmissionList.applicantAdmission
      ) {
        const admissionDateArr =
          nextProps.applicantAdmissionList?.applicantAdmission.admissionDate ==
          null
            ? ""
            : nextProps.applicantAdmissionList?.applicantAdmission.admissionDate.split(
                "-"
              );
        const admissionDt =
          admissionDateArr == ""
            ? ""
            : new Date(
                admissionDateArr[2],
                admissionDateArr[1] - 1,
                admissionDateArr[0]
              );
        this.setState({
          detail: true,
          viewPrn: nextProps.applicantAdmissionList?.applicantAdmission.prn,
          academicYear:
            nextProps.applicantAdmissionList?.applicantAdmission.academicYearId,
          studentName:
            nextProps.applicantAdmissionList?.applicantAdmission.studentName,
          viewStudentName:
            nextProps.applicantAdmissionList?.applicantAdmission.studName,
          yearDetail:
            nextProps.applicantAdmissionList?.applicantAdmission.yearDetailId,
          scholarshipApplicable:
            nextProps.applicantAdmissionList?.applicantAdmission
              .scholarshipApplicable,
          scholarshipFormSubmit:
            nextProps.applicantAdmissionList?.applicantAdmission
              .scholarshipApplicable === "0"
              ? "0"
              : nextProps.applicantAdmissionList?.applicantAdmission
                  .scholarshipFormSubmit,
          scholarshipType:
            nextProps.applicantAdmissionList?.applicantAdmission
              .scholarshipApplicable === "0"
              ? "0"
              : nextProps.applicantAdmissionList?.applicantAdmission
                  .scholarshipType,
          admissionStatus:
            nextProps.applicantAdmissionList?.applicantAdmission
              .admissionStatus,
          admissionDate: admissionDt,
        });
        this.props
          .getStudentList({
            academicId:
              nextProps.applicantAdmissionList?.applicantAdmission
                .academicYearId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: "Something went wrong",
              });
            }
          });
      }
    }
  }

  componentDidMount() {
    const { getClass, getAcademicYear, applicantAdmissionList } = this.props;
    // const {
    //   // academicYear,
    //   // studentName,
    //   // yearDetail,
    //   // scholarshipApplicable,
    //   // scholarshipType,
    //   // scholarshipFormSubmit,
    //   // admissionStatus,
    // } = this.state;
    getClass().then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });

    this.props.getAdmissionStatus().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });
    if (applicantAdmissionList.isFetch) {
      this.setState({
        detail: true,
        viewPrn: applicantAdmissionList?.applicantAdmission.prn,
        academicYear: applicantAdmissionList?.applicantAdmission.academicYearId,
        studentName: applicantAdmissionList?.applicantAdmission.studentName,
        viewStudentName: applicantAdmissionList?.applicantAdmission.studName,
        yearDetail: applicantAdmissionList?.applicantAdmission.yearDetailId,
        scholarshipApplicable:
          applicantAdmissionList?.applicantAdmission.scholarshipApplicable,
        scholarshipFormSubmit:
          applicantAdmissionList?.applicantAdmission.scholarshipApplicable ===
          "0"
            ? "0"
            : applicantAdmissionList?.applicantAdmission.scholarshipFormSubmit,
        scholarshipType:
          applicantAdmissionList?.applicantAdmission.scholarshipApplicable ===
          "0"
            ? "0"
            : applicantAdmissionList?.applicantAdmission.scholarshipType,
        admissionStatus:
          applicantAdmissionList?.applicantAdmission.admissionStatus,
        // admissionDate: applicantAdmissionList?.applicantAdmission.admissionDate,
      });
      this.props
        .getStudentList({
          academicId: applicantAdmissionList?.applicantAdmission.academicYearId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({
              message: "Something went wrong",
            });
          }
        });
    }
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      this.props.getStudentList({ academicId: newValue }).then((response) => {
        if (!response) {
          this.props.getAlert({
            message: "Something went wrong",
          });
        }
      });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { yearDetail, admissionDate, admissionStatus } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailsError"] = myConstClass.yearDetailMsg;
    }
    if (admissionDate === "" || admissionDate === null) {
      formIsValid = false;
      formErrors["admissionDateError"] = "*Admission date required";
    }

    if (admissionStatus === "" || admissionStatus === null) {
      formIsValid = false;
      formErrors["admissionStatusError"] = myConstClass.admissionStatusMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  ChangedHandlerSearch = (name, newValue) => {
    this.setState({ ...this.state, [name]: newValue });
    if (name == "studentName") {
      this.setState({ detail: true });
    }
  };

  dropDownChangeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = () => {
    if (this.handleFormValidation()) {
      const { applicantRegId } = this.props;
      const {
        academicYear,
        studentName,
        // promotionalAdmissionDetail,
        yearDetail,
        scholarshipApplicable,
        scholarshipType,
        scholarshipFormSubmit,
        admissionStatus,
        admissionDate,
        feeStructuredHeaderId,
        totalApplicableFees,
        paidStatus,
        totalfeesPaid,
      } = this.state;
      let row = [];
      let admissionDateChange = moment(admissionDate).format("DD-MM-YYYY");
      // const newAdmissionStatus = this.props.admissionStatusList?.admissionStatus.filter( ( row ) =>
      // {
      //   return row.id === admissionStatus
      // } )

      let promAdmissionData = {
        yearDetailId: yearDetail,
        scholarshipApplicable: scholarshipApplicable,
        scholarshipType: scholarshipType,
        scholarshipFormSubmit: scholarshipFormSubmit,
        admissionStatus: admissionStatus,
        admissionDate: admissionDateChange,
        feesStructureHeaderId: feeStructuredHeaderId,
        totalApplicableFees: totalApplicableFees,
        paidStatus: paidStatus,
        totalFeesPaid: totalfeesPaid,
        claimNo: 0,
        isActive: 1,
      };
      row.push(promAdmissionData);
      let docs = {
        isActive: 1,
        academicYearId: academicYear,
        userRegistrationId: studentName,
        admissionRegistrationDetails: row,
        setAdmissionFeesDetails: [],
      };
      //Save API from Vijay

      if (applicantRegId === "") {
        // this.props.saveStudentList({ docs });
        apiPost({
          url: endpoints.admissionList,
          postBody: docs,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data saved successfully",
            });
            let msg = "Data saved successfully";
            this.props.onClosed(msg);
            // window.location.replace( "/applicant-admission" );
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      } else {
        this.props
          .updateAdmission({
            id: applicantRegId,
            yearDetailId: yearDetail,
            scholarshipApplicable: scholarshipApplicable,
            scholarshipFormSubmit: scholarshipFormSubmit,
            scholarshipType: scholarshipType,
            admissionStatus: admissionStatus,
            admissionDate: admissionDateChange,
            claimNo: 0,
          })
          .then((response) => {
            if (response) {
              this.props.showNotification({
                msg: "Data updated successfully",
              });
              let msg = "Data updated successfully";
              this.props.onClosed(msg);
              // window.location.replace( "/applicant-admission" );
            } else {
              this.props.getAlert({
                message: "Failed to update",
              });
            }
          });
      }
    }
  };
  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  changeAdmission = (date) => {
    if (date !== null) {
      this.setState({
        admissionDate: date,
      });
    }
  };

  changeHandlerPrn = (event) => {
    const { academicYear, prn } = this.state;
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
    const { showNotification } = this.props;
    if (academicYear !== "" && prn !== "") {
      apiGet({
        url:
          endpoints.prnNumber +
          "/get-user-registration-list-not-in-admission-registration-by-prn?academicYearId=" +
          academicYear +
          "&prn=" +
          prn,
      }).then(({ data, success }) => {
        if (success) {
          if (data.data.errorMessage === null) {
            this.setState({
              studentName: data.data.userRegistration.id,
              detail: true,
            });
          } else if (data.data.errorMessage !== null) {
            showNotification({
              msg: data.data.errorMessage.message,
              severity: "error",
            });
          }
        }
      });
    }
  };

  changedHandler = (event) => {
    this.setState({ scholarshipApplicable: event.target.value });
    if (event.target.value === "0") {
      this.setState({ scholarshipType: "0", scholarshipFormSubmit: "0" });
    }
  };

  scholarshipFormSubmitChangedHandler = (event) => {
    this.setState({ scholarshipFormSubmit: event.target.value });
  };
  onClickProfile = () => {
    if (this.state.studentName !== "" && this.state.studentName !== null) {
      this.props
        .getStudentById({ studId: this.state.studentName })
        .then((response) => {
          if (response) {
            this.setState({ open: true });
          } else {
            this.props.getAlert({
              message: "Something went wrong",
            });
          }
        });
    }
  };
  GoBack = () => {
    this.setState({
      open: false,
    });
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const {
      academicYear,
      studentName,
      viewPrn,
      viewStudentName,
      yearDetail,
      scholarshipApplicable,
      scholarshipType,
      scholarshipFormSubmit,
      admissionStatus,
      admissionDate,
      detail,
      prn,
    } = this.state;
    const { yearDetailsError, admissionDateError, admissionStatusError } =
      this.state.formErrors;
    const {
      academicYearList,
      classList,
      studentListPromotional,
      applicantAdmissionList,
    } = this.props;

    return (
      <>
        {!this.state.open && (
          <Paper sx={{ borderRadius: 2 }} elevation={0}>
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                p={1}
                // columnSpacing={2}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  // columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                  sx={{ marginTop: 1 }}
                >
                  {!detail && (
                    <>
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <Grid item xs={12} sm={6} md={6}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 22 }}
                            label={
                              this.props.applicantRegId === ""
                                ? "Promotion Admission "
                                : "Edit admission information"
                            }
                          />
                          <Grid item xs={12} sm={12} md={12} />
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label={
                              this.props.applicantRegId === "" &&
                              "(for year on year internal admission) "
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={5} />

                        <Grid item xs={12} sm={1} md={1}>
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Back"
                            fullWidth={true}
                            onClick={(e) => {
                              this.props.onClosed("");
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}></Grid>
                    </>
                  )}
                  {detail && (
                    <>
                      <Grid item sx={{ m: -2, mr: 2 }} xs={12} sm={6} md={6}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 22 }}
                          label={
                            this.props.applicantRegId === ""
                              ? "Promotion Admission "
                              : "Edit admission information"
                          }
                        />
                        <br />
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={
                            this.props.applicantRegId === "" &&
                            "(for year on year internal admission) "
                          }
                        />
                      </Grid>
                      <Grid
                        rowSpacing={2}
                        container
                        columnSpacing={1}
                        justifyContent="right"
                        sm={6}
                        md={6}
                        lg={6}
                      >
                        <Grid item sm={12} xs={12} md={1} lg={1}>
                          <MyComponentWithIconProps
                            statusImage={PermIdentityIcon}
                            color="black"
                            fontSize="large"
                            title="Profile"
                            onClick={this.onClickProfile}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}></Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={12}></Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}></Grid>
                <Grid
                  rowSpacing={2}
                  container
                  columnSpacing={1}
                  justifyContent="right"
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ mr: 2 }}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Academic Year"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={10}>
                    <AutoComplete
                      keyColName={"id"}
                      value={academicYear}
                      name={"academicYear"}
                      options={academicYearList?.academicYear}
                      onChange={this.ChangeHandlerSearch}
                      fullWidth
                      disabled={this.props.applicantRegId === "" ? false : true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}></Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid sx={{ ml: -1 }} item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Year Details"
                    />
                  </Grid>

                  <Grid sx={{ ml: -1 }} item xs={12} sm={12} md={10}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={classList?.class.filter(
                        (data) => data.isActive == 1
                      )}
                      onChange={this.ChangedHandlerSearch}
                      disabled={this.props.applicantRegId === "" ? false : true}
                      isError={yearDetailsError ? true : false}
                      errorText={yearDetailsError ? yearDetailsError : " "}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}></Grid>
                <Grid
                  // rowSpacing={2}
                  container
                  columnSpacing={1}
                  justifyContent="right"
                  sm={12}
                  md={12}
                  lg={12}
                  // sx={{ mr: 2 }}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    {/* <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Student Name"
                    /> */}

                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="PRN"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={10}>
                    {/* {this.props.applicantRegId === '' &&
                      <AutoComplete
                        keyColName={"id"}
                        value={studentName}
                        name={"studentName"}
                        options={studentListPromotional.studentList}
                        onChange={this.ChangedHandlerSearch}
                        // disabled={
                        //   this.props.applicantRegId === undefined ? false : true
                        // }
                        fullWidth
                      />
                    }
                    {this.props.applicantRegId !== '' &&
                      <TextField
                        color="primary"
                        name="viewStudentName"
                        size="small"
                        value={viewStudentName}
                        onChange={this.changedHandler}
                        fullWidth
                        disabled={true}
                        helperText={' '}
                      />
                    } */}

                    {this.props.applicantRegId === "" && (
                      <TextField
                        color="primary"
                        name="prn"
                        size="small"
                        value={prn}
                        fullWidth
                        onBlur={this.changeHandlerPrn}
                        onChange={this.changedHandler}
                        helperText={" "}
                        disabled={
                          this.props.applicantRegId === "" ? false : true
                        }
                      />
                    )}

                    {this.props.applicantRegId !== "" && (
                      <TextField
                        color="primary"
                        name="viewPrn"
                        size="small"
                        value={viewPrn}
                        fullWidth
                        onBlur={this.changeHandlerPrn}
                        onChange={this.changedHandler}
                        helperText={" "}
                        disabled={
                          this.props.applicantRegId === "" ? false : true
                        }
                      />
                    )}

                    {/* <TextField
                      color="primary"
                      name="prn"
                      size="small"
                      value={prn}
                      fullWidth
                      onBlur={this.changeHandlerPrn}
                      onChange={this.changedHandler}
                      helperText={" "}
                      disabled={
                        this.props.applicantRegId === '' ? false : true
                      }
                    /> */}
                  </Grid>
                </Grid>
                {detail && (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        columnSpacing={3}
                        container
                        sx={{ mb: 2 }}
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={3}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Scholarship Applicable"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={9}>
                          <FormControl component="fieldset" sx={{ m: -1 }}>
                            <RadioGroup
                              row
                              aria-label="scholarship"
                              id="scholarshipApplicable"
                              name="scholarshipApplicable"
                              value={scholarshipApplicable}
                              size="small"
                              onChange={this.changedHandler}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {scholarshipApplicable === "1" && (
                        <>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            columnSpacing={3}
                            rowSpacing={3}
                            container
                            justifyContent="space-evenly"
                            sx={{ mb: 2 }}
                          >
                            <Grid item xs={12} sm={12} md={2}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="Scholarship Type"
                              />
                            </Grid>

                            <Grid m={-1} item xs={12} sm={12} md={4}>
                              <FormControl color={"primary"} fullWidth={true}>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  className="text-black"
                                  name="scholarshipType"
                                  id="scholarshipType"
                                  value={scholarshipType}
                                  onChange={this.dropDownChangeHandler}
                                  size="small"
                                  fullWidth={true}
                                >
                                  <MenuItem value={1}>EBC</MenuItem>
                                  <MenuItem value={2}>Freeship</MenuItem>
                                  <MenuItem value={3}>Scholarship</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="Scholarship Form Submit"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={3}>
                              <FormControl component="fieldset" sx={{ m: -1 }}>
                                <RadioGroup
                                  row
                                  aria-label="scholarship"
                                  id="scholarshipFormSubmit"
                                  name="scholarshipFormSubmit"
                                  value={scholarshipFormSubmit}
                                  size="small"
                                  onChange={
                                    this.scholarshipFormSubmitChangedHandler
                                  }
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        columnSpacing={2}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={4}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Admission Status"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={8}>
                            <AutoComplete
                              keyColName={"id"}
                              value={admissionStatus}
                              name={"admissionStatus"}
                              options={this.props.admissionStatusList?.admissionStatus.filter(
                                (data) => data.isActive == 1 && data.id != "6"
                              )}
                              onChange={this.ChangeHandlerSearch}
                              isError={admissionStatusError ? true : false}
                              errorText={
                                admissionStatusError
                                  ? admissionStatusError
                                  : " "
                              }
                            />
                            {/* <FormControl color={"primary"} fullWidth={true}>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                className="text-black"
                                name="admissionStatus"
                                id="admissionStatus"
                                value={admissionStatus}
                                onChange={this.dropDownChangeHandler}
                                size="small"
                                fullWidth={true}
                              >
                                <MenuItem value={1}>Confirmed</MenuItem>
                                <MenuItem value={2}>Year Drop</MenuItem>
                                <MenuItem value={3}>Cancelled</MenuItem>
                                <MenuItem value={4}>Detained</MenuItem>
                                <MenuItem value={5}>Provisional</MenuItem>

                              </Select>
                            </FormControl>
                            <FormHelperText error>{admissionStatusError ? admissionStatusError : ' '}</FormHelperText> */}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={4}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Admission Date"
                            />
                          </Grid>

                          <Grid sx={{ ml: -2 }} item xs={12} sm={12} md={8}>
                            <BasicDatePicker
                              name="castecertdate"
                              value={admissionDate}
                              onChange={this.changeAdmission}
                              isError={admissionDateError ? true : false}
                              errorText={
                                admissionDateError ? admissionDateError : " "
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container columnSpacing={1} justifyContent="right">
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={1.1}
                        lg={1.1}
                        justifyContent="space-evenly"
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="outlined"
                          name="Cancel"
                          fullWidth={true}
                          onClick={(e) => {
                            this.props.onClosed("");
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={1.5}
                        lg={1.5}
                        justifyContent="space-evenly"
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Save"
                          fullWidth={true}
                          onClick={this.submitHandler}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        )}
        {this.state.open && <StudentPreview1 GoBack={this.GoBack} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
  academicYearList: state.academicYear,
  classList: state.class,
  applicantAdmissionList: state.applicantAdmission,
  admissionStatusList: state.admissionStatus,
  studentListPromotional: state.promotionalAdmission,
});

const mapDispatchToProps = {
  getStudentById,
  getClass,
  getAcademicYear,
  getStudentList,
  updateAdmission,
  saveStudentList,
  showNotification,
  getAlert,
  resetError,
  checkPrn,
  getAdmissionStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionalAdmissionForm);
