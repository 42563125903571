import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
const initialState = {
  yearDetails: [],
  isFetch: false,
  studentList: [],
  isFetchStudentList: false,
  distinctDivision: [],
  isFetchDistinctDivision: false,
  internshipDeclaration: [],
  isInternshipDeclareFetch: false,
};
let URL = endpoints.internshipDeclare;
let URL1 = endpoints.division;
let URL2 = endpoints.department;
const departmentId = localStorage.getItem("departmentId");
const internshipDeclaration = createSlice({
  name: "internshipDeclaration",
  initialState,
  reducers: {
    yearDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearDetails: row,
        isFetch: true,
      };
    },
    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentList: row,
        isFetchStudentList: true,
      };
    },
    distinctDivisionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        distinctDivision: row,
        isFetchDistinctDivision: true,
      };
    },

    internshipDeclareSuccess: (state = cloneDeep(initialState), action) => {
      const { internshipDeclareData } = action.payload;
      return {
        ...state,
        internshipDeclaration: internshipDeclareData,
        isInternshipDeclareFetch: true,
      };
    },
  },
});

export const {
  internshipDeclareSuccess,
  yearDetailsSuccess,
  studentListSuccess,
  distinctDivisionSuccess,
} = internshipDeclaration.actions;

export default internshipDeclaration.reducer;

export const getYearDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL2 +
        "/get-year-detail-by-department-of-user?departmentIds=" +
        departmentId,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name:
              data1.year.programType.name +
              " - " +
              data1.programTitle.brName +
              " - " +
              data1.year.className,
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(yearDetailsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStudentList =
  ({ yearDetails, qualification, divisionIds, gapInYears, mathsCriteria }) =>
  async (dispatch) => {
    console.log(yearDetails);
    console.log(qualification);
    try {
      const response = apiPost({
        url:
          URL +
          "/get-eligible-student?yearDetailIds=" +
          yearDetails +
          "&divisionIds=" +
          divisionIds +
          "&gapInYears=" +
          gapInYears +
          "&mathsCriteria=" +
          mathsCriteria,
        postBody: qualification,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              lastName: data1.lastName,
              firstName: data1.firstName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              name:
                data1.lastName.toUpperCase() +
                " " +
                data1.firstName.toUpperCase() +
                " " +
                (data1.fatherName == null || data1.fatherName == ""
                  ? " - "
                  : data1.fatherName.toUpperCase()) +
                " " +
                (data1.motherName == null || data1.motherName == ""
                  ? " - "
                  : data1.motherName.toUpperCase()),
              yearDetails: data1.capround,
              division: data1.meritNo,
              rollNo: data1.birthPlace,
            };
            return bData;
          });
          dispatch(studentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDistinctDivision =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + yearDetail + "/get-division-by-year-detail-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              yearDetailId: data1.yearDetailId,
              name: data1.name,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(distinctDivisionSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
// 63a65bee - 84e5 - 4804 - 9f35 - 4cac4c9d3bf5
export const getInternshipDeclareById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/by-id",
      }).then(({ data, success }) => {
        if (success) {
          let list = data.data;
          let index = 0;
          let division =
            list.getDivisions === null
              ? ""
              : list.getDivisions.map((divisionData) => {
                  return divisionData.name;
                });
          let internshipDeclareData = {
            id: list.id === null ? "" : list.id,
            companyName: list.company.name === null ? "" : list.company.name,
            internshipType:
              list.internshipType.name === null ? "" : list.internshipType.name,
            fromDate: list.fromDate === null || "" ? "N/A" : list.fromDate,
            toDate: list.toDate === null || "" ? "N/A" : list.toDate,
            urlLink: list.urlLink == "" || null ? "N/A" : list.urlLink,
            yearDetailId: list.yearDetailId === null ? "" : list.yearDetailId,
            interviewMode:
              list.interviewMode === null
                ? ""
                : list.interviewMode === 0
                ? "Offline"
                : "Online",
            stipend: list.stipend === null || "" ? "N/A" : list.stipend,
            designation:
              list.designation == "" || null ? "N/A" : list.designation,
            declareDate: list.declareDate === null ? "" : list.declareDate,
            durationFromDate:
              list.durationFromDate === null || ""
                ? "N/A"
                : list.durationFromDate,
            durationToDate:
              list.durationToDate === null || "" ? "N/A" : list.durationToDate,
            remark: list.remark == "" || null ? "N/A" : list.remark,
            fromTime:
              list.fromTime == null || list.fromTime == ""
                ? "N/A"
                : list.fromTime,
            toTime:
              list.toTime == null || list.toTime == "" ? "N/A" : list.toTime,
            isBacklogAllowed: list.isBacklogAllowed === 0 ? "No" : "Yes",
            backlog:
              list.backlog == null || list.backlog == "" ? "N/A" : list.backlog,
            isQualificationCriteriaRequired:
              list.isQualificationCriteriaRequired == 1 ? "Yes" : "No",
            isActive: list.isActive === null ? "" : list.isActive,
            venue: list.venue == null || list.venue == "" ? "N/A" : list.venue,
            divisionId: list.divisionId === null ? "" : list.divisionId,
            yearDetails:
              list.getYearDetail.year.programType.name +
              " " +
              list.getYearDetail.programTitle.brName +
              " " +
              list.getYearDetail.year.className,
            division: division.join(),
            internshipEligibleStudent: list.internshipEligibleStudent.map(
              (internshipEligibleStudent) => {
                let docs = {
                  lastName:
                    internshipEligibleStudent.getUserRegistration !== null &&
                    internshipEligibleStudent.getUserRegistration.lastName.toUpperCase(),
                  firstName:
                    internshipEligibleStudent.getUserRegistration !== null &&
                    internshipEligibleStudent.getUserRegistration.firstName.toUpperCase(),
                  fatherName:
                    internshipEligibleStudent.getUserRegistration !== null &&
                    internshipEligibleStudent.getUserRegistration.fatherName.toUpperCase(),
                  motherName:
                    internshipEligibleStudent.getUserRegistration !== null &&
                    internshipEligibleStudent.getUserRegistration.motherName.toUpperCase(),
                  middleName:
                    internshipEligibleStudent.getUserRegistration !== null &&
                    internshipEligibleStudent.getUserRegistration.middleName.toUpperCase(),
                  studentName:
                    internshipEligibleStudent.getUserRegistration !== null
                      ? internshipEligibleStudent.getUserRegistration.lastName.toUpperCase() +
                        " " +
                        internshipEligibleStudent.getUserRegistration.firstName.toUpperCase() +
                        " " +
                        (internshipEligibleStudent.getUserRegistration
                          .fatherName == null ||
                        internshipEligibleStudent.getUserRegistration
                          .fatherName == ""
                          ? " - "
                          : internshipEligibleStudent.getUserRegistration.fatherName.toUpperCase()) +
                        " " +
                        (internshipEligibleStudent.getUserRegistration
                          .motherName == null ||
                        internshipEligibleStudent.getUserRegistration
                          .motherName == ""
                          ? " - "
                          : internshipEligibleStudent.getUserRegistration.motherName.toUpperCase())
                      : "-",
                  rollNo: internshipEligibleStudent.rollNo
                    ? internshipEligibleStudent.rollNo
                    : "-",
                  division:
                    internshipEligibleStudent.getReportingDetail !== null
                      ? internshipEligibleStudent.getReportingDetail.division
                          .name
                      : "-",
                };
                console.log(internshipEligibleStudent);
                return docs;
              }
            ),

            internshipCriteria: list.internshipCriteria.map(
              (internshipCriteria) => {
                let docs = {
                  index: index + 1,
                  qualificationName: internshipCriteria.getQualification.name,
                  minimumMarks: internshipCriteria.minimumMarks,
                };
                console.log(internshipCriteria);
                return docs;
              }
            ),
          };

          console.log(internshipDeclareData);
          dispatch(internshipDeclareSuccess({ internshipDeclareData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
