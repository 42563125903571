import endpoint from "../config/endpoints";

export const adminInnovativeTeachingJson = {
  apiBaseURL: endpoint.innovative,
  screenTitle: "Innovative Teaching",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Innovative Teaching",
  showExcelDownload: true,
  excelFileName: "Innovative Teaching",
  baseIdColumn: "id",
  DocumentUpload: "employeeInnovativeTeachingDocument",
  dataSetToAdd: [
    {
      label: "Department",
      value: "departmentId",
      masterName: "departmentMaster",
      isRootLevelKey: false,
    },
    {
      label: "Teacher",
      value: "userRegistration",
      masterName: "teacherMaster",
      isRootLevelKey: true,
    },
  ],
  searchList: [
    {
      label: "Department",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "departmentMaster",
      dataKey: "departmentId",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "departmentId",
    },
    {
      label: "Teacher",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "teacherMaster",
      dataKey: "userRegistration",
      isRootLevelKey: false,
      isMandatory: true,
      getListFrom: "departmentId",
    },
  ],
  fieldMeta: [
    {
      label: "Activity",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "employeeActivity",
      isRootLevelKey: false,
      isMandatory: true,
    },
    // {
    //   label: "Date",
    //   controlType: "datepicker",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "date",
    //   isMAxDate: false,
    //   maxDate: new Date(),
    //   isMinDate: false,
    //   minDate: new Date(),
    //   isMandatory: true,
    // },
    {
      label: "Subject",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "subjectIds",
      isMandatory: true,
    },
    {
      label: "Activity Details",
      controlType: "textarea",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "activityDetail",
      isMandatory: true,
    },
  ],
};
