import React from "react";
import { Paper } from "@mui/material";

import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import PortalInfo from "../../components/Comman/PortalInfo";
import { ApplicationListColumns } from "../../tableColumns/table-columns";

class PortalInformation extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      acadamicYearId: "",
    };
  }

  componentDidMount() {}

  render() {
    const { acadamicYearId } = this.state;
    return (
      <>
        <div>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <PortalInfo
              showPdfDownload={true}
              showExcelDownload={true}
              showSearchButton={true}
              pdfFileName={"pdf"}
              excelFileName={"pdf"}
              autoCompleteName={"pdf"}
              onChange={"pdf"}
              options={[]}
              value={acadamicYearId}
            ></PortalInfo>
          </Paper>

          <br />
          <br />

          <Paper>
            <RTTable
              columns={ApplicationListColumns}
              tableData={[]}
              rowEdit={this.rowEdit}
              rowDelete={this.rowDelete}
              onChangeStatus={this.onChangeStatus}
            />
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PortalInformation);
