import endpoint from "../config/endpoints";

export const courseAttendedJson = {
  apiBaseURL: endpoint.courseAttended,
  screenTitle: "Course Attended",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Activity",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "employeeActivity",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Duration",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "duration",
      isMandatory: true,
    },
    {
      label: "Activity Details",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "details",
      isMandatory: true,
    },
  ],
};
