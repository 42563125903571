import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { getAlert } from "../../../CommonActions/alert.slice";
import SearchAppliedList from "../../../components/SearchTable";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { ButtonCompo } from "../../../components/Comman/Button";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import AttachFile from "../../../components/Comman/AttachFileForMultiSelect";
import { Loading1 } from "../../../components/Loading1";
import { VideoInput } from "../../../components/SelectVideo";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import {
  getDeemedCommittee,
  getDeemedCommitteeMember,
  getDeemedCommitteeMemberWithStatus,
} from "../Committee/committee.slice";
import { getDeemedMeetingById } from "./meeting.slice";
import moment from "moment";
import { apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";
import MeetingPopup from "./meetingPopup";
import { saveVideo, downloadVideo } from "../../UploadFile/file.slice";

import { getMeetingDocument, getMailBodyAndSub } from "./meeting.slice";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "title",
    title: "Title",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "name",
    title: "Committee",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "date",
    title: "Date",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
  },
  {
    name: "time",
    title: "Time",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "40%",
  },
];

const MeetingTable = ({
  getDeemedCommittee,
  getAlert,
  deemedCommitteeList,
  openForm,
  onSave,
  deemedMeetingList,
  onEdit,
  onDelete,
  onDetails,
  showNotification,
  rowViewData,
  showView,
  getDeemedMeetingById,
  showPdfData,
  getMeetingDocument,
  getMailBodyAndSub,
  downloadVideo,
  fileData,
  getDeemedCommitteeMemberWithStatus,
}) => {
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const [updateRow, setUpdateRow] = useState(false);
  const [interestedStudent, setInterestedStudent] = useState(true);
  const [yearDetail, setYearDetail] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fileName, setFileName] = useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [loader, setLoader] = useState(false);

  const [meetingPopup, setMeetingPopup] = useState(false);
  const [meetingData, setMeetingData] = useState("");
  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveHandler = (data) => {
    let dateFromChange = moment(fromDate).format("YYYY-MM-DD");
    let dateToChange = moment(toDate).format("YYYY-MM-DD");

    let docs = {
      committee: yearDetail,
      fromDate: dateFromChange,
      toDate: dateToChange,
      value: value,
    };

    if (checkValidation()) {
      onSave(docs);
    }
  };

  const openFormHandler = () => {
    openForm();
  };

  const editInfo = (data) => {
    onEdit(data);
  };

  const deleteInfo = (data) => {
    onDelete(data);
  };

  const rowDetails = (data) => {
    console.log(data);
    onDetails(data);
    getDeemedMeetingById({ id: data.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
      getDeemedCommitteeMemberWithStatus({ id: data.committee.id }).then(
        (response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        }
      );
    });
  };

  const rowViewDetails = (data) => {
    rowViewData(data);
    getDeemedMeetingById({ id: data.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["committeeError"] = myConstClass.committeeMsg;
    }

    if (fromDate === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate === "") {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (toDate < fromDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      setYearDetail(newValue);
    } else {
      setYearDetail("");
    }
  };

  const changeFromDate = (date) => {
    if (date !== null) {
      setFromDate(date);
    }
  };

  const changeToDate = (date) => {
    if (date !== null) {
      setToDate(date);
    }
  };

  useEffect(() => {
    getDeemedCommittee().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const changeH = (fileName, name, index) => {
    setFileName(fileName.target.files);
  };

  const handleClose = () => {
    setMeetingPopup(false);
  };

  const rowView = async (data) => {
    setLoader(true);
    console.log(data);
    await getMeetingDocument({ id: data.id, ext: "1" }).then((response) => {
      if (!response) {
        setLoader(false);

        getAlert({ message: "Something went wrong" });
      } else {
        setMeetingData(data);
        setMeetingPopup(true);
        setLoader(false);
      }
    });
    setLoader(true);
    await getMailBodyAndSub({ id: data.id }).then((response) => {
      if (!response) {
        setLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setLoader(false);
      }
    });
  };
  const sendMailHandler = (row, rowData) => {
    console.log(rowData);
    let dataToSave = row.map((data1) => {
      let data = {
        path: data1.fileName,
        name: data1.showName,
        ext: "1",
        scheduleCommitteeMeeting: {
          id: meetingData.id,
        },
      };
      data = {
        ...data,
        id: data1.id,
      };

      return data;
    });

    swal({
      title: "Are you sure?",
      text: "Do You want to send alert to members",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        setLoader(true);
        apiPost({
          url:
            endpoint.deemedMeeting +
            "/" +
            meetingData.id +
            "/send-meeting-alerts?mailMsg=" +
            rowData.mailMsg +
            "&subjectMail=" +
            rowData.subjectMail,
          postBody: row,
        }).then(({ success }) => {
          if (success) {
            apiPost({
              url: endpoint.meetingDocument + "/save-all",
              postBody: dataToSave,
            }).then(({ data, success }) => {
              if (success) {
                setLoader(false);
                showNotification({ msg: "Alert sent successfully" });
                setMeetingPopup(false);
              } else {
                showNotification({
                  msg: "Failed to send ",
                  severity: "error",
                });
                setLoader(false);
              }
            });
          } else {
            showNotification({
              msg: "Failed to save ",
              severity: "error",
            });
            setLoader(false);
          }
        });
      }
    });
  };

  return (
    <>
      {interestedStudent && (
        <div>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Committee Meeting"
              />
            </Grid>
          </Paper>
          <br />
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid container item xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={10} md={10}>
                {/* <SearchAppliedList
                  onSearch={onSearch}
                  searchValue={searchValue}
                /> */}
              </Grid>
              <Grid item container justifyContent="right" xs={12} sm={2} md={2}>
                <MyComponentWithIconProps
                  statusImage={AddCircleIcon}
                  s
                  color="primary"
                  fontSize="large"
                  title="Add"
                  onClick={openFormHandler}
                />
              </Grid>
            </Grid>
          </Paper>
          <br />
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handledChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={"Scheduled " + deemedMeetingList.deemedMeeting.length}
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    "History " + deemedMeetingList.deemedMeetingHistory.length
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
              sx={{ margin: 2 }}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
                // sx={{ marginTop: 2 }}
                spacing={2}
              >
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Committee "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={deemedCommitteeList.deemedCommittee.filter(
                          (row) => row.isActive === 1
                        )}
                        onChange={ChangeHandlerSearch}
                        isError={formErrors.committeeError ? true : false}
                        errorText={
                          formErrors.committeeError
                            ? formErrors.committeeError
                            : " "
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={changeFromDate}
                        isError={formErrors.fromDateError ? true : false}
                        errorText={
                          formErrors.fromDateError
                            ? formErrors.fromDateError
                            : " "
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={changeToDate}
                        isError={formErrors.toDateError ? true : false}
                        errorText={
                          formErrors.toDateError ? formErrors.toDateError : " "
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label=" "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={saveHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TabPanel value={value} index={0}>
              <DynamicTable
                data={deemedMeetingList.deemedMeeting}
                tableHead={columns}
                showPegination={false}
                rowDelete={deleteInfo}
                rowEdit={editInfo}
                rowDetails={rowDetails}
                rowView={rowView}
                rowViewData={rowViewDetails}
                showHeadEdit={true}
                showHeadDelete={true}
                showDetails={true}
                showApply={true}
                showView={true}
                showHeadPdf={true}
                alertMail={true}
                pdfDetailsId={showPdfData}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DynamicTable
                data={deemedMeetingList.deemedMeetingHistory}
                tableHead={columns}
                showPegination={false}
                rowDelete={deleteInfo}
                rowDetails={rowDetails}
                rowEdit={editInfo}
                rowViewData={rowViewDetails}
                showHeadEdit={true}
                showHeadDelete={true}
                showView={true}
                // showDetails={true}
                showHeadPdf={true}
                pdfDetailsId={showPdfData}
              />
            </TabPanel>
          </Paper>
        </div>
      )}
      {meetingPopup && (
        <MeetingPopup
          open={meetingPopup}
          handleClose={handleClose}
          onSave={sendMailHandler}
          meetingData={meetingData}
        />
      )}
      {loader && <Loading1 />}
    </>
  );
};

const mapStateToProps = (state) => ({
  deemedMeetingList: state.deemedMeeting,
  deemedCommitteeList: state.deemedCommittee,
  fileData: state.file,
});

const mapDispatchToProps = {
  getDeemedCommittee,
  getAlert,
  showNotification,
  getDeemedMeetingById,
  getMeetingDocument,
  saveVideo,
  getMailBodyAndSub,
  downloadVideo,
  getDeemedCommitteeMemberWithStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(MeetingTable);
