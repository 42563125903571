export const rollListJson = {
  screenTitle: "Roll Number List",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getAcadamicYear: true,
  getListId: 'acadamicYear,yearDetail',
  fieldMeta: [
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "yearMaster",
      getListId: 'yearDetail,acadamicYear',
      dataKey: "yearDetail",
      isMandatory: true,
    },
    // {
    //   label: "Academic Year",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "acadamicMaster",
    //   getListId:'acadamicYear,yearDetail',
    //   dataKey: "acadamicYear",
    //   isMandatory: true,
    // },
    {
      label: "Semester Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      getListFrom: 'acadamicYear,yearDetail',
      masterName: "semisterMaster",
      dataKey: "semister",
      isMandatory: true,
    },
    {
      label: "Division",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "divisionMaster",
      getListFrom: 'yearDetail',
      dataKey: "division",
      isMandatory: true,
    },
  ],
};
