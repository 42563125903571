import CancelIcon from "@mui/icons-material/Cancel";
import MoreIcon from "@mui/icons-material/MoreVert";
import PendingIcon from "@mui/icons-material/Pending";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import VerifiedIcon from "@mui/icons-material/Verified";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Menu } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
function Row({
  row,
  rowStatusPending,
  rowStatusCancel,
  rowViewData,
  rowStatusVerified,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickView = (e, id) => {
    e.stopPropagation();
    rowViewData(id);
  };

  const rowVerified = (e, id) => {
    e.stopPropagation();
    rowStatusVerified(id);
  };
  const rowPending = (e, id) => {
    e.stopPropagation();
    rowStatusPending(id);
  };
  const rowCancel = (e, id) => {
    e.stopPropagation();
    rowStatusCancel(id);
  };

  React.useEffect(() => {});
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="td" scope="row">
          <center> {row.index}</center>
        </TableCell>
        <TableCell>{row.applicationNumber}</TableCell>
        <TableCell>{row.studentName}</TableCell>
        <TableCell>{row.branch}</TableCell>
        <TableCell>{row.phno}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.remark}</TableCell>
        <TableCell>
          &nbsp; &nbsp;
          <MyComponentWithIconProps
            statusImage={VisibilityIcon}
            color="primary"
            fontSize="medium"
            title="View"
            onClick={(e) => onClickView(e, row.userApplicationId)}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <IconButton
            onClick={handleClick}
            sx={{ marginTop: -2 }}
            aria-controls={openMenu ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <MoreIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={(e) => rowVerified(e, row.id)}>
              <MyComponentWithIconProps
                statusImage={VerifiedIcon}
                color="success"
                fontSize="medium"
                title="Verified"
                onClick={(e) => rowVerified(e, row.id)}
              />
              &nbsp; Verified
            </MenuItem>
            <MenuItem onClick={(e) => rowPending(e, row.id)}>
              <MyComponentWithIconProps
                statusImage={PendingIcon}
                color="primary"
                fontSize="medium"
                title="pending"
              />
              &nbsp; Pending
            </MenuItem>
            <MenuItem onClick={(e) => rowCancel(e, row.id)}>
              <MyComponentWithIconProps
                statusImage={CancelIcon}
                color="blue"
                fontSize="medium"
                title="Cancel"
              />
              &nbsp; Cancel
            </MenuItem>
          </Menu>
          &nbsp;&nbsp;
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const VerificationDocumentTable = ({
  rowStatusPending,
  rowStatusCancel,
  rowStatusVerified,
  documentVerificationList,
  rowViewData,
  rowList,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>
              <center>Sr. No.</center>
            </TableCell>
            <TableCell align="left">Application Id</TableCell>
            <TableCell align="left">Student Name</TableCell>
            <TableCell align="left">Program Title</TableCell>
            <TableCell align="left">Phone Number</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Remark</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowList.map((row) => (
            <Row
              row={row}
              key={row.id}
              rowStatusPending={rowStatusPending}
              rowStatusCancel={rowStatusCancel}
              rowStatusVerified={rowStatusVerified}
              rowViewData={rowViewData}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationDocumentTable);
