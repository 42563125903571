import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { scheme } from '../../../../DynamicFormsJson/Scheme'
import { SchemeColumns } from "../../../../tableColumns/table-columns";
import { getScheme } from "./scheme.slice"
import { getAlert } from "../../../../CommonActions/alert.slice";

class Scheme extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        const { getScheme } = this.props;
        getScheme().then((response) => {
            if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
            }
        });
    }

    render() {
        const { schemeDataList } = this.props;

        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={scheme.screenTitle}
                        fieldMeta={scheme.fieldMeta}
                        showPdfDownload={scheme.showPdfDownload}
                        showExcelDownload={scheme.showExcelDownload}
                        pdfFileName={scheme.pdfFileName}
                        excelFileName={scheme.excelFileName}
                        showAddButton={scheme.showAddButton}
                        tableColumnsToFilter={scheme.tableColumnsToFilter}
                        tableColumns={SchemeColumns}
                        tableData={schemeDataList?.scheme}
                        getTableData={this.props.getScheme}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={schemeDataList.isFetch}
                        baseIdColumn={scheme.baseIdColumn}
                        apiBaseURL={scheme.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    schemeDataList: state.scheme
});
const mapDispatchToProps = {
    getScheme, getAlert
}
export default connect(mapStateToProps, mapDispatchToProps)(Scheme);