import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
import {
  default as endpoint,
  default as endpoints,
} from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiDelete, apiGet } from "../../utils/api_service";
import {
  getApplicantAdmission,
  getPendingFees,
  getTransactionDetailById,
  getTransactionList,
  saveApplicantFee,
} from "../ApplicantAdmission/applicantAdmission.slice";
import {
  getStudentByApplicationId,
  getStudentById,
} from "../BasicDetailsPost/basicDetails.slice";
import { getFeesStructureHeadById } from "../Masters/Admission/FeeStructureHead/feeStructureHead.slice";
import { downloadF } from "../PDF/PDFDownload";
import { getAddFeesStructureHeader } from "../StudentFeesCollection/FeesCollection.slice";
import StudentPreview from "../StudentPreview1/index";
import AddDebitTransaction from "./AddDebitTransaction";
import AddFeesStruture from "./AddFeesStruture";
import EditTransaction from "./EditTransaction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AccountFees = ({
  getApplicantAdmission,
  getAddFeesStructureHeader,
  getPendingFees,
  applicationData,
  applicantAdmissionData,
  getFeesStructureHeadById,
  feesStructureHeaderByIdData,
  PendingListData,
  getTransactionList,
  getTransactionDetailById,
  //feesTotal,
  saveApplicantFee,
  getAlert,
  // totalFees,
  onCancel,
  onCloseTab,
  getStudentById,
  studentFeeData,
}) => {
  let [count, setCount] = useState();
  const [feesTotal, setFeesTotal] = useState("");
  const [type, setType] = useState("0");
  let [total, setTotal] = useState(0);
  let [total1, setTotal1] = useState(0);
  let [total11, setTotal11] = useState(0);
  let [tatalFees, setTatalFees] = useState("");
  let [nameError, setNameError] = useState(false);
  let [feeId, setFeeId] = useState("");
  let [dataToSave, setDataToSave] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDebit, setOpenDebit] = useState(false);
  const [openTransaction, setOpenTransaction] = useState(false);
  const [pendingAmt, setPendingAmt] = useState(0);
  const [totalFeesPaid, setTotalFeesPaid] = useState(0);
  const [totalFeesPending, setTotalFeesPending] = useState(0);
  const [currentYearFees, setCurrentYearFees] = useState(0);
  const [amountRefundFees, setAmountRefundFees] = useState(0);
  const [debitValidation, setDebitValidation] = useState(0);
  const [yearDetail, setYearDetail] = useState("");
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [number, setNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [remark, setRemark] = useState("");
  const [docsToAddStructure, setDocsToAddStructure] = useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [studentData, setStudentData] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [feesError, setFeesError] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onDebitTransaction = () => {
    setOpenDebit(true);
    setStudentData(applicationData);
    let tData =
      total -
      amountRefundFees -
      feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees;
    setDebitValidation(tData);
  };

  const onTransaction = (e, row) => {
    getTransactionDetailById({
      transactionId: row.id,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        setOpenTransaction(true);
      }
    });
  };

  const changeFromDate = (date) => {
    setTransactionDate(date);
  };

  const onCloseDebit = () => {
    setOpenDebit(false);
    setOpenTransaction(false);
  };

  const getSuccess = (feesStructureHeader) => {
    setLoading(true);
    let amount = 0;
    let amountRefund = 0;
    getAddFeesStructureHeader({
      admissionRegistrationDetailId: applicationData.id,
      feesStructureId: feesStructureHeader,
    }).then((response) => {
      if (response) {
        setLoading(false);
        setOpenDebit(false);
        setOpenDialog(false);
        getApplicantAdmission({ id: applicationData.id }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
          if (response) {
            if (
              response.data.admissionRegistration.getUserRegistration !== null
            ) {
              getPendingFees({
                id: response.data.admissionRegistration.getUserRegistration.id,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
              });

              getFeesStructureHeadById({
                feesStructureHeadId: response.data.feesStructureHeaderId,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
              });

              getTransactionList({
                userRegistrationId:
                  response.data.admissionRegistration.getUserRegistration.id,
                academicYearId:
                  response.data.admissionRegistration.academicYearId,
                admissionRegistrationDetailId: applicationData.id,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                } else {
                  response.data.map((feeDetail) => {
                    if (feeDetail.transactionType == 0) {
                      amount = amount + feeDetail.amt;
                    } else {
                      amountRefund = amountRefund + feeDetail.amt;
                    }
                    return feeDetail;
                  });
                  //  setTotal(0);
                  /*  if(total===0)
              { */
                  setTotal(amount);
                  setAmountRefundFees(amountRefund);
                }
              });
            }
          }
        });
      } else {
        setLoading(false);
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  const onClickDelete = (e, row) => {
    let amount = 0;
    let amountRefund = 0;
    swal({
      title: "Are you sure?",
      text: "Do you want to delete transaction?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        setLoading(true);
        apiDelete({
          url: endpoint.receiptList + "/" + row.id,
        }).then(({ success }) => {
          if (success) {
            setLoading(false);

            getApplicantAdmission({ id: applicationData.id }).then(
              (response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
                if (response) {
                  if (
                    response.data.admissionRegistration.getUserRegistration !==
                    null
                  ) {
                    getPendingFees({
                      id: response.data.admissionRegistration
                        .getUserRegistration.id,
                    }).then((response) => {
                      if (!response) {
                        getAlert({ message: "Something went wrong" });
                      }
                    });

                    getFeesStructureHeadById({
                      feesStructureHeadId: response.data.feesStructureHeaderId,
                    }).then((response) => {
                      if (!response) {
                        getAlert({ message: "Something went wrong" });
                      }
                    });

                    getTransactionList({
                      userRegistrationId:
                        response.data.admissionRegistration.getUserRegistration
                          .id,
                      academicYearId:
                        response.data.admissionRegistration.academicYearId,
                      admissionRegistrationDetailId: applicationData.id,
                    }).then((response) => {
                      if (!response) {
                        getAlert({ message: "Something went wrong" });
                      } else {
                        response.data.map((feeDetail) => {
                          if (feeDetail.transactionType == 0) {
                            amount = amount + feeDetail.amt;
                          } else {
                            amountRefund = amountRefund + feeDetail.amt;
                          }
                          return feeDetail;
                        });
                        //  setTotal(0);
                        /*  if(total===0)
                  { */
                        setTotal(amount);
                        setAmountRefundFees(amountRefund);
                      }
                    });
                  }
                }
              }
            );
          } else {
            setLoading(false);
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  const onClose = () => {
    setOpenDialog(false);
  };

  const openDeclareForm = () => {
    // "00a943df-503a-4dc4-9039-05b264ac3618"

    // getFeesStructureHeadById({
    //   feesStructureHeadId: feeId,
    // }).then((response) => {
    //   if (!response) {
    //     getAlert({ message: "Something went wrong" });
    //   } else {
    let docs = {
      fees: feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees,
      name: feesStructureHeaderByIdData?.feesStructureHeaderById.name,
    };
    setOpenDialog(true);
    setDocsToAddStructure(docs);
    // }
    // });
  };

  const changeHandlerNumber = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      if (name === "feesTotal") {
        setFeesTotal("");
      } else {
        setNumber("");
      }
    }
    if (onlyNumber.test(e.target.value)) {
      if (name === "feesTotal") {
        setFeesTotal(value);
      } else {
        setNumber(value);
      }
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "feesTotal") {
      setFeesTotal(value);
    } else if (name === "type") {
      setType(value);
    } else if (name === "number") {
      setNumber(value);
    } else if (name === "remark") {
      setRemark(value);
    } else {
      setBankName(value);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFeesTotal(0);
    setTransactionDate("");
    setFeesTotal("");
    setType("");
    setNumber("");
    setBankName("");
    setRemark("");
    setFormErrors({});
  };

  const saveTransaction = (feesToSave) => {
    let amount = 0;
    let amountRefund = 0;
    setLoading(true);
    saveApplicantFee({ saveToData: feesToSave }).then((response) => {
      if (response) {
        setOpenTransaction(false);
        setOpenDialog(false);
        setLoading(false);

        getApplicantAdmission({ id: applicationData.id }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
          if (response) {
            if (
              response.data.admissionRegistration.getUserRegistration !== null
            ) {
              getPendingFees({
                id: response.data.admissionRegistration.getUserRegistration.id,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
              });

              getFeesStructureHeadById({
                feesStructureHeadId: response.data.feesStructureHeaderId,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
              });

              getTransactionList({
                userRegistrationId:
                  response.data.admissionRegistration.getUserRegistration.id,
                academicYearId:
                  response.data.admissionRegistration.academicYearId,
                admissionRegistrationDetailId: applicationData.id,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                } else {
                  response.data.map((feeDetail) => {
                    if (feeDetail.transactionType == 0) {
                      amount = amount + feeDetail.amt;
                    } else {
                      amountRefund = amountRefund + feeDetail.amt;
                    }
                    return feeDetail;
                  });
                  //  setTotal(0);
                  /*  if(total===0)
                { */
                  setTotal(amount);
                  setAmountRefundFees(amountRefund);
                }
              });
            }
          }
        });
      } else {
        setLoading(false);
      }
    });
  };

  const refundApplicantFeesData = (feesData) => {
    let tDate = moment(feesData.date).format("DD-MM-YYYY");
    setLoading(true);

    let feesToSave = {
      userRegistrationId:
        applicantAdmissionData?.applicantAdmission.userRegistrationId,
      academicYearId: applicantAdmissionData?.applicantAdmission.academicYearId,
      admissionRegistrationDetailId: applicationData.id,
      transactionDate: tDate,
      totalFee: feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees,
      transactionRemark: feesData.remark,
      payType: feesData.type,
      payRefNo: feesData.number,
      payBank: feesData.bankName,
      amt: feesData.amount,
      transactionType: 1,
      receiptTypeId: 1,
      transactionNo: "",
      isActive: 1,
      exVar1: 0,
      exVar2: 0,
      exVar3: 0,
    };

    let amount = 0;
    let amountRefund = 0;
    saveApplicantFee({ saveToData: feesToSave }).then((response) => {
      if (response) {
        setLoading(false);
        setOpen(false);
        setOpenDebit(false);
        getApplicantAdmission({ id: applicationData.id }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
          if (response) {
            if (
              response.data.admissionRegistration.getUserRegistration !== null
            ) {
              getPendingFees({
                id: response.data.admissionRegistration.getUserRegistration.id,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
              });

              getFeesStructureHeadById({
                feesStructureHeadId: response.data.feesStructureHeaderId,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
              });

              getTransactionList({
                userRegistrationId:
                  response.data.admissionRegistration.getUserRegistration.id,
                academicYearId:
                  response.data.admissionRegistration.academicYearId,
                admissionRegistrationDetailId: applicationData.id,
              }).then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                } else {
                  response.data.map((feeDetail) => {
                    if (feeDetail.transactionType == 0) {
                      amount = amount + feeDetail.amt;
                    } else {
                      amountRefund = amountRefund + feeDetail.amt;
                    }
                    return feeDetail;
                  });
                  //  setTotal(0);
                  /*  if(total===0)
                { */
                  setTotal(amount);
                  setAmountRefundFees(amountRefund);
                }
              });
            }
          }
        });
      } else {
        getAlert({ message: "Something went wrong" });
        setLoading(false);
      }
    });
  };

  const pdfDetailsId = async (e, row) => {
    downloadF({
      url: "/api/report/v1/fee-receipt-report/" + row.id + "/get-fee-receipt",
    });
  };

  const pdfLedgerDownload = () => {
    downloadF({
      url:
        "/api/report/v1/leisure-report?admissionRegistrationDetailId=" +
        applicationData.id +
        "&documentType=1",
    });
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (feesTotal === "") {
      formIsValid = false;
      formErrors["feesTotalError"] = myConstClass.debitAmountMsg;
    }

    if (type == "1" || type == "2" || type == "3") {
      if (number === "") {
        formIsValid = false;
        formErrors["numberError"] = myConstClass.numberMsg;
      }
    }

    if (type == "1" || type == "2") {
      if (bankName === "") {
        formIsValid = false;
        formErrors["bankNameError"] = myConstClass.bankNameMsg;
      }
    }

    // if (
    //   feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees - total >
    //   feesStructureHeaderByIdData?.feesStructureHeaderById.minimumFees
    // ) {
    //   if (
    //     feesTotal <
    //     feesStructureHeaderByIdData?.feesStructureHeaderById.minimumFees
    //   ) {
    //     formIsValid = false;
    //     formErrors["feesTotalError"] = myConstClass.feesMinimumPayMsg;
    //   }
    // }

    if (
      feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees - total <
      feesStructureHeaderByIdData?.feesStructureHeaderById.minimumFees
    ) {
      if (
        feesTotal !=
        feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees - total
      ) {
        swal({
          title: "Are you sure?",
          text: myConstClass.feesEqualPayMsg,
          icon: "warning",
          buttons: ["Cancel", true],
        }).then((res) => {
          if (res === null) {
            formIsValid = false;
            formErrors["feesTotalError"] = myConstClass.feesEqualPayMsg;
          }
        });
      }
    }

    if (transactionDate === "" || transactionDate === null) {
      formIsValid = false;
      formErrors["transactionDateError"] = myConstClass.transactionDateMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const saveApplicantFeeData = () => {
    let amount = 0;
    let amountRefund = 0;
    if (checkValidation()) {
      let error = false;
      if (
        feesTotal >
        feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees - total
      ) {
        swal({
          title: "Are you sure?",
          text: "Amount is greater than pending amount still you want to continue",
          icon: "warning",
          buttons: ["Cancel", true],
        }).then((res) => {
          if (res === null) {
            error = true;
          } else {
            setLoading(true);

            let tDate = moment(transactionDate).format("DD-MM-YYYY");

            let feesToSave = {
              userRegistrationId:
                applicantAdmissionData?.applicantAdmission.userRegistrationId,
              academicYearId:
                applicantAdmissionData?.applicantAdmission.academicYearId,
              admissionRegistrationDetailId: applicationData.id,
              transactionDate: tDate,
              totalFee:
                feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees,
              transactionRemark: remark === "" ? "-" : remark,
              payType: type === "" ? 0 : type,
              payRefNo: number === "" ? "-" : number,
              payBank: bankName === "" ? "-" : bankName,
              amt: feesTotal,
              transactionType: 0,
              transactionNo: "",
              receiptTypeId: 1,
              isActive: 1,
              exVar1: 0,
              exVar2: 0,
              exVar3: 0,
            };

            saveApplicantFee({ saveToData: feesToSave }).then((response) => {
              if (response) {
                setOpen(false);
                setLoading(false);

                setFeesTotal("");
                setTransactionDate("");
                setType("");
                setNumber("");
                setBankName("");
                setRemark("");
                setFormErrors({});
                getApplicantAdmission({ id: applicationData.id }).then(
                  (response) => {
                    if (!response) {
                      getAlert({ message: "Something went wrong" });
                    }
                    if (response) {
                      if (
                        response.data.admissionRegistration
                          .getUserRegistration !== null
                      ) {
                        getPendingFees({
                          id: response.data.admissionRegistration
                            .getUserRegistration.id,
                        }).then((response) => {
                          if (!response) {
                            getAlert({ message: "Something went wrong" });
                          }
                        });

                        getTransactionList({
                          userRegistrationId:
                            response.data.admissionRegistration
                              .getUserRegistration.id,
                          academicYearId:
                            response.data.admissionRegistration.academicYearId,
                          admissionRegistrationDetailId: applicationData.id,
                        }).then((response) => {
                          if (!response) {
                            getAlert({ message: "Something went wrong" });
                          } else {
                            response.data.map((feeDetail) => {
                              if (feeDetail.transactionType == 0) {
                                amount = amount + feeDetail.amt;
                              } else {
                                amountRefund = amountRefund + feeDetail.amt;
                              }
                              return feeDetail;
                            });
                            //  setTotal(0);
                            /*  if(total===0)
                            { */
                            setTotal(amount);
                            setAmountRefundFees(amountRefund);
                          }
                        });

                        getFeesStructureHeadById({
                          feesStructureHeadId:
                            response.data.feesStructureHeaderId,
                        }).then((response) => {
                          if (!response) {
                            getAlert({ message: "Something went wrong" });
                          }
                        });
                      }
                    }
                  }
                );
              } else {
                setLoading(false);
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      } else {
        swal({
          title: "Are you sure?",
          text: "Do You want to submit",
          icon: "warning",
          buttons: ["Cancel", true],
        }).then((res) => {
          if (res) {
            setLoading(true);

            let tDate = moment(transactionDate).format("DD-MM-YYYY");

            let feesToSave = {
              userRegistrationId:
                applicantAdmissionData?.applicantAdmission.userRegistrationId,
              academicYearId:
                applicantAdmissionData?.applicantAdmission.academicYearId,
              admissionRegistrationDetailId: applicationData.id,
              transactionDate: tDate,
              totalFee:
                feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees,
              transactionRemark: remark === "" ? "-" : remark,
              payType: type === "" ? 0 : type,
              payRefNo: number === "" ? "-" : number,
              payBank: bankName === "" ? "-" : bankName,
              amt: feesTotal,
              transactionType: 0,
              transactionNo: "",
              receiptTypeId: 1,
              isActive: 1,
              exVar1: 0,
              exVar2: 0,
              exVar3: 0,
            };

            saveApplicantFee({ saveToData: feesToSave }).then((response) => {
              if (response) {
                setOpen(false);
                setLoading(false);

                setFeesTotal("");
                setTransactionDate("");
                setType("");
                setNumber("");
                setBankName("");
                setRemark("");
                setFormErrors({});
                getApplicantAdmission({ id: applicationData.id }).then(
                  (response) => {
                    if (!response) {
                      getAlert({ message: "Something went wrong" });
                    }
                    if (response) {
                      if (
                        response.data.admissionRegistration
                          .getUserRegistration !== null
                      ) {
                        getPendingFees({
                          id: response.data.admissionRegistration
                            .getUserRegistration.id,
                        }).then((response) => {
                          if (!response) {
                            getAlert({ message: "Something went wrong" });
                          }
                        });

                        getTransactionList({
                          userRegistrationId:
                            response.data.admissionRegistration
                              .getUserRegistration.id,
                          academicYearId:
                            response.data.admissionRegistration.academicYearId,
                          admissionRegistrationDetailId: applicationData.id,
                        }).then((response) => {
                          if (!response) {
                            getAlert({ message: "Something went wrong" });
                          } else {
                            response.data.map((feeDetail) => {
                              if (feeDetail.transactionType == 0) {
                                amount = amount + feeDetail.amt;
                              } else {
                                amountRefund = amountRefund + feeDetail.amt;
                              }
                              return feeDetail;
                            });
                            //  setTotal(0);
                            /*  if(total===0)
                        { */
                            setTotal(amount);
                            setAmountRefundFees(amountRefund);
                          }
                        });

                        getFeesStructureHeadById({
                          feesStructureHeadId:
                            response.data.feesStructureHeaderId,
                        }).then((response) => {
                          if (!response) {
                            getAlert({ message: "Something went wrong" });
                          }
                        });
                      }
                    }
                  }
                );
              } else {
                setLoading(false);
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    let amount = 0;
    let amount1 = 0;
    let amount11 = 0;
    let amountRefund = 0;
    let totalPendingFees1 = 0;

    apiGet({
      url: endpoints.yearDetailId + "/" + applicationData.yearDetailId,
    }).then(({ data, success }) => {
      if (success) {
        setYearDetail(
          data.data.programTitle.programType.name +
            " - " +
            data.data.programTitle.brName +
            " - " +
            data.data.year.className
        );
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
    // + applicationData.admissionRegId
    //0ada0f74-2044-4e74-bfce-0566f9b95d37

    getApplicantAdmission({ id: applicationData.id }).then((response) => {
      if (response) {
        if (
          response.data.feesStructureHeaderId !== "" &&
          response.data.feesStructureHeaderId != 0 &&
          response.data.feesStructureHeaderId !== null
        ) {
          setFeeId(response.data.feesStructureHeaderId);
          getFeesStructureHeadById({
            feesStructureHeadId: response.data.feesStructureHeaderId,
          }).then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });

              setOpenDialog(true);
            } else {
              const totalPendingFees1 =
                feesStructureHeaderByIdData?.feesStructureHeaderById.totalFees -
                totalFeesPending;
              setTotalFeesPending(totalPendingFees1);
            }
          });
          if (
            response.data.admissionRegistration.getUserRegistration !== null
          ) {
            getPendingFees({
              id: response.data.admissionRegistration.getUserRegistration.id,
            }).then((response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
              }
            });
            getTransactionList({
              userRegistrationId:
                response.data.admissionRegistration.getUserRegistration.id,
              academicYearId:
                response.data.admissionRegistration.academicYearId,
              admissionRegistrationDetailId: applicationData.id,
            }).then((response) => {
              if (response) {
                response.data.map((feeDetail) => {
                  if (feeDetail.transactionType == 0) {
                    amount = amount + feeDetail.amt;
                  } else {
                    amountRefund = amountRefund + feeDetail.amt;
                  }
                  return feeDetail;
                });
                setTotal(amount);
                setAmountRefundFees(amountRefund);
                //}
              } else {
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        } else {
          setOpenDialog(true);
        }
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);
  const GoBack = () => {
    setOpenPreview(false);
  };
  const rowViewData = () => {
    getStudentById({
      studId: applicantAdmissionData?.applicantAdmission?.userRegistrationId,
    }).then((response) => {
      if (response) {
        setOpenPreview(true);
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  };
  return (
    <>
      <div>
        {/* <Paper sx={{ p: 2, pt: 4, borderRadius: 2 }} elevation={0}> */}
        {!openPreview && (
          <>
            {" "}
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
              columnSpacing={3}
            >
              <Grid item xs={12} sm={8} md={8}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Student Fees Details "
                />
              </Grid>
              <Grid item sx={{ mr: -5.5 }} xs={12} sm={2.5} md={2.5}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Change Structure"
                  onClick={openDeclareForm}
                />
              </Grid>
              <Grid item sx={{ mr: -1.5 }} xs={12} sm={1} md={1}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Pay"
                  onClick={handleClickOpen}
                />
              </Grid>
              <Grid item sx={{ mr: -1.5 }} xs={12} sm={0.5} md={0.5}>
                <MyComponentWithIconProps
                  statusImage={Avatar}
                  color="primary"
                  fontSize="medium"
                  title="Details"
                  onClick={rowViewData}
                />
              </Grid>
            </Grid>
            <br />
            <Grid item xs={6} sm={6} md={12}>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={2}
                rowSpacing={2}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={1.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Student Name  "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={10.5}>
                    <TextField
                      color="primary"
                      name="domicileof"
                      size="small"
                      value={applicationData.applicantName}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={1.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Year Detail  "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={10.5}>
                    <TextField
                      color="primary"
                      name="yearDetail"
                      size="small"
                      value={yearDetail == "" ? "" : yearDetail}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="AC Year  "
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="domicileof"
                        size="small"
                        value={applicationData.academicYearName}
                        // fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Contact  "
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="domicileof"
                        size="small"
                        value={applicationData.studentMobile}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Email Id  "
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="domicileof"
                        size="small"
                        value={applicationData.studentEmail}
                        // fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Fees Structure Name  "
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="domicileof"
                        size="small"
                        value={
                          feesStructureHeaderByIdData?.feesStructureHeaderById
                            .name
                            ? feesStructureHeaderByIdData
                                ?.feesStructureHeaderById.name
                            : ""
                        }
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Current year Fees  "
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="domicileof"
                        size="small"
                        value={
                          feesStructureHeaderByIdData?.feesStructureHeaderById
                            .totalFees
                        }
                        //fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Total Paid Fees  "
                      />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="total"
                        size="small"
                        value={total == 0 ? 0 : total - amountRefundFees}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Minimum Fees To Be Paid  "
                      />
                    </Grid>
                    {/* applicantAdmissionData?.PendingList.pendingAmount */}
                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="domicileof"
                        size="small"
                        value={
                          feesStructureHeaderByIdData?.feesStructureHeaderById
                            .minimumFees
                        }
                        // fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Total Pending Fees  "
                      />
                    </Grid>
                    {/* applicantAdmissionData?.PendingList.pendingAmount */}

                    <Grid item xs={12} sm={9} md={9}>
                      <TextField
                        color="primary"
                        name="domicileof"
                        size="small"
                        value={
                          feesStructureHeaderByIdData?.feesStructureHeaderById
                            .totalFees -
                          (total - amountRefundFees)
                        }
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} md={12} rowSpacing={2}>
                  <Grid item xs={12} sm={10} md={10}>
                    {" "}
                    Transaction Details :
                  </Grid>
                  <Grid item sm={2} xs={12} md={2} lg={2} sx={{}}>
                    <ButtonCompo
                      size="medium"
                      fullWidth={true}
                      type="button"
                      variant="contained"
                      name="Debit amount"
                      onClick={onDebitTransaction}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Table sx={{ minWidth: 250 }} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Sr. No.</TableCell>
                          <TableCell align="center">Transaction No</TableCell>
                          <TableCell align="center">Date</TableCell>
                          <TableCell align="center">Payment Type</TableCell>
                          <TableCell align="center">Paid Fees</TableCell>
                          <TableCell align="center">Refund</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {applicantAdmissionData.transactionList.map(
                          (row, index) => (
                            <TableRow key={row.desc}>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">
                                {row.transactionNo}
                              </TableCell>
                              <TableCell align="center">
                                {row.transactionDate}
                              </TableCell>
                              <TableCell align="left">{row.payType}</TableCell>
                              <TableCell align="right">{row.amount}</TableCell>
                              <TableCell align="right">{row.refund}</TableCell>
                              <TableCell align="right">
                                {
                                  <>
                                    {/* <MyComponentWithIconProps
                                      statusImage={PictureAsPdfIcon}
                                      color="error"
                                      fontSize="medium"
                                      title="Edit"
                                      onClick={(e) => pdfDetailsId(e, row)}
                                    /> */}
                                    <FontAwesomeIconCompo
                                      className="fa-file-pdf"
                                      color="error"
                                      title="PDF"
                                      baseClassName="fas"
                                      fontSize="small"
                                      onClick={(e) => pdfDetailsId(e, row)}
                                    />
                                    &nbsp;&nbsp;
                                    <MyComponentWithIconProps
                                      statusImage={EditIcon}
                                      color="primary"
                                      fontSize="medium"
                                      title="Edit"
                                      sx={{ marginTop: -2 }}
                                      onClick={(e) => onTransaction(e, row)}
                                    />
                                    &nbsp;&nbsp;
                                    <MyComponentWithIconProps
                                      statusImage={DeleteIcon}
                                      color="error"
                                      fontSize="medium"
                                      title="Delete"
                                      sx={{ marginTop: -2 }}
                                      onClick={(e) => onClickDelete(e, row)}
                                    />
                                  </>
                                }
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          <TableCell align="left" colSpan={3}>
                            Total
                          </TableCell>
                          <TableCell align="right">{total}</TableCell>
                          <TableCell align="right">
                            {amountRefundFees}
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="left"
                      sx={{ mt: 2 }}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Ledger Report"
                        onClick={pdfLedgerDownload}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="right"
                      direction="row"
                      sx={{ mt: 2 }}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back"
                        onClick={onCancel}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  fullWidth
                  maxWidth="lg"
                  onBackdropClick="true"
                >
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    Fees Structure
                  </BootstrapDialogTitle>
                  <DialogContent dividers>
                    <Table sx={{ minWidth: 650 }} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Sr. No.</TableCell>
                          <TableCell align="center">Particular Fee</TableCell>
                          <TableCell align="center">Applicable Fees</TableCell>
                          <TableCell align="center">Amount Paid</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {applicantAdmissionData?.applicantAdmission &&
                          Object.keys(
                            applicantAdmissionData?.applicantAdmission
                          ).length !== 0 &&
                          applicantAdmissionData?.applicantAdmission.setAdmissionFeesDetails.map(
                            (row, index) => (
                              /*  row.setAdmissionFeesDetails.map((row1,index) => ( */
                              <TableRow key={row.desc}>
                                <TableCell align="center">
                                  {index + 1}
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="right">
                                  {row.applicableAmt}
                                </TableCell>
                                <TableCell align="right">
                                  {row.receivedAmt}
                                </TableCell>
                              </TableRow>
                              /* )) */
                            )
                          )}

                        <TableRow>
                          {/*   <TableCell align="right"></TableCell>
                        <TableCell align="left">Total</TableCell>
                        <TableCell align="center">{total1}</TableCell>
                        <TableCell align="right">{total11}</TableCell> */}
                        </TableRow>
                      </TableBody>
                    </Table>

                    <Grid
                      item
                      sx={{ mt: 1 }}
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      spacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={6}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Mode of pay "
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl color={"primary"} fullWidth={true}>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              className="text-black"
                              name="type"
                              id="type"
                              value={type}
                              onChange={changeHandler}
                              size="small"
                              fullWidth={true}
                            >
                              <MenuItem value="0">Cash</MenuItem>
                              <MenuItem value="1">DD</MenuItem>
                              <MenuItem value="2">Cheque</MenuItem>
                              <MenuItem value="3">Online</MenuItem>
                              <MenuItem value="4">Transfer</MenuItem>
                            </Select>
                          </FormControl>
                          <FormHelperText> </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Amount To Be Paid "
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            color="primary"
                            name="feesTotal"
                            size="small"
                            value={feesTotal}
                            fullWidth
                            onChange={changeHandlerNumber}
                            error={formErrors.feesTotalError ? true : false}
                            helperText={
                              formErrors.feesTotalError
                                ? formErrors.feesTotalError
                                : " "
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      // sx={{ m: 1 }}
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      spacing={1}
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={6}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Transaction Date "
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                          <BasicDatePicker
                            color="primary"
                            name="transactionDate"
                            size="small"
                            value={transactionDate}
                            maxDate={new Date()}
                            onChange={changeFromDate}
                            isError={
                              formErrors.transactionDateError ? true : false
                            }
                            errorText={
                              formErrors.transactionDateError
                                ? formErrors.transactionDateError
                                : " "
                            }
                          />
                        </Grid>
                      </Grid>
                      {type == "1" || type == "2" || type == "3" ? (
                        <>
                          <Grid
                            item
                            sm={6}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} md={12} sm={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="*Number "
                              />
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                              <TextField
                                color="primary"
                                name="number"
                                size="small"
                                value={number}
                                fullWidth
                                onChange={changeHandlerNumber}
                                error={formErrors.numberError ? true : false}
                                helperText={
                                  formErrors.numberError
                                    ? formErrors.numberError
                                    : " "
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            item
                            sm={6}
                            xs={6}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          ></Grid>
                        </>
                      )}

                      {/*   <Grid item  xs={12} md={0.5} sm={0.5}></Grid> */}
                    </Grid>
                    {(type == "1" || type == "2") && (
                      <>
                        <Grid
                          item
                          // sx={{ m: 1 }}
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} md={12} sm={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="*Bank Name "
                              />
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                              <TextField
                                color="primary"
                                name="bankName"
                                size="small"
                                value={bankName}
                                fullWidth
                                onChange={changeHandler}
                                error={formErrors.bankNameError ? true : false}
                                helperText={
                                  formErrors.bankNameError
                                    ? formErrors.bankNameError
                                    : " "
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      // sx={{ m: 1 }}
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Remark "
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            color="primary"
                            name="remark"
                            size="small"
                            value={remark}
                            fullWidth
                            onChange={changeHandler}
                            error={false}
                            helperText={" "}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    {/*  <Button autoFocus onClick={handleClose}>
                                        Submit
                                    </Button> */}
                    <Grid item sx={{ mr: 3 }} xs={12} sm={12} md={1}>
                      <ButtonCompo
                        size="medium"
                        onClick={saveApplicantFeeData}
                        variant="contained"
                        name="Submit"
                        fullWidth={true}
                      />
                    </Grid>
                  </DialogActions>
                </BootstrapDialog>
              </Grid>
            </Grid>
          </>
        )}
        {openPreview && <StudentPreview GoBack={GoBack} />}
        {openDialog && (
          <AddFeesStruture
            open={openDialog}
            onClose={onClose}
            data={dataToSave}
            onCloseTab={onCloseTab}
            onSuccess={getSuccess}
            docsToAddStructure={docsToAddStructure}
          />
        )}
        {openDebit && (
          <AddDebitTransaction
            open={openDebit}
            onClose={onCloseDebit}
            onSave={refundApplicantFeesData}
            debitValidation={debitValidation}
            studentData={studentData}
          />
        )}
        {openTransaction && (
          <EditTransaction
            open={openTransaction}
            onClose={onCloseDebit}
            onSave={saveTransaction}
          />
        )}
        {loading && <Loading1 />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  applicantAdmissionData: state.applicantAdmission,
  feesStructureHeaderByIdData: state.feesStructureHead,
});

const mapDispatchToProps = {
  getApplicantAdmission,
  getFeesStructureHeadById,
  getPendingFees,
  getTransactionList,
  saveApplicantFee,
  getAlert,
  getStudentByApplicationId,
  getStudentById,
  getTransactionDetailById,
  getAddFeesStructureHeader,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountFees);
