import ArticleIcon from "@mui/icons-material/Article";
import BoyIcon from "@mui/icons-material/Boy";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiDelete } from "../../utils/api_service";
import {
  getAnnualIncome,
  getBloodGroup,
  getCategory,
  getDistrict,
  getReligion,
  getState,
  getTaluka,
  getRoleAccess,
} from "../BasicDetailsPost/basicDetails.slice";
import { showNotification } from "../../Pages/Landing/Landing.slice";

import Address from "../EmployeeDetails/Address";
import DocumentForm from "../EmployeeDetails/DocumentForm";
import { saveEmployee } from "../EmployeeDetails/employee.slice";
import PersonalDetails from "../EmployeeDetails/PersonalDetails";
import UploadPhotoAndSign from "../EmployeeDetails/PhotoAndSign";
import QualificationForm from "../EmployeeDetails/QualificationForm";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import { getDesignation } from "../Masters/Internship/Designation/designation.slice";
import { getDocument } from "../Masters/OnlineModule/Document/document.slice";
import { getQualification } from "../QualificationMaster/qualification.slice";
import { deleteFile, openFileInNewTab } from "../UploadFile/file.slice";
import DesignationAndRole from "./DesignationAndRole";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { generateExcel } from "../../components/Comman/GenerateExcel";
import PromotionsRecord from "./PromotionsRecord";
import { Loading } from "../../components/Loader";
const EmployeeDetails = ({
  studentList,
  getReligion,
  getCategory,
  getBloodGroup,
  getAnnualIncome,
  getState,
  getDistrict,
  getTaluka,
  openFileInNewTab,
  deleteFile,
  saveStudent,
  getQualification,
  getDocument,
  onCancel,
  getDesignation,
  getDepartment,
  saveEmployee,
  employeeDetails,
  getRoleAccess,
  getAlert,
  goToList,
  showNotification,
  editForm,
}) => {
  const [value, setValue] = React.useState("1");
  const [row, setRow] = React.useState([]);
  const [quaRow, setQuaRow] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editRowData, setEditRowData] = React.useState([]);

  const data = (personaldetails) => {
    setLoading(true);

    saveEmployee({ employeeData: personaldetails }).then((response) => {
      if (response) {
        showNotification({
          msg: "Data saved successfully",
        });
        setValue("2");
        setLoading(false);
      } else {
        getAlert({ message: myConstClass.serverMsg });
        setLoading(false);
      }
    });
  };

  const studAddressData = (addressDetails) => {
    setLoading(true);

    saveEmployee({ employeeData: addressDetails }).then((response) => {
      if (response) {
        setValue("3");
        setLoading(false);

        showNotification({
          msg: "Data saved successfully",
        });
        setRow(
          employeeDetails.employee.userDocumentDetailSaves
            ? employeeDetails.employee.userDocumentDetailSaves.map(
                (userDocumentDetailData, index) => {
                  let userDocData = {
                    id: userDocumentDetailData.id,
                    index: index + 1,
                    docDesc:
                      userDocumentDetailData.getDocument !== null
                        ? userDocumentDetailData.getDocument.name
                        : "",
                    documentPath: userDocumentDetailData.documentFileName,
                    documentId: userDocumentDetailData.documentId,
                  };
                  return userDocData;
                }
              )
            : []
        );

        setQuaRow(
          employeeDetails.employee.userQualificationDetailSaves
            ? employeeDetails.employee.userQualificationDetailSaves.map(
                (userQualificationDetailData, index) => {
                  let userDocData = {
                    id: userQualificationDetailData.id,
                    index: index + 1,
                    qualificationName:
                      userQualificationDetailData.getQualification.name,
                    qualificationId:
                      userQualificationDetailData.qualificationId,
                    nameOfSchoolClg:
                      userQualificationDetailData.nameOfSchoolCollege,
                    yearOfPass: userQualificationDetailData.yearOfPass,

                    boardUniversity: userQualificationDetailData.university,
                    obtainedMarks: userQualificationDetailData.marksObtained,
                    totalMarks: userQualificationDetailData.marksOutof,
                    percentage: userQualificationDetailData.percentage,
                    country: userQualificationDetailData.country,
                    state: userQualificationDetailData.state,
                    board: userQualificationDetailData.board,
                    isActive: userQualificationDetailData.isActive,
                  };
                  return userDocData;
                }
              )
            : []
        );
      } else {
        setLoading(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  const qualificationData = (qualification) => {};

  const uploadPhotoAndSign = (uploadeddata) => {
    setLoading(true);

    saveEmployee({ employeeData: uploadeddata }).then((response) => {
      if (response) {
        setLoading(false);

        showNotification({
          msg: "Data saved successfully",
        });
        setValue("5");
      } else {
        setLoading(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  const designation = (designationData) => {
    setLoading(true);

    saveEmployee({ employeeData: designationData }).then((response) => {
      if (response) {
        setLoading(false);

        showNotification({
          msg: "Data saved successfully",
        });
        setValue("6");
      } else {
        setLoading(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  const rowView = (data) => {
    const viewRow = row.filter((row) => row.id === data.id);
    openFileInNewTab({ file: viewRow[0].documentPath });
    setOpen(true);
  };

  const rowEdit = (data) => {
    if (data.qualificationId !== undefined) {
      const editRow = quaRow.filter(
        (row) => row.qualificationId === data.qualificationId
      );
      if (editRow.length > 0) {
        return setEditRowData(editRow);
      }
      return setEditRowData([]);
    } else {
      const editRow = quaRow.filter((row) => row.index === data.index);
      if (editRow.length > 0) {
        return setEditRowData(editRow);
      }
      return setEditRowData([]);
    }
  };

  const rowDeleteQualification = (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);

        if (data.id !== undefined) {
          let quaRowList = quaRow.filter((row) => row.id !== data.id);
          setQuaRow(quaRowList);
          apiDelete({
            url: endpoint.userQualificationDetail + "/" + data.id,
          }).then(({ success }) => {
            if (!success) {
              setLoading(false);

              getAlert({
                message: "Failed to delete",
              });
            } else {
              setLoading(false);
            }
          });
        } else {
          setLoading(false);

          let quaRowList = quaRow.filter((row) => row.index !== data.index);
          setQuaRow(quaRowList);
        }
      }
    });
  };

  const rowDelete = (id) => {
    const viewRow = row.filter((row) => row.id === id);
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFile({ file: viewRow[0].documentPath }).then(() => {});
        const rowData = row.filter((row) => row.id !== id);
        setRow(rowData);
        apiDelete({
          url: endpoint.userDocumentDetail + "/" + id,
        }).then(({ success }) => {
          if (!success) {
            getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };

  const setRowData = (data) => {
    if (data.id === undefined) {
      data = {
        ...data,
        id: row.length + 1,
      };
      setRow((prevData) => {
        return [...prevData, data];
      });
    } else {
      setRow((prevData) => {
        return [...prevData, data];
      });
    }
  };

  const setQuaData = (data) => {
    if (data.index === 0 || data.index === undefined) {
      data = {
        ...data,
        index: quaRow.length + 1,
      };

      setQuaRow((prevData) => {
        return [...prevData, data];
      });
    } else {
      let viewRow = quaRow.filter((row) => row.index !== data.index);
      viewRow = [...viewRow, data];
      setQuaRow(viewRow);
    }
    setEditRowData([]);
  };

  useEffect(() => {
    getReligion();
    getCategory();
    getBloodGroup();
    getAnnualIncome();
    getState();
    getDistrict();
    getTaluka();
    getRoleAccess();
    getQualification();
    getDocument();
    getDesignation();
    getDepartment();
  }, []);

  const changeTabs = (event, newValue) => {
    if (newValue === "3") {
      setRow(
        employeeDetails.employee.userDocumentDetailSaves
          ? employeeDetails.employee.userDocumentDetailSaves.map(
              (userDocumentDetailData, index) => {
                let userDocData = {
                  id: userDocumentDetailData.id,
                  index: index + 1,
                  docDesc:
                    userDocumentDetailData.getDocument !== null
                      ? userDocumentDetailData.getDocument.name
                      : "",
                  documentPath: userDocumentDetailData.documentFileName,
                  documentId: userDocumentDetailData.documentId,
                };
                return userDocData;
              }
            )
          : []
      );
      setQuaRow(
        employeeDetails.employee.userQualificationDetailSaves
          ? employeeDetails.employee.userQualificationDetailSaves.map(
              (userQualificationDetailData, index) => {
                let userDocData = {
                  id: userQualificationDetailData.id,
                  index: index + 1,
                  qualificationId: userQualificationDetailData.qualificationId,
                  qualificationName:
                    userQualificationDetailData.getQualification.name,
                  nameOfSchoolClg:
                    userQualificationDetailData.nameOfSchoolCollege,
                  yearOfPass: userQualificationDetailData.yearOfPass,

                  boardUniversity: userQualificationDetailData.university,
                  obtainedMarks: userQualificationDetailData.marksObtained,
                  totalMarks: userQualificationDetailData.marksOutof,
                  percentage: userQualificationDetailData.percentage,
                  country: userQualificationDetailData.country,
                  state: userQualificationDetailData.state,
                  board: userQualificationDetailData.board,
                  isActive: userQualificationDetailData.isActive,
                };
                return userDocData;
              }
            )
          : []
      );
    }
    setValue(newValue);
  };

  const gotoPersonalDetails = () => {
    setValue("1");
  };

  const gotoAddressDetails = () => {
    setValue("2");
  };
  const gotoDocumentsAndQualification = () => {
    setValue("3");
  };

  const gotoPhotoAndSign = () => {
    setValue("4");
  };

  const gotoDesignationAndRoles = () => {
    setValue("5");
  };

  const saveDocumentAndQualification = (e) => {
    e.preventDefault();
    let QualificationDetailSaves = quaRow.map((data) => {
      let docs = {
        qualificationId: data.qualificationId,
        yearOfPass: data.yearOfPass,
        university: data.boardUniversity,
        marksObtained: data.obtainedMarks,
        marksOutof: data.totalMarks,
        percentage: data.percentage,
        nameOfSchoolCollege: data.nameOfSchoolClg,
        country: data.country,
        state: data.state,
        board: data.board,
        isActive: 1,
      };
      if (data.id) {
        docs = {
          ...docs,
          id: data.id,
        };
      }
      return docs;
    });

    const DocumentDetails = row.map((data) => {
      let docss = {
        documentId: data.documentId,
        documentFileName: data.documentPath,
        isActive: 1,
      };
      if (data.id) {
        docss = {
          ...docss,
          id: data.id,
        };
      }
      return docss;
    });

    let QualificationAndDocsSave = {
      id: employeeDetails.employee.id,
      userDocumentDetailSaves: DocumentDetails,
      userQualificationDetailSaves: QualificationDetailSaves,
      type: 3,
    };

    saveEmployee({ employeeData: QualificationAndDocsSave }).then(
      (response) => {
        if (response) {
          showNotification({
            msg: "Data saved successfully",
          });
          setValue("4");
        } else {
          getAlert({ message: myConstClass.serverMsg });
        }
      }
    );
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={changeTabs} aria-label="lab API tabs example">
                <Tab
                  icon={<InfoIcon />}
                  textColor="secondary"
                  indicatorcolor="secondary"
                  label="Basic Details"
                  value="1"
                />

                <Tab
                  icon={<HomeIcon />}
                  textColor="secondary"
                  indicatorcolor="secondary"
                  label="Address Details"
                  value="2"
                  {...(Object.keys(employeeDetails.employee).length === 0
                    ? {
                        disabled: true,
                      }
                    : employeeDetails.employee.type == 0
                    ? {
                        disabled: true,
                      }
                    : {
                        disabled: false,
                      })}
                />
                <Tab
                  icon={<ArticleIcon />}
                  textColor="secondary"
                  indicatorcolor="secondary"
                  label="Documents And Qualification"
                  value="3"
                  {...(Object.keys(employeeDetails.employee).length === 0
                    ? {
                        disabled: true,
                      }
                    : employeeDetails.employee.type < 2
                    ? {
                        disabled: true,
                      }
                    : {
                        disabled: false,
                      })}
                />

                <Tab
                  icon={<PhotoSizeSelectActualIcon />}
                  textColor="secondary"
                  indicatorcolor="secondary"
                  label="Photo and Sign"
                  value="4"
                  {...(Object.keys(employeeDetails.employee).length === 0
                    ? {
                        disabled: true,
                      }
                    : employeeDetails.employee.type < 3
                    ? {
                        disabled: true,
                      }
                    : {
                        disabled: false,
                      })}
                />

                <Tab
                  icon={<BoyIcon />}
                  textColor="secondary"
                  indicatorcolor="secondary"
                  label="Designation And Role"
                  value="5"
                  {...(Object.keys(employeeDetails.employee).length === 0
                    ? {
                        disabled: true,
                      }
                    : employeeDetails.employee.type < 4
                    ? {
                        disabled: true,
                      }
                    : {
                        disabled: false,
                      })}
                />

                <Tab
                  icon={<BoyIcon />}
                  textColor="secondary"
                  indicatorcolor="secondary"
                  label="Promotion Records"
                  value="6"
                  {...(Object.keys(employeeDetails.employee).length === 0
                    ? {
                        disabled: true,
                      }
                    : employeeDetails.employee.type < 5
                    ? {
                        disabled: true,
                      }
                    : {
                        disabled: false,
                      })}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <PersonalDetails
                editForm={editForm}
                OnSave={data}
                onCancel={onCancel}
              />
            </TabPanel>
            <TabPanel value="2">
              <Address
                editForm={editForm}
                onCancel={gotoPersonalDetails}
                studAddressData={studAddressData}
              />
            </TabPanel>
            <TabPanel value="3">
              <>
                <DocumentForm
                  row={row}
                  rowView={rowView}
                  rowData={setRowData}
                  rowDelete={rowDelete}
                />

                <QualificationForm
                  row={quaRow}
                  rowData={setQuaData}
                  rowDelete={rowDeleteQualification}
                  qualificationData={qualificationData}
                  rowEdit={rowEdit}
                  editRows={editRowData}
                />
                {/*  */}

                <Grid
                  rowSpacing={2}
                  container
                  columnSpacing={1}
                  justifyContent="flex-end"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back"
                      fullWidth={true}
                      onClick={gotoAddressDetails}
                    />
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={2.5}
                    lg={2.5}
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save and Proceed"
                      onClick={saveDocumentAndQualification}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </>
            </TabPanel>

            <TabPanel value="4">
              <UploadPhotoAndSign
                uploadphotoandsign={uploadPhotoAndSign}
                onCancel={gotoDocumentsAndQualification}
              />
            </TabPanel>

            <TabPanel value="5">
              <DesignationAndRole
                onCancel={gotoPhotoAndSign}
                designation={designation}
                editForm={editForm}
                gotoDesignationAndRoles={gotoDesignationAndRoles}
              />
            </TabPanel>

            <TabPanel value="6">
              <PromotionsRecord
                editForm={editForm}
                onCancel={gotoDesignationAndRoles}
                saveHandler={onCancel}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
      {loading && <Loading />}
    </>
  );
};
const mapStateToProps = (state) => ({
  studentList: state.studentList,
  employeeDetails: state.employeeList,
});
const mapDispatchToProps = {
  getReligion,
  getCategory,
  getBloodGroup,
  getAnnualIncome,
  getState,
  getDistrict,
  getTaluka,
  getRoleAccess,
  openFileInNewTab,
  deleteFile,
  saveEmployee,
  getQualification,
  getDocument,
  getDesignation,
  getDepartment,
  getAlert,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
