import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  innovative: [],
  innovativeById: [],
  innovativeByDate: [],
  isFetchByDateStatus: false,
  isFetchByIdStatus: false,
  isFetch: false,
};
let URL = endpoints.innovative;
const InnovativeTeaching = createSlice({
  name: "innovative",
  initialState,
  reducers: {
    innovativeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        innovative: row,
        isFetch: true,
      };
    },
    innovativeByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { bData } = action.payload;
      return {
        ...state,
        innovativeById: bData,
        isFetchByIdStatus: true,
      };
    },
    innovativeByDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        innovativeByDate: row,
        isFetchByDateStatus: true,
      };
    },
  },
});

export const {
  innovativeSuccess,
  innovativeByIdSuccess,
  innovativeByDateSuccess,
} = InnovativeTeaching.actions;

export default InnovativeTeaching.reducer;

export const getInnovative = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.employeeActivity.name,
            date: data1.date,
            employeeActivity: data1.employeeActivity.id,
            subjectIds: data1.subjectIds,
            activityDetail: data1.activityDetail,
            employeeInnovativeTeachingDocument:
              data1.employeeInnovativeTeachingDocument.map((folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }),
          };
          row.push(bData);
          return data1;
        });
        dispatch(innovativeSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminInnovative =
  ({ userRegistration }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/by-user-registration?userRegistrationId=" + userRegistration,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.employeeActivity.name,
              date: data1.date,
              employeeActivity: data1.employeeActivity.id,
              subjectIds: data1.subjectIds,
              activityDetail: data1.activityDetail,
              employeeInnovativeTeachingDocument:
                data1.employeeInnovativeTeachingDocument.map(
                  (folder, index) => {
                    folder = {
                      index: index + 1,
                      ...folder,
                    };
                    return folder;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(innovativeSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInnovativeByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-innovative-teaching-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.employeeActivity.name,
              date: data1.date,
              employeeActivity: data1.employeeActivity.id,
              subjectIds: data1.subjectIds,
              activityDetail: data1.activityDetail,
              employeeInnovativeTeachingDocument:
                data1.employeeInnovativeTeachingDocument.map(
                  (folder, index) => {
                    folder = {
                      index: index + 1,
                      ...folder,
                    };
                    return folder;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(innovativeSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInnovativeWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-innovative-teaching-list-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.employeeActivity.name,
            date: data1.date,
            employeeActivity: data1.employeeActivity.id,
            subjectIds: data1.subjectIds,
            activityDetail: data1.activityDetail,
            employeeInnovativeTeachingDocument:
              data1.employeeInnovativeTeachingDocument.map((folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }),
          };
          row.push(bData);
          return data1;
        });
        dispatch(innovativeSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setInnovativeTeaching = (row) => async (dispatch) => {
  dispatch(innovativeSuccess({ row }));
};
