import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../../../components/Comman/IconButton";

function Row({
  row,
  rowAdd,
  rowDetailDelete,
  rowDetailEdit,
  rowDetailStatus,
  rowStatus,
  rowDelete,
  rowEdit,
}) {
  const [open, setOpen] = React.useState(false);

  const changeStatus = (e, id, status) => {
    e.stopPropagation();
    rowStatus(id, status);
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };

  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };

  React.useEffect(() => {});
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="td" scope="row">
          <center> {row.index}</center>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.code}</TableCell>
        <TableCell>{row.patternName}</TableCell>
        <TableCell>{row.semisterName}</TableCell>
        <TableCell>{row.subjectTypeName}</TableCell>
        <TableCell>{row.yearDetailName}</TableCell>
        <TableCell>
          {row.isActive === 1 ? (
            <center>
              {" "}
              <ChipCompo
                label="Active"
                variant="outlined"
                size="small"
                color="success"
                onClick={(e) => changeStatus(e, row.id, row.isActive)}
              />
            </center>
          ) : (
            <center>
              <ChipCompo
                label="Inactive"
                variant="outlined"
                size="small"
                color="error"
                onClick={(e) => changeStatus(e, row.id)}
              />
            </center>
          )}
        </TableCell>
        <TableCell>
          <MyComponentWithIconProps
            statusImage={EditIcon}
            color="primary"
            fontSize="medium"
            title="Edit"
            onClick={(e) => onClickEdit(e, row.id)}
          />
          &nbsp;&nbsp;
          <MyComponentWithIconProps
            statusImage={DeleteIcon}
            color="error"
            fontSize="medium"
            title="Delete"
            onClick={(e) => onClickDelete(e, row.id)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <center>Sr. No.</center>
                    </TableCell>
                    <TableCell>Scheme </TableCell>
                    <TableCell>Scheme Head </TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Minimum Marks</TableCell>
                    <TableCell>Maximum Marks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subjectDetails.map((historyRow, index) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="td" scope="row">
                        <center> {index + 1}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {historyRow.schemeName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {historyRow.schemeHeadName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <center> {historyRow.value}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <center> {historyRow.minMarks}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <center> {historyRow.maxMarks}</center>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  rowAdd,
  rowDetailEdit,
  rowDetailDelete,
  rowDetailStatus,
  rowStatus,
  rowDelete,
  rowEdit,
  data,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ minWidth: 520 }}>
              <center>Sr. No.</center>
            </TableCell>
            <TableCell style={{ minWidth: 120 }}>Subject Name</TableCell>
            <TableCell style={{ minWidth: 120 }}> Code </TableCell>
            <TableCell style={{ minWidth: 120 }}>Pattern Name</TableCell>
            <TableCell style={{ minWidth: 120 }}>Semister Name</TableCell>
            <TableCell style={{ minWidth: 120 }}>Subject Type Name</TableCell>
            <TableCell style={{ minWidth: 120 }}>Year Name</TableCell>
            <TableCell style={{ minWidth: 120 }}>
              <center>Status</center>
            </TableCell>
            <TableCell style={{ minWidth: 200 }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row.id}
              row={row}
              rowEdit={rowEdit}
              rowAdd={rowAdd}
              rowStatus={rowStatus}
              rowDelete={rowDelete}
              rowDetailStatus={rowDetailStatus}
              rowDetailEdit={rowDetailEdit}
              rowDetailDelete={rowDetailDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
