import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { semisterDetailsMasterJson } from "../../../../DynamicFormsJson/SemisterDetails.js";
import { SemisterDetailsColumns } from "../../../../tableColumns/table-columns";
import { getSemisterDetails } from "./semisterDetails.slice"
import { getAcademicYear } from "../../OnlineModule/AcademicYear/academicYear.slice"
import { getAlert } from "../../../../CommonActions/alert.slice";
class SemisterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        academicYearData: this.props.academicYearList?.academicYear,
      },
    };
  }

  componentDidMount() {
    this.props.getSemisterDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

  }

  componentWillReceiveProps(nextProps) {

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (this.props.academicYearList.academicYear !== nextProps.academicYearList.academicYear) {
        this.setState({
          dynamicMasterData: { ...this.state.dynamicMasterData, academicYearData: nextProps.academicYearList.academicYear }
        })
      }
    }
  }

  render() {
    const { dynamicMasterData } = this.state;
    const { semisterDetailList } = this.props

    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={semisterDetailsMasterJson.screenTitle}
            fieldMeta={semisterDetailsMasterJson.fieldMeta}
            showPdfDownload={semisterDetailsMasterJson.showPdfDownload}
            showExcelDownload={semisterDetailsMasterJson.showExcelDownload}
            pdfFileName={semisterDetailsMasterJson.pdfFileName}
            excelFileName={semisterDetailsMasterJson.excelFileName}
            showAddButton={semisterDetailsMasterJson.showAddButton}
            tableColumnsToFilter={semisterDetailsMasterJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            getTableData={this.props.getSemisterDetails}
            tableColumns={SemisterDetailsColumns}
            tableData={semisterDetailList.semisterDetails}
            onDelete={this.onDelete}
            onSave={this.onSave}
            baseIdColumn={semisterDetailsMasterJson.baseIdColumn}
            apiBaseURL={semisterDetailsMasterJson.apiBaseURL}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  semisterDetailList: state.semisterDetails,
  academicYearList: state.academicYear


});
const mapDispatchToProps = {
  getSemisterDetails,
  getAcademicYear,
  getAlert
};
export default connect(mapStateToProps, mapDispatchToProps)(SemisterDetails);
