import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import { apiPost } from "../../../utils/api_service";
import { showNotification } from "../../Landing/Landing.slice";
import { getSyllabus } from "../../TeachingPlan/teachingPlan.slice";

const CompletedSyllabusView = ({
  syllabus = [],
  saveHandler,
  getAlert,
  editSyllabus,
  editData = {},
  changeSyllabus,
  stateData,
  showNotification,
  studentAttendanceList,
  getSyllabus,
}) => {
  //   const [moduleList, setModuleList] = React.useState([]);
  useEffect(() => {
    if (editSyllabus.length !== 0) {
      const chapterList = syllabus.map((chapterData) => {
        const sectionList = chapterData.sectionList.map((sectionData) => {
          const subSectionList = sectionData.subSectionList.map(
            (subSectionData) => {
              const filterData = editSyllabus.filter(
                (rowData) => subSectionData.id === rowData.syllabusSubSection.id
              );
              if (filterData.length !== 0) {
                subSectionData = {
                  ...subSectionData,
                  checked: true,
                  attandaceSyllabusId: filterData[0].id,
                };
                return subSectionData;
              }
              return subSectionData;
            }
          );
          sectionData = {
            ...sectionData,
            subSectionList: subSectionList,
          };
          return sectionData;
        });
        chapterData = {
          ...chapterData,
          sectionList: sectionList,
        };

        return chapterData;
      });
      changeSyllabus(chapterList);
    }
  }, []);
  // http://localhost:8088/api/acdmc/v1/subject-teaching-plan
  const submitHandler = () => {
    const subjectSyllbus = [
      {
        subjectTeachingPlan: {
          id: "6ee8dffe-3752-40bc-ba09-9f0b0ba47459",
        },
        syllabusSubSection: {
          id: "0b6a18d9-9117-488f-9a78-ac2d895bebd5",
        },
        isActive: 1,
      },
      {
        subjectTeachingPlan: {
          id: "7896eb5c-3189-446a-b69a-2ff1429f5901",
        },
        syllabusSubSection: {
          id: "0b6a18d9-9117-488f-9a78-ac2d895bebd5",
        },
        isActive: 1,
      },
    ];
    console.log(stateData);
    let dataToSave = {
      semisterDetail: {
        id: stateData.semister,
      },
      subjectDetail: {
        id: stateData.subject,
      },
      division: {
        id: stateData.division,
      },
      batch: {
        id: stateData.batch,
      },
      topicTaught: stateData.topic,
      date: moment(stateData.date).format("DD-MM-YYYY"),
      remark: stateData.remark,
      isActive: 1,
    };
    console.log(dataToSave);
    apiPost({
      url: endpoint.teachingPlan,
      postBody: dataToSave,
    }).then(({ data, success }) => {
      if (success) {
        const subjectList = [];
        syllabus.map((chapterData) => {
          chapterData.sectionList.map((section) => {
            section.subSectionList.map((subSection) => {
              if (subSection.checked) {
                const subsectionData = {
                  subjectTeachingPlan: {
                    id: data.data.id,
                  },
                  syllabusSubSection: {
                    id: subSection.id,
                  },
                  isActive: 1,
                };
                subjectList.push(subsectionData);
              }
            });
          });
        });

        apiPost({
          url: endpoint.subjectTeachingPlanDetail,
          postBody: subjectList,
        }).then(({ data, success }) => {
          if (success) {
            showNotification({
              msg: "Data saved successfully",
            });
            window.location.replace("/teaching-plan-teacher-view");
          } else {
            getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  const onClickChange = (e, rowData) => {
    const chapterList = syllabus.map((chapterData) => {
      if (chapterData.id === rowData.id) {
        chapterData = {
          ...chapterData,
          checked: e.target.checked,
          sectionList: chapterData.sectionList.map((sectionData) => {
            sectionData = {
              ...sectionData,
              checked: e.target.checked,
              subSectionList: sectionData.subSectionList.map(
                (subSectionData) => {
                  subSectionData = {
                    ...subSectionData,
                    checked: e.target.checked,
                  };
                  return subSectionData;
                }
              ),
            };
            return sectionData;
          }),
        };
        return chapterData;
      }
      return chapterData;
    });
    changeSyllabus(chapterList);
  };
  const onClickSection = (e, rowData, section) => {
    const chapterList = syllabus.map((chapterData) => {
      if (chapterData.id === rowData.id) {
        const sectionList = chapterData.sectionList.map((sectionData) => {
          if (sectionData.id === section.id) {
            sectionData = {
              ...sectionData,
              checked: e.target.checked,
              subSectionList: sectionData.subSectionList.map(
                (subSectionData) => {
                  subSectionData = {
                    ...subSectionData,
                    checked: e.target.checked,
                  };
                  return subSectionData;
                }
              ),
            };
            return sectionData;
          }
          return sectionData;
        });
        chapterData = {
          ...chapterData,
          sectionList: sectionList,
        };
        return chapterData;
      }
      return chapterData;
    });
    changeSyllabus(chapterList);
  };

  const onClickSubSection = (e, section, subSection) => {
    const chapterList = syllabus.map((chapterData) => {
      if (chapterData.id === section.chapterId) {
        const sectionList = chapterData.sectionList.map((sectionData) => {
          if (sectionData.id === section.id) {
            const subSectionList = sectionData.subSectionList.map(
              (subSectionData) => {
                if (subSectionData.id === subSection.id) {
                  subSectionData = {
                    ...subSectionData,
                    checked: e.target.checked,
                  };
                  return subSectionData;
                }
                return subSectionData;
              }
            );
            sectionData = {
              ...sectionData,
              subSectionList: subSectionList,
            };
            return sectionData;
          }
          return sectionData;
        });
        chapterData = {
          ...chapterData,
          sectionList: sectionList,
        };
        return chapterData;
      }
      return chapterData;
    });
    changeSyllabus(chapterList);
  };
  console.log(editSyllabus);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Sr. No.</TableCell>
              <TableCell width="30%">Chapter Name</TableCell>
              <TableCell width="30%">Section </TableCell>
              <TableCell width="30%">Sub Section </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {syllabus.map((row, index) => (
              <>
                <TableRow key={row.id}>
                  <TableCell align="center">{index + 1}</TableCell>

                  <TableCell component="th" scope="row" colSpan={3}>
                    {row.sectionList.length !== 0 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"Course" + index}
                              checked={row.checked}
                              id={"Course" + index}
                              disabled={true}
                            />
                          }
                          onChange={(e) => onClickChange(e, row)}
                          label={row.chapter}
                          labelPlacement="right"
                        />
                      </FormGroup>
                    ) : (
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={row.chapter}
                      />
                    )}
                  </TableCell>
                </TableRow>

                {row.sectionList.map((section, index) => {
                  return (
                    <>
                      <TableRow key={section.id}>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell component="th" scope="row" colSpan={2}>
                          {section.subSectionList.length !== 0 ? (
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={"Course" + index}
                                    checked={section.checked}
                                    id={"Course" + index}
                                    disabled={true}
                                  />
                                }
                                label={section.sectionName}
                                onChange={(e) =>
                                  onClickSection(e, row, section)
                                }
                                labelPlacement="right"
                              />
                            </FormGroup>
                          ) : (
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={section.sectionName}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {section.subSectionList.map((subSection, index) => {
                        return (
                          <>
                            <TableRow key={subSection.id}>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center" colSpan={2}></TableCell>
                              <TableCell component="th" scope="row">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={"Course" + index}
                                        checked={subSection.checked}
                                        id={"Course" + index}
                                        disabled={true}
                                      />
                                    }
                                    onChange={(e) =>
                                      onClickSubSection(e, section, subSection)
                                    }
                                    label={subSection.subSectionName}
                                    labelPlacement="right"
                                  />
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      <TableRow key={row.chapter}></TableRow>
                    </>
                  );
                })}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
const mapStateToProps = (state) => ({
  student: state.studentList,
  studentAttendanceList: state.studentAttendance,
  teachingPlanList: state.teachingPlan,
});

const mapDispatchToProps = {
  getAlert,
  showNotification,
  getSyllabus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedSyllabusView);
