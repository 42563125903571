import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import {
  default as endpoint,
  default as endpoints,
} from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import EmployeeAppliedList from "../Employee Feedback/EmployeeAppliedList";
import EmployeeFeedbackDeclare from "../Employee Feedback/EmployeeFeedbackDeclare";

import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { EmpFeedbackJson } from "../../../DynamicFormsJson/EmpFeedbackDynamic";
import { downloadF } from "../../PDF/PDFDownload";
import {
  getFeedback,
  getFeedbackSelfFromDtToDt,
  getFeedbackSelfFromDtToDtFilter,
  resetValue,
} from "../Employee Feedback/feedback.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: 100,
  },
  {
    name: "feedbackName",
    title: "Feedback name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    width: "15%",
  },
  {
    name: "department",
    title: "Department",
    positionCenter: false,
    showInscreen: true,
    canSearch: true,
    alignCenter: "left",
    width: "15%",
  },
  {
    name: "fromDate",
    title: "From Date",
    positionCenter: false,
    showInscreen: true,
    width: 100,
    canSearch: true,
    alignCenter: "center",
  },
  {
    name: "toDate",
    title: "To Date",
    positionCenter: false,
    showInscreen: true,
    canSearch: true,
    alignCenter: "center",
    width: "13%",
  },
  {
    name: "description",
    title: "Instruction",
    canSearch: true,
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",

    width: "10%",
  },
  {
    name: "isActive",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "5%",
  },
];

class ViewDeclaredFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showAppliedForm: false,
      showAddCommitteeForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      department: "",
      academicYearId: "",
      fromDate: "",
      toDate: "",
      departmentList: [],
      editData: {},
      appliedData: {},
      formErrors: [],
      showTheTable: false,
      searchValue: "",
      showLoader: false,
    };
  }

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  // API Remaining to consume
  pdfDetailsId = async (rowData) => {
    let userAppReportURL = endpoints.certificateReport;
    downloadF({
      url:
        userAppReportURL +
        "/" +
        rowData.userRegistrationId +
        "/get-student-certificate?type=" +
        rowData.type,
    });
  };

  onSearchData = (dataToSearch) => {
    this.setState({
      fromDate: dataToSearch.fromDate,
      showLoader: true,
      toDate: dataToSearch.toDate,
    });

    this.props
      .getFeedbackSelfFromDtToDt({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onChangeStatus = (rowData) => {
    const { fromDate, toDate } = this.state;

    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        let newData = {
          id: rowData.id,
          feedbackHead: { id: rowData.feedbackNameId },
          feedbackDetails: rowData.description,
          deptAuthorityToView: rowData.deptAuthorityToView,
          empAuthorityToView: rowData.empAuthorityToView,
          departmentIds: rowData.departmentIds,
          fromDate: rowData.fromDate,
          toDate: rowData.toDate,
          isActive: activeStatus,
          type: 0,
        };

        apiPost({
          url: endpoint.employeeFeedback,
          postBody: newData,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            if (fromDate !== "" && toDate !== "") {
              this.props.showNotification({ msg: "Status Updated" });
              let frmDt = moment(fromDate).format("YYYY-MM-DD");
              let toDt = moment(toDate).format("YYYY-MM-DD");
              this.props
                .getFeedbackSelfFromDtToDt({
                  fromDate: frmDt,
                  toDate: toDt,
                })
                .then((response) => {
                  if (!response) {
                    this.props.getAlert({ message: "Something went wrong" });
                  }
                });
            } else {
              this.props.showNotification({ msg: "Status Updated" });
              this.props.getFeedbackSelfFromDtToDtFilter().then((response) => {
                if (!response) {
                  this.setState({
                    showLoader: false,
                  });
                  this.props.getAlert({ message: "Something went wrong" });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
            }
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  toggleFormTableVisibility = () => {
    const {
      showForm,
      showTable,
      showAddCommitteeForm,
      editData,
      showAppliedForm,
      appliedData,
    } = this.state;
    this.setState({
      showForm: true,
      showAppliedForm: false,
      showTable: false,
      editData: {},
      appliedData: {},
    });
  };

  backToForm = () => {
    this.setState({
      showForm: false,
      showAppliedForm: false,
      showTable: true,
      showAppliedStudentsForm: false,
    });
    this.props.resetReducList();
  };

  backTo = () => {
    this.setState({
      showTable: true,
    });
  };

  onFormCancel = () => {
    this.setState({
      showForm: true,
      showAppliedForm: false,
      showTable: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.departments.department,
          { id: "0", name: "All" },
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    // const { fieldMeta } = this.props;
    // fieldMeta.map((currentField) => {
    // if (currentField.defaultValue) {
    // fieldData[currentField.dataKey] = currentField.defaultValue;
    // }
    // });

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
      fromDate: "",
      toDate: "",
    });
    this.toggleFormTableVisibility();
    this.props.resetValue();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowEdit = (data) => {
    this.setState({
      showAppliedForm: false,
      showTable: false,
      showForm: true,
      editData: data,
      fromDate: "",
      toDate: "",
    });
    this.props.resetValue();
  };

  appliedList = (data) => {
    this.setState({
      showTable: false,
      showForm: false,
      showAppliedForm: true,
      appliedData: data,
      fromDate: "",
      toDate: "",
    });
    this.props.resetValue();
  };

  backToAppliedForm = (data) => {
    this.setState({
      showTable: true,
      showForm: false,
      showAppliedForm: false,
    });
  };

  componentDidMount() {
    this.props.resetReducList();

    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.setState({
      showLoader: true,
    });
    this.props.getFeedbackSelfFromDtToDtFilter().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.employeeFeedback + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { fromDate, toDate } = this.state;
        let frmDt = moment(fromDate).format("YYYY-MM-DD");
        let toDt = moment(toDate).format("YYYY-MM-DD");
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props
          .getFeedbackSelfFromDtToDt({
            fromDate: frmDt,
            toDate: toDt,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  generateDetails = (rowData) => {
    apiGet({
      url:
        endpoint.employeeFeedback +
        "/" +
        rowData.id +
        "/generate-employee-feedback-link",
    }).then(({ data, success }) => {
      if (success) {
        swal(
          "URL generated successfully",
          "http://" +
            window.location.host +
            endpoint.outSideEmployeeLink +
            "?token=" +
            data.data.message,
          "success"
        );
      }
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.feedbackList?.feedbackByDate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["feedbackName"] &&
          currentRow["feedbackName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["fromDate"] &&
          currentRow["fromDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["toDate"] &&
          currentRow["toDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["department"] &&
          currentRow["department"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["description"] &&
          currentRow["description"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = false,
      showAddButton = true,
      departments,
      employeeDetails,
      academicYear,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      showAppliedForm,
      showAddCommitteeForm,
      department,
      departmentList,
      showAppliedStudentsForm,
      academicYearId,
      showCommittee,
      fromDate,
      toDate,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        {/* <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}> */}
        {/* <NewLandingScreenHeader
 screenTitle={EmployeeFeedbackJson.screenTitle}
 showPdfDownload={false}
 showExcelDownload={false}
 showAddButton={false}
 onAddButtonClick={this.onAddButtonClick}
 showTable={showTable}
 /> */}
        {showForm && (
          <>
            <EmployeeFeedbackDeclare
              onCancel={this.backToForm}
              editData={this.state.editData}
            />
          </>
        )}

        {showAppliedForm && (
          <>
            <EmployeeAppliedList
              onCancel={this.backToForm}
              appliedData={this.state.appliedData}
              backToAppliedForm={this.backToAppliedForm}
            />
          </>
        )}

        {showTable && (
          <>
            {/* <Grid
 container
 sx={{ marginTop: 3 }}
 justifyContent="space-evenly"
 direction="row"
 >
 <Grid
 item
 sm={12}
 xs={12}
 md={12}
 lg={12}
 columnSpacing={1}
 container
 justifyContent="space-evenly"
 >
 <Grid
 item
 xs={12}
 sm={12}
 md={5.5}
 lg={5.5}
 columnSpacing={1}
 container
 justifyContent="space-evenly"
 >
 <Grid item xs={12} sm={12} md={12}>
 <LabelCompo
 className="text-black"
 style={{ fontSize: 16 }}
 label="*From Date "
 />
 </Grid>

 <Grid item xs={12} sm={12} md={12}>
 <BasicDatePicker
 color="primary"
 name="fromDate"
 size="small"
 value={fromDate}
 fullWidth
 onChange={this.onDateChange( "fromDate" )}
 isError={fromDateError ? true : false}
 errorText={fromDateError ? fromDateError : " "}
 />
 </Grid>
 </Grid>
 <Grid
 item
 xs={12}
 sm={12}
 md={5.5}
 lg={5.5}
 container
 justifyContent="space-evenly"
 >
 <Grid item xs={12} sm={12} md={12}>
 <LabelCompo
 className="text-black"
 style={{ fontSize: 16 }}
 label="*To Date "
 />
 </Grid>

 <Grid item xs={12} sm={12} md={12}>
 <BasicDatePicker
 color="primary"
 name="toDate"
 size="small"
 value={toDate}
 fullWidth
 onChange={this.onDateChange( "toDate" )}
 isError={toDateError ? true : false}
 errorText={toDateError ? toDateError : " "}
 />
 </Grid>
 </Grid>
 <Grid
 item
 sm={12}
 xs={12}
 md={1}
 lg={1}
 container
 justifyContent="flex-end"
 >
 <Grid item xs={12} sm={12} md={12}></Grid>

 <Grid item xs={12} sm={12} md={12}>
 <ButtonCompo
 size="medium"
 type="button"
 variant="contained"
 name="Search"
 onClick={this.submitHandler}
 />
 </Grid>
 </Grid>
 </Grid>
 </Grid>
 <Grid
 sx={{ marginTop: 1, marginBottom: 2 }}
 item
 xs={12}
 sm={12}
 md={12}
 >
 <SearchExternalPlacedStudList onSearch={this.onSearch} />
 </Grid>
 <Grid item>
 <DynamicTable
 data={this.getFilteredTableData()}
 tableHead={columns}
 showHeadDelete={true}
 showHeadEdit={true}
 showAppliedList={true}
 showLinkIcon={true}
 generateDetails={this.generateDetails}
 showPegination={false}
 rowDelete={this.rowDelete}
 rowEdit={this.OnClickEdit}
 rowAppliedList={this.appliedList}
 onCancel={this.backToForm}
 rowStatus={this.onChangeStatus}
 />
 </Grid> */}

            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={EmpFeedbackJson.screenTitle}
              fieldMeta={EmpFeedbackJson.fieldMeta}
              buttonCenter={EmpFeedbackJson.buttonCenter}
              showPdfDownload={EmpFeedbackJson.showPdfDownload}
              showExcelDownload={EmpFeedbackJson.showExcelDownload}
              tableColumnsToFilter={EmpFeedbackJson.tableColumnsToFilter}
              showAddButton={EmpFeedbackJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={columns}
              isActionColActive={false}
              tableData={this.props.feedbackList?.feedbackByDate}
              showPegination={true}
              showHeadEdit={false}
              showHeadDelete={false}
              showAppliedList={false}
              showLinkIcon={false}
              showLoader={this.state.showLoader}
              pdfDetailsId={this.pdfDetails}
              showHeadPdf={false}
              generateDetails={this.generateDetails}
              rowDelete={this.rowDelete}
              rowEdit={this.rowEdit}
              rowStatus={this.onChangeStatus}
              rowAppliedList={this.appliedList}
              onSearchData={this.onSearchData}
              baseIdColumn={EmpFeedbackJson.baseIdColumn}
              apiBaseURL={EmpFeedbackJson.apiBaseURL}
            />
          </>
        )}

        {/* {this.state.showTheTable &&
 <EmployeeFeedBackDetailTable onCancle={this.backToAppliedForm} />
 } */}
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYear: state.academicYear,
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
  feedbackList: state.empFeedback,
});
const mapDispatchToProps = {
  showNotification,
  getFeedback,
  getAlert,
  getDepartment,
  getAcademicYear,
  getFeedbackSelfFromDtToDt,
  resetReducList,
  resetValue,
  getFeedbackSelfFromDtToDtFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDeclaredFeedback);
