export const teacherViewTableJson = {
    screenTitle: "Teaching Plan",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    getAcadamicYear: true,
    getListId: 'yearDetail',
    fieldMeta: [
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "yearDetailMaster",
            getListId: 'yearDetail',
            dataKey: "yearDetail",
            isMandatory: true,
        },
        {
            label: "Semester Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "semesterDetailMaster",
            getListId: "semister,division,batch",
            getListFrom: "yearDetail",
            dataKey: "semister",
            isMandatory: true,
        },
        {
            label: "Division",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "divisionMaster",
            getListId: "division,semister,batch",
            getListFrom: "semister",
            dataKey: "division",
            isMandatory: true,
        },
        {
            label: "Batch",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "batchMaster",
            getListFrom: "division,semister",
            getListId: "division,semister,batch",
            dataKey: "batch",
            isMandatory: true,
        },
        {
            label: "Subject",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "subjectMaster",
            getListFrom: "division,semister,batch",
            dataKey: "subject",
            isMandatory: true,
        },
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "fromDate",
            isMAxDate: false,
            // maxDate: new Date(),
            isMinDate: false,
            // minDate: new Date(),
            isMandatory: false,
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "toDate",
            isMAxDate: false,
            isMinDate: true,
            isMandatory: false,
            // onSubmit: "compare",
            // onSubmitParameter: "fromDate-g"
        },

    ],
};
