import InfoIcon from "@mui/icons-material/Info";
import { Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import { getEmployeeByDeptId } from "../../EmployeeDetails/employee.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { showNotification } from "../../Landing/Landing.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../../Masters/OnlineModule/Class/class.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getFeedBack,
  getfeedBackDetailsByFeedBackIdStudentPreview,
  getfeedBackId,
  resetValues,
} from "../AddAndViewFeedBack/addFeedback.slice";
import { getStudentFeedbackDetails } from "../Student Feedback/feedback.slice";
import StudentFeedbackPreview from "./StudentFeedbackPreview";
import { getFeedbackFromDtToDtFilter } from "./feedback.slice";

let docs;
class StudentFeedbackDeclare extends Component {
  state = {
    id: 0,
    feedbackName: "",
    employee: [],
    description: "",
    department: [],
    role: [],
    semester: "",
    yearDetails: "",
    fromDate: "",
    toDate: "",
    studentFeedBack: 0,
    label: "",
    formErrors: [],
    division: [],
    dept: [],
    showPreview: false,
    showForm: true,
    isShowSubject: true,
  };

  multiSelectHandle = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  multiSelectHandler = (value, name) => {
    if (value !== null) {
      this.setState({ [name]: value });
      this.props
        .getEmployeeByDeptId({ departmentId: value })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  backToForm = () => {
    this.setState({
      showPreview: false,
      showForm: true,
    });
  };

  showStudentPreview = () => {
    this.props.resetValues();
    const { feedbackName } = this.state;
    if (feedbackName != null || feedbackName != "") {
      this.props
        .getfeedBackId({
          feedbackId: feedbackName,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getfeedBackDetailsByFeedBackIdStudentPreview({
          feedBackId: feedbackName,
        })
        .then((response) => {
          this.setState({
            showPreview: true,
            showForm: false,
          });
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    } else {
      this.props.getAlert({ message: "Something went wrong" });
    }
  };

  // ChangeHandlerSearch = ( name, newValue ) =>
  // {
  //   if ( newValue !== null )
  //   {
  //     this.setState( { [ name ]: newValue } );
  //   }
  //   else
  //   {
  //     this.setState( { [ name ]: '' } );
  //   }
  // };

  ChangeHandlerSearch = (name, newValue) => {
    const { yearDetails } = this.state;
    const { getSemesterDetailsByYearAndAcademic } = this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name == "yearDetails") {
        this.setState({
          semester: "",
          division: [],
        });
        getSemesterDetailsByYearAndAcademic({
          yearId: newValue,
          academicId: "",
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });

        this.props
          .getDistinctDivision({
            yearDetail: newValue,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  toTimeChangeHandler = (time) => {
    this.setState({
      time: time,
    });
  };

  handleFormValidation() {
    const {
      feedbackName,
      description,
      yearDetails,
      semester,
      fromDate,
      toDate,
      employee,
      department,
      role,
      venue,
      time,
      applicationLastDate,
      studentFeedBack,
      label,
      dept,
      division,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (feedbackName == "" || feedbackName === null) {
      formIsValid = false;
      formErrors["feedbackError"] = myConstClass.feedbackMsg;
    }

    if (!dept.length) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    if (!employee.length) {
      formIsValid = false;
      formErrors["employeeError"] = myConstClass.employeemsg;
    }

    // if ( description.toString().trim() === "" || description === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "descriptionError" ] = myConstClass.instructionsMsg;
    // }

    if (yearDetails.toString().trim() === "" || yearDetails === null) {
      formIsValid = false;
      formErrors["yearDetailsError"] = myConstClass.yearDetailMsg;
    }

    if (semester.toString().trim() === "" || semester === null) {
      formIsValid = false;
      formErrors["semesterError"] = myConstClass.semisterMsg;
    }

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }
    if (!division.length) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divisionNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    const { eventDataForStudFeedback } = this.props;
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getFeedBack().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    // for Edit Dataa

    const { editData } = this.props;

    if (editData != undefined || editData != null) {
      if (Object.keys(editData).length !== 0) {
        this.props
          .getEmployeeByDeptId({ departmentId: editData.deptAuthorityToView })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

        this.props
          .getSemesterDetailsByYearAndAcademic({
            yearId: editData.yearDetail,
            academicId: "",
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

        this.props
          .getDistinctDivision({
            yearDetail: editData.yearDetail,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

        const fromDateARR =
          editData.fromDate == null ? "" : editData.fromDate.split("-");
        const fromDt =
          fromDateARR == ""
            ? ""
            : new Date(fromDateARR[2], fromDateARR[1] - 1, fromDateARR[0]);
        const toDateARR =
          editData.toDate == null ? "" : editData.toDate.split("-");
        const toDt =
          toDateARR == ""
            ? ""
            : new Date(toDateARR[2], toDateARR[1] - 1, toDateARR[0]);
        this.setState({
          ...this.state,
          id: editData.id,
          feedbackName: editData.feedbackHeadId,
          description: editData.feedbackDetails,
          fromDate: fromDt,
          toDate: toDt,
          semester: editData.semisterDetail,
          division: editData.divisionIds.split(","),
          employee: editData.empAuthorityToView.split(","),
          dept: editData.deptAuthorityToView.split(","),
          yearDetails: editData.yearDetail,
          studentFeedBack: editData.isSubjectData == "Yes" ? 1 : 0,
        });
        if (editData.eventId !== "") {
          this.setState({
            isShowSubject: false,
          });
        }
      }
    }
    if (eventDataForStudFeedback != undefined) {
      this.setState({
        isShowSubject: false,
      });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { eventDataForStudFeedback } = this.props;
    const {
      feedbackName,
      description,
      fromDate,
      toDate,
      department,
      studentFeedBack,
      semester,
      division,
      employee,
      dept,
      id,
    } = this.state;
    let frmDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    if (eventDataForStudFeedback != undefined) {
      docs = {
        eventId: eventDataForStudFeedback.id,
        feedbackHead: { id: feedbackName },
        feedbackDetails: description,
        divisionIds: division.join(),
        fromDate: frmDt,
        toDate: toDt,
        isSubject: 0,
        isActive: 1,
        deptAuthorityToView: dept.join(),
        empAuthorityToView: employee.join(),
        type: 1,
        semisterDetail: { id: semester },
      };
    } else {
      docs = {
        feedbackHead: { id: feedbackName },
        feedbackDetails: description,
        divisionIds: division.join(),
        fromDate: frmDt,
        toDate: toDt,
        isSubject: studentFeedBack,
        isActive: 1,
        deptAuthorityToView: dept.join(),
        empAuthorityToView: employee.join(),
        type: 1,
        semisterDetail: { id: semester },
      };
      if (id !== 0) {
        docs = {
          ...docs,
          id: id,
        };
      }
    }

    if (this.handleFormValidation()) {
      swal({
        title: "Do you want to submit the information?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((response) => {
        if (response) {
          this.onSave(docs);
        }
      });
    }
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.studentFeedback,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });

        this.props.getFeedbackFromDtToDtFilter().then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
        this.props.onCancel();
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  render() {
    const {
      feedbackName,
      description,
      fromDate,
      toDate,
      studentFeedBack,
      employee,
      yearDetails,
      semester,
      division,
      dept,
      isShowSubject,
    } = this.state;
    const { onCancel, internshipDeclarationList } = this.props;
    const {
      feedbackError,
      departmentError,
      fromDateError,
      toDateError,
      descriptionError,
      divisionError,
      yearDetailsError,
      semesterError,
      employeeError,
    } = this.state.formErrors;

    return (
      <>
        {this.state.showForm && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="Feedback Declare Form (For Student)" />
            </Paper>
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <form
                onSubmit={this.submitHandler}
                autoComplete="off"
                noValidate={true}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={2}
                  rowSpacing={4}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      sx={{ marginBottom: 2 }}
                      item
                      xs={12}
                      sm={10.5}
                      md={10.5}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label=""
                      />
                    </Grid>
                    <Grid
                      sx={{ marginBottom: 2 }}
                      item
                      xs={12}
                      sm={1.5}
                      md={1.5}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back to List"
                        onClick={onCancel}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={11.5}
                      md={11.5}
                      lg={11.5}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Feedback"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={feedbackName}
                          name={"feedbackName"}
                          options={this.props.feedbackList?.feedBack.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.ChangeHandlerSearch}
                          isError={feedbackError ? true : false}
                          errorText={feedbackError ? feedbackError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={0.5}
                      md={0.5}
                      lg={0.5}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        &nbsp;
                      </Grid>
                      {this.state.feedbackName !== "" && (
                        <>
                          <Grid
                            sx={{ marginBottom: 3 }}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <MyComponentWithIconProps
                              statusImage={InfoIcon}
                              color="primary"
                              fontSize="large"
                              title="Details"
                              onClick={this.showStudentPreview}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    sx={{ marginTop: -3 }}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Department"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={departmentError}
                          name1="dept"
                          value1={dept}
                          names={this.props.departmentList?.department.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.multiSelectHandler}
                        />
                      </Grid>
                    </Grid>
                    {/*  */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Employee(Authority to see the response)"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={employeeError}
                          name1="employee"
                          value1={employee}
                          names={this.props.employeeList?.employeeList.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.multiSelectHandle}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*  */}

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    sx={{ marginTop: -3 }}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Instructions"
                        />
                      </Grid>

                      <Grid sx={{ marginTop: -3 }} item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="description"
                          size="small"
                          value={description}
                          onChange={this.changeHandler}
                          fullWidth
                          // error={descriptionError ? true : false}
                          // helperText={descriptionError ? descriptionError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Year Detail"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={yearDetails}
                          name={"yearDetails"}
                          options={this.props.divisionList?.yearDetails.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.ChangeHandlerSearch}
                          isError={yearDetailsError ? true : false}
                          errorText={yearDetailsError ? yearDetailsError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*  */}

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    sx={{ marginTop: -3 }}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Semester Detail"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={semester}
                          name={"semester"}
                          options={this.props.semisterList?.semisterDetailsList.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.ChangeHandlerSearch}
                          isError={semesterError ? true : false}
                          errorText={semesterError ? semesterError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Division"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={divisionError}
                          name1="division"
                          value1={division}
                          names={this.props.divisionList?.distinctDivision.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.multiSelectHandle}
                          isError={divisionError ? true : false}
                          errorText={divisionError ? divisionError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    sx={{ marginTop: -3 }}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*From Date "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="fromDate"
                          size="small"
                          value={fromDate}
                          fullWidth
                          onChange={this.onDateChange("fromDate")}
                          isError={fromDateError ? true : false}
                          errorText={fromDateError ? fromDateError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*To Date "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="toDate"
                          size="small"
                          value={toDate}
                          fullWidth
                          minDate={fromDate}
                          onChange={this.onDateChange("toDate")}
                          isError={toDateError ? true : false}
                          errorText={toDateError ? toDateError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {isShowSubject && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                      sx={{ marginTop: -2 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Is Subject Feedback"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="studentFeedBack"
                            id="studentFeedBack"
                            name="studentFeedBack"
                            value={studentFeedBack}
                            onChange={this.changeHandler}
                            size="small"
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    sx={{ marginTop: 2 }}
                    container
                    columnSpacing={1}
                    justifyContent="right"
                  >
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                        onClick={onCancel}
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save"
                        onClick={this.submitHandler}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </>
        )}

        {this.state.showPreview && (
          <>
            <StudentFeedbackPreview backToForm={this.backToForm} />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYear: state.academicYear,
  divisionList: state.internshipDeclaration,
  departmentList: state.department,
  classList: state.class,
  semisterList: state.rollNo,
  feedbackList: state.feedBack,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAcademicYear,
  getYearDetails,
  getDepartment,
  getClass,
  getSemesterDetailsByYearAndAcademic,
  getDistinctDivision,
  getFeedBack,
  getStudentFeedbackDetails,
  getEmployeeByDeptId,
  resetValues,
  getfeedBackDetailsByFeedBackIdStudentPreview,
  getfeedBackId,
  getFeedbackFromDtToDtFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFeedbackDeclare);
