import endpoint from "../config/endpoints";

export const adminAddingQualificationJson = {
  apiBaseURL: endpoint.courseAttended,
  screenTitle: "Adding Qualification",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Add Qualification",
  showExcelDownload: true,
  excelFileName: "Add Qualification",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Course Details",
      columnKeyName: "details",
      isChecked: true,
    },
    {
      columnDisplayName: "Duration",
      columnKeyName: "duration",
      isChecked: true,
    },
    {
      columnDisplayName: "Level",
      columnKeyName: "level",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "status",
      isChecked: true,
    },
  ],
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Course Details",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "details",
      isMandatory: true,
    },
    {
      label: "Duration",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "duration",
      isMandatory: true,
    },
    {
      label: "Status",
      controlType: "textfield",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "status",
      isMandatory: true,
    },
    {
      label: "Level",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "LevelMaster",
      dataKey: "level",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "LevelMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "levelData",
      tableDataKey: "level",
      open: "other",
      error: "Level",
    },
  ],
};
