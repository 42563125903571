import
{
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import * as myConstClass from "../../../config/messageconstant";
import { getRollNoList, resetValues } from "./GenerateRollNo.slice";
import { getSemisterInfo } from "../../Masters/Admission/Semister Info/semisterInfo.slice";
import
{
  getYearDetails,
  getDistinctDivision,
} from "../../InternshipDeclaration/InternshipDeclataration.slice"; // import AccountFees from "../AccountFees/index";
import { getAlert } from "../../../CommonActions/alert.slice";
import GenrateRollNoTable from "./GenrateRollNoTable";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { getSemesterDetailsByYearAndAcademic } from "../ViewOfRollList/rollno.slice";
import CarryForwardPopup from "./CarryForwardPopup";
import CarryForwardStudentList from "./CarryForwardStudentList";

const rows = [
  {
    index: 1,
    id: 1,
    studentName: "TCS",
    prnNo: "NskRd",
    rollNo: "1",
    reportingDate: "",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 2,
    id: 2,
    studentName: "Wipro",
    prnNo: "",
    rollNo: "2",
    reportingDate: "12-06-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 3,
    id: 3,
    studentName: "ATS",
    prnNo: "123",
    rollNo: "3",
    reportingDate: "1-01-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
];
class ListOfCompany extends React.Component
{
  state = {
    id: 0,
    programTitle: "",
    semisterDetail: "",
    yearDetail: "",
    sortOrder: "",
    formErrors: [],
    showButtonFlag: 1
    //1- hide, 0- visible
  };

  componentDidMount()
  {
    const { getYearDetails, getAlert, getSemisterInfo } = this.props;

    getYearDetails().then( ( response ) =>
    {
      if ( !response )
      {
        getAlert( { message: "Something went wrong" } );
      }
    } );
    getSemisterInfo().then( ( response ) =>
    {
      if ( !response )
      {
        getAlert( { message: "Something went wrong" } );
      }
    } );
  }

  onDateChange = ( dataKey ) => ( date ) =>
  {
    this.setState( {
      [ dataKey ]: date,
    } );
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  ChangeHandlerSearch = ( name, newValue ) =>
  {

    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;

    const valueToShow = this.props.rollNoData?.semisterDetailsList.filter(
      ( data ) => data.id === newValue
    );
    if ( valueToShow && valueToShow.length > 0 )
    {
      const hasEvenSortNo = valueToShow[ 0 ].sortNo % 2 === 0;
      this.setState( { showButtonFlag: hasEvenSortNo ? 0 : 1 } );

    }

    if ( newValue !== null )
    {
      this.setState( { [ name ]: newValue } );
      if ( name == "yearDetail" )
      {
        this.setState( { semisterDetail: "" } );

        getDistinctDivision( { yearDetail: newValue } ).then( ( response ) =>
        {
          if ( !response )
          {
            this.props.getAlert( { message: "Something went wrong" } );
          }
        } );
        getSemesterDetailsByYearAndAcademic( {
          yearId: newValue,
          academicId: "",
        } ).then( ( response ) =>
        {
          if ( !response )
          {
            this.props.getAlert( { message: "Something went wrong" } );
          }
        } );
      }
    } else
    {
      this.setState( { [ name ]: "" } );
    }
  };

  handleFormValidation()
  {
    const { programTitle, semisterDetail, yearDetail, sortOrder } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( semisterDetail.toString().trim() === "" || semisterDetail === null )
    {
      formIsValid = false;
      formErrors[ "semisterDetailError" ] = myConstClass.semisterDetailMsg;
    }

    if ( yearDetail.toString().trim() === "" || yearDetail === null )
    {
      formIsValid = false;
      formErrors[ "yearDetailError" ] = myConstClass.yearDetailMsg;
    }
    if ( sortOrder.toString().trim() === "" || sortOrder === null )
    {
      formIsValid = false;
      formErrors[ "sortOrderError" ] = myConstClass.sortOrderMsg;
    }
    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  searchData = () =>
  {
    const { fromDate, toDate, internType } = this.state;
    const { getCompanyList } = this.props;
    let fromDt = moment( fromDate ).format( "DD-MM-YYYY" );
    let toDt = moment( toDate ).format( "DD-MM-YYYY" );
    if ( this.handleFormValidation() )
    {
      // getCompanyList({ fromDate: fromDate, toDate: toDate, internType: internType });
    }
  };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };

  rowAppliedList = ( data ) =>
  {
    const { comanyData } = this.state;
    this.setState( {
      interestedStudent: true,
      listOfComp: false,
      companyData: data,
    } );
  };

  saveHandler = ( RollNoList ) =>
  {

    console.log(RollNoList)
    const { saveRollNoList } = this.props;
    const { semisterDetail } = this.state;
    const saveToData = RollNoList.map( ( data ) =>
    {
      let saveData = {
        ...data,
        semisterDetail: {
          id: semisterDetail,
        },
        dateOfReporting: moment( data.dateOfReporting ).format( "DD-MM-YYYY" ),
      };
      console.log( saveData );
      return saveData;
    } );
    apiPost( {
      url: endpoint.studentRollList,
      postBody: saveToData,
    } ).then( ( { success } ) =>
    {
      if ( success )
      {
        window.location.replace( "/roll-list" );
      } else
      {
        this.props.getAlert( {
          message: "Failed to save",
        } );
      }
    } );
    // saveRollNoList({rollNoList:saveToData})
  };

  closeStudentFeesDetails = () =>
  {
    this.setState( { interestedStudent: false, listOfComp: true } );
  };
  submitHandler = () =>
  {
    const { getRollNoList, onSave, getAlert, showNotification, resetValues } =
      this.props;
    const { semisterDetail, yearDetail, sortOrder } = this.state;
    resetValues();
    if ( this.handleFormValidation() )
    {
      getRollNoList( {
        semisterDetail: semisterDetail,
        yearDetail: yearDetail,
        sortOrder: sortOrder,
      } ).then( ( response ) =>
      {
        if ( !response )
        {
          getAlert( { message: "Something went wrong" } );
        }
      } );
    }
  };




  openCarryForwardMenu()
  {
    
  }



  render()
  {
    const { programTitle, semisterDetail, yearDetail, sortOrder, showButtonFlag } = this.state;
    const {
      semisterDetailError,
      sortOrderError,
      programTitleError,
      yearDetailError,
    } = this.state.formErrors;
    const { internshipDeclarationList, rollNoData, studentRollNoList } =
      this.props;
    return (
      <>
        <div>
          <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Roll Number Generation"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                sx={{ pl: 1 }}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={internshipDeclarationList?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Semester Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={semisterDetail}
                      name={"semisterDetail"}
                      options={rollNoData?.semisterDetailsList.filter(
                        ( data ) => data.isActive == 1
                      )}
                      onChange={this.ChangeHandlerSearch}
                      isError={semisterDetailError ? true : false}
                      errorText={
                        semisterDetailError ? semisterDetailError : " "
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Sort Order"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl color={"primary"} fullWidth={true}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="sortOrder"
                        id="sortOrder"
                        value={sortOrder}
                        onChange={this.changeHandler}
                        size="small"
                        fullWidth={true}
                      >
                        <MenuItem value={0}>Surname</MenuItem>
                        <MenuItem value={1}>PRN Number</MenuItem>
                      </Select>
                      <FormHelperText error>
                        {sortOrderError ? sortOrderError : " "}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 3 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Search"
                      onClick={this.submitHandler}
                    />

                    {/* &nbsp;&nbsp;
                    {this.state.showButtonFlag === 0 && (
                      <ButtonCompo
                        size="button"
                        type="button"
                        variant="contained"
                        name="Carry Forward Roll No"
                        onClick={this.openCarryForwardMenu}
                      />
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <GenrateRollNoTable
                  rows={studentRollNoList?.rollNoList}
                  divisionList={internshipDeclarationList?.distinctDivision}
                  OnSave={this.saveHandler}
                  showButtonFlag={showButtonFlag}
                  yearDetail={yearDetail}
                  semisterDetail={semisterDetail}
                />

              </Grid>



            </>


          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  internshipDeclarationList: state.internshipDeclaration,
  studentRollNoList: state.rollNoList,
  semisterList: state.semister,
  rollNoData: state.rollNo,
} );
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getRollNoList,
  getSemisterInfo,
  getSemesterDetailsByYearAndAcademic,
  resetValues,
};
export default connect( mapStateToProps, mapDispatchToProps )( ListOfCompany );
