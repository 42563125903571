import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddTimeSlot = ({
  handleCloses,
  showHeadEdit,
  showHeadDelete,
  rowDelete,
  rowEdit,
  rowEditTimeTableDetails,
  isActionColActive,
  open,
  timeSlot,
  day,
  columns,
  rows,
  teacherData,
  teacherColumns,
}) => {
  const handleClose = () => {
    handleCloses();
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Time slot details
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Time slot : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={10}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={timeSlot}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={1}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Day : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={11}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={day}
                  />
                </Grid>
              </Grid>
            </Grid>

            {rows.length !== 0 && (
              <>
                <br />
                <DynamicTable
                  data={rows}
                  tableHead={columns}
                  showPegination={false}
                  showHeadEdit={true}
                  rowEdit={rowEditTimeTableDetails}
                  showHeadDelete={showHeadDelete}
                  rowDelete={rowDelete}
                  isActionColActive={isActionColActive}
                />
              </>
            )}
            {teacherData && teacherData.length !== 0 && (
              <>
                <br />
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={2}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={1.5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Teacher Name : "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={10.5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={teacherData[0].teacher}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <DynamicTable
                  data={teacherData}
                  tableHead={teacherColumns}
                  showPegination={false}
                  showHeadEdit={showHeadEdit}
                  showHeadDelete={showHeadDelete}
                  rowDelete={rowDelete}
                  rowEdit={rowEdit}
                  isActionColActive={false}
                />
              </>
            )}
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddTimeSlot);
