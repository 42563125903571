import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  researchGuide: [],
  isFetch: false,
};
let URL = endpoints.researchGuide;

const researchGuideSlice = createSlice({
  name: "researchGuide",
  initialState,
  reducers: {
    researchGuideSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        researchGuide: row,
        isFetch: true,
      };
    },
  },
});

export const { researchGuideSuccess } = researchGuideSlice.actions;

export default researchGuideSlice.reducer;

export const getResearchGuide = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            university: data1.university,
            researchCenter: data1.researchCenter,
            guideRegDate: data1.guideRegDate,
            validUpTo: data1.validUpTo,
            guideFor: data1.guideFor,
            docUrl: data1.docUrl,
            guideAs: data1.guideAs,
            details: data1.details,
            employeeAsGuideDocument: data1.employeeAsGuideDocument.map(
              (folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }
            ),
          };
          row.push(bData);
          return data1;
        });
        dispatch(researchGuideSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminResearchGuide =
  ({ userRegistration }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/by-user-registration?userRegistrationId=" + userRegistration,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              university: data1.university,
              researchCenter: data1.researchCenter,
              guideRegDate: data1.guideRegDate,
              validUpTo: data1.validUpTo,
              guideFor: data1.guideFor,
              docUrl: data1.docUrl,
              guideAs: data1.guideAs,
              details: data1.details,
              employeeAsGuideDocument: data1.employeeAsGuideDocument.map(
                (folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }
              ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(researchGuideSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getResearchByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-as-guide-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              university: data1.university,
              researchCenter: data1.researchCenter,
              guideRegDate: data1.guideRegDate,
              validUpTo: data1.validUpTo,
              guideFor: data1.guideFor,
              docUrl: data1.docUrl,
              guideAs: data1.guideAs,
              details: data1.details,
              employeeAsGuideDocument: data1.employeeAsGuideDocument.map(
                (folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }
              ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(researchGuideSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getResearchWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-as-guide-list-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            university: data1.university,
            researchCenter: data1.researchCenter,
            guideRegDate: data1.guideRegDate,
            validUpTo: data1.validUpTo,
            guideFor: data1.guideFor,
            docUrl: data1.docUrl,
            guideAs: data1.guideAs,
            details: data1.details,
            employeeAsGuideDocument: data1.employeeAsGuideDocument.map(
              (folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }
            ),
          };
          row.push(bData);
          return data1;
        });
        dispatch(researchGuideSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const SetResearchData = (row) => async (dispatch) => {
  dispatch(researchGuideSuccess({ row }));
};
