import { Checkbox, Link, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import swal from "sweetalert";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import FormHelperText from "@mui/material/FormHelperText";
import { pink } from "@mui/material/colors";
function descendingComparator( a, b, orderBy )
{
  if ( b[ orderBy ] < a[ orderBy ] )
  {
    return -1;
  }
  if ( b[ orderBy ] > a[ orderBy ] )
  {
    return 1;
  }
  return 0;
}

function getComparator( order, orderBy )
{
  return order === "desc"
    ? ( a, b ) => descendingComparator( a, b, orderBy )
    : ( a, b ) => -descendingComparator( a, b, orderBy );
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort( array, comparator )
{
  const stabilizedThis = array.map( ( el, index ) => [ el, index ] );
  stabilizedThis.sort( ( a, b ) =>
  {
    const order = comparator( a[ 0 ], b[ 0 ] );
    if ( order !== 0 )
    {
      return order;
    }
    return a[ 1 ] - b[ 1 ];
  } );
  return stabilizedThis.map( ( el ) => el[ 0 ] );
}

function EnhancedTableHead( props )
{
  const {
    onSelectAllClick,
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectAllChecked
  } = props;
  const createSortHandler = ( property ) => ( event ) =>
  {
    onRequestSort( event, property );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
           sx={{
              color: pink[ 800 ],
              "&.Mui-checked": {
                color: pink[ 600 ],
              },
            }}
            // color="primary"
            
            indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            checked={selectAllChecked}
            onChange={onSelectAllClick}
            // onClick={( event ) => handleClick( event, row.id )}
            // disabled
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>

        {headCells.map( ( headCell ) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            width={headCell.minWidth}
          >
            {headCell.title}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableCell>
        ) )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf( [ "asc", "desc" ] ).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function CarryForwardStudentList( {
  rows,
  selection,
  divisionList,
  OnSave,
} )
{
  const [ values, setValue ] = React.useState( [] );
  React.useEffect( () =>
  {
    if ( values.length === 0 )
    {
      let rowList = [];
      rows.map( ( rowData ) =>
      {
        rowData = {
          ...rowData,
          // rollNo: rowList.length + 1,
          index: rowList.length + 1,
        };
        rowList.push( rowData );
      } );
      setValue( rowList );
    }
  } );
  const columns = [
    { name: "index", title: "Sr. No.", minWidth: "10%" },
    { name: "studentName", title: "Student Name" },
    { name: "prnNo", title: "PRN Number", minWidth: "20%" },
    { name: "rollNo", title: "Roll Number", minWidth: "15%" },
    {
      name: "reportingDate",
      title: "Reporting Date",
      minWidth: "20%",
      getCellValue: ( params, index1 ) =>
      {
        return <div></div>;
      },
    },
  ];
  const [ order, setOrder ] = React.useState( "asc" );
  const [ orderBy, setOrderBy ] = React.useState( "calories" );
  const [ page, setPage ] = React.useState( 0 );
  const [ dense, setDense ] = React.useState( false );
  const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 );
  const [ division, setdivision ] = useState( "" );
  const [ divisionError, setdivisionError ] = useState( "" );
  const [ selectedObjects, setSelectedObjects ] = useState( [] );
  const [ newList, setNewList ] = useState( [] );
  const [ checkBoxError, setCheckBoxError ] = useState( false );
  const [ selectAllChecked, setSelectAllChecked ] = useState( false );


  const handleRequestSort = ( event, property ) =>
  {
    const isAsc = orderBy === property && order === "asc";
    setOrder( isAsc ? "desc" : "asc" );
    setOrderBy( property );
  };

  // const handleSelectAllClick = ( event ) =>
  // {
  //   if ( event.target.checked )
  //   {
  //     let updatedValues = values.map( ( dataObject ) =>
  //     {
  //       return {
  //         ...dataObject,
  //         isChecked: event.target.checked,
  //       };
  //     } );
  //     setValue( updatedValues );
  //     setSelectAllChecked( isChecked );
  //   }
  //   setValue( [] );
  //   setSelectAllChecked( !isChecked );
  // };



// important start
  const handleSelectAllClick = () => {
    const allValuesSelected = values.every((dataObject) => dataObject.isChecked);
  
    const updatedValues = values.map((dataObject) => ({
      ...dataObject,
      isChecked: !allValuesSelected,
    }));
  
    setValue(updatedValues);
    setSelectAllChecked(!allValuesSelected);
  };
  
  




  const handleClick = ( event, name ) =>
  {
    let updatedValues = values.map( ( dataObject ) =>
    {
      if ( dataObject.id === name )
      {
        return {
          ...dataObject,
          isChecked: event.target.checked,
        };
      } else
      {
        return dataObject;
      }
    } );

    setValue( updatedValues );

  };
  // important end
  


  // const handleClick = (event, name) => {
  //   let updatedValues = values.map((dataObject) => {
  //     if (dataObject.id === name) {
  //       return {
  //         ...dataObject,
  //         isChecked: event.target.checked,
  //       };
  //     } else {
  //       return dataObject;
  //     }
  //   });
  
  //   setValue(updatedValues);
  
  //   // Check if all values are checked
  //   const allValuesChecked = updatedValues.every((dataObject) => dataObject.isChecked);
  
  //   // Update the state of the "Select All" checkbox
  //   setSelectAllChecked(allValuesChecked);
  // };
  
  // const handleSelectAllClick = () => {
  //   const allValuesChecked = !selectAllChecked;
  
  //   let updatedValues = values.map((dataObject) => ({
  //     ...dataObject,
  //     isChecked: allValuesChecked,
  //   }));
  
  //   setValue(updatedValues);
  //   setSelectAllChecked(allValuesChecked);
  // };












  // // Get the selected object
  // const selectedObject = updatedValues.find((dataObject) => dataObject.id === name);
  // // If the checkbox is checked, add the selected object to a new list
  // if (event.target.checked) {
  //   setNewList((prevNewList) => [...prevNewList, selectedObject]);
  // } else {
  //   // If the checkbox is unchecked, remove the selected object from the new list
  //   setNewList((prevNewList) => prevNewList.filter((obj) => obj.id !== name));
  // }

  const handleChangePage = ( event, newPage ) =>
  {
    setPage( newPage );
  };

  const handleChangeRowsPerPage = ( event ) =>
  {
    setRowsPerPage( parseInt( event.target.value, 10 ) );
    setPage( 0 );
  };

  const handleChangeDense = ( event ) =>
  {
    setDense( event.target.checked );
  };

  const isSelected = ( name ) => selection.indexOf( name ) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - values.length ) : 0;
  const ChangeHandlerSearch = ( name, newValue ) =>
  {
    if ( newValue !== null )
    {
      setdivision( newValue );
    } else
    {
      this.setState( { [ name ]: "" } );
    }
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();

  //   let RollNoList = [];
  //   if (division === "") {
  //     setdivisionError("Select division");
  //   }

  //   values.map((RollNoData) => {
  //     const a = selection.filter((item, index) => item === RollNoData.id);
  //     if (a.length !== 0) {
  //       let error = false;
  //       if (RollNoData.rollNo === "") {
  //         error = true;
  //         setValue((data) =>
  //           data?.map((list, index) =>
  //             list.id === RollNoData.id ? { ...list, rollNoValid: 1 } : list
  //           )
  //         );
  //       }
  //       if (RollNoData.reportingDate === "") {
  //         error = true;
  //         setValue((data) =>
  //           data?.map((list, index) =>
  //             list.id === RollNoData.id
  //               ? { ...list, reportingDateValid: 1 }
  //               : list
  //           )
  //         );
  //       }
  //       if (!error) {
  //         let saveData = {
  //           getAdmissionRegistrationDetail:
  //             RollNoData.admissionRegistrationDetailId,
  //           rollNo: RollNoData.rollNo,
  //           dateOfReporting: RollNoData.reportingDate,
  //           isActive: "1",
  //           division: { id: division },
  //         };
  //         RollNoList.push(saveData);
  //       }
  //     }
  //   });
  //   if (RollNoList.length === selection.length && division !== "") {
  //     swal("Do you want to generate roll numbers", {
  //       buttons: ["Cancel", true],
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         OnSave(RollNoList);
  //       }
  //     });
  //   }
  // };









  const submitHandler = ( e ) =>
  {
    e.preventDefault();
    let RollNoList = [];
    if ( division === "" )
    {
      setdivisionError( "Select division" );
    }
    // Filtered values based on isChecked being true
    const selectedValues = values.filter( ( RollNoData ) => RollNoData.isChecked );



    // if (selectedValues.length === 0) {
    //   setCheckBoxError( true );
    //   return;
    // }


    let error = false;

    selectedValues.forEach( ( RollNoData ) =>
    {

      if ( RollNoData.rollNo === "" )
      {
        error = true;
        setValue( ( data ) =>
          data?.map( ( list ) =>
            list.id === RollNoData.id ? { ...list, rollNoValid: 1 } : list
          )
        );
      }

      if ( RollNoData.reportingDate === "" )
      {
        error = true;
        setValue( ( data ) =>
          data?.map( ( list ) =>
            list.id === RollNoData.id
              ? { ...list, reportingDateValid: 1 }
              : list
          )
        );
      }

      // if (selectedValues.length === 0) {
      //   setCheckBoxError( true );
      //   return;
      // }


      if ( !error )
      {
        let saveData = {
          getAdmissionRegistrationDetail: RollNoData.admissionRegistrationDetailId,
          rollNo: RollNoData.rollNo,
          dateOfReporting: RollNoData.reportingDate,
          isActive: "1",
          division: { id: division },
        };

        RollNoList.push( saveData );
      }
    } );

    if ( RollNoList.length === 0 )
    {
      setCheckBoxError( true );
      error = true;
    }


    if ( !error )
    {
      setCheckBoxError( false );
      if ( RollNoList.length > 0 && division !== "" )
      {
        swal( "Do you want to carry forward roll numbers ?", {
          buttons: [ "Cancel", true ],
        } ).then( ( willDelete ) =>
        {
          if ( willDelete )
          {
            OnSave( RollNoList );
          }
        } );
      }
    }



  };











  return (
    <>
      <Box sx={{ width: "100%" }}>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              headCells={columns}
              numSelected={selection.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={values.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 values.slice().sort(getComparator(order, orderBy)) */}
              {stableSort( values, getComparator( order, orderBy ) )
                .map( ( row, index ) =>
                {
                  const isItemSelected = isSelected( row.id );
                  const labelId = `enhanced-table-checkbox-${ index }`;
                  const changeHandler = ( id, e ) =>
                  {
                    const { value } = e.target;
                    setValue( ( data ) =>
                      data?.map( ( valueData, index ) =>
                        valueData.id === id
                          ? { ...valueData, rollNo: value }
                          : valueData
                      )
                    );
                  };
                  const onDateChange = ( id ) => ( date ) =>
                  {
                    if ( date !== null )
                    {
                      const valueData = values.map( ( valueData ) =>
                      {
                        if ( valueData.id === id )
                        {
                          return { ...valueData, reportingDate: date };
                        } else
                        {
                          return valueData;
                        }
                      } );
                      setValue( valueData );
                    }
                  };
                  //  if (isItemSelected) {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      changeHandler={changeHandler}
                      onDateChange={onDateChange}
                      handleClick={handleClick}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                    />
                  );
                  // }
                } )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: ( dense ? 33 : 53 ) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>



        <FormHelperText error>
          {checkBoxError ? "Select minimum one student." : " "}
        </FormHelperText>
      </Box>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div>
        <div>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="*Division"
          />

          <AutoComplete
            keyColName={"id"}
            value={division}
            name={"division"}
            options={divisionList}
            onChange={ChangeHandlerSearch}
            isError={divisionError ? true : false}
            errorText={divisionError ? divisionError : " "}
          />
        </div>
        <div align="right">
          <Link
            color="primary"
            variant="body2"
            component={RouterLink}
            to="/roll-list"
            sx={{ textDecoration: "none" }}
          >
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
            />
          </Link>
          &nbsp;&nbsp;
          <ButtonCompo
            size="button"
            type="button"
            variant="contained"
            name="Submit"
            onClick={submitHandler}
          />
        </div>
      </div>
    </>
  );
}

function Row( {
  row,
  changeHandler,
  onDateChange,
  labelId,
  isItemSelected,
  handleClick,
} )
{
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onClick={( event ) => handleClick( event, row.id )}
          checked={row.isChecked}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>

      <TableCell align={"center"}>{row.index}</TableCell>
      <TableCell>{row.studentName}</TableCell>
      <TableCell>{row.prnNo}</TableCell>
      <TableCell>
        <TextField
          color="primary"
          name={"rollNo" + row.id}
          size="small"
          value={row.rollNo}
          error={row.rollNoValid == 1 ? true : false}
          helperText={row.rollNoValid == 1 ? "Roll number required" : " "}
          onChange={( e ) => changeHandler( row.id, e )}
          fullWidth={true}
        />
      </TableCell>
      <TableCell>
        <BasicDatePicker
          color="primary"
          name="reportingDate"
          size="small"
          value={row.reportingDate}
          fullWidth
          onChange={onDateChange( row.id )}
          errorText={row.reportingDateValid === 1 ? "Select date" : " "}
          isError={row.reportingDateValid === 1 ? true : false}
        />
      </TableCell>
    </TableRow>
  );
}
