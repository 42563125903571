import { FormHelperText, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
// import { ButtonCompo } from "../../components/Comman/Buttton";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { LabelCompo } from "../../components/Comman/Label";
import {
  onlyNumber,
  textOnly,
  validEmail,
  textOnlyWithSpaces,
} from "../../components/Comman/Util/Validations";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import * as myConstClass from "../../config/messageconstant";

let docs;
class PersonalDetailsForm extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    candidatename: "",
    firstname: "",
    middleName: "",
    lastname: "",
    fatherHusband: "",
    mothername: "",
    gender: "",
    dob: "",
    nationality: "",
    relegion: "",
    category: "",
    caste: "",
    subcaste: "",
    bloodgroup: "",
    fathersoccupation: "",
    medium: "",
    familyincome: "",
    validitycdno: "",
    validitycddate: "",
    castecertno: "",
    castecertdate: "",
    domicileof: "",
    mothertounge: "",
    parentmobile: "",
    studmobile: "",
    parentemail: "",
    studemail: "",
    remark: "",
    landline: "",
    aadhaarNo: "",
    birthPlace: "",
    martialStatus: "",
    isStudentNriForeignNational: "",
    oms: "",
    scholarship: "",
    prnNo: "",
    grnNo: "",
    departmentId: "",
    libraryNo: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  changedHandler = (event) => {
    const { name, value } = event.target;
    if (
      event.target.value === "" ||
      textOnlyWithSpaces.test(event.target.value)
    ) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandlerEmail = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  changeDob = (date) => {
    if (date != null) {
      this.setState({
        dob: date,
      });
    }
  };

  changeValidityDate = (date) => {
    if (date !== null) {
      this.setState({
        validitycddate: date,
      });
    }
  };

  // componentDidMount() {
  //   const { studentList } = this.props;

  //   this.setState({
  //     id: studentList.applicant.id,
  //   });
  // }

  componentDidMount() {
    const { studentList } = this.props;
    console.log(studentList);
    if (Object.keys(studentList.applicant).length !== 0) {
      const DOBArr =
        studentList.applicant.dateOfBirth == null
          ? ""
          : studentList.applicant.dateOfBirth.split("-");
      const DOB =
        DOBArr === "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

      const CasteDobArr =
        studentList.applicant.castCertificateDate == null
          ? ""
          : studentList.applicant.castCertificateDate.split("-");
      const CasteDob =
        CasteDobArr === ""
          ? ""
          : new Date(CasteDobArr[2], CasteDobArr[1] - 1, CasteDobArr[0]);

      const ValidityDobArr =
        studentList.applicant.validityCdDate === null
          ? ""
          : studentList.applicant.validityCdDate.split("-");
      const ValidityDob =
        ValidityDobArr === ""
          ? ""
          : new Date(
              ValidityDobArr[2],
              ValidityDobArr[1] - 1,
              ValidityDobArr[0]
            );
      this.setState({
        id: studentList.applicant.id,
        candidatename: studentList.applicant.firstName,
        middleName: studentList.applicant.middleName
          ? studentList.applicant.middleName
          : "",
        lastname: studentList.applicant.lastName
          ? studentList.applicant.lastName
          : "",
        gender: studentList.applicant.gender,
        dob: DOB,
        studemail: studentList.applicant.studentEmail,
        studmobile: studentList.applicant.studentMobile,
        aadhaarNo: studentList.applicant.aadhaarNo,
        birthPlace: studentList.applicant.birthPlace,
        nationality: studentList.applicant.nationality,
        relegion: studentList.applicant.religionId,
        caste: studentList.applicant.cast,
        category: studentList.applicant.categoryId,
        subcaste: studentList.applicant.subCast,
        bloodgroup: studentList.applicant.bloodGroup,
        medium: studentList.applicant.medium,
        validitycdno: studentList.applicant.validityCdNo,
        validitycddate: ValidityDob,
        castecertno: studentList.applicant.castCertificateNo,
        castecertdate: CasteDob,
        domicileof: studentList.applicant.domicileof,
        mothertounge: studentList.applicant.motherTongue,
        fatherHusband:
          studentList.applicant.fatherName === null
            ? ""
            : studentList.applicant.fatherName,
        mothername:
          studentList.applicant.motherName === null
            ? ""
            : studentList.applicant.motherName,
        fathersoccupation: studentList.applicant.fatherOccupation,
        familyincome: studentList.applicant.annualIncome,
        parentmobile: studentList.applicant.parentMobile,
        parentemail: studentList.applicant.parentEmail,
        landline: studentList.applicant.landlineNo,
        remark: studentList.applicant.remark,
        martialStatus: studentList.applicant.martialStatus,
        isStudentNriForeignNational:
          studentList.applicant.isStudentNriForeignNational,
        oms: studentList.applicant.oms,
        scholarship: studentList.applicant.scholarship,
        departmentId: studentList.applicant.userRegistrationDepartment,
        prnNo: studentList.applicant.prnNo,
        libraryNo: studentList.applicant.libraryNo,

        grnNo: studentList.applicant.grnNo,

        // userRegistrationDepartment: {
        //   id: departmentId
        // },
      });
    } else {
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.applicant !== nextProps.studentList.applicant
      ) {
        const DOBArr =
          nextProps.studentList.applicant.dateOfBirth === null
            ? ""
            : nextProps.studentList.applicant.dateOfBirth.split("-");
        const DOB =
          DOBArr === "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

        const CasteDobArr =
          nextProps.studentList.applicant.castCertificateDate === null
            ? ""
            : nextProps.studentList.applicant.castCertificateDate.split("-");
        const CasteDob =
          CasteDobArr === ""
            ? ""
            : new Date(CasteDobArr[2], CasteDobArr[1] - 1, CasteDobArr[0]);

        const ValidityDobArr =
          nextProps.studentList.applicant.validityCdDate == null
            ? ""
            : nextProps.studentList.applicant.validityCdDate.split("-");
        const ValidityDob =
          ValidityDobArr === ""
            ? ""
            : new Date(
                ValidityDobArr[2],
                ValidityDobArr[1] - 1,
                ValidityDobArr[0]
              );
        this.setState({
          id: nextProps.studentList.applicant.id,
          candidatename: nextProps.studentList.applicant.firstName,
          middleName: nextProps.studentList.applicant.middleName,
          lastname: nextProps.studentList.applicant.lastName,
          gender: nextProps.studentList.applicant.gender,
          dob: DOB,
          studemail: nextProps.studentList.applicant.studentEmail,
          studmobile: nextProps.studentList.applicant.studentMobile,
          aadhaarNo: nextProps.studentList.applicant.aadhaarNo,
          birthPlace: nextProps.studentList.applicant.birthPlace,
          nationality: nextProps.studentList.applicant.nationality,
          relegion: nextProps.studentList.applicant.religionId,
          caste: nextProps.studentList.applicant.cast,
          category: nextProps.studentList.applicant.categoryId,
          subcaste: nextProps.studentList.applicant.subCast,
          bloodgroup: nextProps.studentList.applicant.bloodGroup,
          medium: nextProps.studentList.applicant.medium,
          validitycdno: nextProps.studentList.applicant.validityCdNo,
          validitycddate: ValidityDob,
          castecertno: nextProps.studentList.applicant.castCertificateNo,
          castecertdate: CasteDob,
          domicileof: nextProps.studentList.applicant.domicileof,
          mothertounge: nextProps.studentList.applicant.motherTongue,
          fatherHusband:
            nextProps.studentList.applicant.fatherName === null
              ? ""
              : nextProps.studentList.applicant.fatherName,
          mothername:
            nextProps.studentList.applicant.motherName === null
              ? ""
              : nextProps.studentList.applicant.motherName,
          fathersoccupation: nextProps.studentList.applicant.fatherOccupation,
          familyincome: nextProps.studentList.applicant.annualIncome,
          parentmobile: nextProps.studentList.applicant.parentMobile,
          parentemail: nextProps.studentList.applicant.parentEmail,
          landline: nextProps.studentList.applicant.landlineNo,
          remark: nextProps.studentList.applicant.remark,
          martialStatus: nextProps.studentList.applicant.martialStatus,
          isStudentNriForeignNational:
            nextProps.studentList.applicant.isStudentNriForeignNational,
          oms: nextProps.studentList.applicant.oms,
          scholarship: nextProps.studentList.applicant.scholarship,
          departmentId:
            nextProps.studentList.applicant.userRegistrationDepartment,
          prnNo: nextProps.studentList.applicant.prnNo,
          libraryNo: nextProps.studentList.applicant.libraryNo,
          grnNo: nextProps.studentList.applicant.grnNo,
        });
      }
    }
  }
  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        dob: date,
      });
    }
  };

  changeValidatityDate = (date) => {
    if (date !== null) {
      this.setState({
        validitycddate: date,
      });
    }
  };

  changeCasteDate = (date) => {
    if (date !== null) {
      this.setState({
        castecertdate: date,
      });
    }
  };

  checkEmployeeEmail = (event) => {
    const { value } = event.target;

    const { getAlert, showNotification } = this.props;
    if (value != "") {
      apiGet({
        url: "/api/config/v1/user/check-unique-email-id?emailId=" + value,
      }).then(({ data, success }) => {
        if (success) {
          if (data.data.error === false) {
            showNotification({
              msg: "Email Id Unqiue",
            });
            this.setState({ formErrors: [] });
          } else {
            let formErrors = {};
            formErrors["studemailErr"] = myConstClass.emailInvalMsg;
            this.setState({ formErrors: formErrors, studemail: "" });
          }
        } else {
          getAlert({
            message: myConstClass.serverMsg,
          });
        }
      });
    }
  };

  handleFormValidation() {
    const {
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      medium,
      fathersoccupation,
      familyincome,
      mothertounge,
      parentmobile,
      studmobile,
      studemail,
      oms,
      aadhaarNo,
      birthPlace,
      martialStatus,
      scholarship,
      isStudentNriForeignNational,
      departmentId,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    // if ( oms.toString().trim() === "" || oms === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "omsErr" ] = myConstClass.omsMsg;
    // }

    // if ( scholarship.toString().trim() === "" || scholarship === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "scholarShipErr" ] = myConstClass.isScholarShipMsg;
    // }

    // if ( dob.toString().trim() === "" || dob === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "dobErr" ] = myConstClass.dobMsg;
    // }

    if (
      departmentId === "" ||
      departmentId === null ||
      departmentId === undefined
    ) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    // if ( isStudentNriForeignNational.toString().trim() === "" || isStudentNriForeignNational === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "isStudentNriForeignNationalErr" ] =
    //     myConstClass.isStudentNriForeignNationalMsg;
    // }

    // if ( martialStatus.toString().trim() === "" || martialStatus === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "martialStatusErr" ] = myConstClass.martialMsg;
    // }

    // if ( gender.toString().trim() === "" || gender === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "genderErr" ] = myConstClass.genderMsg;
    // }
    // if ( mothername.toString().trim() === "" || mothername === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "mothernameErr" ] = myConstClass.motherNameMsg;
    // }
    // if ( fatherHusband.toString().trim() === "" || fatherHusband === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "fatherHusbandErr" ] = myConstClass.fatherHusbandNameMsg;
    // }
    if (candidatename.toString() === "" || candidatename === null) {
      formIsValid = false;
      formErrors["candidatenameErr"] = myConstClass.firstNameMsg;
    }

    // if ( familyincome.toString().trim() === "" || familyincome === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "familyincomeErr" ] = myConstClass.annualIncomeMsg;
    // }
    // if ( fathersoccupation.toString().trim() === "" || fathersoccupation === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "fathersoccupationErr" ] = myConstClass.fatherOccupationMsg;
    // }
    // if ( bloodgroup.toString().trim() === "" || bloodgroup === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "bloodgroupErr" ] = myConstClass.bloodGroupMsg;
    // }
    // if ( subcaste.toString().trim() === "" || subcaste === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "subcasteErr" ] = myConstClass.subcasteMsg;
    // }
    // if ( caste.toString().trim() === "" || caste === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "casteErr" ] = myConstClass.casteMsg;
    // }
    // if ( category.toString().trim() === "" || category === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "categoryErr" ] = myConstClass.catNameMsg;
    // }
    // if ( relegion.toString().trim() === "" || relegion === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "relegionErr" ] = myConstClass.religionMsg;
    // }
    // if ( nationality.toString().trim() === "" || nationality === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "nationalityErr" ] = myConstClass.nationalityMsg;
    // }

    // if ( aadhaarNo.toString().trim() === "" || aadhaarNo.length !== 12 )
    // {
    //   if ( aadhaarNo === "" )
    //   {
    //     formIsValid = false;
    //     formErrors[ "aadhaarNoErr" ] = myConstClass.InvalidadharMsg;
    //   } else if ( !onlyNumber.test( aadhaarNo ) )
    //   {
    //     formIsValid = false;
    //     formErrors[ "aadhaarNoErr" ] = myConstClass.InvalidadharMsg;
    //   } else if ( studmobile.length > 0 && aadhaarNo.length !== 12 )
    //   {
    //     formIsValid = false;
    //     formErrors[ "aadhaarNoErr" ] = myConstClass.InvalidadharMsg;
    //   }
    // }

    if (studemail.toString().trim() === "" || !validEmail.test(studemail)) {
      if (studemail === "") {
        formIsValid = false;
        formErrors["studemailErr"] = myConstClass.emailIdMsg;
      } else if (!validEmail.test(studemail)) {
        formIsValid = false;
        formErrors["studemailErr"] = myConstClass.invalidEmailIdMsg;
      }
    }

    if (studmobile.toString().trim() === "" || studmobile.length !== 10) {
      if (studmobile === "") {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNoMsg;
      } else if (!onlyNumber.test(studmobile)) {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNumberMsg;
      } else if (studmobile.length > 0 && studmobile.length !== 10) {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNoLengthMsg;
      }
    }

    // if ( parentmobile.toString().trim() === "" || parentmobile.length !== 10 )
    // {
    //   if ( parentmobile === "" )
    //   {
    //     formIsValid = false;
    //     formErrors[ "parentmobileErr" ] = myConstClass.contactNoMsg;
    //   } else if ( !onlyNumber.test( parentmobile ) )
    //   {
    //     formIsValid = false;
    //     formErrors[ "parentmobileErr" ] = myConstClass.contactNumberMsg;
    //   } else if ( parentmobile.length > 0 && parentmobile.length !== 10 )
    //   {
    //     formIsValid = false;
    //     formErrors[ "parentmobileErr" ] = myConstClass.contactNoLengthMsg;
    //   }
    // }

    // if ( mothertounge.toString().trim() === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "mothertoungeErr" ] = myConstClass.mothertoungeMsg;
    // }

    // if (lastname.toString().trim() === "") {
    //   formIsValid = false;
    //   formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    // }

    // if ( birthPlace.toString().trim() === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "birthPlaceErr" ] = myConstClass.birthplaceMsg;
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      medium,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      studmobile,
      studemail,
      parentemail,
      remark,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
      departmentId,
      prnNo,
      grnNo,
    } = this.state;
    let fd = dob === "" ? "" : moment(dob).format("DD-MM-YYYY");
    let vd =
      validitycddate === "" ? "" : moment(validitycddate).format("DD-MM-YYYY");
    let cd =
      castecertdate === "" ? "" : moment(castecertdate).format("DD-MM-YYYY");
    console.log(mothername);
    console.log(fatherHusband);
    docs = {
      id: id,
      firstName: candidatename.toUpperCase(),
      middleName: middleName !== "" ? middleName.toUpperCase() : "",
      lastName: lastname.toUpperCase(),
      motherName: mothername !== "" ? mothername.toUpperCase() : "",
      fatherName: fatherHusband !== "" ? fatherHusband.toUpperCase() : "",
      gender: gender,
      dateOfBirth: fd,
      bloodGroup: bloodgroup,
      mediumOfLanguageInPreviousCollege: medium,
      religionId: relegion,
      categoryId: category,
      cast: caste,
      subCast: subcaste,
      fatherOccupation: fathersoccupation,
      annualIncome: familyincome,
      nationality: nationality,
      domicileof: domicileof,
      motherTongue: mothertounge,
      parentMobile: parentmobile,
      parentEmail: parentemail,
      studentMobile: studmobile,
      studentEmail: studemail,
      landlineNo: landline,
      remark: remark,
      validityCdNo: validitycdno,
      validityCdDate: vd,
      castCertificateNo: castecertno,
      castCertificateDate: cd,
      aadhaarNo: aadhaarNo,
      birthPlace: birthPlace,
      martialStatus: martialStatus,
      isStudentNriForeignNational: isStudentNriForeignNational,
      oms: oms,
      scholarship: scholarship,
      type: 1,
      prnNo: prnNo,
      grnNo: grnNo,
      userRegistrationDepartment: {
        id: departmentId,
      },
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  saveHandler = (e) => {
    e.preventDefault();
    const {
      id,
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      medium,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      parentemail,
      remark,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
      departmentId,
      prnNo,
      grnNo,
    } = this.state;
    docs = {
      id: id === 0 ? 0 : id,
      candidatename: candidatename.toUpperCase(),
      middleName: middleName !== "" ? middleName.toUpperCase() : "",
      lastname: lastname.toUpperCase(),
      fatherHusband: fatherHusband !== "" ? fatherHusband.toUpperCase() : "",
      mothername: mothername !== "" ? mothername.toUpperCase() : "",
      gender: gender,
      dob: dob,
      nationality: nationality,
      relegion: relegion,
      category: category,
      caste: caste,
      subcaste: subcaste,
      bloodgroup: bloodgroup,
      mediumOfLanguageInPreviousCollege: medium,
      fathersoccupation: fathersoccupation,
      familyincome: familyincome,
      validitycdno: validitycdno,
      validitycddate: validitycddate,
      castecertno: castecertno,
      castecertdate: castecertdate,
      domicileof: domicileof,
      mothertounge: mothertounge,
      parentmobile: parentmobile,
      parentemail: parentemail,
      remark: remark,
      landline: landline,
      aadhaarNo: aadhaarNo,
      birthPlace: birthPlace,
      martialStatus: martialStatus,
      isStudentNriForeignNational: isStudentNriForeignNational,
      oms: oms,
      scholarship: scholarship,
      prnNo: prnNo,
      grnNo: grnNo,
      userRegistrationDepartment: {
        id: departmentId,
      },
    };

    if (this.handleFormValidation()) {
      this.props.saveAndNext(docs);
      this.setState({
        id: 0,
        candidatename: candidatename.toUpperCase(),
        middleName: middleName !== "" ? middleName.toUpperCase() : "",
        lastname: lastname.toUpperCase(),
        fatherHusband: fatherHusband !== "" ? fatherHusband.toUpperCase() : "",
        mothername: mothername !== "" ? mothername.toUpperCase() : "",
        prnNo: prnNo,
        grnNo: grnNo,
        gender: gender,
        dob: dob,
        birthPlace: birthPlace,
        bloodgroup: bloodgroup,
        medium: medium,
        nationality: nationality,
        relegion: relegion,
        category: category,
        caste: caste,
        subcaste: subcaste,
        fathersoccupation: fathersoccupation,
        familyincome: familyincome,
        validitycdno: validitycdno,
        validitycddate: validitycddate,
        castecertno: castecertno,
        castecertdate: castecertdate,
        domicileof: domicileof,
        mothertounge: mothertounge,
        parentmobile: parentmobile,
        parentemail: parentemail,
        remark: remark,
        landline: landline,
        aadhaarNo: aadhaarNo,
        martialStatus: martialStatus,
        isStudentNriForeignNational: isStudentNriForeignNational,
        oms: oms,
        scholarship: scholarship,
        userRegistrationDepartment: {
          id: departmentId,
        },
      });
    }
  };

  render() {
    const {
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      studmobile,
      studemail,
      parentemail,
      remark,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
      departmentId,
      grnNo,
      prnNo,
      libraryNo,
      medium,
    } = this.state;

    const {
      validitycddateErr,
      castecertdateErr,
      candidatenameErr,
      // lastNameErr,
      studemailErr,
      studmobileErr,
      dobErr,
      genderErr,
      departmentError,
    } = this.state.formErrors;

    const { onCancel, studentList, departments, editForm } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            {/* <Grid item xs={12} sm={6} md={12} /> */}

            <Grid
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  sx={{ marginTop: 2 }}
                  // rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="GRN Number"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="grnNo"
                      size="small"
                      value={grnNo}
                      // disabled={editForm ? true : false}
                      disabled
                      onChange={this.changedHandlerEmail}
                      fullWidth
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  sx={{ marginTop: 2 }}
                  // rowSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="PRN Number"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="prnNo"
                      size="small"
                      value={prnNo}
                      // disabled={editForm ? true : false}
                      // disabled
                      onChange={this.changedHandlerEmail}
                      fullWidth
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  sx={{ marginTop: 2 }}
                  // rowSpacing={2}
                  columnSpacing={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Library ID"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="libraryNo"
                      size="small"
                      value={libraryNo}
                      // disabled={editForm ? true : false}
                      disabled
                      onChange={this.changedHandlerEmail}
                      fullWidth
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  // sx={{ marginTop: 2 }}
                  // rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*First Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="candidatename"
                      size="small"
                      value={candidatename}
                      fullWidth
                      disabled={editForm ? true : false}
                      onChange={this.changedHandler}
                      helperText={" "}
                      {...(candidatenameErr
                        ? { error: true, helperText: candidatenameErr }
                        : "")}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  // sx={{ marginTop: 2 }}
                  // rowSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Middle Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="middleName"
                      size="small"
                      value={middleName}
                      fullWidth
                      disabled={editForm ? true : false}
                      onChange={this.changedHandler}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  // sx={{ marginTop: 2 }}
                  // rowSpacing={2}
                  columnSpacing={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Last Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="lastname"
                      size="small"
                      value={lastname}
                      disabled={editForm ? true : false}
                      fullWidth
                      onChange={this.changedHandler}
                      helperText={" "}
                      // {...(lastNameErr
                      //   ? { error: true, helperText: lastNameErr }
                      //   : "")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Gender"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="gender"
                      id="gender"
                      disabled={editForm ? true : false}
                      value={gender}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value="m">Male</MenuItem>
                      <MenuItem value="f">Female</MenuItem>
                      <MenuItem value="t">Transgender</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Date of Birth"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="dob"
                    size="small"
                    value={dob}
                    maxDate={new Date()}
                    disabled={editForm ? true : false}
                    fullWidth
                    onChange={this.changeFromDate}
                    isError={dobErr ? true : false}
                    errorText={dobErr ? dobErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Birth Place"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="birthPlace"
                    size="small"
                    value={birthPlace}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Blood Group"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={bloodgroup}
                    disabled={editForm ? true : false}
                    name={"bloodgroup"}
                    options={studentList?.bloodGroup.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Medium (Language of previous college)"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="medium"
                    size="small"
                    disabled={editForm ? true : false}
                    value={medium}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Student Contact No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studmobile"
                    size="small"
                    value={studmobile}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled={editForm ? true : false}
                    {...(studmobileErr
                      ? { error: true, helperText: studmobileErr }
                      : "")}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Student Email"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studemail"
                    size="small"
                    value={studemail}
                    onBlur={this.checkEmployeeEmail}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled={editForm ? true : false}
                    {...(studemailErr
                      ? { error: true, helperText: studemailErr }
                      : "")}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                sx={{ marginTop: 2 }}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Father's/Husband's Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="fatherHusband"
                    size="small"
                    value={fatherHusband}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                sx={{ marginTop: 2 }}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Mother's Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothername"
                    size="small"
                    value={mothername}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Marital Status"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="martialStatus"
                      id="martialStatus"
                      value={martialStatus}
                      disabled={editForm ? true : false}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value={0}>Un-Married</MenuItem>
                      <MenuItem value={1}>Married</MenuItem>
                      <MenuItem value={2}>Divorced</MenuItem>
                      <MenuItem value={3}>Widowed</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Mother Tounge"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothertounge"
                    size="small"
                    disabled={editForm ? true : false}
                    value={mothertounge}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Father's Occupation"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="fathersoccupation"
                    size="small"
                    disabled={editForm ? true : false}
                    value={fathersoccupation}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Family's Annual Income"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={familyincome}
                    name={"familyincome"}
                    fullWidth
                    disabled={editForm ? true : false}
                    options={studentList?.annualIncome.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Is Other Than Maharashtra"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="oms"
                      id="oms"
                      disabled={editForm ? true : false}
                      value={oms}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Is Student NRI/Foreign National"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="isStudentNriForeignNational"
                      id="isStudentNriForeignNational"
                      value={isStudentNriForeignNational}
                      disabled={editForm ? true : false}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value="0">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="1">NRI</MenuItem>
                      <MenuItem value="2">Foreign National</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12} /> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Aadhar No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="aadhaarNo"
                    size="small"
                    disabled={editForm ? true : false}
                    value={aadhaarNo}
                    inputProps={{ maxLength: 12 }}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Nationality"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="nationality"
                    size="small"
                    disabled={editForm ? true : false}
                    value={nationality}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Religion"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={relegion}
                    name={"relegion"}
                    fullWidth
                    disabled={editForm ? true : false}
                    options={studentList?.religion.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Category"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={category}
                    disabled={editForm ? true : false}
                    name={"category"}
                    options={studentList?.category.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="caste"
                    size="small"
                    value={caste}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Subcaste"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="subcaste"
                    size="small"
                    value={subcaste}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Is Scholarship"
                  />
                </Grid>

                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="scholarship"
                      id="scholarship"
                      disabled={editForm ? true : false}
                      value={scholarship}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value={0}>No</MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                    </Select>
                    <FormHelperText> </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Domicile State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="domicileof"
                    size="small"
                    disabled={editForm ? true : false}
                    value={domicileof}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="castecertno"
                    size="small"
                    disabled={editForm ? true : false}
                    value={castecertno}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate Date"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    name="castecertdate"
                    value={castecertdate}
                    disabled={editForm ? true : false}
                    onChange={this.changeCasteDate}
                    maxDate={new Date()}
                    error={castecertdateErr}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="validitycdno"
                    disabled={editForm ? true : false}
                    size="small"
                    value={validitycdno}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate Date"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    name="validitycddate"
                    value={validitycddate}
                    disabled={editForm ? true : false}
                    onChange={this.changeValidatityDate}
                    maxDate={new Date()}
                    error={validitycddateErr}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12} /> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Parent's Contact No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentmobile"
                    size="small"
                    value={parentmobile}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandler}
                    disabled={editForm ? true : false}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Parent's Email"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentemail"
                    size="small"
                    value={parentemail}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} /> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Landline No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="landline"
                    size="small"
                    value={landline}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Department"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={departmentId}
                    disabled={editForm ? true : false}
                    name={"departmentId"}
                    options={departments?.department.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                    isError={departmentError ? true : false}
                    errorText={departmentError ? departmentError : " "}
                  />
                </Grid>
              </Grid>

              {/* prn */}
            </Grid>

            {/* <Grid item xs={12} sm={6} md={12}></Grid> */}

            <Grid item xs={12} sm={6} md={1} />
            <Grid
              rowSpacing={2}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>

              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentList: state.studentList,
  departments: state.department,
});

const mapDispatchToProps = {
  showNotification,
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsForm);
