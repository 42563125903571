import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  company: [],
  isFetch: false,
  companyByDepartment: [],
  isFetchCompanyByDepartment: false,

  companyData: {},
  isFetchCompanyData:false

};
let URL = endpoints.company;
const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    companySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        company: row,
        isFetch: true,
      };
    },
    companyByDepartmentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        companyByDepartment: row,
        isFetchCompanyByDepartment: true,
      };
    },

    companyDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        companyData: row,
        isFetchCompanyData: true,
      };
    },
  },
});

export const { companySuccess, companyByDepartmentSuccess ,companyDataSuccess} =
  companySlice.actions;

export default companySlice.reducer;

export const getCompany = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            address: data1.address,
            departments: data1.departments,
            emailId: data1.emailId,
            ContactPersonName:
              data1.companyContactPersons.length !== 0
                ? data1.companyContactPersons[0].personName
                : "-",
            contactNo:
              data1.companyContactPersons.length !== 0
                ? data1.companyContactPersons[0].contactNo
                : "-",
            personEmailId:
              data1.companyContactPersons.length !== 0
                ? data1.companyContactPersons[0].emailId
                : "-",
            companyContactPersons: data1.companyContactPersons.map(
              (companyContactPersonsData, index) => {
                let contactPersonsData = {
                  index: index + 1,
                  id: companyContactPersonsData.id,
                  personName: companyContactPersonsData.personName,
                  contactNo: companyContactPersonsData.contactNo,
                  emailId: companyContactPersonsData.emailId,
                };
                return contactPersonsData;
              }
            ),
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(companySuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCompanyByType =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + '?filters={"type":"' + type + '","_mode" : "EXACT"}',
      }).then(({ data, success }) => {
        if (success) {
          const { content } = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              type: data1.type,
              address: data1.address,
              emailId: data1.emailId,
              ContactPersonName:
                data1.companyContactPersons.length !== 0
                  ? data1.companyContactPersons[0].personName
                  : "-",
              contactNo:
                data1.companyContactPersons.length !== 0
                  ? data1.companyContactPersons[0].contactNo
                  : "-",
              personEmailId:
                data1.companyContactPersons.length !== 0
                  ? data1.companyContactPersons[0].emailId
                  : "-",
              isActive: data1.isActive,

              companyContactPersons: data1.companyContactPersons.map(
                (companyContactPersonsData, index) => {
                  let contactPersonsData = {
                    index: index + 1,
                    id: companyContactPersonsData.id,
                    personName: companyContactPersonsData.personName,
                    contactNo: companyContactPersonsData.contactNo,
                    emailId: companyContactPersonsData.emailId,
                  };
                  return contactPersonsData;
                }
              ),
            };
            return bData;
          });
          dispatch(companySuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

const departmentId = localStorage.getItem("departmentId");

export const getCompanyByTypeAndDepartment =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-type-and-department?type=" +
          type +
          "&departmentId=" +
          departmentId,
      }).then(({ data, success }) => {
        if (success) {
          const { content } = data.data;
          let index = 0;

          const row = data.data.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              type: data1.type,
              address: data1.address,
              emailId: data1.emailId,
              ContactPersonName:
                data1.companyContactPersons.length !== 0
                  ? data1.companyContactPersons[0].personName
                  : "-",
              contactNo:
                data1.companyContactPersons.length !== 0
                  ? data1.companyContactPersons[0].contactNo
                  : "-",
              personEmailId:
                data1.companyContactPersons.length !== 0
                  ? data1.companyContactPersons[0].emailId
                  : "-",
              isActive: data1.isActive,

              companyContactPersons: data1.companyContactPersons.map(
                (companyContactPersonsData, index) => {
                  let contactPersonsData = {
                    index: index + 1,
                    id: companyContactPersonsData.id,
                    personName: companyContactPersonsData.personName,
                    contactNo: companyContactPersonsData.contactNo,
                    emailId: companyContactPersonsData.emailId,
                  };
                  return contactPersonsData;
                }
              ),
            };
            return bData;
          });
          dispatch(companyByDepartmentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
    };
  


export const getCompanyById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const companyData = data.data;
          let cData = {
              id: companyData.id,
              name: companyData.name,
              type: companyData.type,
              address: companyData.address,
          };
          dispatch(companyDataSuccess({ row: cData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };