import { Paper } from "@mui/material";
import * as React from "react";
import AdminStudentAllocation from "./AdminStudentAllocation";
import
  {
    resetStudentAndTeacher
  } from "../AllocationOfStudent/AllocationOfStudent.slice";
import { connect } from "react-redux";
const DisableElevation = () =>
{
  const [ open, setOpen ] = React.useState( false );
  const handleClickOpen = () =>
  {
    setOpen( true );
  };
  React.useEffect( () =>
  {
    resetStudentAndTeacher()
  }, [] )
  return (
    <>
      {/* <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}> */}
      {!open && (
        <>

          <AdminStudentAllocation handleClickOpen={handleClickOpen} />
        </>
      )}
      {/* </Paper> */}
    </>
  );
}
const mapStateToProps = ( state ) => ( {
} );
const mapDispatchToProps = {
  resetStudentAndTeacher
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( DisableElevation );
