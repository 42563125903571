import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { AttendaceReportJson } from "../../DynamicFormsJson/AttendanceReport";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { resetReducList } from "../../CommonActions/User.action";
import { getTimeTable } from "../TimeTable/GenerateTimeTable/generateTimeTable.slice";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import {
  getSubjectByDivision,
  getBatch,
} from "../../Pages/StudentAttendance/StudentAttendanceForms/studentAttendance.slice";
import { getTeacherByBatchId } from "../Batches/BatchList/batchlist.slice";
class DynamicReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        acadamicMaster: this.props.academicYearList?.academicYear,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
        subjectMaster: this.props.subjects?.subjectByDivision,
        batchMaster: this.props.subjects?.batch,
        teacherMaster: this.props.teacherList?.teacher,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department,
          },
        });
      }
    }
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employeeList !==
        nextProps.employeeDetails.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeDetails.employeeList,
          },
        });
      }
    }
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
        });
      }
    }
    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList?.timeTableList !==
        nextProps.timeTableList?.timeTableList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            timeTableHeaderMaster: nextProps.timeTableList?.timeTableList,
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            acadamicMaster: nextProps.academicYearList.academicYear,
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
    if (this.props.subjects !== nextProps.subjects) {
      if (
        this.props.subjects.subjectByDivision !==
        nextProps.subjects.subjectByDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.subjects.subjectByDivision,
          },
        });
      }
    }
    if (this.props.subjects !== nextProps.subjects) {
      if (this.props.subjects.batch !== nextProps.subjects.batch) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchMaster: nextProps.subjects.batch,
          },
        });
      }
    }

    if (this.props.teacherList !== nextProps.teacherList) {
      if (this.props.teacherList.teacher !== nextProps.teacherList.teacher) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.teacherList.teacher,
          },
        });
      }
    }
  }
  componentDidMount() {
    const {
      getDepartment,
      resetReducList,
      getYearDetails,
      getAcademicYear,
      getAlert,
    } = this.props;
    resetReducList();
    getDepartment().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    getAcademicYear().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  rowData = [
    {
      index: 1,
      reportName: "Attendance Report (D0, D1, D2, D3, D7, D8)",
      filter:
        "yearDetail,acadamicYear,semisterDetailId,divisionId,fromDate,toDate",
      condition: 1,
      url: "/api/report/v1/monthly-attendance-report?semisterDetailId={semisterDetailId}&divisionId={divisionId}&fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 2,
      reportName: "Student Attendance Report (D0, D1, D2, D3, D4)",
      filter:
        "yearDetail,divisionId,acadamicYear,semisterDetailId,subjectDetailIds",
      condition: 0,
      url: "/api/exportexcel/v1/student-subject-wise-attendance?response=&semisterDetailId={semisterDetailId}&divisionId={divisionId}&subjectDetailIds={subjectDetailIds}",
    },
    {
      index: 3,
      reportName: "Teacher Attendance Report (D0, D1, D2, D3, D4, D5, D6)",
      condition: 0,
      filter:
        "teacherId,yearDetail,divisionId,semisterDetailId,acadamicYear,subjectDetailIds,batchId",
      url: "/api/exportexcel/v1/teacher-attendance-report?response=&semisterDetailId={semisterDetailId}&divisionId={divisionId}&subjectDetailIds={subjectDetailIds}&batchId={batchId}&teacherId={teacherId}",
    },
    {
      index: 4,
      reportName: "Total Student Attendance Report (D0, D1, D2, D3, D4)",
      condition: 0,
      filter:
        "acadamicYear,yearDetail,divisionId,semisterDetailId,subjectDetailIds",
      url: "/api/exportexcel/v1/student-subject-wise-attendance/get-total?response=&semisterDetailId={semisterDetailId}&divisionId={divisionId}&subjectDetailIds={subjectDetailIds}",
    },
  ];
  condition = [
    {
      title: "index",
      value: 1,
      iconHide: "showHeadExcel",
    },
    {
      title: "index",
      value: 2,
      iconHide: "showHeadPdf",
    },
    {
      title: "index",
      value: 3,
      iconHide: "showHeadPdf",
    },
    {
      title: "index",
      value: 4,
      iconHide: "showHeadPdf",
    },
  ];
  getListById = (data) => {
    console.log(data);
    if (data.yearDetail && data.acadamicYear) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: data.acadamicYear,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    if (data.batchId) {
      this.props
        .getTeacherByBatchId({ batchId: data.batchId })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    if (data.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.semisterDetailId && data.divisionId) {
      this.props
        .getSubjectByDivision({
          semister: data.semisterDetailId,
          division: data.divisionId,
          subjectDetailId: true,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });

      this.props
        .getBatch({
          semister: data.semisterDetailId,
          division: data.divisionId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={AttendaceReportJson.screenTitle}
          fieldMeta={AttendaceReportJson.fieldMeta}
          buttonCenter={AttendaceReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={AttendaceReportJson.searchButton}
          PDFButton={AttendaceReportJson.PDFButton}
          EXCELButton={AttendaceReportJson.EXCELButton}
          baseIdColumn={AttendaceReportJson.baseIdColumn}
          apiBaseURL={AttendaceReportJson.apiBaseURL}
          marginTop={AttendaceReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
          condition={this.condition}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  departmentList: state.department,
  employeeDetails: state.employeeList,
  subjects: state.studentAttendance,
  teacherList: state.batch,
});

const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  getSemesterDetailsByYearAndAcademic,
  getTimeTable,
  resetReducList,
  getDepartment,
  getSubjectByDivision,
  getBatch,
  getTeacherByBatchId,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicReport);
