import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import TimePicker from "../../../components/Comman/TimePicker";
import * as myConstClass from "../../../config/messageconstant";
import { getEmployeeByDeptId } from "../../EmployeeDetails/employee.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";

let docs;
class AddCommittee extends Component {
  state = {
    id: 0,
    name: "",
    details: "",
    employeeDepartment: "",
    teacher: "",
    time: "",
    date: "",
    venue: "",
    urlLink: "",
    title: "",
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value, date, time, venue, urlLink } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { teacher, details, title, date, time, venue, urlLink } = this.state;

    let formErrors = {};
    let formIsValid = true;

    // if (details.toString().trim() === "" || details === null) {
    //   formIsValid = false;
    //   formErrors["detailsError"] = myConstClass.detailsMsg;
    // }

    if (title.toString().trim() === "" || title === null) {
      formIsValid = false;
      formErrors["titleError"] = myConstClass.titleMsg;
    }

    if (teacher.toString().trim() === "" || teacher === null) {
      formIsValid = false;
      formErrors["teacherError"] = myConstClass.committeeMsg;
    }

    if (date === "") {
      formIsValid = false;
      formErrors["dateErr"] = myConstClass.dateMsg;
    }

    if (time === "" || time === null) {
      formIsValid = false;
      formErrors["timeError"] = myConstClass.timeMsg;
    }

    // if (venue.toString().trim() === "" || venue === null) {
    //   formIsValid = false;
    //   formErrors["venueError"] = myConstClass.venueMessage;
    // }

    // if (urlLink.toString().trim() === "" || urlLink === null) {
    //   formIsValid = false;
    //   formErrors["urlError"] = myConstClass.urlMsg;
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, date, details, title, teacher, venue, urlLink, time } =
      this.state;
    let dateChange = moment(date).format("DD-MM-YYYY");
    let changeTime =
      time === null ? "" : moment(time, "h:mm:ss A").format("HH:mm");
    docs = {
      committee: {
        id: teacher,
      },
      details: details,
      title: title,
      date: dateChange,
      time: changeTime,
      venue: venue,
      urlLink: urlLink,

      isActive: 1,
    };
    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (this.handleFormValidation()) {
      this.props.onSave(docs);

      this.props.onCancel();
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  componentDidMount() {
    const { editData } = this.props;
    if (Object.keys(editData).length != 0) {
      const changeDateArr =
        editData.date === null ? "" : editData.date.split("-");
      const changeDate =
        changeDateArr === ""
          ? ""
          : new Date(changeDateArr[2], changeDateArr[1] - 1, changeDateArr[0]);

      const fromTimeDate =
        editData.time !== ""
          ? moment(editData.time, ["h:mm A"]).format("HH:mm")
          : "";
      const fromTimeArr =
        fromTimeDate !== " " ? fromTimeDate.split(":", -1) : null;
      const changedTime =
        fromTimeArr !== null
          ? new Date(null, null, null, fromTimeArr[0], fromTimeArr[1])
          : null;
      this.setState({
        id: editData.id,
        details: editData.details,
        title: editData.title,
        teacher: editData.committee.id,
        date: changeDate,
        time: changedTime,
        urlLink: editData.urlLink,
        venue: editData.venue,
      });
    }
    this.props.getDepartment().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  componentWillReceiveProps(nextProps) {}
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.setState({
      [dataKey]: time,
    });
  };
  changeFromDate = (dat) => {
    if (dat !== null) {
      this.setState({
        date: dat,
      });
    }
  };
  onSave = (dataToSave) => {};

  render() {
    const {
      name,
      details,
      title,
      employeeDepartment,
      teacher,
      time,
      date,
      venue,
      urlLink,
    } = this.state;
    const { onCancel } = this.props;
    const {
      teacherError,
      detailsError,
      titleError,
      dateErr,
      timeError,
      venueError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={onCancel}
              />
            </Grid>
          </Grid>
          <br />
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Committee "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={teacher}
                      name="teacher"
                      options={this.props.deemedCommitteeList?.deemedCommittee.filter(
                        (row) => row.isActive === 1
                      )}
                      onChange={this.ChangeHandlerSearch}
                      isError={teacherError ? true : false}
                      errorText={teacherError ? teacherError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Date  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="date"
                      size="small"
                      value={date}
                      fullWidth
                      onChange={this.changeFromDate}
                      isError={dateErr ? true : false}
                      errorText={dateErr ? dateErr : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Time "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TimePicker
                      value={time}
                      onChange={this.timeChangeHandler("time")}
                      error={timeError ? timeError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Title"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="title"
                      size="small"
                      value={title}
                      onChange={this.changeHandler}
                      fullWidth
                      multiline
                      error={titleError ? true : false}
                      helperText={titleError ? titleError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="details"
                      size="small"
                      value={details}
                      onChange={this.changeHandler}
                      fullWidth
                      multiline
                      error={detailsError ? true : false}
                      helperText={detailsError ? detailsError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Venue"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="venue"
                      size="small"
                      value={venue}
                      onChange={this.changeHandler}
                      fullWidth
                      error={venueError ? true : false}
                      helperText={venueError ? venueError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="URL Link"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="urlLink"
                      size="small"
                      value={urlLink}
                      onChange={this.changeHandler}
                      fullWidth
                      // error={urlError ? true : false}
                      // helperText={urlError ? urlError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{ mr: 0.5 }}
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                m={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  departmentList: state.department,
  deemedCommitteeList: state.deemedCommittee,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  getDepartment,
  getEmployeeByDeptId,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCommittee);
