import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function FormDialog({ open, onSubmit, onclose }) {
  const [remark, setRemark] = React.useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(remark);
    onSubmit(remark);
  };
  return (
    <div>
      <Dialog open={open} onClose={() => onclose(false)}>
        <DialogTitle>Remarks for Status </DialogTitle>
        <DialogContent>
          {/* <DialogContentText ></DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Remark"
            fullWidth
            variant="outlined"
            onChange={(event) => setRemark(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onclose(false)}>Cancel</Button>
          <Button onClick={submitHandler}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
