
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../../config/endpoints';
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  cocurricular: [],
  isFetch: false,
};
let URL = endpoints.cocurricular;
const cocurricularSlice = createSlice( {
  name: "cocurricular",
  initialState,
  reducers: {
    cocurricularSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        cocurricular: row,
        isFetch: true,
      };
    },
  },
} );

export const {
  cocurricularSuccess,
} = cocurricularSlice.actions;

export default cocurricularSlice.reducer;

export const getCocurricular = () => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      url: URL + '?sort={"insertdatetime": "DESC"}'
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            employeeActivity: data1.employeeActivity.id,
            name: data1.employeeActivity.name,
            date: data1.date,
            activityDetail: data1.activityDetail,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( cocurricularSuccess( { row } ) );
      }
      return success
    } );
    return response
  } catch ( e )
  {

    return console.error( e.message );
  }
};

export const getCocurricularfilter = ( { fromDate, toDate } ) => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      url: URL + "/get-curricular-activities-list-by-fromDate-toDate?fromDate=" + fromDate + "&toDate=" + toDate
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const content = data.data
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            employeeActivity: data1.employeeActivity.id,
            name: data1.employeeActivity.name,
            date: data1.date,
            activityDetail: data1.activityDetail,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( cocurricularSuccess( { row } ) );
      }
      return success
    } );
    return response
  } catch ( e )
  {

    return console.error( e.message );
  }
};


export const getCocurricularWithoutDate = (  ) => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      url: URL + "/get-curricular-activities-list-by-limit?limit=1",
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const content = data.data
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            employeeActivity: data1.employeeActivity.id,
            name: data1.employeeActivity.name,
            date: data1.date,
            activityDetail: data1.activityDetail,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( cocurricularSuccess( { row } ) );
      }
      return success
    } );
    return response
  } catch ( e )
  {

    return console.error( e.message );
  }
};
