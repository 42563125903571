
import endpoint from "../config/endpoints";

export const designationJson = {
 apiBaseURL: endpoint.designation,
  screenTitle: "Designation",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Designation",
  showExcelDownload: true,
  excelFileName: "Desgnation",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order No",
      columnKeyName: "sortOrderNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    }
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true
    }, 
   {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
     dataKey: "sortOrderNo",
     inputType:'number',
      isMandatory: true
    },
  ],
};
