import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../../../components/Comman/IconButton";
import DynamicSelectTable from "../../../../components/Comman/RT/CheckBoxSelectionTable";
const VolunteerUpdateStatusTable = ({
  rows,
  rowView,
  onSelectionChange,
  selection,
  documentVerificationList,
  rowDelete,
  rowAppliedList,
}) => {
  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      minWidth: 80,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      name: "alumniName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
    },
    {
      name: "interest",
      title: "Interests",
      minWidth: 200,
      alignCell: "center",
      alignCenter: "left",
    },
    {
      name: "expertise",
      title: "Expertise",
      minWidth: 200,
      alignCell: "center",
      alignCenter: "left",
    },
    {
      name: "time",
      title: "Time alumni can spend",
      minWidth: 200,
      alignCell: "center",
      alignCenter: "left",
    },
  ];
  const rowPrint = (row) => {};

  return (
    <DynamicSelectTable
      rows={rows}
      columns={columns}
      selection={selection}
      onSelectionChange={onSelectionChange}
      rowViewData={rowView}
      showView={false}
      showDetailsIcon={true}
      showDelete={true}
      SummarizeRoundedShow={false}
      rowPrint={rowPrint}
      rowDelete={rowDelete}
      rowAppliedList={rowAppliedList}
      isActionColActive={false}
    />
  );
};

const mapStateToProps = (state) => ({
  // documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolunteerUpdateStatusTable);
