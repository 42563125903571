import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import {
  default as endpoint,
  default as endpoints,
} from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { ListOfPlacementDynamicJson } from "../../../DynamicFormsJson/ListOfPlacementDynamicUI";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet } from "../../../utils/api_service";
import { getInternshipDeclareById } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAllPlacementList } from "../ListOfCompany/PlacementCompanyList.slice";
import InterestedStudent from "./InterestedStudent";
import {
  changeInternshipDeclareStatus,
  getCompanyList,
} from "./PlacementCompanyList.slice";
import Preview from "./Preview";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "companyName",
    title: "Company Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    name: "venue",
    title: "Venue",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    name: "date",
    title: "Date",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    name: "isActive",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];

class ListOfCompany extends React.Component {
  state = {
    id: 0,
    companyData: "",
    companyYearData: "",
    formErrors: [],
    interestedStudent: false,
    preview: false,
    listOfComp: true,
    fromDate: "",
    toDate: "",
    placementType: "",
    searchValue: "",
    showLoader: false,
    toggleOn: false,
    dynamicMasterData: {
      placementMaster: [
        { id: "0", name: "On Campus" },
        { id: "1", name: "Off Campus" },
        { id: "2", name: "Pool Campus" },
      ],
    },
  };

  componentDidMount() {
    const { resetReducList } = this.props;
    resetReducList();
    this.setState({
      showLoader: true,
    });
    this.props.getAllPlacementList().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    apiGet({
      url: endpoints.instituteConfig + "/infoForPlacement",
    }).then(({ data, success }) => {
      console.log(data);
      if (!success) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          toggleOn: data.instituteConfig.value == 0 ? false : true,
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  handleChange = (e, value) => {
    console.log(e.target.checked);
    this.setState({
      showLoader: true,
    });
    swal({
      title: "Are you sure?",
      text: "Do you want to change the status?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        this.setState({
          toggleOn: !e.target.checked,
        });
        let value = e.target.checked == true ? 1 : 0;
        apiGet({
          url:
            endpoints.instituteConfig +
            "/infoForPlacement/update-value?value=" +
            value,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Status Updated successfully",
            });
            this.setState({
              showLoader: false,
            });
          } else {
            this.props.showNotification({
              msg: "Server Failed",
              severity: "error",
            });
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { fromDate, toDate, placementType } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (placementType.toString().trim() === "" || placementType === null) {
      formIsValid = false;
      formErrors["placementTypeError"] = myConstClass.placementTypeMsg;
    }

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateLessThanToDate;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onSearchData = (data) => {
    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
      placementType: data.placement,
    });
    const { fromDate, toDate, placementType } = this.state;
    const { getCompanyList, getAlert } = this.props;
    // let fromDt = moment( fromDate ).format( "YYYY-MM-DD" );
    // let toDt = moment( toDate ).format( "YYYY-MM-DD" );
    // console.log( fromDt + " fromDate" );
    // console.log( toDt + " toDate" );
    // console.log( placementType + " placementType" );
    // if ( this.handleFormValidation() )
    // {
    this.setState({
      showLoader: true,
    });
    getCompanyList({
      fromDate: data.fromDate,
      toDate: data.toDate,
      placementType: data.placement,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    // }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  rowDetails = (data) => {
    this.setState({
      showLoader: true,
    });
    console.log(data);
    const { getInternshipDeclareById } = this.props;
    getInternshipDeclareById({
      id: data.placementDeclareId,
    }).then((response) => {
      if (response) {
        console.log(
          this.props.placementDeclarationList?.internshipDeclaration
            .yearDetailId
        );

        apiGet({
          url:
            endpoint.yearDetailId +
            "/get-distinct-year-detail-by-year-detail-ids?yearDetailIds=" +
            this.props.placementDeclarationList?.internshipDeclaration
              .yearDetailId,
        }).then(({ data, success }) => {
          this.setState({
            showLoader: false,
          });
          if (!success) {
            this.props.getAlert({
              message: "Something went wrong",
            });
          } else {
            console.log(data);
            const yearDetailsId = data.map((data1) => {
              return (
                data1.year.programType.name +
                " - " +
                data1.programTitle.brName +
                " - " +
                data1.year.className
              );
            });
            this.setState({
              preview: true,
              interestedStudent: false,
              listOfComp: false,
              paymentDetailsData: data,
              companyYearData: yearDetailsId.join(),
            });
            console.log(yearDetailsId.join());
          }
        });
      } else {
        this.props.getAlert({ message: "Something went wrong" });
        this.setState({
          showLoader: false,
        });
      }
    });
  };
  GoBack = () => {
    this.setState({
      preview: false,
      interestedStudent: false,
      listOfComp: true,
    });
  };
  onChangeStatus = (data) => {
    const { fromDate, toDate, placementType } = this.state;
    const { getCompanyList, changeInternshipDeclareStatus } = this.props;
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    console.log(data);
    this.setState({
      showLoader: true,
    });
    swal({
      title: "Are you sure?",
      text: "Do you want to change the status?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      console.log(data);
      if (res) {
        changeInternshipDeclareStatus({
          placementDeclareId: data.placementDeclareId,
          status: data.isActive ? 0 : 1,
        }).then((success) => {
          if (success) {
            this.props.getAllPlacementList().then((response) => {
              this.props.showNotification({ msg: "Status updated" });
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          }
        });
      }
    });
  };
  rowAppliedList = (data) => {
    console.log(data);
    this.setState({
      showLoader: true,
    });
    console.log(data.internshipDeclareId);
    const { comanyData } = this.state;
    this.setState({
      interestedStudent: true,
      listOfComp: false,
      companyData: data,
      showLoader: false,
    });
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };

  rowDelete = (data) => {
    this.setState({
      showLoader: true,
    });
    const { fromDate, toDate, internType, placementType } = this.state;
    const { getCompanyList } = this.props;
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    console.log(data);
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      console.log(data);
      if (res) {
        apiDelete({
          url: endpoints.placementDeclare + "/" + data.placementDeclareId,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data deleted successfully",
            });
            this.setState({
              showLoader: false,
            });
            this.props.getAllPlacementList().then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          }
        });
        // getAppliedEventList(data);
        //   this.onSave(data);
      }
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    const { internshipDeclareCompanyList } = this.props;
    return internshipDeclareCompanyList?.companyListStatus.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["companyName"] &&
            currentRow["companyName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["venue"] &&
            currentRow["venue"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["date"] &&
            currentRow["date"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  onAddButtonClick = () => {
    window.location.replace("/PlacementDeclaration");
  };

  render() {
    const {
      interestedStudent,
      listOfComp,
      fromDate,
      toDate,
      companyData,
      placementType,
      preview,
      dynamicMasterData,
      toggleOn,
    } = this.state;
    const { fromDateError, toDateError, placementTypeError } =
      this.state.formErrors;
    const { internshipDeclareCompanyList, placementDeclarationList } =
      this.props;
    return (
      <>
        <div>
          {/* <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}> */}
          {listOfComp && !interestedStudent && (
            <>
              <DynamicMainScreen
                onAddButtonClick={this.onAddButtonClick}
                screenTitle={ListOfPlacementDynamicJson.screenTitle}
                fieldMeta={ListOfPlacementDynamicJson.fieldMeta}
                buttonCenter={ListOfPlacementDynamicJson.buttonCenter}
                showPdfDownload={ListOfPlacementDynamicJson.showPdfDownload}
                showExcelDownload={ListOfPlacementDynamicJson.showExcelDownload}
                pdfFileName={ListOfPlacementDynamicJson.pdfFileName}
                excelFileName={ListOfPlacementDynamicJson.excelFileName}
                tableColumnsToFilter={
                  ListOfPlacementDynamicJson.tableColumnsToFilter
                }
                showSwitchButton={true}
                handleChange={this.handleChange}
                checked={toggleOn}
                title={
                  "On toggling the switch, a card will appear on the student dashboard which will allow you to take the required information from the student."
                }
                showAddButton={ListOfPlacementDynamicJson.showAddButton}
                dynamicMasterData={dynamicMasterData}
                tableColumns={columns}
                tableData={internshipDeclareCompanyList?.companyListStatus}
                getListById={this.getListById}
                showHeadEdit={false}
                pdfDetailsId={this.pdfDetails}
                rowViewData={this.onClickView}
                onDelete={this.onDelete}
                onSearchData={this.onSearchData}
                baseIdColumn={ListOfPlacementDynamicJson.baseIdColumn}
                apiBaseURL={ListOfPlacementDynamicJson.apiBaseURL}
                showLoader={this.state.showLoader}
                showHeadDelete={true}
                showAppliedList={true}
                showDetails={true}
                rowDelete={this.rowDelete}
                rowAppliedList={this.rowAppliedList}
                rowDetails={this.rowDetails}
                showPegination={true}
                rowStatus={this.onChangeStatus}
              />

              {/* <Grid container justifyContent="space-evenly" direction="row">
                  <Grid container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label="List of Placement Declared"
                      />
                    </Grid>
                    <Grid
                      sx={{ mb: 2 }}
                      item
                      xs={2}
                      md={6}
                      container
                      justifyContent="flex-end"
                    >
                      <Link
                        color="primary"
                        variant="body2"
                        component={RouterLink}
                        to="/PlacementDeclaration"
                      >
                        <MyComponentWithIconProps
                          statusImage={AddCircleIcon}
                          color="primary"
                          fontSize="large"
                          title="Declare Placement"
                        />
                      </Link>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*From Date "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="fromDate"
                          size="small"
                          value={fromDate}
                          fullWidth
                          onChange={this.onDateChange( "fromDate" )}
                          isError={fromDateError ? true : false}
                          errorText={fromDateError ? fromDateError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*To Date  "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="toDate"
                          size="small"
                          value={toDate}
                          fullWidth
                          onChange={this.onDateChange( "toDate" )}
                          isError={toDateError ? true : false}
                          errorText={toDateError ? toDateError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Placement Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="placementType"
                          id="placementType"
                          value={placementType}
                          onChange={this.changedHandler}
                          size="small"
                          fullWidth={true}
                          error={placementTypeError ? true : false}
                        >
                          <MenuItem value="0">On Campus</MenuItem>
                          <MenuItem value="1">Off Campus</MenuItem>
                          <MenuItem value="2">Pool Campus</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {placementTypeError ? placementTypeError : " "}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                    sx={{ marginTop: 3 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.searchData}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  sx={{ marginTop: 1, marginBottom: 2 }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <SearchCompanyList
                    onSearch={this.onSearch}
                    showEmpNotice={false}
                    showStudNotice={false}
                    showStudFeedback={false}
                    showEmpFeedback={false}
                    showEmpNoticeDetails={false}
                    showEmpFeedbackDetails={false}
                    showStudFeedbackDetails={false}
                    showStudNoticeDetails={false}
                  />
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <DynamicTable
                    data={this.getFilteredTableData()}
                    tableHead={columns}
                    showHeadDelete={true}
                    showAppliedList={true}
                    showDetails={true}
                    rowDelete={this.rowDelete}
                    rowAppliedList={this.rowAppliedList}
                    rowDetails={this.rowDetails}
                    showPegination={false}
                    rowStatus={this.onChangeStatus}
                  />
                </Grid> */}

              {/* <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ p: 2 }}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    container
                    justifyContent="right"
                    sx={{ mt: 1,mr:-4 }}

                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back"
                      onClick={this.back}
                    />
                  </Grid>
                </Grid> */}
            </>
          )}
          {!listOfComp && interestedStudent && (
            <InterestedStudent
              onCancel={this.closeStudentFeesDetails}
              companyData={companyData}
            />
          )}

          {preview && (
            <Preview
              GoBack={this.GoBack}
              DeclarationData={placementDeclarationList?.internshipDeclaration}
              companyYearData={this.state.companyYearData}
            />
          )}
          {/* </Paper> */}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclareCompanyList: state.companyPlacementList,
  internshipDeclarationList: state.internshipDeclaration,
  placementDeclarationList: state.placementDeclaration,
});
const mapDispatchToProps = {
  getAlert,
  getCompanyList,
  resetReducList,
  getInternshipDeclareById,
  changeInternshipDeclareStatus,
  showNotification,
  getAllPlacementList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOfCompany);
