import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import * as myConstClass from "../../config/messageconstant";
import { teacherVivaDynamicJson } from "../../DynamicFormsJson/teacherVivaDynamic";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { getAllocationList } from "./TeacherViva.slice";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
  },

  {
    name: "yearDetails",
    title: "Year Details",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    width: "40%",
  },

  {
    name: "division",
    title: "Division",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,

    width: "20%",
  },
  {
    name: "remark",
    title: "Remark",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,

    width: "20%",
  },
  {
    name: "status",
    title: "Status",
    positionCenter: true,
    showInscreen: true,

    alignCenter: "center",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "20%",
  },
];
const data = [
  {
    index: 1,
    academicYear: "2021-2022",
    allocationStatus: "1st Allocation",
    status: "1",
  },
];

class TeacherStudentAllocation extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      internshipType: "",
      acadamicYear: "",
      showLoader: false,
      fieldData: {},
      formErrors: {},
      searchValue: "",
      dynamicMasterData: {
        internshipMaster: this.props.internshipTypeList?.internshipType,
      },
    };
  }

  componentDidMount() {
    const { getInternshipType, getAcademicYear, getAlert, resetReducList } =
      this.props;
    resetReducList();

    getInternshipType().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const dataToSearch = {};

    if (this.props.internshipTypeList !== nextProps.internshipTypeList) {
      if (
        this.props.internshipTypeList.internshipType !==
        nextProps.internshipTypeList.internshipType
      ) {
        if (
          nextProps.internshipTypeList.internshipType.length !== 0 &&
          nextProps.internshipTypeList.internshipType[0].id
        ) {
          dataToSearch["internship"] =
            nextProps.internshipTypeList.internshipType[0].id;

          const ay = localStorage.getItem("acadamicYearId");

          const { getAllocationList, getAlert } = this.props;
          this.setState({
            showLoader: true,
          });
          getAllocationList({
            academicId: ay,
            internType: nextProps.internshipTypeList.internshipType[0].id,
          }).then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        }
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            internshipMaster: nextProps.internshipTypeList.internshipType,
          },
          fieldData: dataToSearch,
        });
      }
    }
  }
  handleFormValidation() {
    const { acadamicYear, internshipType } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (internshipType.toString().trim() === "" || internshipType === null) {
      formIsValid = false;
      formErrors["internTypeError"] = myConstClass.internTypeMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  onSearchData = (data) => {
    const ay = localStorage.getItem("acadamicYearId");

    const { getAllocationList, getAlert } = this.props;
    this.setState({
      showLoader: true,
    });
    getAllocationList({
      academicId: ay,
      internType: data.internship,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.allocationList?.allocationList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["year"] &&
          currentRow["year"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["division"] &&
          currentRow["division"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["remark"] &&
          currentRow["remark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      handleClickOpen,
      academicYearList,
      internshipTypeList,
      allocationList,
    } = this.props;
    const {
      internshipType,
      acadamicYear,
      dynamicMasterData,
      showLoader,
      fieldData,
    } = this.state;
    const { academicYearError, internTypeError } = this.state.formErrors;
    return (
      <>
        {/* <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}> */}
        {/* <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Teacher Viva"
              />
            </Grid>
          </Grid>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={5.5}
                md={5.5}
                sx={{ marginTop: 4 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Intern Type"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                    keyColName={"id"}
                    value={internshipType}
                    name={"internshipType"}
                    options={internshipTypeList?.internshipType}
                    onChange={this.ChangeHandlerSearch}
                    isError={internTypeError ? true : false}
                    errorText={internTypeError ? internTypeError : " "}
                  />
                </Grid>
                </Grid>
                <Grid
                container
                justifyContent="flex-start"
                direction="row"
                xs={12}
                sm={6.5}
                md={6.5}
                sx={{ marginTop: 4 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Search"
                    onClick={this.submitHandler}
                  />
              </Grid>
            </Grid>
              </Grid>

          </form>
          <Grid
            sx={{ marginTop: 1, marginBottom: 2 }}
            item
            xs={12}
            sm={12}
            md={12}
          >
            <SearchTeacherVivaList onSearch={this.onSearch} />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <DynamicTable
              data={this.getFilteredTableData()}
              tableHead={columns}
              showView={true}
              rowViewData={handleClickOpen}
              showPegination={false}
            />

            <Grid
              item
              sm={12} 
              xs={12}
              md={12}
              lg={12}
              m={2}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
          </Grid> */}

        <DynamicMainScreen
          onAddButtonClick={this.onAddButtonClick}
          screenTitle={teacherVivaDynamicJson.screenTitle}
          fieldMeta={teacherVivaDynamicJson.fieldMeta}
          buttonCenter={teacherVivaDynamicJson.buttonCenter}
          showPdfDownload={teacherVivaDynamicJson.showPdfDownload}
          showExcelDownload={teacherVivaDynamicJson.showExcelDownload}
          pdfFileName={teacherVivaDynamicJson.pdfFileName}
          excelFileName={teacherVivaDynamicJson.excelFileName}
          tableColumnsToFilter={teacherVivaDynamicJson.tableColumnsToFilter}
          showAddButton={teacherVivaDynamicJson.showAddButton}
          dynamicMasterData={dynamicMasterData}
          tableColumns={columns}
          fieldData={fieldData}
          tableData={this.props.allocationList?.allocationList}
          showPegination={true}
          showLoader={showLoader}
          rowDetails={this.props.handleClickOpen}
          showDetails={true}
          onSearchData={this.onSearchData}
          baseIdColumn={teacherVivaDynamicJson.baseIdColumn}
          apiBaseURL={teacherVivaDynamicJson.apiBaseURL}
        />

        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipTypeList: state.internshipType,
  allocationList: state.allocationList,
});
const mapDispatchToProps = {
  getInternshipType,
  getAlert,
  getAllocationList,
  resetReducList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherStudentAllocation);
