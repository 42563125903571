import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import * as React from "react";
//import frLocale from 'date-fns/locale/fr';
import enINLocale from "date-fns/locale/en-IN";

export default function BasicDatePicker({
  label,
  value,
  isError = false,
  errorText = " ",
  maxDate,
  minDate,
  onChange,
  disabled,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enINLocale}>
      <DatePicker
        label={label}
        //value={value?moment(value).format('DD/MM/YYYY'):null}
        value={value || null}
        maxDate={maxDate}
        // format={'DD/MM/YYYY'}
        minDate={minDate}
        onChange={onChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            size="small"
            fullWidth
            {...params}
            value={value}
            error={isError}
            helperText={errorText}
          />
        )}
      />
    </LocalizationProvider>
  );
}
