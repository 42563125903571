import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { connect } from "react-redux";

const CompletedSyllabus = ({ syllabus = [] }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Sr. No.</TableCell>
              <TableCell width="30%">Chapter Name</TableCell>
              <TableCell width="30%">Section </TableCell>
              <TableCell width="30%">Sub Section </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {syllabus.map((row, index) => (
              <>
                <TableRow key={row.id}>
                  <TableCell align="center">{index + 1}</TableCell>

                  <TableCell component="th" scope="row" colSpan={3}>
                    {row.chapter}
                  </TableCell>
                </TableRow>

                {row.sectionList.map((section, index) => {
                  return (
                    <>
                      <TableRow key={section.id}>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell component="th" scope="row" colSpan={2}>
                          {section.sectionName}
                        </TableCell>
                      </TableRow>
                      {section.subSectionList.map((subSection, index) => {
                        return (
                          <>
                            <TableRow key={subSection.id}>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center" colSpan={2}></TableCell>
                              <TableCell component="th" scope="row">
                                {subSection.subSectionName}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      <TableRow key={row.chapter}></TableRow>
                    </>
                  );
                })}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedSyllabus);
