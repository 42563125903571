
    import endpoint from "../config/endpoints";
    
    export const ReceiptTypeMasterJson = {
    "apiBaseURL": endpoint.receiptType,
    "screenTitle": "Receipt Type",
    "showAddButton": true,
    "showPdfDownload": true,
    "pdfFileName": "Receipt Type",
    "showExcelDownload": true,
    "excelFileName": "Receipt Type",
    "tableColumnsToFilter": [
        {
            "columnDisplayName": "Name",
            "columnKeyName": "name",
            "isChecked": true
        },
        {
            "columnDisplayName": "Status",
            "columnKeyName": "isActive",
            "isChecked": true
        }
    ],
    "baseIdColumn": "id",
    "fieldMeta": [
        {
            "label": "Name",
            "controlType": "textfield",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "name",
            "isMandatory": true
        }
    ]
}