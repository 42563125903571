import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  semister: [],
  isFetch: false,
  semisterDetails: [],
  isFetchSemisterDetail: false,

  semesterList: [],
  isFetchSemesterList: false,
};
let URL = endpoints.semister;
const semisterInfoSlice = createSlice({
  name: "semister",
  initialState,
  reducers: {
    semisterInfoSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        semister: row,
        isFetch: true,
      };
    },

    semesterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        semesterList: row,
        isFetchSemesterList: true,
      };
    },

    semisterDetailsInfoSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        semisterDetails: row,
        isFetchSemisterDetail: true,
      };
    },
  },
});

export const {
  semisterInfoSuccess,
  semisterDetailsInfoSuccess,
  semesterSuccess,
} = semisterInfoSlice.actions;

export default semisterInfoSlice.reducer;

export const getSemisterInfo = () => async (dispatch) => {
  try {
    const response = apiGet({
      // url: URL + '?sort={"insertdatetime": "DESC"}'
      url: URL + '?sort={"sortNo": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            yearDetailName:
              data1.getYearDetailId !== null
                ? data1.getYearDetailId.year.programType.name +
                  " - " +
                  data1.getYearDetailId.programTitle.brName +
                  " - " +
                  data1.getYearDetailId.year.className
                : "",
            yearDetailId:
              data1.getYearDetailId !== null ? data1.getYearDetailId.id : "",
            sortNo: data1.sortNo,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(semisterInfoSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSemesterInfoByYD =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + yearDetail + "/get-semister-by-year-detail-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              yearDetailId: data1.yearDetailId,
              sortNo: data1.sortNo,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(semisterInfoSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSemisterDetailsBySemisterInfoId =
  ({ semisterId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + semisterId + "/semister-details",
      }).then(({ data, success }) => {
        if (success) {
          const list = data.data;
          let index = 0;
          let row = [];
          list.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              academicYearName: data1.getAcademicYear.name,
              semisterStartDate: data1.semisterStartDate,
              semisterEndDate: data1.semisterEndDate,
              semisterName: data1.semister.name,
              semisterId: data1.semister.id,
              academicYearId: data1.getAcademicYear.id,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(semisterDetailsInfoSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSemesterByYearDetails =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/" +
          yearDetailId +
          "/by-year-detail-id" +
          '?sort={"sortNo": "ASC"}',
      }).then(({ data, success }) => {
        if (success) {
          const list = data.data;
          let index = 0;
          let row = [];
          list.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              sortNo: data1.sortNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(semesterSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSemesterByYearDetailId =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/" +
          yearDetailId +
          "/get-semister-by-year-detail-id" +
          '?sort={"sortNo": "ASC"}',
      }).then(({ data, success }) => {
        if (success) {
          const list = data.data;
          let index = 0;
          let row = [];
          list.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              sortNo: data1.sortNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(semesterSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
