import endpoint from "../config/endpoints";
export const ExtendedActivitiesJson = {
  apiBaseURL: endpoint.extendedActivity,
  deleteUrl: endpoint.extendedActivityDoc,
  screenTitle: "Faculty Activity",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Faculty Activities",
  showExcelDownload: true,
  excelFileName: "Faculty Activities",
  baseIdColumn: "id",
  DocumentUpload: "extendedActivitiesDocuments",
  fieldMeta: [
    {
      label: "Activity Name",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "employeeActivity",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Activity Title",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "activityTitle",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "Organized By",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "organizedBy",
      isMandatory: true,
    },
    {
      label: "Activity Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "type",
      dataKey: "activityType",
      isMandatory: true,
      isRootLevelKey: true,
      getListFrom: "programType",
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ActivitytypeNew",
      tableDataKey: "activityType",
      open: "other",
      error: "Activity Type",
    },
    {
      label: "Level",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "LevelMaster",
      dataKey: "level",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "LevelMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "levelData",
      tableDataKey: "level",
      open: "other",
      error: "Level",
    },
    {
      label: "No. of participants",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "noOfParticipants",
      inputType: "number",
      isMandatory: false,
    },
  ],
};
