import { Avatar, Button, Grid, List, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import user from "../../Images/user.jpeg";
import { apiPost } from "../../utils/api_service";
import { showNotification } from "../Landing/Landing.slice";
import { getMaterialReview } from "./ematerial.slice";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const EmaterialStudentCommentView = ({
  ematerialList,
  commentData,
  getMaterialReview,
  getAlert,
  showNotification,
}) => {
  const [comment, setComment] = React.useState("");
  const [reply, setReply] = React.useState("");
  const [commentRowData, setCommentData] = React.useState({});
  const [commentList, setCommentList] = React.useState([]);
  const [formErrors, setFormErrors] = React.useState({});
  const [showComment, setShowComment] = React.useState(false);

  React.useEffect(() => {
    if (Object.keys(commentData).length !== 0) {
      setCommentData(commentData);
    }
  });
  React.useEffect(() => {
    if (commentList.length === 0 && ematerialList.materialReview.length !== 0) {
      setCommentList(ematerialList.materialReview);
    } else {
      if (
        commentList.length !== 0 &&
        commentList !== ematerialList.materialReview
      ) {
        setCommentList(ematerialList.materialReview);
      }
    }
  }, []);

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (comment === "") {
      formIsValid = false;
      formErrors["commentError"] = myConstClass.commentMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const checkReplyValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (reply === "") {
      formIsValid = false;
      formErrors["replyError"] = myConstClass.replyMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const submitReplyHandler = (e, section) => {
    let docs = {
      ematerialReview: {
        id: section.id,
      },
      reply: reply,
    };
    if (checkReplyValidation()) {
      apiPost({
        url: "/api/acdmc/v1/ematerial-review-detail",
        postBody: docs,
      }).then(({ data, success }) => {
        if (success) {
          setReply("");
          getMaterialReview({ ematerialId: commentRowData.id }).then(
            (response) => {
              if (!response.success) {
                getAlert({ message: "Something went wrong" });
              } else {
                const list = response.data.data;
                let index = 0;
                let row = [];
                let rowData2 = [];
                let flag = false;
                list.map((data1) => {
                  if (data1.id === section.id) {
                    flag = true;
                  } else {
                    flag = false;
                  }
                  const dt =
                    data1.dateTime == null ? "" : data1.dateTime.split(" ");
                  const DOBArr = dt == "" ? "" : dt[0].split("-");
                  const time =
                    dt == "" ? "" : moment(dt[1], "HH:mm:ss").format("hh:mm A");
                  const DOB =
                    DOBArr === ""
                      ? ""
                      : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

                  index = index + 1;
                  let bData = {
                    index: index,
                    id: data1.id,
                    comment: data1.comment,
                    dateTimeFormat:
                      moment(DOB).format("dddd, MMMM D, Y") + " " + time,
                    dateTime: data1.dateTime,
                    name:
                      data1.userRegistration.firstName +
                      " " +
                      data1.userRegistration.middleName +
                      " " +
                      data1.userRegistration.lastName,
                    showText: false,
                    showComment: flag,
                    ematerialReviewDetail: data1.ematerialReviewDetail.map(
                      (data2) => {
                        const dt1 =
                          data2.dateTime == null
                            ? ""
                            : data2.dateTime.split(" ");
                        const DOBArr = dt1 == "" ? "" : dt1[0].split("-");
                        const time1 =
                          dt1 == ""
                            ? ""
                            : moment(dt1[1], "HH:mm:ss").format("hh:mm A");
                        const DOB =
                          DOBArr === ""
                            ? ""
                            : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);
                        index = index + 1;
                        let bData1 = {
                          index: index,
                          id: data2.id,
                          reply: data2.reply,
                          dateTimeFormat:
                            moment(DOB).format("dddd, MMMM D, Y") + " " + time1,
                          dateTime: data2.dateTime,
                          name:
                            data2.userRegistration.firstName +
                            " " +
                            data2.userRegistration.middleName +
                            " " +
                            data2.userRegistration.lastName,
                          showText: false,
                          showComment: false,
                        };
                        return bData1;
                      }
                    ),
                  };
                  row.push(bData);
                  return data1;
                });
                setCommentList(row);
              }
            }
          );
        } else {
          showNotification({
            msg: "Failed to save ",
            severity: "error",
          });
        }
      });
    }
  };

  const submitHandler = () => {
    let docs = {
      ematerial: {
        id: commentRowData.id,
      },
      comment: comment,
      isActive: 1,
    };
    if (checkValidation()) {
      apiPost({
        url: endpoint.ematerialReview,
        postBody: docs,
      }).then(({ data, success }) => {
        if (success) {
          setComment("");
          getMaterialReview({ ematerialId: commentRowData.id }).then(
            (response) => {
              if (!response.success) {
                getAlert({ message: "Something went wrong" });
              } else {
                const list = response.data.data;
                let index = 0;
                let row = [];
                list.map((data1) => {
                  const dt =
                    data1.dateTime == null ? "" : data1.dateTime.split(" ");
                  const DOBArr = dt == "" ? "" : dt[0].split("-");
                  const time =
                    dt == "" ? "" : moment(dt[1], "HH:mm:ss").format("hh:mm A");
                  const DOB =
                    DOBArr === ""
                      ? ""
                      : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

                  index = index + 1;
                  let bData = {
                    index: index,
                    id: data1.id,
                    comment: data1.comment,
                    dateTimeFormat:
                      moment(DOB).format("dddd, MMMM D, Y") + " " + time,
                    dateTime: data1.dateTime,
                    name:
                      data1.userRegistration.firstName +
                      " " +
                      data1.userRegistration.middleName +
                      " " +
                      data1.userRegistration.lastName,
                    showText: false,
                    ematerialReviewDetail: data1.ematerialReviewDetail.map(
                      (data2) => {
                        const dt1 =
                          data2.dateTime == null
                            ? ""
                            : data2.dateTime.split(" ");
                        const DOBArr = dt1 == "" ? "" : dt1[0].split("-");
                        const time1 =
                          dt1 == ""
                            ? ""
                            : moment(dt1[1], "HH:mm:ss").format("hh:mm A");
                        const DOB =
                          DOBArr === ""
                            ? ""
                            : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

                        index = index + 1;
                        let bData1 = {
                          index: index,
                          id: data2.id,
                          reply: data2.reply,
                          dateTimeFormat:
                            moment(DOB).format("dddd, MMMM D, Y") + " " + time1,
                          dateTime: data2.dateTime,
                          name:
                            data2.userRegistration.firstName +
                            " " +
                            data2.userRegistration.middleName +
                            " " +
                            data2.userRegistration.lastName,
                          showText: false,
                          showComment: false,
                        };
                        return bData1;
                      }
                    ),
                  };
                  row.push(bData);
                  return data1;
                });
                setCommentList(row);
              }
            }
          );
        } else {
          showNotification({
            msg: "Failed to save ",
            severity: "error",
          });
        }
      });
    }
  };

  const changedHandler = (event) => {
    const { name, value } = event.target;

    setComment(value);
  };

  const changedReplyHandler = (event) => {
    const { name, value } = event.target;

    setReply(value);
  };

  const onClickView = (e, section) => {
    let rowData2 = [];
    if (section.showComment) {
      const list = commentList.map((data) => {
        if (data.id === section.id) {
          let docs = {
            ...data,
            showComment: false,
          };
          rowData2.push(docs);
        } else {
          rowData2.push(data);
        }
      });
      setCommentList(rowData2);
    } else {
      const list = commentList.map((data) => {
        if (data.id === section.id) {
          let docs = {
            ...data,
            showComment: true,
          };
          rowData2.push(docs);
        } else {
          rowData2.push(data);
        }
      });
      setCommentList(rowData2);
    }
  };

  const onClickReplyWise = (e, section, data) => {
    const list = commentList.map((data1) => {
      if (data1.id === section.id) {
        let list1 = data1.ematerialReviewDetail.map((data2) => {
          if (data2.id === data.id) {
            data2 = {
              ...data2,
              showText: !data2.showText,
            };
            return data2;
          } else {
            return data2;
          }
        });
        data1 = {
          ...data1,
          ematerialReviewDetail: list1,
        };
        return data1;
      } else {
        return data1;
      }
    });
    setCommentList(list);
  };

  const onClickReply = (e, section) => {
    let rowList = commentList.filter((row) => row.showText === true);
    if (rowList.length !== 0) {
      if (section.id === rowList[0].id) {
        let rowData2 = [];

        let row1 = commentList.map((data) => {
          if (data.showText === true) {
            let docs = {
              ...data,
              showText: false,
            };
            rowData2.push(docs);
          } else {
            rowData2.push(data);
          }
        });
        setCommentList(rowData2);
      } else {
        let rowData1 = [];
        let row1 = commentList.map((data) => {
          if (data.showText === true) {
            let docs = {
              ...data,
              showText: false,
            };
            rowData1.push(docs);
          } else {
            rowData1.push(data);
          }
        });
        let rowData = [];
        let row = rowData1.map((data) => {
          if (data.id === section.id) {
            let docs = {
              ...data,
              showText: true,
            };
            rowData.push(docs);
          } else {
            let docs = {
              ...data,
              showText: false,
            };
            rowData.push(data);
          }
        });
        setCommentList(rowData);
      }
    } else {
      let rowData1 = [];
      let row1 = commentList.map((data) => {
        if (data.showText === true) {
          let docs = {
            ...data,
            showText: false,
          };
          rowData1.push(docs);
        } else {
          rowData1.push(data);
        }
      });
      let rowData = [];
      let row = rowData1.map((data) => {
        if (data.id === section.id) {
          let docs = {
            ...data,
            showText: true,
          };
          rowData.push(docs);
        } else {
          let docs = {
            ...data,
            showText: false,
          };
          rowData.push(data);
        }
      });
      setCommentList(rowData);
    }
  };

  return (
    <>
      <Paper sx={{ p: 1, pt: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          xs={12}
          sm={12}
          md={12}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={3}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item xs={12} sm={1} md={1}>
              <Avatar
                sx={{ width: 40, height: 40, mb: 1, bgcolor: "red" }}
                id="basic-button"
                aria-haspopup="true"
                src={user}
              ></Avatar>
            </Grid>

            <Grid item xs={12} sm={10} md={10}>
              <TextField
                color="primary"
                name="comment"
                size="small"
                value={comment}
                onChange={changedHandler}
                placeholder="Add a comment..."
                multiline
                fullWidth
                helperText={
                  formErrors.commentError ? formErrors.commentError : " "
                }
                error={formErrors.commentError ? true : false}
              />
            </Grid>

            <Grid item xs={12} sm={1} md={1}>
              <ButtonCompo
                size="button"
                type="button"
                variant="contained"
                name="Post"
                onClick={submitHandler}
              />
            </Grid>
          </Grid>
        </Grid>
        <Paper style={{ maxHeight: 450, overflow: "auto" }} elevation={0}>
          <List>
            {commentList.map((section) => {
              return (
                <>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ scrollBehavior: "smooth", "overflow-x": "scroll" }}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ marginTop: 2 }}
                    >
                      <Grid item xs={12} sm={0.5} md={0.5}>
                        <Avatar
                          sx={{ width: 30, height: 30, mb: 1, bgcolor: "red" }}
                          id="basic-button"
                          aria-haspopup="true"
                          src={user}
                        ></Avatar>
                      </Grid>

                      <Grid item xs={12} sm={11.5} md={11.5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 18, fontWeight: "bold" }}
                          label={section.name}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={12}
                      md={12}
                      columnSpacing={3}
                    >
                      <Grid item xs={12} sm={1} md={1}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={11} md={11}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={section.comment}
                        />
                      </Grid>

                      <Grid item xs={12} sm={10} md={10}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 10 }}
                          label={section.dateTimeFormat}
                        />
                      </Grid>
                      <Grid item xs={12} sm={10.5} md={10.5}>
                        <Button
                          variant="outlined"
                          style={{ border: "none" }}
                          sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "black",
                          }}
                          onClick={(e) => onClickReply(e, section)}
                        >
                          {"Reply"}
                        </Button>
                        &nbsp;
                        <Button
                          variant="outlined"
                          endIcon={<ExpandCircleDownIcon />}
                          style={{ border: "none" }}
                          sx={{ fontSize: 12 }}
                          onClick={(e) => onClickView(e, section)}
                        >
                          {section.ematerialReviewDetail.length + " Replies"}
                        </Button>
                      </Grid>
                    </Grid>

                    {section.showComment && (
                      <>
                        {section.ematerialReviewDetail.map((data) => {
                          return (
                            <>
                              <Grid
                                container
                                justifyContent="space-evenly"
                                direction="row"
                                xs={12}
                                sm={12}
                                md={12}
                                style={{
                                  scrollBehavior: "smooth",
                                  "overflow-x": "scroll",
                                }}
                              >
                                <Grid
                                  container
                                  justifyContent="space-evenly"
                                  direction="row"
                                  columnSpacing={3}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  sx={{ marginLeft: 7, marginTop: 1 }}
                                >
                                  <Grid item xs={12} sm={0.5} md={0.5}>
                                    <Avatar
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        mb: 1,
                                        bgcolor: "red",
                                      }}
                                      id="basic-button"
                                      aria-haspopup="true"
                                      src={user}
                                    ></Avatar>
                                  </Grid>

                                  <Grid item xs={12} sm={11.5} md={11.5}>
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                      label={data.name}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  justifyContent="space-evenly"
                                  direction="row"
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  columnSpacing={3}
                                >
                                  <Grid item xs={12} sm={1.5} md={1.5}>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={10.5} md={10.5}>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                      label={data.reply}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={1.5} md={1.5}>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={10.5} md={10.5}>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 10 }}
                                      label={data.dateTimeFormat}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={1.3} md={1.3}>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 16 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={10.7} md={10.7}>
                                    <Button
                                      variant="outlined"
                                      style={{ border: "none" }}
                                      sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                      onClick={(e) =>
                                        onClickReplyWise(e, section, data)
                                      }
                                    >
                                      {"Reply"}
                                    </Button>
                                    &nbsp;
                                  </Grid>
                                  {data.showText && (
                                    <Grid
                                      container
                                      justifyContent="space-evenly"
                                      direction="row"
                                      columnSpacing={3}
                                      xs={12}
                                      sm={12}
                                      md={12}
                                    >
                                      <Grid item xs={12} sm={1} md={1}></Grid>
                                      <Grid item xs={12} sm={0.8} md={0.8}>
                                        <Avatar
                                          sx={{
                                            width: 40,
                                            height: 40,
                                            bgcolor: "red",
                                          }}
                                          id="basic-button"
                                          aria-haspopup="true"
                                          src={user}
                                        ></Avatar>
                                      </Grid>

                                      <Grid item xs={12} sm={8.8} md={8.8}>
                                        <TextField
                                          color="primary"
                                          name="reply"
                                          size="small"
                                          value={reply}
                                          variant="standard"
                                          multiline
                                          onChange={(e) =>
                                            changedReplyHandler(e, section)
                                          }
                                          placeholder="Add a reply..."
                                          fullWidth
                                          helperText={
                                            formErrors.replyError
                                              ? formErrors.replyError
                                              : " "
                                          }
                                          error={
                                            formErrors.replyError ? true : false
                                          }
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={1} md={1}>
                                        <ButtonCompo
                                          size="small"
                                          type="button"
                                          variant="contained"
                                          name="Add"
                                          onClick={(e) =>
                                            submitReplyHandler(e, section)
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    )}

                    {section.showText && (
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        columnSpacing={3}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <Grid item xs={12} sm={1} md={1}></Grid>
                        <Grid item xs={12} sm={0.8} md={0.8}>
                          <Avatar
                            sx={{
                              width: 40,
                              height: 40,
                              bgcolor: "red",
                            }}
                            id="basic-button"
                            aria-haspopup="true"
                            src={user}
                          ></Avatar>
                        </Grid>

                        <Grid item xs={12} sm={8.8} md={8.8}>
                          <TextField
                            color="primary"
                            name="reply"
                            size="small"
                            value={reply}
                            variant="standard"
                            multiline
                            onChange={(e) => changedReplyHandler(e, section)}
                            placeholder="Add a reply..."
                            fullWidth
                            helperText={
                              formErrors.replyError
                                ? formErrors.replyError
                                : " "
                            }
                            error={formErrors.replyError ? true : false}
                          />
                        </Grid>

                        <Grid item xs={12} sm={1} md={1}>
                          <ButtonCompo
                            size="small"
                            type="button"
                            variant="contained"
                            name="Add"
                            onClick={(e) => submitReplyHandler(e, section)}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <br />
                </>
              );
            })}
          </List>
        </Paper>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  ematerialList: state.ematerial,
});

const mapDispatchToProps = {
  getMaterialReview,
  showNotification,
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmaterialStudentCommentView);
