import * as React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import MarkSubMission from "./MarkSubMission";
import TeacherStudentAllocation from "./TeacherStudentAllocation";
import {
  getAllotedStudentListByid,
  updateStudentVivaMarks,
} from "./TeacherViva.slice";

function DisableElevation({
  getAllotedStudentListByid,
  updateStudentVivaMarks,
  getAlert,
}) {
  const [open, setOpen] = React.useState(false);
  const [headerId, setHeaderId] = React.useState("");

  const handleClickOpen = (params) => {
    console.log(params)
    setHeaderId(params);
    getAllotedStudentListByid({ academicId: params.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        setOpen(true);
      }
    });
  };
  const onCancel = () => {
    setOpen(false);
  };
  const dataToSave = (data) => {
    let studentVivaData = {
      externalMarks: data.externalMarks,
      internalMarks: data.internalMarks,
      outOfExternal: data.outOfExternal,
      outOfInternal: data.outOfInternal,
      studentId: data.studentId,
      headerId: headerId.id,
    };

    updateStudentVivaMarks({ studentVivaData }).then((success) => {
      if (success) {
        getAllotedStudentListByid({ academicId: headerId.id }).then(
          (response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            } else {
              setOpen(true);
            }
          }
        );
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  return (
    <>
      {!open && <TeacherStudentAllocation handleClickOpen={handleClickOpen} />}
      {open && (
        <MarkSubMission
          cancelMarkSubmission={onCancel}
          dataToSave={dataToSave}
          headerId={headerId}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  getAllotedStudentListByid,
  getAlert,
  updateStudentVivaMarks,
};
export default connect(mapStateToProps, mapDispatchToProps)(DisableElevation);
