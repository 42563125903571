import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";
const initialState = {
  consultancy: [],
  isFetch: false,
};
let URL = endpoints.consultancy;
const consultancySlice = createSlice({
  name: "consultancy",
  initialState,
  reducers: {
    consultancySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        consultancy: row,
        isFetch: true,
      };
    },
  },
});

export const { consultancySuccess } = consultancySlice.actions;

export default consultancySlice.reducer;

export const getConsultancy = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            consNature: data1.consNature,
            consTitle: data1.consTitle,
            clientOrganisationName: data1.clientOrganisationName,
            consDate: data1.consDate,
            consAgency: data1.consAgency,
            consPeriod: data1.consPeriod,
            consAmount: data1.consAmount,
            consCompleted: data1.consCompleted,
            isActive: data1.isActive === null ? "" : data1.isActive,
            employeeConsultancyDocument: data1.employeeConsultancyDocument.map(
              (folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }
            ),
          };
          row.push(bData);
          return data1;
        });
        dispatch(consultancySuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminConsultancy =
  ({ userRegistration }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/by-user-registration?userRegistrationId=" + userRegistration,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              consNature: data1.consNature,
              consTitle: data1.consTitle,
              clientOrganisationName: data1.clientOrganisationName,
              consDate: data1.consDate,
              consAgency: data1.consAgency,
              consPeriod: data1.consPeriod,
              consAmount: data1.consAmount,
              consCompleted: data1.consCompleted,
              isActive: data1.isActive === null ? "" : data1.isActive,
              employeeConsultancyDocument:
                data1.employeeConsultancyDocument.map((folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(consultancySuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//
export const getConsultancyByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-consultancy-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              consNature: data1.consNature,
              consTitle: data1.consTitle,
              clientOrganisationName: data1.clientOrganisationName,
              consDate: data1.consDate,
              consAgency: data1.consAgency,
              consPeriod: data1.consPeriod,
              consAmount: data1.consAmount,
              consCompleted: data1.consCompleted,
              isActive: data1.isActive === null ? "" : data1.isActive,
              employeeConsultancyDocument:
                data1.employeeConsultancyDocument.map((folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(consultancySuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getConsultancyWithoutFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-consultancy-list-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            consNature: data1.consNature,
            consTitle: data1.consTitle,
            clientOrganisationName: data1.clientOrganisationName,
            consDate: data1.consDate,
            consAgency: data1.consAgency,
            consPeriod: data1.consPeriod,
            consAmount: data1.consAmount,
            consCompleted: data1.consCompleted,
            isActive: data1.isActive === null ? "" : data1.isActive,
            employeeConsultancyDocument: data1.employeeConsultancyDocument.map(
              (folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }
            ),
          };
          row.push(bData);
          return data1;
        });
        dispatch(consultancySuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
