import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },

  MuiTableHeadRootDetail: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
    width: 400,
  },

  MuiTableHeadRootMark: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
    width: 100,
  },
});

const QualificationPreview = ({ DeclarationData, classes }) => {
  console.log(DeclarationData);
  return (
    <>
      {DeclarationData.isQualificationCriteriaRequired == "No" ? (
        <>
          <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12} rowSpacing={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 20 }}
              label="Eligible Student List"
            />
          </Grid>
          <table className={classes.MuiTable}>
            <tr>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Sr. No.
              </td>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Roll No
              </td>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Student Name
              </td>
              {/* <td style={{ color: "#000000" }} className={classes.MuiTableHeadRoot}>
                                &nbsp;&nbsp;Year Detail
                            </td> */}

              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Division
              </td>
            </tr>

            {DeclarationData.internshipEligibleStudent.map((detail, index) => (
              <tr style={{ color: "#5a5a5a" }}>
                <td className={classes.MuiTableHeadRootMark}>
                  &nbsp;&nbsp;{index + 1}
                </td>
                <td className={classes.MuiTableHeadRoot}>
                  &nbsp;&nbsp;{detail.rollNo}
                </td>
                <td className={classes.MuiTableHeadRoot}>
                  &nbsp;&nbsp;{detail.studentName}
                </td>

                {/* <td className={classes.MuiTableHeadRoot}>
                                    &nbsp;&nbsp;{DeclarationData.yearDetails}
                                </td> */}
                <td className={classes.MuiTableHeadRoot}>
                  &nbsp;&nbsp;{detail.division}
                </td>
              </tr>
            ))}
          </table>
        </>
      ) : (
        ""
      )}

      <br></br>

      {/* qualification */}
      {DeclarationData.isQualificationCriteriaRequired == "Yes" ? (
        <>
          <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12} rowSpacing={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 20 }}
              label="Qualification List"
            />
          </Grid>
          <table className={classes.MuiTable}>
            <tr>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Sr. No.
              </td>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Qualification Name
              </td>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Minimum Marks
              </td>
            </tr>

            {DeclarationData.internshipCriteria.map((detail, index) => (
              <tr style={{ color: "#5a5a5a" }}>
                <td className={classes.MuiTableHeadRootMark}>
                  &nbsp;&nbsp;{index + 1}
                </td>
                <td className={classes.MuiTableHeadRoot}>
                  &nbsp;&nbsp;{detail.qualificationName}
                </td>
                <td className={classes.MuiTableHeadRoot}>
                  &nbsp;&nbsp;{detail.minimumMarks}
                </td>
              </tr>
            ))}
          </table>
          <br />
          <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12} rowSpacing={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 20 }}
              label="Eligible Student List"
            />
          </Grid>
          <table className={classes.MuiTable}>
            <tr>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Sr. No.
              </td>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Roll No
              </td>
              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Student Name
              </td>

              {/* <td style={{ color: "#000000" }} className={classes.MuiTableHeadRoot}>
                                &nbsp;&nbsp;Year Detail
                            </td> */}

              <td
                style={{ color: "#000000" }}
                className={classes.MuiTableHeadRoot}
              >
                &nbsp;&nbsp;Division
              </td>
            </tr>

            {DeclarationData.internshipEligibleStudent.map((detail, index) => (
              <tr>
                <td
                  style={{ color: "#5a5a5a" }}
                  className={classes.MuiTableHeadRootMark}
                >
                  &nbsp;&nbsp;{index + 1}
                </td>
                <td
                  style={{ color: "#5a5a5a" }}
                  className={classes.MuiTableHeadRoot}
                >
                  &nbsp;&nbsp;{detail.rollNo}
                </td>
                <td
                  style={{ color: "#5a5a5a" }}
                  className={classes.MuiTableHeadRoot}
                >
                  &nbsp;&nbsp;{detail.studentName}
                </td>

                {/* <td style={{ color: "#5a5a5a" }}  className={classes.MuiTableHeadRoot}>
                                    &nbsp;&nbsp;{DeclarationData.yearDetails}
                                </td> */}

                <td
                  style={{ color: "#5a5a5a" }}
                  className={classes.MuiTableHeadRoot}
                >
                  &nbsp;&nbsp;{detail.division}
                </td>
              </tr>
            ))}
          </table>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QualificationPreview));
