import BlockIcon from "@mui/icons-material/Block";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ShareIcon from "@mui/icons-material/Share";
import { Avatar, Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { Loading1 } from "../../components/Loading1";
import endpoint from "../../config/endpoints";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import news from "../../Images/news.png";
import user1 from "../../Images/serverFailure.jpg";
import studyImg from "../../Images/studyImage.png";
import user from "../../Images/user.jpeg";
import { apiGet } from "../../utils/api_service";
import { getEmpProfile } from "../EmployeeDetails/employee.slice";
import EmpPreview from "../EmpPreview/index";
import { getAdminAlumniDashboardInfo } from "./AlumniDashboard.slice";
import endpoints from "../../config/endpoints";
const newsroomData = [
  {
    id: "1",
    title: "Amazon",
    date: "18th Oct 2022",
    image: news,
    description:
      "The Paragraphs module in Drupal provides editors with a component driven architecture for building pages. Morpht has been developing Paragraph approaches to site building since 2015.",
  },
  {
    id: "2",
    title: "Amazon",
    date: "18th Oct 2022",
    image: news,
    description:
      "The Paragraphs module in Drupal provides editors with a component driven architecture for building pages. Morpht has been developing Paragraph approaches to site building since 2015.",
  },
];

class AlumniAdminDashboard extends Component {
  state = {
    showPreview: false,
    role: "",
    semister: "",
    yearDetailId: "",
    showFeedback: false,
    showEvent: false,
    showFee: false,
    dob: "",
    curDOB: "",
    name: "",
    birthday: false,
    showLoader: false,
    showFailure: false,
    barChartFlag: false,
    studentData: [],
    subjectAttendance: [],
    galleryList: [],
    profile: "",
    nameFirstLetter: "",
  };

  readMore = () => {
    // window.location.replace( "/alumini-newsroom" );
    window.location.replace("/admin-view-table");
  };

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const person = localStorage.getItem("person");
    this.setState({
      nameFirstLetter: person !== null ? person.toUpperCase() : "",
    });
    this.props.getAdminAlumniDashboardInfo().then(({ response, success }) => {
      if (!success) {
        this.setState({
          showLoader: false,
          showFailure: true,
        });
      } else {
        console.log(response.profilePic);
        if (response.profilePic !== null || response.profilePic !== "") {
          console.log("profilePic" + response.profilePic);
          apiGet({
            url: endpoints.fileDownload + "/" + response.profilePic,
          }).then(({ data, success }) => {
            if (success) {
              let fileRes = data.data;
              this.setState({ profile: fileRes });
            }

            return success;
          });
        } else {
          this.setState({
            profile: "",
          });
        }
        this.setState({
          showLoader: false,
          showPreview: false,
          showFailure: false,
        });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.alumniDashboardData.adminAlumniDashboardInfo !==
      nextProps.alumniDashboardData.adminAlumniDashboardInfo
    ) {
      this.setState({
        galleryList: [],
      });
      nextProps.alumniDashboardData.adminAlumniDashboardInfo.latestNews.map(
        (folderData) => {
          if (folderData.image !== null) {
            apiGet({
              url: endpoint.fileDownload + "/" + folderData.image,
            }).then(({ data, success }) => {
              if (success) {
                folderData = {
                  ...folderData,
                  profile: data.data,
                };
                this.setState({
                  galleryList: [...this.state.galleryList, folderData],
                });
              } else {
                folderData = {
                  ...folderData,
                };
                this.setState({
                  galleryList: [...this.state.galleryList, folderData],
                });
              }
            });
          }
        }
      );
    }
  }
  viewProfile = () => {
    this.props.getEmpProfile().then((resp) => {
      if (!resp) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      } else {
        // window.location.replace("/empPreview");
        this.setState({
          showPreview: true,
        });
      }
    });
    // window.location.replace("/empPreview");
  };

  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };

  approvedRequests = () => {
    window.location.replace("/admin-side-alimini");
  };

  blockedRequests = () => {
    window.location.replace("/admin-side-alimini");
  };

  aluminiMentor = () => {
    window.location.replace("/admin-mentor-status");
  };

  aluminiVolunteer = () => {
    window.location.replace("/admin-mentor-status");
  };

  pendingRequests = () => {
    window.location.replace("/admin-side-alimini");
  };

  shareOpportunity = () => {
    window.location.replace("/admin-view-alumni-internship");
  };

  aluminiEvents = () => {
    window.location.replace("/alumini-events");
  };

  render() {
    const { alumniDashboardData } = this.props;
    const { galleryList, showLoader, showPreview, showFailure } = this.state;
    console.log(alumniDashboardData.adminAlumniDashboardInfo.profilePic);
    return (
      <>
        {!showPreview && !showLoader && !showFailure && (
          <div id="layout-wrapper">
            {/* ========== App Menu ========== */}

            {/* Left Sidebar End */}
            {/* Vertical Overlay*/}
            <div className="vertical-overlay" />
            {/* ============================================================== */}
            {/* Start right Content here */}
            {/* ============================================================== */}
            <div className="main-content">
              <div className="page-content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card">
                        <div className="bg-primary bg-soft">
                          <div className="row">
                            <div className="col-7">
                              <div className="text-primary p-3 ">
                                <h5 className="text-primary mb-1">
                                  Welcome Back !
                                </h5>
                                <p> </p>
                              </div>
                            </div>
                            <div className="col-5 align-self-end">
                              <img
                                src={studyImg}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="profile-user-wid mb-2">
                                {/*avatar-md*/}
                                {this.state.profile !== "" ? (
                                  <Avatar
                                    sx={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                    }}
                                    src={this.state.profile}
                                  ></Avatar>
                                ) : (
                                  <Avatar
                                    sx={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                      bgcolor: "red",
                                      fontSize: 100,
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    {this.state.nameFirstLetter.charAt(0)}
                                  </Avatar>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div class="a mb-3">
                                <h5 class="font-size-15 text-truncate mb-1">
                                  {Object.keys(
                                    alumniDashboardData.adminAlumniDashboardInfo
                                  ).length !== 0 &&
                                    alumniDashboardData.adminAlumniDashboardInfo
                                      .employeeEmail}
                                </h5>
                                <p class="text-muted mb-0 text-truncate">
                                  Email Address{" "}
                                </p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {Object.keys(
                                    alumniDashboardData.adminAlumniDashboardInfo
                                  ).length !== 0 &&
                                    alumniDashboardData.adminAlumniDashboardInfo
                                      .dateOfBirth}
                                </h5>
                                <p class="text-muted">Date of birth</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {Object.keys(
                                    alumniDashboardData.adminAlumniDashboardInfo
                                  ).length !== 0 &&
                                    alumniDashboardData.adminAlumniDashboardInfo
                                      .employeeJoiningDate}
                                </h5>
                                <p class="text-muted">Joining date</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {Object.keys(
                                    alumniDashboardData.adminAlumniDashboardInfo
                                  ).length !== 0 &&
                                    alumniDashboardData.adminAlumniDashboardInfo
                                      .department}
                                </h5>
                                <p class="text-muted">Department</p>
                              </div>
                              {/* {this.state.role == "alumni" && ( */}
                              <div
                                className="mt-4"
                                style={{ textAlign: "right" }}
                              >
                                <button
                                  onClick={this.viewProfile}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light btn-sm"
                                >
                                  View Profile
                                  <i className="mdi mdi-arrow-right ms-1" />
                                </button>
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Pending requests
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    {/* $ */}
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {Object.keys(
                                        alumniDashboardData.adminAlumniDashboardInfo
                                      ).length !== 0 &&
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo
                                          .pendingAlumniRegistrations.length}
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    You have
                                    {Object.keys(
                                      alumniDashboardData.adminAlumniDashboardInfo
                                    ).length !== 0 &&
                                      " " +
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo
                                          .pendingAlumniRegistrations.length +
                                        " "}
                                    alumnae requests pending
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-success rounded fs-3">
                                    {/* <i className="bx fa fa-user-circle-o text-success" /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={PendingActionsIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.pendingRequests}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Approved alumnae
                              </p>

                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {Object.keys(
                                        alumniDashboardData.adminAlumniDashboardInfo
                                      ).length !== 0 &&
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo
                                          .approvedAlumniRegistrations.length}
                                    </span>
                                  </h4>

                                  <small className="d-block mb-0 mn-ht">
                                    You have
                                    {Object.keys(
                                      alumniDashboardData.adminAlumniDashboardInfo
                                    ).length !== 0 &&
                                      " " +
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo
                                          .approvedAlumniRegistrations.length +
                                        " "}
                                    approved alumnae
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    {/* <i
                                    className="fa fa-trophy text-warning"
                                    aria-hidden="true"
                                  /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={EmojiEventsIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.approvedRequests}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Blocked alumnae{" "}
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {Object.keys(
                                        alumniDashboardData.adminAlumniDashboardInfo
                                      ).length !== 0 &&
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo
                                          .rejectedAlumniRegistrations.length}
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    You have
                                    {Object.keys(
                                      alumniDashboardData.adminAlumniDashboardInfo
                                    ).length !== 0 &&
                                      " " +
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo
                                          .rejectedAlumniRegistrations.length +
                                        " "}
                                    blocked alumnae
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    {/* <i
                                    className="fa fa-percent text-primary"
                                    aria-hidden="true"
                                    style={{ fontSize: 18 }}
                                  /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={BlockIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.blockedRequests}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Mentorship
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {Object.keys(
                                        alumniDashboardData.adminAlumniDashboardInfo
                                      ).length !== 0 &&
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo.mentors
                                          .length}
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total
                                    {Object.keys(
                                      alumniDashboardData.adminAlumniDashboardInfo
                                    ).length !== 0 &&
                                      " " +
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo.mentors
                                          .length +
                                        " "}
                                    mentor you have approved
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    {/* <i
                                    className="fa fa-calendar text-warning"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={CalendarMonthOutlinedIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.aluminiMentor}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Volunteer
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {Object.keys(
                                        alumniDashboardData.adminAlumniDashboardInfo
                                      ).length !== 0 &&
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo.volunteers
                                          .length}
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total
                                    {Object.keys(
                                      alumniDashboardData.adminAlumniDashboardInfo
                                    ).length !== 0 &&
                                      " " +
                                        alumniDashboardData
                                          .adminAlumniDashboardInfo.volunteers
                                          .length +
                                        " "}
                                    volunteer you have approved
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    {/* <i
                                    className="fa fa-comments-o text-primary"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={EmojiPeopleIcon}
                                      color="primary"
                                      fontSize="medium"
                                      onClick={this.aluminiVolunteer}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Alumnae Opportunities
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    ></span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    See how many alumnae have posted internship
                                    opportunities
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-success rounded fs-3">
                                    <i
                                    className="fa fa-file-text text-success"
                                    aria-hidden="true"
                                  />

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={ShareIcon}
                                      color="primary"
                                      fontSize="medium"
                                      onClick={this.shareOpportunity}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {galleryList.length !== 0 && (
                      <div className="col-lg-12">
                        <div
                          className="card"
                          style={{
                            maxHeight: "435px",
                          }}
                        >
                          <div className="card-header align-items-center d-flex">
                            <h4
                              onClick={this.readMore}
                              className="card-title mb-0 flex-grow-1"
                              style={{ cursor: "pointer" }}
                            >
                              Latest News
                            </h4>
                          </div>
                          <div
                            style={{
                              overflow: "scroll",
                              scrollBehavior: "smooth",
                            }}
                          >
                            <div className="card-body">
                              {galleryList.map((data) => {
                                return (
                                  <div
                                    className="news_detail"
                                    style={{ overflow: "hidden" }}
                                  >
                                    <div className="det_news_pic pic">
                                      <img src={data.profile}></img>
                                    </div>
                                    <div className="admission_cont detil">
                                      <h6 className="event_sub_nm">
                                        {data.title}
                                      </h6>

                                      <h6 class="news_time latest">
                                        <span class="blue">Posted on </span>
                                        {data.date}
                                      </h6>
                                    </div>
                                    <p className="admission_txt">
                                      {data.description}
                                    </p>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      container
                                      justifyContent="flex-end"
                                    >
                                      <Grid item xs={12} sm={12} md={12}>
                                        <ButtonCompo
                                          size="medium"
                                          type="button"
                                          variant="contained"
                                          name="View All"
                                          onClick={this.readMore}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div
                      className={
                        galleryList.length !== 0 ? "col-lg-6" : "col-lg-12"
                      }
                    >
                      <div
                        className="card"
                        style={{
                          maxHeight: "435px",
                        }}
                      >
                        <div className="card-header align-items-center d-flex">
                          <h4
                            onClick={this.aluminiEvents}
                            className="card-title mb-0 flex-grow-1"
                            style={{ cursor: "pointer" }}
                          >
                            Gallery
                          </h4>
                        </div>
                        <div
                          style={{
                            overflow: "scroll",
                            scrollBehavior: "smooth",
                          }}
                        >
                          <div
                            className="card-body"
                          >
                            {newsroomData.map((data) => {
                              return (
                                <div
                                  className="news_detail"
                                  style={{ overflow: "hidden" }}
                                >
                                  <div className="det_news_pic pic">
                                    <img
                                      src={
                                        data.image
                                      }
                                    ></img>
                                  </div>
                                  <div className="admission_cont detil">
                                    <h6 className="event_sub_nm">
                                      {data.title}
                                    </h6>

                                    <h6 class="news_time latest">
                                      <span class="blue">Posted on </span>
                                      {data.date}
                                    </h6>
                                  </div>
                                  <p className="admission_txt">
                                    {data.description}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showPreview && (
          <EmpPreview open={true} GoBack={this.GoBack} />
        )}
        {/* {this.state.showEvent && (
          <EventPopUp
            rows={[]}
            columns={this.eventColumns}
            open={this.state.showEvent}
            handleCloses={this.handleCloses}
          />
        )} */}

        {this.state.showLoader && (
          <div>
            <Paper sx={{ p: 40, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <span className="counter-value" data-target="559.25">
                  Please wait...!!!
                </span>
              </h4>
              <Loading1 size={25} />
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}

        {this.state.showFailure && (
          <div>
            <Paper sx={{ p: 11, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <Avatar
                  sx={{
                    width: 350,
                    height: 350,
                    mt: -10,
                    ml: 30,
                  }}
                  // onClick={handleClick}
                  id="basic-button"
                  // aria-controls={open ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                  src={user1}
                ></Avatar>
                <span
                  style={{
                    fontSize: "75px",
                    textAlign: "center",
                    letterSpacing: "1px",
                    marginLeft: "300px",
                  }}
                >
                  Oops !
                </span>
                <br />
                <span
                  style={{
                    fontSize: "35px",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                    marginLeft: "210px",
                  }}
                >
                  Something went wrong.
                </span>
              </h4>
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  student: state.studentList,
  yearList: state.applicantAdmission,
  rollNoData: state.rollNo,
  alumniDashboardData: state.alumniDashboard,
});

const mapDispatchToProps = {
  getEmpProfile,
  getAlert,
  getAdminAlumniDashboardInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlumniAdminDashboard);
