import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  programTitle: [],
  isFetchProgramTitle: false,

  progTitle: [],
  isFetchProgTitle: false,
};
let URL = endpoints.programTitle;
let programTitle = endpoints.progTitle;
const programTitleSlice = createSlice({
  name: "programTitle",
  initialState,
  reducers: {
    programTitleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        programTitle: row,
        isFetchProgramTitle: true,
      };
    },

    progTitleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        progTitle: row,
        isFetchProgTitle: true,
      };
    },
  },
});

export const { programTitleSuccess, progTitleSuccess } =
  programTitleSlice.actions;

export default programTitleSlice.reducer;

export const getProgramTitle = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.brName,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(programTitleSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getProgramTitleNew = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: programTitle,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.programType.name + " - " + data1.brName,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(progTitleSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
