import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../components/Comman/NewLandingScreenHeader";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../../config/endpoints";
import { StudentFeedbackJson } from "../../../DynamicFormsJson/StudentFeedback";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import StudentFeedbackDeclare from "../Student Feedback/StudentFeedbackDeclare";
import { resetReducList } from "../../../CommonActions/User.action";
import * as myConstClass from "../../../config/messageconstant";
import { downloadF } from "../../PDF/PDFDownload";
import SearchExternalPlacedStudList from "../../../components/SearchTable";
import {
  getStudentFeedbackDetails,
  getStudentFeedbackDetailsByID,
  resetValues,
} from "../Student Feedback/feedback.slice";
import {
  getStudentFeedbackTheyApplied,
  getFeedbackWithoutDate,
} from "../Student Feedback Portal/studentFeedbackPortal.slice";
import { default as endpoints } from "../../../config/endpoints";
import AppliedFeedback from "../Student Feedback/AppliedStudentFeedback";
import StudentFeedBackDetails from "../Student Feedback/StudentFeedBackDetails";
import * as msgConstant from "../../../config/messageconstant";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { studFeedbackDynamicJson } from "../../../DynamicFormsJson/StuFeedbackDynamic";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "feedbackHead",
    title: "Feedback name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "17%",
    canSearch: true,
  },
  {
    name: "yearDetailName",
    title: "Year Detail",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "17%",
    canSearch: true,
  },
  {
    name: "division",
    title: "Division",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "5%",
    canSearch: true,
  },

  {
    name: "fromDate",
    title: "From Date",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
    width: "13%",
    canSearch: true,
  },
  {
    name: "toDate",
    title: "To Date",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
    width: "13%",
    canSearch: true,
  },
  {
    name: "isSubjectData",
    title: "Is Subject",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "5%",
  },
  {
    name: "isActive",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "5%",
  },
];
const condition = [
  {
    title: "isSubject",
    value: 0,
    iconHide: "showLinkIcon",
  },
];
class ViewDeclaredStudentFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showAddCommitteeForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      showLoader: false,
      isLoading: false,
      department: "",
      academicYearId: "",
      fromDate: "",
      toDate: "",
      departmentList: [],
      editData: {},
      committeeData: {},
      eventDataForAppliedStudents: {},
      formErrors: [],
      showApplied: false,
      appliedData: {},
      showStudentFeedForm: false,
      searchValue: "",
    };
  }

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onSearchData = (dataToSearch) => {
    this.setState({
      fromDate: dataToSearch.fromDate,
      toDate: dataToSearch.toDate,
      showLoader: true,
    });
    const { getEventByAcademicId, getAlert, getAllEmployees } = this.props;

    this.props
      .getStudentFeedbackTheyApplied({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onChangeStatus = (rowData) => {
    const { fromDate, toDate } = this.state;
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        let newData = {
          id: rowData.id,
          feedbackHead: { id: rowData.feedbackHeadId },
          feedbackDetails: rowData.feedbackDetails,
          divisionIds: rowData.divisionIds,
          fromDate: rowData.fromDate,
          toDate: rowData.toDate,
          isSubject: rowData.isSubject,
          deptAuthorityToView: rowData.deptAuthorityToView,
          empAuthorityToView: rowData.empAuthorityToView,
          type: 1,
          semisterDetail: { id: rowData.semisterDetail },
          isActive: activeStatus,
        };

        apiPost({
          url: endpoint.studentFeedback,
          postBody: newData,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
            let frmDt = moment(fromDate).format("YYYY-MM-DD");
            let toDt = moment(toDate).format("YYYY-MM-DD");
            this.props
              .getStudentFeedbackTheyApplied({
                fromDate: frmDt,
                toDate: toDt,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  toggleFormTableVisibility = () => {
    const { showForm, showTable, showAddCommitteeForm } = this.state;
    this.setState({
      showForm: true,
      showApplied: false,
      showAddCommitteeForm: false,
      showTable: false,
      openSnackbar: true,
      editData: {},
    });
  };

  backToForm = () => {
    this.setState({
      showForm: false,
      showAddCommitteeForm: false,
      showTable: true,
      showAppliedStudentsForm: false,
      openSnackbar: true,
      showApplied: false,
      fromDate: "",
      toDate: "",
    });
    this.props.resetReducList();
  };

  backTo = () => {
    this.setState({
      showTable: true,
    });
  };

  onFormCancel = () => {
    this.setState({
      showForm: true,
      showAddCommitteeForm: false,
      showTable: false,
      showApplied: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.departments.department,
          { id: "0", name: "All" },
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
    this.props.resetValues();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowEdit = (data) => {
    this.setState({
      showAddCommitteeForm: false,
      showTable: false,
      showForm: true,
      editData: data,
      showApplied: false,
      fromDate: "",
      toDate: "",
    });
    this.props.resetValues();
  };

  onClickApplied = (data) => {
    const { getStudentFeedbackDetailsByID } = this.props;
    getStudentFeedbackDetailsByID({ id: data.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      showAddCommitteeForm: false,
      showTable: false,
      showForm: false,
      appliedData: data,
      showApplied: true,
    });
    this.props.resetValues();
  };

  componentDidMount() {
    this.props.resetReducList();
    this.props.resetValues();
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.setState({
      showLoader: true,
    });
    this.props.getFeedbackWithoutDate().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  generateDetails = (rowData) => {
    apiGet({
      url:
        endpoint.studentFeedback +
        "/" +
        rowData.id +
        "/generate-student-feedback-link",
    }).then(({ data, success }) => {
      if (success) {
        swal(
          "URL generated successfully",
          "http://" +
            window.location.host +
            endpoint.outsideStudentLink +
            "?token=" +
            data.data.message,
          "success"
        );
      }
    });
  };

  // API Remaining to consume
  pdfDetailsId = async (rowData) => {
    let userAppReportURL = endpoints.certificateReport;
    downloadF({
      url:
        userAppReportURL +
        "/" +
        rowData.userRegistrationId +
        "/get-student-certificate?type=" +
        rowData.type,
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.studentFeedback + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { fromDate, toDate } = this.state;
        let frmDt = moment(fromDate).format("YYYY-MM-DD");
        let toDt = moment(toDate).format("YYYY-MM-DD");
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props
          .getStudentFeedbackTheyApplied({
            fromDate: frmDt,
            toDate: toDt,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studentFeedbackDetailsList?.studentFeedbackDetails.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["feedbackHead"] &&
            currentRow["feedbackHead"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["fromDate"] &&
            currentRow["fromDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["toDate"] &&
            currentRow["toDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["division"] &&
            currentRow["division"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["yearDetailName"] &&
            currentRow["yearDetailName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["isSubjectData"] &&
            currentRow["isSubjectData"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      showTable,
      showForm,

      fromDate,
      showApplied,
      toDate,
      showStudentFeedForm,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        {/* <LandingScreenHeader
                    tableColumnsToFilter={tableColumnsToFilter}
                    onRTSelectMultipleChange={this.onRTSelectMultipleChange}
                    screenTitle={NoticeJson.screenTitle}
                    showPdfDownload={EmployeeListJson.showPdfDownload}
                    generatePDF={this.generatePDF}
                    showExcelDownload={showExcelDownload}
                    generateExcel={this.generateExcel}
                    showAddButton={showAddButton}
                    onAddButtonClick={this.onAddButtonClick}
                    onSearch={this.onSearch}
                    showTable={showTable}
                /> */}
        {/* <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <NewLandingScreenHeader
            screenTitle={StudentFeedbackJson.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            onAddButtonClick={this.onAddButtonClick}
            showTable={showTable}
          /> */}
        {showForm && (
          <>
            <StudentFeedbackDeclare
              onCancel={this.backToForm}
              editData={this.state.editData}
            />
          </>
        )}

        {showApplied && (
          <>
            <AppliedFeedback
              applieddata={this.state.appliedData}
              onCancel={this.backToForm}
            />
          </>
        )}

        {showTable && (
          <>
            {/* <Grid
                container
                sx={{ marginTop: 2 }}
                justifyContent="space-evenly"
                direction="row"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={5.5}
                    lg={5.5}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange( "fromDate" )}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={5.5}
                    lg={5.5}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.onDateChange( "toDate" )}
                        isError={toDateError ? true : false}
                        errorText={toDateError ? toDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{ marginTop: 1, marginBottom: 2 }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <SearchExternalPlacedStudList onSearch={this.onSearch} />
              </Grid> */}
            {/* <Grid item> */}
            {/* <DynamicTable
                  data={this.getFilteredTableData()}
                  tableHead={columns}
                  showHeadDelete={true}
                  showHeadEdit={true}
                  showPegination={false}
                  rowDelete={this.rowDelete}
                  rowEdit={this.OnClickEdit}
                  onCancel={this.backToForm}
                  condition={condition}
                  showAppliedList={true}
                  rowAppliedList={this.onClickApplied}
                  showLinkIcon={true}
                  generateDetails={this.generateDetails}
                  rowStatus={this.onChangeStatus}
                /> */}

            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={studFeedbackDynamicJson.screenTitle}
              fieldMeta={studFeedbackDynamicJson.fieldMeta}
              buttonCenter={studFeedbackDynamicJson.buttonCenter}
              showPdfDownload={studFeedbackDynamicJson.showPdfDownload}
              showExcelDownload={studFeedbackDynamicJson.showExcelDownload}
              tableColumnsToFilter={
                studFeedbackDynamicJson.tableColumnsToFilter
              }
              showAddButton={studFeedbackDynamicJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={columns}
              isActionColActive={false}
              tableData={
                this.props.studentFeedbackDetailsList?.studentFeedbackDetails
              }
              showPegination={true}
              showHeadEdit={false}
              showHeadDelete={false}
              showAppliedList={false}
              pdfDetailsId={this.pdfDetailsId}
              showHeadPdf={true}
              showLoader={this.state.showLoader}
              showLinkIcon={false}
              generateDetails={this.generateDetails}
              rowDelete={this.rowDelete}
              rowEdit={this.rowEdit}
              rowStatus={this.onChangeStatus}
              rowAppliedList={this.onClickApplied}
              onSearchData={this.onSearchData}
              baseIdColumn={studFeedbackDynamicJson.baseIdColumn}
              apiBaseURL={studFeedbackDynamicJson.apiBaseURL}
            />
            {/* </Grid> */}
          </>
        )}
        {showStudentFeedForm && <StudentFeedBackDetails />}
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentFeedbackDetailsList: state.studentFeedbackDetails,
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
  noticeList: state.empNotice,
  studentFeedback: state.studentFeedback,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getDepartment,
  getAcademicYear,
  getStudentFeedbackTheyApplied,
  getStudentFeedbackDetails,
  getStudentFeedbackDetailsByID,
  resetReducList,
  resetValues,
  getFeedbackWithoutDate,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDeclaredStudentFeedback);
