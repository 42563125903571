import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import {
  getUnitTestDetailsById,
  resetSuccessById,
} from "../UnitTestMaster/unitTestMaster.slice";
import { getAlert } from "../../../CommonActions/alert.slice";
class UnitTestView extends React.Component {
  state = {
    id: 0,
    unitTest: "",
    subjectName: "",
    declareBy: "",
    formErrors: [],
    date: "",
    toDate: "",
    remark: "",
    studentList: [],
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.timeTableHeaderData !== nextProps.timeTableHeaderData) {
      this.setState({
        id: nextProps.timeTableHeaderData.id,
        unitTest: nextProps.timeTableHeaderData.unitTestHead,
        subjectName: nextProps.timeTableHeaderData.subjectName,
        declareBy: nextProps.timeTableHeaderData.declareBy,
        date: nextProps.timeTableHeaderData.date,
      });
    }
  }
  componentDidMount() {
    const {
      timeTableHeaderData,
      getUnitTestDetailsById,
      getAlert,
      unitTestList,
    } = this.props;
    getUnitTestDetailsById({ id: timeTableHeaderData.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    console.log(timeTableHeaderData);
    if (
      Object.keys(unitTestList?.unitTestById).length !== 0 &&
      unitTestList?.unitTestById.studentUnitTestDetails
    ) {
      this.setState({
        id: unitTestList?.unitTestById.id,
        unitTest: unitTestList?.unitTestById.unitTestHead.name,
        subjectName: unitTestList?.unitTestById.subjectName,
        declareBy: unitTestList?.unitTestById.declareBy,
        date: unitTestList?.unitTestById.date,
        studentList: unitTestList?.unitTestById.studentUnitTestDetails.map(
          (rowData, studListIndex) => {
            let studData = {
              index: studListIndex + 1,
              fillMarkBy: rowData.fillMarkBy,
              id: rowData.id,
              reportingDetail:
                rowData.reportingDetail === null
                  ? ""
                  : rowData.reportingDetail.id,
              rollNo:
                rowData.reportingDetail === null
                  ? ""
                  : rowData.reportingDetail.rollNo,
              division:
                rowData.reportingDetail === null
                  ? ""
                  : rowData.reportingDetail.division.id,
              admissionRegistrationDetail:
                rowData.reportingDetail === null
                  ? ""
                  : rowData.reportingDetail.getAdmissionRegistrationDetail,
              name:
                rowData.userRegistration === null
                  ? ""
                  : rowData.userRegistration.firstName +
                    " " +
                    rowData.userRegistration.middleName +
                    " " +
                    rowData.userRegistration.lastName,
              userRegistrationId:
                rowData.userRegistration === null
                  ? ""
                  : rowData.userRegistration.id,
              marks: rowData.marks,
            };
            return studData;
          }
        ),
      });
    }
  }

  onCancleHandler = () => {
    this.props.resetSuccessById();
    this.props.onCancle();
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };

  render() {
    const { declareBy, subjectName, unitTest, studentList, date } = this.state;
    const { unitTestList } = this.props;
    console.log(this.props.unitTestList?.unitTestById);
    console.log(unitTest);
    console.log(date);
    console.log(declareBy);
    console.log(studentList);
    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                {" "}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  m={2}
                  rowSpacing={1}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <Grid item xs={12} sm={10.7} md={10.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label={"View Student Unit Test Details"}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1.3}
                    lg={1.3}
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back"
                      fullWidth={true}
                      onClick={() => {
                        this.onCancleHandler();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Unit Test : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        Object.keys(unitTestList?.unitTestById).length !== 0 &&
                        unitTestList?.unitTestById.unitTestHead.name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Subject : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        Object.keys(unitTestList?.unitTestById).length !== 0 &&
                        unitTestList?.unitTestById.getSubject.subject.name
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Declare By : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        Object.keys(unitTestList?.unitTestById).length !== 0 &&
                        unitTestList?.unitTestById.declareBy.firstName +
                          " " +
                          unitTestList?.unitTestById.declareBy.middleName +
                          " " +
                          unitTestList?.unitTestById.declareBy.lastName
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Date : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        Object.keys(unitTestList?.unitTestById).length !== 0 &&
                        unitTestList?.unitTestById.date
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={2} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Maximum Marks : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} md={10}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        Object.keys(unitTestList?.unitTestById).length !== 0 &&
                        unitTestList?.unitTestById.maxMarks
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mr: 1, mt: 1 }} xs={12} sm={12} md={12}>
                <DynamicTable
                  data={
                    Object.keys(unitTestList?.unitTestById).length !== 0
                      ? unitTestList?.unitTestById.studentList
                      : []
                  }
                  // data={this.props.timeTableHeaderData.studentList}
                  tableHead={this.props.columns}
                  showPegination={false}
                  rowEdit={this.editStudentInfo}
                  showHeadEdit={false}
                  isActionColActive={false}
                />
              </Grid>
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ unitTestList: state.unitTestStore });
const mapDispatchToProps = {
  getUnitTestDetailsById,
  getAlert,
  resetSuccessById,
};
export default connect(mapStateToProps, mapDispatchToProps)(UnitTestView);
