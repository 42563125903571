import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { AlumniReportJson } from "../../DynamicFormsJson/AlumniReport";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { resetReducList } from "../../CommonActions/User.action";
import { getAlumini } from "../Alumini/AdminSideAlimini/AluminiStatus/alumini.slice";

class AlumniReport extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        statusMaster: [
          {
            id: "0",
            name: "Pending",
          },
          {
            id: "1",
            name: "Approved",
          },
          {
            id: "2",
            name: "Rejected",
          },
        ],
        engagementMaster: [
          { id: "0", name: "Volunteer" },
          { id: "1", name: "Mentor" },
          { id: "2", name: "Alumnae Association Office Bearer" },
          { id: "3", name: "Donor and Sponsor" },
          { id: "4", name: "Paper Setter/ Evaluator/Examiner/Guide" },
          { id: "5", name: "Resource Person" },
          { id: "7", name: "Collaborator" },
          { id: "8", name: "Teacher" },
        ],
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.alumini !== nextProps.alumini) {
      if (this.props.alumini.alumini !== nextProps.alumini.alumini) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            alumniMaster: nextProps.alumini.alumini,
          },
        });
      }
    }
  }
  componentDidMount() {
    const { resetReducList, getAlumini, getAlert } = this.props;
    resetReducList();
    getAlumini().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  rowData = [
    {
      index: 1,
      reportName: "List of Alumna by Engagement Type (D3, D4)",
      filter: "engagementType,status",
      url: "/api/report/v1/volunteer-mentor-report?engagementType={engagementType}&status={status}&documentType=1",
      excelUrl:
        "/api/exportexcel/v1/volunteer-mentor-report?engagementType={engagementType}&status={status}",
    },
    {
      index: 2,
      reportName: "List of Engagement Alumna wise (D2, D3, D4)",
      filter: "engagementType,status,alumniId",
      excelUrl:
        "/api/exportexcel/v1/volunteer-mentor-report/by-id?id={alumniId}&engagementType={engagementType}&status={status}",
      url: "/api/report/v1/volunteer-mentor-report/by-id?id={alumniId}&engagementType={engagementType}&status={status}&documentType=1",
    },
    {
      index: 3,
      reportName: "List of Internship Shared Alumna wise (D0,D1,D2)",
      filter: "fromDate,toDate,alumniId",
      excelUrl:
        "/api/exportexcel/v1/get-post-an-internship-report/by-id?id={alumniId}&fromDate={fromDate}&toDate={toDate}",
      url: "/api/report/v1/get-post-an-internship-report/by-id?id={alumniId}&fromDate={fromDate}&toDate={toDate}&documentType=1",
    },
    {
      index: 4,
      reportName: "List of Internship Shared (D0,D1)",
      filter: "fromDate,toDate",
      excelUrl:
        "/api/exportexcel/v1/get-post-an-internship-report?fromDate={fromDate}&toDate={toDate}",
      url: "/api/report/v1/get-post-an-internship-report?documentType=1&fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 5,
      reportName: "List Of Completed Alumnae Activities (D0, D1)",
      filter: "fromDate,toDate",
      excelUrl:
        "/api/exportexcel/v1/alumni-engagement-details-report?fromDate={fromDate}&toDate={toDate}",
      url: "/api/report/v1/alumni-engagement-details-report?fromDate={fromDate}&toDate={toDate}&documentType=1",
    },
    {
      index: 6,
      reportName:
        "List Of Completed Alumnae Activities Engagement Type Wise (D0, D1, D3)",
      filter: "fromDate,toDate,engagementType",
      excelUrl:
        "/api/exportexcel/v1/alumni-engagement-details-report/by-type?fromDate={fromDate}&toDate={toDate}&engagementType={engagementType}",
      url: "/api/report/v1/alumni-engagement-details-report/by-type?fromDate={fromDate}&toDate={toDate}&documentType=1&engagementType={engagementType}",
    },
  ];
  getListById = (data) => {};
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={AlumniReportJson.screenTitle}
          fieldMeta={AlumniReportJson.fieldMeta}
          buttonCenter={AlumniReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={AlumniReportJson.searchButton}
          PDFButton={AlumniReportJson.PDFButton}
          EXCELButton={AlumniReportJson.EXCELButton}
          baseIdColumn={AlumniReportJson.baseIdColumn}
          apiBaseURL={AlumniReportJson.apiBaseURL}
          marginTop={AlumniReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  alumini: state.alumini,
});
const mapDispatchToProps = {
  getAlert,
  getAlumini,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlumniReport);
