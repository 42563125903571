import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MessageIcon from "@mui/icons-material/Message";
import endpoints from "../../config/endpoints";
import PercentIcon from "@mui/icons-material/Percent";
import { Alert, Avatar, Grid, Paper, Stack, Switch } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { onToggle } from "../../CommonActions/common.slice";
// import BarChart from "../../components/Charts/BarChart";
import AutoComplete from "../../components/Comman/AutoComplete";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from "recharts";
import user1 from "../../Images/serverFailure.jpg";
import news from "../../Images/news.png";
import { Loading1 } from "../../components/Loading1";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import ShareIcon from "@mui/icons-material/Share";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GroupsIcon from "@mui/icons-material/Groups";
import FeedIcon from "@mui/icons-material/Feed";
import certificatePic from "../../Images/certificate-pic.jpg";
import certificate from "../../Images/certificate.png";
import studyImg from "../../Images/studyImage.png";
import trophy from "../../Images/trophy.png";
import user from "../../Images/user.jpeg";
import serverFailure from "../../Images/serverFailure.jpg";
import { getYears } from "../ApplicantAdmission/applicantAdmission.slice";
import SchoolIcon from "@mui/icons-material/School";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  getProfile,
  getStudentInfoByPRNNo,
} from "../BasicDetailsPost/basicDetails.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import StudentPreview1 from "../StudentPreviewById/index";
import EventPopUp from "./PopUps/EventPopUp";
import FeedbackPopup from "./PopUps/FeedbackPopup";
import FeesPopup from "./PopUps/FeesPopup";
import { getAlumniDashboardInfo } from "./AlumniDashboard.slice";
import { apiGet } from "../../utils/api_service";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";

class ParentDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showPreview: false,
    showDashBoard: false,
    role: "",
    dob: "",
    curDOB: "",
    name: "",
    birthday: false,
    showLoader: false,
    showFailure: false,
    galleryList: [],
    profile: "",
  };

  readMore = () => {
    window.location.replace("/alumini-newsroom");
  };

  componentDidMount() {
    let role = localStorage.getItem("role");

    let profilePic = localStorage.getItem("profilePic");
    if (profilePic && profilePic !== null) {
      console.log("profilePic" + profilePic);
      apiGet({
        url: endpoints.fileDownload + "/" + profilePic,
      }).then(({ data, success }) => {
        if (success) {
          let fileRes = data.data;
          this.setState({ profile: fileRes });
        }

        return success;
      });
    }

    this.setState({
      role: localStorage.getItem("role"),
    });
    const curDOB = new Date();
    let fd = curDOB === "" ? "" : moment(curDOB).format("DD-MM-YYYY");

    this.setState({
      showLoader: true,
    });
    if (role == "parent") {
      this.props.getProfile().then((resp) => {
        if (!resp) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showDashBoard: true,
            showLoader: false,
            showPreview: false,
            showFailure: false,
          });
        }
      });
    }
    this.setState({
      showLoader: false,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.alumniDashboardData.alumniDashboardInfo.latestNews !==
      nextProps.alumniDashboardData.alumniDashboardInfo.latestNews
    ) {
      this.setState({
        galleryList: [],
      });
      nextProps.alumniDashboardData.alumniDashboardInfo.latestNews.map(
        (folderData) => {
          console.log(folderData.image);
          if (folderData.image !== null) {
            apiGet({
              url: endpoint.fileDownload + "/" + folderData.image,
            }).then(({ data, success }) => {
              if (success) {
                folderData = {
                  ...folderData,
                  profile: data.data,
                };
                this.setState({
                  galleryList: [...this.state.galleryList, folderData],
                });
              } else {
                folderData = {
                  ...folderData,
                };
                this.setState({
                  galleryList: [...this.state.galleryList, folderData],
                });
              }
            });
          }
        }
      );
    }
  }
  viewProfile = () => {
    let role = localStorage.getItem("role");
    if (role == "parent") {
      window.location.replace("/newDashboard");
    }
  };

  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };

  attendanceChild = () => {
    if (this.state.role == "parent") {
      window.location.replace("/own-attendance");
    }
  };

  childTimeTable = () => {
    if (this.state.role == "parent") {
      window.location.replace("/view-time-table-to-student");
    }
  };

  childNotice = () => {
    if (this.state.role == "parent") {
      window.location.replace("/student-view-notice");
    }
  };

  childInternship = () => {
    if (this.state.role == "parent") {
      window.location.replace("/student-add-internship");
    }
  };

  inviteFriends = () => {
    if (this.state.role == "parent") {
      window.location.replace("/student-exam-apply");
    }
  };

  childPlacement = () => {
    if (this.state.role == "parent") {
      window.location.replace("/student-placement");
    }
  };

  render() {
    const { alumniDashboardData, employeeDetails } = this.props;
    const { galleryList } = this.state;
    console.log(employeeDetails?.applicant);
    return (
      <>
        {/* {this.state.showDashBoard && ( */}
        <div id="layout-wrapper">
          {/* ========== App Menu ========== */}

          {/* Left Sidebar End */}
          {/* Vertical Overlay*/}
          <div className="vertical-overlay" />
          {/* ============================================================== */}
          {/* Start right Content here */}
          {/* ============================================================== */}
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="bg-primary bg-soft">
                        <div className="row">
                          <div className="col-7">
                            <div className="text-primary p-3 ">
                              <h5 className="text-primary mb-1">
                                Welcome Back !
                              </h5>
                              <p> </p>
                            </div>
                          </div>
                          <div className="col-5 align-self-end">
                            <img src={studyImg} alt="" className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="profile-user-wid mb-2">
                              {/* <img
                                src={this.state.profile}
                                alt=""
                                className="img-thumbnail rounded-circle"
                              /> */}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <div class="a mb-3">
                              <h5
                                style={{ marginTop: 15 }}
                                class="font-size-15 text-truncate mb-1"
                              >
                                {employeeDetails?.applicant.firstName +
                                  " " +
                                  employeeDetails?.applicant.middleName +
                                  " " +
                                  employeeDetails?.applicant.lastName}
                              </h5>
                              <p class="text-muted mb-0 text-truncate">
                                Parent{" "}
                              </p>
                            </div>

                            <div class="a mb-3">
                              <h5 class="font-size-15 mb-1">
                                {employeeDetails?.applicant.parentEmail}
                              </h5>
                              <p class="text-muted">Email Address</p>
                            </div>

                            <div class="a mb-3">
                              <h5 class="font-size-15 mb-1">
                                {" "}
                                {employeeDetails?.applicant.parentMobile}
                              </h5>
                              <p class="text-muted">Mobile Number</p>
                            </div>

                            <div
                              className="mt-4"
                              style={{ textAlign: "right" }}
                            >
                              <button
                                onClick={this.viewProfile}
                                type="button"
                                className="btn btn-primary waves-effect waves-light btn-sm"
                              >
                                View Student Dashboard
                                <i className="mdi mdi-arrow-right ms-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="card card-animate">
                          <div className="card-body">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                              Examination
                            </p>
                            <div className="d-flex align-items-end justify-content-between mt-3">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                  {/* $ */}
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  ></span>
                                </h4>
                                <small className="d-block mb-0 mn-ht">
                                  Go to Examination Module Page.
                                </small>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-soft-success rounded fs-3">
                                  {/* <i className="bx fa fa-user-circle-o text-success" /> */}
                                  <MyComponentWithIconProps
                                    title=""
                                    statusImage={FeedIcon}
                                    color="primary"
                                    fontSize="small"
                                    onClick={this.inviteFriends}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-animate">
                          <div className="card-body">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                              Attendance
                            </p>

                            <div className="d-flex align-items-end justify-content-between mt-3">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {/* {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.achievements.length
                                    } */}
                                  </span>
                                </h4>

                                <small className="d-block mb-0 mn-ht">
                                  See your children(s) Attendance.
                                </small>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-soft-warning rounded fs-3">
                                  {/* <i
                                    className="fa fa-trophy text-warning"
                                    aria-hidden="true"
                                  /> */}
                                  <MyComponentWithIconProps
                                    title=""
                                    statusImage={PercentIcon}
                                    color="primary"
                                    fontSize="small"
                                    onClick={this.attendanceChild}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-animate">
                          <div className="card-body">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                              {" "}
                              Time Table{" "}
                            </p>
                            <div className="d-flex align-items-end justify-content-between mt-3">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {/* {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.postAninternship
                                        .length
                                    } */}
                                  </span>
                                </h4>
                                <small className="d-block mb-0 mn-ht">
                                  See your children(s) Time Table.
                                </small>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-soft-primary rounded fs-3">
                                  {/* <i
                                    className="fa fa-percent text-primary"
                                    aria-hidden="true"
                                    style={{ fontSize: 18 }}
                                  /> */}
                                  <MyComponentWithIconProps
                                    title=""
                                    statusImage={CalendarMonthIcon}
                                    color="primary"
                                    fontSize="small"
                                    onClick={this.childTimeTable}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-animate">
                          <div className="card-body">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                              {" "}
                              Notice
                            </p>
                            <div className="d-flex align-items-end justify-content-between mt-3">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {/* {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.mentors.length
                                    } */}
                                  </span>
                                </h4>
                                <small className="d-block mb-0 mn-ht">
                                  See your children(s) has any notice declared
                                  for him / her.
                                </small>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-soft-warning rounded fs-3">
                                  {/* <i
                                    className="fa fa-calendar text-warning"
                                    aria-hidden="true"
                                  /> */}

                                  <MyComponentWithIconProps
                                    title=""
                                    statusImage={NotificationsIcon}
                                    color="primary"
                                    fontSize="small"
                                    onClick={this.childNotice}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-animate">
                          <div className="card-body">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                              {" "}
                              Internship
                            </p>
                            <div className="d-flex align-items-end justify-content-between mt-3">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {/* {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.volunteers.length
                                    } */}
                                  </span>
                                </h4>
                                <small className="d-block mb-0 mn-ht">
                                  See if you children(s) has any internship
                                  opportunity
                                </small>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-soft-primary rounded fs-3">
                                  {/* <i
                                    className="fa fa-comments-o text-primary"
                                    aria-hidden="true"
                                  /> */}

                                  <MyComponentWithIconProps
                                    title=""
                                    statusImage={SchoolIcon}
                                    color="primary"
                                    fontSize="medium"
                                    onClick={this.childInternship}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-animate">
                          <div className="card-body">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                              Placement
                            </p>
                            <div className="d-flex align-items-end justify-content-between mt-3">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {/* {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.membersConnected
                                        .length
                                    } */}
                                  </span>
                                </h4>
                                <small className="d-block mb-0 mn-ht">
                                  See if you children(s) has any placement
                                  opportunity
                                </small>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-soft-success rounded fs-3">
                                  {/* <i
                                    className="fa fa-file-text text-success"
                                    aria-hidden="true"
                                  /> */}

                                  <MyComponentWithIconProps
                                    title=""
                                    statusImage={GroupsIcon}
                                    color="primary"
                                    fontSize="medium"
                                    onClick={this.childPlacement}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        {this.state.showLoader && (
          <div>
            <Paper sx={{ p: 40, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <span className="counter-value" data-target="559.25">
                  Please wait...!!!
                </span>
              </h4>
              <Loading1 size={25} />
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}

        {this.state.showFailure && (
          <div>
            <Paper sx={{ p: 11, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <Avatar
                  sx={{
                    width: 350,
                    height: 350,
                    mt: -10,
                    ml: 30,
                  }}
                  // onClick={handleClick}
                  id="basic-button"
                  // aria-controls={open ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                  src={user1}
                ></Avatar>
                <span
                  style={{
                    fontSize: "75px",
                    textAlign: "center",
                    letterSpacing: "1px",
                    marginLeft: "300px",
                  }}
                >
                  Oops !
                </span>
                <br />
                <span
                  style={{
                    fontSize: "35px",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                    marginLeft: "210px",
                  }}
                >
                  Something went wrong.
                </span>
              </h4>
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  student: state.studentList,
  yearList: state.applicantAdmission,
  rollNoData: state.rollNo,
  alumniDashboardData: state.alumniDashboard,
  employeeDetails: state.studentList,
});

const mapDispatchToProps = {
  onToggle,
  getProfile,
  getAlert,
  // getStudentInfoByPRNNo,
  getYears,
  // getSemesterDetailsByYearAndAcademic,
  getAlumniDashboardInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentDashboard);
