import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { ChipCompo, MyComponentWithIconProps } from "../../../../components/Comman/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
export default function QualificationTable({
  data,
  rowEdit,
  rowDelete,
  columns,
  rows,
  onChangeStatus,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1975d2",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const changeStatus = (e,row) => {
    e.stopPropagation();
   onChangeStatus(row);
  };
  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
              <StyledTableCell align="center" colSpan={2}>
                Subject
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.name}
                  align={"left"}
                  //   style={{ minWidth: column.minWidth }}
                >
                  {column.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.name];
                      if (column.name === "action") {
                        const onClickEdit = (e) => {
                          e.stopPropagation();
                          rowEdit(row);
                        };
                        const onClickDelete = (e) => {
                          e.stopPropagation();
                          rowDelete(row);
                        };

                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            <MyComponentWithIconProps
                              statusImage={EditIcon}
                              color="primary"
                              fontSize="medium"
                              title="Edit"
                              onClick={onClickEdit}
                            />
                            {
                              row.shortName.toLowerCase()!=='comp'&&
                            <>
                            &nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={DeleteIcon}
                              color="error"
                              fontSize="medium"
                              title="Delete"
                              onClick={onClickDelete}
                            />
                            </>
                      }
                          </StyledTableCell>
                        );
                      }
                      if (column.name === "isActive") {
                      

                        return (
                          <StyledTableCell key={column.id} align={'center'}>
                          <ChipCompo
                            label={
                              row.isActive === 1 ? "Active" : "Inactive"
                            }
                            variant="outlined"
                            size="small"
                            color={row.isActive === 1 ? "success" : "error"}
                            onClick={(e) => changeStatus(e, row)}
                          />
                              </StyledTableCell>
                        );
                      } else {
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </>
  );
}
