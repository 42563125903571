// import
// {
//     Checkbox,
//     FormControl,
//     FormControlLabel,
//     FormGroup,
//     Grid,
//     Radio,
//     RadioGroup,
//     TextField,
//     checkValidation
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import { ButtonCompo } from "../../components/Comman/Button";
// import { withStyles } from "@mui/styles";

// const StyledDataGrid = withStyles( {
//     root: {
//         "& .MuiDataGrid-renderingZone": {
//             maxHeight: "none !important"
//         },
//         "& .MuiDataGrid-cell": {
//             lineHeight: "unset !important",
//             maxHeight: "none !important",
//             whiteSpace: "normal"
//         },
//         "& .MuiDataGrid-row": {
//             maxHeight: "none !important"
//         }
//     }
// } )( DataGrid );
// const DynamicTable = ( {
//     employeeFeedbackDetailsList,
//     rowData,
//     checkValidation
// } ) =>
// {
//     const [ values, setValues ] = useState(
//         employeeFeedbackDetailsList?.outsideStudentsFeedbackQuestions
//     );
//     useEffect( () =>
//     {
//         if ( values.length === 0 )
//         {
//             setValues( employeeFeedbackDetailsList?.outsideStudentsFeedbackQuestions );
//         }
//     } );

//     let validation = () =>
//     {
//         let listOfQuestion = [];
//         const val = values.map( ( data1 ) =>
//         {
//             if ( data1.answer !== "" )
//             {

//                 listOfQuestion.push( data1 );
//             } else
//             {
//                 setValues( ( data ) =>
//                     data?.map( ( list, index ) =>
//                         list.id === data1.id ? { ...list, answerValid: 1 } : list
//                     )
//                 );
//             }
//         } );
//         if ( values.length === listOfQuestion.length )
//         {
//             rowData( values );
//         }
//     };

//     let columns = [
//         { field: "index", headerName: "Sr. No.", minWidth: 100, sortable: false },
//         { field: "question", headerName: "Question", minWidth: 700 },
//         {
//             field: "answer",
//             headerName: "Answer",
//             sortable: false,
//             minWidth: 1000,
//             renderCell: ( params ) =>
//             {
//                 const changeHandler = ( id, e ) =>
//                 {
//                     const { value } = e.target;
//                     setValues( ( data ) =>
//                         data?.map( ( list, index ) =>
//                             list.id === id ? { ...list, answer: value, answerValid: 0 } : list
//                         )
//                     );
//                 };
//                 const handleChange = ( id, e ) =>
//                 {
//                     const { checked } = e.target;

//                     const newData = values.map( ( questionValue ) =>
//                     {
//                         if ( questionValue.id === id )
//                         {
//                             if ( checked )
//                             {
//                                 questionValue = {
//                                     ...questionValue,
//                                     answer:
//                                         questionValue.answer === ""
//                                             ? e.target.name
//                                             : questionValue.answer + "," + e.target.name,
//                                     answerValid: 0,
//                                 };
//                                 return questionValue;
//                             } else
//                             {
//                                 let answer = questionValue.answer.split( "," );
//                                 let multiSelect = answer.filter(
//                                     ( item ) => item !== e.target.name
//                                 );
//                                 questionValue = {
//                                     ...questionValue,
//                                     answer: multiSelect.join(),
//                                     answerValid: 0,
//                                 };
//                                 return questionValue;
//                             }
//                         }

//                         return questionValue;
//                     } );
//                     setValues( newData );
//                 };
//                 if ( params.row.optionType === "1" )
//                 {
//                     return (
//                         <div style={{ marginTop: 2 }}>
//                             <>
//                                 <TextField
//                                     color="primary"
//                                     name={"Percentage" + params.id}
//                                     size="small"
//                                     value={params.row.answer}
//                                     onChange={( e ) => changeHandler( params.row.id, e )}
//                                     sx={{ width: 500, marginTop: 3 }}
//                                     error={params.row.answerValid !== 0 ? true : false}
//                                     helperText={
//                                         params.row.answerValid !== 0 ? "Feedback answer is required" : " "
//                                     }
//                                 />
//                             </>
//                         </div>
//                     );
//                 } else if ( params.row.optionType === "0" )
//                 {
//                     return (
//                         <div>
//                             <FormControl component="fieldset">
//                                 <RadioGroup
//                                     row
//                                     aria-label="amountApplicable"
//                                     id="amountApplicable"
//                                     name="amountApplicable"
//                                     value={params.row.answer}
//                                     onChange={( e ) => changeHandler( params.row.id, e )}
//                                     size="small"
//                                     sx={{ marginTop: 3 }}
//                                 >
//                                     {params.row.feedbackHeadDetailsOptions.map(
//                                         ( optionData, index ) =>
//                                         {
//                                             return (
//                                                 <FormControlLabel
//                                                     value={optionData.optionForSelection}
//                                                     control={<Radio />}
//                                                     label={optionData.optionForSelection}
//                                                 />
//                                             );
//                                         }
//                                     )}
//                                 </RadioGroup>
//
//                             </FormControl>
//                         </div>
//                     );
//                 }
//                 else
//                 {
//                     return (
//                         <div>
//                             <FormControl component="fieldset" variant="standard" sx={{ marginTop: 2 }}>
//                                 <FormGroup aria-label="position" row>
//                                     {params.row.feedbackHeadDetailsOptions.map(
//                                         ( optionData, index ) =>
//                                         {
//                                             return (
//                                                 <FormControlLabel
//                                                     control={
//                                                         <Checkbox

//                                                             onChange={( e ) => handleChange( params.row.id, e )}
//                                                             name={optionData.optionForSelection}
//                                                         />
//                                                     }
//                                                     label={optionData.optionForSelection}
//                                                 />
//                                             );
//                                         }
//                                     )}
//                                 </FormGroup>
//
//                             </FormControl>
//                         </div>
//                     );
//                 }
//             },
//         },
//     ];

//     useEffect( () =>
//     {
//     }, [] );

//     return (
//         <div align="center">
//             <StyledDataGrid
//                 autoHeight={true}
//                 rows={values}
//                 columns={columns}
//                 disableColumnMenu
//                 pageSize={10}
//                 rowsPerPageOptions={[ 10 ]}
//             />
//             <br />
//             <Grid container justifyContent="right" item xs={12} sm={12} md={12}>
//                 <ButtonCompo
//                     size="medium"
//                     type="button"
//                     variant="contained"
//                     name="Submit"
//                     onClick={validation}
//                 />
//             </Grid>
//         </div>
//     );
// };

// const mapStateToProps = ( state ) => ( {
//     employeeFeedbackDetailsList: state.outSideEmployee,
// } );
// const mapDispatchToProps = {
// };
// export default connect( mapStateToProps, mapDispatchToProps )( DynamicTable );

import React, { useEffect, useState } from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

const DynamicTable = ({ employeeFeedbackDetailsList, rowData }) => {
  const [values, setValues] = useState(
    employeeFeedbackDetailsList?.outsideStudentsFeedbackQuestions
  );
  useEffect(() => {
    if (values.length === 0) {
      setValues(employeeFeedbackDetailsList?.outsideStudentsFeedbackQuestions);
    }
  });
  const changeHandler = (id, e) => {
    const { value } = e.target;
    setValues((data) =>
      data?.map((list, index) =>
        list.id === id ? { ...list, answer: value, answerValid: 0 } : list
      )
    );
  };

  const validation = () => {
    let listOfQuestion = [];
    const val = values.map((data1) => {
      if (data1.answer.toString().trim() !== "") {
        listOfQuestion.push(data1);
      } else {
        setValues((data) =>
          data?.map((list, index) =>
            list.id === data1.id ? { ...list, answerValid: 1 } : list
          )
        );
      }
    });
    if (values.length === listOfQuestion.length) {
      rowData(values);
    }
  };
  const handleChange = (id, e) => {
    const { checked } = e.target;

    const newData = values.map((questionValue) => {
      if (questionValue.id === id) {
        if (checked) {
          questionValue = {
            ...questionValue,
            answer:
              questionValue.answer === ""
                ? e.target.name
                : questionValue.answer + "," + e.target.name,
            answerValid: 0,
          };
          return questionValue;
        } else {
          let answer = questionValue.answer.split(",");
          let multiSelect = answer.filter((item) => item !== e.target.name);
          questionValue = {
            ...questionValue,
            answer: multiSelect.join(),
            answerValid: 0,
          };
          return questionValue;
        }
      }

      return questionValue;
    });
    setValues(newData);
  };
  useEffect(() => {}, []);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" width="10%">
                Sr. No.
              </TableCell>
              <TableCell align="center" width="40%">
                Question
              </TableCell>
              <TableCell align="center">Answer</TableCell>
              {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="left">{row.question}</TableCell>

                <TableCell align="left">
                  {row.optionType === "1" ? (
                    <>
                      <TextField
                        color="primary"
                        name={"Percentage" + row.id}
                        size="small"
                        value={row.answer}
                        onChange={(e) => changeHandler(row.id, e)}
                        sx={{ width: 500 }}
                        error={row.answerValid !== 0 ? true : false}
                        helperText={
                          row.answerValid !== 0 ? "answer is required" : " "
                        }
                      />
                    </>
                  ) : row.optionType === "0" ? (
                    <div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="amountApplicable"
                          id="amountApplicable"
                          name="amountApplicable"
                          value={row.answer}
                          onChange={(e) => changeHandler(row.id, e)}
                          size="small"
                        >
                          {row.feedbackHeadDetailsOptions.map(
                            (optionData, index) => {
                              return (
                                <FormControlLabel
                                  value={optionData.optionForSelection}
                                  control={<Radio />}
                                  label={optionData.optionForSelection}
                                />
                              );
                            }
                          )}
                        </RadioGroup>
                        <FormHelperText error>
                          {row.answerValid !== 0 ? "answer is required" : " "}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  ) : (
                    <div>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup aria-label="position" row>
                          {row.feedbackHeadDetailsOptions.map(
                            (optionData, index) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) => handleChange(row.id, e)}
                                      name={optionData.optionForSelection}
                                    />
                                  }
                                  label={optionData.optionForSelection}
                                />
                              );
                            }
                          )}
                        </FormGroup>
                        <FormHelperText error>
                          {row.answerValid !== 0 ? "answer is required" : " "}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Grid container justifyContent="right" item xs={12} sm={12} md={12}>
        <ButtonCompo
          size="medium"
          type="button"
          variant="contained"
          name="Save"
          onClick={validation}
        />
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  employeeFeedbackDetailsList: state.outSideEmployee,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicTable);
