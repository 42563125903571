import { Grid, Link, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import * as myConstClass from "../../../config/messageconstant";
import { getBatchBySemesterList } from "../../Batches/BatchList/batchlist.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getTimeSlotByProgramType } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";

import {
  getSubjectByDepartment,
  getSubjectDetailBySubject,
} from "../../Masters/Admission/Subject Info/subjectInfo.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice"; // import AccountFees from "../AccountFees/index";
import { saveTimeTableHeader } from "./generateTimeTable.slice";
import { getClassCoordinator } from "../../Masters/Internship/Department/department.slice";
import { apiGet } from "../../../utils/api_service";
import endpoints from "../../../config/endpoints";
class GenerateTimeTableForm extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    semester: "",
    division: "",
    formErrors: [],
    fromDate: "",
    toDate: "",
    remark: "",
    semId: "",
    classCoordinator: "",
  };

  componentDidMount() {
    const { getYearDetails, timeTableHeaderData, getClassCoordinator } =
      this.props;
    if (Object.keys(timeTableHeaderData) != 0) {
      const fromDateArr =
        timeTableHeaderData.fromDate == null
          ? ""
          : timeTableHeaderData.fromDate == ""
          ? ""
          : timeTableHeaderData.fromDate.split("-");
      const fromDate =
        fromDateArr == ""
          ? ""
          : new Date(fromDateArr[2], fromDateArr[1] - 1, fromDateArr[0]);

      const toDateArr =
        timeTableHeaderData.toDate == null
          ? ""
          : timeTableHeaderData.toDate == ""
          ? ""
          : timeTableHeaderData.toDate.split("-");
      const toDate =
        toDateArr == ""
          ? ""
          : new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0]);

      this.setState({
        id: timeTableHeaderData.id,
        yearDetail: timeTableHeaderData.yearDetailId,
        semester: timeTableHeaderData.semisterDetail,
        division: timeTableHeaderData.division,
        fromDate: fromDate,
        toDate: toDate,
        remark: timeTableHeaderData.remark,
        classCoordinator: timeTableHeaderData.userRegistration,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: timeTableHeaderData.yearDetailId,
          academicId: "",
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getDistinctDivision({
          yearDetail: this.props.timeTableList.timeTable.division.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    getClassCoordinator().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    if (Object.keys(this.props.timeTableList.timeTable) != 0) {
      const fromDateArr =
        this.props.timeTableList.timeTable.fromDate == null
          ? ""
          : this.props.timeTableList.timeTable.fromDate == ""
          ? ""
          : this.props.timeTableList.timeTable.fromDate.split("-");
      const fromDate =
        fromDateArr == ""
          ? ""
          : new Date(fromDateArr[2], fromDateArr[1] - 1, fromDateArr[0]);

      const toDateArr =
        this.props.timeTableList.timeTable.toDate == null
          ? ""
          : this.props.timeTableList.timeTable.toDate == ""
          ? ""
          : this.props.timeTableList.timeTable.toDate.split("-");
      const toDate =
        toDateArr == ""
          ? ""
          : new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0]);
      this.setState({
        id: this.props.timeTableList.timeTable.id,
        yearDetail: this.props.timeTableList.timeTable.division.yearDetailId,
        semester: this.props.timeTableList.timeTable.semisterDetail.id,
        division: this.props.timeTableList.timeTable.division.id,
        fromDate: fromDate,
        toDate: toDate,
        remark: this.props.timeTableList.timeTable.remark,
        classCoordinator:
          this.props.timeTableList.timeTable.userRegistration.id,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: this.props.timeTableList.timeTable.division.yearDetailId,
          academicId: "",
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getDistinctDivision({
          yearDetail: this.props.timeTableList.timeTable.division.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    let semesterId = this.props.rollNoData?.semisterDetailsList.map((data) => {
      return data.semisterId;
    });
    this.setState({ semId: semesterId });
  }
  componentWillReceiveProps = (nextProps) => {
    if (this.props.timeTableHeaderData !== undefined) {
      if (this.props.timeTableHeaderData !== nextProps.timeTableHeaderData) {
        const fromDateArr =
          nextProps.timeTableHeaderData.fromDate == null
            ? ""
            : nextProps.timeTableHeaderData.fromDate == ""
            ? ""
            : nextProps.timeTableHeaderData.fromDate.split("-");
        const fromDate =
          fromDateArr == ""
            ? ""
            : new Date(fromDateArr[2], fromDateArr[1] - 1, fromDateArr[0]);

        const toDateArr =
          nextProps.timeTableHeaderData.toDate == null
            ? ""
            : nextProps.timeTableHeaderData.toDate == ""
            ? ""
            : nextProps.timeTableHeaderData.toDate.split("-");
        const toDate =
          toDateArr == ""
            ? ""
            : new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0]);
        this.setState({
          id: nextProps.timeTableHeaderData.id,
          yearDetail: nextProps.timeTableHeaderData.yearDetailId,
          semester: nextProps.timeTableHeaderData.semisterDetail,
          division: nextProps.timeTableHeaderData.division,
          fromDate: fromDate,
          toDate: toDate,
          remark: nextProps.timeTableHeaderData.remark,
          classCoordinator: nextProps.timeTableHeaderData.userRegistration,
        });
        this.props
          .getSemesterDetailsByYearAndAcademic({
            yearId: nextProps.timeTableHeaderData.yearDetailId,
            academicId: "",
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props
          .getDistinctDivision({
            yearDetail: nextProps.timeTableHeaderData.yearDetailId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    }
    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList.timeTable !== nextProps.timeTableList.timeTable
      ) {
        const fromDateArr =
          nextProps.timeTableList.timeTable.fromDate == null
            ? ""
            : nextProps.timeTableList.timeTable.fromDate == ""
            ? ""
            : nextProps.timeTableList.timeTable.fromDate.split("-");
        const fromDate =
          fromDateArr == ""
            ? ""
            : new Date(fromDateArr[2], fromDateArr[1] - 1, fromDateArr[0]);

        const toDateArr =
          nextProps.timeTableList.timeTable.toDate == null
            ? ""
            : nextProps.timeTableList.timeTable.toDate == ""
            ? ""
            : nextProps.timeTableList.timeTable.toDate.split("-");
        const toDate =
          toDateArr == ""
            ? ""
            : new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0]);
        this.setState({
          id: nextProps.timeTableList.timeTable.id,
          yearDetail: nextProps.timeTableList.timeTable.division.yearDetailId,
          semester: nextProps.timeTableList.timeTable.semisterDetail.id,
          division: nextProps.timeTableList.timeTable.division.id,
          fromDate: fromDate,
          toDate: toDate,
          remark: nextProps.timeTableList.timeTable.remark,
          classCoordinator:
            nextProps.timeTableList.timeTable.userRegistration.id,
        });
      }
    }
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name == "yearDetail") {
        this.setState({
          division: "",
          semester: "",
        });
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        getSemesterDetailsByYearAndAcademic({
          yearId: newValue,
          academicId: "",
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const {
      division,
      semester,
      fromDate,
      toDate,
      yearDetail,
      classCoordinator,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (classCoordinator === "" || classCoordinator === null) {
      formIsValid = false;
      formErrors["classCoordinatorError"] = myConstClass.classCoordinatorMsg;
    }

    if (fromDate === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateErr"] = myConstClass.fromDateMsg;
    }
    if (toDate === "" || fromDate === null) {
      formIsValid = false;
      formErrors["toDateErr"] = myConstClass.toDateMsg;
    }
    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateErr"] = myConstClass.displaytoMsg;
    }

    if (division === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    if (yearDetail === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (semester === null || semester.toString().trim() === "") {
      formIsValid = false;
      formErrors["semesterError"] = myConstClass.semisterNameMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  //   searchData = () => {
  //     const { fromDate, toDate, internType } = this.state;
  //     const { getCompanyList } = this.props;
  //     let fromDt = moment(fromDate).format("DD-MM-YYYY");
  //     let toDt = moment(toDate).format("DD-MM-YYYY");
  //     console.log(fromDt + " fromDate");
  //     console.log(toDt + " toDate");
  //     console.log(internType + " internType");
  //     if (this.handleFormValidation()) {
  //       // getCompanyList({ fromDate: fromDate, toDate: toDate, internType: internType });
  //     }
  //   };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };
  submitHandler = () => {
    const {
      division,
      semester,
      fromDate,
      toDate,
      remark,
      id,
      classCoordinator,
    } = this.state;

    let timeTableHeader = {
      semisterDetail: {
        id: semester,
      },
      division: {
        id: division,
      },
      fromDate: fromDate === "" ? "" : moment(fromDate).format("DD-MM-YYYY"),
      toDate: toDate === "" ? "" : moment(toDate).format("DD-MM-YYYY"),
      remark: remark,
      isActive: 1,
      userRegistration: {
        id: classCoordinator,
      },
    };
    if (id != 0) {
      timeTableHeader = {
        ...timeTableHeader,
        id: id,
      };
    }
    apiGet({
      url: endpoints.yearDetailId + "/" + this.state.yearDetail,
    }).then(({ data, success }) => {
      if (success) {
        this.props
          .getTimeSlotByProgramType({
            programTypeId: data.data.year.programType.id,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: "Something went wrong",
              });
            }
          });
        console.log(data);
      } else {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });
    if (this.handleFormValidation()) {
      this.props
        .saveTimeTableHeader({ dataToSave: timeTableHeader })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.props.onSave();
          }
        });
    }

    // apiPost({
    //   url: endpoints.employeeWorkload,
    //   postBody: workloadAllocate,
    // }).then(({ data, success }) => {
    //   if (!success) {
    //     this.props.getAlert({ message: "Something went wrong" });
    //   } else {
    //     this.props.goToList();
    //   }
    // });
  };

  render() {
    const {
      division,
      semester,
      yearDetail,
      remark,
      fromDate,
      toDate,
      classCoordinator,
    } = this.state;
    const {
      yearDetailError,
      divisionError,
      semesterError,
      fromDateErr,
      toDateErr,
      classCoordinatorError,
    } = this.state.formErrors;
    const { internshipDeclarationList, rollNoData } = this.props;

    console.log(rollNoData?.semisterDetailsList);

    return (
      <>
        {/* <Paper sx={{ p: 0, borderRadius: 0 }} elevation={0}> */}
        <>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              columnSpacing={3}
              xs={12}
              sm={6}
              md={6}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Year details"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={yearDetail}
                  name={"yearDetail"}
                  options={internshipDeclarationList?.yearDetails}
                  onChange={this.ChangeHandlerSearch}
                  isError={yearDetailError ? true : false}
                  errorText={yearDetailError ? yearDetailError : " "}
                  disabled={this.state.id != 0 ? true : false}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={6}
              md={6}
              columnSpacing={3}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Semester Details"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={semester}
                  name={"semester"}
                  options={rollNoData?.semisterDetailsList.filter(
                    (data) => data.isActive == 1
                  )}
                  onChange={this.ChangeHandlerSearch}
                  isError={semesterError ? true : false}
                  errorText={semesterError ? semesterError : " "}
                  disabled={this.state.id != 0 ? true : false}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={6}
              md={6}
              columnSpacing={3}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Division"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={division}
                  name={"division"}
                  options={internshipDeclarationList?.distinctDivision}
                  onChange={this.ChangeHandlerSearch}
                  isError={divisionError ? true : false}
                  errorText={divisionError ? divisionError : " "}
                  disabled={this.state.id != 0 ? true : false}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={6}
              md={6}
              columnSpacing={3}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*From Date"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <BasicDatePicker
                  color="primary"
                  name="dob"
                  size="small"
                  value={fromDate}
                  fullWidth
                  onChange={this.onDateChange("fromDate")}
                  isError={fromDateErr ? true : false}
                  errorText={fromDateErr ? fromDateErr : " "}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={6}
              md={6}
              columnSpacing={3}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*To Date"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <BasicDatePicker
                  color="primary"
                  name="dob"
                  size="small"
                  value={toDate}
                  fullWidth
                  onChange={this.onDateChange("toDate")}
                  isError={toDateErr ? true : false}
                  errorText={toDateErr ? toDateErr : " "}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={6}
              md={6}
              columnSpacing={3}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Remark"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  size="small"
                  color="primary"
                  name="remark"
                  id="remark"
                  value={remark}
                  onChange={this.changeHandler}
                  fullWidth
                  helperText=" "
                ></TextField>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={6}
              md={6}
              columnSpacing={3}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Class Co-ordinator"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={classCoordinator}
                  name={"classCoordinator"}
                  options={this.props.classCoordinatorList?.classCoordinator}
                  onChange={this.ChangeHandlerSearch}
                  isError={classCoordinatorError ? true : false}
                  errorText={
                    classCoordinatorError ? classCoordinatorError : " "
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={6}
              md={6}
              columnSpacing={3}
              sx={{ marginTop: 2 }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="right"
              m={1}
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Save"
                onClick={this.submitHandler}
              />
              &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back"
                onClick={this.props.onCancle}
              />
            </Grid>
          </Grid>
        </>
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  studentRollNoList: state.rollNoList,
  semisterList: state.semister,
  rollNoData: state.rollNo,
  departmentList: state.department,
  employeeDetails: state.employeeList,
  batchList: state.batch,
  timeTableList: state.timeTable,
  subjectList: state.subjectInfo,
  classCoordinatorList: state.department,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getBatchBySemesterList,
  getSubjectByDepartment,
  getSubjectDetailBySubject,
  saveTimeTableHeader,
  getTimeSlotByProgramType,
  getClassCoordinator,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateTimeTableForm);
