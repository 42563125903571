export const certificateStudentJson = {
    screenTitle: "List of Certificate",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter:false,
    getAcadamicYear:false,
  
  };
  