const endpoint = {
  division: "/api/acdmc/v1/division",
  scholarship: "/api/acdmc/v1/scholarship-type",
  feeType: "/api/acdmc/v1/fees-type",
  auth: "/api/auth/login",
  religion: "/api/application/v1/religion",
  category: "/api/application/v1/category",
  university: "/api/application/v1/university",
  document: "/api/application/v1/document",
  academicYear:
    "/api/application/v1/academic-year/get-academic-year-list-by-institute",
  currentAcademic: "/api/application/v1/academic-year",
  institute: "/api/config/v1/tenant",
  class: "/api/application/v1/year",
  yearDetail: "/api/acdmc/v1/get-year-detail",
  yearDetailId: "/api/application/v1/year-detail",
  course: "/api/application/v1/program-title",
  program: "/api/application/v1/program",
  programType: "/api/application/v1/program-type",
  getProgramType: "/api/acdmc/v1/get-program-type",
  ClassIntakeDetail: "/api/application/v1/class-intake-detail",
  qualification: "/api/application/v1/qualification",
  instituteCourse: "/api/application/v1/institute-program-title",
  subjectInfo: "/api/acdmc/v1/subject",
  applicationCap: "/api/application/v1/application-declare-application-round",
  fileDelete: "/api/document/v1/file/deleteFile",
  fileAllDelete: "/api/document/v1/file/delete-files",
  application: "/api/application/v1/application-declare",
  catSeatIntakeDetail: "/api/application/v1/category-seat-intake-header",
  fromFees: "/api/acdmc/v1/from-fees",
  receiptType: "/api/acdmc/v1/receipt-type",
  financial: "/api/acdmc/v1/finance-year",
  pattern: "/api/acdmc/v1/pattern",
  scheme: "/api/acdmc/v1/scheme",
  schemeHead: "/api/acdmc/v1/scheme-head",
  admissionStatus: "/api/acdmc/v1/admission-status",
  subjectTypeDetails: "/api/acdmc/v1/subject-type",
  feesTitle: "/api/acdmc/v1/fees-title",
  feesHeadDetail: "/api/acdmc/v1/fees-head",
  examFeesStructure: "/api/acdmc/v1/exam-fees-structure",
  documentVerification: "/api/application/v1/application-declare",
  documentVerificationList: "/api/application/v1/user-round-allocation",
  documentVerificationUpdate: "/api/application/v1/student-reporting-process",
  semisterDetails: "/api/acdmc/v1/semister-detail",
  semister: "/api/acdmc/v1/semister",
  admissionType: "/api/acdmc/v1/admission-type",
  programTitle: "/api/application/v1/program-title",
  feesHead: "/api/acdmc/v1/fees-head",
  feesSturctureHead: "/api/acdmc/v1/fees-structure-header",
  admissionForm:
    "/api/config/prelogin/get-user-registration-qualification-document-detail",
  subjectSelection:
    "/api/config/prelogin/get-semister-and-subject-by-year-detail",
  feesCollection: "/api/config/prelogin/get-fees-structure-header-and-detail",
  admissionList: "/api/admission/v1/admission-registration",
  studentList: "/api/acdmc/v1/user-registration",
  receiptList: "/api/admission/v1/transaction-header",
  bloodGroup: "/api/application/v1/blood-group",
  annualIncome: "/api/application/v1/annual-income",
  state: "/api/application/v1/state",
  district: "/api/application/v1/district",
  taluka: "/api/application/v1/taluka",
  fileUpload: "/api/document/v1/file/upload",
  videoDownload: "/api/document/v1/file/download",
  fileUploadPreLogin: "/api/document/v1/file/uploadPublicFile",
  fileDownload: "/api/document/v1/file/getFileUrl",
  saveStudent: "/api/acdmc/v1/user-registration",
  academicYearPrelogin: "/api/config/prelogin/get-academic-year",
  saveAdmissionForm:
    "/api/config/prelogin/set-user-registration-admission-registration-subject-fees-structure",
  yearList:
    "/api/application/v1/year-detail/get-year-detail-by-institute-and-program",
  saveUserRegistration:
    "/api/acdmc/v1/save-user-registration-and-admission-registration",
  department: "/api/acdmc/v1/department",
  designation: "/api/acdmc/v1/designation",
  company: "/api/placement/v1/company",
  internshipType: "/api/placement/v1/internship-type",
  saveEmployee: "/api/acdmc/v1/employee-registration",
  userDocumentDetail: "/api/acdmc/v1/user-document-detail",
  userQualificationDetail: "/api/acdmc/v1/user-qualification-detail",
  employeeDetails: "/api/acdmc/v1/employee-details",
  placementDeclare: "/api/placement/v1/placement-declare",
  internshipStudentMarks: "api/placement/v1/internship-viva-declare-student",
  studentRollNo: "/api/acdmc/v1/reporting-detail",
  internshipDeclare: "/api/placement/v1/internship-declare",
  internshipDocument: "/api/placement/v1/internship-document",
  checkEmail: "/api/config/prelogin/otp/",
  vivaDeclare: "/api/placement/v1/internship-viva-declare",
  internshipIntrest: "/api/placement/v1/internship-intrested-student",
  interestedStudent:
    "/api/placement/v1/internship-intrested-student/get-intrested-student-by-intrested-student-ids",
  allotedStudentList:
    "/api/placement/v1/internship-viva-declare/get-internship-viva-declare-faculty-by-user-id-and-viva-declare-id",
  allocationList:
    "api/placement/v1/internship-viva-declare/get-internship-viva-declare-by-academic-year-and-intern-type",
  userApplication: "/api/application/v1/user-application",
  studentRollList: "/api/acdmc/v1/reporting-detail",
  intrestedAndNotIntrestedStudent:
    "api/placement/v1/internship-intrested-student",
  placementIntrestedAndNotIntrestedStudent:
    "api/placement/v1/placement-intrested-student",
  placementIntrest: "/api/placement/v1/placement-intrested-student",
  batch: "/api/acdmc/v1/batch",
  room: "/api/acdmc/v1/rooms",
  floor: "/api/acdmc/v1/floor",
  timeSlot: "/api/acdmc/v1/time-slot",
  employeeWorkload: "/api/acdmc/v1/employee-work-load",
  activity: "/api/acdmc/v1/employee-activity",
  innovative: "/api/acdmc/v1/employee-innovative-teaching",
  timeTableHeader: "/api/acdmc/v1/time-table-header",
  timeTableDetails: "/api/acdmc/v1/time-table-detail",
  resourcePerson: "/api/acdmc/v1/employee-publication",
  award: "/api/acdmc/v1/employee-award",
  resourceProject: "/api/acdmc/v1/employee-research-project",
  courseAttended: "/api/acdmc/v1/employee-professional-enhancement",
  researchGuide: "/api/acdmc/v1/employee-as-guide",
  seminarWebinar: "/api/acdmc/v1/employee-curricular-activities",
  cocurricular: "/api/acdmc/v1/employee-curricular-activities",
  committeeHandle: "/api/acdmc/v1/employee-committees-handle",
  grievance: "/api/acdmc/v1/grievance",
  consultancy: "/api/acdmc/v1/employee-consultancy",
  extendedActivity: "/api/acdmc/v1/employee-extended-activities",
  extendedActivityDocument:
    "/api/acdmc/v1/employee-extended-activities-document",
  event: "/api/acdmc/v1/event",
  eventCommittee: "/api/acdmc/v1/event-committee",
  eventParticipant: "/api/acdmc/v1/event-participant",
  profileInfo: "/api/acdmc/v1/user-registration/get-profile",
  employeeNotice: "/api/acdmc/v1/employee-notice",
  studentNotice: "/api/acdmc/v1/student-notice",
  employeeFeedback: "/api/acdmc/v1/employee-feedback",
  studentFeedbackHeadDetails: "/api/acdmc/v1/feedback-head",
  feedBack: "/api/acdmc/v1/feedback-head",
  feedBackDetails: "/api/acdmc/v1/feedback-head-details",
  studentFeedbackDetails: "/api/acdmc/v1/student-feedback",
  studentFeedbackTransaction: "/api/acdmc/v1/student-feedback-transaction",
  studentFeedback: "/api/acdmc/v1/student-feedback",
  employeeFeedbackDetails: "/api/acdmc/v1/employee-feedback",
  employeeFeedbackTransaction: "/api/acdmc/v1/employee-feedback-transaction",
  studentFeedBackTransaction: "/api/acdmc/v1/student-feedback-transaction",
  hr: "/api/hr/v1/get-hr-credentials",
  prnNumber: "/api/acdmc/v1/user-registration",
  certificate: "/api/acdmc/v1/student-certificate-application",
  certificateGet: "/api/acdmc/v1/certificate-application-header",
  studentAttendance: "/api/acdmc/v1/student-attendance-header",
  divisionAttendance:
    "/api/acdmc/v1/employee-work-load/get-division-by-semister-detail-user-id",
  batchAttendance:
    "/api/acdmc/v1/employee-work-load/get-batch-by-semister-detail-division-user-id",
  subjectAttendance:
    "/api/acdmc/v1/employee-work-load/get-subject-by-semister-detail-division-batch-user-id",
  subjectByDivisionAttendance:
    "/api/acdmc/v1/employee-work-load/get-subject-by-semister-detail-division",
  studentDataAttendance:
    "/api/acdmc/v1/batch/get-student-list-by-batch-id-by-order",
  studentAttendanceBySubject:
    "/api/acdmc/v1/student-attendance-header/by-employee-work-load-id",
  unitTest: "/api/acdmc/v1/unit-test-head",
  teachingPlan: "/api/acdmc/v1/subject-teaching-plan",
  unitTestDeclare: "/api/acdmc/v1/student-unit-test-header",
  ematerial: "/api/acdmc/v1/ematerial",
  ematerialReview: "/api/acdmc/v1/ematerial-review",
  employeeDesignation: "/api/acdmc/v1/employee-designation",
  outsideEmployeeFeedback: "/api/config/prelogin",
  outsideEmployeeFeedbackSave:
    "/api/config/prelogin/save-employee-feedback-transaction",
  outsideStudentFeedbackSave:
    "/api/config/prelogin/save-student-feedback-transaction",
  roleAccess: "/api/acdmc/v1/access-role-name",
  outSideEmployeeLink: "/outside-feedback-employee",
  outsideStudentLink: "/outside-feedback-student",
  moduleSubModule: "/api/acdmc/v1/module",
  saveAccessRole: "/api/acdmc/v1/access-role-name",
  certList: "/api/acdmc/v1/certificate-application-header",
  linkSendOnEmail: "/api/config/prelogin/forgot-password",
  getEmpHeadPreLogin: "/api/config/prelogin/get-employee-feedback-by-id",
  getEmpDetailPreLogin: "/api/config/prelogin/get-feedback-head-details-by-id",
  getStudHeadPreLogin: "/api/config/prelogin/get-student-feedback-by-id",
  certificateReport: "/api/report/v1/student-certificate-report",
  internshipReport: "/api/report/v1/internship-intrested-not-intrested-report",
  studentTransaction: "/api/admission/v1/transaction-header",
  filterTable: "/api/acdmc/v1/filter-table",
  examFormDeclare: "/api/acdmc/v1/exam-form-declare",
  studSubject: "/api/acdmc/v1/student-subjects",
  studentApply: "/api/acdmc/v1/exam-form-student-apply",
  examSubject: "/api/acdmc/v1/exam-form-schedule",
  examConduction: "/api/acdmc/v1/exam-conduction",
  studentUnitTest: "/api/acdmc/v1/student-unit-test-header",
  teachingSyllabus: "/api/acdmc/v1/get-syllabus",
  syllabusSubsection: "/api/acdmc/v1/syllabus-subsection",
  syllabusSection: "/api/acdmc/v1/syllabus-section",
  syllabusChapter: "/api/acdmc/v1/syllabus-chapter",
  syllabus: "/api/acdmc/v1/syllabus",
  studentUpdateMarks: "/api/acdmc/v1/student-detail-exam-marks",
  subjectTeachingPlanDetail: "/api/acdmc/v1/subject-teaching-plan-detail",
  studentAttandaceSyllabus: "/api/acdmc/v1/student-completed-subsection",
  teacherDashboard: "/api/acdmc/v1/teacher-dashboard",
  studentDashboard: "/api/acdmc/v1/student-dashboard",
  alumniDashboard: "/api/acdmc/v1/alumni-dashboard",
  adminAlumniDashboard: "/api/acdmc/v1/admin-dashboard",
  uniqueEmailId: "/api/config/v1/user/check-unique-email-id",
  deemedCommittee: "/api/acdmc/v1/committee",
  deemedMeeting: "/api/acdmc/v1/schedule-committee-meeting",
  deemedAttendance: "/api/acdmc/v1/meeting-attendance",
  deemedAgenda: "/api/acdmc/v1/meeting-agenda",
  newsRoom: "/api/acdmc/v1/news-room",
  volunteerUpdateStatus: "/api/acdmc/v1/volunteer-mentor",
  adminViewAlumniInternship: "/api/acdmc/v1/post-an-internship",
  generateOTP:
    "/api/config/prelogin/otp/generate-otp-alumni-registration?emailId=",
  generateParentOTP:
    "/api/config/prelogin/otp/generate-otp-parent-registration?mobileNo=",
  studentRegister: "/api/config/prelogin/applicant-user-registration",
  emailEmail: "/api/config/prelogin/otp/",
  validateOtp: "/api/config/prelogin/otp/validate-otp?otp=",
  achievements: "/api/acdmc/v1/achievements",
  meetingDocument: "/api/acdmc/v1/meeting-documents",
  folder: "/api/acdmc/v1/folder",
  alumniRegister: "/api/acdmc/v1/alumni-registration",
  postInternship: "/api/acdmc/v1/post-an-internship",
  preloginProgram: "/api/config/prelogin/online-service/get-program",
  preloginProgramTitle: "/api/config/prelogin/online-service/get-program-title",
  workExperience: "/api/acdmc/v1/work-details",
  educationalDetails: "/api/acdmc/v1/education-details",
  progTitle: "/api/acdmc/v1/get-program-title",
  preloginAlumniRegister: "/api/config/prelogin/alumni-registration",
  minutesOfMeetingPdf: "/api/report/v1/minutes-of-meeting",
  prnStudentPrelogin: "/api/config/prelogin/get-parent-mobile-by-prn",
  checkParentMob: "/api/config/prelogin/check-parent-mobile",
  validateParentUsername: "/api/config/prelogin/parent-registration",
  parentPostLogin: "/api/acdmc/v1/parent-registration",
  yearDetailByDepartmentIds:
    "/api/placement/v1/internship-declare/get-department-wise-distinct-year-detail",
  countryUrl: "/api/application/v1/tbl-countries",
  stateUrl: "/api/application/v1/tbl-states",
  cityUrl: "/api/application/v1/tbl-cities",
  paymentUrl: "api/payment/v1/payment",
  alumniEngDetailsUrl: "/api/acdmc/v1/alumni-engagement-details",
  internshipIntrestedDoc:
    "/api/placement/v1/internsip-intrested-student-document",
  placementIntrestedDoc:
    "/api/placement/v1/placement-intrested-student-document",
  consultancyDoc: "/api/acdmc/v1/employee-consultancy-document",
  empPublicationDoc: "api/acdmc/v1/employee-publication-document",
  empAsGuideDoc: "/api/acdmc/v1/employee-as-guide-document",
  empInnovativeDoc: "/api/acdmc/v1/employee-innovative-teaching-document",
  committeeHandleDoc: "/api/acdmc/v1/employee-committees-handle-document",
  extendedActivityDoc: "/api/acdmc/v1/employee-extended-activities-document",
  promotionalAdmission: "/api/application/v1/next-year-admission-academic-year",
  promotionalAdmissionStudent: "/api/acdmc/v1/next-year-admission",
  instituteConfig: "/api/acdmc/v1/institute-config",
  placementConfig: "/api/placement/v1/placement-config",
  deletePlacementDoc: "/api/placement/v1/placement-declare-document",
  payTransaction:"/api/config/prelogin/payment-transaction"
};

export default endpoint;
