import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { timeSlotJson } from "../../../../DynamicFormsJson/timeSlot";
import { TimeSlotColumns } from "../../../../tableColumns/table-columns";
import {
  getProgramTypeInstituteWise,
  getTimeSlot,
  getTimeSlotByProgramType,
} from "./timeSlot.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
class TimeSlot extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        programType: this.props.timeSlotList?.programTypeInstituteWise,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.timeSlotList !== nextProps.timeSlotList) {
      if (
        this.props.timeSlotList.programTypeInstituteWise !==
        nextProps.timeSlotList.programTypeInstituteWise
      ) {
        let programTypeList = [
          {
            id: "all",
            name: "All",
          },
          ...nextProps.timeSlotList.programTypeInstituteWise,
        ];
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programType: programTypeList,
            programTypeList: nextProps.timeSlotList.programTypeInstituteWise,
          },
        });
      }
    }
  }

  onSearchData = (data) => {
    console.log(data);

    if (data.programType) {
      if (data.programType !== "all") {
        this.props
          .getTimeSlotByProgramType({ programTypeId: data.programType })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props.getTimeSlot().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    }
  };

  componentDidMount() {
    const { getTimeSlot, getProgramTypeInstituteWise } = this.props;
    getTimeSlot().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    getProgramTypeInstituteWise().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  render() {
    const { timeSlotList } = this.props;
    const { dynamicMasterData } = this.state;

    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={timeSlotJson.screenTitle}
            fieldMeta={timeSlotJson.fieldMeta}
            showPdfDownload={timeSlotJson.showPdfDownload}
            showExcelDownload={timeSlotJson.showExcelDownload}
            pdfFileName={timeSlotJson.pdfFileName}
            excelFileName={timeSlotJson.excelFileName}
            showAddButton={timeSlotJson.showAddButton}
            tableColumnsToFilter={timeSlotJson.tableColumnsToFilter}
            baseIdColumn={timeSlotJson.baseIdColumn}
            dynamicMasterData={dynamicMasterData}
            onSearchData={this.onSearchData}
            searchList={timeSlotJson.searchList}
            getTableDataById={true}
            getTableData={this.props.getTimeSlot}
            tableColumns={TimeSlotColumns}
            tableData={timeSlotList?.timeSlot}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={timeSlotList.isFetch}
            apiBaseURL={timeSlotJson.apiBaseURL}
            rowData={this.rowData}
          ></DynamicMainScreen>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  timeSlotList: state.timeSlot,
});
const mapDispatchToProps = {
  getTimeSlot,
  getProgramTypeInstituteWise,
  getAlert,
  getTimeSlotByProgramType,
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeSlot);
