import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { getAllEmployees } from "../../EmployeeDetails/employee.slice";
import { showNotification } from "../../Landing/Landing.slice";
import { getRoomForExamConduct } from "../../Masters/TimeTable/room/room.slice";
import {
  getStudentForExamConduct,
  getSubjectForExamConduct,
  resetData,
} from "./examconduct.slice";
import { ButtonCompo } from "../../../components/Comman/Button";

class ExamConductView extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    flag: false,
    studentList: [],
    examCapacity: 0,
    openPopUp: false,
    room: "",
    subject: [],
    teacher: [],
    scheduleDate: "",
    selection: [],
  };
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "seatNo",
      title: "Seat Number",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "name",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      name: "subjectName",
      title: "Subject Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      name: "headName",
      title: "Head Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "fromTime",
      title: "Start Time",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "toTime",
      title: "End Time",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
  ];
  componentDidMount() {}

  handleFormValidation() {}

  ChangedHandlerSearch = (name, newValue) => {};
  submitHandler = () => {};

  render() {
    const { room, teacher, scheduleDate, subject } = this.state;
    const { viewRowData, examConduct } = this.props;
    console.log(examConduct?.examConductionById);
    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title={"Exam Conduction"} />
        </Paper>
        <br />
        <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={2}
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Room Name : "
                />
                {" " + viewRowData.room}
              </Grid>
              <br />

              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Schedule Date :"
                />
                {" " + viewRowData.scheduleDate}
              </Grid>
              <br />
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Teacher Name : "
                />
                {" " + viewRowData.teacher}
              </Grid>
              <br />

              <DynamicTable
                data={examConduct?.examConductionById}
                tableHead={this.columns}
                showPegination={false}
                isActionColActive={false}
              />
              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid
                container
                sx={{ marginTop: 2 }}
                columnSpacing={1}
                justifyContent="right"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  onClick={this.props.onCancel}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  examConduct: state.examConduct,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExamConductView);
