import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { LabelCompo } from "../../../components/Comman/Label";
import { getTimeSlot } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";
import {
  getTeacherTimeTable,
  getTeacherByDepartmentAccess,
} from "../GenerateTimeTable/generateTimeTable.slice";

import MatrixView from "./MatrixView";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
};

class FeeStructureHeadIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
    };
  }
  componentDidMount() {
    const {
      getTimeSlot,
      getTeacherTimeTable,
      getAlert,
      getTeacherByDepartmentAccess,
    } = this.props;
    getTimeSlot({ sortNo: true }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getTeacherTimeTable({ userRegistrationId: "" }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getTeacherByDepartmentAccess().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  saveHandler = () => {
    this.setState({
      open: true,
    });
  };
  cancleHandler = () => {
    this.setState({
      open: false,
    });
  };
  submitHandler = (data) => {
    this.setState({
      open: false,
    });
  };
  render() {
    const { isLoading } = this.state;
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          {this.props.timeSlotList.isFetch && (
            <MatrixView
              dayList={[
                { id: "0", name: "Monday" },
                { id: "1", name: "Tuesday" },
                { id: "2", name: "Wednesday" },
                { id: "3", name: "Thursday" },
                { id: "4", name: "Friday" },
                { id: "5", name: "Saturday" },
                { id: "6", name: "Sunday" },
              ]}
              timeSlotList={this.props.timeSlotList?.timeSlot}
              onCancel={this.cancleHandler}
            />
          )}
        </Paper>
        {/* {isLoading && <Loading1 />}
        {!feeStructuredList.isFeesStructureHeaderFetch && <Loading1 />} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  timeSlotList: state.timeSlot,
});
const mapDispatchToProps = {
  getTimeSlot,
  getTeacherTimeTable,
  getAlert,
  getTeacherByDepartmentAccess,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeeStructureHeadIndex);
