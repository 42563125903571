import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { committeeHandleJson } from "../../../../../DynamicFormsJson/CommitteeHandle";
import { committeeHandleColumns } from "../../../../../tableColumns/table-columns";
import { showNotification } from "../../../../Landing/Landing.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getActivityByCommitteeHandle } from "../../AcademicDetails/Activity/activity.slice";
import {
  getCommitteeHandle,
  getCommitteeHandleWithFilter,
} from "./committeeHandle.slice";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class CommitteeHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      dynamicMasterData: {
        LevelMaster: [
          { id: "International", name: "International" },
          { id: "National", name: "National" },
          { id: "State", name: "State" },
          { id: "University", name: "University" },
          { id: "Inhouse", name: "Inhouse" },
          { id: "Intercollegiate", name: "Intercollegiate" },
          { id: "other", name: "Other" },
        ],
        listOfCommittee: [
          { id: "Examination Cell", name: "Examination Cell" },
          { id: "Time Table", name: "Time Table" },
          { id: "Cultural", name: "Cultural" },
          { id: "Sports", name: "Sports" },
          { id: "N.S.S", name: "N.S.S" },
          { id: "Women's Cell", name: "Women's Cell" },
          { id: "EECH", name: "EECH" },
          { id: "Seminar & Workshop", name: "Seminar & Workshop" },
          { id: "Stride", name: "Stride" },
          { id: "Website & Social Media", name: "Website & Social Media" },
          {
            id: "Environment Sensitization",
            name: "Environment Sensitization",
          },
          { id: "Gandhian Studies", name: "Gandhian Studies" },
          { id: "Incubation Cell", name: "Incubation Cell" },
          { id: "Magazine & Publication", name: "Magazine & Publication" },
          { id: "Freeship", name: "Freeship" },
          { id: "Mentoring", name: "Mentoring" },
          { id: "Canteen", name: "Canteen" },
          { id: "Library", name: "Library" },
          { id: "Student Welfare", name: "Student Welfare" },
          { id: "Anti-Ragging", name: "Anti-Ragging" },
          { id: "IQAC", name: "IQAC" },
        ],
        departmentMaster: this.props.departmentList?.department,
        activity: this.props.activityList?.committeeHandle,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.committeeHandle !==
        nextProps.activityList.committeeHandle
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: nextProps.activityList.committeeHandle.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getActivityByCommitteeHandle().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
        this.setState({ showLoader: false });
      } else {
        this.setState({ showLoader: false });
      }
    });
    this.setState({ showLoader: true });
    this.props.getCommitteeHandle().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
    this.setState({ showLoader: true });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  submitHandler = (data) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getCommitteeHandleWithFilter({
        listOfCommittee: data.committeeName,
        activityId: data.employeeActivity,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  render() {
    const { committeList } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={committeeHandleJson.screenTitle}
          fieldMeta={committeeHandleJson.fieldMeta}
          showPdfDownload={committeeHandleJson.showPdfDownload}
          showExcelDownload={committeeHandleJson.showExcelDownload}
          pdfFileName={committeeHandleJson.pdfFileName}
          excelFileName={committeeHandleJson.excelFileName}
          showAddButton={committeeHandleJson.showAddButton}
          tableColumnsToFilter={committeeHandleJson.tableColumnsToFilter}
          dynamicMasterData={dynamicMasterData}
          showSaveNextBtn={false}
          tableColumns={committeeHandleColumns}
          tableData={committeList?.committeeHandle}
          getTableData={this.props.getCommitteeHandle}
          showLoader={!this.state.showLoader}
          apiBaseURL={committeeHandleJson.apiBaseURL}
          baseIdColumn={committeeHandleJson.baseIdColumn}
          deleteUrl={committeeHandleJson.deleteUrl}
          type={1}
          DocumentUpload={committeeHandleJson.DocumentUpload}
          sendEmptyId={false}
          onSearchData={this.submitHandler}
          searchList={committeeHandleJson.searchList}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  departmentList: state.department,
  committeList: state.committee,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getCommitteeHandleWithFilter,
  getCommitteeHandle,
  getDepartment,
  getActivityByCommitteeHandle,
};
export default connect(mapStateToProps, mapDispatchToProps)(CommitteeHandle);
