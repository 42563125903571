import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { PatternColumns } from "../../../../tableColumns/table-columns";
import { patternJson } from "../../../../DynamicFormsJson/Pattern";
import { getPattern } from "./pattern.slice";
import {getAlert}from "../../../../CommonActions/alert.slice";
class Pattern extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        markingSystem: [
          { id: "1", name: "Credit" },
          { id: "2", name: "Marks" },
          { id: "3", name: "Grade" },
        ],
      },
    };
  }

  componentDidMount() {
    const { getPattern } = this.props;
    getPattern().then((response)=>{
      if(!response){
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  render() {
    const { dynamicMasterData } = this.state;
    const { patternList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={patternJson.screenTitle}
            fieldMeta={patternJson.fieldMeta}
            showPdfDownload={patternJson.showPdfDownload}
            showExcelDownload={patternJson.showExcelDownload}
            pdfFileName={patternJson.pdfFileName}
            excelFileName={patternJson.excelFileName}
            showAddButton={patternJson.showAddButton}
            tableColumnsToFilter={patternJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            tableColumns={PatternColumns}
            tableData={patternList?.pattern}
            getTableData={this.props.getPattern}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={patternList.isPatternFetch}
            apiBaseURL={patternJson.apiBaseURL}
            baseIdColumn={patternJson.baseIdColumn}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  patternList: state.pattern,
});
const mapDispatchToProps = {
  getPattern,getAlert
};
export default connect(mapStateToProps, mapDispatchToProps)(Pattern);
