
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  examFeesStructure: [],
  isFetch: false,
};
let URL = endpoints.examFeesStructure;
const examFeesStructureSlice = createSlice( {
  name: "examFeesStructure",
  initialState,
  reducers: {
    examFeesStructureSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        examFeesStructure: row,
        isFetch: true,
      };
    },
  },
} );

export const {
  examFeesStructureSuccess,
} = examFeesStructureSlice.actions;

export default examFeesStructureSlice.reducer;

export const getExamFeesStructure = () => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      url: URL + '?sort={"insertdatetime": "DESC"}'
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data
        console.log( content )
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            name: data1.title,
            title: data1.title,
            type: data1.type,
            fixedFees: data1.fixedFees,
            perSubject: data1.perSubject,
            maxCapFees: data1.maxCapFees,
            isActive: data1.isActive,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( examFeesStructureSuccess( { row } ) );
      }
      return success
    } );
    return response
  } catch ( e )
  {

    return console.error( e.message );
  }
};