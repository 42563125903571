import { fabClasses } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import Alert from "../../../../Alert/alert.slice";
import { DirectionSnackbar } from "../../../../components/Comman/SnakBarCompo";
import { Loading1 } from "../../../../components/Loading1";
import * as myConstClass from "../../../../config/messageconstant";
// import ProgMView from "./ProgMView";

import {
  getGenerateOTP,
  getValidateOtp,
  getChangePassword,
} from "./ForgotPassword.slice";
import AlumniRegisterForm from "./ForgotPasswordForm";

const ParentForgotPassword = ({
  getGenerateOTP,
  getValidateOtp,
  studentRegData,
  getChangePassword,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [enterOtp, setEnterOtp] = useState();
  const [regUser, setRegUser] = useState({});
  const [openSnak, setOpenSnak] = useState(false);
  const [openSuccess, setSuccess] = useState("");
  const [login, setLogin] = useState(false);
  const [loader, setLoader] = useState(false);

  const studentRegisterData = (password) => {
    setRegUser(password);
    setLoader(true);

    getChangePassword({ contact: username, password: password }).then(
      (success) => {
        if (success) {
          setLoader(false);

          swal
            .fire({
              icon: "success",
              title: "Password Change Successfully",
              text: "  Your Password has been updated successfully",
              showConfirmButton: true,
              confirmButtonColor: "#1f89f4",
              confirmButtonText: "Login",
            })
            .then((okLogin) => {
              if (okLogin.isConfirmed) {
                navigate("/login");
              }
            });
        } else {
          setSnakMsg(myConstClass.saveFailMsg);
          setSuccess("error");
          setLoader(false);
        }
      }
    );
  };

  const generateData = (contact) => {
    setUsername(contact);
    setLoader(true);
    getGenerateOTP({ contact: contact }).then((succsss) => {
      setOpenSnak(true);
      if (succsss) {
        setLoader(false);
        setSnakMsg(myConstClass.otpGenerateMsg);
        setSuccess("success");
      } else {
        setLoader(false);

        setSnakMsg(myConstClass.otpNotGenerateMsg);
        setSuccess("error");
      }
    });
    setEditRow([]);
    setLoader(false);
  };

  const validateData = (enterOtp) => {
    setEnterOtp(enterOtp);
    setLoader(true);
    getValidateOtp({ otp: enterOtp, contact: username }).then((success) => {
      setOpenSnak(true);
      if (success) {
        setSnakMsg(myConstClass.otpValidMsg);
        setSuccess("success");
        setLoader(false);
      } else {
        setSnakMsg(myConstClass.otpInvalidMsg);
        setSuccess("error");
        setLoader(false);
      }
    });
    setEditRow([]);
    setLoader(false);
  };
  const stopEdit = () => {
    navigate("/login");
    setEditRow([]);
  };

  return (
    <>
      {!studentRegData.isServerFail && (
        <DirectionSnackbar
          open={openSnak}
          severity={openSuccess}
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      )}
      {studentRegData.isServerFail && <Alert msg={myConstClass.serverMsg} />}

      {""}
      <AlumniRegisterForm
        OnSave={studentRegisterData}
        saveMobile={generateData}
        submitOtp={validateData}
        onCancel={stopEdit}
      />
      {/* {login && open && <ProgMView openparam={open} closeView={setOpen} />} */}
      {loader && <Loading1 size={50} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  studentRegData: state.alumniRegister,
  common: state.common,
});

const mapDispatchToProps = {
  getGenerateOTP,
  getValidateOtp,
  getChangePassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentForgotPassword);
