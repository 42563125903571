import React from "react";
import { connect } from "react-redux";
import FeesCollection from "./FeesCollection";
import { resetValues } from "./FeesCollection.slice";
//import Matrix1 from "./Matrix1";

class FeesStructureHeader extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    flag: 0,
  };
  onClickOpenForm = () => {
    this.setState({
      flag: 1,
    });
  };

  componentDidMount() {
    const { resetValues } = this.props;
    resetValues();
  }

  render() {
    return (
      <>
        <FeesCollection onClickAdd={this.onClickOpenForm} />
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  resetValues,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeesStructureHeader);
