export const DeemedMeetingDynamicJson = {
  screenTitle: "Deemed Meeting",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getAcadamicYear: true,
  getListId: "acadamicYear,yearDetail",
  fieldMeta: [
    {
      label: "Committee ",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "committeeMaster",
      dataKey: "committee",
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      dataKey: "fromDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      dataKey: "toDate",
      isMAxDate: false,
      isMinDate: true,
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-g",
    },
  ],
};
