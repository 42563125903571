import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import * as myConstClass from "../../config/messageconstant";
import { ExternalInternshipDynamicJson } from "../../DynamicFormsJson/ExternalInternshipDynamicJson";
import { getStudentList, resetValues } from "../ExternalStudent/external.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { FilterSave } from "../../components/FilterSave";
import { getInterestedStudById } from "../ListOfCompany/CompanyList.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import StudentInternship from "./StudentInternship";
class ExternalStudentForm extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    fromDate: "",
    toDate: "",
    fieldData: {},
    internType: "",
    flag: false,
    showLoader: false,
    academicYear: "",
    yearDetail: "",
    division: [],
    dynamicMasterData: {
      yearDetailMaster: this.props.internshipDeclarationList?.yearDetails,
      divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      internTypeMaster: this.props.internshipTypeList?.internshipType,
    },
  };

  getListById = (data) => {
    if (data.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList?.yearDetails !==
        nextProps.internshipDeclarationList?.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.internshipDeclarationList?.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList?.distinctDivision !==
        nextProps.internshipDeclarationList?.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList?.distinctDivision,
          },
        });
      }
    }

    if (this.props.internshipTypeList !== nextProps.internshipTypeList) {
      if (
        this.props.internshipTypeList.internshipType !==
        nextProps.internshipTypeList.internshipType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            internTypeMaster: nextProps.internshipTypeList.internshipType,
          },
        });
      }
    }
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,
    },
    {
      name: "companyName",
      title: "Company",
      positionCenter: true,
      showInscreen: true,
      canSearch: true,
      alignCenter: "center",
    },
    {
      name: "status",
      title: "Viva Status",
      positionCenter: true,
      showInscreen: true,
      canSearch: true,
      alignCenter: "center",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      canSearch: true,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  getListById = (data) => {
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  componentWillReceiveProps(nextProps) {
    const dataToSearch = {};

    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList?.yearDetails !==
        nextProps.internshipDeclarationList?.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.internshipDeclarationList?.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList?.distinctDivision !==
        nextProps.internshipDeclarationList?.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList?.distinctDivision,
          },
        });
      }
    }

    if (this.props.internshipTypeList !== nextProps.internshipTypeList) {
      if (
        this.props.internshipTypeList.internshipType !==
        nextProps.internshipTypeList.internshipType
      ) {
        const yearDetailId = localStorage.getItem("yearDetailId");
        if (
          yearDetailId &&
          nextProps.internshipTypeList.internshipType.length !== 0 &&
          nextProps.internshipTypeList.internshipType[0].id
        ) {
          dataToSearch["internship"] =
            nextProps.internshipTypeList.internshipType[0].id;
        }

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            internTypeMaster: nextProps.internshipTypeList.internshipType,
          },
          fieldData: dataToSearch,
        });

        const ay = localStorage.getItem("acadamicYearId");
        const divisionIds = localStorage.getItem("divisionIds");
        if (
          yearDetailId &&
          divisionIds &&
          ay &&
          nextProps.internshipTypeList.internshipType.length !== 0 &&
          nextProps.internshipTypeList.internshipType[0].id
        ) {
          const { getStudentList } = this.props;

          this.setState({
            showLoader: true,
          });
          getStudentList({
            academicYearId: ay,
            YearDetail: yearDetailId,
            division: divisionIds,
            internType: nextProps.internshipTypeList.internshipType[0].id,
          }).then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        }
      }
    }
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "studentName",
      title: "Student Name",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "companyName",
      title: "Company",
      positionCenter: true,
      showInscreen: true,
      canSearch: true,
      alignCenter: "center",
    },
    {
      name: "status",
      title: "Viva Status",
      positionCenter: true,
      showInscreen: true,
      canSearch: true,
      alignCenter: "center",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      canSearch: true,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  componentDidMount() {
    const {
      getInternshipType,
      getAlert,
      resetValues,
      getYearDetails,
      resetReducList,
    } = this.props;

    resetValues();
    resetReducList();
    const yearDetailId = localStorage.getItem("yearDetailId");

    this.setState({
      showLoader: true,
    });
    getInternshipType().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "yearDetail") {
        this.setState({ division: [] });
        const { getDistinctDivision, getAlert } = this.props;
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  rowView = (row) => {
    this.props.getInterestedStudById({ id: row.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          open: true,
          flag: true,
        });
      }
    });
  };

  searchData = (data) => {
    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("divisionIds", data.divisionId);
    FilterSave();
    const { getStudentList, getAlert } = this.props;
    const ay = localStorage.getItem("acadamicYearId");
    this.setState({
      showLoader: true,
    });
    getStudentList({
      academicYearId: ay,
      YearDetail: data.yearDetail,
      division: data.divisionId,
      internType: data.internship,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onClose = (flag) => {
    this.setState({
      open: false,
      flag: false,
    });
  };

  multiSelectHandler = (value, name) => {
    console.log(value);
    console.log(name);
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };
  render() {
    const {
      academicYear,
      yearDetail,
      internType,
      division,
      dynamicMasterData,
      showLoader,
      fieldData,
    } = this.state;
    const {
      internTypeError,
      academicYearError,
      yearDetailError,
      divisionError,
    } = this.state.formErrors;
    const {
      internshipTypeList,
      internshipDeclareCompanyList,
      academicYearList,
      internshipDeclarationList,
    } = this.props;
    console.log(internshipDeclareCompanyList?.companyListStatus);
    return (
      <>
        <div>
          {!this.state.flag && (
            <>
              <DynamicMainScreen
                // onAddButtonClick={this.onAddButtonClick}
                screenTitle={ExternalInternshipDynamicJson.screenTitle}
                fieldMeta={ExternalInternshipDynamicJson.fieldMeta}
                buttonCenter={ExternalInternshipDynamicJson.buttonCenter}
                showPdfDownload={false}
                showExcelDownload={false}
                tableColumnsToFilter={
                  ExternalInternshipDynamicJson.tableColumnsToFilter
                }
                showAddButton={false}
                dynamicMasterData={this.state.dynamicMasterData}
                tableColumns={this.columns}
                getListById={this.getListById}
                tableData={this.props.externalStudentList?.appliedStudentList}
                showPegination={false}
                fieldData={fieldData}
                showView={true}
                downLoadPdfFromApi={true}
                pdfDownload={this.downloadPdf}
                rowViewData={this.rowView}
                showLoader={showLoader}
                onSearchData={this.searchData}
                baseIdColumn={ExternalInternshipDynamicJson.baseIdColumn}
                apiBaseURL={ExternalInternshipDynamicJson.apiBaseURL}
              />
            </>
          )}
          {/* <Grid xs={12} sm={12} md={12}>
              <ExternalStudentTable columns={columns} flag={this.onClose} />
            </Grid> */}
          {this.state.open && <StudentInternship goBack={this.onClose} />}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipTypeList: state.internshipType,
  externalStudentList: state.external,
  internshipDeclarationList: state.internshipDeclaration,
  internshipDeclareCompanyList: state.companyList,
});
const mapDispatchToProps = {
  getInternshipType,
  getAlert,
  getStudentList,
  resetValues,
  getYearDetails,
  getDistinctDivision,
  resetReducList,
  getInterestedStudById,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalStudentForm);

//

// import { Grid, Paper } from "@mui/material";
// import React from "react";
// import { connect } from "react-redux";
// import { getAlert } from "../../CommonActions/alert.slice";
// import { resetReducList } from '../../CommonActions/User.action';
// import AutoComplete from "../../components/Comman/AutoComplete";
// import { ButtonCompo } from "../../components/Comman/Button";
// import { LabelCompo } from "../../components/Comman/Label";
// import MultipleSelect from "../../components/Comman/MultipleSelect";
// import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
// import * as myConstClass from "../../config/messageconstant";
// import { getStudentList, resetValues } from "../ExternalStudent/external.slice";
// import { getDistinctDivision, getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
// import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
// import ExternalStudentTable from "./ExternalStudentTable";
// import { ExternalInternshipDynamicJson } from "../../DynamicFormsJson/ExternalInternshipDynamicJson"
// import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
// const columns = [
//   {
//     name: "index",
//     title: "Sr. No.",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//   },
//   {
//     name: "studentName",
//     title: "Student Name",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//   },
//   {
//     name: "companyName",
//     title: "Company",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//   },
//   {
//     name: "status",
//     title: "Viva Status",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//   },
//   {
//     name: "action",
//     title: "Action",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "right",
//   },
// ];

// class ExternalStudentForm extends React.Component
// {
//   state = {
//     id: 0,
//     formErrors: [],
//     fromDate: "",
//     toDate: "",
//     internType: "",
//     flag: false,
//     academicYear: "",
//     yearDetail: "",
//     division: [],
//   };

//   componentDidMount()
//   {
//     const {
//       getInternshipType,
//       getAlert,
//       resetValues,
//       getAcademicYear,
//       getYearDetails,
//       resetReducList
//     } = this.props;
//     getInternshipType().then( ( response ) =>
//     {
//       if ( !response )
//       {
//         getAlert( { message: "Something went wrong" } );
//       }
//     } );

//     getYearDetails().then( ( response ) =>
//     {
//       if ( !response )
//       {
//         getAlert( { message: "Something went wrong" } );
//       }
//     } );
//     resetValues();
//     resetReducList();
//   }

//   onDateChange = ( dataKey ) => ( date ) =>
//   {
//     if ( date !== null )
//     {
//       this.setState( {
//         [ dataKey ]: date,
//       } );
//     }
//   };

//   changeHandler = ( event ) =>
//   {
//     const { name, value } = event.target;
//     this.setState( {
//       ...this.state,
//       [ name ]: value,
//     } );
//   };

//   ChangeHandlerSearch = ( name, newValue ) =>
//   {
//     if ( newValue !== null )
//     {
//       this.setState( { [ name ]: newValue } );
//       if ( name === "yearDetail" )
//       {
//         this.setState( { division: [] } )
//         const { getDistinctDivision } = this.props;
//         getDistinctDivision( { yearDetail: newValue } ).then( ( response ) =>
//         {
//           if ( !response )
//           {
//             getAlert( { message: "Something went wrong" } );
//           }
//         } );
//       }
//     } else
//     {
//       this.setState( { [ name ]: "" } );
//     }
//   };

//   handleFormValidation()
//   {
//     const { division, yearDetail, internType, academicYear } = this.state;
//     let formErrors = {};
//     let formIsValid = true;

//     if ( internType === "" || internType === null )
//     {
//       formIsValid = false;
//       formErrors[ "internTypeError" ] = myConstClass.internTypeMsg;
//     }

//     if ( division.length === 0 || division === null )
//     {
//       formIsValid = false;
//       formErrors[ "divisionError" ] = myConstClass.divisionNameMsg;
//     }
//     if ( yearDetail === "" || yearDetail === null )
//     {
//       formIsValid = false;
//       formErrors[ "yearDetailError" ] = myConstClass.yearDetailMsg;
//     }

//     this.setState( { formErrors: formErrors } );
//     return formIsValid;
//   }

//   searchData = () =>
//   {
//     const { academicYear, yearDetail, internType, division } = this.state;
//     const { getStudentList } = this.props;
//     const ay = localStorage.getItem( 'acadamicYearId' )
//     if ( this.handleFormValidation() )
//     {
//       getStudentList( {
//         academicYearId: ay,
//         YearDetail: yearDetail,
//         division: division,
//         internType: internType,
//       } ).then( ( response ) =>
//       {
//         if ( !response )
//         {
//           getAlert( { message: "Something went wrong" } );
//         }
//       } );
//     }
//   };

//   onClose = ( flag ) =>
//   {
//     this.setState( {
//       flag: flag,
//     } );
//   };

//   multiSelectHandler = ( value, name ) =>
//   {
//     console.log( value );
//     console.log( name );
//     this.setState( {
//       ...this.state,
//       [ name ]: typeof value === "string" ? value.split( "," ) : value,
//     } );
//   };
//   render()
//   {
//     const { academicYear, yearDetail, internType, division } = this.state;
//     const {
//       internTypeError,
//       academicYearError,
//       yearDetailError,
//       divisionError,
//     } = this.state.formErrors;
//     const {
//       internshipTypeList,
//       internshipDeclareCompanyList,
//       academicYearList,
//       internshipDeclarationList,
//     } = this.props;
//     console.log( internshipDeclareCompanyList?.companyListStatus );
//     return (
//       <>
//         <div>
//           <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
//             <TitleAndBreadCrum title="External Student Internship ist" />
//           </Paper>
//           <br />
//           <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
//             {!this.state.flag && (
//               <>

//                 <Grid container justifyContent="space-evenly" direction="row">

//                   <Grid
//                     item
//                     sm={12}
//                     xs={12}
//                     md={12}
//                     lg={12}
//                     container
//                     rowSpacing={2}
//                     columnSpacing={1}
//                     justifyContent="space-evenly"
//                     sx={{ marginTop: 2 }}
//                   >
//                     <Grid
//                       item
//                       sm={12}
//                       xs={12}
//                       md={4}
//                       lg={4}
//                       container
//                       justifyContent="space-evenly"
//                     >
//                       <Grid item xs={12} sm={12} md={12}>
//                         <LabelCompo
//                           className="text-black"
//                           style={{ fontSize: 16 }}
//                           label="*Year Details"
//                         />
//                       </Grid>

//                       <Grid item xs={12} sm={12} md={12}>
//                         <AutoComplete
//                           keyColName={"id"}
//                           value={yearDetail}
//                           name={"yearDetail"}
//                           options={internshipDeclarationList?.yearDetails}
//                           onChange={this.ChangeHandlerSearch}
//                           isError={yearDetailError ? true : false}
//                           errorText={yearDetailError ? yearDetailError : " "}
//                         />
//                       </Grid>
//                     </Grid>

//                     <Grid
//                       item
//                       sm={12}
//                       xs={12}
//                       md={4}
//                       lg={4}
//                       container
//                       justifyContent="space-evenly"
//                     >
//                       <Grid item xs={12} sm={12} md={12}>
//                         <LabelCompo
//                           className="text-black"
//                           style={{ fontSize: 16 }}
//                           label="*Division"
//                         />
//                       </Grid>

//                       <Grid item xs={12} sm={12} md={12}>
//                         <MultipleSelect
//                           er={divisionError}
//                           name1="division"
//                           value1={division}
//                           names={internshipDeclarationList?.distinctDivision}
//                           onChange={this.multiSelectHandler}
//                         />
//                       </Grid>
//                     </Grid>
//                     <Grid
//                       item
//                       sm={12}
//                       xs={12}
//                       md={4}
//                       lg={4}
//                       container
//                       justifyContent="space-evenly"
//                     >
//                       <Grid item xs={12} sm={12} md={12}>
//                         <LabelCompo
//                           className="text-black"
//                           style={{ fontSize: 16 }}
//                           label="*Internship Type"
//                         />
//                       </Grid>

//                       <Grid item xs={12} sm={12} md={12}>
//                         <AutoComplete
//                           keyColName={"id"}
//                           value={internType}
//                           name={"internType"}
//                           options={internshipTypeList?.internshipType}
//                           onChange={this.ChangeHandlerSearch}
//                           isError={internTypeError ? true : false}
//                           errorText={internTypeError ? internTypeError : " "}
//                         />
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//                 <Grid item sm={12} xs={12} md={12} lg={12}>
//                   <Grid
//                     container
//                     justifyContent="center"
//                     item
//                     xs={12}
//                     sm={12}
//                     md={12}
//                   >
//                     <ButtonCompo
//                       size="medium"
//                       type="button"
//                       variant="contained"
//                       name="Search"
//                       onClick={this.searchData}
//                     />
//                   </Grid>
//                 </Grid>
//               </>
//             )}
//             <br />
//             <Grid xs={12} sm={12} md={12}>
//               <ExternalStudentTable columns={columns} flag={this.onClose} />
//             </Grid>
//           </Paper>
//         </div>
//       </>
//     );
//   }
// }
// const mapStateToProps = ( state ) => ( {
//   internshipTypeList: state.internshipType,
//   externalStudentList: state.external,
//   internshipDeclarationList: state.internshipDeclaration,
//   internshipDeclareCompanyList: state.companyList,
// } );
// const mapDispatchToProps = {
//   getInternshipType,
//   getAlert,
//   getStudentList,
//   resetValues,
//   getYearDetails,
//   getDistinctDivision,
//   resetReducList
// };
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )( ExternalStudentForm );
