
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import {  apiGet } from "../../../../utils/api_service";

const initialState = {
  internshipType: [],
  isInternshipTypeFetch: false,
};
let URL = endpoints.internshipType;
const internshipTypeSlice = createSlice({
  name: "internship",
  initialState,
  reducers: {
    internshipTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        internshipType: row,
        isInternshipTypeFetch: true,
      };
    },
  },
});

export const {
  internshipTypeSuccess,
} = internshipTypeSlice.actions;

export default internshipTypeSlice.reducer;

export const getInternshipType = () => async (dispatch) => {
  try {
 const response= apiGet({
    url: URL+'?sort={"insertdatetime": "DESC"}'
}).then(({data ,success }) => {
    if (success) {
      const {content} = data.data
      let index = 0;
      let row = [];
      content.map((data1) => {
        index = index + 1
        let bData = {
          index: index,
          id: data1.id,
          name: data1.name,
          address: data1.address,
          emailId: data1.emailId,
          isActive: data1.isActive ,

        };
        row.push(bData);
        return data1;
      });
        dispatch(internshipTypeSuccess({ row }));
    }
    return success
});
return response
  } catch (e) {
   
    return console.error(e.message);
  }
};