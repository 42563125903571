import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class EditSubjectSyllabus extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    name: "",
    rowListDetails: [],
    formErrors: [],
    orderNo: "",
    editList: {},
  };

  componentDidMount() {
    const { editList } = this.props;
    console.log(editList);
    if (editList.name === "subSection") {
      this.setState({
        id: editList.id,
        orderNo: editList.sortNo,
        name: editList.subSectionName,
      });
    } else if (editList.name === "section") {
      this.setState({
        id: editList.id,

        orderNo: editList.sortNo,
        name: editList.sectionName,
      });
    } else {
      this.setState({
        id: editList.id,

        orderNo: editList.sortNo,
        name: editList.chapter,
      });
    }
  }

  changeFromDate = (date) => {};

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = (e) => {
    const { editList } = this.props;
    const { name, orderNo,id } = this.state;

    let formData = {
      id: id,
      orderNo: orderNo,
      name: name,
      editList: editList,
    };
    console.log(formData);
    this.props.onSave(formData);
  };

  handleFormValidation() {}

  handleClose = () => {
    this.props.onCancel();
  };

  render() {
    const { applicantAdmissionData, data, intrestedStudList } = this.props;
    const { name, orderNo } = this.state;
    const { debitAmountError, transactionDateError } = this.state.formErrors;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Edit Syllabus
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 2 }}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="name"
                        id="name"
                        value={name}
                        onChange={this.changeHandler}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Sort order number"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="orderNo"
                        id="orderNo"
                        value={orderNo}
                        onChange={this.changeHandler}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label=""
                      />
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Submit"
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubjectSyllabus);
