import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { generateExcel } from "../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../components/Comman/GeneratePDF";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import NewLandingScreenHeader from "../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { ActivitiesJson } from "../../../DynamicFormsJson/Activities.js";
import { AcademicAchievementsJson } from "../../../DynamicFormsJson/AcademicAchievements";
import { BooksAuthoredJson } from "../../../DynamicFormsJson/BooksAuthored";
import { ExtracurricularActivitiesJson } from "../../../DynamicFormsJson/ExtracurricularActivities";
import { HonoursAwardsJson } from "../../../DynamicFormsJson/HonoursAwards";
import { PublicationsJson } from "../../../DynamicFormsJson/Publications";
import { PatentsJson } from "../../../DynamicFormsJson/Patents";
import { PlacementJson } from "../../../DynamicFormsJson/Placement";
import { PapersPresentedJson } from "../../../DynamicFormsJson/PapersPresented";
import { SportsAchievementsJson } from "../../../DynamicFormsJson/SportsAchievements";
import { TestScoresJson } from "../../../DynamicFormsJson/TestScores";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { AcademicAchievementsColumns } from "../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { Loading1 } from "../../../components/Loading1";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { getAchievementsByAluminiId } from "./achivment.slice";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AluminiAchievements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      formErrors: {},
      showTable: true,
      showLoader: false,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      achievements: "",
      achievementsType: [
        {
          id: "Academic Achievements",
          name: "Academic Achievements",
          isActive: "1",
        },
        {
          id: "Professional",
          name: "Professional (Award / Accolades)",
          isActive: "1",
        },
        {
          id: "Publications",
          name: "Publication (Books, Articles)",
          isActive: "1",
        },
        { id: "Patents", name: "Patents", isActive: "1" },
        {
          id: "Personal Accomplishments",
          name: "Personal Accomplishments",
          isActive: "1",
        },
        {
          id: "Any Other",
          name: "Any Other",
          isActive: "1",
        },
      ],
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };
  componentWillReceiveProps(nextProps) {}

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
      achievements: "",
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    let fieldData = {};
    const dateArr = rowData["date"]?.split("-");
    console.log(rowData);
    fieldData["date"] = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
    fieldData["title"] = rowData["title"]?.toString();
    fieldData["id"] = rowData.id;
    fieldData["detail"] = rowData.detail;
    fieldData["description"] = rowData.description;

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
      achievements: rowData.achievementType,
    });
    this.toggleFormTableVisibility();
  };

  componentDidMount() {
    // this.setState({ showLoader: true });
    // this.props.getActivityByType().then((response) => {
    //   if (!response) {
    //     this.setState({ showLoader: false });
    //     this.props.getAlert({ message: "Something went wrong" });
    //   } else {
    //     this.setState({ showLoader: false });
    //   }
    // });
    this.props.getAchievementsByAluminiId({ id: "" }).then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  onChangeStatus = (rowData, status) => {
    // swal(msgConstant.changeActive, {
    //   buttons: ["Cancel", true],
    // }).then((value) => {
    //   if (value) {
    //     this.setState({
    //       isLoading: true,
    //     });
    //     let activeStatus = rowData.isActive === 1 ? 0 : 1;
    //     apiGet({
    //       url:
    //         endpoint.saveEmployee +
    //         "/update-status?userRegistrationId=" +
    //         rowData.id +
    //         "&status=" +
    //         activeStatus,
    //     }).then(({ success }) => {
    //       if (success) {
    //         this.setState({
    //           isLoading: false,
    //         });
    //         this.props.showNotification({ msg: "Status Updated" });
    //       } else {
    //         this.props.getAlert({
    //           message: "Failed to change status",
    //         });
    //         this.setState({
    //           isLoading: false,
    //         });
    //       }
    //     });
    //   }
    // });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
    // this.setState({
    //   achievements: "",
    // });
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode, achievements } = this.state;
    if (achievements !== "") {
      const dataToSave = {
        // isActive:
        //   currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
        detail: fieldData.detail,
        achievementType: achievements,
      };

      console.log(dataToSave);
      if (currentOperationMode === OperationMode.Edit) {
        dataToSave["id"] = fieldData["id"];
      }
      AcademicAchievementsJson.fieldMeta.forEach((currentField) => {
        if (
          currentField.controlType === "autocomplete" &&
          !currentField.isRootLevelKey
        ) {
          dataToSave[currentField.dataKey] = {};
          dataToSave[currentField.dataKey]["id"] = fieldData[
            currentField.dataKey
          ]
            ? fieldData[currentField.dataKey]
            : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey] !== undefined) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
          }

          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });
      this.onSave(dataToSave, isToggle);
    }
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    // send json according to achievementType
    apiPost({
      url: AcademicAchievementsJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.props.getAchievementsByAluminiId({ id: "" }).then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    // send json according to achievementType
    apiDelete({
      url: AcademicAchievementsJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const {} = this.state;
        this.props.getAchievementsByAluminiId({ id: "" }).then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });

        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { achievements } = this.props;
    const { searchValue } = this.state;

    const columsFil = AcademicAchievementsColumns.filter((item) => {
      return item.canSearch;
    });
    return achievements?.achievements.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    console.log(name);
    console.log(newValue);
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      onCancel,
      onSubmit,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      achievements,
      achievementsType,
      dynamicMasterData,
      fieldData,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    console.log(achievements);
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        {/* send json according to achievementType */}
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={""}
          showPdfDownload={false}
          // generatePDF={this.generatePDF}
          showExcelDownload={false}
          showSearchBox={false}
          showAddButton={showAddButton}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        {/* <br /> */}
        {/* <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}> */}
        {showForm && (
          <>
            {/* send json according to achievementType */}
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                xs={12}
                sm={10.5}
                md={10.5}
                lg={10.5}
                container
                // sx={{ mt: -3 }}
                justifyContent="flex-end"
              ></Grid>
              <Grid
                item
                xs={12}
                sm={1.5}
                md={1.5}
                lg={1.5}
                container
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to list"
                    onClick={this.onFormCancel}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Achievement Type"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={achievements}
                    name={"achievements"}
                    options={achievementsType}
                    onChange={this.ChangeHandlerSearch}
                    // isError={institutionErr ? true : false}
                    // errorText={institutionErr ? institutionErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
            <DynamicForm
              fieldMeta={
                achievements == "Academic Achievements"
                  ? AcademicAchievementsJson.fieldMeta
                  : achievements == "Professional"
                  ? HonoursAwardsJson.fieldMeta
                  : achievements == "Publications"
                  ? PublicationsJson.fieldMeta
                  : achievements == "Patents"
                  ? PatentsJson.fieldMeta
                  : achievements == "Personal Accomplishments"
                  ? TestScoresJson.fieldMeta
                  : achievements == "Any Other"
                  ? AcademicAchievementsJson.fieldMeta
                  : []
              }
              dynamicMasterData={dynamicMasterData}
              fieldData={fieldData}
              onDataChange={this.onDataChange}
              showSaveNextBtn={false}
              showBackToListBtn={false}
              onCancel={this.onFormCancel}
              onSave={this.onFormSave(true)}
              onSaveAndNext={this.onFormSave(false)}
              showTitle={true}
            />
          </>
        )}

        {showTable && (
          <>
            {/* send columns according to achievementType */}
            <Grid item>
              <RTTable
                columns={AcademicAchievementsColumns}
                hiddenColumnNames={tableColumnsToFilter.map((item) => {
                  return !item.isChecked ? item.columnKeyName : "";
                })}
                showAddButtonInTable={showAddButtonInTable}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                rowAdd={this.rowAdd}
                rows={this.getFilteredTableData()}
                tableData={this.getFilteredTableData()}
              />
            </Grid>
            <br />
            <Grid
              rowSpacing={2}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  onClick={this.props.onCancel}
                  fullWidth={true}
                />
              </Grid>

              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={this.props.onSubmit}
                  name="Submit"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </>
        )}
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  achievements: state.achievements,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAchievementsByAluminiId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AluminiAchievements);
