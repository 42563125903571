import { Checkbox, FormGroup, Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { onlyNumber } from "../../../components/Comman/Util/Validations";
import * as myConstClass from "../../../config/messageconstant";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
let docs;
class StudAddress extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    perAddress1: "",
    perAddress2: "",
    pin: "",
    district: "",
    city: "",
    state: "",
    country: "",
    curAddress1: "",
    curAddress2: "",
    curpin: "",
    curDistrict: "",
    curCity: "",
    curState: "",
    checkForm: 0,
    formErrors: [],
    curCountry: "",
  };

  filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  changeHandler = (event) => {
    const { name, value, checked } = event.target;

    if (name === "checkForm") {
      const {
        id,
        perAddress1,
        perAddress2,
        pin,
        district,
        state,
        city,
        country,
      } = this.state;

      let countryList = this.props.studentFillProfileList?.country.filter(
        (data) => data.id === country
      );

      let stateList = this.props.studentFillProfileList?.state.filter(
        (data) => data.id === state
      );

      let cityList = this.props.studentFillProfileList?.city.filter(
        (data) => data.id === city
      );
      // console.log(countryList);
      // console.log(stateList);
      // console.log(cityList);

      if (checked === true) {
        this.setState({
          ...this.state,
          [name]: 1,
          id: id,
          curAddress1: perAddress1,
          curAddress2: perAddress2,
          curpin: pin,
          curDistrict: district,
          curState: stateList[0].id,
          curCity: cityList[0].id,
          curCountry: countryList[0].id,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: 0,
          id: id,
          curAddress1: "",
          curAddress2: "",
          curpin: "",
          curDistrict: "",
          curState: "",
          curCity: "",
          curCountry: "",
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  componentDidMount() {
    const { studentFillProfileList } = this.props;
    if (studentFillProfileList.isSave) {
      let cityNew = [];
      this.props.studentFillProfileList.city.map((data) => {
        if (data.name === studentFillProfileList.applicant.permanentCity) {
          cityNew.push(data);
        }
      });

      let countryNew = [];
      this.props.studentFillProfileList.country.map((data) => {
        if (data.name === studentFillProfileList.applicant.country) {
          countryNew.push(data);
        }
      });

      let stateNew = [];
      this.props.studentFillProfileList.state.map((data) => {
        if (data.name === studentFillProfileList.applicant.permanentState) {
          stateNew.push(data);
        }
      });

      let cityNewTemp = [];
      this.props.studentFillProfileList.city.map((data) => {
        if (data.name === studentFillProfileList.applicant.temporaryCity) {
          cityNewTemp.push(data);
        }
      });

      let stateNewTemp = [];
      this.props.studentFillProfileList.state.map((data) => {
        if (data.name === studentFillProfileList.applicant.temporaryState) {
          stateNewTemp.push(data);
        }
      });

      let countryNewTemp = [];
      this.props.studentFillProfileList.country.map((data) => {
        if (data.name === studentFillProfileList.applicant.temporaryCountry) {
          countryNewTemp.push(data);
        }
      });

      this.setState({
        id: studentFillProfileList.applicant.id,
        perAddress1: studentFillProfileList.applicant.permanentAddressLine1,
        perAddress2: studentFillProfileList.applicant.permanentAddressLine2,
        city: cityNew.length !== 0 ? cityNew[0].id : "",
        district: studentFillProfileList.applicant.permanentDistrict,
        state: stateNew.length !== 0 ? stateNew[0].id : "",
        country: countryNew.length !== 0 ? countryNew[0].id : "",
        pin: studentFillProfileList.applicant.permanentPin,
        curAddress1: studentFillProfileList.applicant.temporaryAddressLine1,
        curAddress2: studentFillProfileList.applicant.temporaryAddressLine2,
        curpin: studentFillProfileList.applicant.temporaryPin,
        curCity: cityNewTemp.length !== 0 ? cityNewTemp[0].id : "",
        curDistrict: studentFillProfileList.applicant.temporaryDistrict,
        curState: stateNewTemp.length !== 0 ? stateNewTemp[0].id : "",
        curCountry: countryNewTemp.length !== 0 ? countryNewTemp[0].id : "",
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentFillProfileList !== nextProps.studentFillProfileList
    ) {
      if (
        this.props.studentFillProfileList.applicant !==
        nextProps.studentFillProfileList.applicant
      ) {
        this.setState({
          id: nextProps.studentFillProfileList.applicant.id,
          perAddress1:
            nextProps.studentFillProfileList.applicant.permanentAddressLine1,
          perAddress2:
            nextProps.studentFillProfileList.applicant.permanentAddressLine2,
          pin: nextProps.studentFillProfileList.applicant.permanentPin,
          city: nextProps.studentFillProfileList.applicant.permanentCity,
          district:
            nextProps.studentFillProfileList.applicant.permanentDistrict,
          state: nextProps.studentFillProfileList.applicant.permanentState,
          country: nextProps.studentFillProfileList.applicant.country,
          curAddress1:
            nextProps.studentFillProfileList.applicant.temporaryAddressLine1,
          curAddress2:
            nextProps.studentFillProfileList.applicant.temporaryAddressLine2,
          curpin: nextProps.studentFillProfileList.applicant.temporaryPin,
          curCity: nextProps.studentFillProfileList.applicant.temporaryCity,
          curDistrict:
            nextProps.studentFillProfileList.applicant.temporaryDistrict,
          curState: nextProps.studentFillProfileList.applicant.temporaryState,
        });
      } else if (
        this.props.studentFillProfileList.applicant !==
        nextProps.studentFillProfileList.applicant
      ) {
        this.setState({
          id: nextProps.studentFillProfileList.applicant.id,
        });
      }
    }
  }

  changeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  checkPincodeHandler = (event) => {
    const { pin, curpin } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (pin.length !== 6) {
      formIsValid = false;
      formErrors["pinErrorr"] = "Pincode should be 6 digit";
    }

    if (curpin !== null)
      if (curpin.length !== 6) {
        formIsValid = false;
        formErrors["curpinErrorr"] = "Pincode should be 6 digit";
      }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handleFormValidation() {
    const {  pin, curpin } = this.state;
    let formErrors = {};
    let formIsValid = true;

    // if (perAddress1.toString().trim() === "") {
    //   formIsValid = false;
    //   formErrors["perAddressErr"] = myConstClass.perAddressMsg;
    // }

    // if (pin.toString().trim() === "" || pin === null) {
    //   formIsValid = false;
    //   formErrors["pinErr"] = myConstClass.pinMsg;
    // }

    // if (curpin.toString().trim() === "" || curpin === null) {
    //   formIsValid = false;
    //   formErrors["currpinErr"] = myConstClass.pinMsg;
    // }



    if ( pin !== null || typeof pin !== 'undefined' || pin.trim() !== "" )
    {
      if ( pin.trim() !== "" && pin.length !== 6) {
        formIsValid = false;
        formErrors["pinErrorr"] = "Pincode should be 6 digit";
      }
    } 

    if ( curpin !== null || typeof curpin !== 'undefined' || curpin.trim() !== "" )
    { 
      if (curpin.trim() !== "" && curpin.length !== 6) {
        formIsValid = false;
        formErrors["curpinErrorr"] = "Pincode should be 6 digit";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      perAddress1,
      perAddress2,
      curAddress1,
      curAddress2,
      curpin,
      pin,
      district,
      city,
      state,
      country,
      curDistrict,
      curState,
      curCity,
      curCountry,
    } = this.state;

    let cityNew = [];
    this.props.studentFillProfileList.city.map((data) => {
      if (data.id === city) {
        cityNew.push(data);
      }
    });
    // console.log(cityNew);

    let countryNew = [];
    this.props.studentFillProfileList.country.map((data) => {
      if (data.id === country) {
        countryNew.push(data);
      }
    });
    // console.log(countryNew);

    let stateNew = [];
    this.props.studentFillProfileList.state.map((data) => {
      if (data.id === state) {
        stateNew.push(data);
      }
    });
    // console.log(stateNew);

    let cityNewTemp = [];
    this.props.studentFillProfileList.city.map((data) => {
      if (data.id === curCity) {
        cityNewTemp.push(data);
      }
    });
    // console.log(cityNewTemp);

    let stateNewTemp = [];
    this.props.studentFillProfileList.state.map((data) => {
      if (data.id === curState) {
        stateNewTemp.push(data);
      }
    });
    // console.log(stateNewTemp);

    let countryNewTemp = [];
    this.props.studentFillProfileList.country.map((data) => {
      if (data.id === curCountry) {
        countryNewTemp.push(data);
      }
    });
    // console.log(countryNewTemp);

    docs = {
      id: id,
      permanentAddressLine1: perAddress1?perAddress1:"",
      permanentAddressLine2: perAddress2?perAddress2:"",
      permanentPin: pin?pin:"",
      temporaryAddressLine1: curAddress1?curAddress1:"",
      temporaryAddressLine2: curAddress2?curAddress2:"",
      temporaryPin: curpin?curpin:"",
      type: 2,
      // permanentCity: cityNew[0].name,
      permanentCity:  cityNew[0] && cityNew[0].name ? cityNew[0].name : "",
      permanentState: stateNew[0] && stateNew[0].name ? stateNew[0].name:"", 
      permanentDistrict: district?district:"",
      temporaryCity: cityNewTemp[0] && cityNewTemp[0].name?cityNewTemp[0].name:"",
      temporaryDistrict: curDistrict?curDistrict:"",
      temporaryState: stateNewTemp[0]&&stateNewTemp[0].name?stateNewTemp[0].name:"",
      country: countryNew[0]&&countryNew[0].name?countryNew[0].name:"",
      temporaryCountry: countryNewTemp[0]&&countryNewTemp[0].name?countryNewTemp[0].name:"",
    };
    if (this.handleFormValidation()) {
    this.props.onSave(docs);
    }
  };

  render() {
    const {
      perAddress1,
      perAddress2,
      curAddress1,
      curAddress2,
      pin,
      curpin,
      district,
      city,
      state,
      country,
      curDistrict,
      curCity,
      curState,
      curCountry,
    } = this.state;
    const { perAddressErr, pinErrorr, curpinErrorr } = this.state.formErrors;
    const { onCancel, studentList, editForm } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid columnSpacing={1} container justifyContent="space-evenly">
              <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label="Permanent Address"
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ mt: 1 }}
                justifyContent="space-evenly"
              >
                {" "}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Address Line 1"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="perAddress1"
                    size="small"
                    value={perAddress1}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                    disabled={editForm ? true : false}
                    {...(perAddressErr
                      ? { error: true, helperText: perAddressErr }
                      : "")}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Address Line 2"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="perAddress2"
                    size="small"
                    value={perAddress2}
                    onChange={this.changeHandler}
                    disabled={editForm ? true : false}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs    :12, sm    :6, md    :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="pin"
                    size="small"
                    value={pin}
                    disabled={editForm ? true : false}
                    inputProps={{ maxLength: 6 }}
                    onChange={this.changeHandlerNumber}
                    // onBlur={this.checkPincodeHandler}
                    fullWidth
                    error={pinErrorr ? true : false}
                    helperText={pinErrorr ? pinErrorr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                {/* add country */}

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Country"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={country}
                    name={"country"}
                    options={this.props.studentFillProfileList?.country}
                    onChange={this.changeHandlerSearch}
                    filterOptions={this.filterOptions}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                {/* Add state */}

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={state}
                    name={"state"}
                    options={this.props.studentFillProfileList?.state.filter(
                      (data) => data.countryId === country
                    )}
                    onChange={this.changeHandlerSearch}
                    filterOptions={this.filterOptions}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="City"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={city}
                    name={"city"}
                    options={this.props.studentFillProfileList?.city.filter(
                      (data) => data.stateId === state
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.changeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="district"
                    size="small"
                    value={district}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={6}></Grid>
              <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label="Correspondence Address"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkForm"
                        id="checkForm"
                        value={1}
                        onChange={this.changeHandler}
                      />
                    }
                    label="Same as of Permanent Address"
                  />
                </FormGroup>
              </Grid>

              {/* <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ mt: 1 }}
                justifyContent="space-evenly"
              >
                {" "}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Address Line 1"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curAddress1"
                    size="small"
                    value={curAddress1}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                    // {...(curAddressErr
                    //   ? { error: true, helperText: curAddressErr }
                    //   : "")}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Address Line 2"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curAddress2"
                    size="small"
                    value={curAddress2}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curpin"
                    size="small"
                    value={curpin}
                    disabled={editForm ? true : false}
                    inputProps={{ maxLength: 6 }}
                    // onBlur={this.checkPincodeHandler}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    error={curpinErrorr ? true : false}
                    helperText={curpinErrorr ? curpinErrorr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                {/* Add country */}

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Country"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curCountry}
                    name={"curCountry"}
                    options={this.props.studentFillProfileList?.country}
                    onChange={this.changeHandlerSearch}
                    filterOptions={this.filterOptions}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curState}
                    name={"curState"}
                    options={this.props.studentFillProfileList?.state.filter(
                      (data) => data.countryId === curCountry
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.changeHandlerSearch}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="City"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curCity}
                    name={"curCity"}
                    options={this.props.studentFillProfileList?.city.filter(
                      (data) => data.stateId === curState
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.changeHandlerSearch}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                {/*  */}

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curDistrict"
                    size="small"
                    value={curDistrict}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={6}></Grid>

              <Grid
                sx={{ mt: 1 }}
                rowSpacing={2}
                container
                columnSpacing={1}
                justifyContent="right"
              >
                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const Navigation = (props) => {
  let navigate = useNavigate();
  return <StudAddress {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => ({
  studentList: state.studentList,
  studentFillProfileList: state.studentFillProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
