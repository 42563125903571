import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicTable";
import endpoint from "../../../../../config/endpoints";
import * as myConstClass from "../../../../../config/messageconstant";
import { getAcademicYear } from "../../../../../Pages/Masters/OnlineModule/AcademicYear/academicYear.slice";
import { apiDelete } from "../../../../../utils/api_service";
import { prnNumberJson } from "../../../../../DynamicFormsJson/PrnNumberTable";
import { getPrnNumber, resetValues } from "./PrnNumber.slice";
import PrnNumberForm from "./PrnNumberForm";

class PrnNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      division: "",
      yearDetail: "",
      semisterDetails: "",
      academicYear: "",
      popUpValue: false,
      open: false,
      showForm: false,
      showTable: true,
      showForm: false,
      formErrors: {},
      studentData: {},
      searchValue: "",
    };
  }

  rowEdit = (params) => {
    this.setState({
      popUpValue: true,
      open: true,
      studentData: params,
    });
  };

  onClose = () => {
    this.setState({
      popUpValue: false,
      open: false,
      studentData: {},
    });
  };

  //   columns = [
  //     { name: "index", title: "Sr. No." },
  //     { name: "name", title: "Student Name" },
  //     { name: "prnNo", title: "PRN Number" },
  //     { name: "rollNo", title: "Roll No" },
  //     { name: "dateOfReporting", title: "Reporting Date" },
  //     {
  //       name: "action",
  //       title: "Action",
  //       getCellValue: (params) => {
  //         const onClickEdit = (e) => {
  //           e.stopPropagation();
  //           this.rowEdit(params);
  //         };
  //         return (
  //           <div>
  //             <MyComponentWithIconProps
  //               statusImage={EditIcon}
  //               color="black"
  //               fontSize="medium"
  //               title="Edit"
  //               onClick={onClickEdit}
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //   ];

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",

      width: "10%",
    },
    {
      name: "prnNo",
      title: "PRN No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: "15%",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      canSearch: true,
      alignCenter: "left",
      width: "30%",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    {
      name: "yearDetails",
      title: "Year details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      width: "30%",
    },
  ];

  onAddButtonClick = () => {
    this.setState({
      showForm: true,
      showTable: false,
    });
  };
  backToTable = () => {
    const ay = localStorage.getItem("acadamicYearId");
    if (ay) {
      this.props.getPrnNumber({ id: ay }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }
    this.setState({
      showForm: false,
      showTable: true,
    });
  };

  componentWillMount() {
    const ay = localStorage.getItem("acadamicYearId");
    if (ay) {
      this.setState({
        showLoader: true,
      });
      this.props.getPrnNumber({ id: ay }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
  }

  rowDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoint.userDocumentDetail + "/" + data,
        }).then(({ success }) => {
          if (!success) {
            this.props.getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };

  handleFormValidation() {
    const { division, yearDetail, semisterDetails, academicYear } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (semisterDetails.toString().trim() === "" || semisterDetails === null) {
      formIsValid = false;
      formErrors["semisterDetailsError"] = myConstClass.semisterNameMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  save = () => {
    const { division, yearDetail, semisterDetails } = this.state;
    const { getAlert, getStudentList } = this.props;
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  // getFilteredTableData = () => {
  //   const { searchValue } = this.state;
  //   const { prnNumberList } = this.props;
  //   return prnNumberList?.prnNumber.filter((currentRow) => {
  //     let isValid = false;
  //     if (
  //       (currentRow["name"] &&
  //         currentRow["name"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase())) ||
  //       (currentRow["yearDetails"] &&
  //         currentRow["yearDetails"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase())) ||
  //       (currentRow["prnNo"] &&
  //         currentRow["prnNo"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase())) ||
  //       (currentRow["division"] &&
  //         currentRow["division"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase()))
  //     ) {
  //       isValid = true;
  //       return true;
  //     }
  //     return isValid;
  //   });
  // };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      division,
      yearDetail,
      semisterDetails,
      popUpValue,
      open,
      academicYear,
      showForm,
      showTable,
    } = this.state;
    const {
      divisionError,
      yearDetailError,
      semisterDetailsError,
      academicYearError,
    } = this.state.formErrors;
    const { academicYearList, prnNumberList } = this.props;
    return (
      <>
        {showTable && (
          <>
            {/* <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    sx={{ marginBottom: 2 }}
                    item
                    xs={12}
                    sm={11.5}
                    md={11.5}
                  >
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="PRN Number"
                    />
                  </Grid>
                  <Grid sx={{ marginBottom: 2 }} item xs={12} sm={0.5} md={0.5}>
                    <MyComponentWithIconProps
                      statusImage={AddCircleIcon}
                      color="primary"
                      fontSize="large"
                      title="Generate PRN Number"
                      onClick={this.changeFormHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                sx={{ marginTop: 1, marginBottom: 2 }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <SearchPrnList
                  onSearch={this.onSearch}
                  showEmpNotice={false}
                  showStudNotice={false}
                  showStudFeedback={false}
                  showEmpFeedback={false}
                  showEmpNoticeDetails={false}
                  showEmpFeedbackDetails={false}
                  showStudFeedbackDetails={false}
                  showStudNoticeDetails={false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <DynamicTable
                  data={this.getFilteredTableData()}
                  tableHead={this.columns}
                  showHeadDelete={true}
                  rowDelete={this.rowDelete}
                  showPegination={true}
                  isActionColActive={false}
                />
              </Grid> */}

            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={prnNumberJson.screenTitle}
              fieldMeta={[]}
              buttonCenter={prnNumberJson.buttonCenter}
              showPdfDownload={prnNumberJson.showPdfDownload}
              showExcelDownload={prnNumberJson.showExcelDownload}
              pdfFileName={prnNumberJson.pdfFileName}
              excelFileName={prnNumberJson.excelFileName}
              tableColumnsToFilter={[]}
              showAddButton={prnNumberJson.showAddButton}
              dynamicMasterData={[]}
              searchButton={false}
              isActionColActive={false}
              showLoader={this.state.showLoader}
              tableColumns={this.columns}
              tableData={this.props.prnNumberList?.prnNumber}
              // getListById={this.getListById}
              showPegination={false}
              showHeadEdit={false}
              showHeadDelete={false}
              // showPegination={false}
              // pdfDetailsId={this.pdfDetails}
              // rowViewData={this.onClickView}
              // rowDelete={this.rowDelete}
              // rowEdit={this.rowEdit}
              // onDelete={this.onDelete}
              // onSearchData={this.onSearchData}
              baseIdColumn={prnNumberJson.baseIdColumn}
              apiBaseURL={prnNumberJson.apiBaseURL}
            />
          </>
        )}
        <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <PrnNumberForm backToTable={this.backToTable} />
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  prnNumberList: state.prnNumber,
  academicYearList: state.academicYear,
});
const mapDispatchToProps = {
  getPrnNumber,
  getAlert,
  resetValues,
};
export default connect(mapStateToProps, mapDispatchToProps)(PrnNumber);
