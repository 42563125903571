import { FormHelperText, Grid, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { ButtonCompo } from '../../components/Comman/Button'
import { LabelCompo } from "../../components/Comman/Label";

let docs;
class FeesPayment extends React.Component
{
  constructor( props )
  {
    super( props );
  }
  state = {
    id: 0,
    paymentselection: "",
    paymentValid: "",
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  componentDidMount()
  {
    this.setState( {} );
  }

  submitHandler = ( e ) =>
  {
    e.preventDefault();
    const { id, paymentselection } = this.state;
    docs = {
      id: id,
      paymentselection: paymentselection,
    };

    if ( paymentselection !== "" )
    {
      console.log( docs );
      this.setState( {
        paymentValid: "",
      } );
    } else
    {
      this.setState( {
        paymentValid: "*Select Payment Mode",
      } );
    }
  };

  render()
  {
    const { paymentselection, paymentValid } = this.state;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid item xs={12} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Fees Payment"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} />
              <Grid item xs={12} sm={6} md={12} />
              <Grid item xs={12} sm={6} md={3}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label="Fees Mode :"
                />
                <FormHelperText error>
                  <h3 style={{ fontWeight: "normal" }}> {paymentValid}</h3>
                </FormHelperText>
              </Grid>

              <Grid m={-1} item xs={12} sm={12} md={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="UPI"
                    name="paymentselection"
                    value={paymentselection}
                    onChange={this.changeHandler}
                    row
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="ONLINE"
                    />
                    <FormControlLabel
                      value="upi"
                      control={<Radio />}
                      label="UPI"
                    />

                    <FormControlLabel
                      value="cash"
                      control={<Radio />}
                      label="CASH"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3} />

              <Grid item xs={12} sm={12} md={12}>
                {" "}
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Back"
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Save"
                    fullWidth={true}
                    onClick={this.submitHandler}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Print"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
export default FeesPayment;
