import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import AutoComplete from "../Comman/AutoComplete";
import { ButtonCompo } from "./Button";


class PortalInfo extends Component {
  constructor(props) {
    super(props);
  }

  onSeachButtonClick = () => {
  
    this.props.onSeachButtonClick();
  };

  render() {
    const {
      showPdfDownload = true,
      showExcelDownload = true,
      showSearchButton = true,
      showAutoComplete = true,
      autoCompleteName = "",
      onChange,
      options = [],
      value = "",
    } = this.props;
    return (
      <>
        <div>
          <br></br>
          <Paper sx={{  borderRadius: 4 }} elevation={0}>
            <Grid container spacing={3}>
              <Grid container item xs={12} md={4} lg={3}>
                <Grid container item md xs>
                  {showPdfDownload && (
                    <div style={{ padding: "5px" }}>
                      {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={pdfFileName}
                        type="pdf"
                      /> */}
                    </div>
                  )}
                  {showExcelDownload && (
                    <div style={{ padding: "5px" }}>
                      {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={excelFileName}
                        type="excel"
                      /> */}
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="flex-end"
                item
                xs={12}
                md={8}
                lg={9}
              >
                {showAutoComplete && (
                  <div style={{ padding: "1px" }}>
                    <AutoComplete
                      keyColName={"id"}
                      value={value}
                      name={autoCompleteName}
                      options={options}
                      onChange={onChange}
                      fullWidth={true}
                    />
                  </div>
                )}
                {showSearchButton && (
                  <div style={{ padding: "5px" }}>
                    <ButtonCompo
                      size="small"
                      type="button"
                      variant="outlined"
                      name=" Search "
                      // onClick={onClick}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}

export default PortalInfo;
