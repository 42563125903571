
    import endpoint from "../config/endpoints";
    
    export const roomJson = {
        "apiBaseURL":endpoint.room,
  "screenTitle": "Room",
  "showAddButton": true,
  "showPdfDownload": true,
  "pdfFileName": "Room",
  "showExcelDownload": true,
  "excelFileName": "Room",
  "tableColumnsToFilter": [
    {
      "columnDisplayName": "Floor Details",
      "columnKeyName": "floor",
      "isChecked": true
    },
    {
      "columnDisplayName": "Name",
      "columnKeyName": "name",
      "isChecked": true
    },
    {
      "columnDisplayName": "Room Capacity",
      "columnKeyName": "roomCapacity",
      "isChecked": true
    },
    {
      "columnDisplayName": "Exam Capacity",
      "columnKeyName": "examCapacity",
      "isChecked": true
    },
    {
      "columnDisplayName": "Status",
      "columnKeyName": "isActive",
      "isChecked": true
    }
  ],
  "fieldMeta": [
    {
      "label": "Floor",
      "controlType": "autocomplete",
      "placeHolder": "",
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "masterName": "floorDetail",
      "dataKey": "floor",
      "isRootLevelKey": false,
      isMandatory: true
    },
    {
      "label": "Name",
      "controlType": "textfield",
      "placeHolder": "",
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "dataKey": "name",
      isMandatory: true
    },
    {
      "label": "Room Capacity",
      "controlType": "textfield",
      "placeHolder": "",
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "dataKey": "roomCapacity",
      isMandatory: true
    },
    {
      "label": "Exam Capacity",
      "controlType": "textfield",
      "placeHolder": "",
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "dataKey": "examCapacity",
      isMandatory: true
    }
  ]
}
