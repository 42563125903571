import { Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";

import {
  onlyNumber,
  validEmail,
} from "../../components/Comman/Util/Validations";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiGet, apiGetWithoutHeader, apiPost } from "../../utils/api_service";
import {
  getAdmissionForm,
  resetError,
} from "../AdmissionForm/admissionForm.slice";
import { getAdmissionType } from "../Masters/Admission/AdmissionType/admissionType.slice";
import { getCurrentAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getProgram,
  getYearListByProgramId,
} from "../Masters/OnlineModule/ProgramMaster/program.slice";
import { getStudentById, getUniqueEmailId } from "./basicDetails.slice";

let docs;

class AdmissionFormPost extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    academicYear: "",
    program: "",
    progTitle: "",
    admissionYear: "",
    applicationId: "",
    admissionTypeId: "",
    academicYearId: "",
    applicationNumber: "",
    grnNo: "",
    prnNo: "",
    programId: "",
    programTitleId: "",
    admissionYearId: "",
    firstName: "",
    library: "",
    middleName: "",
    lastName: "",
    admissionDate: new Date(),
    dateOfBirth: "",
    studentEmail: "",
    studentMobile: "",
    formErrors: [],
    formErrorsLib: [],
    libraryUniqueError: "",
    libraryUniqueFlag: false,
    isLoading: false,
  };

  componentDidMount() {
    const { getCurrentAcademicYear, getProgram, getAdmissionType } = this.props;
    getCurrentAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getProgram().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getAdmissionType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    const { studentList } = this.props;

    if (studentList.isFetch) {
      this.setDataToTextField(studentList.admissionForm);
    }
    this.setState({
      firstName: "",
      middleName: "",
      library: "",
      lastName: "",
      dateOfBirth: new Date(),
      admissionDate: new Date(),
      studentEmail: "",
      studentMobile: "",
      programId: "",
      admissionYearId: "",
      library: "",
    });
  }

  setDataToTextField(data) {
    const DOBArr = data.dateOfBirth == null ? "" : data.dateOfBirth.split("-");
    const DOB =
      DOBArr == "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);
    if (data.length !== 0) {
      this.setState({
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        dateOfBirth: DOB,
        studentEmail: data.studentEmail,
        studentMobile: data.studentMobile,
      });
    } else {
      this.setState({
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: new Date(),
        admissionDate: new Date(),
        studentEmail: "",
        studentMobile: "",
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.admissionForm !==
        nextProps.studentList.admissionForm
      ) {
        this.setDataToTextField(nextProps.studentList.admissionForm);
      }
    }

    if (this.props.studentDetails !== nextProps.studentDetails) {
      if (
        this.props.studentDetails?.currentAcademicYear !==
        nextProps.studentDetails?.currentAcademicYear
      ) {
        this.setState({
          academicYearId: nextProps.studentDetails?.currentAcademicYear.id,
        });
      }
    }
  }
  changeDob = (date) => {
    if (date != null) {
      this.setState({
        dateOfBirth: date,
      });
    }
  };

  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
    {
      if (event.target.value === "" || onlyNumber.test(event.target.value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    }
  };
  changeAdmissionDate = (date) => {
    if (date != null) {
      this.setState({
        admissionDate: date,
      });
    } else {
      this.setState({
        admissionDate: "",
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  // http://localhost:8088/api/config/prelogin/otp/check-unique-email-id?emailId=vtkharat@gmail.com&baseUrl=13.233.230.164
  checkEmployeeEmail = (event) => {
    const { value } = event.target;
    const { getAlert, showNotification, getUniqueEmailId } = this.props;
    if (value != "" && validEmail.test(value)) {
      getUniqueEmailId({ emailId: value }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          if (data.data.error === false) {
            showNotification({
              msg: "Email Id Unqiue",
            });
            this.setState({ formErrors: [] });
          } else {
            let formErrors = {};
            formErrors["studentEmailError"] = myConstClass.emailInvalMsg;
            this.setState({ formErrors: formErrors, studentEmail: "" });
          }
        } else {
          getAlert({
            message: myConstClass.serverMsg,
          });
        }
      });
    }
  };

  changedHandlerApplication = (event) => {
    if (event.target.value !== "") {
      const { name, value } = event.target;
      this.setState({
        ...this.state,
        [name]: value,
      });
      const { showNotification, getAdmissionForm, getAlert, resetError } =
        this.props;
      this.setState({
        isLoading: true,
      });
      getAdmissionForm({
        applicationNumber: value,
        domainName: window.location.host,
      }).then((response) => {
        if (response !== null) {
          if (response.data.errorMessage.error == true) {
            showNotification({
              msg: "No Seat Allotted for applicationNumber " + value,
              severity: "error",
              // : "Data updated successfully",
            });
            // getAlert( {
            //   message: "No Seat Allotted for applicationNumber " + value,
            //   // : "Data updated successfully",
            // } );
            resetError();
            this.setState({
              firstName: "",
              middleName: "",
              library: "",
              lastName: "",
              dateOfBirth: new Date(),
              admissionDate: new Date(),
              studentEmail: "",
              studentMobile: "",
              programId: "",
              admissionYearId: "",
              library: "",
              isLoading: false,
            });
          } else {
            showNotification({
              msg: "Application Number Validate successfully",
            });
            let progId = this.props.programList?.program.filter(
              (row) =>
                row.id ==
                response.data.getUserRoundAllocationAndUserApplication
                  .programTitle.programType.program.id
            );
            let yearDetailId =
              response.data.getUserRoundAllocationAndUserApplication.yearDetail
                .id;
            this.setState({
              programId: progId[0].id,
              isLoading: false,
            });
            const { getYearListByProgramId } = this.props;
            getYearListByProgramId({ progId: progId[0].id }).then(
              (response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                  this.setState({
                    isLoading: false,
                  });
                } else {
                  let yearId = this.props.programList?.yearList.filter(
                    (row) => row.id == yearDetailId
                  );
                  this.setState({
                    admissionYearId: yearId[0].id,
                    isLoading: false,
                  });
                  apiGet({
                    url:
                      endpoints.studentList +
                      "/get-library-no?programTitleShortName=" +
                      yearId[0].courseCode.split(" ").join("").toUpperCase(),
                  }).then(({ data, success }) => {
                    console.log(data);
                    if (success) {
                      this.setState({
                        library: data,
                      });
                    }
                  });
                }
              }
            );
          }
        } else {
          showNotification({
            msg: "Application Number Invalid",
            severity: "error",
            // : "Data updated successfully",
          });
          this.setState({
            isLoading: false,
          });
        }
      });
    }
  };

  libraryNumberHandler = (data1) => {
    console.log(data1);
    let formErrorsLib = {};
    apiGet({
      url: endpoints.studentList + "/check-library-no?libraryNo=" + data1,
    }).then(({ data, sucess }) => {
      console.log(sucess);
      if (!data.error) {
        if (!data.data.error) {
          formErrorsLib["libraryError"] = myConstClass.libraryUniqueMsg;
          this.setState({
            formErrorsLib: formErrorsLib,
            libraryUniqueFlag: true,
          });
        } else {
          this.setState({ formErrorsLib: [], libraryUniqueFlag: false });

          this.props.showNotification({
            msg: "Library number is unique",
          });
        }
      }
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    console.log(newValue);
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name === "programId") {
        const { getYearListByProgramId } = this.props;
        this.setState({ admissionYearId: "" });
        getYearListByProgramId({ progId: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
      if (name === "admissionYearId") {
        const { programList } = this.props;
        let admissionList = programList?.yearList.filter(
          (data) => data.id === newValue
        );
        console.log(admissionList[0].progShortName.split(" ").join(""));
        apiGet({
          url:
            endpoints.studentList +
            "/get-library-no?programTitleShortName=" +
            admissionList[0].courseCode.split(" ").join("").toUpperCase(),
        }).then(({ data, success }) => {
          console.log(data);
          if (success) {
            this.setState({
              library: data,
            });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  onSave = (dataToSave) => {
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: endpoints.saveUserRegistration,
      postBody: dataToSave,
    }).then(({ data, success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.getStudentById({ studId: data.data.id }).then((res) => {
          if (res) {
            this.props.onSave(true);
          } else {
            this.props.getAlert({
              message: "Something went wrong ",
            });
          }
        });
      } else {
        this.setState({
          isLoading: false,
        });
        this.props.getAlert({
          message: "Something went wrong ",
        });
      }
    });
  };

  handleFormValidation(name) {
    const {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      admissionDate,
      studentEmail,
      studentMobile,
      programId,
      admissionTypeId,
      admissionYearId,
      library,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (firstName.toString().trim() === "") {
      formIsValid = false;
      formErrors["firstNameError"] = myConstClass.firstNameMsg;
    }

    if (library.toString().trim() === "") {
      formIsValid = false;
      formErrors["libraryError"] = myConstClass.libraryNoMsg;
    } else {
      console.log(this.state.library);
      this.libraryNumberHandler(this.state.library);
    }

    // if (lastName.toString().trim() === "") {
    //   formIsValid = false;
    //   formErrors["lastNameError"] = myConstClass.lastNameMsg;
    // }

    if (studentMobile.toString().trim() === "" || studentMobile.length != 10) {
      if (studentMobile === "") {
        formIsValid = false;
        formErrors["studentMobileError"] = myConstClass.contactNoMsg;
      } else if (!onlyNumber.test(studentMobile)) {
        formIsValid = false;
        formErrors["studentMobileError"] = myConstClass.contactNumberMsg;
      } else if (studentMobile.length > 0 && studentMobile.length != 10) {
        formIsValid = false;
        formErrors["studentMobileError"] = myConstClass.contactNoLengthMsg;
      }
    }

    if (
      studentEmail.toString().trim() === "" ||
      !validEmail.test(studentEmail)
    ) {
      if (studentEmail === "") {
        formIsValid = false;
        formErrors["studentEmailError"] = myConstClass.emailIdMsg;
      } else if (!validEmail.test(studentEmail)) {
        formIsValid = false;
        formErrors["studentEmailError"] = myConstClass.invalidEmailIdMsg;
      }
    }

    if (dateOfBirth.toString().trim() === "") {
      formIsValid = false;
      formErrors["dateOfBirthError"] = myConstClass.dobMsg;
    }

    if (programId.toString().trim() === "" || programId === null) {
      formIsValid = false;
      formErrors["programIdError"] = myConstClass.progNameMsg;
    }
    if (admissionYearId.toString().trim() === "") {
      formIsValid = false;
      formErrors["admissionYearIdError"] = myConstClass.admissionForYearMessage;
    }
    if (admissionTypeId.toString().trim() === "" || admissionTypeId === null) {
      formIsValid = false;
      formErrors["admissionTypeIdError"] = myConstClass.admissionTypeMsg;
    }
    console.log(admissionDate);
    if (
      admissionDate.toString().trim() === "" ||
      admissionDate === "Invalid Date"
    ) {
      formIsValid = false;
      formErrors["admissionDateError"] = myConstClass.admissionDateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }



  submitHandler = (e) => {
    e.preventDefault();

    const {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      admissionDate,
      studentEmail,
      studentMobile,
      applicationNumber,
      grnNo,
      prnNo,
      programId,
      admissionYearId,
      academicYearId,
      admissionTypeId,
      formErrors,
      library,
      libraryUniqueFlag,
    } = this.state;
    let newAdmissionDate = moment(admissionDate).format("DD-MM-YYYY");
    let newBirthDate = moment(dateOfBirth).format("DD-MM-YYYY");
    docs = {
      firstName: firstName.toUpperCase(),
      middleName: middleName !== "" ? middleName.toUpperCase() : "",
      lastName: lastName.toUpperCase(),
      dateOfBirth: newBirthDate,
      admissionDate: newAdmissionDate,
      studentEmail: studentEmail,
      studentMobile: studentMobile,
      applicationNumber: applicationNumber,
      grnNo: grnNo,
      prnNo: prnNo,
      programId: programId,
      admissionYearId: admissionYearId,
      admissionType: {
        id: admissionTypeId,
      },
      academicYearId: academicYearId,
      libraryNo: library,
    };
    console.log(formErrors);
    //http://localhost:8088/api/acdmc/v1/user-registration/exist-by-application-no?applicationNo
    if (this.handleFormValidation()) {
      if (!libraryUniqueFlag) {
        apiGet({
          url:
            endpoints.saveStudent +
            "/exist-by-application-no?applicationNo=" +
            applicationNumber,
        }).then(({ data, success }) => {
          console.log(data);
          if (!data.data.error) {
            swal({
              title: "Are you sure?",
              text: "Do you want to confirm Admission?",
              icon: "warning",
              buttons: ["Cancel", true],
            }).then((res) => {
              if (res) {
                if (this.handleFormValidation()) {
                  this.onSave(docs);
                }
              }
            });
          } else {
            this.props.showNotification({
              msg: data.data.message,
              severity: "error",
            });
          }
        });
      }
    }
  };
  changedHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  saveHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const {
      // academicYearId,
      applicationNumber,
      grnNo,
      prnNo,
      programId,
      admissionYearId,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      admissionDate,
      studentEmail,
      studentMobile,
      admissionTypeId,
      library,
      isLoading,
    } = this.state;
    const {
      firstNameError,
      // lastNameError,
      studentEmailError,
      studentMobileError,
      dateOfBirthError,
      admissionYearIdError,
      admissionTypeIdError,
      programIdError,
      admissionDateError,
      libraryUniqueError,
      libraryUniqueErrorFlag,
    } = this.state.formErrors;
    const { libraryError } = this.state.formErrorsLib;

    const { studentDetails, programList, onCancel, admissionTypeList } =
      this.props;

    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <form
              onSubmit={this.submitHandler}
              autoComplete="off"
              noValidate={true}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                sx={{ pl: 1 }}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Admission Form"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{ marginTop: 1 }}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Academic Year"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="academicYearId"
                      size="small"
                      value={studentDetails?.currentAcademicYear.name}
                      fullWidth
                      disabled
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{ marginTop: 1 }}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Application Number"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="applicationNumber"
                      size="small"
                      value={applicationNumber}
                      fullWidth
                      onBlur={this.changedHandlerApplication}
                      onChange={this.changeHandler}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  // rowSpacing={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="GRN Number"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="grnNo"
                      size="small"
                      value={grnNo}
                      onChange={this.changeHandler}
                      fullWidth
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  // rowSpacing={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="PRN Number"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="prnNo"
                      size="small"
                      value={prnNo}
                      onChange={this.changeHandler}
                      fullWidth
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  // rowSpacing={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Program"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={programId}
                      name={"programId"}
                      fullWidth
                      options={programList?.program.filter(
                        (data) => data.isActive == 1
                      )}
                      onChange={this.ChangeHandlerSearch}
                      isError={programIdError ? true : false}
                      errorText={programIdError ? programIdError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  // rowSpacing={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Admission for Year"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={admissionYearId}
                      name={"admissionYearId"}
                      fullWidth
                      options={programList?.yearList.filter(
                        (data) => data.isActive == 1
                      )}
                      onChange={this.ChangeHandlerSearch}
                      isError={admissionYearIdError ? true : false}
                      errorText={
                        admissionYearIdError ? admissionYearIdError : " "
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  // rowSpacing={2}
                  // spacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    // rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Admission Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={admissionTypeId}
                        name={"admissionTypeId"}
                        fullWidth
                        options={admissionTypeList?.admissionType.filter(
                          (data) => data.isActive == 1
                        )}
                        onChange={this.ChangeHandlerSearch}
                        isError={admissionTypeIdError ? true : false}
                        errorText={
                          admissionTypeIdError ? admissionTypeIdError : " "
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    // rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Admission Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="admissionDate"
                        size="small"
                        value={admissionDate}
                        fullWidth
                        onChange={this.changeAdmissionDate}
                        isError={admissionDateError ? true : false}
                        errorText={
                          admissionDateError ? admissionDateError : " "
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    // rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Library Number"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="library"
                        size="small"
                        value={library}
                        fullWidth
                        onChange={this.changeHandler}
                        onBlur={(e) => this.libraryNumberHandler(library)}
                        error={libraryError ? true : false}
                        helperText={libraryError ? libraryError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  // rowSpacing={2}
                  // spacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    // rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*First Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="firstName"
                        size="small"
                        value={firstName}
                        fullWidth
                        onChange={this.changeHandler}
                        // {...( firstNameError
                        //   ? { error: true, helperText: firstNameError }
                        //   : "" )}
                        error={firstNameError ? true : false}
                        helperText={firstNameError ? firstNameError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    // rowSpacing={2}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Middle Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="middleName"
                        size="small"
                        value={middleName}
                        fullWidth
                        onChange={this.changeHandler}
                        error={false}
                        helperText={" "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    // rowSpacing={2}
                    columnSpacing={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Last Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="lastName"
                        size="small"
                        value={lastName}
                        fullWidth
                        onChange={this.changeHandler}
                        // {...( lastNameError
                        //   ? { error: true, helperText: lastNameError }
                        //   : "" )}
                        // error={lastNameError ? true : false}
                        // helperText={lastNameError ? lastNameError : " "}
                        helperText={" "}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  // rowSpacing={2}
                  // spacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Date of Birth"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="dob"
                        size="small"
                        value={dateOfBirth}
                        fullWidth
                        maxDate={new Date()}
                        onChange={this.changeDob}
                        isError={dateOfBirthError ? true : false}
                        errorText={dateOfBirthError ? dateOfBirthError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Student Mobile"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="studentMobile"
                        size="small"
                        value={studentMobile}
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        onChange={this.changeHandlerNumber}
                        error={studentMobileError ? true : false}
                        helperText={
                          studentMobileError ? studentMobileError : " "
                        }
                        // error={studentMobileError ? true : false}
                        // helperText={
                        //   studentMobileError ? studentMobileError : " "
                        // }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    columnSpacing={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Student Email"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="studentEmail"
                        size="small"
                        value={studentEmail}
                        fullWidth
                        onChange={this.changeHandler}
                        onBlur={this.checkEmployeeEmail}
                        // {...( studentEmailError
                        //   ? { error: true, helperText: studentEmailError }
                        //   : "" )}
                        error={studentEmailError ? true : false}
                        helperText={studentEmailError ? studentEmailError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}></Grid>
                {/* <Grid
                  rowSpacing={2}
                  container
                  columnSpacing={1}
                  justifyContent="right"
                  m={2}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1.1}
                    lg={1.1}
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Cancel"
                      onClick={onCancel}
                    />
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={2}
                    lg={2}
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Submit"
                      onClick={this.submitHandler}
                    />
                  </Grid>
                </Grid> */}

                {/* ********* */}

                <Grid
                  rowSpacing={2}
                  container
                  columnSpacing={1}
                  justifyContent="right"
                >
                  <Grid item justifyContent="space-evenly">
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={onCancel}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item justifyContent="space-evenly">
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Submit"
                      fullWidth={true}
                      onClick={this.submitHandler}
                    />
                  </Grid>
                </Grid>
                {/*  */}
              </Grid>
            </form>
          </Paper>
          {isLoading && <Loading1 />}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentDetails: state.academicYear,
  programList: state.program,
  studentList: state.admissionForm,
  admissionTypeList: state.admissionType,
});
const mapDispatchToProps = {
  getCurrentAcademicYear,
  getProgram,
  getYearListByProgramId,
  getStudentById,
  getAdmissionForm,
  getAlert,
  resetError,
  showNotification,
  getAdmissionType,
  getUniqueEmailId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionFormPost);
