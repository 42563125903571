import { Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicForm from "../../../components/dynamicscreens/DynamicFormWithTable";
import { Loading1 } from "../../../components/Loading1";
import TeacherTable from "./TeacherTable";
import * as msgConstant from "../../../config/messageconstant";
import { teacherViewTableJson } from "../../../DynamicFormsJson/TeacherViewTable";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete } from "../../../utils/api_service";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getRecordsByFilters } from "../teachingPlan.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getBatch,
  getDivision,
  getStudentAttendanceBySubject,
  getStudentAttendanceBySubjectView,
  getStudentAttendanceList,
  getSubjectByDivision,
  getSubjectByYearDetailsId,
} from "../../StudentAttendance/StudentAttendanceForms/studentAttendance.slice";
import {
  getAttendanceYearDetails,
  getDivisionForAttendance,
  getSemesterDetails,
  getSubjectByDivisionAttendance,
} from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import TeacherActionView from "./TeacherActionView";
import endpoints from "../../../config/endpoints";
import TeacherViewAddForm from "./TeacherVIewAddForm";
import { getByIdTeacherPlan, resetValues } from "../teachingPlan.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class TeacherViewTable extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showSearch: true,
      showLoader: false,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      formErrors: {},
      rowDataForm: {},
      fromDate: "",
      toDate: "",
      showView: false,
      onEdit: false,
      tableData: {},
      showForm: true,
      dynamicMasterData: {
        yearDetailMaster: this.props.employeeWorkList?.attendanceYearDetails,
        semesterDetailMaster:
          this.props.employeeWorkList?.attendanceSemesterDetails,
        divisionMaster: this.props.employeeWorkList?.attendanceDivision,
        subjectMaster: this.props.employeeWorkList?.attendanceSubjects,
        batchMaster: this.props.studentAttendanceList?.batch,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.fieldData !== nextProps.fieldData) {
      let dataToSearch = {};
      dataToSearch = {
        ...nextProps.fieldData,
      };

      const semisterDetailId = localStorage.getItem("semisterDetailId");
      const yearDetailId = localStorage.getItem("yearDetailId");
      const divisionId = localStorage.getItem("divisionId");
      const divisionIds = localStorage.getItem("divisionIds");
      if (divisionIds && divisionIds !== "") {
        dataToSearch["divisionId"] = divisionIds.split(",");
      }
      if (divisionId) {
        dataToSearch["division"] = divisionId;
      }
      if (yearDetailId) {
        dataToSearch["yearDetail"] = yearDetailId;
      }
      if (semisterDetailId) {
        dataToSearch["semister"] = semisterDetailId;
      }

      teacherViewTableJson.fieldMeta.map((currentField) => {
        if (currentField.defaultValue) {
          dataToSearch[currentField.dataKey] = currentField.defaultValue;
        }
      });

      this.setState({
        fieldData: dataToSearch,
      });
    }
  }

  onSearchData = (dataToSearch) => {
    console.log(dataToSearch);
    this.setState({
      fromDate: dataToSearch.fromDate,
      toDate: dataToSearch.toDate,
      yearDetail: dataToSearch.yearDetail,
      semister: dataToSearch.semister,
      division: dataToSearch.division,
      batch: dataToSearch.batch,
      subject: dataToSearch.subject,

      showLoader: true,
    });
    const { getAlert } = this.props;
    this.props
      .getRecordsByFilters({
        semesterDetailId: dataToSearch.semister,
        subjectDetailId: dataToSearch.subject,
        batchId: dataToSearch.batch,
        divisionId: dataToSearch.division,
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  componentDidMount() {
    this.props.resetReducList();

    const dataToSearch = {};
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const ay = localStorage.getItem("acadamicYearId");

    const divisionIds = localStorage.getItem("divisionIds");
    if (divisionIds && divisionIds !== "") {
      dataToSearch["divisionId"] = divisionIds.split(",");
    }
    if (divisionId) {
      dataToSearch["division"] = divisionId;
    }
    if (yearDetailId) {
      dataToSearch["yearDetail"] = yearDetailId;
    }
    if (semisterDetailId) {
      dataToSearch["semister"] = semisterDetailId;
    }

    teacherViewTableJson.fieldMeta.map((currentField) => {
      if (currentField.defaultValue) {
        dataToSearch[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldData: dataToSearch,
    });

    if (yearDetailId && ay &&yearDetailId!=null && ay!=null ) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: yearDetailId,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId&&semisterDetailId!=null ) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: semisterDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    this.props
      .getAttendanceYearDetails({ academicYear: ay })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });

    if (semisterDetailId  && divisionId &&semisterDetailId !=null && divisionId!=null ) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: semisterDetailId,
          division: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }
  toggleFormTableVisibility = () => {
    const { showForm, showTable, showSearch } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      // showSearch: !showSearch,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = {};
    let listError = false;
    teacherViewTableJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  // onAddButtonClick = () =>
  // {
  //   let fieldData = {};
  //   teacherViewTableJson.fieldMeta.map( ( currentField ) =>
  //   {
  //     if ( currentField.defaultValue )
  //     {
  //       fieldData[ currentField.dataKey ] = currentField.defaultValue;
  //     }
  //   } );

  //   this.setState( {
  //     fieldData: fieldData,
  //     currentOperationMode: OperationMode.Add,
  //   } );
  //   this.toggleFormTableVisibility();
  // };

  onAddButtonClick = () => {
    window.location.replace("/teacher-view-form");
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  // rowDelete = ( rowData ) =>
  // {
  //   swal( {
  //     title: "Are you sure?",
  //     text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   } ).then( ( willDelete ) =>
  //   {
  //     if ( willDelete )
  //     {
  //       this.onDelete( rowData );
  //     }
  //   } );
  // };

  rowDelete = (rowData) => {
    const { semister, batch, division, fromDate, toDate, subject } = this.state;
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoints.teachingPlan + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getRecordsByFilters({
                semesterDetailId: semister,
                subjectDetailId: subject,
                batchId: batch,
                divisionId: division,
                fromDate: fromDate,
                toDate: toDate,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  onFormSave = () => () => {
    const { fieldData } = this.state;
    const dataToSave = {};
    teacherViewTableJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "datepicker" &&
        fieldData[currentField.dataKey]
      ) {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = year + "-" + month + "-" + day;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    this.onSearchData(dataToSave);
  };
  onDelete = (rowdata) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getTableData();
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  checkValidationOnSubmit = (dataToSave, filter) => {
    if (this.mandatoryCheck(dataToSave, filter)) {
      return this.onSubmitCheck(dataToSave, filter);
    } else {
      return false;
    }
  };
  onSubmitCheck = (dataToSave, filter) => {
    let formValid = true;
    let formErrors = {};
    const dataList = filter.split(",");
    teacherViewTableJson.fieldMeta.forEach((currentField) => {
      const compareWithFieldLabel = dataList.filter(
        (item) => item === currentField.dataKey
      );
      if (
        compareWithFieldLabel.length !== 0 &&
        currentField.onSubmit === "compare"
      ) {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          switch (compareCond) {
            case "l":
              {
                if (
                  !(dataToSave[currentField.dataKey] < dataToSave[compareWith])
                ) {
                  isError = true;
                  condErrorText = "less than";
                }
              }
              break;
            case "le":
              {
                if (
                  !(dataToSave[currentField.dataKey] <= dataToSave[compareWith])
                ) {
                  isError = true;
                  condErrorText = "less than equal to";
                }
              }
              break;
            case "g":
              {
                if (
                  !(dataToSave[currentField.dataKey] > dataToSave[compareWith])
                ) {
                  isError = true;
                  condErrorText = "greater than";
                }
              }
              break;
            case "ge":
              {
                if (
                  !(dataToSave[currentField.dataKey] >= dataToSave[compareWith])
                ) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }
              }
              break;
            case "eq":
              {
                if (
                  !(dataToSave[currentField.label] === dataToSave[compareWith])
                ) {
                  isError = true;
                  condErrorText = "equal to";
                }
              }
              break;
          }

          if (isError) {
            const compareWithFieldLabel = teacherViewTableJson.fieldMeta.filter(
              (item) => item.dataKey === compareWith
            )[0].label;
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
              },
            };
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "firstName",
      // name: "name",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
      canSearch: true,
      keyName:"firstName,lastName,middleName",
      isFormat:true,
      type:1
    },
    {
      name: "date",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
    {
      name: "topicTaught",
      title: "Topic Taught",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  mandatoryCheck = (dataToSave, filter) => {
    let formValid = true;
    let formErrors = {};
    const dataList = filter.split(",");
    teacherViewTableJson.fieldMeta.forEach((currentField) => {
      const compareWithFieldLabel = dataList.filter(
        (item) => item === currentField.dataKey
      );
      if (compareWithFieldLabel.length !== 0 && currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (dataToSave[currentField.dataKey] === undefined ||
              dataToSave[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            (dataToSave[currentField.dataKey] === undefined ||
              dataToSave[currentField.dataKey] === null ||
              dataToSave[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceYearDetails !==
        nextProps.employeeWorkList?.attendanceYearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.employeeWorkList?.attendanceYearDetails,
          },
        });
      }
    }
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceSemesterDetails !==
        nextProps.employeeWorkList?.attendanceSemesterDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster:
              nextProps.employeeWorkList?.attendanceSemesterDetails,
          },
        });
      }
    }
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceDivision !==
        nextProps.employeeWorkList?.attendanceDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster: nextProps.employeeWorkList?.attendanceDivision,
          },
        });
      }

      if (
        this.props.employeeWorkList?.attendanceSubjects !==
        nextProps.employeeWorkList?.attendanceSubjects
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.employeeWorkList?.attendanceSubjects,
          },
        });
      }
    }

    if (this.props.studentAttendanceList !== nextProps.studentAttendanceList) {
      if (
        this.props.studentAttendanceList?.batch !==
        nextProps.studentAttendanceList?.batch
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchMaster: nextProps.studentAttendanceList?.batch,
          },
        });
      }
    }
  }

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: data.yearDetail,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: data.semister })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: data.semister,
          division: data.division,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister && data.batch) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSubjectByDivisionAttendance({
          semister: data.semister,
          division: data.division,
          batchId: data.batch,
          subjectDetailId: true,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const dataToSave = {
          id: rowData["id"],
        };

        const { tableColumns } = this.props;
        let fieldData = {};

        tableColumns.forEach((currentCol) => {
          const formDataKey = currentCol.formDataKey
            ? currentCol.formDataKey
            : currentCol.name;
          const tableDataKey = currentCol.tableDataKey
            ? currentCol.tableDataKey
            : currentCol.name;
          fieldData[formDataKey] = rowData[tableDataKey];
        });

        teacherViewTableJson.fieldMeta.forEach((currentField) => {
          if (
            currentField.controlType === "autocomplete" &&
            !currentField.isRootLevelKey
          ) {
            dataToSave[currentField.dataKey] = {};
            dataToSave[currentField.dataKey]["id"] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          } else {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        });

        dataToSave["isActive"] = rowData.isActive === 1 ? "0" : "1";
        this.setState({
          currentOperationMode: OperationMode.Status,
        });
        this.onSave(dataToSave, false);
      }
    });
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    tableColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
  };

  generatePDFDownload = () => {
    if (this.props.downLoadPdfFromApi) {
      if (
        this.checkValidationOnSubmit(this.state.fieldData, this.props.filter)
      ) {
        this.props.pdfDownload();
      }
    } else {
      this.generatePDF();
    }
  };

  rowView = (rowData) => {
    this.setState({
      showView: true,
      showForm: false,
      tableData: rowData,
    });
  };

  rowEdit = async(rowData) => {
    console.log(rowData);
    await this.props.getByIdTeacherPlan({ id: rowData.id });
    this.setState({
      onEdit: true,
      rowDataForm: rowData,
      showView: false,
      showForm: false,
    });
  };

  onCancelView = () => {
    // this.props.resetValues();
    this.setState({
      showView: false,
      showForm: true,
      onEdit: false,
    });
  };

  render() {
    const { teachingPlan } = this.props;
    const { fieldData, isLoading, dynamicMasterData, showTable, showView } =
      this.state;

    return (
      <>
        {this.state.showForm && (
          <>
            <LandingScreenHeader
              tableColumnsToFilter={[]}
              onRTSelectMultipleChange={this.onRTSelectMultipleChange}
              onAddButtonClick={this.onAddButtonClick}
              onSearch={this.onSearchData}
              screenTitle={teacherViewTableJson.screenTitle}
              showPdfDownload={false}
              generatePDF={this.generatePDFDownload}
              showExcelDownload={false}
              generateExcel={this.generateExcel}
              showAddButton={true}
              showTable={showTable}
              showSearch={true}
            />
            <br />
            {/* <Alert msg={"hi"} /> */}
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
              {/* <NewLandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={screenTitle}
            showPdfDownload={showPdfDownload}
            generatePDF={this.generatePDF}
            showExcelDownload={showExcelDownload}
            generateExcel={this.generateExcel}
            showAddButton={showAddButton}
            onAddButtonClick={this.props.onAddButtonClick}
            showTable={showTable}
          /> */}
              {showTable && (
                <>
                  <DynamicForm
                    fieldMeta={teacherViewTableJson.fieldMeta}
                    dynamicMasterData={dynamicMasterData}
                    fieldData={fieldData}
                    formErrorsShows={this.state.formErrors}
                    onDataChange={this.onDataChange}
                    onSave={this.onFormSave()}
                    buttonCenter={teacherViewTableJson.buttonCenter}
                    searchButton={teacherViewTableJson.searchButton}
                    searchAlign={teacherViewTableJson.searchAlign}
                    marginTop={teacherViewTableJson.marginTop}
                  />
                  <br />
                  {/* <MaterialUIRTTable
                data={this.getFilteredTableData()}
                tableHead={tableColumns}
                showHeadDelete={showHeadDelete}
                rowDelete={rowDelete}
                rowViewData={rowViewData}
                showPegination={showPegination}
                showView={showView}
                rowStatus={rowStatus}
                statusChange={statusChange}
                rowEdit={rowEdit}
                showHeadEdit={showHeadEdit}
                rowId={rowId}
                showHeadAdd={showHeadAdd}
                generateDetails={generateDetails}
                showLinkIcon={showLinkIcon}
                showHeadExcel={showHeadExcel}
                excelDetailsId={excelDetailsId}
                rowView={rowView}
                marginTop={marginTopTable}
                showApply={showApply}
                showRoundDetails={showRoundDetails}
                showRoundDetailsNew={showRoundDetailsNew}
                rounds={rounds}
                isActionColActive={isActionColActive}
                rowFeesPaid={rowFeesPaid}
                condition={condition}
                rowAppliedList={rowAppliedList}
                rowPaymentDetails={rowPaymentDetails}
                showPayFees={showPayFees}
                showPaymentDetails={showPaymentDetails}
                showAppliedList={showAppliedList}
                showDetails={showDetails}
                showSchoolIcon={showSchoolIcon}
                showPersonAdd={showPersonAdd}
                rowDetails={rowDetails}
                rowSchoolDetails={rowSchoolDetails}
                rowPersonAddDetails={rowPersonAddDetails}
                showHeadPdf={showHeadPdf}
                pdfDetailsId={pdfDetailsId}
                rowFillMarks={rowFillMarks}
                showFillMarks={showFillMarks}
              /> */}
                  <TeacherTable
                    tableHead={this.columns}
                    rowDelete={this.rowDelete}
                    rowEdit={this.rowEdit}
                    data={this.props.teachingPlan?.teacherRecords}
                    rowView={this.rowView}
                    showPegination={false}
                  />
                </>
              )}
            </Paper>
          </>
        )}

        {showView && (
          <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
            <TeacherActionView
              rows={
                Object.keys(teachingPlan?.syllabus).length !== 0
                  ? teachingPlan?.syllabus.details
                  : []
              }
              onCancelView={this.onCancelView}
              tableData={this.state.tableData}
            />
          </Paper>
        )}

        {this.state.onEdit && (
          <TeacherViewAddForm
            rowDataForm={this.state.rowDataForm}
            onCancelView={this.onCancelView}
          />
        )}
        {this.state.showLoader && <Loading1 />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearDetails: state.internshipDeclaration,
  semisterList: state.rollNo,
  studentAttendanceList: state.studentAttendance,
  employeeWorkList: state.employeeWorkload,
  teachingPlan: state.teachingPlan,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getSubjectByYearDetailsId,
  getDivision,
  getSubjectByDivision,
  getStudentAttendanceBySubject,
  getStudentAttendanceBySubjectView,

  resetReducList,
  getStudentAttendanceList,
  getBatch,
  getAttendanceYearDetails,
  getSemesterDetails,
  getDivisionForAttendance,
  getSubjectByDivisionAttendance,
  getRecordsByFilters,
  getByIdTeacherPlan,
  resetValues,
};
export default connect(mapStateToProps, mapDispatchToProps)(TeacherViewTable);
