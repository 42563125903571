import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import MultipleSelect from "../../../../components/Comman/MultipleSelect";
import { showNotification } from "../../../../Pages/Landing/Landing.slice";
import RTTable from "../../../../components/Comman/RT/RTTable";
import {
  onlyNumber,
  validEmail,
  textOnly,
  textOnlyWithSpaces,
} from "../../../../components/Comman/Util/Validations";
import * as myConstClass from "../../../../config/messageconstant";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { getDepartmentByuserId } from "../../Internship/Department/department.slice";
import { apiGet } from "../../../../utils/api_service";
class CompanyForm extends Component {
  state = {
    id: 0,
    contactpersonId: 0,
    index: 0,
    companyName: "",
    personName: "",
    contactNo: "",
    emailId: "",
    type: "",
    address: "",
    isActive: 1,
    formErrors: [],
    department: [],
  };
  columns = [
    {
      name: "index",
      title: "Sr. No.",
    },
    {
      name: "personName",
      title: "Company Person Name",
    },
    {
      name: "contactNo",
      title: "Contact",
    },
    {
      name: "emailId",
      title: "Email Id",
    },

    {
      name: "action",
      title: "Actions",
    },
  ];

  onClickDelete = (params) => {
    this.props.rowDelete(params.index, params.id);
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    if (name === "contactNo") {
      if (onlyNumber.test(value) || value === "") {
        if (
          value === "0" ||
          value === "1" ||
          value === "2" ||
          value === "3" ||
          value === "4" ||
          value === "5"
        ) {
          this.setState({
            ...this.state,
            [name]: "",
          });
        } else {
          this.setState({
            ...this.state,
            [name]: value,
          });
        }
      }
    } else if (name === "personName") {
      // if (textOnlyWithSpaces.test(value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
      // }
      //  else {
      //   this.setState({
      //     ...this.state,
      //     [name]: "",
      //   });
      // }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  handleFormValidation() {
    const { companyName, type, address, department } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (companyName.toString().trim() === "") {
      formIsValid = false;
      formErrors["companyNameError"] = myConstClass.companyNameMsg;
    }
    if (type.toString().trim() === "") {
      formIsValid = false;
      formErrors["typeError"] = myConstClass.typeMsg;
    }
    if (address.toString().trim() === "") {
      formIsValid = false;
      formErrors["addressError"] = myConstClass.companyAddressMsg;
    }

    if (!department.length) {
      formIsValid = false;
      formErrors["departmentListError"] = myConstClass.departmentListMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidation1() {
    const { personName, contactNo, emailId } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (personName === "") {
      formIsValid = false;
      formErrors["contactNameError"] = myConstClass.contactNameMsg;
    }

    if (contactNo.length > 0 && contactNo.length <= 10) {
      // if (contactNo === "") {
      //   formIsValid = false;
      //   formErrors["contactError"] = myConstClass.contactNoMsg;
      // } else
      if (!onlyNumber.test(contactNo)) {
        formIsValid = false;
        formErrors["contactError"] = myConstClass.contactNumberMsg;
      } else if (contactNo.length > 0 && contactNo.length != 10) {
        formIsValid = false;
        formErrors["contactError"] = myConstClass.contactNoLengthMsg;
      }
    }

    // if (!validEmail.test(emailId)) {
    if (emailId !== "") {
      //   formIsValid = false;
      //   formErrors["emailIdError"] = myConstClass.emailIdMsg;
      // } else
      if (!validEmail.test(emailId)) {
        formIsValid = false;
        formErrors["emailIdError"] = myConstClass.invalidEmailIdMsg;
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    const { editCompany, getDepartmentByuserId, getAlert } = this.props;
    if (Object.keys(editCompany).length != 0) {
      this.setState({
        id: editCompany.id,
        companyName: editCompany.name,
        address: editCompany.address,
        type: editCompany.type,
        department: editCompany.departments
          ? editCompany.departments.split(",")
          : [],
      });
    }
    getDepartmentByuserId().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.editRows !== nextProps.editRows) {
      this.setState({
        contactpersonId: nextProps.editRows.id,
        personName: nextProps.editRows.personName,
        contactNo: nextProps.editRows.contactNo,
        emailId: nextProps.editRows.emailId,
      });
    }
    if (this.props.editCompany !== nextProps.editCompany) {
      this.setState({
        id: nextProps.editCompany.id,
        companyName: nextProps.editCompany.name,
        address: nextProps.editCompany.address,
        type: nextProps.editCompany.type,
        department: nextProps.editCompany.departments
          ? nextProps.editCompany.departments.split(",")
          : [],
      });
    }
    if (nextProps.saveandNext) {
      this.setState({
        id: 0,
        contactpersonId: 0,
        index: 0,
        companyName: "",
        personName: "",
        contactNo: "",
        emailId: "",
        type: "",
        address: "",
        isActive: 1,
        department: [],
        formErrors: [],
      });
      this.props.resetSaveAndNext();
    }
  }

  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const { companyName, id, address, type, isActive, department } = this.state;

    let company = {
      address: address,
      name: companyName,
      type: type,
      emailId: "-",
      departments: department.join(),
      isActive: isActive,
    };
    if (id != 0) {
      company = {
        ...company,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.props.qualificationData(company, true);
    }
  };

  onSaveAndNext = (e) => {
    e.preventDefault();

    const { companyName, id, type, address, isActive, department } = this.state;

    let company = {
      address: address,
      name: companyName,
      departments: department.join(),
      type: type,
      emailId: "-",
      isActive: isActive,
    };
    if (id != 0) {
      company = {
        ...company,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.props.onSaveAndNext(company, false);
    }
  };

  checkEmployeeEmail = (event) => {
    const { name, value } = event.target;

    const { getAlert, showNotification, editCompany } = this.props;
    if (name === "contactNo") {
      if (this.state.contactpersonId == "") {
        if (value != "") {
          apiGet({
            url:
              "/api/placement/v1/company-contact-person/check-unique-contact-no?contactNo=" +
              value +
              "&id=",
          }).then(({ data, success }) => {
            if (data.data.error) {
              showNotification({
                msg: "Please enter unique contact number",
                severity: "error",
              });

              this.setState({
                ...this.state,
                [name]: "",
              });
            }
          });
        }
      } else {
        apiGet({
          url:
            "/api/placement/v1/company-contact-person/check-unique-contact-no?contactNo=" +
            value +
            "&id=" +
            this.state.contactpersonId,
        }).then(({ data, success }) => {
          console.log(data);
          if (data.data.error) {
            showNotification({
              msg: "Please enter unique contact number",
              severity: "error",
            });

            this.setState({
              ...this.state,
              [name]: "",
            });
          }
        });
      }
    } else if (name === "emailId") {
      if (value != "") {
        apiGet({
          url: "/api/acdmc/v1/email?email=" + value,
        }).then(({ data, success }) => {
          if (success) {
            if (data.data.error) {
              showNotification({ msg: data.data.message });
              this.setState({
                ...this.state,
                [name]: "",
              });
            }
          }
        });
      }
    }
  };

  saveHandler = (e) => {
    e.preventDefault();
    const { personName, contactNo, index, emailId, contactpersonId } =
      this.state;

    // const local = [".com", ".net", ".org", ".gov"];
    // console.log("AKASh");
    // const list = local.map((data) => {
    //   if(emailId.includes(data)){

    //   }

    // });

    let data = {
      index: index,
      personName: personName,
      contactNo: contactNo,
      emailId: emailId,
    };
    console.log(data);
    if (contactpersonId !== 0) {
      data = {
        ...data,
        id: contactpersonId,
      };
    }
    if (this.handleFormValidation1()) {
      this.props.rowData(data);
      this.setState({
        personName: "",
        contactNo: "",
        emailId: "",
        contactpersonId: 0,
      });
      console.log(data);
    }
  };

  onClickEdit = (editData) => {
    console.log(editData);
    this.setState({
      contactpersonId: editData.id,
      index: editData.index,
      personName: editData.personName,
      contactNo: editData.contactNo,
      emailId: editData.emailId,
    });
  };

  render() {
    const {
      companyName,
      personName,
      contactNo,
      emailId,
      type,
      address,
      department,
    } = this.state;

    const {
      companyNameError,
      contactNameError,
      contactError,
      emailIdError,
      addressError,
      typeError,
      departmentListError,
    } = this.state.formErrors;

    const { row, rowView, rowDelete, onCancel } = this.props;

    return (
      <>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                xs={12}
                md={12}
                size="small"
                container
                justifyContent="flex-end"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="outlined"
                  name=" Back to List"
                  onClick={onCancel}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="companyName"
                      size="small"
                      value={companyName}
                      fullWidth
                      onChange={this.changedHandler}
                      error={companyNameError ? true : false}
                      helperText={companyNameError ? companyNameError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Address"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="address"
                      size="small"
                      value={address}
                      fullWidth
                      onChange={this.changedHandler}
                      error={addressError ? true : false}
                      helperText={addressError ? addressError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Department "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MultipleSelect
                    er={departmentListError}
                    name1="department"
                    value1={department}
                    names={this.props.departmentList?.departmentByUserId}
                    onChange={this.multiSelectHandler}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Type "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="type"
                      id="type"
                      value={type}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                      error={typeError ? true : false}
                    >
                      <MenuItem value="0">Placement</MenuItem>
                      <MenuItem value="1">Internship</MenuItem>
                      <MenuItem value="2">Both</MenuItem>
                    </Select>
                  </FormControl>

                  <FormHelperText error>
                    &ensp;&ensp; {typeError ? typeError : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 10, marginBottom: 25 }} />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Contact Person Name "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="personName"
                      size="small"
                      value={personName}
                      fullWidth
                      onChange={this.changedHandler}
                      error={contactNameError ? true : false}
                      helperText={contactNameError ? contactNameError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Contact "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="contactNo"
                      size="small"
                      value={contactNo}
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      onBlur={this.checkEmployeeEmail}
                      onChange={this.changedHandler}
                      error={contactError ? true : false}
                      helperText={contactError ? contactError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Email Id "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="emailId"
                      size="small"
                      value={emailId}
                      fullWidth
                      onChange={this.changedHandler}
                      onBlur={this.checkEmployeeEmail}
                      error={emailIdError ? true : false}
                      helperText={emailIdError ? emailIdError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                rowSpacing={2}
                container
                columnSpacing={1}
                justifyContent="right"
              >
                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name={
                      Object.keys(this.props.editCompany).length != 0
                        ? "Edit"
                        : "Add"
                    }
                    fullWidth={true}
                    onClick={this.saveHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <RTTable
                    columns={this.columns}
                    tableData={row}
                    rowDelete={this.onClickDelete}
                    showEditIcon={true}
                    rowEdit={this.onClickEdit}
                  />
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save"
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1.7}
                    lg={1.7}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Add Another Company"
                      onClick={this.onSaveAndNext}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1.1}
                    lg={1.1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={onCancel}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* </Grid> */}
            {/* </Grid> */}
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  departmentList: state.department,
});
const mapDispatchToProps = {
  getDepartmentByuserId,
  showNotification,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
