import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import EmaterialCommentView from "./EmaterialStudentCommentView";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class EmaterialStudentCommentDialogue extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    open: false,
    close: false,
  };

  componentDidMount() {
    this.setState({
      open: true,
    });
  }

  changedHandler = (event) => {};
  changeFromDate = (date) => {};
  submitHandler = (e) => {};
  changeHandler = (event) => {};

  handleFormValidation() {}

  handleClose = () => {
    this.props.onClose();
  };

  changedHandler = (event) => {};

  render() {
    const { open } = this.state;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Add Comment
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <EmaterialCommentView
                commentData={this.props.commentData}
                backToList={this.props.backToList}
              />
            </DialogContent>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmaterialStudentCommentDialogue);
