import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import SubjectScheduleForm from "./SubjectScheduleForm";
import TreeView from "../../../components/TreeView";
import { LabelCompo } from "../../../components/Comman/Label";
import { apiPost } from "../../../utils/api_service";
import { getSyllabus } from "../teachingPlan.slice";
import endpoint from "../../../config/endpoints";
import { ButtonCompo } from "../../../components/Comman/Button";
const DisableElevation = ({
  onCancelForm,
  SubjectInfoList,
  teachingPlanList,
  subjectDetail,
  getSyllabus,
}) => {
  const [open, setOpen] = React.useState(false);
  const [moduleList, setModuleList] = React.useState([]);
  console.log(teachingPlanList);
  React.useEffect(() => {
    if (Object.keys(teachingPlanList?.syllabus).length !== 0) {
      setModuleList(teachingPlanList?.syllabus.details);
    } else {
      setModuleList([]);
    }
  }, []);

  console.log(subjectDetail);
  return (
    <>
      <Paper sx={{ p: 1, pt: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          xs={12}
          sm={12}
          md={12}
        >
          <Grid
            container
            justifyContent="flex-end"
            direction="row"
            xs={12}
            sm={12}
            md={12}
          >
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Back to List"
              onClick={onCancelForm}
            />
          </Grid>

          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={3}
            xs={12}
            sm={6}
            md={6}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Subject Code : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={SubjectInfoList?.subjectById.code}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Subject : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={SubjectInfoList?.subjectById.name}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Pattern : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={SubjectInfoList?.subjectById.patternName}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Semester Name : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={SubjectInfoList?.subjectById.semisterName}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={6}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Subject Type Name : "}
              />
            </Grid>
            <Grid item xs={12} sm={10} md={10}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={SubjectInfoList?.subjectById.subjectTypeName}
              />
            </Grid>
          </Grid>
          {/*  */}
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={6}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Scheme Name : "}
              />
            </Grid>
            <Grid item xs={12} sm={10} md={10}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={subjectDetail.name}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <SubjectScheduleForm
          data={moduleList}
          onCancelForm={onCancelForm}
          subjectDetail={subjectDetail}
        />
        {/* <TreeView/> */}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  SubjectInfoList: state.subjectInfo,
  teachingPlanList: state.teachingPlan,
});
const mapDispatchToProps = {
  getSyllabus,
};
export default connect(mapStateToProps, mapDispatchToProps)(DisableElevation);
