import { Grid, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import education from "../Images/education.png";
import { ButtonCompo } from './Comman/Button'
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",
   
    left: '39.17%',
right: '39.17%',
top: '79.7%',
bottom: '14.8%',
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "43.42%",
    right: "43.33%",
    top: "57%",
    bottom: "39%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
})
const ErrorPage = ({ classes }) => {
  return (
    <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
      <Grid container justifyContent="space-evenly" direction="row">
      <Grid item sm={4} xs={4} md={4} lg={4}/>
            <Grid item sm={4} xs={4} md={4} lg={4}>
       PRN Number is invalid
       </Grid>
       <Grid item sm={4} xs={4} md={4} lg={4}/>
      </Grid>
    </Paper>
  );
};
export default withStyles(styles)(ErrorPage);
