import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicForm from "../../../components/dynamicscreens/DynamicFormWithTable";
import { ExamMarksFillJson } from "../../../DynamicFormsJson/ExamMarksFill";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../utils/api_service";
import ExamMarksFillTable from "./ExamMarksFillTable";
import {
  getExamFormDeclare,
  getStudentBySemisterAndExamDeclare,
  resetStudList,
  getExamdeclareGetSubject,
} from "../../ExamFormDeclaration/examform.slice";
import endpoints from "../../../config/endpoints";
import { ButtonCompo } from "../../../components/Comman/Button";
import {resetReducList} from '../../../CommonActions/User.action'
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showSearch: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      flag:false,
      formErrors: {},
      fromDate: "",
      toDate: "",
      dynamicMasterData: {
        examDeclareMaster: this.props.examDeclareList?.examFormDeclare,
        subjectMaster: this.props.subjectsList.subjectBysemisterDetail,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.examDeclareList !== nextProps.examDeclareList) {
      if (
        this.props.examDeclareList.examDeclareGetSubject !==
        nextProps.examDeclareList.examDeclareGetSubject
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster:
              nextProps.examDeclareList.examDeclareGetSubject.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }
    if (this.props.examDeclareList !== nextProps.examDeclareList) {
      if (
        this.props.examDeclareList.examFormDeclare !==
        nextProps.examDeclareList.examFormDeclare
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            examDeclareMaster: nextProps.examDeclareList.examFormDeclare.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }
  componentDidMount() {
    this.props.resetReducList()
    this.props.getExamFormDeclare({ flag: true }).then((resp) => {
      if (!resp) {
        this.props.getAlert({
          message: "Server failed",
        });
      }
    });
  }
  toggleFormTableVisibility = () => {
    const { showForm, showTable, showSearch } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      // showSearch: !showSearch,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    let fieldData = {};
    if (fieldName === "examDeclare") {
      this.props
        .getExamdeclareGetSubject({ examDeclareId: newValue })
        .then((resp) => {
          if (!resp) {
            this.props.getAlert({
              message: "Server failed",
            });
          }
        });
    }
    fieldData[fieldName] = newValue;
    this.setState({
      fieldData: { ...this.state.fieldData, ...fieldData },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    ExamMarksFillJson.fieldMeta.map((currentField) => {
      if (currentField.defaultValue) {
        fieldData[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };
  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onFormSave = () => () => {
    const { fieldData } = this.state;

    const dataToSave = {};
    ExamMarksFillJson.fieldMeta.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = year + "-" + month + "-" + day;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
  
    this.props.resetStudList()
    this.props
      .getStudentBySemisterAndExamDeclare({
        examDeclareId: dataToSave.examDeclare,
        subjectDetailId: dataToSave.subject,
      })
      .then((resp) => {
        if (!resp) {
          this.props.getAlert({
            message: "Server failed",
          });
        }
        else{
          this.setState({
            flag:true
          })
        }
      });
  };
  onDelete = (rowdata) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getTableData();
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  resetFlag=()=>{
    this.setState({
      flag:false
    })
  }
  submitHandler = (studentList) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to submit marks?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        const studentMarks = studentList.map((stud) => {
          const studentData = {
            id: stud.id,
            subjectDetail: {
              id: this.state.fieldData.subject,
            },
            grade: '-',
            marks: stud.marks,
            result: stud.result,
            isActive: 1,
          };
          return studentData;
        });
        console.log(studentList);

        apiPost({
          url: endpoints.studentUpdateMarks,
          postBody: studentMarks,
        }).then(({ data, success }) => {
          if (!success) {
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.props.resetReducList()
            this.props.getExamFormDeclare({ flag: true }).then((resp) => {
              if (!resp) {
                this.props.getAlert({
                  message: "Server failed",
                });
              }
            });
            this.setState({
              fieldData:{},
              flag:true
            })

          }
        });
      }
    });
  };
  render() {
    const { dynamicMasterData, fieldData, isLoading, showTable } = this.state;

    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          onAddButtonClick={false}
          onSearch={this.onSearch}
          screenTitle={ExamMarksFillJson.screenTitle}
          showPdfDownload={ExamMarksFillJson.showPdfDownload}
          // generatePDF={downLoadPdfFromApi ? pdfDownload : this.generatePDF}
          generatePDF={this.generatePDFDownload}
          showExcelDownload={ExamMarksFillJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={false}
          showTable={showTable}
          showSearch={ExamMarksFillJson.showSearch}
        />
        <br />
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          {showTable && (
            <>
              <DynamicForm
                fieldMeta={ExamMarksFillJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                formErrorsShows={this.state.formErrors}
                onDataChange={this.onDataChange}
                onSave={this.onFormSave()}
                buttonCenter={ExamMarksFillJson.buttonCenter}
                searchButton={ExamMarksFillJson.searchButton}
                searchAlign={ExamMarksFillJson.searchAlign}
                marginTop={ExamMarksFillJson.marginTop}
              />
              <br />
              <ExamMarksFillTable
                row={this.props.examDeclareList.studentList}
                submitData={this.submitHandler}
                flag={this.state.flag}
                resetFlag={this.resetFlag}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subjectsList: state.subjectInfo,
  examDeclareList: state.examDeclare,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getExamdeclareGetSubject,
  getExamFormDeclare,
  getStudentBySemisterAndExamDeclare,
  resetReducList,
  resetStudList
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
