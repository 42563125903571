export const StudentTransactionDetailsDynamicJson = {
    screenTitle: "Student Transaction Details",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter:false,
    getAcadamicYear:true,
    // getListId:'yearDetail',
    fieldMeta: [
      {
        label: "Year Detail",
        controlType: "autocomplete",
        placeHolder: "",
        md: 4,
        lg: 4,
        sm: 4,
        xs: 12,
        masterName: "yearDetailMaster",
        // getListId:'yearDetail',
        dataKey: "admissionDetail",
        isMandatory: true,
      }
    ],
  };
  