import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { ExternalEventReportJson } from "../../DynamicFormsJson/ExternalEventReport";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAlert } from "../../CommonActions/alert.slice";

class ExternalEventReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        academicYear: this.props.academicYearList?.academicYear,
        yearDetailMaster: this.props.internshipDeclarationList?.yearDetails,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  rowData = [
    {
      index: 1,
      reportName: "Event Organised - Summary Report (D0, D1)",
      filter: "fromDate,toDate",
      url: "/api/report/v1/event-report-by-date?fromDate={fromDate}&toDate={toDate}",
    },
    // {
    //     index: 2,
    //     reportName: "Event Report By Event ID",
    //     filter: 'eventId',
    //     url: '/api/report/v1/event-report?eventId={eventId}'
    // },
    {
      index: 2,
      reportName: "External Events Report (D2, D3, D4)",
      filter: "academicYearId,yearDetailId,divisionId",
      url: "/api/report/v1/student-external-event-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionId={divisionId}",
    },
  ];

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }

    if (this.props.yearDetailsList !== nextProps.yearDetailsList) {
      if (
        this.props.yearDetailsList.yearDetails !==
        nextProps.yearDetailsList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.yearDetailsList.yearDetails,
          },
        });
      }
    }

    if (
      this.props.yearDetailsList.distinctDivision !==
      nextProps.yearDetailsList.distinctDivision
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          divisionMaster: nextProps.yearDetailsList.distinctDivision,
        },
      });
    }
  }

  getListById = (data) => {
    if (data.yearDetailId) {
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetailId })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={ExternalEventReportJson.screenTitle}
          fieldMeta={ExternalEventReportJson.fieldMeta}
          buttonCenter={ExternalEventReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={ExternalEventReportJson.searchButton}
          PDFButton={ExternalEventReportJson.PDFButton}
          EXCELButton={ExternalEventReportJson.EXCELButton}
          baseIdColumn={ExternalEventReportJson.baseIdColumn}
          apiBaseURL={ExternalEventReportJson.apiBaseURL}
          marginTop={ExternalEventReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  yearDetailsList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  getAcademicYear,
  getYearDetails,
  getDistinctDivision,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalEventReport);
