import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  feesStructureHeader: [],
  isFeesStructureHeaderFetch: false,
  feesStructureHeaderById: {},
  isFeesStructureHeaderFetchById: false,
};
let URL = endpoints.feesSturctureHead;
const feeStructureHeadSlice = createSlice({
  name: "feestructureHead",
  initialState,
  reducers: {
    feeStructureHeaderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        feesStructureHeader: row,
        isFeesStructureHeaderFetch: true,
      };
    },
    feesStructureReset: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
    feeStructureHeaderByIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { data } = action.payload;
      return {
        ...state,
        feesStructureHeaderById: data,
        isFeesStructureHeaderFetchById: true,
      };
    },
  },
});

export const {
  feeStructureHeaderSuccess,
  feeStructureHeaderByIdSuccess,
  feesStructureReset,
} = feeStructureHeadSlice.actions;

export default feeStructureHeadSlice.reducer;

export const getFeeStructureHeader = () => async (dispatch) => {
  try {
    const response=apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name + " - " + data1.totalFees ,
            isActive: data1.isActive,
            minimumFees: data1.minimumFees,
            // programTitleId: data1.programTitleId,
            // programTitleName: data1.getProgramTitleId.brName,
            yearDetailName:
              data1.getYearDetail === null
                ? ""
                : data1.getYearDetail.year.className +
                  "  -  " +
                  data1.getYearDetail.programTitle.brName,
            academicYearName:
              data1.getAcademicYearId === null
                ? ""
                : data1.getAcademicYearId.name,
            categoryName:
              data1.getCategoryId === null ? "" : data1.getCategoryId.name,
            scholarship: data1.scholarship === 0 ? "No" : "Yes",
            totalFees: data1.totalFees,
            academicYearId: data1.academicYearId,
            categoryId: data1.categoryId,
            feesStructureDetails: data1.feesStructureDetails.map((data) => {
              let feeStructureHead = {
                id: data.id,
                amt: data.amt,
                feesHeadId: data.feesHead.id,
                feesHeadName: data.feesHead.name,
                fromFeesId: data.fromFees.id,
                fromFeesName: data.fromFees.name,
              };
              return feeStructureHead;
            }),
          };
          row.push(bData);
          return data1;
        });
        dispatch(feeStructureHeaderSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};

export const getFeeStructureHeaderByAY = ({academicYearId}) => async (dispatch) => {
  try {
    const response=apiGet({
      url: URL + '/' + academicYearId + '/get-fees-structure-by-academic-year',
    }).then(({ data, success }) => {
      if (success) {
        const  content  = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name + " - " + data1.totalFees ,
            isActive: data1.isActive,
            minimumFees: data1.minimumFees,
            // programTitleId: data1.programTitleId,
            // programTitleName: data1.getProgramTitleId.brName,
            yearDetailName:
              data1.getYearDetail === null
                ? ""
                : data1.getYearDetail.year.className +
                  "  -  " +
                  data1.getYearDetail.programTitle.brName,
            academicYearName:
              data1.getAcademicYearId === null
                ? ""
                : data1.getAcademicYearId.name,
            categoryName:
              data1.getCategoryId === null ? "" : data1.getCategoryId.name,
            scholarship: data1.scholarship === 0 ? "No" : "Yes",
            totalFees: data1.totalFees,
            academicYearId: data1.academicYearId,
            categoryId: data1.categoryId,
            feesStructureDetails: data1.feesStructureDetails.map((data) => {
              let feeStructureHead = {
                id: data.id,
                amt: data.amt,
                feesHeadId: data.feesHead.id,
                feesHeadName: data.feesHead.name,
                fromFeesId: data.fromFees.id,
                fromFeesName: data.fromFees.name,
              };
              return feeStructureHead;
            }),
          };
          row.push(bData);
          return data1;
        });
        dispatch(feeStructureHeaderSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};

export const getFeesStructureHeadById =
  ({ feesStructureHeadId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + feesStructureHeadId,
      }).then(({ data, success }) => {
        if (success) {
          const feesStructureHeadData = data.data;
          console.log(feesStructureHeadData);
     
          let feesStructureHead = {
            id: feesStructureHeadData.id,
            name: feesStructureHeadData.name,
            minimumFees: feesStructureHeadData.minimumFees,
            totalFees: feesStructureHeadData.totalFees,
            scholarship: feesStructureHeadData.scholarship,
            yearDetailId: feesStructureHeadData.yearDetailId,
            categoryName:
              feesStructureHeadData.getCategoryId === null
                ? ""
                : feesStructureHeadData.getCategoryId.name,
            categoryId:
              feesStructureHeadData.getCategoryId === null
                ? ""
                : feesStructureHeadData.getCategoryId.id,
            acadamicName:
              feesStructureHeadData.getAcademicYearId === null
                ? ""
                : feesStructureHeadData.getAcademicYearId.name,
            acadamicId:
              feesStructureHeadData.getAcademicYearId === null
                ? ""
                : feesStructureHeadData.getAcademicYearId.id,
            isActive: feesStructureHeadData.isActive,
            feesStuctureDetail: feesStructureHeadData.feesStructureDetails.map(
              (feesStructureDetailData) => {
                let feesStuctureDetail = {
                  id: feesStructureDetailData.id,
                  value: feesStructureDetailData.amt,
                  feeHeadId: feesStructureDetailData.feesHead.id,
                  feeHeadName: feesStructureDetailData.feesHead.name,
                  fromFeesId: feesStructureDetailData.fromFees.id,
                  fromFeesName: feesStructureDetailData.fromFees.name,
                };
                return feesStuctureDetail;
              }
            ),
          };
          dispatch(feeStructureHeaderByIdSuccess({ data: feesStructureHead }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

  export const getFeesStructureHeadByHeaderId =
  ({ feesStructureHeadId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + feesStructureHeadId,
      }).then(({ data, success }) => {
        if (success) {
          const feesStructureHeadData = data.data;
          console.log(feesStructureHeadData);
     
          dispatch(feeStructureHeaderByIdSuccess({ data: feesStructureHeadData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetFeesStructure = () => async (dispatch) => {
  try {
    dispatch(feesStructureReset());
  } catch (e) {
    return console.error(e.message);
  }
};
