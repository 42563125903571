export const PlacementReportJson = {
  apiBaseURL:
    "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
  screenTitle: "Placement List Report",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  marginTop: 3,
  fieldMeta: [
    {
      label: "Academic Year",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "academicYear",
      dataKey: "academicYearId",
      isMandatory: true,
    },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearDetails",
      dataKey: "yearDetailId",
      isMandatory: true,
      getListId: "yearDetailId",
    },
    {
      label: "Division Single select",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "division",
      dataKey: "division",
      isMandatory: true,
    },
    {
      label: "Division Multiselect",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "divisionIds",
      dataKey: "divisionIds",
      isMandatory: true,
    },
    {
      label: "Company",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "company",
      dataKey: "companyId",
      isMandatory: true,
    },
    {
      label: "Placement Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "placementType",
      dataKey: "placementType",
      isMandatory: true,
    },
    {
      label: "Qualification Criteria",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "qualification",
      dataKey: "qualificationId",
      isMandatory: true,
    },
    {
      label: "Cut-off",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "cutoff",
      dataKey: "cutoff",
      isMandatory: true,
    },
  ],
};
