import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import AlumniAssociation from "../AlumniEngage/AlumniAssociation";
import AlumniCollaborator from "../AlumniEngage/AlumniCollaborator";
import AlumniDonor from "../AlumniEngage/AlumniDonor";
import AlumniInternship from "../AlumniEngage/AlumniInternship";
import AlumniMentor from "../AlumniEngage/AlumniMentor";
import AlumniPaper from "../AlumniEngage/AlumniPaper";
import AlumniResoursePerson from "../AlumniEngage/AlumniResoursePerson";
import AlumniTeacher from "../AlumniEngage/AlumniTeacher";
import AlumniVolunteer from "../AlumniEngage/AlumniVolunteer";

import { getAllStatus } from "../AdminSideAlimini/AlumniVolunteerStatus/AdminVolunteerStatus.slice";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AlumniEngagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "9",
      hidePaper: true,
      showFlag: false,
    };
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "type",
      title: "Apply as a",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "expertise",
      title: "Expertise",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },

    {
      name: "status",
      title: "Status",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
  ];

  componentDidMount() {
    this.props.getAllStatus().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  changeHandler = (event) => {
    const { type, hidePaper, alumniForm } = this.state;
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
    if (!hidePaper) {
      this.setState({
        showFlag: true,
      });
    }
  };

  onCancle = () => {
    this.setState({
      hidePaper: true,
      showFlag: false,
      type: "9",
    });
  };

  onAddButtonClick = () => {
    this.setState({
      hidePaper: false,
      type: "",
    });
  };
  onEdit = () => {
    this.setState({
      hidePaper: false,
    });
  };

  render() {
    const { type, hidePaper, showFlag } = this.state;
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={"View Engagements"}
          showPdfDownload={false}
          // generatePDF={this.generatePDF}
          showExcelDownload={false}
          showSearchBox={false}
          generateExcel={this.generateExcel}
          showAddButton={hidePaper}
          onAddButtonClick={this.onAddButtonClick}
          // onSearch={this.onSearch}
          showTable={hidePaper}
        />
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Engage"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl color={"primary"} fullWidth={true}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    className="text-black"
                    name="type"
                    id="type"
                    value={type}
                    onChange={this.changeHandler}
                    size="small"
                    fullWidth={true}
                  >
                    {hidePaper && <MenuItem value="9">All</MenuItem>}
                    <MenuItem value="0">Be a Mentor</MenuItem>
                    <MenuItem value="1">Be a Volunteer</MenuItem>
                    <MenuItem value="2">
                      Be a Alumnae Association Office Bearer
                    </MenuItem>
                    <MenuItem value="3">Be a Donor and Sponsor</MenuItem>
                    <MenuItem value="4">
                      Be a Paper Setter/ Evaluator/Examiner/Guide
                    </MenuItem>
                    <MenuItem value="5">Be a Resource Person</MenuItem>
                    <MenuItem value="6">Share Opportunity</MenuItem>
                    <MenuItem value="7">Be a Collaborator</MenuItem>
                    <MenuItem value="8">Be a Teacher</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText> </FormHelperText>
              </Grid>
            </Grid>
            {!hidePaper ? (
              <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
                <Grid
                  sx={{ marginTop: 2.5 }}
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  justifyContent="flex-end"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={this.onCancle}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={6}></Grid>
            )}
          </Grid>
          {type === "9" && (
            <DynamicTable
              data={this.props.volunteerList.alumnaAll}
              tableHead={this.columns}
              isActionColActive={false}
            />
          )}
          {type === "0" && (
            <AlumniMentor
              alumniForm={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
              showForm1={showFlag}
            />
          )}

          {type === "1" && (
            <AlumniVolunteer
              volunteerForm={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}

          {type === "2" && (
            <AlumniAssociation
              associationForm={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}

          {type === "3" && (
            <AlumniDonor
              donorForm={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}

          {type === "4" && (
            <AlumniPaper
              paperForm={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}

          {type === "5" && (
            <AlumniResoursePerson
              resoursePerson={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}

          {type === "6" && (
            <AlumniInternship
              opportunity={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}

          {type === "7" && (
            <AlumniCollaborator
              collaborator={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}

          {type === "8" && (
            <AlumniTeacher
              teacher={showFlag}
              onCancle={this.onCancle}
              onEdit={this.onEdit}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  volunteerList: state.volunteerStatus,
});
const mapDispatchToProps = {
  getAllStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlumniEngagement);
