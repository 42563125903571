export const InternshipListReportJson = {
    apiBaseURL:
        "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
    screenTitle: "Internship List Report",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    searchButton: false,
    PDFButton: true,
    EXCELButton: true,
    marginTop: 3,
    fieldMeta: [
        {
            label: "Academic Year",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "academicYear",
            dataKey: "academicYearId",
            isMandatory: true,
        },
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearDetails",
            dataKey: "yearDetailId",
            isMandatory: true,
            getListId: "yearDetailId"
        },
        {
            label: "Division",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "division",
            dataKey: "division",
            isMandatory: true,
        },
        {
            label: "Internship Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "internshipType",
            dataKey: "internshipType",
            isMandatory: true,
        },  
        {
            label: "Qualification Criteria",
            controlType: "multiSelect",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "qualification",
            dataKey: "qualificationId",
            isMandatory: true,
        },
        {
            "label": "Cut-off",
            "controlType": "textfield",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "masterName": "cutoff",
            "dataKey": "cutoff",
            "isMandatory": true
        },
        {
            label: "Student Status",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "studentStatus",
            dataKey: "studentStatus",
            isMandatory: true,
        },  

        {
            label: "To",
            controlType: "textfield",
            placeHolder: "Eg. The Manager BET",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "to",
            dataKey: "to",
            isMandatory: true,
        },  

        {
            label: "Date",
            controlType: "textfield",
            placeHolder: "Eg. 25th December, 2022",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "date",
            dataKey: "date",
            isMandatory: true,
        }, 


        {
            label: "Subject",
            controlType: "textfield",
            placeHolder: "Eg. Internship For B.C.A",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "subject",
            dataKey: "subject",
            isMandatory: true,
        }, 


        {
            label: "Student Name",
            controlType: "textfield",
            placeHolder: "Eg. Ekant Visave",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "studentName",
            dataKey: "studentName",
            isMandatory: true,
        }, 


        {
            label: "Class Name",
            controlType: "textfield",
            placeHolder: "Eg. BSC COMPUTER SCIENCE",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearDetailName",
            dataKey: "yearDetailName",
            isMandatory: true,
        }, 




        {
            label: "Duration",
            controlType: "textfield",
            placeHolder: "Eg. 1",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "duration",
            dataKey: "duration",
            isMandatory: true,
        }, 
        {
            label: "Rejoining Date",
            controlType: "textfield",
            placeHolder: "Eg. 1st February, 2022",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "rejoiningDate",
            dataKey: "rejoiningDate",
            isMandatory: true,
        }, 

        {
            label: "Placement Incharge",
            controlType: "textfield",
            placeHolder: "Eg. Mr. Vivek Gosavi",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "placementIncharge",
            dataKey: "placementIncharge",
            isMandatory: true,
        }, 

        {
            label: "Placement Officer",
            controlType: "textfield",
            placeHolder: "Eg. Mr. Akash Chavan",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "placementOfficer",
            dataKey: "placementOfficer",
            isMandatory: true,
        }, 

        {
            label: "Internship Campus Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "internType",
            dataKey: "internType",
            isMandatory: true,
          },
    ],
};
