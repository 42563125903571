
    import endpoint from "../config/endpoints";
    
    export const ExamFeesStructureJson = {
    "apiBaseURL": endpoint.examFeesStructure,
    "screenTitle": "Exam Fees Structure",
    "showAddButton": true,
    "showPdfDownload": true,
    "pdfFileName": "Exam Fees Structure",
    "showExcelDownload": true,
    "excelFileName": "Exam Fees Structure",
    "tableColumnsToFilter": [
        {
            "columnDisplayName": "Fees Title",
            "columnKeyName": "title",
            "isChecked": true
        },
        {
            "columnDisplayName": "Fees Type",
            "columnKeyName": "type",
            "isChecked": true
        },
        {
            "columnDisplayName": "Fixed Fees",
            "columnKeyName": "fixedFees",
            "isChecked": true
        },
        {
            "columnDisplayName": "Per Subject",
            "columnKeyName": "perSubject",
            "isChecked": true
        },
        {
            "columnDisplayName": "Max Cap Fees",
            "columnKeyName": "maxCapFees",
            "isChecked": true
        },
        {
            "columnDisplayName": "Status",
            "columnKeyName": "isActive",
            "isChecked": true
        }
    ],
    "baseIdColumn": "id",
    "fieldMeta": [
        {
            "label": "Fees Title",
            "controlType": "textfield",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "title",
            "isMandatory": true
        },
        {
            "label": "Fees Type",
            "controlType": "autocomplete",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "masterName": "type",
            "dataKey": "type",      
            "isRootLevelKey" : true,
            "isMandatory": true
        },
        {
            "label": "Fixed Fees",
            "controlType": "textfield",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "fixedFees",
            "isMandatory": true
        },
        {
            "label": "Per Subject",
            "controlType": "textfield",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "perSubject",
            "isMandatory": true
        },
        {
            "label": "Max Cap Fees",
            "controlType": "textfield",
            "placeHolder": " ",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "maxCapFees",
            "isMandatory": true
        },
        // {
        //     "label": "Carry Forward",
        //     "controlType": "radiogroup",
        //     "md": 6,
        //     "lg": 6,
        //     "sm": 6,
        //     "xs": 12,
        //     "dataKey": "isCarryForward",
        //     "defaultValue":1,
        //     "radioGroupItems": [
        //         {
        //             "label": "Yes",
        //             "value": 1
        //         },
        //         {
        //             "label": "No",
        //             "value": 0
        //         }
        //     ],
        //     "isRootLevelKey" : false,
        //     "isMandatory": true
        // }
    ]
}