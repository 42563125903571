import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet, apiPost } from "../../../utils/api_service";
const initialState = {
  studentBatch: [],
  isFetchStudentList: false,
};
let URL = endpoints.studentRollNo;
const studentBatchSlice = createSlice({
  name: "studentBatch",
  initialState,
  reducers: {
    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentBatch: row,
        isFetchStudentList: true,
      };
    },
    resetStudentListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        studentBatch: [],
        isFetchStudentList: false,
      };
    },
  },
});

export const { studentListSuccess, resetStudentListSuccess } =
  studentBatchSlice.actions;

export default studentBatchSlice.reducer;

// /api/acdmc/v1/reporting-detail/get-reporting-detail-list-by-academic-year-division-semister-detail?divisionId=c38d9cd6-3ade-4e34-8d4d-6deab307332f&semisterDetailId=673e30fc-777c-42fb-8fe7-e211f5f1a279

export const getStudentList =
  ({ semisterDetail, division }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-reporting-detail-list-by-academic-year-division-semister-detail?divisionId=" +
          division +
          "&semisterDetailId=" +
          semisterDetail,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              studentName:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
              lastName: data1.lastName,
              firstName: data1.firstName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,

              rollNo: data1.rollNo,
              prnNo: data1.prnNo,
            };
            return bData;
          });
          dispatch(studentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetStudent = () => async (dispatch) => {
  dispatch(resetStudentListSuccess());
};

export const saveStudentList =
  ({ studentBatch }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL,
        postBody: studentBatch,
      }).then(({ data, success }) => {
        if (success) {
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
