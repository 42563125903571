import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import AdmissionForm from "./Pages/AdmissionForm/index";
import AllowOutSideFeedback from "./Pages/AllowOutSideFeedback/AllowOutSideFeedback";
import AllowOutsideFeedbackEmployee from "./Pages/AllowOutSideFeedbackEmployee/AllowOutsideFeedbackEmployee";
import MainDashboard from "./Pages/Dashboard/MainDashboard";
import ForgotPasswordPostLogin from "./Pages/ForgotPasswordPostLogin/ForgotPasswordPostLogin";
import ForgotPasswordPreLogin from "./Pages/ForgotPasswordPreLogin/ForgotPasswordPreLogin";
import Landing from "./Pages/Landing/index";
import Login from "./Pages/Login/index";
import PrivateRoute from "./routes/index";
import AluminiRegister from "./Pages/Alumini/Alumini Register";
import AlumniForgotPassword from "./Pages/Alumini/Alumini Forgot Pasword";
import AlimuniBlock from "./Pages/Alumini/aluminiBlocked";
import ParentRegister from "./Pages/Parent/Parent Register/index";
import ParentForgotPassword from "./Pages/Parent/Parent Register/ForgotPassword/index";
import ForgotUsernamePrelogin from "./Pages/ForgotUsernamePrelogin/ForgotUsernamePrelogin";
import PaymentSuccessPagePrelogin from "./Pages/AccountFees/PaymentSuccessPagePrelogin";
// import AllNotification from "./Pages/AllNotifications/AllNotification";
const App = () => {
  const [flag, setFlag] = useState(true);
  return (
    <>
      {/* <Header/> */}
      <Routes>
      <Route path="/payment-success-prelogin" element={<PaymentSuccessPagePrelogin/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/admission-form" element={<AdmissionForm />} />
        <Route path="/alumna-register" element={<AluminiRegister />} />
        <Route path="/parent-register" element={<ParentRegister />} />
        <Route path="/alumni-block" element={<AlimuniBlock />} />
        <Route
          path="/outside-feedback-student"
          element={<AllowOutSideFeedback />}
        />
        <Route
          path="/outside-feedback-employee"
          element={<AllowOutsideFeedbackEmployee />}
        />

        <Route
          path="/forgot-password-prn"
          element={<ForgotPasswordPreLogin />}
        />
        <Route
          path="/forgot-username-prn"
          element={<ForgotUsernamePrelogin />}
        />

        <Route path="/resetpassword" element={<ForgotPasswordPostLogin />} />

        <Route path="/resetpassword" element={<ForgotPasswordPostLogin />} />
        <Route
          path="/parent-forgot-password"
          element={<ParentForgotPassword />}
        />
        <Route
          path="/alumni-forgot-password"
          element={<AlumniForgotPassword />}
        />

        {/* <Route path="/importantlinks" element={<ImportantLinks/>} /> */}
        <Route
          path="/*"
          element={
            <PrivateRoute>
              {!flag && <ErrorPage onCancel={setFlag} />}
              {flag && <Landing />}
            </PrivateRoute>
          }
        />

        {/* Private Route */}
        {/* <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Institute />
            </PrivateRoute>
          }
        />
        <Route
          path="/student"
          element={
            <PrivateRoute>
              <Landing />
            </PrivateRoute>
          }
        /> */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </>
  );
};

export default App;
