import React, { useState } from "react";
import "./Carousel.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Paper } from "@mui/material";
import { LabelCompo } from "../../../components/Comman/Label";
const Carousel = ({ ImageList, onClose }) => {
  const [currImg, setCurrImg] = useState(0);

  return (
    <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
      <div className="close">
        <ClearIcon style={{ fontSize: 30 }} onClick={onClose} />
      </div>

      <Grid sx={{marginBottom:1}} item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 26,fontWeight:'bold' }}
          label={ImageList[ currImg ].name}
          
              />
            </Grid>
      <div className="carousel" style={{ height: "670px", width: "970px"}}>
        <div
          className="carouselInner"
          style={{ backgroundImage: `url(${ImageList[currImg].profile})` }}
        >
          
          <div
            className="left"
            onClick={() => {
              currImg > 0 && setCurrImg(currImg - 1);
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: 30 }} />
          </div>
{/* 
          <div className="center">
            <p>{ImageList[currImg].name}</p>
          </div> */}
          <div
            className="right"
            onClick={() => {
              currImg < ImageList.length - 1 && setCurrImg(currImg + 1);
            }}
          >
            <ArrowForwardIosIcon style={{ fontSize: 30 }} />
          </div>
        </div>
      </div>

    

      {/* <div align='center'>
            <h1>{ImageList[currImg].title}</h1>
            <p><b></b></p>
          </div> */}
    </Paper>
  );
};

export default Carousel;
