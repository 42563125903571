import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  examFormDeclare: [],
  isFetch: false,

  examFormDeclareById: {},
  isFetchexamFormDeclareById: false,

  studentListStatus: [],
  isFetchStudentListStatus: false,
  studentList: [],
  isFetchStudentList: false,
  schemeHead: [],
  isFetchSchemeHead: false,

  subjectSelected: [],
  isFetchSubjectList: false,

  studentAppliedSubject: [],
  isFetchStudentApplied: false,

  examDeclareGetSubject: [],
  isFetchExamDeclareGetSubject: false,

  examDetailsStudent: [],
  isFetchExamDetails: false,
  //
  schemeHeadStudentExam: [],
  isFetchSchemeHeadExam: false,

  subjectSelectedStudentExam: [],
  isFetchSubjectListStudentExam: false,

  studentExamAppliedSubject: [],
  isFetchStudentExamApplied: false,

  scheduleDateList: [],
  isFetchScheduleDate: false,
};
let URL = endpoints.examFormDeclare;
let studentAppliedUrl = endpoints.studentApply;
let URL1 = endpoints.examSubject;
const examFormDeclareSlice = createSlice({
  name: "examFormSlice",
  initialState,
  reducers: {
    examDeclareSubjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        examDeclareSubject: row,
        isFetchExamDeclareSubject: true,
      };
    },
    examFormSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        examFormDeclare: row,
        isFetch: true,
      };
    },

    examFormByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;

      return {
        ...state,
        examFormDeclareById: row,
        isFetchexamFormDeclareById: true,
      };
    },

    studentListStatusSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;

      return {
        ...state,
        studentListStatus: row,
        isFetchStudentListStatus: true,
      };
    },
    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;

      return {
        ...state,
        studentList: row,
        isFetchStudentList: true,
      };
    },
    studentListFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        studentList: [],
        isFetchStudentList: false,
      };
    },
    schemeHeadSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        schemeHead: row,
        isFetchSchemeHead: true,
      };
    },

    schemeHeadStudentExamSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        schemeHeadStudentExam: row,
        isFetchSchemeHeadExam: true,
      };
    },

    subjectSelectedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectSelected: row,
        isFetchSubjectList: true,
      };
    },

    subjectSelectedStudentExamSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectSelectedStudentExam: row,
        isFetchSubjectListStudentExam: true,
      };
    },

    studentAppliedSubjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAppliedSubject: row,
        isFetchStudentApplied: true,
      };
    },

    studentExamAppliedSubjectSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentExamAppliedSubject: row,
        isFetchStudentExamApplied: true,
      };
    },

    examDeclareGetSubjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        examDeclareGetSubject: row,
        isFetchExamDeclareGetSubject: true,
      };
    },

    examDetailsStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        examDetailsStudent: row,
        isFetchExamDetails: true,
      };
    },
    scheduleDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        scheduleDateList: row,
        isFetchScheduleDate: true,
      };
    },
  },
});

export const {
  examDeclareSubjectSuccess,
  examDeclareGetSubjectSuccess,
  examFormSuccess,
  examFormByIdSuccess,
  studentListSuccess,
  studentListStatusSuccess,
  schemeHeadSuccess,
  subjectSelectedSuccess,
  studentAppliedSubjectSuccess,
  examDetailsStudentSuccess,
  studentListFail,
  schemeHeadStudentExamSuccess,
  subjectSelectedStudentExamSuccess,
  studentExamAppliedSubjectSuccess,
  scheduleDateSuccess,
} = examFormDeclareSlice.actions;

export default examFormDeclareSlice.reducer;

export const getExamFormDeclare =
  ({ flag = false }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: flag
          ? URL + '?sort={"insertdatetime": "DESC"}'
          : URL + '?sort={"insertdatetime": "DESC"}&offset=0&limit=20',
      }).then(({ data, success }) => {
        if (success) {
          const { content } = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.examTitle,
              yearDetails:
                data1.getYearDetail === null
                  ? ""
                  : data1.getYearDetail.programTitle.programType.name +
                    " - " +
                    data1.getYearDetail.programTitle.brName +
                    " - " +
                    data1.getYearDetail.year.className,
              semesterName: data1.semisterDetail.semister.name,
              examTitle: data1.examTitle,
              startDate: data1.startDate,
              endDate: data1.endDate,
              isActive: data1.isActive,
              examFeesStructure: data1.examFeesStructure.id,
              semisterDetail: data1.semisterDetail.id,
              showLable: data1.showLable,
              lableName: data1.lableName,
              yearId:
                data1.getYearDetail === null ? "" : data1.getYearDetail.id,
            };
            return bData;
          });
          dispatch(examFormSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExamFormDeclareBYUserId =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/for-admin?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              examTitle: data1.examTitle,
              startDate: data1.startDate,
              endDate: data1.endDate,
              declareResult: data1.declareResult,
              semisterDetailId: data1.semisterDetail.id,
              examFeesStructureId: data1.examFeesStructure.id,
              examFeesStructureFixedFees: data1.examFeesStructure.fixedFees,
              examFeesStructureMaxCapFees: data1.examFeesStructure.maxCapFees,
              examFeesStructurePerSubject: data1.examFeesStructure.perSubject,
              examFeesStructureType: data1.examFeesStructure.type,
            };
            return bData;
          });
          dispatch(examFormSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/exam-form-declare/by-from-to-date?fromDate=2022-02-01&toDate=2022-02-03

export const getExamdeclareFromDateToDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/by-from-to-date?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              yearDetails:
                data1.getYearDetail === null
                  ? ""
                  : data1.getYearDetail.programTitle.programType.name +
                    " - " +
                    data1.getYearDetail.programTitle.brName +
                    " - " +
                    data1.getYearDetail.year.className,
              semesterName: data1.semisterDetail.semister.name,
              examTitle: data1.examTitle,
              startDate: data1.startDate,
              endDate: data1.endDate,
              isActive: data1.isActive,
              examFeesStructure: data1.examFeesStructure.id,
              semisterDetail: data1.semisterDetail.id,
              showLable: data1.showLable,
              lableName: data1.lableName,
              yearId:
                data1.getYearDetail === null ? "" : data1.getYearDetail.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(examFormSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExamFormDeclareById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;

          index = index + 1;

          let bData = {
            index: index,
            id: data.data.id,
            yearDetails:
              data.data.getYearDetail === null
                ? ""
                : data.data.getYearDetail.programTitle.programType.name +
                  " - " +
                  data.data.getYearDetail.programTitle.brName +
                  " - " +
                  data.data.getYearDetail.year.className,
            semesterName: data.data.semisterDetail.semister.name,
            examTitle: data.data.examTitle,
            startDate: data.data.startDate,
            endDate: data.data.endDate,
            isActive: data.data.isActive,
            examFeesStructure: data.data.examFeesStructure.id,
            semisterDetail: data.data.semisterDetail.id,
            showLable: data.data.showLable,
            lableName: data.data.lableName,
          };

          dispatch(examFormByIdSuccess({ row: bData }));
          return bData;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentBySemisterAndExamDeclare =
  ({ examDeclareId, subjectDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentAppliedUrl +
          "/get-student-list-by-exam-declare-subject-detail?examDeclareId=" +
          examDeclareId +
          "&subjectDetailId=" +
          subjectDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const studentList = data.data;
          const studList = studentList.map((stduent, index) => {
            let bData = {
              index: index + 1,
              id: stduent.id,
              marks: stduent.marks === null ? "" : stduent.marks,
              exam_form_student_apply_id: stduent.marks,
              min_marks: stduent.min_marks,
              seat_no: stduent.seat_no,
              student_name: stduent.student_name,
              value: stduent.value,
              result:
                stduent.marks === null
                  ? ""
                  : +stduent.min_marks > +stduent.marks
                  ? "F"
                  : +stduent.value >= +stduent.marks
                  ? "P"
                  : stduent.marks,
              marksValid: 0,
            };
            return bData;
          });

          dispatch(studentListSuccess({ row: studList }));
          return studList;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentStatusList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentAppliedUrl +
          "/" +
          id +
          "/get-applied-student-list-by-exam-declare",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              seatNo: data1.seatNo,
              studentName:
                data1.student.firstName +
                " " +
                data1.student.middleName +
                " " +
                data1.student.lastName,
              name:
                data1.student.firstName +
                " " +
                data1.student.middleName +
                " " +
                data1.student.lastName,
              applicableFees: data1.applicableFees,
              amount: data1.amount,
              approvedStatus: data1.approvedStatus,
            };
            return bData;
          });
          dispatch(studentListStatusSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUpdatedStatus =
  ({ applyId, status }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentAppliedUrl +
          "/update-exam-form-status?applyId=" +
          applyId +
          "&status=" +
          status,
      }).then(({ data, success }) => {
        if (success) {
          // const content = data.data;
          // let index = 0;
          // const row = content.map( ( data1 ) =>
          // {
          //     index = index + 1;
          //     let bData = {
          //         index: index,
          //         id: data1.id,
          //         seatNo: data1.seatNo,
          //         studentName: data1.student.firstName + " " + data1.student.middleName + " " + data1.student.lastName,
          //         applicableFees: data1.applicableFees,
          //         amount: data1.amount,
          //         approvedStatus: data1.approvedStatus,
          //     };
          //     return bData;
          // } );
          // dispatch( studentListStatusSuccess( { row } ) );
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/exam-form-student-apply/get-scheme-head?applyId=48b6ad42-a1a9-4f49-9b1e-f23c60eab304

//  Get scheme Head
export const getSchemeHead =
  ({ applyId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: studentAppliedUrl + "/get-scheme-head?applyId=" + applyId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              shortName: data1.shortName,
              schemeId: data1.schemeId.id,
              schemeName: data1.schemeId.name,
              schemeShortName: data1.schemeId.shortName,
              isTeaching: data1.schemeId.isTeaching,
              isActive: data1.isActive,
              sortOrderNo: data1.sortOrderNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(schemeHeadSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/exam-form-student-apply/get-subject?applyId=b559350b-fc32-4484-8c46-128098de6992
export const getSubjectFromId =
  ({ applyId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: studentAppliedUrl + "/get-subject?applyId=" + applyId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              headName: data1.headName,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(subjectSelectedSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentAppliedSubject =
  ({ applyId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentAppliedUrl +
          "/get-exam-form-student-apply-subject?applyId=" +
          applyId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(studentAppliedSubjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExamdeclareGetSubject =
  ({ examDeclareId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-subject-by-declare-id?examDeclareId=" + examDeclareId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.subject.name + " - " + data1.schemeHeadId.name,
              subject: data1.subject.name,
              isActive: 1,
              subjectDetails: data1.schemeHeadId.name,
            };
            row.push(bData);
            return data1;
          });
          dispatch(examDeclareGetSubjectSuccess({ row }));
          return success;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExamdeclareSubject =
  ({ examDeclareId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/get-subject?examDeclareId=" + examDeclareId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            const durationForArr =
              data1.scheduleDate !== "" ? data1.scheduleDate.split("-") : "";
            const durationFor =
              durationForArr !== ""
                ? new Date(
                    durationForArr[2],
                    durationForArr[1] - 1,
                    durationForArr[0]
                  )
                : "";

            const fromtimeDate =
              data1.startTime !== ""
                ? moment(data1.startTime, ["h:mm A"]).format("HH:mm")
                : "";

            const fromTimeArr =
              fromtimeDate !== "" ? fromtimeDate.split(":", -1) : null;

            const fromTime =
              fromTimeArr !== null
                ? new Date(null, null, null, fromTimeArr[0], fromTimeArr[1])
                : null;

            const toTimeDate =
              data1.endTime !== ""
                ? moment(data1.endTime, ["h:mm A"]).format("HH:mm")
                : "";

            const toTimeArr =
              toTimeDate !== "" ? toTimeDate.split(":", -1) : null;

            var toTime =
              toTimeArr !== null
                ? new Date(null, null, null, toTimeArr[0], toTimeArr[1])
                : null;

            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              subject: data1.subjectName,
              subjectDetails: data1.headName,
              endTime: toTime,
              scheduleDate: durationFor,
              startTime: fromTime,
              examFormScheduleId: data1.examFormScheduleId,
              isChecked: true,
              scheduleDateErr: 0,
              startTimeErr: 0,
              endTimeErr: 0,
              //   subjectDetails: data1.subject.subjectDetails.map((doc) => {
              //     index = index + 1;
              //     let subjectData = {
              //       index: index,

              //       id: doc.schemeHeadId.id,
              //       schemeName: doc.schemeHeadId.name,
              //     //   startDate: "",
              //     //   startTime: "",
              //     //   toTime: "",
              //     };
              //     return subjectData;
              //   }),
              // examTitle: data1.examTitle,
              // startDate: data1.startDate,
              // endDate: data1.endDate,
              // isActive: data1.isActive,
              // examFeesStructure: data1.examFeesStructure.id,
              // semisterDetail: data1.semisterDetail.id,
              // showLable: data1.showLable,
              // lableName: data1.lableName,
              // yearId: data1.getYearDetail === null ? "" : data1.getYearDetail.id
            };
            row.push(bData);
            return data1;
          });
          dispatch(examDeclareSubjectSuccess({ row }));
          return success;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExamDetailForStudent =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/for-student?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              examTitle: data1.examTitle,
              startDate: data1.startDate,
              endDate: data1.endDate,
              declareResult: data1.declareResult,
              semesterDetailId: data1.semisterDetail.id,
              examFeesStructureId: data1.examFeesStructure.id,
              examFeesStructureFixedFees: data1.examFeesStructure.fixedFees,
              examFeesStructureMaxCapFees: data1.examFeesStructure.maxCapFees,
              examFeesStructurePerSubject: data1.examFeesStructure.perSubject,
              examFeesStructureType: data1.examFeesStructure.type,
            };
            return bData;
          });
          dispatch(examDetailsStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExamScheduleDate =
  ({ examDeclareId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + examDeclareId + "/by-exam-declare",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: moment(data1.scheduleDate, ["DD-MM-YYYY"]).format(
                "YYYY-MM-DD"
              ),
              name: data1.scheduleDate,
              subject: data1.subjectName,
              subjectDetailId:
                data1.subjectDetail === null ? "" : data1.subjectDetail.id,
              examFormDeclareId:
                data1.examFormDeclare === null ? "" : data1.examFormDeclare.id,
              endTime: data1.endTime,
              startTime: data1.startTime,
            };
            row.push(bData);
            console.log(row);
            return data1;
          });
          dispatch(scheduleDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// apis for student apply

// declare 0,1
// http://localhost:8088/api/acdmc/v1/exam-form-declare/get-scheme-head-for-student?semisterDetailId=1
export const getSchemeHeadStudentExam =
  ({ semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-scheme-head-for-student?semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              shortName: data1.shortName,
              schemeId: data1.schemeId.id,
              schemeName: data1.schemeId.name,
              schemeShortName: data1.schemeId.shortName,
              isTeaching: data1.schemeId.isTeaching,
              isActive: data1.isActive,
              sortOrderNo: data1.sortOrderNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(schemeHeadStudentExamSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/exam-form-declare/get-subject-for-student?semisterDetailId=1

export const getSubjectStudentExam =
  ({ semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-subject-for-student?semisterDetailId=" + semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
              shortName: data1.shortName,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(subjectSelectedStudentExamSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/exam-form-declare/get-subject-scheme-for-student?semisterDetailId=1

export const getStudentExamAppliedSubject =
  ({ semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-scheme-for-student?semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(studentExamAppliedSubjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// apis for student apply

// declare 2

export const getSchemeHeadStudentExamDeclare =
  ({ semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentAppliedUrl +
          "/get-scheme-head-for-student?semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              shortName: data1.shortName,
              schemeId: data1.schemeId.id,
              schemeName: data1.schemeId.name,
              schemeShortName: data1.schemeId.shortName,
              isTeaching: data1.schemeId.isTeaching,
              isActive: data1.isActive,
              sortOrderNo: data1.sortOrderNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(schemeHeadStudentExamSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/exam-form-declare/get-subject-for-student?semisterDetailId=1

export const getSubjectStudentExamDeclare =
  ({ semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentAppliedUrl +
          "/get-subject-for-student?semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(subjectSelectedStudentExamSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/exam-form-declare/get-subject-scheme-for-student?semisterDetailId=1

export const getStudentExamAppliedSubjectDeclare =
  ({ semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studentAppliedUrl +
          "/get-subject-scheme-for-student?semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
              name: data1.subjectName,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(studentExamAppliedSubjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetStudList = () => async (dispatch) => {
  try {
    dispatch(studentListFail());
  } catch (e) {
    return console.error(e.message);
  }
};
