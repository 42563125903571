import endpoint from "../config/endpoints";

export const ResourcePersonJson = {
    apiBaseURL: endpoint.resourcePerson,
    screenTitle: "Resource Person",
    showAddButton: true,
    baseIdColumn: "id",
    fieldMeta: [
        {
            label: "Title",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "pressTitle",
            isMandatory: true,
        },
        {
            label: "Details",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "publication",
            isMandatory: true,
        },
        {
            label: "Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "pressDate",
            // isMAxDate: false,
            // maxDate: new Date(),
            // isMinDate: true,
            // minDate: new Date(),
            isMandatory: true,
        },
        {
            label: "Location",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "confVenue",
            isMandatory: true,
        },
        {
            label: "Level",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "LevelMaster",
            dataKey: "level",
            isRootLevelKey: true,
            isMandatory: true
        },
        {
            label: " ",
            controlType: "hideTextfield",
            placeHolder: "",
            masterName: "LevelMaster",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "levelData",
            tableDataKey: 'level',
            open: 'other',
            error: 'Level'
        },
     
        {
            label: "Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "typeMaster",
            dataKey: "type",
            isRootLevelKey: true,
            isMandatory: true
        },
        {
            label: " ",
            controlType: "hideTextfield",
            placeHolder: "",
            masterName: "typeMaster",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "typeData",
            tableDataKey: 'type',
            open: 'other',
            error: 'Type'
        },
    ],
};
