import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { ExamApplyJson } from "../../../DynamicFormsJson/StudApplyForExam";
import { getStudentById } from "../../BasicDetailsPost/basicDetails.slice";
import {
  getStudentListByAcademicYear,
  getStudentListByPRN,
  getStudentListByStudName,
} from "../../ApplicantAdmission/applicantAdmission.slice";
import { getExamFormDeclareBYUserId } from "../../ExamFormDeclaration/examform.slice";

import StudentApplyForm from "./StudentApplyForm";
class AdminEditStudentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      openForm: true,
      studData: {},
      showLoader: false,
      dynamicMasterData: {
        academicYearData: this.props.academicYearList?.academicYear,
        searchByMaster: [
          { id: "academic", name: "Academic Year" },
          { id: "prn", name: "PRN" },
          { id: "studentName", name: "Student Name" },
        ],
      },
    };
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      title: "Student Name",
      name: "studentName",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      title: "PRN No.",
      name: "prnNo",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      title: "Contact Number",
      name: "studContact",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      title: "Email Id",
      name: "studEmail",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  editStudentInfo = (data) => {
    this.setState({
      openForm: false,
      studData: data,
      showLoader: true,
    });
    this.props
      .getExamFormDeclareBYUserId({ userRegistrationId: data.id })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  };

  componentDidMount() {
    this.props.resetReducList();
    this.props.resetReducList();
    const ay = localStorage.getItem("acadamicYearId");
    this.setState({
      showLoader: true,
    });
    this.props
      .getStudentListByAcademicYear({
        academicYearId: ay,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYearData: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
  }

  submitHandler = (data) => {
    // e.preventDefault();
    if (data.searchBy == "academic") {
      this.setState({
        showLoader: true,
      });
      const ay = localStorage.getItem("acadamicYearId");
      if (ay) {
        this.props
          .getStudentListByAcademicYear({
            academicYearId: ay,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
    } else if (data.searchBy == "prn") {
      this.setState({
        showLoader: true,
      });
      this.props
        .getStudentListByPRN({
          prnNo: data.prnNo,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      // }
    } else {
      this.setState({
        showLoader: true,
      });
      this.props
        .getStudentListByStudName({
          studName: data.studentName,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      // }
    }
  };
  onCancel = () => {
    this.setState({
      openForm: true,
      showLoader: false,
    });
  };
  render() {
    const { openForm } = this.state;

    return (
      <>
        {openForm ? (
          <>
            {/* <Paper sx={{ padding: 2, borderRadius: 2 }} elevation={0}> */}
            <DynamicMainScreen
              // onAddButtonClick={this.onAddButtonClick}
              screenTitle={ExamApplyJson.screenTitle}
              fieldMeta={ExamApplyJson.fieldMeta}
              buttonCenter={ExamApplyJson.buttonCenter}
              tableColumnsToFilter={ExamApplyJson.tableColumnsToFilter}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={this.columns}
              tableData={this.props.studentList?.studentList}
              showPegination={false}
              showApply={true}
              showPdfDownload={false}
              showExcelDownload={false}
              showAddButton={false}
              showLoader={this.state.showLoader}
              rowView={this.editStudentInfo}
              onSearchData={this.submitHandler}
            />
          </>
        ) : (
          <StudentApplyForm
            studData={this.state.studData}
            onCancel={this.onCancel}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  studentList: state.applicantAdmission,
});
const mapDispatchToProps = {
  getAlert,
  resetReducList,
  getStudentById,
  getStudentListByAcademicYear,
  getStudentListByPRN,
  getStudentListByStudName,
  getExamFormDeclareBYUserId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminEditStudentDetails);
