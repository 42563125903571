import { Grid, Link, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { Link as RouterLink } from "react-router-dom";
import { ViewStudAttendanceDynamicJson } from "../../../DynamicFormsJson/ViewStudAttendanceDynamicJson";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import endpoint from "../../../config/endpoints";
import { apiPost } from "../../../utils/api_service";
import moment from "moment";
import {
  getBatch,
  getDivision,
  getStudentAttendanceBySubject,
  getCompletedSubSection,
  getStudentAttendanceBySubjectView,
  getStudentAttendanceList,
  getSubjectByDivision,
  getSubjectByYearDetailsId,
  resetTableValues,
} from "../StudentAttendanceForms/studentAttendance.slice";
import swal from "sweetalert";
import { FilterSave } from "../../../components/FilterSave";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getAttendanceYearDetails,
  getDivisionForAttendance,
  getSemesterDetails,
  getSubjectByDivisionAttendance,
} from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import StudentAttendanceForm from "./StudentAttandanceForm";
import StudentAttandaceFormTable from "./StudentAttandaceFormTable";
import CoordinatorPreviewTable from "../StudentAttendanceForms/CoordinatorPreviewTable";
import CompletedSyllabus from "./CompletedSyllabus";
import { Box } from "@mui/system";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
class PresentStudentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      showForm: true,
      showSaveForm: false,
      saveFormData: {},
      dataTosave: {},
      changeValue: 0,
      searchValue: "",
      values: [],
      syllabus: [],
      showLoader: false,
      dynamicMasterData: {
        yearDetailMaster: this.props.employeeWorkList?.attendanceYearDetails,
        semesterDetailMaster:
          this.props.employeeWorkList?.attendanceSemesterDetails,
        divisionMaster: this.props.employeeWorkList?.attendanceDivision,
        subjectMaster: this.props.employeeWorkList?.attendanceSubjects,
        batchMaster: this.props.studentAttendanceList?.batch,
      },
    };
  }

  componentDidMount() {
    this.props.resetReducList();
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const ay = localStorage.getItem("acadamicYearId");

    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: yearDetailId,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: semisterDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    this.props
      .getAttendanceYearDetails({ academicYear: ay })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });

    if (semisterDetailId && divisionId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: semisterDetailId,
          division: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceYearDetails !==
          nextProps.employeeWorkList?.attendanceYearDetails &&
        nextProps.employeeWorkList?.isFetchAttendanceYearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.employeeWorkList?.attendanceYearDetails,
          },
        });
      }
    }
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceSemesterDetails !==
          nextProps.employeeWorkList?.attendanceSemesterDetails &&
        nextProps.employeeWorkList?.isFetchAttendanceSemesterDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster:
              nextProps.employeeWorkList?.attendanceSemesterDetails,
          },
        });
      }
    }
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceDivision !==
          nextProps.employeeWorkList?.attendanceDivision &&
        nextProps.employeeWorkList?.isFetchAttendanceDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster: nextProps.employeeWorkList?.attendanceDivision,
          },
        });
      }

      if (
        this.props.employeeWorkList?.attendanceSubjects !==
          nextProps.employeeWorkList?.attendanceSubjects &&
        nextProps.employeeWorkList?.isFetchattendanceSubjects
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.employeeWorkList?.attendanceSubjects,
          },
        });
      }
    }
    if (
      this.props.teachingPlanList.syllabus !==
      nextProps.teachingPlanList.syllabus
    ) {
      this.setState({
        syllabus:
          Object.keys(nextProps.teachingPlanList?.syllabus).length !== 0
            ? nextProps.teachingPlanList?.syllabus.details
            : [],
      });
    }
    if (this.props.studentAttendanceList !== nextProps.studentAttendanceList) {
      if (
        this.props.studentAttendanceList?.batch !==
          nextProps.studentAttendanceList?.batch &&
        nextProps.studentAttendanceList?.isFetchBatch
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchMaster: nextProps.studentAttendanceList?.batch,
          },
        });
      }
    }
  }

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: data.yearDetail,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: data.semister })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: data.semister,
          division: data.division,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister && data.batch) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSubjectByDivisionAttendance({
          semister: data.semister,
          division: data.division,
          batchId: data.batch,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "attendanceDate",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },

    {
      name: "topicTaught",
      title: "Topic Taught",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
      canSearch: true,
    },
    {
      name: "presentCount",
      title: "Present Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
    {
      name: "absentCount",
      title: "Absent Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
    {
      name: "totalCount",
      title: "Total Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  submitHandler = (data) => {
    this.setState({
      showLoader: true,
    });

    this.props
      .getStudentAttendanceBySubject({
        employeeWorkLoadId: data.subject,
        fromDate: data.fromDate,
        toDate: data.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", data.division);
    localStorage.setItem("academicYearId", ay);
    localStorage.setItem("divisionIds", "");
    FilterSave();
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  // rowDetails = (data) => {
  //   this.props
  //     .getStudentAttendanceBySubjectView({ id: data.id })
  //     .then((response) => {
  //       if (!response) {
  //         this.props.getAlert({ message: "Something went wrong" });
  //       }
  //       else {
  //         this.setState({
  //           showSaveForm: false,
  //           showTable: true,
  //           showForm: false,
  //           dataTosave: this.props.studentAttendanceList
  //             ?.studentAttendanceBySubjectView.studentAttendanceDetails
  //             ? this.props.studentAttendanceList?.studentAttendanceBySubjectView
  //             : {},
  //           //     .studentAttendanceDetails
  //           // : []
  //         });
  //       }
  //     });
  // };

  rowDetails = (data) => {
    this.props
      .getStudentAttendanceBySubjectView({ id: data.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props
            .getCompletedSubSection({ id: data.id })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showSaveForm: false,
                  showTable: true,
                  showForm: false,
                  dataTosave: this.props.studentAttendanceList
                    ?.studentAttendanceBySubjectView.studentAttendanceDetails
                    ? this.props.studentAttendanceList
                        ?.studentAttendanceBySubjectView
                    : {},
                });
              }
            });
        }
      });
  };

  rowEdit = (data) => {
    this.props
      .getStudentAttendanceBySubjectView({ id: data.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props
            .getCompletedSubSection({ id: data.id })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showSaveForm: true,
                  showTable: false,
                  showForm: false,
                  saveFormData: this.props.studentAttendanceList
                    ?.studentAttendanceBySubjectView
                    ? this.props.studentAttendanceList
                        ?.studentAttendanceBySubjectView
                    : [],
                });
              }
            });
        }
      });
  };

  backToList = () => {
    const { resetReducList } = this.props;
    // resetReducList();
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const ay = localStorage.getItem("acadamicYearId");

    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: yearDetailId,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: semisterDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    this.props
      .getAttendanceYearDetails({ academicYear: ay })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });

    if (semisterDetailId && divisionId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: semisterDetailId,
          division: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    this.setState({
      showTable: false,
      showForm: true,
      showSaveForm: false,
      dynamicMasterData: {
        yearDetailMaster: [],
        semesterDetailMaster: [],
        divisionMaster: [],
        subjectMaster: [],
        batchMaster: [],
      },
    });
  };
  backToForm = () => {
    this.setState({
      changeValue: 0,
    });
  };
  backToAttandace = () => {
    this.setState({
      changeValue: 1,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studentAttendanceList?.studentAttendanceBySubject.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["attendanceDate"] &&
            currentRow["attendanceDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["subject"] &&
            currentRow["subject"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["batch"] &&
            currentRow["batch"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["presentCount"] &&
            currentRow["presentCount"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["absentCount"] &&
            currentRow["absentCount"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["topicTaught"] &&
            currentRow["topicTaught"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  onAddButtonClick = () => {
    this.props.resetReducList();
    this.setState({
      changeValue: 0,
      showSaveForm: true,
      showTable: false,
      showForm: false,
      saveFormData: {},
    });
  };

  handledChange = (event, newValue) => {
    this.setState({
      changeValue: newValue,
    });
  };

  saveData = (data) => {
    const saveData =
      Object.keys(this.state.saveFormData).length !== 0
        ? {
            ...this.state.saveFormData,
            ...data,
          }
        : data;

    this.setState({
      saveFormData: saveData,
      changeValue: 1,
    });
  };
  onSaveTable = (data) => {
    const fieldDataValue = {
      ...this.state.saveFormData,
      ...data,
    };
    this.setState({
      saveFormData: { ...this.state.saveFormData, ...data },
      changeValue: 2,
    });
  };
  saveHandler = () => {
    const { saveFormData, syllabus } = this.state;
    swal({
      title: "Are you sure?",
      text: "Do you want to save information?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        let dataToSaveFinal = {
          employeeWorkLoad: {
            id: saveFormData.subject,
          },
          attendanceDate: saveFormData.attendanceDate,
          timeSlot: {
            id: saveFormData.timeSlot,
          },
          batch: {
            id: saveFormData.batch,
          },
          topicTaught: saveFormData.topicTaught,
          presentRollNos: saveFormData.presentRollNos,
          remark: saveFormData.remark,
          presentCount: saveFormData.presentCount,
          absentCount: saveFormData.absentCount,
          isActive: 1,
          studentAttendanceDetails: saveFormData.studentAttendanceDetails.map(
            (data) => {
              let bData = {
                student: {
                  id: data.userRegistrationId,
                },
                reportingDetail: {
                  id: data.reportingDetailId,
                },
                rollNo: data.rollNo,
                status: data.attendance === true ? 1 : 0,
                excuse: data.excuse === true ? 1 : 0,
                remark: data.answer,
              };
              if (data.id !== 0) {
                bData = {
                  ...bData,
                  id: data.id,
                };
              }

              return bData;
            }
          ),
        };
        if (saveFormData.id !== 0) {
          dataToSaveFinal = {
            ...dataToSaveFinal,
            id: saveFormData.id,
          };
        }

        apiPost({
          url: endpoint.studentAttendance,
          postBody: dataToSaveFinal,
        }).then(({ data, success }) => {
          if (success) {
            const subjectList = [];
            syllabus.map((chapterData) => {
              chapterData.sectionList.map((section) => {
                section.subSectionList.map((subSection) => {
                  if (subSection.checked) {
                    let subsectionData = {
                      studentAttendanceHeader: {
                        id: data.data.id,
                      },
                      syllabusSubSection: {
                        id: subSection.id,
                      },
                      isActive: 1,
                    };
                    if (subSection.attandaceSyllabusId) {
                      subsectionData = {
                        ...subsectionData,
                        id: subSection.attandaceSyllabusId,
                      };
                    }
                    subjectList.push(subsectionData);
                  }
                });
              });
            });
            apiPost({
              url: endpoint.studentAttandaceSyllabus,
              postBody: subjectList,
            }).then(({ data, success }) => {
              if (success) {
                this.props.showNotification({ msg: "Data saved successfully" });
                window.location.replace("/present-student-details");
              } else {
                this.props.getAlert({
                  message: "Failed to save",
                });
              }
            });
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });
  };
  setSyllabus = (data) => {
    this.setState({
      syllabus: data,
    });
  };

  render() {
    return (
      <>
        {this.state.showForm && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={ViewStudAttendanceDynamicJson.screenTitle}
              fieldMeta={ViewStudAttendanceDynamicJson.fieldMeta}
              buttonCenter={ViewStudAttendanceDynamicJson.buttonCenter}
              showPdfDownload={false}
              showExcelDownload={false}
              showLoader={this.state.showLoader}
              tableColumnsToFilter={
                ViewStudAttendanceDynamicJson.tableColumnsToFilter
              }
              showAddButton={true}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={this.columns}
              getListById={this.getListById}
              tableData={
                this.props.studentAttendanceList?.studentAttendanceBySubject
              }
              showPegination={false}
              downLoadPdfFromApi={true}
              pdfDownload={this.downloadPdf}
              showDetails={true}
              showHeadEdit={true}
              rowEdit={this.rowEdit}
              rowDetails={this.rowDetails}
              onSearchData={this.submitHandler}
              baseIdColumn={ViewStudAttendanceDynamicJson.baseIdColumn}
              apiBaseURL={ViewStudAttendanceDynamicJson.apiBaseURL}
            />
          </>
        )}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {this.state.showTable && (
            <>
              {" "}
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={10.5} md={10.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Students List"
                    />
                  </Grid>
                  <Grid item xs={12} sm={1.5} md={1.5}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back to List"
                      onClick={this.backToList}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 1 }} item xs={12} sm={12} md={12}>
                <CoordinatorPreviewTable rowData={this.state.dataTosave} />
              </Grid>
            </>
          )}
          {this.state.showSaveForm && (
            <>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Student Attendance"
                  />
                </Grid>
                <Grid
                  sx={{ mt: 1 }}
                  item
                  container
                  justifyContent="right"
                  xs={12}
                  md={6}
                  lg={6}
                >
                  <Link
                    color="primary"
                    variant="body2"
                    component={RouterLink}
                    to="/present-student-details"
                    sx={{ textDecoration: "none" }}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back To List"
                      onClick={this.backToList}
                    />
                  </Link>
                </Grid>
              </Grid>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={this.state.changeValue}
                  onChange={this.handledChange}
                  aria-label="basic tabs example"
                >
                  <Tab label={"Basic info"} {...a11yProps(0)} />
                  <Tab
                    disabled={
                      Object.keys(this.state.saveFormData).length !== 0
                        ? false
                        : true
                    }
                    label={"Student attendance"}
                    {...a11yProps(1)}
                  />
                  <Tab
                    disabled={
                      Object.keys(this.state.saveFormData).length !== 0 &&
                      this.state.saveFormData.studentAttendanceDetails
                        ? false
                        : true
                    }
                    label={"Syllabus "}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={this.state.changeValue} index={0}>
                <StudentAttendanceForm
                  saveFormData={this.state.saveFormData}
                  backToList={this.backToList}
                  Onsave={this.saveData}
                  resetvalues={this.state.values}
                />
              </TabPanel>
              <TabPanel value={this.state.changeValue} index={1}>
                <StudentAttandaceFormTable
                  saveFormData={this.state.saveFormData}
                  backToList={this.backToForm}
                  onSave={this.onSaveTable}
                />
              </TabPanel>
              <TabPanel value={this.state.changeValue} index={2}>
                <CompletedSyllabus
                  changeSyllabus={this.setSyllabus}
                  syllabus={this.state.syllabus}
                  editSyllabus={
                    this.props.studentAttendanceList.studentAttendanceSyllabus
                  }
                  saveHandler={this.saveHandler}
                  backToList={this.backToAttandace}
                />
              </TabPanel>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearDetails: state.internshipDeclaration,
  semisterList: state.rollNo,
  teachingPlanList: state.teachingPlan,
  studentAttendanceList: state.studentAttendance,
  employeeWorkList: state.employeeWorkload,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getSubjectByYearDetailsId,
  getDivision,
  getSubjectByDivision,
  getStudentAttendanceBySubject,
  getStudentAttendanceBySubjectView,
  getCompletedSubSection,
  resetReducList,
  getStudentAttendanceList,
  getBatch,
  getAttendanceYearDetails,
  getSemesterDetails,
  getDivisionForAttendance,
  getSubjectByDivisionAttendance,
  resetTableValues,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentStudentDetails);
