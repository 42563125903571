import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../components/Comman/Button";
import BasicDatePicker from "../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../../components/dynamicscreens/DynamicForm";
import endpoint from "../../../../config/endpoints";
import * as msgConstant from "../../../../config/messageconstant";
import { AdminViewAlumniInternshipJson } from "../../../../DynamicFormsJson/AdminViewAlumniInternship";
import { showNotification } from "../../../../Pages/Landing/Landing.slice";
import { adminViewAlumniPostInternship } from "../../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../../utils/api_service";
import { getAlumniInternship } from "./AdminViewInternship.slice";
import { generatePDF } from "../../../../components/Comman/GeneratePDF";
import { generateExcel } from "../../../../components/Comman/GenerateExcel";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
import * as myConstClass from "../../../../config/messageconstant";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminViewAlumniPostInternship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      formErrors: [],
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  // onRTSelectMultipleChange = (selectedOption) => {
  //   const { tableColumnsToFilter } = this.state;
  //   const selectedItem = tableColumnsToFilter.filter(
  //     (item) => item.columnKeyName === selectedOption.columnKeyName
  //   )[0];
  //   selectedItem.isChecked = !selectedItem.isChecked;

  //   this.setState({
  //     tableColumnsToFilter: tableColumnsToFilter,
  //   });
  // };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  componentDidMount() {
    this.props.getAlumniInternship().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  getFilteredTableData = () => {
    const { adminViewAlumniInternshipList } = this.props;
    const { searchValue } = this.state;
    const columsFil = adminViewAlumniPostInternship.filter((item) => {
      return item.canSearch;
    });
    return adminViewAlumniInternshipList?.adminViewInternship.filter(
      (currentRow) => {
        let isValid = false;
        columsFil.some((col) => {
          if (
            currentRow[col.name] &&
            currentRow[col.name]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
        });
        return isValid;
      }
    );
  };
  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = false,
      showAddButton = true,
      employeeDetails,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      dynamicMasterData,
      fieldData,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={AdminViewAlumniInternshipJson.screenTitle}
          showPdfDownload={false}
          showExcelDownload={false}
          showAddButton={false}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {showTable && (
            <>
              <Grid item sx={{ marginTop: 1 }}>
                <RTTable
                  columns={adminViewAlumniPostInternship}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  showAddButtonInTable={showAddButtonInTable}
                  isActionColActive={false}
                  tableData={this.getFilteredTableData()}
                  rows={this.getFilteredTableData()}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  adminViewAlumniInternshipList: state.adminViewAlumniInternship,
});
const mapDispatchToProps = {
  showNotification,
  getAlumniInternship,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminViewAlumniPostInternship);
