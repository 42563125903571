import { FormHelperText, Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { FilterSave } from "../../components/FilterSave";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../config/messageconstant";
import AccountFees from "../AccountFees/index";
import AddFeesStructure from "../AccountFees/AddFeesStruture";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import { feesStructureReset } from "../Masters/Admission/FeeStructureHead/feeStructureHead.slice";
import { resetReducList } from "../../CommonActions/User.action";
import { getAdmissionList, resetValues } from "./FeesCollection.slice";
import StudentFeesDetails from "./StudentFeesDetails";
import { FeesCollectionDynamicJson } from "../../DynamicFormsJson/FeesCollectionDynamicJson";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import endpoints from "../../config/endpoints";
import { apiPost ,apiGet} from "../../utils/api_service";
import { showNotification } from "../../Pages/Landing/Landing.slice";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
  },
  {
    // name: "applicantName",
    name: "firstName",
    title: "Applicant Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    keyName: "firstName,lastName,fatherName,motherName",
    isFormat: true,
    type: 0,
  },
  {
    name: "studentMobile",
    title: "Mobile number",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
  },
  {
    name: "totalFees",
    title: "Total Fees",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
  },
  {
    name: "paidStatus",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
  },
  {
    name: "claimNo",
    title: "Payment Link Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true

  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    minWidth: "10%",
  },
];
class ApplicantListForm extends React.Component {
  state = {
    id: 0,
    academicYear: localStorage.academicYearId,
    yearDetail: localStorage.yearDetailId,
    typeOfAdmission: "",
    applicationId: "",
    formErrors: [],
    fieldData: {},
    studentFeesDet: false,
    accountFees: false,
    feesCollection: true,
    addFeesStructure: false,
    showLoader: false,
    linkFlag:"0",
    dynamicMasterData: {
      yearDetailMaster: this.props.classList.class,
    },
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.classList !== nextProps.classList) {
      if (this.props.classList.class !== nextProps.classList.class) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.classList.class,
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYearData: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
  }

  componentDidMount() {
    const {
      getClass,
      getAcademicYear,
      getAlert,
      resetReducList,
      feesStructureReset,
      getAdmissionList,

      feesCollectionList
    } = this.props;
    resetReducList();

  

    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const divisionId = localStorage.getItem("divisionId");

    this.setState({
      showLoader: true,
    });
    getAdmissionList({
      academicId: ay,
      yearDetailId: yearDetailId,
    } ).then( ( response ) =>
    {
      console.log("resp___"+response)
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else
      {
        console.log("feesCollectionList"+  JSON.stringify(feesCollectionList))
        this.setState({
          showLoader: false,

        });
      }
    });

    getClass().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    getAcademicYear().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    feesStructureReset();
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  // handleFormValidation() {
  //   const { academicYear, yearDetail } = this.state;
  //   let formErrors = {};
  //   let formIsValid = true;

  //   if (academicYear.toString().trim() === "" || academicYear === null) {
  //     formIsValid = false;
  //     formErrors["academicYearError"] = myConstClass.academicYearMessage;
  //   }

  //   if (yearDetail.toString().trim() === "" || yearDetail === null) {
  //     formIsValid = false;
  //     formErrors["yearDetailsError"] = myConstClass.yearDetailMsg;
  //   }

  //   this.setState({ formErrors: formErrors });
  //   return formIsValid;
  // }



  generateDetails = ( rowData ) =>
  {
    console.log(rowData)
    if ( rowData.flag === 0 )
    { 
      console.log("In if row data ==0")
      let data =
      {
        admissionRegistrationDetailId: rowData.id,
        returnURL:"https://eduerpsuccess.aaryatechindia.in/payment-success-prelogin.php"
      }
      console.log(data)
      swal( {
        title: "Are you sure?",
        text: "Do you want to send Admission Link?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      } ).then( ( yes ) =>
      {
        if ( yes )
        {
          this.setState({
            showLoader: true,
          });
          apiPost({ 
            url: endpoints.paymentUrl+"/get-hash-for-link",
            postBody: data,
          } ).then( ( { data, success } ) =>
          {
            console.log("data"+data)
            console.log( "success" + success )
            if ( success )
            {
              console.log("data"+data)
              this.setState({
                showLoader: false,
              } );
              this.props.showNotification({ msg: "Link sent successfully!" });
            } else {
              this.setState({
                showLoader: false,
              } );
              this.props.showNotification({
                msg: "Failed to send Link!",
                severity: "error",
              });
            }
          });
        } else{
          this.setState({
            showLoader: false,
          });
        }
      } );
    } else
    {
      console.log("In if row data == 1")
      let data =
      {
        admissionRegistrationDetailId: rowData.id
      }
      console.log(data)
      swal( {
        title: "Are you sure?",
        text: "Do you want to re-send Admission Link?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      } ).then( ( yes ) =>
      {
        if ( yes )
        {
          this.setState({
            showLoader: true,
          });

          apiGet({ 
            url: endpoints.paymentUrl+"/regenerate-payment-link?admissionRegistrationDetailId="+rowData.id,
          } ).then( ( { data, success } ) =>
          {
            console.log("data"+data)
            console.log( "success" + success )
            if ( success )
            {
              console.log("data"+data)
              this.setState({
                showLoader: false,
              } );
              this.props.showNotification({ msg: "Link sent successfully!" });
            } else {
              this.setState({
                showLoader: false,
              } );
              this.props.showNotification({
                msg: "Failed to send Link!",
                severity: "error",
              });
            }
          });
        } else{
          this.setState({
            showLoader: false,
          });
        }
      } );
      
    }
  };

  searchData = (data) => {
    // console.log(localStorage);
    const ay = localStorage.getItem("acadamicYearId");
    const { academicYear, yearDetail } = this.state;
    const { getAdmissionList, getAlert } = this.props;
    this.setState({
      showLoader: true,
    });
    getAdmissionList({
      academicId: ay,
      yearDetailId: data.yearDetail,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", data.division);
    localStorage.setItem("divisionIds", "");
    FilterSave();
  };

  paymentDetails = (data) => {
    this.setState({
      accountFees: false,
      studentFeesDet: true,
      feesCollection: false,
      paymentDetailsData: data,
    });
  };

  rowFeesPaid = (data) => {
    this.setState({
      accountFees: true,
      studentFeesDet: false,
      feesCollection: false,
      applicationData: data,
    });
  };

  onCancel = () => {
    // this.props.resetReducList()
    this.props.getClass().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      accountFees: false,
      studentFeesDet: false,
      feesCollection: true,
    });
  };

  closeStudentFeesDetails = () => {
    this.setState({ studentFeesDet: false, feesCollection: true });
  };

  render() {
    const {
      yearDetail,
      academicYear,
      studentFeesDet,
      paymentDetailsData,
      feesCollection,
      accountFees,
      addFeesStructure,
    } = this.state;
    const { academicYearError, yearDetailsError } = this.state.formErrors;
    const { classList, academicYearList, feesCollectionList } = this.props;
    return (
      <>
        {feesCollection && (
          <>
            <DynamicMainScreen
              // onAddButtonClick={this.onAddButtonClick}
              screenTitle={FeesCollectionDynamicJson.screenTitle}
              fieldMeta={FeesCollectionDynamicJson.fieldMeta}
              buttonCenter={FeesCollectionDynamicJson.buttonCenter}
              showPdfDownload={false}
              showExcelDownload={false}
              tableColumnsToFilter={
                FeesCollectionDynamicJson.tableColumnsToFilter
              }
              showAddButton={false}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={columns}
              tableData={feesCollectionList.admissionListStatus}
              showPegination={false}
              showPaymentDetails={true}
              showLoader={this.state.showLoader}
              rowPaymentDetails={this.rowFeesPaid}
              // onDelete={this.onDelete}
              onSearchData={this.searchData}
              baseIdColumn={FeesCollectionDynamicJson.baseIdColumn}
              apiBaseURL={FeesCollectionDynamicJson.apiBaseURL}
              showLinkIcon={true}
              generateDetails={this.generateDetails}
              // showLinkIconNew={true}
              // generateDetailsNew={this.generateDetails}
              linkStatus={0}
            />
            {/* <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={5}
                md={5}
                // sx={{ marginTop: 2 }}
              >
                <Grid item sx={{ ml: 1 }} xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Admited Student Fees "
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={5.5}
                  md={5.5}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Academic Year "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={academicYear}
                      name={"academicYear"}
                      options={academicYearList?.academicYear}
                      onChange={this.ChangeHandlerSearch}
                      isError={academicYearError ? true : false}
                      errorText={academicYearError ? academicYearError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={5.5}
                  md={5.5}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Details "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={classList?.class}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailsError ? true : false}
                      errorText={yearDetailsError ? yearDetailsError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-start"
                  direction="row"
                  xs={12}
                  sm={1}
                  md={1}
                  sx={{ marginTop: 5 }}
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Search"
                      onClick={this.searchData}
                    />
                  </Grid>
                </Grid>

                <Grid item sx={{ p: 1 }} xs={12} sm={12} md={12}>
                  <DynamicTable
                    data={feesCollectionList.admissionListStatus}
                    tableHead={columns}
                    showHeadDelete={false}
                    showHeadEdit={false}
                    showPegination={false}
                    rowFeesPaid={this.paymentDetails}
                    rowStatus={this.onChangeStatus}
                    rowPaymentDetails={this.rowFeesPaid}
                    showPayFees={false}
                    showPaymentDetails={true}
                  />
                </Grid>
              </Grid> */}
          </>
        )}
        <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
          {studentFeesDet && (
            <StudentFeesDetails
              onCancel={this.closeStudentFeesDetails}
              studentFeeData={paymentDetailsData}
            />
          )}

          {accountFees && (
            <AccountFees
              applicationData={this.state.applicationData}
              onCancel={this.onCancel}
              onCloseTab={this.onCancel}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  classList: state.class,
  academicYearList: state.academicYear,
  feesCollectionList: state.feesCollection,
});
const mapDispatchToProps = {
  getClass,
  getAcademicYear,
  getAdmissionList,
  getAlert,
  resetReducList,
  feesStructureReset,
  showNotification
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplicantListForm);
