import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  FormControl,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getAlert } from "../../CommonActions/alert.slice";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiPost } from "../../utils/api_service";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getSubjectInfoByYearDetailId } from "../Masters/Admission/Subject Info/subjectInfo.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
  viewMultipleFile,
} from "../UploadFile/file.slice";
import {
  getEmaterialByAcYearAndYearDet,
  getOtherPatternList,
  getOtherSubjectList,
} from "./ematerial.slice";

// import { getCertificateList } from "./studentCertificate.slice"
let docs = {};
class EmaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      formErrors: [],
      toDate: "",
      other: "",
      academicYear: "",
      yearDetail: "",
      subject: "",
      patternList: [],
      subjectsList: [],
      opentOtherField: false,
      opentOtherSearchField: false,
      subjectOther: "",
      topic: "",
      documentType: "",
      openDocUrl: false,
      docURL: "",
      documentName: "",
      fileName: "",
      file: "",
      uploadError: false,
      upload: false,
      fileNameFlag: false,
      showDocumentField: false,
      id: 0,
    };
  }

  onClickViewPreview = (fileName) => {
    this.props.openFileInNewTab({ file: fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  uploadImage = () => {
    const { saveMultipleFile, getAlert } = this.props;
    const { fileName, documentName, fileNameFlag } = this.state;
    if (fileName !== "") {
      this.setState({
        upload: true,
        uploadError: false,
      });
      let fileData = {
        fileName: fileName,
        name: documentName,
      };
      saveMultipleFile({ file: fileData }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
          this.setState({
            // documentName: "",
            // fileName: "",
            fileNameFlag: true,
            upload: false,
            uploadError: false,
          });
        } else {
          this.setState({
            // documentName: "",
            // fileName: "",
            fileNameFlag: true,
            upload: false,
            uploadError: false,
          });
        }
      });
    } else {
      this.setState({
        uploadError: true,
      });
    }
  };

  changeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    // for Edit Dataa
    const { editData } = this.props;
    if (Object.keys(editData).length !== 0) {
      this.setState({
        ...this.state,
        id: editData.id,
        documentType: editData.docType,
        docURL: editData.docType != "0" ? editData.docUrl : "",
        file: editData.docType == "0" ? editData.docUrl : "",
        topic: editData.topic,
        yearDetail: editData.yearDetailId,
      });
      const { getSubjectInfoByYearDetailId, getAlert } = this.props;
      this.props.getOtherSubjectList().then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        } else {
          getSubjectInfoByYearDetailId({
            yearDetailId: editData.yearDetailId,
          }).then((data) => {
            if (data.errorMessage !== null) {
              if (data.errorMessage.error === true) {
                let id = this.props.ematerialList.otherSubject.filter(
                  (row) => row.name === editData.subject
                );
                if (id.length !== 0) {
                  this.setState({
                    subjectsList: this.props.ematerialList.otherSubject,
                    subject: id[0].id,
                  });
                }
              }
            }
          });
        }
      });
    }
  }

  changedHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "yearDetail") {
        const { getOtherSubjectList, getSubjectInfoByYearDetailId, getAlert } =
          this.props;

        getOtherSubjectList().then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          } else {
            getSubjectInfoByYearDetailId({
              yearDetailId: newValue,
            }).then((data) => {
              if (data.errorMessage !== null) {
                this.setState({
                  subjectsList: this.props.ematerialList.otherSubject,
                });
              }
            });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.ematerialList !== nextProps.ematerialList ||
      this.props.subjectsList !== nextProps.subjectsList
    ) {
      if (
        this.props.ematerialList.getOtherSubjectList !==
          nextProps.ematerialList.getOtherSubjectList ||
        this.props.subjectsList.subjects !== nextProps.subjectsList.subjects
      ) {
        const subjectOtherList =
          nextProps.ematerialList.getOtherSubjectList.map((subjectOther) => {
            let subject = {
              id: subjectOther,
              name: subjectOther,
            };
            return subject;
          });
        if (this.props.subjectsList !== nextProps.subjectsList) {
          if (
            this.props.subjectsList.subjects !== nextProps.subjectsList.subjects
          ) {
            let subjectData = [];
            subjectData = [
              ...nextProps.subjectsList.subjects.filter(
                (data) => data.isActive == 1
              ),
              ...subjectOtherList,
              { id: "0", name: "Other" },
            ];
            if (Object.keys(this.props.editData).length !== 0) {
              let subjectId = subjectData.filter(
                (row) => row.name === this.props.editData.subject
              );
              this.setState({
                subject: subjectId.length !== 0 ? subjectId[0].id : "",
              });
            }
            this.setState({
              subjectsList: subjectData,
            });
          }
        }
      }
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === "documentType" && value == "0") {
      this.setState({
        ...this.state,
        docURL: "",
        [name]: value,
      });
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleFormValidation() {
    const {
      yearDetail,
      subject,
      documentType,
      other,
      subjectOther,
      topic,
      docURL,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (subject.toString().trim() === "" || subject === null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectMsg;
    }

    if (subject == "0" && subjectOther === "") {
      formIsValid = false;
      formErrors["subjectOtherError"] = myConstClass.subjectNameMsg;
    }

    if (topic === "") {
      formIsValid = false;
      formErrors["topicError"] = myConstClass.topicMsg;
    }

    if (documentType.toString().trim() === "" || documentType === null) {
      formIsValid = false;
      formErrors["documentTypeError"] = myConstClass.docNameMsg;
    }

    if ((documentType == "1" || documentType == "2") && docURL === "") {
      formIsValid = false;
      formErrors["docURLError"] = myConstClass.urlMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    if (this.handleFormValidation()) {
      const {
        fromDate,
        toDate,
        academicYear,
        yearDetail,
        subject,
        subjectOther,
        topic,
        documentType,
        docURL,
        documentName,
        fileName,
        upload,
        other,
        id,
        file,
      } = this.state;
      const { editData } = this.props;
      let subjectName =
        subject != 0 &&
        this.state.subjectsList.filter((row) => row.id === subject);
      if (id === 0) {
        if (documentType == "0" && fileName !== "") {
          const formData = new FormData();
          formData.append("file", fileName);
          apiPost({
            url: endpoint.fileUpload,
            postBody: formData,
          }).then(({ data, success }) => {
            if (success) {
              docs = {
                yearDetailId: yearDetail,
                subject: subject == "0" ? subjectOther : subjectName[0].name,
                topic: topic,
                docType: documentType,
                docUrl: data,
              };
              if (this.handleFormValidation()) {
                this.onSave(docs);
              }
            } else {
              this.props.getAlert({
                message: "Failed to save",
              });
            }
          });
        } else if (documentType != "0") {
          docs = {
            yearDetailId: yearDetail,
            subject: subject == "0" ? subjectOther : subjectName[0].name,
            topic: topic,
            docType: documentType,
            docUrl: docURL,
          };
          this.onSave(docs);
        } else {
        }
      } else {
        if (documentType == "0" && fileName !== "") {
          const formData = new FormData();
          formData.append("file", fileName);
          apiPost({
            url: endpoint.fileUpload,
            postBody: formData,
          }).then(({ data, success }) => {
            if (success) {
              docs = {
                id: id,
                yearDetailId: editData.yearDetailId,
                academicYearId: editData.academicYearId,
                uplodedBy: { id: editData.userRegistaration },
                yearDetailId: yearDetail,
                subject: subject == "0" ? subjectOther : subject,
                topic: topic,
                docType: documentType,
                docUrl: data,
              };

              this.onSave(docs);
            } else {
              this.props.getAlert({
                message: "Failed to save",
              });
            }
          });
        } else {
          if (documentType != "0") {
            docs = {
              id: id,
              yearDetailId: editData.yearDetailId,
              academicYearId: editData.academicYearId,
              uplodedBy: { id: editData.userRegistaration },
              yearDetailId: yearDetail,
              subject: subject == "0" ? subjectOther : subjectName[0].name,
              topic: topic,
              docType: documentType,
              docUrl: docURL,
            };

            this.onSave(docs);
          } else {
            docs = {
              id: id,
              yearDetailId: editData.yearDetailId,
              academicYearId: editData.academicYearId,
              uplodedBy: { id: editData.userRegistaration },
              yearDetailId: yearDetail,
              subject: subject == "0" ? subjectOther : subject,
              topic: topic,
              docType: documentType,
              docUrl: file,
            };

            this.onSave(docs);
          }
        }
      }
    }
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.ematerial,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.props.onCancel();
        this.props
          .getEmaterialByAcYearAndYearDet({
            academicYear: this.props.editData.academicYearId,
            yearDetail: this.props.editData.yearDetailId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  render() {
    const {
      academicYear,
      yearDetail,
      subject,
      other,
      opentOtherField,
      opentOtherSearchField,
      subjectOther,
      topic,
      documentType,
      openDocUrl,
      docURL,
      fileName,
      uploadError,
      upload,
      fileNameFlag,
      file,
      showDocumentField,
      documentName,
    } = this.state;
    const { onCancel } = this.props;
    const {
      academicYearError,
      yearDetailError,
      patternError,
      subjectError,
      documentTypeError,
      otherError,
      subjectOtherError,
      topicError,
      docURLError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <>
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="E-material Form"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="right"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Link
                    color="primary"
                    variant="body2"
                    component={RouterLink}
                    to="/ematerial-form"
                    sx={{ textDecoration: "none" }}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back To List"
                      fullWidth={true}
                      onClick={onCancel}
                    />
                  </Link>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                sx={{ marginTop: 5 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  {/* <Grid
  item
  sm={12}
  xs={12}
  md={6}
  lg={6}
  container
  justifyContent="space-evenly"
  >
  <Grid item xs={12} sm={12} md={12}>
  <LabelCompo
  className="text-black"
  style={{ fontSize: 16 }}
  label="*Academic Year"
  />
  </Grid>
 
  <Grid item xs={12} sm={12} md={12}>
  <AutoComplete
  keyColName={"id"}
  value={academicYear}
  name={"academicYear"}
  options={this.props.academicYearList?.academicYear}
  onChange={this.changedHandlerSearch}
  isError={academicYearError ? true : false}
  errorText={
  academicYearError ? academicYearError : " "
  }
  />
  </Grid>
  </Grid> */}

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Details"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={this.props.yearDetails?.yearDetails.filter(
                          (data) => data.isActive == 1
                        )}
                        onChange={this.changedHandlerSearch}
                        isError={yearDetailError ? true : false}
                        errorText={yearDetailError ? yearDetailError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* subject */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Subject"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={subject}
                        name={"subject"}
                        options={this.state.subjectsList}
                        onChange={this.changeHandlerSearch}
                        isError={subjectError ? true : false}
                        errorText={subjectError ? subjectError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    {subject == "0" && (
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Other"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            color="primary"
                            name="subjectOther"
                            size="small"
                            value={subjectOther}
                            fullWidth
                            onChange={this.changeHandler}
                            error={subjectOtherError ? true : false}
                            helperText={
                              subjectOtherError ? subjectOtherError : " "
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                {/* Topic */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Module"
                    />
                  </Grid>

                  <Grid item sx={{ marginTop: -1 }} xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="topic"
                      size="small"
                      value={topic}
                      fullWidth
                      multiline
                      onChange={this.changeHandler}
                      error={topicError ? true : false}
                      helperText={topicError ? topicError : " "}
                    />
                  </Grid>
                </Grid>

                {/* */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Document Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="documentType"
                          id="documentType"
                          value={documentType}
                          onChange={this.changeHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value={0}>Document</MenuItem>
                          <MenuItem value={1}>Video</MenuItem>
                          <MenuItem value={2}>Audio</MenuItem>
                        </Select>
                      </FormControl>
                      <FormHelperText error>{documentTypeError}</FormHelperText>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                    sx={{ marginTop: 2 }}
                  >
                    {(documentType == "1" || documentType == "2") && (
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Document URL"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            color="primary"
                            name="docURL"
                            size="small"
                            value={docURL}
                            fullWidth
                            onChange={this.changeHandler}
                            error={docURLError ? true : false}
                            helperText={docURLError ? docURLError : " "}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {documentType == "0" && (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={4}
                        lg={4}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label=" "
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Document"
                          />
                          {uploadError && (
                            <FormHelperText error>
                              {"please select file and Document Name"}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={7}
                        lg={7}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label=" "
                          />
                        </Grid>

                        <Grid item xs={12} sm={2.7} md={2.7}>
                          <AttachFile
                            name="documentName"
                            fileName={this.changeH}
                          />
                          &nbsp; {fileName !== "" ? fileName.name : ""}
                        </Grid>
                        <Grid item xs={12} sm={12} md={9.3}>
                          {file !== "" && (
                            <MyComponentWithIconProps
                              statusImage={VisibilityIcon}
                              color="primary"
                              fontSize="medium"
                              title="View"
                              onClick={(e) => this.onClickViewPreview(file)}
                            />
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={1}
                        lg={1}
                        container
                        justifyContent="space-evenly"
                      ></Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {/* <FormHelperText error>
  {fileNameError ? fileNameError : " "}
  </FormHelperText> */}
                    </Grid>
                  </>
                )}

                <Grid
                  sx={{ mr: 0.5 }}
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={onCancel}
                      fullWidth={true}
                    />{" "}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save"
                      onClick={this.submitHandler}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  certificateList: state.studentCertificate,
  academicYearList: state.academicYear,
  yearDetails: state.internshipDeclaration,
  subjectsList: state.subjectInfo,
  fileData: state.file,
  ematerialList: state.ematerial,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAcademicYear,
  getYearDetails,
  getSubjectInfoByYearDetailId,
  saveMultipleFile,
  fileMulitipleSave,
  saveFile,
  deleteFile,
  deleteFileData,
  viewMultipleFile,
  getOtherPatternList,
  getOtherSubjectList,
  getEmaterialByAcYearAndYearDet,
  openFileInNewTab,
  // getCertificateList
};
export default connect(mapStateToProps, mapDispatchToProps)(EmaterialForm);
