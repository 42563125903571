import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import { adminExtendedActivitiesJson } from "../../../../../DynamicFormsJson/AdminExtendedActivities";
import { extendedActivitiesColumns } from "../../../../../tableColumns/table-columns";
import { getActivityByExtendedActivity } from "../../AcademicDetails/Activity/activity.slice";
import {
  getExtendedActivities,
  getExtendedActivityByDate,
  getExtendedActivityWithoutFilter,
  getAdminExtendedActivities,
} from "../../AcademicDetails/ExtendedActivities/extended.slice";
import { showNotification } from "../../../../Landing/Landing.slice";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getEmployeeByDeptId } from "../../../../EmployeeDetails/employee.slice";

class AdminExtendedActivitieMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      dynamicMasterData: {
        LevelMaster: [
          { id: "International", name: "International" },
          { id: "National", name: "National" },
          { id: "State", name: "State" },
          { id: "University", name: "University" },
          { id: "Inhouse", name: "Inhouse" },
          { id: "Intercollegiate", name: "Intercollegiate" },
          { id: "other", name: "Other" },
        ],
        typeMaster: [
          { id: "0", name: "Personal" },
          { id: "1", name: "Faculty" },
        ],
        type: [
          { id: "Attended", name: "Attended", type: "0" },
          { id: "Organized", name: "Organized", type: "0" },
          { id: "Resource Person", name: "Resource Person", type: "0" },
          // new Added
          { id: "presented", name: "Presented", type: "0" },
          { id: "supervision", name: "Supervision", type: "1" },
          { id: "invited", name: "Invited", type: "1" },
          { id: "jury", name: "Jury", type: "1" },
          { id: "judge", name: "Judge", type: "1" },
          { id: "other", name: "Other", type: "0" },
        ],
        activity: this.props.activityList?.extendedActivity,
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeList?.employeeList,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.extendedActivity !==
        nextProps.activityList.extendedActivity
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: nextProps.activityList.extendedActivity.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
    if (this.props.employeeList !== nextProps.employeeList) {
      if (
        this.props.employeeList?.employeeList !==
        nextProps.employeeList?.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeList?.employeeList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }
  componentDidMount() {
    // this.props.getExtendedActivityWithoutFilter().then((response) => {
    //   if (!response) {
    //     this.props.getAlert({ message: "Something went wrong" });
    //   }
    // });

    this.props.getActivityByExtendedActivity().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  getListById = (data) => {
    if (data.departmentId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getEmployeeByDeptId({
          departmentId: data.departmentId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  goToAdminAcademicPanel = () => {
    window.location.replace("/admin-academic-panel");
  };
  submitHandler = (data) => {
    console.log(data.userRegistration);
    this.setState({
      showLoader: true,
    });
    this.props
      .getAdminExtendedActivities({
        userRegistration: data.userRegistration,
        activityType: data.activityType,
        activityId: data.employeeActivity,
        level: data.level,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };
  render() {
    const { dynamicMasterData } = this.state;
    const { extendedActivityList, getActivity } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={adminExtendedActivitiesJson.screenTitle}
            fieldMeta={adminExtendedActivitiesJson.fieldMeta}
            showPdfDownload={adminExtendedActivitiesJson.showPdfDownload}
            showExcelDownload={adminExtendedActivitiesJson.showExcelDownload}
            pdfFileName={adminExtendedActivitiesJson.pdfFileName}
            excelFileName={adminExtendedActivitiesJson.excelFileName}
            showAddButton={adminExtendedActivitiesJson.showAddButton}
            tableColumnsToFilter={
              adminExtendedActivitiesJson.tableColumnsToFilter
            }
            dynamicMasterData={dynamicMasterData}
            showSaveNextBtn={false}
            tableColumns={extendedActivitiesColumns}
            tableData={extendedActivityList?.extendedActivity}
            getTableData={""}
            getTableDataById={true}
            showLoader={!this.state.showLoader}
            apiBaseURL={adminExtendedActivitiesJson.apiBaseURL}
            baseIdColumn={adminExtendedActivitiesJson.baseIdColumn}
            type={1}
            DocumentUpload={adminExtendedActivitiesJson.DocumentUpload}
            showBackToListBtn={true}
            onBackToList={this.goToAdminAcademicPanel}
            getListById={this.getListById}
            addValidation={true}
            onSearchData={this.submitHandler}
            searchList={adminExtendedActivitiesJson.searchList}
            dataSetToAdd={adminExtendedActivitiesJson.dataSetToAdd}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  extendedActivityList: state.extendedActivity,
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getExtendedActivityByDate,
  getActivityByExtendedActivity,
  getExtendedActivities,
  getExtendedActivityWithoutFilter,
  getDepartment,
  getEmployeeByDeptId,
  getAdminExtendedActivities,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminExtendedActivitieMaster);
