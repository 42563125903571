import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, stubFalse } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
const initialState = {
  academicYearInstituteWise: [],
  isFetchAcademicYearInstituteWise: false,

  userCredentialNull: [],
  isFetchUserCredentialNull: false,

  prnNumberGenerate: [],
  isFetchPrnNumberGenerate: false,
};
let URL = endpoints.academicYear;
let URL1 = endpoints.studentList;
const userCredentialSlice = createSlice({
  name: "userCredential",
  initialState,
  reducers: {
    academicYearInstituteWiseSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        academicYearInstituteWise: row,
        isFetchAcademicYearInstituteWise: true,
      };
    },

    UserCredentialNullSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        userCredentialNull: row,
        isFetchUserCredentialNull: true,
      };
    },

    prnNumberGenerateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        prnNumberGenerate: row,
        isFetchPrnNumberGenerate: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  academicYearInstituteWiseSuccess,
  UserCredentialNullSuccess,
  prnNumberGenerateSuccess,
  resetSuccess,
} = userCredentialSlice.actions;

export default userCredentialSlice.reducer;

export const getAcademicYearInstituteWise = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        console.log(content);
        let index = 0;
        let row = [];

        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
          };
          row.push(bData);
          return data1;
        });
        dispatch(academicYearInstituteWiseSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUserCredentialNull =
  ({ academicYearId, division }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/for-null-user-id?academicYearId=" +
          academicYearId +
          "&divisionId=" +
          division,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];

          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              prnNo: data1.prnNo,
              dateOfBirth: data1.dateOfBirth,
              emailId: data1.emailId,
              name:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
              mobileNo: data1.mobileNo,
              firstName: data1.firstName,
              middleName: data1.middleName,
              motherName: data1.motherName,
              fatherName: data1.fatherName,
              lastName: data1.lastName,
            };
            row.push(bData);
            return data1;
          });
          dispatch(UserCredentialNullSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPrnNumberGenerate =
  ({ ids }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-prnno?userRegistrationIds=" + ids,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];

          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.firstName === null
                  ? ""
                  : data1.lastName +
                    " " +
                    data1.firstName +
                    " " +
                    data1.middleName +
                    " - " +
                    data1.motherName,
              firstName: data1.firstName,
              middleName: data1.middleName,
              motherName: data1.motherName,
              fatherName: data1.fatherName,
              lastName: data1.lastName,
              prnNumber: data1.prnNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(prnNumberGenerateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
