import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { Link as RouterLink } from "react-router-dom";
import AutoComplete from "../../components/Comman/AutoComplete";
import { getAlert } from "../../CommonActions/alert.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import {
  getPromotionalAdmisionAcademicYear,
  getPromotionalAdmsionStudentList,
} from "./promotionalAdmission.slice";
import swal from "sweetalert";

function PromotionalAdmissionTable({
  editData,
  promotionalAdmmisionList,
  studentAttendanceList,
  saveFormData,
  onSave,
  backToList,
  classList,
  getClass,
  getAlert,
  getPromotionalAdmisionAcademicYear,
  getPromotionalAdmsionStudentList,
}) {
  const [valuesLength, setValuesLength] = React.useState("");
  const [presentCount, setPresentCount] = React.useState("");
  const [absentCount, setAbsentCount] = React.useState("");
  const [rollNoCount, setRollNoCount] = React.useState("");
  const [present, setPresent] = React.useState("");
  const [values1, setValues1] = React.useState([]);
  const [yearDetailId, setYearDetailId] = React.useState("");
  const [academicYear, setAcademicYear] = React.useState("");
  const [academicYearId, setAcademicYearId] = React.useState("");
  useEffect(() => {
    setValues1(promotionalAdmmisionList.promotionalAdmsionStudentList);
  }, []);

  const changeHandler1 = (e, id) => {
    console.log(e.target.checked);
    setValues1((data) =>
      data?.map((list, index) =>
        list.studentRegistrationId === id
          ? { ...list, isChecked: e.target.checked, answer: "" }
          : list
      )
    );
  };

  const attendanceChangeHandler = (e) => {
    setPresent(e.target.checked);

    const val = values1.map((data) => {
      let bData = {
        ...data,
        isChecked: e.target.checked,
      };
      return bData;
    });
    console.log(val);
    setValues1(val);
  };

  const saveHandler = () => {
    if (values1.length !== 0) {
      let row = [];
      values1.map((data) => {
        if (data.isChecked && data.isDisabled === false) {
          row.push(data.id);
        }
      });
      onSave(row);
    }
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 600 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"Course"}
                        onChange={(e) => attendanceChangeHandler(e)}
                        checked={present}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                          padding: "0px",
                        }}
                      />
                    }
                    label={""}
                    labelPlacement="top"
                  />
                </FormGroup>
              </TableCell>
              <TableCell align="center">Sr. No.</TableCell>
              <TableCell align="center">Student Name</TableCell>
              <TableCell align="center">Fees Structure</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Paid Status</TableCell>
              <TableCell align="center">Approved Status</TableCell>
            </TableRow>
          </TableHead>
          {console.log(values1)}
          <TableBody>
            {values1.map((data) => {
              return (
                <TableRow sx={{}}>
                  <TableCell align="center">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={"isChecked"}
                            checked={data.isDisabled ? true : data.isChecked}
                          />
                        }
                        onChange={(e) =>
                          changeHandler1(e, data.studentRegistrationId)
                        }
                        value={data.isChecked}
                        labelPlacement="bottom"
                        disabled={data.isDisabled}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell align="center">{data.index}</TableCell>
                  <TableCell align="left">{data.studentName}</TableCell>
                  <TableCell align="center">{data.feesStructure}</TableCell>
                  <TableCell align="center">{data.amount}</TableCell>
                  <TableCell align="center">{data.paidStatus}</TableCell>
                  <TableCell align="center">{data.approvedStatus}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <Grid
        container
        justifyContent="space-evenly"
        direction="row"
        xs={12}
        sm={12}
        md={12}
      >
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="right"
        >
          {/* <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Back"
              onClick={backToList}
            /> */}
          &nbsp;&nbsp;
          <ButtonCompo
            size="medium"
            type="button"
            variant="contained"
            name="Send Approval"
            onClick={saveHandler}
          />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
  promotionalAdmmisionList: state.promotionalAdmmision,
  classList: state.class,
});
const mapDispatchToProps = {
  showNotification,
  getClass,
  getAlert,
  getPromotionalAdmisionAcademicYear,
  getPromotionalAdmsionStudentList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionalAdmissionTable);
