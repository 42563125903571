export const eMaterialStudentJson = {
  screenTitle: "E-Material (Student)",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getAcadamicYear: false,
  getListId: "yearDetail",
  fieldMeta: [
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "yearMaster",
      dataKey: "yearDetail",
      isMandatory: true,
      getListId: "yearDetail",
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      getListFrom: "yearDetail",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "subjectMaster",
      dataKey: "subject",
      isMandatory: true,
    },
  ],
};
