export const ComplaintReportJson = {
    apiBaseURL:
        "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
    screenTitle: "Complaint Report",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    searchButton: false,
    PDFButton: true,
    EXCELButton: true,
    marginTop: 3,
    fieldMeta: [
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-l"
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: true,
            minDate: new Date(),
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "fromDate-g"
        },
        {
            label: "Status",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "statusMaster",
            dataKey: "status",
            isMandatory: true,
        },
    ],
};
