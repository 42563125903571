import { Grid, Paper } from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import {
  getSuggestionsByDateAndDepartment,
  getSuggestionsListWithoutFilter,
  resetValues,
} from "./suggestion.slice";
import { viewSuggestionDynamicJson } from "../../DynamicFormsJson/ViewSuggestionsDynamic";
import SuggestionsAdminTable from "./SuggestionsAdminTable";

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: 100,
  },
  {
    name: "complaintDetail",
    title: "Suggestions",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    width: 300,
  },
  {
    name: "name",
    title: "Name of suggester/Mentors",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: 300,
    canSearch: true,
  },

  {
    name: "date",
    title: "Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
    width: 300,
    canSearch: true,
  },
];

class ViewSuggestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      showForm: false,
      fromDate: "",
      toDate: "",
      formErrors: [],
      dynamicMasterData: { activity: this.props.activityList?.activityByType },
      searchValue: "",
      showLoader: false,
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    this.props.resetReducList();
    this.props.getSuggestionsListWithoutFilter().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  changeHandle = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    }
  };

  onSearchData = (dataToSearch) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getSuggestionsByDateAndDepartment({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.suggestionList?.viewSuggestions.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["complaintDetail"] &&
          currentRow["complaintDetail"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["date"] &&
          currentRow["date"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        {" "}
        {showForm && (
          <>
            {/* <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <TitleAndBreadCrum title={AddSuggestionsJson.screenTitle} />
          </Paper>
          <br /> */}
          </>
        )}
        {showTable && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={viewSuggestionDynamicJson.screenTitle}
              fieldMeta={viewSuggestionDynamicJson.fieldMeta}
              buttonCenter={viewSuggestionDynamicJson.buttonCenter}
              showPdfDownload={viewSuggestionDynamicJson.showPdfDownload}
              showExcelDownload={viewSuggestionDynamicJson.showExcelDownload}
              // pdfFileName={viewSuggestionDynamicJson.pdfFileName}
              // excelFileName={viewSuggestionDynamicJson.excelFileName}
              tableColumnsToFilter={
                viewSuggestionDynamicJson.tableColumnsToFilter
              }
              showAddButton={viewSuggestionDynamicJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={columns}
              isActionColActive={false}
              tableData={this.props.suggestionList?.viewSuggestions}
              // getListById={this.getListById}
              showPegination={true}
              // showHeadEdit={true}
              showHeadDelete={false}
              showLoader={this.state.showLoader}
              // showPegination={false}
              // pdfDetailsId={this.pdfDetails}
              // rowViewData={this.onClickView}
              // rowDelete={this.rowDelete}
              // rowEdit={this.rowEdit}
              // onDelete={this.onDelete}
              onSearchData={this.onSearchData}
              baseIdColumn={viewSuggestionDynamicJson.baseIdColumn}
              apiBaseURL={viewSuggestionDynamicJson.apiBaseURL}
              tableOpen={false}
            />
            <Paper sx={{ p: 1, borderRadius: 2, mt: -4 }} elevation={0}>
              <SuggestionsAdminTable
                rows={this.props.suggestionList?.viewSuggestions}
              />
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  suggestionList: state.suggestions,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  resetValues,
  resetReducList,
  getSuggestionsByDateAndDepartment,
  getSuggestionsListWithoutFilter,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewSuggestions);

//
