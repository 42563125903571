import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAlert } from "../../../CommonActions/alert.slice";
import * as myConstClass from "../../../config/messageconstant";
import {
  getDeemedCommittee,
  getDeemedCommitteeMember,
} from "../Committee/committee.slice";
import {
  getMeetingDocument,
  getDeemedAttendanceList,
} from "../Meeting/meeting.slice";
import StudentAttandaceFormTable from "./memberAttendance";
import MinutesOfMeetingRemark from "./memberRemark";
import MinutesOfMeetingDialogue from "./minutesOfMeetingDialogue";
import MinutesOfMeetingDocument from "./minutesOfMeetingDocument";
import { deleteFile, openFileInNewTab } from "../../UploadFile/file.slice";
import endpoint from "../../../config/endpoints";
import { apiDelete, apiPost } from "../../../utils/api_service";
import swal from "sweetalert";
import { showNotification } from "../../../Pages/Landing/Landing.slice";

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "title",
    title: "Title",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "40%",
  },
  {
    name: "details",
    title: "Details",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];

const MeetingTable = ({
  getDeemedCommittee,
  getAlert,
  deemedCommitteeList,
  openForm,
  onSave,
  deemedMeetingList,
  onEdit,
  onDelete,
  onDetails,
  scheduleMeetingData,
  isDisabledFalse,
  backToForm,
  backToTable,
  getMeetingDocument,
  getDeemedAttendanceList,
  getDeemedCommitteeMember,
  openFileInNewTab,
  deleteFile,
  showView,
  showNotification,
}) => {
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const [updateStudInfo, setUpdateStudInfo] = useState(false);
  const [interestedStudent, setInterestedStudent] = useState(true);
  const [yearDetail, setYearDetail] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [row, setRow] = React.useState([]);

  const [openingRemark, setOpeningRemark] = useState("");
  const [closingRemark, setClosingRemark] = useState("");
  const [scheduleMeeting, setScheduleMeetingData] = useState({});
  const [disabled, setDisabled] = useState(true);

  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    setRow(deemedMeetingList.meetingDocument);
  };
  const rowView = (data) => {
    console.log("hello");
    const viewRow = row.filter((row) => row.id === data.id);
    openFileInNewTab({ file: viewRow[0].documentPath });
    // setOpen(true);
  };

  const setRowData = (data) => {
    if (data.id === undefined) {
      data = {
        ...data,
        id: row.length + 1,
      };
      setRow((prevData) => {
        return [...prevData, data];
      });
    } else {
      setRow((prevData) => {
        return [...prevData, data];
      });
    }
  };
  const saveHandler = (data) => {
    console.log(data);
    console.log(row);
    let list = row.map((data1) => {
      if (data1.documentId === 0) {
        let docs = {
          scheduleCommitteeMeeting: {
            id: scheduleMeetingData.id,
          },
          name: data1.docDesc,
          path: data1.documentPath,
          ext: "0",
        };
        return docs;
      } else {
        let docs = {
          id: data1.id,
          scheduleCommitteeMeeting: {
            id: scheduleMeetingData.id,
          },
          name: data1.docDesc,
          path: data1.documentPath,
          ext: "0",
        };
        return docs;
      }
    });
    console.log(list);
    apiPost({
      url: endpoint.meetingDocument + "/save-all",
      postBody: list,
    }).then(({ data, success }) => {
      if (success) {
        showNotification({
          msg: "Data saved successfully",
        });
        backToTable();
      }
    });
  };

  const openFormHandler = () => {
    openForm();
  };

  const editInfo = (data) => {
    onEdit(data);
  };

  const deleteInfo = (data) => {
    onDelete(data);
  };
  const rowDetails = (data) => {
    console.log(data);
    onDetails(data);
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["committeeError"] = myConstClass.committeeMsg;
    }

    if (fromDate === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const changeHandler = (e, values) => {
    const { value, name } = e.target;
    console.log(value);
    console.log(name);
    console.log(values);

    if (name === "openingRemark") {
      setOpeningRemark(value);
    } else {
      setClosingRemark(value);
    }
  };

  const changeFromDate = (date) => {
    console.log(date);

    if (date !== null) {
      setFromDate(date);
    }
  };

  const changeToDate = (date) => {
    console.log(date);

    if (date !== null) {
      setToDate(date);
    }
  };

  useEffect(() => {
    getDeemedCommittee().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getMeetingDocument({ id: scheduleMeetingData.id, ext: "0" }).then(
      (response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        } else {
          setRow(deemedMeetingList.meetingDocument);
        }
      }
    );

    setScheduleMeetingData(scheduleMeetingData);
    // getDeemedAttendanceList({ id: scheduleMeetingData.id }).then((response) => {
    //   if (!response) {
    //     getAlert({ message: "Something went wrong" });
    //   } else {
    //     getDeemedCommitteeMember({ id: scheduleMeetingData.id }).then(
    //       (response) => {
    //         if (!response) {
    //           getAlert({ message: "Something went wrong" });
    //         }
    //       }
    //     );
    //   }
    // });
  }, []);

  const rowDelete = (data) => {
    console.log("hello delete");
    console.log(row);

    const viewRow = row.filter((row) => row.id === data.id);

    console.log(viewRow);
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFile({ file: viewRow[0].documentPath }).then(() => {});
        const rowData = row.filter((row) => row.id !== data.id);
        setRow(rowData);
        if (data.documentId !== 0) {
          apiDelete({
            url: endpoint.meetingDocument + "/" + data.id,
          }).then(({ success }) => {
            if (!success) {
              getAlert({
                message: "Failed to delete",
              });
            }
          });
        }
      }
    });
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const isDisabledHandler = (value) => {
    console.log(value);
    setDisabled(value);
  };

  const isNextTab = () => {
    setValue(1);
  };
  const goToAgenda = () => {
    setValue(2);
  };
  const goToDocument = () => {
    setRow(deemedMeetingList.meetingDocument);
    setValue(3);
  };
  const backToAgenda = () => {
    setValue(2);
  };
  const backToAttendance = () => {
    setValue(1);
  };
  const backToDetails = () => {
    setValue(0);
  };

  return (
    <>
      {interestedStudent && (
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handledChange}
                aria-label="basic tabs example"
              >
                <Tab label={"Remark "} {...a11yProps(0)} />
                <Tab label={"Attendance "} {...a11yProps(1)} />
                <Tab label={"Agenda "} {...a11yProps(2)} />

                <Tab label={"Document "} {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <MinutesOfMeetingRemark
                scheduleMeeting={scheduleMeetingData}
                isDisabledFalse={isDisabledHandler}
                isNextTab={isNextTab}
                backToForm={backToForm}
                showView={showView}
              />
            </TabPanel>
          </Paper>
          <TabPanel value={value} index={1}>
            <StudentAttandaceFormTable
              saveFormData={scheduleMeetingData}
              goToAgenda={goToAgenda}
              backToDetails={backToDetails}
              showView={showView}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MinutesOfMeetingDialogue
              scheduleMeetingData={scheduleMeetingData}
              backToAttendance={backToAttendance}
              goToDocument={goToDocument}
              showView={showView}
            />
          </TabPanel>

          <TabPanel value={value} index={3}>
            {/* <MinutesOfMeetingDocument
              scheduleMeetingData={scheduleMeetingData}
              backToAgenda={backToAgenda}
            /> */}

            <MinutesOfMeetingDocument
              row={row}
              rowView={rowView}
              rowData={setRowData}
              rowDelete={rowDelete}
              saveHandler={saveHandler}
              backToAgenda={backToAgenda}
              showView={showView}
            />
          </TabPanel>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  deemedMeetingList: state.deemedMeeting,
  deemedCommitteeList: state.deemedCommittee,
});

const mapDispatchToProps = {
  getDeemedCommittee,
  getMeetingDocument,
  getDeemedAttendanceList,
  getDeemedCommitteeMember,
  openFileInNewTab,
  getAlert,
  deleteFile,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(MeetingTable);
