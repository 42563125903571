import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import endpoint from "../../../config/endpoints";
import { showNotification } from "../../Landing/Landing.slice";
import { apiPost, apiDelete } from "../../../utils/api_service";
import AddFeeback from "./AddFeedback";
import RTTable from "../../../components/Comman/RT/RTTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddQuestion from "./AddQuestion";
import * as myConstClass from "../../../config/messageconstant";
import { resetReducList } from "../../../CommonActions/User.action";
import SearchFeedbackDetList from "../../../components/SearchTable";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { addFeedbackDynamicJson } from "../../../DynamicFormsJson/AddFeedbackDynamic";
import {
  getfeedBackDetailsByFeedBackId,
  getFeedBack,
  getfeedBackId,
  resetFeedBackDetails,
  resetValues,
} from "./addFeedback.slice";
import AddOptionForm from "./AddOptionForm";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import swal from "sweetalert";
const AdmissionProcess = ({
  showNotification,
  getfeedBackDetailsByFeedBackId,
  getFeedBack,
  getAlert,
  feedBack,
  getfeedBackId,
  resetFeedBackDetails,
  resetReducList,
  searchButton = false,
  resetValues,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [openAddQuestionForm, setopenAddQuestionForm] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [openOptions, setopenOptions] = useState(false);
  const [feedBackId, setFeedBackId] = useState("");
  const [feedBackDetails, setFeedBackDetails] = useState("");
  const addQuestions = (feedBack) => {
    setFeedBackId(feedBack);
    setopenAddQuestionForm(true);
  };
  useEffect(() => {
    resetReducList();
    setShowLoader(true);
    getFeedBack().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({
          message: "Server Failed",
        });
      } else {
        setShowLoader(false);
      }
    });
  }, []);
  const handleCloses = () => {
    setopenAddQuestionForm(false);
  };
  const closedOptions = () => {
    setopenOptions(false);
  };
  const OnSaveQuestion = (questionData) => {
    let dataToSave = {
      feedbackHead: {
        id: questionData.feedBackId,
      },
      optionType: questionData.optionType,
      question: questionData.question,
      orderNo: questionData.orderNo,
      isActive: 1,
      feedbackHeadDetailsOptions: [],
    };
    if (questionData.id) {
      dataToSave = {
        ...dataToSave,
        id: questionData.id,
      };
    }
    if (questionData.optionType == 1) {
      apiPost({
        url: endpoint.feedBackDetails,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showNotification({
            msg: "Data saved successfully",
          });
          setopenAddQuestionForm(false);
          getfeedBackDetailsByFeedBackId({
            feedBackId: questionData.feedBackId,
          }).then((response) => {
            if (!response) {
              getAlert({
                message: "Server Failed",
              });
            }
          });
          resetFeedBackDetails();
        } else {
          getAlert({
            message: "Failed to save",
          });
        }
      });
    } else {
      setopenOptions(true);
      setFeedBackDetails(dataToSave);
    }
  };
  const OnSaveOption = (optionList) => {
    const dataToSave = {
      ...feedBackDetails,
      feedbackHeadDetailsOptions: optionList.map((optionData) => {
        let option = {
          optionForSelection: optionData.option,
          isActive: 1,
          orderNo: optionData.orderNo,
        };
        if (optionData.id) {
          option = {
            ...option,
            id: optionData.id,
          };
        }
        return option;
      }),
    };
    apiPost({
      url: endpoint.feedBackDetails,
      postBody: dataToSave,
    }).then(({ data, success }) => {
      if (success) {
        showNotification({
          msg: "Data saved successfully",
        });
        setopenAddQuestionForm(false);
        setopenOptions(false);
        setFeedBackDetails("");
        resetFeedBackDetails();
        getfeedBackDetailsByFeedBackId({
          feedBackId: feedBackId,
        }).then((response) => {
          if (!response) {
            getAlert({
              message: "Server Failed",
            });
          }
        });
      } else {
        getAlert({
          message: "Failed to save",
        });
      }
    });
  };
  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "name",
      title: "Feedback name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "remark",
      title: "Remark",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "isActive",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "5%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: 150,
    },
  ];
  const rowEdit = (rowdata) => {
    getfeedBackId({ feedbackId: rowdata.id }).then((resp) => {
      if (!resp) {
        getAlert({
          message: "Server Failed",
        });
      } else {
        setShowTable(!showTable);
      }
    });
  };
  const rowDelete = (rowdata) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoint.feedBack + "/" + rowdata.id,
        }).then(({ success }) => {
          if (success) {
            showNotification({ msg: "Data deleted successfully" });
            getFeedBack().then((response) => {
              if (!response) {
                getAlert({
                  message: "Server Failed",
                });
              }
            });
          } else {
            getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };
  const onChangeStatus = (rowdata) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        rowdata = {
          ...rowdata,
          isActive: rowdata.isActive === 0 ? 1 : 0,
        };
        apiPost({
          url: endpoint.feedBack,
          postBody: rowdata,
        }).then(({ data, success }) => {
          if (success) {
            showNotification({
              msg: "Status updated",
            });

            getFeedBack().then((response) => {
              if (!response) {
                getAlert({
                  message: "Server Failed",
                });
              }
            });
          } else {
            getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });
  };
  const onAddButtonClick = () => {
    setShowTable(!showTable);
    resetValues().then((response) => {});
    setFeedBackDetails("");
    // getFeedBack().then((response) => {
    //   if (!response) {
    //     getAlert({
    //       message: "Server Failed",
    //     });
    //   }
    // });
  };
  const saveData = () => {
    setopenAddQuestionForm(false);
    setopenOptions(false);
    setShowTable(false);
    resetReducList();
    getFeedBack().then((response) => {
      if (!response) {
        getAlert({
          message: "Server Failed",
        });
      } else {
        console.log("hiiiiiiiiiiiiiiii");
      }
    });
  };
  const getFilteredTableData = () => {
    return feedBack.feedBack.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["remark"] &&
          currentRow["remark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["isActive"] &&
          currentRow["isActive"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <>
      {!showTable && (
        <>
          <DynamicMainScreen
            onAddButtonClick={onAddButtonClick}
            screenTitle={addFeedbackDynamicJson.screenTitle}
            fieldMeta={addFeedbackDynamicJson.fieldMeta}
            buttonCenter={addFeedbackDynamicJson.buttonCenter}
            showPdfDownload={addFeedbackDynamicJson.showPdfDownload}
            showExcelDownload={addFeedbackDynamicJson.showExcelDownload}
            // pdfFileName={addFeedbackDynamicJson.pdfFileName}
            // excelFileName={addFeedbackDynamicJson.excelFileName}
            tableColumnsToFilter={addFeedbackDynamicJson.tableColumnsToFilter}
            showAddButton={addFeedbackDynamicJson.showAddButton}
            dynamicMasterData={[]}
            tableColumns={columns}
            isActionColActive={true}
            tableData={feedBack.feedBack}
            // getListById={this.getListById}
            showLoader={showLoader}
            showPegination={true}
            showHeadEdit={true}
            showHeadDelete={true}
            showAppliedList={false}
            // showPegination={false}
            // pdfDetailsId={this.pdfDetails}
            // rowViewData={this.onClickView}
            // showLinkIcon={true}
            // generateDetails={generateDetails}
            rowDelete={rowDelete}
            rowEdit={rowEdit}
            searchButton={searchButton}
            rowStatus={onChangeStatus}
            // rowAppliedList={this.appliedList}
            // onDelete={this.onDelete}
            // onSearchData={onSearchData}
            baseIdColumn={addFeedbackDynamicJson.baseIdColumn}
            apiBaseURL={addFeedbackDynamicJson.apiBaseURL}
          />
        </>
      )}{" "}
      {showTable && (
        <>
          <AddFeeback
            onCancel={onAddButtonClick}
            addQuestions={addQuestions}
            saveData={saveData}
          />
          {openAddQuestionForm && (
            <AddQuestion
              open={openAddQuestionForm}
              handleCloses={handleCloses}
              onSave={OnSaveQuestion}
              feedBackId={feedBackId}
              // editData={editData}
            />
          )}
          {openOptions && (
            <AddOptionForm
              open={openOptions}
              handleCloses={closedOptions}
              onSave={OnSaveOption}
              feedbackDetail={feedBackDetails}
              // editData={editData}
            />
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({ feedBack: state.feedBack });
const mapDispatchToProps = {
  showNotification,
  getfeedBackDetailsByFeedBackId,
  getFeedBack,
  getAlert,
  getfeedBackId,
  resetFeedBackDetails,
  resetReducList,
  resetValues,
  resetFeedBackDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionProcess);
