import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import DynamicForm from "../../components/dynamicscreens/DynamicFormWithTable";
import { VerificationDocumentJson } from "../../DynamicFormsJson/VerificationDocumentJson";
import {
  getDocumentVerification,
  getDocumentVerificationDataList,
  getProgramTitle,
  getRoundDetails
} from "../DocumentVerification/documentVerification.slice";
import { Loading1 } from "../../components/Loading1";
class VerificationDocumentForm extends React.Component {
  state = {
    id: 0,
    typeOfAdmission: "",
    roundDetails: "",
    allotmentStatus: [],
    programtitle: "",
    application: "",
    fieldData: {},
    formErrors: [],
    showLoader: false,
    dynamicMasterData: {
      applicationNameMaster:
        this.props.documentVerificationList.documentVerification.filter(
          (row) => row.isActive === 1
        ),
      roundDetailMaster: this.props.documentVerificationList.roundDetails,
      programTitleMaster: this.props.documentVerificationList.programTitle,
      allotmentStatusMaster: [
        { id: "0", name: "Waiting" },
        { id: "1", name: "Allocated" },
        { id: "2", name: "Confirm Admission" },
        { id: "3", name: "Rejected Admission" },
        { id: "4", name: "Cancelled Admission" },
        { id: "5", name: "Cancelled Waiting List" },
        { id: "6", name: "System Cancelled (institute_status)" },
      ],
    },
  };

  componentDidMount() {
    const { getDocumentVerification } = this.props;
    getDocumentVerification().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.documentVerificationList !== nextProps.documentVerificationList
    ) {
      if (
        this.props.documentVerificationList.documentVerification.filter(
          (row) => row.isActive === 1
        ) !==
        nextProps.documentVerificationList.documentVerification.filter(
          (row) => row.isActive === 1
        )
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            applicationNameMaster:
              nextProps.documentVerificationList.documentVerification.filter(
                (row) => row.isActive === 1
              ),
          },
        });
      }
      if (
        this.props.documentVerificationList.roundDetails !==
        nextProps.documentVerificationList.roundDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            roundDetailMaster: nextProps.documentVerificationList.roundDetails,
          },
        });
      }
      if (
        this.props.documentVerificationList.programTitle !==
        nextProps.documentVerificationList.programTitle
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programTitleMaster: nextProps.documentVerificationList.programTitle,
          },
        });
      }
    }
  }
  getListById = (data) => {
    if (data.applicationName) {
      this.setState({
        showLoader:true
      })
      this.props
        .getRoundDetails({ roundId: data.applicationName })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader:false
            })
            this.props.getAlert({ message: "Something went wrong" });
          }else{
            this.setState({
              showLoader:false
            })
          }
        });
        this.props
        .getProgramTitle({ roundId: data.applicationName })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader:false
            })
            this.props.getAlert({ message: "Something went wrong" });
          }else{
            this.setState({
              showLoader:false
            })
          }
        });
    }
  };
  // changeHandlerSearch = (name, newValue) => {
  //   if (newValue !== null) {
  //     this.setState({ [name]: newValue });
     
  //     if (name === "application") {
  //       this.setState({
  //         roundDetails: "",
  //         programtitle: "",
  //       });
  //       const { getRoundDetails, getProgramTitle } = this.props;
  //       getRoundDetails({ roundId: newValue }).then((response) => {
  //         if (!response) {
  //           this.props.getAlert({ message: "Something went wrong" });
  //         }
  //       });
  //       getProgramTitle({ roundId: newValue }).then((response) => {
  //         if (!response) {
  //           this.props.getAlert({ message: "Something went wrong" });
  //         }
  //       });
  //     }
  //   } else {
  //     this.setState({ [name]: "" });
  //   }
  // };

  // changeHandler = (event) => {
  //   const { name, value } = event.target;
  //   this.setState({
  //     ...this.state,
  //     [name]: value,
  //   });
  // };

  // handleFormValidation(name) {
  //   const { application, roundDetails, programtitle } = this.state;
  //   let formErrors = {};
  //   let formIsValid = true;
  //   if (application.toString().trim() === "" || application === null) {
  //     formIsValid = false;
  //     formErrors["applicationErr"] = myConstClass.applicationIdMessage;
  //   }
  //   if (roundDetails.toString().trim() === "" || roundDetails === null) {
  //     formIsValid = false;
  //     formErrors["roundDetailsErr"] = myConstClass.roundDetailsMsg;
  //   }

  //   if (programtitle.toString().trim() === "" || programtitle === null) {
  //     formIsValid = false;
  //     formErrors["programtitleErr"] = myConstClass.programTitleMsg;
  //   }
  //   this.setState({ formErrors: formErrors });
  //   return formIsValid;
  // }

  submitHandler = () => {
    const { fieldData } = this.state;
    const {
      getDocumentVerificationDataList,
      onSave,
      getAlert,
      showNotification,
    } = this.props;
    const { application, programtitle, roundDetails, allotmentStatus } =
      this.state;
      this.setState({
        showLoader:true
      })
      getDocumentVerificationDataList({
        appId:  fieldData.applicationName,
        progId: fieldData.programTitle,
        roundId: fieldData.roundDetail,
        acceptId: 1,
      }).then((response) => {
        if (!response) {
          this.setState({
            showLoader:false
          })
          getAlert({ message: "Something went wrong" });
        }else{
          this.setState({
            showLoader:false
          })
        }
      });

    let data = {
      appId: fieldData.applicationName,
      progId: fieldData.programTitle,
      roundId: fieldData.roundDetail,
      acceptId: 2,
    };
    onSave(data);
  };
  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });

    // setType(typeof value === "string" ? value.split(",") : value);
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = {};
    let listError = false;
    VerificationDocumentJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  render() {
    const {
      roundDetails,
      programtitle,
      application,
      dynamicMasterData,
      fieldData,
    } = this.state;
    const { documentVerificationList } = this.props;

    const { applicationErr, roundDetailsErr, programtitleErr } =
      this.state.formErrors;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {this.state.showLoader && <Loading1 />}
        <DynamicForm
            fieldMeta={VerificationDocumentJson.fieldMeta}
            dynamicMasterData={dynamicMasterData}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            onSave={this.submitHandler}
            buttonCenter={VerificationDocumentJson.buttonCenter}
            marginTop={0}
          />
          {/* <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
            sx={{ pl: 1 }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={2}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Verification Document  "
                />
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={6}
                md={6}
                sx={{ marginTop: 3 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Application Name "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={application}
                    name={"application"}
                    options={documentVerificationList.documentVerification.filter(
                      (row) => row.isActive === 1
                    )}
                    onChange={this.changeHandlerSearch}
                    isError={applicationErr ? true : false}
                    errorText={applicationErr ? applicationErr : " "}
                  />
                     </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={6}
                md={6}
                sx={{ marginTop: 3 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Round Details   "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={roundDetails}
                    name={"roundDetails"}
                    options={documentVerificationList.roundDetails}
                    onChange={this.changeHandlerSearch}
                    isError={roundDetailsErr ? true : false}
                    errorText={roundDetailsErr ? roundDetailsErr : " "}
                  />
              
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={6}
                md={6}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Program Title"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={programtitle}
                    name={"programtitle"}
                    options={documentVerificationList.programTitle}
                    onChange={this.changeHandlerSearch}
                    isError={programtitleErr ? true : false}
                    errorText={programtitleErr ? programtitleErr : " "}
                  />
                
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={6}
                md={6}
                sx={{ marginTop: 2, display: "block" }}
              >
                <Grid
                  sx={{ ml: 0 }}
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="left"
                  m={1}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Search"
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {
  getDocumentVerification,
  getRoundDetails,
  getProgramTitle,
  getDocumentVerificationDataList,
  getAlert,
  showNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationDocumentForm);
