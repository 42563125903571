export const FeesCollectionDynamicJson = {
    screenTitle: "Admited Student Fees",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter:false,
    getAcadamicYear:true,
    getListId:'yearDetail',
    fieldMeta: [
      {
        label: "Year Detail",
        controlType: "autocomplete",
        placeHolder: "",
        md: 4,
        lg: 4,
        sm: 4,
        xs: 12,
        masterName: "yearDetailMaster",
        dataKey: "yearDetail",
        isMandatory: true,
      },
    ],
  };
  