import {
  Box,
  Checkbox,
  FormGroup,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../../components/Comman/Label";
import CompletedSyllabusView from "../AddStudentAttandance/CompletedSyllabusView";
import { getSyllabus } from "../../TeachingPlan/teachingPlan.slice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CoordinatorPreviewTable = ({
  rowData,
  data,
  studentAttendanceListView,
  getSyllabus,
  teachingPlanList,
}) => {
  console.log(rowData);
  const [changeValue, setChangeValue] = useState(0);
  const [values, setValues] = useState(
    rowData.studentAttendanceDetails ? rowData.studentAttendanceDetails : []
  );
  const [syllabus, setSyllabus] = useState([]);
  console.log(teachingPlanList.syllabus.details);
  const presentData = values.filter((row) => row.attendance === true);
  const presentCount = presentData.length;
  console.log(presentCount);

  const absentData = values.filter((row) => row.attendance === false);
  const absentCount = absentData.length;
  console.log(absentCount);

  const valueCount = values.length;
  console.log(valueCount);

  useEffect(() => {
    if (
      Object.keys(rowData).length !== 0 &&
      !teachingPlanList.isFetchSyllabus
    ) {
      getSyllabus({
        subjectDetailId: rowData.subjectDetail,
      }).then((resp) => {
        if (!resp) {
        }
      });
    }
    if (syllabus.length === 0 && teachingPlanList.isFetchSyllabus) {
      setSyllabus(teachingPlanList.syllabus.details);
    }

    if (values.length === 0) {
      setValues(
        rowData.studentAttendanceDetails ? rowData.studentAttendanceDetails : []
      );
    }
  });
  const handledChange = (event, newValue) => {
    setChangeValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={changeValue}
          onChange={handledChange}
          aria-label="basic tabs example"
        >
          <Tab label={"Basic info"} {...a11yProps(0)} />
          <Tab
            // disabled={
            //   Object.keys(this.statesaveFormData).length !== 0
            //     ? false
            //     : true
            // }
            label={"Student attendance"}
            {...a11yProps(1)}
          />
          <Tab
            // disabled={
            //   Object.keys(this.state.saveFormData).length !== 0 &&
            //   this.state.saveFormData.studentAttendanceDetails
            //     ? false
            //     : true
            // }
            label={"Syllabus "}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={changeValue} index={0}>
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          spacing={2}
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Semester"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.semisterName}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Division"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.divisionName}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Batch"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.batchName}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Subject"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.subjectName}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Time Slot"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.timeSlotName}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Attendance Date"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.attendanceDate}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Remark"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.remark}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Topic Taught"
              />
            </Grid>
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={rowData.topicTaught}
              />
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={changeValue} index={1}>
        <TableContainer>
          <Table sx={{ minWidth: 600 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Sr. No.</TableCell>
                <TableCell align="center">Student Name</TableCell>
                <TableCell align="center">Roll No</TableCell>
                <TableCell align="center">PRN Number</TableCell>
                <TableCell align="center">Present</TableCell>
                <TableCell align="center">Excuse</TableCell>
                <TableCell align="center">Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((data) => {
                return (
                  <TableRow>
                    <TableCell align="center">{data.index}</TableCell>
                    <TableCell align="left">{data.name}</TableCell>
                    <TableCell align="center">{data.rollNo}</TableCell>
                    <TableCell align="center">{data.prnNo}</TableCell>
                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"Course"}
                              checked={data.attendance}
                            />
                          }
                          value={data.attendance}
                          labelPlacement="bottom"
                          disabled={true}
                        />
                      </FormGroup>
                    </TableCell>

                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox name={"Course"} checked={data.excuse} />
                          }
                          value={data.excuse}
                          labelPlacement="bottom"
                          disabled={true}
                        />
                      </FormGroup>
                    </TableCell>

                    <TableCell>{data.answer}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />

        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          xs={12}
          sm={12}
          md={12}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={2}
            xs={12}
            sm={4}
            md={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Total Student : "
              />
              {valueCount === "" ? 0 : valueCount}
            </Grid>

            <Grid item xs={12} sm={12} md={12}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={2}
            xs={12}
            sm={4}
            md={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Present Student : "
              />
              {presentCount === "" ? 0 : presentCount}
            </Grid>

            <Grid item xs={12} sm={12} md={12}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={4}
            md={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Absent Student : "
              />
              {absentCount === "" ? 0 : absentCount}
            </Grid>

            <Grid item xs={12} sm={12} md={12}></Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={changeValue} index={2}>
        <CompletedSyllabusView
          editSyllabus={studentAttendanceListView?.studentAttendanceSyllabus}
          changeSyllabus={setSyllabus}
          syllabus={syllabus}
        />
      </TabPanel>

      <div align="center"></div>
    </>
  );
};

const mapStateToProps = (state) => ({
  studentAttendanceListView: state.studentAttendance,
  teachingPlanList: state.teachingPlan,
});
const mapDispatchToProps = { getSyllabus };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoordinatorPreviewTable);
