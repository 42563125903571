import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  pendingCertificate: [],
  isFetchStatus: false,
  verifyCertificate: [],
  isFetchVerifyStatus: false,
  acceptedCertificate: [],
  isFetchAcceptedStatus: false,
  preparedCertificate: [],
  isFetchPreparedStatus: false,
  deliveredCertificate: [],
  isFetchDeliveredStatus: false,
  rejectedCertificate: [],
  isFetchRejectedStatus: false,
};

let URL = endpoints.certificate;

const adminChangeStatusSlice = createSlice({
  name: "adminChangeStatus",
  initialState,
  reducers: {
    pendingCertificateListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        pendingCertificate: row,
        isFetchStatus: true,
      };
    },
    verifyCertificateListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        verifyCertificate: row,
        isFetchVerifyStatus: true,
      };
    },
    acceptedCertificateListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        acceptedCertificate: row,
        isFetchAcceptedStatus: true,
      };
    },
    preparedCertificateListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        preparedCertificate: row,
        isFetchPreparedStatus: true,
      };
    },
    deliveredCertificateListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        deliveredCertificate: row,
        isFetchDeliveredStatus: true,
      };
    },
    rejectedCertificateListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        rejectedCertificate: row,
        isFetchRejectedStatus: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
    resetTableListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        companyListStatus: [],
        isFetchStatus: false,
      };
    },
  },
});

export const {
  resetTableListSuccess,
  rejectedCertificateListSuccess,
  deliveredCertificateListSuccess,
  pendingCertificateListSuccess,
  verifyCertificateListSuccess,
  acceptedCertificateListSuccess,
  preparedCertificateListSuccess,
  resetSuccess,
} = adminChangeStatusSlice.actions;

export default adminChangeStatusSlice.reducer;

export const getPendingCertificateList = () => async (dispatch) => {
  try {
    // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
    // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
    // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
    const response = apiGet({
      url: URL + "/get-student-certificate-application-by-status?status=0",
    }).then(({ data, success }) => {
      if (success) {
        const pendingCertificateList = data.data;
        let row = [];
        let index = 0;
        row = pendingCertificateList.map((data) => {
          index = index + 1;
          let companyData = {
            index: index,
            id: data.id,
            documentUpload:
              data.documentUpload === null ? "" : data.documentUpload,
            name:
              data.userRegistration === null
                ? ""
                : data.userRegistration.lastName +
                  " " +
                  data.userRegistration.firstName +
                  " " +
                  (data.userRegistration.fatherName == null ||
                  data.userRegistration.fatherName == ""
                    ? " - "
                    : data.userRegistration.fatherName) +
                  " " +
                  (data.userRegistration.motherName == null ||
                  data.userRegistration.motherName == ""
                    ? " - "
                  : data.userRegistration.motherName ),
            
            
            
          lastName: data.userRegistration.lastName,
		      firstName:   data.userRegistration.firstName ,
		      fatherName: data.userRegistration.fatherName,
		      motherName:   data.userRegistration.motherName ,
		      
            appliedDate: data.applyDate === null ? "" : data.applyDate,
            certificateApplicationHeaderId:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.id,
            userRegistrationId:
              data.userRegistration === null ? "" : data.userRegistration.id,
            cetificateName:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.cetificate_name,
            isPaymentApplicable:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.is_payment_applicable,
            deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
            detail: data.detail === null ? "" : data.detail,
            payDetail: data.payDetail === null ? "" : data.payDetail,
            isPaid: data.isPaid === null ? "" : data.isPaid,
            status: data.status === 0 ? "Pending" : "",
            remark: data.adminRemark ? data.adminRemark : "",
            userRemark: data.userRemark ? data.userRemark : "-",
            type: data.certificateApplicationHeader.type,
            amount:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.amount,
          };
          return companyData;
        });
        dispatch(pendingCertificateListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getVerifyCertificateList = () => async (dispatch) => {
  try {
    // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
    // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
    // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
    const response = apiGet({
      url: URL + "/get-student-certificate-application-by-status?status=1",
    }).then(({ data, success }) => {
      if (success) {
        const VerifyCertificateList = data.data;
        let row = [];
        let index = 0;
        row = VerifyCertificateList.map((data) => {
          index = index + 1;
          let companyData = {
            index: index,
            id: data.id,
            name:
              data.userRegistration === null
                ? ""
                : data.userRegistration.lastName +
                  " " +
                  data.userRegistration.firstName +
                  " " +
                  (data.userRegistration.fatherName == null ||
                  data.userRegistration.fatherName == ""
                    ? " - "
                    : data.userRegistration.fatherName) +
                  " " +
                  (data.userRegistration.motherName == null ||
                  data.userRegistration.motherName == ""
                    ? " - "
                  : data.userRegistration.motherName ),
            

                  lastName: data.userRegistration.lastName,
                  firstName:   data.userRegistration.firstName ,
                  fatherName: data.userRegistration.fatherName,
                  motherName:   data.userRegistration.motherName ,
            appliedDate: data.applyDate === null ? "" : data.applyDate,
            certificateApplicationHeaderId:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.id,
            userRegistrationId:
              data.userRegistration === null ? "" : data.userRegistration.id,
            cetificateName:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.cetificate_name,
            isPaymentApplicable:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.is_payment_applicable,
            documentUpload:
              data.documentUpload === null ? "" : data.documentUpload,
            deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
            detail: data.detail === null ? "" : data.detail,
            payDetail: data.payDetail === null ? "" : data.payDetail,
            isPaid: data.isPaid === null ? "" : data.isPaid,
            status: data.status === 1 ? "Verify" : "",
            // remark: data.adminRemark ? data.adminRemark : "No Remarks",
            remark: data.adminRemark ? data.adminRemark : "",
            userRemark: data.userRemark ? data.userRemark : "No Remarks",
            amount:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.amount,
          };
          return companyData;
        });
        dispatch(verifyCertificateListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAcceptedCertificateList = () => async (dispatch) => {
  try {
    // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
    // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
    // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
    const response = apiGet({
      url: URL + "/get-student-certificate-application-by-status?status=2",
    }).then(({ data, success }) => {
      if (success) {
        const acceptedCertificateList = data.data;
        let row = [];
        let index = 0;
        row = acceptedCertificateList.map((data) => {
          index = index + 1;
          let companyData = {
            index: index,
            id: data.id,
            name:
              data.userRegistration === null
                ? ""
                : data.userRegistration.lastName +
                  " " +
                  data.userRegistration.firstName +
                  " " +
                  (data.userRegistration.fatherName == null ||
                  data.userRegistration.fatherName == ""
                    ? " - "
                    : data.userRegistration.fatherName) +
                  " " +
                  (data.userRegistration.motherName == null ||
                  data.userRegistration.motherName == ""
                    ? " - "
                  : data.userRegistration.motherName ),
            
            
                  lastName: data.userRegistration.lastName,
                  firstName:   data.userRegistration.firstName ,
                  fatherName: data.userRegistration.fatherName,
                  motherName:   data.userRegistration.motherName ,
            appliedDate: data.applyDate === null ? "" : data.applyDate,
            certificateApplicationHeaderId:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.id,
            userRegistrationId:
              data.userRegistration === null ? "" : data.userRegistration.id,
            cetificateName:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.cetificate_name,
            isPaymentApplicable:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.is_payment_applicable,
            documentUpload:
              data.documentUpload === null ? "" : data.documentUpload,
            deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
            detail: data.detail === null ? "" : data.detail,
            payDetail: data.payDetail === null ? "" : data.payDetail,
            isPaid: data.isPaid === null ? "" : data.isPaid,
            status: data.status === 2 ? "Accepted" : "",
            // remark: data.adminRemark ? data.adminRemark : "No Remarks",
            remark: data.adminRemark ? data.adminRemark : "",
            userRemark: data.userRemark ? data.userRemark : "No Remarks",
            amount:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.amount,
          };
          return companyData;
        });
        dispatch(acceptedCertificateListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPreparedCertificateList = () => async (dispatch) => {
  try {
    // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
    // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
    // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
    const response = apiGet({
      url: URL + "/get-student-certificate-application-by-status?status=3",
    }).then(({ data, success }) => {
      if (success) {
        const preparedCertificateList = data.data;
        let row = [];
        let index = 0;
        row = preparedCertificateList.map((data) => {
          index = index + 1;
          let companyData = {
            index: index,
            id: data.id,
            name:
              data.userRegistration === null
                ? ""
                : data.userRegistration.lastName +
                  " " +
                  data.userRegistration.firstName +
                  " " +
                  (data.userRegistration.fatherName == null ||
                  data.userRegistration.fatherName == ""
                    ? " - "
                    : data.userRegistration.fatherName) +
                  " " +
                  (data.userRegistration.motherName == null ||
                  data.userRegistration.motherName == ""
                    ? " - "
                  : data.userRegistration.motherName ),
            
                  lastName: data.userRegistration.lastName,
                  firstName:   data.userRegistration.firstName ,
                  fatherName: data.userRegistration.fatherName,
                  motherName:   data.userRegistration.motherName ,
            appliedDate: data.applyDate === null ? "" : data.applyDate,
            certificateApplicationHeaderId:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.id,
            userRegistrationId:
              data.userRegistration === null ? "" : data.userRegistration.id,
            cetificateName:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.cetificate_name,
            isPaymentApplicable:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.is_payment_applicable,
            deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
            documentUpload:
              data.documentUpload === null ? "" : data.documentUpload,
            detail: data.detail === null ? "" : data.detail,
            payDetail: data.payDetail === null ? "" : data.payDetail,
            isPaid: data.isPaid === null ? "" : data.isPaid,
            type: data.certificateApplicationHeader.type,
            status: data.status === 3 ? "Prepared" : "",
            // remark: data.adminRemark ? data.adminRemark : "No Remarks",
            remark: data.adminRemark ? data.adminRemark : "",
            userRemark: data.userRemark ? data.userRemark : "No Remarks",
            amount:
              data.certificateApplicationHeader === null
                ? ""
                : data.certificateApplicationHeader.amount,
          };
          return companyData;
        });
        dispatch(preparedCertificateListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getDeliveredCertificateList =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL +
          "/get-student-certificate-application-by-status-and-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&status=4",
      }).then(({ data, success }) => {
        if (success) {
          const preparedCertificateList = data.data;
          let row = [];
          let index = 0;
          row = preparedCertificateList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              id: data.id,
              name:
                data.userRegistration === null
                  ? ""
                  : data.userRegistration.lastName +
                    " " +
                    data.userRegistration.firstName +
                    " " +
                    (data.userRegistration.fatherName == null ||
                    data.userRegistration.fatherName == ""
                      ? " - "
                      : data.userRegistration.fatherName) +
                    " " +
                    (data.userRegistration.motherName == null ||
                    data.userRegistration.motherName == ""
                      ? " - "
                    : data.userRegistration.motherName ),
              
                    lastName: data.userRegistration.lastName,
                    firstName:   data.userRegistration.firstName ,
                    fatherName: data.userRegistration.fatherName,
                    motherName:   data.userRegistration.motherName ,
              appliedDate: data.applyDate === null ? "" : data.applyDate,
              certificateApplicationHeaderId:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.id,
              userRegistrationId:
                data.userRegistration === null ? "" : data.userRegistration.id,
              cetificateName:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.cetificate_name,
              isPaymentApplicable:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.is_payment_applicable,
              deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
              detail: data.detail === null ? "" : data.detail,
              payDetail: data.payDetail === null ? "" : data.payDetail,
              isPaid: data.isPaid === null ? "" : data.isPaid,
              documentUpload:
                data.documentUpload === null ? "" : data.documentUpload,
              status: data.status === 4 ? "Delivered" : "",
              // remark: data.adminRemark ? data.adminRemark : "No Remarks",
              remark: data.adminRemark ? data.adminRemark : "",
              userRemark: data.userRemark ? data.userRemark : "No Remarks",
              type: data.certificateApplicationHeader.type,
              amount:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.amount,
            };
            return companyData;
          });
          dispatch(deliveredCertificateListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// new Filter

// URL +
//   "/get-employee-publication-list-by-fromDate-toDate?fromDate=" +
//   fromDate +
//   "&toDate=" +
//   toDate +
//   '?filters={"formType":"' +
//   "1" +
// '","_mode" : "EXACT"}',
export const getDeliveredCertificateListFilter =
  ({ status }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          '/get-student-certificate-application-by-status-date-and-limit?filters={"status": ' +
          status +
          ',"_mode" : "EXACT"}&offset=0&limit=20',
      }).then(({ data, success }) => {
        if (success) {
          const preparedCertificateList = data.data.content;
          let row = [];
          let index = 0;
          row = preparedCertificateList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              id: data.id,
              name:
                data.userRegistration === null
                  ? ""
                  : data.userRegistration.lastName +
                    " " +
                    data.userRegistration.firstName +
                    " " +
                    (data.userRegistration.fatherName == null ||
                    data.userRegistration.fatherName == ""
                      ? " - "
                      : data.userRegistration.fatherName) +
                    " " +
                    (data.userRegistration.motherName == null ||
                    data.userRegistration.motherName == ""
                      ? " - "
                    : data.userRegistration.motherName ),
              
                    lastName: data.userRegistration.lastName,
                    firstName:   data.userRegistration.firstName ,
                    fatherName: data.userRegistration.fatherName,
                    motherName:   data.userRegistration.motherName ,
              appliedDate: data.applyDate === null ? "" : data.applyDate,
              certificateApplicationHeaderId:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.id,
              userRegistrationId:
                data.userRegistration === null ? "" : data.userRegistration.id,
              cetificateName:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.cetificate_name,
              isPaymentApplicable:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.is_payment_applicable,
              deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
              detail: data.detail === null ? "" : data.detail,
              payDetail: data.payDetail === null ? "" : data.payDetail,
              isPaid: data.isPaid === null ? "" : data.isPaid,
              documentUpload:
                data.documentUpload === null ? "" : data.documentUpload,
              status: data.status === 4 ? "Delivered" : "",
              // remark: data.adminRemark ? data.adminRemark : "No Remarks",
              remark: data.adminRemark ? data.adminRemark : "",
              userRemark: data.userRemark ? data.userRemark : "No Remarks",
              type: data.certificateApplicationHeader.type,
              amount:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.amount,
            };
            return companyData;
          });
          if (status == 4) {
            dispatch(deliveredCertificateListSuccess({ row }));
          } else {
            dispatch(rejectedCertificateListSuccess({ row }));
          }
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getRejectedCertificateList =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL +
          "/get-student-certificate-application-by-status-and-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&status=5",
      }).then(({ data, success }) => {
        if (success) {
          const preparedCertificateList = data.data;
          let row = [];
          let index = 0;
          row = preparedCertificateList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              id: data.id,
              name:
                data.userRegistration === null
                  ? ""
                  : data.userRegistration.lastName +
                    " " +
                    data.userRegistration.firstName +
                    " " +
                    (data.userRegistration.fatherName == null ||
                    data.userRegistration.fatherName == ""
                      ? " - "
                      : data.userRegistration.fatherName) +
                    " " +
                    (data.userRegistration.motherName == null ||
                    data.userRegistration.motherName == ""
                      ? " - "
                    : data.userRegistration.motherName ),
                    lastName: data.userRegistration.lastName,
                    firstName:   data.userRegistration.firstName ,
                    fatherName: data.userRegistration.fatherName,
                    motherName:   data.userRegistration.motherName ,
              appliedDate: data.applyDate === null ? "" : data.applyDate,
              certificateApplicationHeaderId:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.id,
              userRegistrationId:
                data.userRegistration === null ? "" : data.userRegistration.id,
              cetificateName:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.cetificate_name,
              isPaymentApplicable:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.is_payment_applicable,
              deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
              detail: data.detail === null ? "" : data.detail,
              payDetail: data.payDetail === null ? "" : data.payDetail,
              isPaid: data.isPaid === null ? "" : data.isPaid,
              documentUpload:
                data.documentUpload === null ? "" : data.documentUpload,
              status: data.status === 5 ? "Rejected" : "",
              // remark: data.adminRemark ? data.adminRemark : "No Remarks",
              remark: data.adminRemark ? data.adminRemark : "",
              userRemark: data.userRemark ? data.userRemark : "-",
              amount:
                data.certificateApplicationHeader === null
                  ? ""
                  : data.certificateApplicationHeader.amount,
            };
            return companyData;
          });
          dispatch(rejectedCertificateListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetTableList = () => async (dispatch) => {
  try {
    dispatch(resetTableListSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
