import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { DynamicReportJson } from "../../DynamicFormsJson/DynamicReport";
import { getEmployeeByDeptId } from "../EmployeeDetails/employee.slice";
import { getFloor } from "../Masters/TimeTable/Floor/floor.slice";
import {getRoom} from '../Masters/TimeTable/room/room.slice'
import { getTimeSlot } from "../Masters/TimeTable/Time-Slot/timeSlot.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
class DynamicReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        teacherMaster: [
          {
            id: "0",
            name: "Live Application",
          },
          {
            id: "1",
            name: "Closed Application",
          },
        ],
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeDetails?.employeeList,
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        acadamicMaster: this.props.academicYearList?.academicYear,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
        timeTableHeaderMaster: this.props.timeTableList?.timeTableList,
        floorMaster: this.props.floorList?.floor,
        roomMaster:this.props.roomList.room,
        timeSlotMaster: this.props.timeSlotList?.timeSlot,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department,
          },
        });
      }
    }
    if (this.props.floorList !== nextProps.floorList) {
      if (this.props.floorList.floor !== nextProps.floorList.floor) {
        let floorData = [];
        floorData = [{ id: "1", name: "All" }, ...nextProps.floorList.floor];
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            floorMaster: floorData,
          },
        });
      }
    }
    if (this.props.roomList !== nextProps.roomList) {
      if (this.props.roomList.room !== nextProps.roomList.room) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            roomMaster: nextProps.roomList.room,
          },
        });
      }
    }
    
    if (this.props.timeSlotList !== nextProps.timeSlotList) {
      if (
        this.props.timeSlotList?.timeSlot !== nextProps.timeSlotList?.timeSlot
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            timeSlotMaster: nextProps.timeSlotList?.timeSlot,
          },
        });
      }
    }
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employeeList !==
        nextProps.employeeDetails.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeDetails.employeeList,
          },
        });
      }
    }
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
        });
      }
    }
  }
  componentDidMount() {
    const {
      getDepartment,
      resetReducList,
      getYearDetails,
      getAlert,
      getFloor,
      getRoom,
      getTimeSlot,
    } = this.props;
    resetReducList();
    getDepartment().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getFloor().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getRoom().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getTimeSlot().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  rowData = [
    {
      index: 1,
      reportName: "Time table report for Teacher (D0, D1)",
      filter: "department,teacherIds",

      url: "/api/report/v1/get-time-table-detail-report/for-teacher?teacherIds={teacherIds}",
    },
    {
      index: 2,
      reportName: "time table report for student (D2, D3)",
      filter: "yearDetail,divisionIds",
      url: "/api/report/v1/get-time-table-detail-report/for-division?divisionIds={divisionIds}",
    },
    // {
    //   index: 3,
    //   reportName: "Timeslot And Floor Wise Available Rooms (D4, D5) ",
    //   filter: "timeSlot,floor",
    //   url: "/api/report/v1/available-rooms?floorIds={floor}&timeSlotId={timeSlot}&documentType=1",
    // },
    {
      index: 3,
      reportName: "Floor Wise Available Rooms (D4, D5)",
      filter: "timeSlot,floor",
      url: "/api/report/v1/available-rooms?floorIds={floor}&timeSlotId={timeSlot}&documentType=1",
    },
    {
      index: 4,
      reportName: "Timetable report room wise",
      filter: "room",
      url: "/api/report/v1/get-time-table-detail-report/for-room?roomId={room}",
    }
  ];
  getListById = (data) => {
    if (data.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    if (data.department) {
      this.props
        .getEmployeeByDeptId({
          departmentId: data.department,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={DynamicReportJson.screenTitle}
          fieldMeta={DynamicReportJson.fieldMeta}
          buttonCenter={DynamicReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={DynamicReportJson.searchButton}
          PDFButton={DynamicReportJson.PDFButton}
          EXCELButton={DynamicReportJson.EXCELButton}
          baseIdColumn={DynamicReportJson.baseIdColumn}
          apiBaseURL={DynamicReportJson.apiBaseURL}
          marginTop={DynamicReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  departmentList: state.department,
  employeeDetails: state.employeeList,
  floorList: state.floor,
  timeSlotList: state.timeSlot,
  roomList: state.room,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  resetReducList,
  getDepartment,
  getEmployeeByDeptId,
  getFloor,
  getRoom,
  getTimeSlot,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicReport);
