import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import {
  getFloor,
  getRoomsByFloor,
} from "../../Masters/TimeTable/Floor/floor.slice";
import { checkRoomAvalibility } from "./generateTimeTable.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddTimeSlot = ({
  getFloor,
  getRoomsByFloor,
  floorList,
  handleCloses,
  timeSlot,
  editData,
  getAlert,
}) => {
  const [open, setOpen] = React.useState(true);
  const [floorNo, setFloorNo] = React.useState("");
  const [roomBusy, setRoomBusy] = React.useState([]);
  const [roomNo, setRoomNo] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const handleClose = () => {
    handleCloses();
  };

 
  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name == "floorNo") {
        setRoomNo("");
        getRoomsByFloor({
          floorId: newValue,
          timeSlotIdData: editData.timeSlot,
          dayIdData: editData.day,
        }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
        
        setFloorNo(newValue);
      } else if (name == "roomNo") {
        setRoomNo(newValue);
      }
    } else if (name == "floorNo") {
      setFloorNo("");
    } else if (name == "roomNo") {
      setRoomNo("");
    }
  };
  useEffect(() => {
    getFloor().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

   
    if (Object.keys(editData).length != 0) {
      getRoomsByFloor({
        floorId: editData.floorNo,
        timeSlotIdData: editData.timeSlot,
        dayIdData: editData.day,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
      
      setFloorNo(editData.floorNo);
    }
  }, []);
  const submitHandler = () => {
   
    if (Object.keys(editData).length !== 0) {
     const dataToSave = {
        ...editData,
        floorNo: floorNo,
        roomNo: roomNo,
      };
      if (checkValidation()) {
        handleCloses(dataToSave)
        // onSave(timeTableDetails);
      }
    }
   
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (floorNo === "" || floorNo === null) {
      formIsValid = false;
      formErrors["floorNoError"] = myConstClass.floorMsg;
    }

    if (roomNo === "" || roomNo === null) {
      formIsValid = false;
      formErrors["roomNoError"] = myConstClass.roomMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };
  console.log(editData)
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Time Slot
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              {/* , batch, , batchShortName, teacher, teacherName, teacherShortName, subjectDetail, subjectDetailName, subjectName, floorNo, floorName, roomNo, roomsName, department, departmentName, id, timeSlot, day */}
               <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Teacher Name : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={editData.teacher}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Subject Name : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={editData.subjectDetailName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Department  : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={editData.departmentName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Batch Name : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={editData.batchName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Room Name : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={editData.roomsName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              ></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}></Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Floor Number  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <AutoComplete
                      keyColName={"id"}
                      value={floorNo}
                      name={"floorNo"}
                      options={floorList.floor.filter(
                        (row) => row.isActive == 1
                      )}
                      onChange={ChangeHandlerSearch}
                      isError={formErrors.floorNoError ? true : false}
                      errorText={
                        formErrors.floorNoError ? formErrors.floorNoError : " "
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Room Number  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <AutoComplete
                      keyColName={"id"}
                      value={roomNo}
                      name={"roomNo"}
                      options={floorList.room}
                      onChange={ChangeHandlerSearch}
                      isError={formErrors.roomNoError ? true : false}
                      errorText={
                        formErrors.roomNoError ? formErrors.roomNoError : " "
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    rowSpacing={2}
                    container
                    columnSpacing={1}
                    justifyContent="right"
                  >
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                        onClick={handleClose}
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Submit"
                        onClick={submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  floorList: state.floor,
  departments: state.department,
  timeTableList: state.timeTable,
  workloadAllocList: state.workloadAllocate,
});

const mapDispatchToProps = {
  getFloor,
  getRoomsByFloor,
  getAlert,
  checkRoomAvalibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTimeSlot);
