import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  floor: [],
  isFetch: false,
  room: [],
  isFetchRoom: false,
};
let URL = endpoints.floor;
const floorSlice = createSlice({
  name: "floor",
  initialState,
  reducers: {
    floorSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        floor: row,
        isFetch: true,
      };
    },
    roomSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        room: row,
        isFetchRoom: true,
      };
    },
  },
});

export const { floorSuccess, roomSuccess } = floorSlice.actions;

export default floorSlice.reducer;

export const getFloor = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(floorSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getRoomsByFloor =
  ({ floorId, timeSlotIdData, dayIdData }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/" +
          floorId +
          "/get-distict-rooms?timeSlotIds=" +
          timeSlotIdData +
          "&days=" +
          dayIdData,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(roomSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
