import { FormHelperText, Grid, Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../components/Loading1";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import { getAllEmployees } from "../../EmployeeDetails/employee.slice";
import { showNotification } from "../../Landing/Landing.slice";
import { getRoomForExamConduct } from "../../Masters/TimeTable/room/room.slice";
import {
  getStudentForExamConduct,
  getSubjectForExamConduct,
  resetData,
} from "./examconduct.slice";
import ExamStudListPopUp from "./ExamStudListPopUp";
import DynamicSelectTable from "./StudentListWithCheckBox";

class PromotionalAdmissionForm extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    flag: false,
    studentList: [],
    examCapacity: 0,
    openPopUp: false,
    room: "",
    subject: [],
    teacher: [],
    scheduleDate: "",
    selection: [],
    studentNewList: [],
    showLoader: false,
  };
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "seatNo",
      title: "Seat Number",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "name",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      name: "subjectName",
      title: "Subject Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      name: "headName",
      title: "Head Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "startTime",
      title: "Start Time",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "endTime",
      title: "End Time",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
  ];

  componentDidMount() {
    const { getAllEmployees } = this.props;
    getAllEmployees().then((resp) => {});
  }

  handleFormValidation() {
    const { room, scheduleDate, teacher, subject } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (room === "" || room === null) {
      formIsValid = false;
      formErrors["roomError"] = myConstClass.roomMsg;
    }
    if (scheduleDate === "" || scheduleDate === null) {
      formIsValid = false;
      formErrors["scheduleDateError"] = "*Schedule date required";
    }

    if (teacher.length === 0 || teacher === null) {
      formIsValid = false;
      formErrors["teacherError"] = myConstClass.teacherNameMsg;
    }
    if (subject.length === 0 || subject === null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  ChangedHandlerSearch = (name, newValue) => {
    this.setState({ ...this.state, [name]: newValue });
    if (name == "studentName") {
      this.setState({ detail: true });
    }
  };
  saveData = () => {
    const { room, teacher, scheduleDate, studentList } = this.state;
    swal("Do you want to submit", {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        let admissionDateChange = moment(scheduleDate).format("DD-MM-YYYY");

        let docs = {
          rooms: {
            id: room,
          },
          teacherIds: teacher.join(),
          scheduleDate: admissionDateChange,
          examConductionDetailDetails: studentList.map((stud) => {
            let studMarks = {
              studentDetailExamMarks: {
                id: stud.id,
              },
              startTime: stud.startTime,
              endTime: stud.endTime,
            };
            return studMarks;
          }),
        };

        apiPost({
          url: endpoints.examConduction,
          postBody: docs,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data saved successfully",
            });
            this.setState({
              flag: false,
              openPopUp: false,
              id: 0,
              formErrors: [],
              studentList: [],
              examCapacity: 0,
              room: "",
              subject: [],
              teacher: [],
              scheduleDate: "",
              selection: [],
            });
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });
  };
  submitHandler = () => {
    if (this.handleFormValidation()) {
      if (this.state.selection.length !== 0) {
        const { room } = this.state;
        const roomListData = this.props.roomList.room.filter(
          (row) => row.id === room
        );
        let examCapacity =
          roomListData.length !== 0 ? roomListData[0].examCapacity : 0;
        const row = [];
        this.props.examConduct.student.map((studData) => {
          const selectionList = this.state.selection.filter(
            (row) => row === studData.id
          );
          if (selectionList.length !== 0) {
            studData = {
              ...studData,
              index: row.length + 1,
            };
            row.push(studData);
          }
        });
        this.setState({
          // openPopUp: true,
          examCapacity: examCapacity,
          studentList: row,
        });
        if (examCapacity !== 0 && examCapacity >= row.length) {
          this.setState({
            openPopUp: true,
          });
        } else {
          swal({
            title: "Are you sure?",
            text: "Selected student count greater than exam capacity ",
            icon: "warning",
            buttons: ["Cancel", true],
          }).then((res) => {
            if (res) {
              this.setState({
                openPopUp: true,
              });
            }
          });
        }
      } else {
        let formErrors = {};

        formErrors["MatrixError"] = "Select atleast one student";
        this.setState({ formErrors: formErrors });
      }
    }
  };
  onSelectionChange = (e) => {
    this.setState({
      selection: e,
    });
  };
  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  searchHandler = () => {
    if (this.handleFormValidation()) {
      const { studentNewList } = this.state;
      if (this.state.subject.length === 0) {
        this.setState({
          studentNewList: [],
        });
      }
      console.log(this.state.subject.length);
      console.log(this.state.studentNewList.length);
      let admissionDateChange = moment(this.state.scheduleDate).format(
        "YYYY-MM-DD"
      );
      this.setState({
        studentNewList: [],
        showLoader: true,
      });
      this.props.resetData({ flag: true });

      this.state.subject.map((subjectId) => {
        this.props
          .getStudentForExamConduct({
            scheduleDate: admissionDateChange,
            subjectDetailId: subjectId,
          })
          .then((resp) => {
            if (!resp) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({
                message: "Server Failed",
              });
            } else {
              console.log(this.state.subject.length);
              if (this.props.examConduct.student.length !== 0) {
                const uniqueTags = [];
                this.props.examConduct.student.map((item) => {
                  var findItem = uniqueTags.find((x) => x.id === item.id);
                  if (!findItem) uniqueTags.push(item);
                });
                this.setState({
                  studentNewList: uniqueTags,
                  showLoader: false,
                });
              }
            }
          });
      });
    }
  };

  changeAdmission = (date) => {
    if (date !== null) {
      let admissionDateChange = moment(date).format("YYYY-MM-DD");
      this.props.resetData({ flag: false });
      this.setState({
        room: "",
        subject: [],
        teacher: [],
      });
      this.props
        .getRoomForExamConduct({ scheduleDate: admissionDateChange })
        .then((resp) => {
          if (!resp) {
            this.props.getAlert({
              message: "Server Failed",
            });
          }
        });
      this.props
        .getSubjectForExamConduct({ scheduleDate: admissionDateChange })
        .then((resp) => {
          if (!resp) {
            this.props.getAlert({
              message: "Server Failed",
            });
          }
        });
      this.setState({
        scheduleDate: date,
        flag: true,
      });
    }
  };
  handleCloses = () => {
    this.setState({
      openPopUp: false,
    });
  };
  render() {
    const { room, teacher, scheduleDate, subject, studentNewList, showLoader } =
      this.state;
    const {
      roomError,
      scheduleDateError,
      teacherError,
      subjectError,
      MatrixError,
    } = this.state.formErrors;
    const { examConduct, roomList } = this.props;
    console.log(this.state.studentNewList);

    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title={"Exam Conduction"} />
        </Paper>
        <br />
        <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              sx={{ marginTop: -3 }}
              justifyContent="flex-end"
            >
              <Grid sx={{ marginLeft: 1 }} item xs={12} sm={10.5} md={10.5} />
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=""
                />
              </Grid>
              <Grid item xs={12} sm={1.5} md={1.5}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back to List"
                  onClick={this.props.onCancel}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={2}
              justifyContent="space-evenly"
            >
              <Grid
                container
                justifyContent="space-evenly"
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  columnSpacing={3}
                  sm={4}
                  md={4}
                  lg={4}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Schedule Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      name="scheduleDate"
                      value={scheduleDate}
                      onChange={this.changeAdmission}
                      isError={scheduleDateError ? true : false}
                      errorText={scheduleDateError ? scheduleDateError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={8} md={8} />
              </Grid>

              {this.state.flag && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      sm={3}
                      md={3}
                      lg={3}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Room "
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={room}
                          name={"room"}
                          options={roomList.room}
                          onChange={this.ChangedHandlerSearch}
                          isError={roomError ? true : false}
                          errorText={roomError ? roomError : " "}
                          fullWidth
                        />
                      </Grid>{" "}
                    </Grid>
                    <Grid container sm={3} md={3} lg={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Teacher Name "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={teacherError}
                          name1="teacher"
                          value1={teacher}
                          names={this.props.employeeDetails?.employeeList.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.multiSelectHandler}
                        />
                      </Grid>
                    </Grid>
                    <Grid container sm={3} md={3} lg={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Subject "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={subjectError}
                          name1="subject"
                          value1={subject}
                          // names={departments?.department.filter((data)=>data.isActive==1)}
                          names={examConduct.subject}
                          onChange={this.multiSelectHandler}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1}
                      lg={1}
                      sx={{ marginTop: 2.5 }}
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        fullWidth={true}
                        onClick={this.searchHandler}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      xs={12}
                      md={1}
                      lg={1}
                      justifyContent="space-evenly"
                    ></Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <FormHelperText error>
                      {MatrixError ? MatrixError : " "}
                    </FormHelperText>
                    <DynamicSelectTable
                      rows={
                        this.state.subject.length === 0 ? [] : studentNewList
                      }
                      columns={this.columns}
                      isActionColActive={false}
                      selection={this.state.selection}
                      onSelectionChange={this.onSelectionChange}
                    />
                  </Grid>
                  <Grid
                    container
                    sx={{ marginTop: 2 }}
                    columnSpacing={1}
                    justifyContent="right"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1.1}
                      lg={1.1}
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                        fullWidth={true}
                        onClick={this.props.onCancel}
                      />
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={2}
                      lg={2}
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Save and Next"
                        fullWidth={true}
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {showLoader && <Loading1 />}
        </Paper>

        {this.state.openPopUp && (
          <ExamStudListPopUp
            rows={this.state.studentList}
            saveData={this.saveData}
            columns={this.columns}
            open={this.state.openPopUp}
            handleCloses={this.handleCloses}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeDetails: state.employeeList,
  examConduct: state.examConduct,
  roomList: state.room,
});

const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAllEmployees,
  getSubjectForExamConduct,
  getRoomForExamConduct,
  getStudentForExamConduct,
  resetData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionalAdmissionForm);
