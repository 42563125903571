import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import {AdmissionTypeMasterJson} from '../../../../DynamicFormsJson/AdmissionType.js';
import { AdmissionTypeMasterColumns } from "../../../../tableColumns/table-columns";
import {getAdmissionType} from "./admissionType.slice"
import {getAlert}from "../../../../CommonActions/alert.slice";
class AdmissionTypeMaster extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: { programMaster: this.props.programMaster?.program, instituteList: this.props.instituteList?.institute }
        }
    }

    componentDidMount() {

        this.props.getAdmissionType().then((response)=>{
            if(!response){
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
    }

    render() {

        const {admissionTypeList}= this.props
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={AdmissionTypeMasterJson.screenTitle}
                        fieldMeta={AdmissionTypeMasterJson.fieldMeta}
                        showPdfDownload={AdmissionTypeMasterJson.showPdfDownload}
                        showExcelDownload={AdmissionTypeMasterJson.showExcelDownload}
                        pdfFileName={AdmissionTypeMasterJson.pdfFileName}
                        excelFileName={AdmissionTypeMasterJson.excelFileName}
                        showAddButton={AdmissionTypeMasterJson.showAddButton}
                        tableColumnsToFilter={AdmissionTypeMasterJson.tableColumnsToFilter}
                        //dynamicMasterData={dynamicMasterData}
                        tableColumns={AdmissionTypeMasterColumns}
                        tableData={admissionTypeList.admissionType}
                        getTableData={this.props.getAdmissionType}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={admissionTypeList.isFetch}
                        baseIdColumn={AdmissionTypeMasterJson.baseIdColumn}
                        apiBaseURL={AdmissionTypeMasterJson.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
   admissionTypeList: state.admissionType
});
const mapDispatchToProps = {
    getAdmissionType,
    getAlert
}
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionTypeMaster);