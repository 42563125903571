import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { onlyNumber } from "../../../components/Comman/Util/Validations";
import { Grid, Paper } from "@mui/material";
import { ButtonCompo } from "../../../components/Comman/Button";

export default function BasicTable({ row, resetFlag, flag, submitData }) {
  const [values, setValues] = useState([]);
  const changeHandler = (id, e) => {
    const { value } = e.target;
    setValues((data) =>
      data?.map((list, index) =>
        list.id === id ? { ...list, marks: value, marksValid: 0 } : list
      )
    );
  };
  const changeResultHandler = (rowData, e) => {
    const { value } = e.target;
    if (value !== "") {
      let result = "";

      if (onlyNumber.test(value) && rowData.min_marks > +value) {
        result = "F";
      } else {
        if (onlyNumber.test(value) && rowData.value >= +value) {
          result = "P";
        } else if (onlyNumber.test(value) && rowData.value < +value) {
          result = "";
        } else {
          result = value;
        }
      }
      setValues((data) =>
        data?.map((list, index) =>
          list.id === rowData.id
            ? {
                ...list,
                result: result,
                marks: result === "" ? "" : rowData.marks,
                marksValid: result === "" ? 1 : rowData.marksValid,
              }
            : list
        )
      );
    }
  };
  useEffect(() => {
    if (values !== row && flag) {
      setValues(row);
      resetFlag(false);
    }
    if (values.length === 0 && row.length !== 0 && flag) {
      setValues(row);
    }
  });
  const submitHandler = () => {
    submitData(values);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 100 }} align="center">
                Sr. No.
              </TableCell>
              <TableCell style={{ minWidth: 150 }} align="center">
                Seat No
              </TableCell>
              <TableCell style={{ minWidth: 200 }} align="center">
                Student Name
              </TableCell>
              <TableCell style={{ minWidth: 150 }} align="center">
                Marks
              </TableCell>
              <TableCell style={{ minWidth: 150 }} align="center">
                Out Of
              </TableCell>
              <TableCell style={{ minWidth: 150 }} align="center">
                Result
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((rowData, index) => (
              <TableRow key={rowData.name}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="center">{rowData.seat_no}</TableCell>
                <TableCell align="left">{rowData.student_name}</TableCell>
                <TableCell align="center">
                  <TextField
                    color="primary"
                    name={"Percentage" + rowData.id}
                    size="small"
                    value={rowData.marks}
                    onChange={(e) => changeHandler(rowData.id, e)}
                    onBlur={(e) => changeResultHandler(rowData, e)}
                    error={rowData.marksValid !== 0 ? true : false}
                    helperText={
                      rowData.marksValid === 1
                        ? "Marks should be grater than max marks"
                        : " "
                    }
                  />
                </TableCell>
                <TableCell align="center">{rowData.value}</TableCell>
                <TableCell align="center">{rowData.result}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        m={2}
        rowSpacing={1}
        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
        container
        justifyContent="right"
      >
        <Grid
          item
          sm={12}
          xs={12}
          md={1}
          lg={1}
          container
          justifyContent="space-evenly"
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Submit"
            fullWidth={true}
            onClick={submitHandler}
          />
        </Grid>
      </Grid>
    </>
  );
}
