export const AllocatedStudentListJson = {
    screenTitle: "Allocated Student List",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter:false,
    getAcadamicYear:true,
    getListId:'applicationName',
    fieldMeta: [
      {
        label: "Application Name",
        controlType: "autocomplete",
        placeHolder: "",
        md: 2.7,
        lg: 2.7,
        sm: 2.7,
        xs: 12,
        masterName: "applicationNameMaster",
        getListId:'applicationName',
        dataKey: "applicationName",
        isMandatory: true,
      },
      {
        label: "Round Details",
        controlType: "autocomplete",
        placeHolder: "",
        md: 2.7,
        lg: 2.7,
        sm: 2.7,
        xs: 12,
        getListFrom:'applicationName',
        masterName: "roundDetailMaster",
        dataKey: "roundDetail",
        isMandatory: true,
      },
      {
          label: "Allotment Status",
          controlType: "multiSelect",
          placeHolder: "",
          md: 2.7,
          lg: 2.7,
          sm: 2.7,
          xs: 12,
          masterName: "allotmentStatusMaster",
          dataKey: "allotmentStatus",
          isMandatory: true,
        },
        {
        label: "Program Title",
        controlType: "autocomplete",
        placeHolder: "",
        md: 2.7,
        lg: 2.7,
        sm: 2.7,
        xs: 12,
        masterName: "programTitleMaster",
        getListFrom:'applicationName',
        dataKey: "programTitle",
        isMandatory: true,
      },
    ],
  };
  