import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import * as msgConstant from "../../config/messageconstant";
import { EmployeeListJson } from "../../DynamicFormsJson/EmployeeList";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { EmployeeListColumns } from "../../tableColumns/table-columns";
import { apiDelete, apiPost } from "../../utils/api_service";
import EmployeeDetails from "../EmployeeDetails/index";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import EmpPreview from "../EmpPreview/index";
import { resetReducList } from "../../CommonActions/User.action";
import {
  getEmployeeByDeptId,
  getAllEmployees,
  getEmployeeById,
} from "../EmployeeDetails/employee.slice";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import NewLandingScreenHeader from "../../components/Comman/NewLandingScreenHeader";
import * as myConstClass from "../../config/messageconstant";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { generateExcel } from "../../components/Comman/GenerateExcel";
// import { Loading1 } from "../Loading1";
// import DynamicForm from "./DynamicForm";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class EmployeeList extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showPreview: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      department: "",
      departmentList: [],
      formErrors: [],
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          { id: "0", name: "All" },
          ...nextProps.departments.department,
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  handleFormValidation() {
    const { department } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (department === "" || department === null) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onAddButtonClick = () => {
    let fieldData = {};
    this.props.resetReducList();

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowView = (rowData) => {
    this.props.getEmployeeById({ employeeId: rowData.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showPreview: true });
      }
    });
  };
  GoBack = () => {
    this.setState({ showPreview: false });
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    this.props.getEmployeeById({ employeeId: rowData.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    const { getDepartment } = this.props;
    // getDepartment();
    this.props.resetReducList();
    getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getAllEmployees().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  // rowDelete = ( rowData ) =>
  // {
  //   swal( {
  //     title: "Are you sure?",
  //     text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   } ).then( ( willDelete ) =>
  //   {
  //     if ( willDelete )
  //     {
  //       this.onDelete( rowData );
  //     }
  //   } );
  // };

  onChangeStatus = (rowData, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveEmployee +
            "/update-status?userRegistrationId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
            this.props.getAllEmployees().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.props.resetReducList();
    this.props.getAllEmployees().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.props.getTableData();
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.saveEmployee + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const { getEmployeeByDeptId, getAlert } = this.props;
        const { department } = this.state;
        if (department !== "0") {
          getEmployeeByDeptId({
            departmentId: department,
          }).then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
        } else if (department === "") {
          this.props.getAllEmployees().then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
        } else {
          this.props.getAllEmployees().then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
        }

        this.setState({
          currentOperationMode: "",
        });
      } else {
        getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  // onDelete = ( rowdata ) =>
  // {
  //   const { apiBaseURL } = this.props;
  //   this.setState( {
  //     isLoading: true,
  //   } );
  //   apiDelete( {
  //     url: apiBaseURL + "/" + rowdata.id,
  //   } ).then( ( { success } ) =>
  //   {
  //     if ( success )
  //     {
  //       this.setState( {
  //         isLoading: false,
  //       } );
  //       this.props.showNotification( { msg: "Data deleted successfully" } );
  //       this.props.getTableData();
  //       this.setState( {
  //         currentOperationMode: "",
  //       } );
  //     } else
  //     {
  //       this.props.getAlert( {
  //         message: "Failed to delete",
  //       } );
  //       this.setState( {
  //         isLoading: false,
  //       } );
  //     }
  //   } );
  // };

  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  // onChangeStatus = ( rowData ) =>
  // {
  //   swal( msgConstant.changeActive, {
  //     buttons: [ "Cancel", true ],
  //   } ).then( ( value ) =>
  //   {
  //     if ( value )
  //     {
  //       const { fieldMeta } = this.props;

  //       const dataToSave = {
  //         id: rowData[ "id" ],
  //       };

  //       const { tableColumns } = this.props;
  //       let fieldData = {};

  //       tableColumns.forEach( ( currentCol ) =>
  //       {
  //         const formDataKey = currentCol.formDataKey
  //           ? currentCol.formDataKey
  //           : currentCol.name;
  //         const tableDataKey = currentCol.tableDataKey
  //           ? currentCol.tableDataKey
  //           : currentCol.name;
  //         fieldData[ formDataKey ] = rowData[ tableDataKey ];
  //       } );

  //       fieldMeta.forEach( ( currentField ) =>
  //       {
  //         if (
  //           currentField.controlType === "autocomplete" &&
  //           !currentField.isRootLevelKey
  //         )
  //         {
  //           dataToSave[ currentField.dataKey ] = {};
  //           dataToSave[ currentField.dataKey ][ "id" ] = fieldData[
  //             currentField.dataKey
  //           ]
  //             ? fieldData[ currentField.dataKey ]
  //             : "";
  //         } else
  //         {
  //           dataToSave[ currentField.dataKey ] = fieldData[ currentField.dataKey ]
  //             ? fieldData[ currentField.dataKey ]
  //             : "";
  //         }
  //       } );

  //       dataToSave[ "isActive" ] = rowData.isActive === 1 ? "0" : "1";
  //       this.setState( {
  //         currentOperationMode: OperationMode.Status,
  //       } );
  //       this.onSave( dataToSave, false );
  //     }
  //   } );
  // };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    tableColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  submitHandler = () => {
    const { getEmployeeByDeptId, getAlert } = this.props;
    const { department } = this.state;

    if (this.handleFormValidation()) {
      if (department === "0") {
        this.props.getAllEmployees().then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        getEmployeeByDeptId({
          departmentId: department,
        }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    }
  };
  generatePDF = () => {
    const { excelFileName = "Faculty Details" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      EmployeeListColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    EmployeeListColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  generateExcel = () => {
    const { excelFileName = "Faculty Details" } = this.props;
    const tableData = this.getFilteredTableData();
    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      EmployeeListColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  getFilteredTableData = () => {
    const { employeeDetails } = this.props;
    const { searchValue } = this.state;
    const columsFil = EmployeeListColumns.filter((item) => {
      return item.canSearch;
    });
    return employeeDetails?.employeeList.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      departments,
      employeeDetails,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      department,
      departmentList,
      showPreview,
    } = this.state;
    const { departmentError } = this.state.formErrors;
    return (
      <>
        {!showPreview && (
          <>
            <LandingScreenHeader
              tableColumnsToFilter={[]}
              screenTitle={EmployeeListJson.screenTitle}
              showPdfDownload={EmployeeListJson.showPdfDownload}
              generatePDF={this.generatePDF}
              showExcelDownload={EmployeeListJson.showExcelDownload}
              generateExcel={this.generateExcel}
              showAddButton={showAddButton}
              onAddButtonClick={this.onAddButtonClick}
              onSearch={this.onSearch}
              showTable={showTable}
            />
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              {showForm && (
                <>
                  <EmployeeDetails onCancel={this.onFormCancel} />
                </>
              )}

              {showTable && (
                <>
                  <Grid container justifyContent="space-evenly" direction="row">
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={2}
                      container
                      sx={{ p: 1 }}
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={11}
                        lg={11}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Department"
                          />
                        </Grid>
                        <Grid
                          sx={{ marginLeft: -1 }}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <AutoComplete
                            keyColName={"id"}
                            value={department}
                            name={"department"}
                            fullWidth
                            options={departmentList}
                            onChange={this.ChangeHandlerSearch}
                            isError={departmentError ? true : false}
                            errorText={departmentError ? departmentError : " "}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={1}
                        lg={1}
                        container
                        sx={{ marginTop: -3 }}
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Search"
                            onClick={this.submitHandler}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <RTTable
                      columns={EmployeeListColumns}
                      hiddenColumnNames={tableColumnsToFilter.map((item) => {
                        return !item.isChecked ? item.columnKeyName : "";
                      })}
                      showAddButtonInTable={showAddButtonInTable}
                      rowEdit={this.rowEdit}
                      rowDelete={this.rowDelete}
                      onChangeStatus={this.onChangeStatus}
                      rowAdd={this.rowAdd}
                      showViewButtonInTable={true}
                      rowView={this.rowView}
                      // rows={employeeDetails?.employeeList}
                      // tableData={employeeDetails?.employeeList}
                      tableData={this.getFilteredTableData()}
                      rows={this.getFilteredTableData()}
                    />
                  </Grid>
                </>
              )}
            </Paper>
          </>
        )}
        {showPreview && <EmpPreview GoBack={this.GoBack} />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  departments: state.department,
  employeeDetails: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getDepartment,
  getEmployeeByDeptId,
  getAllEmployees,
  getEmployeeById,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
