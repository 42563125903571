import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "./EmployeeFeedbackPortalTable";
import * as myConstClass from "../../../config/messageconstant";
import AutoComplete from "../../../components/Comman/AutoComplete";
import swal from "sweetalert";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { getAlert } from "../../../CommonActions/alert.slice";
import { showNotification } from "../../Landing/Landing.slice";
function StudentFeedbackForm({
  backToList,
  onCancel,
  editData,
  employeeFeedbackDetailsList,
  getAlert,
  showNotification,
}) {
  const [values, setValues] = React.useState("");

  const setQuaData = (data) => {
    const dataToPush = data.map((data1) => {
      let bData = {
        feedbackHeadDetails: {
          id: data1.id,
        },
        value: data1.answer,
      };
      return bData;
    });

    let pushData = {
      employeeFeedback: {
        id: editData.id,
      },
      employeeFeedbackTransactionDetails: dataToPush,
    };

    swal({
      title: "Are you sure?",
      text: "Do you want to save the feedback?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiPost({
          url: endpoint.employeeFeedbackTransaction,
          postBody: pushData,
        }).then(({ success }) => {
          if (success) {
            showNotification({ msg: "Data saved successfully" });
            backToList();
          } else {
            getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Questions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Feedback Details"
            />
          </Grid>
          <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back to List"
              onClick={onCancel}
            />
          </Grid>
        </Grid>

        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={2} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Feedback Name "
              />
            </Grid>

            <Grid item xs={12} sm={10} md={10}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={editData !== undefined ? editData.feedbackHead : ""}
              />
            </Grid>
          </Grid>
        </Grid>
        {editData !== undefined && editData.details !== "" ? (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={2} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Instruction"
              />
            </Grid>
            <Grid item xs={12} sm={10} md={10}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={editData.details}
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={2} md={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Date"
            />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={
                editData !== undefined &&
                editData.fromDate + "/" + editData.toDate
              }
            />
          </Grid>
        </Grid>
        <DynamicTable
          data={employeeFeedbackDetailsList?.employeeFeedbackQuestions}
          tableHead={columns}
          showPegination={false}
          rowData={setQuaData}
          isActionColActive={false}
          //   rowStatus={onChangeAction}
        />
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  employeeFeedbackDetailsList: state.employeeFeedbackDetails,
});
const mapDispatchToProps = {
  getAlert,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFeedbackForm);
