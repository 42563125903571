import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import { connect } from "react-redux";
import
{
  MyComponentWithIconProps
} from "../../components/Comman/IconButton";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
const SubjectSelectUpdateStatusTable = ( { rows, rowView, onSelectionChange, selection, documentVerificationList, rowDelete, rowAppliedList } ) =>
{


  const columns = [

    { name: "seatNo", title: "Seat No", minWidth: 100, alignCell: "center", alignCenter: "center" },
    { name: "studentName", title: "Student Name", minWidth: 250, alignCell: "center" },
    { name: "applicableFees", title: "Applicable Fees", minWidth: 200, alignCell: "center", alignCenter: "right" },
    { name: "amount", title: "Paid Fees", minWidth: 200, alignCell: "center", alignCenter: "right" },
    {
      title: "Action",
      name: "actions",
      minWidth: 100,
      alignCell: "center",
      renderCell: ( params ) =>
      {
        const onClickView = ( e ) =>
        {
          e.preventDefault()
          rowView( params.row.userApplicationId )
        }
        return (
          <>
            <div>
              <MyComponentWithIconProps
                statusImage={VisibilityIcon}
                color="primary"
                fontSize="medium"
                title="View"
                onClick={onClickView}
                sx={{ ml: 3.2 }}
              />
              &nbsp;&nbsp;
              <MyComponentWithIconProps
                statusImage={SummarizeRoundedIcon}
                color="error"
                fontSize="medium"
                title="Print"
              // onClick={onClickView}
              />

            </div>
          </>
        );
      },
    },
  ];
  const rowPrint = ( row ) =>
  {

  }







  return (

    <DynamicSelectTable
      rows={rows}
      columns={columns}
      selection={selection}
      onSelectionChange={onSelectionChange}
      rowViewData={rowView}
      showView={false}
      showDetailsIcon={true}
      showDelete={true}
      SummarizeRoundedShow={false}
      rowPrint={rowPrint}
      rowDelete={rowDelete}
      rowAppliedList={rowAppliedList}

    />
  );
};

const mapStateToProps = ( state ) => ( {
  // documentVerificationList: state.documentVerification,
} );

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( SubjectSelectUpdateStatusTable );

