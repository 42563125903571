import { Grid, Paper } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import SearchExternalPlacedStudList from "../../../components/SearchTable";
import { ViewStudentAttendanceHeadDynamicJson } from "../../../DynamicFormsJson/ViewStudentAttendanceHeadDynamicJson";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import CoordinatorPreviewTable from "./CoordinatorPreviewTable";
import {
  getStudentAttendanceListForHead,
  getStudentAttendanceListForHeadView,
} from "./studentAttendance.slice";
import { FilterSave } from "../../../components/FilterSave";
class ViewPresentDetailsByHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      formErrors: [],
      toDate: "",
      yearDetail: "",
      semester: "",
      division: "",
      academicYear: "",
      viewData: {},
      showTable: false,
      showForm: true,
      dataToSave: {},
      searchValue: "",
      showLoader: false,
      dynamicMasterData: {
        yearDetailMaster: this.props.yearDetails?.yearDetails,
        semesterDetailMaster: this.props.Semester?.semisterDetailsList,
        divisionMaster: this.props.yearDetails?.distinctDivision,
      },
    };
  }

  componentDidMount() {
    this.props.resetReducList();
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const ay = localStorage.getItem("acadamicYearId");
    console.log(semisterDetailId);
    console.log(divisionId);
    if (divisionId && semisterDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getStudentAttendanceListForHead({
          semesterId: semisterDetailId,
          divisionId: divisionId,
          fromDate: "",
          toDate: "",
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    this.props.getAcademicYear().then((response) => {
      this.setState({
        showLoader: true,
      });
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    this.props.getYearDetails().then((response) => {
      this.setState({
        showLoader: true,
      });
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  submitHandler = (data) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getStudentAttendanceListForHead({
        semesterId: data.semister,
        divisionId: data.division,
        fromDate: data.fromDate,
        toDate: data.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", data.division);
    localStorage.setItem("academicYearId", ay);
    localStorage.setItem("divisionIds", "");
    FilterSave();
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.yearDetails !== nextProps.yearDetails) {
      if (
        this.props.yearDetails?.yearDetails !==
        nextProps.yearDetails?.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.yearDetails?.yearDetails,
          },
        });
      }
      if (
        this.props.yearDetails?.distinctDivision !==
        nextProps.yearDetails?.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster: nextProps.yearDetails?.distinctDivision,
          },
        });
      }
    }
    if (this.props.Semester !== nextProps.Semester) {
      if (
        this.props.Semester?.semisterDetailsList !==
        nextProps.Semester?.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster: nextProps.Semester?.semisterDetailsList,
          },
        });
      }
    }
  }

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({ showLoader: true });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });
    }
    if (data.yearDetail) {
      this.setState({ showLoader: true });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });
    }
  };

  changeHandlerSearch = (name, newValue) => {
    const { yearDetail, academicYear } = this.state;
    const ay = localStorage.getItem("acadamicYearId");
    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name == "yearDetail") {
        this.setState({ division: "" });
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        this.setState({ semester: "" });
        getSemesterDetailsByYearAndAcademic({
          yearId: newValue,
          academicId: ay,
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  rowDetails = (data) => {
    console.log(data);
    this.props
      .getStudentAttendanceListForHeadView({ id: data.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showTable: true,
            showForm: false,
            dataToSave: this.props.studentAtt?.studentAttendanceHeadView
              ? this.props.studentAtt?.studentAttendanceHeadView
              : {},
          });
        }
      });
  };

  backToList = () => {
    this.setState({
      showForm: true,
      showTable: false,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "attendanceDate",
      title: "Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "13%",
      canSearch: true,
    },
    {
      name: "batch",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "subject",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "teacherName",
      title: "Teacher Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
      canSearch: true,
    },
    {
      name: "presentCount",
      title: "Present Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },

    {
      name: "absentCount",
      title: "Absent Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "totalCount",
      title: "Total Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  handleFormValidation() {
    const { yearDetail, division, semester, academicYear } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }
    if (semester.toString().trim() === "" || semester === null) {
      formIsValid = false;
      formErrors["semesterError"] = myConstClass.semisterNameMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divisionNameMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studentAtt?.studentAttendanceHead.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["attendanceDate"] &&
          currentRow["attendanceDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["subject"] &&
          currentRow["subject"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["teacherName"] &&
          currentRow["teacherName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["batch"] &&
          currentRow["batch"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["presentCount"] &&
          currentRow["presentCount"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["absentCount"] &&
          currentRow["absentCount"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {} = this.props;
    const { yearDetail, semester, division, academicYear } = this.state;

    const { yearDetailError, divisionError, semesterError, academicYearError } =
      this.state.formErrors;

    return (
      <>
        {this.state.showForm && (
          <>
            <DynamicMainScreen
              // onAddButtonClick={this.onAddButtonClick}
              screenTitle={ViewStudentAttendanceHeadDynamicJson.screenTitle}
              fieldMeta={ViewStudentAttendanceHeadDynamicJson.fieldMeta}
              buttonCenter={ViewStudentAttendanceHeadDynamicJson.buttonCenter}
              showPdfDownload={false}
              showExcelDownload={false}
              tableColumnsToFilter={
                ViewStudentAttendanceHeadDynamicJson.tableColumnsToFilter
              }
              showAddButton={false}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={this.columns}
              getListById={this.getListById}
              tableData={this.props.studentAtt?.studentAttendanceHead}
              showLoader={this.state.showLoader}
              showPegination={false}
              showDetails={true}
              downLoadPdfFromApi={true}
              pdfDownload={this.downloadPdf}
              rowDetails={this.rowDetails}
              // onDelete={this.onDelete}
              onSearchData={this.submitHandler}
              baseIdColumn={ViewStudentAttendanceHeadDynamicJson.baseIdColumn}
              apiBaseURL={ViewStudentAttendanceHeadDynamicJson.apiBaseURL}
            />
            {/* <Grid container justifyContent="space-evenly" direction="row">
                <Grid sx={{ marginBottom: 3 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Viewing Student Attendance By Head/Principal"
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Details"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={this.props.yearDetails?.yearDetails}
                        onChange={this.changeHandlerSearch}
                        isError={yearDetailError ? true : false}
                        errorText={yearDetailError ? yearDetailError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Semester Details"
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={semester}
                      name={"semester"}
                      options={this.props.Semester?.semisterDetailsList}
                      onChange={this.changeHandlerSearch}
                      isError={semesterError ? true : false}
                      errorText={semesterError ? semesterError : " "}
                    />
                    </Grid>
                  </Grid>
                  <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="left"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Division"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={division}
                      name={"division"}
                      options={this.props.yearDetails?.distinctDivision}
                      onChange={this.changeHandlerSearch}
                      isError={divisionError ? true : false}
                      errorText={divisionError ? divisionError : " "}
                    />
                  </Grid>
                </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    
                  </Grid>
                </Grid>
                
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="center"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Search"
                  onClick={this.submitHandler}
                />
              </Grid>
              <Grid
                sx={{ marginTop: 1, marginBottom: 2 }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <SearchExternalPlacedStudList onSearch={this.onSearch} />
</Grid>
              <Grid item sx={{ marginTop: 2 }}>
                <DynamicTable
                  data={this.getFilteredTableData()}
                  tableHead={this.columns}
                  showPegination={false}
                  isActionColActive={true}
                  showDetails={true}
                  rowDetails={this.rowDetails}
                />
              </Grid> */}
          </>
        )}
        {this.state.showTable && (
          <>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              {" "}
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={10.5} md={10.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Students List"
                    />
                  </Grid>
                  <Grid item xs={12} sm={1.5} md={1.5}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back to List"
                      onClick={this.backToList}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 1 }} item xs={12} sm={12} md={12}>
                <CoordinatorPreviewTable rowData={this.state.dataToSave} />
              </Grid>
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  yearDetails: state.internshipDeclaration,
  Semester: state.rollNo,
  studentAtt: state.studentAttendance,
});
const mapDispatchToProps = {
  showNotification,
  getAcademicYear,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getAlert,
  getStudentAttendanceListForHead,
  getStudentAttendanceListForHeadView,
  resetReducList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPresentDetailsByHead);
