import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import {
  getInterestedStudById,
  resetValues,
} from "../Placement/ListOfCompany/PlacementCompanyList.slice";
import { deleteFileData } from "../UploadFile/file.slice";
import StudentPlacement from "./StudentPlacement";
import SearchExternalPlacedStudList from "../../components/SearchTable";
import { Grid } from "@mui/material";

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "companyName",
    title: "Student Name With Company",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "status",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
];

class ExternalPlacedStudentTable extends Component {
  getColumns() {
    let { columns } = this.props;

    return columns.map((column) => {
      if (column.name === "action") {
        return {
          name: "action",
          title: column.title,
          getCellValue: (params) => {
            const onClickView = (e) => {
              e.stopPropagation();
              this.rowView(params);
            };

            return (
              <div>
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={onClickView}
                />
              </div>
            );
          },
        };
      } else if (column.name === "status") {
        return {
          title: column.title,
          name: column.name,
          getCellValue: (params) => {
            return (
              <ChipCompo
                label={params.status === 1 ? "Done" : "Not Done"}
                variant="outlined"
                size="small"
                color={params.status === 1 ? "success" : "error"}
              />
            );
          },
        };
      } else {
        return column;
      }
    });
  }
  state = {
    open: false,
    searchValue: "",
  };
  UpdateInfoData = () => {
    console.log("Open Interested Student");
    this.setState({
      open: false,
      flag: false,
    });
    this.props.resetValues();
    this.props.deleteFileData();
  };
  rowView = (params) => {
    this.props.getInterestedStudById({ id: params.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      open: true,
      flag: true,
    });
  };

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.externalStudentList?.appliedPlacedStudentList.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["companyName"] &&
            currentRow["companyName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["status"] &&
            currentRow["status"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      sorting = [],
      pageSizes = [5, 10, 15, 0],
      hiddenColumnNames = [],
      externalStudentList,
    } = this.props;
    console.log(externalStudentList?.appliedStudentList);
    const tableCols = this.getColumns();

    return (
      <>
        {!this.state.open && (
          <>
            <Grid
              sx={{ marginTop: 1, marginBottom: 2 }}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <SearchExternalPlacedStudList onSearch={this.onSearch} />
            </Grid>
            <DynamicTable
              data={this.getFilteredTableData()}
              tableHead={columns}
              showPegination={true}
              rowViewData={this.rowView}
              showView={true}
            />
          </>
        )}
        {this.state.open && <StudentPlacement goBack={this.UpdateInfoData} />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  externalStudentList: state.external,
});
const mapDispatchToProps = {
  getInterestedStudById,
  getAlert,
  resetValues,
  deleteFileData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalPlacedStudentTable);
