import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  semisterDetailsList: [],
  isFetch: false,

  studentList: [],
  isFetchStudentList: false,
};
let URL = endpoints.semisterDetails;
let StudentListURL = endpoints.studentRollList;

const rollnoSlice = createSlice({
  name: "rollno",
  initialState,
  reducers: {
    semisterDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        semisterDetailsList: row,
        isFetch: true,
      };
    },

    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentList: row,
        isFetchStudentList: true,
      };
    },
  },
});

export const { semisterDetailsSuccess, studentListSuccess } =
  rollnoSlice.actions;

export default rollnoSlice.reducer;

// http://localhost:8088/api/acdmc/v1/semister-detail/get-semister-detail-by-academic-and-year-detail-id?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c
export const getSemesterDetailsByYearAndAcademic =
  ({ yearId, academicId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-semister-detail-by-academic-and-year-detail-id?academicYearId=" +
          academicId +
          "&yearDetailId=" +
          yearId,
      }).then(({ data, success }) => {
        if (success) {
          const semisterDetails = data.data;
          let index = 0;
          let row = [];
          semisterDetails.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.semister.name,
              semisterId: data1.semister.id,
              isActive: data1.isActive,
              sortNo:data1.semister.sortNo
            };
            row.push(bData);
            return data1;
          });
          dispatch(semisterDetailsSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentList =
  ({ yearId, semisterID, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          StudentListURL +
          "/list-by-year-detail-semister-detail-and-division?yearDetailId=" +
          yearId +
          "&semisterDetailId=" +
          semisterID +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const studentRollList = data.data;
          let index = 0;
          let row = [];
          studentRollList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              lastName: data1.lastName,
              firstName: data1.firstName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              name:
                data1.lastName + " " + data1.firstName + " " + data1.middleName,
              prnNo: data1.prnNo,
              rollNo: data1.rollNo,
              divisionId: data1.divisionId,
              dateOfReporting: data1.dateOfReporting,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentListSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByReportingIds =
  ({ reportingIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          StudentListURL +
          "/get-reporting-details-by-reporting-detail-ids?ids=" +
          reportingIds,
      }).then(({ data, success }) => {
        if (success) {
          const studentRollList = data.data;
          let index = 0;
          let row = [];
          studentRollList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              admissionRegistrationDetailId:
                data1.getAdmissionRegistrationDetail,
              lastName: data1.lastName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              name:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
              prnNo: data1.prnNo,
              rollNo: data1.rollNo,
              divisionId: data1.divisionId,
              dateOfReporting: data1.dateOfReporting,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentListSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
