import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicPreview from "../../../components/Preview/DynamicPreview";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const InternshipCompanyPreviewPopup = ({ open, previewData, handleCloses }) => {
  // const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const handleClose = () => {
    handleCloses();
  };
  useEffect(() => {
    console.log(previewData);
  });
  const title = [
    [
      {
        name: "companyName",
        title: "Company Name",
        colSpan: 3,
      },
    ],
    [
      {
        name: "internshipType",
        title: "Internship Type",
        colSpan: 0,
      },
      {
        name: "interviewMode",
        title: "Interview Mode",
        colSpan: 0,
      },
    ],

    [
      {
        name: "venue",
        title: "Venue",
        colSpan: 3,
      },
    ],
    [
      {
        name: "yearDetails",
        title: "Year Details",
        colSpan: 0,
      },
      {
        name: "division",
        title: "Division",
        colSpan: 0,
      },
    ],

    [
      {
        name: "durationFromDate",
        title: "Internship Duration From Date",
        colSpan: 0,
      },
      {
        name: "durationToDate",
        title: "Internship Duration To Date",
        colSpan: 0,
      },
    ],

    [
      {
        name: "fromDate",
        title: "Declaration Valid From Date",
        colSpan: 0,
      },
      {
        name: "toDate",
        title: "Declaration Valid To Date",
        colSpan: 0,
      },
    ],

    [
      {
        name: "fromTime",
        title: "From Time",
        colSpan: 0,
      },
      {
        name: "toTime",
        title: "To Time",
        colSpan: 0,
      },
    ],

    [
      {
        name: "designation",
        title: "Designation",
        colSpan: 0,
      },
      {
        name: "stipend",
        title: "Stipend( ₹ Per month )",
        colSpan: 0,
      },
    ],

    [
      {
        name: "urlLink",
        title: "URL Link",
        colSpan: 3,
      },
    ],

    [
      {
        name: "isQualificationCriteriaRequired",
        title: "Qualification Criteria",
        colSpan: 0,
      },
      {
        name: "remark",
        title: "Remark",
        colSpan: 0,
      },
    ],
  ];
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Preview
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              {/* <div align="right">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Back"
                  onClick={handleCloses}
                />
              </div> */}
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Grid
                  sx={{ mb: 2 }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  rowSpacing={2}
                >
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Basic Details"
                  />
                </Grid>
                <DynamicPreview data={previewData} title={title} />
                {/* <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab
                        textColor="secondary"
                        indicatorColor="secondary"
                        label="Step 1"
                        value="1"
                      />
                      <Tab
                        textColor="secondary"
                        indicatorColor="secondary"
                        label="Step 2"
                        value="2"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Grid
                      sx={{ mb: 2 }}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      rowSpacing={2}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 20 }}
                        label="Basic Details"
                      />
                    </Grid>
                    <DynamicPreview data={previewData} title={title} />
                  </TabPanel>
                  <TabPanel value="2">
                    <QualificationPreview
                      DeclarationData={DeclarationData}
                      title={title}
                    />
                  </TabPanel>
                </TabContext> */}
              </Box>
            </Paper>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternshipCompanyPreviewPopup);
