import { Grid, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import
{
  onlyNumber,
  validEmail,
  facebookRegex
} from "../../../components/Comman/Util/Validations";
let docs;
class SocialProfile extends React.Component
{
  state = {
    id: 0,
    formErrors: [],
    website: "",
    facebook: "",
    linkedin: "",
    youtube: "",
    instagram: "",
    formErrors: [],
    twitter: "",
  };

  changedHandler = ( event ) =>
  {
    const { name, value } = event.target;

    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  componentDidMount()
  {
    const { studentFillProfileList } = this.props;
    if ( Object.keys( studentFillProfileList.applicant ).length !== 0 )
    {
      this.setState( {
        id: studentFillProfileList.applicant.id,
        website: studentFillProfileList.applicant.websitePortfoliBlog,
        facebook: studentFillProfileList.applicant.facebookProfile,
        linkedin: studentFillProfileList.applicant.linkedinProfile,
        youtube: studentFillProfileList.applicant.youtubeChannel,
        instagram: studentFillProfileList.applicant.instagramProfile,
        twitter: studentFillProfileList.applicant.twitterHhandle,
      } );
    } else
    {
    }
  }
  componentWillReceiveProps( nextProps )
  {
    if (
      this.props.studentFillProfileList !== nextProps.studentFillProfileList
    )
    {
      if (
        this.props.studentFillProfileList.applicant !==
        nextProps.studentFillProfileList.applicant
      )
      {
        this.setState( {
          id: nextProps.studentFillProfileList.applicant.id,
          website:
            nextProps.studentFillProfileList.applicant.websitePortfoliBlog,
          facebook: nextProps.studentFillProfileList.applicant.facebookProfile,
          linkedin: nextProps.studentFillProfileList.applicant.linkedinProfile,
          youtube: nextProps.studentFillProfileList.applicant.youtubeChannel,
          instagram:
            nextProps.studentFillProfileList.applicant.instagramProfile,
          twitter: nextProps.studentFillProfileList.applicant.twitterHhandle,
        } );
      }
    }
  }


  handleFormValidationNew()
  {
    const {
      website,
      facebook,
      instagram,
      youtube,
      linkedin,
      twitter
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    // new code for not checking blank only checking regex
    const twitterRegex = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]{1,15})\/?$/;
    const facebookRegex = /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9.]{5,})\/?$/;
    const websiteRegex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(?:\/[^\s]*)?$/;
    const instagramRegex = /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)\/?$/;
    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:c\/|channel\/|user\/)?([a-zA-Z0-9_-]{1,})\/?(?:\?[\w=&]*)?$/;
    const linkedinRegex = /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(in|pub)\/([a-zA-Z0-9\-]+)(?:\/[^\s]*)?$/;


    if ( twitter !== null || typeof twitter !== 'undefined' || twitter.trim() !== "" )
    {
      if (twitter.trim() !== "" && !twitter.match( twitterRegex ))
        {
          formIsValid = false;
          formErrors[ "twitterError" ] = "Please enter a valid Twitter URL";
      } 
    } 

    if ( facebook !== null || typeof facebook !== 'undefined' || facebook.trim() !== "" )
    {
      if (facebook.trim() !== "" &&  !facebook.match( facebookRegex ))
      {
        formIsValid = false;
        formErrors[ "facebookError" ] = "Please enter valid facebook url";
      }
    }
   
    if ( website !== null || typeof website !== 'undefined' || website.trim() !== "" )
    {
      if (website.trim() !== "" &&   !website.match( websiteRegex ))
      {
        formIsValid = false;
        formErrors[ "websiteError" ] = "Please enter a valid website";
      }
    }

    if ( instagram !== null || typeof instagram !== 'undefined' || instagram.trim() !== "" )
    {
      if (instagram.trim() !== "" &&    !instagram.match( instagramRegex ))
      {
        formIsValid = false;
        formErrors[ "instagramError" ] = "Please enter valid Instagram url";
      }
    }

    if ( youtube !== null || typeof youtube !== 'undefined' || youtube.trim() !== "" )
    {
      if (youtube.trim() !== "" &&   !youtube.match( youtubeRegex ))
      {
        formIsValid = false;
        formErrors[ "youtubeError" ] = "Please enter a valid YouTube URL";
      }
    }

    if ( linkedin !== null || typeof linkedin !== 'undefined' || linkedin.trim() !== "" )
    {
      if (linkedin.trim() !== "" &&   !linkedin.match( linkedinRegex ))
      {
        formIsValid = false;
        formErrors[ "linkedinError" ] = "Please enter a valid LinkedIn URL";
      }
    }


    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }


  handleFormValidation()
  {
    const {
      website,
      facebook,
      instagram,
      youtube,
      linkedin,
      twitter
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    // new code
    const twitterRegex = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]{1,15})\/?$/;
    const facebookRegex = /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/([a-zA-Z0-9.]{5,})\/?$/;
    const websiteRegex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(?:\/[^\s]*)?$/;
    const instagramRegex = /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_\.]+)\/?$/;
    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:c\/|channel\/|user\/)?([a-zA-Z0-9_-]{1,})\/?(?:\?[\w=&]*)?$/;
    const linkedinRegex = /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(in|pub)\/([a-zA-Z0-9\-]+)(?:\/[^\s]*)?$/;


    if ( twitter === null || typeof twitter === 'undefined' || twitter.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "twitterError" ] = "Twitter field is required";
    } else if ( !twitter.match( twitterRegex ) )
    {
      formIsValid = false;
      formErrors[ "twitterError" ] = "Please enter a valid Twitter URL";
    }

    if ( facebook === null || typeof facebook === 'undefined' || facebook.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "facebookError" ] = "Facebook field is required";
    }
    else if ( !facebook.match( facebookRegex ) )
    {
      formIsValid = false;
      formErrors[ "facebookError" ] = "Please enter valid facebook url";
    }


    if ( website === null || typeof website === 'undefined' || website.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "websiteError" ] = "Website field is required";
    } else if ( !website.match( websiteRegex ) )
    {
      formIsValid = false;
      formErrors[ "websiteError" ] = "Please enter a valid website";
    }


    if ( instagram === null || typeof instagram === 'undefined' || instagram.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "instagramError" ] = "Instagram is required";
    } else if ( !instagram.match( instagramRegex ) )
    {
      formIsValid = false;
      formErrors[ "instagramError" ] = "Please enter valid Instagram url";
    }



    if ( youtube === null || typeof youtube === 'undefined' || youtube.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "youtubeError" ] = "YouTube URL is required";
    } else if ( !youtube.match( youtubeRegex ) )
    {
      formIsValid = false;
      formErrors[ "youtubeError" ] = "Please enter a valid YouTube URL";
    }



    if ( linkedin === null || typeof linkedin === 'undefined' || linkedin.trim() === "" )
    {
      formIsValid = false;
      formErrors[ "linkedinError" ] = "LinkedIn URL is required";
    } else if ( !linkedin.match( linkedinRegex ) )
    {
      formIsValid = false;
      formErrors[ "linkedinError" ] = "Please enter a valid LinkedIn URL";
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  submitHandler = ( e ) =>
  {
    e.preventDefault();
    const { id, website, facebook, linkedin, youtube, instagram, twitter } =
      this.state;
    docs = {
      id: id,
      websitePortfoliBlog: website,
      facebookProfile: facebook,
      linkedinProfile: linkedin,
      youtubeChannel: youtube,
      instagramProfile: instagram,
      twitterHhandle: twitter,
      type: 3,
    };
    if ( this.handleFormValidationNew() )
    {
      this.props.onSave( docs );
    }
  };

  render()
  {
    const { website, facebook, linkedin, youtube, instagram, twitter } =
      this.state;

    const {
      facebookError,
      websiteError,
      instagramError,
      youtubeError,
      linkedinError,
      twitterError
    } = this.state.formErrors;

    const { onCancel, editForm } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            {/* <Grid item xs={12} sm={6} md={12} /> */}

            <Grid
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Website"
                  />
                </Grid>

                <Grid item xs={12} sm={10.5} md={10.5}>
                  <TextField
                    color="primary"
                    name="website"
                    size="small"
                    value={website}
                    fullWidth
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    helperText={websiteError ? websiteError : " "}
                    error={websiteError ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Facebook"
                  />
                </Grid>

                <Grid item xs={12} sm={10.5} md={10.5}>
                  <TextField
                    color="primary"
                    name="facebook"
                    size="small"
                    value={facebook}
                    fullWidth
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    helperText={facebookError ? facebookError : " "}
                    error={facebookError ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Linkedin"
                  />
                </Grid>

                <Grid item xs={12} sm={10.5} md={10.5}>
                  <TextField
                    color="primary"
                    name="linkedin"
                    size="small"
                    value={linkedin}
                    disabled={editForm ? true : false}
                    fullWidth
                    onChange={this.changedHandler}
                    helperText={linkedinError ? linkedinError : " "}
                    error={linkedinError ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="You Tube"
                  />
                </Grid>

                <Grid item xs={12} sm={10.5} md={10.5}>
                  <TextField
                    color="primary"
                    name="youtube"
                    size="small"
                    value={youtube}
                    disabled={editForm ? true : false}
                    fullWidth
                    onChange={this.changedHandler}
                    helperText={youtubeError ? youtubeError : " "}
                    error={youtubeError ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Instagram"
                  />
                </Grid>

                <Grid item xs={12} sm={10.5} md={10.5}>
                  <TextField
                    color="primary"
                    name="instagram"
                    size="small"
                    value={instagram}
                    disabled={editForm ? true : false}
                    fullWidth
                    onChange={this.changedHandler}
                    helperText={instagramError ? instagramError : " "}
                    error={instagramError ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Twitter"
                  />
                </Grid>

                <Grid item xs={12} sm={10.5} md={10.5}>
                  <TextField
                    color="primary"
                    name="twitter"
                    size="small"
                    value={twitter}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={twitterError ? twitterError : " "}
                    error={twitterError ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={1} />
            <Grid
              rowSpacing={2}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>

              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  studentList: state.studentList,
  departments: state.department,
  studentFillProfileList: state.studentFillProfile,
} );

const mapDispatchToProps = {
  showNotification,
  getAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( SocialProfile );
