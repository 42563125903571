import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { getExamFormDeclareBYUserId } from "../../ExamFormDeclaration/examform.slice";
import { showNotification } from "../../Landing/Landing.slice";
import StudentApplayMatrix from "./StudentApplayMatrix";
import {
  getAdminSchemeHead,
  getAdminSchemeHeadDeclare,
  getAdminSubject,
  getAdminSubjectDeclare,
  getAdminSubjectSchemeHead,
  getAdminSubjectSchemeHeadDeclare,
} from "./StudentApply.slice";

class StudentApply extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    room: "",
    openForm: true,
    subject: [],
    teacher: [],
    examData: {},
    scheduleDate: "",
    examDetails: {},
  };
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "examTitle",
      title: "Exam Title",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "startDate",
      title: "From Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },

    {
      name: "endDate",
      title: "To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
  ];
  editStudentInfo = (data) => {
    console.log(data);
    this.setState({
      examDetails: data,
    });
    if (data.declareResult == 0 || data.declareResult == 1) {
      this.props
        .getAdminSubject({
          userRegistrationId: this.props.studData.id,
          semisterDetailId: data.semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            // this.setState({
            //   showLoader: false,
            // });
            // this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getAdminSchemeHead({
          userRegistrationId: this.props.studData.id,
          semisterDetailId: data.semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            // this.setState({
            //   showLoader: false,
            // });
            // this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getAdminSubjectSchemeHead({
          userRegistrationId: this.props.studData.id,
          semisterDetailId: data.semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            // this.setState({
            //   showLoader: false,
            // });
            // this.props.getAlert({ message: "Something went wrong" });
          }
        });

      this.setState({
        openForm: false,
        examData: data,
        showLoader: true,
      });
    } else {
      this.props
        .getAdminSubjectDeclare({
          userRegistrationId: this.props.studData.id,
          semisterDetailId: data.semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            // this.setState({
            //   showLoader: false,
            // });
            // this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getAdminSchemeHeadDeclare({
          userRegistrationId: this.props.studData.id,
          semisterDetailId: data.semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            // this.setState({
            //   showLoader: false,
            // });
            // this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getAdminSubjectSchemeHeadDeclare({
          userRegistrationId: this.props.studData.id,
          semisterDetailId: data.semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            // this.setState({
            //   showLoader: false,
            // });
            // this.props.getAlert({ message: "Something went wrong" });
          }
        });

      this.setState({
        openForm: false,
        examData: data,
        showLoader: true,
      });
    }
  };
  closeMatrix = () => {
    this.setState({
      openForm: true,
    });
    this.props
      .getExamFormDeclareBYUserId({
        userRegistrationId: this.props.studData.id,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  };

  render() {
    console.log(this.props.employeeDetails.employeeList);
    console.log(this.props.examDeclareList.examDeclare);
    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title={"Exam Application"} />
        </Paper>
        <br />
        {this.state.openForm ? (
          <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ marginTop: -3 }}
                justifyContent="flex-end"
              >
                <Grid sx={{ marginLeft: 1 }} item xs={12} sm={10.5} md={10.5} />
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=""
                  />
                </Grid>
                <Grid item xs={12} sm={1.5} md={1.5}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={this.props.onCancel}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                justifyContent="space-evenly"
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={
                        "Student Name : " + this.props.studData.studentName
                      }
                    />
                  </Grid>

                  <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={"PRN : " + this.props.studData.prnNo}
                    />
                  </Grid>
                  <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={"Email Id : " + this.props.studData.studEmail}
                    />
                  </Grid>
                  <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={"Contact No: " + this.props.studData.studContact}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  sx={{ marginTop: 2 }}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <DynamicTable
                    data={this.props.examDeclareList?.examFormDeclare}
                    tableHead={this.columns}
                    showPegination={false}
                    rowView={this.editStudentInfo}
                    showApply={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <StudentApplayMatrix
            onCloseMat={this.closeMatrix}
            semisterDetailId={""}
            studentData={this.props.studData}
            examListData={this.state.examData}
            subjectList={[]}
            onCancel={this.props.onCancel}
            examDetails={this.state.examDetails}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeDetails: state.employeeList,
  examDeclareList: state.examDeclare,
});

const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAdminSubject,
  getAdminSubjectDeclare,
  getAdminSchemeHead,
  getAdminSchemeHeadDeclare,
  getAdminSubjectSchemeHead,
  getExamFormDeclareBYUserId,
  getAdminSubjectSchemeHeadDeclare,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentApply);
