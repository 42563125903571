import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiDelete } from "../../../utils/api_service";
import { showNotification } from "../../Landing/Landing.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import SearchAppliedList from "../../../components/SearchTable";
import {
  getAppliedList,
  getFeedback,
  getFeedbackFromDtToDt,
  getFeedbackByEventId,
} from "./feedback.slice";
import EmployeeFeedBackDetails from "./EmployeeFeedBackDetails";
import { downloadF } from "../../PDF/PDFDownload";

import ViewDeclaredFeedback from "./ViewDeclaredFeedback";
import { LabelCompo } from "../../../components/Comman/Label";
import { ButtonCompo } from "../../../components/Comman/Button";
import { FontAwesomeIconCompo } from "../../../components/Comman/IconButton";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "employeeName",
    title: "Employee name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "designation",
    title: "Employee Designation",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "department",
    title: "Employee Department",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];

class EmployeeAppliedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      showEmpFeedBack: false,
      empFeedBack: {},
      showTable: true,
      showForm: false,
      showAppliedForm: false,
      showAppliedStudentsForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      department: "",
      academicYearId: "",
      fromDate: "",
      toDate: "",
      departmentList: [],
      editData: {},
      formErrors: [],
      searchValue: "",
    };
  }

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onFormCancel = () => {
    this.setState({
      showForm: true,
      showAppliedForm: false,
      showTable: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.departments.department,
          { id: "0", name: "All" },
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
    if (this.props.feedbackList !== nextProps.feedbackList) {
      if (
        this.props.feedbackList.feedbackData !==
        nextProps.feedbackList.feedbackData
      ) {
        this.props
          .getAppliedList({ id: nextProps.feedbackList.feedbackData.id })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    // const { fieldMeta } = this.props;
    // fieldMeta.map((currentField) => {
    //   if (currentField.defaultValue) {
    //     fieldData[currentField.dataKey] = currentField.defaultValue;
    //   }
    // });

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  OnClickEdit = (data) => {
    console.log(data);
    this.setState({
      showAppliedForm: false,
      showTable: false,
      showForm: true,
      editData: data,
    });
  };

  appliedList = (data) => {
    console.log(data);
    this.setState({
      showTable: false,
      showForm: false,
      showAppliedForm: true,
      appliedData: data,
    });
  };

  componentDidMount() {
    const { appliedData, appliedDataFromEvent } = this.props;
    console.log(appliedData);
    if (appliedData !== undefined && Object.keys(appliedData).length !== 0) {
      this.props.getAppliedList({ id: appliedData.id }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
      const fromDateARR =
        appliedData.fromDate == null ? "" : appliedData.fromDate.split("-");
      const fromDt =
        fromDateARR == ""
          ? ""
          : new Date(fromDateARR[2], fromDateARR[1] - 1, fromDateARR[0]);
      const toDateARR =
        appliedData.toDate == null ? "" : appliedData.toDate.split("-");
      const toDt =
        toDateARR == ""
          ? ""
          : new Date(toDateARR[2], toDateARR[1] - 1, toDateARR[0]);
      this.setState({
        ...this.state,
        id: appliedData.id,
        feedbackName: appliedData.feedbackNameId,
        description: appliedData.description,
        fromDate: fromDt,
        toDate: toDt,
        department: appliedData.departmentIds.split(","),
      });
    }
    if (
      appliedDataFromEvent !== undefined &&
      Object.keys(appliedDataFromEvent).length !== 0
    ) {
      this.props
        .getFeedbackByEventId({ id: appliedDataFromEvent.id })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  }

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.employeeFeedback + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getFeedback().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  rowDetails = (row) => {
    this.setState({
      showEmpFeedBack: true,
      empFeedBack: row,
    });
    console.log(row);
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };
  onClosedForm = () => {
    if (this.state.showEmpFeedBack) {
      this.setState({
        showEmpFeedBack: false,
        empFeedBack: {},
      });
    } else {
      this.props.onCancel();
    }
  };
  generatePDF = () => {
    const feedbackId =
      this.props.appliedData.id !== undefined
        ? this.props.appliedData.id
        : this.props.feedbackList.feedbackData.id;
    downloadF({
      url:
        "/api/report/v1/feedback-report/generate-employee-report?feedbackdeclareId=" +
        feedbackId,
      ext: "pdf",
      openNewTab: true,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.feedbackList?.appliedListData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["employeeName"] &&
          currentRow["employeeName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["designation"] &&
          currentRow["designation"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["department"] &&
          currentRow["department"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const { showTable, showForm, showEmpFeedBack, empFeedBack } = this.state;
    console.log(this.props.feedbackList);
    const { appliedData, feedbackList } = this.props;
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          {!showEmpFeedBack && (
            <>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    sx={{ mb: 3, ml: -1, mt: -1 }}
                    item
                    xs={12}
                    sm={10.5}
                    md={10.5}
                  >
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Feedback Declare Form (For Employee)"
                    />
                  </Grid>
                  <Grid sx={{ mb: 2, mt: -1 }} item xs={12} sm={1.5} md={1.5}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back to List"
                      onClick={this.onClosedForm}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={2} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Feedback Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} md={10}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        appliedData !== undefined
                          ? appliedData.feedbackName
                          : Object.keys(feedbackList.feedbackData).length !== 0
                          ? feedbackList.feedbackData.feedbackName
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={2} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Department"
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} md={10}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        appliedData !== undefined
                          ? appliedData.department
                          : Object.keys(feedbackList.feedbackData).length !== 0
                          ? feedbackList.feedbackData.department
                          : ""
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={2} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={appliedData.fromDate+"-"+appliedData.toDate}
                    />
                  </Grid> */}
                </Grid>
                {appliedData !== undefined && appliedData.description !== "" ? (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={2} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Instruction"
                      />
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={appliedData.description}
                      />
                    </Grid>
                  </Grid>
                ) : appliedData === undefined &&
                  Object.keys(feedbackList.feedbackData).length !== 0 &&
                  feedbackList.feedbackData.description !== "" ? (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={2} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Instruction"
                      />
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={feedbackList.feedbackData.description}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={2} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={10} md={10}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        appliedData !== undefined
                          ? appliedData.fromDate + "/" + appliedData.toDate
                          : Object.keys(feedbackList.feedbackData).length !== 0
                          ? feedbackList.feedbackData.fromDate +
                            "/" +
                            feedbackList.feedbackData.toDate
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={11} md={11}>
                    <SearchAppliedList onSearch={this.onSearch} />
                  </Grid>
                  <Grid sx={{ mt: 2 }} item xs={12} sm={1} md={1}>
                    <FontAwesomeIconCompo
                      color="error"
                      fontSize="small"
                      title="PDF"
                      baseClassName="fas"
                      className="fa-file-pdf"
                      onClick={this.generatePDF}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mt: 2, pr: 2 }}>
                <DynamicTable
                  data={this.getFilteredTableData()}
                  tableHead={columns}
                  showDetails={true}
                  showPegination={false}
                  rowDetails={this.rowDetails}
                />
              </Grid>
            </>
          )}
          {showEmpFeedBack && (
            <EmployeeFeedBackDetails
              editData={empFeedBack}
              backToAppliedForm={this.onClosedForm}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYear: state.academicYear,
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
  feedbackList: state.empFeedback,
});
const mapDispatchToProps = {
  showNotification,
  getFeedback,
  getAlert,
  getDepartment,
  getAcademicYear,
  getFeedbackFromDtToDt,
  getAppliedList,
  downloadF,
  getFeedbackByEventId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeAppliedList);
