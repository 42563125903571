import { FormHelperText, Grid, Link, Paper, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import {
  getStudentListForAllocation,
  getAllocatedListOfStudentAndTeacher,
  resetStudentAndTeacher,
  resetValues,
  resetStudentList,
} from "./AllocationOfStudent.slice";
import { Link as RouterLink } from "react-router-dom";
import { getDistinctDivision } from "../InternshipDeclaration/InternshipDeclataration.slice";
import endpoints from "../../config/endpoints";
import { getEmployeeByDeptId } from "../EmployeeDetails/employee.slice";
import StudentAllocationTable from "./StudentAllocationTable";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import TeacherStudentList from "./TeacherStudentListDialoge";
import { apiPost } from "../../utils/api_service";
import swal from "sweetalert";
import { Loading1 } from "../../components/Loading1";
import moment from "moment";

class AllocationOfStudent extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    formErrors: [],
    department: [],
    division: [],
    internType: "",
    remark: "",
    teacherSelect: [],
    setList: [],
    selection: [],
    openTeachStud: false,
    actionVariableFlag: false,
    divisionList: [],
    showLoader: false,
  };

  componentDidMount() {
    const { getYearDetails, getInternshipType, getDepartment, resetValues } =
      this.props;
    getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getInternshipType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    resetValues();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList?.distinctDivision !==
        nextProps.internshipDeclarationList?.distinctDivision
      ) {
        let divisionData = [];
        divisionData = [
          { id: "all", name: "All" },
          ...nextProps.internshipDeclarationList.distinctDivision.filter(
            (data) => data.isActive == 1
          ),
        ];

        this.setState({
          divisionList: divisionData,
        });
      }
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      const { getDistinctDivision, resetStudentList } = this.props;
      resetStudentList();
      this.setState({ [name]: newValue });
      if (name === "yearDetail") {
        this.setState({ division: [] });

        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation1() {
    const { department, setList, teacherSelect, selection } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (department.length === 0 || department === null) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    if (teacherSelect.length === 0 || teacherSelect === null) {
      formIsValid = false;
      formErrors["teacherSelectErr"] = myConstClass.teacherNameMsg;
    }
    if (selection.length < teacherSelect.length) {
      formIsValid = false;
      formErrors["teacherSelectErr"] = myConstClass.teacherSelectionMsg;
    }
    if (setList.length === 0 || setList === null) {
      formIsValid = false;
      formErrors["setListErr"] = "Select Atleast One Student";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidation() {
    const { internType, division, yearDetail, remark } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (internType.toString().trim() === "" || internType === null) {
      formIsValid = false;
      formErrors["internTypeError"] = myConstClass.internTypeMsg;
    }
    if (division.length === 0 || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divisionNameMsg;
    }

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { yearDetail, internType, division } = this.state;
    const { getStudentListForAllocation, getAlert } = this.props;
    this.setState({
      showLoader: true,
    });
    if (this.handleFormValidation()) {
      getStudentListForAllocation({
        yearDetailId: yearDetail,
        internTypeId: internType,
        divisionId: division,
      }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({ showLoader: false });
        }
      });
    } else {
      this.setState({ showLoader: false });
    }
  };

  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");

    if (filterValue.length !== 0) {
      if (name === "division") {
        const { resetStudentList } = this.props;
        resetStudentList();
        let divisionList = [];
        this.state.divisionList.map((data) => {
          if (data.id !== "all") {
            divisionList.push(data.id);
          }
        });
        this.setState({
          [name]: divisionList,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    }

    if (name === "department") {
      this.props
        .getEmployeeByDeptId({ departmentId: value.join() })
        .then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
    }

    // setType(typeof value === "string" ? value.split(",") : value);
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  getRow = (e) => {
    let selectedList = [];
    this.setState({
      selection: e,
    });
    e.forEach((data) => {
      const selRow = this.props.allocationList.studentList.filter(
        (row, index) => {
          return row.id === data;
        }
      );
      selectedList = [...selectedList, selRow[0]];
    });
    this.setState({ setList: selectedList });
  };
  closeAllocation = () => {
    this.setState({
      openTeachStud: false,
    });
  };

  rowView = (data) => {};

  allocate = () => {
    const { teacherSelect, setList } = this.state;

    let listOfStudent = setList.map(({ index, ...rest }) => {
      return rest;
    });

    listOfStudent = listOfStudent.map(({ name, ...rest }) => {
      return rest;
    });

    const { getAllocatedListOfStudentAndTeacher, getAlert } = this.props;
    if (this.handleFormValidation1()) {
      getAllocatedListOfStudentAndTeacher({
        teacherId: teacherSelect,
        studentList: listOfStudent,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            openTeachStud: true,
            actionVariableFlag: false,
          });
        }
      });
    }
  };
  submitHandler = () => {
    const { allocationList } = this.props;
    const { yearDetail, internType, division, remark } = this.state;
    const teacherStudentList = allocationList.teacherStudentList.map(
      (teacherStudentData) => {
        let listOfStudent = teacherStudentData.internshipVivaDeclareStudent.map(
          ({ studentName, ...rest }) => {
            return rest;
          }
        );
        listOfStudent = listOfStudent.map(({ index, ...rest }) => {
          return rest;
        });
        let changedDate =
          teacherStudentData.vivaDate === ""
            ? ""
            : moment(teacherStudentData.vivaDate).format("DD-MM-YYYY");
        let changedFromTime =
          teacherStudentData.time === null
            ? ""
            : moment(teacherStudentData.time, "h:mm:ss A").format("HH:mm");
        teacherStudentData = {
          userRegistrationId: teacherStudentData.teacherId,
          vivaDate: changedDate,
          remark: teacherStudentData.remark,
          time: changedFromTime,
          internshipVivaDeclareStudent: listOfStudent,
          status: "",
        };
        return teacherStudentData;
      }
    );
    const vivaDeclareData = {
      yearDetailId: yearDetail,
      internshipType: {
        id: internType,
      },
      divisionId: division.join(),
      remark: remark,
      status: 0,
      internshipVivaDeclareFaculty: teacherStudentList,
    };

    swal({
      title: "Are you sure?",
      text: "Do you want to submit the data?",
      // icon: "war  ning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiPost({
          url: endpoints.vivaDeclare,
          postBody: vivaDeclareData,
        }).then(({ data, success }) => {
          if (!success) {
            getAlert({ message: "Something went wrong" });
          } else {
            this.props.resetStudentAndTeacher();
            window.location.replace("/viva-declaration");
          }
        });
      }
    });
  };
  render() {
    const {
      yearDetail,
      internType,
      department,
      division,
      remark,
      teacherSelect,
      selection,
    } = this.state;
    const {
      internTypeError,
      departmentError,
      teacherSelectErr,
      setListErr,
      yearDetailError,
      divisionError,
    } = this.state.formErrors;
    const {
      internshipDeclarationList,
      internshipTypeList,
      allocationList,
      departmentList,
      employeeDetails,
    } = this.props;
    return (
      <>
        {this.state.showLoader && <Loading1 />}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Viva Declaration Admin Side"
                />
              </Grid>
              <Grid
                sx={{ mt: 1 }}
                item
                container
                justifyContent="right"
                xs={12}
                md={6}
                lg={6}
              >
                <Link
                  color="primary"
                  variant="body2"
                  component={RouterLink}
                  to="/viva-declaration"
                  sx={{ textDecoration: "none" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back To List"
                  />
                </Link>
              </Grid>
            </Grid>
            <form
              onSubmit={this.submitHandler}
              autoComplete="off"
              noValidate={true}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={1}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Detail"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={internshipDeclarationList?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Intern Type"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={internType}
                      name={"internType"}
                      options={internshipTypeList?.internshipType}
                      onChange={this.ChangeHandlerSearch}
                      isError={internTypeError ? true : false}
                      errorText={internTypeError ? internTypeError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Division"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        er={divisionError}
                        name1="division"
                        value1={division}
                        names={this.state.divisionList}
                        onChange={this.multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Remark"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="remark"
                        size="small"
                        value={remark}
                        onChange={this.changedHandler}
                        error={false}
                        helperText={" "}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="center"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.searchData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <br />
            {/* 
            <Grid item sx={{ mr: 1 }} xs={12} sm={12} md={12}></Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              {setListErr && (
                <FormHelperText error>{setListErr}</FormHelperText>
              )}
              <StudentAllocationTable
                onSelectionChange={this.getRow}
                rows={allocationList.studentList}
                selection={selection}
                isActionColActive={false}
              />
            </Grid>
            <br />
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={6}
                md={6}
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Department"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <MultipleSelect
                    er={departmentError}
                    name1="department"
                    value1={department}
                    names={departmentList?.department.filter(
                      (row) => row.showForSelection === 1 && row.isActive == 1
                    )}
                    onChange={this.multiSelectHandler}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={6}
                md={6}
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Teacher Selection"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <MultipleSelect
                    er={teacherSelectErr}
                    name1="teacherSelect"
                    value1={teacherSelect}
                    names={employeeDetails?.employeeList.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.multiSelectHandler}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="right"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid item sm={6} xs={12} md={6} lg={6}></Grid>
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="right"
                m={1}
              >
                <Grid item sm={2} xs={12} md={2} lg={2}>
                  <Link
                    color="primary"
                    variant="body2"
                    component={RouterLink}
                    to="/viva-declaration"
                    sx={{ textDecoration: "none" }}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                    />
                  </Link>
                </Grid>
                <Grid item sm={2} xs={12} md={2} lg={2}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Allocate"
                    onClick={this.allocate}
                  />
                </Grid>
              </Grid>
            </Grid>
            <TeacherStudentList
              open={this.state.openTeachStud}
              submit={true}
              submitHandler={this.submitHandler}
              allocationList={allocationList.teacherStudentList}
              handleClickOpen={this.closeAllocation}
              actionVariable={this.state.actionVariableFlag}
              allocationStudentList={allocationList.studentList}
            />
          </>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  departmentList: state.department,
  divisionList: state.division,
  internshipTypeList: state.internshipType,
  allocationList: state.allocation,
  employeeDetails: state.employeeList,
});
const mapDispatchToProps = {
  getYearDetails,
  getInternshipType,
  getStudentListForAllocation,
  getDepartment,
  getEmployeeByDeptId,
  getDistinctDivision,
  getAllocatedListOfStudentAndTeacher,
  resetStudentAndTeacher,
  getAlert,
  resetValues,
  resetStudentList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllocationOfStudent);
