import endpoint from "../config/endpoints";

export const UnitTestMasterJson = {
    apiBaseURL: endpoint.unitTest,
    "screenTitle": "Unit Test Master",
    "showAddButton": true,
    baseIdColumn: "id",
    "fieldMeta": [
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "name",
            isMandatory: true
        },
    ]
}