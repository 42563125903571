import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  teacherWorkload: [],
  isFetch: false,

  attendanceYearDetails: [],
  isFetchAttendanceYearDetails: false,

  attendanceSemesterDetails: [],
  isFetchAttendanceSemesterDetails: false,

  attendanceDivision: [],
  isFetchAttendanceDivision: false,

  attendanceSubjects: [],
  isFetchattendanceSubjects: false,

  studentAttendanceFormYeardetails: [],
  isFetchStudentFormYearDetails: false,

  attendanceSemesterDetailsStudent: [],
  isFetchAttendanceSemesterDetailsStudent: false,
};
let URL = endpoints.employeeWorkload;
const teacherWorkloadSlice = createSlice({
  name: "teacherWorkload",
  initialState,
  reducers: {
    teacherWorkloadSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        teacherWorkload: row,
        isFetch: true,
      };
    },

    yearDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        attendanceYearDetails: row,
        isFetchAttendanceYearDetails: true,
      };
    },

    semesterDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        attendanceSemesterDetails: row,
        isFetchAttendanceSemesterDetails: true,
      };
    },

    semesterDetailsStudentSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        attendanceSemesterDetailsStudent: row,
        isFetchAttendanceSemesterDetailsStudent: true,
      };
    },

    divisionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        attendanceDivision: row,
        isFetchAttendanceDivision: true,
      };
    },

    subjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        attendanceSubjects: row,
        isFetchattendanceSubjects: true,
      };
    },

    studentFormYearDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceFormYeardetails: row,
        isFetchStudentFormYearDetails: true,
      };
    },
  },
});

export const {
  teacherWorkloadSuccess,
  yearDetailsSuccess,
  semesterDetailsSuccess,
  divisionSuccess,
  subjectSuccess,
  studentFormYearDetailsSuccess,
  semesterDetailsStudentSuccess,
} = teacherWorkloadSlice.actions;

export default teacherWorkloadSlice.reducer;

export const getTeacherWorkload =
  ({ semisterDetailId, divisionId }) =>
  async (dispatch) => {
    try {
      apiGet({
        url:
          URL +
          "/by-semister-detail-division?semisterDetailId=" +
          semisterDetailId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const workload = data.data;
          let index = 0;

          const row = workload.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              subjectName: data1.subject.name,
              subjectScheme: data1.subjectDetail.schemeHeadId.name,
              batch: data1.batch.batchName,
              isActive: data1.isActive,
              subjectList: "",
            };
            return bData;
          });
          dispatch(teacherWorkloadSuccess({ row }));
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-employee-work-load-by-academic-year?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0
export const getAttendanceYearDetails =
  ({ academicYear }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url:
          URL +
          "/get-employee-work-load-by-academic-year?academicYearId=" +
          academicYear,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.year.programType.name +
                " - " +
                data1.programTitle.brName +
                " - " +
                data1.year.className,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(yearDetailsSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-semister-detail-by-year-detail-academic-year-user-id?yearDetailId=5ca6de9a-829f-4c6e-a134-88adfb556381&academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0

export const getSemesterDetails =
  ({ yearDetailId, academicYearId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url:
          URL +
          "/get-semister-detail-by-year-detail-academic-year-user-id?yearDetailId=" +
          yearDetailId +
          "&academicYearId=" +
          academicYearId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.semisterDetail.id,
              name: data1.semisterDetail.semister.name,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(semesterDetailsSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-division-by-semister-detail-user-id?semisterDetailId=de9699a2-eaf5-4ed6-ba36-94be8d19f16b
export const getDivisionForAttendance =
  ({ semesterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-division-by-semister-detail-user-id?semisterDetailId=" +
          semesterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(divisionSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-division-by-semister-detail-user-id?semisterDetailId=de9699a2-eaf5-4ed6-ba36-94be8d19f16b

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-subject-by-semister-detail-division-batch-user-id?semisterDetailId=673e30fc-777c-42fb-8fe7-e211f5f1a279&divisionId=d910e283-b645-426e-865e-040440651afc&batchId=5533f0db-10cf-46f1-be96-4ae222f9c795

// export const getSubjectByDivisionAttendance =
//   ( { semister, division, batchId } ) =>
//     async ( dispatch ) =>
//     {
//       try
//       {
//         const success = apiGet( {
//           url: URL + "/get-subject-by-semister-detail-division-batch-user-id?semisterDetailId=" + semister + "&divisionId=" + division + "&batchId=" + batchId,
//         } ).then( ( { data, success } ) =>
//         {
//           if ( success )
//           {
//             const content = data.data;
//             let index = 0;
//             console.log( content );
//             const row = content.map( ( data1 ) =>
//             {
//               index = index + 1;

//               let bData = {
//                 index: index,
//                 id: subjectDetailId ? data1.subjectDetail.id : data1.id,
//                 name:
//                   data1.subject !== null ? data1.subject.name : "" +
//                     " - " +
//                     data1.subjectDetail !== null ? data1.subjectDetail.schemeHeadId.shortName : "",
//               };
//               return bData;
//             } );
//             dispatch( subjectByDivisionSuccess( { row } ) );
//           }
//           return success;
//         } );
//         return success;
//       } catch ( e )
//       {
//         return console.error( e.message );
//       }
//     };

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-subject-by-semister-detail-division-batch-user-id?semisterDetailId=673e30fc-777c-42fb-8fe7-e211f5f1a279&divisionId=d910e283-b645-426e-865e-040440651afc&batchId=5533f0db-10cf-46f1-be96-4ae222f9c795

export const getSubjectByDivisionAttendance =
  ({ semister, division, batchId, subjectDetailId = false }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-by-semister-detail-division-batch-user-id?semisterDetailId=" +
          semister +
          "&divisionId=" +
          division +
          "&batchId=" +
          batchId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: subjectDetailId ? data1.subjectDetail.id : data1.id,
              name:
                data1.subject !== null
                  ? data1.subject.name +
                    " - " +
                    data1.subjectDetail.schemeHeadId.shortName
                  : "",
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-year-detail-by-current-academic-year-user-id

export const getYearDetailsStudentForm = () => async (dispatch) => {
  try {
    const success = apiGet({
      url: URL + "/get-year-detail-by-current-academic-year-user-id",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name:
              data1.year.programType.name +
              " - " +
              data1.programTitle.brName +
              " - " +
              data1.year.className,
            programTypeId: data1.year.programType.id,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(studentFormYearDetailsSuccess({ row }));
      }
      return success;
    });
    return success;
  } catch (e) {
    return console.error(e.message);
  }
};

// http://localhost:8088/api/acdmc/v1/employee-work-load/get-semister-detail-by-year-detail-current-academic-year-user-id?yearDetailId=5ca6de9a-829f-4c6e-a134-88adfb556381
export const getSemesterDetailsStudentForm =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url:
          URL +
          "/get-semister-detail-by-year-detail-current-academic-year-user-id?yearDetailId=" +
          yearDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.semisterDetail.id,
              name: data1.semisterDetail.semister.name,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(semesterDetailsStudentSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };
