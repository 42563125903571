import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import SearchAppliedList from "../../../components/SearchTable";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { Loading1 } from "../../../components/Loading1";
import endpoints from "../../../config/endpoints";
import { DeemedMeetingDynamicJson } from "../../../DynamicFormsJson/DeemedMeeting";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import {
  getDeemedCommittee,
  getDeemedCommitteeMember,
} from "../Committee/committee.slice";
import DeemedMinutesOfMeeting from "../MinutesOfMeeting/index";
import {
  getDeemedAggendaList,
  getDeemedAttendanceList,
  getDeemedMeeting,
  getDeemedMeetingSchedule,
  getDeemedMeetingHistory,
  getDeemedMeetingHistoryByFilter,
} from "./meeting.slice";
import CommitteeForm from "./meetingForm";
import DynamicTable from "../../../components/dynamicscreens/DynamicTable";
import MeetingTable from "./meetingTable";
import endpoint from "../../../config/endpoints";
import { downloadF } from "../../PDF/PDFDownload";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "details",
    title: "Details",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
    canSearch: true,
  },
  {
    name: "date",
    title: "Date",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
    canSearch: true,
  },
  {
    name: "time",
    title: "Time",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "15%",
    canSearch: true,
  },
  {
    name: "venue",
    title: "Venue",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "15%",
    canSearch: true,
  },
  {
    name: "isActive",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
    canSearch: true,
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "15%",
  },
];

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DeemedMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showLoader: false,
      showForm: false,
      showTable: true,
      showAttendance: false,
      showAttendanceTable: false,
      showAggenda: false,
      showView: false,
      committee: "",
      fromDate: "",
      toDate: "",
      editData: {},
      committeeData: {},
      scheduleData: {},
      searchValue: "",
      dynamicMasterData: {
        committeeMaster: this.props.deemedCommitteeList.deemedCommittee,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.deemedCommitteeList !== nextProps.deemedCommitteeList) {
      if (
        this.props.deemedCommitteeList.deemedCommittee !==
        nextProps.deemedCommitteeList.deemedCommittee
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            committeeMaster: nextProps.deemedCommitteeList.deemedCommittee,
          },
        });
      }
    }
  }

  onAttendance = (row) => {
    this.props
      .getDeemedCommitteeMember({ id: row.committee.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props
            .getDeemedAttendanceList({ id: row.id })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showForm: false,
                  showTable: false,
                  showAttendance: true,
                  showAttendanceTable: true,
                  showAggenda: false,
                  scheduleData: row,
                });
              }
            });
        }
      });
  };

  OnClickEdit = (data) => {
    this.setState({
      editData: data,
      showForm: true,
      showTable: false,
    });
  };

  showPdfData = (data) => {
    this.setState({
      showLoader: true,
    });

    downloadF({
      url: endpoint.minutesOfMeetingPdf + "/" + data.id + "?documentType=1",
    });

    this.setState({
      showLoader: false,
    });
  };

  rowViewData = (data) => {
    this.props.getDeemedAttendanceList({ id: data.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getDeemedAggendaList({ id: data.id }).then((response) => {
      if (!response) {
      } else {
        this.setState({
          showForm: false,
          showTable: false,
          showAttendanceTable: false,
          showAggenda: true,
          showAttendance: true,
          scheduleData: data,
          showView: true,
        });
      }
    });
  };

  rowDetails = (data) => {
    this.props
      .getDeemedCommitteeMember({ id: data.committee.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props
            .getDeemedAttendanceList({ id: data.id })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
        }
      });
    this.props.getDeemedAggendaList({ id: data.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showForm: false,
          showTable: false,
          showAttendanceTable: false,
          showAggenda: true,
          showAttendance: true,
          scheduleData: data,
          showView: false,
        });
      }
    });
  };

  OnClickAdd = (data) => {
    this.setState({
      showForm: false,
      showTable: false,
      showMember: true,
      committeeData: data,
      editData: {},
    });
  };

  onClickView = (data) => {
    this.setState({
      showForm: false,
      showTable: false,
      showMember: true,
      committeeData: data,
    });
  };

  submitHandler = () => {
    const { committee, fromDate, toDate } = this.state;

    let dateChange = moment(fromDate).format("YYYY-MM-DD");
    let toDateChange = moment(toDate).format("YYYY-MM-DD");
    this.props
      .getDeemedMeeting({
        committee,
        fromDate: dateChange,
        toDate: toDateChange,
      })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  };

  toggleFormTableVisibility = () => {
    const { showTable, showForm } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
    });
  };

  backToForm = () => {
    this.setState({
      showForm: false,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
      editData: {},
    });
    this.toggleFormTableVisibility();
  };

  componentDidMount() {
    this.props.getDeemedCommittee().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getDeemedMeetingSchedule().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getDeemedMeetingHistory().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  changeStatus = (row, rowData) => {
    const { committee, fromDate, toDate } = this.state;

    let docs = {
      id: row.id,
      committee: {
        id: row.committee.id,
      },
      date: row.date,
      details: row.details,
      time: row.time,
      urlLink: row.urlLink,
      venue: row.venue,
      isActive: row.isActive === 1 ? 0 : 1,
    };
    swal({
      title: "Are you sure?",
      text: "Do you want to change status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        this.setState({
          showLoader: true,
        });
        apiPost({
          url: endpoints.deemedMeeting,
          postBody: docs,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              showLoader: false,
            });
            this.props.showNotification({ msg: "Status updated" });
            this.props.getDeemedMeetingSchedule().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
            this.props.getDeemedMeetingHistory().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  rowDelete = (rowData) => {
    const { committee, fromDate, toDate } = this.state;

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          showLoader: true,
        });
        apiDelete({
          url: endpoints.deemedMeeting + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({
              showLoader: false,
            });
            this.props.showNotification({ msg: "Data deleted successfully" });
            this.props.getDeemedMeetingSchedule().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
            this.props.getDeemedMeetingHistory().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            this.props.showNotification({ msg: "Failed to delete" });
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.props.showNotification({ msg: "Failed to delete" });

        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onFormSave = (isToggle) => () => {};

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL, getAlert } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: endpoints.deemedMeeting,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({ msg: "Data saved successfully" });
        this.props.getDeemedMeetingSchedule().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        this.props.getDeemedMeetingHistory().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        this.setState({
          isLoading: false,
        });
      } else {
        getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {};

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  cancelHandler = () => {
    this.setState({
      showForm: false,
      showTable: true,
      showMember: false,
    });
  };
  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };
  changeToDate = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  backToTable = () => {
    this.setState({
      showForm: false,
      showTable: true,
      showAttendance: false,
    });
  };

  onSearchData = (data) => {
    const { getDeemedMeeting, getAlert, getDeemedMeetingHistoryByFilter } =
      this.props;
    this.setState({
      showLoader: true,
    });
    if (data.value === 0) {
      getDeemedMeeting({
        fromDate: data.fromDate,
        toDate: data.toDate,
        committee: data.committee,
      }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    } else {
      getDeemedMeetingHistoryByFilter({
        fromDate: data.fromDate,
        toDate: data.toDate,
        committee: data.committee,
      }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
  };

  render() {
    const {
      showTable,
      showForm,
      editData,
      committeeData,
      committee,
      fromDate,
      showAttendance,
      dynamicMasterData,
      showAttendanceTable,
      showAggenda,
      scheduleData,
      showView,
      showLoader,
    } = this.state;

    return (
      <>
        {this.state.showLoader && <Loading1 />}
        {showForm && (
          <>
            <CommitteeForm
              onCancel={this.toggleFormTableVisibility}
              editData={this.state.editData}
              onSave={this.onSave}
            />
          </>
        )}
        {showAttendance && (
          <>
            <DeemedMinutesOfMeeting
              onCancel={this.toggleFormTableVisibility}
              showAttendanceTable={showAttendanceTable}
              showAggenda={showAggenda}
              scheduleData={scheduleData}
              showView={showView}
              onSave={this.onSave}
              backToForm={this.backToTable}
              backToTable={this.backToTable}
            />
          </>
        )}

        {showTable && (
          <>
            <MeetingTable
              openForm={this.onAddButtonClick}
              onSave={this.onSearchData}
              onEdit={this.OnClickEdit}
              onDelete={this.rowDelete}
              onDetails={this.rowDetails}
              rowViewData={this.rowViewData}
              showPdfData={this.showPdfData}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  deemedMeetingList: state.deemedMeeting,
  deemedCommitteeList: state.deemedCommittee,
});
const mapDispatchToProps = {
  getDeemedCommittee,
  showNotification,
  getDeemedMeeting,
  getDeemedCommitteeMember,
  getAlert,
  getDeemedAttendanceList,
  getDeemedAggendaList,
  getDeemedMeetingSchedule,
  getDeemedMeetingHistory,
  getDeemedMeetingHistoryByFilter,
};
export default connect(mapStateToProps, mapDispatchToProps)(DeemedMeeting);
