import { Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
// import {  } from "../../UploadFile/file.slice";
import { apiPost } from "../../utils/api_service";
import {
  getStudentList,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
} from "../UploadFile/file.slice";

class StudentDeclareInternshipListTable extends React.Component {
  // columns = [
  //   { name: "index", title: "Sr. No." },
  //   { title: "Document Name", name: "name" },
  //   {
  //     name: "action",
  //     title: "Action",
  //     getCellValue: ( params ) =>
  //     {
  //       const onClickDelete = ( e ) =>
  //       {
  //         e.stopPropagation();
  //         swal( {
  //           title: "Are you sure?",
  //           text: "Do you want to delete the file?",
  //           icon: "warning",
  //           buttons: [ "Cancel", true ],
  //         } ).then( ( res ) =>
  //         {
  //           if ( res )
  //           {
  //             this.props.deleteFile( { file: params.fileName } ).then( () => { } );
  //             const docList = this.props.fileData.fileData.filter(
  //               ( row ) => row.fileName !== params.fileName
  //             );

  //             this.props.fileMulitipleSave( { fileData: docList } );
  //           }
  //         } );
  //       };
  //       const details = ( e ) =>
  //       {
  //         e.stopPropagation();
  //         this.props
  //           .openFileInNewTab( { file: params.fileName } )
  //           .then( ( response ) =>
  //           {
  //             if ( !response )
  //             {
  //               this.props.getAlert( { message: "Something went wrong" } );
  //             }
  //           } );
  //       };

  //       return (
  //         <div>
  //           {!params.id && <><MyComponentWithIconProps
  //             statusImage={DeleteIcon}
  //             color="error"
  //             fontSize="medium"
  //             title="Delete"
  //             onClick={onClickDelete}
  //           />
  //             &nbsp;</>}
  //           <MyComponentWithIconProps
  //             statusImage={InfoIcon}
  //             color="primary"
  //             fontSize="medium"
  //             title="Details"
  //             onClick={details}
  //           />
  //         </div>
  //       );
  //     },
  //   },
  // ];

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      title: "Document Name",
      name: "name",
      positionCenter: true,
      showInscreen: true,
      positionCenter: false,
      alignCenter: "left",
      width: "100%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  details = (params) => {
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  state = {
    id: 0,
    academicYear: "",
    yearDetail: "",
    typeOfAdmission: "",
    applicationId: "",
    formErrors: [],
    interestedStudent: false,
    accountFees: false,
    listOfComp: true,
    fromDate: new Date(),
    toDate: new Date(),
    placementType: "",
    companyName: "",
    companyAddress: "",
    designation: "",
    placementPackage: "",
    remark: "",
    fileName: "",
    documentName: "",
    fileNameData: [],
    upload: false,
    uploadError: false,
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentData?.getInterestedStudByIdStatus !==
        nextProps.studentData?.getInterestedStudByIdStatus &&
      this.props.fileData.fileData.length === 0
    ) {
      console.log(nextProps.studentData?.getInterestedStudByIdStatus);

      this.setState({
        placementType:
          nextProps.studentData?.getInterestedStudByIdStatus.placementType,
        id: nextProps.studentData?.getInterestedStudByIdStatus.id,
        companyAddress:
          nextProps.studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName:
          nextProps.studentData?.getInterestedStudByIdStatus.companyName,
        placementPackage:
          nextProps.studentData?.getInterestedStudByIdStatus.placementPackage,
        remark: nextProps.studentData?.getInterestedStudByIdStatus.remark,
        designation:
          nextProps.studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .lastName,
        studUserRegistrationId:
          nextProps.studentData?.getInterestedStudByIdStatus.userRegistrationId,
        durationFromDate:
          nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate,
        durationToDate:
          nextProps.studentData?.getInterestedStudByIdStatus.durationToDate,
      });
      const docList =
        nextProps.studentData?.getInterestedStudByIdStatus.placementIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              index: index + 1,
              name: fileData.docName,
              id: fileData.id,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }
    if (
      this.props.fileData.fileData !== nextProps.fileData.fileData &&
      this.state.id === 0
    ) {
      const docList = nextProps.fileData.fileData.map((fileData, index) => {
        let document = {
          index: index + 1,
          ...fileData,
        };
        return document;
      });
      this.props.fileMulitipleSave({ fileData: docList });
    }
  }

  componentDidMount() {
    const {
      getClass,
      getAcademicYear,
      getYearDetails,
      getStudentList,
      getAlert,
      studentData,
    } = this.props;
    const {
      id,
      companyName,
      companyAddress,
      placementType,
      designation,
      placementPackage,
      remark,
      durationFromDate,
      durationToDate,
    } = this.state;

    this.props.getInternshipType().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    console.log(studentData);
    // console.log(data)
    // console.log(studentData)
    // const durationFromDateArr = data.durationFromDate.split("-");
    // const changedDurationFromDate = new Date(
    //   durationFromDateArr[2],
    //   durationFromDateArr[1] - 1,
    //   durationFromDateArr[0]
    // );
    // const durationToDateArr = data.durationToDate.split("-");
    // const changedDurationToDate = new Date(
    //   durationToDateArr[2],
    //   durationToDateArr[1] - 1,
    //   durationToDateArr[0]
    // );
    // this.setState({
    //   companyName: data.companyName,
    //   placementPackage: data.placementPackage,
    //   remark: data.remark,
    //   designation: data.designation,
    //   placementType: data.placementType,
    //   durationFromDate: data.durationFromDate,
    //   durationToDate: data.durationToDate,
    // });
    if (Object.keys(studentData?.getInterestedStudByIdStatus).length != 0) {
      console.log(studentData?.getInterestedStudByIdStatus);
      this.setState({
        placementType: studentData?.getInterestedStudByIdStatus.placementType,
        id: studentData?.getInterestedStudByIdStatus.id,
        companyAddress: studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName: studentData?.getInterestedStudByIdStatus.companyName,
        placementPackage:
          studentData?.getInterestedStudByIdStatus.placementPackage,
        remark: studentData?.getInterestedStudByIdStatus.remark,
        designation: studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration.lastName,
        studUserRegistrationId:
          studentData?.getInterestedStudByIdStatus.userRegistrationId,
        durationFromDate:
          studentData?.getInterestedStudByIdStatus.durationFromDate,
        durationToDate: studentData?.getInterestedStudByIdStatus.durationToDate,
      });
      const docList =
        studentData?.getInterestedStudByIdStatus.placementIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              id: fileData.id,
              name: fileData.docName,
              fileName: fileData.fileName,
              index: index + 1,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }
    //     getStudentList({yearDetails:data.yearDetailId,qualification:[]}).then((response)=>{
    //   if(!response){
    //     getAlert({ message: "Something went wrong" });
    //   }
    // });
  }

  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };

  changeToDate = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation(name) {
    const {
      companyName,
      placementType,
      designation,
      academicYear,
      fromDate,
      toDate,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (academicYear.toString().trim() === "") {
      formIsValid = false;
      formErrors["academicYearError"] = myConstClass.academicYearMessage;
    }

    if (placementType.toString().trim() === "") {
      formIsValid = false;
      formErrors["studentNameError"] = myConstClass.studentNameMsg;
    }

    if (companyName === "") {
      formIsValid = false;
      formErrors["companyNameError"] = myConstClass.companyNameMsg;
    }

    if (designation.toString().trim() === "") {
      formIsValid = false;
      formErrors["designationError"] = myConstClass.designationMsg;
    }

    if (fromDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { fromDate, toDate, academicYear, placementType } = this.state;
    const {} = this.props;
    console.log(fromDate + " fromDate");
    console.log(toDate + " toDate");
    // getAdmissionList({ academicId: academicYear, yearDetailId: yearDetail });
  };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };

  // rowAppliedList = (data) => {
  //   this.setState({
  //     accountFees: true,
  //     interestedStudent: false,
  //     listOfComp: false,
  //     applicationId:data.id
  //   });
  // };

  changeH = (fileName, name, index) => {
    console.log(fileName);
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  // uploadImage = (docId) => {
  //   let {fileNameData} = this.state;
  //     let fileDataList = fileNameData.filter((qData) => qData.name === docId);
  //     const { fileData } = this.props;
  //     let filterSaveFileData = fileData.fileData.filter(
  //       (qData) => qData.name === docId
  //     );

  //     const { saveMultipleFile, resetFileData } = this.props;
  //     if (filterSaveFileData.length != 0) {
  //       resetFileData({ fileData: filterSaveFileData });
  //     }

  //     if (fileDataList.length !== 0) {
  //       this.setState({
  //         upload: true,
  //         uploadError: false,
  //       });
  //       let fileData = {
  //         fileName: fileDataList[0].fileName,
  //         name: fileDataList[0].name,
  //       };
  //       saveMultipleFile({ file: fileData }).then((resp) => {
  //         let fileData = {
  //           fileName: fileDataList[0].fileName,
  //           name: fileDataList[0].name,
  //           uploadFile: true,
  //         };
  //         let filterFileList = fileNameData.filter(
  //           (qData) => qData.name !== docId
  //         );
  //         fileNameData = [...filterFileList, fileData];
  //         this.setState({ upload: true });
  //         console.log(fileNameData);
  //       });
  //     } else {
  //       this.setState({
  //         uploadError: true,
  //       });
  //     }
  //   };
  uploadImage = () => {
    const { saveMultipleFile, getAlert } = this.props;
    const { fileName, documentName } = this.state;
    if (fileName !== "") {
      this.setState({
        upload: true,
        uploadError: false,
      });
      let fileData = {
        fileName: fileName,
        name: documentName,
      };
      saveMultipleFile({ file: fileData }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
          this.setState({
            documentName: "",
            fileName: "",
            upload: false,
            uploadError: false,
          });
        } else {
          this.setState({
            documentName: "",
            fileName: "",
            upload: false,
            uploadError: false,
          });
        }
      });
    } else {
      this.setState({
        uploadError: true,
      });
    }
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };
  onSave = () => {
    const {
      id,
      companyName,
      companyAddress,
      designation,
      placementType,
      placementPackage,
      remark,
      fromDate,
      toDate,
      fileName,
      documentName,
    } = this.state;
    const { data, studentData } = this.props;
    let fromDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    console.log(fromDate + " to " + fromDt);
    console.log(toDate + " to " + toDt);
    const documentList = this.props.fileData.fileData.map((docData) => {
      let docs = {
        docName: docData.name,
        fileName: docData.fileName,
      };
      if (docData.id) {
        docs = {
          ...docs,
          id: docData.id,
        };
      }
      return docs;
    });
    let studentInfo = {
      internshipDeclare: null,
      companyName: companyName,
      companyAdd: companyAddress,
      rollNo: "",
      userRegistrationId: "",
      selectedStatus: 1,
      designation: designation,
      placementPackage: placementPackage,
      durationFromDate: fromDt,
      durationToDate: toDt,
      internshipType: {
        id: placementType,
      },
      vivaStatus: 0,
      approvedStatus: 0,
      intrestStatus: 1,
      internshipIntrestedStudentDocument: documentList,
    };

    if (id !== 0) {
      let student = studentData?.getInterestedStudByIdStatus;
      student = {
        ...student,
        designation: designation,
        placementPackage: placementPackage,
        durationFromDate: fromDt,
        durationToDate: toDt,
        internshipIntrestedStudentDocument: documentList,
      };
      apiPost({
        url: endpoints.internshipIntrest + "/edit",
        postBody: student,
      }).then(({ data, success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props.goBack();
          this.props.deleteFileData();
        }
      });
    } else {
      apiPost({
        url: endpoints.internshipIntrest,
        postBody: studentInfo,
      }).then(({ data, success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props.goBack();
          this.props.deleteFileData();
        }
      });
    }
  };
  render() {
    const {
      listOfComp,
      fromDate,
      toDate,
      placementType,
      companyName,
      companyAddress,
      remark,
      designation,
      placementPackage,
      documentName,
      uploadError,
      upload,
      fileName,
    } = this.state;
    const {
      fromDateError,
      toDateError,
      studentNameError,
      designationError,
      companyNameError,
    } = this.state.formErrors;
    const {
      goBack,
      intrestedStudList,
      studentData,
      fileData,
      internshipTypeList,
    } = this.props;
    console.log(intrestedStudList);
    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            {listOfComp && (
              <>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={10.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label="View Student Details"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={1.5}
                      justifyContent="flex-end"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back to List"
                        fullWidth={true}
                        onClick={goBack}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Placement Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="placementType"
                          id="placementType"
                          value={placementType}
                          onChange={this.changedHandler}
                          size="small"
                          fullWidth={true}
                          {...(Object.keys(
                            studentData?.getInterestedStudByIdStatus
                          ).length != 0
                            ? { disabled: true }
                            : { disabled: false })}
                        >
                          <MenuItem value="0">On Campus</MenuItem>
                          <MenuItem value="1">Off Campus</MenuItem>
                          <MenuItem value="2">Pool Campus</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyName"
                        id="companyName"
                        value={companyName}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(companyNameError
                          ? { error: true, helperText: companyNameError }
                          : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company Address"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyAddress"
                        id="companyAddress"
                        value={companyAddress}
                        onChange={this.changeHandler}
                        fullWidth
                        // {...(companyNameError
                        //   ? { error: true, helperText: companyNameError }
                        //   : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Designation"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="designation"
                        id="designation"
                        value={designation}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(designationError
                          ? { error: true, helperText: designationError }
                          : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Joining Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.changeFromDate}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.changeToDate}
                        {...(toDateError
                          ? { error: true, helperText: toDateError }
                          : "")}
                          {...(Object.keys(studentData?.getInterestedStudByIdStatus).length != 0
                            ? { disabled: true }
                            :  { disabled: false })}
                      />
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Placement Package(₹ Lakh per annum )"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="placementPackage"
                        id="placementPackage"
                        value={placementPackage}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                        //   {...(codeErr ? { error: true, helperText: codeErr }:"")}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                    <DynamicTable
                      data={fileData.fileData}
                      tableHead={this.columns}
                      showPegination={false}
                      rowViewData={this.details}
                      showView={true}
                    />
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Remark"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="remark"
                        id="remark"
                        value={remark}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {/* <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                item
                xs={12}
                sm={10}
                md={10}
                lg={10}
                rowSpacing={2}
                container
                justifyContent="right"
                m={1}
              />
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                rowSpacing={2}
                container
                justifyContent="right"
                m={1}
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  fullWidth={true}
                  onClick={goBack}
                />
              </Grid>
            </Grid> */}

            <Grid
              sx={{ mt: 2 }}
              container
              justifyContent="right"
              columnSpacing={1}
              direction="row"
            >
              <Grid item>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  fullWidth={true}
                  onClick={goBack}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentData: state.companyPlacementList,
  internshipTypeList: state.internshipType,
  intrestedStudList: state.internshipDeclaration,
  fileData: state.file,
});
const mapDispatchToProps = {
  getClass,
  getAcademicYear,
  getInternshipType,
  // getAdmissionList,
  getYearDetails,
  getStudentList,
  getAlert,
  saveMultipleFile,
  fileMulitipleSave,
  saveFile,
  deleteFile,
  openFileInNewTab,
  deleteFileData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentDeclareInternshipListTable);
