import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../components/Loading1";
import SearchApplyEvent from "../../../components/SearchTable";
import endpoint from "../../../config/endpoints";
import { listOfAppliedForEventsJson } from "../../../DynamicFormsJson/ListOfStudentAppliedForEventDynamic";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getEvent } from "../Event Management/event.slice";
import AppliedEventListTable from "./AppliedEventListTable";
import ParticipatedEventListTable from "./ParticipatedEventListTable";
import {
  getAppliedEventList,
  getParticipatedEventList,
} from "./StudentApply.slice";
let newInstData;
const ApplyForEvent = ({
  getParticipatedEventList,
  resetReducList,
  getAcademicYear,
  getAppliedEventList,
  getAlert,
  studParticipatedList,
  showNotification,
}) => {
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const [interestedStudent, setInterestedStudent] = useState(true);
  const [snakMsg, setSnakMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    resetReducList();
    setShowLoader(true);
    const parentChildId = localStorage.getItem("parentChildId");
    getAppliedEventList({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
    const ay = localStorage.getItem("acadamicYearId");
    if (ay) {
      setShowLoader(true);
      getParticipatedEventList({
        academicId: ay,
        yearDetailId: "",
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      }).then((response) => {
        if (!response) {
          setShowLoader(false);
          getAlert({ message: "Something went wrong" });
        } else {
          setShowLoader(false);
        }
      });
    }
  }, []);

  const AppliedEventListData = (data) => {
    console.log(data);
    const parentChildId = localStorage.getItem("parentChildId");
    const ay = localStorage.getItem("acadamicYearId");
    let docs = {
      event: {
        id: data.id,
      },
      amountPaid: 0,
      isCompleted: 0,
    };
    swal({
      title: "Are you sure?",
      text: "Do you want to confirm Participation?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      console.log(docs);
      if (res) {
        apiPost({
          url: endpoint.eventParticipant,
          postBody: docs,
        }).then(({ success }) => {
          if (success) {
            showNotification({
              msg: "Data saved successfully",
            });
            getAppliedEventList({
              userRegistrationId: parentChildId === null ? "" : parentChildId,
            }).then((response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
              }
            });
            getParticipatedEventList({
              academicId: ay,
              yearDetailId: "",
              userRegistrationId: parentChildId === null ? "" : parentChildId,
            }).then((response) => {
              if (!response) {
                setShowLoader(false);
                getAlert({ message: "Something went wrong" });
              } else {
                setShowLoader(false);
              }
            });
          } else {
            getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  const getFilteredTableDataLive = () => {
    return studParticipatedList?.aplliedEventStatus.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["fromDate"] &&
          currentRow["fromDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["toDate"] &&
          currentRow["toDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["eventStatus"] &&
          currentRow["eventStatus"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const getFilteredTableDataParticipated = () => {
    return studParticipatedList?.participatedEventStatus.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["fromDate"] &&
            currentRow["fromDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["toDate"] &&
            currentRow["toDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["eventStatus"] &&
            currentRow["eventStatus"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  return (
    <>
      {showLoader && <Loading1 />}
      {interestedStudent && (
        <div>
          {/* <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}> */}
          {/* <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="List of Student Applied for Events "
                />
              </Grid>

            </Grid> */}

          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <TitleAndBreadCrum title={listOfAppliedForEventsJson.screenTitle} />
          </Paper>
          <br />
          <Grid
            container
            item
            justifyContent="space-evenly"
            spacing={2}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                <SearchApplyEvent onSearch={onSearch} />
              </Paper>
            </Grid>
          </Grid>
          <br />
          {/* <Grid
            sx={{ marginTop: 1, marginBottom: 2 }}
            item
            xs={12}
            sm={12}
            md={12}
          >
            <SearchApplyEvent
              onSearch={onSearch}
            />
          </Grid> */}
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handledChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    "Live Events " +
                    studParticipatedList?.aplliedEventStatus.length
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    "Participated Events " +
                    studParticipatedList?.participatedEventStatus.length
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <AppliedEventListTable
                data={getFilteredTableDataLive()}
                AppliedEventList={AppliedEventListData}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ParticipatedEventListTable
                data={getFilteredTableDataParticipated()}
              />
            </TabPanel>
          </Paper>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  eventList: state.event,
  studParticipatedList: state.studentApplyEvent,
});

const mapDispatchToProps = {
  getAppliedEventList,
  getEvent,
  getAlert,
  showNotification,
  getAcademicYear,
  getParticipatedEventList,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplyForEvent);
