import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { ButtonCompo } from "../../components/Comman/Button";
import { downloadFile, viewMultipleFile } from "../UploadFile/file.slice";
import { getProfile } from "../BasicDetailsPost/basicDetails.slice";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { getAlert } from "../../CommonActions/alert.slice";
import StudentMasterPost from "../BasicDetailsPost/index";
import {
  getStudentById,
  getStudentInfoByPRNNo,
} from "../BasicDetailsPost/basicDetails.slice";
const DeclarationPreview = ({
  data,
  student,
  GoBack,
  backtoList,
  getAlert,
  getStudentInfoByPRNNo,
  getStudentById,
  getProfile,
}) => {
  const [back, setBack] = useState(true);
  const [role, setRole] = useState(true);
  const [openDetailForm, setOpenDetailForm] = React.useState(false);

  useEffect(() => {
    if (data === undefined && Object.keys(student.applicant).length === 0) {
      let role = localStorage.getItem("role");
      if (role && role === "student") {
        setBack(false);
        getProfile().then((resp) => {
          if (!resp) {
            getAlert({
              message: "Something went wrong",
            });
          }
        });
      } else {
        let prnNo = localStorage.getItem("prnNo");
        if (prnNo) {
          getStudentInfoByPRNNo({ prnNo: prnNo }).then((resp) => {
            if (!resp) {
              getAlert({
                message: "Something went wrong",
              });
            }
          });
        }
      }
    }
  }, []);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editStudent = () => {
    setOpenDetailForm(true);
    getStudentById({ studId: student.applicant.id }).then((res) => {
      if (res) {
        // this.props.onSave( true );
      } else {
        getAlert({
          message: "Something went wrong ",
        });
      }
    });
  };
  const onCancel = () => {
    setOpenDetailForm(false);
    getProfile().then((resp) => {
      if (!resp) {
        getAlert({
          message: "Something went wrong",
        });
      }
    });
  };
  useEffect(() => {
    let role = localStorage.getItem("role");
    setRole(role);
  });

  return (
    <>
      {!openDetailForm && (
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <div align="right">
            {back ? (
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Back"
                onClick={GoBack}
              />
            ) : (
              <>
                {role !== "parent" && (
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Edit"
                    onClick={editStudent}
                  />
                )}
              </>
            )}
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    icon={<InfoIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Basic Details"
                    value="1"
                  />
                  <Tab
                    icon={<HomeIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Address Details"
                    value="2"
                  />

                  <Tab
                    icon={<DocumentScannerIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Qualification & Document Details"
                    value="4"
                  />

                  <Tab
                    icon={<PhotoSizeSelectActualIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Photo and Sign"
                    value="5"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Step1 data={data !== undefined ? data : student.applicant} />
              </TabPanel>
              <TabPanel value="2">
                <Step2 data={data !== undefined ? data : student.applicant} />
              </TabPanel>
              <TabPanel value="5">
                <Step4 data={data !== undefined ? data : student.applicant} />
              </TabPanel>
              <TabPanel value="4">
                <Step5 data={data !== undefined ? data : student.applicant} />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      )}
      {openDetailForm && (
        <>
          <StudentMasterPost
            onCancelForm={onCancel}
            editForm={true}
            studFormOpen={true}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  student: state.studentList,
});

const mapDispatchToProps = {
  viewMultipleFile,
  downloadFile,
  getProfile,
  getAlert,
  getStudentById,
  getStudentInfoByPRNNo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationPreview);
