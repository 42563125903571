import {
  FormHelperText,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../../components/Loading1";
import VolunteerUpdateStatusTable from "./VolunteerUpdateStatusTable";
import { showNotification } from "../../../Landing/Landing.slice";
import * as myConstClass from "../../../../config/messageconstant";
// import ExamFormDeclarationMatrix from "../ExamFormDeclaration/ExamFormDeclarationMatrix";
import { apiDelete } from "../../../../utils/api_service";
import endpoint from "../../../../config/endpoints";
import DynamicTable from "../../../../components/Comman/RT/MaterialUIRTTable";
import { getVolunteer, getUpdatedStatus } from "./AdminVolunteerStatus.slice";
import { textOnly } from "../../../../components/Comman/Util/Validations";

// import UpdateInfo from "./UpdateInfo";

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    title: "Seat No",
    name: "seatNo",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },

  {
    title: "Student Name",
    name: "studentName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Applicable Fees",
    name: "applicableFees",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Paid Fees",
    name: "amount",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
];

const VolunteerChangeStatus = ({
  onClose,
  getVolunteer,
  getUpdatedStatus,
  volunteerStatusList,
  show,
  declarationId,
  viewData,
  isActiveStatus,
  getAlert,
}) => {
  let navigate = useNavigate();

  const [searchValue, setSearchValue] = React.useState("");
  const [updateStudInfo, setUpdateStudInfo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [studData, setStudData] = useState({});
  const [snakMsg, setSnakMsg] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [certificateData, setCertificateData] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpValue, setPopUpValue] = useState(false);
  const [toDateError, setToDateError] = React.useState("");
  const [fromDateError, setFromDateError] = React.useState("");
  const [changeStatus, setChangeStatus] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [list, setList] = React.useState([]);
  const [listErrorMsg, setListErrorMsg] = React.useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [showMatrix, setShowMatrix] = useState(false);
  const [examFormData, setExamFormData] = useState([]);
  const [status, setStatus] = React.useState([
    { id: "0", name: "Pending" },
    { id: "1", name: "Approved" },
    { id: "2", name: "Rejected" },
  ]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    getVolunteer().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);

  const handledChange = (event, newValue) => {
    setValue(newValue);
    setSearchValue("");
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (changeStatus === "" || changeStatus === null) {
      formIsValid = false;
      formErrors["changesStatusError"] = "*Select Status";
    }

    if (list.length === 0) {
      formIsValid = false;
      formErrors["emptyListError"] = "*Please select atleast one student";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      setChangeStatus(newValue);
    } else {
      setChangeStatus("");
    }
  };

  const getRow = (e) => {
    setList(e);
  };

  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name == "remark") {
      setRemark(value);
    }
  };

  const submitHandler = () => {
    let newList = list.join();
    if (checkValidation()) {
      if (list.length !== 0) {
        setListErrorMsg(false);
        swal({
          title: "Are you sure?",
          text: " Do you want to update status for this student",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((newValue) => {
          if (newValue) {
            getUpdatedStatus({
              applyId: newList,
              status: changeStatus,
              type: 0,
              remark: remark === "" || remark === null ? "" : remark,
            }).then((success) => {
              if (success) {
                getVolunteer().then((success) => {
                  if (success) {
                    showNotification({
                      msg: "Status Updated successfully",
                    });
                    setChangeStatus("");
                    setList([]);
                  } else {
                    getAlert({ message: "Something went wrong" });
                  }
                });
              } else {
                setShowLoader(false);
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
      // else
      // {
      //   setListErrorMsg( true );
      // }
    }
  };

  return (
    <>
      {showLoader && <Loading1 />}

      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        <TitleAndBreadCrum title="Admin change status ( Volunteer )" />
      </Paper>
      <br />

      <div>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {/* <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              sx={{ marginTop: -3 }}
              justifyContent="flex-end"
            >
              <Grid sx={{ marginLeft: 1 }} item xs={12} sm={10.7} md={10.7} />
              <br />
              <Grid sx={{ marginLeft: 1 }} item xs={12} sm={1.3} md={1.3}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back to List"
                  onClick={onClose}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label={"Year Detail : " + viewData.yearDetails}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} />

              <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label={"Examination : " + viewData.examTitle}
                />
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label={"Semester : " + viewData.semesterName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} />

              <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label={"Start Date : " + viewData.startDate}
                />
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label={"End Date : " + viewData.endDate}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} />
            </Grid> */}

          <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: 2 }}>
            <Tabs
              value={value}
              onChange={handledChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  "Pending "
                  // +volunteerStatusList?.volunteerStatusUpdate.length
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  "Approved "
                  // +  volunteerStatusList?.volunteerStatusUpdate.length
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  "Rejected "
                  // +volunteerStatusList?.volunteerStatusUpdate.length
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* {isActiveStatus == 1 ? ( */}
            <>
              <VolunteerUpdateStatusTable
                rows={volunteerStatusList?.volunteerStatusUpdate.filter(
                  (data) => data.status == 0 && data.type == 0
                )}
                onSelectionChange={getRow}
                selection={list}
              />
              <FormHelperText error>
                {formErrors.emptyListError ? formErrors.emptyListError : " "}
              </FormHelperText>
            </>
            {/* ) : (
                <DynamicTable
                  data={volunteerStatusList?.volunteerStatusUpdate.filter(
                    (data) => data.status == 0
                  )}
                  tableHead={columns}
                  showPegination={false}
                  rowView={rowView}
                  showHeadDelete={false}
                  rowDelete={rowDelete}
                  showAppliedList={true}
                  rowAppliedList={rowAppliedList}
                />
              )} */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* {isActiveStatus == 1 ? ( */}
            <>
              <VolunteerUpdateStatusTable
                rows={volunteerStatusList?.volunteerStatusUpdate.filter(
                  (data) => data.status == 1 && data.type == 0
                )}
                onSelectionChange={getRow}
                selection={list}
              />
              <FormHelperText error>
                {formErrors.emptyListError ? formErrors.emptyListError : " "}
              </FormHelperText>
            </>
            {/* ) : (
                <DynamicTable
                  data={volunteerStatusList?.volunteerStatusUpdate.filter(
                    (data) => data.status == 1
                  )}
                  tableHead={columns}
                  showPegination={false}
                  rowView={rowView}
                  showHeadDelete={false}
                  rowDelete={rowDelete}
                  showAppliedList={true}
                  rowAppliedList={rowAppliedList}
                />
              )} */}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* {isActiveStatus == 1 ? ( */}
            <>
              <VolunteerUpdateStatusTable
                rows={volunteerStatusList?.volunteerStatusUpdate.filter(
                  (data) => data.status == 2 && data.type == 0
                )}
                onSelectionChange={getRow}
                selection={list}
              />
              <FormHelperText error>
                {formErrors.emptyListError ? formErrors.emptyListError : " "}
              </FormHelperText>
            </>
            {/* ) : (
                <DynamicTable
                  data={volunteerStatusList?.volunteerStatusUpdate.filter(
                    (data) => data.status == 2
                  )}
                  tableHead={columns}
                  showPegination={false}
                  rowView={rowView}
                  showHeadDelete={false}
                  rowDelete={rowDelete}
                  showAppliedList={true}
                  rowAppliedList={rowAppliedList}
                />
              )} */}
          </TabPanel>

          {/* {isActiveStatus == 1 && ( */}
          <Grid item sm={12} xs={12} md={12} lg={12} container>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Update Status"
                />
              </Grid>
              <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={changeStatus}
                  name={"changeStatus"}
                  fullWidth
                  options={status.filter(
                    (filterData) => filterData.id != value
                  )}
                  onChange={ChangeHandlerSearch}
                  isError={formErrors.changesStatusError ? true : false}
                  errorText={
                    formErrors.changesStatusError
                      ? formErrors.changesStatusError
                      : " "
                  }
                />
              </Grid>
            </Grid>
            {changeStatus == 2 && (
              <Grid
                item
                sm={12}
                xs={12}
                md={4}
                lg={4}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark"
                  />
                </Grid>
                <Grid
                  sx={{ marginLeft: 1, marginTop: -3 }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    onChange={changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              sx={{ marginTop: -3 }}
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=""
                />
              </Grid>
              <Grid sx={{ marginLeft: 1 }} item xs={12} sm={12} md={12}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Submit"
                  onClick={submitHandler}
                />
              </Grid>
            </Grid>
            {changeStatus == 2 ? (
              <Grid item xs={12} sm={3} md={3} />
            ) : (
              <Grid item xs={12} sm={7} md={7} />
            )}
          </Grid>
          {/* )} */}
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  volunteerStatusList: state.volunteerStatus,
});

const mapDispatchToProps = {
  getAlert,
  getVolunteer,
  getUpdatedStatus,
  showNotification,
  // getStudentStatusList,
  // getSchemeHead,
  // getSubjectFromId,
  // getStudentAppliedSubject,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolunteerChangeStatus);
