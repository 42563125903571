import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import endpoint from "../../../../../config/endpoints";
import * as msgConstant from "../../../../../config/messageconstant";
import { adminConsultancyJson } from "../../../../../DynamicFormsJson/AdminConsultancy";
import { showNotification } from "../../../../Landing/Landing.slice";
import { consultancyColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../../../utils/api_service";
import {
  getConsultancy,
  getConsultancyByDate,
  getConsultancyWithoutFilter,
  getAdminConsultancy,
} from "../../AcademicDetails/Consultancy/consultancy.slice";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getEmployeeByDeptId } from "../../../../EmployeeDetails/employee.slice";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import * as myConstClass from "../../../../../config/messageconstant";
import { Loading1 } from "../../../../../components/Loading1";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminConsultancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      showLoader: false,
      fromDate: "",
      toDate: "",
      formErrors: [],
      dynamicMasterData: {
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeList?.employeeList,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
    if (this.props.employeeList !== nextProps.employeeList) {
      if (
        this.props.employeeList?.employeeList !==
        nextProps.employeeList?.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeList?.employeeList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    // this.props.getConsultancyWithoutFilter().then((response) => {
    //   if (!response) {
    //     this.setState({ showLoader: false });

    //     this.props.getAlert({ message: "Something went wrong" });
    //   } else {
    //     this.setState({ showLoader: false });
    //   }
    // });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }
  getListById = (data) => {
    if (data.departmentId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getEmployeeByDeptId({
          departmentId: data.departmentId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  submitHandler = (data) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getAdminConsultancy({ userRegistration: data.userRegistration })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  goToAdminAcademicPanel = () => {
    window.location.replace("/admin-academic-panel");
  };
  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      employeeDetails,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      dynamicMasterData,
      fieldData,
      showLoader,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    console.log(this.props.seminarWebinarList?.seminarWebinar);
    return (
      <>
        {this.state.showLoader && <Loading1 />}
        <DynamicMainScreen
          screenTitle={adminConsultancyJson.screenTitle}
          fieldMeta={adminConsultancyJson.fieldMeta}
          showPdfDownload={adminConsultancyJson.showPdfDownload}
          showExcelDownload={adminConsultancyJson.showExcelDownload}
          pdfFileName={adminConsultancyJson.pdfFileName}
          excelFileName={adminConsultancyJson.excelFileName}
          showAddButton={adminConsultancyJson.showAddButton}
          tableColumnsToFilter={adminConsultancyJson.tableColumnsToFilter}
          dynamicMasterData={dynamicMasterData}
          showSaveNextBtn={false}
          tableColumns={consultancyColumns}
          tableData={this.props.consultancyList?.consultancy}
          getTableData={""}
          getTableDataById={true}
          showLoader={!showLoader}
          apiBaseURL={adminConsultancyJson.apiBaseURL}
          baseIdColumn={adminConsultancyJson.baseIdColumn}
          type={1}
          DocumentUpload={adminConsultancyJson.DocumentUpload}
          onSearchData={this.submitHandler}
          searchList={adminConsultancyJson.searchList}
          showBackToListBtn={true}
          onBackToList={this.goToAdminAcademicPanel}
          getListById={this.getListById}
          addValidation={true}
          dataSetToAdd={adminConsultancyJson.dataSetToAdd}
        />
        {/* <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={adminConsultancyJson.screenTitle}
          showPdfDownload={adminConsultancyJson.showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={adminConsultancyJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={showAddButton}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <DynamicForm
                fieldMeta={adminConsultancyJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showSaveNextBtn={false}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave(true)}
                onSaveAndNext={this.onFormSave(false)}
              />
            </>
          )}

          {showTable && (
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange("fromDate")}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="To Date  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.onDateChange("toDate")}
                        isError={toDateError ? true : false}
                        errorText={toDateError ? toDateError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.submitHandler}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ marginTop: 1 }}>
                <RTTable
                  columns={consultancyColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  showAddButtonInTable={showAddButtonInTable}
                  rowEdit={this.rowEdit}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                  rowAdd={this.rowAdd}
                  // rows={this.props.consultancyList?.consultancy}
                  // tableData={this.props.consultancyList?.consultancy}
                  tableData={this.getFilteredTableData()}
                  rows={this.getFilteredTableData()}
                />
              </Grid>
            </>
          )}
        </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  seminarWebinarList: state.seminarWebinar,
  consultancyList: state.consultancy,
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getConsultancyByDate,
  getConsultancyWithoutFilter,
  getConsultancy,
  getDepartment,
  getEmployeeByDeptId,
  getAdminConsultancy,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminConsultancy);
