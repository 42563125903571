import {
  FormHelperText,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../../components/Loading1";
import SearchBar from "../../../../components/Search";
import { showNotification } from "../../../Landing/Landing.slice";
import {
  getUpdatedStatus,
  getVolunteer,
} from "../AlumniVolunteerStatus/AdminVolunteerStatus.slice";
import AddEngagementDetails from "./AddEngagementDetails";
import {
  getAlumniEngDetailsById,
  getAlumniEngDetailsByIdReset,
} from "./AlumniMentorStatus.slice";
import MentorUpdateStatusTable from "./MentorUpdateStatusTable";

// import UpdateInfo from "./UpdateInfo";

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    title: "Seat No",
    name: "seatNo",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },

  {
    title: "Student Name",
    name: "studentName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Applicable Fees",
    name: "applicableFees",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Paid Fees",
    name: "amount",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
];

const MentorChangeStatus = ({
  onClose,
  getVolunteer,
  getUpdatedStatus,
  volunteerStatusList,
  show,
  declarationId,
  isActiveStatus,
  getAlert,
  showNotification,
  getAlumniEngDetailsById,
  alumniMentorList,
  getAlumniEngDetailsByIdReset,
}) => {
  let navigate = useNavigate();

  const [searchValue, setSearchValue] = React.useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [addEngDetails, setAddEngDetails] = useState(false);
  const [changeSearchBy, setChangeSearchBy] = React.useState("0");
  const [searchBy, setSearchBy] = React.useState([
    { id: "0", name: "Be a Volunteer" },
    { id: "1", name: "Be a Mentor" },
    { id: "2", name: "Be a Alumnae Association Office Bearer" },
    { id: "3", name: "Be a Donor and Sponsor" },
    { id: "4", name: "Be a Paper Setter/ Evaluator/Examiner/Guide" },
    { id: "5", name: "Be a Resource Person" },
    { id: "7", name: "Be a Collaborator" },
    { id: "8", name: "Be a Teacher" },
  ]);

  const [changeStatus, setChangeStatus] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [searchData, setSearchData] = React.useState("");
  const [alumnaeType, setAlumnaeType] = React.useState("0");
  const [formErrors, setFormErrors] = React.useState({});
  const [alumniData, setAlumniData] = React.useState({});
  const [list, setList] = React.useState([]);
  const [status, setStatus] = React.useState([
    { id: "0", name: "Pending" },
    { id: "1", name: "Approved" },
    { id: "2", name: "Rejected" },
  ]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    getVolunteer().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);

  const handledChange = (event, newValue) => {
    setValue(newValue);
    setSearchValue("");
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (changeStatus === "" || changeStatus === null) {
      formIsValid = false;
      formErrors["changesStatusError"] = "*Select Status";
    }

    if (list.length === 0) {
      formIsValid = false;
      formErrors["emptyListError"] = "*Please select atleast one student";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name === "changeSearchBy") {
        setChangeSearchBy(newValue);
        setAlumnaeType(newValue);
      } else {
        setChangeStatus(newValue);
      }
    } else {
      setChangeSearchBy("");

      setChangeStatus("");
    }
  };

  const getRow = (e) => {
    setList(e);
  };

  const submitHandler = () => {
    let newList = list.join();
    if (checkValidation()) {
      if (list.length !== 0) {
        swal({
          title: "Are you sure?",
          text: " Do you want to update status for this student",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((newValue) => {
          if (newValue) {
            getUpdatedStatus({
              applyId: newList,
              status: changeStatus,
              type: changeSearchBy,
              remark: remark === "" || remark === null ? "" : remark,
            }).then((success) => {
              if (success) {
                getVolunteer().then((success) => {
                  if (success) {
                    showNotification({
                      msg: "Status Updated successfully",
                    });
                    setChangeStatus("");
                    setRemark("");
                    setList([]);
                  } else {
                    getAlert({ message: "Something went wrong" });
                  }
                });
              } else {
                setShowLoader(false);
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
    }
  };
  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name == "remark") {
      setRemark(value);
    }
  };

  const onRowAdd = (rowData) => {
    setAddEngDetails(true);
    setAlumniData(rowData);
  };

  const onClickViewDetails = (rowData) => {
    setViewData(true);
    console.log(rowData);
    setAlumniData(rowData);
    setShowLoader(true);
    getAlumniEngDetailsById({ id: rowData.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setAddEngDetails(true);
      }
    });
  };

  const hideAddEngDetails = () => {
    setAddEngDetails(false);
    getAlumniEngDetailsByIdReset();
    getVolunteer().then((success) => {
      if (!success) {
        getAlert({ message: "Something went wrong" });
      }
    });
  };
  const onSearch = (searchValue) => {
    setSearchData(searchValue);
  };
  const getFilteredTableData = (status, searchType) => {
    const aluminiList = volunteerStatusList?.volunteerStatusUpdate.filter(
      (data) => data.status == status && data.type == searchType
    );
    return aluminiList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["alumniName"] &&
          currentRow["alumniName"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["message"] &&
          currentRow["message"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["expertise"] &&
          currentRow["expertise"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["remark"] &&
          currentRow["remark"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["time"] &&
          currentRow["time"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["interest"] &&
          currentRow["interest"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  return (
    <>
      {showLoader && <Loading1 />}
      {!addEngDetails ? (
        <>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <TitleAndBreadCrum title="Admin Engagement Panel" />
          </Paper>
          <br />

          <div>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Type"
                    />
                  </Grid>
                  <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={changeSearchBy}
                      name={"changeSearchBy"}
                      fullWidth
                      options={searchBy}
                      onChange={ChangeHandlerSearch}
                      isError={formErrors.changeSearchByError ? true : false}
                      errorText={
                        formErrors.changeSearchByError
                          ? formErrors.changeSearchByError
                          : " "
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={3}
                  xs={12}
                  md={3}
                  lg={3}
                  container
                  justifyContent="flex-end"
                  sx={{ mr: 5 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=""
                    />
                  </Grid>
                  <Grid sx={{ mt: -3 }} item xs={12} sm={12} md={12}>
                    <SearchBar
                      sx={{ width: "100%" }}
                      searchValue={searchData}
                      filteredRows={onSearch}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handledChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      "Pending " +
                      volunteerStatusList?.volunteerStatusUpdate.filter(
                        (data) =>
                          data.status == 0 && data.type == changeSearchBy
                      ).length
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      "Approved " +
                      volunteerStatusList?.volunteerStatusUpdate.filter(
                        (data) =>
                          data.status == 1 && data.type == changeSearchBy
                      ).length
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      "Rejected " +
                      volunteerStatusList?.volunteerStatusUpdate.filter(
                        (data) =>
                          data.status == 2 && data.type == changeSearchBy
                      ).length
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <>
                  <MentorUpdateStatusTable
                    rows={getFilteredTableData(0, changeSearchBy)}
                    onSelectionChange={getRow}
                    selection={list}
                    alumnaeType={alumnaeType}
                    isActionColActive={false}
                    status={value}
                  />
                  <FormHelperText error>
                    {formErrors.emptyListError
                      ? formErrors.emptyListError
                      : " "}
                  </FormHelperText>
                </>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <>
                  <MentorUpdateStatusTable
                    rows={getFilteredTableData(1, changeSearchBy)}
                    onSelectionChange={getRow}
                    selection={list}
                    showAdd={true}
                    rowAdd={onRowAdd}
                    showView={true}
                    rowViewData={onClickViewDetails}
                    alumnaeType={alumnaeType}
                    isActionColActive={true}
                    status={value}
                  />
                  <FormHelperText error>
                    {formErrors.emptyListError
                      ? formErrors.emptyListError
                      : " "}
                  </FormHelperText>
                </>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <>
                  <MentorUpdateStatusTable
                    rows={getFilteredTableData(2, changeSearchBy)}
                    onSelectionChange={getRow}
                    selection={list}
                    alumnaeType={alumnaeType}
                    isActionColActive={false}
                    status={value}
                  />
                  <FormHelperText error>
                    {formErrors.emptyListError
                      ? formErrors.emptyListError
                      : " "}
                  </FormHelperText>
                </>
              </TabPanel>
              <Grid item sm={12} xs={12} md={12} lg={12} container>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Update Status"
                    />
                  </Grid>
                  <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={changeStatus}
                      name={"changeStatus"}
                      fullWidth
                      options={status.filter(
                        (filterData) => filterData.id != value
                      )}
                      onChange={ChangeHandlerSearch}
                      isError={formErrors.changesStatusError ? true : false}
                      errorText={
                        formErrors.changesStatusError
                          ? formErrors.changesStatusError
                          : " "
                      }
                    />
                  </Grid>
                </Grid>
                {changeStatus == 2 && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid sx={{ marginLeft: 1 }} item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Remark"
                      />
                    </Grid>
                    <Grid
                      sx={{ marginLeft: 1, marginTop: -3 }}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <TextField
                        color="primary"
                        name="remark"
                        size="small"
                        value={remark}
                        onChange={changedHandler}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  sx={{ marginTop: -3 }}
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=""
                    />
                  </Grid>
                  <Grid sx={{ marginLeft: 1 }} item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Submit"
                      onClick={submitHandler}
                    />
                  </Grid>
                </Grid>
                {changeStatus == 2 ? (
                  <Grid item xs={12} sm={3} md={3} />
                ) : (
                  <Grid item xs={12} sm={7} md={7} />
                )}
              </Grid>
              {/* )} */}
            </Paper>
          </div>
        </>
      ) : (
        <AddEngagementDetails
          alumniData={alumniData}
          onCancelForm={hideAddEngDetails}
          alumnaeType={alumnaeType}
          backToForm={hideAddEngDetails}
          viewData={viewData}
          engDetailsData={alumniMentorList.engDetailsData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  volunteerStatusList: state.volunteerStatus,
  alumniMentorList: state.alumniMentorStatus,
});

const mapDispatchToProps = {
  getAlert,
  getVolunteer,
  getUpdatedStatus,
  showNotification,
  getAlumniEngDetailsById,
  getAlumniEngDetailsByIdReset,
  // getStudentStatusList,
  // getSchemeHead,
  // getSubjectFromId,
  // getStudentAppliedSubject,
};
export default connect(mapStateToProps, mapDispatchToProps)(MentorChangeStatus);
