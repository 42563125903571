import { Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import AttachFile from "../../../../../components/Comman/AttachFile";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../../../../components/Comman/IconButton";
import { LabelCompo } from "../../../../../components/Comman/Label";
import MultipleSelect from "../../../../../components/Comman/MultipleSelect";
import endpoint from "../../../../../config/endpoints";
import * as myConstClass from "../../../../../config/messageconstant";
import { showNotification } from "../../../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../../../utils/api_service";
import { getDepartment } from "../../../../Masters/Internship/Department/department.slice";
import { getNotice, resetValues, getNoticeList } from "./notice.slice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { openFileInNewTab } from "../../../../UploadFile/file.slice";

let docs;
class AddNotice extends Component {
  state = {
    id: 0,
    title: "",
    fileName: "",
    file: "",
    description: "",
    selectAllRole: [],
    department: [],
    role: [],
    fromDate: "",
    toDate: "",
    highlightLabel: 0,
    label: "",
    departmentList: [],
    formErrors: [],
    roleDataList: [
      { id: "all", name: "All" },
      { id: "Teacher", name: "Teacher" },
      { id: "Hod", name: "Hod" },
      { id: "Department Coordinator", name: "Department Co-ordinator" },
      { id: "Committee Incharge", name: "Committee Incharge" },
      { id: "Class Co-ordinator", name: "Class Co-ordinator" }
    ],
  };

  multiSelectHandler = (value, name) => {
    if (name == "department") {
      let deptDataList = this.props.departmentList.department.map((data) => {
        return data.id;
      });
      if (value == "all") {
        this.setState({
          [name]: deptDataList,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: typeof value === "string" ? value.split(",") : value,
        });
      }
    } else {
      if (value == "all") {
        let rolelist = this.state.roleDataList.map((data) => {
          return data.id != "all" && data.id;
        });

        this.setState({
          [name]: rolelist,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: typeof value === "string" ? value.split(",") : value,
        });
      }
    }
  };

  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");
    if (filterValue.length !== 0) {
      if (name == "role") {
        let rolelist = [];
        this.state.roleDataList.map((data) => {
          if (data.id != "all") {
            rolelist.push(data.id);
          }
        });

        this.setState({
          [name]: rolelist,
        });
      } else {
        let departmentlist = [];
        this.state.departmentList.map((data) => {
          if (data.id != "all") {
            departmentlist.push(data.id);
          }
        });

        this.setState({
          [name]: departmentlist,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    }
  };
  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  onClickViewPreview = (fileName) => {
    this.props.openFileInNewTab({ file: fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  toTimeChangeHandler = (time) => {
    this.setState({
      time: time,
    });
  };

  handleFormValidation() {
    const {
      title,
      description,
      fromDate,
      toDate,
      department,
      role,
      venue,
      time,
      applicationLastDate,
      highlightLabel,
      label,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    // if ( title == "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "titleError" ] = myConstClass.titleMsg;
    // }

    if (title.toString().trim() == "" || title == null) {
      formIsValid = false;
      formErrors["titleError"] = myConstClass.titleMsg;
    }

    // if ( description === "" )
    // {
    //   formIsValid = false;
    //   formErrors[ "descriptionError" ] = myConstClass.descriptionMsg;
    // }

    if (!department.length) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    if (!role.length) {
      formIsValid = false;
      formErrors["roleError"] = myConstClass.roleMsg;
    }

    if (fromDate === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    if (venue === "") {
      formIsValid = false;
      formErrors["venueError"] = myConstClass.venueMsg;
    }

    if (time === "" || time === null) {
      formIsValid = false;
      formErrors["timeError"] = myConstClass.timeMsg;
    }
    if (applicationLastDate === "") {
      formIsValid = false;
      formErrors["applicationLastDateError"] =
        myConstClass.applicationLastDateMsg;
    }

    if (highlightLabel == 1 && label === "") {
      formIsValid = false;
      formErrors["labelError"] = myConstClass.labelMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        let departmentData = [];
        departmentData = [
          { id: "all", name: "All" },
          ...nextProps.departmentList.department.filter(
            (data) => data.isActive == 1
          ),
        ];

        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  componentDidMount() {
    const { eventDataForEmpNotice, getAlert } = this.props;

    this.props.getDepartment().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    const { editData } = this.props;

    if (editData != undefined || editData != null) {
      if (Object.keys(editData).length !== 0) {
        const fromDateARR =
          editData.fromDate == null ? "" : editData.fromDate.split("-");
        const fromDt =
          fromDateARR == ""
            ? ""
            : new Date(fromDateARR[2], fromDateARR[1] - 1, fromDateARR[0]);
        const toDateARR =
          editData.toDate == null ? "" : editData.toDate.split("-");
        const toDt =
          toDateARR == ""
            ? ""
            : new Date(toDateARR[2], toDateARR[1] - 1, toDateARR[0]);

        this.setState({
          ...this.state,
          id: editData.id,
          title: editData.title,
          description: editData.description,
          role: editData.noticeFor.split(","),
          fromDate: fromDt,
          toDate: toDt,
          highlightLabel: editData.highlightLabel,
          label: editData.labelText,
          department: editData.departmentIds.split(","),
          file: editData.documentUpload != null ? editData.documentUpload : "",
        });
      }
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { eventDataForEmpNotice } = this.props;

    const {
      title,
      description,
      fromDate,
      toDate,
      department,
      role,
      venue,
      time,
      applicationLastDate,
      highlightLabel,
      label,
      id,
    } = this.state;

    let frmDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    let applidate = moment(applicationLastDate).format("DD-MM-YYYY");

    // if ( this.state.highlightLabel == 0 )
    // {
    //   this.setState( {
    //     label: "",
    //   } )
    // }

    if (eventDataForEmpNotice != undefined) {
      docs = {
        eventId: eventDataForEmpNotice.id,
        title: title,
        description: description,
        noticeFor: role.join(),
        departmentIds: department.join(),
        fromDate: frmDt,
        toDate: toDt,
        highlightLabel: highlightLabel,
        type: 1,
        labelText: highlightLabel == 0 ? "" : label,
        isActive: 1,
      };
    } else {
      docs = {
        title: title,
        description: description,
        noticeFor: role.join(),
        departmentIds: department.join(),
        fromDate: frmDt,
        toDate: toDt,
        highlightLabel: highlightLabel,
        type: 0,
        labelText: highlightLabel == 0 ? "" : label,
        isActive: 1,
      };

      if (id !== 0) {
        docs = {
          ...docs,
          id: id,
        };
      }
    }
    if (this.handleFormValidation()) {
      swal({
        title: "Do you want to submit the information?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((response) => {
        if (response) {
          this.onSave(docs);
          this.props.onCancel();
        }
      });
    }
  };

  onSave = (dataToSave) => {
    if (this.state.fileName !== "") {
      const formData = new FormData();
      formData.append("file", this.state.fileName);
      apiPost({
        url: endpoint.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          dataToSave = {
            ...dataToSave,
            documentUpload: data,
          };
          apiPost({
            url: endpoint.employeeNotice,
            postBody: dataToSave,
          }).then(({ success }) => {
            if (success) {
              this.props.showNotification({
                msg: "Data saved successfully",
              });
              this.props.getNoticeList().then((response) => {
                if (!response) {
                  this.setState({
                    showLoader: false,
                  });
                  this.props.getAlert({ message: "Something went wrong" });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
            } else {
              this.props.getAlert({
                message: "Failed to save",
              });
            }
          });
        } else {
          this.props.getAlert({
            message: "Failed to upload document",
          });
        }
      });
    } else {
      if (this.state.file !== "") {
        dataToSave = {
          ...dataToSave,
          documentUpload: this.state.file,
        };
      }
      apiPost({
        url: endpoint.employeeNotice,
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.props.showNotification({
            msg: "Data saved successfully",
          });
          this.props.getNoticeList().then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        } else {
          this.props.getAlert({
            message: "Failed to save",
          });
        }
      });
    }
  };

  render() {
    const {
      title,
      description,
      fromDate,
      toDate,
      department,
      role,
      highlightLabel,
      label,
      fileName,
    } = this.state;
    const { onCancel } = this.props;
    const {
      titleError,
      departmentError,
      roleError,
      fromDateError,
      toDateError,
      labelError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={10.5} md={10.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Notice Form (For Staff)"
                  />
                </Grid>
                <Grid sx={{ mb: 2 }} item xs={12} sm={1.5} md={1.5}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Title"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="title"
                      size="small"
                      value={title}
                      onChange={this.changeHandler}
                      error={titleError ? true : false}
                      helperText={titleError ? titleError : " "}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Description"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="description"
                      size="small"
                      multiline
                      value={description}
                      onChange={this.changeHandler}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                sx={{ marginTop: 1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Department"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSelect
                      er={departmentError}
                      name1="department"
                      value1={department}
                      names={this.state.departmentList}
                      onChange={this.multiSelectHandler}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Role"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSelect
                      er={roleError}
                      name1="role"
                      value1={role}
                      names={this.state.roleDataList}
                      onChange={this.multiSelectHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*From Date "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="fromDate"
                      size="small"
                      value={fromDate}
                      fullWidth
                      onChange={this.onDateChange("fromDate")}
                      isError={fromDateError ? true : false}
                      errorText={fromDateError ? fromDateError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*To Date "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="toDate"
                      size="small"
                      value={toDate}
                      fullWidth
                      onChange={this.onDateChange("toDate")}
                      minDate={fromDate}
                      isError={toDateError ? true : false}
                      errorText={toDateError ? toDateError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2}
                  lg={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Document Name "
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {this.state.file !== "" && (
                      <MyComponentWithIconProps
                        statusImage={VisibilityIcon}
                        color="primary"
                        fontSize="medium"
                        title="View"
                        onClick={(e) =>
                          this.onClickViewPreview(this.state.file)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={9}
                  lg={9}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <AttachFile name="documentName" fileName={this.changeH} />
                    &nbsp; {fileName !== "" ? fileName.name : ""}
                  </Grid>
                </Grid>
              </Grid>

              {highlightLabel == 0 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Highlight Label"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="highlightLabel"
                          id="highlightLabel"
                          name="highlightLabel"
                          value={highlightLabel}
                          onChange={this.changeHandler}
                          size="small"
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              ) : null}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {highlightLabel == 1 ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Highlight Label"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="highlightLabel"
                            id="highlightLabel"
                            name="highlightLabel"
                            value={highlightLabel}
                            onChange={this.changeHandler}
                            size="small"
                          >
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={0}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Label"
                        />
                      </Grid>

                      <Grid sx={{ mb: 1 }} item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="label"
                          size="small"
                          type="text"
                          value={label}
                          onChange={this.changeHandler}
                          fullWidth
                          {...(labelError
                            ? { error: true, helperText: labelError }
                            : "")}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Grid>

              <Grid container columnSpacing={1} justifyContent="right">
                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  departmentList: state.department,
  fileData: state.file,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getNotice,
  getDepartment,
  resetValues,
  getNoticeList,
  openFileInNewTab,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNotice);

