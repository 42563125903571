import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  suggestions: [],
  isFetch: false,

  viewSuggestions: [],
  isFetchViewSuggestions: false,
};
let URL = endpoints.grievance;

const suggestionsSlice = createSlice({
  name: "suggestions",
  initialState,
  reducers: {
    suggestionsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        suggestions: row,
        isFetch: true,
      };
    },

    viewSuggestionsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        viewSuggestions: row,
        isFetchViewSuggestions: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { suggestionsSuccess, viewSuggestionsSuccess, resetSuccess } =
  suggestionsSlice.actions;

export default suggestionsSlice.reducer;

export const getSuggestions = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"type":"' + "0" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            complaintDetail: data1.complaintDetail,
            date: data1.date,
            id: data1.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(suggestionsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// http://localhost:8088/api/acdmc/v1/grievance/by-from-to-date-department-type?fromDate=2022-06-24&toDate=2022-06-26&departmentId=cf5c0f2b-4e17-440d-b734-6fb6438bcbe2&type=0

export const getSuggestionsByDateAndDepartment =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-grievance-by-from-to-date-type-for-admin?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&type=" +
          0,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              complaintDetail: data1.complaintDetail,
              lastName: data1.userRegistration.lastName,
              middleName: data1.userRegistration.middleName,
              firstName: data1.userRegistration.firstName,
              fatherName: data1.userRegistration.fatherName,
              motherName: data1.userRegistration.motherName,
              name:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                (data1.userRegistration.fatherName == null ||
                data1.userRegistration.fatherName == ""
                  ? " - "
                  : data1.userRegistration.fatherName) +
                " " +
                (data1.userRegistration.motherName == null ||
                data1.userRegistration.motherName == ""
                  ? " - "
                  : data1.userRegistration.motherName),
              date: data1.date,
              id: data1.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(viewSuggestionsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSuggestionsListWithoutFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL +
        "/get-grievance-by-type-and-limit-for-admin" +
        '?filters={ "type": "0", "_mode": "EXACT" } &offset=0&limit=20&sort = { "insertdatetime": "DESC" }',

      // ?filters={ "type": "", "_mode": "EXACT" } &offset=0&limit=20&sort = { "insertdatetime": "DESC" }
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data.content;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            complaintDetail: data1.complaintDetail,
            lastName: data1.userRegistration.lastName,
            middleName: data1.userRegistration.middleName,
            firstName: data1.userRegistration.firstName,
            fatherName: data1.userRegistration.fatherName,
            motherName: data1.userRegistration.motherName,
            name:
              data1.userRegistration.lastName +
              " " +
              data1.userRegistration.firstName +
              " " +
              (data1.userRegistration.fatherName == null ||
              data1.userRegistration.fatherName == ""
                ? " - "
                : data1.userRegistration.fatherName) +
              " " +
              (data1.userRegistration.motherName == null ||
              data1.userRegistration.motherName == ""
                ? " - "
                : data1.userRegistration.motherName),
            date: data1.date,
            id: data1.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(viewSuggestionsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSuggestionsByDate =
  ({ fromDate, toDate, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-grievance-by-from-to-date-type?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&type=" +
          0 +
          "&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              complaintDetail: data1.complaintDetail,
              date: data1.date,
              id: data1.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(suggestionsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSuggestionsWithoutFilter =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-grievance-by-type-and-limit?type=0&limit=20&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              complaintDetail: data1.complaintDetail,
              date: data1.date,
              id: data1.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(suggestionsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
