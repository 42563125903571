import { FormHelperText, Grid, TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { withStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAlert } from "../../CommonActions/alert.slice";
import { encodeData } from "../../components/Comman/Util/Base64";
//const backgroundImage = require("../../Images/bg.jpeg");
//const bmn = require("../../Images/logo.png");
import Header from "../../components/NewHeader";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import "../../CSS/NewLanding.css";
import { apiGet } from "../../utils/api_service";
import { getAlumniRegistration } from "../Alumini/StudentFillProfile/studentFillProfile.slice";
import { getProfile } from "../BasicDetailsPost/basicDetails.slice";
import { getEmpProfile } from "../EmployeeDetails/employee.slice";
import { getAccessRoleByUserId } from "../UserAccess/accessRole.slice";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { login } from "./login.slice";
import { Loading1 } from "../../components/Loading1";
const categories = ["all", "recipe", "video", "article"];
const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  top: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  bottom: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  ellipse: {
    // position: "static",
    // width: "517px",
    // height: "350px",
    // left: "10px",
    // top: "10px",

    // background: "rgba(41, 151, 171, 0.2)",
    // flex: "none",
    // order: "0",
    // flexgrow: "0",
    justifyContent: "center",
  },
  loginBox: {
    display: "flex",
    border: `1px solid ${theme.palette.grey[400]}`,
    margin: "50px",
    width: "auto",
    zIndex: "1",
    backgroundColor: "white",
  },
  text: {
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "bold",
    fontsize: "32px",
    textAlign: "center",

    color: "#FFFFFF",
  },
  bmn: {
    padding: "30px",
    justifyContent: "center",

    // alignSelf:'center',
  },
  left: {
    //  width: "50%",
  },
  hello: {
    textAlign: "center",
    top: "60px",
    left: "50px",
  },
  helper: {
    textAlign: "center",
    height: "56px",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "18px",
    lineheight: "28px",
    color: "#666666",
  },
  field: {
    padding: "10px 50px",
    margin: "50px",
  },
  stay: {
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "14px",
    lineheight: "28px",
    paddingLeft: "20px",

    color: "#666666",
  },
  ellipse2: {
    width: "504px",
    height: "504px",
    left: "-139px",
    top: "500px",

    background: "#FFFFFF",
    border: "80px solid #2997AB",
    boxsizing: "border-box",
  },
  fogot: {
    left: "500px",
  },
  body: { backgroundColor: "#f5f5f5" },
});

const initState = { name: "", password: "" };

const Login = ({
  login,
  getEmpProfile,
  getAlumniRegistration,
  getProfile,
  getAccessRoleByUserId,
  common,
  getAlert,
}) => {
  const [auth, setAuth] = useState(initState);
  const navigate = useNavigate();
  const [loginErr, setloginErr] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);	
  useEffect(() => {}, []);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const Login = () =>
  {
    setLoader( true );
    if (auth.name !== "" && auth.password !== "") {
      const name = `${auth.name}:${auth.password}`;

      const userName = encodeData(name);
      console.log(userName);
      login({ name: userName }).then(({ userRole, success }) => {
        if (success) {
          apiGet({
            url: '/api/acdmc/v1/name-config?sort={"sortNo": "ASC"}',
          }).then(({ data, success }) => {
            console.log(data.data.content);
            if ( success )
            {
              setLoader( false);
              if (data !== null) {
                localStorage.setItem(
                  "nameFormatData",
                  JSON.stringify(data.data.content)
                );
              }
            } else
            {
              setLoader( false);
              getAlert({ message: "Something went wrong" });
            }
          });
        }
        if (success && userRole !== "alumni" && userRole !== "parent") {
          apiGet({
            url: endpoints.filterTable,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({
                message: myConstClass.serverMsg,
              } );
              setLoader( false);
            } else {
              if (data.data !== null) {
                localStorage.setItem(
                  "semisterDetailId",
                  data.data.filterObject.semisterDetailId
                );
                localStorage.setItem(
                  "yearDetailId",
                  data.data.filterObject.yearDetailId
                );
                localStorage.setItem(
                  "divisionIds",
                  data.data.filterObject.divisionIds
                );
                localStorage.setItem(
                  "divisionId",
                  data.data.filterObject.divisionId
                );
                localStorage.setItem(
                  "departmentId",
                  data.data.filterObject.departmentId
                );
              }
            }
          });

          if (userRole === "student") {
            getProfile().then((resp) => {
              if (!resp) {
                getAlert({
                  message: "Something went wrong",
                });
              } else {
                getAccessRoleByUserId().then((resp) => {
                  if (resp) {
                    window.location.replace("/newDashboard");
                    // navigate("/dashbourd");
                  } else {
                    if (!resp) {
                      getAlert({
                        message: "Something went wrong",
                      } );
                      setLoader( false);
                    }
                  }
                });
              }
            });
          } else {
            getEmpProfile().then((resp) => {
              if (!resp) {
                getAlert({
                  message: "Something went wrong",
                } );
                setLoader( false);
              } else {
                getAccessRoleByUserId().then((resp) => {
                  if (resp) {
                    window.location.replace("/teacherDashboard");
                  } else {
                    if (!resp) {
                      getAlert({
                        message: "Something went wrong",
                      });
                    }
                  }
                });
              }
            });
          }
        } else if (success && userRole === "alumni") {
          getAlumniRegistration().then((resp) => {
            if (!resp) {
              getAlert({
                message: "Something went wrong",
              });
            } else {
              getAccessRoleByUserId().then((resp) => {
                if (resp) {
                  const aluminiStatus = localStorage.getItem("aluminiStatus");
                  if (aluminiStatus != 2) {
                    window.location.replace("/alumini-dashboard");
                  } else {
                    window.location.replace("/alumini-block");
                  }
                } else {
                  if (!resp) {
                    getAlert({
                      message: "Something went wrong",
                    });
                  }
                  setLoader( false);
                }
              });
            }
          });
        } else if (success && userRole === "parent") {
          getProfile().then((resp) => {
            if (!resp) {
              getAlert({
                message: "Something went wrong",
              });
            } else {
              getAccessRoleByUserId().then((resp) => {
                if (resp) {
                  window.location.replace("/parent-dashboard");
                } else {
                  if (!resp) {
                    getAlert({
                      message: "Something went wrong",
                    });
                  }
                }
              });
            }
          });
        } else {
          setloginErr("Invalid credentials");
        }
      });
    } else {
      if (auth.name === "" && auth.password === "") {
        setNameErr("Username required");
        setPasswordErr("Password required");
      } else if (auth.name === "") {
        setNameErr("Username required");
      } else {
        setPasswordErr("Password required");
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setAuth({
      ...auth,
      [name]: value,
    });
  };

  const onApiCalled = (e) => {
    //href="forgot-password-pre-login"
  };

  return (
    <>
      <div align="center">
        {/* <Header/> */}
        <Header />
        {/* <Navbar/> */}
        <div className="new_content login">
          <div className="dash_login">
            <Grid item container justifyContent="space-between">
              <Grid item md={4}></Grid>
              <Grid item md={4}>
                <div className="lgn_bx">
                  <h3 className="lgn_title">Login Form</h3>

                  <div className="lgn_container">
                    <div className="user_lgn_new">
                      <div className="user_label">Username</div>
                      <div className="user_field">
                        <TextField
                          color="primary"
                          name="name"
                          size="small"
                          value={auth.name}
                          onChange={onChange}
                          onKeyPress={(e) => e.key === "Enter" && Login()}
                          fullWidth
                          helperText={nameErr ? nameErr : " "}
                          error={nameErr ? true : false}
                        />
                      </div>
                    </div>

                    <div className="user_lgn_new">
                      <div className="user_label">Password</div>
                      <div className="user_field">
                        <OutlinedInput
                          color="primary"
                          name="password"
                          size="small"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          value={auth.password}
                          onChange={onChange}
                          fullWidth
                          onKeyPress={(e) => e.key === "Enter" && Login()}
                          helperText={passwordErr ? passwordErr : " "}
                          error={passwordErr ? true : false}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: 20 }} className="lgn_btn">
                      <button
                        type="button"
                        onClick={Login}
                        onKeyPress={(e) => e.key === "Enter" && Login()}
                        className="register_btn"
                      >
                        Login
                      </button>
                      <FormHelperText error>
                        {loginErr !== "" ? loginErr : " "}
                      </FormHelperText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="user_label" onClick={onApiCalled}>
                        <a href="forgot-username-prn">Forgot Username</a>
                      </div>
                      <div className="user_label" onClick={onApiCalled}>
                        <a href="forgot-password-prn">Forgot Password</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>


              <Grid item md={4}></Grid>
            </Grid>


            <Grid item md={2}>
            {loader && <Loading1 size={50} />}

            </Grid>
         
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state,
  common: state.common,
});

const mapDispatchToProps = {
  login,
  getAccessRoleByUserId,
  getAlert,
  getEmpProfile,
  getAlumniRegistration,
  getProfile,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
