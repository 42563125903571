import endpoint from "../config/endpoints";

export const seminarWebinarJson = {
    apiBaseURL: endpoint.resourcePerson,
    "screenTitle": "Seminar Webinar Activity",
    "showAddButton": true,
    baseIdColumn: "id",
    "fieldMeta": [
        {
            "label": "Activity",
            "controlType": "autocomplete",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "masterName": "activity",
            "dataKey": "employeeActivity",
            "isRootLevelKey": false,
            "isMandatory": true
        },
        {
            label: "Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "pressDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            "isMandatory": true
        },
        {
            label: "Details",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "publication",
            isMandatory: true
        },
        {
            label: "Number of beneficiaries",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "numberOfBeneficiaries",
            isMandatory: true
        },

    ]
}