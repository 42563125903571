import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../../../components/Comman/RT/MaterialUIRTTable";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../../components/Loading1";
import endpoint from "../../../../config/endpoints";
import { showNotification } from "../../../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../../utils/api_service";
import AdminAddNews from "./AdminAddNews";
import { getNewRoomsList } from "./adminNews.slice";
import AdminNewsView from "./AdminNewsView";
import AdminNewsRoomTable from "./AdminTableLatest";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminAddNewsTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      showLoader: false,
      editData: {},
      showView: false,
    };
  }

  OnClickEdit = (data) => {
    this.setState({
      showAddCommitteeForm: false,
      showTable: false,
      showForm: true,
      editData: data,
    });
  };

  rowViewData = (data) => {
    console.log(data);
    this.setState({
      showAddCommitteeForm: false,
      showTable: false,
      showView: true,
      showForm: false,
      editData: data,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "title",
      title: "Title",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 200,
    },
    {
      name: "description",
      title: "Description",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 200,
    },
    {
      name: "date",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 110,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: 170,
    },
  ];

  toggleFormTableVisibility = () => {
    this.setState({
      showForm: true,
      showAddCommitteeForm: false,
      showTable: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: false,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: false,
      openSnackbar: true,
    });
  };

  backToForm = () => {
    const { getNewRoomsList } = this.props;
    getNewRoomsList().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.setState({
      showForm: false,
      showTable: true,
      showView: false,
      openSnackbar: true,
      editData: {},
    });
  };

  backTo = () => {
    this.props.getNewRoomsList().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.setState({
      showTable: true,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  componentDidMount() {
    this.props.getNewRoomsList().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.newsRoom + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getNewRoomsList().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.newsRoomList?.adminNewsList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["title"] &&
          currentRow["title"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["description"] &&
          currentRow["description"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["date"] &&
          currentRow["date"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["toDate"] &&
          currentRow["toDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["time"] &&
          currentRow["time"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  render() {
    const { showTable, showForm, showView } = this.state;

    return (
      <>
        {this.state.showLoader && <Loading1 />}

        {showForm && (
          <>
            <AdminAddNews
              onCancel={this.backToForm}
              editData={this.state.editData}
              // fileSaveData={this.fileSaveData}
            />
          </>
        )}

        {showView && (
          <>
            <AdminNewsView
              onCancel={this.backToForm}
              editData={this.state.editData}
              onClickView={this.rowViewData}
            />
          </>
        )}

        {showTable && (
          <>
            <LandingScreenHeader
              tableColumnsToFilter={[]}
              screenTitle={"Admin News Room"}
              showPdfDownload={false}
              showExcelDownload={false}
              showSearchBox={true}
              showAddButton={true}
              onAddButtonClick={this.onAddButtonClick}
              onSearch={this.onSearch}
              showTable={showTable}
            />
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid item sx={{ mt: 2 }}>
                <AdminNewsRoomTable
                  rows={this.getFilteredTableData()}
                  onClickEdit={this.OnClickEdit}
                  onClickDelete={this.rowDelete}
                  onClickViewPreview={this.rowViewData}
                />
              </Grid>
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  newsRoomList: state.newsRoom,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getNewRoomsList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAddNewsTableView);
