import { numberMsg } from "../config/messageconstant";

export const GovPayJson = {
  screenTitle: "Government Fees",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getAcadamicYear: true,
  getListId: "yearDetail",
  fieldMeta: [
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "yearDetailMaster",
      dataKey: "yearDetail",
      isMandatory: true,
    },
    {
      label: "From Fees",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "FromFeeslMaster",
      dataKey: "fromFees",
      isMandatory: true,
    },
    // {
    //   label: "Payment",
    //   controlType: "textfield",
    //   placeHolder: "",
    //   inputType:'number',
    //   md: 3.6,
    //   lg: 3.6,
    //   sm: 3.6,
    //   xs: 12,
    //   dataKey: "pay",
    //   isMandatory: true
    // },
  ],
};
