//import { getClass } from "../Masters/OnlineModule/Class/class.slice";
//import { getProgramType } from "../Masters/OnlineModule/ProgramType/semisterDetails.slice";
import { Grid } from "@mui/material";
import { FilterSave } from "../../../components/FilterSave";

import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
//import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
//import {  } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import SearchRollList from "../../../components/SearchTable";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { rollListJson } from "../../../DynamicFormsJson/RollListTable";
import { apiDelete } from "../../../utils/api_service";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getSemesterDetailsByYearAndAcademic,
  getStudentList,
} from "../ViewOfRollList/rollno.slice";
import RollNoPopUp from "./RollNoPopUp";

class RollList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      division: "",
      yearDetail: "",
      semisterDetails: "",
      showLoader: false,
      popUpValue: false,
      open: false,
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      },
      studentData: {},
      searchValue: "",
    };
  }

  rowEdit = (params) => {
    this.setState({
      popUpValue: true,
      open: true,
      studentData: params,
    });
  };

  onClose = () => {
    this.setState({
      popUpValue: false,
      open: false,
      studentData: {},
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      width: "15%",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    {
      name: "prnNo",
      title: "PRN Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: "10%",
    },
    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: "10%",
    },
    {
      name: "dateOfReporting",
      title: "Reporting Date",
      positionCenter: true,
      showInscreen: true,
      canSearch: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    // {
    //   name: "action",
    //   title: "Action",
    //   getCellValue: (params) => {
    //     const onClickEdit = (e) => {
    //       e.stopPropagation();
    //       this.rowEdit(params);
    //     };
    //     return (
    //       <div>
    //         <MyComponentWithIconProps
    //           statusImage={EditIcon}
    //           color="black"
    //           fontSize="medium"
    //           title="Edit"
    //           onClick={onClickEdit}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  handleClickOpen() {
    this.setState({
      open: false,
    });
  }

  componentDidMount() {
    const { resetReducList, getYearDetails } = this.props;

    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const divisionId = localStorage.getItem("divisionId");

    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (yearDetailId && divisionId && semisterDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getStudentList({
          divisionId: divisionId,
          yearId: yearDetailId,
          semisterID: semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    resetReducList();
    getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
  }

  save = () => {
    const { division, yearDetail, semisterDetails } = this.state;
    const { getAlert, getStudentList } = this.props;
    this.setState({
      showLoader: true,
    });
    getStudentList({
      divisionId: division,
      yearId: yearDetail,
      semisterID: semisterDetails,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onAddButtonClick = () => {
    window.location.replace("/genarateRollNo");
  };

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoints.studentRollList + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            const { division, yearDetail, semisterDetails } = this.state;
            this.setState({
              showLoader: true,
            });
            this.props
              .getStudentList({
                divisionId: division,
                yearId: yearDetail,
                semisterID: semisterDetails,
              })
              .then((response) => {
                if (!response) {
                  this.setState({
                    showLoader: false,
                  });
                  this.props.getAlert({ message: "Something went wrong" });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  onSearchData = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", data.division);
    localStorage.setItem("divisionIds", "");
    FilterSave();

    this.setState({
      division: data.division,
      yearDetail: data.yearDetail,
      semisterDetails: data.semister,
    });
    this.setState({
      showLoader: true,
    });
    this.props
      .getStudentList({
        divisionId: data.division,
        yearId: data.yearDetail,
        semisterID: data.semister,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  render() {
    const {
      yearDetail,
      semisterDetails,
      popUpValue,
      open,
      showLoader,
      dynamicMasterData,
    } = this.state;
    const { rollNoData } = this.props;
    return (
      <>
        <DynamicMainScreen
          onAddButtonClick={this.onAddButtonClick}
          screenTitle={rollListJson.screenTitle}
          fieldMeta={rollListJson.fieldMeta}
          buttonCenter={rollListJson.buttonCenter}
          showPdfDownload={rollListJson.showPdfDownload}
          showExcelDownload={rollListJson.showExcelDownload}
          pdfFileName={rollListJson.pdfFileName}
          excelFileName={rollListJson.excelFileName}
          tableColumnsToFilter={rollListJson.tableColumnsToFilter}
          showAddButton={rollListJson.showAddButton}
          dynamicMasterData={dynamicMasterData}
          tableColumns={this.columns}
          tableData={rollNoData?.studentList}
          getListById={this.getListById}
          showPegination={false}
          showHeadEdit={true}
          showHeadDelete={true}
          showLoader={showLoader}
          // showPegination={false}
          pdfDetailsId={this.pdfDetails}
          rowViewData={this.onClickView}
          rowDelete={this.rowDelete}
          rowEdit={this.rowEdit}
          onDelete={this.onDelete}
          onSearchData={this.onSearchData}
          baseIdColumn={rollListJson.baseIdColumn}
          apiBaseURL={rollListJson.apiBaseURL}
        />

        {popUpValue && (
          <RollNoPopUp
            open={open}
            submitHandler={this.save}
            semisterDetail={semisterDetails}
            yearDetail={yearDetail}
            studentData={this.state.studentData}
            handleClickOpen={this.handleClickOpen}
            onClose={this.onClose}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getStudentList,
  getSemesterDetailsByYearAndAcademic,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(RollList);

// import React from "react";
// import { connect } from "react-redux";
// import { getAlert } from "../../../CommonActions/alert.slice";
// import { resetReducList } from "../../../CommonActions/User.action";
// import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
// import { rollListJson } from "../../../DynamicFormsJson/RollListTable";
// import {
//   getDistinctDivision,
//   getYearDetails
// } from "../../InternshipDeclaration/InternshipDeclataration.slice";
// import { downloadF } from "../../PDF/PDFDownload";
// import { getSemesterDetailsByYearAndAcademic ,getStudentList} from "../../RollNoList/ViewOfRollList/rollno.slice";
// import RollNoPopUp from "./RollNoPopUp";
// class AdmissionStatusMaster extends React.Component {

//   columns = [
//     {
//       name: "index",
//       title: "Sr. No.",
//       positionCenter: true,
//       showInscreen: true,
//       alignCenter: "center",
//       width: "10%",
//     },
//     {
//       name: "name",
//       title: "Student Name",
//       positionCenter: false,
//       showInscreen: true,
//       alignCenter: "left",
//       canSearch:true,
//       width: "15%",
//     },
//     {
//       name: "prnNo",
//       title: "PRN Number",
//       positionCenter: false,
//       showInscreen: true,
//       alignCenter: "center",
//       width: "10%",
//     },
//     {
//       name: "rollNo",
//       title: "Roll No",
//       positionCenter: true,
//       showInscreen: true,
//       alignCenter: "center",
//       width: "10%",
//     },
//     {
//       name: "dateOfReporting",
//       title: "Reporting Date",
//       positionCenter: true,
//       showInscreen: true,
//       alignCenter: "center",
//       width: "10%",
//     },
//     {
//       name: "action",
//       title: "Action",
//       positionCenter: true,
//       showInscreen: true,
//       alignCenter: "center",
//       width: "10%",
//     },

//   ];

//   constructor(props) {
//     super(props);
//     this.state = {
//       dynamicMasterData: {
//         yearMaster: this.props.internshipDeclarationList?.yearDetails,
//         semisterMaster: this.props.rollNoData?.semisterDetailsList,
//         divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
//       },
//       open: false,
//       openView: false,
//       timeTableHeaderData: {},
//     };
//   }
//   componentWillReceiveProps(nextProps) {
//     if (
//       this.props.internshipDeclarationList !==
//       nextProps.internshipDeclarationList
//     ) {
//       if (
//         this.props.internshipDeclarationList.yearDetails !==
//         nextProps.internshipDeclarationList.yearDetails
//       ) {
//         this.setState({
//           dynamicMasterData: {
//             ...this.state.dynamicMasterData,
//             yearMaster: nextProps.internshipDeclarationList.yearDetails,
//           },
//         });
//       }
//       if (
//         this.props.internshipDeclarationList.distinctDivision !==
//         nextProps.internshipDeclarationList.distinctDivision
//       ) {
//         this.setState({
//           dynamicMasterData: {
//             ...this.state.dynamicMasterData,
//             divisionMaster:
//               nextProps.internshipDeclarationList.distinctDivision,
//           },
//         });
//       }
//     }

//     if (this.props.rollNoData !== nextProps.rollNoData) {
//       if (
//         this.props.rollNoData.semisterDetailsList !==
//         nextProps.rollNoData.semisterDetailsList
//       ) {
//         this.setState({
//           dynamicMasterData: {
//             ...this.state.dynamicMasterData,
//             semisterMaster: nextProps.rollNoData.semisterDetailsList,
//           },
//         });
//       }
//     }
//   }
//   componentDidMount() {
//     const {
//       resetReducList,
//       getYearDetails,
//       getAlert,
//     } = this.props;
//     resetReducList();

//     getYearDetails().then((response) => {
//       if (!response) {
//         getAlert({ message: "Something went wrong" });
//       }
//     });

//   }
//   getListById = (data) => {
//     const ay=localStorage.getItem('acadamicYearId')
//     if (data.yearDetail) {
//       this.props
//         .getSemesterDetailsByYearAndAcademic({
//           yearId: data.yearDetail,
//           academicId: ay,
//         })
//         .then((response) => {
//           if (!response) {
//             this.props.getAlert({ message: "Something went wrong" });
//           }
//         });
//     }
//     if (data.yearDetail) {
//       this.props
//         .getDistinctDivision({ yearDetail: data.yearDetail })
//         .then((response) => {
//           if (!response) {
//             this.props.getAlert({ message: "Something went wrong" });
//           }
//         });
//     }
//   };

//   onAddButtonClick=()=>{
//     window.location.replace("/genarateRollNo");
//   }
//   onClickEdit = (params) => {
//     this.setState({
//       popUpValue: true,
//       open: true,
//       studentData: params,
//     });
//   };
//   onSearchData=(data)=>{

//       this.props.getStudentList({
//         divisionId: data.division,
//         yearId: data.yearDetail,
//         semisterID: data.semisterDetails,
//       }).then((response) => {
//         if (!response) {
//           getAlert({ message: "Something went wrong" });
//         }
//       });

//   }
//   render() {
//     const { rollNoData } = this.props;
//     const { dynamicMasterData, open,popUpValue,openView } = this.state;
//     return (
//       <>
//         {!open && !openView && (
//           <DynamicMainScreen
//             onAddButtonClick={this.onAddButtonClick}
//             screenTitle={rollListJson.screenTitle}
//             fieldMeta={rollListJson.fieldMeta}
//             buttonCenter={rollListJson.buttonCenter}
//             showPdfDownload={rollListJson.showPdfDownload}
//             showExcelDownload={rollListJson.showExcelDownload}
//             pdfFileName={rollListJson.pdfFileName}
//             excelFileName={rollListJson.excelFileName}
//             tableColumnsToFilter={rollListJson.tableColumnsToFilter}
//             showAddButton={rollListJson.showAddButton}
//             dynamicMasterData={dynamicMasterData}
//             tableColumns={this.columns}
//             tableData={rollNoData?.studentList}
//             getListById={this.getListById}
//             showPegination={false}
//             showView={true}
//             showHeadEdit={true}
//             showHeadDelete={true}
//             showHeadPdf={true}
//             // showPegination={false}
//             pdfDetailsId={this.pdfDetails}
//             rowViewData={this.onClickView}
//             rowDelete={this.rowDelete}
//             rowEdit={this.onClickEdit}
//             rowStatus={this.changeStatus}
//             onDelete={this.onDelete}
//             onSearchData={this.onSearchData}
//             baseIdColumn={rollListJson.baseIdColumn}
//             apiBaseURL={rollListJson.apiBaseURL}
//           />
//         )}

//                     {popUpValue && (
//               <RollNoPopUp
//                 open={open}
//                 submitHandler={this.save}
//                 // semisterDetail={semisterDetails}
//                 // yearDetail={yearDetail}
//                 studentData={this.state.studentData}
//                 handleClickOpen={this.handleClickOpen}
//                 onClose={this.onClose}
//               />
//             )}
//       </>
//     );
//   }
// }
// const mapStateToProps = (state) => ({
//   internshipDeclarationList: state.internshipDeclaration,
//   academicYearList: state.academicYear,
//   rollNoData: state.rollNo,
// });
// const mapDispatchToProps = {
//   getAlert,
//   getYearDetails,
//   getDistinctDivision,
//   getSemesterDetailsByYearAndAcademic,
//   resetReducList,
//   getStudentList,
//   downloadF,
// };
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(AdmissionStatusMaster);
