import { Grid, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getProgramTitleNew } from "../../Masters/OnlineModule/ProgramTitle/programTitle.slice";
import { getEducationByAluminiId } from "./studentFillProfile.slice";

class AluminiCourseDetails extends Component {
  state = {
    id: "",
    locationOfTheInst: "",
    institutionName: "",
    yearDetail: "",
    endDate: "",
    formErrors: [],
    programTitle: "",
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { institutionName, endDate, programTitle } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (endDate.toString().trim() === "" || endDate === null) {
      formIsValid = false;
      formErrors["endDateErr"] = myConstClass.completionYearMsg;
    }

    if (institutionName.toString().trim() === "") {
      formIsValid = false;
      formErrors["institutionErr"] = myConstClass.instNameMsg;
    }

    if (programTitle === "" || programTitle === null) {
      formIsValid = false;
      formErrors["programTitleErr"] = myConstClass.programTitleMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    const { programTitle, academicYearList, educationalData } = this.props;
    console.log(educationalData);
    if (Object.keys(educationalData).length != 0) {
      this.setState({
        id: educationalData.id,
        locationOfTheInst: educationalData.location,
        endDate: educationalData.period,
        programTitle: educationalData.programTitleId,
      });
    } else {
      this.setState({
        id: "",
        locationOfTheInst: "",
        institutionName: "",
        yearDetail: "",
        endDate: "",
        programTitle: "",
      });
    }

    this.props.getProgramTitleNew().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    const progName = this.props.programTitleList?.progTitle.filter((row) => {
      return row.id === programTitle;
    });
    this.setState({
      institutionName: academicYearList.academicYearData.tenant.shortName,
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { locationOfTheInst, institutionName, endDate, programTitle, id } =
      this.state;
    const { academicYearList } = this.props;
    console.log(this.state);

    const progName = this.props.programTitleList?.progTitle.filter((row) => {
      return row.id === programTitle;
    });

    if (this.handleFormValidation()) {
      let docs = {};
      if (id !== "") {
        docs = {
          id: id,
          location: locationOfTheInst,
          institute: institutionName,
          programTitle: progName[0].name,
          period: endDate,
          programTitleId: programTitle,
          instituteId: academicYearList.academicYearData.tenant.id,
        };
      } else {
        docs = {
          location: locationOfTheInst,
          institute: institutionName,
          programTitle: progName[0].name,
          period: endDate,
          programTitleId: programTitle,
          instituteId: academicYearList.academicYearData.tenant.id,
        };
      }

      this.onSave(docs);
      this.props.onSaveData();
      this.setState({
        id: "",
        locationOfTheInst: "",
        endDate: "",
        programTitle: "",
      });
    }
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.educationalDetails,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: dataToSave.id
            ? "Data Updated Successfully"
            : "Data Saved Successfully",
        });
        this.setState({
          uniName: "",
          programDegree: "",
          period: "",
          locationOfTheInst: "",
        });
        this.props.getEducationByAluminiId({ id: "" }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  render() {
    const { locationOfTheInst, institutionName, endDate, programTitle } =
      this.state;
    const { programTitleList, academicYearList } = this.props;
    const { programTitleErr, endDateErr } = this.state.formErrors;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
            sx={{ marginTop: 3 }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              spacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                spacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="College / Institute/ University Name	"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    {/* <AutoComplete
                      keyColName={"id"}
                      value={institutionName}
                      name={"institutionName"}
                      options={[]}
                      onChange={this.ChangeHandlerSearch}
                      isError={institutionErr ? true : false}
                      errorText={institutionErr ? institutionErr : " "}
                    /> */}

                    <TextField
                      color="primary"
                      name="institutionName"
                      size="small"
                      value={
                        Object.keys(
                          this.props.academicYearList.academicYearData
                        ).length !== 0
                          ? this.props.academicYearList.academicYearData.tenant
                              .shortName
                          : ""
                      }
                      fullWidth
                      onChange={this.changedHandler}
                      helperText={" "}
                      disabled
                    />
                  </Grid>
                </Grid>{" "}
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Program Title"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={programTitle}
                      name={"programTitle"}
                      options={this.props.programTitleList?.progTitle}
                      onChange={this.ChangeHandlerSearch}
                      isError={programTitleErr ? true : false}
                      errorText={programTitleErr ? programTitleErr : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Location"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="locationOfTheInst"
                      size="small"
                      value={"Kingscircle"}
                      fullWidth
                      disabled
                      onChange={this.changedHandler}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Completion Year"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="endDate"
                      size="small"
                      value={endDate}
                      fullWidth
                      onChange={this.changedHandler}
                      helperText={endDateErr ? endDateErr : " "}
                      error={endDateErr ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={8}
                  xs={12}
                  md={8}
                  lg={8}
                  columnSpacing={4}
                  container
                  justifyContent="space-evenly"
                ></Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                container
                justifyContent="flex-start"
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12 }}
                  label="*Completion year is the year you completed that particular degree/diploma."
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                container
                justifyContent="flex-start"
              />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={2}
                sx={{ mt: -6, mb: -4 }}
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={1} md={1} lg={1} container>
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=""
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="submit"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1}
                  md={1}
                  lg={1}
                  container
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=""
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={this.props.cancelForm}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  programTitleList: state.programTitle,
  academicYearList: state.academicYear,
});

const mapDispatchToProps = {
  getAlert,
  showNotification,
  getProgramTitleNew,
  getEducationByAluminiId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AluminiCourseDetails);
