import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import SearchAppliedList from "../../components/SearchTable";
import endpoint from "../../config/endpoints";
import { getStudentList } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { downloadF } from "../PDF/PDFDownload";
import { deleteFileData } from "../UploadFile/file.slice";
import AddIntrestedStudents from "./AddIntrestedStudent";
import {
  getInterestedStudById,
  getIntrestedStudentList,
  getNotIntrestedStudentList,
  resetValues,
} from "./CompanyList.slice";
import UpdateInfo from "./UpdateInfo";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "firstName",
    title: "Student Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "30%",
    keyName: "firstName,lastName,fatherName,motherName,middleName",
    isFormat: true,
    type: 0,
  },
  {
    name: "division",
    title: "Division",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "rollNo",
    title: "Roll No.",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];

const InterestedStudent = ({
  onCancel,
  companyData,
  getIntrestedStudentList,
  getNotIntrestedStudentList,
  getAlert,
  intrestedStudList,
  notIntrestedStudList,
  getInterestedStudById,
  deleteFileData,
  getStudentListById,
  resetValues,
  getStudentList,
}) => {
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const [updateStudInfo, setUpdateStudInfo] = useState(false);
  const [interestedStudent, setInterestedStudent] = useState(true);
  const [studData, setStudData] = useState({});
  const [flag, setFlag] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpValue, setPopUpValue] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyPackage, setCompanyPackage] = useState("");

  const onClose = () => {
    setOpenDialog(true);
    setPopUpValue(false);
    setInterestedStudent(true);
    setStudData({});
  };

  const handleClickOpen = () => {
    setOpenDialog(false);
  };

  const rowDetails = (data) => {
    // this.setState({
    // //   accountFees: false,
    // //   studentFeesDet: true,
    // //   feesCollection: false,
    // //   paymentDetailsData: data,
    // });
  };

  const rowUpdateStudList = (data) => {
    console.log("Open Update Info" + data);
    setUpdateStudInfo(true);
    setPopUpValue(false);
    setStudData({});
    setInterestedStudent(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadPdf = () => {
    downloadF({
      url:
        endpoint.internshipReport +
        "?internshipDeclareId=" +
        companyData.internshipDeclareId +
        "&intrestStatus=0&documentType=1",
      ext: "pdf",
      openNewTab: true,
    });
  };
  const downloadExcel = () => {
    downloadF({
      url:
        endpoint.internshipReport +
        "?internshipDeclareId=" +
        companyData.internshipDeclareId +
        "&intrestStatus=0&documentType=0",
      ext: "xls",
      openNewTab: false,
    });
  };

  const openDeclareForm = () => {
    // resetSaveDeclare()
    console.log("Open Update Info");
    setUpdateStudInfo(false);
    setPopUpValue(true);
    setOpenDialog(true);
    setStudData({});
    setInterestedStudent(true);
  };

  const editStudentInfoIntStudent = (data) => {
    console.log(companyData);
    console.log(data);
    setStudData(data);
    setUpdateStudInfo(true);
    setPopUpValue(false);
    setOpenDialog(false);
    setInterestedStudent(false);
    setFlag(2);

    getInterestedStudById({ id: data.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    // setStudDataById(getStudentListById?.getInterestedStudByIdStatus)
  };
  const editStudentInfo = (data) => {
    console.log(companyData);
    console.log(data);
    setStudData(data);
    setUpdateStudInfo(true);
    setPopUpValue(false);
    setOpenDialog(false);
    setInterestedStudent(false);
    setFlag(1);

    getInterestedStudById({ id: data.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    // setStudDataById(getStudentListById?.getInterestedStudByIdStatus)
  };
  const UpdateInfoData = () => {
    console.log("Open Interested Student");
    getIntrestedStudentList({ id: companyData.internshipDeclareId }).then(
      (response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      }
    );
    getNotIntrestedStudentList({ id: companyData.internshipDeclareId }).then(
      (response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      }
    );

    getStudentList({
      yearDetails: companyData.yearDetailId,
      qualification: [],
      divisionIds: companyData.divisionId,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    // resetValues();
    deleteFileData();
    setUpdateStudInfo(false);
    setStudData({});
    setInterestedStudent(true);
  };

  useEffect(() => {
    console.log(companyData);
    console.log(companyData.internshipDeclareId);

    setCompanyName(companyData.companyName ? companyData.companyName : "N/A");
    setDesignation(companyData.designation ? companyData.designation : "N/A");
    setCompanyPackage(companyData.stipend ? companyData.stipend : "N/A");

    getIntrestedStudentList({ id: companyData.internshipDeclareId }).then(
      (response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      }
    );
    getNotIntrestedStudentList({ id: companyData.internshipDeclareId }).then(
      (response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      }
    );

    getStudentList({
      yearDetails: companyData.yearDetailId,
      qualification: [],
      divisionIds: companyData.divisionId,
      gapInYears: companyData.gapInYears,
      mathsCriteria: companyData.mathsCriteria,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    console.log(getStudentListById);
    console.log(getStudentListById?.getInterestedStudByIdStatus);
  }, []);
  console.log(intrestedStudList?.interestedStudentListSuccess);

  const getFilteredTableDataInterested = () => {
    return intrestedStudList?.interestedStudentListSuccess.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["division"] &&
            currentRow["division"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["rollNo"] &&
            currentRow["rollNo"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  const getFilteredTableDataNotInterested = () => {
    return intrestedStudList?.notInterestedStudentListSuccess.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["division"] &&
            currentRow["division"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["rollNo"] &&
            currentRow["rollNo"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <>
      {interestedStudent && (
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Interested Student List"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                container
                justifyContent="flex-end"
              >
                <FontAwesomeIconCompo
                  color="error"
                  fontSize="large"
                  title="PDF"
                  baseClassName="fas"
                  className="fa-file-pdf"
                  onClick={downloadPdf}
                />
                &nbsp; &nbsp; &nbsp;
                <FontAwesomeIconCompo
                  color="success"
                  fontSize="large"
                  title="Excel"
                  baseClassName="fas"
                  className="fa-file-excel"
                  onClick={downloadExcel}
                />
                &nbsp; &nbsp; &nbsp;
                <MyComponentWithIconProps
                  statusImage={PersonAddAlt1Icon}
                  color="primary"
                  fontSize="large"
                  title="Add Student"
                  onClick={openDeclareForm}
                />
                &nbsp; &nbsp; &nbsp;
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
            <br />
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
                sx={{ marginTop: 2, mb: 2 }}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={"Company Name : " + companyName}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={"Designation : " + designation}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={"Stipend( ₹ Per month ) : " + companyPackage}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="space-evenly"
              spacing={2}
              xs={12}
              sm={12}
              md={12}
              sx={{ marginBottom: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <SearchAppliedList
                  onSearch={onSearch}
                  searchValue={searchValue}
                />
              </Grid>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handledChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    "Applied Students " +
                    intrestedStudList?.interestedStudentListSuccess.length
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    "Not Applied Students " +
                    notIntrestedStudList?.notInterestedStudentListSuccess.length
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <DynamicTable
                data={getFilteredTableDataInterested()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfo}
                showHeadEdit={true}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DynamicTable
                data={getFilteredTableDataNotInterested()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfoIntStudent}
                showHeadEdit={true}
              />
            </TabPanel>
          </Paper>
        </div>
      )}
      {updateStudInfo && (
        <UpdateInfo
          goBack={UpdateInfoData}
          data={companyData}
          studentData={studData}
          flag={flag}
        />
      )}
      {popUpValue && (
        <AddIntrestedStudents
          open={openDialog}
          onClose={onClose}
          handleClickOpen={handleClickOpen}
          goBack={UpdateInfoData}
          data={companyData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  intrestedStudList: state.companyList,
  notIntrestedStudList: state.companyList,
  getStudentListById: state.companyList,
});

const mapDispatchToProps = {
  getIntrestedStudentList,
  deleteFileData,
  getNotIntrestedStudentList,
  getAlert,
  getInterestedStudById,
  resetValues,
  getStudentList,
  downloadF,
};
export default connect(mapStateToProps, mapDispatchToProps)(InterestedStudent);
