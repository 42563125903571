export const ExternalEventReportJson = {
    screenTitle: "Event Report",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    searchButton: false,
    PDFButton: true,
    EXCELButton: true,
    marginTop: 3,
    fieldMeta: [

        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-l"
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: true,
            minDate: new Date(),
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "fromDate-g"
        },
        {
            label: "Academic Year",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "academicYear",
            dataKey: "academicYearId",
            isMandatory: true,
        },
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearDetailMaster",
            dataKey: "yearDetailId",
            getListId: "yearDetailId",
            isMandatory: true,
        },
        {
            label: "Division",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "divisionMaster",
            dataKey: "divisionId",
            getListFrom: "yearDetailId",
            isMandatory: true,
        },
        // {
        //     label: "Event",
        //     controlType: "autocomplete",
        //     placeHolder: "",
        //     md: 6,
        //     lg: 6,
        //     sm: 6,
        //     xs: 12,
        //     masterName: "eventMaster",
        //     dataKey: "eventId",
        //     isMandatory: true,
        // },
    ],
};
