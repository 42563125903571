
import endpoint from "../config/endpoints";

export const patternJson = {
  apiBaseURL: endpoint.pattern,
  screenTitle: "Pattern Details",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Pattern Details",
  showExcelDownload: true,
  excelFileName: "Pattern Details",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Marking System",
      columnKeyName: "markingSystemName",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true
    },
    {
      label: "Marking System",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "markingSystem",
      dataKey: "markingSystem",
      isRootLevelKey: true,
      isMandatory: true
    },
  ],
};
