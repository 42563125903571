
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAlert ,resetGenratedAlert} from "./alert.slice";
import { Alert, Stack } from "@mui/material";
const AlertMsg = ({getAlert,resetGenratedAlert,msg,alertInfo,closeAlert}) => {
  const [show, setShow] = useState(false);

//   useEffect(() => {
//     if (alerts.length > 0) {
//       setAlert(alerts[alerts.length - 1]);
//       setShow(true);
//       setTimeout(() => {
//         setShow(false);
//       }, 3000);
//     }
//   }, [alerts]);
useEffect(()=>{
    getAlert({message:msg,type:'error'}).then(()=>{
        setShow(true)
    })
},[])

const resetAlertMsg=()=>{
    resetGenratedAlert().then(()=>{
        setShow(false)
        closeAlert()
    })
}
  return show ? (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert onClose={resetAlertMsg} severity="error">{alertInfo.alertMsg}</Alert>
    </Stack>
  ) : null;
};

const mapStateToProps = (state) => ({
    alertInfo: state.alertInfo,
  });
  
  const mapDispatchToProps = {
    getAlert,
    resetGenratedAlert
  };
  export default connect(mapStateToProps, mapDispatchToProps)(AlertMsg);