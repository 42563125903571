import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../../components/Comman/IconButton";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { getDeemedCommittee } from "./committee.slice";
import { LabelCompo } from "../../../components/Comman/Label";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DeemedCommittee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.rows !== nextProps.rows) {
      this.setState({
        rowList: nextProps.rows,
      });
    }
  }
  onClickViewRow = (data) => {
    let docs = {
      departmentIds: data.departmentIds,
      details: data.details,
      flag: 0,
      id: data.id,
      index: data.index,
      isActive: data.isActive,
      name: data.name,
      userRegistration: data.userRegistration,
    };
    this.props.onClickView(docs);
  };
  onClickRead = (id) => {
    const rows = this.state.rowList.map((rowData) => {
      if (rowData.id === id) {
        rowData = {
          ...rowData,
          open: !rowData.open,
        };
        return rowData;
      }
      return rowData;
    });
    this.setState({
      rowList: rows,
    });
  };
  render() {
    return (
      <>
        <TableContainer component={Paper} variant="scrollable">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="50" align="center">
                  Sr. No.
                </TableCell>
                <TableCell width="200" align="center">
                  Committee Name
                </TableCell>
                <TableCell width="200" align="center">
                  Committee Details
                </TableCell>
                <TableCell width="30" align="center">
                  Is Active
                </TableCell>
                <TableCell width="70" align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rowList.map((row, index) => (
                <>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>

                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>

                    <TableCell>
                      {!row.open &&
                      row.details.length > row.details.slice(0, 70).length
                        ? row.details.slice(0, 70) + " ... "
                        : row.details.slice(0, 70)}{" "}
                      {!row.open &&
                        row.details !== row.details.slice(0, 70) && (
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            label={" Read more"}
                            onClick={() => this.onClickRead(row.id)}
                          />
                        )}
                      {row.open && row.details}
                      {row.open &&
                        row.details.length >
                          row.details.slice(0, 70).length && (
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            label={"show less"}
                            onClick={() => this.onClickRead(row.id)}
                          />
                        )}
                    </TableCell>
                    <TableCell>
                      {row.isActive === 1 ? (
                        <center>
                          <ChipCompo
                            label="Active"
                            variant="outlined"
                            size="small"
                            color="success"
                            onClick={(e) => this.props.changeStatus(e, row)}
                          />
                        </center>
                      ) : (
                        <center>
                          <ChipCompo
                            label="Inactive"
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={(e) => this.props.changeStatus(e, row)}
                          />
                        </center>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.flag === 1 ? (
                        <>
                          <MyComponentWithIconProps
                            statusImage={EditIcon}
                            color="primary"
                            fontSize="small"
                            title="Edit"
                            // sx={{ marginLeft: 3 }}
                            onClick={(e) => this.props.OnClickEdit(row)}
                          />
                          <MyComponentWithIconProps
                            statusImage={DeleteIcon}
                            color="error"
                            fontSize="small"
                            // sx={{ marginLeft: 35 }}
                            title="Delete"
                            onClick={(e) => this.props.rowDelete(row)}
                          />
                          <MyComponentWithIconProps
                            statusImage={VisibilityIcon}
                            color="primary"
                            fontSize="small"
                            // sx={{ marginLeft: 35 }}
                            title="View"
                            onClick={(e) => this.onClickViewRow(row)}
                          />
                          <MyComponentWithIconProps
                            statusImage={AddIcon}
                            color="primary"
                            fontSize="small"
                            title="Add Member"
                            // sx={{ marginLeft: 3 }}
                            onClick={(e) => this.props.OnClickAdd(row)}
                          />
                          <MyComponentWithIconProps
                            statusImage={PictureAsPdfIcon}
                            color="error"
                            fontSize="small"
                            title="PDF"
                            // sx={{ marginLeft: 3 }}
                            onClick={(e) => this.props.onClickDownload(row)}
                          />
                        </>
                      ) : (
                        <>
                          <MyComponentWithIconProps
                            statusImage={VisibilityIcon}
                            color="primary"
                            fontSize="small"
                            // sx={{ marginLeft: 35 }}
                            title="View"
                            onClick={(e) => this.props.onClickView(row)}
                          />
                          <MyComponentWithIconProps
                            statusImage={PictureAsPdfIcon}
                            color="error"
                            fontSize="small"
                            title="PDF"
                            // sx={{ marginLeft: 3 }}
                            onClick={(e) => this.props.onClickDownload(row)}
                          />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  deemedCommitteeList: state.deemedCommittee,
});
const mapDispatchToProps = {
  getDeemedCommittee,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(DeemedCommittee);
