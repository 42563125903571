import React, { useEffect, useState } from "react";
import DynamicSelectTable from "../../../components/Comman/RT/CheckBoxSelectionTable";
const GenarationOfBatchesTable = ({ onSelectionChange, selection, rows }) => {
  // const [values, setValue] = useState(rows);
  // const [selection, setSelection] = useState([]);

  // const getRow = (e) => {
  //   setSelection(e);

  //   const listLength = e.length;
  //   lengthValue(listLength);
  // };

  const columns = [
    { name: "index", title: "Sr. No." },
    {
      name: "firstName",
      title: "Student Name",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    { name: "rollNo", title: "Roll Number" },
    { name: "prnNo", title: "PRN Number" },
  ];
  // useEffect(() => {

  //   if (values.length === 0) {
  //     setValue(rows);
  //   }
  // });
  // useEffect(()=>{
  //   if (selectedStudentIndexList!==selection) {
  //     setSelection(selectedStudentIndexList);
  //     selList(StudentList);
  //      resetSelectedStudentIndexList();
  //   }
  // },[])
  return (
    <>
      <DynamicSelectTable
        rows={rows}
        columns={columns}
        selection={selection}
        onSelectionChange={onSelectionChange}
        isActionColActive={false}
      />
      &nbsp;&nbsp; &nbsp;&nbsp;
    </>
  );
};
export default GenarationOfBatchesTable;
