import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  class: [],
  classData: [],
  isFetchClass: false,
  isFetch: false,
  classById: [],
  isFetchById: false,
  isFetchClassDetails: false,
  classDetails: {},
  isSave: false,
  classFilter: [],
  isFilter: false,
  isServerFail: false,
  error: false,
  yearDetailsByProgramTitle: [],
  isYearDetailsBYProgTitleFetch: false,
  yearDetails: [],
  isYearDetailsFetch: false,

  yearDetailsTransfer: [],
  isFetchYearDetails: false,

  yearDetailByUserId: [],
  isFetchYearDetailByUserId: false,

  yearDetailByProgramTitle: [],
  isFetchYearDetailByProgramTitle: false,
};
let URL = endpoints.yearDetail;
let URL2 = endpoints.yearDetailByDepartmentIds;
const departmentId = localStorage.getItem("departmentId");
const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    classSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        class: row,
        isFetchClass: true,
      };
    },
    classByProgramTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearDetailsByProgramTitle: row,
        isYearDetailsBYProgTitleFetch: true,
      };
    },
    classDeleteFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: true,
        isDelete: true,
        isSave: false,
      };
    },
    classFilterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        classFilter: row,
        isFilter: true,
      };
    },
    classFilterFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        classFilter: [],
        isFilter: false,
      };
    },
    classDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: true,
      };
    },
    classByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        classById: row,
        isFetchById: true,
      };
    },
    classDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        classDetails: data,
        isFetchClassDetails: true,
        isSave: true,
      };
    },
    classDetailsFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        classDetails: {},
        isFetchClassDetails: false,
        isSave: false,
      };
    },
    classFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        class: [],
      };
    },
    classListByNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        classData: data,
        isFetch: true,
      };
    },
    classListByNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        classData: [],
        isFetch: false,
      };
    },
    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete: false,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: false,
        isDelete: false,
        isSave: false,
      };
    },

    yearDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearDetails: row,
        isYearDetailsFetch: true,
      };
    },

    yearDetailsAdmittedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearDetailsTransfer: row,
        isFetchYearDetails: true,
      };
    },

    yearDetailByUserIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearDetailByUserId: row,
        isFetchYearDetailByUserId: true,
      };
    },
    yearDetailByProgramTitleSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        yearDetailByProgramTitle: row,
        isFetchYearDetailByProgramTitle: true,
      };
    },
  },
});

export const {
  classByProgramTypeSuccess,
  classFilterSuccess,
  classSuccess,
  resetDeleteSuccess,
  classByIdSuccess,
  classDeleteSuccess,
  classDetailsSuccess,
  classDetailsFail,
  classFailure,
  classDeleteFail,
  serverFailed,
  classListByNameSuccess,
  classListByNameFailure,
  yearDetailsSuccess,
  yearDetailsAdmittedSuccess,
  yearDetailByUserIdSuccess,
  yearDetailByProgramTitleSuccess,
} = classSlice.actions;

export default classSlice.reducer;

// export const getClass = () => async (dispatch) => {
//   try {
//     const name = localStorage.getItem("token");
//     const token = decodeData(name);
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       Authorization: token,
//       shouldrefresh: "true",
//     };
//     const doc = await axios
//       .get(URL, { headers })
//       .then((response) => {
//         const {error}=response.data
//         if(error===true){
//           dispatch(tokenExp());

//         }
//         let data = response.data.data;

//         let index = 0;
//         const row=data.map((yearDetail) => {
//           index = index + 1;
//                 let classData = {
//                   index: index,
//                   id: yearDetail.id,
//                   name: yearDetail.programTitle.brName,
//                   courseId: yearDetail.programTitle.id,
//                   isActive: yearDetail.isActive ,
//                 };
//                 return classData;
//               })
//         dispatch(classSuccess({ row }));
//         return row;
//       })
//       .catch((error) => {
//         console.log("Error getDocument========>", error);
//         dispatch(serverFailed());
//       });
//     return doc;
//   } catch (e) {
//     dispatch(serverFailed());
//     return console.error(e.message);
//   }
// };

// export const getClass = () => async (dispatch) => {
//   try {
//     const name = localStorage.getItem("token");
//     const token = decodeData(name);
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       Authorization: token,
//       shouldrefresh: "true",
//     };
//     const doc = await axios
//       .get(URL, { headers })
//       .then((response) => {
//         const { error } = response.data;
//         if (error === true) {
//           dispatch(tokenExp());
//         }
//         let data = response.data.data;

//         let index = 0;
//         const row = data.map((yearDetail) => {
//           index = index + 1;
//           let classData = {
//             index: index,
//             id: yearDetail.id,
//             name: yearDetail.programTitle.brName,
//             courseId: yearDetail.programTitle.id,
//             isActive: yearDetail.isActive,
//           };
//           return classData;
//         });
//         dispatch(classSuccess({ row }));
//         return row;
//       })
//       .catch((error) => {
//         console.log("Error getDocument========>", error);
//         dispatch(serverFailed());
//       });
//     return doc;
//   } catch (e) {
//     dispatch(serverFailed());
//     return console.error(e.message);
//   }
// };

export const getClass = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.year
              ? data1.year.programType.name +
                " - " +
                data1.programTitle.brName +
                " - " +
                data1.year.className
              : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(classSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getClassByProgramTitle =
  ({ programTitle }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-program-title?programTitleId=" + programTitle,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.year.programType.name +
                " - " +
                data1.programTitle.brName +
                " - " +
                data1.year.className,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(classByProgramTypeSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8087/v1/get-year-detail/by-id?yearDetailId=5ca6de9a-829f-4c6e-a134-88adfb556381
export const getAdmittedStudentByPreviousId =
  ({ yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-id?yearDetailId=" + yearId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.year.programType.name +
                " - " +
                data1.programTitle.brName +
                " - " +
                data1.year.className,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(yearDetailsAdmittedSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getYearDetailByUserId =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/by-year-detail-id?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;

          const row = data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              yearDetailId: data1.year.id,
              name:
                data1.year.programType.name +
                " - " +
                data1.programTitle.brName +
                " - " +
                data1.year.className,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(yearDetailByUserIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//used for only in teaching plan for hod and principal view
export const getYearDetailByDepartmentIds = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name:
              data1.year.programType.name +
              " - " +
              data1.programTitle.brName +
              " - " +
              data1.year.className,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(classSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

//localhost:8088/api/acdmc/v1/get-year-detail/by-program-title-with-limit?programTitleId=457cdb48-10aa-4039-8cbe-445a1be979f6
export const getYearDetailByProgramTitle =
  ({ progTitle }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-program-title-with-limit?programTitleId=" + progTitle,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.year.className,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(yearDetailByProgramTitleSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
