export const ExaminationReportJson = {
  apiBaseURL: "",
  screenTitle: "Examination Report",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  marginTop: 3,
  fieldMeta: [
    {
      label: "Exam Declare",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "examDeclareMaster",
      dataKey: "examDeclare",
      isMandatory: true,
      getListId: "examDeclare",
    },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearDetailMaster",
      dataKey: "yearDetail",
      isMandatory: true,
      getListId: "yearDetail",
    },
    {
      label: "Semester Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "semesterDetailMaster",
      dataKey: "semesterDetail",
      isMandatory: true,
      getListFrom: "yearDetail",
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subjectMaster",
      dataKey: "subject",
      isMandatory: true,
      getListId: "subject",
      getListFrom: "examDeclare",
    },
    {
      label: "Applied Student",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "examFormApplyStudentMaster",
      dataKey: "examFormStudentApply",
      isMandatory: true,
      getListFrom: "examDeclare",
    },
    {
      label: "Schedule Date",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "scheduleDateMaster",
      dataKey: "scheduleDate",
      isMandatory: true,
      getListId: "scheduleDate",
      getListFrom: "examDeclare",
    },
    {
      label: "Room Name",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "roomNameMaster",
      dataKey: "roomName",
      isMandatory: true,
      getListId: "roomName",
      getListFrom: "scheduleDate",
    },
  ],
};
