import { Grid, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { hasNumber } from "../../../../components/Comman/Util/Validations";
import { getFeesHeadDetail } from "../FeesHeadDetails/feesHeadDetails.slice";
import { getFromFees } from "../FromFees/fromFees.slice";
class MatrixForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      matList: [],
      columnList: [],
      total: [],
      fromFeesNames: [],
      columnHeaderStatus: {},
      rowHeaderStatus: {},
      totalGov: "100",
      matrixError: false,
    };
  }

  componentDidMount() {
    const {
      getFeesHeadDetail,
      getFromFees,
      fromFeesList,
      matrix,
      feeHeadList,
    } = this.props;
    getFeesHeadDetail();
    getFromFees();
    let matrixData = [];
    if (matrix.length === 0) {
      feeHeadList.map((feeHeadData) => {
        let matData;
        matData = { feeHeadId: feeHeadData.id, feeHeadName: feeHeadData.name };
        const fromFeesData = fromFeesList.map((fromFeesData) => {
          let fromFees = {
            fromFeesId: fromFeesData.id,
            fromFeesName: fromFeesData.name,
            value: 0,
          };
          return fromFees;
        });
        matData = { ...matData, fromFeesList: fromFeesData };

        this.setState({ total: fromFeesData });
        matrixData.push(matData);
      });
    } else {
      feeHeadList.map((feeHeadData) => {
        let matData;
        matData = { feeHeadId: feeHeadData.id, feeHeadName: feeHeadData.name };
        const fromFeesData = matrix.map((matrixData) => {
          if (matrixData.feeHeadId == feeHeadData.id) {
            let fromFees = {
              fromFeesId: matrixData.fromFeesId,
              fromFeesName: matrixData.fromFeesName,
              value: matrixData.value != "" ? matrixData.value : 0,
            };
            return fromFees;
          }
        });
        matData = { ...matData, fromFeesList: fromFeesData };
        matrixData.push(matData);
      });
    }

    this.setState({ matList: matrixData });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.matrix !== nextProps.matrix) {
      const { matList } = this.state;
      if (matList.length != 0) {
        let matrixDataRow = matList.map((data) => {
          const fromFeesList = data.fromFeesList.map((fromFeesData) => {
            let fromFees = nextProps.matrix.filter(
              (item) =>
                item.fromFeesId === fromFeesData.fromFeesId &&
                item.feeHeadId === data.feeHeadId
            );
            if (fromFees.length !== 0) {
              return fromFees[0];
            }
            return fromFeesData;
          });
          data = { ...data, fromFeesList: fromFeesList };
          return data;
        });
        const totalValue = this.state.total.map((totalOfValue) => {
          let fromFees = nextProps.matrix.filter(
            (item) => item.fromFeesId === totalOfValue.fromFeesId
          );
          let total = 0;
          fromFees.map((fromFeesData) => {
            total = +fromFeesData.value + total;
          });
          totalOfValue = {
            ...totalOfValue,
            value: total,
          };
          return totalOfValue;
        });
        this.setState({ matList: matrixDataRow, total: totalValue });
      }
    }
  }
  onChange = (e, feesHeadId, formFeesId) => {
    let { matList, total } = this.state;
    const { value } = e.target;
    console.log(matList);

    // let matrixDataRow = matList.map((data) => {
    //   if (data.feeHeadId === feesHeadId) {
    //     let fromFeesData = data.fromFeesList.filter(
    //       (item) => item.fromFeesId === formFeesId
    //     );
    //     let filterFromFeesList = data.fromFeesList.filter(
    //       (item) => item.fromFeesId !== formFeesId
    //     );
    //     let fromFees = {
    //       fromFeesId: fromFeesData[0].fromFeesId,
    //       fromFeesName: fromFeesData[0].fromFeesName,
    //       value: value,
    //     };
    //     if (fromFeesData[0].id) {
    //       fromFees = {
    //         ...fromFees,
    //         id: fromFeesData[0].id,
    //       };
    //     }
    //     filterFromFeesList = [...filterFromFeesList, fromFees];
    //     let matrixData = {
    //       feeHeadId: data.feeHeadId,
    //       feeHeadName: data.feeHeadName,
    //       fromFeesList: filterFromFeesList,
    //     };
    //     return matrixData;
    //   }

    //   return data;
    // });
    let totalMat = [];
    let matrixDataRow = matList.map((data) => {
      if (data.feeHeadId === feesHeadId) {
        const fromFeesList = data.fromFeesList.map((fromFeesData) => {
          if (fromFeesData.fromFeesId === formFeesId) {
            let fromFees = {
              fromFeesId: fromFeesData.fromFeesId,
              fromFeesName: fromFeesData.fromFeesName,
              value: value,
            };
            if (fromFeesData.id) {
              fromFees = { ...fromFees, id: fromFeesData.id };
            }
            return fromFees;
          }

          return fromFeesData;
        });
        totalMat.push(...fromFeesList);
        let matrixData = {
          feeHeadId: data.feeHeadId,
          feeHeadName: data.feeHeadName,
          fromFeesList: fromFeesList,
        };
        return matrixData;
      }
      totalMat.push(...data.fromFeesList);
      return data;
    });
    console.log("totalMat");
    console.log(totalMat);
    const totalValue = this.state.total.map((totalOfValue) => {
      let fromFees = totalMat.filter(
        (item) => item.fromFeesId === totalOfValue.fromFeesId
      );
      let total = 0;
      fromFees.map((fromFeesData) => {
        total = +fromFeesData.value + total;
      });
      totalOfValue = {
        ...totalOfValue,
        value: total,
      };
      return totalOfValue;
    });
    this.setState({ matList: matrixDataRow, total: totalValue });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { matList } = this.state;
    console.log(matList);
    if (matList.length !== 0) {
      this.props.onSave(matList);
    } else {
      this.setState({
        matrixError: true,
      });
    }
  };
  saveHandler = (e) => {
    e.preventDefault();
    const { matList } = this.state;
    const { feeHeadList, onSaveAndNext, fromFeesList, error } = this.props;
    if (matList.length !== 0) {
      const response = onSaveAndNext(matList);
      if (response) {
        let matrixData = [];
        feeHeadList.map((feeHeadData) => {
          let matData;
          matData = {
            feeHeadId: feeHeadData.id,
            feeHeadName: feeHeadData.name,
          };
          const fromFeesData = fromFeesList.map((fromFeesData) => {
            let fromFees = {
              fromFeesId: fromFeesData.id,
              fromFeesName: fromFeesData.name,
              value: 0,
            };
            return fromFees;
          });
          matData = { ...matData, fromFeesList: fromFeesData };
          matrixData.push(matData);
        });
        this.setState({ matList: matrixData });
      }
    } else {
    }
  };
  render() {
    const { fromFeesList, onCancel } = this.props;
    const { matList } = this.state;
    return (
      <>
        {
          <TableContainer>
            <Table
              sx={{ minWidth: 600 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Fees Head</TableCell>
                  {fromFeesList.map((columnName, index) => {
                    return (
                      <TableCell align="center" sx={{ width: 200 }}>{columnName.name}</TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {matList.map((matrixDataRow, indexRow) => {
                  return (
                    <TableRow>
                      <TableCell component="td" scope="row" >
                        {matrixDataRow.feeHeadName}
                      </TableCell>
                      {fromFeesList.map((columnName, index) => {
                        let fromFeesData = matrixDataRow.fromFeesList.filter(
                          (item) => item.fromFeesId === columnName.id
                        );
                        return (
                          <TableCell align="center">
                            <TextField
                              size="small"
                              color="primary"
                              type="number"
                              name={columnName.name}
                              id={columnName.name}
                              value={
                                fromFeesData.length === 0
                                  ? ""
                                  : fromFeesData[0].value
                              }
                              onChange={(e) =>
                                this.onChange(
                                  e,
                                  matrixDataRow.feeHeadId,
                                  columnName.id
                                )
                              }
                              fullWidth
                              // {...(totalFeeErr
                              //   ? { error: true, helperText: totalFeeErr }
                              //   : "")}
                            ></TextField>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell>Total</TableCell>
                  {this.state.total.map((totalValue, indexRow) => {
                    return <TableCell>{totalValue.value}</TableCell>;
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          m={2}
          rowSpacing={1}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1.1}
            lg={1.1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Save"
              fullWidth={true}
              onClick={this.submitHandler}
            />
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={2}
            lg={2}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Save and Next"
              fullWidth={true}
              onClick={this.saveHandler}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name=" Cancel"
              fullWidth={true}
              onClick={onCancel}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  programTitleList: state.feesStructureHead,
});
const mapDispatchToProps = {
  getFeesHeadDetail,
  getFromFees,
};
export default connect(mapStateToProps, mapDispatchToProps)(MatrixForm);
