import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import { adminPaperPosterJson } from "../../../../../DynamicFormsJson/AdminPaperPosterPresented";
import { paperPosterColumns } from "../../../../../tableColumns/table-columns";
import {
  getResourcePersonByType,
  setPublicationData,
  getAdminResourcePersonByType,
  resetData,
} from "../../AcademicDetails/ResourcePerson/resourcePerson.slice";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getEmployeeByDeptId } from "../../../../EmployeeDetails/employee.slice";

class AdminPaperPoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      dynamicMasterData: {
        LevelMaster: [
          { id: "International", name: "International" },
          { id: "National", name: "National" },
          { id: "State", name: "State" },
          { id: "University", name: "University" },
          { id: "Inhouse", name: "Inhouse" },
          { id: "Intercollegiate", name: "Intercollegiate" },
          { id: "other", name: "Other" },
        ],
        typeMaster: [
          { id: "Paper", name: "Paper" },
          { id: "Poster", name: "Poster" },
        ],
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeList?.employeeList,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
    if (this.props.employeeList !== nextProps.employeeList) {
      if (
        this.props.employeeList?.employeeList !==
        nextProps.employeeList?.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeList?.employeeList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  componentDidMount() {
    const { getResourcePersonByType } = this.props;
    this.setState({
      showLoader: true,
    });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  getListById = (data) => {
    if (data.departmentId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getEmployeeByDeptId({
          departmentId: data.departmentId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  goToAdminAcademicPanel = () => {
    window.location.replace("/admin-academic-panel");
  };

  submitHandler = (data) => {
    console.log(data.userRegistration);
    this.setState({
      showLoader: true,
    });
    this.props
      .getAdminResourcePersonByType({ userRegistration: data.userRegistration })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  render() {
    const { dynamicMasterData } = this.state;
    const { resourcePersonList, getActivity } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={adminPaperPosterJson.screenTitle}
            fieldMeta={adminPaperPosterJson.fieldMeta}
            showPdfDownload={adminPaperPosterJson.showPdfDownload}
            showExcelDownload={adminPaperPosterJson.showExcelDownload}
            pdfFileName={adminPaperPosterJson.pdfFileName}
            excelFileName={adminPaperPosterJson.excelFileName}
            showAddButton={adminPaperPosterJson.showAddButton}
            tableColumnsToFilter={adminPaperPosterJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            showSaveNextBtn={false}
            tableColumns={paperPosterColumns}
            tableData={resourcePersonList?.resourcePerson}
            getTableData={""}
            getTableDataById={true}
            showLoader={!this.state.showLoader}
            apiBaseURL={adminPaperPosterJson.apiBaseURL}
            baseIdColumn={adminPaperPosterJson.baseIdColumn}
            type={1}
            DocumentUpload={adminPaperPosterJson.DocumentUpload}
            setData={this.props.setPublicationData}
            showBackToListBtn={true}
            onBackToList={this.goToAdminAcademicPanel}
            getListById={this.getListById}
            addValidation={true}
            onSearchData={this.submitHandler}
            searchList={adminPaperPosterJson.searchList}
            dataSetToAdd={adminPaperPosterJson.dataSetToAdd}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  resourcePersonList: state.resourcePerson,
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  getResourcePersonByType,
  getAlert,
  setPublicationData,
  getDepartment,
  getEmployeeByDeptId,
  getAdminResourcePersonByType,
  resetData,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminPaperPoster);
