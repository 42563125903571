import React from "react";
import { connect } from "react-redux";
import { SubjectTypeDetailMasterColumns } from "../../../../tableColumns/table-columns";
import SubjectTypeDetailsNew from "../SubjectTypeDetail/SubjectTypeDetailsNew";
import { SubjectTypeDetailsJson } from "../../../../DynamicFormsJson/SubjectTypeDetail";
import { getSubjectTypeDetails } from "./subjectTypeDetails.slice";
import {getAlert}from "../../../../CommonActions/alert.slice";
class SubjectTypeDetailsIndex extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getSubjectTypeDetails } = this.props;
    getSubjectTypeDetails().then((response)=>{
      if(!response){
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  render() {
    const { subjectTypeDetailsList } = this.props;
    return (
      <>
        <div>
          <SubjectTypeDetailsNew
            screenTitle={SubjectTypeDetailsJson.screenTitle}
            fieldMeta={SubjectTypeDetailsJson.fieldMeta}
            showPdfDownload={SubjectTypeDetailsJson.showPdfDownload}
            showExcelDownload={SubjectTypeDetailsJson.showExcelDownload}
            pdfFileName={SubjectTypeDetailsJson.pdfFileName}
            excelFileName={SubjectTypeDetailsJson.excelFileName}
            showAddButton={SubjectTypeDetailsJson.showAddButton}
            onDelete={this.onDelete}
            onSave={this.onSave}
            tableColumnsToFilter={SubjectTypeDetailsJson.tableColumnsToFilter}
            tableColumns={SubjectTypeDetailMasterColumns}
            tableData={subjectTypeDetailsList?.subjectTypeDetails}
            baseIdColumn={SubjectTypeDetailsJson.baseIdColumn}
            getTableData={this.props.getSubjectTypeDetails}
            apiBaseURL={SubjectTypeDetailsJson.apiBaseURL}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subjectTypeDetailsList: state.subjectTypeDetails,
});
const mapDispatchToProps = {
  getSubjectTypeDetails,getAlert
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectTypeDetailsIndex);
