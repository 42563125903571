import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAlert, resetGenratedAlert } from "./alert.slice";
import { Alert, Stack } from "@mui/material";
const AlertMsg = ({ resetGenratedAlert, msg }) => {
  const resetAlertMsg = () => {
    resetGenratedAlert().then(() => {});
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      resetGenratedAlert().then(() => {
        console.log("This will run after 1 second!");
      });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert autoHideDuration={50} onClose={resetAlertMsg} severity={"error"}>
        {msg}
      </Alert>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  alertInfo: state.alertInfo,
});

const mapDispatchToProps = {
  getAlert,
  resetGenratedAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertMsg);
