import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { ExamFeesStructureJson } from '../../../../DynamicFormsJson/ExamFeesStructure.js';
import { examFeesStructureColumns } from "../../../../tableColumns/table-columns";
import { getExamFeesStructure } from "./examFeesStructure.slice";
import { getFeesTitile } from "../../Admission/FeesTitle/feesTitle.slice";
import { getFeeType } from "../../Admission/FeeType/feeType.slice"
import { getReceiptType } from "../../Admission/ReceiptType/receiptType.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
class ExamFeesStructure extends React.Component
{

    // eslint-disable-next-line no-useless-constructor
    constructor( props )
    {
        super( props );
        this.state = {
            dynamicMasterData: {
                type: [{id:"0",name:"Fixed Fees"},{id:"1",name:"Per Subject"},]
            }
        }
    }

    componentDidMount()
    {
        // console.log(this.props.feesTitleList?.feesTitle)
        this.props.getExamFeesStructure().then( ( response ) =>
        {
            if ( !response )
            {
                this.props.getAlert( { message: "Something went wrong" } );
            }
        } );

        // this.props.getFeeType().then( ( response ) =>
        // {
        //     if ( !response )
        //     {
        //         this.props.getAlert( { message: "Something went wrong" } );
        //     }
        // } );

        // this.props.getReceiptType().then( ( response ) =>
        // {
        //     if ( !response )
        //     {
        //         this.props.getAlert( { message: "Something went wrong" } );
        //     }
        // } );

        // this.props.getFeesTitile().then( ( response ) =>
        // {
        //     if ( !response )
        //     {
        //         this.props.getAlert( { message: "Something went wrong" } );
        //     }
        // } );

    }

    componentWillReceiveProps(nextProps) {
        // if (this.props.feeeTypeList !== nextProps.feeeTypeList) {
        //     if (this.props.feeeTypeList.feeType !== nextProps.feeeTypeList.feeType) {
        //         this.setState({
        //             dynamicMasterData: { ...this.state.dynamicMasterData, feesTypeMaster: nextProps.feeeTypeList.feeType }
        //         })
        //     }
        // }

        // if (this.props.feesTitleList !== nextProps.feesTitleList) {
        //     if (this.props.feesTitleList.feesTitle !== nextProps.feesTitleList.feesTitle) {
        //         this.setState({
        //             dynamicMasterData: { ...this.state.dynamicMasterData, feesTitleMaster: nextProps.feesTitleList.feesTitle.filter((data)=>data.isActive==1) }
        //         })
        //     }
        // }

        // if ( this.props.receiptTypeList !== nextProps.receiptTypeList )
        // {
        //     if ( this.props.receiptTypeList.receiptType !== nextProps.receiptTypeList.receiptType )
        //     {
        //         this.setState( {
        //             dynamicMasterData: { ...this.state.dynamicMasterData, receiptTypeMaster: nextProps.receiptTypeList.receiptType.filter( ( data ) => data.isActive == 1 ), }
        //         } )
        //     }
        // }
    }
    render()
    {
        const { dynamicMasterData } = this.state;
        const { examFeesStructureList } = this.props
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={ExamFeesStructureJson.screenTitle}
                        fieldMeta={ExamFeesStructureJson.fieldMeta}
                        showPdfDownload={ExamFeesStructureJson.showPdfDownload}
                        showExcelDownload={ExamFeesStructureJson.showExcelDownload}
                        pdfFileName={ExamFeesStructureJson.pdfFileName}
                        excelFileName={ExamFeesStructureJson.excelFileName}
                        showAddButton={ExamFeesStructureJson.showAddButton}
                        tableColumnsToFilter={ExamFeesStructureJson.tableColumnsToFilter}
                        dynamicMasterData={dynamicMasterData}
                        tableColumns={examFeesStructureColumns}
                        tableData={examFeesStructureList.examFeesStructure}
                        getTableData={this.props.getExamFeesStructure}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={examFeesStructureList.isFetch}
                        baseIdColumn={ExamFeesStructureJson.baseIdColumn}
                        apiBaseURL={ExamFeesStructureJson.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = ( state ) => ( {
    examFeesStructureList: state.examFeesStructure,
} );
const mapDispatchToProps = {
    getExamFeesStructure, getFeeType, getReceiptType, getFeesTitile, getAlert
}
export default connect( mapStateToProps, mapDispatchToProps )( ExamFeesStructure );