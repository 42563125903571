import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  subjectSelected: [],
  isFetch: false,

  schemeHead: [],
  isFetchSchemeHead: false,

  studentList: [],
  isFetchStudentList: false,

  studentListSub: [],
  isFetchStudentListSub: false,

  schemeHeadView: [],
  isFetchSchemeHeadView: false,

  subjectView: [],
  isFetchSubjectView: false,

  schemeSubject: [],
  isFetchSchemeSubject: false,

  subject: [],
  isFetchSubject: false,
};
let URL = endpoints.subjectInfo;
let SchemeHeadURL = endpoints.schemeHead;
let StudentUrl = endpoints.studentRollNo;
let studSubject = endpoints.studSubject;
const subjectSelectionSlice = createSlice({
  name: "subjectSelected",
  initialState,
  reducers: {
    subjectSelectedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectSelected: row,
        isFetch: true,
      };
    },
    subjectSelectedFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        subjectSelected: [],
        isFetch: true,
      };
    },
    schemeHeadSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        schemeHead: row,
        isFetchSchemeHead: true,
      };
    },

    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentList: row,
        isFetchStudentList: true,
      };
    },

    subjectSelectedListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentListSub: row,
        isFetchStudentListSub: true,
      };
    },
    subjectListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subject: row,
        isFetchSubject: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },

    schemeHeadViewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        schemeHeadView: row,
        isFetchSchemeHeadView: true,
      };
    },

    subjectViewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectView: row,
        isFetchSubjectView: true,
      };
    },

    schemeSubjectDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        schemeSubject: row,
        isFetchSchemeSubject: true,
      };
    },
  },
});

export const {
  subjectSelectedSuccess,
  subjectSelectedListSuccess,
  subjectSelectedFailed,
  subjectListSuccess,
  schemeHeadSuccess,
  resetSuccess,
  studentListSuccess,
  schemeHeadViewSuccess,
  subjectViewSuccess,
  schemeSubjectDataSuccess,
} = subjectSelectionSlice.actions;

export default subjectSelectionSlice.reducer;

export const getSubjectFromDeptSemester =
  ({ semesterDetail, department }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-by-depratment-semister-detail-and-scheme?departmentId=" +
          department +
          "&semisterDetailId=" +
          semesterDetail,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let subjectDetails = [];
            data1.subjectDetails.map((subjectDetailData) => {
              if (subjectDetailData.schemeHeadId.schemeId.id === "2") {
                subjectDetails.push(subjectDetailData);
              }
            });
            let bData = {
              index: index,
              name: data1.name,
              departmentId: data1.departmentId,
              patternId: data1.pattern.id,
              patternName: data1.pattern.name,
              semisterId: data1.semister.id,
              semisterName: data1.semister.name,
              isActive: data1.isActive,
              subjectTypeId: data1.subjectTypeId.id,
              subjectType: data1.subjectTypeId.name,
              shortName: data1.subjectTypeId.shortName,
              code: data1.code,
              id: data1.id,
              subjectDetails: subjectDetails,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(subjectSelectedSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectSelectList =
  ({ semesterId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studSubject +
          "/get-student-subject-by-semister-detail-and-division?semisterDetailId=" +
          semesterId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;

            let bData = {
              id: data1.id,
              index: index,
              name:
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName +
                " " +
                data1.userRegistration.lastName,
              rollNo:
                data1.reportingDetail !== null
                  ? data1.reportingDetail.rollNo
                  : "",
              userRegistrationId: data1.userRegistration.id,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(subjectSelectedListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectBySemisterId =
  ({ semesterId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studSubject +
          "/get-subject-by-user-and-semister-detail?semisterDetailId=" +
          semesterId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((data1) => {
            let bData = {
              id: data1.id,
              name: data1.name,
            };
            return bData;
          });
          console.log(row);
          dispatch(subjectListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getSchemeHead = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: SchemeHeadURL + "/get-scheme-head-by-scheme",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        console.log(content);
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            shortName: data1.shortName,
            schemeId: data1.schemeId.id,
            schemeName: data1.schemeId.name,
            schemeShortName: data1.schemeId.shortName,
            isTeaching: data1.schemeId.isTeaching,
            isActive: data1.isActive,
            sortOrderNo: data1.sortOrderNo,
          };
          row.push(bData);
          return data1;
        });
        dispatch(schemeHeadSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetSubjectValues = () => async (dispatch) => {
  try {
    dispatch(subjectSelectedFailed());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getStudentForSelection =
  ({ semisterDetailId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          StudentUrl +
          "/get-student-list-by-semister_detail-division?semisterDetailId=" +
          semisterDetailId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.userRegistrationId,
              rollNo: data1.rollNo,
              name:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              dateOfReporting: data1.dateOfReporting,
              divisionId: data1.divisionId,
              reportingDetail: data1.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

  //previous backup

// export const getStudentListForUnitTest =
//   ({ semisterDetailId, divisionId }) =>
//   async (dispatch) => {
//     try {
//       const response = apiGet({
//         url:
//           StudentUrl +
//           "/get-students-by-semister-detail-division?semisterDetailId=" +
//           semisterDetailId +
//           "&divisionId=" +
//           divisionId,
//       }).then(({ data, success }) => {
//         if (success) {
//           const content = data.data;
//           let index = 0;
//           let row = [];
//           content.map((data1) => {
//             index = index + 1;
//             let bData = {
//               index: index,
//               id: data1.userRegistrationId,
//               rollNo: data1.rollNo,
//               name:
//                 data1.firstName + " " + data1.middleName + " " + data1.lastName,
//               admissionRegistrationDetailId:
//                 data1.admissionRegistrationDetailId,
//               dateOfReporting: data1.dateOfReporting,
//               divisionId: data1.divisionId,
//               reportingDetail: data1.id,
//             };
//             row.push(bData);
//             return data1;
//           });
//           dispatch(studentListSuccess({ row }));
//         }
//         return success;
//       });
//       return response;
//     } catch (e) {
//       return console.error(e.message);
//     }
//   };


export const getStudentListForUnitTest =
  ({ semisterDetailId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          StudentUrl +
          "/get-students-by-semister-detail-divisions?semisterDetailId=" +
          semisterDetailId +
          "&divisionIds=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.userRegistrationId,
              rollNo: data1.rollNo,
              name:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              dateOfReporting: data1.dateOfReporting,
              divisionId: data1.divisionId,
              reportingDetail: data1.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// methods for subject selection view
// http://localhost:8088/api/acdmc/v1/student-subjects/get-scheme-head?userRegistrationId=f5db7f06-2109-4715-b413-0cef758a0116
export const getSchemeHeadView =
  ({ id, headerId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studSubject +
          "/get-scheme-head?userRegistrationId=" +
          id +
          "&headerId=" +
          headerId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              shortName: data1.shortName,
              schemeId: data1.schemeId.id,
              schemeName: data1.schemeId.name,
              schemeShortName: data1.schemeId.shortName,
              isTeaching: data1.schemeId.isTeaching,
              isActive: data1.isActive,
              sortOrderNo: data1.sortOrderNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(schemeHeadViewSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectView =
  ({ id, headerId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studSubject +
          "/get-subject?userRegistrationId=" +
          id +
          "&headerId=" +
          headerId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              headName: data1.headName,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(subjectViewSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDataView =
  ({ id, headerId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          studSubject +
          "/get-student-subjects-selection?userRegistrationId=" +
          id +
          "&headerId=" +
          headerId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(schemeSubjectDataSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
