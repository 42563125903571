export const VerificationDocumentJson = {
    screenTitle: "Verification Document",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter:false,
    getAcadamicYear:true,
    getListId:'applicationName',
    fieldMeta: [
      {
        label: "Application Name",
        controlType: "autocomplete",
        placeHolder: "",
        md: 3.6,
        lg: 3.6,
        sm: 3.6,
        xs: 12,
        masterName: "applicationNameMaster",
        getListId:'applicationName',
        dataKey: "applicationName",
        isMandatory: true,
      },
      {
        label: "Round Details",
        controlType: "autocomplete",
        placeHolder: "",
        md: 3.6,
        lg: 3.6,
        sm: 3.6,
        xs: 12,
        getListFrom:'applicationName',
        masterName: "roundDetailMaster",
        dataKey: "roundDetail",
        isMandatory: true,
      },
        {
        label: "Program Title",
        controlType: "autocomplete",
        placeHolder: "",
        md: 3.6,
        lg: 3.6,
        sm: 3.6,
        xs: 12,
        masterName: "programTitleMaster",
        getListFrom:'applicationName',
        dataKey: "programTitle",
        isMandatory: true,
      },
    ],
  };
  