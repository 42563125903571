import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TextField } from "@mui/material";

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

export default function ColumnGroupingTable({
  dataChange,
  scheme,
  schemeHead,
  rowEdit,
  rowDelete,
  rows,
  onChangeStatus,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {/* <TableCell align="center" colSpan={2}>
                Country
              </TableCell>
              <TableCell align="center" colSpan={3}>
                Details
              </TableCell> */}
            {scheme.map((schemeHeadData) => {
              let count = 0;
              const schmeList = schemeHead.filter(
                (row) => row.schemeId === schemeHeadData.id
              );
              schmeList.map((schemeData) => {
                if (schemeData.isMinMaxShow === 1) {
                  count = count + 2;
                } else {
                  count = count + 1;
                }
              });
              if (schmeList.length !== 0) {
                return (
                  <TableCell  align="center" colSpan={count}>
                    {schemeHeadData.name}
                  </TableCell>
                );
              }
            })}
          </TableRow>
          <TableRow>
            {scheme.map((schemeHeadData) => {
              return schemeHead.map((column) => {
                //  const schmeList = scheme.filter(
                //   (row) => row.id === column.schemeId
                // );
                if (column.schemeId === schemeHeadData.id) {
                
                if (column.isMinMaxShow === 1) {
                  return (
                    <>
                      <TableCell key={column.id} align={"center"}>
                        {column.name}
                      </TableCell>
                      <TableCell key={column.id} align={"center"}>
                        {column.name + " Min"}
                      </TableCell>
                      {/* <TableCell key={column.id} align={"center"}>
                        {column.name + "Max"}
                      </TableCell> */}
                    </>
                  );
                } else {
                  return (
                    <TableCell  key={column.id} align={"center"}>
                      {column.name}
                    </TableCell>
                  );
                }
              }
              });
            })}
          </TableRow>
        </TableHead>
        <TableBody>
     
          <TableRow>
            {scheme.map((schemeHeadData) => {
              return schemeHead.map((column) => {
                const onTextFieldDataChange = (e, subHeaderId,flag) => {
                  e.stopPropagation();
                
                  dataChange(e, rows, subHeaderId);
                };

                if (column.schemeId === schemeHeadData.id) {
                
                if (column.isMinMaxShow === 1) {
                  
                  return (
                    <>
                      <TableCell key={column.id} align={"center"}>
                      <TextField
                            placeholder={"Enter value"}
                            size="small"
                            color="primary"
                            id={rows[column.id]}
                            type="number"
                            name={rows[column.id]}
                            value={rows[column.id]}
                            // value={column.id}
                            onChange={(e) =>
                              onTextFieldDataChange(e, column.id,true)
                            }
                            fullWidth
                          ></TextField>
                        
                      </TableCell>
                      <TableCell key={column.id} align={"center"}>
                       
                        <TextField
                            placeholder={"Enter value"}
                            size="small"
                            color="primary"
                            type="number"
                            name={rows["min" + column.id]}
                            id={rows["min" + column.id]}
                            value={rows["min" + column.id]}
                            // value={"min" +column.id}
                            onChange={(e) =>
                              onTextFieldDataChange(e, "min" + column.id,false)
                            }
                            fullWidth
                          ></TextField>
                      </TableCell>
                    
                    </>
                  );
                } else {
                  return (
                    <TableCell  key={column.id} align={"center"}>
                       <TextField
                            placeholder={"Enter value"}
                            size="small"
                            color="primary"
                            id={rows[column.id]}
                            type="number"
                            name={rows[column.id]}
                            value={rows[column.id]}
                            // value={column.id}
                            onChange={(e) =>
                              onTextFieldDataChange(e, column.id,true)
                            }
                            fullWidth
                          ></TextField>
                    </TableCell>
                  );
                }
              }
              });
            })}
          </TableRow>
          {/* {rows.map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                {schemeHead.map((column) => {
                  // value=params[ schemeHeadData.id ]
                  const onTextFieldDataChange = (e, subHeaderId,flag) => {
                    e.stopPropagation();
                  
                    dataChange(e, row, subHeaderId);
                  };

                  if (column.isMinMaxShow === 1) {
                    return (
                      <>
                        <TableCell style={{ minWidth: 150}}key={column.id} align={column.align}>
                          <TextField
                            placeholder={"Enter value"}
                            size="small"
                            color="primary"
                            name={row[column.id]}
                            // type="number"
                            id={row[column.id]}
                            // value={row[column.id]}
                            value={column.id}
                            onChange={(e) =>
                              onTextFieldDataChange(e, column.id,true)
                            }
                            fullWidth
                          ></TextField>
                        </TableCell>
                        
                        <TableCell style={{ minWidth: 150}}key={column.id} align={column.align}>
                          <TextField
                            placeholder={"Enter value"}
                            size="small"
                            color="primary"
                            // type="number"
                            name={row["min" + column.id]}
                            id={row["min" + column.id]}
                            // value={row["min" + column.id]}
                            value={"min" +column.id}
                            onChange={(e) =>
                              onTextFieldDataChange(e, "min" + column.id,false)
                            }
                            fullWidth
                          ></TextField>
                        </TableCell>
                     
                      </>
                    );
                  } else {
                    return (
                      <TableCell style={{ minWidth: 150}} key={column.id} align={column.align}>
                        <TextField
                          placeholder={"Enter value"}
                          size="small"
                          color="primary"
                          // type="number"
                          name={row[column.id]}
                          id={row[column.id]}
                          // value={row[column.id]}
                          value={column.id}
                          onChange={(e) => onTextFieldDataChange(e, column.id,true)}
                          fullWidth
                        ></TextField>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
