import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { FilterSave } from "../../../components/FilterSave";

import { resetReducList } from "../../../CommonActions/User.action";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { ExternalStudentJson } from "../../../DynamicFormsJson/ExternalStudentEvent";
import { apiDelete, apiPost } from "../../../utils/api_service";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getEventByAcademicIdYearDetailAndDivision } from "../Event Management/event.slice";
class AdmissionStatusMaster extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr.no ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "studentName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "rollNo",
      title: "Roll No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
      canSearch: true,
    },
    {
      name: "eventName",
      title: "Event Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "venue",
      title: "Venue",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "fromToDate",
      title: "From Date - To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "21%",
      canSearch: true,
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        acadamicMaster: this.props.academicYearList?.academicYear,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      },
      open: false,
      showLoader: false,
      openView: false,
      showLoader: false,
      timeTableHeaderData: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            acadamicMaster: nextProps.academicYearList.academicYear,
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
  }
  componentDidMount() {
    const { resetReducList, getYearDetails, getAcademicYear, getAlert } =
      this.props;
    resetReducList();

    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const divisionId = localStorage.getItem("divisionId");

    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (yearDetailId && divisionId && ay) {
      this.setState({
        isLoading: true,
      });
      this.props
        .getEventByAcademicIdYearDetailAndDivision({
          acadamicYear: ay,
          YearDetail: yearDetailId,
          division: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              isLoading: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        });
    }

    this.setState({
      isLoading: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          isLoading: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }
  getListById = (data) => {
    if (data.yearDetail) {
      this.setState({
        isLoading: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              isLoading: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        });
    }
  };
  onSearchData = (dataToSearch) => {
    const ay = localStorage.getItem("acadamicYearId");

    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", dataToSearch.division);
    localStorage.setItem("divisionIds", "");
    FilterSave();
    this.setState({
      showLoader: true,
    });
    this.props
      .getEventByAcademicIdYearDetailAndDivision({
        acadamicYear: ay,
        YearDetail: dataToSearch.yearDetail,
        division: dataToSearch.division,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };
  onAddButtonClick = () => {
    this.setState({
      open: true,
    });
    // window.location.replace("/genrate-time-table");
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoints.timeTableHeader + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({
              showLoader: true,
            });
            this.props
              .getTimeTable({
                divisionId: rowData.division,
                semisterId: rowData.semisterDetail,
              })
              .then((response) => {
                if (!response) {
                  this.setState({
                    showLoader: false,
                  });
                  this.props.getAlert({ message: "Something went wrong" });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  changeStatus = (row) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const tableHeader = {
          id: row.id,
          semisterDetail: {
            id: row.semisterDetail,
          },
          division: {
            id: row.division,
          },
          fromDate: row.fromDate,
          toDate: row.toDate,
          remark: row.remark,
          isActive: row.isActive === 1 ? 0 : 1,
        };
        console.log(tableHeader);
        this.setState({
          showLoader: true,
        });
        apiPost({
          url: endpoints.timeTableHeader,
          postBody: tableHeader,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({
              showLoader: false,
            });
            this.props
              .getTimeTable({
                divisionId: row.division,
                semisterId: row.semisterDetail,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  render() {
    const { eventList } = this.props;
    const { dynamicMasterData, open, openView, showLoader } = this.state;
    return (
      <>
        <DynamicMainScreen
          onAddButtonClick={this.onAddButtonClick}
          screenTitle={ExternalStudentJson.screenTitle}
          fieldMeta={ExternalStudentJson.fieldMeta}
          buttonCenter={ExternalStudentJson.buttonCenter}
          showPdfDownload={ExternalStudentJson.showPdfDownload}
          showExcelDownload={ExternalStudentJson.showExcelDownload}
          pdfFileName={ExternalStudentJson.pdfFileName}
          excelFileName={ExternalStudentJson.excelFileName}
          tableColumnsToFilter={ExternalStudentJson.tableColumnsToFilter}
          showAddButton={ExternalStudentJson.showAddButton}
          dynamicMasterData={dynamicMasterData}
          tableColumns={this.columns}
          isActionColActive={false}
          tableData={eventList?.event}
          getListById={this.getListById}
          showPegination={false}
          showView={true}
          showHeadEdit={true}
          showHeadDelete={true}
          showLoader={showLoader}
          rowViewData={this.onClickView}
          rowDelete={this.rowDelete}
          rowEdit={this.onClickEdit}
          marginTop={ExternalStudentJson.marginTop}
          rowStatus={this.changeStatus}
          onDelete={this.onDelete}
          onSearchData={this.onSearchData}
          baseIdColumn={ExternalStudentJson.baseIdColumn}
          apiBaseURL={ExternalStudentJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  eventList: state.event,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  resetReducList,
  getEventByAcademicIdYearDetailAndDivision,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmissionStatusMaster);
