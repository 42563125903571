import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import FormHelperText from "@mui/material/FormHelperText";
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import swal from "sweetalert";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getStudentAttendanceList } from "../StudentAttendanceForms/studentAttendance.slice";

//   import CompletedSyllabus from "./CompletedSyllabus";

function StudentAttendanceForm({
  editData,
  teachingPlanList,
  studentAttendanceList,
  saveFormData,
  onSave,
  backToList,
}) {
  const [valuesLength, setValuesLength] = React.useState("");
  const [presentCount, setPresentCount] = React.useState("");
  const [absentCount, setAbsentCount] = React.useState("");
  const [rollNoCount, setRollNoCount] = React.useState("");
  const [present, setPresent] = React.useState("");
  const [values1Error, setValues1Error] = React.useState(false);
  const [values1, setValues1] = React.useState([]);

  useEffect(() => {
    if (values1.length === 0) {
      if (
        studentAttendanceList?.studentAttendanceList.length !== 0 &&
        Object.keys(saveFormData).length !== 0 &&
        saveFormData.studentAttendanceDetails
      ) {
        // if(saveFormData.id==0){
        //   const studentList =  studentAttendanceList?.studentAttendanceList.map((studData) => {
        //     const filterData = saveFormData.studentAttendanceDetails.filter(
        //       (row) => row.student.id === studData.userRegistrationId
        //     );

        //     if (studData.length !== 0) {
        //       studData = {
        //         ...studData,
        //         attendance: filterData[0].status === 1 ? true : false,
        //         excuse: filterData[0].excuse,
        //         answer: filterData[0].remark,
        //       };
        //       return studData;
        //     }
        //     return studData;
        //   });

        //   setValues1(studentList);
        // }
        setValues1(saveFormData.studentAttendanceDetails);
      } else {
        setValues1(studentAttendanceList?.studentAttendanceList);
      }
    } else {
      // if (values1.length !== 0&&values1!==studentAttendanceList?.studentAttendanceList) {
      //   setValues1(studentAttendanceList?.studentAttendanceList);
      // }
      const valuesLength1 = values1.length;
      console.log(valuesLength1);
      setValuesLength(valuesLength1);

      const varData1 = values1.filter((row) => row.attendance === true);
      console.log(varData1);

      const varDataLength = varData1.length;
      console.log(varDataLength);
      setPresentCount(varDataLength);

      const result1 = valuesLength1 - varDataLength;
      console.log(result1);
      setAbsentCount(result1);

      const rollNo1 = varData1.map((data) => {
        return data.rollNo;
      });
      setRollNoCount(rollNo1.join());
    }
  });

  const saveHandler = () => {
    if (values1.length !== 0) {
      setValues1Error(false);
      let dataToSaveFinal = {
        presentRollNos: rollNoCount,
        presentCount: presentCount,
        absentCount: absentCount,
        studentAttendanceDetails: values1,
      };

      console.log(dataToSaveFinal);
      onSave(dataToSaveFinal);
    } else {
      setValues1Error(true);
    }
  };

  const changeHandler1 = (e, id) => {
    console.log(e.target.checked);
    setValues1((data) =>
      data?.map((list, index) =>
        list.userRegistrationId === id
          ? { ...list, attendance: e.target.checked, answer: "" }
          : list
      )
    );
  };

  const changeHandler2 = (e, id) => {
    console.log(e.target.checked);
    setValues1((data) =>
      data?.map((list, index) =>
        list.userRegistrationId === id
          ? { ...list, excuse: e.target.checked }
          : list
      )
    );
  };

  const changeHandler3 = (e, id) => {
    const { value } = e.target;
    setValues1((data) =>
      data?.map((list, index) =>
        list.id === id ? { ...list, answer: value } : list
      )
    );
  };

  const attendanceChangeHandler = (e) => {
    console.log(e.target.checked);
    setPresent(e.target.checked);
    console.log(studentAttendanceList?.studentAttendanceList);

    const val = values1.map((data) => {
      let bData = {
        ...data,
        attendance: e.target.checked,
        excuse: e.target.checked ? !e.target.checked : data.excuse,
        answer: e.target.checked ? "" : data.answer,
      };
      return bData;
    });
    console.log(val);
    setValues1(val);
  };

  const changeHandler = (e) => {
    console.log(e.target.checked);

    // setValues1((data) =>
    //   data?.map((list, index) => {
    //     list.excuse = e.target.checked;
    //   })
    // );
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <FormHelperText error>
          {values1Error ? "Select atleast 1 student" : " "}
        </FormHelperText>

        <TableContainer>
          <Table sx={{ minWidth: 600 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Sr. No.</TableCell>
                <TableCell align="center">Student Name</TableCell>
                <TableCell align="center">Roll No</TableCell>
                <TableCell align="center">PRN Number</TableCell>
                <TableCell align="center">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"Course"}
                          onChange={(e) => attendanceChangeHandler(e)}
                          checked={present}
                          sx={{
                            color: pink[800],
                            "&.Mui-checked": {
                              color: pink[600],
                            },
                            padding: "0px",
                          }}
                        />
                      }
                      label={"Present"}
                      labelPlacement="top"
                    />
                  </FormGroup>
                </TableCell>
                <TableCell align="center">
                  Excuse
                  <br />
                </TableCell>
                <TableCell align="center">Remark</TableCell>
              </TableRow>
            </TableHead>
            {console.log(values1)}
            <TableBody>
              {values1.map((data) => {
                return (
                  <TableRow sx={{}}>
                    <TableCell align="center">{data.index}</TableCell>
                    <TableCell align="left">{data.name}</TableCell>
                    <TableCell align="center">{data.rollNo}</TableCell>
                    <TableCell align="center">{data.prnNo}</TableCell>
                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"attendance"}
                              checked={data.attendance}
                            />
                          }
                          onChange={(e) =>
                            changeHandler1(e, data.userRegistrationId)
                          }
                          value={data.attendance}
                          labelPlacement="bottom"
                          disabled={data.excuse}
                        />
                      </FormGroup>
                    </TableCell>

                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox name={"excuse"} checked={data.excuse} />
                          }
                          onChange={(e) =>
                            changeHandler2(e, data.userRegistrationId)
                          }
                          value={data.excuse}
                          labelPlacement="bottom"
                          disabled={data.attendance}
                        />
                      </FormGroup>
                    </TableCell>

                    <TableCell>
                      <FormGroup>
                        <TextField
                          color="primary"
                          name={"Percentage"}
                          size="small"
                          value={data.answer}
                          disabled={data.attendance}
                          onChange={(e) => changeHandler3(e, data.id)}
                          sx={{ width: 150 }}
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          xs={12}
          sm={12}
          md={12}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={2}
            xs={12}
            sm={4}
            md={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Total Student : "
              />
              {valuesLength === "" ? 0 : valuesLength}
            </Grid>

            <Grid item xs={12} sm={12} md={12}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={2}
            xs={12}
            sm={4}
            md={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Present Student : "
              />
              {presentCount === "" ? 0 : presentCount}
            </Grid>

            <Grid item xs={12} sm={12} md={12}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={4}
            md={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Absent Student : "
              />
              {absentCount === "" ? 0 : absentCount}
            </Grid>

            <Grid item xs={12} sm={12} md={12}></Grid>
          </Grid>
        </Grid>
        <br />

        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          xs={12}
          sm={12}
          md={12}
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Back"
              onClick={backToList}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Save and Next"
              onClick={saveHandler}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
  teachingPlanList: state.teachingPlan,
});
const mapDispatchToProps = {
  showNotification,
  getStudentAttendanceList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAttendanceForm);
