import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import {
  onlyNumber,
  validEmail,
} from "../../../components/Comman/Util/Validations";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost, apiGet } from "../../../utils/api_service";
import { getEmployeeByDeptId } from "../../EmployeeDetails/employee.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getClass } from "../../Masters/OnlineModule/Class/class.slice";
import { getStudentListByYear } from "../../RollNoList/GenrateRollNo/GenerateRollNo.slice";
import {
  getDeemedCommitteeMember,
  getDeemedCommitteeMemberWithStatus,
} from "./committee.slice";
import EventCommitteeTable from "./memberTable";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import moment from "moment";
let docs;
class MemberForm extends Component {
  state = {
    id: 0,
    eventName: "",
    memberName: "",
    committeeMember: "",
    email: "",
    contactNo: "",
    memberDesignation: "",
    designation: "",
    sequenceNo: "",
    yearDetailId: "",
    student: "",
    fromDate: "",
    toDate: "",
    status: 1,
    employeeDepartment: "",
    employee: "",
    showFlag: true,
    employeeList: [],
    studentList: [],
    formErrors: [],
    open: false,
    hideYearAndStudent: false,
  };

  componentDidMount() {
    const { committeeData, getAlert } = this.props;
    if (committeeData !== "") {
      this.setState({
        showFlag: committeeData.flag === 1 ? true : false,
      });
    }
    this.props.getClass().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getDepartment().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    this.props
      .getDeemedCommitteeMember({ id: committeeData.id })
      .then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
  }

  changeHandlerForNumber = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  checkMemberDesignation = (event) => {
    const { memberDesignation } = this.state;
    let lowerCaseName = memberDesignation.toLowerCase();

    let formErrors = {};
    let formIsValid = true;
    if (lowerCaseName === "chairperson") {
      this.setState({
        ...this.state,
        memberDesignation: "",
      });
    }
    if (lowerCaseName === "chairperson") {
      formIsValid = false;
      formErrors["memberDesignationError"] = "Designtion....!!!!!";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === "contactNo") {
      if (onlyNumber.test(value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleFormValidation() {
    const {
      memberName,
      memberDesignation,
      email,
      sequenceNo,
      contactNo,
      employeeDepartment,
      employee,
      yearDetailId,
      student,
      committeeMember,
      designation,
      fromDate,
      id,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (committeeMember === "student") {
      if (id == 0) {
        if (!yearDetailId.length) {
          formIsValid = false;
          formErrors["yearDetailIdError"] = myConstClass.yearDetailMsg;
        }
        if (!student.length) {
          formIsValid = false;
          formErrors["studError"] = myConstClass.studMsg;
        }
      }
      if (sequenceNo === "") {
        formIsValid = false;
        formErrors["sequenceNoError"] = myConstClass.sequenceNoMsg;
      }
      if (memberDesignation === "") {
        formIsValid = false;
        formErrors["memberDesignationError"] =
          myConstClass.memberDesignationMsg;
      }
      if (designation === "") {
        formIsValid = false;
        formErrors["fromDateError"] = myConstClass.fromDateMsg;
      }
      // if (fromDate === "") {
      //   formIsValid = false;
      //   formErrors["designationError"] = myConstClass.designationMsg;
      // }
    }
    if (committeeMember === "teacher") {
      if (id == 0) {
        if (!employeeDepartment.length) {
          formIsValid = false;
          formErrors["empDeptError"] = myConstClass.departmentMsg;
        }
        if (!employee.length) {
          formIsValid = false;
          formErrors["empError"] = myConstClass.employeemsg;
        }
      }
      if (sequenceNo === "") {
        formIsValid = false;
        formErrors["sequenceNoError"] = myConstClass.sequenceNoMsg;
      }
      if (memberDesignation === "") {
        formIsValid = false;
        formErrors["memberDesignationError"] =
          myConstClass.memberDesignationMsg;
      }
      if (designation === "") {
        formIsValid = false;
        formErrors["designationError"] = myConstClass.designationMsg;
      }
      // if (fromDate === "") {
      //   formIsValid = false;
      //   formErrors["designationError"] = myConstClass.designationMsg;
      // }
    }
    if (committeeMember === "other") {
      if (memberName === "") {
        formIsValid = false;
        formErrors["memberNameError"] = myConstClass.memberNameMsg;
      }
      if (memberDesignation === "") {
        formIsValid = false;
        formErrors["memberDesignationError"] =
          myConstClass.memberDesignationMsg;
      }
      if (designation === "") {
        formIsValid = false;
        formErrors["designationError"] = myConstClass.designationMsg;
      }
      if (contactNo === "" || contactNo.length != 10) {
        if (contactNo === "") {
          formIsValid = false;
          formErrors["contactNoError"] = myConstClass.contactNoMsg;
        } else if (!onlyNumber.test(contactNo)) {
          formIsValid = false;
          formErrors["contactNoError"] = myConstClass.contactNumberMsg;
        } else if (contactNo.length > 0 && contactNo.length != 10) {
          formIsValid = false;
          formErrors["contactNoError"] = myConstClass.contactNoLengthMsg;
        }
      }
      if (email === "" || !validEmail.test(email)) {
        if (email === "") {
          formIsValid = false;
          formErrors["emailError"] = myConstClass.emailIdMsg;
        } else if (!validEmail.test(email)) {
          formIsValid = false;
          formErrors["emailError"] = myConstClass.invalidEmailIdMsg;
        }
      }
      if (sequenceNo === "") {
        formIsValid = false;
        formErrors["sequenceNoError"] = myConstClass.sequenceNoMsg;
      }
      // if (fromDate === "") {
      //   formIsValid = false;
      //   formErrors["designationError"] = myConstClass.designationMsg;
      // }
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      memberName,
      memberDesignation,
      email,
      contactNo,
      sequenceNo,
      student,
      employee,
      committeeMember,
      designation,
      status,
      fromDate,
      toDate,
    } = this.state;
    if (committeeMember === "other") {
      docs = {
        committee: {
          id: this.props.committeeData.id,
        },
        memberName: memberName,
        memberDesignation: memberDesignation,
        emailId: email,
        contactNo: contactNo,
        sequenceNo: sequenceNo,
        designation: designation,
        joiningDate: moment(fromDate).format("DD-MM-YYYY"),
        leavingDate: toDate != "" ? moment(toDate).format("DD-MM-YYYY") : "",
        status: status,
      };
      if (id != 0) {
        docs = {
          id: id,
          ...docs,
        };
      }
      if (this.handleFormValidation()) {
        this.onSave(docs);
      }
    } else {
      docs = {
        committee: {
          id: this.props.committeeData.id,
        },
        userRegistration: {
          id:
            committeeMember == "student"
              ? student
              : committeeMember == "teacher"
              ? employee
              : "",
        },
        memberName: memberName,
        memberDesignation: memberDesignation,
        emailId: email,
        contactNo: contactNo,
        sequenceNo: sequenceNo,
        designation: designation,
        joiningDate: moment(fromDate).format("DD-MM-YYYY"),
        leavingDate: toDate != "" ? moment(toDate).format("DD-MM-YYYY") : "",
        status: status,
      };
      if (id != 0) {
        docs = {
          id: id,
          ...docs,
        };
      }
      if (this.handleFormValidation()) {
        this.onSave(docs);
      }
    }
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.deemedCommittee + "-member",
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.setState({
          memberName: "",
          memberDesignation: "",
          email: "",
          contactNo: "",
          sequenceNo: "",
          yearDetailId: "",
          student: "",
          employeeDepartment: "",
          employee: "",
          designation: "",
          fromDate: "",
          toDate: "",
          hideYearAndStudent: false,
        });
        const { getDeemedCommitteeMember, committeeData } = this.props;
        const { id } = this.state;
        getDeemedCommitteeMember({ id: committeeData.id }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoint.deemedCommittee + "-member/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data deleted successfully",
            });
            this.props
              .getDeemedCommitteeMember({ id: this.props.committeeData.id })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            this.props.getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };

  rowEdit = (rowData) => {
    console.log(rowData);
    let joiningDate = "";
    let leavingDate = "";
    if (rowData.joiningDate != null) {
      const joiningDateArr = rowData.joiningDate.split("-");
      joiningDate = new Date(
        joiningDateArr[2],
        joiningDateArr[1] - 1,
        joiningDateArr[0]
      );
    }

    if (rowData.leavingDate != null) {
      const leavingDateArr = rowData.leavingDate.split("-");
      leavingDate = new Date(
        leavingDateArr[2],
        leavingDateArr[1] - 1,
        leavingDateArr[0]
      );
    }
    if (committeeMember === "other") {
      this.setState({
        id: rowData.id,
        memberName: rowData.memberName,
        memberDesignation: rowData.memberDesignation,
        email: rowData.emailId,
        contactNo: rowData.contactNo,
        sequenceNo: rowData.sequenceNo,
        designation: rowData.designation,
        fromDate: joiningDate ? joiningDate : "",
        toDate: leavingDate ? leavingDate : "",
        hideYearAndStudent: true,
      });
    } else {
      this.setState({
        id: rowData.id,
        memberName: rowData.memberName,
        memberDesignation: rowData.memberDesignation,
        email: rowData.emailId,
        contactNo: rowData.contactNo,
        sequenceNo: rowData.sequenceNo,
        designation: rowData.designation,
        fromDate: joiningDate ? joiningDate : "",
        toDate: leavingDate ? leavingDate : "",
        student:
          rowData.userRegistration != null ? rowData.userRegistration.id : "",
        employee:
          rowData.userRegistration != null ? rowData.userRegistration.id : "",
        hideYearAndStudent: true,
      });
    }
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.changeStatus(rowData);
      }
    });
  };

  changeStatus = (rowdata) => {
    // this.setState({
    //   isLoading: true,
    // });
    console.log(rowdata);
    const isActive = rowdata.status == 1 ? 0 : 1;
    apiGet({
      url:
        endpoint.deemedCommittee +
        "-member" +
        "/change-status?id=" +
        rowdata.id +
        "&status=" +
        isActive,
    }).then(({ success }) => {
      if (success) {
        // this.setState({
        //   isLoading: false,
        // });
        this.props.showNotification({ msg: "Status updated successfully" });
        this.props
          .getDeemedCommitteeMember({ id: this.props.committeeData.id })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to Change Status",
        });
        // this.setState({
        //   isLoading: false,
        // });
      }
    });
  };

  changeHandlers = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "yearDetailId") {
        let bData = [];
        this.props
          .getStudentListByYear({ yearDetail: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              if (
                this.props.deemedCommitteeList.deemedCommitteeMember.length !==
                0
              ) {
                this.props.studentList?.studentListByYear.map((data) => {
                  let rowList1 =
                    this.props.deemedCommitteeList.deemedCommitteeMember.filter(
                      (row) =>
                        row.userRegistration !== null &&
                        row.userRegistration.id === data.id
                    );
                  if (rowList1.length === 0) {
                    bData.push(data);
                  }
                });
                this.setState({ studentList: bData });
              } else {
                this.setState({
                  studentList: this.props.studentList?.studentListByYear,
                });
              }
            }
          });
      }
      if (name === "employeeDepartment") {
        let bData = [];
        this.props
          .getEmployeeByDeptId({ departmentId: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              console.log(this.props.deemedCommitteeList.deemedCommitteeMember);
              if (
                this.props.deemedCommitteeList.deemedCommitteeMember.length !==
                0
              ) {
                this.props.employeeList?.employeeList.map((data) => {
                  let rowList1 =
                    this.props.deemedCommitteeList.deemedCommitteeMember.filter(
                      (row) =>
                        row.userRegistration !== null &&
                        row.userRegistration.id === data.id
                    );
                  if (rowList1.length === 0) {
                    bData.push(data);
                  }
                });
                this.setState({ employeeList: bData });
              } else {
                this.setState({
                  employeeList: this.props.employeeList?.employeeList,
                });
              }
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };
  onClickRead = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };
  render() {
    const {
      eventName,
      memberName,
      memberDesignation,
      email,
      contactNo,
      sequenceNo,
      committeeMember,
      yearDetailId,
      student,
      employeeDepartment,
      employee,
      employeeList,
      studentList,
      showFlag,
      open,
      designation,
      fromDate,
      toDate,
    } = this.state;

    const { onCancel, committeeData } = this.props;

    const {
      memberNameError,
      memberDesignationError,
      sequenceNoError,
      emailError,
      contactNoError,
      empDeptError,
      empError,
      yearDetailIdError,
      studError,
      designationError,
      fromDateError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label={""}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="right"
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={onCancel}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18 }}
                    label="Committee Name : "
                  />{" "}
                  {committeeData.name}
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                sx={{ mt: 1, mb: -1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18 }}
                    label="Committee Details : "
                  />
                  {/* {committeeData.details} */}

                  {!open &&
                  committeeData.details.length >
                    committeeData.details.slice(0, 70).length
                    ? committeeData.details.slice(0, 70) + " ... "
                    : committeeData.details.slice(0, 70)}
                  {!open &&
                    committeeData.details !==
                      committeeData.details.slice(0, 70) && (
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        label={" Read more"}
                        onClick={() => this.onClickRead()}
                      />
                    )}
                  {open && committeeData.details}
                  {open &&
                    committeeData.details.length >
                      committeeData.details.slice(0, 70).length && (
                      <LabelCompo
                        className="text-black"
                        style={{
                          fontSize: 16,
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        label={"show less"}
                        onClick={() => this.onClickRead()}
                      />
                    )}
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              ></Grid>
              {showFlag && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{ mt: 3 }}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Committee Member"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className="text-black"
                            name="committeeMember"
                            id="committeeMember"
                            value={committeeMember}
                            onChange={this.changeHandlers}
                            size="small"
                            fullWidth={true}
                          >
                            <MenuItem value="student">Student</MenuItem>
                            <MenuItem value="teacher">Teacher</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    ></Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  ></Grid>
                  <br />
                  {committeeMember == "other" && (
                    <>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Member Name "
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="memberName"
                              size="small"
                              value={memberName}
                              onChange={this.changeHandler}
                              fullWidth
                              error={memberNameError ? true : false}
                              helperText={
                                memberNameError ? memberNameError : " "
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Designation"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="designation"
                              size="small"
                              value={designation}
                              onChange={this.changeHandler}
                              fullWidth
                              error={designationError ? true : false}
                              helperText={
                                designationError ? designationError : " "
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Committee Designation"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="memberDesignation"
                              size="small"
                              value={memberDesignation}
                              onChange={this.changeHandler}
                              fullWidth
                              error={memberDesignationError ? true : false}
                              helperText={
                                memberDesignationError
                                  ? memberDesignationError
                                  : " "
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Email"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="email"
                              size="small"
                              value={email}
                              onChange={this.changeHandler}
                              fullWidth
                              error={emailError ? true : false}
                              helperText={emailError ? emailError : " "}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          {/* dec */}
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Joining Date "
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <BasicDatePicker
                              color="primary"
                              name="fromDate"
                              size="small"
                              value={fromDate}
                              fullWidth
                              onChange={this.onDateChange("fromDate")}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="Leaving Date  "
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <BasicDatePicker
                              color="primary"
                              name="toDate"
                              size="small"
                              value={toDate}
                              minDate={fromDate}
                              fullWidth
                              onChange={this.onDateChange("toDate")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        spacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={6}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Contact No"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="contactNo"
                              size="small"
                              value={contactNo}
                              inputProps={{ maxLength: 10 }}
                              onChange={this.changeHandler}
                              fullWidth
                              error={contactNoError ? true : false}
                              helperText={contactNoError ? contactNoError : " "}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Sequence No"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="sequenceNo"
                              size="small"
                              value={sequenceNo}
                              onChange={this.changeHandlerForNumber}
                              fullWidth
                              error={sequenceNoError ? true : false}
                              helperText={
                                sequenceNoError ? sequenceNoError : " "
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {committeeMember == "student" && (
                    <>
                      {!this.state.hideYearAndStudent ? (
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          container
                          spacing={1}
                          justifyContent="space-evenly"
                        >
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="*Year Detail "
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <AutoComplete
                                keyColName={"id"}
                                value={yearDetailId}
                                name="yearDetailId"
                                options={this.props.yearDetail?.class}
                                onChange={this.ChangeHandlerSearch}
                                isError={yearDetailIdError ? true : false}
                                errorText={
                                  yearDetailIdError ? yearDetailIdError : " "
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="*Student"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <AutoComplete
                                keyColName={"id"}
                                value={student}
                                name="student"
                                options={studentList}
                                onChange={this.ChangeHandlerSearch}
                                isError={studError ? true : false}
                                errorText={studError ? studError : " "}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            spacing={1}
                          >
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, marginLeft: 5 }}
                              label={"Student : " + memberName}
                            />
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            spacing={1}
                          />
                        </>
                      )}

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          {/* dec */}
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Joining Date "
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <BasicDatePicker
                              color="primary"
                              name="fromDate"
                              size="small"
                              value={fromDate}
                              fullWidth
                              onChange={this.onDateChange("fromDate")}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="Leaving Date  "
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <BasicDatePicker
                              color="primary"
                              name="toDate"
                              size="small"
                              value={toDate}
                              minDate={fromDate}
                              fullWidth
                              onChange={this.onDateChange("toDate")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Designation"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="designation"
                              size="small"
                              value={designation}
                              onBlur={this.checkMemberDesignation}
                              onChange={this.changeHandler}
                              fullWidth
                              error={designationError ? true : false}
                              helperText={
                                designationError ? designationError : " "
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Committee Designation"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="memberDesignation"
                              size="small"
                              value={memberDesignation}
                              onBlur={this.checkMemberDesignation}
                              onChange={this.changeHandler}
                              fullWidth
                              error={memberDesignationError ? true : false}
                              helperText={
                                memberDesignationError
                                  ? memberDesignationError
                                  : " "
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Sequence No"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="sequenceNo"
                              size="small"
                              value={sequenceNo}
                              onChange={this.changeHandlerForNumber}
                              fullWidth
                              error={sequenceNoError ? true : false}
                              helperText={
                                sequenceNoError ? sequenceNoError : " "
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        ></Grid>
                      </Grid>
                    </>
                  )}
                  {committeeMember == "teacher" && (
                    <>
                      {!this.state.hideYearAndStudent ? (
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          container
                          spacing={1}
                          justifyContent="space-evenly"
                        >
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="*Department "
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <AutoComplete
                                keyColName={"id"}
                                value={employeeDepartment}
                                name="employeeDepartment"
                                options={this.props.departmentList?.department.filter(
                                  (data) => data.isActive == 1
                                )}
                                onChange={this.ChangeHandlerSearch}
                                isError={empDeptError ? true : false}
                                errorText={empDeptError ? empDeptError : " "}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="*Name of the member"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <AutoComplete
                                keyColName={"id"}
                                value={employee}
                                name="employee"
                                options={employeeList}
                                onChange={this.ChangeHandlerSearch}
                                isError={empError ? true : false}
                                errorText={empError ? empError : " "}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            spacing={1}
                          >
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, marginLeft: 5 }}
                              label={"Name of the member : " + memberName}
                            />
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            spacing={1}
                          />
                        </>
                      )}

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          {/* dec */}
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Joining Date "
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <BasicDatePicker
                              color="primary"
                              name="fromDate"
                              size="small"
                              value={fromDate}
                              fullWidth
                              onChange={this.onDateChange("fromDate")}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="Leaving Date  "
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <BasicDatePicker
                              color="primary"
                              name="toDate"
                              size="small"
                              value={toDate}
                              minDate={fromDate}
                              fullWidth
                              onChange={this.onDateChange("toDate")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Designation"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="designation"
                              size="small"
                              value={designation}
                              onBlur={this.checkMemberDesignation}
                              onChange={this.changeHandler}
                              fullWidth
                              error={designationError ? true : false}
                              helperText={
                                designationError ? designationError : " "
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Committee Designation"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="memberDesignation"
                              size="small"
                              value={memberDesignation}
                              onChange={this.changeHandler}
                              onBlur={this.checkMemberDesignation}
                              fullWidth
                              error={memberDesignationError ? true : false}
                              helperText={
                                memberDesignationError
                                  ? memberDesignationError
                                  : " "
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        spacing={1}
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Sequence No"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="sequenceNo"
                              size="small"
                              value={sequenceNo}
                              onChange={this.changeHandlerForNumber}
                              fullWidth
                              error={sequenceNoError ? true : false}
                              helperText={
                                sequenceNoError ? sequenceNoError : " "
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        ></Grid>
                      </Grid>
                    </>
                  )}

                  <Grid container justifyContent="center" sx={{ mt: 2 }}>
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Add Committee Member"
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
                <EventCommitteeTable
                  row={this.props.deemedCommitteeList.deemedCommitteeMember}
                  rowDelete={this.rowDelete}
                  rowEdit={this.rowEdit}
                  changeStatus={this.onChangeStatus}
                  committeeData={this.props.committeeData}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  deemedCommitteeList: state.deemedCommittee,
  employeeList: state.employeeList,
  departmentList: state.department,
  studentList: state.rollNoList,
  yearDetail: state.class,
});
const mapDispatchToProps = {
  getClass,
  getStudentListByYear,
  getAlert,
  getDepartment,
  getEmployeeByDeptId,
  showNotification,
  getDeemedCommitteeMember,
  getDeemedCommitteeMemberWithStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberForm);
