import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
const initialState = {
  companyListStatus: [],
  interestedStudentListSuccess: [],
  notInterestedStudentListSuccess: [],
  changeStatusList: [],
  getInterestedStudentByIdStatus: [],
  getInterestedStudByIdStatus: [],
  getInterestedStudByIdStatusIsFetchStatus: false,
  getInterestedStudentByIdStatusIsFetchStatus: false,
  changeStatusFetchStatus: false,
  notIntrestedStudentIsFetch: false,
  isFetchStatus: false,
  intrestedStudentIsFetch: false,
};

let URL = endpoints.internshipDeclare;
let URL1 = endpoints.intrestedAndNotIntrestedStudent;
let URL2 = endpoints.internshipIntrest;

const companyListSlice = createSlice({
  name: "companyList",
  initialState,
  reducers: {
    companyListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        companyListStatus: row,
        isFetchStatus: true,
      };
    },
    getInterestedStudentByIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        getInterestedStudentByIdStatus: row,
        getInterestedStudentByIdStatusIsFetchStatus: true,
      };
    },
    getInterestedStudByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        getInterestedStudByIdStatus: row,
        getInterestedStudByIdStatusIsFetchStatus: true,
      };
    },
    resetInterestedStudent: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        getInterestedStudByIdStatus: [],
        getInterestedStudByIdStatusIsFetchStatus: false,
      };
    },

    changeStatusSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        changeStatusList: data,
        changeStatusFetchStatus: true,
      };
    },

    interestedStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        interestedStudentListSuccess: row,
        intrestedStudentIsFetch: true,
      };
    },
    notInterestedStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        notInterestedStudentListSuccess: row,
        notIntrestedStudentIsFetch: true,
      };
    },

    admissionListFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        documentVerificationDataList: [],
        isFetchDocumentVerificationDataList: false,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },

    resetTableListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        companyListStatus: [],
        isFetchStatus: false,
      };
    },
  },
});

export const {
  resetInterestedStudent,
  companyListSuccess,
  getInterestedStudentByIdSuccess,
  getInterestedStudByIdSuccess,
  changeStatusSuccess,
  interestedStudentSuccess,
  notInterestedStudentSuccess,
  resetSuccess,
  resetTableListSuccess,
} = companyListSlice.actions;

export default companyListSlice.reducer;

export const getAllCompanyList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}&offset=0&limit=20',
    }).then(({ data, success }) => {
      if (success) {
        const companyList = data.data.content;
        let row = [];
        let index = 0;
        row = companyList.map((data) => {
          index = index + 1;
          let companyData = {
            index: index,
            internshipDeclareId: data.id,
            companyId: data.company.id,
            companyName: data.company.name,
            companyAddress: data.company.address,
            venue: data.venue == "" || null ? "-" : data.venue,
            durationFromDate: data.durationFromDate,
            durationToDate: data.durationToDate,
            date: data.declareDate,
            isActive: data.isActive,
            yearDetailId: data.yearDetailId,
            divisionId: data.divisionId,
            stipend: data.stipend === null ? "" : data.stipend,
            designation: data.designation === "" ? "" : data.designation,
            internshipType: data.internshipType.id,
            remark: data.remark,
            mathsCriteria: data.mathsCriteria,
            gapInYears: data.backLog == null ? 0 : data.backLog,
          };
          return companyData;
        });
        dispatch(companyListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCompanyList =
  ({ fromDate, toDate, internType }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL +
          "/by-intern-type?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&internshipType=" +
          internType,
      }).then(({ data, success }) => {
        if (success) {
          const companyList = data.data;
          // let index = 0;
          let row = [];
          let index = 0;
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              internshipDeclareId: data.id,
              companyId: data.company.id,
              companyName: data.company.name,
              companyAddress: data.company.address,
              venue: data.venue,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              date: data.declareDate,
              isActive: data.isActive,
              yearDetailId: data.yearDetailId,
              divisionId: data.divisionId,
              stipend: data.stipend === null ? "" : data.stipend,
              designation: data.designation === "" ? "" : data.designation,
              internshipType: data.internshipType.id,
              remark: data.remark,
              mathsCriteria: data.mathsCriteria,
              gapInYears: data.backLog == null ? 0 : data.backLog,
            };
            return companyData;
          });
          dispatch(companyListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInterestedStudentById =
  ({ userRegistrationId, status }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL2 +
          "/get-intrested-student-by-user-id?userRegistrationId=" +
          userRegistrationId +
          "&status=" +
          status,
      }).then(({ data, success }) => {
        if (success) {
          const companyList = data.data;
          let index = 0;
          let row = [];
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              //     internshipDeclareId: data.id,
              //     companyId: data.company.id,
              id: data.id,
              companyName: data.companyName,
              companyAddress: data.companyAdd,
              internshipIntrestedStudentDocument:
                data.internshipIntrestedStudentDocument.map(
                  (docData, index) => {
                    let docDataDetails = {
                      index: index + 1,
                      id: docData.id,
                      name: docData.docName,
                      fileName: docData.fileName,
                    };
                    return docDataDetails;
                  }
                ),
              //     venue: data.venue,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              //     date:data.declareDate,
              //     status: data.isActive,
              //     yearDetailId: data.yearDetailId,
              //     // designationId: data.designationId,
              stipend: data.stipend,
              designation: data.designation ? data.designation : "-",
              status:
                data.selectedStatus == 1
                  ? "Selected"
                  : data.selectedStatus == 0
                  ? "Not Selected"
                  : data.selectedStatus == 0
                  ? "Completed"
                  : "",
              internshipType: data.internshipType.id,
              remark: data.remark,
            };
            return companyData;
          });
          dispatch(getInterestedStudentByIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInterestedStudById =
  ({ id }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url: URL2 + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          const getStudById = data.data;
          dispatch(getInterestedStudByIdSuccess({ row: getStudById }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const changeInternshipDeclareStatus =
  ({ internshipDeclareId, status }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/api/placement/v1/internship-declare/change-active-status?id=44d8e490-d807-40af-8c07-47bd10af603c&status=0
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL +
          "/change-active-status?id=" +
          internshipDeclareId +
          "&status=" +
          status,
      }).then(({ data, success }) => {
        if (success) {
          dispatch(changeStatusSuccess({ data }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getIntrestedStudentList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/by-internship-declare?intrestStatus=1",
      }).then(({ data, success }) => {
        if (success) {
          const studentList = data.data;
          // let index = 0;
          let row = [];
          let index = 0;
          row = studentList.map((data) => {
            index = index + 1;
            let studentData = {
              index: index,
              id: data.id,
              lastName:
                data.getUserRegistration != null
                  ? data.getUserRegistration.lastName
                  : null,
              firstName:
                data.getUserRegistration != null
                  ? data.getUserRegistration.firstName
                  : null,
              fatherName:
                data.getUserRegistration != null
                  ? data.getUserRegistration.fatherName
                  : null,
              motherName:
                data.getUserRegistration != null
                  ? data.getUserRegistration.motherName
                  : null,
              middleName:
                data.getUserRegistration != null
                  ? data.getUserRegistration.middleName
                  : null,
              name:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.lastName.toUpperCase() +
                    " " +
                    data.getUserRegistration.firstName.toUpperCase() +
                    " " +
                    (data.getUserRegistration.fatherName == null ||
                    data.getUserRegistration.fatherName == ""
                      ? " - "
                      : data.getUserRegistration.fatherName.toUpperCase()) +
                    " " +
                    (data.getUserRegistration.motherName == null ||
                    data.getUserRegistration.motherName == ""
                      ? " - "
                      : data.getUserRegistration.motherName.toUpperCase()),
              division:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.division.name,
              rollNo:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.rollNo,
              // + " " + data.getReportingDetail.getYearDetail,
              intrestedStatus: data.intrestStatus,
              selectedStatus: data.selectedStatus,
              userRegistrationId:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.id,
              companyName: data.companyName === null ? "" : data.companyName,
              designation: data.designation === null ? "" : data.designation,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              stipend: data.stipend,
              remark:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.remark,
              internshipIntrestedStudentDocument:
                data.internshipIntrestedStudentDocument.map(
                  (docData, index) => {
                    let docDataDetails = {
                      index: index + 1,
                      id: docData.id,
                      name: docData.docName,
                      fileName: docData.fileName,
                    };
                    return docDataDetails;
                  }
                ),
            };
            return studentData;
          });
          dispatch(interestedStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getNotIntrestedStudentList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/by-internship-declare?intrestStatus=0",
      }).then(({ data, success }) => {
        if (success) {
          const studentList = data.data;
          // let index = 0;
          let row = [];
          let index = 0;
          row = studentList.map((data) => {
            index = index + 1;
            let studentData = {
              index: index,
              id: data.id,
              lastName:
                data.getUserRegistration != null &&
                data.getUserRegistration.lastName,
              firstName:
                data.getUserRegistration != null &&
                data.getUserRegistration.firstName,
              fatherName:
                data.getUserRegistration != null &&
                data.getUserRegistration.fatherName,
              motherName:
                data.getUserRegistration != null &&
                data.getUserRegistration.motherName,
              middleName:
                data.getUserRegistration != null &&
                data.getUserRegistration.middleName,
              name:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.lastName.toUpperCase() +
                    " " +
                    data.getUserRegistration.firstName.toUpperCase() +
                    " " +
                    (data.getUserRegistration.fatherName == null ||
                    data.getUserRegistration.fatherName == ""
                      ? " - "
                      : data.getUserRegistration.fatherName.toUpperCase()) +
                    " " +
                    (data.getUserRegistration.motherName == null ||
                    data.getUserRegistration.motherName == ""
                      ? " - "
                      : data.getUserRegistration.motherName.toUpperCase()),
              division:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.division.name,
              rollNo:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.rollNo,
              // + " " + data.getReportingDetail.getYearDetail,
              intrestedStatus: data.intrestStatus,
              selectedStatus: data.selectedStatus,
              userRegistrationId:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.id,
              companyName: data.companyName === null ? "" : data.companyName,
              designation: data.designation === null ? "" : data.designation,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              stipend: data.stipend,
              remark:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.remark,
              internshipIntrestedStudentDocument:
                data.internshipIntrestedStudentDocument.map(
                  (docData, index) => {
                    let docDataDetails = {
                      index: index + 1,
                      id: docData.id,
                      name: docData.docName,
                      fileName: docData.fileName,
                    };
                    return docDataDetails;
                  }
                ),
            };
            return studentData;
          });
          dispatch(notInterestedStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetTableList = () => async (dispatch) => {
  try {
    dispatch(resetTableListSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
