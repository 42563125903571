import { fabClasses } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import Alert from "../../../Alert/Alert";
import { DirectionSnackbar } from "../../../components/Comman/SnakBarCompo";
import { Loading1 } from "../../../components/Loading1";
import * as myConstClass from "../../../config/messageconstant";
// import ProgMView from "./ProgMView";
import {
  checkEmailById,
  getGenerateOTP,
  getStudentRegister,
  getValidateOtp,
  resetDelete,
  resetError,
  saveStudentRegister,
} from "./AluminiRegister.slice";
import AlumniRegisterForm from "./AluminiBasicDetail";

const AlumniRegister = ({
  saveStudentRegister,
  checkEmailById,
  getGenerateOTP,
  getValidateOtp,
  studentRegData,
  resetError,
  resetDelete,
}) => {
  const navigate = useNavigate();
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [enterOtp, setEnterOtp] = useState();
  const [regUser, setRegUser] = useState({});
  const [openSnak, setOpenSnak] = useState(false);
  const [openSuccess, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [loader, setLoader] = useState(false);

  const studentRegisterData = (instData) => {
    setRegUser(instData);
    setLoader(true);
    console.log(instData);
    var emailString = instData.alumniEmail;
    var mobileString = instData.alumniMobile;
    console.log(mobileString.slice(5, 10));
    var name = emailString.substring(0, emailString.lastIndexOf("@"));
    console.log(name);
    var mailArr = emailString.split("@");
    var logn = mailArr[0];
    console.log(logn);
    var mailHost = mailArr[1];
    console.log(mailHost);
    var last3 = logn.slice(-4);
    console.log(last3);
    saveStudentRegister({ studentRegisterData: instData }).then((success) => {
      if (success) {
        setLoader(false);

        swal
          .fire({
            icon: "success",
            title: "Registered Successfully",
            text:
              "  Your User id has been sent to your registered Email id XXXX" +
              last3 +
              "@" +
              mailHost,
            showConfirmButton: true,
            confirmButtonColor: "#1f89f4",
            allowOutsideClick: false,
            confirmButtonText: "Login",
          })
          .then((okLogin) => {
            if (okLogin.isConfirmed) {
              navigate("/login");
            }
          });
      } else {
        setSnakMsg(myConstClass.saveFailMsg);
        setSuccess("error");
        setLoader(false);
      }
    });
  };
  const studentData = (studentRegisterData) => {
    setRegUser(studentRegisterData);

    setLoader(true);

    checkEmailById({ emailId: studentRegisterData.user.emailId }).then(
      (response) => {
        setOpenSnak(true);

        if (response) {
          if (response.data.data.error == true) {
            setSnakMsg(response.data.data.message);
            setSuccess("error");
            setLoader(false);
          } else {
            setSnakMsg(myConstClass.emailValMsg);
            setSuccess("success");
            setLoader(false);
          }
        }
      }
    );

    setEditRow([]);
    setLoader(false);
  };

  const generateData = (emailId, contactNo) => {
    setLoader(true);
    getGenerateOTP({ emailId: emailId, contactNo: contactNo }).then(
      (succsss) => {
        setOpenSnak(true);
        if (succsss) {
          setLoader(false);
          setSnakMsg(myConstClass.otpSentSuccessfull);
          setSuccess("success");
        } else {
          setLoader(false);
          setSnakMsg(myConstClass.otpNotGenerateMsg);
          setSuccess("error");
        }
      }
    );
    setEditRow([]);
    setLoader(false);
  };

  const validateData = (enterOtp) => {
    setEnterOtp(enterOtp);
    setLoader(true);
    getValidateOtp({ otp: enterOtp }).then((success) => {
      setOpenSnak(true);
      if (success) {
        setSnakMsg(myConstClass.otpValidMsg);
        setSuccess("success");
        setLoader(false);
      } else {
        setSnakMsg(myConstClass.otpInvalidMsg);
        setSuccess("error");
        setLoader(false);
      }
    });
    setEditRow([]);
    setLoader(false);
  };
  const stopEdit = () => {
    navigate("/login");
    setEditRow([]);
  };

  return (
    <>
      {!studentRegData.isServerFail && (
        <DirectionSnackbar
          open={openSnak}
          severity={openSuccess}
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      )}
      {studentRegData.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )}

      {""}
      <AlumniRegisterForm
        OnSave={studentRegisterData}
        saveNext={studentData}
        saveMobile={generateData}
        submitOtp={validateData}
        isEdit={editRow}
        onCancel={stopEdit}
      />
      {/* {login && open && <ProgMView openparam={open} closeView={setOpen} />} */}
      {loader && <Loading1 size={50} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  studentRegData: state.alumniRegister,
  common: state.common,
});

const mapDispatchToProps = {
  saveStudentRegister,
  checkEmailById,
  getStudentRegister,
  getGenerateOTP,
  getValidateOtp,
  resetError,
  resetDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlumniRegister);
