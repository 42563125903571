import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import * as myConstClass from "../../../config/messageconstant";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import swal from "sweetalert";
import { onlyNumber } from "../../../components/Comman/Util/Validations";
import { saveTimeTableDetail } from "../../TimeTable/GenerateTimeTable/generateTimeTable.slice";
import { Grid4x4 } from "@mui/icons-material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddQuestion = ({
  open,
  feedBack,
  handleCloses,
  onSave,
  feedbackDetail,
}) => {
  const [question, setquestion] = React.useState(feedbackDetail.question);
  const [optionType, setoptionType] = React.useState(feedbackDetail.optionType);
  const [sortOrderNo, setSortOrderNo] = React.useState("");
  const [option, setoption] = React.useState("");
  const [optionList, setOptionList] = React.useState(
    feedBack?.feedBackDetailById.feedbackHeadDetailsOptions
      ? feedBack?.feedBackDetailById.feedbackHeadDetailsOptions
      : []
  );
  console.log(feedBack?.feedBackDetailById.feedbackHeadDetailsOptions);
  const [formErrors, setFormErrors] = React.useState({});
  const handleClose = () => {
    handleCloses();
  };
  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name === "option") {
      setoption(value);
    } else if (name === "sortOrderNo") {
      if (onlyNumber.test(value)) {
        setSortOrderNo(value);
      }
    }
  };

  const submitHandler = () => {
    if (checkValidation()) {
      const optiondata = {
        index: optionList.length + 1,
        id: 0,
        option: option,
        orderNo: sortOrderNo,
      };
      let options = [...optionList, optiondata];
      console.log(options);
      setOptionList(options.sort((a, b) => a.orderNo - b.orderNo));
      setoption("");
      setSortOrderNo("");
    }
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (option.toString().trim() === "" || option === null) {
      formIsValid = false;
      formErrors["optionError"] = myConstClass.option;
    }

    if (sortOrderNo === "" || sortOrderNo === null) {
      formIsValid = false;
      formErrors["sortOrderNoError"] = myConstClass.sortOrderNoMsg;
    }

    if (optionType.toString().trim() === "" || optionType === null) {
      formIsValid = false;
      formErrors["optionTypeError"] = myConstClass.optionType;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };
  let columns = [
    {
      name: "option",
      title: "Option",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "action",
      title: "Actions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  useEffect(() => {}, []);
  const rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowData.id === 0) {
          const filteredList = optionList.filter(
            (row) => row.index !== rowData.index
          );
          setOptionList(filteredList);
        } else {
          const filteredList = optionList.filter(
            (row) => row.id !== rowData.id
          );
          setOptionList(filteredList);
        }
      }
    });
  };
  const saveHandler = () => {
    console.log(optionList);
    onSave(optionList);
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Options
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Question  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="question"
                      size="small"
                      value={feedbackDetail.question}
                      error={false}
                      helperText={" "}
                      onChange={changedHandler}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Option Type "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl color={"primary"} fullWidth={true}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="optionType"
                        id="optionType"
                        value={feedbackDetail.optionType}
                        onChange={changedHandler}
                        size="small"
                        fullWidth={true}
                        error={false}
                        disabled
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">Description </MenuItem>
                        <MenuItem value="0">optional</MenuItem>
                        <MenuItem value="2">MultiSelect</MenuItem>
                      </Select>
                      <FormHelperText error> </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                // columnSpacing={1}
                spacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*option  "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="option"
                      size="small"
                      value={option}
                      onChange={changedHandler}
                      fullWidth
                      error={formErrors?.optionError ? true : false}
                      helperText={
                        formErrors?.optionError ? formErrors?.optionError : " "
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Sort Order No  "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="sortOrderNo"
                      size="small"
                      value={sortOrderNo}
                      onChange={changedHandler}
                      fullWidth
                      error={formErrors?.sortOrderNoError ? true : false}
                      helperText={
                        formErrors?.sortOrderNoError
                          ? formErrors?.sortOrderNoError
                          : " "
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ mt: -2 }}
                justifyContent="center"
              >
                <ButtonCompo
                  size="medium"
                  type="Submit"
                  variant="contained"
                  name="Add"
                  onClick={submitHandler}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <DynamicTable
                  data={optionList}
                  tableHead={columns}
                  showHeadDelete={true}
                  showPegination={false}
                  rowDelete={rowDelete}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="Submit"
                  variant="contained"
                  name="Add Question"
                  onClick={saveHandler}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({ feedBack: state.feedBack });

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
