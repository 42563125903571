import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
// import { getRollNoList } from "./GenerateRollNo.slice";
import InfoIcon from "@mui/icons-material/Info";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import endpoint from "../../config/endpoints";
import { apiPost } from "../../utils/api_service";
import { getDepartmentByuserId } from "../Masters/Internship/Department/department.slice";
import {
  getSubjectFromDeptSemester,
  resetSubjectValues,
} from "./SubjectSelection.slice";
import SubjectSelectionMatrix from "./SubjectSelectionMatrix";
import StudentListPopUp from "./StudentListPopUp";
const rows = [
  {
    index: 1,
    id: 1,
    studentName: "TCS",
    prnNo: "NskRd",
    rollNo: "1",
    reportingDate: "",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 2,
    id: 2,
    studentName: "Wipro",
    prnNo: "",
    rollNo: "2",
    reportingDate: "12-06-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 3,
    id: 3,
    studentName: "ATS",
    prnNo: "123",
    rollNo: "3",
    reportingDate: "1-01-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
];
class SelectSubjectIndex extends React.Component {
  state = {
    id: 0,
    programTitle: "",
    semisterDetail: "",
    department: "",
    sortOrder: "",
    divisionId: "",
    openPopUp: false,
    formErrors: [],
    selection: [],
  };

  componentDidMount() {
    console.log(this.props.studentData);
    const {
      resetSubjectValues,
      getDepartmentByuserId,
      getAlert,
      getSemisterInfo,
    } = this.props;
    resetSubjectValues();
    getDepartmentByuserId().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { programTitle, semisterDetail, department, sortOrder, divisionId } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    if (department.toString().trim() === "" || department === null) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { fromDate, toDate, internType } = this.state;
    const { getCompanyList } = this.props;
    let fromDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    if (this.handleFormValidation()) {
      // getCompanyList({ fromDate: fromDate, toDate: toDate, internType: internType });
    }
  };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };

  getRow = (e) => {
    let selectedList = [];
    this.setState({
      selection: e,
    });
    e.forEach((data) => {
      const selRow = this.props.prnNumberList?.prnNumber.filter(
        (row, index) => {
          return row.id === data;
        }
      );
      selectedList = [...selectedList, selRow[0]];
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (this.props.departments.employee !== nextProps.departments.employee) {
        this.setState({
          department: nextProps.departments.department,
        });
      }
    }
  }

  rowAppliedList = (data) => {
    const { comanyData } = this.state;
    this.setState({
      interestedStudent: true,
      listOfComp: false,
      companyData: data,
    });
  };

  saveHandler = (RollNoList) => {
    const { saveRollNoList } = this.props;
    const { semisterDetail } = this.state;
    const saveToData = RollNoList.map((data) => {
      let saveData = {
        ...data,
        semisterDetail: {
          id: semisterDetail,
        },
        dateOfReporting: moment(data.dateOfReporting).format("DD-MM-YYYY"),
      };
      return saveData;
    });
    apiPost({
      url: endpoint.studentRollList,
      postBody: saveToData,
    }).then(({ success }) => {
      if (success) {
        window.location.replace("/roll-list");
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
    // saveRollNoList({rollNoList:saveToData})
  };

  closeStudentFeesDetails = () => {
    this.setState({ interestedStudent: false, listOfComp: true });
  };
  submitHandler = () => {
    const { semisterDetailId, getAlert } = this.props;
    const { department } = this.state;
    console.log(semisterDetailId);
    console.log(department);
    if (this.handleFormValidation()) {
      // getCompanyList({ fromDate: fromDate, toDate: toDate, internType: internType });

      this.props
        .getSubjectFromDeptSemester({
          semesterDetail: semisterDetailId,
          department: department,
        })
        .then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  onClickView = () => {
    this.setState({
      openPopUp: true,
    });
  };
  handleCloses = () => {
    this.setState({
      openPopUp: false,
    });
  };

  eventColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: 100,
    },
    {
      name: "name",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 400,
    },
  ];
  render() {
    const {
      programTitle,
      semisterDetail,
      department,
      sortOrder,
      divisionId,
      selection,
    } = this.state;
    const {
      semisterDetailError,
      sortOrderError,
      programTitleError,
      divisionError,
      departmentError,
    } = this.state.formErrors;
    const {
      internshipDeclarationList,
      rollNoData,
      studentRollNoList,
      onClickBack,
    } = this.props;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <>
            <Grid
              sx={{ ml: -1.5 }}
              container
              justifyContent="space-evenly"
              direction="row"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ marginTop: -2 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Subject List"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={4}
                md={4}
                sx={{ marginTop: 2 }}
              >
                <Grid sx={{ ml: -3.5 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Department"
                  />
                </Grid>

                <Grid sx={{ ml: -3.5 }} item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={department}
                    name={"department"}
                    options={this.props.departments?.departmentByUserId}
                    onChange={this.ChangeHandlerSearch}
                    isError={departmentError ? true : false}
                    errorText={departmentError ? departmentError : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={1}
                md={1}
                sx={{ marginTop: 5 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Search"
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={1}
                md={1}
                sx={{ marginTop: 5 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <MyComponentWithIconProps
                    statusImage={InfoIcon}
                    color="primary"
                    fontSize="large"
                    title="View"
                    onClick={this.onClickView}
                    //   sx={{ ml: 3.2 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} />
            </Grid>
          </>
        </Paper>
        <Grid sx={{ mt: -3.5, ml: -1.5 }} item>
          <SubjectSelectionMatrix
            onCloseMat={this.props.onCloseMat}
            semisterDetailId={this.props.semisterDetailId}
            studentData={this.props.studentData}
            subjectList={this.props.subjectList.subjectSelected}
            onCancel={this.props.onCancel}
          />
        </Grid>

        {this.state.openPopUp && (
          <StudentListPopUp
            rows={this.props.studentData}
            columns={this.eventColumns}
            open={this.state.openPopUp}
            handleCloses={this.handleCloses}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subjectList: state.subjectSelection,
  departments: state.department,
});
const mapDispatchToProps = {
  getAlert,
  getSubjectFromDeptSemester,
  resetSubjectValues,
  getDepartmentByuserId,
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectSubjectIndex);
