import { Grid, Paper, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import MultipleSelect from "../../../../components/Comman/MultipleSelect";
import * as myConstClass from "../../../../config/messageconstant";
import { getDepartment } from "../../Internship/Department/department.slice";
import { getClass } from "../../OnlineModule/Class/class.slice";
import { getPattern } from "../Pattern/pattern.slice";
import { getSemisterInfo } from "../Semister Info/semisterInfo.slice";
import { getSubjectTypeDetails } from "../SubjectTypeDetail/subjectTypeDetails.slice";
import { resetSubjectInfoGetById } from "./subjectInfo.slice";
import SubjectInfoFormTable from "./SubjectInfoFormTable";
import { getSemesterByYearDetailId } from "../Semister Info/semisterInfo.slice";

let docs;
class SubjectInfo extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      rows: {},
      columnBands: [],
      yearDetailId: "",
      semister: "",
      department: [],
      code: "",
      pattern: "",
      name: "",
      shortName: "",
      subjectTypeId: "",
      isActive: "1",
      formErrors: [],
      id: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.SubjectInfoList !== nextProps.SubjectInfoList) {
      if (
        this.props.SubjectInfoList.subjectById !==
          nextProps.SubjectInfoList.subjectById &&
        Object.keys(nextProps.SubjectInfoList.subjectById).length !== 0
      ) {
        this.setDataToForm(nextProps.SubjectInfoList.subjectById);
      }
    }
  }

  componentDidMount() {
    const {
      SubjectInfoList,
      getSubjectTypeDetails,
      getPattern,
      getSemisterInfo,
      getDepartment,
    } = this.props;
    console.log(this.props.SubjectInfoList);
    console.log(this.props.schemeHeadList);
    let column = [];
    const SubHeadList = this.props.schemeHeadList.schemeHead.map(
      (schemeHeadData) => {
        let HeaderData = {
          name: schemeHeadData.id,
          title: schemeHeadData.name,
        };
        column.push(HeaderData);
        if (schemeHeadData.isMinMaxShow === 1) {
          let min = {
            name: "min" + schemeHeadData.id,
            title: schemeHeadData.name + " Min",
          };
          column.push(min);
        }

        return HeaderData;
      }
    );

    if (Object.keys(this.state.rows).length === 0) {
      let rowData = {};
      column.map((subHeaderData) => {
        rowData = {
          ...rowData,
          [subHeaderData.name]: "0",
        };
      });
      this.setState({
        columns: column,
        rows: rowData,
      });
    }
    getSubjectTypeDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    getPattern().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    getSemisterInfo().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    if (SubjectInfoList.isFetchSubjectById === true) {
      let rowData = {};
      SubjectInfoList.subjectById.subjectDetails.map((subjectDetailData) => {
       
          rowData = {
            ...rowData,
            [subjectDetailData.schemeHeadId]: subjectDetailData.value,
            ["min" + subjectDetailData.schemeHeadId]:
              subjectDetailData.minMarks,
            [subjectDetailData.schemeHeadId]: subjectDetailData.value,
          };
        
      });

      this.props
        .getSemesterByYearDetailId({
          yearDetailId: SubjectInfoList.subjectById.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            if (Object.keys(this.state.rows).length === 0) {
              this.setState({
                rows: rowData,
              });
            } else {
              let dataRows = {};
              Object.keys(this.state.rows).map((innerkey, innerindex) => {
                if (rowData[innerkey]) {
                  dataRows[innerkey] = rowData[innerkey];
                } else {
                  dataRows[innerkey] = "";
                }
              });

              this.setState({
                rows: dataRows,
              });
            }
            this.setState({
              id: SubjectInfoList.subjectById.id,
              code: SubjectInfoList.subjectById.code,
              pattern: SubjectInfoList.subjectById.patternId,
              semister: SubjectInfoList.subjectById.semisterId,
              department: SubjectInfoList.subjectById.departmentId.split(","),
              name: SubjectInfoList.subjectById.name,
              shortName: SubjectInfoList.subjectById.shortName,
              yearDetailId: SubjectInfoList.subjectById.yearDetailId,
              subjectTypeId: SubjectInfoList.subjectById.subjectTypeId,
            });
          }
        });
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "yearDetailId") {
        this.setState({
          semister: "",
        });
        const { getSemesterByYearDetailId } = this.props;
        getSemesterByYearDetailId({ yearDetailId: newValue }).then(
          (response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          }
        );
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const {
      yearDetailId,
      name,
      shortName,
      pattern,
      code,
      semister,
      department,
      rows,
      subjectTypeId,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    Object.keys(rows).map((innerkey, innerindex) => {
      if (rows[innerkey] === "") {
        formIsValid = false;
        formErrors["matrixErr"] = myConstClass.subjectDetailsMsg;
      }
    });
    if (yearDetailId.toString().trim() === "") {
      formIsValid = false;
      formErrors["classDetailsErr"] = myConstClass.yearDetailMsg;
    }
    if (name.toString().trim() === "") {
      formIsValid = false;
      formErrors["nameErr"] = myConstClass.nameMsg;
    }
    if (shortName.toString().trim() === "") {
      formIsValid = false;
      formErrors["shortNameErr"] = myConstClass.SubjectShortNameMsg;
    }
    if (pattern.toString().trim() === "") {
      formIsValid = false;
      formErrors["patternErr"] = myConstClass.yearOfSyllabusApprovalMessage;
    }
    if (code.toString().trim() === "") {
      formIsValid = false;
      formErrors["codeErr"] = myConstClass.courseCodeMessage;
    }
    if (semister.toString().trim() === "") {
      formIsValid = false;
      formErrors["semisterErr"] = myConstClass.semisterMsg;
    }
    if (department.toString().trim() === "") {
      formIsValid = false;
      formErrors["departmentErr"] = myConstClass.departmentMsg;
    }
    if (subjectTypeId.toString().trim() === "") {
      formIsValid = false;
      formErrors["subjectTypeErr"] = myConstClass.courseTypeMessage;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  saveHandler = (e) => {
    e.preventDefault();
    const {
      id,
      yearDetailId,
      name,
      shortName,
      pattern,
      semister,
      department,
      code,
      subjectTypeId,
      isActive,
      rows,
    } = this.state;
    const { SubjectInfoList } = this.props;
    let subjectDetails = [];
    Object.keys(rows).map((innerkey, innerindex) => {
      const first3 = innerkey.substring(0, 3);
      if (first3 !== "min") {
        let subjectDetailList = [];
        let subData = subjectDetails.filter((row) => {
          if (row.schemeHeadId.id === innerkey) {
            return row;
          }
        });
        if (SubjectInfoList.isFetchSubjectById === true) {
          subjectDetailList = SubjectInfoList.subjectById.subjectDetails.filter(
            (row) => {
              if (row.schemeHeadId === innerkey) {
                return row;
              }
            }
          );
        }
        if (subData.length === 0) {
          let data = {
            schemeHeadId: {
              id: innerkey,
            },
            value: rows[innerkey],
            maxMarks: rows[innerkey],
            minMarks: 0,
          };
          if (subjectDetailList.length !== 0) {
            data = {
              ...data,
              id: subjectDetailList[0].id,
            };
          }
          subjectDetails.push(data);
        }
      }
      if (first3 === "min") {
        let subjectDetailList = [];
        let result = innerkey.substring(3);
        let subData = subjectDetails.filter((row) => {
          if (row.schemeHeadId.id === result) {
            return row;
          }
        });
        if (SubjectInfoList.isFetchSubjectById === true) {
          subjectDetailList = SubjectInfoList.subjectById.subjectDetails.filter(
            (row) => {
              if (row.schemeHeadId === result) {
                return row;
              }
            }
          );
        }
        if (subData.length === 0) {
          let data = {
            schemeHeadId: {
              id: result,
            },
            value: 0,
            maxMarks: 0,
            minMarks: rows[innerkey],
          };
          if (subjectDetailList.length !== 0) {
            data = {
              ...data,
              id: subjectDetailList[0].id,
            };
          }
          subjectDetails.push(data);
        } else {
          let filterData = subjectDetails.filter((row) => {
            if (row.schemeHeadId.id !== result) {
              return row;
            }
          });
          let data = {
            schemeHeadId: {
              id: result,
            },
            minMarks: rows[innerkey],
            value: subData[0].value !== 0 ? subData[0].value : 0,
            maxMarks: subData[0].maxMarks !== 0 ? subData[0].maxMarks : 0,
          };
          if (subjectDetailList.length !== 0) {
            data = {
              ...data,
              id: subjectDetailList[0].id,
            };
          }
          filterData = [...filterData, data];
          subjectDetails = filterData;
        }
      }
    });
    docs = {
      name: name,
      yearDetailId: yearDetailId,
      shortName: shortName,
      pattern: {
        id: pattern,
      },
      code: code,
      semister: {
        id: semister,
      },
      departmentId: department.join(),
      subjectTypeId: {
        id: subjectTypeId,
      },
      isActive: isActive,
      subjectDetails: subjectDetails,
    };
    if (id !== 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (this.handleFormValidation()) {
      this.props.onSaveAndNext(docs);

      let rowData = {};
      this.state.columns.map((subHeaderData) => {
        rowData = {
          ...rowData,
          [subHeaderData.name]: "0",
        };
      });

      this.setState({
        rows: rowData,
        // yearDetailId: "",
        // semister: "",
        // department: [],
        code: "",
        // pattern: "",
        name: "",
        shortName: "",
        // subjectTypeId: "",
        isActive: "1",
        formErrors: [],
        id: 0,
      });
      const { resetSubjectInfoGetById } = this.props;
      resetSubjectInfoGetById();
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  dataChange = (e, data, subHeaderId) => {
    const { value } = e.target;
    let rowData = {};
    Object.keys(data).map((innerkey, innerindex) => {
      if (innerkey === subHeaderId) {
        rowData = {
          ...rowData,
          [innerkey]: value,
        };
      } else {
        rowData = {
          ...rowData,
          [innerkey]: data[innerkey],
        };
      }
    });

    this.setState({
      rows: rowData,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      yearDetailId,
      name,
      shortName,
      pattern,
      semister,
      department,
      code,
      subjectTypeId,
      isActive,
      rows,
    } = this.state;
    const { SubjectInfoList } = this.props;
    let subjectDetails = [];
    console.log(rows);
    Object.keys(rows).map((innerkey, innerindex) => {
      const first3 = innerkey.substring(0, 3);
      if (first3 !== "max" && first3 !== "min") {
        let subjectDetailList = [];
        let subData = subjectDetails.filter((row) => {
          if (row.schemeHeadId.id === innerkey) {
            return row;
          }
        });
        if (SubjectInfoList.isFetchSubjectById === true) {
          subjectDetailList = SubjectInfoList.subjectById.subjectDetails.filter(
            (row) => {
              if (row.schemeHeadId === innerkey) {
                return row;
              }
            }
          );
        }
        if (subData.length === 0) {
          let data = {
            schemeHeadId: {
              id: innerkey,
            },
            value: rows[innerkey],
            maxMarks: rows[innerkey],
            minMarks: 0,
          };
          if (subjectDetailList.length !== 0) {
            data = {
              ...data,
              id: subjectDetailList[0].id,
            };
          }
          subjectDetails.push(data);
        }
      }

      if (first3 === "min") {
        let subjectDetailList = [];
        let result = innerkey.substring(3);
        let subData = subjectDetails.filter((row) => {
          if (row.schemeHeadId.id === result) {
            return row;
          }
        });
        if (SubjectInfoList.isFetchSubjectById === true) {
          subjectDetailList = SubjectInfoList.subjectById.subjectDetails.filter(
            (row) => {
              if (row.schemeHeadId === result) {
                return row;
              }
            }
          );
        }
        if (subData.length === 0) {
          let data = {
            schemeHeadId: {
              id: result,
            },
            value: 0,
            maxMarks: 0,
            minMarks: rows[innerkey],
          };
          if (subjectDetailList.length !== 0) {
            data = {
              ...data,
              id: subjectDetailList[0].id,
            };
          }
          subjectDetails.push(data);
        } else {
          let filterData = subjectDetails.filter((row) => {
            if (row.schemeHeadId.id !== result) {
              return row;
            }
          });
          let data = {
            schemeHeadId: {
              id: result,
            },
            minMarks: rows[innerkey],
            value: subData[0].value !== 0 ? subData[0].value : 0,
            maxMarks: subData[0].maxMarks !== 0 ? subData[0].maxMarks : 0,
          };
          if (subjectDetailList.length !== 0) {
            data = {
              ...data,
              id: subjectDetailList[0].id,
            };
          }
          filterData = [...filterData, data];
          subjectDetails = filterData;
        }
      }
    });
    docs = {
      name: name,
      yearDetailId: yearDetailId,
      shortName: shortName,
      pattern: {
        id: pattern,
      },
      code: code,
      semister: {
        id: semister,
      },
      departmentId: department.join(),
      subjectTypeId: {
        id: subjectTypeId,
      },
      isActive: isActive,
      subjectDetails: subjectDetails,
    };
    if (id !== 0) {
      docs = {
        ...docs,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.props.onSave(docs);
    }
  };
  componentWillUnmount() {
    const { resetSubjectInfoGetById } = this.props;
    resetSubjectInfoGetById();
  }

  multiSelectHandler = (value, name) => {
    console.log(value);
    console.log(name);
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });

    // setType(typeof value === "string" ? value.split(",") : value);
  };
  render() {
    const {
      classDetailsErr,
      nameErr,
      codeErr,
      shortNameErr,
      patternErr,
      semisterErr,
      departmentErr,
      subjectTypeErr,
      matrixErr,
    } = this.state.formErrors;
    const {
      columns,
      columnBands,
      rows,
      yearDetailId,
      semister,
      department,
      code,
      pattern,
      name,
      shortName,
      subjectTypeId,
    } = this.state;
    const {
      classList,
      subjectTypeList,
      patternList,
      semisterInfoList,
      departmentList,
      onCancel,
    } = this.props;

    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                sx={{ m: -1 }}
                item
                xs={12}
                md={12}
                size="small"
                container
                justifyContent="flex-end"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="outlined"
                  name=" Back to List"
                  onClick={onCancel}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Details"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetailId}
                      name={"yearDetailId"}
                      options={classList?.class}
                      onChange={this.ChangeHandlerSearch}
                      isError={classDetailsErr ? true : false}
                      errorText={classDetailsErr ? classDetailsErr : " "}
                    />

                    {/* <FormHelperText error>{classDetailsErr ? classDetailsErr : ' '} </FormHelperText> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Department"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                
                    <MultipleSelect
                      er={departmentErr}
                      name1="department"
                      value1={department}
                      names={departmentList?.department}
                      onChange={this.multiSelectHandler}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Semester"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={semister}
                      name={"semister"}
                      options={semisterInfoList?.semesterList}
                      onChange={this.ChangeHandlerSearch}
                      isError={semisterErr ? true : false}
                      errorText={semisterErr ? semisterErr : " "}
                    />
                    {/* <FormHelperText error>{semisterErr ? semisterErr : ' '} </FormHelperText> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="code"
                      id="code"
                      value={code}
                      onChange={this.changeHandler}
                      fullWidth
                      error={codeErr ? true : false}
                      helperText={codeErr ? codeErr : ' '}
                    ></TextField>
                  </Grid> */}

                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Course Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="name"
                      id="name"
                      value={name}
                      onChange={this.changeHandler}
                      fullWidth
                      error={nameErr ? true : false}
                      helperText={nameErr ? nameErr : " "}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Pattern"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={pattern}
                      name={"pattern"}
                      options={patternList?.pattern}
                      onChange={this.ChangeHandlerSearch}
                      isError={patternErr ? true : false}
                      errorText={patternErr ? patternErr : " "}
                    />

                  </Grid> */}

                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Course Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="code"
                      id="code"
                      value={code}
                      onChange={this.changeHandler}
                      fullWidth
                      error={codeErr ? true : false}
                      helperText={codeErr ? codeErr : " "}
                    ></TextField>
                  </Grid>
                </Grid>

              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  

                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Course Short Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="shortName"
                      id="shortName"
                      value={shortName}
                      onChange={this.changeHandler}
                      fullWidth
                      inputProps={{ maxLength: 7 }}
                      error={shortNameErr ? true : false}
                      helperText={shortNameErr ? shortNameErr : " "}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                 
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Department"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSelect
                      er={departmentErr}
                      name1="department"
                      value1={department}
                      names={departmentList?.department}
                      onChange={this.multiSelectHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                 

                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year of Syllabus Approval"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={pattern}
                      name={"pattern"}
                      options={patternList?.pattern}
                      onChange={this.ChangeHandlerSearch}
                      isError={patternErr ? true : false}
                      errorText={patternErr ? patternErr : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
               

                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Course Type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={subjectTypeId}
                      name={"subjectTypeId"}
                      options={subjectTypeList?.subjectTypeDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={subjectTypeErr ? true : false}
                      errorText={subjectTypeErr ? subjectTypeErr : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="space-evenly"
              ></Grid>
              {/* <br/> */}
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <SubjectInfoFormTable
                  rows={rows}
                  scheme={this.props.schemeList.scheme}
                  schemeHead={this.props.schemeHeadList.schemeHead}
                  dataChange={this.dataChange}
                  // columnExtensions={columnExtensions}
                  columnBands={columnBands}
                  // textfieldData={textfieldData}
                  //tableData={tableData}
                />
                <FormHelperText error>
                  {matrixErr ? matrixErr : " "}{" "}
                </FormHelperText>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.8}
                  lg={1.8}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    fullWidth={true}
                    type="button"
                    variant="contained"
                    name="Save and Next"
                    onClick={this.saveHandler}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    fullWidth={true}
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  classList: state.class,
  subjectTypeList: state.subjectTypeDetails,
  patternList: state.pattern,
  semisterInfoList: state.semister,
  schemeList: state.scheme,
  schemeHeadList: state.schemeHead,
  SubjectInfoList: state.subjectInfo,
  departmentList: state.department,
});
const mapDispatchToProps = {
  getClass,
  getSubjectTypeDetails,
  getPattern,
  getSemisterInfo,
  getDepartment,
  resetSubjectInfoGetById,
  getAlert,
  getSemesterByYearDetailId,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubjectInfo);
