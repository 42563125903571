import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  category: [],
  isCategoryFetch: false,
};

let URL = endpoints.category;
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        category: row,
        isCategoryFetch: true,
      };
    },
  },
});

export const { categorySuccess } = categorySlice.actions;

export default categorySlice.reducer;

export const getCategory = () => async (dispatch) => {
  try {
  const response=  apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(categorySuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
