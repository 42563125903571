import endpoint from "../config/endpoints";

export const AlumniAssociationJson = {
  apiBaseURL: endpoint.volunteerUpdateStatus,
  screenTitle: "Be a Alumnae Association Office Bearer",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Nominate for",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "NominateForMaster",
      dataKey: "expertise",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Reason for Nomination",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "message",
      isMandatory: true,
    },
  ],
};
