import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { Loading1 } from "../../../components/Loading1";
import endpoints from "../../../config/endpoints";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import Declaration from "./Declaration";
import { getStudentList } from "./InternshipDeclataration.slice";
import QualificationCriteria from "./QualificationCriteria";
import StudentListTable from "./StudentListTable";
import { FontAwesomeIconCompo } from "../../../components/Comman/IconButton";
import { downloadF } from "../../PDF/PDFDOwnloadWithBody";

const DisableElevation = ({
  getStudentList,
  getAlert,
  studentList,
  showNotification,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openStudent, setOpenStudent] = React.useState(false);
  const [value, setValue] = React.useState({});
  const [showLoader, setShowLoader] = React.useState(false);
  const [quaRow, setQuaRow] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [editRowData, setEditRowData] = React.useState([]);
  const [selection, setSelection] = React.useState([]);
  const [qualiDataForExcel, setQualiDataForExcel] = React.useState([]);

  const qualiData = () => {
    let QualificationDetailSaves = quaRow.map((data) => {
      let docs = {
        qualificationId: data.qualificationId,
        minMarks: data.percentage,
        flag: data.elegibilityCriteria,
      };
      if (data.id) {
        docs = {
          ...docs,
          id: data.id,
        };
      }
      return docs;
    });
    console.log(QualificationDetailSaves);
    setQualiDataForExcel(QualificationDetailSaves);
    setShowLoader(true);
    // On Save send two more parameters with yearDetails and divisionIds namely mathsCriteria, gapInYears
    getStudentList({
      yearDetails: value.yearDetailId,
      divisionIds: value.division,
      qualification: QualificationDetailSaves,
      gapInYears: value.gapInYears,
      mathsCriteria: value.mathsCriteria,
    }).then((response) => {
      if (response) {
        setShowLoader(false);
        setOpenStudent(true);
        setOpen(false);
      } else {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      }
    });
  };
  const BackToList = () => {
    setOpen(false);
  };
  const BackToListStudent = () => {
    if (value.qualiCriteria == 1) {
      setOpenStudent(false);
      setOpen(true);
    } else {
      setOpen(false);
      setOpenStudent(false);
    }
  };
  const RowValue = () => {
    console.log("RowValue" + list);
    // setOpen(false);
    let docs = {
      company: {
        id: value.companyNameId,
      },
      venue: value.venue,
      placementType: value.placementType,
      yearDetailId: value.yearDetailId,
      fromDate: value.fromDate,
      toDate: value.toDate,
      fromTime: value.fromTime,
      toTime: value.toTime,
      urlLink: value.urlLink,
      declareDate: "",
      remark: value.remark,
      isQualificationCriteriaRequired: value.qualiCriteria,
      divisionId: value.division,
      designation: value.designation,
      placementPackage: value.companyPackage,
      durationFromDate: value.durationFor,
      durationToDate: value.durationTo,
      isBacklogAllowed: value.gapAllowed,
      backlog: value.gapInYears,
      mathsCriteria: value.mathsCriteria,
      isActive: 1,
      interviwMode: value.interviewMode,
      placementDeclareDocuments: value.placementDeclareDocument,
      placementCriteria: quaRow.map((data) => {
        let qualificationData = {
          qualificationId: data.qualificationId,
          minimumMarks: data.percentage,
          flag: data.elegibilityCriteria,
        };
        return qualificationData;
      }),
      placementEligibleStudent: list.map((sList) => {
        let studentList = {
          userRegistrationId: sList.id,
          rollNo: sList.rollNo,
        };
        return studentList;
      }),
    };
    console.log(docs);
    swal({
      title: "Are you sure?",
      text: "Do you want save data?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        setShowLoader(true);
        apiPost({
          url: endpoints.placementDeclare,
          postBody: docs,
        }).then(({ data, success }) => {
          if (success) {
            showNotification({
              msg: "Data saved successfully",
            });
            setShowLoader(false);
            window.location.replace("/list-of-placement");
          } else {
            setShowLoader(false);
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  const getRow = (e) => {
    let selectedList = [];
    e.forEach((data) => {
      const selRow = studentList?.studentList.filter((row, index) => {
        return row.id === data;
      });
      selectedList = [...selectedList, selRow[0]];
    });
    setSelection(e);
    setList(selectedList);
    console.log(selectedList);
  };

  const data = (companyData) => {
    setValue(companyData);
    console.log(companyData);
    if (companyData.qualiCriteria === "1") {
      setOpen(true);
    } else if (companyData.qualiCriteria === "0") {
      setShowLoader(true);
      getStudentList({
        yearDetails: companyData.yearDetailId,
        divisionIds: companyData.division,
        qualification: [],
        gapInYears: companyData.gapInYears,
        mathsCriteria: companyData.mathsCriteria,
      }).then((success) => {
        if (success) {
          setShowLoader(false);
          setOpen(true);
          setOpenStudent(true);
        } else {
          setShowLoader(false);
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  };
  const rowDeleteQualification = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the record?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let rowData = quaRow.filter((row) => row.index !== id);
        rowData = rowData.map((rowDetails, index) => {
          rowDetails = {
            ...rowDetails,
            index: index + 1,
          };
          return rowDetails;
        });
        setQuaRow(rowData);
      }
    });
  };
  const setQuaData = (data) => {
    console.log(data);
    if (data.index === 0) {
      data = {
        ...data,
        index: quaRow.length + 1,
      };

      console.log(data);
      setQuaRow((prevData) => {
        return [...prevData, data];
      });
    } else {
      let viewRow = quaRow.map((row) => {
        if (row.index === data.index) {
          return data;
        }
        return row;
      });
      console.log(viewRow);
      setQuaRow(viewRow);
    }
    setEditRowData([]);
  };

  const excelDetails = () => {
    let docs = {
      qualificationAndMinimumMarks: qualiDataForExcel,
      yearDetailIds: value.yearDetailId,
      divisionIds: value.division,
      gapInYears: value.gapInYears,
      mathsCriteria: value.mathsCriteria,
    };
    downloadF({
      url: "/api/exportexcel/v1/eligible-students-report",
      openNewTab: true,
      // requestBody: studentList?.studentList,
      requestBody: docs,
      ext: "xls",
    }).then((response) => {
      if (!response) {
        getAlert({
          message: "Something went wrong",
        });
       
      } else {
      
      }
    });
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        {!open && !openStudent && <Declaration OnSave={data} value={value} />}
        {console.log(quaRow)}

        {open && !openStudent && (
          <QualificationCriteria
            row={quaRow}
            qualiData={qualiData}
            rowDelete={rowDeleteQualification}
            rowData={setQuaData}
            onCancel={BackToList}
          />
        )}
        {openStudent && (
          <>
            <Grid
              justifyContent="space-between"
              // sx={{ marginBottom: 3 }}
              item
              xs={12}
              sm={12}
              md={12}
              flexDirection="row"
              container
            >
              <Grid sx={{ marginBottom: 3 }} item xs={6} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Eligible Student List"
                />
              </Grid>
              <Grid
                sx={{
                  // marginBottom: 3,
                  float: "right",
                }}
                item
                xs={5.8}
                sm={5.8}
                md={5.8}
              />
              <Grid item xs={0.2} sm={0.2} md={0.2}>
                <FontAwesomeIconCompo
                  className="fa-file-excel"
                  color="primary"
                  title="Excel"
                  baseClassName="fas"
                  fontSize="medium"
                  onClick={excelDetails}
                />
              </Grid>
            </Grid>
            <StudentListTable
              onSelectionChange={getRow}
              selection={selection}
              isActionColActive={false}
            />

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              m={2}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-between"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={3}
                lg={3}
                container
                justifyContent="left"
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 15 }}
                  label={"Student List = " + studentList?.studentList.length}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                spacing={1}
                justifyContent="right"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=""
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={2}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    fullWidth={true}
                    onClick={BackToListStudent}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={2}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Submit"
                    fullWidth={true}
                    onClick={RowValue}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {showLoader && <Loading1 />}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  studentList: state.internshipDeclaration,
});

const mapDispatchToProps = {
  getStudentList,
  showNotification,
  getAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(DisableElevation);
