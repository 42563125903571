import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import AutoComplete from "../../../../../components/Comman/AutoComplete";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../../components/Comman/Label";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../../../components/dynamicscreens/DynamicForm";
import endpoint from "../../../../../config/endpoints";
import * as msgConstant from "../../../../../config/messageconstant";
import { adminAwardJson } from "../../../../../DynamicFormsJson/AdminAward";
import { showNotification } from "../../../../Landing/Landing.slice";
import { AwardColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../../../utils/api_service";
import { getActivityByType } from "../../AcademicDetails/Activity/activity.slice";
import {
  getAward,
  getAwardByDate,
  getAwardWithoutDate,
  getAdminAward,
} from "../../AcademicDetails/Award/Award.slice";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getEmployeeByDeptId } from "../../../../EmployeeDetails/employee.slice";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import * as myConstClass from "../../../../../config/messageconstant";
import { Loading1 } from "../../../../../components/Loading1";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminAwardIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      formErrors: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      showLoader: false,
      userRegistration: "",
      departmentId: "",
      DataToSet: [],
      dynamicMasterData: {
        activity: this.props.activityList?.activityByType,
        LevelMaster: [
          { id: "International", name: "International" },
          { id: "National", name: "National" },
          { id: "State", name: "State" },
          { id: "University", name: "University" },
          { id: "Inhouse", name: "Inhouse" },
          { id: "Intercollegiate", name: "Intercollegiate" },
          { id: "other", name: "Other" },
        ],
        durationMaster: [
          { id: "Lifetime", name: "Lifetime" },
          { id: "Duration", name: "Duration" },
        ],
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeList?.employeeList,
      },
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.activityByType !==
        nextProps.activityList.activityByType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: nextProps.activityList.activityByType,
          },
        });
      }
    }
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
    if (this.props.employeeList !== nextProps.employeeList) {
      if (
        this.props.employeeList?.employeeList !==
        nextProps.employeeList?.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeList?.employeeList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    adminAwardJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        // if (dataToSearch.departmentId) {
        //   this.setState({
        //     showLoader: true,
        //   });
        //   this.props
        //     .getEmployeeByDeptId({
        //       departmentId: dataToSearch.departmentId,
        //     })
        //     .then((response) => {
        //       if (!response) {
        //         this.setState({
        //           showLoader: false,
        //         });
        //         this.props.getAlert({ message: "Something went wrong" });
        //       } else {
        //         this.setState({
        //           showLoader: false,
        //         });
        //       }
        //     });
        // }
      }
    });
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    const { fieldDataForSearch, departmentId, userRegistration } = this.state;
    const dataToSave = {};
    const dataToPassForm = [];
    let error = false;
    if (userRegistration != "" && departmentId != "") {
      const valueToShow = this.props.employeeList?.employeeList.filter(
        (data) => data.id === this.state.userRegistration
      );
      let dataToSet = {
        label: "Teacher",
        value: valueToShow.length !== 0 ? valueToShow[0].name : " - ",
      };
      dataToPassForm.push(dataToSet);
      const valueToShow1 = this.props.departmentList?.department.filter(
        (data) => data.id === this.state.departmentId
      );
      let dataToSet1 = {
        label: "Department",
        value: valueToShow1.length !== 0 ? valueToShow1[0].name : " - ",
      };
      dataToPassForm.push(dataToSet1);
    } else {
      error = true;
    }

    this.setState({
      DataToSet: dataToPassForm,
    });
    if (!error) {
      let fieldData = {};
      const { fieldMeta } = this.props;
      adminAwardJson.fieldMeta.map((currentField) => {
        if (currentField.defaultValue) {
          fieldData[currentField.dataKey] = currentField.defaultValue;
        }
      });

      this.setState({
        fieldData: fieldData,
        currentOperationMode: OperationMode.Add,
      });
      this.toggleFormTableVisibility();
    } else {
      if (this.state.userRegistration == "") {
        swal({
          title: "Alert !",
          text: "Please Select Teacher",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
    }
    console.log(dataToSave);
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    let fieldData = {};

    adminAwardJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else if (currentField.controlType === "hideTextfield") {
        let list = this.state.dynamicMasterData[
          currentField["masterName"]
        ].filter((item) => {
          return item.id === rowData[tableDataKey];
        });
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = "other";
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] === currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
    this.props.getActivityByType().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });

    // this.props.getAwardWithoutDate().then((response) => {
    //   if (!response) {
    //     this.setState({ showLoader: false });
    //     this.props.getAlert({ message: "Something went wrong" });
    //   } else {
    //     this.setState({ showLoader: false });
    //   }
    // });
  }

  onChangeStatus = (rowData, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveEmployee +
            "/update-status?userRegistrationId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode, userRegistration } = this.state;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      userRegistration: { id: userRegistration },
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    adminAwardJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (
        currentField.controlType === "datepicker" ||
        currentField.controlType === "hideDatepicker"
      ) {
        if (fieldData[currentField.dataKey] !== undefined) {
          let date = new Date(fieldData[currentField.dataKey]);

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
        }
        if (
          currentField.controlType === "hideDatepicker" &&
          fieldData[currentField.tableDataKey] !== currentField.open
        ) {
          dataToSave[currentField.dataKey] = "";
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      } else if (currentField.controlType === "hideTextfield") {
        console.log(fieldData[currentField.tableDataKey]);
        console.log(fieldData[currentField.open]);
        if (fieldData[currentField.tableDataKey] === currentField.open) {
          dataToSave[currentField.tableDataKey] = fieldData[
            currentField.dataKey
          ]
            ? fieldData[currentField.dataKey]
            : "";
        }
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      showLoader: true,
    });
    apiPost({
      url: adminAwardJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.setState({
          showLoader: true,
        });
        this.props
          .getAdminAward({
            userRegistration: this.state.userRegistration,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      showLoader: true,
    });
    apiDelete({
      url: adminAwardJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.setState({
          showLoader: true,
        });
        this.props
          .getAdminAward({
            userRegistration: this.state.userRegistration,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });

        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  submitHandler = () => {
    const { fromDate, toDate, userRegistration } = this.state;
    if (userRegistration !== "" && this.teacherHandleFormValidation()) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getAdminAward({
          userRegistration: userRegistration,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  getFilteredTableData = () => {
    const { awardList } = this.props;
    const { searchValue } = this.state;
    const columsFil = AwardColumns.filter((item) => {
      return item.canSearch;
    });
    console.log(awardList?.award);
    return awardList?.award.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  generatePDF = () => {
    const { excelFileName = "Awards / Recognization PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      AwardColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    AwardColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  generateExcel = () => {
    const { excelFileName = "Awards / Recognization Excel Report" } =
      this.props;
    const tableData = this.getFilteredTableData();
    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      AwardColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  teacherHandleFormValidation() {
    const { userRegistration, departmentId } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (userRegistration === "") {
      formIsValid = false;
      formErrors["teacherError"] = myConstClass.employeemsg;
    }

    if (departmentId === "") {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  goToAdminAcademicPanel = () => {
    window.location.replace("/admin-academic-panel");
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name == "departmentId") {
        this.setState({
          showLoader: true,
        });
        this.props
          .getEmployeeByDeptId({
            departmentId: newValue,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        this.setState({ departmentId: newValue });
      } else {
        this.setState({ userRegistration: newValue });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      employeeDetails,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      dynamicMasterData,
      fieldData,
      userRegistration,
      departmentId,
      DataToSet,
    } = this.state;
    const { fromDateError, toDateError, teacherError, departmentError } =
      this.state.formErrors;
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={adminAwardJson.screenTitle}
          showPdfDownload={adminAwardJson.showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={adminAwardJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={showAddButton}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
          showBackToListBtn={true}
          onBackToList={this.goToAdminAcademicPanel}
        />
        <br></br>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <DynamicForm
                fieldMeta={adminAwardJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showSaveNextBtn={false}
                DataToSet={DataToSet}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave(true)}
                onSaveAndNext={this.onFormSave(false)}
              />
            </>
          )}

          {showTable && (
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Department"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={departmentId}
                        name={"departmentId"}
                        options={this.props.departmentList?.department.filter(
                          (data) => data.isActive == 1
                        )}
                        onChange={this.ChangeHandlerSearch}
                        isError={departmentError ? true : false}
                        errorText={departmentError ? departmentError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Teacher"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={userRegistration}
                        name={"userRegistration"}
                        options={this.props.employeeList?.employeeList.filter(
                          (data) => data.isActive == 1
                        )}
                        onChange={this.ChangeHandlerSearch}
                        isError={teacherError ? true : false}
                        errorText={teacherError ? teacherError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.submitHandler}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ marginTop: 1 }}>
                <RTTable
                  columns={AwardColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  showAddButtonInTable={showAddButtonInTable}
                  rowEdit={this.rowEdit}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                  rowAdd={this.rowAdd}
                  tableData={this.getFilteredTableData()}
                  rows={this.getFilteredTableData()}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  innovativeList: state.innovativeTeaching,
  awardList: state.award,
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getActivityByType,
  getAward,
  getAwardByDate,
  getAwardWithoutDate,
  getDepartment,
  getEmployeeByDeptId,
  getAdminAward,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminAwardIndex);
