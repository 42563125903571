import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import AlertSlice from "../Alert/alert.slice";
import commonSlice from "../CommonActions/common.slice";
import { localStorageMiddleware } from "../middleware/storage";
import academicYearSlice from "../Pages/Masters/OnlineModule/AcademicYear/academicYear.slice";
import admissionFormSlice from "../Pages/AdmissionForm/admissionForm.slice";
import AdmissionStatusSlice from "../Pages/Masters/Admission/AdmissionStatus/admissionStatus.slice";
import AdmissionTypeSlice from "../Pages/Masters/Admission/AdmissionType/admissionType.slice";
import classSlice from "../Pages/Masters/OnlineModule/Class/class.slice";
import divisionSlice from "../Pages/Masters/Admission/Division/division.slice";
import documentVerificationSlice from "../Pages/DocumentVerification/documentVerification.slice";
import feesHeadDetailsSlice from "../Pages/Masters/Admission/FeesHeadDetails/feesHeadDetails.slice";
import FeesTitleSlice from "../Pages/Masters/Admission/FeesTitle/feesTitle.slice";
import feeStructureHeadSlice from "../Pages/Masters/Admission/FeeStructureHead/feeStructureHead.slice";
import feeTypeSlice from "../Pages/Masters/Admission/FeeType/feeType.slice";
import financialYearSlice from "../Pages/Masters/Admission/FinancialYear/financialYear.slice";
import fromFeesSlice from "../Pages/Masters/Admission/FromFees/fromFees.slice";
import instituteSlice from "../Pages/Masters/OnlineModule/Institute/institute.slice";
import LandingSlice from "../Pages/Landing/Landing.slice";
import loginSlice from "../Pages/Login/login.slice";
import patternSlice from "../Pages/Masters/Admission/Pattern/pattern.slice";
import programSlice from "../Pages/Masters/OnlineModule/ProgramMaster/program.slice";
import programTypeSlice from "../Pages/Masters/OnlineModule/ProgramType/programType.slice";
import ReceiptTypeSlice from "../Pages/Masters/Admission/ReceiptType/receiptType.slice";
import schemeSlice from "../Pages/Masters/Admission/Scheme/scheme.slice";
import SchemeHeadSlice from "../Pages/Masters/Admission/SchemeHead/schemeHead.slice";
import ScholarshipTypeSlice from "../Pages/Masters/Admission/Scholarship Type/ScholarshipType.slice";
import semisterInfoSlice from "../Pages/Masters/Admission/Semister Info/semisterInfo.slice";
import semisterDetailsSlice from "../Pages/Masters/Admission/SemisterDetails/semisterDetails.slice";
import subjectInfoSlice from "../Pages/Masters/Admission/Subject Info/subjectInfo.slice";
import feesCollectionSlice from "../Pages/StudentFeesCollection/FeesCollection.slice";
import SubjectTypeDetailsSlice from "../Pages/Masters/Admission/SubjectTypeDetail/subjectTypeDetails.slice";
import programTitleSlice from "../Pages/Masters/OnlineModule/ProgramTitle/programTitle.slice";
import categorySlice from "../Pages/Masters/OnlineModule/Category/category.slice";
import PromotionalSlice from "../Pages/PromotionalAdmission/Promotional.slice";
import applicantAdmissionSlice from "../Pages/ApplicantAdmission/applicantAdmission.slice";
import basicDetailsSlice from "../Pages/BasicDetailsPost/basicDetails.slice";
import fileSlice from "../Pages/UploadFile/file.slice";
import { UserActionTypes } from ".././CommonActions/User.action";
import qualificationSlice from "../Pages/QualificationMaster/qualification.slice";
import documentSlice from "../Pages/Masters/OnlineModule/Document/document.slice";
import departmentSlice from "../Pages/Masters/Internship/Department/department.slice";
import designationSlice from "../Pages/Masters/Internship/Designation/designation.slice";
import companySlice from "../Pages/Masters/Faculty/Company/company.slice";
import internshipTypeSlice from "../Pages/Masters/Internship/InternshipType/IntershipType.slice";
import employeeSlice from "../Pages/EmployeeDetails/employee.slice";
import InternshipDeclarationSlice from "../Pages/InternshipDeclaration/InternshipDeclataration.slice";
import companyListSlice from "../Pages/ListOfCompany/CompanyList.slice";
import AllocationOfStudentSlice from "../Pages/AllocationOfStudent/AllocationOfStudent.slice";
import allocationListSlice from "../Pages/TeacherViva/TeacherViva.slice";
import externalStudentSlice from "../Pages/ExternalStudent/external.slice";
import PlacementCompanyListSlice from "../Pages/Placement/ListOfCompany/PlacementCompanyList.slice";
import rollNoListSlice from "../Pages/RollNoList/GenrateRollNo/GenerateRollNo.slice";
import rollnoSlice from "../Pages/RollNoList/ViewOfRollList/rollno.slice";
import studentBatchSlice from "../Pages/Batches/GenerationOfBatches/GenerationOfBatches.slice";
import placementDeclarationSlice from "../Pages/Placement/InternshipDeclaration/InternshipDeclataration.slice";
import batchSlice from "../Pages/Batches/BatchList/batchlist.slice";
import roomSlice from "../Pages/Masters/TimeTable/room/room.slice";
import floorSlice from "../Pages/Masters/TimeTable/Floor/floor.slice";
import timeSlotSlice from "../Pages/Masters/TimeTable/Time-Slot/timeSlot.slice";
import WorkloadAllocationSlice from "../Pages/Workload/AdminSideViewAndGenerateWorkout/WorkloadAllocation.slice";
import employeeWorkloadSlice from "../Pages/Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import activitySlice from "../Pages/Masters/Faculty/AcademicDetails/Activity/activity.slice";
import InnovativeTeaching from "../Pages/Masters/Faculty/AcademicDetails/InnovativeTeaching/InnovativeTeaching.slice";
import generateTimeTableSlice from "../Pages/TimeTable/GenerateTimeTable/generateTimeTable.slice";
import resourcePersonSlice from "../Pages/Masters/Faculty/AcademicDetails/ResourcePerson/resourcePerson.slice";
import awardSlice from "../Pages/Masters/Faculty/AcademicDetails/Award/Award.slice";
import reserchJournalPublicationSlice from "../Pages/Masters/Faculty/AcademicDetails/ReserchJournalPublication/ReseachJournalPublication.slice";
import resourceProjectSlice from "../Pages/Masters/Faculty/AcademicDetails/ResearchProject/resourceProject.slice";
import courseAttendedSlice from "../Pages/Masters/Faculty/AcademicDetails/CourseAttended/courseAttended.slice";
import researchguidanceSlice from "../Pages/Masters/Faculty/AcademicDetails/ResearchGuidance/researchguidance.slice";
import seminarWebinarSlice from "../Pages/Masters/Faculty/AcademicDetails/SeminarWebinarActivity/seminarWebinar.slice";
import CoCurricularSlice from "../Pages/Masters/Faculty/AcademicDetails/Co-curricular/Co-curricular.slice";
import AttendedSeminarSlice from "../Pages/Masters/Faculty/AcademicDetails/AttendedSeminar/AttendedSeminar.slice";
import committeeHandleSlice from "../Pages/Masters/Faculty/AcademicDetails/CommitteeHandle/committeeHandle.slice";
import grievanceSlice from "../Pages/Masters/Faculty/AcademicDetails/GrevanceOrComplaint/GrievanceOrComplaint.slice";
import consultancySlice from "../Pages/Masters/Faculty/AcademicDetails/Consultancy/consultancy.slice";
import extendedActivitySlice from "../Pages/Masters/Faculty/AcademicDetails/ExtendedActivities/extended.slice";
import eventSlice from "../Pages/Event/Event Management/event.slice";
import StudentApplySlice from "../Pages/Event/StudentApplyForEvent/StudentApply.slice";

import noticeSlice from "../Pages/Masters/Faculty/AcademicDetails/AdminNotice/notice.slice";
import studentNotice from "../Pages/Masters/Faculty/AcademicDetails/StudentNotice/studentNotice.slice";
import suggestionsSlice from "../Pages/Suggestions/suggestion.slice";
import feedbackSlice from "../Pages/Feedback/Employee Feedback/feedback.slice";
import studentFeedbackDetailsSlice from "../Pages/Feedback/Student Feedback Portal/studentFeedbackPortal.slice";
import addFeedbackSlice from "../Pages/Feedback/AddAndViewFeedBack/addFeedback.slice";
import studentFeedbackSlice from "../Pages/Feedback/Student Feedback/feedback.slice";
import employeeFeedbackDetailsSlice from "../Pages/Feedback/Employee FeedBack Portal/employeeFeedbackportal.slice";
import ActivitySlice from "../components/api.slice";
import prnNumberSlice from "../Pages/Masters/Faculty/AcademicDetails/PrnNumber/PrnNumber.slice";
import studentAttendanceSlice from "../Pages/StudentAttendance/StudentAttendanceForms/studentAttendance.slice";
import studentCertificateSlice from "../Pages/Certificate/Student/studentCertificate.slice";
import adminChangeStatusSlice from "../Pages/Certificate/AdminCertificate/AdminStatusChange.slice";
import unitTestSlice from "../Pages/UnitTest/UnitTestMaster/unitTestMaster.slice";
import teachingPlanSlice from "../Pages/TeachingPlan/teachingPlan.slice";
import userCredentialSlice from "../Pages/UserCredentials/UserCredentials.slice";
import ematerialSlice from "../Pages/Ematerial/ematerial.slice";
import outsideemployeeSlice from "../Pages/AllowOutSideFeedbackEmployee/outsideemployee.slice";
import accessroleSlice from "../Pages/UserAccess/accessRole.slice";
import SubjectSelectionSlice from "../Pages/SubjectSelection/SubjectSelection.slice";
import examFeesStructureSlice from "../Pages/Masters/Examination/Exam Fees Structure/examFeesStructure.slice";
import examFormDeclareSlice from "../Pages/ExamFormDeclaration/examform.slice";
import examconductSlice from "../Pages/Exam Module/ExamConduct/examconduct.slice";
import applyStudentSlice from "../Pages/Exam Module/StudentApplyAdmi/StudentApply.slice";
import teacherDashboardSlice from "../Pages/Dashboard/teacherDashboard.slice";
import studentDashboardSlice from "../Pages/Dashboard/Studentdashboard.slice";
import deemedCommitteeSlice from "../Pages/Deemed/Committee/committee.slice";
import deemedMeetingSlice from "../Pages/Deemed/Meeting/meeting.slice";
import adminNewsSlice from "../Pages/Alumini/AdminSideAlimini/AdminAddNews/adminNews.slice";
import adminVolunteerSlice from "../Pages/Alumini/AdminSideAlimini/AlumniVolunteerStatus/AdminVolunteerStatus.slice";
import adminViewAlumniInternshipSlice from "../Pages/Alumini/AdminSideAlimini/AlumniPostInternship/AdminViewInternship.slice";
import aluminiRegisterSlice from "../Pages/Alumini/Alumini Register/AluminiRegister.slice";
import achievementSlice from "../Pages/Alumini/Alumini Achievements/achivment.slice";
import folderSlice from "../Pages/Alumini/AdminGalleryFolder/adminGallary.slice";
import membersSlice from "../Pages/Alumini/Members/members.slice";
import alumniDashboardSlice from "../Pages/Dashboard/AlumniDashboard.slice";
import workExperienceSlice from "../Pages/Alumini/WorkExp/workExperiance.slice";
import studentFillProfileSlice from "../Pages/Alumini/StudentFillProfile/studentFillProfile.slice";
import aluminiSlice from "../Pages/Alumini/AdminSideAlimini/AluminiStatus/alumini.slice";
import alumniPasswordSlice from "../Pages/Alumini/Alumini Forgot Pasword/ForgotPassword.slice";
import internshipDocumentSlice from "../Pages/InternshipDocument/InternshipDocument.slice";
import parentRegisterSlice from "../Pages/Parent/Parent Register/ParentRegister.slice";
import parentPasswordSlice from "../Pages/Parent/Parent Register/ForgotPassword/ForgotPassword.slice";
import parentProfileSlice from "../Pages/Dashboard/parentDashboard.slice";
import alumniMentorStatusSlice from "../Pages/Alumini/AdminSideAlimini/AlumniMentorStatusChange/AlumniMentorStatus.slice";
import studentListForAlumniRegistrationSlice from "../Pages/Alumini/AdminAddAlumni/AdminAddAlumni.slice";
import promotionalAdmmisionSlice from "../Pages/PromotionalStudentAdmission/promotionalAdmission.slice";
const reducer = combineReducers({
  user: loginSlice,
  programType: programTypeSlice,
  division: divisionSlice,
  scholarship: ScholarshipTypeSlice,
  common: commonSlice,
  program: programSlice,
  institute: instituteSlice,
  landing: LandingSlice,
  financial: financialYearSlice,
  pattern: patternSlice,
  feeType: feeTypeSlice,
  class: classSlice,
  alertInfo: AlertSlice,
  fromFees: fromFeesSlice,
  receiptType: ReceiptTypeSlice,
  admissionType: AdmissionTypeSlice,
  schemeHead: SchemeHeadSlice,
  scheme: schemeSlice,
  admissionStatus: AdmissionStatusSlice,
  subjectTypeDetails: SubjectTypeDetailsSlice,
  feesTitle: FeesTitleSlice,
  semister: semisterInfoSlice,
  feesHeadDetail: feesHeadDetailsSlice,
  documentVerification: documentVerificationSlice,
  semisterDetails: semisterDetailsSlice,
  academicYear: academicYearSlice,
  subjectInfo: subjectInfoSlice,
  admissionForm: admissionFormSlice,
  feesStructureHead: feeStructureHeadSlice,
  feesCollection: feesCollectionSlice,
  programTitle: programTitleSlice,
  category: categorySlice,
  promotionalAdmission: PromotionalSlice,
  applicantAdmission: applicantAdmissionSlice,
  studentList: basicDetailsSlice,
  file: fileSlice,
  qualification: qualificationSlice,
  document: documentSlice,
  company: companySlice,
  internshipType: internshipTypeSlice,
  department: departmentSlice,
  designation: designationSlice,
  employeeList: employeeSlice,
  internshipDeclaration: InternshipDeclarationSlice,
  companyList: companyListSlice,
  companyPlacementList: PlacementCompanyListSlice,
  allocation: AllocationOfStudentSlice,
  allocationList: allocationListSlice,
  external: externalStudentSlice,
  rollNoList: rollNoListSlice,
  rollNo: rollnoSlice,
  placementDeclaration: placementDeclarationSlice,
  batch: batchSlice,
  studentBatch: studentBatchSlice,
  employeeWorkload: employeeWorkloadSlice,
  activity: activitySlice,
  innovativeTeaching: InnovativeTeaching,
  floor: floorSlice,
  room: roomSlice,
  timeSlot: timeSlotSlice,
  workloadAllocate: WorkloadAllocationSlice,
  timeTable: generateTimeTableSlice,
  resourcePerson: resourcePersonSlice,
  reserchJournalPublication: reserchJournalPublicationSlice,
  award: awardSlice,
  researchProject: resourceProjectSlice,
  courseAttended: courseAttendedSlice,
  researchGuide: researchguidanceSlice,
  seminarWebinar: seminarWebinarSlice,
  cocurricular: CoCurricularSlice,
  attendedSeminar: AttendedSeminarSlice,
  committee: committeeHandleSlice,
  grievance: grievanceSlice,
  consultancy: consultancySlice,
  extendedActivity: extendedActivitySlice,
  event: eventSlice,
  studentApplyEvent: StudentApplySlice,
  empNotice: noticeSlice,
  suggestions: suggestionsSlice,
  studNotice: studentNotice,
  empFeedback: feedbackSlice,
  studentFeedbackDetails: studentFeedbackDetailsSlice,
  feedBack: addFeedbackSlice,
  studentFeedback: studentFeedbackSlice,
  employeeFeedbackDetails: employeeFeedbackDetailsSlice,
  hrDetails: ActivitySlice,
  prnNumber: prnNumberSlice,
  studentAttendance: studentAttendanceSlice,
  studentCertificate: studentCertificateSlice,
  adminCertificateList: adminChangeStatusSlice,
  unitTestStore: unitTestSlice,
  teachingPlan: teachingPlanSlice,
  userCredential: userCredentialSlice,
  ematerial: ematerialSlice,
  outSideEmployee: outsideemployeeSlice,
  accessRole: accessroleSlice,
  subjectSelection: SubjectSelectionSlice,
  examFeesStructure: examFeesStructureSlice,
  examDeclare: examFormDeclareSlice,
  examConduct: examconductSlice,
  applyStudent: applyStudentSlice,
  teacherDashboard: teacherDashboardSlice,
  studentDashboard: studentDashboardSlice,
  deemedCommittee: deemedCommitteeSlice,
  deemedMeeting: deemedMeetingSlice,
  newsRoom: adminNewsSlice,
  volunteerStatus: adminVolunteerSlice,
  adminViewAlumniInternship: adminViewAlumniInternshipSlice,
  alumniRegister: aluminiRegisterSlice,
  achievements: achievementSlice,
  folder: folderSlice,
  members: membersSlice,
  alumniDashboard: alumniDashboardSlice,
  workExperience: workExperienceSlice,
  studentFillProfile: studentFillProfileSlice,
  alumini: aluminiSlice,
  internshipDocument: internshipDocumentSlice,
  parentRegister: parentRegisterSlice,
  parentProfile: parentProfileSlice,
  parentPassword: parentPasswordSlice,
  alumniPassword: alumniPasswordSlice,
  alumniMentorStatus: alumniMentorStatusSlice,
  studentListForAlumniRegistration: studentListForAlumniRegistrationSlice,
  promotionalAdmmision: promotionalAdmmisionSlice,
});

const rootReducer = (state, action) => {
  if (action.type === UserActionTypes.LOGOUT) {
    state = undefined;
  }
  if (action.type === UserActionTypes.ReserRedux) {
    state = undefined;
  } else {
  }
  return reducer(state, action);
};
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;
