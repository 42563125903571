import endpoint from "../config/endpoints";

export const AlumniResoursePersonJson = {
  apiBaseURL: endpoint.volunteerUpdateStatus,
  screenTitle: "Be a Resource Person",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Role",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "RoleMaster",
      dataKey: "interest",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area of Expertise",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expertise",
      isMandatory: true,
    },
    {
      label: "Time you can contribute (In hours)",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "time",
      isMandatory: true,
    },
  ],
};
