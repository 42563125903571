import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  achievements: [],
  isFetch: false,
};
let URL = endpoints.achievements;
const achievementsSlice = createSlice({
  name: "achievements",
  initialState,
  reducers: {
    achievementsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        achievements: row,
        isFetch: true,
      };
    },
  },
});

export const { achievementsSuccess } = achievementsSlice.actions;

export default achievementsSlice.reducer;

export const getAchievements = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            title: data1.title,
            description: data1.description,
            date: data1.date,
            achievementType: data1.achievementType,
            detail: data1.detail,
            // isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(achievementsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAchievementsByAluminiId =
  ({ id = "" }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-alumni-registration-id?id=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              description: data1.description,
              date: data1.date,
              achievementType: data1.achievementType,
              detail: data1.detail,
            };
            row.push(bData);
            return data1;
          });
          dispatch(achievementsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
