import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  appliedStudentList: [],
  isFetch: false,
  appliedPlacedStudentList: [],
  isPlacedFetch: false,
};

let URL = endpoints.intrestedAndNotIntrestedStudent;
let URL2 = endpoints.placementIntrestedAndNotIntrestedStudent;

const externalStudentSlice = createSlice({
  name: "externalStudent",
  initialState,
  reducers: {
    externalStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        appliedStudentList: row,
        isFetch: true,
      };
    },
    externalPlacedStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        appliedPlacedStudentList: row,
        isPlacedFetch: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  externalStudentSuccess,
  externalPlacedStudentSuccess,
  resetSuccess,
} = externalStudentSlice.actions;

export default externalStudentSlice.reducer;

export const getStudentList =
  ({ academicYearId, YearDetail, division, internType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-internship-intrested-student-by-intern-type-academicYear-divisionIds?academicYearId=" +
          academicYearId +
          "&yearDetailId=" +
          YearDetail +
          "&divisionIds=" +
          division +
          "&internshipType=" +
          internType,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const companyList = data.data;
          console.log(companyList);
          let row = [];
          let index = 0;
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              id: data.id,
              index: index,
              lastName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.lastName.toUpperCase(),
              firstName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.firstName.toUpperCase(),
              middleName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.middleName.toUpperCase(),
              fatherName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.fatherName.toUpperCase(),
              motherName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.motherName.toUpperCase(),
              studentName:
                data.getUserRegistration == null
                  ? " "
                  : data.getUserRegistration.lastName.toUpperCase() +
                    " " +
                    data.getUserRegistration.firstName.toUpperCase() +
                    " " +
                    (data.getUserRegistration.fatherName == null ||
                    data.getUserRegistration.fatherName == ""
                      ? " - "
                      : data.getUserRegistration.fatherName.toUpperCase()) +
                    " " +
                    (data.getUserRegistration.motherName == null ||
                    data.getUserRegistration.motherName == ""
                      ? " - "
                      : data.getUserRegistration.motherName.toUpperCase()),
              companyName: data.companyName,
              status: data.vivaStatus == 1 ? "Done" : "Not Done",
            };
            return companyData;
          });
          dispatch(externalStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPlacedStudentList =
  ({ academicYearId, YearDetail, division, placementType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/get-placement-intrested-student-by-intern-type-academicYear-divisionIds?academicYearId=" +
          academicYearId +
          "&yearDetailId=" +
          YearDetail +
          "&divisionIds=" +
          division +
          "&placementType=" +
          placementType,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const companyList = data.data;
          console.log(companyList);
          let row = [];
          let index = 0;
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              id: data.id,
              index: index,
              rollNo: data.rollNo,
              studentName: data.userRegistrationName.toUpperCase(),
              companyName: data.companyName,
            };
            return companyData;
          });
          dispatch(externalPlacedStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
