import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
const initialState = {
  parentProfile: [],
  isFetch: false,
  parentChild: [],
  isFetchChild: false,
};
let URL = endpoints.profileInfo;
let URL2 = endpoints.parentPostLogin;
const parentProfileSlice = createSlice({
  name: "parentProfile",
  initialState,
  reducers: {
    profileSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        parentProfile: row,
        isFetch: true,
      };
    },
    parentChildSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        parentChild: row,
        isFetchChild: true,
      };
    },
  },
});

export const { profileSuccess, parentChildSuccess } =
  parentProfileSlice.actions;

export default parentProfileSlice.reducer;

export const getParentProfile = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const profile = data.data;
        let row = [];
        if (profile !== null) {
          localStorage.setItem(
            "userName",
            profile.firstName.charAt(0) +
              "." +
              profile.middleName.charAt(0) +
              "." +
              profile.lastName
          );
        }
        // content.map((data1) => {
        //   index = index + 1
        //   let bData = {
        //     index: index,
        //     id: data1.id,
        //     name: data1.name,
        //     isActive: data1.isActive,

        //   };
        //   row.push(bData);
        //   return data1;
        // });
        dispatch(profileSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// /api/acdmc/v1/parent-registration/get-student-by-parent-mobile

export const getParentChild = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2 + "/get-student-by-parent-mobile",
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        const content = data.data;
        console.log(content);
        let index = 0;
        let row = [];
        content.map((data1) => {
          let bData = {
            // index: index,
            id: data1.id,
            name:
              data1.firstName + " " + data1.middleName + " " + data1.lastName,
          };
          row.push(bData);
          return data1;
        });
        console.log(row);
        localStorage.setItem("parentChildList", JSON.stringify(row));
        dispatch(parentChildSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
