import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AttachFile from "../../../components/Comman/AttachFile";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import TimePicker from "../../../components/Comman/TimePicker";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getYearDetails } from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { openFileInNewTab } from "../../UploadFile/file.slice";
import { getEventByAcademicId } from "./event.slice";

let docs;
class AddEvent extends Component {
  state = {
    id: 0,
    name: "",
    details: "",
    yearDetail: [],
    fromDate: "",
    toDate: "",
    venue: "",
    time: null,
    toTime: null,
    applicationLastDate: "",
    amountApplicable: 0,
    amount: "",
    formErrors: [],
    documentName: "",
    fileName: "",
    file: "",
    yearDeatilList: [],
    uploadError: false,
    upload: false,
    fileNameFlag: false,
  };
  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");
    if (filterValue.length !== 0) {
      let yearlist = [];
      this.state.yearDeatilList.map((data) => {
        if (data.id != "all") {
          yearlist.push(data.id);
        }
      });
      this.setState({
        [name]: yearlist,
      });
    } else {
      this.setState({
        ...this.state,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  timeChangeHandler = (dataKey) => (time) => {
    this.setState({
      [dataKey]: time,
    });
  };

  handleFormValidation() {
    const {
      name,
      details,
      fromDate,
      toDate,
      yearDetail,
      venue,
      time,
      toTime,
      applicationLastDate,
      amountApplicable,
      amount,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (name.toString().trim() === "" || name === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.nameMsgRequired;
    }

    // if ( fileName.toString().trim() === "" || fileName === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "fileNameError" ] = myConstClass.docNameMsg;
    // }

    if (details.toString().trim() === "" || details === null) {
      formIsValid = false;
      formErrors["detailsError"] = myConstClass.detailsMsg;
    }

    if (!yearDetail.length) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (fromDate === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    if (venue.toString().trim() === "" || venue === null) {
      formIsValid = false;
      formErrors["venueError"] = myConstClass.venueMessage;
    }

    if (time === "" || time === null) {
      formIsValid = false;
      formErrors["timeError"] = myConstClass.fromTimeMsg;
    }
    if (toTime === "" || toTime === null) {
      formIsValid = false;
      formErrors["toTimeError"] = myConstClass.toTimeMsg;
    }
    // if (applicationLastDate === "" || applicationLastDate === null) {
    //   formIsValid = false;
    //   formErrors["applicationLastDateError"] =
    //     myConstClass.applicationLastDateMsg;
    // }

    if (amountApplicable == 1 && amount === "") {
      formIsValid = false;
      formErrors["amountError"] = myConstClass.amountMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      name,
      details,
      fromDate,
      toDate,
      yearDetail,
      venue,
      time,
      toTime,
      applicationLastDate,
      amountApplicable,
      amount,
      fileName,
    } = this.state;
    let frmDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    let applidate =
      applicationLastDate !== null
        ? moment(applicationLastDate).format("DD-MM-YYYY")
        : "";
    // let changeTime = time === null ? "" : moment( time ).format( "hh:mm" );
    // let currentTime = time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();
    // let currentTime = time.toTimeString().slice( 0, 8 );
    console.log(applicationLastDate);
    console.log(applidate);
    // new
    let changeTime =
      time === null ? "" : moment(time, "h:mm:ss A").format("HH:mm");
    let changeToTime =
      toTime === null ? "" : moment(toTime, "h:mm:ss A").format("HH:mm");
    if (Object.keys(this.props.editData).length !== 0) {
      docs = {
        name: name,
        eventDetails: details,
        fromDate: frmDt,
        toDate: toDt,
        yearDetailIds: yearDetail.join(),
        venue: venue,
        time: changeTime,
        toTime: changeToTime,
        applicationLastDate:
          this.props.editData.applicationLastDate !== null ? applidate : null,
        amountApplicable: amountApplicable,
        amount: amount,
        isActive: 1,
      };
    } else {
      docs = {
        name: name,
        eventDetails: details,
        fromDate: frmDt,
        toDate: toDt,
        yearDetailIds: yearDetail.join(),
        venue: venue,
        time: changeTime,
        toTime: changeToTime,
        applicationLastDate: applicationLastDate !== "" ? applidate : null,
        amountApplicable: amountApplicable,
        amount: amount,
        isActive: 1,
      };
    }

    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (this.handleFormValidation()) {
      this.onSave(docs);
      this.props.onCancel();
    }
  };
  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    const { editData } = this.props;
    if (Object.keys(editData).length !== 0) {
      console.log(editData.applicationLastDate);
      const durationForArr =
        editData.fromDate !== "" ? editData.fromDate.split("-") : "";
      const durationFor =
        durationForArr !== ""
          ? new Date(
              durationForArr[2],
              durationForArr[1] - 1,
              durationForArr[0]
            )
          : "";

      const toDateArr =
        editData.toDate !== "" ? editData.toDate.split("-") : "";
      const changedToDate =
        toDateArr !== ""
          ? new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0])
          : "";

      const applicationLastDateArr =
        editData.applicationLastDate !== null
          ? editData.applicationLastDate.split("-")
          : "";
      const changedApplicationLastDate =
        applicationLastDateArr !== ""
          ? new Date(
              applicationLastDateArr[2],
              applicationLastDateArr[1] - 1,
              applicationLastDateArr[0]
            )
          : "";

      // const fromTimeArr = editData.time !== "" ? editData.time.split( ":", -1 ) : null;
      // var changedTime = fromTimeArr !== null ? new Date( null, null, null, fromTimeArr[ 0 ], fromTimeArr[ 1 ] ) : null;

      const fromTimeDate =
        editData.time !== ""
          ? moment(editData.time, ["h:mm A"]).format("HH:mm")
          : "";
      const fromTimeArr =
        fromTimeDate !== " " ? fromTimeDate.split(":", -1) : null;
      const changedTime =
        fromTimeArr !== null
          ? new Date(null, null, null, fromTimeArr[0], fromTimeArr[1])
          : null;

      const toTimeDate =
        editData.toTime !== ""
          ? moment(editData.toTime, ["h:mm A"]).format("HH:mm")
          : "";
      const toTimeArr = toTimeDate !== " " ? toTimeDate.split(":", -1) : null;
      const changedToTime =
        toTimeArr !== null
          ? new Date(null, null, null, toTimeArr[0], toTimeArr[1])
          : null;

      this.setState({
        ...this.state,
        id: editData.id,
        name: editData.name,
        details: editData.documentUpload,
        fromDate: durationFor,
        yearDetail: editData.yearDetailIds.split(","),
        toDate: changedToDate,
        venue: editData.venue,
        time: changedTime,
        toTime: changedToTime,
        applicationLastDate:
          editData.applicationLastDate === null
            ? ""
            : changedApplicationLastDate,
        amountApplicable: editData.amountApplicable,
        amount: editData.amount,
        file: editData.documentUpload != null ? editData.documentUpload : "",
        details: editData.eventDetails,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        let yearList = [
          { id: "all", name: "All" },
          ...nextProps.internshipDeclarationList.yearDetails.filter(
            (data) => data.isActive == 1
          ),
        ];
        this.setState({ yearDeatilList: yearList });
      }
    }
  }
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  onSave = (dataToSave) => {
    const ay = localStorage.getItem("acadamicYearId");

    if (this.state.fileName !== "") {
      const formData = new FormData();
      formData.append("file", this.state.fileName);
      apiPost({
        url: endpoint.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          dataToSave = {
            ...dataToSave,
            documentUpload: data,
          };
          apiPost({
            url: endpoint.event,
            postBody: dataToSave,
          }).then(({ success }) => {
            if (success) {
              this.props.showNotification({
                msg:
                  this.state.id != 0
                    ? "Data saved successfully"
                    : "Data updated successfully",
              });
            } else {
              this.props.getAlert({
                message: "Failed to save",
              });
            }
          });
        } else {
          this.props.getAlert({
            message: "Failed to upload document",
          });
        }
      });
    } else {
      if (this.state.file !== "") {
        dataToSave = {
          ...dataToSave,
          documentUpload: this.state.file,
        };
      }
      apiPost({
        url: endpoint.event,
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.props.showNotification({
            msg: "Data saved successfully",
          });
          this.props
            .getEventByAcademicId({
              id: ay,
            })
            .then((response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
              }
            });
        } else {
          this.props.getAlert({
            message: "Failed to save",
          });
        }
      });
    }
  };
  onClickViewPreview = (fileName) => {
    this.props.openFileInNewTab({ file: fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  render() {
    const {
      name,
      fileName,
      upload,
      documentName,
      details,
      uploadError,
      fromDate,
      toDate,
      yearDetail,
      venue,
      time,
      toTime,
      applicationLastDate,
      amountApplicable,
      amount,
      fileNameFlag,
      yearDeatilList,
    } = this.state;
    const { onCancel, internshipDeclarationList } = this.props;
    const {
      nameError,
      detailsError,
      yearDetailError,
      fromDateError,
      toDateError,
      venueError,
      timeError,
      applicationLastDateError,
      amountError,
      fileNameError,
      toTimeError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Event Form"
              />
            </Grid>
            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={onCancel}
              />
            </Grid>
          </Grid>
          <br />
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Name "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="name"
                      size="small"
                      value={name}
                      onChange={this.changeHandler}
                      fullWidth
                      // {...( nameError
                      //     ? { error: true, helperText: nameError }
                      //     : "" )}

                      error={nameError ? true : false}
                      helperText={nameError ? nameError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="details"
                      size="small"
                      value={details}
                      onChange={this.changeHandler}
                      fullWidth
                      //   {...(detailsError
                      //     ? { error: true, helperText: detailsError }
                      //     : "")}

                      error={detailsError ? true : false}
                      helperText={detailsError ? detailsError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Detail "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        er={yearDetailError}
                        name1="yearDetail"
                        value1={yearDetail}
                        names={yearDeatilList}
                        onChange={this.multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date "
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange("fromDate")}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date "
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        minDate={fromDate}
                        onChange={this.onDateChange("toDate")}
                        isError={toDateError ? true : false}
                        errorText={toDateError ? toDateError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Time "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TimePicker
                        value={time}
                        onChange={this.timeChangeHandler("time")}
                        error={timeError ? timeError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Time "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TimePicker
                        value={toTime}
                        onChange={this.timeChangeHandler("toTime")}
                        error={toTimeError ? toTimeError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={2}
                    lg={2}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Document Name "
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      {this.state.file !== "" && (
                        <MyComponentWithIconProps
                          statusImage={VisibilityIcon}
                          color="primary"
                          fontSize="medium"
                          title="View"
                          onClick={(e) =>
                            this.onClickViewPreview(this.state.file)
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={9}
                    lg={9}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <AttachFile name="documentName" fileName={this.changeH} />
                      &nbsp; {fileName !== "" ? fileName.name : ""}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Application Last Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="applicationLastDate"
                        size="small"
                        value={applicationLastDate}
                        // maxDate={toDate}
                        fullWidth
                        onChange={this.onDateChange("applicationLastDate")}
                        // isError={applicationLastDateError ? true : false}
                        // errorText={
                        //   applicationLastDateError
                        //     ? applicationLastDateError
                        //     : " "
                        // }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Venue "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="venue"
                        size="small"
                        value={venue}
                        onChange={this.changeHandler}
                        fullWidth
                        error={venueError ? true : false}
                        helperText={venueError ? venueError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                // rowSpacing={2}
                // columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Amount Applicable"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="amountApplicable"
                        id="amountApplicable"
                        name="amountApplicable"
                        value={amountApplicable}
                        onChange={this.changeHandler}
                        size="small"
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      <FormHelperText> </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {amountApplicable == "1" && (
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Amount"
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    {amountApplicable == "1" && (
                      <TextField
                        color="primary"
                        name="amount"
                        size="small"
                        type="number"
                        value={amount}
                        onChange={this.changeHandler}
                        fullWidth
                        error={amountError ? true : false}
                        helperText={amountError ? amountError : " "}
                      />
                    )}
                  </Grid>
                </Grid>
                {/* {amountApplicable == "0" ? (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}></Grid>
                      <Grid item xs={12} sm={12} md={12}></Grid>
                    </Grid>
                  </>
                ) : null} */}

                {/* {amountApplicable == "1" ? (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Amount"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="amount"
                          size="small"
                          type="number"
                          value={amount}
                          onChange={this.changeHandler}
                          fullWidth
                          error={amountError ? true : false}
                          helperText={amountError ? amountError : " "}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null} */}
              </Grid>

              <Grid
                sx={{ mr: 0.5 }}
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                m={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fileData: state.file,
  academicYear: state.academicYear,
  internshipDeclarationList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAcademicYear,
  // deleteFileData,
  getYearDetails,
  openFileInNewTab,
  getEventByAcademicId,
  // viewMultipleFile
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
