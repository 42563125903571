import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import { apiPost } from "../../../utils/api_service";
import { showNotification } from "../../Landing/Landing.slice";
import { getStudentFeedbackSubject } from "./studentFeedbackPortal.slice";
import DynamicTable from "./StudentFeedBackPortalTable";
import { getAlert } from "../../../CommonActions/alert.slice";

function StudentFeedbackForm({
  onCancel,
  editData,
  showNotification,
  studentFeedbackDetailsList,
  getStudentFeedbackSubject,
  backToList,
  getAlert,
}) {
  const [values, setValues] = React.useState("");
  const [teacherId, setTeacherId] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");
  const [teacherError, setTeacherError] = React.useState(false);
  const [subjectError, setSubjectError] = React.useState(false);
  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name === "teacherId") {
        setSubjectId("");
        getStudentFeedbackSubject({
          teacherId: newValue,
          studentFeedbackId: editData.id,
        }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
        setTeacherId(newValue);
      }
      if (name === "subjectId") {
        setSubjectId(newValue);
      }
    } else {
      if (name === "teacherId") {
        setTeacherId("");
      }
      if (name === "subjectId") {
        setSubjectId("");
      }
    }
  };

  const handleFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (subjectId === "") {
      formIsValid = false;
      setSubjectError(true);
    }

    if (teacherId === "") {
      formIsValid = false;
      setTeacherError(true);
    }

    return formIsValid;
  };

  const setQuaData = (data) => {
    const dataToPush = data.map((data1) => {
      let bData = {
        feedbackHeadDetails: {
          id: data1.id,
        },
        value: data1.answer,
      };
      return bData;
    });
    let pushData = {
      studentFeedback: {
        id: editData.id,
      },
      studentFeedbackTransactionDetails: dataToPush,
    };

    if (editData.isSubject === 1) {
      if (handleFormValidation()) {
        const subjectList =
          studentFeedbackDetailsList?.studentFeedbackSubject.filter(
            (subjectData) => subjectData.id === subjectId
          );
        pushData = {
          ...pushData,
          subject: {
            id: subjectId,
          },
          subjectDetailId: subjectList[0].subjectDetail,
          facultyRegistration: {
            id: teacherId,
          },
        };

        swal({
          title: "Are you sure?",
          text: "Do you want to save the feedback?",
          icon: "warning",
          buttons: ["Cancel", true],
        }).then((res) => {
          if (res) {
            apiPost({
              url: endpoint.studentFeedbackTransaction,
              postBody: pushData,
            }).then(({ success }) => {
              if (success) {
                showNotification({ msg: "Data saved successfully" });
                backToList();
              } else {
                getAlert({
                  message: "Failed to save",
                });
              }
            });
          }
        });
      }
    } else {
      console.log(pushData);

      swal({
        title: "Are you sure?",
        text: "Do you want to save the feedback?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          apiPost({
            url: endpoint.studentFeedbackTransaction,
            postBody: pushData,
          }).then(({ success }) => {
            if (success) {
              showNotification({ msg: "Data saved successfully" });
              backToList();
            } else {
              getAlert({
                message: "Failed to save",
              });
            }
          });
        }
      });
    }
    setValues(pushData);
    console.log(pushData);
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Questions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Feedback Details"
            />
          </Grid>
          <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back to List"
              onClick={onCancel}
            />
          </Grid>
        </Grid>
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={2} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Feedback Name "
              />
            </Grid>

            <Grid item xs={12} sm={10} md={10}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={editData !== undefined ? editData.feedbackHead : ""}
              />
            </Grid>
          </Grid>
        </Grid>
        {editData !== undefined && editData.feedbackDetails !== "" ? (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={2} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Instruction"
              />
            </Grid>
            <Grid item xs={12} sm={10} md={10}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={editData.feedbackDetails}
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={2} md={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Date"
            />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={
                editData !== undefined &&
                editData.fromDate + "/" + editData.toDate
              }
            />
          </Grid>
        </Grid>
        {editData.isSubject === 1 && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Teacher "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={teacherId}
                    name={"teacherId"}
                    options={
                      studentFeedbackDetailsList?.studentFeedbackTeacher
                        ? studentFeedbackDetailsList?.studentFeedbackTeacher
                        : []
                    }
                    onChange={ChangeHandlerSearch}
                    isError={teacherError ? true : false}
                    errorText={teacherError ? "Enter teacher name" : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Subject "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={subjectId}
                    name={"subjectId"}
                    options={
                      studentFeedbackDetailsList?.studentFeedbackSubject
                        ? studentFeedbackDetailsList?.studentFeedbackSubject
                        : []
                    }
                    onChange={ChangeHandlerSearch}
                    isError={subjectError ? true : false}
                    errorText={subjectError ? "Enter subject name" : " "}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <DynamicTable
          data={studentFeedbackDetailsList?.studentFeedbackDetails}
          tableHead={columns}
          showPegination={false}
          rowData={setQuaData}
          isActionColActive={false}
          //   rowStatus={onChangeAction}
        />
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  studentFeedbackDetailsList: state.studentFeedbackDetails,
});
const mapDispatchToProps = {
  getStudentFeedbackSubject,
  getAlert,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFeedbackForm);
