import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { FilterSave } from "../../components/FilterSave";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { vivaDeclarationListDynamicJson } from "../../DynamicFormsJson/VivaDeclarationListDynamic";
import { apiDelete } from "../../utils/api_service";
import {
  getAllocationListAdminSide,
  getViviDeclareById,
  resetSuccess,
} from "../AllocationOfStudent/AllocationOfStudent.slice";
import MarkAllocationPopUp from "../AllocationOfStudent/StudentMarksViewPopUp";
import TeacherStudentListDialoge from "../AllocationOfStudent/TeacherStudentListDialoge";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { showNotification } from "../Landing/Landing.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { downloadF } from "../PDF/PDFDownload";
const data = [
  { no: 1, name: "1st Allocation", percentage: "Yes" },
  { no: 1, name: "2nd Allocation", percentage: "Yes" },
];

class AdminStudentAllocation extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      academicYear: "",
      yearDetail: "",
      programType: "",
      internType: "",
      formErrors: {},
      markData: {},
      fieldData: {},
      markShow: false,
      showLoader: false,
      open: false,
      actionVariable: false,
      searchValue: "",
      dynamicMasterData: {
        internshipMaster: this.props.internshipTypeList?.internshipType,
        yearDetailMaster: this.props.internshipDeclarationList?.yearDetails,
      },
    };
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },

    {
      name: "year",
      title: "Year Details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
      canSearch: true,
    },
    {
      name: "division",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
      canSearch: true,
    },
    {
      name: "remark",
      title: "Remark",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
      canSearch: true,
    },
    {
      name: "status",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];
  rowViewData = (data) => {
    console.log(data);

    this.setState({
      markData: data,
      markShow: true,
    });
  };

  handleCloses = (data) => {
    this.setState({
      markData: {},
      markShow: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    const dataToSearch = {};
    if (this.props.internshipTypeList !== nextProps.internshipTypeList) {
      if (
        this.props.internshipTypeList.internshipType !==
        nextProps.internshipTypeList.internshipType
      ) {
        const yearDetailId = localStorage.getItem("yearDetailId");
        if (
          yearDetailId &&
          nextProps.internshipTypeList.internshipType.length !== 0 &&
          nextProps.internshipTypeList.internshipType[0].id
        ) {
          const { getAllocationListAdminSide, getAlert } = this.props;
          const ay = localStorage.getItem("acadamicYearId");
          this.setState({
            showLoader: true,
          });
          getAllocationListAdminSide({
            academicYearId: ay,
            yearDetailId: yearDetailId,
            internType: nextProps.internshipTypeList.internshipType[0].id,
          }).then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
          dataToSearch["internship"] =
            nextProps.internshipTypeList.internshipType[0].id;
        }
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            internshipMaster: nextProps.internshipTypeList.internshipType,
          },
          fieldData: dataToSearch,
        });
      }
    }
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
    }
  }

  onDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        console.log(rowData);
        apiDelete({
          url: endpoints.vivaDeclare + "/" + rowData.id,
        }).then(({ success }) => {
          if (success) {
            const { academicYear, yearDetail, internType } = this.state;
            const { getAllocationListAdminSide, getAlert } = this.props;
            const ay = localStorage.getItem("acadamicYearId");
            this.setState({
              showLoader: true,
            });
            getAllocationListAdminSide({
              academicYearId: ay,
              yearDetailId: rowData.yearDetailId,
              internType: rowData.internshipType,
            }).then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
            this.props.showNotification({ msg: "Data deleted successfully" });
          } else {
            this.setState({
              showLoader: false,
            });
            getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };
  rowView = (rowData) => {
    console.log(rowData);
    this.props.getViviDeclareById({
      vivaDeclareId: rowData.id,
    });
    this.setState({
      open: true,
      actionVariable: true,
      markData: rowData,
    });
  };

  pdfDetailsId = (row) => {
    console.log(row);

    downloadF({
      url:
        "/api/report/v1/internship-viva-teacher-student?vivaDeclareId=" +
        row.id +
        "&documentType=1",
      ext: "pdf",
      openNewTab: true,
    });
  };

  excelDetailsId = (row) => {
    console.log(row);
    downloadF({
      url:
        "/api/exportexcel/v1/internship-viva-declare-excel-report?response&vivaDeclareId=" +
        row.id,
      ext: "xls",
      openNewTab: false,
    });
  };

  componentDidMount() {
    const {
      getInternshipType,
      getAlert,
      getAcademicYear,
      getYearDetails,
      resetSuccess,
      resetReducList,
    } = this.props;
    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    getInternshipType().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    resetSuccess();
    resetReducList();
  }
  handleFormValidation() {
    const { academicYear, yearDetail, programType, internType } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (internType.toString().trim() === "" || internType === null) {
      formIsValid = false;
      formErrors["internTypeError"] = myConstClass.internTypeMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  onSearchData = (data) => {
    const { getAllocationListAdminSide, getAlert } = this.props;
    const ay = localStorage.getItem("acadamicYearId");
    this.setState({
      showLoader: true,
      internType: data.internship,
      yearDetail: data.yearDetail,
    });
    localStorage.setItem("semisterDetailId", "");
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("divisionIds", "");
    FilterSave();
    getAllocationListAdminSide({
      academicYearId: ay,
      yearDetailId: data.yearDetail,
      internType: data.internship,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onAddButtonClick = () => {
    window.location.replace("/allocation-of-student");
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  closeAllocation = () => {
    this.setState({
      open: false,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.allocationList.allocationList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["year"] &&
          currentRow["year"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["division"] &&
          currentRow["division"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["remark"] &&
          currentRow["remark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  render() {
    const { markShow, markData, dynamicMasterData, showLoader, fieldData } =
      this.state;
    console.log(this.props.allocationList.teacherStudentList);
    return (
      <>
        {/* <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid sx={{ mt: 1 }} item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Viva Declaration List"
            />
          </Grid>
          <Grid
            sx={{ mt: 1 }}
            item
            xs={12}
            sm={6}
            md={6}
            container
            justifyContent="flex-end"
          >
            <Link
              color="primary"
              variant="body2"
              component={RouterLink}
              to="/allocation-of-student"
            >
              <MyComponentWithIconProps
                statusImage={AddCircleIcon}
                color="primary"
                fontSize="large"
                title="Declare Viva"
              />
            </Link>
          </Grid>
        </Grid>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={1}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              columnSpacing={3}
              xs={12}
              sm={6}
              md={6}
              sx={{ marginTop: 3 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Year Details"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={yearDetail}
                  name={"yearDetail"}
                  options={internshipDeclarationList?.yearDetails}
                  onChange={this.ChangeHandlerSearch}
                  isError={yearDetailError ? true : false}
                  errorText={yearDetailError ? yearDetailError : " "}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-evenly"
              Spacing={1}
              direction="row"
              xs={12}
              sm={6}
              md={6}
              sx={{ marginTop: 3 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Intern Type"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={internType}
                  name={"internType"}
                  options={internshipTypeList?.internshipType}
                  onChange={this.ChangeHandlerSearch}
                  isError={internTypeError ? true : false}
                  errorText={internTypeError ? internTypeError : " "}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid item xs={12} sm={5} md={5}></Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Search"
                    onClick={this.submitHandler}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={5}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          sx={{ marginTop: 1, marginBottom: 2 }}
          item
          xs={12}
          sm={12}
          md={12}
        >
          <SearchVivaList onSearch={this.onSearch} />
        </Grid>

        <Grid item sx={{ mr: 1 }} xs={12} sm={12} md={12}>
          <DynamicTable
            data={this.getFilteredTableData()}
            tableHead={this.columns}
            showHeadDelete={true}
            marginTop={-1}
            rowDelete={this.onDelete}
            rowViewData={this.rowView}
            showPegination={false}
            showView={true}
            pdfDetailsId={this.pdfDetailsId}
            showHeadPdf={true}
            excelDetailsId={this.excelDetailsId}
            showHeadExcel={true}
          />
        </Grid> */}

        <DynamicMainScreen
          onAddButtonClick={this.onAddButtonClick}
          screenTitle={vivaDeclarationListDynamicJson.screenTitle}
          fieldMeta={vivaDeclarationListDynamicJson.fieldMeta}
          buttonCenter={vivaDeclarationListDynamicJson.buttonCenter}
          showPdfDownload={vivaDeclarationListDynamicJson.showPdfDownload}
          showExcelDownload={vivaDeclarationListDynamicJson.showExcelDownload}
          pdfFileName={vivaDeclarationListDynamicJson.pdfFileName}
          excelFileName={vivaDeclarationListDynamicJson.excelFileName}
          tableColumnsToFilter={
            vivaDeclarationListDynamicJson.tableColumnsToFilter
          }
          showAddButton={vivaDeclarationListDynamicJson.showAddButton}
          dynamicMasterData={dynamicMasterData}
          tableColumns={this.columns}
          tableData={this.props.allocationList.allocationList}
          getListById={this.getListById}
          showHeadEdit={false}
          rowAppliedList={this.rowAppliedList}
          showAppliedList={false}
          rowDetails={this.rowDetails}
          onDelete={this.onDelete}
          fieldData={fieldData}
          showDetails={false}
          onSearchData={this.onSearchData}
          baseIdColumn={vivaDeclarationListDynamicJson.baseIdColumn}
          apiBaseURL={vivaDeclarationListDynamicJson.apiBaseURL}
          showLoader={showLoader}
          tableHead={this.columns}
          showHeadDelete={true}
          marginTopTable={-1}
          rowDelete={this.onDelete}
          rowViewData={this.rowView}
          showPegination={false}
          showView={true}
          pdfDetailsId={this.pdfDetailsId}
          showHeadPdf={true}
          excelDetailsId={this.excelDetailsId}
          showHeadExcel={true}
        />

        <TeacherStudentListDialoge
          open={this.state.open}
          allocationList={this.props.allocationList.teacherStudentList}
          handleClickOpen={this.closeAllocation}
          rowViewData={this.rowViewData}
          actionVariable={this.state.actionVariable}
          markData={markData}
        />
        {markShow && (
          <>
            <MarkAllocationPopUp
              markData={markData}
              handleCloses={this.handleCloses}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  allocationList: state.allocation,
  internshipDeclarationList: state.internshipDeclaration,
  internshipTypeList: state.internshipType,
});
const mapDispatchToProps = {
  getInternshipType,
  getAlert,
  showNotification,
  getYearDetails,
  getAllocationListAdminSide,
  getViviDeclareById,
  resetSuccess,
  resetReducList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminStudentAllocation);
