import { Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { Loading1 } from "../../../components/Loading1";
import endpoints from "../../../config/endpoints";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../utils/api_service";
import { downloadF } from "../../PDF/PDFDownload";
import { getDeemedCommittee } from "./committee.slice";
import CommitteeForm from "./committeeForm";
import MemberForm from "./memberForm";
import MemberTableMain from "./MemberTableMain";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DeemedCommittee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showLoader: false,
      showForm: false,
      showTable: true,
      showMember: false,
      editData: {},
      committeeData: {},
      searchValue: "",
    };
  }

  OnClickEdit = (data) => {
    this.setState({
      editData: data,
      showForm: true,
      showTable: false,
    });
  };

  OnClickAdd = (data) => {
    this.setState({
      showForm: false,
      showTable: false,
      showMember: true,
      committeeData: data,
    });
  };

  onClickView = (data) => {
    this.setState({
      showForm: false,
      showTable: false,
      showMember: true,
      committeeData: data,
    });
  };

  submitHandler = () => {};

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "name",
      title: "Committee Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 200,
    },
    {
      name: "venue",
      title: "Committee Details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 200,
    },
    {
      name: "isActive",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: 170,
    },
  ];

  onChangeAction = (rowData) => {};

  toggleFormTableVisibility = () => {
    const { showTable, showForm } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      editData: {},
    });
    this.props.getDeemedCommittee().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  backToForm = () => {
    this.setState({
      showForm: false,
    });
  };

  backTo = () => {};

  onFormCancel = () => {};

  componentWillReceiveProps(nextProps) {}

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {};

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {};
  rowEdit = (rowData) => {};

  componentDidMount() {
    this.props.getDeemedCommittee().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      editData: {},
    });
  }

  changeStatus = (e, rowData) => {
    let docs = {
      id: rowData.id,
      name: rowData.name,
      details: rowData.details,
      userRegistrationIds: rowData.userRegistration,
      departmentIds: rowData.departmentIds,
      isActive: rowData.isActive === 1 ? 0 : 1,
    };
    swal({
      title: "Are you sure?",
      text: "Do you want to change status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        apiPost({
          url: endpoints.deemedCommittee,
          postBody: docs,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Status updated",
            });
            this.props.getDeemedCommittee().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoints.deemedCommittee + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props.getDeemedCommittee().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
    });
  };

  onFormSave = (isToggle) => () => {};

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL, getAlert } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: endpoints.deemedCommittee,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.getDeemedCommittee().then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.setState({
          showForm: false,
          showTable: true,
          editData: {},
        });
      } else {
        getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {};

  getFilteredTableData = () => {};

  cancelHandler = () => {
    this.props.getDeemedCommittee().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      showForm: false,
      showTable: true,
      showMember: false,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.deemedCommitteeList?.deemedCommittee.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["details"] &&
            currentRow["details"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };

  onClickDownload = (data) => {
    this.setState({ showLoader: true });
    downloadF({
      url:
        "/api/report/v1/committee-and-committee-members/" +
        data.id +
        "?documentType=1",
    }).then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  render() {
    const { showTable, showForm, showMember, editData, committeeData } =
      this.state;

    return (
      <>
        {this.state.showLoader && <Loading1 />}
        <LandingScreenHeader
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={"List of Committee"}
          showPdfDownload={false}
          showExcelDownload={false}
          showAddButton={true}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />
        {showForm && (
          <>
            <CommitteeForm
              onCancel={this.toggleFormTableVisibility}
              editData={this.state.editData}
              onSave={this.onSave}
            />
          </>
        )}

        {showMember && (
          <>
            <MemberForm
              onCancel={this.cancelHandler}
              editData={this.state.editData}
              committeeData={this.state.committeeData}
            />
          </>
        )}

        {showTable && (
          <>
            <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
              <MemberTableMain
                changeStatus={this.changeStatus}
                OnClickEdit={this.OnClickEdit}
                rowDelete={this.rowDelete}
                OnClickAdd={this.OnClickAdd}
                onClickView={this.onClickView}
                onClickDownload={this.onClickDownload}
                rows={this.getFilteredTableData()}
              />
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  deemedCommitteeList: state.deemedCommittee,
});
const mapDispatchToProps = {
  getDeemedCommittee,
  showNotification,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(DeemedCommittee);
