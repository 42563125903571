import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
// import * as React from "react";
import { ButtonCompo } from "../../../components/Comman/Button";
import React, { useEffect, useState } from "react";
import { LabelCompo } from "../../../components/Comman/Label";
import AttachFile from "../../../components/Comman/AttachFileForMultiSelect";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { getAlert } from "../../../CommonActions/alert.slice";
import { Loading1 } from "../../../components/Loading1";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost, apiDelete } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { openFileInNewTab, deleteFile } from "../../UploadFile/file.slice";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function MeetingPopup({
  open,
  showNotification,
  getAlert,
  handleClose,
  onSave,
  deleteFile,
  openFileInNewTab,
  deemedMeetingList,
}) {
  const [fileName, setFileName] = useState("");
  const [fileLength, setFileLength] = useState(0);
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateRow, setUpdateRow] = useState(false);

  useEffect(() => {
    if (
      rows != deemedMeetingList.meetingDocument &&
      deemedMeetingList.meetingDocument.length !== 0 &&
      rows.length == 0 &&
      !updateRow
    ) {
      setUpdateRow(true);
      setRows(deemedMeetingList.meetingDocument);
    }

    if (
      (body == "" || subject == "") &&
      Object.keys(deemedMeetingList.bodySubData).length !== 0
    ) {
      setBody(
        deemedMeetingList.bodySubData
          ? deemedMeetingList.bodySubData.mailMsg
          : ""
      );
      setSubject(
        deemedMeetingList.bodySubData
          ? deemedMeetingList.bodySubData.subjectMail
          : ""
      );
    }
  });

  const changeH = (fileName, name, index) => {
    setFileName(fileName.target.files);
    setFileLength(fileName.target.files.length);
  };

  const submitHandler = () => {
    let showList = [];
    const bodySubData = {
      subjectMail: subject,
      mailMsg: body,
    };
    rows.map((data) => {
      let docs = {
        fileName: data.documentPath,
        showName: data.docDesc,
      };
      if (data.id && data.id != 0) {
        docs = {
          ...docs,
          id: data.id,
        };
      }
      showList.push(docs);
    });
    onSave(showList, bodySubData);
  };

  const rowViewData = (data) => {
    setLoader(true);
    openFileInNewTab({ file: data.documentPath }).then((response) => {
      if (!response) {
        setLoader(false);

        getAlert({ message: "Something went wrong" });
      } else {
        setLoader(false);
      }
    });
  };

  const rowDeleteData = (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete document?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        setLoader(true);
        if (data.id && data.id !== 0) {
          apiDelete({
            url: endpoint.meetingDocument + "/" + data.id,
          }).then(({ success }) => {
            if (!success) {
              setLoader(false);
              getAlert({
                message: "Failed to delete",
              });
            } else {
              deleteFile({ file: data.documentPath }).then((response) => {
                if (!response) {
                  setLoader(false);
                  getAlert({ message: "Something went wrong" });
                } else {
                  setLoader(false);
                  showNotification({
                    msg: "Data Deleted successfully",
                  });
                  let deleteRow = rows.filter((row) => row.id !== data.id);
                  const filterRows = deleteRow.map((rowData, index) => {
                    rowData = {
                      ...rowData,
                      index: index + 1,
                    };
                    return rowData;
                  });
                  setRows(filterRows);
                }
              });
            }
          });
        } else {
          deleteFile({ file: data.documentPath }).then((response) => {
            if (!response) {
              setLoader(false);
              getAlert({ message: "Something went wrong" });
            } else {
              setLoader(false);
              showNotification({
                msg: "Data Deleted successfully",
              });
              let deleteRow = rows.filter((row) => row.index !== data.index);

              const filterRows = deleteRow.map((rowData, index) => {
                rowData = {
                  ...rowData,
                  index: index + 1,
                };
                return rowData;
              });
              setRows(filterRows);
            }
          });
        }
      }
    });
  };

  const addDetails = () => {
    if (fileName !== "") {
      setError(false);
      const formData = new FormData();
      fileName.forEach((file) => {
        formData.append("files", file);
      });
      let list1 = [...rows];
      setLoader(true);

      apiPost({
        url: "/api/document/v1/file/upload-list",
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          data.map((data1, index) => {
            let document = {
              index: list1.length + 1,
              id: 0,
              docDesc: fileName[index].name,
              documentPath: data1,
            };
            list1.push(document);
          });
          setRows(list1);
          setLoader(false);
          showNotification({
            msg: "Document uploaded successfully",
          });
        } else {
          setLoader(false);
          showNotification({
            msg: "Failed to save ",
            severity: "error",
          });
        }
      });
    } else {
      setError(true);
    }
  };

  const changeHandler = (event) => {
    if (event.target.name == "body") {
      setBody(event.target.value);
    } else {
      setSubject(event.target.value);
    }
  };

  const onChangeDescription = (event, editor) => {
    const data = editor.getData();
    setBody(data);
  };

  const columns = [
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      title: "Document Name",
      name: "docDesc",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  return (
    <>
      <Paper sx={{ pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Attachment
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Subject"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="subject"
                    size="small"
                    value={subject}
                    onChange={changeHandler}
                    fullWidth
                    // error={subjectError ? true : false}
                    // helperText={subjectError ? subjectError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Body"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={body}
                    onInit={(editor) => {}}
                    onChange={(event, editor) =>
                      onChangeDescription(event, editor)
                    }
                    onBlur={(editor) => {}}
                    onFocus={(editor) => {}}
                  /> */}
                  <TextField
                    color="primary"
                    name="body"
                    size="small"
                    value={body}
                    onChange={changeHandler}
                    fullWidth
                    multiline
                    // error={subjectError ? true : false}
                    // helperText={subjectError ? subjectError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid style={{ marginTop: 10 }} item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Add Attachment"
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
              // style={{ marginTop: 2 }}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={10}
                lg={10}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <AttachFile name={"file"} fileName={changeH} />
                  {error && (
                    <FormHelperText error>{"Select Document"}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={9}
                lg={9}
                container
                justifyContent="left"
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={"Number of file selected : " + fileLength}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={3}
                lg={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Add document"
                    fullWidth={true}
                    onClick={addDetails}
                  />
                </Grid>
              </Grid>
            </Grid>

            <br />
            <DynamicTable
              data={rows}
              tableHead={columns}
              showView={true}
              rowViewData={rowViewData}
              showPegination={false}
              showHeadDelete={true}
              rowDelete={rowDeleteData}
            />
            <br />
            <Grid
              container
              justifyContent="right"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Send"
                onClick={submitHandler}
              />
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>{" "}
      {loader && <Loading1 />}
    </>
  );
}
const mapStateToProps = (state) => ({
  deemedMeetingList: state.deemedMeeting,
});
const mapDispatchToProps = {
  openFileInNewTab,
  deleteFile,
  showNotification,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(MeetingPopup);