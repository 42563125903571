export const AdmissionModuleReportsJson = {
    apiBaseURL:
        "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
    screenTitle: "Admission Module Report",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    searchButton: false,
    PDFButton: true,
    EXCELButton: true,
    marginTop: 3,
    fieldMeta: [
        {
            label: "Academic Year",
            controlType: "autocomplete",
            placeHolder: "",
            md: 12,
            lg: 12,
            sm: 12,
            xs: 12,
            masterName: "academicYear",
            dataKey: "academicYearId",
            isMandatory: true,
            getListId: "academicYearId"
        },
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearDetails",
            dataKey: "yearDetailId",
            isMandatory: true,
            getListId:'yearDetailId,academicYearId',
        },
        {
            label: "Year Detail ( Multiselect )",
            controlType: "multiSelect",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearDetails",
            dataKey: "yearDetailIds",
            isMandatory: true,
            getListId: "yearDetailIds"
        },
        {
            label: "Division",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "division",
            dataKey: "division",
            isMandatory: true,
        },
        {
            label: "Division ( Multiselect )",
            controlType: "multiSelect",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "division",
            dataKey: "divisionIds",
            isMandatory: true,
        },
        {
            label: "Semester Details",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            getListFrom:'academicYearId,yearDetailId',
            masterName: "semisterMaster",
            dataKey: "semisterDetailId",
            isMandatory: true,
          },
          {
            label: "Category ( Multiselect )",
            controlType: "multiSelect",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            // getListFrom:'acadamicYearId,yearDetailId',
            masterName: "categoryMaster",
            dataKey: "categoryIds",
            isMandatory: true,
          },
          {
            label: "Program Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "programTypeMaster",
            dataKey: "programType",
            isMandatory: true,
            getListId: "programType"
        },
        {
            label: "Program Title",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "programTitleMaster",
            getListFrom:'programType',
            dataKey: "programTitle",
            isMandatory: true,
        },
          {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-l"
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "fromDate-g"
        },
    ],
};
