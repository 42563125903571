import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  feedbackDateList: [],
  isFeedbackDateListFetch: false,
  studentFeedback: {},
  isStudentFeedback: false,
  studentDataByDivision: [],
  isFetchByDivision: false,
  studentDataByDivisionAndTeacherId: [],
  isFetchDataByDivisionAndTeacherId: false,
  distinctTeacher: [],
  isFetchDistinctTeacher: false,
  externalStudentData: [],
  distinctSubject: [],
  isFetchDistinctSubject: false,
  externalStudentFetch: false,
  internalStudentData: [],
  internalStudentFetch: false,
  divisionListByStudentFeedbackId: [],
  isFetchDivisionListByStudentFeedbackId: false,
  studentByDivisionIdAndTeacher: [],
  isFetchByStudentByDivisionIdAndTeacher: false,
};
let URL = endpoints.studentFeedback;
let transactionURL = endpoints.studentFeedBackTransaction;
const studentFeedbackSlice = createSlice({
  name: "studentFeedback",
  initialState,
  reducers: {
    feedbackDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        feedbackDateList: row,
        isFeedbackDateListFetch: true,
      };
    },

    ExtStudListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        externalStudentFetch: true,
        externalStudentData: row,
      };
    },
    internalStudentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        internalStudentFetch: true,
        internalStudentData: row,
      };
    },

    studentFeedbackSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentFeedback: row,
        isStudentFeedback: true,
      };
    },

    studentDataByDivisionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentDataByDivision: row,
        isFetchByDivision: true,
      };
    },

    studentByDivisionIdAndTeacherSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentByDivisionIdAndTeacher: row,
        isFetchByStudentByDivisionIdAndTeacher: true,
      };
    },

    studentDataByDivisionAndTeacherSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentDataByDivisionAndTeacherId: row,
        isFetchDataByDivisionAndTeacherId: true,
      };
    },

    distinctTeacherSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        distinctTeacher: row,
        isFetchDistinctTeacher: true,
      };
    },
    distinctSubjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        distinctSubject: row,
        isFetchDistinctSubject: true,
      };
    },
    divisionListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        divisionListByStudentFeedbackId: row,
        isFetchDivisionListByStudentFeedbackId: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  resetSuccess,
  feedbackDateSuccess,
  studentFeedbackSuccess,
  studentDataByDivisionSuccess,
  studentDataByDivisionAndTeacherSuccess,
  distinctTeacherSuccess,
  distinctSubjectSuccess,
  ExtStudListSuccess,
  internalStudentListSuccess,
  divisionListSuccess,
  studentByDivisionIdAndTeacherSuccess,
} = studentFeedbackSlice.actions;

export default studentFeedbackSlice.reducer;

export const getFeedbackFromDtToDt =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-feedback-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            const division =
              data1.divisions !== null
                ? data1.divisions.map((divisionData) => {
                    return divisionData.name;
                  })
                : [];
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              feedbackHead: data1.feedbackHead.name,
              feedbackHeadId: data1.feedbackHead.id,
              feedbackDetails: data1.feedbackDetails,
              yearDetailName:
                data1.divisions !== null &&
                data1.divisions.length !== 0 &&
                data1.divisions[0].getYearDetailId.year.programType.name +
                  " - " +
                  data1.divisions[0].getYearDetailId.programTitle.brName +
                  " - " +
                  data1.divisions[0].getYearDetailId.year.className,
              division: division.join(),
              fromDate: data1.fromDate,
              eventId: data1.eventId === null ? "" : data1.eventId,
              toDate: data1.toDate,
              isSubject: data1.isSubject,
              isSubjectData: data1.isSubject == 1 ? "Yes" : "No",
              semisterDetail: data1.semisterDetail.id,
              divisionIds: data1.divisionIds,
              deptAuthorityToView: data1.deptAuthorityToView,
              empAuthorityToView: data1.empAuthorityToView,
              yearDetail: data1.semisterDetail.semister.yearDetailId,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(feedbackDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
// new

export const getFeedbackFromDtToDtFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}&offset=0&limit=20',
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data.content;
        let index = 0;
        let row = [];
        content.map((data1) => {
          const division =
            data1.divisions !== null
              ? data1.divisions.map((divisionData) => {
                  return divisionData.name;
                })
              : [];
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            feedbackHead: data1.feedbackHead.name,
            feedbackHeadId: data1.feedbackHead.id,
            feedbackDetails: data1.feedbackDetails,
            yearDetailName:
              data1.divisions !== null &&
              data1.divisions.length !== 0 &&
              data1.divisions[0].getYearDetailId.year.programType.name +
                " - " +
                data1.divisions[0].getYearDetailId.programTitle.brName +
                " - " +
                data1.divisions[0].getYearDetailId.year.className,
            division: division.join(),
            fromDate: data1.fromDate,
            eventId: data1.eventId === null ? "" : data1.eventId,
            toDate: data1.toDate,
            isSubject: data1.isSubject,
            isSubjectData: data1.isSubject == 1 ? "Yes" : "No",
            semisterDetail: data1.semisterDetail.id,
            divisionIds: data1.divisionIds,
            deptAuthorityToView: data1.deptAuthorityToView,
            empAuthorityToView: data1.empAuthorityToView,
            yearDetail: data1.semisterDetail.semister.yearDetailId,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(feedbackDateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStudentFeedbackByEventId =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/by-event-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const division =
            content.divisions !== null
              ? content.divisions.map((divisionData) => {
                  return divisionData.name;
                })
              : [];
          index = index + 1;
          let studFeedbackData = {
            id: content.id,
            feedbackHead: content.feedbackHead.name,
            feedbackHeadId: content.feedbackHead.id,
            feedbackDetails: content.feedbackDetails,
            yearDetailName:
              content.divisions !== null &&
              content.divisions.length !== 0 &&
              content.divisions[0].getYearDetailId.year.programType.name +
                " - " +
                content.divisions[0].getYearDetailId.programTitle.brName +
                " - " +
                content.divisions[0].getYearDetailId.year.className,
            division: division.join(),
            divisionId: content.divisions,
            fromDate: content.fromDate,
            toDate: content.toDate,
            isSubject: content.isSubject,
            semisterDetail: content.semisterDetail.id,
            divisionIds: content.divisionIds,
            deptAuthorityToView: content.deptAuthorityToView,
            empAuthorityToView: content.empAuthorityToView,
            yearDetail: content.semisterDetail.semister.yearDetailId,
            eventId: content.eventId,
            isActive: content.isActive,
          };
          dispatch(studentFeedbackSuccess({ row: studFeedbackData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getStudentFeedbackDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const feedbackList = data.data.content;
        let index = 0;
        let row = [];
        feedbackList.map((data1) => {
          index = index + 1;
          const divisionIds = data1.divisions.map((data) => {
            return data.id;
          });

          const yearDetailIds = data1.divisions.map((data) => {
            return data.getYearDetailId.id;
          });

          let bData = {
            index: index,
            id: data1.id,
            feedbackHead: data1.feedbackHead.name,
            feedbackHeadId: data1.feedbackHead.id,
            feedbackDetails: data1.feedbackDetails,
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            isSubject: data1.isSubject,
            semisterDetail: data1.semisterDetail.id,
            divisionIds: data1.divisionIds,
            deptAuthorityToView: data1.deptAuthorityToView,
            empAuthorityToView: data1.empAuthorityToView,
            yearDetail: data1.semisterDetail.semister.yearDetailId,
            eventId: data1.eventId === null ? "" : data1.eventId,
            // index: index,
            // id: data1.id,
            // feedbackHead: data1.feedbackHead.name,
            // feedbackHeadId: data1.feedbackHead.id,
            // fromDate: data1.fromDate,
            // toDate: data1.toDate,
            // isSubject: data1.isSubject,
            // semisterDetail: data1.semisterDetail.id,
            // instructions: data1.feedbackDetails,
            // divisionId: divisionIds ? divisionIds.join() : "",
            // deptAuthorityToView: data1.deptAuthorityToView ? data1.deptAuthorityToView : "",
            // empAuthorityToView: data1.empAuthorityToView ? data1.empAuthorityToView : "",
            // yearDetail: yearDetailIds ? yearDetailIds.join() : "",
          };
          row.push(bData);
          return data1;
        });
        dispatch(feedbackDateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStudentFeedbackDetailsByID =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          const { feedbackList } = data.data;

          // const divisionIds = feedbackList.divisions.map( ( data ) =>
          // {
          //     return data.id
          // } )
          let row = {
            id: data.data.id,
            feedbackHead: data.data.feedbackHead.name,
            feedbackHeadId: data.data.feedbackHead.id,
            fromDate: data.data.fromDate,
            toDate: data.data.toDate,
            isSubject: data.data.isSubject,
            semisterDetail: data.data.semisterDetail.semister.id,
            instructions: data.data.feedbackDetails,
            divisionId: data.data.divisions,
          };
          dispatch(studentFeedbackSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByDivisionID =
  ({ divisionId, studentFeedbackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          transactionURL +
          "/get-student-feedback-transaction-by-division-id?studentFeedbackId=" +
          studentFeedbackId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName:
                data1.studentRegistration.lastName +
                " " +
                data1.studentRegistration.firstName +
                " " +
                (data1.studentRegistration.fatherName == null || data1.studentRegistration.fatherName == "" ? " - " : data1.studentRegistration.fatherName)+
                " " +
                (data1.studentRegistration.motherName == null || data1.studentRegistration.motherName == "" ? " - " : data1.studentRegistration.motherName),
              rollNo: data1.reportingDetail.rollNo,
              emailId: data1.emailId,
              gender: data1.studentRegistration.gender,
              feedbackDate: data1.feedbackDate,
              mobile: data1.studentRegistration.studentMobile,

              studentFeedbackTransactionDetails:
                data1.studentFeedbackTransactionDetails.map(
                  (employeeFeedbackTransactionDetailData, empIndex) => {
                    let feedBack = {
                      index: empIndex + 1,
                      id: employeeFeedbackTransactionDetailData
                        .feedbackHeadDetails.id,
                      question:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.question,
                      optionType:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.optionType,
                      answer: employeeFeedbackTransactionDetailData.value,
                      answerValid: 0,
                      isActive:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.isActive,
                      feedbackHeadDetailsOptions:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.feedbackHeadDetailsOptions,
                    };
                    return feedBack;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentDataByDivisionSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getExternalStudentList =
  ({ studentFeedbackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          transactionURL +
          "/outside-student?studentFeedbackId=" +
          studentFeedbackId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName: data1.name,
              rollno:
                data1.reportingDetail !== null
                  ? data1.reportingDetail.rollNo
                  : "",
              emailId: data1.emailId,
              gender:
                data1.studentRegistration !== null
                  ? data1.studentRegistration.gender
                  : "",
              feedbackDate: data1.feedbackDate,
              mobile:
                data1.studentRegistration !== null
                  ? data1.studentRegistration.studentMobile
                  : "",

              studentFeedbackTransactionDetails:
                data1.studentFeedbackTransactionDetails.map(
                  (employeeFeedbackTransactionDetailData, empIndex) => {
                    let feedBack = {
                      index: empIndex + 1,
                      id: employeeFeedbackTransactionDetailData
                        .feedbackHeadDetails.id,
                      question:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.question,
                      optionType:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.optionType,
                      answer: employeeFeedbackTransactionDetailData.value,
                      answerValid: 0,
                      isActive:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.isActive,
                      feedbackHeadDetailsOptions:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.feedbackHeadDetailsOptions,
                    };
                    return feedBack;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(ExtStudListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByDivisionIdAndTeacher =
  ({ divisionId, teacherId, studentFeedbackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          transactionURL +
          "/by-division-teacher?divisionId=" +
          divisionId +
          "&teacherId=" +
          teacherId +
          "&studentFeedbackId=" +
          studentFeedbackId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName:
                data1.studentRegistration.firstName +
                " " +
                data1.studentRegistration.middleName +
                " " +
                data1.studentRegistration.lastName,
              rollNo:
                data1.reportingDetail !== null
                  ? data1.reportingDetail.rollNo
                  : "",
              emailId: data1.emailId,
              subject:
                data1.subject.name +
                " - " +
                data1.subjectDetail.schemeHeadId.shortName,
              feedbackDate: data1.feedbackDate,
              gender: data1.studentRegistration.gender,

              mobile: data1.studentRegistration.studentMobile,
              studentFeedbackTransactionDetails:
                data1.studentFeedbackTransactionDetails.map(
                  (employeeFeedbackTransactionDetailData, empIndex) => {
                    let feedBack = {
                      index: empIndex + 1,
                      id: employeeFeedbackTransactionDetailData
                        .feedbackHeadDetails.id,
                      question:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.question,
                      optionType:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.optionType,
                      answer: employeeFeedbackTransactionDetailData.value,
                      answerValid: 0,
                      isActive:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.isActive,
                      feedbackHeadDetailsOptions:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.feedbackHeadDetailsOptions,
                    };
                    return feedBack;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentByDivisionIdAndTeacherSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/student-feedback-transaction/get-distinct-teacher?studentFeedbackId=b93ef6cf-43ce-4af5-8021-7319effbe78c

export const getDistinctTeacher =
  ({ id, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          transactionURL +
          "/get-distinct-teacher?studentFeedbackId=" +
          id +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const feedbackList = data.data;
          let index = 0;
          let row = [];
          feedbackList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              // isActive: data1.isActive,
              name:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
            };
            row.push(bData);
            return data1;
          });
          dispatch(distinctTeacherSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDistinctSubject =
  ({ feedBackId, teacherId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          transactionURL +
          "/get-distinct-subject?studentFeedbackId=" +
          feedBackId +
          "&divisionId=" +
          divisionId +
          "&teacherId=" +
          teacherId,
      }).then(({ data, success }) => {
        if (success) {
          const subjectList = data.data;
          let index = 0;
          let row = [];
          subjectList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              // isActive: data1.isActive,
              name: data1.subject.name + " - " + data1.schemeHeadId.name,
            };
            row.push(bData);
            return data1;
          });
          dispatch(distinctSubjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

//api/acdmc/v1/student-feedback/view-response-by-limit?limit=1
export const getFeedbackWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/view-response-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          const division =
            data1.divisions !== null
              ? data1.divisions.map((divisionData) => {
                  return divisionData.name;
                })
              : [];
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            feedbackHead: data1.feedbackHead.name,
            feedbackHeadId: data1.feedbackHead.id,
            feedbackDetails: data1.feedbackDetails,
            yearDetailName:
              data1.divisions !== null &&
              data1.divisions.length !== 0 &&
              data1.divisions[0].getYearDetailId.year.programType.name +
                " - " +
                data1.divisions[0].getYearDetailId.programTitle.brName +
                " - " +
                data1.divisions[0].getYearDetailId.year.className,
            division: division.join(),
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            isSubject: data1.isSubject,
            eventId: data1.eventId === null ? "" : data1.eventId,
            isSubjectData: data1.isSubject == 1 ? "Yes" : "No",
            semisterDetail: data1.semisterDetail.id,
            divisionIds: data1.divisionIds,
            deptAuthorityToView: data1.deptAuthorityToView,
            empAuthorityToView: data1.empAuthorityToView,
            yearDetail: data1.semisterDetail.semister.yearDetailId,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(feedbackDateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
//get-student-feedback-transaction
export const getInternalStudentList =
  ({ studentFeedbackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/" + studentFeedbackId + "/get-student-feedback-transaction",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName: data1.name,
              rollNo:
                data1.reportingDetail !== null
                  ? data1.reportingDetail.rollNo
                  : "",
              emailId: data1.emailId,
              gender:
                data1.studentRegistration !== null
                  ? data1.studentRegistration.gender
                  : "",
              feedbackDate: data1.feedbackDate,
              mobile:
                data1.studentRegistration !== null
                  ? data1.studentRegistration.studentMobile
                  : "",

              studentFeedbackTransactionDetails:
                data1.studentFeedbackTransactionDetails.map(
                  (employeeFeedbackTransactionDetailData, empIndex) => {
                    let feedBack = {
                      index: empIndex + 1,
                      id: employeeFeedbackTransactionDetailData
                        .feedbackHeadDetails.id,
                      question:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.question,
                      optionType:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.optionType,
                      answer: employeeFeedbackTransactionDetailData.value,
                      answerValid: 0,
                      isActive:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.isActive,
                      feedbackHeadDetailsOptions:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.feedbackHeadDetailsOptions,
                    };
                    return feedBack;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(internalStudentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDivisionListByStudentFeedbackId =
  ({ studentFeedbackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + studentFeedbackId + "/get-division",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
            };
            row.push(bData);
            return data1;
          });
          dispatch(divisionListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
