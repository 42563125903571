import { Grid, Link, MenuItem, Paper, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import TimePicker from "../../../components/Comman/TimePicker";
import { numberWithDot } from "../../../components/Comman/Util/Validations";
import * as myConstClass from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";
import { getDistinctDivisionByYear } from "../../Masters/Faculty/AcademicDetails/StudentNotice/studentNotice.slice";
import { getCompanyByTypeAndDepartment } from "../../Masters/Faculty/Company/company.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getInternshipType } from "../../Masters/Internship/InternshipType/IntershipType.slice";
import { getYearDetails } from "./InternshipDeclataration.slice";
import endpoints from "../../../config/endpoints";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
} from "../../UploadFile/file.slice";
import AttachFile from "../../../components/Comman/AttachFile";

const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Document Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
class Declaration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      companyNameId: "",
      division: [],
      placementType: "",
      yearDetailId: [],
      designation: "",
      companyPackage: "",
      durationFor: "",
      durationTo: "",
      venue: "",
      fromTime: null,
      toTime: null,
      fromDate: "",
      toDate: "",
      urlLink: "",
      remark: "",
      qualiCriteria: "0",
      interviewMode: "0",
      formErrors: [],
      divisionList: [],
      yearDetailList: [],
      mathsCriteria: 0,
      gapAllowed: 0,
      gapInYears: "",
      documentName: "",
      fileName: "",
      fileNameData: [],
      upload: false,
      uploadError: false,
      isError: false,
    };
  }

  componentDidMount() {
    const {
      getCompanyByTypeAndDepartment,
      getInternshipType,
      getYearDetails,
      value,
      getDepartment,
    } = this.props;
    getCompanyByTypeAndDepartment({ type: "0" }).then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getInternshipType().then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getYearDetails().then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getDepartment().then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    if (Object.keys(value).length !== 0) {
      const durationForArr =
        value.durationFor !== "" ? value.durationFor.split("-") : "";
      const durationFor =
        durationForArr !== ""
          ? new Date(
              durationForArr[2],
              durationForArr[1] - 1,
              durationForArr[0]
            )
          : "";
      const durationToArr =
        value.durationTo !== "" ? value.durationTo.split("-") : "";
      const durationTo =
        durationToArr !== ""
          ? new Date(durationToArr[2], durationToArr[1] - 1, durationToArr[0])
          : "";
      const fromDateArr =
        value.fromDate !== "" ? value.fromDate.split("-") : "";
      const fromDate =
        fromDateArr !== ""
          ? new Date(fromDateArr[2], fromDateArr[1] - 1, fromDateArr[0])
          : "";
      const toDateArr = value.toDate !== "" ? value.toDate.split("-") : "";
      const toDate =
        toDateArr !== ""
          ? new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0])
          : "";

      // const fromTimeArr =
      //   value.fromTime !== "" ? value.fromTime.split( ":", -1 ) : null;
      // const toTimeArr =
      //   value.toTime !== "" ? value.toTime.split( ":", -1 ) : null;
      // console.log( toTimeArr );

      // var fromTime =
      //   fromTimeArr !== null
      //     ? new Date( null, null, null, fromTimeArr[ 0 ], fromTimeArr[ 1 ] )
      //     : null;
      // var toTime =
      //   toTimeArr !== null
      //     ? new Date( null, null, null, toTimeArr[ 0 ], toTimeArr[ 1 ] )
      //     : null;
      // console.log( fromTime );
      //

      const fromtimeDate =
        value.fromTime !== ""
          ? moment(value.fromTime, ["h:mm A"]).format("HH:mm")
          : "";

      const fromTimeArr =
        fromtimeDate !== "" ? fromtimeDate.split(":", -1) : null;

      const fromTime =
        fromTimeArr !== null
          ? new Date(null, null, null, fromTimeArr[0], fromTimeArr[1])
          : null;

      const toTimeDate =
        value.toTime !== ""
          ? moment(value.toTime, ["h:mm A"]).format("HH:mm")
          : "";

      const toTimeArr = toTimeDate !== "" ? toTimeDate.split(":", -1) : null;

      var toTime =
        toTimeArr !== null
          ? new Date(null, null, null, toTimeArr[0], toTimeArr[1])
          : null;

      const docList = value.placementDeclareDocument.map((fileData, index) => {
        let document = {
          id: fileData.id,
          index: index + 1,
          name: fileData.docName,
          fileName: fileData.fileName,
        };
        return document;
      });
      this.props.fileMulitipleSave({ fileData: docList });
      this.setState({
        companyNameId: value.companyNameId,
        division: value.division.split(","),
        placementType: value.placementType,
        yearDetailId: value.yearDetailId.split(","),
        designation: value.designation,
        companyPackage: value.companyPackage,
        durationFor: durationFor,
        durationTo: durationTo,
        venue: value.venue,
        fromTime: fromTime,
        toTime: toTime,
        fromDate: fromDate,
        toDate: toDate,
        urlLink: value.urlLink,
        remark: value.remark,
        qualiCriteria: value.qualiCriteria,
        interviewMode: value.interviewMode,
        gapAllowed: value.gapAllowed,
        gapInYears: value.gapInYears,
        mathsCriteria: value.mathsCriteria,
      });
    }
  }

  //
  componentWillReceiveProps(nextProps) {
    // studNoticeList?.divisionByYearll
    if (this.props.studNoticeList !== nextProps.studNoticeList) {
      if (
        this.props.studNoticeList.divisionByYear !==
        nextProps.studNoticeList.divisionByYear
      ) {
        let divisionData = [];
        divisionData = [
          { id: "all", name: "All" },
          ...nextProps.studNoticeList.divisionByYear.filter(
            (data) => data.isActive == 1
          ),
        ];

        this.setState({
          divisionList: divisionData,
        });
      }
    }

    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        let yearData = [];
        yearData = [
          { id: "all", name: "All" },
          ...nextProps.internshipDeclarationList.yearDetails.filter(
            (data) => data.isActive == 1
          ),
        ];

        this.setState({
          yearDetailList: yearData,
        });
      }
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  changedHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || numberWithDot.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  timeChangeHandler = (dataKey) => (time) => {
    this.setState({
      [dataKey]: time,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    }
    if (name == "companyNameId" && newValue !== null) {
      apiGet({
        url: endpoints.company + "/" + newValue,
      }).then(({ data }) => {
        if (data !== null || data !== "") {
          this.setState({
            venue: data.data.address,
          });
        }
      });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const {
      companyNameId,
      placementType,
      yearDetailId,
      fromDate,
      toDate,
      durationFor,
      durationTo,
      division,
      gapAllowed,
      gapInYears,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    // let changedFromDate =
    //   fromDate === "" ? "" : moment(fromDate).format("DD-MM-YYYY");
    // let changedToDate =
    //   toDate === "" ? "" : moment(toDate).format("DD-MM-YYYY");
    if (gapAllowed == 1 && gapInYears == "") {
      formIsValid = false;
      formErrors["gapInYearsError"] = myConstClass.gapInYearsMsg;
    }
    if (companyNameId.toString().trim() === "" || companyNameId === null) {
      formIsValid = false;
      formErrors["companyNameIdError"] = myConstClass.companyNameMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divisionNameMsg;
    }

    if (placementType.toString().trim() === "") {
      formIsValid = false;
      formErrors["placementTypeError"] = myConstClass.placementTypeMsg;
    }

    if (!yearDetailId.length) {
      formIsValid = false;
      formErrors["yearDetailIdError"] = myConstClass.yearDetailMsg;
    }
    // console.log(changedFromDate);
    // console.log(changedToDate);
    // if (changedFromDate < changedToDate) {
    //   formIsValid = false;
    //   formErrors["yearDetailIdError"] = myConstClass.yearDetailMsg;
    // }

    if (fromDate != "" && toDate != "" && fromDate > toDate) {
      formIsValid = false;
      formErrors["fromDateError"] = "To Date cannot be less than From Date";
    }

    if (durationFor != "" && durationTo != "" && durationFor > durationTo) {
      formIsValid = false;
      formErrors["fromDateError"] = "To Date cannot be less than From Date";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeHandlers = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changeHandler1 = (event) => {
    console.log(event.target.value);
    if (event.target.value === "1") {
    }
  };
  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");
    if (filterValue.length !== 0) {
      if (name === "division") {
        let divisionList = [];
        this.state.divisionList.map((data) => {
          if (data.id !== "all") {
            divisionList.push(data.id);
          }
        });
        this.setState({
          [name]: divisionList,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    }

    if (filterValue.length !== 0) {
      if (name == "yearDetailId") {
        let yearList = [];
        this.setState({
          divisionList: [],
          division: [],
        });
        this.state.yearDetailList.map((data) => {
          if (data.id !== "all") {
            yearList.push(data.id);
          }
        });
        this.props
          .getDistinctDivisionByYear({ yearDetail: yearList })
          .then((response) => {
            if (!response) {
            }
            this.setState({
              [name]: yearList,
            });
          });
      }
    }

    if (name == "yearDetailId") {
      this.setState({
        divisionList: [],
        division: [],
      });
      if (value != "all" && value.length != 0) {
        this.props
          .getDistinctDivisionByYear({ yearDetail: value })
          .then((response) => {
            if (!response) {
            }
          });
      } else {
        this.setState({
          ...this.state,
          [name]: typeof value === "string" ? value.split(",") : value,
        });
      }
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      companyNameId,
      division,
      placementType,
      yearDetailId,
      designation,
      companyPackage,
      durationFor,
      durationTo,
      venue,
      fromTime,
      toTime,
      fromDate,
      toDate,
      urlLink,
      remark,
      qualiCriteria,
      interviewMode,
      gapInYears,
      mathsCriteria,
      gapAllowed,
    } = this.state;
    const documentList = this.props.fileData.fileData.map((docData) => {
      let docs = {
        docName: docData.name,
        fileName: docData.fileName,
      };
      if (docData.id) {
        docs = {
          ...docs,
          id: docData.id,
        };
      }
      return docs;
    });
    let changedDurationFromDate =
      durationFor === "" ? "" : moment(durationFor).format("DD-MM-YYYY");
    let changedDurationToDate =
      durationTo === "" ? "" : moment(durationTo).format("DD-MM-YYYY");
    let changedFromDate =
      fromDate === "" ? "" : moment(fromDate).format("DD-MM-YYYY");
    let changedToDate =
      toDate === "" ? "" : moment(toDate).format("DD-MM-YYYY");
    // let changedFromTime =
    //   fromTime === null ? "" : moment( fromTime ).format( "hh:mm" );
    // let changedToTime = toTime === null ? "" : moment( toTime ).format( "hh:mm" );
    // new code
    let changedFromTime =
      fromTime === null ? "" : moment(fromTime, "h:mm:ss A").format("HH:mm");
    let changedToTime =
      toTime === null ? "" : moment(toTime, "h:mm:ss A").format("HH:mm");

    let docs = {
      companyNameId: companyNameId,
      division: division.toString(),
      placementType: placementType,
      yearDetailId: yearDetailId.toString(),
      designation: designation,
      companyPackage: companyPackage,
      durationFor: changedDurationFromDate,
      durationTo: changedDurationToDate,
      venue: venue,
      fromTime: changedFromTime,
      toTime: changedToTime,
      fromDate: changedFromDate,
      toDate: changedToDate,
      urlLink: urlLink,
      remark: remark,
      qualiCriteria: qualiCriteria,
      interviewMode: interviewMode,
      gapAllowed: gapAllowed,
      gapInYears: gapAllowed == 0 ? "0" : gapInYears,
      mathsCriteria: mathsCriteria,
      isActive: 1,
      placementDeclareDocument: documentList,
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }

    /* if(id!=0){
          docs={
            ...docs,
            id:id
          }
        }
        if (this.handleFormValidation()) {
         
        } */
  };

  onClickDelete = (params) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        this.setState({
          showLoader: true,
        });
        if (params.id !== undefined) {
          apiDelete({
            url: endpoints.deletePlacementDoc + "/" + params.id,
          }).then(({ data, success }) => {
            if (success) {
              this.setState({
                showLoader: false,
              });
              this.props
                .deleteFile({ file: params.fileName })
                .then((success) => {
                  if (success) {
                    const docList = this.props.fileData.fileData.filter(
                      (row) => row.fileName !== params.fileName
                    );
                    const rowData = docList.map((docData, index) => {
                      return { ...docData, index: index + 1 };
                    });
                    this.props.fileMulitipleSave({ fileData: rowData });
                  }
                });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        } else {
          this.props.deleteFile({ file: params.fileName }).then((success) => {
            if (success) {
              this.setState({
                showLoader: false,
              });
              const docList = this.props.fileData.fileData.filter(
                (row) => row.fileName !== params.fileName
              );
              const rowData = docList.map((docData, index) => {
                return { ...docData, index: index + 1 };
              });
              this.props.fileMulitipleSave({ fileData: rowData });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        }
      }
    });
  };

  details = (params) => {
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  docHandleDetailValidation() {
    const { file, documentName } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (documentName.toString().trim() === "" || documentName === null) {
      formIsValid = false;
      formErrors["documentError"] = myConstClass.docNameMsg;
    }

    this.setState({ ...this.state, formErrors: formErrors });
    return formIsValid;
  }

  uploadImage = () => {
    const { fileName, documentName } = this.state;
    if (this.docHandleDetailValidation()) {
      if (fileName !== "") {
        this.setState({
          upload: true,
          showLoader: true,
          uploadError: false,
        });

        const formData = new FormData();
        formData.append("file", this.state.fileName);
        apiPost({
          url: endpoints.fileUpload,
          postBody: formData,
        }).then(({ data, success }) => {
          if (success) {
            let docList = [
              ...this.props.fileData.fileData,
              {
                index: this.props.fileData.fileData.length + 1,
                name: documentName,
                fileName: data,
              },
            ];
            this.setState({
              // documentName: "",
              fileName: "",
              upload: false,
              uploadError: false,
              showLoader: false,
            });
            this.props.fileMulitipleSave({ fileData: docList });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.setState({
          upload: false,
          uploadError: true,
        });
      }
    }
  };

  changeH = (fileName, name, index) => {
    if (fileName !== undefined) {
      this.setState({
        ...this.state,
        fileName: fileName,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const {
      companyNameIdError,
      placementTypeError,
      venueError,
      yearDetailIdError,
      divisionError,
      fromDateError,
      gapInYearsError,
      documentError,
    } = this.state.formErrors;
    const {
      companyNameId,
      division,
      placementType,
      yearDetailId,
      designation,
      companyPackage,
      durationFor,
      durationTo,
      venue,
      fromTime,
      toTime,
      fromDate,
      toDate,
      urlLink,
      remark,
      qualiCriteria,
      interviewMode,
      mathsCriteria,
      gapAllowed,
      gapInYears,
      documentName,
      fileName,
      fileNameData,
      upload,
      uploadError,
      isError,
    } = this.state;
    const {
      companyList,
      internshipTypeList,
      onCancel,
      internshipDeclarationList,
      departments,
    } = this.props;
    console.log(internshipDeclarationList?.yearDetails);
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={3}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Declaration of Placement"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Link
                    color="primary"
                    variant="body2"
                    component={RouterLink}
                    to="/list-of-placement"
                    sx={{ textDecoration: "none" }}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back To List"
                    />
                  </Link>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Company Name "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={companyNameId}
                      name="companyNameId"
                      options={companyList?.companyByDepartment.filter(
                        (row) => row.isActive == 1
                      )}
                      onChange={this.ChangeHandlerSearch}
                      isError={companyNameIdError ? true : false}
                      errorText={companyNameIdError ? companyNameIdError : " "}
                    />
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  sx={{ marginTop: -5 }}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Placement Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="placementType"
                          id="placementType"
                          value={placementType}
                          onChange={this.changeHandlers}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="0">On-Campus</MenuItem>
                          <MenuItem value="1">Off-Campus</MenuItem>
                          <MenuItem value="2">Pool-Campus</MenuItem>
                        </Select>
                      </FormControl>
                      <FormHelperText error>
                        {placementTypeError ? placementTypeError : " "}
                      </FormHelperText>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Interview Mode"
                      />
                    </Grid>

                    <Grid sx={{ marginTop: -3 }} item xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="interviewMode"
                          id="interviewMode"
                          name="interviewMode"
                          value={interviewMode}
                          onChange={this.changeHandlers}
                          size="small"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="Offline"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Online"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  sx={{ marginTop: -3 }}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Detail "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        name1="yearDetailId"
                        value1={yearDetailId}
                        er={yearDetailIdError}
                        // names={internshipDeclarationList?.yearDetails.filter(
                        //   (data) => data.isActive == 1
                        // )}
                        names={this.state.yearDetailList}
                        onChange={this.multiSelectHandler}
                      />
                      {/* <AutoComplete
                        keyColName={"id"}
                        value={yearDetailId}
                        name="yearDetailId"
                        options={internshipDeclarationList?.yearDetails}
                        onChange={this.ChangeHandlerSearch}
                        isError={yearDetailIdError ? true : false}
                        errorText={yearDetailIdError ? yearDetailIdError : " "}
                      /> */}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Division"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        name1="division"
                        value1={division}
                        er={divisionError}
                        names={this.state.divisionList}
                        onChange={this.multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  sx={{ marginTop: -5 }}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company Address"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="venue"
                        size="small"
                        value={venue}
                        onChange={this.changeHandler}
                        fullWidth
                        error={venueError ? true : false}
                        helperText={venueError ? venueError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  sx={{ marginTop: -4 }}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    {/* dec */}
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Declaration Valid From Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange("fromDate")}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Declaration Valid To Date  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        minDate={fromDate}
                        fullWidth
                        onChange={this.onDateChange("toDate")}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  sx={{ marginTop: -4 }}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="From Time "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TimePicker
                        value={fromTime}
                        onChange={this.timeChangeHandler("fromTime")}
                        helperText={" "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="To Time  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TimePicker
                        value={toTime}
                        onChange={this.timeChangeHandler("toTime")}
                        helperText={" "}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Package( ₹ Lakh per annum )"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="companyPackage"
                        size="small"
                        value={companyPackage}
                        onChange={this.changedHandler}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Designation "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="designation"
                        size="small"
                        value={designation}
                        onChange={this.changeHandler}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Placement Duration From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="durationFor"
                        size="small"
                        value={durationFor}
                        fullWidth
                        onChange={this.onDateChange("durationFor")}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Placement Duration To Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="durationTo"
                        size="small"
                        value={durationTo}
                        minDate={durationFor}
                        fullWidth
                        onChange={this.onDateChange("durationTo")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  sx={{ marginTop: -4 }}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="URL Link "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="urlLink"
                        size="small"
                        value={urlLink}
                        onChange={this.changeHandler}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Remark "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="remark"
                        size="small"
                        value={remark}
                        onChange={this.changeHandler}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid m={1} item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Qualification Criteria"
                      />
                    </Grid>

                    <Grid sx={{ marginTop: -1 }} item xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="qualiCriteria"
                          id="qualiCriteria"
                          name="qualiCriteria"
                          value={qualiCriteria}
                          onChange={this.changeHandlers}
                          size="small"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                            // onClick={handleClickOpen}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid m={1} item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Gap Allowed?"
                      />
                    </Grid>
                    <Grid sx={{ marginTop: -1 }} item xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="gapAllowed"
                          id="gapAllowed"
                          name="gapAllowed"
                          value={gapAllowed}
                          onChange={this.changeHandlers}
                          size="small"
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                            // onClick={handleClickOpen}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                        sx={{ mt: 1 }}
                      >
                        <Grid m={1} item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Maths Criteria?"
                          />
                        </Grid>
                        <Grid
                          sx={{ marginTop: -1 }}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="mathsCriteria"
                              id="mathsCriteria"
                              name="mathsCriteria"
                              value={mathsCriteria}
                              onChange={this.changeHandlers}
                              size="small"
                            >
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Both"
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Maths"
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Non-Maths"
                                // onClick={handleClickOpen}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {gapAllowed == 0 ? (
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        />
                      ) : (
                        <>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="Gap in years"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                color="primary"
                                name="gapInYears"
                                size="small"
                                value={gapInYears}
                                onChange={this.changeHandler}
                                fullWidth
                                type="number"
                                error={gapInYearsError ? true : false}
                                helperText={
                                  gapInYearsError ? gapInYearsError : " "
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 20 }}
                      label="Add Documents / Photos / Reports"
                    />
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      lg={4}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Document name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          size="small"
                          color="primary"
                          name="documentName"
                          id="documentName"
                          value={documentName}
                          onChange={this.changeHandler}
                          fullWidth
                          error={documentError ? true : false}
                          helperText={documentError ? documentError : " "}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={7}
                      xs={12}
                      md={7}
                      lg={7}
                      container
                      justifyContent="space-evenly"
                      sx={{ marginTop: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <AttachFile name={"file"} fileName={this.changeH} />
                        &nbsp; {fileName !== "" ? fileName.name : ""}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={1}
                      rowSpacing={2}
                      xs={12}
                      sm={1}
                      md={1}
                      sx={{ marginTop: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          fullWidth={true}
                          disabled={
                            documentName !== "" && upload ? true : false
                          }
                          name="Upload"
                          onClick={this.uploadImage}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {uploadError && (
                        <FormHelperText error>
                          {"please select file and Document Name"}
                        </FormHelperText>
                      )}
                    </Grid>
                    {/* <Grid
                      item
                      sm={12}
                      xs={12}
                      md={2}
                      lg={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}></Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {isError ? (
                    <FormHelperText error>
                      {"*Please select atleast one record"}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>{}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <DynamicTable
                    data={this.props.fileData.fileData}
                    tableHead={columns}
                    showHeadDelete={true}
                    rowDelete={this.onClickDelete}
                    showPegination={false}
                    rowViewData={this.details}
                    showView={true}
                  />
                </Grid>

                <Grid
                  container
                  columnSpacing={1}
                  rowSpacing={1}
                  justifyContent="flex-end"
                  sx={{ mt: 2 }}
                >
                  <Grid item>
                    <Link
                      color="primary"
                      variant="body2"
                      component={RouterLink}
                      to="/list-of-placement"
                      sx={{ textDecoration: "none" }}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                      />
                    </Link>
                  </Grid>

                  <Grid item>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save and Proceed"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  companyList: state.company,
  internshipTypeList: state.internshipType,
  departments: state.department,
  internshipDeclarationList: state.internshipDeclaration,
  studNoticeList: state.studNotice,
  fileData: state.file,
});
const mapDispatchToProps = {
  getCompanyByTypeAndDepartment,
  getInternshipType,
  getYearDetails,
  getAlert,
  getDepartment,
  getDistinctDivisionByYear,
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Declaration);
