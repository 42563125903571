import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
const initialState = {
  studentList: [],
  studentListFetch: false,

  promotional: {},
  isFetchPromotional: false,
};
let URL = endpoints.studentList;
let URL1 = endpoints.admissionList;
let URL2 = endpoints.prnNumber;
const promotionalSlice = createSlice( {
  name: "studentList",
  initialState,
  reducers: {
    studentListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        studentList: row,
        studentListFetch: true,
      };
    },
    admissionUpdateSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        updateAdmissionLList: data,
        updateAdmissionListFetch: true,
      };
    },

    prnFails: ( state = cloneDeep( initialState ), action ) =>
    {
      const { msg } = action.payload;
      return {
        ...state,
        applicationIdMsg: msg,
        isFetch: true,
      };
    },
    resetErrorSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        applicationIdMsg: "",
        admissionForm: '',
        isFetch: false,
      };
    },


    promotionalDataSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { promotionData } = action.payload;
      return {
        ...state,
        promotional: promotionData,
        isFetchPromotional: true,
      };
    },

  },
} );

export const { studentListSuccess, admissionUpdateSuccess, resetErrorSuccess, prnFails, promotionalDataSuccess } =
  promotionalSlice.actions;

export default promotionalSlice.reducer;

//  http://localhost:8088/api/acdmc/v1/user-registration/1b61d1ee-1ba5-418a-b727-b62706f94dd0/get-user-registration-list-not-in-admission-registration
// export const getStudentList =
//   ({ academicId }) =>
//   async (dispatch) => {
//     try {
//     const response = apiGet({
//         url: URL + "/" + academicId + "/get-user-registration-by-academic-year",
//       }).then(({ data, success }) => {
//         console.log(data);
//         if (success) {
//           const studentList = data.data;
//           let index = 0;
//           let row = [];
//           studentList.map((data1) => {
//             index = index + 1;

//             let bData = {
//               index: index,
//               id: data1.id,
//               fromDate: data1.fromDate,
//               isActive: data1.isActive,
//               name:
//                 data1.firstName +
//                 " " +
//                 data1.middleName +
//                 " " +
//                 data1.lastName +
//                 "-" +
//                 data1.grnNo,
//               isLocked: data1.isLocked,
//               toDate: data1.toDate,
//             };
//             row.push(bData);
//             return data1;
//           });
//           dispatch(studentListSuccess({ row }));
//         }
//         return success
//       });
//       return response
//     } catch (e) {
//       return console.error(e.message);
//     }
//   };

// http://localhost:8088/api/acdmc/v1/user-registration/1b61d1ee-1ba5-418a-b727-b62706f94dd0/get-user-registration-list-not-in-admission-registration
export const getStudentList =
  ( { academicId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url: URL + "/" + academicId + "/get-user-registration-list-not-in-admission-registration",
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            const studentList = data.data;
            let index = 0;
            let row = [];
            studentList.map( ( data1 ) =>
            {
              index = index + 1;

              let bData = {
                index: index,
                id: data1.id,
                fromDate: data1.fromDate,
                isActive: data1.isActive,
                name:
                  data1.firstName +
                  " " +
                  data1.middleName +
                  " " +
                  data1.lastName +
                  "-" +
                  data1.grnNo,
                isLocked: data1.isLocked,
                toDate: data1.toDate,
              };
              row.push( bData );
              return data1;
            } );
            dispatch( studentListSuccess( { row } ) );
          }
          return success
        } );
        return response
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const updateAdmission =
  ( {
    id,
    yearDetailId,
    scholarshipApplicable,
    scholarshipFormSubmit,
    scholarshipType,
    admissionStatus,
    admissionDate,
    claimNo,
  } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url:
            URL1 +
            "/update-admission-registration-detail?id=" +
            id +
            "&yearDetailId=" +
            yearDetailId +
            "&scholarshipApplicable=" +
            scholarshipApplicable +
            "&scholarshipFormSubmit=" +
            scholarshipFormSubmit +
            "&scholarshipType=" +
            scholarshipType +
            "&admissionStatus=" +
            admissionStatus +
            "&admissionDate=" +
            admissionDate +
            "&claimNo=" +
            claimNo,
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            dispatch( admissionUpdateSuccess( { data } ) );
            return success
          }
        } );
        return response
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const saveStudentList =
  ( { docs } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiPost( {
          url: URL1,
          postBody: docs,
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            // let index = 0;
            // let row = [];
          }
          return success
        } );
        return response
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const resetError = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetErrorSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};


// export const checkPrn =
//   ( { academicYear, prn } ) =>
//     async ( dispatch ) =>
//     {
//       try
//       {
//         const response = apiGet( {
//           // url: URL2 + "/get-user-registration-list-not-in-admission-registration-by-prn?academicYearId=" + "216d1b35-ca64-4552-a222-4412bd2ac23c" + "&prn=" + prn,
//           url: URL2 + "/get-user-registration-list-not-in-admission-registration-by-prn?academicYearId=" + academicYear + "&prn=" + prn,

//         } ).then( ( { data, success } ) =>
//         {
//           if ( success )
//           {
//             if ( data.data.error !== false )
//             {
//               dispatch(
//                 prnFails( { msg: data.errorMessage.message } )
//               );
//               return data;
//             } else
//             {
//               if ( data.data.error !== false )
//               {
//                 dispatch(
//                   prnFails( { msg: data.errorMessage.message } )
//                 );
//                 return data;
//               }
//             }
//           }
//           else
//           {
//             dispatch(
//               prnFails( { msg: "PRN Invalid" } )
//             );

//           }
//           return null;
//         } );
//         return response;
//       } catch ( e )
//       {
//         return console.error( e.message );
//       }
//     };


export const checkPrn =
  ( { academicYear, prn } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url: URL2 + "/get-user-registration-list-not-in-admission-registration-by-prn?academicYearId=" + "216d1b35-ca64-4552-a222-4412bd2ac23c" + "&prn=" + prn,
          // url: URL2 + "/get-user-registration-list-not-in-admission-registration-by-prn?academicYearId=" + academicYear + "&prn=" + prn,
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            let promotionData = data.data;
            dispatch( promotionalDataSuccess( { promotionData } ) );
          }
          return success
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };