import { Grid, Paper } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import * as myConstClass from "../../../config/messageconstant";
import { ViewStudAttendanceDynamicJson } from "../../../DynamicFormsJson/ViewStudAttendanceDynamicJson";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getBatch,
  getDivision,
  getStudentAttendanceBySubject,
  getStudentAttendanceBySubjectView,
  getStudentAttendanceList,
  getSubjectByDivision,
} from "../StudentAttendanceForms/studentAttendance.slice";
import { getSubjectByYearDetailsId } from "./studentAttendance.slice";

import { FilterSave } from "../../../components/FilterSave";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getAttendanceYearDetails,
  getDivisionForAttendance,
  getSemesterDetails,
  getSubjectByDivisionAttendance,
} from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import StudentAttendanceForm from "../StudentAttendanceTable/StudentAttendanceForm";
import CoordinatorPreviewTable from "./CoordinatorPreviewTable";

class PresentStudentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      formErrors: [],
      toDate: "",
      yearDetail: "",
      semester: "",
      division: "",
      subject: "",
      showTable: false,
      showForm: true,
      showSaveForm: false,
      saveFormData: {},
      formData: {},
      dataTosave: {},
      searchValue: "",
      showLoader: false,
      dynamicMasterData: {
        yearDetailMaster: this.props.employeeWorkList?.attendanceYearDetails,
        semesterDetailMaster:
          this.props.employeeWorkList?.attendanceSemesterDetails,
        divisionMaster: this.props.employeeWorkList?.attendanceDivision,
        subjectMaster: this.props.employeeWorkList?.attendanceSubjects,
        batchMaster: this.props.studentAttendanceList?.batch,
      },
    };
  }

  componentDidMount() {
    this.props.resetReducList();
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const ay = localStorage.getItem("acadamicYearId");

    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: yearDetailId,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: semisterDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    this.props
      .getAttendanceYearDetails({ academicYear: ay })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });

    if (semisterDetailId && divisionId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: semisterDetailId,
          division: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceYearDetails !==
        nextProps.employeeWorkList?.attendanceYearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.employeeWorkList?.attendanceYearDetails,
          },
        });
      }
    }
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceSemesterDetails !==
        nextProps.employeeWorkList?.attendanceSemesterDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster:
              nextProps.employeeWorkList?.attendanceSemesterDetails,
          },
        });
      }
    }
    if (this.props.employeeWorkList !== nextProps.employeeWorkList) {
      if (
        this.props.employeeWorkList?.attendanceDivision !==
        nextProps.employeeWorkList?.attendanceDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster: nextProps.employeeWorkList?.attendanceDivision,
          },
        });
      }

      if (
        this.props.employeeWorkList?.attendanceSubjects !==
        nextProps.employeeWorkList?.attendanceSubjects
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.employeeWorkList?.attendanceSubjects,
          },
        });
      }
    }

    if (this.props.studentAttendanceList !== nextProps.studentAttendanceList) {
      if (
        this.props.studentAttendanceList?.batch !==
        nextProps.studentAttendanceList?.batch
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchMaster: nextProps.studentAttendanceList?.batch,
          },
        });
      }
    }
  }

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetails({
          yearDetailId: data.yearDetail,
          academicYearId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDivisionForAttendance({ semesterDetailId: data.semister })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatch({
          semister: data.semister,
          division: data.division,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister && data.batch) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSubjectByDivisionAttendance({
          semister: data.semister,
          division: data.division,
          batchId: data.batch,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "attendanceDate",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },

    {
      name: "topicTaught",
      title: "Topic Taught",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
      canSearch: true,
    },
    {
      name: "presentCount",
      title: "Present Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
    {
      name: "absentCount",
      title: "Absent Students",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  handleFormValidation() {
    const { yearDetail, division, semester, subject } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }
    if (semester.toString().trim() === "" || semester === null) {
      formIsValid = false;
      formErrors["semesterError"] = myConstClass.semisterNameMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divisionNameMsg;
    }

    if (subject.toString().trim() === "" || subject === null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectNameEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (data) => {
    this.setState({
      showLoader: true,
    });
    console.log(data);
    this.props
      .getStudentAttendanceBySubject({
        employeeWorkLoadId: data.subject,
        fromDate: data.fromDate,
        toDate: data.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", data.division);
    localStorage.setItem("academicYearId", ay);
    localStorage.setItem("divisionIds", "");
    FilterSave();
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  rowDetails = (data) => {
    console.log(data);
    this.props
      .getStudentAttendanceBySubjectView({ id: data.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showSaveForm: false,
            showTable: true,
            showForm: false,
            dataTosave: this.props.studentAttendanceList
              ?.studentAttendanceBySubjectView.studentAttendanceDetails
              ? this.props.studentAttendanceList?.studentAttendanceBySubjectView
              : {},
            //     .studentAttendanceDetails
            // : []
          });
        }
      });
  };

  rowEdit = (data) => {
    console.log(data);
    this.props
      .getStudentAttendanceBySubjectView({ id: data.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showSaveForm: true,
            showTable: false,
            showForm: false,
            saveFormData: this.props.studentAttendanceList
              ?.studentAttendanceBySubjectView
              ? this.props.studentAttendanceList?.studentAttendanceBySubjectView
              : [],
          });
        }
      });
  };

  backToList = () => {
    this.setState({
      showTable: false,
      showForm: true,
      showSaveForm: false,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studentAttendanceList?.studentAttendanceBySubject.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["attendanceDate"] &&
            currentRow["attendanceDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["subject"] &&
            currentRow["subject"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["batch"] &&
            currentRow["batch"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["presentCount"] &&
            currentRow["presentCount"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["absentCount"] &&
            currentRow["absentCount"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["topicTaught"] &&
            currentRow["topicTaught"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  onAddButtonClick = () => {
    window.location.replace("/student-attendance");
  };

  render() {
    const { studentAttendanceList } = this.props;
    const { yearDetail, semester, division, subject } = this.state;

    const { yearDetailError, divisionError, semesterError, subjectError } =
      this.state.formErrors;
    console.log(studentAttendanceList?.batch);

    return (
      <>
        {this.state.showForm && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={ViewStudAttendanceDynamicJson.screenTitle}
              fieldMeta={ViewStudAttendanceDynamicJson.fieldMeta}
              buttonCenter={ViewStudAttendanceDynamicJson.buttonCenter}
              showPdfDownload={false}
              showExcelDownload={false}
              // fieldData={this.state.formData}
              showLoader={this.state.showLoader}
              tableColumnsToFilter={
                ViewStudAttendanceDynamicJson.tableColumnsToFilter
              }
              showAddButton={true}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={this.columns}
              getListById={this.getListById}
              tableData={
                this.props.studentAttendanceList?.studentAttendanceBySubject
              }
              showPegination={false}
              downLoadPdfFromApi={true}
              pdfDownload={this.downloadPdf}
              showDetails={true}
              showHeadEdit={true}
              rowEdit={this.rowEdit}
              rowDetails={this.rowDetails}
              // onDelete={this.onDelete}
              onSearchData={this.submitHandler}
              baseIdColumn={ViewStudAttendanceDynamicJson.baseIdColumn}
              apiBaseURL={ViewStudAttendanceDynamicJson.apiBaseURL}
            />
            {/* <Grid container justifyContent="space-evenly" direction="row">
                <Grid sx={{ marginBottom: 3 }} item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Viewing Student Attendance"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="right"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Link
                    color="primary"
                    variant="body2"
                    component={RouterLink}
                    to="/student-attendance"
                  >
                    <MyComponentWithIconProps
                      statusImage={AddCircleIcon}
                      color="primary"
                      fontSize="large"
                      title="Student Attendance"
                    />
                  </Link>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Details"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={this.props.yearDetails?.yearDetails}
                        onChange={this.changeHandlerSearch}
                        isError={yearDetailError ? true : false}
                        errorText={yearDetailError ? yearDetailError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Semester Details"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={semester}
                        name={"semester"}
                        options={this.props.semisterList?.semisterDetailsList}
                        onChange={this.changeHandlerSearch}
                        isError={semesterError ? true : false}
                        errorText={semesterError ? semesterError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Division"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={division}
                      name={"division"}
                      options={this.props.studentAttendanceList?.division}
                      onChange={this.changeHandlerSearch}
                      isError={divisionError ? true : false}
                      errorText={divisionError ? divisionError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="left"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Subject"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={subject}
                      name={"subject"}
                      options={
                        this.props.studentAttendanceList?.subjectByDivision
                          ? this.props.studentAttendanceList?.subjectByDivision
                          : []
                      }
                      onChange={this.changeHandlerSearch}
                      isError={subjectError ? true : false}
                      errorText={subjectError ? subjectError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="center"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Search"
                  onClick={this.submitHandler}
                />
              </Grid>
              <Grid
                sx={{ marginTop: 1, marginBottom: 2 }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <SearchExternalPlacedStudList onSearch={this.onSearch} />
</Grid>
              <Grid item sx={{ marginTop: 2 }}>
                <DynamicTable
                  data={
                    this.getFilteredTableData()
                  }
                  tableHead={this.columns}
                  showPegination={false}
                  showDetails={true}
                  showHeadEdit={true}
                  rowEdit={this.rowEdit}
                  rowDetails={this.rowDetails}
                />
              </Grid> */}
          </>
        )}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {this.state.showTable && (
            <>
              {" "}
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={10.5} md={10.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Students List"
                    />
                  </Grid>
                  <Grid item xs={12} sm={1.5} md={1.5}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back to List"
                      onClick={this.backToList}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 1 }} item xs={12} sm={12} md={12}>
                <CoordinatorPreviewTable rowData={this.state.dataTosave} />
              </Grid>
            </>
          )}
          {this.state.showSaveForm && (
            <>
              <StudentAttendanceForm
                saveFormData={this.state.saveFormData}
                backToList={this.backToList}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearDetails: state.internshipDeclaration,
  semisterList: state.rollNo,

  studentAttendanceList: state.studentAttendance,
  employeeWorkList: state.employeeWorkload,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getSubjectByYearDetailsId,
  getDivision,
  getSubjectByDivision,
  getStudentAttendanceBySubject,
  getStudentAttendanceBySubjectView,
  resetReducList,
  getStudentAttendanceList,
  getBatch,
  getAttendanceYearDetails,
  getSemesterDetails,
  getDivisionForAttendance,
  getSubjectByDivisionAttendance,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PresentStudentDetails);
