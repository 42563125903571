import endpoints from "../config/endpoints";

export const adminResearchProjectJson = {
  apiBaseURL: endpoints.resourceProject,
  screenTitle: "Research Project Grant",
  showAddButton: true,
  baseIdColumn: "id",
  dataSetToAdd: [
    {
      label: "Department",
      value: "departmentId",
      masterName: "departmentMaster",
      isRootLevelKey: false,
    },
    {
      label: "Teacher",
      value: "userRegistration",
      masterName: "teacherMaster",
      isRootLevelKey: true,
    },
  ],
  fieldMeta: [
    {
      label: "Type",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "projectType",
      space: "&nbsp;",
      note: "Note: @1. Major.@2. Minor.@3. Consultancy.@4. Research Grant.",
      isMandatory: true,
    },
    {
      label: "Title",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "projectTitle",
      isMandatory: true,
    },
    {
      label: "Sponsoring Authority",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sponsoredBy",
      isMandatory: true,
    },
    {
      label: "Principal Invistigator",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isPrincipalInvestigator",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Co-principal Invistigator",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "coInvestigator",
      isMandatory: true,
    },
    {
      label: "Scheme",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "scheme",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "Period",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "duration",
      isMandatory: true,
    },
    {
      label: "Amount Mobilized",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sanctionAmount",
      inputType: "number",
      isMandatory: true,
    },
    {
      label: "Project Amount",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "projectAmount",
      isMandatory: true,
    },
    {
      label: "Project Details",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "details",
      isMandatory: true,
    },
  ],
};
