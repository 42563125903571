import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { generateExcel } from "../../../components/Comman/GenerateExcel";
import * as myConstClass from "../../../config/messageconstant";

import { generatePDF } from "../../../components/Comman/GeneratePDF";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import NewLandingScreenHeader from "../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { AlumniInternshipJson } from "../../../DynamicFormsJson/AlumniInternship";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { AlumniInternshipColumns } from "../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { Loading1 } from "../../../components/Loading1";
import {
  getVolunteerById,
  getInternshipById,
} from "../AdminSideAlimini/AlumniVolunteerStatus/AdminVolunteerStatus.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AlumniInternship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      formErrors: {},
      showTable: true,
      showLoader: false,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      dynamicMasterData: {
        TitleMaster: [
          { id: "Internship", name: "Internship" },
          { id: "Job", name: "Job" },
          { id: "other", name: "Any Other" },
        ],
      },
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };
  //attendedSeminarList?.attendedSeminar
  componentWillReceiveProps(nextProps) {
    // if ( this.props.attendedSeminarList !== nextProps.attendedSeminarList )
    // {
    //   if (
    //     this.props.attendedSeminarList.attendedSeminar !==
    //     nextProps.attendedSeminarList.attendedSeminar
    //   )
    //   {
    //     this.setState( {
    //       dynamicMasterData: {
    //         ...this.state.dynamicMasterData,
    //         activity: nextProps.activityList.activityByType,
    //       },
    //     } );
    //   }
    // }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    const { getVolunteerById } = this.props;
    this.setState({
      showLoader: true,
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
    this.props.getVolunteerById().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    let fieldData = {};
    this.props.onEdit();

    AlumniInternshipJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else if (currentField.controlType === "hideTextfield") {
        let list = this.state.dynamicMasterData[
          currentField["masterName"]
        ].filter((item) => {
          return item.id === rowData[tableDataKey];
        });
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = "other";
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getInternshipById({ id: "" }).then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
    const { opportunity } = this.props;
    console.log(opportunity);
    this.setState({ showForm: opportunity, showTable: !opportunity });
  }

  onChangeStatus = (rowData, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveEmployee +
            "/update-status?userRegistrationId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.onCancle();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      formType: 4,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    AlumniInternshipJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        if (fieldData[currentField.dataKey] !== undefined) {
          let date = new Date(fieldData[currentField.dataKey]);

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
        }

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "hideTextfield") {
        console.log(fieldData[currentField.tableDataKey]);
        console.log(fieldData[currentField.open]);
        if (fieldData[currentField.tableDataKey] === currentField.open) {
          dataToSave[currentField.tableDataKey] = fieldData[
            currentField.dataKey
          ]
            ? fieldData[currentField.dataKey]
            : "";
        }
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: AlumniInternshipJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.props.getInternshipById({ id: "" }).then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
          this.props.onCancle();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: AlumniInternshipJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const {} = this.state;
        this.props.getInternshipById({ id: "" }).then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { volunteerList } = this.props;
    const { searchValue } = this.state;
    const columsFil = AlumniInternshipColumns.filter((item) => {
      return item.canSearch;
    });
    return volunteerList?.internshipByIdData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      employeeDetails,
      volunteerList,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      dynamicMasterData,
      fieldData,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    console.log(volunteerList?.internshipByIdData);
    return (
      <>
        {this.state.showLoader && <Loading1 />}
        {showForm && (
          <>
            <DynamicForm
              fieldMeta={AlumniInternshipJson.fieldMeta}
              dynamicMasterData={dynamicMasterData}
              fieldData={fieldData}
              onDataChange={this.onDataChange}
              showSaveNextBtn={false}
              onCancel={this.onFormCancel}
              onSave={this.onFormSave(true)}
              onSaveAndNext={this.onFormSave(false)}
              showTitle={true}
              showBackToListBtn={false}
            />
          </>
        )}

        {showTable && (
          <>
            <Grid item sx={{ marginTop: 1 }}>
              <RTTable
                columns={AlumniInternshipColumns}
                hiddenColumnNames={tableColumnsToFilter.map((item) => {
                  return !item.isChecked ? item.columnKeyName : "";
                })}
                showAddButtonInTable={showAddButtonInTable}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                onChangeStatus={this.onChangeStatus}
                rowAdd={this.rowAdd}
                rows={this.getFilteredTableData()}
                tableData={this.getFilteredTableData()}
              />
            </Grid>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  volunteerList: state.volunteerStatus,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getVolunteerById,
  getInternshipById,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlumniInternship);
