// import screenfull from 'screenfull';
import Container from "@mui/material/Container";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { useRef, useState } from "react";
import ControlIcons from "./ControlIcons";
import "./Video.css";
const format = (seconds) => {
  if (isNaN(seconds)) {
    return "00:00";
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  } else {
    return `${mm}:${ss}`;
  }
};

const styles = (theme) => ({
  videoWrapper: {
    height: "100%",
    width: "100%",
    minHeight: "225px",
  },

  "css-xdmu94-MuiContainer-root": {
    width: "100%",
    marginLeft: "auto",
    boxSizing: "borderBox",
    marginRight: "auto",
    display: "tableCell",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
});

const VideoEx = ({ url, classes, handleFullScreenMode }) => {
  const [usingCss, setUsingCss] = useState("playerDiv1");
  const [showIcons, setShowIcons] = useState(true);
  const [playerstate, setPlayerState] = useState({
    playing: true,
    muted: false,
    volume: 1.0,
    playerbackRate: 1.0,
    played: 0,
    seeking: false,
  });

  //Destructure State in other to get the values in it
  const { playing, muted, volume, playerbackRate, played, seeking } =
    playerstate;
  const playerRef = useRef(null);
  const playerDivRef = useRef(null);

  //This function handles play and pause onchange button
  const handlePlayAndPause = () => {
    setPlayerState({ ...playerstate, playing: !playerstate.playing });
  };
  React.useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 5 second!");
      setShowIcons(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleMuting = () => {
    setPlayerState({ ...playerstate, muted: !playerstate.muted });
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30);
  };

  const handleVolumeChange = (e, newValue) => {
    setPlayerState({
      ...playerstate,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const handleVolumeSeek = (e, newValue) => {
    setPlayerState({
      ...playerstate,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const handlePlayerRate = (rate) => {
    setPlayerState({ ...playerstate, playerbackRate: rate });
  };

  const handlePlayerProgress = (state) => {
    // console.log("onProgress", state);
    if (!playerstate.seeking) {
      setPlayerState({ ...playerstate, ...state });
    }
    // console.log("afterProgress", state);
  };

  const onClick = (event) => {
    console.log("onClick");
    setShowIcons(true);
    const timer = setTimeout(() => {
      console.log("This will run after 5 second!");
      setShowIcons(false);
    }, 5000);
    return () => clearTimeout(timer);
  };
  const MouseOut = (event) => {
    console.log("MouseOut");
    setShowIcons(false);
  };

  const handlePlayerSeek = (e, newValue) => {
    setPlayerState({ ...playerstate, played: parseFloat(newValue / 100) });
    playerRef.current.seekTo(parseFloat(newValue / 100));
  };

  const handlePlayerMouseSeekDown = (e) => {
    setPlayerState({ ...playerstate, seeking: true });
  };

  const handlePlayerMouseSeekUp = (e, newValue) => {
    setPlayerState({ ...playerstate, seeking: false });
    playerRef.current.seekTo(newValue / 100);
  };

  const currentPlayerTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const movieDuration = playerRef.current
    ? playerRef.current.getDuration()
    : "00:00";
  const playedTime = format(currentPlayerTime);
  const fullMovieTime = format(movieDuration);
  const onClickPreview = () => {
    console.log("hiii");
  };
  return (
    <>
      <Container sx={{ float: "right" }} maxWidth="lg">
        <div
          className={usingCss}
          ref={playerDivRef}
          // onMouseOver={MouseOver}
          // onMouseOut={MouseOut}
        >
          {/* <ReactPlayer
            width={"100%"}
            height="100%"
            ref={playerRef}
            url={url}
            // url="blob:http://localhost:3000/d99c4a47-f972-41cc-ad67-989e9e850df0"
            playing={playing}
            volume={volume}
            onClickPreview={onClickPreview}
            playbackRate={playerbackRate}
            onProgress={handlePlayerProgress}
            muted={muted}
            onClick={onClick}
          /> */}
          {showIcons && (
            <ControlIcons
              key={volume.toString()}
              playandpause={handlePlayAndPause}
              playing={playing}
              rewind={handleRewind}
              fastForward={handleFastForward}
              muting={handleMuting}
              muted={muted}
              volumeChange={handleVolumeChange}
              volumeSeek={handleVolumeSeek}
              volume={volume}
              playerbackRate={playerbackRate}
              playRate={handlePlayerRate}
              fullScreenMode={handleFullScreenMode}
              played={played}
              onSeek={handlePlayerSeek}
              onSeekMouseUp={handlePlayerMouseSeekUp}
              onSeekMouseDown={handlePlayerMouseSeekDown}
              playedTime={playedTime}
              fullMovieTime={fullMovieTime}
              seeking={seeking}
            />
          )}
        </div>
      </Container>
    </>
  );
};

// export default VideoEx;
export default withStyles(styles)(VideoEx);
