import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { AcademicDetailsReportJson } from "../../DynamicFormsJson/AcademicDetailsReports";
// import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getActivity } from "../Masters/Faculty/AcademicDetails/Activity/activity.slice";
import { getAllEmployees } from "../EmployeeDetails/employee.slice";

class AcademicDetailsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        academicYear: this.props.academicYearList?.academicYear,
        activity: this.props.activityList.activity,
        teacher: this.props.employeeDetails.employeeList,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getActivity().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getAllEmployees().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.activity !== nextProps.activityList.activity
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: nextProps.activityList.activity,
          },
        });
      }
    }
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employeeList !==
        nextProps.employeeDetails.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacher: nextProps.employeeDetails.employeeList,
          },
        });
      }
    }
  }

  getListById = (data) => {};
  rowData = [
    {
      index: 1,
      reportName: "Awards of Teacher",
      filter: "academicYearId",
      url: "/api/report/v1/employee-award?academicYearId={academicYearId}",
    },
    {
      index: 2,
      reportName: "Extended Activities of Teacher",
      filter: "academicYearId,activityId",
      url: "/api/report/v1/employee-extended-activities?academicYearId={academicYearId}&employeeActivityId={activityId}",
    },
    {
      index: 3,
      reportName: "Details of Teacher",
      filter: "academicYearId",
      url: "/api/report/v1/employee-details?academicYearId={academicYearId}",
    },
    {
      index: 4,
      reportName: "Details of publication",
      filter: "academicYearId,teacherId",
      url: "/api/report/v1/employee-publication?academicYearId={academicYearId}&userRegistrationId={teacherId}",
    },
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={AcademicDetailsReportJson.screenTitle}
          fieldMeta={AcademicDetailsReportJson.fieldMeta}
          buttonCenter={AcademicDetailsReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={AcademicDetailsReportJson.searchButton}
          PDFButton={AcademicDetailsReportJson.PDFButton}
          EXCELButton={AcademicDetailsReportJson.EXCELButton}
          baseIdColumn={AcademicDetailsReportJson.baseIdColumn}
          apiBaseURL={AcademicDetailsReportJson.apiBaseURL}
          marginTop={AcademicDetailsReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  activityList: state.activity,
  employeeDetails: state.employeeList,

  //   yearDetailList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  getAcademicYear,
  getActivity,
  getAllEmployees,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcademicDetailsReport);
