import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  folder: [],
  isFetch: false,
};
let URL = endpoints.folder;
const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    folderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        folder: row,
        isFetch: true,
      };
    },
  },
});

export const { folderSuccess } = folderSlice.actions;

export default folderSlice.reducer;

export const getFolder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            detail: data1.detail,
            date: data1.date,
            folderContents: data1.folderContents.map((folder, index) => {
              folder = {
                index: index + 1,
                deleteId: 0,
                ...folder,
              };
              return folder;
            }),
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(folderSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
