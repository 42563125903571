import endpoint from "../config/endpoints";

export const PlacementJson = {
  apiBaseURL: endpoint.resourcePerson,
  screenTitle: "Placement",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Company",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "title",
      isMandatory: true,
    },
    {
      label: "On / Off campus",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "detail",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMAxDate: true,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "Description",
      controlType: "textarea",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "description",
      isMandatory: true,
    },
  ],
};
