import { FormHelperText, Grid, Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getPlacedStudentList } from "../ExternalStudent/external.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import ExternalStudentTable from "./ExternalPlacedStudentTable";
import FormControl from "@mui/material/FormControl";
import { getDistinctDivision } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { resetReducList } from "../../CommonActions/User.action";
import { ExternalPlacementDynamicJson } from "../../DynamicFormsJson/ExternalPlacementDynamicJson";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import StudentPlacement from "./StudentPlacement";
import { deleteFileData } from "../UploadFile/file.slice";
import {
  getInterestedStudById,
  resetValues,
} from "../Placement/ListOfCompany/PlacementCompanyList.slice";
import { FilterSave } from "../../components/FilterSave";

const columns = [
  { name: "index", title: "Sr. No." },
  { name: "companyName", title: "Student Name With Company" },
  { name: "status", title: "Status" },
  { name: "action", title: "Action" },
];

class ExternalPlacedStudent extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    fromDate: "",
    toDate: "",
    placementType: "",
    flag: false,
    academicYear: "",
    yearDetail: "",
    showLoader: false,
    division: [],
    open: false,
    dynamicMasterData: {
      yearDetailMaster: this.props.internshipDeclarationList?.yearDetails,
      divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      placementTypeMaster: [
        { id: "0", name: "On Campus" },
        { id: "1", name: "Off Campus" },
        { id: "2", name: "Pool Campus" },
      ],
    },
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "studentName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "companyName",
      title: "Company Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  UpdateInfoData = () => {
    console.log("Open Interested Student");
    this.setState({
      open: false,
      flag: false,
    });
    this.props.resetValues();
    this.props.deleteFileData();
  };

  componentDidMount() {
    const { getInternshipType, getAlert, getYearDetails, resetReducList } =
      this.props;

    const ay = localStorage.getItem("acadamicYearId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionIds");

    resetReducList();

    getInternshipType().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId && divisionId && ay) {
      const { getPlacedStudentList } = this.props;

      this.setState({
        showLoader: true,
      });
      getPlacedStudentList({
        academicYearId: ay,
        YearDetail: yearDetailId,
        division: divisionId,
        placementType: "0",
      }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList?.yearDetails !==
        nextProps.internshipDeclarationList?.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.internshipDeclarationList?.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList?.distinctDivision !==
        nextProps.internshipDeclarationList?.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList?.distinctDivision,
          },
        });
      }
    }
  }

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  rowView = (row) => {
    this.props.getInterestedStudById({ id: row.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          open: true,
          flag: true,
        });
      }
    });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  searchData = (data) => {
    const { getPlacedStudentList, getAlert } = this.props;
    const ay = localStorage.getItem("acadamicYearId");
    // if (this.handleFormValidation()) {
    this.setState({
      showLoader: true,
    });
    localStorage.setItem("semisterDetailId", "");
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("academicYearId", ay);
    localStorage.setItem("divisionIds", data.divisionId);
    FilterSave();
    getPlacedStudentList({
      academicYearId: ay,
      YearDetail: data.yearDetail,
      division: data.divisionId,
      placementType: data.placementType,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    // }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onClose = (flag) => {
    this.setState({
      flag: flag,
    });
  };

  multiSelectHandler = (value, name) => {
    console.log(value);
    console.log(name);
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  render() {
    const { academicYear, yearDetail, division, placementType, showLoader } =
      this.state;
    const {
      placementTypeError,
      academicYearError,
      yearDetailError,
      divisionError,
    } = this.state.formErrors;
    const {
      internshipTypeList,
      internshipDeclareCompanyList,
      academicYearList,
      internshipDeclarationList,
    } = this.props;
    return (
      <>
        <div>
          {!this.state.open && (
            <DynamicMainScreen
              // onAddButtonClick={this.onAddButtonClick}
              screenTitle={ExternalPlacementDynamicJson.screenTitle}
              fieldMeta={ExternalPlacementDynamicJson.fieldMeta}
              buttonCenter={ExternalPlacementDynamicJson.buttonCenter}
              showPdfDownload={false}
              showExcelDownload={false}
              tableColumnsToFilter={
                ExternalPlacementDynamicJson.tableColumnsToFilter
              }
              showAddButton={false}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={this.columns}
              getListById={this.getListById}
              tableData={
                this.props.externalStudentList?.appliedPlacedStudentList
              }
              showPegination={false}
              showLoader={showLoader}
              showView={true}
              downLoadPdfFromApi={true}
              pdfDownload={this.downloadPdf}
              rowViewData={this.rowView}
              // onDelete={this.onDelete}
              onSearchData={this.searchData}
              baseIdColumn={ExternalPlacementDynamicJson.baseIdColumn}
              apiBaseURL={ExternalPlacementDynamicJson.apiBaseURL}
            />
          )}
          {this.state.open && <StudentPlacement goBack={this.UpdateInfoData} />}
          {/* <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
            {!this.state.flag && (
              <>
                <Grid container justifyContent="space-evenly" direction="row">
                  <Grid container xs={12} sm={12} md={12}>
                    <Grid sx={{ marginBottom: 2 }} item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label="Off Campus Placement"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    rowSpacing={2}
                    columnSpacing={1}
                    justifyContent="space-evenly"
                  >

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={4}
                      lg={4}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Year Details"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={yearDetail}
                          name={"yearDetail"}
                          options={internshipDeclarationList?.yearDetails}
                          onChange={this.ChangeHandlerSearch}
                          isError={yearDetailError ? true : false}
                          errorText={yearDetailError ? yearDetailError : " "}
                        />
                      </Grid>
                    </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Division"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        er={divisionError}
                        name1="division"
                        value1={division}
                        names={internshipDeclarationList?.distinctDivision}
                        onChange={this.multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Placement Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="placementType"
                          id="placementType"
                          value={placementType}
                          onChange={this.changedHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="0">On Campus</MenuItem>
                          <MenuItem value="1">Off Campus</MenuItem>
                          <MenuItem value="2">Pool Campus</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {placementTypeError ? placementTypeError : " "}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Search"
                      onClick={this.searchData}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item sx={{ marginTop: 2 }}>
              <ExternalStudentTable columns={columns} flag={this.onClose} />
            </Grid>
          </Paper> */}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclareCompanyList: state.companyPlacementList,
  internshipTypeList: state.internshipType,
  externalStudentList: state.external,
  academicYearList: state.academicYear,
  internshipDeclarationList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  getInternshipType,
  getAlert,
  getPlacedStudentList,
  getYearDetails,
  getDistinctDivision,
  resetReducList,
  getInterestedStudById,
  resetValues,
  deleteFileData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalPlacedStudent);
