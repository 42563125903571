import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import {
  apiGet,
  apiGetWithoutHeader,
  apiPostWithoutHeader,
  apiPost,
} from "../../utils/api_service";

const initialState = {
  admissionForm: {},
  isFetch: false,
  subjectSelection: {},
  isFetchSubjectSelection: false,
  applicationIdMsg: "",
  feesDetails: {},
  isFetchFeesDetails: false,
  academicYear: {},
  isFetchAcademicYear: false,
  keySuccess: {},
  isFetchKeySuccess: false,
};
let URL = endpoints.admissionForm;
let URL2 = endpoints.subjectSelection;
let URL3 = endpoints.feesCollection;
let URL4 = endpoints.academicYearPrelogin;
let URL5 = endpoints.saveAdmissionForm;

const admissionFormSlice = createSlice({
  name: "admissionForm",
  initialState,
  reducers: {
    admissionFormSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        admissionForm: data,
        isFetch: true,
      };
    },
    admissionFormFails: (state = cloneDeep(initialState), action) => {
      const { msg } = action.payload;
      return {
        ...state,
        applicationIdMsg: msg,
        isFetch: true,
      };
    },
    subjectSelectionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectSelection: row,
        isFetchSubjectSelection: true,
      };
    },
    feesDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        feesDetails: data,
        isFetchFeesDetails: true,
      };
    },
    academicYearSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        academicYear: data,
        isFetchAcademicYear: true,
      };
    },
    keySuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        keySuccess: data,
        isFetchKeySuccess: true,
      };
    },
    resetErrorSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        applicationIdMsg: "",
        admissionForm: "",
        isFetch: false,
      };
    },
  },
});

export const {
  admissionFormSuccess,
  admissionFormFails,
  subjectSelectionSuccess,
  feesDetailsSuccess,
  academicYearSuccess,
  resetErrorSuccess,
  keySuccess,
} = admissionFormSlice.actions;

export default admissionFormSlice.reducer;

export const getAdmissionFormPreLogin =
  ({ applicationNumber, domainName }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url:
          URL +
          "?domainName=" +
          domainName +
          "&applicationNumber=" +
          applicationNumber,
      }).then(({ data, success }) => {
        if (success) {
          if (data.data.errorMessage.error !== false) {
            dispatch(
              admissionFormFails({ msg: data.data.errorMessage.message })
            );
            return data;
          } else {
            const admissionList =
              data.data.getUserRoundAllocationAndUserApplication;
            if (admissionList != null) {
              let userData = {
                //   id: admissionList.getUserRoundAllocationAndUserApplication.userApplication.userRegistration.userId,
                fatherName:
                  admissionList?.userApplication?.userRegistration?.fatherName,
                aadhaarNo:
                  admissionList?.userApplication?.userRegistration?.aadhaarNo,
                annualIncome:
                  admissionList.userApplication.userRegistration
                    .annualIncome !== null
                    ? admissionList.userApplication.userRegistration
                        .annualIncome.name
                    : "",
                birthPlace:
                  admissionList.userApplication.userRegistration.birthPlace,
                bloodGroup:
                  admissionList.userApplication.userRegistration.bloodGroup !==
                  null
                    ? admissionList.userApplication.userRegistration.bloodGroup
                        .name
                    : "",
                cast: admissionList.userApplication.userRegistration.cast,
                castCertificateDate:
                  admissionList.userApplication.userRegistration
                    .castCertificateDate,
                castCertificateNo:
                  admissionList.userApplication.userRegistration
                    .castCertificateNo,
                category:
                  admissionList.userApplication.userRegistration.category !==
                  null
                    ? admissionList.userApplication.userRegistration.category
                        .name
                    : "",
                categoryId:
                  admissionList.userApplication.userRegistration.category !==
                  null
                    ? admissionList.userApplication.userRegistration.category.id
                    : "",
                dateOfBirth:
                  admissionList.userApplication.userRegistration.dateOfBirth,
                defenceType:
                  admissionList.userApplication.userRegistration.defenceType,
                domicileof:
                  admissionList.userApplication.userRegistration.domicileof,
                fatherOccupation:
                  admissionList.userApplication.userRegistration
                    .fatherOccupation,
                firstName:
                  admissionList.userApplication.userRegistration.firstName,
                gender: admissionList.userApplication.userRegistration.gender,
                handicapCategory:
                  admissionList.userApplication.userRegistration
                    .handicapCategory,
                isOrphan:
                  admissionList.userApplication.userRegistration.isOrphan,
                isStudentNriForeignNational:
                  admissionList.userApplication.userRegistration
                    .isStudentNriForeignNational === 0
                    ? "None"
                    : admissionList.userApplication.userRegistration
                        .isStudentNriForeignNational === 1
                    ? "NRI"
                    : admissionList.userApplication.userRegistration
                        .isStudentNriForeignNational === 2
                    ? "Foreign National"
                    : "",
                landlineNo:
                  admissionList.userApplication.userRegistration.landlineNo,
                medium:
                  admissionList.userApplication.userRegistration
                    .mediumOfLanguageInPreviousCollege !== null
                    ? admissionList.userApplication.userRegistration
                        .mediumOfLanguageInPreviousCollege
                    : "",
                lastName: admissionList.userApplication.userRegistration
                  .lastName
                  ? admissionList.userApplication.userRegistration.lastName
                  : "",

                martialStatus:
                  admissionList.userApplication.userRegistration
                    .martialStatus === 0
                    ? "Un-Married"
                    : admissionList.userApplication.userRegistration
                        .martialStatus === 1
                    ? "Married"
                    : admissionList.userApplication.userRegistration
                        .martialStatus === 2
                    ? "Divorced"
                    : admissionList.userApplication.userRegistration
                        .martialStatus === 3
                    ? "widowed"
                    : "",

                middleName: admissionList.userApplication.userRegistration
                  .middleName
                  ? admissionList.userApplication.userRegistration.middleName
                  : "",
                minority:
                  admissionList.userApplication.userRegistration.minority,
                motherName:
                  admissionList.userApplication.userRegistration.motherName,
                motherTongue:
                  admissionList.userApplication.userRegistration.motherTongue,
                nationality:
                  admissionList.userApplication.userRegistration.nationality,
                oms:
                  admissionList.userApplication.userRegistration.oms === 0
                    ? "No"
                    : admissionList.userApplication.userRegistration.oms === 1
                    ? "Yes"
                    : "",
                parentEmail:
                  admissionList.userApplication.userRegistration.parentEmail,
                parentMobile:
                  admissionList.userApplication.userRegistration.parentMobile,
                permanentAddressLine1:
                  admissionList.userApplication.userRegistration
                    .permanentAddressLine1,
                permanentAddressLine2:
                  admissionList.userApplication.userRegistration
                    .permanentAddressLine2,
                permanentAddressLine3:
                  admissionList.userApplication.userRegistration
                    .permanentAddressLine3,
                permanentCity:
                  admissionList.userApplication.userRegistration.permanentCity,
                permanentDistrictName:
                  admissionList.userApplication.userRegistration
                    .permanentDistrict !== null
                    ? admissionList.userApplication.userRegistration
                        .permanentDistrict.name
                    : "",
                permanentPin:
                  admissionList.userApplication.userRegistration.permanentPin,
                permanentState:
                  admissionList.userApplication.userRegistration
                    .permanentState !== null
                    ? admissionList.userApplication.userRegistration
                        .permanentState.name
                    : "",
                permanentTalukaName:
                  admissionList.userApplication.userRegistration
                    .permanentTaluka !== null
                    ? admissionList.userApplication.userRegistration
                        .permanentTaluka.name
                    : "",
                profilePic:
                  admissionList.userApplication.userRegistration.profilePic,
                religion:
                  admissionList.userApplication.userRegistration.religion !==
                  null
                    ? admissionList.userApplication.userRegistration.religion
                        .name
                    : "",
                remark: admissionList.userApplication.userRegistration.remark,
                ruralUrban:
                  admissionList.userApplication.userRegistration.ruralUrban,
                scholarship:
                  admissionList.userApplication.userRegistration.scholarship ===
                  0
                    ? "No"
                    : admissionList.userApplication.userRegistration
                        .scholarship === 1
                    ? "Yes"
                    : "",
                signature:
                  admissionList.userApplication.userRegistration.signature,
                studentEmail:
                  admissionList.userApplication.userRegistration.studentEmail,
                studentMobile:
                  admissionList.userApplication.userRegistration.studentMobile,
                subCast: admissionList.userApplication.userRegistration.subCast,
                temporaryAddressLine1:
                  admissionList.userApplication.userRegistration
                    .temporaryAddressLine1,
                temporaryAddressLine2:
                  admissionList.userApplication.userRegistration
                    .temporaryAddressLine2,
                temporaryAddressLine3:
                  admissionList.userApplication.userRegistration
                    .temporaryAddressLine3,
                temporaryCity:
                  admissionList.userApplication.userRegistration.temporaryCity,
                temporaryDistrict:
                  admissionList.userApplication.userRegistration
                    .temporaryDistrict !== null
                    ? admissionList.userApplication.userRegistration
                        .temporaryDistrict.name
                    : "",
                temporaryPin:
                  admissionList.userApplication.userRegistration.temporaryPin,
                temporaryState:
                  admissionList.userApplication.userRegistration
                    .temporaryState !== null
                    ? admissionList.userApplication.userRegistration
                        .temporaryState.name
                    : "",
                temporaryTaluka:
                  admissionList.userApplication.userRegistration
                    .temporaryTaluka !== null
                    ? admissionList.userApplication.userRegistration
                        .temporaryTaluka.name
                    : "",
                validityCdDate:
                  admissionList.userApplication.userRegistration.validityCdDate,
                validityCdNo:
                  admissionList.userApplication.userRegistration.validityCdNo,
                program:
                  admissionList?.programTitle?.programType?.program?.name,
                programTitle: admissionList?.programTitle?.brName,
                programTitleId: admissionList?.programTitle?.id,
                yearName: admissionList?.yearDetail?.year?.className,
                // signature:
                //   admissionList.userApplication.userRegistration.signature,
                // profilePic:
                //   admissionList.userApplication.userRegistration.profilePic,
                yearDetailId: admissionList.yearDetail.id,
                userQualificationDetails:
                  admissionList.userApplication.userQualificationDetails ===
                  null
                    ? []
                    : admissionList.userApplication.userQualificationDetails.map(
                        (data, index) => {
                          let docs = {
                            index: index + 1,
                            university: data.university,
                            board: data.board,
                            nameOfSchoolCollege: data.nameOfSchoolCollege,
                            qualification: data.qualification.name,
                            yearOfPass: data.yearOfPass,
                            percentage: data.percentage,
                            marksObtained: data.marksObtained,
                            marksOutof: data.marksOutof,
                          };
                          return docs;
                        }
                      ),
                userDocumentDetails:
                  admissionList.userApplication.userDocumentDetails === null
                    ? []
                    : admissionList.userApplication.userDocumentDetails.map(
                        (data, index) => {
                          let docss = {
                            index: index + 1,
                            documentFileName: data.documentFileName,
                            documentName: data.document.name,
                          };
                          return docss;
                        }
                      ),
              };

              dispatch(admissionFormSuccess({ data: userData }));
              return data;
            }
          }
        }
        return null;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdmissionForm =
  ({ applicationNumber, domainName }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "?domainName=" +
          domainName +
          "&applicationNumber=" +
          applicationNumber,
      }).then(({ data, success }) => {
        if (success) {
          if (data.data.errorMessage.error !== false) {
            dispatch(
              admissionFormFails({ msg: data.data.errorMessage.message })
            );
            return data;
          } else {
            const admissionList =
              data.data.getUserRoundAllocationAndUserApplication;
            if (admissionList != null) {
              let userData = {
                //   id: admissionList.getUserRoundAllocationAndUserApplication.userApplication.userRegistration.userId,
                fatherName:
                  admissionList?.userApplication?.userRegistration?.fatherName,
                aadhaarNo:
                  admissionList?.userApplication?.userRegistration?.aadhaarNo,
                annualIncome:
                  admissionList.userApplication.userRegistration
                    .annualIncome !== null
                    ? admissionList.userApplication.userRegistration
                        .annualIncome.name
                    : "",
                birthPlace:
                  admissionList.userApplication.userRegistration.birthPlace,
                bloodGroup:
                  admissionList.userApplication.userRegistration.bloodGroup !==
                  null
                    ? admissionList.userApplication.userRegistration.bloodGroup
                        .name
                    : "",
                cast: admissionList.userApplication.userRegistration.cast,
                castCertificateDate:
                  admissionList.userApplication.userRegistration
                    .castCertificateDate,
                castCertificateNo:
                  admissionList.userApplication.userRegistration
                    .castCertificateNo,
                category:
                  admissionList.userApplication.userRegistration.category !==
                  null
                    ? admissionList.userApplication.userRegistration.category
                        .name
                    : "",
                categoryId:
                  admissionList.userApplication.userRegistration.category !==
                  null
                    ? admissionList.userApplication.userRegistration.category.id
                    : "",
                dateOfBirth:
                  admissionList.userApplication.userRegistration.dateOfBirth,
                defenceType:
                  admissionList.userApplication.userRegistration.defenceType,
                domicileof:
                  admissionList.userApplication.userRegistration.domicileof,
                fatherOccupation:
                  admissionList.userApplication.userRegistration
                    .fatherOccupation,
                firstName:
                  admissionList.userApplication.userRegistration.firstName,
                gender: admissionList.userApplication.userRegistration.gender,
                handicapCategory:
                  admissionList.userApplication.userRegistration
                    .handicapCategory,
                isOrphan:
                  admissionList.userApplication.userRegistration.isOrphan,
                isStudentNriForeignNational:
                  admissionList.userApplication.userRegistration
                    .isStudentNriForeignNational === 0
                    ? "None"
                    : admissionList.userApplication.userRegistration
                        .isStudentNriForeignNational === 1
                    ? "NRI"
                    : admissionList.userApplication.userRegistration
                        .isStudentNriForeignNational === 2
                    ? "Foreign National"
                    : "",
                landlineNo:
                  admissionList.userApplication.userRegistration.landlineNo,
                lastName:
                  admissionList.userApplication.userRegistration.lastName,

                martialStatus:
                  admissionList.userApplication.userRegistration
                    .martialStatus === 0
                    ? "Un-Married"
                    : admissionList.userApplication.userRegistration
                        .martialStatus === 1
                    ? "Married"
                    : admissionList.userApplication.userRegistration
                        .martialStatus === 2
                    ? "Divorced"
                    : admissionList.userApplication.userRegistration
                        .martialStatus === 3
                    ? "widowed"
                    : "",

                middleName:
                  admissionList.userApplication.userRegistration.middleName,
                minority:
                  admissionList.userApplication.userRegistration.minority,
                motherName:
                  admissionList.userApplication.userRegistration.motherName,
                motherTongue:
                  admissionList.userApplication.userRegistration.motherTongue,
                nationality:
                  admissionList.userApplication.userRegistration.nationality,
                oms:
                  admissionList.userApplication.userRegistration.oms === 0
                    ? "No"
                    : admissionList.userApplication.userRegistration.oms === 1
                    ? "Yes"
                    : "",
                parentEmail:
                  admissionList.userApplication.userRegistration.parentEmail,
                parentMobile:
                  admissionList.userApplication.userRegistration.parentMobile,
                permanentAddressLine1:
                  admissionList.userApplication.userRegistration
                    .permanentAddressLine1,
                permanentAddressLine2:
                  admissionList.userApplication.userRegistration
                    .permanentAddressLine2,
                permanentAddressLine3:
                  admissionList.userApplication.userRegistration
                    .permanentAddressLine3,
                permanentCity:
                  admissionList.userApplication.userRegistration.permanentCity,
                permanentDistrictName:
                  admissionList.userApplication.userRegistration
                    .permanentDistrict !== null
                    ? admissionList.userApplication.userRegistration
                        .permanentDistrict.name
                    : "",
                permanentPin:
                  admissionList.userApplication.userRegistration.permanentPin,
                permanentState:
                  admissionList.userApplication.userRegistration
                    .permanentState !== null
                    ? admissionList.userApplication.userRegistration
                        .permanentState.name
                    : "",
                permanentTalukaName:
                  admissionList.userApplication.userRegistration
                    .permanentTaluka !== null
                    ? admissionList.userApplication.userRegistration
                        .permanentTaluka.name
                    : "",
                profilePic:
                  admissionList.userApplication.userRegistration.profilePic,
                religion:
                  admissionList.userApplication.userRegistration.religion !==
                  null
                    ? admissionList.userApplication.userRegistration.religion
                        .name
                    : "",
                remark: admissionList.userApplication.userRegistration.remark,
                ruralUrban:
                  admissionList.userApplication.userRegistration.ruralUrban,
                scholarship:
                  admissionList.userApplication.userRegistration.scholarship ===
                  0
                    ? "No"
                    : admissionList.userApplication.userRegistration
                        .scholarship === 1
                    ? "Yes"
                    : "",
                signature:
                  admissionList.userApplication.userRegistration.signature,
                studentEmail:
                  admissionList.userApplication.userRegistration.studentEmail,
                studentMobile:
                  admissionList.userApplication.userRegistration.studentMobile,
                subCast: admissionList.userApplication.userRegistration.subCast,
                temporaryAddressLine1:
                  admissionList.userApplication.userRegistration
                    .temporaryAddressLine1,
                temporaryAddressLine2:
                  admissionList.userApplication.userRegistration
                    .temporaryAddressLine2,
                temporaryAddressLine3:
                  admissionList.userApplication.userRegistration
                    .temporaryAddressLine3,
                temporaryCity:
                  admissionList.userApplication.userRegistration.temporaryCity,
                temporaryDistrict:
                  admissionList.userApplication.userRegistration
                    .temporaryDistrict !== null
                    ? admissionList.userApplication.userRegistration
                        .temporaryDistrict.name
                    : "",
                temporaryPin:
                  admissionList.userApplication.userRegistration.temporaryPin,
                temporaryState:
                  admissionList.userApplication.userRegistration
                    .temporaryState !== null
                    ? admissionList.userApplication.userRegistration
                        .temporaryState.name
                    : "",
                temporaryTaluka:
                  admissionList.userApplication.userRegistration
                    .temporaryTaluka !== null
                    ? admissionList.userApplication.userRegistration
                        .temporaryTaluka.name
                    : "",
                validityCdDate:
                  admissionList.userApplication.userRegistration.validityCdDate,
                validityCdNo:
                  admissionList.userApplication.userRegistration.validityCdNo,
                program:
                  admissionList?.programTitle?.programType?.program?.name,
                programTitle: admissionList?.programTitle?.brName,
                programTitleId: admissionList?.programTitle?.id,
                yearName: admissionList?.yearDetail?.year?.className,
                // signature:
                //   admissionList.userApplication.userRegistration.signature,
                // profilePic:
                //   admissionList.userApplication.userRegistration.profilePic,
                yearDetailId: admissionList.yearDetail.id,
                userQualificationDetails:
                  admissionList.userApplication.userQualificationDetails ===
                  null
                    ? []
                    : admissionList.userApplication.userQualificationDetails.map(
                        (data, index) => {
                          let docs = {
                            index: index + 1,
                            university: data.university,
                            board: data.board,
                            nameOfSchoolCollege: data.nameOfSchoolCollege,
                            qualification: data.qualification.name,
                            yearOfPass: data.yearOfPass,
                            percentage: data.percentage,
                            marksObtained: data.marksObtained,
                            marksOutof: data.marksOutof,
                          };
                          return docs;
                        }
                      ),
                userDocumentDetails:
                  admissionList.userApplication.userDocumentDetails === null
                    ? []
                    : admissionList.userApplication.userDocumentDetails.map(
                        (data, index) => {
                          let docss = {
                            index: index + 1,
                            documentFileName: data.documentFileName,
                            documentName: data.document.name,
                          };
                          return docss;
                        }
                      ),
              };

              dispatch(admissionFormSuccess({ data: userData }));
              return data;
            }
          }
        }
        return null;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getSubjectSelection =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url: URL2 + "?domainName=localhost:3000&yearDetailId=" + yearDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const subjectList = data.data;
          let index = 0;
          let row = [];
          subjectList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              semisterName: data1.name,
              subjectList: data1.getSubjects.map((data) => {
                let sub = {
                  id: data.id,
                  subjectName: data.name,
                  subjectShortName: data.subjectTypeId.shortName,
                  subjectTypeId: data.subjectTypeId.id,
                  subjectDetails: data.subjectDetails.map((data1) => {
                    let subDetails = {
                      id: data1.id,
                      maxMarks: data1.maxMarks,
                      minMarks: data1.minMarks,
                      value: data1.value,
                    };

                    return subDetails;
                  }),
                };
                return sub;
              }),
              subjectType: data1.subjectTypes.map((data1) => {
                let subType = {
                  id: data1.id,
                  subjectTypeName: data1.name,
                  subjectTypeShowName: data1.showName,
                  subjectTypeShortName: data1.shortName,
                };
                return subType;
              }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectSelectionSuccess({ row }));
          return row;
        }
        return null;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getFessDeatilsPreLogin =
  ({ categoryId, scholarship, yearDetailId, academicYearId, domainName }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url:
          URL3 +
          "?domainName=" +
          domainName +
          "&categoryId=" +
          categoryId +
          "&scholarship=" +
          scholarship +
          "&yearDetailId=" +
          yearDetailId +
          "&academicYearId=" +
          academicYearId,
      }).then(({ data, success }) => {
        if (success) {
          const feesList = data.data.getFeesStructureHeader;
          if (feesList != null) {
            let index = 0;
            let Data = {
              index: index,
              id: feesList.id,
              feesDetailList: feesList.feesStructureDetails.map(
                (data, index) => {
                  let fee = {
                    index: index + 1,
                    id: data.id,
                    feeTitle: data.feesHead.feesTitle.name,
                    amount: data.amt,
                  };
                  return fee;
                }
              ),
            };

            dispatch(feesDetailsSuccess({ data: Data }));
            return Data;
          }
        }
        return null;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getFessDeatils =
  ({ categoryId, scholarship, yearDetailId, academicYearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL3 +
          // "?domainName="+domainName+"&categoryId=" +
          "?domainName=1&categoryId=" +
          categoryId +
          "&scholarship=" +
          scholarship +
          "&yearDetailId=" +
          yearDetailId +
          "&academicYearId=" +
          academicYearId,
      }).then(({ data, success }) => {
        if (success) {
          const feesList = data.data.getFeesStructureHeader;
          if (feesList != null) {
            let index = 0;
            let Data = {
              index: index,
              id: feesList.id,
              feesDetailList: feesList.feesStructureDetails.map(
                (data, index) => {
                  let fee = {
                    index: index + 1,
                    id: data.id,
                    feeTitle: data.feesHead.feesTitle.name,
                    amount: data.amt,
                  };
                  return fee;
                }
              ),
            };

            dispatch(feesDetailsSuccess({ data: Data }));
            return Data;
          }
        }
        return null;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAcademicYearPreLogin =
  ({ domainName }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url: URL4 + "?domainName=" + domainName,
      }).then(({ data, success }) => {
        if (success) {
          const academicList = data.data;
          let index = 0;
          let Data = {
            index: index,
            id: academicList.id,
            academiYearName: academicList.name,
          };

          dispatch(academicYearSuccess({ data: Data }));
          return Data;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getKeyPreLogin =
  ({ domainName }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url:
          "/api/config/prelogin/get-institute-config-by-key?domainName=" +
          domainName +
          "&key=is_subject_allowed",
      }).then(({ data, success }) => {
        if (success) {
          const academicList = data.data;
          let Data = {
            id: academicList.instituteConfig.id,
            key: academicList.instituteConfig.key,
            value: academicList.instituteConfig.value,
          };

          dispatch(keySuccess({ data: Data }));
          return Data;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveAdmissionForm =
  ({ paramsData, saveToData, domainName }) =>
  async (dispatch) => {
    try {
      const response = apiPostWithoutHeader({
        url:
          URL5 +
          "?domainName=" +
          domainName +
          "&applicationNumber=" +
          paramsData.appliId +
          "&feesStructureHeaderId=" +
          paramsData.feeDetail +
          "&academicYearId=" +
          paramsData.academicYear,
        postBody: saveToData,
      }).then(({ data, success }) => {
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetError = () => async (dispatch) => {
  try {
    dispatch(resetErrorSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
