import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
// import PasswordStrengthBar from "react-password-strength-bar";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import NotificationToast from "../../components/Comman/NotificationToast";
import { validEmail } from "../../components/Comman/Util/Validations";
import { Loading } from "../../components/Loader";
import Header from "../../components/NewHeader";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiGetWithoutHeader } from "../../utils/api_service";
import { closeNotification, showNotification } from "../Landing/Landing.slice";
let docs;
class ForgotUsernamePreLogin extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    dob: "",
    emailId: "",
    enterOtp: "",
    otpCheck: "1",
    formErrors: [],
    loading: false,
    isDisable: false,
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {}

  generateOtp = () => {
    const { dob, emailId, otpCheck } = this.state;
    let dateOF = moment(dob).format("YYYY-MM-DD");
    if (this.handleFormValidation()) {
      this.setState({
        loading: true,
      });
      apiGetWithoutHeader({
        url:
          endpoint.preloginAlumniRegister +
          "/forgot-user-name?emailId=" +
          emailId +
          "&baseUrl=" +
          window.location.host +
          "&dob=" +
          dateOF +
          "&type=" +
          otpCheck,
      }).then(({ success }) => {
        if (success) {
          this.setState({
            loading: false,
            isDisable: true,
          });
          this.props.showNotification({
            msg: "OTP generate successfully",
          });
        } else {
          this.setState({
            loading: false,
          });
          this.props.showNotification({
            msg: "Failed to validate",
            severity: "error",
          });
        }
      });
    }
  };
  validateOtp = () => {
    const { emailId, enterOtp } = this.state;
    if (this.handleFormValidationOtp()) {
      var emailString = emailId;
      var name = emailString.substring(0, emailString.lastIndexOf("@"));
      var mailArr = emailString.split("@");
      var logn = mailArr[0];
      var mailHost = mailArr[1];
      var last3 = logn.slice(-4);

      this.setState({
        loading: true,
      });
      apiGetWithoutHeader({
        url:
          endpoint.preloginAlumniRegister +
          "/validate-otp?emailId=" +
          emailId +
          "&baseUrl=" +
          window.location.host +
          "&otp=" +
          enterOtp,
      }).then(({ success }) => {
        if (success) {
          this.setState({
            loading: false,
          });
          Swal.fire({
            icon: "success",
            title: "Username Send Successfully",
            text:
              "  Your User id has been sent to your registered Email id XXXX" +
              last3 +
              "@" +
              mailHost,
            showConfirmButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#1f89f4",
            confirmButtonText: "Login",
          }).then((okLogin) => {
            if (okLogin.isConfirmed) {
              window.location.replace("/login");
            }
          });
        } else {
          this.setState({
            loading: false,
          });
          this.props.showNotification({
            msg: "Failed to validate",
            severity: "error",
          });
        }
      });
    }
  };
  changeFromDate = (date) => {
    this.setState({
      dob: date,
    });
  };

  handleFormValidation(name) {
    const { dob, emailId } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    if (!validEmail.test(emailId)) {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.invalidEmailIdMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpError"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  closeSnak = () => {
    const { closeNotification } = this.props;
    closeNotification();
  };

  cancelHandler = () => {
    window.location.replace("/login");
  };

  render() {
    const { emailIdErr, dobErr, otpError } = this.state.formErrors;

    const { landingData } = this.props;
    const { loading, dob, emailId, enterOtp, isDisable, otpCheck } = this.state;
    return (
      <>
        <NotificationToast
          open={landingData.isShowNotification}
          severity={
            landingData.severity == undefined ? "success" : landingData.severity
          }
          msg={landingData.msg}
          closeSnak={this.closeSnak}
        />
        <div align="center">
          <Header />
          {/* <Navbar /> */}
          <div className="new_content login">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Forgot Username</h3>

                    <div className="lgn_container">
                      <div className="user_lgn">
                        <div className="user_label">*Email ID :</div>
                        <div className="user_field">
                          <TextField
                            placeholder="abcd@gmail.com"
                            color="primary"
                            name="emailId"
                            size="small"
                            value={emailId}
                            onChange={this.changedHandler}
                            fullWidth
                            error={emailIdErr ? true : false}
                            helperText={emailIdErr ? emailIdErr : " "}
                            disabled={isDisable}
                          />
                        </div>
                      </div>
                      <div className="user_lgn">
                        <div className="user_label">*D.O.B :</div>
                        <div className="user_field">
                          <BasicDatePicker
                            name="dob"
                            id="dob"
                            value={dob}
                            maxDate={new Date()}
                            onChange={this.changeFromDate}
                            isError={dobErr ? true : false}
                            errorText={dobErr ? dobErr : " "}
                            disabled={isDisable}
                          />
                        </div>
                      </div>
                      <div className="user_lgn">
                        <div className="user_label">
                          Select Contact Number or Email id For OTP Verification
                        </div>
                        <div className="user_field">
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="Is Active"
                              id="otpCheck"
                              name="otpCheck"
                              value={otpCheck}
                              onChange={this.changedHandler}
                              size="small"
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Email id"
                              />
                              &nbsp;&nbsp;&nbsp;
                              <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Mobile No."
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                      {!isDisable && (
                        <div className="lgn_btn">
                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.generateOtp}
                          >
                            Generate OTP
                          </button>

                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.cancelHandler}
                          >
                            Cancel
                          </button>
                        </div>
                      )}

                      {isDisable && (
                        <>
                          <div className="user_lgn">
                            <div className="user_label">*Enter OTP :</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="enterOtp"
                                size="small"
                                value={enterOtp}
                                onChange={this.changedHandler}
                                fullWidth
                                error={otpError ? true : false}
                                helperText={otpError ? otpError : " "}
                              />
                            </div>
                          </div>
                          <div className="lgn_btn">
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.validateOtp}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Resend OTP
                            </button>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.cancelHandler}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </div>
          </div>
        </div>
        {loading && <Loading />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  landingData: state.landing,
});

const mapDispatchToProps = { showNotification, closeNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotUsernamePreLogin);
