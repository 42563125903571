import {
  FormHelperText,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../../components/Loading1";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
import { showNotification } from "../../../Landing/Landing.slice";
import CheckBoxSelectionTable from "../../../../components/Comman/RT/CheckBoxSelectionTable";
import AluminiProfile from "../../AluminiProfile/index";
// import { getProgramTitleNew } from "../../Masters/OnlineModule/ProgramTitle/programTitle.slice";
import { getProgramTitleNew } from "../../../Masters/OnlineModule/ProgramTitle/programTitle.slice";
import { getYearDetailByProgramTitle } from "../../../Masters/OnlineModule/Class/class.slice";
import {
  getAlumini,
  getAluminiById,
  getAlumniListByYearDetail,
} from "../AluminiStatus/alumini.slice";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
const AdminAddAlumni = ({
  getAluminiById,
  alumini,
  getAlert,
  programTitleList,
  getProgramTitleNew,
  getYearDetailByProgramTitle,
  classList,
  getAlumniListByYearDetail,
}) => {
  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      align: "center",
      minWidth: "10%",
      canSearch: true,
    },
    // {
    //   name: "studentName",
    //   title: "Student Name",
    //   positionCenter: true,
    //   showInscreen: true,
    //   minWidth: "40%",
    //   align: "left",
    //   canSearch: true,
    // },
    {
      name: "studentEmail",
      title: "Email",
      minWidth: "25%",
      positionCenter: true,
      showInscreen: true,
      align: "left",
      canSearch: true,
    },
    {
      name: "studentMobile",
      title: "Contact No.",
      positionCenter: true,
      minWidth: "20%",
      showInscreen: true,
      align: "left",
      canSearch: true,
    },
  ];
  const [showLoader, setShowLoader] = useState(false);
  const [changeStatus, setChangeStatus] = React.useState("");
  const [changeSearchBy, setChangeSearchBy] = React.useState("");
  const [searchData, setSearchData] = React.useState("");

  const [formErrors, setFormErrors] = React.useState({});
  const [list, setList] = React.useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [status, setStatus] = React.useState([]);
  const [programTitle, setProgramTitle] = React.useState("");
  const [yearDetail, setYearDetail] = React.useState("");
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    setShowLoader(true);
    getProgramTitleNew().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
  }, []);
  const checkSearchValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail === "") {
      formIsValid = false;
      formErrors["yearDetailError"] = "*Select year details";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const checkValidationTable = () => {
    let formErrors = {};
    let formIsValid = true;
    if (list.length === 0) {
      formIsValid = false;
      formErrors["emptyListError"] = "*Please select Atleast One Student";
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name === "programTitle") {
        setProgramTitle(newValue);
        setShowLoader(true);
        getYearDetailByProgramTitle({ progTitle: newValue }).then(
          (response) => {
            if (!response) {
              setShowLoader(false);
              getAlert({ message: "Something went wrong" });
            } else {
              setShowLoader(false);
            }
          }
        );
      } else {
        setYearDetail(newValue);
      }
    } else {
      if (name === "programTitle") {
        setProgramTitle("");
      } else {
        setYearDetail("");
      }
    }
  };

  const getRow = (e) => {
    setList(e);
  };
  const searchHandler = () => {
    if (checkSearchValidation()) {
      setShowLoader(true);
      getAlumniListByYearDetail({ yearDetail: yearDetail }).then((response) => {
        if (!response) {
          setShowLoader(false);
          getAlert({ message: "Something went wrong" });
        } else {
          setShowLoader(false);
        }
      });
    }
  };

  const submitHandler = () => {
    if (checkValidationTable()) {
      console.log(list);
      if (list.length !== 0) {
        swal({
          title: "Are you sure?",
          text: " Do you want to add students as a alumni",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((newValue) => {
          if (newValue) {
            setShowLoader(true);
            apiGet({
              url:
                endpoints.alumniRegister +
                "/student-to-alumni?userRegistrationIds=" +
                list,
            }).then(({ success }) => {
              if (success) {
                getAlumniListByYearDetail({ yearDetail: yearDetail }).then(
                  (response) => {
                    if (!response) {
                      setShowLoader(false);
                      getAlert({ message: "Something went wrong" });
                    } else {
                      setShowLoader(false);
                    }
                  }
                );
              } else {
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
    }
  };

  const rowView = (row) => {
    getAluminiById({
      id: row.id,
    }).then((response) => {
      if (response) {
        setOpenPreview(true);
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  const onSearch = (searchValue) => {
    setSearchData(searchValue);
  };
  const getFilteredTableData = () => {
    return alumini.aluminiList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["studentName"] &&
          currentRow["studentName"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["studentEmail"] &&
          currentRow["studentEmail"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["studentMobile"] &&
          currentRow["studentMobile"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  return (
    <>
      {showLoader && <Loading1 />}
      {console.log(formErrors)}
      <LandingScreenHeader
        tableColumnsToFilter={[]}
        screenTitle={"Admin Add Alumnae"}
        showPdfDownload={false}
        showExcelDownload={false}
        showAddButton={false}
        onSearch={onSearch}
        showTable={true}
      />
      <br />
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {/* ********* */}
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          xs={12}
          sm={12}
          md={12}
          // sx={{ margin: 2 }}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            // sx={{ marginTop: 2 }}
            spacing={1}
          >
            <Grid item xs={12} sm={5} md={5}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Program Title"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={programTitle}
                    name={"programTitle"}
                    fullWidth
                    options={programTitleList.progTitle}
                    onChange={ChangeHandlerSearch}
                    isError={false}
                    errorText={" "}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Year Details"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={yearDetail}
                    name={"yearDetail"}
                    fullWidth
                    options={classList.yearDetailByProgramTitle}
                    onChange={ChangeHandlerSearch}
                    isError={formErrors.yearDetailError ? true : false}
                    errorText={
                      formErrors.yearDetailError
                        ? formErrors.yearDetailError
                        : " "
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Search"
                    onClick={searchHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* ********* */}

        <CheckBoxSelectionTable
          rows={getFilteredTableData()}
          columns={columns}
          rowViewData={rowView}
          showView={true}
          selection={list}
          isActionColActive={false}
          onSelectionChange={getRow}
        />
        <FormHelperText error>
          {formErrors.emptyListError ? formErrors.emptyListError : " "}
        </FormHelperText>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          justifyContent="flex-end"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label=""
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Submit"
                onClick={submitHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  programTitleList: state.programTitle,
  classList: state.class,
  alumini: state.alumini,
});

const mapDispatchToProps = {
  getAlert,
  getAlumini,
  showNotification,
  getAluminiById,
  getProgramTitleNew,
  getYearDetailByProgramTitle,
  getAlumniListByYearDetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminAddAlumni);
