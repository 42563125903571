import { fabClasses } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import Alert from "../../../Alert/Alert";
import { DirectionSnackbar } from "../../../components/Comman/SnakBarCompo";
import { Loading1 } from "../../../components/Loading1";
import * as myConstClass from "../../../config/messageconstant";
// import ProgMView from "./ProgMView";

import {
  getGenerateOTP,
  getValidateOtp,
  resetDelete,
  resetError,
  saveParentRegister,
} from "./ParentRegister.slice";
import ParentRegisterForm from "./ParentBasicDetail";

const ParentRegister = ({
  saveParentRegister,
  getGenerateOTP,
  getValidateOtp,
  studentRegData,
  resetError,
  resetDelete,
}) => {
  const navigate = useNavigate();
  const [snakMsg, setSnakMsg] = useState("");
  const [editRow, setEditRow] = useState([]);
  const [enterOtp, setEnterOtp] = useState();
  const [regUser, setRegUser] = useState({});
  const [openSnak, setOpenSnak] = useState(false);
  const [basicDetailFlag, setBasicDetailFlag] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [openSuccess, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [loader, setLoader] = useState(false);

  const studentRegisterData = (instData) => {
    setRegUser(instData);
    setLoader(true);
    saveParentRegister({ studentRegisterData: instData }).then((success) => {
      if (success) {
        swal
          .fire({
            icon: "success",
            title: "Registered Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#1f89f4",
            allowOutsideClick: false,
            confirmButtonText: "Login",
          })
          .then((okLogin) => {
            if (okLogin.isConfirmed) {
              navigate("/login");
            }
          });
        setLoader(false);
      } else {
        setSnakMsg(myConstClass.saveFailMsg);
        setSuccess("error");
        setLoader(false);
      }
    });
  };
  const studentData = (studentRegisterData) => {
    setRegUser(studentRegisterData);
    setLoader(true);
    setEditRow([]);
    setLoader(false);
  };

  const generateData = (contactNo) => {
    setLoader(true);
    getGenerateOTP({ contactNo: contactNo }).then((succsss) => {
      setOpenSnak(true);
      if (succsss) {
        setLoader(false);
        setSnakMsg(myConstClass.otpGenerateMsg);
        setSuccess("success");
        setShowOtpField(true);
      } else {
        setLoader(false);

        setSnakMsg(myConstClass.otpNotGenerateMsg);
        setSuccess("error");
      }
    });
    setEditRow([]);
    setLoader(false);
  };

  const validateData = (enterOtp) => {
    setEnterOtp(enterOtp);
    setLoader(true);
    getValidateOtp({ otp: enterOtp }).then((success) => {
      setOpenSnak(true);
      if (success) {
        setBasicDetailFlag(true);
        setSnakMsg(myConstClass.otpValidMsg);
        setSuccess("success");
        setLoader(false);
      } else {
        setSnakMsg(myConstClass.otpInvalidMsg);
        setSuccess("error");
        setLoader(false);
      }
    });
    setEditRow([]);
    setLoader(false);
  };
  const stopEdit = () => {
    navigate("/login");
    setEditRow([]);
  };

  return (
    <>
      {!studentRegData.isServerFail && (
        <DirectionSnackbar
          open={openSnak}
          severity={openSuccess}
          msg={snakMsg}
          closeSnak={setOpenSnak}
        />
      )}
      {studentRegData.isServerFail && (
        <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete} />
      )}

      {""}
      <ParentRegisterForm
        OnSave={studentRegisterData}
        saveNext={studentData}
        saveMobile={generateData}
        submitOtp={validateData}
        basicDetailFlag={basicDetailFlag}
        showOtpField={showOtpField}
        isEdit={editRow}
        onCancel={stopEdit}
      />
      {/* {login && open && <ProgMView openparam={open} closeView={setOpen} />} */}
      {loader && <Loading1 size={50} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  studentRegData: state.alumniRegister,
  common: state.common,
});

const mapDispatchToProps = {
  saveParentRegister,
  getGenerateOTP,
  getValidateOtp,
  resetError,
  resetDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentRegister);
