import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { teachingPlanPrincipleJson } from "../../../DynamicFormsJson/TeachingPlanPrincipleJson";
import { getBatchListBySemesterDivision } from "../../Batches/BatchList/batchlist.slice";
import { getDistinctDivision } from "../../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getClass,
  getYearDetailByDepartmentIds,
} from "../../Masters/OnlineModule/Class/class.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import { getSubjectBySemesterDetailDivBatch } from "../../Workload/AdminSideViewAndGenerateWorkout/WorkloadAllocation.slice";
import { getRecordsByFilters, resetValues } from "../teachingPlan.slice";
import { getEmpProfile } from "../../EmployeeDetails/employee.slice";
import PrincipalActionView from "./PrincipalActionView";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
    canSearch: true,
  },
  {
    // name: "name",
     name: "firstName",
    title: "Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "10%",
    canSearch: true,
    keyName:"firstName,lastName,middleName",
    isFormat:true,
    type:1
    
  },
  {
    name: "date",
    title: "Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
    canSearch: true,
  },
  {
    name: "topicTaught",
    title: "Topic Taught",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
    canSearch: true,
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];
class TeachingPlanPrincipleView extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      open: false,
      openView: false,
      timeTableHeaderData: {},
      tableData: {},
      showTableView: true,
      fieldData: {},
      role: "",
      dynamicMasterData: {
        yearDetailMaster: this.props.yearDetail?.class,
        semesterDetailMaster: this.props.semisterList?.semisterDetailsList,
        divisionMaster: this.props.divisionList?.distinctDivision,
        subjectMaster: this.props.subjectList?.getSubjectist,
        batchMaster: this.props.batchList?.batchListSemesterDiv,
      },
    };
  }

  componentDidMount() {
    const { getAlert, resetReducList, employeeList } = this.props;
    resetReducList();
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const ay = localStorage.getItem("acadamicYearId");

    const role = employeeList.employee.role;
    console.log(role);
    this.setState({
      role: role,
    });
    this.props.getEmpProfile().then((data) => {
      console.log(data.data.data);
      if (!data.success) {
        getAlert({ message: "Something went wrong" });
      } else {
        const role = data.data.data.role.includes("principal");
        if (role) {
          this.props.getClass().then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
        } else {
          this.props.getYearDetailByDepartmentIds().then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
        }
      }
    });

    if (yearDetailId && ay) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (yearDetailId) {
      this.props
        .getDistinctDivision({
          yearDetail: yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId && divisionId) {
      this.props
        .getBatchListBySemesterDivision({
          semisterID: semisterDetailId,
          divisionId: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.yearDetail !== nextProps.yearDetail) {
      if (this.props.yearDetail?.class !== nextProps.yearDetail?.class) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.yearDetail?.class,
          },
        });
      }
    }
    if (this.props.semisterList !== nextProps.semisterList) {
      if (
        this.props.semisterList?.semisterDetailsList !==
        nextProps.semisterList?.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster: nextProps.semisterList?.semisterDetailsList,
          },
        });
      }
    }
    if (this.props.divisionList !== nextProps.divisionList) {
      if (
        this.props.divisionList?.distinctDivision !==
        nextProps.divisionList?.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster: nextProps.divisionList?.distinctDivision,
          },
        });
      }
    }

    if (this.props.subjectList !== nextProps.subjectList) {
      if (
        this.props.subjectList?.getSubjectist !==
        nextProps.subjectList?.getSubjectist
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.subjectList?.getSubjectist,
          },
        });
      }
    }

    if (this.props.batchList !== nextProps.batchList) {
      if (
        this.props.batchList?.batchListSemesterDiv !==
        nextProps.batchList?.batchListSemesterDiv
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchMaster: nextProps.batchList?.batchListSemesterDiv,
          },
        });
      }
    }
  }

  onSearchData = (dataToSearch) => {
    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", dataToSearch.division);
    localStorage.setItem("divisionIds", "");
    this.setState({
      showLoader: true,
    });
    this.setState({
      yearDetail: dataToSearch.yearDetail,
      semister: dataToSearch.semister,
      division: dataToSearch.division,
      batch: dataToSearch.batch,
      subject: dataToSearch.subject,
      showLoader: true,
    });
    const { getAlert } = this.props;
    this.props
      .getRecordsByFilters({
        semesterDetailId: dataToSearch.semister,
        subjectDetailId: dataToSearch.subject,
        batchId: dataToSearch.batch,
        divisionId: dataToSearch.division,
        fromDate: "",
        toDate: "",
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({
          yearDetail: data.yearDetail,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatchListBySemesterDivision({
          semisterID: data.semister,
          divisionId: data.division,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.division && data.semister && data.batch) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSubjectBySemesterDetailDivBatch({
          semesterDetailId: data.semister,
          divisionId: data.division,
          batchId: data.batch,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  onClickView = (rowData) => {
    console.log(rowData);
    this.setState({
      openView: true,
      tableData: rowData,
      showTableView: false,
    });
  };

  onCancelView = () => {
    // this.props.resetValues();
    this.setState({
      showTableView: true,
      openView: false,
    });
  };

  render() {
    const {
      dynamicMasterData,
      showLoader,
      fieldData,
      showTableView,
      openView,
    } = this.state;

    return (
      <>
        {showTableView && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={teachingPlanPrincipleJson.screenTitle}
            fieldMeta={teachingPlanPrincipleJson.fieldMeta}
            buttonCenter={teachingPlanPrincipleJson.buttonCenter}
            showPdfDownload={teachingPlanPrincipleJson.showPdfDownload}
            showExcelDownload={teachingPlanPrincipleJson.showExcelDownload}
            pdfFileName={teachingPlanPrincipleJson.pdfFileName}
            excelFileName={teachingPlanPrincipleJson.excelFileName}
            tableColumnsToFilter={
              teachingPlanPrincipleJson.tableColumnsToFilter
            }
            showAddButton={teachingPlanPrincipleJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={columns}
            fieldData={fieldData}
            tableData={this.props.teachingPlan?.teacherRecords}
            getListById={this.getListById}
            showPegination={true}
            showHeadEdit={false}
            showHeadDelete={false}
            showLoader={showLoader}
            pdfDetailsId={this.pdfDetails}
            rowAppliedList={this.rowAppliedList}
            showView={true}
            rowViewData={this.onClickView}
            rowDelete={this.rowDelete}
            showAppliedList={false}
            rowDetails={this.rowDetails}
            // rowEdit={this.rowEdit}
            onDelete={this.onDelete}
            showDetails={false}
            onSearchData={this.onSearchData}
            baseIdColumn={teachingPlanPrincipleJson.baseIdColumn}
            apiBaseURL={teachingPlanPrincipleJson.apiBaseURL}
          />
        )}
        {openView && (
          <PrincipalActionView
            rows={
              Object.keys(this.props.teachingPlan?.syllabus).length !== 0
                ? this.props.teachingPlan?.syllabus.details
                : []
            }
            tableData={this.state.tableData}
            onCancel={this.onCancelView}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearDetails: state.internshipDeclaration,
  semisterList: state.rollNo,
  employeeWorkList: state.employeeWorkload,
  teachingPlan: state.teachingPlan,
  yearDetail: state.class,
  subjectList: state.workloadAllocate,
  batchList: state.batch,
  employeeList: state.employeeList,
  divisionList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  getSubjectBySemesterDetailDivBatch,
  getSemesterDetailsByYearAndAcademic,
  getClass,
  getBatchListBySemesterDivision,
  getRecordsByFilters,
  getAlert,
  resetReducList,
  getDistinctDivision,
  resetValues,
  getEmpProfile,
  getYearDetailByDepartmentIds,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeachingPlanPrincipleView);
