import { withStyles } from "@mui/styles";
import { React } from "react";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, title, classes }) => {
  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          {title.map((titleData, index) => {
            if (index % 2 === 0) {
              return (
                <tr >
                  {titleData.map((previewRow) => {
                    
                    return (
                      <>
                        <td
                          style={{ color: "#000000" }}
                          className={classes.MuiTableHeadRoot}
                        >
                          &nbsp; {previewRow.title}
                        </td>
                        <td
                          style={{ color: "#5a5a5a" }}
                          className={classes.MuiTableHeadRoot}
                          colSpan={previewRow.colSpan}
                        >
                          &nbsp; {data[previewRow.name]}
                        </td>
                      </>
                    );
                  })}
                </tr>
              );
            }
           else{
              return (
                <tr style={{ background: "#f5f5f5" }}>
                  {titleData.map((previewRow) => {
                    return (
                      <>
                        <td
                          style={{ color: "#000000" }}
                          className={classes.MuiTableHeadRoot}
                        >
                          &nbsp; {previewRow.title}
                        </td>
                        <td
                          style={{ color: "#5a5a5a" }}
                          className={classes.MuiTableHeadRoot}
                        >
                          &nbsp; {data[previewRow.name]}
                        </td>
                      </>
                    );
                  })}
                </tr>
              );
            }
          })}
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(BasicTable);
