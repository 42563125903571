import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import SelectedStudentList from "./SelectedStudentList";
import CarryForwardStudentList from "./CarryForwardStudentList";
const BootstrapDialog = styled( Dialog )( ( { theme } ) => ( {
  "& .MuiDialogContent-root": {
    padding: theme.spacing( 2 ),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing( 1 ),
  },
} ) );

const BootstrapDialogTitle = ( props ) =>
{
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: ( theme ) => theme.palette.grey[ 500 ],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CarryForwardPopup = ( {
  handleCloses,
  open,
  rows,
  divisionList,
  OnSave,
  selection,
} ) =>
{
  const handleClose = () =>
  {
    handleCloses();
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Roll Number Carry Forward
          </BootstrapDialogTitle>
          <DialogContent dividers>


            <CarryForwardStudentList

              rows={rows}
              divisionList={divisionList}
              OnSave={OnSave}
              selection={selection}

            />



          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = ( state ) => ( {} );

const mapDispatchToProps = {};

export default connect( mapStateToProps, mapDispatchToProps )( CarryForwardPopup );
