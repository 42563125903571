import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { WorkExpJson } from "../../../DynamicFormsJson/WorkExp";
import { WorkExpColumns } from "../../../tableColumns/table-columns";
import { getWorkExperienceByAluminiId } from "../WorkExp/workExperiance.slice";
class WorkExp extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getWorkExperienceByAluminiId({ id: "" }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  render() {
    const { workExperienceList, onCancel, onSubmit } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={WorkExpJson.screenTitle}
            fieldMeta={WorkExpJson.fieldMeta}
            showPdfDownload={WorkExpJson.showPdfDownload}
            showExcelDownload={WorkExpJson.showExcelDownload}
            pdfFileName={WorkExpJson.pdfFileName}
            excelFileName={WorkExpJson.excelFileName}
            tableColumnsToFilter={WorkExpJson.tableColumnsToFilter}
            showInPaper={false}
            showSaveNextBtn={WorkExpJson.showSaveNextBtn}
            showSearchBox={false}
            showAddButton={WorkExpJson.showAddButton}
            tableColumns={WorkExpColumns}
            tableData={workExperienceList.workExperience}
            getTableData={() => {
              this.props.getWorkExperienceByAluminiId({ id: "" });
            }}
            onCancel={onCancel}
            onSubmit={onSubmit}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={workExperienceList.isFetch}
            baseIdColumn={WorkExpJson.baseIdColumn}
            apiBaseURL={WorkExpJson.apiBaseURL}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  workExperienceList: state.workExperience,
});
const mapDispatchToProps = {
  getAlert,
  getWorkExperienceByAluminiId,
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkExp);
