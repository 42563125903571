import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import * as React from "react";

export default function FileSystemNavigator() {
  const Rows = [
    {
      title: "Applications",
      nodeId: "1",
      subList: [
        {
          title: "Calendar",
          nodeId: "2",
          subSubList: [],
        },
      ],
    },
    {
      title: "Documents",
      nodeId: "5",
      subList: [
        {
          title: "OSS",
          nodeId: "10",
          subSubList: [],
        },
        {
          title: "MUI",
          nodeId: "6",
          subSubList: [
            {
              title: "index.js",
              nodeId: "8",
            },
          ],
        },
      ],
    },
  ];
  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {Rows.map((rowData) => {
        return (
          <>
            <TreeItem nodeId={rowData.nodeId} label={rowData.title}>
              {rowData.subList.map((subListData) => {
                return (
                  <>
                    <TreeItem
                      nodeId={subListData.nodeId}
                      label={subListData.title}
                    >
                      {subListData.subSubList.map((subSubData) => {
                        return (
                          <>
                            <TreeItem
                              nodeId={subSubData.nodeId}
                              label={subSubData.title}
                            />
                            {/* <TextField
                              color="primary"
                              name="remark"
                              size="small"
                              value={""}
                              //   onChange={changeHandler}
                              placeholder="shraddha sub sub Section"
                              fullWidth
                            />
                               <br/> */}
                          </>
                        );
                      })}
                    </TreeItem>
                    {/* <TextField
                      color="primary"
                      name="remark"
                      size="small"
                      value={""}
                      //   onChange={changeHandler}
                      placeholder="shraddha sub Section"
                      fullWidth
                    />
                    <br /> */}
                  </>
                );
              })}
            </TreeItem>
            {/* <TextField
              color="primary"
              name="remark"
              size="small"
              value={""}
              //   onChange={changeHandler}
              placeholder="Enter new chapter"
              fullWidth
            />
            <br /> */}
          </>
        );
      })}
    </TreeView>
  );
}
