export const schemeHeadNameMsg = "*Scheme head name required";
export const schemeNameMsg = "*Scheme name required";
export const shortNameMsg = "*Short name required";
export const accessRoleMsg = "*Access role name required";
export const firstNameMsg = "*First name required";
export const middleNameMsg = "*Middle name required";
export const dobGTCurrentDateMsg = "*Alumni age must be greater than 18";
export const lastNameMsg = "*Last name required";
export const genderEmptyMsg = "*Gender required";
export const qualiMsg = "Please select atleast one qualification";
export const DocMsg = "Please select atleast one document";
export const dobEmptyMsg = "*Date of birth required";
export const contactNoMsg = "*Contact number required";
export const contactNumberMsg = "*Contact number must be number";
export const SelectInstType = "*Please select atleast one type";
export const emailIdMsg = "*Email id required";
export const enterPasswordMsg = "*Password required";
export const confirmPasswordMsg = "*Confirm password required";
export const serverMsg = "Something went wrong";
export const confirmPasswordIncorrectMsg = "*Confirm password does not match";
export const captchaMsg = "*Captcha required";
export const otpEmptyMsg = "*OTP Required";
export const contactNoLengthMsg = "*Contact number should have 10 digits";
export const namePasswordMsg = "*Password should not contain your name";
export const specialPasswordMsg =
  "*Password should be more than 8 character and contain 1 Uppercase, 1 Lowercase, 1 Special Character and 1 Numeric Digit";
export const invalidEmailIdMsg = "*Email id is invalid";
export const userIdMsg = "*User id required";
export const activeStatus = "*Status updated";
export const deleteMsg = "*Data deleted successfully";
export const deleteFailMsg = "*Failed to delete";
export const saveFailMsg = "*Failed to save";
export const saveMsg = " *Data saved successfully";
export const updateMsg = " *Data updated successfully";
export const emailValMsg = " *Email Validate successfully";
export const emailInvalMsg = " *Email Id is already been taken.. ";
export const otpGenerateMsg = " *OTP Generated Successfully";
export const otpSubmitMsg = " *OTP Required";
export const otpNotGenerateMsg = " *Fail To Generate OTP";
export const otpValidMsg = " *OTP Validate Successfully";
export const otpInvalidMsg = " *OTP Invalid";
export const changeActive = "*Do you want to change status?";
export const instNameMsg = "*Institute name required";
export const instShortNameUniMsg = "*Institute short name must be unique";
export const instNameUniMsg = "*Institute name must Be unique";
export const instShortNameMsg = "*Institute short name required";
export const instAffiMsg = "*Institute affiliation required";
export const EPFNoMsg = "*EPF number required";
export const taxNoMsg = "*TAX number required";
export const tanNoMsg = "*TAN number required";
export const PANNoMsg = "*PAN number required";
export const uniCodeMsg = "*university code required";
export const AICTE = "AICTE code required";
export const DTE = "*DTE code required";
export const instAddr = "*Institute address required";
export const companyAddressMsg = "*Company address required";
export const companyNameMsg = "*Company name required";
export const contactNameMsg = "*Company person name required";
export const contactTextMsg = "*Only text allowed";
export const trustNameMsg = "*Trust name required";
export const remarkMsg = "*Remark required";
export const mandatoryOptionalMsg = "Please select mandatory or optional";
export const classDetailNameMsg = "*Class detail name required ";
export const patternNameMsg = "*Pattern name required";
export const semisterNameMsg = "*Semester name required";
export const subjectCodeNameMsg = "*Course code name required";
export const subjectNameMsg = "*Course name required";
export const subjectShortNameMsg = "*Subject short name required";
export const classNameMsg = "*Year name is required";
export const labelTextMsg = "*Label name required";
export const minorityReligionMsg = "*Minority religion required";
export const progNameMsg = "*Program name required";
export const sortOrderNoMsg = "*Sort order no required";
export const sortOrderMsg = "*Sort order required";
export const sortOrderNumberMsg = "*Sort order no must be a number";
export const feBrNameMsg = "*First year program title required";
export const shiftNameMsg = "*Shift name required";
export const gapInYearsMsg = "*Gap in years required";
export const branchShortNameMsg = "*Short name required";
export const branchNameMsg = "*Program title required";
export const docNameMsg = "*Document name required";
export const financeYearNameMsg = "*Finance year name required";
export const fromDateMsg = "*From date required";
export const attandaceDateMsg = "*Attandance date required";
export const toDateMsg = "*To date required";
export const endDateMsg = "*End date required";
export const progDurationMsg = "*Program duration required";
export const uniNameMsg = "*University name required";
export const classUniMsg = "*Class name must be unique";
export const academicYearNameMsg = "*Academic year name required";
export const profTaxIdMsg = "*prof tax required";
export const panNoMsg = "*PAN number required";
export const trustAddMsg = "*Trust add required";
export const qualificationMsg = "*Qualification Name required";
export const trustShortNameMsg = "*Trust short name required";
export const scholarshipTypeMsg = "*Scholarship type required";
export const clientNameMsg = "*Client name required";
export const receiptNameMsg = "*Receipt name required";
export const feesTypeNameMsg = "*Fees type name required";
export const ClassDetEmptyMsg = "*Class detail name required";
export const divEmptyMsg = "*Division name required";
export const divInvalidMsg = "*Valid division name required";
export const educationNameMsg = "*Education name required";
export const matrixNameMsg = "*Matrix name required";
export const classIntakeNameMsg = "*Class intake name required";
export const sortOrdNoMsg = "*Sort order number required";
export const ClassIntDetEmptyMsg = "*Class detail name required";
export const acYearEmptyMsg = "*Academic year required";
export const subjectNameEmptyMsg = "*Course name required";
export const subjectHeadNameMsg = "*Subject head name required";
export const valueEmptyMsg = "*value";
export const valueMsg = "*value";
export const religionNameMsg = "*Religion name required";
export const catNameMsg = "*Category name is required";
export const typeEmptyMsg = "*Category type required";
export const sanctionIntakeVaildMsg = "*Sanction intake should be equal to";
export const sanctionIntakeMsg = "*Sanction intake required";
export const sanctionIntakeNoMsg = "*Sanction intake must be a number";
export const valueNumberMsg = "*Value must be a number";
export const openingAcYearMsg = "*Opening academic year required";
export const closingAcYearMsg = "*Closing academic year required";
export const headingNameMsg = "*Heading name";
export const contentValidMsg = "*Please content";
export const logoMsg = "*Please select logo";
export const motherNameMsg = "*Mother's name required";
export const profilePicMsg = "*Profile pic required";
export const genderMsg = "*Gender required";
export const dobMsg = "*Date of birth required";
export const birthplaceMsg = "*Birth place required";
export const bloodGroupMsg = "*Blood group required";
export const religionMsg = "*Religion required ";
export const studMobileMsg = "*Student mobile no. required";
export const mobileMsg = "*Mobile number required";
export const altMobileMsg = "*Alternative mobile no. required";
export const studMobileLengthMsg = "*mobile number length should be 10";
export const studEmailMsg = "*Student email required";
export const nationalityMsg = "*Nationality required";
export const admCategoryMsg = "*Admission category required";
export const admissionDateMsg = "*Admission date required";
export const studCategoryMsg = "*Student category required";
export const subCastMsg = "*Sub Cast required";
export const validityCdNoMsg = " *Validity certificate number required";
export const validityCdDateMsg = "*Validity certificate date required";
export const castCertNoMsg = "*Cast certificate number required";
export const castCertDateMsg = "*Cast certificate date required";
export const bankNameMsg = "*Bank name required";
export const bankBranchMsg = "*Bank branch required";
export const ifscCodeMsg = "*IFSC code required";
export const micrCodeMsg = "*MICR code required";
export const bankAccNoMsg = "*Bank account number required";
export const seatNoMsg = "*Seat number required";
export const eligibilityNoMsg = "*Eligibility number required";
export const prnNoMsg = "*PRN required";
export const admissionTypeMsg = " *Admission type required";
export const caproundMsg = "*Capround required";
export const allotmentNoMsg = "*Allotment number required";
export const meritNoMsg = "*Merit number required";
export const prevInstituteMsg = " *Previous institute required";
export const prevInstAddressMsg = "*Previous institute address required";
export const prevInstAreaMsg = "*Previous institute area required";
export const prevInstMediumMsg = "*Previous institute medioum required";
export const passoutMsg = "*Passout year required";
export const classDetailNameValidMsg = "*Class detail name required";
export const fatherOccupationMsg = "*Father's occupation required";
export const fatherNameMsg = "*Father's name required";
export const parAddressMsg = "*Annual income required";
export const annualIncomeMsg = "*Annual income required";
export const parentEmailMsg = "*Parent's email id required";
export const parentMobileMsg = "*Parent's contact no required";
export const perAddressMsg = "*Permanent address required";
export const vilageMsg = "*Village required";
export const talukaMsg = "*Taluka required";
export const districtMsg = "*District required";
export const stateMsg = "*State required";
export const pinMsg = "*Pincode required";
export const localAddMsg = "*Local address required";
export const admissionStatusMsg = "*Admission status name required";
export const feesTypeMsg = "*Fees type name";
export const feesTitleNameMsg = "*Fees title name";
export const formFeesNameMsg = "*form fees name";
export const programTypeMsg = "*Program type name required";
export const programTitleMsg = "*Program title name required";

export const feesNameMsg = "*fees name";
export const feesTitleMsg = "*fees title name";
export const receiptTypeMsg = "*Receipt type name required";
export const userNameMsg = "*User name required";
export const semEndDateMsg = "*Select sem end date";
export const semStartDate = "*Select sem start date";
export const divisionNameMsg = "*Division required";
export const markingSystemMsg = "*Marking system required";

export const subjectMsg = "*Subject required";
export const maxSubMsg = "*Maximum subject required";
export const minSubInvalidMsg = "*Please valid minimum subject";
export const minSubMsg = "*Minimum subject required";
export const minMaxSubMsg =
  "*Minimum subject must be less than maximum subject";
export const showNameMsg = "*Show name required";
export const subjectTypeNameMsg = "*Subject type name required";
export const minSubNoMsg = "*Minimum subject must be a number";
export const maxSubNoMsg = "*Maximum subject must be a number";
export const onlyContactNoMsg = "*Contact number must only be number";
export const cityMsg = "*City/Village required";
export const titleMsg = "*Title required";
export const titleErr = "*Title required";
export const shortSummMsg = "*Short summary required";
export const detailsOfDescriptionMsg = "Form description required";
export const typeMsg = "*Type required";
export const labelNameMsg = "*Label Name required";
export const feesMsg = "*Fees required";
export const feesValidMsg = "*Fees must be in number";
export const typeOfProgramMsg = "*Program type required";
export const programMsg = "*Program required";
export const displaytoMsg = "*Date must be greater than from date";
export const fromDateLessThanToDate = "*Date must be less than to date";
export const displayFromMsg = "*Display from date required";
export const launchToMsg = "*Date must be greater than from date";
export const launchFromMsg = "*Launch From Date required";
export const instructionsMsg = "*Instructions are required";
export const docUniMsg = "*Document name must be unique";
export const disabilityNameMsg = "*Disability name required";
export const defenceNameMsg = "*Defence name required ";
export const labelMsg = "*Label required";
export const roundFromMsg = "*Round from date must not be equal to to date";
export const roundToMsg = "*Round to date must not be equal to from date";
export const seatAllotmentFromMsg =
  "*Seat accept from date must be less than to date";
export const seatAcceptFromMsg =
  "*Seat accept from date must not be equal to to date";
export const seatAcceptToMsg =
  "*Seat accept to date must not be equal to From Date";
export const progTypeUniqueMsg = "*Program type must be unique";
export const catUniMsg = "*Category must be unique";
export const religionUniMsg = "*Religion name must be unique";
export const academicYearUniMsg = "*Acadamic year must be unique";
export const branchShortNameUniMsg = "*Branch short name must be unique";
export const branchUniMsg = "*Branch name must be unique";
export const CourseMsgg = "*Program title required";
export const percentageMsg = "*Percentage required";
export const elegibilityCriteriaMsg = "*Elegibility Criteria required";
export const generalPercentageMsg = "*General percentage required";
export const otherPercentageMsg = "*Other percentage required";
export const malePercentageMsg = "*Male percentage required";
export const femalePercentageMsg = "*Female percentage required";
export const marksobtMsg = "*Mark obtained required";
export const scheduleMsg = "*Schedule required";
export const descriptionMsg = "*Description required";
export const ordNoMsg = "*Order number required";
export const categoryPercentage =
  "*sum of all percentage should be equal to 100";
export const casteMsg = "*Caste required";
export const subcasteMsg = "*Subcaste required";
export const candidateNameMsg = "*Candidate name required";
export const fatherHusbandNameMsg = "*Father/Husband name required";
export const domicileOfMsg = "*Domicile name required";
export const prefrenceMsg = "*Preference required";
export const mothertoungeMsg = "*Mother tounge required";
export const aadhaarMsg = "*Aadhar no required";
export const martialMsg = "*Marital status required";
export const isStudentNriForeignNationalMsg = "*This field required";
export const InvalidadharMsg = "*Aadhar no invalid";
export const instTypeMsg = "*Institute type required";
export const autonomousStatusMsg = "*Autonomous status required";
export const minorityMsg = "*Minority status required";
export const reservStuctureMsg = "*Reservation stucture required";
export const omsMsg = "*Other than maharashtra field required";
export const isScholarShipMsg = "*Scholarship field required";
export const invalidEmail = "*Invalid email id or date of birth";
export const invalidUserName = "*Invalid user name or date of birth";
export const academicYearMessage = "*Academic year is required";
export const admissionForYearMessage = "*Enter admission for year";
export const typeOfAdmissionMessage = "*Enter type of admisson";
export const applicationIdMessage = "   *Enter application id";
export const yearDetailMsg = "*Year details required";
export const semisterDetailMsg = "*Semester details required";
export const nameMsg = "*Course name required";
export const SubjectShortNameMsg = "*Course short name required";
export const patternMsg = "*Pattern required";
export const codeMsg = "*Code required";
export const semisterMsg = "*Semester required";
export const subjectTypeMsg = "*Subject type required";
export const nameMsgRequired = "*Name is required";
export const totalFeesRequiredMsg = "*Total fees required";
export const minFeesRequiredMsg = "*Partially / Minimum payment required";
export const subjectDetailsMsg = "*Enter the Values in the table";
export const totalMarksMsg = "*Total marks required";
export const roundDetailsMsg = "*Round details is required";
export const allotmentStatusListMsg = "*Choose atleast 1 allotment Status";
export const departmentListMsg = "*Choose atleast 1 department";
export const studentNameMsg = "*Student Name required";
export const designationMsg = "*Designation required";
export const roleMsg = "*Role is required";
export const employeeRoleMsg = "*Aceess Role is required";
export const totalExperiancesMsg = "*Total experiance required";
export const departmentMsg = "*Department required";
export const employeeTypeMsg = "*Employee type required";
export const employeeCodeMsg = "*Employee code already present";
export const internTypeMsg = "*Intern type required";
export const placementTypeMsg = "*Placement type required";
export const detailsMsg = "*Details required";
export const venueMsg = "*Address required";
export const timeMsg = "*Time required";
export const amountMsg = "*Amount required";
export const applicationLastDateMsg = "*Application Last Date required";
export const eventNameMsg = "*Event name required";
export const memberNameMsg = "*Member name reqired";
export const memberDesignationMsg = "*Member designation required";
export const sequenceNoMsg = "*Sequence No required";
export const joiningDateMsg = "*Joining Date required";
export const statusMsg = "*Status required";
export const batchMsg = "*Batch name required";
export const subjectSchemeMSg = "*Subject scheme required";
export const teacherSelectionMsg =
  "*Number of teachers must be equal to or less than the number of selected students on the list";
export const teacherNameMsg = "*Teacher name required";
export const floorMsg = "*Floor required";
export const roomMsg = "*Room required";
export const isCompletedMsg = "Are you sure,you want to make actions?";
export const applicationName = "*Application name required";
export const CreditMsg = "*Credit required";
export const noOfLecturesMsg = "*No Of Lectures required";
export const packageMsg = "*Package required";
export const feedbackMsg1 = "*Feddback type required";
export const feedbackMsg = "*Feedback required";
export const questionMsg = "*Question required";
export const optionType = "*Select option type";
export const option = "*Option required";
export const internshipDeclareTypeMsg = "*Internship declare type required";
export const employeemsg = "*Employee required";
export const employeeCodeMessage = "*Employee Code required";
export const obtainedMarksEr =
  "*Obtained marks should be less than Total Marks";
export const internalMarksMsg = "*Internal marks required";
export const outOfInternalMsg = "*Out of Internal marks required";
export const externalMarksMsg = "*External marks required";
export const outOfExternalMsg = "*Out of External marks required";
export const dateOfRetirementMsg = "*Date of retirement required";
export const dateOfAppointmentMsg = "*Date of Appointment required";
export const dateOfJoiningMsg = "*Date of Joining required";
export const approvalDateMsg = "*Approval date required";
export const approvalTypeMsg = "*Approval type required";
export const approvalNoMsg = "*Approval no required";
export const timeSlotMsg = "*Timeslot required";
export const venueMessage = "*Venue is required";
export const countryMsg = "*Country required";
export const boardMsg = "*Board required";
export const debitAmountMsg = "*Amount required";
export const debitAmountNotValidMsg =
  "*Amount should be less or equal to pending amount";
export const debitAmountZeroMsg = "*Amount should be greater than 0";
export const transactionDateMsg = "*Transaction date required";
export const feesStructureHeaderMsg = "*Fees structure required";
export const feesMinimumPayMsg =
  "*Amount should be greater than minimum paid amount";
export const feesMaximumPayMsg = "*Amount should be less than pending amount";
export const feesEqualPayMsg = "*Amount should be equal to pending amount";
export const numberMsg = "*Number required";
export const classCoordinatorMsg = "*Class Co-ordinator is required";
export const studMsg = "*Student required";
export const chapterMsg = "*Chapter required";
export const sectionMsg = "*Section required";
export const subSectionMsg = "*Sub-Section required";
export const commentMsg = "*Comment required";
export const replyMsg = "*Reply required";
export const topicMsg = "*Topic required";
export const urlMsg = "*URL required";
export const committeeMsg = "*Committee required";
export const dateMsg = "*Date required";
export const openingRemarkMsg = "*Opening remark required";
export const closingRemarkMsg = "*Closing remark required";
export const periodMsg = "*period (In years) required";
export const completionYearMsg = "*Completion year required";
export const programDegreeMsg = "*Program/Degree required";
export const endYear = "*End year required";
export const fileName = "*Select file";
export const docTypeMsg = "*Document type required";
export const yearOfSyllabusApprovalMessage =
  "*Year of syllabus approval required";
export const courseTypeMessage = "*Course type required";
export const courseCodeMessage = "*Course code required";
export const toTimeMsg = "To Time required";
export const fromTimeMsg = "From Time required";
export const noOfParticipantsMsg = "No of Participants required";
export const activityNameMsg = "Activity name required";
export const organizedByMsg = "*Organized by required;";
export const activityTypeMsg = "*Activity Type required";
export const levelTypeMsg = "*Level required";
export const mediaUsedMsg = "*Media Type required";
export const libraryNoMsg = "*Library number required";
export const libraryUniqueMsg = "*Library number was already been taken";
export const audienceMsg = "*Audience required";
export const targetMsg = "*Target required";
export const otpSentSuccessfull =
  "*OTP has been sent to your Registered Email id and Mobile No.";
