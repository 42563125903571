import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../../../components/Comman/IconButton";
import { LabelCompo } from "../../../../components/Comman/Label";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { showNotification } from "../../../../Pages/Landing/Landing.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminNewsRoomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.rows !== nextProps.rows) {
      this.setState({
        rowList: nextProps.rows,
      });
    }
  }

  onClickRead = (id) => {
    const rows = this.state.rowList.map((rowData) => {
      if (rowData.id === id) {
        rowData = {
          ...rowData,
          open: !rowData.open,
        };
        return rowData;
      }
      return rowData;
    });
    this.setState({
      rowList: rows,
    });
  };

  onClickEditHandler = (row) => {
    this.props.onClickEdit(row);
  };
  onClickDeleteHandler = (row) => {
    this.props.onClickDelete(row);
  };
  onClickViewPreviewHandler = (row) => {
    this.props.onClickViewPreview(row);
  };

  render() {
    return (
      <>
        <TableContainer component={Paper} variant="scrollable">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="50" align="center">
                  Sr. No.
                </TableCell>
                <TableCell width="70" align="center">
                  Title
                </TableCell>
                <TableCell width="200" align="center">
                  Description
                </TableCell>
                <TableCell width="70" align="center">
                  Date
                </TableCell>
                <TableCell width="70" align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rowList.map((row, index) => (
                <>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell>
                      {!row.open &&
                      row.description.length >
                        row.description.slice(0, 70).length
                        ? row.description.slice(0, 70) + " ... "
                        : row.description.slice(0, 70)}{" "}
                      {!row.open &&
                        row.description !== row.description.slice(0, 70) && (
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            label={" Read more"}
                            onClick={() => this.onClickRead(row.id)}
                          />
                        )}
                      {row.open && row.description}
                      {row.open &&
                        row.description.length >
                          row.description.slice(0, 70).length && (
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            label={"Read less"}
                            onClick={() => this.onClickRead(row.id)}
                          />
                        )}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.eventDate}
                    </TableCell>
                    <TableCell align="center">
                      <>
                        <MyComponentWithIconProps
                          statusImage={EditIcon}
                          color="primary"
                          fontSize="medium"
                          //   sx={{ marginTop: marginTop }}
                          title="Edit"
                          onClick={() => this.onClickEditHandler(row)}
                        />

                        <MyComponentWithIconProps
                          statusImage={DeleteIcon}
                          color="error"
                          fontSize="medium"
                          // sx={{ marginTop: marginTop }}
                          title="Delete"
                          onClick={() => this.onClickDeleteHandler(row)}
                        />
                        <MyComponentWithIconProps
                          statusImage={VisibilityIcon}
                          color="primary"
                          fontSize="medium"
                          title="View"
                          //   sx={{ marginTop: marginTop }}
                          onClick={() => this.onClickViewPreviewHandler(row)}
                        />
                      </>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminNewsRoomTable);
