import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { timeTableJson } from "../../../DynamicFormsJson/TimeTableView";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { FilterSave } from "../../../components/FilterSave";
import { getTimeSlotByProgramType } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";

import { apiDelete, apiPost } from "../../../utils/api_service";
import endpoints from "../../../config/endpoints";
import swal from "sweetalert";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import * as myConstClass from "../../../config/messageconstant";
import {
  getTimeTable,
  getTimeTableDetailByHeaderId,
} from "../GenerateTimeTable/generateTimeTable.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import GenerateTimeTableForm from "../GenerateTimeTable/index";
import ViewOfTimeTable from "./ViewOfTimeTable";
import { downloadF } from "../../PDF/PDFDownload";
class AdmissionStatusMaster extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr.no ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "fDTd",
      title: "From Date - To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "remark",
      title: "Remark",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "isActive",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      },
      showLoader: false,
      open: false,
      openView: false,
      timeTableHeaderData: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
  }
  componentDidMount() {
    const { resetReducList, getYearDetails } = this.props;
    resetReducList();
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const divisionId = localStorage.getItem("divisionId");
    if (divisionId && semisterDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getTimeTable({
          divisionId: divisionId,
          semisterId: semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId && ay) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (yearDetailId) {
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }
  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail && ay) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  onSearchData = (dataToSearch) => {
    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", dataToSearch.division);
    localStorage.setItem("divisionIds", "");
    FilterSave();
    this.setState({
      showLoader: true,
    });
    this.props
      .getTimeTable({
        divisionId: dataToSearch.division,
        semisterId: dataToSearch.semister,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };
  onAddButtonClick = () => {
    this.setState({
      open: true,
    });
    // window.location.replace("/genrate-time-table");
  };

  rowEdit = (params) => {
    console.log(params);

    this.props
      .getTimeTableDetailByHeaderId({ timeTableHeaderId: params.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            timeTableHeaderData: params,
          });
        }
      });
  };

  onClickEdit = (row) => {
    this.setState({
      open: true,
    });
    this.rowEdit(row);
  };
  onClickView = (row) => {
    this.setState({
      openView: true,
    });
    this.props
      .getTimeSlotByProgramType({ programTypeId: row.programType })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    this.rowEdit(row);
  };
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          showLoader: true,
        });
        apiDelete({
          url: endpoints.timeTableHeader + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getTimeTable({
                divisionId: rowData.division,
                semisterId: rowData.semisterDetail,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                  this.setState({
                    showLoader: false,
                  });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  changeStatus = (row) => {
    swal(myConstClass.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const tableHeader = {
          id: row.id,
          semisterDetail: {
            id: row.semisterDetail,
          },
          division: {
            id: row.division,
          },
          fromDate: row.fromDate,
          toDate: row.toDate,
          remark: row.remark,
          isActive: row.isActive === 1 ? 0 : 1,
        };
        console.log(tableHeader);
        this.setState({
          showLoader: true,
        });
        apiPost({
          url: endpoints.timeTableHeader,
          postBody: tableHeader,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getTimeTable({
                divisionId: row.division,
                semisterId: row.semisterDetail,
              })
              .then((response) => {
                if (!response) {
                  this.setState({
                    showLoader: false,
                  });
                  this.props.getAlert({ message: "Something went wrong" });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  handleClickOpen = () => {
    window.location.replace("/view-time-table");
  };
  pdfDetails = (row) => {
    downloadF({
      url:
        "/api/report/v1/get-time-table-detail-report/for-time-table-header?timeTableHeaderId=" +
        row.id +
        "&documentType=1",
      ext: "pdf",
      openNewTab: true,
    });
  };
  render() {
    const { timeTableList } = this.props;
    const { dynamicMasterData, showLoader, open, openView } = this.state;
    return (
      <>
        {!open && !openView && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={timeTableJson.screenTitle}
            searchAlign={timeTableJson.searchAlign}
            fieldMeta={timeTableJson.fieldMeta}
            buttonCenter={timeTableJson.buttonCenter}
            showPdfDownload={timeTableJson.showPdfDownload}
            showExcelDownload={timeTableJson.showExcelDownload}
            pdfFileName={timeTableJson.pdfFileName}
            excelFileName={timeTableJson.excelFileName}
            tableColumnsToFilter={timeTableJson.tableColumnsToFilter}
            showAddButton={timeTableJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={this.columns}
            tableData={timeTableList?.timeTableList}
            getListById={this.getListById}
            showPegination={false}
            showView={true}
            showLoader={showLoader}
            showHeadEdit={true}
            showHeadDelete={true}
            showHeadPdf={true}
            // showPegination={false}
            pdfDetailsId={this.pdfDetails}
            rowViewData={this.onClickView}
            rowDelete={this.rowDelete}
            rowEdit={this.onClickEdit}
            rowStatus={this.changeStatus}
            onDelete={this.onDelete}
            onSearchData={this.onSearchData}
            baseIdColumn={timeTableJson.baseIdColumn}
            apiBaseURL={timeTableJson.apiBaseURL}
          />
        )}
        {open && (
          <GenerateTimeTableForm
            onCancle={this.handleClickOpen}
            timeTableHeaderData={this.state.timeTableHeaderData}
          />
        )}
        {openView && (
          <ViewOfTimeTable
            onCancle={this.handleClickOpen}
            timeTableHeaderData={this.state.timeTableHeaderData}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getTimeSlotByProgramType,
  getDistinctDivision,
  getAcademicYear,
  getSemesterDetailsByYearAndAcademic,
  getTimeTable,
  getTimeTableDetailByHeaderId,
  resetReducList,
  downloadF,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmissionStatusMaster);
