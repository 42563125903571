import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as React from "react";
import { connect } from "react-redux";
import { ChipCompo, MyComponentWithIconProps } from "../IconButton";
import { CommonNameFormat } from "../Util/CommonNameFormat";
function Row({
  row,
  tableHead,
  rowStatus,
  rowDelete,
  rowSubjectDetails,
  rowEdit,
  showHeadEdit,
  showHeadDelete,
  showSubjectDetails,
  marginTop,
  tableSubHead,
  tableSubList,
}) {
  const [open, setOpen] = React.useState(false);

  const changeStatus = (e, row) => {
    e.stopPropagation();
    rowStatus(row);
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };

  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };

  const onClickSubjectDetails = (e, row) => {
    e.stopPropagation();
    rowSubjectDetails(row);
  };

  React.useEffect(() => {});
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {Object.keys(row).map(function (key, index) {
          const filteredList = tableHead.filter((row) => row.name === key);
          if ( filteredList.length !== 0 )
          {
            if (filteredList[0].isFormat!=undefined&&filteredList[0].isFormat) {
         
         const formatedNameData=CommonNameFormat(filteredList[0].type,filteredList[0].keyName,row)
        
         return (
          <TableCell>
           {formatedNameData}
          </TableCell>
        );
        }else
            if (filteredList[0].positionCenter && key !== "isActive")
              return (
                <TableCell>
                  {" "}
                  <center>{row[key]}</center>
                </TableCell>
              );
            else if (key === "isActive") {
              return (
                <TableCell>
                  {row[key] === 1 ? (
                    <center>
                      <ChipCompo
                        label="Active"
                        variant="outlined"
                        size="small"
                        color="success"
                        onClick={(e) => changeStatus(e, row)}
                      />
                    </center>
                  ) : (
                    <center>
                      <ChipCompo
                        label="Inactive"
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={(e) => changeStatus(e, row)}
                      />
                    </center>
                  )}
                </TableCell>
              );
            } else return <TableCell> {row[key]}</TableCell>;
          }
        })}

        <TableCell>
          {showHeadEdit && (
            <>
             &nbsp;&nbsp;
            <MyComponentWithIconProps
              statusImage={EditIcon}
              color="primary"
              fontSize="medium"
              title="Edit"
              onClick={(e) => onClickEdit(e, row.id)}
            />
            </>
          )}
         
          {showHeadDelete && (
            <>
             &nbsp;&nbsp;
            <MyComponentWithIconProps
              statusImage={DeleteIcon}
              color="error"
              fontSize="medium"
              title="Delete"
              onClick={(e) => onClickDelete(e, row.id)}
            />
            </>
          )}
          {showSubjectDetails && (
            <>
             &nbsp;&nbsp;
            <MyComponentWithIconProps
              statusImage={VisibilityIcon}
              color="primary"
              fontSize="medium"
              title="Subject Syllabus"
              sx={{ marginTop: marginTop }}
              onClick={(e) => onClickSubjectDetails(e, row)}
            />
            </>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {tableSubHead.map((tableSubHeadData) => {
                      if (!tableSubHeadData.positionCenter)
                        return <TableCell>{tableSubHeadData.title}</TableCell>;
                      else
                        return (
                          <TableCell>
                            <center>{tableSubHeadData.title}</center>
                          </TableCell>
                        );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row[tableSubList].map((tableSubData, index) => (
                    <TableRow key={tableSubData.id}>
                      <TableCell>
                        <center>{index + 1}</center>
                      </TableCell>

                      {Object.keys(tableSubData).map(function (key, index) {
                        const filteredSubList = tableSubHead.filter(
                          (row) => row.name === key
                        );

                        if (filteredSubList.length !== 0) {
                          if (filteredSubList[0].positionCenter)
                            return (
                              <TableCell>
                                <center>{tableSubData[key]}</center>
                              </TableCell>
                            );
                          else if (key === "isActive") {
                            return (
                              <TableCell>
                                {tableSubData[key] === 1 ? (
                                  <center>
                                    <ChipCompo
                                      label="Active"
                                      variant="outlined"
                                      size="small"
                                      color="success"
                                      onClick={(e) => changeStatus(e, row)}
                                    />
                                  </center>
                                ) : (
                                  <center>
                                    <ChipCompo
                                      label="Inactive"
                                      variant="outlined"
                                      size="small"
                                      color="error"
                                      onClick={(e) => changeStatus(e, row)}
                                    />
                                  </center>
                                )}
                              </TableCell>
                            );
                          } else
                            return <TableCell> {tableSubData[key]}</TableCell>;
                        }
                      })}
                      {/* <TableCell component="td" scope="row">
                        <center> {index + 1}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {tableSubData.schemeHeadName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <center> {tableSubData.value}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <center> {tableSubData.minMarks}</center>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <center> {tableSubData.maxMarks}</center>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  rowStatus,
  rowDelete,
  rowSubjectDetails,
  rowEdit,
  data,
  tableHead,
  showHeadEdit = true,
  showHeadDelete = true,
  showSubjectDetails = true,
  tableSubHead,
  marginTop,
  tableSubList,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {tableHead.map((tableHeadData) => {
              if (!tableHeadData.positionCenter)
                return <TableCell  style={{ minWidth: tableHeadData.minWidth }}>{tableHeadData.title}</TableCell>;
              else
                return (
                  <TableCell  style={{ minWidth:  tableHeadData.minWidth }}>
                    <center>{tableHeadData.title}</center>
                  </TableCell>
                );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row.id}
              row={row}
              tableHead={tableHead}
              showHeadDelete={showHeadDelete}
              showSubjectDetails={showSubjectDetails}
              showHeadEdit={showHeadEdit}
              marginTop={marginTop}
              rowEdit={rowEdit}
              rowStatus={rowStatus}
              rowDelete={rowDelete}
              rowSubjectDetails={rowSubjectDetails}
              tableSubHead={tableSubHead}
              tableSubList={tableSubList}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
