import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    activity: {},
    isFetch: false,
};
let URL = endpoints.hr;
const ActivitySlice = createSlice( {
    name: "activity",
    initialState,
    reducers: {
        responseSuccess: ( state = cloneDeep( initialState ), action ) =>
        {
            const { hrData } = action.payload;
            return {
                ...state,
                activity: hrData,
                isFetch: true,
            };
        }
    },
} );

export const { responseSuccess } = ActivitySlice.actions;

export default ActivitySlice.reducer;


export const getResponse = () => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const hrData = data.data
                console.log( hrData )
                dispatch( responseSuccess( { hrData } ) );
                return data
            }
            return success
        } );
        return response
    } catch ( e )
    {
        return console.error( e.message );
    }
};


