
    import endpoint from "../config/endpoints";
    
    export const FromFeesMasterJson = {
            "apiBaseURL":endpoint.fromFees,
            "screenTitle": "From Fees",
            "showAddButton": true,
            "showPdfDownload": true,
            "pdfFileName": "From Fees",
            "showExcelDownload": true,
            "excelFileName": "From Fees",
            "tableColumnsToFilter": [
                {
                    "columnDisplayName": "Name",
                    "columnKeyName": "name",
                    "isChecked": true
                },
                {
                    "columnDisplayName": "Status",
                    "columnKeyName": "isActive",
                    "isChecked": true
                }
            ],
            "baseIdColumn": "id",
            "fieldMeta": [
                {
                    "label": "Name",
                    "controlType": "textfield",
                    "placeHolder": "",
                    "md": 6,
                    "lg": 6,
                    "sm": 6,
                    "xs": 12,
                    "dataKey": "name",
                    "isMandatory": true
                }
            ]
        }
