import { FormHelperText, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getDesignation } from "../Masters/Internship/Designation/designation.slice";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import MultipleSelect from "../../components/Comman/MultipleSelect";

class DesignationAndRole extends Component {
  state = {
    id: 0,
    employeeType: "",
    designation: "",
    department: "",
    departmentAccess: [],
    formErrors: [],
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  changeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { employeeType, designation, department, departmentAccess } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    if (employeeType.toString().trim() === "") {
      formIsValid = false;
      formErrors["employeeTypeErr"] = myConstClass.employeeTypeMsg;
    }
    if (designation.toString().trim() === "" || designation === null) {
      formIsValid = false;
      formErrors["designationErr"] = myConstClass.designationMsg;
    }
    if (department.toString().trim() === "" || department === null) {
      formIsValid = false;
      formErrors["departmentErr"] = myConstClass.departmentMsg;
    }

    if (!departmentAccess.length) {
      formIsValid = false;
      formErrors["departmentAccessErr"] = myConstClass.departmentMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    const { getDepartment, getDesignation, employeeDetails } = this.props;
    getDesignation();
    getDepartment();
    console.log(employeeDetails.isSave);
    if (Object.keys(employeeDetails.employee).length != 0) {
      this.setState({
        id: employeeDetails.employee.id,
        employeeType:
          employeeDetails.employee.employeeType !== null
            ? employeeDetails.employee.employeeType
            : "",
        designation:
          employeeDetails.employee.designationId !== null
            ? employeeDetails.employee.designationId
            : "",
        department:
          employeeDetails.employee.departmentId !== null
            ? employeeDetails.employee.departmentId
            : "",
        departmentAccess:
          employeeDetails.employee.departmentAccess !== null
            ? employeeDetails.employee.departmentAccess.split(",")
            : [],
      });
    } else {
      this.setState({
        id: employeeDetails.employee.id,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employee !==
        nextProps.employeeDetails.employee
      ) {
        this.setState({
          id: nextProps.employeeDetails.employee.id,
          employeeType:
            nextProps.employeeDetails.employee.employeeType !== null
              ? nextProps.employeeDetails.employee.employeeType
              : "",
          designation:
            nextProps.employeeDetails.employee.designationId !== null
              ? nextProps.employeeDetails.employee.designationId
              : "",
          department:
            nextProps.employeeDetails.employee.departmentId !== null
              ? nextProps.employeeDetails.employee.departmentId
              : "",
          departmentAccess:
            nextProps.employeeDetails.employee.departmentAccess !== null
              ? nextProps.employeeDetails.employee.departmentAccess.split(",")
              : [],
        });
      }
    }
  }

  multiSelectHandler = (value, name) => {
    console.log(value);
    console.log(name);
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { employeeType, designation, department, id, departmentAccess } =
      this.state;
    let docs = {
      id: id,
      employeeType: employeeType,
      designationId: designation,
      departmentId: department,
      departmentAccess: departmentAccess.join(","),
      type: 5,
    };
    console.log(docs);
    if (this.handleFormValidation()) {
      this.props.designation(docs);
    }
    console.log(docs);
  };

  render() {
    const { designation, department, employeeType, departmentAccess } =
      this.state;
    const { onCancel, designations, departments, editForm } = this.props;
    const {
      designationErr,
      departmentErr,
      employeeTypeErr,
      departmentAccessErr,
    } = this.state.formErrors;
    console.log(designations);
    console.log(departments);
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid columnSpacing={1} container justifyContent="space-evenly">
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                sx={{ mt: 2 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Designation"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={designation}
                    name={"designation"}
                    disabled={editForm ? true : false}
                    options={designations?.designation.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.changeHandlerSearch}
                    isError={designationErr ? true : false}
                    errorText={designationErr ? designationErr : " "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                sx={{ mt: 2 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Department"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={department}
                    name={"department"}
                    disabled={editForm ? true : false}
                    options={departments?.department.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.changeHandlerSearch}
                    isError={departmentErr ? true : false}
                    errorText={departmentErr ? departmentErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Employee Type"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="employeeType"
                      id="employeeType"
                      value={employeeType}
                      disabled={editForm ? true : false}
                      onChange={this.handleChange}
                      size="small"
                      fullWidth={true}
                      // {...( employeeTypeErr
                      //   ? { error: true, helperText: employeeTypeErr }
                      //   : "" )}
                      // isError={departmentErr ? true : false}
                      // errorText={departmentErr ? departmentErr : " "}
                    >
                      <MenuItem value="Teaching Staff">Teaching Staff</MenuItem>
                      <MenuItem value="Non-Teaching Staff">
                        Non-Teaching Staff
                      </MenuItem>
                      <MenuItem value="Supporting Staff">
                        Supporting Staff
                      </MenuItem>
                      <MenuItem value="Administrative Staff">
                        Administrative Staff
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {employeeTypeErr ? employeeTypeErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Department Access"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <MultipleSelect
                    er={departmentAccessErr}
                    name1="departmentAccess"
                    disabled={editForm ? true : false}
                    value1={departmentAccess}
                    names={departments?.department.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.multiSelectHandler}
                  />
                </Grid>
              </Grid>

              <Grid container columnSpacing={1} justifyContent="right">
                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  designations: state.designation,
  departments: state.department,
  employeeDetails: state.employeeList,
});
const mapDispatchToProps = {
  getDesignation,
  getDepartment,
};
export default connect(mapStateToProps, mapDispatchToProps)(DesignationAndRole);
