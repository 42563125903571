import axios from "axios";
//import { ShowToast, toastType } from "./toast-service";
import { decodeData } from "../components/Comman/Util/Base64";

export function getApiHeader() {
  console.log(`${decodeData(localStorage.getItem("token"))}`);

  return {
    authorization: `${decodeData(localStorage.getItem("token"))}`,
    shouldrefresh: "true",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
}

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
export function cancelAllOngoingRequest() {
  if (source) {
    source.cancel();
  }
}

export function apiRequest({ url, params = {} }) {
  return axios
    .get(url, {
      headers: getApiHeader(),
      ...{ params },
      cancelToken: source.token,
    })
    .then((responses) => {
      return responses.data;
    })
    .catch(({ response }) => {
      // react on errors.
      //errorHandler(response);
      return response;
    });
}

export function apiBatchRequest(apiList) {
  const apiAxiosArr = [];

  apiList.forEach(({ url, params = {} }) => {
    apiAxiosArr.push(
      axios.get(url, {
        headers: getApiHeader(),
        ...{ params },
        cancelToken: source.token,
      })
    );
  });

  const response = genericAPI(apiAxiosArr, apiList);
  return response;
}

function genericAPI(apiAxiosArr, apiList) {
  const responseData = {};
  return axios
    .all(apiAxiosArr)
    .then(
      axios.spread((...responses) => {
        responses.forEach((res, i) => {
          responseData[apiList[i].key] = res.data;
        });

        return responseData;
      })
    )
    .catch(({ response }) => {
      //errorHandler(response);
      return response;
    });
}

export function apiPut({ url, postBody, params = {} }) {
  return axios
    .put(url, postBody, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      return { data, success: statusHandler(status) };
    })
    .catch(({ response }) => {
      // react on errors.
      // errorHandler({
      // 	message: response ? response.data : commonLabels.somethingWrong
      // });
      return { success: statusHandler(400), data: {} };
    });
}

export function apiPost({ url, postBody, params = {} }) {
  return axios
    .post(url, postBody, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      if (data.error && status === 200) {
        window.location.replace("/login");
        return null;
      }
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      // react on errors.
      // errorHandler({
      // 	message: error ? error.response.data : commonLabels.somethingWrong
      // });
      return { success: statusHandler(400), data: {} };
    });
}

export function apiPostWithoutHeader({ url, postBody, params = {} }) {
  return axios
    .post(url, postBody, {
      ...{ params },
    })
    .then(({ data, status }) => {
      if (data.error && status === 200) {
        window.location.replace("/login");
        return null;
      }
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      // react on errors.
      // errorHandler({
      // 	message: error ? error.response.data : commonLabels.somethingWrong
      // });
      return { success: statusHandler(400), data: {} };
    });
}

export function apiGetWithoutHeader({ url, params = {} }) {
  return axios
    .get(url, {
      ...{ params },
    })
    .then(({ data, status }) => {
      if (data.error) {
        window.location.replace("/login");
        return null;
      }
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      // react on errors.
      // errorHandler({
      // 	message: error ? error.response.data : commonLabels.somethingWrong
      // });
      return { success: statusHandler(400), data: {} };
    });
}
export function apiGet({ url, params = {} }) {
  console.log(url);
  return axios
    .get(url, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      if (data.error) {
        window.location.replace("/login");
        return null;
      }
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      // react on errors.
      // errorHandler({
      // 	message: error ? error.response.data : commonLabels.somethingWrong
      // });
      return { success: statusHandler(400), data: {} };
    });
}
export function apiDelete({ url, params = {} }) {
  return axios
    .delete(url, {
      headers: getApiHeader(),
      ...{ params },
    })
    .then(({ data, status }) => {
      if (data.error) {
        window.location.replace("/login");
        return null;
      }
      return { data, success: statusHandler(status) };
    })
    .catch(({ error }) => {
      // react on errors.
      // errorHandler({
      // 	message: error ? error.response.data : commonLabels.somethingWrong
      // });
      return { success: statusHandler(400), data: {} };
    });
}

function statusHandler(status) {
  if (!status) return;
  switch (status) {
    case 200:
      return true;

    default:
      return false;
  }
}
