import { Grid, Link } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import {
  getAdmissionList,
  resetValues,
} from "../StudentFeesCollection/FeesCollection.slice";
import { FilterSave } from "../../components/FilterSave";
import { Loading1 } from "../../components/Loading1";
let docs;

class ApplicantListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      yearDetailId: "",
      academicYear: "",
      academicYearId: "",
      admissionForYear: "",
      typeOfAdmission: "",
      applicationId: "",
      formErrors: [],
      showLoader: false,
      dynamicMasterData: {
        academicYearData: this.props.academicYearList?.academicYear,
        yearDetail: this.props.classList?.class,
      },
    };
  }

  componentDidMount() {
    this.props.resetReducList();
    this.props.resetValues();
    this.state.yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    if (ay && this.state.yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getAdmissionList({
          academicId: ay,
          yearDetailId: this.state.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    this.setState({
      showLoader: true,
    });
    this.props.getClass().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.yearDetail !== nextProps.yearDetail) {
      if (this.props.classList.class !== nextProps.classList.class) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.classList.class.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { yearDetailId } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetailId.toString().trim() === "" || yearDetailId === null) {
      formIsValid = false;
      formErrors["yearDetailIdError"] = myConstClass.yearDetailMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("yearDetailId", this.state.yearDetailId);
    localStorage.setItem("divisionIds", "");
    localStorage.setItem("semisterDetailId", "");
    localStorage.setItem("divisionId", "");
    localStorage.setItem("ay", ay);
    FilterSave();
    this.props.listReset([]);
    const { academicYearId, yearDetailId } = this.state;
    console.log("-------");
    console.log(ay);
    if (this.handleFormValidation()) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getAdmissionList({
          academicId: ay,
          yearDetailId: yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  saveHandler = (e) => {
    e.preventDefault();
    const {
      id,
      academicYearId,
      academicYear,
      admissionForYear,
      typeOfAdmission,
      applicationId,
    } = this.state;

    docs = {
      id: id === 0 ? 0 : id,
      academicYearId: academicYearId,
      academicYear: academicYear,
      admissionForYear: admissionForYear,
      typeOfAdmission: typeOfAdmission,
      applicationId: applicationId,
    };

    if (this.handleFormValidation()) {
      this.setState({
        id: 0,
        academicYear: academicYear,
        admissionForYear: admissionForYear,
        typeOfAdmission: typeOfAdmission,
        applicationId: applicationId,
      });
    }
  };

  render() {
    const { academicYearId, yearDetailId } = this.state;
    const { classList, academicYearList } = this.props;
    const { academicYearError, yearDetailIdError } = this.state.formErrors;
    return (
      <>
        {this.state.showLoader && <Loading1 />}
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          {/* <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                rowSpacing={2}
              > */}
          {/* <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={3}
            xs={12}
            sm={4}
            md={4}
          >
            <Grid item sx={{ ml: 1 }} xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Admited Student List"
              />
            </Grid>
          </Grid> */}

          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={4}
              md={4}
              sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Year Details"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={yearDetailId}
                  name={"yearDetailId"}
                  options={classList?.class}
                  onChange={this.ChangeHandlerSearch}
                  isError={yearDetailIdError ? true : false}
                  errorText={yearDetailIdError ? yearDetailIdError : " "}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              direction="row"
              xs={12}
              sm={4}
              md={8}
              sx={{ marginTop: 5 }}
            >
              <Grid item sx={{ mr: 1 }} xs={12} sm={12} md={1}>
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Search"
                  fullWidth={true}
                />
              </Grid>
              <Grid item sx={{ mr: 1 }} xs={12} sm={12} md={7} />

              <Grid item sx={{ mr: 4 }} xs={12} sm={12} md={1}>
                <Link href="/admissionForm" underline="none">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="New"
                    fullWidth={true}
                  />
                </Link>
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <ButtonCompo
                  size="medium"
                  //type="submit"
                  variant="contained"
                  name="Promotion"
                  fullWidth={true}
                  onClick={this.props.getPromotion}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* </Grid> */}
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  classList: state.class,
});
const mapDispatchToProps = {
  getAdmissionList,
  getAlert,
  getAcademicYear,
  getClass,
  resetReducList,
  resetValues,
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplicantListForm);
