import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { showNotification } from "../../Landing/Landing.slice";
const BasicTable = ({
  rows,
  onCancelForm,
  getAlert,
  editData = {},
  resetUserAccess,
  stateData,
  showNotification,
  rowDataForm,
  onCancelView,
  onSaveData,
}) => {
  const [moduleList, setModuleList] = React.useState([]);
  const [error, setErrors] = React.useState(false);
  const [checkedvalue, setCheckedvalue] = React.useState(false);
  const [formError, setFormErrors] = React.useState(false);

  const onBack = () => {
    window.location.replace("/teaching-plan-teacher-view");
  };

  React.useEffect(() => {
    if (rowDataForm != undefined || rowDataForm != null) {
      if (moduleList.length === 0 && rows.length !== 0) {
        apiGet({
          url:
            endpoint.teachingPlan +
            "/" +
            rowDataForm.id +
            "/get-subject-teaching-plan-detail",
        }).then(({ data, success }) => {
          const chapterList = rows.map((chapterData) => {
            const sectionList = chapterData.sectionList.map((section) => {
              const subSectionList = section.subSectionList.map(
                (subSection) => {
                  const filterSubSectionList = data.data.filter(
                    (subSectionData) =>
                      subSectionData.syllabusSubSection.id === subSection.id
                  );
                  if (filterSubSectionList.length !== 0) {
                    subSection = {
                      ...subSection,
                      checked: true,
                    };
                    return subSection;
                  }
                  return subSection;
                }
              );
              section = {
                ...section,
                subSectionList: subSectionList,
              };
              return section;
            });
            chapterData = {
              ...chapterData,
              sectionList: sectionList,
            };
            return chapterData;
          });
          const chapterList1 = chapterList.map((chapterData) => {
            const sectionList = chapterData.sectionList.map((section) => {
              let subSectionList = section.subSectionList.filter(
                (item) => item.checked == true
              );
              if (section.subSectionList.length === subSectionList.length) {
                section = {
                  ...section,
                  checked: true,
                };
                return section;
              } else {
                section = {
                  ...section,
                };
                return section;
              }
            });
            chapterData = {
              ...chapterData,
              sectionList: sectionList,
            };
            return chapterData;
          });
          const chapterList2 = chapterList1.map((chapterData) => {
            let sectionList = chapterData.sectionList.filter(
              (item) => item.checked == true
            );
            if (chapterData.sectionList.length === sectionList.length) {
              chapterData = {
                ...chapterData,
                checked: true,
              };
              return chapterData;
            } else {
              chapterData = {
                ...chapterData,
              };
              return chapterData;
            }
          });
          setModuleList(chapterList2);
        });
      }
    }
  });

  React.useEffect(() => {
    if (moduleList.length === 0) {
      setModuleList(rows);
    }
    if (Object.keys(editData).length !== 0 && moduleList !== editData.modules) {
      if (moduleList.length !== 0) {
        const moduleListUpdated = moduleList.map((module) => {
          let filteredModuleList = editData.modules.filter(
            (moduleData) => moduleData.moduleId === module.moduleId
          );
          if (filteredModuleList.length !== 0) {
            const moduleSubs = module.moduleSubs.map((moduleSubs) => {
              let filteredSubModuleList =
                filteredModuleList[0].moduleSubs.filter(
                  (moduleData) =>
                    moduleData.subModuleId === moduleSubs.subModuleId
                );
              if (filteredSubModuleList.length !== 0) {
                moduleSubs = {
                  ...moduleSubs,
                  addApproveConfig: filteredSubModuleList[0].addApproveConfig,
                  deleteRejectApprove:
                    filteredSubModuleList[0].deleteRejectApprove,
                  editReject: filteredSubModuleList[0].editReject,
                  view: filteredSubModuleList[0].view,
                };
                return moduleSubs;
              }
              return moduleSubs;
            });
            module = {
              ...module,
              checked: 0,
              moduleSubs: moduleSubs,
            };
            return module;
          }
          return module;
        });
        setModuleList(moduleListUpdated);
        resetUserAccess();
      }
    }
  });
  // http://localhost:8088/api/acdmc/v1/subject-teaching-plan

  const submitHandler = (flag) => {
    console.log(flag);
    if (!checkedvalue) {
      setFormErrors(true);
    } else {
      setFormErrors(false);
      let dataToSave = {
        semisterDetail: {
          id: stateData.semister,
        },
        subjectDetail: {
          id: stateData.subject,
        },
        division: {
          id: stateData.division,
        },
        noOfLectures: stateData.noOfLectures,
        mediaUsed: stateData.mediaUsed,
        topicTaught: stateData.topic,
        date: moment(stateData.date).format("DD-MM-YYYY"),
        toDate: moment(stateData.toDate).format("DD-MM-YYYY"),
        remark: stateData.remark,
        isActive: 1,
      };
      if (stateData.id !== 0) {
        dataToSave = {
          ...dataToSave,
          id: stateData.id,
        };
      }
      onSaveData(dataToSave, moduleList, flag);
    }
  };

  const onClickChange = (e, rowData) => {
    if (e.target.checked) {
      setCheckedvalue(true);
    } else {
      setCheckedvalue(false);
    }
    const chapterList = moduleList.map((chapterData) => {
      if (chapterData.id === rowData.id) {
        chapterData = {
          ...chapterData,
          checked: e.target.checked,
          sectionList: chapterData.sectionList.map((sectionData) => {
            sectionData = {
              ...sectionData,
              checked: e.target.checked,
              subSectionList: sectionData.subSectionList.map(
                (subSectionData) => {
                  subSectionData = {
                    ...subSectionData,
                    checked: e.target.checked,
                  };
                  return subSectionData;
                }
              ),
            };
            return sectionData;
          }),
        };
        return chapterData;
      }
      return chapterData;
    });
    setModuleList(chapterList);
  };
  const onClickSection = (e, rowData, section) => {
    if (e.target.checked) {
      setCheckedvalue(true);
    } else {
      setCheckedvalue(false);
    }
    const chapterList = moduleList.map((chapterData) => {
      if (chapterData.id === rowData.id) {
        const sectionList = chapterData.sectionList.map((sectionData) => {
          if (sectionData.id === section.id) {
            sectionData = {
              ...sectionData,
              checked: e.target.checked,
              subSectionList: sectionData.subSectionList.map(
                (subSectionData) => {
                  subSectionData = {
                    ...subSectionData,
                    checked: e.target.checked,
                  };
                  return subSectionData;
                }
              ),
            };
            return sectionData;
          }
          return sectionData;
        });
        chapterData = {
          ...chapterData,
          sectionList: sectionList,
        };
        return chapterData;
      }
      return chapterData;
    });
    const chapterList2 = chapterList.map((chapterData) => {
      if (chapterData.id === section.chapterId) {
        let chapterDataCnt = chapterData.sectionList.filter(
          (row) => row.checked == true
        );

        if (chapterData.sectionList.length == chapterDataCnt.length) {
          chapterData = {
            ...chapterData,
            checked: true,
          };
          return chapterData;
        } else {
          chapterData = {
            ...chapterData,
            checked: false,
          };
          return chapterData;
        }
      }
      return chapterData;
    });
    setModuleList(chapterList2);
  };

  const onClickSubSection = (e, section, subSection) => {
    if (e.target.checked) {
      setCheckedvalue(true);
    } else {
      setCheckedvalue(false);
    }
    const chapterList = moduleList.map((chapterData) => {
      if (chapterData.id === section.chapterId) {
        const sectionList = chapterData.sectionList.map((sectionData) => {
          if (sectionData.id === section.id) {
            const subSectionList = sectionData.subSectionList.map(
              (subSectionData) => {
                if (subSectionData.id === subSection.id) {
                  subSectionData = {
                    ...subSectionData,
                    checked: e.target.checked,
                  };
                  return subSectionData;
                }
                return subSectionData;
              }
            );
            sectionData = {
              ...sectionData,
              subSectionList: subSectionList,
            };
            return sectionData;
          }
          return sectionData;
        });
        chapterData = {
          ...chapterData,
          sectionList: sectionList,
        };
        return chapterData;
      }
      return chapterData;
    });
    const chapterList1 = chapterList.map((chapterData) => {
      if (chapterData.id === section.chapterId) {
        const sectionList = chapterData.sectionList.map((sectionData) => {
          if (sectionData.id === section.id) {
            let subSectionCnt = sectionData.subSectionList.filter(
              (row) => row.checked == true
            );
            if (sectionData.subSectionList.length == subSectionCnt.length) {
              sectionData = {
                ...sectionData,
                checked: true,
              };
              return sectionData;
            } else {
              sectionData = {
                ...sectionData,
                checked: false,
              };
              return sectionData;
            }
          }
          return sectionData;
        });
        chapterData = {
          ...chapterData,
          sectionList: sectionList,
        };
        return chapterData;
      }
      return chapterData;
    });
    const chapterList2 = chapterList1.map((chapterData) => {
      if (chapterData.id === section.chapterId) {
        let chapterDataCnt = chapterData.sectionList.filter(
          (row) => row.checked == true
        );

        if (chapterData.sectionList.length == chapterDataCnt.length) {
          chapterData = {
            ...chapterData,
            checked: true,
          };
          return chapterData;
        } else {
          chapterData = {
            ...chapterData,
            checked: false,
          };
          return chapterData;
        }
      }
      return chapterData;
    });
    setModuleList(chapterList2);
  };
  return (
    <>
      <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
        <LabelCompo
          className="text-black"
          style={{ fontSize: 12, color: "#808080" }}
          label="Note: Select atleast one chapter, section or subsection."
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Sr. No.</TableCell>
              <TableCell width="30%">Chapter Name</TableCell>
              <TableCell width="30%">Section </TableCell>
              <TableCell width="30%">Sub Section </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moduleList.map((row, index) => (
              <>
                <TableRow key={row.id}>
                  <TableCell align="center">{index + 1}</TableCell>

                  <TableCell component="th" scope="row" colSpan={3}>
                    {row.sectionList.length !== 0 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"Course" + index}
                              checked={row.checked}
                              id={"Course" + index}
                            />
                          }
                          onChange={(e) => onClickChange(e, row)}
                          label={row.chapter}
                          labelPlacement="right"
                        />
                      </FormGroup>
                    ) : (
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={row.chapter}
                      />
                    )}
                  </TableCell>
                </TableRow>

                {row.sectionList.map((section, index) => {
                  return (
                    <>
                      <TableRow key={section.id}>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell component="th" scope="row" colSpan={2}>
                          {section.subSectionList.length !== 0 ? (
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={"Course" + index}
                                    checked={section.checked}
                                    id={"Course" + index}
                                  />
                                }
                                label={section.sectionName}
                                onChange={(e) =>
                                  onClickSection(e, row, section)
                                }
                                labelPlacement="right"
                              />
                            </FormGroup>
                          ) : (
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={section.sectionName}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {section.subSectionList.map((subSection, index) => {
                        return (
                          <>
                            <TableRow key={subSection.id}>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center" colSpan={2}></TableCell>
                              <TableCell component="th" scope="row">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={"Course" + index}
                                        checked={subSection.checked}
                                        id={"Course" + index}
                                      />
                                    }
                                    onChange={(e) =>
                                      onClickSubSection(e, section, subSection)
                                    }
                                    label={subSection.subSectionName}
                                    labelPlacement="right"
                                  />
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      <TableRow key={row.chapter}></TableRow>
                    </>
                  );
                })}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {formError && (
        <FormHelperText error>
          *Please Select all required fields (Select atleast one chapter,
          section or subSection)
        </FormHelperText>
      )}
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div align="right">
        <ButtonCompo
          size="button"
          type="button"
          variant="outlined"
          name="Cancel"
          onClick={onBack}
        />
        &nbsp;&nbsp;
        <ButtonCompo
          size="button"
          type="button"
          variant="contained"
          name="Save And Next"
          onClick={(e) => submitHandler(1)}
        />
        &nbsp;&nbsp;
        <ButtonCompo
          size="button"
          type="button"
          variant="contained"
          name="Submit"
          onClick={(e) => submitHandler(0)}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  student: state.studentList,
  teachingPlan: state.teachingPlan,
});

const mapDispatchToProps = {
  getAlert,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
