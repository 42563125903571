import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import ListOfCertificatePopUp from "./ListOfCertificatePopUp";
import { getCertificateListOfStudent } from "./studentCertificate.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { getAlert } from "../../../CommonActions/alert.slice";
import { apiPost, apiGet } from "../../../utils/api_service";
import endpoints from "../../../config/endpoints";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import SearchCertificateHistory from "../../../components/SearchTable";
import { certificateStudentJson } from "../../../DynamicFormsJson/CertificateStudentTable";
import { isEmpty } from "lodash";
import PayPhiPaymentGateway from "../../../components/Comman/PayPhiPaymentGatway";

const ListOfCertificate = ({
  getCertificateListOfStudent,
  studentCertificateList,
  getAlert,
  showNotification,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [showTable, setShowTable] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [regId, setRegId] = useState("");
  const [detailId, setDetailId] = useState("");
  const [type, setType] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [amt, setAmount] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [merchantTxnNo, setMerchantTxnNo] = useState("");
  const [returnURL, setReturnURL] = useState("");
  const [txnDate, setTxnDate] = useState("");
  const [hashId, setHashId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [progTId, setProgTId] = useState("");
  const [aggId, setAggId] = useState("");

  useEffect(() => {
    setShowLoader(true);
    getCertificateListOfStudent().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
  }, []);

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "certificateList",
      title: "Certificate List",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "payment",
      title: "Payment",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "action",
      title: "Actions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  const rowView = (data) => {
    console.log(data);
    setDetailId(data.id);
    setType(data.type);
    setAmount(data.payment);
    setShowTable(true);
    setShowPopUp(true);
  };

  const handleCloses = () => {
    setShowTable(true);
    setShowPopUp(false);
  };

  const OnSave = (datatoSave) => {
    console.log(datatoSave);
    apiGet({
      url: endpoints.profileInfo,
    }).then(({ success, data }) => {
      if (success) {
        console.log(data.data.programTitleId);
        setRegId(data.data.id);
        setFirstName(data.data.firstName);
        setLastName(data.data.lastName);
        setEmail(data.data.studentEmail);
        setPhone(data.data.studentMobile);
        localStorage.setItem("setProgTitleId", data.data.programTitleId);
      }
    });
    swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        const formData = new FormData();
        formData.append("file", datatoSave.file);
        apiPost({
          url: endpoints.fileUpload,
          postBody: formData,
        }).then(({ data1, success }) => {
          let docs1 = {
            certificateApplicationHeader: {
              id: detailId,
            },
            detail: datatoSave.details,
            userRemark: datatoSave.userRemark,
            type: type,
            // documentUpload: Object.keys(data1).length === 0 ? "" : data1,
            is_paid: 1,
            payDetail: "",
            amount: amt,
            status: 0,
            deliveryDate: "",
          };
          if (data1 !== undefined) {
            docs1 = {
              ...docs1,
              documentUpload: Object.keys(data1).length === 0 ? "" : data1,
            };
          } else {
            docs1 = {
              ...docs1,
              documentUpload: "",
            };
          }
          localStorage.setItem(
            "saveCetificatePaymentTransactionPojo",
            JSON.stringify(docs1)
          );
          const programTitleId = localStorage.getItem("setProgTitleId");
          console.log(programTitleId);
          let docs = {
            programTitleId: programTitleId,
            amount: amt,
            returnURL:
              "https://eduerpsuccess.aaryatechindia.in/promotional-student-admission-success.php?userId=",
          };
          apiPost({
            url: endpoints.paymentUrl + "/get-hash",
            postBody: docs,
          }).then(({ data, success }) => {
            if (success) {
              console.log(data);
              setMerchantId(data.merchantId);
              setMerchantTxnNo(data.merchantTxnNo);
              setReturnURL(data.returnURL);
              setTxnDate(data.txnDate);
              setHashId(data.hash);
              setCurrencyCode(data.currencyCode);
              setAggId(data.aggregatorId);
              setShowTable(false);
              setShowPopUp(false);
              setShowPayment(true);
            }
          });
        });
      }
    });
  };
  const cancelHandler = () => {
    setShowTable(true);
    setShowPopUp(false);
    setShowPayment(false);
    setMerchantId("");
    setMerchantTxnNo("");
    setReturnURL("");
    setTxnDate("");
    setHashId("");
    setCurrencyCode("");
    setAmount("");
  };
  const postMethode = () => {
    let docs = {
      userRegistrationId: regId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobileNo: phone,
      txnId: merchantTxnNo,
      amount: amt,
      paymentReturnStatus: "",
      paymentReturnData: "",
      mihpayid: "",
      productInfo: "Certificate Fee",
    };
    apiPost({
      url: endpoints.paymentUrl + "-transaction",
      postBody: docs,
    }).then(({ data, success }) => {
      if (success) {
      }
    });
  };
  const getFilteredTableData = () => {
    return studentCertificateList?.certificateListOfStudent.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["certificateList"] &&
            currentRow["certificateList"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["payment"] &&
            currentRow["payment"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["index"] &&
            currentRow["index"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  return (
    <>
      {showTable && (
        <>
          <DynamicMainScreen
            // onAddButtonClick={this.OpenForm}
            screenTitle={certificateStudentJson.screenTitle}
            fieldMeta={[]}
            buttonCenter={certificateStudentJson.buttonCenter}
            showPdfDownload={certificateStudentJson.showPdfDownload}
            showExcelDownload={certificateStudentJson.showExcelDownload}
            pdfFileName={certificateStudentJson.pdfFileName}
            excelFileName={certificateStudentJson.excelFileName}
            tableColumnsToFilter={[]}
            showAddButton={certificateStudentJson.showAddButton}
            dynamicMasterData={[]}
            showLoader={showLoader}
            searchButton={false}
            tableColumns={columns}
            tableData={studentCertificateList?.certificateListOfStudent}
            // getListById={this.getListById}
            showPegination={false}
            showHeadEdit={false}
            showHeadDelete={false}
            showApply={true}
            rowView={rowView}
          />
        </>
      )}

      {showPopUp && (
        <>
          <ListOfCertificatePopUp onSave={OnSave} handleCloses={handleCloses} />
        </>
      )}

      {showPayment && (
        <>
          <PayPhiPaymentGateway
            currencyCode={currencyCode}
            amount={amt}
            merchantId={merchantId}
            merchantTxnNo={merchantTxnNo}
            returnURL={returnURL}
            txnDate={txnDate}
            hashcode={hashId}
            handleClose={cancelHandler}
            postMethode={postMethode}
            firstName={firstName}
            lastName={lastName}
            email={email}
            mobile={phone}
            aggregatorID={aggId}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  studentCertificateList: state.studentCertificate,
});
const mapDispatchToProps = {
  getCertificateListOfStudent,
  getAlert,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListOfCertificate);
