import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
// import * as React from "react";

import { pink } from "@mui/material/colors";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { Link as RouterLink } from "react-router-dom";
import AutoComplete from "../../components/Comman/AutoComplete";
import { getAlert } from "../../CommonActions/alert.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import swal from "sweetalert";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import PayPhiPaymentGateway from "../../components/Comman/PayPhiPaymentGatway";
import { Loading1 } from "../../components/Loading1";
import "../../CSS/paymentPopup.css";
import { savePaymentTransactionPojo } from "../PromotionalStudentAdmission/promotionalAdmission.slice";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//   import CompletedSyllabus from "./CompletedSyllabus";

function StudentApplyForNextYear({
  editData,
  teachingPlanList,
  studentAttendanceList,
  saveFormData,
  onSave,
  backToList,
  classList,
  getClass,
  getAlert,
  savePaymentTransactionPojo,
}) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [userRegistrationId, setUserRegistrationId] = React.useState("");
  const [key, setKey] = React.useState("");
  const [regId, setRegId] = React.useState("");
  const [txnId, setTxnId] = React.useState("");
  const [payment, setPayment] = React.useState(false);
  const [label, setLabel] = React.useState("1");
  const [labelValue, setLabelValue] = React.useState("");
  const [nextYearAdmission, setNextYearAdmission] = React.useState("");
  const [academicYearName, setAcademicYearName] = React.useState("");
  const [yearDetail, setYearDetail] = React.useState("");
  const [feesStructureName, setFeesStructureName] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [currencyCode, setCurrencyCode] = React.useState("");
  const [merchantId, setMerchantId] = React.useState("");
  const [merchantTxnNo, setMerchantTxnNo] = React.useState("");
  const [returnURL, setReturnURL] = React.useState("");
  const [txnDate, setTxnDate] = React.useState("");
  const [hashId, setHashId] = React.useState("");
  const [progTId, setProgTId] = React.useState("");
  const [aggId, setAggId] = React.useState("");

  useEffect(() => {
    setLoader(true);
    apiGet({
      url:
        endpoint.promotionalAdmissionStudent +
        "/get-next-year-admission-academic-year-by-institute",
    }).then(({ success, data }) => {
      if (!success) {
        setLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setLoader(false);
        if (!data.data.errorMessage.error) {
          setLabel("3");
          setLabelValue(data.data.errorMessage.message);
        } else if (data.data.errorMessage.error) {
          if (data.data.errorMessage.statusCode === 500) {
            setLabel("1");
            setLabelValue("No admissions open for any academic year");
          } else {
            apiGet({
              url:
                endpoint.promotionalAdmissionStudent +
                "/by-payment-status-and-approved-status",
            }).then(({ success, data }) => {
              console.log(data);
              console.log(success);
              if (success) {
                if (!data.data.error) {
                  if (data.data.paymentStatus === 0) {
                    setLabelValue(
                      "Admission for the next year has been approved proceed for payment"
                    );
                    setLabel("4");
                    setFirstName(data.data.userRegistration.firstName);
                    setLastName(data.data.userRegistration.lastName);
                    setEmail(data.data.userRegistration.studentEmail);
                    setPhone(data.data.userRegistration.studentMobile);
                    setAmount(data.data.feesStructureHeader.totalFees);
                    setUserRegistrationId(data.data.userRegistration.id);
                    setNextYearAdmission(data.data.id);
                    setAcademicYearName(data.data.academicYear.name);
                    setProgTId(data.data.yearDetail.programTitle.id);
                    setYearDetail(
                      data.data.yearDetail.year.programType.name +
                        " " +
                        data.data.yearDetail.programTitle.brName +
                        " " +
                        data.data.yearDetail.year.className
                    );
                    setFeesStructureName(data.data.feesStructureHeader.name);
                  } else {
                    setLabelValue("Your admission process is done");
                    setLabel("5");
                  }
                } else {
                  setLabelValue(data.data.errorMessage.message);
                  setLabel("2");
                }
              } else {
                setLabelValue(
                  "You have already applied for next year admission"
                );
              }
            });
          }
        }
      }
    });
  }, []);

  const applyHandler = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to apply for next year?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoader(true);
        let docs = {
          paymentStatus: 0,
          approvedStatus: 0,
          isActive: 1,
        };
        apiPost({
          url: endpoint.promotionalAdmissionStudent,
          postBody: docs,
        }).then(({ data, success }) => {
          if (success) {
            setLoader(false);
            window.location.replace("/student-apply-for-next-year");
          } else {
            setLoader(false);
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  const paymentHandler = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to pay?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiGet({
          url: endpoint.profileInfo,
        }).then(({ success, data }) => {
          if (success) {
            setRegId(data.data.id);
            setFirstName(data.data.firstName);
            setLastName(data.data.lastName);
            setEmail(data.data.studentEmail);
            setPhone(data.data.studentMobile);
          }
        });
        setLoader(true);
        //progTId
        let docs = {
          programTitleId: progTId,
          returnURL:
            "https://eduerpsuccess.aaryatechindia.in/promotional-student-admission-success.php?userId=",
          amount: amount,
        };
        apiPost({
          url: endpoint.paymentUrl + "/get-hash",
          postBody: docs,
        }).then(({ data, success }) => {
          if (success) {
            setLoader(false);
            setPayment(true);
            console.log(data);
            setMerchantId(data.merchantId);
            setMerchantTxnNo(data.merchantTxnNo);
            setReturnURL(data.returnURL);
            setTxnDate(data.txnDate);
            setHashId(data.hash);
            setAggId(data.aggregatorId);
            setCurrencyCode(data.currencyCode);
          } else {
            setLoader(false);
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  const handleClose = () => {
    setPayment(false);
  };

  const postMethode = () => {
    let docs = {
      nextYearAdmission: {
        id: nextYearAdmission,
      },
      paymentTransaction: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobileNo: phone,
        txnId: txnId,
        amount: amount,
        productInfo: "Next Year Admission Fee",
      },
    };

    localStorage.setItem("savePaymentTransactionPojo", JSON.stringify(docs));

    let document = {
      userRegistrationId: regId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobileNo: phone,
      txnId: merchantTxnNo,
      amount: amount,
      paymentReturnStatus: "",
      paymentReturnData: "",
      mihpayid: "",
      productInfo: "Next Year Admission Fee",
    };
    apiPost({
      url: endpoint.paymentUrl + "-transaction",
      postBody: document,
    }).then(({ data, success }) => {
      if (success) {
      }
    });
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Apply for next year admission"
            />
          </Grid>
          <Grid
            sx={{ mt: 1 }}
            item
            container
            justifyContent="right"
            xs={12}
            md={6}
            lg={6}
          ></Grid>
        </Grid>
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={labelValue}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            {label == "3" && (
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Apply"
                onClick={applyHandler}
              />
            )}
            {label == "4" && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Year Detail "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="venue"
                        size="small"
                        value={yearDetail}
                        // onChange={this.changeHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Academic Year "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="venue"
                        size="small"
                        value={academicYearName}
                        // onChange={this.changeHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Fees Structure Name "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="venue"
                        size="small"
                        value={feesStructureName}
                        // onChange={this.changeHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Amount "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="venue"
                        size="small"
                        value={"₹ " + amount}
                        // onChange={this.changeHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Proceed to pay"
                  onClick={paymentHandler}
                />
              </>
            )}
          </Grid>
        </Grid>
        <br />
        {console.log(currencyCode)}
        {console.log(amount)}
        {console.log(merchantId)}
        {console.log(merchantTxnNo)}
        {console.log(returnURL)}
        {console.log(txnDate)}
        {console.log(hashId)}
        {console.log(firstName)}
        {payment && (
          // <>
          //   <PayPhiPaymentGateway
          //     currencyCode={currencyCode}
          //     amount={amount}
          //     merchantId={merchantId}
          //     merchantTxnNo={merchantTxnNo}
          //     returnURL={returnURL}
          //     txnDate={txnDate}
          //     hashcode={hashId}
          //     handleClose={cancelHandler}
          //     postMethode={postMethode}
          //     firstName={firstName}
          //     lastName={lastName}
          //     email={email}
          //     mobile={phone}
          //   />

          <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth="sm"
              >
                <DialogContent>
                  <div class="form-style-5">
                    <form>
                      <fieldset>
                        <legend>Transaction Details</legend>
                        <lable>First Name</lable>
                        <input
                          type="text"
                          name="firstName"
                          disabled
                          value={firstName}
                        />
                        <lable>Last Name</lable>
                        <input
                          type="text"
                          name="lastName"
                          disabled
                          value={lastName}
                        />
                        <lable>Email</lable>
                        <input
                          type="text"
                          name="email"
                          disabled
                          value={email}
                        />
                        <lable>Mobile</lable>
                        <input
                          type="text"
                          name="phone"
                          disabled
                          value={phone}
                        />
                        <label>Amount</label>
                        <input
                          type="text"
                          name="amount"
                          disabled
                          value={amount}
                        />
                      </fieldset>
                    </form>
                    {/* https://qa.phicommerce.com/pg/api/sale?v=2 */}
                    <form
                      id="myForm"
                      name="myForm"
                      action="https://secure-ptg.payphi.com/pg/api/sale?v=2"
                      method="post"
                      enctype="application/x-www-form-urlencoded"
                    >
                      <fieldset>
                        <input
                          type="hidden"
                          name="merchantID"
                          value={merchantId}
                        />
                        <input
                          type="hidden"
                          name="merchantTxnNo"
                          value={merchantTxnNo}
                        />
                        <input type="hidden" name="amount" value={amount} />
                        <input
                          type="hidden"
                          name="currencyCode"
                          value={currencyCode}
                        />
                        <input type="hidden" name="payType" value="" />
                        <input type="hidden" name="paymentMode" value="" />
                        <input type="hidden" name="customerEmailID" value="" />
                        <input type="hidden" name="transactionType" value="" />
                        <input
                          type="hidden"
                          name="paymentOptionCodes"
                          value=""
                        />
                        <input type="hidden" name="txnDate" value={txnDate} />
                        <input type="hidden" name="cardNo" value="" />
                        <input type="hidden" name="cardExpiry" value="" />
                        <input type="hidden" name="nameOnCard" value="" />
                        <input type="hidden" name="cvv" value="" />
                        <input type="hidden" name="customerUPIAlias" value="" />
                        <input
                          type="hidden"
                          name="aggregatorID"
                          value={aggId}
                        />
                        <input type="hidden" name="remarks" value="" />
                        <input type="hidden" name="customerName" value="" />
                        <input type="hidden" name="customerMobileNo" value="" />
                        <input type="hidden" name="invoiceNo" value="" />
                        <input
                          type="hidden"
                          name="returnURL"
                          value={returnURL}
                        />
                        <input type="hidden" name="addlParam1" value="" />
                        <input
                          type="hidden"
                          name="allowDisablePaymentMode"
                          value=""
                        />
                        <input type="hidden" name="addlParam2" value="" />
                        <input type="hidden" name="secureHash" value={hashId} />
                        <p id="hashtext"></p>
                      </fieldset>
                      <input
                        type="submit"
                        name="submitbut"
                        value="Pay"
                        onClick={postMethode}
                      />
                      <input
                        type="button"
                        value="Cancel"
                        onClick={handleClose}
                      />
                    </form>
                  </div>
                </DialogContent>
                <DialogActions></DialogActions>
              </BootstrapDialog>
            </Paper>
          </>
        )}
        {loader && <Loading1 />}
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
  teachingPlanList: state.teachingPlan,
  classList: state.class,
});
const mapDispatchToProps = {
  showNotification,
  getClass,
  getAlert,
  savePaymentTransactionPojo,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentApplyForNextYear);
