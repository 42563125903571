import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  adminViewInternship: [],
  isFetch: false,
};
let URL = endpoints.adminViewAlumniInternship;

const adminViewAlumniInternshipSlice = createSlice({
  name: "adminViewInternship",
  initialState,
  reducers: {
    adminViewInternshipSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        adminViewInternship: row,
        isFetch: true,
      };
    },
  },
});

export const { adminViewInternshipSuccess } =
  adminViewAlumniInternshipSlice.actions;

export default adminViewAlumniInternshipSlice.reducer;

export const getAlumniInternship = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            alumniName:
              data1.alumniRegistration === null
                ? ""
                : data1.alumniRegistration.lastName +
                  " " +
                  data1.alumniRegistration.firstName +
                  " " +
                  (data1.alumniRegistration.fatherName == null ||
                  data1.alumniRegistration.fatherName == ""
                    ? " - "
                    : data1.alumniRegistration.fatherName) +
                  " " +
                  (data1.alumniRegistration.motherName == null ||
                  data1.alumniRegistration.motherName == ""
                    ? " - "
                  : data1.alumniRegistration.motherName ),
            lastName: data1.alumniRegistration.lastName,
            firstName:   data1.alumniRegistration.firstName ,
            fatherName: data1.alumniRegistration.fatherName,
            motherName:   data1.alumniRegistration.motherName ,
            
            alumniId:
              data1.alumniRegistration === null
                ? ""
                : data1.alumniRegistration.id,
            id: data1.id,
            companyName: data1.companyName,
            title: data1.title,
            duration: data1.duration,
            location: data1.location,
            companyWebsite: data1.companyWebsite,
          };
          row.push(bData);
          return data1;
        });
        dispatch(adminViewInternshipSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
