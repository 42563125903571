import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen"
import { InternshipTypeColumns } from "../../../../tableColumns/table-columns";
import { InternshipTypeJson } from "../../../../DynamicFormsJson/InternshipType";
import { getInternshipType } from './IntershipType.slice'
class InternshipType extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
      },
    };
  }

  componentDidMount() {
    const { getInternshipType } = this.props;
    getInternshipType();
  }

  render() {
    const { dynamicMasterData } = this.state;
    const { internshipTypeList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={InternshipTypeJson.screenTitle}
            fieldMeta={InternshipTypeJson.fieldMeta}
            showPdfDownload={InternshipTypeJson.showPdfDownload}
            showExcelDownload={InternshipTypeJson.showExcelDownload}
            pdfFileName={InternshipTypeJson.pdfFileName}
            excelFileName={InternshipTypeJson.excelFileName}
            showAddButton={InternshipTypeJson.showAddButton}
            tableColumnsToFilter={InternshipTypeJson.tableColumnsToFilter}
            // dynamicMasterData={dynamicMasterData}
            tableColumns={InternshipTypeColumns}
            tableData={internshipTypeList?.internshipType}
            getTableData={this.props.getInternshipType}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={internshipTypeList.isInternshipTypeFetch}
            apiBaseURL={InternshipTypeJson.apiBaseURL}
            baseIdColumn={InternshipTypeJson.baseIdColumn}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipTypeList: state.internshipType,
});
const mapDispatchToProps = {
  getInternshipType,
};
export default connect(mapStateToProps, mapDispatchToProps)(InternshipType);
