import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
// import * as msgConstant from "../../../../../config/messageconstant";
import { adminInnovativeTeachingJson } from "../../../../../DynamicFormsJson/AdminInnovativeTeaching";
import { showNotification } from "../../../../Landing/Landing.slice";
import { innovativeTeachingColumns } from "../../../../../tableColumns/table-columns";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { apiDelete, apiPost } from "../../../../../utils/api_service";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import moment from "moment";
// import { Loading1 } from "../Loading1";
import { getActivityByType } from "../../AcademicDetails/Activity/activity.slice";
import {
  getInnovative,
  getInnovativeByDate,
  getInnovativeWithoutDate,
  setInnovativeTeaching,
  getAdminInnovative,
} from "../../AcademicDetails/InnovativeTeaching/InnovativeTeaching.slice";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getEmployeeByDeptId } from "../../../../EmployeeDetails/employee.slice";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import * as myConstClass from "../../../../../config/messageconstant";
import { Loading1 } from "../../../../../components/Loading1";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminInnovativeTeaching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      formErrors: {},
      searchValue: "",
      showLoader: false,
      dynamicMasterData: {
        activity: this.props.activityList?.activityByType,
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeList?.employeeList,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.activityByType !==
        nextProps.activityList.activityByType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: nextProps.activityList.activityByType.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
    if (this.props.employeeList !== nextProps.employeeList) {
      if (
        this.props.employeeList?.employeeList !==
        nextProps.employeeList?.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeList?.employeeList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  getListById = (data) => {
    if (data.departmentId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getEmployeeByDeptId({
          departmentId: data.departmentId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });

    this.props.getActivityByType().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });

    // this.props.getInnovativeWithoutDate().then((response) => {
    //   if (!response) {
    //     this.setState({ showLoader: false });

    //     this.props.getAlert({ message: "Something went wrong" });
    //   } else {
    //     this.setState({ showLoader: false });
    //   }
    // });
  }

  submitHandler = (data) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getAdminInnovative({ userRegistration: data.userRegistration })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };
  goToAdminAcademicPanel = () => {
    window.location.replace("/admin-academic-panel");
  };
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <DynamicMainScreen
          screenTitle={adminInnovativeTeachingJson.screenTitle}
          fieldMeta={adminInnovativeTeachingJson.fieldMeta}
          showPdfDownload={adminInnovativeTeachingJson.showPdfDownload}
          showExcelDownload={adminInnovativeTeachingJson.showExcelDownload}
          pdfFileName={adminInnovativeTeachingJson.pdfFileName}
          excelFileName={adminInnovativeTeachingJson.excelFileName}
          showAddButton={adminInnovativeTeachingJson.showAddButton}
          tableColumnsToFilter={
            adminInnovativeTeachingJson.tableColumnsToFilter
          }
          dynamicMasterData={dynamicMasterData}
          showSaveNextBtn={false}
          tableColumns={innovativeTeachingColumns}
          tableData={this.props.innovativeList?.innovative}
          getTableData={""}
          getTableDataById={true}
          showLoader={!this.state.showLoader}
          apiBaseURL={adminInnovativeTeachingJson.apiBaseURL}
          baseIdColumn={adminInnovativeTeachingJson.baseIdColumn}
          type={1}
          DocumentUpload={adminInnovativeTeachingJson.DocumentUpload}
          addValidation={true}
          onSearchData={this.submitHandler}
          searchList={adminInnovativeTeachingJson.searchList}
          dataSetToAdd={adminInnovativeTeachingJson.dataSetToAdd}
          showBackToListBtn={true}
          onBackToList={this.goToAdminAcademicPanel}
          getListById={this.getListById}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  innovativeList: state.innovativeTeaching,
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getActivityByType,
  getInnovative,
  getInnovativeByDate,
  getInnovativeWithoutDate,
  setInnovativeTeaching,
  getDepartment,
  getEmployeeByDeptId,
  getAdminInnovative,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminInnovativeTeaching);
