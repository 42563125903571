import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import {
  getFloor,
  getRoomsByFloor,
} from "../../Masters/TimeTable/Floor/floor.slice";
import { checkRoomAvalibility } from "./generateTimeTable.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddTimeSlot = ({
  allocationList,
  getFloor,
  getRoomsByFloor,
  checkRoomAvalibility,
  floorList,
  handleCloses,
  timeSlot,
  day,
  onSave,
  editData,
  getAlert,
}) => {
  const [open, setOpen] = React.useState(true);
  const [floorNo, setFloorNo] = React.useState("");
  const [roomBusy, setRoomBusy] = React.useState([]);
  const [roomNo, setRoomNo] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const handleClose = () => {
    handleCloses();
  };
  const timeSlotBusyList = [
    {
      name: "YearDetailName",
      title: "Year details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      // width: "10%",
    },
    {
      name: "divisionName",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "batchName",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "subject",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "timeSlot",
      title: "Time Slot",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "day",
      title: "Day",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  const timeSlotList = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "timeSlotName",
      title: "Time Slot",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "dayName",
      title: "Day",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name == "floorNo") {
        setRoomNo("");

        let dayIdData = [];
        let timeSlotIdData = [];
        allocationList.map((allocationData) => {
          timeSlotIdData.push(allocationData.timeSlotId);
          dayIdData.push(allocationData.dayId);
        });
        getRoomsByFloor({
          floorId: newValue,
          timeSlotIdData: timeSlotIdData,
          dayIdData: dayIdData,
        }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
        setFloorNo(newValue);
      } else if (name == "roomNo") {
        setRoomNo(newValue);
        const allocationListData = allocationList.map((allocationData) => {
          let allocation = {
            timeSlotId: allocationData.timeSlotId,
            day: allocationData.dayId,
          };
          return allocation;
        });
        setRoomBusy([]);
        const response = apiPost({
          url:
            endpoints.timeTableDetails +
            "/check-room-availability?roomId=" +
            newValue,
          postBody: allocationListData,
        }).then(({ data, success }) => {
          if (success) {
            const content = data.data;
            // let index = 0;
            // let row = [];
            content.map((data1) => {
              // const rowFilter = row.filter(
              //   (rowData) => rowData.timeSlotId === data1.timeSlot.id&& rowData.dayId === data1.day
              // );
              // if (rowFilter.length === 0) {
              const dayList = allocationList.filter(
                (rowData) => rowData.dayId === data1.day
              );
              let bData = {
                YearDetailName:
                  data1.batch.division.getYearDetailId.year.programType.name +
                  " - " +
                  data1.batch.division.getYearDetailId.programTitle.brName +
                  " - " +
                  data1.batch.division.getYearDetailId.year.className,
                divisionName: data1.batch.division.name,
                batchName:
                  data1.batch.batchName + " (" + data1.batch.shortName + " )",
                subject:
                  data1.subject.name +
                  " - " +
                  data1.subjectDetail.schemeHeadId.shortName,
                timeSlot:
                  data1.timeSlot.fromTime + " - " + data1.timeSlot.toTime,
                day: dayList.length !== 0 && dayList[0].dayName,
                dayId: data1.day,

                timeSlotId: data1.timeSlot.id,
              };
              // let rowList = [...roomBusy, bData];
              // row.push(bData);
              setRoomBusy((messages) => [...messages, bData]);
              // }

              return data1;
            });
            return success;
          }
          return success;
        });
      }
    } else if (name == "floorNo") {
      setFloorNo("");
    } else if (name == "roomNo") {
      setRoomNo("");
    }
  };
  useEffect(() => {
    getFloor().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    if (Object.keys(editData).length != 0) {
      setFloorNo(editData.floorNo);
      setRoomNo(editData.roomNo);
    }
  }, []);
  const submitHandler = () => {
    let timeTableDetails = [];
    if (Object.keys(editData).length == 0) {
      timeTableDetails = allocationList.map((timeTableDetailData) => {
        let timeTableDetail = {
          floorNo: floorNo,
          roomNo: roomNo,
          day: timeTableDetailData.dayId,
          timeSlot: timeTableDetailData.timeSlotId,
          id: 0,
        };
        return timeTableDetail;
      });
    }
    if (Object.keys(editData).length !== 0) {
      timeTableDetails = {
        floorNo: floorNo,
        roomNo: roomNo,
        day: editData.day,
        timeSlot: editData.timeSlot,
        id: editData.id,
      };
    }
    if (checkValidation()) {
      onSave(timeTableDetails);
    }
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (floorNo === "" || floorNo === null) {
      formIsValid = false;
      formErrors["floorNoError"] = myConstClass.floorMsg;
    }

    if (roomNo === "" || roomNo === null) {
      formIsValid = false;
      formErrors["roomNoError"] = myConstClass.roomMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Time Slot
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {roomBusy.length !== 0 &&
                      roomBusy.map((roomData) => {
                        return (
                          <>
                            {/* <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, color: "red" }}
                              label={
                                "Year Detail : " +
                                roomData.YearDetailName +
                                "Division: " +
                                roomData.divisionName +
                                "Batch: " +
                                roomData.batchName +
                                "Subject: " +
                                roomData.batchName +
                                "Time Slot: " +
                                roomData.timeSlot +
                                " And Day : " +
                                roomData.day
                              }
                            />
                            <br /> */}
                          </>
                        );
                      })}
                    {roomBusy.length !== 0 && (
                      <DynamicTable
                        data={roomBusy}
                        tableHead={timeSlotBusyList}
                        showPegination={false}
                        isActionColActive={false}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}></Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Floor Number  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <AutoComplete
                      keyColName={"id"}
                      value={floorNo}
                      name={"floorNo"}
                      options={floorList.floor.filter(
                        (row) => row.isActive == 1
                      )}
                      onChange={ChangeHandlerSearch}
                      isError={formErrors.floorNoError ? true : false}
                      errorText={
                        formErrors.floorNoError ? formErrors.floorNoError : " "
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Room Number  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <AutoComplete
                      keyColName={"id"}
                      value={roomNo}
                      name={"roomNo"}
                      options={floorList.room}
                      onChange={ChangeHandlerSearch}
                      isError={formErrors.roomNoError ? true : false}
                      errorText={
                        formErrors.roomNoError ? formErrors.roomNoError : " "
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    rowSpacing={2}
                    container
                    columnSpacing={1}
                    justifyContent="right"
                  >
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                        onClick={handleClose}
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Submit"
                        onClick={submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <DynamicTable
                    data={allocationList}
                    tableHead={timeSlotList}
                    showPegination={false}
                    isActionColActive={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  floorList: state.floor,
  departments: state.department,
  timeTableList: state.timeTable,
  workloadAllocList: state.workloadAllocate,
});

const mapDispatchToProps = {
  getFloor,
  getRoomsByFloor,
  getAlert,
  checkRoomAvalibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTimeSlot);
