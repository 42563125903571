import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { getStudentById } from "../BasicDetailsPost/basicDetails.slice";
import { showNotification } from "../Landing/Landing.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getClass,
  getAdmittedStudentByPreviousId,
} from "../Masters/OnlineModule/Class/class.slice";

import { apiPost, apiGet } from "../../utils/api_service";
import endpoints from "../../config/endpoints";
import {
  checkPrn,
  getStudentList,
  resetError,
  saveStudentList,
  updateAdmission,
} from "./Promotional.slice";
import * as myConstClass from "../../config/messageconstant";
import Divider from "@mui/material/Divider";
import { ButtonCompo } from "../../components/Comman/Button";
import { getAdmissionStatus } from "../Masters/Admission/AdmissionStatus/admissionStatus.slice";
import swal from "sweetalert";
class CurrentPreviousDetails extends React.Component {
  state = {
    id: 0,
    currentAcademicYear: "",
    previousAcademicYear: "",
    currentYearDetails: "",
    previousYearDetails: "",
    previousStudentName: "",
    previousAdmissionStatus: "",
    previousAdmissionDate: "",
    scholarshipApplicable: "0",
    scholarshipType: "",
    admissionStatus: "",
    admissionDate: new Date(),
    scholarshipFormSubmit: "",
    academicYear: "",
    userRegistrationId: "",
    admissionRegistration: "",
    formErrors: [],
    admissionRegistrationDetailId: "",
    isButtonDisabled: false, // Set the initial state of the flag
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  changedHandler = (event) => {
    this.setState({ scholarshipApplicable: event.target.value });
    if (event.target.value === "0") {
      this.setState({ scholarshipType: "0", scholarshipFormSubmit: "0" });
    }
  };

  scholarshipFormSubmitChangedHandler = (event) => {
    this.setState({ scholarshipFormSubmit: event.target.value });
  };

  dropDownChangeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    const { getClass, applicantAdmissionList, getAdmittedStudentByPreviousId } =
      this.props;
    console.log(applicantAdmissionList);
    getClass().then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });

    // this.props.getAdmittedStudentByPreviousId( { yearId: this.state.yearDetail } ).then( ( response ) =>
    // {
    //     if ( !response )
    //     {
    //         this.props.getAlert( {
    //             message: "Something went wrong",
    //         } );
    //     }
    // } );

    this.props.getAdmissionStatus().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.applicantAdmissionList !== nextProps.applicantAdmissionList
    ) {
      if (
        this.props.applicantAdmissionList.applicantAdmission !==
        nextProps.applicantAdmissionList.applicantAdmission
      ) {
        const admissionDateArr =
          nextProps.applicantAdmissionList?.applicantAdmission.admissionDate ==
          null
            ? ""
            : nextProps.applicantAdmissionList?.applicantAdmission.admissionDate.split(
                "-"
              );
        const admissionDt =
          admissionDateArr == ""
            ? ""
            : new Date(
                admissionDateArr[2],
                admissionDateArr[1] - 1,
                admissionDateArr[0]
              );
        this.setState({
          admissionRegistrationDetailId:
            nextProps.applicantAdmissionList?.applicantAdmission.id,
          previousAcademicYear:
            nextProps.applicantAdmissionList?.applicantAdmission
              .academicYearName,
          admissionRegistration:
            nextProps.applicantAdmissionList?.applicantAdmission
              .admissionRegistration,
          academicYear:
            nextProps.applicantAdmissionList?.applicantAdmission.academicYearId,
          presentYearDetails:
            nextProps.applicantAdmissionList?.applicantAdmission.yearDetailName,
          previousStudentName:
            nextProps.applicantAdmissionList?.applicantAdmission.studentName,
          previousStudentName:
            nextProps.applicantAdmissionList?.applicantAdmission.studName,
          yearDetail:
            nextProps.applicantAdmissionList?.applicantAdmission.yearDetailId,
          scholarshipApplicable:
            nextProps.applicantAdmissionList?.applicantAdmission
              .scholarshipApplicable,
          previousAdmissionStatus:
            nextProps.applicantAdmissionList?.applicantAdmission
              .admissionStatusName,
          scholarshipFormSubmit:
            nextProps.applicantAdmissionList?.applicantAdmission
              .scholarshipApplicable === "0"
              ? "0"
              : nextProps.applicantAdmissionList?.applicantAdmission
                  .scholarshipFormSubmit,
          scholarshipType:
            nextProps.applicantAdmissionList?.applicantAdmission
              .scholarshipApplicable === "0"
              ? "0"
              : nextProps.applicantAdmissionList?.applicantAdmission
                  .scholarshipType,
          userRegistrationId:
            nextProps.applicantAdmissionList?.applicantAdmission
              .userRegistrationId,

          // admissionStatus:
          //     nextProps.applicantAdmissionList?.applicantAdmission
          //         .admissionStatus,
          previousAdmissionDate: admissionDt,
        });
        // this.props
        //     .getStudentList( {
        //         academicId:
        //             nextProps.applicantAdmissionList?.applicantAdmission
        //                 .academicYearId,
        //     } )
        //     .then( ( response ) =>
        //     {
        //         if ( !response )
        //         {
        //             this.props.getAlert( {
        //                 message: "Something went wrong",
        //             } );
        //         }
        //     } );

        this.props
          .getAdmittedStudentByPreviousId({
            yearId:
              nextProps.applicantAdmissionList?.applicantAdmission.yearDetailId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: "Something went wrong",
              });
            }
          });
      }
    }
  }

  onSave = (docs) => {
    const { admissionRegistration, admissionRegistrationDetailId } = this.state;
    console.log(admissionRegistrationDetailId);
    const { showNotification } = this.props;
    apiGet({
      url:
        endpoints.admissionList +
        "/save-transfer-admission?admissionRegistrationDetailId=" +
        admissionRegistrationDetailId,
    } ).then( ( { success } ) =>
    {
      // alert("success in success if") 
      if ( success )
      {
        showNotification( { msg: "Data Updated Successfully" } );
        this.props.onClosed();
        apiPost({
          url: endpoints.admissionList,
          postBody: docs,
        } ).then( ( { success } ) =>
        {
          if (success) {
            showNotification({
              msg: "Data saved successfully",
            });
            let msg = "Data saved successfully";
            this.props.onClosed(msg);
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  handleFormValidation() {
    const { currentYearDetails, admissionDate, admissionStatus } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (currentYearDetails === "" || currentYearDetails === null) {
      formIsValid = false;
      formErrors["yearDetailsError"] = myConstClass.yearDetailMsg;
    }
    if (admissionDate === "" || admissionDate === null) {
      formIsValid = false;
      formErrors["admissionDateError"] = "*Admission date required";
    }

    if (admissionStatus === "" || admissionStatus === null) {
      formIsValid = false;
      formErrors["admissionStatusError"] = myConstClass.admissionStatusMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
   


    
    if ( this.handleFormValidation() )
    {

      
      this.setState( {
        isButtonDisabled: true
      })
      const {
        academicYear,
        admissionRegistration,
        yearDetail,
        scholarshipApplicable,
        scholarshipType,
        scholarshipFormSubmit,
        admissionStatus,
        admissionDate,
        feeStructuredHeaderId,
        totalApplicableFees,
        paidStatus,
        totalfeesPaid,
        currentYearDetails,
        userRegistrationId,
      } = this.state;

      // const newAdmissionStatus = this.props.admissionStatusList?.admissionStatus.filter( ( row ) =>
      // {
      //     return row.id === admissionStatus
      // } )

      let row = [];
      let admissionDateChange = moment(admissionDate).format("DD-MM-YYYY");
      let promAdmissionData = {
        yearDetailId: currentYearDetails,
        scholarshipApplicable: scholarshipApplicable,
        scholarshipType: scholarshipType,
        admissionStatus: admissionStatus,
        scholarshipFormSubmit: scholarshipFormSubmit,
        admissionDate: admissionDateChange,
        feesStructureHeaderId: "",
        totalApplicableFees: "",
        paidStatus: "",
        totalFeesPaid: "",
        claimNo: 0,
        isActive: 1,
      };
      row.push(promAdmissionData);
      let docs = {
        id: admissionRegistration,
        isActive: 1,
        academicYearId: academicYear,
        // academicYearId: "e8fee486-ff04-4514-99b8-1b598fe4a4fd",
        userRegistrationId: userRegistrationId,
        admissionRegistrationDetails: row,
        setAdmissionFeesDetails: [],
      };

      // console.log(docs);
      swal({
        title: "Are you sure?",
        text: "Do you want to submit?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if ( res )
        {
             
          this.onSave( docs );
        } else
        { 
          this.setState( {
            isButtonDisabled:false
          })
        }
      });

    }
  };

  changeAdmission = (date) => {
    if (date !== null) {
      this.setState({
        admissionDate: date,
      });
    }
  };

  render() {
    const {
      currentAcademicYear,
      currentYearDetails,
      presentYearDetails,
      previousAcademicYear,
      previousStudentName,
      previousAdmissionStatus,
      previousAdmissionDate,
      scholarshipApplicable,
      scholarshipType,
      admissionStatus,
      admissionDate,
      scholarshipFormSubmit,isButtonDisabled
    } = this.state;
    const { yearDetailsError, admissionDateError, admissionStatusError } =
      this.state.formErrors;
    const { applicantAdmissionList } = this.props;

    return (
      <>
        <Grid container justifyContent="space-evenly" direction="row">
          <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Transfer Admission Form"
            />
          </Grid>

          <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 20 }}
              label="Previous Details"
            />
          </Grid>

          <Grid
            container
            columnSpacing={1}
            justifyContent="right"
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Academic Year"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                color="primary"
                name="previousAcademicYear"
                size="small"
                value={previousAcademicYear}
                onChange={this.changedHandler}
                fullWidth
                disabled={true}
                helperText={" "}
              />
            </Grid>
          </Grid>

          <Grid
            container
            columnSpacing={1}
            justifyContent="right"
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Year Detail"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                color="primary"
                name="presentYearDetails"
                size="small"
                value={presentYearDetails}
                onChange={this.changedHandler}
                fullWidth
                disabled={true}
                helperText={" "}
              />
            </Grid>
          </Grid>

          <Grid
            container
            columnSpacing={1}
            justifyContent="right"
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Student Name"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                color="primary"
                name="previousStudentName"
                size="small"
                value={previousStudentName}
                onChange={this.changedHandler}
                fullWidth
                disabled={true}
                helperText={" "}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={2}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              columnSpacing={2}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Admission Status"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="previousAdmissionStatus"
                  size="small"
                  value={previousAdmissionStatus}
                  onChange={this.changedHandler}
                  fullWidth
                  disabled={true}
                  helperText={" "}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Admission Date"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <BasicDatePicker
                  name="previousAdmissionDate"
                  value={previousAdmissionDate}
                  onChange={this.changeAdmission}
                  disabled
                  // isError={admissionDateError ? true : false}
                  // errorText={admissionDateError ? admissionDateError : " "}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider />

          <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 20 }}
              label="Current Details"
            />
          </Grid>

          <Grid
            container
            columnSpacing={1}
            justifyContent="right"
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="*Year Detail"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              {/* <AutoComplete
                                keyColName={"id"}
                                value={currentYearDetails}
                                name={"currentYearDetails"}
                                options={this.props.classList?.class.filter( ( data ) => data.isActive == 1 )}
                                onChange={this.ChangeHandlerSearch}
                                isError={yearDetailsError ? true : false}
                                errorText={yearDetailsError ? yearDetailsError : " "}
                            /> */}
              <AutoComplete
                keyColName={"id"}
                value={currentYearDetails}
                name={"currentYearDetails"}
                options={this.props.classList?.yearDetailsTransfer.filter(
                  (data) => data.isActive == 1
                )}
                onChange={this.ChangeHandlerSearch}
                isError={yearDetailsError ? true : false}
                errorText={yearDetailsError ? yearDetailsError : " "}
              />
            </Grid>
          </Grid>

          {/*  */}

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={3}
              container
              sx={{ mb: 2 }}
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={3}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Scholarship Applicable"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={9}>
                <FormControl component="fieldset" sx={{ m: -1 }}>
                  <RadioGroup
                    row
                    aria-label="scholarship"
                    id="scholarshipApplicable"
                    name="scholarshipApplicable"
                    value={scholarshipApplicable}
                    size="small"
                    onChange={this.changedHandler}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            {scholarshipApplicable === "1" && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={3}
                  rowSpacing={3}
                  container
                  justifyContent="space-evenly"
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Scholarship Type"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={4}>
                    <FormControl color={"primary"} fullWidth={true}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="scholarshipType"
                        id="scholarshipType"
                        value={scholarshipType}
                        onChange={this.dropDownChangeHandler}
                        size="small"
                        fullWidth={true}
                      >
                        <MenuItem value={0}>No</MenuItem>
                        <MenuItem value={1}>EBC</MenuItem>
                        <MenuItem value={2}>Freeship</MenuItem>
                        <MenuItem value={3}>Scholarship</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Scholarship Form Submit"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <FormControl component="fieldset" sx={{ m: -1 }}>
                      <RadioGroup
                        row
                        aria-label="scholarship"
                        id="scholarshipFormSubmit"
                        name="scholarshipFormSubmit"
                        value={scholarshipFormSubmit}
                        size="small"
                        onChange={this.scholarshipFormSubmitChangedHandler}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={2}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Admission Status"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <AutoComplete
                    keyColName={"id"}
                    value={admissionStatus}
                    name={"admissionStatus"}
                    options={this.props.admissionStatusList?.admissionStatus.filter(
                      (data) => data.isActive == 1 && data.id != "6"
                    )}
                    onChange={this.ChangeHandlerSearch}
                    isError={admissionStatusError ? true : false}
                    errorText={
                      admissionStatusError ? admissionStatusError : " "
                    }
                  />
                  {/* <FormControl color={"primary"} fullWidth={true}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            className="text-black"
                                            name="admissionStatus"
                                            id="admissionStatus"
                                            value={admissionStatus}
                                            onChange={this.dropDownChangeHandler}
                                            size="small"
                                            fullWidth={true}
                                        >
                                            <MenuItem value={1}>Confirmed</MenuItem>
                                            <MenuItem value={2}>Year Drop</MenuItem>
                                            <MenuItem value={3}>Cancelled</MenuItem>
                                            <MenuItem value={4}>Detained</MenuItem>
                                            <MenuItem value={5}>Provisional</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormHelperText error>{admissionStatusError ? admissionStatusError : ' '}</FormHelperText> */}
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Admission Date"
                  />
                </Grid>

                <Grid sx={{ ml: -2 }} item xs={12} sm={12} md={8}>
                  <BasicDatePicker
                    name="castecertdate"
                    value={admissionDate}
                    onChange={this.changeAdmission}
                    isError={admissionDateError ? true : false}
                    errorText={admissionDateError ? admissionDateError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container columnSpacing={1} justifyContent="right">
            <Grid
              item
              sm={12}
              xs={12}
              md={1.1}
              lg={1.1}
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Cancel"
                fullWidth={true}
                onClick={(e) => {
                  this.props.onClosed("");
                }}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={1.5}
              lg={1.5}
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Save"
                fullWidth={true}
                onClick={this.submitHandler}
                disable={isButtonDisabled} 
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

// yearDetailsTransfer: [],

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
  academicYearList: state.academicYear,
  classList: state.class,
  applicantAdmissionList: state.applicantAdmission,
  studentListPromotional: state.promotionalAdmission,
  admissionStatusList: state.admissionStatus,
});

const mapDispatchToProps = {
  getStudentById,
  getClass,
  getAcademicYear,
  getStudentList,
  updateAdmission,
  saveStudentList,
  showNotification,
  getAlert,
  resetError,
  checkPrn,
  getAdmissionStatus,
  getAdmittedStudentByPreviousId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentPreviousDetails);
