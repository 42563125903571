import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import {
  getProfile,
  getStudentById,
  getStudentInfoByPRNNo,
} from "../../BasicDetailsPost/basicDetails.slice";
import { downloadFile, viewMultipleFile } from "../../UploadFile/file.slice";
import { getAlumniRegistration } from "../StudentFillProfile/studentFillProfile.slice";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const DeclarationPreview = ({
  alumini,
  GoBack,
  getAlumniRegistration,
  studentFillProfileList,
}) => {
  const [role, setRole] = useState(true);

  useEffect(() => {
    let role = localStorage.getItem("role");
    console.log(role);
    setRole(role);
    getAlumniRegistration().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const editProfile = () => {
    window.location.replace("/student-fill-profile");
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <div align="right">
          {role !== "alumni" ? (
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Back"
              onClick={GoBack}
            />
          ) : (
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Edit"
              onClick={editProfile}
            />
          )}
        </div>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  icon={<InfoIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Basic Details"
                  value="1"
                />
                <Tab
                  icon={<HomeIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Address Details"
                  value="2"
                />
                <Tab
                  icon={<HomeIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Work experience"
                  value="6"
                />
                <Tab
                  icon={<DocumentScannerIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Social Profile"
                  value="3"
                />
                <Tab
                  icon={<DocumentScannerIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Qualification Details"
                  value="4"
                />

                <Tab
                  icon={<PhotoSizeSelectActualIcon />}
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Achievement"
                  value="5"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Step1
                data={
                  role !== "alumni"
                    ? alumini.aluminiById
                    : studentFillProfileList.applicant
                }
              />
            </TabPanel>
            <TabPanel value="2">
              <Step2
                data={
                  role !== "alumni"
                    ? alumini.aluminiById
                    : studentFillProfileList.applicant
                }
              />
            </TabPanel>
            <TabPanel value="3">
              <Step3
                data={
                  role !== "alumni"
                    ? alumini.aluminiById
                    : studentFillProfileList.applicant
                }
              />
            </TabPanel>

            <TabPanel value="4">
              <Step4
                data={
                  role !== "alumni"
                    ? alumini.aluminiById
                    : studentFillProfileList.applicant
                }
              />
            </TabPanel>
            <TabPanel value="5">
              <Step5
                data={
                  role !== "alumni"
                    ? alumini.aluminiById
                    : studentFillProfileList.applicant
                }
              />
            </TabPanel>
            <TabPanel value="6">
              <Step6
                data={
                  role !== "alumni"
                    ? alumini.aluminiById
                    : studentFillProfileList.applicant
                }
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  alumini: state.alumini,
  studentFillProfileList: state.studentFillProfile,
});

const mapDispatchToProps = {
  viewMultipleFile,
  downloadFile,
  getProfile,
  getAlert,
  getStudentById,
  getStudentInfoByPRNNo,
  getAlumniRegistration,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationPreview);
