import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  document: [],
  isDataFetch: false,
};
let URL = endpoints.document;
const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    documentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        document: row,
        isDataFetch: true,
      };
    },
  },
});

export const { documentSuccess } = documentSlice.actions;

export default documentSlice.reducer;

export const getDocument = () => async (dispatch) => {
  try {
    const response=    apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(documentSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
