import React from "react";
import "./ControlIcons.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { FastRewind } from "@mui/icons-material";
import { FastForwardSharp } from "@mui/icons-material";
import { PlayArrowSharp } from "@mui/icons-material";
import { PauseSharp } from "@mui/icons-material";
import { VolumeUp } from "@mui/icons-material";
import { VolumeOff } from "@mui/icons-material";
import { Fullscreen } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";

const ControlIcons = ({
  playandpause,
  playing,
  rewind,
  fastForward,
  muting,
  muted,
  volumeChange,
  volumeSeek,
  volume,
  playRate,
  playerbackRate,
  fullScreenMode,
  onSeek,
  played,
  onSeekMouseUp,
  onSeekMouseDown,
  fullMovieTime,
  playedTime,
  // onMouseLeave,
  // onMouseEnter,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [display, setDisplay] = React.useState(true);
  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMouseEnter = () => {
    setDisplay(true);
  };

  const onMouseLeave = () => {
    // const timer = setTimeout(() => {
    //   console.log("TsetDisplay(!display);");
    // setDisplay(false);
    // }, 3000);
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 1 second!");
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "playbackrate-popover" : undefined;

  function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
      <Tooltip enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  const PrettoSlider = styled(Slider)({
    height: 5,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 16,
      width: 16,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: "#52af77",
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
    minIcon: {
      color: "white !important",
    },
  });

  return (
    <div
      className="controls__div"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* Top Controls */}
      {display && (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="start"
            style={{ padding: 16 }}
          >
            <Grid item>
              {/* <Typography variant='h5' style={{color:'white'}}>Player</Typography> */}
            </Grid>
          </Grid>

          {/* Middle Controls */}

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
          ></Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ padding: 16 }}
          >
            {/* <Grid item>
                <Typography variant='h5' style={{color:'white'}}>Tears Of Steel</Typography>
              </Grid> */}

            <Grid item xs={12}>
              <PrettoSlider
                min={0}
                max={100}
                value={played * 100}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
                valueLabelDisplay="auto"
                // aria-label="custom thumb label"
                components={{
                  ValueLabel: ValueLabelComponent,
                }}
              />
              <Grid container direction="row" justifyContent="space-between">
                <Typography style={{ color: "white", fontSize: "small" }}>
                  {playedTime + "/" + fullMovieTime}
                </Typography>

                <IconButton
                  className="controls__icons"
                  aria-label="reqind"
                  onClick={rewind}
                >
                  <FastRewind fontSize="md" style={{ color: "white" }} />
                </IconButton>

                <IconButton
                  className="controls__icons"
                  aria-label="reqind"
                  onClick={playandpause}
                >
                  {playing ? (
                    <PauseSharp fontSize="md" style={{ color: "white" }} />
                  ) : (
                    <PlayArrowSharp fontSize="md" style={{ color: "white" }} />
                  )}
                </IconButton>

                <IconButton
                  className="controls__icons"
                  aria-label="reqind"
                  onClick={fastForward}
                >
                  <FastForwardSharp fontSize="md" style={{ color: "white" }} />
                </IconButton>
                <IconButton sx={{ color: "white" }} onClick={fullScreenMode}>
                  <Fullscreen fontSize="md" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ControlIcons;
