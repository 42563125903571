import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import "../../CSS/paymentPopup.css";
import { connect } from "react-redux";
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class TestPayCode extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    value: "",
    key: "",
    txnid: "",
    amount: "",
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    hashcode: "",
    surl: "",
    userRegId: "",
  };
  handleCloses = () => {
    this.props.onClose();
  };
  handleClose = () => {
    this.props.onClose();
  };

  postMethode = () => {
    console.log("in post.......");
    const {
      key,
      txnid,
      amount,
      firstname,
      mobile,
      hashcode,
      lastname,
      email,
      surl,
      userRegId,
    } = this.state;
    let docs = {
      userRegistrationId: userRegId,
      firstName: firstname,
      lastName: lastname,
      email: email,
      mobileNo: mobile,
      txnId: txnid,
      amount: amount,
      paymentReturnStatus: "",
      paymentReturnData: "",
      mihpayid: "",
      productInfo: "College Fee",
    };
    apiPost({
      url: endpoint.paymentUrl + "-transaction",
      postBody: docs,
    }).then(({ data, success }) => {
      if (success) {
      }
    });
  };
  componentDidMount() {
    let userId = localStorage.getItem("userRegistrationId");

    const { dataToSend, firstName, lastName, mobileNo, emailId } = this.props;
    let url = window.location.origin;

    this.setState({
      key: dataToSend.key,
      txnid: dataToSend.merchantTxnNo,
      amount: dataToSend.amount,
      firstname: firstName,
      lastname: lastName,
      mobile: mobileNo,
      email: emailId,
      hashcode: dataToSend.hash,
      surl: "",
      userRegId: userId,
    });
  }

  render() {
    const {
      key,
      txnid,
      amount,
      firstname,
      mobile,
      hashcode,
      lastname,
      email,
      surl,
      userRegId,
      handleClose,
    } = this.state;
    const { dataToSend, firstName, lastName, mobileNo, emailId } = this.props;

    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="sm"
          >
            <DialogContent>
              <div class="form-style-5">
                <form>
                  <fieldset>
                    <legend>Transaction Details</legend>
                    <lable>First Name</lable>
                    <input
                      type="text"
                      name="firstName"
                      disabled
                      value={firstName}
                    />
                    <lable>Last Name</lable>
                    <input
                      type="text"
                      name="lastName"
                      disabled
                      value={lastName}
                    />
                    <lable>Email</lable>
                    <input type="text" name="email" disabled value={emailId} />
                    <lable>Mobile</lable>
                    <input type="text" name="phone" disabled value={mobileNo} />
                    <label>Amount</label>
                    <input type="text" name="amount" disabled value={amount} />
                  </fieldset>
                </form>
                {/* https://qa.phicommerce.com/pg/api/sale?v=2 */}
                <form
                  id="myForm"
                  name="myForm"
                  action="https://secure-ptg.payphi.com/pg/api/sale?v=2"
                  method="post"
                  enctype="application/x-www-form-urlencoded"
                >
                  <fieldset>
                    <input
                      type="hidden"
                      name="merchantID"
                      value={dataToSend.merchantId}
                    />
                    <input
                      type="hidden"
                      name="merchantTxnNo"
                      value={dataToSend.merchantTxnNo}
                    />
                    <input
                      type="hidden"
                      name="amount"
                      value={dataToSend.amount}
                    />
                    <input
                      type="hidden"
                      name="currencyCode"
                      value={dataToSend.currencyCode}
                    />
                    <input type="hidden" name="payType" value="" />
                    <input type="hidden" name="paymentMode" value="" />
                    <input type="hidden" name="customerEmailID" value="" />
                    <input type="hidden" name="transactionType" value="" />
                    <input type="hidden" name="paymentOptionCodes" value="" />
                    <input
                      type="hidden"
                      name="txnDate"
                      value={dataToSend.txnDate}
                    />
                    <input type="hidden" name="cardNo" value="" />
                    <input type="hidden" name="cardExpiry" value="" />
                    <input type="hidden" name="nameOnCard" value="" />
                    <input type="hidden" name="cvv" value="" />
                    <input type="hidden" name="customerUPIAlias" value="" />
                    <input
                      type="hidden"
                      name="aggregatorID"
                      value={dataToSend.aggregatorId}
                    />
                    <input type="hidden" name="remarks" value="" />
                    <input type="hidden" name="customerName" value="" />
                    <input type="hidden" name="customerMobileNo" value="" />
                    <input type="hidden" name="invoiceNo" value="" />
                    <input
                      type="hidden"
                      name="returnURL"
                      value={dataToSend.returnURL}
                    />
                    <input type="hidden" name="addlParam1" value="" />
                    <input
                      type="hidden"
                      name="allowDisablePaymentMode"
                      value=""
                    />
                    <input type="hidden" name="addlParam2" value="" />
                    <input type="hidden" name="secureHash" value={hashcode} />
                    <p id="hashtext"></p>
                  </fieldset>
                  <input
                    type="submit"
                    name="submitbut"
                    value="Pay"
                    onClick={this.postMethode}
                  />
                  <input type="button" value="Cancel" onClick={handleClose} />
                </form>
              </div>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TestPayCode);
