export const prnNumberJson = {
    screenTitle: "PRN Number",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter:true,
   
  };
  