import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import { paperPosterJson } from "../../../../../DynamicFormsJson/PaperPosterPresented";
import { paperPosterColumns } from "../../../../../tableColumns/table-columns";
import {
  getResourcePersonByType,
  setPublicationData,
} from "../ResourcePerson/resourcePerson.slice";

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      dynamicMasterData: {
        LevelMaster: [
          { id: "International", name: "International" },
          { id: "National", name: "National" },
          { id: "State", name: "State" },
          { id: "University", name: "University" },
          { id: "Inhouse", name: "Inhouse" },
          { id: "Intercollegiate", name: "Intercollegiate" },
          { id: "other", name: "Other" },
        ],
        typeMaster: [
          { id: "Paper", name: "Paper" },
          { id: "Poster", name: "Poster" },
        ],
      },
    };
  }

  componentDidMount() {
    const { getResourcePersonByType } = this.props;
    this.setState({
      showLoader: true,
    });
    getResourcePersonByType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
        this.setState({
          showLoader: false,
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  render() {
    const { dynamicMasterData, showLoader } = this.state;
    const { resourcePersonList, getActivity } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={paperPosterJson.screenTitle}
            fieldMeta={paperPosterJson.fieldMeta}
            showPdfDownload={paperPosterJson.showPdfDownload}
            showExcelDownload={paperPosterJson.showExcelDownload}
            pdfFileName={paperPosterJson.pdfFileName}
            excelFileName={paperPosterJson.excelFileName}
            showAddButton={paperPosterJson.showAddButton}
            tableColumnsToFilter={paperPosterJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            showSaveNextBtn={false}
            tableColumns={paperPosterColumns}
            tableData={resourcePersonList?.resourcePerson}
            getTableData={this.props.getResourcePersonByType}
            showLoader={!showLoader}
            apiBaseURL={paperPosterJson.apiBaseURL}
            baseIdColumn={paperPosterJson.baseIdColumn}
            type={1}
            DocumentUpload={paperPosterJson.DocumentUpload}
            deleteUrl={paperPosterJson.deleteUrl}
            setData={this.props.setPublicationData}
            sendEmptyId={true}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  resourcePersonList: state.resourcePerson,
});
const mapDispatchToProps = {
  getResourcePersonByType,
  getAlert,
  setPublicationData,
};
export default connect(mapStateToProps, mapDispatchToProps)(Activity);
