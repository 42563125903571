import React, { Component } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getAlert } from "../../../CommonActions/alert.slice";
import { showNotification } from "../../../Pages/Landing/Landing.slice";

import { ButtonCompo } from "../../../components/Comman/Button";
import { connect } from "react-redux";
import * as myConstClass from "../../../config/messageconstant";
import {
  getSubjectFromDeptSemester,
  getSchemeHead,
} from "../../SubjectSelection/SubjectSelection.slice";
import { LabelCompo } from "../../../components/Comman/Label";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";
class SubjectSelectionMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      columnList: [],
      matrixError: false,
    };
  }

  componentDidMount() {
    const { examDetails } = this.props;
  }

  componentWillReceiveProps(nextProps) {
    const { adminApplyList } = this.props;
    if (
      (adminApplyList?.adminSubject !== nextProps.adminApplyList.adminSubject &&
        nextProps.adminApplyList.isFetchAdminSchemeHead) ||
      (adminApplyList?.adminSchemeHead !==
        nextProps.adminApplyList.adminSchemeHead &&
        nextProps.adminApplyList.isFetchAdminSubject) ||
      (adminApplyList?.adminSubjectSchemeHead !==
        nextProps.adminApplyList.adminSubjectSchemeHead &&
        nextProps.adminApplyList.isFetchAdminSubjectSchemeHead)
    ) {
      this.createMatrixData(
        nextProps.adminApplyList?.adminSubject,
        nextProps.adminApplyList?.adminSchemeHead,
        nextProps.adminApplyList.adminSubjectSchemeHead
      );
    }
  }

  createMatrixData(adminSubject, adminSchemeHead, adminSubjectSchemeHead) {
    const subjectMatrix = adminSubject.map((subjectData) => {
      const schemeHeadList = adminSchemeHead.map((schemeHeadData) => {
        const filteredList = adminSubjectSchemeHead.filter(
          (rowData) =>
            rowData.schemeHeadId === schemeHeadData.id &&
            rowData.subjectId === subjectData.subjectId
        );
        if (subjectData.shortName === "comp") {
          schemeHeadData = {
            ...schemeHeadData,
            value:
              subjectData.shortName === "comp"
                ? true
                : this.props.examListData.declareResult == 2
                ? true
                : false,
            checkBox: filteredList.length === 0 ? false : true,
            subjectDetailId:
              filteredList.length !== 0 ? filteredList[0].id : "",
          };
          return schemeHeadData;
        } else {
          schemeHeadData = {
            ...schemeHeadData,
            value:
              subjectData.shortName === "comp"
                ? true
                : this.props.examListData.declareResult == 2
                ? true
                : false,
            checkBox: filteredList.length === 0 ? false : true,
            subjectDetailId:
              filteredList.length !== 0 ? filteredList[0].id : "",
          };
          return schemeHeadData;
        }
      });
      subjectData = {
        ...subjectData,
        value:
          subjectData.shortName === "comp"
            ? true
            : this.props.examListData.declareResult == 2
            ? true
            : false,
        schemeHeadList: schemeHeadList,
      };
      return subjectData;
    });

    this.setState({
      matrixData: subjectMatrix,
    });
  }

  onRowHeaderChange = (subjectId, e) => {
    console.log(subjectId);
    let { matrixData } = this.state;
    const subjectMatrix = matrixData.map((subjectData) => {
      if (subjectData.id === subjectId) {
        const schemeHeadList = subjectData.schemeHeadList.map(
          (schemeHeadData) => {
            schemeHeadData = {
              ...schemeHeadData,
              value: schemeHeadData.checkBox ? e.target.checked : false,
            };
            return schemeHeadData;
          }
        );
        subjectData = {
          ...subjectData,
          value: e.target.checked,
          schemeHeadList: schemeHeadList,
        };
        return subjectData;
      }

      return subjectData;
    });
    this.setState({ matrixData: subjectMatrix });
  };
  submitHandler = (e) => {
    e.preventDefault();

    let { matrixData } = this.state;
    console.log(matrixData);
    let subjectlist = [];
    let subjectCount = 0;
    matrixData.map((columnRowData) => {
      if (columnRowData.value) {
        columnRowData.schemeHeadList.map((subDetails) => {
          if (subDetails.checkBox && subDetails.value) {
            let sub = {
              subjectDetail: {
                id: subDetails.subjectDetailId,
              },
              grade: "",
              result: "",
              isActive: 1,
            };

            subjectlist.push(sub);
          }
        });
      }
    });
    console.log(subjectlist);

    subjectCount =
      this.props.adminApplyList?.adminSubject.length *
      this.props.examListData.examFeesStructurePerSubject;
    console.log(subjectCount);
    if (subjectCount > this.props.examListData.examFeesStructureMaxCapFees) {
      subjectCount = this.props.examListData.examFeesStructureMaxCapFees;
    }
    console.log(this.props.examListData);
    let columnRowData = {
      examFormDeclare: {
        id: this.props.examListData.id,
      },
      student: {
        id: this.props.studentData.id,
      },
      approvedStatus: "0",
      admissionRegistrationDetailId:
        matrixData[0].admissionRegistrationDetailId,
      applicableFees:
        this.props.examListData.examFeesStructureType === "0"
          ? this.props.examListData.examFeesStructureFixedFees
          : subjectCount,
      remark: "",
      seatNo: "",
      isRegular: this.props.examListData.declareResult,
      isActive: "1",
      studentDetailExamMarks: subjectlist,
    };
    console.log(columnRowData);

    const SaveApi = {
      examFormDeclare: {
        id: this.props.examListData.id,
      },
      student: {
        id: this.props.studentData.id,
      },
      admissionRegistrationDetailId:
        matrixData[0].admissionRegistrationDetailId,
      applicableFees:
        this.props.examListData.examFeesStructureType === "0"
          ? this.props.examListData.examFeesStructureFixedFees
          : subjectCount,
      // amount: "200",
      approvedStatus: "0",
      remark: "",
      // approvedDateTime: "2022-08-23 13:23:34",
      seatNo: "201478",
      isRegular: this.props.examListData.declareResult,
      isActive: 1,
      studentDetailExamMarks: subjectlist,
    };
    swal({
      title: "Are you sure?",
      text: "Do you want to submit data?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiPost({
          url: endpoint.studentApply,
          postBody: SaveApi,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data saved successfully",
            });
            this.props.onCloseMat();
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });

    // let { instituteProgramData } = this.props;

    // let programList = [];
    // let dataToSave = [];
    // columnList.forEach((columnRowData) => {
    //   const programData = instituteProgramData.filter(
    //     (item) => item.programTitle.brName === columnRowData
    //   )[0];
    //   programList.push({
    //     programTypeId: programData.programTitle.programType.id,
    //     programTitleId: programData.programTitle.id,
    //     brName: programData.programTitle.brName,
    //   });
    // });
    // matrixData.forEach((matrixDataRow) => {
    //   programList.forEach((programData) => {
    //     if (matrixDataRow[programData.brName]) {
    //       dataToSave.push({
    //         programTypeId: programData.programTypeId,
    //         programTitleId: programData.programTitleId,
    //         tenantId: matrixDataRow.tenantId,
    //       });
    //     }
    //   });
    // });
    // if (dataToSave.length != 0) {
    //   this.props.onSave(dataToSave);
    // } else {
    //   this.setState({
    //     matrixError: true,
    //   });
    // }
  };
  render() {
    const { matrixData } = this.state;
    const { onCloseMat, adminApplyList } = this.props;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            // m={2}
            rowSpacing={1}
            container
            justifyContent="left"
          >
            <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"Exam Title : " + this.props.examDetails.examTitle}
              />
            </Grid>

            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"Start Date : " + this.props.examDetails.startDate}
              />
            </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"End Date : " + this.props.examDetails.endDate}
              />
            </Grid>

            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"Student Name : " + this.props.studentData.studentName}
              />
            </Grid>

            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"PRN : " + this.props.studentData.prnNo}
              />
            </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"Email Id : " + this.props.studentData.studEmail}
              />
            </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"Contact No: " + this.props.studentData.studContact}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} />

            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: "#808080" }}
                label="Note: Select subject for the student"
              />
            </Grid>
          </Grid>
          {
            <TableContainer>
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    {this.props.adminApplyList?.adminSchemeHead.map(
                      (columnName, index) => {
                        return (
                          <TableCell align="center">
                            {columnName.name}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matrixData.map((matrixDataRow, indexRow) => {
                    return (
                      <TableRow>
                        <TableCell component="td" scope="row" sx={{ width: 5 }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={"Inst" + indexRow}
                                  onChange={(e) =>
                                    this.onRowHeaderChange(matrixDataRow.id, e)
                                  }
                                  id={"Inst" + indexRow}
                                  checked={matrixDataRow.value}
                                  disabled={
                                    matrixDataRow.shortName === "comp"
                                      ? true
                                      : this.props.examListData.declareResult ==
                                        2
                                      ? true
                                      : false
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label={matrixDataRow.subjectName}
                            />
                          </FormGroup>
                        </TableCell>
                        {matrixDataRow.schemeHeadList.map(
                          (columnName, index) => {
                            if (columnName.checkBox) {
                              return (
                                <TableCell align="center">
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={"Course" + index}
                                          onChange={(e) =>
                                            this.onProgramChange(
                                              matrixDataRow.name,
                                              columnName
                                            )
                                          }
                                          checked={columnName.value}
                                          disabled={true}
                                          id={"Course" + index}
                                        />
                                      }
                                      label={""}
                                      labelPlacement="bottom"
                                    />
                                  </FormGroup>
                                </TableCell>
                              );
                            }
                            return <TableCell align="center"></TableCell>;
                          }
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name=" Cancel"
                fullWidth={true}
                onClick={this.props.onCloseMat}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                fullWidth={true}
                onClick={this.submitHandler}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  adminApplyList: state.applyStudent,
});

const mapDispatchToProps = {
  getSubjectFromDeptSemester,
  getSchemeHead,

  showNotification,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectSelectionMatrix);
