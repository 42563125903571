import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { LabelCompo } from "../../components/Comman/Label";
import { apiGet } from "../../utils/api_service";
import { useEffect, useState } from "react";
import endpoints from "../../config/endpoints";
import { getAlert } from "../../CommonActions/alert.slice";
import { connect } from "react-redux";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
})

const BasicTable = ({getAlert, data,classes }) => {
  const [tempTaluka, setTempTaluka] = useState("");
  const [tempState, setTempState] = useState("");
  const [tempDistrict, setTempDistrict] = useState("");
  const [perTaluka, setPerTaluka] = useState("");
  const [perState, setPerState] = useState("");
  const [perDistrict, setPerDistrict] = useState("")
  useEffect(() => {
    if (Object.keys(data).length != 0) {
      if (perDistrict === "" && perDistrict !== null) {
        if (data.permanentDistrict !== null && data.permanentDistrict !== "") {
          setPerDistrict(null);
          apiGet({
            url: endpoints.district + "/" + data.permanentDistrict,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setPerDistrict(data.data.name);
            }
          });
        } else if (data.permanentDistrict === null || data.permanentDistrict === "") {
          setPerDistrict(" - ");
        }
      }
      if (perState === "" && perState !== null) {
        if (data.permanentState !== null && data.permanentState !== "") {
          setPerState(null);
          apiGet({
            url: endpoints.state + "/" + data.permanentState,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setPerState(data.data.name);
            }
          });
        } else if (data.permanentState === null || data.permanentState === "") {
          setPerState(" - ");
        }
      }
      if (perTaluka === "" && perTaluka !== null) {
        if (data.permanentTaluka !== null && data.permanentTaluka !== "") {
          setPerTaluka(null);
          apiGet({
            url: endpoints.taluka + "/" + data.permanentTaluka,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setPerTaluka(data.data.name);
            }
          });
        } else if (data.permanentTaluka === null || data.permanentTaluka === "") {
          setPerTaluka(" - ");
        }
      } if (tempTaluka === "" && tempTaluka !== null) {
        if (data.temporaryTaluka !== null && data.temporaryTaluka !== "") {
          setTempTaluka(null);
          apiGet({
            url: endpoints.taluka + "/" + data.temporaryTaluka,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setTempTaluka(data.data.name);
            }
          });
        } else if (data.temporaryTaluka === null || data.temporaryTaluka === "") {
          setTempTaluka(" - ");
        }
      }
      if (tempDistrict === "" && tempDistrict !== null) {
        if (data.temporaryDistrict !== null && data.temporaryDistrict !== "") {
          setTempDistrict(null);
          apiGet({
            url: endpoints.district + "/" + data.temporaryDistrict,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setTempDistrict(data.data.name);
            }
          });
        } else if (data.temporaryDistrict === null || data.temporaryDistrict === "") {
          setTempDistrict(" - ");
        }
      }  if (tempState === "" && tempState !== null) {
        if (data.temporaryState !== null && data.temporaryState !== "") {
          setTempState(null);
          apiGet({
            url: endpoints.state + "/" + data.temporaryState,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setTempState(data.data.name);
            }
          });
        } else if (data.temporaryState === null || data.temporaryState === "") {
          setTempState(" - ");
        }
      }
    }
  });
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Permanent Address"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Address{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.permanentAddressLine1 +
                " " +
                data.permanentAddressLine2 +
                " " +
                data.permanentAddressLine3}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Pin Code{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{data.permanentPin}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Taluka{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{perTaluka}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; District{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{perDistrict}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;City / Village{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.permanentCity}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; State{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{perState}
            </td>
          </tr>
        </table>
        <Grid>&nbsp;&nbsp;</Grid>
        <Grid>&nbsp;&nbsp;</Grid>
        <LabelCompo
          className="text-black"
          style={{ fontSize: 20 }}
          label="Corresponding Address"
        />
        <Grid>&nbsp;&nbsp;</Grid>

        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Address{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.temporaryAddressLine1 +
                " " +
                data.temporaryAddressLine2 +
                " " +
                data.temporaryAddressLine3}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Pin Code{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{data.temporaryPin}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Taluka{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{tempTaluka}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; District{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{tempDistrict}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;City / Village{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.temporaryCity}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; State{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{tempState}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BasicTable));