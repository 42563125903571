import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import RTTable from "../../../components/Comman/RT/RTTable";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../utils/api_service";
import {
  getfeedBackDetailsByFeedBackId,
  getfeedBackDetailId,
} from "./addFeedback.slice";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
let docs;
class EmployeeFeedbackDeclare extends Component {
  state = {
    id: 0,
    name: "",
    remark: "",
    formErrors: [],
  };
  columns = [
    { name: "index", title: "Sr. No." },
    { name: "question", title: "Question" },
    { name: "optionType", title: "Question Type" },
    { name: "feedbackHeadDetailsOptions", title: "Options" },
    { name: "action", title: "Action" },
  ];

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { name } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (name.toString().trim() == "" || name == "") {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.feedbackMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidation1() {
    const { name } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (name == "") {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.feedbackMsg;
    }
    if (this.props.feedBackDetails.feedBackDetailsByfeedBack.length === 0) {
      formIsValid = false;
      formErrors["feedbackListErr"] = "*Please add atleast one question";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    const { feedBackDetails, getfeedBackDetailsByFeedBackId } = this.props;
    if (Object.keys(feedBackDetails.feedBackById).length !== 0) {
      this.setState({
        ...this.state,
        id: feedBackDetails.feedBackById.id,
        name: feedBackDetails.feedBackById.name,
        remark: feedBackDetails.feedBackById.remark,
      });
      getfeedBackDetailsByFeedBackId({
        feedBackId: feedBackDetails.feedBackById.id,
      }).then((response) => {
        if (!response) {
          this.props.getAlert({
            message: "Server Failed",
          });
        }
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.feedBackDetails.feedBackById !=
      nextProps.feedBackDetails.feedBackById
    ) {
      this.setState({
        ...this.state,
        id: nextProps.feedBackDetails.feedBackById.id,
        name: nextProps.feedBackDetails.feedBackById.name,
        remark: nextProps.feedBackDetails.feedBackById.remark,
      });
      this.props
        .getfeedBackDetailsByFeedBackId({
          feedBackId: nextProps.feedBackDetails.feedBackById.id,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({
              message: "Server Failed",
            });
          }
        });
    }
  }
  submitHandler = (e) => {
    e.preventDefault();
    const { name, remark, id } = this.state;
    docs = {
      name: name,
      remark: remark,
      isActive: 1,
    };
    if (id !== 0) {
      docs = {
        ...docs,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.onSave(docs, true);
    }
  };

  onSave = (dataToSave, flag) => {
    apiPost({
      url: endpoint.feedBack,
      postBody: dataToSave,
    }).then(({ data, success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        if (flag) {
          this.setState({
            id: data.data.id,
          });

          this.props.addQuestions(data.data.id);

          this.props
            .getfeedBackDetailsByFeedBackId({ feedBackId: data.data.id })
            .then((response) => {
              if (!response) {
                this.props.getAlert({
                  message: "Server Failed",
                });
              }
            });
        } else {
          this.props.saveData(docs);
        }
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };
  saveHandler = () => {
    const { name, remark, id } = this.state;
    docs = {
      name: name,
      remark: remark,
      isActive: 1,
    };
    if (id !== 0) {
      docs = {
        ...docs,
        id: id,
      };
    }
    if (this.handleFormValidation()) {
      this.onSave(docs, false);
    }
    // if ( this.handleFormValidation1() )
    // {
    //   console.log( docs );
    //   this.props.saveData( docs );
    // }
  };
  rowDelete = (rowdata) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoint.feedBackDetails + "/" + rowdata.id,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({ msg: "Data deleted successfully" });
            this.props
              .getfeedBackDetailsByFeedBackId({ feedBackId: this.state.id })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({
                    message: "Server Failed",
                  });
                }
              });
          } else {
            this.props.getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };
  rowEdit = (rowdata) => {
    this.props
      .getfeedBackDetailId({ feedbackDetailId: rowdata.id })
      .then((resp) => {
        if (resp) {
          this.props.addQuestions(this.state.id);
        } else {
          this.props.getAlert({
            message: "Server Failed",
          });
        }
      });
  };
  render() {
    const { name, remark } = this.state;
    const { onCancel, feedBackDetails } = this.props;
    const { nameError, feedbackListErr } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title="Add Feedback" />
        </Paper>
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mb: 2 }} item xs={12} sm={10.5} md={10.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label=""
                  />
                </Grid>
                <Grid sx={{ mb: 2 }} item xs={12} sm={1.5} md={1.5}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Feedback Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="name"
                    size="small"
                    value={name}
                    onChange={this.changeHandler}
                    error={nameError ? true : false}
                    helperText={nameError ? nameError : " "}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    error={false}
                    helperText={" "}
                    onChange={this.changeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              rowSpacing={2}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Add Questions"
                  onClick={this.submitHandler}
                  fullWidth={true}
                />
              </Grid>
            </Grid>

            <Grid
              sx={{ mt: 2 }}
              container
              columnSpacing={1}
              justifyContent="left"
            >
              <FormHelperText error>{feedbackListErr}</FormHelperText>
              <RTTable
                columns={this.columns}
                // showAddButtonInTable={showAddButtonInTable}
                // tableData={fee}
                tableData={feedBackDetails.feedBackDetailsByfeedBack}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              sx={{ marginTop: 2 }}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                  onClick={this.saveHandler}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={1.1}
                lg={1.1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  feedBackDetails: state.feedBack,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getfeedBackDetailId,
  getfeedBackDetailsByFeedBackId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeFeedbackDeclare);
