import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getFeeStructureHeader } from "../Masters/Admission/FeeStructureHead/feeStructureHead.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class AddDebitTransaction extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    debitAmount: "",
    transactionDate: new Date(),
    rowListDetails: [],
    formErrors: [],
    type: "0",
    number: "",
    bankName: "",
    remark: "",
    debitError: "",
    debitAmountValidation: "",
  };

  componentDidMount() {
    console.log(this.props.studentData.applicantName);
    this.setState({
      debitAmountValidation: this.props.debitValidation,
    });
  }

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  changeFromDate = (date) => {
    this.setState({
      transactionDate: date,
    });
  };
  submitHandler = (e) => {
    const { debitAmount, transactionDate, type, number, bankName, remark } =
      this.state;

    let feesToSave = {
      amount: debitAmount,
      date: transactionDate,
      type: type,
      number: number,
      bankName: bankName,
      remark: remark,
    };

    console.log(feesToSave);
    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Do you want submit data ?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.props.onSave(feesToSave);
        }
      });
    }
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changedHandlerNumber = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      this.setState({
        ...this.state,
        [name]: "",
      });
    }
    if (onlyNumber.test(e.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleFormValidation() {
    const {
      debitAmount,
      debitAmountValidation,
      transactionDate,
      number,
      bankName,
      type,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (debitAmount === "") {
      formIsValid = false;
      formErrors["debitAmountError"] = myConstClass.debitAmountMsg;
    }
    if (debitAmount > debitAmountValidation) {
      this.setState({
        debitError: myConstClass.debitAmountNotValidMsg,
      });
    }
    if (debitAmount <= 0) {
      formIsValid = false;
      formErrors["debitAmountError"] = myConstClass.debitAmountZeroMsg;
    }

    if (transactionDate === "" || transactionDate === null) {
      formIsValid = false;
      formErrors["transactionDateError"] = myConstClass.transactionDateMsg;
    }

    if (type == "1" || type == "2" || type == "3") {
      if (number === "") {
        formIsValid = false;
        formErrors["numberError"] = myConstClass.numberMsg;
      }
    }

    if (type == "1" || type == "2") {
      if (bankName === "") {
        formIsValid = false;
        formErrors["bankNameError"] = myConstClass.bankNameMsg;
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleClose = () => {
    this.setState({
      debitAmount: "",
      transactionDate: "",
      type: "",
      number: "",
      bankName: "",
      remark: "",
    });
    this.props.onClose();
  };

  changedHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const { feesStructureHeaderList, data, studentData } = this.props;
    const { debitAmount, transactionDate, type, number, bankName, remark } =
      this.state;
    const {
      debitAmountError,
      transactionDateError,
      bankNameError,
      numberError,
    } = this.state.formErrors;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.open}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Debit Amount
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 1 }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Student Name : "
                    />
                    {" " + studentData.applicantName}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Total Fees : "
                    />
                    {" " +
                      feesStructureHeaderList?.feesStructureHeaderById
                        .totalFees}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 1 }}
                >
                  <Grid
                    container
                    columnSpacing={2}
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Debit amount"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="debitAmount"
                        id="debitAmount"
                        value={debitAmount}
                        onChange={this.changedHandlerNumber}
                        fullWidth
                        error={debitAmountError ? true : false}
                        helperText={debitAmountError ? debitAmountError : " "}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="transactionDate"
                        size="small"
                        value={transactionDate}
                        maxDate={new Date()}
                        onChange={this.changeFromDate}
                        isError={transactionDateError ? true : false}
                        errorText={
                          transactionDateError ? transactionDateError : " "
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{ marginTop: -2 }}
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  margin={1}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    columnSpacing={2}
                  >
                    <Grid item xs={12} md={12} sm={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Mode of pay "
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="type"
                          id="type"
                          value={type}
                          onChange={this.changeHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="0">Cash</MenuItem>
                          <MenuItem value="1">DD</MenuItem>
                          <MenuItem value="2">Cheque</MenuItem>
                          <MenuItem value="3">Online</MenuItem>
                          <MenuItem value="4">Transfer</MenuItem>
                        </Select>
                      </FormControl>
                      <FormHelperText> </FormHelperText>
                    </Grid>
                  </Grid>
                  {type == "1" || type == "2" || type == "3" ? (
                    <>
                      <Grid
                        container
                        justifyContent="left"
                        direction="row"
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Number "
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            color="primary"
                            name="number"
                            size="small"
                            value={number}
                            fullWidth
                            onChange={this.changedHandlerNumber}
                            error={numberError ? true : false}
                            helperText={numberError ? numberError : " "}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                      ></Grid>
                    </>
                  )}
                </Grid>
                {(type == "1" || type == "2") && (
                  <>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ marginLeft: 1, marginTop: -2 }}
                    >
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        // margin={1}
                        columnSpacing={2}
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Bank Name "
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            color="primary"
                            name="bankName"
                            size="small"
                            value={bankName}
                            fullWidth
                            onChange={this.changeHandler}
                            error={bankNameError ? true : false}
                            helperText={bankNameError ? bankNameError : " "}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  sx={{ marginTop: -3 }}
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  columnSpacing={2}
                  justifyContent="left"
                >
                  <Grid item xs={12} md={12} sm={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Remark "
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <TextField
                      color="primary"
                      name="remark"
                      size="small"
                      value={remark}
                      fullWidth
                      onChange={this.changeHandler}
                      error={false}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Debit"
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feesStructureHeaderList: state.feesStructureHead,
  applicantAdmissionData: state.applicantAdmission,
});
const mapDispatchToProps = {
  getFeeStructureHeader,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDebitTransaction);
