import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import * as msgConstant from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../utils/api_service";
import { generateExcel } from "../Comman/GenerateExcel";
import { generatePDF } from "../Comman/GeneratePDF";
import NewLandingScreenHeader from "../Comman/NewLandingScreenHeader";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import MaterialUIRTTable from "../Comman/RT/MaterialUIRTTable";
import { Loading1 } from "../Loading1";
import DynamicForm from "./DynamicFormWithTable";
import { LabelCompo } from "../Comman/Label";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showSearch: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      formErrors: {},
      fromDate: "",
      toDate: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.fieldData !== nextProps.fieldData) {
      let dataToSearch = {};
      dataToSearch = {
        ...nextProps.fieldData,
      };

      const semisterDetailId = localStorage.getItem("semisterDetailId");
      const yearDetailId = localStorage.getItem("yearDetailId");
      const divisionId = localStorage.getItem("divisionId");
      const divisionIds = localStorage.getItem("divisionIds");
      if (divisionIds && divisionIds !== "") {
        dataToSearch["divisionId"] = divisionIds.split(",");
      }
      if (divisionId) {
        dataToSearch["division"] = divisionId;
      }
      if (yearDetailId) {
        dataToSearch["yearDetail"] = yearDetailId;
      }
      if (semisterDetailId) {
        dataToSearch["semister"] = semisterDetailId;
      }

      const { fieldMeta } = this.props;
      fieldMeta.map((currentField) => {
        if (currentField.defaultValue) {
          dataToSearch[currentField.dataKey] = currentField.defaultValue;
        }
      });

      this.setState({
        fieldData: dataToSearch,
      });
    }
  }
  componentDidMount() {
    const dataToSearch = {};
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const divisionIds = localStorage.getItem("divisionIds");
    if (divisionIds && divisionIds !== "") {
      dataToSearch["divisionId"] = divisionIds.split(",");
    }
    if (divisionId) {
      dataToSearch["division"] = divisionId;
    }
    if (yearDetailId) {
      dataToSearch["yearDetail"] = yearDetailId;
    }
    if (semisterDetailId) {
      dataToSearch["semister"] = semisterDetailId;
    }

    const { fieldMeta } = this.props;
    fieldMeta.map((currentField) => {
      if (currentField.defaultValue) {
        dataToSearch[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldData: dataToSearch,
    });
  }
  toggleFormTableVisibility = () => {
    const { showForm, showTable, showSearch } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      // showSearch: !showSearch,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    const { fieldMeta } = this.props;
    fieldMeta.map((currentField) => {
      if (currentField.defaultValue) {
        fieldData[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };
  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onFormSave = () => () => {
    const { fieldData } = this.state;
    const { fieldMeta } = this.props;
    const dataToSave = {};
    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "datepicker" &&
        fieldData[currentField.dataKey] != undefined
      ) {
        let date = new Date(fieldData[currentField.dataKey]);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = year + "-" + month + "-" + day;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.props.onSearchData(dataToSave);
  };
  onDelete = (rowdata) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getTableData();
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  checkValidationOnSubmit = (dataToSave, filter) => {
    if (this.mandatoryCheck(dataToSave, filter)) {
      return this.onSubmitCheck(dataToSave, filter);
    } else {
      return false;
    }
  };

  onSubmitCheck = (dataToSave, filter) => {
    let formValid = true;
    const { fieldMeta } = this.props;
    let formErrors = {};
    const dataList = filter.split(",");
    fieldMeta.forEach((currentField) => {
      const compareWithFieldLabel = dataList.filter(
        (item) => item === currentField.dataKey
      );
      if (
        compareWithFieldLabel.length !== 0 &&
        currentField.onSubmit === "compare"
      ) {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = dataToSave[currentField.dataKey];
          let compareWithValue = dataToSave[compareWith];

          if (typeof dataKeyValue === "string") {
            const dateArr = dataKeyValue?.split("-");

            dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }
          if (typeof compareWithValue === "string") {
            const dateArr = compareWithValue?.split("-");

            compareWithValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }
          switch (compareCond) {
            case "l":
              {
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }
              }
              break;
            case "le":
              {
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }
              }
              break;
            case "g":
              {
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }
              }
              break;
            case "ge":
              {
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }
              }
              break;
            case "eq":
              {
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }
              }
              break;
          }

          if (isError) {
            const compareWithFieldLabel = fieldMeta.filter(
              (item) => item.dataKey === compareWith
            )[0].label;
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
              },
            };
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  mandatoryCheck = (dataToSave, filter) => {
    let formValid = true;
    const { fieldMeta } = this.props;
    let formErrors = {};
    const dataList = filter.split(",");
    fieldMeta.forEach((currentField) => {
      const compareWithFieldLabel = dataList.filter(
        (item) => item === currentField.dataKey
      );
      if (compareWithFieldLabel.length !== 0 && currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (dataToSave[currentField.dataKey] === undefined ||
              dataToSave[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            (dataToSave[currentField.dataKey] === undefined ||
              dataToSave[currentField.dataKey] === null ||
              dataToSave[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const { fieldMeta } = this.props;

        const dataToSave = {
          id: rowData["id"],
        };

        const { tableColumns } = this.props;
        let fieldData = {};

        tableColumns.forEach((currentCol) => {
          const formDataKey = currentCol.formDataKey
            ? currentCol.formDataKey
            : currentCol.name;
          const tableDataKey = currentCol.tableDataKey
            ? currentCol.tableDataKey
            : currentCol.name;
          fieldData[formDataKey] = rowData[tableDataKey];
        });

        fieldMeta.forEach((currentField) => {
          if (
            currentField.controlType === "autocomplete" &&
            !currentField.isRootLevelKey
          ) {
            dataToSave[currentField.dataKey] = {};
            dataToSave[currentField.dataKey]["id"] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          } else {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        });

        dataToSave["isActive"] = rowData.isActive === 1 ? "0" : "1";
        this.setState({
          currentOperationMode: OperationMode.Status,
        });
        this.onSave(dataToSave, false);
      }
    });
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    tableColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  generatePDFDownload = () => {
    if (this.props.downLoadPdfFromApi) {
      if (
        this.checkValidationOnSubmit(this.state.fieldData, this.props.filter)
      ) {
        this.props.pdfDownload();
      }
    } else {
      this.generatePDF();
    }
  };
  render() {
    const {
      screenTitle = "",
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      fieldMeta,
      dynamicMasterData = {},
      searchAlign = 6,
      tableColumns = [],
      tableData = [],
      showHeadDelete,
      condition = [],
      rowDelete,
      rowViewData,
      showPegination,
      showView,
      rowStatus,
      statusChange,
      rowEdit,
      showHeadEdit,
      studListEmpty = false,
      msg,
      rowId,
      showHeadAdd,
      rowView,
      showApply,
      showRoundDetails,
      rounds,
      isActionColActive,
      rowFeesPaid,
      rowAppliedList,
      rowPaymentDetails,
      showPayFees,
      showPaymentDetails,
      showAppliedList,
      showDetails,
      showSchoolIcon,
      showPersonAdd,
      showLinkIcon,
      showHeadExcel,
      rowDetails,
      rowSchoolDetails,
      rowPersonAddDetails,
      showHeadPdf,
      pdfDetailsId,
      searchButton,
      onAddButtonClick,
      buttonCenter,
      excelDetailsId,
      generateDetails,
      downLoadPdfFromApi = false,
      pdfDownload,
      marginTop,
      marginTopTable,
      showRoundDetailsNew,
      showLoader = false,
      showSearch = true,
      showFillMarks,
      tableOpen = true,
      rowFillMarks,
      showComment,
      rowComment,
      showNotes = false,
      notes = "",
      showSwitchButton,
      handleChange,
      checked,
      title,
    } = this.props;
    const { fieldData, isLoading, showTable } = this.state;

    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          onAddButtonClick={onAddButtonClick}
          onSearch={this.onSearch}
          screenTitle={screenTitle}
          showPdfDownload={showPdfDownload}
          // generatePDF={downLoadPdfFromApi ? pdfDownload : this.generatePDF}
          generatePDF={this.generatePDFDownload}
          showExcelDownload={showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={showAddButton}
          showTable={showTable}
          showSearch={showSearch}
          showSwitchButton={showSwitchButton}
          handleChange={handleChange}
          checked={checked}
          title={title}
        />
        <br />
        {/* <Alert msg={"hi"} /> */}
        <Paper sx={{ p: 3, pt: 0, borderRadius: 2 }} elevation={0}>
          {/* <NewLandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={screenTitle}
            showPdfDownload={showPdfDownload}
            generatePDF={this.generatePDF}
            showExcelDownload={showExcelDownload}
            generateExcel={this.generateExcel}
            showAddButton={showAddButton}
            onAddButtonClick={this.props.onAddButtonClick}
            showTable={showTable}
          /> */}
          {showTable && (
            <>
              <DynamicForm
                fieldMeta={fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                formErrorsShows={this.state.formErrors}
                onDataChange={this.onDataChange}
                onSave={this.onFormSave()}
                buttonCenter={buttonCenter}
                searchButton={searchButton}
                searchAlign={searchAlign}
                marginTop={marginTop}
              />

              {showNotes && (
                <>
                  <br />
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 12, color: "#808080" }}
                      label={notes}
                    />
                  </Grid>
                </>
              )}
              <br />
              {tableOpen && (
                <MaterialUIRTTable
                  data={this.getFilteredTableData()}
                  tableHead={tableColumns}
                  showHeadDelete={showHeadDelete}
                  rowDelete={rowDelete}
                  rowViewData={rowViewData}
                  showPegination={showPegination}
                  showView={showView}
                  rowStatus={rowStatus}
                  statusChange={statusChange}
                  rowEdit={rowEdit}
                  showHeadEdit={showHeadEdit}
                  rowId={rowId}
                  studListEmpty={studListEmpty}
                  msg={msg}
                  showHeadAdd={showHeadAdd}
                  generateDetails={generateDetails}
                  showLinkIcon={showLinkIcon}
                  showHeadExcel={showHeadExcel}
                  excelDetailsId={excelDetailsId}
                  rowView={rowView}
                  marginTop={marginTopTable}
                  showApply={showApply}
                  showRoundDetails={showRoundDetails}
                  showRoundDetailsNew={showRoundDetailsNew}
                  rounds={rounds}
                  isActionColActive={isActionColActive}
                  rowFeesPaid={rowFeesPaid}
                  condition={condition}
                  rowAppliedList={rowAppliedList}
                  rowPaymentDetails={rowPaymentDetails}
                  showPayFees={showPayFees}
                  showPaymentDetails={showPaymentDetails}
                  showAppliedList={showAppliedList}
                  showDetails={showDetails}
                  showSchoolIcon={showSchoolIcon}
                  showPersonAdd={showPersonAdd}
                  rowDetails={rowDetails}
                  rowSchoolDetails={rowSchoolDetails}
                  rowPersonAddDetails={rowPersonAddDetails}
                  showHeadPdf={showHeadPdf}
                  pdfDetailsId={pdfDetailsId}
                  rowFillMarks={rowFillMarks}
                  showFillMarks={showFillMarks}
                  showComment={showComment}
                  rowComment={rowComment}
                />
              )}
            </>
          )}
        </Paper>
        {/* {isLoading && <Loading1 />} */}
        {showLoader && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = { showNotification, getAlert };
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
