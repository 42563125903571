import endpoint from "../config/endpoints";

export const scheme = {
  apiBaseURL: endpoint.scheme,
  screenTitle: "Scheme",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Scheme",
  showExcelDownload: true,
  excelFileName: "Scheme",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Short Name",
      columnKeyName: "shortName",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order No",
      columnKeyName: "sortOrderNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Min/Max",
      columnKeyName: "isMinMaxShow",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrderNo",
      inputType:'number',
      isMandatory: true,
    },
    {
      label: "Min/Max",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isMinMaxShow",
      defaultValue: 1,
      toolTip:'Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus. Praesent non nunc mollis, fermentum neque at, semper arcu.Nullam eget est sed sem iaculis gravida eget vitae justo.',
      radioGroupItems: [
        {
          label: "Yes",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ],
      isMandatory: true,
    },
    {
      label: "Is Teaching ?",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isTeaching",
      defaultValue: 1,
      // note: "Note: @1. Scheme Min/Max.@2. Scheme is teaching.",
      radioGroupItems: [
        {
          label: "Yes",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ],
      isMandatory: true,
    },
  ],
};
