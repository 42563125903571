import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class MemberAgendaTable extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    studUserRegistrationId: [],
    rowListDetails: [],
    formErrors: [],
    section: {},
  };

  componentDidMount() {
    this.setState({
      section: this.props.agendaData,
    });
  }

  handleFormValidation() {}

  handleClose = () => {};

  render() {
    const { section } = this.state;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.props.handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.props.handleClose}
            >
              {section.title}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 2 }}
                >
                  {" "}
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <br />
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={12}
                      md={12}
                      columnSpacing={3}
                    >
                      <Grid item xs={12} sm={1} md={1}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={11} md={11}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={section.details}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MemberAgendaTable);
