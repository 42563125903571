import { Divider, Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import NotificationToast from "../../components/Comman/NotificationToast";
import * as myConstClass from "../../config/messageconstant";
import {
  showNotification,
  closeNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getAcademicYearPreLogin,
  getAdmissionFormPreLogin,
  resetError,
} from "./admissionForm.slice";

class AdmissionForm extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    academicYear: "",
    grn: "",
    prn: "",
    program: "",
    progTitle: "",
    admissionYear: "",
    applicationId: "",
    domainName: "",
    formErrors: [],
  };

  componentDidMount() {
    const { studentList, getAcademicYearPreLogin } = this.props;
    getAcademicYearPreLogin({ domainName: window.location.host }).then(
      (response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      }
    );

    if (studentList.isFetch) {
      this.setDataToTextField(studentList.admissionForm);
    }
    if (studentList.isFetchAcademicYear) {
      this.setAcademicToTextField(studentList.academicYear);
    }
  }

  setAcademicToTextField(data) {
    if (data.length !== 0) {
      this.setState({
        academicYear: data.academiYearName,
      });
    }
  }

  setDataToTextField(data) {
    if (data.length !== 0) {
      this.setState({
        program: data.program,
        progTitle: data.programTitle,
        admissionYear: data.yearName,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.admissionForm !==
        nextProps.studentList.admissionForm
      ) {
        this.setDataToTextField(nextProps.studentList.admissionForm);
      }
    }

    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.academicYear !==
        nextProps.studentList.academicYear
      ) {
        this.setAcademicToTextField(nextProps.studentList.academicYear);
      }
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation(name) {
    const { academicYear, typeOfAdmission, applicationId } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (academicYear.toString().trim() === "" || academicYear === null) {
      formIsValid = false;
      formErrors["academicYearError"] = myConstClass.academicYearMessage;
    }

    if (typeOfAdmission.toString().trim() === "" || academicYear === null) {
      formIsValid = false;
      formErrors["typeOfAdmissionError"] = myConstClass.typeOfAdmissionMessage;
    }
    if (applicationId.toString().trim() === "" || academicYear === null) {
      formIsValid = false;
      formErrors["applicationIdError"] = myConstClass.applicationIdMessage;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, academicYear, applicationId } = this.state;
    this.setState({
      applicationId: applicationId,
    });

    const { onSave } = this.props;

    let appliData = {
      id: id,
      academicYear: academicYear,
      applicationId: applicationId,
    };
    onSave(appliData);
  };

  changedHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  saveHandler = (event) => {
    const { value } = event.target;
    this.setState({
      applicationId: value,
    });
    const { getAdmissionFormPreLogin, getAcademicYearPreLogin } = this.props;
    const { showNotification, resetError, getAlert } = this.props;

    getAdmissionFormPreLogin({
      applicationNumber: value,
      domainName: window.location.host,
    }).then((response) => {
      if (response !== null) {
        if (response.data.errorMessage.error !== false) {
          showNotification({
            msg: "No Seat Allotted for applicationNumber " + value,
            severity: "error",
            // : "Data updated successfully",
          });
          // getAlert( {
          //   message: "No Seat Allotted for applicationNumber " + value,

          // } );
          resetError();
        } else {
          showNotification({
            msg: "Application Number Validate successfully",
            severity: "success",
          });
        }
      } else {
        showNotification({
          msg: "Application Number Invalid",
          severity: "error",
        });
        // getAlert( {
        //   message: "Something went wrong",
        // } );
      }
    });

    getAcademicYearPreLogin({ domainName: window.location.host }).then(
      (response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      }
    );
  };

  closeSnak = () => {
    const { closeNotification } = this.props;
    closeNotification();
  };

  render() {
    const {
      academicYear,
      grn,
      prn,
      program,
      progTitle,
      admissionYear,
      applicationId,
    } = this.state;
    const { studentList, landingData } = this.props;
    return (
      <>
        <NotificationToast
          open={landingData.isShowNotification}
          severity={
            landingData.severity == undefined ? "success" : landingData.severity
          }
          msg={landingData.msg}
          closeSnak={this.closeSnak}
        />
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <form
              onSubmit={this.submitHandler}
              autoComplete="off"
              noValidate={true}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                rowSpacing={2}
              >
                <Grid m={3} container justifyContent="center">
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 25 }}
                    label="e-Admission Portal"
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="(candidates self admission portal )"
                  />
                </Grid>
                {/* <Grid m={3} container justifyContent="left">
                <LabelCompo
                    className="text-black"
                    style={{ fontSize: 12 }}
                    label="Application Id"
                  />
                  </Grid> */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  container
                  justifyContent="space-evenly"
                ></Grid>
                <Grid item xs={12} sm={6} md={12}></Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Application Id "
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="applicationId"
                      size="small"
                      value={applicationId}
                      fullWidth
                      onBlur={this.saveHandler}
                      onChange={this.changedHandler}
                    />
                  </Grid>
                </Grid>
                {studentList.isFetch && studentList.applicationIdMsg == "" && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Academic Year"
                      />
                    </Grid>

                    <Grid m={-1} item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="academicYear"
                        size="small"
                        value={academicYear}
                        fullWidth
                        onChange={this.changedHandler}
                        disabled
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={12}></Grid>
                {/* {studentList.isFetch && studentList.applicationIdMsg == "" && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="GRN Number "
                      />
                    </Grid>

                    <Grid m={-1} item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="grn"
                        size="small"
                        value={grn}
                        onChange={this.changedHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                )} */}
                {/* {studentList.isFetch && studentList.applicationIdMsg == "" && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="PRN Number"
                      />
                    </Grid>

                    <Grid m={-1} item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="prn"
                        size="small"
                        value={prn}
                        onChange={this.changedHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                )} */}
                <Grid item xs={12} sm={6} md={12}></Grid>
                {studentList.isFetch && studentList.applicationIdMsg == "" && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Program"
                      />
                    </Grid>

                    <Grid m={-1} item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="program"
                        size="small"
                        value={program}
                        onChange={this.changeHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                )}
                {studentList.isFetch && studentList.applicationIdMsg == "" && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Program Title"
                      />
                    </Grid>

                    <Grid m={-1} item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="progTitle"
                        size="small"
                        value={progTitle}
                        onChange={this.changeHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                )}
                {studentList.isFetch && studentList.applicationIdMsg == "" && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Admission for Year"
                      />
                    </Grid>

                    <Grid m={-1} item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="admissionYear"
                        size="small"
                        value={admissionYear}
                        onChange={this.changeHandler}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  rowSpacing={3}
                  // columnSpacing={{xs  :12, sm  :6, md  :1}}
                  container
                  justifyContent="space-evenly"
                ></Grid>
                <Grid item xs={12} sm={6} md={3}></Grid>
                {studentList.isFetch && studentList.applicationIdMsg == "" && (
                  <Grid m={2} container justifyContent="right">
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save and Proceed"
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentList: state.admissionForm,
  landingData: state.landing,
});
const mapDispatchToProps = {
  getAdmissionFormPreLogin,
  getAcademicYearPreLogin,
  showNotification,
  resetError,
  closeNotification,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionForm);
