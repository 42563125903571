import { Grid, Paper, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import * as myConstClass from "../../../config/messageconstant";
import { getEmployeeByDeptId } from "../../EmployeeDetails/employee.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";

let docs;
class AddCommittee extends Component {
  state = {
    id: 0,
    name: "",
    details: "",
    employeeDepartment: [],
    teacher: [],
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { name, details, teacher, employeeDepartment } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (name === "" || name === undefined) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.nameMsgRequired;
    }

    if (details === "" || details === undefined) {
      formIsValid = false;
      formErrors["detailsError"] = myConstClass.detailsMsg;
    }

    if (teacher.toString().trim() === "") {
      formIsValid = false;
      formErrors["teacherError"] = myConstClass.teacherNameMsg;
    }

    if (employeeDepartment.toString().trim() === "") {
      formIsValid = false;
      formErrors["employeeDepartmentError"] = myConstClass.departmentMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, name, details, teacher, employeeDepartment } = this.state;
    docs = {
      name: name,
      details: details,
      userRegistrationIds: teacher.join(),
      departmentIds: employeeDepartment.join(),
      isActive: 1,
    };

    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (this.handleFormValidation()) {
      this.props.onSave(docs);
      this.setState({ name: "", details: "" });
      this.props.onCancel();
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name === "employeeDepartment") {
        let bData = [];
        this.props
          .getEmployeeByDeptId({ departmentId: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };
  componentDidMount() {
    const { editData } = this.props;
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        if (Object.keys(editData).length !== 0) {
          this.props
            .getEmployeeByDeptId({ departmentId: editData.departmentIds })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          this.setState({
            ...this.state,
            employeeDepartment: editData.departmentIds.split(","),
            teacher: editData.userRegistration.split(","),
          });
        }
      }
    });
    if (Object.keys(editData).length !== 0) {
      this.setState({
        ...this.state,
        id: editData.id,
        name: editData.name,
        details: editData.details,
      });
    } else {
      this.setState({
        id: 0,
        name: "",
        details: "",
      });
    }
  }
  componentWillReceiveProps(nextProps) {}
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };
  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
    if (name === "employeeDepartment") {
      this.props
        .getEmployeeByDeptId({ departmentId: value })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    // setType(typeof value === "string" ? value.split(",") : value);
  };
  onCancelHandler = () => {
    const { id, name, details, teacher } = this.state;

    this.setState({ name: "", details: "" });
    this.props.onCancel();
  };

  render() {
    const { name, details, employeeDepartment, teacher } = this.state;
    const { onCancel } = this.props;
    const { nameError, detailsError, teacherError, employeeDepartmentError } =
      this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={this.onCancelHandler}
              />
            </Grid>
          </Grid>
          <br />
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Name "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="name"
                      size="small"
                      value={name}
                      onChange={this.changeHandler}
                      fullWidth
                      error={nameError ? true : false}
                      helperText={nameError ? nameError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="details"
                      size="small"
                      value={details}
                      onChange={this.changeHandler}
                      fullWidth
                      multiline
                      error={detailsError ? true : false}
                      helperText={detailsError ? detailsError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Department"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        name1="employeeDepartment"
                        value1={employeeDepartment}
                        er={employeeDepartmentError}
                        names={this.props.departmentList?.department.filter(
                          (data) => data.isActive == 1
                        )}
                        onChange={this.multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Authority to edit"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        er={teacherError}
                        name1="teacher"
                        value1={teacher}
                        names={this.props.employeeList?.employeeList}
                        onChange={this.multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                sx={{ mr: 0.5 }}
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                m={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={this.onCancelHandler}
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  getDepartment,
  getEmployeeByDeptId,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCommittee);