import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  deemedCommittee: [],
  isFetchDeemedCommittee: false,

  deemedCommitteeById: {},
  isFetchDeemedCommitteeById: false,

  deemedCommitteeMember: [],
  isFetchDeemedCommitteeMember: false,

  deemedCommitteeMemberWithStatus: [],
  isFetchDeemedCommitteeMemberWithStatus: false,
};
let URL1 = endpoints.deemedCommittee;
const deemedCommitteeSlice = createSlice({
  name: "deemedCommittee",
  initialState,
  reducers: {
    deemedCommitteeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        deemedCommittee: row,
        isFetchDeemedCommittee: true,
      };
    },

    deemedCommitteeByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { bData } = action.payload;
      return {
        ...state,
        deemedCommitteeById: bData,
        isFetchDeemedCommitteeById: true,
      };
    },
    deemedCommitteeMemberSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        deemedCommitteeMember: row,
        isFetchDeemedCommitteeMember: true,
      };
    },
    deemedCommitteeMemberWithStatusSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        deemedCommitteeMemberWithStatus: row,
        isFetchDeemedCommitteeMemberWithStatus: true,
      };
    },
  },
});

export const {
  deemedCommitteeSuccess,
  deemedCommitteeByIdSuccess,
  deemedCommitteeMemberSuccess,
  deemedCommitteeMemberWithStatusSuccess,
} = deemedCommitteeSlice.actions;

export default deemedCommitteeSlice.reducer;

export const getDeemedCommittee = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        let row = [];
        data.data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            details: data1.details,
            flag: data1.flag,
            userRegistration: data1.userRegistrationIds,
            departmentIds: data1.departmentIds,
            isActive: data1.isActive,

            open: false,
          };
          row.push(bData);
          return data1;
        });
        dispatch(deemedCommitteeSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getDeemedCommitteeById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1,
      }).then(({ data, success }) => {
        if (success) {
          const { content } = data.data;
          let bData = {
            id: data.data.id,
            eventName: data.data.eventName,
            venue: data.data.venue,
            fromDate: data.data.fromDate,
            toDate: data.data.toDate,
            time: data.data.eventTime,
            amount: data.data.amountPaid,
            eventDetails: data.data.eventDetails,
          };
          dispatch(deemedCommitteeByIdSuccess({ bData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDeemedCommitteeMember =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/get-committee-member",
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              memberName: data1.memberName,
              memberDesignation: data1.memberDesignation,
              designation: data1.designation,
              contactNo: data1.contactNo,
              emailId: data1.emailId,
              joiningDate: data1.joiningDate,
              leavingDate: data1.leavingDate,
              sequenceNo: data1.sequenceNo,
              committee: data1.committee,
              userRegistration: data1.userRegistration,
              status: data1.status,
              attendance: false,
              excuse: false,
              answer: "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(deemedCommitteeMemberSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDeemedCommitteeMemberWithStatus =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/get-committee-member-with-status",
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              memberName: data1.memberName,
              memberDesignation: data1.memberDesignation,
              designation: data1.designation,
              contactNo: data1.contactNo,
              emailId: data1.emailId,
              joiningDate: data1.joiningDate,
              leavingDate: data1.leavingDate,
              sequenceNo: data1.sequenceNo,
              committee: data1.committee,
              userRegistration: data1.userRegistration,
              status: data1.status,
              attendance: false,
              excuse: false,
              answer: "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(deemedCommitteeMemberWithStatusSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
