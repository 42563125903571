import { Grid, Paper } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import RTTable from "../../../components/Comman/RT/RTTable";
import endpoint from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
import { getFolder } from "../AdminGalleryFolder/adminGallary.slice";
import { Loading1 } from "../../../components/Loading1";
import { downloadVideo, downloadStudentVideo } from "../../Video/Video";
import Corousel from "./Carousel";
import AdminGallarYPopup from "../AdminGalleryFolder/AdminGallarYPopup";
const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Video Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];

class MasonryImageList extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    gallaryList: [],
    itemList: {},
    open: false,
    currentImage: 0,
    showVideo: false,
    showVideoPopup: false,
    videoData: [],
    setImage: "",
    index: "",
    urlData: "",
  };
  componentDidMount() {
    this.setState({
      gallaryList: [],
    });
    this.props.itemList.folderContents.map((folderData) => {
      if (folderData.docType !== 1) {
        apiGet({
          url: endpoint.fileDownload + "/" + folderData.path,
        }).then(({ data, success }) => {
          if (success) {
            folderData = {
              ...folderData,
              profile: data.data,
              // profile: "https://images.unsplash.com/photo-1525097487452-6278ff080c31"
            };
            this.setState({
              gallaryList: [...this.state.gallaryList, folderData],
            });
          } else {
            folderData = {
              ...folderData,
            };
            this.setState({
              gallaryList: [...this.state.gallaryList, folderData],
            });
          }
        });
      } else {
        this.state.videoData.push(folderData);

        this.setState({
          showVideo: true,
        });
      }
    });
  }
  rowView = (params) => {
    const { index, urlData } = this.state;
    console.log(params);
    this.setState({
      showLoader: true,
      urlData: "",
    });

    if (index == params.index) {
      this.setState({
        showVideoPopup: true,
        urlData: urlData,
        showLoader: false,
      });
    } else {
      this.props.downloadVideo({ url: params.path }).then((response) => {
        console.log(response);
        if (response) {
          this.setState({
            showVideoPopup: true,
            urlData: response,
            index: params.index,
            showLoader: false,
          });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
  };

  handleClose = () => {
    this.setState({
      showVideoPopup: false,
      urlData: this.state.urlData,
    });
  };

  onClickPhoto = (item) => {
    this.setState({
      open: !this.state.open,
      itemList: item,
    });
  };
  openLightbox = (index) => {
    this.setState({
      open: true,
      currentImage: index,
    });
  };
  closeLightbox = () => {
    this.setState({
      open: false,
      currentImage: 0,
    });
  };
  render() {
    const { gallaryList, open, showVideo, videoData, showVideoPopup, urlData } =
      this.state;

    return (
      <>
        {!open ? (
          <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                sx={{ margin: 2 }}
                // rowSpacing={2}
                //   columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={11} md={11}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18 }}
                    label={this.props.itemList.name}
                  />
                </Grid>
                <Grid item xs={12} sm={1} md={1}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Back"
                    onClick={this.props.GoBack}
                  />
                </Grid>
              </Grid>
            </Grid>
            <ImageList variant="masonry" cols={4} gap={8}>
              {gallaryList.map((item, index) => (
                <>
                  <ImageListItem key={item.profile}>
                    <img
                      src={item.profile}
                      srcSet={item.profile}
                      alt={item.name}
                      onClick={() => {
                        this.openLightbox(index);
                      }}
                      loading="lazy"
                    />
                  </ImageListItem>
                </>
              ))}
            </ImageList>
            {showVideo && (
              <RTTable
                columns={columns}
                showEditIcon={false}
                showDeleteIcon={false}
                showViewButtonInTable={true}
                tableData={videoData}
                showPegination={false}
                rowView={this.rowView}
                //  rowView={this.props.rowView}
                //  rowDelete={this.props.rowDelete}
              />
            )}
            {showVideoPopup && (
              <AdminGallarYPopup
                urlData={urlData}
                open={showVideo}
                handleClose={this.handleClose}
              />
            )}
          </Paper>
        ) : (
          <Corousel ImageList={gallaryList} onClose={this.closeLightbox} />
        )}
        {this.state.showLoader && <Loading1 />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  downloadVideo,
};
export default connect(mapStateToProps, mapDispatchToProps)(MasonryImageList);
