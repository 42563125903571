

import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TextField } from "@mui/material";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import { styled } from "@mui/material/styles";

// const columns = [
// { id: 'name', label: 'Name', minWidth: 170 },
// { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
// {
// id: 'population',
// label: 'Population',
// minWidth: 170,
// align: 'right',
// format: (value) => value.toLocaleString('en-US'),
// },
// {
// id: 'size',
// label: 'Size\u00a0(km\u00b2)',
// minWidth: 170,
// align: 'right',
// format: (value) => value.toLocaleString('en-US'),
// },
// {
// id: 'density',
// label: 'Density',
// minWidth: 170,
// align: 'right',
// format: (value) => value.toFixed(2),
// },
// ];

export default function QualificationTable({ data, columns,rows, onCancle }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1975d2",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
              <StyledTableCell align="center" colSpan={5}>
                Total Marks
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={0}></StyledTableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
     
      </>
  );
}
