import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import * as msgConstant from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../utils/api_service";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { generateExcel } from "../Comman/GenerateExcel";
import { generatePDF } from "../Comman/GeneratePDF";
import { LabelCompo } from "../Comman/Label";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import RTTable from "../Comman/RT/RTTable";
import { Loading1 } from "../Loading1";
import DynamicFromWithImageUpload from "./DynamicFormWithImageUpload";
import DynamicFormWithTable from "./DynamicFormWithTable";
import * as myConstClass from "../../config/messageconstant";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      fieldDataForSearch: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      DataToSet: [],
      formErrors: {},
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };
  componentDidMount() {
    const dataToSearch = {};
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const divisionIds = localStorage.getItem("divisionIds");
    if (divisionIds && divisionIds !== "") {
      dataToSearch["divisionId"] = divisionIds.split(",");
    }
    if (divisionId) {
      dataToSearch["division"] = divisionId;
    }
    if (yearDetailId) {
      dataToSearch["yearDetail"] = yearDetailId;
    }
    if (semisterDetailId) {
      dataToSearch["semister"] = semisterDetailId;
    }

    const { searchList = [] } = this.props;
    searchList.map((currentField) => {
      if (currentField.defaultValue) {
        dataToSearch[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldDataForSearch: dataToSearch,
    });
  }
  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  calcDate(dt1, dt2) {
    if (dt1 !== null && dt2 !== null) {
      let startMonth = dt1.getFullYear() * 12 + dt1.getMonth();
      let endMonth = dt2.getFullYear() * 12 + dt2.getMonth();
      let monthInterval = endMonth - startMonth;
      let yearsOfExperience = Math.floor(monthInterval / 12);
      let monthsOfExperience = monthInterval % 12;

      //
      const startYear = dt1.getFullYear();
      let yearDiff = dt2.getFullYear() - startYear;
      let monthDiff = dt2.getMonth() - dt1.getMonth();

      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [
        31,
        february,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
      ];

      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }
      let dayDiff = dt2.getDate() - dt1.getDate();
      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[dt1.getMonth()];
      }

      //

      //  let time =(dt2.getTime() - dt1.getTime()) / 1000;
      //  let year  = Math.abs(Math.round((time/(60 * 60 * 24))/365.25));
      //  let month = Math.abs(Math.round(time/(60 * 60 * 24 * 7 * 4)));
      //   let days = Math.abs( Math.round( time / ( 3600 * 24 ) ) );
      return (
        +yearsOfExperience +
        " Year " +
        monthsOfExperience +
        " Month " +
        dayDiff +
        " Days"
      );
    }
  }

  onDataChange = (fieldName, newValue) => {
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        let date = new Date(newValue);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear() + 1;
        fieldData[currentField.getDate] = new Date(year, month, day);
      } else if (currentField.dataKey === fieldName) {
        let date = new Date(newValue);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear() + 1;
        fieldData[currentField.getDate] = new Date(year, month, day);
        if (currentField.setText) {
          if (this.state.fieldData[currentField.getText]) {
            let calValuToSet = "";
            if (currentField.date) {
              calValuToSet = this.calcDate(
                newValue,
                this.state.fieldData[currentField.getText]
              );
            } else {
              calValuToSet = this.calcDate(
                this.state.fieldData[currentField.getText],
                newValue
              );
            }

            fieldData[currentField.setTextTo] = calValuToSet;
          }
        }
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  // onDataChange = (fieldName, newValue) => {
  //   let fieldData = {};
  //   const { fieldMeta } = this.props;
  //   fieldMeta.map((currentField) => {
  //     if (currentField.getDate && currentField.dataKey === fieldName) {
  //       let date = new Date(newValue);
  //       let day = date.getDate();
  //       let month = date.getMonth();
  //       let year = date.getFullYear() + 1;
  //       fieldData[currentField.getDate] = new Date(year, month, day);
  //     } else if (currentField.dataKey === fieldName) {
  //       let date = new Date(newValue);
  //       let day = date.getDate();
  //       let month = date.getMonth();
  //       let year = date.getFullYear() + 1;
  //       fieldData[currentField.getDate] = new Date(year, month, day);
  //       if (currentField.setText) {
  //         if (this.state.fieldData[currentField.getText]) {
  //           let calValuToSet = "";
  //           if (currentField.date) {
  //             calValuToSet = this.calcDate(
  //               newValue,
  //               this.state.fieldData[currentField.getText]
  //             );
  //           } else {
  //             calValuToSet = this.calcDate(
  //               this.state.fieldData[currentField.getText],
  //               newValue
  //             );
  //           }

  //           fieldData[currentField.setTextTo] = calValuToSet;
  //         }
  //       }
  //     }
  //     if (currentField.dataKey === fieldName && currentField.getListId) {
  //       // dataToSearch[fieldName] = newValue;
  //       const masterKeyList = currentField.getListId.split(",");
  //       masterKeyList.map((key) => {
  //         if (fieldName === key) {
  //           dataToSearch[key] = newValue;
  //         } else if (fieldData[key]) {
  //           dataToSearch[key] = fieldData[key];
  //         }
  //       });

  //       this.props.getListById(dataToSearch);
  //     }
  //     if (currentField.getListFrom) {
  //       const masterKeyList = currentField.getListFrom.split(",");
  //       masterKeyList.map((key) => {
  //         if (key === fieldName) {
  //           listError = true;
  //         }
  //       });
  //     }
  //     if (!listError && fieldData[currentField.dataKey]) {
  //       dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
  //     }
  //   });
  //   fieldData[fieldName] = newValue;
  //   this.setState({
  //     fieldData: { ...this.state.fieldData, ...fieldData },
  //   });
  // };
  onDataChangeForSearch = (fieldName, newValue) => {
    this.setState({
      fieldDataForSearch: {
        ...this.state.fieldDataForSearch,
        [fieldName]: newValue,
      },
    });
  };

  onDataChangeForSearch = (fieldName, newValue) => {
    const { searchList = [] } = this.props;
    const { fieldDataForSearch } = this.state;
    const dataToSearch = {};
    const dataToReset = {};
    let listError = false;
    searchList.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        let date = new Date(newValue);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear() + 1;
        fieldDataForSearch[currentField.getDate] = new Date(year, month, day);
      } else if (currentField.dataKey === fieldName) {
        let date = new Date(newValue);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear() + 1;
        fieldDataForSearch[currentField.getDate] = new Date(year, month, day);
        if (currentField.setText) {
          if (this.state.fieldDataForSearch[currentField.getText]) {
            let calValuToSet = "";
            if (currentField.date) {
              calValuToSet = this.calcDate(
                newValue,
                this.state.fieldDataForSearch[currentField.getText]
              );
            } else {
              calValuToSet = this.calcDate(
                this.state.fieldDataForSearch[currentField.getText],
                newValue
              );
            }

            fieldDataForSearch[currentField.setTextTo] = calValuToSet;
          }
        }
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldDataForSearch[key]) {
            dataToSearch[key] = fieldDataForSearch[key];
          }
        });

        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldDataForSearch[currentField.dataKey]) {
        dataToReset[currentField.dataKey] =
          fieldDataForSearch[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldDataForSearch: dataToReset,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    const { addValidation = false, msg = "" } = this.props;
    if (!addValidation) {
      let fieldData = {};
      const { fieldMeta } = this.props;
      fieldMeta.map((currentField) => {
        if (currentField.defaultValue) {
          fieldData[currentField.dataKey] = currentField.defaultValue;
        }
      });

      this.setState({
        fieldData: fieldData,
        currentOperationMode: OperationMode.Add,
      });
      this.toggleFormTableVisibility();
    } else {
      const { fieldDataForSearch, DataToSet = [] } = this.state;
      const { dataSetToAdd, dynamicMasterData } = this.props;
      const dataToSave = {};
      const dataToPassForm = [];
      let error = false;
      dataSetToAdd.forEach((data) => {
        if (fieldDataForSearch[data.value] == undefined) {
          error = true;
        } else {
          const valueToShow = dynamicMasterData[data["masterName"]]
            ? dynamicMasterData[data["masterName"]].filter(
                (row) => row.id == fieldDataForSearch[data.value]
              )
            : [];
          let dataToSet = {
            label: data.label,
            value: valueToShow.length !== 0 ? valueToShow[0].name : " - ",
          };
          dataToPassForm.push(dataToSet);
        }
      });
      this.setState({
        DataToSet: dataToPassForm,
      });
      if (!error) {
        let fieldData = {};
        const { fieldMeta } = this.props;
        fieldMeta.map((currentField) => {
          if (currentField.defaultValue) {
            fieldData[currentField.dataKey] = currentField.defaultValue;
          }
        });

        this.setState({
          fieldData: fieldData,
          currentOperationMode: OperationMode.Add,
        });
        this.toggleFormTableVisibility();
      } else {
        swal({
          title: "Alert !",
          text: msg !== "" ? msg : "Please Select Teacher !",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
      console.log(dataToSave);
    }
  };
  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  rowEdit = (rowData) => {
    const { fieldMeta, dynamicMasterData, addValidation, DocumentUpload } =
      this.props;

    let fieldData = {};

    fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    fieldData["rows"] = rowData[DocumentUpload];
    console.log(fieldData);

    if (!addValidation) {
      this.toggleFormTableVisibility();

      this.setState({
        fieldData: fieldData,
        currentOperationMode: OperationMode.Edit,
      });
    } else {
      const { fieldDataForSearch, DataToSet = [] } = this.state;
      const { dataSetToAdd, dynamicMasterData } = this.props;
      const dataToSave = {};
      const dataToPassForm = [];
      let error = false;
      dataSetToAdd.forEach((data) => {
        if (fieldDataForSearch[data.value] == undefined) {
          error = true;
        } else {
          const valueToShow = dynamicMasterData[data["masterName"]]
            ? dynamicMasterData[data["masterName"]].filter(
                (row) => row.id == fieldDataForSearch[data.value]
              )
            : [];
          let dataToSet = {
            label: data.label,
            value: valueToShow.length !== 0 ? valueToShow[0].name : " - ",
          };
          dataToPassForm.push(dataToSet);
        }
      });
      this.setState({
        DataToSet: dataToPassForm,
      });
      if (!error) {
        this.setState({
          fieldData: fieldData,
          currentOperationMode: OperationMode.Edit,
        });
        this.toggleFormTableVisibility();
      } else {
        swal("Teacher Should Be Selected");
      }
      console.log(dataToSave);
    }
  };

  submitHandler = () => {
    const { fromDate, toDate } = this.state;
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    if (this.handleFormValidation()) {
      this.props.getSearchData(fromDt, toDt);
    }
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onFormCancel = () => {
    const { getTableDataById = false } = this.props;
    if (getTableDataById) {
      this.props.onSearchData(this.state.fieldDataForSearch);
    } else {
      if (this.props.getTableData !== "") {
        this.props.getTableData();
      }
    }

    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => (fieldData, rows) => {
    const { currentOperationMode, fieldDataForSearch } = this.state;
    const {
      fieldMeta,
      type = "",
      dataSetToAdd = [],
      addValidation = false,
      DocumentUpload = "",
    } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };
    if (type != "") {
      dataToSave["formType"] = type;
    }
    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "multiSelect") {
        let multiselectValue = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey].join()
          : "";

        dataToSave[currentField.dataKey] = multiselectValue;
      } else if (currentField.controlType === "timePicker") {
        let timeValue =
          fieldData[currentField.dataKey] === ""
            ? ""
            : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
        dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
      } else if (currentField.controlType === "datepicker") {
        if (fieldData[currentField.dataKey]) {
          let date = new Date(fieldData[currentField.dataKey]);

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
        }

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        if (fieldData[currentField.tableDataKey] == currentField.open) {
          dataToSave[currentField.tableDataKey] = fieldData[
            currentField.dataKey
          ]
            ? fieldData[currentField.dataKey]
            : "";
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (fieldData[currentField.tableDataKey] == currentField.open) {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
          }

          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    if (addValidation) {
      dataSetToAdd.forEach((data) => {
        if (fieldDataForSearch[data.value] !== undefined) {
          if (data.isRootLevelKey) {
            dataToSave[data.value] = {};
            dataToSave[data.value]["id"] = fieldDataForSearch[data.value]
              ? fieldDataForSearch[data.value]
              : "";
          } else {
            dataToSave[data.value] = fieldDataForSearch[data.value];
          }
        }
      });
    }
    dataToSave[DocumentUpload] = rows;
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL, sendEmptyId = false } = this.props;
    this.setState({
      isLoading: true,
    });
    console.log(dataToSave);
    let saveData = [{ ...dataToSave, userRegistration: { id: "" } }];
    console.log(saveData);
    apiPost({
      url: apiBaseURL,
      postBody: sendEmptyId ? saveData[0] : dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        const { getTableDataById = false } = this.props;
        if (getTableDataById) {
          this.props.onSearchData(this.state.fieldDataForSearch);
        } else {
          if (this.props.getTableData !== "") {
            this.props.getTableData();
          }
        }
        if (isToggle) {
          this.toggleFormTableVisibility();
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        }
        if (!isToggle) {
          let fieldData = {};
          const { fieldMeta } = this.props;
          fieldMeta.map((currentField) => {
            if (currentField.defaultValue) {
              fieldData[currentField.dataKey] = currentField.defaultValue;
            }
            if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              fieldData[currentField.dataKey] = dataToSave[
                currentField.dataKey
              ]["id"]
                ? dataToSave[currentField.dataKey]["id"]
                : "";
            } else if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              fieldData[currentField.dataKey] = dataToSave[currentField.dataKey]
                ? dataToSave[currentField.dataKey]
                : "";
            }
          });

          this.setState({
            fieldData: fieldData,
            currentOperationMode: OperationMode.Add,
          });
        }
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (rowdata) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const { getTableDataById = false } = this.props;
        if (getTableDataById) {
          this.props.onSearchData(this.state.fieldDataForSearch);
        } else {
          if (this.props.getTableData !== "") {
            this.props.getTableData();
          }
        }
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const { fieldMeta } = this.props;

        const dataToSave = {
          id: rowData["id"],
        };

        fieldMeta.forEach((currentField) => {
          if (
            currentField.controlType === "autocomplete" &&
            !currentField.isRootLevelKey
          ) {
            dataToSave[currentField.dataKey] = {};
            dataToSave[currentField.dataKey]["id"] = rowData[
              currentField.dataKey
            ]
              ? rowData[currentField.dataKey]
              : "";
          } else {
            dataToSave[currentField.dataKey] = rowData[currentField.dataKey]
              ? rowData[currentField.dataKey]
              : "";
          }
        });

        dataToSave["isActive"] = rowData.isActive === 1 ? "0" : "1";
        this.setState({
          currentOperationMode: OperationMode.Status,
        });
        this.onSave(dataToSave, false);
      }
    });
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    tableColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  onFormSearch = () => () => {
    const { fieldDataForSearch } = this.state;
    const { searchList } = this.props;
    const dataToSave = {};
    searchList.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        let date = new Date(fieldDataForSearch[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldDataForSearch[currentField.dataKey] =
          year + "-" + month + "-" + day;

        dataToSave[currentField.dataKey] = fieldDataForSearch[
          currentField.dataKey
        ]
          ? fieldDataForSearch[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldDataForSearch[
          currentField.dataKey
        ]
          ? fieldDataForSearch[currentField.dataKey]
          : "";
      }
    });
    console.log(dataToSave);
    this.props.onSearchData(dataToSave);
  };

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (fromDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  render() {
    const {
      screenTitle = "",
      showAddButtonInTable = false,
      showSaveNextBtn = true,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      fieldMeta,
      dynamicMasterData = {},
      tableColumns = [],
      showLoader = true,
      showSearchBox = true,
      showInPaper = true,
      searchList = [],
      getListById,
      onSubmit,
      showBackToListBtn,
      onBackToList,
      showSend,
      rowSend,
      showEditIcon,
      showDeleteIcon,
      sendEmptyId = false,
      deleteUrl = "",
      isFilter = false,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      fieldData,
      fieldDataForSearch,
      showTable,
      showForm,
      isLoading,
      fromDate,
      toDate,
      DataToSet,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={tableColumnsToFilter}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={screenTitle}
          showSearchBox={showSearchBox}
          showPdfDownload={showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={showAddButton}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
          showBackToListBtn={showBackToListBtn}
          onBackToList={onBackToList}
        />
        {showInPaper && <br />}

        <Paper
          sx={{
            p: showInPaper ? 2 : 0,
            pt: showInPaper ? 2 : 0,
            borderRadius: showInPaper ? 2 : 0,
          }}
          elevation={0}
        >
          {showForm && (
            <>
              <DynamicFromWithImageUpload
                fieldMeta={fieldMeta}
                dynamicMasterData={dynamicMasterData}
                rowEdit={fieldData}
                getListById={getListById}
                onDataChange={this.onDataChange}
                showSaveNextBtn={showSaveNextBtn}
                onCancel={this.onFormCancel}
                DataToSet={DataToSet}
                onSave={this.onFormSave(true)}
                sendEmptyId={sendEmptyId}
                deleteUrl={deleteUrl}
              />
              <br />
            </>
          )}

          {showTable && (
            <>
              {searchList.length !== 0 && (
                <>
                  <DynamicFormWithTable
                    fieldMeta={searchList}
                    dynamicMasterData={dynamicMasterData}
                    fieldData={fieldDataForSearch}
                    onDataChange={this.onDataChangeForSearch}
                    showSaveNextBtn={showSaveNextBtn}
                    onCancel={this.onFormCancel}
                    onSave={this.onFormSearch(true)}
                  />
                </>
              )}
              {isFilter && (
                <Grid container justifyContent="space-evenly" direction="row">
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={4}
                      lg={4}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="From Date"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="fromDate"
                          size="small"
                          value={fromDate}
                          fullWidth
                          onChange={this.onDateChange("fromDate")}
                          isError={fromDateError ? true : false}
                          errorText={fromDateError ? fromDateError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={4}
                      lg={4}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="To Date  "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="toDate"
                          size="small"
                          value={toDate}
                          fullWidth
                          onChange={this.onDateChange("toDate")}
                          isError={toDateError ? true : false}
                          errorText={toDateError ? toDateError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1}
                      lg={1}
                      container
                      justifyContent="flex-end"
                    >
                      <Grid item xs={12} sm={12} md={12}></Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Search"
                          onClick={this.submitHandler}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                  </Grid>
                </Grid>
              )}
              <RTTable
                columns={tableColumns}
                hiddenColumnNames={tableColumnsToFilter.map((item) => {
                  return !item.isChecked ? item.columnKeyName : "";
                })}
                showAddButtonInTable={showAddButtonInTable}
                tableData={this.getFilteredTableData()}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                onChangeStatus={this.onChangeStatus}
                rowAdd={this.rowAdd}
                showSend={showSend}
                rowSend={rowSend}
                showDeleteIcon={showDeleteIcon}
                showEditIcon={showEditIcon}
              />
            </>
          )}
        </Paper>
        {isLoading && <Loading1 />}
        {!showLoader && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = { showNotification, getAlert };
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
