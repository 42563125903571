import { Grid, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { Loading1 } from "../../../components/Loading1";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import {
  getDeemedMeetingHistory,
  getDeemedMeetingSchedule,
  getDeemedMeetingById,
} from "../Meeting/meeting.slice";
let docs;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
class MinutesOfMeeting extends Component {
  state = {
    id: 0,
    name: "",
    details: "",
    employeeDepartment: "",
    openingRemark: "",
    closingRemark: "",
    dialogue: false,
    attendance: false,
    showLoader: false,
    meeting: false,
    showView: false,
    showForm: true,
    scheduleMeetingData: {},
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = (e) => {
    const { openingRemark, closingRemark } = this.state;
    const { scheduleMeeting, showView } = this.props;
    e.preventDefault();
    if (showView) {
      this.props.isNextTab();
    } else {
      let docs = {
        committee: {
          id: scheduleMeeting.committee.id,
        },
        id: scheduleMeeting.id,
        openingRemark: openingRemark,
        closingRemark: closingRemark,
        details: scheduleMeeting.details,
        title: scheduleMeeting.title,
        date: scheduleMeeting.date,
        time: scheduleMeeting.time,
        venue: scheduleMeeting.venue,
        urlLink: scheduleMeeting.urlLink,
      };
      apiPost({
        url: endpoints.deemedMeeting,
        postBody: docs,
      }).then(({ success }) => {
        if (success) {
          this.props.showNotification({ msg: "Data saved successfully" });
          this.props
            .getDeemedMeetingById({ id: this.state.scheduleMeetingData.id })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          this.props.getDeemedMeetingSchedule().then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
          this.props.getDeemedMeetingHistory().then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

          this.props.isNextTab();
        }
      });
    }
  };

  componentDidMount() {
    const {
      showAttendanceTable,
      scheduleMeeting,
      showAggenda,
      showView,
      deemedMeetingList,
    } = this.props;

    if (Object.keys(scheduleMeeting).length !== 0) {
      if (
        scheduleMeeting.openingRemark !== null &&
        scheduleMeeting.closingRemark !== null
      ) {
        if (
          scheduleMeeting.openingRemark !== "" &&
          scheduleMeeting.closingRemark !== ""
        ) {
          this.props.isDisabledFalse();
        }
      }
      this.setState({
        openingRemark: deemedMeetingList.deemedMeetingById.openingRemark,
        closingRemark: deemedMeetingList.deemedMeetingById.closingRemark,
      });
    }

    this.setState({
      attendance: showAttendanceTable,
      scheduleMeetingData: scheduleMeeting,
      dialogue: showAggenda,
      showView: showView,
      showForm: showView === true ? false : true,
    });
  }

  onCancel = (dataToSave) => {
    this.setState({ dialogue: false, attendance: true });
  };

  handledChange = (value) => {
    this.setState({ value: value });
  };

  onSaveTable = (data) => {
    let dataToSave = data.map((data1) => {
      if (data1.tableId !== "") {
        let docs = {
          id: data1.tableId,
          scheduleCommitteeMeeting: {
            id: this.props.scheduleData.id,
          },
          committeeMember: {
            id: data1.id,
          },
          status: data1.attendance === true ? 1 : 0,
          remark: data1.answer,
        };
        return docs;
      } else {
        let docs = {
          scheduleCommitteeMeeting: {
            id: this.props.scheduleData.id,
          },
          committeeMember: {
            id: data1.id,
          },
          status: data1.attendance === true ? 1 : 0,
          remark: data1.answer,
        };
        return docs;
      }
    });
    this.setState({
      showLoader: true,
    });
    apiPost({
      url: endpoints.deemedAttendance + "/save-all",
      postBody: dataToSave,
    }).then(({ data, success }) => {
      if (success) {
        this.setState({
          showLoader: false,
        });
        this.props.showNotification({ msg: "Data saved successfully" });

        this.props.backToForm();
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  render() {
    const {
      openingRemark,
      closingRemark,
      dialogue,
      attendance,
      meeting,
      value,
      showView,
      showForm,
    } = this.state;
    const { onCancel, backToForm } = this.props;
    return (
      <>
        {showForm && (
          <Paper sx={{ borderRadius: 2 }} elevation={0}>
            <form
              onSubmit={this.submitHandler}
              autoComplete="off"
              noValidate={true}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={1}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Opening Remark "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="openingRemark"
                        size="small"
                        multiline
                        value={openingRemark}
                        onChange={this.changeHandler}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Closing Remark  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="closingRemark"
                        size="small"
                        multiline
                        value={closingRemark}
                        onChange={this.changeHandler}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
        <br />

        {showView && (
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={1}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18, fontWeight: "bold" }}
                    label="Opening Remark "
                  />
                </Grid>
                {openingRemark}
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18, fontWeight: "bold" }}
                    label="Closing Remark "
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Grid>
                {closingRemark}
              </Grid>
            </Grid>
          </Grid>
        )}
        <br />
        <Grid
          sx={{ mr: 0.5 }}
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          m={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name={showView ? "Back" : "Cancel"}
              onClick={this.props.backToForm}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name={showView ? "Next" : "Save"}
              onClick={this.submitHandler}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        {this.state.showLoader && <Loading1 />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  deemedCommitteeList: state.deemedCommittee,
  deemedMeetingList: state.deemedMeeting,
});
const mapDispatchToProps = {
  getDeemedMeetingSchedule,
  getDeemedMeetingHistory,
  getDeemedMeetingById,
  showNotification,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(MinutesOfMeeting);
