import React from "react";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import { FormHelperText, Grid } from "@mui/material";
import { connect } from "react-redux";
import DynamicSelectTable from "../../../../../components/Comman/RT/CheckBoxSelectionTable";
const PrnNumberTable = ({
  isActionColActive,
  onSelectionChange,
  selection,
  prnNumberList,
  generatePrn,
  errorMessage,
}) => {
  const columns = [
    { name: "index", title: "Sr. No.", minWidth: 90 },
    {
      name: "firstName",
      title: "Student Name",
      minWidth: 250,
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
  ];
  const rowPrint = (row) => {};
  return (
    <>
      <DynamicSelectTable
        rows={prnNumberList?.prnNumberNull}
        columns={columns}
        selection={selection}
        isActionColActive={isActionColActive}
        onSelectionChange={onSelectionChange}
      />
      <br />
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid
          sx={{ marginBottom: 2 }}
          container
          justifyContent="left"
          item
          xs={2}
          sm={2}
          md={2}
        >
          {errorMessage && (
            <FormHelperText error>
              {"Select atleast one student"}
            </FormHelperText>
          )}
        </Grid>
        <Grid
          sx={{ marginBottom: 2 }}
          container
          justifyContent="right"
          item
          xs={10}
          sm={10}
          md={10}
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Generate PRN"
            onClick={generatePrn}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  prnNumberList: state.prnNumber,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrnNumberTable);
