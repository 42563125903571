import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { Link as RouterLink } from "react-router-dom";
import AutoComplete from "../../components/Comman/AutoComplete";
import { getAlert } from "../../CommonActions/alert.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import {
  getPromotionalAdmisionAcademicYear,
  getPromotionalAdmsionStudentList,
} from "./promotionalAdmission.slice";
import swal from "sweetalert";
import PromotionalAdmissionTable from "./promotionalAdmissionTable";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { Loading1 } from "../../components/Loading1";

//   import CompletedSyllabus from "./CompletedSyllabus";

function PromotionalAdmissionAdmin({
  editData,
  promotionalAdmmisionList,
  studentAttendanceList,
  saveFormData,
  onSave,
  backToList,
  classList,
  getClass,
  getAlert,
  getPromotionalAdmisionAcademicYear,
  getPromotionalAdmsionStudentList,
}) {
  const [valuesLength, setValuesLength] = React.useState("");
  const [presentCount, setPresentCount] = React.useState("");
  const [absentCount, setAbsentCount] = React.useState("");
  const [rollNoCount, setRollNoCount] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const [values1, setValues1] = React.useState([]);
  const [values1Error, setValues1Error] = React.useState(false);
  const [yearDetailId, setYearDetailId] = React.useState("");
  const [academicYear, setAcademicYear] = React.useState("");
  const [academicYearId, setAcademicYearId] = React.useState("");
  useEffect(() => {
    getClass().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
      }
    });
    getPromotionalAdmisionAcademicYear().then((response) => {
      if (!response.success) {
        getAlert({ message: "Something went wrong" });
      } else {
        setAcademicYear(response.data.data.name);
        setAcademicYearId(response.data.data.id);
      }
    });
  }, []);

  const saveHandler = (row) => {
    if (row.length !== 0) {
      setValues1Error(false);
      swal({
        title: "Are you sure?",
        text: "Do you want to approved status?",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setShowLoader(true);
          console.log(row);
          apiGet({
            url:
              endpoint.promotionalAdmissionStudent +
              "/send-mail-to-approved-students?nextYearAdmissionIds=" +
              row,
          }).then(({ success }) => {
            if (success) {
              setShowLoader(false);
              window.location.replace("/promotional-admission-admin");
            } else {
              setShowLoader(false);
            }
          });
        }
      });
    } else {
      setValues1Error(true);
    }
  };

  const searchHandler = () => {
    getPromotionalAdmsionStudentList({
      yearDetailId: yearDetailId,
      academicYearId: academicYearId,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        setShowTable(true);
      }
    });
  };

  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      setYearDetailId(newValue);
    } else {
      setYearDetailId("");
    }
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Student Promotional Admission"
            />
          </Grid>
          <Grid
            sx={{ mt: 1 }}
            item
            container
            justifyContent="right"
            xs={12}
            md={6}
            lg={6}
          ></Grid>
        </Grid>
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid xs={12} md={5.5} lg={5.5}>
            <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="*Academic Year"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="academicYear"
                id="academicYear"
                value={academicYear}
                // onChange={this.changeHandler}
                fullWidth
                disabled
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5.5} md={5.5}>
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="*Year Details"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <AutoComplete
                keyColName={"id"}
                value={yearDetailId}
                name={"yearDetailId"}
                options={classList?.class}
                onChange={ChangeHandlerSearch}
                // isError={yearDetailIdError ? true : false}
                // errorText={yearDetailIdError ? yearDetailIdError : " "}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1} md={1}>
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label=""
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Search"
                onClick={searchHandler}
              />
            </Grid>
          </Grid>
        </Grid>
        <FormHelperText error>
          {values1Error ? "Select atleast 1 student" : " "}
        </FormHelperText>
        {showTable && <PromotionalAdmissionTable onSave={saveHandler} />}
      </Paper>
      {showLoader && <Loading1 />}
    </>
  );
}

const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
  promotionalAdmmisionList: state.promotionalAdmmision,
  classList: state.class,
});
const mapDispatchToProps = {
  showNotification,
  getClass,
  getAlert,
  getPromotionalAdmisionAcademicYear,
  getPromotionalAdmsionStudentList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionalAdmissionAdmin);
