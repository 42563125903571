import { Grid, Paper, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import { getQualification } from "../../Pages/QualificationMaster/qualification.slice";
const columns = [
  {
    name: "qualificationName",
    title: "Qualification",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "nameOfSchoolClg",
    title: "Name of School/College",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "yearOfPass",
    title: "Year Of Passing",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "boardUniversity",
    title: "Board / University",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "obtainedMarks",
    title: "Obtained Marks",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "totalMarks",
    title: "Total Marks",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "percentage",
    title: "Percentage",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "action",
    title: "Actions",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
class QualificationDetails extends Component
{
  state = {
    id: 0,
    index: 0,
    qualificationName: "",
    boardUniversity: "",
    obtainedMarks: "",
    totalMarks: "",
    percentage: "",
    yearOfPass: "",
    nameOfSchoolClg: "",
    country: "",
    state: "",
    board: "",
    formErrors: [],
  };

  changedHandler = ( event ) =>
  {
    const { name, value } = event.target;
    console.log( name, value );
    const { obtainedMarks, totalMarks } = this.state;

    if ( name === "obtainedMarks" && totalMarks !== "" )
    {
      let per = ( value / totalMarks ) * 100;
      this.setState( {
        percentage: per.toFixed( 2 ),
        obtainedMarks: value,
      } );
    } else if ( name === "totalMarks" && obtainedMarks !== "" )
    {
      let per = ( obtainedMarks / value ) * 100;
      this.setState( {
        percentage: per.toFixed( 2 ),
        totalMarks: value,
      } );
    } else
    {
      this.setState( {
        ...this.state,
        [ name ]: value,
      } );
    }
  };
  handleFormValidation( name )
  {
    const {
      qualificationName,
      boardUniversity,
      obtainedMarks,
      totalMarks,
      yearOfPass,
      nameOfSchoolClg,
      state, board, country
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( qualificationName.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "qualificationNameError" ] = myConstClass.qualificationMsg;
    }

    if ( state.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "stateError" ] = myConstClass.stateMsg;
    }
    if ( board.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "boardError" ] = myConstClass.boardMsg;
    } if ( country.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "countryError" ] = myConstClass.countryMsg;
    }



    if ( boardUniversity.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "boardUniversityError" ] = myConstClass.uniNameMsg;
    }

    // if ( obtainedMarks > totalMarks )
    // {
    //   formIsValid = false;
    //   formErrors[ "obtainedMarksError" ] = myConstClass.obtainedMarksEr;
    // }

    if ( obtainedMarks.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "obtainedMarksError" ] = myConstClass.marksobtMsg;
    }

    if ( totalMarks.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "totalMarksError" ] = myConstClass.totalMarksMsg;
    }

    if (
      totalMarks !== '' &&
      obtainedMarks !== '' &&
      +obtainedMarks > +totalMarks
    )
    {
      formIsValid = false;
      formErrors[ "obtainedMarksError" ] =
        "*Obtain marks must be less than out of marks";
    }
    if ( yearOfPass.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "yearOfPassError" ] = myConstClass.passoutMsg;
    }
    if ( yearOfPass.trim() !== "" && yearOfPass.length !== 4 )
    {
      formIsValid = false;
      formErrors[ "yearOfPassError" ] = 'Maximum 4 digit required';
    }
    if ( nameOfSchoolClg.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "nameOfSchoolClgError" ] = "Name of school/college requird";
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  componentDidMount()
  {
    getQualification();
  }

  componentDidMount()
  {
    const { editRows } = this.props;
    console.log( editRows );
    if ( editRows.length > 0 )
    {
      this.setState( {
        id: editRows[ 0 ].id,
        index: editRows[ 0 ].index,
        qualificationName: editRows[ 0 ].qualificationId,
        yearOfPass: editRows[ 0 ].yearOfPass,
        nameOfSchoolClg: editRows[ 0 ].nameOfSchoolClg,
        boardUniversity: editRows[ 0 ].boardUniversity,
        obtainedMarks: editRows[ 0 ].obtainedMarks,
        totalMarks: editRows[ 0 ].totalMarks,
        percentage: editRows[ 0 ].percentage,
        country: editRows[ 0 ].country,
        board: editRows[ 0 ].board,
        state: editRows[ 0 ].state,
      } );
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   const { editRows } = this.props;
  //   if (this.props.editRows !== nextProps.editRows) {
  //     if (this.props.editRows !== nextProps.editRows) {
  //       this.setState({
  //         id: editRows[0].id,
  //         qualificationName: editRows[0].qualificationName,
  //         boardUniversity: editRows[0].boardUniversity,
  //         obtainedMarks: editRows[0].obtainedMarks,
  //         yearOfPass: editRows[0].yearOfPass,
  //         totalMarks: editRows[0].totalMarks,
  //         percentage: editRows[0].percentage,
  //       });
  //     }
  //   }
  // }

  changeHandlerNumber = ( event ) =>
  {
    const { name, value } = event.target;
    {
      if ( event.target.value === "" || onlyNumber.test( event.target.value ) )
      {
        this.setState( {
          ...this.state,
          [ name ]: value,
        } );
      }
    }
  };
  componentWillReceiveProps( nextProps )
  {
    const { editRows } = this.props;
    console.log( editRows );
    if ( editRows !== nextProps.editRows && nextProps.editRows.length !== 0 )
    {
      this.setState( {
        id: nextProps.editRows[ 0 ].id,
        index: nextProps.editRows[ 0 ].index,
        qualificationName: nextProps.editRows[ 0 ].qualificationId,
        yearOfPass: nextProps.editRows[ 0 ].yearOfPass,
        nameOfSchoolClg: nextProps.editRows[ 0 ].nameOfSchoolClg,
        boardUniversity: nextProps.editRows[ 0 ].boardUniversity,
        obtainedMarks: nextProps.editRows[ 0 ].obtainedMarks,
        totalMarks: nextProps.editRows[ 0 ].totalMarks,
        percentage: nextProps.editRows[ 0 ].percentage,
        country: nextProps.editRows[ 0 ].country,
        state: nextProps.editRows[ 0 ].state,
        board: nextProps.editRows[ 0 ].board,
      } );
    }
  }

  ChangeHandlerSearch = ( name, newValue ) =>
  {
    if ( newValue !== null )
    {
      this.setState( { [ name ]: newValue } );
    } else
    {
      this.setState( { [ name ]: "" } );
    }
  };

  submitHandler = ( e ) =>
  {
    e.preventDefault();
    const {
      qualificationName,
      boardUniversity,
      obtainedMarks,
      totalMarks,
      percentage,
      country,
      state,
      board,
      yearOfPass,
      nameOfSchoolClg,
      id,
      index
    } = this.state;
    if ( this.handleFormValidation() )
    {
      let viewRow = this.props.qualificationsList?.qualification.filter(
        ( row ) => row.id === qualificationName
      );
      console.log( viewRow );

      let data = {
        index: index,
        qualificationName: viewRow[ 0 ].name,
        qualificationId: qualificationName,
        nameOfSchoolClg: nameOfSchoolClg,
        yearOfPass: yearOfPass,

        boardUniversity: boardUniversity,
        obtainedMarks: obtainedMarks,
        totalMarks: totalMarks,
        percentage: percentage,
        country: country,
        state: state,
        board: board,
      };
      console.log( data );
      if ( id !== 0 )
      {
        data = {
          ...data,
          id: id,
        };
      }
      this.props.rowData( data );
      this.setState( {
        id: 0,
        index: 0,
        qualificationName: "",
        boardUniversity: "",
        obtainedMarks: "",
        totalMarks: "",
        percentage: "",
        country: "",
        state: "",
        board: "",
        yearOfPass: "",
        nameOfSchoolClg: "",
      } );
      console.log( data );
    }
  };

  render()
  {
    const {
      qualificationName,
      boardUniversity,
      obtainedMarks,
      totalMarks,
      percentage,
      country,
      state,
      board,
      yearOfPass,
      nameOfSchoolClg,
    } = this.state;

    const {
      qualificationNameError,
      yearOfPassError,
      boardUniversityError,
      totalMarksError,
      obtainedMarksError,
      percentageError,
      countryError,
      stateError,
      boardError,
      nameOfSchoolClgError,
    } = this.state.formErrors;

    const { row, rowView, rowDelete, rowEdit, qualificationsList } = this.props;
    console.log(
      "🚀 ~ file: QualificationDetails.jsx ~ line 213 ~ QualificationDetails ~ render ~ qualificationsList",
      qualificationsList.qualification
    );
    return (
      <>
        {console.log( qualificationsList?.qualification )}
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid sx={{ mb: 1, mt: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Qualification Details"
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={4}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Country"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="country"
                      size="small"
                      value={country}
                      onChange={this.changedHandler}
                      fullWidth
                      error={countryError ? true : false}
                      helperText={countryError ? countryError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="State"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="state"
                      size="small"
                      value={state}
                      onChange={this.changedHandler}
                      fullWidth
                      error={stateError ? true : false}
                      helperText={stateError ? stateError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Board"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="board"
                      size="small"
                      value={board}
                      onChange={this.changedHandler}
                      fullWidth
                      error={boardError ? true : false}
                      helperText={boardError ? boardError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Qualification Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={qualificationName}
                      name={"qualificationName"}
                      options={qualificationsList?.qualification.filter((data)=>data.isActive==1)}
                      onChange={this.ChangeHandlerSearch}
                      isError={qualificationNameError ? true : false}
                      errorText={
                        qualificationNameError ? qualificationNameError : " "
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Name of School/College"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="nameOfSchoolClg"
                      size="small"
                      value={nameOfSchoolClg}
                      fullWidth
                      onChange={this.changedHandler}
                      error={nameOfSchoolClgError ? true : false}
                      helperText={
                        nameOfSchoolClgError ? nameOfSchoolClgError : " "
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Year of Passing"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="yearOfPass"
                      size="small"
                      value={yearOfPass}
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                      onChange={this.changeHandlerNumber}
                      error={yearOfPassError ? true : false}
                      helperText={yearOfPassError ? yearOfPassError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={3}
                  xs={12}
                  md={3}
                  lg={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="University "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="boardUniversity"
                      size="small"
                      value={boardUniversity}
                      fullWidth
                      onChange={this.changedHandler}
                      error={boardUniversityError ? true : false}
                      helperText={
                        boardUniversityError ? boardUniversityError : " "
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={3}
                  xs={12}
                  md={3}
                  lg={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Obtained Marks "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="obtainedMarks"
                      size="small"
                      value={obtainedMarks}
                      fullWidth
                      type="number"
                      onChange={this.changedHandler}
                      error={obtainedMarksError ? true : false}
                      helperText={obtainedMarksError ? obtainedMarksError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={3}
                  xs={12}
                  md={3}
                  lg={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Total Marks "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="totalMarks"
                      size="small"
                      value={totalMarks}
                      fullWidth
                      type="number"
                      onChange={this.changedHandler}
                      error={totalMarksError ? true : false}
                      helperText={totalMarksError ? totalMarksError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={3}
                  xs={12}
                  md={3}
                  lg={3}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Percentage "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="percentage"
                      size="small"
                      value={percentage}
                      fullWidth
                      disabled={true}
                      error={percentageError ? true : false}
                      helperText={percentageError ? percentageError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                sx={{ marginTop: -2 }}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  container
                  columnSpacing={1}
                  justifyContent="right"
                  sx={{ marginBottom: 1 }}
                >
                  <Grid item justifyContent="space-evenly">
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Add"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
                  <DynamicTable
                    data={row}
                    tableHead={columns}
                    showHeadDelete={true}
                    showHeadEdit={true}
                    rowDelete={this.props.rowDelete}
                    rowEdit={this.props.rowEdit}
                    showPegination={false}
                  />
                </Grid>


              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  qualificationsList: state.qualification,
} );
const mapDispatchToProps = { getQualification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( QualificationDetails );
