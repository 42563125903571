import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  feedback: [],
  isFetch: false,
  feedbackData: {},
  isFetchFeedback: false,
  feedbackByDate: [],
  appliedListData: [],
  isFetchAppliedList: false,
  isFetchFeedbackByDate: false,
};
let URL = endpoints.employeeFeedback;
let URL2 = endpoints.employeeFeedbackTransaction;
const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    feedbackSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        feedbackData: row,
        isFetchFeedback: true,
      };
    },
    appliedListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        appliedListData: row,
        isFetchAppliedList: true,
      };
    },
    feedbackByDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        feedbackByDate: row,
        isFetchFeedbackByDate: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  resetSuccess,
  feedbackSuccess,
  feedbackByDateSuccess,
  appliedListSuccess,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const getFeedback = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const feedbackList = data.data.content;
        let index = 0;
        let row = [];
        feedbackList.map((data1) => {
          index = index + 1;
          const departments = data1.departments.map((data) => {
            return data.name;
          });
          let bData = {
            index: index,
            id: data1.id,
            feedbackName: data1.feedbackHead.name,
            department: departments.join(),
            noticeFor: data1.noticeFor,
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            labelText: data1.labelText,
            highlightLabel: data1.highlightLabel,
            isActive: data1.isActive,
            fromTo: data1.fromDate + " -" + data1.toDate,
            departmentIds: data1.departmentIds,
            eventDetails: data1.eventDetails,
            deptAuthorityToView: data1.deptAuthorityToView,
            empAuthorityToView: data1.empAuthorityToView,
          };
          row.push(bData);
          return data1;
        });
        dispatch(feedbackByDateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getFeedbackByEventId =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/by-event-id",
      }).then(({ data, success }) => {
        if (success && data.data !== null) {
          const feedbackData = data.data;
          let index = 0;
          //   const departments = feedbackData.departments.map( ( data ) =>
          // {
          //     return data.name
          // } )
          let feedback = {
            index: index,
            id: feedbackData.id,
            feedbackName: feedbackData.feedbackHead.name,
            department: feedbackData.departmentIds,
            noticeFor: feedbackData.noticeFor,
            fromDate: feedbackData.fromDate,
            toDate: feedbackData.toDate,
            labelText: feedbackData.labelText,
            highlightLabel: feedbackData.highlightLabel,
            isActive: feedbackData.isActive,
            fromTo: feedbackData.fromDate + " - " + feedbackData.toDate,
            departmentIds: feedbackData.departmentIds,
            description: feedbackData.feedbackDetails,
            eventDetails: feedbackData.eventDetails,
            deptAuthorityToView: feedbackData.deptAuthorityToView,
            empAuthorityToView: feedbackData.empAuthorityToView,
          };

          dispatch(feedbackSuccess({ row: feedback }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getAppliedList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/get-employee-feedback-transaction",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            // const departments= data1.departments.map((data)=>{
            //     return data.name
            //    })
            let bData = {
              index: index,
              id: data1.id,
              // employeeName: data1.userRegistration == null ? "-" : data1.userRegistration.firstName + " " + data1.userRegistration.middleName + " " + data1.userRegistration.lastName,
              // designation: data1.userRegistration == null ? "-" : data1.userRegistration.designation,
              employeeName: data1.name,
              designation: data1.designation,
              emailId: data1.emailId,
              department:
                data1.userRegistration == null
                  ? "-"
                  : data1.userRegistration.userRegistrationDepartment.name,
              employeeFeedbackTransactionDetails:
                data1.employeeFeedbackTransactionDetails.map(
                  (employeeFeedbackTransactionDetailData, empIndex) => {
                    let feedBack = {
                      index: empIndex + 1,
                      id: employeeFeedbackTransactionDetailData.id,
                      question:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.question,
                      optionType:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.optionType,
                      answer: employeeFeedbackTransactionDetailData.value,
                      answerValid: 0,
                      isActive:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.isActive,
                      feedbackHeadDetailsOptions:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.feedbackHeadDetailsOptions,
                      //   id:  employeeFeedbackTransactionDetailData.id,
                      //   value:employeeFeedbackTransactionDetailData.value,
                      //   feedbackHeadDetails:employeeFeedbackTransactionDetailData.feedbackHeadDetails
                    };
                    return feedBack;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(appliedListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAppliedListFromEventId =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL2 + "/" + id + "/by-event-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            // const departments= data1.departments.map((data)=>{
            //     return data.name
            //    })
            let bData = {
              index: index,
              id: data1.id,
              // employeeName: data1.userRegistration == null ? "-" : data1.userRegistration.firstName + " " + data1.userRegistration.middleName + " " + data1.userRegistration.lastName,
              // designation: data1.userRegistration == null ? "-" : data1.userRegistration.designation,
              employeeName: data1.name,
              designation: data1.designation,
              emailId: data1.emailId,
              department:
                data1.userRegistration == null
                  ? "-"
                  : data1.userRegistration.userRegistrationDepartment.name,
              employeeFeedbackTransactionDetails:
                data1.employeeFeedbackTransactionDetails.map(
                  (employeeFeedbackTransactionDetailData, empIndex) => {
                    let feedBack = {
                      index: empIndex + 1,
                      id: employeeFeedbackTransactionDetailData.id,
                      question:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.question,
                      optionType:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.optionType,
                      answer: employeeFeedbackTransactionDetailData.value,
                      answerValid: 0,
                      isActive:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.isActive,
                      feedbackHeadDetailsOptions:
                        employeeFeedbackTransactionDetailData
                          .feedbackHeadDetails.feedbackHeadDetailsOptions,
                      //   id:  employeeFeedbackTransactionDetailData.id,
                      //   value:employeeFeedbackTransactionDetailData.value,
                      //   feedbackHeadDetails:employeeFeedbackTransactionDetailData.feedbackHeadDetails
                    };
                    return feedBack;
                  }
                ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(appliedListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getFeedbackFromDtToDt =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-feedback-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            const departments = data1.departments.map((data) => {
              return data.name;
            });
            let bData = {
              index: index,
              id: data1.id,
              feedbackName: data1.feedbackHead.name,
              feedbackNameId: data1.feedbackHead.id,
              department: departments.join(),
              noticeFor: data1.noticeFor,
              fromDate: data1.fromDate,
              toDate: data1.toDate,

              labelText: data1.labelText,
              highlightLabel: data1.highlightLabel,
              fromTo: data1.fromDate + " -" + data1.toDate,
              departmentIds: data1.departmentIds ? data1.departmentIds : "",
              description: data1.feedbackDetails,
              deptAuthorityToView: data1.deptAuthorityToView
                ? data1.deptAuthorityToView
                : "",
              empAuthorityToView: data1.empAuthorityToView
                ? data1.empAuthorityToView
                : "",
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(feedbackByDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// new

// http://localhost:8088/api/acdmc/v1/employee-feedback/get-employee-feedback-by-limit?limit=1
export const getFeedbackFromDtToDtFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-feedback-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          const departments = data1.departments.map((data) => {
            return data.name;
          });
          let bData = {
            index: index,
            id: data1.id,
            feedbackName: data1.feedbackHead.name,
            feedbackNameId: data1.feedbackHead.id,
            department: departments.join(),
            noticeFor: data1.noticeFor,
            fromDate: data1.fromDate,
            toDate: data1.toDate,

            labelText: data1.labelText,
            highlightLabel: data1.highlightLabel,
            fromTo: data1.fromDate + " -" + data1.toDate,
            departmentIds: data1.departmentIds ? data1.departmentIds : "",
            description: data1.feedbackDetails,
            deptAuthorityToView: data1.deptAuthorityToView
              ? data1.deptAuthorityToView
              : "",
            empAuthorityToView: data1.empAuthorityToView
              ? data1.empAuthorityToView
              : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(feedbackByDateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getFeedbackSelfFromDtToDt =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/view-response?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            const departments =
              data1.departments !== null
                ? data1.departments.map((data) => {
                    return data.name;
                  })
                : [];
            let bData = {
              index: index,
              id: data1.id,
              feedbackName: data1.feedbackHead.name,
              feedbackNameId: data1.feedbackHead.id,
              department: departments.join(),
              noticeFor: data1.noticeFor,
              fromDate: data1.fromDate,
              toDate: data1.toDate,

              labelText: data1.labelText,
              highlightLabel: data1.highlightLabel,
              fromTo: data1.fromDate + " -" + data1.toDate,
              departmentIds: data1.departmentIds ? data1.departmentIds : "",
              description: data1.feedbackDetails,
              deptAuthorityToView: data1.deptAuthorityToView
                ? data1.deptAuthorityToView
                : "",
              empAuthorityToView: data1.empAuthorityToView
                ? data1.empAuthorityToView
                : "",
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(feedbackByDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/employee-feedback/view-response-by-limit?limit=1
export const getFeedbackSelfFromDtToDtFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/view-response-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          const departments =
            data1.departments !== null
              ? data1.departments.map((data) => {
                  return data.name;
                })
              : [];
          let bData = {
            index: index,
            id: data1.id,
            feedbackName: data1.feedbackHead.name,
            feedbackNameId: data1.feedbackHead.id,
            department: departments.join(),
            noticeFor: data1.noticeFor,
            fromDate: data1.fromDate,
            toDate: data1.toDate,

            labelText: data1.labelText,
            highlightLabel: data1.highlightLabel,
            fromTo: data1.fromDate + " -" + data1.toDate,
            departmentIds: data1.departmentIds ? data1.departmentIds : "",
            description: data1.feedbackDetails,
            deptAuthorityToView: data1.deptAuthorityToView
              ? data1.deptAuthorityToView
              : "",
            empAuthorityToView: data1.empAuthorityToView
              ? data1.empAuthorityToView
              : "",
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(feedbackByDateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetValue = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
