import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
const initialState = {
  internshipDocument: [],
  isFetchInternshipDocument: false,
};
let URL = endpoints.internshipDocument;
const internshipDocumentSlice = createSlice({
  name: "internshipDocument",
  initialState,
  reducers: {
    internshipDocumentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        internshipDocument: row,
        isFetchInternshipDocument: true,
      };
    },
  },
});

export const { internshipDocumentSuccess } = internshipDocumentSlice.actions;

export default internshipDocumentSlice.reducer;

export const getInternshipDocument = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            fileName: data1.fileName,
            docName: data1.docName,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(internshipDocumentSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
