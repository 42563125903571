import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddmissionFeesDetailsTable from "../AdmissionFeesDetails/AddmissionFeesDetailsTable";
import StudentMaster from "../StudentDetails/index";
import AdmissionForm from "./AdmissionForm";
import {
  resetError,
  saveAdmissionForm,
  getKeyPreLogin,
} from "./admissionForm.slice";
import SubjectSelected from "./SubjectSelected";
import SubjectSelection from "./SubjectSelection";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import Header from "../../components/NewHeader";
import Navbar from "../../components/Navbar";
import Alert from "../../CommonActions/Alert";
const AdmissionProcess = ({
  getAlert,
  alertInfo,
  showNotification,
  studentList,
  resetError,
  saveAdmissionForm,
  getKeyPreLogin,
}) => {
  const [appliData, setApplicantData] = useState("");
  const [basicDetails, setBasicDetails] = useState(false);
  const [selectionData, setSelectionData] = useState(false);
  const [openSubjectSelected, setOpenSubjectSelected] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [feesDetails, setFeesDetails] = useState(false);
  const [feeStructure, setFeeStructure] = useState("");

  const saveSelectedSUbject = (data) => {
    setOpenSubjectSelected(true);
    setSelectedSubject(data);
  };

  const closeSelectedSubject = () => {
    setOpenSubjectSelected(false);
  };
  const closeFeesdetails = () => {
    setFeesDetails(false);
    setOpenSubjectSelected(false);
  };
  const closeSubject = () => {
    setSelectionData(false);
  };
  const closeSubjectSelection = () => {
    setBasicDetails(false);
  };
  const saveAppicantData = (docs) => {
    setBasicDetails(true);
    setApplicantData(docs);
  };
  const subjectSelection = () => {
    if (studentList?.keySuccess.value === 1) {
      setSelectionData(true);
    } else {
      setFeesDetails(true);
    }
  };
  const goToFeesDetails = () => {
    setFeesDetails(true);
  };
  const goToFeesStructute = (docs) => {
    let subjectListToSave = [];
    if (studentList?.keySuccess.value === 1) {
      studentList.subjectSelection.map((semister) => {
        semister.subjectList.map((subject) => {
          Object.keys(selectedSubject.checkedValues).map(
            (innerkey, innerindex) => {
              if (
                selectedSubject.checkedValues[innerkey] &&
                innerkey === subject.subjectName
              ) {
                const subjectDetails = subject.subjectDetails.map(
                  (subjectDetailsData) => {
                    let data = {
                      marks: "0",
                      grade: "0",
                      result: "0",
                      subjectDetail: {
                        id: subjectDetailsData.id,
                      },
                    };
                    return data;
                  }
                );
                let subjectData = {
                  userRegistration: {
                    id: "",
                  },
                  subjectId: subject.id,
                  academicYearId: studentList.academicYear.id,
                  studentExamMarks: subjectDetails,
                };
                subjectListToSave.push(subjectData);
              }
            }
          );
        });
      });
    }
    setFeeStructure(docs);

    let data = {
      appliId: appliData.applicationId,
      feeDetail: docs.feesSturctureHeadId,
      academicYear: studentList.academicYear.id,
    };

    saveAdmissionForm({
      paramsData: data,
      saveToData: subjectListToSave,
      domainName: window.location.host,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        showNotification({
          msg: "Data saved successfully",
        });
        window.location.replace("/login");
      }
    });
  };
  useEffect(() => {
    getKeyPreLogin({ domainName: window.location.host });
    resetError();
  }, []);

  return (
    <>
      <Header />
      <Navbar />
      {alertInfo.show && <Alert msg={alertInfo.alertMsg} />}
      {!basicDetails && (
        <AdmissionForm appliData={appliData} onSave={saveAppicantData} />
      )}
      {basicDetails && !selectionData && !feesDetails && (
        <StudentMaster
          onCanclePersonal={closeSubjectSelection}
          onSubjectSelection={subjectSelection}
        />
      )}
      {selectionData && !openSubjectSelected && (
        <SubjectSelection
          selectedSubject={selectedSubject}
          onClose={closeSubject}
          onSave={saveSelectedSUbject}
        />
      )}
      {openSubjectSelected && !feesDetails && (
        <SubjectSelected
          selectedSubject={selectedSubject}
          onClose={closeSelectedSubject}
          onSave={goToFeesDetails}
        />
      )}
      {feesDetails && (
        <AddmissionFeesDetailsTable
          feeStructure={feeStructure}
          onCancel={closeFeesdetails}
          onSave={goToFeesStructute}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  studentList: state.admissionForm,
  alertInfo: state.alertInfo,
});
const mapDispatchToProps = {
  saveAdmissionForm,
  resetError,
  showNotification,
  getAlert,
  getKeyPreLogin,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdmissionProcess);
