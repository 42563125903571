import { Grid } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import RTTable from "../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../components/dynamicscreens/DynamicFormWithImageUpload";
import { Loading1 } from "../../../components/Loading1";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { AlumniMentorJson } from "../../../DynamicFormsJson/AlumniMentor";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { AlumniMentorColumns } from "../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import {
  getVolunteerById,
  getVolunteerByRegistrationId,
} from "../AdminSideAlimini/AlumniVolunteerStatus/AdminVolunteerStatus.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AlumniMentor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      formErrors: {},
      showTable: true,
      showLoader: false,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fileName: "",
      experties: "",
      time: "",
      department: "",
      // dynamicMasterData: {},
      // dynamicMasterFormData
      dynamicMasterData: {
        DepartmentMaster: this.props.departmentList.department,
      },
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    const { getVolunteerById } = this.props;
    this.setState({
      showLoader: true,
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
    this.props.getVolunteerById().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    let deptId = this.props.departmentList.department.filter(
      (row) => row.name == rowData.message
    );
    console.log(deptId);

    console.log(rowData);
    this.toggleFormTableVisibility();
    let fieldData = {};
    this.props.onEdit();
    AlumniMentorJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    console.log(fieldData);
    fieldData["message"] = deptId[0].id;
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    fieldData["rows"] = [
      {
        fileName: rowData.documentName,
        docName: rowData.remark,
      },
    ];
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            DepartmentMaster: nextProps.departmentList.department.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getVolunteerByRegistrationId({ type: "1" }).then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });

    this.props.getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    const { alumniForm } = this.props;
    console.log(alumniForm);
    this.setState({ showForm: alumniForm, showTable: !alumniForm });
  }

  onChangeStatus = (rowData, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveEmployee +
            "/update-status?userRegistrationId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.onCancle();
  };
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  onFormSave = (fieldData, rows) => {
    console.log(fieldData);
    const { currentOperationMode } = this.state;
    const dataToSave = {
      // isActive:
      //   currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      type: 1,
      status: 0,
      interest: "",
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    AlumniMentorJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        if (fieldData[currentField.dataKey] !== undefined) {
          let date = new Date(fieldData[currentField.dataKey]);

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
        }

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    if (rows.length !== 0) {
      dataToSave["documentName"] = rows[0].fileName ? rows[0].fileName : "";
      dataToSave["remark"] = rows[0].docName ? rows[0].docName : "";
    }
    console.log(dataToSave);
    this.onSave(dataToSave, true);
  };

  onSave = (dataToSave, isToggle = true) => {
    let row = [];
    console.log(dataToSave.message.id);
    console.log(this.props.departmentList);
    console.log(dataToSave);
    this.props.departmentList.department.map((data) => {
      if (data.id === dataToSave.message.id) {
        row.push(data);
      }
    });
    dataToSave = {
      ...dataToSave,
      message: row[0].name,
    };
    console.log(dataToSave);
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: AlumniMentorJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.props
          .getVolunteerByRegistrationId({ type: "1" })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        if (isToggle) {
          this.toggleFormTableVisibility();
          this.props.onCancle();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { id, fileName, experties, time, department } = this.state;

    const formData = new FormData();
    formData.append("file", fileName);
    apiPost({
      url: endpoint.fileUpload,
      postBody: formData,
    }).then(({ data, success }) => {
      if (success) {
        let docs = {
          documentName: data,
          expertise: experties,
          time: time,
          interest: department,
          type: 1,
        };
        console.log(docs);
        apiPost({
          url: AlumniMentorJson.apiBaseURL,
          postBody: docs,
        }).then(({ success }) => {
          if (success) {
            this.props.onCancle();
          }
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: AlumniMentorJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const {} = this.state;
        this.props
          .getVolunteerByRegistrationId({ type: "1" })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { volunteerList } = this.props;
    const { searchValue } = this.state;
    console.log(volunteerList?.volunteerByRegistrationIdData);
    const columsFil = AlumniMentorColumns.filter((item) => {
      return item.canSearch;
    });
    return volunteerList?.volunteerByRegistrationIdData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      employeeDetails,
      alumniForm,
      showForm1,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fileName,
      experties,
      time,
      department,
      fieldData,
      dynamicMasterData,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        {showForm && (
          <>
            <DynamicForm
              fieldMeta={AlumniMentorJson.fieldMeta}
              dynamicMasterData={dynamicMasterData}
              rowEdit={fieldData}
              onDataChange={this.onDataChange}
              showSaveNextBtn={false}
              showBackToListBtn={false}
              onCancel={this.onFormCancel}
              onSave={this.onFormSave}
              onSaveAndNext={this.onFormSave}
              showTitle={false}
              hideTable={true}
              showTextfield={false}
              allow={true}
            />
          </>
        )}
        {showTable && (
          <>
            <Grid item sx={{ marginTop: 1 }}>
              <RTTable
                columns={AlumniMentorColumns}
                hiddenColumnNames={tableColumnsToFilter.map((item) => {
                  return !item.isChecked ? item.columnKeyName : "";
                })}
                showAddButtonInTable={showAddButtonInTable}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                onChangeStatus={this.onChangeStatus}
                rowAdd={this.rowAdd}
                tableData={this.getFilteredTableData()}
              />
            </Grid>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  volunteerList: state.volunteerStatus,
  departmentList: state.department,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getVolunteerByRegistrationId,
  getVolunteerById,
  getDepartment,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlumniMentor);
