import endpoint from "../config/endpoints";

export const ContributionsJson = {
  apiBaseURL: endpoint.committeeHandle,
  screenTitle: "Contributions",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      "label": "Committee",
      "controlType": "autocomplete",
      "placeHolder": "",
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "masterName": "committee",
      "dataKey": "committeeDesignation",
      "isMandatory": true,
      isRootLevelKey: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "committee",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "committeeDesignationNew",
      tableDataKey: 'committeeDesignation',
      open: 'other',
      error: 'Committee'
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "committeeName",
      isMandatory: false,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "Level",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "LevelMaster",
      dataKey: "level",
      isRootLevelKey: true,
      isMandatory: true
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "LevelMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "levelData",
      tableDataKey: 'level',
      open: 'other',
      error: 'Level'
    },
  ],
};
