
import endpoint from "../config/endpoints";

export const FeesHeadDetailMasterJson = {
    "apiBaseURL": endpoint.feesHeadDetail,
    "screenTitle": "Fees Head Details",
    "showAddButton": true,
    "showPdfDownload": true,
    "pdfFileName": "Fees Head Details",
    "showExcelDownload": true,
    "excelFileName": "Fees Head Details",
    "tableColumnsToFilter": [
        {
            "columnDisplayName": "Fees Title",
            "columnKeyName": "feesTitleName",
            "isChecked": true
        },
        {
            "columnDisplayName": "Fees Type",
            "columnKeyName": "feesTypeName",
            "isChecked": true
        },
        {
            "columnDisplayName": "Name",
            "columnKeyName": "name",
            "isChecked": true
        },
        {
            "columnDisplayName": "Receipt Type",
            "columnKeyName": "receiptTypeName",
            "isChecked": true
        },
        {
            "columnDisplayName": "Fees Collection Preference",
            "columnKeyName": "feesCollectionPref",
            "isChecked": true
        },
        {
            "columnDisplayName": "Carry Forward",
            "columnKeyName": "isCarryForward",
            "isChecked": true
        },
        // {
        //     "columnDisplayName": "Sort Order No",
        //     "columnKeyName": "sortOrderNo",
        //     "isChecked": true
        // },
        {
            "columnDisplayName": "Status",
            "columnKeyName": "isActive",
            "isChecked": true
        }
    ],
    "baseIdColumn": "id",
    "fieldMeta": [
        {
            "label": "Fees Title",
            "controlType": "autocomplete",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "masterName": "feesTitleMaster",
            "dataKey": "feesTitle",
            "tableDataKey": "feesTitleId",
            "isRootLevelKey": false,
            "isMandatory": true
        },
        {
            "label": "Fees Type",
            "controlType": "autocomplete",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "masterName": "feesTypeMaster",
            "dataKey": "feesType",
            "tableDataKey": "feesTypeId",
            "isRootLevelKey": false,
            "isMandatory": true
        },
        {
            "label": "Name",
            "controlType": "textfield",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "name",
            "isMandatory": true
        },
        {
            "label": "Receipt Type",
            "controlType": "autocomplete",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "masterName": "receiptTypeMaster",
            "dataKey": "receiptType",
            "tableDataKey": "receiptTypeId",
            "isRootLevelKey": false,
            "isMandatory": true
        },
        {
            "label": "Fees Collection Preference",
            "controlType": "textfield",
            "placeHolder": " ",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "feesCollectionPref",
            "isMandatory": true
        },
        // {
        //     label: "Sort Order No",
        //     controlType: "textfield",
        //     placeHolder: "",
        //     md: 6,
        //     lg: 6,
        //     sm: 6,
        //     xs: 12,
        //     dataKey: "sortOrderNo",
        //     isMandatory: true,
        // },
        {
            "label": "Carry Forward",
            "controlType": "radiogroup",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "isCarryForward",
            "defaultValue": 1,
            "radioGroupItems": [
                {
                    "label": "Yes",
                    "value": 1
                },
                {
                    "label": "No",
                    "value": 0
                }
            ],
            "isRootLevelKey": false,
            "isMandatory": true
        }
    ]
}