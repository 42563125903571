import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import MultipleSelect from "../Comman/MultipleSelect";
import TimePickerCompo from "../Comman/TimePicker";
import InfoIcon from "@mui/icons-material/Info";
import { validEmail } from "../Comman/Util/Validations";
class DynamicForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
    };
  }

  componentDidMount() {}

  onAutocompleteChange = (dataKey) => (name, newValue) => {
    this.props.onDataChange(dataKey, newValue);
  };
  // multiSelectHandler = (value, name) => {
  //   this.setState({
  //     ...this.state,
  //     [name]: typeof value === "string" ? value.split(",") : value,
  //   });
  // };
  onMultiSelectChange = (dataKey) => (value, name) => {
    this.props.onDataChange(
      dataKey,
      typeof value === "string" ? value.split(",") : value
    );
  };
  onTextFieldDataChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey) => (date) => {
    // date.setHours(0, 0, 0, 0);
    this.props.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onSave = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSave();
    }
  };
  onSaveAndNext = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSaveAndNext();
    }
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue) {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                {
                  if (!(dataKeyValue < compareWithValue)) {
                    isError = true;
                    condErrorText = "less than";
                  }
                }
                break;
              case "le":
                {
                  if (!(dataKeyValue <= compareWithValue)) {
                    isError = true;
                    condErrorText = "less than equal to";
                  }
                }
                break;
              case "g":
                {
                  if (!(dataKeyValue > compareWithValue)) {
                    isError = true;
                    condErrorText = "greater than";
                  }
                }
                break;
              case "ge":
                {
                  if (!(dataKeyValue >= compareWithValue)) {
                    isError = true;
                    condErrorText = "greater than equal to";
                  }
                }
                break;
              case "eq":
                {
                  if (!(dataKeyValue === compareWithValue)) {
                    isError = true;
                    condErrorText = "equal to";
                  }
                }
                break;
            }

            if (isError) {
              const compareWithFieldLabel = fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  render() {
    const {
      fieldMeta,
      dynamicMasterData = {},
      fieldData,
      showBackToListBtn = true,
      showSaveBtn = true,
      showSaveNextBtn = true,
      showTitle = false,
      screenTitle = "",
      showCancel = true,
      DataToSet = [],
    } = this.props;

    const { formErrors } = this.state;

    return (
      <>
        {showTitle === false ? (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label={screenTitle}
              />
            </Grid>
            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={this.onCancel}
              />
            </Grid>
          </Grid>
        ) : showBackToListBtn ? (
          <Grid
            item
            xs={12}
            md={12}
            size="small"
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              size="small"
              type="button"
              variant="outlined"
              name=" Back to List"
              onClick={this.onCancel}
            />
          </Grid>
        ) : (
          ""
        )}

        {DataToSet.length !== 0 && (
          <Grid container sx={{ margin: 2 }} spacing={1}>
            {DataToSet.map((dropDownData) => (
              <Grid container xs={6} sm={6} md={6}>
                <Grid item xs={4} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={dropDownData.label}
                  />
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={dropDownData.value}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid container spacing={1}>
          {fieldMeta.map((currentField) => (
            <Grid
              item
              xs={currentField.xs}
              sm={currentField.sm}
              md={currentField.md}
              sx={{ marginTop: currentField.marginTop }}
            >
              {currentField.toolTip && currentField.toolTip !== "" && (
                <>
                  <Tooltip placement="top" title={currentField.toolTip}>
                    <InfoIcon color="disabled" />
                  </Tooltip>
                </>
              )}

              {currentField.controlType !== "hideDatepicker" &&
                currentField.controlType !== "hideTextfield" &&
                currentField.controlType !== "hideAutocomplete" && (
                  <>
                    &nbsp;&nbsp;
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        (currentField.isMandatory ? "*" : "") +
                        currentField.label
                      }
                    />
                  </>
                )}
              {currentField.controlType === "hideDatepicker" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <>
                    &nbsp;&nbsp;
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"*" + currentField.label}
                    />
                  </>
                )}
              {currentField.controlType === "hideAutocomplete" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <>
                    &nbsp;&nbsp;
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"*" + currentField.label}
                    />
                  </>
                )}
              {currentField.controlType === "hideTextfield" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <>
                    {currentField.showLabel ? (
                      <>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={"*" + currentField.label}
                        />
                      </>
                    ) : (
                      <>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={"  "}
                        />
                      </>
                    )}
                  </>
                )}

              {currentField.controlType === "textfield" && (
                <>
                  <TextField
                    placeholder={currentField.placeHolder}
                    size="small"
                    color="primary"
                    name={currentField.label}
                    id={currentField.label}
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    disabled={
                      fieldData["id"] && currentField.disable
                        ? true
                        : currentField.disableTrue
                        ? true
                        : false
                    }
                    inputProps={{
                      maxLength:
                        currentField.maxLength && currentField.maxLength,
                      min: currentField.minNum && currentField.minNum,
                    }}
                    type={
                      currentField.inputType ? currentField.inputType : "text"
                    }
                    onChange={this.onTextFieldDataChange(currentField.dataKey)}
                    fullWidth
                    error={
                      formErrors[currentField.dataKey]?.isError ? true : false
                    }
                    helperText={
                      formErrors[currentField.dataKey]?.isError
                        ? formErrors[currentField.dataKey].errorText
                        : " "
                    }
                  ></TextField>
                  {currentField.note &&
                    currentField.space &&
                    currentField.note.split("@").map((note) => {
                      return (
                        <>
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 12,
                              color: "#808080",
                              marginLeft: 2,
                            }}
                            label={note}
                          />
                          {currentField.space === "br" ? (
                            <br></br>
                          ) : currentField.space === "&nbsp;" ? (
                            " "
                          ) : (
                            ""
                          )}
                          {/* <br></br> */}
                        </>
                      );
                    })}
                </>
              )}
              {currentField.controlType === "textarea" && (
                <TextField
                  placeholder={currentField.placeHolder}
                  size="small"
                  color="primary"
                  name={currentField.label}
                  id={currentField.label}
                  value={
                    fieldData[currentField.dataKey]
                      ? fieldData[currentField.dataKey]
                      : ""
                  }
                  disabled={
                    fieldData["id"] && currentField.disable ? true : false
                  }
                  inputProps={{
                    maxLength: currentField.maxLength && currentField.maxLength,
                  }}
                  type={
                    currentField.inputType ? currentField.inputType : "text"
                  }
                  multiline
                  onChange={this.onTextFieldDataChange(currentField.dataKey)}
                  fullWidth
                  error={
                    formErrors[currentField.dataKey]?.isError ? true : false
                  }
                  helperText={
                    formErrors[currentField.dataKey]?.isError
                      ? formErrors[currentField.dataKey].errorText
                      : " "
                  }
                ></TextField>
              )}
              {currentField.controlType === "hideTextfield" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <TextField
                    placeholder={currentField.placeHolder}
                    size="small"
                    color="primary"
                    name={currentField.label}
                    disabled={
                      fieldData["id"] && currentField.disable
                        ? true
                        : currentField.disableTrue
                        ? true
                        : false
                    }
                    id={currentField.label}
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    inputProps={{
                      maxLength:
                        currentField.maxLength && currentField.maxLength,
                    }}
                    type={
                      currentField.inputType ? currentField.inputType : "text"
                    }
                    onChange={this.onTextFieldDataChange(currentField.dataKey)}
                    fullWidth
                    error={
                      formErrors[currentField.dataKey]?.isError ? true : false
                    }
                    helperText={
                      formErrors[currentField.dataKey]?.isError
                        ? formErrors[currentField.dataKey].errorText
                        : " "
                    }
                  ></TextField>
                )}
              {currentField.controlType === "datepicker" && (
                <BasicDatePicker
                  color="primary"
                  name="dob"
                  size="small"
                  value={
                    fieldData[currentField.dataKey]
                      ? fieldData[currentField.dataKey]
                      : null
                  }
                  {...(currentField.isMAxDate && {
                    maxDate: currentField.maxDate,
                  })}
                  {...(currentField.isMinDate && {
                    minDate: currentField.minDate,
                  })}
                  disabled={
                    fieldData["id"] && currentField.disable ? true : false
                  }
                  fullWidth
                  onChange={this.onDateChange(currentField.dataKey)}
                  isError={formErrors[currentField.dataKey]?.isError}
                  errorText={formErrors[currentField.dataKey]?.errorText}
                />
              )}
              {currentField.controlType === "hideDatepicker" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <BasicDatePicker
                    color="primary"
                    name="dob"
                    size="small"
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : null
                    }
                    {...(currentField.isMAxDate && {
                      maxDate: currentField.maxDate,
                    })}
                    {...(currentField.isMinDate && {
                      minDate: currentField.minDate,
                    })}
                    disabled={
                      fieldData["id"] && currentField.disable ? true : false
                    }
                    fullWidth
                    onChange={this.onDateChange(currentField.dataKey)}
                    isError={formErrors[currentField.dataKey]?.isError}
                    errorText={formErrors[currentField.dataKey]?.errorText}
                  />
                )}
              {currentField.controlType === "hideAutocomplete" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <AutoComplete
                    keyColName={"id"}
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    options={
                      dynamicMasterData[currentField["masterName"]]
                        ? dynamicMasterData[currentField["masterName"]]
                        : []
                    }
                    disabled={
                      fieldData["id"] && currentField.disable ? true : false
                    }
                    onChange={this.onAutocompleteChange(currentField.dataKey)}
                    isError={formErrors[currentField.dataKey]?.isError}
                    errorText={formErrors[currentField.dataKey]?.errorText}
                  />
                )}
              {currentField.controlType === "autocomplete" && (
                <>
                  <AutoComplete
                    keyColName={"id"}
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    options={
                      dynamicMasterData[currentField["masterName"]]
                        ? dynamicMasterData[currentField["masterName"]]
                        : []
                    }
                    disabled={
                      fieldData["id"] && currentField.disable ? true : false
                    }
                    onChange={this.onAutocompleteChange(currentField.dataKey)}
                    isError={formErrors[currentField.dataKey]?.isError}
                    errorText={formErrors[currentField.dataKey]?.errorText}
                  />
                  {currentField.note &&
                    currentField.space &&
                    currentField.note.split("@").map((note) => {
                      return (
                        <>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 12, color: "#808080" }}
                            label={note}
                          />
                          {currentField.space === "br" ? (
                            <br></br>
                          ) : currentField.space === "&nbsp;" ? (
                            " "
                          ) : (
                            ""
                          )}
                          {/* <br></br> */}
                        </>
                      );
                    })}
                </>
              )}
              {currentField.controlType === "multiSelect" && (
                <MultipleSelect
                  er={formErrors[currentField.dataKey]?.isError}
                  name1={currentField.dataKey}
                  value1={
                    fieldData[currentField.dataKey]
                      ? fieldData[currentField.dataKey]
                      : []
                  }
                  names={
                    dynamicMasterData[currentField["masterName"]]
                      ? dynamicMasterData[currentField["masterName"]]
                      : []
                  }
                  //onChange={this.multiSelectHandler}
                  onChange={this.onMultiSelectChange(currentField.dataKey)}
                />
              )}
              {currentField.controlType === "timePicker" && (
                <TimePickerCompo
                  value={
                    fieldData[currentField.dataKey]
                      ? fieldData[currentField.dataKey]
                      : ""
                  }
                  onChange={this.timeChangeHandler(currentField.dataKey)}
                />
              )}
              {currentField.controlType === "radiogroup" && (
                <>
                  <RadioGroup
                    row
                    aria-label={currentField.label}
                    name={currentField.label}
                    id={currentField.label}
                    disabled={
                      fieldData["id"] && currentField.disable ? true : false
                    }
                    value={
                      fieldData[currentField.dataKey]
                        ? fieldData[currentField.dataKey]
                        : ""
                    }
                    onChange={this.onRadioChange(currentField.dataKey)}
                    size="small"
                  >
                    {currentField.radioGroupItems.map((radioItem) => (
                      <FormControlLabel
                        value={radioItem.value}
                        control={<Radio />}
                        label={radioItem.label}
                      />
                    ))}
                  </RadioGroup>
                  {currentField.note &&
                    currentField.note.split("@").map((note) => {
                      return (
                        <>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 12, color: "#808080" }}
                            label={note}
                          />
                          <br></br>
                        </>
                      );
                    })}
                </>
              )}
            </Grid>
          ))}
        </Grid>
        {/* <br></br> */}
        {(showSaveBtn || showSaveNextBtn || showCancel) && (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            {showSaveBtn && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>
            )}
            {showSaveNextBtn && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1.7}
                lg={1.7}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Next"
                  onClick={this.onSaveAndNext}
                  fullWidth={true}
                />
              </Grid>
            )}
            {showCancel && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1.1}
                lg={1.1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={this.onCancel}
                  fullWidth={true}
                />
              </Grid>
            )}
          </Grid>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
