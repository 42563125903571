import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  department: [],
  isFetch: false,

  departmentByUserId: [],
  isFetchDepartmentByUserId: false,

  classCoordinator: [],
  isFetchCoordinator: false

};
let URL = endpoints.department;
const departmentSlice = createSlice( {
  name: "department",
  initialState,
  reducers: {
    departmentSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        department: row,
        isFetch: true,
      };
    },
    departmentByUserIdSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        departmentByUserId: row,
        isFetchDepartmentByUserId: true,
      };
    },


    classCoordinatorSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        classCoordinator: row,
        isFetchCoordinator: true,
      };
    },

  },
} );

export const { departmentSuccess, departmentByUserIdSuccess, classCoordinatorSuccess } = departmentSlice.actions;

export default departmentSlice.reducer;

export const getDepartment = () => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: URL + '?sort={"insertdatetime": "DESC"}',
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
            yearDetailId: data1.yearDetailId,
            showForSelection: data1.showForSelection
          };
          row.push( bData );
          return data1;
        } );
        dispatch( departmentSuccess( { row } ) );
      }
      return success;
    } );
    return success;
  } catch ( e )
  {
    return console.error( e.message );
  }
};

export const getDepartmentByuserId = () => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: URL + '/get-department-by-userId',
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
            yearDetailId: data1.yearDetailId,
            showForSelection: data1.showForSelection
          };
          row.push( bData );
          return data1;
        } );
        localStorage.setItem("departmentList", JSON.stringify(row));
        dispatch( departmentByUserIdSuccess( { row } ) );
      }
      return success;
    } );
    return success;
  } catch ( e )
  {
    return console.error( e.message );
  }
};
export const getDepartmentByYearDetailsId = ( { yearDetailId } ) => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: URL + '?yearDetailId=' + yearDetailId,
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
            yearDetailId: data1.yearDetailId,
            showForSelection: data1.showForSelection
          };
          row.push( bData );
          return data1;
        } );
        dispatch( departmentSuccess( { row } ) );
      }
      return success;
    } );
    return success;
  } catch ( e )
  {
    return console.error( e.message );
  }
};



export const getClassCoordinator = () => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: URL + '/get-users-by-department-access',
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.firstName + " " + data1.middleName + " " + data1.lastName,
            isActive: data1.isActive,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( classCoordinatorSuccess( { row } ) );
      }
      return success;
    } );
    return success;
  } catch ( e )
  {
    return console.error( e.message );
  }
};