import {
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { getFessDeatilsPreLogin } from "../AdmissionForm/admissionForm.slice";
import { getAlert } from "../../CommonActions/alert.slice";

const TAX_RATE = 0.18;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow("Main Fees ", 100, 1.15),
  createRow("Paper (Case)", 10, 45.99),
  createRow("Waste Basket", 2, 17.99),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const AddmissionFeesDetailsTable = ({
  getFessDeatilsPreLogin,
  studentList,
  onSave,
  onCancel,
  getAlert,
}) => {
  const [open, setOpen] = React.useState(false);
  const [amt, setAmt] = React.useState(false);
  const [total, setTotal] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  console.log(amt);
  console.log(total);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let amount = 0;
    console.log(studentList);
    //{categoryId,scholarship,programTitleId}
    getFessDeatilsPreLogin({
      domainName: window.location.host,
      categoryId: studentList.admissionForm.categoryId,
      scholarship: studentList.admissionForm.scholarship === "yes" ? 1 : 0,
      yearDetailId: studentList.admissionForm.yearDetailId,
      academicYearId: studentList.academicYear.id,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        console.log(response);
        response.feesDetailList.map((feeDetail) => {
          amount = amount + feeDetail.amount;
        });
        setTotal(amount);
      }
    });
  }, []);

  const changeHandler = (event) => {
    console.log(event.target.value);
    if (event.target.value === "0") {
      swal({
        title:
          "Your Admission is not Confirmed, please pay the fee and confirm your Admission",
      }).then((value) => {
        console.log(value);
      });
    }
  };

  const saveHandler = (e) => {
    e.preventDefault();
    if (amt !== total) {
      setFlag(true);
    } else {
      setOpen(false);
    }
  };
  const submitHandler = (e) => {
    let data = {
      feesSturctureHeadId: studentList?.feesDetails.id,
    };
    console.log(studentList?.feesDetails.id);
    onSave(data);
  };
  console.log(studentList?.feesDetails.feesDetailList);
  return (
    <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
      <Grid item xs={6} sm={6} md={12}>
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          spacing={2}
          columnSpacing={2}
          rowSpacing={2}
        >
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 18 }}
              label="Fees Details"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} />
          <Table sx={{ margin: 2 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Sr. No.</TableCell>
                <TableCell align="center">Particular Type</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {studentList?.feesDetails.feesDetailList.map((row) => {
                return (
                  <TableRow>
                    <TableCell>{row.index}</TableCell>
                    <TableCell>{row.feeTitle}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Total
                </TableCell>
                <TableCell align="right">{total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label="*Payment Mode : "
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="scholarship"
                  id="paymentMode"
                  name="paymentMode"
                  onChange={changeHandler}
                  size="small"
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Online"
                    onClick={handleClickOpen}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Offline"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
              <DialogTitle>Fees Details</DialogTitle>
              <DialogContent>
                <DialogContentText m={2}>Total Fees :{total}</DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="amt"
                  label="Amount to be Paid"
                  fullWidth
                  variant="outlined"
                  onChange={(event) => setAmt(event.target.value)}
                  error={flag ? true : false}
                  helperText={
                    flag ? "Amount to be paid must be equal to total fees" : " "
                  }
                />
              </DialogContent>
              <Grid container>
                <Grid item xs={12} sm={12} md={2}>
                  <InputLabel sx={{ ml: 5, mt: 3 }}>Gateway</InputLabel>
                </Grid>
                <Grid m={2} item xs={12} sm={4} md={5}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="scholarship"
                      id="paymentMode"
                      name="paymentMode"
                      size="small"
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Paytm"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="UPI"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={saveHandler}>Confirm</Button>
                <Button onClick={handleClose}>Reset</Button>
              </DialogActions>
            </Dialog>
            <Grid item xs={12} sm={4} md={4}></Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name=" Back"
                fullWidth={true}
                onClick={onCancel}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save"
                fullWidth={true}
                onClick={submitHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
// export default AddmissionFeesDetailsTable;
const mapStateToProps = (state) => ({
  studentList: state.admissionForm,
});
const mapDispatchToProps = {
  getAlert,
  getFessDeatilsPreLogin,
  // getSubjectSelection,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddmissionFeesDetailsTable);
