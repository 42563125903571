import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import RTTable from "../../../components/Comman/RT/RTTable";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../utils/api_service";
import {
  getfeedBackDetailsByFeedBackId,
  getfeedBackDetailId,
  resetValues,
} from "../AddAndViewFeedBack/addFeedback.slice";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
let docs;
class EmployeeFeedbackPreview extends Component {
  state = {
    id: 0,
    name: "",
    remark: "",
    formErrors: [],
  };
  // columns = [
  //     { name: "index", title: "Sr. No." },
  //     { name: "question", title: "Question" },
  //     { name: "optionType", title: "Question Type" },
  //     { name: "feedbackHeadDetailsOptions", title: "Options" },
  //     { name: "action", title: "Action" },
  // ];

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "5%",
    },
    {
      name: "question",
      title: "Question",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },

    {
      name: "optionType",
      title: "Question Type",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
    {
      name: "feedbackHeadDetailsOptions",
      title: "Options",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
  ];

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  render() {
    const { name, remark } = this.state;
    const { onCancel, feedBackDetails } = this.props;
    const { nameError, feedbackListErr } = this.state.formErrors;
    console.log();
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mb: 2 }} item xs={12} sm={10.5} md={10.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Feedback Questions Preview"
                  />
                </Grid>
                <Grid sx={{ mb: 2 }} item xs={12} sm={1.5} md={1.5}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={this.props.backToForm}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Feedback Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="name"
                    size="small"
                    value={feedBackDetails.feedBackById.name}
                    onChange={this.changeHandler}
                    error={nameError ? true : false}
                    helperText={nameError ? nameError : " "}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              {/* <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                container
                                justifyContent="space-evenly"
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label="Remark"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        color="primary"
                                        name="remark"
                                        size="small"
                                        value={remark}
                                        error={false}
                                        helperText={" "}
                                        onChange={this.changeHandler}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid> */}
            </Grid>

            {/* <Grid
                            sx={{ mt: 2 }}
                            rowSpacing={2}
                            container
                            columnSpacing={1}
                            justifyContent="right"
                        >
                            <Grid item justifyContent="space-evenly">
                                <ButtonCompo
                                    size="medium"
                                    type="button"
                                    variant="contained"
                                    name="Add Questions"
                                    onClick={this.submitHandler}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid> */}

            <Grid
              // sx={{ mt: 2 }}
              container
              justifyContent="left"
            >
              {/* <FormHelperText error>{feedbackListErr}</FormHelperText> */}
              {/* <RTTable
                                columns={this.columns}
                                // showAddButtonInTable={showAddButtonInTable}
                                // tableData={fee}
                                tableData={feedBackDetails.feedBackDetailsByfeedBack}
                                rowEdit={this.rowEdit}
                                rowDelete={this.rowDelete}
                            /> \\ */}
              <DynamicTable
                data={feedBackDetails?.empPreview}
                tableHead={this.columns}
                showPegination={false}
                isActionColActive={false}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              sx={{ marginTop: 2 }}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              {/* <Grid
                                item
                                sm={12}
                                xs={12}
                                md={1}
                                lg={1}
                                container
                                justifyContent="space-evenly"
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="button"
                                    variant="contained"
                                    name="Save"
                                    fullWidth={true}
                                    onClick={this.saveHandler}
                                />
                            </Grid> */}
              {/* 
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={1.1}
                                lg={1.1}
                                container
                                justifyContent="space-evenly"
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="button"
                                    variant="outlined"
                                    name="Cancel"
                                    onClick={onCancel}
                                    fullWidth={true}
                                />
                            </Grid> */}
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  feedBackDetails: state.feedBack,
});
const mapDispatchToProps = {
  showNotification,
  getfeedBackDetailId,
  getfeedBackDetailsByFeedBackId,
  resetValues,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeFeedbackPreview);
