import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { downloadVideo, saveVideo } from "../../UploadFile/file.slice";

const AdminVideo = ({ saveVideo, downloadVideo, handleFileChange }) => {
  const inputRef = React.useRef();
  //   const [filedata, setFiledata] = useState("");

  //   const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     console.log(event.target.files[0]);

  //     fileData(file);
  //     saveVideo({ file: file }).then((response) => {
  //       if (!response) {
  //       }
  //     });
  //   };

  const handleChoose = (event) => {
    // inputRef.current.click();
    // console.log(fileData.video);
    // downloadVideo({ file: fileData.video }).then((response) => {
    //   if (!response) {
    //     const blb = new Blob([fileData.filePath], { type: "text/plain" });
    //     const reader = new FileReader();
    //     reader.addEventListener("loadend", (e) => {
    //       const text = e.srcElement.result;
    //       try {
    //         const obj = JSON.parse(text);
    //         console.log(obj.error);
    //       } catch (e) {}
    //     });
    //     reader.readAsText(blb);
    //   }
    // });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      <div className="VideoInput">
        <input
          ref={inputRef}
          className="VideoInput_input"
          type="file"
          onChange={handleFileChange}
          accept=".mov,.mp4"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  fileData: state.file,
});

const mapDispatchToProps = {
  saveVideo,
  downloadVideo,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminVideo);
