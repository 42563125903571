import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  academicYear: [],
  isFetch: false,
  currentAcademicYear: {},
  isFetchCurrentAcademicYear: false,
  academicYearData: {},
  isFetchAcademicYearData: false,
};
let URL = endpoints.academicYear;
let currentAcademicURL = endpoints.currentAcademic;
const academicYearSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    academicYearSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        academicYear: row,
        isFetch: true,
      };
    },

    currentAcademicYearSuccess: (state = cloneDeep(initialState), action) => {
      const { yearData } = action.payload;
      return {
        ...state,
        currentAcademicYear: yearData,
        isFetchCurrentAcademicYear: true,
      };
    },

    academicYearDataSuccess: (state = cloneDeep(initialState), action) => {
      const { academicYearData } = action.payload;
      return {
        ...state,
        academicYearData: academicYearData,
        isFetchAcademicYearData: true,
      };
    },

    
  },
});

export const { academicYearSuccess, currentAcademicYearSuccess, academicYearDataSuccess } =
  academicYearSlice.actions;

export default academicYearSlice.reducer;

export const getAcademicYear = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const acadamicList = data.data;
        let index = 0;
        let row = [];
        acadamicList.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        localStorage.setItem("acadamicYearList", JSON.stringify(row));
       
        dispatch(academicYearSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCurrentAcademicYear = () => async (dispatch) => {
  try {
   const response= apiGet({
      url: currentAcademicURL + "/get-current-academic-year",
    }).then(({ data, success }) => {
      if (success) {
        let academicYearData = {
          id: data.id,
          name: data.name,
          isActive: data.isActive,
        };
        dispatch(currentAcademicYearSuccess({ yearData: academicYearData }));
        dispatch(academicYearDataSuccess({ academicYearData: data }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
