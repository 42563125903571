
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  scheme: [],
  isFetch: false,
};
let URL = endpoints.scheme;
const schemeSlice = createSlice( {
  name: "class",
  initialState,
  reducers: {
    schemeSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        scheme: row,
        isFetch: true,
      };
    },
  },
} );

export const {
  schemeSuccess,
} = schemeSlice.actions;

export default schemeSlice.reducer;

export const getScheme = () => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      url: URL+'?sort={"sortOrderNo": "ASC"}'
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            shortName: data1.shortName,
            sortOrderNo: data1.sortOrderNo,
            isMinMaxShow: data1.isMinMaxShow,
            isActive: data1.isActive,
            isTeaching: data1.isTeaching

          };
          row.push( bData );
          return data1;
        } );
        dispatch( schemeSuccess( { row } ) );
      }
      return success
    } );
    return response
  } catch ( e )
  {

    return console.error( e.message );
  }
};