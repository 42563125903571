import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import * as myConstClass from "../../../config/messageconstant";
import { getTimeSlot } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import { getSyllabus } from "../../TeachingPlan/teachingPlan.slice";
import {
  getSemesterDetailsStudentForm,
  getYearDetailsStudentForm,
} from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import moment from "moment";

import {
  getBatch,
  getDivision,
  getStudentAttendanceList,
  getSubject,
  resetStudentList,
} from "../StudentAttendanceForms/studentAttendance.slice";

let selectedStudentList = [];

class StudentAttendForm extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    semister: "",
    division: "",
    batch: "",
    subject: "",
    timeSlot: "",
    attendanceDate: "",
    topic: "",
    remark: "",
    selectedStudentIndexList: [],
    StudentList: [],
    formErrors: [],
    disabledflag: false,
    flagTab2: false,
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentAttendanceList?.subject !==
        nextProps.studentAttendanceList?.subject &&
      Object.keys(this.props.saveFormData).length != 0
    ) {
      const subjectDetailFilterList =
        nextProps.studentAttendanceList?.subject.filter(
          (subjectList) => subjectList.id === this.props.saveFormData.subject
        );
      if (subjectDetailFilterList.length !== 0) {
        this.props
          .getSyllabus({
            subjectDetailId: subjectDetailFilterList[0].subjectDetailId,
          })
          .then((resp) => {
            if (!resp) {
              this.props.getAlert({
                message: "Server Failed",
              });
            }
          });
      }
    }
  }
  componentDidMount() {
    const { saveFormData } = this.props;
    console.log(saveFormData);
    if (saveFormData !== undefined) {
      if (Object.keys(saveFormData).length != 0) {
        console.log(saveFormData);
        const attendanceDate =
          saveFormData.attendanceDate && saveFormData.attendanceDate !== ""
            ? saveFormData.attendanceDate.split("-")
            : "";
        const attendanceDateArr =
          attendanceDate === ""
            ? ""
            : new Date(
                attendanceDate[2],
                attendanceDate[1] - 1,
                attendanceDate[0]
              );

        this.setState({
          id: saveFormData.id,
          yearDetail: saveFormData.yearDetail,
          attendanceDate: attendanceDateArr,
          remark: saveFormData.remark,
          topic: saveFormData.topicTaught,
          semister: saveFormData.semister,
          division: saveFormData.division,
          batch: saveFormData.batch,
          timeSlot: saveFormData.timeSlot,
          subject: saveFormData.subject,
          disabledflag: saveFormData.id !== 0 ? true : false,
        });
        this.props
          .getStudentAttendanceList({ id: saveFormData.batch })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props
          .getBatch({
            semister: saveFormData.semister,
            division: saveFormData.division,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props
          .getSubject({
            semister: saveFormData.semister,
            division: saveFormData.division,
            batch: saveFormData.batch,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props
          .getDivision({ id: saveFormData.semister })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props
          .getSemesterDetailsStudentForm({
            yearDetailId: saveFormData.yearDetail,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props.getTimeSlot().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    }

    this.props.getYearDetailsStudentForm().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  ChangeHandlerSearch = (name, newValue) => {
    console.log(name);
    console.log(newValue);
    const { getDivision, getBatch, getSubject, getSemesterDetailsStudentForm } =
      this.props;
    const { semister, division, batch, yearDetail, timeSlot, subject } =
      this.state;

    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name == "batch") {
        this.props.resetStudentList();
      }
      if (name === "subject") {
        const subjectDetailFilterList =
          this.props.studentAttendanceList?.subject.filter(
            (subjectList) => subjectList.id === newValue
          );
        if (subjectDetailFilterList.length !== 0) {
          this.props
            .getSyllabus({
              subjectDetailId: subjectDetailFilterList[0].subjectDetailId,
            })
            .then((resp) => {
              if (!resp) {
                this.props.getAlert({
                  message: "Server Failed",
                });
              }
            });
        }
      }
      if (name == "yearDetail") {
        this.setState({ semister: "", division: "", batch: "", subject: "" });

        const FilterList =
          this.props.employeeWorkList?.studentAttendanceFormYeardetails.filter(
            (row) => row.id === newValue
          );
        console.log(FilterList[0].programTypeId);
        this.props.getTimeSlot().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        getSemesterDetailsStudentForm({
          yearDetailId: newValue,
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
      if (name == "semister") {
        this.setState({ division: "" });
        getDivision({ id: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (division !== "" && batch !== "") {
          this.setState({ subject: "" });
          getSubject({
            semister: newValue,
            division: division,
            batch: batch,
          }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      }
      if (name == "division") {
        this.setState({ subject: "", batch: "" });
        getBatch({ semister: semister, division: newValue }).then(
          (response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          }
        );
        if (semister !== "" && batch !== "") {
          getSubject({
            semister: semister,
            division: newValue,
            batch: batch,
          }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      }
      if (name == "batch" && semister !== "" && division !== "") {
        this.setState({ subject: "" });
        getSubject({
          semister: semister,
          division: division,
          batch: newValue,
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
      if (name == "batch") {
        this.props.resetStudentList();
      }
    }
  };

  handleFormValidation() {
    const {
      yearDetail,
      semister,
      division,
      batch,
      subject,
      timeSlot,
      attendanceDate,
      topic,
      remark,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (semister.toString().trim() === "" || semister == null) {
      formIsValid = false;
      formErrors["semisterError"] = myConstClass.semisterMsg;
    }

    if (yearDetail.toString().trim() === "" || yearDetail == null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }
    if (division.toString().trim() === "" || division == null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    if (batch.toString().trim() === "" || batch == null) {
      formIsValid = false;
      formErrors["batchError"] = myConstClass.batchMsg;
    }
    if (subject.toString().trim() === "" || subject == null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectNameMsg;
    }
    if (timeSlot.toString().trim() === "" || timeSlot == null) {
      formIsValid = false;
      formErrors["timeSlotError"] = myConstClass.timeSlotMsg;
    }
    if (attendanceDate === "") {
      formIsValid = false;
      formErrors["attendanceDateError"] = myConstClass.attandaceDateMsg;
    }
    if (topic.toString().trim() === "" || topic == null) {
      formIsValid = false;
      formErrors["topicError"] = "Topic taught required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { getStudentAttendanceList, onSave, getAlert, showNotification } =
      this.props;
    const {
      id,
      topic,
      remark,
      yearDetail,
      semister,
      division,
      batch,
      subject,
      attendanceDate,
      timeSlot,
    } = this.state;

    let docs = {
      id: id,
      yearDetail: yearDetail,
      semister: semister,
      attendanceDate: moment(attendanceDate).format("DD-MM-YYYY"),
      division: division,
      batch: batch,
      subject: subject,
      topicTaught: topic,
      remark: remark,
      timeSlot: timeSlot,
    };
    console.log(docs);

    if (this.handleFormValidation()) {
      this.props.Onsave(docs);
      getStudentAttendanceList({ id: batch }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  render() {
    const {
      yearDetail,
      semister,
      division,
      batch,
      subject,
      timeSlot,
      attendanceDate,
      disabledflag,
      topic,
      remark,
    } = this.state;
    const {
      semisterError,
      divisionError,
      batchError,
      yearDetailError,
      subjectError,
      timeSlotError,
      attendanceDateError,
      topicError,
    } = this.state.formErrors;
    const { internshipDeclarationList, rollNoData, studentBatchList } =
      this.props;
    return (
      <>
        <div>
          <Paper sx={{ borderRadius: 2 }} elevation={0}>
            <>
              <form autoComplete="off" noValidate={true}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={1}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Detail"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={
                          this.props.employeeWorkList
                            ?.studentAttendanceFormYeardetails
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={yearDetailError ? true : false}
                        errorText={yearDetailError ? yearDetailError : " "}
                        disabled={disabledflag}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Semester"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={semister}
                        name={"semister"}
                        options={
                          this.props.employeeWorkList
                            ?.attendanceSemesterDetailsStudent
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={semisterError ? true : false}
                        errorText={semisterError ? semisterError : " "}
                        disabled={disabledflag}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Division"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={division}
                          name={"division"}
                          options={this.props.studentAttendanceList?.division}
                          onChange={this.ChangeHandlerSearch}
                          isError={divisionError ? true : false}
                          errorText={divisionError ? divisionError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Batch"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={batch}
                          name={"batch"}
                          options={this.props.studentAttendanceList?.batch}
                          onChange={this.ChangeHandlerSearch}
                          isError={batchError ? true : false}
                          errorText={batchError ? batchError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Subject"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={subject}
                          name={"subject"}
                          options={this.props.studentAttendanceList?.subject}
                          onChange={this.ChangeHandlerSearch}
                          isError={subjectError ? true : false}
                          errorText={subjectError ? subjectError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Time Slot"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={timeSlot}
                          name={"timeSlot"}
                          options={this.props.timeSlotList?.timeSlot.filter(
                            (row) => row.isRecesses === 0
                          )}
                          onChange={this.ChangeHandlerSearch}
                          isError={timeSlotError ? true : false}
                          errorText={timeSlotError ? timeSlotError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Attendance Date"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="attendanceDate"
                          size="small"
                          value={attendanceDate}
                          errorText={
                            attendanceDateError ? attendanceDateError : " "
                          }
                          isError={attendanceDateError ? true : false}
                          fullWidth
                          onChange={this.onDateChange("attendanceDate")}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Remark"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="remark"
                          size="small"
                          value={remark}
                          fullWidth
                          onChange={this.changedHandler}
                          helperText={" "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={12}
                      md={12}
                      columnSpacing={3}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Topic Taught"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="topic"
                          size="small"
                          value={topic}
                          multiline
                          fullWidth
                          onChange={this.changedHandler}
                          helperText={topicError ? topicError : " "}
                          error={topicError ? true : false}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="right"
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Cancel"
                          onClick={this.props.backToList}
                        />
                        &nbsp;&nbsp;
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Save and Next"
                          onClick={this.searchData}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          </Paper>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  semesterList: state.rollNo,
  yearDetails: state.internshipDeclaration,
  timeSlotList: state.timeSlot,
  studentAttendanceList: state.studentAttendance,
  employeeWorkList: state.employeeWorkload,
});
const mapDispatchToProps = {
  getStudentAttendanceList,
  getSemesterDetailsByYearAndAcademic,
  getTimeSlot,
  getDivision,
  getBatch,
  getSubject,
  getAlert,
  resetStudentList,
  getYearDetailsStudentForm,
  getSemesterDetailsStudentForm,
  getSyllabus,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentAttendForm);
