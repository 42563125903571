import { Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
// import { getAdmissionList } from "./CompanyList.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
// import {  } from "../../UploadFile/file.slice";
import { apiPost } from "../../utils/api_service";
import {
  getStudentList,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
} from "../UploadFile/file.slice";

class StudentDeclareInternshipListTable extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      title: "Document Name",
      name: "name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "60%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  details = (params) => {
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  onClickDelete = (params) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        // this.props.deleteFile({ file: params.fileName }).then(() => {});
        // const docList = this.props.fileData.fileData.filter(
        //   (row) => row.fileName !== params.fileName
        // );
        // this.props.fileMulitipleSave({ fileData: docList });
      }
    });
  };
  state = {
    id: 0,
    academicYear: "",
    yearDetail: "",
    typeOfAdmission: "",
    applicationId: "",
    formErrors: [],
    interestedStudent: false,
    accountFees: false,
    listOfComp: true,
    fromDate: new Date(),
    toDate: new Date(),
    internType: "",
    companyName: "",
    companyAddress: "",
    designation: "",
    stipend: "",
    remark: "",
    fileName: "",
    documentName: "",
    fileNameData: [],
    upload: false,
    uploadError: false,
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentData?.getInterestedStudByIdStatus !==
        nextProps.studentData?.getInterestedStudByIdStatus &&
      this.props.fileData.fileData.length === 0
    ) {
      console.log(nextProps.studentData?.getInterestedStudByIdStatus);

      this.setState({
        internType:
          nextProps.studentData?.getInterestedStudByIdStatus.internshipType.id,
        id: nextProps.studentData?.getInterestedStudByIdStatus.id,
        companyAddress:
          nextProps.studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName:
          nextProps.studentData?.getInterestedStudByIdStatus.companyName,
        stipend: nextProps.studentData?.getInterestedStudByIdStatus.stipend,
        remark: nextProps.studentData?.getInterestedStudByIdStatus.remark,
        designation:
          nextProps.studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .lastName,
        studUserRegistrationId:
          nextProps.studentData?.getInterestedStudByIdStatus.userRegistrationId,
        durationFromDate:
          nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate,
        durationToDate:
          nextProps.studentData?.getInterestedStudByIdStatus.durationToDate,
      });
      const docList =
        nextProps.studentData?.getInterestedStudByIdStatus.internshipIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              index: index + 1,
              id: fileData.id,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }
    if (
      this.props.fileData.fileData !== nextProps.fileData.fileData &&
      this.state.id === 0
    ) {
      const docList = nextProps.fileData.fileData.map((fileData, index) => {
        let document = {
          ...fileData,
          index: index + 1,
        };
        return document;
      });
      this.props.fileMulitipleSave({ fileData: docList });
    }
  }

  componentDidMount() {
    const {
      getClass,
      getAcademicYear,
      getYearDetails,
      getStudentList,
      getAlert,
      studentData,
    } = this.props;
    const {
      id,
      companyName,
      companyAddress,
      internType,
      designation,
      stipend,
      remark,
      durationFromDate,
      durationToDate,
    } = this.state;

    this.props.getInternshipType().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    console.log(studentData);

    if (Object.keys(studentData?.getInterestedStudByIdStatus).length != 0) {
      console.log(studentData?.getInterestedStudByIdStatus);
      this.setState({
        internType: studentData?.getInterestedStudByIdStatus.internshipType.id,
        id: studentData?.getInterestedStudByIdStatus.id,
        companyAddress: studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName: studentData?.getInterestedStudByIdStatus.companyName,
        stipend: studentData?.getInterestedStudByIdStatus.stipend,
        remark: studentData?.getInterestedStudByIdStatus.remark,
        designation: studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration.lastName,
        studUserRegistrationId:
          studentData?.getInterestedStudByIdStatus.userRegistrationId,
        durationFromDate:
          studentData?.getInterestedStudByIdStatus.durationFromDate,
        durationToDate: studentData?.getInterestedStudByIdStatus.durationToDate,
      });
      const docList =
        studentData?.getInterestedStudByIdStatus.internshipIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              index: index + 1,
              id: fileData.id,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }
  }

  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };

  changeToDate = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation(name) {
    const {
      companyName,
      internType,
      designation,
      academicYear,
      fromDate,
      toDate,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (academicYear.toString().trim() === "") {
      formIsValid = false;
      formErrors["academicYearError"] = myConstClass.academicYearMessage;
    }

    if (internType.toString().trim() === "") {
      formIsValid = false;
      formErrors["studentNameError"] = myConstClass.studentNameMsg;
    }

    if (companyName === "") {
      formIsValid = false;
      formErrors["companyNameError"] = myConstClass.companyNameMsg;
    }

    if (designation.toString().trim() === "") {
      formIsValid = false;
      formErrors["designationError"] = myConstClass.designationMsg;
    }

    if (fromDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { fromDate, toDate, academicYear, internType } = this.state;
    const {} = this.props;
    console.log(fromDate + " fromDate");
    console.log(toDate + " toDate");
  };

  changeH = (fileName, name, index) => {
    console.log(fileName);
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  uploadImage = () => {
    const { saveMultipleFile, getAlert } = this.props;
    const { fileName, documentName } = this.state;
    if (fileName !== "") {
      this.setState({
        upload: true,
        uploadError: false,
      });
      let fileData = {
        fileName: fileName,
        name: documentName,
      };
      saveMultipleFile({ file: fileData }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
          this.setState({
            documentName: "",
            fileName: "",
            upload: false,
            uploadError: false,
          });
        } else {
          this.setState({
            documentName: "",
            fileName: "",
            upload: false,
            uploadError: false,
          });
        }
      });
    } else {
      this.setState({
        uploadError: true,
      });
    }
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };
  onSave = () => {
    const {
      id,
      companyName,
      companyAddress,
      designation,
      internType,
      stipend,
      remark,
      fromDate,
      toDate,
      fileName,
      documentName,
    } = this.state;
    const { data, studentData } = this.props;
    let fromDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    console.log(fromDate + " to " + fromDt);
    console.log(toDate + " to " + toDt);
    const documentList = this.props.fileData.fileData.map((docData) => {
      let docs = {
        docName: docData.name,
        fileName: docData.fileName,
      };
      if (docData.id) {
        docs = {
          ...docs,
          id: docData.id,
        };
      }
      return docs;
    });
    let studentInfo = {
      internshipDeclare: null,
      companyName: companyName,
      companyAdd: companyAddress,
      rollNo: "",
      userRegistrationId: "",
      selectedStatus: 1,
      designation: designation,
      stipend: stipend,
      durationFromDate: fromDt,
      durationToDate: toDt,
      internshipType: {
        id: internType,
      },
      vivaStatus: 0,
      approvedStatus: 0,
      intrestStatus: 1,
      internshipIntrestedStudentDocument: documentList,
    };

    if (id !== 0) {
      let student = studentData?.getInterestedStudByIdStatus;
      student = {
        ...student,
        designation: designation,
        stipend: stipend,
        durationFromDate: fromDt,
        durationToDate: toDt,
        internshipIntrestedStudentDocument: documentList,
      };
      apiPost({
        url: endpoints.internshipIntrest + "/edit",
        postBody: student,
      }).then(({ data, success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props.goBack();
          this.props.deleteFileData();
        }
      });
    } else {
      apiPost({
        url: endpoints.internshipIntrest,
        postBody: studentInfo,
      }).then(({ data, success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props.goBack();
          this.props.deleteFileData();
        }
      });
    }
  };
  render() {
    const {
      listOfComp,
      fromDate,
      toDate,
      internType,
      companyName,
      companyAddress,
      remark,
      designation,
      stipend,
      documentName,
      uploadError,
      upload,
      fileName,
    } = this.state;
    const {
      fromDateError,
      toDateError,
      studentNameError,
      designationError,
      companyNameError,
    } = this.state.formErrors;
    const {
      goBack,
      intrestedStudList,
      studentData,
      fileData,
      internshipTypeList,
    } = this.props;
    console.log(intrestedStudList);
    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            {listOfComp && (
              <>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label="View Student Details"
                      />
                    </Grid>
                    <Grid
                      container
                      justifyContent="right"
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back to List"
                        onClick={goBack}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Intern Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={internType}
                        name={"internType"}
                        options={internshipTypeList?.internshipType}
                        onChange={this.ChangeHandlerSearch}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyName"
                        id="companyName"
                        value={companyName}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(companyNameError
                          ? { error: true, helperText: companyNameError }
                          : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company Address"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyAddress"
                        id="companyAddress"
                        value={companyAddress}
                        onChange={this.changeHandler}
                        fullWidth
                        // {...(companyNameError
                        //   ? { error: true, helperText: companyNameError }
                        //   : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Designation"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="designation"
                        id="designation"
                        value={designation}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(designationError
                          ? { error: true, helperText: designationError }
                          : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    columnSpacing={1}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.changeFromDate}
                        {...(fromDateError
                          ? { error: true, helperText: fromDateError }
                          : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="To Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.changeToDate}
                        {...(toDateError
                          ? { error: true, helperText: toDateError }
                          : "")}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Stipend( ₹ Per month )"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="stipend"
                        id="stipend"
                        value={stipend}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                        //   {...(codeErr ? { error: true, helperText: codeErr }:"")}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
                    <DynamicTable
                      data={fileData.fileData}
                      tableHead={this.columns}
                      showHeadDelete={true}
                      rowDelete={this.onClickDelete}
                      showPegination={false}
                      rowViewData={this.details}
                      showView={true}
                    />
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Remark"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="remark"
                        id="remark"
                        value={remark}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                        //   {...(codeErr ? { error: true, helperText: codeErr }:"")}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              container
              justifyContent="flex-end"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
                sx={{ marginTop: 2 }}
              />

              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back"
                onClick={goBack}
              />
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentData: state.companyList,
  internshipTypeList: state.internshipType,
  intrestedStudList: state.internshipDeclaration,
  fileData: state.file,
});
const mapDispatchToProps = {
  getClass,
  getAcademicYear,
  getInternshipType,
  // getAdmissionList,
  getYearDetails,
  getStudentList,
  getAlert,
  saveMultipleFile,
  fileMulitipleSave,
  saveFile,
  deleteFile,
  openFileInNewTab,
  deleteFileData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentDeclareInternshipListTable);
