import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid } from "@mui/material";
import React, { Component } from "react";
import Search from "../components/Search";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../components/Comman/IconButton";
import RTSelectMultiple from "../components/Comman/RT/RTSelectMultiple";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import FeedbackIcon from "@mui/icons-material/Feedback";
class SearchTable extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  onAddButtonClick = () => {
    this.props.onAddButtonClick();
  };

  onSearch = (searchvalue) => {
    this.props.onSearch(searchvalue);
  };

  render() {
    const {
      showPdfDownload = false,
      showExcelDownload = false,
      showAddButton = false,
      showEmpNotice = false,
      showStudNotice = false,
      showStudFeedback = false,
      showEmpFeedback = false,
      searchValue,
      generatePDF,
      generateExcel,
      showEmpNoticeDetails,
      showEmpFeedbackDetails,
      showStudFeedbackDetails,
      showStudNoticeDetails,
    } = this.props;
    return (
      <>
        <Grid container spacing={3}>
          <Grid container sx={{ ml: -3 }} item xs={12} md={4} lg={3}>
            <Search
              sx={{ width: "100%" }}
              searchValue={searchValue}
              filteredRows={this.onSearch}
            />
          </Grid>

          <Grid container justifyContent="flex-end" item xs={12} md={8} lg={9}>
            <Grid container justifyContent="flex-end" item md xs>
              {showAddButton && (
                <div style={{ padding: "10px" }}>
                  <MyComponentWithIconProps
                    statusImage={AddCircleIcon}
                    sx={{ m: -1 }}
                    color="primary"
                    title=""
                    fontSize="large"
                    onClick={this.onAddButtonClick}
                  />
                </div>
              )}
              {showPdfDownload && (
                <div style={{ padding: "10px" }}>
                  {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={pdfFileName}
                        type="pdf"
                      /> */}

                  <FontAwesomeIconCompo
                    color="error"
                    fontSize="small"
                    title="PDF"
                    baseClassName="fas"
                    className="fa-file-pdf"
                    onClick={generatePDF}
                  />
                </div>
              )}
              {showExcelDownload && (
                <div style={{ padding: "10px" }}>
                  <FontAwesomeIconCompo
                    color="secondary"
                    fontSize="small"
                    title="Excel"
                    baseClassName="fas"
                    className="fa-file-excel"
                    onClick={generateExcel}
                  />
                </div>
              )}
              {showEmpFeedback && (
                <div style={{ padding: "10px" }}>
                  <MyComponentWithIconProps
                    statusImage={FeedbackIcon}
                    color="error"
                    fontSize="medium"
                    title="Add Employee Feedback"
                    onClick={showEmpFeedbackDetails}
                  />
                </div>
              )}
              {showStudFeedback && (
                <div style={{ padding: "10px" }}>
                  <MyComponentWithIconProps
                    statusImage={FeedbackIcon}
                    color="primary"
                    fontSize="medium"
                    title="Add Student Feedback"
                    onClick={showStudFeedbackDetails}
                  />
                </div>
              )}
              {showEmpNotice && (
                <div style={{ padding: "10px" }}>
                  <MyComponentWithIconProps
                    statusImage={CircleNotificationsIcon}
                    color="error"
                    fontSize="medium"
                    title="Add Employee Notice"
                    onClick={showEmpNoticeDetails}
                  />
                </div>
              )}
              {showStudNotice && (
                <div style={{ padding: "10px" }}>
                  <MyComponentWithIconProps
                    statusImage={CircleNotificationsIcon}
                    color="primary"
                    fontSize="medium"
                    title="Add Student Notice"
                    onClick={showStudNoticeDetails}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default SearchTable;
