import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import * as myConstClass from "../../../config/messageconstant";
import { StudentViewUnitTestDynamicJson } from "../../../DynamicFormsJson/StudentViewUnitTest";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import {
  getUnitTestStudent,
  getUnitTestStudentWithoutFilter,
} from "../UnitTestMaster/unitTestMaster.slice";
class StudentViewUnitTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    this.props.getUnitTestStudentWithoutFilter().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "unitTestName",
      title: "Unit Test",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      name: "subjectName",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "maxMarks",
      title: "Marks Out Off",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "marks",
      title: "Marks Obtained",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "date",
      title: "Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
  ];

  onSearchData = (dataToSearch) => {
    this.setState({
      showLoader: true,
    });

    console.log("from Date : " + dataToSearch.fromDate);
    console.log("to Date : " + dataToSearch.toDate);
    this.props
      .getUnitTestStudent({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };
  render() {
    const { unitTestList } = this.props;
    return (
      <>
        <DynamicMainScreen
          onAddButtonClick={this.onAddButtonClick}
          screenTitle={StudentViewUnitTestDynamicJson.screenTitle}
          fieldMeta={StudentViewUnitTestDynamicJson.fieldMeta}
          buttonCenter={StudentViewUnitTestDynamicJson.buttonCenter}
          showPdfDownload={StudentViewUnitTestDynamicJson.showPdfDownload}
          showExcelDownload={StudentViewUnitTestDynamicJson.showExcelDownload}
          tableColumnsToFilter={
            StudentViewUnitTestDynamicJson.tableColumnsToFilter
          }
          showAddButton={StudentViewUnitTestDynamicJson.showAddButton}
          dynamicMasterData={[]}
          tableColumns={this.columns}
          isActionColActive={false}
          tableData={unitTestList?.unitTestStudent}
          showPegination={true}
          showHeadEdit={false}
          showHeadDelete={false}
          showAppliedList={false}
          showLoader={this.state.showLoader}
          onSearchData={this.onSearchData}
          baseIdColumn={StudentViewUnitTestDynamicJson.baseIdColumn}
          apiBaseURL={StudentViewUnitTestDynamicJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  unitTestList: state.unitTestStore,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getUnitTestStudent,
  getUnitTestStudentWithoutFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentViewUnitTest);
