import { Paper } from "@mui/material";
import { findAllByDisplayValue } from "@testing-library/react";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicForm from "../../components/dynamicscreens/DynamicForm";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import { FilterSave } from "../../components/FilterSave";
import endpoints from "../../config/endpoints";
import { ExamFormDeclarationJson } from "../../DynamicFormsJson/ExamFormDeclaration";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../utils/api_service";
import * as msgConstant from "../../config/messageconstant";

import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getSubjectInfoBySemisterDetailId,
  getSubjectInfoByYearDetailId,
} from "../Masters/Admission/Subject Info/subjectInfo.slice";
import { getExamFeesStructure } from "../Masters/Examination/Exam Fees Structure/examFeesStructure.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import {
  getUnitTest,
  getUnitTestDetailsBySemesterAndDivisionId,
  resetValues,
} from "../UnitTest/UnitTestMaster/unitTestMaster.slice";
import {
  getExamFormDeclare,
  getExamdeclareFromDateToDate,
  getExamFormDeclareById,
  getStudentStatusList,
  getExamdeclareSubject,
  getExamdeclareGetSubject,
} from "./examform.slice";
import ExamSchedule from "../Masters/Examination/Exam Fees Structure/ExamSchedule";
import ExamAppliedStudList from "../SubjectSelection/ExamAppliedStudList";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};
class ExamFormDeclaration extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },

    {
      name: "yearDetails",
      title: "Year Details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      width: 300,
    },

    {
      name: "semesterName",
      title: "Semester",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      width: 150,
    },

    {
      name: "examTitle",
      title: "Exam Title",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      width: 200,
    },

    {
      name: "startDate",
      title: "Start Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: 130,
    },

    {
      name: "endDate",
      title: "End Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: 130,
    },
    {
      name: "isActive",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 150,
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
        // subjectMaster: this.props.subjectsList?.subjects,
        unitTestMaster: this.props.unitTestList?.unitTest,
        subjectMaster: this.props.subjectsList?.subjectBysemisterDetail,
        examFeeStructureMaster:
          this.props.examFeeStructureList?.examFeesStructure,
        LevelMaster: [
          { id: "0", name: "No" },
          { id: "1", name: "Yes" },
        ],
      },
      showLoader: false,
      open: false,
      openView: false,
      openSchedule: false,
      openAppliedList: false,
      timeTableHeaderData: {},
      fieldData: {},
      scheduleData: {},
      declarationId: "",
      isActiveStatus: "",
      showLables: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            acadamicMaster: nextProps.academicYearList.academicYear.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }

    if (this.props.subjectsList !== nextProps.subjectsList) {
      if (
        this.props.subjectsList.subjectBysemisterDetail !==
        nextProps.subjectsList.subjectBysemisterDetail
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster:
              nextProps.subjectsList.subjectBysemisterDetail.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }

    if (this.props.examFeeStructureList !== nextProps.examFeeStructureList) {
      if (
        this.props.examFeeStructureList.examFeesStructure !==
        nextProps.examFeeStructureList.examFeesStructure
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            examFeeStructureMaster:
              nextProps.examFeeStructureList.examFeesStructure.filter(
                (data) => data.isActive == "1"
              ),
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }

    if (this.props.unitTestList !== nextProps.unitTestList) {
      if (
        this.props.unitTestList.unitTest !== nextProps.unitTestList.unitTest
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            unitTestMaster: nextProps.unitTestList.unitTest.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  componentDidMount() {
    const {
      getYearDetails,

      getExamFeesStructure,
      getExamFormDeclare,
    } = this.props;
    this.props.resetValues();

    const ay = localStorage.getItem("acadamicYearId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    getExamFormDeclare({ flag: false }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    getExamFeesStructure().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    // if (yearDetailId) {
    //   this.setState({
    //     showLoader: true,
    //   });
    //   this.props
    //     .getDistinctDivision({ yearDetail: yearDetailId })
    //     .then((response) => {
    //       if (!response) {
    //         this.setState({
    //           showLoader: false,
    //         });
    //         this.props.getAlert({ message: "Something went wrong" });
    //       } else {
    //         this.setState({
    //           showLoader: false,
    //         });
    //       }
    //     });
    // }
    // if (yearDetailId && ay) {
    //   this.setState({
    //     showLoader: true,
    //   });
    //   this.props
    //     .getSemesterDetailsByYearAndAcademic({
    //       yearId: yearDetailId,
    //       academicId: ay,
    //     })
    //     .then((response) => {
    //       if (!response) {
    //         this.setState({
    //           showLoader: false,
    //         });
    //         this.props.getAlert({ message: "Something went wrong" });
    //       } else {
    //         this.setState({
    //           showLoader: false,
    //         });
    //       }
    //     });
    // }

    // if ( yearDetailId && semisterDetailId && divisionId )
    // {
    //     this.setState( {
    //         showLoader: true,
    //     } );
    //     this.props
    //         .getUnitTestDetailsBySemesterAndDivisionId( {
    //             semesterId: semisterDetailId,
    //             divisionId: divisionId,
    //         } )
    //         .then( ( response ) =>
    //         {
    //             if ( !response )
    //             {
    //                 this.setState( {
    //                     showLoader: false,
    //                 } );
    //                 getAlert( { message: "Something went wrong" } );
    //             } else
    //             {
    //                 this.setState( {
    //                     showLoader: false,
    //                 } );
    //             }
    //         } );
    // }
  }

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    ExamFormDeclarationJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        if (dataToSearch.yearDetail) {
          this.setState({
            showLoader: true,
          });
          this.props
            .getSemesterDetailsByYearAndAcademic({
              yearId: dataToSearch.yearDetail,
              academicId: "",
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }

        if (dataToSearch.yearDetail) {
          this.setState({
            showLoader: true,
          });
          this.props
            .getDistinctDivision({ yearDetail: dataToSearch.yearDetail })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: findAllByDisplayValue,
                });
              }
            });
        }
      }
    });
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.resetValues();
  };

  toggleFormTableVisibility = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      openSnackbar: true,
    });
  };

  // onSearchData = ( dataToSearch ) =>
  // {
  //     const ay = localStorage.getItem( "acadamicYearId" );
  //     localStorage.setItem( "semisterDetailId", dataToSearch.semister );
  //     localStorage.setItem( "yearDetailId", dataToSearch.yearDetail );
  //     localStorage.setItem( "divisionId", dataToSearch.division );
  //     localStorage.setItem( "divisionIds", "" );
  //     localStorage.setItem( "acadamicYearId", ay );
  //     FilterSave()
  //     this.setState( {
  //         showLoader: true,
  //     } );

  //     this.props
  //         .getUnitTestDetailsBySemesterAndDivisionId( {
  //             semesterId: dataToSearch.semister,
  //             divisionId: dataToSearch.division,
  //         } )
  //         .then( ( response ) =>
  //         {
  //             if ( !response )
  //             {
  //                 this.setState( {
  //                     showLoader: false,
  //                 } );
  //                 getAlert( { message: "Something went wrong" } );
  //             } else
  //             {
  //                 this.setState( {
  //                     showLoader: false,
  //                 } );
  //             }
  //         } );
  // };

  onSearchData = (dataToSearch) => {
    this.setState({
      fromDate: dataToSearch.fromDate,
      toDate: dataToSearch.toDate,
      showLoader: true,
    });
    const { getAlert } = this.props;
    this.props
      .getExamdeclareFromDateToDate({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    this.setState({
      open: true,
      fieldData: fieldData,
    });
  };

  // onAddButtonClick = () =>
  // {
  //     let fieldData = {};
  //     const { fieldMeta } = this.props;
  //     fieldMeta.map( ( currentField ) =>
  //     {
  //         if ( currentField.defaultValue )
  //         {
  //             fieldData[ currentField.dataKey ] = currentField.defaultValue;
  //         }
  //     } );

  //     this.setState( {
  //         fieldData: fieldData,
  //         currentOperationMode: OperationMode.Add,
  //     } );
  //     this.toggleFormTableVisibility();
  // };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;

    const { resetValues } = this.props;
    let dataToSave = {
      isActive: fieldData.isActive ? fieldData.isActive : "1",
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }
    let date = new Date(fieldData.startDate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let newStartDate = day + "-" + month + "-" + year;

    let edate = new Date(fieldData.endDate);
    let eday = edate.getDate();
    let emonth = edate.getMonth() + 1;
    let eyear = edate.getFullYear();
    let newEndDate = eday + "-" + emonth + "-" + eyear;
    console.log(fieldData);
    dataToSave = {
      ...dataToSave,
      semisterDetail: {
        id: fieldData.semisterDetail,
      },
      examFeesStructure: {
        id: fieldData.examFeesStructure,
      },
      yearDetailId: fieldData.yearDetail,
      lableName: fieldData.labelName ? fieldData.labelName : "",
      examTitle: fieldData.examTitle,
      startDate: newStartDate,
      endDate: newEndDate,
      showLable: fieldData.showLable ? fieldData.showLable : 0,
      declareResult: "",
    };

    console.log(dataToSave);
    swal({
      title: "Are you sure?",
      text: "Once Submitted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((response) => {
      if (response) {
        this.onSave(dataToSave, isToggle);
        resetValues();
      }
    });
  };

  onSave = (dataToSave, isToggle = true) => {
    apiPost({
      url: ExamFormDeclarationJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.getExamFormDeclare({ flag: false }).then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    const { dynamicMasterData } = this.state;
    let fieldData = {};

    ExamFormDeclarationJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    if (fieldData.yearDetail) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: fieldData.yearDetail,
          academicId: "",
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    if (fieldData.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: fieldData.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    this.props
      .getSubjectInfoBySemisterDetailId({ semisterId: fieldData.semister })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    console.log(fieldData);
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onDelete = (rowdata) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: ExamFormDeclarationJson.apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: true,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getExamFormDeclare({ flag: false }).then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onSchedule = (row) => {
    this.setState({
      showLoader: true,
    });

    this.props.getExamFormDeclareById({ id: row.id }).then((data) => {
      if (Object.keys(data).length === 0) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
          scheduleData: data,
        });
        this.props
          .getExamdeclareSubject({ examDeclareId: row.id })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.props
                .getExamdeclareGetSubject({ examDeclareId: row.id })
                .then((response) => {
                  if (!response) {
                    this.setState({
                      showLoader: false,
                    });
                    this.props.getAlert({ message: "Something went wrong" });
                  } else {
                    this.setState({
                      showLoader: false,
                      openSchedule: true,
                    });
                  }
                });
            }
          });
      }
    });
  };

  onCloseSchedule = () => {
    this.setState({
      openSchedule: false,
    });
  };

  onAppliedList = (row) => {
    this.setState({
      declarationId: row.id,
      isActiveStatus: row.isActive,
      showLables: row,
    });
    this.props.getStudentStatusList({ id: row.id }).then((success) => {
      if (success) {
        this.setState({
          openAppliedList: true,
        });
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  onCloseAppliedList = () => {
    this.setState({
      openAppliedList: false,
    });
  };

  changeStatus = (row) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((willDelete) => {
      if (willDelete) {
        const tableHeader = {
          id: row.id,
          semisterDetail: {
            id: row.semisterDetail,
          },
          examFeesStructure: {
            id: row.examFeesStructure,
          },
          lableName: row.lableName,
          examTitle: row.examTitle,
          startDate: row.startDate,
          showLable: row.showLable,
          yearDetailId: row.yearId,
          declareResult: row.declareResult,
          endDate: row.endDate,
          isActive: row.isActive === 1 ? 0 : 1,
        };
        console.log(tableHeader);
        this.setState({
          showLoader: true,
        });
        apiPost({
          url: endpoints.examFormDeclare,
          postBody: tableHeader,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({
              showLoader: false,
            });

            this.props.getExamFormDeclare({ flag: false }).then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  render() {
    const { searchButton = true } = this.props;
    const {
      dynamicMasterData,
      open,
      openView,
      showLoader,
      fieldData,
      scheduleData,
      openSchedule,
      openAppliedList,
    } = this.state;
    return (
      <>
        {!open && !openView && !openSchedule && !openAppliedList && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={ExamFormDeclarationJson.screenTitle}
            fieldMeta={ExamFormDeclarationJson.fieldMetaForTable}
            buttonCenter={ExamFormDeclarationJson.buttonCenter}
            showPdfDownload={ExamFormDeclarationJson.showPdfDownload}
            showExcelDownload={ExamFormDeclarationJson.showExcelDownload}
            pdfFileName={ExamFormDeclarationJson.pdfFileName}
            excelFileName={ExamFormDeclarationJson.excelFileName}
            tableColumnsToFilter={ExamFormDeclarationJson.tableColumnsToFilter}
            showAddButton={ExamFormDeclarationJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={this.columns}
            tableData={this.props.examDeclareList?.examFormDeclare}
            showLoader={this.state.showLoader}
            getListById={this.getListById}
            showPegination={true}
            showView={false}
            searchButton={searchButton}
            showHeadEdit={false}
            showHeadDelete={true}
            rowViewData={this.onClickView}
            rowDelete={this.rowDelete}
            rowEdit={this.rowEdit}
            rowStatus={this.changeStatus}
            onDelete={this.onDelete}
            // showRoundDetails={true}
            showRoundDetailsNew={true}
            rounds={this.onSchedule}
            showAppliedList={true}
            rowAppliedList={this.onAppliedList}
            onSearchData={this.onSearchData}
            baseIdColumn={ExamFormDeclarationJson.baseIdColumn}
            apiBaseURL={ExamFormDeclarationJson.apiBaseURL}
          />
        )}
        {open && !openSchedule && !openAppliedList && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title={ExamFormDeclarationJson.screenTitle} />
            </Paper>
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <DynamicForm
                fieldMeta={ExamFormDeclarationJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showSaveNextBtn={false}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave(true)}
                onSaveAndNext={this.onFormSave(false)}
                screenTitle={ExamFormDeclarationJson.screenTitle}
              />
            </Paper>
          </>
        )}
        {openSchedule && (
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <ExamSchedule
              onClose={this.onCloseSchedule}
              scheduleSaveData={scheduleData}
            />
          </Paper>
        )}
        {openAppliedList && (
          <ExamAppliedStudList
            onClose={this.onCloseAppliedList}
            declarationId={this.state.declarationId}
            viewData={this.state.showLables}
            isActiveStatus={this.state.isActiveStatus}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  subjectsList: state.subjectInfo,
  unitTestList: state.unitTestStore,
  examFeeStructureList: state.examFeesStructure,
  examDeclareList: state.examDeclare,
});
const mapDispatchToProps = {
  getExamFormDeclare,
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getAcademicYear,
  getSubjectInfoByYearDetailId,
  showNotification,
  getSubjectInfoBySemisterDetailId,
  resetValues,
  getExamdeclareFromDateToDate,
  getExamFeesStructure,
  getExamFormDeclareById,
  getExamdeclareSubject,
  getExamdeclareGetSubject,
  getStudentStatusList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamFormDeclaration);
