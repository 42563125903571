import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Alert from "../../CommonActions/Alert";
import { onToggle } from "../../CommonActions/common.slice";
import BarChart from "../../components/Charts/BarChart";
import LineChart from "../../components/Charts/LineChart";
import PieChart from "../../components/Charts/PieChart";
import NotificationToast from "../../components/Comman/NotificationToast";
import DrawerNav from "../../components/Drawer";
import ErrorPage from "../../components/ErrorInSearch";
import Header from "../../components/Header";
import config from "../../config/config";
import PrivateRoute from "../../routes/index";
import AccountFees from "../AccountFees/index";
import AdminStudentAllocation from "../AdminSideAllocationList/index";
import AdmissionForm from "../AdmissionForm/index";
import AllocationOfStudent from "../AllocationOfStudent/AllocationOfStudent";
import AllowOutSideFeedback from "../AllowOutSideFeedback/AllowOutSideFeedback";
import AdminGalleryFolder from "../Alumini/AdminGalleryFolder/index";
import AdminAddNews from "../Alumini/AdminSideAlimini/AdminAddNews/AdminAddNews";
import AdminAddNewsTableView from "../Alumini/AdminSideAlimini/AdminAddNews/AdminAddNewsTableView";
import AdminSideAlimini from "../Alumini/AdminSideAlimini/AluminiStatus/index";
import MentorChangeStatus from "../Alumini/AdminSideAlimini/AlumniMentorStatusChange/MentorChangeStatus";
import AdminViewAlumniPostInternship from "../Alumini/AdminSideAlimini/AlumniPostInternship/AdminViewAlumniPostInternship";
import VolunteerChangeStatus from "../Alumini/AdminSideAlimini/AlumniVolunteerStatus/VolunteerChangeStatus";
import AluminiAchievements from "../Alumini/Alumini Achievements/AluminiAchievements";
import AlumniRegister from "../Alumini/Alumini Register";
import AluminiBasicDetails from "../Alumini/Alumini Register/AluminiBasicDetail";
import AluminiCourseDetails from "../Alumini/Alumini Register/AluminiRegister.slice";
import Gallary from "../Alumini/AluminiGallery";
import AluminiProfile from "../Alumini/AluminiProfile/index";
import AlumniInternship from "../Alumini/AlumniEngage/AlumniInternship";
import AlumniInviteFriends from "../Alumini/AlumniEngage/AlumniInviteFriends";
import AlumniMentor from "../Alumini/AlumniEngage/AlumniMentor";
import AlumniVolunteer from "../Alumini/AlumniEngage/AlumniVolunteer";
import AlumniEvents from "../Alumini/AlumniNewsroom/AlumniEvents";
import Newsroom from "../Alumini/AlumniNewsroom/Newsroom";
import Members from "../Alumini/Members/index";
import StudentFillProfile from "../Alumini/StudentFillProfile/index";
import WorkExp from "../Alumini/WorkExp";
import AdminEditStudentDetails from "../ApplicantAdmission/AdminEditStudentDetails";
import ApplicantAdmission from "../ApplicantAdmission/ManiIndex";
import TransactionDetails from "../ApplicantAdmission/TransactionDetails";
import {
  default as AdmissionFormPost,
  default as StudentMasterPost,
} from "../BasicDetailsPost/index";
import BranchListForm from "../Batches/BatchList/BatchListForm";
import GenerationOfBatches from "../Batches/GenerationOfBatches/GenerationOfBatchesForm";
import AdminCertificateStatus from "../Certificate/AdminCertificate/AdminCertificateStatus";
import ListOfCertificate from "../Certificate/Student/ListOfCertificate";
import ViewHistory from "../Certificate/Student/ViewHistory";
import AdminAlumniDashboard from "../Dashboard/AdminAlumniDashboard";
import AlumniDashboard from "../Dashboard/AlumniDashboard";
import MainDashboard from "../Dashboard/MainDashboard";
import TeacherDashboard from "../Dashboard/TeacherDashboard";
import DeemedCommittee from "../Deemed/Committee/index";
import DeemedMeeting from "../Deemed/Meeting/index";
import DeemedMinutesOfMeeting from "../Deemed/MinutesOfMeeting/index";
import DocumentVerification from "../DocumentVerification/index";
import EmaterialCommentView from "../Ematerial/EmaterialCommentView";
import EmaterialForm from "../Ematerial/EmaterialForm";
import EmaterialTable from "../Ematerial/EmaterialTable";
import EmaterialTableView from "../Ematerial/EmaterialTableView";
import EmployeeList from "../EmployeeDetails/EmployeeList";
import EmployeeDetails from "../EmployeeDetails/index";
import EmpPreview from "../EmpPreview/index";
import AddEvent from "../Event/Event Management/AddEvent";
import AppliedStudentList from "../Event/Event Management/AppliedStudentList";
import EventCommitteeForm from "../Event/Event Management/EventCommitteeForm";
import EventCommitteeMainForm from "../Event/Event Management/EventCommitteeMainForm";
import ViewingEvent from "../Event/Event Management/ViewingEvent";
import ExternalEvent from "../Event/ExternalEventTable/index";
import StudentViewingEvent from "../Event/StudentAddEvent/StudentViewingEvent";
import ApplyForEvent from "../Event/StudentApplyForEvent/ApplyEvent";
import ExamConduct from "../Exam Module/ExamConduct/ListOfExamConduct";
import ExamMarksFilling from "../Exam Module/ExamMarksFilling/ExamMakrsFill";
import ExamModuleApply from "../Exam Module/StudentApplyAdmi/ListOfStudApply";
import StudentApply from "../Exam Module/StudentApplyAdmi/StudentApply";
import ExamFormDeclaration from "../ExamFormDeclaration/ExamFormDeclaration";
import ExternalPlacedStudents from "../ExternalStudent/ExternalPlacedStudents";
import ExternalStudentForm from "../ExternalStudent/ExternalStudentForm";
import AddFeeback from "../Feedback/AddAndViewFeedBack/index";
import EmployeeFeedbackDetailsPortalIndex from "../Feedback/Employee FeedBack Portal/index";
import EmployeeFeedbackPreview from "../Feedback/Employee Feedback/EmployeeFeedbackPreview";
import ViewDeclaredFeedback from "../Feedback/Employee Feedback/ViewDeclaredFeedback";
import EmployeeViewTheirAppliedFeedback from "../Feedback/EmployeeViewTheirAppliedFeedback/EmployeeViewTheirAppliedFeedback";
import FeedbackDetailsPortalIndex from "../Feedback/Student Feedback Portal/index";
import ViewDeclaredStudentFeedback from "../Feedback/Student Feedback/ViewDeclaredStudentFeedback";
import StudentViewTheirAppliedFeedback from "../Feedback/StudentViewTheirAppliedFeedback /StudentViewTheirAppliedFeedback";
import FeesPayment from "../FeesPayement/FeesPayment";
import ForgotPasswordLink from "../ForgotPasswordLink/ForgotPasswordLink";
import GovernmentPayment from "../GovernmentPayment/index";
import InternshipDeclaration from "../InternshipDeclaration/index";
import InterestedStudent from "../ListOfCompany/InterestedStudent";
import ListOfCompany from "../ListOfCompany/ListOfCompany";
import Preview from "../ListOfCompany/Preview";
import UpdateInfo from "../ListOfCompany/UpdateInfo";
import AdmissionStatus from "../Masters/Admission/AdmissionStatus/AdmissionStatus";
import AdmissionType from "../Masters/Admission/AdmissionType/AdmissionType";
import Division from "../Masters/Admission/Division/Division";
import FeesHeadDetails from "../Masters/Admission/FeesHeadDetails/FeesHeadDetails";
import FeesTitle from "../Masters/Admission/FeesTitle/FeesTitle";
import FeeStructuredHeadIndex from "../Masters/Admission/FeeStructureHead/FeeStructuredHeadIndex";
import FeeType from "../Masters/Admission/FeeType/FeeType";
import FinancialYear from "../Masters/Admission/FinancialYear/FinancialYear";
import FromFees from "../Masters/Admission/FromFees/FromFees";
import Pattern from "../Masters/Admission/Pattern/Pattern";
import ReceiptType from "../Masters/Admission/ReceiptType/ReceiptType";
import Scheme from "../Masters/Admission/Scheme/Scheme";
import SchemeHead from "../Masters/Admission/SchemeHead/SchemeHead";
import ScholarshipTypeMaster from "../Masters/Admission/Scholarship Type/ScholarshipTypeMaster";
import SemisterInfo from "../Masters/Admission/Semister Info/SemisterInfo";
import SemisterDetails from "../Masters/Admission/SemisterDetails/SemisterDetails";
import SubjectInfoMaster from "../Masters/Admission/Subject Info/index";
import SubjectTypeDetailsIndex from "../Masters/Admission/SubjectTypeDetail/SubjectTypeDetailsIndex";
import ExamFeesStructure from "../Masters/Examination/Exam Fees Structure/ExamFeesStructure";
import ExamSchedule from "../Masters/Examination/Exam Fees Structure/ExamSchedule";
import Activity from "../Masters/Faculty/AcademicDetails/Activity/Activity";
import AddingQualification from "../Masters/Faculty/AcademicDetails/AddingQualification/AddingQualification";
import ViewNotice from "../Masters/Faculty/AcademicDetails/AdminNotice/ViewNotice";
import ViewNoticeForTeacher from "../Masters/Faculty/AcademicDetails/AdminNotice/ViewNoticeForTeacherTable";
import AttendedSeminar from "../Masters/Faculty/AcademicDetails/AttendedSeminar/AttendedSeminar";
import Award from "../Masters/Faculty/AcademicDetails/Award/AwardIndex";
import CoCurricular from "../Masters/Faculty/AcademicDetails/Co-curricular/Co-curricular";
import CommitteeHandle from "../Masters/Faculty/AcademicDetails/CommitteeHandle/CommitteeHandle";
import Consultancy from "../Masters/Faculty/AcademicDetails/Consultancy/Consultancy";
import Contributions from "../Masters/Faculty/AcademicDetails/Contributions/Contributions";
import CourseAttended from "../Masters/Faculty/AcademicDetails/CourseAttended/CourseAttended";
import ExtendedActivityMain from "../Masters/Faculty/AcademicDetails/ExtendedActivities/ExtendedActivitieMaster";
import ComplaintAdmin from "../Masters/Faculty/AcademicDetails/GrevanceOrComplaint/ComplaintAdmin";
import GrievanceOrComplaint from "../Masters/Faculty/AcademicDetails/GrevanceOrComplaint/GrievanceOrComplaint";
import InnovativeTeaching from "../Masters/Faculty/AcademicDetails/InnovativeTeaching/InnovativeTeaching";
import PaperPosterPresented from "../Masters/Faculty/AcademicDetails/PaperPosterPresented/PaperPoster";
import ParticipationDetails from "../Masters/Faculty/AcademicDetails/ParticipationDetails/ParticipationDetails";
import PrnNumber from "../Masters/Faculty/AcademicDetails/PrnNumber/PrnNumber";
import ResearchGuidance from "../Masters/Faculty/AcademicDetails/ResearchGuidance/ResearchGuidance";
import ResearchProject from "../Masters/Faculty/AcademicDetails/ResearchProject/ResearchProject";
import ReserchJournalPublication from "../Masters/Faculty/AcademicDetails/ReserchJournalPublication/ReserchJournalPublication";
import ResourcePerson from "../Masters/Faculty/AcademicDetails/ResourcePerson/ResourcePerson";
import SemWeb from "../Masters/Faculty/AcademicDetails/SeminarWebinarActivity/semWeb";
import StudentViewNotice from "../Masters/Faculty/AcademicDetails/StudentNotice/StudentViewNotice";
import ViewStudentNotice from "../Masters/Faculty/AcademicDetails/StudentNotice/ViewStudentNotice";
import CompanyMaster from "../Masters/Faculty/Company/index";
import Department from "../Masters/Internship/Department/Department";
import Designation from "../Masters/Internship/Designation/Designation";
import InternshipType from "../Masters/Internship/InternshipType/InternshipType";
import FloorMaster from "../Masters/TimeTable/Floor/FloorMaster";
import Room from "../Masters/TimeTable/room/Room";
import TimeSlot from "../Masters/TimeTable/Time-Slot/timeSlot";
import PlacementDeclaration from "../Placement/InternshipDeclaration/index";
import ListOfPlacement from "../Placement/ListOfCompany/ListOfCompany";
import StudentDeclarePlacementList from "../Placement/StudentPlacementDeclare/StudentDeclarePlacementList";
import PortalInformation from "../PortalInfo/portalInfo";
import CurrentPreviousDetails from "../PromotionalAdmission/CurrentPreviousDetails";
import PromotionalAdmissionForm from "../PromotionalAdmission/PromotionalAdmissionForm";
import AdmissionModuleReports from "../Reports/AdmissionModuleReports";
import AttendanceReport from "../Reports/AttendanceReport";
import ComplaintsReport from "../Reports/ComplaintsReport";
import ExternalEventReport from "../Reports/ExternalEventReport";
import InstituteReport from "../Reports/InstituteReport";
import InternshipListReport from "../Reports/InternshipListReport";
import PlacementReport from "../Reports/PlacementReport";
import EmaterialReport from "../Reports/EmaterialReport";
import AcademicDetailsReport from "../Reports/AcademicDetailsReport";
import NaacReports from "../Reports/NaacReports";
import StudentFeesCollectionReport from "../Reports/StudentFeesCollectionReport";
import SuggestionReport from "../Reports/SuggestionReport";
import DynamicReport from "../Reports/TimeTableReport";
import GenarateRollNo from "../RollNoList/GenrateRollNo/GenrateRollForm";
import RollList from "../RollNoList/ViewOfRollList/RollList";
import AddStudentAttandance from "../StudentAttendance/AddStudentAttandance/index";
import OwnAttendanceDetails from "../StudentAttendance/StudentAttendanceForms/OwnAttendanceDetails";
import PresentStudentDetails from "../StudentAttendance/StudentAttendanceForms/PresentStudentDetails";
import ViewAttendanceClassTeacher from "../StudentAttendance/StudentAttendanceForms/ViewAttendanceClassTeacher";
import ViewPresentDetailsByHead from "../StudentAttendance/StudentAttendanceForms/ViewPresentDetailsByHead";
import StudentAttendanceForm from "../StudentAttendance/StudentAttendanceTable/StudentAttendanceForm";
import StudentFeesCollection from "../StudentFeesCollection/index";
import StudentFeesDetails from "../StudentFeesCollection/StudentFeesDetails";
import StudentDeclareInternshipList from "../StudentInternshipDeclare/StudentDeclareInternshipList";
import StudentPreview1 from "../StudentPreview1/index";
import StudentPreview from "../StudentPreviewById/index";
import StudentSyllabus from "../StudentSyllabus/index";
import ExamAppliedStudList from "../SubjectSelection/ExamAppliedStudList";
import SubjectSelection from "../SubjectSelection/SubjectSelection";
import SubjectSelectionMatrix from "../SubjectSelection/SubjectSelectionMatrix";
import Suggestions from "../Suggestions/Suggestions";
import ViewSuggestions from "../Suggestions/ViewSuggestions";
import TeacherViva from "../TeacherViva/index";
import ClassCoOrdinatorView from "../TeachingPlan/ClassCo-ordinator/ClassCoOrdinatorView";
import SubjectDetailsAdd from "../TeachingPlan/CreateSubjectSchedule/index";
import TeachingPlanPrincipleView from "../TeachingPlan/PrincipleView/PrincipleView";
import TeacherAddSubject from "../TeachingPlan/TeacherView/TeacherView";
import TeacherVIewAddForm from "../TeachingPlan/TeacherView/TeacherVIewAddForm";
import TeacherViewTable from "../TeachingPlan/TeacherView/TeacherViewTable";
import TeachingPlan from "../TeachingPlan/TeachingPlan";
import GenerateTimeTable from "../TimeTable/GenerateTimeTable/index";
import ViewClasscoordinator from "../TimeTable/ViewClasscoordinator/ViewClasscoordinator";
import ViewTimeTable from "../TimeTable/ViewTimeTable/index";
import ViewDyanamicTimeTable from "../TimeTable/ViewTimeTable/ViewDyanamicTimeTable";
import ViewTimeTableToStudent from "../TimeTable/ViewTimeTableToStudent/index";
import ViewTimeTableToTeacher from "../TimeTable/ViewTimeTableToTeacher/index";
import UnitTestDeclare from "../UnitTest/UnitTestDeclare/UnitTestDeclare";
import UnitTestFillMarks from "../UnitTest/UnitTestDeclare/UnitTestFillMarks";
import UnitTestDeclareTeacher from "../UnitTest/UnitTestDeclareTeacherSide/UnitTestDeclareTeacher";
import UnitTestMaster from "../UnitTest/UnitTestMaster/UnitTestMaster";
import StudentViewUnitTest from "../UnitTest/UnitTestStudent/StudentViewUnitTest";
import AccessRole from "../UserAccess/index";
import UserCredentialsForm from "../UserCredentials/UserCredentialsForm";
import VerificationDocument from "../VerificationDocument/index";
import WorkloadAllocation from "../Workload/AdminSideViewAndGenerateWorkout/WorkloadAllocation";
import WorkloadAllocationForm from "../Workload/AdminSideViewAndGenerateWorkout/WorkloadAllocationForm";
import TeacherWorkloadAllocation from "../Workload/TeacherSideWorkloadView/WokloadView";
import { closeNotification } from "./Landing.slice";
import DeemedReport from "../Reports/DeemedReport";
import InternshipDocumentTable from "../InternshipDocument/InternshipDocumentTable";
import ParentRegister from "../Parent/Parent Register/index";
import ParentForgotPassword from "../Parent/Parent Register/ForgotPassword/index";
import AlumniEngagement from "../Alumini/Alumni Engagement/alumniEngagementTable";
import AdminAddAlumni from "../Alumini/AdminSideAlimini/AdminAddAlumni/AdminAddAlumni";
import ParentDashboard from "../Dashboard/ParentDashboard";
import InternshipLetter from "../InternshipDeclaration/InternshipLetter";
import ExaminationReport from "../Reports/ExaminationReport";
import PaymentSuccess from "../AccountFees/PaymentSuccess";
import StudentPayment from "../AccountFees/StudentPayment";
import AddEngagementDetails from "../Alumini/AdminSideAlimini/AlumniMentorStatusChange/AddEngagementDetails";
import AlumniReport from "../Reports/AlumniReport";
import AdminAcademicPanel from "../Masters/Faculty/AdminAcademicDetails/AdminAcademicPanel/AdminAcademicPanel";
import AdminResearchGuidance from "../Masters/Faculty/AdminAcademicDetails/AdminResearchGuidance/AdminResearchGuidance";
import AdminInnovativeTeaching from "../Masters/Faculty/AdminAcademicDetails/AdminInnovativeTeaching/AdminInnovativeTeaching";
import AdminReserchJournalPublication from "../Masters/Faculty/AdminAcademicDetails/AdminReserchJournalPublication/AdminReserchJournalPublication";
import AdminResearchProject from "../Masters/Faculty/AdminAcademicDetails/AdminResearchProject/AdminResearchProject";
import AdminAddingQualification from "../Masters/Faculty/AdminAcademicDetails/AdminAddingQualification/AdminAddingQualification";
import AdminAwardIndex from "../Masters/Faculty/AdminAcademicDetails/AdminAward/AdminAwardIndex";
import AdminConsultancy from "../Masters/Faculty/AdminAcademicDetails/AdminConsultancy/AdminConsultancy";
import AdminPaperPoster from "../Masters/Faculty/AdminAcademicDetails/AdminPaperPosterPresented/AdminPaperPoster";
import AdminExtendedActivitieMaster from "../Masters/Faculty/AdminAcademicDetails/AdminExtendedActivities/AdminExtendedActivitieMaster";
import AdminAddStunentAsAlumni from "../Alumini/AdminAddAlumni/AdminAddAlumni";
import PromotionalAdmissionAdmin from "../PromotionalStudentAdmission/promotionalAdmission";
import StudentApplyForNextYear from "../StudentApplyForNextYear/studentApplyForNextYear";
import PromotionalStudentAdmissionSuccess from "../PromotionalStudentAdmission/paymentSuccess";
import CompanyPreviewPopup from "../Dashboard/PopUps/CompanyPreviewPopup";
import InternshipCompanyPreviewPopup from "../Dashboard/PopUps/InternshipCompanyPreviewPopup";
import PayPhiPaymentGateway from "../../components/Comman/PayPhiPaymentGatway";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(7),
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    minHeight: "calc(100vh - 64px)",
    padding: theme.spacing(1),
    // borderRadius: `${ theme.spacing( 1.5 ) } ${ theme.spacing( 1.5 ) } 0px 0px`,
  },
  typo: {
    color: theme.palette.primary.contrastText,
  },
});

class Landing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSnackBar: false,
    };
  }

  closeSnak = () => {
    const { closeNotification } = this.props;
    closeNotification();
  };

  render() {
    const { classes, common, landingData, alertInfo } = this.props;

    return (
      <>
        <Header />
        <DrawerNav />
        <NotificationToast
          open={landingData.isShowNotification}
          severity={
            landingData.severity == undefined ? "success" : landingData.severity
          }
          msg={landingData.msg}
          closeSnak={this.closeSnak}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: common.toggleNav
              ? `calc(100% - ${config.drawerWidth}px)`
              : "100%",
            ml: common.toggleNav ? `${config.drawerWidth}px` : "0px",
          }}
          className={classes.root}
        >
          {alertInfo.show && <Alert msg={alertInfo.alertMsg} />}
          <Routes>
            {/* Private Route */}
            <Route
              path="/portalInfo"
              element={
                <PrivateRoute>
                  <PortalInformation />
                </PrivateRoute>
              }
            />
            <Route
              path="/studentSyllabus"
              element={
                <PrivateRoute>
                  <StudentSyllabus />
                </PrivateRoute>
              }
            />

            <Route
              path="/externalEvent"
              element={
                <PrivateRoute>
                  <ExternalEvent />
                </PrivateRoute>
              }
            />
            <Route
              path="/governmentPayment"
              element={
                <PrivateRoute>
                  <GovernmentPayment />
                </PrivateRoute>
              }
            />

            <Route
              path="/attendanceReport"
              element={
                <PrivateRoute>
                  <AttendanceReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/barChart"
              element={
                <PrivateRoute>
                  <BarChart />
                </PrivateRoute>
              }
            />
            <Route
              path="/lineChart"
              element={
                <PrivateRoute>
                  <LineChart />
                </PrivateRoute>
              }
            />
            <Route
              path="/pieChart"
              element={
                <PrivateRoute>
                  <PieChart />
                </PrivateRoute>
              }
            />
            <Route
              path="/timeTableReport"
              element={
                <PrivateRoute>
                  <DynamicReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/accessRole"
              element={
                <PrivateRoute>
                  <AccessRole />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-time-table"
              element={
                <PrivateRoute>
                  <ViewDyanamicTimeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-Feedback"
              element={
                <PrivateRoute>
                  <AddFeeback />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-time-table-to-student"
              element={
                <PrivateRoute>
                  <ViewTimeTableToStudent />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-time-table-to-teacher"
              element={
                <PrivateRoute>
                  <ViewTimeTableToTeacher />
                </PrivateRoute>
              }
            />
            <Route
              path="/viewDyanamicTimeTable"
              element={
                <PrivateRoute>
                  <ViewTimeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/teacher-workload-allocation"
              element={
                <PrivateRoute>
                  <TeacherWorkloadAllocation />
                </PrivateRoute>
              }
            />
            <Route
              path="/floor"
              element={
                <PrivateRoute>
                  <FloorMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/time-slot"
              element={
                <PrivateRoute>
                  <TimeSlot />
                </PrivateRoute>
              }
            />
            <Route
              path="/room"
              element={
                <PrivateRoute>
                  <Room />
                </PrivateRoute>
              }
            />
            <Route
              path="/list-of-placement"
              element={
                <PrivateRoute>
                  <ListOfPlacement />
                </PrivateRoute>
              }
            />
            <Route
              path="/genrate-time-table"
              element={
                <PrivateRoute>
                  <GenerateTimeTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/studentPreview1"
              element={
                <PrivateRoute>
                  <StudentPreview1 />
                </PrivateRoute>
              }
            />
            <Route
              path="/studentPreview"
              element={
                <PrivateRoute>
                  <StudentPreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/empPreview"
              element={
                <PrivateRoute>
                  <EmpPreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/genarateRollNo"
              element={
                <PrivateRoute>
                  <GenarateRollNo />
                </PrivateRoute>
              }
            />
            <Route
              path="/viva-declaration"
              element={
                <PrivateRoute>
                  <AdminStudentAllocation />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-fees-collection"
              element={
                <PrivateRoute>
                  <StudentFeesCollection />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-fees-details"
              element={
                <PrivateRoute>
                  <StudentFeesDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/account-fees"
              element={
                <PrivateRoute>
                  <AccountFees />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-add-internship"
              element={
                <PrivateRoute>
                  <StudentDeclareInternshipList />
                </PrivateRoute>
              }
            />
            <Route
              path="/roll-list"
              element={
                <PrivateRoute>
                  <RollList />
                </PrivateRoute>
              }
            />
            <Route
              path="/prn-number"
              element={
                <PrivateRoute>
                  <PrnNumber />
                </PrivateRoute>
              }
            />
            <Route
              path="/generation-of-batches"
              element={
                <PrivateRoute>
                  <GenerationOfBatches />
                </PrivateRoute>
              }
            />
            <Route
              path="/scholarship-type"
              element={
                <PrivateRoute>
                  <ScholarshipTypeMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/fees-head-details"
              element={
                <PrivateRoute>
                  <FeesHeadDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/fee-type"
              element={
                <PrivateRoute>
                  <FeeType />
                </PrivateRoute>
              }
            />
            <Route
              path="/fees-title"
              element={
                <PrivateRoute>
                  <FeesTitle />
                </PrivateRoute>
              }
            />
            <Route
              path="/division"
              element={
                <PrivateRoute>
                  <Division />
                </PrivateRoute>
              }
            />
            <Route
              path="/pattern"
              element={
                <PrivateRoute>
                  <Pattern />
                </PrivateRoute>
              }
            />
            <Route
              path="/designation"
              element={
                <PrivateRoute>
                  <Designation />
                </PrivateRoute>
              }
            />
            <Route
              path="/department"
              element={
                <PrivateRoute>
                  <Department />
                </PrivateRoute>
              }
            />
            <Route
              path="/list-of-internship-declaration"
              element={
                <PrivateRoute>
                  <ListOfCompany />
                </PrivateRoute>
              }
            />
            <Route
              path="/allocation-of-student"
              element={
                <PrivateRoute>
                  <AllocationOfStudent />
                </PrivateRoute>
              }
            />
            <Route
              path="/interested-student"
              element={
                <PrivateRoute>
                  <InterestedStudent />
                </PrivateRoute>
              }
            />
            <Route
              path="/update-info"
              element={
                <PrivateRoute>
                  <UpdateInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheme"
              element={
                <PrivateRoute>
                  <Scheme />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheme-head"
              element={
                <PrivateRoute>
                  <SchemeHead />
                </PrivateRoute>
              }
            />
            <Route
              path="/admission-type"
              element={
                <PrivateRoute>
                  <AdmissionType />
                </PrivateRoute>
              }
            />
            <Route
              path="/semister-info"
              element={
                <PrivateRoute>
                  <SemisterInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/receipt-type"
              element={
                <PrivateRoute>
                  <ReceiptType />
                </PrivateRoute>
              }
            />
            <Route
              path="/from-fees"
              element={
                <PrivateRoute>
                  <FromFees />
                </PrivateRoute>
              }
            />
            <Route
              path="/subject-Type-Detail"
              element={
                <PrivateRoute>
                  <SubjectTypeDetailsIndex />
                </PrivateRoute>
              }
            />
            <Route
              path="/subject-info"
              element={
                <PrivateRoute>
                  <SubjectInfoMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/semister-details"
              element={
                <PrivateRoute>
                  <SemisterDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/admission-form"
              element={
                <PrivateRoute>
                  <AdmissionForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-placement"
              element={
                <PrivateRoute>
                  <StudentDeclarePlacementList />
                </PrivateRoute>
              }
            />
            {/* <Route
 path="/subject-selection"
 element={
 <PrivateRoute>
 <SubjectSelection />
 </PrivateRoute>
 }
 /> */}
            <Route
              path="/select-subject"
              element={
                <PrivateRoute>
                  <SubjectSelectionMatrix />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-fees-structure"
              element={
                <PrivateRoute>
                  <ExamFeesStructure />
                </PrivateRoute>
              }
            />
            <Route
              path="/fees-Structure-Header"
              element={
                <PrivateRoute>
                  <FeeStructuredHeadIndex />
                </PrivateRoute>
              }
            />
            {/* <Route
 path="/subject-selected"
 element={
 <PrivateRoute>
 <SubjectSelected />
 </PrivateRoute>
 }
 /> */}
            <Route
              path="/fees-payment"
              element={
                <PrivateRoute>
                  <FeesPayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/document-verification"
              element={
                <PrivateRoute>
                  <DocumentVerification />
                </PrivateRoute>
              }
            />
            <Route
              path="/financial-year"
              element={
                <PrivateRoute>
                  <FinancialYear />
                </PrivateRoute>
              }
            />
            <Route
              path="/admission-status"
              element={
                <PrivateRoute>
                  <AdmissionStatus />
                </PrivateRoute>
              }
            />
            <Route
              path="/applicant-admission"
              element={
                <PrivateRoute>
                  <ApplicantAdmission />
                </PrivateRoute>
              }
            />
            <Route
              path="/verification-document"
              element={
                <PrivateRoute>
                  <VerificationDocument />
                </PrivateRoute>
              }
            />
            {/* <Route
 path="/addmission-fees-details"
 element={
 <PrivateRoute>
 <AddmissionFeesDetailsTable />
 </PrivateRoute>
 }
 /> */}
            <Route
              path="/promotional-admission"
              element={
                <PrivateRoute>
                  <PromotionalAdmissionForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-master-post"
              element={
                <PrivateRoute>
                  <StudentMasterPost />
                </PrivateRoute>
              }
            />
            <Route
              path="/admissionForm"
              element={
                <PrivateRoute>
                  <AdmissionFormPost />
                </PrivateRoute>
              }
            />
            <Route
              path="/employee"
              element={
                <PrivateRoute>
                  <EmployeeDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/cocurricular"
              element={
                <PrivateRoute>
                  <CoCurricular />
                </PrivateRoute>
              }
            />
            <Route
              path="/attendedseminar"
              element={
                <PrivateRoute>
                  <AttendedSeminar />
                </PrivateRoute>
              }
            />
            <Route
              path="/awards"
              element={
                <PrivateRoute>
                  <Award />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-awards"
              element={
                <PrivateRoute>
                  <AdminAwardIndex />
                </PrivateRoute>
              }
            />
            <Route
              path="/participation-details"
              element={
                <PrivateRoute>
                  <ParticipationDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/innovative-teaching"
              element={
                <PrivateRoute>
                  <InnovativeTeaching />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-innovative-teaching"
              element={
                <PrivateRoute>
                  <AdminInnovativeTeaching />
                </PrivateRoute>
              }
            />
            <Route
              path="/reserch-journal-publication"
              element={
                <PrivateRoute>
                  <ReserchJournalPublication />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-reserch-journal-publication"
              element={
                <PrivateRoute>
                  <AdminReserchJournalPublication />
                </PrivateRoute>
              }
            />
            <Route
              path="/paper-poster-presented"
              element={
                <PrivateRoute>
                  <PaperPosterPresented />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-paper-poster-presented"
              element={
                <PrivateRoute>
                  <AdminPaperPoster />
                </PrivateRoute>
              }
            />
            <Route
              path="/research-project"
              element={
                <PrivateRoute>
                  <ResearchProject />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-research-project"
              element={
                <PrivateRoute>
                  <AdminResearchProject />
                </PrivateRoute>
              }
            />
            <Route
              path="/employeeList"
              element={
                <PrivateRoute>
                  <EmployeeList />
                </PrivateRoute>
              }
            />
            <Route
              path="/companymaster"
              element={
                <PrivateRoute>
                  <CompanyMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/internshipType"
              element={
                <PrivateRoute>
                  <InternshipType />
                </PrivateRoute>
              }
            />
            <Route
              path="/declarationOfInternship"
              element={
                <PrivateRoute>
                  <InternshipDeclaration />
                </PrivateRoute>
              }
            />
            <Route
              path="/PlacementDeclaration"
              element={
                <PrivateRoute>
                  <PlacementDeclaration />
                </PrivateRoute>
              }
            />
            <Route
              path="/teacherViva"
              element={
                <PrivateRoute>
                  <TeacherViva />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-apply-event"
              element={
                <PrivateRoute>
                  <ApplyForEvent />
                </PrivateRoute>
              }
            />
            <Route
              path="/event-form"
              element={
                <PrivateRoute>
                  <AddEvent />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-view-event"
              element={
                <PrivateRoute>
                  <StudentViewingEvent />
                </PrivateRoute>
              }
            />
            <Route
              path="/event-committee-form"
              element={
                <PrivateRoute>
                  <EventCommitteeForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/event-committee-main-form"
              element={
                <PrivateRoute>
                  <EventCommitteeMainForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-event"
              element={
                <PrivateRoute>
                  <ViewingEvent />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-notice"
              element={
                <PrivateRoute>
                  <ViewNotice />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-student-notice"
              element={
                <PrivateRoute>
                  <ViewStudentNotice />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-teacher-notice"
              element={
                <PrivateRoute>
                  <ViewNoticeForTeacher />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-view-notice"
              element={
                <PrivateRoute>
                  <StudentViewNotice />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-declared-student-feedback"
              element={
                <PrivateRoute>
                  <ViewDeclaredStudentFeedback />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-feedback-details"
              element={
                <PrivateRoute>
                  <FeedbackDetailsPortalIndex />
                </PrivateRoute>
              }
            />
            <Route
              path="/employee-feedback-details"
              element={
                <PrivateRoute>
                  <EmployeeFeedbackDetailsPortalIndex />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-declared-feedback"
              element={
                <PrivateRoute>
                  <ViewDeclaredFeedback />
                </PrivateRoute>
              }
            />
            <Route
              path="/employee-view-applied-feedback"
              element={
                <PrivateRoute>
                  <EmployeeViewTheirAppliedFeedback />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-view-applied-feedback"
              element={
                <PrivateRoute>
                  <StudentViewTheirAppliedFeedback />
                </PrivateRoute>
              }
            />
            <Route
              path="/applied-student-list"
              element={
                <PrivateRoute>
                  <AppliedStudentList />
                </PrivateRoute>
              }
            />
            <Route
              path="/external-students"
              element={
                <PrivateRoute>
                  <ExternalStudentForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/external-placed-students"
              element={
                <PrivateRoute>
                  <ExternalPlacedStudents />
                </PrivateRoute>
              }
            />
            <Route
              path="/internship-d-preview"
              element={
                <PrivateRoute>
                  <Preview />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard-comp-preview"
              element={
                <PrivateRoute>
                  <CompanyPreviewPopup />
                </PrivateRoute>
              }
            />

            <Route
              path="/dashboard-internship-comp-preview"
              element={
                <PrivateRoute>
                  <InternshipCompanyPreviewPopup />
                </PrivateRoute>
              }
            />
            <Route
              path="/workload-allocation"
              element={
                <PrivateRoute>
                  <WorkloadAllocation />
                </PrivateRoute>
              }
            />
            <Route
              path="/workload-allocation-form"
              element={
                <PrivateRoute>
                  <WorkloadAllocationForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/batch-list"
              element={
                <PrivateRoute>
                  <BranchListForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/activity-master"
              element={
                <PrivateRoute>
                  <Activity />
                </PrivateRoute>
              }
            />
            <Route
              path="/research-guidance"
              element={
                <PrivateRoute>
                  <ResearchGuidance />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-research-guidance"
              element={
                <PrivateRoute>
                  <AdminResearchGuidance />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumna-register"
              element={
                <PrivateRoute>
                  <AlumniRegister />
                </PrivateRoute>
              }
            />
            <Route
              path="/parent-register"
              element={
                <PrivateRoute>
                  <ParentRegister />
                </PrivateRoute>
              }
            />
            <Route
              path="/parent-forgot-password"
              element={
                <PrivateRoute>
                  <ParentForgotPassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-basic-details"
              element={
                <PrivateRoute>
                  <AluminiBasicDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-course-details"
              element={
                <PrivateRoute>
                  <AluminiCourseDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/research-person"
              element={
                <PrivateRoute>
                  <ResourcePerson />
                </PrivateRoute>
              }
            />
            <Route
              path="/course-attended"
              element={
                <PrivateRoute>
                  <CourseAttended />
                </PrivateRoute>
              }
            />
            <Route
              path="/adding-qualification"
              element={
                <PrivateRoute>
                  <AddingQualification />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-adding-qualification"
              element={
                <PrivateRoute>
                  <AdminAddingQualification />
                </PrivateRoute>
              }
            />
            <Route
              path="/sem-web"
              element={
                <PrivateRoute>
                  <SemWeb />
                </PrivateRoute>
              }
            />
            <Route
              path="/complaint"
              element={
                <PrivateRoute>
                  <GrievanceOrComplaint />
                </PrivateRoute>
              }
            />
            <Route
              path="/complaint-admin"
              element={
                <PrivateRoute>
                  <ComplaintAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="/contributions"
              element={
                <PrivateRoute>
                  <Contributions />
                </PrivateRoute>
              }
            />
            {/* <Route
 path="/complaint-admin"
 element={
 <PrivateRoute>
 <GrievanceOrComplaintAdmin />
 </PrivateRoute>
 }
 /> */}
            <Route
              path="/committee-handle"
              element={
                <PrivateRoute>
                  <CommitteeHandle />
                </PrivateRoute>
              }
            />
            <Route
              path="/consultancy"
              element={
                <PrivateRoute>
                  <Consultancy />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-consultancy"
              element={
                <PrivateRoute>
                  <AdminConsultancy />
                </PrivateRoute>
              }
            />
            <Route
              path="/extended-activity"
              element={
                <PrivateRoute>
                  <ExtendedActivityMain />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-extended-activity"
              element={
                <PrivateRoute>
                  <AdminExtendedActivitieMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/suggestions-form"
              element={
                <PrivateRoute>
                  <Suggestions />
                </PrivateRoute>
              }
            />
            <Route
              path="/own-attendance"
              element={
                <PrivateRoute>
                  <OwnAttendanceDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-edit-details"
              element={
                <PrivateRoute>
                  <AdminEditStudentDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-attendance-class"
              element={
                <PrivateRoute>
                  <ViewAttendanceClassTeacher />
                </PrivateRoute>
              }
            />
            <Route
              path="/present-student-details"
              element={
                <PrivateRoute>
                  <AddStudentAttandance />
                </PrivateRoute>
              }
            />
            <Route
              path="/present-student-details-old"
              element={
                <PrivateRoute>
                  <PresentStudentDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-add-teaching-plan"
              element={
                <PrivateRoute>
                  <TeachingPlan />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-add-subject-details"
              element={
                <PrivateRoute>
                  <SubjectDetailsAdd />
                </PrivateRoute>
              }
            />
            <Route
              path="/teacher-add-subject-details"
              element={
                <PrivateRoute>
                  <TeacherAddSubject />
                </PrivateRoute>
              }
            />
            <Route
              path="/teaching-plan-principle-view"
              element={
                <PrivateRoute>
                  <TeachingPlanPrincipleView />
                </PrivateRoute>
              }
            />
            <Route
              path="/teaching-plan-class-co-ordinator-view"
              element={
                <PrivateRoute>
                  <ClassCoOrdinatorView />
                </PrivateRoute>
              }
            />
            <Route
              path="/teaching-plan-teacher-view"
              element={
                <PrivateRoute>
                  <TeacherViewTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-present-by-head"
              element={
                <PrivateRoute>
                  <ViewPresentDetailsByHead />
                </PrivateRoute>
              }
            />
            <Route
              path="/suggestion"
              element={
                <PrivateRoute>
                  <ViewSuggestions />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-attendance"
              element={
                <PrivateRoute>
                  <StudentAttendanceForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-certificate"
              element={
                <PrivateRoute>
                  <AdminCertificateStatus />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-history"
              element={
                <PrivateRoute>
                  <ViewHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/list-of-certificate"
              element={
                <PrivateRoute>
                  <ListOfCertificate />
                </PrivateRoute>
              }
            />
            <Route
              path="/unit-test"
              element={
                <PrivateRoute>
                  <UnitTestMaster />
                </PrivateRoute>
              }
            />
            <Route
              path="/unit-test-declare"
              element={
                <PrivateRoute>
                  <UnitTestDeclare />
                </PrivateRoute>
              }
            />
            <Route
              path="/ematerial-form"
              element={
                <PrivateRoute>
                  <EmaterialTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/ematerial-form-table"
              element={
                <PrivateRoute>
                  <EmaterialForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/ematerial-view-table"
              element={
                <PrivateRoute>
                  <EmaterialTableView />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-credentials-form"
              element={
                <PrivateRoute>
                  <UserCredentialsForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/outside-feedback"
              element={
                <PrivateRoute>
                  <AllowOutSideFeedback />
                </PrivateRoute>
              }
            />
            <Route
              path="/forgot-password-link"
              element={
                <PrivateRoute>
                  <ForgotPasswordLink />
                </PrivateRoute>
              }
            />
            <Route
              path="/employee-feedback-preview"
              element={
                <PrivateRoute>
                  <EmployeeFeedbackPreview />
                </PrivateRoute>
              }
            />
            <Route
              path="/newDashboard"
              element={
                <PrivateRoute>
                  <MainDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-dashboard"
              element={
                <PrivateRoute>
                  <AlumniDashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/parent-dashboard"
              element={
                <PrivateRoute>
                  <ParentDashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-alumini-dashboard"
              element={
                <PrivateRoute>
                  <AdminAlumniDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-invite-friends"
              element={
                <PrivateRoute>
                  <AlumniInviteFriends />
                </PrivateRoute>
              }
            />
            <Route
              path="/teacherDashboard"
              element={
                <PrivateRoute>
                  <TeacherDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/suggestion-report"
              element={
                <PrivateRoute>
                  <SuggestionReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/complaints-report"
              element={
                <PrivateRoute>
                  <ComplaintsReport />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/dynamic-report/complaints-summary-report"
              element={
                <PrivateRoute>
                  <ComplaintSummaryReport />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="/dynamic-report/time-table-report"
              element={
                <PrivateRoute>
                  <TimeTableByTeacher />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="/dynamic-report/event-organised-summary-report"
              element={
                <PrivateRoute>
                  <EventOrganisedSummaryReport />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="/dynamic-report/event-organised-detailed-report"
              element={
                <PrivateRoute>
                  <EventOrganisedDetailed />
                </PrivateRoute>
              }
            /> */}
            {/* <Route
              path="/dynamic-report/internship-viva-declare-report"
              element={
                <PrivateRoute>
                  <InternshipVivaDeclare />
                </PrivateRoute>
              }
            />
            <Route
              path="/dynamic-report/external-internship-report"
              element={
                <PrivateRoute>
                  <ExternalInternshipReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/dynamic-report/internship-by-company-report"
              element={
                <PrivateRoute>
                  <InternshipListByCompanyReport />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/internship-list-report"
              element={
                <PrivateRoute>
                  <InternshipListReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-fees-collection-report"
              element={
                <PrivateRoute>
                  <StudentFeesCollectionReport />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/dynamic-report/roll-list-and-batches"
              element={
                <PrivateRoute>
                  <RollListAndBatches />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/event-report"
              element={
                <PrivateRoute>
                  <ExternalEventReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/admission-module-report"
              element={
                <PrivateRoute>
                  <AdmissionModuleReports />
                </PrivateRoute>
              }
            />
            <Route
              path="/placement-list-report"
              element={
                <PrivateRoute>
                  <PlacementReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/institute-wise-report"
              element={
                <PrivateRoute>
                  <InstituteReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/errorPage"
              element={
                <PrivateRoute>
                  <ErrorPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/currentPrevious"
              element={
                <PrivateRoute>
                  <CurrentPreviousDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-declaration-form"
              element={
                <PrivateRoute>
                  <ExamFormDeclaration />
                </PrivateRoute>
              }
            />
            <Route
              path="/subject-selection"
              element={
                <PrivateRoute>
                  <SubjectSelection />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-applied-student"
              element={
                <PrivateRoute>
                  <ExamAppliedStudList />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-schedule"
              element={
                <PrivateRoute>
                  <ExamSchedule />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-conduct"
              element={
                <PrivateRoute>
                  <ExamConduct />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-apply"
              element={
                <PrivateRoute>
                  <ExamModuleApply />
                </PrivateRoute>
              }
            />
            <Route
              path="/unit-test-fill-marks"
              element={
                <PrivateRoute>
                  <UnitTestFillMarks />
                </PrivateRoute>
              }
            />
            <Route
              path="/unit-test-student-marks"
              element={
                <PrivateRoute>
                  <StudentViewUnitTest />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-exam-apply"
              element={
                <PrivateRoute>
                  <StudentApply />
                </PrivateRoute>
              }
            />
            <Route
              path="/unit-test-declare-teacher"
              element={
                <PrivateRoute>
                  <UnitTestDeclareTeacher />
                </PrivateRoute>
              }
            />
            <Route
              path="/transaction-details"
              element={
                <PrivateRoute>
                  <TransactionDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/class-coordinator-view"
              element={
                <PrivateRoute>
                  <ViewClasscoordinator />
                </PrivateRoute>
              }
            />
            <Route
              path="/exam-marks-filling"
              element={
                <PrivateRoute>
                  <ExamMarksFilling />
                </PrivateRoute>
              }
            />
            <Route
              path="/teacher-view-form"
              element={
                <PrivateRoute>
                  <TeacherVIewAddForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/ematerial-comment-view"
              element={
                <PrivateRoute>
                  <EmaterialCommentView />
                </PrivateRoute>
              }
            />
            <Route
              path="/deemed-committee"
              element={
                <PrivateRoute>
                  <DeemedCommittee />
                </PrivateRoute>
              }
            />
            <Route
              path="/deemed-meeting"
              element={
                <PrivateRoute>
                  <DeemedMeeting />
                </PrivateRoute>
              }
            />
            <Route
              path="/minutes-of-meeting"
              element={
                <PrivateRoute>
                  <DeemedMinutesOfMeeting />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-achievements"
              element={
                <PrivateRoute>
                  <AluminiAchievements />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-academic-panel"
              element={
                <PrivateRoute>
                  <AdminAcademicPanel />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-volunteer"
              element={
                <PrivateRoute>
                  <AlumniVolunteer />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-mentor"
              element={
                <PrivateRoute>
                  <AlumniMentor />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-mentor-status"
              element={
                <PrivateRoute>
                  <MentorChangeStatus />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-view-alumni-internship"
              element={
                <PrivateRoute>
                  <AdminViewAlumniPostInternship />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-volunteer-status"
              element={
                <PrivateRoute>
                  <VolunteerChangeStatus />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-internship"
              element={
                <PrivateRoute>
                  <AlumniInternship />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-newsroom"
              element={
                <PrivateRoute>
                  <Newsroom />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumini-events"
              element={
                <PrivateRoute>
                  <AlumniEvents />
                </PrivateRoute>
              }
            />

            <Route
              path="/student-fill-profile"
              element={
                <PrivateRoute>
                  <StudentFillProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/work-exp"
              element={
                <PrivateRoute>
                  <WorkExp />
                </PrivateRoute>
              }
            />
            <Route
              path="/gallary"
              element={
                <PrivateRoute>
                  <Gallary />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-side-alimini"
              element={
                <PrivateRoute>
                  <AdminSideAlimini />
                </PrivateRoute>
              }
            />
            <Route
              path="/members"
              element={
                <PrivateRoute>
                  <Members />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-add-news"
              element={
                <PrivateRoute>
                  <AdminAddNews />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-view-table"
              element={
                <PrivateRoute>
                  <AdminAddNewsTableView />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-gallery"
              element={
                <PrivateRoute>
                  <AdminGalleryFolder />
                </PrivateRoute>
              }
            />

            <Route
              path="/alumini-profile"
              element={
                <PrivateRoute>
                  <AluminiProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/deemed-report"
              element={
                <PrivateRoute>
                  <DeemedReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/examination-report"
              element={
                <PrivateRoute>
                  <ExaminationReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/ematerial-report"
              element={
                <PrivateRoute>
                  <EmaterialReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/academic-details-report"
              element={
                <PrivateRoute>
                  <AcademicDetailsReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/naac-report"
              element={
                <PrivateRoute>
                  <NaacReports />
                </PrivateRoute>
              }
            />

            <Route
              path="/internship-document"
              element={
                <PrivateRoute>
                  <InternshipDocumentTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/alumni-engagement"
              element={
                <PrivateRoute>
                  <AlumniEngagement />
                </PrivateRoute>
              }
            />

            <Route
              path="/internship-letter"
              element={
                <PrivateRoute>
                  <InternshipLetter />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-add-alumni"
              element={
                <PrivateRoute>
                  <AdminAddAlumni />
                </PrivateRoute>
              }
            />

            <Route
              path="/payment-success"
              element={
                <PrivateRoute>
                  <PaymentSuccess />
                </PrivateRoute>
              }
            />

            <Route
              path="/student-payment"
              element={
                <PrivateRoute>
                  <StudentPayment />
                </PrivateRoute>
              }
            />

            <Route
              path="/add-engagement-details"
              element={
                <PrivateRoute>
                  <AddEngagementDetails />
                </PrivateRoute>
              }
            />

            <Route
              path="/alumni-report"
              element={
                <PrivateRoute>
                  <AlumniReport />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin-add-student-as-alumni"
              element={
                <PrivateRoute>
                  <AdminAddStunentAsAlumni />
                </PrivateRoute>
              }
            />

            <Route
              path="/promotional-admission-admin"
              element={
                <PrivateRoute>
                  <PromotionalAdmissionAdmin />
                </PrivateRoute>
              }
            />

            <Route
              path="/student-apply-for-next-year"
              element={
                <PrivateRoute>
                  <StudentApplyForNextYear />
                </PrivateRoute>
              }
            />

            <Route
              path="/promotional-student-admission-success"
              element={
                <PrivateRoute>
                  <PromotionalStudentAdmissionSuccess />
                </PrivateRoute>
              }
            />
            <Route
              path="/pay-phi-payment-gateway"
              element={
                <PrivateRoute>
                  <PayPhiPaymentGateway />
                </PrivateRoute>
              }
            />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Box>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  common: state.common,
  landingData: state.landing,
  alertInfo: state.alertInfo,
});

const mapDispatchToProps = { onToggle, closeNotification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Landing));
