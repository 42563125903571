import endpoint from "../config/endpoints";

export const AdminAcademicPanelJson = {
  // apiBaseURL: endpoint.researchGuide,
  screenTitle: "Admin Academic Details Panel",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "Research Guide",
  showExcelDownload: false,
  excelFileName: "Research Guide",
  baseIdColumn: "id",
};
