import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  adminNewsList: [],
  isFetch: false,
};
let URL = endpoints.newsRoom;
const adminNewsSlice = createSlice({
  name: "adminNewsSlice",
  initialState,
  reducers: {
    adminNewsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        adminNewsList: row,
        isFetch: true,
      };
    },
  },
});

export const { adminNewsSuccess } = adminNewsSlice.actions;

export default adminNewsSlice.reducer;

export const getNewRoomsList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            title: data1.title,
            description: data1.description,
            date: data1.date,
            link: data1.link,
            image: data1.image,
            eventDate: data1.eventDate,
          };
          row.push(bData);
          return data1;
        });
        dispatch(adminNewsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
