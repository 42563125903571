import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { FeesHeadDetailMasterJson } from "../../../../DynamicFormsJson/FeesHeadDetails.js";
import { FeesHeadDetailsColumns } from "../../../../tableColumns/table-columns";
import { getFeesHeadDetail } from "./feesHeadDetails.slice";
import { getFeesTitile } from "../FeesTitle/feesTitle.slice";
import { getFeeType } from "../FeeType/feeType.slice";
import { getReceiptType } from "../ReceiptType/receiptType.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
class FeesHeadDetails extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        feesTypeMaster: this.props.feeeTypeList?.feeType,
        receiptTypeMaster: this.props.receiptTypeList?.receiptType,
        feesTitleMaster: this.props.feesTitleList?.feesTitle.filter(
          (data) => data.isActive == 1
        ),
      },
    };
  }

  componentDidMount() {
    console.log(this.props.feesTitleList?.feesTitle);
    this.props.getFeesHeadDetail().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getFeeType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getReceiptType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getFeesTitile().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.feeeTypeList !== nextProps.feeeTypeList) {
      if (this.props.feeeTypeList.feeType !== nextProps.feeeTypeList.feeType) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            feesTypeMaster: nextProps.feeeTypeList.feeType,
          },
        });
      }
    }

    if (this.props.feesTitleList !== nextProps.feesTitleList) {
      if (
        this.props.feesTitleList.feesTitle !== nextProps.feesTitleList.feesTitle
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            feesTitleMaster: nextProps.feesTitleList.feesTitle.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }

    if (this.props.receiptTypeList !== nextProps.receiptTypeList) {
      if (
        this.props.receiptTypeList.receiptType !==
        nextProps.receiptTypeList.receiptType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            receiptTypeMaster: nextProps.receiptTypeList.receiptType.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }
  condition = [
    {
      title: "name",
      value: "Surplus Fees",
      iconHide: "showEditIcon",
      deleteIconHide: "showDeleteIcon",
    },
  ];
  render() {
    const { dynamicMasterData } = this.state;
    const { feesHeadDetailList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={FeesHeadDetailMasterJson.screenTitle}
            fieldMeta={FeesHeadDetailMasterJson.fieldMeta}
            showPdfDownload={FeesHeadDetailMasterJson.showPdfDownload}
            showExcelDownload={FeesHeadDetailMasterJson.showExcelDownload}
            pdfFileName={FeesHeadDetailMasterJson.pdfFileName}
            excelFileName={FeesHeadDetailMasterJson.excelFileName}
            showAddButton={FeesHeadDetailMasterJson.showAddButton}
            tableColumnsToFilter={FeesHeadDetailMasterJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            tableColumns={FeesHeadDetailsColumns}
            tableData={feesHeadDetailList.feesHeadDetail}
            getTableData={this.props.getFeesHeadDetail}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={feesHeadDetailList.isFetch}
            baseIdColumn={FeesHeadDetailMasterJson.baseIdColumn}
            apiBaseURL={FeesHeadDetailMasterJson.apiBaseURL}
            condition={this.condition}
            conditionDelete={true}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  feesHeadDetailList: state.feesHeadDetail,
  feesTitleList: state.feesTitle,
  receiptTypeList: state.receiptType,
  feeeTypeList: state.feeType,
});
const mapDispatchToProps = {
  getFeesHeadDetail,
  getFeeType,
  getReceiptType,
  getFeesTitile,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(FeesHeadDetails);
