import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  award: [],
  isFetch: false,
};
let URL = endpoints.award;

const awardSlice = createSlice({
  name: "award",
  initialState,
  reducers: {
    awardSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        award: row,
        isFetch: true,
      };
    },
  },
});

export const { awardSuccess } = awardSlice.actions;

export default awardSlice.reducer;

export const getAward = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            awardName: data1.awardName,
            organization: data1.organization,
            awardDate: data1.awardDate,
            onLevel: data1.onLevel,
            awardDuration: data1.awardDuration,
            awardFrom: data1.awardFrom,
            awardTo: data1.awardTo,
          };
          row.push(bData);
          return data1;
        });
        dispatch(awardSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminAward =
  ({ userRegistration }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-user-registration-id?userRegistrationId=" +
          userRegistration,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              awardName: data1.awardName,
              organization: data1.organization,
              awardDate: data1.awardDate,
              onLevel: data1.onLevel,
              awardDuration: data1.awardDuration,
              awardFrom: data1.awardFrom,
              awardTo: data1.awardTo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(awardSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAwardByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-award-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              awardName: data1.awardName,
              organization: data1.organization,
              awardDate: data1.awardDate,
              onLevel: data1.onLevel,
              awardDuration: data1.awardDuration,
              awardFrom: data1.awardFrom,
              awardTo: data1.awardTo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(awardSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAwardWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-award-list-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            awardName: data1.awardName,
            organization: data1.organization,
            awardDate: data1.awardDate,
            onLevel: data1.onLevel,
            awardDuration: data1.awardDuration,
            awardFrom: data1.awardFrom,
            awardTo: data1.awardTo,
          };
          row.push(bData);
          return data1;
        });
        dispatch(awardSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
