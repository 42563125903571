import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../../config/endpoints";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete } from "../../../utils/api_service";
import { getCommitteeMembers } from "../Event Management/event.slice";

class EventCommitteeTable extends React.Component {
  columnExtensions = [
    { columnName: "action", width: 100 },
    { columnName: "index", width: 100 },
  ];
  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "memberName",
      title: "Member Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "memberDesignation",
      title: "Designation",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "emailId",
      title: "Email Id",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
    {
      name: "contactNo",
      title: "Contact No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  componentDidMount() {
    const { committeeData } = this.props;
  }

  rowDelete = (params) => {
    const { getAlert, committeeData } = this.props;
    swal({
      title: "Are you sure?",
      text: "Would you like to remove committee member?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoint.eventCommittee + "/" + params.id,
        }).then(({ success }) => {
          if (success) {
            showNotification({ msg: "Data deleted successfully" });
            this.props
              .getCommitteeMembers({ id: committeeData.id })
              .then((response) => {
                if (!response) {
                  getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };

  render() {
    return (
      <>
        <div>
          <DynamicTable
            data={this.props.row}
            tableHead={this.columns}
            showHeadDelete={true}
            rowDelete={this.rowDelete}
            showPegination={true}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  getCommitteeMembers,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCommitteeTable);
