import endpoint from "../config/endpoints";

export const ResearchGuidanceJson = {
  apiBaseURL: endpoint.researchGuide,
  deleteUrl: endpoint.empAsGuideDoc,
  screenTitle: "Research Guide",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Research Guide",
  showExcelDownload: true,
  excelFileName: "Research Guide",
  baseIdColumn: "id",
  DocumentUpload: "employeeAsGuideDocument",
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromDate",
      // isMAxDate: false,
      // maxDate: new Date(),
      // isMinDate: true,
      // minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "toDate",
      // isMAxDate: false,
      // maxDate: new Date(),
      // isMinDate: true,
      // minDate: new Date(),
      isMandatory: true,
    },
  ],
  fieldMeta: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeMaster",
      dataKey: "university",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "",
      controlType: "",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "",
      dataKey: "",
      isMandatory: false,
    },
    {
      label: "Research Center",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "researchCenterMaster",
      dataKey: "researchCenter",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "researchCenterMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "researchCenterData",
      tableDataKey: "researchCenter",
      open: "other",
      error: "Level",
    },
    // {
    //   label: "Research Center",
    //   controlType: "textfield",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "researchCenter",
    //   isMandatory: true,
    // },
    // {
    //   label: "Guide Registration Date",
    //   controlType: "datepicker",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "guideRegDate",
    //   // isMAxDate: false,
    //   // maxDate: new Date(),
    //   // isMinDate: true,
    //   // minDate: new Date(),
    //   isMandatory: true,
    // },
    // {
    //   label: "Valid Upto",
    //   controlType: "textfield",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "validUpTo",
    //   isMandatory: false,
    // },
    {
      label: "Guide For",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "guideFor",
      isMandatory: true,
    },
    {
      label: "Doc URL",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "docUrl",
      isMandatory: true,
    },
    {
      label: "Guided As",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "guideAs",
      isMandatory: true,
    },
    {
      label: "Details",
      controlType: "textarea",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "details",
      isMandatory: true,
    },
  ],
};
