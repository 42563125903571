import React, { Component } from "react";
import { connect } from "react-redux";
import "../../CSS/paymentSuccess.css";
import {
  getApplicantAdmission,
  saveApplicantFee,
  getPendingFees,
} from "../ApplicantAdmission/applicantAdmission.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import moment from "moment";
import { apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, convertedJson: "", txniId: "", userId: "" };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  componentDidMount() {
    let response = window.location.search;
    const urlParams = new URLSearchParams(response);
    const product = urlParams.get("returndata");
    let convertedJson = atob(product);
    var obj = JSON.parse(convertedJson);
    console.log(obj.responseCode);
    let resp = "";
    let paidStatus = 0;
    if (obj.responseCode == "0000") {
      resp = "success";
      paidStatus = 1;
    } else {
      resp = "fail";
      paidStatus = 0;
    }

    const transactionId = urlParams.get("txnid");
    const userRegId = urlParams.get("userId");
    let userId = localStorage.getItem("userRegistrationId");
    let admissionRegDetId = localStorage.getItem("admissionRegDetId");
    let acadamicYearId = localStorage.getItem("acadamicYearId");
    let tDate = moment(new Date()).format("DD-MM-YYYY");
    let directPaymentFlag = localStorage.getItem("directPayment");

    if (obj.responseCode == "0000") {
      this.props
        .getApplicantAdmission({ id: admissionRegDetId })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            if (
              response.data.admissionRegistration.getUserRegistration !== null
            ) {
              let feesToSave = {
                userRegistrationId: userId,
                academicYearId: acadamicYearId,
                admissionRegistrationDetailId: admissionRegDetId,
                transactionDate: tDate,
                totalFee: response.data.totalApplicableFees,
                transactionRemark: "College Fees",
                payType: "3",
                payRefNo: "Online",
                payBank: "Online",
                amt:
                  response.data.totalApplicableFees -
                  response.data.totalFeesPaid,
                transactionType: 0,
                transactionNo: transactionId,
                receiptTypeId: 1,
                isActive: 1,
                exVar1: 0,
                exVar2: 0,
                exVar3: 0,
              };
              this.props
                .saveApplicantFee({ saveToData: feesToSave })
                .then((response) => {
                  if (!response) {
                    this.props.getAlert({ message: "Something went wrong" });
                  }
                });
            }
          }
        });
    }

    apiGet({
      url:
        endpoint.paymentUrl +
        "-transaction/update-status?transactionId=" +
        transactionId +
        "&status=" +
        resp +
        "&data=" +
        product +
        "&mihpayId=13750ID",
    }).then(({ success }) => {
      if (!success) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.setState({
      jsonData: convertedJson,
      txnId: transactionId,
      userId: userRegId,
    });
  }

  render() {
    if (obj.responseCode == "0000") {
      return (
        <>
          <body className="payment-success-body">
            <div className="payment-success-card">
              <div
                style={{
                  borderRadius: "200px",
                  height: "200px",
                  width: "200px",
                  background: "#F8FAF5",
                  margin: "0 auto",
                }}
              >
                <i className="payment-success-i">
                  <span className="success-checkmark">✓</span>
                </i>
              </div>
              <h1 className="payment-success-h1">Success</h1>
              <p className="payment-success-p">
                Payment successfully done
                <br />
              </p>
            </div>
          </body>
        </>
      );
    } else {
      return (
        <div className="payment-failed-section">
          <h1 className="payment-failed-heading">Payment Failed</h1>
          <p className="payment-failed-message">
            Oops! Something went wrong with your payment.
          </p>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  promotionalAdmissionList: state.promotionalAdmmision,
});
const mapDispatchToProps = {
  getApplicantAdmission,
  getPendingFees,
  saveApplicantFee,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
