import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../../config/messageconstant";
import AdminStatusChange from "./AdminStatusChange";
import { downloadF } from "../../PDF/PDFDownload";
import endpoints from "../../../config/endpoints";
import SearchCertificate from "../../../components/SearchTable";
import {
  getAcceptedCertificateList,
  getDeliveredCertificateList,
  getPendingCertificateList,
  getDeliveredCertificateListFilter,
  getPreparedCertificateList,
  getRejectedCertificateList,
  getVerifyCertificateList,
  resetValues,
} from "./AdminStatusChange.slice";
import { viewCertificateDynamicAdminJson } from "../../../DynamicFormsJson/viewCertificateDynamicAdmin";

import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../components/Loading1";
// import UpdateInfo from "./UpdateInfo";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },

  {
    title: "Student Name",
    // name: "name",
     name: "firstName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    keyName:"firstName,lastName,fatherName,motherName",
    isFormat:true,
    type:0
    

  },
  {
    title: "Applied Date",
    name: "appliedDate",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Certificate Name",
    name: "cetificateName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  // {
  //   title: "Status",
  //   name: "status",
  //   positionCenter: false,
  //   showInscreen: true,
  //   alignCenter: "left",
  // },

  {
    title: "Remarks",
    name: "userRemark",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
];

const AdminCertificateStatus = ({
  onCancel,
  getDeliveredCertificateListFilter,
  getPendingCertificateList,
  getRejectedCertificateList,
  pendingCertificateList,
  getVerifyCertificateList,
  getAcceptedCertificateList,
  getPreparedCertificateList,
  getDeliveredCertificateList,
  getAlert,
  resetValues,
}) => {
  let navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const [updateStudInfo, setUpdateStudInfo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [interestedStudent, setInterestedStudent] = useState(true);
  const [studData, setStudData] = useState({});
  const [snakMsg, setSnakMsg] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [certificateData, setCertificateData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [popUpValue, setPopUpValue] = useState(false);
  const [toDateError, setToDateError] = React.useState("");
  const [fromDateError, setFromDateError] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    setValue(newValue);
    setSearchValue("");
  };

  const editStudentInfo = (data) => {
    setCertificateData(data);
    setUpdateStudInfo(true);
    setInterestedStudent(false);
  };

  const pdfDetailsId = async (rowData) => {
    let userAppReportURL = endpoints.certificateReport;
    downloadF({
      url:
        userAppReportURL +
        "/" +
        rowData.userRegistrationId +
        "/get-student-certificate?type=" +
        rowData.type,
    });
  };

  const backFromSave = (data) => {
    setCertificateData(data);
    setUpdateStudInfo(false);
    setInterestedStudent(true);
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    setShowLoader(true);
    getPendingCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });

    getVerifyCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });

    getAcceptedCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
    getPreparedCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
    getDeliveredCertificateList({ fromDate: fromDt, toDate: toDt }).then(
      (response) => {
        if (!response) {
          setShowLoader(false);
          getAlert({ message: "Something went wrong" });
        } else {
          setShowLoader(false);
        }
      }
    );
    getRejectedCertificateList({ fromDate: fromDt, toDate: toDt }).then(
      (response) => {
        if (!response) {
          setShowLoader(false);
          getAlert({ message: "Something went wrong" });
        } else {
          setShowLoader(false);
        }
      }
    );
  };

  const UpdateInfoData = () => {
    // resetValues();
    setUpdateStudInfo(false);
    setCertificateData({});
    setInterestedStudent(true);
  };

  useEffect(() => {
    setShowLoader(true);
    getDeliveredCertificateListFilter({ status: 4 }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
    getDeliveredCertificateListFilter({ status: 5 }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });

    getPendingCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });

    getVerifyCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });

    getAcceptedCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
    getPreparedCertificateList().then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
  }, []);

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      if (dataKey === "fromDate") {
        setFromDate(date);
      } else {
        setToDate(date);
      }
    }
  };

  const submitHandler = () => {
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");

    if (checkValidation()) {
      setShowLoader(true);
      getRejectedCertificateList({ fromDate: fromDt, toDate: toDt }).then(
        (response) => {
          if (!response) {
            setShowLoader(false);
            getAlert({ message: "Something went wrong" });
          } else {
            setShowLoader(false);
          }
        }
      );
    }
  };

  const submitHandlerDelivered = () => {
    let fromDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");

    if (checkValidation()) {
      setShowLoader(true);
      getDeliveredCertificateList({ fromDate: fromDt, toDate: toDt }).then(
        (response) => {
          if (!response) {
            setShowLoader(false);
            getAlert({ message: "Something went wrong" });
          } else {
            setShowLoader(false);
          }
        }
      );
    }
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };

  const getFilteredTableDataPending = () => {
    return pendingCertificateList?.pendingCertificate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["appliedDate"] &&
          currentRow["appliedDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["cetificateName"] &&
          currentRow["cetificateName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["userRemark"] &&
          currentRow["userRemark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  const getFilteredTableDataVerify = () => {
    return pendingCertificateList?.verifyCertificate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["appliedDate"] &&
          currentRow["appliedDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["cetificateName"] &&
          currentRow["cetificateName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["userRemark"] &&
          currentRow["userRemark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  const getFilteredTableDataAccepted = () => {
    return pendingCertificateList?.acceptedCertificate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["appliedDate"] &&
          currentRow["appliedDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["cetificateName"] &&
          currentRow["cetificateName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["userRemark"] &&
          currentRow["userRemark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  const getFilteredTableDataPrepared = () => {
    return pendingCertificateList?.preparedCertificate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["appliedDate"] &&
          currentRow["appliedDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["cetificateName"] &&
          currentRow["cetificateName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["userRemark"] &&
          currentRow["userRemark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  const getFilteredTableDataDelivered = () => {
    return pendingCertificateList?.deliveredCertificate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["appliedDate"] &&
          currentRow["appliedDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["cetificateName"] &&
          currentRow["cetificateName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["userRemark"] &&
          currentRow["userRemark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  const getFilteredTableDataRejected = () => {
    return pendingCertificateList?.rejectedCertificate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["appliedDate"] &&
          currentRow["appliedDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["cetificateName"] &&
          currentRow["cetificateName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["userRemark"] &&
          currentRow["userRemark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  return (
    <>
      {showLoader && <Loading1 />}
      {interestedStudent && (
        <div>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <TitleAndBreadCrum
              title={viewCertificateDynamicAdminJson.screenTitle}
            />
          </Paper>
          <br />
          <Grid
            container
            item
            justifyContent="space-evenly"
            spacing={2}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                <SearchCertificate
                  onSearch={onSearch}
                  searchValue={searchValue}
                />
              </Paper>
            </Grid>
          </Grid>
          <br />

          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handledChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    "Pending " +
                    pendingCertificateList?.pendingCertificate.length
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    "Verify " + pendingCertificateList?.verifyCertificate.length
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label={
                    "Accepted " +
                    pendingCertificateList?.acceptedCertificate.length
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  label={
                    "Prepared " +
                    pendingCertificateList?.preparedCertificate.length
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  label={
                    "Delivered " +
                    pendingCertificateList?.deliveredCertificate.length
                  }
                  {...a11yProps(4)}
                />
                <Tab
                  label={
                    "Rejected " +
                    pendingCertificateList?.rejectedCertificate.length
                  }
                  {...a11yProps(5)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <DynamicTable
                data={getFilteredTableDataPending()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfo}
                // showHeadPdf={true}
                showHeadEdit={true}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DynamicTable
                data={getFilteredTableDataVerify()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfo}
                // showHeadPdf={true}
                showHeadEdit={true}
              />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <DynamicTable
                data={getFilteredTableDataAccepted()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfo}
                // showHeadPdf={true}
                showHeadEdit={true}
              />
            </TabPanel>

            <TabPanel value={value} index={3}>
              <DynamicTable
                data={getFilteredTableDataPrepared()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfo}
                showHeadPdf={true}
                showHeadEdit={true}
                pdfDetailsId={pdfDetailsId}
              />
            </TabPanel>

            <TabPanel value={value} index={4}>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={onDateChange("fromDate")}
                        isError={formErrors.fromDateError ? true : false}
                        errorText={
                          formErrors.fromDateError
                            ? formErrors.fromDateError
                            : " "
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={onDateChange("toDate")}
                        isError={formErrors.toDateError ? true : false}
                        errorText={
                          formErrors.toDateError ? formErrors.toDateError : " "
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={submitHandlerDelivered}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
              </Grid>

              <DynamicTable
                data={getFilteredTableDataDelivered()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfo}
                showHeadPdf={true}
                showHeadEdit={false}
                // pdfDetailsId={pdfDetailsId}
                pdfDetailsId={pdfDetailsId}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={onDateChange("fromDate")}
                        isError={formErrors.fromDateError ? true : false}
                        errorText={
                          formErrors.fromDateError
                            ? formErrors.fromDateError
                            : " "
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={onDateChange("toDate")}
                        isError={formErrors.toDateError ? true : false}
                        errorText={
                          formErrors.toDateError ? formErrors.toDateError : " "
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={submitHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
              </Grid>

              <DynamicTable
                data={getFilteredTableDataRejected()}
                tableHead={columns}
                showPegination={false}
                rowEdit={editStudentInfo}
                // showHeadPdf={true}
                showHeadEdit={true}
              />
            </TabPanel>
          </Paper>
        </div>
      )}
      {updateStudInfo && (
        <AdminStatusChange
          goBack={UpdateInfoData}
          data={certificateData}
          studentData={studData}
          backFromSave={backFromSave}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  pendingCertificateList: state.adminCertificateList,
});

const mapDispatchToProps = {
  getAlert,
  resetValues,
  getPendingCertificateList,
  getDeliveredCertificateListFilter,
  getVerifyCertificateList,
  getAcceptedCertificateList,
  getPreparedCertificateList,
  getDeliveredCertificateList,
  getRejectedCertificateList,
  downloadF,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCertificateStatus);
