import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  subjectTypeDetails: [],
  isSubjectTypeDetailsFetch: false,
};
let URL = endpoints.subjectTypeDetails;
const subjectTypeDetailsSclice = createSlice({
  name: "subjectTypeDetails",
  initialState,
  reducers: {
    subjectTypeDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectTypeDetails: row,
        isSubjectTypeDetailsFetch: true,
      };
    },
  },
});

export const { subjectTypeDetailsSuccess } = subjectTypeDetailsSclice.actions;

export default subjectTypeDetailsSclice.reducer;

export const getSubjectTypeDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            subjectTypeName: data1.name+" - "+data1.shortName,
            minSub: data1.minSub,
            maxSub: data1.maxSub,
            shortName: data1.shortName,
            isActive: data1.isActive,
            showName: data1.showName,
          };
          row.push(bData);
          return data1;
        });
        dispatch(subjectTypeDetailsSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
