import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  subject: [],
  isFetch: false,
  student: [],
  isFetchStudent: false,
  examConduct: [],
  isFetchExamConduct: false,
  examConductionById: [],
  isFetchExamConductionById: true,
};
let URL = endpoints.examConduction;

const subjectSlice = createSlice({
  name: "examConduct",
  initialState,
  reducers: {
    subjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subject: row,
        isFetch: true,
      };
    },
    studentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        student: [...state.student, ...row],
        isFetchStudent: true,
      };
    },
    examConductSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        examConduct: row,
        isFetchExamConduct: true,
      };
    },
    examConductionByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        examConductionById: row,
        isFetchExamConductionById: true,
      };
    },

    resetListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        student: [],
        isFetchStudent: false,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  subjectSuccess,
  examConductSuccess,
  studentSuccess,
  resetSuccess,
  resetListSuccess,
  examConductionByIdSuccess,
} = subjectSlice.actions;

export default subjectSlice.reducer;

export const getExamConduct = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "?offset=0&limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];

        content.map((data1) => {
          index = index + 1;
          const teacherName =
            data1.getTeacher !== null
              ? data1.getTeacher.map((teacher) => {
                  return (
                    teacher.firstName +
                    " " +
                    teacher.middleName +
                    " " +
                    teacher.lastName
                  );
                })
              : [];
          let bData = {
            index: index,
            room: data1.rooms.name,
            teacherIds: data1.teacherIds,
            teacher: teacherName.join(),
            id: data1.id,
            scheduleDate: data1.scheduleDate,
          };
          row.push(bData);
          return data1;
        });
        dispatch(examConductSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getExamConductByFDTD =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-from-date-to-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];

          content.map((data1) => {
            index = index + 1;
            const teacherName =
              data1.getTeacher !== null
                ? data1.getTeacher.map((teacher) => {
                    return (
                      teacher.firstName +
                      " " +
                      teacher.middleName +
                      " " +
                      teacher.lastName
                    );
                  })
                : [];
            let bData = {
              index: index,
              room: data1.rooms.name,
              teacherIds: data1.teacherIds,
              teacher: teacherName.join(),
              id: data1.id,
              scheduleDate: data1.scheduleDate,
            };
            row.push(bData);
            return data1;
          });
          dispatch(examConductSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectForExamConduct =
  ({ scheduleDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-details-for-exam-conduction?scheduleDate=" +
          scheduleDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              name:
                data1.subjectName +
                " - " +
                data1.headShortName +
                " - " +
                data1.studentCount,
              id: data1.subjectDetailId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentForExamConduct =
  ({ scheduleDate, subjectDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-list?scheduleDate=" +
          scheduleDate +
          "&subjectDetailId=" +
          subjectDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              seatNo: data1.seatNo,
              name: data1.studentName,
              studentId: data1.studentId,
              id: data1.id,
              subjectName: data1.subjectName + " - " + data1.subjectShortName,
              headName: data1.headName + " - " + data1.headShortName,
              startTime: data1.startTime,
              endTime: data1.endTime,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentSuccess({ row }));
        }
        return { data, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExamConductionById =
  ({ examConductionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-list-by-exam-conduction?examConductionId=" +
          examConductionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              seatNo: data1.seatNo,
              name: data1.studentName,
              studentId: data1.studentId,
              id: data1.id,
              subjectName: data1.subjectName,
              headName: data1.headName,
              fromTime: data1.startTime,
              toTime: data1.endTime,
            };
            row.push(bData);
            return data1;
          });
          dispatch(examConductionByIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetData =
  ({ flag }) =>
  async (dispatch) => {
    try {
      if (flag) {
        dispatch(resetListSuccess());
      } else {
        dispatch(resetSuccess());
      }
    } catch (e) {
      return console.error(e.message);
    }
  };
