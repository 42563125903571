
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import {  apiGet } from "../../../../utils/api_service";

const initialState = {
  admissionType: [],
  isFetch: false,
};
let URL = endpoints.admissionType;
const admissionTypeSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    admissionTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        admissionType: row,
        isFetch: true,
      };
    },
  },
});

export const {
  admissionTypeSuccess,
} = admissionTypeSlice.actions;

export default admissionTypeSlice.reducer;

export const getAdmissionType = () => async (dispatch) => {
  try {
 const response= apiGet({
    url: URL+'?sort={"insertdatetime": "DESC"}'
}).then(({data ,success }) => {
    if (success) {
      const {content} = data.data
      let index = 0;
      let row = [];
      content.map((data1) => {
        index = index + 1
        let bData = {
          index: index,
          id: data1.id,
          name: data1.name,
          isActive: data1.isActive ,

        };
        row.push(bData);
        return data1;
      });
        dispatch(admissionTypeSuccess({ row }));
    }
    return success
});
return response
  } catch (e) {
   
    return console.error(e.message);
  }
};