import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";

const initialState = {
  religion: [],
  isReligionFetch: false,
  category: [],
  isCategoryFetch: false,
  bloodGroup: [],
  isFetchBloodGroup: false,
  annualIncome: [],
  isFetchAnnualIncome: false,
  state: [],
  isStateFetch: false,
  district: [],
  isDistrictFetch: false,
  taluka: [],
  isTalukaFetch: false,
  applicant: {},
  isSave: false,
  employeeRoleAccess: [],
  isFetchEmployeeRoleAccess: false,
  profile: {},
  isFetchProfile: false,
};
let URL = endpoints.religion;
let categoryURL = endpoints.category;
let bloodGroupURL = endpoints.bloodGroup;
let annualIncomeURL = endpoints.annualIncome;
let stateURL = endpoints.state;
let districtURL = endpoints.district;
let talukaURL = endpoints.taluka;
let studentSaveURL = endpoints.saveStudent;
let saveUserRegistration = endpoints.saveUserRegistration;
let userApplication = endpoints.userApplication;
let roleAccessURL = endpoints.roleAccess;
let profileInfo = endpoints.profileInfo;
let uniqueEmailId = endpoints.uniqueEmailId;
const StudentPostSlice = createSlice({
  name: "studentpost",
  initialState,
  reducers: {
    religionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        religion: row,
        isReligionFetch: true,
      };
    },
    categorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        category: row,
        isCategoryFetch: true,
      };
    },
    bloodGroupSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        bloodGroup: row,
        isFetchBloodGroup: true,
      };
    },
    annualIncomeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        annualIncome: row,
        isFetchAnnualIncome: true,
      };
    },
    stateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        state: row,
        isStateFetch: true,
      };
    },
    districtSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        district: row,
        isDistrictFetch: true,
      };
    },
    talukaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        taluka: row,
        isTalukaFetch: true,
      };
    },
    applicantSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        applicant: data,
        isSave: true,
      };
    },
    employeeRoleAccessSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        employeeRoleAccess: row,
        isFetchEmployeeRoleAccess: true,
      };
    },
    profileSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        profile: row,
        isFetch: true,
      };
    },
  },
});

export const {
  religionSuccess,
  categorySuccess,
  bloodGroupSuccess,
  annualIncomeSuccess,
  stateSuccess,
  districtSuccess,
  talukaSuccess,
  applicantSuccess,
  employeeRoleAccessSuccess,
  profileSuccess,
} = StudentPostSlice.actions;

export default StudentPostSlice.reducer;

export const getReligion = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(religionSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getRoleAccess = () => async (dispatch) => {
  try {
    const success = apiGet({
      url: roleAccessURL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.empTypeId,

            name: data1.empTypeName === "" ? "" : data1.empTypeName,

            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(employeeRoleAccessSuccess({ row }));
      }
      return success;
    });
    return success;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCategory = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: categoryURL, //filter type = 0
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            type: data1.type,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(categorySuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getBloodGroup = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: bloodGroupURL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(bloodGroupSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAnnualIncome = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: annualIncomeURL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(annualIncomeSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getState = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: stateURL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(stateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getDistrict = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: districtURL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
            stid: data1.state.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(districtSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTaluka = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: talukaURL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
            distid: data1.district.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(talukaSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const saveStudent =
  ({ studData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: studentSaveURL,
        postBody: studData,
      }).then(({ data, success }) => {
        if (success) {
          const userRegistration = data.data;
          const userRegistrationResponse = {
            aadhaarNo: userRegistration.aadhaarNo,
            academicYearId: userRegistration.academicYearId,
            admissionType: userRegistration.admissionType,
            allotlettno: userRegistration.allotlettno,
            annualIncome: userRegistration.annualIncome,
            bankAccountNo: userRegistration.bankAccountNo,
            bankBranch: userRegistration.bankBranch,
            bankName: userRegistration.bankName,
            birthPlace: userRegistration.birthPlace,
            bloodGroup: userRegistration.bloodGroup,
            medium: userRegistration.mediumOfLanguageInPreviousCollege,
            capround: userRegistration.capround,
            cast: userRegistration.cast,
            castCertificateDate: userRegistration.castCertificateDate,
            castCertificateNo: userRegistration.castCertificateNo,
            categoryId: userRegistration.categoryId,
            dateOfBirth: userRegistration.dateOfBirth,
            dateOfRepo: userRegistration.dateOfRepo,
            defenceType: userRegistration.defenceType,
            domicileof: userRegistration.domicileof,
            dse: userRegistration.dse,
            ebc: userRegistration.ebc,
            eligibilityNo: userRegistration.eligibilityNo,
            fatherName: userRegistration.fatherName,
            fatherOccupation: userRegistration.fatherOccupation,
            firstName: userRegistration.firstName,
            gender: userRegistration.gender,
            grnNo: userRegistration.grnNo,
            handicapCategory: userRegistration.handicapCategory,
            id: userRegistration.id,
            ifscCode: userRegistration.ifscCode,
            intrestIn: userRegistration.intrestIn,
            isActive: userRegistration.isActive,
            isOrphan: userRegistration.isOrphan,
            isStudentNriForeignNational:
              userRegistration.isStudentNriForeignNational,
            landlineNo: userRegistration.landlineNo,
            lastName: userRegistration.lastName,
            lcIssued: userRegistration.lcIssued,
            libraryNo: userRegistration.libraryNo,
            martialStatus: userRegistration.martialStatus,
            meritNo: userRegistration.meritNo,
            micrCode: userRegistration.micrCode,
            middleName: userRegistration.middleName,
            minority: userRegistration.minority,
            motherName: userRegistration.motherName,
            motherTongue: userRegistration.motherTongue,
            nationality: userRegistration.nationality,
            oms: userRegistration.oms,
            parentEmail: userRegistration.parentEmail,
            parentMobile: userRegistration.parentMobile,
            passout: userRegistration.passout,
            permanentAddressLine1: userRegistration.permanentAddressLine1,
            permanentAddressLine2: userRegistration.permanentAddressLine2,
            permanentAddressLine3: userRegistration.permanentAddressLine3,
            permanentCity: userRegistration.permanentCity,
            permanentDistrict: userRegistration.permanentDistrict,
            permanentPin: userRegistration.permanentPin,
            permanentState: userRegistration.permanentState,
            permanentTaluka: userRegistration.permanentTaluka,
            previousInstitute: userRegistration.previousInstitute,
            previousInstituteAddress: userRegistration.previousInstituteAddress,
            previousInstituteArea: userRegistration.previousInstituteArea,
            previousInstituteMedium: userRegistration.previousInstituteMedium,
            prnNo: userRegistration.prnNo,
            profilePic: userRegistration.profilePic,
            profilePicture: userRegistration.profilePicture,
            programTitleId: userRegistration.programTitleId,
            religionId: userRegistration.religionId,
            remark: userRegistration.remark,
            ruralUrban: userRegistration.ruralUrban,
            scholarship: userRegistration.scholarship,
            seatNo: userRegistration.seatNo,
            signature: userRegistration.signature,
            studentEmail: userRegistration.studentEmail,
            studentMobile: userRegistration.studentMobile,
            subCast: userRegistration.subCast,
            temporaryAddressLine1: userRegistration.temporaryAddressLine1,
            temporaryAddressLine2: userRegistration.temporaryAddressLine2,
            temporaryAddressLine3: userRegistration.temporaryAddressLine3,
            temporaryCity: userRegistration.temporaryCity,
            temporaryDistrict: userRegistration.temporaryDistrict,
            temporaryPin: userRegistration.temporaryPin,
            temporaryState: userRegistration.temporaryState,
            temporaryTaluka: userRegistration.temporaryTaluka,
            type: userRegistration.type,
            userId: userRegistration.userId,
            userRegistrationDepartment:
              userRegistration.userRegistrationDepartment !== null
                ? userRegistration.userRegistrationDepartment.id
                : "",
            userQualificationDetailSaves:
              userRegistration.userQualificationDetailSaves === null
                ? []
                : userRegistration.userQualificationDetailSaves.map(
                    (userQualificationDetailData, index) => {
                      let userDocData = {
                        id: userQualificationDetailData.id,
                        index: index + 1,
                        qualificationName:
                          userQualificationDetailData.getQualification !== null
                            ? userQualificationDetailData.getQualification.name
                            : "",
                        state: userQualificationDetailData.state,
                        board: userQualificationDetailData.board,
                        country: userQualificationDetailData.country,
                        boardUniversity: userQualificationDetailData.university,
                        yearOfPass: userQualificationDetailData.yearOfPass,
                        obtainedMarks:
                          userQualificationDetailData.marksObtained,
                        totalMarks: userQualificationDetailData.marksOutof,
                        percentage: userQualificationDetailData.percentage,
                        nameOfSchoolCollege:
                          userQualificationDetailData.nameOfSchoolCollege,
                        qualificationId:
                          userQualificationDetailData.qualificationId,
                        isActive: userQualificationDetailData.isActive,
                      };
                      return userDocData;
                    }
                  ),
            validityCdDate: userRegistration.validityCdDate,
            validityCdNo: userRegistration.validityCdNo,
            userDocumentDetailSaves:
              userRegistration.userDocumentDetailSaves === null
                ? []
                : userRegistration.userDocumentDetailSaves.map(
                    (userDocumentDetailData, index) => {
                      let userDocData = {
                        id: userDocumentDetailData.id,
                        index: index + 1,
                        docDesc:
                          userDocumentDetailData.getDocument !== null
                            ? userDocumentDetailData.getDocument.name
                            : "",
                        documentPath: userDocumentDetailData.documentFileName,
                        documentId: userDocumentDetailData.documentId,
                      };
                      return userDocData;
                    }
                  ),
          };
          dispatch(applicantSuccess({ data: userRegistrationResponse }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentById =
  ({ studId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: saveUserRegistration + "/" + studId,
      }).then(({ data, success }) => {
        if (success) {
          const userRegistration = data.data;
          const userRegistrationResponse = {
            aadhaarNo: userRegistration.aadhaarNo,
            academicYearId: userRegistration.academicYearId,
            admissionType: userRegistration.admissionType,
            allotlettno: userRegistration.allotlettno,
            annualIncome: userRegistration.annualIncome,
            bankAccountNo: userRegistration.bankAccountNo,
            bankBranch: userRegistration.bankBranch,
            bankName: userRegistration.bankName,
            birthPlace: userRegistration.birthPlace,
            bloodGroup: userRegistration.bloodGroup,
            medium: userRegistration.mediumOfLanguageInPreviousCollege,
            capround: userRegistration.capround,
            cast: userRegistration.cast,
            castCertificateDate: userRegistration.castCertificateDate,
            castCertificateNo: userRegistration.castCertificateNo,
            categoryId: userRegistration.categoryId,
            dateOfBirth: userRegistration.dateOfBirth,
            dateOfRepo: userRegistration.dateOfRepo,
            defenceType: userRegistration.defenceType,
            domicileof: userRegistration.domicileof,
            dse: userRegistration.dse,
            ebc: userRegistration.ebc,
            eligibilityNo: userRegistration.eligibilityNo,
            fatherName: userRegistration.fatherName,
            fatherOccupation: userRegistration.fatherOccupation,
            firstName: userRegistration.firstName,
            gender: userRegistration.gender,
            grnNo: userRegistration.grnNo,
            handicapCategory: userRegistration.handicapCategory,
            id: userRegistration.id,
            ifscCode: userRegistration.ifscCode,
            intrestIn: userRegistration.intrestIn,
            isActive: userRegistration.isActive,
            isOrphan: userRegistration.isOrphan,
            isStudentNriForeignNational:
              userRegistration.isStudentNriForeignNational,
            landlineNo: userRegistration.landlineNo,
            lastName: userRegistration.lastName,
            lcIssued: userRegistration.lcIssued,
            libraryNo: userRegistration.libraryNo,
            martialStatus: userRegistration.martialStatus,
            meritNo: userRegistration.meritNo,
            micrCode: userRegistration.micrCode,
            middleName: userRegistration.middleName,
            minority: userRegistration.minority,
            motherName: userRegistration.motherName,
            motherTongue: userRegistration.motherTongue,
            nationality: userRegistration.nationality,
            oms: userRegistration.oms,
            parentEmail: userRegistration.parentEmail,
            parentMobile: userRegistration.parentMobile,
            passout: userRegistration.passout,
            permanentAddressLine1: userRegistration.permanentAddressLine1,
            permanentAddressLine2: userRegistration.permanentAddressLine2,
            permanentAddressLine3: userRegistration.permanentAddressLine3,
            permanentCity: userRegistration.permanentCity,
            permanentDistrict: userRegistration.permanentDistrict,
            permanentPin: userRegistration.permanentPin,
            permanentState: userRegistration.permanentState,
            permanentTaluka: userRegistration.permanentTaluka,
            previousInstitute: userRegistration.previousInstitute,
            previousInstituteAddress: userRegistration.previousInstituteAddress,
            previousInstituteArea: userRegistration.previousInstituteArea,
            previousInstituteMedium: userRegistration.previousInstituteMedium,
            prnNo: userRegistration.prnNo,
            profilePic: userRegistration.profilePic,
            profilePicture: userRegistration.profilePicture,
            programTitleId: userRegistration.programTitleId,
            religionId: userRegistration.religionId,
            remark: userRegistration.remark,
            ruralUrban: userRegistration.ruralUrban,
            scholarship: userRegistration.scholarship,
            seatNo: userRegistration.seatNo,
            signature: userRegistration.signature,
            studentEmail: userRegistration.studentEmail,
            studentMobile: userRegistration.studentMobile,
            subCast: userRegistration.subCast,
            temporaryAddressLine1: userRegistration.temporaryAddressLine1,
            temporaryAddressLine2: userRegistration.temporaryAddressLine2,
            temporaryAddressLine3: userRegistration.temporaryAddressLine3,
            temporaryCity: userRegistration.temporaryCity,
            temporaryDistrict: userRegistration.temporaryDistrict,
            temporaryPin: userRegistration.temporaryPin,
            temporaryState: userRegistration.temporaryState,
            temporaryTaluka: userRegistration.temporaryTaluka,
            type: userRegistration.type,
            userRegistrationDepartment:
              userRegistration.userRegistrationDepartment !== null
                ? userRegistration.userRegistrationDepartment.id
                : "",
            userQualificationDetailSaves:
              userRegistration.userQualificationDetailSaves === null
                ? []
                : userRegistration.userQualificationDetailSaves.map(
                    (userQualificationDetailData, index) => {
                      let userDocData = {
                        index: index + 1,
                        id: userQualificationDetailData.id,
                        qualificationName:
                          userQualificationDetailData.getQualification.name,
                        qualificationId:
                          userQualificationDetailData.qualificationId,
                        country: userQualificationDetailData.country,
                        state: userQualificationDetailData.state,
                        board: userQualificationDetailData.board,
                        boardUniversity: userQualificationDetailData.university,
                        obtainedMarks:
                          userQualificationDetailData.marksObtained,
                        totalMarks: userQualificationDetailData.marksOutof,
                        percentage: userQualificationDetailData.percentage,
                        yearOfPass: userQualificationDetailData.yearOfPass,

                        certificateNo:
                          userQualificationDetailData.certificateNo,
                        examSeatNo: userQualificationDetailData.examSeatNo,
                        nameOfSchoolCollege:
                          userQualificationDetailData.nameOfSchoolCollege,

                        isActive: userQualificationDetailData.isActive,
                      };
                      return userDocData;
                    }
                  ),
            userId: userRegistration.userId,
            userRegistrationDepartment:
              userRegistration.userRegistrationDepartment !== null
                ? userRegistration.userRegistrationDepartment.id
                : "",
            userDocumentDetailSaves:
              userRegistration.userDocumentDetailSaves === null
                ? []
                : userRegistration.userDocumentDetailSaves.map(
                    (userDocumentDetailData, index) => {
                      let userDocData = {
                        id: userDocumentDetailData.id,
                        index: index + 1,
                        docDesc:
                          userDocumentDetailData.getDocument !== null
                            ? userDocumentDetailData.getDocument.name
                            : "",
                        documentPath: userDocumentDetailData.documentFileName,
                        documentId: userDocumentDetailData.documentId,
                      };
                      return userDocData;
                    }
                  ),
            validityCdDate: userRegistration.validityCdDate,
            validityCdNo: userRegistration.validityCdNo,
          };
          dispatch(applicantSuccess({ data: userRegistrationResponse }));
          return success;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentByApplicationId =
  ({ userApplicationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: userApplication + "/" + userApplicationId,
      }).then(({ data, success }) => {
        if (success) {
          const studentData = data.data;
          const userRegistrationResponse = {
            aadhaarNo: studentData.userRegistration.aadhaarNo,
            academicYearId: studentData.userRegistration.academicYearId,
            admissionType: studentData.userRegistration.admissionType,
            allotlettno: studentData.userRegistration.allotlettno,
            annualIncome: studentData.userRegistration.annualIncome,
            bankAccountNo: studentData.userRegistration.bankAccountNo,
            bankBranch: studentData.userRegistration.bankBranch,
            bankName: studentData.userRegistration.bankName,
            birthPlace: studentData.userRegistration.birthPlace,
            bloodGroup: studentData.userRegistration.bloodGroup,
            medium:
              studentData.userRegistration.mediumOfLanguageInPreviousCollege,
            capround: studentData.userRegistration.capround,
            cast: studentData.userRegistration.cast,
            castCertificateDate:
              studentData.userRegistration.castCertificateDate,
            castCertificateNo: studentData.userRegistration.castCertificateNo,
            categoryId: studentData.userRegistration.categoryId,
            dateOfBirth: studentData.userRegistration.dateOfBirth,
            dateOfRepo: studentData.userRegistration.dateOfRepo,
            defenceType: studentData.userRegistration.defenceType,
            domicileof: studentData.userRegistration.domicileof,
            dse: studentData.userRegistration.dse,
            ebc: studentData.userRegistration.ebc,
            eligibilityNo: studentData.userRegistration.eligibilityNo,
            fatherName: studentData.userRegistration.fatherName,
            fatherOccupation: studentData.userRegistration.fatherOccupation,
            firstName: studentData.userRegistration.firstName,
            gender: studentData.userRegistration.gender,
            grnNo: studentData.userRegistration.grnNo,
            handicapCategory: studentData.userRegistration.handicapCategory,
            id: studentData.userRegistration.id,
            ifscCode: studentData.userRegistration.ifscCode,
            intrestIn: studentData.userRegistration.intrestIn,
            isActive: studentData.userRegistration.isActive,
            isOrphan: studentData.userRegistration.isOrphan,
            isStudentNriForeignNational:
              studentData.userRegistration.isStudentNriForeignNational,
            landlineNo: studentData.userRegistration.landlineNo,
            lastName: studentData.userRegistration.lastName,
            lcIssued: studentData.userRegistration.lcIssued,
            libraryNo: studentData.userRegistration.libraryNo,
            martialStatus: studentData.userRegistration.martialStatus,
            meritNo: studentData.userRegistration.meritNo,
            micrCode: studentData.userRegistration.micrCode,
            middleName: studentData.userRegistration.middleName,
            minority: studentData.userRegistration.minority,
            motherName: studentData.userRegistration.motherName,
            motherTongue: studentData.userRegistration.motherTongue,
            nationality: studentData.userRegistration.nationality,
            oms: studentData.userRegistration.oms,
            parentEmail: studentData.userRegistration.parentEmail,
            parentMobile: studentData.userRegistration.parentMobile,
            passout: studentData.userRegistration.passout,
            permanentAddressLine1:
              studentData.userRegistration.permanentAddressLine1,
            permanentAddressLine2:
              studentData.userRegistration.permanentAddressLine2,
            permanentAddressLine3:
              studentData.userRegistration.permanentAddressLine3,
            permanentCity: studentData.userRegistration.permanentCity,
            permanentDistrict: studentData.userRegistration.permanentDistrict,
            permanentPin: studentData.userRegistration.permanentPin,
            permanentState: studentData.userRegistration.permanentState,
            permanentTaluka: studentData.userRegistration.permanentTaluka,
            previousInstitute: studentData.userRegistration.previousInstitute,
            previousInstituteAddress:
              studentData.userRegistration.previousInstituteAddress,
            previousInstituteArea:
              studentData.userRegistration.previousInstituteArea,
            previousInstituteMedium:
              studentData.userRegistration.previousInstituteMedium,
            prnNo: studentData.userRegistration.prnNo,
            profilePic: studentData.userRegistration.profilePic,
            profilePicture: studentData.userRegistration.profilePicture,
            programTitleId: studentData.userRegistration.programTitleId,
            religionId: studentData.userRegistration.religionId,
            remark: studentData.userRegistration.remark,
            ruralUrban: studentData.userRegistration.ruralUrban,
            scholarship: studentData.userRegistration.scholarship,
            seatNo: studentData.userRegistration.seatNo,
            signature: studentData.userRegistration.signature,
            studentEmail: studentData.userRegistration.studentEmail,
            studentMobile: studentData.userRegistration.studentMobile,
            subCast: studentData.userRegistration.subCast,
            temporaryAddressLine1:
              studentData.userRegistration.temporaryAddressLine1,
            temporaryAddressLine2:
              studentData.userRegistration.temporaryAddressLine2,
            temporaryAddressLine3:
              studentData.userRegistration.temporaryAddressLine3,
            temporaryCity: studentData.userRegistration.temporaryCity,
            temporaryDistrict: studentData.userRegistration.temporaryDistrict,
            temporaryPin: studentData.userRegistration.temporaryPin,
            temporaryState: studentData.userRegistration.temporaryState,
            temporaryTaluka: studentData.userRegistration.temporaryTaluka,
            type: studentData.userRegistration.type,
            userQualificationDetailSaves:
              studentData.userQualificationDetails === null
                ? []
                : studentData.userQualificationDetails.map(
                    (userQualificationDetailData, index) => {
                      let userDocData = {
                        index: index + 1,
                        id: userQualificationDetailData.id,
                        country: userQualificationDetailData.country,
                        state: userQualificationDetailData.state,
                        board: userQualificationDetailData.board,
                        boardUniversity: userQualificationDetailData.university,
                        yearOfPass: userQualificationDetailData.yearOfPass,
                        obtainedMarks:
                          userQualificationDetailData.marksObtained,
                        totalMarks: userQualificationDetailData.marksOutof,
                        certificateNo:
                          userQualificationDetailData.certificateNo,
                        examSeatNo: userQualificationDetailData.examSeatNo,
                        nameOfSchoolCollege:
                          userQualificationDetailData.nameOfSchoolCollege,
                        percentage: userQualificationDetailData.percentage,
                        qualificationName:
                          userQualificationDetailData.qualification !== null
                            ? userQualificationDetailData.qualification.name
                            : "",
                        qualificationId:
                          userQualificationDetailData.qualification !== null
                            ? userQualificationDetailData.qualification.id
                            : "",
                        isActive: userQualificationDetailData.isActive,
                      };
                      return userDocData;
                    }
                  ),
            userId: studentData.userRegistration.userId,
            // userRegistrationDepartment:
            //   studentData.userRegistrationDepartment !== null ||
            //   studentData.userRegistrationDepartment != undefined
            //     ? studentData.userRegistrationDepartment.id
            //     : "",
            userDocumentDetailSaves:
              studentData.userDocumentDetails === null
                ? []
                : studentData.userDocumentDetails.map(
                    (userDocumentDetailData, index) => {
                      let userDocData = {
                        id: userDocumentDetailData.id,
                        index: index + 1,
                        docDesc:
                          userDocumentDetailData.document !== null
                            ? userDocumentDetailData.document.name
                            : "",
                        documentPath: userDocumentDetailData.documentFileName,
                        documentId:
                          userDocumentDetailData.document !== null
                            ? userDocumentDetailData.document.id
                            : "",
                      };
                      return userDocData;
                    }
                  ),
            validityCdDate: studentData.userRegistration.validityCdDate,
            validityCdNo: studentData.userRegistration.validityCdNo,
          };
          dispatch(applicantSuccess({ data: userRegistrationResponse }));
          return success;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentInfoByPRNNo =
  ({ prnNo }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: endpoints.studentList + "/by-prnno?prnno=" + prnNo,
      }).then(({ data, success }) => {
        if (success && data.data.length !== 0) {
          const userRegistration = data.data[0];
          const userRegistrationResponse = {
            aadhaarNo: userRegistration.aadhaarNo,
            academicYearId: userRegistration.academicYearId,
            admissionType: userRegistration.admissionType,
            allotlettno: userRegistration.allotlettno,
            annualIncome: userRegistration.annualIncome,
            bankAccountNo: userRegistration.bankAccountNo,
            bankBranch: userRegistration.bankBranch,
            bankName: userRegistration.bankName,
            birthPlace: userRegistration.birthPlace,
            bloodGroup: userRegistration.bloodGroup,
            medium: userRegistration.mediumOfLanguageInPreviousCollege,
            capround: userRegistration.capround,
            cast: userRegistration.cast,
            castCertificateDate: userRegistration.castCertificateDate,
            castCertificateNo: userRegistration.castCertificateNo,
            categoryId: userRegistration.categoryId,
            dateOfBirth: userRegistration.dateOfBirth,
            dateOfRepo: userRegistration.dateOfRepo,
            defenceType: userRegistration.defenceType,
            totalExperiances: userRegistration.totalExperiences,
            panNo: userRegistration.seatNo,
            domicileof: userRegistration.domicileof,
            dse: userRegistration.dse,
            ebc: userRegistration.ebc,
            eligibilityNo: userRegistration.eligibilityNo,
            fatherName: userRegistration.fatherName,
            fatherOccupation: userRegistration.fatherOccupation,
            firstName: userRegistration.firstName,
            gender: userRegistration.gender,
            grnNo: userRegistration.grnNo,
            handicapCategory: userRegistration.handicapCategory,
            id: userRegistration.id,
            ifscCode: userRegistration.ifscCode,
            intrestIn: userRegistration.intrestIn,
            isActive: userRegistration.isActive,
            isOrphan: userRegistration.isOrphan,
            isStudentNriForeignNational:
              userRegistration.isStudentNriForeignNational,
            landlineNo: userRegistration.landlineNo,
            lastName: userRegistration.lastName,
            lcIssued: userRegistration.lcIssued,
            libraryNo: userRegistration.libraryNo,
            martialStatus: userRegistration.martialStatus,
            meritNo: userRegistration.meritNo,
            micrCode: userRegistration.micrCode,
            middleName: userRegistration.middleName,
            minority: userRegistration.minority,
            motherName: userRegistration.motherName,
            motherTongue: userRegistration.motherTongue,
            nationality: userRegistration.nationality,
            oms: userRegistration.oms,
            parentEmail: userRegistration.parentEmail,
            parentMobile: userRegistration.parentMobile,
            passout: userRegistration.passout,
            permanentAddressLine1: userRegistration.permanentAddressLine1,
            permanentAddressLine2: userRegistration.permanentAddressLine2,
            permanentAddressLine3: userRegistration.permanentAddressLine3,
            permanentCity: userRegistration.permanentCity,
            permanentDistrict: userRegistration.permanentDistrict,
            permanentPin: userRegistration.permanentPin,
            permanentState: userRegistration.permanentState,
            permanentTaluka: userRegistration.permanentTaluka,
            previousInstitute: userRegistration.previousInstitute,
            previousInstituteAddress: userRegistration.previousInstituteAddress,
            previousInstituteArea: userRegistration.previousInstituteArea,
            previousInstituteMedium: userRegistration.previousInstituteMedium,
            prnNo: userRegistration.prnNo,
            profilePic: userRegistration.profilePic,
            profilePicture: userRegistration.profilePicture,
            programTitleId: userRegistration.programTitleId,
            religionId: userRegistration.religionId,
            remark: userRegistration.remark,
            ruralUrban: userRegistration.ruralUrban,
            scholarship: userRegistration.scholarship,
            seatNo: userRegistration.seatNo,
            signature: userRegistration.signature,
            studentEmail: userRegistration.studentEmail,
            studentMobile: userRegistration.studentMobile,
            subCast: userRegistration.subCast,
            temporaryAddressLine1: userRegistration.temporaryAddressLine1,
            temporaryAddressLine2: userRegistration.temporaryAddressLine2,
            temporaryAddressLine3: userRegistration.temporaryAddressLine3,
            temporaryCity: userRegistration.temporaryCity,
            temporaryDistrict: userRegistration.temporaryDistrict,
            temporaryPin: userRegistration.temporaryPin,
            temporaryState: userRegistration.temporaryState,
            temporaryTaluka: userRegistration.temporaryTaluka,
            type: userRegistration.type,
            userId: userRegistration.userId,
            userQualificationDetailSaves:
              userRegistration.userQualificationDetailSaves === null
                ? []
                : userRegistration.userQualificationDetailSaves.map(
                    (userQualificationDetailData, index) => {
                      let userDocData = {
                        id: userQualificationDetailData.id,
                        index: index + 1,
                        qualificationName:
                          userQualificationDetailData.getQualification !== null
                            ? userQualificationDetailData.getQualification.name
                            : "",
                        state: userQualificationDetailData.state,
                        board: userQualificationDetailData.board,
                        boardUniversity: userQualificationDetailData.university,
                        yearOfPass: userQualificationDetailData.yearOfPass,
                        obtainedMarks:
                          userQualificationDetailData.marksObtained,
                        totalMarks: userQualificationDetailData.marksOutof,
                        percentage: userQualificationDetailData.percentage,
                        country: userQualificationDetailData.country,
                        nameOfSchoolCollege:
                          userQualificationDetailData.nameOfSchoolCollege,

                        qualificationId:
                          userQualificationDetailData.qualificationId,
                        isActive: userQualificationDetailData.isActive,
                      };
                      return userDocData;
                    }
                  ),
            validityCdDate: userRegistration.validityCdDate,
            validityCdNo: userRegistration.validityCdNo,
            userRegistrationDepartment:
              userRegistration.userRegistrationDepartment !== null
                ? userRegistration.userRegistrationDepartment.id
                : "",
            userDocumentDetailSaves:
              userRegistration.userDocumentDetailSaves === null
                ? []
                : userRegistration.userDocumentDetailSaves.map(
                    (userDocumentDetailData, index) => {
                      let userDocData = {
                        id: userDocumentDetailData.id,
                        index: index + 1,
                        docDesc:
                          userDocumentDetailData.getDocument !== null
                            ? userDocumentDetailData.getDocument.name
                            : "",
                        documentPath: userDocumentDetailData.documentFileName,
                        documentId: userDocumentDetailData.documentId,
                      };
                      return userDocData;
                    }
                  ),
          };
          dispatch(applicantSuccess({ data: userRegistrationResponse }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProfile = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: profileInfo,
    }).then(({ data, success }) => {
      if (success) {
        const profile = data.data;
        let row = [];
        if (profile !== null) {
          localStorage.setItem(
            "userName",
            profile.firstName.charAt(0) +
              "." +
              profile.middleName.charAt(0) +
              "." +
              profile.lastName
          );
          localStorage.setItem("person", profile.firstName);
          localStorage.setItem("userRegistrationId", profile.id);
          localStorage.setItem("profilePic", profile.profilePic);

          
        }

        console.log("Success Save Student ========>", data);
        console.log(data);
        const userRegistration = data.data;
        const userRegistrationResponse = {
          aadhaarNo: userRegistration.aadhaarNo,
          academicYearId: userRegistration.academicYearId,
          admissionType: userRegistration.admissionType,
          allotlettno: userRegistration.allotlettno,
          annualIncome: userRegistration.annualIncome,
          bankAccountNo: userRegistration.bankAccountNo,
          bankBranch: userRegistration.bankBranch,
          bankName: userRegistration.bankName,
          birthPlace: userRegistration.birthPlace,
          bloodGroup: userRegistration.bloodGroup,
          medium: userRegistration.mediumOfLanguageInPreviousCollege,
          capround: userRegistration.capround,
          cast: userRegistration.cast,
          castCertificateDate: userRegistration.castCertificateDate,
          castCertificateNo: userRegistration.castCertificateNo,
          categoryId: userRegistration.categoryId,
          dateOfBirth: userRegistration.dateOfBirth,
          dateOfRepo: userRegistration.dateOfRepo,
          defenceType: userRegistration.defenceType,
          totalExperiances: userRegistration.totalExperiences,
          panNo: userRegistration.seatNo,
          domicileof: userRegistration.domicileof,
          dse: userRegistration.dse,
          ebc: userRegistration.ebc,
          eligibilityNo: userRegistration.eligibilityNo,
          fatherName: userRegistration.fatherName,
          fatherOccupation: userRegistration.fatherOccupation,
          firstName: userRegistration.firstName,
          gender: userRegistration.gender,
          grnNo: userRegistration.grnNo,
          handicapCategory: userRegistration.handicapCategory,
          id: userRegistration.id,
          ifscCode: userRegistration.ifscCode,
          intrestIn: userRegistration.intrestIn,
          isActive: userRegistration.isActive,
          isOrphan: userRegistration.isOrphan,
          isStudentNriForeignNational:
            userRegistration.isStudentNriForeignNational,
          landlineNo: userRegistration.landlineNo,
          lastName: userRegistration.lastName,
          lcIssued: userRegistration.lcIssued,
          libraryNo: userRegistration.libraryNo,
          martialStatus: userRegistration.martialStatus,
          meritNo: userRegistration.meritNo,
          micrCode: userRegistration.micrCode,
          middleName: userRegistration.middleName,
          minority: userRegistration.minority,
          motherName: userRegistration.motherName,
          motherTongue: userRegistration.motherTongue,
          nationality: userRegistration.nationality,
          oms: userRegistration.oms,
          parentEmail: userRegistration.parentEmail,
          parentMobile: userRegistration.parentMobile,
          passout: userRegistration.passout,
          permanentAddressLine1: userRegistration.permanentAddressLine1,
          permanentAddressLine2: userRegistration.permanentAddressLine2,
          permanentAddressLine3: userRegistration.permanentAddressLine3,
          permanentCity: userRegistration.permanentCity,
          permanentDistrict: userRegistration.permanentDistrict,
          permanentPin: userRegistration.permanentPin,
          permanentState: userRegistration.permanentState,
          permanentTaluka: userRegistration.permanentTaluka,
          previousInstitute: userRegistration.previousInstitute,
          previousInstituteAddress: userRegistration.previousInstituteAddress,
          previousInstituteArea: userRegistration.previousInstituteArea,
          previousInstituteMedium: userRegistration.previousInstituteMedium,
          prnNo: userRegistration.prnNo,
          profilePic: userRegistration.profilePic,
          profilePicture: userRegistration.profilePicture,
          programTitleId: userRegistration.programTitleId,
          religionId: userRegistration.religionId,
          remark: userRegistration.remark,
          ruralUrban: userRegistration.ruralUrban,
          scholarship: userRegistration.scholarship,
          seatNo: userRegistration.seatNo,
          signature: userRegistration.signature,
          studentEmail: userRegistration.studentEmail,
          studentMobile: userRegistration.studentMobile,
          subCast: userRegistration.subCast,
          temporaryAddressLine1: userRegistration.temporaryAddressLine1,
          temporaryAddressLine2: userRegistration.temporaryAddressLine2,
          temporaryAddressLine3: userRegistration.temporaryAddressLine3,
          temporaryCity: userRegistration.temporaryCity,
          temporaryDistrict: userRegistration.temporaryDistrict,
          temporaryPin: userRegistration.temporaryPin,
          temporaryState: userRegistration.temporaryState,
          temporaryTaluka: userRegistration.temporaryTaluka,
          type: userRegistration.type,
          userId: userRegistration.userId,
          userQualificationDetailSaves:
            userRegistration.userQualificationDetailSaves === null
              ? []
              : userRegistration.userQualificationDetailSaves.map(
                  (userQualificationDetailData, index) => {
                    let userDocData = {
                      id: userQualificationDetailData.id,
                      index: index + 1,
                      qualificationName:
                        userQualificationDetailData.getQualification !== null
                          ? userQualificationDetailData.getQualification.name
                          : "",
                      state: userQualificationDetailData.state,
                      board: userQualificationDetailData.board,
                      boardUniversity: userQualificationDetailData.university,
                      yearOfPass: userQualificationDetailData.yearOfPass,
                      obtainedMarks: userQualificationDetailData.marksObtained,
                      totalMarks: userQualificationDetailData.marksOutof,
                      percentage: userQualificationDetailData.percentage,
                      country: userQualificationDetailData.country,
                      nameOfSchoolCollege:
                        userQualificationDetailData.nameOfSchoolCollege,

                      qualificationId:
                        userQualificationDetailData.qualificationId,
                      isActive: userQualificationDetailData.isActive,
                    };
                    return userDocData;
                  }
                ),
          validityCdDate: userRegistration.validityCdDate,
          validityCdNo: userRegistration.validityCdNo,
          userRegistrationDepartment:
            userRegistration.userRegistrationDepartment !== null
              ? userRegistration.userRegistrationDepartment.id
              : "",
          userDocumentDetailSaves:
            userRegistration.userDocumentDetailSaves === null
              ? []
              : userRegistration.userDocumentDetailSaves.map(
                  (userDocumentDetailData, index) => {
                    let userDocData = {
                      id: userDocumentDetailData.id,
                      index: index + 1,
                      docDesc:
                        userDocumentDetailData.getDocument !== null
                          ? userDocumentDetailData.getDocument.name
                          : "",
                      documentPath: userDocumentDetailData.documentFileName,
                      documentId: userDocumentDetailData.documentId,
                    };
                    return userDocData;
                  }
                ),
        };
        dispatch(applicantSuccess({ data: userRegistrationResponse }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUniqueEmailId =
  ({ emailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: uniqueEmailId + "?emailId=" + emailId,
      }).then(({ data, success }) => {
        if (success) {
          return { data, success };
        } else {
          return { data, success: false };
        }
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
