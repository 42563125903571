import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import DynamicTable from "../../../../components/Comman/RT/MaterialUIRTTable";
import { getSubjectSchemeBySubjectId } from "./subjectInfo.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
import TeachingSyllabus from "../../../TeachingPlan/CreateSubjectSchedule/index";
import { getSyllabus } from "../../../TeachingPlan/teachingPlan.slice";

class SubjectDetails extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "name",
      title: "Scheme Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    // {
    //   name: "name",
    //   title: "Student Name",
    //   positionCenter: false,
    //   showInscreen: true,
    //   alignCenter: "left",
    //   canSearch: true,
    // },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  state = {
    id: 0,
    subjectCode: "",
    subjectName: "",
    patternName: "",
    formErrors: [],
    semesterName: "",
    subjectTypeName: "",
    openSyllabusForm: false,
    openTable: true,
    remark: "",
    subjectDetail: {},
  };

  componentDidMount() {
    const {
      subjectData,
      getSubjectSchemeBySubjectId,
      getAlert,
      SubjectInfoList,
    } = this.props;
    getSubjectSchemeBySubjectId({ subjectId: subjectData.id }).then(
      (response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      }
    );
    console.log(subjectData);
  }

  onCancleHandler = () => {
    this.props.onCancle();
  };

  onClickOpenSyllabusForm = (row) => {
    const { getSyllabus, getAlert } = this.props;
    getSyllabus({ subjectDetailId: row.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          openSyllabusForm: true,
          openTable: false,
          subjectDetail: row,
        });
      }
    });
  };

  onCancelForm = () => {
    this.setState({
      openSyllabusForm: false,
      openTable: true,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };

  render() {
    const { openSyllabusForm, openTable, subjectCode, subjectDetail, date } =
      this.state;
    const { SubjectInfoList } = this.props;
    return (
      <>
        <div>
          {openTable && (
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  {" "}
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    m={2}
                    rowSpacing={1}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                  >
                    <Grid item xs={12} sm={10.7} md={10.7}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label={"View Subject Details"}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1.3}
                      lg={1.3}
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back"
                        fullWidth={true}
                        onClick={() => {
                          this.onCancleHandler();
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={"Subject Code : "}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={SubjectInfoList?.subjectById.code}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    columnSpacing={3}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={"Subject : "}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={SubjectInfoList?.subjectById.name}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    columnSpacing={3}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={"Pattern : "}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={SubjectInfoList?.subjectById.patternName}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    columnSpacing={3}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={"Semester Name : "}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={SubjectInfoList?.subjectById.semisterName}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                    columnSpacing={3}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={2} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={"Subject Type Name : "}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={SubjectInfoList?.subjectById.subjectTypeName}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ mr: 1, mt: 1 }} xs={12} sm={12} md={12}>
                  <DynamicTable
                    data={
                      Object.keys(SubjectInfoList?.subjectById).length !== 0
                        ? SubjectInfoList?.subjectById.subjectDetails
                        : []
                    }
                    tableHead={this.columns}
                    showPegination={false}
                    showHeadAdd={true}
                    rowId={this.onClickOpenSyllabusForm}
                  />
                </Grid>
              </>
            </Paper>
          )}

          {openSyllabusForm && (
            <TeachingSyllabus
              onCancelForm={this.onCancelForm}
              subjectDetail={subjectDetail}
            />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ SubjectInfoList: state.subjectInfo });
const mapDispatchToProps = {
  getSubjectSchemeBySubjectId,
  getAlert,
  getSyllabus,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubjectDetails);
