import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";
const initialState = {
  attendedSeminar: [],
  isFetch: false,
};
let URL = endpoints.resourcePerson;
const AttendedSeminarSlice = createSlice({
  name: "attendedSeminar",
  initialState,
  reducers: {
    attendedSeminarSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        attendedSeminar: row,
        isFetch: true,
      };
    },
  },
});

export const { attendedSeminarSuccess } = AttendedSeminarSlice.actions;

export default AttendedSeminarSlice.reducer;

export const getAttendedSeminar = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"formType":"' + "4" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];

        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            publication: data1.publication,
            pressDate: data1.pressDate,
            type: data1.type,
            typeName:
              data1.type === "0"
                ? "Innovation"
                : data1.type == "1"
                ? "Journal"
                : data1.type == "2"
                ? "Publication"
                : data1.type === "3"
                ? "Seminar Attended"
                : data1.type == "4"
                ? "Webinar Attended"
                : data1.type === "5"
                ? "Conference Attended"
                : "",
          };
          row.push(bData);
          return data1;
        });
        dispatch(attendedSeminarSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getAttendedSeminarByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-publication-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&formType=4",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              publication: data1.publication,
              pressDate: data1.pressDate,
              typeName:
                data1.type == "0"
                  ? "Innovation"
                  : data1.type == "1"
                  ? "Journal"
                  : data1.type == "2"
                  ? "Publication"
                  : data1.type === "3"
                  ? "Seminar Attended"
                  : data1.type == "4"
                  ? "Webinar Attended"
                  : data1.type === "5"
                  ? "Conference Attended"
                  : "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(attendedSeminarSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };



export const getAttendedSeminarWithoutDate =
( ) =>
async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL +
        "/get-employee-publication-list-by-limit?formType=4&limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            typeName:
              data1.type == "0"
                ? "Innovation"
                : data1.type == "1"
                ? "Journal"
                : data1.type == "2"
                ? "Publication"
                : data1.type === "3"
                ? "Seminar Attended"
                : data1.type == "4"
                ? "Webinar Attended"
                : data1.type === "5"
                ? "Conference Attended"
                : "",
            pressDate: data1.pressDate,

            publication: data1.publication,
            
          };
          row.push(bData);
          return data1;
        });
        dispatch(attendedSeminarSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
