import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { ButtonCompo } from "../../components/Comman/Button";
import { downloadFile, viewMultipleFile } from "../UploadFile/file.slice";
import {
  getEmpProfile,
  getEmployeeById,
} from "../EmployeeDetails/employee.slice";
import { getProfile } from "../BasicDetailsPost/basicDetails.slice";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { getAlert } from "../../CommonActions/alert.slice";
import StudentMasterPost from "../EmployeeDetails/index";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
const DeclarationPreview = ({
  employeeDetails,
  GoBack,
  backtoList,
  getAlert,
  getEmployeeById,
  getEmpProfile,
  getProfile,
  open = false,
}) => {
  const [back, setBack] = useState(true);
  const [openDetailForm, setOpenDetailForm] = React.useState(false);

  useEffect(() => {
    if (Object.keys(employeeDetails.employee).length === 0) {
      setBack(false);
      getEmpProfile().then((resp) => {
        if (!resp) {
          getAlert({
            message: "Something went wrong",
          });
        }
      });
    } else {
      apiGet({
        url: endpoint.profileInfo,
      }).then(({ success, data }) => {
        if (success) {
          if (data.data.id === employeeDetails.employee.id) {
            setBack(false);
          }
        }
      });
    }
  }, []);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editStudent = () => {
    setOpenDetailForm(true);
    getEmployeeById({ employeeId: employeeDetails.employee.id }).then((res) => {
      if (res) {
        // this.props.onSave( true );
      } else {
        getAlert({
          message: "Something went wrong ",
        });
      }
    });
  };
  const onCancel = () => {
    setOpenDetailForm(false);
    getEmpProfile().then((resp) => {
      if (!resp) {
        getAlert({
          message: "Something went wrong",
        });
      }
    });
  };

  return (
    <>
      {!openDetailForm && (
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <div align="right">
            {open && (
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name={"Back To Dashboard"}
                onClick={GoBack}
              />
            )}
            &nbsp;&nbsp;&nbsp;
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name={back ? "Back" : "Edit"}
              onClick={back ? GoBack : editStudent}
            />
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    icon={<InfoIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Basic Details"
                    value="1"
                  />
                  <Tab
                    icon={<HomeIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Address Details"
                    value="2"
                  />

                  <Tab
                    icon={<DocumentScannerIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Qualification & Document Details"
                    value="4"
                  />

                  <Tab
                    icon={<PhotoSizeSelectActualIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Photo and Sign"
                    value="5"
                  />
                  <Tab
                    icon={<PhotoSizeSelectActualIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Designation,Role and Promotion Records"
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Step1 data={employeeDetails.employee} />
              </TabPanel>
              <TabPanel value="2">
                <Step2 data={employeeDetails.employee} />
              </TabPanel>
              <TabPanel value="5">
                <Step4 data={employeeDetails.employee} />
              </TabPanel>
              <TabPanel value="4">
                <Step5 data={employeeDetails.employee} />
              </TabPanel>
              <TabPanel value="3">
                <Step3 data={employeeDetails.employee} />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      )}
      {openDetailForm && (
        <>
          <StudentMasterPost editForm={true} onCancel={onCancel} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  employeeDetails: state.employeeList,
});

const mapDispatchToProps = {
  viewMultipleFile,
  downloadFile,
  getEmpProfile,
  getAlert,
  getEmployeeById,
  getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationPreview);
