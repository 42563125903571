export const vivaDeclarationListDynamicJson = {
    screenTitle: "Viva Declaration List",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    getAcadamicYear: true,
    getListId: 'yearDetail',
    fieldMeta: [
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "yearDetailMaster",
            // getListId: 'yearDetail',
            dataKey: "yearDetail",
            isMandatory: true,
        },
        {
            label: "Intern Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "internshipMaster",
            dataKey: "internship",
            isMandatory: true,
        },
    ],
};
