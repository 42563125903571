
import endpoint from "../config/endpoints";
    
export const InternshipTypeJson = {
apiBaseURL: endpoint.internshipType,
screenTitle: "Internship Type",
showAddButton: true,
showPdfDownload: true,
pdfFileName: "Internship Type",
showExcelDownload: true,
excelFileName: "Internship Type",
tableColumnsToFilter: [
    {
        columnDisplayName: "Name",
        columnKeyName: "name",
        isChecked: true
    },
    {
        columnDisplayName: "Status",
        columnKeyName: "isActive",
        isChecked: true
    }
],
baseIdColumn: "id",
fieldMeta: [
    {
        label: "Name",
        controlType: "textfield",
        placeHolder: "",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        dataKey: "name",
        isMandatory: true
    }
]
}