import { Grid, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber, textOnly } from "../../components/Comman/Util/Validations";
class PersonalDetailsForm extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    candidatename: "",
    firstname: "",
    middleName: "",
    lastname: "",
    fatherHusband: "",
    mothername: "",
    gender: "",
    dob: "",
    nationality: "",
    relegion: "",
    category: "",
    caste: "",
    subcaste: "",
    bloodgroup: "",
    fathersoccupation: "",
    familyincome: "",
    validitycdno: "",
    validitycddate: "",
    castecertno: "",
    castecertdate: "",
    domicileof: "",
    mothertounge: "",
    parentmobile: "",
    studmobile: "",
    medium: "",
    parentemail: "",
    studemail: "",
    remark: "",
    landline: "",
    aadhaarNo: "",
    birthPlace: "",
    martialStatus: "",
    isStudentNriForeignNational: "",
    oms: "",
    scholarship: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || textOnly.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandlerEmail = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changeDob = (date) => {
    this.setState({
      dob: date,
    });
  };

  changeValidityDate = (date) => {
    this.setState({
      validitycddate: date,
    });
  };

  componentDidMount() {
    const { studentList } = this.props;

    if (studentList.isFetch) {
      this.setDataToTextField(studentList.admissionForm);
    }
  }
  setDataToTextField(data) {
    if (data.length !== 0) {
      this.setState({
        id: data.id,
        candidatename: data.firstName,
        middleName: data.middleName,
        lastname: data.lastName,
        gender: data.gender,
        dob: data.dateOfBirth,
        studemail: data.studentEmail,
        studmobile: data.studentMobile,
        medium: data.medium,
        aadhaarNo: data.aadhaarNo,
        birthPlace: data.birthPlace,
        nationality: data.nationality,
        relegion: data.religion,
        caste: data.cast,
        category: data.category,
        subcaste: data.subCast,
        bloodgroup: data.bloodGroup,
        validitycdno: data.validityCdNo,
        validitycddate: data.validityCdDate,
        castecertno: data.castCertificateNo,
        castecertdate: data.castCertificateDate,
        domicileof: data.domicileof,
        mothertounge: data.motherTongue,
        fatherHusband: data.fatherName,
        mothername: data.motherName,
        fathersoccupation: data.fatherOccupation,
        familyincome: data.annualIncome,
        parentmobile: data.parentMobile,
        parentemail: data.parentEmail,
        landline: data.landlineNo,
        remark: data.remark,
        martialStatus: data.martialStatus,
        isStudentNriForeignNational: data.isStudentNriForeignNational,
        oms: data.oms,
        scholarship: data.scholarship,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.admissionForm !==
        nextProps.studentList.admissionForm
      ) {
        this.setDataToTextField(nextProps.studentList.admissionForm);
      }
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      studmobile,
      medium,
      studemail,
      parentemail,
      remark,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
    } = this.state;

    // let fd = moment(dob).format("DD-MM-YYYY");
    // let vd = moment(validitycddate).format("DD-MM-YYYY");
    // let cd = moment(castecertdate).format("DD-MM-YYYY");

    let docs = {
      id: id,
      firstName: candidatename.toUpperCase(),
      middleName: middleName.toUpperCase(),
      lastName: lastname.toUpperCase(),
      motherName: mothername.toUpperCase(),
      fatherName: fatherHusband.toUpperCase(),
      gender: gender,
      dateOfBirth: dob,
      bloodGroup: bloodgroup,
      religionId: relegion,
      studCategory: category,
      cast: caste,
      subCast: subcaste,
      fatherOccupation: fathersoccupation,
      annualIncome: familyincome,
      nationality: nationality,
      domicileof: domicileof,
      motherTongue: mothertounge,
      parentMobile: parentmobile,
      parentEmail: parentemail,
      studentMobile: studmobile,
      mediumOfLanguageInPreviousCollege: medium,
      studentEmail: studemail,
      landlineNo: landline,
      remark: remark,
      validityCdNo: validitycdno,
      validityCdDate: validitycddate,
      castCertificateNo: castecertno,
      castCertificateDate: castecertdate,
      aadhaarNo: aadhaarNo,
      birthPlace: birthPlace,
      martialStatus: martialStatus,
      isStudentNriForeignNational: isStudentNriForeignNational,
      oms: oms,
      scholarship: scholarship,
      type: 1,
    };

    // if (this.handleFormValidation()) {
    this.props.OnSave(docs);
    // }
  };
  render() {
    const {
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      fathersoccupation,
      familyincome,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      parentmobile,
      studmobile,
      medium,
      studemail,
      parentemail,
      remark,
      landline,
      aadhaarNo,
      birthPlace,
      martialStatus,
      isStudentNriForeignNational,
      oms,
      scholarship,
    } = this.state;

    const { onCancel } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={12} />

            <Grid
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="First Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="candidatename"
                      size="small"
                      value={candidatename}
                      fullWidth
                      onChange={this.changedHandler}
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  rowSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Middle Name"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="middleName"
                      size="small"
                      value={middleName}
                      fullWidth
                      onChange={this.changedHandler}
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  rowSpacing={2}
                  columnSpacing={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Last Name"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="lastname"
                      size="small"
                      value={lastname}
                      fullWidth
                      onChange={this.changedHandler}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Gender"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="gender"
                    size="small"
                    value={gender}
                    fullWidth
                    onChange={this.changedHandler}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Date of Birth"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="dob"
                    size="small"
                    value={dob}
                    fullWidth
                    onChange={this.changedHandler}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Birth Place"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="birthPlace"
                    size="small"
                    value={birthPlace}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>{" "}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Blood Group"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="bloodgroup"
                    size="small"
                    value={bloodgroup}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Medium (Language of previous college)"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="medium"
                    size="small"
                    value={medium}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Student Contact No"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studmobile"
                    size="small"
                    value={studmobile}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Student Email"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studemail"
                    size="small"
                    value={studemail}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Father's/Husband's Name"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="fatherHusband"
                    size="small"
                    value={fatherHusband}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Mother's Name"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothername"
                    size="small"
                    value={mothername}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Marital Status"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="martialStatus"
                    size="small"
                    value={martialStatus}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Mother Tounge"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothertounge"
                    size="small"
                    value={mothertounge}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Father's Occupation"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="fathersoccupation"
                    size="small"
                    value={fathersoccupation}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Family's Annual Income"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="familyincome"
                    size="small"
                    value={familyincome}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Is Other Than Maharashtra"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="oms"
                    size="small"
                    value={oms}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>{" "}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Is Student NRI/Foreign National"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="isStudentNriForeignNational"
                    size="small"
                    value={isStudentNriForeignNational}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Aadhar No"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="aadhaarNo"
                    size="small"
                    value={aadhaarNo}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Nationality"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="nationality"
                    size="small"
                    value={nationality}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Religion"
                  />
                </Grid>

                <Grid m={-3} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="relegion"
                    size="small"
                    value={relegion}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Category"
                  />
                </Grid>

                <Grid m={-3} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="category"
                    size="small"
                    value={category}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Caste"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="caste"
                    size="small"
                    value={caste}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Subcaste"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="subcaste"
                    size="small"
                    value={subcaste}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Is Scholarship"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="scholarship"
                    size="small"
                    value={scholarship}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Domicile State"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="domicileof"
                    size="small"
                    value={domicileof}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate No"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="castecertno"
                    size="small"
                    value={castecertno}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate Date"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="castecertdate"
                    size="small"
                    value={castecertdate}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate No"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="validitycdno"
                    size="small"
                    value={validitycdno}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate Date"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="validitycddate"
                    size="small"
                    value={validitycddate}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Parent's Contact No"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentmobile"
                    size="small"
                    value={parentmobile}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Parent's Email"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentemail"
                    size="small"
                    value={parentemail}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Landline No"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="landline"
                    size="small"
                    value={landline}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark"
                  />
                </Grid>

                <Grid m={-1} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    onChange={this.changedHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={12}></Grid>

            <Grid item xs={12} sm={6} md={1} />

            <Grid
              rowSpacing={2}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.1}
                lg={1.1}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={2}
                lg={2}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  religionMaster: state.religion,
  categoryList: state.category,
  studentList: state.admissionForm,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsForm);
