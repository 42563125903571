import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { getDocumentVerificationDataList } from "../DocumentVerification/documentVerification.slice";
import DocumentVerificationForm from "../VerificationDocument/VerificationDocumentForm";
import DocumentVerificationTable from "../VerificationDocument/VerificationDocumentTable";
import SaveRemark from "./SaveRemark";
import { getStatus } from "../VerificationDocument/verification.slice";
import { resetValues } from "../DocumentVerification/documentVerification.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { getStudentByApplicationId } from "../BasicDetailsPost/basicDetails.slice";
import StudentPreview from "../StudentPreview1/index";
import SearchDocument from "../../components/SearchTable";
import NewLandingScreenHeader from "../../components/Comman/NewLandingScreenHeader";
import { VerificationDocumentJson } from "../../DynamicFormsJson/VerificationDocumentJson";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
const VerificationDocument = ({
  getDocumentVerificationDataList,
  getStatus,
  resetValues,
  getAlert,
  getStudentByApplicationId,
  documentVerificationList
}) => {
  const [saveData, setSaveData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = React.useState(false);
  const [idAndStatus, setIdAndStatus] = useState({});
  const [openPreview, setOpenPreview] = useState(false);
  const rowStatusVerified = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to confirm their verification status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((newValue) => {
      if (newValue) {
        let data = {
          id: id,
          status: 1,
        };
        setIdAndStatus(data);
        setOpen(true);
      }
    });
  };
  const rowStatusPending = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to keep their pending status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((newValue) => {
      if (newValue) {
        let data = {
          id: id,
          status: 0,
        };
        setIdAndStatus(data);
        setOpen(true);
      }
    });
  };

  const rowStatusCancel = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to cancel their verification status?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((newValue) => {
      if (newValue) {
        setOpen(true);
        let data = {
          id: id,
          status: 2,
        };
        setIdAndStatus(data);
      }
    });
  };

  useEffect(() => {
    resetValues();
  }, []);

  const verificationData = (data) => {
    setSaveData(data);
  };
  const saveRemarkData = (event) => {
    getStatus({
      id: idAndStatus.id,
      status: idAndStatus.status,
      remark: event,
    }).then((response) => {
      if (!response) {
        getAlert({
          message: "Something went wrong ",
          // : "Data updated successfully",
        });
      } else {
        setOpen(false);
        getDocumentVerificationDataList({
          appId: saveData.appId,
          progId: saveData.progId,
          roundId: saveData.roundId,
          acceptId: saveData.acceptId,
        }).then((response) => {
          if (!response) {
            getAlert({
              message: "Something went wrong ",
              // : "Data updated successfully",
            });
          }
        });
      }
    });
  };
  const rowViewData = (userApplicationId) => {
    getStudentByApplicationId({ userApplicationId: userApplicationId }).then(
      (response) => {
        if (response) {
          setOpenPreview(true);
        } else {
          getAlert({ message: "Something went wrong" });
        }
      }
    );
  };
  const GoBack = () => {
    setOpenPreview(false);
  };
 const getFilteredTableData = () => {
    return documentVerificationList.documentVerificationDataList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["studentName"] &&
          currentRow["studentName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
            (currentRow["branch"] &&
          currentRow["branch"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["phno"] &&
          currentRow["phno"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["remark"] &&
          currentRow["remark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
 const onSearch = (value) => {
    setSearchValue(value)
  };
  return (
    <>
    <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        <TitleAndBreadCrum title={VerificationDocumentJson.screenTitle} />
        </Paper>
        <br/>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid item xs={12} sm={12} md={12}>
            <SearchDocument
              onSearch={onSearch}
            />
            </Grid>
        </Paper>
        <br/>
      {!openPreview && (
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <DocumentVerificationForm onSave={verificationData} />
          <Grid item sx={{ p: 2 }} xs={12} sm={12} md={12}>
            <DocumentVerificationTable
              rowStatusVerified={rowStatusVerified}
              rowStatusCancel={rowStatusCancel}
              rowStatusPending={rowStatusPending}
              rowViewData={rowViewData}
              rowList={getFilteredTableData()}
            />
          </Grid>

          <SaveRemark open={open} onclose={setOpen} onSubmit={saveRemarkData} />

          {/* <Grid
          sx={{ mt: 3 }}
          rowSpacing={2}
          container
          columnSpacing={1}
          justifyContent="right"
        >
          <Grid
            item
            m={1}
            sm={12}
            xs={12}
            md={1.1}
            lg={1.1}
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back"
              fullWidth={true}
            />
          </Grid>
        </Grid> */}
        </Paper>
      )}
      {openPreview && <StudentPreview GoBack={GoBack} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {
  getDocumentVerificationDataList,
  getStatus,
  resetValues,
  getAlert,
  getStudentByApplicationId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationDocument);
