export const DynamicReportJson = {
  screenTitle: "Time Table Reports",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  fieldMeta: [
    {
      label: "Department",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "departmentMaster",
      getListId: "department",

      dataKey: "department",
      isMandatory: true,
    },
    {
      label: "Teacher",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "teacherMaster",
      getListFrom: "department",
      dataKey: "teacherIds",
      isMandatory: true,
    },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearMaster",
      getListId: "yearDetail,acadamicYear",
      dataKey: "yearDetail",
      isMandatory: true,
    },

    {
      label: "Division",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "divisionMaster",
      getListFrom: "yearDetail",
      dataKey: "divisionIds",
      isMandatory: true,
    },
    {
      label: "Time Slot",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "timeSlotMaster",
      dataKey: "timeSlot",
      isMandatory: true,
    },
    {
      label: "Floor",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "floorMaster",
      dataKey: "floor",
      isMandatory: true,
    },
    {
      label: "Room",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "roomMaster",
      dataKey: "room",
      isMandatory: true,
    }
  ],
};
