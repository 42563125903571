import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { LabelCompo } from "../../../components/Comman/Label";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, classes }) => {
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Social Profile"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a", width: "20%" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Website
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.websitePortfoliBlog === ""
                ? "-"
                : data.websitePortfoliBlog === null
                ? "-"
                : data.websitePortfoliBlog}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Facebook
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.facebookProfile === ""
                ? "-"
                : data.facebookProfile === null
                ? "-"
                : data.facebookProfile}
            </td>
          </tr>
          <tr>
            <td className={classes.MuiTableHeadRoot}>&nbsp; Linkedin</td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.linkedinProfile === ""
                ? "-"
                : data.linkedinProfile === null
                ? "-"
                : data.linkedinProfile}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              className={classes.MuiTableHeadRoot}
              style={{ color: "#5a5a5a" }}
            >
              &nbsp; You Tube
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.youtubeChannel === ""
                ? "-"
                : data.youtubeChannel === null
                ? "-"
                : data.youtubeChannel}
            </td>
          </tr>
          <tr>
            <td className={classes.MuiTableHeadRoot}>&nbsp; Instagram</td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.instagramProfile === ""
                ? "-"
                : data.instagramProfile === null
                ? "-"
                : data.instagramProfile}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Twitter
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.twitterHhandle === ""
                ? "-"
                : data.twitterHhandle === null
                ? "-"
                : data.twitterHhandle}
            </td>
          </tr>
        </table>
        <Grid>&nbsp;&nbsp;</Grid>
      </div>
    </>
  );
};
export default withStyles(styles)(BasicTable);
