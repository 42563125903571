import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import { generateExcel } from "../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../components/Comman/GeneratePDF";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../components/Loading1";
import SearchEvents from "../../../components/SearchTable";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import {
  getAllEmployees,
  getEmployeeById,
} from "../../EmployeeDetails/employee.slice";
import AddEvent from "../../Event/Event Management/AddEvent";
import AppliedStudentList from "../../Event/Event Management/AppliedStudentList";
import EmployeeFeedbackDeclare from "../../Feedback/Employee Feedback/EmployeeFeedbackDeclare";
import StudentFeedbackDeclare from "../../Feedback/Student Feedback/StudentFeedbackDeclare";
import AddNotice from "../../Masters/Faculty/AcademicDetails/AdminNotice/AddNotice";
import AddStudentNotice from "../../Masters/Faculty/AcademicDetails/StudentNotice/AddStudentNotice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getEventAppliedStudent, getEventByAcademicId } from "./event.slice";
import EventCommitteeForm from "./EventCommitteeForm";
import DynamicTable from "./EventTable";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class ViewingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: false,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: false,
      showAddCommitteeForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      showLoader: false,
      department: "",
      academicYearId: "",
      departmentList: [],
      editData: {},
      committeeData: {},
      eventDataForAppliedStudents: {},
      eventDataForEmpFeedback: {},
      eventDataForStudFeedback: {},
      eventDataForEmpNotice: {},
      eventDataForStudNotice: {},
      searchValue: "",
    };
  }

  OnClickEdit = (data) => {
    this.setState({
      showAddCommitteeForm: false,
      showTable: false,
      showForm: true,
      editData: data,
    });
  };

  submitHandler = () => {};

  addEventCommittee = (data) => {
    this.setState({
      showAddCommitteeForm: true,
      showTable: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: false,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: false,
      committeeData: data,
    });
  };

  AppliedStudentList = (rowData) => {
    const { getEventAppliedStudent } = this.props;
    getEventAppliedStudent({ id: rowData.id }).then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Failed to delete (Students have applied against the event)",
        });
      }
    });
    apiGet({
      url:
        endpoint.yearDetailId +
        "/get-distinct-year-detail-by-year-detail-ids?yearDetailIds=" +
        rowData.yearDetailIds,
    }).then(({ data, success }) => {
      if (!success) {
        this.props.getAlert({
          message: "Failed to change status",
        });
      } else {
        rowData = {
          ...rowData,
          yearDetails: data.map((year) => {
            return (
              year.year.programType.name +
              " - " +
              year.programTitle.brName +
              " - " +
              year.year.className
            );
          }),
        };

        this.setState({
          showAppliedStudentsForm: true,
          showTable: false,
          showStudNoticeForm: false,
          showEmpNoticeForm: false,
          showStudFeedbackForm: false,
          showEmpFeedbackForm: false,
          eventDataForAppliedStudents: rowData,
        });
      }
    });
  };

  AddEmployeeFeedback = (data) => {
    this.setState({
      showAppliedStudentsForm: false,
      showTable: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: false,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: true,
      eventDataForEmpFeedback: data,
    });
  };

  AddStudentFeedback = (data) => {
    this.setState({
      showAppliedStudentsForm: false,
      showTable: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: false,
      showStudFeedbackForm: true,
      showEmpFeedbackForm: false,
      eventDataForStudFeedback: data,
    });
  };

  AddEmployeeNotice = (data) => {
    this.setState({
      showAppliedStudentsForm: false,
      showTable: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: true,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: false,
      eventDataForEmpNotice: data,
    });
  };

  AddStudentNotice = (data) => {
    this.setState({
      showAppliedStudentsForm: false,
      showTable: false,
      showStudNoticeForm: true,
      showEmpNoticeForm: false,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: false,
      eventDataForStudNotice: data,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "name",
      title: "Event Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 200,
    },
    {
      name: "venue",
      title: "Venue",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: 200,
    },
    {
      name: "fromDate",
      title: "From Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 110,
    },
    {
      name: "toDate",
      title: "To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 110,
    },

    {
      name: "time",
      title: "From Time",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 90,
    },
    {
      name: "toTime",
      title: "To Time",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 90,
    },
    {
      name: "isActive",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: 170,
    },
  ];
  //   columns = [
  //     { name: "index", title: "Sr. No." },
  //     { name: "name", title: "Event Name" },
  //     { name: "venue", title: "Venue" },
  //     { name: "fromDate", title: "From Date" },
  //     { name: "toDate", title: "To Date" },
  //     { name: "time", title: "Time" },
  //     { name: "applicationLastDate", title: "Last Date" },
  //     {
  //       name: "isActive",
  //       title: "Status",
  //       getCellValue: (params) => {
  //         const onClickChangeStatus = (e) => {
  //           e.stopPropagation();
  //           this.onChangeAction(params);
  //         };
  //         return (
  //           <ChipCompo
  //             label={params.isActive === 1 ? "Active" : "Inactive"}
  //             variant="outlined"
  //             size="small"
  //             color={params.isActive === 1 ? "success" : "error"}
  //             onClick={onClickChangeStatus}
  //           />
  //         );
  //       },
  //     },

  //     {
  //       name: "action",
  //       title: "Action",
  //       getCellValue: (params) => {
  //         const addCommitteList = (e) => {
  //           e.stopPropagation();
  //           this.addEventCommittee(params);
  //         };

  //         const studentList = (e) => {
  //           e.stopPropagation();
  //           this.AppliedStudentList(params);
  //         };

  //         const onClickDelete = (e) => {
  //           e.stopPropagation();
  //           this.rowDelete(params);
  //         };

  //         const onClickEdit = (e) => {
  //           e.stopPropagation();
  //           this.OnClickEdit(params);
  //         };

  //         return (
  //           <div>
  //             <MyComponentWithIconProps
  //               statusImage={EditIcon}
  //               color="black"
  //               fontSize="medium"
  //               title="Edit"
  //               onClick={onClickEdit}
  //             />
  //             &nbsp;&nbsp;
  //             <MyComponentWithIconProps
  //               statusImage={DeleteIcon}
  //               color="error"
  //               fontSize="medium"
  //               title="Delete"
  //               onClick={onClickDelete}
  //             />
  //             &nbsp;&nbsp;
  //             <MyComponentWithIconProps
  //               statusImage={PersonAddAlt1Icon}
  //               color="black"
  //               fontSize="medium"
  //               title="Add Event Committee"
  //               onClick={addCommitteList}
  //             />
  //             &nbsp;&nbsp;
  //             <MyComponentWithIconProps
  //               statusImage={ListAltIcon}
  //               color="black"
  //               fontSize="medium"
  //               title="List of Applied Students"
  //               onClick={studentList}
  //             />
  //             &nbsp;&nbsp;
  //           </div>
  //         );
  //       },
  //     },
  //   ];

  onChangeAction = (rowData) => {
    swal(msgConstant.isCompletedMsg, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.event +
            "/update-status?eventId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({ msg: "Status Updated" });
            const { getEventByAcademicId, getAlert } = this.props;
            const { academicYearId } = this.state;
            const ay = localStorage.getItem("acadamicYearId");
            getEventByAcademicId({
              id: ay,
            }).then((response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  toggleFormTableVisibility = () => {
    const { showForm, showTable, showAddCommitteeForm } = this.state;
    this.setState({
      showForm: true,
      showAddCommitteeForm: false,
      showTable: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: false,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: false,
      openSnackbar: true,
    });
  };

  backToForm = () => {
    this.props.resetReducList();
    const ay = localStorage.getItem("acadamicYearId");
    if (ay) {
      this.props
        .getEventByAcademicId({
          id: ay,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    this.setState({
      academicYearId: "",
      showForm: false,
      showAddCommitteeForm: false,
      showTable: true,
      showAppliedStudentsForm: false,
      showStudNoticeForm: false,
      showEmpNoticeForm: false,
      showStudFeedbackForm: false,
      showEmpFeedbackForm: false,
      openSnackbar: true,
      editData: {},
    });
  };

  backTo = () => {
    this.setState({
      showTable: true,
    });
  };

  onFormCancel = () => {
    this.setState({
      showForm: true,
      showAddCommitteeForm: false,
      showTable: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.departments.department,
          { id: "0", name: "All" },
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      editData: {},
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    this.props.getEmployeeById({ employeeId: rowData.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    this.props.resetReducList();

    const ay = localStorage.getItem("acadamicYearId");
    this.setState({
      showLoader: true,
      academicYearId: ay,
    });
    if (ay) {
      this.props
        .getEventByAcademicId({
          id: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL, getAlert } = this.props;
    const ay = localStorage.getItem("acadamicYearId");
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props
          .getEventByAcademicId({
            id: ay,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  // delete event by id

  onDelete = (id) => {
    const ay = localStorage.getItem("acadamicYearId");
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.event + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const { getEventByAcademicId, getAlert } = this.props;
        getEventByAcademicId({
          id: ay,
        }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        getAlert({
          message: "Failed to delete (Students have applied against the event)",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    tableColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.eventList?.event.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["venue"] &&
          currentRow["venue"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["fromDate"] &&
          currentRow["fromDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["toDate"] &&
          currentRow["toDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["time"] &&
          currentRow["time"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  render() {
    const {
      showTable,
      showForm,
      showAddCommitteeForm,
      showAppliedStudentsForm,
      showEmpFeedbackForm,
      showStudFeedbackForm,
      showEmpNoticeForm,
      showStudNoticeForm,
    } = this.state;

    return (
      <>
        {this.state.showLoader && <Loading1 />}

        {showForm && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="List of Events" />
            </Paper>
            <br />
            <AddEvent
              onCancel={this.backToForm}
              editData={this.state.editData}
            />
          </>
        )}

        {showAddCommitteeForm && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="Add Committee" />
            </Paper>
            <br />
            <EventCommitteeForm
              committeeData={this.state.committeeData}
              onCancel={this.backToForm}
            />
          </>
        )}

        {showAppliedStudentsForm && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="Applied List" />
            </Paper>
            <br />
            <AppliedStudentList
              onCancel={this.backToForm}
              eventDataForAppliedStudents={
                this.state.eventDataForAppliedStudents
              }
            />
          </>
        )}

        {showEmpFeedbackForm && (
          <>
            <EmployeeFeedbackDeclare
              onCancel={this.backToForm}
              eventDataForEmpFeedback={this.state.eventDataForEmpFeedback}
            />
          </>
        )}

        {showStudFeedbackForm && (
          <>
            <StudentFeedbackDeclare
              onCancel={this.backToForm}
              eventDataForStudFeedback={this.state.eventDataForStudFeedback}
            />
          </>
        )}

        {showEmpNoticeForm && (
          <>
            <AddNotice
              onCancel={this.backToForm}
              eventDataForEmpNotice={this.state.eventDataForEmpNotice}
            />
          </>
        )}

        {showStudNoticeForm && (
          <>
            <AddStudentNotice
              onCancel={this.backToForm}
              eventDataForStudNotice={this.state.eventDataForStudNotice}
            />
          </>
        )}
        {showTable && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="List of Events" />
            </Paper>
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <SearchEvents
                    onSearch={this.onSearch}
                    showEmpNotice={false}
                    showStudNotice={false}
                    showStudFeedback={false}
                    showEmpFeedback={false}
                    showEmpNoticeDetails={false}
                    showEmpFeedbackDetails={false}
                    showStudFeedbackDetails={false}
                    showStudNoticeDetails={false}
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="right"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <MyComponentWithIconProps
                    statusImage={AddCircleIcon}
                    color="primary"
                    fontSize="large"
                    title="Add Event"
                    onClick={this.onAddButtonClick}
                  />
                </Grid>
              </Grid>
            </Paper>
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid item sx={{ mt: 2 }}>
                <DynamicTable
                  data={this.getFilteredTableData()}
                  tableHead={this.columns}
                  showPegination={true}
                  showHeadDelete={true}
                  rowDelete={this.rowDelete}
                  rowStatus={this.onChangeAction}
                  showAppliedList={true}
                  rowAppliedList={this.AppliedStudentList}
                  showPersonAdd={true}
                  tableData={this.props.eventList?.event}
                  //rowTeacherfeedBack={true}
                  //rowStudentfeedBack={true}
                  // showTeacherFeedback={true}
                  // showStudentFeedback={true}
                  rowAddEmpFeedback={this.AddEmployeeFeedback}
                  rowAddStudFeedback={this.AddStudentFeedback}
                  rowAddEmpNotice={this.AddEmployeeNotice}
                  rowAddStudNotice={this.AddStudentNotice}
                  rowPersonAddDetails={this.addEventCommittee}
                  showHeadEdit={true}
                  rowEdit={this.OnClickEdit}
                />
              </Grid>
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getDepartment,
  getAllEmployees,
  getEmployeeById,
  getEventByAcademicId,
  getEventAppliedStudent,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewingEvent);
