import { Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
import { Loading1 } from "../../../../components/Loading1";
import endpoint from "../../../../config/endpoints";
import * as msgConstant from "../../../../config/messageconstant";
import FeeStructuredHeadTable from "./FeeStructuredHeadTable";
import {
  getFeeStructureHeaderByAY,
  getFeesStructureHeadByHeaderId,
  resetFeesStructure,
} from "./feeStructureHead.slice";
import { apiDelete, apiGet, apiPost } from "../../../../utils/api_service";
import { getFeesHeadDetail } from "../FeesHeadDetails/feesHeadDetails.slice";
import { getFromFees } from "../FromFees/fromFees.slice";
import { showNotification } from "../../../Landing/Landing.slice";
import FeeStructureHead from "./FeeStructureHead";
import FeeStructureHeadCopy from "./FeeStructureHeadCopy";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
};

class FeeStructureHeadIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      showCopyForm:false
    };
  }
  componentDidMount() {
    const { getFeeStructureHeaderByAY, getFromFees, getFeesHeadDetail } =
      this.props;
    const ay = localStorage.getItem("acadamicYearId");
    if (ay) {
      getFeeStructureHeaderByAY({ academicYearId: ay }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }
    getFromFees().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getFeesHeadDetail().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable,showCopyForm } = this.state;
    this.setState( {

      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.feeStructuredList?.feesStructureHeader.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["yearDetailName"] &&
            currentRow["yearDetailName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["academicYearName"] &&
            currentRow["academicYearName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["categoryName"] &&
            currentRow["categoryName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };

  onAddButtonClick = () => {
    this.setState({
      fieldData: {},
      currentOperationMode: OperationMode.Add,
      showCopyForm: false,

    });
    this.props.resetFeesStructure();
    const ay = localStorage.getItem("acadamicYearId");
    if (ay) {
      this.props
        .getFeeStructureHeaderByAY({ academicYearId: ay })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    this.toggleFormTableVisibility();
  };

  rowEdit = (id) => {
    const { getFeesStructureHeadByHeaderId } = this.props;
    getFeesStructureHeadByHeaderId({ feesStructureHeadId: id }).then(
      (response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      }
    );
    this.toggleFormTableVisibility();
  };



  rowCopy = (id) => {
    const { getFeesStructureHeadByHeaderId } = this.props;
    getFeesStructureHeadByHeaderId({ feesStructureHeadId: id }).then(
      (response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      }
    );


    const { showCopyForm, showTable } = this.state;
    this.setState({
      showCopyForm: !showCopyForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  


  rowDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (dataToSave) => {
    this.onSave(dataToSave);
  };

  onFormSaveAndNext = (dataToSave) => {
    this.onSave(dataToSave, false);
  };




  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.feesSturctureHead + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const { getFeeStructureHeaderByAY } = this.props;
        const ay = localStorage.getItem("acadamicYearId");
        if (ay) {
          getFeeStructureHeaderByAY({ academicYearId: ay }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    const { currentOperationMode } = this.state;
    if (currentOperationMode === OperationMode.Add) {
      apiPost({
        url: endpoint.feesSturctureHead,
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.setState({
            isLoading: false,
          });

          this.props.showNotification({
            msg:
              currentOperationMode === OperationMode.Add
                ? "Data saved successfully"
                : "Data updated successfully",
          });
          const { getFeeStructureHeaderByAY } = this.props;
          const ay = localStorage.getItem("acadamicYearId");
          if (ay) {
            getFeeStructureHeaderByAY({ academicYearId: ay }).then(
              (response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              }
            );
          }
          if (isToggle) {
            this.toggleFormTableVisibility();
          }
          this.setState({
            currentOperationMode: "",
            fieldData: {},
          });
        } else {
          this.setState({
            isLoading: false,
          });
          this.props.getAlert({ message: "Failed to Save" });
        }
      });
    } else {
      apiPost({
        url: endpoint.feesSturctureHead + "/edit",
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.setState({
            isLoading: false,
          });

          this.props.showNotification({
            msg:
              currentOperationMode === OperationMode.Add
                ? "Data saved successfully"
                : "Data updated successfully",
          });
          const { getFeeStructureHeaderByAY } = this.props;
          const ay = localStorage.getItem("acadamicYearId");
          if (ay) {
            getFeeStructureHeaderByAY({ academicYearId: ay }).then(
              (response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              }
            );
          }
          if (isToggle) {
            this.toggleFormTableVisibility();
            this.setState({
              showCopyForm: false,
              showTable: true,
              // openSnackbar: true,
            });
          }
          this.setState({
            currentOperationMode: "",
            fieldData: {},
          });
        } else {
          this.setState({
            isLoading: false,
          });
          this.props.getAlert({ message: "Failed to Save" });
        }
      });
    }
  };

  // 

  onSaveCopy = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    const { currentOperationMode } = this.state;
    if (currentOperationMode === OperationMode.Add) {
      apiPost({
        url: endpoint.feesSturctureHead,
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.setState({
            isLoading: false,
          });

          this.props.showNotification({
            msg: "Data saved successfully"
          });
          const { getFeeStructureHeaderByAY } = this.props;
          const ay = localStorage.getItem("acadamicYearId");
          if (ay) {
            getFeeStructureHeaderByAY({ academicYearId: ay }).then(
              (response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              }
            );
          }
          if (isToggle) {
            this.setState({
              showCopyForm: false,
              showTable: true,
            });
          }
          this.setState({
            currentOperationMode: "",
            fieldData: {},
          });
        } else {
          this.setState({
            isLoading: false,
          });
          this.props.getAlert({ message: "Failed to Save" });
        }
      });
    } else {
      apiPost({
        url: endpoint.feesSturctureHead + "/edit",
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.setState({
            isLoading: false,
          });

          this.props.showNotification({
            msg: "Data saved successfully"
          });
          const { getFeeStructureHeaderByAY } = this.props;
          const ay = localStorage.getItem("acadamicYearId");
          if (ay) {
            getFeeStructureHeaderByAY({ academicYearId: ay }).then(
              (response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              }
            );
          }
          if (isToggle) {
        
            this.setState({
              showCopyForm: false,
              showTable: true,
              
            });
          }
          this.setState({
            currentOperationMode: "",
            fieldData: {},
          });
        } else {
          this.setState({
            isLoading: false,
          });
          this.props.getAlert({ message: "Failed to Save" });
        }
      });
    }
  };


  // 




  onFormCancelCopy = () => {
    const { showCopyForm, showTable } = this.state;
    this.setState({
      showCopyForm: !showCopyForm,
      showTable: true,
      // openSnackbar: true,
    });
  };


  onChangeStatus = (id, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = status === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.feesSturctureHead +
            "/change-fees-structure-active-status?id=" +
            id +
            "&activeStatus=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
            const { getFeeStructureHeaderByAY } = this.props;
            const ay = localStorage.getItem("acadamicYearId");
            if (ay) {
              getFeeStructureHeaderByAY({ academicYearId: ay }).then(
                (response) => {
                  if (!response) {
                    this.props.getAlert({ message: "Something went wrong" });
                  }
                }
              );
            }
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };
  render() {
    const { showLoader = true, feeStructuredList } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      isLoading,
      showCopyForm
    } = this.state;
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={tableColumnsToFilter}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={"Fees Header"}
          showPdfDownload={true}
          showExcelDownload={true}
          showAddButton={true}
          pdfFileName={"Fees Header"}
          excelFileName={"Fees Header"}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />

        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <FeeStructureHead
                onCancel={this.onFormCancel}
                onSave={this.onFormSave}
                saveAndNext={this.onFormSaveAndNext}
              />
              <br />
            </>
          )}


          {showCopyForm && ( <>
            <FeeStructureHeadCopy
                onCancel={this.onFormCancelCopy}
                onSave={this.onSaveCopy}
                saveAndNext={this.onFormSaveAndNext}
            />
          </>)
          }


          {showTable && (
            <FeeStructuredHeadTable
              data={this.getFilteredTableData()}
              rowEdit={this.rowEdit}
              rowCopy={this.rowCopy}
              rowDelete={this.rowDelete}
              rowStatus={this.onChangeStatus}
              fromFeesList={this.props.fromFeesList.fromFees}
              feeHeadList={this.props.feeHeadList.feesHeadDetail}
              yearDetailsList={this.props.yearDetailsList.class}
            />
          )}
        </Paper>
        {isLoading && <Loading1 />}
        {!feeStructuredList.isFeesStructureHeaderFetch && <Loading1 />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  feeStructuredList: state.feesStructureHead,
  fromFeesList: state.fromFees,
  feeHeadList: state.feesHeadDetail,
  yearDetailsList: state.class,
});
const mapDispatchToProps = {
  getAlert,
  resetFeesStructure,
  showNotification,
  getFeesStructureHeadByHeaderId,
  getFromFees,
  getFeesHeadDetail,
  getFeeStructureHeaderByAY,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeeStructureHeadIndex);
