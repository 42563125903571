import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../../config/messageconstant";
import { getQualification } from "../../QualificationMaster/qualification.slice";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../../components/Comman/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const data = [];

class QualificationCriteria extends React.Component {
  // eslint-disable-next-line no-useless-constructor

  state = {
    id: 0,
    index: 0,
    qualification: "",
    percentage: "",
    elegibilityCriteria: "",
    listErrorMsg: false,
    formErrors: [],
    qualiList: [],
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "qualification",
      title: "Qualification",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "45%",
    },
    {
      name: "percentage",
      title: "Percentage",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "15%",
    },
    {
      name: "elegibilityCriteria",
      title: "Elegibility Criteria",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "15%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "15%",
    },
  ];

  componentDidMount() {
    const { getQualification, row, qualificationList } = this.props;
    getQualification().then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.qualificationList?.qualification !==
      nextProps.qualificationList?.qualification
    ) {
      let newList = [];
      if (this.props.row.length != 0) {
        this.props.qualificationList?.qualification.map((rowData) => {
          const filterList = this.props.row.filter(
            (filterData) => filterData.qualificationId == rowData.id
          );
          if (filterList.length == 0) {
            newList.push(rowData);
          }
        });
        this.setState({
          qualiList: newList,
        });
        console.log(newList);
      } else {
        this.setState({
          qualiList: nextProps.qualificationList.qualification,
        });
      }
    }
  }

  onClickDelete = (params) => {
    let viewRow = this.props.qualificationList?.qualification.filter(
      (row) => row.id === params.qualificationId
    );

    console.log(viewRow);
    if (viewRow.length != 0) {
      this.setState({
        qualiList: [viewRow[0], ...this.state.qualiList],
      });
    }
    this.props.rowDelete(params.index);
  };
  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.props.row);
    if (this.props.row.length !== 0) {
      this.setState({ listErrorMsg: false });
      this.props.qualiData();
    } else {
      this.setState({ listErrorMsg: true });
    }
  };
  saveHandler = (e) => {
    e.preventDefault();
    const {
      id,
      index,
      qualification,
      percentage,
      elegibilityCriteria,
      qualiList,
    } = this.state;

    // console.log(viewRow);

    if (this.handleFormValidation()) {
      let viewRow = this.props.qualificationList?.qualification.filter(
        (row) => row.id === qualification
      );

      let viewRow1 = qualiList.filter((row) => row.id != qualification);

      console.log(viewRow1);
      this.setState({
        qualiList: viewRow1,
      });
      let docs = {
        index: index,
        qualification: viewRow[0].name,
        qualificationId: qualification,
        percentage: percentage,
        elegibilityCriteria: elegibilityCriteria,
      };
      if (id !== 0) {
        data = {
          ...data,
          id: id,
        };
      }
      this.props.rowData(docs);
      this.setState({
        id: 0,
        qualification: "",
        percentage: "",
        index: 0,
        elegibilityCriteria: "",
      });
    }
  };

  editHandler = (params) => {
    console.log(params);
    const { index, qualification, percentage, elegibilityCriteria } =
      this.state;

    this.setState({
      index: params.index,
      qualification: params.qualificationId,
      percentage: params.percentage,
      qualificationId: params.qualificationId,
      elegibilityCriteria: params.elegibilityCriteria,
    });
    let viewRow = this.props.qualificationList?.qualification.filter(
      (row) => row.id === params.qualificationId
    );

    console.log(viewRow);
    if (viewRow.length != 0) {
      this.setState({
        qualiList: [viewRow[0], ...this.state.qualiList],
      });
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { qualification, percentage, elegibilityCriteria } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (qualification.toString().trim() === "" || qualification === null) {
      formIsValid = false;
      formErrors["qualificationError"] = myConstClass.qualificationMsg;
    }

    if (percentage.toString().trim() === "") {
      formIsValid = false;
      formErrors["percentageError"] = myConstClass.percentageMsg;
    }

    if (elegibilityCriteria.toString().trim() === "") {
      formIsValid = false;
      formErrors["eligibilityCriteriaError"] =
        myConstClass.elegibilityCriteriaMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  render() {
    const { qualificationList, row, onCancel } = this.props;
    const {
      qualification,
      percentage,
      listErrorMsg,
      elegibilityCriteria,
      qualiList,
    } = this.state;
    const { percentageError, qualificationError, eligibilityCriteriaError } =
      this.state.formErrors;

    // const auto = ["a"];
    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <Grid sx={{ marginBottom: 3 }} item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 20 }}
              label="Qualification Criteria  "
            />
          </Grid>
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={3.6}
              lg={3.6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Qualification"
                />
              </Grid>

              <Grid sx={{ marginTop: -1 }} item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={qualification}
                  name="qualification"
                  options={qualiList}
                  onChange={this.ChangeHandlerSearch}
                  isError={qualificationError ? true : false}
                  errorText={qualificationError ? qualificationError : " "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={3.6}
              lg={3.6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Marks / Percentage Criteria"
                />
              </Grid>

              <Grid sx={{ mb: 1 }} item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="percentage"
                  size="small"
                  value={percentage}
                  onChange={this.changeHandler}
                  fullWidth
                  error={percentageError ? true : false}
                  helperText={percentageError ? percentageError : " "}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={3.6}
              lg={3.6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Elegibility Criteria"
                />
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={InfoOutlinedIcon}
                  sx={{ m: -1 }}
                  color="primary"
                  title="If the given qualification has same 'Eligibility Criteria' then the student is eligible for the qualification criteria"
                  fontSize="small"
                />
              </Grid>

              <Grid sx={{ mb: 1 }} item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="elegibilityCriteria"
                  size="small"
                  value={elegibilityCriteria}
                  onChange={this.changeHandler}
                  fullWidth
                  error={eligibilityCriteriaError ? true : false}
                  helperText={
                    eligibilityCriteriaError ? eligibilityCriteriaError : " "
                  }
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={1.2}
              lg={1.2}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" "
                />
              </Grid>
              <Grid sx={{ mb: 4.5 }} item xs={12} sm={12} md={12}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Add"
                  fullWidth={true}
                  onClick={this.saveHandler}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* </form> */}

          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="Selected Qualification Criteria "
              />
            </Grid>
            <br />
            {listErrorMsg && (
              <FormHelperText error>
                {"Add atleast 1 qualification"}
              </FormHelperText>
            )}
            {console.log(row)}
            <DynamicTable
              data={row}
              tableHead={this.columns}
              showPegination={false}
              rowDelete={this.onClickDelete}
              showHeadDelete={true}
              rowEdit={this.editHandler}
              showHeadEdit={true}
            />

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              m={2}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              {" "}
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={2}
                lg={2}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                  onClick={this.submitHandler}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  qualificationList: state.qualification,
});
const mapDispatchToProps = {
  getQualification,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualificationCriteria);
