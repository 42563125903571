import {
  FormHelperText,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../../components/Loading1";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
import { showNotification } from "../../../Landing/Landing.slice";
import CheckBoxSelectionTable from "../../../../components/Comman/RT/CheckBoxSelectionTable";
import AluminiProfile from "../../AluminiProfile/index";
import { getAlumini, getAluminiById } from "./alumini.slice";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
import SearchBar from "../../../../components/Search";
const ExamAppliedStudList = ({
  getAlumini,
  getAluminiById,
  examDeclareList,
  showNotification,
  alumini,
  getAlert,
}) => {
  let navigate = useNavigate();
  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      align: "center",
      alignCell: "center",
      minWidth: "10%",
      canSearch: true,
    },
    {
      // name: "name",
      name: "firstName",
      title: "Alumnae Name",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
      alignCell: "center",
      minWidth: "20%",
      canSearch: true,
        keyName:"firstName,lastName,fatherName,motherName",
      isFormat:true,
      type:0
    },
    {
      name: "course",
      title: "Courses",
      positionCenter: true,
      alignCell: "center",
      minWidth: "15%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "alumniEmail",
      title: "Email",
      minWidth: "30%",
      positionCenter: true,
      showInscreen: true,
      alignCell: "center",
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "alumniMobile",
      title: "Contact No.",
      positionCenter: true,
      minWidth: "15%",
      showInscreen: true,
      alignCell: "center",
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "passingYear",
      title: "Passing year",
      positionCenter: true,
      minWidth: "25%",
      showInscreen: true,
      alignCell: "center",
      align: "center",
      canSearch: true,
    },

    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCell: "center",
      alignCenter: "right",
    },
  ];
  const [showLoader, setShowLoader] = useState(false);
  const [changeStatus, setChangeStatus] = React.useState("");
  const [changeSearchBy, setChangeSearchBy] = React.useState("");
  const [searchData, setSearchData] = React.useState("");

  const [formErrors, setFormErrors] = React.useState({});
  const [list, setList] = React.useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [status, setStatus] = React.useState([
    { id: "0", name: "Pending" },
    { id: "1", name: "Approved" },
    { id: "2", name: "Blocked" },
  ]);
  const [searchBy, setSearchBy] = React.useState([
    { id: "passYear", name: "Passing Year" },
    { id: "studentName", name: "Alumnae Name" },
  ]);
  const [remark, setRemark] = React.useState("");
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  useEffect(() => {
    getAlumini().then((resp) => {
      if (!resp) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);
  const checkSearchValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (changeSearchBy === "" || changeSearchBy === null) {
      formIsValid = false;
      formErrors["changeSearchByError"] = "*Select Search By";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (changeStatus === "" || changeStatus === null) {
      formIsValid = false;
      formErrors["changesStatusError"] = "*Select Status";
    }

    if (list.length === 0) {
      formIsValid = false;
      formErrors["emptyListError"] = "*Please select atleast one alumna";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const checkValidationTable = () => {
    let formErrors = {};
    let formIsValid = true;
    if (list.length === 0) {
      formIsValid = false;
      formErrors["emptyListError"] = "*Please select Atleast One Student";
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name === "changeSearchBy") {
        setChangeSearchBy(newValue);
      } else {
        setChangeStatus(newValue);
      }
    } else {
      setChangeStatus("");
      setChangeSearchBy("");
    }
  };

  const getRow = (e) => {
    setList(e);
  };
  const searchHandler = () => {
    let newList = list.join();
    console.log(list);
    if (checkSearchValidation()) {
    }
  };

  const submitHandler = () => {
    if (checkValidation()) {
      let newList = list.join();
      console.log(newList);
      console.log(changeStatus);

      if (list.length !== 0) {
        swal({
          title: "Are you sure?",
          text: " Do you want to update status for this alumnae",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((newValue) => {
          if (newValue) {
            apiGet({
              url:
                endpoints.alumniRegister +
                "/update-status?ids=" +
                newList +
                "&status=" +
                changeStatus +
                "&remark=" +
                remark,
            }).then(({ data, success }) => {
              console.log(
                endpoints.alumniRegister +
                  "/update-status?ids=" +
                  newList +
                  "&status=" +
                  changeStatus +
                  "&remark=" +
                  remark
              );
              if ( success )
              {
                    window.location.reload();   
                setChangeStatus("");
                setRemark("");
                setList([]);
                getAlumini().then((resp) => {
                  if (!resp) {
                    getAlert({ message: "Something went wrong" });
                  }
                });
                showNotification({
                  msg: "Status Updated successfully",
                });
              } else {
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
    }
  };

  const rowView = (row) => {
    getAluminiById({
      id: row.id,
    }).then((response) => {
      if (response) {
        setOpenPreview(true);
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  const onSearch = (searchValue) => {
    setSearchData(searchValue);
  };
  const getFilteredTableData = () => {
    const aluminiList = alumini.alumini.filter(
      (filterData) => filterData.status == value
    );
    return aluminiList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["course"] &&
          currentRow["course"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["alumniEmail"] &&
          currentRow["alumniEmail"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["alumniMobile"] &&
          currentRow["alumniMobile"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())) ||
        (currentRow["passingYear"] &&
          currentRow["passingYear"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name == "remark") {
      setRemark(value);
    }
  };
  return (
    <>
      {showLoader && <Loading1 />}

      <LandingScreenHeader
        tableColumnsToFilter={[]}
        screenTitle={"Admin Approve List"}
        showPdfDownload={false}
        // generatePDF={this.generatePDF}
        showExcelDownload={false}
        showAddButton={false}
        onSearch={onSearch}
        showTable={true}
      />
      <br />
      {!openPreview ? (
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handledChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={
                  "Pending " +
                  alumini.alumini.filter(
                    (filterData) => filterData.status === 0
                  ).length
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  "Approved " +
                  alumini.alumini.filter(
                    (filterData) => filterData.status === 1
                  ).length
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  "Blocked " +
                  alumini.alumini.filter(
                    (filterData) => filterData.status === 2
                  ).length
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CheckBoxSelectionTable
              rows={
                getFilteredTableData()
                //   alumini.alumini.filter(
                //   (filterData) => filterData.status === 0
                // )
              }
              columns={columns}
              rowViewData={rowView}
              showView={true}
              selection={list}
              isActionColActive={true}
              onSelectionChange={getRow}
            />
            <FormHelperText error>
              {formErrors.emptyListError ? formErrors.emptyListError : " "}
            </FormHelperText>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CheckBoxSelectionTable
              rows={
                getFilteredTableData()
                //   alumini.alumini.filter(
                //   (filterData) => filterData.status === 1
                // )
              }
              columns={columns}
              selection={list}
              rowViewData={rowView}
              showView={true}
              isActionColActive={true}
              onSelectionChange={getRow}
            />
            <FormHelperText error>
              {formErrors.emptyListError ? formErrors.emptyListError : " "}
            </FormHelperText>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CheckBoxSelectionTable
              rows={
                getFilteredTableData()
                //   alumini.alumini.filter(
                //   (filterData) => filterData.status === 2
                // )
              }
              columns={columns}
              selection={list}
              showView={true}
              isActionColActive={true}
              rowViewData={rowView}
              onSelectionChange={getRow}
            />

            <FormHelperText error>
              {formErrors.emptyListError ? formErrors.emptyListError : " "}
            </FormHelperText>
          </TabPanel>

          <Grid item sm={12} xs={12} md={12} lg={12} container>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Update Status"
                />
              </Grid>
              <Grid sx={{ marginLeft: 3 }} item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={changeStatus}
                  name={"changeStatus"}
                  fullWidth
                  options={status.filter(
                    (filterData) => filterData.id != value
                  )}
                  onChange={ChangeHandlerSearch}
                  isError={formErrors.changesStatusError ? true : false}
                  errorText={
                    formErrors.changesStatusError
                      ? formErrors.changesStatusError
                      : " "
                  }
                />
              </Grid>
            </Grid>
            {changeStatus == 2 && (
              <Grid
                item
                sm={12}
                xs={12}
                md={4}
                lg={4}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ marginLeft: 1 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark"
                  />
                </Grid>
                <Grid
                  sx={{ marginLeft: 1, marginTop: -3 }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    onChange={changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              sx={{ marginTop: -3 }}
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=""
                />
              </Grid>
              <Grid sx={{ marginLeft: 1 }} item xs={12} sm={12} md={12}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Submit"
                  onClick={submitHandler}
                />
              </Grid>
            </Grid>
            {changeStatus == 2 ? (
              <Grid item xs={12} sm={3} md={3} />
            ) : (
              <Grid item xs={12} sm={7} md={7} />
            )}
          </Grid>
        </Paper>
      ) : (
        <AluminiProfile
          GoBack={() => {
            setOpenPreview(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  alumini: state.alumini,
});

const mapDispatchToProps = {
  getAlert,
  getAlumini,
  showNotification,
  getAluminiById,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExamAppliedStudList);
