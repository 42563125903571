import { Grid } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import "../../CSS/View.css";
import {viewMultipleFile} from '../UploadFile/file.slice'
const Step4 = ({ fileData,student,viewMultipleFile }) => {
  const [profile, setProfile] = React.useState("");
  const [signature, setSignature] = React.useState("");

  React.useEffect(() => {
    if( student.applicant.profilePic!==null&& student.applicant.profilePic!==''){
      let profilePic = {
        fileName: student.applicant.profilePic,
        name: "profilePic",
      };
      viewMultipleFile({
        file: profilePic,
      }).then((response)=>{
        if(response){
          setProfile(response.fileName);
        }
      });
    }
    
    if( student.applicant.signature!==null&& student.applicant.signature!==''){
      let sign = {
        fileName: student.applicant.signature,
        name: "signature",
      };
      viewMultipleFile({
        file: sign,
      }).then((response)=>{
        if(response){
          setSignature(response.fileName);
        }
      });
    }
    
   

  }, []);
  return (
    
    <>
      <Grid container justifyContent="space-evenly" direction="row" spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 20 }}
            label="Photo"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 20 }}
            label="Signature"
          />
        </Grid>

        <Grid item xs={12} sm={4} md={6}></Grid>
        <Grid item xs={12} sm={4} md={3}>
          
          <img src={profile} style={{ height: "100px", width: "100px" }} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <img src={signature} style={{ height: "100px", width: "100px" }} />
        </Grid>
        <Grid item xs={12} sm={4} md={6}></Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  fileData: state.file,
  student: state.studentList,
});

const mapDispatchToProps = {
  viewMultipleFile
};

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
