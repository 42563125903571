import endpoint from "../config/endpoints";

export const ExamFormDeclarationJson = {
    apiBaseURL: endpoint.examFormDeclare,
    screenTitle: "Exam Form Declaration",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    fieldMetaForTable: [
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "fromDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            isMandatory: true,
            // onSubmit: "compare",
            // onSubmitParameter: "toDate-l"
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "toDate",
            isMAxDate: false,
            // maxDate: new Date(),
            isMinDate: false,
            // minDate: new Date(),
            isMandatory: true,
            // onSubmit: "compare",
            // onSubmitParameter: "fromDate-g"
        },
        // {
        //     label: "Division",
        //     controlType: "autocomplete",
        //     placeHolder: "",
        //     md: 3.6,
        //     lg: 3.6,
        //     sm: 3.6,
        //     xs: 12,
        //     masterName: "divisionMaster",
        //     getListFrom: 'yearDetail',
        //     dataKey: "division",
        //     isMandatory: true,
        // },
    ],
    fieldMeta: [
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearMaster",
            getListId: 'yearDetail',
            dataKey: "yearDetail",
            isMandatory: true,
        },
        {
            label: "Semester Details",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "semisterMaster",
            dataKey: "semisterDetail",
            getListId: 'semister',
            isMandatory: true,
        },
        // {
        //     label: "Division",
        //     controlType: "autocomplete",
        //     placeHolder: "",
        //     md: 6,
        //     lg: 6,
        //     sm: 6,
        //     xs: 12,
        //     masterName: "divisionMaster",
        //     getListId: 'division',
        //     dataKey: "division",
        //     isMandatory: true,
        // },
        {
            label: "Exam Title",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "examTitle",
            isMandatory: true
        },
        {
            label: "Exam Fee Structure",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "examFeeStructureMaster",
            // getListId: 'examFeesStructure',
            dataKey: "examFeesStructure",
            isMandatory: true,
        },
        {
            label: "Start Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "startDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            "isMandatory": true
        },
        {
            label: "End Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "endDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            "isMandatory": true
        },
        {
            label: "Show Label",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "LevelMaster",
            dataKey: "showLable",
            isRootLevelKey: true,
            isMandatory: true
        },
        {
            label: "",
            controlType: "hideTextfield",
            placeHolder: "",
            masterName: "LevelMaster",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "labelName",
            tableDataKey: 'showLable',
            open: '1',
            error: 'Label Name is required'
        },
    ],
};






