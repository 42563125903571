import { connect } from "react-redux";
import SubjectScheduleForm from "./TeacherViewForm";
import TreeView from "../../../components/TreeView";
import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { getSyllabus } from "../teachingPlan.slice";
const DisableElevation = ({
  stateData,
  teachingPlanList,
  getSyllabus,
  onCancelForm,
  rowDataForm,
  onCancelView,
  onSaveData,
}) => {
  useEffect(() => {
    console.log("rowdata:" + rowDataForm);
  }, []);
  return (
    <>
      <Paper sx={{ p: 1, pt: 2, borderRadius: 2 }} elevation={0}>
        <SubjectScheduleForm
          stateData={stateData}
          rows={
            Object.keys(teachingPlanList?.syllabus).length !== 0
              ? teachingPlanList?.syllabus.details
              : []
          }
          onCancelForm={onCancelForm}
          rowDataForm={rowDataForm}
          onCancelView={onCancelView}
          onSaveData={onSaveData}
        />
        {/* <TreeView/> */}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  teachingPlanList: state.teachingPlan,
});
const mapDispatchToProps = {
  getSyllabus,
};
export default connect(mapStateToProps, mapDispatchToProps)(DisableElevation);
