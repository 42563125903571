import { Grid, TextField } from "@mui/material";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../Alert/alert.slice";
import {
  onlyNumber,
  validEmail,
} from "../../../components/Comman/Util/Validations";
import Navbar from "../../../components/Navbar";
import Header from "../../../components/NewHeader";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import * as myConstClass from "../../../config/messageconstant";

let docs;
class AlumniRegisterForm extends React.Component {
  state = {
    id: 0,
    contact: "",
    dob: "",
    newPassword: "",
    confirmPassword: "",
    enterOtp: "",
    formErrors: [],
  };

  componentDidMount() {}

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changeFromDate = (date) => {
    this.setState({
      dob: date,
    });
  };

  generateOtp = () => {
    const { contact } = this.state;
    this.setState({
      enterOtp: "",
    });
    if (this.handleFormValidation()) {
      this.props.saveMobile(contact);
    }
  };

  validateOtp = () => {
    const { enterOtp, contact } = this.state;
    if (this.handleFormValidationOtp()) {
      if (this.state.enterOtp !== null) {
        this.props.submitOtp(enterOtp);
      }
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { newPassword } = this.state;
    if (this.passwordFormValidation()) {
      console.log("Befor OnSave");
      this.props.OnSave(newPassword);
    }
  };

  handleFormValidation(name) {
    const { contact, dob } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (contact === "") {
      formIsValid = false;
      formErrors["usernameErr"] = myConstClass.userNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["enterOtpErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  passwordFormValidation() {
    const { newPassword, confirmPassword } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (confirmPassword != newPassword) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }

    if (newPassword === "") {
      formIsValid = false;
      formErrors["newPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  render() {
    const { contact, newPassword, confirmPassword, enterOtp } = this.state;

    const { enterOtpErr, usernameErr, newPasswordErr, confirmPasswordErr } =
      this.state.formErrors;

    const { onCancel, alumniPasswordData } = this.props;
    return (
      <>
        <div align="center">
          {/* <Header/> */}
          <Header />
          <Navbar />
          <div className="new_content">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                  <div className="lgn_bx" style={{ width: "max-content" }}>
                    <h3 className="lgn_title">Forgot Password</h3>

                    <div className="lgn_container">
                      <div className="user_lgn">
                        <div className="user_label">*Username</div>
                        <div className="user_field">
                          <TextField
                            color="primary"
                            name="contact"
                            size="small"
                            value={contact}
                            fullWidth
                            onChange={this.changedHandler}
                            error={usernameErr ? true : false}
                            helperText={usernameErr ? usernameErr : " "}
                            disabled={alumniPasswordData.isFetchGenerateMessage}
                          />
                        </div>
                      </div>

                      {!alumniPasswordData.isFetchGenerateMessage &&
                        alumniPasswordData.generateMessage !==
                          "Otp has been send successfully.." && (
                          <>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Send OTP
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                      {alumniPasswordData.isFetchGenerateMessage &&
                        alumniPasswordData.generateMessage.message ===
                          "Otp has been send successfully.." && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">*Enter OTP</div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="enterOtp"
                                  size="small"
                                  value={enterOtp}
                                  onChange={this.changedHandler}
                                  error={enterOtpErr ? true : false}
                                  helperText={enterOtpErr ? enterOtpErr : " "}
                                  disabled={
                                    alumniPasswordData.isFetchValidateMessage
                                  }
                                />
                              </div>
                            </div>

                            {!alumniPasswordData.isFetchValidateMessage &&
                              alumniPasswordData.validateMessage !==
                                "Otp verified.." && (
                                <>
                                  <div
                                    style={{
                                      display: "block",
                                      padding: " 0 10px 15px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="register_btn sendOtp"
                                      onClick={this.validateOtp}
                                    >
                                      Submit
                                    </button>
                                    <button
                                      type="button"
                                      className="register_btn sendOtp"
                                      onClick={this.generateOtp}
                                    >
                                      Resend
                                    </button>
                                  </div>
                                </>
                              )}
                            {alumniPasswordData.isFetchValidateMessage &&
                              alumniPasswordData.validateMessage.message ===
                                "Otp verified.." && (
                                <>
                                  <div className="user_lgn">
                                    <div className="user_label">
                                      *New Password
                                    </div>
                                    <div className="user_field">
                                      <TextField
                                        color="primary"
                                        name="newPassword"
                                        size="small"
                                        type="password"
                                        value={newPassword}
                                        onChange={this.changedHandler}
                                        onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                        onCopy={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                        fullWidth
                                        error={newPasswordErr ? true : false}
                                        helperText={
                                          newPasswordErr ? newPasswordErr : " "
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="user_lgn">
                                    <div className="user_label">
                                      *Confirm Password
                                    </div>
                                    <div className="user_field">
                                      <TextField
                                        color="primary"
                                        name="confirmPassword"
                                        size="small"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={this.changedHandler}
                                        onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                        onCopy={(e) => {
                                          e.preventDefault();
                                          return false;
                                        }}
                                        fullWidth
                                        error={
                                          confirmPasswordErr ? true : false
                                        }
                                        helperText={
                                          confirmPasswordErr
                                            ? confirmPasswordErr
                                            : " "
                                        }
                                      />
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="register_btn sendOtp"
                                    onClick={this.submitHandler}
                                  >
                                    Save
                                  </button>

                                  <button
                                    type="button"
                                    className="register_btn sendOtp"
                                    onClick={onCancel}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                          </>
                        )}
                    </div>
                  </div>
                </Grid>

                <Grid item md={3}></Grid>
              </Grid>
              {/* onClick={onApiCalled} */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  alumniPasswordData: state.alumniPassword,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AlumniRegisterForm);
