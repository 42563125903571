import { Grid, TextField } from "@mui/material";
import moment from "moment";
import * as React from "react";
// import PasswordStrengthBar from "react-password-strength-bar";
import { connect } from "react-redux";
import swal from "sweetalert";
import NotificationToast from "../../components/Comman/NotificationToast";
import {
  onlyNumber,
  validPassword,
} from "../../components/Comman/Util/Validations";
import { Loading } from "../../components/Loader";
import Header from "../../components/NewHeader";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import {
  closeNotification,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { apiGetWithoutHeader } from "../../utils/api_service";
let docs;
class ForgotPasswordPreLogin extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    userName: "",
    isActive: "1",
    otpCheck: "0",
    dob: "",
    enterOtp: "",
    confirmPassword: "",
    newPassword: "",
    formErrors: [],
    checkedValues: [],
    breadCrum: "",
    loading: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    let date = new Date();
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      dob: date,
    });
  }

  generateOtp = () => {
    const { userName, dob } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");
    if (this.handleFormValidation()) {
      if (this.state.userName != null && this.state.dob != null) {
        this.props.submitOtp(userName, birthdate);
      } else {
        this.setState({
          formErrors: {},
        });
      }
    }
  };
  validateOtp = () => {
    const { userName } = this.state;
    swal({
      title: "Are you sure?",
      text: "Would you want to send link on registered Email Id?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          loading: true,
        });
        apiGetWithoutHeader({
          url:
            endpoint.linkSendOnEmail +
            "/institute?url=" +
            window.location.host +
            "&username=" +
            userName,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              loading: false,
            });
            swal("Link sent successfully").then((value) => {
              if (value) {
                window.location.replace("/login");
              }
            });
            // this.props.showNotification({ msg: "Validate successfully" });
            // // localStorage.removeItem("token");
            // // this.props.logOut();
            // window.location.replace("/login");
          } else {
            this.setState({
              loading: false,
            });
            this.props.showNotification({
              msg: "Failed to validate",
              severity: "error",
            });
          }
        });
      }
    });
  };

  changeFromDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      dob: date,
    });
  };

  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpEmptyErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleFormValidation(name) {
    const { dob, userName, newPassword, confirmPassword, enterOtp } =
      this.state;

    let formErrors = {};
    let formIsValid = true;

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (userName === "") {
      formIsValid = false;
      formErrors["userNameErr"] = myConstClass.userNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  passwordFormValidation(name) {
    const { newPassword, confirmPassword } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (confirmPassword != newPassword) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }

    if (newPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (!validPassword.test(newPassword)) {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.specialPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  closeSnak = () => {
    const { closeNotification } = this.props;
    closeNotification();
  };

  generateOtp = () => {
    const { userName, dob } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");
    if (this.handleFormValidation()) {
      if (this.state.userName != null && this.state.dob != null) {
        this.props.submitOtp(userName, birthdate);
      } else {
        this.setState({
          formErrors: {},
        });
      }
    }
  };
  cancelHandler = () => {
    window.location.replace("/login");
  };

  sendHandler = (e) => {
    e.preventDefault();
    const { id, userName, dob, enterOtp } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");

    docs = {
      id: id === 0 ? 0 : id,
      dob: birthdate,
      userName: userName,
      enterOtp: enterOtp,
    };
    if (this.handleFormValidation()) {
      this.props.OnSubmitOtp(docs);
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { id, userName, newPassword, confirmPassword, dob } = this.state;
    docs = {
      id: id === 0 ? 0 : id,
      userName: userName,
      dob: dob,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    if (this.passwordFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  render() {
    const {
      dob,
      userName,
      enterOtp,
      contactNo,
      newPassword,
      confirmPassword,
      otpCheck,
    } = this.state;

    const {
      dobErr,
      enterPasswordErr,
      newPasswordErr,
      confirmPasswordErr,
      userNameErr,
      incorrectPasswordErr,
      otpEmptyErr,
    } = this.state.formErrors;

    const { landingData, passwordData } = this.props;
    const { loading } = this.state;
    return (
      <>
        <NotificationToast
          open={landingData.isShowNotification}
          severity={
            landingData.severity == undefined ? "success" : landingData.severity
          }
          msg={landingData.msg}
          closeSnak={this.closeSnak}
        />
        <div align="center">
          <Header />
          {/* <Navbar /> */}
          <div className="new_content login">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Forgot Password</h3>

                    <div className="lgn_container">
                      <div className="user_lgn">
                        <div className="user_label">
                          PRN Number/ Employee Code/ Alumna Username:
                        </div>
                        <div className="user_field">
                          <TextField
                            placeholder=""
                            color="primary"
                            name="userName"
                            size="small"
                            value={userName}
                            onChange={this.changedHandler}
                            fullWidth
                            error={userNameErr ? true : false}
                            helperText={userNameErr ? userNameErr : " "}
                          />
                        </div>
                      </div>

                      <div className="lgn_btn">
                        <button
                          type="button"
                          className="register_btn sendOtp"
                          onClick={this.validateOtp}
                        >
                          Validate
                        </button>

                        <button
                          type="button"
                          className="register_btn sendOtp"
                          onClick={this.cancelHandler}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </div>
          </div>
        </div>
        {loading && <Loading />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  passwordData: state.userPassword,
  landingData: state.landing,
});

const mapDispatchToProps = { showNotification, closeNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordPreLogin);
