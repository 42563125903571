import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { division } from "../../../../DynamicFormsJson/Division";
import { DivisionMasterColumns } from "../../../../tableColumns/table-columns";
import { getClass } from "../../OnlineModule/Class/class.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { FilterSave } from "../../../../components/FilterSave";

import { getDistinctDivision } from "../../../InternshipDeclaration/InternshipDeclataration.slice";
class Division extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: { yearDetail: this.props.classList?.class },
    };
  }

  componentDidMount() {
    const { getClass } = this.props;
    const year = localStorage.getItem("yearDetailId");
    if (year) {
      this.props.getDistinctDivision({ yearDetail: year }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }

    getClass().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.classList !== nextProps.classList) {
      if (this.props.classList.class !== nextProps.classList.class) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.classList.class.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }
  onSearchData = (data) => {
    if (data.yearDetail) {
      localStorage.setItem("semisterDetailId", "");
      localStorage.setItem("yearDetailId", data.yearDetail);
      localStorage.setItem("divisionId", "");
      localStorage.setItem("divisionIds", "");
      FilterSave();
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    const { internshipDeclarationList } = this.props;

    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={division.screenTitle}
            fieldMeta={division.fieldMeta}
            showPdfDownload={division.showPdfDownload}
            showExcelDownload={division.showExcelDownload}
            pdfFileName={division.pdfFileName}
            excelFileName={division.excelFileName}
            showAddButton={division.showAddButton}
            onSearchData={this.onSearchData}
            tableColumnsToFilter={division.tableColumnsToFilter}
            baseIdColumn={division.baseIdColumn}
            dynamicMasterData={dynamicMasterData}
            tableColumns={DivisionMasterColumns}
            getTableDataById={true}
            getTableData={this.props.getDistinctDivision}
            tableData={internshipDeclarationList.distinctDivision}
            searchList={division.searchList}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={!internshipDeclarationList.isFetchDivision}
            apiBaseURL={division.apiBaseURL}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  classList: state.class,
});
const mapDispatchToProps = {
  getDistinctDivision,
  getClass,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(Division);
