import React from "react";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";

let columns;
let columnExtensions;
export default ({ data, AppliedEventList }) => {
  columnExtensions = [
    { columnName: "index", width: 70 },
    { columnName: "name", width: 450 },
    { columnName: "fromDate", width: 150 },
    { columnName: "toDate", width: 150 },
    { columnName: "action" },
  ];

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "5%",
    },
    {
      name: "name",
      title: "Event Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "fromDate",
      title: "From Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "toDate",
      title: "To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },

    {
      name: "action",
      title: "Event Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  return (
    <div>
      <DynamicTable
        data={data}
        tableHead={columns}
        showPegination={true}
        showApply={true}
        rowView={AppliedEventList}
      />
    </div>
  );
};
