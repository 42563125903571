import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  timeSlot: [],
  isFetch: false,
  programTypeInstituteWise: [],
  isFetchInstituteWise: false,
};
let URL = endpoints.timeSlot;
let URL1 = endpoints.getProgramType;
const timeSlotSlice = createSlice({
  name: "timeSlot",
  initialState,
  reducers: {
    timeSlotSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        timeSlot: row,
        isFetch: true,
      };
    },

    programTypeInstituteWiseSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        programTypeInstituteWise: row,
        isFetchInstituteWise: true,
      };
    },
  },
});

export const { timeSlotSuccess, programTypeInstituteWiseSuccess } =
  timeSlotSlice.actions;

export default timeSlotSlice.reducer;
export const getTimeSlot = () => async (dispatch) => {
  try {
    const success = apiGet({
      url: URL + '?sort={"sortNo": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.slotName,
            slotName: data1.slotName,
            sortNo: data1.sortNo,
            programTypeId: data1.programTypeId,
            fromTime: data1.fromTime,
            toTime: data1.toTime,
            isRecesses: data1.isRecesses,
            isRecessesName: data1.isRecesses === 1 ? "Recess" : "Lecture ",
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(timeSlotSuccess({ row }));
      }
      return success;
    });
    return success;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getProgramTypeInstituteWise = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        let row = [];
        data.data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(programTypeInstituteWiseSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTimeSlotByProgramType =
  ({ programTypeId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL + "/by-program-type?programTypeId=" + programTypeId,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;

          const row = data.data.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.slotName,
              slotName: data1.slotName,
              programTypeId: data1.programTypeId,
              sortNo: data1.sortNo,
              fromTime: data1.fromTime,
              toTime: data1.toTime,
              isRecesses: data1.isRecesses,
              isRecessesName: data1.isRecesses === 1 ? "Recess" : "Lecture ",
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(timeSlotSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

// /v1/time-slot/by-program-type?programTypeId=
