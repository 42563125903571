import endpoint from "../config/endpoints";
    
    export const AdmissionStatusMasterJson = {
      apiBaseURL: endpoint.admissionStatus,
      screenTitle: "Admission Status",
      showAddButton: true,
      showPdfDownload: true,
      pdfFileName: "Admission Status",
      showExcelDownload: true,
      excelFileName: "Admission Status",
      tableColumnsToFilter: [
        {
          columnDisplayName: "Name",
          columnKeyName: "name",
          isChecked: true,
        },
        {
          columnDisplayName: "Status",
          columnKeyName: "isActive",
          isChecked: true,
        }
      ],

      fieldMeta: [
        {
          label: "Name",
          controlType: "textfield",
          placeHolder: "",
          md: 6,
          lg: 6,
          sm: 6,
          xs: 12,
          dataKey: "name",
          isMandatory: true,
        },
      ],
    };