import React, { Component } from "react";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
import swal from "sweetalert";
import { Grid, Paper } from "@mui/material";
import SubjectInfoTable from "./SubjectInfoTable";
import { FilterSave } from "../../../../components/FilterSave";
import { getScheme } from "../Scheme/scheme.slice";
import { getSchemeHead } from "../SchemeHead/schemeHead.slice";
import { apiGet, apiDelete, apiPost } from "../../../../utils/api_service";
import * as msgConstant from "../../../../config/messageconstant";
import { showNotification } from "../../../Landing/Landing.slice";
import { connect } from "react-redux";
import { Loading1 } from "../../../../components/Loading1";
import SubjectInfo from "./SubjectInfo";
import endpoint from "../../../../config/endpoints";
import {
  getSubjectInfoByYearDetailNew,
  getSubjectInfoByYearDetail,
  getSubjectInfoById,
  resetSubjectInfo,
} from "./subjectInfo.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
import RTHeaderDetailTable from "../../../../components/Comman/RT/RTHeaderDetailTable";
import { getClass } from "../../OnlineModule/Class/class.slice";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import AutoComplete from "../../../../components/Comman/AutoComplete";
import SubjectDetails from "./SubjectDetails";
import { getSemesterDetailsByYearAndAcademic } from "../../../RollNoList/ViewOfRollList/rollno.slice";
import { getPattern } from "../Pattern/pattern.slice";
// import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    tableColumnsToFilter: this.props.tableColumnsToFilter,
    fieldData: {},
    showTable: true,
    showForm: false,
    showSubjectDetails: false,
    currentOperationMode: "",
    searchValue: "",
    isLoading: false,
    yearDetail: "",
    subjectData: "",
    semisterDetail: "",
    pattern: ""
  };
  componentDidMount() {
    const yearDetailId = localStorage.getItem("yearDetailId");
    if (yearDetailId) {
      this.setState({
        yearDetail: yearDetailId,
      });
      const { getSubjectInfoByYearDetail } = this.props;
      getSubjectInfoByYearDetail({ yearDetail: yearDetailId }).then(
        (response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        }
      );
    }
    const { getClass, getScheme, getSchemeHead, getPattern } = this.props;


    getPattern().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    getScheme().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    getSchemeHead().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getClass().then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });
  }
  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    this.setState({
      fieldData: {},
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowEdit = (id) => {
    const { getSubjectInfoById } = this.props;

    getSubjectInfoById({ subjectId: id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.toggleFormTableVisibility();
      }
    });
  };

  showSubDetails = (rowData) => {
    this.setState({
      showSubjectDetails: true,
      showForm: false,
      showTable: false,
      subjectData: rowData,
    });
  };

  rowDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (dataToSave) => {
    this.onSave(dataToSave);
  };
  onFormSaveAndNext = (dataToSave) => {
    this.onSave(dataToSave, false);
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: endpoint.subjectInfo,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : "Data updated successfully",
        });
        this.props.resetSubjectInfo();
        const { getSubjectInfoByYearDetail } = this.props;
        getSubjectInfoByYearDetail({ yearDetail: this.state.yearDetail }).then(
          (response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          }
        );
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.setState({
          isLoading: false,
        });
        this.props.getAlert({ message: "Failed to Save" });
      }
    });
  };

  onDelete = (id) => {
    const { getSubjectInfoByYearDetail ,getSubjectInfoByYearDetailNew} = this.props;
    const { yearDetail,semisterDetail,pattern } = this.state;
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.subjectInfo + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.setState({
          currentOperationMode: "",
        });
        this.props.resetSubjectInfo();
        const { getSubjectInfoByYearDetail } = this.props;
        // getSubjectInfoByYearDetail({ yearDetail: this.state.yearDetail }).then(
        //   (response) => {
        //     if (!response) {
        //       this.props.getAlert({ message: "Something went wrong" });
        //     }
        //   }
        // );

        getSubjectInfoByYearDetailNew({ yearDetailId: yearDetail,semisterDetailId:semisterDetail,patternId:pattern }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });


      } else {
        this.setState({
          isLoading: false,
        });
        this.props.getAlert({ message: "Failed to Delete" });
      }
    });
  };

  subjectFilteredData = this.props.SubjectInfoList?.subject.filter((item) => {
    const { searchValue } = this.state;
    console.log(searchValue);
    return (
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.code.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.patternName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.subjectTypeName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.semisterName.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.yearDetailName.toLowerCase().includes(searchValue.toLowerCase())
    );
  });

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.subjectInfo +
            "/change-subject-active-status?id=" +
            rowData.id +
            "&activeStatus=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.props.resetSubjectInfo();
            this.setState({
              isLoading: false,
            });
            const { getSubjectInfoByYearDetail } = this.props;
            getSubjectInfoByYearDetail({
              yearDetail: this.state.yearDetail,
            }).then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
            this.props.showNotification({ msg: "Status Updated" });
          }
        });
      }
    });
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  // New Change
  // ChangeHandlerSearch = (name, newValue) => {
  //   const { getSemesterDetailsByYearAndAcademic } =
  //     this.props;
  //   if (newValue !== null) {
  //     if (name == "yearDetail") {
  //       const ay = localStorage.getItem("acadamicYearId");
  //       getSemesterDetailsByYearAndAcademic({
  //         yearId: newValue,
  //         academicId: ay,
  //       }).then((response) => {
  //         if (!response) {
  //           this.props.getAlert({ message: "Something went wrong" });
  //         }
  //       });
  //     }
  //     this.setState({ [name]: newValue });
  //   } else {
  //     this.setState({ [name]: "" });
  //   }
  // };

  // New Change end





  closeSubjectDetails = () => {
    this.setState({
      showForm: false,
      showTable: true,
      showSubjectDetails: false,
    });
  };
  submitHandler = () => {

    const { yearDetail,semisterDetail,pattern } = this.state;
    console.log(yearDetail);
    console.log(semisterDetail);
    console.log(pattern);
    localStorage.setItem("semisterDetailId", "");
    localStorage.setItem("yearDetailId", yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("divisionIds", "");
    FilterSave();
    const { getSubjectInfoByYearDetail ,getSubjectInfoByYearDetailNew} = this.props;
    // getSubjectInfoByYearDetail({ yearDetail: yearDetail }).then((response) => {
    //   if (!response) {
    //     this.props.getAlert({ message: "Something went wrong" });
    //   }
    // });

    getSubjectInfoByYearDetailNew({ yearDetailId: yearDetail,semisterDetailId:semisterDetail,patternId:pattern }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });


  };
  render() {
    const { SubjectInfoList = [], classList, rollNoData, patternList } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      isLoading,
      yearDetail,
      showSubjectDetails,
      semisterDetail,
      pattern
    } = this.state;
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={tableColumnsToFilter}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={"Subject Info"}
          showPdfDownload={true}
          showExcelDownload={true}
          showAddButton={true}
          pdfFileName={"SubjectInfo"}
          excelFileName={"SubjectInfo"}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />

        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <SubjectInfo
                onCancel={this.onFormCancel}
                onSave={this.onFormSave}
                onSaveAndNext={this.onFormSaveAndNext}
              />
              <br />
            </>
          )}
          {showTable && (
            //   <SubjectInfoTable
            //   data={this.subjectFilteredData}
            //   rowEdit={this.rowEdit}
            //   rowDelete={this.rowDelete}
            //   rowStatus={this.onChangeStatus}
            // />
            <>
              <Grid
                sx={{ borderRadius: 2 }}
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2} // Adjust spacing between items
              >
                {/* Year Detail */}
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo className="text-black" style={{ fontSize: 16 }} label="Year Detail" />
                  <AutoComplete
                    keyColName={"id"}
                    value={yearDetail}
                    name={"yearDetail"}
                    options={classList?.class}
                    onChange={this.ChangeHandlerSearch}
                    fullWidth
                  />
                </Grid>

                {/* Semester Details */}
                {/* <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo className="text-black" style={{ fontSize: 16 }} label="*Semester Details" />
                  <AutoComplete
                    keyColName={"id"}
                    value={semisterDetail}
                    name={"semisterDetail"}
                    options={rollNoData?.semisterDetailsList.filter((data) => data.isActive == 1)}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid> */}

                {/* Year of Syllabus Approval */}
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo className="text-black" style={{ fontSize: 16 }} label="Year of Syllabus Approval" />
                  <AutoComplete
                    keyColName={"id"}
                    value={pattern}
                    name={"pattern"}
                    options={patternList?.pattern}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>

                {/* Search Button */}
                <Grid item xs={12} sm={4} md={4} container justifyContent="flex-end">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Search"
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>


              <RTHeaderDetailTable
                data={SubjectInfoList?.subject}
                tableHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: true,
                    minWidth: 100,
                  },
                  {
                    title: "Course Name",
                    name: "name",
                    positionCenter: false,
                    minWidth: 150,
                  },
                  {
                    title: "Course Code",
                    name: "code",
                    positionCenter: false,
                    minWidth: 120,
                  },
                  {
                    title: "Year of Syllabus Approval",
                    name: "patternName",
                    positionCenter: false,
                    minWidth: 150,
                  },
                  {
                    title: "Semester Name",
                    name: "semisterName",
                    positionCenter: false,
                    minWidth: 150,
                  },
                  {
                    title: "Code Type Name",
                    name: "subjectTypeName",
                    positionCenter: false,
                    minWidth: 150,
                  },
                  {
                    title: "Status",
                    name: "isActive",
                    positionCenter: true,
                    minWidth: 100,
                  },
                  {
                    title: "Action",
                    name: "action",
                    positionCenter: false,
                    minWidth: 120,
                  },
                ]}
                tableSubHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: true,
                  },
                  {
                    title: "Scheme Name",
                    name: "schemeName",
                    positionCenter: false,
                  },
                  {
                    title: "Scheme Head Name",
                    name: "schemeHeadName",
                    positionCenter: false,
                  },
                  {
                    title: "Value",
                    name: "value",
                    positionCenter: false,
                  },
                  {
                    title: "Minimum Marks",
                    name: "minMarks",
                    positionCenter: false,
                  },
                ]}
                showSubjectDetails={true}
                rowSubjectDetails={this.showSubDetails}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                rowStatus={this.onChangeStatus}
                tableSubList={"subjectDetails"}
              />
            </>
          )}
          {showSubjectDetails && (
            <SubjectDetails
              subjectData={this.state.subjectData}
              onCancle={this.closeSubjectDetails}
            />
          )}
        </Paper>
        {/* {isLoading && <Loading1 />}
        {!SubjectInfoList.isFetch && <Loading1 />} */}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  SubjectInfoList: state.subjectInfo,
  classList: state.class,
  rollNoData: state.rollNo,
  patternList: state.pattern,
});
const mapDispatchToProps = {
  getAlert,
  getClass,
  showNotification,
  getSubjectInfoByYearDetail,
  getSubjectInfoById,
  resetSubjectInfo,
  getScheme,
  getSchemeHead,
  getSemesterDetailsByYearAndAcademic,
  getPattern,
  getSubjectInfoByYearDetailNew
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
