import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { generateExcel } from "../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../components/Comman/GeneratePDF";
import { LabelCompo } from "../../../components/Comman/Label";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import SearchExternalPlacedStudList from "../../../components/SearchTable";
import {
  getStudentFeedbackDetails,
  getStudentApplyFeedback,
  getStudentFeedbackQuestions,
  resetValues,
} from "./studentFeedbackPortal.slice";
import StudentFeedbackForm from "./studentFeedbackForm";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { StudentApplyFeedbackJson } from "../../../DynamicFormsJson/StudentApplyFeedbackDynamic";

import { ButtonCompo } from "../../../components/Comman/Button";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class FeedbackDetailsPortalIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showAddCommitteeForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      department: "",
      academicYearId: "",
      departmentList: [],
      editData: {},
      committeeData: {},
      eventDataForAppliedStudents: {},
      searchValue: "",
      showLoader: false,
      role: "",
    };
  }

  OnClickApply = (data) => {
    this.props
      .getStudentFeedbackQuestions({ id: data.feedbackHeadId })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showTable: false,
            showForm: true,
            editData: data,
          });
        }
      });
  };

  backToListHandler = () => {
    const {
      getStudentFeedbackDetails,
      getAlert,
      resetValues,
      getStudentApplyFeedback,
    } = this.props;
    resetValues();
    const parentChildId = localStorage.getItem("parentChildId");
    getStudentFeedbackDetails({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      showForm: false,
      showTable: true,
    });
    getStudentApplyFeedback({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "5%",
    },
    {
      name: "feedbackHead",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      width: "80%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,

      width: "10%",
    },
  ];

  column = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Questions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  submitHandler = () => {};

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    let role = localStorage.getItem("role");
    this.setState({
      role: role,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    const { getStudentFeedbackDetails, getStudentApplyFeedback, getAlert } =
      this.props;
    getStudentFeedbackDetails({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    getStudentApplyFeedback({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  backTo = () => {
    this.setState({
      showTable: true,
    });
  };

  cancleHandler = () => {
    this.setState({
      showForm: false,
      showTable: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {};

  // delete event by id

  onDelete = (id) => {};
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studentFeedbackDetailsList?.studentFeedbackDetails.filter(
      (currentRow) => {
        let isValid = false;
        if (
          currentRow["feedbackHead"] &&
          currentRow["feedbackHead"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
          // ||
          // (currentRow["fromDate"] &&
          //   currentRow["fromDate"]
          //     .toString()
          //     .toLowerCase()
          //     .includes(searchValue.toLowerCase())) ||
          // (currentRow["toDate"] &&
          //   currentRow["toDate"]
          //     .toString()
          //     .toLowerCase()
          //     .includes(searchValue.toLowerCase())) ||
          // (currentRow["division"] &&
          //   currentRow["division"]
          //     .toString()
          //     .toLowerCase()
          //     .includes(searchValue.toLowerCase())) ||
          // (currentRow["yearDetailName"] &&
          //   currentRow["yearDetailName"]
          //     .toString()
          //     .toLowerCase()
          //     .includes(searchValue.toLowerCase())) ||
          // (currentRow["isSubjectData"] &&
          //   currentRow["isSubjectData"]
          //     .toString()
          //     .toLowerCase()
          //     .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const { searchButton = false, studentFeedbackDetailsList } = this.props;
    const { showTable, showForm } = this.state;

    console.log(studentFeedbackDetailsList?.studentFeedbackApply);
    return (
      <>
        {showForm && (
          <>
            <StudentFeedbackForm
              editData={this.state.editData}
              onCancel={this.cancleHandler}
              backToList={this.backToListHandler}
            />
          </>
        )}

        {showTable && (
          <>
            {/* <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Feedback Details "
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{ marginTop: 1, marginBottom: 2 }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <SearchExternalPlacedStudList onSearch={this.onSearch} />
              </Grid>

              <Grid item >
                <DynamicTable
                  data={
                    this.getFilteredTableData()
                  }
                  tableHead={this.columns}
                  showPegination={true}
                  rowView={this.OnClickApply}
                  showApply={true}
                />
              </Grid> */}

            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={StudentApplyFeedbackJson.screenTitle}
              fieldMeta={StudentApplyFeedbackJson.fieldMeta}
              buttonCenter={StudentApplyFeedbackJson.buttonCenter}
              showPdfDownload={StudentApplyFeedbackJson.showPdfDownload}
              showExcelDownload={StudentApplyFeedbackJson.showExcelDownload}
              // pdfFileName={StudentApplyFeedbackJson.pdfFileName}
              // excelFileName={StudentApplyFeedbackJson.excelFileName}
              tableColumnsToFilter={
                StudentApplyFeedbackJson.tableColumnsToFilter
              }
              showAddButton={StudentApplyFeedbackJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={this.columns}
              tableData={studentFeedbackDetailsList?.studentFeedbackApply}
              // getListById={this.getListById}
              showPegination={true}
              // showHeadEdit={true}
              // showHeadDelete={true}
              // showAppliedList={true}
              // showPegination={false}
              // pdfDetailsId={this.pdfDetails}
              // rowViewData={this.onClickView}
              // showLinkIcon={true}
              searchButton={searchButton}
              generateDetails={this.generateDetails}
              rowView={this.OnClickApply}
              showApply={true}
              showLoader={this.state.showLoader}
              // rowDelete={this.rowDelete}
              // rowEdit={this.rowEdit}
              // rowStatus={this.onChangeStatus}
              // rowAppliedList={this.onClickApplied}
              // onSearchData={this.onSearchData}
              baseIdColumn={StudentApplyFeedbackJson.baseIdColumn}
              apiBaseURL={StudentApplyFeedbackJson.apiBaseURL}
              isActionColActive={this.state.role === "parent" ? false : true}
            />
          </>
        )}
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentFeedbackDetailsList: state.studentFeedbackDetails,
});
const mapDispatchToProps = {
  getStudentFeedbackDetails,
  getStudentApplyFeedback,
  getStudentFeedbackQuestions,
  getAlert,
  resetValues,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDetailsPortalIndex);
