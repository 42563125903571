import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../../config/messageconstant";
import { getParticipatedEventList } from "./StudentApply.slice";
import SearchApplyEvent from "../../../components/SearchTable";
class ParticipatedEventListTable extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    searchValue: "",
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "5%",
    },
    {
      name: "name",
      title: "Event Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "fromDate",
      title: "From Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "toDate",
      title: "To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },

    {
      name: "eventStatus",
      title: "Event Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  // componentWillMount(){
  //   const ay=localStorage.getItem('acadamicYearId')
  //   if(ay){
  //     this.props.getParticipatedEventList( {
  //       academicId: ay,
  //       yearDetailId:"",
  //     } ).then( ( response ) =>
  //     {
  //       if ( !response )
  //       {
  //         getAlert( { message: "Something went wrong" } );
  //       }
  //     } );
  //   }
  // }

  // onSearch = (value) => {
  //   this.setState({
  //     searchValue: value,
  //   });
  // };

  //  getFilteredTableData = () =>
  // {
  //   const {searchValue} = this.state;
  //   return this.props.studParticipatedList?.participatedEventStatus.filter( ( currentRow ) =>
  //   {
  //     let isValid = false;
  //     if (
  //       (currentRow["name"] &&
  //         currentRow["name"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase())) ||
  //       (currentRow["fromDate"] &&
  //         currentRow["fromDate"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase())) ||
  //       (currentRow["toDate"] &&
  //         currentRow["toDate"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase())) ||
  //       (currentRow["eventStatus"] &&
  //         currentRow["eventStatus"]
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase()))
  //     ) {
  //       isValid = true;
  //       return true;
  //     }
  //     return isValid;
  //   } );
  // };

  render() {
    return (
      <>
        <div>
          <Paper sx={{ borderRadius: 2 }} elevation={0}>
            <>
              {/* <Grid
              sx={{ marginBottom: 2 }}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <SearchApplyEvent
                onSearch={this.onSearch}
              />
            </Grid> */}
              <Grid item xs={12} sm={12} md={12}>
                <DynamicTable
                  data={this.props.data}
                  tableHead={this.columns}
                  showPegination={true}
                  isActionColActive={false}
                />
              </Grid>
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studParticipatedList: state.studentApplyEvent,
  yearDetails: state.internshipDeclaration,
});
const mapDispatchToProps = {
  getParticipatedEventList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipatedEventListTable);
