import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { InstituteReportJson } from "../../DynamicFormsJson/InstituteReport";
import {
  getProgramTypeInstituteWise,
  getProgramTitleByProgTypeId,
} from "../Masters/OnlineModule/ProgramType/programType.slice";
import { getAlert } from "../../CommonActions/alert.slice";

class InstituteReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        programType: this.props.programTypeList?.programTypeInstituteWise,
        programTitle: this.props.programTypeList?.progTitleByProgType,
      },
    };
  }

  componentDidMount() {
    this.props.getProgramTypeInstituteWise().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.programTypeList !== nextProps.programTypeList) {
      if (
        this.props.programTypeList.programTypeInstituteWise !==
        nextProps.programTypeList.programTypeInstituteWise
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programType: nextProps.programTypeList.programTypeInstituteWise,
          },
        });
      }
    }
    if (this.props.programTypeList !== nextProps.programTypeList) {
      if (
        this.props.programTypeList.progTitleByProgType !==
        nextProps.programTypeList.progTitleByProgType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programTitle: nextProps.programTypeList.progTitleByProgType,
          },
        });
      }
    }
  }

  getListById = (data) => {
    if (data.programType) {
      this.props
        .getProgramTitleByProgTypeId({
          progTypeId: data.programType,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  rowData = [
    {
      index: 1,
      reportName: "Institute report program-title wise (D1)",
      filter: "programTitle",
      url: "/api/report/v1/program-title-report?ProgramTitleIds={programTitle}",
    },
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={InstituteReportJson.screenTitle}
          fieldMeta={InstituteReportJson.fieldMeta}
          buttonCenter={InstituteReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={InstituteReportJson.searchButton}
          PDFButton={InstituteReportJson.PDFButton}
          EXCELButton={InstituteReportJson.EXCELButton}
          baseIdColumn={InstituteReportJson.baseIdColumn}
          apiBaseURL={InstituteReportJson.apiBaseURL}
          marginTop={InstituteReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  programTypeList: state.programType,
});
const mapDispatchToProps = {
  getProgramTypeInstituteWise,
  getProgramTitleByProgTypeId,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(InstituteReport);
