export const programMasterColumns = [
  { title: "Sr. No.", name: "index" },
  { title: "Program Type", name: "name", formDataKey: "name", canSearch: true },
  {
    title: "University Name",
    name: "uniName",
    formDataKey: "university",
    tableDataKey: "uniId",
    canSearch: true,
  },
  {
    title: "Name",
    name: "progName",
    formDataKey: "program",
    tableDataKey: "progTypeId",
    canSearch: true,
  },
  { title: "Duration", name: "progDuration", formDataKey: "duration" },
  {
    title: "First Year Program Title",
    name: "feBrName",
    formDataKey: "feBrName",
    canSearch: true,
  },
  { title: "Status", name: "isActive", canSearch: true },
  { name: "action", title: "Action" },
];

export const AwardColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Award Name",
    name: "awardName",
    formDataKey: "awardName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Orgnization",
    name: "organization",
    formDataKey: "organization",
    tableDataKey: "organization",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Award Date",
    name: "awardDate",
    formDataKey: "awardDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Level",
    name: "onLevel",
    formDataKey: "onLevel",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Award Duration",
    name: "awardDuration",
    formDataKey: "awardDuration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Award From Date",
    name: "awardFrom",
    formDataKey: "awardFrom",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Award To Date",
    name: "awardTo",
    formDataKey: "awardTo",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const DivisionMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const ScholarshipTypeMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const AccessRoleColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Remarks",
    name: "empTypeRemarks",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },

  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const CocurricularMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Activity",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Date",
    name: "date",
    formDataKey: "date",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Activity Details",
    name: "activityDetail",
    formDataKey: "activityDetail",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const CourseAttendedColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Duration",
    name: "duration",
    formDataKey: "duration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Activity Details",
    name: "details",
    formDataKey: "details",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const GrievanceOrComplaintColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Details Of Complaint",
    name: "complaintDetail",
    formDataKey: "complaintDetail",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Complaint Date",
    name: "complaintDate",
    formDataKey: "complaintDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Attended By",
    name: "attendedBy",
    formDataKey: "attendedBy",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Attended Date",
    name: "attendedDate",
    formDataKey: "attendedDate",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "status", canSearch: true, showInExcel: true },
  // { name: "action", title: "Action" },
];

export const GrievanceOrComplaintAdminColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "firstName",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    keyName: "firstName,lastName,fatherName,motherName,middleName",
    isFormat: true,
    type: 0,
  },
  {
    title: "Complaint Details",
    name: "complaintDetail",
    formDataKey: "complaintDetail",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Complaint Date",
    name: "complaintDate",
    formDataKey: "complaintDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Attended By",
    name: "attendedBy",
    formDataKey: "attendedBy",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Attended Date",
    name: "attendedDate",
    formDataKey: "attendedDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "statusName",
    formDataKey: "status",
    tableDataKey: "status",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const innovativeTeachingColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Activity",
    name: "name",
    formDataKey: "employeeActivity",
    canSearch: true,
    showInExcel: true,
  },
  // {
  //   title: "Date",
  //   name: "date",
  //   formDataKey: "date",
  //   canSearch: true,
  //   showInExcel: true,
  // },
  {
    title: "Subject",
    name: "subjectIds",
    formDataKey: "subjectIds",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Activity Details",
    name: "activityDetail",
    formDataKey: "activityDetail",
    canSearch: true,
    showInExcel: true,
    readMore: true,
    readMoreData: "activityDetail",
  },
  { name: "action", title: "Action" },
];

export const ReseachJournalPublicationColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Title Of The Paper",
    name: "pressTitle",
    formDataKey: "pressTitle",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Date",
    name: "pressDate",
    formDataKey: "pressDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Journal Name With Volume Name",
    name: "issnIsbn",
    formDataKey: "issnIsbn",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Journal Is On UGC",
    name: "isUgcCare",
    formDataKey: "isUgcCare",
    canSearch: true,
    showInExcel: true,
  },
  // {
  //   title: "Impact Factor",
  //   name: "impactFactor",
  //   formDataKey: "impactFactor",
  //   canSearch: true,
  //   showInExcel: true,
  // },
  // {
  //   title: "Corresponding Author",
  //   name: "isFirstAuthor",
  //   formDataKey: "isFirstAuthor",
  //   canSearch: true,
  //   showInExcel: true,
  // },
  {
    title: "Other Author",
    name: "otherAuthor",
    formDataKey: "otherAuthor",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AttendedSeminarMasterColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Type",
    name: "typeName",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Date",
    name: "pressDate",
    formDataKey: "pressDate",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Details",
    name: "publication",
    formDataKey: "publication",
    canSearch: true,
    showInExcel: true,
  },

  { name: "action", title: "Action" },
];

export const AcademicAchievementsColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Title",
    name: "title",
    formDataKey: "title",
    canSearch: true,
    showInExcel: true,
    width: 200,
  },
  {
    title: "Description",
    name: "description",
    formDataKey: "description",
    canSearch: true,
    showInExcel: true,
    width: 250,
  },
  {
    title: "Date",
    name: "date",
    formDataKey: "date",
    canSearch: true,
    align: "center",
    showInExcel: true,
    width: 120,
  },

  {
    title: "Achievement Type",
    name: "achievementType",
    formDataKey: "achievementType",
    canSearch: true,
    showInExcel: true,
    width: 200,
  },
  { name: "isActive", title: "Action" },
];

export const AlumniVolunteerColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Volunteer For",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Time you can contribute (In hours)",
    name: "time",
    formDataKey: "time",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "status",
    formDataKey: "status",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AlumniAssociationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Nominate for",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Reason for Nomination",
    name: "message",
    formDataKey: "message",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AlumniPaperColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Role",
    name: "interest",
    formDataKey: "interest",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area of expertise",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Academic Experience (In years)",
    name: "message",
    formDataKey: "message",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Industry Experience (In years)",
    name: "remark",
    formDataKey: "remark",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AlumniDonorColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Donor / Sponsor",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Purpose",
    name: "messageData",
    formDataKey: "messageData",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Type of Sponsorship",
    name: "interest",
    formDataKey: "interest",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AlumniResoursePersonColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Role",
    name: "interest",
    formDataKey: "interest",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Area of expertise",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Time you can contribute (In hours)",
    name: "time",
    formDataKey: "time",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AlumniCollaborationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Collaboration Type",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AlumniTeacherColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Highest Academic Qualification",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "UGC SET",
    name: "message",
    formDataKey: "message",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "UGC NET",
    name: "remark",
    formDataKey: "remark",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];
export const AlumniMentorColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Area of expertise",
    name: "expertise",
    formDataKey: "expertise",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Time you can spend (Hours / Days)",
    name: "time",
    formDataKey: "time",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Department you can mentor",
    name: "message",
    formDataKey: "message",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "status",
    formDataKey: "status",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const AlumniInternshipColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Title",
    name: "title",
    formDataKey: "title",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Company name",
    name: "companyName",
    formDataKey: "companyName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Company website",
    name: "companyWebsite",
    formDataKey: "companyWebsite",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Registration Link",
    name: "duration",
    formDataKey: "duration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Location",
    name: "location",
    formDataKey: "location",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const ParticipationDetailsColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Visiting Lecture",
    name: "visitingLecturer",
    formDataKey: "visitingLecturer",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Resource Person",
    name: "resourcePerson",
    formDataKey: "resourcePerson",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Details",
    name: "details",
    formDataKey: "details",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Duration",
    name: "duration",
    formDataKey: "duration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Level",
    name: "level",
    formDataKey: "level",
    canSearch: true,
    showInExcel: true,
  },

  { name: "action", title: "Action" },
];

export const ContributionsColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Name",
    name: "committeeName",
    formDataKey: "committeeName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Committee",
    name: "committeeDesignation",
    formDataKey: "committeeDesignation",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Level",
    name: "level",
    formDataKey: "level",
    canSearch: true,
    showInExcel: true,
  },

  { name: "action", title: "Action" },
];

export const paperPosterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Type",
    name: "pressTitle",
    formDataKey: "pressTitle",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Details of conference",
    name: "publication",
    formDataKey: "publication",
    canSearch: true,
    showInExcel: true,
    readMore: true,
    width: 300,
    readMoreData: "publication",
  },
  {
    title: "Level",
    name: "level",
    formDataKey: "levelData",
    tableDataKey: "level",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const researchProjectColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Title",
    name: "projectTitle",
    formDataKey: "projectTitle",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sponsoring Authority",
    name: "sponsoredBy",
    formDataKey: "sponsoredBy",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Scheme",
    name: "scheme",
    formDataKey: "scheme",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Period",
    name: "duration",
    formDataKey: "duration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Grant",
    name: "sanctionAmount",
    formDataKey: "sanctionAmount",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "status",
    formDataKey: "status",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const FloorColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const UnitTestMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const AdmissionTypeMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const AdmissionStatusMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const ReceiptTypeMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const FormFeesMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const SubjectTypeDetailMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "subjectTypeName",
    tableDataKey: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Minimum Subject",
    name: "minSub",
    formDataKey: "minSub",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Maximum Subject",
    name: "maxSub",
    formDataKey: "maxSub",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Show Name",
    name: "showName",
    formDataKey: "showName",
    canSearch: true,
    showInExcel: true,
  },

  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const FeesHeadDetailsColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Fees Title",
    name: "feesTitleName",
    formDataKey: "feesTitle",
    tableDataKey: "feesTitleId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Fees Type",
    name: "feesTypeName",
    formDataKey: "feesType",
    tableDataKey: "feesTypeId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Receipt Type",
    name: "receiptTypeName",
    formDataKey: "receiptType",
    tableDataKey: "receiptTypeId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Carry Forward",
    name: "isCarryForward",
    formDataKey: "isCarryForward",
    tableDataKey: "isCarryForward",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Fees Collection Preference",
    name: "feesCollectionPref",
    formDataKey: "feesCollectionPref",
    tableDataKey: "feesCollectionPref",
    canSearch: true,
    showInExcel: true,
  },
  // {
  //   title: "Sort Order No",
  //   name: "sortOrderNo",
  //   formDataKey: "sortOrderNo",
  //   tableDataKey: "sortOrderNo",
  //   canSearch: true,
  //   showInExcel: true,
  // },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const examFeesStructureColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Fees Title",
    name: "title",
    formDataKey: "title",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Fees Type",
    name: "type",
    formDataKey: "type",
    tableDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Fixed Fees",
    name: "fixedFees",
    formDataKey: "fixedFees",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Per Subject",
    name: "perSubject",
    formDataKey: "perSubject",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Max Cap Fees",
    name: "maxCapFees",
    formDataKey: "maxCapFees",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const FeeTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const FeesTitleColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const SemisterInfoColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order No",
    name: "sortNo",
    formDataKey: "sortNo",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const PatternColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Marking System",
    name: "markingSystemName",
    tableDataKey: "markingSystem",
    formDataKey: "markingSystem",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const designationColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Designation",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order No",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const SchemeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Short Name",
    name: "shortName",
    formDataKey: "shortName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order No",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const SchemeHeadColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Short Name",
    name: "shortName",
    formDataKey: "shortName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Scheme",
    name: "schemeName",
    formDataKey: "schemeId",
    tableDataKey: "schemeId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Sort Order No",
    name: "sortOrderNo",
    formDataKey: "sortOrderNo",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const DepartmentColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Department",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },

  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const SubjectInfoColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Code",
    name: "code",
    formDataKey: "code",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Short Name",
    name: "shortName",
    formDataKey: "shortName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Year Details",
    name: "yearDetailName",
    formDataKey: "yearDetailId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Semister",
    name: "semisterName",
    formDataKey: "semister",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Pattern",
    name: "patternName",
    formDataKey: "pattern",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Subject Type",
    name: "subjectTypeName",
    formDataKey: "subjectType",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const SemisterDetailsColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Academic Year",
    name: "academicYearName",
    formDataKey: "academicYearId",
    tableDataKey: "academicYearId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Date",
    name: "semisterStartDate",
    formDataKey: "semisterStartDate",
    canSearch: true,
    dataType: "date",
    showInExcel: true,
  },
  {
    title: "To Date",
    name: "semisterEndDate",
    formDataKey: "semisterEndDate",
    canSearch: true,
    dataType: "date",
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const FinancialYearColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    dataType: "date",
    showInExcel: true,
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
    dataType: "date",
  },
  // { title: "Is Locked", name: "isLocked", formDataKey: "isLocked"},
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action" },
];

export const ApplicationListColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Application Name /Id",
    name: "name",
    formDataKey: "name",
    canSearch: true,
  },
  { title: "Status", name: "isActive" },
  { name: "action", title: "Action" },
];

export const EmployeeListColumns = [
  { title: "Sr. No.", name: "index", align: "center" },
  {
    title: "Employee ID",
    name: "employeeId",
    formDataKey: "employeeId",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Employee Name",
    name: "firstName",
    formDataKey: "employeeName",
    canSearch: true,
    showInExcel: true,
    keyName: "firstName,lastName,middleName",
    isFormat: true,
    type: 1,
  },

  {
    title: "Department",
    name: "department",
    formDataKey: "department",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Designation",
    name: "designation",
    formDataKey: "designation",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const InternshipTypeColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Internship Type Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];
export const CompanyMasterColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Address",
    name: "address",
    formDataKey: "address",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "contact person name",
    name: "ContactPersonName",
    formDataKey: "ContactPersonName",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Contact Number",
    name: "contactNo",
    formDataKey: "contactNo",
    showInExcel: true,
    canSearch: true,
  },
  {
    title: "Email ID",
    name: "personEmailId",
    formDataKey: "contactNo",
    showInExcel: true,
    canSearch: true,
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const RoomColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Floor Details",
    name: "floorName",
    formDataKey: "floor",
    tableDataKey: "floor",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Room Capacity",
    name: "roomCapacity",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Exam Capacity",
    name: "examCapacity",
    showInExcel: true,
    align: "center",
  },
  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];
export const TimeSlotColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true },
  {
    title: "Slot Name",
    name: "slotName",
    tableDataKey: "slotName",
    formDataKey: "slotName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Time",
    name: "fromTime",
    formDataKey: "fromTime",
    tableDataKey: "fromTime",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "To Time",
    name: "toTime",
    formDataKey: "toTime",
    tableDataKey: "toTime",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Time Slot Type",
    name: "isRecessesName",
    formDataKey: "isRecesses",
    canSearch: true,
    showInExcel: true,
  },

  { title: "Status", name: "isActive", showInExcel: true },
  { name: "action", title: "Action" },
];

export const activityColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Activity",
    name: "name",
    formDataKey: "name",
    tableDataKey: "name",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Type",
    name: "typeName",
    tableDataKey: "type",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action", canSearch: true },
];

export const seminarWebinarColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Activity",
    name: "employeeActivityName",
    formDataKey: "employeeActivityName",
    tableDataKey: "employeeActivityName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Date",
    name: "pressDate",
    tableDataKey: "pressDate",
    formDataKey: "pressDate",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Activity Details",
    name: "publication",
    tableDataKey: "publication",
    formDataKey: "publication",
    canSearch: true,
    showInExcel: true,
  },

  {
    title: "Number of beneficiaries",
    name: "numberOfBeneficiaries",
    tableDataKey: "numberOfBeneficiaries",
    formDataKey: "numberOfBeneficiaries",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const resourcePersonColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Title",
    name: "pressTitle",
    formDataKey: "pressTitle",
    canSearch: true,
    width: 150,
    showInExcel: true,
  },
  {
    title: "Details",
    name: "publication",
    formDataKey: "publication",
    canSearch: true,
    showInExcel: true,
    readMore: true,
    width: 300,
    readMoreData: "publication",
  },
  {
    title: "Date",
    name: "pressDate",
    formDataKey: "pressDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Location",
    name: "confVenue",
    formDataKey: "confVenue",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Level",
    name: "level",
    formDataKey: "level",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Type",
    name: "type",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  {
    name: "action",
    title: "Action",
    canSearch: true,
    width: 100,
  },
];

export const researchGuidanceColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Type",
    name: "university",
    formDataKey: "university",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Research Center",
    name: "researchCenter",
    formDataKey: "researchCenter",
    canSearch: true,
    showInExcel: true,
  },
  // {
  //   title: "Guide Registration Date",
  //   name: "guideRegDate",
  //   formDataKey: "guideRegDate",
  //   canSearch: true,
  //   showInExcel: true,
  // },
  // {
  //   title: "Valid Upto",
  //   name: "validUpTo",
  //   formDataKey: "validUpTo",
  //   canSearch: true,
  //   showInExcel: true,
  // },
  {
    title: "Guide For",
    name: "guideFor",
    formDataKey: "guideFor",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Doc URL",
    name: "docUrl",
    formDataKey: "docUrl",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Guided As",
    name: "guideAs",
    formDataKey: "guideAs",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Details",
    name: "details",
    formDataKey: "details",
    canSearch: true,
    showInExcel: true,
    readMore: true,
    width: 300,
    readMoreData: "details",
  },
  { name: "action", title: "Action" },
];

export const adminAcademicPanelColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Form Name",
    name: "subModuleName",
    formDataKey: "subModuleName",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const addingQualificationColumns = [
  {
    title: "Sr. No.",
    name: "index",
    showInExcel: true,
    align: "center",
  },
  {
    title: "Course Details",
    name: "details",
    formDataKey: "details",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Duration",
    name: "duration",
    formDataKey: "duration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Status",
    name: "status",
    formDataKey: "status",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Level",
    name: "level",
    formDataKey: "level",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const committeeHandleColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Name of Committee",
    name: "committeeName",
    formDataKey: "committeeName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Description Of Activity",
    name: "description",
    formDataKey: "description",
    canSearch: true,
    showInExcel: true,
    readMore: true,
    width: 300,
    readMoreData: "description",
  },
  {
    title: "Activity Name",
    name: "employeeActivityName",
    formDataKey: "employeeActivityName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Date Of Activity",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "No Of Participants",
    name: "noParticipant",
    formDataKey: "noParticipant",
    canSearch: true,
    showInExcel: true,
    align: "right",
  },
  {
    title: "Level",
    name: "level",
    formDataKey: "level",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const viewTeacherNoticeColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Notice",
    name: "title",
    formDataKey: "title",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Notice For",
    name: "noticeFor",
    formDataKey: "noticeFor",
    canSearch: true,
    showInExcel: true
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
  }, {
    name: "action",
    title: "Action",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right"
  }
];

export const viewStudentNoticeColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Notice",
    name: "title",
    formDataKey: "title",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    showInExcel: true,
  },
];

export const adminViewAlumniPostInternship = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Alumnae Name",
    // name: "alumniName",
    name: "firstName",
    formDataKey: "alumniName",
    canSearch: true,
    showInExcel: true,
    width: 200,
    keyName: "firstName,lastName,fatherName,motherName",
    isFormat: true,
    type: 0,
  },
  {
    title: "Company Name",
    name: "companyName",
    formDataKey: "companyName",
    canSearch: true,
    showInExcel: true,
    width: 150,
  },
  {
    title: "Title",
    name: "title",
    formDataKey: "title",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Registration Links",
    name: "duration",
    formDataKey: "duration",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Location",
    name: "location",
    formDataKey: "location",
    canSearch: true,
    showInExcel: true,
    width: 100,
  },
  {
    title: "Company Website",
    name: "companyWebsite",
    formDataKey: "companyWebsite",
    canSearch: true,
    showInExcel: true,
    width: 100,
  },
];

export const consultancyColumns = [
  {
    title: "Sr. No.",
    name: "index",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  {
    title: "Consultancy Nature",
    name: "consNature",
    formDataKey: "consNature",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Consultancy Project Title",
    name: "consTitle",
    formDataKey: "consTitle",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Name Of The Client Organization",
    name: "clientOrganisationName",
    formDataKey: "clientOrganisationName",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Consultancy Details",
    name: "consAgency",
    formDataKey: "consAgency",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Consultancy Period",
    name: "consPeriod",
    formDataKey: "consPeriod",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Consultancy Amount",
    name: "consAmount",
    formDataKey: "consAmount",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

export const extendedActivitiesColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Activity Name",
    name: "name",
    formDataKey: "employeeActivity",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Activity Title",
    name: "activityTitle",
    formDataKey: "activityTitle",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Activity Type",
    name: "activityType",
    formDataKey: "type",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Date",
    name: "date",
    formDataKey: "date",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Level",
    name: "level",
    formDataKey: "level",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Organized By",
    name: "organizedBy",
    formDataKey: "organizedBy",
    canSearch: true,
    showInExcel: true,
  },

  { name: "action", title: "Action" },
];
export const WorkExpColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
  {
    title: "Company Name",
    name: "companyName",
    formDataKey: "companyName",
    canSearch: true,
    showInExcel: true,
    width: 200,
  },
  {
    title: "Position / Designation",
    name: "designation",
    formDataKey: "designation",
    canSearch: true,
    showInExcel: true,
    width: 150,
  },
  {
    title: "Location ",
    name: "location",
    formDataKey: "location",
    canSearch: true,
    showInExcel: true,
    width: 100,
  },
  {
    title: "From Date",
    name: "fromDate",
    formDataKey: "fromDate",
    canSearch: true,
    showInExcel: true,
    align: "center",
    width: 130,
  },
  {
    title: "To Date",
    name: "toDate",
    formDataKey: "toDate",
    canSearch: true,
    align: "center",
    showInExcel: true,
    width: 120,
  },
  {
    title: "Total Experience",
    name: "totalExperience",
    formDataKey: "totalExperience",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Professional Skills",
    name: "professionalSkills",
    formDataKey: "professionalSkills",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Current Job",
    name: "currentJobName",
    formDataKey: "currentJob",
    canSearch: true,
    showInExcel: true,
  },
  {
    title: "Self Employed",
    name: "selfEmployeedName",
    formDataKey: "selfEmployeed",
    canSearch: true,
    showInExcel: true,
  },
  { name: "action", title: "Action" },
];

// SportsAchievementsColumns,

export const AdminGallaryColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Name",
    name: "name",
    formDataKey: "name",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

// InternshipDocumentColumns,
export const InternshipDocumentColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Document Name",
    name: "fileName",
    formDataKey: "fileName",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },
  {
    title: "Status",
    name: "isActive",
    canSearch: true,
    showInExcel: true,
    align: "center",
  },
  { name: "action", title: "Action", align: "center" },
];

export const documentUploadColumns = [
  { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },

  {
    title: "Document Name",
    name: "docName",
    formDataKey: "docName",
    canSearch: true,
    showInExcel: true,
    align: "left",
  },

  { name: "action", title: "Action", align: "center" },
];

