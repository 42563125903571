import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import {
  default as endpoint,
  default as endpoints,
} from "../../../config/endpoints";
import { apiGet, apiGetWithoutHeader } from "../../../utils/api_service";
const initialState = {
  studentRegister: [],
  studentRegisterData: [],
  isFetch: false,
  msg: "",
  isMailUnique: false,
  GetOTPmsg: "",
  isGetOTP: false,
  error: "",
  isValOtp: false,
  getValidateOtpmsg: "",
  isValidateOtp: false,
  isServerFail: false,
  isSaveFailed: false,
  preloginPrnStud: {},
  isFetchPreloginPrnStud: false,
  preloginProgramTitleList: [],
  isFetchPreloginProgramTitle: false,
  getValidateMobMsg: "",
  isValidateMob: false,
  error: "",
  isValMob: false,
  validateUsername: {},
  isValidateUsername: false,
  error: "",
  isValUsernm: false,
};
let URL = endpoints.prnStudentPrelogin;
let generateOtp = endpoints.generateOTP;
let parentGenOtp = endpoints.generateParentOTP;
let emailUrl = endpoints.emailEmail;
let validateOtp = endpoint.validateOtp;
let preloginPrnStud = endpoints.prnStudentPrelogin;
let preloginProgramTitleURL = endpoints.preloginProgramTitle;
let preloginAlumniReg = endpoints.preloginAlumniRegister;
let parentMobURL = endpoints.checkParentMob;
let parentUsername = endpoints.validateParentUsername;
const parentRegisterSlice = createSlice({
  name: "parentRegister",
  initialState,
  reducers: {
    personValidateOTPFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: "Otp Invalid",
        isValOtp: true,
      };
    },
    validateMobFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error: "Mobile No Invalid",
        isValMob: true,
      };
    },
    validateParentUsernameFailure: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        error: "Mobile No Invalid",
        isValUsernm: true,
      };
    },
    parentGenrateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        GetOTPmsg: message,
        isGetOTP: true,
        error: "",
        isValOtp: false,
      };
    },
    personValidateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        getValidateOtpmsg: message,
        isValidateOtp: true,
        error: "",
        isValOtp: false,
      };
    },
    validateMobSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        getValidateMobMsg: data,
        isValidateMob: true,
        error: "",
        isValMob: false,
      };
    },
    validateParentUsernameSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { data } = action.payload;
      return {
        ...state,
        validateUsername: data,
        isValidateUsername: true,
        error: "",
        isValUsernm: false,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentRegister: row,
        isFetch: true,
      };
    },
    preloginPrnStudent: (state = cloneDeep(initialState), action) => {
      const { content } = action.payload;
      return {
        ...state,
        preloginPrnStud: content,
        isFetchPreloginPrnStud: true,
      };
    },
    resetErrorSucces: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        studentRegisterData: [],
        isFetch: false,
        msg: "",
        isMailUnique: false,
        GetOTPmsg: "",
        isGetOTP: false,
        error: "",
        isValOtp: false,
        getValidateOtpmsg: "",
        isValidateOtp: false,
      };
    },
    resetFlagSucces: (state = cloneDeep(initialState), action) => {
      console.log("hi");
      return {
        ...state,
        GetOTPmsg: "",
        isGetOTP: false,
      };
    },

    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
      };
    },
    resetDeleteSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: false,
      };
    },
    saveFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isSaveFailed: true,
      };
    },
  },
});

export const {
  parentGenrateOTPSuccess,
  personValidateOTPFailure,
  personValidateOTPSuccess,
  resetErrorSucces,
  serverFailed,
  resetDeleteSuccess,
  resetFlagSucces,
  saveFailed,
  preloginPrnStudent,
  validateMobSuccess,
  validateMobFailure,
  validateParentUsernameSuccess,
  validateParentUsernameFailure,
} = parentRegisterSlice.actions;

export default parentRegisterSlice.reducer;

export const getPrnStudentPrelogin =
  ({ prn }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url:
          preloginPrnStud + "?baseUrl=" + window.location.host + "&prn=" + prn,
      }).then(({ data, success }) => {
        if (success) {
          const content = data;
          console.log(content);
          dispatch(preloginPrnStudent({ content }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveParentRegister =
  ({ studentRegisterData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(studentRegisterData);
      console.log(studentRegisterData);
      console.log(data);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      const response = await axios
        .post(parentUsername + "?baseUrl=" + window.location.host, data, {
          headers,
        })
        .then((response) => {
          console.log("Success With saveReservCat ========>", response.data);
          const { data } = response.data;
          return true;
        })
        .catch((error) => {
          console.log("Error saveReser vCat ========>", error.response.data);
          dispatch(saveFailed());

          return false;
        });
      return response;
    } catch (e) {
      dispatch(serverFailed());

      return console.error(e.message);
    }
  };

export const getGenerateOTP =
  ({ contactNo }) =>
  async (dispatch) => {
    try {
      console.log("contactNo" + contactNo);

      const response = await axios
        .get(parentGenOtp + contactNo + "&baseUrl=" + window.location.host)
        .then((response) => {
          console.log("Success With OTP Send ========>", response.data);
          const { message } = response.data.data;
          dispatch(parentGenrateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With OTP Send ========>", error.response.data);
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getValidateOtp =
  ({ otp }) =>
  async (dispatch) => {
    try {
      console.log("otp" + otp);

      const response = await axios
        .get(validateOtp + otp)
        .then((response) => {
          console.log("Success With id validate OTP ========>", response);
          const { message } = response.data.data;
          dispatch(personValidateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With Validate OTP ========>", error.response.data);
          dispatch(personValidateOTPFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const validateParentMob =
  ({ contactNo }) =>
  async (dispatch) => {
    try {
      const response = await axios
        .get(
          parentMobURL +
            "?baseUrl=" +
            window.location.host +
            "&parentMobile=" +
            contactNo
        )
        .then((response) => {
          console.log("Success With id validate Mob ========>", response);
          const { data } = response.data;
          console.log("Success With id validate Mob ========>", data);
          dispatch(validateMobSuccess({ data }));
          return response.data.data.error;
        })
        .catch((error) => {
          dispatch(validateMobFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const usernameParentValidate =
  ({ contactNo }) =>
  async (dispatch) => {
    try {
      const response = await axios
        .get(
          parentUsername +
            "/validate-user-name?baseUrl=" +
            window.location.host +
            "&parentMobile=" +
            contactNo
        )
        .then((response) => {
          console.log("Success With id validate Mob ========>", response);
          const { data } = response.data;
          console.log("Success With id validate Mob ========>", data);
          dispatch(validateParentUsernameSuccess({ data }));
          return response.data.data.error;
        })
        .catch((error) => {
          dispatch(validateParentUsernameFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetError = () => async (dispatch) => {
  try {
    dispatch(resetErrorSucces());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetFlag = () => async (dispatch) => {
  try {
    dispatch(resetFlagSucces());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetDelete = () => async (dispatch) => {
  try {
    dispatch(resetDeleteSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
