export const teachingPlanPrincipleJson = {
  screenTitle: "Principal / HOD View",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getListId: "yearDetail",
  fieldMeta: [
    {
      label: "Year Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "yearDetailMaster",
      getListId: "yearDetail",
      dataKey: "yearDetail",
      isMandatory: true,
    },
    {
      label: "Semester",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "semesterDetailMaster",
      getListId: "semister,division,batch",
      getListFrom: "yearDetail",
      dataKey: "semister",
      isMandatory: true,
    },
    {
      label: "Division",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "divisionMaster",
      dataKey: "division",
      getListId: "division,semister,batch",
      getListFrom: "semister",
      isMandatory: true,
    },
    {
      label: "Batch",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "batchMaster",
      getListFrom: "division,semister",
      getListId: "division,semister,batch",
      dataKey: "batch",
      isMandatory: true,
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "subjectMaster",
      getListFrom: "division,semister,batch",
      dataKey: "subject",
      isMandatory: true,
    },
  ],
};
