// import { Grid, Paper } from "@mui/material";
// import moment from "moment";
// import React, { Component } from "react";
// import { connect } from "react-redux";
// import swal from "sweetalert";
// import { getAlert } from "../../../../../CommonActions/alert.slice";
// import { ButtonCompo } from "../../../../../components/Comman/Button";
// import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
// import { LabelCompo } from "../../../../../components/Comman/Label";
// import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
// import DynamicTable from "../../../../../components/Comman/RT/MaterialUIRTTable";
// import endpoint from "../../../../../config/endpoints";
// import { StudentNoticeJson } from "../../../../../DynamicFormsJson/StudentNotice";
// import { showNotification } from "../../../../Landing/Landing.slice";
// import { apiDelete } from "../../../../../utils/api_service";
// import {
//     getAllEmployees,
//     getEmployeeByDeptId,
//     getEmployeeById
// } from "../../../../EmployeeDetails/employee.slice";
// import { getDepartment } from "../../../Internship/Department/department.slice";
// import { getAcademicYear } from "../../../OnlineModule/AcademicYear/academicYear.slice";
// import AddStudentNotice from "./AddStudentNotice";
// import * as myConstClass from "../../../../../config/messageconstant";
// import {
//     getStudentNotice,
//     getStudentNoticeFromDtToDt
// } from "./studentNotice.slice";
// export const OperationMode = {
//   Add: "Add",
//   Edit: "Edit",
//   Status: "Status",
// };

// const columns = [
//   {
//     name: "index",
//     title: "Sr. No.",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width:"10%"
//   },
//   {
//     name: "title",
//     title: "Title",
//     positionCenter: false,
//     showInscreen: true,
//     alignCenter: "left",
//     width:"20%"
//   },
//   // {
//   //   name: "yearDetail",
//   //   title: "Year Details",
//   //   positionCenter: false,
//   //   showInscreen: true,
//   //   alignCenter: "left",
//   //   width:"20%"
//   // },
//   {
//     name: "fromDate",
//     title: "From Date",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width:"20%"
//   },
//   {
//     name: "toDate",
//     title: "To Date",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width:"20%"
//   },
// ];

// class StudentViewNotice extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       tableColumnsToFilter: this.props.tableColumnsToFilter,
//       fieldData: {},
//       showTable: true,
//       showForm: false,
//       showAddCommitteeForm: false,
//       showAppliedStudentsForm: false,
//       showCommittee: false,
//       currentOperationMode: "",
//       searchValue: "",
//       isLoading: false,
//       department: "",
//       academicYearId: "",
//       fromDate: "",
//       toDate: "",
//       departmentList: [],
//       editData: {},
//       committeeData: {},
//       eventDataForAppliedStudents: {},
//       formErrors:[]
//     };
//   }

//   handleFormValidation()
//   {
//       const { fromDate, toDate } = this.state;
//       let formErrors = {};
//       let formIsValid = true;

//       if ( fromDate.toString().trim() === "" || fromDate === null )
//       {
//           formIsValid = false;
//           formErrors[ "fromDateError" ] = myConstClass.fromDateMsg;
//       }

//       if ( toDate.toString().trim() === "" || toDate === null )
//       {
//           formIsValid = false;
//           formErrors[ "toDateError" ] = myConstClass.toDateMsg;
//       }

//       this.setState( { formErrors: formErrors } );
//       return formIsValid;
//   }

//   submitHandler = () => {
//     const { getEventByAcademicId, getAlert, getAllEmployees } = this.props;
//     const { fromDate,toDate } = this.state;
//     let frmDt = moment(fromDate).format("YYYY-MM-DD");
//     let toDt = moment(toDate).format("YYYY-MM-DD");
//     if(this.handleFormValidation()){
//         this.props.getStudentNoticeFromDtToDt({
//             fromDate: frmDt,toDate:toDt
//           }).then((response) => {
//             if (!response) {
//               getAlert({ message: "Something went wrong" });
//             }
//           });
//     }
//   };

//   toggleFormTableVisibility = () => {
//     const { showForm, showTable, showAddCommitteeForm } = this.state;
//     this.setState({
//       showForm: true,
//       showAddCommitteeForm: false,
//       showTable: false,
//       openSnackbar: true,
//       editData: {},
//     });
//   };

//   backToForm = () => {
//     console.log("button clicked");
//     this.setState({
//       showForm: false,
//       showAddCommitteeForm: false,
//       showTable: true,
//       showAppliedStudentsForm: false,
//       openSnackbar: true,
//     });
//   };

//   backTo = () => {
//     this.setState({
//       showTable: true,
//     });
//   };

//   onFormCancel = () => {
//     this.setState({
//       showForm: true,
//       showAddCommitteeForm: false,
//       showTable: false,
//     });
//   };

//   componentWillReceiveProps(nextProps) {
//     if (this.props.departments !== nextProps.departments) {
//       if (
//         this.props.departments.department !== nextProps.departments.department
//       ) {
//         let departmentData = [];
//         departmentData = [
//           ...nextProps.departments.department,
//           { id: "0", name: "All" },
//         ];
//         this.setState({
//           departmentList: departmentData,
//         });
//       }
//     }
//   }

//   onRTSelectMultipleChange = (selectedOption) => {
//     const { tableColumnsToFilter } = this.state;
//     const selectedItem = tableColumnsToFilter.filter(
//       (item) => item.columnKeyName === selectedOption.columnKeyName
//     )[0];
//     selectedItem.isChecked = !selectedItem.isChecked;

//     this.setState({
//       tableColumnsToFilter: tableColumnsToFilter,
//     });
//   };

//   onDataChange = (fieldName, newValue) => {
//     this.setState({
//       fieldData: { ...this.state.fieldData, [fieldName]: newValue },
//     });
//   };

//   onSearch = (searchValue) => {
//     this.setState({
//       searchValue: searchValue,
//     });
//   };

//   onAddButtonClick = () => {
//     let fieldData = {};
//     // const { fieldMeta } = this.props;
//     // fieldMeta.map((currentField) => {
//     //   if (currentField.defaultValue) {
//     //     fieldData[currentField.dataKey] = currentField.defaultValue;
//     //   }
//     // });

//     this.setState({
//       fieldData: fieldData,
//       currentOperationMode: OperationMode.Add,
//     });
//     this.toggleFormTableVisibility();
//   };

//   rowAdd = (rowData) => {
//     this.props.rowData(rowData);
//   };

//   OnClickEdit = ( data ) =>
//     {
//         console.log( data );
//         this.setState( {
//             showAddCommitteeForm: false,
//             showTable: false,
//             showForm: true,
//             editData: data,
//         } );
//     };

//   componentDidMount() {
//     this.props.getAcademicYear().then((response) => {
//       if (!response) {
//         this.props.getAlert({ message: "Something went wrong" });
//       }
//     });

//     this.props.getStudentNotice().then((response) => {
//       if (!response) {
//         this.props.getAlert({ message: "Something went wrong" });
//       }
//     });
//   }

//   rowDelete = (rowData) => {
//     swal({
//       title: "Are you sure?",
//       text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         this.onDelete(rowData.id);
//       }
//     });
//   };

//   onDelete = (id) => {
//     this.setState({
//       isLoading: true,
//     });
//     apiDelete({
//       url: endpoint.studentNotice + "/" + id,
//     }).then(({ success }) => {
//       if (success) {
//         this.setState({
//           isLoading: false,
//         });
//         this.props.showNotification({ msg: "Data deleted successfully" });
//         const { getEmployeeByDeptId, getAlert } = this.props;
//         this.props.getStudentNotice().then((response) => {
//           if (!response) {
//             this.props.getAlert({ message: "Something went wrong" });
//           }
//         });
//       } else {
//         this.props.getAlert({
//           message: "Failed to delete",
//         });
//         this.setState({
//           isLoading: false,
//         });
//       }
//     });
//   };

//   onDateChange = (dataKey) => (date) => {
//     this.setState({
//       [dataKey]: date,
//     });
//   };

//   render() {
//     const {
//       showAddButtonInTable = false,
//       showExcelDownload = false,
//       showAddButton = false,
//       departments,
//       employeeDetails,
//       academicYear,
//     } = this.props;
//     const {
//       tableColumnsToFilter = [],
//       showTable,
//       showForm,
//       showAddCommitteeForm,
//       department,
//       departmentList,
//       showAppliedStudentsForm,
//       academicYearId,
//       showCommittee,
//       fromDate,
//       toDate,
//     } = this.state;
//     const {
//         fromDateError,
//         toDateError,
//       } = this.state.formErrors;
//     console.log(this.props.studNoticeList);
//     return (
//       <>
//         {/* <LandingScreenHeader
//                     tableColumnsToFilter={tableColumnsToFilter}
//                     onRTSelectMultipleChange={this.onRTSelectMultipleChange}
//                     screenTitle={StudentNoticeJson.screenTitle}
//                     showPdfDownload={EmployeeListJson.showPdfDownload}
//                     generatePDF={this.generatePDF}
//                     showExcelDownload={showExcelDownload}
//                     generateExcel={this.generateExcel}
//                     showAddButton={showAddButton}
//                     onAddButtonClick={this.onAddButtonClick}
//                     onSearch={this.onSearch}
//                     showTable={showTable}
//                 /> */}
//         <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
//         <NewLandingScreenHeader
//           screenTitle={StudentNoticeJson.screenTitle}
//           showPdfDownload={false}
//           showExcelDownload={false}
//           showAddButton={showAddButton}
//           onAddButtonClick={this.onAddButtonClick}
//           showTable={showTable}
//         />

//           {showTable && (
//             <>
//               <Grid item sx={{ mt: 2 }}>
//                 <DynamicTable
//                   data={this.props.studNoticeList?.studentNotice}
//                   tableHead={columns}
//                   showHeadDelete={true}
//                   showHeadEdit={true}
//                   showPegination={false}
//                   isActionColActive={false}
//                   rowDelete={this.rowDelete}
//                   rowEdit={this.OnClickEdit}
//                   onCancel={this.backToForm}
//                 />

//               </Grid>
//             </>
//           )}
//         </Paper>
//       </>
//     );
//   }
// }
// const mapStateToProps = (state) => ({
//   academicYear: state.academicYear,
//   departments: state.department,
//   employeeDetails: state.employeeList,
//   eventList: state.event,
//   studNoticeList: state.studNotice,
// });
// const mapDispatchToProps = {
//   showNotification,
//   getStudentNotice,
//   getAlert,
//   getDepartment,
//   getEmployeeByDeptId,
//   getAllEmployees,
//   getEmployeeById,
//   getAcademicYear,
//   getStudentNoticeFromDtToDt
// };
// export default connect(mapStateToProps, mapDispatchToProps)(StudentViewNotice);

import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../../../components/dynamicscreens/DynamicForm";
import endpoint from "../../../../../config/endpoints";
import * as msgConstant from "../../../../../config/messageconstant";
import { StudentNoticeJson } from "../../../../../DynamicFormsJson/StudentNotice";
import { showNotification } from "../../../../../Pages/Landing/Landing.slice";
import { viewStudentNoticeColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../../../utils/api_service";
import { getStudentNotice } from "./studentNotice.slice";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import * as myConstClass from "../../../../../config/messageconstant";
import StudentNoticeTable from "./StudentNoticeTable";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class StudentViewNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      formErrors: [],
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    let fieldData = {};

    StudentNoticeJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getStudentNotice({
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  }

  onChangeStatus = (rowData, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveEmployee +
            "/update-status?userRegistrationId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    StudentNoticeJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: StudentNoticeJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.props.getConsultancy().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: StudentNoticeJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getConsultancy().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });

        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
    const { fromDate, toDate } = this.state;
    let fromDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    console.log(fromDt + " 1 " + toDt);
    if (this.handleFormValidation()) {
      this.props
        .getConsultancyByDate({ fromDate: fromDt, toDate: toDt })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  getFilteredTableData = () => {
    const { studNoticeList } = this.props;
    const { searchValue } = this.state;
    const columsFil = viewStudentNoticeColumns.filter((item) => {
      return item.canSearch;
    });
    return studNoticeList?.studentNotice.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  generatePDF = () => {
    const { excelFileName = "StudentViewNotice" } = this.props;
    const tableData = this.getFilteredTableData();
    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      viewStudentNoticeColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    viewStudentNoticeColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  generateExcel = () => {
    const { excelFileName = "Resource Person" } = this.props;
    const tableData = this.getFilteredTableData();
    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      viewStudentNoticeColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      employeeDetails,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      dynamicMasterData,
      fieldData,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    console.log(this.props.seminarWebinarList?.seminarWebinar);
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={StudentNoticeJson.screenTitle}
          showPdfDownload={StudentNoticeJson.showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={StudentNoticeJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={false}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {showTable && (
            <>
              <Grid item sx={{ marginTop: 1 }}>
                <StudentNoticeTable rows={this.getFilteredTableData()} />
                {/* <RTTable
                  columns={viewStudentNoticeColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  showAddButtonInTable={showAddButtonInTable}
                  rowEdit={this.rowEdit}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                  rowAdd={this.rowAdd}
                  isActionColActive={false}
                  // rows={this.props.consultancyList?.consultancy}
                  // tableData={this.props.consultancyList?.consultancy}
                  tableData={this.getFilteredTableData()}
                  rows={this.getFilteredTableData()}
                /> */}
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYear: state.academicYear,
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
  studNoticeList: state.studNotice,
});
const mapDispatchToProps = {
  showNotification,
  getStudentNotice,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentViewNotice);
