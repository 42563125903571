export const viewSuggestionDynamicJson = {
    screenTitle: "View Suggestions",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    getAcadamicYear: true,
    getListId: 'acadamicYear,yearDetail',
    fieldMeta: [
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "fromDate",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            isMandatory: true,
            // onSubmit: "compare",
            // onSubmitParameter: "toDate-l"
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "toDate",
            isMAxDate: false,
            // maxDate: new Date(),
            isMinDate: true,
            // minDate: new Date(),
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "fromDate-g"
        },
    ],
};
