import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  workExperience: [],
  isFetch: false,
};
let URL = endpoints.workExperience;
const workExperienceSlice = createSlice({
  name: "workExperienceSlice",
  initialState,
  reducers: {
    workExperienceSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        workExperience: row,
        isFetch: true,
      };
    },
  },
});

export const { workExperienceSuccess } = workExperienceSlice.actions;

export default workExperienceSlice.reducer;

export const getWorkExperienceByAluminiId =
  ({ id = "" }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-alumni-registration?id=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              companyName: data1.companyName,
              designation: data1.designation,
              location: data1.location,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              totalExperience: data1.totalExperience,
              professionalSkills: data1.professionalSkills,
              currentJob: data1.currentJob,
              currentJobName: data1.currentJob == "1" ? "No" : "Yes",
              selfEmployeed: data1.selfEmployeed,
              selfEmployeedName: data1.selfEmployeed == "1" ? "Yes" : "No",
            };
            row.push(bData);
            return data1;
          });
          dispatch(workExperienceSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
