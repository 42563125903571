import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { LabelCompo } from "../../components/Comman/Label";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MarkAllocationPopUp({
  handleCloses,
  onClose,
  markData,
}) {
  const [open, setOpen] = React.useState(true);
  const [open1, setOpen1] = React.useState(false);
  const [internalMarks, setInternalMarks] = React.useState("");
  const [outOfInternal, setOutOfInternal] = React.useState("");
  const [externalMarks, setExternalMarks] = React.useState("");
  const [outOfExternal, setOutOfExternal] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  console.log(markData);

  const handleClose = () => {
    handleCloses();
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Marks For Interview
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Marks"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}></Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Internal Marks "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="internalMarks"
                      size="small"
                      value={markData.internalMarks}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Out of Internal Marks  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="outOfInternal"
                      size="small"
                      value={markData.outOfInternal}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="External Marks  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="externalMarks"
                      size="small"
                      value={markData.externalMarks}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Out of External Marks "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="outOfExternal"
                      size="small"
                      value={markData.outOfExternal}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}></Grid>

                  <Grid item xs={12} sm={12} md={4}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </Paper>
    </>
  );
}
