import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { MyComponentWithIconProps } from "../../../../components/Comman/IconButton";
import { CommonNameFormat } from "../../../../components/Comman/Util/CommonNameFormat";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="error"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.alignCell === "center"
                ? "center"
                : headCell.alignCell === "right"
                ? "right"
                : "left"
            }
            width={headCell.minWidth}
          >
            {headCell.title}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function AluminiMentorTable({
  rows,
  columns,
  rowViewData,
  selection,
  isActionColActive = true,
  onSelectionChange,
  rowAdd,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState(selection);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      // setSelected(newSelecteds);
      onSelectionChange(newSelecteds);

      return;
    }
    onSelectionChange([]);

    // setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selection.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selection, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelected = newSelected.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1)
      );
    }

    onSelectionChange(newSelected);
    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selection.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            headCells={columns}
            numSelected={selection.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Row
                    key={row.id}
                    row={row}
                    rowIndex={index}
                    tableHead={columns}
                    rowViewData={rowViewData}
                    handleClick={handleClick}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    isActionColActive={isActionColActive}
                    rowAdd={rowAdd}
                    //   tableSubHead={tableSubHead}
                    //   tableSubList={tableSubList}
                  />
                  // <TableRow
                  //   hover

                  //   role="checkbox"
                  //   aria-checked={isItemSelected}
                  //   tabIndex={-1}
                  //   key={row.name}
                  //   selected={isItemSelected}
                  // >
                  //   <TableCell padding="checkbox">
                  //     <Checkbox
                  //       color="primary"
                  //       onClick={(event) => handleClick(event, row.id)}
                  //       checked={isItemSelected}
                  //       inputProps={{
                  //         "aria-labelledby": labelId,
                  //       }}
                  //     />
                  //   </TableCell>
                  //   <TableCell
                  //     component="th"
                  //     id={labelId}
                  //     scope="row"
                  //     padding="none"
                  //   >
                  //     {row.index}
                  //   </TableCell>

                  // </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
    </Box>
  );
}

function Row({
  row,
  tableHead,
  rowViewData,
  rowAdd,
  rowIndex,
  labelId,
  isItemSelected,
  handleClick,
  isActionColActive,
}) {
  const onClickAdd = (e, row) => {
    e.stopPropagation();
    rowAdd(row);
  };

  const onClickViewPreview = (e, data) => {
    e.stopPropagation();
    rowViewData(data);
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onClick={(event) => handleClick(event, row.id)}
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter((row) => row.name === key);
        if (filteredList.length !== 0) {
          if (
            filteredList[0].isFormat != undefined &&
            filteredList[0].isFormat
          ) {
            const formatedNameData = CommonNameFormat(
              filteredList[0].type,
              filteredList[0].keyName,
              row
            );

            return <TableCell>{formatedNameData}</TableCell>;
          } else if (filteredList[0].name === "index")
            return (
              <TableCell align={filteredList[0].align} style={{ width: "10%" }}>
                {rowIndex + 1}
              </TableCell>
            );
          else if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell align={filteredList[0].align} style={{ width: "10%" }}>
                {row[key]}
              </TableCell>
            );
          else
            return (
              <TableCell align={filteredList[0].alignCenter}>
                {" "}
                {row[key]}
              </TableCell>
            );
        }
      })}

      {isActionColActive && (
        <>
          <TableCell align={"center"} style={{ width: "12%" }}>
            {row.completeStatus == 0 ? (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AddIcon}
                  color="primary"
                  fontSize="medium"
                  title="Add Details"
                  onClick={(e) => onClickAdd(e, row)}
                />
              </>
            ) : (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  onClick={(e) => onClickViewPreview(e, row)}
                />
              </>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
