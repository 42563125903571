import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";

const initialState = {
  employee: {},
  isSave: false,

  employeeList: [],
  isFetch: false,

  employeeCodeMsg: "",

  promotionRecords: [],
  isFetchPromotionRecords: false,
};
let employeeSaveURL = endpoints.saveEmployee;
let employeeDetailsURL = endpoints.employeeDetails;
let employeeDesignation = endpoints.employeeDesignation;
let profileInfo = endpoints.profileInfo;
const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        employee: {},
        isSave: false,
      };
    },

    employeeSuccess: (state = cloneDeep(initialState), action) => {
      const { employee } = action.payload;
      return {
        ...state,
        employee: employee,
        isSave: true,
      };
    },

    promotionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        promotionRecords: row,
        isFetchPromotionRecords: true,
      };
    },

    employeeListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        employeeList: row,
        isFetch: true,
      };
    },
    resetEmployeeSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        employeeList: [],
        isFetch: false,
      };
    },
    employeeCheckFails: (state = cloneDeep(initialState), action) => {
      const { msg } = action.payload;
      return {
        ...state,
        employeeCodeMsg: msg,
        isFetch: true,
      };
    },
  },
});

export const {
  employeeSuccess,
  resetEmployeeSuccess,
  employeeListSuccess,
  employeeCheckFails,
  promotionSuccess,
  resetSuccess,
} = employeeSlice.actions;

export default employeeSlice.reducer;

export const saveEmployee =
  ({ employeeData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: employeeSaveURL,
        postBody: employeeData,
      }).then(({ data, success }) => {
        if (success) {
          if (data.data.errorMessage !== null) {
            if (data.data.errorMessage.error) {
              return false;
            }
          } else {
            const { employee } = data.data;
            dispatch(employeeSuccess({ employee }));
            return true;
          }
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetEmployee = () => async (dispatch) => {
  try {
    dispatch(resetEmployeeSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getEmployeeById =
  ({ employeeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: employeeSaveURL + "/" + employeeId,
      }).then(({ data, success }) => {
        if (success) {
          const employee = data.data;

          dispatch(employeeSuccess({ employee }));
          return success;
        }
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getEmpProfile = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: profileInfo,
    }).then(({ data, success }) => {
      if (success) {
        const employee = data.data;
        if (employee !== null) {
          localStorage.setItem(
            "userName",
            employee.firstName.charAt(0) +
              "." +
              employee.middleName.charAt(0) +
              "." +
              employee.lastName
          );
          localStorage.setItem("person", employee.firstName);
          localStorage.setItem("profilePic", employee.profilePic);
          dispatch(employeeSuccess({ employee }));
        }
        return { success: success, data: data };
      }
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getEmployeeByDeptId =
  ({ departmentId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          employeeDetailsURL +
          "/get-employee-by-department-ids?departmentIds=" +
          departmentId,
      }).then(({ data, success }) => {
        if (success) {
          const empDetailsList = data.data;
          let index = 0;
          let row = [];
          empDetailsList.map((data1) => {
            index = index + 1;
            let employeeData = {
              index: index,
              firstName: data1.userRegistration.firstName,
              middleName: data1.userRegistration.middleName,
              lastName: data1.userRegistration.lastName,
              id: data1.userRegistration.id,
              name:
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName +
                " " +
                data1.userRegistration.lastName,
              employeeId:
                data1.userRegistration.grnNo === null
                  ? "No Data"
                  : data1.userRegistration.grnNo,

              employeeName:
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName +
                " " +
                data1.userRegistration.lastName,
              department:
                data1.department === null ? "" : data1.department.name,
              designation:
                data1.designation === null ? "" : data1.designation.name,
              isActive: data1.userRegistration.isActive,
            };
            row.push(employeeData);
            return data1;
          });
          dispatch(employeeListSuccess({ row }));
          return row;
        }
        return null;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAllEmployees = () => async (dispatch) => {
  try {
    const success = apiGet({
      url: employeeSaveURL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            employeeId: data1.grnNo === null ? "No Data" : data1.grnNo,
            name:
              data1.firstName + " " + data1.middleName + " " + data1.lastName,
            employeeName:
              data1.lastName + " " + data1.firstName + " " + data1.middleName,
            firstName: data1.firstName,
            middleName: data1.middleName,
            lastName: data1.lastName,
            department: data1.department === null ? "" : data1.department.name,
            designation:
              data1.designation === null ? "" : data1.designation.name,
            joiningDate: data1.joiningDate === null ? "" : data1.joiningDate,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(employeeListSuccess({ row }));
      }
      return success;
    });
    return success;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPromotionRecords =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          employeeDesignation +
          "/by-user-registration?userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const empDetailsList = data.data;
          let index = 0;
          let row = [];
          empDetailsList.map((data1) => {
            index = index + 1;
            let employeeData = {
              index: index,
              id: data1.id,
              userId: data1.userRegistration.id,
              designation: data1.designation.name,
              fromDate: data1.fromDate === null ? " - " : data1.fromDate,
              toDate: data1.toDate === null ? " - " : data1.toDate,
              approvalDate:
                data1.approvalDate === "" || data1.approvalDate === null
                  ? " - "
                  : data1.approvalDate,
              approvalNo:
                data1.approvalNo === "" || data1.approvalNo === null
                  ? " - "
                  : data1.approvalNo,
            };
            row.push(employeeData);
            return data1;
          });
          dispatch(promotionSuccess({ row }));
          return row;
        }
        return null;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
