
    import endpoint from "../config/endpoints";
    
    export const feeType = {
        "apiBaseURL": endpoint.feeType,
  "screenTitle": "Fee Type",
  "showAddButton": true,
  "showPdfDownload": true,
  "pdfFileName": "Fee Type",
  "showExcelDownload": true,
  "excelFileName": "Fee Type",
  "tableColumnsToFilter": [
    {
      "columnDisplayName": "Name",
      "columnKeyName": "name",
      "isChecked": true
    },
    {
      "columnDisplayName": "Status",
      "columnKeyName": "isActive",
      "isChecked": true
    }
  ],
  "baseIdColumn": "id",
  "fieldMeta": [
    {
      "label": "Name",
      "controlType": "textfield",
      "placeHolder": "",
      "md": 12,
      "lg": 12,
      "sm": 12,
      "xs": 12,
      "dataKey": "name",
      "isMandatory": true
    }
  ]
}
