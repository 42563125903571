import axios from "axios";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoint from "../../config/endpoints";
//004f5faf-c79e-4ed0-bb66-379043e4e4bc.mp4
//+ url
export const downloadVideo =
  ({ url }) =>
  async (dispatch) => {
    try {
      let videoURL = endpoint.videoDownload;
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const doc = axios
        .get(videoURL + "/" + url, {
          headers: {
            Authorization: token,
            shouldrefresh: "true",
          },
          responseType: "blob",
        })
        .then((response) => {
          console.log(response);
          const file = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const fileURL = URL.createObjectURL(file);
          console.log(fileURL);

          return fileURL;
        });
      return doc;
    } catch (e) {}
  };

export const downloadStudentVideo =
  ({ url }) =>
  async (dispatch) => {
    try {
      let videoURL = endpoint.videoDownload;
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const doc = axios
        .get(videoURL + "/" + url, {
          headers: {
            Authorization: token,
            shouldrefresh: "true",
          },
          responseType: "blob",
        })
        .then((response) => {
          console.log(response);
          const file = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const fileURL = URL.createObjectURL(file);
          console.log(fileURL);

          const pdfWindow = window.open("_self");
          pdfWindow.location.href = fileURL;
          return fileURL;
        });
      return doc;
    } catch (e) {}
  };
