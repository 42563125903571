import React, { Component } from 'react';
import { connect } from 'react-redux';
import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class PieChart extends Component
{
	state = {
		percentageList: [
			{ y: 1, label: "Completed" },
			{ y: 1, label: "Not Completed" },
		]		
	  };
	  
	render()
	{
		const { graphData } = this.props.teacherDashboardDetails;
		const { percentageList } = this.state;
		const { pieChartData,teacherDashboardDetails } = this.props;
		
		console.log(graphData)
		const options = {
			exportEnabled: true,
			exportFileName: "Syllabus Status",
			animationEnabled: true,		
			title: {
				text: "Syllabus Status"
			},
			toolTipContent: "{name}: <strong>{y}%</strong>",
			data: [{
				type: "pie",
				startAngle: 100,
				toolTipContent: "<b>{label}</b>: {y}%",
				showInLegend: "true",
				legendText: "{label}",
				indexLabelFontSize: 20,
				indexLabel: "{label} = {y}%",
				dataPoints:pieChartData
			}]
		}
		
		return (
	
			<CanvasJSChart options = {options} />
			
		)
	}
}

const mapStateToProps = ( state ) => ( {
	teacherDashboardDetails: state.teacherDashboard,
	
  } );
  
  const mapDispatchToProps = {
  };
  
  export default connect( mapStateToProps, mapDispatchToProps )( PieChart );
  
  