import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  division: [],
  isFetchDivision: false,
};
let URL = endpoints.division;
const divisionSlice = createSlice({
  name: "division",
  initialState,
  reducers: {
    divisionListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        division: row,
        isFetchDivision: true,
      };
    },
    divisionListFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        division: [],
        isFetchDivision: false,
      };
    },
  },
});

export const { divisionListSuccess, divisionListFail } = divisionSlice.actions;

export default divisionSlice.reducer;

export const getDivision = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            yearDetails:
              data1.getYearDetailId !== null
                ? data1.getYearDetailId.year.programType.name +
                  " - " +
                  data1.getYearDetailId.programTitle.brName +
                  " - " +
                  data1.getYearDetailId.year.className
                : "",
            name: data1.name,
            isActive: data1.isActive,
            yearDetailId: data1.yearDetailId,
          };
          row.push(bData);
          return data1;
        });
        dispatch(divisionListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
