import { Grid, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getEducationByAluminiId } from "./studentFillProfile.slice";

class AluminiCourseDetails extends Component {
  state = {
    id: "",
    locationOfTheInst: "",
    uniName: "",
    programDegree: "",
    period: "",
    formErrors: [],
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  componentDidMount() {
    const { educationalData } = this.props;
    console.log(educationalData);
    if (Object.keys(educationalData).length != 0) {
      this.setState({
        id: educationalData.id,
        locationOfTheInst: educationalData.location,
        uniName: educationalData.institute,
        programDegree: educationalData.programTitle,
        period: educationalData.period,
      });
    } else {
      this.setState({
        id: "",
        locationOfTheInst: "",
        uniName: "",
        programDegree: "",
        period: "",
      });
    }
  }

  handleFormValidation() {
    const { uniName, programDegree, period } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (period.toString().trim() === "" || period === null) {
      formIsValid = false;
      formErrors["periodErr"] = myConstClass.completionYearMsg;
    }

    if (uniName.toString().trim() === "") {
      formIsValid = false;
      formErrors["uniErr"] = "*College / Institute/ University Name Required";
    }
    if (programDegree.toString().trim() === "" || programDegree === null) {
      formIsValid = false;
      formErrors["progerr"] = myConstClass.programDegreeMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { locationOfTheInst, uniName, programDegree, period, id } =
      this.state;
    let docs = {};
    if (id !== "") {
      docs = {
        id: id,
        institute: uniName,
        programTitle: programDegree,
        period: period,
        location: locationOfTheInst,
        instituteId: "-",
        programTitleId: "-",
      };
    } else {
      docs = {
        institute: uniName,
        programTitle: programDegree,
        period: period,
        location: locationOfTheInst,
        instituteId: "-",
        programTitleId: "-",
      };
    }
    console.log(docs);
    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Do you want to Save Information?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.onSave(docs);
          this.props.onSaveData();
          this.setState({
            id: "",
            locationOfTheInst: "",
            uniName: "",
            programDegree: "",
            period: "",
          });
        }
      });
    }
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.educationalDetails,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data Saved Successfully",
        });
        this.setState({
          uniName: "",
          programDegree: "",
          period: "",
          locationOfTheInst: "",
        });
        this.props.getEducationByAluminiId({ id: "" }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  render() {
    const { locationOfTheInst, uniName, programDegree, period } = this.state;
    const {} = this.props;
    const { uniErr, progerr, periodErr } = this.state.formErrors;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
            sx={{ marginTop: 3 }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              spacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                spacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*College / Institute/ University Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="uniName"
                      size="small"
                      value={uniName}
                      fullWidth
                      onChange={this.changedHandler}
                      helperText={uniErr ? uniErr : " "}
                      error={uniErr ? true : false}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Program/Degree"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="programDegree"
                      size="small"
                      value={programDegree}
                      fullWidth
                      onChange={this.changedHandler}
                      helperText={progerr ? progerr : " "}
                      error={progerr ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Location "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="locationOfTheInst"
                      size="small"
                      value={locationOfTheInst}
                      fullWidth
                      onChange={this.changedHandler}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Completion Year"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="period"
                      size="small"
                      value={period}
                      fullWidth
                      onChange={this.changedHandler}
                      helperText={periodErr ? periodErr : " "}
                      error={periodErr ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={8}
                  xs={12}
                  md={8}
                  lg={8}
                  columnSpacing={4}
                  container
                  justifyContent="space-evenly"
                ></Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                container
                justifyContent="flex-start"
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 12 }}
                  label="*Completion year is the year you completed that particular degree/diploma."
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                container
                justifyContent="flex-start"
              />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={2}
                sx={{ mt: -6, mb: -4 }}
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={1} md={1} lg={1} container>
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=""
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Submit"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={1}
                  md={1}
                  lg={1}
                  container
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=""
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={this.props.cancelForm}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getAlert,
  showNotification,
  getEducationByAluminiId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AluminiCourseDetails);
