export const SuggestionReportJson = {
  apiBaseURL:
    "/api/report/v1/suggestion-report?fromDate={fromDate}&toDate={toDate}&documentType=1",
  screenTitle: "Suggestion Report",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  marginTop: 3,
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-l",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-g",
    },
  ],
};
