import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { apiGet } from "../../../utils/api_service";
import endpoints from "../../../config/endpoints";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EmpNotice = ({ handleCloses, noticeList, getAlert }) => {
  const [department, setDepartment] = useState("");

  useEffect(() => {
    if (
      department === "" &&
      department !== null &&
      noticeList.noticeByEvent.length !== 0
    ) {
      if (noticeList.noticeByEvent[0].departmentIds !== null) {
        setDepartment(null);
        apiGet({
          url:
            endpoints.department +
            "/get-department-by-department-ids?departmentIds=" +
            noticeList.noticeByEvent[0].departmentIds,
        }).then(({ data, success }) => {
          if (!success) {
            getAlert({ message: "Something went wrong" });
          } else {
            const departmentList = data.map((departmentData) => {
              return departmentData.name;
            });
            setDepartment(departmentList.join());
          }
        });
      } else if (
        noticeList.noticeByEvent[0].departmentIds === null ||
        noticeList.noticeByEvent[0].departmentIds === ""
      ) {
        setDepartment(" - ");
      }
    }
    // label={noticeList.noticeByEvent.length!==0&&noticeList.noticeByEvent[0].departmentIds}
  });
  const handleClose = () => {
    handleCloses();
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Notice For Employee
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={1.3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Title : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        noticeList.noticeByEvent.length !== 0 &&
                        noticeList.noticeByEvent[0].title
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1.3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Description : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        noticeList.noticeByEvent.length !== 0 &&
                        noticeList.noticeByEvent[0].description
                      }
                    />
                  </Grid>
                </Grid>{" "}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={1.3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="From Date : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        noticeList.noticeByEvent.length !== 0 &&
                        noticeList.noticeByEvent[0].fromDate
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1.3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="To Date : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        noticeList.noticeByEvent.length !== 0 &&
                        noticeList.noticeByEvent[0].toDate
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={1.3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Department : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={10.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={department}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={1.3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Role : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={10.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        noticeList.noticeByEvent.length !== 0 &&
                        noticeList.noticeByEvent[0].noticeFor
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}></Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  noticeList: state.empNotice,
});

const mapDispatchToProps = {
  getAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmpNotice);
