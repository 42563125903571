import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  designation: [],
  isFetch: false,
};
let URL = endpoints.designation;
const designationSlice = createSlice({
  name: "designation",
  initialState,
  reducers: {
    designationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        designation: row,
        isFetch: true,
      };
    },
  },
});

export const { designationSuccess } = designationSlice.actions;

export default designationSlice.reducer;
//'?sort={"orderBy": "ASC"}'
export const getDesignation = () => async (dispatch) => {
  try {
    apiGet({
      url: URL+'?sort={"sortOrderNo": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
      
       const row= content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            sortOrderNo: data1.sortOrderNo,
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(designationSuccess({ row }));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
