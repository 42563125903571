import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiPost, apiGet } from "../../../utils/api_service";
const initialState = {
  timeTable: {},
  isFetch: false,
  isFetchList: false,
  timeTableList: [],
  timeTableDetail: {},
  isFetchDetail: false,
  timeTableDetailsList: [],
  isFetchDetailList: false,
  timeTableDetailForTeacher: [],
  isFetchDetailForTeacher: false,
  timeTableDetailForStudent: [],
  isFetchDetailForStudent: false,
  timeTableDetailsByTeacherId: [],
  isFetchDetailByTeacherId: false,
  timeTableDetailsByBatchId: [],
  isFetchDetailByBatchId: false,
  timeTableDetailsRoomAvalibile: [],
  isFetchDetailRoomAvalibile: false,

  classCoordinatorDivision: [],
  isFetchClassCoordinatorDivision: false,

  classCoordinatorYearDetails: [],
  isFetchClassCoordinatorYearDetails: false,

  classCoordinatorSemester: [],
  isFetchClassCoordinatorSemester: false,

  timeTableListClassCoordinator: [],
  isFetchListClassCoordinator: false,

  batcList: [],
  isFetchBatchList: false,

  subjectList: [],
  isFetchSubjectList: false,

  studentYearDetail: [],
  isFetchStudentYearDetail: false,

  teacherByDepartmentAccess: [],
  isFetchTeacherByDepartmentAccess: false,
};
let URL = endpoints.timeTableHeader;
let timeTableDetails = endpoints.timeTableDetails;
const timeTableSlice = createSlice({
  name: "timeTable",
  initialState,
  reducers: {
    timeTableSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        timeTable: data,
        isFetch: true,
      };
    },
    timeTableDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        timeTableDetail: data,
        isFetchDetail: true,
      };
    },
    timeTableDetailsForTeacherSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableDetailForTeacher: row,
        isFetchDetailForTeacher: true,
      };
    },
    timeTableDetailsForStudentSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableDetailForStudent: row,
        isFetchDetailForStudent: true,
      };
    },
    timeTableListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableList: row,
        isFetchList: true,
      };
    },

    timeTableListClassCoordinatorSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableListClassCoordinator: row,
        isFetchListClassCoordinator: true,
      };
    },
    timeTableDetailListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableDetailsList: row,
        isFetchDetailList: true,
      };
    },

    TimeTableDetailsByTeacherIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableDetailsByTeacherId: row,
        isFetchDetailByTeacherId: true,
      };
    },
    TimeTableDetailsByBatchIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableDetailsByBatchId: row,
        isFetchDetailByBatchId: true,
      };
    },
    TimeTableDetailsRoomAvalibile: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        timeTableDetailsRoomAvalibile: row,
        isFetchDetailRoomAvalibile: true,
      };
    },

    //
    classCoordinatoDivisionSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        classCoordinatorDivision: row,
        isFetchClassCoordinatorDivision: true,
      };
    },

    classCoordinatoYearDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        classCoordinatorYearDetails: row,
        isFetchClassCoordinatorYearDetails: true,
      };
    },

    classCoordinatoSemesterSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        classCoordinatorSemester: row,
        isFetchClassCoordinatorSemester: true,
      };
    },

    batchListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        batcList: row,
        isFetchBatchList: true,
      };
    },

    subjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectList: row,
        isFetchSubjectList: true,
      };
    },

    studentYearDetailSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentYearDetail: row,
        isFetchStudentYearDetail: true,
      };
    },

    resetTeacherSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        timeTableDetailsByBatchId: [],
        isFetchDetailByBatchId: false,
        timeTableDetailsByTeacherId: [],
        isFetchDetailByTeacherId: false,
      };
    },

    teacherByDepartmentAccessSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        teacherByDepartmentAccess: row,
        isFetchTeacherByDepartmentAccess: true,
      };
    },
  },
});

export const {
  timeTableSuccess,
  timeTableListSuccess,
  timeTableDetailsSuccess,
  timeTableDetailsForTeacherSuccess,
  timeTableDetailsForStudentSuccess,
  TimeTableDetailsByTeacherIdSuccess,
  TimeTableDetailsByBatchIdSuccess,
  TimeTableDetailsRoomAvalibile,
  timeTableDetailListSuccess,
  resetTeacherSuccess,
  classCoordinatoDivisionSuccess,
  classCoordinatoYearDetailsSuccess,
  classCoordinatoSemesterSuccess,
  timeTableListClassCoordinatorSuccess,
  batchListSuccess,
  subjectSuccess,
  studentYearDetailSuccess,
  teacherByDepartmentAccessSuccess,
} = timeTableSlice.actions;

export default timeTableSlice.reducer;

export const resetData = () => async (dispatch) => {
  try {
    dispatch(resetTeacherSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const saveTimeTableHeader =
  ({ dataToSave }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          const timeTable = data.data;

          dispatch(timeTableSuccess({ data: timeTable }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveTimeTableDetail =
  ({ dataToSave }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: "/api/acdmc/v1/time-table-detail/save-list",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          const timeTable = data.data;

          dispatch(timeTableDetailsSuccess({ data: timeTable }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getTimeTable =
  ({ semisterId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-semister-detail-division?semisterDetailId=" +
          semisterId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              fDTd: data1.fromDate + " - " + data1.toDate,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              division: data1.division.id,
              divisionName: data1.division.name,
              yearDetailId: data1.division.yearDetailId,
              yearDetailName:
                data1.division.getYearDetailId !== null
                  ? data1.division.getYearDetailId.year.programType.name +
                    " - " +
                    data1.division.getYearDetailId.programTitle.brName +
                    " - " +
                    data1.division.getYearDetailId.year.className
                  : "",
              programType:
                data1.division.getYearDetailId !== null
                  ? data1.division.getYearDetailId.year.programType.id
                  : "",
              semisterDetail: data1.semisterDetail.id,
              semisterDetailName: data1.semisterDetail.semister.name,
              remark: data1.remark,
              name: data1.remark,
              isActive: data1.isActive,
              userRegistration:
                data1.userRegistration !== null
                  ? data1.userRegistration.id
                  : "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(timeTableListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTimeTableDetailByHeaderId =
  ({ timeTableHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + timeTableHeaderId + "/get-time-table-detail",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            const shortName =
              data1.subject !== null ? data1.subject.shortName.split("~") : [];
            let bData = {
              index: index,
              id: data1.id,
              // yearDetailId: data1.batch.division.yearDetailId,
              batch: data1.batch.id,
              batchName:
                data1.batch.batchName + " (" + data1.batch.shortName + " )",
              batchShortName: data1.batch.shortName,
              teacherName: data1.userRegistration.id,
              lastName: data1.userRegistration.lastName,
              firstName: data1.userRegistration.firstName,
              fatherName: data1.userRegistration.fatherName,
              motherName: data1.userRegistration.motherName,
              middleName: data1.userRegistration.middleName,
              teacher:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.middleName +
                " " +
                data1.userRegistration.firstName +
                " (" +
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase() +
                " )",
              teacherShortName:
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase(),
              subjectName: data1.subjectDetail.id,
              subjectDetail:
                data1.subject !== null
                  ? shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase()
                  : "",
              subjectDetailName:
                data1.subject !== null
                  ? data1.subject.name +
                    " (" +
                    shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase() +
                    " )"
                  : "",
              departmentId: data1.department.id,
              departmentName: data1.department.name,

              roomNo: data1.rooms.id,
              roomsName: data1.rooms.name,

              floorNo: data1.rooms.floor.id,
              floorName: data1.rooms.floor.name,

              timeSlot: data1.timeSlot.id,
              timeSlotName:
                data1.timeSlot.fromTime + " - " + data1.timeSlot.toTime,

              day: data1.day,
            };
            row.push(bData);
            return data1;
          });
          dispatch(timeTableDetailListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTeacherTimeTable =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          timeTableDetails +
          "/for-teacher?userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            const shortName =
              data1.subject !== null ? data1.subject.shortName.split("~") : [];
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,

              batch: data1.batch.id,
              batchName:
                data1.batch.batchName + " (" + data1.batch.shortName + " )",
              batchShortName: data1.batch.shortName,
              departmentId: data1.department.id,
              departmentName: data1.department.name,

              roomNo: data1.rooms.id,
              roomsName: data1.rooms.name,

              floorNo: data1.rooms.floor.id,
              floorName: data1.rooms.floor.name,

              timeSlot: data1.timeSlot.id,
              timeSlotName:
                data1.timeSlot.fromTime + " - " + data1.timeSlot.toTime,

              teacherName: data1.userRegistration.id,
              lastName: data1.userRegistration.lastName,
              firstName: data1.userRegistration.firstName,
              fatherName: data1.userRegistration.fatherName,
              motherName: data1.userRegistration.motherName,
              middleName: data1.userRegistration.middleName,
              teacher:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName +
                " (" +
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase() +
                " )",
              teacherShortName:
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase(),
              day: data1.day,
              yearDetailName:
                data1.timeTableHeader.division.getYearDetailId.year.programType
                  .name +
                "-" +
                data1.timeTableHeader.division.getYearDetailId.programTitle
                  .brShortName +
                "-" +
                data1.timeTableHeader.division.getYearDetailId.year.className,
              divisionName: data1.timeTableHeader.division.name,
              subjectName: data1.subjectDetail.id,
              subjectDetailName:
                data1.subject !== null
                  ? data1.subject.name +
                    " (" +
                    shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase() +
                    " )"
                  : "",
              subjectDetail:
                data1.subject !== null
                  ? shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase()
                  : "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(timeTableDetailsForTeacherSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentTimeTable =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "-detail?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              fDTd: data1.fromDate + " - " + data1.toDate,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              division: data1.division.id,
              divisionName: data1.division.name,
              yearDetailId: data1.division.yearDetailId,
              yearDetailName:
                data1.division.getYearDetailId.year.programType.name +
                " - " +
                data1.division.getYearDetailId.programTitle.brName +
                " - " +
                data1.division.getYearDetailId.year.className,
              programType: data1.division.getYearDetailId.year.programType.id,
              semisterDetail: data1.semisterDetail.id,
              semisterDetailName: data1.semisterDetail.semister.name,
              remark: data1.remark,
              isActive: data1.isActive,
              timeTableDetails: data1.timeTableDetails.map(
                (timeTableDetailData, timeTableIndex) => {
                  const shortName =
                    timeTableDetailData.subject !== null
                      ? timeTableDetailData.subject.shortName.split("~")
                      : [];

                  let timeTableDetail = {
                    index: timeTableIndex + 1,
                    id: timeTableDetailData.id,

                    batch: timeTableDetailData.batch.id,
                    batchName:
                      timeTableDetailData.batch.batchName +
                      " (" +
                      timeTableDetailData.batch.shortName +
                      " )",
                    batchShortName: timeTableDetailData.batch.shortName,
                    departmentId: timeTableDetailData.department.id,
                    departmentName: timeTableDetailData.department.name,

                    roomNo: timeTableDetailData.rooms.id,
                    roomsName: timeTableDetailData.rooms.name,

                    floorNo: timeTableDetailData.rooms.floor.id,
                    floorName: timeTableDetailData.rooms.floor.name,

                    timeSlot: timeTableDetailData.timeSlot.id,
                    timeSlotName:
                      timeTableDetailData.timeSlot.fromTime +
                      " - " +
                      timeTableDetailData.timeSlot.toTime,

                    teacherName: timeTableDetailData.userRegistration.id,
                    lastName: timeTableDetailData.userRegistration.lastName,
                    firstName: timeTableDetailData.userRegistration.firstName,
                    fatherName: timeTableDetailData.userRegistration.fatherName,
                    motherName: timeTableDetailData.userRegistration.motherName,
                    middleName: timeTableDetailData.userRegistration.middleName,
                    teacher:
                      timeTableDetailData.userRegistration.lastName +
                      " " +
                      timeTableDetailData.userRegistration.firstName +
                      " " +
                      timeTableDetailData.userRegistration.middleName +
                      " (" +
                      timeTableDetailData.userRegistration.firstName
                        .charAt(0)
                        .toUpperCase() +
                      " . " +
                      timeTableDetailData.userRegistration.lastName
                        .charAt(0)
                        .toUpperCase() +
                      " )",
                    teacherShortName:
                      timeTableDetailData.userRegistration.firstName
                        .charAt(0)
                        .toUpperCase() +
                      " . " +
                      timeTableDetailData.userRegistration.lastName
                        .charAt(0)
                        .toUpperCase(),

                    day: timeTableDetailData.day,
                    subjectName: timeTableDetailData.subjectDetail.id,
                    subjectDetailName:
                      timeTableDetailData.subject !== null
                        ? timeTableDetailData.subject.name +
                          " (" +
                          shortName[1].toUpperCase() +
                          "-" +
                          timeTableDetailData.subjectDetail.schemeHeadId.shortName.toUpperCase() +
                          " )"
                        : "",
                    subjectDetail:
                      timeTableDetailData.subject !== null
                        ? shortName[1].toUpperCase() +
                          "-" +
                          timeTableDetailData.subjectDetail.schemeHeadId.shortName.toUpperCase()
                        : "",
                  };
                  return timeTableDetail;
                }
              ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(timeTableDetailsForStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTimeTableDetailsByTeacherId =
  ({ teacherId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: timeTableDetails + "/" + teacherId + "/by-teacher-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            const shortName =
              data1.subject !== null ? data1.subject.shortName.split("~") : [];
            let bData = {
              index: index,
              id: data1.id,
              division: data1.timeTableHeader.division.id,
              divisionName: data1.timeTableHeader.division.name,
              batchName:
                data1.batch.batchName + " (" + data1.batch.shortName + " )",
              batchShortName: data1.batch.shortName,
              subjectDetailName:
                data1.subject !== null
                  ? data1.subject.name +
                    " (" +
                    shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase() +
                    " )"
                  : "",
              floorNo: data1.rooms.floor.id,
              floorName: data1.rooms.floor.name,
              roomNo: data1.rooms.id,
              roomsName: data1.rooms.name,
              batch: data1.batch.id,

              departmentId: data1.department.id,
              departmentName: data1.department.name,
              teacherName: data1.userRegistration.id,
              lastName: data1.userRegistration.lastName,
              firstName: data1.userRegistration.firstName,
              fatherName: data1.userRegistration.fatherName,
              motherName: data1.userRegistration.motherName,
              middleName: data1.userRegistration.middleName,
              teacher:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName +
                " (" +
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase() +
                " )",
              teacherShortName:
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase(),

              fDTd:
                data1.timeTableHeader.fromDate +
                " - " +
                data1.timeTableHeader.toDate,
              fromDate: data1.timeTableHeader.fromDate,
              toDate: data1.timeTableHeader.toDate,

              yearDetailId: data1.timeTableHeader.division.yearDetailId,
              // yearDetailName:
              // data1.timeTableHeader.division.getYearDetailId.year.programType.name +
              // " - " +
              // data1.division.getYearDetailId.programTitle.brName +
              // " - " +
              // data1.division.getYearDetailId.year.className,
              semisterDetail: data1.timeTableHeader.semisterDetail.id,
              semisterDetailName:
                data1.timeTableHeader.semisterDetail.semister.name,
              remark: data1.timeTableHeader.remark,
              isActive: data1.isActive,

              id: data1.timeTableHeader.id,

              timeSlot: data1.timeSlot.id,
              timeSlotName:
                data1.timeSlot.fromTime + " - " + data1.timeSlot.toTime,

              day: data1.day,
              subjectName: data1.subjectDetail.id,

              subjectDetail:
                data1.subject !== null
                  ? shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase()
                  : "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(TimeTableDetailsByTeacherIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTimeTableDetailsByBatchId =
  ({ batchId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: timeTableDetails + "/" + batchId + "/by-batch-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            const shortName =
              data1.subject !== null ? data1.subject.shortName.split("~") : [];
            let bData = {
              index: index,
              id: data1.id,
              fDTd:
                data1.timeTableHeader.fromDate +
                " - " +
                data1.timeTableHeader.toDate,
              fromDate: data1.timeTableHeader.fromDate,
              toDate: data1.timeTableHeader.toDate,
              division: data1.timeTableHeader.division.id,
              divisionName: data1.timeTableHeader.division.name,
              yearDetailId: data1.timeTableHeader.division.yearDetailId,
              // yearDetailName:
              // data1.timeTableHeader.division.getYearDetailId.year.programType.name +
              // " - " +
              // data1.division.getYearDetailId.programTitle.brName +
              // " - " +
              // data1.division.getYearDetailId.year.className,
              semisterDetail: data1.timeTableHeader.semisterDetail.id,
              semisterDetailName:
                data1.timeTableHeader.semisterDetail.semister.name,
              remark: data1.timeTableHeader.remark,
              isActive: data1.isActive,

              id: data1.timeTableHeader.id,

              batch: data1.batch.id,
              batchName:
                data1.batch.batchName + " (" + data1.batch.shortName + " )",
              batchShortName: data1.batch.shortName,
              departmentId: data1.department.id,
              departmentName: data1.department.name,

              roomNo: data1.rooms.id,
              roomsName: data1.rooms.name,

              floorNo: data1.rooms.floor.id,
              floorName: data1.rooms.floor.name,

              timeSlot: data1.timeSlot.id,
              timeSlotName:
                data1.timeSlot.fromTime + " - " + data1.timeSlot.toTime,

              teacherName: data1.userRegistration.id,
              lastName: data1.userRegistration.lastName,
              firstName: data1.userRegistration.firstName,
              fatherName: data1.userRegistration.fatherName,
              motherName: data1.userRegistration.motherName,
              middleName: data1.userRegistration.middleName,
              teacher:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName +
                " (" +
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase() +
                " )",
              teacherShortName:
                data1.userRegistration.firstName.charAt(0).toUpperCase() +
                " . " +
                data1.userRegistration.lastName.charAt(0).toUpperCase(),

              day: data1.day,
              subjectName: data1.subjectDetail.id,
              subjectDetailName:
                data1.subject !== null
                  ? data1.subject.name +
                    " (" +
                    shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase() +
                    " )"
                  : "",
              subjectDetail:
                data1.subject !== null
                  ? shortName[1].toUpperCase() +
                    "-" +
                    data1.subjectDetail.schemeHeadId.shortName.toUpperCase()
                  : "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(TimeTableDetailsByBatchIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const checkRoomAvalibility =
  ({ roomId, dataToSave }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: timeTableDetails + "/check-room-availability?roomId=" + roomId,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              day: data1.day,
              timeSlotId: data1.timeSlotId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(TimeTableDetailsRoomAvalibile({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// year detail
// http://localhost:8088/api/acdmc/v1/time-table-header/get-division-for-class-coordinator?yearDetailId=5ca6de9a-829f-4c6e-a134-88adfb556381

export const getDivisionForClasscoordinator =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-division-for-class-coordinator?yearDetailId=" +
          yearDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(classCoordinatoDivisionSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/time-table-header/get-year-details-for-class-coordinator?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0
export const getClassCoordinatorYearDetails = () => async (dispatch) => {
  try {
    const success = apiGet({
      url: URL + "/get-year-details-for-class-coordinator",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name:
              data1.year.programType.name +
              " - " +
              data1.programTitle.brName +
              " - " +
              data1.year.className,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(classCoordinatoYearDetailsSuccess({ row }));
      }
      return success;
    });
    return success;
  } catch (e) {
    return console.error(e.message);
  }
};

// http://localhost:8088/api/acdmc/v1/time-table-header/get-semister-detail-for-class-coordinator?yearDetailId=5ca6de9a-829f-4c6e-a134-88adfb556381

export const getSemesterByYearDetailClassCoordinator =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-semister-detail-for-class-coordinator?yearDetailId=" +
          yearDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.semister.name,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(classCoordinatoSemesterSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/time-table-header/get-time-table-header-for-class-coordinator-by-semisterDetail-division-yearDetail?yearDetailId=5ca6de9a-829f-4c6e-a134-88adfb556381&semisterDetailId=e7c1de29-e6bd-4c3b-8fd8-6db6d9fb14b6&divisionId=2e9b5957-fdfb-4de7-ad43-6086abb77273

export const getTimeTableClassCoordinatorView =
  ({ yearDetailId, semisterId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-time-table-header-for-class-coordinator-by-semisterDetail-division-yearDetail?yearDetailId=" +
          yearDetailId +
          "&semisterDetailId=" +
          semisterId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              fDTd: data1.fromDate + " - " + data1.toDate,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              division: data1.division.id,
              divisionName: data1.division.name,
              yearDetailId: data1.division.yearDetailId,
              yearDetailName:
                data1.division.getYearDetailId !== null
                  ? data1.division.getYearDetailId.year.programType.name +
                    " - " +
                    data1.division.getYearDetailId.programTitle.brName +
                    " - " +
                    data1.division.getYearDetailId.year.className
                  : "",
              semisterDetail: data1.semisterDetail.id,
              semisterDetailName: data1.semisterDetail.semister.name,
              remark: data1.remark,
              name: data1.remark,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(timeTableListClassCoordinatorSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/time-table-header/get-batch-for-class-coordinator?semisterDetailId=e7c1de29-e6bd-4c3b-8fd8-6db6d9fb14b6&divisionId=05676336-8984-43ce-afaa-4cea7fd6b625

export const getBatchClassCoordinatorView =
  ({ semisterDetailId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-batch-for-class-coordinator?semisterDetailId=" +
          semisterDetailId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.batchName,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(batchListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/time-table-header/get-subject-detail-for-class-coordinator?semisterDetailId=e7c1de29-e6bd-4c3b-8fd8-6db6d9fb14b6&divisionId=05676336-8984-43ce-afaa-4cea7fd6b625&batchId=f2a940ff-c6b9-4a02-88e1-ccb6792596a7

export const getSubjectClassCoordinator =
  ({ semisterDetailId, divisionId, batchId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-detail-for-class-coordinator?semisterDetailId=" +
          semisterDetailId +
          "&divisionId=" +
          divisionId +
          "&batchId=" +
          batchId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.subject.name,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentYearDetail =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "-detail?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.division.getYearDetailId.programTitle.programType.name +
                " - " +
                data1.division.getYearDetailId.programTitle.brName +
                " - " +
                data1.division.getYearDetailId.year.className,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentYearDetailSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTeacherByDepartmentAccess = () => async (dispatch) => {
  try {
    const success = apiGet({
      url: timeTableDetails + "/get-users-present-in-time-table",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name:
              data1.lastName + " " + data1.firstName + " " + data1.middleName,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(teacherByDepartmentAccessSuccess({ row }));
      }
      return success;
    });
    return success;
  } catch (e) {
    return console.error(e.message);
  }
};
