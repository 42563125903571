import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet, apiGetWithoutHeader } from "../../utils/api_service";

const initialState = {
  outsideEmployeeFeedbackQuestions: [],
  isFetchEmployeeFeedbackQuestions: false,

  outsideStudentsFeedbackQuestions: [],
  isFetchStudentsFeedbackQuestions: false,
};
let getEmpDetailPreLogin = endpoints.getEmpDetailPreLogin;
let URL = endpoints.outsideEmployeeFeedback;
const outSideEmployeeFeedbackDetailsSlice = createSlice({
  name: "outSideEmployeeFeedbackDetails",
  initialState,
  reducers: {
    outsideEmployeeFeedbackQuestionsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        outsideEmployeeFeedbackQuestions: row,
        isFetchEmployeeFeedbackQuestions: true,
      };
    },

    outsideStudentsFeedbackQuestionsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        outsideStudentsFeedbackQuestions: row,
        isFetchStudentsFeedbackQuestions: true,
      };
    },
  },
});

export const {
  outsideEmployeeFeedbackQuestionsSuccess,
  outsideStudentsFeedbackQuestionsSuccess,
} = outSideEmployeeFeedbackDetailsSlice.actions;

export default outSideEmployeeFeedbackDetailsSlice.reducer;


export const getOutsideEmployeeFeedbackQuestions =
  ({ id, token }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url:
          getEmpDetailPreLogin +
          "?token=" +
          token +
          "&id=" +
          id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              question: data1.question,
              optionType: data1.optionType,
              answer: "",
              answerValid: 0,
              isActive: data1.isActive,
              feedbackHeadDetailsOptions: data1.feedbackHeadDetailsOptions,
            };
            row.push(bData);
            return data1;
          });
          dispatch(outsideEmployeeFeedbackQuestionsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutsideStudentFeedbackQuestions =
  ({ id, token }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
        getEmpDetailPreLogin +
          "?token=" +
          token +
          "&id=" +
          id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              question: data1.question,
              optionType: data1.optionType,
              answer: "",
              answerValid: 0,
              isActive: data1.isActive,
              feedbackHeadDetailsOptions: data1.feedbackHeadDetailsOptions,
            };
            row.push(bData);
            return data1;
          });
          dispatch(outsideStudentsFeedbackQuestionsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
