// import { Grid, Paper } from "@mui/material";

// const AlimuniBlock = () => {
//   return (
//     <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
//       <Grid container justifyContent="space-evenly" direction="row">
//       <Grid item sm={4} xs={4} md={4} lg={4}/>
//             <Grid item sm={4} xs={4} md={4} lg={4}>
//       You are blocked
//        </Grid>
//        <Grid item sm={4} xs={4} md={4} lg={4}/>
//       </Grid>
//     </Paper>
//   );
// };
// export default AlimuniBlock;
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import blocked from '../../Images/blocked.jpg'
const styles = (theme) => ({
  hello: {
    position: "absolute",
    left: "15.17%",
    right: "15.08%",
    top: "63.4%",
    bottom: "32.6%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "36px",
    lineheight: "40px",
    color: "#000000",
  },
  buttonC: {
    position: "absolute",
   
    left: '39.17%',
right: '39.17%',
top: '79.7%',
bottom: '14.8%',
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "18px",
    lineheight: "40px",
    color: "#FFFFFF",
  },
  helper: {
    position: "absolute",
    left: "9.58%",
    right: "9.5%",
    top: "70%",
    bottom: "22%",
    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: "normal",
    fontsize: "20px",
    lineheight: "40px",

    textAlign: "center",

    color: "#666666",
  },
  title: {
    position: "absolute",
    left: "40%",
    right: "50",
    top: "30%",

    fontfamily: "Roboto",
    fontstyle: "normal",
    fontweight: 500,
    fontsize: "55px",
    lineheight: "40px",
    color: "#000000",
  },
})
const AlimuniBlock = ({ classes }) => {
  return (
    <div align="center">
      <br></br>
      <img alt='' heigth='10%'width='20%' src={blocked} />
      <Grid className={classes.title}>
        <h1> You are blocked</h1>
      </Grid>
      {/* <Grid className={classes.hello}>
        <h3>Something went wrong. We'll clean up and try again.</h3>
      </Grid>
      <Grid className={classes.helper}>
        <p align="center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip.
        </p>
      </Grid> */}
   
    </div>
  );
};
export default withStyles(styles)(AlimuniBlock);
