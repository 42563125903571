import
{
    Checkbox,
    FormControlLabel,
    FormGroup, Grid,
    Paper
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { showNotification } from "../../Pages/Landing/Landing.slice";

import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import
{
    getSchemeHead, getSubjectFromDeptSemester
} from "../SubjectSelection/SubjectSelection.slice";
import { LabelCompo } from "../../components/Comman/Label";
class SubjectSelectionViewMatrix extends Component
{
    constructor( props )
    {
        super( props );
        this.state = {
            matrixData: [],
            matrixError: false,
        };
    }

    componentDidMount()
    {
        const { details } = this.props;
        console.log( details )
    }

    componentWillReceiveProps( nextProps )
    {
        const { subjectList } = this.props;
        if (
            ( subjectList?.subjectSelected !== nextProps.subjectList.subjectSelected &&
                nextProps.subjectList.isFetchSchemeHead ) ||
            ( subjectList?.schemeHead !== nextProps.subjectList.schemeHead &&
                nextProps.subjectList.isFetch )
        )
        {
            this.createMatrixData(
                nextProps.subjectList?.subjectSelected,
                nextProps.subjectList?.schemeHead
            );
        }
    }

    createMatrixData( subjectList, schemeHead )
    {
        const subjectMatrix = subjectList.map( ( subjectData ) =>
        {
            const schemeHeadList = schemeHead.map( ( schemeHeadData ) =>
            {
                if ( subjectData.shortName === "comp" )
                {
                    schemeHeadData = {
                        ...schemeHeadData,
                        value: true,
                    };
                    return schemeHeadData;
                } else
                {
                    schemeHeadData = {
                        ...schemeHeadData,
                        value: false,
                    };
                    return schemeHeadData;
                }
            } );
            subjectData = {
                ...subjectData,
                value: subjectData.shortName === "comp" ? true : false,
                schemeHeadList: schemeHeadList,
            };
            return subjectData;
        } );

        this.setState( {
            matrixData: subjectMatrix,
        } );
    }

    onRowHeaderChange = ( subjectId, e ) =>
    {
        console.log( subjectId );
        let { matrixData } = this.state;
        const subjectMatrix = matrixData.map( ( subjectData ) =>
        {
            if ( subjectData.id === subjectId )
            {
                const schemeHeadList = subjectData.schemeHeadList.map(
                    ( schemeHeadData ) =>
                    {
                        const filteredList =
                            subjectData.subjectDetails.length !== 0
                                ? subjectData.subjectDetails.filter(
                                    ( item ) => item.schemeHeadId.id === schemeHeadData.id
                                )
                                : [];
                        schemeHeadData = {
                            ...schemeHeadData,
                            value: filteredList.length !== 0 ? e.target.checked : false,
                        };
                        return schemeHeadData;
                    }
                );
                subjectData = {
                    ...subjectData,
                    value: e.target.checked,
                    schemeHeadList: schemeHeadList,
                };
                return subjectData;
            }

            return subjectData;
        } );
        this.setState( { matrixData: subjectMatrix } );
    };


    render()
    {
        const { matrixData } = this.state;
        const { onCancel } = this.props;

        return (
            <>
                <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={1}
                        container
                        justifyContent="left"
                    >
                        <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 22 }}
                                label=" Selected subjects"
                            />
                        </Grid>

                    </Grid>

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                    >

                        <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 18 }}
                                label={"Student Name : " + this.props.details.name}
                            />
                        </Grid>
                        <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 18 }}
                                label={"Roll No: " + this.props.details.rollNo}
                            />
                        </Grid>
                    </Grid>
                    <Grid sx={{ marginTop: 2 }} xs={12} sm={12} md={12} />
                    {
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 600 }}
                                aria-label="simple table"
                                size="small"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Subject</TableCell>
                                        {this.props.subjectList?.schemeHeadView.map(
                                            ( columnName, index ) =>
                                            {
                                                return (
                                                    <TableCell align="center">
                                                        {columnName.name}
                                                    </TableCell>
                                                );
                                            }
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.subjectList?.subjectView.map( ( matrixDataRow, indexRow ) =>
                                    {
                                        return (
                                            <TableRow>
                                                <TableCell component="td" scope="row" sx={{ width: 600 }}>
                                                    {matrixDataRow.subjectName}
                                                </TableCell>
                                                {this.props.subjectList?.schemeHeadView.map(
                                                    ( columnName, index ) =>
                                                    {
                                                        return this.props.subjectList?.schemeSubject.map(
                                                            ( subjectApplied, index ) =>
                                                            {
                                                                if (
                                                                    subjectApplied.schemeHeadId === columnName.id && subjectApplied.subjectId === matrixDataRow.subjectId
                                                                )
                                                                {
                                                                    return (

                                                                        <TableCell align="center" >
                                                                            <FormGroup>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            name={"Course" + index}

                                                                                            checked={true}
                                                                                            disabled={true}
                                                                                            id={"Course" + index}
                                                                                        />
                                                                                    }
                                                                                    label={""}
                                                                                    labelPlacement="bottom"
                                                                                />
                                                                            </FormGroup>
                                                                        </TableCell>
                                                                    );
                                                                }

                                                            } )

                                                    }
                                                )}

                                            </TableRow>
                                        );
                                    } )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        m={2}
                        rowSpacing={1}
                        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                        container
                        justifyContent="right"
                    >
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={1}
                            lg={1}
                            container
                            justifyContent="space-evenly"
                        >
                            <ButtonCompo
                                size="medium"
                                type="button"
                                variant="outlined"
                                name=" Cancel"
                                fullWidth={true}
                                onClick={this.props.onCancel}
                            />
                        </Grid>

                    </Grid>
                </Paper>
            </>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    subjectList: state.subjectSelection,
} );

const mapDispatchToProps = {
    getSubjectFromDeptSemester,
    getSchemeHead,
    showNotification,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)( SubjectSelectionViewMatrix );
