import endpoint from "../config/endpoints";
export const committeeHandleJson = {
  apiBaseURL: endpoint.committeeHandle,
  deleteUrl: endpoint.committeeHandleDoc,
  screenTitle: "Committee Handle",
  showAddButton: true,
  baseIdColumn: "id",
  DocumentUpload: "employeeCommitteesHandleDocuments",
  searchList: [
    {
      label: "List Of Committees",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "listOfCommittee",
      dataKey: "committeeName",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Activity Name",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "activity",
      dataKey: "employeeActivity",
      isMandatory: true,
      isRootLevelKey: false,
    },
  ],
  fieldMeta: [
    {
      label: "List Of Committees",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      anyOne: "department",
      msg: "Committee or department.",
      masterName: "listOfCommittee",
      dataKey: "committeeName",
      isMandatory: false,
      isRootLevelKey: true,
    },
    {
      label: "Department",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      anyOne: "committeeName",
      msg: "Department or Committee",
      masterName: "departmentMaster",
      dataKey: "department",
      isRootLevelKey: false,
      isMandatory: false,
    },
    {
      label: "Activity Name",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "employeeActivity",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Description Of Activity",
      controlType: "textarea",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "description",
      isMandatory: false,
    },

    {
      label: "Date Of Activity",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "No Of Participants",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "noParticipant",
      isMandatory: false,
    },
    {
      label: "Level",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "LevelMaster",
      dataKey: "level",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "LevelMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "levelData",
      tableDataKey: "level",
      open: "other",
      error: "Level",
    },
  ],
};
