import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../components/Loading1";
import AutoComplete from "../../../components/Comman/AutoComplete";
import SearchStudAddCompanyList from "../../../components/SearchTable";
import { deleteFileData } from "../../UploadFile/file.slice";
import {
  getInterestedStudById,
  getInterestedStudentById,
  resetValues,
} from "../ListOfCompany/PlacementCompanyList.slice";
import StudentAddInternship from "./StudentAddPlacement";
import { set } from "lodash";

const columnExtensions = [
  { name: "index", title: "Sr. No.", width: 70 },
  { title: "Company Name", name: "companyName", width: 250 },
  { title: "Duration", name: "durationFromDate", width: 150 },
  { title: "Designation", name: "designation", width: 200 },
  { title: "Package", name: "stipend", width: 100 },
  { name: "action", title: "Action", width: 130 },
];

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    title: "Company Name",
    name: "companyName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Joining Date",
    name: "durationFromDate",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Designation",
    name: "designation",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Package(₹ Lakh per annum )",
    name: "placementPackage",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "status",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];

const rows = [
  {
    index: 1,
    studentName: "Ram",
  },
  {
    index: 2,
    studentName: "Sham",
  },
  {
    index: 3,
    studentName: "Raju",
  },
];

let newInstData;
const StudentDeclareInternshipList = ({
  onCancel,
  companyData,
  getInterestedStudentById,
  getAlert,
  getIntrestedStudListById,
  resetValues,
  deleteFileData,
  getInterestedStudById,
  resetReducList,
}) => {
  let navigate = useNavigate();
  const [updateStudInfo, setUpdateStudInfo] = useState(false);
  const [openAddStudent, setOpenAddStudent] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [interestedStudent, setInterestedStudent] = useState(true);
  const [studData, setStudData] = useState({});
  const [id1, setId] = useState(4);
  const [snakMsg, setSnakMsg] = useState("");
  const [openSnak, setOpenSnak] = useState(false);
  const [role, setRole] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [placementStatus, setPlacementStatus] = useState("");
  const [placementStatusList, setPlacementStatusList] = useState([
    {
      id: "1",
      name: "Applied",
    },
    {
      id: "0",
      name: "Declared",
    },
    {
      id: "2",
      name: "Off Campus",
    },
  ]);

  const rowDetails = (data) => {
    // this.setState({
    // //   accountFees: false,
    // //   studentFeesDet: true,
    // //   feesCollection: false,
    // //   paymentDetailsData: data,
    // });
  };

  const rowUpdateStudList = (data) => {
    console.log("Open Update Info" + data);
    setUpdateStudInfo(true);
    setInterestedStudent(false);
  };

  const editStudentInfo = (data) => {
    getInterestedStudById({ id: data.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    console.log(companyData);
    console.log(data);
    setStudData(data);
    setUpdateStudInfo(true);
    setInterestedStudent(false);
  };

  const openDeclareForm = () => {
    // resetSaveDeclare()
    console.log("Open Update Info");
    setUpdateStudInfo(true);
    setInterestedStudent(false);
    // window.location.replace("/onlineappprocess");
  };

  const UpdateInfoData = () => {
    console.log("Open Interested Student");
    setUpdateStudInfo(false);
    setStudData({});
    setInterestedStudent(true);
    resetValues();
    deleteFileData();
    const parentChildId = localStorage.getItem("parentChildId");
    getInterestedStudentById({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
      status: 7,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    console.log(getIntrestedStudListById?.getInterestedStudentByIdStatus);
    console.log(getIntrestedStudListById);
    setShowLoader(true);
    const parentChildId = localStorage.getItem("parentChildId");
    getInterestedStudentById({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
      status: 7,
    }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
      let role = localStorage.getItem("role");
      setRole(role);
    });
    resetReducList();
    //use the above internshipDeclareId to pass it to the intrested and not interested student api also send the status
    // 1 for interseted and 0 for not interested (API is the same only status wil change accordingly)
  }, []);
  const getFilteredTableData = () => {
    return getIntrestedStudListById?.getInterestedStudentByIdStatus.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["companyName"] &&
            currentRow["companyName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["placementPackage"] &&
            currentRow["placementPackage"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["durationFromDate"] &&
            currentRow["durationFromDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["designation"] &&
            currentRow["designation"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  const ChangeHandlerSearch = (name, newValue) => {
    setShowLoader(true);
    const parentChildId = localStorage.getItem("parentChildId");
    getInterestedStudentById({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
      status: newValue,
    }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
    setPlacementStatus(newValue);
  };
  return (
    <>
      {showLoader && <Loading1 />}
      <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        <TitleAndBreadCrum title="Placement List" />
      </Paper>
      <br />
      {interestedStudent && (
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid container xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={6} md={6}>
                <SearchStudAddCompanyList onSearch={onSearch} />
              </Grid>
              {role !== "parent" && (
                <Grid item xs={2} md={6} container justifyContent="flex-end">
                  <MyComponentWithIconProps
                    statusImage={AddCircleIcon}
                    color="primary"
                    fontSize="large"
                    title="Add Placement"
                    onClick={openDeclareForm}
                  />
                </Grid>
              )}
            </Grid>
          </Paper>
          <br />
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              columnSpacing={3}
              xs={12}
              sm={4}
              md={4}
              // sx={{ marginTop: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Placement Status"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={placementStatus}
                  name={"placementStatus"}
                  options={placementStatusList}
                  onChange={ChangeHandlerSearch}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: "#808080" }}
                label="Note: To upload Offer letter or Appointment letter or any other proof, click on edit icon against that company."
              />
            </Grid>
            <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
              {/* <StudentDeclareInternshipListTable
                columns={columns}
                showEditButton={true}
                showDeleteButton={false}
                tableData={getIntrestedStudListById?.getInterestedStudentByIdStatus}
                rowStudDetails={rowDetails}
                rowUpdateList={rowUpdateStudList}
                rowEdit={editStudentInfo}
                columnExtensions={columnExtensions}
              /> */}
              <DynamicTable
                data={getFilteredTableData()}
                tableHead={columns}
                showPegination={false}
                showEditButton={true}
                showHeadEdit={true}
                rowEdit={editStudentInfo}
                isActionColActive={role === "parent" ? false : true}
              />
            </Grid>
          </Paper>
        </div>
      )}
      {updateStudInfo && (
        <StudentAddInternship goBack={UpdateInfoData} studentData={studData} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getIntrestedStudListById: state.companyPlacementList,
});

const mapDispatchToProps = {
  deleteFileData,
  getInterestedStudentById,
  getAlert,
  resetReducList,
  getInterestedStudById,
  resetValues,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentDeclareInternshipList);
