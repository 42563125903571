import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet, apiPost } from "../../../utils/api_service";
const initialState = {
  otherEducation: [],
  isFetch: false,
  studentProfile: {},
  isSave: false,
  applicant: {},
  isFetchApplicant: false,

  country: [],
  isFetchCountry: false,
  

  state: [],
  isFetchState: false,
  
  city: [],
  isFetchCity:false


};
let URL = endpoints.educationalDetails;
let StudentUrl = endpoints.educationalDetails;
let applicantUrl = endpoints.alumniRegister;
let countryUrl = endpoints.countryUrl;
let stateUrl = endpoints.stateUrl;
let cityUrl = endpoints.cityUrl;
const studentFillProfileSlice = createSlice({
  name: "studentFillProfileSlice",
  initialState,
  reducers: {
    otherEducationalSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        otherEducation: row,
        isFetch: true,
      };
    },

    studentProfileSuccess: (state = cloneDeep(initialState), action) => {
      const { student } = action.payload;
      return {
        ...state,
        student: student,
        isSave: true,
      };
    },

    applicantSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        applicant: data,
        isSave: true,
      };
    },


    countrySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        country: row,
        isFetchCountry: true,
      };
    },

    stateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        state: row,
        isFetchState: true,
      };
    },


    citySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        city: row,
        isFetchCity: true,
      };
    },

  },
});

export const {
  otherEducationalSuccess,
  studentProfileSuccess,
  applicantSuccess,
  countrySuccess,
  stateSuccess,
  citySuccess
} = studentFillProfileSlice.actions;

export default studentFillProfileSlice.reducer;

export const getEducationByAluminiId =
  ({ id = "" }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-alumni-registration?id=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              institute: data1.institute,
              programTitle: data1.programTitle,
              location: data1.location,
              period: data1.period,
              instituteId: data1.instituteId,
              programTitleId: data1.programTitleId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(otherEducationalSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveStudentFillProfile =
  ({ studentData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: applicantUrl,
        postBody: studentData,
      }).then(({ data, success }) => {
        if (success) {
          const studentData = data.data;
          localStorage.setItem("person", studentData.firstName);
          localStorage.setItem("profilePic",studentData.profilePic);
          localStorage.setItem("aluminiStatus", studentData.status);
          dispatch(applicantSuccess({ data: studentData }));
          return success;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

  export const getAlumniRegistration = () => async (dispatch) => {
    try {
      const response = apiGet({
        url: applicantUrl + "/by-user-id",
      }).then(({ data, success }) => {
        if (success) {
          if (data.data !== null) {
            const studentData = data.data;
            localStorage.setItem("person", studentData.firstName);
            localStorage.setItem("profilePic", studentData.profilePic);
            localStorage.setItem("aluminiStatus", studentData.status);
            dispatch(applicantSuccess({ data: studentData }));
            return success;
          }
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };


  export const getCountry = () => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: countryUrl,
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( countrySuccess( { row } ) );
      }
      return success;
    } );
    return success;
  } catch ( e )
  {
    return console.error( e.message );
  }
};

export const getState = () => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: stateUrl,
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content }  = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            countryId:data1.countryId
          };
          row.push( bData );
          return data1;
        } );
        dispatch( stateSuccess( { row } ) );
      }
      return success;
    } );
    return success;
  } catch ( e )
  {
    return console.error( e.message );
  }
};


export const getCity = () => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: cityUrl,
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content }  = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            stateId:data1.stateId,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( citySuccess( { row } ) );
      }
      return success;
    } );
    return success;
  } catch ( e )
  {
    return console.error( e.message );
  }
};

