import React from "react";
import { connect } from "react-redux";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
const StudentAllocationTable = ({
  isActionColActive,
  onSelectionChange,
  selection,
  rows,
}) => {
  const columns = [
    { name: "index", title: "Sr. No.", minWidth: 90 },
    {
      name: "firstName",
      title: "Student Name",
      minWidth: 250,
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,
    },
    { name: "division", title: "Division", minWidth: 100 },
    { name: "rollNo", title: "Roll No", minWidth: 100 },
  ];
  const rowPrint = (row) => {};
  return (
    <DynamicSelectTable
      rows={rows}
      columns={columns}
      selection={selection}
      isActionColActive={isActionColActive}
      onSelectionChange={onSelectionChange}
    />
  );
};

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAllocationTable);
