import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: "#1975d2",
      white: '#fff',
      success:" #00c853",
      lighBlue:'#CFE9ED',
      smokeGrey:'#f8f8f8',
     black:'#625f6e'
    },
    secondary:{
      main: "#008000",
    }
    
  },
  typography: {
    "fontFamily": `"Poppins", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    button: {
      textTransform: 'none'
    }
   },
  components: {
    // Name of the component
    MuiDataGrid: {
      styleOverrides: {
        // Name of the slot
        main: {
          // Some CSS
        },
        columnHeaderTitle: {
          color: '#fff',
          fontSize:'1.2em',
          fontFamily:'Poppins'
        }
      },
    },
  },
});

export default theme