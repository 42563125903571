export const listOfInternshipDeclareAddStudentJson = {
  screenTitle: "List Of Internship",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "",
  notes:
    "Note: To upload Offer letter or Appointment letter or any other proof, click on edit icon against that company.",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getAcadamicYear: true,
  getListId: "acadamicYear,yearDetail",
  fieldMeta: [
    {
      label: "Internship Status",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "internshipStatusMaster",
      dataKey: "internshipStatus",
      isMandatory: false,
      getListId: "internshipStatus",
    },
    // {
    //     label: "From Date",
    //     controlType: "datepicker",
    //     placeHolder: "",
    //     md: 4,
    //     lg: 4,
    //     sm: 4,
    //     xs: 12,
    //     dataKey: "fromDate",
    //     isMAxDate: false,
    //     maxDate: new Date(),
    //     isMinDate: false,
    //     minDate: new Date(),
    //     isMandatory: true,
    // },
    // {
    //     label: "To Date",
    //     controlType: "datepicker",
    //     placeHolder: "",
    //     md: 4,
    //     lg: 4,
    //     sm: 4,
    //     xs: 12,
    //     dataKey: "toDate",
    //     isMAxDate: false,
    //     maxDate: new Date(),
    //     isMinDate: true,
    //     minDate: new Date(),
    //     isMandatory: true,
    // },
  ],
};
