import endpoint from "../config/endpoints";

export const TeachingPlanJson = {
  apiBaseURL: endpoint.teachingPlan,
  screenTitle: "Teaching Plan",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  fieldMetaForTable: [

    // {
    //   label: "Academic Year",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "acadamicMaster",
    //   getListId: 'acadamicYear,yearDetail',
    //   dataKey: "acadamicYear",
    //   isMandatory: true,
    // },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "yearMaster",
      getListId: 'yearDetail,acadamicYear',
      dataKey: "yearDetail",
      isMandatory: true,
    },
    {
      label: "Semester Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "semisterMaster",
      getListFrom: 'acadamicYear,yearDetail',
      dataKey: "semister",
      isMandatory: true,
    },
  ],
  fieldMeta: [
    {
      label: "Program Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "prograTypeMaster",
      getListId: 'programType',
      dataKey: "programType",
      isMandatory: true,
    },
    {
      label: "Program Title",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "programTitleMaster",
      getListId: 'programTitle',
      dataKey: "programTitle",
      isMandatory: true,
    },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearMaster",
      getListId: 'yearDetail',
      dataKey: "yearDetail",
      isMandatory: true,
    },
    {
      label: "Semester Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "semisterMaster",
      getListId: 'semister,department',
      dataKey: "semister",
      isMandatory: true,
    },
    {
      label: "Department",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "DepartmentMaster",
      getListId: 'department',
      dataKey: "department",
      isMandatory: true,
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subjectMaster",
      getListId: 'subject',
      dataKey: "subject",
      isMandatory: true,
    },
    {
      label: "Subject Scheme",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subjectSchemeMaster",
      dataKey: "scheme",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMAxDate: false,
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      "label": "Topic Tought",
      "controlType": "textarea",
      "placeHolder": "",
      "md": 12,
      "lg": 12,
      "sm": 12,
      "xs": 12,
      "dataKey": "topicTought",
      "isMandatory": true
    },
    {
      "label": "Remark",
      "controlType": "textarea",
      "placeHolder": "",
      "md": 12,
      "lg": 12,
      "sm": 12,
      "xs": 12,
      "dataKey": "remark",
      "isMandatory": false
    },
  ],
};

