import { apiPost } from "../utils/api_service";
import endpoints from "../config/endpoints";
import { getAlert } from "../CommonActions/alert.slice";
export const FilterSave = () => {
  const divisionIds = localStorage.getItem("divisionIds");
  const semisterDetailId = localStorage.getItem("semisterDetailId");
  const yearDetailId = localStorage.getItem("yearDetailId");
  const divisionId = localStorage.getItem("divisionId");
  const ay = localStorage.getItem("acadamicYearId");
  const departmentId = localStorage.getItem("departmentId");
  const filterData = {
    academicYearId: ay ? ay : "",
    semisterDetailId: semisterDetailId ? semisterDetailId : "",
    yearDetailId: yearDetailId ? yearDetailId : "",
    divisionId: divisionId ? divisionId : "",
    divisionIds: divisionIds ? divisionIds : "",
    departmentId: departmentId ? departmentId : "",
  };

  apiPost({
    url: endpoints.filterTable,
    postBody: filterData,
  }).then(({ data, success }) => {
    if (!success) {
      getAlert({ message: "Something went wrong" });
    } else {
      // localStorage.setItem("semisterDetailId", data.data.filterObject.semister);
      // localStorage.setItem("yearDetailId", data.data.filterObject.yearDetail);
      // localStorage.setItem("divisionId", data.data.filterObject.division);
      // localStorage.setItem("divisionIds", data.data.filterObject.yearDetail);
      // localStorage.setItem("acadamicYearId", data.data.filterObject.division);
    }
  });
};
