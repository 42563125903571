import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { showNotification } from "../../Pages/Landing/Landing.slice";

import endpoints from "../../config/endpoints";
import { getAlert } from "../../CommonActions/alert.slice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { getFeeStructureHeader } from "../Masters/Admission/FeeStructureHead/feeStructureHead.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class AddDebitTransaction extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    debitAmount: "",
    transactionDate: "",
    rowListDetails: [],
    formErrors: [],
    type: "0",
    number: "",
    bankName: "",
    remark: "",
    debitError: "",
    debitAmountValidation: "",
  };

  componentDidMount() {
    console.log(this.props.studentData);
    if (
      Object.keys(this.props.applicantAdmissionList.transactionById).length !==
      0
    ) {
      const DOBArr =
        this.props.applicantAdmissionList.transactionById.transactionDate ==
        null
          ? ""
          : this.props.applicantAdmissionList.transactionById.transactionDate.split(
              "-"
            );
      const DOB =
        DOBArr === "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);
      this.setState({
        transactionDate: DOB,
        debitAmount: this.props.applicantAdmissionList.transactionById.amt,
      });
    }
  }
  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = (e) => {
    const { debitAmount, transactionDate, type, number, bankName, remark } =
      this.state;

    let feesToSave = {
      amount: debitAmount,
      date: transactionDate,
      type: type,
      number: number,
      bankName: bankName,
      remark: remark,
    };

    console.log(feesToSave);
    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Do you want submit data ?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          const dataToSave = {
            ...this.props.applicantAdmissionList.transactionById,
            amt: debitAmount,
          };
          apiPost({
            url: endpoints.studentTransaction + "/update",
            postBody: dataToSave,
          }).then(({ data, success }) => {
            if (!success) {
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.props.showNotification({
                msg: "Data saved successfully",
              });
              this.setState({
                showTable: false,
              });
              this.props.onClose();
              window.location.replace("/governmentPayment");
            }
          });
        }
      });
    }
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { debitAmount, debitAmountValidation } = this.state;
    let formErrors = {};
    let formIsValid = true;
    console.log(debitAmountValidation);
    if (debitAmount === "") {
      formIsValid = false;
      formErrors["debitAmountError"] = myConstClass.debitAmountMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleClose = () => {
    this.props.onClose();
  };

  changedHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const { debitAmount } = this.state;
    const { debitAmountError } = this.state.formErrors;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.open}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Debit Amount
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 1 }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Student Name : "
                    />{" "}
                    {this.props.studentData.applicantName}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Total Fees : "
                    />{" "}
                    {this.props.studentData.totalFees}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 1 }}
                  columnSpacing={2}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    columnSpacing={2}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Payment"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="debitAmount"
                        id="debitAmount"
                        value={debitAmount}
                        onChange={this.changeHandler}
                        fullWidth
                        error={debitAmountError ? true : false}
                        helperText={debitAmountError ? debitAmountError : " "}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                  ></Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Submit"
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feesStructureHeaderList: state.feesStructureHead,
  applicantAdmissionList: state.applicantAdmission,
});
const mapDispatchToProps = {
  getFeeStructureHeader,
  getAlert,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDebitTransaction);
