import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import {FeesTitleMasterJson} from '../../../../DynamicFormsJson/FeesTitle.js';
import { FeesTitleColumns } from "../../../../tableColumns/table-columns";
import {getFeesTitile} from "./feesTitle.slice"
import {getAlert}from "../../../../CommonActions/alert.slice";

class FeesTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: { programMaster: this.props.programMaster?.program, instituteList: this.props.instituteList?.institute }
        }
    }

    componentDidMount() {
        this.props.getFeesTitile().then((response)=>{
            if(!response){
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
    }

    render() {

        const {feesTitleList}= this.props
        // const { dynamicMasterData } = this.state;
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={FeesTitleMasterJson.screenTitle}
                        fieldMeta={FeesTitleMasterJson.fieldMeta}
                        showPdfDownload={FeesTitleMasterJson.showPdfDownload}
                        showExcelDownload={FeesTitleMasterJson.showExcelDownload}
                        pdfFileName={FeesTitleMasterJson.pdfFileName}
                        excelFileName={FeesTitleMasterJson.excelFileName}
                        showAddButton={FeesTitleMasterJson.showAddButton}
                        tableColumnsToFilter={FeesTitleMasterJson.tableColumnsToFilter}
                       // dynamicMasterData={dynamicMasterData}
                        tableColumns={FeesTitleColumns}
                        tableData={feesTitleList.feesTitle}
                        getTableData={this.props.getFeesTitile}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={feesTitleList.isFetch}
                        baseIdColumn={FeesTitleMasterJson.baseIdColumn}
                        apiBaseURL={FeesTitleMasterJson.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({

    feesTitleList: state.feesTitle
});
const mapDispatchToProps = {
    getFeesTitile,
    getAlert
}
export default connect(mapStateToProps, mapDispatchToProps)(FeesTitle);