export const AlumniReportJson = {
  apiBaseURL:
    "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
  screenTitle: "Alumna Report",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  marginTop: 3,
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-l",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-g",
      isMandatory: true,
      isMAxDate: true,
      maxDate: new Date()
    },
    {
      label: "Alumnae Registration",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "alumniMaster",
      dataKey: "alumniId",
      isMandatory: true,
    },
    {
      label: "Type of Enagagement",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "engagementMaster",
      dataKey: "engagementType",
      isMandatory: true,
    },
    {
      label: "Status",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "statusMaster",
      dataKey: "status",
      isMandatory: true,
    },
  ],
};
