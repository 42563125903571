import endpoint from "../config/endpoints";

export const division = {
  apiBaseURL: endpoint.division,
  screenTitle: "Division",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Division",
  showExcelDownload: true,
  excelFileName: "Division",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Year Details",
      columnKeyName: "yearDetails",
      isChecked: true,
    },
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Year Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "yearDetail",
      isRootLevelKey: true,
      isMandatory: true,
    }

  ],
  fieldMeta: [
    {
      label: "Year Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "yearDetailId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
  ],
};
