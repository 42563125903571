import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { ExaminationReportJson } from "../../DynamicFormsJson/ExaminationReport";
import { getAlert } from "../../CommonActions/alert.slice";
import {
  getExamFormDeclare,
  getExamdeclareGetSubject,
  getStudentStatusList,
  getExamScheduleDate,
} from "../ExamFormDeclaration/examform.slice";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { getRoomByScheduleDate } from "../Masters/TimeTable/room/room.slice";

class ExaminationReport extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        examDeclareMaster: this.props.examDeclareList?.examFormDeclare,
        subjectMaster: this.props.subjectsList.subjectBysemisterDetail,
        semesterDetailMaster: this.props.rollNoData?.semisterDetailsList,
        yearDetailMaster: this.props.internshipDeclarationList?.yearDetails,
        examFormApplyStudentMaster:
          this.props.examDeclareList?.studentListStatus,
        scheduleDateMaster: this.props.examDeclareList?.scheduleDateList,
        roomNameMaster: this.props.roomList?.roomByDate,
        //
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.examDeclareList !== nextProps.examDeclareList) {
      if (
        this.props.examDeclareList.examFormDeclare !==
        nextProps.examDeclareList.examFormDeclare
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            examDeclareMaster: nextProps.examDeclareList.examFormDeclare,
          },
        });
      }
      if (
        this.props.examDeclareList.scheduleDateList !==
        nextProps.examDeclareList.scheduleDateList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            scheduleDateMaster: nextProps.examDeclareList.scheduleDateList,
          },
        });
      }
      if (
        this.props.examDeclareList.examDeclareSemesterDetail !==
        nextProps.examDeclareList.examDeclareSemesterDetail
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster:
              nextProps.examDeclareList.examDeclareSemesterDetail,
          },
        });
      }
      if (
        this.props.examDeclareList.examDeclareGetSubject !==
        nextProps.examDeclareList.examDeclareGetSubject
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster:
              nextProps.examDeclareList.examDeclareGetSubject.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
      if (
        this.props.examDeclareList.studentListStatus !==
        nextProps.examDeclareList.studentListStatus
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            examFormApplyStudentMaster:
              nextProps.examDeclareList.studentListStatus,
          },
        });
      }
    }
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster:
              nextProps.internshipDeclarationList.yearDetails.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }
    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData?.semisterDetailsList !==
        nextProps.rollNoData?.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster: nextProps.rollNoData?.semisterDetailsList,
          },
        });
      }
    }
    if (this.props.roomList !== nextProps.roomList) {
      if (this.props.roomList?.roomByDate !== nextProps.roomList?.roomByDate) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            roomNameMaster: nextProps.roomList?.roomByDate,
          },
        });
      }
    }
  }
  componentDidMount() {
    const {
      getAlert,
      getExamFormDeclare,
      getYearDetails,
      getSemesterDetailsByYearAndAcademic,
    } = this.props;
    getExamFormDeclare({ flag: true }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }
  rowData = [
    {
      index: 1,
      reportName: "Examination Schedule Report (D0)",
      filter: "examDeclare",
      url: "/api/report/v1/exam-schedule-report?examFormDeclareId={examDeclare}&documentType=1",
    },
    {
      index: 2,
      reportName: "Examination Appllied List Report (D0)",
      filter: "examDeclare",
      url: "/api/report/v1/applied-student-list?examFormDeclareId={examDeclare}&documentType=1",
    },
    {
      index: 3,
      reportName: "Examination & Subject Wise Marks Report (D0, D3)",
      filter: "examDeclare,subject",
      url: "/api/report/v1/get-student-subjects-marks-report?examFormDeclareId={examDeclare}&documentType=1&subjectDetailId={subject}",
    },
    {
      index: 4,
      reportName: "Student Allotement Report (D0, D5, D6)",
      filter: "examDeclare,scheduleDate,roomName",
      url: "/api/report/v1/get-exam-conduction?scheduleDate={scheduleDate}&documentType=1&roomId={roomName}",
    },
    {
      index: 5,
      reportName: "Semester Wise Examination Report (D1, D2)",
      filter: "yearDetail,semesterDetail",
      url: "/api/report/v1/exam-declare-list?semisterDetailId={semesterDetail}&documentType=1&yearDetailId={yearDetail}",
    },
    {
      index: 6,
      reportName: "Hall Ticket (D0, D4)",
      filter: "examDeclare,examFormStudentApply",
      url: "/api/report/v1/get-student-hall-ticket?applyIds={examFormStudentApply}&examDeclareId={examDeclare}&documentType=1",
    },
    {
      index: 7,
      reportName: "Marksheet",
      filter: "examDeclare,examFormStudentApply (D0, D4)",
      url: "/api/report/v1/get-student-marksheet?applyIds={examFormStudentApply}&examDeclareId={examDeclare}&documentType=1",
    },
  ];
  getListById = (data) => {
    if (data.examDeclare) {
      this.props
        .getExamdeclareGetSubject({ examDeclareId: data.examDeclare })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getStudentStatusList({ id: data.examDeclare })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getExamScheduleDate({ examDeclareId: data.examDeclare })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.yearDetail) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: "",
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (data.scheduleDate) {
      this.props
        .getRoomByScheduleDate({
          scheduleDate: data.scheduleDate,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    console.log(this.props.roomList?.roomByDate);
    return (
      <>
        <DynamicMainScreen
          screenTitle={ExaminationReportJson.screenTitle}
          fieldMeta={ExaminationReportJson.fieldMeta}
          buttonCenter={ExaminationReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={ExaminationReportJson.searchButton}
          PDFButton={ExaminationReportJson.PDFButton}
          EXCELButton={ExaminationReportJson.EXCELButton}
          showHeadExcel={true}
          baseIdColumn={ExaminationReportJson.baseIdColumn}
          apiBaseURL={ExaminationReportJson.apiBaseURL}
          marginTop={ExaminationReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  examDeclareList: state.examDeclare,
  subjectsList: state.subjectInfo,
  internshipDeclarationList: state.internshipDeclaration,
  rollNoData: state.rollNo,
  roomList: state.room,
});
const mapDispatchToProps = {
  getExamdeclareGetSubject,
  getAlert,
  getExamFormDeclare,
  getYearDetails,
  getSemesterDetailsByYearAndAcademic,
  getStudentStatusList,
  getExamScheduleDate,
  getRoomByScheduleDate,
};
export default connect(mapStateToProps, mapDispatchToProps)(ExaminationReport);
