import { Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { ExamConductJson } from "../../../DynamicFormsJson/ExamConduct";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet } from "../../../utils/api_service";
import ExamConductFrom from "./ExamConductFrom";
import {
  getExamConductByFDTD,
  getExamConduct,
  getExamConductionById,
} from "./examconduct.slice";
import ExamConductView from "./ExamConductView";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: 100,
  },
  {
    name: "room",
    title: "Room Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    width: 120,
  },
  {
    name: "teacher",
    title: "Teacher Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    canSearch: true,
    width: 200,
  },
  {
    name: "scheduleDate",
    title: "Schedule Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
    width: 120,
  },

  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: 150,
  },
];

class ViewNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      showTable: true,
      showForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      department: "",
      academicYearId: "",
      fromDate: "",
      toDate: "",
      departmentList: [],
      editData: {},
      committeeData: {},
      eventDataForAppliedStudents: {},
      formErrors: [],
      searchValue: "",
      showLoader: false,
      showView: false,
      viewRowData: {},
    };
  }

  onSearchData = (dataToSearch) => {
    this.setState({
      fromDate: dataToSearch.fromDate,
      toDate: dataToSearch.toDate,
      showLoader: true,
    });
    this.props
      .getExamConductByFDTD({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,

      showTable: !showTable,
    });
  };

  backToForm = () => {
    this.setState({
      showForm: false,

      showTable: true,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.departments.department,
          { id: "0", name: "All" },
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    // const { fieldMeta } = this.props;
    // fieldMeta.map((currentField) => {
    //   if (currentField.defaultValue) {
    //     fieldData[currentField.dataKey] = currentField.defaultValue;
    //   }
    // });

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowEdit = (data) => {
    console.log(data);
    this.setState({
      showTable: false,
      showForm: true,
      editData: data,
    });
  };

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    this.props.resetReducList();
    this.props.getExamConduct().then((resp) => {
      if (!resp) {
        this.props.getAlert({ message: "Something went wrong" });
        this.setState({
          showLoader: false,
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  // http://localhost:8088/api/acdmc/v1/employee-notice/update-status?employeeNoticeId=230e724a-ae37-4f8b-935b-c554a793a5bd&status=0

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      showLoader: true,
    });
    apiDelete({
      url: endpoint.examConduction + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: true,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getExamConduct().then((resp) => {
          if (!resp) {
            this.props.getAlert({ message: "Something went wrong" });
            this.setState({
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.noticeList?.noticeByDate.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["title"] &&
          currentRow["title"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["noticeFor"] &&
          currentRow["noticeFor"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["fromDate"] &&
          currentRow["fromDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["toDate"] &&
          currentRow["toDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.getExamConduct().then((resp) => {
      if (!resp) {
        this.props.getAlert({ message: "Something went wrong" });
        this.setState({
          showLoader: false,
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };
  onViewCancel = () => {
    this.setState({
      showView: false,
      showTable: true,
      showForm: false,
    });
  };

  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  rowViewData = (data) => {
    this.props
      .getExamConductionById({ examConductionId: data.id })
      .then((resp) => {
        if (!resp) {
          this.props.getAlert({ message: "Something went wrong" });
          this.setState({
            showLoader: false,
          });
        } else {
          this.setState({
            showLoader: false,
            showView: true,
            showTable: false,
            showForm: false,
            viewRowData: data,
          });
        }
      });
  };
  render() {
    const {} = this.props;
    const { showTable, showForm, showView, viewRowData } = this.state;
    return (
      <>
        {showTable && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={ExamConductJson.screenTitle}
              fieldMeta={ExamConductJson.fieldMeta}
              buttonCenter={ExamConductJson.buttonCenter}
              showPdfDownload={ExamConductJson.showPdfDownload}
              showExcelDownload={ExamConductJson.showExcelDownload}
              tableColumnsToFilter={ExamConductJson.tableColumnsToFilter}
              showAddButton={ExamConductJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={columns}
              isActionColActive={true}
              tableData={this.props.examConduct.examConduct}
              showPegination={true}
              showView={true}
              rowViewData={this.rowViewData}
              showHeadDelete={true}
              rowDelete={this.rowDelete}
              showLoader={this.state.showLoader}
              onSearchData={this.onSearchData}
              baseIdColumn={ExamConductJson.baseIdColumn}
              apiBaseURL={ExamConductJson.apiBaseURL}
            />
          </>
        )}

        {showForm && (
          <>
            <ExamConductFrom onCancel={this.onFormCancel} />
          </>
        )}

        {showView && (
          <>
            <ExamConductView
              onCancel={this.onViewCancel}
              viewRowData={viewRowData}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  examConduct: state.examConduct,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  resetReducList,
  getExamConductByFDTD,
  getExamConduct,
  getExamConductionById,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewNotice);
