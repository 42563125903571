import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import {
  getDistinctDivision
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getStudentList } from "../ViewOfRollList/rollno.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class RollNoPopUp extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    admissionRegistrationDetailId: "",
    id: 0,
    rollNo: "",
    reportingDate: "",
    division: "",
    formErrors: [],
  };

  componentDidMount() {
    const { studentData } = this.props;
    const DOBArr =
      studentData.dateOfReporting == null
        ? ""
        : studentData.dateOfReporting.split("-");
    const DOB =
      DOBArr === "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

    this.setState({
      admissionRegistrationDetailId: studentData.admissionRegistrationDetailId,
      id: studentData.id,
      rollNo: studentData.rollNo,
      reportingDate: DOB,
      division: studentData.divisionId,
    });
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.studentRollList,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.submitHandler();
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  handleValid() {
    const {
      rollNo,
      division,
      reportingDate,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (rollNo.toString().trim() === "" || rollNo === null) {
      formIsValid = false;
      formErrors["rollNoError"] = 'Roll Number required';
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }

    if (reportingDate=== "" || reportingDate === null) {
      formIsValid = false;
      formErrors["reportingDateError"] =  'Select reporting date';
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  submitHandler = (e) => {
    e.preventDefault();
    const {
      rollNo,
      division,
      id,
      admissionRegistrationDetailId,
      reportingDate,
    } = this.state;

    const { getAlert, getStudentList } = this.props;
    let reportD =
      reportingDate === "" ? "" : moment(reportingDate).format("DD-MM-YYYY");
    let row = [];
    let docs = {
      id: id,
      getAdmissionRegistrationDetail: admissionRegistrationDetailId,
      rollNo: rollNo,
      division: {
        id: division,
      },
      semisterDetail: {
        id: this.props.semisterDetail,
      },
      dateOfReporting: reportD,
      isActive: 1,
    };
    row.push(docs);
    if (this.handleValid()) {
      swal({
        title: "Are you sure?",
        text: "Do you want to Edit Information?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        this.onSave(row);
        getStudentList({
          divisionId: division,
          yearId: this.props.yearDetail,
          semisterID: this.props.semisterDetail,
          
        });
      });
      this.handleClose();
    }
  };

  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        reportingDate: date,
      });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { internshipDeclarationList, onClickEdit } = this.props;
    const { rollNo, reportingDate, division } = this.state;
const {reportingDateError,divisionError,rollNoError} = this.state.formErrors
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.open}
            fullWidth
            maxWidth="lg"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Edit Details
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label=""
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}></Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Roll No "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        color="primary"
                        name="rollNo"
                        size="small"
                        type='number'
                        value={rollNo}
                        onChange={this.changedHandler}
                        fullWidth
                        error={rollNoError?true:false}
                        helperText={rollNoError?rollNoError:' '}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Reporting Date  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <BasicDatePicker
                        color="primary"
                        name="reportingDate"
                        size="small"
                        value={reportingDate}
                        fullWidth
                        onChange={this.changeFromDate}
                        isError={reportingDateError ? true : false}
                        errorText={reportingDateError ? reportingDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Division  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <AutoComplete
                        keyColName={"id"}
                        value={division}
                        name={"division"}
                        options={internshipDeclarationList?.distinctDivision}
                        onChange={this.ChangeHandlerSearch}
                        isError={divisionError ? true : false}
                        errorText={
                          divisionError ? divisionError : " "
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}></Grid>

                    <Grid item xs={12} sm={12} md={4}></Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="right"
                      m={1}
                    >
                      <ButtonCompo
                        size="medium"
                        type="Submit"
                        variant="outlined"
                        name="Submit"
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
});
const mapDispatchToProps = {
  getDistinctDivision,
  getAlert,
  getStudentList,
};
export default connect(mapStateToProps, mapDispatchToProps)(RollNoPopUp);
