import { FormHelperText, Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import { LabelCompo } from "../../../../../components/Comman/Label";
import endpoint from "../../../../../config/endpoints";
import * as myConstClass from "../../../../../config/messageconstant";
import { apiPost } from "../../../../../utils/api_service";
import { showNotification } from "../../../../Landing/Landing.slice";
import {
  getPrnNumberGenerate,
  getPrnNumberNull,
  resetValues,
} from "./PrnNumber.slice";
import PrnNumberPopUp from "./PrnNumberPopUp";
import PrnNumberTable from "./PrnNumberTable";

class PrnNumberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      division: "",
      yearDetail: "",
      semisterDetails: "",
      popUpValue: false,
      open: false,
      selection: [],
      setList: [],
      selectionList: [],
      formErrors: {},
      studentData: {},
      errorMsg: false,
    };
  }

  // rowEdit = (params) => {
  //   this.setState({
  //     popUpValue: true,
  //     open: true,
  //     studentData: params,
  //   });
  // };

  // onClose = () => {
  //   this.setState({
  //     popUpValue: false,
  //     open: false,
  //     studentData: {},
  //   });
  // };

  handleCloses = () => {
    this.setState({
      popUpValue: false,
      open: false,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Student Name",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "yearDetails",
      title: "Year details",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "division",
      title: "Division",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "20%",
    },
  ];

  handleClickOpen() {
    this.setState({
      open: false,
    });
  }

  componentDidMount() {
    const { resetValues } = this.props;

    resetValues();
  }

  componentWillMount() {
    const { getPrnNumberNull } = this.props;
    const ay = localStorage.getItem("acadamicYearId");
    getPrnNumberNull({ id: ay }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  saveHandler = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Do you want to submit Information",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        let dataToSave = this.props.prnNumberList?.prnNumberGenerate.map(
          (data1) => {
            let bData = {
              id: data1.id,
              prnNo: data1.prnNumber,
            };
            return bData;
          }
        );
        const { backToTable } = this.props;
        apiPost({
          url: endpoint.prnNumber + "/save-prnno",
          postBody: dataToSave,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({ msg: "Data saved successfully" });
            backToTable();
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });
  };

  handleFormValidation() {
    const { division, yearDetail, semisterDetails } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (semisterDetails.toString().trim() === "" || semisterDetails === null) {
      formIsValid = false;
      formErrors["semisterDetailsError"] = myConstClass.semisterNameMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  generatePrn = (e) => {
    e.preventDefault();
    const { getPrnNumberGenerate } = this.props;
    let studentIds = this.state.selection.toString();
    if (this.state.selection.length !== 0) {
      this.setState({
        errorMsg: false,
      });
      getPrnNumberGenerate({ ids: studentIds }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            popUpValue: true,
            open: true,
          });
        }
      });
    } else {
      this.setState({
        errorMsg: true,
      });
    }
  };
  save = () => {
    const { division, yearDetail, semisterDetails } = this.state;
    const { getAlert, getStudentList } = this.props;
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  getRow = (e) => {
    let selectedList = [];
    this.setState({
      selection: e,
    });
    e.forEach((data) => {
      const selRow = this.props.prnNumberList?.prnNumber.filter(
        (row, index) => {
          return row.id === data;
        }
      );
      selectedList = [...selectedList, selRow[0]];
    });
  };

  render() {
    const {
      division,
      yearDetail,
      semisterDetails,
      popUpValue,
      open,
      errorMsg,
    } = this.state;
    const {
      divisionError,
      yearDetailError,
      semisterDetailsError,
      academicYearError,
    } = this.state.formErrors;
    const { internshipDeclarationList, academicYearList, backToTable } =
      this.props;
    return (
      <>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="PRN Number Form"
                />
              </Grid>
              <Grid
                sx={{ marginBottom: 2 }}
                container
                justifyContent="right"
                item
                xs={12}
                sm={6}
                md={6}
              >
                <ButtonCompo
                  size="small"
                  type="submit"
                  variant="outlined"
                  name="Back To List"
                  onClick={backToTable}
                />
              </Grid>
            </Grid>
          </Grid>

          <br />
          <Grid item xs={12} sm={12} md={12}>
            <PrnNumberTable
              onSelectionChange={this.getRow}
              selection={this.state.selection}
              isActionColActive={false}
              generatePrn={this.generatePrn}
              errorMessage={this.state.errorMsg}
            />
          </Grid>

          <br />

          {popUpValue && (
            <PrnNumberPopUp
              open={open}
              handleCloses={this.handleCloses}
              submitHandler={this.saveHandler}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  prnNumberList: state.prnNumber,
});
const mapDispatchToProps = {
  showNotification,
  getPrnNumberNull,
  getPrnNumberGenerate,
  getAlert,
  resetValues,
};
export default connect(mapStateToProps, mapDispatchToProps)(PrnNumberForm);
