import { Grid, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../Comman/Label";
import { ButtonCompo } from "../Comman/Button";
import RTTable from "../Comman/RT/RTTable";
import AttachFile from "../Comman/AttachFile";
import { documentUploadColumns } from "../../tableColumns/table-columns";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MyComponentWithIconProps } from "../Comman/IconButton";
class DynamicDocumentUpload extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      imageName: "",
      fileName: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hideTable && nextProps.rows.length !== 0) {
      this.setState({
        imageName: nextProps.rows[0].docName,
      });
    }
  }
  onTextFieldDataChange = (dataKey) => (event) => {
    this.setState({
      imageName: event.target.value,
    });
  };

  onSave = () => {
    if (this.mandatoryCheck()) {
      this.setState({
        fileName: "",
        imageName: "",
      });
      this.props.onSave(this.state);
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { imageName, fileName } = this.state;
    let formErrors = {};
    if (this.props.showTextfield) {
      if (imageName === "") {
        formValid = false;

        formErrors = {
          ...formErrors,
          ["imageName"]: {
            isError: true,
            errorText: `Image name is required`,
          },
        };
      }
    }
    if (fileName === "") {
      formValid = false;

      formErrors = {
        ...formErrors,
        ["fileName"]: {
          isError: true,
          errorText: `select file`,
        },
      };
    }

    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  render() {
    const { formErrors, imageName, fileName } = this.state;
    const { rows, hideTable, showTextfield, allow } = this.props;
    return (
      <>
        <Grid container spacing={1}>
          {showTextfield ? (
            <>
              <Grid item xs={12} sm={12} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={"Documents / Reports"}
                />

                <TextField
                  size="small"
                  color="primary"
                  name={"imageName"}
                  id={"imageName"}
                  value={imageName}
                  onChange={this.onTextFieldDataChange("imageName")}
                  fullWidth
                  error={formErrors["imageName"]?.isError ? true : false}
                ></TextField>
              </Grid>
              <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={6.5}>
                <AttachFile name={"file"} fileName={this.changeH} />
                &nbsp; {fileName !== "" ? fileName.name : ""}
              </Grid>
              <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={1}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Upload"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={"Upload your CV"}
                />
              </Grid>
              <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={8.5}>
                <AttachFile
                  name={"file"}
                  fileName={this.changeH}
                  allow={allow}
                />
                &nbsp; {fileName !== "" ? fileName.name : ""}
              </Grid>
              <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={1}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Upload"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>
            </>
          )}

          <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={0.5}>
            {hideTable && rows.length !== 0 && (
              <MyComponentWithIconProps
                statusImage={VisibilityIcon}
                color="primary"
                fontSize="medium"
                title="View"
                onClick={(e) => this.props.rowView(rows[0])}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 10, color: "#ff0000" }}
              label={
                formErrors["imageName"]?.isError
                  ? formErrors["imageName"].errorText
                  : formErrors["fileName"]?.isError
                  ? formErrors["fileName"].errorText
                  : " "
              }
            />
            {!hideTable && (
              <RTTable
                columns={documentUploadColumns}
                showEditIcon={false}
                showViewButtonInTable={true}
                tableData={rows}
                rowView={this.props.rowView}
                rowDelete={this.props.rowDelete}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicDocumentUpload);
