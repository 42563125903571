import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AttachFile from "../../../components/Comman/AttachFile";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { Loading1 } from "../../../components/Loading1";
import * as myConstClass from "../../../config/messageconstant";
import {
  deleteFileData,
  openFileInNewTab,
  saveFile,
} from "../../UploadFile/file.slice";
const columns = [
  {
    title: "Document Name",
    name: "docDesc",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
class MinutesOfMeetingDocument extends Component {
  state = {
    id: 0,
    documentName: "",
    remark: "",
    label: "",
    fileName: "",
    file: "",
    other: "",
    addTextField: [],
    AttachFileName: [],
    AttachFileTitle: [],
    formErrors: [],
    open: false,
    upload: false,
    showView: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      file: fileName,
    });
  };

  handleFormValidation() {
    const { label } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (label === "") {
      formIsValid = false;
      formErrors["uploadError"] = myConstClass.docNameMsg;
    }
    this.setState({
      ...this.state,
      formErrors: formErrors,
    });
    return formIsValid;
  }

  componentDidMount() {
    const { showView } = this.props;
    this.setState({
      showView: showView,
    });
  }
  componentDidUpdate() {
    const { fileData, deleteFileData } = this.props;
    const { id, label } = this.state;

    if (fileData.isFetch === true) {
      let data = {
        documentId: id,
        docDesc: label,
        documentPath: fileData.file,
      };
      this.props.rowData(data);

      deleteFileData();
      // this.setState({
      //   ...this.state,
      //   label: "",
      //   upload: false,
      // });
    }
  }

  uploadImage = () => {
    const { saveFile, getAlert } = this.props;
    const { file, label, showLoader } = this.state;
    if (this.handleFormValidation()) {
      if (file !== "" && label !== "") {
        this.setState({
          showLoader: true,
        });
        saveFile({ file: file }).then((response) => {
          if (!response) {
            this.setState({ showLoader: false });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({ showLoader: false });
          }
        });
      }
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({
        ...this.state,
        [name]: newValue,
      });
    } else {
      this.setState({ ...this.state, [name]: "" });
    }
  };

  details = (params) => {
    this.props
      .openFileInNewTab({ file: params.documentPath })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  };

  render() {
    const { label, showView, showLoader } = this.state;
    const { row, rowView, rowDelete, documentList } = this.props;

    const { uploadError } = this.state.formErrors;

    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            {!showView && (
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                // spacing={2}
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Upload Documents"
                    />
                  </Grid>

                  <Grid sx={{ mt: 3 }} item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Enter Document Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4.5}>
                    <TextField
                      color="primary"
                      name="label"
                      size="small"
                      value={label}
                      onChange={this.changeHandler}
                      fullWidth
                      error={uploadError ? true : false}
                      helperText={uploadError ? uploadError : " "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1.2} md={1.2}>
                    <AttachFile
                      style={{
                        width: "100%",
                        display: "block",
                        textAlign: "center",
                      }}
                      name={"file"}
                      fileName={this.changeH}
                    />
                  </Grid>
                  {console.log(this.state.file.name)}

                  <Grid item xs={12} sm={12} md={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={this.state.file.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1.3} md={1.3}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      fullWidth={true}
                      name="Upload"
                      onClick={this.uploadImage}
                    />
                  </Grid>
                  <Grid
                    sx={{ marginTop: -3 }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  ></Grid>

                  <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}></Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  ></Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <DynamicTable
                data={row}
                tableHead={columns}
                showPegination={false}
                rowViewData={this.details}
                showView={true}
                showHeadDelete={showView ? false : true}
                rowDelete={rowDelete}
              />
            </Grid>
          </form>
          <Grid
            sx={{ mr: 0.5 }}
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name={showView ? "Back" : "Cancel"}
                onClick={this.props.backToAgenda}
              />
            </Grid>
            {!showView && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  onClick={this.props.saveHandler}
                  fullWidth={true}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fileData: state.file,
  documentList: state.document,
});

const mapDispatchToProps = {
  saveFile,
  deleteFileData,
  openFileInNewTab,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinutesOfMeetingDocument);
