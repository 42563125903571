import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import endpoint from "../../../../../config/endpoints";
import * as msgConstant from "../../../../../config/messageconstant";
import { ResearchGuidanceJson } from "../../../../../DynamicFormsJson/ResearchGuidance";
import { showNotification } from "../../../../../Pages/Landing/Landing.slice";
import { researchGuidanceColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../../../utils/api_service";
import { getActivityByType } from "../Activity/activity.slice";
import {
  getResearchByDate,
  getResearchGuide,
  getResearchWithoutDate,
  SetResearchData,
} from "../ResearchGuidance/researchguidance.slice";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import * as myConstClass from "../../../../../config/messageconstant";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { Loading1 } from "../../../../../components/Loading1";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class ResourcePerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      formErrors: {},
      searchValue: "",
      showLoader: false,
      dynamicMasterData: {
        activity: this.props.activityList?.activityByType,
        researchCenterMaster: [
          { id: "University", name: "University" },
          { id: "Research Center", name: "Research Center" },
          { id: "other", name: "Other" },
        ],
        typeMaster: [
          { id: "PHD", name: "PHD" },
          { id: "Masters", name: "Masters" },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getResearchWithoutDate().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  submitHandler = (data) => {
    this.setState({ showLoader: true });
    this.props
      .getResearchByDate({ fromDate: data.fromDate, toDate: data.toDate })
      .then((response) => {
        if (!response) {
          this.setState({ showLoader: false });
          console.log("In If");

          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({ showLoader: false });
          console.log("In else");
        }
      });
  };

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <DynamicMainScreen
          screenTitle={ResearchGuidanceJson.screenTitle}
          fieldMeta={ResearchGuidanceJson.fieldMeta}
          showPdfDownload={ResearchGuidanceJson.showPdfDownload}
          showExcelDownload={ResearchGuidanceJson.showExcelDownload}
          pdfFileName={ResearchGuidanceJson.pdfFileName}
          excelFileName={ResearchGuidanceJson.excelFileName}
          showAddButton={ResearchGuidanceJson.showAddButton}
          tableColumnsToFilter={ResearchGuidanceJson.tableColumnsToFilter}
          dynamicMasterData={dynamicMasterData}
          showSaveNextBtn={false}
          tableColumns={researchGuidanceColumns}
          tableData={this.props.researchGuideList?.researchGuide}
          getTableData={this.props.getResearchWithoutDate}
          showLoader={!this.state.showLoader}
          apiBaseURL={ResearchGuidanceJson.apiBaseURL}
          baseIdColumn={ResearchGuidanceJson.baseIdColumn}
          type={1}
          DocumentUpload={ResearchGuidanceJson.DocumentUpload}
          onSearchData={this.submitHandler}
          searchList={ResearchGuidanceJson.searchList}
          deleteUrl={ResearchGuidanceJson.deleteUrl}
          sendEmptyId={true}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  innovativeList: state.innovativeTeaching,
  researchGuideList: state.researchGuide,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getActivityByType,
  getResearchByDate,
  getResearchGuide,
  getResearchWithoutDate,
  SetResearchData,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResourcePerson);
