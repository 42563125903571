import { Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { generateExcel } from "../../components/Comman/GenerateExcel";
import { generatePDF } from "../../components/Comman/GeneratePDF";
import { getAlert } from "../../CommonActions/alert.slice";
import * as msgConstant from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import { Loading1 } from "../../components/Loading1";
import UserAccessTable from "./UserAccessTable";
import {
  getModuleAndSubModule,
  getAccessRoleById,
  getAccessRoleList,
  resetUserAccessById,
} from "./accessRole.slice";
import { AccessRoleColumns } from "../../tableColumns/table-columns";
import endpoint from "../../config/endpoints";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};
const condition = [
  {
    title: "id",
    value: 1,
    iconHide: "showDeleteIcon",
  },
];
class UserAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
    };
  }
  componentDidMount() {
    const { getModuleAndSubModule, getAccessRoleList } = this.props;
    getModuleAndSubModule().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getAccessRoleList().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    this.setState({
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };
  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    this.props
      .getAccessRoleById({ accessRoleId: rowData.id })
      .then((success) => {
        if (!success) {
          this.props.getAlert({
            message: "Something went wrong",
          });
        }
      });

    this.setState({
      currentOperationMode: OperationMode.Edit,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onDelete = (rowdata) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.saveAccessRole + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getAccessRoleList().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    const columsFil = AccessRoleColumns.filter((item) => {
      return item.canSearch;
    });
    return this.props.accessRole?.accessRole.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        console.log("ssss" + endpoint.saveAccessRole);
        const isActive = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveAccessRole +
            "/" +
            rowData.id +
            "/update-status/" +
            isActive,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showNotification({ msg: "Status updated" });
            this.props.getAccessRoleList().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      AccessRoleColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      AccessRoleColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    AccessRoleColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };
  resetUserAccess = () => {
    this.props.resetUserAccessById();
  };
  SaveAndClose = () => {
    this.toggleFormTableVisibility();
    this.props.showNotification({ msg: "Data saved successfully" });
    this.props.getAccessRoleList().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };
  render() {
    const { accessRole } = this.props;
    const {
      tableColumnsToFilter = [],
      fieldData,
      showTable,
      showForm,
      isLoading,
      fromDate,
      toDate,
    } = this.state;

    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={tableColumnsToFilter}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={"Access Role"}
          showPdfDownload={true}
          generatePDF={this.generatePDF}
          showExcelDownload={true}
          generateExcel={this.generateExcel}
          showAddButton={true}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />
        {/* <Alert msg={"hi"} /> */}
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <UserAccessTable
                resetUserAccess={this.resetUserAccess}
                data={accessRole?.moduleSubModule}
                onSave={this.SaveAndClose}
                editData={accessRole?.accessRoleById}
              />
              <br />
            </>
          )}
          {showTable && (
            <RTTable
              columns={AccessRoleColumns}
              hiddenColumnNames={tableColumnsToFilter.map((item) => {
                return !item.isChecked ? item.columnKeyName : "";
              })}
              tableData={this.getFilteredTableData()}
              rowEdit={this.rowEdit}
              condition={condition}
              showEditIcon={true}
              showDeleteIcon={true}
              rowDelete={this.rowDelete}
              onChangeStatus={this.onChangeStatus}
            />
          )}
        </Paper>
        {/* {isLoading && <Loading1 />}
        {!showLoader && <Loading1 />} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accessRole: state.accessRole,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAccessRoleList,
  getModuleAndSubModule,
  getAccessRoleById,
  resetUserAccessById,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
