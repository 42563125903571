import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  volunteerStatusUpdate: [],
  isFetch: false,
  volunteerByIdData: {},
  isFetchVolunteerById: false,
  volunteerByRegistrationIdData: [],
  isFetchVolunteerByRegistrationId: false,
  internshipByIdData: [],
  isFetchInternshipById: false,
  associationByRegistrationId: [],
  isFetchAssociationByRegistrationId: false,
  donorByRegistrationId: [],
  isFetchDonorByRegistrationId: false,
  paperByRegistrationId: [],
  isFetchPaperByRegistrationId: false,
  resoursePersonByRegistrationId: [],
  isFetchResoursePersonByRegistrationId: false,
  collaboratorByRegistrationId: [],
  isFetchCollaboratorByRegistrationId: false,
  teacherByRegistrationId: [],
  isFetchTeacherByRegistrationId: false,
  alumnaAll: [],
  isFetchAlumnaAll: false,
};
let URL = endpoints.volunteerUpdateStatus;
let alumniRegisterURL = endpoints.alumniRegister;
let alumniPostInternship = endpoints.postInternship;
const adminVolunteerSlice = createSlice({
  name: "volunteerStatusUpdate",
  initialState,
  reducers: {
    volunteerSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        volunteerStatusUpdate: row,
        isFetch: true,
      };
    },
    volunteerByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        volunteerByIdData: row,
        isFetchVolunteerById: true,
      };
    },
    intrenshipByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        internshipByIdData: row,
        isFetchInternshipById: true,
      };
    },
    volunteerByRegistrationIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        volunteerByRegistrationIdData: row,
        isFetchVolunteerByRegistrationId: true,
      };
    },
    associationByRegistrationIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        associationByRegistrationId: row,
        isFetchAssociationByRegistrationId: true,
      };
    },
    donorByRegistrationIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        donorByRegistrationId: row,
        isFetchDonorByRegistrationId: true,
      };
    },
    paperByRegistrationIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paperByRegistrationId: row,
        isFetchPaperByRegistrationId: true,
      };
    },
    resoursePersonByRegistrationIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        resoursePersonByRegistrationId: row,
        isFetchResoursePersonByRegistrationId: true,
      };
    },
    collaboratorByRegistrationIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        collaboratorByRegistrationId: row,
        isFetchCollaboratorByRegistrationId: true,
      };
    },
    teacherByRegistrationIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        teacherByRegistrationId: row,
        isFetchTeacherByRegistrationId: true,
      };
    },
    alumnaAllSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        alumnaAll: row,
        isFetchAlumnaAll: true,
      };
    },
  },
});

export const {
  volunteerSuccess,
  volunteerByIdSuccess,
  volunteerByRegistrationIdSuccess,
  associationByRegistrationIdSuccess,
  donorByRegistrationIdSuccess,
  intrenshipByIdSuccess,
  paperByRegistrationIdSuccess,
  resoursePersonByRegistrationIdSuccess,
  collaboratorByRegistrationIdSuccess,
  teacherByRegistrationIdSuccess,
  alumnaAllSuccess,
} = adminVolunteerSlice.actions;

export default adminVolunteerSlice.reducer;

export const getVolunteer = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        console.log(content);
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            alumniName:
              data1.alumniRegistration === null
                ? ""
                : data1.alumniRegistration.lastName +
                  " " +
                  data1.alumniRegistration.firstName +
                  " " +
                  (data1.alumniRegistration.fatherName == null ||
                  data1.alumniRegistration.fatherName == ""
                    ? " - "
                    : data1.alumniRegistration.fatherName) +
                  " " +
                  (data1.alumniRegistration.motherName == null ||
                  data1.alumniRegistration.motherName == ""
                    ? " - "
                    : data1.alumniRegistration.motherName),
            lastName:
              data1.alumniRegistration != null
                ? data1.alumniRegistration.lastName
                : null,
            firstName:
              data1.alumniRegistration != null
                ? data1.alumniRegistration.firstName
                : null,
            fatherName:
              data1.alumniRegistration != null
                ? data1.alumniRegistration.fatherName
                : null,
            motherName:
              data1.alumniRegistration != null
                ? data1.alumniRegistration.motherName
                : null,
            alumniId:
              data1.alumniRegistration === null
                ? ""
                : data1.alumniRegistration.id,
            contact:data1.alumniRegistration.alumniMobile===null || data1.alumniRegistration.alumniMobile===""?"N/A":data1.alumniRegistration.alumniMobile,
            email:data1.alumniRegistration.alumniEmail===null || data1.alumniRegistration.alumniEmail===""?"N/A":data1.alumniRegistration.alumniEmail,
            id: data1.id,
            interest: data1.interest === null ? " - " : data1.interest,
            expertise: data1.expertise === null ? " - " : data1.expertise,
            time: data1.time === null ? " - " : data1.time,
            message: data1.message === null ? " - " : data1.message,
            remark: data1.remark === null ? " - " : data1.remark,
            status: data1.status,
            type: data1.type,
            completeStatus: data1.completeStatus,
          };
          row.push(bData);
          return data1;
        });
        dispatch(volunteerSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getVolunteerByRegistrationId =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-volunteer-mentor-by-alumni-registration-id?type=" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              // alumniName:
              //   data1.alumniRegistration === null
              //     ? ""
              //     : data1.alumniRegistration.firstName +
              //       " " +
              //       data1.alumniRegistration.middleName +
              //       " " +
              //       data1.alumniRegistration.lastName,
              // alumniId:
              //   data1.alumniRegistration === null
              //     ? ""
              //     : data1.alumniRegistration.id,
              id: data1.id,
              interest: data1.interest,
              expertise: data1.expertise,
              time: data1.time,
              message: data1.message,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Approved"
                  : "Rejected",
              type: data1.type,
              documentName: data1.documentName,
              remark: data1.remark,
            };
            row.push(bData);
            return data1;
          });
          dispatch(volunteerByRegistrationIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInternshipById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: alumniPostInternship + "/by-alumni-registration-id?id=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              companyName: data1.companyName,
              companyWebsite: data1.companyWebsite,
              duration: data1.duration,
              location: data1.location,
            };
            row.push(bData);
            return data1;
          });
          dispatch(intrenshipByIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getVolunteerById = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: alumniRegisterURL + "/by-user-id",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        let bData = {
          accessRole: content.accessRole,
          alternativeEmail:
            content.alternativeEmail === null ? "" : content.alternativeEmail,
          alternativeMobile:
            content.alternativeMobile === null ? "" : content.alternativeMobile,
          alumniEmail: content.alumniEmail === null ? "" : content.alumniEmail,
          alumniMobile:
            content.alumniMobile === null ? "" : content.alumniMobile,
          bloodGroup: content.bloodGroup === null ? "" : content.bloodGroup,
          dateOfBirth: content.dateOfBirth === null ? "" : content.dateOfBirth,
          educationDetails:
            content.educationDetails === null ? "" : content.educationDetails,
          facebookProfile:
            content.facebookProfile === null ? "" : content.facebookProfile,
          fatherName: content.fatherName === null ? "" : content.fatherName,
          alumniName:
            content.firstName === null
              ? ""
              : content.firstName + " " + content.middleName === null
              ? ""
              : content.middleName + " " + content.lastName === null
              ? ""
              : content.lastName,
          gender: content.gender === null ? "" : content.gender,
          id: content.id,
          instagramProfile:
            content.instagramProfile === null ? "" : content.instagramProfile,
          isActive: content.isActive,
          linkedinProfile:
            content.linkedinProfile === null ? "" : content.linkedinProfile,
          martialStatus: content.martialStatus,
          motherName: content.motherName === null ? "" : content.motherName,
          permanentAddressLine1:
            content.permanentAddressLine1 === null
              ? ""
              : content.permanentAddressLine1,
          permanentAddressLine2:
            content.permanentAddressLine2 === null
              ? ""
              : content.permanentAddressLine2,
          permanentPin:
            content.permanentPin === null ? "" : content.permanentPin,
          profilePic: content.profilePic === null ? "" : content.profilePic,
          role: content.role,
          temporaryAddressLine1:
            content.temporaryAddressLine1 === null
              ? ""
              : content.temporaryAddressLine1,
          temporaryAddressLine2:
            content.temporaryAddressLine2 === null
              ? ""
              : content.temporaryAddressLine2,
          temporaryPin:
            content.temporaryPin === null ? "" : content.temporaryPin,
          twitterHhandle:
            content.twitterHhandle === null ? "" : content.twitterHhandle,
          type: content.type,
          userId: content.userId,
          websitePortfoliBlog:
            content.websitePortfoliBlog === null
              ? ""
              : content.websitePortfoliBlog,
          youtubeChannel:
            content.youtubeChannel === null ? "" : content.youtubeChannel,
        };
        dispatch(volunteerByIdSuccess({ row: bData }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUpdatedStatus =
  ({ applyId, status, type, remark }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/update-status?ids=" +
          applyId +
          "&type=" +
          type +
          "&status=" +
          status +
          "&remark=" +
          remark,
      }).then(({ data, success }) => {
        if (success) {
          // const content = data.data;
          // let index = 0;
          // const row = content.map( ( data1 ) =>
          // {
          //     index = index + 1;
          //     let bData = {
          //         index: index,
          //         id: data1.id,
          //         seatNo: data1.seatNo,
          //         studentName: data1.student.firstName + " " + data1.student.middleName + " " + data1.student.lastName,
          //         applicableFees: data1.applicableFees,
          //         amount: data1.amount,
          //         approvedStatus: data1.approvedStatus,
          //     };
          //     return bData;
          // } );
          // dispatch( studentListStatusSuccess( { row } ) );
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const alumniInviteFriends =
  ({ emailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: alumniRegisterURL + "/invite-friends?emailIds=" + emailId,
      }).then(({ data, success }) => {
        if (success) {
          // const content = data.data;
          // let index = 0;
          // const row = content.map( ( data1 ) =>
          // {
          //     index = index + 1;
          //     let bData = {
          //         index: index,
          //         id: data1.id,
          //         seatNo: data1.seatNo,
          //         studentName: data1.student.firstName + " " + data1.student.middleName + " " + data1.student.lastName,
          //         applicableFees: data1.applicableFees,
          //         amount: data1.amount,
          //         approvedStatus: data1.approvedStatus,
          //     };
          //     return bData;
          // } );
          // dispatch( studentListStatusSuccess( { row } ) );
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAssociationByRegistrationId =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-volunteer-mentor-by-alumni-registration-id?type=" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              expertise: data1.expertise,
              message: data1.message,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Approved"
                  : "Rejected",
              type: data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(associationByRegistrationIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDonorByRegistrationId =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-volunteer-mentor-by-alumni-registration-id?type=" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,

              expertise: data1.expertise,
              message: data1.message,
              messageData:
                data1.message === "Any other" ? data1.remark : data1.message,
              remark: data1.remark,
              interest: data1.interest,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Approved"
                  : "Rejected",
              type: data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(donorByRegistrationIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPaperByRegistrationId =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-volunteer-mentor-by-alumni-registration-id?type=" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              interest: data1.interest,
              expertise: data1.expertise,
              message: data1.message,
              remark: data1.remark,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Approved"
                  : "Rejected",
              type: data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(paperByRegistrationIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getResoursePersonByRegistrationId =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-volunteer-mentor-by-alumni-registration-id?type=" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              interest: data1.interest,
              expertise: data1.expertise,
              time: data1.time,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Approved"
                  : "Rejected",
              type: data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(resoursePersonByRegistrationIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCollaboratorByRegistrationId =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-volunteer-mentor-by-alumni-registration-id?type=" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              expertise: data1.expertise,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Approved"
                  : "Rejected",
              type: data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(collaboratorByRegistrationIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTeacherByRegistrationId =
  ({ type }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-volunteer-mentor-by-alumni-registration-id?type=" + type,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              expertise: data1.expertise,
              remark: data1.remark,
              message: data1.message,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Approved"
                  : "Rejected",
              type: data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(teacherByRegistrationIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAllStatus = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL + "/get-volunteer-mentor-by-alumni-registration-id-witout-type?id=",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            type:
              data1.type == 0
                ? "Mentor"
                : data1.type == 1
                ? "Volunteer"
                : data1.type == 2
                ? "Alumnae Association Office Bearer"
                : data1.type == 3
                ? "Donor and Sponsor"
                : data1.type == 4
                ? "Paper Setter/ Evaluator/Examiner/Guide"
                : data1.type == 5
                ? "Resource Person"
                : data1.type == 6
                ? "Collaborator"
                : "Teacher",
            expertise: data1.expertise,
            status:
              data1.status == 0
                ? "Pending"
                : data1.status == 1
                ? "Approved"
                : "Rejected",
          };
          row.push(bData);
          return data1;
        });
        dispatch(alumnaAllSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
