import { FormControl, Grid, MenuItem, Paper, Select } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { generateExcel } from "../../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../../components/Comman/GeneratePDF";
import { LabelCompo } from "../../../../components/Comman/Label";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
import RTTable from "../../../../components/Comman/RT/RTTable";
import { Loading1 } from "../../../../components/Loading1";
import endpoints from "../../../../config/endpoints";
import * as msgConstant from "../../../../config/messageconstant";
import { CompanyMasterColumns } from "../../../../tableColumns/table-columns";
import { apiDelete, apiPost } from "../../../../utils/api_service";
import { showNotification } from "../../../Landing/Landing.slice";
import { getCompany, getCompanyByType } from "./company.slice";
import CompanyForm from "./CompanyForm";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class CompanyMaster extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      quaRow: [],
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      editRowData: {},
      editCompany: {},
      type: "2",
      saveandNext: false,
    };
  }
  rowDeleteQualification = (index, id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let filterQualiList = this.state.quaRow.filter(
          (row) => row.index !== index
        );
        const quaRowList = filterQualiList.map((quaData, index) => {
          quaData = {
            ...quaData,
            index: index + 1,
          };
          return quaData;
        });
        this.setState({
          quaRow: quaRowList,
        });
        if (id) {
          apiDelete({
            url: endpoints.company + "-contact-person/" + id,
          }).then(({ success }) => {
            if (success) {
              const { showNotification, getCompany } = this.props;
              this.setState({
                isLoading: false,
              });
              showNotification({ msg: "Data deleted successfully" });
              getCompany().then((response) => {
                if (!response) {
                  this.props.getAlert({
                    message: msgConstant.serverMsg,
                  });
                }
              });
              this.setState({
                currentOperationMode: "",
              });
            } else {
              this.props.getAlert({
                message: "Failed to delete",
              });
              this.setState({
                isLoading: false,
              });
            }
          });
        }
      }
    });
  };
  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };
  componentDidMount() {
    const { getCompany } = this.props;
    getCompany().then((response) => {
      if (!response) {
        this.props.getAlert({
          message: msgConstant.serverMsg,
        });
      }
    });
  }
  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    this.setState({
      currentOperationMode: OperationMode.Add,
      editCompany: {},
      quaRow: [],
    });
    this.toggleFormTableVisibility();
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onFormCancel = () => {
    this.props.getCompany().then((response) => {
      if (!response) {
        this.props.getAlert({
          message: msgConstant.serverMsg,
        });
      }
    });
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "multiSelect") {
        let multiselectValue = fieldData[currentField.dataKey].join();

        dataToSave[currentField.dataKey] = multiselectValue
          ? multiselectValue
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: endpoints.company,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? msgConstant.saveMsg
              : currentOperationMode === OperationMode.Status
              ? msgConstant.activeStatus
              : msgConstant.updateMsg,
        });
        this.props.getCompany().then((response) => {
          if (!response) {
            this.props.getAlert({
              message: msgConstant.serverMsg,
            });
          }
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
          quaRow: [],
          saveandNext: isToggle ? false : true,
        });
      } else {
        this.props.getAlert({
          message: msgConstant.saveFailMsg,
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  resetSaveAndNext = () => {
    this.setState({
      saveandNext: false,
    });
  };
  onDelete = (rowdata) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoints.company + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        const { showNotification, getCompany } = this.props;
        this.setState({
          isLoading: false,
        });
        showNotification({ msg: "Data deleted successfully" });
        getCompany().then((response) => {
          if (!response) {
            this.props.getAlert({
              message: msgConstant.serverMsg,
            });
          }
        });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { company } = this.props;
    const { searchValue } = this.state;
    const columsFil = CompanyMasterColumns.filter((item) => {
      return item.canSearch;
    });
    return company.company.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };
  qualificationData = (company, toggle) => {
    const companyContactPersonList = this.state.quaRow.map((contactPerson) => {
      let persondata = {
        personName: contactPerson.personName,
        contactNo: contactPerson.contactNo,
        emailId: contactPerson.emailId,
      };
      if (company.id !== undefined && contactPerson.id !== undefined) {
        persondata = {
          ...persondata,
          id: contactPerson.id,
        };
      }
      return persondata;
    });
    company = {
      ...company,
      companyContactPersons: companyContactPersonList,
    };
    console.log(company);

    this.onSave(company, toggle);
  };
  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const companyContactPersons = rowData.companyContactPersons.map(
          ({ index, ...rest }) => {
            return rest;
          }
        );
        console.log("companyContactPersons");
        console.log(companyContactPersons);

        rowData = {
          ...rowData,
          isActive: rowData.isActive === 1 ? 0 : 1,
          companyContactPersons: companyContactPersons,
        };
        delete rowData.index;
        console.log("rowData");
        console.log(rowData);
        this.setState({
          currentOperationMode: OperationMode.Status,
        });
        this.onSave(rowData, false);
      }
    });
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      CompanyMasterColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      CompanyMasterColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    CompanyMasterColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };
  setQuaData = (data) => {
    console.log(data);
    if (data.index == 0) {
      data = {
        ...data,
        index: this.state.quaRow.length + 1,
      };
      let companyList = [...this.state.quaRow, data];
      this.setState({
        quaRow: companyList,
      });
    } else {
      let filteredData = this.state.quaRow.filter(
        (row) => row.index !== data.index
      );
      filteredData = [...filteredData, data];
      this.setState({
        quaRow: filteredData,
      });
    }
  };
  rowEditCompany = (rowData) => {
    this.toggleFormTableVisibility();
    const companyData = this.props.company.company.filter(
      (companyData) => companyData.id === rowData.id
    );
    console.log(companyData);
    if (companyData.length != 0) {
      this.setState({
        editCompany: companyData[0],
        quaRow: companyData[0].companyContactPersons,
      });
    }
  };
  changedHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);

    this.setState({
      ...this.state,
      [name]: value,
    });

    if (value !== "") {
      if (value == "2") {
        this.props.getCompany().then((response) => {
          if (!response) {
            this.props.getAlert({
              message: msgConstant.serverMsg,
            });
          }
        });
      } else {
        this.props.getCompanyByType({ type: value }).then((response) => {
          if (!response) {
            this.props.getAlert({
              message: msgConstant.serverMsg,
            });
          }
        });
      }
    }
  };
  searchHandler = (e) => {
    e.preventDefault();
    if (this.state.type !== "") {
      if (this.state.type == "2") {
        this.props.getCompany().then((response) => {
          if (!response) {
            this.props.getAlert({
              message: msgConstant.serverMsg,
            });
          }
        });
      } else {
        this.props
          .getCompanyByType({ type: this.state.type })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: msgConstant.serverMsg,
              });
            }
          });
      }
    }
  };
  render() {
    const {
      screenTitle = "",
      showAddButtonInTable = false,
      showSaveNextBtn = true,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      fieldMeta,
      showLoader = true,
      company,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      quaRow,
      showTable,
      showForm,
      isLoading,
      editRowData,
      editCompany,
      saveandNext,
      type,
    } = this.state;

    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={tableColumnsToFilter}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={"Add Company"}
          showPdfDownload={showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={showAddButton}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />
        {/* <Alert msg={"hi"} /> */}
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <CompanyForm
                row={quaRow}
                rowData={this.setQuaData}
                rowDelete={this.rowDeleteQualification}
                qualificationData={this.qualificationData}
                editRows={editRowData}
                editCompany={editCompany}
                onCancel={this.onFormCancel}
                saveandNext={saveandNext}
                resetSaveAndNext={this.resetSaveAndNext}
                onSaveAndNext={this.qualificationData}
              />
              <br />
            </>
          )}
          {showTable && (
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  sx={{ mt: 2 }}
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={2}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Select Internship/Placement Company"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label=" "
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="type"
                          id="type"
                          value={type}
                          onChange={this.changedHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="0">Placement</MenuItem>
                          <MenuItem value="1">Internship</MenuItem>
                          <MenuItem value="2">All</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      {/* <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.searchHandler}
                      /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ mt: 2 }}>
                <RTTable
                  columns={CompanyMasterColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  tableData={this.getFilteredTableData()}
                  rowEdit={this.rowEditCompany}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                />
              </Grid>
            </>
          )}
        </Paper>
        {isLoading && <Loading1 />}
        {!showLoader && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  company: state.company,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getCompanyByType,
  getCompany,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyMaster);
