import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { downloadF } from "../../PDF/PDFDownload";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { getTeacherTimeTable } from "../GenerateTimeTable/generateTimeTable.slice";
import { Loading1 } from "../../../components/Loading1";
import { getAlert } from "../../../CommonActions/alert.slice";
import ViewTimeTableCellPop from "../GenerateTimeTable/ViewTimeTableCellPop";
class MatrixForm extends Component
{
  constructor( props )
  {
    super( props );
    this.state = {
      matrixData: [],
      matList: [],
      columnList: [],
      openTimeSlotCell: false,
      rows: [],
      value: [],
      timeSlot: "",
      day: "",
      achievements: "",
      name: "",
      dayDataList: [],
      loading: false,
    };
  }

  componentDidMount()
  {
    
    const { timeTableList, timeSlotList } = this.props;
    let userName = localStorage.getItem( "userName" );
    this.setState( { name: userName, dayDataList: timeSlotList } );
    this.createMatrix( timeTableList.timeTableDetailForTeacher, timeSlotList );
  }
  createMatrix( timeTableDetailsList, timeSlotList )
  {
    const { dayList } = this.props;

    let matrixData = [];
    if ( this.state.matList.length === 0 )
    {
      timeSlotList.map( ( timeSlotData ) =>
      {
        let matData;
        matData = {
          timeSlotId: timeSlotData.id,
          timeSlotName: timeSlotData.fromTime + " - " + timeSlotData.toTime,
        };
        const daysData = dayList.map( ( dayData ) =>
        {
          let timeTableDetailsData = timeTableDetailsList.filter(
            ( item ) =>
              item.day === dayData.id && item.timeSlot === timeSlotData.id
          );

          console.log( JSON.stringify( timeTableDetailsData ) )

          if ( timeTableDetailsData.length !== 0 )
          {
            let dataList = [];
            timeTableDetailsData.map( ( timeTableDetailsDetails ) =>
            {
              let matValue = {
                yearDetailName: timeTableDetailsDetails.yearDetailName,
                divisionName: timeTableDetailsDetails.divisionName,
                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,
                batchShortName: timeTableDetailsDetails.batchShortName,

                teacher: timeTableDetailsDetails.teacher,
                lastName: timeTableDetailsDetails.lastName,
                firstName: timeTableDetailsDetails.firstName,
                fatherName: timeTableDetailsDetails.fatherName,
                motherName: timeTableDetailsDetails.motherName,
                middleName: timeTableDetailsDetails.middleName,

                teacherName: timeTableDetailsDetails.teacherName,
                teacherShortName: timeTableDetailsDetails.teacherShortName,

                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectDetailName: timeTableDetailsDetails.subjectDetailName,
                subjectName: timeTableDetailsDetails.subjectName,

                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                id: timeTableDetailsDetails.id,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };
              dataList.push( matValue );
            } );

            console.log( "datalist" + dataList )
            let days = {
              dayId: dayData.id,
              dayName: dayData.name,
              value: dataList,
            };
            return days;
          }
          let days = {
            dayId: dayData.id,
            dayName: dayData.name,
            value: [],
          };
          return days;
        } );
        matData = { ...matData, dayList: daysData };


        matrixData.push( matData );
      } );

    } else
    {

      this.state.matList.map( ( timeSlotData ) =>
      {
        let matData;
        matData = {
          timeSlotId: timeSlotData.timeSlotId,
          timeSlotName: timeSlotData.timeSlotName,
        };
        const daysData = timeSlotData.dayList.map( ( dayData ) =>
        {
          let timeTableDetailsData = timeTableDetailsList.filter(
            ( item ) =>
              item.day === dayData.dayId &&
              item.timeSlotName === timeSlotData.timeSlotName
          );

          if ( timeTableDetailsData.length !== 0 )
          {
            let dataList = [];
            timeTableDetailsData.map( ( timeTableDetailsDetails ) =>
            {
              let matValue = {
                yearDetailName: timeTableDetailsDetails.yearDetailName,
                divisionName: timeTableDetailsDetails.divisionName,
                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,
                batchShortName: timeTableDetailsDetails.batchShortName,

                teacher: timeTableDetailsDetails.teacher,
                lastName: timeTableDetailsDetails.lastName,
                firstName: timeTableDetailsDetails.firstName,
                fatherName: timeTableDetailsDetails.fatherName,
                motherName: timeTableDetailsDetails.motherName,
                middleName: timeTableDetailsDetails.middleName,

                teacherName: timeTableDetailsDetails.teacherName,
                teacherShortName: timeTableDetailsDetails.teacherShortName,

                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectDetailName: timeTableDetailsDetails.subjectDetailName,
                subjectName: timeTableDetailsDetails.subjectName,

                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                id: timeTableDetailsDetails.id,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };
              dataList.push( matValue );
            } );

            let days = {
              dayId: dayData.dayId,
              dayName: dayData.dayName,
              value: dataList,
            };
            return days;
            // return [];
          }
          let days = {
            dayId: dayData.dayId,
            dayName: dayData.dayName,
            value: [],
          };
          return days;
        } );
        if ( daysData.length !== 0 )
        {
          matData = { ...matData, dayList: daysData };
          matrixData.push( matData );
        }
      } );
    }
    let matList1 = [];
    let matList2 = matrixData.map( ( data ) =>
    {
      data.dayList.map( ( data2 ) =>
      {
        if ( data2.value.length !== 0 )
        {
          matList1.push( data );
        }
      } );
    } );
    const uniqueTags = [];
    matList1.map( ( item ) =>
    {
      var findItem = uniqueTags.find(
        ( x ) => x.timeSlotName === item.timeSlotName
      );
      if ( !findItem ) uniqueTags.push( item );
    } );

    this.setState( { matList: uniqueTags, loading: false } );
  }
  componentWillReceiveProps( nextProps )
  {
    if ( this.props.timeTableList !== nextProps.timeTableList )
    {
      if (
        this.props.timeTableList.timeTableDetailForTeacher !==
        nextProps.timeTableList.timeTableDetailForTeacher
      )
      {
        this.createMatrix(
          nextProps.timeTableList.timeTableDetailForTeacher,
          this.props.timeSlotList
        );
      }
    }
  }

  ChangeHandlerSearch = ( name, newValue ) =>
  {
    const { dayDataList } = this.state;

    if ( newValue !== null )
    {
      let user = this.props.timeTableList.teacherByDepartmentAccess.filter(
        ( data ) => data.id == newValue
      );
      this.setState( { [ name ]: newValue, loading: true, name: user[ 0 ].name } );
      this.props
        .getTeacherTimeTable( { userRegistrationId: newValue } )
        .then( ( response ) =>
        {
          if ( !response )
          {
            this.setState( { loading: false } );
            this.props.getAlert( { message: "Something went wrong" } );
          } else
          {
            this.createMatrix(
              this.props.timeTableList.timeTableDetailForTeacher,
              dayDataList
            );
          }
        } );
    } else
    {
      this.setState( { [ name ]: "" } );
    }
  };
  handleClosesTimeSlotCell = () =>
  {
    this.setState( {
      openTimeSlotCell: false,
      rows: [],
    } );
  };
  columns = [
    {
      name: "yearDetailName",
      title: "Year Details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "divisionName",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "batchName",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "subjectDetailName",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "floorName",
      title: "Floor Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "roomsName",
      title: "Room Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  pdfDetails = () =>
  {
    downloadF( {
      url:
        "/api/report/v1/get-time-table-detail-report/for-teacher?documentType=1&teacherIds=" +
        this.state.achievements,
      ext: "pdf",
      openNewTab: true,
    } );
  };
  render()
  {
    const { dayList, timeTableList } = this.props;
    const { matList, achievements, name } = this.state;
    return (
      <>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={12} md={7.5}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Time Table"
            />
          </Grid>

          <Grid container justifyContent="flex-end" item xs={12} sm={12} md={4}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label={name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={0.5}>
            <MyComponentWithIconProps
              statusImage={PictureAsPdfIcon}
              color="error"
              title="PDF"
              fontSize="medium"
              onClick={this.pdfDetails}
            />
          </Grid>
        </Grid>
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={5}
            xs={12}
            md={5}
            lg={5}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label="Select Teacher"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <AutoComplete
                keyColName={"id"}
                value={achievements}
                name={"achievements"}
                options={timeTableList.teacherByDepartmentAccess}
                onChange={this.ChangeHandlerSearch}
              // isError={institutionErr ? true : false}
              // errorText={institutionErr ? institutionErr : " "}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={7}
            xs={12}
            md={7}
            lg={7}
            container
            justifyContent="space-evenly"
          ></Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 600 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {dayList.map( ( columnName, index ) =>
                {
                  return (
                    <TableCell align="center" sx={{ width: "12%" }}>
                      {columnName.name}
                    </TableCell>
                  );
                } )}
              </TableRow>
            </TableHead>
            <TableBody>
              {matList.map( ( matrixDataRow, indexRow ) =>
              {
                if ( matrixDataRow.isRecesses !== 1 )
                {
                  return (
                    <TableRow>
                      <TableCell
                        component="td"
                        scope="row"
                        sx={{ width: "12%" }}
                      >
                        {matrixDataRow.timeSlotName}
                      </TableCell>
                      {dayList.map( ( columnName, index ) =>
                      {
                        let dayData = matrixDataRow.dayList.filter( ( item ) =>
                        {
                          if ( item.dayId === columnName.id )
                          {
                            return item.dayId === columnName.id;
                          }
                        } );

                        return (
                          <TableCell
                            sx={{
                              height: 100,
                            }}
                            onClick={( e ) =>
                              dayData[ 0 ].value.length !== 0 &&
                              this.setState( {
                                openTimeSlotCell: true,
                                rows: dayData[ 0 ].value,
                                day: columnName.name,
                                timeSlot: matrixDataRow.timeSlotName,
                              } )
                            }
                          >
                            {dayData[ 0 ].value.length !== 0 && (
                              <>
                                {dayData[ 0 ].value.map( ( timeTableData ) =>
                                {
                                  return (
                                    <>
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 12 }}
                                        label={timeTableData.yearDetailName}
                                      />
                                      <br />
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 12 }}
                                        label={timeTableData.batchShortName}
                                      />
                                      <br />
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 12 }}
                                        label={timeTableData.subjectDetail}
                                      />
                                    </>
                                  );
                                } )}
                              </>
                            )}
                          </TableCell>
                        );
                      } )}
                    </TableRow>
                  );
                } else
                {
                  return (
                    <TableRow sx={{ height: 30 }}>
                      <TableCell
                        component="td"
                        scope="row"
                        sx={{ width: "12%" }}
                      >
                        {matrixDataRow.timeSlotName}
                      </TableCell>

                      <TableCell colSpan={dayList.length} align="center">
                        Reccess Time
                      </TableCell>
                    </TableRow>
                  );
                }
              } )}
            </TableBody>
          </Table>
        </TableContainer>
        {this.state.loading && <Loading1 />}
        {this.state.openTimeSlotCell && (
          <ViewTimeTableCellPop
            open={this.state.openTimeSlotCell}
            handleCloses={this.handleClosesTimeSlotCell}
            rows={this.state.rows}
            isActionColActive={false}
            columns={this.columns}
            timeSlot={this.state.timeSlot}
            day={this.state.day}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ( state ) => ( {
  timeTableList: state.timeTable,
} );
const mapDispatchToProps = { downloadF, getTeacherTimeTable, getAlert };
export default connect( mapStateToProps, mapDispatchToProps )( MatrixForm );
