import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet, apiPost } from "../../../utils/api_service";
const initialState = {
  yearDetails: [],
  isFetch: false,
  studentList: [],
  isFetchStudentList: false,
  distinctDivision: [],
  isFetchDistinctDivision: false,
  internshipDeclaration: [],
  isInternshipDeclareFetch: false,
};
let placementDeclare = endpoints.placementDeclare;
let URL = endpoints.internshipDeclare;
let URL1 = endpoints.division;
let URL2 = endpoints.department;
const departmentId = localStorage.getItem("departmentId");

const placementDeclarationSlice = createSlice({
  name: "internshipDeclaration",
  initialState,
  reducers: {
    yearDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearDetails: row,
        isFetch: true,
      };
    },
    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentList: row,
        isFetchStudentList: true,
      };
    },
    distinctDivisionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        distinctDivision: row,
        isFetchDistinctDivision: true,
      };
    },

    internshipDeclareSuccess: (state = cloneDeep(initialState), action) => {
      const { internshipDeclareData } = action.payload;
      return {
        ...state,
        internshipDeclaration: internshipDeclareData,
        isInternshipDeclareFetch: true,
      };
    },
  },
});

export const {
  internshipDeclareSuccess,
  yearDetailsSuccess,
  studentListSuccess,
  distinctDivisionSuccess,
  placementDeclareSuccess,
} = placementDeclarationSlice.actions;

export default placementDeclarationSlice.reducer;

export const getYearDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL2 +
        "/get-year-detail-by-department-of-user?departmentIds=" +
        departmentId,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name:
              data1.year.programType.name +
              " - " +
              data1.programTitle.brName +
              " - " +
              data1.year.className,
            isActive: data1.isActive,
            //     type: data1.type,
            //     address: data1.address,
            //     emailId: data1.emailId,
            //     companyContactPersons: data1.companyContactPersons.map(
            //       (companyContactPersonsData,index) => {
            //         let contactPersonsData = {
            //           index:index+1,
            //           id: companyContactPersonsData.id,
            //           contactName: companyContactPersonsData.personName,
            //           contact: companyContactPersonsData.contactNo,
            //           emailId: companyContactPersonsData.emailId,
            //         };
            //         return contactPersonsData;
            //       }
            //     ),
          };
          return bData;
        });
        dispatch(yearDetailsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStudentList =
  ({ yearDetails, qualification, divisionIds, gapInYears, mathsCriteria }) =>
  async (dispatch) => {
    console.log(yearDetails);
    console.log(qualification);
    try {
      const response = apiPost({
        url:
          placementDeclare +
          "/get-eligible-student?yearDetailIds=" +
          yearDetails +
          "&divisionIds=" +
          divisionIds +
          "&gapInYears=" +
          gapInYears +
          "&mathsCriteria=" +
          mathsCriteria,
        postBody: qualification,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              lastName: data1.lastName,
              middleName: data1.middleName,
              firstName: data1.firstName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              name:
                data1.lastName.toUpperCase() +
                " " +
                data1.firstName.toUpperCase() +
                " " +
                (data1.fatherName == null || data1.fatherName == ""
                  ? " - "
                  : data1.fatherName.toUpperCase()) +
                " " +
                (data1.motherName == null || data1.motherName == ""
                  ? " - "
                  : data1.motherName.toUpperCase()),
              yearDetails: data1.capround,
              division: data1.meritNo,
              rollNo: data1.birthPlace,
            };
            return bData;
          });
          dispatch(studentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDistinctDivision =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + yearDetail + "/get-division-by-year-detail-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
            };
            return bData;
          });
          dispatch(distinctDivisionSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
// 63a65bee - 84e5 - 4804 - 9f35 - 4cac4c9d3bf5
export const getInternshipDeclareById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: placementDeclare + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          let list = data.data;
          console.log(list);
          let division =
            list.getDivisions === null
              ? ""
              : list.getDivisions.map((divisionData) => {
                  return divisionData.name;
                });

          let yearDetails =
            list.getYearDetails === null
              ? ""
              : list.getYearDetails.map((yearData) => {
                  return (
                    yearData.year.programType.name +
                    " " +
                    yearData.programTitle.brName +
                    " " +
                    yearData.year.className
                  );
                });
          console.log(yearDetails);
          let internshipDeclareData = {
            id: list.id === null ? "" : list.id,
            companyName: list.company.name === null ? "" : list.company.name,
            fromDate:
              list.fromDate === null || list.fromDate == ""
                ? "N/A"
                : list.fromDate,
            toDate:
              list.toDate === null || list.toDate === "" ? "N/A" : list.toDate,
            urlLink:
              list.urlLink === null || list.urlLink == ""
                ? "N/A"
                : list.urlLink,
            yearDetailId: list.yearDetailId === null ? "" : list.yearDetailId,
            interviewMode:
              list.interviewMode === null
                ? ""
                : list.interviewMode === 0
                ? "Offline"
                : "Online",
            placementPackage:
              list.placementPackage === null ? "N/A" : list.placementPackage,
            placementType:
              list.placementType === null
                ? ""
                : list.placementType === "0"
                ? "On Campus"
                : list.placementType === "1"
                ? "Off Campus"
                : list.placementType === "2"
                ? "Pool Campus"
                : "",
            designation:
              list.designation === null || list.designation == ""
                ? "N/A"
                : list.designation,
            declareDate: list.declareDate === null ? "N/A" : list.declareDate,
            durationFromDate:
              list.durationFromDate === null ? "N/A" : list.durationFromDate,
            durationToDate:
              list.durationToDate === null ? "N/A" : list.durationToDate,
            remark:
              list.remark === null || list.remark == "" ? "N/A" : list.remark,
            fromTime:
              list.fromTime === null || list.fromTime == ""
                ? "N/A"
                : list.fromTime,
            toTime:
              list.toTime === null || list.toTime == "" ? "N/A" : list.toTime,
            isBacklogAllowed: list.isBacklogAllowed === 0 ? "No" : "Yes",
            backlog:
              list.backlog === null || list.backlog == ""
                ? "N/A"
                : list.backlog,
            isQualificationCriteriaRequired:
              list.isQualificationCriteriaRequired == 1 ? "Yes" : "No",
            mathsCriteria: list.mathsCriteria == 0 ? "Both" : "No",
            isActive:
              list.isActive === null
                ? ""
                : list.isActive == 1
                ? "Active"
                : "InActive",
            venue: list.venue === null ? "" : list.venue,
            divisionId: list.divisionId === null ? "" : list.divisionId,
            // yearDetails:
            //   list.getYearDetails === null
            //     ? "N/A"
            //     : list.getYearDetails.year.programType.name +
            //       " " +
            //       list.getYearDetails.programTitle.brName +
            //       " " +
            //       list.getYearDetails.year.className,
            division: division ? division.join() : "N/A",
            yearDetailsName: yearDetails ? yearDetails.join() : "N/A",
            placementEligibleStudent: list.placementEligibleStudent.map(
              (placementEligibleStudent) => {
                let docs = {
                  lastName:
                    placementEligibleStudent.getUserRegistration !== null &&
                    placementEligibleStudent.getUserRegistration.lastName.toUpperCase(),
                  middleName:
                    placementEligibleStudent.getUserRegistration !== null &&
                    placementEligibleStudent.getUserRegistration.middleName.toUpperCase(),
                  firstName:
                    placementEligibleStudent.getUserRegistration !== null &&
                    placementEligibleStudent.getUserRegistration.firstName.toUpperCase(),
                  fatherName:
                    placementEligibleStudent.getUserRegistration !== null &&
                    placementEligibleStudent.getUserRegistration.fatherName.toUpperCase(),
                  motherName:
                    placementEligibleStudent.getUserRegistration !== null &&
                    placementEligibleStudent.getUserRegistration.motherName.toUpperCase(),
                  studentName:
                    placementEligibleStudent.getUserRegistration !== null
                      ? placementEligibleStudent.getUserRegistration.lastName.toUpperCase() +
                        " " +
                        placementEligibleStudent.getUserRegistration.firstName.toUpperCase() +
                        " " +
                        (placementEligibleStudent.getUserRegistration
                          .fatherName == null ||
                        placementEligibleStudent.getUserRegistration
                          .fatherName == ""
                          ? " - "
                          : placementEligibleStudent.getUserRegistration.fatherName.toUpperCase()) +
                        " " +
                        (placementEligibleStudent.getUserRegistration
                          .motherName == null ||
                        placementEligibleStudent.getUserRegistration
                          .motherName == ""
                          ? " - "
                          : placementEligibleStudent.getUserRegistration.motherName.toUpperCase())
                      : "-",
                  division:
                    placementEligibleStudent.getReportingDetail === null
                      ? "N/A"
                      : placementEligibleStudent.getReportingDetail.division
                          .name,
                  yearDet:
                    placementEligibleStudent.getReportingDetail === null
                      ? "N/A"
                      : placementEligibleStudent.getReportingDetail
                          .getYearDetail.year.programType.name +
                        " " +
                        placementEligibleStudent.getReportingDetail
                          .getYearDetail.programTitle.brName +
                        " " +
                        placementEligibleStudent.getReportingDetail
                          .getYearDetail.year.className,
                  rollNo: placementEligibleStudent.rollNo
                    ? placementEligibleStudent.rollNo
                    : "N/A",
                };
                console.log(placementEligibleStudent);
                return docs;
              }
            ),

            placementCriteria: list.placementCriteria.map(
              (placementCriteria) => {
                let docs = {
                  qualificationName: placementCriteria.getQualification.name,
                  minimumMarks: placementCriteria.minimumMarks,
                };
                console.log(placementCriteria);
                return docs;
              }
            ),
          };

          console.log(internshipDeclareData);
          dispatch(internshipDeclareSuccess({ internshipDeclareData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
