import endpoint from "../config/endpoints";

export const ExamMarksFillJson = {
  apiBaseURL: endpoint.examFormDeclare,
  screenTitle: "Exam Marks Filling",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,

  fieldMeta: [
    {
      label: "Exam Declare",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "examDeclareMaster",
      dataKey: "examDeclare",
      isMandatory: true,
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "subjectMaster",
      dataKey: "subject",
      isMandatory: true,
    },
  ],
};
