import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import { getPromotionRecords } from "../EmployeeDetails/employee.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { apiGet } from "../../utils/api_service";
import endpoints from "../../config/endpoints";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});
const BasicTable = ({
  employeeDetails,
  getAlert,
  getPromotionRecords,
  data,
  classes,
}) => {
  const [department, setDepartment] = useState("");
  useEffect(() => {
    getPromotionRecords({ userRegistrationId: data.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    if (department === "" && department !== null) {
      if (data.departmentAccess !== null && data.departmentAccess !== "") {
        setDepartment(null);
        apiGet({
          url:
            endpoints.department +
            "/get-department-by-department-ids?departmentIds=" +
            data.departmentAccess,
        }).then(({ data, success }) => {
          if (!success) {
            getAlert({ message: "Something went wrong" });
          } else {
            const departmentList = data.map((departmentData) => {
              return departmentData.name;
            });
            setDepartment(departmentList.join());
          }
        });
      } else if (
        data.departmentAccess === null ||
        data.departmentAccess === ""
      ) {
        setDepartment(" - ");
      }
    }
  }, []);
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Designation and Role"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Designation
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.department === null ? "-" : data.designation.name}
            </td>
            {/* <td className={classes.MuiTableHeadRoot}>&nbsp;{data.userRegistration.fatherName}</td> */}
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Department
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.department === null ? "-" : data.department.name}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Employee Type
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.employeeType === ""
                ? "-"
                : data.employeeType === null
                ? "-"
                : data.employeeType}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Department Access
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {department === "" ? "-" : department === null ? "-" : department}
            </td>
          </tr>
        </table>
      </div>
      <br></br>

      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Promotion Records"
      />
      <br></br>
      <br></br>

      <table className={classes.MuiTable}>
        <tr>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;Sr. No.
          </td>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;Designation
          </td>{" "}
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;Approval Date
          </td>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;Approval No
          </td>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;From Date
          </td>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            &nbsp;&nbsp;To Date
          </td>
        </tr>

        {employeeDetails.promotionRecords.map((detail, index) => (
          <tr>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;&nbsp;{index + 1}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;&nbsp;{detail.designation}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;&nbsp;{detail.approvalDate}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;&nbsp;{detail.approvalNo}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;&nbsp;{detail.fromDate}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;&nbsp;{detail.toDate}
            </td>
          </tr>
        ))}
      </table>
    </>
  );
};
const mapStateToProps = (state) => ({
  employeeDetails: state.employeeList,
});

const mapDispatchToProps = {
  getAlert,
  getPromotionRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BasicTable));
