import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  fromFees: [],
  isFetch: false,
};
let URL = endpoints.fromFees;
const fromFeesSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    fromFeesSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        fromFees: row,
        isFetch: true,
      };
    },
    fromFeesReset: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        fromFees: [],
        isFetch: false,
      };
    },
  },
});

export const { fromFeesReset, fromFeesSuccess } = fromFeesSlice.actions;

export default fromFeesSlice.reducer;

export const getFromFees = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(fromFeesSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetFromFees = () => async (dispatch) => {
  try {

    dispatch(fromFeesReset());

  } catch (e) {
    return console.error(e.message);
  }
};
