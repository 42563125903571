import endpoint from "../config/endpoints";

export const AwardJson = {
  apiBaseURL: endpoint.award,
  screenTitle: "Awards/Recognization",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Awards/Recognization",
  showExcelDownload: true,
  excelFileName: "Awards/Recognization",
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Award Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "awardName",
      isMandatory: true,
    },
    {
      label: "Orginisation",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "organization",
      isMandatory: true,
    },
    {
      label: "Award Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "awardDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },

    {
      label: "Award Duration",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      defaultValue: "Duration",
      depends: "awardFrom,awardTo",
      openOn: "Duration",
      masterName: "durationMaster",
      dataKey: "awardDuration",
      isRootLevelKey: true,
      isMandatory: false,
    },
    {
      label: "Award From Date",
      controlType: "hideDatepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "awardFrom",
      isMAxDate: false,
      maxDate: new Date(),
      tableDataKey: "awardDuration",

      open: "Duration",
      isMinDate: false,
      minDate: new Date(),
      // onSubmit: "compare",
      // onSubmitParameter: "awardTo-l",
    },

    {
      label: "Award To Date",
      controlType: "hideDatepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "awardTo",
      tableDataKey: "awardDuration",
      open: "Duration",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      onSubmit: "compare",
      onSubmitParameter: "awardFrom-g",
    },
    {
      label: "Level",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "LevelMaster",
      dataKey: "onLevel",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "LevelMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "levelData",
      tableDataKey: "onLevel",
      open: "other",
      error: "Level",
    },
  ],
};
