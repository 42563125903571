import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import AppliedFeedback from "./AppliedFeedback";
import { getAlert } from "../../../CommonActions/alert.slice";
import { FontAwesomeIconCompo } from "../../../components/Comman/IconButton";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import StudentFeedBackDetail from "./StudentFeedBackDetails";
import {
  getExternalStudentList,
  getDistinctTeacher,
  getFeedbackFromDtToDt,
  getStudentFeedbackDetails,
  getStudentListByDivisionID,
  getStudentListByDivisionIdAndTeacher,
  getStudentFeedbackByEventId,
  getInternalStudentList,
  getDivisionListByStudentFeedbackId,
} from "./feedback.slice";

import { getDivisionByEventId } from "../../Event/Event Management/event.slice.js";

const StudentFeedBackDetails = ({
  studentFeedback,
  getStudentFeedbackDetails,
  getAcademicYear,
  getExternalStudentList,
  getDivisionByEventId,
  getAlert,
  applieddata,
  getStudentFeedbackByEventId,
  appliedDataFromEvent,
  onCancel,
  getInternalStudentList,
  getDivisionListByStudentFeedbackId,
}) => {
  const [showEmpFeedBack, setShowEmpFeedBack] = React.useState(false);
  const [feedBack, setFeedBack] = React.useState({});
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handledChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    if (applieddata !== undefined) {
      if (applieddata.eventId !== "") {
        getDivisionByEventId({ eventId: applieddata.eventId }).then(
          (response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          }
        );
      }
      getAcademicYear().then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });

      getStudentFeedbackDetails().then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });

      getDivisionListByStudentFeedbackId({
        studentFeedbackId: applieddata.id,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });

      if (applieddata.isSubject == 0) {
        getExternalStudentList({ studentFeedbackId: applieddata.id }).then(
          (response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          }
        );
        if (applieddata.eventId == "") {
          getInternalStudentList({ studentFeedbackId: applieddata.id }).then(
            (response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
              }
            }
          );
        }
      }
    }
    if (appliedDataFromEvent !== undefined) {
      getStudentFeedbackByEventId({ id: appliedDataFromEvent.id }).then(
        (response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        }
      );
    }
  }, []);
  React.useEffect(() => {
    if (
      applieddata === undefined &&
      Object.keys(studentFeedback?.studentFeedback).length !== 0 &&
      !studentFeedback.externalStudentFetch
    ) {
      getDivisionByEventId({
        eventId: studentFeedback?.studentFeedback.eventId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
      if (studentFeedback?.studentFeedback.isSubject == 0) {
        getExternalStudentList({
          studentFeedbackId: studentFeedback?.studentFeedback.id,
        }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    }
  });
  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "studentName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },

    {
      name: "emailId",
      title: "E-mail",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },

    {
      name: "feedbackDate",
      title: "FeedBack Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },

    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];
  const rowDetails = (row) => {
    // this.setState({
    //   showEmpFeedBack: true,
    //   studFeedBack: row,
    //   showTable: false,
    // });
    setFeedBack(row);
    setShowEmpFeedBack(true);
    console.log(row);
  };
  const onClosedForm = () => {
    if (showEmpFeedBack) {
      setShowEmpFeedBack(false);
      setFeedBack({});
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
        {!showEmpFeedBack && (
          <>
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ marginBottom: 2 }} item xs={12} sm={10.5} md={10.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Student Feedback"
                  />
                </Grid>
                <Grid sx={{ marginBottom: 2 }} item xs={12} sm={1.5} md={1.5}>
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <>
                  <Grid item xs={12} sm={2} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Feedback Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        applieddata !== undefined
                          ? applieddata.feedbackHead
                          : Object.keys(studentFeedback?.studentFeedback)
                              .length !== 0
                          ? studentFeedback?.studentFeedback.feedbackHead
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1.5} md={1.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Instruction"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4.5} md={4.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        applieddata !== undefined
                          ? applieddata.feedbackDetails
                          : Object.keys(studentFeedback?.studentFeedback)
                              .length !== 0
                          ? studentFeedback?.studentFeedback.feedbackDetails
                          : "-"
                      }
                    />
                  </Grid>
                </>
              </Grid>
              {/* <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
              
              </Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                {applieddata !== undefined && applieddata.id === "" ? (
                  <>
                    <Grid item xs={12} sm={2} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Year detail"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          applieddata !== undefined
                            ? applieddata.yearDetailName
                            : Object.keys(studentFeedback?.studentFeedback)
                                .length !== 0
                            ? studentFeedback?.studentFeedback.yearDetailName
                            : "-"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={1.5} md={1.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Date"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4.5} md={4.5}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          applieddata !== undefined &&
                          applieddata.fromDate + "/" + applieddata.toDate
                        }
                        label={
                          applieddata !== undefined
                            ? applieddata.fromDate + "/" + applieddata.toDate
                            : Object.keys(studentFeedback?.studentFeedback)
                                .length !== 0
                            ? studentFeedback?.studentFeedback.fromDate +
                              "/" +
                              studentFeedback?.studentFeedback.toDate
                            : "-"
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={2} md={2}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Date"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          applieddata !== undefined &&
                          applieddata.fromDate + "/" + applieddata.toDate
                        }
                        label={
                          applieddata !== undefined
                            ? applieddata.fromDate + "/" + applieddata.toDate
                            : Object.keys(studentFeedback?.studentFeedback)
                                .length !== 0
                            ? studentFeedback?.studentFeedback.fromDate +
                              "/" +
                              studentFeedback?.studentFeedback.toDate
                            : "-"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                  </>
                )}
              </Grid>
            </Grid>
            {applieddata !== undefined && applieddata.isSubject == 0 ? (
              <>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handledChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={
                        "Internal Student " +
                        studentFeedback?.internalStudentData.length
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        "External Student " +
                        studentFeedback?.externalStudentData.length
                      }
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <AppliedFeedback
                    applieddata={applieddata}
                    rowDetails={rowDetails}
                    onCancel={onCancel}
                    value={value}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <DynamicTable
                    data={studentFeedback?.externalStudentData}
                    tableHead={columns}
                    showPegination={false}
                    showDetails={true}
                    rowDetails={rowDetails}
                  />
                </TabPanel>
              </>
            ) : applieddata === undefined &&
              Object.keys(studentFeedback?.studentFeedback).length !== 0 &&
              studentFeedback?.studentFeedback.isSubject == 0 ? (
              <>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handledChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={
                        "Internal Student " +
                        studentFeedback?.studentFeedback.length
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        "External Student " +
                        studentFeedback?.externalStudentData.length
                      }
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <AppliedFeedback
                    applieddata={
                      applieddata !== undefined
                        ? applieddata
                        : studentFeedback?.studentFeedback
                    }
                    rowDetails={rowDetails}
                    onCancel={onCancel}
                    value={value}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <DynamicTable
                    data={studentFeedback?.externalStudentData}
                    tableHead={columns}
                    showPegination={false}
                    showDetails={true}
                    rowDetails={rowDetails}
                  />
                </TabPanel>
              </>
            ) : applieddata !== undefined &&
              Object.keys(studentFeedback?.studentFeedback).length === 0 &&
              studentFeedback?.studentFeedback.isSubject !== 0 ? (
              <>
                <AppliedFeedback
                  applieddata={applieddata}
                  rowDetails={rowDetails}
                  onCancel={onCancel}
                  value={value}
                />
              </>
            ) : applieddata !== undefined &&
              Object.keys(studentFeedback?.studentFeedback).length !== 0 &&
              studentFeedback?.studentFeedback.isSubject !== 0 ? (
              <>
                <AppliedFeedback
                  applieddata={applieddata}
                  rowDetails={rowDetails}
                  onCancel={onCancel}
                  value={value}
                />
              </>
            ) : (
              ""
            )}
          </>
        )}
        {showEmpFeedBack && (
          <>
            <StudentFeedBackDetail
              editData={feedBack}
              onCancel={onClosedForm}
            />
          </>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({ studentFeedback: state.studentFeedback });
const mapDispatchToProps = {
  getStudentFeedbackDetails,
  getDistinctTeacher,
  getAcademicYear,
  getExternalStudentList,
  getAlert,
  getStudentFeedbackByEventId,
  getDivisionByEventId,
  getInternalStudentList,
  getDivisionListByStudentFeedbackId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFeedBackDetails);
