import { Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Loading1 } from "../../../components/Loading1";
import MatrixForm from "./MatrixForm";
import GenerateTimeTableForm from "./GenerateTimeTableForm";
import { resetReducList } from "../../../CommonActions/User.action";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
};

class FeeStructureHeadIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      open: false,
    };
  }
  componentDidMount() {
    this.props.resetReducList();
  }
  saveHandler = () => {
    this.setState({
      open: true,
    });
  };
  cancleHandler = () => {
    this.setState({
      open: false,
    });
  };
  submitHandler = (data) => {
    this.setState({
      open: false,
    });
  };
  render() {
    const { open } = this.state;
    return (
      <>
        <Paper sx={{ p: 1, pt: 2, borderRadius: 2 }} elevation={0}>
          {open && this.props.timeSlotList.isFetch && (
            <MatrixForm
              dayList={[
                { id: "0", name: "Monday" },
                { id: "1", name: "Tuesday" },
                { id: "2", name: "Wednesday" },
                { id: "3", name: "Thursday" },
                { id: "4", name: "Friday" },
                { id: "5", name: "Saturday" },
                { id: "6", name: "Sunday" },
              ]}
              timeSlotList={this.props.timeSlotList?.timeSlot}
              onCancel={this.cancleHandler}
              backToList={this.props.onCancle}
            />
          )}
          {!open && (
            <GenerateTimeTableForm
              onCancle={this.props.onCancle}
              timeTableHeaderData={this.props.timeTableHeaderData}
              onSave={this.saveHandler}
            />
          )}
        </Paper>
        {/* {isLoading && <Loading1 />}
        {!feeStructuredList.isFeesStructureHeaderFetch && <Loading1 />} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  timeSlotList: state.timeSlot,
});
const mapDispatchToProps = { resetReducList };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeeStructureHeadIndex);
