import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { AdmissionModuleReportsJson } from "../../DynamicFormsJson/AdmissionModuleReports";
import { getCategory } from "../../Pages/Masters/OnlineModule/Category/category.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { resetReducList } from "../../CommonActions/User.action";
import {
  getProgramTypeInstituteWise,
  getProgramTitleByProgTypeId,
} from "../Masters/OnlineModule/ProgramType/programType.slice";
import { getAlert } from "../../CommonActions/alert.slice";

class AdmissionModuleReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        academicYear: this.props.academicYearList?.academicYear,
        yearDetails: this.props.yearDetailList?.yearDetails,
        division: this.props.yearDetailList?.distinctDivision,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        categoryMaster: this.props.categoryList?.category,
        programTypeMaster: this.props.programTypeList?.programTypeInstituteWise,
        programTitleMaster: this.props.programTypeList?.progTitleByProgType,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getCategory().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getProgramTypeInstituteWise().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.resetReducList();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.programTypeList !== nextProps.programTypeList) {
      if (
        this.props.programTypeList.programTypeInstituteWise !==
        nextProps.programTypeList.programTypeInstituteWise
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programTypeMaster:
              nextProps.programTypeList.programTypeInstituteWise,
          },
        });
      }
    }
    if (this.props.programTypeList !== nextProps.programTypeList) {
      if (
        this.props.programTypeList.progTitleByProgType !==
        nextProps.programTypeList.progTitleByProgType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programTitleMaster: nextProps.programTypeList.progTitleByProgType,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList.category !== nextProps.categoryList.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryMaster: nextProps.categoryList.category,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.yearDetails !==
        nextProps.yearDetailList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.yearDetailList.yearDetails,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.distinctDivision !==
        nextProps.yearDetailList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            division: nextProps.yearDetailList.distinctDivision,
          },
        });
      }
    }
    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
  }

  getListById = (data) => {
    if (data.yearDetailId && data.academicYearId) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetailId,
          academicId: data.academicYearId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.yearDetailId) {
      this.props
        .getDistinctDivision({
          yearDetail: data.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.programType) {
      this.props
        .getProgramTitleByProgTypeId({
          progTypeId: data.programType,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  rowData = [
    {
      index: 1,
      reportName: "Fees Collection Summary Report (D0, D2)",
      filter: "academicYearId,yearDetailIds",
      condition: 1,
      url: "/api/report/v1/fees-collection-summary-report/get-fees-collection-summary-report?academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
    },
    {
      index: 2,
      reportName: "Fees Collection Detailed Report (D0, D2)",
      filter: "academicYearId,yearDetailIds",
      condition: 1,
      url: "/api/report/v1/daily-fees-collection-report/get-daily-fees-collection-report?academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
    },
    {
      index: 3,
      reportName: "Admission Category Summary Report (D0, D6)",
      filter: "academicYearId,categoryIds",
      condition: 1,
      url: "/api/report/v1/admission-category-report/get-admission-category-report?academicYearId={academicYearId}&categoryIds={categoryIds}",
    },
    {
      index: 4,
      reportName: "Admission Category Detailed Report (D0, D6)",
      filter: "academicYearId,categoryIds",
      condition: 1,
      url: "/api/report/v1/admission-category-detail-report/get-admission-category-detail-report?academicYearId={academicYearId}&categoryIds={categoryIds}",
    },
    {
      index: 5,
      reportName: "Admission Status Summary Report (D0, D2)",
      filter: "academicYearId,yearDetailIds",
      condition: 1,
      url: "/api/report/v1/admission-status-report/get-admission-status-report?academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
    },
    {
      index: 6,
      reportName: "Students Year Wise Detail Report (D0, D1, D4)",
      filter: "academicYearId,yearDetailId,divisionIds",
      condition: 1,
      url: "/api/report/v1/enrollment-detail-report/get-daily-fees-collection-report?academicYearId={academicYearId}&divisionIds={divisionIds}",
    },
    {
      index: 7,
      reportName:
        "Reporting Student's List ( Date Wise ) Report (D0, D1, D5, D9, D10)",
      filter: "academicYearId,yearDetailId,semisterDetailId,fromDate,toDate",
      condition: 1,
      url: "/api/report/v1/reporting-student-list-report/get-reporting-student-list-report?yearDetailId={yearDetailId}&semisterDetailId={semisterDetailId}&academicYearId={academicYearId}&fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 8,
      reportName: "Students Branch Wise Detailed Report (D0, D7, D8)",
      filter: "academicYearId,programType,programTitle",
      condition: 1,
      url: "/api/report/v1/branch-wise-report/get-branch-wise-report?academicYearId={academicYearId}&programTitleId={programTitle}",
    },
    {
      index: 9,
      reportName: "Admission Canceled Report (D0, D2)",
      filter: "academicYearId,yearDetailIds",
      condition: 1,
      url: "/api/report/v1/admission-cancel-report/get-admission-cancel-report?academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
    },
    {
      // /api/exportexcel/v1/student-details-excel-report?response&academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailIds=71993caf-e0a6-4edc-8f53-4251ee2b6e7c,83bd6686-cd24-4559-80ac-8029a337b9a3,c29a8b5f-b125-4507-81e5-880467971aeb,729047a6-8475-4d9e-8a25-03a571cbeb07
      index: 10,
      reportName: "General Registration Number Report (D0, D2)",
      filter: "academicYearId,yearDetailIds",
      condition: 0,
      excelUrl:
        "/api/exportexcel/v1/student-details-excel-report?response&academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
      url: "/api/report/v1/student-details-report/get-general-register-report?academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
    },
  ];
  condition = [
    {
      title: "condition",
      value: 0,
      iconHide: "showHeadPdf",
    },
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={AdmissionModuleReportsJson.screenTitle}
          fieldMeta={AdmissionModuleReportsJson.fieldMeta}
          buttonCenter={AdmissionModuleReportsJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={AdmissionModuleReportsJson.searchButton}
          PDFButton={AdmissionModuleReportsJson.PDFButton}
          EXCELButton={AdmissionModuleReportsJson.EXCELButton}
          baseIdColumn={AdmissionModuleReportsJson.baseIdColumn}
          apiBaseURL={AdmissionModuleReportsJson.apiBaseURL}
          marginTop={AdmissionModuleReportsJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
          condition={this.condition}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  yearDetailList: state.internshipDeclaration,
  categoryList: state.category,
  rollNoData: state.rollNo,
  programTypeList: state.programType,
});
const mapDispatchToProps = {
  resetReducList,
  getAcademicYear,
  getYearDetails,
  getDistinctDivision,
  getCategory,
  getSemesterDetailsByYearAndAcademic,
  getProgramTypeInstituteWise,
  getProgramTitleByProgTypeId,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmissionModuleReports);
