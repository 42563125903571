import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { LabelCompo } from "../../components/Comman/Label";
import { showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import AutoComplete from "../../components/Comman/AutoComplete";
import { logOut } from "../Login/login.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { Loading1 } from "../../components/Loading1";
import {
  onlyNumber,
  validPassword,
} from "../../components/Comman/Util/Validations";

class ForgotPasswordLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      enterOtp: "",
      confirmPassword: "",
      newPassword: "",
      formErrors: {},
      studentData: {},
    };
  }

  // rowEdit = (params) => {
  //   console.log(params);
  //   this.setState({
  //     popUpValue: true,
  //     open: true,
  //     studentData: params,
  //   });
  // };

  // onClose = () => {
  //   this.setState({
  //     popUpValue: false,
  //     open: false,
  //     studentData: {},
  //   });
  // };

  handleCloses = () => {
    this.setState({
      popUpValue: false,
      open: false,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Student Name",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "yearDetails",
      title: "Year details",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "division",
      title: "Division",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "20%",
    },
  ];

  handleClickOpen() {
    this.setState({
      open: false,
    });
  }

  componentDidMount() {}

  submitHandler = (e) => {
    e.preventDefault();
    const { id, newPassword, confirmPassword } = this.state;
    let docs = {
      id: id === 0 ? 0 : id,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    if (this.passwordFormValidation()) {
      this.props.OnSave(docs);
    }
  };

  saveHandler = (e) => {};

  logOutHandler = () => {
    console.log(window.location.host);
    console.log(localStorage.getItem("username"));

    swal({
      title: "Are you sure?",
      text: "Would you want to send link on registered Email Id?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          loading: true,
        });
        // hardcode url given for local testing
        apiGet({
          url:
            endpoint.linkSendOnEmail +
            "/institute?url=" +
            window.location.host +
            "&username=" +
            localStorage.getItem("username"),
        }).then(({ success }) => {
          if (success) {
            this.setState({
              loading: false,
            });
            swal("Link sent successfully").then((value) => {
              if (value) {
                localStorage.removeItem("token");
                this.props.logOut();
                window.location.replace("/login");
              }
            });
          } else {
            this.setState({
              loading: false,
            });
            this.props.showNotification({
              msg: "Failed to send link",
              severity: "error",
            });
          }
        });
      }
    });
  };

  handleFormValidationUserId(name) {}

  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpEmptyErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  passwordFormValidation() {
    const { newPassword, confirmPassword } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (confirmPassword != newPassword) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }

    if (newPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (!validPassword.test(newPassword)) {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.specialPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <Grid
                container
                justifyContent="space-evenly"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Change Password Link"
                />
              </Grid>
            </Grid>
          </Grid>
          <br />

          <Grid container justifyContent="space-evenly" direction="row">
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <Grid
                container
                justifyContent="space-evenly"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Get reset password link"
                  onClick={this.logOutHandler}
                />
              </Grid>
            </Grid>
          </Grid>
          <br />

          <br />
          {loading && <Loading1 />}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  logOut,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordLink);
