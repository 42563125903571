
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints'
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  semisterDetails: [],
  isFetch: false,
};
let URL = endpoints.semisterDetails;
const semisterDetailsSlice = createSlice({
  name: "semisterDetails",
  initialState,
  reducers: {
    semisterDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        semisterDetails: row,
        isFetch: true,
      };
    },
  },
});

export const {
  semisterDetailsSuccess,
} = semisterDetailsSlice.actions;

export default semisterDetailsSlice.reducer;

export const getSemisterDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}'
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            // name: data1.academicYearId,
            fromDate: data1.semisterStartDate,
            toDate: data1.semisterEndDate,
            semisterName: data1.semister.name,
            semisterId: data1.semister.id,
            academicYearName: data1.getAcademicYear.name,
            academicYearId: data1.getAcademicYear.id,
            //  yearDetailName:data1.getYearDetailId.programTitle.brName+" - "+data1.getYearDetailId.year.className,
            //yearDetailId:data1.getYearDetailId.id,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(semisterDetailsSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {

    return console.error(e.message);
  }
};


