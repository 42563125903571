import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import endpoint from "../../../../../config/endpoints";
import * as msgConstant from "../../../../../config/messageconstant";
import { AdminAcademicPanelJson } from "../../../../../DynamicFormsJson/AdminAcademicPanel";
import { showNotification } from "../../../../Landing/Landing.slice";
import { adminAcademicPanelColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../../../utils/api_service";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import * as myConstClass from "../../../../../config/messageconstant";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { Loading1 } from "../../../../../components/Loading1";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminAcademicPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      dynamicMasterData: {},
      subModuleList: [
        {
          index: 1,
          subModuleName: "Committee / Faculty Activity",
          subModuleMapping: "admin-extended-activity",
        },
        {
          index: 2,
          subModuleName: "Innovative Teaching Learning Technologies",
          subModuleMapping: "admin-innovative-teaching",
        },
        {
          index: 3,
          subModuleName: "Research Journal Publication",
          subModuleMapping: "admin-reserch-journal-publication",
        },
        {
          index: 4,
          subModuleName: "Research Guidance",
          subModuleMapping: "admin-research-guidance",
        },
        {
          index: 5,
          subModuleName: "Research Project",
          subModuleMapping: "admin-research-project",
        },
        {
          index: 6,
          subModuleName: "Adding Qualificaton",
          subModuleMapping: "admin-adding-qualification",
        },
        {
          index: 7,
          subModuleName: "Award",
          subModuleMapping: "admin-awards",
        },
        {
          index: 8,
          subModuleName: "Paper Poster Presented",
          subModuleMapping: "admin-paper-poster-presented",
        },
        {
          index: 9,
          subModuleName: "Consultancy",
          subModuleMapping: "admin-consultancy",
        },
      ],
    };
  }
  componentDidMount() {
    // const moduleSubModuleList = localStorage.getItem("moduleSubModule");
    // const subModList = JSON.parse(moduleSubModuleList).filter(
    //   (data) => data.moduleId == "63"
    // );
    // console.log(subModList);
    // let index = 0;
    // let row = [];
    // subModList.map((data1) => {
    //   index = index + 1;
    //   let bData = {
    //     moduleSubs: data1.moduleSubs.map((subModuleData, index) => {
    //       subModuleData = {
    //         index: index + 1,
    //         ...subModuleData,
    //       };
    //       return subModuleData;
    //     }),
    //   };
    //   row.push(bData);
    //   return data1;
    // });
    // console.log(row);
    // this.setState({
    //   subModuleList: row[0],
    // });
  }

  goToAdminFacultyDetailForm = (row) => {
    console.log(row);
    window.location.replace("/" + row.subModuleMapping);
  };
  render() {
    const { dynamicMasterData, subModuleList } = this.state;
    console.log(subModuleList.moduleSubs);

    return (
      <>
        <DynamicMainScreen
          screenTitle={AdminAcademicPanelJson.screenTitle}
          fieldMeta={AdminAcademicPanelJson.fieldMeta}
          showPdfDownload={AdminAcademicPanelJson.showPdfDownload}
          showExcelDownload={AdminAcademicPanelJson.showExcelDownload}
          pdfFileName={AdminAcademicPanelJson.pdfFileName}
          excelFileName={AdminAcademicPanelJson.excelFileName}
          showAddButton={AdminAcademicPanelJson.showAddButton}
          tableColumnsToFilter={AdminAcademicPanelJson.tableColumnsToFilter}
          dynamicMasterData={dynamicMasterData}
          showSaveNextBtn={false}
          tableColumns={adminAcademicPanelColumns}
          tableData={subModuleList}
          getTableData={this.props.getResearchWithoutDate}
          type={1}
          showDeleteIcon={false}
          showEditIcon={false}
          showSend={true}
          rowSend={this.goToAdminFacultyDetailForm}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AdminAcademicPanel);
