import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import MessageIcon from "@mui/icons-material/Message";
import PercentIcon from "@mui/icons-material/Percent";
import ReportIcon from "@mui/icons-material/Report";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { onToggle } from "../../CommonActions/common.slice";
import PieChart from "../../components/Charts/PieChart";
import AutoComplete from "../../components/Comman/AutoComplete";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { Loading1 } from "../../components/Loading1";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import studyImg from "../../Images/studyImage.png";
import user from "../../Images/user.jpeg";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { getEmpProfile } from "../EmployeeDetails/employee.slice";
import {
  getBatch,
  getDivision,
  getSubject,
} from "../StudentAttendance/StudentAttendanceForms/studentAttendance.slice";
import StudentPreview1 from "../StudentPreviewById/index";
import {
  getSemesterDetailsStudentForm,
  getYearDetailsStudentForm,
} from "../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import { getGraphData, teacherDashboard } from "./teacherDashboard.slice";
import endpoints from "../../config/endpoints";
import { Avatar } from "@mui/material";
import { apiGet } from "../../utils/api_service";
class MainDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showFeedback: false,
    showPreview: false,
    date1: new Date(),
    newDate: "",
    currentDate: new Date(),
    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
  };

  componentDidMount() {
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const ay = localStorage.getItem("acadamicYearId");
    const person = localStorage.getItem("person");
    this.setState({
      nameFirstLetter: person !== null ? person.toUpperCase() : "",
    });
    let profilePic = localStorage.getItem("profilePic");
    console.log("profilePic" + profilePic);
    if (profilePic === "null" || profilePic === "") {
      this.setState({
        profile: "",
      });
    } else {
      apiGet({
        url: endpoints.fileDownload + "/" + profilePic,
      }).then(({ data, success }) => {
        if (success) {
          let fileRes = data.data;
          this.setState({ profile: fileRes });
        }
        return success;
      });
    }

    this.setState({
      showLoader: true,
      yearDetail: yearDetailId,
      semester: semisterDetailId,
      division: divisionId,
    });
    this.props.teacherDashboard().then((success) => {
      if (!success.success) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      } else {
        const [dday, mmonth] =
          this.props.teacherDashboardDetails.teacherDashboard !== {}
            ? ""
            : this.props.teacherDashboardDetails.teacherDashboard.dateOfBirth.split(
                "-"
              );
        let newCurrDate = moment(this.state.curDate).format("DD-MM");
        const [curday, curmonth] = newCurrDate.split("-");
        this.setState({
          // newDate: monthsRound,
          dobDay: dday,
          dobMonth: mmonth,
          currenDate: curday,
          currentMonth: curmonth,
          timeTableList:
            this.props.teacherDashboardDetails.teacherDashboard.timetableList,
        });
      }
    });

    this.props.getYearDetailsStudentForm().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    this.props
      .getSemesterDetailsStudentForm({ yearDetailId: yearDetailId })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });

    this.props.getDivision({ id: semisterDetailId }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    this.props
      .getBatch({ semister: semisterDetailId, division: divisionId })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  }
  viewProfile = () => {
    window.location.replace("/empPreview");
  };
  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };
  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    const { semester, batch, division, subject } = this.state;
    let bData = [];

    if (newValue !== null) {
      if (name == "yearDetail") {
        this.setState({
          showLoader: true,
        });
        this.props
          .getSemesterDetailsStudentForm({
            yearDetailId: newValue,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }

      if (name == "semester") {
        this.setState({
          showLoader: true,
        });
        this.props
          .getDivision({
            id: newValue,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }

      if (name == "division") {
        this.setState({
          showLoader: true,
        });
        this.props
          .getBatch({
            semister: semester,
            division: newValue,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }

      if (name == "batch") {
        this.setState({
          showLoader: true,
        });
        this.props
          .getSubject({
            semister: semester,
            division: division,
            batch: newValue,
            subjectDetailId: true,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
      if (name == "subject") {
        this.setState({
          showLoader: true,
        });
        this.props
          .getGraphData({
            subjectDetailId: newValue,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
              this.props.teacherDashboardDetails.graphData.map((data) => {
                if (data !== null) {
                  bData.push(data);
                }
                bData.push({ y: 100 - data.y, label: "Incomplete" });
              });
              this.setState({ pieChartData: bData });
            }
            this.setState({
              showLoader: false,
            });
          });
      }

      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  addSuggestions = (e) => {
    e.preventDefault();
    window.location.replace("/suggestions-form");
  };

  addEmaterial = (e) => {
    e.preventDefault();
    window.location.replace("/ematerial-form");
  };

  addNotice = (e) => {
    e.preventDefault();
    window.location.replace("/view-teacher-notice");
  };

  addViva = (e) => {
    e.preventDefault();
    window.location.replace("/teacherViva");
  };

  addFeedback = (e) => {
    e.preventDefault();
    window.location.replace("/employee-feedback-details");
  };

  addSuggestion = (e) => {
    e.preventDefault();
    window.location.replace("/suggestion");
  };

  addWorkload = (e) => {
    e.preventDefault();
    window.location.replace("/view-time-table");
  };

  libraryColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "title",
      title: "Book Title",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "mediaType",
      title: "Media Type",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "status",
      title: "Status",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "returnDate",
      title: "Return Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
  ];

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "yearDetailName",
      title: "Year Detail",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "divisionName",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "batchName",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "subjectName",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];

  render() {
    const { employeeDetails, teacherDashboardDetails } = this.props;
    const {
      dobDay,
      dobMonth,
      currenDate,
      currentMonth,
      timeTableList,
      semester,
      yearDetail,
      division,
      batch,
      subject,
      pieChartData,
      isLoading,
      name,
      nameFirstLetter,
    } = this.state;
    return (
      <>
        {!this.state.showPreview ? (
          <div id="layout-wrapper">
            {/* ========== App Menu ========== */}

            {/* Left Sidebar End */}
            {/* Vertical Overlay*/}
            <div className="vertical-overlay" />
            {/* ============================================================== */}
            {/* Start right Content here */}
            {/* ============================================================== */}
            <div className="main-content">
              <div className="page-content">
                <div className="container-fluid">
                  {/* start page title */}
                  {/* <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Student Dashboard </h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div> */}
                  {teacherDashboardDetails.isFetchTeacherDashboard &&
                  dobDay == currenDate &&
                  dobMonth == currentMonth ? (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              <div className="birthday-bg">
                                <span className="element">
                                  Wish you Happy Birthday &nbsp;
                                  {Object.keys(
                                    teacherDashboardDetails.teacherDashboard
                                  ).length !== 0
                                    ? teacherDashboardDetails.teacherDashboard
                                        .firstName
                                    : ""}
                                  &nbsp;!
                                </span>
                              </div>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              <div className="birthday-bg">
                                <span className="element">
                                  Welcome Back&nbsp;
                                  {Object.keys(
                                    teacherDashboardDetails.teacherDashboard
                                  ).length !== 0
                                    ? teacherDashboardDetails.teacherDashboard
                                        .firstName
                                    : ""}
                                  &nbsp;!
                                </span>
                              </div>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card">
                        <div className="bg-primary bg-soft">
                          <div className="row">
                            <div className="col-7">
                              <div className="text-primary p-3 ">
                                <h5 className="text-primary mb-1">
                                  Welcome Back !
                                </h5>
                                <p> </p>
                              </div>
                            </div>
                            <div className="col-5 align-self-end">
                              <img
                                src={studyImg}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="profile-user-wid mb-2">
                                {/*avatar-md*/}
                                {this.state.profile !== "" ? (
                                  <Avatar
                                    sx={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                    }}
                                    src={this.state.profile}
                                  ></Avatar>
                                ) : (
                                  <Avatar
                                    sx={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                      bgcolor: "red",
                                      fontSize: 100,
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    {nameFirstLetter.charAt(0)}
                                  </Avatar>
                                )}
                                {/* <img
                                  src={
                                    this.state.profile === ""
                                      ? user
                                      : this.state.profile
                                  }
                                  alt=""
                                  style={{
                                    height: 130,
                                    width: 130,
                                    marginLeft: -11,
                                    borderRadius: "50%",
                                    padding: "0.25rem",
                                    backgroundColor: "#e9ebec",
                                    border: "1px solid #e9ebec",
                                  }}
                                /> */}
                              </div>
                            </div>
                            <div className="col-sm-8">
                              <div className="pt-4">
                                <div className="a mb-4">
                                  <h5
                                    style={{ overflow: "visible" }}
                                    className="font-size-15 text-truncate mb-1"
                                  >
                                    {Object.keys(
                                      teacherDashboardDetails.teacherDashboard
                                    ).length !== 0
                                      ? teacherDashboardDetails.teacherDashboard.firstName.charAt(
                                          0
                                        ) +
                                        " . " +
                                        teacherDashboardDetails.teacherDashboard.middleName.charAt(
                                          0
                                        ) +
                                        " . " +
                                        teacherDashboardDetails.teacherDashboard
                                          .lastName +
                                        " ( " +
                                        teacherDashboardDetails.teacherDashboard
                                          .employeeCode +
                                        " )"
                                      : ""}
                                  </h5>
                                  <p className="text-muted mb-0 text-truncate">
                                    {Object.keys(
                                      teacherDashboardDetails.teacherDashboard
                                    ).length !== 0 &&
                                    teacherDashboardDetails.teacherDashboard
                                      .employeeDesignation !== null
                                      ? teacherDashboardDetails.teacherDashboard
                                          .employeeDesignation
                                      : "-"}
                                  </p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    {Object.keys(
                                      teacherDashboardDetails.teacherDashboard
                                    ).length !== 0
                                      ? teacherDashboardDetails.teacherDashboard
                                          .employeeEmail
                                      : ""}
                                  </h5>
                                  <p className="text-muted">Email Address</p>
                                </div>

                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    {Object.keys(
                                      teacherDashboardDetails.teacherDashboard
                                    ).length !== 0
                                      ? teacherDashboardDetails.teacherDashboard
                                          .dateOfBirth
                                      : ""}
                                  </h5>
                                  <p className="text-muted">Date Of Birth</p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    {/* {this.state.newDate === ""
                                      ? ""
                                      : this.state.newDate}  Months ago */}

                                    {Object.keys(
                                      teacherDashboardDetails.teacherDashboard
                                    ).length !== 0 &&
                                    teacherDashboardDetails.teacherDashboard
                                      .employeeJoiningDate !== null
                                      ? teacherDashboardDetails.teacherDashboard
                                          .employeeJoiningDate
                                      : "-"}
                                  </h5>

                                  <p className="text-muted">Joining Date</p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    {Object.keys(
                                      teacherDashboardDetails.teacherDashboard
                                    ).length !== 0 &&
                                    teacherDashboardDetails.teacherDashboard
                                      .employeeDepartment !== null
                                      ? teacherDashboardDetails.teacherDashboard
                                          .employeeDepartment
                                      : "-"}
                                  </h5>
                                  <p className="text-muted">Department</p>
                                </div>
                                <div
                                  className="mt-4"
                                  style={{ textAlign: "right" }}
                                >
                                  <button
                                    onClick={this.viewProfile}
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light btn-sm"
                                  >
                                    View Profile
                                    <i className="mdi mdi-arrow-right ms-1" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.addEmaterial}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                E-material
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      <a
                                        href="/ematerial-form"
                                        className="counter-value"
                                      >
                                        {Object.keys(
                                          teacherDashboardDetails.teacherDashboard
                                        ).length !== 0
                                          ? teacherDashboardDetails
                                              .teacherDashboard
                                              .employeeMaterialCount
                                          : ""}
                                      </a>
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total E-Material Uploaded
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={AddToDriveIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                              <a
                                href="/ematerial-form"
                                className="counter-value"
                              ></a>
                            </div>
                          </div>
                        </div>
                        {/* new */}

                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body" onClick={this.addNotice}>
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Notice
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      <a
                                        href="/view-teacher-notice"
                                        className="counter-value"
                                      >
                                        {Object.keys(
                                          teacherDashboardDetails.teacherDashboard
                                        ).length !== 0
                                          ? teacherDashboardDetails
                                              .teacherDashboard
                                              .employeeNoticeCount
                                          : ""}
                                      </a>
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total Notice Count
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={ReportIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body" onClick={this.addViva}>
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Remaining Viva
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      <a
                                        href="/teacherViva"
                                        className="counter-value"
                                      >
                                        {Object.keys(
                                          teacherDashboardDetails.teacherDashboard
                                        ).length !== 0
                                          ? teacherDashboardDetails
                                              .teacherDashboard
                                              .employeeRemainingVivaList
                                          : ""}
                                      </a>
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Remaining Viva count
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={PercentIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.addFeedback}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                FEEDBACKS
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      <a
                                        href="/employee-feedback-details"
                                        className="counter-value"
                                      >
                                        {Object.keys(
                                          teacherDashboardDetails.teacherDashboard
                                        ).length !== 0
                                          ? teacherDashboardDetails
                                              .teacherDashboard
                                              .employeeFeedbackCount
                                          : ""}
                                      </a>
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Pending Feedback Count
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={CalendarMonthOutlinedIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.addSuggestion}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Suggestions
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      <a
                                        href="/suggestion"
                                        className="counter-value"
                                      >
                                        {Object.keys(
                                          teacherDashboardDetails.teacherDashboard
                                        ).length !== 0
                                          ? teacherDashboardDetails
                                              .teacherDashboard
                                              .employeeSuggestionCount
                                          : ""}
                                      </a>
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total Suggestions Count
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={MessageIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.addSuggestions}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.addWorkload}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                EMPLOYEE WORKLOAD
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      <a
                                        href="/view-time-table"
                                        className="counter-value"
                                      >
                                        {Object.keys(
                                          teacherDashboardDetails.teacherDashboard
                                        ).length !== 0
                                          ? teacherDashboardDetails
                                              .teacherDashboard
                                              .employeeWorkloadCount
                                          : ""}
                                      </a>
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total Workload Count
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={ContentPasteOffIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Object.keys(teacherDashboardDetails.teacherDashboard)
                  .length !== 0 ? (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="text-primary p-3 ">
                          <p className="text-uppercase fw-medium text-muted text-truncate">
                            Library Details
                          </p>
                          <div className="col-lg-12">
                            <div className="d-flex align-items-end justify-content-between ">
                              <div>
                                <h5 class="font-size-15">
                                  {"Borrower ID : "}{" "}
                                  {this.props.teacherDashboardDetails
                                    .teacherDashboard.libraryResponseList !== ""
                                    ? this.props.teacherDashboardDetails
                                        .teacherDashboard.libraryResponseList
                                        .borrid
                                    : "-"}
                                </h5>
                              </div>
                              <div>
                                <h5 class="font-size-15">
                                  {"Membership Expiry Date : "}{" "}
                                  {this.props.teacherDashboardDetails
                                    .teacherDashboard.libraryResponseList !== ""
                                    ? this.props.teacherDashboardDetails
                                        .teacherDashboard.libraryResponseList
                                        .validUntil
                                    : "-"}
                                </h5>
                              </div>
                              <div>
                                <h5 class="font-size-15">
                                  {"Pending Fees : "}{" "}
                                  {this.props.teacherDashboardDetails
                                    .teacherDashboard.libraryResponseList !== ""
                                    ? this.props.teacherDashboardDetails
                                        .teacherDashboard.libraryResponseList
                                        .pendingFees
                                    : "-"}
                                </h5>
                              </div>
                            </div>
                          </div>

                          {this.props.teacherDashboardDetails.teacherDashboard
                            .libraryResponseList !== "" &&
                          this.props.teacherDashboardDetails.teacherDashboard
                            .libraryResponseList.warningMessage ? (
                            <div className="col-lg-12">
                              <br />

                              <div className="d-flex align-items-end justify-content-between ">
                                <div>
                                  <h5 class="font-size-15">
                                    {"Message : "}{" "}
                                    {this.props.teacherDashboardDetails
                                      .teacherDashboard.libraryResponseList !==
                                    ""
                                      ? this.props.teacherDashboardDetails
                                          .teacherDashboard.libraryResponseList
                                          .warningMessage
                                      : ""}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {this.props.teacherDashboardDetails.teacherDashboard
                          .libraryResponseList !== "" &&
                          this.props.teacherDashboardDetails.teacherDashboard
                            .libraryResponseList.lentItems.length !== 0 && (
                            <div className="card-body">
                              <DynamicTable
                                data={
                                  this.props.teacherDashboardDetails
                                    .teacherDashboard.libraryResponseList
                                    .lentItems
                                }
                                tableHead={this.libraryColumns}
                                showPegination={false}
                                isActionColActive={false}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Subject And Teacher Table
                        </h4>
                      </div>
                      <div className="card-body">
                        <DynamicTable
                          // data={teacherDashboardDetails?.teacherDashboard.timetableList.length!==0 ? teacherDashboardDetails?.teacherDashboard.timetableList :[]}
                          data={timeTableList}
                          tableHead={this.columns}
                          showPegination={false}
                          isActionColActive={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header border-bottom-dashed align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Overall Syllabus Percentage
                    </h4>
                  </div>

                  <div className="card-body">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-4">
                          <AutoComplete
                            keyColName={"id"}
                            placeHolder="Year Details"
                            value={yearDetail}
                            name={"yearDetail"}
                            options={
                              this.props.employeeWorkList
                                ?.studentAttendanceFormYeardetails
                            }
                            onChange={this.ChangeHandlerSearch}
                          />
                        </div>

                        <div className="col-lg-4">
                          <AutoComplete
                            keyColName={"id"}
                            value={semester}
                            placeHolder="Semester"
                            name={"semester"}
                            options={
                              this.props.employeeWorkList
                                ?.attendanceSemesterDetailsStudent
                            }
                            onChange={this.ChangeHandlerSearch}
                          />
                        </div>

                        <div className="col-lg-4">
                          <AutoComplete
                            keyColName={"id"}
                            value={division}
                            placeHolder="Division"
                            name={"division"}
                            options={this.props.studentAttendanceList?.division}
                            onChange={this.ChangeHandlerSearch}
                          />
                        </div>

                        <div className="col-lg-4">
                          <AutoComplete
                            keyColName={"id"}
                            value={batch}
                            placeHolder="Batch"
                            name={"batch"}
                            options={this.props.studentAttendanceList?.batch}
                            onChange={this.ChangeHandlerSearch}
                          />
                        </div>

                        <div className="col-lg-4">
                          <AutoComplete
                            keyColName={"id"}
                            value={subject}
                            placeHolder="Subject"
                            name={"subject"}
                            options={this.props.studentAttendanceList?.subject}
                            onChange={this.ChangeHandlerSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {teacherDashboardDetails.isFetchGraph && subject !== "" ? (
                    <PieChart pieChartData={pieChartData} />
                  ) : (
                    ""
                  )}
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-6">2022 © Byte Elephants. </div>
                      <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                          {" "}
                          Designed &amp; Developed by Byte Elephants
                          Technologies PVT LTD{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        ) : (
          <StudentPreview1 GoBack={this.GoBack} />
        )}
        {this.state.showLoader && (
          <div>
            <h4 className="text-primary mb-1">
              <span className="counter-value" data-target="559.25">
                <Loading1 size={50} />
              </span>
            </h4>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  employeeDetails: state.employeeList,
  teacherDashboardDetails: state.teacherDashboard,
  semesterList: state.rollNo,
  yearDetails: state.internshipDeclaration,
  timeSlotList: state.timeSlot,
  studentAttendanceList: state.studentAttendance,
  employeeWorkList: state.employeeWorkload,
});

const mapDispatchToProps = {
  onToggle,
  getEmpProfile,
  getAlert,
  teacherDashboard,
  getSemesterDetailsStudentForm,
  getYearDetailsStudentForm,
  getDivision,
  getBatch,
  getSubject,
  getGraphData,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
