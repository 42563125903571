import { Grid, Paper, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import TestPayCode from "./TestPayCode";
import { apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { getAlert } from "../../CommonActions/alert.slice";
import { getStudentDashboardInfo } from "../Dashboard/Studentdashboard.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { Loading1 } from "../../components/Loading1";
import { FontAwesomeIconCompo } from "../../components/Comman/IconButton";
import {
  getStudentTransactionList,
  getYears,
  getApplicantAdmission,
  getPendingFees,
  getTransactionDetailById,
  getTransactionList,
  saveApplicantFee,
} from "../ApplicantAdmission/applicantAdmission.slice";
import { getFeesStructureHeadById } from "../Masters/Admission/FeeStructureHead/feeStructureHead.slice";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { downloadF } from "../PDF/PDFDownload";
import Swal from "sweetalert2";

let docs;
class StudentPayment extends Component {
  state = {
    id: 0,
    pendingAmount: "",
    amountPaid: "",
    openPaymentGatway: false,
    showPayment: false,
    loading: false,
    dataToSend: "",
    year: "",
    firstname: "",
    lastname: "",
    mobile: "",
    email: "",
    formErrors: [],
    showButton: false,
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "transactionNo",
      title: "Transaction Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "transactionDate",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "payType",
      title: "Payment Type",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "amount",
      title: "Paid Fees",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
    {
      name: "refund",
      title: "Refund",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  changeHandler = (event) => {
    const { name, value } = event.target;
    if (value === "") {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
    if (onlyNumber.test(value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { pendingAmount, amountPaid } = this.state;
    let docs = {
      programTitleId: "6d5906c1-a3a9-454d-b590-e359d190b013",
      returnURL: "https://eduerpsuccess.aaryatechindia.in?userId=",
      amount: this.props.applicantAdmissionData?.PendingList.pendingAmount,
    };

    this.setState({
      loading: true,
    });
    apiPost({
      url: endpoint.paymentUrl + "/get-hash",
      postBody: docs,
    }).then(({ data, success }) => {
      if (success) {
        this.setState({
          openPaymentGatway: true,
          dataToSend: data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  changeHandlerSearch = (name, newValue) => {
    const {
      getFeesStructureHeadById,
      getApplicantAdmission,
      getPendingFees,
      getTransactionDetailById,
      getTransactionList,
    } = this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      localStorage.setItem("admissionRegDetId", newValue);
      this.setState({
        loading: true,
      });
      getApplicantAdmission({ id: newValue }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showPayment: true,
            loading: true,
          });

          if (
            response.data.admissionRegistration.getUserRegistration !== null
          ) {
            this.setState({
              firstname:
                response.data.admissionRegistration.getUserRegistration
                  .firstName,
              lastname:
                response.data.admissionRegistration.getUserRegistration
                  .lastName,
              mobile:
                response.data.admissionRegistration.getUserRegistration
                  .studentMobile,
              email:
                response.data.admissionRegistration.getUserRegistration
                  .studentEmail,
            });
            getPendingFees({
              id: response.data.admissionRegistration.getUserRegistration.id,
            }).then((response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
                this.setState({
                  loading: false,
                });
              } else {
                this.setState({
                  showButton: true,
                  loading: false,
                });
              }
            });

            getTransactionList({
              userRegistrationId:
                response.data.admissionRegistration.getUserRegistration.id,
              academicYearId:
                response.data.admissionRegistration.academicYearId,
              admissionRegistrationDetailId: newValue,
            }).then((response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
                this.setState({
                  loading: false,
                });
              } else {
                this.setState({
                  loading: false,
                });
              }
            });
          }
        }
      });
    } else {
      this.setState({ [name]: "" });
    }
  };

  componentDidMount() {
    let response = window.location.search;
    const urlParams = new URLSearchParams(response);
    const product = urlParams.get("returndata");
    const transactionId = urlParams.get("txnid");
    const userRegId = urlParams.get("userId");
    // let convertedJson = atob(product);

    if (product !== null) {
      Swal.fire({
        icon: "error",
        title: "Payment failed",
        text: "",
        confirmButtonColor: "#1f89f4",
      }).then((okLogin) => {
        this.setState({
          loading: true,
        });
        this.props
          .getYears({
            flag: false,
            userRegistrationId: "",
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: "Something went wrong",
              });
              this.setState({
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          });

        let amt = localStorage.getItem("pendingFees");
        this.setState({
          pendingAmount: amt,
        });
      });
      apiGet({
        url:
          endpoint.paymentUrl +
          "-transaction/update-status?transactionId=" +
          transactionId +
          "&status=fail&data=" +
          product +
          "&mihpayId=13750ID",
      }).then(({ success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    } else {
      this.setState({
        loading: true,
      });
      this.props
        .getYears({
          flag: false,
          userRegistrationId: "",
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({
              message: "Something went wrong",
            });
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        });

      let amt = localStorage.getItem("pendingFees");
      this.setState({
        pendingAmount: amt,
      });
    }
  }

  cancleHander = () => {
    this.setState({
      openPaymentGatway: false,
    });
  };
  pdfLedgerDownload = () => {
    const { year } = this.state;
    downloadF({
      url:
        "/api/report/v1/leisure-report?admissionRegistrationDetailId=" +
        year +
        "&documentType=1",
    });
  };

  pdfDetailsId = async (rowData) => {
    downloadF({
      url:
        "/api/report/v1/fee-receipt-report/" + rowData.id + "/get-fee-receipt",
    });
  };

  render() {
    const {
      pendingAmount,
      loading,
      openPaymentGatway,
      dataToSend,
      year,
      showPayment,
      showButton,
      firstname,
      lastname,
      mobile,
      email,
    } = this.state;
    const { amountError } = this.state.formErrors;
    const { studentDashboardData, yearList, applicantAdmissionData } =
      this.props;
    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: "10px" }}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label="Student Transaction Details"
            />
          </Grid>
        </Paper>
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Year Detail"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={year}
                    name={"year"}
                    fullWidth
                    options={yearList?.year}
                    onChange={this.changeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>

              {showPayment && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Total Fees : "
                        />{" "}
                        {applicantAdmissionData?.PendingList
                          .totalApplicableFees === undefined
                          ? ""
                          : applicantAdmissionData?.PendingList
                              .totalApplicableFees}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Total Pending Fees : "
                        />{" "}
                        {applicantAdmissionData?.PendingList.pendingAmount ===
                        undefined
                          ? ""
                          : applicantAdmissionData?.PendingList.pendingAmount}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  ></Grid>
                  <br />
                  <DynamicTable
                    data={yearList?.transactionList}
                    tableHead={this.columns}
                    pdfDetailsId={this.pdfDetailsId}
                    showHeadPdf={true}
                    showPegination={false}
                  />
                  <br />
                  {showButton && (
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        container
                        justifyContent="left"
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Ledger Report"
                          onClick={this.pdfLedgerDownload}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={1}
                        lg={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="outlined"
                          name="Cancel"
                          onClick={this.onCancelHandler}
                          fullWidth={true}
                        />
                      </Grid>
                      {applicantAdmissionData?.PendingList.pendingAmount !==
                        undefined &&
                        applicantAdmissionData?.PendingList.pendingAmount !==
                          0 && (
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={1}
                            lg={1}
                            container
                            justifyContent="space-evenly"
                          >
                            <ButtonCompo
                              size="medium"
                              type="submit"
                              variant="contained"
                              name="Pay"
                              onClick={this.submitHandler}
                              fullWidth={true}
                            />
                          </Grid>
                        )}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
        {openPaymentGatway && (
          <TestPayCode
            dataToSend={dataToSend}
            handleClose={this.cancleHander}
            firstName={firstname}
            lastName={lastname}
            mobileNo={mobile}
            emailId={email}
          />
        )}
        {loading && <Loading1 />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  applicantAdmissionData: state.applicantAdmission,
  studentDashboardData: state.studentDashboard,
  yearList: state.applicantAdmission,
});
const mapDispatchToProps = {
  getStudentDashboardInfo,
  getAlert,
  getYears,
  getFeesStructureHeadById,
  getApplicantAdmission,
  getPendingFees,
  getTransactionDetailById,
  getTransactionList,
  downloadF,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentPayment);
