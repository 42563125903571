import endpoint from "../config/endpoints";

export const ResearchJournalPublicationJson = {
  apiBaseURL: endpoint.resourcePerson,

  screenTitle: "Research Journal Publication",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Title of the paper",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "pressTitle",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "pressDate",
      isMAxDate: false,
      // maxDate: new Date(),
      isMinDate: true,
      // minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "Journal Name With Volume Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "issnIsbn",
      isMandatory: true,
    },
    {
      label: "Impact Factor",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "impactFactor",
      isMandatory: false,
    },
    {
      label: "First Author/corresponding Author",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isFirstAuthor",
      defaultValue: 1,
      radioGroupItems: [
        {
          label: "Yes",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ],
      isMandatory: true,
    },
    {
      label: "",
      controlType: "hideTextfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "otherAuthor",
      rootValue: true,
      tableDataKey: "isFirstAuthor",
      open: 0,
    },
    {
      label: "Journal is on UGC",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isUgcCare",
      defaultValue: 1,
      radioGroupItems: [
        {
          label: "Yes",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ],
      isMandatory: true,
    },

    // {
    //   label: "Other Author",
    //   controlType: "textfield",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "otherAuthor",
    //   isMandatory: true,
    // },
  ],
};
