import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  resourceProject: [],
  isFetch: false,
};
let URL = endpoints.resourceProject;

const resourceProjectSlice = createSlice({
  name: "resourceProject",
  initialState,
  reducers: {
    researchProjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        resourceProject: row,
        isFetch: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { researchProjectSuccess, resetSuccess } =
  resourceProjectSlice.actions;

export default resourceProjectSlice.reducer;

export const getResearchProject = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            projectTitle: data1.projectTitle,
            sponsoredBy: data1.sponsoredBy,
            date: data1.date,
            projectType: data1.projectType,
            isPrincipalInvestigator: data1.isPrincipalInvestigator,
            coInvestigator: data1.coInvestigator,
            scheme: data1.scheme,
            duration: data1.duration,
            projectAmount: data1.projectAmount,
            sanctionAmount: data1.sanctionAmount,
            details: data1.details,
            status: data1.status,
            userRegistration:
              data1.userRegistration === null ? "" : data1.userRegistration.id,
            departmentId:
              data1.userRegistration === null
                ? ""
                : data1.userRegistration.userRegistrationDepartment.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(researchProjectSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminResearchProject =
  ({ userRegistration }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/by-user-registration?userRegistrationId=" + userRegistration,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              projectTitle: data1.projectTitle,
              sponsoredBy: data1.sponsoredBy,
              date: data1.date,
              projectType: data1.projectType,
              isPrincipalInvestigator: data1.isPrincipalInvestigator,
              coInvestigator: data1.coInvestigator,
              scheme: data1.scheme,
              duration: data1.duration,
              projectAmount: data1.projectAmount,
              sanctionAmount: data1.sanctionAmount,
              details: data1.details,
              status: data1.status,
              userRegistration:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.id,
              departmentId:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.userRegistrationDepartment.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(researchProjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetData = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getResearchProjectByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-research-project-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              projectTitle: data1.projectTitle,
              sponsoredBy: data1.sponsoredBy,
              date: data1.date,
              projectType: data1.projectType,
              isPrincipalInvestigator: data1.isPrincipalInvestigator,
              coInvestigator: data1.coInvestigator,
              scheme: data1.scheme,
              duration: data1.duration,
              projectAmount: data1.projectAmount,
              sanctionAmount: data1.sanctionAmount,
              details: data1.details,
              status: data1.status,
              userRegistration:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.id,
              departmentId:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.userRegistrationDepartment.id,
            };
            row.push(bData);
            return data1;
          });
          dispatch(researchProjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getResearchProjectWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-research-project-list-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            projectTitle: data1.projectTitle,
            sponsoredBy: data1.sponsoredBy,
            date: data1.date,
            projectType: data1.projectType,
            isPrincipalInvestigator: data1.isPrincipalInvestigator,
            coInvestigator: data1.coInvestigator,
            scheme: data1.scheme,
            duration: data1.duration,
            projectAmount: data1.projectAmount,
            sanctionAmount: data1.sanctionAmount,
            details: data1.details,
            status: data1.status,
            userRegistration:
              data1.userRegistration === null ? "" : data1.userRegistration.id,
            departmentId:
              data1.userRegistration === null
                ? ""
                : data1.userRegistration.userRegistrationDepartment.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(researchProjectSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
