import
{
  FormControl,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import * as myConstClass from "../../../config/messageconstant";
import { Link as RouterLink } from "react-router-dom";
import
{
  getStudentList,
  resetStudentListSuccess,
} from "./GenerationOfBatches.slice";
import
{
  getYearDetails,
  getDistinctDivision,
} from "../../InternshipDeclaration/InternshipDeclataration.slice"; // import AccountFees from "../AccountFees/index";
import { getAlert } from "../../../CommonActions/alert.slice";
import GenarationOfBatchesTable from "./GenerationOfBatchesTable";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import
{
  getSemesterDetailsByYearAndAcademic,
  getStudentListByReportingIds,
} from "../../RollNoList/ViewOfRollList/rollno.slice";
import { showNotification } from "../../Landing/Landing.slice";

class GenerationOfBatches extends React.Component
{
  state = {
    id: 0,
    batchComplited: 0,
    noOfStudent: "",
    programTitle: "",
    semisterDetail: "",
    yearDetail: "",
    divisionId: "",
    batches: "",
    shortName: "",
    selectedStudentIndexList: [],
    StudentList: [],
    formErrors: [],
  };
  resetSelectedStudentIndexList = () =>
  {
    this.setState( {
      selectedStudentIndexList: [],
    } );
  };
  onDateChange = ( dataKey ) => ( date ) =>
  {
    this.setState( {
      [ dataKey ]: date,
    } );
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    console.log( name, value );
    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  componentDidMount()
  {
    const {
      batchData,
      getStudentList,
      getStudentListByReportingIds,
      resetStudentListSuccess,
      getYearDetails,
    } = this.props;

    getYearDetails().then( ( response ) =>
    {
      if ( !response )
      {
        getAlert( { message: "Something went wrong" } );
      }
    } );
    console.log( batchData );
    if (  Object.keys( batchData ).length !== 0 )
    {
      getStudentListByReportingIds( {
        reportingIds: batchData.reportingDetailId,
      } ).then( ( response ) =>
      {
        if ( !response )
        {
          getAlert( { message: "Something went wrong" } );
        } else
        {
          getStudentList( {
            semisterDetail: batchData.semisterId,
            division: batchData.divisionId,
          } ).then( ( response ) =>
          {
            if ( !response )
            {
              getAlert( { message: "Something went wrong" } );
            }
          } );
        }
      } );
      this.setState( {
        ...this.state,
        semisterDetail: batchData.semisterId,
        divisionId: batchData.divisionId,
        batches: batchData.name,
        shortName: batchData.shortName,
        // noOfStudent: batchData.studentCount,
        id: batchData.id,
        yearDetail: batchData.yearDetailId,
      } );
    } else
    {
      resetStudentListSuccess();
    }
    const listener = ( event ) =>
    {
      if ( event.code === "Enter" || event.code === "NumpadEnter" )
      {
        event.preventDefault();
        this.searchData();
      }
    };
    document.addEventListener( "keydown", listener );
    return () =>
    {
      document.removeEventListener( "keydown", listener );
    };
  }

  componentWillReceiveProps( nextProps )
  {
    if ( this.props.studentBatchList !== nextProps.studentBatchList )
    {
      if (
        this.props.studentBatchList?.studentBatch !==
        nextProps.studentBatchList?.studentBatch
      )
      {
        if ( this.props.rollNoData.studentList.length != 0 )
        {
          let selectedIndex = [];
          let list = [];
          this.props.rollNoData.studentList.map( ( stud, index ) =>
          {
            const studentBatchData =
              nextProps.studentBatchList?.studentBatch.filter( ( row ) =>
              {
                if ( row.id == stud.id )
                {
                  return row.id == stud.id;
                }
              } );
            if ( studentBatchData.length != 0 )
            {
              list.push( studentBatchData[ 0 ].id );
              selectedIndex.push( studentBatchData[ 0 ].id );
              this.setState( {
                selectedStudentIndexList: selectedIndex,
                StudentList: list,
              } );
            }
          } );
        }
        if ( this.state.noOfStudent !== "" )
        {
          let selectedIndex = [];
          let list = [];

          for ( var i = 0; i < this.state.noOfStudent; i++ )
          {
            if ( nextProps.studentBatchList?.studentBatch.length > i )
            {
              selectedIndex.push(
                nextProps.studentBatchList?.studentBatch[ i ].id
              );
            }
          }
          list = nextProps.studentBatchList?.studentBatch.slice(
            0,
            this.state.noOfStudent
          );

          this.setState( {
            selectedStudentIndexList: selectedIndex,
            StudentList: selectedIndex,
          } );
        }
      }
    }
  }

  ChangeHandlerSearch = ( name, newValue ) =>
  {
    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;
    if ( newValue !== null )
    {
      if ( name == "yearDetail" )
      {
        this.setState( {
          divisionId: '',
          semisterDetail: ''
        } )
        getDistinctDivision( { yearDetail: newValue } ).then( ( response ) =>
        {
          if ( !response )
          {
            this.props.getAlert( { message: "Something went wrong" } );
          }
        } );
        getSemesterDetailsByYearAndAcademic( {
          yearId: newValue,
          academicId: "",
        } ).then( ( response ) =>
        {
          if ( !response )
          {
            this.props.getAlert( { message: "Something went wrong" } );
          }
        } );
      }
      this.setState( { [ name ]: newValue } );
    } else
    {
      this.setState( { [ name ]: "" } );
    }
  };

  handleFormValidation()
  {
    const { programTitle, semisterDetail, yearDetail, divisionId, batches } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( semisterDetail.toString().trim() === "" || semisterDetail == null )
    {
      formIsValid = false;
      formErrors[ "semisterDetailError" ] = myConstClass.semisterDetailMsg;
    }

    if ( yearDetail.toString().trim() === "" || yearDetail == null )
    {
      formIsValid = false;
      formErrors[ "yearDetailError" ] = myConstClass.yearDetailMsg;
    }
    if ( divisionId.toString().trim() === "" || divisionId == null )
    {
      formIsValid = false;
      formErrors[ "divisionError" ] = myConstClass.divEmptyMsg;
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  handleFormValidation1()
  {
    const { batches, shortName, selectedStudentIndexList } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( batches.toString().trim() === "" || batches == null )
    {
      formIsValid = false;
      formErrors[ "batchesError" ] = myConstClass.batchMsg;
    }
    if ( shortName.toString().trim() === "" || shortName == null )
    {
      formIsValid = false;
      formErrors[ "shortNameError" ] = myConstClass.shortNameMsg;
    }
    if ( selectedStudentIndexList.length === 0 )
    {
      formIsValid = false;
      formErrors[ "emptyListError" ] = "Please select atleast one student";
    }
    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  searchData = () =>
  {
    const { getStudentList, onSave, getAlert, showNotification } = this.props;
    const { semisterDetail, divisionId } = this.state;

    if ( this.handleFormValidation() )
    {
      getStudentList( {
        semisterDetail: semisterDetail,
        division: divisionId,
      } ).then( ( response ) =>
      {
        if ( !response )
        {
          getAlert( { message: "Something went wrong" } );
        }
      } );
      // getCompanyList({ fromDate: fromDate, toDate: toDate, internType: internType });
    }
  };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };


  rowAppliedList = ( data ) =>
  {
    console.log( data );
    const { comanyData } = this.state;
    this.setState( {
      interestedStudent: true,
      listOfComp: false,
      companyData: data,
    } );
  };

  selectedListHandler = ( studentList ) =>
  {
    console.log( studentList );

    this.setState( {
      selectedStudentIndexList: studentList,
      StudentList: studentList,
    } );
  };

  cancelHandler = () =>
  {
    console.log( "data1111" );
    this.props.CancelHandler();
  };

  saveHandler = () =>
  {
    const { getStudentList, onSave, getAlert, showNotification } = this.props;
    const {
      semisterDetail,
      id,
      divisionId,
      batches,
      selectedStudentIndexList,
      shortName,
    } = this.state;
    console.log( "selectedStudentIndexList" );
    console.log( selectedStudentIndexList );

    let docs = {
      division: {
        id: divisionId,
      },
      semisterDetail: {
        id: semisterDetail,
      },
      batchName: batches,
      shortName: shortName,
      reportingDetailId: selectedStudentIndexList.toString(),
      studentCount: selectedStudentIndexList.length,
      isActive: 1,
    };
    console.log( docs );
    if ( id != 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }
    if ( this.handleFormValidation1() )
    {
      apiPost( {
        url: endpoint.batch,
        postBody: docs,
      } ).then( ( { success } ) =>
      {
        if ( success )
        {
          showNotification( { msg: "Data saved successfully" } );

          window.location.replace( "/batch-list" );
        } else
        {
          this.props.getAlert( {
            message: "Failed to save",
          } );
        }
      } );
    }
  };

  // changeHandler = ( event ) =>
  // {
  //   const { name, value } = event.target;
  //   this.setState( {
  //     ...this.state,
  //     [ name ]: value,
  //   } );
  // };

  saveAndProceedHandler = () =>
  {
    const { getStudentList, onSave, getAlert, showNotification } = this.props;
    const {
      semisterDetail,
      id,
      divisionId,
      batches,
      selectedStudentIndexList,
      selectedList,
      shortName,
    } = this.state;

    let docs = {
      division: {
        id: divisionId,
      },
      semisterDetail: {
        id: semisterDetail,
      },
      batchName: batches,
      reportingDetailId: selectedStudentIndexList.toString(),
      studentCount: selectedStudentIndexList.length,
      shortName: shortName,
      isActive: 1,
    };
    console.log( docs );
    if ( id != 0 )
    {
      docs = {
        ...docs,
        id: id,
      };
    }
    if ( this.handleFormValidation1() && this.state.noOfStudent !== "" )
    {
      apiPost( {
        url: endpoint.batch,
        postBody: docs,
      } ).then( ( { success } ) =>
      {
        if ( success )
        {
          let startPoint = +this.state.batchComplited + +this.state.noOfStudent;
          let batch = +this.state.noOfStudent + +startPoint;
          if ( batch >= this.props.studentBatchList?.studentBatch.length )
          {
            console.log( "batch" );
            console.log( batch );
            console.log( "this.props.studentBatchList?.studentBatch.length" );
            console.log( this.props.studentBatchList?.studentBatch.length );
          }
          showNotification( { msg: "Data saved successfully" } );
          this.setState( {
            batchComplited: startPoint,
            batches: "",
          } );
          let selectedIndex = [];
          let list = [];
          for ( var i = startPoint; i < batch; i++ )
          {
            if ( this.props.studentBatchList?.studentBatch.length > i )
            {
              selectedIndex.push(
                this.props.studentBatchList?.studentBatch[ i ].id
              );
            }
            // if (this.props.studentBatchList?.studentBatch.length === i) {
            //   window.location.replace("/generation-of-batches");
            // }
          }

          list = this.props.studentBatchList?.studentBatch.slice(
            startPoint,
            batch
          );

          this.setState( {
            selectedStudentIndexList: selectedIndex,
            StudentList: selectedIndex,
          } );
        } else
        {
          this.props.getAlert( {
            message: "Failed to save",
          } );
        }
      } );
    }
    else if ( this.state.noOfStudent === "" )
    {
      let formErrors = {};
      formErrors[ "noOfStudentError" ] = "*number of student required";
      this.setState( { formErrors: formErrors } );
    }
  };
  getRow = ( e ) =>
  {
    // setSelection(e);
    this.setState( {
      selectedStudentIndexList: e,
      StudentList: e,
    } );
  };
  render()
  {
    const {
      selectedStudentIndexList,
      StudentList,
      semisterDetail,
      yearDetail,
      divisionId,
      noOfStudent,
      batches,
      shortName,
    } = this.state;
    const {
      semisterDetailError,
      divisionError,
      noOfStudentError,
      yearDetailError,
      batchesError,
      shortNameError,
      emptyListError,
    } = this.state.formErrors;
    const { internshipDeclarationList, rollNoData, studentBatchList } =
      this.props;
    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                spacing={2}
                columnSpacing={1}
                container
                sx={{ m: 1 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {/* <Grid item xs={12} sm={12} md={0.5}></Grid> */}
                  <Grid container xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label="Batches Generation"
                      />
                    </Grid>
                    <Grid
                      sx={{ mb: 2, pr: 3 }}
                      item
                      xs={2}
                      md={6}
                      container
                      justifyContent="flex-end"
                    >
                      <Link
                        color="primary"
                        variant="body2"
                        underline="none"
                        component={RouterLink}
                        to="/batch-list"
                      >
                        <ButtonCompo
                          size="button"
                          type="button"
                          variant="outlined"
                          name="Back to List"
                          onClick= {this.cancelHandler }
                          
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={internshipDeclarationList?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Semester Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={semisterDetail}
                      name={"semisterDetail"}
                      options={rollNoData?.semisterDetailsList.filter( ( data ) => data.isActive == 1 )}
                      onChange={this.ChangeHandlerSearch}
                      isError={semisterDetailError ? true : false}
                      errorText={
                        semisterDetailError ? semisterDetailError : " "
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Division"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={divisionId}
                      name={"divisionId"}
                      options={internshipDeclarationList?.distinctDivision}
                      onChange={this.ChangeHandlerSearch}
                      isError={divisionError ? true : false}
                      errorText={divisionError ? divisionError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Number of Student"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="noOfStudent"
                      size="small"
                      type="number"
                      value={noOfStudent}
                      fullWidth
                      onChange={this.changeHandler}
                      error={noOfStudentError ? true : false}
                      helperText={noOfStudentError ? noOfStudentError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  container
                  justifyContent="center"
                  sx={{ mr: 2 }}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Search"
                      fullWidth={true}
                      onClick={this.searchData}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />

              {studentBatchList?.isFetchStudentList && (
                <>
                  <Grid item sx={{ m: 1 }}>
                    <FormHelperText error>
                      {emptyListError ? emptyListError : " "}
                    </FormHelperText>
                    <GenarationOfBatchesTable
                      rows={studentBatchList?.studentBatch}
                      selList={this.selectedListHandler}
                      StudentList={StudentList}
                      selectedStudentIndexList={selectedStudentIndexList}
                      resetSelectedStudentIndexList={
                        this.resetSelectedStudentIndexList
                      }
                      noOfStudent={noOfStudent}
                      selection={selectedStudentIndexList}
                      onSelectionChange={this.getRow}
                    />
                  </Grid>
                  <div>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      columnSpacing={1}
                      sm={12}
                      md={12}
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Batch Name"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            color="primary"
                            name="batches"
                            size="small"
                            value={batches}
                            fullWidth
                            onChange={this.changeHandler}
                            error={batchesError ? true : false}
                            helperText={batchesError ? batchesError : " "}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Short Name"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            color="primary"
                            name="shortName"
                            size="small"
                            inputProps={{ maxLength: 2 }}
                            value={shortName}
                            onChange={this.changeHandler}
                            fullWidth
                            error={shortNameError ? true : false}
                            helperText={shortNameError ? shortNameError : " "}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <div align="right">
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          rowSpacing={2}
                          columnSpacing={1}
                          container
                          justifyContent="right"
                          m={1}
                        >
                          <ButtonCompo
                            size="button"
                            type="button"
                            variant="contained"
                            name="Save"
                            onClick={this.saveHandler}
                          />
                          &nbsp;&nbsp;
                          <ButtonCompo
                            size="button"
                            type="button"
                            variant="contained"
                            name="Save and Next"
                            onClick={this.saveAndProceedHandler}
                          />
                          &nbsp;&nbsp;
                          <Link
                            color="primary"
                            variant="body2"
                            component={RouterLink}
                            to="/batch-list"
                            sx={{ textDecoration: "none" }}
                          >
                            <ButtonCompo
                              size="button"
                              type="button"
                              variant="outlined"
                              name="Cancel"
                            onClick= {this.cancelHandler }
                               
                            />
                          </Link>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </>
              )}
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  internshipDeclarationList: state.internshipDeclaration,
  studentRollNoList: state.rollNoList,
  studentBatchList: state.studentBatch,
  rollNoData: state.rollNo,
} );
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getStudentList,
  getSemesterDetailsByYearAndAcademic,
  getStudentListByReportingIds,
  resetStudentListSuccess,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( GenerationOfBatches );
