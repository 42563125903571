import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  studentDashboardInfo: [],
  isFetchStudentDashboardInfo: false,

  monthAttendanceDataBySemester: [],
  isFetchMonthAttendanceDataBySemester: false,

  subjectAttendanceDataBySemester: [],
  isFetchSubjectAttendanceDataBySemester: false,
};
let URL = endpoints.studentDashboard;
let URL1 = endpoints.studentAttendance;

const studentDashboardSlice = createSlice({
  name: "studentDashboard",
  initialState,
  reducers: {
    studentDashboardInfoSuccess: (state = cloneDeep(initialState), action) => {
      const { bData } = action.payload;
      return {
        ...state,
        studentDashboardInfo: bData,
        isFetchStudentDashboardInfo: true,
      };
    },

    subjectAttendanceDataBySemesterSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectAttendanceDataBySemester: row,
        isFetchSubjectAttendanceDataBySemester: true,
      };
    },

    monthAttendanceDataBySemesterSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        monthAttendanceDataBySemester: row,
        isFetchMonthAttendanceDataBySemester: true,
      };
    },
  },
});

export const {
  studentDashboardInfoSuccess,
  subjectAttendanceDataBySemesterSuccess,
  monthAttendanceDataBySemesterSuccess,
} = studentDashboardSlice.actions;

export default studentDashboardSlice.reducer;

export const getStudentDashboardInfo =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let bData = {
            id: content.id,
            studentDetails: content.studentDetails,
            yearDetails:
              content.studentDetails !== null &&
              content.studentDetails.getYearDetail !== null
                ? content.studentDetails.getYearDetail.year.programType.name +
                  "-" +
                  content.studentDetails.getYearDetail.programTitle.brName +
                  "-" +
                  content.studentDetails.getYearDetail.year.className
                : "",
            //   attendancePerson: content.
            cardFlag: content.cardFlag,
            certificateCountLive: content.certificateLive.length,
            certificateCountHistory: content.certificateHistory.length,
            totalFees: content.applicableFees,
            pendingfees: content.totalPendingfees,
            eventCount: content.eventCount,
            pendingFeedbackCount: content.pendingFeedback.length,
            complaintCountLive: content.complaintLive.length,
            complaintCountHistory:
              content.complaintHistory !== null
                ? content.complaintHistory.length
                : 0,
            suggestionsCount: content.suggestions.length,
            overallAttendancePercentage: content.overallAttendanceCount,
            internshipAlertList:
              content.internshipAlert !== null
                ? content.internshipAlert.map((data1) => {
                    let bData1 = {
                      companyId: data1.id,
                      companyName: data1.companyName,
                      designationId: data1.designationId,
                      fromDate: data1.fromDate,
                      companyAddress: data1.venue,
                      noOfDaysLeft: data1.noOfDaysLeft,
                      internshipType: data1.internshipType,
                    };
                    return bData1;
                  })
                : [],
            internshipCompanyList:
              content.internshipDeclareList !== null
                ? content.internshipDeclareList.map((data1) => {
                    let division =
                      data1.getDivisions === null
                        ? ""
                        : data1.getDivisions.map((divisionData) => {
                            return divisionData.name;
                          });

                    // let yearDetails =
                    //   data1.getYearDetails === null
                    //     ? ""
                    //     : data1.getYearDetails.map((yearData) => {
                    //         return (
                    //           yearData.year.programType.name +
                    //           " " +
                    //           yearData.programTitle.brName +
                    //           " " +
                    //           yearData.year.className
                    //         );
                    //       });
                    let bData1 = {
                      internshipId: data1.id,
                      companyId: data1.company.id,
                      companyName: data1.company.name,
                      companyAddress: data1.company.address,
                      venue: data1.venue,
                      urlLink: data1.urlLink,
                      declareDate: data1.declareDate,
                      designation: data1.designation,
                      stipend: data1.stipend,
                      isQualificationCriteriaRequired:
                        data1.isQualificationCriteriaRequired == 0
                          ? "No"
                          : "Yes",
                      remark: data1.remark,
                      fromDate: data1.fromDate != null ? data1.fromDate : " - ",
                      toDate: data1.toDate != null ? data1.toDate : " - ",
                      fromTime: data1.fromTime != null ? data1.fromTime : " - ",
                      toTime: data1.toTime != null ? data1.toTime : " - ",
                      durationFromDate:
                        data1.durationFromDate != null
                          ? data1.durationFromDate
                          : " - ",
                      durationToDate:
                        data1.durationToDate != null
                          ? data1.durationToDate
                          : " - ",
                      interviewMode:
                        data1.interviewMode == 0 ? "Offline" : "Online",
                      internshipType: data1.internshipType
                        ? data1.internshipType.name
                        : " - ",
                      division: division ? division.join() : "N/A",
                      yearDetails:
                        data1.getYearDetail === null
                          ? ""
                          : data1.getYearDetail.year.programType.name +
                            " " +
                            data1.getYearDetail.programTitle.brName +
                            " " +
                            data1.getYearDetail.year.className,
                    };
                    return bData1;
                  })
                : [],
            placementCompanyList:
              content.placementDeclareList !== null
                ? content.placementDeclareList.map((data1) => {
                    let division =
                      data1.getDivisions === null
                        ? ""
                        : data1.getDivisions.map((divisionData) => {
                            return divisionData.name;
                          });

                    let yearDetails =
                      data1.getYearDetails === null
                        ? ""
                        : data1.getYearDetails.map((yearData) => {
                            return (
                              yearData.year.programType.name +
                              " " +
                              yearData.programTitle.brName +
                              " " +
                              yearData.year.className
                            );
                          });
                    let bData1 = {
                      placementId: data1.id,
                      companyId: data1.company.id,
                      companyName: data1.company.name,
                      companyAddress: data1.company.address,
                      venue: data1.venue,
                      urlLink: data1.urlLink,
                      declareDate: data1.declareDate,
                      designation: data1.designation,
                      placementPackage: data1.placementPackage,
                      isQualificationCriteriaRequired:
                        data1.isQualificationCriteriaRequired == 0
                          ? "No"
                          : "Yes",
                      remark: data1.remark,
                      fromDate: data1.fromDate != null ? data1.fromDate : " - ",
                      toDate: data1.toDate != null ? data1.toDate : " - ",
                      fromTime: data1.fromTime != null ? data1.fromTime : " - ",
                      toTime: data1.toTime != null ? data1.toTime : " - ",
                      durationFromDate:
                        data1.durationFromDate != null
                          ? durationFromDate
                          : " - ",
                      durationToDate:
                        data1.durationToDate != null ? durationToDate : " - ",
                      interviewMode:
                        data1.interviewMode == 0 ? "Offline" : "Online",
                      placementType:
                        data1.placementType == 0
                          ? "Off Campus"
                          : data1.placementType == 1
                          ? "On Campus"
                          : "Pool Campus",
                      division: division ? division.join() : "N/A",
                      yearDetails: yearDetails ? yearDetails.join() : "N/A",
                    };
                    return bData1;
                  })
                : [],
            studentAttendanceList:
              content.studentAttendanceByMonth !== null
                ? content.studentAttendanceByMonth.map((data2, index) => {
                    let bData2 = {
                      // id: data2.id,

                      name: data2.monthYear,
                      // presentCount: data2.presentCount,
                      students: data2.presentPer,
                      // absentStudent: data2.absentStudent,
                    };
                    return bData2;
                  })
                : [],
            timetableList:
              content.timeTable !== null
                ? content.timeTable.map((data2, index) => {
                    let bData2 = {
                      id: data2.id,
                      index: index + 1,
                      subject: data2.subjectAndSubjectDetail.subject.name,
                      teacherName:
                        data2.userRegistration !== null
                          ? data2.userRegistration.firstName +
                            " " +
                            data2.userRegistration.lastName +
                            " " +
                            data2.userRegistration.lastName
                          : "",
                      emailId:
                        data2.userRegistration !== null
                          ? data2.userRegistration.studentEmail
                          : "",
                      mobNo:
                        data2.userRegistration !== null
                          ? data2.userRegistration.studentMobile
                          : "",
                    };
                    return bData2;
                  })
                : [],
            studentVivaDetailsList:
              content.studentVivaDetails !== null
                ? content.studentVivaDetails.map((data1) => {
                    let bData1 = {
                      studentVivaId: data1.id,
                      facultyName: data1.facultyName,
                      remark: data1.remark,
                      time: data1.time,
                      vivaDate: data1.vivaDate,
                    };
                    return bData1;
                  })
                : [],
            libraryResponseList:
              content.getLibraryResponse !== null
                ? (content.getLibraryResponse = {
                    id: content.getLibraryResponse.id,
                    borrid: content.getLibraryResponse.borrid,
                    message: content.getLibraryResponse.message,
                    name: content.getLibraryResponse.name,
                    pendingFees: content.getLibraryResponse.pendingFees,
                    validUntil: content.getLibraryResponse.validUntil,
                    verified: content.getLibraryResponse.verified,
                    warningMessage: content.getLibraryResponse.warningMessage,
                    lentItems: content.getLibraryResponse.lentItems.map(
                      (data1, index) => {
                        let bData1 = {
                          id: data1.id,
                          index: index + 1,
                          title: data1.title,
                          mediaType: data1.mediaType,
                          status: data1.status,
                          returnDate: data1.returnDate,
                          author: data1.author,
                          barcode: data1.barcode,
                          catrefnum: data1.catrefnum,
                          homeBranch: data1.homeBranch,
                          lendingBranch: data1.lendingBranch,
                        };
                        return bData1;
                      }
                    ),
                  })
                : "",
          };
          dispatch(studentDashboardInfoSuccess({ bData }));
          return { success, response: { bData } };
        }
        return { success, response: {} };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

///by-subject-by-semister-detail-id?semisterDetailId=e7c1de29-e6bd-4c3b-8fd8-6db6d9fb14b6
export const getSubjectAttendanceDataBySemester =
  ({ semester, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/by-subject-by-semister-detail-id?semisterDetailId=" +
          semester +
          "&userRegistrationId" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              name: data1.subjectAndSubjectDetail.subject.name,
              students: data1.presentPer,
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectAttendanceDataBySemesterSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//http://localhost:8088/api/acdmc/v1/student-attendance-header/by-month-by-semister-detail-id?semisterDetailId=e7c1de29-e6bd-4c3b-8fd8-6db6d9fb14b6
export const getMonthAttendanceDataBySemester =
  ({ semester, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/by-month-by-semister-detail-id?semisterDetailId=" +
          semester +
          "&userRegistrationId" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              name: data1.monthYear,
              students: data1.presentPer,
            };
            row.push(bData);
            return data1;
          });
          dispatch(monthAttendanceDataBySemesterSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
