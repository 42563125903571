import { Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiDelete, apiPost } from "../../../utils/api_service";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { showNotification } from "../../Landing/Landing.slice";
import { getDeemedAggendaList } from "../Meeting/meeting.slice";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../../components/Comman/IconButton";
import MemberAgendaTable from "./memberAgendaTable";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "title",
    title: "Title",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "40%",
  },
  {
    name: "details",
    title: "Details",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];
class MinutesOfMeetingDialogue extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: "",
    open: false,
    close: false,
    showAgendaTable: false,
    details: "",
    title: "",
    agendaTitle: "",
    openingRemark: "",
    closingRemark: "",
    showForm: false,
    showView: false,
    scheduleMeeting: {},
    formErrors: [],
    agendaData: {},
  };

  componentDidMount() {
    const { scheduleMeetingData, showView } = this.props;
    this.setState({
      open: true,
      scheduleMeeting: scheduleMeetingData,
      showView: showView,
    });
  }

  submitHandler = (e) => {
    const { id, details, title, scheduleMeeting } = this.state;
    const { deemedMeetingList } = this.props;
    let docs = {};
    if (id !== "") {
      docs = {
        id: id,
        srNo: deemedMeetingList.deemedMeetingAggenda.length + 1,
        title: title,
        details: details,
        scheduleCommitteeMeeting: {
          id: scheduleMeeting.id,
        },
      };
    } else {
      docs = {
        srNo: deemedMeetingList.deemedMeetingAggenda.length + 1,
        title: title,
        details: details,
        scheduleCommitteeMeeting: {
          id: scheduleMeeting.id,
        },
      };
    }

    if (this.handleFormValidation()) {
      apiPost({
        url: endpoint.deemedAgenda + "/save-all",
        postBody: docs,
      }).then(({ data, success }) => {
        if (success) {
          this.props.showNotification({ msg: "Data saved successfully" });
          this.props
            .getDeemedAggendaList({ id: scheduleMeeting.id })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          this.setState({
            title: "",
            details: "",
            showForm: false,
          });
        }
      });
    }
  };

  onClickViewRow = (data) => {
    this.setState({
      showAgendaTable: true,
      agendaData: data,
    });
  };

  handleTableClose = (data) => {
    this.setState({
      showAgendaTable: false,
    });
  };

  formHandler = (data) => {
    const { details, title } = this.state;
    this.setState({
      showForm: false,
      id: data.id,
      title: data.title,
      details: data.details,
    });
  };

  deleteFormHandler = (data) => {
    const { scheduleMeeting } = this.state;

    swal({
      title: "Are you sure?",
      text: "Do you want to delete?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiDelete({
          url: endpoint.deemedAgenda + "/" + data.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showNotification({ msg: "Data deleted successfully" });

            this.props
              .getDeemedAggendaList({ id: scheduleMeeting.id })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          }
        });
      }
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  editInfo(data) {
    this.setState({});
  }
  handleFormValidation() {
    const { details, title } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (details.trim() === "") {
      formIsValid = false;
      formErrors["detailsError"] = myConstClass.detailsMsg;
    }

    if (title.trim() === "") {
      formIsValid = false;
      formErrors["titleError"] = myConstClass.titleMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleClose = () => {
    this.props.onClose();
  };

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.deemedMeetingList.deemedMeetingAggenda.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["details"] &&
            currentRow["details"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };

  render() {
    const {
      details,
      showForm,
      title,
      agendaTitle,
      openingRemark,
      closingRemark,
      showAgendaTable,
      agendaData,
      showView,
    } = this.state;
    const { deemedMeetingList } = this.props;
    const { detailsError, titleError } = this.state.formErrors;
    return (
      <>
        {!showForm && !showView && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Agenda"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="title"
                    size="small"
                    value={title}
                    onChange={this.changeHandler}
                    fullWidth
                    error={titleError ? true : false}
                    helperText={titleError ? titleError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Details"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="details"
                    size="small"
                    value={details}
                    onChange={this.changeHandler}
                    fullWidth
                    multiline
                    error={detailsError ? true : false}
                    helperText={detailsError ? detailsError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{ mr: 0.5 }}
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              m={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              ></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Add"
                  onClick={this.submitHandler}
                  fullWidth={true}
                />
              </Grid>
            </Grid>

            <br />
          </>
        )}
        <TableContainer component={Paper} variant="scrollable">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="50" align="center">
                  Sr. No.
                </TableCell>
                <TableCell width="200" align="center">
                  Agenda
                </TableCell>
                <TableCell width="200" align="center">
                  Details
                </TableCell>
                <TableCell width="70" align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.deemedMeetingList.deemedMeetingAggenda.map(
                (row, index) => (
                  <>
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>

                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>

                      <TableCell>
                        {!row.open &&
                        row.details.length > row.details.slice(0, 70).length
                          ? row.details.slice(0, 100) + " ... "
                          : row.details.slice(0, 100)}{" "}
                      </TableCell>
                      <TableCell align="center">
                        <MyComponentWithIconProps
                          statusImage={VisibilityIcon}
                          color="primary"
                          fontSize="small"
                          // sx={{ marginLeft: 35 }}
                          title="View"
                          onClick={(e) => this.onClickViewRow(row)}
                        />
                        &nbsp;&nbsp;
                        {!showView && (
                          <>
                            <MyComponentWithIconProps
                              statusImage={EditIcon}
                              color="primary"
                              fontSize="small"
                              title="Edit"
                              // sx={{ marginLeft: 3 }}
                              onClick={(e) => this.formHandler(row)}
                            />
                            &nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={DeleteIcon}
                              color="error"
                              fontSize="small"
                              // sx={{ marginLeft: 35 }}
                              title="Delete"
                              onClick={(e) => this.deleteFormHandler(row)}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <br />
        <Grid
          sx={{ mr: 0.5 }}
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          m={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name={showView ? "Back" : "Cancel"}
              onClick={this.props.backToAttendance}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name={showView ? "Next" : "Save"}
              onClick={this.props.goToDocument}
              fullWidth={true}
            />
          </Grid>
        </Grid>

        {showAgendaTable && (
          <MemberAgendaTable
            handleClose={this.handleTableClose}
            agendaData={agendaData}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  deemedMeetingList: state.deemedMeeting,
});
const mapDispatchToProps = {
  getDeemedAggendaList,
  getAlert,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinutesOfMeetingDialogue);
