export const teacherVivaDynamicJson = {
    screenTitle: "Alloted Viva",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    getAcadamicYear: true,
    getListId: 'acadamicYear,yearDetail',
    fieldMeta: [
        {
            label: "Intern Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "internshipMaster",
            dataKey: "internship",
            isMandatory: true,
        },
    ],
};
