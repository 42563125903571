import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import { Loading1 } from "../../../../../components/Loading1";
import { consultancyJson } from "../../../../../DynamicFormsJson/Consultancy";
import { showNotification } from "../../../../../Pages/Landing/Landing.slice";
import { consultancyColumns } from "../../../../../tableColumns/table-columns";
import {
  getConsultancy,
  getConsultancyByDate,
  getConsultancyWithoutFilter,
} from "./consultancy.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class Consultancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      showLoader: false,
      fromDate: "",
      toDate: "",
      formErrors: [],
    };
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getConsultancyWithoutFilter().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  submitHandler = (data) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getConsultancyByDate({ fromDate: data.fromDate, toDate: data.toDate })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  render() {
    const { dynamicMasterData, showLoader } = this.state;
    return (
      <>
        {showLoader && <Loading1 />}
        <DynamicMainScreen
          screenTitle={consultancyJson.screenTitle}
          fieldMeta={consultancyJson.fieldMeta}
          showPdfDownload={consultancyJson.showPdfDownload}
          showExcelDownload={consultancyJson.showExcelDownload}
          pdfFileName={consultancyJson.pdfFileName}
          excelFileName={consultancyJson.excelFileName}
          showAddButton={consultancyJson.showAddButton}
          tableColumnsToFilter={consultancyJson.tableColumnsToFilter}
          dynamicMasterData={dynamicMasterData}
          showSaveNextBtn={false}
          tableColumns={consultancyColumns}
          tableData={this.props.consultancyList?.consultancy}
          getTableData={this.props.getConsultancyWithoutFilter}
          showLoader={!showLoader}
          apiBaseURL={consultancyJson.apiBaseURL}
          baseIdColumn={consultancyJson.baseIdColumn}
          type={1}
          DocumentUpload={consultancyJson.DocumentUpload}
          onSearchData={this.submitHandler}
          searchList={consultancyJson.searchList}
          sendEmptyId={true}
          deleteUrl={consultancyJson.deleteUrl}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  seminarWebinarList: state.seminarWebinar,
  consultancyList: state.consultancy,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getConsultancyByDate,
  getConsultancyWithoutFilter,
  getConsultancy,
};
export default connect(mapStateToProps, mapDispatchToProps)(Consultancy);
