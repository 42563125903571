import ArticleIcon from "@mui/icons-material/Article";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete } from "../../utils/api_service";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
// import IntrestedProgramForm from "../IntresetedProgram/IntresetedProgramForm";
// import StudentPreview1 from "../StudentPreview1/index";
import {
  getAnnualIncome,
  getBloodGroup,
  getCategory,
  getDistrict,
  getReligion,
  getState,
  getTaluka,
  saveStudent,
} from "../BasicDetailsPost/basicDetails.slice";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import DocumentAndQualification from "../BasicDetailsPost/DocumentAndQualification";
import PersonalDetailsForm from "../BasicDetailsPost/PersonalDetailsForm";
import StudAddress from "../BasicDetailsPost/StudAddress";
import { deleteFile, openFileInNewTab } from "../UploadFile/file.slice";
import AdmissionForm from "./AdmissionForm";
import QualificationDetails from "./QualificationDetails";
import UploadPhotoAndSign from "./UploadPhotoAndSign";
import { getQualification } from "../QualificationMaster/qualification.slice";
import { getDocument } from "../Masters/OnlineModule/Document/document.slice";
import endpoint from "../../config/endpoints";
import { getAlert } from "../../CommonActions/alert.slice";

import * as myConstClass from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
import { Loading1 } from "../../components/Loading1";
const StudentMasterPost = ({
  studentList,
  getReligion,
  getCategory,
  getBloodGroup,
  getAnnualIncome,
  getState,
  getDistrict,
  getTaluka,
  getAlert,
  openFileInNewTab,
  deleteFile,
  saveStudent,
  showNotification,
  getQualification,
  getDocument,
  editForm,
  getDepartment,
  studFormOpen,
  onCancelForm,
}) => {
  const [value, setValue] = React.useState("1");
  const [viewDeclr, setViewDeclr] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [row, setRow] = React.useState([]);
  const [quaRow, setQuaRow] = React.useState([]);
  const [editRowData, setEditRowData] = React.useState([]);
  const navigate = useNavigate();

  const data = (personaldetails) => {
    setLoading(true);
    saveStudent({ studData: personaldetails }).then((response) => {
      if (response) {
        setLoading(false);
        showNotification({
          msg: "Data saved successfully",
        });
        setValue("2");
      } else {
        setLoading(false);
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  const studAddressData = (addressDetails) => {
    setLoading(true);

    saveStudent({ studData: addressDetails }).then((response) => {
      if (response) {
        setLoading(false);

        showNotification({
          msg: "Data saved successfully",
        });
        setValue("3");
        setRow(
          studentList.applicant.userDocumentDetailSaves
            ? studentList.applicant.userDocumentDetailSaves.map(
                (userDocumentDetailData, index) => {
                  let userDocData = {
                    id: userDocumentDetailData.id,
                    index: index + 1,
                    docDesc: userDocumentDetailData.docDesc,
                    documentPath: userDocumentDetailData.documentPath,
                    documentId: userDocumentDetailData.documentId,
                  };
                  return userDocData;
                }
              )
            : []
        );
        setQuaRow(
          studentList.applicant.userQualificationDetailSaves
            ? studentList.applicant.userQualificationDetailSaves.map(
                (userQualificationDetailData, index) => {
                  let userDocData = {
                    id: userQualificationDetailData.id,
                    index: index + 1,
                    qualificationName:
                      userQualificationDetailData.qualificationName,
                    yearOfPass: userQualificationDetailData.yearOfPass,
                    country: userQualificationDetailData.country,
                    state: userQualificationDetailData.state,
                    boardUniversity:
                      userQualificationDetailData.boardUniversity,
                    obtainedMarks: userQualificationDetailData.obtainedMarks,
                    totalMarks: userQualificationDetailData.totalMarks,
                    qualificationId:
                      userQualificationDetailData.qualificationId,
                    nameOfSchoolClg:
                      userQualificationDetailData.nameOfSchoolCollege,
                    percentage: userQualificationDetailData.percentage,
                    board: userQualificationDetailData.board,
                    isActive: userQualificationDetailData.isActive,
                  };
                  return userDocData;
                }
              )
            : []
        );
      } else {
        setLoading(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  const qualificationData = (qualification) => {};

  const uploadPhotoAndSign = (uploadeddata) => {
    setLoading(true);

    saveStudent({ studData: uploadeddata }).then((response) => {
      if (response) {
        setLoading(false);

        showNotification({
          msg: "Data saved successfully",
        });
        if (studFormOpen) {
          onCancelForm();
        } else {
          window.location.replace("/applicant-admission");
        }
      } else {
        setLoading(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  const rowView = (rowData) => {
    const viewRow = row.filter((row) => row.id === rowData.id);
    openFileInNewTab({ file: viewRow[0].documentPath }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  const rowEdit = (rowData) => {
    if (rowData.id !== undefined) {
      const editRow = quaRow.filter((qRow) => qRow.id === rowData.id);
      if (editRow.length > 0) {
        return setEditRowData(editRow);
      }
      return setEditRowData([]);
    } else {
      const editRow = quaRow.filter((qRow) => qRow.index === rowData.index);
      if (editRow.length > 0) {
        return setEditRowData(editRow);
      }
      return setEditRowData([]);
    }
  };

  // const rowDeleteQualification = ( id, index ) =>
  // {
  //   swal( {
  //     title: "Are you sure?",
  //     text: "Do you want to delete the file?",
  //     icon: "warning",
  //     buttons: [ "Cancel", true ],
  //     dangerMode: true,
  //   } ).then( ( willDelete ) =>
  //   {
  //     if ( willDelete )
  //     {
  //       if ( id !== undefined )
  //       {
  //         let quaRowList = quaRow.filter( ( row ) => row.id !== id );
  //         setQuaRow( quaRowList );
  //         apiDelete( {
  //           url: endpoint.userQualificationDetail + "/" + id,
  //         } ).then( ( { success } ) =>
  //         {
  //           if ( !success )
  //           {
  //             getAlert( {
  //               message: "Failed to delete",
  //             } );
  //           }
  //         } );
  //       } else
  //       {
  //         let quaRowList = quaRow.filter( ( row ) => row.index !== index );
  //         setQuaRow( quaRowList );
  //       }
  //     }
  //   } );
  // };

  const rowDeleteQualification = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowData.id !== undefined) {
          let quaRowList = quaRow.filter((row) => row.id !== rowData.id);
          setQuaRow(quaRowList);
          apiDelete({
            url: endpoint.userQualificationDetail + "/" + rowData.id,
          }).then(({ success }) => {
            if (!success) {
              getAlert({
                message: "Failed to delete",
              });
            }
          });
        } else {
          let quaRowList = quaRow.filter((row) => row.index !== rowData.index);
          setQuaRow(quaRowList);
        }
      }
    });
  };

  // const rowDelete = ( id ) =>
  // {
  //   const viewRow = row.filter( ( row ) => row.id === id );
  //   swal( {
  //     title: "Are you sure?",
  //     text: "Do you want to delete the file?",
  //     icon: "warning",
  //     buttons: [ "Cancel", true ],
  //     dangerMode: true,
  //   } ).then( ( willDelete ) =>
  //   {
  //     if ( willDelete )
  //     {
  //       deleteFile( { file: viewRow[ 0 ].documentPath } ).then( ( response ) =>
  //       {
  //         if ( !response )
  //         {
  //           getAlert( { message: "Something went wrong" } );
  //         }
  //       } );

  //       const rowData = row.filter( ( row ) => row.id !== id );
  //       setRow( rowData );
  //       apiDelete( {
  //         url: endpoint.userDocumentDetail + "/" + id,
  //       } ).then( ( { success } ) =>
  //       {
  //         if ( !success )
  //         {
  //           getAlert( {
  //             message: "Failed to delete",
  //           } );
  //         }
  //       } );
  //     }
  //   } );
  // };

  const rowDelete = (rowDataa) => {
    const viewRow = row.filter((row) => row.id === rowDataa.id);
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFile({ file: viewRow[0].documentPath }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });

        const rowData = row.filter((row) => row.id !== rowDataa.id);
        setRow(rowData);
        apiDelete({
          url: endpoint.userDocumentDetail + "/" + rowDataa.id,
        }).then(({ success }) => {
          if (!success) {
            getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };

  const setRowData = (data) => {
    if (data.id === undefined) {
      data = {
        ...data,
        id: row.length + 1,
      };
      setRow((prevData) => {
        return [...prevData, data];
      });
    } else {
      setRow((prevData) => {
        return [...prevData, data];
      });
    }
  };

  const setQuaData = (data) => {
    if (data.index === 0 || data.index === undefined) {
      data = {
        ...data,
        index: quaRow.length + 1,
      };

      setQuaRow((prevData) => {
        return [...prevData, data];
      });
    } else {
      let viewRow = quaRow.filter((row) => row.index !== data.index);
      viewRow = [...viewRow, data];
      setQuaRow(viewRow);
    }
    setEditRowData([]);
  };

  useEffect(() => {
    getReligion().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getCategory().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getBloodGroup().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getAnnualIncome().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getState().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getDistrict().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getTaluka().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getQualification().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getDocument().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getDepartment().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    if (studFormOpen) {
      setViewDeclr(true);
    }
  }, []);

  const changeTabs = (event, newValue) => {
    if (newValue === "3") {
      setRow(
        studentList.applicant.userDocumentDetailSaves
          ? studentList.applicant.userDocumentDetailSaves.map(
              (userDocumentDetailData, index) => {
                let userDocData = {
                  id: userDocumentDetailData.id,
                  index: index + 1,
                  docDesc: userDocumentDetailData.docDesc,
                  documentPath: userDocumentDetailData.documentPath,
                  documentId: userDocumentDetailData.documentId,
                };
                return userDocData;
              }
            )
          : []
      );
      setQuaRow(
        studentList.applicant.userQualificationDetailSaves
          ? studentList.applicant.userQualificationDetailSaves.map(
              (userQualificationDetailData, index) => {
                let userDocData = {
                  id: userQualificationDetailData.id,
                  index: index + 1,
                  qualificationName:
                    userQualificationDetailData.qualificationName,
                  yearOfPass: userQualificationDetailData.yearOfPass,
                  country: userQualificationDetailData.country,
                  state: userQualificationDetailData.state,
                  boardUniversity: userQualificationDetailData.boardUniversity,
                  obtainedMarks: userQualificationDetailData.obtainedMarks,
                  totalMarks: userQualificationDetailData.totalMarks,
                  qualificationId: userQualificationDetailData.qualificationId,
                  nameOfSchoolClg:
                    userQualificationDetailData.nameOfSchoolCollege,
                  percentage: userQualificationDetailData.percentage,
                  board: userQualificationDetailData.board,
                  isActive: userQualificationDetailData.isActive,
                };
                return userDocData;
              }
            )
          : []
      );
    }
    setValue(newValue);
  };
  const gotoApplicantAddmission = () => {
    navigate("/applicant-admission");
  };

  const gotoPersonalDetails = () => {
    setValue("1");
  };
  const onCancel = () => {
    if (studFormOpen) {
      onCancelForm();
    } else {
      setViewDeclr(false);
    }
  };
  const gotoAddressDetails = () => {
    setValue("2");
  };
  const gotoDocumentsAndQualification = () => {
    setValue("3");
  };
  const saveDocumentAndQualification = (e) => {
    e.preventDefault();
    let QualificationDetailSaves = quaRow.map((data) => {
      let docs = {
        university: data.boardUniversity,
        state: data.state,
        board: "",
        country: data.country,
        nameOfSchoolCollege: data.nameOfSchoolClg,
        yearOfPass: data.yearOfPass,
        marksObtained: data.obtainedMarks,
        marksOutof: data.totalMarks,
        percentage: data.percentage,
        qualificationId: data.qualificationId,
        isActive: 1,
      };
      if (data.id) {
        docs = {
          ...docs,
          id: data.id,
        };
      }
      return docs;
    });

    const DocumentDetails = row.map((data) => {
      let docss = {
        documentFileName: data.documentPath,
        isActive: 1,
        documentId: data.documentId,
      };
      if (data.id) {
        docss = {
          ...docss,
          id: data.id,
        };
      }
      return docss;
    });

    let QualificationAndDocsSave = {
      id: studentList.applicant.id,
      userDocumentDetailSaves: DocumentDetails,
      userQualificationDetailSaves: QualificationDetailSaves,
      type: 3,
    };
    setLoading(true);

    saveStudent({ studData: QualificationAndDocsSave }).then((response) => {
      if (response) {
        setLoading(false);

        showNotification({
          msg: "Data saved successfully",
        });
        setValue("4");
      } else {
        setLoading(false);

        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {viewDeclr && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Admission Form"
                />
              </Grid>
            </Grid>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={changeTabs}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      icon={<InfoIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Basic Details"
                      value="1"
                    />
                    <Tab
                      icon={<HomeIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Address Details"
                      value="2"
                    />
                    <Tab
                      icon={<ArticleIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Documents And Qualification"
                      value="3"
                    />

                    <Tab
                      icon={<PhotoSizeSelectActualIcon />}
                      textColor="secondary"
                      indicatorcolor="secondary"
                      label="Photo and Sign"
                      value="4"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <PersonalDetailsForm
                    editForm={editForm}
                    OnSave={data}
                    onCancel={onCancel}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <StudAddress
                    editForm={editForm}
                    onCancel={gotoPersonalDetails}
                    studAddressData={studAddressData}
                  />
                </TabPanel>
                <TabPanel value="3">
                  <>
                    <DocumentAndQualification
                      row={row}
                      rowView={rowView}
                      rowData={setRowData}
                      rowDelete={rowDelete}
                    />

                    <QualificationDetails
                      row={quaRow}
                      rowData={setQuaData}
                      rowDelete={rowDeleteQualification}
                      qualificationData={qualificationData}
                      rowEdit={rowEdit}
                      editRows={editRowData}
                    />

                    <Grid
                      rowSpacing={2}
                      container
                      columnSpacing={1}
                      justifyContent="right"
                      sx={{ pr: 2 }}
                    >
                      <Grid item justifyContent="space-evenly">
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="outlined"
                          name="Back"
                          onClick={gotoAddressDetails}
                          fullWidth={true}
                        />
                      </Grid>

                      <Grid item justifyContent="space-evenly">
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name="Save and Proceed"
                          onClick={saveDocumentAndQualification}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  </>
                </TabPanel>

                <TabPanel value="4">
                  <UploadPhotoAndSign
                    uploadphotoandsign={uploadPhotoAndSign}
                    onCancel={gotoDocumentsAndQualification}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )}
        {!viewDeclr && (
          <AdmissionForm
            onSave={setViewDeclr}
            onCancel={gotoApplicantAddmission}
          />
        )}
        {loading && <Loading1 />}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  studentList: state.studentList,
});
const mapDispatchToProps = {
  getReligion,
  getCategory,
  getBloodGroup,
  getAnnualIncome,
  getState,
  getDistrict,
  getTaluka,
  openFileInNewTab,
  deleteFile,
  saveStudent,
  getQualification,
  getDocument,
  getAlert,
  getDepartment,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentMasterPost);
