import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { generateExcel } from "../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../components/Comman/GeneratePDF";
import { LabelCompo } from "../../../components/Comman/Label";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import SearchEmpFeedbackDetList from "../../../components/SearchTable";
import {
  getEmployeeFeedbackDetails,
  getEmployeeFeedbackQuestions,
} from "./employeeFeedbackportal.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { EmployeeApplyFeedbackJson } from "../../../DynamicFormsJson/EmployeeApplyFeedbackDynamic";

import StudentFeedbackForm from "./EmployeeFeedbackForm";
import { ButtonCompo } from "../../../components/Comman/Button";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class EmployeeFeedbackDetailsPortalIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showAddCommitteeForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      department: "",
      academicYearId: "",
      departmentList: [],
      editData: {},
      committeeData: {},
      eventDataForAppliedStudents: {},
      searchValue: "",
      showLoader: false,
    };
  }

  OnClickApply = (data) => {
    console.log(data);
    this.props
      .getEmployeeFeedbackQuestions({ id: data.feedbackHeadId })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showTable: false,
            showForm: true,
            editData: data,
          });
        }
      });
  };
  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: 100,
    },
    {
      name: "feedbackHead",
      title: "Feedback name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,

      width: "20%",
    },
    {
      name: "department",
      title: "Department",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,

      // width: "35%",
    },
    {
      name: "fromDate",
      title: "From Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: "13%",
    },
    {
      name: "toDate",
      title: "To Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: "12%",
    },
    {
      name: "details",
      title: "Instruction",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,

      // width: "35%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: 50,
    },
  ];

  column = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Questions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  submitHandler = () => {};

  componentDidMount() {
    const { getEmployeeFeedbackDetails, getAlert } = this.props;
    this.setState({
      showLoader: true,
    });
    getEmployeeFeedbackDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  addEventCommittee = (data) => {};

  AppliedStudentList = (data) => {};

  onChangeAction = (rowData) => {};

  toggleFormTableVisibility = () => {};

  backToForm = () => {};

  backTo = () => {
    this.setState({
      showTable: true,
    });
  };

  cancleHandler = () => {
    this.setState({
      showForm: false,
      showTable: true,
    });
  };

  backToListHandler = () => {
    console.log("back....");
    const { getEmployeeFeedbackDetails, getAlert } = this.props;
    getEmployeeFeedbackDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      showForm: false,
      showTable: true,
    });
  };

  componentWillReceiveProps(nextProps) {}

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {};

  onChangeStatus = (rowData) => {};

  rowDelete = (rowData) => {};

  // onFormCancel = () =>
  // {
  //     // this.props.getTableData();
  //     this.toggleFormTableVisibility();
  // };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {};

  // delete event by id

  onDelete = (id) => {};
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.employeeFeedbackDetailsList?.employeeFeedbackDetails.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["feedbackHead"] &&
            currentRow["feedbackHead"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["details"] &&
            currentRow["details"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["department"] &&
            currentRow["department"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["fromDate"] &&
            currentRow["fromDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["toDate"] &&
            currentRow["toDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const { searchButton = false } = this.props;
    const { showTable, showForm } = this.state;

    return (
      <>
        {/* <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}> */}
        {showForm && (
          <>
            <StudentFeedbackForm
              editData={this.state.editData}
              onCancel={this.cancleHandler}
              backToList={this.backToListHandler}
            />
            {/* <DynamicTable
                  data={this.props.employeeFeedbackDetailsList?.employeeFeedbackDetails}
                  tableHead={this.column}
                  showPegination={true}
                  rowView={this.OnClickApply}
                  showApply={false}
                /> */}
          </>
        )}

        {showTable && (
          <>
            {/* <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Employee Feedback Details "
                  />
                </Grid>
              </Grid> */}
            {/* <Grid
              sx={{ marginTop: 1, marginBottom: 2 }}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <SearchEmpFeedbackDetList onSearch={this.onSearch} />
            </Grid> */}

            <Grid item>
              {/* <DynamicTable
                  data={
                    this.getFilteredTableData()
                  }
                  tableHead={this.columns}
                  showPegination={true}
                  rowView={this.OnClickApply}
                  showApply={true}
                /> */}

              <DynamicMainScreen
                onAddButtonClick={this.onAddButtonClick}
                screenTitle={EmployeeApplyFeedbackJson.screenTitle}
                fieldMeta={EmployeeApplyFeedbackJson.fieldMeta}
                buttonCenter={EmployeeApplyFeedbackJson.buttonCenter}
                showPdfDownload={EmployeeApplyFeedbackJson.showPdfDownload}
                showExcelDownload={EmployeeApplyFeedbackJson.showExcelDownload}
                // pdfFileName={EmployeeApplyFeedbackJson.pdfFileName}
                // excelFileName={EmployeeApplyFeedbackJson.excelFileName}
                tableColumnsToFilter={
                  EmployeeApplyFeedbackJson.tableColumnsToFilter
                }
                showAddButton={EmployeeApplyFeedbackJson.showAddButton}
                dynamicMasterData={[]}
                tableColumns={this.columns}
                isActionColActive={true}
                tableData={
                  this.props.employeeFeedbackDetailsList
                    ?.employeeFeedbackDetails
                }
                // getListById={this.getListById}
                showPegination={true}
                // showHeadEdit={true}
                // showHeadDelete={true}
                // showAppliedList={true}
                // showPegination={false}
                // pdfDetailsId={this.pdfDetails}
                // rowViewData={this.onClickView}
                // showLinkIcon={true}
                searchButton={searchButton}
                generateDetails={this.generateDetails}
                rowView={this.OnClickApply}
                showApply={true}
                showLoader={this.state.showLoader}
                // rowDelete={this.rowDelete}
                // rowEdit={this.rowEdit}
                // rowStatus={this.onChangeStatus}
                // rowAppliedList={this.onClickApplied}
                // onSearchData={this.onSearchData}
                baseIdColumn={EmployeeApplyFeedbackJson.baseIdColumn}
                apiBaseURL={EmployeeApplyFeedbackJson.apiBaseURL}
              />
            </Grid>
          </>
        )}
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  employeeFeedbackDetailsList: state.employeeFeedbackDetails,
});
const mapDispatchToProps = {
  getEmployeeFeedbackDetails,
  getEmployeeFeedbackQuestions,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeFeedbackDetailsPortalIndex);
