import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import { getAlert } from "../../CommonActions/alert.slice";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { showNotification } from "../Landing/Landing.slice";
import { apiDelete, apiPost } from "../../utils/api_service";
import
{
  getStudentList,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import
{
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
} from "../UploadFile/file.slice";
import { Loading1 } from "../../components/Loading1";

class StudentDeclareInternshipListTable extends React.Component
{
  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      title: "Document Name",
      name: "name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "60%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];
  onClickDelete = ( params ) =>
  {
    swal( {
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: [ "Cancel", true ],
    } ).then( ( res ) =>
    {
      if ( res )
      {
        if ( params.id !== undefined )
        {
          apiDelete( {
            url: endpoints.internshipIntrestedDoc + "/" + params.id,
          } ).then( ( { data, success } ) =>
          {
            if ( success )
            {
              this.props.deleteFile( { file: params.fileName } ).then( () => { } );
              const docList = this.props.fileData.fileData.filter(
                ( row ) => row.fileName !== params.fileName
              );
              const rowData = docList.map( ( docData, index ) =>
              {
                return { ...docData, index: index + 1 };
              } );
              this.props.fileMulitipleSave( { fileData: rowData } );
            }
          } );
        } else
        {
          this.props.deleteFile( { file: params.fileName } ).then( () => { } );
          const docList = this.props.fileData.fileData.filter(
            ( row ) => row.fileName !== params.fileName
          );
          const rowData = docList.map( ( docData, index ) =>
          {
            return { ...docData, index: index + 1 };
          } );
          this.props.fileMulitipleSave( { fileData: rowData } );
        }
      }
    } );
  };

  details = ( params ) =>
  {
    this.props.openFileInNewTab( { file: params.fileName } ).then( ( response ) =>
    {
      if ( !response )
      {
        this.props.getAlert( { message: "Something went wrong" } );
      }
    } );
  };
  state = {
    id: 0,
    academicYear: "",
    yearDetail: "",
    typeOfAdmission: "",
    applicationId: "",
    formErrors: [],
    interestedStudent: false,
    accountFees: false,
    listOfComp: true,
    fromDate: "",
    toDate: "",
    internType: "",
    companyName: "",
    companyAddress: "",
    designation: "",
    stipend: "",
    remark: "",
    fileName: "",
    documentName: "",
    fileNameData: [],
    upload: false,
    uploadError: false,
    docNameDD: "",
    documentListLocal: [
      { id: "Offer Letter", name: "Offer Letter" },
      {
        id: "Appointment Letter",
        name: "Appointment Letter",
      },
      {
        id: "Experience Letter",
        name: "Experience Letter",
      },
      {
        id: "1",
        name: "Other",
      },
    ],
    companyMail: "",
    companyNo: "",
  };

  componentWillReceiveProps( nextProps )
  {
    if (
      this.props.studentData?.getInterestedStudByIdStatus !==
      nextProps.studentData?.getInterestedStudByIdStatus
    )
    {
      const durationForArr =
        nextProps.studentData?.getInterestedStudByIdStatus.durationToDate !==
          "" &&
          nextProps.studentData?.getInterestedStudByIdStatus.durationToDate !==
          null
          ? nextProps.studentData?.getInterestedStudByIdStatus.durationToDate.split(
            "-"
          )
          : "";
      const toDt =
        durationForArr !== ""
          ? new Date(
            durationForArr[ 2 ],
            durationForArr[ 1 ] - 1,
            durationForArr[ 0 ]
          )
          : "";
      const durationForsArr =
        nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate !==
          "" &&
          nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate !==
          null
          ? nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate.split(
            "-"
          )
          : "";
      const frDt =
        durationForsArr !== ""
          ? new Date(
            durationForsArr[ 2 ],
            durationForsArr[ 1 ] - 1,
            durationForsArr[ 0 ]
          )
          : "";

      this.setState( {
        internType:
          nextProps.studentData?.getInterestedStudByIdStatus.internshipType.id,
        id: nextProps.studentData?.getInterestedStudByIdStatus.id,
        companyAddress:
          nextProps.studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName:
          nextProps.studentData?.getInterestedStudByIdStatus.companyName,
        companyMail:
          nextProps.studentData?.getInterestedStudByIdStatus.companyEmail,
        companyNo: nextProps.studentData?.getInterestedStudByIdStatus.contactNo,
        stipend: nextProps.studentData?.getInterestedStudByIdStatus.stipend,
        remark: nextProps.studentData?.getInterestedStudByIdStatus.remark,
        designation:
          nextProps.studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .lastName,
        studUserRegistrationId:
          nextProps.studentData?.getInterestedStudByIdStatus.userRegistrationId,
        fromDate: frDt,
        toDate: toDt,
      } );
      const docList =
        nextProps.studentData?.getInterestedStudByIdStatus.internshipIntrestedStudentDocument.map(
          ( fileData, index ) =>
          {
            let document = {
              id: fileData.id,
              index: index + 1,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave( { fileData: docList } );
    }
  }

  componentDidMount()
  {
    const { getAlert, studentData } = this.props;

    this.props.getInternshipType().then( ( response ) =>
    {
      if ( !response )
      {
        getAlert( { message: "Something went wrong" } );
      }
    } );
    if ( Object.keys( studentData?.getInterestedStudByIdStatus ).length !== 0 )
    {
      const durationForArr =
        studentData?.getInterestedStudByIdStatus.durationToDate !== ""
          ? studentData?.getInterestedStudByIdStatus.durationToDate.split( "-" )
          : "";
      const toDt =
        durationForArr !== ""
          ? new Date(
            durationForArr[ 2 ],
            durationForArr[ 1 ] - 1,
            durationForArr[ 0 ]
          )
          : "";
      const durationForsArr =
        studentData?.getInterestedStudByIdStatus.durationFromDate !== ""
          ? studentData?.getInterestedStudByIdStatus.durationFromDate.split( "-" )
          : "";
      const frDt =
        durationForsArr !== ""
          ? new Date(
            durationForsArr[ 2 ],
            durationForsArr[ 1 ] - 1,
            durationForsArr[ 0 ]
          )
          : "";
      this.setState( {
        internType: studentData?.getInterestedStudByIdStatus.internshipType.id,
        id: studentData?.getInterestedStudByIdStatus.id,
        companyAddress: studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName: studentData?.getInterestedStudByIdStatus.companyName,
        companyMail:
          nextProps.studentData?.getInterestedStudByIdStatus.companyEmail,
        companyNo: nextProps.studentData?.getInterestedStudByIdStatus.contactNo,
        stipend: studentData?.getInterestedStudByIdStatus.stipend,
        remark: studentData?.getInterestedStudByIdStatus.remark,
        designation: studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration.lastName,
        studUserRegistrationId:
          studentData?.getInterestedStudByIdStatus.userRegistrationId,
        fromDate: frDt,
        toDate: toDt,
      } );
      const docList =
        studentData?.getInterestedStudByIdStatus.internshipIntrestedStudentDocument.map(
          ( fileData, index ) =>
          {
            let document = {
              id: fileData.id,
              index: index + 1,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave( { fileData: docList } );
    }
  }

  changeFromDate = ( date ) =>
  {
    if ( date !== null )
    {
      this.setState( {
        fromDate: date,
      } );
    }
  };

  changeToDate = ( date ) =>
  {
    if ( date !== null )
    {
      this.setState( {
        toDate: date,
      } );
    }
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    if ( name === "contactNo" )
    {
      if ( onlyNumber.test( value ) )
      {
        this.setState( {
          ...this.state,
          [ name ]: value,
        } );
      }
    } else
    {
      this.setState( {
        ...this.state,
        [ name ]: value,
      } );
    }
  };

  ChangeHandlerSearch = ( name, newValue ) =>
  {
    if ( newValue !== null )
    {
      this.setState( { [ name ]: newValue } );
    } else
    {
      this.setState( { [ name ]: "" } );
    }
  };

  handleFormValidation( name )
  {
    const {
      companyName,
      fromDate,
      toDate,
      companyAddress,
      designation,
      internType,
      companyNo,
    } = this.state;
    const { studentData } = this.props;
    let formErrors = {};
    let formIsValid = true;

    if ( internType === "" || internType === null )
    {
      formIsValid = false;
      formErrors[ "internTypeError" ] = myConstClass.internTypeMsg;
    }

    if ( companyName === "" || companyName === null )
    {
      formIsValid = false;
      formErrors[ "companyNameError" ] = myConstClass.companyNameMsg;
    }

    if ( companyAddress.toString().trim() === "" )
    {
      formIsValid = false;
      formErrors[ "companyAddressError" ] = myConstClass.companyAddressMsg;
    }

    // if (designation.toString().trim() === "") {
    //   formIsValid = false;
    //   formErrors["designationError"] = myConstClass.designationMsg;
    // }

    // if (fromDate.toString().trim() === "" || fromDate === null) {
    //   formIsValid = false;
    //   formErrors["fromDateError"] = myConstClass.fromDateMsg;
    // }

    // if (toDate.toString().trim() === "" || toDate === null) {
    //   formIsValid = false;
    //   formErrors["toDateError"] = myConstClass.toDateMsg;
    // }

    if ( fromDate > toDate )
    {
      formIsValid = false;
      formErrors[ "toDateError" ] = myConstClass.displaytoMsg;
    }

    if ( Object.keys(
      studentData?.getInterestedStudByIdStatus
    ).length == 0 && companyNo != "" )
    {
      if ( !onlyNumber.test( companyNo ) )
      {
        formIsValid = false;
        formErrors[ "contactNoError" ] = myConstClass.contactNumberMsg;
      } else if ( companyNo.length > 0 && companyNo.length != 10 )
      {
        formIsValid = false;
        formErrors[ "contactNoError" ] = myConstClass.contactNoLengthMsg;
      }
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  changeH = ( fileName, name, index ) =>
  {
    if ( fileName !== undefined )
    {
      this.setState( {
        ...this.state,
        fileName: fileName,
      } );
    }
  };

  uploadImage = () =>
  {
    const { fileName, documentName, docNameDD } = this.state;
    if ( fileName !== "" )
    {
      this.setState( {
        upload: true,

        uploadError: false,
      } );

      const formData = new FormData();
      formData.append( "file", this.state.fileName );
      apiPost( {
        url: endpoints.fileUpload,
        postBody: formData,
      } ).then( ( { data, success } ) =>
      {
        if ( success )
        {
          let docList = [
            ...this.props.fileData.fileData,
            {
              index: this.props.fileData.fileData.length + 1,
              name: docNameDD === "1" ? documentName : docNameDD,
              fileName: data,
            },
          ];
          this.setState( {
            documentName: "",
            docNameDD: "",
            fileName: "",
            upload: false,
            uploadError: false,
          } );
          this.props.fileMulitipleSave( { fileData: docList } );
        }
      } );
    } else
    {
      this.setState( {
        upload: false,
        uploadError: true,
      } );
    }
  };

  closeStudentFeesDetails = () =>
  {
    this.setState( { interestedStudent: false, listOfComp: true } );
  };

  onSave = ( e ) =>
  {
    e.preventDefault();
    const {
      id,
      companyName,
      remark,
      companyAddress,
      designation,
      internType,
      stipend,
      fromDate,
      toDate,
      companyMail,
      companyNo,
    } = this.state;
    const { studentData } = this.props;
    let fromDt = moment( fromDate ).format( "DD-MM-YYYY" );
    let toDt = moment( toDate ).format( "DD-MM-YYYY" );
    if ( this.handleFormValidation() )
    {
      const documentList = this.props.fileData.fileData.map( ( docData ) =>
      {
        let docs = {
          docName: docData.name,
          fileName: docData.fileName,
        };
        if ( docData.id )
        {
          docs = {
            ...docs,
            id: docData.id,
          };
        }
        return docs;
      } );

      let studentInfo = {
        internshipDeclare: null,
        companyName: companyName,
        companyAdd: companyAddress,
        rollNo: "",
        remark: remark,
        userRegistrationId: "",
        selectedStatus: 1,
        designation: designation,
        stipend: stipend,
        durationFromDate: fromDt,
        durationToDate: toDt,
        internshipType: {
          id: internType,
        },
        vivaStatus: 0,
        approvedStatus: 0,
        intrestStatus: 1,
        internshipIntrestedStudentDocument: documentList,
        companyEmail: companyMail,
        contactNo: companyNo,
      };

      swal( {
        title: "Are you sure?",
        text: "Do you want to save the data?",
        icon: "warning",
        buttons: [ "Cancel", true ],
      } ).then( ( res ) =>
      {
        if ( res )
        {
          if ( id !== 0 )
          {
            let student = studentData?.getInterestedStudByIdStatus;
            student = {
              ...student,
              designation: designation,
              stipend: stipend,
              durationFromDate: fromDt,
              durationToDate: toDt,
              internshipIntrestedStudentDocument: documentList,
            };

            apiPost( {
              url: endpoints.internshipIntrest + "/edit",
              postBody: student,
            } ).then( ( { data, success } ) =>
            {
              if ( !success )
              {
                this.props.getAlert( { message: "Something went wrong" } );
              } else
              {
                this.props.showNotification( {
                  msg: "Data updated successfully",
                } );
                this.props.goBack();
                this.props.deleteFileData();
              }
            } );
          } else
          {
            apiPost( {
              url: endpoints.internshipIntrest,
              postBody: studentInfo,
            } ).then( ( { data, success } ) =>
            {
              if ( !success )
              {
                this.props.getAlert( { message: "Something went wrong" } );
              } else
              {
                this.props.showNotification( { msg: "Data saved successfully" } );
                this.props.goBack();
                this.props.deleteFileData();
              }
            } );
          }
        }
      } );
    }
  };
  render()
  {
    const {
      listOfComp,
      fromDate,
      toDate,
      internType,
      companyName,
      companyAddress,
      remark,
      designation,
      stipend,
      documentName,
      uploadError,
      upload,
      documentListLocal,
      docNameDD,
      fileName,
      companyMail,
      companyNo,
    } = this.state;
    const {
      fromDateError,
      toDateError,
      designationError,
      companyNameError,
      companyAddressError,
      internTypeError,
      contactNoError,
    } = this.state.formErrors;
    const {
      goBack,
      intrestedStudList,
      studentData,
      fileData,
      internshipTypeList,
    } = this.props;
    return (
      <>
        <div>
          <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
            {listOfComp && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Student Add Internship  "
                    />
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back To List"
                      onClick={goBack}
                    />
                  </Grid>
                </Grid>
                <br />
                <form
                  onSubmit={this.submitHandler}
                  autoComplete="off"
                  noValidate={true}
                >
                  <Grid container justifyContent="space-evenly" direction="row">
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Intern Type"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <AutoComplete
                            keyColName={"id"}
                            value={internType}
                            name={"internType"}
                            options={internshipTypeList?.internshipType.filter(
                              ( data ) => data.isActive == 1
                            )}
                            onChange={this.ChangeHandlerSearch}
                            isError={internTypeError ? true : false}
                            errorText={internTypeError ? internTypeError : " "}
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Company Name"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            size="small"
                            color="primary"
                            name="companyName"
                            id="companyName"
                            value={companyName}
                            onChange={this.changeHandler}
                            fullWidth
                            error={companyNameError ? true : false}
                            helperText={
                              companyNameError ? companyNameError : " "
                            }
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Company Address"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            size="small"
                            color="primary"
                            name="companyAddress"
                            id="companyAddress"
                            value={companyAddress}
                            onChange={this.changeHandler}
                            fullWidth
                            error={companyAddressError ? true : false}
                            helperText={
                              companyAddressError ? companyAddressError : " "
                            }
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Company Email"
                          />
                        </Grid>

                        <Grid
                          sx={{ marginTop: -3 }}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <TextField
                            size="small"
                            color="primary"
                            name="companyMail"
                            id="companyMail"
                            value={companyMail}
                            onChange={this.changeHandler}
                            fullWidth
                            // error={designationError ? true : false}
                            // helperText={
                            //   designationError ? designationError : " "
                            // }
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          ></TextField>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Company No."
                          />
                        </Grid>
                        <Grid
                          // sx={{ marginTop: -3 }}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <TextField
                            size="small"
                            color="primary"
                            name="companyNo"
                            id="companyNo"
                            value={companyNo}
                            onChange={this.changeHandler}
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                            error={contactNoError ? true : false}
                            helperText={contactNoError ? contactNoError : " "}
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length != 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Designation"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            size="small"
                            color="primary"
                            name="designation"
                            id="designation"
                            value={designation}
                            onChange={this.changeHandler}
                            fullWidth
                            error={designationError ? true : false}
                            helperText={
                              designationError ? designationError : " "
                            }
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          ></TextField>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Stipend( ₹ Per month )"
                          />
                        </Grid>
                        <Grid
                          sx={{ marginTop: -3 }}
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <TextField
                            size="small"
                            color="primary"
                            name="stipend"
                            id="stipend"
                            value={stipend}
                            onChange={this.changeHandler}
                            fullWidth
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          //   {...(codeErr ? { error: true, helperText: codeErr }:"")}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*From Date"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <BasicDatePicker
                            color="primary"
                            name="fromDate"
                            size="small"
                            value={fromDate}
                            fullWidth
                            onChange={this.changeFromDate}
                            isError={fromDateError ? true : false}
                            errorText={fromDateError ? fromDateError : " "}
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                            maxDate={new Date()}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*To Date"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <BasicDatePicker
                            color="primary"
                            name="toDate"
                            size="small"
                            value={toDate}
                            // minDate={fromDate}
                            // maxDate={new Date()}
                            fullWidth
                            onChange={this.changeToDate}
                            isError={toDateError ? true : false}
                            errorText={toDateError ? toDateError : " "}
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {studentData?.getInterestedStudByIdStatus.intrestStatus ===
                      1 &&
                      studentData?.getInterestedStudByIdStatus
                        .selectedStatus === 1 && (
                        <>
                          <Grid
                            container
                            justifyContent="space-evenly"
                            direction="row"
                            columnSpacing={1}
                            xs={12}
                            sm={4}
                            md={4}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="*Document Upload"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <AutoComplete
                                keyColName={"id"}
                                value={docNameDD}
                                name={"docNameDD"}
                                options={documentListLocal}
                                onChange={this.ChangeHandlerSearch}
                              />
                            </Grid>
                          </Grid>
                          {docNameDD === "1" ? (
                            <>
                              <Grid
                                container
                                justifyContent="space-evenly"
                                direction="row"
                                columnSpacing={1}
                                xs={12}
                                sm={4}
                                md={4}
                              >
                                <Grid item xs={12} sm={12} md={12}>
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16 }}
                                    label="Enter Document"
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    name="documentName"
                                    id="documentName"
                                    value={documentName}
                                    onChange={this.changeHandler}
                                    fullWidth
                                    helperText=" "
                                  ></TextField>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                justifyContent="space-evenly"
                                direction="row"
                                columnSpacing={1}
                                rowSpacing={2}
                                xs={12}
                                sm={1}
                                md={1}
                                sx={{ marginTop: 1 }}
                              >
                                <Grid item xs={12} sm={12} md={12}>
                                  <AttachFile name="" fileName={this.changeH} />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                justifyContent="space-evenly"
                                direction="row"
                                columnSpacing={1}
                                rowSpacing={2}
                                xs={12}
                                sm={2}
                                md={2}
                                sx={{ marginTop: 1 }}
                              >
                                <Grid item xs={12} sm={12} md={12}>
                                  &nbsp; {fileName !== "" ? fileName.name : ""}
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <Grid
                              container
                              justifyContent="space-evenly"
                              direction="row"
                              columnSpacing={1}
                              rowSpacing={2}
                              xs={12}
                              sm={7}
                              md={7}
                              sx={{ marginTop: 1 }}
                            >
                              <Grid item xs={12} sm={12} md={12}>
                                <AttachFile name="" fileName={this.changeH} />
                                &nbsp; {fileName !== "" ? fileName.name : ""}
                              </Grid>
                            </Grid>
                          )}
                          <Grid
                            container
                            justifyContent="space-evenly"
                            direction="row"
                            columnSpacing={1}
                            rowSpacing={2}
                            xs={12}
                            sm={1}
                            md={1}
                            sx={{ marginTop: 1 }}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <ButtonCompo
                                size="medium"
                                type="button"
                                variant="contained"
                                fullWidth={true}
                                {...( ( ( docNameDD == "1" &&
                                  documentName !== "" ) ||
                                  docNameDD !== "" ) &&
                                  upload && { disable: true } )}
                                name="Upload"
                                onClick={this.uploadImage}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            {uploadError && (
                              <FormHelperText error>
                                {"please select file and Document Name"}
                              </FormHelperText>
                            )}
                          </Grid>

                          <Grid
                            sx={{ mt: 3, mr: 0.7 }}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <DynamicTable
                              data={fileData.fileData}
                              tableHead={this.columns}
                              showHeadDelete={true}
                              rowDelete={this.onClickDelete}
                              showPegination={false}
                              rowViewData={this.details}
                              showView={true}
                            />
                          </Grid>
                        </>
                      )}

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={1}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-evenly"
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Remark"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            size="small"
                            color="primary"
                            name="remark"
                            id="remark"
                            value={remark}
                            onChange={this.changeHandler}
                            fullWidth
                            {...( Object.keys(
                              studentData?.getInterestedStudByIdStatus
                            ).length !== 0
                              ? { disabled: true }
                              : { disabled: false } )}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    m={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1}
                      lg={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                        fullWidth={true}
                        onClick={goBack}
                      />
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1}
                      lg={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save"
                        fullWidth={true}
                        onClick={this.onSave}
                      />
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
            {( ( docNameDD == "1" && documentName !== "" ) || docNameDD !== "" ) &&
              upload && <Loading1 />}
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  studentData: state.companyList,
  internshipTypeList: state.internshipType,
  intrestedStudList: state.internshipDeclaration,
  fileData: state.file,
} );
const mapDispatchToProps = {
  getClass,
  getAcademicYear,
  getInternshipType,
  getYearDetails,
  getStudentList,
  getAlert,
  fileMulitipleSave,
  saveFile,
  deleteFile,
  openFileInNewTab,
  deleteFileData,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( StudentDeclareInternshipListTable );
