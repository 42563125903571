import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AttachFile from "../../../components/Comman/AttachFileForMultiSelect";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import CheckBoxSelectionTable from "../../../components/Comman/RT/CheckBoxSelectionTable";
import { LabelCompo } from "../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import endpoints from "../../../config/endpoints";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import {
  openFileInNewTab,
  deleteFile,
  deleteAllFile,
  // downloadVideo,
  saveVideo,
} from "../../UploadFile/file.slice";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { downloadVideo, downloadStudentVideo } from "../../Video/Video";
import swal from "sweetalert";
import { getFolder } from "./adminGallary.slice";
import { Loading1 } from "../../../components/Loading1";
import AdminVideo from "./AdminVideo";
import VideoEx from "../AluminiGallery/VideoEx";
import AdminGallarYPopup from "./AdminGallarYPopup";

// const inputRef = useRef();

const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Document Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];

const column = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Video Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
let docs;
class AdminGalleryFolder extends Component {
  state = {
    id: 0,
    description: "",
    title: "",
    formErrors: [],
    documentName: "",
    fileName: "",
    upload: false,
    detail: "",
    date: "",
    docType: "0",
    isActive: 1,
    detailsName: "",
    rows: [],
    videoRows: [],
    list2: [],
    showLoader: false,
    setList: [],
    setList1: [],
    gallaryList: [],
    isError: false,
    file: "",
    showVideo: false,
    videoListData: [],
    urlData: "",
    index: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      ...this.state,
      [dataKey]: date,
    });
  };

  addDetails = () => {};

  getRow = (e) => {
    const { rows, docType, videoRows } = this.state;
    let list2 = [];
    if (docType === "0") {
      const gallaryList = e.map((data) => {
        let list1 = rows.filter((row) => row.id === data);
        console.log(list1);
        if (list1.length !== 0) {
          list2.push(list1[0].path);
        }
        return list1[0];
      });
      this.setState({ setList: e, setList1: list2, gallaryList: gallaryList });
    } else {
      const gallaryList = e.map((data) => {
        let list1 = videoRows.filter((row) => row.id === data);
        console.log(list1);
        if (list1.length !== 0) {
          list2.push(list1[0].path);
        }
        return list1[0];
      });
      this.setState({ setList: e, setList1: list2, gallaryList: gallaryList });
    }
  };

  deleteFileHandler = () => {
    const {
      rows,
      gallaryList,
      setList1,
      setList,
      videoRows,
      docType,
      showLoader,
    } = this.state;
    console.log(videoRows);
    if (setList.length !== 0) {
      this.setState({
        isError: false,
      });
      if (docType === "0") {
        const results = rows.filter(
          ({ index: id1 }) => !gallaryList.some(({ index: id2 }) => id2 === id1)
        );

        let list3 = [];
        let list4 = [];
        gallaryList.map((data) => {
          if (data.deleteId === 0) {
            list3.push(data.id);
          } else {
            list4.push(data.id);
          }
        });

        swal({
          title: "Are you sure?",
          text: "Do you want to delete?",
          icon: "warning",
          buttons: ["Cancel", true],
        }).then((res) => {
          if (res) {
            this.setState({
              showLoader: true,
            });
            if (list3.length !== 0) {
              apiDelete({
                url:
                  endpoints.folder +
                  "-content/delete-by-ids?folderContentIds=" +
                  list3,
              }).then(({ success }) => {
                if (success) {
                  this.props
                    .deleteAllFile({ fileNames: setList1 })
                    .then((success) => {
                      if (success) {
                        this.props.showNotification({
                          msg: "Data Deleted successfully",
                        });
                      }
                    });

                  this.setState({
                    rows: results,
                    showLoader: false,
                  });
                } else {
                  this.props.getAlert({ message: "Failed to delete" });
                  this.setState({
                    rows: rows,
                    showLoader: false,
                  });
                }
              });
            } else {
              apiDelete({
                url:
                  endpoints.folder +
                  "-content/delete-by-ids?folderContentIds=" +
                  setList1,
              }).then(({ success }) => {
                if (success) {
                  this.setState({
                    rows: results,
                    showLoader: false,
                  });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
            }
          }
        });
      } else {
        const results = videoRows.filter(
          ({ index: id1 }) => !gallaryList.some(({ index: id2 }) => id2 === id1)
        );

        let list3 = [];
        let list4 = [];
        gallaryList.map((data) => {
          if (data.deleteId === 0) {
            list3.push(data.id);
          } else {
            list4.push(data.id);
          }
        });

        swal({
          title: "Are you sure?",
          text: "Do you want to delete?",
          icon: "warning",
          buttons: ["Cancel", true],
        }).then((res) => {
          if (res) {
            this.setState({
              showLoader: true,
            });
            if (list3.length !== 0) {
              apiDelete({
                url:
                  endpoints.folder +
                  "-content/delete-by-ids?folderContentIds=" +
                  list3,
              }).then(({ success }) => {
                if (success) {
                  this.props
                    .deleteAllFile({ fileNames: setList1 })
                    .then((success) => {
                      if (success) {
                        this.props.showNotification({
                          msg: "Data Deleted successfully",
                        });
                      }
                    });

                  this.setState({
                    videoRows: results,
                    showLoader: false,
                  });
                } else {
                  this.props.getAlert({ message: "Failed to delete" });
                  this.setState({
                    videoRows: rows,
                    showLoader: false,
                  });
                }
              });
            } else {
              apiDelete({
                url:
                  endpoints.folder +
                  "-content/delete-by-ids?folderContentIds=" +
                  setList1,
              }).then(({ success }) => {
                if (success) {
                  this.setState({
                    videoRows: results,
                    showLoader: false,
                  });
                } else {
                  this.setState({
                    showLoader: false,
                  });
                }
              });
            }
          }
        });
      }
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  handleFormValidation() {
    const { title, description, date, fileName } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (title.toString().trim() === "" || title === null) {
      formIsValid = false;
      formErrors["titleError"] = myConstClass.eventNameMsg;
    }
    if (description.toString().trim() === "" || description === null) {
      formIsValid = false;
      formErrors["descriptionError"] = myConstClass.descriptionMsg;
    }

    if (date === "" || date === null) {
      formIsValid = false;
      formErrors["dateError"] = myConstClass.dateMsg;
    }

    this.setState({ ...this.state, formErrors: formErrors });
    return formIsValid;
  }
  handleDetailValidation() {
    const { file, fileName, docType } = this.state;

    let formErrors = {};
    let formIsValid = true;

    // if (detailsName.toString().trim() === "" || detailsName === null) {
    //   formIsValid = false;
    //   formErrors["detailsNameError"] = myConstClass.titleMsg;
    // }
    if (fileName.toString().trim() === "" || fileName === null) {
      formIsValid = false;
      formErrors["fileNameError"] = myConstClass.fileName;
    }

    this.setState({ ...this.state, formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      rows,
      title,
      description,
      date,
      isActive,
      videoRows,
      videoListData,
    } = this.state;
    console.log(videoListData);
    let dataToSave = rows.map((data) => {
      if (data.deleteId === 0) {
        let document = {
          id: data.id,
          docType: "0",
          name: data.name,
          path: data.path,
        };
        return document;
      } else {
        let document = {
          docType: "0",
          name: data.name,
          path: data.path,
        };
        return document;
      }
    });
    console.log(videoRows);

    let videoToSave = videoRows.map((data) => {
      if (data.deleteId === 0) {
        let video = {
          id: data.id,
          docType: "1",
          name: data.name,
          path: data.path,
        };
        dataToSave.push(video);
      } else {
        let video = {
          docType: "1",
          name: data.name,
          path: data.path,
        };
        dataToSave.push(video);
      }
    });
    console.log(dataToSave);
    let addNewsDate = moment(date).format("DD-MM-YYYY");
    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit the data?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.setState({
            showLoader: true,
          });
          docs = {
            name: title,
            detail: description,
            date: addNewsDate,
            isActive: isActive,
            folderContents: dataToSave,
          };

          if (id != 0) {
            docs = {
              ...docs,
              id: id,
            };
          }

          apiPost({
            url: endpoint.folder,
            postBody: docs,
          }).then(({ success }) => {
            if (success) {
              this.props.getFolder().then((resp) => {
                if (!resp) {
                  this.props.getAlert({
                    message: "Server Failed",
                  });
                }
              });
              this.props.onCancelForm();
              this.props.showNotification({
                msg: "Data saved successfully",
              });
              this.setState({
                name: "",
                detail: "",
                date: "",
                folderContents: [],
                list: [],
                videoRows: [],
                showLoader: false,
              });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        }
      });
    }
  };

  componentDidMount() {
    if (Object.keys(this.props.fieldData).length !== 0) {
      const dateArr =
        this.props.fieldData.date == null
          ? ""
          : this.props.fieldData.date.split("-");
      const date =
        dateArr === "" ? "" : new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);

      this.setState({
        id: this.props.fieldData.id,
        isActive: this.props.fieldData.isActive,
        description: this.props.fieldData.detail,
        title: this.props.fieldData.name,
        date: date,
        // rows: this.props.fieldData.folderContents,
      });
      if (this.props.fieldData.folderContents.length !== 0) {
        const { rows, videoRows, videoListData } = this.state;
        // console.log(this.props.fieldData.folderContents);
        this.props.fieldData.folderContents.map((fileData, index) => {
          if (fileData.docType == "0") {
            apiGet({
              url: endpoint.fileDownload + "/" + fileData.path,
            }).then(({ data, success }) => {
              if (success) {
                fileData = {
                  ...fileData,
                  index: index,
                  fileName: data.data,
                };
                rows.push(fileData);
                console.log(rows);
                this.setState({
                  rows: rows,
                });
              } else {
                fileData = {
                  index: index,
                  ...fileData,
                };
                rows.push(fileData);
                this.setState({
                  rows: rows,
                });
              }
            });
            // rows.push(data);
          } else {
            videoRows.push(fileData);
            // this.props.downloadVideo({ url: data.path }).then((response) => {
            //   console.log(response);
            //   if (response) {
            //     let data1 = {
            //       index: videoListData.length + 1,
            //       response: response,
            //     };
            //     videoListData.push(data1);
            //   }
            // });
          }
        });
      }
      console.log(this.props.fieldData);
    }
  }

  fileData = (video) => {
    const { rows, detailsName, docType, file, videoRows } = this.state;

    console.log(video.target.files[0]);
    this.setState({
      ...this.state,
      file: video.target.files[0],
    });
    // else {
    console.log(video.target.files[0]);
    if (video.target.files[0] !== "") {
      this.setState({
        showLoader: true,
      });
      let list = [...videoRows];

      this.props.saveVideo({ file: video.target.files[0] }).then((data) => {
        if (data.success) {
          let document = {
            index: list.length + 1,
            id: list.length + 1,
            name: video.target.files[0].name,
            path: data.data,
            docType: docType,
            deleteId: 1,
          };
          list.push(document);

          this.setState({
            ...videoRows,
            videoRows: list,
            showLoader: false,
            fileName: "",
            file: "",
          });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
    // }
  };

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName.target.files,
    });
    if (fileName !== "") {
      const { rows, detailsName, docType, file, videoRows } = this.state;

      // if (docType === "0") {
      // if (this.handleDetailValidation()) {
      this.setState({
        showLoader: true,
      });
      const formData = new FormData();
      fileName.target.files.forEach((file) => {
        formData.append("files", file);
      });
      console.log(formData);
      let list1 = [...rows];

      apiPost({
        url: "/api/document/v1/file/upload-list",
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          data.map((data1, index) => {
            let document = {
              index: list1.length + 1,
              id: list1.length + 1,
              name: fileName.target.files[index].name,
              path: data1,
              docType: docType,
              deleteId: 1,
            };
            // list1.push(document);
            apiGet({
              url: endpoint.fileDownload + "/" + data1,
            }).then(({ data, success }) => {
              if (success) {
                document = {
                  ...document,
                  fileName: data.data,
                };
                list1.push(document);
                this.setState({
                  rows: list1,
                });
                console.log(list1);
              } else {
                document = {
                  ...document,
                };
                list1.push(document);
                this.setState({
                  rows: list1,
                });
              }
            });
          });
          this.setState({
            showLoader: false,
            fileName: "",
          });
        } else {
          this.props.getAlert({ message: "Something went wrong" });
          this.setState({
            showLoader: false,
          });
        }
      });
      // }
      // }
      // this.props.showNotification({
      //   msg: "Image Selected!",
      // });
    }
  };

  rowDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        this.setState({
          showLoader: true,
        });
        apiDelete({
          url: endpoints.folder + "-content/" + data.id,
        }).then(({ success }) => {
          if (success) {
            this.props.deleteFile({ file: data.path }).then((response) => {
              this.props.showNotification({
                msg: "Data Deleted successfully",
              });
              if (!response) {
                getAlert({ message: "Something went wrong" });
              }
            });
            let videoList = this.state.videoRows.filter(
              (row) => row.index !== data.index
            );
            let list = this.state.rows.filter(
              (row) => row.index !== data.index
            );
            this.setState({
              rows: list,
              videoRows: videoList,
              showLoader: false,
            });
          } else {
            this.props.deleteFile({ file: data.path }).then((response) => {
              this.props.showNotification({
                msg: "Data Deleted successfully",
              });
              if (!response) {
                getAlert({ message: "Something went wrong" });
              } else {
                let videoList = this.state.videoRows.filter(
                  (row) => row.index !== data.index
                );
                let list = this.state.rows.filter(
                  (row) => row.index !== data.index
                );
                this.setState({
                  rows: list,
                  videoRows: videoList,
                  showLoader: false,
                });
              }
            });
          }
        });
      }
    });
  };

  componentDidUpdate() {
    if (this.state.upload) {
      this.setState({
        upload: false,
        detailsName: "",
        fileName: "",
      });
    }
  }

  handleClose = () => {
    this.setState({
      showVideo: false,
      urlData: this.state.urlData,
    });
  };

  viewVideo = (params) => {
    const { index, urlData } = this.state;
    console.log(params);
    this.setState({
      showLoader: true,
      urlData: "",
    });

    if (index == params.index) {
      this.setState({
        showVideo: true,
        urlData: urlData,
        showLoader: false,
      });
    } else {
      this.props.downloadVideo({ url: params.path }).then((response) => {
        console.log(response);
        if (response) {
          this.setState({
            showVideo: true,
            urlData: response,
            index: params.index,
            showLoader: false,
          });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
  };

  details = (params) => {
    this.setState({
      showLoader: true,
    });
    this.props.openFileInNewTab({ file: params.path }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  render() {
    const {
      fileName,

      title,
      description,
      date,
      detailsName,
      docType,
      isError,
      showVideo,
      videoError,
      urlData,
    } = this.state;
    const { onCancel, onCancelForm } = this.props;
    const {
      titleError,
      descriptionError,
      detailsNameError,
      fileNameError,
      docTypeError,
      dateError,
    } = this.state.formErrors;

    return (
      <>
        {this.state.showLoader && <Loading1 />}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label=""
              />
            </Grid>
            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={onCancelForm}
              />
            </Grid>
          </Grid>
          <br />
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Event Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="title"
                      size="small"
                      value={title}
                      onChange={this.changeHandler}
                      fullWidth
                      error={titleError ? true : false}
                      helperText={titleError ? titleError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="date"
                        size="small"
                        value={date}
                        fullWidth
                        onChange={this.onDateChange("date")}
                        isError={dateError ? true : false}
                        errorText={dateError ? dateError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="description"
                      size="small"
                      value={description}
                      onChange={this.changeHandler}
                      fullWidth
                      error={descriptionError ? true : false}
                      helperText={descriptionError ? descriptionError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Add Content"
                  />
                </Grid>

                {/* <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Image / Video Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} />
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="detailsName"
                        size="small"
                        value={detailsName}
                        onChange={this.changeHandler}
                        fullWidth
                        error={detailsNameError ? true : false}
                        helperText={detailsNameError ? detailsNameError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="docType"
                          id="docType"
                          value={docType}
                          onChange={this.changeHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="0">Image</MenuItem>
                          <MenuItem value="1">Video</MenuItem>
                        </Select>
                      </FormControl>
                      <FormHelperText error>
                        {docTypeError ? docTypeError : " "}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid> */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="docType"
                          id="docType"
                          value={docType}
                          onChange={this.changeHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="0">Image</MenuItem>
                          <MenuItem value="1">Video</MenuItem>
                        </Select>
                      </FormControl>
                      <FormHelperText error>
                        {docTypeError ? docTypeError : " "}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  ></Grid>
                </Grid>

                {this.state.docType == "0" ? (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Select Images"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={8}
                        lg={8}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <AttachFile name={"file"} fileName={this.changeH} />
                          &nbsp; {fileName !== "" ? fileName.name : ""}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          {/* <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Add Selected Images"
                            fullWidth={true}
                            onClick={this.addDetails}
                          /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Select Video"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={8}
                        lg={8}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <AdminVideo handleFileChange={this.fileData} />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          {/* <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Add Selected Images"
                            fullWidth={true}
                            onClick={this.addDetails}
                          /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {isError ? (
                  <FormHelperText error>
                    {"*Please select atleast one record"}
                  </FormHelperText>
                ) : (
                  <FormHelperText>{}</FormHelperText>
                )}
              </Grid>
              {this.state.docType == "0" ? (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <CheckBoxSelectionTable
                      rows={this.state.rows}
                      columns={[
                        ...columns,
                        {
                          title: "",
                          name: "",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                      ]}
                      rowViewData={this.details}
                      rowDelete={this.rowDelete}
                      showDelete={true}
                      showView={true}
                      selection={this.state.setList}
                      isActionColActive={true}
                      onSelectionChange={this.getRow}
                      docType={docType}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <CheckBoxSelectionTable
                      rows={this.state.videoRows}
                      columns={column}
                      rowViewData={this.viewVideo}
                      rowDelete={this.rowDelete}
                      showDelete={true}
                      showView={true}
                      selection={this.state.setList}
                      isActionColActive={true}
                      onSelectionChange={this.getRow}
                    />
                  </Grid>
                </>
              )}
              {showVideo && (
                // <Grid item xs={12} sm={12} md={12}>
                //   <video width="550" height="300" controls>
                //     <source src={urlData} type="video/mp4" />
                //   </video>
                // </Grid>
                <AdminGallarYPopup
                  urlData={urlData}
                  open={showVideo}
                  handleClose={this.handleClose}
                />
              )}

              <Grid
                sx={{ mr: 0.5 }}
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                m={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.5}
                  lg={1.5}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Delete All"
                    onClick={this.deleteFileHandler}
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancelForm}
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fileData: state.file,
  academicYear: state.academicYear,
  internshipDeclarationList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  openFileInNewTab,
  deleteFile,
  getFolder,
  deleteAllFile,
  downloadVideo,
  saveVideo,
  downloadStudentVideo,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminGalleryFolder);
