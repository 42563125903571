import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { ChipCompo, MyComponentWithIconProps } from "../IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import DownloadIcon from "@mui/icons-material/Download";
import { LabelCompo } from "../Label";
import { CommonNameFormat } from "../Util/CommonNameFormat";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    tableHead,
    hiddenColumnNames,
    orderBy,

    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell, index) => {
          const hiddenColumn = hiddenColumnNames.filter(
            (hiddenCol) => hiddenCol === headCell.name
          );

          return (
            <TableCell
              key={headCell.name}
              align={"center"}
              sx={{ display: hiddenColumn.length !== 0 && "none" }}
              style={{
                minWidth:
                  headCell.name === "index"
                    ? 100
                    : headCell.width
                    ? headCell.width
                    : 100,
              }}
              // width={headCell.name === "index" ? "10%" : headCell.width?headCell.width:''}
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.name ? order : false}
            >
              {headCell.title}
              {orderBy === headCell.name ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  tableData,
  tableHead,
  onChangeStatus,
  rowDelete,
  rowEdit,
  showEditIcon = true,
  showDeleteIcon = true,
  condition = [],
  conditionDelete,
  rowAdd,
  rowTransfer,
  rowView,
  showAddButtonInTable,
  showTransferButtonInTable,
  showViewButtonInTable,
  isActionColActive,
  hiddenColumnNames,
  showDownload,
  rowDownload,
  setData,
  showSend,
  rowSend,
}) {
  const [role, setRole] = React.useState("");

  const changeStatus = (e, row) => {
    e.stopPropagation();
    onChangeStatus(row);
  };
  const onClickEdit = (e, row) => {
    e.stopPropagation();
    rowEdit(row);
  };
  const onClickSend = (e, row) => {
    e.stopPropagation();
    rowSend(row);
  };
  const onClickDelete = (e, row) => {
    e.stopPropagation();
    rowDelete(row);
  };
  const onClickAdd = (e, row) => {
    e.stopPropagation();
    rowAdd(row);
  };

  const onClickTransfer = (e, row) => {
    e.stopPropagation();
    rowTransfer(row);
  };
  const onClickView = (e, row) => {
    e.stopPropagation();
    rowView(row);
  };

  const onClickDownload = (e, row) => {
    e.stopPropagation();
    rowDownload(row);
  };

  let conditionApplied = condition.filter((rowData) => {
    if (row[rowData.title]) {
      return row;
    } else {
      return [];
    }
  });
  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  }, []);

  const onClickRead = (id) => {
    const rows = tableData.map((rowData) => {
      if (rowData.id === id) {
        rowData = {
          ...rowData,
          open: !rowData.open,
        };
        return rowData;
      }
      return rowData;
    });
    setData(rows);
  };
  return (
    <TableRow key={row.name}>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter((row) => row.name === key);
        const hiddenColumn = hiddenColumnNames.filter(
          (hiddenCol) => hiddenCol === key
        );

        if ( filteredList.length !== 0 )
        {
          if (filteredList[0].isFormat!=undefined&&filteredList[0].isFormat) {
         
         const formatedNameData=CommonNameFormat(filteredList[0].type,filteredList[0].keyName,row)
        
         return (
          <TableCell>
           {formatedNameData}
          </TableCell>
        );
        }else
          if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell style={{ width: "10%" }}>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{ width: "15%" }}
              >
                {row[key] == 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].readMore) {
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {!row.open &&
                row[filteredList[0].readMoreData].length >
                  row[filteredList[0].readMoreData].slice(0, 70).length
                  ? row[filteredList[0].readMoreData].slice(0, 70) + " ... "
                  : row[filteredList[0].readMoreData].slice(0, 70)}{" "}
                {!row.open &&
                  row[filteredList[0].readMoreData] !==
                    row[filteredList[0].readMoreData].slice(0, 70) && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={" Read more"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
                {row.open && row[filteredList[0].readMoreData]}
                {row.open &&
                  row[filteredList[0].readMoreData].length >
                    row[filteredList[0].readMoreData].slice(0, 70).length && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={"Read less"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
              </TableCell>
            );
          } else
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {row[key]}
              </TableCell>
            );
        }
      })}
      {isActionColActive && (
        <>
          <TableCell align={"center"} style={{ width: "13%" }}>
            {role !== "parent" &&
            condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            conditionApplied[0].iconHide === "showEditIcon" &&
            showEditIcon &&
            row[conditionApplied[0].title] !== conditionApplied[0].value ? (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            ) : role !== "parent" &&
              condition.length !== 0 &&
              showEditIcon &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide !== "showEditIcon" ? (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            ) : (
              condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide === "showEditIcon" &&
              row[conditionApplied[0].title] === conditionApplied[0].value && (
                <> &nbsp;&nbsp;&nbsp;&emsp;</>
              )
            )}
            {role !== "parent" && condition.length === 0 && showEditIcon && (
              <MyComponentWithIconProps
                statusImage={EditIcon}
                color="primary"
                fontSize="medium"
                title="Edit"
                onClick={(e) => onClickEdit(e, row)}
              />
            )}

            {condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            conditionApplied[0].iconHide === "showDeleteIcon" &&
            showDeleteIcon &&
            row[conditionApplied[0].title] !== conditionApplied[0].value ? (
              <>
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            ) : condition.length !== 0 &&
              showDeleteIcon &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].deleteIconHide === "showDeleteIcon" &&
              row[conditionApplied[0].title] === conditionApplied[0].value ? (
              <>&nbsp;&nbsp;</>
            ) : condition.length !== 0 &&
              showDeleteIcon &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide !== "showDeleteIcon" ? (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            ) : (
              ""
            )}
            {condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide === "showDeleteIcon" &&
              row[conditionApplied[0].title] === conditionApplied[0].value && (
                <>&nbsp;&nbsp;&nbsp;&emsp;</>
              )}

            {condition.length === 0 && showDeleteIcon && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            )}
            {showSend && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={SendIcon}
                  color="primary"
                  fontSize="medium"
                  title="Details"
                  onClick={(e) => onClickSend(e, row)}
                />
              </>
            )}
            {showAddButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AddCircleIcon}
                  color="primary"
                  fontSize="medium"
                  title="Add"
                  onClick={(e) => onClickAdd(e, row)}
                />
              </>
            )}
            {showTransferButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={MoveUpIcon}
                  color="primary"
                  fontSize="medium"
                  title="Transfer"
                  onClick={(e) => onClickTransfer(e, row)}
                />
              </>
            )}

            {showViewButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickView(e, row)}
                />
              </>
            )}
            {showDownload && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DownloadIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickDownload(e, row)}
                />
              </>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  columns,
  onChangeStatus,
  rowDelete,
  rowEdit,
  showEditIcon,
  showDeleteIcon,
  tableData = [],
  showAddButtonInTable,
  showTransferButtonInTable,
  showViewButtonInTable,
  hiddenColumnNames = [],
  showPegination = true,
  rowAdd,
  rowTransfer,
  rowView,
  condition,
  isActionColActive = true,
  showDownload,
  rowDownload,
  setData,
  showSend,
  rowSend,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={columns}
            hiddenColumnNames={hiddenColumnNames}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
          />
          <TableBody>
            {showPegination
              ? stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        tableHead={columns}
                        rowEdit={rowEdit}
                        showEditIcon={showEditIcon}
                        showDeleteIcon={showDeleteIcon}
                        condition={condition}
                        onChangeStatus={onChangeStatus}
                        rowDelete={rowDelete}
                        rowAdd={rowAdd}
                        rowTransfer={rowTransfer}
                        rowView={rowView}
                        showViewButtonInTable={showViewButtonInTable}
                        showAddButtonInTable={showAddButtonInTable}
                        showTransferButtonInTable={showTransferButtonInTable}
                        isActionColActive={isActionColActive}
                        hiddenColumnNames={hiddenColumnNames}
                        showDownload={showDownload}
                        rowDownload={rowDownload}
                        tableData={tableData}
                        setData={setData}
                        showSend={showSend}
                        rowSend={rowSend}
                      />
                    );
                  })
              : stableSort(tableData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        tableHead={columns}
                        rowEdit={rowEdit}
                        showEditIcon={showEditIcon}
                        showDeleteIcon={showDeleteIcon}
                        condition={condition}
                        onChangeStatus={onChangeStatus}
                        rowDelete={rowDelete}
                        rowAdd={rowAdd}
                        rowTransfer={rowTransfer}
                        rowView={rowView}
                        showViewButtonInTable={showViewButtonInTable}
                        showAddButtonInTable={showAddButtonInTable}
                        showTransferButtonInTable={showTransferButtonInTable}
                        isActionColActive={isActionColActive}
                        hiddenColumnNames={hiddenColumnNames}
                        showDownload={showDownload}
                        rowDownload={rowDownload}
                        tableData={tableData}
                        setData={setData}
                        showSend={showSend}
                        rowSend={rowSend}
                      />
                    );
                  }
                )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={columns.length} />
              </TableRow>
            )}
            {tableData.length === 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * 1,
                }}
              >
                <TableCell align="center" colSpan={columns.length}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPegination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
