import React from "react";

const VideoInput=(props)=> {
  const { width, height } = props;

  const inputRef = React.useRef();

  const [sourceData, setSource] = React.useState();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    console.log('event')
    console.log(event)

    console.log('url')
    console.log(url)
    setSource(url);
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <div className="VideoInput">
      <input
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        // accept=".mov,.mp4"
      />
      {!sourceData && <button onClick={handleChoose}>Choose</button>}
      {sourceData && (
        <video
          className="VideoInput_video"
          width="100%"
          height={height}
          controls
          src={sourceData}
        />
//         <video width="320" height="240" controls>
//   <source src={sourceData} type="video/mp4">
// </video>
      )}
      <div className="VideoInput_footer">{sourceData || "Nothing selectd"}</div>
    </div>
  );
}
export default VideoInput

// <VideoInput width={400} height={300} />