
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import {  apiGet } from "../../../../utils/api_service";

const initialState = {
  receiptType: [],
  isFetch: false,
};
let URL = endpoints.receiptType;
const receiptTypeSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    receiptTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        receiptType: row,
        isFetch: true,
      };
    },
  },
});

export const {
  receiptTypeSuccess,
} = receiptTypeSlice.actions;

export default receiptTypeSlice.reducer;

export const getReceiptType = () => async (dispatch) => {
  try {
    const response= apiGet({
    url: URL+'?sort={"insertdatetime": "DESC"}'
}).then(({data ,success }) => {
    if (success) {
      const {content} = data.data
      let index = 0;
      let row = [];
      content.map((data1) => {
        index = index + 1
        let bData = {
          index: index,
          id: data1.id,
          name: data1.name,
          isActive: data1.isActive ,

        };
        row.push(bData);
        return data1;
      });
        dispatch(receiptTypeSuccess({ row }));
    }
    return success
});
return response
  } catch (e) {
   
    return console.error(e.message);
  }
};