
import endpoint from "../config/endpoints";

export const coCurricularMasterJson = {
  apiBaseURL: endpoint.cocurricular,
  "screenTitle": "Co Curricular",
  "showAddButton": true,
  showPdfDownload: true,
  pdfFileName: "Co Curricular",
  showExcelDownload: true,
  excelFileName: "Co Curricular",
  baseIdColumn: "id",
  "fieldMeta": [

    {
      label: "Activity",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "employeeActivity",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true
    },
    {
      label: "Activity Details",
      controlType: "textfield",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "activityDetail",
      isMandatory: true,
    },

  ]
}
