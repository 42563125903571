import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  grievance: [],
  isFetch: false,

  grievanceAdmin: [],
  isGrievanceAdminFetch: false,
};
let URL = endpoints.grievance;

const grievanceSlice = createSlice({
  name: "grievance",
  initialState,
  reducers: {
    grievanceSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grievance: row,
        isFetch: true,
      };
    },

    grievanceAdminSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grievanceAdmin: row,
        isGrievanceAdminFetch: true,
      };
    },
  },
});

export const { grievanceSuccess, grievanceAdminSuccess } =
  grievanceSlice.actions;

export default grievanceSlice.reducer;

export const getGrievance = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            // attendedBy: data1.attendedBy===null ? "" : data1.attendedBy,
            // attendedDate: data1.attendedDate=== null ? "" : data1.attendedDate,
            problemLocation:
              data1.problemLocation === null ? "-" : data1.problemLocation,
            // department: data1.department.name,
            emailId: data1.emailId === null ? "-" : data1.emailId,
            contactNo: data1.contactNo === null ? "-" : data1.contactNo,
            // date: data1.date=== null ? "" : data1.date,
            complaintDetail: data1.date === null ? "-" : data1.date,
            complaintDate: data1.date === null ? "" : data1.date,
            attendedBy: data1.attendedBy === null ? "-" : data1.attendedBy,
            attendedDate:
              data1.attendedDate === null ? "-" : data1.attendedDate,
            type: data1.type === null ? "" : data1.type,
            status: data1.status === 1 ? "Completed" : "Pending",
          };
          row.push(bData);
          return data1;
        });
        dispatch(grievanceSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getGrievanceByFilter =
  ({ fromDate, toDate, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-from-to-date-type?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&type=2&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              userRegistration:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.id,
              problemLocation:
                data1.problemLocation === null ? "" : data1.problemLocation,
              // department: data1.department.name,
              emailId: data1.emailId === null ? "" : data1.emailId,
              contactNo: data1.contactNo === null ? "" : data1.contactNo,
              // date: data1.date=== null ? "" : data1.date,
              complaintDetail:
                data1.complaintDetail === null ? "" : data1.complaintDetail,
              complaintDate: data1.date === null ? "" : data1.date,
              type: data1.type === null ? "" : data1.type,
              attendedBy: data1.attendedBy === null ? "-" : data1.attendedBy,
              attendedDate:
                data1.attendedDate === null ? "-" : data1.attendedDate,
              status:
                data1.status === 1
                  ? "In Process"
                  : data1.status === 2
                  ? "Completed"
                  : data1.status === 3
                  ? "Rejected"
                  : "Pending",
            };
            row.push(bData);
            return data1;
          });
          dispatch(grievanceSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//new

export const getGrievanceByLimit =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-grievance-by-type-and-limit?type=2&limit=20&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              userRegistration:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.id,
              problemLocation:
                data1.problemLocation === null ? "" : data1.problemLocation,
              // department: data1.department.name,
              emailId: data1.emailId === null ? "" : data1.emailId,
              contactNo: data1.contactNo === null ? "" : data1.contactNo,
              // date: data1.date=== null ? "" : data1.date,
              complaintDetail:
                data1.complaintDetail === null ? "" : data1.complaintDetail,
              complaintDate: data1.date === null ? "" : data1.date,
              type: data1.type === null ? "" : data1.type,
              attendedBy: data1.attendedBy === null ? "-" : data1.attendedBy,
              attendedDate:
                data1.attendedDate === null ? "-" : data1.attendedDate,
              status:
                data1.status === 1
                  ? "In Process"
                  : data1.status === 2
                  ? "Completed"
                  : data1.status === 3
                  ? "Rejected"
                  : "Pending",
            };
            row.push(bData);
            return data1;
          });
          dispatch(grievanceSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getGrievanceAdmin = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            attendedBy: data1.attendedBy,
            attendedDate: data1.attendedDate,
            // department: data1.department.name,
            problemLocation: data1.problemLocation,
            emailId: data1.emailId,
            contactNo: data1.contactNo,
            complaintDetail: data1.complaintDetail,
            complaintDate: data1.date,
            type: data1.type,
            status: data1.status,
            statusName:
              data1.status === 1
                ? "In Process"
                : data1.status === 2
                ? "Completed"
                : data1.status === 3
                ? "Rejected"
                : "Pending",
          };
          row.push(bData);
          return data1;
        });
        dispatch(grievanceAdminSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// new

export const getGrievanceAdminFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL +
        "/get-grievance-by-type-and-limit-for-admin" +
        '?filters={ "type": "2", "_mode": "EXACT" } &offset=0&limit=20&sort = { "insertdatetime": "DESC" }',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            userRegistration:
              data1.userRegistration === null ? "" : data1.userRegistration.id,
            lastName:
              data1.userRegistration != null
                ? data1.userRegistration.lastName
                : null,
            middleName:
              data1.userRegistration != null
                ? data1.userRegistration.middleName
                : null,
            firstName:
              data1.userRegistration != null
                ? data1.userRegistration.firstName
                : null,
            fatherName:
              data1.userRegistration != null
                ? data1.userRegistration.fatherName
                : null,
            motherName:
              data1.userRegistration != null
                ? data1.userRegistration.motherName
                : null,
            name:
              data1.userRegistration === null
                ? ""
                : data1.userRegistration.lastName +
                  " " +
                  data1.userRegistration.firstName +
                  " " +
                  (data1.userRegistration.fatherName == null ||
                  data1.userRegistration.fatherName == ""
                    ? " - "
                    : data1.userRegistration.fatherName) +
                  " " +
                  (data1.userRegistration.motherName == null ||
                  data1.userRegistration.motherName == ""
                    ? " - "
                    : data1.userRegistration.motherName),
            complaintDetail:
              data1.complaintDetail === null ? "" : data1.complaintDetail,
            complaintDate: data1.date === null ? "" : data1.date,
            attendedBy: data1.attendedBy === null ? "" : data1.attendedBy,
            attendedDate: data1.attendedDate === null ? "" : data1.attendedDate,
            problemLocation:
              data1.problemLocation === null ? "" : data1.problemLocation,
            // department: data1.department.name,
            emailId: data1.emailId === null ? "" : data1.emailId,
            contactNo: data1.contactNo === null ? "" : data1.contactNo,
            type: data1.type === null ? "" : data1.type,

            status: data1.status,
            statusName:
              data1.status === 1
                ? "In Process"
                : data1.status === 2
                ? "Completed"
                : data1.status === 3
                ? "Rejected"
                : "Pending",
          };
          row.push(bData);
          return data1;
        });
        dispatch(grievanceAdminSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getGrievanceAdminByFilter =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-grievance-by-from-to-date-type-for-admin?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&type=2",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              userRegistration:
                data1.userRegistration === null
                  ? null
                  : data1.userRegistration.id,
              lastName:
                data1.userRegistration != null
                  ? data1.userRegistration.lastName
                  : null,
              middleName:
                data1.userRegistration != null
                  ? data1.userRegistration.middleName
                  : null,
              firstName:
                data1.userRegistration != null
                  ? data1.userRegistration.firstName
                  : null,
              fatherName:
                data1.userRegistration != null
                  ? data1.userRegistration.fatherName
                  : null,
              motherName:
                data1.userRegistration != null
                  ? data1.userRegistration.motherName
                  : null,
              name:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.lastName +
                    " " +
                    data1.userRegistration.firstName +
                    " " +
                    (data1.userRegistration.fatherName == null ||
                    data1.userRegistration.fatherName == ""
                      ? " - "
                      : data1.userRegistration.fatherName) +
                    " " +
                    (data1.userRegistration.motherName == null ||
                    data1.userRegistration.motherName == ""
                      ? " - "
                      : data1.userRegistration.motherName),
              complaintDetail:
                data1.complaintDetail === null ? "" : data1.complaintDetail,
              complaintDate: data1.date === null ? "" : data1.date,
              attendedBy: data1.attendedBy === null ? "" : data1.attendedBy,
              attendedDate:
                data1.attendedDate === null ? "" : data1.attendedDate,
              problemLocation:
                data1.problemLocation === null ? "" : data1.problemLocation,
              // department: data1.department.name,
              emailId: data1.emailId === null ? "" : data1.emailId,
              contactNo: data1.contactNo === null ? "" : data1.contactNo,
              type: data1.type === null ? "" : data1.type,

              status: data1.status,
              statusName:
                data1.status === 1
                  ? "In Process"
                  : data1.status === 2
                  ? "Completed"
                  : data1.status === 3
                  ? "Rejected"
                  : "Pending",
            };
            row.push(bData);
            return data1;
          });
          dispatch(grievanceAdminSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// export const getGrievanceAdminByFilter =
//   ({ fromDate, toDate,type,department}) =>
//   async (dispatch) => {
//     try {
//       const response = apiGet({
//         url:
//           URL +
//           "?fromDate="+ fromDate+
//           "&toDate="+ toDate+
//           "&type="+ type+
//           "&department="+ department,
//       }).then(({ data, success }) => {
//         if (success) {
//           const content = data.data;
//           let index = 0;
//           let row = [];
//           content.map((data1) => {
//             index = index + 1;
//             let bData = {
//               index: index,
//               id: data1.id,
//               activityTitle: data1.activityTitle,
//               employeeActivity: data1.employeeActivity.id,
//               name: data1.employeeActivity.name,
//               duration: data1.duration,
//               details: data1.details,
//               level: data1.level,
//               status: data1.status,
//             };
//             row.push(bData);
//             return data1;
//           });
//           dispatch(grievanceAdminSuccess({ row }));
//         }
//         return success;
//       });
//       return response;
//     } catch (e) {
//       return console.error(e.message);
//     }
//   };

//
