import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { showNotification } from "../../Landing/Landing.slice";
import { getSyllabus } from "../teachingPlan.slice";

const TeacherActionView = ({
  rows,
  onCancelForm,
  getAlert,
  editData = {},
  resetUserAccess,
  stateData,
  getSyllabus,
  showNotification,
  onCancelView,
  tableData,
}) => {
  const [moduleList, setModuleList] = React.useState([]);
  React.useEffect(() => {
    if (moduleList.length === 0 && rows.length !== 0) {
      apiGet({
        url:
          endpoint.teachingPlan +
          "/" +
          tableData.id +
          "/get-subject-teaching-plan-detail",
      }).then(({ data, success }) => {
        const chapterList = rows.map((chapterData) => {
          const sectionList = chapterData.sectionList.map((section) => {
            const subSectionList = section.subSectionList.map((subSection) => {
              const filterSubSectionList = data.data.filter(
                (subSectionData) =>
                  subSectionData.syllabusSubSection.id === subSection.id
              );
              if (filterSubSectionList.length !== 0) {
                subSection = {
                  ...subSection,
                  checked: true,
                };
                return subSection;
              }
              return subSection;
            });
            section = {
              ...section,
              subSectionList: subSectionList,
            };
            return section;
          });
          chapterData = {
            ...chapterData,
            sectionList: sectionList,
          };
          return chapterData;
        });
        const chapterList1 = chapterList.map((chapterData) => {
          const sectionList = chapterData.sectionList.map((section) => {
            let subSectionList = section.subSectionList.filter(
              (item) => item.checked == true
            );
            if (section.subSectionList.length === subSectionList.length) {
              section = {
                ...section,
                checked: true,
              };
              return section;
            } else {
              section = {
                ...section,
              };
              return section;
            }
          });
          chapterData = {
            ...chapterData,
            sectionList: sectionList,
          };
          return chapterData;
        });
        const chapterList2 = chapterList1.map((chapterData) => {
          let sectionList = chapterData.sectionList.filter(
            (item) => item.checked == true
          );
          if (chapterData.sectionList.length === sectionList.length) {
            chapterData = {
              ...chapterData,
              checked: true,
            };
            return chapterData;
          } else {
            chapterData = {
              ...chapterData,
            };
            return chapterData;
          }
        });
        setModuleList(chapterList2);
      });
    }
  });

  React.useEffect(() => {
    getSyllabus({
      subjectDetailId: tableData.subjectDetailId,
    }).then((resp) => {
      if (!resp) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);

  return (
    <>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={2}
        columnSpacing={2}
        justifyContent="space-evenly"
      >
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          sx={{ marginTop: -3 }}
          justifyContent="flex-end"
        >
          <Grid sx={{ marginLeft: 1 }} item xs={12} sm={10.5} md={10.5} />
          <Grid item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label=""
            />
          </Grid>
          <Grid item xs={12} sm={1.5} md={1.5}>
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back to List"
              onClick={onCancelView}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Semester : "
          />
          {" " + tableData.semisterDetail}
        </Grid>
        <br />

        <Grid item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Division :"
          />
          {" " + tableData.division}
        </Grid>
        <br />
        <Grid item xs={12} sm={12} md={12}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Batch : "
          />
          {" " + tableData.batchName}
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Sr. No.</TableCell>
              <TableCell width="30%">Chapter Name</TableCell>
              <TableCell width="30%">Section </TableCell>
              <TableCell width="30%">Sub Section </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moduleList.map((row, index) => (
              <>
                <TableRow key={row.id}>
                  <TableCell align="center">{index + 1}</TableCell>

                  <TableCell component="th" scope="row" colSpan={3}>
                    {row.sectionList.length !== 0 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"Course" + index}
                              checked={row.checked}
                              disabled={true}
                              id={"Course" + index}
                            />
                          }
                          label={row.chapter}
                          labelPlacement="right"
                        />
                      </FormGroup>
                    ) : (
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={row.chapter}
                      />
                    )}
                  </TableCell>
                </TableRow>

                {row.sectionList.map((section, index) => {
                  return (
                    <>
                      <TableRow key={section.id}>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell component="th" scope="row" colSpan={2}>
                          {section.subSectionList.length !== 0 ? (
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={"Course" + index}
                                    // onChange={(e) =>
                                    // this.onProgramChange(
                                    // matrixDataRow.name,
                                    // columnName
                                    // )
                                    // }
                                    disabled={true}
                                    checked={section.checked}
                                    id={"Course" + index}
                                  />
                                }
                                label={section.sectionName}
                                labelPlacement="right"
                              />
                            </FormGroup>
                          ) : (
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={section.sectionName}
                            />
                          )}
                          {/* &nbsp;&nbsp; &nbsp;&nbsp; {section.sectionName} */}
                        </TableCell>
                      </TableRow>
                      {section.subSectionList.map((subSection, index) => {
                        return (
                          <>
                            <TableRow key={subSection.id}>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center" colSpan={2}></TableCell>
                              <TableCell component="th" scope="row">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={"Course" + index}
                                        // onChange={(e) =>
                                        // this.onProgramChange(
                                        // matrixDataRow.name,
                                        // columnName
                                        // )
                                        // }
                                        disabled={true}
                                        checked={subSection.checked}
                                        id={"Course" + index}
                                      />
                                    }
                                    label={subSection.subSectionName}
                                    labelPlacement="right"
                                  />
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                      <TableRow key={row.chapter}></TableRow>
                    </>
                  );
                })}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div align="right">
        <ButtonCompo
          size="button"
          type="button"
          variant="outlined"
          name="Back"
          onClick={onCancelView}
        />
        &nbsp;&nbsp;
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  student: state.studentList,
});

const mapDispatchToProps = {
  getAlert,
  showNotification,
  getSyllabus,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherActionView);
