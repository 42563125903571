import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "./EmployeeFeedBackDetailTable";

function StudentFeedbackForm({
  yearDetail,
  onCancel,
  editData,
  studentFeedbackDetailsList,
  backToAppliedForm,
}) {
  const [values, setValues] = React.useState("");
  const [teacherId, setTeacherId] = React.useState("");
  const [subjectId, setSubjectId] = React.useState("");
  const [teacherError, setTeacherError] = React.useState(false);
  const [subjectError, setSubjectError] = React.useState(false);

  const handleFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (subjectId === "") {
      setSubjectError(true);
    }

    if (teacherId === "") {
      setTeacherError(true);
    }

    return formIsValid;
  };

  const setQuaData = (data) => {
    const dataToPush = data.map((data1) => {
      let bData = {
        feedbackHeadDetails: {
          id: data1.id,
        },
        value: data1.answer,
      };
      return bData;
    });
    let pushData = {
      studentFeedback: {
        id: editData.id,
      },
      studentFeedbackTransactionDetails: dataToPush,
    };

    if (editData.isSubject === 1) {
      if (handleFormValidation()) {
        pushData = {
          ...pushData,
          subject: {
            id: "",
          },
          facultyRegistration: {
            id: "",
          },
        };
      }
    } else {
      pushData = {
        ...pushData,
        subject: {
          id: "",
        },
        facultyRegistration: {
          id: "",
        },
      };
    }
    setValues(pushData);
    console.log(pushData);
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Questions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  return (
    <>
      {/* {editData.isSubject === 1 && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Teacher "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={teacherId}
                    name={"teacherId"}
                    options={studentFeedbackDetailsList?.studentFeedbackTeacher}
                    onChange={ChangeHandlerSearch}
                    isError={teacherError ? true : false}
                    errorText={teacherError ? "Enter teacher name" : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Subject "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={subjectId}
                    name={"subjectId"}
                    options={[]}
                    onChange={ChangeHandlerSearch}
                    isError={subjectError ? true : false}
                    errorText={subjectError ? "Enter subject name" : " "}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )} */}
      <Grid container justifyContent="space-evenly" direction="row" spacing={2}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid sx={{ mb: 3, ml: -1, mt: -1 }} item xs={12} sm={10.5} md={10.5}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Feedbacks"
            />
          </Grid>
          <Grid sx={{ mb: 2, mt: -1 }} item xs={12} sm={1.5} md={1.5}>
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back to List"
              onClick={backToAppliedForm}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid item xs={12} sm={2} md={2}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Employee Name"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label={editData.employeeName}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Designation"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label={editData.designation}
          />
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid item xs={12} sm={2} md={2}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="Email-Id"
          />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label={editData.emailId}
          />
        </Grid>
      </Grid>
      <br />
      <DynamicTable
        data={editData.employeeFeedbackTransactionDetails}
        tableHead={columns}
        showPegination={false}
        rowData={setQuaData}
        isActionColActive={false}

        //   rowStatus={onChangeAction}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  studentFeedbackDetailsList: state.studentFeedbackDetails,
});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFeedbackForm);
