import { FormHelperText, Grid, Paper } from "@mui/material";
import { findAllByDisplayValue } from "@testing-library/react";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { FilterSave } from "../../../components/FilterSave";
import { resetReducList } from "../../../CommonActions/User.action";
import endpoints from "../../../config/endpoints";
import { UnitTestDeclareTableJson } from "../../../DynamicFormsJson/UnitTestDeclareTable";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../utils/api_service";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getSubjectInfoBySemisterDetailId,
  getSubjectInfoByYearDetailId,
  getSubjectSchemeBySubjectId,
} from "../../Masters/Admission/Subject Info/subjectInfo.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getUnitTest,
  getUnitTestDetailsBySemesterAndDivisionId,
  resetValues,
  getUnitTestDetailsById,
  resetValuesById,
} from "../UnitTestMaster/unitTestMaster.slice";
import { getStudentListForUnitTest } from "../../SubjectSelection/SubjectSelection.slice";
import UnitTestFillMarks from "./UnitTestFillMarks";
import UnitTestView from "./UnitTestView";
import endpoint from "../../../config/endpoints";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};
class UnitTestDeclare extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "unitTestHead",
      title: "Unit Test",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },

    {
      name: "semisterName",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "date",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "firstName",
      title: "Declare By",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      keyName: "firstName,lastName,middleName",
      isFormat: true,
      type: 1,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];

  StudentCol = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },

    {
      name: "name",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
  ];
  StudentViewCol = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },

    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      keyName: "firstName,lastName,middleName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    {
      name: "marks",
      title: "Marks Obtained",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
        // subjectMaster: this.props.subjectsList?.subjects,
        unitTestMaster: this.props.unitTestList?.unitTest,
        subjectMaster: this.props.subjectsList?.subjectBysemisterDetail,
        subjectSchemeMaster: this.props.subjectList?.subjectById.subjectDetails
          ? this.props.subjectList?.subjectById.subjectDetails
          : [],
        // subjectSchemeMaster: this.props.subjectsList?.subjectById,
      },
      dynamicMasterDataForFrom: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
        // subjectMaster: this.props.subjectsList?.subjects,
        unitTestMaster: this.props.unitTestList?.unitTest,
        subjectMaster: this.props.subjectsList?.subjectBysemisterDetail,
        subjectSchemeMaster: this.props.subjectList?.subjectById.subjectDetails
          ? this.props.subjectList?.subjectById.subjectDetails
          : [],
        // subjectSchemeMaster: this.props.subjectsList?.subjectById,
      },
      showLoader: false,
      open: false,
      openView: false,
      openFillMarks: false,
      timeTableHeaderData: {},
      fieldData: {},
      yearDetail: "",
      semesterDetail: "",
      division: "",
      subject: "",
      subjectScheme: "",
      maxMarks: "",
      date: "",
      unitTest: "",
      studListEmpty: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
          dynamicMasterDataForFrom: {
            ...this.state.dynamicMasterDataForFrom,
            yearMaster: this.state.open
              ? nextProps.internshipDeclarationList.yearDetails.filter(
                  (data) => data.isActive == 1
                )
              : [],
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
          dynamicMasterDataForFrom: {
            ...this.state.dynamicMasterDataForFrom,
            divisionMaster: this.state.open
              ? nextProps.internshipDeclarationList.distinctDivision.filter(
                  (data) => data.isActive == 1
                )
              : [],
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            acadamicMaster: nextProps.academicYearList.academicYear,
          },
          dynamicMasterDataForFrom: {
            ...this.state.dynamicMasterDataForFrom,
            acadamicMaster: this.state.open
              ? nextProps.academicYearList.academicYear.filter(
                  (data) => data.isActive == 1
                )
              : [],
          },
        });
      }
    }

    if (this.props.subjectsList !== nextProps.subjectsList) {
      if (
        this.props.subjectsList.subjectBysemisterDetail !==
        nextProps.subjectsList.subjectBysemisterDetail
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.subjectsList.subjectBysemisterDetail,
          },
          dynamicMasterDataForFrom: {
            ...this.state.dynamicMasterDataForFrom,
            subjectMaster: this.state.open
              ? nextProps.subjectsList.subjectBysemisterDetail.filter(
                  (data) => data.isActive == 1
                )
              : [],
          },
        });
      }
      if (
        this.props.subjectsList.subjectById !==
        nextProps.subjectsList.subjectById
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectSchemeMaster:
              nextProps.subjectsList.subjectById.subjectDetails,
          },
          dynamicMasterDataForFrom: {
            ...this.state.dynamicMasterDataForFrom,
            subjectSchemeMaster: this.state.open
              ? nextProps.subjectsList.subjectById.subjectDetails
              : [],
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
          dynamicMasterDataForFrom: {
            ...this.state.dynamicMasterDataForFrom,
            semisterMaster: this.state.open
              ? nextProps.rollNoData.semisterDetailsList.filter(
                  (data) => data.isActive == 1
                )
              : [],
          },
        });
      }
    }

    if (this.props.unitTestList !== nextProps.unitTestList) {
      if (
        this.props.unitTestList.unitTest !== nextProps.unitTestList.unitTest
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            unitTestMaster: nextProps.unitTestList.unitTest,
          },
          dynamicMasterDataForFrom: {
            ...this.state.dynamicMasterDataForFrom,
            unitTestMaster: this.state.open
              ? nextProps.unitTestList.unitTest.filter(
                  (data) => data.isActive == 1
                )
              : [],
          },
        });
      }
    }
  }

  componentDidMount() {
    const { getYearDetails, getUnitTest } = this.props;
    this.props.resetValues();

    const ay = localStorage.getItem("acadamicYearId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    getUnitTest().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (yearDetailId && semisterDetailId && divisionId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getUnitTestDetailsBySemesterAndDivisionId({
          semesterId: semisterDetailId,
          divisionId: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    UnitTestDeclareTableJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        if (dataToSearch.yearDetail) {
          this.setState({
            showLoader: true,
          });
          this.props
            .getSemesterDetailsByYearAndAcademic({
              yearId: dataToSearch.yearDetail,
              academicId: "",
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }

        if (dataToSearch.yearDetail) {
          this.setState({
            showLoader: true,
          });
          this.props
            .getDistinctDivision({ yearDetail: dataToSearch.yearDetail })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: findAllByDisplayValue,
                });
              }
            });
        }

        if (dataToSearch.semister) {
          this.setState({
            showLoader: true,
          });
          this.props
            .getSubjectInfoBySemisterDetailId({
              semisterId: dataToSearch.semister,
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }
      }
    });
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.subject) {
      this.props
        .getSubjectSchemeBySubjectId({
          subjectId: data.subject,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.semesterDetail) {
      this.props
        .getSubjectInfoBySemisterDetailId({
          semisterId: data.semesterDetail,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    } else if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (data.yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.resetValues();
  };

  toggleFormTableVisibility = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      openSnackbar: true,
      showLoader: false,
    });
  };

  onSearchData = (dataToSearch) => {
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", dataToSearch.division);
    localStorage.setItem("divisionIds", "");
    localStorage.setItem("acadamicYearId", ay);
    FilterSave();
    this.setState({
      showLoader: true,
    });

    this.props
      .getUnitTestDetailsBySemesterAndDivisionId({
        semesterId: dataToSearch.semister,
        divisionId: dataToSearch.division,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onSearchStudentList = (dataToSearch) => {
    this.setState({
      showLoader: true,
    });
    this.props
      .getStudentListForUnitTest({
        semisterDetailId: dataToSearch.semesterDetail,
        divisionId: dataToSearch.divisionId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    this.setState({
      yearDetail: dataToSearch.yearDetailId,
      semesterDetail: dataToSearch.semesterDetail,
      division: dataToSearch.divisionId,
      subject: dataToSearch.subject,
      unitTest: dataToSearch.unitTest,
      maxMarks: dataToSearch.maxMarks,
      date: dataToSearch.date,
      subjectScheme: dataToSearch.subjectScheme,
    });
  };

  onAddButtonClick = () => {
    this.props.resetReducList();
    const { getYearDetails, getUnitTest, getAlert } = this.props;
    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    getUnitTest().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    this.setState({
      open: true,
      fieldData: {},
      studListEmpty: false,
    });
  };

  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    const { dynamicMasterDataForFrom } = this.state;
    let fieldData = {};

    UnitTestDeclareTableJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterDataForFrom[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    if (fieldData.yearDetail) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: fieldData.yearDetail,
          academicId: "",
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    if (fieldData.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: fieldData.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    this.props
      .getSubjectInfoBySemisterDetailId({ semisterId: fieldData.semister })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });

    this.props.getUnitTest().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    console.log(fieldData);
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onDelete = (rowdata) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: UnitTestDeclareTableJson.apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: true,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        // this.props.getTableData();
        this.props
          .getUnitTestDetailsBySemesterAndDivisionId({
            semesterId: rowdata.semister,
            divisionId: rowdata.division,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  changeStatus = (row) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const tableHeader = {
          id: row.id,
          semisterDetail: {
            id: row.semisterDetail,
          },
          division: {
            id: row.division,
          },
          fromDate: row.fromDate,
          toDate: row.toDate,
          remark: row.remark,
          isActive: row.isActive === 1 ? 0 : 1,
        };
        console.log(tableHeader);
        this.setState({
          showLoader: true,
        });
        apiPost({
          url: endpoints.timeTableHeader,
          postBody: tableHeader,
        }).then(({ data, success }) => {
          if (success) {
            this.setState({
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  goToForm = () => {
    this.setState({
      open: false,
      openView: false,
      openFillMarks: false,
      studListEmpty: false,
    });
  };

  onClickView = (rowData) => {
    console.log(rowData);

    this.setState({
      open: false,
      openView: true,
      openFillMarks: false,
      studListEmpty: false,
    });
    this.setState({
      timeTableHeaderData: rowData,
    });
  };

  onClickFillMarks = (rowData) => {
    console.log(rowData);
    this.props.resetValuesById();
    this.setState({
      open: false,
      openView: false,
      openFillMarks: true,
      studListEmpty: false,
    });
    this.setState({
      timeTableHeaderData: rowData,
    });
  };

  FillMarks = (data, data1) => {
    console.log(data);
    console.log(data1);
    const divisionId = localStorage.getItem("divisionId");
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    let studentUnitTestDetails = [];
    const dataToPush = data.map((rowData) => {
      let studData = {
        id: rowData.id,
        userRegistration: {
          id: rowData.userRegistration.id,
        },
        reportingDetail: {
          id: rowData.reportingDetail.id,
        },
        marks: rowData.marks,
        fillDateTime: "",
      };
      studentUnitTestDetails.push(studData);
    });
    console.log(studentUnitTestDetails);
    let docs = {
      date: data1.date,
      id: data1.id,
      academicYearId: data1.academicYearId,
      declareBy: {
        id: data1.declareBy.id,
      },
      division: {
        id: data1.division.id,
      },
      isActive: data1.isActive,
      maxMarks: data1.maxMarks,
      semisterDetail: {
        id: data1.semisterDetail.id,
      },
      subjectDetail: {
        id: data1.subjectDetail.id,
      },
      unitTestHead: {
        id: data1.unitTestHead.id,
      },
      declareType: data1.declareType,
      declareStatus: data1.declareStatus,
      departmentId: "",
      studentUnitTestDetails: studentUnitTestDetails,
    };
    console.log(docs);
    // let pushData = {
    //   employeeFeedback: {
    //     id: editData.id,
    //   },
    //   employeeFeedbackTransactionDetails: dataToPush,
    // };
    apiPost({
      url: endpoint.unitTestDeclare + "/fill-marks-for-admin",
      postBody: docs,
    }).then(({ success }) => {
      if (success) {
        showNotification({ msg: "Data saved successfully" });
        this.handleClickCloseFillMarks();
        this.props
          .getUnitTestDetailsBySemesterAndDivisionId({
            semesterId: semisterDetailId,
            divisionId: divisionId,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  handleClickOpen = () => {
    this.setState({
      open: false,
      openView: false,
      openFillMarks: false,
      studListEmpty: false,
    });
  };

  handleClickCloseFillMarks = () => {
    this.setState({
      open: false,
      openView: false,
      openFillMarks: false,
      studListEmpty: false,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      yearDetail,
      division,
      semesterDetail,
      unitTest,
      date,
      subject,
      maxMarks,
      subjectScheme,
    } = this.state;
    console.log("yearDetail : " + yearDetail);
    console.log("semesterDetail : " + semesterDetail);
    console.log("division : " + division);
    console.log("subject : " + subject);
    console.log("unitTest : " + unitTest);
    console.log("maxMarks : " + maxMarks);
    console.log("date : " + date);

    console.log(this.props.student?.studentList);
    let studentUnitTestDetails = [];
    this.props.student?.studentList.map((rowData) => {
      let studData = {
        userRegistration: {
          id: rowData.id,
        },
        reportingDetail: {
          id: rowData.reportingDetail,
        },
        marks: "",
        fillDateTime: "",
      };
      studentUnitTestDetails.push(studData);
    });
    console.log(studentUnitTestDetails);
    let dArr = date.split("-"); // ex input "2010-01-18"
    let dateChanged = dArr[2] + "-" + dArr[1] + "-" + dArr[0];
    if (studentUnitTestDetails.length !== 0) {
      let docs = {
        date: dateChanged,
        division: {
          id: division,
        },
        isActive: 1,
        maxMarks: maxMarks,
        semisterDetail: {
          id: semesterDetail,
        },
        subjectDetail: {
          id: subjectScheme,
        },
        unitTestHead: {
          id: unitTest,
        },
        declareType: 0,
        declareStatus: 0,
        departmentId: "",
        studentUnitTestDetails: studentUnitTestDetails,
      };
      console.log(docs);
      swal({
        title: "Are you sure?",
        text: "Would you like to continue with your action?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((success) => {
        if (success) {
          this.onSave(docs);
        }
      });
    } else {
      this.setState({
        studListEmpty: true,
      });
    }
  };


  onSave = (dataToSave, isToggle = true) => {
    console.log(dataToSave);  
    let divisionString = dataToSave.division.id.map(id => id.trim()).join(',');

    console.log(divisionString)


 // Create a new object without the division property
 let postData = { ...dataToSave };
 delete postData.division;
console.log("postData"+ JSON.stringify(postData))
    this.setState({
      showLoader: true,
    });
    apiPost({
      url: UnitTestDeclareTableJson.apiBaseURL+"/save"+"?divisionIds="+divisionString,
      postBody: postData,
    }).then(({ success }) => {
      if (success) {
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };
  render() {
    const { unitTestList } = this.props;
    const {
      dynamicMasterData,
      dynamicMasterDataForFrom,
      open,
      openView,
      showLoader,
      openFillMarks,
      studListEmpty,
    } = this.state;
    return (
      <>
        {!open && !openView && !openFillMarks && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={UnitTestDeclareTableJson.screenTitle}
            fieldMeta={UnitTestDeclareTableJson.fieldMetaForTable}
            buttonCenter={UnitTestDeclareTableJson.buttonCenter}
            showPdfDownload={UnitTestDeclareTableJson.showPdfDownload}
            showExcelDownload={UnitTestDeclareTableJson.showExcelDownload}
            pdfFileName={UnitTestDeclareTableJson.pdfFileName}
            excelFileName={UnitTestDeclareTableJson.excelFileName}
            tableColumnsToFilter={UnitTestDeclareTableJson.tableColumnsToFilter}
            showAddButton={UnitTestDeclareTableJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={this.columns}
            tableData={unitTestList?.unitTestList}
            showLoader={showLoader}
            getListById={this.getListById}
            showPegination={false}
            showView={true}
            showFillMarks={true}
            showHeadDelete={true}
            rowViewData={this.onClickView}
            rowDelete={this.rowDelete}
            rowFillMarks={this.onClickFillMarks}
            rowStatus={this.changeStatus}
            onDelete={this.onDelete}
            onSearchData={this.onSearchData}
            baseIdColumn={UnitTestDeclareTableJson.baseIdColumn}
            apiBaseURL={UnitTestDeclareTableJson.apiBaseURL}
          />
        )}
        {open && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={UnitTestDeclareTableJson.screenTitle}
              fieldMeta={UnitTestDeclareTableJson.fieldMeta}
              buttonCenter={true}
              showPdfDownload={UnitTestDeclareTableJson.showPdfDownload}
              showExcelDownload={UnitTestDeclareTableJson.showExcelDownload}
              pdfFileName={UnitTestDeclareTableJson.pdfFileName}
              excelFileName={UnitTestDeclareTableJson.excelFileName}
              tableColumnsToFilter={
                UnitTestDeclareTableJson.tableColumnsToFilter
              }
              showAddButton={false}
              isActionColActive={false}
              showSearch={false}
              dynamicMasterData={dynamicMasterDataForFrom}
              tableColumns={this.StudentCol}
              tableData={this.props.student?.studentList}
              showLoader={showLoader}
              getListById={this.getListById}
              showPegination={false}
              showView={false}
              showHeadEdit={true}
              showHeadDelete={true}
              rowViewData={this.onClickView}
              rowDelete={this.rowDelete}
              rowEdit={this.rowEdit}
              rowStatus={this.changeStatus}
              onDelete={this.onDelete}
              onSearchData={this.onSearchStudentList}
              baseIdColumn={UnitTestDeclareTableJson.baseIdColumn}
              apiBaseURL={UnitTestDeclareTableJson.apiBaseURL}
              studListEmpty={studListEmpty}
              msg={"*Student List Empty"}
            />
            {/* {studListEmpty === true && (
                <Grid sm={12} md={12} xs={12} sx={{ ml: 4, mt: -3 }}>
                  <FormHelperText error>{"*Student List Empty"}</FormHelperText>
                </Grid>
              )} */}
            <Paper sx={{ p: 2, borderRadius: 2, mt: -2 }} elevation={0}>
              <Grid sm={12} md={12} xs={12} container justifyContent="right">
                <Grid sm={1} md={1} xs={12} container justifyContent="right">
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Submit"
                    onClick={this.submitHandler}
                  />
                </Grid>
                <Grid sm={1} md={1} xs={12} container justifyContent="right">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={this.goToForm}
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {openView && (
          <UnitTestView
            columns={this.StudentViewCol}
            onCancle={this.handleClickOpen}
            timeTableHeaderData={this.state.timeTableHeaderData}
          />
        )}
        {openFillMarks && (
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <UnitTestFillMarks
              fillMarksData={this.FillMarks}
              columns={this.StudentViewCol}
              onCancle={this.handleClickCloseFillMarks}
              timeTableHeaderData={this.state.timeTableHeaderData}
            />
          </Paper>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  subjectsList: state.subjectInfo,
  unitTestList: state.unitTestStore,
  student: state.subjectSelection,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getAcademicYear,
  getSubjectInfoByYearDetailId,
  getUnitTest,
  getUnitTestDetailsBySemesterAndDivisionId,
  showNotification,
  getSubjectInfoBySemisterDetailId,
  getSubjectSchemeBySubjectId,
  getStudentListForUnitTest,
  resetValues,
  getUnitTestDetailsById,
  resetReducList,
  resetValuesById,
};
export default connect(mapStateToProps, mapDispatchToProps)(UnitTestDeclare);
