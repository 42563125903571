import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AttachFile from "../../../components/Comman/AttachFile";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import TimePicker from "../../../components/Comman/TimePicker";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import { getYearDetails } from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { showNotification } from "../../Landing/Landing.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
  viewMultipleFile,
} from "../../UploadFile/file.slice";
import {
  getEventByFDTDFilter,
  getStudentEvent,
} from "../Event Management/event.slice";

let docs;
class StudentAddEvent extends Component {
  state = {
    id: 0,
    name: "",
    details: "",
    yearDetail: [],
    fromDate: "",
    toDate: "",
    venue: "",
    time: null,
    applicationLastDate: "",
    amountApplicable: 0,
    amount: 0,
    formErrors: [],
    documentName: "",
    fileName: "",
    uploadError: false,
    upload: false,
    fileNameFlag: false,
    file: "",
  };

  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  timeChangeHandler = (dataKey) => (time) => {
    this.setState({
      [dataKey]: time,
    });
  };

  handleFormValidation() {
    const {
      name,
      details,
      fromDate,
      toDate,
      yearDetail,
      venue,
      time,
      applicationLastDate,
      amountApplicable,
      amount,
      documentName,
      fileName,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (name.toString().trim() === "" || name === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.nameMsgRequired;
    }

    if (details.toString().trim() === "" || details === null) {
      formIsValid = false;
      formErrors["detailsError"] = myConstClass.detailsMsg;
    }

    if (fromDate === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    if (venue.toString().trim() === "" || venue === null) {
      formIsValid = false;
      formErrors["venueError"] = myConstClass.venueMsg;
    }

    // if ( time === "" || time === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "timeError" ] = myConstClass.timeMsg;
    // }

    if (amountApplicable == 1 && amount === "") {
      formIsValid = false;
      formErrors["amountError"] = myConstClass.amountMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      name,
      details,
      fromDate,
      toDate,
      yearDetail,
      venue,
      time,
      applicationLastDate,
      amountApplicable,
      amount,
      fileName,
      documentName,
    } = this.state;
    const { editData } = this.props;
    let frmDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    let applidate = moment(applicationLastDate).format("DD-MM-YYYY");

    //  old
    // let changeTime = time === null ? "" : moment( time ).format( "hh:mm" );

    // let currentTime = time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();
    // let currentTime = time.toTimeString().slice( 0, 8 );

    let changeTime =
      time === null ? "" : moment(time, "h:mm:ss A").format("HH:mm");
    if (Object.keys(editData).length === 0) {
      docs = {
        eventName: name,
        eventDetails: details,
        fromDate: frmDt,
        toDate: toDt,
        eventTime: changeTime,
        venue: venue,
        amountPaid: amount,
        isCompleted: 0,
      };
    } else {
      docs = {
        eventName: name,
        eventDetails: details,
        fromDate: frmDt,
        toDate: toDt,
        eventTime: changeTime,
        venue: venue,
        amountPaid: amount,
        isCompleted: 0,
        reportingDetail: {
          id: editData.reportingDetail,
        },
        userRegistration: {
          id: editData.userRegistration,
        },
        classCourse: editData.classCourse,
        instituteName: editData.instituteName,
        id: editData.id,
        name: editData.name,
      };
    }

    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }

    if (this.handleFormValidation()) {
      this.onSave(docs);
      this.props.onCancel();
    }
  };
  componentDidMount() {
    // for Edit Dataa

    const { editData, viewMultipleFile } = this.props;
    if (Object.keys(editData).length !== 0) {
      const durationForArr =
        editData.fromDate !== "" ? editData.fromDate.split("-") : "";
      const durationFor =
        durationForArr !== ""
          ? new Date(
              durationForArr[2],
              durationForArr[1] - 1,
              durationForArr[0]
            )
          : "";

      const toDateArr =
        editData.toDate !== "" ? editData.toDate.split("-") : "";
      const changedToDate =
        toDateArr !== ""
          ? new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0])
          : "";

      // const fromTimeArr =
      //   editData.time !== "" ? editData.time.split( ":", -1 ) : null;

      // var changedTime =
      //   fromTimeArr !== null
      //     ? new Date( null, null, null, fromTimeArr[ 0 ], fromTimeArr[ 1 ] )
      //     : null;

      const fromTimeDate =
        editData.time !== ""
          ? moment(editData.time, ["h:mm A"]).format("HH:mm")
          : "";
      const fromTimeArr =
        fromTimeDate !== " " ? fromTimeDate.split(":", -1) : null;
      const changedTime =
        fromTimeArr !== null
          ? new Date(null, null, null, fromTimeArr[0], fromTimeArr[1])
          : null;

      if (editData.amount === 0) {
        this.setState({
          ...this.state,
          id: editData.id,
          name: editData.eventName,
          fromDate: durationFor,
          toDate: changedToDate,
          venue: editData.venue,
          time: changedTime,
          amountApplicable: 0,
          amount: editData.amount,
          details: editData.eventDetails,
          file: editData.documentUpload != null ? editData.documentUpload : "",
        });
      } else {
        this.setState({
          ...this.state,
          id: editData.id,
          name: editData.eventName,
          fromDate: durationFor,
          toDate: changedToDate,
          venue: editData.venue,
          time: changedTime,
          amountApplicable: 1,
          amount: editData.amount,
          details: editData.eventDetails,
          file: editData.documentUpload != null ? editData.documentUpload : "",
        });
      }
    }
  }

  onSave = (dataToSave) => {
    if (this.state.fileName !== "") {
      const formData = new FormData();
      formData.append("file", this.state.fileName);
      apiPost({
        url: endpoint.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          dataToSave = {
            ...dataToSave,
            documentUpload: data,
          };

          if (dataToSave.id === undefined) {
            apiPost({
              url: endpoint.eventParticipant,
              postBody: dataToSave,
            }).then(({ success }) => {
              if (success) {
                this.props.showNotification({
                  msg: "Data saved successfully",
                });

                this.props.getEventByFDTDFilter().then((response) => {
                  if (!response) {
                    this.setState({
                      isLoading: false,
                    });
                    this.props.getAlert({ message: "Something went wrong" });
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                  }
                });
              } else {
                this.props.getAlert({
                  message: "Failed to save",
                });
              }
            });
          } else {
            apiPost({
              url: endpoint.eventParticipant + "/edit",
              postBody: dataToSave,
            }).then(({ success }) => {
              if (success) {
                this.props.showNotification({
                  msg: "Data saved successfully",
                });

                this.props.getEventByFDTDFilter().then((response) => {
                  if (!response) {
                    this.setState({
                      isLoading: false,
                    });
                    this.props.getAlert({ message: "Something went wrong" });
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                  }
                });
              } else {
                this.props.getAlert({
                  message: "Failed to save",
                });
              }
            });
          }
        } else {
          this.props.getAlert({
            message: "Failed to upload document",
          });
        }
      });
    } else {
      if (dataToSave.id === undefined) {
        apiPost({
          url: endpoint.eventParticipant,
          postBody: dataToSave,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data saved successfully",
            });
            this.props.getStudentEvent().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      } else {
        apiPost({
          url: endpoint.eventParticipant + "/edit",
          postBody: dataToSave,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data saved successfully",
            });
            this.props.getEventByFDTDFilter().then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    }
  };

  onClickViewPreview = (fileName) => {
    this.props.openFileInNewTab({ file: fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  render() {
    const {
      name,
      fileName,
      upload,
      documentName,
      details,
      uploadError,
      fromDate,
      toDate,
      yearDetail,
      venue,
      time,
      applicationLastDate,
      amountApplicable,
      amount,
      fileNameFlag,
    } = this.state;
    const { onCancel, internshipDeclarationList } = this.props;
    const {
      nameError,
      detailsError,
      yearDetailError,
      fromDateError,
      toDateError,
      venueError,
      timeError,
      applicationLastDateError,
      amountError,
      fileNameError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Event Form"
              />
            </Grid>
            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={onCancel}
              />
            </Grid>
          </Grid>
          <br />
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Name "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="name"
                      size="small"
                      value={name}
                      onChange={this.changeHandler}
                      fullWidth
                      // {...( nameError
                      //     ? { error: true, helperText: nameError }
                      //     : "" )}

                      error={nameError ? true : false}
                      helperText={nameError ? nameError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="details"
                      size="small"
                      value={details}
                      onChange={this.changeHandler}
                      fullWidth
                      //   {...(detailsError
                      //     ? { error: true, helperText: detailsError }
                      //     : "")}

                      error={detailsError ? true : false}
                      helperText={detailsError ? detailsError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date "
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange("fromDate")}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date "
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        // minDate={new Date()}
                        onChange={this.onDateChange("toDate")}
                        isError={toDateError ? true : false}
                        errorText={toDateError ? toDateError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Venue "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="venue"
                        size="small"
                        value={venue}
                        onChange={this.changeHandler}
                        fullWidth
                        error={venueError ? true : false}
                        helperText={venueError ? venueError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Time "
                      />
                    </Grid>

                    <Grid sx={{ m: -4 }} item xs={12} sm={12} md={12}>
                      <TimePicker
                        value={time}
                        onChange={this.timeChangeHandler("time")}
                        // helperText={timeError ? timeError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* document facility added */}

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2}
                  lg={2}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Document Name "
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    {this.state.file !== "" && (
                      <MyComponentWithIconProps
                        statusImage={VisibilityIcon}
                        color="primary"
                        fontSize="medium"
                        title="View"
                        onClick={(e) =>
                          this.onClickViewPreview(this.state.file)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={9}
                  lg={9}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <AttachFile name="documentName" fileName={this.changeH} />
                    &nbsp; {fileName !== "" ? fileName.name : ""}
                  </Grid>
                </Grid>
              </Grid>

              {/*  */}

              <Grid
                sx={{ mr: 0.5 }}
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                m={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fileData: state.file,
  academicYear: state.academicYear,
  internshipDeclarationList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAcademicYear,
  saveMultipleFile,
  fileMulitipleSave,
  saveFile,
  deleteFile,
  deleteFileData,
  getYearDetails,
  getStudentEvent,
  viewMultipleFile,
  openFileInNewTab,
  getEventByFDTDFilter,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentAddEvent);
