import { FormHelperText, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { getAlert } from "../../CommonActions/alert.slice";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import endpoints from "../../config/endpoints";
import { apiGet, apiGetWithoutHeader } from "../../utils/api_service";
import {
  onlyNumber,
  textOnly,
  validEmail,
} from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import MultipleSelect from "../../components/Comman/MultipleSelect";
let docs;
class PersonalDetailsForm extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    candidatename: "",
    firstname: "",
    middleName: "",
    lastname: "",
    fatherHusband: "",
    mothername: "",
    gender: "",
    dob: "",
    nationality: "",
    relegion: "",
    category: "",
    caste: "",
    subcaste: "",
    bloodgroup: "",
    fathersoccupation: "",
    validitycdno: "",
    validitycddate: "",
    castecertno: "",
    castecertdate: "",
    domicileof: "",
    mothertounge: "",
    studmobile: "",
    studemail: "",
    birthPlace: "",
    martialStatus: "",
    totalExperiances: "",
    alternateNo: "",
    role: [],
    employeeCode: "",
    libraryNo: "",
    isDisable: false,
    adhaarNo: "",
    panNo: "",
    dateOfRetirement: "",
    dateOfAppointment: "",
    dateOfJoining: "",
    libraryUniqueFlag: false,
    employeeRole: "",
    //DSE field is used for nature of appointment
    dse: "",
    roleDataList: [
      { id: "all", name: "All" },
      { id: "Director", name: "Director" },
      { id: "Principal", name: "Principal" },
      { id: "Hod", name: "Hod" },
      { id: "Class Co-ordinator", name: "Class Co-ordinator" },
      { id: "Teacher", name: "Teacher" },
      { id: "Clerk", name: "Clerk" },
      { id: "Accountant", name: "Accountant" },
    ],
  };

  libraryNumberHandler = (data1) => {
    if (this.props.employeeDetails.employee.libraryNo !== data1) {
      let formErrors = {};
      apiGet({
        url: endpoints.studentList + "/check-library-no?libraryNo=" + data1,
      }).then(({ data }) => {
        if (data !== null) {
          if (!data.error) {
            if (!data.data.error) {
              formErrors["libraryNoErr"] = myConstClass.libraryUniqueMsg;
              this.setState({
                formErrors: formErrors,
                libraryUniqueFlag: true,
              });
            } else {
              this.setState({ formErrors: [], libraryUniqueFlag: false });

              this.props.showNotification({
                msg: "Library number is unique",
              });
            }
          }
        }
      });
    } else {
      this.setState({ formErrors: [], libraryUniqueFlag: false });
    }
  };

  checkEmployeeCodeHandler = (event) => {
    const { name, value } = event.target;
    if (this.props.employeeDetails.employee.employeeCode !== value) {
      const { getAlert } = this.props;
      if (value != "") {
        apiGet({
          url: endpoints.saveEmployee + "/" + value + "/check-employee-code",
        }).then(({ data, success }) => {
          if (success) {
            if (success) {
              if (data.data.error !== true) {
                let formErrors = {};
                formErrors["empCodeUniqueErr"] = myConstClass.employeeCodeMsg;
                this.setState({ formErrors: formErrors, employeeCode: "" });
              } else {
                this.setState({ formErrors: [] });
              }
            } else {
              getAlert({
                message: myConstClass.serverMsg,
              });
            }
          }
        });
      }
    } else {
      this.setState({ formErrors: [] });
    }
  };

  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");
    if (filterValue.length !== 0) {
      let rolelist = [];
      this.state.roleDataList.map((data) => {
        if (data.id != "all") {
          rolelist.push(data.id);
        }
      });
      this.setState({
        [name]: rolelist,
      });
    } else {
      this.setState({
        ...this.state,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    }
  };

  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandle = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || textOnly.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  checkEmployeeEmail = (event) => {
    const { value } = event.target;

    const { getAlert, showNotification } = this.props;
    if (this.props.employeeDetails.employee.employeeCode !== value) {
      if (value != "") {
        // api/config/v1/user/check-unique-email-id?emailId=vijayrajvchaugule09@gmail
        apiGet({
          url: "/api/config/v1/user/check-unique-email-id?emailId=" + value,
        }).then(({ data, success }) => {
          if (success) {
            if (data.data.error === true) {
              let formErrors = {};
              formErrors["studemailErr"] = myConstClass.emailInvalMsg;

              this.setState({ formErrors: formErrors, studemail: "" });
            } else {
              this.props.showNotification({
                msg: "Email id is unique",
              });
              this.setState({ formErrors: [] });
            }
          } else {
            getAlert({
              message: myConstClass.serverMsg,
            });
          }
        });
      }
    } else {
      this.setState({ formErrors: [] });
    }
  };
  componentDidMount() {
    const { employeeDetails, editForm } = this.props;
    // alert(editForm);
    if (Object.keys(employeeDetails.employee).length != 0) {
      const DOBArr =
        employeeDetails.employee.dateOfBirth == null
          ? ""
          : employeeDetails.employee.dateOfBirth.split("-");
      const DOB =
        DOBArr == "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

      const CasteDobArr =
        employeeDetails.employee.castCertificateDate == null
          ? ""
          : employeeDetails.employee.castCertificateDate.split("-");
      const CasteDob =
        CasteDobArr == ""
          ? ""
          : new Date(CasteDobArr[2], CasteDobArr[1] - 1, CasteDobArr[0]);

      const ValidityDobArr =
        employeeDetails.employee.validityCdDate == null
          ? ""
          : employeeDetails.employee.validityCdDate.split("-");
      const ValidityDob =
        ValidityDobArr == ""
          ? ""
          : new Date(
              ValidityDobArr[2],
              ValidityDobArr[1] - 1,
              ValidityDobArr[0]
            );

      // new

      const dateOfJoining =
        employeeDetails.employee.joiningDate == null
          ? ""
          : employeeDetails.employee.joiningDate.split("-");
      const dateOfjoin =
        dateOfJoining == ""
          ? ""
          : new Date(dateOfJoining[2], dateOfJoining[1] - 1, dateOfJoining[0]);

      const dateOfReportingArr =
        employeeDetails.employee.reportingDate == null
          ? ""
          : employeeDetails.employee.reportingDate.split("-");
      const dateOfReport =
        dateOfReportingArr == ""
          ? ""
          : new Date(
              dateOfReportingArr[2],
              dateOfReportingArr[1] - 1,
              dateOfReportingArr[0]
            );

      const dateOfRetirementArr =
        employeeDetails.employee.leavingDate == null
          ? ""
          : employeeDetails.employee.leavingDate.split("-");
      const dateOfRetire =
        dateOfRetirementArr == ""
          ? ""
          : new Date(
              dateOfRetirementArr[2],
              dateOfRetirementArr[1] - 1,
              dateOfRetirementArr[0]
            );

      this.setState({
        id: employeeDetails.employee.id,
        candidatename: employeeDetails.employee.firstName,
        middleName: employeeDetails.employee.middleName
          ? employeeDetails.employee.middleName
          : "",
        lastname: employeeDetails.employee.lastName
          ? employeeDetails.employee.lastName
          : "",
        gender: employeeDetails.employee.gender,
        dob: DOB,
        studemail: employeeDetails.employee.emailId,
        studmobile: employeeDetails.employee.contactNo,
        birthPlace: employeeDetails.employee.birthplace,
        nationality: employeeDetails.employee.nationality,
        relegion: employeeDetails.employee.religionId,
        caste: employeeDetails.employee.cast,
        category: employeeDetails.employee.categoryId,
        subcaste: employeeDetails.employee.subCast,
        bloodgroup: employeeDetails.employee.bloodGroup,
        validitycdno: employeeDetails.employee.validityCdNo,
        validitycddate: ValidityDob,
        castecertno: employeeDetails.employee.castCertificateNo,
        castecertdate: CasteDob,
        domicileof: employeeDetails.employee.domicileof,
        mothertounge: employeeDetails.employee.motherTongue,
        martialStatus: employeeDetails.employee.martialStatus,
        totalExperiances: employeeDetails.employee.totalExperiences,
        role: employeeDetails.employee.role.split(","),
        alternateNo: employeeDetails.employee.alternateNo,
        employeeCode: employeeDetails.employee.employeeCode,
        libraryNo: employeeDetails.employee.libraryNo,
        dateOfJoining: dateOfjoin,
        dateOfAppointment: dateOfReport,
        dateOfRetirement: dateOfRetire,
        adhaarNo: employeeDetails.employee.aadhaarNo,
        panNo: employeeDetails.employee.panNo,
        employeeRole: +employeeDetails.employee.accessRole,
        dse: employeeDetails.employee.dse,
        isDisable: true,
      });
    } else {
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employee !==
        nextProps.employeeDetails.employee
      ) {
        const DOBArr =
          nextProps.employeeDetails.employee.dateOfBirth == null
            ? ""
            : nextProps.employeeDetails.employee.dateOfBirth.split("-");
        const DOB =
          DOBArr == "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

        const CasteDobArr =
          nextProps.employeeDetails.employee.castCertificateDate == null
            ? ""
            : nextProps.employeeDetails.employee.castCertificateDate.split("-");
        const CasteDob =
          CasteDobArr == ""
            ? ""
            : new Date(CasteDobArr[2], CasteDobArr[1] - 1, CasteDobArr[0]);

        const ValidityDobArr =
          nextProps.employeeDetails.employee.validityCdDate == null
            ? ""
            : nextProps.employeeDetails.employee.validityCdDate.split("-");
        const ValidityDob =
          ValidityDobArr == ""
            ? ""
            : new Date(
                ValidityDobArr[2],
                ValidityDobArr[1] - 1,
                ValidityDobArr[0]
              );

        const dateOfJoining =
          nextProps.employeeDetails.employee.joiningDate == null
            ? ""
            : nextProps.employeeDetails.employee.joiningDate.split("-");
        const dateOfjoin =
          dateOfJoining == ""
            ? ""
            : new Date(
                dateOfJoining[2],
                dateOfJoining[1] - 1,
                dateOfJoining[0]
              );

        const dateOfReportingArr =
          nextProps.employeeDetails.employee.reportingDate == null
            ? ""
            : nextProps.employeeDetails.employee.reportingDate.split("-");
        const dateOfReport =
          dateOfReportingArr == ""
            ? ""
            : new Date(
                dateOfReportingArr[2],
                dateOfReportingArr[1] - 1,
                dateOfReportingArr[0]
              );

        const dateOfRetirementArr =
          nextProps.employeeDetails.employee.validityCdDate == null
            ? ""
            : nextProps.employeeDetails.employee.validityCdDate.split("-");
        const dateOfRetire =
          dateOfRetirementArr == ""
            ? ""
            : new Date(
                dateOfRetirementArr[2],
                dateOfRetirementArr[1] - 1,
                dateOfRetirementArr[0]
              );

        this.setState({
          id: nextProps.employeeDetails.employee.id,
          candidatename: nextProps.employeeDetails.employee.firstName,
          middleName: nextProps.employeeDetails.employee.middleName
            ? nextProps.employeeDetails.employee.middleName
            : "",
          lastname: nextProps.employeeDetails.employee.lastName
            ? nextProps.employeeDetails.employee.lastName
            : "",
          gender: nextProps.employeeDetails.employee.gender,
          dob: DOB,
          studemail: nextProps.employeeDetails.employee.emailId,
          studmobile: nextProps.employeeDetails.employee.contactNo,
          birthPlace: nextProps.employeeDetails.employee.birthplace,
          nationality: nextProps.employeeDetails.employee.nationality,
          relegion: nextProps.employeeDetails.employee.religionId,
          caste: nextProps.employeeDetails.employee.cast,
          category: nextProps.employeeDetails.employee.categoryId,
          subcaste: nextProps.employeeDetails.employee.subCast,
          bloodgroup: nextProps.employeeDetails.employee.bloodGroup,
          validitycdno: nextProps.employeeDetails.employee.validityCdNo,
          validitycddate: ValidityDob,
          castecertno: nextProps.employeeDetails.employee.castCertificateNo,
          castecertdate: CasteDob,
          domicileof: nextProps.employeeDetails.employee.domicileof,
          mothertounge: nextProps.employeeDetails.employee.motherTongue,
          martialStatus: nextProps.employeeDetails.employee.martialStatus,
          totalExperiances: nextProps.employeeDetails.employee.totalExperiences,
          role: nextProps.employeeDetails.employee.role.split(","),
          alternateNo: nextProps.employeeDetails.employee.alternateNo,
          employeeCode: nextProps.employeeDetails.employee.employeeCode,
          libraryNo: nextProps.employeeDetails.employee.libraryNo,
          dateOfJoining: dateOfjoin,
          dateOfRetirement: dateOfRetire,
          dateOfAppointment: dateOfReport,
          adhaarNo: nextProps.employeeDetails.employee.aadhaarNo,
          panNo: nextProps.employeeDetails.employee.panNo,
          employeeRole: +nextProps.employeeDetails.employee.accessRole,
          dse: nextProps.employeeDetails.employee.dse,
          isDisable: true,
        });
      }
    }
  }

  onDateChange = (dataKey) => (date) => {
    if (dataKey == "dob") {
      let fromYear = new Date(date).getFullYear();
      let month = new Date(date).getMonth();
      let day = new Date(date).getDate();
      let retirementDate = new Date(fromYear + 60, month, day);
      this.setState({
        dateOfRetirement: retirementDate,
      });
    }

    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  handleFormValidation(name) {
    const {
      candidatename,
      middleName,
      lastname,
      gender,
      dob,
      nationality,
      studmobile,
      studemail,
      martialStatus,
      totalExperiances,
      role,
      employeeCode,
      libraryNo,
      mothertounge,
      adhaarNo,
      panNo,
      dateOfAppointment,
      dateOfJoining,
      employeeRole,
      dse,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobMsg;
    }

    if (martialStatus === "" || martialStatus === null) {
      formIsValid = false;
      formErrors["martialStatusErr"] = myConstClass.martialMsg;
    }

    if (gender === "" || gender === null) {
      formIsValid = false;
      formErrors["genderErr"] = myConstClass.genderMsg;
    }

    if (totalExperiances === "" || totalExperiances === null) {
      formIsValid = false;
      formErrors["totalExperiancesErr"] = myConstClass.totalExperiancesMsg;
    }

    if (nationality === "" || nationality === null) {
      formIsValid = false;
      formErrors["nationalityErr"] = myConstClass.nationalityMsg;
    }
    if (dateOfJoining === "" || dateOfJoining === null) {
      formIsValid = false;
      formErrors["dateOfJoiningError"] = myConstClass.dateOfJoiningMsg;
    }
    if (role.length === 0 || role === null) {
      formIsValid = false;
      formErrors["roleErr"] = myConstClass.roleMsg;
    }

    if (studemail === "" || !validEmail.test(studemail)) {
      if (studemail === "") {
        formIsValid = false;
        formErrors["studemailErr"] = myConstClass.emailIdMsg;
      } else if (!validEmail.test(studemail)) {
        formIsValid = false;
        formErrors["studemailErr"] = myConstClass.invalidEmailIdMsg;
      }
    }

    if (studmobile === "" || studmobile.length != 10) {
      if (studmobile === "") {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNoMsg;
      } else if (!onlyNumber.test(studmobile)) {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNumberMsg;
      } else if (studmobile.length > 0 && studmobile.length != 10) {
        formIsValid = false;
        formErrors["studmobileErr"] = myConstClass.contactNoLengthMsg;
      }
    }

    if (candidatename === "" || candidatename === null) {
      formIsValid = false;
      formErrors["candidatenameErr"] = myConstClass.firstNameMsg;
    }

    // if (middleName === "" || middleName === null) {
    //   formIsValid = false;
    //   formErrors["middleNameErr"] = myConstClass.middleNameMsg;
    // }
    if (mothertounge === "" || mothertounge === null) {
      formIsValid = false;
      formErrors["mothertoungeError"] = myConstClass.mothertoungeMsg;
    }

    if (adhaarNo === "" || adhaarNo === null) {
      formIsValid = false;
      formErrors["adhaarNoError"] = myConstClass.aadhaarMsg;
    }

    if (panNo === "" || panNo === null) {
      formIsValid = false;
      formErrors["panNoError"] = myConstClass.panNoMsg;
    }

    if (dateOfAppointment === "" || dateOfAppointment === null) {
      formIsValid = false;
      formErrors["dateOfAppointmentError"] = myConstClass.dateOfAppointmentMsg;
    }
    // if (lastname === "" || lastname === null) {
    //   formIsValid = false;
    //   formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    // }
    if (employeeCode === "" || employeeCode === null) {
      formIsValid = false;
      formErrors["employeeCodeErr"] = myConstClass.employeeCodeMessage;
    }
    if (libraryNo === "" || libraryNo === null) {
      formIsValid = false;
      formErrors["libraryNoErr"] = myConstClass.libraryNoMsg;
    } else {
      this.libraryNumberHandler(this.state.libraryNo);
    }

    if (employeeRole === "" || employeeRole === null) {
      formIsValid = false;
      formErrors["employeeRoleErr"] = myConstClass.employeeRoleMsg;
    }
    if (dse === "" || dse === null) {
      formIsValid = false;
      formErrors["natureOfAppointmentError"] = "Nature of Appointment required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      studmobile,
      studemail,
      formErrors,
      birthPlace,
      martialStatus,
      totalExperiances,
      role,
      alternateNo,
      employeeCode,
      libraryNo,
      dateOfJoining,
      dateOfAppointment,
      dateOfRetirement,
      adhaarNo,
      panNo,
      employeeRole,
      dse,
      libraryUniqueFlag,
    } = this.state;

    let fd = dob === "" ? "" : moment(dob).format("DD-MM-YYYY");
    let vd =
      validitycddate === "" ? "" : moment(validitycddate).format("DD-MM-YYYY");
    let cd =
      castecertdate === "" ? "" : moment(castecertdate).format("DD-MM-YYYY");
    let dtjoin =
      dateOfJoining === "" ? "" : moment(dateOfJoining).format("DD-MM-YYYY");

    let dtAppt =
      dateOfAppointment === ""
        ? ""
        : moment(dateOfAppointment).format("DD-MM-YYYY");

    let dtRet =
      dateOfRetirement === ""
        ? ""
        : moment(dateOfRetirement).format("DD-MM-YYYY");

    docs = {
      id: id,
      firstName: candidatename,
      middleName: middleName,
      lastName: lastname,
      gender: gender,
      dateOfBirth: fd,
      bloodGroup: bloodgroup,
      religionId: relegion,
      categoryId: category,
      cast: caste,
      subCast: subcaste,
      nationality: nationality,
      domicileof: domicileof,
      motherTongue: mothertounge,
      contactNo: studmobile,
      emailId: studemail,
      validityCdNo: validitycdno,
      validityCdDate: vd,
      castCertificateNo: castecertno,
      castCertificateDate: cd,
      birthplace: birthPlace,
      martialStatus: martialStatus,
      role: role.join(),
      alternateNo: alternateNo,
      employeeCode: employeeCode,
      libraryNo: libraryNo,
      joiningDate: dtjoin,
      leavingDate: dtRet,
      reportingDate: dtAppt,
      aadhaarNo: adhaarNo,
      panNo: panNo,
      type: 1,
      totalExperiences: totalExperiances,
      accessRole: employeeRole,
      dse: dse,
    };

    if (this.handleFormValidation()) {
      if (!libraryUniqueFlag) {
        this.props.OnSave(docs);
      }
    }
  };
  render() {
    const {
      candidatename,
      middleName,
      lastname,
      gender,
      dob,
      nationality,
      relegion,
      category,
      caste,
      subcaste,
      bloodgroup,
      validitycdno,
      validitycddate,
      castecertno,
      castecertdate,
      domicileof,
      mothertounge,
      studmobile,
      studemail,
      birthPlace,
      martialStatus,
      totalExperiances,
      alternateNo,
      employeeCode,
      libraryNo,
      role,
      adhaarNo,
      panNo,
      dateOfAppointment,
      dateOfRetirement,
      dateOfJoining,
      employeeRole,
      dse,
      isDisable,
      id,
    } = this.state;

    const {
      dobErr,
      genderErr,
      candidatenameErr,
      roleErr,
      // middleNameErr,
      // lastNameErr,
      studemailErr,
      totalExperiancesErr,
      nationalityErr,
      studmobileErr,
      martialStatusErr,
      employeeCodeErr,
      libraryNoErr,
      empCodeUniqueErr,
      mothertoungeError,
      emailIdUniqueErr,
      dateOfAppointmentError,
      panNoError,
      adhaarNoError,
      dateOfJoiningError,
      employeeRoleErr,
      natureOfAppointmentError,
    } = this.state.formErrors;

    const { onCancel, employeeList, editForm } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={12} />

            <Grid
              columnSpacing={1}
              container
              justifyContent="space-evenly"
              direction="row"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                spacing={1}
                columnSpacing={1}
                container
                sx={{ mb: 2 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Employee Code"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="employeeCode"
                      size="small"
                      value={employeeCode}
                      onBlur={this.checkEmployeeCodeHandler}
                      onChange={this.changedHandler}
                      fullWidth
                      {...(employeeCodeErr
                        ? { error: true, helperText: employeeCodeErr }
                        : empCodeUniqueErr
                        ? { error: true, helperText: empCodeUniqueErr }
                        : "")}
                      disabled={id != 0 ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Library ID"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="libraryNo"
                      size="small"
                      value={libraryNo}
                      onBlur={(e) => this.libraryNumberHandler(libraryNo)}
                      onChange={this.changedHandler}
                      fullWidth
                      {...(libraryNoErr
                        ? { error: true, helperText: libraryNoErr }
                        : "")}
                      disabled={id != 0 ? true : false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                spacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*First Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="candidatename"
                      size="small"
                      value={candidatename}
                      fullWidth
                      onChange={this.changedHandler}
                      error={candidatenameErr ? true : false}
                      // disabled={id != 0 ? true : false}
                      helperText={candidatenameErr ? candidatenameErr : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Middle Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="middleName"
                      size="small"
                      value={middleName}
                      // disabled={id != 0 ? true : false}
                      fullWidth
                      onChange={this.changedHandler}
                      // error={middleNameErr ? true : false}
                      // helperText={middleNameErr ? middleNameErr : " "}

                      error={false}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={4}
                  lg={4}
                  columnSpacing={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Last Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="lastname"
                      size="small"
                      value={lastname}
                      fullWidth
                      onChange={this.changedHandler}
                      // error={lastNameErr ? true : false}
                      // helperText={lastNameErr ? lastNameErr : " "}

                      error={false}
                      helperText={" "}
                      // disabled={id != 0 ? true : false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Gender"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="gender"
                      id="gender"
                      value={gender}
                      // disabled={id != 0 ? true : false}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value="m">Male</MenuItem>
                      <MenuItem value="f">Female</MenuItem>
                      <MenuItem value="t">Transgender</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {genderErr ? genderErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Date of Birth"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="dob"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    value={dob}
                    maxDate={new Date()}
                    fullWidth
                    isError={dobErr ? true : false}
                    errorText={dobErr ? dobErr : " "}
                    onChange={this.onDateChange("dob")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mb: -4 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Birth Place"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="birthPlace"
                    size="small"
                    value={birthPlace}
                    // disabled={id != 0 ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Blood Group"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={bloodgroup}
                    name={"bloodgroup"}
                    // disabled={id != 0 ? true : false}
                    options={employeeList?.bloodGroup.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Contact No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studmobile"
                    size="small"
                    value={studmobile}
                    // disabled={id != 0 ? true : false}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    error={studmobileErr ? true : false}
                    helperText={studmobileErr ? studmobileErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Email Id"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studemail"
                    size="small"
                    value={studemail}
                    onChange={this.changedHandler}
                    onBlur={this.checkEmployeeEmail}
                    // disabled={id != 0 ? true : false}
                    fullWidth
                    error={studemailErr ? true : false}
                    helperText={studemailErr ? studemailErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Marital Status"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="martialStatus"
                      // disabled={id != 0 ? true : false}
                      id="martialStatus"
                      value={martialStatus}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value={0}>Un-Married</MenuItem>
                      <MenuItem value={1}>Married</MenuItem>
                      <MenuItem value={2}>Divorced</MenuItem>
                      <MenuItem value={3}>widowed</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {martialStatusErr ? martialStatusErr : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Mother Tongue"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothertounge"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    value={mothertounge}
                    onChange={this.changedHandler}
                    fullWidth
                    error={mothertoungeError ? true : false}
                    helperText={mothertoungeError ? mothertoungeError : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Total Experience (In Years)"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="totalExperiances"
                    size="small"
                    value={totalExperiances}
                    // disabled={id != 0 ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    error={totalExperiancesErr ? true : false}
                    helperText={totalExperiancesErr ? totalExperiancesErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Nationality"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="nationality"
                    size="small"
                    value={nationality}
                    // disabled={id != 0 ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    error={nationalityErr ? true : false}
                    helperText={nationalityErr ? nationalityErr : " "}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Religion"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={relegion}
                    name={"relegion"}
                    // disabled={id != 0 ? true : false}
                    fullWidth
                    options={employeeList?.religion.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Category"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={category}
                    name={"category"}
                    // disabled={id != 0 ? true : false}
                    options={employeeList?.category.filter(
                      (row) => row.type === 0 && row.isActive === 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="caste"
                    size="small"
                    value={caste}
                    // disabled={id != 0 ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Subcaste"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="subcaste"
                    // disabled={id != 0 ? true : false}
                    size="small"
                    value={subcaste}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                sx={{ mt: 3 }}
                justifyContent="space-evenly"
              >
                <Grid sx={{ mb: -3 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="castecertno"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    value={castecertno}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                sx={{ mt: 3 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Caste Certificate Date"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    name="castecertdate"
                    // disabled={id != 0 ? true : false}
                    value={castecertdate}
                    onChange={this.onDateChange("castecertdate")}
                    maxDate={new Date()}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mb: -4 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="validitycdno"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    value={validitycdno}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Validity Certificate Date"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    name="validitycddate"
                    value={validitycddate}
                    // disabled={id != 0 ? true : false}
                    onChange={this.onDateChange("validitycddate")}
                    maxDate={new Date()}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} />
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Domicile state"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="domicileof"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    value={domicileof}
                    onChange={this.changedHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Alternate No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="alternateNo"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    value={alternateNo}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} />

              <Grid item xs={12} sm={6} md={12}></Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                sx={{ mt: 3 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Aadhar No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="adhaarNo"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    inputProps={{ maxLength: 12 }}
                    onChange={this.changeHandlerNumber}
                    value={adhaarNo}
                    fullWidth
                    error={adhaarNoError ? true : false}
                    helperText={adhaarNoError ? adhaarNoError : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                sx={{ mt: 3 }}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*PAN No"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="panNo"
                    size="small"
                    // disabled={id != 0 ? true : false}
                    inputProps={{ maxLength: 10 }}
                    value={panNo}
                    onChange={this.changedHandler}
                    // onBlur={this.checkEmployeeEmail}
                    fullWidth
                    error={panNoError ? true : false}
                    helperText={panNoError ? panNoError : " "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Date of Appointment"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="dateOfAppointment"
                    size="small"
                    value={dateOfAppointment}
                    // disabled={id != 0 ? true : false}
                    fullWidth
                    isError={dateOfAppointmentError ? true : false}
                    errorText={
                      dateOfAppointmentError ? dateOfAppointmentError : " "
                    }
                    onChange={this.onDateChange("dateOfAppointment")}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Date of Retirement"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="dateOfRetirement"
                    size="small"
                    value={dateOfRetirement}
                    // disabled={id != 0 ? true : false}
                    minDate={new Date()}
                    fullWidth
                    onChange={this.onDateChange("dateOfRetirement")}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  {/* <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Role"
                  /> */}

                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Date of Joining"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <BasicDatePicker
                    color="primary"
                    name="dateOfJoining"
                    size="small"
                    value={dateOfJoining}
                    // disabled={id != 0 ? true : false}
                    fullWidth
                    isError={dateOfJoiningError ? true : false}
                    errorText={dateOfJoiningError ? dateOfJoiningError : " "}
                    onChange={this.onDateChange("dateOfJoining")}
                  ></BasicDatePicker>
                  {/* <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="role"
                      id="role"
                      value={role}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                      {...(roleErr ? { error: true, helperText: roleErr } : "")}
                    >
                      <MenuItem value="Director">Director</MenuItem>
                      <MenuItem value="Principal">Principal</MenuItem>
                      <MenuItem value="Hod">Hod</MenuItem>
                      <MenuItem value="Class Co-ordinator">
                        Class Co-ordinator
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {roleErr && <FormHelperText error>{roleErr}</FormHelperText>} */}

                  {/* <MultipleSelect
                    er={roleErr}
                    name1="role"
                    value1={role}
                    disabled={editForm ? true : false}
                    names={this.state.roleDataList}
                    onChange={this.multiSelectHandler}
                  /> */}
                </Grid>
              </Grid>

              <Grid
                item
                // sx={{ marginTop: 3 }}
                sm={12}
                xs={12}
                md={6}
                lg={6}
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Nature of Appointment"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="dse"
                      id="dse"
                      value={dse}
                      onChange={this.changedHandler}
                      size="small"
                      fullWidth={true}
                      {...(roleErr ? { error: true, helperText: roleErr } : "")}
                    >
                      <MenuItem value="0">Full Time</MenuItem>
                      <MenuItem value="1">Part Time</MenuItem>
                      <MenuItem value="2">CHB</MenuItem>
                      <MenuItem value="3">Temporary</MenuItem>
                      <MenuItem value="4">Permanent</MenuItem>
                    </Select>
                  </FormControl>
                  {natureOfAppointmentError && (
                    <FormHelperText error>
                      {natureOfAppointmentError}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Role"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <MultipleSelect
                    er={roleErr}
                    name1="role"
                    value1={role}
                    // disabled={id != 0 ? true : false}
                    names={this.state.roleDataList}
                    onChange={this.multiSelectHandler}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Role Access"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={employeeRole}
                    name={"employeeRole"}
                    // disabled={id != 0 ? true : false}
                    options={employeeList?.employeeRoleAccess.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                    isError={employeeRoleErr ? true : false}
                    errorText={employeeRoleErr ? employeeRoleErr : " "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={1} />

            <Grid item xs={12} sm={12} md={6}></Grid>

            <Grid container columnSpacing={1} justifyContent="right">
              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={onCancel}
                  fullWidth={true}
                />
              </Grid>

              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  employeeList: state.studentList,
  employeeDetails: state.employeeList,
});

const mapDispatchToProps = {
  getAlert,
  showNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetailsForm);
