import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { Loading1 } from "../../../components/Loading1";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import MeetingTable from "./memberDetailsTable";

let docs;
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
class MinutesOfMeeting extends Component {
  state = {
    id: 0,
    name: "",
    details: "",
    employeeDepartment: "",
    teacher: "",
    time: "",
    date: "",
    venue: "",
    urlLink: "",
    value: "",
    dialogue: false,
    attendance: false,
    showLoader: false,
    meeting: false,
    scheduleMeetingData: {},
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { name, details } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (details.toString().trim() === "" || details === null) {
      formIsValid = false;
      formErrors["detailsError"] = myConstClass.detailsMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();

    this.setState({ dialogue: true, attendance: false });
  };

  componentDidMount() {
    const { showAttendanceTable, scheduleData, showAggenda } = this.props;
    this.setState({
      attendance: showAttendanceTable,
      scheduleMeetingData: scheduleData,
      dialogue: showAggenda,
    });
  }

  onCancel = (dataToSave) => {
    this.setState({ dialogue: false, attendance: true });
  };

  handledChange = (value) => {
    this.setState({ value: value });
  };

  render() {
    const { name, details, dialogue, attendance, meeting, value } = this.state;
    const { onCancel, backToForm } = this.props;
    const { nameError, detailsError } = this.state.formErrors;
    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={10.5} md={10.5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label="Committee Name "
                    />

                    {" : " + this.props.scheduleData.committee.name}
                  </Grid>
                  <Grid item xs={12} sm={1.5} md={1.5}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back to List"
                      onClick={backToForm}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label="Meeting Title "
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {" : " + this.props.scheduleData.title}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label="Meeting URL "
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {": " + this.props.scheduleData.urlLink}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label="Meeting Venue "
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {" : " + this.props.scheduleData.venue}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label="Meeting Date "
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {" : " + this.props.scheduleData.date}
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label="Meeting Time "
                    />
                    {" : " + this.props.scheduleData.time}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <MeetingTable
            openForm={this.onAddButtonClick}
            onSave={this.onSearchData}
            onEdit={this.OnClickEdit}
            onDelete={this.rowDelete}
            onDetails={this.rowDetails}
            scheduleMeetingData={this.props.scheduleData}
            backToForm={this.props.backToForm}
            backToTable={this.props.backToTable}
            showView={this.props.showView}
          />
        </Paper>

        {this.state.showLoader && <Loading1 />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  deemedCommitteeList: state.deemedCommittee,
});
const mapDispatchToProps = {
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(MinutesOfMeeting);
