import { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { listOfEventsDynamicJson } from "../../../DynamicFormsJson/ListOfEventDynamic";
import { apiDelete } from "../../../utils/api_service";
import { getEmployeeById } from "../../EmployeeDetails/employee.slice";
import { showNotification } from "../../Landing/Landing.slice";
import {
  getEventByFDTD,
  getEventByFDTDFilter,
  getEventById,
} from "../Event Management/event.slice";
import StudentAddEvent from "./StudentAddEvent";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class StudentViewingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showAddCommitteeForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      showLoader: false,
      department: "",
      academicYearId: "",
      departmentList: [],
      editData: {},
      committeeData: {},
      eventDataForAppliedStudents: {},
      fromDate: "",
      formErrors: [],
      toDate: "",
      searchValue: "",
    };
  }

  OnClickEdit = (data) => {
    console.log(data);
    this.props
      .getEventById({
        eventId: data.id,
      })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showAddCommitteeForm: false,
            showTable: false,
            showForm: true,
            editData: this.props.eventList?.eventStudentById,
          });
        }
      });
    console.log(this.props.eventList?.eventStudentById);
  };

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onSearchData = (dataToSearch) => {
    this.setState({
      fromDate: dataToSearch.fromDate,
      toDate: dataToSearch.toDate,
      showLoader: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getEventByFDTD({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "eventName",
      title: "Event Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
      canSearch: true,
    },
    {
      name: "venue",
      title: "Venue",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,

      width: "15%",
    },
    {
      name: "fromDate",
      title: "From Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "toDate",
      title: "To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },

    {
      name: "time",
      title: "Time",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "15%",
    },
  ];

  toggleFormTableVisibility = () => {
    const { showForm, showTable, showAddCommitteeForm, editData } = this.state;
    this.setState({
      showForm: true,
      showAddCommitteeForm: false,
      showTable: false,
      openSnackbar: true,
      editData: {},
    });
  };

  backToForm = () => {
    this.setState({
      showForm: false,
      showAddCommitteeForm: false,
      showTable: true,
      showAppliedStudentsForm: false,
      openSnackbar: true,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    this.props.getEmployeeById({ employeeId: rowData.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      currentOperationMode: OperationMode.Edit,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    apiDelete({
      url: endpoint.eventParticipant + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props
          .getEventByFDTDFilter({
            userRegistrationId: parentChildId === null ? "" : parentChildId,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                isLoading: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                isLoading: false,
              });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getEventByFDTDFilter({
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            isLoading: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      });
  }
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.eventList?.eventStudent.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["venue"] &&
          currentRow["venue"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["fromDate"] &&
          currentRow["fromDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["toDate"] &&
          currentRow["toDate"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["time"] &&
          currentRow["time"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
    } = this.props;
    const { showTable, showForm, editData, fromDate, toDate } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        {/* <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}> */}
        {showForm && (
          <>
            <StudentAddEvent onCancel={this.backToForm} editData={editData} />
          </>
        )}

        {showTable && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={listOfEventsDynamicJson.screenTitle}
              fieldMeta={listOfEventsDynamicJson.fieldMeta}
              buttonCenter={listOfEventsDynamicJson.buttonCenter}
              showPdfDownload={listOfEventsDynamicJson.showPdfDownload}
              showExcelDownload={listOfEventsDynamicJson.showExcelDownload}
              // pdfFileName={listOfEventsDynamicJson.pdfFileName}
              // excelFileName={listOfEventsDynamicJson.excelFileName}
              tableColumnsToFilter={
                listOfEventsDynamicJson.tableColumnsToFilter
              }
              showAddButton={listOfEventsDynamicJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={this.columns}
              isActionColActive={true}
              tableData={this.props.eventList?.eventStudent}
              // getListById={this.getListById}
              showPegination={true}
              showLoader={this.state.showLoader}
              showHeadEdit={true}
              showHeadDelete={true}
              showAppliedList={false}
              // showPegination={false}
              // pdfDetailsId={this.pdfDetails}
              // rowViewData={this.onClickView}
              // showLinkIcon={true}
              generateDetails={this.generateDetails}
              rowDelete={this.rowDelete}
              rowEdit={this.OnClickEdit}
              rowStatus={this.onChangeStatus}
              rowAppliedList={this.appliedList}
              // onDelete={this.onDelete}
              onSearchData={this.onSearchData}
              baseIdColumn={listOfEventsDynamicJson.baseIdColumn}
              apiBaseURL={listOfEventsDynamicJson.apiBaseURL}
            />
          </>
        )}
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYear: state.academicYear,
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getEmployeeById,
  getEventById,
  getEventByFDTD,
  getEventByFDTDFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentViewingEvent);
