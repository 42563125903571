import { Checkbox, FormHelperText, Grid, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import * as myConstClass from "../../config/messageconstant";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import endpoint from "../../config/endpoints";
import { apiPost } from "../../utils/api_service";
import { getAlert } from "../../CommonActions/alert.slice";
import { connect } from "react-redux";

const BasicTable = ({ data, onSave, getAlert, editData, resetUserAccess }) => {
  const [moduleList, setModuleList] = React.useState([]);
  const [accessRoleName, setAccessRoleName] = React.useState("");
  const [shortName, setShortName] = React.useState("");
  const [id, setId] = React.useState(0);
  const [remark, setRemark] = React.useState("");
  const [error, setErrors] = React.useState(false);
  const [formError, setFormErrors] = React.useState("");
  React.useEffect(() => {
    if (moduleList.length === 0) {
      setModuleList(data);
    }
    if (Object.keys(editData).length !== 0 && moduleList !== editData.modules) {
      setAccessRoleName(editData.empTypeName);
      setShortName(editData.empTypeShortName);
      setRemark(editData.empTypeRemarks);
      setId(editData.empTypeId);
      if (moduleList.length !== 0) {
        const moduleListUpdated = moduleList.map((module) => {
          let filteredModuleList = editData.modules.filter(
            (moduleData) => moduleData.moduleId === module.moduleId
          );
          if (filteredModuleList.length !== 0) {
            const moduleSubs = module.moduleSubs.map((moduleSubs) => {
              let filteredSubModuleList =
                filteredModuleList[0].moduleSubs.filter(
                  (moduleData) =>
                    moduleData.subModuleId === moduleSubs.subModuleId
                );
              if (filteredSubModuleList.length !== 0) {
                moduleSubs = {
                  ...moduleSubs,
                  addApproveConfig: filteredSubModuleList[0].addApproveConfig,
                  deleteRejectApprove:
                    filteredSubModuleList[0].deleteRejectApprove,
                  editReject: filteredSubModuleList[0].editReject,
                  view: filteredSubModuleList[0].view,
                };
                return moduleSubs;
              }
              return moduleSubs;
            });
            module = {
              ...module,
              checked: 0,
              moduleSubs: moduleSubs,
            };
            return module;
          }
          return module;
        });
        setModuleList(moduleListUpdated);
        resetUserAccess();
      }
    }
  });
  const changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === "accessRoleName") {
      setAccessRoleName(value);
    } else if (name === "shortName") {
      setShortName(value);
    } else {
      setRemark(value);
    }
  };

  const handleClick = (e, name, moduleSubs, row) => {
    const moduleandSubModuleList = moduleList.map((module) => {
      if (module.moduleId === row.moduleId) {
        const subModuleList = module.moduleSubs.map((submodule) => {
          if (submodule.subModuleId === moduleSubs.subModuleId) {
            submodule = {
              ...submodule,
              [name]: e.target.checked ? 1 : 0,
            };
            return submodule;
          }
          return submodule;
        });
        module = {
          ...module,
          moduleSubs: subModuleList,
        };
        return module;
      }
      return module;
    });
    console.log(e);
    setModuleList(moduleandSubModuleList);
    console.log(moduleandSubModuleList);
  };
  const handleClickAll = (e, row) => {
    const moduleandSubModuleList = moduleList.map((module) => {
      if (module.moduleId === row.moduleId) {
        const submoduleList = module.moduleSubs.map((submodule) => {
          submodule = {
            ...submodule,
            editReject: e.target.checked ? 1 : 0,
            addApproveConfig: e.target.checked ? 1 : 0,
            deleteRejectApprove: e.target.checked ? 1 : 0,
            view: e.target.checked ? 1 : 0,
          };
          return submodule;
        });
        module = {
          ...module,
          checked: e.target.checked,
          moduleSubs: submoduleList,
        };
        return module;
      }

      return module;
    });
    setModuleList(moduleandSubModuleList);
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (accessRoleName === "" || accessRoleName === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.accessRoleMsg;
    }

    if (shortName === "" || shortName === null) {
      formIsValid = false;
      formErrors["shortNameError"] = myConstClass.shortNameMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };
  const submitHandler = () => {
    let moduleListToSave = [];
    moduleList.map((module) => {
      let submoduleList = [];
      if (module.checked == 1) {
        module.moduleSubs.map((submodule) => {
          submoduleList.push(submodule);
        });
      } else {
        module.moduleSubs.map((submodule) => {
          if (
            submodule.addApproveConfig == 1 ||
            submodule.editReject == 1 ||
            submodule.deleteRejectApprove == 1 ||
            submodule.view == 1
          ) {
            submoduleList.push(submodule);
          }
        });
      }
      if (submoduleList.length !== 0) {
        module = {
          ...module,
          moduleSubs: submoduleList,
        };
        delete module.checked;
        moduleListToSave.push(module);
      }
    });
    let accessRole = {
      companyId: 0,
      empTypeName: accessRoleName,
      empTypeShortName: shortName,
      compOffRequestAllowed: 0,
      setting1: 0,
      setting2: 0,
      empTypeRemarks: remark,
      empTypeAccess: "",
      delStatus: 0,
      isActive: 1,
      makerUserId: 0,
      makerEnterDatetime: null,
      exInt1: 0,
      exInt2: 0,
      exInt3: 0,
      exVar1: 0,
      exVar2: 0,
      exVar3: 0,
      modules: moduleListToSave,
    };
    if (id !== 0) {
      accessRole = {
        ...accessRole,
        empTypeId: id,
      };
    }
    if (checkValidation() && accessRole.modules.length !== 0) {
      apiPost({
        url: endpoint.saveAccessRole,
        postBody: accessRole,
      }).then(({ data, success }) => {
        if (!success) {
          getAlert({
            message: "Something went wrong",
          });
        } else {
          onSave();
        }
      });
    } else if (accessRole.modules.length === 0) {
      setErrors(true);
    }

    console.log(moduleListToSave);
  };
  return (
    <>
      <Grid container justifyContent="space-evenly" direction="row">
        <Grid
          item
          xs={12}
          md={12}
          size="small"
          container
          justifyContent="flex-end"
        >
          <ButtonCompo
            size="small"
            type="button"
            variant="outlined"
            name=" Back to List"
            onClick={onSave}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          container
          spacing={1}
          justifyContent="space-evenly"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="*Access Role Name"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                color="primary"
                name="accessRoleName"
                size="small"
                value={accessRoleName}
                onChange={changeHandler}
                error={formError.nameError ? true : false}
                helperText={formError.nameError ? formError.nameError : " "}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={6}
            lg={6}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="*Short Name"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                color="primary"
                name="shortName"
                size="small"
                value={shortName}
                onChange={changeHandler}
                error={formError.shortNameError ? true : false}
                helperText={
                  formError.shortNameError ? formError.shortNameError : " "
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Remark"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                color="primary"
                name="remark"
                size="small"
                value={remark}
                onChange={changeHandler}
                // error={nameError ? true : false}
                // helperText={nameError ? nameError : " "}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <FormHelperText error>
        {error ? "*Please select atlest one checkbox" : ""}
      </FormHelperText>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Sr. No.</TableCell>
              <TableCell>Module Name</TableCell>
              <TableCell align="center" width="10%">
                Add
              </TableCell>
              <TableCell align="center" width="10%">
                Edit
              </TableCell>
              <TableCell align="center" width="10%">
                Delete
              </TableCell>
              <TableCell align="center" width="10%">
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moduleList.map((row, index) => (
              <>
                <TableRow key={row.name}>
                  <TableCell align="center">
                    {index + 1}
                    <Checkbox
                      color="primary"
                      onClick={(event) => handleClickAll(event, row)}
                      checked={row.checked ? true : false}
                      // inputProps={{
                      //   "aria-labelledby": labelId,
                      // }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" colSpan={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, fontWeight: "bold" }}
                      label={row.moduleName}
                    />
                  </TableCell>
                </TableRow>
                {row.moduleSubs.map((submoduleData, index) => {
                  return (
                    <TableRow key={submoduleData.name}>
                      <TableCell align="center"></TableCell>
                      <TableCell component="th" scope="row">
                        &nbsp;&nbsp; &nbsp;&nbsp; {submoduleData.subModuleName}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Checkbox
                          color="primary"
                          onClick={(event) =>
                            handleClick(
                              event,
                              "addApproveConfig",
                              submoduleData,
                              row
                            )
                          }
                          checked={
                            submoduleData.addApproveConfig ? true : false
                          }
                          // inputProps={{
                          //   "aria-labelledby": labelId,
                          // }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Checkbox
                          color="primary"
                          onClick={(event) =>
                            handleClick(event, "editReject", submoduleData, row)
                          }
                          checked={submoduleData.editReject ? true : false}
                          // inputProps={{
                          //   "aria-labelledby": labelId,
                          // }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Checkbox
                          color="primary"
                          onClick={(event) =>
                            handleClick(
                              event,
                              "deleteRejectApprove",
                              submoduleData,
                              row
                            )
                          }
                          checked={
                            submoduleData.deleteRejectApprove ? true : false
                          }
                          // inputProps={{
                          //   "aria-labelledby": labelId,
                          // }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Checkbox
                          color="primary"
                          onClick={(event) =>
                            handleClick(event, "view", submoduleData, row)
                          }
                          checked={submoduleData.view ? true : false}
                          // inputProps={{
                          //   "aria-labelledby": labelId,
                          // }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div align="right">
        <ButtonCompo
          size="button"
          type="button"
          variant="outlined"
          name="Cancel"
          onClick={onSave}
        />
        &nbsp;&nbsp;
        <ButtonCompo
          size="button"
          type="button"
          variant="contained"
          name="Submit"
          onClick={submitHandler}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  student: state.studentList,
});

const mapDispatchToProps = {
  getAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
