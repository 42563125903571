import endpoint from "../config/endpoints";

export const WorkExpJson = {
  apiBaseURL: endpoint.workExperience,
  screenTitle: "",
  showAddButton: true,
  showPdfDownload: false,
  showSaveNextBtn: false,
  pdfFileName: "Work Experience",
  showExcelDownload: false,
  excelFileName: "Work Experience",

  fieldMeta: [
    {
      label: "Company Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "companyName",
      isMandatory: true,
    },
    {
      label: "Position/Designation",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "designation",
      isMandatory: false,
    },
    {
      label: "Location",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "location",
      isMandatory: false,
    },

    {
      label: "Professional Skills",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "professionalSkills",
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      date: true,
      dataKey: "fromDate",
      setText: true,
      setTextTo: "totalExperience",
      isMAxDate: false,
      getText: "toDate",
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "hideDatepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      setText: true,
      dataKey: "toDate",
      date: false,
      setTextTo: "totalExperience",
      isMAxDate: true,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: false,
      getText: "fromDate",
      tableDataKey: "currentJob",
      open: "1",
    },
  
    {
      label: "Job Status",
      controlType: "radiogroup",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,  hideTextfield: true,
      dataKey: "currentJob",
      tableDataKey: "currentJob",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Past",
          value: "1",
        },
        {
          label: "Current",
          value: "0",
        },
      ],
      isMandatory: true,
      depends: "toDate",
      openOn: "2",
    },
    {
      label: "Self Employed",
      controlType: "radiogroup",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,
      dataKey: "selfEmployeed",
      tableDataKey: "selfEmployeed",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Total Experience",
      controlType: "hideTextfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "totalExperience",
      rootValue: true,
      showLabel:true,
      tableDataKey: "currentJob",
      open: "1",
      setTextHide:true,
    },
  ],
};
