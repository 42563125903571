import { Grid } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import endpoint from "../../config/endpoints";
import { apiDelete, apiPost } from "../../utils/api_service";
import { ButtonCompo } from "../Comman/Button";
import { validEmail } from "../Comman/Util/Validations";
import { Loading1 } from "../Loading1";
import DynamicDocumentUpload from "./DynamicDocumentUpload";
import { getAlert } from "../../CommonActions/alert.slice";

import DynamicForm from "./DynamicFormForImg";
import swal from "sweetalert";
import { openFileInNewTab } from "../../Pages/UploadFile/file.slice";
import { showNotification } from "../../Pages/Landing/Landing.slice";
class DynamicFromWithImageUpload extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      isLoading: false,
      formErrors: {},
      fieldData: {},
    };
  }
  onAddRow = (rowData) => {
    const { hideTable } = this.props;
    this.setState({
      isLoading: true,
    });

    const formData = new FormData();
    formData.append("file", rowData.fileName);
    apiPost({
      url: endpoint.fileUpload,
      postBody: formData,
    }).then(({ data, success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        let row = {
          index: this.state.rows.length + 1,
          fileName: data,
          docName: rowData.imageName,
        };
        if (hideTable) {
          this.setState({
            rows: [row],
          });
        } else {
          console.log(row);
          this.setState({
            rows: [...this.state.rows, row],
          });
        }
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        let date = new Date(newValue);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear() + 1;
        fieldData[currentField.getDate] = new Date(year, month, day);
      } else if (currentField.dataKey === fieldName) {
        let date = new Date(newValue);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear() + 1;
        fieldData[currentField.getDate] = new Date(year, month, day);
        if (currentField.setText) {
          if (this.state.fieldData[currentField.getText]) {
            let calValuToSet = "";
            if (currentField.date) {
              calValuToSet = this.calcDate(
                newValue,
                this.state.fieldData[currentField.getText]
              );
            } else {
              calValuToSet = this.calcDate(
                this.state.fieldData[currentField.getText],
                newValue
              );
            }

            fieldData[currentField.setTextTo] = calValuToSet;
          }
        }
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  componentDidMount() {
    const { rowEdit } = this.props;
    if (rowEdit !== undefined) {
      if (Object.keys(rowEdit).length !== 0) {
        console.log(rowEdit);
        this.setState({
          fieldData: rowEdit,
          rows: rowEdit.rows ? rowEdit.rows : [],
        });
      }
    }
  }
  onSave = () => {
    if (this.checkValidationOnSubmit()) {
      const { fieldData, rows } = this.state;

      console.log(rows);
      console.log(fieldData);
      this.props.onSave(fieldData, rows);
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;
    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory && currentField.anyOne == undefined) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.anyOne != undefined) {
        if (
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString() === "") &&
          (fieldData[currentField.anyOne] === undefined ||
            fieldData[currentField.anyOne] === null ||
            fieldData[currentField.anyOne].toString() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.msg} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  ValidationCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      if (this.ValidationCheck()) {
        return this.onSubmitCheck();
      }
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta } = this.props;
    const { fieldData } = this.state;
    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];
          if (dataKeyValue && compareWithValue) {
            if (typeof dataKeyValue === "string") {
              const dateArr = dataKeyValue?.split("-");

              dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
            }
            if (typeof compareWithValue === "string") {
              const dateArr = compareWithValue?.split("-");

              compareWithValue = new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2]
              );
            }
            switch (compareCond) {
              case "l":
                {
                  if (!(dataKeyValue < compareWithValue)) {
                    isError = true;
                    condErrorText = "less than";
                  }
                }
                break;
              case "le":
                {
                  if (!(dataKeyValue <= compareWithValue)) {
                    isError = true;
                    condErrorText = "less than equal to";
                  }
                }
                break;
              case "g":
                {
                  if (!(dataKeyValue > compareWithValue)) {
                    isError = true;
                    condErrorText = "greater than";
                  }
                }
                break;
              case "ge":
                {
                  if (!(dataKeyValue >= compareWithValue)) {
                    isError = true;
                    condErrorText = "greater than equal to";
                  }
                }
                break;
              case "eq":
                {
                  if (!(dataKeyValue === compareWithValue)) {
                    isError = true;
                    condErrorText = "equal to";
                  }
                }
                break;
            }

            if (isError) {
              const compareWithFieldLabel = fieldMeta.filter(
                (item) => item.dataKey === compareWith
              )[0].label;
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
                },
              };
            }
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  rowDelete = (rowData) => {
    console.log(rowData);

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  rowView = (params) => {
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };
  onDelete = (data) => {
    this.setState({
      isLoading: true,
    });
    const { rows } = this.state;
    const { deleteUrl } = this.props;
    if (data.id !== undefined && deleteUrl !== "") {
      apiDelete({
        url: deleteUrl + "/" + data.id,
      }).then(({ success }) => {
        if (success) {
          this.props.showNotification({ msg: "Data deleted successfully" });
          let rowList = [];
          rows.map((item, index) => {
            if (item.index !== data.index) {
              item = {
                ...item,
                index: rowList.length + 1,
              };
              rowList.push(item);
            }
          });

          this.setState({
            rows: rowList,
            currentOperationMode: "",
            isLoading: false,
          });
        } else {
          this.props.getAlert({
            message: "Failed to delete",
          });
          this.setState({
            isLoading: false,
          });
        }
      });
    } else {
      let rowList = [];
      rows.map((item, index) => {
        if (item.index !== data.index) {
          item = {
            ...item,
            index: rowList.length + 1,
          };
          rowList.push(item);
        }
        return;
      });
      this.props.showNotification({ msg: "Data deleted successfully" });
      this.setState({
        rows: rowList,
        isLoading: false,
      });
    }
  };

  render() {
    const {
      fieldMeta,
      onCancel,
      dynamicMasterData,
      showBackToListBtn,
      hideTable,
      showTextfield = true,
      allow = false,
      DataToSet = [],
    } = this.props;
    const { rows, isLoading, fieldData, formErrors } = this.state;
    return (
      <>
        {isLoading && <Loading1 />}
        {fieldMeta.length !== 0 && (
          <DynamicForm
            fieldMeta={fieldMeta}
            fieldData={fieldData}
            dynamicMasterData={dynamicMasterData}
            onDataChange={this.onDataChange}
            showSaveNextBtn={false}
            showCancel={false}
            showSaveBtn={false}
            onCancel={onCancel}
            formErrors={formErrors}
            showBackToListBtn={showBackToListBtn}
            DataToSet={DataToSet}
          />
        )}

        <DynamicDocumentUpload
          hideTable={hideTable}
          rowView={this.rowView}
          rowDelete={this.rowDelete}
          rows={rows}
          onSave={this.onAddRow}
          showTextfield={showTextfield}
          allow={allow}
        />

        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Save"
              fullWidth={true}
              onClick={this.onSave}
            />
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={1.1}
            lg={1.1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={onCancel}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { openFileInNewTab, getAlert, showNotification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicFromWithImageUpload);
