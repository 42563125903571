import endpoint from "../config/endpoints";

export const UnitTestDeclareTableJson = {
    apiBaseURL: endpoint.studentUnitTest,
    screenTitle: "Unit Test Declaration",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    fieldMetaForTable: [
        // {
        //     label: "Academic Year",
        //     controlType: "autocomplete",
        //     placeHolder: "",
        //     md: 6,
        //     lg: 6,
        //     sm: 6,
        //     xs: 12,
        //     masterName: "acadamicMaster",
        //     getListId: 'acadamicYear,yearDetail',
        //     dataKey: "acadamicYear",
        //     isMandatory: true,
        // },
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 3.6,
            lg: 3.6,
            sm: 3.6,
            xs: 12,
            masterName: "yearMaster",
            getListId: 'yearDetail,acadamicYear',
            dataKey: "yearDetail",
            isMandatory: true,
        },

        {
            label: "Semester Details",
            controlType: "autocomplete",
            placeHolder: "",
            md: 3.6,
            lg: 3.6,
            sm: 3.6,
            xs: 12,
            masterName: "semisterMaster",
            getListFrom: 'acadamicYear,yearDetail',
            dataKey: "semister",
            isMandatory: true,
        },
        {
            label: "Division",
            controlType: "autocomplete",
            placeHolder: "",
            md: 3.6,
            lg: 3.6,
            sm: 3.6,
            xs: 12,
            masterName: "divisionMaster",
            getListFrom: 'yearDetail',
            dataKey: "division",
            isMandatory: true,
        },
    ],
    fieldMeta: [
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "yearMaster",
            getListId: 'yearDetailId',
            dataKey: "yearDetailId",
            isMandatory: true,
        },
        {
            label: "Semester Details",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "semisterMaster",
            dataKey: "semesterDetail",
            getListId: 'semesterDetail',
            isMandatory: true,
        },
        {
            label: "Division",
            controlType: "multiSelect",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "divisionMaster",
            getListId: 'divisionId',
            dataKey: "divisionId",
            isMandatory: true,
        },
        {
            label: "Subject",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "subjectMaster",
            dataKey: "subject",
            getListId: 'subject',
            getListFrom:'semister',
            isMandatory: true,
        },
        {
            label: "Subject Scheme",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "subjectSchemeMaster",
            dataKey: "subjectScheme",
            getListFrom:'subject',
            isMandatory: true,
        },
        {
            label: "Unit Test",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "unitTestMaster",
            dataKey: "unitTest",
            isMandatory: true,
        },
        {
            label: "Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "date",
            isMAxDate: false,
            maxDate: new Date(),
            isMinDate: false,
            minDate: new Date(),
            "isMandatory": true
        },
        {
            label: "Maximum Marks",
            controlType: "textfield",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "maxMarks",
            isMandatory: true
        },
    ],

};

