import React, { Component } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getAlert } from "../../CommonActions/alert.slice";
import { showNotification } from "../../Pages/Landing/Landing.slice";

import { ButtonCompo } from "../../components/Comman/Button";
import { connect } from "react-redux";
import * as myConstClass from "../../config/messageconstant";
import {
  getSubjectFromDeptSemester,
  getSchemeHead,
} from "./SubjectSelection.slice";
import { LabelCompo } from "../../components/Comman/Label";
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
class SubjectSelectionMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      columnList: [],
      instituteNames: [],
      columnHeaderStatus: {},
      rowHeaderStatus: {},
      matrixError: false,
    };
  }

  componentDidMount() {
    // this.props.getSubjectFromDeptSemester();
    this.props.getSchemeHead().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { subjectList } = this.props;
    if (
      (subjectList?.subjectSelected !== nextProps.subjectList.subjectSelected &&
        nextProps.subjectList.isFetchSchemeHead) ||
      (subjectList?.schemeHead !== nextProps.subjectList.schemeHead &&
        nextProps.subjectList.isFetch)
    ) {
      this.createMatrixData(
        nextProps.subjectList?.subjectSelected,
        nextProps.subjectList?.schemeHead
      );
    }
  }

  createMatrixData(subjectList, schemeHead) {
    const subjectMatrix = subjectList.map((subjectData) => {
      const schemeHeadList = schemeHead.map((schemeHeadData) => {
        if (subjectData.shortName === "comp") {
          schemeHeadData = {
            ...schemeHeadData,
            value: true,
          };
          return schemeHeadData;
        } else {
          schemeHeadData = {
            ...schemeHeadData,
            value: false,
          };
          return schemeHeadData;
        }
      });
      subjectData = {
        ...subjectData,
        value: subjectData.shortName === "comp" ? true : false,
        schemeHeadList: schemeHeadList,
      };
      return subjectData;
    });

    this.setState({
      matrixData: subjectMatrix,
    });
  }

  onRowHeaderChange = (subjectId, e) => {
    console.log(subjectId);
    let { matrixData } = this.state;
    const subjectMatrix = matrixData.map((subjectData) => {
      if (subjectData.id === subjectId) {
        const schemeHeadList = subjectData.schemeHeadList.map(
          (schemeHeadData) => {
            const filteredList =
              subjectData.subjectDetails.length !== 0
                ? subjectData.subjectDetails.filter(
                    (item) => item.schemeHeadId.id === schemeHeadData.id
                  )
                : [];
            schemeHeadData = {
              ...schemeHeadData,
              value: filteredList.length !== 0 ? e.target.checked : false,
            };
            return schemeHeadData;
          }
        );
        subjectData = {
          ...subjectData,
          value: e.target.checked,
          schemeHeadList: schemeHeadList,
        };
        return subjectData;
      }

      return subjectData;
    });
    this.setState({ matrixData: subjectMatrix });
  };
  submitHandler = (e) => {
    e.preventDefault();

    let { matrixData } = this.state;
    console.log(matrixData);
    let subjectlist = [];
    matrixData.map((columnRowData) => {
      if (columnRowData.value) {
        columnRowData.subjectDetails.map((subDetails) => {
          let sub = {
            subjectDetail: {
              id: subDetails.id,
            },
          };

          subjectlist.push(sub);
        });
      }
    });
    console.log(subjectlist);
    console.log(this.props.studentData);
    const dataToSave = this.props.studentData.map((columnRowData) => {
      columnRowData = {
        userRegistration: {
          id: columnRowData.id,
        },
        reportingDetail: {
          id: columnRowData.reportingDetail,
        },
        admissionRegistrationDetailId:
          columnRowData.admissionRegistrationDetailId,
        semisterDetail: {
          id: this.props.semisterDetailId,
        },
        studentSubjectDetails: subjectlist,
      };
      return columnRowData;
    });
    console.log(dataToSave);
    apiPost({
      url: endpoint.studSubject,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.props.onCloseMat();
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
    // let { instituteProgramData } = this.props;

    // let programList = [];
    // let dataToSave = [];
    // columnList.forEach((columnRowData) => {
    //   const programData = instituteProgramData.filter(
    //     (item) => item.programTitle.brName === columnRowData
    //   )[0];
    //   programList.push({
    //     programTypeId: programData.programTitle.programType.id,
    //     programTitleId: programData.programTitle.id,
    //     brName: programData.programTitle.brName,
    //   });
    // });
    // matrixData.forEach((matrixDataRow) => {
    //   programList.forEach((programData) => {
    //     if (matrixDataRow[programData.brName]) {
    //       dataToSave.push({
    //         programTypeId: programData.programTypeId,
    //         programTitleId: programData.programTitleId,
    //         tenantId: matrixDataRow.tenantId,
    //       });
    //     }
    //   });
    // });
    // if (dataToSave.length != 0) {
    //   this.props.onSave(dataToSave);
    // } else {
    //   this.setState({
    //     matrixError: true,
    //   });
    // }
  };
  render() {
    const { matrixData } = this.state;
    const { onCancel } = this.props;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            // m={2}
            rowSpacing={1}
            container
            justifyContent="left"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: "#808080" }}
                label="Note: Select subject for the student"
              />
            </Grid>
            {/* 
          {matrixError === true && (
            <FormHelperText error>{myConstClass.instCourseMsg}</FormHelperText>
          )} */}
          </Grid>
          {
            <TableContainer>
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    {this.props.subjectList?.schemeHead.map(
                      (columnName, index) => {
                        return (
                          <TableCell align="center">
                            {columnName.name}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matrixData.map((matrixDataRow, indexRow) => {
                    return (
                      <TableRow>
                        <TableCell component="td" scope="row">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={"Inst" + indexRow}
                                  onChange={(e) =>
                                    this.onRowHeaderChange(matrixDataRow.id, e)
                                  }
                                  id={"Inst" + indexRow}
                                  checked={matrixDataRow.value}
                                  disabled={
                                    matrixDataRow.shortName === "comp"
                                      ? true
                                      : false
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label={matrixDataRow.name}
                            />
                          </FormGroup>
                        </TableCell>
                        {matrixDataRow.schemeHeadList.map(
                          (columnName, index) => {
                            const programData =
                              matrixDataRow.subjectDetails.length !== 0
                                ? matrixDataRow.subjectDetails.filter(
                                    (item) =>
                                      item.schemeHeadId.id === columnName.id
                                  )
                                : [];
                            if (programData.length !== 0) {
                              return (
                                <TableCell align="center" sx={{ width: 50 }}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={"Course" + index}
                                          onChange={(e) =>
                                            this.onProgramChange(
                                              matrixDataRow.name,
                                              columnName
                                            )
                                          }
                                          checked={columnName.value}
                                          disabled={true}
                                          id={"Course" + index}
                                        />
                                      }
                                      label={""}
                                      labelPlacement="bottom"
                                    />
                                  </FormGroup>
                                </TableCell>
                              );
                            }
                            return <TableCell align="center"></TableCell>;
                          }
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name=" Cancel"
                fullWidth={true}
                onClick={this.props.onCancel}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                fullWidth={true}
                onClick={this.submitHandler}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subjectList: state.subjectSelection,
});

const mapDispatchToProps = {
  getSubjectFromDeptSemester,
  getSchemeHead,

  showNotification,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectSelectionMatrix);
