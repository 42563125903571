import React, { Component } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getAlert } from "../../../CommonActions/alert.slice";
import { showNotification } from "../../../Pages/Landing/Landing.slice";

import { ButtonCompo } from "../../../components/Comman/Button";
import { connect } from "react-redux";
import * as myConstClass from "../../../config/messageconstant";

import { LabelCompo } from "../../../components/Comman/Label";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";
class StudentApplyMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
    };
  }

  componentDidMount() {
    const { examListData } = this.props;
    console.log(examListData);
  }

  componentWillReceiveProps(nextProps) {
    const { studentExamList } = this.props;
    if (
      (studentExamList?.subjectSelectedStudentExam !==
        nextProps.studentExamList.subjectSelectedStudentExam &&
        nextProps.studentExamList.isFetchSchemeHeadExam) ||
      (studentExamList?.schemeHeadStudentExam !==
        nextProps.studentExamList.schemeHeadStudentExam &&
        nextProps.studentExamList.isFetchSubjectListStudentExam) ||
      (studentExamList?.studentExamAppliedSubject !==
        nextProps.studentExamList.studentExamAppliedSubject &&
        nextProps.studentExamList.isFetchStudentExamApplied)
    ) {
      this.createMatrixData(
        nextProps.studentExamList?.subjectSelectedStudentExam,
        nextProps.studentExamList?.schemeHeadStudentExam,
        nextProps.studentExamList?.studentExamAppliedSubject
      );
    }
  }

  createMatrixData(
    subjectSelectedStudentExam,
    schemeHeadStudentExam,
    studentExamAppliedSubject
  ) {
    const subjectMatrix = subjectSelectedStudentExam.map((subjectData) => {
      const schemeHeadList = schemeHeadStudentExam.map((schemeHeadData) => {
        const filteredList = studentExamAppliedSubject.filter(
          (rowData) =>
            rowData.schemeHeadId === schemeHeadData.id &&
            rowData.subjectId === subjectData.subjectId
        );

        if (subjectData.shortName === "comp") {
          schemeHeadData = {
            ...schemeHeadData,
            value:
              subjectData.shortName === "comp"
                ? true
                : this.props.examListData.declareResult == 2
                ? true
                : false,
            checkBox: filteredList.length === 0 ? false : true,
            subjectDetailId:
              filteredList.length !== 0 ? filteredList[0].id : "",
          };
          return schemeHeadData;
        } else {
          schemeHeadData = {
            ...schemeHeadData,
            value:
              subjectData.shortName === "comp"
                ? true
                : this.props.examListData.declareResult == 2
                ? true
                : false,
            checkBox: filteredList.length === 0 ? false : true,
            subjectDetailId:
              filteredList.length !== 0 ? filteredList[0].id : "",
          };
          return schemeHeadData;
        }
      });
      subjectData = {
        ...subjectData,
        value:
          subjectData.shortName === "comp"
            ? true
            : this.props.examListData.declareResult == 2
            ? true
            : false,
        schemeHeadList: schemeHeadList,
      };
      return subjectData;
    });

    this.setState({
      matrixData: subjectMatrix,
    });
  }

  onRowHeaderChange = (subjectId, e) => {
    console.log(subjectId);
    let { matrixData } = this.state;
    const subjectMatrix = matrixData.map((subjectData) => {
      if (subjectData.id === subjectId) {
        const schemeHeadList = subjectData.schemeHeadList.map(
          (schemeHeadData) => {
            schemeHeadData = {
              ...schemeHeadData,
              value: schemeHeadData.checkBox ? e.target.checked : false,
            };
            return schemeHeadData;
          }
        );
        subjectData = {
          ...subjectData,
          value: e.target.checked,
          schemeHeadList: schemeHeadList,
        };
        return subjectData;
      }

      return subjectData;
    });
    this.setState({ matrixData: subjectMatrix });
  };

  submitHandler = (e) => {
    e.preventDefault();

    let { matrixData } = this.state;
    let subjectlist = [];
    matrixData.map((columnRowData) => {
      if (columnRowData.value) {
        columnRowData.schemeHeadList.map((subDetails) => {
          if (subDetails.checkBox && subDetails.value) {
            let sub = {
              subjectDetail: {
                id: subDetails.subjectDetailId,
              },
              grade: "",
              result: "",
              isActive: 1,
            };

            subjectlist.push(sub);
          }
        });
      }
    });
    const subjectCount =
      this.props.studentExamList?.subjectSelectedStudentExam.length *
      this.props.examListData.examFeesStructurePerSubject;
    if (subjectCount > this.props.examListData.examFeesStructureMaxCapFees) {
      subjectCount = this.props.examListData.examFeesStructureMaxCapFees;
    }

    let columnRowData = {
      examFormDeclare: {
        id: this.props.examListData.id,
      },
      approvedStatus: "0",
      seatNo: "4321",
      admissionRegistrationDetailId:
        matrixData[0].admissionRegistrationDetailId,
      applicableFees:
        this.props.examListData.examFeesStructureType === "0"
          ? this.props.examListData.examFeesStructureFixedFees
          : subjectCount,
      remark: "",
      isRegular: this.props.examListData.declareResult,
      isActive: "1",
      studentDetailExamMarks: subjectlist,
    };
    console.log(columnRowData);
    swal({
      title: "Are you sure?",
      text: "Do you want to submit data?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiPost({
          url: endpoint.studentApply + "/by-student",
          postBody: columnRowData,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data saved successfully",
            });
            this.props.onCloseMat();
          } else {
            this.props.getAlert({
              message: "Failed to save",
            });
          }
        });
      }
    });
  };

  render() {
    const { matrixData } = this.state;
    const { onCancel } = this.props;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={1}
            container
            justifyContent="left"
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"Exam Title : " + this.props.examListData.examTitle}
              />
            </Grid>

            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"Start Date : " + this.props.examListData.startDate}
              />
            </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 18 }}
                label={"End Date : " + this.props.examListData.endDate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} />

          <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 12, color: "#808080" }}
              label="Note: Select subject for the student"
            />
          </Grid>

          {
            <TableContainer>
              <Table
                sx={{ minWidth: 600 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    {this.props.studentExamList?.schemeHeadStudentExam.map(
                      (columnName, index) => {
                        return (
                          <TableCell align="center">
                            {columnName.name}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matrixData.map((matrixDataRow, indexRow) => {
                    return (
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          sx={{ width: 600 }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={"Inst" + indexRow}
                                  onChange={(e) =>
                                    this.onRowHeaderChange(matrixDataRow.id, e)
                                  }
                                  id={"Inst" + indexRow}
                                  checked={matrixDataRow.value}
                                  disabled={
                                    matrixDataRow.shortName === "comp"
                                      ? true
                                      : this.props.examListData.declareResult ==
                                        2
                                      ? true
                                      : false
                                  }
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label={matrixDataRow.subjectName}
                            />
                          </FormGroup>
                        </TableCell>
                        {matrixDataRow.schemeHeadList.map(
                          (columnName, index) => {
                            if (columnName.checkBox) {
                              return (
                                <TableCell align="center">
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={"Course" + index}
                                          onChange={(e) =>
                                            this.onProgramChange(
                                              matrixDataRow.name,
                                              columnName
                                            )
                                          }
                                          checked={columnName.value}
                                          disabled={true}
                                          id={"Course" + index}
                                        />
                                      }
                                      label={""}
                                      labelPlacement="bottom"
                                    />
                                  </FormGroup>
                                </TableCell>
                              );
                            }
                            return <TableCell align="center"></TableCell>;
                          }
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name=" Cancel"
                fullWidth={true}
                onClick={this.props.handleCloses}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                fullWidth={true}
                onClick={this.submitHandler}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  subjectList: state.subjectSelection,
  studentExamList: state.examDeclare,
});

const mapDispatchToProps = {
  showNotification,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentApplyMatrix);
