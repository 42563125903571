
import endpoint from "../config/endpoints";

export const semisterInfoMasterJson = {
  apiBaseURL: endpoint.semister,
  screenTitle: "Semester Info",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Semester Info",
  showExcelDownload: true,
  excelFileName: "Semester Info",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Year Details",
      columnKeyName: "yearDetailName",
      isChecked: true,
    },
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order No",
      columnKeyName: "sortNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Year Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "yearDetail",
      isRootLevelKey: true,
      isMandatory: true,
    }
  ],
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Year Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "yearDetailId",
      masterName: "yearDetail",
      "isRootLevelKey": true,
      "isMandatory": true
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "name",
      dataKey: "name",
      "isMandatory": true
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "sortNo",
      tableDataKey: 'sortNo',
      dataKey: "sortNo",
      isMandatory: true
    },
  ],
};
