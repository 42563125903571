import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../../../components/Comman/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function Row({
  row,
  fromFeesList,
  feeHeadList,
  rowDetailEdit,
  rowDetailStatus,
  rowStatus,
  rowDelete,
  rowEdit,
  rowCopy,
  yearDetailsList,
}) {
  const [open, setOpen] = React.useState(false);

  const changeStatus = (e, id, status) => {
    e.stopPropagation();
    rowStatus(id, status);
  };
  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };

  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };

  const onClickCopy = (e, id) => {
    e.stopPropagation();
    rowCopy(id);
  };

  React.useEffect(() => {});
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="td" scope="row">
          <center> {row.index}</center>
        </TableCell>
        <TableCell>{row.yearDetailName}</TableCell>
        <TableCell>{row.academicYearName}</TableCell>
        <TableCell>{row.categoryName}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell align="right">{row.minimumFees}</TableCell>
        <TableCell align="right">{row.totalFees}</TableCell>
        <TableCell align="center">{row.scholarship}</TableCell>

        <TableCell>
          {row.isActive === 1 ? (
            <center>
              {" "}
              <ChipCompo
                label="Active"
                variant="outlined"
                size="small"
                color="success"
                onClick={(e) => changeStatus(e, row.id, row.isActive)}
              />
            </center>
          ) : (
            <center>
              <ChipCompo
                label="Inactive"
                variant="outlined"
                size="small"
                color="error"
                onClick={(e) => changeStatus(e, row.id, row.isActive)}
              />
            </center>
          )}
        </TableCell>
        <TableCell align="right">
          <MyComponentWithIconProps
            statusImage={EditIcon}
            color="primary"
            fontSize="medium"
            title="Edit"
            onClick={(e) => onClickEdit(e, row.id)}
          />

          <MyComponentWithIconProps
            statusImage={DeleteIcon}
            color="error"
            fontSize="medium"
            title="Delete"
            onClick={(e) => onClickDelete(e, row.id)}
          />
          <MyComponentWithIconProps
            statusImage={ContentCopyIcon}
            color="primary"
            fontSize="medium"
            title="Copy Structure"
            onClick={(e) => onClickCopy(e, row.id)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <center>Sr. No.</center>
                    </TableCell>
                    <TableCell>Fees Head</TableCell>
                    {fromFeesList.map((historyRow, index) => (
                      <TableCell> {historyRow.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feeHeadList.map((feeHeadData, index) => {
                    return (
                      <TableRow key={feeHeadData.id}>
                        <TableCell component="td" scope="row">
                          <center> {index + 1}</center>
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {feeHeadData.name}
                        </TableCell>
                        {fromFeesList.map((historyRow, index) => {
                          return row.feesStructureDetails.map(
                            (feesHead, index) => {
                              if (
                                historyRow.id === feesHead.fromFeesId &&
                                feesHead.feesHeadId === feeHeadData.id
                              ) {
                                return (
                                  <TableCell component="td" scope="row">
                                    {feesHead.amt}
                                  </TableCell>
                                );
                              }
                            }
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CollapsibleTable({
  rowAdd,
  rowDetailEdit,
  rowDetailDelete,
  rowDetailStatus,
  rowStatus,
  rowDelete,
  rowEdit,
  fromFeesList,
  feeHeadList,
  yearDetailsList,
  data,
  rowCopy,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Sr. No.</TableCell>
            <TableCell align="center">Year Details</TableCell>
            <TableCell align="center">Academic Year </TableCell>
            <TableCell align="center">Category</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Minimum Payment</TableCell>
            <TableCell align="center">Total Fees</TableCell>
            <TableCell align="center">Scholarship</TableCell>

            <TableCell align="center">Status</TableCell>
            <TableCell align="center" width="100">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row.id}
              row={row}
              rowEdit={rowEdit}
              rowCopy={rowCopy}
              rowAdd={rowAdd}
              rowStatus={rowStatus}
              rowDelete={rowDelete}
              rowDetailStatus={rowDetailStatus}
              rowDetailEdit={rowDetailEdit}
              rowDetailDelete={rowDetailDelete}
              fromFeesList={fromFeesList}
              feeHeadList={feeHeadList}
              yearDetailsList={yearDetailsList}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
