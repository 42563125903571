import { FormHelperText, Grid, Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import CheckBoxSelectionTable from "../../../components/Comman/RT/CheckBoxSelectionTable";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";
import { getYearDetails } from "../../InternshipDeclaration/InternshipDeclataration.slice"; // import AccountFees from "../AccountFees/index";
import { getStudentListForAlumniRegistration } from "./AdminAddAlumni.slice";

const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Student Name",
    name: "studentName",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
];
class AdminAddStunentAsAlumni extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    setList: [],
    formErrors: [],
    rows: [],
    isError: false,
  };

  componentDidMount() {
    const { getYearDetails, getAlert } = this.props;

    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { yearDetail } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  saveHandler = (RollNoList) => {
    const { saveRollNoList } = this.props;
    const { semisterDetail } = this.state;
    const saveToData = RollNoList.map((data) => {
      let saveData = {
        ...data,
        semisterDetail: {
          id: semisterDetail,
        },
        dateOfReporting: moment(data.dateOfReporting).format("DD-MM-YYYY"),
      };
      return saveData;
    });
    apiPost({
      url: endpoint.studentRollList,
      postBody: saveToData,
    }).then(({ success }) => {
      if (success) {
        window.location.replace("/roll-list");
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
    // saveRollNoList({rollNoList:saveToData})
  };

  getRow = (e) => {
    this.setState({ setList: e });
  };
  searchHandler = () => {
    const { getStudentListForAlumniRegistration, getAlert } = this.props;
    const { yearDetail } = this.state;
    if (this.handleFormValidation()) {
      getStudentListForAlumniRegistration({
        yearDetailId: yearDetail,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            rows: this.props.studentListForAlumniRegistrationList
              ?.studentListForAlumniRegistration,
          });
        }
      });
    }
  };

  submitHandler = () => {
    const { setList } = this.state;
    if (setList.length !== 0) {
      this.setState({
        isError: false,
      });
      swal({
        title: "Are you sure?",
        text: "Do you want to convert students as a alumni?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          apiGet({
            url:
              endpoint.alumniRegister +
              "/student-to-alumni?userRegistrationIds=" +
              setList,
          }).then(({ success }) => {
            if (!success) {
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              window.location.replace("/admin-add-stunent-as-alumni");
            }
          });
        }
      });
    } else {
      this.setState({
        isError: true,
      });
    }
  };
  render() {
    const { programTitle, semisterDetail, yearDetail, sortOrder } = this.state;
    const {
      semisterDetailError,
      sortOrderError,
      programTitleError,
      yearDetailError,
    } = this.state.formErrors;
    const { internshipDeclarationList, rollNoData, studentRollNoList } =
      this.props;
    return (
      <>
        <div>
          <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid sx={{ marginBottom: 2 }} item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Admin Add Alumna"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
                sx={{ pl: 1 }}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={internshipDeclarationList?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Search"
                      onClick={this.searchHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CheckBoxSelectionTable
                  rows={
                    this.props.studentListForAlumniRegistrationList
                      ?.studentListForAlumniRegistration
                  }
                  columns={columns}
                  selection={this.state.setList}
                  isActionColActive={false}
                  onSelectionChange={this.getRow}
                />
              </Grid>
              {this.state.isError && (
                <FormHelperText error>
                  {"Select Atleast One Student "}
                </FormHelperText>
              )}
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={3}
                xs={12}
                sm={12}
                md={12}
              >
                <Grid
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ marginTop: 2 }}
                >
                  <Grid
                    container
                    justifyContent="flex-end"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Proceed"
                      onClick={this.submitHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  studentListForAlumniRegistrationList: state.studentListForAlumniRegistration,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getStudentListForAlumniRegistration,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAddStunentAsAlumni);
