import { Grid, Paper } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { downloadF } from "../../PDF/PDFDownload";
import {
  getStudentListByDivisionID,
  getStudentListByDivisionIdAndTeacher,
  getDistinctSubject,
  getDistinctTeacher,
  getDivisionListByStudentFeedbackId,
} from "./feedback.slice";
import { getDivisionByEventId } from "../../Event/Event Management/event.slice.js";
import { FontAwesomeIconCompo } from "../../../components/Comman/IconButton";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "studentName",
    title: "Student Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "rollNo",
    title: "Roll No",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
    width: "15%",
  },
  {
    name: "emailId",
    title: "E-mail",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "feedbackDate",
    title: "FeedBack Date",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
  },

  {
    name: "mobile",
    title: "Mobile",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];
const isSubjectcolumns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "studentName",
    title: "Student Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "rollNo",
    title: "Roll No",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "15%",
  },
  {
    name: "emailId",
    title: "E-mail",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "subject",
    title: "Subject",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },

  {
    name: "feedbackDate",
    title: "FeedBack Date",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },

  {
    name: "mobile",
    title: "Mobile",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];
class ViewDeclaredStudentFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      searchValue: "",
      isLoading: false,
      department: "",
      fromDate: "",
      toDate: "",
      departmentList: [],
      editData: {},
      formErrors: [],
      division: "",
      teacher: "",
      subject: "",
      showEmpFeedBack: false,
      studFeedBack: {},
    };
  }

  componentDidMount = () => {
    const { applieddata, getDivisionByEventId, getAlert } = this.props;
    if (applieddata !== null && applieddata !== undefined) {
      if (Object.keys(applieddata).length !== 0) {
      }
    }
  };

  handleFormValidation() {
    const { division, teacher, subject } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (
      this.props.applieddata !== undefined &&
      this.props.applieddata.isSubject == 0 &&
      this.props.applieddata.eventId !== ""
    ) {
      if (division === "" || division === null) {
        formIsValid = false;
        formErrors["divisionError"] = myConstClass.divisionNameMsg;
      }
    }
    if (
      this.props.applieddata !== undefined &&
      this.props.applieddata.isSubject == 1
    ) {
      if (division === "" || division === null) {
        formIsValid = false;
        formErrors["divisionError"] = myConstClass.divisionNameMsg;
      }
      if (teacher === "" || teacher === null) {
        formIsValid = false;
        formErrors["teacherError"] = myConstClass.teacherNameMsg;
      }
      if (subject === "" || subject === null) {
        formIsValid = false;
        formErrors["subjectError"] = myConstClass.subjectMsg;
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
    const { applieddata, getAlert } = this.props;
    const { division, teacher } = this.state;
    if (this.handleFormValidation()) {
      if (applieddata !== undefined && applieddata.isSubject == 0) {
        this.props
          .getStudentListByDivisionID({
            studentFeedbackId: applieddata !== undefined && applieddata.id,
            divisionId: division,
          })
          .then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props
          .getStudentListByDivisionIdAndTeacher({
            divisionId: division,
            teacherId: teacher,
            studentFeedbackId: applieddata !== undefined && applieddata.id,
          })
          .then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
      }
    }
  };

  rowDetails = (row) => {
    this.setState({
      showEmpFeedBack: true,
      studFeedBack: row,
      showTable: false,
    });
    console.log(row);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.departments.department,
          { id: "0", name: "All" },
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue != null) {
      this.setState({ [name]: newValue });

      if (name === "division" && this.state.teacher !== "") {
        this.props
          .getDistinctSubject({
            divisionId: newValue,
            teacherId: this.state.teacher,
            feedbackId:
              this.props.applieddata !== undefined && this.props.applieddata.id,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }

      if (name === "division" && this.props.applieddata.isSubject == 1) {
        this.props
          .getDistinctTeacher({
            divisionId: newValue,
            id:
              this.props.applieddata !== undefined && this.props.applieddata.id,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
      if (name === "teacher" && this.state.division !== "") {
        this.props
          .getDistinctSubject({
            divisionId: this.state.division,
            teacherId: newValue,
            feedBackId:
              this.props.applieddata !== undefined && this.props.applieddata.id,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };
  generatePDF = () => {
    const { division, teacher, subject } = this.state;
    if (this.handleFormValidation()) {
      const feedbackId =
        this.props.applieddata.id !== undefined && this.props.applieddata.id;
      downloadF({
        url:
          this.props.applieddata.isSubject == 0
            ? "/api/report/v1/feedback-report/generate-student-report?feedbackdeclareId=" +
              feedbackId
            : "/api/report/v1/feedback-report/generate-student-report?feedbackdeclareId=" +
              feedbackId +
              "&divisionId=" +
              division +
              "&subjectDetailId=" +
              subject +
              "&teacherId=" +
              teacher,
        ext: "pdf",
        openNewTab: true,
      });
    }
  };
  onClosedForm = () => {
    if (this.state.showEmpFeedBack) {
      this.setState({
        showEmpFeedBack: false,
        empFeedBack: {},
      });
    } else {
      this.props.onCancel();
    }
  };
  render() {
    const { division, teacher, subject, showEmpFeedBack } = this.state;
    const { divisionError, teacherError, subjectError } = this.state.formErrors;
    const { applieddata } = this.props;
    console.log(applieddata);
    return (
      <>
        <Paper sx={{ borderRadius: 2 }} elevation={0}>
          {!showEmpFeedBack && (
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  {applieddata !== undefined && applieddata.isSubject == 1 && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5.5}
                        lg={5.5}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Division"
                          />
                        </Grid>
                        {console.log(applieddata)}
                        <Grid item xs={12} sm={12} md={12}>
                          <AutoComplete
                            keyColName={"id"}
                            value={division}
                            name={"division"}
                            options={
                              this.props.studentFeedback
                                ?.divisionListByStudentFeedbackId
                            }
                            onChange={this.ChangeHandlerSearch}
                            isError={divisionError ? true : false}
                            errorText={divisionError ? divisionError : " "}
                          />
                        </Grid>
                      </Grid>
                      {applieddata !== undefined &&
                      applieddata.isSubject == 0 ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={6.5}
                            lg={6.5}
                            columnSpacing={1}
                            sx={{ marginTop: 3 }}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              {" "}
                              <ButtonCompo
                                size="medium"
                                type="button"
                                variant="contained"
                                name="Search"
                                onClick={this.submitHandler}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          md={6.5}
                          lg={6.5}
                          columnSpacing={1}
                          sx={{ marginTop: 3 }}
                          container
                          justifyContent="space-evenly"
                        ></Grid>
                      )}
                    </>
                  )}
                  {applieddata !== undefined && applieddata.eventId !== "" && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5.5}
                        lg={5.5}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Division"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <AutoComplete
                            keyColName={"id"}
                            value={division}
                            name={"division"}
                            options={this.props.eventList.divisionByEvent}
                            onChange={this.ChangeHandlerSearch}
                            isError={divisionError ? true : false}
                            errorText={divisionError ? divisionError : " "}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={6.5}
                        lg={6.5}
                        columnSpacing={1}
                        sx={{ marginTop: 3 }}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          {" "}
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Search"
                            onClick={this.submitHandler}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                {applieddata !== undefined && applieddata.isSubject == 1 && (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={5.5}
                        lg={5.5}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Teacher"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <AutoComplete
                            keyColName={"id"}
                            value={teacher}
                            name={"teacher"}
                            options={
                              this.props.studentFeedback?.distinctTeacher
                                ? this.props.studentFeedback?.distinctTeacher
                                : []
                            }
                            onChange={this.ChangeHandlerSearch}
                            isError={teacherError ? true : false}
                            errorText={teacherError ? teacherError : " "}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5.5}
                        lg={5.5}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Subject"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <AutoComplete
                            keyColName={"id"}
                            value={subject}
                            name={"subject"}
                            options={
                              this.props.studentFeedback?.distinctSubject
                                ? this.props.studentFeedback?.distinctSubject
                                : []
                            }
                            onChange={this.ChangeHandlerSearch}
                            isError={subjectError ? true : false}
                            errorText={subjectError ? subjectError : " "}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={1}
                        lg={1}
                        sx={{ marginTop: 3 }}
                        container
                        justifyContent="flex-end"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Search"
                            onClick={this.submitHandler}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={5}
                        lg={5}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid>
                <DynamicTable
                  data={
                    applieddata !== undefined &&
                    applieddata.isSubject == 0 &&
                    applieddata.eventId == ""
                      ? this.props.studentFeedback?.internalStudentData
                      : applieddata !== undefined &&
                        applieddata.isSubject == 1 &&
                        applieddata.eventId == ""
                      ? this.props.studentFeedback
                          ?.studentByDivisionIdAndTeacher
                      : applieddata !== undefined &&
                        applieddata.isSubject == 0 &&
                        applieddata.eventId !== ""
                      ? this.props.studentFeedback?.studentDataByDivision
                      : []
                  }
                  tableHead={
                    applieddata !== undefined && applieddata.isSubject == 1
                      ? isSubjectcolumns
                      : columns
                  }
                  showPegination={false}
                  showDetails={true}
                  rowDetails={this.props.rowDetails}
                />
              </Grid>
              <br />
              {this.props.value == 0 && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Generate PDF"
                        onClick={this.generatePDF}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYear: state.academicYear,
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
  noticeList: state.empNotice,
  studentFeedback: state.studentFeedback,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getStudentListByDivisionID,
  getStudentListByDivisionIdAndTeacher,
  getDistinctSubject,
  getDistinctTeacher,
  downloadF,
  getDivisionByEventId,
  getDivisionListByStudentFeedbackId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDeclaredStudentFeedback);
