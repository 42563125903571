import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  studentListForAlumniRegistration: [],
  isStudentListForAlumniRegistrationFetch: false,
};
let URL = endpoints.studentList;
const studentListForAlumniRegistrationSlice = createSlice({
  name: "studentListForAlumniRegistrationSlice",
  initialState,
  reducers: {
    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentListForAlumniRegistration: row,
        isStudentListForAlumniRegistrationFetch: true,
      };
    },
  },
});

export const { studentListSuccess } =
  studentListForAlumniRegistrationSlice.actions;

export default studentListForAlumniRegistrationSlice.reducer;

//acdmc/v1/user-registration/student-list-for-alumni-registration parameter "yearDetailId"

export const getStudentListForAlumniRegistration =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/student-list-for-alumni-registration?yearDetailId=" +
          yearDetailId,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
