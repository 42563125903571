import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  program: [],
  isProgFetch: false,
  yearList: [],
  isYearListFetch: false,
};
let URL = endpoints.program;
let yearListURL = endpoints.yearList;
const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    programSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        program: row,
        isProgFetch: true,
      };
    },

    yearListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearList: row,
        isYearListFetch: false,
      };
    },
  },
});

export const { programSuccess, yearListSuccess } = programSlice.actions;

export default programSlice.reducer;

export const getProgram = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(programSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getYearListByProgramId =
  ({ progId }) =>
  async (dispatch) => {
    try {
      console.log(progId);
      const response = apiGet({
        url: yearListURL + "?programId=" + progId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          let index = 0;
          let row = [];
          data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.year.programType.name +
                " - " +
                data1.programTitle.brName +
                " - " +
                data1.year.className,
              isActive: data1.isActive,
              progShortName: data1.programTitle.brShortName,
              courseCode: data1.programTitle.courseCode,
            };
            row.push(bData);
            return data1;
          });
          dispatch(yearListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
