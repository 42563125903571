import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
// import { useNavigate } from "react-router";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import {
  getFessDeatilsPreLogin,
  getSubjectSelection,
} from "../AdmissionForm/admissionForm.slice";
import QualificationTable from "./InstituteTable";
let columns = [
  {
    name: "index",
    title: "Sr.no ",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },

  {
    name: "documentName",
    title: "Document Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Actions",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
];
const QualificationDetails = ({
  studentList,
  getQualiDetails,
  getBackToAddress,
  getFessDeatilsPreLogin,
  getSubjectSelection,
  getAlert,
}) => {
  // const navigate = useNavigate();

  useEffect(() => {
    // setRow([]);
  }, []);

  useEffect(() => {
    // if(loginUser.token){
    //   localStorage.removeItem("token");
    //   navigate("/login");
    //   logOut()
    // }
  });

  const documentData = (data) => {};
  const QualiDetails = () => {
    if (studentList?.keySuccess.value === 1) {
      getSubjectSelection({
        yearDetailId: studentList.admissionForm.yearDetailId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        } else {
          getQualiDetails();
        }
      });
    } else {
      getFessDeatilsPreLogin({
        domainName: window.location.host,
        categoryId: studentList.admissionForm.categoryId,
        scholarship: studentList.admissionForm.scholarship === "yes" ? 1 : 0,
        yearDetailId: studentList.admissionForm.yearDetailId,
        academicYearId: studentList.academicYear.id,
      }).then((response) => {
        if (response !== null) {
          getQualiDetails();
        } else {
          getAlert({ message: "Contact to collage" });
        }
      });
    }
  };
  const backToAddress = () => {
    getBackToAddress();
  };

  return (
    <>
      <div>
        <br />
        {/* {applicationList.isServerFail && <Alert msg={myConstClass.serverMsg} closeAlert={resetDelete}/>} */}
        <Grid>
          <div>
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 20 }}
                    label="Qualification Details :"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Box sx={{ mt: 3 }}>
                    <QualificationTable
                      rows={studentList?.admissionForm.userQualificationDetails}
                      columns={[
                        { label: "Sr. No.", id: "index" },
                        { label: "University", id: "university" },
                        { label: "State", id: "board" },
                        { label: "Qualification", id: "qualification" },
                        {
                          label: "School / College",
                          id: "nameOfSchoolCollege",
                        },
                        { label: "Year Of Passing", id: "yearOfPass" },
                        { label: "Marks Obtained", id: "marksObtained" },
                        { label: "Marks OutOff", id: "marksOutof" },
                        { label: "Percentage", id: "percentage" },
                      ]}
                      columnBands={[
                        {
                          title: "Aggregate Marks",
                          children: [
                            { columnName: "marksOutof" },
                            { columnName: "marksObtained" },
                          ],
                        },
                      ]}
                      // textfieldData={textfieldData}
                      //tableData={tableData}
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <br />

              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid item xs={12} sm={12} md={12} />

              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Document Details :"
                />
              </Grid>
              <Box sx={{ mt: 3 }}>
                <DynamicTable
                  data={studentList?.admissionForm.userDocumentDetails}
                  tableHead={columns}
                  showView={true}
                  showPegination={false}
                  rowViewData={documentData}
                />
              </Box>
            </Box>
            <br />
            <br />
            <div>
              <div align="right">
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  onClick={backToAddress}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonCompo
                  size="button"
                  type="button"
                  variant="contained"
                  name="  Save and Proceed"
                  onClick={QualiDetails}
                />
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  studentList: state.admissionForm,
});

const mapDispatchToProps = {
  getFessDeatilsPreLogin,
  getSubjectSelection,
  getAlert,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualificationDetails);
