import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import SearchStudAddCompanyList from "../../components/SearchTable";
import {
  getInterestedStudById,
  getInterestedStudentById,
  resetValues,
} from "../ListOfCompany/CompanyList.slice";
import { deleteFileData } from "../UploadFile/file.slice";
import StudentAddInternship from "./StudentAddInternship";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import { listOfInternshipDeclareAddStudentJson } from "../../DynamicFormsJson/ListOfInternshipAddStudentDynamic";

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
    canSearch: true,
  },
  {
    name: "companyName",
    title: "Company Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "25%",
    canSearch: true,
  },
  {
    name: "companyAddress",
    title: "Company Address",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "30%",
    canSearch: true,
  },
  {
    name: "durationFromDate",
    title: "From Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
    canSearch: true,
  },
  {
    name: "durationToDate",
    title: "To Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
    width: "20%",
  },
  {
    name: "designation",
    title: "Designation",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
    width: "20%",
  },
  {
    name: "status",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    canSearch: true,
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "15%",
  },
];
const StudentDeclareInternshipList = ({
  onCancel,
  companyData,
  getInterestedStudentById,
  getAlert,
  getIntrestedStudListById,
  resetValues,
  deleteFileData,
  searchButton = false,
  getInterestedStudById,
}) => {
  const [updateStudInfo, setUpdateStudInfo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [interestedStudent, setInterestedStudent] = useState(true);
  const [studData, setStudData] = useState({});
  const [fieldData, setFieldData] = useState({});
  const [dynamicMasterData, setDynamicMasterData] = useState({
    internshipStatusMaster: [
      {
        id: "1",
        name: "Applied",
      },
      {
        id: "0",
        name: "Declared",
      },
      {
        id: "2",
        name: "Off Campus",
      },
    ],
  });
  const [searchValue, setSearchValue] = useState("");
  const [role, setRole] = useState("");

  const editStudentInfo = (data) => {
    getInterestedStudById({ id: data.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    setStudData(data);
    setUpdateStudInfo(true);
    setInterestedStudent(false);
  };

  const openDeclareForm = () => {
    // resetSaveDeclare()
    setUpdateStudInfo(true);
    setInterestedStudent(false);
    // window.location.replace("/onlineappprocess");
  };

  const UpdateInfoData = () => {
    setUpdateStudInfo(false);
    setStudData({});
    setInterestedStudent(true);
    resetValues();
    deleteFileData();
    const parentChildId = localStorage.getItem("parentChildId");
    getInterestedStudentById({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
      status: 7,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    let role = localStorage.getItem("role");
    setRole(role);
    const parentChildId = localStorage.getItem("parentChildId");
    console.log(parentChildId + " parentChildId");
    setShowLoader(true);
    getInterestedStudentById({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
      status: 7,
    }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
  }, []);

  const getFilteredTableData = () => {
    return getIntrestedStudListById?.getInterestedStudentByIdStatus.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["companyName"] &&
            currentRow["companyName"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["companyAddress"] &&
            currentRow["companyAddress"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["durationFromDate"] &&
            currentRow["durationFromDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["durationToDate"] &&
            currentRow["durationToDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["designation"] &&
            currentRow["designation"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };

  const getListById = (data) => {
    const parentChildId = localStorage.getItem("parentChildId");
    setShowLoader(true);
    getInterestedStudentById({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
      status: data.internshipStatus,
    }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
  };

  const onDataChange = (fieldName, newValue) => {
    fieldData[fieldName] = newValue;
    setFieldData(fieldData);
  };

  return (
    <>
      {interestedStudent && (
        <>
          <DynamicMainScreen
            onAddButtonClick={openDeclareForm}
            screenTitle={listOfInternshipDeclareAddStudentJson.screenTitle}
            fieldMeta={listOfInternshipDeclareAddStudentJson.fieldMeta}
            buttonCenter={listOfInternshipDeclareAddStudentJson.buttonCenter}
            showPdfDownload={
              listOfInternshipDeclareAddStudentJson.showPdfDownload
            }
            showExcelDownload={
              listOfInternshipDeclareAddStudentJson.showExcelDownload
            }
            // pdfFileName={listOfInternshipDeclareAddStudentJson.pdfFileName}
            // excelFileName={listOfInternshipDeclareAddStudentJson.excelFileName}
            tableColumnsToFilter={
              listOfInternshipDeclareAddStudentJson.tableColumnsToFilter
            }
            showAddButton={listOfInternshipDeclareAddStudentJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={columns}
            isActionColActive={role === "parent" ? false : true}
            tableData={getIntrestedStudListById?.getInterestedStudentByIdStatus}
            showLoader={showLoader}
            showPegination={true}
            showHeadEdit={true}
            showHeadDelete={false}
            showAppliedList={false}
            rowEdit={editStudentInfo}
            onDataChange={onDataChange}
            fieldData={fieldData}
            getListById={getListById}
            // showPegination={false}
            // pdfDetailsId={this.pdfDetails}
            // rowViewData={this.onClickView}
            // showLinkIcon={true}
            // generateDetails={generateDetails}
            // rowDelete={this.rowDelete}
            // rowEdit={this.rowEdit}
            searchButton={searchButton}
            // rowStatus={this.onChangeStatus}
            // rowAppliedList={this.appliedList}
            notes={listOfInternshipDeclareAddStudentJson.notes}
            showNotes={true}
            baseIdColumn={listOfInternshipDeclareAddStudentJson.baseIdColumn}
            apiBaseURL={listOfInternshipDeclareAddStudentJson.apiBaseURL}
          />
        </>
      )}
      {updateStudInfo && (
        <StudentAddInternship goBack={UpdateInfoData} studentData={studData} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getIntrestedStudListById: state.companyList,
});

const mapDispatchToProps = {
  deleteFileData,
  getInterestedStudentById,
  getAlert,
  getInterestedStudById,
  resetValues,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentDeclareInternshipList);

// {/* <div>
//           <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
//             <Grid
//               item
//               sm={12}
//               xs={12}
//               md={12}
//               lg={12}
//               rowSpacing={2}
//               columnSpacing={1}
//               container
//               justifyContent="space-evenly"
//             >
//               <Grid item xs={12} sm={6} md={6}>
//                 <LabelCompo
//                   className="text-black"
//                   style={{ fontSize: 22 }}
//                   label="List of Internship Declared"
//                 />
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 sm={6}
//                 md={6}
//                 container
//                 justifyContent="flex-end"
//               >
//                 <MyComponentWithIconProps
//                   statusImage={PersonAddAlt1Icon}
//                   color="primary"
//                   fontSize="large"
//                   title="Add Student"
//                   onClick={openDeclareForm}
//                 />
//               </Grid>
//             </Grid>

//             <Grid
//               sx={{ marginTop: 1, marginBottom: 2 }}
//               item
//               xs={12}
//               sm={12}
//               md={12}
//             >
//               <SearchStudAddCompanyList onSearch={onSearch} />
//             </Grid>
//             <Grid item sx={{ mr: 1 }} xs={12} sm={12} md={12}>
//               <DynamicTable
//                 data={getFilteredTableData()}
//                 tableHead={columns}
//                 showPegination={true}
//                 rowEdit={editStudentInfo}
//                 showHeadEdit={true}
//               />
//             </Grid>
//           </Paper>
//         </div> */}
