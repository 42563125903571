import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  unitTest: [],
  isFetch: false,
  unitTestList: [],
  isFetchUnitTestList: false,
  unitTestListTeacher: [],
  isFetchUnitTestListTeacher: false,
  unitTestStudent: [],
  isFetchStudentUnit: false,
  unitTestTeacherFillMarks: [],
  isFetchTeacherFillMarks: false,
  unitTestById: {},
  isFetchById: false,
};
let URL = endpoints.unitTest;
let UnitTestDeclare = endpoints.unitTestDeclare;
let StudentUnitURL = endpoints.studentUnitTest;
const unitTestSlice = createSlice({
  name: "unitTest",
  initialState,
  reducers: {
    unitTestSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        unitTest: row,
        isFetch: true,
      };
    },

    unitTestTeacherFillMarksSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        unitTestTeacherFillMarks: row,
        isFetchTeacherFillMarks: true,
      };
    },

    unitTestStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        unitTestStudent: row,
        isFetchStudentUnit: true,
      };
    },

    unitTestListByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        unitTestById: row,
        isFetchById: true,
      };
    },

    unitTestListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        unitTestList: row,
        isFetchUnitTestList: true,
      };
    },

    unitTestListTeacherSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        unitTestListTeacher: row,
        isFetchUnitTestListTeacher: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        unitTestList: [],
        isFetchUnitTestList: false,
        unitTestListTeacher: [],
        isFetchUnitTestListTeacher: false,
      };
    },

    resetSuccessById: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        unitTestById: {},
        isFetchById: false,
      };
    },
  },
});

export const {
  unitTestSuccess,
  unitTestListSuccess,
  resetSuccess,
  unitTestListByIdSuccess,
  resetSuccessById,
  unitTestListTeacherSuccess,
  unitTestStudentSuccess,
  unitTestTeacherFillMarksSuccess,
} = unitTestSlice.actions;

export default unitTestSlice.reducer;

export const getUnitTest = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(unitTestSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const UnitTestTeacherFillMarks =
  ({ id, batchId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          StudentUnitURL +
          "/get-student-list-for-filling-marks-by-teacher?studentUnitTestHeaderId=" +
          id +
          "&batchId=" +
          batchId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              dateOfReporting: data1.dateOfReporting,
              divisionId: data1.divisionId,
              firstName:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
              id: data1.id,
              prnNo: data1.prnNo,
              rollNo: data1.rollNo,
              userRegistrationId: data1.userRegistrationId,
            };
            return bData;
          });
          dispatch(unitTestTeacherFillMarksSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUnitTestStudent =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          StudentUnitURL +
          "/show-marks-for-student?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              headName: data1.headName,
              id: data1.id,
              rollNo: data1.rollNo,
              studentName: data1.studentName,
              unitTestName: data1.unitTestName,
              subjectName: data1.subjectName + " " + data1.headName,
              maxMarks: data1.maxMarks,
              marks: data1.marks,
              date: data1.date,
              userRegistrationId: data1.userRegistrationId,
            };
            return bData;
          });
          dispatch(unitTestStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUnitTestStudentWithoutFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: StudentUnitURL + "/show-marks-for-student-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            headName: data1.headName,
            id: data1.id,
            rollNo: data1.rollNo,
            studentName: data1.studentName,
            unitTestName: data1.unitTestName,
            subjectName: data1.subjectName + " " + data1.headName,
            maxMarks: data1.maxMarks,
            marks: data1.marks,
            date: data1.date,
            userRegistrationId: data1.userRegistrationId,
          };
          return bData;
        });
        dispatch(unitTestStudentSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// http://localhost:8088/api/acdmc/v1/student-unit-test-header/get-student-unit-test-header-by-semisterDetail-and-division?semisterDetailId=673e30fc-777c-42fb-8fe7-e211f5f1a279&divisionId=d910e283-b645-426e-865e-040440651afc

export const getUnitTestDetailsBySemesterAndDivisionId =
  ({ semesterId, divisionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          UnitTestDeclare +
          "/get-student-unit-test-header-by-semisterDetail-and-division?semisterDetailId=" +
          semesterId +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              semister: data1.semisterDetail.id,
              division: data1.division.id,
              subject:
                data1.getSubject === null ? "" : data1.getSubject.subject.id,
              subjectName:
                data1.getSubject === null ? "" : data1.getSubject.subject.name,
              unitTestHead: data1.unitTestHead.name,
              semisterName: data1.semisterDetail.semister.name,
              unitTest: data1.unitTestHead.id,
              maxMarks: data1.maxMarks,
              date: data1.date,
              yearDetail: data1.division.yearDetailId,
              isActive: data1.isActive,
              declareById: data1.declareBy.id,
              declareBy:
                data1.declareBy === null
                  ? ""
                  : data1.declareBy.lastName +
                    " " +
                    data1.declareBy.firstName +
                    " " +
                    data1.declareBy.middleName,
              lastName:
                data1.declareBy === null ? null : data1.declareBy.lastName,
              middleName:
                data1.declareBy === null ? null : data1.declareBy.middleName,
              firstName:
                data1.declareBy === null ? null : data1.declareBy.firstName,
              studentList: data1.studentUnitTestDetails.map(
                (rowData, studunitindex) => {
                  let studData = {
                    index: studunitindex + 1,
                    fillMarkBy: rowData.fillMarkBy,
                    id: rowData.id,
                    reportingDetail:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail.id,
                    rollNo:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail.rollNo,
                    division:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail.division.id,
                    admissionRegistrationDetail:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail
                            .getAdmissionRegistrationDetail,
                    studentName:
                      rowData.userRegistration === null
                        ? ""
                        : rowData.userRegistration.lastName +
                          " " +
                          rowData.userRegistration.firstName +
                          " " +
                          (rowData.userRegistration.fatherName == null ||
                          rowData.userRegistration.fatherName == ""
                            ? " - "
                            : rowData.userRegistration.fatherName) +
                          " " +
                          (rowData.userRegistration.motherName == null ||
                          rowData.userRegistration.motherName == ""
                            ? " - "
                            : rowData.userRegistration.motherName),
                    userRegistrationId:
                      rowData.userRegistration === null
                        ? ""
                        : rowData.userRegistration.id,
                    marks: rowData.marks,
                  };
                  return studData;
                }
              ),
            };
            return bData;
          });
          dispatch(unitTestListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUnitTestDetailsTeacherBySemDivAndSub =
  ({ semesterId, divisionId, subjectId, batchId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          UnitTestDeclare +
          "/get-student-unit-test-header-by-semisterDetail-divisionId-subjectDetail?semiterDetailId=" +
          semesterId +
          "&divisionId=" +
          divisionId +
          "&subjectDetailId=" +
          subjectId +
          "&batchId=" +
          batchId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              semister: data1.semisterDetail.id,
              division: data1.division.id,
              declareType: data1.declareType,
              subjectDetailId:
                data1.subjectDetail === null ? "" : data1.subjectDetail.id,
              subject:
                data1.getSubject === null ? "" : data1.getSubject.subject.id,
              subjectName:
                data1.getSubject === null ? "" : data1.getSubject.subject.name,
              unitTestHead: data1.unitTestHead.name,
              semisterName: data1.semisterDetail.semister.name,
              unitTest: data1.unitTestHead.id,
              maxMarks: data1.maxMarks,
              date: data1.date,
              yearDetail: data1.division.yearDetailId,
              isActive: data1.isActive,
              declareById: data1.declareBy.id,
              declareBy:
                data1.declareBy === null
                  ? ""
                  : data1.declareBy.lastName +
                    " " +
                    data1.declareBy.firstName +
                    " " +
                    data1.declareBy.middleName,
              lastName:
                data1.declareBy === null ? null : data1.declareBy.lastName,
              middleName:
                data1.declareBy === null ? null : data1.declareBy.middleName,
              firstName:
                data1.declareBy === null ? null : data1.declareBy.firstName,
              studentList: data1.studentUnitTestDetails.map(
                (rowData, studunitindex) => {
                  let studData = {
                    index: studunitindex + 1,
                    fillMarkBy: rowData.fillMarkBy,
                    id: rowData.id,
                    reportingDetail:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail.id,
                    rollNo:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail.rollNo,
                    division:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail.division.id,
                    admissionRegistrationDetail:
                      rowData.reportingDetail === null
                        ? ""
                        : rowData.reportingDetail
                            .getAdmissionRegistrationDetail,
                    studentName:
                      rowData.userRegistration === null
                        ? ""
                        : rowData.userRegistration.lastName +
                          " " +
                          rowData.userRegistration.firstName +
                          " " +
                          (rowData.userRegistration.fatherName == null ||
                          rowData.userRegistration.fatherName == ""
                            ? " - "
                            : rowData.userRegistration.fatherName) +
                          " " +
                          (rowData.userRegistration.motherName == null ||
                          rowData.userRegistration.motherName == ""
                            ? " - "
                            : rowData.userRegistration.motherName),
                    userRegistrationId:
                      rowData.userRegistration === null
                        ? ""
                        : rowData.userRegistration.id,
                    marks: rowData.marks,
                  };
                  return studData;
                }
              ),
            };
            return bData;
          });
          dispatch(unitTestListTeacherSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUnitTestDetailsById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: UnitTestDeclare + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          let content = data.data;
          content = {
            ...content,
            studentUnitTestDetails: content.studentUnitTestDetails.map(
              (rowData) => {
                rowData = {
                  ...rowData,
                  marksValid: 0,
                };
                return rowData;
              }
            ),
            studentList: content.studentUnitTestDetails.map(
              (rowData, studListIndex) => {
                let studData = {
                  index: studListIndex + 1,
                  fillMarkBy: rowData.fillMarkBy,
                  id: rowData.id,
                  reportingDetail:
                    rowData.reportingDetail === null
                      ? ""
                      : rowData.reportingDetail.id,
                  rollNo:
                    rowData.reportingDetail === null
                      ? ""
                      : rowData.reportingDetail.rollNo,
                  division:
                    rowData.reportingDetail === null
                      ? ""
                      : rowData.reportingDetail.division.id,
                  admissionRegistrationDetail:
                    rowData.reportingDetail === null
                      ? ""
                      : rowData.reportingDetail.getAdmissionRegistrationDetail,
                  lastName:
                    rowData.userRegistration === null
                      ? null
                      : rowData.userRegistration.lastName,
                  middleName:
                    rowData.userRegistration === null
                      ? null
                      : rowData.userRegistration.middleName,
                  firstName:
                    rowData.userRegistration === null
                      ? null
                      : rowData.userRegistration.firstName,
                  motherName:
                    rowData.userRegistration === null
                      ? null
                      : rowData.userRegistration.motherName,
                  fatherName:
                    rowData.userRegistration === null
                      ? null
                      : rowData.userRegistration.fatherName,
                  name:
                    rowData.userRegistration === null
                      ? ""
                      : rowData.userRegistration.lastName +
                        " " +
                        rowData.userRegistration.firstName +
                        " " +
                        (rowData.userRegistration.fatherName == null ||
                        rowData.userRegistration.fatherName == ""
                          ? " - "
                          : rowData.userRegistration.fatherName) +
                        " " +
                        (rowData.userRegistration.motherName == null ||
                        rowData.userRegistration.motherName == ""
                          ? " - "
                          : rowData.userRegistration.motherName),
                  userRegistrationId:
                    rowData.userRegistration === null
                      ? ""
                      : rowData.userRegistration.id,
                  marks: rowData.marks,
                  marksValid: 0,
                };
                return studData;
              }
            ),
          };
          dispatch(unitTestListByIdSuccess({ row: content }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetValuesById = () => async (dispatch) => {
  try {
    dispatch(resetSuccessById());
  } catch (e) {
    return console.error(e.message);
  }
};
