import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, stubFalse } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  certificate: [],
  isFetchCertificate: false,

  certificateListOfStudent: [],
  isFetchCertificateListOfStudent: false,
};
let URL = endpoints.certificate;
let ListURL = endpoints.certList;

const StudentCertificateSlice = createSlice({
  name: "StudentCertificateSlice",
  initialState,
  reducers: {
    certificateListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        certificate: row,
        isFetchCertificate: true,
      };
    },
    certificateListOfStudentSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        certificateListOfStudent: row,
        isFetchCertificateListOfStudent: true,
      };
    },
  },
});

export const { certificateListSuccess, certificateListOfStudentSuccess } =
  StudentCertificateSlice.actions;

export default StudentCertificateSlice.reducer;

export const getCertificateList =
  ({ fromDate, toDate, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-certificate-application-by-from-to-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              applicationName:
                data1.certificateApplicationHeader.cetificate_name,
              date: data1.applyDate,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Verified"
                  : data1.status == 2
                  ? "Accepted"
                  : data1.status == 3
                  ? "Prepared"
                  : data1.status == 4
                  ? "Delivered"
                  : data1.status == 5
                  ? "Rejected"
                  : "",
              detail: data1.detail ? data1.detail : "-",
              adminRemark: data1.adminRemark ? data1.adminRemark : "-",
            };
            row.push(bData);
            return data1;
          });
          dispatch(certificateListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCertificateListFilter =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-certificate-application-by-limit?limit=20&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              applicationName:
                data1.certificateApplicationHeader.cetificate_name,
              date: data1.applyDate,
              status:
                data1.status == 0
                  ? "Pending"
                  : data1.status == 1
                  ? "Verified"
                  : data1.status == 2
                  ? "Accepted"
                  : data1.status == 3
                  ? "Prepared"
                  : data1.status == 4
                  ? "Delivered"
                  : data1.status == 5
                  ? "Rejected"
                  : "",
              detail: data1.detail ? data1.detail : "-",
              adminRemark: data1.adminRemark ? data1.adminRemark : "-",
            };
            row.push(bData);
            return data1;
          });
          dispatch(certificateListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCertificateListOfStudent = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: ListURL,
    }).then(({ data, success }) => {
      if (success) {
        const list = data.data.content;
        let index = 0;
        let row = [];
        list.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            // certificateId: data1.certificateApplicationHeader.id,
            certificateList: data1.cetificate_name,
            payment: data1.amount,
            // paymentApplicable: data1.is_payment_applicable,
            // mapping: data1.cetificate_mapping,
            type: data1.type,
          };
          row.push(bData);
          return data1;
        });
        dispatch(certificateListOfStudentSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
