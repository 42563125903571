import endpoint from "../config/endpoints";

export const AlumniPaperJson = {
  apiBaseURL: endpoint.volunteerUpdateStatus,
  screenTitle: "Be a Paper Setter/Evaluator/Examiner/Research Guide",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Select a Role",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "RoleMaster",
      dataKey: "interest",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area of Expertise",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expertise",
      isMandatory: true,
    },
    {
      label: "Academic Experience (In years)",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "message",
      isMandatory: true,
      inputType: "number",
      minNum: 0,
    },
    {
      label: "Industry Experience (In years)",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: true,
      inputType: "number",
      minNum: 0,
    },
  ],
};
