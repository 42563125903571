import { Checkbox, Divider, FormGroup, Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import { createFilterOptions } from "@mui/material/Autocomplete";
let docs;
class Address extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    perAddress1: "",
    perAddress2: "",
    perAddress3: "",
    pin: "",
    state: "",
    district: "",
    taluka: "",
    city: "",

    curAddress1: "",
    curAddress2: "",
    curAddress3: "",
    curpin: "",
    curstate: "",
    curdistrict: "",
    curtaluka: "",
    curcity: "",
    checkForm: 0,
    flag: 0,
    formErrors: [],
  };

  filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  changeHandler = (event) => {
    const { name, value, checked } = event.target;

    if (name === "checkForm") {
      const {
        id,
        perAddress1,
        perAddress2,
        perAddress3,
        taluka,
        district,
        city,
        state,
        pin,
      } = this.state;

      if (checked === true) {
        this.setState({
          ...this.state,
          [name]: 1,
          id: id,
          curAddress1: perAddress1,
          curAddress2: perAddress2,
          curAddress3: perAddress3,
          curtaluka: taluka,
          curcity: city,
          curstate: state,
          curpin: pin,
          curdistrict: district,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: 0,
          id: id,
          curAddress1: "",
          curAddress2: "",
          curAddress3: "",
          curtaluka: "",
          curcity: "",
          curstate: "",
          curpin: "",
          curdistrict: "",
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employee !==
        nextProps.employeeDetails.employee
      ) {
        this.setState({
          id: nextProps.employeeDetails.employee.id,
          perAddress1:
            nextProps.employeeDetails.employee.permanentAddressLine1 === null
              ? ""
              : nextProps.employeeDetails.employee.permanentAddressLine1,
          perAddress2:
            nextProps.employeeDetails.employee.permanentAddressLine2 === null
              ? ""
              : nextProps.employeeDetails.employee.permanentAddressLine2,
          perAddress3:
            nextProps.employeeDetails.employee.permanentAddressLine3 === null
              ? ""
              : nextProps.employeeDetails.employee.permanentAddressLine3,
          pin:
            nextProps.employeeDetails.employee.permanentPin === null
              ? ""
              : nextProps.employeeDetails.employee.permanentPin,
          state:
            nextProps.employeeDetails.employee.permanentState === null
              ? ""
              : nextProps.employeeDetails.employee.permanentState,
          district:
            nextProps.employeeDetails.employee.permanentDistrict === null
              ? ""
              : nextProps.employeeDetails.employee.permanentDistrict,
          taluka:
            nextProps.employeeDetails.employee.permanentTaluka === null
              ? ""
              : nextProps.employeeDetails.employee.permanentTaluka,
          city:
            nextProps.employeeDetails.employee.permanentCity === null
              ? ""
              : nextProps.employeeDetails.employee.permanentCity,
          curAddress1:
            nextProps.employeeDetails.employee.temporaryAddressLine1 === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryAddressLine1,
          curAddress2:
            nextProps.employeeDetails.employee.temporaryAddressLine2 === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryAddressLine2,
          curAddress3:
            nextProps.employeeDetails.employee.temporaryAddressLine3 === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryAddressLine3,
          curpin:
            nextProps.employeeDetails.employee.temporaryPin === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryPin,
          curstate:
            nextProps.employeeDetails.employee.temporaryState === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryState,
          curdistrict:
            nextProps.employeeDetails.employee.temporaryDistrict === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryDistrict,
          curtaluka:
            nextProps.employeeDetails.employee.temporaryTaluka === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryTaluka,
          curcity:
            nextProps.employeeDetails.employee.temporaryCity === null
              ? ""
              : nextProps.employeeDetails.employee.temporaryCity,
        });
      } else if (
        this.props.employeeDetails.employee !==
        nextProps.employeeDetails.employee
      ) {
        this.setState({
          id: nextProps.employeeDetails.employee.id,
        });
      }
    }
  }

  componentDidMount() {
    const { employeeDetails } = this.props;
    console.log(employeeDetails.isSave);
    if (Object.keys(employeeDetails.employee).length != 0) {
      this.setState({
        id: employeeDetails.employee.id,
        perAddress1:
          employeeDetails.employee.permanentAddressLine1 === null
            ? ""
            : employeeDetails.employee.permanentAddressLine1,
        perAddress2:
          employeeDetails.employee.permanentAddressLine2 === null
            ? ""
            : employeeDetails.employee.permanentAddressLine2,
        perAddress3:
          employeeDetails.employee.permanentAddressLine3 === null
            ? ""
            : employeeDetails.employee.permanentAddressLine3,
        pin:
          employeeDetails.employee.permanentPin === null
            ? ""
            : employeeDetails.employee.permanentPin,
        state:
          employeeDetails.employee.permanentState === null
            ? ""
            : employeeDetails.employee.permanentState,
        district:
          employeeDetails.employee.permanentDistrict === null
            ? ""
            : employeeDetails.employee.permanentDistrict,
        taluka:
          employeeDetails.employee.permanentTaluka === null
            ? ""
            : employeeDetails.employee.permanentTaluka,
        city:
          employeeDetails.employee.permanentCity === null
            ? ""
            : employeeDetails.employee.permanentCity,
        curAddress1:
          employeeDetails.employee.temporaryAddressLine1 === null
            ? ""
            : employeeDetails.employee.temporaryAddressLine1,
        curAddress2:
          employeeDetails.employee.temporaryAddressLine2 === null
            ? ""
            : employeeDetails.employee.temporaryAddressLine2,
        curAddress3:
          employeeDetails.employee.temporaryAddressLine3 === null
            ? ""
            : employeeDetails.employee.temporaryAddressLine3,
        curpin:
          employeeDetails.employee.temporaryPin === null
            ? ""
            : employeeDetails.employee.temporaryPin,
        curstate:
          employeeDetails.employee.temporaryState === null
            ? ""
            : employeeDetails.employee.temporaryState,
        curdistrict:
          employeeDetails.employee.temporaryDistrict === null
            ? ""
            : employeeDetails.employee.temporaryDistrict,
        curtaluka:
          employeeDetails.employee.temporaryTaluka === null
            ? ""
            : employeeDetails.employee.temporaryTaluka,
        curcity:
          employeeDetails.employee.temporaryCity === null
            ? ""
            : employeeDetails.employee.temporaryCity,
      });
    } else {
      this.setState({
        id: employeeDetails.employee.id,
      });
    }
  }
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const { perAddress1 } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (perAddress1.toString().trim() === "") {
      formIsValid = false;
      formErrors["perAddressErr"] = myConstClass.perAddressMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      curtaluka,
      curdistrict,
      curcity,
      curstate,
      curpin,
      taluka,
      district,
      city,
      state,
      pin,
    } = this.state;

    docs = {
      id: id,
      permanentAddressLine1: perAddress1,
      permanentAddressLine2: perAddress2,
      permanentAddressLine3: perAddress3,
      permanentPin: pin,
      permanentState: state,
      permanentDistrict: district,
      permanentTaluka: taluka,
      permanentCity: city,
      temporaryAddressLine1: curAddress1,
      temporaryAddressLine2: curAddress2,
      temporaryAddressLine3: curAddress3,
      temporaryPin: curpin,
      temporaryState: curstate,
      temporaryDistrict: curdistrict,
      temporaryTaluka: curtaluka,
      temporaryCity: curcity,
      type: 2,
    };
    console.log(docs);
    if (this.handleFormValidation()) {
      this.props.studAddressData(docs);
    }
  };
  render() {
    const {
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      taluka,
      city,
      district,
      state,
      pin,
      curtaluka,
      curcity,
      curdistrict,
      curstate,
      curpin,
    } = this.state;

    const { onCancel, employeeList, editForm } = this.props;
    console.log(employeeList);
    const { perAddressErr } = this.state.formErrors;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid columnSpacing={1} container justifyContent="space-evenly">
              <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                Permanent Address
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>

              {/*  */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ marginTop: 1 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Address Line"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="perAddress1"
                      size="small"
                      value={perAddress1}
                      disabled={editForm ? true : false}
                      onChange={this.changeHandler}
                      fullWidth
                      error={perAddressErr ? true : false}
                      helperText={perAddressErr ? perAddressErr : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}

              {/* <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Address Line"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress1"
                  size="small"
                  value={perAddress1}
                  onChange={this.changeHandler}
                  fullWidth
                  error={perAddressErr ? true : false}
                  helperText={perAddressErr ? perAddressErr : " "}
                />
              </Grid> */}

              {/*  */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ marginTop: -1 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=" Road"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="perAddress2"
                      size="small"
                      disabled={editForm ? true : false}
                      value={perAddress2}
                      onChange={this.changeHandler}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}

              {/* <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Road"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress2"
                  size="small"
                  value={perAddress2}
                  onChange={this.changeHandler}
                  fullWidth
                />
              </Grid> */}

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ marginTop: 2 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label=" Landmark"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="perAddress3"
                      size="small"
                      disabled={editForm ? true : false}
                      value={perAddress3}
                      onChange={this.changeHandler}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/*  */}
              {/* <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Landmark"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress3"
                  size="small"
                  value={perAddress3}
                  onChange={this.changeHandler}
                  fullWidth
                />
              </Grid> */}

              {/*  */}

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                sx={{ marginTop: 1 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Pincode"
                    />
                  </Grid>

                  <Grid sx={{ marginTop: -3 }} item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="pin"
                      size="small"
                      value={pin}
                      disabled={editForm ? true : false}
                      onChange={this.changeHandlerNumber}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="State"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={state}
                      name={"state"}
                      disabled={editForm ? true : false}
                      options={employeeList?.state.filter(
                        (data) => data.isActive == 1
                      )}
                      filterOptions={this.filterOptions}
                      onChange={this.ChangeHandlerSearch}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/*  */}

              {/* <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mt: 2, mb: -4 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="pin"
                    size="small"
                    value={pin}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={state}
                    name={"state"}
                    options={employeeList?.state}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid> */}

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={district}
                    name={"district"}
                    disabled={editForm ? true : false}
                    options={employeeList?.district.filter(
                      (district) =>
                        district.stid === state && district.isActive === 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Taluka"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={taluka}
                    name={"taluka"}
                    disabled={editForm ? true : false}
                    options={employeeList?.taluka.filter(
                      (taluka) =>
                        taluka.distid === district && taluka.isActive === 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="City/Village"
                  />
                </Grid>

                <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="city"
                    size="small"
                    value={city}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={6}></Grid>

              <Grid item xs={12} sm={6} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkForm"
                        id="checkForm"
                        value={1}
                        onChange={this.changeHandler}
                      />
                    }
                    label="Same as of Permanent Address"
                  />
                </FormGroup>
              </Grid>
              <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                Correspondence Address
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>
              <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Address Line"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress1"
                  size="small"
                  value={curAddress1}
                  disabled={editForm ? true : false}
                  onChange={this.changeHandler}
                  fullWidth
                />
              </Grid>
              <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Road"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress2"
                  disabled={editForm ? true : false}
                  size="small"
                  value={curAddress2}
                  onChange={this.changeHandler}
                  fullWidth
                />
              </Grid>
              <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Landmark"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress3"
                  disabled={editForm ? true : false}
                  size="small"
                  value={curAddress3}
                  onChange={this.changeHandler}
                  fullWidth
                />
              </Grid>

              {/*  */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                sx={{ marginTop: 1 }}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Pincode"
                    />
                  </Grid>

                  <Grid sx={{ marginTop: -3 }} item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="curpin"
                      size="small"
                      disabled={editForm ? true : false}
                      value={curpin}
                      onChange={this.changeHandlerNumber}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="State"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      disabled={editForm ? true : false}
                      value={curstate}
                      name={"curstate"}
                      options={employeeList?.state.filter(
                        (data) => data.isActive == 1
                      )}
                      filterOptions={this.filterOptions}
                      onChange={this.ChangeHandlerSearch}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/*  */}

              {/* <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mt: 2, mb: -4 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curpin"
                    size="small"
                    value={curpin}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curstate}
                    name={"curstate"}
                    options={employeeList?.state}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curdistrict}
                    disabled={editForm ? true : false}
                    name={"curdistrict"}
                    options={employeeList?.district.filter(
                      (curdistrict) =>
                        curdistrict.stid === curstate &&
                        curdistrict.isActive == 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Taluka"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curtaluka}
                    disabled={editForm ? true : false}
                    name={"curtaluka"}
                    options={employeeList?.taluka.filter(
                      (taluka) =>
                        taluka.distid === curdistrict && taluka.isActive == 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="City/Village"
                />
              </Grid>

              <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curcity"
                  disabled={editForm ? true : false}
                  size="small"
                  value={curcity}
                  onChange={this.changeHandler}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid container columnSpacing={1} justifyContent="right">
                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeList: state.studentList,
  employeeDetails: state.employeeList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
