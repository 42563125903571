import endpoint from "../config/endpoints";

export const BooksAuthoredJson = {
  apiBaseURL: endpoint.resourcePerson,
  screenTitle: "Books Authored",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Title of the Book / Article / Paper presented",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "title",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMAxDate: true,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },

    {
      label: "Publisher / Publication with ISBN No",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "publisher",
      isMandatory: true,
    },
    {
      label: "Description",
      controlType: "textarea",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "description",
      isMandatory: true,
    },
  ],
};
