import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet, apiPost } from "../../../utils/api_service";
const initialState = {
  subjects: [],
  isFetchSubjects: false,

  studentAttendanceList: [],
  isFetch: false,

  studentsAttendanceByDate: [],
  isFetchStudentsAttendanceByDate: false,

  studentAttendanceHead: [],
  isFetchStudentAttendance: false,

  studentAttendanceCoordinator: [],
  isFetchStudentAttendanceCoordinator: false,

  studentAttendanceHeadView: {},
  isFetchAttendance: false,

  division: [],
  isFetchDivision: false,

  batch: [],
  isFetchBatch: false,

  subject: [],
  isFetchSubject: false,

  subjectByDivision: [],
  isFetchSubjectByDivision: false,

  studentAttendanceBySubject: [],
  isFetchStudentAttendanceBySubject: false,

  studentAttendanceBySubjectView: {},
  isFetchStudentAttendanceBySubjectView: false,

  studentAttendanceSyllabus: [],
  isFetchStudentAttendanceSyllabus: false,
};
let URL = endpoints.subjectInfo;
let URL1 = endpoints.studentRollNo;
let URL2 = endpoints.studentAttendance;
let URL3 = endpoints.divisionAttendance;
let URL4 = endpoints.batchAttendance;
let URL5 = endpoints.subjectAttendance;
let URL6 = endpoints.studentDataAttendance;
let URL7 = endpoints.subjectByDivisionAttendance;
let URL8 = endpoints.studentAttendanceBySubject;

const studentsAttendanceSlice = createSlice({
  name: "studentAttendance",
  initialState,
  reducers: {
    studentsAttendanceSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjects: row,
        isFetchSubjects: true,
      };
    },
    studentAttendanceListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceList: row,
        isFetch: true,
      };
    },

    studentsAttendanceByDateSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentsAttendanceByDate: row,
        isFetchStudentsAttendanceByDate: true,
      };
    },

    studentAttendanceHeadListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceHead: row,
        isFetchStudentAttendance: true,
      };
    },

    studentAttendanceCoordinatorListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceCoordinator: row,
        isFetchStudentAttendanceCoordinator: true,
      };
    },

    studentAttendanceViewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceHeadView: row,
        isFetchAttendance: true,
      };
    },

    divisionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        division: row,
        isFetchDivision: true,
      };
    },

    batchSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        batch: row,
        isFetchBatch: true,
      };
    },

    subjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subject: row,
        isFetchSubject: true,
      };
    },

    subjectByDivisionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectByDivision: row,
        isFetchSubjectByDivision: true,
      };
    },

    studentAttendanceBySubjectSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceBySubject: row,
        isFetchStudentAttendanceBySubject: true,
      };
    },

    studentAttendanceBySubjectViewSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceBySubjectView: row,
        isFetchStudentAttendanceBySubjectView: true,
      };
    },

    studentAttendanceSyllabusSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentAttendanceSyllabus: row,
        isFetchStudentAttendanceSyllabus: true,
      };
    },
    resetTableListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },

    resetTableSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        studentAttendanceBySubject: [],
        isFetchStudentAttendanceBySubject: false,
      };
    },

    studentResetListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        studentAttendanceList: [],
        isFetch: false,
      };
    },
  },
});

export const {
  studentsAttendanceSuccess,
  studentAttendanceListSuccess,
  studentsAttendanceByDateSuccess,
  studentAttendanceHeadListSuccess,
  studentAttendanceViewSuccess,
  studentAttendanceCoordinatorListSuccess,
  studentAttendanceBySubjectViewSuccess,
  studentAttendanceSyllabusSuccess,
  divisionSuccess,
  batchSuccess,
  subjectSuccess,
  subjectByDivisionSuccess,
  studentAttendanceBySubjectSuccess,
  resetTableListSuccess,
  studentResetListSuccess,
  resetTableSuccess,
} = studentsAttendanceSlice.actions;

export default studentsAttendanceSlice.reducer;

export const getSubjectByYearDetailsId =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-year-detail?yearDetailId=" + yearDetail,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.getSubject;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
            };
            return bData;
          });
          dispatch(studentsAttendanceSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentAttendanceList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL6 + "?batchId=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              // id: data1.id,
              name:
                data1.firstName + " " + data1.middleName + " " + data1.lastName,
              middleName: data1.middleName,
              rollNo: data1.rollNo,
              prnNo: data1.prnNo,
              answer: "",
              attendance: false,
              excuse: false,
              divisionId: data1.divisionId,
              dateOfReporting: data1.dateOfReporting,
              reportingDetailId: data1.id,
              userRegistrationId: data1.userRegistrationId,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentAttendanceListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetStudentList = () => async (dispatch) => {
  dispatch(studentResetListSuccess());
};

// /api/acdmc/v1/student-attendance-header/by-from-to-date?fromDate=2022-01-01&toDate=2022-02-01

export const getStudentAttendanceByDate =
  ({ fromDate, toDate, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/by-from-to-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              date: data1.attendanceDate === null ? "" : data1.attendanceDate,
              subject:
                data1.employeeWorkLoad === null
                  ? ""
                  : data1.employeeWorkLoad.subject.name +
                    " - " +
                    data1.employeeWorkLoad.subjectDetail.schemeHeadId.shortName,
              teacher:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.lastName +
                    " " +
                    data1.userRegistration.firstName +
                    " " +
                    data1.userRegistration.middleName,
              lastName: data1.userRegistration.lastName,
              firstName: data1.userRegistration.firstName,
              middleName: data1.userRegistration.middleName,
              present:
                data1.presentRollNos === null
                  ? ""
                  : data1.presentRollNos === "1"
                  ? "Present"
                  : "Absent",
            };
            return bData;
          });
          dispatch(studentsAttendanceByDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// new

export const getStudentAttendanceByDateFilter =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/by-from-to-date-limit?limit=20&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              date: data1.attendanceDate === null ? "" : data1.attendanceDate,
              subject:
                data1.employeeWorkLoad === null
                  ? ""
                  : data1.employeeWorkLoad.subject.name +
                    " - " +
                    data1.employeeWorkLoad.subjectDetail.schemeHeadId.shortName,
              teacher:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.lastName +
                    " " +
                    data1.userRegistration.firstName +
                    " " +
                    data1.userRegistration.middleName,
              lastName:
                data1.userRegistration != null
                  ? data1.userRegistration.lastName
                  : null,
              firstName:
                data1.userRegistration != null
                  ? data1.userRegistration.firstName
                  : null,
              middleName:
                data1.userRegistration != null
                  ? data1.userRegistration.middleName
                  : null,
              present:
                data1.presentRollNos === null
                  ? ""
                  : data1.presentRollNos === "1"
                  ? "Present"
                  : "Absent",
            };
            return bData;
          });
          dispatch(studentsAttendanceByDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentAttendanceListForHead =
  ({ semesterId, divisionId, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/by-semister-detail-division?semisterDetailId=" +
          semesterId +
          "&divisionId=" +
          divisionId +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              attendanceDate: data1.attendanceDate,
              batch: data1.batch.batchName + " - " + data1.batch.shortName,
              subject:
                data1.employeeWorkLoad.subject === null
                  ? ""
                  : data1.employeeWorkLoad.subject.name +
                    " - " +
                    data1.employeeWorkLoad.subjectDetail.schemeHeadId.shortName,
              teacherName:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName,
              lastName:
                data1.userRegistration != null
                  ? data1.userRegistration.lastName
                  : null,
              firstName:
                data1.userRegistration != null
                  ? data1.userRegistration.firstName
                  : null,
              middleName:
                data1.userRegistration != null
                  ? data1.userRegistration.middleName
                  : null,
              presentCount: data1.presentCount,
              absentCount: data1.absentCount,
              totalCount: data1.presentCount + data1.absentCount,
              // teacherName: data1.userRegistration.lastName + " " + data1.userRegistration.firstName + "" + data1.userRegistration.fatherName + "" + data1.userRegistration.motherName,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentAttendanceHeadListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentAttendanceListForCoordinator =
  ({ semesterId, divisionId, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/by-semister-detail-division?semisterDetailId=" +
          semesterId +
          "&divisionId=" +
          divisionId +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              attendanceDate: data1.attendanceDate,
              batch: data1.batch.batchName + " - " + data1.batch.shortName,
              subject:
                data1.employeeWorkLoad.subject === null
                  ? ""
                  : data1.employeeWorkLoad.subject.name +
                    " - " +
                    data1.employeeWorkLoad.subjectDetail.schemeHeadId.shortName,

              teacherName:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName,
              lastName:
                data1.userRegistration != null
                  ? data1.userRegistration.lastName
                  : null,
              firstName:
                data1.userRegistration != null
                  ? data1.userRegistration.firstName
                  : null,
              middleName:
                data1.userRegistration != null
                  ? data1.userRegistration.middleName
                  : null,
              presentCount: data1.presentCount,
              absentCount: data1.absentCount,
              totalCount: data1.presentCount + data1.absentCount,
              // teacherName: data1.userRegistration.lastName + " " + data1.userRegistration.firstName + "" + data1.userRegistration.fatherName + "" + data1.userRegistration.motherName,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentAttendanceCoordinatorListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentAttendanceBySubjectView =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL2 + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          console.log(data.data);

          const studentData = data.data;
          let index = 0;
          let row = [];
          let student = {
            id: studentData.id,
            topicTaught: studentData.topicTaught,
            yearDetail:
              studentData.employeeWorkLoad.semisterDetail.semister.yearDetailId,
            semister: studentData.employeeWorkLoad.semisterDetail.id,
            semisterName:
              studentData.employeeWorkLoad.semisterDetail.semister.name,

            division: studentData.employeeWorkLoad.division.id,
            batch: studentData.employeeWorkLoad.batch.id,
            batchName: studentData.employeeWorkLoad.batch.batchName,
            divisionName: studentData.employeeWorkLoad.division.name,
            name: studentData.employeeWorkLoad.batch.batchName,
            subject: studentData.employeeWorkLoad.id,
            subjectId: studentData.employeeWorkLoad.subjectDetail.id,

            subjectDetail: studentData.employeeWorkLoad.subjectDetail.id,
            timeSlotName:
              studentData.timeSlot.fromTime +
              " - " +
              studentData.timeSlot.toTime,
            subjectName:
              studentData.employeeWorkLoad.subject === null
                ? ""
                : studentData.employeeWorkLoad.subject.name +
                  " - " +
                  studentData.employeeWorkLoad.subjectDetail.schemeHeadId
                    .shortName,

            timeSlot: studentData.timeSlot.id,
            attendanceDate: studentData.attendanceDate,
            remark: studentData.remark,
            presentRollNos: studentData.presentRollNos,
            presentCount: studentData.presentCount,
            absentCount: studentData.absentCount,
            studentAttendanceDetails: studentData.studentAttendanceDetails.map(
              (data1) => {
                index = index + 1;
                let bData = {
                  id: data1.id,
                  studentId: data1.student.id,
                  userRegistrationId: data1.student.id,
                  index: index,
                  reportingDetailId: data1.reportingDetail.id,
                  excuse: data1.excuse === 1 ? true : false,
                  rollNo: data1.rollNo,
                  attendance: data1.status === 1 ? true : false,
                  answer: data1.remark ? data1.remark : "",
                  name:
                    data1.student.lastName +
                    " " +
                    data1.student.firstName +
                    " " +
                    (data1.student.fatherName == null ||
                    data1.student.fatherName == " "
                      ? " - "
                      : data1.student.fatherName) +
                    " " +
                    (data1.student.motherName == null ||
                    data1.student.motherName == " "
                      ? " - "
                      : data1.student.motherName),
                  prnNo: data1.student.prnNo ? data1.student.prnNo : "",
                };
                row.push(bData);
                return bData;
              }
            ),
            studentCompletedSubsections:
              studentData.studentCompletedSubsections,
          };
          dispatch(studentAttendanceBySubjectViewSuccess({ row: student }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCompletedSubSection =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          endpoints.studentAttandaceSyllabus +
          "/by-student-attendance-header?studentAttendanceHeaderId=" +
          id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);

          dispatch(studentAttendanceSyllabusSuccess({ row: content }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentAttendanceListForHeadView =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL2 + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          console.log(data.data);

          const studentData = data.data;
          let index = 0;
          let row = [];
          const classAttendance = {
            id: studentData.id,
            topicTaught: studentData.topicTaught,
            yearDetailId:
              studentData.employeeWorkLoad.semisterDetail.semister.yearDetailId,
            semisterId: studentData.employeeWorkLoad.semisterDetail.id,
            semisterName:
              studentData.employeeWorkLoad.semisterDetail.semister.name,
            divisionId: studentData.employeeWorkLoad.division.id,
            divisionName: studentData.employeeWorkLoad.division.name,
            batchId: studentData.employeeWorkLoad.batch.id,
            batchName: studentData.employeeWorkLoad.batch.batchName,
            name:
              studentData.employeeWorkLoad.batch.batchName +
              " - " +
              studentData.employeeWorkLoad.batch.shortName,
            subjectId: studentData.employeeWorkLoad.id,
            subject:
              studentData.employeeWorkLoad.subject === null
                ? ""
                : studentData.employeeWorkLoad.subject.name +
                  " - " +
                  studentData.employeeWorkLoad.subjectDetail.schemeHeadId
                    .shortName,
            subjectDetail:
              studentData.employeeWorkLoad.subjectDetail === null
                ? ""
                : studentData.employeeWorkLoad.subjectDetail.id,
            subjectName:
              studentData.employeeWorkLoad.subject === null
                ? ""
                : studentData.employeeWorkLoad.subject.name +
                  " - " +
                  studentData.employeeWorkLoad.subjectDetail.schemeHeadId
                    .shortName,
            timeSlotId: studentData.timeSlot.id,
            timeSlotName:
              studentData.timeSlot.fromTime +
              " - " +
              studentData.timeSlot.toTime,
            attendanceDate: studentData.attendanceDate,
            remark: studentData.remark,
            studentAttendanceDetails: studentData.studentAttendanceDetails.map(
              (data1) => {
                index = index + 1;
                let bData = {
                  id: data1.id,
                  index: index,
                  excuse: data1.excuse === 1 ? true : false,
                  rollNo: data1.rollNo,
                  attendance: data1.status === 1 ? true : false,
                  answer: data1.remark ? data1.remark : "No Details ",
                  name:
                    data1.student.lastName +
                    " " +
                    data1.student.firstName +
                    " " +
                    (data1.student.fatherName == null ||
                    data1.student.fatherName == " "
                      ? " - "
                      : data1.student.fatherName) +
                    " " +
                    (data1.student.motherName == null ||
                    data1.student.motherName == " "
                      ? " - "
                      : data1.student.motherName),
                  prnNo: data1.student.prnNo
                    ? data1.student.prnNo
                    : "No PRN or Null Data",
                };
                return bData;
              }
            ),
          };
          dispatch(studentAttendanceViewSuccess({ row: classAttendance }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDivision =
  ({ id }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL3 + "?semisterDetailId=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              yearDetailId: data1.yearDetailId,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(divisionSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getBatch =
  ({ semister, division }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL4 + "?semisterDetailId=" + semister + "&divisionId=" + division,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.batchName + " - " + data1.shortName,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(batchSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubject =
  ({ semister, division, batch, subjectDetailId = false }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url:
          URL5 +
          "?semisterDetailId=" +
          semister +
          "&divisionId=" +
          division +
          "&batchId=" +
          batch,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: subjectDetailId ? data1.subjectDetail.id : data1.id,
              subjectDetailId: data1.subjectDetail.id,
              name:
                data1.subject === null
                  ? ""
                  : data1.subject.name +
                    " - " +
                    data1.subjectDetail.schemeHeadId.shortName,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(subjectSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectByDivision =
  ({ semister, division, subjectDetailId = false }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL7 + "?semisterDetailId=" + semister + "&divisionId=" + division,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: subjectDetailId ? data1.subjectDetail.id : data1.id,
              name:
                data1.subject !== null
                  ? data1.subject.name
                  : "" + " - " + data1.subjectDetail !== null
                  ? data1.subjectDetail.schemeHeadId.shortName
                  : "",
            };
            return bData;
          });
          dispatch(subjectByDivisionSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentAttendanceBySubject =
  ({ employeeWorkLoadId, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url:
          URL8 +
          "?employeeWorkLoadId=" +
          employeeWorkLoadId +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          console.log(content);
          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,

              attendanceDate: data1.attendanceDate,
              batch:
                data1.employeeWorkLoad.batch.batchName +
                " - " +
                data1.employeeWorkLoad.batch.shortName,
              topicTaught: data1.topicTaught,
              presentCount: data1.presentCount,
              absentCount: data1.absentCount,
              totalCount: data1.absentCount + data1.presentCount,
            };
            return bData;
          });
          dispatch(studentAttendanceBySubjectSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetTableListValues = () => async (dispatch) => {
  try {
    dispatch(resetTableListSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetTableValues = () => async (dispatch) => {
  try {
    dispatch(resetTableSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
