import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import AdmissionForm from "../AdmissionForm/AdmissionForm";
import ApplicantListForm from "../ApplicantAdmission/ApplicantListForm";
import PromotionalAdmissionForm from "../PromotionalAdmission/PromotionalAdmissionForm";
import { getApplicantAdmission } from "./applicantAdmission.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import swal from "sweetalert";
import endpoint from "../../config/endpoints";
import { apiDelete } from "../../utils/api_service";
import { getAdmissionList } from "../StudentFeesCollection/FeesCollection.slice";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import CurrentPreviousDetails from "../PromotionalAdmission/CurrentPreviousDetails";
import SearchStudList from "../../components/SearchTable";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
//import Matrix1 from "./Matrix1";
const columns = [
  { name: "index", title: "Sr. No.", align: "center", alignCenter: "center" },
  {
    name: "firstName",
    title: "Name",
    alignCenter: "right",
    keyName: "firstName,lastName,fatherName,motherName",
    isFormat: true,
    type: 0,
  },
  {
    name: "studentMobile",
    title: "Mobile",
    align: "center",
    alignCenter: "center",
  },
  { name: "studentEmail", title: "Email-Id", alignCenter: "left" },
  { name: "admissionStatus", title: "Status", alignCenter: "center" },
  { name: "action", title: "Action" },
];

class FeesStructureHeader extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    flag: 0,
    applicantForm: true,
    admissionForm: false,
    promotionalAdmission: false,
    editId: "",
    showTransferForm: false,
    searchValue: "",
    admissionList: [],
  };
  onClickOpenForm = () => {
    this.setState({
      flag: 1,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      admissionList: [],
    });
    if (
      this.props.feesCollectionList.admissionListStatus !==
      nextProps.feesCollectionList.admissionListStatus
    ) {
      nextProps.feesCollectionList.admissionListStatus.map(
        (admissionListData) => {
          apiGet({
            url:
              endpoints.admissionStatus +
              "/" +
              admissionListData.admissionStatus,
          }).then(({ data, success }) => {
            if (success) {
              admissionListData = {
                ...admissionListData,
                index: this.state.admissionList.length + 1,
                admissionStatus: data.data.name,
              };
              this.setState({
                admissionList: [...this.state.admissionList, admissionListData],
              });
            } else {
              admissionListData = {
                ...admissionListData,
                index: this.state.admissionList.length + 1,
              };
              this.setState({
                admissionList: [...this.state.admissionList, admissionListData],
              });
            }
          });
        }
      );
    }
  }

  toPromotion = () => {
    this.setState({
      promotionalAdmission: true,
      flag: 1,
      applicantForm: false,
      editId: "",
    });
  };
  onClosedPromotional = (msgData) => {
    this.setState({
      promotionalAdmission: false,
      flag: 0,
      applicantForm: true,
    });
    if (msgData != "") {
      this.props.showNotification({ msg: msgData });
    }
  };

  onClosedTransfer = () => {
    this.setState({
      showTransferForm: false,
      flag: 0,
      applicantForm: true,
    });
  };
  rowEdit = (rowData) => {
    const editId = rowData.id;
    this.props.getApplicantAdmission({ id: editId }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      promotionalAdmission: true,
      flag: 1,
      applicantForm: false,
      editId: rowData.id,
    });
  };

  onClickTransfer = (rowData) => {
    const editId = rowData.id;
    this.props.getApplicantAdmission({ id: editId }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.setState({
      // promotionalAdmission: true,
      flag: 1,
      applicantForm: false,
      showTransferForm: true,
      // editId: rowData.id,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDeleteData(rowData);
      }
    });
  };

  onDeleteData = (rowData) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.admissionList + "/" + rowData.admissionRegId,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props
          .getAdmissionList({
            academicId: rowData.academicYearId,
            yearDetailId: rowData.yearDetailId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  getFilteredTableData = () => {
    const { searchValue, admissionList } = this.state;
    const { feesCollectionList } = this.props;
    return admissionList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["applicationName"] &&
          currentRow["applicationName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["studentMobile"] &&
          currentRow["studentMobile"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["studentEmail"] &&
          currentRow["studentEmail"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["admissionStatus"] &&
          currentRow["admissionStatus"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  listReset = () => {
    this.setState({
      admissionList: [],
    });
  };
  render() {
    const { flag, promotionalAdmission, applicantForm, admissionForm, editId } =
      this.state;
    const { feesCollectionList, applicantAdmissionList } = this.props;

    return (
      <>
        {!flag && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="Admitted Student List" />
            </Paper>
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid item xs={12} sm={12} md={12}>
                <SearchStudList onSearch={this.onSearch} />
              </Grid>
            </Paper>
            <br />
          </>
        )}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {applicantForm && (
            <ApplicantListForm
              getPromotion={this.toPromotion}
              onClickAdd={this.onClickOpenForm}
              listReset={this.listReset}
            />
          )}

          {!flag && (
            <>
              <RTTable
                columns={columns}
                // showAddButtonInTable={showAddButtonInTable}
                // tableData={fee}
                tableData={this.getFilteredTableData()}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                onChangeStatus={this.onChangeStatus}
                // showAddButtonInTable={true}
                // rowAdd={this.onClickAdd}
                showTransferButtonInTable={true}
                rowTransfer={this.onClickTransfer}
                showDeleteIcon={false}
              />
            </>
          )}
          {admissionForm && <AdmissionForm />}
          {promotionalAdmission && (
            <PromotionalAdmissionForm
              onClosed={this.onClosedPromotional}
              applicantRegId={editId}
            />
          )}
          {this.state.showTransferForm && (
            <CurrentPreviousDetails
              onClosed={this.onClosedTransfer}
              applicantRegId={editId}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  feesCollectionList: state.feesCollection,
  applicantAdmissionList: state.applicantAdmission,
});
const mapDispatchToProps = {
  getApplicantAdmission,
  getAlert,
  getAdmissionList,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeesStructureHeader);
