import { Divider, Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";

let docs;
class StudAddress extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    perAddress1: "",
    perAddress2: "",
    perAddress3: "",
    pin: "",
    state: "",
    district: "",
    taluka: "",
    city: "",

    curAddress1: "",
    curAddress2: "",
    curAddress3: "",
    curpin: "",
    curstate: "",
    curdistrict: "",
    curtaluka: "",
    curcity: "",
    checkForm: 0,
    flag: 0,
    formErrors: [],
  };

  changeHandler = (event) => {
    const { name, value, checked } = event.target;

    if (name === "checkForm") {
      const {
        id,
        perAddress1,
        perAddress2,
        perAddress3,
        taluka,
        district,
        city,
        state,
        pin,
      } = this.state;

      if (checked === true) {
        this.setState({
          ...this.state,
          [name]: 1,
          id: id,
          curAddress1: perAddress1,
          curAddress2: perAddress2,
          curAddress3: perAddress3,
          curtaluka: taluka,
          curcity: city,
          curstate: state,
          curpin: pin,
          curdistrict: district,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: 0,
          id: id,
          curAddress1: "",
          curAddress2: "",
          curAddress3: "",
          curtaluka: "",
          curcity: "",
          curstate: "",
          curpin: "",
          curdistrict: "",
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
      // }
    }
  };

  componentDidUpdate() {
  }

  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
      if (event.target.value === "" || onlyNumber.test(event.target.value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
  };
  componentDidMount() {
    const { studentList } = this.props;

    if (studentList.isFetch) {
      this.setDataToTextField(studentList.admissionForm);
    }
  }
  setDataToTextField(data) {
    if (data.length !== 0) {
      this.setState({
        perAddress1: data.permanentAddressLine1,
        perAddress2: data.permanentAddressLine2,
        perAddress3: data.permanentAddressLine3,
        pin: data.permanentPin,
        state: data.permanentState,
        district: data.permanentDistrictName,
        taluka: data.permanentTalukaName,
        city: data.permanentCity,
        curAddress1: data.temporaryAddressLine1,
        curAddress2: data.temporaryAddressLine2,
        curAddress3: data.temporaryAddressLine3,
        curpin: data.temporaryPin,
        curstate: data.temporaryState,
        curdistrict: data.temporaryDistrict,
        curtaluka: data.temporaryTaluka,
        curcity: data.temporaryCity,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.admissionForm !==
        nextProps.studentList.admissionForm
      ) {
        this.setDataToTextField(nextProps.studentList.admissionForm);
      }
    }
  }


  handleFormValidation() {
    const {
      perAddress1,
      curAddress1,
      taluka,
      district,
      state,
      city,
      pin,
      curpin,
      curcity,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (perAddress1.toString().trim() === "") {
      formIsValid = false;
      formErrors["perAddressErr"] = myConstClass.perAddressMsg;
    }

    if (city.toString().trim() === "") {
      formIsValid = false;
      formErrors["cityErr"] = myConstClass.cityMsg;
    }

    if (curcity.toString().trim() === "") {
      formIsValid = false;
      formErrors["curcityErr"] = myConstClass.cityMsg;
    }

    if (taluka.toString().trim() === "") {
      formIsValid = false;
      formErrors["talukaErr"] = myConstClass.talukaMsg;
    }
    if (district.toString().trim() === "") {
      formIsValid = false;
      formErrors["districtErr"] = myConstClass.districtMsg;
    }

    if (state.toString().trim() === "") {
      formIsValid = false;
      formErrors["stateErr"] = myConstClass.stateMsg;
    }

    if (pin.toString().trim() === "") {
      formIsValid = false;
      formErrors["pinErr"] = myConstClass.pinMsg;
    }

    if (curpin.toString().trim() === "") {
      formIsValid = false;
      formErrors["currpinErr"] = myConstClass.pinMsg;
    }

    if (curAddress1.toString().trim() === "") {
      formIsValid = false;
      formErrors["curAddressErr"] = myConstClass.localAddMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      curtaluka,
      curdistrict,
      curcity,
      curstate,
      curpin,
      taluka,
      district,
      city,
      state,
      pin,
    } = this.state;

    docs = {
      id: id === 0 ? 0 : id,
      permanentAddressLine1: perAddress1,
      permanentAddressLine2: perAddress2,
      permanentAddressLine3: perAddress3,
      permanentPin: pin,
      permanentState: state,
      permanentDistrict: district,
      permanentTaluka: taluka,
      permanentCity: city,
      temporaryAddressLine1: curAddress1,
      temporaryAddressLine2: curAddress2,
      temporaryAddressLine3: curAddress3,
      temporaryPin: curpin,
      temporaryState: curstate,
      temporaryDistrict: curdistrict,
      temporaryTaluka: curtaluka,
      temporaryCity: curcity,
      type: 2,
    };

    if (this.handleFormValidation()) {
      this.props.studAddressData(docs);
      this.setState({ flag: 1 });
    }
  };
  render() {
    const {
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      taluka,
      city,
      district,
      state,
      pin,
      curtaluka,
      curcity,
      curdistrict,
      curstate,
      curpin,
    } = this.state;

    const { onCancel } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                &nbsp; Permanent Address
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Address Line"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress1"
                  size="small"
                  value={perAddress1}
                  onChange={this.changeHandler}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Road"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress2"
                  size="small"
                  value={perAddress2}
                  onChange={this.changeHandler}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Landmark"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="perAddress3"
                  size="small"
                  value={perAddress3}
                  onChange={this.changeHandler}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="pin"
                    size="small"
                    value={pin}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="state"
                    size="small"
                    value={state}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="district"
                    size="small"
                    value={district}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Taluka"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="taluka"
                    size="small"
                    value={taluka}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="City/Village"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="city"
                    size="small"
                    value={city}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={6}></Grid>

              <Grid item xs={12} sm={6} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid item xs={12} sm={12} md={12}>
                &nbsp; Correspondence Address
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Address Line"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress1"
                  size="small"
                  value={curAddress1}
                  onChange={this.changeHandler}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Road"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress2"
                  size="small"
                  value={curAddress2}
                  onChange={this.changeHandler}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" Landmark"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curAddress3"
                  size="small"
                  value={curAddress3}
                  onChange={this.changeHandler}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curpin"
                    size="small"
                    value={curpin}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curstate"
                    size="small"
                    value={curstate}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curdistrict"
                    size="small"
                    value={curdistrict}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Taluka"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curtaluka"
                    size="small"
                    value={curtaluka}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="City/Village"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  color="primary"
                  name="curcity"
                  size="small"
                  value={curcity}
                  onChange={this.changeHandler}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                rowSpacing={2}
                container
                columnSpacing={1}
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.1}
                  lg={1.1}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={2}
                  lg={2}
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const Navigation = (props) => {
  let navigate = useNavigate();
  return <StudAddress {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => ({
  studentList: state.admissionForm,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
