import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { React, useEffect } from "react";
import { LabelCompo } from "../../../components/Comman/Label";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, classes }) => {
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Personal Information"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Candidate Name
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.lastName +
                " " +
                data.firstName +
                " " +
                (data.fatherName === ""
                  ? "-"
                  : data.fatherName === null
                  ? "-"
                  : data.fatherName) +
                " " +
                (data.motherName === ""
                  ? "-"
                  : data.motherName === null
                  ? "-"
                  : data.motherName)}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Library ID
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.libraryNo === ""
                ? "-"
                : data.libraryNo === null
                ? "-"
                : data.libraryNo}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Gender
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.gender === "m"
                ? "Male"
                : data.gender === "f"
                ? "Female"
                : "Transgender"}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Date of Birth
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.dateOfBirth === ""
                ? "-"
                : data.dateOfBirth === null
                ? "-"
                : data.dateOfBirth}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Father's Name
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.fatherName === ""
                ? "-"
                : data.fatherName === null
                ? "-"
                : data.fatherName}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Mother's Name
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.motherName === ""
                ? "-"
                : data.motherName === null
                ? "-"
                : data.motherName}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Blood Group
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.bloodGroup === ""
                ? "-"
                : data.bloodGroup === null
                ? "-"
                : data.bloodGroup}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Marital Status
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.martialStatus === ""
                ? "-"
                : data.martialStatus === null
                ? "-"
                : data.martialStatus == 0
                ? "Un-Married"
                : data.martialStatus == 1
                ? "Married"
                : data.martialStatus == 2
                ? "Divorced"
                : "widowed"}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Mobile No.
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.alumniMobile === ""
                ? "-"
                : data.alumniMobile === null
                ? "-"
                : data.alumniMobile}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Alt. Mobile No.
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.alternativeMobile === ""
                ? "-"
                : data.alternativeMobile === null
                ? "-"
                : data.alternativeMobile}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Email-Id
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.alumniEmail === ""
                ? "-"
                : data.alumniEmail === null
                ? "-"
                : data.alumniEmail}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Alt. Email-Id
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.alternativeEmail === ""
                ? "-"
                : data.alternativeEmail === null
                ? "-"
                : data.alternativeEmail}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; About me
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.aboutMe === ""
                ? "-"
                : data.aboutMe === null
                ? "-"
                : data.aboutMe}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(BasicTable);
