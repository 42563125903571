import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../../config/messageconstant";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getSemesterDetailsByYearAndAcademic,
  getStudentList,
} from "../../RollNoList/ViewOfRollList/rollno.slice";
import { getTeacherWorkload } from "./employeeWorkload.slice";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    name: "subjectName",
    title: "Subject Name",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    name: "subjectScheme",
    title: "Subject Scheme",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    name: "batch",
    title: "Batch",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
];
const columnExtensions = [{ columnName: "index", width: 100 }];
class WorkloadAllocation extends React.Component {
  state = {
    id: 0,
    academicYear: "",
    yearDetail: "",
    semester: "",
    division: "",
    formErrors: [],
    addWorkload: false,
  };

  componentDidMount() {
    const { getYearDetails, getAlert, getAcademicYear } = this.props;

    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    getAcademicYear().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    const { yearDetail, academicYear } = this.state;
    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name == "yearDetail") {
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (academicYear !== "") {
          getSemesterDetailsByYearAndAcademic({
            yearId: newValue,
            academicId: academicYear,
          }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      } else {
        this.setState({ [name]: "" });
      }
    }

    if (name == "academicYear" && yearDetail !== "") {
      getSemesterDetailsByYearAndAcademic({
        yearId: yearDetail,
        academicId: newValue,
      }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  handleFormValidation() {
    const { academicYear, yearDetail, division, semester } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (academicYear.toString().trim() === "" || academicYear === null) {
      formIsValid = false;
      formErrors["academicYearError"] = myConstClass.acYearEmptyMsg;
    }

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }

    if (semester.toString().trim() === "" || semester === null) {
      formIsValid = false;
      formErrors["semesterError"] = myConstClass.semisterNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { semester, division } = this.state;
    const { getTeacherWorkload } = this.props;

    if (this.handleFormValidation()) {
      getTeacherWorkload({
        semisterDetailId: semester,
        divisionId: division,
      }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  render() {
    const { yearDetail, academicYear, semester, division } = this.state;
    const { academicYearError, yearDetailError, divisionError, semesterError } =
      this.state.formErrors;
    const {
      academicYearList,
      rollNoData,
      internshipDeclarationList,
      employeeWorkload,
    } = this.props;
    return (
      <>
        <div>
          <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
            <>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                {" "}
                <Grid
                  item
                  sx={{ ml: 1 }}
                  xs={12}
                  sm={12}
                  md={12}
                  rowSpacing={2}
                >
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Teacher Work Allocated"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Academic Year"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={academicYear}
                      name={"academicYear"}
                      options={academicYearList?.academicYear}
                      onChange={this.ChangeHandlerSearch}
                      isError={academicYearError ? true : false}
                      errorText={academicYearError ? academicYearError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Detail"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={internshipDeclarationList?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Semester"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={semester}
                      name={"semester"}
                      options={rollNoData?.semisterDetailsList}
                      onChange={this.ChangeHandlerSearch}
                      isError={semesterError ? true : false}
                      errorText={semesterError ? semesterError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Division"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={division}
                      name={"division"}
                      options={internshipDeclarationList?.distinctDivision}
                      onChange={this.ChangeHandlerSearch}
                      isError={divisionError ? true : false}
                      errorText={divisionError ? divisionError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      container
                      justifyContent="center"
                      m={1}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.searchData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ m: 1 }}>
                <DynamicTable
                  data={employeeWorkload.teacherWorkload}
                  tableHead={columns}
                  showHeadDelete={false}
                  showHeadEdit={false}
                  showPegination={false}
                  isActionColActive={false}
                />
              </Grid>
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  employeeWorkload: state.employeeWorkload,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  getStudentList,
  getSemesterDetailsByYearAndAcademic,
  getTeacherWorkload,
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkloadAllocation);
