import { Checkbox, Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiDelete, apiGet } from "../../../utils/api_service";
import { getBatchList } from "../../Batches/BatchList/batchlist.slice";
import {
  getEmployeeByDeptId,
  resetEmployee,
} from "../../EmployeeDetails/employee.slice";
import EditTimeTableDetails from "./EditTimeTableDetails";

import { showNotification } from "../../Landing/Landing.slice";
import {
  getSubjectByDepartment,
  getSubjectBySemesterAndDepartmentId,
  getSubjectSchemeBySubjectId,
  resetSubjectInfoGetById,
} from "../../Masters/Admission/Subject Info/subjectInfo.slice";
import { getDepartmentByYearDetailsId } from "../../Masters/Internship/Department/department.slice";
import AddTimeSlot from "./AddTimeSlot";
import {
  getTimeTableDetailByHeaderId,
  getTimeTableDetailsByBatchId,
  getTimeTableDetailsByTeacherId,
  resetData,
  saveTimeTableDetail,
} from "./generateTimeTable.slice";
import ViewTimeTableCellPop from "./ViewTimeTableCellPop";
import ViewTimeTableHeader from "./ViewTimeTableHeaderDetails";
class MatrixForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      allocationList: [],
      matList: [],
      matrixError: false,
      openTimeSlot: false,
      openTimeSlotCell: false,
      showCheckbox: false,
      error: false,
      batch: "",
      department: "",
      teacherName: "",
      scheme: "",
      subjectName: "",
      subjectHrCount: 0,
      subject: "",
      formErrors: {},
      rows: [],
      value: [],
      teacherData: [],
      editData: {},
      editTimeTableDetails: {},
      openEditTimeTableDetails: false,
      timeSlot: "",
      day: "",
      yearDetailName: "",
    };
  }
  subData = [
    {
      index: "sub123",
      scheme: "th",
      hr: 2,
    },
    {
      index: "sub123",
      scheme: "th",
      hr: 2,
    },
    {
      index: "sub123",
      scheme: "th",
      hr: 2,
    },
  ];
  columnsData = [
    {
      name: "index",
      title: "Subject Code",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "scheme",
      title: "Scheme",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "hr",
      title: "Hr",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  componentDidMount() {
    const {
      timeTableList,
      timeSlotList,
      getDepartmentByYearDetailsId,
      getBatchList,
    } = this.props;
    this.createMatrix(timeTableList.timeTableDetailsList, timeSlotList);
    console.log(this.props.timeTableList.timeTable);

    getBatchList({
      academicID: timeTableList.timeTable.semisterDetail.academicYearId,
      yearId: timeTableList.timeTable.division.yearDetailId,
      semisterID: timeTableList.timeTable.semisterDetail.id,
      divisionId: timeTableList.timeTable.division.id,
    }).then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });
    getDepartmentByYearDetailsId({
      yearDetailId: timeTableList.timeTable.division.yearDetailId,
    }).then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });
  }
  createMatrix(timeTableDetailsList, timeSlotList) {
    const { dayList } = this.props;
    let matrixData = [];
    const { batch, scheme } = this.state;
    let countHr = 0;
    if (this.state.matList.length === 0) {
      timeSlotList.map((timeSlotData) => {
        let matData;
        matData = {
          timeSlotId: timeSlotData.id,
          timeSlotName: timeSlotData.fromTime + " - " + timeSlotData.toTime,
          isRecesses: timeSlotData.isRecesses,
        };
        const daysData = dayList.map((dayData) => {
          let timeTableDetailsData = timeTableDetailsList.filter(
            (item) =>
              item.day === dayData.id && item.timeSlot === timeSlotData.id
          );
          if (timeTableDetailsData.length !== 0) {
            let dataList = [];
            timeTableDetailsData.map((timeTableDetailsDetails) => {
              if (
                timeTableDetailsDetails.subjectName === scheme &&
                timeTableDetailsDetails.batch === batch
              ) {
                countHr = countHr + 1;
              }
              let matValue = {
                divisionName: timeTableDetailsDetails.divisionName,
                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,
                batchShortName: timeTableDetailsDetails.batchShortName,

                teacher: timeTableDetailsDetails.teacher,
                
               lastName: timeTableDetailsDetails.lastName,
              firstName: timeTableDetailsDetails.firstName,
              fatherName: timeTableDetailsDetails.fatherName,
              motherName: timeTableDetailsDetails.motherName,
              middleName: timeTableDetailsDetails.middleName,

                teacherName: timeTableDetailsDetails.teacherName,
                teacherShortName: timeTableDetailsDetails.teacherShortName,
                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectDetailName: timeTableDetailsDetails.subjectDetailName,
                subjectName: timeTableDetailsDetails.subjectName,

                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                subjectName: timeTableDetailsDetails.subjectName,

                id: timeTableDetailsDetails.id,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };

              dataList.push(matValue);
            });

            let days = {
              dayId: dayData.id,
              dayName: dayData.name,
              checked: false,
              teacherBusy: false,
              batchBusy: false,
              value: dataList,
            };
            return days;
          }
          let days = {
            dayId: dayData.id,
            dayName: dayData.name,
            checked: false,
            teacherBusy: false,
            batchBusy: false,
            value: [],
          };
          return days;
        });
        matData = { ...matData, dayList: daysData };
        matrixData.push(matData);
      });
    } else {
      this.state.matList.map((timeSlotData) => {
        let matData;
        matData = {
          timeSlotId: timeSlotData.timeSlotId,
          timeSlotName: timeSlotData.timeSlotName,
          isRecesses: timeSlotData.isRecesses,
        };
        const daysData = timeSlotData.dayList.map((dayData) => {
          let timeTableDetailsData = timeTableDetailsList.filter(
            (item) =>
              item.day === dayData.dayId &&
              item.timeSlot === timeSlotData.timeSlotId
          );

          if (timeTableDetailsData.length !== 0) {
            let dataList = [];
            timeTableDetailsData.map((timeTableDetailsDetails) => {
              if (
                timeTableDetailsDetails.subjectName === scheme &&
                timeTableDetailsDetails.batch === batch
              ) {
                countHr = countHr + 1;
              }
              let matValue = {
                divisionName: timeTableDetailsDetails.divisionName,
                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,
                batchShortName: timeTableDetailsDetails.batchShortName,

                teacher: timeTableDetailsDetails.teacher,
                lastName: timeTableDetailsDetails.lastName,
                firstName: timeTableDetailsDetails.firstName,
                fatherName: timeTableDetailsDetails.fatherName,
                motherName: timeTableDetailsDetails.motherName,
                middleName: timeTableDetailsDetails.middleName,
                teacherName: timeTableDetailsDetails.teacherName,
                teacherShortName: timeTableDetailsDetails.teacherShortName,

                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectDetailName: timeTableDetailsDetails.subjectDetailName,
                subjectName: timeTableDetailsDetails.subjectName,

                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                subjectName: timeTableDetailsDetails.subjectName,

                id: timeTableDetailsDetails.id,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };
              dataList.push(matValue);
            });

            let days = {
              dayId: dayData.dayId,
              dayName: dayData.dayName,
              checked: false,
              teacherBusy: false,
              value: dataList,
            };
            return days;
          }
          let days = {
            dayId: dayData.dayId,
            dayName: dayData.dayName,
            checked: false,
            teacherBusy: false,
            value: [],
          };
          return days;
        });
        matData = { ...matData, dayList: daysData };
        matrixData.push(matData);
      });
    }
    this.setState({ matList: matrixData, subjectHrCount: countHr });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList.timeTableDetailsList !==
          nextProps.timeTableList.timeTableDetailsList ||
        this.props.timeSlotList !== nextProps.timeSlotList
      ) {
        this.createMatrix(
          nextProps.timeTableList.timeTableDetailsList,
          nextProps.timeSlotList
        );
      }
      if (
        this.props.timeTableList.timeTableDetailsByTeacherId !==
        nextProps.timeTableList.timeTableDetailsByTeacherId
      ) {
        let matrixData = [];
        if (this.state.matList.length !== 0) {
          this.state.matList.map((timeSlotData) => {
            let matData;
            matData = {
              timeSlotId: timeSlotData.timeSlotId,
              timeSlotName: timeSlotData.timeSlotName,
              isRecesses: timeSlotData.isRecesses,
            };
            const daysData = timeSlotData.dayList.map((dayData) => {
              let timeTableDetailsData =
                nextProps.timeTableList.timeTableDetailsByTeacherId.filter(
                  (item) =>
                    item.day === dayData.dayId &&
                    item.timeSlot === timeSlotData.timeSlotId
                );

              if (timeTableDetailsData.length !== 0) {
                let days = {
                  dayId: dayData.dayId,
                  dayName: dayData.dayName,
                  checked: false,
                  teacherBusy: true,
                  batchBusy: dayData.batchBusy,
                  value: dayData.value,
                };
                return days;
              }
              let days = {
                dayId: dayData.dayId,
                dayName: dayData.dayName,
                checked: false,
                teacherBusy: false,
                batchBusy: dayData.batchBusy,
                value: dayData.value,
              };
              return days;
            });
            matData = { ...matData, dayList: daysData };
            matrixData.push(matData);
          });
        }
        this.setState({ matList: matrixData });
      }
      if (
        this.props.timeTableList.timeTableDetailsByBatchId !==
        nextProps.timeTableList.timeTableDetailsByBatchId
      ) {
        let matrixData = [];
        if (this.state.matList.length !== 0) {
          this.state.matList.map((timeSlotData) => {
            let matData;
            matData = {
              timeSlotId: timeSlotData.timeSlotId,
              timeSlotName: timeSlotData.timeSlotName,
              isRecesses: timeSlotData.isRecesses,
            };
            const daysData = timeSlotData.dayList.map((dayData) => {
              let timeTableDetailsData =
                nextProps.timeTableList.timeTableDetailsByBatchId.filter(
                  (item) =>
                    item.day === dayData.dayId &&
                    item.timeSlot === timeSlotData.timeSlotId
                );

              if (timeTableDetailsData.length !== 0) {
                let days = {
                  dayId: dayData.dayId,
                  dayName: dayData.dayName,
                  checked: false,
                  teacherBusy: dayData.teacherBusy,
                  batchBusy: true,
                  value: dayData.value,
                };
                return days;
              }
              let days = {
                dayId: dayData.dayId,
                dayName: dayData.dayName,
                checked: false,
                teacherBusy: dayData.teacherBusy,
                batchBusy: false,
                value: dayData.value,
              };
              return days;
            });
            matData = { ...matData, dayList: daysData };
            matrixData.push(matData);
          });
        }
        this.setState({ matList: matrixData });
      }
    }
  }
  submitHandler = (e) => {
    e.preventDefault();
    window.location.replace("/view-time-table");
  };
  onClickAdd = (e, timeSlot, day) => {
    e.stopPropagation();
    let { matList } = this.state;
    let matrixDataRow = matList.map((data) => {
      if (data.timeSlotId === timeSlot) {
        let dayListData = data.dayList.filter((item) => item.dayId === day);
        this.setState({
          openTimeSlot: true,
          timeSlot: timeSlot,
          day: day,
          value: dayListData[0].value,
          editData: {},
        });
      }
      return data;
    });
  };
  handleCloses = () => {
    this.setState({
      openTimeSlot: false,
    });
  };

  handleClosesTimeTableDetails = (itemData) => {
    if (itemData) {
      console.log("itemData");
      console.log(itemData);

      const { timeTableList } = this.props;

      let timeTableDetails = {
        timeTableHeader: {
          id: timeTableList.timeTable.id,
        },
        day: itemData.day,
        department: {
          id: itemData.department,
        },
        timeSlot: {
          id: itemData.timeSlot,
        },
        subjectDetail: {
          id: itemData.subjectName,
        },
        userRegistration: {
          id: itemData.teacherName,
        },
        batch: {
          id: itemData.batch,
        },
        rooms: {
          id: itemData.roomNo,
        },
      };
      if (itemData.id !== 0) {
        timeTableDetails = {
          ...timeTableDetails,
          id: itemData.id,
        };
      }
      console.log("timeTableDetails");
      console.log(timeTableDetails);

      this.props
        .saveTimeTableDetail({ dataToSave: [timeTableDetails] })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.props
              .getTimeTableDetailByHeaderId({
                timeTableHeaderId: timeTableList.timeTable.id,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                } else {
                  this.props.resetEmployee();
                  this.props.resetSubjectInfoGetById();
                  this.setState({
                    openTimeSlotCell: false,
                    rows: [],
                    showCheckbox: false,
                    batch: "",
                    department: "",
                    teacherName: "",
                    scheme: "",
                    subjectName: "",
                    subject: "",
                    formErrors: {},
                  });
                }
              });
          }
        });
    }

    this.setState({
      editTimeTableDetails: {},
      openEditTimeTableDetails: false,
    });
  };
  handleClosesTimeSlotCell = () => {
    this.setState({
      openTimeSlotCell: false,
      rows: [],
    });
  };
  OnSave = (dataToSave) => {
    const { timeTableList } = this.props;
    const { batch, department, teacherName, scheme, subjectName } = this.state;
    const timeTableDetailsList = dataToSave.map((timeTableDetailData) => {
      let timeTableDetails = {
        timeTableHeader: {
          id: timeTableList.timeTable.id,
        },
        day: timeTableDetailData.day,
        department: {
          id: department,
        },
        timeSlot: {
          id: timeTableDetailData.timeSlot,
        },
        subjectDetail: {
          id: scheme,
        },
        userRegistration: {
          id: teacherName,
        },
        batch: {
          id: batch,
        },
        rooms: {
          id: timeTableDetailData.roomNo,
        },
      };
      if (timeTableDetailData.id !== 0) {
        timeTableDetails = {
          ...timeTableDetails,
          id: timeTableDetailData.id,
        };
      }
      return timeTableDetails;
    });

    this.props
      .saveTimeTableDetail({ dataToSave: timeTableDetailsList })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props
            .getTimeTableDetailByHeaderId({
              timeTableHeaderId: timeTableList.timeTable.id,
            })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.props.resetEmployee();
                this.props.resetSubjectInfoGetById();
                this.setState({
                  openTimeSlotCell: false,
                  rows: [],
                  showCheckbox: false,
                  batch: "",
                  department: "",
                  teacherName: "",
                  scheme: "",
                  subjectName: "",
                  subject: "",
                  formErrors: {},
                });
              }
            });
        }
      });

    this.setState({
      openTimeSlot: false,
    });
  };
  onClickDelete = (row) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoints.timeTableDetails + "/" + row.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getTimeTableDetailByHeaderId({
                timeTableHeaderId: this.props.timeTableList.timeTable.id,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({
                    message: "Something went wrong",
                  });
                } else {
                  this.setState({
                    openTimeSlotCell: false,
                    rows: [],
                    showCheckbox: false,
                    batch: "",
                    department: "",
                    teacherName: "",
                    scheme: "",
                    subjectName: "",
                    subject: "",
                    formErrors: {},
                  });
                }
              });
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  onClickEdit = (row) => {
    this.setState({
      editData: {
        floorNo: row.floorNo,
        roomNo: row.roomNo,
        batch: row.batch,
        department: row.department,
        teacherName: row.teacherName,
        subjectName: row.subjectName,
        timeSlot: row.timeSlot,
        day: row.day,
        id: row.id,
      },
    });
    this.setState({
      openTimeSlot: true,
      timeSlot: row.timeSlot,
      day: row.day,
    });
  };
  columns = [
    {
      name: "batchName",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "teacher",
      title: "Teacher Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 1,
    },
    {
      name: "subjectDetailName",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "floorName",
      title: "Floor Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "roomsName",
      title: "Room Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  teacherColumns = [
    {
      name: "year",
      title: "Year Details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "divisionName",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "batchName",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "subjectDetailName",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "floorName",
      title: "Floor Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "roomsName",
      title: "Room Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  ChangeHandlerSearch = (name, newValue) => {
    const {
      timeTableList,
      timeSlotList,
      getDepartmentByYearDetailsId,
      getBatchList,
    } = this.props;

    if (newValue !== null) {
      this.setState({
        [name]: newValue,
      });

      if (name === "department") {
        this.setState({
          subjectName: "",
          subject: "",
          teacherName: "",
          scheme: "",
        });
        this.props.resetSubjectInfoGetById();

        this.props
          .getSubjectBySemesterAndDepartmentId({
            // departmentId: newValue,
            semesterId: timeTableList.timeTable.semisterDetail.semister.id,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: "Something went wrong",
              });
            }
          });

        this.props
          .getEmployeeByDeptId({
            departmentId: newValue,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: "Something went wrong",
              });
            }
          });
      } else if (name === "subjectName") {
        const subjectNameList =
          this.props.subjectList?.subjectBySemisterAndDepartment.filter(
            (row) => row.id === newValue
          );

        this.setState({
          scheme: "",
          subject: subjectNameList.length !== 0 ? subjectNameList[0].name : "",
        });
        this.props
          .getSubjectSchemeBySubjectId({
            subjectId: newValue,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({
                message: "Something went wrong",
              });
            }
          });
      }
      this.props.resetData();
      this.props
        .getTimeTableDetailByHeaderId({
          timeTableHeaderId: timeTableList.timeTable.id,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              openTimeSlotCell: false,
              rows: [],
              showCheckbox: false,
              formErrors: {},
            });
          }
        });
    } else {
      this.setState({
        [name]: "",
      });
    }
  };
  checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    const { batch, department, teacherName, scheme, subjectName } = this.state;

    if (batch === "" || batch === null) {
      formIsValid = false;
      formErrors["batchError"] = myConstClass.batchMsg;
    }
    if (scheme === "" || scheme === null) {
      formIsValid = false;
      formErrors["schemeError"] = myConstClass.schemeNameMsg;
    }
    if (department === "" || department === null) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    if (teacherName === "" || teacherName === null) {
      formIsValid = false;
      formErrors["teacherNameError"] = myConstClass.teacherNameMsg;
    }
    if (subjectName === "" || subjectName === null) {
      formIsValid = false;
      formErrors["subjectNameError"] = myConstClass.subjectSchemeMSg;
    }
    this.setState({
      formErrors: formErrors,
    });
    return formIsValid;
  };
  DeleteSelected = () => {
    let matrixData = [];
    let index = 0;
    this.state.matList.map((timeSlotData) => {
      timeSlotData.dayList.map((dayData) => {
        if (dayData.checked) {
          dayData.value.map((rowData) => {
            matrixData.push(rowData.id);
          });
        }
      });
    });
    console.log(matrixData);
    if (matrixData.length !== 0) {
      apiDelete({
        url:
          endpoints.timeTableDetails +
          "/delete-by-ids?ids=" +
          matrixData.join(),
      }).then(({ success }) => {
        if (success) {
          this.props
            .getTimeTableDetailByHeaderId({
              timeTableHeaderId: this.props.timeTableList.timeTable.id,
            })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.props.resetEmployee();
                this.props.resetSubjectInfoGetById();
                this.setState({
                  openTimeSlotCell: false,
                  rows: [],
                  showCheckbox: false,
                  batch: "",
                  department: "",
                  teacherName: "",
                  scheme: "",
                  subjectName: "",
                  subject: "",
                  formErrors: {},
                });
              }
            });
          this.props.showNotification({
            msg: "Deleted successfully",
            severity: "success",
          });
        } else {
          this.props.showNotification({
            msg: "Failed to delete",
            severity: "error",
          });
        }
      });
    } else {
      this.props.showNotification({
        msg: "Please select atleast one checkbox",
        severity: "error",
      });
    }
  };
  allocateTimeTable = () => {
    let matrixData = [];
    let index = 0;
    this.state.matList.map((timeSlotData) => {
      timeSlotData.dayList.map((dayData) => {
        if (dayData.checked) {
          index = index + 1;
          let days = {
            index: index,
            timeSlotId: timeSlotData.timeSlotId,
            timeSlotName: timeSlotData.timeSlotName,
            dayId: dayData.dayId,
            dayName: dayData.dayName,
            value: dayData.value,
          };

          matrixData.push(days);
        }
      });
    });
    console.log(matrixData);
    if (matrixData.length !== 0) {
      this.setState({
        openTimeSlot: true,
        allocationList: matrixData,
        editData: {},
      });
    } else {
      this.props.showNotification({
        msg: "Please select atleast one checkbox",
        severity: "error",
      });
    }
  };
  onClickEditTimeTableDetails = (rowData) => {
    this.setState({
      editTimeTableDetails: rowData,
      openEditTimeTableDetails: true,
      openTimeSlotCell: false,
    });
  };
  search = async () => {
    if (this.checkValidation()) {
      await this.props
        .getTimeTableDetailsByTeacherId({
          teacherId: this.state.teacherName,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({
              message: "Something went wrong",
            });
          }
        });
      await this.props
        .getTimeTableDetailsByBatchId({
          batchId: this.state.batch,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({
              message: "Something went wrong",
            });
          }
        });
      this.setState({
        showCheckbox: true,
      });
    }
  };
  onSelectAllClick = (day, timeSlot, e) => {
    this.setState({
      openTimeSlotCell: false,
      rows: [],
      day: "",
      timeSlot: "",
    });
    let matrixData = [];
    this.state.matList.map((timeSlotData) => {
      let matData;
      matData = {
        timeSlotId: timeSlotData.timeSlotId,
        timeSlotName: timeSlotData.timeSlotName,
        isRecesses: timeSlotData.isRecesses,
      };
      const daysData = timeSlotData.dayList.map((dayData) => {
        if (timeSlotData.timeSlotId === timeSlot && dayData.dayId === day) {
          let days = {
            dayId: dayData.dayId,
            checked: e.currentTarget.checked,
            dayName: dayData.dayName,
            value: dayData.value,
            teacherBusy: dayData.teacherBusy,
            batchBusy: dayData.batchBusy,
          };
          return days;
        } else {
          let days = {
            dayId: dayData.dayId,
            checked: dayData.checked,
            dayName: dayData.dayName,
            value: dayData.value,
            teacherBusy: dayData.teacherBusy,
            batchBusy: dayData.batchBusy,
          };
          return days;
        }
      });
      matData = { ...matData, dayList: daysData };
      matrixData.push(matData);
    });
    this.setState({ matList: matrixData });
  };
  openTableDetails = (
    checkedValue,
    value,
    teacherData,
    name,
    timeSlotName,
    e
  ) => {
    console.log(value);
    if (teacherData.length !== 0) {
      let teacherList = [];
      teacherData.map((teacher) => {
        apiGet({
          url: endpoints.yearDetailId + "/" + teacher.yearDetailId,
        }).then(({ data, success }) => {
          if (success) {
            teacher = {
              year:
                data.data.year.programType.name +
                " - " +
                data.data.programTitle.brName +
                " - " +
                data.data.year.className,
              ...teacher,
            };
            teacherList.push(teacher);
            if (teacherList.length === teacherData.length) {
              this.setState({
                openTimeSlotCell: true,
                rows: value,
                day: name,
                teacherData: teacherList,
                timeSlot: timeSlotName,
              });
            }
            return teacher;
          }
        });
      });
    } else {
      this.setState({
        openTimeSlotCell: true,
        rows: value,
        day: name,
        teacherData: teacherData,
        timeSlot: timeSlotName,
      });
    }
  };
  clearTime = () => {
    this.props
      .getTimeTableDetailByHeaderId({
        timeTableHeaderId: this.props.timeTableList.timeTable.id,
      })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props.resetEmployee();
          this.props.resetSubjectInfoGetById();
          this.setState({
            openTimeSlotCell: false,
            rows: [],
            showCheckbox: false,
            batch: "",
            department: "",
            teacherName: "",
            scheme: "",
            subjectName: "",
            subject: "",
            formErrors: {},
          });
        }
      });
  };
  render() {
    const {
      dayList,
      onCancel,
      backToList,
      employeeDetails,
      subjectList,
      departmentList,
      batchList,
      timeTableList,
      rollNoData,
    } = this.props;
    const { matList, batch, department, teacherName, scheme, subjectName } =
      this.state;
    const {
      batchError,
      departmentError,
      schemeError,
      subjectNameError,
      teacherNameError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                sx={{ marginRight: 2 }}
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  fullWidth={true}
                  onClick={backToList}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back"
                  fullWidth={true}
                  onClick={onCancel}
                />
              </Grid>
            </Grid>
            <ViewTimeTableHeader
              subjectHrCount={this.state.subjectHrCount}
              timeTableByTeacherId={
                this.props.timeTableList.timeTableDetailsByTeacherId
              }
              subject={this.state.subject}
              yearDetailName={this.state.yearDetailName}
              timeTable={timeTableList.timeTable}
            />
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Batch"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={batch}
                  name={"batch"}
                  options={batchList?.batchList}
                  onChange={this.ChangeHandlerSearch}
                  isError={batchError ? true : false}
                  errorText={batchError ? batchError : " "}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Department"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={department}
                  name={"department"}
                  options={departmentList?.department.filter(
                    (row) => row.showForSelection === 1
                  )}
                  onChange={this.ChangeHandlerSearch}
                  isError={departmentError ? true : false}
                  errorText={departmentError ? departmentError : " "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Teacher"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={teacherName}
                  name={"teacherName"}
                  options={employeeDetails?.employeeList}
                  onChange={this.ChangeHandlerSearch}
                  isError={teacherNameError ? true : false}
                  errorText={teacherNameError ? teacherNameError : " "}
                />
              </Grid>
              {/* <DynamicTable
 data={this.subData}
 tableHead={this.columnsData}
 showPegination={false}
 /> */}
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Subject"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={subjectName}
                  name={"subjectName"}
                  // options={subjectList?.subjectByDepartment}
                  options={subjectList?.subjectBySemisterAndDepartment}
                  onChange={this.ChangeHandlerSearch}
                  isError={subjectNameError ? true : false}
                  errorText={subjectNameError ? subjectNameError : " "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Scheme"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={scheme}
                  name={"scheme"}
                  options={
                    subjectList?.subjectById.subjectDetails
                      ? subjectList?.subjectById.subjectDetails.filter(
                          (data) => data.value != 0
                        )
                      : []
                  }
                  onChange={this.ChangeHandlerSearch}
                  isError={schemeError ? true : false}
                  errorText={schemeError ? schemeError : " "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              sx={{ marginTop: 3 }}
              container
              justifyContent="right"
            >
              <Grid item xs={12} sm={12} md={12}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Search"
                  onClick={this.search}
                />
                {!this.state.showCheckbox && (
                  <>
                    &nbsp;&nbsp;
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Delete Selected Allocation"
                      onClick={this.DeleteSelected}
                    />
                  </>
                )}
                {this.state.showCheckbox && (
                  <>
                    &nbsp;&nbsp;
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Allocate"
                      onClick={this.allocateTimeTable}
                    />
                  </>
                )}
                <>
                  &nbsp;&nbsp;
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Clear"
                    onClick={this.clearTime}
                  />
                </>
              </Grid>
            </Grid>
          </Grid>
          {this.state.showCheckbox ? (
            <>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: "#808080" }}
                label="Select checkbox to allocate"
              />
            </>
          ) : (
            <>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: "#808080" }}
                label="Select checkbox to delete allocation"
              />
            </>
          )}
          <br />
          <br />
          {
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {dayList.map((columnName, index) => {
                      return (
                        <TableCell align="center" sx={{ width: "12%" }}>
                          {columnName.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matList.map((matrixDataRow, indexRow) => {
                    if (matrixDataRow.isRecesses !== 1) {
                      return (
                        <TableRow>
                          <TableCell
                            component="td"
                            scope="row"
                            sx={{ width: "12%" }}
                          >
                            {matrixDataRow.timeSlotName}
                          </TableCell>
                          {dayList.map((columnName, index) => {
                            let dayData = matrixDataRow.dayList.filter(
                              (item) => {
                                if (item.dayId === columnName.id) {
                                  return item.dayId === columnName.id;
                                }
                              }
                            );
                            let teacherData =
                              this.props.timeTableList.timeTableDetailsByTeacherId.filter(
                                (item) =>
                                  item.day === columnName.id &&
                                  item.timeSlot === matrixDataRow.timeSlotId
                              );
                            teacherData.length !== 0 &&
                              console.log(teacherData);
                            // if(){
                            return (
                              <TableCell
                                sx={{
                                  height: 100,
                                  background:
                                    (dayData[0].batchBusy &&
                                      dayData[0].teacherBusy &&
                                      "#FF2400") ||
                                    (dayData[0].teacherBusy === true
                                      ? "#7c81d6"
                                      : dayData[0].batchBusy
                                      ? "#fafa25"
                                      : ""),
                                }}
                                onClick={(e) =>
                                  (dayData[0].value.length !== 0 &&
                                    this.openTableDetails(
                                      dayData[0].checked,
                                      dayData[0].value,
                                      teacherData,
                                      columnName.name,
                                      matrixDataRow.timeSlotName,
                                      e
                                    )) ||
                                  (teacherData.length !== 0 &&
                                    this.openTableDetails(
                                      dayData[0].checked,
                                      dayData[0].value,
                                      teacherData,
                                      columnName.name,
                                      matrixDataRow.timeSlotName,
                                      e
                                    ))
                                }
                              >
                                {dayData[0].value.length === 0 && (
                                  <>
                                    {this.state.showCheckbox &&
                                    !dayData[0].teacherBusy &&
                                    !dayData[0].batchBusy ? (
                                      <Checkbox
                                        sx={{
                                          color: "#c7c8c9",
                                          "&.Mui-checked": {
                                            color: "#1976d2",
                                          },
                                          padding: "0px",
                                        }}
                                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={dayData[0].checked}
                                        onChange={(e) => {
                                          this.onSelectAllClick(
                                            columnName.id,
                                            matrixDataRow.timeSlotId,
                                            e
                                          );
                                        }}
                                        inputProps={{
                                          "aria-label": "select all desserts",
                                        }}
                                      />
                                    ) : (
                                      <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 12 }}
                                        label={" "}
                                      />
                                    )}
                                    <br />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 12 }}
                                      label={" "}
                                    />
                                    <br />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 12 }}
                                      label={" "}
                                    />
                                    <br />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 12 }}
                                      label={" "}
                                    />
                                    <br />
                                  </>
                                )}
                                {dayData[0].value.length !== 0 && (
                                  <>
                                    {!dayData[0].teacherBusy &&
                                    !dayData[0].batchBusy ? (
                                      <>
                                        <Checkbox
                                          sx={{
                                            color: "#c7c8c9",
                                            "&.Mui-checked": {
                                              color: "#1976d2",
                                            },
                                            padding: "0px",
                                          }}
                                          // indeterminate={numSelected > 0 && numSelected < rowCount}
                                          checked={dayData[0].checked}
                                          onChange={(e) => {
                                            this.onSelectAllClick(
                                              columnName.id,
                                              matrixDataRow.timeSlotId,
                                              e
                                            );
                                          }}
                                          inputProps={{
                                            "aria-label": "select all desserts",
                                          }}
                                        />
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        <LabelCompo
                                          className="text-black"
                                          style={{ fontSize: 12 }}
                                          label={" "}
                                        />
                                        <br />
                                      </>
                                    )}

                                    {dayData[0].value.length !== 2 &&
                                      dayData[0].value
                                        .slice(0, 3)
                                        .map((timeTableData) => {
                                          return (
                                            <>
                                              <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 12 }}
                                                label={
                                                  timeTableData.subjectDetail +
                                                  "(" +
                                                  timeTableData.batchShortName +
                                                  ")"
                                                }
                                              />
                                              <br />

                                              {dayData[0].value.length ===
                                                1 && (
                                                <>
                                                  <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 12 }}
                                                    label={
                                                      timeTableData.teacherShortName
                                                    }
                                                  />
                                                  <br />
                                                  <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 12 }}
                                                    label={" "}
                                                  />
                                                  <br />
                                                </>
                                              )}
                                              {/* <br /> */}
                                            </>
                                          );
                                        })}

                                    {
                                      dayData[0].value.length === 2 && (
                                        <>
                                          <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 12 }}
                                            label={
                                              dayData[0].value[0]
                                                .subjectDetail +
                                              " (" +
                                              dayData[0].value[0]
                                                .batchShortName +
                                              ")"
                                            }
                                          />
                                          <br />
                                          <br />
                                          <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 12 }}
                                            label={
                                              dayData[0].value[1]
                                                .subjectDetail +
                                              " (" +
                                              dayData[0].value[1]
                                                .batchShortName +
                                              ")"
                                            }
                                          />
                                        </>
                                      )
                                      // );
                                      // })
                                    }
                                  </>
                                )}
                              </TableCell>
                            );
                            // }
                          })}
                        </TableRow>
                      );
                    } else {
                      return (
                        <TableRow sx={{ height: 30 }}>
                          <TableCell
                            component="td"
                            scope="row"
                            sx={{ width: "12%" }}
                          >
                            {matrixDataRow.timeSlotName}
                          </TableCell>

                          <TableCell colSpan={dayList.length} align="center">
                            Reccess Time
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {this.state.openEditTimeTableDetails && (
            <EditTimeTableDetails
              open={this.state.openEditTimeTableDetails}
              handleCloses={this.handleClosesTimeTableDetails}
              // onSave={this.OnSave}
              editData={this.state.editTimeTableDetails}
            />
          )}
          {this.state.openTimeSlot && (
            <AddTimeSlot
              open={this.state.openTimeSlot}
              handleCloses={this.handleCloses}
              onSave={this.OnSave}
              value={this.state.value}
              timeSlot={this.state.timeSlot}
              allocationList={this.state.allocationList}
              day={this.state.day}
              editData={this.state.editData}
            />
          )}
          {this.state.openTimeSlotCell && (
            <ViewTimeTableCellPop
              open={this.state.openTimeSlotCell}
              handleCloses={this.handleClosesTimeSlotCell}
              rows={this.state.rows}
              columns={this.columns}
              teacherColumns={this.teacherColumns}
              timeSlot={this.state.timeSlot}
              day={this.state.day}
              state={this.state}
              teacherData={this.state.teacherData}
              showHeadEdit={true}
              showHeadDelete={true}
              rowDelete={this.onClickDelete}
              isActionColActive={true}
              rowEdit={this.onClickEdit}
              rowEditTimeTableDetails={this.onClickEditTimeTableDetails}
            />
          )}
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            sx={{ marginTop: 3 }}
            spacing={2}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={5} md={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Teacher Busy"
                />
              </Grid>

              <Grid item xs={12} sm={7} md={7}>
                {/* (dayData[0].batchBusy &&
 dayData[0].teacherBusy &&
 "#FF2400") ||
 (dayData[0].teacherBusy === true
 ? "#FF5C5C"
 : dayData[0].batchBusy
 ? "#FF8A8A"
 : ""), */}
                <Checkbox
                  sx={{
                    color: "#7c81d6",
                    "&.Mui-checked": {
                      color: "#7c81d6",
                    },
                    padding: "0px",
                  }}
                  checked={true}
                  disabled={true}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={4} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Batch Busy"
                />
              </Grid>

              <Grid item xs={12} sm={8} md={8}>
                <Checkbox
                  sx={{
                    color: "#fafa25",
                    "&.Mui-checked": {
                      color: "#fafa25",
                    },
                    padding: "0px",
                  }}
                  checked={true}
                  disabled={true}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={4} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Both Busy"
                />
              </Grid>

              <Grid item xs={12} sm={8} md={8}>
                <Checkbox
                  sx={{
                    color: "#FF2400",
                    "&.Mui-checked": {
                      color: "#FF2400",
                    },
                    padding: "0px",
                  }}
                  checked={true}
                  disabled={true}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}
// subjectBySemisterAndDepartment
const mapStateToProps = (state) => ({
  timeTableList: state.timeTable,
  workloadAllocList: state.workloadAllocate,
  batchList: state.batch,
  departmentList: state.department,
  subjectList: state.subjectInfo,
  employeeDetails: state.employeeList,
  rollNoData: state.rollNo,
});
const mapDispatchToProps = {
  saveTimeTableDetail,
  resetEmployee,
  getTimeTableDetailByHeaderId,
  getAlert,
  getBatchList,
  getDepartmentByYearDetailsId,
  getEmployeeByDeptId,
  getSubjectByDepartment,
  getSubjectSchemeBySubjectId,
  resetSubjectInfoGetById,
  getTimeTableDetailsByTeacherId,
  getTimeTableDetailsByBatchId,
  getSubjectBySemesterAndDepartmentId,
  showNotification,
  resetData,
};
export default connect(mapStateToProps, mapDispatchToProps)(MatrixForm);
