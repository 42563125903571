import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  alumini: [],
  isFetch: false,
  aluminiById: {},
  isFetchAlumini: false,
  aluminiList: [],
  isFetchAluminiList: false,
};
let URL = endpoints.alumniRegister;
let URL1 = endpoints.studentList;
const aluminiSlice = createSlice({
  name: "alumini",
  initialState,
  reducers: {
    aluminiSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        alumini: row,
        isFetch: true,
      };
    },
    aluminiByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { content } = action.payload;
      return {
        ...state,
        aluminiById: content,
        isFetchAlumini: true,
      };
    },
    aluminiListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        aluminiList: row,
        isFetchAluminiList: true,
      };
    },
  },
});

export const { aluminiSuccess, aluminiByIdSuccess, aluminiListSuccess } =
  aluminiSlice.actions;

export default aluminiSlice.reducer;

export const getAlumini = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name:
              data1.lastName + " " + data1.firstName + " " + data1.middleName,
              lastName: data1.lastName,
              firstName:   data1.firstName ,
              fatherName: data1.fatherName,
              motherName:   data1.motherName ,
            course: data1.course,
            alumniEmail: data1.alumniEmail,
            alumniMobile: data1.alumniMobile,
            passingYear:
              data1.passingYear !== "" ? data1.passingYear.slice(-4) : "",

            status: data1.status,
          };
          row.push(bData);
          return data1;
        });
        dispatch(aluminiSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAluminiById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;

          dispatch(aluminiByIdSuccess({ content }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//api/acdmc/v1/user-registration/student-list-for-alumni-registration?yearDetailId=c29a8b5f-b125-4507-81e5-880467971aeb
export const getAlumniListByYearDetail =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/student-list-for-alumni-registration?yearDetailId=" +
          yearDetail,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName:
                data1.lastName +
                " " +
                data1.firstName +
                " " +
                data1.middleName +
                " " +
                data1.motherName,
              
                lastName: data1.lastName,
                firstName:   data1.firstName ,
                fatherName: data1.fatherName,
                motherName:   data1.motherName ,
              studentEmail: data1.studentEmail,
              studentMobile: data1.studentMobile,
              // passingYear:data1.passingYear,
              // status:data1.status
            };
            row.push(bData);
            return data1;
          });
          dispatch(aluminiListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
