import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete } from "../../../utils/api_service";
import { getBatchBySemesterList } from "../../Batches/BatchList/batchlist.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getSemesterDetailsByYearAndAcademic,
  getStudentList,
} from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getWorkloadAllocatedList,
  resetValues,
} from "./WorkloadAllocation.slice";
import WorkloadAllocationForm from "./WorkloadAllocationForm";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    name: "subjectList",
    title: "Subject List",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    name: "subjectScheme",
    title: "Subject Scheme",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    name: "name",
    title: "Teacher Name",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
class WorkloadAllocation extends React.Component {
  state = {
    id: 0,
    academicYear: "",
    yearDetail: "",
    semester: "",
    division: "",
    formErrors: [],
    batch: "",
    workloadData: {},
    addWorkload: false,
    viewWorkload: true,
  };

  componentDidMount() {
    const { getYearDetails, getAlert, getAcademicYear } = this.props;

    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    getAcademicYear().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    const { yearDetail, academicYear } = this.state;
    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name == "yearDetail") {
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (academicYear !== "") {
          getSemesterDetailsByYearAndAcademic({
            yearId: newValue,
            academicId: academicYear,
          }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      } else {
        this.setState({ [name]: "" });
      }
    }

    if (name == "academicYear" && yearDetail !== "") {
      getSemesterDetailsByYearAndAcademic({
        yearId: yearDetail,
        academicId: newValue,
      }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }

    if (name == "semester") {
      this.props
        .getBatchBySemesterList({
          semisterID: newValue,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  handleFormValidation() {
    const { academicYear, yearDetail, division, semester, batch } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (academicYear.toString().trim() === "" || academicYear === null) {
      formIsValid = false;
      formErrors["academicYearError"] = myConstClass.acYearEmptyMsg;
    }

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }

    if (semester.toString().trim() === "" || semester === null) {
      formIsValid = false;
      formErrors["semesterError"] = myConstClass.semisterNameMsg;
    }

    if (batch.toString().trim() === "" || batch === null) {
      formIsValid = false;
      formErrors["batchError"] = myConstClass.batchMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { academicYear, yearDetail, division, semester, batch } = this.state;
    console.log(
      academicYear +
        " 1 " +
        yearDetail +
        " 2 " +
        division +
        " 3 " +
        semester +
        " 4 " +
        batch
    );
    if (this.handleFormValidation()) {
      this.props
        .getWorkloadAllocatedList({
          divisionId: division,
          semesterDetailId: semester,
          batchId: batch,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  goToList = () => {
    console.log("Go to List");
    this.setState({
      addWorkload: false,
      viewWorkload: true,
      academicYear: "",
      yearDetail: "",
      division: "",
      semester: "",
      batch: "",
    });
    this.props.resetValues();
  };

  goToForm = () => {
    this.setState({
      workloadData: {},
      addWorkload: true,
      viewWorkload: false,
    });
  };

  rowDelete = (data) => {
    const { academicYear, yearDetail, division, semester, batch } = this.state;
    const { getCompanyList } = this.props;
    console.log(data);
    swal({
      title: "Are you sure?",
      text: "Do you want to confirm Admission?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      console.log(data);
      if (res) {
        apiDelete({
          url: endpoint.employeeWorkload + "/" + data.id,
        }).then(({ data, success }) => {
          this.props.showNotification({ msg: "Data deleted successfully" });
          if (success) {
            this.props
              .getWorkloadAllocatedList({
                divisionId: division,
                semesterDetailId: semester,
                batchId: batch,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          }
        });
        // getAppliedEventList(data);
        //   this.onSave(data);
      }
    });
  };

  rowEdit = (data) => {
    console.log(data);
    this.setState({
      workloadData: data,
      addWorkload: true,
      viewWorkload: false,
    });

    // getInterestedStudById({ id: data.id }).then((response) => {
    //   if (!response) {
    //     getAlert({ message: "Something went wrong" });
    //   }
    // });
    // setStudDataById(getStudentListById?.getInterestedStudByIdStatus)
  };

  // rowDetails = (data) => {
  //   console.log(data);
  //   const { getInternshipDeclareById } = this.props;
  //   getInternshipDeclareById({
  //     id: data.internshipDeclareId,
  //   }).then((response) => {});

  //   this.setState({
  //     preview: true,
  //     interestedStudent: false,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };
  //  onChangeStatus = (data) => {
  //   const { fromDate, toDate, internType } = this.state;
  //   const { getCompanyList, changeInternshipDeclareStatus } = this.props;
  //   let fromDt = moment(fromDate).format("DD-MM-YYYY");
  //   let toDt = moment(toDate).format("DD-MM-YYYY");
  //   console.log(data);
  //   swal({
  //     title: "Are you sure?",
  //     text: "Do you want to confirm Admission?",
  //     icon: "warning",
  //     buttons: ["Cancel", true],
  //   }).then((res) => {
  //     console.log(data);
  //     if (res) {
  //       changeInternshipDeclareStatus({
  //         internshipDeclareId: data.internshipDeclareId,
  //         status: data.status === 1 ? 0 : 1,
  //       }).then((success) => {
  //         if (success) {
  //           getCompanyList({
  //             fromDate: fromDt,
  //             toDate: toDt,
  //             internType: internType,
  //           }).then((response) => {
  //             console.log("in getCompanyList after status change 1");
  //             if (!response) {
  //               console.log("in getCompanyList after status change");
  //               getAlert({ message: "Something went wrong" });
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
  // };
  // rowAppliedList = (data) => {
  //   console.log(data);
  //   console.log(data.internshipDeclareId);
  //   const { comanyData } = this.state;
  //   this.setState({
  //     interestedStudent: true,
  //     listOfComp: false,
  //     companyData: data,
  //   });
  // };

  // closeStudentFeesDetails = () => {
  //   console.log("Back from interested Student");
  //   this.setState({ interestedStudent: false, listOfComp: true });
  // };

  render() {
    const {
      yearDetail,
      academicYear,
      semester,
      division,
      batch,
      addWorkload,
      viewWorkload,
      workloadData,
    } = this.state;
    const {
      academicYearError,
      yearDetailError,
      divisionError,
      semesterError,
      batchError,
    } = this.state.formErrors;
    const {
      academicYearList,
      rollNoData,
      internshipTypeList,
      internshipDeclareCompanyList,
      internshipDeclarationList,
      goToList,
      batchList,
      workloadAllocList,
    } = this.props;
    console.log(workloadAllocList?.workloadAllocatedListSuccess);
    return (
      <>
        <div>
          <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
            {viewWorkload && (
              <>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  {" "}
                  <Grid item sx={{ ml: 1 }} xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="List of Workload Allocation"
                    />
                  </Grid>
                  <Grid
                    rowSpacing={2}
                    container
                    columnSpacing={1}
                    justifyContent="right"
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item sm={12} xs={12} md={1} lg={1}>
                      <MyComponentWithIconProps
                        statusImage={AddCircleIcon}
                        color="primary"
                        fontSize="large"
                        title="Add Employee Workload"
                        onClick={this.goToForm}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Academic Year"
                      />
                    </Grid>

                    <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={academicYear}
                        name={"academicYear"}
                        options={academicYearList?.academicYear}
                        onChange={this.ChangeHandlerSearch}
                        isError={academicYearError ? true : false}
                        errorText={academicYearError ? academicYearError : " "}
                      />
                      {/* <FormHelperText error>{academicYearError?academicYearError:' '}</FormHelperText> */}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Detail"
                      />
                    </Grid>

                    <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={internshipDeclarationList?.yearDetails}
                        onChange={this.ChangeHandlerSearch}
                        isError={yearDetailError ? true : false}
                        errorText={yearDetailError ? yearDetailError : " "}
                      />
                      {/* <FormHelperText error>{yearDetailError?yearDetailError:' '}</FormHelperText> */}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Semester"
                        />
                      </Grid>

                      <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={semester}
                          name={"semester"}
                          options={rollNoData?.semisterDetailsList}
                          onChange={this.ChangeHandlerSearch}
                          isError={semesterError ? true : false}
                          errorText={semesterError ? semesterError : " "}
                        />
                        {/* <FormHelperText error>{semesterError?semesterError:' '}</FormHelperText> */}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Division"
                        />
                      </Grid>

                      <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={division}
                          name={"division"}
                          options={internshipDeclarationList?.distinctDivision}
                          onChange={this.ChangeHandlerSearch}
                          isError={divisionError ? true : false}
                          errorText={divisionError ? divisionError : " "}
                        />
                        {/* <FormHelperText error>{divisionError?divisionError:' '}</FormHelperText> */}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Batch"
                        />
                      </Grid>

                      <Grid sx={{ ml: 1 }} item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={batch}
                          name={"batch"}
                          options={batchList?.batchListBySemester}
                          onChange={this.ChangeHandlerSearch}
                          isError={batchError ? true : false}
                          errorText={batchError ? batchError : " "}
                        />
                        {/* <FormHelperText error>{batchError?batchError:' '}</FormHelperText> */}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ marginTop: 5 }}
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        container
                        sx={{ display: "block" }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Search"
                          onClick={this.searchData}
                          sx={{ float: "left", display: "block", ml: 1 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{ p: 1 }}>
                  {" "}
                  <DynamicTable
                    data={workloadAllocList?.workloadAllocatedListSuccess}
                    tableHead={columns}
                    showHeadDelete={true}
                    showHeadEdit={true}
                    rowDelete={this.rowDelete}
                    rowEdit={this.rowEdit}
                    showPegination={false}
                  />
                  {/* <WorkloadAllocationTable
                    columns={columns}
                    showEditButton={true}
                    showDeleteButton={true}
                    tableData={workloadAllocList?.workloadAllocatedListSuccess}
                    rowDelete={this.rowDelete}
                    rowEdit={this.rowEdit}
                  /> */}
                </Grid>
              </>
            )}

            {addWorkload && (
              <WorkloadAllocationForm
                goToList={this.goToList}
                data={workloadData}
              />
            )}
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  batchList: state.batch,
  workloadAllocList: state.workloadAllocate,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  getStudentList,
  getSemesterDetailsByYearAndAcademic,
  getBatchBySemesterList,
  getWorkloadAllocatedList,
  resetValues,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkloadAllocation);
