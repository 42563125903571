import { Grid, TextField } from "@mui/material";
import moment from "moment";
import * as React from "react";
// import PasswordStrengthBar from "react-password-strength-bar";
import { connect } from "react-redux";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import {
  onlyNumber,
  validPassword,
} from "../../components/Comman/Util/Validations";
import Header from "../../components/NewHeader";
import * as myConstClass from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import {
  apiPostWithoutHeader,
  apiGetWithoutHeader,
} from "../../utils/api_service";
import swal from "sweetalert";
import Navbar from "../../components/Navbar";
import {
  showNotification,
  closeNotification,
} from "../../Pages/Landing/Landing.slice";
import NotificationToast from "../../components/Comman/NotificationToast";
let docs;
class ForgotPasswordPostLogin extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    userName: "",
    isActive: "1",
    otpCheck: "0",
    dob: "",
    enterOtp: "",
    confirmPassword: "",
    newPassword: "",
    formErrors: [],
    checkedValues: [],
    breadCrum: "",
    showForm: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    console.log(window.location.search);
    console.log(endpoint.linkSendOnEmail);
    apiGetWithoutHeader({
      url:
        endpoint.linkSendOnEmail + "/validate-token" + window.location.search,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showForm: true,
        });
      } else {
        swal({
          title: "Link expired..!!",
          text: "Please try again",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.replace("/login");
          }
        });

        // Swal.fire({
        //   icon: "success",
        //   title: "Password Reset Successfully",
        //   text:
        //     "  Your Password has been reseted successfully",
        //   showConfirmButton: true,
        //   confirmButtonColor: "#1f89f4",
        //   confirmButtonText: "Login",
        // }).then((okLogin) => {
        //   if (okLogin.isConfirmed) {
        //     navigate("/login");
        //   }
        // });

        // this.setState({
        //   showForm: false,
        // });
      }
    });
  }

  generateOtp = () => {
    const { userName, dob } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");
    if (this.handleFormValidation()) {
      if (this.state.userName != null && this.state.dob != null) {
        this.props.submitOtp(userName, birthdate);
      } else {
        this.setState({
          formErrors: {},
        });
      }
    }
  };
  validateOtp = () => {
    const { enterOtp, userName } = this.state;
    if (this.handleFormValidationOtp()) {
      if (this.state.enterOtp != null) {
        this.props.saveOtp(enterOtp, userName);
      }
    }
  };

  changeFromDate = (date) => {
    let frmDate = moment(date).format("DD/MM/YYYY");
    let fromYear = new Date(date).getFullYear();
    let month = new Date(date).getMonth();
    let day = new Date(date).getDate();
    let changedToDate = new Date(fromYear + 1, month, day - 1);
    let toYear = new Date(changedToDate).getFullYear();

    this.setState({
      dob: date,
    });
  };

  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpEmptyErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleFormValidation(name) {
    const { dob, userName, newPassword, confirmPassword, enterOtp } =
      this.state;

    let formErrors = {};
    let formIsValid = true;

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (userName === "") {
      formIsValid = false;
      formErrors["userNameErr"] = myConstClass.userNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  passwordFormValidation(name) {
    const { newPassword, confirmPassword } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (confirmPassword != newPassword) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }

    if (newPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (!validPassword.test(newPassword)) {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.specialPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  sendHandler = (e) => {
    e.preventDefault();
    const { id, userName, dob, enterOtp } = this.state;
    let birthdate = moment(dob).format("YYYY-MM-DD");

    docs = {
      id: id === 0 ? 0 : id,
      dob: birthdate,
      userName: userName,
      enterOtp: enterOtp,
    };
    if (this.handleFormValidation()) {
      this.props.OnSubmitOtp(docs);
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { confirmPassword } = this.state;

    swal({
      title: "Are you sure",
      text: "You want to change your password",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiPostWithoutHeader({
          url:
            endpoint.linkSendOnEmail +
            "/update-password" +
            window.location.search +
            "&password=" +
            confirmPassword,
          // postBody: confirmPassword,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Password updated successfully",
            });
            // localStorage.removeItem("token");
            // this.props.logOut();
            window.location.replace("/login");
          } else {
            this.props.showNotification({
              msg: "Failed to update password",
              severity: "error",
            });
          }
        });
      }
    });
  };

  closeSnak = () => {
    const { closeNotification } = this.props;
    closeNotification();
  };

  render() {
    const {
      dob,
      userName,
      enterOtp,
      contactNo,
      newPassword,
      confirmPassword,
      showForm,
    } = this.state;

    const {
      dobErr,
      enterPasswordErr,
      newPasswordErr,
      confirmPasswordErr,
      userNameErr,
      incorrectPasswordErr,
      otpEmptyErr,
    } = this.state.formErrors;

    const { landingData, passwordData } = this.props;

    return (
      <>
        <NotificationToast
          open={landingData.isShowNotification}
          severity={
            landingData.severity == undefined ? "success" : landingData.severity
          }
          msg={landingData.msg}
          closeSnak={this.closeSnak}
        />
        {showForm && (
          <>
            <div align="center">
              <Header />
              <Navbar />
              <div className="new_content">
                <div className="dash_login">
                  <Grid item container justifyContent="space-between">
                    <Grid item md={4}></Grid>
                    <Grid item md={4}>
                      <div className="lgn_bx">
                        <h3 className="lgn_title">Forgot Password</h3>

                        <div className="lgn_container">
                          <div className="user_lgn">
                            <div className="user_label">*Enter Password :</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="newPassword"
                                type="password"
                                size="small"
                                value={newPassword}
                                onChange={this.changedHandler}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                fullWidth
                                error={enterPasswordErr ? true : false}
                                helperText={
                                  enterPasswordErr ? enterPasswordErr : " "
                                }
                              />
                              {/* <PasswordStrengthBar password={newPassword} /> */}
                            </div>
                          </div>
                          <div className="user_lgn">
                            <div className="user_label">
                              *Confirm Password :
                            </div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="confirmPassword"
                                size="small"
                                type="password"
                                value={confirmPassword}
                                onChange={this.changedHandler}
                                fullWidth
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                {...(confirmPasswordErr
                                  ? {
                                      error: true,
                                      helperText: confirmPasswordErr,
                                    }
                                  : "")}
                                {...(incorrectPasswordErr
                                  ? {
                                      error: true,
                                      helperText: incorrectPasswordErr,
                                    }
                                  : "")}
                              />
                              {/* <PasswordStrengthBar
                                  password={confirmPassword}
                                /> */}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.submitHandler}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={4}></Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  landingData: state.landing,
  passwordData: state.userPassword,
});

const mapDispatchToProps = { showNotification, closeNotification };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordPostLogin);
