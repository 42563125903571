import { Grid, Paper, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import Alert from "../../CommonActions/Alert";
import { getAlert } from "../../CommonActions/alert.slice";
import { LabelCompo } from "../../components/Comman/Label";
import { validEmail } from "../../components/Comman/Util/Validations";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiGetWithoutHeader, apiPost } from "../../utils/api_service";
import { getOutsideStudentFeedbackQuestions } from "../AllowOutSideFeedbackEmployee/outsideemployee.slice";
import { showNotification } from "../Landing/Landing.slice";
import DynamicTable from "./OutStudentFeedbackTable";

function AllowOutSideFeedback({
  backToList,
  onCancel,
  alertInfo,
  employeeFeedbackDetailsList,
  getAlert,
  showNotification,
  getOutsideStudentFeedbackQuestions,
}) {
  const [values, setValues] = React.useState("");
  const [name, setName] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});
  let [searchParams, setSearchParams] = useSearchParams(window.location.search);
  const token = searchParams.get("token");
  const [feedBackHead, setFeedBackHead] = React.useState("");
  const [error, setError] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  // useEffect( () =>
  // {
  //     getOutsideStudentFeedbackQuestions( {
  //         id: "28c96862-d102-41d4-91c4-3a9950bc39ed"
  //     } );
  // }, [] );

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (name.toString().trim() === "" || name === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.nameMsgRequired;
    }

    if (email.toString().trim() === "" || !validEmail.test(email)) {
      if (email === "") {
        formIsValid = false;
        formErrors["emailError"] = myConstClass.emailIdMsg;
      } else if (!validEmail.test(email)) {
        formIsValid = false;
        formErrors["emailError"] = myConstClass.invalidEmailIdMsg;
      }
    }

    if (designation.toString().trim() === "" || designation === null) {
      formIsValid = false;
      formErrors["designationError"] = myConstClass.designationMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  useEffect(() => {
    apiGetWithoutHeader({
      url: endpoint.getStudHeadPreLogin + "?token=" + token,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data.data);
        setFeedBackHead(data.data.id);
        getOutsideStudentFeedbackQuestions({
          id: data.data.feedbackHead.id,
          token: token,
        }).then((response) => {
          if (!response) {
            setError(true);
          }
        });
      } else {
        setError(true);
      }
    });
  }, []);

  const setQuaData = (data) => {
    if (checkValidation()) {
      const dataToPush = data.map((data1) => {
        let bData = {
          feedbackHeadDetails: {
            id: data1.id,
          },
          value: data1.answer,
        };
        return bData;
      });

      let pushData = {
        studentFeedback: {
          id: feedBackHead,
        },
        name: name,
        emailId: email,
        designation: designation,
        studentFeedbackTransactionDetails: dataToPush,
      };
      console.log(pushData);
      swal({
        title: "Are you sure?",
        text: "Do you want to save the feedback?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          apiPost({
            url: endpoint.outsideStudentFeedbackSave + "?token=" + token,
            postBody: pushData,
          }).then(({ success }) => {
            if (success) {
              setMsg("Thanking you");
              setError(true);
              showNotification({ msg: "Data saved successfully" });
            } else {
              getAlert({
                message: "Failed to save",
              });
            }
          });
        }
      });
    }
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Questions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "action",
      title: "Answers",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name == "name") {
      setName(value);
    }
    if (name == "email") {
      setEmail(value);
    }
    if (name == "designation") {
      setDesignation(value);
    }
  };
  return (
    <>
      {" "}
      {alertInfo.show && <Alert msg={alertInfo.alertMsg} />}
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {!error ? (
          <>
            {" "}
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              sx={{ marginBottom: 2 }}
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={6} md={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Student Feedback Details"
                />
              </Grid>
              <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
                {/* <ButtonCompo
                            size="medium"
                            type="button"
                            variant="outlined"
                            name="Back to List"
                            onClick={onCancel}
                        /> */}
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              spacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="name"
                    size="small"
                    value={name}
                    onChange={changedHandler}
                    fullWidth
                    helperText={
                      formErrors.nameError ? formErrors.nameError : " "
                    }
                    error={formErrors.nameError ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Student Email"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="email"
                    size="small"
                    value={email}
                    onChange={changedHandler}
                    fullWidth
                    helperText={
                      formErrors.emailError ? formErrors.emailError : " "
                    }
                    error={formErrors.emailError ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              spacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Designation"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="designation"
                    size="small"
                    value={designation}
                    onChange={changedHandler}
                    fullWidth
                    helperText={
                      formErrors.designationError
                        ? formErrors.designationError
                        : " "
                    }
                    error={formErrors.designationError ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
            <DynamicTable
              data={
                employeeFeedbackDetailsList?.outsideStudentsFeedbackQuestions
              }
              tableHead={columns}
              showPegination={false}
              rowData={setQuaData}
              isActionColActive={false}
              checkValidation={checkValidation}
            />
          </>
        ) : (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              sx={{ marginTop: 5 }}
              justifyContent="center"
            >
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label={msg ? msg : "Invalid Link"}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  employeeFeedbackDetailsList: state.outSideEmployee,
  alertInfo: state.alertInfo,
});
const mapDispatchToProps = {
  getAlert,
  showNotification,
  getOutsideStudentFeedbackQuestions,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllowOutSideFeedback);
