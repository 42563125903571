import { Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import * as React from "react";
import AutoComplete from "../../../components/Comman/AutoComplete";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { showNotification } from "../../Landing/Landing.slice";

import PasswordStrengthBar from "react-password-strength-bar";
import { connect } from "react-redux";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";

import {
  onlyNumber,
  validEmail,
  textOnly,
} from "../../../components/Comman/Util/Validations";
import Navbar from "../../../components/Navbar";
import Header from "../../../components/NewHeader";
import * as myConstClass from "../../../config/messageconstant";
import { resetFlag } from "./AluminiRegister.slice";
import {
  getPreloginProgram,
  getPreloginProgramTitle,
} from "./AluminiRegister.slice";
import { getAlert } from "../../../Alert/alert.slice";
import { isExists } from "date-fns";
import { apiGetWithoutHeader } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { DirectionSnackbar } from "../../../components/Comman/SnakBarCompo";

let docs;
class AlumniRegisterForm extends React.Component {
  state = {
    id: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    genderList: [
      { id: "m", name: "Male", isActive: "Active" },
      { id: "f", name: "Female", isActive: "Active" },
      { id: "t", name: "Transgender", isActive: "Active" },
    ],
    dob: "",
    contactNo: "",
    emailId: "",
    passingYear: "",
    enterOtp: "",
    captcha: "",
    isActive: "1",
    formErrors: [],
    breadCrum: "",
    programTitle: "",
    program: "",
    phone: "",
    setError: false,
  };

  componentDidMount() {
    let date = new Date();
    console.log("INNNN");
    // this.props.getPreloginProgram();
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changeNameHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value !== "") {
      if (textOnly.test(event.target.value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
    if (name == "program") {
      this.props
        .getPreloginProgramTitle({ programId: newValue })
        .then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  validateOtp = () => {
    const { enterOtp } = this.state;
    if (this.handleFormValidationMobile()) {
      if (this.handleFormValidationOtp()) {
        if (this.state.enterOtp !== null) {
          this.props.submitOtp(enterOtp);
          this.props.getPreloginProgram().then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
        }
      }
    }
  };

  handleValidation(name) {
    const { firstName, middleName, lastName, gender, dob, emailId } =
      this.state;
    // let validEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    let formErrors = {};
    let formIsValid = true;

    if (firstName === "") {
      formIsValid = false;
      formErrors["firstNameErr"] = myConstClass.firstNameMsg;
    }

    if (lastName === "") {
      formIsValid = false;
      formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    }

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    } else {
      let year = dob.getFullYear();
      if (year < 1900) {
        formIsValid = false;
        formErrors["dobErr"] = "Please enter valid date";
      }
    }

    // if (dob === "" ) {
    //   formIsValid = false;
    //   formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    // }

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    if (!validEmail.test(emailId)) {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.invalidEmailIdMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleFormValidation(name) {
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      contactNo,
      emailId,
      passingYear,
      programTitle,
      program,
      captcha,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (firstName === "") {
      formIsValid = false;
      formErrors["firstNameErr"] = myConstClass.firstNameMsg;
    }

    if (lastName === "") {
      formIsValid = false;
      formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    }

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (programTitle === "") {
      formIsValid = false;
      formErrors["programTitleErr"] = myConstClass.programTitleMsg;
    }
    if (program === "") {
      formIsValid = false;
      formErrors["programErr"] = myConstClass.programMsg;
    }

    if (passingYear === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    let fromYear = new Date().getFullYear();
    if (passingYear != "") {
      if (1984 > passingYear || passingYear > fromYear) {
        formIsValid = false;
        formErrors["passingYearError"] =
          "Passing Year must be between 1984 and current year";
      }
    }
    // if (contactNo === "" || contactNo.length !== 10) {
    //   if (contactNo === "") {
    //     formIsValid = false;
    //     formErrors["contactNoErr"] = myConstClass.contactNoMsg;
    //   } else if (contactNo.length <= 9 || contactNo.length > 10) {
    //     formIsValid = false;
    //     formErrors["contactNumberLenErr"] = myConstClass.contactNoLengthMsg;
    //   }
    // }

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    if (!validEmail.test(emailId)) {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.invalidEmailIdMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["enterOtpErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidationMobile(name) {
    const { contactNo } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (contactNo === "") {
      formIsValid = false;
      formErrors["phoneError"] = myConstClass.mobileMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeFromDate = (date) => {
    this.setState({
      dob: date,
    });
  };

  saveHandler = (e) => {
    e.preventDefault();
    const { firstName, middleName, lastName, gender, dob, emailId } =
      this.state;
    let dateOF = moment(dob).format("DD-MM-YYYY");

    docs = {
      //id: id === 0 ? 0 : id,
      user: {
        dateOfBirth: dateOF,
        emailId: emailId,
      },
      userRegistrationSave: {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        gender: gender,
      },
    };
    if (this.handleValidation()) {
      this.props.saveNext(docs);
    }
  };
  generateOtp = () => {
    const { emailId, contactNo } = this.state;

    if (this.handleFormValidationMobile()) {
      if (this.state.emailId !== null) {
        apiGetWithoutHeader({
          url:
            endpoint.preloginAlumniRegister +
            "/check-unique-mobile-no?baseUrl=" +
            window.location.host +
            "&mobileNo=" +
            contactNo,
        }).then(({ data }) => {
          if (!data.data.error) {
            this.props.saveMobile(emailId, contactNo);
          } else {
            this.setState({
              contactNo: "",
              setError: true,
            });
          }
        });
      }
    }
  };

  editContactNo = () => {
    const { resetFlag } = this.props;
    resetFlag();
  };

  handleOnChange = (value) => {
    console.log(value);
    this.setState({ contactNo: value }, () => {});
  };

  closeHandler = () => {
    this.setState({
      setError: false,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      contactNo,
      emailId,
      passingYear,
      programTitle,
    } = this.state;
    let dateOF = moment(dob).format("DD-MM-YYYY");
    const courseName = this.props.studentData?.preloginProgramTitleList.filter(
      (row) => {
        return row.id === programTitle;
      }
    );
    console.log(courseName);
    docs = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      gender: gender,
      dateOfBirth: dateOF,
      alumniEmail: emailId,
      alumniMobile: contactNo,
      course: courseName[0].name,
      passingYear: passingYear,
    };
    console.log(docs);
    if (this.handleFormValidation()) {
      console.log("Befor OnSave");
      this.props.OnSave(docs);
    }
  };
  render() {
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      genderList,
      contactNo,
      emailId,
      program,
      programTitle,
      passingYear,
      captcha,
      enterOtp,
      phone,
      setError,
    } = this.state;

    const {
      firstNameErr,
      middleNameErr,
      lastNameErr,
      dobErr,
      contactNoErr,
      emailIdErr,
      enterPasswordErr,
      confirmPasswordErr,
      incorrectPasswordErr,
      namePasswordErr,
      contactNumberLenErr,
      specialPasswordErr,
      invalidEmailIdErr,
      captchaErr,
      enterOtpErr,
      programTitleErr,
      programErr,
      phoneError,
      passingYearError,
    } = this.state.formErrors;

    const { onCancel, studentData } = this.props;
    console.log(studentData?.preloginProgramList);
    console.log(studentData?.preloginProgramTitleList);
    return (
      <>
        {setError && (
          <DirectionSnackbar
            open={true}
            severity={"error"}
            msg={"Mobile Number Is Already Been Used.."}
            closeSnak={this.closeHandler}
          />
        )}
        <div align="center">
          {/* <Header/> */}
          <Header />
          <Navbar />
          <div className="new_content">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Registration</h3>

                    <div className="lgn_container">
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*First Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="firstName"
                              size="small"
                              value={firstName}
                              onChange={this.changeNameHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*First Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="firstName"
                              size="small"
                              value={firstName}
                              onChange={this.changeNameHandler}
                              fullWidth
                              error={firstNameErr ? true : false}
                              helperText={firstNameErr ? firstNameErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">Middle Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="middleName"
                              size="small"
                              value={middleName}
                              onChange={this.changeNameHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">Middle Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="middleName"
                              size="small"
                              value={middleName}
                              onChange={this.changeNameHandler}
                              fullWidth
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*Last Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="lastName"
                              size="small"
                              value={lastName}
                              onChange={this.changeNameHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn fmlnm">
                          <div className="user_label">*Last Name :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="lastName"
                              size="small"
                              value={lastName}
                              onChange={this.changeNameHandler}
                              fullWidth
                              error={lastNameErr ? true : false}
                              helperText={lastNameErr ? lastNameErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn genDob">
                          <div className="user_label">Gender :</div>
                          <div className="user_field">
                            <TextField
                              color="primary"
                              name="gender"
                              size="small"
                              value={
                                gender === "m"
                                  ? "Male"
                                  : gender === "f"
                                  ? "Female"
                                  : gender === "t"
                                  ? "Transgender"
                                  : ""
                              }
                              onChange={this.changedHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn genDob">
                          <div className="user_label">Gender :</div>
                          <div className="user_field">
                            <AutoComplete
                              // sx={{ marginTop: -2 }}
                              keyColName={"id"}
                              value={gender}
                              name={"gender"}
                              fullWidth
                              options={genderList}
                              onChange={this.ChangeHandlerSearch}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn genDob">
                          <div className="user_label">*D.O.B :</div>
                          <div className="user_field">
                            <BasicDatePicker
                              name="dob"
                              id="dob"
                              value={dob}
                              maxDate={new Date()}
                              onChange={this.changeFromDate}
                              disabled
                              error={" "}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn genDob">
                          <div className="user_label">*D.O.B :</div>
                          <div className="user_field">
                            <BasicDatePicker
                              name="dob"
                              id="dob"
                              value={dob}
                              maxDate={new Date()}
                              onChange={this.changeFromDate}
                              isError={dobErr ? true : false}
                              errorText={dobErr ? dobErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <div className="user_lgn">
                          <div className="user_label">*Email ID :</div>
                          <div className="user_field">
                            <TextField
                              placeholder="abcd@gmail.com"
                              color="primary"
                              name="emailId"
                              size="small"
                              value={emailId}
                              onChange={this.changedHandler}
                              fullWidth
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="user_lgn">
                          <div className="user_label">*Email ID :</div>
                          <div className="user_field">
                            <TextField
                              placeholder="abcd@gmail.com"
                              color="primary"
                              name="emailId"
                              size="small"
                              value={emailId}
                              onChange={this.changedHandler}
                              fullWidth
                              error={emailIdErr ? true : false}
                              helperText={emailIdErr ? emailIdErr : " "}
                            />
                          </div>
                        </div>
                      )}
                      {studentData.isMailUnique &&
                      studentData.msg === "Email Id Is Unique.." ? (
                        <></>
                      ) : (
                        <div className="lgn_btn">
                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.saveHandler}
                          >
                            Proceed
                          </button>

                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={onCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                      <br />
                      {studentData.isMailUnique &&
                        studentData.msg === "Email Id Is Unique.." &&
                        !studentData.isGetOTP &&
                        studentData.GetOTPmsg === "" && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Contact Number :
                              </div>
                              <div className="user_field">
                                <ReactPhoneInput
                                  inputExtraProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: true,
                                  }}
                                  country={"in"}
                                  value={this.state.contactNo}
                                  onChange={this.handleOnChange}
                                  dropdownStyle={{ height: "150px" }}
                                />
                              </div>
                            </div>
                            {phoneError && (
                              <FormHelperText error>
                                {phoneError}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      {studentData.isMailUnique &&
                        studentData.msg === "Email Id Is Unique.." &&
                        studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Contact Number :
                              </div>
                              <div className="user_field">
                                <ReactPhoneInput
                                  inputExtraProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: true,
                                  }}
                                  country={"in"}
                                  value={this.state.contactNo}
                                  onChange={this.handleOnChange}
                                  dropdownStyle={{ height: "50px" }}
                                  disabled
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {studentData.isMailUnique &&
                        studentData.msg === "Email Id Is Unique.." &&
                        !studentData.isGetOTP && (
                          <>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.generateOtp}
                            >
                              Proceed
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}

                      <br />
                      {studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">*Enter OTP</div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="enterOtp"
                                  size="small"
                                  value={enterOtp}
                                  onChange={this.changeHandler}
                                  fullWidth
                                  error={enterOtpErr ? true : false}
                                  helperText={enterOtpErr ? enterOtpErr : " "}
                                  disabled={
                                    studentData.isValidateOtp &&
                                    studentData.getValidateOtpmsg ===
                                      "Otp verified.."
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      {studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." &&
                        !studentData.isValidateOtp && (
                          <>
                            <div
                              style={{
                                display: "block",
                                padding: " 0 10px 15px",
                              }}
                            >
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.validateOtp}
                              >
                                Proceed
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.generateOtp}
                              >
                                Resend
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.editContactNo}
                              >
                                Edit Contact no.
                              </button>
                            </div>
                          </>
                        )}
                      {studentData.isGetOTP &&
                        studentData.GetOTPmsg ===
                          "Otp has been send successfully.." &&
                        studentData.isValidateOtp &&
                        studentData.getValidateOtpmsg === "Otp verified.." && (
                          <></>
                        )}

                      {studentData.isValidateOtp &&
                        studentData.getValidateOtpmsg === "Otp verified.." && (
                          <>
                            {/* programTitle dropdown */}
                            <div className="user_lgn genDob">
                              <div className="user_label">*Program</div>
                              <div className="user_field">
                                <AutoComplete
                                  // sx={{ marginTop: -2 }}
                                  keyColName={"id"}
                                  value={program}
                                  name={"program"}
                                  fullWidth
                                  options={studentData?.preloginProgramList}
                                  onChange={this.ChangeHandlerSearch}
                                />
                                {/* <TextField
                                  color="primary"
                                  name="enterPassword"
                                  size="small"
                                  type="password"
                                  value={enterPassword}
                                  onChange={this.changedHandler}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  fullWidth
                                  error={enterPasswordErr ? true : false}
                                  helperText={
                                    enterPasswordErr ? enterPasswordErr : " "
                                  }
                                /> */}
                                {/* <PasswordStrengthBar password={enterPassword} /> */}
                              </div>
                            </div>
                            <div className="user_lgn genDob">
                              <div className="user_label">*Course</div>
                              <div className="user_field">
                                <AutoComplete
                                  // sx={{ marginTop: -2 }}
                                  keyColName={"id"}
                                  value={programTitle}
                                  name={"programTitle"}
                                  fullWidth
                                  options={
                                    studentData?.preloginProgramTitleList
                                  }
                                  onChange={this.ChangeHandlerSearch}
                                />
                                {/* <TextField
                                  color="primary"
                                  name="enterPassword"
                                  size="small"
                                  type="password"
                                  value={enterPassword}
                                  onChange={this.changedHandler}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  fullWidth
                                  error={enterPasswordErr ? true : false}
                                  helperText={
                                    enterPasswordErr ? enterPasswordErr : " "
                                  }
                                /> */}
                                {/* <PasswordStrengthBar password={enterPassword} /> */}
                              </div>
                            </div>
                            <div className="user_lgn">
                              <div className="user_label">*Passing Year :</div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="passingYear"
                                  size="small"
                                  value={passingYear}
                                  onChange={this.changedHandler}
                                  fullWidth
                                  error={passingYearError ? true : false}
                                  helperText={
                                    passingYearError ? passingYearError : " "
                                  }
                                  onKeyPress={(event) => {
      if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
      }
  }}
  inputProps={{ maxLength: 4 }}
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.submitHandler}
                            >
                              Register
                            </button>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                </Grid>

                <Grid item md={3}></Grid>
              </Grid>
              {/* onClick={onApiCalled} */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentData: state.alumniRegister,
});

const mapDispatchToProps = {
  getPreloginProgram,
  getPreloginProgramTitle,
  resetFlag,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlumniRegisterForm);
