import { Grid, Link, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getBatchByID,
  getStudentListByBatchId,
} from "../BatchList/batchlist.slice";
import { getBatchList } from "./batchlist.slice";
class BatchListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cancelHandler = () => {
    console.log("data1111");
    this.props.CancelHandler();
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "rollNo",
      title: "Roll no",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
  ];

  render() {
    const { studentData, batchListData } = this.props;
    console.log(batchListData);
    console.log(studentData);
    return (
      <>
        <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={1.5} md={1.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={"Batch : "}
                />
              </Grid>
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={4.5} md={4.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={batchListData.name + "-" + batchListData.shortName}
                />
              </Grid>
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={"No Of Students : "}
                />
              </Grid>
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={4} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={batchListData.studentCount}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={1.5} md={1.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={"Year details : "}
                />
              </Grid>
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={4.5} md={4.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={
                    batchListData.yearDetail +
                    " (" +
                    batchListData.semisterName +
                    ")"
                  }
                />
              </Grid>
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={"Division : "}
                />
              </Grid>
              <Grid sx={{ marginBottom: 2 }} item xs={12} sm={4} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={batchListData.division}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid sx={{ mr: 2 }} item xs={12} sm={12} md={12}>
            <DynamicTable
              data={studentData}
              tableHead={this.columns}
              showPegination={false}
              isActionColActive={false}
            />
          </Grid>
          <div align="right">
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="right"
                sx={{ mt: 1, mr: 1 }}
              >
                <Link
                  color="primary"
                  variant="body2"
                  component={RouterLink}
                  to="/batch-list"
                  sx={{ textDecoration: "none" }}
                >
                  <ButtonCompo
                    size="button"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    {...(this.props.studentData !== undefined
                      ? { onClick: this.cancelHandler }
                      : "")}
                  />
                </Link>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  batchList: state.batch,
});
const mapDispatchToProps = {
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  getBatchList,
  getSemesterDetailsByYearAndAcademic,
  showNotification,
  getBatchByID,
  getStudentListByBatchId,
};
export default connect(mapStateToProps, mapDispatchToProps)(BatchListForm);
