import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import QualificationDetails from "../QualificationDetails/index";
import StudAddress from "./AddressDetails";
import PersonalDetailsForm from "./PersonalDetailsForm";

const StudentMaster = ({ onSubjectSelection, onCanclePersonal }) => {
  const [value, setValue] = React.useState("1");
  const [viewDeclr, setViewDeclr] = React.useState(false);

  // let navigate = useNavigate();

  const data = (instData) => {
    setValue("2");
  };

  const studAddressData = (instData) => {
    setValue("3");
  };

  useEffect(() => {}, []);

  const changeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const gotoPersonalDetails = () => {
    setValue("1");
  };

  const gotoAddressDetails = () => {
    setValue("2");
  };

  // const goToPhotoAndSign = () => {
  //   console.log("hi");
  //   setValue("4");
  // };

  // const gotoInstrestedProgram = () => {
  //   setValue("4");
  // };
  const goToAddmissionForm = () => {
    onCanclePersonal();
  };

  const goToSubjectSelection = () => {
    onSubjectSelection();
  };

  return (
    <>
      {" "}
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        {!viewDeclr && (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={changeTabs}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    icon={<InfoIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Basic Details"
                    value="1"
                  />
                  <Tab
                    icon={<HomeIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Address Details"
                    value="2"
                  />

                  <Tab
                    icon={<PhotoSizeSelectActualIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Document And Qualification"
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                {
                  <PersonalDetailsForm
                    OnSave={data}
                    onCancel={goToAddmissionForm}
                  />
                }
              </TabPanel>

              <TabPanel value="2">
                {
                  <StudAddress
                    onCancel={gotoPersonalDetails}
                    studAddressData={studAddressData}
                  />
                }
              </TabPanel>

              <TabPanel value="3">
                {
                  <QualificationDetails
                    getQualiDetails={goToSubjectSelection}
                    getBackToAddress={gotoAddressDetails}
                  />
                }
              </TabPanel>
            </TabContext>
          </Box>
        )}
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(StudentMaster);
