import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import { showNotification } from "../Landing/Landing.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class SuggestionsStudentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.rows !== nextProps.rows) {
      this.setState({
        rowList: nextProps.rows,
      });
    }
  }

  onClickRead = (id) => {
    const rows = this.state.rowList.map((rowData) => {
      if (rowData.id === id) {
        rowData = {
          ...rowData,
          open: !rowData.open,
        };
        return rowData;
      }
      return rowData;
    });
    this.setState({
      rowList: rows,
    });
  };
  render() {
    return (
      <>
        <TableContainer component={Paper} variant="scrollable">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="50" align="center">
                  Sr. No.
                </TableCell>
                <TableCell width="200" align="center">
                  Suggestions
                </TableCell>
                <TableCell width="70" align="center">
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rowList.map((row, index) => (
                <>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>

                    <TableCell>
                      {!row.open &&
                      row.complaintDetail.length >
                        row.complaintDetail.slice(0, 70).length
                        ? row.complaintDetail.slice(0, 70) + " ... "
                        : row.complaintDetail.slice(0, 70)}{" "}
                      {!row.open &&
                        row.complaintDetail !==
                          row.complaintDetail.slice(0, 70) && (
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            label={" Read more"}
                            onClick={() => this.onClickRead(row.id)}
                          />
                        )}
                      {row.open && row.complaintDetail}
                      {row.open &&
                        row.complaintDetail.length >
                          row.complaintDetail.slice(0, 70).length && (
                          <LabelCompo
                            className="text-black"
                            style={{
                              fontSize: 16,
                              textDecoration: "underline",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            label={"Read less"}
                            onClick={() => this.onClickRead(row.id)}
                          />
                        )}
                    </TableCell>
                    <TableCell component="th" align="center" scope="row">
                      {row.date}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionsStudentTable);
