import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { FeeTypeColumns } from "../../../../tableColumns/table-columns";
import {feeType} from "../../../../DynamicFormsJson/FeeType"
import {getFeeType} from "./feeType.slice"
import {getAlert}from "../../../../CommonActions/alert.slice";
class FeeType extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
        const { getFeeType } = this.props;
        getFeeType().then((response)=>{
            if(!response){
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
    }

    render() {

        const { feeeTypeList } = this.props;
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={feeType.screenTitle}
                        fieldMeta={feeType.fieldMeta}
                        showPdfDownload={feeType.showPdfDownload}
                        showExcelDownload={feeType.showExcelDownload}
                        pdfFileName={feeType.pdfFileName}
                        excelFileName={feeType.excelFileName}
                        showAddButton={feeType.showAddButton}
                        tableColumnsToFilter={feeType.tableColumnsToFilter}
                        tableColumns={FeeTypeColumns}
                        tableData={feeeTypeList.feeType}
                        getTableData={this.props.getFeeType}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={feeeTypeList.isFetchFeeType}
                        baseIdColumn={feeType.baseIdColumn}
                        apiBaseURL={feeType.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    feeeTypeList: state.feeType
});
const mapDispatchToProps = {
    getFeeType,getAlert
}
export default connect(mapStateToProps, mapDispatchToProps)(FeeType);