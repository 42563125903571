import { Paper } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import endpoints from "../../../config/endpoints";
import { studentApplyForExamJson } from "../../../DynamicFormsJson/StudentApplyForExam";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getCertificateListOfStudent } from "../../Certificate/Student/studentCertificate.slice";
import { getExamDetailForStudent } from "../../ExamFormDeclaration/examform.slice";
import StudentApplyMatrix from "./StudentApplyMatrix";
import {
  getSchemeHeadStudentExam,
  getSubjectStudentExam,
  getStudentExamAppliedSubject,
  getSchemeHeadStudentExamDeclare,
  getSubjectStudentExamDeclare,
  getStudentExamAppliedSubjectDeclare,
} from "../../ExamFormDeclaration/examform.slice";
const StudentApply = ({
  getCertificateListOfStudent,
  studentCertificateList,
  getAlert,
  showNotification,
  getExamDetailForStudent,
  studentExamList,
  getSchemeHeadStudentExam,
  getSubjectStudentExam,
  getStudentExamAppliedSubject,
  getSchemeHeadStudentExamDeclare,
  getSubjectStudentExamDeclare,
  getStudentExamAppliedSubjectDeclare,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [showTable, setShowTable] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [type, setType] = useState("");
  const [examListData, setExamListData] = useState("");

  useEffect(() => {
    setShowLoader(true);
    const parentChildId = localStorage.getItem("parentChildId");
    getExamDetailForStudent({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowLoader(false);
      }
    });
  }, []);

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "examTitle",
      title: "Exam Title",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "startDate",
      title: "From Date",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },

    {
      name: "endDate",
      title: "To Date",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "action",
      title: "Actions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  const rowView = (data) => {
    setExamListData(data);
    setDetailId(data.id);
    setShowPopUp(true);
    setShowTable(false);
    if (data.declareResult == 0 || data.declareResult == 1) {
      getSchemeHeadStudentExam({
        semisterDetailId: data.semesterDetailId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });

      getSubjectStudentExam({ semisterDetailId: data.semesterDetailId }).then(
        (response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        }
      );

      getStudentExamAppliedSubject({
        semisterDetailId: data.semesterDetailId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    } else {
      getSchemeHeadStudentExamDeclare({
        semisterDetailId: data.semesterDetailId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });

      getSubjectStudentExamDeclare({
        semisterDetailId: data.semesterDetailId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });

      getStudentExamAppliedSubjectDeclare({
        semisterDetailId: data.semesterDetailId,
      }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  const handleCloses = () => {
    setShowTable(true);
    setShowPopUp(false);
  };

  const OnSave = (datatoSave) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        const formData = new FormData();
        formData.append("file", datatoSave.file);
        apiPost({
          url: endpoints.fileUpload,
          postBody: formData,
        }).then(({ data, success }) => {
          if (success) {
            let docs = {
              certificateApplicationHeader: {
                id: detailId,
              },
              detail: datatoSave.details,
              userRemark: datatoSave.userRemark,
              type: type,
              documentUpload: data,
              is_paid: 0,
              payDetail: "",
              amount: 0,
              status: 0,
              deliveryDate: "",
            };
            apiPost({
              url: endpoints.certificate,
              postBody: docs,
            }).then(({ success }) => {
              if (success) {
                showNotification({
                  msg: "Applied Successfully",
                });
                setShowTable(true);
                setShowPopUp(false);
              } else {
                getAlert({
                  message: "Failed to save",
                });
                setShowTable(true);
                setShowPopUp(false);
              }
            });
          } else {
            getAlert({
              message: "Failed to upload document",
            });
            setShowTable(true);
            setShowPopUp(false);
          }
        });
      }
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  const getFilteredTableData = () => {
    return studentCertificateList?.certificateListOfStudent.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["certificateList"] &&
            currentRow["certificateList"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["payment"] &&
            currentRow["payment"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["index"] &&
            currentRow["index"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  const closeMatrix = () => {
    const parentChildId = localStorage.getItem("parentChildId");
    getExamDetailForStudent({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        setShowLoader(false);
        getAlert({ message: "Something went wrong" });
      } else {
        setShowPopUp(false);
        setShowTable(true);
        setShowLoader(false);
      }
    });
  };
  return (
    <>
      {showTable && (
        <>
          <DynamicMainScreen
            screenTitle={studentApplyForExamJson.screenTitle}
            fieldMeta={[]}
            buttonCenter={studentApplyForExamJson.buttonCenter}
            showPdfDownload={studentApplyForExamJson.showPdfDownload}
            showExcelDownload={studentApplyForExamJson.showExcelDownload}
            pdfFileName={studentApplyForExamJson.pdfFileName}
            excelFileName={studentApplyForExamJson.excelFileName}
            tableColumnsToFilter={[]}
            showAddButton={studentApplyForExamJson.showAddButton}
            dynamicMasterData={[]}
            showLoader={showLoader}
            searchButton={false}
            tableColumns={columns}
            tableData={studentExamList?.examDetailsStudent}
            showPegination={false}
            showHeadEdit={false}
            showHeadDelete={false}
            showApply={true}
            rowView={rowView}
            // onAddButtonClick={this.OpenForm}
            // getListById={this.getListById}
            // showPegination={false}
            // pdfDetailsId={this.pdfDetails}
            // rowViewData={this.rowViewData}
            // rowDelete={this.rowDelete}
            // rowEdit={this.rowEdit}
            // onDelete={this.onDelete}
            // onSearchData={this.onSearchData}
            // baseIdColumn={studentApplyForExamJson.baseIdColumn}
            // apiBaseURL={studentApplyForExamJson.apiBaseURL}
          />
        </>
      )}
      {showPopUp && (
        <>
          <StudentApplyMatrix
            onCloseMat={closeMatrix}
            examListData={examListData}
            handleCloses={handleCloses}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  studentCertificateList: state.studentCertificate,
  studentExamList: state.examDeclare,
});
const mapDispatchToProps = {
  getCertificateListOfStudent,
  getAlert,
  showNotification,
  getExamDetailForStudent,
  getSchemeHeadStudentExam,
  getSubjectStudentExam,
  getStudentExamAppliedSubject,
  getSchemeHeadStudentExamDeclare,
  getSubjectStudentExamDeclare,
  getStudentExamAppliedSubjectDeclare,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentApply);
