import endpoint from "../config/endpoints";

export const PaticipationDetailsJson = {
  apiBaseURL: endpoint.courseAttended,
  screenTitle: "Participation Details",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Visiting Lecturer",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "visitingLecturer",
      isMandatory: true,
    },
    {
      label: "Details",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "details",
      isMandatory: true,
    },
    {
      label: "Invited As",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "resourcePerson",
      dataKey: "resourcePerson",
      isMandatory: true,
      isRootLevelKey: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "resourcePerson",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "resourcePersonNew",
      tableDataKey: "resourcePerson",
      open: "other",
      error: "Resource Person",
    },
    {
      label: "Level",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "LevelMaster",
      dataKey: "level",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "LevelMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "levelData",
      tableDataKey: "level",
      open: "other",
      error: "Level",
    },
    {
      label: "Duration",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "duration",
      isMandatory: true,
    },
  ],
};
