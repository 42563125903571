import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { LabelCompo } from "../../components/Comman/Label";
import { apiGet } from "../../utils/api_service";
import endpoints from "../../config/endpoints";
import { getAlert } from "../../CommonActions/alert.slice";
import { connect } from "react-redux";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ getAlert, data, classes }) => {
  const [bloodGroup, setBloodGroup] = useState("");
  const [religion, setReligion] = useState("");
  const [category, setCategory] = useState("");
  const [accessRole, setAccessRole] = useState("");
  useEffect(() => {
    if (Object.keys(data).length != 0) {
      if (bloodGroup === "" && bloodGroup !== null) {
        if (data.bloodGroup !== null && data.bloodGroup !== "") {
          setBloodGroup(null);
          apiGet({
            url: endpoints.bloodGroup + "/" + data.bloodGroup,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setBloodGroup(data.data.name);
            }
          });
        } else if (data.bloodGroup === null || data.bloodGroup === "") {
          setBloodGroup(" - ");
        }
      }
      if (category === "" && category !== null) {
        if (data.categoryId !== null && data.categoryId !== "") {
          setCategory(null);
          apiGet({
            url: endpoints.category + "/" + data.categoryId,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setCategory(data.data.name);
            }
          });
        } else if (data.categoryId === null || data.categoryId === "") {
          setCategory(" - ");
        }
      }
      if (religion === "" && religion !== null) {
        if (data.religionId !== null && data.religionId !== "") {
          setReligion(null);
          apiGet({
            url: endpoints.religion + "/" + data.religionId,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setReligion(data.data.name);
            }
          });
        } else if (data.religionId === null || data.religionId === "") {
          setReligion(" - ");
        }
      }
      if (accessRole === "" && accessRole !== null) {
        if (data.accessRole !== null && data.accessRole !== "") {
          setAccessRole(null);
          apiGet({
            url: endpoints.roleAccess + "/" + data.accessRole,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setAccessRole(data.data.empTypeName);
            }
          });
        } else if (data.accessRole === null || data.accessRole === "") {
          setAccessRole(" - ");
        }
      }
    }
  });
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Personal Information"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Employee Name
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.lastName +
                "    " +
                data.firstName +
                "      " +
                data.middleName}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Employee Code
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.employeeCode}
            </td>
            {/* <td className={classes.MuiTableHeadRoot}>&nbsp;{data.userRegistration.fatherName}</td> */}
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Library ID
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.libraryNo}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Gender
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.gender === "m"
                ? "Male"
                : data.gender === "f"
                ? "Female"
                : "Transgender"}
            </td>
            {/* <td className={classes.MuiTableHeadRoot}>&nbsp;{data.userRegistration.fatherName}</td> */}
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Date of Birth
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.dateOfBirth === ""
                ? "-"
                : data.dateOfBirth === null
                ? "-"
                : data.dateOfBirth}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Birth Place
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.birthplace === ""
                ? "-"
                : data.birthplace === null
                ? "-"
                : data.birthplace}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Blood Group
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {bloodGroup === "" ? "-" : bloodGroup === null ? "-" : bloodGroup}
            </td>
          </tr>
          {/* <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Medium ( Language in previous college )
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.medium===''?'-':data.medium===null?'-':data.medium}
            </td>
          </tr> */}
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Contact Number
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.contactNo === ""
                ? "-"
                : data.contactNo === null
                ? "-"
                : data.contactNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Email-Id
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.emailId === ""
                ? "-"
                : data.emailId === null
                ? "-"
                : data.emailId}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Marital Status
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.martialStatus === 1 ? "Yes" : "No"}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Mother Tougue{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.motherTongue === ""
                ? "-"
                : data.motherTongue === null
                ? "-"
                : data.motherTongue}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Total Experience
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.totalExperiences === ""
                ? "-"
                : data.totalExperiences === null
                ? "-"
                : data.totalExperiences}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Nationality
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.nationality === ""
                ? "-"
                : data.nationality === null
                ? "-"
                : data.nationality}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Religion
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {religion === "" ? "-" : religion === null ? "-" : religion}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Category
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {category === "" ? "-" : category === null ? "-" : category}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Caste
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.cast === "" ? "-" : data.cast === null ? "-" : data.cast}
            </td>

            {/* <td className={classes.MuiTableHeadRoot}>
            &nbsp;{data.cast}
          </td> */}
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Sub-Caste
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.subCast === ""
                ? "-"
                : data.subCast === null
                ? "-"
                : data.subCast}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Caste Certificate No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.castCertificateNo === ""
                ? "-"
                : data.castCertificateNo === null
                ? "-"
                : data.castCertificateNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Caste Certificate Date
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.castCertificateDate}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Validity Certificate No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.validityCdNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Validity Certificate Date
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.validityCdDate}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Alternate No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.alternateNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Domicile State
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.domicileof === ""
                ? "-"
                : data.domicileof === null
                ? "-"
                : data.domicileof}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Aadhar No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.aadhaarNo === ""
                ? "-"
                : data.aadhaarNo === null
                ? "-"
                : data.aadhaarNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; PAN Number
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.panNo === "" ? "-" : data.panNo === null ? "-" : data.panNo}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Date of Appointment
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.reportingDate}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Date of Retirement
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.leavingDate}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Role
            </td>
            <td className={classes.MuiTableHeadRoot}>&nbsp;{data.role}</td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Date of Joining
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.joiningDate}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Role Access
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {accessRole}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  employeeDetails: state.employeeList,
});

const mapDispatchToProps = {
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BasicTable));
