import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from '../../../../components/dynamicscreens/DynamicMainScreen'
import { FloorColumns } from "../../../../tableColumns/table-columns";
import { floorJson } from "../../../../DynamicFormsJson/Floor";
import { getFloor } from "./floor.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
class Floor extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
   
  }

  componentDidMount() {
    const { getFloor,getAlert } = this.props;
    getFloor().then((response)=>{
      if (!response) {
      getAlert({ message: "Something went wrong" });
      }
    });
  }

  render() {
   
    const { floorList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={floorJson.screenTitle}
            fieldMeta={floorJson.fieldMeta}
            showPdfDownload={floorJson.showPdfDownload}
            showExcelDownload={floorJson.showExcelDownload}
            pdfFileName={floorJson.pdfFileName}
            excelFileName={floorJson.excelFileName}
            showAddButton={floorJson.showAddButton}
            tableColumnsToFilter={floorJson.tableColumnsToFilter}
            // dynamicMasterData={dynamicMasterData}
            tableColumns={FloorColumns}
            tableData={floorList?.floor}
            getTableData={this.props.getFloor}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={floorList.isFetch}
            apiBaseURL={floorJson.apiBaseURL}
            baseIdColumn={floorJson.baseIdColumn}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  floorList: state.floor,
});
const mapDispatchToProps = {
    getFloor,
    getAlert
};
export default connect(mapStateToProps, mapDispatchToProps)(Floor);
