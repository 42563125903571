import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import endpoint from "../../../../../config/endpoints";
import * as msgConstant from "../../../../../config/messageconstant";
import { AdminResearchGuidanceJson } from "../../../../../DynamicFormsJson/AdminResearchGuidance";
import { showNotification } from "../../../../Landing/Landing.slice";
import { researchGuidanceColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../../../utils/api_service";
import { getActivityByType } from "../../AcademicDetails/Activity/activity.slice";
import { getAdminResearchGuide } from "../../AcademicDetails/ResearchGuidance/researchguidance.slice";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getEmployeeByDeptId } from "../../../../EmployeeDetails/employee.slice";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import * as myConstClass from "../../../../../config/messageconstant";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { Loading1 } from "../../../../../components/Loading1";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminResearchGuidance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showLoader: false,
      dynamicMasterData: {
        activity: this.props.activityList?.activityByType,
        researchCenterMaster: [
          { id: "University", name: "University" },
          { id: "Research Center", name: "Research Center" },
          { id: "other", name: "Other" },
        ],
        typeMaster: [
          { id: "PHD", name: "PHD" },
          { id: "Masters", name: "Masters" },
        ],
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeList?.employeeList,
      },
    };
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    // this.props.getResearchWithoutDate().then((response) => {
    //   if (!response) {
    //     this.setState({ showLoader: false });

    //     this.props.getAlert({ message: "Something went wrong" });
    //   } else {
    //     this.setState({ showLoader: false });
    //   }
    // });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  submitHandler = (data) => {
    this.setState({ showLoader: true });
    this.props
      .getAdminResearchGuide({ userRegistration: data.userRegistration })
      .then((response) => {
        if (!response) {
          this.setState({ showLoader: false });
          console.log("In If");

          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({ showLoader: false });
          console.log("In else");
        }
      });
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
    if (this.props.employeeList !== nextProps.employeeList) {
      if (
        this.props.employeeList?.employeeList !==
        nextProps.employeeList?.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeList?.employeeList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }
  getListById = (data) => {
    if (data.departmentId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getEmployeeByDeptId({
          departmentId: data.departmentId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  goToAdminAcademicPanel = () => {
    window.location.replace("/admin-academic-panel");
  };
  render() {
    const { dynamicMasterData } = this.state;
    console.log(this.props.employeeList?.employeeList);
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <DynamicMainScreen
          screenTitle={AdminResearchGuidanceJson.screenTitle}
          fieldMeta={AdminResearchGuidanceJson.fieldMeta}
          showPdfDownload={AdminResearchGuidanceJson.showPdfDownload}
          showExcelDownload={AdminResearchGuidanceJson.showExcelDownload}
          pdfFileName={AdminResearchGuidanceJson.pdfFileName}
          excelFileName={AdminResearchGuidanceJson.excelFileName}
          showAddButton={AdminResearchGuidanceJson.showAddButton}
          tableColumnsToFilter={AdminResearchGuidanceJson.tableColumnsToFilter}
          dynamicMasterData={dynamicMasterData}
          showSaveNextBtn={false}
          tableColumns={researchGuidanceColumns}
          tableData={this.props.researchGuideList?.researchGuide}
          getTableData={""}
          getTableDataById={true}
          showLoader={!this.state.showLoader}
          apiBaseURL={AdminResearchGuidanceJson.apiBaseURL}
          baseIdColumn={AdminResearchGuidanceJson.baseIdColumn}
          type={1}
          getListById={this.getListById}
          DocumentUpload={AdminResearchGuidanceJson.DocumentUpload}
          addValidation={true}
          onSearchData={this.submitHandler}
          searchList={AdminResearchGuidanceJson.searchList}
          dataSetToAdd={AdminResearchGuidanceJson.dataSetToAdd}
          showBackToListBtn={true}
          onBackToList={this.goToAdminAcademicPanel}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  innovativeList: state.innovativeTeaching,
  researchGuideList: state.researchGuide,
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getActivityByType,
  getDepartment,
  getEmployeeByDeptId,
  getAdminResearchGuide,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminResearchGuidance);
