import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import DynamicTable from "../../../../../components/Comman/RT/MaterialUIRTTable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, mr: 5, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "name",
    title: "Student Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "40%",
  },
  {
    name: "prnNumber",
    title: "PRN Number",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
  },
];

function PrnNumberPopUp({ prnNumberList, open, handleCloses, submitHandler }) {
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleCloses}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloses}
          >
            Student Name with PRN Number
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <DynamicTable
              data={prnNumberList?.prnNumberGenerate}
              tableHead={columns}
              isActionColActive={false}
              showPegination={false}
            />
          </DialogContent>
          <DialogActions>
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                fullWidth={true}
                onClick={submitHandler}
              />
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  prnNumberList: state.prnNumber,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PrnNumberPopUp);
