import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import "../../CSS/paymentPopup.css";
import sha from "./sha";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PayPhiPaymentGateway({
  currencyCode,
  amount,
  merchantId,
  merchantTxnNo,
  returnURL,
  txnDate,
  hashcode,
  handleClose,
  postMethode,
  firstName,
  lastName,
  email,
  aggregatorID,
  mobile,
}) {
  const [amt, setAmt] = React.useState(amount);
  const [code, setCode] = React.useState(currencyCode);
  const [mid, setMid] = React.useState(merchantId);
  const [mxId, setMxid] = React.useState(merchantTxnNo);
  const [url, setUrl] = React.useState(returnURL);
  const [date, setDate] = React.useState(txnDate);
  const [hash, setHash] = React.useState(hashcode);
  const [fName, setFirstName] = React.useState(firstName);
  const [lName, setLastName] = React.useState(lastName);
  const [emailId, setEmail] = React.useState(email);
  const [mobileNo, setMobile] = React.useState(mobile);

  const getHMACResult = () => {};
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <div class="form-style-5">
              <form>
                <fieldset>
                  <legend>Transaction Details</legend>
                  <lable>First Name</lable>
                  <input type="text" name="fName" disabled value={fName} />
                  <lable>Last Name</lable>
                  <input type="text" name="lName" disabled value={lName} />
                  <lable>Email</lable>
                  <input type="text" name="emailId" disabled value={emailId} />
                  <lable>Mobile</lable>
                  <input
                    type="text"
                    name="mobileNo"
                    disabled
                    value={mobileNo}
                  />
                  <label>Amount</label>
                  <input type="text" name="amount" disabled value={amt} />
                </fieldset>
              </form>
              {/* https://qa.phicommerce.com/pg/api/sale?v=2 */}
              <form
                id="myForm"
                name="myForm"
                action="https://secure-ptg.payphi.com/pg/api/sale?v=2"
                method="post"
                enctype="application/x-www-form-urlencoded"
              >
                <fieldset>
                  <input type="hidden" name="merchantID" value={mid} />
                  <input type="hidden" name="merchantTxnNo" value={mxId} />
                  <input type="hidden" name="amount" value={amt} />
                  <input type="hidden" name="currencyCode" value={code} />
                  <input type="hidden" name="payType" value="" />
                  <input type="hidden" name="paymentMode" value="" />
                  <input type="hidden" name="customerEmailID" value="" />
                  <input type="hidden" name="transactionType" value="" />
                  <input type="hidden" name="paymentOptionCodes" value="" />
                  <input type="hidden" name="txnDate" value={date} />
                  <input type="hidden" name="cardNo" value="" />
                  <input type="hidden" name="cardExpiry" value="" />
                  <input type="hidden" name="nameOnCard" value="" />
                  <input type="hidden" name="cvv" value="" />
                  <input type="hidden" name="customerUPIAlias" value="" />
                  <input
                    type="hidden"
                    name="aggregatorID"
                    value={aggregatorID}
                  />
                  <input type="hidden" name="remarks" value="" />
                  <input type="hidden" name="customerName" value="" />
                  <input type="hidden" name="customerMobileNo" value="" />
                  <input type="hidden" name="invoiceNo" value="" />
                  <input type="hidden" name="returnURL" value={url} />
                  <input type="hidden" name="addlParam1" value="" />
                  <input
                    type="hidden"
                    name="allowDisablePaymentMode"
                    value=""
                  />
                  <input type="hidden" name="addlParam2" value="" />
                  <input type="hidden" name="secureHash" value={hash} />
                  <p id="hashtext"></p>
                </fieldset>
                <input
                  type="submit"
                  name="submitbut"
                  value="Pay"
                  onClick={postMethode}
                />
                <input type="button" value="Cancel" onClick={handleClose} />
              </form>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </Paper>
    </>
  );
}
