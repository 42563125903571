import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { SchemeHeadMasterJson } from '../../../../DynamicFormsJson/SchemeHead.js'
import { SchemeHeadColumns } from "../../../../tableColumns/table-columns";
import { getSchemeHead } from "./schemeHead.slice"
import { getScheme } from "../Scheme/scheme.slice"
import { getAlert } from "../../../../CommonActions/alert.slice";

class SchemeHead extends React.Component
{

    // eslint-disable-next-line no-useless-constructor
    constructor( props )
    {
        super( props );
        this.state = {
            dynamicMasterData: { schemeList: this.props.schemeDataList?.scheme }
        }
    }

    componentDidMount()
    {
        const { getSchemeHead, getScheme } = this.props;
        getSchemeHead().then( ( response ) =>
        {
            if ( !response )
            {
                this.props.getAlert( { message: "Something went wrong" } );
            }
        } );
        getScheme().then( ( response ) =>
        {
            if ( !response )
            {
                this.props.getAlert( { message: "Something went wrong" } );
            }
        } );
    }
    componentWillReceiveProps( nextProps )
    {
        if ( this.props.schemeDataList !== nextProps.schemeDataList )
        {
            if ( this.props.schemeDataList.scheme !== nextProps.schemeDataList.scheme )
            {
                this.setState( {
                    dynamicMasterData: { ...this.state.dynamicMasterData, schemeList: nextProps.schemeDataList.scheme.filter( ( data ) => data.isActive == 1 ), }
                } )
            }
        }
    }
    render()
    {

        const { dynamicMasterData } = this.state;
        const { schemeHeadList } = this.props;
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={SchemeHeadMasterJson.screenTitle}
                        fieldMeta={SchemeHeadMasterJson.fieldMeta}
                        showPdfDownload={SchemeHeadMasterJson.showPdfDownload}
                        showExcelDownload={SchemeHeadMasterJson.showExcelDownload}
                        pdfFileName={SchemeHeadMasterJson.pdfFileName}
                        excelFileName={SchemeHeadMasterJson.excelFileName}
                        showAddButton={SchemeHeadMasterJson.showAddButton}
                        tableColumnsToFilter={SchemeHeadMasterJson.tableColumnsToFilter}
                        dynamicMasterData={dynamicMasterData}
                        tableColumns={SchemeHeadColumns}
                        tableData={schemeHeadList?.schemeHead}
                        getTableData={this.props.getSchemeHead}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={schemeHeadList.isFetch}
                        baseIdColumn={SchemeHeadMasterJson.baseIdColumn}
                        apiBaseURL={SchemeHeadMasterJson.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = ( state ) => ( {
    schemeHeadList: state.schemeHead,
    schemeDataList: state.scheme
} );
const mapDispatchToProps = {
    getSchemeHead,
    getScheme,
    getAlert
}
export default connect( mapStateToProps, mapDispatchToProps )( SchemeHead );