import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import {
  default as endpoint,
  default as endpoints,
} from "../../../config/endpoints";
import { apiGet, apiGetWithoutHeader } from "../../../utils/api_service";
const initialState = {
  generateMessage: {},
  isFetchGenerateMessage: false,

  validateMessage: {},
  isFetchValidateMessage: false,

  alumniPassword: {},
  isFetchAlumniPassword: false,
};
let generateOtp = endpoints.preloginAlumniRegister;
let validateOtp = endpoint.linkSendOnEmail;
const alumniPasswordSlice = createSlice({
  name: "alumniPassword",
  initialState,
  reducers: {
    alumniGenrateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        generateMessage: data.data,
        isFetchGenerateMessage: true,
      };
    },

    alumniValidateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        validateMessage: data.data,
        isFetchValidateMessage: true,
      };
    },

    alumniGeneratePasswordSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { data } = action.payload;
      return {
        ...state,
        alumniPassword: data.data,
        isFetchAlumniPassword: true,
      };
    },
  },
});

export const {
  alumniGenrateOTPSuccess,
  alumniValidateOTPSuccess,
  alumniGeneratePasswordSuccess,
} = alumniPasswordSlice.actions;

export default alumniPasswordSlice.reducer;
//api/config/prelogin/forgot-password/reset-password?userName=test&newPassword=737719
export const getChangePassword =
  ({ contact, password }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url:
          validateOtp +
          "/reset-password?userName=" +
          contact +
          "&newPassword=" +
          password,
      }).then(({ data, success }) => {
        if (success) {
          console.log("Success With OTP Send ========>", data);
          const { message } = data;
          dispatch(alumniGeneratePasswordSuccess({ data }));
          return true;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//api/config/prelogin/forgot-password/validate-otp?userName=test&otp=737719
export const getValidateOtp =
  ({ contact, otp }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url: validateOtp + "/validate-otp?userName=" + contact + "&otp=" + otp,
      }).then(({ data, success }) => {
        if (success) {
          console.log("Success With OTP Send ========>", data);
          const { message } = data;
          dispatch(alumniValidateOTPSuccess({ data }));
          return true;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//api/config/prelogin/alumni-registration/forgot-password?userName=test&baseUrl=eduerp.aaryatechindia.in
export const getGenerateOTP =
  ({ contact }) =>
  async (dispatch) => {
    try {
      const response = apiGetWithoutHeader({
        url:
          generateOtp +
          "/forgot-password?userName=" +
          contact +
          "&baseUrl=" +
          window.location.host,
      }).then(({ data, success }) => {
        if (success) {
          console.log("Success With OTP Send ========>", data);
          const { message } = data;
          dispatch(alumniGenrateOTPSuccess({ data }));
          return true;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
