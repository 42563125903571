import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  companyListStatus: [],
  interestedStudentListSuccess: [],
  notInterestedStudentListSuccess: [],
  changeStatusList: [],
  getInterestedStudentByIdStatus: [],
  getInterestedStudByIdStatus: [],
  getInterestedStudByIdStatusIsFetchStatus: false,
  getInterestedStudentByIdStatusIsFetchStatus: false,
  changeStatusFetchStatus: false,
  notIntrestedStudentIsFetch: false,
  isFetchStatus: false,
  intrestedStudentIsFetch: false,
  placementDeclareList: [],
  isFetchPlacementList: false,
};

let URL = endpoints.placementDeclare;
let URL1 = endpoints.placementIntrestedAndNotIntrestedStudent;
let URL2 = endpoints.placementIntrest;

const placementCompanyList = createSlice({
  name: "companyPlacementList",
  initialState,
  reducers: {
    companyListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        companyListStatus: row,
        isFetchStatus: true,
      };
    },
    getInterestedStudentByIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        getInterestedStudentByIdStatus: row,
        getInterestedStudentByIdStatusIsFetchStatus: true,
      };
    },
    getInterestedStudByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        getInterestedStudByIdStatus: row,
        getInterestedStudByIdStatusIsFetchStatus: true,
      };
    },
    resetInterestedStudent: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        getInterestedStudByIdStatus: [],
        getInterestedStudByIdStatusIsFetchStatus: false,
      };
    },

    changeStatusSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        changeStatusList: data,
        changeStatusFetchStatus: true,
      };
    },

    interestedStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        interestedStudentListSuccess: row,
        intrestedStudentIsFetch: true,
      };
    },
    notInterestedStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        notInterestedStudentListSuccess: row,
        notIntrestedStudentIsFetch: true,
      };
    },

    admissionListFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        documentVerificationDataList: [],
        isFetchDocumentVerificationDataList: false,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },

    resetTableListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        companyListStatus: [],
        isFetchStatus: false,
      };
    },

    placementDeclareSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        placementDeclareList: row,
        isFetchPlacementList: true,
      };
    },
  },
});

export const {
  resetInterestedStudent,
  resetTableListSuccess,
  companyListSuccess,
  getInterestedStudentByIdSuccess,
  getInterestedStudByIdSuccess,
  changeStatusSuccess,
  interestedStudentSuccess,
  notInterestedStudentSuccess,
  resetSuccess,
  placementDeclareSuccess,
} = placementCompanyList.actions;

export default placementCompanyList.reducer;

export const getCompanyList =
  ({ fromDate, toDate, placementType }) =>
  async (dispatch) => {
    try {
      console.log(fromDate);
      console.log(toDate);
      console.log(placementType);
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL +
          "/by-placement-type?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&placementType=" +
          placementType,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const companyList = data.data;
          console.log(companyList);
          // let index = 0;
          let row = [];
          let index = 0;
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              placementDeclareId: data.id,
              companyId: data.company.id,
              companyName: data.company.name,
              companyAddress: data.company.address,
              venue: data.venue,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              date: data.declareDate,
              isActive: data.isActive,
              yearDetailId: data.yearDetailId,
              divisionId: data.divisionId,
              placementPackage: data.placementPackage,
              designation: data.designation,
              placementType:
                data.placementType === "0"
                  ? "On Campus"
                  : "1"
                  ? "Off Campus"
                  : "Pool Campus",
              remark: data.remark,
              mathsCriteria: data.mathsCriteria,
              gapInYears: data.backLog == null ? 0 : data.backLog,
            };
            return companyData;
          });
          dispatch(companyListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInterestedStudentById =
  ({ userRegistrationId, status }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL2 +
          "/get-intrested-student-by-user-id?userRegistrationId=" +
          userRegistrationId +
          "&status=" +
          status,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const companyList = data.data;
          console.log(companyList);
          let index = 0;
          let row = [];
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              //     internshipDeclareId: data.id,
              //     companyId: data.company.id,
              id: data.id,
              companyName: data.companyName,
              companyAddress: data.companyAdd,
              placementType:
                data.placementType === "0"
                  ? "On Campus"
                  : "1"
                  ? "Off Campus"
                  : "Pool Campus",
              placementIntrestedStudentDocument:
                data.placementIntrestedStudentDocument.map((docData, index) => {
                  let docDataDetails = {
                    index: index + 1,
                    name: docData.docName,
                    fileName: docData.fileName,
                    id: docData.id,
                  };
                  return docDataDetails;
                }),
              //     venue: data.venue,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              designation: data.designation,
              placementPackage: data.placementPackage,
              status:
                data.selectedStatus == 1
                  ? "Selected"
                  : data.selectedStatus == 0
                  ? "Not Selected"
                  : data.selectedStatus == 0
                  ? "Completed"
                  : "",
              remark: data.remark,
            };
            return companyData;
          });
          dispatch(getInterestedStudentByIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInterestedStudById =
  ({ id }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url: URL2 + "/" + id,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const getStudById = data.data;
          dispatch(getInterestedStudByIdSuccess({ row: getStudById }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const changeInternshipDeclareStatus =
  ({ placementDeclareId, status }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/api/placement/v1/internship-declare/change-active-status?id=44d8e490-d807-40af-8c07-47bd10af603c&status=0
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
      const response = apiGet({
        url:
          URL +
          "/change-active-status?id=" +
          placementDeclareId +
          "&status=" +
          status,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          dispatch(changeStatusSuccess({ data }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getIntrestedStudentList =
  ({ id }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      // http://localhost:8092/v1/internship-intrested-student/5b0539f3-de7c-44da-a7f6-1afadb3f985d/by-internship-declare?intrestStatus=0
      const response = apiGet({
        url: URL1 + "/" + id + "/by-placement-declare?intrestStatus=1",
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const studentList = data.data;
          console.log(studentList);
          // let index = 0;
          let row = [];
          let index = 0;
          row = studentList.map((data) => {
            // const divisions = data.placementDeclare.getDivisions.map(
            //   (data1) => {
            //     return data1.name;
            //   }
            // );
            index = index + 1;
            let studentData = {
              index: index,
              id: data.id,
              // studentName:
              //   data.getUserRegistration === null
              //     ? ""
              //     : data.getUserRegistration.firstName +
              //     " " +
              //     data.getUserRegistration.middleName +
              //     " " +
              //     data.getUserRegistration.lastName,
              firstName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.firstName,
              middleName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.middleName,
              lastName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.lastName,
              fatherName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.fatherName,
              lastName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.lastName,
              studentName: data.userRegistrationName.toUpperCase(),
              userRegistrationId:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.id,
              companyName: data.companyName === null ? "" : data.companyName,
              division:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.division.name,
              yearDetails:
                data.getReportingDetail === null
                  ? ""
                  : data.getReportingDetail.getYearDetail.programTitle.brName,
              rollNo:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.rollNo,
              // yearDetail:
              //   data.placementDeclare === null
              //     ? ""
              //     : data.placementDeclare.getYearDetail.year.className +
              //       " -  " +
              //       data.placementDeclare.getYearDetail.programTitle.brName +
              //       " -  " +
              //       data.placementDeclare.getYearDetail.programTitle.programType
              //         .name,
              // division: divisions.join(),
              designation: data.designation === null ? "" : data.designation,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              placementPackage: data.placementPackage,
              remark:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.remark,
              placementIntrestedStudentDocument:
                data.placementIntrestedStudentDocument.map((docData, index) => {
                  let docDataDetails = {
                    index: index + 1,
                    name: docData.docName,
                    fileName: docData.fileName,
                    id: docData.id,
                  };
                  return docDataDetails;
                }),
            };
            return studentData;
          });
          dispatch(interestedStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getNotIntrestedStudentList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/by-placement-declare?intrestStatus=0",
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const studentList = data.data;
          console.log(studentList);
          // let index = 0;
          let row = [];
          let index = 0;
          row = studentList.map((data) => {
            // const divisions = data.placementDeclare.getDivisions.map(
            //   (data1) => {
            //     return data1.name;
            //   }
            // );
            index = index + 1;
            let studentData = {
              index: index,
              id: data.id,
              // studentName:
              //   data.getUserRegistration === null
              //     ? ""
              //     : data.getUserRegistration.firstName +
              //     " " +
              //     data.getUserRegistration.middleName +
              //     " " +
              //     data.getUserRegistration.lastName,
              firstName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.firstName,
              middleName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.middleName,
              lastName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.lastName,
              fatherName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.fatherName,
              lastName:
                data.getUserRegistration === null
                  ? null
                  : data.getUserRegistration.lastName,
              studentName: data.userRegistrationName.toUpperCase(),
              userRegistrationId:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.id,
              companyName: data.companyName === null ? "" : data.companyName,
              division:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.division.name,
              yearDetails:
                data.getReportingDetail === null
                  ? ""
                  : data.getReportingDetail.getYearDetail.programTitle.brName,
              rollNo:
                data.getReportingDetail === null
                  ? "-"
                  : data.getReportingDetail.rollNo,
              // + " " + data.getReportingDetail.getYearDetail,
              // yearDetail:
              //   data.placementDeclare === null
              //     ? ""
              //     : data.placementDeclare.getYearDetail.year.className +
              //       " - " +
              //       data.placementDeclare.getYearDetail.programTitle.brName +
              //       " - " +
              //       data.placementDeclare.getYearDetail.programTitle.programType
              //         .name,
              // division: divisions.join(),
              designation: data.designation === null ? "" : data.designation,
              durationFromDate: data.durationFromDate,
              durationToDate: data.durationToDate,
              placementPackage: data.placementPackage,
              remark:
                data.getUserRegistration === null
                  ? ""
                  : data.getUserRegistration.remark,

              placementIntrestedStudentDocument:
                data.placementIntrestedStudentDocument.map((docData, index) => {
                  let docDataDetails = {
                    index: index + 1,
                    name: docData.docName,
                    fileName: docData.fileName,
                    id: docData.id,
                  };
                  return docDataDetails;
                }),
            };
            return studentData;
          });
          dispatch(notInterestedStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetTableList = () => async (dispatch) => {
  try {
    dispatch(resetTableListSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAllPlacementList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?offset=0&limit=20&sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const companyList = data.data.content;
        let row = [];
        let index = 0;
        console.log(companyList);
        row = companyList.map((data) => {
          index = index + 1;
          let companyData = {
            index: index,
            placementDeclareId: data.id,
            companyId: data.company.id,
            companyName: data.company.name,
            companyAddress: data.company.address,
            venue: data.venue,
            durationFromDate: data.durationFromDate,
            durationToDate: data.durationToDate,
            date: data.declareDate,
            isActive: data.isActive,
            yearDetailId: data.yearDetailId,
            divisionId: data.divisionId,
            placementPackage: data.placementPackage,
            designation: data.designation,
            placementType:
              data.placementType === "0"
                ? "On Campus"
                : "1"
                ? "Off Campus"
                : "Pool Campus",
            remark: data.remark,
            mathsCriteria: data.mathsCriteria,
            gapInYears: data.backLog == null ? 0 : data.backLog,
          };
          return companyData;
        });
        dispatch(companyListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
