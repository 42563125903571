import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreen";
import { ExtendedActivitiesJson } from "../../../../../DynamicFormsJson/ExtendedActivities";
import { viewTeacherNoticeColumns } from "../../../../../tableColumns/table-columns";
import { getNotice,setNoticeData } from "./notice.slice";
import { NoticeJson } from "../../../../../DynamicFormsJson/Notice";

import { showNotification } from "../../../../../Pages/Landing/Landing.slice";
import { openFileInNewTab } from "../../../../../Pages/UploadFile/file.slice";

class ViewNoticeForTeacherTable extends React.Component
{
  constructor( props )
  {
    super( props );
  }

  componentDidMount() {
    this.props.getNotice().then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  }
  setData=(row)=>{
console.log(row)
  }


  rowView = ( rowData ) =>
  {
    this.props.openFileInNewTab({ file: rowData.documentUpload }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };
  render()
  {
    const { noticeList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={NoticeJson.screenTitle}
            fieldMeta={[]}
            showPdfDownload={NoticeJson.showPdfDownload}
            showExcelDownload={NoticeJson.showExcelDownload}
            pdfFileName={NoticeJson.pdfFileName}
            excelFileName={NoticeJson.excelFileName}
            showAddButton={false}
            tableColumnsToFilter={NoticeJson.tableColumnsToFilter}
            showSaveNextBtn={false}
            tableColumns={viewTeacherNoticeColumns}
            tableData={noticeList?.notice}
            getTableData={this.props.getNotice}
            isActionColActive={true}
            showLoader={noticeList.isFetch}
            baseIdColumn={NoticeJson.baseIdColumn}
            setData={this.props.setNoticeData}
            showViewButtonInTable={true}
            showEditIcon={false}
  showDeleteIcon={false}
  rowView={this.rowView}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
    noticeList: state.empNotice,
} );
const mapDispatchToProps = {
    showNotification,
    getNotice,
    setNoticeData,
    getAlert,openFileInNewTab
};
export default connect( mapStateToProps, mapDispatchToProps )( ViewNoticeForTeacherTable );

