import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import * as myConstClass from "../../../config/messageconstant";
import { getSyllabus } from "../../TeachingPlan/teachingPlan.slice";

import {
  getStudentAttendanceList,
  getDivision,
  getBatch,
  getSubject,
  resetStudentList,
} from "../StudentAttendanceForms/studentAttendance.slice";

import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";

import { getAlert } from "../../../CommonActions/alert.slice";
import { getTimeSlot } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getYearDetailsStudentForm,
  getSemesterDetailsStudentForm,
} from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
let selectedStudentList = [];

class StudentAttendForm extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    semister: "",
    division: "",
    batch: "",
    subject: "",
    timeSlot: "",
    attendanceDate: "",
    topic: "",
    remark: "",
    selectedStudentIndexList: [],
    StudentList: [],
    formErrors: [],
    disabledflag: false,
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentAttendanceList?.subject !==
      nextProps.studentAttendanceList?.subject
    ) {
      const subjectDetailFilterList =
        nextProps.studentAttendanceList?.subject.filter(
          (subjectList) => subjectList.id === this.props.saveFormData.subjectId
        );
      if (subjectDetailFilterList.length !== 0) {
        this.props
          .getSyllabus({
            subjectDetailId: subjectDetailFilterList[0].subjectDetailId,
          })
          .then((resp) => {
            if (!resp) {
              this.props.getAlert({
                message: "Server Failed",
              });
            }
          });
      }
    }
  }
  componentDidMount() {
    const { saveFormData } = this.props;
    console.log(saveFormData);
    if (saveFormData !== undefined) {
      if (Object.keys(saveFormData).length != 0) {
        this.setState({
          id: saveFormData.id,
          yearDetail: saveFormData.yearDetailId,
          semister: saveFormData.semisterId,
          division: saveFormData.divisionId,
          batch: saveFormData.batchId,
          timeSlot: saveFormData.timeSlotId,
          subject: saveFormData.subjectId,
          disabledflag: true,
        });

        let docs = {
          id: saveFormData.id,
          yearDetail: saveFormData.yearDetailId,
          semister: saveFormData.semisterId,
          division: saveFormData.divisionId,
          batch: saveFormData.batchId,
          subject: saveFormData.subjectId,
          timeSlot: saveFormData.timeSlotId,
        };
        console.log(docs);

        this.props.Onsave(docs);
        this.props
          .getBatch({
            semister: saveFormData.semisterId,
            division: saveFormData.divisionId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props
          .getSubject({
            semister: saveFormData.semisterId,
            division: saveFormData.divisionId,
            batch: saveFormData.batchId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    }

    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getYearDetailsStudentForm().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getTimeSlot().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  ChangeHandlerSearch = (name, newValue) => {
    console.log(name);
    console.log(newValue);
    const {
      getSemesterDetailsByYearAndAcademic,
      getDivision,
      getBatch,
      getSubject,
      resetStudentList,
      getSemesterDetailsStudentForm,
    } = this.props;
    const { semister, division, batch, yearDetail, timeSlot, subject } =
      this.state;

    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name == "batch") {
        this.props.resetStudentList();
        this.props.resetvalues([]);
      }

      if (name == "yearDetail") {
        this.setState({ semister: "", division: "", batch: "", subject: "" });
        // getSemesterDetailsByYearAndAcademic( {
        //   yearId: newValue,
        //   academicId: "",
        // } ).then( ( response ) =>
        // {
        //   if ( !response )
        //   {
        //     this.props.getAlert( { message: "Something went wrong" } );
        //   }
        // } );

        getSemesterDetailsStudentForm({
          yearDetailId: newValue,
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
      if (name == "semister") {
        this.setState({ division: "" });
        getDivision({ id: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (division !== "" && batch !== "") {
          this.setState({ subject: "" });
          getSubject({
            semister: newValue,
            division: division,
            batch: batch,
          }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      }
      if (name == "division") {
        this.setState({ subject: "", batch: "" });
        getBatch({ semister: semister, division: newValue }).then(
          (response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          }
        );
        if (semister !== "" && batch !== "") {
          getSubject({
            semister: semister,
            division: newValue,
            batch: batch,
          }).then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      }
      if (name == "batch" && semister !== "" && division !== "") {
        this.setState({ subject: "" });
        getSubject({
          semister: semister,
          division: division,
          batch: newValue,
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
      if (name == "batch") {
        this.props.resetStudentList();
        this.props.resetvalues([]);
      }
    }
  };

  handleFormValidation() {
    const {
      yearDetail,
      semister,
      division,
      batch,
      subject,
      timeSlot,
      attendanceDate,
      topic,
      remark,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (semister.toString().trim() === "" || semister == null) {
      formIsValid = false;
      formErrors["semisterError"] = myConstClass.semisterMsg;
    }

    if (yearDetail.toString().trim() === "" || yearDetail == null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }
    if (division.toString().trim() === "" || division == null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    if (batch.toString().trim() === "" || batch == null) {
      formIsValid = false;
      formErrors["batchError"] = myConstClass.batchMsg;
    }
    if (subject.toString().trim() === "" || subject == null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectNameMsg;
    }
    if (timeSlot.toString().trim() === "" || timeSlot == null) {
      formIsValid = false;
      formErrors["timeSlotError"] = myConstClass.timeSlotMsg;
    }
    // if (attendanceDate.toString().trim() === "" || attendanceDate == null) {
    //   formIsValid = false;
    //   formErrors["attendanceDateError"] = myConstClass.divEmptyMsg;
    // }
    // if (topic.toString().trim() === "" || topic == null) {
    //   formIsValid = false;
    //   formErrors["topicError"] = myConstClass.divEmptyMsg;
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { getStudentAttendanceList, onSave, getAlert, showNotification } =
      this.props;
    const { yearDetail, semister, division, batch, subject, timeSlot } =
      this.state;

    let docs = {
      yearDetail: yearDetail,
      semister: semister,
      division: division,
      batch: batch,
      subject: subject,
      timeSlot: timeSlot,
    };
    console.log(docs);

    if (this.handleFormValidation()) {
      this.props.Onsave(docs);
      getStudentAttendanceList({ id: batch }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  render() {
    const {
      yearDetail,
      semister,
      division,
      batch,
      subject,
      timeSlot,
      attendanceDate,
      disabledflag,
      topic,
      remark,
    } = this.state;
    const {
      semisterError,
      divisionError,
      batchError,
      yearDetailError,
      subjectError,
      timeSlotError,
    } = this.state.formErrors;
    const { internshipDeclarationList, rollNoData, studentBatchList } =
      this.props;
    return (
      <>
        <div>
          <Paper sx={{ borderRadius: 2 }} elevation={0}>
            <>
              <form autoComplete="off" noValidate={true}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={1}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Detail"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={
                          this.props.employeeWorkList
                            ?.studentAttendanceFormYeardetails
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={yearDetailError ? true : false}
                        errorText={yearDetailError ? yearDetailError : " "}
                        disabled={disabledflag}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Semester"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={semister}
                        name={"semister"}
                        options={
                          this.props.employeeWorkList
                            ?.attendanceSemesterDetailsStudent
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={semisterError ? true : false}
                        errorText={semisterError ? semisterError : " "}
                        disabled={disabledflag}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Division"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={division}
                          name={"division"}
                          options={this.props.studentAttendanceList?.division}
                          onChange={this.ChangeHandlerSearch}
                          isError={divisionError ? true : false}
                          errorText={divisionError ? divisionError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Batch"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={batch}
                          name={"batch"}
                          options={this.props.studentAttendanceList?.batch}
                          onChange={this.ChangeHandlerSearch}
                          isError={batchError ? true : false}
                          errorText={batchError ? batchError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Subject"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={subject}
                          name={"subject"}
                          options={this.props.studentAttendanceList?.subject}
                          onChange={this.ChangeHandlerSearch}
                          isError={subjectError ? true : false}
                          errorText={subjectError ? subjectError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Time Slot"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={timeSlot}
                          name={"timeSlot"}
                          options={this.props.timeSlotList?.timeSlot}
                          onChange={this.ChangeHandlerSearch}
                          isError={timeSlotError ? true : false}
                          errorText={timeSlotError ? timeSlotError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="right"
                      >
                        {!disabledflag && (
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Search"
                            onClick={this.searchData}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          </Paper>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  semesterList: state.rollNo,
  yearDetails: state.internshipDeclaration,
  timeSlotList: state.timeSlot,
  studentAttendanceList: state.studentAttendance,
  employeeWorkList: state.employeeWorkload,
});
const mapDispatchToProps = {
  getStudentAttendanceList,
  getYearDetails,
  getSemesterDetailsByYearAndAcademic,
  getTimeSlot,
  getDivision,
  getBatch,
  getSubject,
  getAlert,
  resetStudentList,
  getYearDetailsStudentForm,
  getSemesterDetailsStudentForm,
  getSyllabus,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentAttendForm);
