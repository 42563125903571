import React, { Component } from "react";
import { connect } from "react-redux";
import "../../CSS/paymentSuccess.css";
import {
  getApplicantAdmission,
  saveApplicantFee,
  getPendingFees,
} from "../ApplicantAdmission/applicantAdmission.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import moment from "moment";
import { apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
// import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { showNotification } from "../../Pages/Landing/Landing.slice";

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      convertedJson: "",
      txniId: "",
      userId: "",
      showSuccess: true,
    };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  componentDidMount() {
    let response = window.location.search;
    const urlParams = new URLSearchParams(response);
    const product = urlParams.get("returndata");
    let convertedJson = atob(product);
    var obj = JSON.parse(convertedJson);
    console.log(obj.responseCode);
    let resp = "";
    let paidStatus = 0;
    if (obj.responseCode == "0000") {
      resp = "success";
      paidStatus = 1;
    } else {
      resp = "fail";
      paidStatus = 0;
    }

    let type = localStorage.getItem("savePaymentTransactionPojo");
    let savePaymentTransactionPojoData = JSON.parse(type);
    if (savePaymentTransactionPojoData !== null) {
      let dataToSave = {
        nextYearAdmission: {
          id: savePaymentTransactionPojoData.nextYearAdmission.id,
        },
        paymentTransaction: {
          firstName:
            savePaymentTransactionPojoData.paymentTransaction.firstName,
          lastName: savePaymentTransactionPojoData.paymentTransaction.lastName,
          email: savePaymentTransactionPojoData.paymentTransaction.email,
          mobileNo: savePaymentTransactionPojoData.paymentTransaction.mobileNo,
          txnId: savePaymentTransactionPojoData.paymentTransaction.txnId,
          amount: savePaymentTransactionPojoData.paymentTransaction.amount,
          productInfo:
            savePaymentTransactionPojoData.paymentTransaction.productInfo,
          paymentReturnStatus: paidStatus, // 0- fail & 1-success
          paymentReturnData: product,
          mihpayid: "13750ID",
        },
      };
      if (paidStatus == 1) {
        apiPost({
          url:
            endpoint.promotionalAdmissionStudent +
            "/admission-registration-and-transaction-header-and-payment-transaction",
          postBody: dataToSave,
        }).then(({ success }) => {
          if (success) {
            localStorage.removeItem("savePaymentTransactionPojo");
          }
        });
      }

      apiGet({
        url:
          endpoint.paymentUrl +
          "-transaction/update-status?transactionId=" +
          obj.merchantTxnNo +
          "&status=" +
          resp +
          "&data=" +
          product +
          "&mihpayId=13750ID",
      }).then(({ success }) => {
        if (paidStatus == 0) {
          this.props.showNotification({
            msg: "Payment Failed",
            severity: error,
          });
          window.location.replace("/student-apply-for-next-year");
        } else {
          window.location.replace("/student-apply-for-next-year");
          this.props.showNotification({
            msg: "Payment Successfull",
          });
        }
      });
    } else {
      let certificateData = localStorage.getItem(
        "saveCetificatePaymentTransactionPojo"
      );
      let saveCetificatePaymentTransactionPojoData =
        JSON.parse(certificateData);

      let documentToSave = {
        certificateApplicationHeader: {
          id: saveCetificatePaymentTransactionPojoData
            .certificateApplicationHeader.id,
        },
        detail: saveCetificatePaymentTransactionPojoData.detail,
        userRemark: saveCetificatePaymentTransactionPojoData.userRemark,
        type: saveCetificatePaymentTransactionPojoData.type,
        documentUpload: saveCetificatePaymentTransactionPojoData.documentUpload,
        isPaid: paidStatus,
        payDetail: "",
        amount: saveCetificatePaymentTransactionPojoData.amount,
        status: 0,
        deliveryDate: "",
      };
      apiPost({
        url: endpoint.certificate,
        postBody: documentToSave,
      }).then(({ success }) => {
        if (success) {
          apiGet({
            url:
              endpoint.paymentUrl +
              "-transaction/update-status?transactionId=" +
              obj.merchantTxnNo +
              "&status=" +
              resp +
              "&data=" +
              product +
              "&mihpayId=13750ID",
          }).then(({ success }) => {
            if (paidStatus == 0) {
              this.props.showNotification({
                msg: "Payment Failed",
                severity: error,
              });
              window.location.replace("/list-of-certificate");
            } else {
              window.location.replace("/list-of-certificate");
              this.props.showNotification({
                msg: "Payment Successfull",
              });
            }
          });
        }
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return (
      <>
        {this.state.showSuccess && (
          <>
            <body className="payment-success-body">
              <div className="payment-success-card">
                <div
                  style={{
                    borderRadius: "200px",
                    height: "200px",
                    width: "200px",
                    background: "#F8FAF5",
                    margin: "0 auto",
                  }}
                >
                  <i className="payment-success-i">
                    <span className="success-checkmark">✓</span>
                  </i>
                </div>
                <h1 className="payment-success-h1">Success</h1>
                <p className="payment-success-p">
                  Payment successfully done
                  <br />
                </p>
              </div>
            </body>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  promotionalAdmissionList: state.promotionalAdmmision,
});
const mapDispatchToProps = {
  getApplicantAdmission,
  getPendingFees,
  saveApplicantFee,
  getAlert,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
