export const StudentShowJson = {
  screenTitle: "Student Syllabus",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getAcadamicYear: true,
  getListId: "acadamicYear,yearDetail",
  searchAlign: 1.2,
  fieldMeta: [
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 2.7,
      lg: 2.7,
      sm: 2.7,
      xs: 12,
      masterName: "yearMaster",
      getListId: "yearDetail,acadamicYear",
      dataKey: "yearDetail",
      isMandatory: true,
    },

    {
      label: "Semester Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 2.7,
      lg: 2.7,
      sm: 2.7,
      xs: 12,
      getListFrom: "acadamicYear,yearDetail",
      masterName: "semisterMaster",
      getListId: "semister",
      dataKey: "semister",
      isMandatory: true,
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 2.7,
      lg: 2.7,
      sm: 2.7,
      xs: 12,
      masterName: "subjectMaster",
      getListFrom: "semister",
      getListId: "subject",
      dataKey: "subject",
      isMandatory: true,
    },
    {
      label: "Scheme",
      controlType: "autocomplete",
      placeHolder: "",
      md: 2.7,
      lg: 2.7,
      sm: 2.7,
      xs: 12,
      masterName: "schemeMaster",
      getListFrom: "subject",
      dataKey: "subjectDetail",
      isMandatory: true,
    },
  ],
};
