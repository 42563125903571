import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  teacherDashboard: {},
  isFetchTeacherDashboard: false,

  graphData: [],
  isFetchGraph: false,
};
let teacherDashboardURL = endpoints.teacherDashboard;
let syllabus = endpoints.syllabus;
const teacherDashboardSlice = createSlice({
  name: "teacherDashboard",
  initialState,
  reducers: {
    teacherDashboardSuccess: (state = cloneDeep(initialState), action) => {
      const { dashboardData } = action.payload;
      return {
        ...state,
        teacherDashboard: dashboardData,
        isFetchTeacherDashboard: true,
      };
    },

    graphSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        graphData: row,
        isFetchGraph: true,
      };
    },
  },
});

export const { teacherDashboardSuccess, graphSuccess } =
  teacherDashboardSlice.actions;

export default teacherDashboardSlice.reducer;

export const teacherDashboard = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: teacherDashboardURL,
    }).then(({ data, success }) => {
      if (success) {
        const teacherData = data.data;
        if (teacherData !== null) {
          let dashboardData = {
            firstName:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.firstName
                : "",
            middleName:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.middleName
                : "",
            lastName:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.lastName
                : "",
            employeeCode:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.employeeCode
                : "",
            dateOfBirth:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.dateOfBirth
                : "",
            employeeDesignation:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.designation.name
                : "",
            employeeWorkloadCount: teacherData.employeeWorkloadCount,
            employeeEmail:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.emailId
                : "",
            employeeDepartment:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.department.name
                : "",
            employeeJoiningDate:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.joiningDate
                : "",
            employeeFeedbackCount:
              teacherData.employeeFeedback !== null
                ? teacherData.employeeFeedback.length
                : "",
            employeeNoticeCount:
              teacherData.employeeNotice !== null
                ? teacherData.employeeNotice.length
                : "",
            employeeMaterialCount:
              teacherData.ematerial !== null
                ? teacherData.ematerial.length
                : "",
            employeeEventCount:
              teacherData.eventCount !== null ? teacherData.eventCount : "",
            employeeSuggestionCount: teacherData.suggestionCount
              ? teacherData.suggestionCount
              : "",
            employeeRemainingVivaList: teacherData.remainingVivaList
              ? teacherData.remainingVivaList.length
              : "",
            dateOfBirth:
              teacherData.employeeDetails !== null
                ? teacherData.employeeDetails.dateOfBirth
                : "",
            timetableList:
              teacherData.timeTable !== null
                ? teacherData.timeTable.map((timeTableData, index) => {
                    let bData1 = {
                      index: index + 1,
                      yearDetailName:
                        timeTableData.yearDetail.year.programType.name +
                        " - " +
                        timeTableData.yearDetail.programTitle.brName +
                        " - " +
                        timeTableData.yearDetail.year.className,
                      divisionName: timeTableData.division,
                      batchName: timeTableData.batch,
                      subjectName:
                        timeTableData.subjectAndSubjectDetail.subject.name +
                        " - " +
                        timeTableData.subjectAndSubjectDetail.schemeHeadId.name,
                    };
                    return bData1;
                  })
                : [],
            libraryResponseList:
              teacherData.getLibraryResponse !== null
                ? (teacherData.getLibraryResponse = {
                    id: teacherData.getLibraryResponse.id,
                    borrid: teacherData.getLibraryResponse.borrid,
                    message: teacherData.getLibraryResponse.message,
                    name: teacherData.getLibraryResponse.name,
                    pendingFees: teacherData.getLibraryResponse.pendingFees,
                    validUntil: teacherData.getLibraryResponse.validUntil,
                    verified: teacherData.getLibraryResponse.verified,
                    warningMessage:
                      teacherData.getLibraryResponse.warningMessage,
                    lentItems: teacherData.getLibraryResponse.lentItems.map(
                      (data1, index) => {
                        let bData1 = {
                          id: data1.id,
                          index: index + 1,
                          title: data1.title,
                          mediaType: data1.mediaType,
                          status: data1.status,
                          returnDate: data1.returnDate,
                          author: data1.author,
                          barcode: data1.barcode,
                          catrefnum: data1.catrefnum,
                          homeBranch: data1.homeBranch,
                          lendingBranch: data1.lendingBranch,
                        };
                        return bData1;
                      }
                    ),
                  })
                : "",
          };
          dispatch(teacherDashboardSuccess({ dashboardData }));
        }
        // return { success: success, data: data };
      }
      return { success: success, data: data };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// http://localhost:8088/api/acdmc/v1/syllabus/get-total-syllabus-complete-count?subjectDetailId=35497692-a8c1-4477-a496-c41f5f84c5bc
export const getGraphData =
  ({ subjectDetailId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url:
          syllabus +
          "/get-total-syllabus-complete-count?subjectDetailId=" +
          subjectDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              y: data1.completeSyllabusPer,
              label: "Complete",
              // y:data1.inCompleteSyllabusPer,
            };
            return bData;
          });
          dispatch(graphSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };
