import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { DepartmentMasterJson } from '../../../../DynamicFormsJson/Department'
import { DepartmentColumns } from "../../../../tableColumns/table-columns";
import { getClass } from "../../OnlineModule/Class/class.slice"
import { getDepartment } from './department.slice'
class Department extends React.Component
{

    // eslint-disable-next-line no-useless-constructor
    constructor( props )
    {
        super( props );
        this.state = {
            dynamicMasterData: { yearDetailList: this.props.yearDetail?.class }
        }
    }

    componentDidMount()
    {
        const { getClass, getDepartment } = this.props;
        getClass();
        getDepartment()
    }
    componentWillReceiveProps( nextProps )
    {
        if ( this.props.yearDetail !== nextProps.yearDetail )
        {
            if ( this.props.yearDetail.class !== nextProps.yearDetail.class )
            {
                this.setState( {
                    dynamicMasterData: { ...this.state.dynamicMasterData, yearDetailList: nextProps.yearDetail?.class.filter( ( data ) => data.isActive == 1 ) }
                } )
            }
        }
    }
    render()
    {

        const { dynamicMasterData } = this.state;
        const { departmentList } = this.props
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={DepartmentMasterJson.screenTitle}
                        fieldMeta={DepartmentMasterJson.fieldMeta}
                        showPdfDownload={DepartmentMasterJson.showPdfDownload}
                        showExcelDownload={DepartmentMasterJson.showExcelDownload}
                        pdfFileName={DepartmentMasterJson.pdfFileName}
                        excelFileName={DepartmentMasterJson.excelFileName}
                        showAddButton={DepartmentMasterJson.showAddButton}
                        tableColumnsToFilter={DepartmentMasterJson.tableColumnsToFilter}
                        dynamicMasterData={dynamicMasterData}
                        tableColumns={DepartmentColumns}
                        tableData={departmentList?.department}
                        getTableData={this.props.getDepartment}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={departmentList.isFetch}
                        baseIdColumn={DepartmentMasterJson.baseIdColumn}
                        apiBaseURL={DepartmentMasterJson.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = ( state ) => ( {
    yearDetail: state.class,
    departmentList: state.department
} );
const mapDispatchToProps = {
    getClass,
    getDepartment
}
export default connect( mapStateToProps, mapDispatchToProps )( Department );