import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { onlyNumber } from "../../../components/Comman/Util/Validations";
import * as myConstClass from "../../../config/messageconstant";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddQuestion = ({
  open,
  feedBackId,
  feedBack,
  handleCloses,
  onSave,
  editData,
}) => {
  const [question, setquestion] = React.useState("");
  const [sortOrderNo, setSortOrderNo] = React.useState("");
  const [optionType, setoptionType] = React.useState("");

  const [formErrors, setFormErrors] = React.useState({});
  const handleClose = () => {
    handleCloses();
  };
  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name === "question") {
      setquestion(value);
    } else if (name === "optionType") {
      setoptionType(value);
    } else if (name === "sortOrderNo") {
      console.log(value);
      if (value === "") {
        setSortOrderNo("");
      } else {
        if (onlyNumber.test(value)) {
          setSortOrderNo(value);
        }
      }
    }
  };

  useEffect(() => {
    console.log(feedBack?.feedBackDetailById);
    if (Object.keys(feedBack?.feedBackDetailById).length === 0) {
      setquestion("");
      setoptionType("");
      setSortOrderNo("");
      setFormErrors({});
    } else {
      setquestion(feedBack?.feedBackDetailById.question);
      setoptionType(feedBack?.feedBackDetailById.optionType);
      setSortOrderNo(feedBack?.feedBackDetailById.orderNo);
      setFormErrors({});
    }
  }, []);
  const submitHandler = () => {
    console.log(sortOrderNo);
    let QuestionData = {
      feedBackId: feedBackId,
      question: question,
      orderNo: sortOrderNo,
      optionType: optionType,
    };
    if (Object.keys(feedBack?.feedBackDetailById).length !== 0) {
      QuestionData = {
        ...QuestionData,
        id: feedBack?.feedBackDetailById.id,
      };
    }
    console.log(QuestionData);
    if (checkValidation()) {
      onSave(QuestionData);
    }
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (question.toString().trim() === "" || question === null) {
      formIsValid = false;
      formErrors["questionError"] = myConstClass.questionMsg;
    }

    if (sortOrderNo === "" || sortOrderNo === null) {
      formIsValid = false;
      formErrors["sortOrderNoError"] = myConstClass.sortOrderNoMsg;
    }

    if (optionType.toString().trim() === "" || optionType === null) {
      formIsValid = false;
      formErrors["optionTypeError"] = myConstClass.optionType;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Question
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                spacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Question  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="question"
                      size="small"
                      value={question}
                      error={formErrors?.questionError ? true : false}
                      helperText={
                        formErrors?.questionError
                          ? formErrors?.questionError
                          : " "
                      }
                      multiline
                      onChange={changedHandler}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Option Type "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl color={"primary"} fullWidth={true}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="optionType"
                        id="optionType"
                        value={optionType}
                        onChange={changedHandler}
                        size="small"
                        fullWidth={true}
                        error={formErrors?.optionTypeError ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">Description </MenuItem>
                        <MenuItem value="0">optional</MenuItem>
                        <MenuItem value="2">MultiSelect</MenuItem>
                      </Select>
                      <FormHelperText error>
                        {formErrors?.optionTypeError
                          ? formErrors?.optionTypeError
                          : " "}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Sort Order No  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="sortOrderNo"
                      size="small"
                      value={sortOrderNo}
                      error={formErrors?.sortOrderNoError ? true : false}
                      helperText={
                        formErrors?.sortOrderNoError
                          ? formErrors?.sortOrderNoError
                          : " "
                      }
                      onChange={changedHandler}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  sx={{ mt: -4 }}
                  justifyContent="right"
                >
                  <Grid item justifyContent="space-evenly">
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={handleClose}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item justifyContent="space-evenly">
                    <ButtonCompo
                      size="medium"
                      type="Submit"
                      variant="contained"
                      name="Submit"
                      onClick={submitHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({ feedBack: state.feedBack });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
