import endpoint from "../config/endpoints";

export const AlumniCollaboratorJson = {
  apiBaseURL: endpoint.volunteerUpdateStatus,
  screenTitle: "Be a Collaborator",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Type of Collaboration",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "CollaborationMaster",
      dataKey: "expertise",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "CollaborationMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "collaborationNew",
      tableDataKey: "expertise",
      open: "other",
      error: "Collaboration",
    },
  ],
};
