import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { RoomColumns } from "../../../../tableColumns/table-columns";
import { roomJson } from "../../../../DynamicFormsJson/room";
import { getFloor } from "../Floor/floor.slice";
import { getRoom } from "./room.slice";
class Room extends React.Component
{
  // eslint-disable-next-line no-useless-constructor
  constructor( props )
  {
    super( props );
    this.state = {
      dynamicMasterData: { floorDetail: this.props.floorList?.floor },
    };
  }
  componentWillReceiveProps( nextProps )
  {
    if ( this.props.floorList !== nextProps.floorList )
    {
      if ( this.props.floorList?.floor !== nextProps.floorList?.floor )
      {
        this.setState( {
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            floorDetail: nextProps.floorList?.floor.filter( ( data ) => data.isActive == 1 ),
          },
        } );
      }
    }
  }
  componentDidMount()
  {
    const { getFloor, getRoom } = this.props;
    getFloor();
    getRoom();
  }

  render()
  {
    const { dynamicMasterData } = this.state;
    const { roomList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={roomJson.screenTitle}
            fieldMeta={roomJson.fieldMeta}
            showPdfDownload={roomJson.showPdfDownload}
            showExcelDownload={roomJson.showExcelDownload}
            pdfFileName={roomJson.pdfFileName}
            excelFileName={roomJson.excelFileName}
            showAddButton={roomJson.showAddButton}
            tableColumnsToFilter={roomJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            tableColumns={RoomColumns}
            tableData={roomList?.room}
            getTableData={this.props.getRoom}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={roomList.isFetch}
            apiBaseURL={roomJson.apiBaseURL}
            baseIdColumn={roomJson.baseIdColumn}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  floorList: state.floor,
  roomList: state.room,
} );
const mapDispatchToProps = {
  getFloor,
  getRoom,
};
export default connect( mapStateToProps, mapDispatchToProps )( Room );
