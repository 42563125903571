import endpoint from "../config/endpoints";
export const adminConsultancyJson = {
  apiBaseURL: endpoint.consultancy,
  screenTitle: "Consultancy",
  showAddButton: true,
  baseIdColumn: "id",
  DocumentUpload: "employeeConsultancyDocument",
  dataSetToAdd: [
    {
      label: "Department",
      value: "departmentId",
      masterName: "departmentMaster",
      isRootLevelKey: false,
    },
    {
      label: "Teacher",
      value: "userRegistration",
      masterName: "teacherMaster",
      isRootLevelKey: true,
    },
  ],
  searchList: [
    {
      label: "Department",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "departmentMaster",
      dataKey: "departmentId",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "departmentId",
    },
    {
      label: "Teacher",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "teacherMaster",
      dataKey: "userRegistration",
      isRootLevelKey: false,
      isMandatory: true,
      getListFrom: "departmentId",
    },
  ],
  fieldMeta: [
    // {
    //   label: "Department",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "departmentMaster",
    //   dataKey: "departmentId",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    //   getListId: "departmentId",
    // },
    // {
    //   label: "Teacher",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "teacherMaster",
    //   dataKey: "userRegistration",
    //   isRootLevelKey: false,
    //   isMandatory: true,
    //   getListFrom: "departmentId",
    // },
    {
      label: "Consultancy Nature",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "consNature",
      isMandatory: true,
    },
    {
      label: "Consultancy Project Title",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "consTitle",
      isMandatory: true,
    },

    {
      label: "Name Of The Client Organization",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "clientOrganisationName",
      isMandatory: true,
    },
    {
      label: "Consultancy Details",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "consAgency",
      isMandatory: true,
    },
    {
      label: "Consultancy Period",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "consPeriod",
      isMandatory: true,
    },
    {
      label: "Consultancy Amount",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "consAmount",
      inputType: "number",
      isMandatory: true,
    },

    {
      label: "Consultancy Completed",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "consCompleted",
      defaultValue: 1,
      radioGroupItems: [
        {
          label: "Yes",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ],
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
};
