import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { DeemedReportJson } from "../../DynamicFormsJson/DeemedReport";
import { getDeemedCommittee } from "../Deemed/Committee/committee.slice";
import { getAlert } from "../../CommonActions/alert.slice";

class DeemedReport extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        committeeMaster: this.props.deemedCommitteeList.deemedCommittee.filter(
          (data) => data.isActive == 1
        ),
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.deemedCommitteeList !== nextProps.deemedCommitteeList) {
      if (
        this.props.deemedCommitteeList.deemedCommittee !==
        nextProps.deemedCommitteeList.deemedCommittee
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            committeeMaster: nextProps.deemedCommitteeList.deemedCommittee,
          },
        });
      }
    }
  }
  componentDidMount() {
    const { getDeemedCommittee, getAlert } = this.props;

    getDeemedCommittee().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  rowData = [
    {
      index: 1,
      reportName: "Committee Meeting Summary Report (D0, D1, D2)",
      filter: "fromDate,toDate,committee",
      url: "/api/report/v1/committee-summary-report/{committee}?fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 2,
      reportName: "Committee And Committee-members Report (D0)",
      filter: "committee",
      url: "/api/report/v1/committee-and-committee-members/{committee}",
    },
    {
      index: 3,
      reportName: "Committee Wise Committee-members Report (D0)",
      filter: "committee",
      url: "/api/report/v1/committee-member/get-committee-members-by-committee-id-report?committeeId={committee}&isSign=0",
    },
    {
      index: 4,
      reportName: "Committee Wise Committee-members Report (Signature) (D0)",
      filter: "committee",
      url: "/api/report/v1/committee-member/get-committee-members-by-committee-id-report?committeeId={committee}&isSign=1",
    },
  ];
  getListById = (data) => {};
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={DeemedReportJson.screenTitle}
          fieldMeta={DeemedReportJson.fieldMeta}
          buttonCenter={DeemedReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={DeemedReportJson.searchButton}
          PDFButton={DeemedReportJson.PDFButton}
          EXCELButton={DeemedReportJson.EXCELButton}
          showHeadExcel={false}
          baseIdColumn={DeemedReportJson.baseIdColumn}
          apiBaseURL={DeemedReportJson.apiBaseURL}
          marginTop={DeemedReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  deemedCommitteeList: state.deemedCommittee,
});
const mapDispatchToProps = {
  getDeemedCommittee,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(DeemedReport);
