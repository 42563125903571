import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  subject: [],
  isFetch: false,
  subjectByDepartment: [],
  isFetchSubjectByDepartment: false,
  subjectDetailBySubject: [],
  isFetchsubjectDetailBySubject: false,
  subjectById: {},
  isFetchSubjectById: false,
  subjectBydeptAndSemisterId: [],
  isFetchSubjectBydeptAndSemisterId: false,
  subjects: [],
  isFetchSubjects: false,
  subjectBysemisterDetail: [],
  isFetchSubjectsBySemister: false,
  msg: "",
  isErrorMsg: false,

  subjectBySemisterAndDepartment: [],
  isFetchSubjectsBySemAndDept: false,
};
let URL = endpoints.subjectInfo;
const deptId = localStorage.getItem("departmentId");
const subjectInfoSlice = createSlice({
  name: "subject",
  initialState,
  reducers: {
    subjectInfoSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subject: row,
        isFetch: true,
      };
    },

    subjectListReset: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },

    subSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectBySemisterAndDepartment: row,
        isFetchSubjectsBySemAndDept: true,
      };
    },

    subjectInfoBySemisterAndDeptSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectBydeptAndSemisterId: row,
        isFetchSubjectBydeptAndSemisterId: true,
      };
    },
    subjectByDepartmentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectByDepartment: row,
        isFetchSubjectByDepartment: true,
      };
    },

    subjectDetailBySubjectSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectDetailBySubject: row,
        isFetchsubjectDetailBySubject: true,
      };
    },

    subjectInfoByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { subjectInfo } = action.payload;
      return {
        ...state,
        subjectById: subjectInfo,
        isFetchSubjectById: true,
      };
    },

    resetSubjectInfoByIdSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        subjectById: {},
        isFetchSubjectById: false,
        subjectByDepartment: [],
        isFetchSubjectByDepartment: false,
      };
    },

    subjectsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjects: row,
        isFetchSubjects: true,
      };
    },

    subjectsFailure: (state = cloneDeep(initialState), action) => {
      const { msg } = action.payload;
      return {
        ...state,
        msg: msg,
        isErrorMsg: true,
      };
    },

    subjectsBySemisterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectBysemisterDetail: row,
        isFetchSubjectsBySemister: true,
      };
    },
  },
});

export const {
  subjectInfoSuccess,
  subjectInfoByIdSuccess,
  subjectInfoBySemisterAndDeptSuccess,
  resetSubjectInfoByIdSuccess,
  subjectByDepartmentSuccess,
  subjectDetailBySubjectSuccess,
  subjectsSuccess,
  subjectsBySemisterSuccess,
  subSuccess,
  subjectListReset,
  subjectsFailure,
} = subjectInfoSlice.actions;

export default subjectInfoSlice.reducer;

export const getSubjectInfo = () => async (dispatch) => {
  try {
    const success = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,

            name: data1.name + " - " + data1.shortName,

            code: data1.code,

            patternName: data1.pattern.name,
            patternId: data1.pattern.id,

            semisterName: data1.semister.name,
            semisterId: data1.semister.id,

            subjectTypeName: data1.subjectTypeId.name,
            subjectTypeId: data1.subjectTypeId.id,

            yearDetailName:
              data1.getYearDetailId !== null
                ? data1.getYearDetailId.year.programType.name +
                  " - " +
                  data1.getYearDetailId.programTitle.brName +
                  " - " +
                  data1.getYearDetailId.year.className
                : "",
            yearDetailId:
              data1.getYearDetailId !== null ? data1.getYearDetailId.id : "",

            isActive: data1.isActive,

            subjectDetails: data1.subjectDetails.map((subjectDetailsData) => {
              let subjectDetail = {
                id: subjectDetailsData.id,
                schemeName: subjectDetailsData.schemeHeadId.schemeId.name,
                schemeHeadName: subjectDetailsData.schemeHeadId.name,

                value: subjectDetailsData.value,
                minMarks: subjectDetailsData.minMarks,
                maxMarks: subjectDetailsData.maxMarks,
                schemeHeadId: subjectDetailsData.schemeHeadId.id,
                isActive: subjectDetailsData.isActive,
              };
              return subjectDetail;
            }),
          };
          row.push(bData);
          return data1;
        });
        dispatch(subjectInfoSuccess({ row }));
      }
      return success;
    });
    return success;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubjectInfoByYearDetail =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL + "/get-subject-by-year-detail?yearDetailId=" + yearDetail,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,

              name: data1.name + " - " + data1.shortName,

              code: data1.code,

              patternName: data1.pattern.name,
              patternId: data1.pattern.id,

              semisterName: data1.semister.name,
              semisterId: data1.semister.id,

              subjectTypeName: data1.subjectTypeId.name,
              subjectTypeId: data1.subjectTypeId.id,

              yearDetailName:
                data1.getYearDetailId !== null
                  ? data1.getYearDetailId.year.programType.name +
                    " - " +
                    data1.getYearDetailId.programTitle.brName +
                    " - " +
                    data1.getYearDetailId.year.className
                  : "",
              yearDetailId:
                data1.getYearDetailId !== null ? data1.getYearDetailId.id : "",

              isActive: data1.isActive,

              subjectDetails: data1.subjectDetails.map((subjectDetailsData) => {
                let subjectDetail = {
                  id: subjectDetailsData.id,
                  schemeName: subjectDetailsData.schemeHeadId.schemeId.name,
                  schemeHeadName: subjectDetailsData.schemeHeadId.name,
                  value: subjectDetailsData.value,
                  minMarks: subjectDetailsData.minMarks,
                  maxMarks: subjectDetailsData.maxMarks,
                  schemeHeadId: subjectDetailsData.schemeHeadId.id,
                  isActive: subjectDetailsData.isActive,
                };
                return subjectDetail;
              }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectInfoSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };


// New Method Added on April 12 2024

// http://localhost:8087/v1/subject/get-subject-by-year-detail-and-semister-detail-and-pattern?yearDetailId=ac55ecf1-ee83-4b89-8c38-66024c39d32a&semisterDetailId=22c4b3ed-23ab-481c-ab19-638ae7bb3f67&patternId=33a59d20-b357-4f10-875d-2ce6a7d1bda7


export const getSubjectInfoByYearDetailNew =
  ({ yearDetailId, semisterDetailId, patternId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL + "/get-subject-by-year-detail-and-semister-detail-and-pattern?yearDetailId=" + yearDetailId +"&semisterDetailId="+semisterDetailId+"&patternId="+patternId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,

              name: data1.name + " - " + data1.shortName,

              code: data1.code,

              patternName: data1.pattern.name,
              patternId: data1.pattern.id,

              semisterName: data1.semister.name,
              semisterId: data1.semister.id,

              subjectTypeName: data1.subjectTypeId.name,
              subjectTypeId: data1.subjectTypeId.id,

              yearDetailName:
                data1.getYearDetailId !== null
                  ? data1.getYearDetailId.year.programType.name +
                    " - " +
                    data1.getYearDetailId.programTitle.brName +
                    " - " +
                    data1.getYearDetailId.year.className
                  : "",
              yearDetailId:
                data1.getYearDetailId !== null ? data1.getYearDetailId.id : "",

              isActive: data1.isActive,

              subjectDetails: data1.subjectDetails.map((subjectDetailsData) => {
                let subjectDetail = {
                  id: subjectDetailsData.id,
                  schemeName: subjectDetailsData.schemeHeadId.schemeId.name,
                  schemeHeadName: subjectDetailsData.schemeHeadId.name,
                  value: subjectDetailsData.value,
                  minMarks: subjectDetailsData.minMarks,
                  maxMarks: subjectDetailsData.maxMarks,
                  schemeHeadId: subjectDetailsData.schemeHeadId.id,
                  isActive: subjectDetailsData.isActive,
                };
                return subjectDetail;
              }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectInfoSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };




// New Method end














// api/acdmc/v1/subject/by-department?departmentId=cf5c0f2b-4e17-440d-b734-6fb6438bcbe2
export const getSubjectByDepartment =
  ({ departmentId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL + "/by-department?departmentId=" + departmentId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,

              name: data1.name + " - " + data1.shortName,

              // code: data1.code,

              // patternName: data1.pattern.name,
              // patternId: data1.pattern.id,

              // semisterName: data1.semister.name,
              // semisterId: data1.semister.id,

              // subjectTypeName: data1.subjectTypeId.name,
              // subjectTypeId: data1.subjectTypeId.id,

              // yearDetailName:
              //   data1.getYearDetailId.programTitle.brName +
              //   " - " +
              //   data1.getYearDetailId.year.className,
              // yearDetailId: data1.getYearDetailId.id,

              isActive: data1.isActive,

              subjectDetails: data1.subjectDetails.map((subjectDetailsData) => {
                let subjectDetail = {
                  id: subjectDetailsData.id,
                  schemeHeadName: subjectDetailsData.schemeHeadId.name,
                  value: subjectDetailsData.value,
                  minMarks: subjectDetailsData.minMarks,
                  maxMarks: subjectDetailsData.maxMarks,
                  schemeHeadId: subjectDetailsData.schemeHeadId.id,
                  isActive: subjectDetailsData.isActive,
                };
                return subjectDetail;
              }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectByDepartmentSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectDetailBySubject =
  ({ subjectId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL + "/" + subjectId + "/get-subject-detail",
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.schemeHeadId.name + " - " + data1.schemeHeadId.shortName,
              schemeHeadId: data1.schemeHeadId.id,
              // code: data1.code,

              // patternName: data1.pattern.name,
              // patternId: data1.pattern.id,

              // semisterName: data1.semister.name,
              // semisterId: data1.semister.id,

              // subjectTypeName: data1.subjectTypeId.name,
              // subjectTypeId: data1.subjectTypeId.id,

              // yearDetailName:
              //   data1.getYearDetailId.programTitle.brName +
              //   " - " +
              //   data1.getYearDetailId.year.className,
              // yearDetailId: data1.getYearDetailId.id,

              isActive: data1.isActive,

              // subjectDetails: data1.subjectDetails.map( ( subjectDetailsData ) =>
              // {
              //   let subjectDetail = {
              //     id: subjectDetailsData.id,
              //     schemeHeadName: subjectDetailsData.schemeHeadId.name,
              //     value: subjectDetailsData.value,
              //     minMarks: subjectDetailsData.minMarks,
              //     maxMarks: subjectDetailsData.maxMarks,
              //     schemeHeadId: subjectDetailsData.schemeHeadId.id,
              //     isActive: subjectDetailsData.isActive,
              //   };
              // return subjectDetail;
              // } ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectDetailBySubjectSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectSchemeBySubjectId =
  ({ subjectId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + subjectId,
      }).then(({ data, success }) => {
        if (success) {
          const subjectdata = data.data;
          let row = [];
          let index = 0;

          subjectdata.subjectDetails.map((subjectDetailsData, subDetIndex) => {
            if (subjectDetailsData.schemeHeadId.schemeId.isTeaching == 1) {
              index = index + 1;
              let subjectDetail = {
                index: index,
                id: subjectDetailsData.id,
                // name:
                //   subjectDetailsData.schemeHeadId.name +
                //   " - " +
                //   subjectDetailsData.value,
                name: subjectDetailsData.schemeHeadId.name +
                  " - " +
                  subjectDetailsData.value,
                value: subjectDetailsData.value,
              };
              row.push(subjectDetail);
            }
          });
          let subjectInfo = {
            id: subjectdata.id,
            name: subjectdata.name,
            shortName: subjectdata.shortName,
            code: subjectdata.code,
            patternName: subjectdata.pattern.name,
            patternId: subjectdata.pattern.id,
            subjectTypeName: subjectdata.subjectTypeId.name,
            subjectTypeId: subjectdata.subjectTypeId.id,
            departmentId: subjectdata.departmentId,
            semisterName: subjectdata.semister.name,
            semisterId: subjectdata.semister.id,
            yearDetailName:
              subjectdata.getYearDetailId.programTitle.brName +
              " - " +
              subjectdata.getYearDetailId.year.className,
            yearDetailId: subjectdata.getYearDetailId.id,
            isActive: subjectdata.isActive,
            subjectDetails: row,
          };
          dispatch(subjectInfoByIdSuccess({ subjectInfo }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getSubjectInfoById =
  ({ subjectId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + subjectId,
      }).then(({ data, success }) => {
        if (success) {
          const subjectdata = data.data;

          let subjectInfo = {
            id: subjectdata.id,
            name: subjectdata.name,
            shortName: subjectdata.shortName,
            code: subjectdata.code,
            patternName: subjectdata.pattern.name,
            patternId: subjectdata.pattern.id,
            subjectTypeName: subjectdata.subjectTypeId.name,
            subjectTypeId: subjectdata.subjectTypeId.id,
            departmentId: subjectdata.departmentId,
            semisterName: subjectdata.semister.name,
            semisterId: subjectdata.semister.id,
            yearDetailName:
              subjectdata.getYearDetailId.programTitle.brName +
              " - " +
              subjectdata.getYearDetailId.year.className,
            yearDetailId: subjectdata.getYearDetailId.id,
            isActive: subjectdata.isActive,
            subjectDetails: subjectdata.subjectDetails.map(
              (subjectDetailsData, subjectDetailsIndex) => {
                let subjectDetail = {
                  index: subjectDetailsIndex + 1,
                  id: subjectDetailsData.id,
                  isActive: subjectDetailsData.isActive,
                  maxMarks: subjectDetailsData.maxMarks,
                  minMarks: subjectDetailsData.minMarks,
                  value: subjectDetailsData.value,
                  schemeHeadId: subjectDetailsData.schemeHeadId.id,
                  schemeHeadName:
                    subjectDetailsData.schemeHeadId.name +
                    " - " +
                    subjectDetailsData.schemeHeadId.schemeId.name,
                  name:
                    subjectDetailsData.schemeHeadId.name +
                    " - " +
                    subjectDetailsData.schemeHeadId.schemeId.name,
                };
                return subjectDetail;
              }
            ),
          };
          dispatch(subjectInfoByIdSuccess({ subjectInfo }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetSubjectInfoGetById = () => async (dispatch) => {
  try {
    dispatch(resetSubjectInfoByIdSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubjectBySemisterAndDept =
  ({ semister, department }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-detail-by-department-and-semister?semisterId=" +
          semister +
          "&departmentId=" +
          department,
      }).then(({ data, success }) => {
        if (success) {
          const subjectdata = data.data;

          const row = subjectdata.map((data1) => {
            let subjectD = {
              id: data1.id,
              name: data1.name + " ( " + data1.shortName + " )",
            };
            return subjectD;
          });
          dispatch(subjectInfoBySemisterAndDeptSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// export const getSubjectInfoByYearDetailId =
//   ( { yearDetailId } ) =>
//     async ( dispatch ) =>
//     {
//       try
//       {
//         const response = apiGet( {
//           url: URL + "/by-year-detail?yearDetailId=" + yearDetailId,
//         } ).then( ( { data, success } ) =>
//         {
//           if ( success )
//           {
//             const subjectdata = data.data;

//             let subjectInfo = {
//               id: subjectdata.id,
//               name: subjectdata.name,
//               // shortName: subjectdata.shortName,
//               // code: subjectdata.code,
//               // patternName: subjectdata.pattern.name,
//               // patternId: subjectdata.pattern.id,
//               // subjectTypeName: subjectdata.subjectTypeId.name,
//               // subjectTypeId: subjectdata.subjectTypeId.id,
//               // departmentId: subjectdata.departmentId,
//               // semisterName: subjectdata.semister.name,
//               // semisterId: subjectdata.semister.id,
//               // yearDetailName:
//               //   subjectdata.getYearDetailId.programTitle.brName +
//               //   " - " +
//               //   subjectdata.getYearDetailId.year.className,
//               // yearDetailId: subjectdata.getYearDetailId.id,
//               isActive: subjectdata.isActive,
//               // subjectDetails: subjectdata.subjectDetails.map(
//               //   ( subjectDetailsData ) =>
//               //   {
//               //     let subjectDetail = {
//               //       id: subjectDetailsData.id,
//               //       isActive: subjectDetailsData.isActive,
//               //       maxMarks: subjectDetailsData.maxMarks,
//               //       minMarks: subjectDetailsData.minMarks,
//               //       value: subjectDetailsData.value,
//               //       schemeHeadId: subjectDetailsData.schemeHeadId.id,
//               //       schemeHeadName: subjectDetailsData.schemeHeadId.name,
//               //     };
//               //     return subjectDetail;
//               //   }
//               // ),
//             };
//             dispatch( subjectsSuccess( { row } ) );
//           }
//           return success;
//         } );
//         return response;
//       } catch ( e )
//       {
//         return console.error( e.message );
//       }
//     };

export const getSubjectInfoByYearDetailId =
  ({ yearDetailId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL + "/by-year-detail?yearDetailId=" + yearDetailId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          if (data.errorMessage == null) {
            const content = data.getSubject;
            let index = 0;
            let row = [];
            content.map((data1) => {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                name: data1.name,
                isActive: data1.isActive,
              };
              row.push(bData);
              return data1;
            });
            dispatch(subjectsSuccess({ row }));
          } else {
            dispatch(subjectsFailure(data.errorMessage));
          }
        }
        return data;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectInfoBySemisterDetailId =
  ({ semisterId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: URL + "/by-semister-detail?semisterDetailId=" + semisterId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(subjectsBySemisterSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectBySemesterAndDepartmentId =
  ({ semesterId }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url:
          URL +
          "/by-department-and-semister?departmentId=" +
          deptId +
          "&semisterId=" +
          semesterId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
            };
            row.push(bData);
            return data1;
          });
          dispatch(subSuccess({ row }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetSubjectInfo = () => async (dispatch) => {
  try {
    dispatch(subjectListReset());
  } catch (e) {
    return console.error(e.message);
  }
};
