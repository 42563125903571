import { apiGet } from "../../utils/api_service";
import axios from "axios";
import { decodeData } from "../../components/Comman/Util/Base64";
import { getAlert } from "../../CommonActions/alert.slice";
import { connect } from "react-redux";
import swal from "sweetalert";

export const downloadF = ({ url, ext = "pdf", openNewTab = false }) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const response = axios
      .get(url, {
        headers: {
          Authorization: token,
          shouldrefresh: "true",
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status == 200) {
          const blb = new Blob([response.data], { type: "text/plain" });
          const reader = new FileReader();

          reader.addEventListener("loadend", (e) => {
            const text = e.srcElement.result;
            try {
              const obj = JSON.parse(text);
              console.log(obj.error);
              if (obj.error) {
                window.location.replace("/login");
                return false;
              }
            } catch (e) {
              if (!openNewTab || ext !== "pdf") {
                var blob = new Blob([response.data], {
                  type: "application/" + ext,
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "Report_" + new Date() + "." + ext;
                link.click();
              } else if (openNewTab && ext === "pdf") {
                const file = new Blob([response.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
              }
              return true;
            }
          });
          reader.readAsText(blb);
        }
        return response;
      })
      .catch((error) => {
        console.log("Error ========>", error);
        swal({
          title: "Something went wrong!",
          icon: "warning",
        });
        return false;
      });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// export const downdloadF = ( { url } ) =>
//     async ( dispatch ) =>
//     {
//         try
//         {
//             const response = apiGet( {
//                 url: URL
//             } ).then( response =>
//             {
//                 var blob = new Blob( [ response.data ], { type: "application/pdf" } );
//                 var link = document.createElement( 'a' );
//                 link.href = window.URL.createObjectURL( blob );
//                 link.download = "Report_" + new Date() + ".pdf";
//                 link.click();
//             } ).catch( ( error ) =>
//             {
//                 console.log( "Error ========>", error );

//             } );
//         } catch ( e )
//         {
//             return console.error( e.message );
//         }
//     };

//                 if ( success )
//                 {
//                     const preparedCertificateList = data.data;
//                     console.log( preparedCertificateList );
//                     let row = [];
//                     let index = 0;
//                     row = preparedCertificateList.map( ( data ) =>
//                     {
//                         index = index + 1;
//                         let companyData = {
//                             index: index,
//                             id: data.id,
//                         };
//                         return companyData;
//                     } );
//                     dispatch( preparedCertificateListSuccess( { row } ) );
//                 }
//                 return success;
//             } );
//             return response;
//         } catch ( e )
//         {
//             return console.error( e.message );
//         }
//     };

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(downloadF);
