import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoint from "../../../config/endpoints";
import { apiDelete, apiPost } from "../../../utils/api_service";
import { getSyllabus } from "../teachingPlan.slice";
import * as myConstClass from "../../../config/messageconstant";
import EditSubjectSyllabus from "./SubjectSyllabusEdit";
const BasicTable = ({
  data,
  getAlert,
  onCancelForm,
  teachingPlanList,
  subjectDetail,
  getSyllabus,
}) => {
  const [moduleList, setModuleList] = React.useState([]);
  const [editList, setEditList] = React.useState({});
  const [id, setId] = React.useState(0);
  const [remark, setRemark] = React.useState("");
  const [section, setSection] = React.useState("");
  const [subSection, setSubSection] = React.useState("");
  const [error, setErrors] = React.useState(false);
  const [subjectEdit, setSubjectEdit] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [subjectEdit1, setSubjectEdit1] = React.useState(true);
  React.useEffect(() => {
    if (
      moduleList.length === 0 &&
      teachingPlanList?.syllabus.details.length !== 0
    ) {
      setModuleList(teachingPlanList?.syllabus.details);
    } else {
      if (
        moduleList.length !== 0 &&
        moduleList !== teachingPlanList?.syllabus.details
      ) {
        setModuleList(teachingPlanList?.syllabus.details);
      }
    }
  });
  const changeHandler = (event, id) => {
    const { name, value } = event.target;

    setId(id);

    if (name === "subSection") {
      setSubSection(value);
    } else if (name === "section") {
      setSection(value);
    } else {
      setRemark(value);
    }
  };

  const checkValidation = (e) => {
    let formErrors = {};
    let formIsValid = true;
    console.log(e);
    if (e.target.name === "remark" && remark === "") {
      formIsValid = false;
      formErrors["chapterError"] = myConstClass.chapterMsg;
    }

    if (e.target.name === "section" && section === "") {
      formIsValid = false;
      formErrors["sectionError"] = myConstClass.sectionMsg;
    }

    if (e.target.name === "subSection" && subSection === "") {
      formIsValid = false;
      formErrors["subSectionError"] = myConstClass.subSectionMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const onClickDelete = (e, name, row) => {
    e.stopPropagation();

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiDelete({
          url:
            name === "chapter"
              ? endpoint.syllabusChapter + "/" + row.id
              : name === "section"
              ? endpoint.syllabusSection + "/" + row.id
              : endpoint.syllabusSubsection + "/" + row.id,
        }).then(({ data, success }) => {
          if (success) {
            getSyllabus({ subjectDetailId: subjectDetail.id }).then(
              (success) => {
                if (success) {
                  setModuleList(teachingPlanList?.syllabus.details);
                } else {
                  getAlert({ message: "Something went wrong" });
                }
              }
            );
          } else {
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
    // rowDelete( id );
  };

  const onClickEdit = (e, name, data) => {
    data = {
      ...data,
      name: name,
    };

    setEditList(data);
    setSubjectEdit(true);
    setSubjectEdit1(false);
  };
  const onCancel = (e, id) => {
    setSubjectEdit(false);
  };

  const onSubmit = (data) => {
    setSubjectEdit(false);
    let formData = {};
    if (data.editList.name === "subSection") {
      formData = {
        syllabusSection: {
          id: data.editList.sectionId,
        },
        id: data.id,
        subsectionName: data.name,
        documentName: "",
        orderNo: data.orderNo,
        isActive: 1,
      };
    } else if (data.editList.name === "section") {
      formData = {
        syllabusChapter: {
          id: data.editList.chapterId,
        },
        id: data.id,

        sectionName: data.name,
        orderNo: data.orderNo,
        isActive: 1,
      };
    } else {
      formData = {
        syllabus: {
          id: data.editList.syllabusId,
        },
        id: data.id,

        chapterName: data.name,
        orderNo: data.orderNo,
        isActive: 1,
      };
    }

    apiPost({
      url:
        data.editList.name === "chapter"
          ? endpoint.syllabusChapter
          : data.editList.name === "section"
          ? endpoint.syllabusSection
          : endpoint.syllabusSubsection,
      postBody: formData,
    }).then(({ data, success }) => {
      if (success) {
        getSyllabus({ subjectDetailId: subjectDetail.id }).then((success) => {
          setModuleList([]);
          if (success) {
            setModuleList(teachingPlanList?.syllabus.details);
          } else {
            getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  };

  const addSubSection = (e, row) => {
    console.log(e);
    let formData = {};
    if (checkValidation(e)) {
      if (e.target.name === "subSection") {
        formData = {
          syllabusSection: {
            id: row.id,
          },
          subsectionName: e.target.value,
          documentName: "",
          orderNo: row.subSectionList.length + 1,
          isActive: 1,
        };
      } else if (e.target.name === "section") {
        formData = {
          syllabusChapter: {
            id: row.id,
          },
          sectionName: e.target.value,
          orderNo: row.sectionList.length + 1,
          isActive: 1,
        };
      } else {
        formData = {
          syllabus: {
            id: teachingPlanList?.syllabus.id,
          },
          chapterName: e.target.value,
          orderNo: teachingPlanList?.syllabus.details.length + 1,
          isActive: 1,
        };
      }
      apiPost({
        url:
          e.target.name === "subSection"
            ? endpoint.syllabusSubsection
            : e.target.name === "section"
            ? endpoint.syllabusSection
            : endpoint.syllabusChapter,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          setSubSection("");
          setSection("");
          setRemark("");
          getSyllabus({ subjectDetailId: subjectDetail.id }).then((success) => {
            setModuleList([]);
            if (success) {
              setModuleList(teachingPlanList?.syllabus.details);
            } else {
              getAlert({ message: "Something went wrong" });
            }
          });
        } else {
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Sr. No.</TableCell>
              <TableCell width="30%">Chapter Name</TableCell>
              <TableCell width="30%">Section </TableCell>
              <TableCell width="30%">Sub Section </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moduleList.map((row, index) => (
              <>
                <TableRow key={row.chapter}>
                  <TableCell align="center">{index + 1}</TableCell>

                  <TableCell component="th" scope="row" colSpan={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, fontWeight: "bold" }}
                      label={row.chapter}
                    />
                    <MyComponentWithIconProps
                      statusImage={EditIcon}
                      color="primary"
                      fontSize="small"
                      title="Edit"
                      sx={{ marginLeft: 3 }}
                      onClick={(e) => onClickEdit(e, "chapter", row)}
                    />
                    <MyComponentWithIconProps
                      statusImage={DeleteIcon}
                      color="error"
                      fontSize="small"
                      title="Delete"
                      onClick={(e) => onClickDelete(e, "chapter", row)}
                    />
                  </TableCell>
                </TableRow>

                {row.sectionList.map((section, index) => {
                  return (
                    <>
                      <TableRow key={section.sectionName}>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell component="th" scope="row" colSpan={2}>
                          &nbsp;&nbsp; &nbsp;&nbsp; {section.sectionName}
                          <MyComponentWithIconProps
                            statusImage={EditIcon}
                            color="primary"
                            fontSize="small"
                            title="Edit"
                            sx={{ marginLeft: 3 }}
                            onClick={(e) => onClickEdit(e, "section", section)}
                          />
                          <MyComponentWithIconProps
                            statusImage={DeleteIcon}
                            color="error"
                            fontSize="small"
                            // sx={{ marginLeft: 35 }}
                            title="Delete"
                            onClick={(e) =>
                              onClickDelete(e, "section", section)
                            }
                          />
                        </TableCell>
                      </TableRow>
                      {section.subSectionList.map((subSection, index) => {
                        return (
                          <>
                            <TableRow key={subSection.subSectionName}>
                              <TableCell align="center"></TableCell>
                              <TableCell align="center" colSpan={2}></TableCell>
                              <TableCell component="th" scope="row">
                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                &nbsp;&nbsp; {subSection.subSectionName}
                                <MyComponentWithIconProps
                                  statusImage={EditIcon}
                                  color="primary"
                                  fontSize="small"
                                  title="Edit"
                                  sx={{ marginLeft: 3 }}
                                  onClick={(e) =>
                                    onClickEdit(e, "subSection", subSection)
                                  }
                                />
                                <MyComponentWithIconProps
                                  statusImage={DeleteIcon}
                                  color="error"
                                  fontSize="small"
                                  // sx={{ marginLeft: 35 }}
                                  title="Delete"
                                  onClick={(e) =>
                                    onClickDelete(e, "subSection", subSection)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}

                      <TableRow key={row.chapter}>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center" colSpan={2}></TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            color="primary"
                            name="subSection"
                            size="small"
                            value={subSection}
                            value={id === section.id ? subSection : ""}
                            onChange={(e) => changeHandler(e, section.id)}
                            onKeyPress={(e) =>
                              e.key === "Enter" && addSubSection(e, section)
                            }
                            placeholder="Enter new sub selection"
                            fullWidth
                            error={formErrors.subSectionError ? true : false}
                            helperText={
                              formErrors.subSectionError
                                ? formErrors.subSectionError
                                : " "
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
                <TableRow key={row.name}>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell component="th" scope="row" colSpan={2}>
                    <TextField
                      color="primary"
                      name="section"
                      size="small"
                      value={id === row.id ? section : ""}
                      onChange={(e) => changeHandler(e, row.id)}
                      onKeyPress={(e) =>
                        e.key === "Enter" && addSubSection(e, row)
                      }
                      placeholder="Enter new section"
                      fullWidth
                      error={formErrors.sectionError ? true : false}
                      helperText={
                        formErrors.sectionError ? formErrors.sectionError : " "
                      }
                    />
                  </TableCell>
                </TableRow>
              </>
            ))}
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell component="th" scope="row" colSpan={3}>
                <TextField
                  color="primary"
                  name="remark"
                  size="small"
                  value={id === "0" ? remark : ""}
                  onChange={(e) => changeHandler(e, "0")}
                  onKeyPress={(e) => e.key === "Enter" && addSubSection(e)}
                  placeholder="Enter new chapter"
                  fullWidth
                  error={formErrors.chapterError ? true : false}
                  helperText={
                    formErrors.chapterError ? formErrors.chapterError : " "
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div align="right">
        <ButtonCompo
          size="button"
          type="button"
          variant="outlined"
          name="Cancel"
          onClick={onCancelForm}
        />
        &nbsp;&nbsp;
        {/* <ButtonCompo
          size="button"
          type="button"
          variant="contained"
          name="Submit"
          onClick={(e) => addSubSection(e)}
        /> */}
      </div>
      {subjectEdit && (
        <>
          <EditSubjectSyllabus
            onCancel={onCancel}
            editList={editList}
            onSave={onSubmit}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  student: state.studentList,
  teachingPlanList: state.teachingPlan,
});

const mapDispatchToProps = {
  getAlert,
  getSyllabus,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTable);
