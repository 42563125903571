import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import SyllabusTable from "./SyllabusTable";
import DynamicForm from "../../components/dynamicscreens/DynamicFormWithTable";
import { FilterSave } from "../../components/FilterSave";
import { Loading1 } from "../../components/Loading1";
import { StudentShowJson } from "../../DynamicFormsJson/StudentShowSyllabus";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { getYearDetailByUserId } from "../Masters/OnlineModule/Class/class.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { getAdmissionList } from "../StudentFeesCollection/FeesCollection.slice";
import { getSubjectSchemeBySubjectId } from "../Masters/Admission/Subject Info/subjectInfo.slice";
import { getSyllabus } from "../TeachingPlan/teachingPlan.slice";
import { getSubjectBySemisterId } from "../SubjectSelection/SubjectSelection.slice";
class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      selection: [],
      showTable: false,
      showSearch: true,
      currentOperationMode: "",
      searchValue: "",
      showLoader: false,
      formErrors: {},
      fromDate: "",
      toDate: "",
      dynamicMasterData: {
        yearMaster: this.props.classList.yearDetailByUserId,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        subjectMaster: this.props.subjectSelection?.subject,
        schemeMaster: this.props.subjectList?.subjectById.subjectDetails
          ? this.props.subjectList?.subjectById.subjectDetails
          : [],
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.classList !== nextProps.classList) {
      if (
        this.props.classList.yearDetailByUserId !==
        nextProps.classList.yearDetailByUserId
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.classList.yearDetailByUserId,
          },
        });
      }
    }
    if (this.props.subjectList !== nextProps.subjectList) {
      if (
        this.props.subjectList.subjectById !== nextProps.subjectList.subjectById
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            schemeMaster: nextProps.subjectList.subjectById.subjectDetails
              ? nextProps.subjectList?.subjectById.subjectDetails
              : [],
          },
        });
      }
    }
    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
    if (this.props.subjectSelection !== nextProps.subjectSelection) {
      if (
        this.props.subjectSelection.subject !==
        nextProps.subjectSelection.subject
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.subjectSelection.subject,
          },
        });
      }
    }
  }

  componentDidMount() {
    const { getYearDetailByUserId, getAlert, resetReducList } = this.props;
    resetReducList();
    const yearDetailId = localStorage.getItem("yearDetailId");
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const parentChildId = localStorage.getItem("parentChildId");
    this.setState({
      showLoader: true,
    });
    getYearDetailByUserId({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    // if (yearDetailId && ay) {
    //   this.props
    //     .getSemesterDetailsByYearAndAcademic({
    //       yearId: yearDetailId,
    //       academicId: ay,
    //     })
    //     .then((response) => {
    //       if (!response) {
    //         this.props.getAlert({ message: "Something went wrong" });
    //       }
    //     });
    // }
    // if (semisterDetailId) {
    //   this.props
    //     .getSubjectBySemisterId({
    //       semesterId: semisterDetailId,
    //     })
    //     .then((response) => {
    //       if (!response) {
    //         this.props.getAlert({ message: "Something went wrong" });
    //       }
    //     });
    // }
    this.setState({
      showLoader: true,
    });

    const dataToSearch = {};

    // if (yearDetailId) {
    //   dataToSearch["yearDetail"] = yearDetailId;
    // }
    // if (semisterDetailId) {
    //   dataToSearch["semister"] = semisterDetailId;
    // }
    StudentShowJson.fieldMeta.map((currentField) => {
      if (currentField.defaultValue) {
        dataToSearch[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldData: dataToSearch,
    });
  }

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = {};
    let listError = false;
    StudentShowJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail && ay) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.semister) {
      this.props
        .getSubjectBySemisterId({
          semesterId: data.semister,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    } else if (data.subject) {
      this.props
        .getSubjectSchemeBySubjectId({
          subjectId: data.subject,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onFormSave = () => () => {
    const { fieldData } = this.state;
    const dataToSave = {};
    StudentShowJson.fieldMeta.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = year + "-" + month + "-" + day;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.searchData(dataToSave);
  };
  getFilteredTableData = () => {
    const { feesCollectionList } = this.props;
    const { searchValue } = this.state;
    const columsFil = this.columns.filter((item) => {
      return item.canSearch;
    });
    return feesCollectionList.admissionListStatus.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  searchData = (data) => {
    console.log(data);
    this.setState({
      showLoader: true,
    });
    this.props
      .getSyllabus({
        subjectDetailId: data.subjectDetail,
      })
      .then((resp) => {
        if (!resp) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({
            message: "Something went wrong",
          });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });

    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("divisionIds", "");
    FilterSave();
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "applicantName",
      title: "Applicant Name",
      positionCenter: false,
      showInscreen: true,
      minWidth: "40%",
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "studentMobile",
      title: "Mobile number",
      positionCenter: true,
      minWidth: "20%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "totalFees",
      title: "Total Fees",
      positionCenter: true,
      minWidth: "15%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "paidStatus",
      title: "Status",
      minWidth: "10%",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
  ];

  onSelectionChange = (e) => {
    this.setState({
      selection: e,
    });
  };
  submitHandler = () => {
    this.state.selection.length === 0
      ? swal("Please select atleast one student")
      : swal("Do you want to submit", {
          buttons: ["Cancel", true],
        }).then((value) => {
          if (value) {
            console.log(this.state.fieldData);
            console.log(this.state.selection);
          }
        });
  };
  render() {
    const { fieldData, selection, dynamicMasterData, showLoader, showTable } =
      this.state;

    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          screenTitle={StudentShowJson.screenTitle}
          showPdfDownload={StudentShowJson.showPdfDownload}
          // generatePDF={downLoadPdfFromApi ? pdfDownload : this.generatePDF}
          generatePDF={this.generatePDFDownload}
          showExcelDownload={StudentShowJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={StudentShowJson.showAddButton}
          showTable={true}
          showSearch={StudentShowJson.showSearch}
        />
        <br />
        {/* <Alert msg={"hi"} /> */}
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <DynamicForm
            fieldMeta={StudentShowJson.fieldMeta}
            dynamicMasterData={dynamicMasterData}
            fieldData={fieldData}
            formErrorsShows={this.state.formErrors}
            onDataChange={this.onDataChange}
            onSave={this.onFormSave()}
            buttonCenter={StudentShowJson.buttonCenter}
            searchButton={StudentShowJson.searchButton}
            searchAlign={StudentShowJson.searchAlign}
            marginTop={StudentShowJson.marginTop}
          />
          <>
            {" "}
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              sx={{ marginTop: 1 }}
              container
            >
              <SyllabusTable
                syllabus={
                  Object.keys(this.props.teachingPlanList?.syllabus).length !==
                  0
                    ? this.props.teachingPlanList?.syllabus.details
                    : []
                }
              />
            </Grid>
          </>
        </Paper>
        {showLoader && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  classList: state.class,
  feesCollectionList: state.feesCollection,
  rollNoData: state.rollNo,
  subjectList: state.subjectInfo,
  teachingPlanList: state.teachingPlan,
  subjectSelection: state.subjectSelection,
});
const mapDispatchToProps = {
  getYearDetailByUserId,
  getAdmissionList,
  getAlert,
  resetReducList,
  getSemesterDetailsByYearAndAcademic,
  showNotification,
  getSubjectSchemeBySubjectId,
  getSyllabus,
  getSubjectBySemisterId,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
