import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../../../components/Comman/IconButton";
import AluminiMentorTable from "./AluminiMentorTable";
const MentorUpdateStatusTable = ({
  rows,
  onSelectionChange,
  selection,
  alumnaeType,
  showAdd,
  rowAdd,
  isActionColActive,
  status,
  rowViewData,
  showView,
}) => {
  const AlumniMentorColumns = [
    {
      title: "Sr. No.",
      name: "index",
      alignCell: "center",
      align: "center",
      minWidth: 100,
    },

    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },


    
    {
      title: "Area of expertise",
      name: "expertise",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Time you can spend(Hours / Days)",
      name: "time",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Department you can mentor",
      name: "message",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
  ];

  const AlumniVolunteerColumns = [
    {
      title: "Sr. No.",
      name: "index",
      alignCell: "center",
      align: "center",
      minWidth: 100,
    },
    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },

    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },

    {
      title: "Volunteer For",
      name: "expertise",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Time you can contribute (In hours)",
      name: "time",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
  ];
  const AlumniAssociationColumns = [
    {
      title: "Sr. No.",
      name: "index",
      alignCell: "center",
      align: "center",
      minWidth: 100,
    },
    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },


    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Nominate for",
      name: "expertise",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Reason for Nomination",
      name: "message",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
  ];

  const AlumniDonorColumns = [
    {
      title: "Sr. No.",
      name: "index",
      canSearch: true,
      showInExcel: true,
      alignCell: "center",
      align: "center",
      minWidth: 100,
    },
    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },


    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Donor / Sponsor",
      name: "expertise",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },

    {
      title: "Type of Sponsorship",
      name: "interest",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Purpose",
      name: "message",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
  ];

  const AlumniPaperColumns = [
    {
      title: "Sr. No.",
      name: "index",
      minWidth: 100,
      alignCell: "center",
      align: "center",
    },
    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },

    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Role",
      name: "interest",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      title: "Area of expertise",
      name: "expertise",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      title: "Academic Experience (In years)",
      name: "message",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      title: "Industry Experience (In years)",
      name: "remark",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
  ];

  const AlumniResoursePersonColumns = [
    {
      title: "Sr. No.",
      name: "index",
      minWidth: 100,
      alignCell: "center",
      align: "center",
    },
    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },

    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Role",
      name: "interest",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      title: "Area of expertise",
      name: "expertise",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      title: "Time you can contribute (In hours)",
      name: "time",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
  ];

  const AlumniCollaborationColumns = [
    {
      title: "Sr. No.",
      name: "index",
      minWidth: 100,
      alignCell: "center",
      align: "center",
    },
    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },

    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Collaboration Type",
      name: "expertise",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
  ];

  const AlumniTeacherColumns = [
    {
      title: "Sr. No.",
      name: "index",
      minWidth: 100,
      alignCell: "center",
      align: "center",
    },
    {
      name: "firstName",
      title: "Alumnae Name",
      minWidth: 250,
      alignCell: "center",
      alignCenter: "center",
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },


    {
      title: "Contact",
      name: "contact",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Email",
      name: "email",
      alignCell: "center",
      alignCenter: "center",
      minWidth: 250,
    },
    {
      title: "Highest Academic Qualification",
      name: "expertise",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },

    {
      title: "UGC SET",
      name: "message",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      title: "UGC NET",
      name: "remark",
      minWidth: 100,
      alignCell: "center",
      alignCenter: "center",
    },
  ];
  const rowPrint = (row) => {};

  return (
    <AluminiMentorTable
      rows={rows}
      columns={
        status == 1
          ? alumnaeType === "0"
            ? [
                ...AlumniVolunteerColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
            : alumnaeType === "1"
            ? [
                ...AlumniMentorColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
            : alumnaeType === "2"
            ? [
                ...AlumniAssociationColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
            : alumnaeType === "3"
            ? [
                ...AlumniDonorColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
            : alumnaeType === "4"
            ? [
                ...AlumniPaperColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
            : alumnaeType === "5"
            ? [
                ...AlumniResoursePersonColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
            : alumnaeType === "7"
            ? [
                ...AlumniCollaborationColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
            : [
                ...AlumniTeacherColumns,
                {
                  name: "action",
                  title: "Action",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
          : alumnaeType === "0"
          ? AlumniVolunteerColumns
          : alumnaeType === "1"
          ? AlumniMentorColumns
          : alumnaeType === "2"
          ? AlumniAssociationColumns
          : alumnaeType === "3"
          ? AlumniDonorColumns
          : alumnaeType === "4"
          ? AlumniPaperColumns
          : alumnaeType === "5"
          ? AlumniResoursePersonColumns
          : alumnaeType === "7"
          ? AlumniCollaborationColumns
          : AlumniTeacherColumns
      }
      selection={selection}
      onSelectionChange={onSelectionChange}
      isActionColActive={isActionColActive}
      showView={showView}
      rowViewData={rowViewData}
      showAdd={showAdd}
      rowAdd={rowAdd}
    />
  );
};

const mapStateToProps = (state) => ({
  // documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MentorUpdateStatusTable);
