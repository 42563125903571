import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicPreview from "../../components/Preview/DynamicPreview";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, mr: 5, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function InternshipStudentMarks({
  allocationList,
  handleCloses,
  marksContain,
}) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    handleCloses();
  };
  const title = [
    [
      {
        name: "externalMarks",
        title: "External Marks",
        colSpan: 0,
      },
      {
        name: "outOfExternal",
        title: "Out of External",
        colSpan: 0,
      },
    ],
    [
      {
        name: "internalMarks",
        title: "Internal Marks",
        colSpan: 0,
      },
      {
        name: "outOfInternal",
        title: "Out of Internal",
        colSpan: 0,
      },
    ],
  ];
  const columns = [
    { name: "externalMarks", title: "externalMarks" },
    { name: "outOfExternal", title: "outOfExternal" },
    { name: "internalMarks", title: "internalMarks" },
    { name: "outOfInternal", title: "outOfInternal" },
  ];
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Viva Marks
          </BootstrapDialogTitle>

          <DynamicPreview
            data={allocationList?.StudentVivaMarksById}
            title={title}
          />

          <DialogActions>
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            ></Grid>
          </DialogActions>
        </BootstrapDialog>
      </Paper>
    </>
  );
}
const mapStateToProps = (state) => ({
  allocationList: state.allocationList,
});
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternshipStudentMarks);
