import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  documentVerification: [],
  isFetchDocumentVerification: false,

  roundDetails: [],
  isFetchRoundDetails: false,

  programTitle: [],
  isFetchProgramTitle: false,

  documentVerificationDataList: [],
  isFetchDocumentVerificationDataList: false,

  documentVerificationUpdated: [],
  isFetchdocumentVerificationUpdated: false,
};
//application/v1/application-declare
let URL = endpoints.documentVerification;
let URL2 = endpoints.documentVerificationList;
let URL3 = endpoints.documentVerificationUpdate;

const documentVerificationSlice = createSlice( {
  name: "documentVerification",
  initialState,
  reducers: {
    documentVerificationListSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        documentVerification: row,
        isFetchDocumentVerification: true,
      };
    },
    documentVerificationListFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        documentVerification: [],
        isFetchDocumentVerification: false,
      };
    },
    roundDetailsListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        roundDetails: row,
        isFetchRoundDetails: true,
      };
    },
    roundDetailsListFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        roundDetails: [],
        isFetchRoundDetails: false,
      };
    },
    programTitleListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        programTitle: row,
        isFetchProgramTitle: true,
      };
    },
    programTitleListFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        programTitle: [],
        isFetchProgramTitle: false,
      };
    },
    documentVerificationDataListSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        documentVerificationDataList: row,
        isFetchdocumentVerificationDataList: true,
      };
    },
    documentVerificationDataListFail: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      return {
        ...state,
        documentVerificationDataList: [],
        isFetchDocumentVerificationDataList: false,
      };
    },
    documentVerificationUpdatedSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        documentVerificationUpdated: row,
        isFetchdocumentVerificationUpdated: true,
      };
    },
    documentVerificationUpdatedFail: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      return {
        ...state,
        documentVerificationUpdated: [],
        isFetchdocumentVerificationUpdated: false,
      };
    },

    resetSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...initialState,
      };
    },
  },
} );

export const {
  resetSuccess,
  documentVerificationListSuccess,
  documentVerificationListFail,
  roundDetailsListSuccess,
  roundDetailsListFail,
  programTitleListSuccess,
  documentVerificationDataListSuccess,
  documentVerificationUpdatedSuccess,
} = documentVerificationSlice.actions;

export default documentVerificationSlice.reducer;

export const getDocumentVerification = () => async ( dispatch ) =>
{
  try
  {
    const success = apiGet( {
      url: URL,
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.title,
            isActive: data1.isActive,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( documentVerificationListSuccess( { row } ) );
      }
      return success
    } );
    return success
  } catch ( e )
  {
    return console.error( e.message );
  }
};

export const getRoundDetails =
  ( { roundId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        ///{id}/application-round
        const response = apiGet( {
          url: URL + "/" + roundId + "/application-round",
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            const roundDetailsList = data.data;
            let index = 0;
            let row = [];
            roundDetailsList.map( ( data1 ) =>
            {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                name: data1.name,
              };
              row.push( bData );
              return data1;
            } );
            dispatch( roundDetailsListSuccess( { row } ) );
          }
          return success
        } );
        return response
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const getProgramTitle =
  ( { roundId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        ///{applicationDeclareId}/distinct-program-title-by-applicationid-and-instituteid
        const response = apiGet( {
          url:
            URL +
            "/" +
            roundId +
            "/distinct-program-title-by-applicationid-and-instituteid",
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            const programtitleList = data.data;
            let index = 0;
            let row = [];
            programtitleList.map( ( data1 ) =>
            {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                name: data1.brName,
              };
              row.push( bData );
              return data1;
            } );
            dispatch( programTitleListSuccess( { row } ) );
          }
          return success
        } );
        return response
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

// "/distinct-program-title-by-applicationid-and-program-typeid?id=" +
// appliId +
// "&progTypeId=" +
// progTypeId,

// "To get Allocated Students Send parameters
// applicationDeclareId
// applicationRoundId
// programTitleId
// acceptanceStatus"

///get-list-of-allocated-student

export const getDocumentVerificationDataList =
  ( { appId, roundId, progId, acceptId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url:
            URL2 +
            "/get-list-of-allocated-student?applicationDeclareId=" +
            appId +
            "&applicationRoundId=" +
            roundId +
            "&programTitleId=" +
            progId +
            "&acceptanceStatus=" +
            acceptId,
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            const programtitleList = data.data;
            let index = 0;
            let row = [];
            programtitleList.map( ( data1 ) =>
            {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                applicationNumber: data1.applicationNumber,
                userRoundPrefrence: data1.userRoundPrefrence.id,
                remark: data1.remark,
                userApplicationId: data1.userApplicationId,
                studentName:
                data1.lastName + " " + data1.firstName + " " + data1.fatherName + " " + data1.motherName,
              
                lastName: data1.lastName,
                firstName:   data1.firstName ,
                fatherName: data1.fatherName,
                motherName:   data1.motherName ,
                
              
                branch: data1.programTitle.brName,
                phno: data1.studentMobile,
                status:
                  data1.acceptanceStatus === 0
                    ? "Waiting"
                    : data1.acceptanceStatus === 1
                      ? "Allocated"
                      : data1.acceptanceStatus === 2
                        ? "Confirm Admission"
                        : data1.acceptanceStatus === 3
                          ? "Rejected Admission"
                          : data1.acceptanceStatus === 4
                            ? "Cancelled Admission"
                            : data1.acceptanceStatus === 5
                              ? "Cancelled Waiting List"
                              : data1.acceptanceStatus === 6
                                ? "System Cancelled (institute_status)"
                                : "",
              };
              row.push( bData );
              return data1;
            } );
            dispatch( documentVerificationDataListSuccess( { row } ) );
            return row;
          }
          return success
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const getDocumentVerificationUpdated =
  ( { appId, status } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url: URL3 + "?ids=" + appId + "&status=" + status,
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            const updatedList = data.data;
            // let index = 0;
            let row = [];
            //  programtitleList.map((data1) => {
            //     index = index + 1
            //     let bData = {
            //       index: index,
            //       id: data1.id,
            //       studentName: data1.firstName+" "+data1.middleName+" "+data1.lastName,
            //       branch: data1.programTitle.brName,
            //       status: data1.applicantStatus === 0 ? "waiting" :
            //       data1.applicantStatus  === 1 ? "allocated" :
            //       data1.applicantStatus  === 2 ? "confirm admission" :
            //       data1.applicantStatus === 3 ? "rejected admission" :
            //       data1.applicantStatus === 4 ? "cancelled admission" :
            //       data1.applicantStatus === 5 ? "cancelled waiting list" :
            //       data1.applicantStatus === 6 ? "system cancelled (institute_status)":"",
            //       phno: data1.studentMobile
            //     };
            //     row.push(bData);
            //     return data1;
            //   });
            dispatch( documentVerificationUpdatedSuccess( { row } ) );
            return row;
          }
          return null
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const resetValues = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
