import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  studentFeedbackDetails: [],
  isFetchStudentFeedbackDetails: false,

  studentFeedbackQuestions: [],
  isFetchStudentFeedbackQuestions: false,

  studentFeedbackTeacher: [],
  isFetchStudentFeedbackTeacher: false,

  studentFeedbackTheyApplied: [],
  isFetchStudentFeedbackTheyApplied: false,

  studentFeedbackSubject: [],
  isFetchStudentFeedbackSubject: false,

  studentFeedbackApply: [],
  isFetchStudentFeedbackApply: false,
};
let URL = endpoints.studentFeedbackDetails;
let URL1 = endpoints.studentFeedbackHeadDetails;
let URL2 = endpoints.studentFeedbackTransaction;

const studentFeedbackDetailsSlice = createSlice({
  name: "studentFeedbackDetails",
  initialState,
  reducers: {
    studentFeedbackDetailsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentFeedbackDetails: row,
        isFetchStudentFeedbackDetails: true,
      };
    },

    studentFeedbackApplySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentFeedbackApply: row,
        isFetchStudentFeedbackApply: true,
      };
    },

    studentFeedbackTheyAppliedSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentFeedbackTheyApplied: row,
        isFetchStudentFeedbackTheyApplied: true,
      };
    },

    studentFeedbackQuestionsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentFeedbackQuestions: row,
        isFetchStudentFeedbackQuestions: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },

    studentFeedbackTeacherSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentFeedbackTeacher: row,
        isFetchStudentFeedbackTeacher: true,
      };
    },
    studentFeedbackSubjectSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentFeedbackSubject: row,
        isFetchStudentFeedbackSubject: true,
      };
    },
  },
});

export const {
  studentFeedbackDetailsSuccess,
  studentFeedbackQuestionsSuccess,
  studentFeedbackTeacherSuccess,
  studentFeedbackSubjectSuccess,
  studentFeedbackTheyAppliedSuccess,
  studentFeedbackApplySuccess,
  resetSuccess,
} = studentFeedbackDetailsSlice.actions;

export default studentFeedbackDetailsSlice.reducer;

export const getStudentFeedbackDetails =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-student-feedback?userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              feedbackHead: data1.feedbackHead.name,
              feedbackHeadId: data1.feedbackHead.id,
              isSubject: data1.isSubject,
              feedbackDetails: data1.feedbackDetails,
              // venue: data1.venue,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              // applicationLastDate: data1.applicationLastDate,
              // time: data1.time,
              // amount: data1.amount,
              // amountApplicable: data1.amountApplicable,
              // isActive: data1.isActive === 1 ? "Active" : "Inactive",
              // fromTo: data1.fromDate + " -" + data1.toDate,
              // yearDetailIds: data1.yearDetailIds,
              // documentUpload: data1.documentUpload,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentFeedbackDetailsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//this API is used for get list of feedback with and without subject both
//this API is also used to get list of feedback declare aginst an event apply by that perticular student
export const getStudentApplyFeedback =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-for-apply?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              feedbackHead:
                data1.feedbackHead === null ? "" : data1.feedbackHead.name,
              feedbackHeadId:
                data1.feedbackHead === null ? "" : data1.feedbackHead.id,
              isSubject: data1.isSubject,
              feedbackDetails: data1.feedbackDetails,
              // venue: data1.venue,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              academicYearId: data1.academicYearId,
              deptAuthorityToView: data1.deptAuthorityToView,
              divisionIds: data1.divisionIds,
              empAuthorityToView: data1.empAuthorityToView,
              eventId: data1.eventId,
              semisterDetailId:
                data1.semisterDetail === null ? "" : data1.semisterDetail.id,
              // applicationLastDate: data1.applicationLastDate,
              // time: data1.time,
              // amount: data1.amount,
              // amountApplicable: data1.amountApplicable,
              // isActive: data1.isActive === 1 ? "Active" : "Inactive",
              fromTo: data1.fromDate + " -" + data1.toDate,
              // yearDetailIds: data1.yearDetailIds,
              // documentUpload: data1.documentUpload,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentFeedbackApplySuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentFeedbackByEventId =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL2 + "/" + id + "/by-event-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          console.log(content);
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              feedbackHead: data1.studentFeedback.feedbackHead.name,
              details: data1.studentFeedback.feedbackDetails,
              studentName:
                data1.studentRegistration === null
                  ? ""
                  : data1.studentRegistration.lastName +
                    " " +
                    data1.studentRegistration.firstName +
                    " " +
                    (data1.studentRegistration.fatherName == null ||
                    data1.studentRegistration.fatherName == ""
                      ? " - "
                      : data1.studentRegistration.fatherName) +
                    " " +
                    (data1.studentRegistration.motherName == null ||
                    data1.studentRegistration.motherName == ""
                      ? " - "
                      : data1.studentRegistration.motherName),
              feedbackHeadId: data1.studentFeedback.feedbackHead.id,
              // isSubject: data1.isSubject
              // venue: data1.venue,
              // fromDate: data1.fromDate,
              // toDate: data1.toDate,
              // applicationLastDate: data1.applicationLastDate,
              // time: data1.time,
              // amount: data1.amount,
              // amountApplicable: data1.amountApplicable,
              // isActive: data1.isActive === 1 ? "Active" : "Inactive",
              // fromTo: data1.fromDate + " -" + data1.toDate,
              // yearDetailIds: data1.yearDetailIds,
              // documentUpload: data1.documentUpload,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentFeedbackTheyAppliedSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentFeedbackTheyApplied =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/view-response?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            const division =
              data1.divisions !== null
                ? data1.divisions.map((divisionData) => {
                    return divisionData.name;
                  })
                : [];
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              feedbackHead: data1.feedbackHead.name,
              feedbackHeadId: data1.feedbackHead.id,
              feedbackDetails: data1.feedbackDetails,
              yearDetailName:
                data1.divisions !== null &&
                data1.divisions.length !== 0 &&
                data1.divisions[0].getYearDetailId.year.programType.name +
                  " - " +
                  data1.divisions[0].getYearDetailId.programTitle.brName +
                  " - " +
                  data1.divisions[0].getYearDetailId.year.className,
              division: division.join(),
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              isSubject: data1.isSubject,
              isSubjectData: data1.isSubject == 1 ? "Yes" : "No",
              semisterDetail: data1.semisterDetail.id,
              divisionIds: data1.divisionIds,
              deptAuthorityToView: data1.deptAuthorityToView,
              empAuthorityToView: data1.empAuthorityToView,
              yearDetail: data1.semisterDetail.semister.yearDetailId,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentFeedbackDetailsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// /v1/feedback-head/dfda7965-6ad4-413d-b77f-ef64f39a662d/get-feedback-head-details

export const getStudentFeedbackQuestions =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/get-feedback-head-details",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              question: data1.question,
              optionType: data1.optionType,
              answer: "",
              answerValid: 0,
              isActive: data1.isActive,
              feedbackHeadDetailsOptions: data1.feedbackHeadDetailsOptions,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentFeedbackQuestionsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentFeedbackSubject =
  ({ teacherId, studentFeedbackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/get-subject?teacherId=" +
          teacherId +
          "&studentFeedbackId=" +
          studentFeedbackId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,

              id: data1.subject.id,
              subjectDetail: data1.subjectDetail.id,
              name:
                data1.subject.name +
                " - " +
                data1.subjectDetail.schemeHeadId.shortName,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentFeedbackSubjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getStudentFeedbackTeacher = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2 + "/get-teacher",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,

            id: data1.userRegistration.id,
            name:
              data1.userRegistration.lastName +
              " " +
              data1.userRegistration.firstName +
              " " +
              (data1.userRegistration.fatherName == null ||
              data1.userRegistration.fatherName == ""
                ? " - "
                : data1.userRegistration.fatherName) +
              " " +
              (data1.userRegistration.motherName == null ||
              data1.userRegistration.motherName == ""
                ? " - "
                : data1.userRegistration.motherName),
          };
          row.push(bData);
          return data1;
        });
        dispatch(studentFeedbackTeacherSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getFeedbackWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/view-response-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          const division =
            data1.divisions !== null
              ? data1.divisions.map((divisionData) => {
                  return divisionData.name;
                })
              : [];
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            feedbackHead: data1.feedbackHead.name,
            feedbackHeadId: data1.feedbackHead.id,
            feedbackDetails: data1.feedbackDetails,
            yearDetailName:
              data1.divisions !== null &&
              data1.divisions.length !== 0 &&
              data1.divisions[0].getYearDetailId.year.programType.name +
                " - " +
                data1.divisions[0].getYearDetailId.programTitle.brName +
                " - " +
                data1.divisions[0].getYearDetailId.year.className,
            division: division.join(),
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            isSubject: data1.isSubject,
            isSubjectData: data1.isSubject == 1 ? "Yes" : "No",
            semisterDetail: data1.semisterDetail.id,
            divisionIds: data1.divisionIds,
            deptAuthorityToView: data1.deptAuthorityToView,
            empAuthorityToView: data1.empAuthorityToView,
            yearDetail: data1.semisterDetail.semister.yearDetailId,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(studentFeedbackDetailsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
