import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
const initialState = {
  verificationStatus: [],
  isFetchStatus: false,
};

let URL = endpoints.documentVerificationList;

const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    verificationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        verificationStatus: row,
        isFetchStatus: true,
      };
    },
    verificationFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        documentVerificationDataList: [],
        isFetchDocumentVerificationDataList: false,
      };
    },
  },
});

export const { verificationSuccess } = verificationSlice.actions;

export default verificationSlice.reducer;

export const getStatus =
  ({ id, status, remark }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
     const response= apiGet({
        url:
          URL +
          "/update-document-verification-status?id=" +
          id +
          "&verifyDocumentStatus=" +
          status +
          "&remark=" +
          remark,
      }).then(({ data, success }) => {
        if (success) {  
          const programtitleList = data.data;
          // let index = 0;
          let row = [];
          dispatch(verificationSuccess({ row }));
        }
        return success
      });
      return response
    } catch (e) {
      return console.error(e.message);
    }
  };
