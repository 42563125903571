import CloseIcon from "@mui/icons-material/Close";
import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import * as myConstClass from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
import AttachFile from "../../components/Comman/AttachFile";
const BootstrapDialog = styled( Dialog )( ( { theme } ) => ( {
  "& .MuiDialogContent-root": {
    padding: theme.spacing( 2 ),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing( 1 ),
  },
} ) );

const BootstrapDialogTitle = ( props ) =>
{
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: ( theme ) => theme.palette.grey[ 500 ],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ListOfCertificatePopUp( {
  handleCloses,
  onClose,
  onSave,
} )
{
  const [ open, setOpen ] = React.useState( true );
  const [ details, setDetails ] = React.useState( "" );
  const [ formErrors, setFormErrors ] = React.useState( {} );
  const [ remark, setRemark ] = React.useState( "" );
  const [ fileName, setFileName ] = React.useState( "" );
  const checkValidation = () =>
  {
    let formErrors = {};
    let formIsValid = true;

    if ( details.toString().trim() === "" || details === null )
    {
      formIsValid = false;
      formErrors[ "detailsError" ] = myConstClass.detailsMsg;
    }

    if ( remark.toString().trim() === "" || remark === null )
    {
      formIsValid = false;
      formErrors[ "remarError" ] = myConstClass.remarkMsg;
    }

    // if ( fileName == "" || fileName == null )
    // {
    //   formIsValid = false;
    //   formErrors[ "uploadError" ] = "*Upload File";
    // }

    setFormErrors( formErrors );
    return formIsValid;
  };

  const handleClose = () =>
  {
    handleCloses();
  };
  const changedHandler = ( event ) =>
  {
    const { name, value } = event.target;
    if ( name == "details" )
    {
      setDetails( value );
    }
    if ( name == "remark" )
    {
      setRemark( value );
    }



  };

  const submitHandler = () =>
  {
    let bData = {
      details: details,
      userRemark: remark,
      file: fileName
    };

    if ( checkValidation() )
    {
      onSave( bData );
    }
  };
  const changeH = ( fileName, name, index ) =>
  {
    setFileName( fileName );
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          {" "}
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Enter Details
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="details"
                      size="small"
                      value={details}
                      onChange={changedHandler}
                      fullWidth
                      isError={formErrors.detailsError ? true : false}
                      errorText={
                        formErrors.detailsError ? formErrors.detailsError : " "
                      }
                    />

                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="To"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="remark"
                      size="small"
                      value={remark}
                      onChange={changedHandler}
                      fullWidth
                      isError={formErrors.remarError ? true : false}
                      errorText={
                        formErrors.remarError ? formErrors.remarError : " "
                      }
                    />
                  </Grid>
                              </Grid>
                              


                              <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="To"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="remark"
                      size="small"
                      value={remark}
                      onChange={changedHandler}
                      fullWidth
                      isError={formErrors.remarError ? true : false}
                      errorText={
                        formErrors.remarError ? formErrors.remarError : " "
                      }
                    />
                  </Grid>
                </Grid>
                

            

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  spacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="center"
                  >
                    <ButtonCompo
                      size="medium"
                      type="Submit"
                      variant="contained"
                      name="Submit"
                      onClick={submitHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </Paper>
    </>
  );
}
