// export function CommonNameFormat({
//   type,
//   firstName = "",
//   middleName = "",
//   lastName = "",
//   fatherName = "",
//   motherName = "",
// }) {
//   let name = localStorage.getItem("nameFormatData");
//   console.log(JSON.parse(name));
//   JSON.parse(name).filter((data) => data.type == type);
//   if (type == 0) {
//   }
//   return firstName;
// }

export const CommonNameFormat = (type, keyName, row) => {
  let name = localStorage.getItem("nameFormatData");

  let nameFormatData = JSON.parse(name).filter((data) => data.type == type && data.isActive == 1);
  // console.log(keyName);
  // console.log(nameFormatData)
  // console.log(row)
  let nameToSet = ""
  nameFormatData.map((nameData) => {
    
    const filterNameData = keyName.split(",").filter((key) => key == nameData.name)
    if (filterNameData.length != 0) {
      let nameToAdd=row[filterNameData[0]]!=null?row[filterNameData[0]]:" - "
      nameToSet = nameToSet + " " + nameToAdd
    }
  })
  return nameToSet;
}