import { Grid, Paper } from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import {
  getStudentAttendanceByDate,
  getStudentAttendanceByDateFilter,
} from "./studentAttendance.slice";
import SearchExternalPlacedStudList from "../../../components/SearchTable";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { studentAttendanceOwnJson } from "../../../DynamicFormsJson/StudentAttendanceOwnDynamic";
class OwnAttendanceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      formErrors: [],
      toDate: "",
      searchValue: "",
      showLoader: false,
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getStudentAttendanceByDateFilter({
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "date",
      title: "Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
      canSearch: true,
    },
    {
      name: "subject",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "30%",
      canSearch: true,
    },
    {
      name: "firstName",
      title: "Teacher",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "30%",
      canSearch: true,
      keyName:"firstName,lastName,middleName",
      isFormat:true,
      type:1
    },
    {
      name: "present",
      title: "Present/Absent",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
  ];

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onSearchData = (dataToSearch) => {
    const parentChildId = localStorage.getItem("parentChildId");
    this.setState({
      fromDate: dataToSearch.fromDate,
      toDate: dataToSearch.toDate,
      showLoader: true,
    });
    this.props
      .getStudentAttendanceByDate({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studentAttendanceList?.studentsAttendanceByDate.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["date"] &&
            currentRow["date"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["subject"] &&
            currentRow["subject"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["present"] &&
            currentRow["present"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["teacher"] &&
            currentRow["teacher"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const { employeeDetails, searchButton } = this.props;
    const { fromDate, toDate } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;

    return (
      <>
        <DynamicMainScreen
          onAddButtonClick={this.onAddButtonClick}
          screenTitle={studentAttendanceOwnJson.screenTitle}
          fieldMeta={studentAttendanceOwnJson.fieldMeta}
          buttonCenter={studentAttendanceOwnJson.buttonCenter}
          showPdfDownload={studentAttendanceOwnJson.showPdfDownload}
          showExcelDownload={studentAttendanceOwnJson.showExcelDownload}
          // pdfFileName={studentAttendanceOwnJson.pdfFileName}
          // excelFileName={studentAttendanceOwnJson.excelFileName}
          tableColumnsToFilter={studentAttendanceOwnJson.tableColumnsToFilter}
          showAddButton={studentAttendanceOwnJson.showAddButton}
          dynamicMasterData={[]}
          tableColumns={this.columns}
          isActionColActive={false}
          tableData={this.props.studentAttendanceList?.studentsAttendanceByDate}
          // getListById={this.getListById}
          showPegination={true}
          showHeadEdit={false}
          showHeadDelete={false}
          showAppliedList={false}
          // showPegination={false}
          // pdfDetailsId={this.pdfDetails}
          // rowViewData={this.onClickView}
          // showLinkIcon={true}
          generateDetails={this.generateDetails}
          rowDelete={this.rowDelete}
          rowEdit={this.rowEdit}
          rowStatus={this.onChangeStatus}
          rowAppliedList={this.onClickApplied}
          // onDelete={this.onDelete}
          showLoader={this.state.showLoader}
          onSearchData={this.onSearchData}
          baseIdColumn={studentAttendanceOwnJson.baseIdColumn}
          apiBaseURL={studentAttendanceOwnJson.apiBaseURL}
        />

        {/* <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <>
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid sx={{ marginBottom: 3 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Viewing Own Attendance Details"
                />
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={5.5}
                  lg={5.5}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*From Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="fromDate"
                      size="small"
                      value={fromDate}
                      fullWidth
                      onChange={this.onDateChange( "fromDate" )}
                      isError={fromDateError ? true : false}
                      errorText={fromDateError ? fromDateError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={5.5}
                  lg={5.5}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*To Date  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="toDate"
                      size="small"
                      value={toDate}
                      fullWidth
                      onChange={this.onDateChange( "toDate" )}
                      isError={toDateError ? true : false}
                      errorText={toDateError ? toDateError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={12} md={12}></Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Search"
                      onClick={this.submitHandler}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{ marginTop: 1, marginBottom: 2 }}
              item
              xs={12}
              sm={12}
              md={12}
            >
              <SearchExternalPlacedStudList onSearch={this.onSearch} />
            </Grid>
            <Grid item sx={{ marginTop: 1 }}>
              <DynamicTable
                data={this.getFilteredTableData()}
                tableHead={this.columns}
                showPegination={false}
                isActionColActive={false}
              />
            </Grid>
          </>
        </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getStudentAttendanceByDate,
  getStudentAttendanceByDateFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnAttendanceDetails);
