import { Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import DynamicTable from "../../../../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../../../../config/endpoints";
import { StudentNoticeJson } from "../../../../../DynamicFormsJson/StudentNotice";
import { showNotification } from "../../../../Landing/Landing.slice";
import { apiDelete } from "../../../../../utils/api_service";
import SearchExternalPlacedStudList from "../../../../../components/SearchTable";
import {
  getAllEmployees,
  getEmployeeById,
} from "../../../../EmployeeDetails/employee.slice";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getAcademicYear } from "../../../OnlineModule/AcademicYear/academicYear.slice";
import AddStudentNotice from "./AddStudentNotice";
import * as myConstClass from "../../../../../config/messageconstant";
import { resetReducList } from "../../../../../CommonActions/User.action";
import * as msgConstant from "../../../../../config/messageconstant";
import { apiGet, apiPost } from "../../../../../utils/api_service";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicTable";
import { ViewStudentNoticeDynamicTeacherJson } from "../../../../../DynamicFormsJson/ViewStudentNoticeDynamic";

import {
  getStudentNotice,
  getStudentNoticeFromDtToDt,
  getStudentNoticeWithoutDate,
} from "./studentNotice.slice";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "10%",
  },
  {
    name: "title",
    title: "Title",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
    width: "20%",
    canSearch: true,
  },
  // {
  //   name: "yearDetail",
  //   title: "Year Details",
  //   positionCenter: false,
  //   showInscreen: true,
  //   alignCenter: "left",
  //   width:"20%"
  // },
  {
    name: "fromDate",
    title: "From Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
    canSearch: true,
  },
  {
    name: "toDate",
    title: "To Date",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
    canSearch: true,
    width: "20%",
  },
  {
    name: "isActive",
    title: "Status",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: "20%",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
    width: "10%",
  },
];

class ViewStudentNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      showAddCommitteeForm: false,
      showAppliedStudentsForm: false,
      showCommittee: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      department: "",
      academicYearId: "",
      fromDate: "",
      toDate: "",
      departmentList: [],
      editData: {},
      committeeData: {},
      eventDataForAppliedStudents: {},
      formErrors: [],
      showLoader: false,
    };
  }

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onChangeStatus = (rowData) => {
    const { fromDate, toDate } = this.state;
    let frmDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.studentNotice +
            "/update-status?studentNoticeId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
            this.props.getStudentNoticeWithoutDate().then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  onSearchData = (dataToSearch) => {
    this.setState({
      fromDate: dataToSearch.fromDate,
      toDate: dataToSearch.toDate,
      showLoader: true,
    });
    const { getAlert } = this.props;
    this.props
      .getStudentNoticeFromDtToDt({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  toggleFormTableVisibility = () => {
    const { showForm, showTable, showAddCommitteeForm } = this.state;
    this.setState({
      showForm: true,
      showAddCommitteeForm: false,
      showTable: false,
      openSnackbar: true,
      editData: {},
    });
  };

  backToForm = () => {
    // this.props.getStudentNoticeWithoutDate().then( ( response ) =>
    // {
    //   if ( !response )
    //   {
    //     this.setState( {
    //       showLoader: false
    //     } )
    //     this.props.getAlert( { message: "Something went wrong" } );
    //   } else
    //   {
    //     this.setState( {
    //       showLoader: false
    //     } )
    //   }
    // } );

    this.setState({
      showForm: false,
      showAddCommitteeForm: false,
      showTable: true,
      showAppliedStudentsForm: false,
      openSnackbar: true,
      fromDate: "",
      toDate: "",
    });
  };

  backTo = () => {
    this.setState({
      showTable: true,
    });
  };

  onFormCancel = () => {
    this.setState({
      showForm: true,
      showAddCommitteeForm: false,
      showTable: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departments !== nextProps.departments) {
      if (
        this.props.departments.department !== nextProps.departments.department
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.departments.department,
          { id: "0", name: "All" },
        ];
        this.setState({
          departmentList: departmentData,
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    // const { fieldMeta } = this.props;
    // fieldMeta.map((currentField) => {
    //   if (currentField.defaultValue) {
    //     fieldData[currentField.dataKey] = currentField.defaultValue;
    //   }
    // });

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowEdit = (data) => {
    this.setState({
      showAddCommitteeForm: false,
      showTable: false,
      showForm: true,
      editData: data,
    });
  };

  componentDidMount() {
    this.props.resetReducList();
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.setState({
      showLoader: true,
    });

    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getStudentNotice({
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });

    this.props.getStudentNoticeWithoutDate().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onDelete = (id) => {
    const { fromDate, toDate } = this.state;
    let frmDt = moment(fromDate).format("YYYY-MM-DD");
    let toDt = moment(toDate).format("YYYY-MM-DD");
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.studentNotice + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });

        this.props.getStudentNoticeWithoutDate().then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studNoticeList?.studentByDateNotice.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["title"] &&
            currentRow["title"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["fromDate"] &&
            currentRow["fromDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["toDate"] &&
            currentRow["toDate"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = false,
      showAddButton = true,
      departments,
      employeeDetails,
      academicYear,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      showAddCommitteeForm,
      department,
      departmentList,
      showAppliedStudentsForm,
      academicYearId,
      showCommittee,
      fromDate,
      toDate,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    return (
      <>
        {/* <Paper sx={{ p: 3, pt: 3, borderRadius: 2 }} elevation={0}> */}
        {/* <NewLandingScreenHeader
            screenTitle={StudentNoticeJson.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={showAddButton}
            onAddButtonClick={this.onAddButtonClick}
            showTable={showTable}
          /> */}
        {showForm && (
          <>
            <AddStudentNotice
              onCancel={this.backToForm}
              editData={this.state.editData}
            />
          </>
        )}

        {showTable && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={ViewStudentNoticeDynamicTeacherJson.screenTitle}
              fieldMeta={ViewStudentNoticeDynamicTeacherJson.fieldMeta}
              buttonCenter={ViewStudentNoticeDynamicTeacherJson.buttonCenter}
              showPdfDownload={
                ViewStudentNoticeDynamicTeacherJson.showPdfDownload
              }
              showExcelDownload={
                ViewStudentNoticeDynamicTeacherJson.showExcelDownload
              }
              // pdfFileName={ViewStudentNoticeDynamicTeacherJson.pdfFileName}
              // excelFileName={ViewStudentNoticeDynamicTeacherJson.excelFileName}
              tableColumnsToFilter={
                ViewStudentNoticeDynamicTeacherJson.tableColumnsToFilter
              }
              showAddButton={ViewStudentNoticeDynamicTeacherJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={columns}
              isActionColActive={true}
              tableData={this.props.studNoticeList?.studentByDateNotice}
              // getListById={this.getListById}
              showPegination={true}
              showHeadEdit={true}
              showHeadDelete={true}
              // showPegination={false}
              // pdfDetailsId={this.pdfDetails}
              // rowViewData={this.onClickView}
              rowDelete={this.rowDelete}
              rowEdit={this.rowEdit}
              rowStatus={this.onChangeStatus}
              showLoader={this.state.showLoader}
              // onDelete={this.onDelete}
              onSearchData={this.onSearchData}
              baseIdColumn={ViewStudentNoticeDynamicTeacherJson.baseIdColumn}
              apiBaseURL={ViewStudentNoticeDynamicTeacherJson.apiBaseURL}
            />

            {/* <Grid
                sx={{ borderRadius: 2 }}
                container
                justifyContent="space-evenly"
                direction="row"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    xs={12}
                    sm={5.5}
                    md={5.5}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*From Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.onDateChange( "fromDate" )}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5.5}
                    md={5.5}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*To Date "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.onDateChange( "toDate" )}
                        isError={toDateError ? true : false}
                        errorText={toDateError ? toDateError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    md={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{ marginTop: 1, marginBottom: 2 }}
                item
                xs={12}
                sm={12}
                md={12}
              >
                <SearchExternalPlacedStudList onSearch={this.onSearch} />
              </Grid>
              <Grid item>
                <DynamicTable
                  data={this.getFilteredTableData()}
                  tableHead={columns}
                  showHeadDelete={true}
                  showHeadEdit={true}
                  showPegination={false}
                  rowDelete={this.rowDelete}
                  rowEdit={this.OnClickEdit}
                  onCancel={this.backToForm}
                  rowStatus={this.onChangeStatus}
                />
              </Grid> */}
          </>
        )}
        {/* </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYear: state.academicYear,
  departments: state.department,
  employeeDetails: state.employeeList,
  eventList: state.event,
  studNoticeList: state.studNotice,
});
const mapDispatchToProps = {
  showNotification,
  getStudentNotice,
  getAlert,
  getDepartment,
  getAllEmployees,
  getEmployeeById,
  getAcademicYear,
  getStudentNoticeFromDtToDt,
  resetReducList,
  getStudentNoticeWithoutDate,
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewStudentNotice);

//

// import { Grid, Paper } from "@mui/material";
// import moment from "moment";
// import React, { Component } from "react";
// import { connect } from "react-redux";
// import swal from "sweetalert";
// import { getAlert } from "../../../../../CommonActions/alert.slice";
// import { ButtonCompo } from "../../../../../components/Comman/Button";
// import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
// import { LabelCompo } from "../../../../../components/Comman/Label";
// import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
// import DynamicTable from "../../../../../components/Comman/RT/MaterialUIRTTable";
// import endpoint from "../../../../../config/endpoints";
// import { StudentNoticeJson } from "../../../../../DynamicFormsJson/StudentNotice";
// import { showNotification } from "../../../../Landing/Landing.slice";
// import { apiDelete } from "../../../../../utils/api_service";
// import SearchExternalPlacedStudList from "../../../../../components/SearchTable"
// import
// {
//   getAllEmployees,
//   getEmployeeByDeptId,
//   getEmployeeById,
// } from "../../../../EmployeeDetails/employee.slice";
// import { getDepartment } from "../../../Internship/Department/department.slice";
// import { getAcademicYear } from "../../../OnlineModule/AcademicYear/academicYear.slice";
// import AddStudentNotice from "./AddStudentNotice";
// import * as myConstClass from "../../../../../config/messageconstant";
// import { resetReducList } from '../../../../../CommonActions/User.action'
// import * as msgConstant from "../../../../../config/messageconstant";
// import { apiGet, apiPost } from "../../../../../utils/api_service";
// import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicTable";

// import
// {
//   getStudentNotice,
//   getStudentNoticeFromDtToDt,
// } from "./studentNotice.slice";
// export const OperationMode = {
//   Add: "Add",
//   Edit: "Edit",
//   Status: "Status",
// };

// const columns = [
//   {
//     name: "index",
//     title: "Sr. No.",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "10%",
//   },
//   {
//     name: "title",
//     title: "Title",
//     positionCenter: false,
//     showInscreen: true,
//     alignCenter: "left",
//     width: "20%",
//   },
//   // {
//   //   name: "yearDetail",
//   //   title: "Year Details",
//   //   positionCenter: false,
//   //   showInscreen: true,
//   //   alignCenter: "left",
//   //   width:"20%"
//   // },
//   {
//     name: "fromDate",
//     title: "From Date",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "20%",
//   },
//   {
//     name: "toDate",
//     title: "To Date",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: "20%",
//   },
//   {
//     name: "isActive",
//     title: "Status",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "center",
//     width: '20%'
//   },
//   {
//     name: "action",
//     title: "Action",
//     positionCenter: true,
//     showInscreen: true,
//     alignCenter: "right",
//     width: "10%",
//   },
// ];

// class ViewStudentNotice extends Component
// {
//   constructor( props )
//   {
//     super( props );
//     this.state = {
//       tableColumnsToFilter: this.props.tableColumnsToFilter,
//       fieldData: {},
//       showTable: true,
//       showForm: false,
//       showAddCommitteeForm: false,
//       showAppliedStudentsForm: false,
//       showCommittee: false,
//       currentOperationMode: "",
//       searchValue: "",
//       isLoading: false,
//       department: "",
//       academicYearId: "",
//       fromDate: "",
//       toDate: "",
//       departmentList: [],
//       editData: {},
//       committeeData: {},
//       eventDataForAppliedStudents: {},
//       formErrors: [],
//     };
//   }

//   handleFormValidation()
//   {
//     const { fromDate, toDate } = this.state;
//     let formErrors = {};
//     let formIsValid = true;

//     if ( fromDate.toString().trim() === "" || fromDate === null )
//     {
//       formIsValid = false;
//       formErrors[ "fromDateError" ] = myConstClass.fromDateMsg;
//     }

//     if ( toDate.toString().trim() === "" || toDate === null )
//     {
//       formIsValid = false;
//       formErrors[ "toDateError" ] = myConstClass.toDateMsg;
//     }

//     if ( fromDate > toDate )
//     {
//       formIsValid = false;
//       formErrors[ "toDateError" ] = myConstClass.displaytoMsg;
//     }
//     this.setState( { formErrors: formErrors } );
//     return formIsValid;
//   }

//   onChangeStatus = ( rowData ) =>
//   {
//     const { fromDate, toDate } = this.state;
//     let frmDt = moment( fromDate ).format( "YYYY-MM-DD" );
//     let toDt = moment( toDate ).format( "YYYY-MM-DD" );
//     swal( msgConstant.changeActive, {
//       buttons: [ "Cancel", true ],
//     } ).then( ( value ) =>
//     {
//       if ( value )
//       {
//         this.setState( {
//           isLoading: true,
//         } );
//         let activeStatus = rowData.isActive === 1 ? 0 : 1;
//         apiGet( {
//           url:
//             endpoint.studentNotice +
//             "/update-status?studentNoticeId=" +
//             rowData.id +
//             "&status=" +
//             activeStatus,
//         } ).then( ( { success } ) =>
//         {
//           if ( success )
//           {
//             this.setState( {
//               isLoading: false,
//             } );
//             this.props.showNotification( { msg: "Status Updated" } );
//             this.props
//               .getStudentNoticeFromDtToDt( {
//                 fromDate: frmDt,
//                 toDate: toDt,
//               } )
//               .then( ( response ) =>
//               {
//                 if ( !response )
//                 {
//                   getAlert( { message: "Something went wrong" } );
//                 }
//               } );
//           } else
//           {
//             this.props.getAlert( {
//               message: "Failed to change status",
//             } );
//             this.setState( {
//               isLoading: false,
//             } );
//           }
//         } );
//       }
//     } );
//   };

//   submitHandler = () =>
//   {
//     const { getEventByAcademicId, getAlert, getAllEmployees } = this.props;
//     const { fromDate, toDate } = this.state;
//     let frmDt = moment( fromDate ).format( "YYYY-MM-DD" );
//     let toDt = moment( toDate ).format( "YYYY-MM-DD" );
//     if ( this.handleFormValidation() )
//     {
//       this.props
//         .getStudentNoticeFromDtToDt( {
//           fromDate: frmDt,
//           toDate: toDt,
//         } )
//         .then( ( response ) =>
//         {
//           if ( !response )
//           {
//             getAlert( { message: "Something went wrong" } );
//           }
//         } );
//     }
//   };

//   toggleFormTableVisibility = () =>
//   {
//     const { showForm, showTable, showAddCommitteeForm } = this.state;
//     this.setState( {
//       showForm: true,
//       showAddCommitteeForm: false,
//       showTable: false,
//       openSnackbar: true,
//       editData: {},
//     } );
//   };

//   backToForm = () =>
//   {
//     this.setState( {
//       showForm: false,
//       showAddCommitteeForm: false,
//       showTable: true,
//       showAppliedStudentsForm: false,
//       openSnackbar: true,
//       fromDate: "",
//       toDate: ""
//     } );
//   };

//   backTo = () =>
//   {
//     this.setState( {
//       showTable: true,
//     } );
//   };

//   onFormCancel = () =>
//   {
//     this.setState( {
//       showForm: true,
//       showAddCommitteeForm: false,
//       showTable: false,
//     } );
//   };

//   componentWillReceiveProps( nextProps )
//   {
//     if ( this.props.departments !== nextProps.departments )
//     {
//       if (
//         this.props.departments.department !== nextProps.departments.department
//       )
//       {
//         let departmentData = [];
//         departmentData = [
//           ...nextProps.departments.department,
//           { id: "0", name: "All" },
//         ];
//         this.setState( {
//           departmentList: departmentData,
//         } );
//       }
//     }
//   }

//   onRTSelectMultipleChange = ( selectedOption ) =>
//   {
//     const { tableColumnsToFilter } = this.state;
//     const selectedItem = tableColumnsToFilter.filter(
//       ( item ) => item.columnKeyName === selectedOption.columnKeyName
//     )[ 0 ];
//     selectedItem.isChecked = !selectedItem.isChecked;

//     this.setState( {
//       tableColumnsToFilter: tableColumnsToFilter,
//     } );
//   };

//   onDataChange = ( fieldName, newValue ) =>
//   {
//     this.setState( {
//       fieldData: { ...this.state.fieldData, [ fieldName ]: newValue },
//     } );
//   };

//   onSearch = ( searchValue ) =>
//   {
//     this.setState( {
//       searchValue: searchValue,
//     } );
//   };

//   onAddButtonClick = () =>
//   {
//     let fieldData = {};
//     // const { fieldMeta } = this.props;
//     // fieldMeta.map((currentField) => {
//     //   if (currentField.defaultValue) {
//     //     fieldData[currentField.dataKey] = currentField.defaultValue;
//     //   }
//     // });

//     this.setState( {
//       fieldData: fieldData,
//       currentOperationMode: OperationMode.Add,
//     } );
//     this.toggleFormTableVisibility();
//   };

//   rowAdd = ( rowData ) =>
//   {
//     this.props.rowData( rowData );
//   };

//   OnClickEdit = ( data ) =>
//   {
//     this.setState( {
//       showAddCommitteeForm: false,
//       showTable: false,
//       showForm: true,
//       editData: data,
//     } );
//   };

//   componentDidMount()
//   {
//     this.props.resetReducList();
//     this.props.getAcademicYear().then( ( response ) =>
//     {
//       if ( !response )
//       {
//         this.props.getAlert( { message: "Something went wrong" } );
//       }
//     } );

//     this.props.getStudentNotice().then( ( response ) =>
//     {
//       if ( !response )
//       {
//         this.props.getAlert( { message: "Something went wrong" } );
//       }
//     } );

//   }

//   rowDelete = ( rowData ) =>
//   {
//     swal( {
//       title: "Are you sure?",
//       text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     } ).then( ( willDelete ) =>
//     {
//       if ( willDelete )
//       {
//         this.onDelete( rowData.id );
//       }
//     } );
//   };

//   onDelete = ( id ) =>
//   {
//     const { fromDate, toDate } = this.state;
//     let frmDt = moment( fromDate ).format( "YYYY-MM-DD" );
//     let toDt = moment( toDate ).format( "YYYY-MM-DD" );
//     this.setState( {
//       isLoading: true,
//     } );
//     apiDelete( {
//       url: endpoint.studentNotice + "/" + id,
//     } ).then( ( { success } ) =>
//     {
//       if ( success )
//       {
//         this.setState( {
//           isLoading: false,
//         } );
//         this.props.showNotification( { msg: "Data deleted successfully" } );
//         const { getEmployeeByDeptId, getAlert } = this.props;
//         // this.props.getStudentNotice().then( ( response ) =>
//         // {
//         //   if ( !response )
//         //   {
//         //     this.props.getAlert( { message: "Something went wrong" } );
//         //   }
//         // } );
//         this.props
//           .getStudentNoticeFromDtToDt( {
//             fromDate: frmDt,
//             toDate: toDt,
//           } )
//           .then( ( response ) =>
//           {
//             if ( !response )
//             {
//               getAlert( { message: "Something went wrong" } );
//             }
//           } );
//       } else
//       {
//         this.props.getAlert( {
//           message: "Failed to delete",
//         } );
//         this.setState( {
//           isLoading: false,
//         } );
//       }
//     } );
//   };

//   onDateChange = ( dataKey ) => ( date ) =>
//   {
//     this.setState( {
//       [ dataKey ]: date,
//     } );
//   };
//   getFilteredTableData = () =>
//   {
//     const { searchValue } = this.state;
//     return this.props.studNoticeList?.studentByDateNotice.filter(
//       ( currentRow ) =>
//       {
//         let isValid = false;
//         if (
//           ( currentRow[ "title" ] &&
//             currentRow[ "title" ]
//               .toString()
//               .toLowerCase()
//               .includes( searchValue.toLowerCase() ) ) ||
//           ( currentRow[ "fromDate" ] &&
//             currentRow[ "fromDate" ]
//               .toString()
//               .toLowerCase()
//               .includes( searchValue.toLowerCase() ) ) ||
//           ( currentRow[ "toDate" ] &&
//             currentRow[ "toDate" ]
//               .toString()
//               .toLowerCase()
//               .includes( searchValue.toLowerCase() ) )
//         )
//         {
//           isValid = true;
//           return true;
//         }
//         return isValid;
//       }
//     );
//   };
//   onSearch = ( value ) =>
//   {
//     this.setState( {
//       searchValue: value,
//     } );
//   };
//   render()
//   {
//     const {
//       showAddButtonInTable = false,
//       showExcelDownload = false,
//       showAddButton = true,
//       departments,
//       employeeDetails,
//       academicYear,
//     } = this.props;
//     const {
//       tableColumnsToFilter = [],
//       showTable,
//       showForm,
//       showAddCommitteeForm,
//       department,
//       departmentList,
//       showAppliedStudentsForm,
//       academicYearId,
//       showCommittee,
//       fromDate,
//       toDate,
//     } = this.state;
//     const { fromDateError, toDateError } = this.state.formErrors;
//     return (
//       <>
//         <Paper sx={{ p: 3, pt: 3, borderRadius: 2 }} elevation={0}>
//           <NewLandingScreenHeader
//             screenTitle={StudentNoticeJson.screenTitle}
//             showPdfDownload={false}
//             showExcelDownload={false}
//             showAddButton={showAddButton}
//             onAddButtonClick={this.onAddButtonClick}
//             showTable={showTable}
//           />
//           {showForm && (
//             <>
//               <AddStudentNotice
//                 onCancel={this.backToForm}
//                 editData={this.state.editData}
//               />
//             </>
//           )}

//           {showTable && (
//             <>
//               <Grid
//                 sx={{ borderRadius: 2 }}
//                 container
//                 justifyContent="space-evenly"
//                 direction="row"
//               >
//                 <Grid
//                   item
//                   sm={12}
//                   xs={12}
//                   md={12}
//                   lg={12}
//                   columnSpacing={1}
//                   container
//                   justifyContent="space-evenly"
//                 >
//                   <Grid
//                     item
//                     xs={12}
//                     sm={5.5}
//                     md={5.5}
//                     columnSpacing={1}
//                     container
//                     justifyContent="space-evenly"
//                   >
//                     <Grid item xs={12} sm={12} md={12}>
//                       <LabelCompo
//                         className="text-black"
//                         style={{ fontSize: 16 }}
//                         label="*From Date "
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12}>
//                       <BasicDatePicker
//                         color="primary"
//                         name="fromDate"
//                         size="small"
//                         value={fromDate}
//                         fullWidth
//                         onChange={this.onDateChange( "fromDate" )}
//                         isError={fromDateError ? true : false}
//                         errorText={fromDateError ? fromDateError : " "}
//                       />
//                     </Grid>
//                   </Grid>
//                   <Grid
//                     item
//                     xs={12}
//                     sm={5.5}
//                     md={5.5}
//                     columnSpacing={1}
//                     container
//                     justifyContent="space-evenly"
//                   >
//                     <Grid item xs={12} sm={12} md={12}>
//                       <LabelCompo
//                         className="text-black"
//                         style={{ fontSize: 16 }}
//                         label="*To Date "
//                       />
//                     </Grid>

//                     <Grid item xs={12} sm={12} md={12}>
//                       <BasicDatePicker
//                         color="primary"
//                         name="toDate"
//                         size="small"
//                         value={toDate}
//                         fullWidth
//                         onChange={this.onDateChange( "toDate" )}
//                         isError={toDateError ? true : false}
//                         errorText={toDateError ? toDateError : " "}
//                       />
//                     </Grid>
//                   </Grid>
//                   <Grid
//                     item
//                     xs={12}
//                     sm={1}
//                     md={1}
//                     container
//                     justifyContent="flex-end"
//                   >
//                     <Grid item xs={12} sm={12} md={12}></Grid>

//                     <Grid item xs={12} sm={12} md={12}>
//                       <ButtonCompo
//                         size="medium"
//                         type="button"
//                         variant="contained"
//                         name="Search"
//                         onClick={this.submitHandler}
//                       />
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </Grid>
//               <Grid
//                 sx={{ marginTop: 1, marginBottom: 2 }}
//                 item
//                 xs={12}
//                 sm={12}
//                 md={12}
//               >
//                 <SearchExternalPlacedStudList onSearch={this.onSearch} />
//               </Grid>
//               <Grid item>
//                 <DynamicTable
//                   data={this.getFilteredTableData()}
//                   tableHead={columns}
//                   showHeadDelete={true}
//                   showHeadEdit={true}
//                   showPegination={false}
//                   rowDelete={this.rowDelete}
//                   rowEdit={this.OnClickEdit}
//                   onCancel={this.backToForm}
//                   rowStatus={this.onChangeStatus}
//                 />
//               </Grid>
//             </>
//           )}
//         </Paper>
//       </>
//     );
//   }
// }
// const mapStateToProps = ( state ) => ( {
//   academicYear: state.academicYear,
//   departments: state.department,
//   employeeDetails: state.employeeList,
//   eventList: state.event,
//   studNoticeList: state.studNotice,
// } );
// const mapDispatchToProps = {
//   showNotification,
//   getStudentNotice,
//   getAlert,
//   getDepartment,
//   getEmployeeByDeptId,
//   getAllEmployees,
//   getEmployeeById,
//   getAcademicYear,
//   getStudentNoticeFromDtToDt,
//   resetReducList
// };
// export default connect( mapStateToProps, mapDispatchToProps )( ViewStudentNotice );
