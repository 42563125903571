import { Grid, Paper } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import RTTable from "../../../components/Comman/RT/RTTable";
import endpoint from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
import { getFolder } from "../AdminGalleryFolder/adminGallary.slice";
import { Loading1 } from "../../../components/Loading1";
import { downloadVideo, downloadStudentVideo } from "../../Video/Video";
import Corousel from "./Carousel";
import AdminGallarYPopup from "../AdminGalleryFolder/AdminGallarYPopup";
import MasonryImageList from "./MasornyImageList";
const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Document Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
class Gallary extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    gallaryList: [],
    itemList: {},
    open: false,
  };
  componentDidMount() {
    this.props.getFolder().then((resp) => {
      if (!resp) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.folder.folder !== nextProps.folder.folder) {
      this.setState({
        gallaryList: [],
      });
      nextProps.folder.folder.map((folderData) => {
        if (folderData.isActive !== 0) {
          if (
            folderData.folderContents &&
            folderData.folderContents.length !== 0
          ) {
            apiGet({
              url:
                endpoint.fileDownload + "/" + folderData.folderContents[0].path,
            }).then(({ data, success }) => {
              if (success) {
                folderData = {
                  ...folderData,
                  profile: data.data,
                  // profile: "https://images.unsplash.com/photo-1525097487452-6278ff080c31"
                };
                this.setState({
                  gallaryList: [...this.state.gallaryList, folderData],
                });
              } else {
                folderData = {
                  ...folderData,
                };
                this.setState({
                  gallaryList: [...this.state.gallaryList, folderData],
                });
              }
            });
          }
        }
      });
    }
  }
  onClickPhoto = (item) => {
    this.setState({
      open: !this.state.open,
      itemList: item,
    });
  };
  render() {
    const { gallaryList, open, itemList } = this.state;

    return (
      <>
        {!open ? (
          <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
            <ImageList cols={3}>
              {gallaryList.map((item) => (
                <ImageListItem key={item.profile}>
                  <img
                    src={item.profile}
                    srcSet={item.profile}
                    // src={`${item.profile}?w=248&fit=crop&auto=format`}
                    // srcSet={`${item.profile}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    height="100px"
                    width="100"
                    alt={item.name}
                    loading="lazy"
                    onClick={() => {
                      this.onClickPhoto(item);
                    }}
                  />
                  <ImageListItemBar
                    title={<span>Title: {item.name}</span>}
                    // subtitle={<span>by: {item.detail}</span>}
                    position="below"
                    onClick={() => {
                      this.onClickPhoto(item);
                    }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Paper>
        ) : (
          <MasonryImageList
            itemList={itemList}
            GoBack={() => {
              this.setState({
                open: false,
              });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  folder: state.folder,
});
const mapDispatchToProps = {
  getAlert,
  getFolder,
  downloadVideo,
};
export default connect(mapStateToProps, mapDispatchToProps)(Gallary);

// const MasonryImageList = ({itemList}) => {
//   const [currentImage, setCurrentImage] = useState(0);
//   const [viewerIsOpen, setViewerIsOpen] = useState(false);

//   const openLightbox = (index) => {
//     setCurrentImage(index);
//     setViewerIsOpen(true);
//   };

//   const closeLightbox = () => {
//     setCurrentImage(0);
//     setViewerIsOpen(false);
//   };

//   return (
//     <>
//       {!viewerIsOpen ? (
//         <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
//           <Grid
//             container
//             justifyContent="space-evenly"
//             direction="row"
//             spacing={2}
//           >
//             <Grid
//               item
//               sm={12}
//               xs={12}
//               md={12}
//               lg={12}
//               sx={{ margin:2 }}
//               // rowSpacing={2}
//               //   columnSpacing={1}
//               container
//               justifyContent="space-evenly"
//             >
//               <Grid item xs={12} sm={12} md={12}>
//                 <LabelCompo
//                   className="text-black"
//                   style={{ fontSize: 18 }}
//                   label={itemList.name}
//                 />
//               </Grid>
//             </Grid>

//           </Grid>
//           <ImageList variant="masonry" cols={4} gap={8}>
//             {itemDataGallary.map((item, index) => (
//               <ImageListItem key={item.img}>
//                 <img
//                   src={`${item.img}?w=248&fit=crop&auto=format`}
//                   srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
//                   alt={item.title}
//                   onClick={() => {
//                     openLightbox(index);
//                   }}
//                   loading="lazy"
//                 />
//               </ImageListItem>
//             ))}
//           </ImageList>
//         </Paper>
//       ) : (
//         <Corousel ImageList={itemDataGallary} onClose={closeLightbox} />
//       )}

//     </>
//   );
// };

const itemDataGallary = [
  {
    img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
    title: "Kitchen",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
    title: "Bed",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
    title: "Books",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
    title: "Sink",
    subtitle: "The Best City in the World",
  },

  {
    img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
    title: "Blinds",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
    title: "Chairs",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
    title: "Laptop",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
    title: "Doors",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
    title: "Coffee",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
    title: "Storage",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
    title: "Candle",
    subtitle: "The Best City in the World",
  },
  {
    img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
    title: "Coffee table",
    subtitle: "The Best City in the World",
  },
];

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
    author: "@bkristastucchio",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
    author: "@rollelflex_graphy726",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
    author: "@helloimnik",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
    author: "@nolanissac",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
    author: "@hjrc33",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
    author: "@arwinneil",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
    author: "@tjdragotta",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
    author: "@katie_wasserman",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
    author: "@silverdalex",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
    author: "@shelleypauls",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
    author: "@peterlaster",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
    author: "@southside_customs",
  },
];
