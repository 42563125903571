import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class EditTransaction extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    debitAmount: "",
    rowListDetails: [],
    formErrors: [],
    transactionDate: "",
    minimumAmount: "",
  };

  componentDidMount() {
    const { applicantAdmissionData, feesStructureHeaderByIdData } = this.props;

    this.setState({
      minimumAmount:
        feesStructureHeaderByIdData?.feesStructureHeaderById.minimumFees,
    });
    if (Object.keys(applicantAdmissionData.transactionById).length !== 0) {
      const DOBArr =
        applicantAdmissionData.transactionById.transactionDate == null
          ? ""
          : applicantAdmissionData.transactionById.transactionDate.split("-");
      const DOB =
        DOBArr === "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

      this.setState({
        transactionDate: DOB,
        debitAmount: applicantAdmissionData.transactionById.amt,
      });
    } else {
    }
  }

  changeFromDate = (date) => {
    this.setState({
      transactionDate: date,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = (e) => {
    const { applicantAdmissionData } = this.props;
    const { transactionDate } = this.state;
    let tDate = moment(transactionDate).format("DD-MM-YYYY");

    let feesToSave = {
      id: applicantAdmissionData.transactionById.id,
      userRegistrationId:
        applicantAdmissionData.transactionById.userRegistrationId,
      academicYearId: applicantAdmissionData.transactionById.academicYearId,
      admissionRegistrationDetailId:
        applicantAdmissionData.transactionById.admissionRegistrationDetailId,
      transactionDate: tDate,
      totalFee: applicantAdmissionData.transactionById.totalFee,
      transactionRemark:
        applicantAdmissionData.transactionById.transactionRemark,
      payType: applicantAdmissionData.transactionById.payType,
      payRefNo: applicantAdmissionData.transactionById.payRefNo,
      payBank: applicantAdmissionData.transactionById.payBank,
      amt: this.state.debitAmount,
      transactionType: applicantAdmissionData.transactionById.transactionType,
      receiptTypeId: applicantAdmissionData.transactionById.receiptTypeId,
      isActive: applicantAdmissionData.transactionById.isActive,
      exVar1: applicantAdmissionData.transactionById.exVar1,
      exVar2: applicantAdmissionData.transactionById.exVar2,
      exVar3: applicantAdmissionData.transactionById.exVar3,
      transactionNo: applicantAdmissionData.transactionById.transactionNo,
    };

    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Do you want to edit transaction?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.props.onSave(feesToSave);
        }
      });
    }
  };

  handleFormValidation() {
    const { debitAmount, transactionDate, minimumAmount } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (debitAmount === "") {
      formIsValid = false;
      formErrors["debitAmountError"] = myConstClass.debitAmountMsg;
    }
    if (debitAmount < minimumAmount) {
      formIsValid = false;
      formErrors["debitAmountError"] = myConstClass.feesMinimumPayMsg;
    }
    if (debitAmount <= 0) {
      formIsValid = false;
      formErrors["debitAmountError"] = myConstClass.debitAmountZeroMsg;
    }
    if (transactionDate === "" || transactionDate === null) {
      formIsValid = false;
      formErrors["transactionDateError"] = myConstClass.transactionDateMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      transactionDate: "",
      debitAmount: "",
    });
  };

  render() {
    const { applicantAdmissionData, data, intrestedStudList } = this.props;
    const { debitAmount, transactionDate } = this.state;
    const { debitAmountError, transactionDateError } = this.state.formErrors;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.open}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Edit Transaction
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 2 }}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Debit amount"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="debitAmount"
                        id="debitAmount"
                        value={debitAmount}
                        onChange={this.changeHandler}
                        // fullWidth
                        error={debitAmountError ? true : false}
                        helperText={debitAmountError ? debitAmountError : " "}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Transaction Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="transactionDate"
                        size="small"
                        value={transactionDate}
                        // fullWidth
                        onChange={this.changeFromDate}
                        isError={transactionDateError ? true : false}
                        errorText={
                          transactionDateError ? transactionDateError : " "
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-end"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label=""
                      />
                    </Grid>
                    <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Submit"
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feesStructureHeaderByIdData: state.feesStructureHead,
  applicantAdmissionData: state.applicantAdmission,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EditTransaction);
