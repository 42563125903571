export const AcademicDetailsReportJson = {
  apiBaseURL: "",
  screenTitle: "Academic Details Report",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  marginTop: 3,
  fieldMeta: [
    {
      label: "Academic Year",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "academicYear",
      dataKey: "academicYearId",
      isMandatory: true,
    },
    {
      label: "Activity",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "activityId",
      isMandatory: true,
      getListId: "activityId",
    },
    {
      label: "Teacher Name",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "teacher",
      dataKey: "teacherId",
      isMandatory: true,
      getListId: "teacherId",
    },
  ],
};
