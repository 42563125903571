import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  qualification: [],
  isFetch: false,
};
let URL = endpoints.qualification;
const qualificationSlice = createSlice({
  name: "qualification",
  initialState,
  reducers: {
    qualificationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        qualification: row,
        isFetch: true,
      };
    },
  },
});

export const { qualificationSuccess } = qualificationSlice.actions;

export default qualificationSlice.reducer;

export const getQualification = () => async (dispatch) => {
  try {
   const response= apiGet({
      url: URL,
    }).then(({ data, success }) => {

      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };

          row.push(bData);
          return data1;
        });
        dispatch(qualificationSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
