export const ExamApplyJson = {
    screenTitle: "Exam Application",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    getAcadamicYear: true,
    getListId: "yearDetail",
  
    fieldMeta: [
      {
        label: "Search By",
        controlType: "autocomplete",
        placeHolder: "",
        md: 4,
        lg: 4,
        sm: 4,
        xs: 12,
        masterName: "searchByMaster",
        defaultValue: "academic",
        dataKey: "searchBy",
        hideTextfield: true,
        isMandatory: true,
      },
      {
        label: "PRN No",
        controlType: "hideTextfield",
        placeHolder: "",
        md: 4,
        lg: 4,
        sm: 4,
        xs: 12,
        dataKey: "prnNo",
        rootValue: true,
        tableDataKey: "searchBy",
        open: "prn",
      },
      {
        label: "Student name",
        controlType: "hideTextfield",
        placeHolder: "",
        md: 4,
        lg: 4,
        sm: 4,
        xs: 12,
        dataKey: "studentName",
        rootValue: true,
        tableDataKey: "searchBy",
        open: "studentName",
      },
    ],
  };
  