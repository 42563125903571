import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../../../components/Comman/Util/Base64";
import endpoints from "../../../../config/endpoints";
import  { tokenExp } from "../../../Login/login.slice";

const initialState = {
  institute: [],
  instData: [],
  shortNameData: [],
  instituteFilter: [],
  instituteById: {},
  isFetchInstituteById: false,
  isFilter: false,
  isFetch: false,
  isFetchInst: false,
  isFetchShortName: false,
  isFetchInstitute: false,
  error: false,
  isServerFail:false,
  isDelete:false,
  isSave:false,
};

let URL = endpoints.institute;
const instituteSlice = createSlice({
  name: "institute",
  initialState,
  reducers: {
    instituteSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        institute: row,
        isFetchInstitute: true,
      };
    },
    instituteFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        institute: [],
        isFetchInstitute: false,
      };
    },

    getInstituteIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        instituteById: data,
        isFetchInstituteById: true,
        isSave: false,
      };
    },
    SaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        instituteById: data,
        isSave: true,
      };
    },
    resetSaveSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        instituteById: {},
        isSave: false,
        isFetchInstituteById: false,
      };
    },
    getInstituteIdFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        instituteById: {},
        isFetchInstituteById: false,
      };
    },
    instituteFilterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        instituteFilter: row,
        isFilter: true,
      };
    },
    instituteFilterFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        instituteFilter: [],
        isFilter: false,
      };
    },

    instListByNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        instData: data,
        isFetch: true,
      };
    },

    instListByNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        instData: [],
        isFetch: false,
      };
    },

    instListByShortNameSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        isFetchShortName: true,
        shortNameData: data,
      };
    },

    instListByShortNameFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isFetchShortName: false,
        shortNameData: [],
      };
    },

    resetDeleteSuccess : (state = cloneDeep(initialState), action) => {	
      return {	
        ...state,	
        error:false,	
        isDelete: false,	
        isSave: false,	
      };	
    },

    serverFailed: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isServerFail: true,
        error: false,
        isDelete:false
      };
    },

    documentDeleteFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error:true,
        isDelete: true,
        isSave: false,
      };
    },

    documentDeleteSuccess:  (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        error:false,
        isDelete: true,
      };
    },
  },
});

export const {
  resetDeleteSuccess,
  serverFailed,
  instituteSuccess,
  getInstituteIdSuccess,
  instituteFailure,
  instituteFilterSuccess,
  instListByNameSuccess,
  instListByNameFailure,
  SaveSuccess,
  instListByShortNameSuccess,
  instListByShortNameFailure,
  instFetchSuccess,
  resetSaveSuccess,
  documentDeleteSuccess,
  documentDeleteFail
} = instituteSlice.actions;

export default instituteSlice.reducer;

export const getInstitute = () => async (dispatch) => {
  try {
    const name = localStorage.getItem("token");
    const token = decodeData(name);
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token,
      shouldrefresh: "true",
    };
  const doc=await axios
      .get(URL, { headers })
      .then((response) => {
        const{error}=response.data
        if(error===true)
        {
          dispatch(tokenExp())
        }
        else{
        console.log("Success institute getInstitute========>", response);
        let data = response.data.data.content;
        let row = [];
        let index = 0;

        data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.tenantName + " ( " + data1.shortName + " ) ",
            instAddr: data1.address,
            instAffi: data1.instAffiliation,
            aicteCode: data1.aicteCode,
            dbName: data1.dbName,
            delStatus: data1.delStatus,
            domainName: data1.domainName,
            dteCode: data1.dteCode,
            epfNo: data1.epfNo,
            isEducational: data1.isEducational === 1 ? "Yes" : "No",
            isActive: data1.isActive === 1 ? "Active" : "Inactive",
            logo: data1.logo,
            panNo: data1.panNo,
            profTaxNo: data1.profTaxNo,
            instShortName: data1.shortName,
            instName: data1.tenantName,
            tanNo: data1.tanNo,
            uniCode: data1.uniCode,
            tenantEmail: data1.tenantEmail,
            tenantPhone: data1.tenantPhone,
            trustName: data1.trust.name,
            instituteType: data1.instituteType,
            minorityReligion: data1.minorityReligion,
            autonomousStatus: data1.autonomousStatus,
            minority: data1.minority,
          };
          row.push(bData);
        });
        dispatch(instituteSuccess({ row }));
        return row;}
      })
      .catch((error) => {
        console.log("Error getInstitute ========>", error);
        dispatch(serverFailed());
      });
      return doc;
  } catch (e) {
    dispatch(serverFailed());
    instituteFailure();
    return console.error(e.message);
  }
};

export const getInstituteById =
  ({ instId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .get(URL + "/" + instId, { headers })
        .then((response) => {
          console.log("Success With id getInstituteById ========>", response);
          const { data } = response.data;
          dispatch(getInstituteIdSuccess({ data }));
          return data;
        })
        .catch((error) => {
          console.log("Error getInstituteById ========>", error.response.data);
          dispatch(serverFailed());

        });
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const saveInstitute =
  ({ instituteData }) =>
  async (dispatch) => {
    try {
      let data = JSON.stringify(instituteData);

      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      await axios
        .post(URL, data, { headers })
        .then((response) => {
          console.log("Success With saveInstitute ========>", response);
          const { data } = response.data;
          dispatch(SaveSuccess({data})); 
          return data;
        })
        .catch((error) => {
          dispatch(serverFailed());
          console.log("Error saveInstitute ========>", error.response.data);
        });
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };

export const deleteInstitute =
  ({ instId }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
   let response=await axios
        .delete(URL + "/" + instId, { headers })
        .then((response) => {
          console.log(
            "Success With deletedeleteBranchById ========>",
            response
          );
          dispatch(documentDeleteSuccess());

        })
        .catch((error) => {
          if (error.response) {
            console.log('error.response.status')
            console.log(error.response.status)
            console.log('error.response.data')
            console.log(error.response.data)
         }
          dispatch(documentDeleteFail());
          return error.response.data;
        });
      return response;
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };



export const deleteInstData = () => async (dispatch) => {
  try {
    dispatch(instListByNameFailure());
    dispatch(instListByShortNameFailure());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getInstByName =
  ({ id, instName }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      if (id === 0) {
        await axios
          .get(
            URL +
              '?filters={"tenantName":"' +
              instName +
              '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log(
              "Success getinst  ========>",
              response.data.data.content
            );
            let data = response.data.data.content;

            dispatch(instListByNameSuccess({ data }));
            return data;
          })
          .catch((error) => {
            console.log("Error getInst========>", error);
          });
      } else {
        await axios
          .get(
            URL +
              '?filters={"id":"' +
              id +
              '","_mode" : "NIN"},{"tenantName":"' +
              instName +
              '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log(
              "Success getinst  ========>",
              response.data.data.content
            );
            let data = response.data.data.content;

            dispatch(instListByNameSuccess({ data }));
            return data;
          })
          .catch((error) => {

            dispatch(serverFailed());
            console.log("Error getInst========>", error);
          });
      }
    } catch (e) {
      dispatch(serverFailed());
      return console.error(e.message);
    }
  };
export const getInstByShortName =
  ({ id, shortName }) =>
  async (dispatch) => {
    try {
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
        shouldrefresh: "true",
      };
      if (id === 0) {
        await axios
          .get(
            URL +
              '?filters={"shortName":"' +
              shortName +
              '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log(
              "Success getInst shortName  ========>",
              response.data.data.content
            );
            let data = response.data.data.content;

            dispatch(instListByShortNameSuccess({ data }));
            return data;
          })
          .catch((error) => {


            console.log("Error getInst= shortName =======>", error);
          });
      } else {
        await axios
          .get(
            URL +
              '?filters={"id":"' +
              id +
              '","_mode" : "NIN"},{"shortName":"' +
              shortName +
              '","_mode" : "EXACT"}',
            { headers }
          )
          .then((response) => {
            console.log(
              "Success getinst  ========>",
              response.data.data.content
            );
            let data = response.data.data.content;

            dispatch(instListByShortNameSuccess({ data }));
            return data;
          })
          .catch((error) => {
            console.log("Error getInst========>", error);
          });
      }
    } catch (e) {
      return console.error(e.message);
    }
  };
export const filterInstData =
  ({ row, flag }) =>
  async (dispatch) => {
    try {
      if (flag === true) {
        dispatch(instituteFilterSuccess({ row }));
      } else {
        dispatch(instituteSuccess({ row }));
      }
    } catch (e) {
      return console.error(e.message);
    }
  };



  export const resetDelete = () => async (dispatch) => {
    try {
      dispatch(resetDeleteSuccess());
    } catch (e) {
      return console.error(e.message);
    }
  };
  export const resetSave = () => async (dispatch) => {
    try {
      dispatch(resetSaveSuccess());
    } catch (e) {
      return console.error(e.message);
    }
  };