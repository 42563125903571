import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import RTTable from "../../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../../components/dynamicscreens/DynamicForm";
import { Loading1 } from "../../../../components/Loading1";
import * as msgConstant from "../../../../config/messageconstant";
import { showNotification } from "../../../../Pages/Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../../utils/api_service";
import { LabelCompo } from "../../../../components/Comman/Label";
import { getSemisterDetailsBySemisterInfoId } from "./semisterInfo.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({
      fieldData: {},
      currentOperationMode: OperationMode.Add,
    });
  }
  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    this.setState({
      fieldData: {},
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };
  rowAdd = (rowData) => {
    this.setState({
      fieldData: {},
      currentOperationMode: OperationMode.Add,
    });
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    const { tableColumns } = this.props;
    let fieldData = {};

    tableColumns.forEach((currentCol) => {
      const formDataKey = currentCol.formDataKey
        ? currentCol.formDataKey
        : currentCol.name;
      const tableDataKey = currentCol.tableDataKey
        ? currentCol.tableDataKey
        : currentCol.name;
      fieldData[formDataKey] = rowData[tableDataKey];
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] = rowData.isActive;
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onFormCancel = () => {
    this.props.onCancel();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta, semisterInfoId } = this.props;
    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      semister: {
        id: semisterInfoId,
      },
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : "Data updated successfully",
        });
        this.props
          .getSemisterDetailsBySemisterInfoId({
            semisterId: this.props.semisterInfoId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: OperationMode.Add,
          fieldData: {},
        });
      }
    });
  };

  onDelete = (rowdata) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props
          .getSemisterDetailsBySemisterInfoId({
            semisterId: this.props.semisterInfoId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

        this.setState({
          currentOperationMode: OperationMode.Add,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name].toLowerCase().includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return true;
      });
      return isValid;
    });
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const { fieldMeta, semisterInfoId } = this.props;

        const dataToSave = {
          semister: {
            id: semisterInfoId,
          },
        };

        dataToSave["id"] = rowData["id"];

        const { tableColumns } = this.props;
        let fieldData = {};

        tableColumns.forEach((currentCol) => {
          const formDataKey = currentCol.formDataKey
            ? currentCol.formDataKey
            : currentCol.name;
          const tableDataKey = currentCol.tableDataKey
            ? currentCol.tableDataKey
            : currentCol.name;
          fieldData[formDataKey] = rowData[tableDataKey];
        });

        fieldMeta.forEach((currentField) => {
          if (
            currentField.controlType === "autocomplete" &&
            !currentField.isRootLevelKey
          ) {
            dataToSave[currentField.dataKey] = {};
            dataToSave[currentField.dataKey]["id"] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          } else {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        });

        dataToSave["isActive"] = rowData.isActive === 1 ? 0 : 1;

        this.onSave(dataToSave, false);
      }
    });
  };

  render() {
    const {
      showAddButtonInTable = false,
      showSaveNextBtn = true,
      fieldMeta,
      showLoader = false,
      dynamicMasterData = {},
      tableColumns = [],
    } = this.props;
    const { tableColumnsToFilter = [], fieldData, isLoading } = this.state;
    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title={"Semester Detail"} />
        </Paper>

        <br></br>
        <Paper sx={{ p: 1, pt: 2, borderRadius: 2 }} elevation={0}>
          <DynamicForm
            fieldMeta={fieldMeta}
            dynamicMasterData={dynamicMasterData}
            fieldData={fieldData}
            showSaveNextBtn={showSaveNextBtn}
            onDataChange={this.onDataChange}
            onCancel={this.onFormCancel}
            onSave={this.onFormSave(true)}
            onSaveAndNext={this.onFormSave(false)}
          />
          <br />
          <RTTable
            columns={tableColumns}
            hiddenColumnNames={tableColumnsToFilter.map((item) => {
              return !item.isChecked ? item.columnKeyName : "";
            })}
            showAddButtonInTable={showAddButtonInTable}
            tableData={this.getFilteredTableData()}
            rowEdit={this.rowEdit}
            rowDelete={this.rowDelete}
            onChangeStatus={this.onChangeStatus}
            rowAdd={this.rowAdd}
          />
        </Paper>
        {isLoading && <Loading1 />}
        {!showLoader && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  getSemisterDetailsBySemisterInfoId,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
