import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../../components/Comman/Label";
import ViewTimeTableCellPop from "../GenerateTimeTable/ViewTimeTableCellPop";
class MatrixForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      matList: [],
      columnList: [],
      openTimeSlotCell: false,
      rows: [],
      value: [],
      timeSlot: "",
      day: "",
    };
  }

  componentDidMount() {
    const { timeTableList, timeSlotList } = this.props;
    this.createMatrix(timeTableList.timeTableDetailsList, timeSlotList);
  }
  createMatrix(timeTableDetailsList, timeSlotList) {
    const { dayList } = this.props;
    let matrixData = [];
    if (this.state.matList.length === 0) {
      timeSlotList.map((timeSlotData) => {
        let matData;
        matData = {
          timeSlotId: timeSlotData.id,
          timeSlotName: timeSlotData.fromTime + " - " + timeSlotData.toTime,
        };
        const daysData = dayList.map((dayData) => {
          let timeTableDetailsData = timeTableDetailsList.filter(
            (item) =>
              item.day === dayData.id && item.timeSlot === timeSlotData.id
          );
          if (timeTableDetailsData.length !== 0) {
            let dataList = [];
            timeTableDetailsData.map((timeTableDetailsDetails) => {
              let matValue = {
                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                teacher: timeTableDetailsDetails.teacher,
                lastName: timeTableDetailsDetails.lastName,
                firstName: timeTableDetailsDetails.firstName,
                fatherName: timeTableDetailsDetails.fatherName,
                motherName: timeTableDetailsDetails.motherName,
                middleName: timeTableDetailsDetails.middleName,
  
                teacherName: timeTableDetailsDetails.teacherName,

                subjectName: timeTableDetailsDetails.subjectName,

                id: timeTableDetailsDetails.id,

                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectName: timeTableDetailsDetails.subjectName,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };
              dataList.push(matValue);
            });

            let days = {
              dayId: dayData.id,
              dayName: dayData.name,
              value: dataList,
            };
            return days;
          }
          let days = {
            dayId: dayData.id,
            dayName: dayData.name,
            value: [],
          };
          return days;
        });
        matData = { ...matData, dayList: daysData };
        matrixData.push(matData);
      });
    } else {
      this.state.matList.map((timeSlotData) => {
        let matData;
        matData = {
          timeSlotId: timeSlotData.timeSlotId,
          timeSlotName: timeSlotData.timeSlotName,
        };
        const daysData = timeSlotData.dayList.map((dayData) => {
          let timeTableDetailsData = timeTableDetailsList.filter(
            (item) =>
              item.day === dayData.dayId &&
              item.timeSlot === timeSlotData.timeSlotId
          );

          if (timeTableDetailsData.length !== 0) {
            let dataList = [];
            timeTableDetailsData.map((timeTableDetailsDetails) => {
              let matValue = {
                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                teacher: timeTableDetailsDetails.teacher,
                lastName: timeTableDetailsDetails.lastName,
                firstName: timeTableDetailsDetails.firstName,
                fatherName: timeTableDetailsDetails.fatherName,
                motherName: timeTableDetailsDetails.motherName,
                middleName: timeTableDetailsDetails.middleName,
  
                teacherName: timeTableDetailsDetails.teacherName,

                subjectName: timeTableDetailsDetails.subjectName,

                id: timeTableDetailsDetails.id,

                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectName: timeTableDetailsDetails.subjectName,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };
              dataList.push(matValue);
            });

            let days = {
              dayId: dayData.dayId,
              dayName: dayData.dayName,
              value: dataList,
            };
            return days;
          }
          let days = {
            dayId: dayData.dayId,
            dayName: dayData.dayName,
            value: [],
          };
          return days;
        });
        matData = { ...matData, dayList: daysData };
        matrixData.push(matData);
      });
    }
    let matList1 = [];
    let matList2 = matrixData.map((data) => {
      data.dayList.map((data2) => {
        if (data2.value.length !== 0) {
          matList1.push(data);
        }
      });
    });
    console.log(matList1);
    this.setState({ matList: matList1 });
    console.log("....................");
    console.log(matrixData);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList.timeTableDetailsList !==
        nextProps.timeTableList.timeTableDetailsList
      ) {
        this.createMatrix(
          nextProps.timeTableList.timeTableDetailsList,
          this.props.timeSlotList
        );
      }
    }
  }

  handleClosesTimeSlotCell = () => {
    this.setState({
      openTimeSlotCell: false,
      rows: [],
    });
  };

  columns = [
    {
      name: "floorName",
      title: "Floor Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "roomsName",
      title: "Room Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "batchName",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "departmentName",
      title: "Department",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "firstName",
      title: "Teacher Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 1,
    },
    {
      name: "subjectDetail",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  render() {
    const { dayList, onCancel } = this.props;
    const { matList } = this.state;
    return (
      <>
        {
          <TableContainer>
            <Table
              sx={{ minWidth: 600 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {dayList.map((columnName, index) => {
                    return (
                      <TableCell align="center" sx={{ width: "12%" }}>
                        {columnName.name}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {matList.map((matrixDataRow, indexRow) => {
                  return (
                    <TableRow>
                      <TableCell
                        component="td"
                        scope="row"
                        sx={{ width: "12%" }}
                      >
                        {matrixDataRow.timeSlotName}
                      </TableCell>
                      {dayList.map((columnName, index) => {
                        let dayData = matrixDataRow.dayList.filter((item) => {
                          if (item.dayId === columnName.id) {
                            return item.dayId === columnName.id;
                          }
                        });
                        console.log("dayData");
                        console.log(dayData);
                        return (
                          <TableCell
                            sx={{
                              height: 100,
                            }}
                            onClick={(e) =>
                              dayData[0].value.length !== 0 &&
                              this.setState({
                                openTimeSlotCell: true,
                                rows: dayData[0].value,
                                day: columnName.name,
                                timeSlot: matrixDataRow.timeSlotName,
                              })
                            }
                          >
                            {dayData[0].value.length !== 0 && (
                              <>
                                {dayData[0].value.length !== 2 &&
                                  dayData[0].value.map((timeTableData) => {
                                    return (
                                      <>
                                        <LabelCompo
                                          className="text-black"
                                          style={{ fontSize: 12 }}
                                          label={
                                            timeTableData.subjectDetail +
                                            " (" +
                                            timeTableData.batchName +
                                            ")"
                                          }
                                        />
                                        <br />

                                        {dayData[0].value.length === 1 && (
                                          <>
                                            <LabelCompo
                                              className="text-black"
                                              style={{ fontSize: 12 }}
                                              label={timeTableData.teacher}
                                            />
                                            <br />
                                            <LabelCompo
                                              className="text-black"
                                              style={{ fontSize: 12 }}
                                              label={" "}
                                            />
                                            <br />
                                          </>
                                        )}
                                      </>
                                    );
                                  })}

                                {dayData[0].value.length === 2 && (
                                  <>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 12 }}
                                      label={
                                        dayData[0].value[0].subjectDetail +
                                        " (" +
                                        dayData[0].value[0].batchName +
                                        ")"
                                      }
                                    />
                                    <br />
                                    <br />
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 12 }}
                                      label={
                                        dayData[0].value[1].subjectDetail +
                                        " (" +
                                        dayData[0].value[1].batchName +
                                        ")"
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {this.state.openTimeSlotCell && (
          <ViewTimeTableCellPop
            open={this.state.openTimeSlotCell}
            handleCloses={this.handleClosesTimeSlotCell}
            rows={this.state.rows}
            columns={this.columns}
            timeSlot={this.state.timeSlot}
            day={this.state.day}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  timeTableList: state.timeTable,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MatrixForm);
