import React from "react";
import { connect } from "react-redux";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
const StudentListTable = ({
  isActionColActive,
  onSelectionChange,
  selection,
  studentList,
}) => {
  const columns = [
    { name: "index", title: "Sr. No.", minWidth: 90 },
    {
      name: "firstName",
      title: "Student Name",
      minWidth: 250,
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,
    },
    { name: "yearDetails", title: "Year Details", minWidth: 300 },
    { name: "division", title: "Division", minWidth: 150 },
    { name: "rollNo", title: "Roll No", minWidth: 150 },
  ];
  const rowPrint = (row) => {};
  return (
    <DynamicSelectTable
      rows={studentList?.studentList}
      columns={columns}
      selection={selection}
      isActionColActive={isActionColActive}
      onSelectionChange={onSelectionChange}
    />
  );
};

const mapStateToProps = (state) => ({
  studentList: state.internshipDeclaration,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StudentListTable);
