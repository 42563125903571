import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicPreview from "../../../components/Preview/DynamicPreview";
import QualificationPreview from "../ListOfCompany/QualificationPreview";
const Preview = ({ DeclarationData, GoBack, companyYearData }) => {
  const [dataToShow, setDataToShow] = useState({});
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(DeclarationData);
  console.log(companyYearData);
  console.log(dataToShow);

  useEffect(() => {
    if (DeclarationData.length !== 0) {
      let DeclarationDocs = {
        ...DeclarationData,
        companyYearData: companyYearData,
      };
      console.log(DeclarationDocs);
      setDataToShow(DeclarationDocs);
    }
  }, []);

  // [
  //   {
  //     name: "companyName",
  //     title: "Company Name",
  //     colSpan: 3,
  //   },
  // ],
  // [
  //   {
  //     name: "internshipType",
  //     title: "Internship Type",
  //     colSpan: 0,
  //   },
  //   {
  //     name: "interviewMode",
  //     title: "Interview Mode",
  //     colSpan: 0,
  //   },
  // ],

  // [
  //   {
  //     name: "venue",
  //     title: "Venue",
  //     colSpan: 3,
  //   },
  // ],
  // [
  //   {
  //     name: "yearDetails",
  //     title: "Year Details",
  //     colSpan: 0,
  //   },
  //   {
  //     name: "division",
  //     title: "Division",
  //     colSpan: 0,
  //   },
  // ],

  // [
  //   {
  //     name: "durationFromDate",
  //     title: "Internship Duration From Date",
  //     colSpan: 0,
  //   },
  //   {
  //     name: "durationToDate",
  //     title: "Internship Duration To Date",
  //     colSpan: 0,
  //   },
  // ],

  // [
  //   {
  //     name: "fromDate",
  //     title: "Declaration Valid From Date",
  //     colSpan: 0,
  //   },
  //   {
  //     name: "toDate",
  //     title: "Declaration Valid To Date",
  //     colSpan: 0,
  //   },
  // ],

  // [
  //   {
  //     name: "fromTime",
  //     title: "From Time",
  //     colSpan: 0,
  //   },
  //   {
  //     name: "toTime",
  //     title: "To Time",
  //     colSpan: 0,
  //   },
  // ],

  // [
  //   {
  //     name: "designation",
  //     title: "Designation",
  //     colSpan: 0,
  //   },
  //   {
  //     name: "stipend",
  //     title: "Stipend( ₹ Per month )",
  //     colSpan: 0,
  //   },
  // ],

  // [
  //   {
  //     name: "urlLink",
  //     title: "URL Link",
  //     colSpan: 3,
  //   },
  // ],

  // [
  //   {
  //     name: "isQualificationCriteriaRequired",
  //     title: "Qualification Criteria",
  //     colSpan: 0,
  //   },
  //   {
  //     name: "remark",
  //     title: "Remark",
  //     colSpan: 0,
  //   },
  // ],

  const title = [
    // [
    //   {
    //     name: "companyName",
    //     title: "Company Name",
    //     colSpan: 3,
    //   },
    // ],

    // [
    //   {
    //     name: "companyYearData",
    //     title: "Year Details",
    //     colSpan: 3,
    //   },
    // ],

    // [
    //   {
    //     name: "venue",
    //     title: "Venue",
    //     colSpan: 3,
    //   },
    // ],

    // [
    //   {
    //     name: "placementType",
    //     title: "Placement Type",
    //     colSpan: 0,
    //   },
    //   {
    //     name: "interviewMode",
    //     title: "Interview Mode",
    //     colSpan: 0,
    //   },
    // ],
    // [
    //   {
    //     name: "division",
    //     title: "Division",
    //     colSpan: 0,
    //   },
    //   {
    //     name: "fromDate",
    //     title: "From Date",
    //     colSpan: 0,
    //   },
    // ],
    // [
    //   {
    //     name: "toDate",
    //     title: "To Date",
    //     colSpan: 0,
    //   },
    //   {
    //     name: "fromTime",
    //     title: "From Time",
    //     colSpan: 0,
    //   },
    // ],
    // [
    //   {
    //     name: "toTime",
    //     title: "To Time",
    //     colSpan: 0,
    //   },
    //   {
    //     name: "placementPackage",
    //     title: "Package( Per annum )",
    //     colSpan: 0,
    //   },
    // ],
    // [
    //   {
    //     name: "designation",
    //     title: "Designation",
    //     colSpan: 0,
    //   },
    //   {
    //     name: "durationFromDate",
    //     title: "Duration Form",
    //     colSpan: 0,
    //   },
    // ],
    // [
    //   {
    //     name: "durationToDate",
    //     title: "Duration To",
    //     colSpan: 0,
    //   },
    //   {
    //     name: "urlLink",
    //     title: "URL Link",
    //     colSpan: 0,
    //   },
    // ],
    // [
    //   {
    //     name: "remark",
    //     title: "Remark",
    //     colSpan: 0,
    //   },
    //   {
    //     name: "isQualificationCriteriaRequired",
    //     title: "Is Qualification Required",
    //     colSpan: 0,
    //   },
    // ],

    //

    [
      {
        name: "companyName",
        title: "Company Name",
        colSpan: 3,
      },
    ],
    [
      {
        name: "placementType",
        title: "Placement Type",
        colSpan: 0,
      },
      {
        name: "interviewMode",
        title: "Placement Mode",
        colSpan: 0,
      },
    ],

    [
      {
        name: "venue",
        title: "Venue",
        colSpan: 3,
      },
    ],
    [
      {
        name: "yearDetailsName",
        title: "Year Details",
        colSpan: 0,
      },
      {
        name: "division",
        title: "Division",
        colSpan: 0,
      },
    ],

    [
      {
        name: "durationFromDate",
        title: "Placement Duration From Date",
        colSpan: 0,
      },
      {
        name: "durationToDate",
        title: "Placement Duration To Date",
        colSpan: 0,
      },
    ],

    [
      {
        name: "fromDate",
        title: "Declaration Valid From Date",
        colSpan: 0,
      },
      {
        name: "toDate",
        title: "Declaration Valid To Date",
        colSpan: 0,
      },
    ],

    [
      {
        name: "fromTime",
        title: "From Time",
        colSpan: 0,
      },
      {
        name: "toTime",
        title: "To Time",
        colSpan: 0,
      },
    ],

    [
      {
        name: "designation",
        title: "Designation",
        colSpan: 0,
      },
      {
        name: "placementPackage",
        title: "Package (₹ Lakh per annum)",
        colSpan: 0,
      },
    ],

    [
      {
        name: "urlLink",
        title: "URL Link",
        colSpan: 3,
      },
    ],

    [
      {
        name: "isQualificationCriteriaRequired",
        title: "Qualification Criteria",
        colSpan: 0,
      },
      {
        name: "remark",
        title: "Remark",
        colSpan: 0,
      },
    ],

    [
      {
        name: "isBacklogAllowed",
        title: "Gap Allowed",
        colSpan: 0,
      },
      {
        name: "backlog",
        title: "Gap In Years",
        colSpan: 0,
      },
    ],

    [
      {
        name: "mathsCriteria",
        title: "Maths Criteria",
        colSpan: 3,
      },
    ],
  ];

  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid container xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Preview of Company"
            />
          </Grid>
          <Grid item xs={2} md={6} container justifyContent="flex-end">
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back"
              onClick={GoBack}
            />
          </Grid>
        </Grid>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Step 1"
                  value="1"
                />
                <Tab
                  textColor="secondary"
                  indicatorColor="secondary"
                  label="Step 2"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid sx={{ mb: 2 }} item xs={12} sm={12} md={12} rowSpacing={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label="Basic Details"
                />
              </Grid>
              <DynamicPreview data={dataToShow} title={title} />
            </TabPanel>
            <TabPanel value="2">
              <QualificationPreview
                DeclarationData={DeclarationData}
                title={title}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
