import { Grid, TextField } from "@mui/material";
import moment from "moment";
import * as React from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { connect } from "react-redux";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import NotificationToast from "../../../components/Comman/NotificationToast";
import {
  onlyNumber,
  validEmail,
} from "../../../components/Comman/Util/Validations";
import { Loading1 } from "../../../components/Loading1";
import Navbar from "../../../components/Navbar";
import Header from "../../../components/NewHeader";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../Landing/Landing.slice";
import {
  getPrnStudentPrelogin,
  resetFlag,
  usernameParentValidate,
  validateParentMob,
} from "./ParentRegister.slice";

let docs;
class ParentRegisterForm extends React.Component {
  state = {
    id: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    contactNo: "",
    emailId: "",
    confirmPassword: "",
    enterOtp: "",
    formErrors: [],
    enterPassword: "",
    userName: "",
    prn: "",
    prnFlag: false,
    prnErrorFlag: false,
    registeredMobileFlag: false,
    valUsernmFlag: false,
    openPrnFlag: false,
    userDoesNotExistFlag: false,
    loader: false,
  };

  componentDidMount() {}

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  validateOtp = () => {
    const { enterOtp } = this.state;
    if (this.handleFormValidationMobile()) {
      if (this.handleFormValidationOtp()) {
        if (this.state.enterOtp !== null) {
          this.props.submitOtp(enterOtp);
        }
      }
    }
  };

  handleFormValidation(name) {
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      contactNo,
      emailId,
      confirmPassword,
      enterPassword,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (firstName === "") {
      formIsValid = false;
      formErrors["firstNameErr"] = myConstClass.firstNameMsg;
    }

    // if (middleName === "") {
    //   formIsValid = false;
    //   formErrors["middleNameErr"] = myConstClass.middleNameMsg;
    // }

    // if (lastName === "") {
    //   formIsValid = false;
    //   formErrors["lastNameErr"] = myConstClass.lastNameMsg;
    // }

    if (dob === "") {
      formIsValid = false;
      formErrors["dobErr"] = myConstClass.dobEmptyMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }

    if (enterPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (confirmPassword != enterPassword) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }

    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.emailIdMsg;
    }

    if (!validEmail.test(emailId)) {
      formIsValid = false;
      formErrors["emailIdErr"] = myConstClass.invalidEmailIdMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidationOtp(name) {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["enterOtpErr"] = myConstClass.otpEmptyMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleFormValidationMobile(name) {
    const { contactNo } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (contactNo === "" || contactNo.length !== 10) {
      if (contactNo === "") {
        formIsValid = false;
        formErrors["contactNoErr"] = myConstClass.contactNoMsg;
      } else if (contactNo.length <= 9 || contactNo.length > 10) {
        formIsValid = false;
        formErrors["contactNumberLenErr"] = myConstClass.contactNoLengthMsg;
      }
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handlePrnValidation(name) {
    const { prn } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (prn === "") {
      formIsValid = false;
      formErrors["prnError"] = myConstClass.prnNoMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeFromDate = (date) => {
    this.setState({
      dob: date,
    });
  };

  generateOtp = () => {
    const { contactNo } = this.state;
    if (this.handleFormValidationMobile()) {
      if (this.state.contactNo !== null) {
        this.props.saveMobile(contactNo);
      }
    }
  };

  findStudent = () => {
    console.log("in findStudent");
    const { prn } = this.state;
    if (this.handlePrnValidation()) {
      if (this.state.prn !== null) {
        this.setState({
          loader: true,
        });
        this.props.getPrnStudentPrelogin({ prn: prn }).then((response) => {
          if (response !== undefined) {
            if (!response) {
              this.setState({
                prnErrorFlag: true,
                loader: false,
              });
            } else {
              this.setState({
                prnFlag: true,
                loader: false,
              });
            }
          }
        });
      }
    }
  };

  validateParentMob = () => {
    console.log("in validateParentMob");
    const { contactNo } = this.state;
    if (this.handleFormValidationMobile()) {
      if (this.state.contactNo !== null) {
        this.setState({
          loader: true,
        });
        this.props
          .validateParentMob({ contactNo: contactNo })
          .then((response) => {
            if (response !== undefined) {
              console.log(response);
              if (!response) {
                this.setState({
                  loader: true,
                });
                this.props
                  .usernameParentValidate({ contactNo: contactNo })
                  .then((response) => {
                    if (response !== undefined) {
                      if (!response) {
                        this.setState({
                          registeredMobileFlag: true,
                          loader: false,
                        });
                        this.generateOtp();
                      } else {
                        this.setState({
                          valUsernmFlag: true,
                          loader: false,
                        });
                      }
                    }
                    // if (!response) {
                    //   this.setState({
                    //     valUsernmFlag: true,
                    //   });
                    // }
                  });
              } else {
                this.setState({
                  userDoesNotExistFlag: true,
                  loader: false,
                });
              }
            } else {
            }
          });
      }
    }
  };

  closeSnak = () => {
    this.setState({
      prnErrorFlag: false,
      valUsernmFlag: false,
      userDoesNotExistFlag: false,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      userName,
      firstName,
      middleName,
      lastName,
      contactNo,
      dob,
      emailId,
      enterPassword,
    } = this.state;
    let dateOF = moment(dob).format("DD-MM-YYYY");
    docs = {
      userName: contactNo,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      dateOfBirth: dateOF,
      email: emailId,
      password: enterPassword,
    };
    console.log(docs);
    if (this.handleFormValidation()) {
      console.log("Before OnSave");
      this.props.OnSave(docs);
    }
  };
  showPrnForm = () => {
    console.log("In showPrnForm");
    const { openPrnFlag } = this.state;
    if (openPrnFlag) {
      this.setState({
        openPrnFlag: false,
      });
    } else {
      this.setState({
        openPrnFlag: true,
      });
    }
  };

  render() {
    const {
      firstName,
      middleName,
      lastName,
      gender,
      dob,
      genderList,
      contactNo,
      emailId,
      program,
      programTitle,
      confirmPassword,
      captcha,
      enterOtp,
      userName,
      enterPassword,
      prn,
      prnErrorFlag,
      prnFlag,
      registeredMobileFlag,
      parentBasicDetailFlag,
      valUsernmFlag,
      userDoesNotExistFlag,
      openPrnFlag,
      loader,
    } = this.state;

    const {
      firstNameErr,
      // middleNameErr,
      // lastNameErr,
      dobErr,
      contactNoErr,
      enterPasswordErr,
      confirmPasswordErr,
      contactNumberLenErr,
      enterOtpErr,
      prnError,
    } = this.state.formErrors;

    const { onCancel, parentData } = this.props;
    return (
      <>
        {prnErrorFlag && (
          <NotificationToast
            open={prnErrorFlag}
            severity={"error"}
            msg={"Invalid PRN"}
            closeSnak={this.closeSnak}
          />
        )}
        {parentData?.validateUsername.error && valUsernmFlag && (
          <NotificationToast
            open={parentData?.validateUsername.error}
            severity={"error"}
            msg={parentData?.validateUsername.message}
            closeSnak={this.closeSnak}
          />
        )}
        {parentData?.getValidateMobMsg.error && userDoesNotExistFlag && (
          <NotificationToast
            open={parentData?.getValidateMobMsg.error}
            severity={"error"}
            msg={parentData?.getValidateMobMsg.message}
            closeSnak={this.closeSnak}
          />
        )}
        <div align="center">
          {/* <Header/> */}
          {loader && <Loading1 size={50} />}
          <Header />
          <Navbar />
          <div className="new_content">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Parent Registration</h3>
                    <div className="lgn_container">
                      {!this.props.basicDetailFlag && (
                        <>
                          <div className="user_lgn">
                            {openPrnFlag && (
                              <>
                                <div className="user_label">
                                  Know Your Registered Mobile No
                                </div>
                                <div className="user_label">
                                  *Enter Student PRN :
                                </div>
                                <div className="user_field">
                                  <TextField
                                    color="primary"
                                    name="prn"
                                    size="small"
                                    value={prn}
                                    onChange={this.changedHandler}
                                    fullWidth
                                    error={prnError ? true : false}
                                    helperText={prnError ? prnError : ""}
                                  />
                                </div>
                                <div className="user_label">
                                  <LabelCompo
                                    className="text-black"
                                    style={{
                                      fontSize: 12,
                                      alignItems: "flex-start",
                                      display: "flex",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                    label="Back to Registration"
                                    onClick={this.showPrnForm}
                                  />
                                </div>
                                <div
                                  style={{
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="register_btn sendOtp"
                                    onClick={this.findStudent}
                                  >
                                    Find
                                  </button>
                                </div>
                                <div
                                  className="user_label"
                                  style={{
                                    alignItems: "flex-start",
                                    display: "flex",
                                    paddingTop: 5,
                                  }}
                                >
                                  Registered Mobile No is :
                                  {parentData?.preloginPrnStud.data}
                                </div>
                              </>
                            )}
                          </div>
                          {/* {!parentData?.preloginPrnStud.error && prnFlag && ( */}
                          {!openPrnFlag && (
                            <>
                              <div className="user_lgn">
                                <div className="user_label">
                                  *Enter Your Registered Mobile No of the
                                  College:
                                </div>
                                <div className="user_field">
                                  <TextField
                                    color="primary"
                                    name="contactNo"
                                    size="small"
                                    value={contactNo}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={this.changeHandler}
                                    fullWidth
                                    disabled={
                                      registeredMobileFlag ? true : false
                                    }
                                    error={
                                      contactNoErr
                                        ? true
                                        : contactNumberLenErr
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      contactNoErr
                                        ? contactNoErr
                                        : !contactNoErr
                                        ? contactNumberLenErr
                                        : " "
                                    }
                                  />
                                </div>
                              </div>
                              {!this.props.showOtpField && (
                                <>
                                  <div className="user_label">
                                    <LabelCompo
                                      className="text-black"
                                      style={{
                                        fontSize: 12,
                                        alignItems: "flex-start",
                                        display: "flex",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      label="Forgot Registered Mobile No"
                                      onClick={this.showPrnForm}
                                    />
                                    <button
                                      type="button"
                                      className="register_btn sendOtp"
                                      onClick={this.validateParentMob}
                                    >
                                      Generate OTP
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {this.props.showOtpField && (
                            <>
                              <div className="user_lgn">
                                <div className="user_label">*Enter OTP</div>
                                <div className="user_field">
                                  <TextField
                                    color="primary"
                                    name="enterOtp"
                                    size="small"
                                    value={enterOtp}
                                    onChange={this.changeHandler}
                                    fullWidth
                                    error={enterOtpErr ? true : false}
                                    helperText={enterOtpErr ? enterOtpErr : ""}
                                    disabled={
                                      parentData.isValidateOtp &&
                                      parentData.getValidateOtpmsg ===
                                        "Otp verified.."
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {this.props.showOtpField && (
                            <>
                              <div
                                style={{
                                  display: "block",
                                  padding: " 0 10px 15px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="register_btn sendOtp"
                                  onClick={this.validateOtp}
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="register_btn sendOtp"
                                  onClick={this.generateOtp}
                                >
                                  Resend
                                </button>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {this.props.basicDetailFlag && (
                        <>
                          <div className="user_lgn">
                            <div className="user_label">Username :</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="userName"
                                size="small"
                                value={contactNo}
                                disabled={true}
                                onChange={this.changedHandler}
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="user_lgn fmlnm">
                            <div className="user_label">*First Name :</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="firstName"
                                size="small"
                                value={firstName}
                                onChange={this.changedHandler}
                                fullWidth
                                error={firstNameErr ? true : false}
                                helperText={firstNameErr ? firstNameErr : " "}
                              />
                            </div>
                          </div>
                          <div className="user_lgn fmlnm">
                            <div className="user_label">Middle Name :</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="middleName"
                                size="small"
                                value={middleName}
                                onChange={this.changedHandler}
                                fullWidth
                                // error={middleNameErr ? true : false}
                                // helperText={middleNameErr ? middleNameErr : " "}

                                error={false}
                                helperText={" "}
                              />
                            </div>
                          </div>
                          <div className="user_lgn fmlnm">
                            <div className="user_label">Last Name :</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="lastName"
                                size="small"
                                value={lastName}
                                onChange={this.changedHandler}
                                fullWidth
                                // error={lastNameErr ? true : false}
                                // helperText={lastNameErr ? lastNameErr : " "}
                                error={false}
                                helperText={" "}
                              />
                            </div>
                          </div>
                          <div className="user_lgn genDob">
                            <div className="user_label">*D.O.B :</div>
                            <div className="user_field">
                              <BasicDatePicker
                                name="dob"
                                id="dob"
                                value={dob}
                                maxDate={new Date()}
                                onChange={this.changeFromDate}
                                error={" "}
                              />
                            </div>
                          </div>
                          <div className="user_lgn genDob">
                            <div className="user_label">*Email ID :</div>
                            <div className="user_field">
                              <TextField
                                placeholder="abcd@gmail.com"
                                color="primary"
                                name="emailId"
                                size="small"
                                value={emailId}
                                onChange={this.changedHandler}
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="user_lgn genDob">
                            <div className="user_label">*Password</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="enterPassword"
                                size="small"
                                type="password"
                                value={enterPassword}
                                onChange={this.changedHandler}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                fullWidth
                                error={enterPasswordErr ? true : false}
                                helperText={
                                  enterPasswordErr ? enterPasswordErr : " "
                                }
                              />
                              <PasswordStrengthBar password={enterPassword} />
                            </div>
                          </div>
                          <div className="user_lgn genDob">
                            <div className="user_label">*Confirm Password</div>
                            <div className="user_field">
                              <TextField
                                color="primary"
                                name="confirmPassword"
                                size="small"
                                type="password"
                                value={confirmPassword}
                                onChange={this.changedHandler}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                fullWidth
                                error={confirmPasswordErr ? true : false}
                                helperText={
                                  confirmPasswordErr ? confirmPasswordErr : " "
                                }
                              />
                              <PasswordStrengthBar password={confirmPassword} />
                            </div>
                          </div>
                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.submitHandler}
                          >
                            Register
                          </button>

                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={onCancel}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item md={3}></Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  parentData: state.parentRegister,
});

const mapDispatchToProps = {
  getPrnStudentPrelogin,
  resetFlag,
  showNotification,
  validateParentMob,
  usernameParentValidate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentRegisterForm);
