import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  event: [],
  isFetch: false,

  eventAppliedStudent: [],
  isFetchEventAppliedStudent: false,

  eventCommitteeMembers: [],
  isFetchCommitteeMembers: false,

  eventStudent: [],
  isFetchEventStudent: false,

  eventStudentById: {},
  isFetchEventStudentById: false,

  divisionByEvent: [],
  isFetchDivisionByEvent: false,
};
let membersURL = endpoints.eventCommittee;
let URL = endpoints.event;
let URL1 = endpoints.eventParticipant;
const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    eventSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        event: row,
        isFetch: true,
      };
    },
    divisionByEventSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        divisionByEvent: row,
        isFetchDivisionByEvent: true,
      };
    },
    eventStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        eventStudent: row,
        isFetchEventStudent: true,
      };
    },
    eventStudentByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { bData } = action.payload;
      return {
        ...state,
        eventStudentById: bData,
        isFetchEventStudentById: true,
      };
    },
    eventAppliedStudentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        eventAppliedStudent: row,
        isFetchEventAppliedStudent: true,
      };
    },

    eventCommitteeMembersSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        eventCommitteeMembers: row,
        isFetchCommitteeMembers: true,
      };
    },
  },
});

export const {
  eventSuccess,
  eventAppliedStudentSuccess,
  eventCommitteeMembersSuccess,
  eventStudentSuccess,
  eventStudentByIdSuccess,
  divisionByEventSuccess,
} = eventSlice.actions;

export default eventSlice.reducer;

export const getEvent = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            eventDetails: data1.eventDetails,
            name: data1.name,
            venue: data1.venue,
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            applicationLastDate: data1.applicationLastDate,
            time: data1.time,
            amount: data1.amount,
            amountApplicable: data1.amountApplicable,
            isActive: data1.isActive,
            fromTo: data1.fromDate + " -" + data1.toDate,
            yearDetailIds: data1.yearDetailIds,
            documentUpload: data1.documentUpload,
            isEmployeeFeedbackGenerated: data1.isEmployeeFeedbackGenerated,
            isEmployeeNoticeGenerated: data1.isEmployeeNoticeGenerated,
            isStudentFeedbackGenerated: data1.isStudentFeedbackGenerated,
            isStudentNoticeGenerated: data1.isStudentNoticeGenerated,
          };
          row.push(bData);
          return data1;
        });
        dispatch(eventSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getEventByAcademicIdYearDetailAndDivision =
  ({ acadamicYear, YearDetail, division }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/for-external-events?academicYearId=" +
          acadamicYear +
          "&yearDetailId=" +
          YearDetail +
          "&divisionId=" +
          division,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName: data1.studentName,
              rollNo: data1.rollNo != null ? data1.rollNo : "-",
              eventName: data1.eventName,
              venue: data1.venue,
              fromToDate: data1.fromToDate,
            };
            row.push(bData);
            return data1;
          });
          dispatch(eventSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDivisionByEventId =
  ({ eventId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-division-by-event-id?eventId=" + eventId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              getYearDetailId:
                data1.getYearDetailId === null ? "" : data1.getYearDetailId,
              id: data1.id,
              isActive: data1.isActive,
              name: data1.name,
              yearDetailId: data1.yearDetailId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(divisionByEventSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getEventByFDTD =
  ({ fromDate, toDate, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/for-external-event-by-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              eventName: data1.eventName,
              venue: data1.venue,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              time: data1.eventTime,
              isActive: data1.isActive,
              academicYearId: data1.academicYearId,
              amount: data1.amount,
              yearDetailIds: data1.yearDetailIds,
              amountApplicable: data1.amountApplicable,
              applicationLastDate: data1.applicationLastDate,
              documentUpload: data1.documentUpload,
              date: data1.eventDate,
              eventDetails: data1.eventDetails,
              isEmployeeFeedbackGenerated: data1.isEmployeeFeedbackGenerated,
              isEmployeeNoticeGenerated: data1.isEmployeeNoticeGenerated,
              isStudentFeedbackGenerated: data1.isStudentFeedbackGenerated,
              isStudentNoticeGenerated: data1.isStudentNoticeGenerated,
            };
            row.push(bData);
            return data1;
          });
          dispatch(eventStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// new

export const getEventByFDTDFilter =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/for-external-event-by-limit?limit=20&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              eventName: data1.eventName,
              venue: data1.venue,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              time: data1.eventTime,
              isActive: data1.isActive,
              academicYearId: data1.academicYearId,
              amount: data1.amount,
              yearDetailIds: data1.yearDetailIds,
              amountApplicable: data1.amountApplicable,
              applicationLastDate: data1.applicationLastDate,
              documentUpload: data1.documentUpload,
              date: data1.eventDate,
              eventDetails: data1.eventDetails,
              isEmployeeFeedbackGenerated: data1.isEmployeeFeedbackGenerated,
              isEmployeeNoticeGenerated: data1.isEmployeeNoticeGenerated,
              isStudentFeedbackGenerated: data1.isStudentFeedbackGenerated,
              isStudentNoticeGenerated: data1.isStudentNoticeGenerated,
            };
            row.push(bData);
            return data1;
          });
          dispatch(eventStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentEvent = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            // eventDetails: data1.event=== null ? "" : data1.event.eventDetails,
            // name: data1.event=== null ? "" : data1.event.name,
            // venue: data1.event=== null ? "" : data1.event.venue,
            // fromDate: data1.event=== null ? "" : data1.event.fromDate,
            // toDate: data1.event=== null ? "" : data1.event.toDate,
            // time: data1.event=== null ? "" : data1.event.time,
            // amount: data1.event=== null ? "" : data1.event.amount,
            // fromTo: data1.event=== null ? "" : data1.event.fromDate + " - " + data1.event.toDate,
            eventName: data1.eventName,
            venue: data1.venue,
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            time: data1.eventTime,
            amount: data1.amountPaid,
            eventDetails: data1.eventDetails,
            // fromTo: data1.event=== null ? "" : data1.event.fromDate + " - " + data1.event.toDate,
          };
          row.push(bData);
          return data1;
        });
        dispatch(eventStudentSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getEventById =
  ({ eventId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + eventId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let bData = {
            index: index,
            id: content.id,
            eventName: content.eventName,
            venue: content.venue,
            fromDate: content.fromDate,
            toDate: content.toDate,
            time: content.eventTime,
            amount: content.amountPaid,
            eventDetails: content.eventDetails,
            userRegistration:
              content.userRegistration === null
                ? ""
                : content.userRegistration.id,
            classCourse: content.classCourse,
            instituteName: content.instituteName,
            name: content.name,
            reportingDetail:
              content.reportingDetail === null
                ? ""
                : content.reportingDetail.id,
            isEmployeeFeedbackGenerated: content.isEmployeeFeedbackGenerated,
            isEmployeeNoticeGenerated: content.isEmployeeNoticeGenerated,
            isStudentFeedbackGenerated: content.isStudentFeedbackGenerated,
            isStudentNoticeGenerated: content.isStudentNoticeGenerated,
            documentUpload: content.documentUpload
              ? content.documentUpload
              : "",
          };
          dispatch(eventStudentByIdSuccess({ bData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getEventAppliedStudent =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/get-event-participant",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              prnNo:
                data1.userRegistration.prnNo === ""
                  ? "No Data."
                  : data1.userRegistration.prnNo === null
                  ? "No Data."
                  : data1.userRegistration.prnNo,
              classCourse: data1.instituteName + "-" + data1.classCourse,
              division:
                data1.reportingDetail !== null
                  ? data1.reportingDetail.division.name
                  : "-",
              rollNo:
                data1.reportingDetail !== null
                  ? data1.reportingDetail.rollNo
                  : "-",
              fromTo: data1.fromDate + " -" + data1.toDate,
              eventName: data1.event.name,
              // isActive: data1.userRegistration.isActive,
              isStatus: data1.isCompleted === 1 ? "Completed" : "Not Completed",
            };
            row.push(bData);
            return data1;
          });
          dispatch(eventAppliedStudentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getEventByAcademicId =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-academic-year?academicYearId=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              venue: data1.venue,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              time: data1.time,
              toTime: data1.toTime,
              isActive: data1.isActive,
              academicYearId: data1.academicYearId,
              amount: data1.amount,
              yearDetailIds: data1.yearDetailIds,
              amountApplicable: data1.amountApplicable,
              applicationLastDate: data1.applicationLastDate,
              documentUpload: data1.documentUpload,
              date: data1.eventDate,
              eventDetails: data1.eventDetails,
              isEmployeeFeedbackGenerated: data1.isEmployeeFeedbackGenerated,
              isEmployeeNoticeGenerated: data1.isEmployeeNoticeGenerated,
              isStudentFeedbackGenerated: data1.isStudentFeedbackGenerated,
              isStudentNoticeGenerated: data1.isStudentNoticeGenerated,
            };
            row.push(bData);
            return data1;
          });
          dispatch(eventSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCommitteeMembers =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/get-event-committee",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              memberName: data1.memberName,
              memberDesignation: data1.memberDesignation,
              emailId: data1.emailId,
              contactNo: data1.contactNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(eventCommitteeMembersSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
