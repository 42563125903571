import { Checkbox, FormHelperText, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { pink } from "@mui/material/colors";
import { Link as RouterLink } from "react-router-dom";
import { ButtonCompo } from "../../../components/Comman/Button";
import PopUpofSelectedStudent from "./PopUpOfSelectedStudent";
import { getPreviousStudentRollList } from "./GenerateRollNo.slice";
import { connect } from "react-redux";
import CarryForwardPopup from "./CarryForwardPopup";


function descendingComparator( a, b, orderBy )
{
  if ( b[ orderBy ] < a[ orderBy ] )
  {
    return -1;
  }
  if ( b[ orderBy ] > a[ orderBy ] )
  {
    return 1;
  }
  return 0;
}

function getComparator( order, orderBy )
{
  return order === "desc"
    ? ( a, b ) => descendingComparator( a, b, orderBy )
    : ( a, b ) => -descendingComparator( a, b, orderBy );
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort( array, comparator )
{
  const stabilizedThis = array.map( ( el, index ) => [ el, index ] );
  stabilizedThis.sort( ( a, b ) =>
  {
    const order = comparator( a[ 0 ], b[ 0 ] );
    if ( order !== 0 )
    {
      return order;
    }
    return a[ 1 ] - b[ 1 ];
  } );
  return stabilizedThis.map( ( el ) => el[ 0 ] );
}

function EnhancedTableHead( props )
{
  const {
    onSelectAllClick,
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    getPreviousStudentRollList
  } = props;
  const createSortHandler = ( property ) => ( event ) =>
  {
    onRequestSort( event, property );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            sx={{
              color: pink[ 800 ],
              "&.Mui-checked": {
                color: pink[ 600 ],
              },
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>

        {headCells.map( ( headCell ) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            width={headCell.minWidth}
          >
            {headCell.title}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableCell>
        ) )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf( [ "asc", "desc" ] ).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTable( { rows, divisionList, OnSave, showButtonFlag, semisterDetail, yearDetail, getPreviousStudentRollList,studentRollNoList } )
{
  const [ selection, setSelection ] = React.useState( [] );
  const [ values, setValue ] = React.useState( [] );
  const [ valuesNew, setValueNew ] = React.useState( [] );
  const [ flag, setFlag ] = React.useState( 0 );
  const [ error, setError ] = React.useState( false );
  React.useEffect( () =>
  {
    if ( values.length !== rows )
    {
      setValue( rows );
    }
    // else if (values.length !== 0 && rows !== values) {
    //   setValue(rows);
    // }
  } );
  const columns = [
    { name: "index", title: "Sr. No.", minWidth: "10%" },
    { name: "studentName", title: "Student Name" },
    { name: "prnNo", title: "PRN Number", minWidth: "20%" },
    // { name: "rollNo", title: "Roll Number" },
    // {
    //   name: "reportingDate",
    //   title: "Reporting Date",
    // },
  ];
  const [ order, setOrder ] = React.useState( "asc" );
  const [ orderBy, setOrderBy ] = React.useState( "calories" );
  // const [selected, setSelected] = React.useState(selection);
  const [ page, setPage ] = React.useState( 0 );
  const [ dense, setDense ] = React.useState( false );
  const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 );
  const [ showSelectedStudent, setShowSelectedStudent ] = useState( false );
  const [ showSelectedStudentNew, setShowSelectedStudentNew ] = useState( false );


  const handleRequestSort = ( event, property ) =>
  {
    const isAsc = orderBy === property && order === "asc";
    setOrder( isAsc ? "desc" : "asc" );
    setOrderBy( property );
  };

  const handleSelectAllClick = ( event ) =>
  {
    if ( event.target.checked )
    {
      const newSelecteds = values.map( ( n ) => n.id );

      // setSelected(newSelecteds);
      setSelection( newSelecteds );

      return;
    }
    setSelection( [] );
    // setSelected([]);
  };

  const handleClick = ( event, name ) =>
  {
    const selectedIndex = selection.indexOf( name );
    let newSelected = [];
    if ( selectedIndex === -1 )
    {
      newSelected = newSelected.concat( selection, name );
    } else if ( selectedIndex === 0 )
    {
      newSelected = newSelected.concat( selection.slice( 1 ) );
    } else if ( selectedIndex === selection.length - 1 )
    {
      newSelected = newSelected.concat( selection.slice( 0, -1 ) );
    } else if ( selectedIndex > 0 )
    {
      newSelected = newSelected.concat(
        selection.slice( 0, selectedIndex ),
        selection.slice( selectedIndex + 1 )
      );
    }

    setSelection( newSelected );
    let selectedList = [];
    let index = 0;
    const studList = values.map( ( studlist ) =>
    {
      const selRow = newSelected.filter( ( row ) =>
      {
        return row === studlist.id;
      } );
      if ( selRow.length !== 0 )
      {
        index = index + 1;
        studlist = {
          ...studlist,
          index: index,
          rollNo: index,
        };
        return studlist;
      }
      return studlist;
    } );
    // newSelected.forEach((data,index) => {
    //   const selRow = values.filter(
    //     (row, index) => {
    //       return row.id === data;
    //     }
    //   );

    // selRow[0]={
    //   ...selRow[0],
    //   index:index+1,
    //   rollNo:index+1
    // }
    //   selectedList = [...selectedList, selRow[0]];
    // });

    setValue( studList );
  };

  const isSelected = ( name ) => selection.indexOf( name ) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - values.length ) : 0;

  const submitHandler = ( e, num ) =>
  {
    e.preventDefault();

    setFlag( num )
    if ( num == 0 )
    {

      if ( selection.length !== 0 )
      {
        setShowSelectedStudent( true );
      } else
      {
        setError( true );
      }
    }
    else
    {
      setShowSelectedStudentNew( true );
      getPreviousStudentRollList( {
        yearDetail: yearDetail,
        semisterDetail: semisterDetail,
      } )
        .then( ( response ) =>
        {

        } );
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <FormHelperText error>
          {error ? "Please select atleast one student" : " "}
        </FormHelperText>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              headCells={columns}
              numSelected={selection.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={values.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 values.slice().sort(getComparator(order, orderBy)) */}
              {stableSort( values, getComparator( order, orderBy ) )
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map( ( row, index ) =>
                {
                  const isItemSelected = isSelected( row.id );
                  const labelId = `enhanced-table-checkbox-${ index }`;
                  const changeHandler = ( id, e ) =>
                  {
                    const { value } = e.target;
                    setValue( ( data ) =>
                      data?.map( ( valueData, index ) =>
                        valueData.id === id
                          ? { ...valueData, rollNo: value }
                          : valueData
                      )
                    );
                  };
                  const onDateChange = ( id ) => ( date ) =>
                  {
                    if ( date !== null )
                    {
                      const valueData = values.map( ( valueData ) =>
                      {
                        if ( valueData.id === id )
                        {
                          return { ...valueData, reportingDate: date };
                        } else
                        {
                          return valueData;
                        }
                      } );
                      setValue( valueData );
                    }
                  };
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      changeHandler={changeHandler}
                      onDateChange={onDateChange}
                      handleClick={handleClick}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                    />
                    // <TableRow
                    //   hover

                    //   role="checkbox"
                    //   aria-checked={isItemSelected}
                    //   tabIndex={-1}
                    //   key={row.name}
                    //   selected={isItemSelected}
                    // >
                    //   <TableCell padding="checkbox">
                    //     <Checkbox
                    //       color="primary"
                    //       onClick={(event) => handleClick(event, row.id)}
                    //       checked={isItemSelected}
                    //       inputProps={{
                    //         "aria-labelledby": labelId,
                    //       }}
                    //     />
                    //   </TableCell>
                    //   <TableCell
                    //     component="th"
                    //     id={labelId}
                    //     scope="row"
                    //     padding="none"
                    //   >
                    //     {row.index}
                    //   </TableCell>

                    // </TableRow>
                  );
                } )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: ( dense ? 33 : 53 ) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div>
        <div align="right">
          <Link
            color="primary"
            variant="body2"
            component={RouterLink}
            to="/roll-list"
            sx={{ textDecoration: "none" }}
          >
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
            />
          </Link>
          &nbsp;&nbsp;
          <ButtonCompo
            size="button"
            type="button"
            variant="contained"
            name="Save and Proceed"
            onClick={( e ) => submitHandler( e, 0 )}
          />

          &nbsp;&nbsp;
          {showButtonFlag === 0 && (
            <ButtonCompo
              size="button"
              type="button"
              variant="contained"
              name="Carry Forward Roll No"
              onClick={( e ) => submitHandler( e, 1 )}
            />
          )}
        </div>
      </div>

      
      {showSelectedStudent && (
        <PopUpofSelectedStudent
          open={showSelectedStudent}
          rows={values}
          // rows={flag == 0 ? values : studentRollNoList?.rollNoListNew}
          divisionList={divisionList}
          OnSave={OnSave}
          selection={selection}
          handleCloses={() =>
          {
            setShowSelectedStudent( false );
          }}
        />
      )}

      {setShowSelectedStudentNew && (
        <CarryForwardPopup
          open={showSelectedStudentNew}
          rows={studentRollNoList?.rollNoListNew}
          divisionList={divisionList}
          OnSave={OnSave}
          selection={selection}
          handleCloses={() =>
          {
            setShowSelectedStudentNew( false );
          }}
        />
      )}

    </>
  );
}

function Row( {
  row,
  changeHandler,
  onDateChange,
  labelId,
  isItemSelected,
  handleClick,
} )
{
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onClick={( event ) => handleClick( event, row.id )}
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        // sx={{
        //   padding: "0px",
        // }}
        />
      </TableCell>

      <TableCell align={"center"}>{row.index}</TableCell>
      <TableCell>{row.studentName}</TableCell>
      <TableCell>{row.prnNo}</TableCell>
    </TableRow>
  );
}
const mapStateToProps = ( state ) => ( {
  internshipDeclarationList: state.internshipDeclaration,
  studentRollNoList: state.rollNoList,
  semisterList: state.semister,
  rollNoData: state.rollNo,
} );
const mapDispatchToProps = {
  getPreviousStudentRollList
};
export default connect( mapStateToProps, mapDispatchToProps )( EnhancedTable );