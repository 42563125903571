import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import Search from "../../components/Search";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../Comman/IconButton";
import { LabelCompo } from "./Label";
import RTSelectMultiple from "./RT/RTSelectMultiple";

class NewLandingScreenHeader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      role: "",
    };
  }
  componentDidMount() {
    let role = localStorage.getItem("role");
    if (role) {
      this.setState({
        role: role,
      });
    }
  }
  onAddButtonClick = () => {
    this.props.onAddButtonClick();
  };

  onSearch = (searchvalue) => {
    this.props.onSearch(searchvalue);
  };

  render() {
    const {
      screenTitle,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      // pdfFileName = "Pdf Report",
      // excelFileName = "Excel Report",
      onRTSelectMultipleChange,
      tableColumnsToFilter = [],
      searchValue,
      generatePDF,
      generateExcel,
      showTable,
    } = this.props;
    return (
      <>
        {showTable && (
          <>
            <Grid container spacing={1}>
              <Grid
                container
                //  sx={{ml:-2}}
                item
                xs={12}
                md={11}
                lg={11}
              >
                {/* <Search
                  sx={{ width: "100%" }}
                  searchValue={searchValue}
                  filteredRows={this.onSearch}
                /> */}
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 20 }}
                  label={screenTitle}
                />
              </Grid>

              <Grid
                container
                justifyContent="flex-end"
                item
                xs={12}
                md={1}
                lg={1}
              >
                <Grid container justifyContent="flex-end" item md xs>
                  {showAddButton && this.state.role !== "parent" && (
                    <div style={{ padding: "10px" }}>
                      <MyComponentWithIconProps
                        statusImage={AddCircleIcon}
                        sx={{ m: -1 }}
                        color="primary"
                        title="Add"
                        fontSize="large"
                        onClick={this.onAddButtonClick}
                      />
                    </div>
                  )}
                  {showPdfDownload && (
                    <div style={{ padding: "10px" }}>
                      {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={pdfFileName}
                        type="pdf"
                      /> */}

                      <FontAwesomeIconCompo
                        color="error"
                        fontSize="small"
                        title="PDF"
                        baseClassName="fas"
                        className="fa-file-pdf"
                        onClick={generatePDF}
                      />
                    </div>
                  )}
                  {showExcelDownload && (
                    <div style={{ padding: "10px" }}>
                      {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={excelFileName}
                        type="excel"
                      /> */}
                      <FontAwesomeIconCompo
                        color="secondary"
                        fontSize="small"
                        title="Excel"
                        baseClassName="fas"
                        className="fa-file-excel"
                        onClick={generateExcel}
                      />
                    </div>
                  )}
                </Grid>
                {tableColumnsToFilter?.length > 0 && (
                  <Grid item justifyContent="flex-end" xs={6} md={6} lg={3}>
                    <RTSelectMultiple
                      options={tableColumnsToFilter}
                      onChange={onRTSelectMultipleChange}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default NewLandingScreenHeader;
