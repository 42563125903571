export const classCoOrdinatorJson = {
    screenTitle: "Class Co-ordinator View",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    getListId: 'yearDetail',
    fieldMeta: [
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "yearMaster",
            getListId: 'yearDetail,acadamicYear',
            dataKey: "yearDetail",
            isMandatory: true,
        },

        {
            label: "Semester Details",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            getListFrom: 'acadamicYear,yearDetail',
            masterName: "semisterMaster",
            dataKey: "semister",
            isMandatory: true,
        },
        {
            label: "Division",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "divisionMaster",
            getListFrom: 'yearDetail',
            getListId: "division,semister,batch",
            dataKey: "division",
            isMandatory: true,
        },
        {
            label: "Batch",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "batchMaster",
            getListFrom: "division,semister,batch",
            getListId: "division,semister,batch",
            dataKey: "batch",
            isMandatory: true,
        },
        {
            label: "Subject",
            controlType: "autocomplete",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            masterName: "subjectMaster",
            getListFrom: "division,semister,batch",
            getListId: "division,semister,batch",
            dataKey: "subject",
            isMandatory: true,
        },
    ],
};
