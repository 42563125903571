import { FormHelperText, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import {
  onlyNumber,
  textOnly,
  validEmail,
  textOnlyWithSpaces,
} from "../../../components/Comman/Util/Validations";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import {
  apiGet,
  apiGetWithoutHeader,
  apiPost,
} from "../../../utils/api_service";
let docs;
class PersonalDetails extends React.Component {
  state = {
    id: 0,
    formErrors: [],
    candidatename: "",
    firstname: "",
    middleName: "",
    lastname: "",
    fatherHusband: "",
    mothername: "",
    gender: "",
    dob: "",
    bloodgroup: "",
    parentmobile: "",
    studmobile: "",
    parentemail: "",
    studemail: "",
    profilePic: "",
    logo: "",
    file: "",
    martialStatus: "",
    documentName: "",
    fileName: "",
    yearDeatilList: [],
    uploadError: false,
    upload: false,
    fileNameFlag: false,
    passingYear: "",
    course: "",
    userId: "",
    changedCurrDate: "",
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  changedHandler = (event) => {
    const { name, value } = event.target;
    if (name === "aboutMe") {
      this.setState({
        ...this.state,
        [name]: value,
      });
    } else if (event.target.value === "" || textOnly.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandlerMiddleName = (event) => {
    const { name, value } = event.target;
    if (name === "aboutMe") {
      this.setState({
        ...this.state,
        [name]: value,
      });
    } else if (
      event.target.value === "" ||
      textOnlyWithSpaces.test(event.target.value)
    ) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changedHandlerEmail = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  changeDob = (date) => {
    if (date != null) {
      this.setState({
        dob: date,
      });
    }
  };

  changeH = (event) => {
    const fileUploaded = event.target.files[0];

    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ profilePic: e.target.result });
    };
    this.setState({
      ...this.state,
      logo: fileUploaded,
    });
    reader.readAsDataURL(event.target.files[0]);
  };

  componentDidMount() {
    const { studentFillProfileList, fileData } = this.props;
    // console.log(studentFillProfileList);
    let fromYear = new Date().getFullYear();
    let month = new Date().getMonth();
    let days = new Date().getDate();
    this.setState({
      changedCurrDate: new Date(fromYear - 16, month, days),
    });
    if (Object.keys(studentFillProfileList.applicant).length !== 0) {
      let bloodGroupId = this.props.studentList?.bloodGroup.filter(
        (data) =>
          data.name === this.props.studentFillProfileList.applicant.bloodGroup
      );
      // console.log(bloodGroupId);

      const DOBArr =
        studentFillProfileList.applicant.dateOfBirth == null
          ? ""
          : studentFillProfileList.applicant.dateOfBirth.split("-");
      const DOB =
        DOBArr === "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

      if (
        studentFillProfileList.applicant.profilePic !== "" &&
        studentFillProfileList.applicant.profilePic !== null
      ) {
        apiGet({
          url:
            endpoints.fileDownload +
            "/" +
            studentFillProfileList.applicant.profilePic,
        }).then(({ data, success }) => {
          if (success) {
            let fileRes = data.data;
            this.setState({
              profilePic: fileRes,
            });
          } else {
            this.props.getAlert({
              message: myConstClass.serverMsg,
            });
          }
        });
      }

      this.setState({
        id: studentFillProfileList.applicant.id,
        candidatename: studentFillProfileList.applicant.firstName,
        middleName: studentFillProfileList.applicant.middleName
          ? studentFillProfileList.applicant.middleName
          : "",
        lastname: studentFillProfileList.applicant.lastName
          ? studentFillProfileList.applicant.lastName
          : "",
        gender: studentFillProfileList.applicant.gender,
        dob: DOB,
        studemail: studentFillProfileList.applicant.alumniEmail,
        studmobile: studentFillProfileList.applicant.alumniMobile,
        bloodgroup: bloodGroupId.length !== 0 ? bloodGroupId[0].id : "",
        fatherHusband: studentFillProfileList.applicant.fatherName,
        mothername: studentFillProfileList.applicant.motherName,
        parentmobile: studentFillProfileList.applicant.alternativeMobile,
        parentemail: "",
        martialStatus: studentFillProfileList.applicant.martialStatus,
        course: studentFillProfileList.applicant.course,
        passingYear: studentFillProfileList.applicant.passingYear,
        aboutMe: studentFillProfileList.applicant.aboutMe,
        userId: studentFillProfileList.applicant.userId,
        profilePic: fileData.filePath,
      });
    } else {
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentFillProfileList !== nextProps.studentFillProfileList &&
      nextProps.studentList.isFetchBloodGroup
    ) {
      if (
        this.props.studentFillProfileList.applicant !==
        nextProps.studentFillProfileList.applicant
      ) {
        let bloodGroupId = nextProps.studentList?.bloodGroup.filter(
          (data) =>
            data.name === nextProps.studentFillProfileList.applicant.bloodGroup
        );
        // console.log(bloodGroupId);
        const DOBArr =
          nextProps.studentFillProfileList.applicant.dateOfBirth === null
            ? ""
            : nextProps.studentFillProfileList.applicant.dateOfBirth.split("-");
        const DOB =
          DOBArr === "" ? "" : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);
        if (
          nextProps.studentFillProfileList.applicant.profilePic !== "" &&
          nextProps.studentFillProfileList.applicant.profilePic !== null
        ) {
          apiGet({
            url:
              endpoints.fileDownload +
              "/" +
              nextProps.studentFillProfileList.applicant.profilePic,
          }).then(({ data, success }) => {
            if (success) {
              let fileRes = data.data;
              this.setState({
                profilePic: fileRes,
              });
            } else {
              this.props.getAlert({
                message: myConstClass.serverMsg,
              });
            }
          });
        }
        this.setState({
          id: nextProps.studentFillProfileList.applicant.id,
          candidatename: nextProps.studentFillProfileList.applicant.firstName,
          middleName: nextProps.studentFillProfileList.applicant.middleName
            ? nextProps.studentFillProfileList.applicant.middleName
            : "",
          lastname: nextProps.studentFillProfileList.applicant.lastName
            ? nextProps.studentFillProfileList.applicant.lastName
            : "",
          gender: nextProps.studentFillProfileList.applicant.gender,
          dob: DOB,
          studemail: nextProps.studentFillProfileList.applicant.alumniEmail,
          studmobile: nextProps.studentFillProfileList.applicant.alumniMobile,
          bloodgroup: bloodGroupId.length !== 0 ? bloodGroupId[0].id : "",
          fatherHusband: nextProps.studentFillProfileList.applicant.fatherName,
          mothername: nextProps.studentFillProfileList.applicant.motherName,
          parentmobile:
            nextProps.studentFillProfileList.applicant.alternativeMobile,
          parentemail: "",
          martialStatus:
            nextProps.studentFillProfileList.applicant.martialStatus,
          course: nextProps.studentFillProfileList.applicant.course,
          passingYear: nextProps.studentFillProfileList.applicant.passingYear,
          userId: nextProps.studentFillProfileList.applicant.userId,
          aboutMe: nextProps.studentFillProfileList.applicant.aboutMe,
        });
      }
    }
  }
  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        dob: date,
      });
    }
  };

  checkEmployeeEmail = (event) => {
    const { value } = event.target;

    const { getAlert, showNotification } = this.props;
    if (value != "") {
      apiGetWithoutHeader({
        url: endpoints.checkEmail + value + "/check-unique-email-id",
      }).then(({ data, success }) => {
        if (success) {
          if (data.data.error === false) {
            showNotification({
              msg: "Email Id Unqiue",
            });
            this.setState({ formErrors: [] });
          } else {
            let formErrors = {};
            formErrors["studemailErr"] = myConstClass.emailInvalMsg;
            this.setState({ formErrors: formErrors, studemail: "" });
          }
        } else {
          getAlert({
            message: myConstClass.serverMsg,
          });
        }
      });
    }
  };

  handleFormValidation() {
    const {
      candidatename,
      middleName,
      studmobile,
      lastname,
      parentmobile,
      gender,
      parentemail,
      dob,
      changedCurrDate,
      passingYear,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    let fromYear = new Date().getFullYear();

    if (gender.toString().trim() === "" || gender === null) {
      formIsValid = false;
      formErrors["genderErr"] = myConstClass.genderMsg;
    }

    if (candidatename.toString().trim() === "" || candidatename === null) {
      formIsValid = false;
      formErrors["candidatenameErr"] = myConstClass.firstNameMsg;
    }

    if (parentemail.toString().trim() !== "" && !validEmail.test(parentemail)) {
      formIsValid = false;
      formErrors["parentemailErr"] = myConstClass.invalidEmailIdMsg;
    }

    if (dob > changedCurrDate) {
      formIsValid = false;
      formErrors["dobError"] = myConstClass.dobGTCurrentDateMsg;
    }

    // if (passingYear != "") {
    //   if (1984 < passingYear && passingYear > fromYear) {
    //     formIsValid = false;
    //     formErrors["passingYearError"] =
    //       "Passing Year must be between 1984 and current year";
    //   }
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();

    const {
      id,
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,
      bloodgroup,
      parentmobile,
      studmobile,
      studemail,
      parentemail,
      martialStatus,
      aboutMe,
      userId,
    } = this.state;
    let bloodGruopName =
      bloodgroup !== ""
        ? this.props.studentList?.bloodGroup.filter(
            (data) => data.id === bloodgroup
          )
        : [];

    let fd = dob === "" ? "" : moment(dob).format("DD-MM-YYYY");
    // console.log(this.state);
    docs = {
      id: id,
      firstName: candidatename?candidatename:"",
      middleName: middleName?middleName:"",
      lastName: lastname?lastname:"",
      motherName: mothername?mothername:"",
      gender: gender?gender:"",
      dateOfBirth: fd?fd:"",
      bloodGroup: bloodGruopName.length !== 0 ? bloodGruopName[0].name : "",
      fatherName: fatherHusband?fatherHusband:"",
      martialStatus: martialStatus?martialStatus:"",
      alternativeMobile: parentmobile?parentmobile:"",
      alternativeEmail: parentemail?parentemail:"",
      alumniMobile: studmobile?studmobile:"",
      alumniEmail: studemail?studemail:"",
      aboutMe: aboutMe?aboutMe:"",
      userId: userId,
      type: 1,
    };
    if (this.handleFormValidation()) {
      this.onSave(docs);
    }
  };

  onSave = (dataToSave) => {
    // console.log(dataToSave);
    if (this.state.logo !== "") {
      const formData = new FormData();
      formData.append("file", this.state.logo);
      apiPost({
        url: endpoints.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          dataToSave = {
            ...dataToSave,
            profilePic: data,
          };
          this.props.onSave(dataToSave);
        } else {
          this.props.getAlert({
            message: "Failed to upload document",
          });
        }
      });
    } else {
      if (
        Object.keys(this.props.studentFillProfileList.applicant).length !== 0
      ) {
        dataToSave = {
          ...dataToSave,
          profilePic: this.props.studentFillProfileList.applicant.profilePic,
        };
      }
      this.props.onSave(dataToSave);
    }
  };

  handleClick = (event) => {
    this._name.click();
  };

  render() {
    const {
      candidatename,
      middleName,
      lastname,
      fatherHusband,
      mothername,
      gender,
      dob,

      bloodgroup,

      parentmobile,
      studmobile,
      studemail,
      parentemail,

      martialStatus,

      aboutMe,
      passingYear,
      course,
    } = this.state;

    const {
      candidatenameErr,
      parentemailErr,
      altMobileErr,
      // lastNameErr,
      mobileErr,
      genderErr,
      dobError,
      passingYearError,
    } = this.state.formErrors;

    const { studentList, editForm } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid container spacing={2}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1.5}
                  lg={1.5}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <img
                      id="target"
                      src={this.state.profilePic}
                      height="100px"
                      width="100"
                      align="left"
                      style={{ display: "block", margin: " 0 auto" }}
                    />
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="Inputfile"
                      ref={(input) => {
                        return (this._name = input);
                      }}
                      onChange={this.changeH}
                      style={{ display: "none" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Select"
                      onClick={this.handleClick}
                    />
                    {/* <FileUploadIcon color='primary'/> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={10.5}
                  lg={10.5}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*First Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="candidatename"
                        size="small"
                        value={candidatename}
                        fullWidth
                        disabled={editForm ? true : false}
                        onChange={this.changedHandler}
                        helperText={candidatenameErr ? candidatenameErr : " "}
                        error={candidatenameErr ? true : false}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Middle Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="middleName"
                        size="small"
                        value={middleName}
                        fullWidth
                        disabled={editForm ? true : false}
                        onChange={this.changedHandlerMiddleName}
                        helperText={" "}
                        error={false}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="lastname"
                        size="small"
                        value={lastname}
                        disabled={editForm ? true : false}
                        fullWidth
                        onChange={this.changedHandler}
                        // helperText={lastNameErr ? lastNameErr : " "}
                        // error={lastNameErr ? true : false}

                        helperText={" "}
                        error={false}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Gender"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={-2}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="gender"
                          id="gender"
                          disabled={editForm ? true : false}
                          value={gender}
                          onChange={this.changedHandler}
                          size="small"
                          fullWidth={true}
                        >
                          <MenuItem value="m">Male</MenuItem>
                          <MenuItem value="f">Female</MenuItem>
                          <MenuItem value="t">Transgender</MenuItem>
                        </Select>
                      </FormControl>
                      {genderErr ? (
                        <FormHelperText error>{genderErr}</FormHelperText>
                      ) : (
                        <FormHelperText> </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    rowSpacing={3}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Date of Birth"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={-2}>
                      <BasicDatePicker
                        color="primary"
                        name="dob"
                        size="small"
                        value={dob}
                        maxDate={this.state.changedCurrDate}
                        disabled={editForm ? true : false}
                        fullWidth
                        onChange={this.changeFromDate}
                        isError={dobError ? true : false}
                        errorText={dobError ? dobError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                sx={{ marginTop: 2 }}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Father's/Husband's Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="fatherHusband"
                    size="small"
                    value={fatherHusband}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                sx={{ marginTop: 2 }}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Mother's Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="mothername"
                    size="small"
                    value={mothername}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Blood Group"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={bloodgroup}
                    name={"bloodgroup"}
                    options={studentList?.bloodGroup.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Marital Status"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="martialStatus"
                      id="martialStatus"
                      value={martialStatus}
                      disabled={editForm ? true : false}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value={0}>Un-Married</MenuItem>
                      <MenuItem value={1}>Married</MenuItem>
                      <MenuItem value={2}>Divorced</MenuItem>
                      <MenuItem value={3}>widowed</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Mobile No."
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studmobile"
                    size="small"
                    value={studmobile}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandler}
                    fullWidth
                    disabled
                    error={false}
                    helperText={" "}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Email-Id"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="studemail"
                    size="small"
                    value={studemail}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    disabled={true}
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}

              {/* <Grid item xs={12} sm={6} md={12}></Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Alternative Mobile No."
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentmobile"
                    size="small"
                    value={parentmobile}
                    inputProps={{ maxLength: 10 }}
                    onChange={this.changeHandler}
                    fullWidth
                    error={false}
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Alternative Email-id"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="parentemail"
                    size="small"
                    value={parentemail}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    // onBlur={this.checkEmployeeEmail}

                    helperText={parentemailErr ? parentemailErr : " "}
                    error={parentemailErr ? true : false}
                  />
                </Grid>
              </Grid>
              {/*  */}

              <Grid
               item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Course"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} sx={{mt:-3 }}>
                  <TextField
                    color="primary"
                    name="course"
                    size="small"
                    value={course}
                    disabled={true}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    // onBlur={this.checkEmployeeEmail}
                    // helperText={parentemailErr ? parentemailErr : " "}
                    // error={parentemailErr ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // rowSpacing={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Passing Year"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="passingYear"
                    size="small"
                    value={passingYear}
                    inputProps={{ maxLength: 4 }}
                    onChange={this.changeHandler}
                    fullWidth
                    error={passingYearError ? true : false}
                    helperText={passingYearError ? passingYearError : " "}
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="About Me"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="aboutMe"
                    size="small"
                    multiline
                    value={aboutMe}
                    disabled={editForm ? true : false}
                    onChange={this.changedHandlerEmail}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={1} />
            <Grid
              rowSpacing={2}
              container
              columnSpacing={1}
              justifyContent="right"
            >
              <Grid item justifyContent="space-evenly"></Grid>

              <Grid item justifyContent="space-evenly">
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save and Proceed"
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentList: state.studentList,
  departments: state.department,
  studentFillProfileList: state.studentFillProfile,
  fileData: state.file,
});

const mapDispatchToProps = {
  showNotification,
  getAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
