import endpoint from "../config/endpoints";

export const UnitTestDeclareTableTeacherSideJson = {
  apiBaseURL: endpoint.studentUnitTest,
  screenTitle: "Unit Test Declaration (Teacher)",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: true,
  fieldMetaForTable: [
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,
      masterName: "yearMaster",
      getListId: "yearDetail,acadamicYear",
      dataKey: "yearDetail",
      isMandatory: true,
    },

    {
      label: "Semester Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,
      masterName: "semisterMaster",
      getListFrom: "acadamicYear,yearDetail",
      getListId: "division,semister,batch",
      dataKey: "semister",
      isMandatory: true,
    },
    {
      label: "Division",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,
      masterName: "divisionMaster",
      getListId: "division,semister,batch",
      getListFrom: "semister",
      dataKey: "division",
      isMandatory: true,
    },
    {
      label: "Batch",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,
      masterName: "batchMaster",
      getListFrom: "division,semister",
      getListId: "batch,division,semister",
      dataKey: "batch",
      isMandatory: true,
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3,
      lg: 3,
      sm: 3,
      xs: 12,
      masterName: "subjectMasterTable",
      getListFrom: "division,semister,batch",
      dataKey: "subject",
      isMandatory: true,
    },
  ],
  fieldMeta: [
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearMaster",
      getListId: "yearDetailId",
      dataKey: "yearDetailId",
      isMandatory: true,
    },
    {
      label: "Semester Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "semisterMaster",
      dataKey: "semesterDetail",
      getListId: "semesterDetail,divisionId,batchId",
      isMandatory: true,
    },
    {
      label: "Division",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "divisionMaster",
      getListId: "divisionId,semesterDetail,batchId",
      dataKey: "divisionId",
      isMandatory: true,
    },
    {
      label: "Batch",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "batchMaster",
      getListFrom: "semesterDetail,divisionId",
      getListId: "divisionId,semesterDetail,batchId",
      dataKey: "batchId",
      isMandatory: true,
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subjectMaster",
      getListFrom: "semesterDetail,divisionId,batchId",
      dataKey: "subject",
      isMandatory: true,
    },
    {
      label: "Unit Test",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "unitTestMaster",
      dataKey: "unitTest",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
    },
    {
      label: "Maximum Marks",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "maxMarks",
      isMandatory: true,
    },
  ],
};
