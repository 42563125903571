import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { EmaterialReportJson } from "../../DynamicFormsJson/EmaterialReport";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";

class EmaterialReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        academicYear: this.props.academicYearList?.academicYear,
        yearDetails: this.props.yearDetailList?.yearDetails,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.yearDetails !==
        nextProps.yearDetailList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.yearDetailList.yearDetails,
          },
        });
      }
    }
  }

  getListById = (data) => {};
  rowData = [
    {
      index: 1,
      reportName: "E - Content of Teacher (D0, D1)",
      filter: "academicYearId,yearDetailId",
      url: "/api/report/v1/ematerial?yearDetailId={yearDetailId}&academicYearId={academicYearId}",
    },
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={EmaterialReportJson.screenTitle}
          fieldMeta={EmaterialReportJson.fieldMeta}
          buttonCenter={EmaterialReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={EmaterialReportJson.searchButton}
          PDFButton={EmaterialReportJson.PDFButton}
          EXCELButton={EmaterialReportJson.EXCELButton}
          baseIdColumn={EmaterialReportJson.baseIdColumn}
          apiBaseURL={EmaterialReportJson.apiBaseURL}
          marginTop={EmaterialReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  yearDetailList: state.internshipDeclaration,
});
const mapDispatchToProps = {
  getAcademicYear,
  getYearDetails,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmaterialReport);
