import { findAllByDisplayValue } from "@testing-library/react";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import { FilterSave } from "../../components/FilterSave";
import endpoints from "../../config/endpoints";
import { SubjectSelectionJson } from "../../DynamicFormsJson/SubjectSelection";
import { apiDelete, apiPost } from "../../utils/api_service";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getSubjectSelectList } from "./SubjectSelection.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import StudentSelectionForm from "./StudentSelectionForm";
import { resetReducList } from "../../CommonActions/User.action";
import {
  getSchemeHeadView,
  getSubjectView,
  getDataView,
} from "./SubjectSelection.slice";
import SubjectSelectionViewMatrix from "./SubjectSelectionViewMatrix";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};
class SubjectSelection extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No. ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "name",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      width: 500,
    },

    {
      name: "rollNo",
      title: "Roll Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
      width: 100,
    },

    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
        unitTestMaster: this.props.unitTestList?.unitTest,
        subjectMaster: this.props.subjectsList?.subjectBysemisterDetail,
      },
      showLoader: false,
      open: false,
      openView: false,
      timeTableHeaderData: {},
      fieldData: {},
      openForm: false,
      showView: false,
      details: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            acadamicMaster: nextProps.academicYearList.academicYear.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }

    if (this.props.subjectsList !== nextProps.subjectsList) {
      if (
        this.props.subjectsList.subjectBysemisterDetail !==
        nextProps.subjectsList.subjectBysemisterDetail
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster:
              nextProps.subjectsList.subjectBysemisterDetail.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
  }

  componentDidMount() {
    const { getYearDetails } = this.props;

    const ay = localStorage.getItem("acadamicYearId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const divisionId = localStorage.getItem("divisionId");
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (semisterDetailId && divisionId) {
      this.setState({
        showLoader: true,
      });

      this.props
        .getSubjectSelectList({
          semesterId: semisterDetailId,
          divisionId: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  }

  onBackToMainForm = () => {
    window.location.replace("/subject-selection");
  };

  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  toggleFormTableVisibility = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      openSnackbar: true,
    });
  };

  onSearchData = (dataToSearch) => {
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", dataToSearch.division);
    localStorage.setItem("divisionIds", "");
    localStorage.setItem("acadamicYearId", ay);
    FilterSave();
    this.setState({
      showLoader: true,
    });

    this.props
      .getSubjectSelectList({
        semesterId: dataToSearch.semister,
        divisionId: dataToSearch.division,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onAddButtonClick = () => {
    this.props.resetReducList();
    this.setState({
      open: true,
    });
  };

  onClickView = (row) => {
    this.setState({
      showView: true,
      openView: true,
      open: false,
      details: row,
    });
    this.props
      .getSchemeHeadView({ id: row.userRegistrationId, headerId: row.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });

    this.props
      .getSubjectView({ id: row.userRegistrationId, headerId: row.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });

    this.props
      .getDataView({ id: row.userRegistrationId, headerId: row.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onDelete = (rowdata) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: SubjectSelectionJson.apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: true,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        // this.props.getTableData();
        this.props
          .getUnitTestDetailsBySemesterAndDivisionId({
            semesterId: rowdata.semister,
            divisionId: rowdata.division,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  render() {
    const { subjectSeleted } = this.props;
    const { dynamicMasterData, open, openView, showLoader, fieldData } =
      this.state;
    return (
      <>
        {!open && !openView && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={SubjectSelectionJson.screenTitle}
            fieldMeta={SubjectSelectionJson.fieldMetaForTable}
            buttonCenter={SubjectSelectionJson.buttonCenter}
            showPdfDownload={SubjectSelectionJson.showPdfDownload}
            showExcelDownload={SubjectSelectionJson.showExcelDownload}
            pdfFileName={SubjectSelectionJson.pdfFileName}
            excelFileName={SubjectSelectionJson.excelFileName}
            tableColumnsToFilter={SubjectSelectionJson.tableColumnsToFilter}
            showAddButton={SubjectSelectionJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={this.columns}
            tableData={subjectSeleted?.studentListSub}
            showLoader={showLoader}
            getListById={this.getListById}
            showPegination={false}
            showView={true}
            showHeadEdit={false}
            showHeadDelete={true}
            rowViewData={this.onClickView}
            rowDelete={this.rowDelete}
            onDelete={this.onDelete}
            onSearchData={this.onSearchData}
            baseIdColumn={SubjectSelectionJson.baseIdColumn}
            apiBaseURL={SubjectSelectionJson.apiBaseURL}
          />
        )}

        {open && (
          <>
            <StudentSelectionForm onCancel={this.toggleFormTableVisibility} />
          </>
        )}

        {this.state.showView && (
          <>
            <SubjectSelectionViewMatrix
              onCancel={this.onBackToMainForm}
              details={this.state.details}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  subjectsList: state.subjectInfo,
  unitTestList: state.unitTestStore,
  subjectSeleted: state.subjectSelection,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getSemesterDetailsByYearAndAcademic,
  getSubjectSelectList,
  getSchemeHeadView,
  getSubjectView,
  getDataView,
  resetReducList,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectSelection);
