
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  feesHeadDetail: [],
  isFetch: false,
};
let URL = endpoints.feesHeadDetail;
const feesHeadDetailSlice = createSlice( {
  name: "class",
  initialState,
  reducers: {
    feesHeadDetailSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        feesHeadDetail: row,
        isFetch: true,
      };
    },
  },
} );

export const {
  feesHeadDetailSuccess,
} = feesHeadDetailSlice.actions;

export default feesHeadDetailSlice.reducer;

export const getFeesHeadDetail = () => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      // url: URL + '?sort={"insertdatetime": "DESC"}'
      url: URL + '?sort={"feesCollectionPref": "ASC"}'
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const { content } = data.data
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            feesTitleName: data1.feesTitle.name,
            feesTypeName: data1.feesType.name,
            name: data1.name,
            receiptTypeName: data1.receiptType.name,
            isCarryForward: data1.isCarryForward,
            feesTypeId: data1.feesType.id,
            //  sortOrderNo: data1.sortOrderNo
            feesTitleId: data1.feesTitle.id,
            feesCollectionPref: data1.feesCollectionPref,
            receiptTypeId: data1.receiptType.id,
            isActive: data1.isActive,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( feesHeadDetailSuccess( { row } ) );
      }
      return success
    } );
    return response
  } catch ( e )
  {

    return console.error( e.message );
  }
};