import {
  Grid,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Paper,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { generateExcel } from "../../../components/Comman/GenerateExcel";
import * as myConstClass from "../../../config/messageconstant";
import { generatePDF } from "../../../components/Comman/GeneratePDF";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import NewLandingScreenHeader from "../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { AlumniDonorJson } from "../../../DynamicFormsJson/AlumniDonor";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { AlumniDonorColumns } from "../../../tableColumns/table-columns";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { Loading1 } from "../../../components/Loading1";
import {
  getVolunteerById,
  getDonorByRegistrationId,
  getVolunteer,
} from "../AdminSideAlimini/AlumniVolunteerStatus/AdminVolunteerStatus.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AlumniDonor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      formErrors: {},
      showTable: true,
      showLoader: false,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      donor: "Donor",
      purpose: "",
      type: "",
      other: "",
      id: "",
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };
  //attendedSeminarList?.attendedSeminar
  componentWillReceiveProps(nextProps) {
    // if ( this.props.attendedSeminarList !== nextProps.attendedSeminarList )
    // {
    //   if (
    //     this.props.attendedSeminarList.attendedSeminar !==
    //     nextProps.attendedSeminarList.attendedSeminar
    //   )
    //   {
    //     this.setState( {
    //       dynamicMasterData: {
    //         ...this.state.dynamicMasterData,
    //         activity: nextProps.activityList.activityByType,
    //       },
    //     } );
    //   }
    // }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};
    const { getVolunteerById } = this.props;
    this.setState({
      showLoader: true,
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
    this.props.getVolunteerById().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    let fieldData = {};
    this.props.onEdit();

    AlumniDonorJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;

    this.setState({
      id: rowData.id,
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
      donor: rowData.expertise,
      purpose: rowData.message,
      type: rowData.interest,
      other: rowData.remark,
    });
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getDonorByRegistrationId({ type: "3" }).then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
    this.props.getVolunteer().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
    const { donorForm } = this.props;
    console.log(donorForm);
    this.setState({ showForm: donorForm, showTable: !donorForm });
    this.setState({
      id: "",
      donor: "Donor",
      purpose: "",
      type: "",
      other: "",
    });
  }

  onChangeStatus = (rowData, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveEmployee +
            "/update-status?userRegistrationId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.onCancle();
  };
  submitHandler = () => {
    const { id, donor, type, purpose, other } = this.state;
    if (id !== "") {
      const dataToSave = {
        id: id,
        interest: type,
        expertise: donor,
        message: purpose,
        remark: other,
        type: 3,
        status: 0,
      };
      this.onSave(dataToSave);
    } else {
      const dataToSave = {
        interest: type,
        expertise: donor,
        message: purpose,
        remark: other,
        type: 3,
        status: 0,
      };
      this.onSave(dataToSave);
    }
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;

    const dataToSave = {
      // isActive:
      //   currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      type: 0,
      status: 0,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    AlumniDonorJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        if (fieldData[currentField.dataKey] !== undefined) {
          let date = new Date(fieldData[currentField.dataKey]);

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
        }

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: AlumniDonorJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.onCancle();

        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.props.getDonorByRegistrationId({ type: "3" }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: AlumniDonorJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const {} = this.state;
        this.props.getDonorByRegistrationId({ type: "3" }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });

        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { volunteerList } = this.props;
    const { searchValue } = this.state;
    const columsFil = AlumniDonorColumns.filter((item) => {
      return item.canSearch;
    });
    console.log(volunteerList?.donorByRegistrationId);
    return volunteerList?.donorByRegistrationId.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  changeHandler = (event) => {
    const { type, hidePaper, alumniForm } = this.state;
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
      employeeDetails,
      volunteerList,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      dynamicMasterData,
      donor,
      type,
      purpose,
      other,
    } = this.state;
    const { fromDateError, toDateError } = this.state.formErrors;
    console.log(volunteerList?.donorByRegistrationId);
    return (
      <>
        {this.state.showLoader && <Loading1 />}
        {showForm && (
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              rowSpacing={2}
              columnSpacing={1}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="I would like to be a"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="donor"
                      id="donor"
                      value={donor}
                      onChange={this.changeHandler}
                      size="small"
                      fullWidth={true}
                    >
                      <MenuItem value="Donor">Donor</MenuItem>
                      <MenuItem value="Sponsor">Sponsor</MenuItem>
                      <MenuItem value="Both">Both</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              {donor === "Sponsor" || donor === "Both" ? (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Type of Sponsorship"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl color={"primary"} fullWidth={true}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="type"
                        id="type"
                        value={type}
                        onChange={this.changeHandler}
                        size="small"
                        fullWidth={true}
                      >
                        <MenuItem value="Cash">Cash</MenuItem>
                        <MenuItem value="Kind">Kind</MenuItem>
                        <MenuItem value="Both">Both</MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText> </FormHelperText>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                ></Grid>
              )}
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              rowSpacing={2}
              columnSpacing={1}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Purpose"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    {donor === "Donor" && (
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="purpose"
                        id="purpose"
                        value={purpose}
                        onChange={this.changeHandler}
                        size="small"
                        fullWidth={true}
                      >
                        <MenuItem value="Fees">Fees</MenuItem>
                        <MenuItem value="Infrastructure">
                          Infrastructure
                        </MenuItem>
                        <MenuItem value="Books">Books</MenuItem>
                        <MenuItem value="Any other">Any other</MenuItem>
                      </Select>
                    )}
                    {donor === "Sponsor" && (
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="purpose"
                        id="purpose"
                        value={purpose}
                        onChange={this.changeHandler}
                        size="small"
                        fullWidth={true}
                      >
                        <MenuItem value="Cultural Fest">Cultural Fest</MenuItem>
                        <MenuItem value="Technical Fest">
                          Technical Fest
                        </MenuItem>
                        <MenuItem value="Bal Mela">Bal Mela</MenuItem>
                        <MenuItem value="Workshops/Seminars">
                          Workshops/Seminars
                        </MenuItem>
                        <MenuItem value="Sports">Sports</MenuItem>
                        <MenuItem value="College Publicity">
                          College Publicity
                        </MenuItem>
                        <MenuItem value="Technological Support">
                          Technological Support
                        </MenuItem>
                        <MenuItem value="Equipment">Equipment</MenuItem>
                      </Select>
                    )}
                    {donor === "Both" && (
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="purpose"
                        id="purpose"
                        value={purpose}
                        onChange={this.changeHandler}
                        size="small"
                        fullWidth={true}
                      >
                        <MenuItem value="Fees">Fees</MenuItem>
                        <MenuItem value="Infrastructure">
                          Infrastructure
                        </MenuItem>
                        <MenuItem value="Books">Books</MenuItem>
                        <MenuItem value="Cultural Fest">Cultural Fest</MenuItem>
                        <MenuItem value="Technical Fest">
                          Technical Fest
                        </MenuItem>
                        <MenuItem value="Bal Mela">Bal Mela</MenuItem>
                        <MenuItem value="Workshops/Seminars">
                          Workshops/Seminars
                        </MenuItem>
                        <MenuItem value="Sports">Sports</MenuItem>
                        <MenuItem value="College Publicity">
                          College Publicity
                        </MenuItem>
                        <MenuItem value="Technological Support">
                          Technological Support
                        </MenuItem>
                        <MenuItem value="Equipment">Equipment</MenuItem>
                        <MenuItem value="Any other">Any other</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              {purpose === "Any other" ? (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Other"
                    />
                  </Grid>
                  <Grid sxitem xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="other"
                      size="small"
                      value={other}
                      fullWidth
                      onChange={this.changedHandler}
                      error={false}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                ></Grid>
              )}
            </Grid>
            <Grid
              sx={{ mr: 0.5 }}
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              m={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  onClick={this.onFormCancel}
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={1}
                lg={1}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  onClick={this.submitHandler}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </>
        )}

        {showTable && (
          <>
            <Grid item sx={{ marginTop: 1 }}>
              <RTTable
                columns={AlumniDonorColumns}
                hiddenColumnNames={tableColumnsToFilter.map((item) => {
                  return !item.isChecked ? item.columnKeyName : "";
                })}
                showAddButtonInTable={showAddButtonInTable}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                onChangeStatus={this.onChangeStatus}
                rowAdd={this.rowAdd}
                tableData={this.getFilteredTableData()}
              />
            </Grid>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  volunteerList: state.volunteerStatus,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getVolunteerById,
  getDonorByRegistrationId,
  getVolunteer,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlumniDonor);
