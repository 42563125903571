import
{
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import * as React from "react";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import * as myConstClass from "../../../config/messageconstant";
import { ButtonCompo } from "../../../components/Comman/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LabelCompo } from "../../../components/Comman/Label";
import { apiPost } from "../../../utils/api_service";
import { connect } from "react-redux";
import endpoint from "../../../config/endpoints";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { openFileInNewTab } from "../../UploadFile/file.slice";
import { resetReducList } from '../../../CommonActions/User.action'
import moment from "moment";

class AdminStatusChange extends React.Component
{
  constructor( props )
  {
    super( props );
  }

  state = {
    id: 0,
    studUserRegistrationId: [],
    rowListDetails: [],
    formErrors: [],
    status: "",
    studentName: "",
    certificateName: "",
    currentStatus: "",
    adminRemarks: "",
    documentUpload: "",
    userRemark: "",
  };

  componentDidMount()
  {
    // this.props.resetReducList();
    const { data } = this.props;

    this.setState( {
      studentName: data.name,
      certificateName: data.cetificateName,
      currentStatus: data.status,
      adminRemarks: data.remark,
      documentUpload: data.documentUpload,
      userRemark: data.userRemark,
    } );
  }



  onSave = ( dataToSave ) =>
  {
    apiPost( {
      url: endpoint.certificate + "/get-student-certificate-application-update",
      postBody: dataToSave,
    } ).then( ( { success } ) =>
    {
      if ( success )
      {
        this.props.backFromSave();
      } else
      {
        this.props.getAlert( {
          message: "Failed to save",
        } );
      }
    } );
  };

  submitHandler = ( e ) =>
  {
    if ( this.handleFormValidation() )
    {
      const { status, id, adminRemarks, documentUpload } = this.state;
      e.preventDefault();
      const { getAlert, data } = this.props;
      let docs = {
        id: data.id,
        certificateApplicationHeader: {
          id: data.certificateApplicationHeaderId,
        },
        detail: data.detail,
        is_paid: data.isPaid,
        payDetail: data.payDetail,
        amount: data.amount,
        status: status,
        documentUpload: documentUpload,
        deliveryDate: data.deliveryDate,
        applyDate: data.appliedDate,
        userRegistration: { id: data.userRegistrationId },
        is_payment_applicable: data.isPaymentApplicable,
        adminRemark: adminRemarks,
        cetificate_name: data.certificateName,
        userRemark: data.userRemark,
      };
      swal( {
        title: "Are you sure?",
        text: "Do you want to Save Information?",
        icon: "warning",
        buttons: [ "Cancel", true ],
      } ).then( ( res ) =>
      {
        if ( res )
        {
          this.onSave( docs );
        }
      } );
    }
  };

  multiSelectHandler = ( value, name ) =>
  {
    this.setState( {
      ...this.state,
      [ name ]: typeof value === "string" ? value.split( "," ) : value,
    } );
  };
  onClickViewPreview = ( fileName ) =>
  {
    this.props.openFileInNewTab( { file: fileName } ).then( ( response ) =>
    {
      if ( !response )
      {
        this.props.getAlert( { message: "Something went wrong" } );
      }
    } );
  };

  handleFormValidation()
  {
    const { status, adminRemarks } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( status === "" )
    {
      formIsValid = false;
      formErrors[ "statusError" ] = myConstClass.statusMsg;
    }
    // if ( adminRemarks.toString().trim() === "" || adminRemarks === null )
    // {
    //   formIsValid = false;
    //   formErrors[ "adminRemarksError" ] = myConstClass.remarkMsg;
    // }
    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  changedHandler = ( event ) =>
  {
    const { name, value } = event.target;
    {
      this.setState( {
        ...this.state,
        [ name ]: value,
      } );
    }
  };

  render()
  {
    const { goBack, data } = this.props;
    const {
      status,
      studentName,
      certificateName,
      currentStatus,
      adminRemarks,
      documentUpload,
    } = this.state;
    const { statusError, adminRemarksError } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            container
            item
            justifyContent="space-evenly"
            spacing={2}
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label="Change Certificate Status (By Admin)"
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Student name"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="name"
                id="name"
                value={studentName}
                fullWidth
                disabled
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Applied for certificate"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="certificateName"
                id="certificateName"
                value={certificateName}
                fullWidth
                disabled
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Current status"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="status"
                id="status"
                value={currentStatus}
                fullWidth
                disabled
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={12} md={2.7}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Show Uploaded Document"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={9.3}>
              <MyComponentWithIconProps
                statusImage={VisibilityIcon}
                color="primary"
                fontSize="medium"
                title="View"
                onClick={( e ) => this.onClickViewPreview( documentUpload )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            sx={{ marginTop: 2 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label="Remarks"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                size="small"
                color="primary"
                name="adminRemarks"
                id="adminRemarks"
                value={adminRemarks}
                onChange={this.changedHandler}
                fullWidth
                error={adminRemarksError ? true : false}
                helperText={adminRemarksError ? adminRemarksError : " "}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              sx={{ marginTop: 2, marginLeft: 2 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Change Status"
                />
              </Grid>

              <Grid xs={12} sm={12} md={12}>
                <FormControl color={"primary"} fullWidth={true}>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    className="text-black"
                    name="status"
                    id="status"
                    value={status}
                    onChange={this.changedHandler}
                    size="small"
                    fullWidth={true}
                  >
                    <MenuItem value={0}>Pending</MenuItem>
                    <MenuItem value={1}>Verify</MenuItem>
                    <MenuItem value={2}>Accepted</MenuItem>
                    <MenuItem value={3}>Prepared</MenuItem>
                    <MenuItem value={4}>Delivered</MenuItem>
                    <MenuItem value={5}>Rejected</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText error>
                  {statusError ? statusError : " "}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            container
            justifyContent="right"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            sx={{ mt: 2, ml: 3 }}
          >
            <Grid item xs={12} sm={1} md={1}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Save"
                onClick={this.submitHandler}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back"
                onClick={goBack}
              />
            </Grid>
          </Grid> */}




          <Grid
            rowSpacing={2}
            container
            columnSpacing={1}
            justifyContent="right"
          >
            <Grid item justifyContent="space-evenly">
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Cancel"
                onClick={goBack}
                fullWidth={true}
              />
            </Grid>

            <Grid item justifyContent="space-evenly">
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                fullWidth={true}
                onClick={this.submitHandler}
              />
            </Grid>
          </Grid>

        </Paper>
      </>
    );
  }
}

const mapStateToProps = ( state ) => ( {} );
const mapDispatchToProps = {
  getAlert,
  openFileInNewTab,
  resetReducList
};
export default connect( mapStateToProps, mapDispatchToProps )( AdminStatusChange );
