import endpoint from "../config/endpoints";

export const GrievanceOrComplaintJson = {
  apiBaseURL: endpoint.grievance,
  screenTitle: "Complaint List",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    // {
    //   label: "Date",
    //   controlType: "datepicker",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "date",
    //   isMAxDate: false,
    //   maxDate: new Date(),
    //   isMinDate: true,
    //   minDate: new Date(),
    //   "isMandatory": true
    // },
    {
      label: "Detail of complaints",
      controlType: "textarea",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "complaintDetail",
      isMandatory: true,
    },
    // {
    //   label: "Contact No.",
    //   controlType: "textfield",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "contactNo",
    //   isMandatory: true,
    // },
    // {
    //   label: "Email ID",
    //   controlType: "textfield",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "emailId",
    //   isMandatory: true,
    // },
  ],
};
