import { Grid, Paper, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import { getQualification } from "../../Pages/QualificationMaster/qualification.slice";
let columns = [
  {
    name: "qualificationName",
    title: "Qualification",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "yearOfPass",
    title: "Year Of Passing",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "country",
    title: "Country",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "state",
    title: "State",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },

  {
    name: "boardUniversity",
    title: "Board / University",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },

  {
    name: "obtainedMarks",
    title: "Obtained Marks",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "totalMarks",
    title: "Total Marks",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    name: "percentage",
    title: "Percentage",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },

  {
    name: "action",
    title: "Actions",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
class QualificationDetails extends Component {
  state = {
    id: 0,
    index: 0,
    qualificationName: "",
    boardUniversity: "",
    obtainedMarks: "",
    totalMarks: "",
    percentage: "",
    country: "",
    state: "",
    board: "",
    nameOfSchoolCollege: "",
    yearOfPass: "",
    formErrors: [],
  };
  changedHandlerNumber = (e) => {
    const { name, value } = e.target;
    const { obtainedMarks, totalMarks } = this.state;
    if (value === "") {
      this.setState({
        ...this.state,
        [name]: "",
      });
    }
    if (onlyNumber.test(e.target.value)) {
      if (name === "obtainedMarks" && totalMarks !== "") {
        let per = (value / totalMarks) * 100;
        this.setState({
          percentage: per.toFixed(2),
          obtainedMarks: value,
        });
      } else if (name === "totalMarks" && obtainedMarks !== "") {
        let per = (obtainedMarks / value) * 100;
        this.setState({
          percentage: per.toFixed(2),
          totalMarks: value,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    }
  };
  changedHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  handleFormValidation(name) {
    const {
      qualificationName,
      boardUniversity,
      obtainedMarks,
      totalMarks,
      yearOfPass,
      state,
      board,
      country,
      nameOfSchoolCollege,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (qualificationName.trim() === "" || qualificationName == null) {
      formIsValid = false;
      formErrors["qualificationNameError"] = myConstClass.qualificationMsg;
    }

    if (boardUniversity.trim() === "" || boardUniversity == null) {
      formIsValid = false;
      formErrors["boardUniversityError"] = myConstClass.uniNameMsg;
    }

    if (obtainedMarks.trim() === "" || obtainedMarks == null) {
      formIsValid = false;
      formErrors["obtainedMarksError"] = myConstClass.marksobtMsg;
    }

    if (state.toString().trim() === "" || state == null) {
      formIsValid = false;
      formErrors["stateError"] = myConstClass.stateMsg;
    }

    if (country.toString().trim() === "" || country == null) {
      formIsValid = false;
      formErrors["countryError"] = myConstClass.countryMsg;
    }

    if (nameOfSchoolCollege === "" || nameOfSchoolCollege == null) {
      formIsValid = false;
      formErrors["collegeError"] = "Name of School College Required";
    }

    if (totalMarks.trim() === "") {
      formIsValid = false;
      formErrors["totalMarksError"] = myConstClass.totalMarksMsg;
    }
    if (
      totalMarks !== "" &&
      obtainedMarks !== "" &&
      +obtainedMarks > +totalMarks
    ) {
      formIsValid = false;
      formErrors["obtainedMarksError"] =
        "*Obtain marks must be less than out of marks";
    }
    if (yearOfPass.trim() === "") {
      formIsValid = false;
      formErrors["yearOfPassError"] = myConstClass.passoutMsg;
    }
    if (yearOfPass.trim() !== "" && yearOfPass.length !== 4) {
      formIsValid = false;
      formErrors["yearOfPassError"] = "Maximum 4 digit required";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    getQualification();
    const { editRows } = this.props;
    if (editRows.length > 0) {
      this.setState({
        id: editRows[0].id,
        index: editRows[0].index,
        country: editRows[0].country,
        state: editRows[0].state,
        nameOfSchoolCollege: editRows[0].nameOfSchoolCollege,
        qualificationName: editRows[0].qualificationId,
        boardUniversity: editRows[0].boardUniversity,
        obtainedMarks: editRows[0].obtainedMarks,
        yearOfPass: editRows[0].yearOfPass,
        totalMarks: editRows[0].totalMarks,
        percentage: editRows[0].percentage,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    const { editRows } = this.props;
    if (editRows !== nextProps.editRows && nextProps.editRows.length !== 0) {
      this.setState({
        id: nextProps.editRows[0].id,
        index: nextProps.editRows[0].index,
        qualificationName: nextProps.editRows[0].qualificationId,
        country: nextProps.editRows[0].country,
        state: nextProps.editRows[0].state,
        nameOfSchoolCollege: nextProps.editRows[0].nameOfSchoolCollege,
        boardUniversity: nextProps.editRows[0].boardUniversity,
        obtainedMarks: nextProps.editRows[0].obtainedMarks,
        yearOfPass: nextProps.editRows[0].yearOfPass,
        totalMarks: nextProps.editRows[0].totalMarks,
        percentage: nextProps.editRows[0].percentage,
      });
    }
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      qualificationName,
      boardUniversity,
      obtainedMarks,
      totalMarks,
      percentage,
      yearOfPass,
      index,
      country,
      state,
      nameOfSchoolCollege,
      id,
    } = this.state;

    let viewRow = this.props.qualificationsList?.qualification.filter(
      (row) => row.id === qualificationName
    );
    if (this.handleFormValidation()) {
      let data = {
        index: index,
        qualificationName: viewRow[0].name,
        yearOfPass: yearOfPass,
        country: country,
        state: state,
        qualificationId: qualificationName,
        nameOfSchoolClg: nameOfSchoolCollege,
        boardUniversity: boardUniversity,
        obtainedMarks: obtainedMarks,
        totalMarks: totalMarks,
        percentage: percentage,
      };
      if (id !== 0) {
        data = {
          ...data,
          id: id,
        };
      }

      this.props.rowData(data);
      this.setState({
        index: 0,
        qualificationName: "",
        boardUniversity: "",
        obtainedMarks: "",
        totalMarks: "",
        percentage: "",
        yearOfPass: "",
        country: "",
        state: "",
        nameOfSchoolCollege: "",
        id: 0,
      });
    }

    // let docs = {
    //   university: boardUniversity,
    //   yearOfPass: yearOfPass,
    //   marksObtained: obtainedMarks,
    //   marksOutof: totalMarks,
    //   percentage: percentage,
    //   name: qualificationName,
    // };
    // this.props.qualificationData(docs);
  };

  render() {
    const {
      qualificationName,
      boardUniversity,
      obtainedMarks,
      totalMarks,
      percentage,
      yearOfPass,
      country,
      state,
      nameOfSchoolCollege,
    } = this.state;

    const {
      yearOfPassError,
      boardUniversityError,
      totalMarksError,
      obtainedMarksError,
      percentageError,
      stateError,
      countryError,
      boardError,
      qualificationNameError,
      collegeError,
    } = this.state.formErrors;

    const { row, rowView, rowDelete, rowEdit, qualificationsList } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid sx={{ marginBottom: 3 }} item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Qualification Details"
              />
            </Grid>
            {/* <Grid 
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              spacing={1}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18 }}
                    label="Qualification Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={qualificationName}
                    name={"qualificationName"}
                    options={qualificationsList?.qualification.filter((data)=>data.isActive==1)}
                    onChange={this.ChangeHandlerSearch}
                    errorText={qualificationNameError ? qualificationNameError : ""}
                    isError={qualificationNameError ? true : false}
                  />
                </Grid>
              </Grid>


              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >

                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 18 }}
                    label="Year of Passing"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="yearOfPass"
                    size="small"
                    value={yearOfPass}
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    onChange={this.changedHandlerNumber}
                    error={yearOfPassError ? true : false}
                    helperText={yearOfPassError ? yearOfPassError : " "}
                  />
                </Grid>
              </Grid>
            </Grid> */}

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              spacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Qualification Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={qualificationName}
                    name={"qualificationName"}
                    options={qualificationsList?.qualification.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                    errorText={
                      qualificationNameError ? qualificationNameError : ""
                    }
                    isError={qualificationNameError ? true : false}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Year of Passing"
                  />
                </Grid>

                <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="yearOfPass"
                    size="small"
                    value={yearOfPass}
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    onChange={this.changedHandlerNumber}
                    error={yearOfPassError ? true : false}
                    helperText={yearOfPassError ? yearOfPassError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/*  */}

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={4}
                lg={4}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Country"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="country"
                    size="small"
                    value={country}
                    fullWidth
                    onChange={this.changedHandler}
                    error={countryError ? true : false}
                    helperText={countryError ? countryError : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={4}
                lg={4}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="state"
                    size="small"
                    value={state}
                    fullWidth
                    onChange={this.changedHandler}
                    error={stateError ? true : false}
                    helperText={stateError ? stateError : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={4}
                lg={4}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="School / College"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="nameOfSchoolCollege"
                    size="small"
                    value={nameOfSchoolCollege}
                    fullWidth
                    onChange={this.changedHandler}
                    error={collegeError ? true : false}
                    helperText={collegeError ? collegeError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*  */}

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={3}
                xs={12}
                md={3}
                lg={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Board/University "
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="boardUniversity"
                    size="small"
                    value={boardUniversity}
                    fullWidth
                    onChange={this.changedHandler}
                    error={boardUniversityError ? true : false}
                    helperText={
                      boardUniversityError ? boardUniversityError : " "
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={3}
                xs={12}
                md={3}
                lg={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Obtained Marks "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="obtainedMarks"
                    size="small"
                    value={obtainedMarks}
                    fullWidth
                    type="number"
                    onChange={this.changedHandlerNumber}
                    error={obtainedMarksError ? true : false}
                    helperText={obtainedMarksError ? obtainedMarksError : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={3}
                xs={12}
                md={3}
                lg={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Total Marks "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="totalMarks"
                    size="small"
                    value={totalMarks}
                    fullWidth
                    type="number"
                    onChange={this.changedHandlerNumber}
                    error={totalMarksError ? true : false}
                    helperText={totalMarksError ? totalMarksError : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={3}
                xs={12}
                md={3}
                lg={3}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Percentage "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="percentage"
                    size="small"
                    value={percentage}
                    fullWidth
                    disabled={true}
                    error={percentageError ? true : false}
                    helperText={percentageError ? percentageError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent="right">
              {" "}
              <Grid item>
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Add"
                  fullWidth={true}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="right" sx={{ marginTop: 3 }}>
              <Grid item xs={12} sm={12} md={12}>
                <DynamicTable
                  data={row}
                  tableHead={columns}
                  showHeadDelete={true}
                  showHeadEdit={true}
                  rowEdit={rowEdit}
                  rowDelete={rowDelete}
                  rowView={rowView}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  qualificationsList: state.qualification,
});
const mapDispatchToProps = { getQualification };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualificationDetails);
