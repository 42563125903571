import { FormHelperText, Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { getDocument } from "../Masters/OnlineModule/Document/document.slice";
import { deleteFileData, saveFile } from "../UploadFile/file.slice";
let columns = [
  {
    name: "docDesc",
    title: "Document Name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: 'left'
  },

  {
    name: "action",
    title: "Actions",
    positionCenter: false,
    showInscreen: true,
    alignCenter: 'right'
  }
]
class DocumentAndQualification extends Component
{
  state = {
    id: 0,
    documentName: "",
    remark: "",
    label: "",
    fileName: "",
    file: "",
    other: "",
    addTextField: [],
    AttachFileName: [],
    AttachFileTitle: [],
    formErrors: [],
    open: false,
    upload: false,
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  changeH = ( fileName, name, index ) =>
  {
    this.setState( {
      ...this.state,
      file: fileName,
    } );
  };

  componentDidMount()
  {
    getDocument();
  }
  componentDidUpdate()
  {
    const { fileData, deleteFileData } = this.props;
    const { label } = this.state;

    let viewRow = this.props.documentList?.document.filter(
      ( row ) => row.id === label
    );

    if ( fileData.isFetch === true )
    {
      let data = {
        documentId: label,
        docDesc: viewRow[ 0 ].name,
        documentPath: fileData.file,
      };
      this.props.rowData( data );

      deleteFileData();
      this.setState( {
        ...this.state,
        label: "",
        file:'',
        upload: false,
        uploadError: false,
      } );
    }
  }

  uploadImage = () =>
  {
    const { saveFile } = this.props;
    const { file, label } = this.state;
    if ( file !== "" && label !== "" )
    {
      this.setState( {
        upload: true,
      } );
      saveFile( { file: file } );
    } else
    {
      this.setState( {
        uploadError: true,
      } );
    }
  };

  ChangeHandlerSearch = ( name, newValue ) =>
  {
    if ( newValue !== null )
    {
      this.setState( { [ name ]: newValue } );
    }
    else
    {
      this.setState( { [ name ]: '' } );
    }
  };

  render()
  {
    const { label } = this.state;
    const { row, rowView, rowDelete, documentList } = this.props;
    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Upload Documents"
                />
              </Grid>

              <Grid sx={{ mt: 5 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label="Enter Document Name"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
              

                <AutoComplete
                  keyColName={"id"}
                  value={label}
                  name={"label"}
                  options={documentList?.document.filter((data)=>data.isActive==1)}
                  onChange={this.ChangeHandlerSearch}
                />
              </Grid>
              <Grid item xs={12} sm={1.2} md={2}>
                <AttachFile
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center",
                  }}
                  name={label}
                  fileName={this.changeH}
                />
              </Grid>
              <Grid item xs={12} sm={1.3} md={2}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  fullWidth={true}
                  // {...(label !== "" &&
                  //   upload &&
                  //   !fileData.isFetch && { disable: true })}
                  name="Upload"
                  onClick={this.uploadImage}
                />
              </Grid>
              <Grid sx={{ marginTop: -3 }} item xs={12} sm={12} md={12}>
                  {this.state.uploadError && (
                    <FormHelperText error>
                      {"Please select file and Document Type"}
                    </FormHelperText>
                  )}
                </Grid>
              <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>


                <DynamicTable
                  data={row}
                  tableHead={columns}
                  showHeadDelete={true}
                  showView={true}
                  rowDelete={rowDelete}
                  rowViewData={rowView}
                />
              </Grid>
              <Grid sx={{ mt: 3 }} item xs={12} sm={12} md={12}></Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const mapStateToProps = ( state ) => ( {
  fileData: state.file,
  documentList: state.document,
} );

const mapDispatchToProps = {
  saveFile,
  deleteFileData,
  getDocument,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)( DocumentAndQualification );
