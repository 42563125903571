
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../config/endpoints';
import { apiGet } from "../../../utils/api_service";
const initialState = {
  members: [],
  membersWithProfile:[],
  isFetch: false,
};
let URL = endpoints.alumniRegister;
const membersSlice = createSlice( {
  name: "members",
  initialState,
  reducers: {
    membersSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        members: row,
        isFetch: true,
      };
    },
    membersSaveSuccess:( state = cloneDeep( initialState ), action ) =>
    {
      const { member } = action.payload;
      return {
        ...state,
        membersWithProfile: [...state.members, member],
      };
    },
  },
} );

export const {
  membersSuccess,membersSaveSuccess
} = membersSlice.actions;

export default membersSlice.reducer;

export const getMembers = () => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      url: URL + '/get-members'
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const  content  = data.data
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            name: data1.fullName,
            email:data1.email,
            profile:data1.profile,
            passingYear:data1.passingYear,
            course:data1.course

          };
          row.push( bData );
          return data1;
        } );
        dispatch( membersSuccess( { row } ) );
      }
      return success
    } );
    return response
  } catch ( e )
  {

    return console.error( e.message );
  }
};

export const saveMembersWithProfile = ({member}) => async ( dispatch ) =>
{
  try
  {
     
        dispatch( membersSaveSuccess( { member } ) );
   
  } catch ( e )
  {

    return console.error( e.message );
  }
};