import React, { useEffect, useState } from "react";
import { ButtonCompo } from "../../../components/Comman/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import { LabelCompo } from "../../../components/Comman/Label";
import {
  getUnitTestDetailsById,
  resetSuccessById,
  UnitTestTeacherFillMarks,
} from "../UnitTestMaster/unitTestMaster.slice";
import { getAlert } from "../../../CommonActions/alert.slice";

const rows = [
  {
    id: "1",
    rollNo: "1",
    studentName: "Student One ",
    marks: 20,
  },
  {
    id: "2",
    rollNo: "2",
    studentName: "Student Two ",
    marks: 20,
  },
  {
    id: "3",
    rollNo: "10",
    studentName: "Student Three ",
    marks: 20,
  },
];

const TeacherUnitTestFillMarks = ({
  unitTestList,
  fillMarksData,
  onCancle,
  timeTableHeaderData,
  getUnitTestDetailsById,
  getAlert,
  resetSuccessById,
  batchId,
  UnitTestTeacherFillMarks,
  studentList,
}) => {
  // const [values, setValues] = useState(rows)
  const [values, setValues] = useState([]);
  console.log(unitTestList?.unitTestById);
  console.log(studentList);
  useEffect(() => {
    console.log(timeTableHeaderData);
    console.log("batch : " + batchId);
    getUnitTestDetailsById({ id: timeTableHeaderData.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);
  useEffect(() => {
    if (
      values.length === 0 &&
      Object.keys(unitTestList?.unitTestById).length !== 0 &&
      Object.keys(studentList).length !== 0 &&
      unitTestList?.unitTestById.studentUnitTestDetails &&
      studentList
    ) {
      let rowList1 = [];
      let studList = [];
      console.log(studentList);
      let dataStud2 = unitTestList?.unitTestById.studentUnitTestDetails.map(
        (data2) => {
          let rowList1 = studentList.filter(
            (row) => row.id === data2.reportingDetail.id
          );
          console.log(rowList1);
          if (rowList1.length !== 0) {
            studList.push(data2);
          }
        }
      );
      console.log(studList);
      setValues(studList);
    }
  });
  const changeHandler = (id, e) => {
    const { value } = e.target;
    var numberOnly = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/;
    var alphaNumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
    console.log(alphaNumeric.test(value));
    if (numberOnly.test(value)) {
      if (value > unitTestList?.unitTestById.maxMarks) {
        setValues((data) =>
          data?.map((list, index) =>
            list.id === id ? { ...list, marks: value, marksValid: 1 } : list
          )
        );
      } else {
        setValues((data) =>
          data?.map((list, index) =>
            list.id === id ? { ...list, marks: value, marksValid: 0 } : list
          )
        );
      }
    } else if (alphaNumeric.test(value)) {
      console.log("ALPHA NUMERIC...");
      setValues((data) =>
        data?.map((list, index) =>
          list.id === id ? { ...list, marks: value, marksValid: 3 } : list
        )
      );
    } else {
      setValues((data) =>
        data?.map((list, index) =>
          list.id === id ? { ...list, marks: value, marksValid: 0 } : list
        )
      );
    }
  };
  const onCancleHandler = () => {
    console.log(values);
    resetSuccessById();
    setValues([]);
    onCancle();
  };
  const validation = () => {
    let marksFilled = [];
    const val = values.map((data1) => {
      if (data1.marksValid == 0) {
        marksFilled.push(data1);
      }
    });
    console.log(values);
    if (marksFilled.length === values.length) {
      swal({
        title: "Are you sure?",
        text: "Do you want to save the Form?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          fillMarksData(values, unitTestList?.unitTestById);
        }
      });
    }
    // swal({
    //   title: "Are you sure?",
    //   text: "Do you want to save the Form?",
    //   icon: "warning",
    //   buttons: ["Cancel", true],
    // }).then((res) => {
    //   if (res) {
    //     const val = values.map((data1) => {
    //       if (data1.marks !== "") {
    //         marksFilled.push(data1);
    //       }
    //     });
    //     if (values.length === marksFilled.length) {
    //       console.log(values);
    //       fillMarksData(values, unitTestList?.unitTestById);
    //     }
    //   }
    // });
  };
  console.log(values);
  console.log(unitTestList?.unitTestById.studentUnitTestDetails);
  console.log(studentList);
  return (
    <>
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid
          container
          justifyContent="space-evenly"
          direction="row"
          xs={12}
          sm={12}
          md={12}
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <Grid item xs={12} sm={10.7} md={10.7}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label={"Filling Student Marks"}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={1.3}
              lg={1.3}
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to list"
                fullWidth={true}
                onClick={() => {
                  onCancleHandler();
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            columnSpacing={3}
            xs={12}
            sm={6}
            md={6}
            sx={{ p: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Unit Test : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={timeTableHeaderData.unitTestHead}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ p: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Subject : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={timeTableHeaderData.subjectName}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ p: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Declare By : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={timeTableHeaderData.declareBy}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={6}
            md={6}
            columnSpacing={3}
            sx={{ p: 2 }}
          >
            <Grid item xs={12} sm={4} md={4}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Date : "}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={timeTableHeaderData.date}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            columnSpacing={3}
            sx={{ p: 2 }}
          >
            <Grid item xs={12} sm={2} md={2}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Maximum Marks : "}
              />
            </Grid>
            <Grid item xs={12} sm={10} md={10}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={timeTableHeaderData.maxMarks}
              />
            </Grid>
          </Grid>
        </Grid>
        <TableContainer sx={{ p: 2, ml: 2 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" width="10%">
                  Sr. No.
                </TableCell>
                <TableCell align="center" width="10%">
                  Roll No
                </TableCell>
                <TableCell align="center" width="30%">
                  Student Name
                </TableCell>
                <TableCell align="center" width="24%">
                  Marks
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {row.reportingDetail.rollNo}
                  </TableCell>
                  <TableCell align="left">
                    {row.userRegistration.firstName +
                      " " +
                      row.userRegistration.middleName +
                      " " +
                      row.userRegistration.lastName}
                  </TableCell>

                  <TableCell align="left">
                    <>
                      <TextField
                        color="primary"
                        name={"Percentage" + row.id}
                        size="small"
                        value={row.marks}
                        onChange={(e) => changeHandler(row.id, e)}
                        error={
                          row.marksValid == 1 ||
                          row.marksValid == 2 ||
                          row.marksValid == 3
                            ? true
                            : false
                        }
                        helperText={
                          row.marksValid == 2
                            ? "Fill the marks"
                            : row.marksValid == 1
                            ? "Filled marks must be less than max marks"
                            : row.marksValid == 3
                            ? "Filled marks must not be alpha numeric"
                            : " "
                        }
                      />
                    </>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          justifyContent="right"
          item
          xs={12}
          sm={12}
          md={12}
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Save"
              fullWidth={true}
              onClick={validation}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Back"
              fullWidth={true}
              onClick={() => {
                onCancleHandler();
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  unitTestList: state.unitTestStore,
});
const mapDispatchToProps = {
  getUnitTestDetailsById,
  getAlert,
  resetSuccessById,
  UnitTestTeacherFillMarks,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherUnitTestFillMarks);
