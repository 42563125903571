
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../config/endpoints';
import { apiGet } from "../../../../utils/api_service";

const initialState = {
  feesTitle: [],
  isFetch: false,
};
let URL = endpoints.feesTitle;
const feesTitleSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    feesTitleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        feesTitle: row,
        isFetch: true,
      };
    },
  },
});

export const {
  feesTitleSuccess,
} = feesTitleSlice.actions;

export default feesTitleSlice.reducer;

export const getFeesTitile = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}'
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,

          };
          row.push(bData);
          return data1;
        });
        dispatch(feesTitleSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {

    return console.error(e.message);
  }
};