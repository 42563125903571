import { Button } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { downloadFile, saveFile } from "../../Pages/UploadFile/file.slice";

const FileUploader = ({ fileName, style, name, index }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    fileName(event, name, index);
  };
  return (
    <>
      {/* <LabelCompo
        className="text-black"
        style={{ verticalAlign: "super", fontSize: 14 }}
        label=" Attach File"
      /> */}
      <Button
        variant="outlined"
        component="span"
        color="primary"
        onClick={handleClick}
        style={style}
      >
        Select
      </Button>
      <input
        type="file"
        accept="application/msword, application/vnd.ms-excel,text/plain, application/pdf, image/*, image/png, image/gif, image/jpeg"
        ref={hiddenFileInput}
        multiple
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  fileData: state.file,
});

const mapDispatchToProps = {
  saveFile,
  downloadFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
