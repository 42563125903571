import { Grid, Link, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import news from "../../../Images/news.png";
import { downloadFile } from "../../UploadFile/file.slice";
import { getAlert } from "../../../Alert/alert.slice";
import * as myConstClass from "../../../config/messageconstant";
import { ButtonCompo } from "../../../components/Comman/Button";
import { getNewRoomsList } from "../AdminSideAlimini/AdminAddNews/adminNews.slice";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import AdminNewsView from "../AdminSideAlimini/AdminAddNews/AdminNewsView";
class Newsroom extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    galleryList: [],
    showView: false,
    showPage: true,
    editData: "",
  };
  componentDidMount() {
    this.props.getNewRoomsList().then((response) => {
      if (!response) {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.newsRoomList?.adminNewsList !==
      nextProps.newsRoomList?.adminNewsList
    ) {
      this.setState({
        galleryList: [],
      });
      nextProps.newsRoomList?.adminNewsList.map((folderData) => {
        if (folderData.image !== null) {
          apiGet({
            url: endpoint.fileDownload + "/" + folderData.image,
          }).then(({ data, success }) => {
            if (success) {
              folderData = {
                ...folderData,
                profile: data.data,
              };
              this.setState({
                galleryList: [...this.state.galleryList, folderData],
              });
            } else {
              folderData = {
                ...folderData,
              };
              this.setState({
                galleryList: [...this.state.galleryList, folderData],
              });
            }
          });
        }
      });
    }
  }
  readMore = (data) => {
    console.log("In read more");
    this.setState({
      showView: true,
      showPage: false,
      editData: data,
    });
  };
  backToForm = () => {
    this.setState({
      showView: false,
      showPage: true,
      editData: "",
    });
  };

  render() {
    const { galleryList, showView, showPage, editData } = this.state;
    return (
      <>
        {showPage && (
          <Paper sx={{ p: 0, borderRadius: 2 }} elevation={0}>
            <div align="center">
              <div className="new_content">
                <h3 className="event_title1">
                  <span>News</span>room
                </h3>
                {galleryList.map((data) => {
                  return (
                    <div className="news_detail">
                      <div className="det_news_pic pic">
                        <img
                          src={
                            data.profile
                            // data.documentName === "" ? news : data.documentName
                          }
                        ></img>
                      </div>
                      <div className="admission_cont detil">
                        <h6 className="event_sub_nm">
                          {data.title}
                          {/* <span>{data.date}</span> */}
                        </h6>

                        <h6 class="news_time latest">
                          <span class="blue">Posted on </span>
                          {data.date}
                        </h6>
                      </div>
                      <p className="admission_txt">
                        {data.description.slice(0, 225) + "..."}
                      </p>
                      <Link
                        target="_blank"
                        underline="hover"
                        color="inherit"
                        href={data.link}
                      >
                        {data.link}
                      </Link>

                      <Grid
                        item
                        xs={12}
                        sm={1.6}
                        md={1.6}
                        lg={1.6}
                        container
                        justifyContent="flex-end"
                      >
                        <Grid
                          item
                          sx={{ marginTop: 1 }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            name="Read more"
                            onClick={() => this.readMore(data)}
                            fullWidth={true}
                          />
                        </Grid>
                      </Grid>
                      <br />
                    </div>
                  );
                })}
              </div>
            </div>
          </Paper>
        )}

        {showView && (
          <>
            <AdminNewsView
              onCancel={this.backToForm}
              editData={this.state.editData}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  newsRoomList: state.newsRoom,
});

const mapDispatchToProps = {
  getNewRoomsList,
  getAlert,
  downloadFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Newsroom);

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(styles)());
