import { Grid, Paper, Link } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../components/Comman/Button";
import endpoint from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
import { downloadFile } from "../../../UploadFile/file.slice";
import { openFileInNewTab } from "../../../UploadFile/file.slice";
import { getNewRoomsList } from "../../AdminSideAlimini/AdminAddNews/adminNews.slice";
class AdminNewsView extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    galleryList: [],
  };
  componentDidMount() {
    const { editData } = this.props;
    let dataToSet = {};
    if (Object.keys(editData).length !== 0) {
      this.setState({
        galleryList: editData,
      });
      if (editData.image !== null) {
        apiGet({
          url: endpoint.fileDownload + "/" + editData.image,
        }).then(({ data, success }) => {
          if (success) {
            dataToSet = {
              ...editData,
              profile: data.data,
            };
            this.setState({
              galleryList: dataToSet,
            });
          } else {
            this.setState({
              galleryList: editData,
            });
          }
        });
      } else {
        dataToSet = {
          ...editData,
          profile: "",
        };
        this.setState({
          galleryList: dataToSet,
        });
      }
    }
  }

  imageHandler = (data) => {
    console.log(data);
    this.props.openFileInNewTab({ file: data.image }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  linkHandler = (data) => {
    window.open(data.link);
  };

  render() {
    const { galleryList } = this.state;
    const { onCancel, onClickView } = this.props;
    console.log(galleryList);
    return (
      <>
        <Paper sx={{ p: 0, borderRadius: 2 }} elevation={0}>
          <div align="center">
            <div className="new_content">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                  sm={10.4}
                  md={10.4}
                  lg={10.4}
                  container
                  justifyContent="flex-end"
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sm={1.6}
                  md={1.6}
                  lg={1.6}
                  container
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back to list"
                      onClick={onCancel}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <div className="news_detail_new">
                <div className="admission_cont detil">
                  <h6 className="event_sub_nm">{galleryList.title}</h6>

                  <h6 class="news_time latest">
                    <span class="blue">Event Date </span>
                    {galleryList.eventDate}
                  </h6>
                </div>
                <p className="admission_txt">{galleryList.description}</p>
                <p className="admission_txt">
                  <Link
                    target="_blank"
                    underline="hover"
                    color="inherit"
                    href={galleryList.link}
                  >
                    {galleryList.link}
                  </Link>
                </p>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="flex-start"
                  rowSpacing={1}
                  columnSpacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    container
                    justifyContent="flex-start"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="View Document"
                        onClick={() => this.imageHandler(galleryList)}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    container
                    justifyContent="flex-start"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={10}
                    lg={10}
                    container
                    justifyContent="flex-start"
                  ></Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getNewRoomsList,
  getAlert,
  downloadFile,
  openFileInNewTab,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminNewsView);

// <div className="det_news_pic pic">
// <img
//   src={galleryList.profile}
//   onClick={() => this.imageHandler(galleryList)}
// ></img>
// </div>
