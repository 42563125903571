import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Paper, Switch, Tooltip } from "@mui/material";
import React, { Component } from "react";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import Search from "../../components/Search";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../Comman/IconButton";
import { ButtonCompo } from "./Button";
import RTSelectMultiple from "./RT/RTSelectMultiple";
import { LabelCompo } from "./Label";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
class LandingScreenHeader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      role: "",
    };
  }
  componentDidMount() {
    let role = localStorage.getItem("role");
    if (role) {
      this.setState({
        role: role,
      });
    }
  }
  onAddButtonClick = () => {
    this.props.onAddButtonClick();
  };

  onSearch = (searchvalue) => {
    this.props.onSearch(searchvalue);
  };

  render() {
    const {
      screenTitle,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      // pdfFileName = "Pdf Report",
      // excelFileName = "Excel Report",
      onRTSelectMultipleChange,
      tableColumnsToFilter = [],
      searchValue,
      generatePDF,
      generateExcel,
      showTable,
      showSearchBox = true,
      showSearch = true,
      showBackToListBtn = false,
      onBackToList,
      showSwitchButton = false,
      handleChange,
      checked,
      title,
    } = this.props;
    return (
      <>
        <div>
          {screenTitle !== "" && (
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <TitleAndBreadCrum title={screenTitle} />
                {showBackToListBtn && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1.5}
                    lg={1.5}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back"
                      onClick={onBackToList}
                      fullWidth={true}
                    />
                  </Grid>
                )}
              </Grid>
            </Paper>
          )}

          {showTable && (
            <>
              {showSearch && (
                <>
                  {screenTitle !== "" && <br></br>}
                  <Paper sx={{ p: 1, pt: 2, borderRadius: 2 }} elevation={0}>
                    <Grid container spacing={3}>
                      <Grid
                        container
                        sx={{ ml: -2 }}
                        item
                        xs={12}
                        md={4}
                        lg={3}
                      >
                        {showSearchBox && (
                          <Search
                            sx={{ width: "100%" }}
                            searchValue={searchValue}
                            filteredRows={this.onSearch}
                          />
                        )}
                      </Grid>

                      <Grid
                        container
                        justifyContent="flex-end"
                        item
                        xs={12}
                        md={8}
                        lg={9}
                      >
                        <Grid container justifyContent="flex-end" item md xs>
                          {showSwitchButton && (
                            <div style={{ padding: "10px" }}>
                              <Tooltip placement="bottom" title={title}>
                                <Switch
                                  checked={checked}
                                  onChange={handleChange}
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{ m: -1 }}
                                />
                              </Tooltip>
                            </div>
                          )}
                          {showAddButton && this.state.role !== "parent" && (
                            <div style={{ padding: "10px" }}>
                              <MyComponentWithIconProps
                                statusImage={AddCircleIcon}
                                sx={{ m: -1 }}
                                color="primary"
                                title="Add"
                                fontSize="large"
                                onClick={this.onAddButtonClick}
                              />
                            </div>
                          )}
                          {showPdfDownload && (
                            <div style={{ padding: "10px" }}>
                              {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={pdfFileName}
                        type="pdf"
                      /> */}

                              <FontAwesomeIconCompo
                                color="error"
                                fontSize="small"
                                title="PDF"
                                baseClassName="fas"
                                className="fa-file-pdf"
                                onClick={generatePDF}
                              />
                            </div>
                          )}
                          {showExcelDownload && (
                            <div style={{ padding: "10px" }}>
                              {/* <ClassExcelData
                        csvData={[]}
                        classesList={[]}
                        fileName={excelFileName}
                        type="excel"
                      /> */}
                              <FontAwesomeIconCompo
                                color="secondary"
                                fontSize="small"
                                title="Excel"
                                baseClassName="fas"
                                className="fa-file-excel"
                                onClick={generateExcel}
                              />
                            </div>
                          )}
                        </Grid>
                        {tableColumnsToFilter?.length > 0 && (
                          <Grid
                            item
                            justifyContent="flex-end"
                            xs={6}
                            md={6}
                            lg={3}
                          >
                            <RTSelectMultiple
                              options={tableColumnsToFilter}
                              onChange={onRTSelectMultipleChange}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default LandingScreenHeader;
