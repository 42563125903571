import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { NaacReportJson } from "../../DynamicFormsJson/NaacReports";
// import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getActivity } from "../Masters/Faculty/AcademicDetails/Activity/activity.slice";
import { getCompany } from "../Masters/Faculty/Company/company.slice";
import { getAllEmployees } from "../EmployeeDetails/employee.slice";
import {
  getProgramTypeInstituteWise,
  getProgramTitleByProgTypeId,
} from "../Masters/OnlineModule/ProgramType/programType.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";

class NaacReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        studentStatus: [
          {
            id: "1",
            name: "Selected Student List",
            isActive: "Active",
          },
          {
            id: "2",
            name: "Completed Student List",
            isActive: "Active",
          },
        ],
        academicYear: this.props.academicYearList?.academicYear,
        activity: this.props.activityList.activity,
        teacher: this.props.employeeDetails.employeeList,
        programTitle: this.props.programTypeList?.progTitleByProgType,
        programType: this.props.programTypeList?.programTypeInstituteWise,
        yearDetails: this.props.yearDetailList?.yearDetails,
        division: this.props.yearDetailList?.distinctDivision,
        company: this.props.companyList?.company,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getActivity().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getAllEmployees().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getProgramTypeInstituteWise().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getCompany().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.activity !== nextProps.activityList.activity
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: nextProps.activityList.activity,
          },
        });
      }
    }
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employeeList !==
        nextProps.employeeDetails.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacher: nextProps.employeeDetails.employeeList,
          },
        });
      }
    }
    if (this.props.programTypeList !== nextProps.programTypeList) {
      if (
        this.props.programTypeList.programTypeInstituteWise !==
        nextProps.programTypeList.programTypeInstituteWise
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programType: nextProps.programTypeList.programTypeInstituteWise,
          },
        });
      }
    }
    if (this.props.programTypeList !== nextProps.programTypeList) {
      if (
        this.props.programTypeList.progTitleByProgType !==
        nextProps.programTypeList.progTitleByProgType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            programTitle: nextProps.programTypeList.progTitleByProgType,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.yearDetails !==
        nextProps.yearDetailList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.yearDetailList.yearDetails,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.distinctDivision !==
        nextProps.yearDetailList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            division: nextProps.yearDetailList.distinctDivision,
          },
        });
      }
    }
    if (this.props.companyList !== nextProps.companyList) {
      if (this.props.companyList.company !== nextProps.companyList.company) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            company: nextProps.companyList.company,
          },
        });
      }
    }
  }

  getListById = (data) => {
    if (data.programType) {
      this.props
        .getProgramTitleByProgTypeId({
          progTypeId: data.programType,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.yearDetailId) {
      this.props
        .getDistinctDivision({
          yearDetail: data.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  rowData = [
    {
      index: 1,
      reportName: "Details of Teacher Awarded (D0)",
      filter: "academicYearId",
      url: "/api/report/v1/employee-award?academicYearId={academicYearId}",
    },
    {
      index: 2,
      reportName: "Extended Activities of Teacher (D0, D6)",
      filter: "academicYearId,activityId",
      url: "/api/report/v1/employee-extended-activities?academicYearId={academicYearId}&employeeActivityId={activityId}",
    },
    {
      index: 3,
      reportName: "Details of Teacher (D0)",
      filter: "academicYearId",
      url: "/api/report/v1/employee-details?academicYearId={academicYearId}",
    },
    {
      index: 4,
      reportName:
        "Details of reserch paper per teacher in CARE journal notified on UGC website (D0, D7)",
      filter: "academicYearId,teacherId",
      url: "/api/report/v1/employee-publication?academicYearId={academicYearId}&userRegistrationId={teacherId}",
    },
    {
      index: 5,
      reportName: "Institute report program-title wise (D5)",
      filter: "programTitle",
      url: "/api/report/v1/program-title-report?ProgramTitleIds={programTitle}",
    },
    {
      index: 6,
      reportName: "Enrollment Details Report (D0, D1, D3)",
      filter: "academicYearId,yearDetailId,divisionIds",
      url: "/api/report/v1/enrollment-detail-report/get-daily-fees-collection-report?academicYearId={academicYearId}&divisionIds={divisionIds}",
    },
    {
      index: 7,
      reportName: "Admission Status Summary Report (D0, D2)",
      filter: "academicYearId,yearDetailIds",
      url: "/api/report/v1/admission-status-report/get-admission-status-report?academicYearId={academicYearId}&yearDetailIds={yearDetailIds}",
    },
    {
      index: 8,
      reportName: "E - Content of Teacher (D0, D1)",
      filter: "academicYearId,yearDetailId",
      url: "/api/report/v1/ematerial?yearDetailId={yearDetailId}&academicYearId={academicYearId}",
    },
    {
      index: 9,
      reportName: "Internship List by Company (D0, D1, D8)",
      filter: "academicYearId,yearDetailId,studentStatus",
      url: "/api/report/v1/internship-list-with-company-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&status={studentStatus}",
    },
    {
      index: 10,
      reportName: "Placement List by Company (D0, D1, D3, D9)",
      filter: "academicYearId,yearDetailId,divisionIds,companyId",
      url: "/api/report/v1/placement-by-company-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionIds={divisionIds}&companyId={companyId}",
    },
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={NaacReportJson.screenTitle}
          fieldMeta={NaacReportJson.fieldMeta}
          buttonCenter={NaacReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={NaacReportJson.searchButton}
          PDFButton={NaacReportJson.PDFButton}
          EXCELButton={NaacReportJson.EXCELButton}
          baseIdColumn={NaacReportJson.baseIdColumn}
          apiBaseURL={NaacReportJson.apiBaseURL}
          marginTop={NaacReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  activityList: state.activity,
  employeeDetails: state.employeeList,
  programTypeList: state.programType,
  yearDetailList: state.internshipDeclaration,
  companyList: state.company,
});
const mapDispatchToProps = {
  getAcademicYear,
  getActivity,
  getAllEmployees,
  getProgramTypeInstituteWise,
  getProgramTitleByProgTypeId,
  getDistinctDivision,
  getCompany,
  getYearDetails,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(NaacReport);
