import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Component } from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../../components/Comman/Label";
import ViewTimeTableCellPop from "../GenerateTimeTable/ViewTimeTableCellPop";
class MatrixForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixData: [],
      matList: [],
      columnList: [],
      openTimeSlotCell: false,
      rows: [],
      value: [],
      timeSlot: "",
      day: "",
    };
  }

  componentDidMount() {
    const { timeTableDetails, timeSlotList } = this.props;
    this.createMatrix(timeTableDetails, timeSlotList);
  }
  createMatrix(timeTableDetails, timeSlotList) {
    const { dayList } = this.props;
    let matrixData = [];
    if (this.state.matList.length === 0) {
      timeSlotList.map((timeSlotData) => {
        let matData;
        matData = {
          timeSlotId: timeSlotData.id,
          timeSlotName: timeSlotData.fromTime + " - " + timeSlotData.toTime,
          isRecesses: timeSlotData.isRecesses,
        };
        const daysData = dayList.map((dayData) => {
          let timeTableDetailsData = timeTableDetails.filter(
            (item) =>
              item.day === dayData.id && item.timeSlot === timeSlotData.id
          );
          if (timeTableDetailsData.length !== 0) {
            let dataList = [];
            timeTableDetailsData.map((timeTableDetailsDetails) => {
              let matValue = {
                divisionName: timeTableDetailsDetails.divisionName,
                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,
                batchShortName: timeTableDetailsDetails.batchShortName,

                teacher: timeTableDetailsDetails.teacher,
                lastName: timeTableDetailsDetails.lastName,
                firstName: timeTableDetailsDetails.firstName,
                fatherName: timeTableDetailsDetails.fatherName,
                motherName: timeTableDetailsDetails.motherName,
                middleName: timeTableDetailsDetails.middleName,
  
                teacherName: timeTableDetailsDetails.teacherName,
                teacherShortName: timeTableDetailsDetails.teacherShortName,

                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectDetailName: timeTableDetailsDetails.subjectDetailName,
                subjectName: timeTableDetailsDetails.subjectName,

                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                id: timeTableDetailsDetails.id,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };
              dataList.push(matValue);
            });

            let days = {
              dayId: dayData.id,
              dayName: dayData.name,
              value: dataList,
            };
            return days;
          }
          let days = {
            dayId: dayData.id,
            dayName: dayData.name,
            value: [],
          };
          return days;
        });
        matData = { ...matData, dayList: daysData };
        matrixData.push(matData);
      });
    } else {
      this.state.matList.map((timeSlotData) => {
        let matData;
        matData = {
          timeSlotId: timeSlotData.timeSlotId,
          timeSlotName: timeSlotData.timeSlotName,
          isRecesses: timeSlotData.isRecesses,
        };
        const daysData = timeSlotData.dayList.map((dayData) => {
          let timeTableDetailsData = timeTableDetails.filter(
            (item) =>
              item.day === dayData.dayId &&
              item.timeSlot === timeSlotData.timeSlotId
          );

          if (timeTableDetailsData.length !== 0) {
            let dataList = [];
            timeTableDetailsData.map((timeTableDetailsDetails) => {
              let matValue = {
                divisionName: timeTableDetailsDetails.divisionName,
                batch: timeTableDetailsDetails.batch,
                batchName: timeTableDetailsDetails.batchName,
                batchShortName: timeTableDetailsDetails.batchShortName,

                teacher: timeTableDetailsDetails.teacher,
                lastName: timeTableDetailsDetails.lastName,
                firstName: timeTableDetailsDetails.firstName,
                fatherName: timeTableDetailsDetails.fatherName,
                motherName: timeTableDetailsDetails.motherName,
                middleName: timeTableDetailsDetails.middleName,
  
                teacherName: timeTableDetailsDetails.teacherName,
                teacherShortName: timeTableDetailsDetails.teacherShortName,

                subjectDetail: timeTableDetailsDetails.subjectDetail,
                subjectDetailName: timeTableDetailsDetails.subjectDetailName,
                subjectName: timeTableDetailsDetails.subjectName,

                floorNo: timeTableDetailsDetails.floorNo,
                floorName: timeTableDetailsDetails.floorName,

                roomNo: timeTableDetailsDetails.roomNo,
                roomsName: timeTableDetailsDetails.roomsName,

                department: timeTableDetailsDetails.departmentId,
                departmentName: timeTableDetailsDetails.departmentName,

                id: timeTableDetailsDetails.id,

                timeSlot: timeTableDetailsDetails.timeSlot,
                day: timeTableDetailsDetails.day,
              };
              dataList.push(matValue);
            });

            let days = {
              dayId: dayData.dayId,
              dayName: dayData.dayName,
              value: dataList,
            };
            return days;
          }
          let days = {
            dayId: dayData.dayId,
            dayName: dayData.dayName,
            value: [],
          };
          return days;
        });
        matData = { ...matData, dayList: daysData };
        matrixData.push(matData);
      });
    }
    let matList1 = [];
    let matList2 = matrixData.map((data) => {
      data.dayList.map((data2) => {
        if (data2.value.length !== 0 || data.isRecesses === 1) {
          matList1.push(data);
        }
      });
    });
    console.log(matList1);
    const uniqueTags = [];
    matList1.map((item) => {
      var findItem = uniqueTags.find(
        (x) => x.timeSlotName === item.timeSlotName
      );
      if (!findItem) uniqueTags.push(item);
    });

    this.setState({ matList: uniqueTags });
    // this.setState({ matList: matrixData });
    console.log("....................");
    console.log(matrixData);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.timeTableDetails !== nextProps.timeTableDetails) {
      this.createMatrix(nextProps.timeTableDetails, this.props.timeSlotList);
    }
  }

  handleClosesTimeSlotCell = () => {
    this.setState({
      openTimeSlotCell: false,
      rows: [],
    });
  };

  columns = [
    {
      name: "batchName",
      title: "Batch",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "firstName",
      title: "Teacher Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 1,
    },
    {
      name: "subjectDetailName",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "floorName",
      title: "Floor Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "roomsName",
      title: "Room Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  render() {
    const { dayList, onCancel } = this.props;
    const { matList } = this.state;
    return (
      <>
        {
          <TableContainer>
            <Table
              // sx={{ minWidth: 600 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {dayList.map((columnName, index) => {
                    return (
                      <TableCell align="center" sx={{ width: "12%" }}>
                        {columnName.name}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log(matList)}
                {matList.map((matrixDataRow, indexRow) => {
                  if (matrixDataRow.isRecesses !== 1) {
                    return (
                      <TableRow>
                        <TableCell
                          component="td"
                          scope="row"
                          sx={{ width: "12%" }}
                        >
                          {matrixDataRow.timeSlotName}
                        </TableCell>
                        {dayList.map((columnName, index) => {
                          let dayData = matrixDataRow.dayList.filter((item) => {
                            if (item.dayId === columnName.id) {
                              return item.dayId === columnName.id;
                            }
                          });
                          console.log("dayData");
                          console.log(dayData);
                          return (
                            <TableCell
                              sx={{
                                height: 100,
                              }}
                              onClick={(e) =>
                                dayData[0].value.length !== 0 &&
                                this.setState({
                                  openTimeSlotCell: true,
                                  rows: dayData[0].value,
                                  day: columnName.name,
                                  timeSlot: matrixDataRow.timeSlotName,
                                })
                              }
                            >
                              {dayData[0].value.length !== 0 && (
                                <>
                                  {dayData[0].value.length !== 2 &&
                                    dayData[0].value
                                      .slice(0, 3)
                                      .map((timeTableData) => {
                                        return (
                                          <>
                                            <LabelCompo
                                              className="text-black"
                                              style={{ fontSize: 12 }}
                                              label={
                                                timeTableData.subjectDetail +
                                                " (" +
                                                timeTableData.batchShortName +
                                                ")"
                                              }
                                            />
                                            <br />

                                            {dayData[0].value.length === 1 && (
                                              <>
                                                <LabelCompo
                                                  className="text-black"
                                                  style={{ fontSize: 12 }}
                                                  label={
                                                    timeTableData.teacherShortName
                                                  }
                                                />
                                                <br />
                                                <LabelCompo
                                                  className="text-black"
                                                  style={{ fontSize: 12 }}
                                                  label={" "}
                                                />
                                                <br />
                                              </>
                                            )}
                                            {/* <br /> */}
                                          </>
                                        );
                                      })}
                                  {
                                    dayData[0].value.length === 2 && (
                                      <>
                                        <LabelCompo
                                          className="text-black"
                                          style={{ fontSize: 12 }}
                                          label={
                                            dayData[0].value[0].subjectDetail +
                                            " (" +
                                            dayData[0].value[0].batchShortName +
                                            ")"
                                          }
                                        />
                                        <br />
                                        <br />
                                        <LabelCompo
                                          className="text-black"
                                          style={{ fontSize: 12 }}
                                          label={
                                            dayData[0].value[1].subjectDetail +
                                            " (" +
                                            dayData[0].value[1].batchShortName +
                                            ")"
                                          }
                                        />
                                      </>
                                    )
                                    // );
                                    // })
                                  }
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow sx={{ height: 30 }}>
                        <TableCell
                          component="td"
                          scope="row"
                          sx={{ width: "12%" }}
                        >
                          {matrixDataRow.timeSlotName}
                        </TableCell>

                        <TableCell colSpan={dayList.length} align="center">
                          Reccess Time
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {this.state.openTimeSlotCell && (
          <ViewTimeTableCellPop
            open={this.state.openTimeSlotCell}
            handleCloses={this.handleClosesTimeSlotCell}
            rows={this.state.rows}
            columns={this.columns}
            isActionColActive={false}
            timeSlot={this.state.timeSlot}
            day={this.state.day}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MatrixForm);
