import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  courseAttended: [],
  isFetch: false,

  qualification: [],
  isQualificationFetch: false,

  participationDetails: [],
  isParticipationDetailsFetch: false,
};
let URL = endpoints.courseAttended;

const courseAttendedSlice = createSlice({
  name: "courseAttended",
  initialState,
  reducers: {
    courseAttendedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        courseAttended: row,
        isFetch: true,
      };
    },

    qualificationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        qualification: row,
        isQualificaionFetch: true,
      };
    },

    participationDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        participationDetails: row,
        isParticipationDetailsFetch: true,
      };
    },
  },
});

export const {
  courseAttendedSuccess,
  qualificationSuccess,
  participationDetailsSuccess,
} = courseAttendedSlice.actions;

export default courseAttendedSlice.reducer;

export const getCourseAttended = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"formType":"' + "0" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            activityTitle: data1.activityTitle,
            employeeActivity: data1.employeeActivity.id,
            name: data1.employeeActivity.name,
            duration: data1.duration,
            details: data1.details,
            level: data1.level,
            status: data1.status,
          };
          row.push(bData);
          return data1;
        });
        dispatch(courseAttendedSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCourseAttendedByAcademicYear =
  ({ academicYearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-professional-enhancement-list-by-academic-year-id?academicYearId=" +
          academicYearId +
          "&formType=0",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              activityTitle: data1.activityTitle,
              employeeActivity: data1.employeeActivity.id,
              name: data1.employeeActivity.name,
              duration: data1.duration,
              details: data1.details,
              level: data1.level,
              status: data1.status,
            };
            row.push(bData);
            return data1;
          });
          dispatch(courseAttendedSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//

export const getQualification = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"formType":"' + "1" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            activityTitle: data1.activityTitle,
            details: data1.details,
            duration: data1.duration,
            status: data1.status,
            level: data1.level,
            userRegistration:
              data1.userRegistration === null ? "" : data1.userRegistration.id,
            departmentId:
              data1.userRegistration === null
                ? ""
                : data1.userRegistration.userRegistrationDepartment.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(qualificationSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminQualification =
  ({ userRegistration, academicYear }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-user-registration?userRegistrationId=" +
          userRegistration +
          "&formType=1&academicYearId=" +
          academicYear,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              activityTitle: data1.activityTitle,
              details: data1.details,
              duration: data1.duration,
              status: data1.status,
              level: data1.level,
            };
            row.push(bData);
            return data1;
          });
          dispatch(qualificationSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getParticipationDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"formType":"' + "2" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            visitingLecturer: data1.visitingLecturer,
            resourcePerson: data1.resourcePerson,
            details: data1.details,
            duration: data1.duration,
            level: data1.level,
            userRegistration:
              data1.userRegistration === null ? "" : data1.userRegistration.id,
            departmentId:
              data1.userRegistration === null
                ? ""
                : data1.userRegistration.userRegistrationDepartment.id,
          };
          row.push(bData);
          return data1;
        });
        dispatch(participationDetailsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getParticipationDetailsByAcademicYear =
  ({ academicYearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-professional-enhancement-list-by-academic-year-id?academicYearId=" +
          academicYearId +
          "&formType=2",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              visitingLecturer: data1.visitingLecturer,
              resourcePerson: data1.resourcePerson,
              details: data1.details,
              duration: data1.duration,
              level: data1.level,
            };
            row.push(bData);
            return data1;
          });
          dispatch(participationDetailsSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getQualificationByAcademicYear =
  ({ academicYearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-professional-enhancement-list-by-academic-year-id?academicYearId=" +
          academicYearId +
          "&formType=1",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              activityTitle: data1.activityTitle,
              details: data1.details,
              duration: data1.duration,
              status: data1.status,
              level: data1.level,
            };
            row.push(bData);
            return data1;
          });
          dispatch(qualificationSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
