import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../../../components/dynamicscreens/DynamicForm";
import endpoint from "../../../../../config/endpoints";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { adminResearchProjectJson } from "../../../../../DynamicFormsJson/AdminResearchProject";
import { researchProjectColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiPost } from "../../../../../utils/api_service";
import { showNotification } from "../../../../Landing/Landing.slice";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import moment from "moment";
import * as myConstClass from "../../../../../config/messageconstant";
import { Loading1 } from "../../../../../components/Loading1";
import { getDepartment } from "../../../Internship/Department/department.slice";
import { getEmployeeByDeptId } from "../../../../EmployeeDetails/employee.slice";
import { ButtonCompo } from "../../../../../components/Comman/Button";
import AutoComplete from "../../../../../components/Comman/AutoComplete";
import {
  getResearchProject,
  getResearchProjectByDate,
  getResearchProjectWithoutDate,
  getAdminResearchProject,
  resetData,
} from "../../AcademicDetails/ResearchProject/resourceProject.slice";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
// import { Loading1 } from "../Loading1";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class AdminResearchProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      formErrors: {},
      userRegistration: "",
      departmentId: "",
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      DataToSet: [],
      dynamicMasterData: {
        departmentMaster: this.props.departmentList?.department,
        teacherMaster: this.props.employeeList?.employeeList,
      },
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
    this.props.resetData();
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    adminResearchProjectJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });
      }
    });
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    const { fieldDataForSearch, departmentId, userRegistration } = this.state;
    const dataToSave = {};
    const dataToPassForm = [];
    let error = false;
    if (userRegistration != "" && departmentId != "") {
      const valueToShow = this.props.employeeList?.employeeList.filter(
        (data) => data.id === this.state.userRegistration
      );
      let dataToSet = {
        label: "Teacher",
        value: valueToShow.length !== 0 ? valueToShow[0].name : " - ",
      };
      dataToPassForm.push(dataToSet);
      const valueToShow1 = this.props.departmentList?.department.filter(
        (data) => data.id === this.state.departmentId
      );
      let dataToSet1 = {
        label: "Department",
        value: valueToShow1.length !== 0 ? valueToShow1[0].name : " - ",
      };
      dataToPassForm.push(dataToSet1);
    } else {
      error = true;
    }

    this.setState({
      DataToSet: dataToPassForm,
    });
    if (!error) {
      let fieldData = {};
      const { fieldMeta } = this.props;
      adminResearchProjectJson.fieldMeta.map((currentField) => {
        if (currentField.defaultValue) {
          fieldData[currentField.dataKey] = currentField.defaultValue;
        }
      });

      this.setState({
        fieldData: fieldData,
        currentOperationMode: OperationMode.Add,
      });
      this.toggleFormTableVisibility();
    } else {
      if (this.state.userRegistration == "") {
        swal({
          title: "Alert !",
          text: "Please Select Teacher",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
    }
    console.log(dataToSave);
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    let fieldData = {};

    adminResearchProjectJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    this.setState({
      showLoader: true,
    });
    this.props
      .getEmployeeByDeptId({
        departmentId: rowData.departmentId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    this.setState({
      showLoader: true,
    });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });

    // this.props.getResearchProjectWithoutDate().then((response) => {
    //   if (!response) {
    //     this.setState({
    //       showLoader: false,
    //     });
    //     this.props.getAlert({ message: "Something went wrong" });
    //   } else {
    //     this.setState({
    //       showLoader: false,
    //     });
    //   }
    // });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            departmentMaster: nextProps.departmentList.department.filter(
              (data) => data.showForSelection == 1
            ),
          },
        });
      }
    }
    if (this.props.employeeList !== nextProps.employeeList) {
      if (
        this.props.employeeList?.employeeList !==
        nextProps.employeeList?.employeeList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            teacherMaster: nextProps.employeeList?.employeeList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode, userRegistration } = this.state;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      userRegistration: { id: userRegistration },
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    adminResearchProjectJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    console.log(dataToSave);
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;
    this.setState({
      showLoader: true,
    });
    apiPost({
      url: adminResearchProjectJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.setState({
          showLoader: true,
        });
        this.props
          .getAdminResearchProject({
            userRegistration: this.state.userRegistration,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      showLoader: true,
    });
    apiDelete({
      url: adminResearchProjectJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        const { getAllEmployees } = this.props;
        const { academicYear } = this.state;
        this.setState({
          showLoader: true,
        });
        this.props
          .getAdminResearchProject({
            userRegistration: this.state.userRegistration,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        this.setState({
          currentOperationMode: "",
        });
        this.props.resetData();
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  submitHandler = () => {
    const { fromDate, toDate, userRegistration } = this.state;
    if (userRegistration !== "" && this.teacherHandleFormValidation()) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getAdminResearchProject({
          userRegistration: userRegistration,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };
  getFilteredTableData = () => {
    const { researchProjectList } = this.props;
    const { searchValue } = this.state;
    const columsFil = researchProjectColumns.filter((item) => {
      return item.canSearch;
    });
    return researchProjectList?.resourceProject.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };
  generatePDF = () => {
    const { excelFileName = "Reserch Project PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      researchProjectColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    researchProjectColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };
  generateExcel = () => {
    const { excelFileName = "Research Project Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      researchProjectColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  teacherHandleFormValidation() {
    const { userRegistration, departmentId } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (userRegistration === "") {
      formIsValid = false;
      formErrors["teacherError"] = myConstClass.employeemsg;
    }

    if (departmentId === "") {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  goToAdminAcademicPanel = () => {
    window.location.replace("/admin-academic-panel");
  };
  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      if (name == "departmentId") {
        this.setState({
          showLoader: true,
        });
        this.props
          .getEmployeeByDeptId({
            departmentId: newValue,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        this.setState({ departmentId: newValue });
      } else {
        this.setState({ userRegistration: newValue });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      fieldData,
      dynamicMasterData,
      userRegistration,
      departmentId,
      DataToSet,
    } = this.state;
    const { fromDateError, toDateError, teacherError, departmentError } =
      this.state.formErrors;

    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={adminResearchProjectJson.screenTitle}
          showPdfDownload={adminResearchProjectJson.showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={adminResearchProjectJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={showAddButton}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
          showBackToListBtn={true}
          onBackToList={this.goToAdminAcademicPanel}
        />
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <DynamicForm
                fieldMeta={adminResearchProjectJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                DataToSet={DataToSet}
                showSaveNextBtn={false}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave(true)}
                onSaveAndNext={this.onFormSave(false)}
              />
            </>
          )}

          {showTable && (
            <>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  spacing={1}
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Department"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={departmentId}
                        name={"departmentId"}
                        options={this.props.departmentList?.department.filter(
                          (data) => data.isActive == 1
                        )}
                        onChange={this.ChangeHandlerSearch}
                        isError={departmentError ? true : false}
                        errorText={departmentError ? departmentError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Teacher"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={userRegistration}
                        name={"userRegistration"}
                        options={this.props.employeeList?.employeeList.filter(
                          (data) => data.isActive == 1
                        )}
                        onChange={this.ChangeHandlerSearch}
                        isError={teacherError ? true : false}
                        errorText={teacherError ? teacherError : " "}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="flex-end"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Search"
                        onClick={this.submitHandler}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
              </Grid>

              <Grid item sx={{ marginTop: 1 }}>
                <RTTable
                  columns={researchProjectColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  showAddButtonInTable={showAddButtonInTable}
                  rowEdit={this.rowEdit}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                  rowAdd={this.rowAdd}
                  rows={this.getFilteredTableData()}
                  tableData={this.getFilteredTableData()}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  researchProjectList: state.researchProject,
  departmentList: state.department,
  employeeList: state.employeeList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getResearchProject,
  getResearchProjectByDate,
  getResearchProjectWithoutDate,
  getDepartment,
  getEmployeeByDeptId,
  getAdminResearchProject,
  resetData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminResearchProject);
