import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
const initialState = {
  studentList: [],
  isFetch: false,
  teacherStudentList: [],
  isFetchTeacherStud: false,
  allocationList: [],
  isFetchAllocation: false,
};

let URL = endpoints.intrestedAndNotIntrestedStudent;
let vivaDeclare = endpoints.vivaDeclare;
const allocationSlice = createSlice({
  name: "allocation",
  initialState,
  reducers: {
    resetTeacherAndStudentListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        studentList: [],
        isFetch: false,
        teacherStudentList: [],
        isFetchTeacherStud: false,
        allocationList: [],
        isFetchAllocation: false,
      };
    },
    studentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentList: row,
        isFetch: true,
      };
    },
    studentEmptyListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        studentList: [],
        isFetch: false,
      };
    },
    teacherAndStudentListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        teacherStudentList: row,
        isFetchTeacherStud: true,
      };
    },
    allocationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        allocationList: row,
        isFetchAllocation: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  resetTeacherAndStudentListSuccess,
  allocationSuccess,
  studentListSuccess,
  teacherAndStudentListSuccess,
  resetSuccess,
  studentEmptyListSuccess,
} = allocationSlice.actions;

export default allocationSlice.reducer;

export const getStudentListForAllocation =
  ({ internTypeId, yearDetailId, divisionId }) =>
  async (dispatch) => {
    try {
      //&divisionIds=d910e283-b645-426e-865e-040440651afc
      const response = apiGet({
        url:
          URL +
          "/by-year-detail-division-internship-type?yearDetailId=" +
          yearDetailId +
          "&internshipType=" +
          internTypeId +
          "&divisionIds=" +
          divisionId,
      }).then(({ data, success }) => {
        if (success) {
          const StudentList = data.data;

          let row = StudentList.map((Studentdata, index) => {
            const studData = {
              index: index + 1,
              lastName:
                Studentdata.getUserRegistration === null
                  ? ""
                  : Studentdata.getUserRegistration.lastName.toUpperCase(),
              firstName:
                Studentdata.getUserRegistration === null
                  ? ""
                  : Studentdata.getUserRegistration.firstName.toUpperCase(),
              middleName:
                Studentdata.getUserRegistration === null
                  ? ""
                  : Studentdata.getUserRegistration.middleName.toUpperCase(),
              fatherName:
                Studentdata.getUserRegistration === null
                  ? ""
                  : Studentdata.getUserRegistration.fatherName.toUpperCase(),
              motherName:
                Studentdata.getUserRegistration === null
                  ? ""
                  : Studentdata.getUserRegistration.motherName.toUpperCase(),
              name:
                Studentdata.getUserRegistration === null
                  ? ""
                  : Studentdata.getUserRegistration.lastName.toUpperCase() +
                    " " +
                    Studentdata.getUserRegistration.firstName.toUpperCase() +
                    " " +
                    (Studentdata.getUserRegistration.fatherName == null ||
                    Studentdata.getUserRegistration.fatherName == ""
                      ? " - "
                      : Studentdata.getUserRegistration.fatherName.toUpperCase()) +
                    " " +
                    (Studentdata.getUserRegistration.motherName == null ||
                    Studentdata.getUserRegistration.motherName == ""
                      ? " - "
                      : Studentdata.getUserRegistration.motherName.toUpperCase()),

              id: Studentdata.id,
              division:
                Studentdata.getReportingDetail === null
                  ? ""
                  : Studentdata.getReportingDetail.division.name,
              rollNo:
                Studentdata.getReportingDetail === null
                  ? ""
                  : Studentdata.getReportingDetail.rollNo,
              userRegistrationId: Studentdata.userRegistrationId,
              getUserRegistration: {
                id:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.id,
                userId:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.userId,
                grnNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.grnNo,
                seatNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.seatNo,
                eligibilityNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.eligibilityNo,
                prnNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.prnNo,
                admissionType:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.admissionType !== null
                    ? {
                        id: Studentdata.getUserRegistration.admissionType.id,
                        name: Studentdata.getUserRegistration.admissionType
                          .name,
                        isActive:
                          Studentdata.getUserRegistration.admissionType
                            .isActive,
                      }
                    : null,
                capround:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.capround,
                oms:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.oms,
                ebc:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.ebc,
                allotlettno:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.allotlettno,
                meritNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.meritNo,
                dateOfRepo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.dateOfRepo,
                dse:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.dse,
                programTitleId:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.programTitleId,
                profilePicture:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.profilePicture,
                academicYearId:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.academicYearId,
                bankName:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.bankName,
                bankBranch:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.bankBranch,
                ifscCode:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.ifscCode,
                micrCode:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.micrCode,
                bankAccountNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.bankAccountNo,
                previousInstitute:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.previousInstitute,
                previousInstituteAddress:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.previousInstituteAddress,
                previousInstituteArea:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.previousInstituteArea,
                previousInstituteMedium:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.previousInstituteMedium,
                passout:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.passout,
                lcIssued:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.lcIssued,
                firstName:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.firstName,
                middleName:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.middleName,
                lastName:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.lastName,
                motherName:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.motherName,
                gender:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.gender,
                dateOfBirth:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.dateOfBirth,
                birthPlace:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.birthPlace,
                bloodGroup:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.bloodGroup,
                religionId:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.religionId,
                categoryId:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.categoryId,
                cast:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.cast,
                subCast:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.subCast,
                scholarship:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.scholarship,
                fatherOccupation:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.fatherOccupation,
                annualIncome:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.annualIncome,
                fatherName:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.fatherName,
                permanentAddressLine1:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentAddressLine1,
                permanentAddressLine2:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentAddressLine2,
                permanentAddressLine3:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentAddressLine3,
                permanentCity:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentCity,
                permanentTaluka:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentTaluka,
                permanentDistrict:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentDistrict,
                permanentState:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentState,
                permanentPin:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.permanentPin,
                temporaryAddressLine1:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryAddressLine1,
                temporaryAddressLine2:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryAddressLine2,
                temporaryAddressLine3:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryAddressLine3,
                temporaryCity:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryCity,
                temporaryTaluka:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryTaluka,
                temporaryDistrict:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryDistrict,
                temporaryState:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryState,
                temporaryPin:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.temporaryPin,
                nationality:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.nationality,
                domicileof:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.domicileof,
                motherTongue:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.motherTongue,
                parentMobile:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.parentMobile,
                parentEmail:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.parentEmail,
                studentMobile:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.studentMobile,
                studentEmail:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.studentEmail,
                defenceType:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.defenceType,
                handicapCategory:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.handicapCategory,
                isOrphan:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.isOrphan,
                minority:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.minority,
                ruralUrban:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.ruralUrban,
                landlineNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.landlineNo,
                aadhaarNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.aadhaarNo,
                remark:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.remark,
                validityCdNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.validityCdNo,
                validityCdDate:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.validityCdDate,
                castCertificateNo:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.castCertificateNo,
                castCertificateDate:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.castCertificateDate,
                intrestIn:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.intrestIn,
                profilePic:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.profilePic,
                signature:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.signature,
                martialStatus:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.martialStatus,
                isStudentNriForeignNational:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration
                        .isStudentNriForeignNational,
                isActive:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.isActive,
                type:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.type,
                role:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.role,
                userDocumentDetailSaves:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.userDocumentDetailSaves,
                userQualificationDetailSaves:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration
                        .userQualificationDetailSaves,
                designation:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.designation,
                department:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.department,
                totalExperiences:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.totalExperiences,
                employeeType:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.employeeType,
                departmentAccess:
                  Studentdata.getUserRegistration === null
                    ? ""
                    : Studentdata.getUserRegistration.departmentAccess,
              },
              internshipIntrestedStudentIds:
                Studentdata.internshipIntrestedStudentIds,
              status: Studentdata.status,
              internalMarks: Studentdata.internalMarks,
              outOfInternal: Studentdata.outOfInternal,
              externalMarks: Studentdata.externalMarks,
              outOfExternal: Studentdata.outOfExternal,
            };
            return studData;
          });
          dispatch(studentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAllocatedListOfStudentAndTeacher =
  ({ teacherId, studentList }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          vivaDeclare + "/allocate-teachers-to-students?teachers=" + teacherId,
        postBody: studentList,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const StudentList = data.data;
          console.log(StudentList);

          let row = StudentList.map((StudentAndTeacherdata, index) => {
            const studData = {
              id: StudentAndTeacherdata.id,
              teacherId: StudentAndTeacherdata.userRegistrationId,
              teacherName:
                StudentAndTeacherdata.getUserRegistration.lastName +
                " " +
                StudentAndTeacherdata.getUserRegistration.firstName +
                " " +
                StudentAndTeacherdata.getUserRegistration.middleName,
              remark: "",
              vivaDate: null,
              time: null,
              vivaDateValid: 0,
              timeValid: 0,
              internshipVivaDeclareStudent:
                StudentAndTeacherdata.internshipVivaDeclareStudent !== null
                  ? StudentAndTeacherdata.internshipVivaDeclareStudent.map(
                      (studentData, index) => {
                        const student = {
                          index: index + 1,
                          userRegistrationId: studentData.userRegistrationId,
                          lastName:
                            studentData.getUserRegistration === null
                              ? ""
                              : studentData.getUserRegistration.lastName.toUpperCase(),
                          firstName:
                            studentData.getUserRegistration === null
                              ? ""
                              : studentData.getUserRegistration.firstName.toUpperCase(),
                          middleName:
                            studentData.getUserRegistration === null
                              ? ""
                              : studentData.getUserRegistration.middleName.toUpperCase(),
                          fatherName:
                            studentData.getUserRegistration === null
                              ? ""
                              : studentData.getUserRegistration.fatherName.toUpperCase(),
                          motherName:
                            studentData.getUserRegistration === null
                              ? ""
                              : studentData.getUserRegistration.motherName.toUpperCase(),
                          studentName:
                            studentData.getUserRegistration === null
                              ? ""
                              : studentData.getUserRegistration.lastName.toUpperCase() +
                                " " +
                                studentData.getUserRegistration.firstName.toUpperCase() +
                                " " +
                                (studentData.getUserRegistration.fatherName ==
                                  null ||
                                studentData.getUserRegistration.fatherName == ""
                                  ? " - "
                                  : studentData.getUserRegistration.fatherName.toUpperCase()) +
                                " " +
                                (studentData.getUserRegistration.motherName ==
                                  null ||
                                studentData.getUserRegistration.motherName == ""
                                  ? " - "
                                  : studentData.getUserRegistration.motherName.toUpperCase()),
                          // studentName:
                          //   studentData.getUserRegistration === null
                          //     ? " "
                          //     : studentData.getUserRegistration.firstName +
                          //       " " +
                          //       studentData.getUserRegistration.middleName +
                          //       " " +
                          //       studentData.getUserRegistration.lastName,
                          division:
                            studentData.getReportingDetail === null
                              ? ""
                              : studentData.getReportingDetail.division.name,
                          rollNo:
                            studentData.getReportingDetail === null
                              ? ""
                              : studentData.getReportingDetail.rollNo,
                          status: 0,
                          internalMarks: studentData.internalMarks,
                          outOfInternal: studentData.outOfInternal,
                          externalMarks: studentData.externalMarks,
                          outOfExternal: studentData.outOfExternal,
                          internshipIntrestedStudentIds:
                            studentData.internshipIntrestedStudentIds,
                        };
                        return student;
                      }
                    )
                  : [],
            };
            return studData;
          });
          dispatch(teacherAndStudentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setAllocatedListOfStudentAndTeacher =
  (row) => async (dispatch) => {
    dispatch(teacherAndStudentListSuccess({ row }));
  };
export const getAllocationListAdminSide =
  ({ academicYearId, yearDetailId, internType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          vivaDeclare +
          "/get-internship-viva-declare-list-by-academic-year-intern-type-year-detail?academicYearId=" +
          academicYearId +
          "&internType=" +
          internType +
          "&yearDetailId=" +
          yearDetailId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const StudentList = data.data;
          console.log(StudentList);

          let row = StudentList.map((Studentdata, index) => {
            const division =
              Studentdata.getDivisions !== null
                ? Studentdata.getDivisions.map((division) => {
                    return division.name;
                  })
                : [];
            const studData = {
              index: index + 1,
              id: Studentdata.id,
              year:
                Studentdata.getYearDetail !== null
                  ? Studentdata.getYearDetail.year.programType.name +
                    " - " +
                    Studentdata.getYearDetail.programTitle.brName +
                    " - " +
                    Studentdata.getYearDetail.year.className
                  : "",
              division: division.join(),
              remark: Studentdata.remark ? Studentdata.remark : "-",
              status: Studentdata.status === "0" ? "Pending" : "Complete",
              yearDetailId: Studentdata.getYearDetail.id,
              internshipType: Studentdata.internshipType.id,
            };
            return studData;
          });
          dispatch(allocationSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getViviDeclareById =
  ({ vivaDeclareId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: vivaDeclare + "/" + vivaDeclareId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const StudentList = data.data;
          console.log(StudentList);

          let row = StudentList.internshipVivaDeclareFaculty.map(
            (StudentAndTeacherdata, index) => {
              const studData = {
                id: StudentAndTeacherdata.id,
                teacherId: StudentAndTeacherdata.userRegistrationId,
                teacherName:
                  StudentAndTeacherdata.getUserRegistration.lastName +
                  " " +
                  StudentAndTeacherdata.getUserRegistration.firstName +
                  " " +
                  StudentAndTeacherdata.getUserRegistration.middleName,
                remark: StudentAndTeacherdata.remark,
                vivaDate: StudentAndTeacherdata.vivaDate,
                time: StudentAndTeacherdata.time,
                internshipVivaDeclareStudent:
                  StudentAndTeacherdata.internshipVivaDeclareStudent !== null
                    ? StudentAndTeacherdata.internshipVivaDeclareStudent.map(
                        (studentData, index) => {
                          const student = {
                            index: index + 1,
                            userRegistrationId: studentData.userRegistrationId,
                            lastName:
                              studentData.getUserRegistration === null
                                ? ""
                                : studentData.getUserRegistration.lastName.toUpperCase(),
                            firstName:
                              studentData.getUserRegistration === null
                                ? ""
                                : studentData.getUserRegistration.firstName.toUpperCase(),
                            middleName:
                              studentData.getUserRegistration === null
                                ? ""
                                : studentData.getUserRegistration.middleName.toUpperCase(),
                            fatherName:
                              studentData.getUserRegistration === null
                                ? ""
                                : studentData.getUserRegistration.fatherName.toUpperCase(),
                            motherName:
                              studentData.getUserRegistration === null
                                ? ""
                                : studentData.getUserRegistration.motherName.toUpperCase(),
                            studentName:
                              studentData.getUserRegistration === null
                                ? ""
                                : studentData.getUserRegistration.lastName.toUpperCase() +
                                  " " +
                                  studentData.getUserRegistration.firstName.toUpperCase() +
                                  " " +
                                  (studentData.getUserRegistration.fatherName ==
                                    null ||
                                  studentData.getUserRegistration.fatherName ==
                                    ""
                                    ? " - "
                                    : studentData.getUserRegistration.fatherName.toUpperCase()) +
                                  " " +
                                  (studentData.getUserRegistration.motherName ==
                                    null ||
                                  studentData.getUserRegistration.motherName ==
                                    ""
                                    ? " - "
                                    : studentData.getUserRegistration.motherName.toUpperCase()),
                            division:
                              studentData.getReportingDetail === null
                                ? "-"
                                : studentData.getReportingDetail.division.name,
                            rollNo:
                              studentData.getReportingDetail === null
                                ? "-"
                                : studentData.getReportingDetail.rollNo,
                            status: 0,
                            internalMarks: studentData.internalMarks,
                            outOfInternal: studentData.outOfInternal,
                            externalMarks: studentData.externalMarks,
                            outOfExternal: studentData.outOfExternal,
                            internshipIntrestedStudentIds:
                              studentData.internshipIntrestedStudentIds,
                          };
                          return student;
                        }
                      )
                    : [],
              };
              return studData;
            }
          );
          dispatch(teacherAndStudentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetStudentAndTeacher = () => async (dispatch) => {
  dispatch(resetTeacherAndStudentListSuccess());
};

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
export const resetStudentList = () => async (dispatch) => {
  try {
    dispatch(studentEmptyListSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
