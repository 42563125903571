import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  engDetailsData: {},
  isFetch: false,
};
let URL = endpoints.alumniEngDetailsUrl;
const alumniMentorStatusSlice = createSlice({
  name: "engDetailsData",
  initialState,
  reducers: {
    engDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        engDetailsData: row,
        isFetch: true,
      };
    },
    engDetailsResetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        engDetailsData: {},
        isFetch: false,
      };
    },
  },
});

export const { engDetailsSuccess, engDetailsResetSuccess } =
  alumniMentorStatusSlice.actions;

export default alumniMentorStatusSlice.reducer;

export const getAlumniEngDetailsById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-volunteer-mentor-id?id=" + id,
      }).then(({ data, success }) => {
        if (success) {
          dispatch(engDetailsSuccess({ row: data.data }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAlumniEngDetailsByIdReset = () => async (dispatch) => {
  try {
    dispatch(engDetailsResetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
