import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../CommonActions/alert.slice";
import AttachFile from "../../../../components/Comman/AttachFile";
import { ButtonCompo } from "../../../../components/Comman/Button";
import BasicDatePicker from "../../../../components/Comman/DatePickerEx";
import CheckBoxSelectionTable from "../../../../components/Comman/RT/CheckBoxSelectionTable";
import { LabelCompo } from "../../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
import endpoint from "../../../../config/endpoints";
import * as myConstClass from "../../../../config/messageconstant";
import endpoints from "../../../../config/endpoints";
import { showNotification } from "../../../Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../../utils/api_service";
import TimePicker from "../../../../components/Comman/TimePicker";
import AutoComplete from "../../../../components/Comman/AutoComplete";
import { getDepartment } from "../../../Masters/Internship/Department/department.slice";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
} from "../../../UploadFile/file.slice";
import DynamicTable from "../../../../components/Comman/RT/MaterialUIRTTable";
import { Loading1 } from "../../../../components/Loading1";

import { downloadVideo, downloadStudentVideo } from "../../../Video/Video";
import swal from "sweetalert";
import { getFolder } from "../../AdminGalleryFolder/adminGallary.slice";
import MultipleSelect from "../../../../components/Comman/MultipleSelect";
// import AdminVideo from "./AdminVideo";
// import VideoEx from "../AluminiGallery/VideoEx";
// import AdminGallarYPopup from "./AdminGallarYPopup";

// const inputRef = useRef();

const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Document Name",
    name: "name",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    name: "action",
    title: "Action",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];

let docs;

class AddEngagementDetails extends Component {
  state = {
    id: 0,
    description: "",
    target: "",
    formErrors: [],
    documentName: "",
    fileName: "",
    upload: false,
    detail: "",
    date: "",
    toDate: "",
    postitionAsOfficeBearer: "",
    role: "",
    role2: "",
    isActive: 1,
    detailsName: "",
    rows: [],
    department: "",
    list2: [],
    showLoader: false,
    setList: [],
    setList1: [],
    gallaryList: [],
    isError: false,
    file: "",
    showVideo: false,
    videoListData: [],
    urlData: "",
    index: "",
    audience: "",
    venue: "",
    time: "",
    backToForm: false,
    fileName: "",
    documentName: "",
    fileNameData: [],
    upload: false,
    uploadError: false,
    showTable: true,
    docNameDD: "",
    donor: "Donor",
    other: "",
    targetAudienceList: [
      { id: "all", name: "All" },
      {
        id: "Open For Public",
        name: "Open For Public",
      },
      { id: "For Alumnae", name: "For Alumnae" },
      {
        id: "For Current Students",
        name: "For Current Students",
      },
      {
        id: "For Teachers",
        name: "For Teachers",
      },
    ],
    paperSetterRoleList: [
      { id: "Paper Setter", name: "Paper Setter" },
      { id: "Evaluator", name: "Evaluator(Practicals, Presentations)" },
      { id: "Examiner", name: "Examiner (Theory, Dissertation Viva)" },
      { id: "Research Guide", name: "Research Guide" },
    ],
    resourcePersonRoleList: [
      { id: "Subject Expert", name: "Subject Expert" },
      { id: "Event Jury", name: "Event Jury" },
      { id: "Speaker", name: "Speaker" },
    ],
    resourcePersonRoleList: [
      { id: "Subject Expert", name: "Subject Expert" },
      { id: "Event Jury", name: "Event Jury" },
      { id: "Speaker", name: "Speaker" },
    ],
    collaboratorRoleList: [
      { id: "Research", name: "Research" },
      { id: "Consultancy", name: "Consultancy" },
      { id: "Social Outreach", name: "Social Outreach" },
      { id: "other", name: "Any Other" },
    ],
    teacherRoleList: [
      { id: "PhD", name: "PhD" },
      { id: "MPhil", name: "MPhil" },
      { id: "MSc/MS", name: "MSc/MS" },
      { id: "MCA", name: "MCA" },
      { id: "MBA", name: "MBA" },
      { id: "MTech", name: "MTech" },
      { id: "MA", name: "MA" },
      { id: "M Design", name: "M Design" },
      { id: "other", name: "Any Other" },
    ],
    target2: [],
    otherRole: "",
  };

  onClickDelete = (params) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        this.setState({
          showLoader: true,
        });
        if (params.id !== undefined) {
          apiDelete({
            url: endpoints.alumniEngDetailsUrl + "/" + params.id,
          }).then(({ data, success }) => {
            if (success) {
              this.setState({
                showLoader: false,
              });
              this.props
                .deleteFile({ file: params.fileName })
                .then((success) => {
                  if (success) {
                    const docList = this.props.fileData.fileData.filter(
                      (row) => row.fileName !== params.fileName
                    );
                    const rowData = docList.map((docData, index) => {
                      return { ...docData, index: index + 1 };
                    });
                    this.props.fileMulitipleSave({ fileData: rowData });
                  }
                });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        } else {
          this.props.deleteFile({ file: params.fileName }).then((success) => {
            if (success) {
              this.setState({
                showLoader: false,
              });
              const docList = this.props.fileData.fileData.filter(
                (row) => row.fileName !== params.fileName
              );
              const rowData = docList.map((docData, index) => {
                return { ...docData, index: index + 1 };
              });
              this.props.fileMulitipleSave({ fileData: rowData });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        }
      }
    });
  };

  details = (params) => {
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      ...this.state,
      [dataKey]: date,
    });
  };

  addDetails = () => {};

  getRow = (e) => {
    const { rows } = this.state;
    let list2 = [];
    const gallaryList = e.map((data) => {
      let list1 = rows.filter((row) => row.id === data);
      if (list1.length !== 0) {
        list2.push(list1[0].fileName);
      }
      return list1[0];
    });
    this.setState({ setList: e, setList1: list2, gallaryList: gallaryList });
  };

  timeChangeHandler = (dataKey) => (time) => {
    this.setState({
      [dataKey]: time,
    });
  };

  handleFormValidation() {
    const { description, date, toDate } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (description.toString().trim() === "" || description === null) {
      formIsValid = false;
      formErrors["descriptionError"] = myConstClass.descriptionMsg;
    }

    if (date === "" || date === null) {
      formIsValid = false;
      formErrors["dateError"] = myConstClass.dateMsg;
    }

    if (date !== "" && date !== null && toDate !== "" && toDate !== null) {
      if (!(date < toDate)) {
        formIsValid = false;
        formErrors["toDateError"] = "*To date must be greater than from date";
      }
    }
    console.log(formErrors);
    this.setState({ ...this.state, formErrors: formErrors });
    return formIsValid;
  }
  handleDetailValidation() {
    const { file, fileName } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (fileName.toString().trim() === "" || fileName === null) {
      formIsValid = false;
      formErrors["fileNameError"] = myConstClass.fileName;
    }

    this.setState({ ...this.state, formErrors: formErrors });
    return formIsValid;
  }

  docHandleDetailValidation() {
    const { file, documentName } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (documentName.toString().trim() === "" || documentName === null) {
      formIsValid = false;
      formErrors["documentError"] = myConstClass.docNameMsg;
    }

    this.setState({ ...this.state, formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      rows,
      target,
      description,
      date,
      time,
      department,
      venue,
      toDate,
      postitionAsOfficeBearer,
      role,
      target2,
      role2,
      otherRole,
      other,
      donor,
    } = this.state;
    const { alumniData, alumnaeType } = this.props;
    const documentList = this.props.fileData.fileData.map((docData) => {
      let docs = {
        docName: docData.name,
        fileName: docData.fileName,
      };
      if (docData.id) {
        docs = {
          ...docs,
          id: docData.id,
        };
      }
      return docs;
    });
    let addNewsDate = date === "" ? "" : moment(date).format("DD-MM-YYYY");
    let changedToDate =
      toDate === "" ? "" : moment(toDate).format("DD-MM-YYYY");
    let changedTime =
      time == "" || time == "Invalid Date"
        ? ""
        : moment(time, "h:mm:ss A").format("HH:mm");
    console.log(time);
    console.log(changedTime);
    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit the data?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.setState({
            showLoader: true,
          });
          docs = {
            volunteerMentor: {
              id: alumniData.id,
            },
            typeOfEngaement: alumnaeType,
            description: alumnaeType == 3 ? donor : description,
            time: changedTime,
            // audience: audience,
            venue: venue,
            target:
              alumnaeType == 2
                ? target2.toString()
                : target === "Any other"
                ? other
                : target,
            dateOfEvent: addNewsDate,
            alumniEngagementDetailsImages: documentList,
            toDate: changedToDate,
            role: role,
            exVarchar1: alumnaeType == 7 ? otherRole : postitionAsOfficeBearer,
          };
          if (department !== "") {
            docs = {
              ...docs,
              forWhichDepartment: { id: department },
            };
          }
          console.log(docs);
          if (id != 0) {
            docs = {
              ...docs,
              id: id,
            };
          }

          apiPost({
            url: endpoint.alumniEngDetailsUrl,
            postBody: docs,
          }).then(({ success }) => {
            if (success) {
              this.setState({
                showLoader: false,
                description: "",
                time: "",
                forWhichDepartment: "",
                audience: "",
                venue: "",
                target: "",
                date: "",
              });
              this.props.showNotification({
                msg: "Data Saved successfully",
              });
              this.props.onCancelForm();
            } else {
              this.props.getAlert({ message: "Something went wrong" });
              this.setState({
                showLoader: false,
              });
            }
          });
        }
      });
    }
  };

  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");
    const { alumnaeType } = this.props;
    if (filterValue.length !== 0) {
      if (name === "target2") {
        let targetAudienceList = [];
        this.state.targetAudienceList.map((data) => {
          if (data.id !== "all") {
            targetAudienceList.push(data.id);
          }
        });
        this.setState({
          [name]: targetAudienceList,
        });
      } else if (name === "role") {
        if (alumnaeType == 4) {
          let targetVenueList = [];
          this.state.paperSetterRoleList.map((data) => {
            if (data.id !== "all") {
              targetVenueList.push(data.id);
            }
          });
          this.setState({
            [name]: targetVenueList,
          });
        } else if (alumnaeType == 5) {
          let targetVenueList = [];
          this.state.resourcePersonRoleList.map((data) => {
            if (data.id !== "all") {
              targetVenueList.push(data.id);
            }
          });
          this.setState({
            [name]: targetVenueList,
          });
        } else if (alumnaeType == 7) {
          let targetVenueList = [];
          this.state.collaboratorRoleList.map((data) => {
            if (data.id !== "all") {
              targetVenueList.push(data.id);
            }
          });
          this.setState({
            [name]: targetVenueList,
          });
        } else {
          let targetVenueList = [];
          this.state.teacherRoleList.map((data) => {
            if (data.id !== "all") {
              targetVenueList.push(data.id);
            }
          });
          this.setState({
            [name]: targetVenueList,
          });
        }
      } else {
        this.setState({
          ...this.state,
          [name]: typeof value === "string" ? value.split(",") : value,
        });
      }
    }
  };
  componentDidMount() {
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.deleteFileData();
    console.log(this.props.engDetailsData);
    if (
      this.props.engDetailsData &&
      Object.keys(this.props.engDetailsData).length !== 0
    ) {
      const dateArr =
        this.props.engDetailsData.dateOfEvent == null
          ? ""
          : this.props.engDetailsData.dateOfEvent.split("-");
      const date =
        dateArr === "" ? "" : new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);

      const toDateArr =
        this.props.engDetailsData.toDate == null
          ? ""
          : this.props.engDetailsData.toDate.split("-");
      const toDate =
        toDateArr === ""
          ? ""
          : new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0]);
      const fromTimeDate =
        this.props.engDetailsData.time !== ""
          ? moment(this.props.engDetailsData.time, ["h:mm A"]).format("HH:mm")
          : "";
      const fromTimeArr =
        fromTimeDate !== " " ? fromTimeDate.split(":", -1) : null;
      const changedTime =
        fromTimeArr !== null
          ? new Date(null, null, null, fromTimeArr[0], fromTimeArr[1])
          : null;
      this.setState({
        id: this.props.engDetailsData.id,
        description: this.props.engDetailsData.description,
        time: changedTime,
        department:
          this.props.engDetailsData.forWhichDepartment === null
            ? ""
            : this.props.engDetailsData.forWhichDepartment.id,
        audience:
          this.props.engDetailsData.audience === null
            ? ""
            : this.props.engDetailsData.audience,
        venue: this.props.engDetailsData.venue,
        target: this.props.engDetailsData.target,
        target2: this.props.engDetailsData.target.split(","),
        date: date,
        role: this.props.engDetailsData.role,
        postitionAsOfficeBearer: this.props.engDetailsData.exVarchar1,
        otherRole: this.props.engDetailsData.exVarchar1,
        toDate: toDate,
        donor: this.props.engDetailsData.description,
      });
      const docList =
        this.props.engDetailsData.alumniEngagementDetailsImages.map(
          (fileData, index) => {
            let document = {
              id: fileData.id,
              index: index + 1,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
      console.log(this.props.fieldData);
    }
  }

  changeH = (fileName, name, index) => {
    if (fileName !== undefined) {
      this.setState({
        ...this.state,
        fileName: fileName,
      });
    }
  };

  rowDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        this.setState({
          showLoader: true,
        });

        this.props.deleteFile({ file: data.fileName }).then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          } else {
            this.props.showNotification({
              msg: "Data Deleted successfully",
            });
            let list = [];
            this.state.rows.map((row) => {
              if (row.index !== data.index) {
                row = {
                  index: list.length + 1,
                  id: list.length + 1,
                  docName: row.docName,
                  fileName: row.fileName,
                };
                list.push(row);
              }
            });
            this.setState({
              rows: list,
              showLoader: false,
            });
          }
        });
      }
    });
  };

  componentDidUpdate() {
    if (this.state.upload) {
      this.setState({
        upload: false,
        detailsName: "",
        fileName: "",
      });
    }
  }

  details = (params) => {
    this.setState({
      showLoader: true,
    });
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  changeHandlerSearch = (name, newValue) => {
    console.log(newValue);
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  uploadImage = () => {
    const { fileName, documentName } = this.state;
    if (this.docHandleDetailValidation()) {
      if (fileName !== "") {
        this.setState({
          upload: true,
          showLoader: true,
          uploadError: false,
        });

        const formData = new FormData();
        formData.append("file", this.state.fileName);
        apiPost({
          url: endpoints.fileUpload,
          postBody: formData,
        }).then(({ data, success }) => {
          if (success) {
            let docList = [
              ...this.props.fileData.fileData,
              {
                index: this.props.fileData.fileData.length + 1,
                name: documentName,
                fileName: data,
              },
            ];
            this.setState({
              // documentName: "",
              fileName: "",
              upload: false,
              uploadError: false,
              showLoader: false,
            });
            this.props.fileMulitipleSave({ fileData: docList });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.setState({
          upload: false,
          uploadError: true,
        });
      }
    }
  };

  render() {
    const {
      time,
      target,
      description,
      date,
      detailsName,
      isError,
      showVideo,
      urlData,
      department,
      audience,
      venue,
      documentName,
      uploadError,
      upload,
      fileName,
      showTable,
      postitionAsOfficeBearer,
      role,
      toDate,
      targetAudienceList,
      target2,
      role2,
      otherRole,
      donor,
      other,
    } = this.state;
    const { onCancel, onCancelForm, alumnaeType, alumniData } = this.props;
    const {
      targetError,
      descriptionError,
      dateError,
      toDateError,
      timeError,
      departmentError,
      venueError,
      documentError,
    } = this.state.formErrors;
    return (
      <>
        {this.state.showLoader && <Loading1 />}
        {showTable && (
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={8} md={8}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 22 }}
                  label="Add Engagement Details"
                />
              </Grid>
              <Grid container justifyContent="right" item xs={12} sm={4} md={4}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Back to List"
                  onClick={onCancelForm}
                />
              </Grid>
            </Grid>
            <br />
            <form
              onSubmit={this.submitHandler}
              autoComplete="off"
              noValidate={true}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={1}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={"Name : " + alumniData.alumniName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={
                      alumnaeType == "0"
                        ? "Engagement Type : Be a Volunteer"
                        : alumnaeType == "1"
                        ? "Engagement Type : Be a Mentor"
                        : alumnaeType == "2"
                        ? "Engagement Type : Be a Alumnae Association Office Bearer"
                        : alumnaeType == "3"
                        ? "Engagement Type : Be a Donor and Sponsor"
                        : alumnaeType == "4"
                        ? "Engagement Type : Be a Paper Setter/ Evaluator/Examiner/Guide"
                        : alumnaeType == "5"
                        ? "Engagement Type : Be a Resource Person"
                        : alumnaeType == "7"
                        ? "Engagement Type : Be a Collaborator"
                        : alumnaeType == "8"
                        ? "Engagement Type : Be a Teacher"
                        : ""
                    }
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  {alumnaeType == 2 ? (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Position As Office Bearer"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="postitionAsOfficeBearer"
                          size="small"
                          value={postitionAsOfficeBearer}
                          onChange={this.changeHandler}
                          fullWidth
                          error={venueError ? true : false}
                          helperText={venueError ? venueError : " "}
                        />
                      </Grid>
                    </>
                  ) : alumnaeType == 4 ||
                    alumnaeType == 5 ||
                    alumnaeType == 7 ? (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Role"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={role}
                          name={"role"}
                          options={
                            alumnaeType == 4
                              ? this.state.paperSetterRoleList
                              : alumnaeType == 5
                              ? this.state.resourcePersonRoleList
                              : alumnaeType == 8
                              ? this.state.teacherRoleList
                              : this.state.collaboratorRoleList
                          }
                          onChange={this.changeHandlerSearch}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={12} />
                      <Grid item xs={12} sm={12} md={12} />
                    </>
                  )}
                </Grid>
                {(alumnaeType == 2 ||
                  ((alumnaeType == 4 || alumnaeType == 5 || alumnaeType == 7) &&
                    role != "other")) && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  ></Grid>
                )}
                {role == "other" && (
                  <>
                    {alumnaeType == 7 && (
                      <>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="Other Role"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="otherRole"
                              size="small"
                              value={otherRole}
                              onChange={this.changeHandler}
                              fullWidth
                              helperText={" "}
                              // helperText={venueError ? venueError : " "}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  {alumnaeType == 3 ? (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Type of Donation/Sponsorship"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className="text-black"
                            name="donor"
                            id="donor"
                            value={donor}
                            onChange={this.changeHandler}
                            size="small"
                            fullWidth={true}
                          >
                            <MenuItem value="Donor">Donor</MenuItem>
                            <MenuItem value="Sponsor">Sponsor</MenuItem>
                            <MenuItem value="Both">Both</MenuItem>
                          </Select>
                        </FormControl>
                        <FormHelperText> </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={
                            alumnaeType == 2
                              ? "*Description/ Event details/ Initiative taken"
                              : alumnaeType == 3
                              ? "*Type of Donation/Sponsorship"
                              : alumnaeType == 4 || alumnaeType == 8
                              ? "*Subject"
                              : alumnaeType == 5
                              ? "*Description/ Event details"
                              : alumnaeType == 7
                              ? "*Description of collaboration"
                              : "*Description / Topic"
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="description"
                          size="small"
                          value={description}
                          onChange={this.changeHandler}
                          fullWidth
                          multiline={true}
                          error={descriptionError ? true : false}
                          helperText={descriptionError ? descriptionError : " "}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        alumnaeType == 3
                          ? "*Date of Donation/Sponsorship"
                          : alumnaeType == 4 ||
                            alumnaeType == 8 ||
                            alumnaeType == 7
                          ? "*From Date"
                          : alumnaeType == 5
                          ? "*Date"
                          : "*Event Date"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="date"
                      size="small"
                      value={date}
                      fullWidth
                      onChange={this.onDateChange("date")}
                      isError={dateError ? true : false}
                      errorText={dateError ? dateError : " "}
                    />
                  </Grid>
                </Grid>

                {alumnaeType == 4 || alumnaeType == 8 || alumnaeType == 7 ? (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="To Date"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="toDate"
                          size="small"
                          value={toDate}
                          fullWidth
                          onChange={this.onDateChange("toDate")}
                          isError={toDateError ? true : false}
                          errorText={toDateError ? toDateError : " "}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : alumnaeType == 3 ? (
                  <>
                    {/* <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Purpose"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            color="primary"
                            name="target"
                            size="small"
                            value={target}
                            onChange={this.changeHandler}
                            fullWidth
                            multiline={true}
                            error={targetError ? true : false}
                            helperText={targetError ? targetError : " "}
                          />
                        </Grid>
                      </Grid>
                    </Grid> */}
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      {/* <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="I would like to be a"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className="text-black"
                            name="donor"
                            id="donor"
                            value={donor}
                            onChange={this.changeHandler}
                            size="small"
                            fullWidth={true}
                          >
                            <MenuItem value="Donor">Donor</MenuItem>
                            <MenuItem value="Sponsor">Sponsor</MenuItem>
                            <MenuItem value="Both">Both</MenuItem>
                          </Select>
                        </FormControl>
                        <FormHelperText> </FormHelperText>
                      </Grid> */}
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Purpose"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          {donor === "Donor" && (
                            <Select
                              labelId="demo-simple-select-helper-label"
                              className="text-black"
                              name="target"
                              id="target"
                              value={target}
                              onChange={this.changeHandler}
                              size="small"
                              fullWidth={true}
                            >
                              <MenuItem value="Fees">Fees</MenuItem>
                              <MenuItem value="Infrastructure">
                                Infrastructure
                              </MenuItem>
                              <MenuItem value="Books">Books</MenuItem>
                              <MenuItem value="Any other">Any other</MenuItem>
                            </Select>
                          )}
                          {donor === "Sponsor" && (
                            <Select
                              labelId="demo-simple-select-helper-label"
                              className="text-black"
                              name="target"
                              id="target"
                              value={target}
                              onChange={this.changeHandler}
                              size="small"
                              fullWidth={true}
                            >
                              <MenuItem value="Cultural Fest">
                                Cultural Fest
                              </MenuItem>
                              <MenuItem value="Technical Fest">
                                Technical Fest
                              </MenuItem>
                              <MenuItem value="Bal Mela">Bal Mela</MenuItem>
                              <MenuItem value="Workshops/Seminars">
                                Workshops/Seminars
                              </MenuItem>
                              <MenuItem value="Sports">Sports</MenuItem>
                              <MenuItem value="College Publicity">
                                College Publicity
                              </MenuItem>
                              <MenuItem value="Technological Support">
                                Technological Support
                              </MenuItem>
                              <MenuItem value="Equipment">Equipment</MenuItem>
                            </Select>
                          )}
                          {donor === "Both" && (
                            <Select
                              labelId="demo-simple-select-helper-label"
                              className="text-black"
                              name="target"
                              id="target"
                              value={target}
                              onChange={this.changeHandler}
                              size="small"
                              fullWidth={true}
                            >
                              <MenuItem value="Fees">Fees</MenuItem>
                              <MenuItem value="Infrastructure">
                                Infrastructure
                              </MenuItem>
                              <MenuItem value="Books">Books</MenuItem>
                              <MenuItem value="Cultural Fest">
                                Cultural Fest
                              </MenuItem>
                              <MenuItem value="Technical Fest">
                                Technical Fest
                              </MenuItem>
                              <MenuItem value="Bal Mela">Bal Mela</MenuItem>
                              <MenuItem value="Workshops/Seminars">
                                Workshops/Seminars
                              </MenuItem>
                              <MenuItem value="Sports">Sports</MenuItem>
                              <MenuItem value="College Publicity">
                                College Publicity
                              </MenuItem>
                              <MenuItem value="Technological Support">
                                Technological Support
                              </MenuItem>
                              <MenuItem value="Equipment">Equipment</MenuItem>
                              <MenuItem value="Any other">Any other</MenuItem>
                            </Select>
                          )}
                        </FormControl>
                        <FormHelperText> </FormHelperText>
                      </Grid>
                    </Grid>
                    {target === "Any other" && donor !== "Sponsor" ? (
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Other"
                          />
                        </Grid>
                        <Grid sxitem xs={12} sm={12} md={12}>
                          <TextField
                            color="primary"
                            name="other"
                            size="small"
                            value={other}
                            fullWidth
                            onChange={this.changeHandler}
                            error={false}
                            helperText={" "}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      ></Grid>
                    )}
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Time"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TimePicker
                          value={time}
                          onChange={this.timeChangeHandler("time")}
                          error={timeError ? timeError : " "}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                {alumnaeType == 4 && (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Time"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TimePicker
                          value={time}
                          onChange={this.timeChangeHandler("time")}
                          error={timeError ? timeError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {alumnaeType != 3 ? (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Department"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={department}
                          name={"department"}
                          options={this.props.departmentList?.department.filter(
                            (data) => data.showForSelection == 1
                          )}
                          onChange={this.changeHandlerSearch}
                          isError={departmentError ? true : false}
                          errorText={departmentError ? departmentError : " "}
                        />
                      </Grid>
                    </Grid>
                    {alumnaeType != 8 && alumnaeType != 2 ? (
                      <Grid
                        item
                        sm={6}
                        xs={6}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Target Audience"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="target"
                              size="small"
                              value={target}
                              onChange={this.changeHandler}
                              fullWidth
                              multiline={true}
                              error={targetError ? true : false}
                              helperText={targetError ? targetError : " "}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        {alumnaeType != 8 && (
                          <Grid
                            item
                            sm={6}
                            xs={6}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="Target Audience"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <Grid item xs={12} sm={12} md={12}>
                                <MultipleSelect
                                  er={targetError}
                                  name1="target2"
                                  value1={target2}
                                  // names={internshipDeclarationList?.distinctDivision.filter(
                                  //   (data) => data.isActive == 1
                                  // )}

                                  names={targetAudienceList}
                                  onChange={this.multiSelectHandler}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {alumnaeType == 3 ? (
                      <>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="Details"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="venue"
                              size="small"
                              value={venue}
                              onChange={this.changeHandler}
                              fullWidth
                              error={venueError ? true : false}
                              helperText={venueError ? venueError : " "}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={6}
                          lg={6}
                          container
                          justifyContent="space-evenly"
                        ></Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}

                {alumnaeType != 3 && alumnaeType != 8 && (
                  <>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Venue"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="venue"
                          size="small"
                          value={venue}
                          onChange={this.changeHandler}
                          fullWidth
                          error={venueError ? true : false}
                          helperText={venueError ? venueError : " "}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                />

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 20 }}
                      label="Add Documents / Photos / Reports"
                    />
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      lg={4}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Document name"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          size="small"
                          color="primary"
                          name="documentName"
                          id="documentName"
                          value={documentName}
                          onChange={this.changeHandler}
                          fullWidth
                          error={documentError ? true : false}
                          helperText={documentError ? documentError : " "}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={7}
                      xs={12}
                      md={7}
                      lg={7}
                      container
                      justifyContent="space-evenly"
                      sx={{ marginTop: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <AttachFile name={"file"} fileName={this.changeH} />
                        &nbsp; {fileName !== "" ? fileName.name : ""}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={1}
                      rowSpacing={2}
                      xs={12}
                      sm={1}
                      md={1}
                      sx={{ marginTop: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          fullWidth={true}
                          disabled={
                            documentName !== "" && upload ? true : false
                          }
                          name="Upload"
                          onClick={this.uploadImage}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {uploadError && (
                        <FormHelperText error>
                          {"please select file and Document Name"}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={2}
                      lg={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {isError ? (
                    <FormHelperText error>
                      {"*Please select atleast one record"}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>{}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <DynamicTable
                    data={this.props.fileData.fileData}
                    tableHead={columns}
                    showHeadDelete={true}
                    rowDelete={this.onClickDelete}
                    showPegination={false}
                    rowViewData={this.details}
                    showView={true}
                  />
                </Grid>
                <Grid
                  sx={{ mr: 0.5 }}
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  m={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Cancel"
                      onClick={onCancelForm}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1}
                    lg={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Save"
                      onClick={this.submitHandler}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fileData: state.file,
  academicYear: state.academicYear,
  internshipDeclarationList: state.internshipDeclaration,
  departmentList: state.department,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getFolder,
  getDepartment,
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEngagementDetails);
