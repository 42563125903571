import { FormHelperText, Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { LabelCompo } from "../../components/Comman/Label";
import { showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { Loading1 } from "../../components/Loading1";

import {
  getPrnNumberGenerate,
  getUserCredentialNull,
  getAcademicYearInstituteWise,
  resetValues,
} from "./UserCredentials.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import PrnNumberTable from "./UserCredentialsTable";
import { resetReducList } from "../../CommonActions/User.action";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
class UserCredentialsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      divisionId: "",
      yearDetailId: "",
      academicYear: "",
      showLoader: false,
      popUpValue: false,
      open: false,
      selection: [],
      setList: [],
      selectionListData: [],
      formErrors: {},
      studentData: {},
      isSelectionError: false,
    };
  }

  // rowEdit = (params) => {
  //   console.log(params);
  //   this.setState({
  //     popUpValue: true,
  //     open: true,
  //     studentData: params,
  //   });
  // };

  // onClose = () => {
  //   this.setState({
  //     popUpValue: false,
  //     open: false,
  //     studentData: {},
  //   });
  // };

  handleCloses = () => {
    this.setState({
      popUpValue: false,
      open: false,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,
    },
    {
      name: "yearDetails",
      title: "Year details",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "divisionId",
      title: "Division",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "20%",
    },
  ];

  handleClickOpen() {
    this.setState({
      open: false,
    });
  }

  componentDidMount() {
    const {
      getAcademicYearInstituteWise,
      resetReducList,
      getYearDetails,
      getUserCredentialNull,
    } = this.props;
    const ay = localStorage.getItem("acadamicYearId");
    this.state.yearDetailId = localStorage.getItem("yearDetailId");
    this.state.divisionId = localStorage.getItem("divisionId");

    resetReducList();
    getAcademicYearInstituteWise().then((response) => {
      this.setState({
        showLoader: true,
      });
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    getYearDetails().then((response) => {
      this.setState({
        showLoader: true,
      });
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    if (this.state.yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: this.state.yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (this.state.yearDetailId && this.state.divisionId) {
      this.setState({
        showLoader: true,
      });
      getUserCredentialNull({
        academicYearId: ay,
        division: this.state.divisionId,
      }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const ay = localStorage.getItem("acadamicYearId");
    const { getUserCredentialNull, resetValues } = this.props;
    const { academicYear, yearDetailId, divisionId, isSelectionError } =
      this.state;
    resetValues().then((response) => {
      if (!response) {
      }
    });
    if (this.handleFormValidation()) {
      this.setState({
        showLoader: true,
      });
      getUserCredentialNull({
        academicYearId: ay,
        division: divisionId,
      }).then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }
  };
  //http://localhost:8088/api/acdmc/v1/user-registration/generate-username-password
  saveHandler = (e) => {
    const { selectionListData } = this.state;
    console.log(selectionListData);
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Do you want to submit Information",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        // let dataToSave = this.props.prnNumberList?.prnNumberGenerate.map(
        //   (data1) => {
        //     let bData = {
        //       id: data1.id,
        //       prnNo: data1.prnNumber,
        //     };
        //     return bData;
        //   }
        // );
        // const { backToTable } = this.props;
        // apiPost({
        //   url: endpoint.prnNumber + "/save-prnno",
        //   postBody: dataToSave,
        // }).then(({ success }) => {
        //   if (success) {
        //     // this.props.showNotification({ msg: "Data saved successfully" });
        //     backToTable();
        //   } else {
        //     this.props.getAlert({
        //       message: "Failed to save",
        //     });
        //   }
        // });
        // console.log(dataToSave);
      }
    });
  };

  handleFormValidation() {
    const { divisionId, yearDetailId, academicYear } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetailId.toString().trim() === "" || yearDetailId === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (divisionId.toString().trim() === "" || divisionId === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  generateCredential = (e) => {
    e.preventDefault();
    if (this.state.selection.length === 0) {
      this.setState({
        isSelectionError: true,
      });
    } else {
      this.setState({
        isSelectionError: false,
      });
      const ay = localStorage.getItem("acadamicYearId");
      const { getPrnNumberGenerate } = this.props;
      const { selectionListData } = this.state;
      swal({
        title: "Are you sure?",
        text: "Do you want to generate credential?",
        icon: "warning",
        buttons: ["Cancel", true],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let dataToSave = selectionListData.map((data1) => {
            let bData = {
              id: data1.id,
              prnNo: data1.prnNo,
              userId: null,
              firstName: data1.firstName,
              middleName: data1.middleName,
              lastName: data1.lastName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              emailId: data1.emailId,
              dateOfBirth: data1.dateOfBirth,
              mobileNo: data1.mobileNo,
            };
            return bData;
          });
          console.log(dataToSave);

          const { backToTable } = this.props;
          apiPost({
            url: endpoint.studentList + "/generate-username-password",
            postBody: dataToSave,
          }).then(({ success }) => {
            if (success) {
              this.props.showNotification({ msg: "Data saved successfully" });
              this.props
                .getUserCredentialNull({
                  academicYearId: ay,
                  division: this.state.divisionId,
                })
                .then((response) => {
                  if (!response) {
                    this.setState({
                      showLoader: false,
                    });
                    this.props.getAlert({
                      message: "Something went wrong",
                    });
                  } else {
                    this.setState({
                      showLoader: false,
                    });
                  }
                });
            } else {
              this.props.getAlert({
                message: "Failed to save",
              });
            }
          });
        }
      });
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    console.log(name);
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name == "yearDetailId") {
        this.setState({
          divisionId: "",
        });
        this.props
          .getDistinctDivision({ yearDetail: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  getRow = (e) => {
    let selectedList = [];
    this.setState({
      selection: e,
    });
    e.forEach((data) => {
      const selRow = this.props.userCredentialList?.userCredentialNull.filter(
        (row, index) => {
          return row.id === data;
        }
      );
      selectedList = [...selectedList, selRow[0]];
    });
    console.log("selectedList");
    console.log(selectedList);
    this.setState({
      selectionListData: selectedList,
    });
  };

  render() {
    const {
      divisionId,
      yearDetailId,
      academicYear,
      showLoader,
      isSelectionError,
    } = this.state;
    const { divisionError, yearDetailError, academicYearError } =
      this.state.formErrors;
    const { userCredentialList, academicYearList, backToTable } = this.props;
    return (
      <>
        {this.state.showLoader && <Loading1 />}
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title="User Credential Form" />
        </Paper>
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={4}
                  xs={12}
                  md={4}
                  lg={4}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetailId}
                      name={"yearDetailId"}
                      options={this.props.yearDetails?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={4}
                  xs={12}
                  md={4}
                  lg={4}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Division "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={divisionId}
                      name={"divisionId"}
                      options={this.props.yearDetails?.distinctDivision}
                      onChange={this.ChangeHandlerSearch}
                      isError={divisionError ? true : false}
                      errorText={divisionError ? divisionError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={1}
                  xs={12}
                  md={1}
                  lg={1}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Search"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3} md={3}></Grid>
              </Grid>
            </Grid>
          </form>
          {isSelectionError ? (
            <FormHelperText error>
              {"Select atleast one student"}
            </FormHelperText>
          ) : (
            " "
          )}
          <Grid item xs={12} sm={12} md={12}>
            <PrnNumberTable
              onSelectionChange={this.getRow}
              selection={this.state.selection}
              isActionColActive={false}
              generateCredential={this.generateCredential}
            />
          </Grid>

          <br />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearDetails: state.internshipDeclaration,
  userCredentialList: state.userCredential,
});
const mapDispatchToProps = {
  showNotification,
  getUserCredentialNull,
  getPrnNumberGenerate,
  getAlert,
  getAcademicYearInstituteWise,
  getYearDetails,
  getDistinctDivision,
  resetReducList,
  resetValues,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCredentialsForm);
