import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  workloadAllocatedListSuccess: [],
  interestedStudentListSuccess: [],
  notInterestedStudentListSuccess: [],
  changeStatusList: [],
  getInterestedStudentByIdStatus: [],
  getInterestedStudByIdStatus: [],
  getInterestedStudByIdStatusIsFetchStatus: false,
  getInterestedStudentByIdStatusIsFetchStatus: false,
  changeStatusFetchStatus: false,
  notIntrestedStudentIsFetch: false,
  isFetchWorkloadAllocatedListSuccess: false,
  intrestedStudentIsFetch: false,
  employeeList: [],
  isFetchEmp: false,
  subjectList: [],
  isFetchSubject: false,
  departmentList: [],
  isFetchDepartment: false,
  batchList: [],
  isFetchBatch: true,
  getSubjectist: [],
  isFetchGetSubjectist: false,
};

let URL = endpoints.employeeWorkload;
let URL1 = endpoints.intrestedAndNotIntrestedStudent;
let URL2 = endpoints.internshipIntrest;
const workloadAllocationSlice = createSlice( {
  name: "workloadAllocationList",
  initialState,
  reducers: {
    workloadAllocatedList: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        workloadAllocatedListSuccess: row,
        isFetchWorkloadAllocatedListSuccess: true,
      };
    },
    getSubjectList: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        getSubjectist: row,
        isFetchGetSubjectist: true,
      };
    },
    employeeListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        employeeList: row,
        isFetchEmp: true,
      };
    },
    departmentListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        departmentList: row,
        isFetchDepartment: true,
      };
    },
    batchListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        batchList: row,
        isFetchBatch: true,
      };
    },
    subjectListSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        subjectList: row,
        isFetchSubject: true,
      };
    },
    getInterestedStudentByIdSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        getInterestedStudentByIdStatus: row,
        getInterestedStudentByIdStatusIsFetchStatus: true,
      };
    },
    getInterestedStudByIdSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        getInterestedStudByIdStatus: row,
        getInterestedStudByIdStatusIsFetchStatus: true,
      };
    },
    resetInterestedStudent: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        getInterestedStudByIdStatus: [],
        getInterestedStudByIdStatusIsFetchStatus: false,
      };
    },

    changeStatusSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { data } = action.payload;
      return {
        ...state,
        changeStatusList: data,
        changeStatusFetchStatus: true,
      };
    },

    interestedStudentSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        interestedStudentListSuccess: row,
        intrestedStudentIsFetch: true,
      };
    },
    notInterestedStudentSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        notInterestedStudentListSuccess: row,
        notIntrestedStudentIsFetch: true,
      };
    },

    admissionListFail: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...state,
        documentVerificationDataList: [],
        isFetchDocumentVerificationDataList: false,
      };
    },
    resetSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...initialState,
      };
    },
  },
} );

export const {
  employeeListSuccess,
  departmentListSuccess,
  batchListSuccess,
  subjectListSuccess,
  resetInterestedStudent,
  workloadAllocatedList,
  getInterestedStudentByIdSuccess,
  getInterestedStudByIdSuccess,
  changeStatusSuccess,
  interestedStudentSuccess,
  notInterestedStudentSuccess,
  resetSuccess,
  getSubjectList,
} = workloadAllocationSlice.actions;

export default workloadAllocationSlice.reducer;
// /employee-work-load/by-department-batch?departmentId=34c16d57-c935-46b1-abe8-92ce1caa3065&batchId=5533f0db-10cf-46f1-be96-4ae222f9c795
export const getWorkloadAllocatedList =
  ( { semesterDetailId, divisionId, batchId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        console.log( semesterDetailId );
        console.log( divisionId );
        console.log( batchId );
        const response = apiGet( {
          url:
            URL +
            "/by-semister-detail-division-batch?semisterDetailId=" +
            semesterDetailId +
            "&divisionId=" +
            divisionId +
            "&batchId=" +
            batchId,
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const companyList = data.data;
            console.log( companyList );
            // let index = 0;
            let row = [];
            let index = 0;
            row = companyList.map( ( data ) =>
            {
              index = index + 1;
              let companyData = {
                index: index,
                id: data.id,
                divisionId: data.division.id,
                department: data.department.id,
                semesterDetailId: data.semisterDetail.id,
                semesterId: data.semisterDetail.semister.id,
                subjectList: data.subject === null ? "" : data.subject.name,
                subjectScheme: data.subjectDetail.schemeHeadId.name,
                userRegistrationId: data.userRegistration.id,
                credit: data.credit,
                noOfLectures: data.noOfLectures,
                batchId: data.batch.id,
                yearDetailId: data.division.yearDetailId,
                subjectSchemeId: data.subjectDetail.id,
                subjectId: data.subject === null ? "" : data.subject.id,
                name:
                  data.userRegistration === null
                    ? ""
                    : data.userRegistration.firstName +
                    " " +
                    data.userRegistration.middleName +
                    " " +
                    data.userRegistration.lastName,
              };
              return companyData;
            } );
            dispatch( workloadAllocatedList( { row } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const getSubjectBySemesterDetailDivBatch =
  ( { semesterDetailId, divisionId, batchId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        console.log( semesterDetailId );
        console.log( divisionId );
        console.log( batchId );
        const response = apiGet( {
          url:
            URL +
            "/get-subject-by-semister-detail-division-batch?semisterDetailId=" +
            semesterDetailId +
            "&divisionId=" +
            divisionId +
            "&batchId=" +
            batchId,
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const companyList = data.data;
            console.log( companyList );
            // let index = 0;
            let row = [];
            let index = 0;
            row = companyList.map( ( data ) =>
            {
              index = index + 1;
              let companyData = {
                index: index,
                userId: data.id,
                divisionId: data.division.id,
                department: data.department.id,
                semesterDetailId: data.semisterDetail.id,
                semesterId: data.semisterDetail.semister.id,
                subjectList: data.subject === null ? "" : data.subject.name,
                subjectScheme: data.subjectDetail.schemeHeadId.name,
                userRegistrationId: data.userRegistration.id,
                credit: data.credit,
                noOfLectures: data.noOfLectures,
                batchId: data.batch.id,
                yearDetailId: data.division.yearDetailId,
                subjectSchemeId: data.subjectDetail.id,
                id: data.subjectDetail.id,
                name: data.subject === null ? "" : data.subject.name,
                userName:
                  data.userRegistration === null
                    ? ""
                    : data.userRegistration.firstName +
                    " " +
                    data.userRegistration.middleName +
                    " " +
                    data.userRegistration.lastName,
              };
              return companyData;
            } );
            dispatch( getSubjectList( { row } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
export const getEmployeeBySemesterDetailIdAndBatchId =
  ( { departmentId, batchId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url:
            URL +
            "/by-department-batch?departmentId=" +
            departmentId +
            "&batchId=" +
            batchId,
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const companyList = data.data;
            console.log( companyList );
            let row = [];
            let index = 0;
            row = companyList.map( ( data ) =>
            {
              index = index + 1;
              let companyData = {
                index: index,
                id: data.userRegistration.id,
                name:
                  data.userRegistration === null
                    ? ""
                    : data.userRegistration.firstName +
                    " " +
                    data.userRegistration.middleName +
                    " " +
                    data.userRegistration.lastName,
              };
              return companyData;
            } );

            dispatch( employeeListSuccess( { row } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const getDepartmentBySemisterDetailAndYearDetail =
  ( { divisionId, semisterID } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url:
            URL +
            "/get-department-by-semister-detail-division?semisterDetailId=" +
            semisterID +
            "&divisionId=" +
            divisionId,
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const batchList = data.data;
            console.log( batchList );
            let index = 0;
            let row = [];
            batchList.map( ( data1 ) =>
            {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.department.id,
                name: data1.department.name,
                isActive: data1.department.isActive,
              };
              row.push( bData );
              return data1;
            } );

            dispatch( departmentListSuccess( { row } ) );
            return row;
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const getBatchListByYearDetailIdAndSemisterId =
  ( { divisionId, semisterID } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url:
            URL +
            "/get-batch-by-semister-detail-division?semisterDetailId=" +
            semisterID +
            "&divisionId=" +
            divisionId,
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const batchList = data.data;
            console.log( batchList );
            let index = 0;
            let row = [];
            batchList.map( ( data1 ) =>
            {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.batch.id,
                name: data1.batch.batchName,
                isActive: data1.batch.isActive,
              };
              row.push( bData );
              return data1;
            } );

            dispatch( batchListSuccess( { row } ) );
            return row;
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const getSubjectBySemesterDetailIdAndBatchIdAndUserId =
  ( { departmentId, batchId, userId } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url:
            URL +
            "/by-department-batch-teacher?departmentId=" +
            departmentId +
            "&batchId=" +
            batchId +
            "&userRegistrationId=" +
            userId,
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const companyList = data.data;
            console.log( companyList );
            // let index = 0;
            let row = [];
            let index = 0;
            row = companyList.map( ( data ) =>
            {
              index = index + 1;
              let companyData = {
                index: index,
                id: data.subjectDetail.id,
                name: data.subjectDetail.schemeHeadId.name,
              };
              return companyData;
            } );
            dispatch( subjectListSuccess( { row } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
// export const resetWorkloadAllocatedList =
// () =>
// async (dispatch) => {

//         dispatch(resetWorkloadAllocatedListSuccess());

// };
export const getInterestedStudentById = () => async ( dispatch ) =>
{
  try
  {
    // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
    // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
    // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
    const response = apiGet( {
      url: URL2 + "/get-intrested-student-by-user-id",
    } ).then( ( { data, success } ) =>
    {
      console.log( data );
      if ( success )
      {
        const companyList = data.data;
        console.log( companyList );
        let index = 0;
        let row = [];
        row = companyList.map( ( data ) =>
        {
          index = index + 1;
          let companyData = {
            index: index,
            //     internshipDeclareId: data.id,
            //     companyId: data.company.id,
            id: data.id,
            companyName: data.companyName,
            companyAddress: data.companyAdd,
            internshipIntrestedStudentDocument:
              data.internshipIntrestedStudentDocument.map( ( docData, index ) =>
              {
                let docDataDetails = {
                  index: index + 1,
                  name: docData.docName,
                  fileName: docData.fileName,
                  id: docData.id,
                };
                return docDataDetails;
              } ),
            //     venue: data.venue,
            durationFromDate: data.durationFromDate,
            durationToDate: data.durationToDate,
            //     date:data.declareDate,
            //     status: data.isActive,
            //     yearDetailId: data.yearDetailId,
            //     // designationId: data.designationId,
            stipend: data.stipend,
            designation: data.designation,
            internshipType: data.internshipType.id,
            remark: data.remark,
          };
          return companyData;
        } );
        dispatch( getInterestedStudentByIdSuccess( { row } ) );
      }
      return success;
    } );
    return response;
  } catch ( e )
  {
    return console.error( e.message );
  }
};

export const getInterestedStudById =
  ( { id } ) =>
    async ( dispatch ) =>
    {
      try
      {
        // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
        // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
        // http://localhost:8092/v1/internship-declare/by-intern-type?fromDate=26-05-2022&toDate=30-05-2022&internshipType=15ec5d58-2050-4ccf-8865-9b14b92a1251
        const response = apiGet( {
          url: URL2 + "/" + id,
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const getStudById = data.data;
            dispatch( getInterestedStudByIdSuccess( { row: getStudById } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };

export const getIntrestedStudentList =
  ( { id } ) =>
    async ( dispatch ) =>
    {
      try
      {
        // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
        // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
        // http://localhost:8092/v1/internship-intrested-student/5b0539f3-de7c-44da-a7f6-1afadb3f985d/by-internship-declare?intrestStatus=0
        const response = apiGet( {
          url: URL1 + "/" + id + "/by-internship-declare?intrestStatus=1",
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const studentList = data.data;
            console.log( studentList );
            // let index = 0;
            let row = [];
            let index = 0;
            row = studentList.map( ( data ) =>
            {
              index = index + 1;
              let studentData = {
                index: index,
                id: data.id,
                studentName:
                  data.getUserRegistration === null
                    ? ""
                    : data.getUserRegistration.firstName +
                    " " +
                    data.getUserRegistration.middleName +
                    " " +
                    data.getUserRegistration.lastName,
                userRegistrationId:
                  data.getUserRegistration === null
                    ? ""
                    : data.getUserRegistration.id,
                companyName: data.companyName === null ? "" : data.companyName,
                designation: data.designation === null ? "" : data.designation,
                durationFromDate: data.durationFromDate,
                durationToDate: data.durationToDate,
                stipend: data.stipend,
                remark:
                  data.getUserRegistration === null
                    ? ""
                    : data.getUserRegistration.remark,
                rollNo: data.rollNo,
                internshipIntrestedStudentDocument:
                  data.internshipIntrestedStudentDocument.map(
                    ( docData, index ) =>
                    {
                      let docDataDetails = {
                        index: index + 1,
                        name: docData.docName,
                        fileName: docData.fileName,
                        id: docData.id,
                      };
                      return docDataDetails;
                    }
                  ),
              };
              return studentData;
            } );
            dispatch( interestedStudentSuccess( { row } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
export const getNotIntrestedStudentList =
  ( { id } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url: URL1 + "/" + id + "/by-internship-declare?intrestStatus=0",
        } ).then( ( { data, success } ) =>
        {
          console.log( data );
          if ( success )
          {
            const studentList = data.data;
            console.log( studentList );
            // let index = 0;
            let row = [];
            let index = 0;
            row = studentList.map( ( data ) =>
            {
              index = index + 1;
              let studentData = {
                index: index,
                id: data.id,
                studentName:
                  data.getUserRegistration === null
                    ? ""
                    : data.getUserRegistration.firstName +
                    " " +
                    data.getUserRegistration.middleName +
                    " " +
                    data.getUserRegistration.lastName,
                userRegistrationId:
                  data.getUserRegistration === null
                    ? ""
                    : data.getUserRegistration.id,
                companyName: data.companyName === null ? "" : data.companyName,
                designation: data.designation === null ? "" : data.designation,
                durationFromDate: data.durationFromDate,
                durationToDate: data.durationToDate,
                stipend: data.stipend,
                remark:
                  data.getUserRegistration === null
                    ? ""
                    : data.getUserRegistration.remark,
                rollNo: data.rollNo,
                internshipIntrestedStudentDocument:
                  data.internshipIntrestedStudentDocument.map(
                    ( docData, index ) =>
                    {
                      let docDataDetails = {
                        index: index + 1,
                        name: docData.docName,
                        fileName: docData.fileName,
                        id: docData.id,
                      };
                      return docDataDetails;
                    }
                  ),
              };
              return studentData;
            } );
            dispatch( notInterestedStudentSuccess( { row } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
export const resetValues = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
