import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  reserchJournalPublication: [],
  isFetch: false,

  paperPosterPresented: [],
  isFetchPaperPoster: false,
};
let URL = endpoints.resourcePerson;

const reserchJournalPublicationSlice = createSlice({
  name: "reserchJournalPublication",
  initialState,
  reducers: {
    reserchJournalPublicationSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        reserchJournalPublication: row,
        isFetch: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { reserchJournalPublicationSuccess, resetSuccess } =
  reserchJournalPublicationSlice.actions;

export default reserchJournalPublicationSlice.reducer;
//
export const getReserchJournalPublication = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"formType":"' + "2" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            pressTitle: data1.pressTitle === null ? "" : data1.pressTitle,
            pressDate: data1.pressDate === null ? "" : data1.pressDate,
            issnIsbn: data1.issnIsbn === null ? "" : data1.issnIsbn,
            isUgcCare: data1.isUgcCare === 1 ? "Yes" : "No",
            impactFactor: data1.impactFactor === null ? "" : data1.impactFactor,
            isFirstAuthor: data1.isFirstAuthor === 1 ? "Yes" : "No",
            otherAuthor: data1.otherAuthor === null ? "" : data1.otherAuthor,
          };
          row.push(bData);
          return data1;
        });
        dispatch(reserchJournalPublicationSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminReserchJournalPublication =
  ({ userRegistration }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-user-registration?userRegistrationId=" +
          userRegistration +
          "&formType=2",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              pressTitle: data1.pressTitle === null ? "" : data1.pressTitle,
              pressDate: data1.pressDate === null ? "" : data1.pressDate,
              issnIsbn: data1.issnIsbn === null ? "" : data1.issnIsbn,
              isUgcCare: data1.isUgcCare === 1 ? "Yes" : "No",
              impactFactor:
                data1.impactFactor === null ? "" : data1.impactFactor,
              isFirstAuthor: data1.isFirstAuthor === 1 ? "Yes" : "No",
              otherAuthor: data1.otherAuthor === null ? "" : data1.otherAuthor,
            };
            row.push(bData);
            return data1;
          });
          dispatch(reserchJournalPublicationSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetData = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getReserchJournalPublicationByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-publication-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&formType=2",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              index: index,
              id: data1.id,
              pressTitle: data1.pressTitle === null ? "" : data1.pressTitle,
              pressDate: data1.pressDate === null ? "" : data1.pressDate,
              issnIsbn: data1.issnIsbn === null ? "" : data1.issnIsbn,
              isUgcCare: data1.isUgcCare === 1 ? "Yes" : "No",
              impactFactor:
                data1.impactFactor === null ? "" : data1.impactFactor,
              isFirstAuthor: data1.isFirstAuthor === 1 ? "Yes" : "No",
              otherAuthor: data1.otherAuthor === null ? "" : data1.otherAuthor,
            };
            row.push(bData);
            return data1;
          });
          dispatch(reserchJournalPublicationSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getReserchJournalPublicationWithoutFilter =
  () => async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-employee-publication-list-by-limit?formType=2&limit=20",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              index: index,
              id: data1.id,
              pressTitle: data1.pressTitle === null ? "" : data1.pressTitle,
              pressDate: data1.pressDate === null ? "" : data1.pressDate,
              issnIsbn: data1.issnIsbn === null ? "" : data1.issnIsbn,
              isUgcCare: data1.isUgcCare === 1 ? "Yes" : "No",
              impactFactor:
                data1.impactFactor === null ? "" : data1.impactFactor,
              isFirstAuthor: data1.isFirstAuthor === 1 ? "Yes" : "No",
              otherAuthor: data1.otherAuthor === null ? "" : data1.otherAuthor,
            };
            row.push(bData);
            return data1;
          });
          dispatch(reserchJournalPublicationSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
