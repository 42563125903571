import endpoint from "../config/endpoints";

export const AlumniInternshipJson = {
  apiBaseURL: endpoint.postInternship,
  screenTitle: "Post an Internship",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Title",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "TitleMaster",
      dataKey: "title",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "TitleMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "titleNew",
      tableDataKey: "title",
      open: "other",
      error: "title",
    },
    {
      label: "Company name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "companyName",
      isMandatory: true,
    },
    {
      label: "Company website",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "companyWebsite",
      isMandatory: true,
    },
    {
      label: "Registration link",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "duration",
      isMandatory: true,
    },
    {
      label: "Location",
      controlType: "textarea",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "location",
      isMandatory: true,
    },
  ],
};
