export const AttendaceReportJson = {
  screenTitle: "Attendance Reports",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  fieldMeta: [
    {
      label: "Academic Year",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "acadamicMaster",
      getListId: 'acadamicYear,yearDetail',
      dataKey: "acadamicYear",
      isMandatory: true,
    },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearMaster",
      getListId: "yearDetail,acadamicYear",
      dataKey: "yearDetail",
      isMandatory: true,
    },

    {
      label: "Semester Details",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      getListFrom: 'acadamicYear,yearDetail',
      getListId: 'semisterDetailId',
      masterName: "semisterMaster",
      dataKey: "semisterDetailId",
      isMandatory: true,
    },

    {
      label: "Division",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "divisionMaster",
      getListId: 'divisionId,semisterDetailId',
      getListFrom: "yearDetail",
      dataKey: "divisionId",
      isMandatory: true,
    },
    {
      label: "Subject",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      getListFrom: 'divisionId',
      getListId: 'divisionId,semisterDetailId',
      masterName: "subjectMaster",
      dataKey: "subjectDetailIds",
      isMandatory: true,
    },

    {
      label: "Batch",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      getListFrom: 'divisionId',
      getListId: 'batchId',
      masterName: "batchMaster",
      dataKey: "batchId",
      isMandatory: true,
    },
    {
      label: "Teacher",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      getListFrom: 'batchId',
      // getListId: 'batchId,',
      masterName: "teacherMaster",
      dataKey: "teacherId",
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-l"
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-g"
    },
  ],
};


