import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  feedBack: [],
  isFetch: false,
  feedBackDetailsByfeedBack: [],
  isFetchfeedBackDetailsByfeedBack: false,
  feedBackById: {},
  isFetchFeedBackById: false,
  feedBackDetailById: {},
  isFetchFeedBackDetailById: false,

  empPreview: [],
  isFetchEmpPreview: false,

  studentPreview: [],
  isFetchStudentPreview: false,
};
let URL = endpoints.feedBack;
let feedBackDetails = endpoints.feedBackDetails;
const feedBackSlice = createSlice({
  name: "feedBack",
  initialState,
  reducers: {
    feedBackDetailByFeedBackId: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        feedBackDetailsByfeedBack: row,
        isFetchfeedBackDetailsByfeedBack: true,
      };
    },

    studentPreviewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentPreview: row,
        isFetchStudentPreview: true,
      };
    },

    empPreviewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        empPreview: row,
        isFetchEmpPreview: true,
      };
    },

    feedBackListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        feedBack: row,
        isFetch: true,
      };
    },
    feedBackByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { content } = action.payload;
      return {
        ...state,
        feedBackById: content,
        isFetchFeedBackById: true,
      };
    },
    feedBackDetailsByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { content } = action.payload;
      return {
        ...state,
        feedBackDetailById: content,
        isFetchFeedBackDetailById: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        empPreview: [],
        isFetchEmpPreview: false,
        studentPreview: [],
        isFetchStudentPreview: false,
        feedBackById: [],
        isFetchFeedBackById: false,
        feedBackDetailsByfeedBack: [],
        isFetchfeedBackDetailsByfeedBack: false,
      };
    },
    resetFeedBackDetailsSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        feedBackDetailById: [],
        isFetchFeedBackDetailById: false,
      };
    },
  },
});

export const {
  feedBackDetailByFeedBackId,
  feedBackListSuccess,
  feedBackByIdSuccess,
  feedBackDetailsByIdSuccess,
  empPreviewSuccess,
  resetSuccess,
  studentPreviewSuccess,
  resetFeedBackDetailsSuccess,
} = feedBackSlice.actions;

export default feedBackSlice.reducer;

export const getfeedBackDetailsByFeedBackId =
  ({ feedBackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + feedBackId + "/get-feedback-head-details",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            const options = data1.feedbackHeadDetailsOptions.map((option) => {
              return option.optionForSelection;
            });
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              question: data1.question,
              optionType:
                data1.optionType == 1
                  ? "Description"
                  : data1.optionType == 0
                  ? "optional"
                  : "Multiselect",
              feedbackHeadDetailsOptions:
                options.length === 0 ? "-" : options.join(),
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(feedBackDetailByFeedBackId({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getFeedBack = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data.content;
        let index = 0;
        const row = content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            remark: data1.remark != "" ? data1.remark : "-",
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(feedBackListSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getfeedBackId =
  ({ feedbackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + feedbackId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;

          dispatch(feedBackByIdSuccess({ content }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getfeedBackDetailId =
  ({ feedbackDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: feedBackDetails + "/" + feedbackDetailId,
      }).then(({ data, success }) => {
        if (success) {
          let content = data.data;
          content = {
            ...content,
            feedbackHeadDetailsOptions: content.feedbackHeadDetailsOptions.map(
              (feedbackHeadDetailsOptionData, index) => {
                let feedBack = {
                  index: index + 1,
                  id: feedbackHeadDetailsOptionData.id,
                  option: feedbackHeadDetailsOptionData.optionForSelection,
                  orderNo: feedbackHeadDetailsOptionData.orderNo,
                };
                return feedBack;
              }
            ),
          };

          dispatch(feedBackDetailsByIdSuccess({ content }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getfeedBackDetailsByFeedBackIdEmpPreview =
  ({ feedBackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + feedBackId + "/get-feedback-head-details",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            const options = data1.feedbackHeadDetailsOptions.map((option) => {
              return option.optionForSelection;
            });
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              question: data1.question,
              remark: data1.feedbackHead.remark,
              name: data1.feedbackHead.name,
              optionType:
                data1.optionType == 1
                  ? "Description"
                  : data1.optionType == 0
                  ? "optional"
                  : "Multiselect",
              feedbackHeadDetailsOptions:
                options.length === 0 ? "-" : options.join(),
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(empPreviewSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getfeedBackDetailsByFeedBackIdStudentPreview =
  ({ feedBackId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + feedBackId + "/get-feedback-head-details",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          const row = content.map((data1) => {
            const options = data1.feedbackHeadDetailsOptions.map((option) => {
              return option.optionForSelection;
            });
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              question: data1.question,
              remark: data1.feedbackHead.remark,
              name: data1.feedbackHead.name,
              optionType:
                data1.optionType == 1
                  ? "Description"
                  : data1.optionType == 0
                  ? "optional"
                  : "Multiselect",
              feedbackHeadDetailsOptions:
                options.length === 0 ? "-" : options.join(),
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(studentPreviewSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetFeedBackDetails = () => async (dispatch) => {
  try {
    dispatch(resetFeedBackDetailsSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
