import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../Masters/OnlineModule/Class/class.slice";
import { getAdmissionList } from "../StudentFeesCollection/FeesCollection.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { getStudentById } from "../BasicDetailsPost/basicDetails.slice";
import SearchEvent from "../Event/Event Management/SearchEvent";
import { AdminEditStudentDetailsJson } from "../../DynamicFormsJson/AdminEditStudentDetailsJson";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import {
  getStudentListByAcademicYear,
  getStudentListByPRN,
  getStudentListByStudName,
  getStudentListByYearDetail,
} from "./applicantAdmission.slice";
import StudentMasterPost from "../BasicDetailsPost/index";
let docs;
class AdminEditStudentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      searchBy: "academic",
      academicYear: "",
      searchName: "",
      academicYearId: "",
      admissionForYear: "",
      typeOfAdmission: "",
      searchValue: "",
      applicationId: "",
      openForm: true,
      openDetailForm: false,
      showLoader: false,
      formErrors: [],
      dynamicMasterData: {
        academicYearData: this.props.academicYearList?.academicYear,
        yearDetailMaster: this.props.classList?.class,
        searchByMaster: [
          { id: "academic", name: "Academic Year" },
          { id: "prn", name: "PRN" },
          { id: "studentName", name: "Student Name" },
          { id: "yearDetail", name: "Year Detail" },
        ],
      },
    };
  }

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      title: "Student Name",
      name: "firstName",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
      keyName:"firstName,lastName,fatherName,motherName",
      isFormat:true,
      type:0
    },
    {
      title: "PRN No.",
      name: "prnNo",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      title: "Contact Number",
      name: "studContact",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      title: "Email Id",
      name: "studEmail",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      title: "Year Detail",
      name: "yearDetail",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  onCancelForm = () => {
    this.setState({
      openForm: true,
      openDetailForm: false,
    });
  };
  editStudentInfo = (data) => {
    this.setState({
      openForm: false,
      openDetailForm: true,
    });
    this.props.getStudentById({ studId: data.id }).then((res) => {
      if (res) {
        // this.props.onSave( true );
      } else {
        this.props.getAlert({
          message: "Something went wrong ",
        });
      }
    });
  };

  componentDidMount() {
    this.props.resetReducList();
    const ay = localStorage.getItem("acadamicYearId");
    this.setState({
      showLoader: true,
    });
    this.props
      .getStudentListByAcademicYear({
        academicYearId: ay,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    this.setState({
      showLoader: true,
    });
    this.props.getClass().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYearData: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.classList !== nextProps.classList) {
      if (this.props.classList.class !== nextProps.classList.class) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.classList.class.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;

    if (name === "searchBy") {
      this.setState({
        ...this.state,
        [name]: value,
        academicYearId: "",
        searchName: "",
        formErrors: [],
      });
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  submitHandler = (data) => {
    // e.preventDefault();
    const { academicYearId, searchName, searchBy } = this.state;
    const ay = localStorage.getItem("acadamicYearId");
    if (data.searchBy == "academic") {
      this.setState({
        showLoader: true,
      });
      if (ay) {
        this.props
          .getStudentListByAcademicYear({
            academicYearId: ay,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
    } else if (data.searchBy == "prn") {
      this.setState({
        showLoader: true,
      });
      this.props
        .getStudentListByPRN({
          prnNo: data.prnNo,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      // }
    } else if (data.searchBy == "yearDetail") {
      this.setState({
        showLoader: true,
      });
      if (ay) {
        this.props
          .getStudentListByYearDetail({
            yearDetail: data.yearDetail,
            academicYearId: ay,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
      // }
    } else {
      this.setState({
        showLoader: true,
      });
      this.props
        .getStudentListByStudName({
          studName: data.studentName,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      // }
    }
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.studentList?.studentList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["studentName"] &&
          currentRow["studentName"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["studContact"] &&
          currentRow["studContact"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["studEmail"] &&
          currentRow["studEmail"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["prnNo"] &&
          currentRow["prnNo"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  render() {
    const { academicYearId, searchBy, searchName, openForm, openDetailForm } =
      this.state;
    const { classList, academicYearList } = this.props;
    const { academicYearError, searchByError, nameError } =
      this.state.formErrors;
    return (
      <>
        {openForm && (
          <>
            {/* <Paper sx={{ padding: 2, borderRadius: 2 }} elevation={0}> */}
            <DynamicMainScreen
              // onAddButtonClick={this.onAddButtonClick}
              screenTitle={AdminEditStudentDetailsJson.screenTitle}
              fieldMeta={AdminEditStudentDetailsJson.fieldMeta}
              buttonCenter={AdminEditStudentDetailsJson.buttonCenter}
              showPdfDownload={false}
              showExcelDownload={false}
              tableColumnsToFilter={
                AdminEditStudentDetailsJson.tableColumnsToFilter
              }
              showAddButton={false}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={this.columns}
              tableData={this.props.studentList?.studentList}
              showPegination={false}
              showHeadEdit={true}
              showLoader={this.state.showLoader}
              rowEdit={this.editStudentInfo}
              // onDelete={this.onDelete}
              onSearchData={this.submitHandler}
              baseIdColumn={AdminEditStudentDetailsJson.baseIdColumn}
              apiBaseURL={AdminEditStudentDetailsJson.apiBaseURL}
            />
            {/* <form
                onSubmit={this.submitHandler}
                autoComplete="off"
                noValidate={true}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid item sx={{ m: 1 }} xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Admin Edit Student Details"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  {searchBy == "academic" ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5.8}
                      lg={5.8}
                      columnSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid sx={{ ml: -5 }} item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Search By"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className="text-black"
                            name="searchBy"
                            id="searchBy"
                            value={searchBy}
                            onChange={this.changeHandler}
                            size="small"
                            sx={{ ml: -3 }}
                          >
                            <MenuItem value="academic">Academic Year</MenuItem>
                            <MenuItem value="prn">PRN Number</MenuItem>
                            <MenuItem value="studName">Student Name</MenuItem>
                          </Select>
                          <FormHelperText error>
                            {searchByError ? searchByError : " "}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5.8}
                      lg={5.8}
                      columnSpacing={2}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Search By"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className="text-black"
                            name="searchBy"
                            id="searchBy"
                            value={searchBy}
                            onChange={this.changeHandler}
                            size="small"
                          >
                            <MenuItem value="academic">Academic Year</MenuItem>
                            <MenuItem value="prn">PRN Number</MenuItem>
                            <MenuItem value="studName">Student Name</MenuItem>
                          </Select>
                          <FormHelperText error>
                            {searchByError ? searchByError : " "}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5.3}
                    lg={5.3}
                    container
                    justifyContent="space-evenly"
                  >
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label={
                            searchBy == "prn"
                              ? "PRN Number"
                              : searchBy == "academic"
                              ? ""
                              : searchBy !== ""
                              ? "Student Name"
                              : ""
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        {searchBy == "academic" ? (
                          <Grid
                            container
                            justifyContent="flex-start"
                            item
                            xs={2}
                            sm={2}
                            md={2}
                          >
                            <Grid sx={{ mt: -3 }} item xs={12} sm={12} md={12}>
                              <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="Search"
                                fullWidth={true}
                              />
                            </Grid>
                          </Grid>
                        ) : searchBy !== "" ? (
                          <TextField
                            color="primary"
                            name="searchName"
                            size="small"
                            value={searchName}
                            fullWidth
                            onChange={this.changeHandler}
                            error={nameError ? true : false}
                            helperText={
                              nameError
                                ? searchBy === "prn"
                                  ? "*PRN number required"
                                  : "*Student name required"
                                : " "
                            }
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                    </>
                  </Grid>
                  {searchBy === "prn" || searchBy === "studName" ? (
                    <Grid
                      container
                      justifyContent="flex-end"
                      direction="row"
                      xs={12}
                      sm={1}
                      md={1}
                      columnSpacing={2}
                      sx={{ marginTop: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name="Search"
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    sx={{ marginLeft: -1 }}
                    justifyContent="space-evenly"
                  >
                    <SearchEvent onSearch={this.onSearch} />
                  </Grid>
                  <br />
                  <Grid item sx={{ mr: 1, mt: 1 }} xs={12} sm={12} md={12}>
                    <DynamicTable
                      data={this.getFilteredTableData()}
                      // data={[]}
                      tableHead={this.columns}
                      showPegination={false}
                      rowEdit={this.editStudentInfo}
                      showHeadEdit={true}
                    />
                  </Grid>
                </Grid>
              </form> */}
            {/* </Paper> */}
          </>
        )}
        {openDetailForm && (
          <>
            <StudentMasterPost
              onCancelForm={this.onCancelForm}
              studFormOpen={true}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  studentList: state.applicantAdmission,
  classList: state.class,
});
const mapDispatchToProps = {
  getAlert,
  resetReducList,
  getStudentById,
  getStudentListByAcademicYear,
  getStudentListByPRN,
  getStudentListByYearDetail,
  getStudentListByStudName,
  getClass,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminEditStudentDetails);
