import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
const DocumentVerificationTable = ({
  rowView,
  onSelectionChange,
  selection,
  documentVerificationList,
}) => {
  const columns = [
    { name: "applicationNumber", title: "Applicant Id", minWidth: 90 },
    {
      name: "firstName",
      title: "Student Name",
      minWidth: 300,
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    {
      name: "phno",
      title: "Phone Number",
      minWidth: 150,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      name: "status",
      title: "Status",
      minWidth: 150,
      alignCell: "center",
      alignCenter: "center",
    },
    {
      title: "Action",
      minWidth: 100,
      name: "actions",
      renderCell: (params) => {
        const onClickView = (e) => {
          e.preventDefault();
          rowView(params.row.userApplicationId);
        };
        return (
          <>
            <div>
              <MyComponentWithIconProps
                statusImage={VisibilityIcon}
                color="primary"
                fontSize="medium"
                title="View"
                onClick={onClickView}
                sx={{ ml: 3.2 }}
              />
              &nbsp;&nbsp;
            </div>
          </>
        );
      },
    },
  ];

  return (
    <DynamicSelectTable
      rows={documentVerificationList.documentVerificationDataList}
      columns={columns}
      selection={selection}
      onSelectionChange={onSelectionChange}
      rowViewData={rowView}
      showView={true}
      SummarizeRoundedShow={false}
    />
  );
};

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentVerificationTable);
