import { Paper } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import * as msgConstant from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import { generateExcel } from "../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../components/Comman/GeneratePDF";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import RTTable from "../../../components/Comman/RT/RTTable";
import { Loading1 } from "../../../components/Loading1";
import AdminGalleryFolder from "./AdminGalleryFolder";
import { AdminGallaryColumns } from "../../../tableColumns/table-columns";
import { getFolder } from "./adminGallary.slice";
import endpoints from "../../../config/endpoints";
import { deleteAllFile } from "../../UploadFile/file.slice";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      fieldDataForSearch: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
    };
  }
  onCancelForm = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
      fieldData: {},
    });

    this.props.getFolder().then((resp) => {
      if (!resp) {
        this.props.getAlert({
          message: "Server Failed",
        });
      }
    });
  };

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
    this.props.getFolder().then((resp) => {
      if (!resp) {
        this.props.getAlert({
          message: "Server Failed",
        });
      }
    });
  };
  componentDidMount() {
    this.props.getFolder().then((resp) => {
      if (!resp) {
        this.props.getAlert({
          message: "Server Failed",
        });
      }
    });
  }
  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    let fieldData = {};
    const { fieldMeta } = this.props;
    fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        let date = new Date(newValue);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear() + 1;
        fieldData[currentField.getDate] = new Date(year, month, day);
      }
    });
    fieldData[fieldName] = newValue;
    this.setState({
      fieldData: { ...this.state.fieldData, ...fieldData },
    });
  };
  onDataChangeForSearch = (fieldName, newValue) => {
    this.setState({
      fieldDataForSearch: {
        ...this.state.fieldDataForSearch,
        [fieldName]: newValue,
      },
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    this.toggleFormTableVisibility();
  };
  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };

  rowEdit = (rowData) => {
    this.setState({
      fieldData: rowData,
    });
    this.toggleFormTableVisibility();
  };

  rowDelete = (rowData) => {
    console.log(rowData);

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onFormCancel = () => {
    const { getTableDataById = false } = this.props;
    if (getTableDataById) {
      this.props.onSearchData(this.state.fieldDataForSearch);
    } else {
      this.props.getTableData();
    }

    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta, type = "" } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };
    if (type != "") {
      dataToSave["formType"] = type;
    }
    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "multiSelect") {
        let multiselectValue = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey].join()
          : "";

        dataToSave[currentField.dataKey] = multiselectValue;
      } else if (currentField.controlType === "timePicker") {
        let timeValue =
          fieldData[currentField.dataKey] === ""
            ? ""
            : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
        dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
      } else if (currentField.controlType === "datepicker") {
        if (fieldData[currentField.dataKey]) {
          let date = new Date(fieldData[currentField.dataKey]);

          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();

          fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
        }

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        if (fieldData[currentField.tableDataKey] == currentField.open) {
          dataToSave[currentField.tableDataKey] = fieldData[
            currentField.dataKey
          ]
            ? fieldData[currentField.dataKey]
            : "";
        }
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        const { getTableDataById = false } = this.props;
        if (getTableDataById) {
          this.props.onSearchData(this.state.fieldDataForSearch);
        } else {
          this.props.getTableData();
        }
        if (isToggle) {
          this.toggleFormTableVisibility();
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        }
        if (!isToggle) {
          let fieldData = {};
          const { fieldMeta } = this.props;
          fieldMeta.map((currentField) => {
            if (currentField.defaultValue) {
              fieldData[currentField.dataKey] = currentField.defaultValue;
            }
            if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              fieldData[currentField.dataKey] = dataToSave[
                currentField.dataKey
              ]["id"]
                ? dataToSave[currentField.dataKey]["id"]
                : "";
            } else if (
              currentField.controlType === "autocomplete" &&
              !currentField.isRootLevelKey
            ) {
              fieldData[currentField.dataKey] = dataToSave[currentField.dataKey]
                ? dataToSave[currentField.dataKey]
                : "";
            }
          });

          this.setState({
            fieldData: fieldData,
            currentOperationMode: OperationMode.Add,
          });
        }
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.changeStatus(rowData);
      }
    });
  };
  changeStatus = (rowdata) => {
    this.setState({
      isLoading: true,
    });
    const isActive = rowdata.isActive == 1 ? 0 : 1;
    apiGet({
      url:
        endpoints.folder +
        "/change-active-status?id=" +
        rowdata.id +
        "&status=" +
        isActive,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Status updated successfully" });
        this.props.getFolder().then((resp) => {
          if (!resp) {
            this.props.getAlert({
              message: "Server Failed",
            });
          }
        });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  onDelete = (rowdata) => {
    let list2 = [];
    rowdata.folderContents.map((data) => {
      list2.push(data.path);
    });

    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoints.folder + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.props.deleteAllFile({ fileNames: list2 }).then((success) => {
          if (success) {
            this.props.showNotification({
              msg: "Data Deleted successfully",
            });
          } else {
            this.props.getAlert({
              message: "Failed to delete",
            });
          }
        });
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getFolder().then((resp) => {
          if (!resp) {
            this.props.getAlert({
              message: "Server Failed",
            });
          }
        });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { folder } = this.props;
    const { searchValue } = this.state;
    const columsFil = AdminGallaryColumns.filter((item) => {
      return item.canSearch;
    });
    return folder.folder.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      AdminGallaryColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      AdminGallaryColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    AdminGallaryColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  onFormSearch = () => () => {
    const { fieldDataForSearch } = this.state;
    const { searchList } = this.props;
    const dataToSave = {};
    searchList.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        let date = new Date(fieldDataForSearch[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldDataForSearch[currentField.dataKey] =
          day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldDataForSearch[
          currentField.dataKey
        ]
          ? fieldDataForSearch[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldDataForSearch[
          currentField.dataKey
        ]
          ? fieldDataForSearch[currentField.dataKey]
          : "";
      }
    });

    this.props.onSearchData(dataToSave);
  };
  render() {
    const {
      tableColumnsToFilter = [],
      fieldData,
      fieldDataForSearch,
      showTable,
      showForm,
      isLoading,
      fromDate,
      toDate,
    } = this.state;

    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={"Admin Gallery"}
          showSearchBox={true}
          showPdfDownload={false}
          generatePDF={this.generatePDF}
          showExcelDownload={false}
          generateExcel={this.generateExcel}
          showAddButton={true}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />

        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          {showForm && (
            <>
              <AdminGalleryFolder
                onSave={this.toggleFormTableVisibility}
                onCancel={this.toggleFormTableVisibility}
                fieldData={fieldData}
                onCancelForm={this.onCancelForm}
              />
              <br />
            </>
          )}

          {showTable && (
            <>
              <RTTable
                columns={AdminGallaryColumns}
                hiddenColumnNames={tableColumnsToFilter.map((item) => {
                  return !item.isChecked ? item.columnKeyName : "";
                })}
                showAddButtonInTable={false}
                tableData={this.getFilteredTableData()}
                rowEdit={this.rowEdit}
                rowDelete={this.rowDelete}
                onChangeStatus={this.onChangeStatus}
                rowAdd={this.rowAdd}
              />
            </>
          )}
        </Paper>
        {isLoading && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  folder: state.folder,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getFolder,
  deleteAllFile,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
