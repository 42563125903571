import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../Comman/Button";
import BasicDatePicker from "../Comman/DatePickerEx";
import { LabelCompo } from "../Comman/Label";
import MultipleSelect from "../Comman/MultipleSelect";
import TimePickerCompo from "../Comman/TimePicker";
class DynamicForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
    };
  }

  componentDidMount() {}

  onAutocompleteChange = (dataKey) => (name, newValue) => {
    this.props.onDataChange(dataKey, newValue);
  };
  // multiSelectHandler = (value, name) => {
  //   this.setState({
  //     ...this.state,
  //     [name]: typeof value === "string" ? value.split(",") : value,
  //   });
  // };
  onMultiSelectChange = (dataKey) => (value, name) => {
    this.props.onDataChange(
      dataKey,
      typeof value === "string" ? value.split(",") : value
    );
  };
  onTextFieldDataChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey) => (date) => {
    // date.setHours(0, 0, 0, 0);
    this.props.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onSave = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSave();
    }
  };

  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.onSubmitCheck();
    } else {
      return false;
    }
  };

  onSubmitCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.onSubmit === "compare") {
        const onSubmitParameter = currentField.onSubmitParameter;
        if (onSubmitParameter) {
          const compareWith = onSubmitParameter.split("-")[0].trim();
          const compareCond = onSubmitParameter.split("-")[1].trim();
          let isError = false;
          let condErrorText = "";
          let dataKeyValue = fieldData[currentField.dataKey];
          let compareWithValue = fieldData[compareWith];

          if (typeof dataKeyValue === "string") {
            const dateArr = dataKeyValue?.split("-");

            dataKeyValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }
          if (typeof compareWithValue === "string") {
            const dateArr = compareWithValue?.split("-");

            compareWithValue = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
          }
          switch (compareCond) {
            case "l":
              {
                if (!(dataKeyValue < compareWithValue)) {
                  isError = true;
                  condErrorText = "less than";
                }
              }
              break;
            case "le":
              {
                if (!(dataKeyValue <= compareWithValue)) {
                  isError = true;
                  condErrorText = "less than equal to";
                }
              }
              break;
            case "g":
              {
                if (!(dataKeyValue > compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than";
                }
              }
              break;
            case "ge":
              {
                if (!(dataKeyValue >= compareWithValue)) {
                  isError = true;
                  condErrorText = "greater than equal to";
                }
              }
              break;
            case "eq":
              {
                if (!(dataKeyValue === compareWithValue)) {
                  isError = true;
                  condErrorText = "equal to";
                }
              }
              break;
          }

          if (isError) {
            const compareWithFieldLabel = fieldMeta.filter(
              (item) => item.dataKey === compareWith
            )[0].label;
            formValid = false;
            formErrors = {
              ...formErrors,
              [currentField.dataKey]: {
                isError: true,
                errorText: `${currentField.label} should be ${condErrorText} ${compareWithFieldLabel}`,
              },
            };
          }
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldMeta, fieldData } = this.props;

    let formErrors = {};
    fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  onSaveAndNext = () => {
    if (this.checkValidationOnSubmit()) {
      this.props.onSaveAndNext();
    }
  };
  render() {
    const {
      fieldMeta,
      dynamicMasterData = {},
      fieldData,
      buttonCenter,
      formErrorsShows = {},
      marginTop,
      searchButton = true,
      PDFButton = false,
      EXCELButton = false,
    } = this.props;

    const { formErrors } = this.state;

    return (
      <>
        <Grid container sx={{ marginTop: 1 }} spacing={1}>
          {fieldMeta.map((currentField) => (
            <>
              {currentField.controlType !== "hideTextfield" &&
                currentField.controlType !== "hideAutocomplete" && (
                  <Grid
                    item
                    xs={currentField.xs}
                    sm={currentField.sm}
                    md={currentField.md}
                    sx={{ marginTop: currentField.marginTop }}
                  >
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        (currentField.isMandatory ? "*" : "") +
                        currentField.label
                      }
                    />

                    {currentField.controlType === "textfield" && (
                      <TextField
                        placeholder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey
                        )}
                        fullWidth
                        error={
                          formErrors[currentField.dataKey]?.isError
                            ? true
                            : formErrorsShows[currentField.dataKey]?.isError
                            ? true
                            : false
                        }
                        helperText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : formErrorsShows[currentField.dataKey]?.isError
                            ? formErrorsShows[currentField.dataKey].errorText
                            : " "
                        }
                      ></TextField>
                    )}

                    {currentField.controlType === "datepicker" && (
                      <BasicDatePicker
                        color="primary"
                        name="dob"
                        size="small"
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : null
                        }
                        {...(currentField.isMAxDate && {
                          maxDate: currentField.maxDate,
                        })}
                        {...(currentField.isMinDate && {
                          minDate: currentField.minDate,
                        })}
                        fullWidth
                        onChange={this.onDateChange(currentField.dataKey)}
                        isError={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey]?.isError
                            : formErrorsShows[currentField.dataKey]?.isError
                            ? formErrorsShows[currentField.dataKey]?.isError
                            : false
                        }
                        errorText={
                          formErrors[currentField.dataKey]?.errorText
                            ? formErrors[currentField.dataKey]?.errorText
                            : formErrorsShows[currentField.dataKey]?.errorText
                            ? formErrorsShows[currentField.dataKey]?.errorText
                            : " "
                        }
                      />
                    )}
                    {currentField.controlType === "autocomplete" && (
                      <AutoComplete
                        keyColName={"id"}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        options={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        onChange={this.onAutocompleteChange(
                          currentField.dataKey
                        )}
                        isError={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey]?.isError
                            : formErrorsShows[currentField.dataKey]?.isError
                            ? formErrorsShows[currentField.dataKey]?.isError
                            : false
                        }
                        errorText={
                          formErrors[currentField.dataKey]?.errorText
                            ? formErrors[currentField.dataKey]?.errorText
                            : formErrorsShows[currentField.dataKey]?.errorText
                            ? formErrorsShows[currentField.dataKey]?.errorText
                            : " "
                        }
                      />
                    )}
                    {currentField.controlType === "multiSelect" && (
                      <MultipleSelect
                        er={
                          formErrors[currentField.dataKey]?.errorText
                            ? formErrors[currentField.dataKey]?.errorText
                            : formErrorsShows[currentField.dataKey]?.errorText
                            ? formErrorsShows[currentField.dataKey]?.errorText
                            : ""
                        }
                        name1={currentField.dataKey}
                        value1={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : []
                        }
                        names={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        //onChange={this.multiSelectHandler}
                        onChange={this.onMultiSelectChange(
                          currentField.dataKey
                        )}
                      />
                    )}
                    {currentField.controlType === "timePicker" && (
                      <TimePickerCompo
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        onChange={this.timeChangeHandler(currentField.dataKey)}
                      />
                    )}
                    {currentField.controlType === "radiogroup" && (
                      <>
                        <RadioGroup
                          row
                          aria-label={currentField.label}
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKey]
                              ? fieldData[currentField.dataKey]
                              : ""
                          }
                          onChange={this.onRadioChange(currentField.dataKey)}
                          size="small"
                        >
                          {currentField.radioGroupItems.map((radioItem) => (
                            <FormControlLabel
                              value={radioItem.value}
                              control={<Radio />}
                              label={radioItem.label}
                            />
                          ))}
                        </RadioGroup>
                        {currentField.note &&
                          currentField.note.split("@").map((note) => {
                            return (
                              <>
                                <LabelCompo
                                  className="text-black"
                                  style={{ fontSize: 12, color: "#808080" }}
                                  label={note}
                                />
                                <br></br>
                              </>
                            );
                          })}
                      </>
                    )}
                  </Grid>
                )}
              {currentField.hideTextfield &&
                !fieldData[currentField.dataKey] && (
                  <Grid
                    item
                    xs={currentField.xs}
                    sm={currentField.sm}
                    md={currentField.md}
                    sx={{ marginTop: currentField.marginTop }}
                  />
                )}
              {currentField.controlType === "hideTextfield" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <Grid
                    item
                    xs={currentField.xs}
                    sm={currentField.sm}
                    md={currentField.md}
                    sx={{ marginTop: currentField.marginTop }}
                  >
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        (currentField.isMandatory ? "*" : "") +
                        currentField.label
                      }
                    />
                    <TextField
                      placeholder={currentField.placeHolder}
                      size="small"
                      color="primary"
                      name={currentField.label}
                      id={currentField.label}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      inputProps={{
                        maxLength:
                          currentField.maxLength && currentField.maxLength,
                      }}
                      type={
                        currentField.inputType ? currentField.inputType : "text"
                      }
                      onChange={this.onTextFieldDataChange(
                        currentField.dataKey
                      )}
                      fullWidth
                      error={
                        formErrors[currentField.dataKey]?.isError ? true : false
                      }
                      helperText={
                        formErrors[currentField.dataKey]?.isError
                          ? formErrors[currentField.dataKey].errorText
                          : " "
                      }
                    ></TextField>
                  </Grid>
                )}
              {currentField.hideAutocomplete &&
                !fieldData[currentField.dataKey] && (
                  <Grid
                    item
                    xs={currentField.xs}
                    sm={currentField.sm}
                    md={currentField.md}
                    sx={{ marginTop: currentField.marginTop }}
                  />
                )}
              {currentField.controlType === "hideAutocomplete" &&
                fieldData[currentField.tableDataKey] == currentField.open && (
                  <Grid
                    item
                    xs={currentField.xs}
                    sm={currentField.sm}
                    md={currentField.md}
                    sx={{ marginTop: currentField.marginTop }}
                  >
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        (currentField.isMandatory ? "*" : "") +
                        currentField.label
                      }
                    />
                    <AutoComplete
                      keyColName={"id"}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      options={
                        dynamicMasterData[currentField["masterName"]]
                          ? dynamicMasterData[currentField["masterName"]]
                          : []
                      }
                      onChange={this.onAutocompleteChange(currentField.dataKey)}
                      isError={
                        formErrors[currentField.dataKey]?.isError
                          ? formErrors[currentField.dataKey]?.isError
                          : formErrorsShows[currentField.dataKey]?.isError
                          ? formErrorsShows[currentField.dataKey]?.isError
                          : false
                      }
                      errorText={
                        formErrors[currentField.dataKey]?.errorText
                          ? formErrors[currentField.dataKey]?.errorText
                          : formErrorsShows[currentField.dataKey]?.errorText
                          ? formErrorsShows[currentField.dataKey]?.errorText
                          : " "
                      }
                    />
                  </Grid>
                )}
            </>
          ))}{" "}
          {!buttonCenter && (PDFButton || EXCELButton) && (
            <Grid
              item
              sm={12}
              xs={12}
              md={1.2}
              lg={1.2}
              columnSpacing={1}
              sx={{ marginTop: marginTop }}
              container
              justifyContent="space-evenly"
            >
              {PDFButton && !EXCELButton && (
                <>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to PDF"
                      fullWidth={true}
                      onClick={this.onSave}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12} md={8} lg={8} />
                </>
              )}
              {EXCELButton && !PDFButton && (
                <>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to EXCEL"
                      fullWidth={true}
                      onClick={this.onSaveAndNext}
                    />
                  </Grid>

                  <Grid item sm={12} xs={12} md={8} lg={8} />
                </>
              )}
              {EXCELButton && PDFButton && (
                <>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to EXCEL"
                      fullWidth={true}
                      onClick={this.onSaveAndNext}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Export to PDF"
                      fullWidth={true}
                      onClick={this.onSave}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12} md={4} lg={4} />
                </>
              )}
            </Grid>
          )}
          {!buttonCenter && searchButton && (
            <Grid
              item
              sm={12}
              xs={12}
              md={1.2}
              lg={1.2}
              sx={{ marginTop: 3 }}
              container
              justifyContent="space-evenly"
            >
              <Grid item sm={12} xs={12} md={2} lg={2}>
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Search"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>

              <Grid item sm={12} xs={12} md={10} lg={10} />
            </Grid>
          )}
        </Grid>{" "}
        {buttonCenter && (PDFButton || EXCELButton) && (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="center"
          >
            {EXCELButton && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Export to EXCEL"
                  fullWidth={true}
                  onClick={this.onSaveAndNext}
                />
              </Grid>
            )}
            {PDFButton && (
              <Grid
                item
                sm={12}
                xs={12}
                md={1.8}
                lg={1.8}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Export to PDF"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>
            )}
          </Grid>
        )}
        {buttonCenter && searchButton && (
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="center"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Search"
                fullWidth={true}
                onClick={this.onSave}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
