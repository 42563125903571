import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiGet } from "../../../utils/api_service";
import EmployeeAppliedList from "../../Feedback/Employee Feedback/EmployeeAppliedList";
import StudentAppliedList from "../../Feedback/Student Feedback/AppliedStudentFeedback";
import { getNoticeFromEventId } from "../../Masters/Faculty/AcademicDetails/AdminNotice/notice.slice";
import { getEventAppliedStudent } from "../Event Management/event.slice";
import SearchEvent from "./SearchEvent";
import ShowEmpNotice from "./ShowEmpNotice";
import ShowStudNotice from "./ShowStudNotice";
import { getStudNoticeFromEventId } from "../../Masters/Faculty/AcademicDetails/StudentNotice/studentNotice.slice";
import { FontAwesomeIconCompo } from "../../../components/Comman/IconButton";
import downloadPdf, { downloadF } from "../../PDF/PDFDownload";
function AppliedStudentList({
  onCancel,
  getAlert,
  getNoticeFromEventId,
  getStudNoticeFromEventId,
  eventDetails,
  eventList,
  getEventAppliedStudent,
  eventDataForAppliedStudents,
  showNotification,
}) {
  const [searchValue, setSearchValue] = React.useState("");
  const [showEmpNotice, setShowEmpNotice] = React.useState(false);
  const [showStudNotice, setShowStudNotice] = React.useState(false);
  const [showStudFeedback, setShowStudFeedback] = React.useState(false);
  const [showEmpFeedback, setShowEmpFeedback] = React.useState(false);
  const [showAppliedList, setShowAppliedList] = React.useState(false);
  const [showAppliedData, setShowAppliedData] = React.useState({});
  const [showValue, setShowValue] = React.useState(0);
  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    // {
    //   name: "prnNo",
    //   title: "PRN No",
    //   positionCenter: false,
    //   showInscreen: true,
    //   alignCenter: "left",
    //   width: "10%",
    // },
    {
      name: "classCourse",
      title: "Institute and Course",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "25%",
    },
    {
      name: "division",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "rollNo",
      title: "Roll Number",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    // {
    //   name: "eventName",
    //   title: "Event Name",
    //   positionCenter: true,
    //   showInscreen: true,
    //   alignCenter: "center",
    //   width: "20%",
    // },
    {
      name: "isStatus",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  const getFilteredTableData = () => {
    return eventList?.eventAppliedStudent.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["prnNo"] &&
          currentRow["prnNo"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["classCourse"] &&
          currentRow["classCourse"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["rollNo"] &&
          currentRow["rollNo"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  React.useEffect(() => {
    console.log("dataaaa", eventDataForAppliedStudents);
    if (Object.keys(eventDataForAppliedStudents).length !== 0) {
      if (eventDataForAppliedStudents.isEmployeeFeedbackGenerated == 1) {
        setShowEmpFeedback(true);
      }
      if (eventDataForAppliedStudents.isEmployeeNoticeGenerated == 1) {
        setShowEmpNotice(true);
      }
      if (eventDataForAppliedStudents.isStudentFeedbackGenerated == 1) {
        setShowStudFeedback(true);
      }
      if (eventDataForAppliedStudents.isStudentNoticeGenerated == 1) {
        setShowStudNotice(true);
      }
    }
    console.log(eventDataForAppliedStudents.isEmployeeFeedbackGenerated);
  }, []);
  const onSearch = (value) => {
    setSearchValue(value);
  };
  const onChangeAction = (rowData) => {
    swal(msgConstant.isCompletedMsg, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        let activeStatus = rowData.isStatus === "Completed" ? 0 : 1;
        apiGet({
          url:
            endpoint.eventParticipant +
            "/update-is-completed-status?id=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            getEventAppliedStudent({ id: eventDataForAppliedStudents.id });
            showNotification({ msg: "Status Updated" });
          } else {
            getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };
  const showStudNoticeDetails = () => {
    getStudNoticeFromEventId({ id: eventDataForAppliedStudents.id }).then(
      (resp) => {
        if (!resp) {
          getAlert({
            message: "Server Failed",
          });
        } else {
          setShowAppliedList(true);
          setShowValue(4);
        }
      }
    );
  };
  const showEmpNoticeDetails = () => {
    getNoticeFromEventId({ id: eventDataForAppliedStudents.id }).then(
      (resp) => {
        if (!resp) {
          getAlert({
            message: "Server Failed",
          });
        } else {
          setShowAppliedList(true);
          setShowValue(3);
        }
      }
    );
  };

  const downloadPdf = () => {
    downloadF({
      url:
        "/api/report/v1/event-report?eventId=" +
        eventDataForAppliedStudents.id +
        "&documentType=1",
      ext: "pdf",
      openNewTab: true,
    });
  };

  const downloadExcel = () => {
    downloadF({
      url:
        "/api/report/v1/event-report?eventId=" +
        eventDataForAppliedStudents.id +
        "&documentType=0",
      ext: "xls",
      openNewTab: true,
    });
  };
  const showEmpFeedbackDetails = () => {
    setShowAppliedList(true);
    setShowValue(1);
    console.log(eventDataForAppliedStudents);
  };
  const showStudFeedbackDetails = () => {
    setShowAppliedList(true);
    setShowValue(2);
    console.log("1");
  };
  const backToForm = () => {
    setShowAppliedList(false);
    setShowValue(0);
  };
  const handleCloses = () => {
    setShowAppliedList(false);
    setShowValue(0);
  };

  return (
    <>
      {!showAppliedList ? (
        <Paper sx={{ padding: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Student Information"
              />
            </Grid>

            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <FontAwesomeIconCompo
                sx={{ marginTop: 2 }}
                color="error"
                fontSize="medium"
                title="PDF"
                baseClassName="fas"
                className="fa-file-pdf"
                onClick={downloadPdf}
              />
              &nbsp;&nbsp; &nbsp;&nbsp;
              <FontAwesomeIconCompo
                sx={{ marginTop: 2 }}
                color="secondary"
                fontSize="medium"
                title="EXCEL"
                baseClassName="fas"
                className="fa-file-excel"
                onClick={downloadExcel}
              />
              &nbsp;&nbsp; &nbsp;&nbsp;
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={onCancel}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            sx={{ marginTop: 1 }}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Event Name : "}
              />
            </Grid>
            <Grid item xs={12} sm={4.5} md={4.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={eventDataForAppliedStudents.name}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Venue : "}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={eventDataForAppliedStudents.venue}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            sx={{ marginTop: 1, marginBottom: 1 }}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Date: "}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={4.5} md={4.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={
                  eventDataForAppliedStudents.fromDate +
                  " / " +
                  eventDataForAppliedStudents.toDate
                }
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"time :"}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={eventDataForAppliedStudents.time}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            sx={{ marginTop: 1, marginBottom: 2 }}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={1.5} md={1.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Year Details : "}
              />
            </Grid>{" "}
            <Grid item xs={12} sm={10.5} md={10.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={eventDataForAppliedStudents.yearDetails.join()}
              />
            </Grid>
          </Grid>

          <SearchEvent
            onSearch={onSearch}
            showEmpNotice={showEmpNotice}
            showStudNotice={showStudNotice}
            showStudFeedback={showStudFeedback}
            showEmpFeedback={showEmpFeedback}
            showEmpNoticeDetails={showEmpNoticeDetails}
            showEmpFeedbackDetails={showEmpFeedbackDetails}
            showStudFeedbackDetails={showStudFeedbackDetails}
            showStudNoticeDetails={showStudNoticeDetails}
          />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            sx={{ marginTop: 2 }}
            container
            justifyContent="space-evenly"
          >
            <DynamicTable
              data={getFilteredTableData()}
              tableHead={columns}
              showPegination={true}
              isActionColActive={false}
              statusChange={onChangeAction}
            />
          </Grid>
        </Paper>
      ) : (
        <>
          {showAppliedList && showValue == 3 && (
            <ShowEmpNotice handleCloses={backToForm} />
          )}
          {showAppliedList && showValue == 4 && (
            <ShowStudNotice handleCloses={backToForm} />
          )}
          {showAppliedList && showValue == 1 && (
            <EmployeeAppliedList
              onCancel={backToForm}
              appliedDataFromEvent={eventDataForAppliedStudents}
            />
          )}
          {showAppliedList && showValue == 2 && (
            <StudentAppliedList
              onCancel={backToForm}
              appliedDataFromEvent={eventDataForAppliedStudents}
            />
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  eventList: state.event,
});
const mapDispatchToProps = {
  getEventAppliedStudent,
  showNotification,
  getAlert,
  getNoticeFromEventId,
  getStudNoticeFromEventId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppliedStudentList);
