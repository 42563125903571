import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import {
  onlyNumber,
  validEmail,
} from "../../../components/Comman/Util/Validations";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getEmployeeByDeptId } from "../../EmployeeDetails/employee.slice";
import EventCommitteeTable from "../../Event/Event Management/EventCommitteeTable";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getCommitteeMembers } from "../Event Management/event.slice";
import { getClass } from "../../Masters/OnlineModule/Class/class.slice";
import { getStudentListByYear } from "../../RollNoList/GenrateRollNo/GenerateRollNo.slice";
let docs;
class EventCommitteeForm extends Component {
  state = {
    id: 0,
    eventName: "",
    memberName: "",
    committeeMember: "",
    email: "",
    contactNo: "",
    memberDesignation: "",
    sequenceNo: "",
    yearDetailId: "",
    student: "",
    employeeDepartment: "",
    employee: "",
    formErrors: [],
  };

  componentDidMount() {
    const { committeeData, getAlert } = this.props;
    this.setState({
      eventName: committeeData.name,
      id: committeeData.id,
    });
    this.props
      .getCommitteeMembers({ id: committeeData.id })
      .then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    this.props.getDepartment().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getClass().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }

  changeHandlerForNumber = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === "contactNo") {
      if (onlyNumber.test(value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleFormValidation() {
    const {
      memberName,
      memberDesignation,
      email,
      sequenceNo,
      contactNo,
      employeeDepartment,
      employee,
      yearDetailId,
      student,
      committeeMember,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (committeeMember === "student") {
      if (!yearDetailId.length) {
        formIsValid = false;
        formErrors["yearDetailIdError"] = myConstClass.yearDetailMsg;
      }
      if (!student.length) {
        formIsValid = false;
        formErrors["studError"] = myConstClass.studMsg;
      }
      if (sequenceNo === "") {
        formIsValid = false;
        formErrors["sequenceNoError"] = myConstClass.sequenceNoMsg;
      }
      if (memberDesignation === "") {
        formIsValid = false;
        formErrors["memberDesignationError"] =
          myConstClass.memberDesignationMsg;
      }
    }
    if (committeeMember === "teacher") {
      if (!employeeDepartment.length) {
        formIsValid = false;
        formErrors["empDeptError"] = myConstClass.departmentMsg;
      }
      if (!employee.length) {
        formIsValid = false;
        formErrors["empError"] = myConstClass.employeemsg;
      }
      if (sequenceNo === "") {
        formIsValid = false;
        formErrors["sequenceNoError"] = myConstClass.sequenceNoMsg;
      }
      if (memberDesignation === "") {
        formIsValid = false;
        formErrors["memberDesignationError"] =
          myConstClass.memberDesignationMsg;
      }
    }
    if (committeeMember === "other") {
      if (memberName === "") {
        formIsValid = false;
        formErrors["memberNameError"] = myConstClass.memberNameMsg;
      }
      if (memberDesignation === "") {
        formIsValid = false;
        formErrors["memberDesignationError"] =
          myConstClass.memberDesignationMsg;
      }
      if (contactNo === "" || contactNo.length != 10) {
        if (contactNo === "") {
          formIsValid = false;
          formErrors["contactNoError"] = myConstClass.contactNoMsg;
        } else if (!onlyNumber.test(contactNo)) {
          formIsValid = false;
          formErrors["contactNoError"] = myConstClass.contactNumberMsg;
        } else if (contactNo.length > 0 && contactNo.length != 10) {
          formIsValid = false;
          formErrors["contactNoError"] = myConstClass.contactNoLengthMsg;
        }
      }
      if (email === "" || !validEmail.test(email)) {
        if (email === "") {
          formIsValid = false;
          formErrors["emailError"] = myConstClass.emailIdMsg;
        } else if (!validEmail.test(email)) {
          formIsValid = false;
          formErrors["emailError"] = myConstClass.invalidEmailIdMsg;
        }
      }
      if (sequenceNo === "") {
        formIsValid = false;
        formErrors["sequenceNoError"] = myConstClass.sequenceNoMsg;
      }
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      memberName,
      memberDesignation,
      email,
      contactNo,
      sequenceNo,
      student,
      employee,
      committeeMember,
    } = this.state;
    if (committeeMember === "other") {
      docs = {
        event: {
          id: id,
        },
        memberName: memberName,
        memberDesignation: memberDesignation,
        emailId: email,
        contactNo: contactNo,
        sequenceNo: sequenceNo,
      };
      if (this.handleFormValidation()) {
        this.onSave(docs);
      }
    } else {
      docs = {
        event: {
          id: id,
        },
        userRegistration: {
          id:
            committeeMember == "student"
              ? student
              : committeeMember == "teacher"
              ? employee
              : "",
        },
        memberName: memberName,
        memberDesignation: memberDesignation,
        emailId: email,
        contactNo: contactNo,
        sequenceNo: sequenceNo,
      };
      if (this.handleFormValidation()) {
        this.onSave(docs);
      }
    }
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.eventCommittee,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.setState({
          memberName: "",
          memberDesignation: "",
          email: "",
          contactNo: "",
          sequenceNo: "",
          yearDetailId: "",
          student: "",
          employeeDepartment: "",
          employee: "",
        });
        const { getCommitteeMembers } = this.props;
        const { id } = this.state;
        getCommitteeMembers({ id: id }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };
  changeHandlers = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
      formErrors: [],
      memberName: "",
      email: "",
      contactNo: "",
      memberDesignation: "",
      sequenceNo: "",
      yearDetailId: "",
      student: "",
      employeeDepartment: "",
      employee: "",
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "employeeDepartment") {
        this.props
          .getEmployeeByDeptId({ departmentId: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
      if (name === "yearDetailId") {
        this.props
          .getStudentListByYear({ yearDetail: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  render() {
    const {
      eventName,
      memberName,
      memberDesignation,
      email,
      contactNo,
      sequenceNo,
      committeeMember,
      yearDetailId,
      employeeDepartment,
      employee,
      student,
    } = this.state;

    const { rowDelete, onCancel } = this.props;

    const {
      memberNameError,
      memberDesignationError,
      sequenceNoError,
      emailError,
      contactNoError,
      empDeptError,
      empError,
      yearDetailIdError,
      studError,
    } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid container justifyContent="space-evenly" direction="row">
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label={
                      committeeMember == "student"
                        ? "Add Committee Members ( Student )"
                        : committeeMember == "teacher"
                        ? "Add Committee Members ( Teacher )"
                        : committeeMember == "other"
                        ? "Add Committee Members ( Other )"
                        : ""
                    }
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="right"
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={onCancel}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{ mt: 1, mb: -1 }}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Committee Member"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl color={"primary"} fullWidth={true}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className="text-black"
                        name="committeeMember"
                        id="committeeMember"
                        value={committeeMember}
                        onChange={this.changeHandlers}
                        size="small"
                        fullWidth={true}
                      >
                        <MenuItem value="student">Student</MenuItem>
                        <MenuItem value="teacher">Teacher</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                ></Grid>
              </Grid>
              {committeeMember == "other" && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      sx={{ marginTop: 3 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Event Name"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="eventName"
                          size="small"
                          value={eventName}
                          onChange={this.changeHandler}
                          fullWidth
                          disabled
                          helperText={" "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Member Name "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="memberName"
                          size="small"
                          value={memberName}
                          onChange={this.changeHandler}
                          fullWidth
                          error={memberNameError ? true : false}
                          helperText={memberNameError ? memberNameError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Member Designation"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="memberDesignation"
                          size="small"
                          value={memberDesignation}
                          onChange={this.changeHandler}
                          fullWidth
                          error={memberDesignationError ? true : false}
                          helperText={
                            memberDesignationError
                              ? memberDesignationError
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Email"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="email"
                          size="small"
                          value={email}
                          onChange={this.changeHandler}
                          fullWidth
                          error={emailError ? true : false}
                          helperText={emailError ? emailError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Contact No"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="contactNo"
                          size="small"
                          value={contactNo}
                          inputProps={{ maxLength: 10 }}
                          onChange={this.changeHandler}
                          fullWidth
                          error={contactNoError ? true : false}
                          helperText={contactNoError ? contactNoError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Sequence No"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="sequenceNo"
                          size="small"
                          value={sequenceNo}
                          onChange={this.changeHandlerForNumber}
                          fullWidth
                          error={sequenceNoError ? true : false}
                          helperText={sequenceNoError ? sequenceNoError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {committeeMember == "student" && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      sx={{ marginTop: 3 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Event Name"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="eventName"
                          size="small"
                          value={eventName}
                          onChange={this.changeHandler}
                          fullWidth
                          disabled
                          helperText={" "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Year Detail "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={yearDetailId}
                          name="yearDetailId"
                          options={this.props.yearDetail?.class}
                          onChange={this.ChangeHandlerSearch}
                          isError={yearDetailIdError ? true : false}
                          errorText={
                            yearDetailIdError ? yearDetailIdError : " "
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Student"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={student}
                          name="student"
                          options={this.props.studentList?.studentListByYear}
                          onChange={this.ChangeHandlerSearch}
                          isError={studError ? true : false}
                          errorText={studError ? studError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Member Designation"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="memberDesignation"
                          size="small"
                          value={memberDesignation}
                          onChange={this.changeHandler}
                          fullWidth
                          error={memberDesignationError ? true : false}
                          helperText={
                            memberDesignationError
                              ? memberDesignationError
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Sequence No"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="sequenceNo"
                          size="small"
                          value={sequenceNo}
                          onChange={this.changeHandlerForNumber}
                          fullWidth
                          error={sequenceNoError ? true : false}
                          helperText={sequenceNoError ? sequenceNoError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {committeeMember == "teacher" && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      sx={{ marginTop: 3 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Event Name"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="eventName"
                          size="small"
                          value={eventName}
                          onChange={this.changeHandler}
                          fullWidth
                          disabled
                          helperText={" "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Employee Department "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={employeeDepartment}
                          name="employeeDepartment"
                          options={this.props.departmentList?.department.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.ChangeHandlerSearch}
                          isError={empDeptError ? true : false}
                          errorText={empDeptError ? empDeptError : " "}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Employee"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={employee}
                          name="employee"
                          options={this.props.employeeList?.employeeList.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.ChangeHandlerSearch}
                          isError={empError ? true : false}
                          errorText={empError ? empError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Member Designation"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="memberDesignation"
                          size="small"
                          value={memberDesignation}
                          onChange={this.changeHandler}
                          fullWidth
                          error={memberDesignationError ? true : false}
                          helperText={
                            memberDesignationError
                              ? memberDesignationError
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Sequence No"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="sequenceNo"
                          size="small"
                          value={sequenceNo}
                          onChange={this.changeHandlerForNumber}
                          fullWidth
                          error={sequenceNoError ? true : false}
                          helperText={sequenceNoError ? sequenceNoError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {committeeMember == "other" ||
              committeeMember == "student" ||
              committeeMember == "teacher" ? (
                <>
                  <Grid container justifyContent="center">
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Add Committee Member"
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid sx={{ marginTop: 2 }} item xs={12} sm={12} md={12}>
                    <EventCommitteeTable
                      row={this.props.eventList?.eventCommitteeMembers}
                      rowDelete={rowDelete}
                      committeeData={this.props.committeeData}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eventList: state.event,
  employeeList: state.employeeList,
  departmentList: state.department,
  yearDetail: state.class,
  studentList: state.rollNoList,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getCommitteeMembers,
  getDepartment,
  getEmployeeByDeptId,
  getStudentListByYear,
  getClass,
};
export default connect(mapStateToProps, mapDispatchToProps)(EventCommitteeForm);
