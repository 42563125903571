import VerifiedIcon from "@mui/icons-material/Verified";
import { CardHeader, Dialog, DialogContent, Grid, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { LabelCompo } from "../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import Search from "../../../components/Search";
import endpoint from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
import CloseIcon from "@mui/icons-material/Close";
import { getMembers, saveMembersWithProfile } from "./members.slice";
class Members extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    membarList: [],
    searchValue: "",
    profilePic: "",
    openProfile: false,
    closeProfile: true,
  };
  componentDidMount() {
    this.props.getMembers().then((resp) => {
      if (!resp) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    document.addEventListener("mousedown", this.onMouseOut);
    // document.addEventListener("mouseout", this.onMouseOut);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.members.members !== nextProps.members.members) {
      this.setState({
        membarList: [],
      });
      nextProps.members.members.map((memberData) => {
        apiGet({
          url: endpoint.fileDownload + "/" + memberData.profile,
        }).then(({ data, success }) => {
          if (success) {
            memberData = {
              ...memberData,
              profile: data.data,
            };
            this.setState({
              membarList: [...this.state.membarList, memberData],
            });
          } else {
            memberData = {
              ...memberData,
            };
            this.setState({
              membarList: [...this.state.membarList, memberData],
            });
          }
        });
      });
    }
  }
  onSearch = (searchData) => {
    this.setState({
      searchValue: searchData,
    });
  };

  onMouseOut = () => {
    this.setState({
      closeProfile: true,
      openProfile: false,
      profilePic: "",
    });
  };

  onFocus = (e, profile) => {
    this.setState({
      profilePic: profile,
      openProfile: true,
      closeProfile: true,
    });
  };

  getFilteredTableData = () => {
    const { searchValue, membarList } = this.state;
    return membarList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["email"] &&
          currentRow["email"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["course"] &&
          currentRow["course"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["passingYear"] &&
          currentRow["passingYear"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const { membarList, searchValue, profilePic, openProfile, closeProfile } =
      this.state;
    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title={"Members"} />
        </Paper>

        <br />
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <Search
            sx={{ width: "100%" }}
            searchValue={searchValue}
            filteredRows={this.onSearch}
          />
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid container justifyContent="space-evenly">
              <Grid
                item
                sm={3}
                xs={12}
                md={3}
                lg={3}
                container
                justifyContent="space-evenly"
              ></Grid>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16 }}
                label={"Member count " + membarList.length}
              />
            </Grid>
          </Grid>
        </Paper>

        <br />

        <Paper sx={{ p: 0, borderRadius: 2 }} elevation={0}>
          <div align="center">
            <div className="new_content">
              {this.getFilteredTableData().map((cardData) => {
                return (
                  <div className="news_detail">
                    <div className="det_news_pic pic">
                      <img
                        src={cardData.profile}
                        onClick={(e) => this.onFocus(e, cardData.profile)}
                      ></img>
                    </div>
                    <br />
                    <div className="admission_cont detil">
                      <h6 className="event_sub_nm">
                        {cardData.name}
                        {/* <span>{data.date}</span> */}
                      </h6>

                      <Typography variant="subtitle1" gutterBottom>
                        {cardData.course},{cardData.passingYear}
                      </Typography>

                      <Typography variant="subtitle1" gutterBottom>
                        {cardData.email}
                      </Typography>
                    </div>
                  </div>
                );
              })}
              <br />
            </div>
          </div>
        </Paper>

        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            {openProfile && (
              <>
                <Dialog
                  sx={{
                    "& .MuiDialog-paper": { width: "80%", maxHeight: 650 },
                  }}
                  maxWidth="md"
                  open={openProfile}
                  onClose={closeProfile}
                >
                  <DialogContent>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      spacing={2}
                    >
                      <IconButton
                        aria-label="close"
                        onClick={closeProfile}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        columnSpacing={2}
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ margin: 2 }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          sx={{ width: 500, height: 500 }}
                          src={profilePic}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Dialog>
              </>
            )}
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  members: state.members,
});
const mapDispatchToProps = {
  getAlert,
  getMembers,
  saveMembersWithProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Members);
