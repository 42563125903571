import { Paper } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import DynamicForm from "../../components/dynamicscreens/DynamicForm";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import { AddSuggestionsJson } from "../../DynamicFormsJson/AddSuggestions";
import { suggestionDynamicJson } from "../../DynamicFormsJson/SuggestionDynamic";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import {
  getSuggestionsByDate,
  getSuggestionsWithoutFilter,
  resetValues,
} from "./suggestion.slice";
import SuggestionsStudentTable from "./SuggestionsStudentTable";
const columns = [
  {
    name: "index",
    title: "Sr. No.",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
    width: 70,
  },
  {
    name: "complaintDetail",
    title: "Suggestions",
    positionCenter: false,
    showInscreen: true,
    canSearch: true,
    alignCenter: "left",
    width: 500,
  },
  {
    name: "date",
    title: "Date",
    positionCenter: true,
    canSearch: true,
    showInscreen: true,
    alignCenter: "left",
    width: 110,
  },
];

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class Suggestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      department: "",
      showLoader: false,
      formErrors: [],
      dynamicMasterData: { department: this.props.departmentList?.department },
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
      fromDate: "",
      toDate: "",
    });
    this.props.resetValues();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.department !==
        nextProps.departmentList.department
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            department: nextProps.departmentList.department.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    let fieldData = {};

    AddSuggestionsJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    this.props.resetReducList();
    this.setState({
      showLoader: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getSuggestionsWithoutFilter({
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    this.props.getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  onChangeStatus = (rowData, status) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.saveEmployee +
            "/update-status?userRegistrationId=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Status Updated" });
          } else {
            this.props.getAlert({
              message: "Failed to change status",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      type: 0,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    AddSuggestionsJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: AddSuggestionsJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        const parentChildId = localStorage.getItem("parentChildId");

        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.props
          .getSuggestionsWithoutFilter({
            userRegistrationId: parentChildId === null ? "" : parentChildId,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: AddSuggestionsJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  handleFormValidation() {
    const { fromDate, toDate } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (fromDate.toString().trim() === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onSearchData = (dataToSearch) => {
    this.setState({
      showLoader: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getSuggestionsByDate({
        fromDate: dataToSearch.fromDate,
        toDate: dataToSearch.toDate,
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.suggestionList?.suggestions.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["complaintDetail"] &&
          currentRow["complaintDetail"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["date"] &&
          currentRow["date"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const { showAddButton = true } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fromDate,
      toDate,
      dynamicMasterData,
      fieldData,
    } = this.state;
    const { fromDateError, toDateError, placementTypeError } =
      this.state.formErrors;

    return (
      <>
        {showForm && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title={AddSuggestionsJson.screenTitle} />
            </Paper>
            <br />
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
              <DynamicForm
                fieldMeta={AddSuggestionsJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showSaveNextBtn={false}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave(true)}
                onSaveAndNext={this.onFormSave(false)}
                // screenTitle={AddSuggestionsJson.screenTitle}
                showTitle={true}
              />
            </Paper>
          </>
        )}
        {showTable && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.onAddButtonClick}
              screenTitle={suggestionDynamicJson.screenTitle}
              fieldMeta={suggestionDynamicJson.fieldMeta}
              buttonCenter={suggestionDynamicJson.buttonCenter}
              showPdfDownload={suggestionDynamicJson.showPdfDownload}
              showExcelDownload={suggestionDynamicJson.showExcelDownload}
              // pdfFileName={suggestionDynamicJson.pdfFileName}
              // excelFileName={suggestionDynamicJson.excelFileName}
              tableColumnsToFilter={suggestionDynamicJson.tableColumnsToFilter}
              showAddButton={suggestionDynamicJson.showAddButton}
              dynamicMasterData={[]}
              tableColumns={columns}
              isActionColActive={false}
              showLoader={this.state.showLoader}
              tableData={this.props.suggestionList?.suggestions}
              // getListById={this.getListById}
              showPegination={true}
              // showHeadEdit={true}
              showHeadDelete={false}
              // showPegination={false}
              // pdfDetailsId={this.pdfDetails}
              // rowViewData={this.onClickView}
              // rowDelete={this.rowDelete}
              // rowEdit={this.rowEdit}
              // onDelete={this.onDelete}
              onSearchData={this.onSearchData}
              baseIdColumn={suggestionDynamicJson.baseIdColumn}
              apiBaseURL={suggestionDynamicJson.apiBaseURL}
              tableOpen={false}
            />
            <Paper sx={{ p: 1, borderRadius: 2, mt: -4 }} elevation={0}>
              <SuggestionsStudentTable
                rows={this.props.suggestionList?.suggestions}
              />
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  innovativeList: state.innovativeTeaching,
  researchGuideList: state.researchGuide,
  departmentList: state.department,
  suggestionList: state.suggestions,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getDepartment,
  resetReducList,
  getSuggestionsByDate,
  getSuggestionsWithoutFilter,
  resetValues,
};
export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
