import DeleteIcon from "@mui/icons-material/Delete";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import EditIcon from "@mui/icons-material/Edit";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";

import PropTypes from "prop-types";
import * as React from "react";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../../components/Comman/IconButton";

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Protein (g)",
  },
];

function EnhancedTableHead(props) {
  const { order, tableHead, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen) {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.name ? order : false}
              >
                
                  {headCell.title}
                  {orderBy === headCell.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  tableHead,
  rowStatus,
  statusChange,
  rowDelete,
  rowEdit,
  showHeadEdit,
  showHeadDelete,
  isActionColActive,
  showAppliedList,
  showPersonAdd,
  rowPersonAddDetails,
  rowAddEmpFeedback,
  rowAddStudFeedback,
  rowAddEmpNotice,
  rowAddStudNotice,
  rowAppliedList,
  rowFeedBackDetails,
  data,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeStatus = (e, row) => {
    e.stopPropagation();
    rowStatus(row);
  };

  const changeRowStatus = (e, row) => {
    e.stopPropagation();
    statusChange(row);
  };

  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };

  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };

  const appliedList = (e, params) => {
    e.stopPropagation();
    rowAppliedList(params);
  };

  const personAddDetails = (e, params) => {
    e.stopPropagation();
    rowPersonAddDetails(params);
  };
  const addEmpFeedback = (e, params) => {
    e.stopPropagation();
    rowAddEmpFeedback(params);
  };
  const addStudFeedback = (e, params) => {
    e.stopPropagation();
    rowAddStudFeedback(params);
  };
  const addEmpNotice = (e, params) => {
    e.stopPropagation();
    rowAddEmpNotice(params);
  };
  const addStudNotice = (e, params) => {
    e.stopPropagation();
    rowAddStudNotice(params);
  };
  const FeedBackDetails = (e, params) => {
    e.stopPropagation();
    rowFeedBackDetails(params);
  };

  React.useEffect(() => {});
  return (
    <TableRow key={row.name}>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter(
          (row) => row.name === key && row.showInscreen
        );
        if (filteredList.length !== 0) {
          if (
            filteredList[0].positionCenter &&
            key !== "isActive" &&
            key !== "isStatus"
          )
            return (
              <TableCell>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell>
                {row[key] === 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (key === "isStatus") {
            return (
              <TableCell>
                {row[key] === "Completed" ? (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else
            return (
              <TableCell align={filteredList[0].alignCenter}>
                {" "}
                {row[key]}
              </TableCell>
            );
        }
      })}

      <TableCell align={"right"}>
        <MyComponentWithIconProps
          statusImage={EditIcon}
          color="primary"
          fontSize="medium"
          title="Edit"
          onClick={(e) => onClickEdit(e, row)}
        />
        &nbsp;&nbsp;
        <MyComponentWithIconProps
          statusImage={DeleteIcon}
          color="error"
          fontSize="medium"
          title="Delete"
          onClick={(e) => onClickDelete(e, row)}
        />
        &nbsp;&nbsp;
        <MyComponentWithIconProps
          statusImage={ListAltIcon}
          color="primary"
          fontSize="medium"
          title="Applied List"
          onClick={(e) => appliedList(e, row)}
        />
        <IconButton
          onClick={handleClick}
          sx={{ ml: 1 }}
          aria-controls={openMenu ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
        >
          <MoreIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openMenu}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={(e) => personAddDetails(e, row)}>
            <MyComponentWithIconProps
              statusImage={PersonAddAlt1Icon}
              color="primary"
              fontSize="medium"
              title="Add Committee"
              onClick={(e) => personAddDetails(e, row)}
            />
            &nbsp; Add Committee
          </MenuItem>
          {row.isStudentFeedbackGenerated == 0 && (
            <MenuItem
              onClick={(e) => addStudFeedback(e, row)}
              // onClick={( e ) => onClickView( e, row.id )}
            >
              <MyComponentWithIconProps
                statusImage={FeedbackIcon}
                color="primary"
                fontSize="medium"
                title="Add Student Feedback"
                onClick={(e) => addStudFeedback(e, row)}
              />
              &nbsp; Add Student Feedback
            </MenuItem>
          )}
          {/* {row.isEmployeeNoticeGenerated == 0 && 
            <MenuItem
            onClick={(e) => addEmpNotice(e, row)}
            //  onClick={( e ) => onClickDelete( e, row.id )}
          >
            <MyComponentWithIconProps
              statusImage={CircleNotificationsIcon}
              color="error"
              fontSize="medium"
              title="Add Employee Notice"
              onClick={(e) => addEmpNotice(e, row)}
            />
            &nbsp; Add Employee Notice
          </MenuItem>
          }
          {row.isStudentNoticeGenerated == 0 && 
            <MenuItem
            onClick={(e) => addStudNotice(e, row)}
            // onClick={( e ) => onClickView( e, row.id )}
          >
            <MyComponentWithIconProps
              statusImage={CircleNotificationsIcon}
              color="primary"
              fontSize="medium"
              title="Add Student Notice"
              onClick={(e) => addStudNotice(e, row)}
            />
            &nbsp; Add Student Notice
          </MenuItem>
          }  */}
        </Menu>
      </TableCell>
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  tableHead,
  rowStatus,
  statusChange,
  rowDelete,
  rowEdit,
  data,
  showHeadDelete,
  showHeadEdit,
  showHeadAdd,
  rowView,
  rowViewData,
  showPegination = true,
  isActionColActive = true,
  rowAppliedList,
  showAppliedList,
  showPersonAdd,
  rowPersonAddDetails,
  rowAddEmpFeedback,
  rowAddStudFeedback,
  rowAddEmpNotice,
  rowAddStudNotice,
  rowFeedBackDetails,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <Row
                    key={row.id}
                    row={row}
                    tableHead={tableHead}
                    showHeadDelete={showHeadDelete}
                    showHeadEdit={showHeadEdit}
                    rowEdit={rowEdit}
                    rowStatus={rowStatus}
                    statusChange={statusChange}
                    rowDelete={rowDelete}
                    showHeadAdd={showHeadAdd}
                    rowView={rowView}
                    rowViewData={rowViewData}
                    showPegination={showPegination}
                    isActionColActive={isActionColActive}
                    rowAppliedList={rowAppliedList}
                    showAppliedList={showAppliedList}
                    showPersonAdd={showPersonAdd}
                    rowPersonAddDetails={rowPersonAddDetails}
                    rowAddEmpFeedback={rowAddEmpFeedback}
                    rowAddStudFeedback={rowAddStudFeedback}
                    rowAddEmpNotice={rowAddEmpNotice}
                    rowAddStudNotice={rowAddStudNotice}
                    rowFeedBackDetails={rowFeedBackDetails}
                  />
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={tableHead.length} />
              </TableRow>
            )}
            {data.length === 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * 1,
                }}
              >
                <TableCell align="center" colSpan={tableHead.length}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPegination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
