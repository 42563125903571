import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  studentNotice: [],
  isFetch: false,
  divisionByYear: [],
  isFetchDivisionByYear: false,
  studentByDateNotice: [],
  isFetchStudentByDateNotice: false,
  noticeByEvent: [],
  isFetchNoticeByEvent: false,
};
let URL = endpoints.studentNotice;
let URL1 = endpoints.division;
const studentNotice = createSlice({
  name: "studentNotice",
  initialState,
  reducers: {
    studentNoticeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentNotice: row,
        isFetch: true,
      };
    },
    studentNoticeByDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentByDateNotice: row,
        isFetchStudentByDateNotice: true,
      };
    },
    distinctDivisionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        divisionByYear: row,
        isFetchDivisionByYear: true,
      };
    },
    noticeByEventSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        noticeByEvent: row,
        isFetchNoticeByEvent: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  resetSuccess,
  studentNoticeSuccess,
  distinctDivisionSuccess,
  studentNoticeByDateSuccess,
  noticeByEventSuccess,
} = studentNotice.actions;

export default studentNotice.reducer;

export const getStudNoticeFromEventId =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + id + "/by-event-id",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              labelText: data1.labelText,
              highlightLabel: data1.highlightLabel,
              isActive: data1.isActive,
              yearDetailIds: data1.yearDetailIds,
              divisionIds: data1.divisionIds,
              description: data1.description,
            };
            row.push(bData);
            return data1;
          });
          dispatch(noticeByEventSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getStudentNotice =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/for-student?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              labelText: data1.labelText,
              highlightLabel: data1.highlightLabel,
              isActive: data1.isActive,
              yearDetailIds: data1.yearDetailIds,
              divisionIds: data1.divisionIds,
              description: data1.description,
              documentUpload: data1.documentUpload ? data1.documentUpload : "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentNoticeSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentNoticeFromDtToDt =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-date?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              fromDate: data1.fromDate,
              toDate: data1.toDate,
              labelText: data1.labelText,
              highlightLabel: data1.highlightLabel,
              isActive: data1.isActive,
              yearDetailIds: data1.yearDetailIds,
              divisionIds: data1.divisionIds,
              description: data1.description,
              documentUpload: data1.documentUpload ? data1.documentUpload : "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentNoticeByDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDistinctDivisionByYear =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/api/acdmc/v1/division/by-year-detail-ids?yearDetailIds=729047a6-8475-4d9e-8a25-03a571cbeb07,5ca6de9a-829f-4c6e-a134-88adfb556381
      const response = apiGet({
        url: URL1 + "/by-year-detail-ids?yearDetailIds=" + yearDetail,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(distinctDivisionSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStudentNoticeWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "?offset=0&limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const studentList = data.data.content;
        let index = 0;
        let row = [];
        studentList.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            title: data1.title,
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            labelText: data1.labelText,
            highlightLabel: data1.highlightLabel,
            isActive: data1.isActive,
            yearDetailIds: data1.yearDetailIds,
            divisionIds: data1.divisionIds,
            description: data1.description,
            documentUpload: data1.documentUpload ? data1.documentUpload : "",
          };
          row.push(bData);
          return data1;
        });
        dispatch(studentNoticeByDateSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
