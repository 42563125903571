import VisibilityIcon from "@mui/icons-material/Visibility";

import React, { useEffect } from "react";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import { connect } from "react-redux";
import { openFileInNewTab } from "../../UploadFile/file.slice";
import { getAlert } from "../../../CommonActions/alert.slice";

import { getWorkExperienceByAluminiId } from "../WorkExp/workExperiance.slice";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },

  MuiTableHeadRootDetail: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
    width: 400,
  },

  MuiTableHeadRootMark: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
    width: 100,
  },
});

const Step5 = ({
  data,
  getAlert,
  workExperienceList,
  getWorkExperienceByAluminiId,
  classes,
}) => {
  useEffect(() => {
    getWorkExperienceByAluminiId({ id: data.id }).then((resp) => {
      if (!resp) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }, []);

  return (
    <>
      {/* <div style={{ overflow: "auto" }}> */}
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Work Experience"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <table className={classes.MuiTable}>
        <tr>
          <td
            style={{ color: "#5a5a5a", width: "10%" }}
            className={classes.MuiTableHeadRoot}
          >
            &nbsp;&nbsp; Sr. No.
          </td>
          <td
            style={{ color: "#5a5a5a", width: "20%" }}
            className={classes.MuiTableHeadRoot}
          >
            &nbsp;&nbsp; Company Name
          </td>
          <td
            style={{ color: "#5a5a5a", width: "25%" }}
            className={classes.MuiTableHeadRoot}
          >
            &nbsp;&nbsp; Position / Designation
          </td>
          <td
            style={{ color: "#5a5a5a", width: "30%" }}
            className={classes.MuiTableHeadRoot}
          >
            &nbsp;&nbsp; From - To Date
          </td>{" "}
          <td
            style={{ color: "#5a5a5a", width: "10%" }}
            className={classes.MuiTableHeadRoot}
          >
            &nbsp;&nbsp; Total experience
          </td>
          <td
            style={{ color: "#5a5a5a", width: "20%" }}
            className={classes.MuiTableHeadRoot}
          >
            &nbsp;&nbsp;Professional Skills
          </td>
        </tr>

        {workExperienceList.workExperience.map((detail, index) => (
          <tr>
            <td className={classes.MuiTableHeadRootDetail}>
              &nbsp;&nbsp;{index + 1}
            </td>

            <td className={classes.MuiTableHeadRootDetail}>
              &nbsp;&nbsp;{detail.companyName}
            </td>

            <td className={classes.MuiTableHeadRootMark}>
              &nbsp;&nbsp;{detail.designation}
            </td>
            <td className={classes.MuiTableHeadRootMark}>
              &nbsp;&nbsp;{detail.fromDate + " - " + detail.toDate}
            </td>
            <td className={classes.MuiTableHeadRootMark}>
              &nbsp;&nbsp;{detail.totalExperience}
            </td>
            <td className={classes.MuiTableHeadRootMark}>
              &nbsp;&nbsp;
              {detail.professionalSkills === ""
                ? "-"
                : detail.professionalSkills === null
                ? "-"
                : detail.professionalSkills}
            </td>
          </tr>
        ))}
      </table>
      <br></br>
    </>
  );
};

const mapStateToProps = (state) => ({
  workExperienceList: state.workExperience,
});

const mapDispatchToProps = {
  getWorkExperienceByAluminiId,
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step5));
