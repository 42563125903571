import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  alumniDashboardInfo: [],
  isFetchAlumniDashboardInfo: false,
  adminAlumniDashboardInfo: {},
  isFetchAdminAlumniDashboardInfo: false,
};
let URL = endpoints.alumniDashboard;
let URL1 = endpoints.adminAlumniDashboard;

const alumniDashboardSlice = createSlice({
  name: "alumniDashboard",
  initialState,
  reducers: {
    alumniDashboardInfoSuccess: (state = cloneDeep(initialState), action) => {
      const { bData } = action.payload;
      return {
        ...state,
        alumniDashboardInfo: bData,
        isFetchAlumniDashboardInfo: true,
      };
    },
    adminAlumniDashboardInfoSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { bData } = action.payload;
      return {
        ...state,
        adminAlumniDashboardInfo: bData,
        isFetchAdminAlumniDashboardInfo: true,
      };
    },
  },
});

export const { alumniDashboardInfoSuccess, adminAlumniDashboardInfoSuccess } =
  alumniDashboardSlice.actions;

export default alumniDashboardSlice.reducer;

export const getAlumniDashboardInfo =
  ({ alumniRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "?alumniRegistrationId=" + alumniRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let bData = {
            id: content.id,
            fullName: content.fullName,
            email: content.email,
            mobileNumber: content.mobileNumber,
            passingYear: content.passingYear,
            profile: content.profile,
            achievements:
              content.achievements !== null
                ? content.achievements.map((data1) => {
                    let bData1 = {
                      id: data1.id,
                      achievementType: data1.achievementType,
                      designationId: data1.designationId,
                      date: data1.date,
                      detail: data1.detail,
                      description: data1.description,
                      title: data1.title,
                      course:
                        data1.alumniRegistration === null
                          ? ""
                          : data1.alumniRegistration.course,
                      alumniId:
                        data1.alumniRegistration === null
                          ? ""
                          : data1.alumniRegistration.id,
                      userId:
                        data1.alumniRegistration === null
                          ? ""
                          : data1.alumniRegistration.userId,
                    };
                    return bData1;
                  })
                : [],
            volunteers:
              content.volunteers !== null
                ? content.volunteers.map((data1) => {
                    let bData1 = {
                      id: data1.id,
                      expertise: data1.expertise,
                      interest: data1.interest,
                      message: data1.message,
                      status:
                        data1.status == 0
                          ? "Pending"
                          : data1.status == 0
                          ? "Approved"
                          : "Rejected",
                      time: data1.time,
                      type: data1.type == 0 && "Volunteer",
                      remark: data1.remark === null ? "" : data1.remark,
                    };
                    return bData1;
                  })
                : [],
            mentors:
              content.mentors !== null
                ? content.mentors.map((data2, index) => {
                    let bData2 = {
                      id: data2.id,
                      expertise: data2.expertise,
                      interest: data2.interest,
                      message: data2.message,
                      status:
                        data2.status == 0
                          ? "Pending"
                          : data2.status == 0
                          ? "Approved"
                          : "Rejected",
                      time: data2.time,
                      type: data2.type == 1 && "Volunteer",
                      remark: data2.remark === null ? "" : data2.remark,
                    };
                    return bData2;
                  })
                : [],
            membersConnected:
              content.membersConnected !== null
                ? content.membersConnected.map((data2, index) => {
                    let bData2 = {
                      id: data2.id,
                      index: index + 1,
                      course: data2.course,
                      email: data2.email,
                      fullName: data2.fullName,
                      passingYear: data2.passingYear,
                      profile: data2.profile,
                    };
                    return bData2;
                  })
                : [],
            postAninternship:
              content.postAninternship !== null
                ? content.postAninternship.map((data2, index) => {
                    let bData2 = {
                      id: data2.id,
                      index: index + 1,
                      companyName: data2.companyName,
                      companyWebsite: data2.companyWebsite,
                      duration: data2.duration,
                      location: data2.location,
                      title: data2.title,
                    };
                    return bData2;
                  })
                : [],
            latestNews:
              content.latestNews !== null
                ? content.latestNews.map((data2, index) => {
                    let bData2 = {
                      id: data2.id,
                      index: index + 1,
                      description: data2.description,
                      image: data2.image,
                      title: data2.title,
                      date: data2.date,
                    };
                    return bData2;
                  })
                : [],
            libraryResponseList:
              content.getLibraryResponse !== null
                ? (content.getLibraryResponse = {
                    id: content.getLibraryResponse.id,
                    borrid: content.getLibraryResponse.borrid,
                    message: content.getLibraryResponse.message,
                    name: content.getLibraryResponse.name,
                    pendingFees: content.getLibraryResponse.pendingFees,
                    validUntil: content.getLibraryResponse.validUntil,
                    verified: content.getLibraryResponse.verified,
                    warningMessage: content.getLibraryResponse.warningMessage,
                    lentItems: content.getLibraryResponse.lentItems.map(
                      (data1, index) => {
                        let bData1 = {
                          id: data1.id,
                          index: index + 1,
                          title: data1.title,
                          mediaType: data1.mediaType,
                          status: data1.status,
                          returnDate: data1.returnDate,
                          author: data1.author,
                          barcode: data1.barcode,
                          catrefnum: data1.catrefnum,
                          homeBranch: data1.homeBranch,
                          lendingBranch: data1.lendingBranch,
                        };
                        return bData1;
                      }
                    ),
                  })
                : "",
          };
          dispatch(alumniDashboardInfoSuccess({ bData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdminAlumniDashboardInfo = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let bData = {
          id: content.id,
          profilePic:
            content.employeeDetails !== null
              ? content.employeeDetails.profilePic
              : "",
          firstName:
            content.employeeDetails !== null
              ? content.employeeDetails.firstName
              : "",
          middleName:
            content.employeeDetails !== null
              ? content.employeeDetails.middleName
              : "",
          lastName:
            content.employeeDetails !== null
              ? content.employeeDetails.lastName
              : "",
          employeeCode:
            content.employeeDetails !== null
              ? content.employeeDetails.employeeCode
              : "",
          dateOfBirth:
            content.employeeDetails !== null
              ? content.employeeDetails.dateOfBirth
              : "",
          employeeDesignation:
            content.employeeDetails !== null
              ? content.employeeDetails.designation.name
              : "",
          employeeWorkloadCount: content.employeeWorkloadCount,
          employeeEmail:
            content.employeeDetails !== null
              ? content.employeeDetails.emailId
              : "",
          employeeDepartment:
            content.employeeDetails !== null
              ? content.employeeDetails.department.name
              : "",
          employeeJoiningDate:
            content.employeeDetails !== null
              ? content.employeeDetails.joiningDate
              : "",
          dateOfBirth:
            content.employeeDetails !== null
              ? content.employeeDetails.dateOfBirth
              : "",
          department:
            content.employeeDetails !== null
              ? content.employeeDetails.department.name
              : "",
          employeeEmail:
            content.employeeDetails !== null
              ? content.employeeDetails.emailId
              : "",
          pendingAlumniRegistrations:
            content.pendingAlumniRegistrations !== null
              ? content.pendingAlumniRegistrations.map((data1) => {
                  let bData1 = {
                    id: data1.id,
                    aboutMe: data1.aboutMe,
                    alumniEmail: data1.alumniEmail,
                    alumniMobile: data1.alumniMobile,
                    name:
                      data1.firstName +
                      " " +
                      data1.middleName +
                      " " +
                      data1.lastName,
                  };
                  return bData1;
                })
              : [],
          volunteers:
            content.volunteers !== null
              ? content.volunteers.map((data1) => {
                  let bData1 = {
                    id: data1.id,
                    expertise: data1.expertise,
                    interest: data1.interest,
                    message: data1.message,
                    status:
                      data1.status == 0
                        ? "Pending"
                        : data1.status == 0
                        ? "Approved"
                        : "Rejected",
                    time: data1.time,
                    type: data1.type == 0 && "Volunteer",
                    remark: data1.remark === null ? "" : data1.remark,
                  };
                  return bData1;
                })
              : [],
          mentors:
            content.mentors !== null
              ? content.mentors.map((data2, index) => {
                  let bData2 = {
                    id: data2.id,
                    expertise: data2.expertise,
                    interest: data2.interest,
                    message: data2.message,
                    status:
                      data2.status == 0
                        ? "Pending"
                        : data2.status == 0
                        ? "Approved"
                        : "Rejected",
                    time: data2.time,
                    type: data2.type == 1 && "Volunteer",
                    remark: data2.remark === null ? "" : data2.remark,
                  };
                  return bData2;
                })
              : [],
          rejectedAlumniRegistrations:
            content.rejectedAlumniRegistrations !== null
              ? content.rejectedAlumniRegistrations.map((data1, index) => {
                  let bData2 = {
                    id: data1.id,
                    aboutMe: data1.aboutMe,
                    alumniEmail: data1.alumniEmail,
                    alumniMobile: data1.alumniMobile,
                    name:
                      data1.firstName +
                      " " +
                      data1.middleName +
                      " " +
                      data1.lastName,
                  };
                  return bData2;
                })
              : [],
          approvedAlumniRegistrations:
            content.approvedAlumniRegistrations !== null
              ? content.approvedAlumniRegistrations.map((data1, index) => {
                  let bData2 = {
                    id: data1.id,
                    aboutMe: data1.aboutMe,
                    alumniEmail: data1.alumniEmail,
                    alumniMobile: data1.alumniMobile,
                    name:
                      data1.firstName +
                      " " +
                      data1.middleName +
                      " " +
                      data1.lastName,
                  };
                  return bData2;
                })
              : [],
          latestNews:
            content.latestNews !== null
              ? content.latestNews.map((data2, index) => {
                  let bData2 = {
                    id: data2.id,
                    index: index + 1,
                    description: data2.description,
                    image: data2.image,
                    title: data2.title,
                    date: data2.date,
                  };
                  return bData2;
                })
              : [],
        };
        dispatch(adminAlumniDashboardInfoSuccess({ bData }));
        return { success, response: bData };
      }
      return { success, response: {} };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
