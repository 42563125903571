import endpoint from "../config/endpoints";

export const AlumniDonorJson = {
  apiBaseURL: endpoint.volunteerUpdateStatus,
  screenTitle: "Be a Volunteer",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Expertise",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "ExpertiseMaster",
      dataKey: "expertise",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideAutocomplete",
      placeHolder: "",
      masterName: "ExpertiseMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expertise",
      tableDataKey: "expertise",
      open: "other",
      error: "Expertise",
    },
    {
      label: "Volunteer for",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "interest",
      isMandatory: true,
    },
    {
      label: "Time you can contribute (In hours)",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "time",
      isMandatory: true,
    },
  ],
};
