import { Grid, Link, TextField } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import { getDesignation } from "../Masters/Internship/Designation/designation.slice";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { getPromotionRecords } from "./employee.slice";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { apiDelete } from "../../utils/api_service";
import { Link as RouterLink } from "react-router-dom";

import moment from "moment";
import swal from "sweetalert";

class PromotionRecord extends Component {
  state = {
    id: 0,
    employeeType: "",
    designation: "",
    department: "",
    fromDate: "",
    toDate: "",
    approvalNo: "",
    departmentAccess: [],
    formErrors: [],
    approvalDate: "",
    approvalType: "",
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "designation",
      title: "Designation",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "fromDate",
      title: "From Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "toDate",
      title: "To Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "approvalDate",
      title: "Approval Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },

    {
      name: "approvalNo",
      title: "Approval No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];
  changeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleFormValidation() {
    const { designation } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (designation == "" || designation === null) {
      formIsValid = false;
      formErrors["designationErr"] = myConstClass.designationMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  componentDidMount() {
    const { getDepartment, getDesignation, employeeDetails } = this.props;
    const { id } = this.state;
    getDesignation();
    console.log(employeeDetails.isSave);
    if (Object.keys(employeeDetails.employee).length != 0) {
      this.setState({
        id: employeeDetails.employee.id,
      });
    } else {
      this.setState({
        id: employeeDetails.employee.id,
      });
    }

    this.props
      .getPromotionRecords({ userRegistrationId: employeeDetails.employee.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.employeeDetails !== nextProps.employeeDetails) {
      if (
        this.props.employeeDetails.employee !==
        nextProps.employeeDetails.employee
      ) {
        this.setState({
          id: nextProps.employeeDetails.employee.id,
        });
      }
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { toDate, designation, fromDate, id, approvalDate, approvalNo } =
      this.state;
    let fromDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    let approvalDte = moment(approvalDate).format("DD-MM-YYYY");
    let docs = {
      userRegistration: {
        id: id,
      },
      designation: {
        id: designation,
      },
      fromDate: fromDt == "Invalid date" ? "" : fromDt,
      toDate: toDt == "Invalid date" ? "" : toDt,
      approvalNo: approvalNo,
      approvalDate: approvalDte == "Invalid date" ? "" : approvalDte,
      approvalType: 0,
      isActive: 1,
    };
    console.log(docs);
    if (this.handleFormValidation()) {
      this.onSave(docs);
      this.setState({
        designation: "",
        fromDate: "",
        toDate: "",
        approvalNo: "",
        approvalDate: "",
      });
    }
  };

  onSave = (dataToSave) => {
    const { id } = this.state;
    apiPost({
      url: endpoint.employeeDesignation,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        // this.props.getPromotionRecords( { userRegistrationId: id } )

        this.props
          .getPromotionRecords({ userRegistrationId: id })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to confirm Admission?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiDelete({
          url: endpoint.employeeDesignation + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getPromotionRecords({
                userRegistrationId: rowData.userId,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          }
        });
      }
    });
  };
  render() {
    const {
      designation,
      department,
      employeeType,
      departmentAccess,
      approvalType,
      approvalDate,
      fromDate,
      toDate,
      approvalNo,
    } = this.state;
    const { onCancel, designations, departments, saveHandler, editForm } =
      this.props;
    const {
      designationErr,
      fromDateError,
      approvalDateError,
      toDateError,
      approvalNoError,
      approvalTypeError,
    } = this.state.formErrors;
    console.log(localStorage);

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            {!editForm && (
              <Grid columnSpacing={1} container justifyContent="space-evenly">
                <Grid item xs={12} sm={12} md={12}></Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  sx={{ mt: 2 }}
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Designation"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={designation}
                      name={"designation"}
                      disabled={editForm ? true : false}
                      options={designations?.designation.filter(
                        (data) => data.isActive == 1
                      )}
                      onChange={this.changeHandlerSearch}
                      isError={designationErr ? true : false}
                      errorText={designationErr ? designationErr : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  sx={{ mt: 2 }}
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Approval Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      name="approvalDate"
                      value={approvalDate}
                      disabled={editForm ? true : false}
                      onChange={this.onDateChange("approvalDate")}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="From Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      name="fromDate"
                      value={fromDate}
                      disabled={editForm ? true : false}
                      onChange={this.onDateChange("fromDate")}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="To Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      name="toDate"
                      disabled={editForm ? true : false}
                      value={toDate}
                      onChange={this.onDateChange("toDate")}
                    />
                  </Grid>
                </Grid>

                {/*  */}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Approval No"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="approvalNo"
                      size="small"
                      value={approvalNo}
                      disabled={editForm ? true : false}
                      fullWidth
                      onChange={this.changedHandler}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={12}>
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Add"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid sx={{ marginTop: 1 }} xs={12} sm={12} md={12}>
              <DynamicTable
                data={this.props.employeeDetails.promotionRecords}
                tableHead={this.columns}
                rowDelete={this.rowDelete}
                rowViewData={this.rowView}
                showPegination={false}
                showHeadDelete={true}
                rowEdit={this.rowEdit}
              />
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={1}
            justifyContent="right"
            sx={{ marginTop: 2 }}
          >
            <Grid item justifyContent="space-evenly">
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back"
                onClick={onCancel}
                fullWidth={true}
              />
            </Grid>
            <Grid item justifyContent="space-evenly">
              <ButtonCompo
                size="medium"
                type="button"
                variant="contained"
                name="Submit"
                fullWidth={true}
                onClick={saveHandler}
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  designations: state.designation,
  departments: state.department,
  employeeDetails: state.employeeList,
});
const mapDispatchToProps = {
  getDesignation,
  getDepartment,
  showNotification,
  getAlert,
  getPromotionRecords,
};
export default connect(mapStateToProps, mapDispatchToProps)(PromotionRecord);
