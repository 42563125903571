import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as myConstClass from "../../config/messageconstant";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicForm from "../../components/dynamicscreens/DynamicFormWithTable";
import { Loading1 } from "../../components/Loading1";
import * as msgConstant from "../../config/messageconstant";
import { GovPayJson } from "../../DynamicFormsJson/GovPayment";
import { GovPaymentTransactionJson } from "../../DynamicFormsJson/GovPaymentTransaction";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet } from "../../utils/api_service";
import { resetReducList } from "../../CommonActions/User.action";
import { FilterSave } from "../../components/FilterSave";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getStudentListForGovPayment,
  getTransactionDetailByAdmiRegIdAndFromFeesId,
  getTransactionDetailById,
} from "../ApplicantAdmission/applicantAdmission.slice";
import endpoints from "../../config/endpoints";
import TransactionPopUp from "./TransactionPopUp";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { getFromFees } from "../Masters/Admission/FromFees/fromFees.slice";
import { LabelCompo } from "../../components/Comman/Label";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      selection: [],
      showTable: false,
      transactionOpen: false,
      showSearch: true,
      currentOperationMode: "",
      searchValue: "",
      showLoader: false,
      formErrors: {},
      fromDate: "",
      toDate: "",
      fundedBy: "",
      paymentType: "",
      payment: "",
      transcationNo: "",
      fromFees: "",
      yearDetailId: "",
      bankName: "",
      yearDetailName: "",
      fromFeesName: "",
      openEdit: false,
      studentData: {},
      dynamicMasterData: {
        yearDetailMaster: this.props.internshipDeclarationList.yearDetails,
        FromFeeslMaster: this.props.fromFeesList.fromFees,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
    }

    if (this.props.fromFeesList !== nextProps.fromFeesList) {
      if (
        this.props.fromFeesList.fromFees !== nextProps.fromFeesList.fromFees
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            FromFeeslMaster: nextProps.fromFeesList.fromFees,
            // .filter(
            //   (row) => row.id != 1
            // ),
          },
        });
      }
    }
  }

  componentDidMount() {
    const {
      getYearDetails,
      getFromFees,
      getAlert,
      resetReducList,
      internshipDeclarationList,
      fromFeesList,
    } = this.props;
    resetReducList();
    const yearDetailId = localStorage.getItem("yearDetailId");

    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    this.setState({
      showLoader: true,
    });
    getFromFees().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    const dataToSearch = {};

    if (yearDetailId) {
      dataToSearch["yearDetail"] = yearDetailId;
    }

    GovPayJson.fieldMeta.map((currentField) => {
      if (currentField.defaultValue) {
        dataToSearch[currentField.dataKey] = currentField.defaultValue;
      }
    });

    this.setState({
      fieldData: dataToSearch,
    });
  }

  onDataChange = (fieldName, newValue) => {
    this.setState({
      showTable: false,
      openEdit: false,
    });
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = {};
    let listError = false;
    GovPayJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key === fieldName) {
            listError = true;
          }
        });
      }
      if (!listError && fieldData[currentField.dataKey]) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onFormSave = () => () => {
    const { fieldData } = this.state;
    const dataToSave = {};
    GovPayJson.fieldMeta.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = year + "-" + month + "-" + day;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.searchData(dataToSave);
  };
  getFilteredTableData = () => {
    const { applicantAdmissionList } = this.props;
    const { searchValue } = this.state;
    const columsFil = this.columns.filter((item) => {
      return item.canSearch;
    });
    return applicantAdmissionList?.studentList.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  searchData = (data) => {
    console.log(data);
    const ay = localStorage.getItem("acadamicYearId");
    const {
      getStudentListForGovPayment,
      getAlert,
      internshipDeclarationList,
      fromFeesList,
    } = this.props;
    const yearDetailIdName = internshipDeclarationList.yearDetails.filter(
      (row) => {
        return row.id === data.yearDetail;
      }
    );
    const fromFeesIdName = fromFeesList.fromFees.filter((row) => {
      return row.id === data.fromFees;
    });
    this.setState({
      yearDetailName: yearDetailIdName[0].name,
      fromFeesName: fromFeesIdName[0].name,
    });
    this.setState({
      showLoader: true,
      yearDetailId: data.yearDetail,
      fromFees: data.fromFees,
    });
    getStudentListForGovPayment({
      fromFeesId: data.fromFees,
      yearDetailId: data.yearDetail,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showTable: true,
          showLoader: false,
        });
      }
    });

    localStorage.setItem("semisterDetailId", "");
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("divisionIds", "");
    FilterSave();
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      // name: "applicantName",
      name: "firstName",
      title: "Applicant Name",
      positionCenter: false,
      showInscreen: true,
      minWidth: "40%",
      alignCenter: "left",
      canSearch: true,
      keyName: "firstName,lastName,fatherName,motherName",
      isFormat: true,
      type: 0,
    },
    {
      name: "totalFees",
      title: "Total Fees",
      positionCenter: true,
      minWidth: "15%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "totalOutstanding",
      title: "Outstanding",
      minWidth: "10%",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "totalPaid",
      title: "Paid",
      positionCenter: true,
      minWidth: "20%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  transactionColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "transactionNo",
      title: "Transaction No",
      positionCenter: false,
      showInscreen: true,
      minWidth: "40%",
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "transactionDate",
      title: "Transaction Date",
      positionCenter: true,
      minWidth: "15%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "transactionType",
      title: "Transaction Type",
      minWidth: "10%",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "amount",
      title: "Amount",
      positionCenter: true,
      minWidth: "20%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    // {
    //   name: "totalFee",
    //   title: "Toatal Applicable",
    //   positionCenter: true,
    //   minWidth: "20%",
    //   showInscreen: true,
    //   alignCenter: "center",
    //   canSearch: true,
    // },
    {
      name: "paymentType",
      title: "Payment Type",
      positionCenter: true,
      minWidth: "20%",
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  onSelectionChange = (e) => {
    this.setState({
      selection: e,
    });
  };

  rowAppliedList = (rowData) => {
    console.log(rowData);
    const { getTransactionDetailByAdmiRegIdAndFromFeesId } = this.props;
    this.setState({
      showLoader: true,
      openEdit: true,
      studentData: rowData,
    });
    getTransactionDetailByAdmiRegIdAndFromFeesId({
      fromFeesId: this.state.fromFees,
      admissionRegId: rowData.id,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  backToList = () => {
    this.setState({
      openEdit: false,
    });
    this.props
      .getStudentListForGovPayment({
        fromFeesId: this.state.fromFees,
        yearDetailId: this.state.yearDetailId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showTable: true,
            showLoader: false,
          });
        }
      });
  };

  handleFormValidation() {
    const {
      debitAmount,
      debitAmountValidation,
      transactionDate,
      number,
      bankName,
      type,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;
    console.log(debitAmountValidation);
    if (type == "2" || type == "3") {
      if (number === "") {
        formIsValid = false;
        formErrors["numberError"] = myConstClass.numberMsg;
      }
    }

    if (type == "3" || type == "2") {
      if (bankName === "") {
        formIsValid = false;
        formErrors["bankNameError"] = myConstClass.bankNameMsg;
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
    const { payment, paymentType, fundedBy, transcationNo, bankName } =
      this.state;
    console.log("Payment Type - " + paymentType);
    console.log("Payment - " + payment);
    console.log("Funded By - " + fundedBy);
    console.log("Transaction No - " + transcationNo);
    console.log("Bank Name - " + bankName);
    this.state.selection.length === 0
      ? swal("Please select atleast one student")
      : swal("Do you want to submit", {
          buttons: ["Cancel", true],
        }).then((value) => {
          if (value) {
            console.log(this.state.fieldData);
            console.log(this.state.selection);
            const transcationNo =
              this.state.transcationNo === "" ? "-" : this.state.transcationNo;
            const bankName =
              this.state.bankName === "" ? "-" : this.state.bankName;
            const ay = localStorage.getItem("acadamicYearId");
            console.log(
              endpoints.admissionList +
                "/update-admission-fees-detail-by-admission-registration-details-and-from-fees?admissionRegistrationDetailIds=" +
                this.state.selection.join() +
                "&yearDetailId=" +
                this.state.yearDetailId +
                "&fromFeesId=" +
                this.state.fromFees +
                "&totalAmount=" +
                this.state.payment +
                "&name=" +
                this.state.fundedBy +
                "&academicYearId=" +
                ay +
                "&payRefNo=" +
                transcationNo +
                "&payBank=" +
                bankName +
                "&payType=" +
                this.state.paymentType
            );
            apiGet({
              url:
                endpoints.admissionList +
                "/update-admission-fees-detail-by-admission-registration-details-and-from-fees?admissionRegistrationDetailIds=" +
                this.state.selection.join() +
                "&yearDetailId=" +
                this.state.yearDetailId +
                "&fromFeesId=" +
                this.state.fromFees +
                "&totalAmount=" +
                this.state.payment +
                "&name=" +
                this.state.fundedBy +
                "&academicYearId=" +
                ay +
                "&payRefNo=" +
                transcationNo +
                "&payBank=" +
                bankName +
                "&payType=" +
                this.state.paymentType,
            }).then(({ data, success }) => {
              if (!success) {
                getAlert({ message: "Something went wrong" });
              } else {
                this.props.showNotification({
                  msg: "Data saved successfully",
                });
                this.setState({
                  showTable: false,
                });
                window.location.replace("/governmentPayment");
              }
            });
          }
        });
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  changeHandlerDropdown = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  rowEdit = (rowData) => {
    this.setState({
      isLoading: true,
    });
    this.props
      .getTransactionDetailById({ transactionId: rowData.id })
      .then((res) => {
        if (!res) {
          this.props.getAlert({ message: "Something went wrong" });
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
            transactionOpen: true,
          });
        }
      });
  };

  transactionClose = () => {
    console.log("BACK");
    this.setState({
      transactionOpen: false,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          isLoading: true,
        });
        apiDelete({
          url: endpoints.studentTransaction + "/" + rowData.id,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              isLoading: false,
            });
            this.props.showNotification({ msg: "Data deleted successfully" });
            const { getTransactionDetailByAdmiRegIdAndFromFeesId } = this.props;

            getTransactionDetailByAdmiRegIdAndFromFeesId({
              fromFeesId: this.state.fromFees,
              admissionRegId: this.state.studentData.id,
            }).then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          } else {
            this.props.getAlert({
              message: "Failed to delete",
            });
            this.setState({
              isLoading: false,
            });
          }
        });
      }
    });
  };
  render() {
    const {
      fieldData,
      selection,
      dynamicMasterData,
      showLoader,
      showTable,
      payment,
      paymentType,
      fundedBy,
      transcationNo,
      bankName,
      openEdit,
      yearDetailName,
      fromFeesName,
      studentData,
    } = this.state;
    const { bankNameError, numberError } = this.state.formErrors;
    console.log(studentData);
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={[]}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          screenTitle={GovPayJson.screenTitle}
          showPdfDownload={GovPayJson.showPdfDownload}
          // generatePDF={downLoadPdfFromApi ? pdfDownload : this.generatePDF}
          generatePDF={this.generatePDFDownload}
          showExcelDownload={GovPayJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={GovPayJson.showAddButton}
          showTable={!openEdit}
          showSearch={GovPayJson.showSearch}
        />
        <br />
        {!openEdit && (
          <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
              <DynamicForm
                fieldMeta={GovPayJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                formErrorsShows={this.state.formErrors}
                onDataChange={this.onDataChange}
                onSave={this.onFormSave()}
                buttonCenter={GovPayJson.buttonCenter}
                searchButton={GovPayJson.searchButton}
                searchAlign={GovPayJson.searchAlign}
                marginTop={GovPayJson.marginTop}
              />
              {showTable && (
                <>
                  {" "}
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ marginTop: 1 }}
                    container
                  >
                    <DynamicSelectTable
                      rows={this.getFilteredTableData()}
                      columns={this.columns}
                      selection={selection}
                      onSelectionChange={this.onSelectionChange}
                      showDetailsIcon={true}
                      rowAppliedList={this.rowAppliedList}
                    />
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    // sx={{ ml: 1 }}
                    justifyContent="space-evenly"
                  >
                    <Grid sx={{ mr: 1 }} item container sm={4} md={4} lg={4}>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Payment Type "
                        />
                      </Grid>

                      <Grid sx={{ mt: 0.6 }} item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className="text-black"
                            name="paymentType"
                            id="paymentType"
                            value={paymentType}
                            onChange={this.changeHandlerDropdown}
                            size="small"
                            // fullWidth={true}
                          >
                            <MenuItem value="0">Cash</MenuItem>
                            <MenuItem value="1">Online</MenuItem>
                            <MenuItem value="2">Cheque</MenuItem>
                            <MenuItem value="3">DD</MenuItem>
                            <MenuItem value="4">Transfer</MenuItem>
                          </Select>
                        </FormControl>
                        <FormHelperText> </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid container sm={4} md={4} lg={4}>
                      <Grid sx={{ mt: 1.2 }} item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Payment "
                        />
                      </Grid>
                      <Grid sx={{ mb: 2.2 }} item xs={12} sm={12} md={12}>
                        <TextField
                          size="small"
                          color="primary"
                          name="payment"
                          id="payment"
                          value={payment}
                          onChange={this.changeHandler}
                          // fullWidth
                          // error={debitAmountError ? true : false}
                          // helperText={debitAmountError ? debitAmountError : " "}
                        />
                      </Grid>{" "}
                    </Grid>
                    <Grid sx={{ ml: -1 }} item container sm={4} md={4} lg={4}>
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Funded By "
                        />
                      </Grid>

                      <Grid sx={{ mb: 2.2 }} item xs={12} sm={12} md={12}>
                        <TextField
                          size="small"
                          color="primary"
                          name="fundedBy"
                          id="fundedBy"
                          value={fundedBy}
                          onChange={this.changeHandler}
                          // fullWidth
                          // error={debitAmountError ? true : false}
                          // helperText={debitAmountError ? debitAmountError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    spacing={1}
                    sx={{ ml: 1 }}
                    justifyContent="space-evenly"
                  >
                    {(paymentType === "2" || paymentType === "3") && (
                      <>
                        <Grid container sm={4} md={4} lg={4}>
                          <Grid sx={{ mt: 1.2 }} item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Transcation No "
                            />
                          </Grid>
                          <Grid sx={{ mb: 2.2 }} item xs={12} sm={12} md={12}>
                            <TextField
                              size="small"
                              color="primary"
                              name="transcationNo"
                              id="transcationNo"
                              value={transcationNo}
                              onChange={this.changeHandler}
                              // fullWidth
                              error={numberError ? true : false}
                              helperText={numberError ? numberError : " "}
                            />
                          </Grid>{" "}
                        </Grid>
                        <Grid
                          sx={{ ml: -1 }}
                          item
                          container
                          sm={4}
                          md={4}
                          lg={4}
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label="*Bank Name "
                            />
                          </Grid>

                          <Grid sx={{ mb: 2.2 }} item xs={12} sm={12} md={12}>
                            <TextField
                              size="small"
                              color="primary"
                              name="bankName"
                              id="bankName"
                              value={bankName}
                              onChange={this.changeHandler}
                              // fullWidth
                              error={bankNameError ? true : false}
                              helperText={bankNameError ? bankNameError : " "}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{ mr: 1 }}
                          item
                          container
                          sm={4}
                          md={4}
                          lg={4}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ marginTop: 3 }}
                    container
                    justifyContent="right"
                  >
                    <Grid item xs={12} sm={1} md={1}>
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Submit"
                        fullWidth={true}
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Paper>
          </>
        )}
        {openEdit && (
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              // sx={{ marginTop: 3 }}
              container
              justifyContent="right"
            >
              <Grid item xs={12} sm={1.7} md={1.7}>
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="outlined"
                  name="Back to List"
                  fullWidth={true}
                  onClick={this.backToList}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              // sx={{ marginTop: 3 }}
              container
              justifyContent="right"
            >
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Year Detail "
                />
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={studentData.yearDetailName}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              // sx={{ marginTop: 3 }}
              container
              justifyContent="space-evenly"
              spacing={2}
            >
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="From Fees "
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={studentData.fromFeesName}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Student Name "
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={studentData.applicantName}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              // sx={{ marginTop: 3 }}
              container
              justifyContent="right"
            >
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Total Fees "
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={studentData.totalFees}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Pending Fees "
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={studentData.totalOutstanding}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Paid Fees "
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label={studentData.totalPaid}
                />
              </Grid>
            </Grid>
            <br />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              // sx={{ marginTop: 3 }}
              container
              justifyContent="space-evenly"
            >
              <DynamicTable
                data={
                  this.props.applicantAdmissionList
                    ?.transactionByAdmRegIdAndFromFeesId
                }
                // data={[]}
                tableHead={this.transactionColumns}
                showHeadDelete={true}
                rowDelete={this.rowDelete}
                rowEdit={this.rowEdit}
                showHeadEdit={true}
              />
            </Grid>
          </Paper>
        )}
        {this.state.transactionOpen && (
          <TransactionPopUp
            open={this.state.transactionOpen}
            onClose={this.transactionClose}
            studentData={this.state.studentData}
          />
        )}
        {showLoader && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  feesCollectionList: state.feesCollection,
  applicantAdmissionList: state.applicantAdmission,
  fromFeesList: state.fromFees,
});
const mapDispatchToProps = {
  getYearDetails,
  getStudentListForGovPayment,
  getAlert,
  resetReducList,
  showNotification,
  getFromFees,
  getTransactionDetailByAdmiRegIdAndFromFeesId,
  getTransactionDetailById,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
