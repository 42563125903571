import endpoint from "../config/endpoints";
export const activityJson = {
    apiBaseURL: endpoint.activity,
    screenTitle: "Activity",
    showAddButton: true,
    showPdfDownload: true,
    pdfFileName: "Activity",
    showExcelDownload: true,
    excelFileName: "Activity",
    "baseIdColumn": "id",
    "fieldMeta": [
        {
            "label": "Activity Name",
            "controlType": "textfield",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "masterName": "name",
            "dataKey": "name",
            "isMandatory": true
        },
        {
            "label": "Type",
            "controlType": "autocomplete",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "type",
            masterName: "type",
            isRootLevelKey: true,
            "isMandatory": true
        },
    ]

};


