import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import MatrixView from "./MatrixView";
class GenerateTimeTableForm extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    semester: "",
    division: "",
    formErrors: [],
    fromDate: "",
    toDate: "",
    remark: "",
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.timeTableHeaderData !== nextProps.timeTableHeaderData) {
      this.setState({
        id: nextProps.timeTableHeaderData.id,
        yearDetail: nextProps.timeTableHeaderData.yearDetailName,
        semester: nextProps.timeTableHeaderData.semisterDetailName,
        division: nextProps.timeTableHeaderData.divisionName,
        fromDate: nextProps.timeTableHeaderData.fromDate,
        toDate: nextProps.timeTableHeaderData.toDate,
        remark: nextProps.timeTableHeaderData.remark,
      });
    }
  }
  componentDidMount() {
    const { timeTableHeaderData } = this.props;
    if (Object.keys(timeTableHeaderData).length != 0) {
      this.setState({
        id: timeTableHeaderData.id,
        yearDetail: timeTableHeaderData.yearDetailId,
        semester: timeTableHeaderData.semisterDetailName,
        division: timeTableHeaderData.divisionName,
        fromDate: timeTableHeaderData.fromDate,
        toDate: timeTableHeaderData.toDate,
        remark: timeTableHeaderData.remark,
      });
    }
  }

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };

  render() {
    const { division, semester, yearDetail, remark, fromDate, toDate } =
      this.state;
    const { onCancle } = this.props;
    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                {" "}
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  m={2}
                  rowSpacing={1}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={1.1}
                    lg={1.1}
                    justifyContent="space-evenly"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back"
                      fullWidth={true}
                      onClick={() => {
                        onCancle();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Year details : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={yearDetail}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Semister Details : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={semester}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Division : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={division}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"From Date : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={fromDate}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"To Date: "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={toDate}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={4} md={4}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={"Remark : "}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={remark}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
                columnSpacing={3}
                sx={{ marginTop: 2 }}
              />
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
                columnSpacing={3}
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label={"Time Table"}
                  />
                </Grid>
              </Grid>
              {this.props.timeSlotList.isFetch && (
                <MatrixView
                  dayList={[
                    { id: "0", name: "Monday" },
                    { id: "1", name: "Tuesday" },
                    { id: "2", name: "Wednesday" },
                    { id: "3", name: "Thursday" },
                    { id: "4", name: "Friday" },
                    { id: "5", name: "Saturday" },
                    { id: "6", name: "Sunday" },
                  ]}
                  timeSlotList={this.props.timeSlotList?.timeSlot}
                  onSave={this.submitHandler}
                  // onCancel={onCancel}
                />
              )}
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ timeSlotList: state.timeSlot });
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateTimeTableForm);
