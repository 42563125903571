import Chip from "@mui/material/Chip";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { loadCSS } from "fg-loadcss";
import * as React from "react";

export const ChipCompo = ({
  onClick,
  color,
  title,
  size,
  variant,
  label,
  sx,
}) => {
  return (
    <Chip
      label={label}
      size={size}
      onClick={onClick}
      title={title}
      color={color}
      variant={variant}
      sx={sx}
    />
  );
};
export const MyComponentWithIconProps = (props) => {
  const StatusImage = props.statusImage;
  if (props.title && props.title !== "") {
    return (
      <Tooltip placement="bottom" title={props.title}>
        <StatusImage
          color={props.color}
          fontSize={props.fontSize}
          sx={props.sx}
          onClick={props.onClick}
        />
      </Tooltip>
    );
  } else {
    return (
      <StatusImage
        color={props.color}
        fontSize={props.fontSize}
        sx={props.sx}
        onClick={props.onClick}
      />
    );
  }
};

export const FontAwesomeIconCompo = ({
  onClick,
  baseClassName,
  className,
  fontSize,
  sx,
  color,
  title,
}) => {
  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.14.0/css/all.css",
      document.querySelector("#font-awesome-css") || document.head.firstChild
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  if (title && title !== "") {
    return (
      <Tooltip placement="top" title={title}>
        <Icon
          baseClassName={baseClassName}
          className={className}
          fontSize={fontSize}
          sx={sx}
          color={color}
          onClick={onClick}
        />
      </Tooltip>
    );
  } else {
    return (
      <Icon
        baseClassName={baseClassName}
        className={className}
        fontSize={fontSize}
        color={color}
        onClick={onClick}
      />
    );
  }
};
