export const BatchListDynamicJson = {
  screenTitle: "List Batches",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  getAcadamicYear: true,
  getListId: "yearDetail",
  searchAlign:1.2,
  fieldMeta: [
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "yearDetailMaster",
      getListId: "yearDetail",
      dataKey: "yearDetail",
      isMandatory: true,
    },
    {
      label: "Semester Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "semesterDetailMaster",
      getListFrom: "yearDetail",
      dataKey: "semister",
      isMandatory: true,
    },
    {
      label: "Division",
      controlType: "autocomplete",
      placeHolder: "",
      md: 3.6,
      lg: 3.6,
      sm: 3.6,
      xs: 12,
      masterName: "divisionMaster",
      getListFrom: "yearDetail",
      dataKey: "division",
      isMandatory: true,
    },
  ],
};
