import { Grid, Paper, TextField, Link } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import {
  getAttendanceYearDetails,
  getDivisionForAttendance,
  getSemesterDetails,
  getSubjectByDivisionAttendance,
} from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import endpoint from "../../../config/endpoints";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import * as myConstClass from "../../../config/messageconstant";
import { Link as RouterLink } from "react-router-dom";
import {
  getStudentAttendanceList,
  getDivision,
  getBatch,
  getSubject,
  resetStudentList,
} from "../../StudentAttendance/StudentAttendanceForms/studentAttendance.slice";

import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import TeacherView from "../TeacherView/TeacherView";
import { showNotification } from "../../Landing/Landing.slice";
import { getAlert } from "../../../CommonActions/alert.slice";
import { getTimeSlot } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import { getYearDetailsStudentForm } from "../../Workload/TeacherSideWorkloadView/employeeWorkload.slice";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { getSyllabus, resetValues } from "../teachingPlan.slice";
import swal from "sweetalert";
let selectedStudentList = [];

class TeacherViewAddForm extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    semister: "",
    division: "",
    batch: [],
    subject: "",
    timeSlot: "",
    date: "",
    toDate: "",
    attendanceDate: "",
    topic: "",
    remark: "",
    noOfLectures: "",
    mediaUsed: "",
    selectedStudentIndexList: [],
    StudentList: [],
    formErrors: [],
    disabledflag: false,
    headerData: {},
  };

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };
  onToDateChange = (dataKey) => (toDate) => {
    this.setState({
      [dataKey]: toDate,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onSaveData = (dataToSave, moduleList, flag) => {
    console.log(moduleList);
    console.log(dataToSave);
    if (this.handleFormValidation()) {
      swal("Do you want to submit?", {
        buttons: ["Cancel", true],
      }).then((value) => {
        if (value) {
          apiPost({
            url: endpoint.teachingPlan+"?batchIds="+this.state.batch.join(),
            postBody: dataToSave,
          }).then(({ data, success }) => {
            if (success) {
              const subjectList = [];
              moduleList.map((chapterData) => {
                chapterData.sectionList.map((section) => {
                  section.subSectionList.map((subSection) => {
                    if (subSection.checked) {
                      data.data.map((dataRes)=>{
                        const subsectionData = {
                          subjectTeachingPlan: {
                            id: dataRes.id,
                          },
                          syllabusSubSection: {
                            id: subSection.id,
                          },
                          isActive: 1,
                        };
                        subjectList.push(subsectionData);
                      })
                      
                    }
                  });
                });
              });

              apiPost({
                url: endpoint.subjectTeachingPlanDetail,
                postBody: subjectList,
              }).then(({ data, success }) => {
                if (success) {
                  const { getSyllabus, showNotification, getAlert } =
                    this.props;
                  const { subject } = this.state;
                  this.props.showNotification({
                    msg: "Data saved successfully",
                  });
                  if (flag === 0) {
                    window.location.replace("/teaching-plan-teacher-view");
                  } else {
                    this.props
                      .getSyllabus({ subjectDetailId: subject })
                      .then((response) => {
                        if (!response) {
                          this.props.getAlert({
                            message: "Something went wrong",
                          });
                        }
                      });
                    this.setState({
                      date: "",
                      toDate: "",
                    });
                  }
                } else {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
            } else {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      });
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   const ay = localStorage.getItem("acadamicYearId");
  //   if (this.props.teachingPlan !== nextProps.teachingPlan) {
  //     if (
  //       this.props.teachingPlan.teacherPlan !==
  //       nextProps.teachingPlan.teacherPlan
  //     ) {
  //       this.setState({
  //         id: nextProps.teachingPlan.teacherPlan.id,
  //         yearDetail: nextProps.teachingPlan.teacherPlan.yearDetailId,
  //         semister: nextProps.teachingPlan.teacherPlan.semisterDetailId,
  //         division: nextProps.teachingPlan.teacherPlan.divisionId,
  //         batch: nextProps.teachingPlan.teacherPlan.batchId.split(","),
  //         subject: nextProps.teachingPlan.teacherPlan.subjectDetailId,
  //         topic: nextProps.teachingPlan.teacherPlan.topicTaught,
  //         remark: nextProps.teachingPlan.teacherPlan.remark,
  //         // date: nextProps.teachingPlan.teacherPlan.date,
  //       });
  //     }
  //   }
  // }

  // componentDidMount()
  // {
  //   const ay = localStorage.getItem( "acadamicYearId" );
  //   const { teachingPlan, rowDataForm } = this.props;
  //   console.log( rowDataForm );
  //   if ( rowDataForm != undefined || rowDataForm != null )
  //   {
  //     if ( Object.keys( rowDataForm ).length !== 0 )
  //     {

  //       const fromDateARR = rowDataForm.date == null ? "" :
  //         rowDataForm.date.split( "-" );
  //       const fromDt = fromDateARR == "" ? "" : new Date( fromDateARR[ 2 ],
  //         fromDateARR[ 1 ] - 1, fromDateARR[ 0 ] );
  //       this.props
  //         .getSemesterDetails( {
  //           yearDetailId: rowDataForm.yearDetailId,
  //           academicYearId: ay,
  //         } )
  //         .then( ( response ) =>
  //         {
  //           if ( !response )
  //           {
  //             this.setState( {
  //               showLoader: false,
  //             } );
  //             this.props.getAlert( { message: "Something went wrong" } );
  //           } else
  //           {
  //             this.setState( {
  //               showLoader: false,
  //             } );
  //           }
  //         } );

  //       this.props.getSyllabus( {
  //         subjectDetailId:
  //           rowDataForm.subjectDetailId
  //       } ).then( ( response ) =>
  //       {
  //         if ( !response )
  //         {
  //           getAlert( { message: "Something went wrong" } );
  //         }
  //       } );
  //       this.setState( {
  //         yearDetail: rowDataForm.yearDetailId,
  //         semister: rowDataForm.semisterDetailId,
  //         division: rowDataForm.division,
  //         batch: rowDataForm.batchName,
  //         subject: rowDataForm.subjectDetailId,
  //         date: fromDt,
  //         topic: rowDataForm.topicTaught,
  //         remark: rowDataForm.remark,
  //       } )
  //     }
  //   }

  componentDidMount() {
    const ay = localStorage.getItem("acadamicYearId");
    const { teachingPlan, rowDataForm } = this.props;
    console.log(rowDataForm);
    this.props
    .getAttendanceYearDetails({ academicYear: ay })
    .then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    if (rowDataForm != undefined || rowDataForm != null) {
      if (Object.keys(rowDataForm).length !== 0) {
        const fromDateARR =
          rowDataForm.date == null ? "" : rowDataForm.date.split("-");
        const fromDt =
          fromDateARR == ""
            ? ""
            : new Date(fromDateARR[2], fromDateARR[1] - 1, fromDateARR[0]);
        const toDateARR =
          rowDataForm.toDate == null ? "" : rowDataForm.toDate.split("-");
        const toDt =
          toDateARR == ""
            ? ""
            : new Date(toDateARR[2], toDateARR[1] - 1, toDateARR[0]);
        console.log(toDt);
        this.props
          .getSemesterDetails({
            yearDetailId: rowDataForm.yearDetailId,
            academicYearId: ay,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });

        this.props
          .getSyllabus({
            subjectDetailId: rowDataForm.subjectDetailId,
          })
          .then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });

        this.props
          .getDivisionForAttendance({
            semesterDetailId: rowDataForm.semisterDetailId,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });

        this.props
          .getBatch({
            semister: rowDataForm.semisterDetailId,
            division: rowDataForm.divisionId,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });

        this.props
          .getSubjectByDivisionAttendance({
            semister: rowDataForm.semisterDetailId,
            division: rowDataForm.divisionId,
            batchId: rowDataForm.batchId,
            subjectDetailId: true,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });

        this.setState({
          yearDetail: rowDataForm.yearDetailId,
          semister: rowDataForm.semisterDetailId,
          division: rowDataForm.divisionId,
          batch: rowDataForm.batchId.split(','),
          subject: rowDataForm.subjectDetailId,
          date: fromDt,
          toDate: toDt,
          topic: rowDataForm.topicTaught,
          remark: rowDataForm.remark,
          noOfLectures: rowDataForm.noOfLectures,
          mediaUsed: rowDataForm.mediaUsed,
        });
      }
    }else{
      const semisterDetailId = localStorage.getItem("semisterDetailId");
      const yearDetailId = localStorage.getItem("yearDetailId");
      const divisionId = localStorage.getItem("divisionId");
  
      if (yearDetailId!=null && ay!=null) {
        this.setState({
          showLoader: true,
        });
        this.props
          .getSemesterDetails({
            yearDetailId: yearDetailId,
            academicYearId: ay,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
  
      if (semisterDetailId!=null) {
        this.setState({
          showLoader: true,
        });
        this.props
          .getDivisionForAttendance({ semesterDetailId: semisterDetailId })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
  
    
      if (semisterDetailId!=null && divisionId!=null) {
        this.setState({
          showLoader: true,
        });
        this.props
          .getBatch({
            semister: semisterDetailId,
            division: divisionId,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
    }

   

   
  }
  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");
    const { semister, division } = this.state;
    if (filterValue.length !== 0) {
        let divisionList = [];
        this.props.studentAttendanceList?.batch.map((data) => {
          if (data.id !== "all") {
            divisionList.push(data.id);
          }
        });
        this.setState({
          [name]: divisionList,
        });
        this.setState({
          showLoader: true,
        });
        this.props
        .getSubjectByDivisionAttendance({
          semister: semister,
          division: division,
          batchId: divisionList.join(),
          subjectDetailId: true,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    } else {
      this.setState({
        ...this.state,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
      this.setState({
        showLoader: true,
      });
      this.props
      .getSubjectByDivisionAttendance({
        semister: semister,
        division: division,
        batchId: value,
        subjectDetailId: true,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    }

  };
  ChangeHandlerSearch = (name, newValue) => {
    const { getDivision, getBatch, getSubject, getSemesterDetailsStudentForm } =
      this.props;
    const { semister, division } = this.state;

    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name == "yearDetail") {
        const ay = localStorage.getItem("acadamicYearId");
        this.props
          .getSemesterDetails({
            yearDetailId: newValue,
            academicYearId: ay,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
      if (name == "semister") {
        this.props
          .getDivisionForAttendance({ semesterDetailId: newValue })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
      if (name == "division") {
        this.props
          .getBatch({ semister: semister, division: newValue })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
      if (name == "batch" && semister !== "" && division !== "") {
        this.props
          .getSubjectByDivisionAttendance({
            semister: semister,
            division: division,
            batchId: newValue,
            subjectDetailId: true,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const {
      yearDetail,
      semister,
      division,
      batch,
      subject,
      date,
      toDate,
      timeSlot,
      topic,
      remark,
      noOfLectures,
      mediaUsed,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (semister.toString().trim() === "" || semister == null) {
      formIsValid = false;
      formErrors["semisterError"] = myConstClass.semisterMsg;
    }

    if (yearDetail.toString().trim() === "" || yearDetail == null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }
    if (division.toString().trim() === "" || division == null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    if (batch.length === 0|| batch == null) {
      formIsValid = false;
      formErrors["batchError"] = myConstClass.batchMsg;
    }
    if (subject.toString().trim() === "" || subject == null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectNameMsg;
    }
    if (noOfLectures.toString().trim() === "" || noOfLectures == null) {
      formIsValid = false;
      formErrors["noOfLecturesError"] = myConstClass.noOfLecturesMsg;
    }
    if (mediaUsed.toString().trim() === "" || mediaUsed == null) {
      formIsValid = false;
      formErrors["mediaUsedError"] = myConstClass.mediaUsedMsg;
    }

    if (date === "" || date == null) {
      formIsValid = false;
      formErrors["dateError"] = "From Date is required";
    }
    if (toDate === "" || toDate == null) {
      formIsValid = false;
      formErrors["toDateError"] = "To Date is required";
    }
    if (topic.toString().trim() === "" || topic == null) {
      formIsValid = false;
      formErrors["topicError"] = "Topic is required";
    }
    if (remark.toString().trim() === "" || remark == null) {
      formIsValid = false;
      formErrors["remarkError"] = myConstClass.remarkMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = (data) => {
    console.log(data);
    const { getAlert, getSyllabus } = this.props;
    const { subject } = this.state;
    if (this.handleFormValidation()) {
      getSyllabus({ subjectDetailId: subject }).then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  };
  onToDateChange = (dataKey) => (toDate) => {
    this.setState({
      [dataKey]: toDate,
    });
  };

  render() {
    const {
      yearDetail,
      semister,
      division,
      batch,
      subject,
      timeSlot,
      attendanceDate,
      disabledflag,
      topic,
      remark,
      noOfLectures,
      mediaUsed,
    } = this.state;
    const {
      semisterError,
      divisionError,
      batchError,
      yearDetailError,
      subjectError,
      dateError,
      topicError,
      remarkError,
      toDateError,
      noOfLecturesError,
      mediaUsedError,
    } = this.state.formErrors;
    const { internshipDeclarationList, rollNoData, studentBatchList } =
      this.props;
      console.log(batch)
    return (
      <>
        <div>
          <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
            <TitleAndBreadCrum title="Teaching Plan" />
          </Paper>
          <br />
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <>
              <form autoComplete="off" noValidate={true}>
                <Grid
                  sx={{ paddingRight: 1 }}
                  container
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label=""
                    />
                  </Grid>
                  <Grid item xs={2} md={6} container justifyContent="flex-end">
                    <Link
                      color="primary"
                      variant="body2"
                      underline="none"
                      component={RouterLink}
                      to="/teaching-plan-teacher-view"
                    >
                      <ButtonCompo
                        size="button"
                        type="button"
                        variant="outlined"
                        name="Back to List"
                        onClick={this.props.onCancelView}
                      />
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={1}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Year Detail"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={yearDetail}
                        name={"yearDetail"}
                        options={
                          this.props.employeeWorkList?.attendanceYearDetails
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={yearDetailError ? true : false}
                        errorText={yearDetailError ? yearDetailError : " "}
                        disabled={disabledflag}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Semester"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={semister}
                        name={"semister"}
                        options={
                          this.props.employeeWorkList?.attendanceSemesterDetails
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={semisterError ? true : false}
                        errorText={semisterError ? semisterError : " "}
                        disabled={disabledflag}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Division"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={division}
                          name={"division"}
                          options={
                            this.props.employeeWorkList?.attendanceDivision
                          }
                          onChange={this.ChangeHandlerSearch}
                          isError={divisionError ? true : false}
                          errorText={divisionError ? divisionError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Batch"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          keyColName={"id"}
                          value1={batch}
                          name1={"batch"}
                          names={this.props.studentAttendanceList?.batch}
                          onChange={this.multiSelectHandler}
                          er={batchError ? batchError : ""}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Subject"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={subject}
                          name={"subject"}
                          options={
                            this.props.employeeWorkList?.attendanceSubjects
                          }
                          onChange={this.ChangeHandlerSearch}
                          isError={subjectError ? true : false}
                          errorText={subjectError ? subjectError : " "}
                          disabled={disabledflag}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*From Date"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="date"
                          size="small"
                          value={this.state.date}
                          fullWidth
                          onChange={this.onDateChange("date")}
                          isError={dateError ? true : false}
                          errorText={dateError ? dateError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/*  */}

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*To Date"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="toDate"
                          size="small"
                          value={this.state.toDate}
                          fullWidth
                          onChange={this.onToDateChange("toDate")}
                          isError={toDateError ? true : false}
                          errorText={toDateError ? toDateError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Topic"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="topic"
                          size="small"
                          value={topic}
                          onChange={this.changeHandler}
                          error={topicError ? true : false}
                          helperText={topicError ? topicError : " "}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*No of Lectures"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="noOfLectures"
                          size="small"
                          value={noOfLectures}
                          onChange={this.changeHandler}
                          type="number"
                          error={noOfLecturesError ? true : false}
                          helperText={
                            noOfLecturesError ? noOfLecturesError : " "
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Media"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="mediaUsed"
                          size="small"
                          value={mediaUsed}
                          onChange={this.changeHandler}
                          error={mediaUsedError ? true : false}
                          helperText={mediaUsedError ? mediaUsedError : " "}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    // spacing={2}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Remark"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="remark"
                        size="small"
                        value={remark}
                        onChange={this.changeHandler}
                        error={remarkError ? true : false}
                        helperText={remarkError ? remarkError : " "}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {/* </Grid> */}

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="center"
                      >
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Search"
                          onClick={this.searchData}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TeacherView
                      stateData={this.state}
                      rowDataForm={this.props.rowDataForm}
                      onCancelView={this.props.onCancelView}
                      onSaveData={this.onSaveData}
                    />
                  </Grid>
                </Grid>
              </form>
            </>
          </Paper>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  semesterList: state.rollNo,
  yearDetails: state.internshipDeclaration,
  timeSlotList: state.timeSlot,
  studentAttendanceList: state.studentAttendance,
  employeeWorkList: state.employeeWorkload,
  teachingPlan: state.teachingPlan,
});
const mapDispatchToProps = {
  getStudentAttendanceList,
  getYearDetails,
  getSemesterDetailsByYearAndAcademic,
  getTimeSlot,
  getDivision,
  getBatch,
  getSubject,
  getAlert,
  resetStudentList,
  getYearDetailsStudentForm,
  getSyllabus,
  getAttendanceYearDetails,
  getDivisionForAttendance,
  getSemesterDetails,
  getSubjectByDivisionAttendance,
  showNotification,
  resetValues,
};
export default connect(mapStateToProps, mapDispatchToProps)(TeacherViewAddForm);
