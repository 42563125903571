import React from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
const PrnNumberTable = ({
  isActionColActive,
  onSelectionChange,
  selection,
  userCredentialList,
  generateCredential,
}) => {
  const columns = [
    { name: "prnNo", title: "PRN No", minWidth: 90 },
    {
      name: "firstName",
      title: "Student Name",
      minWidth: 250,
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,
    },
  ];
  const rowPrint = (row) => {};
  return (
    <>
      <DynamicSelectTable
        rows={userCredentialList?.userCredentialNull}
        columns={columns}
        selection={selection}
        isActionColActive={isActionColActive}
        onSelectionChange={onSelectionChange}
      />
      <br />
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        columnSpacing={1}
        container
        justifyContent="space-evenly"
      >
        <Grid
          sx={{ marginBottom: 2 }}
          container
          justifyContent="right"
          item
          xs={12}
          sm={12}
          md={12}
        >
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Generate Credential"
            onClick={generateCredential}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  userCredentialList: state.userCredential,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrnNumberTable);
