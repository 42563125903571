import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { apiGet } from "../../../utils/api_service";
import endpoints from "../../../config/endpoints";
import { getAlert } from "../../../CommonActions/alert.slice";
import { connect } from "react-redux";
const ViewTimeTableHeader = ({getAlert,subject,subjectHrCount, timeTable ,timeTableByTeacherId}) => {
  const [yearDetail, setYearDetail] = useState("");
 useEffect(()=>{
   if(yearDetail===''&&Object.keys(timeTable).length !== 0){
    apiGet({
      url: endpoints.yearDetailId + "/" + timeTable.division.yearDetailId,
    }).then(({ data, success }) => {
      if (success) {
        setYearDetail(
          data.data.programTitle.programType.name +
            " - " +
            data.data.programTitle.brName +
            " - " +
            data.data.year.className
        );
        console.log(data)
      }
      else{
       getAlert({
          message: "Something went wrong",
        });
      }
    });
   }
 
 })
  return (
    <>
      <Grid
        item
        sm={12}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={2}
        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
        container
        justifyContent="space-evenly"
      >
        {/* <Grid
          item
          sm={12}
          xs={12}
          md={3.5}
          lg={3.5}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={7} sm={7} md={12}>
            <Typography color="primary">Remark</Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {timeTable.remark}</Typography>
          </Grid>
        </Grid> */}
        <Grid
          item
          sm={12}
          xs={12}
          md={4}
          lg={4}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container
          justifyContent="space-evenly"
        >
          {/* <Grid item xs={7} sm={7} md={12}>
            <Typography color="primary">Year</Typography>
          </Grid> */}
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {yearDetail+" ( "+timeTable.semisterDetail.semister.name+" )"}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={3}
          lg={3}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {timeTable.fromDate+" / "+timeTable.toDate}</Typography>
          </Grid>
        </Grid>
      
        <Grid
          item
          sm={12}
          xs={12}
          md={2}
          lg={2}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {"Div : "+timeTable.division.name}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={3}
          lg={3}
          rowSpacing={1}
          // columnSpacing={{xs:12, sm:6, md:1}}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {'Teacher Workload : '+timeTableByTeacherId.length}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={7}
          lg={7}
          rowSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {timeTable.remark}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={2}
          lg={2}
          rowSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {'Subject :'+subject }</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          md={3}
          lg={3}
          rowSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={5} sm={5} md={12}>
            <Typography> {'Hr : '+subjectHrCount }</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
  getAlert
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewTimeTableHeader);