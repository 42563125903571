import endpoint from "../config/endpoints";

export const AlumniMentorJson = {
  apiBaseURL: endpoint.volunteerUpdateStatus,
  screenTitle: "Be a Mentor",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Area of expertise",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "expertise",
      isMandatory: true,
    },
    {
      label: "Time you can spend (Hours / Days)",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "time",
      isMandatory: true,
    },
    {
      label: "Which department would you like to Mentor?",
      controlType: "autocomplete",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      masterName: "DepartmentMaster",
      dataKey: "message",
      getListId: "message",
      isMandatory: true,
    },
    // {
    //   label: "Department",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "DepartmentMaster",
    //   getListId: 'department',
    //   dataKey: "department",
    //   isMandatory: true,
    // },
  ],
};
