import endpoint from "../config/endpoints";

export const ComplaintAdminJson = {
  apiBaseURL: endpoint.grievance,
  screenTitle: "Complaint Admin",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Complaint Details",
      controlType: "textarea",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      disable: true,
      dataKey: "complaintDetail",
      isMandatory: true,
    },
    {
      label: "Remark (Attended By)",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "attendedBy",
      isMandatory: true,
    },
    {
      label: "Attended Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "attendedDate",
      // isMAxDate: false,
      // maxDate: new Date(),
      // isMinDate: true,
      // minDate: new Date(),
      "isMandatory": true
    },
    {
      label: "Status",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "status",
      dataKey: "status",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
