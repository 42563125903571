
import endpoint from "../config/endpoints";

export const financialMasterJson = {
  apiBaseURL: endpoint.financial,
  screenTitle: "Financial Year Details",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Financial Details",
  showExcelDownload: true,
  excelFileName: "Financial Details",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "From Date",
      columnKeyName: "fromDate",
      isChecked: true,
    },
    {
      columnDisplayName: "To Date",
      columnKeyName: "toDate",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "name",
      isMandatory: true
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      getDate:'toDate',
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: false,
      minDate: new Date(),
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-l"
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: true,
      minDate: new Date(),
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-g"
    },
    {
      label: "Locked",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isLocked",
      tableDataKey:'isLocked',
      defaultValue:"1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true
    }
  ]
};
