import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  programType: [],
  isFetch: false,
  progTitleByProgType: [],
  isFetchProgTitleByProgType: false,
  programTypeInstituteWise: [],
  isFetchInstituteWise: false,
};
let URL = endpoints.programType;
const programTypeSlice = createSlice({
  name: "programType",
  initialState,
  reducers: {
    programTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        programType: row,
        isFetch: true,
      };
    },
    progTitleByProgTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        progTitleByProgType: row,
        isFetchProgTitleByProgType: true,
      };
    },

    programTypeInstituteWiseSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        programTypeInstituteWise: row,
        isFetchInstituteWise: true,
      };
    },
  },
});

export const {
  programTypeSuccess,
  progTitleByProgTypeSuccess,
  programTypeInstituteWiseSuccess,
} = programTypeSlice.actions;

export default programTypeSlice.reducer;

export const getProgramType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(programTypeSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getProgramTitleByProgTypeId =
  ({ progTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + progTypeId + "/program-title",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.brName + " ( " + data1.brShortName + " )",
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(progTitleByProgTypeSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProgramTypeInstituteWise = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-program-type-by-institute",
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        let row = [];
        data.data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(programTypeInstituteWiseSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};