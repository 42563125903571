import ArticleIcon from "@mui/icons-material/Article";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
import { Loading1 } from "../../../components/Loading1";
import * as msgConstant from "../../../config/messageconstant";
import { apiDelete, apiPost } from "../../../utils/api_service";
import { showNotification } from "../../Landing/Landing.slice";
import AluminiAchivments from "../Alumini Achievements/AluminiAchievements";
import AddressDetails from "./AddressDetails";
import EduDetailsOfOtherUni from "./EduDetailsOfOtherUni";
import EduDetailsOfSameUnit from "./EduDetailsOfSameUnit";
import PersonalDetails from "./PersonalDetails";
import SocialProfile from "./SocialProfile";
import WorkExp from "../WorkExp/index";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getWorkExperienceByAluminiId } from "../WorkExp/workExperiance.slice";
import { getProgramTitleNew } from "../../Masters/OnlineModule/ProgramTitle/programTitle.slice";
import {
  saveStudentFillProfile,
  getAlumniRegistration,
  getEducationByAluminiId,
} from "./studentFillProfile.slice";
import * as myConstClass from "../../../config/messageconstant";
import { getBloodGroup } from "../../BasicDetailsPost/basicDetails.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import endpoint from "../../../config/endpoints";
import { getCountry, getState, getCity } from "./studentFillProfile.slice";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      fieldDataForSearch: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      fromDate: "",
      toDate: "",
      showFormSameUni: true,
      showFormOtherUni: true,
      value: "1",
      educationalData: {},
      title: "",
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  backToBasicDetails = () => {
    this.setState({
      value: "1",
    });
  };

  backToAddress = () => {
    this.setState({
      value: "2",
    });
  };
  goToSocialProfile = () => {
    this.setState({
      value: "4",
    });
  };
  backToWorkExperience = () => {
    this.setState({
      value: "3",
    });
  };

  backToSocialProfile = () => {
    this.setState({
      value: "4",
    });
  };
  goToOtherDetails = () => {
    this.setState({
      value: "6",
    });
  };
  backToEducationalDetails = () => {
    this.setState({
      value: "5",
    });
  };
  goToAchievements = () => {
    this.setState({
      value: "7",
    });
  };
  backToOtherDetails = () => {
    this.setState({
      value: "6",
    });
  };
  goToDetails = () => {
    window.location.replace("/alumini-profile");
  };

  componentDidMount() {
    this.props.getBloodGroup().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
        this.props.getAlumniRegistration().then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        this.props.getAlumniRegistration().then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          }
        });
      }
    });

    this.props.getWorkExperienceByAluminiId({ id: "" }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getCountry().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getState().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getCity().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getEducationByAluminiId({ id: "" }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getProgramTitleNew().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    let role = localStorage.getItem("role");
    if (role === "alumni") {
      this.setState({
        title: "Alumna Profile",
      });
    } else {
      this.setState({
        title: "Student Profile",
      });
    }
  }

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        const { getTableDataById = false } = this.props;
        if (getTableDataById) {
          this.props.onSearchData(this.state.fieldDataForSearch);
        } else {
          this.props.getTableData();
        }
        if (isToggle) {
          this.toggleFormTableVisibility();
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        }
        if (!isToggle) {
          let fieldData = {};

          this.setState({
            fieldData: fieldData,
            currentOperationMode: OperationMode.Add,
          });
        }
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "institute",
      title: "College / Institute/ University Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "programTitle",
      title: "Program Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },

    {
      name: "location",
      title: "Location",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "period",
      title: "Completion Year",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
  ];
  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const dataToSave = {
          id: rowData["id"],
        };

        this.setState({
          currentOperationMode: OperationMode.Status,
        });
        this.onSave(dataToSave, false);
      }
    });
  };

  changeTabs = (event, newValue) => {
    this.setState({
      value: newValue,
      showFormSameUni: true,
      showFormOtherUni: true,
      educationalData: {},
    });
  };
  onAddButtonClick = () => {
    this.setState({
      showFormSameUni:
        this.state.value === "5" ? false : this.state.showFormSameUni,
      showFormOtherUni:
        this.state.value === "6" ? false : this.state.showFormOtherUni, 
    });
  };

  onClickEdit = (row) => {
    // console.log(row);
    this.setState({
      educationalData: row,
      showFormSameUni: false,
    });
  };
  onClickEditOther = (row) => {
    // console.log(row);
    this.setState({
      educationalData: row,
      showFormOtherUni: false,
    });
  };
  onClickDelete = (row) => {
    // console.log(row);
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoint.educationalDetails + "/" + row.id,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Data deleted successfully",
            });
            this.props.getEducationByAluminiId({ id: "" }).then((response) => {
              if (!response) {
                getAlert({ message: "Something went wrong" });
              }
            });
          }
        });
      }
    });
  };

  onSaveData = () => {
    this.setState({
      educationalData: {},
      showFormSameUni: true,
      showFormOtherUni: true,
    });
  };

  onSave = (data, value) => {
    // console.log(data);
    const { saveStudentFillProfile } = this.props;
    saveStudentFillProfile({ studentData: data }).then((response) => {
      if (response) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        this.setState({
          value: value,
        });
      } else {
        getAlert({ message: myConstClass.serverMsg });
      }
    });
  };

  render() {
    const {
      isLoading,
      value,
      showFormSameUni,
      showFormOtherUni,
      educationalData,
      title,
    } = this.state;

    return (
      <>
        <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
          <TitleAndBreadCrum title={title} />
        </Paper>
        <br />
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={this.changeTabs}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    icon={<InfoIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Basic Details"
                    value="1"
                  />
                  <Tab
                    icon={<HomeIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Address Details"
                    value="2"
                  />
                  <Tab
                    icon={<AssignmentIndIcon />}
                    textColor="secondary"
                    indicatorColor="secondary"
                    label="Work Experience"
                    value="3"
                  />
                  <Tab
                    icon={<AccountCircleIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Social Profile"
                    value="4"
                  />
                  <Tab
                    icon={<SchoolIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Educational Details"
                    value="5"
                  />
                  <Tab
                    icon={<ArticleIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Other Educational Detail"
                    value="6"
                  />
                  <Tab
                    icon={<EmojiEventsIcon />}
                    textColor="secondary"
                    indicatorcolor="secondary"
                    label="Achievement"
                    value="7"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <PersonalDetails
                  onSave={(data) => {
                    this.onSave(data, "2");
                  }}
                />
              </TabPanel>
              <TabPanel value="2">
                <AddressDetails
                  onCancel={this.backToBasicDetails}
                  onSave={(data) => {
                    this.onSave(data, "3");
                  }}
                />
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="3">
                <WorkExp
                  onCancel={this.backToAddress}
                  onSubmit={this.goToSocialProfile}
                />
              </TabPanel>
              <TabPanel value="4">
                <SocialProfile
                  onCancel={this.backToWorkExperience}
                  onSave={(data) => {
                    this.onSave(data, "5");
                  }}
                />
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="5">
                <LandingScreenHeader
                  tableColumnsToFilter={[]}
                  screenTitle={""}
                  showPdfDownload={false}
                  // generatePDF={this.generatePDF}
                  showExcelDownload={false}
                  // generateExcel={this.generateExcel}
                  showAddButton={true}
                  showSearchBox={false}
                  onAddButtonClick={this.onAddButtonClick}
                  showTable={showFormSameUni}
                />
                {!showFormSameUni && (
                  <EduDetailsOfSameUnit
                    cancelForm={() => {
                      this.setState({
                        showFormSameUni: true,
                        educationalData: {},
                      });
                    }}
                    educationalData={educationalData}
                    onSaveData={this.onSaveData}
                  />
                )}
                <Grid sx={{ marginTop: !showFormSameUni ? 4 : 0 }}>
                  <DynamicTable
                    data={this.props.studentFillProfileList?.otherEducation.filter(
                      (data) => data.programTitleId !== "-"
                    )}
                    tableHead={this.columns}
                    showPegination={false}
                    isActionColActive={true}
                    showHeadDelete={true}
                    showHeadEdit={true}
                    rowEdit={this.onClickEdit}
                    rowDelete={this.onClickDelete}
                  />
                  {/* {
                    showFormSameUni &&
                  <> */}
                  <br />
                  <Grid
                    rowSpacing={2}
                    container
                    columnSpacing={1}
                    justifyContent="right"
                  >
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back"
                        onClick={this.backToSocialProfile}
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        onClick={this.goToOtherDetails}
                        name="Save and Proceed"
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  {/* </>
                  } */}
                </Grid>
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="6">
                <LandingScreenHeader
                  tableColumnsToFilter={[]}
                  screenTitle={""}
                  showPdfDownload={false}
                  // generatePDF={this.generatePDF}
                  showExcelDownload={false}
                  // generateExcel={this.generateExcel}
                  showAddButton={true}
                  showSearchBox={false}
                  onAddButtonClick={this.onAddButtonClick}
                  showTable={showFormOtherUni}
                />
                {!showFormOtherUni && (
                  <EduDetailsOfOtherUni
                    cancelForm={() => {
                      this.setState({
                        showFormOtherUni: true,
                        educationalData: {},
                      });
                    }}
                    educationalData={educationalData}
                    onSaveData={this.onSaveData}
                  />
                )}
                <Grid sx={{ marginTop: !showFormOtherUni ? 4 : 0 }}>
                  <DynamicTable
                    data={this.props.studentFillProfileList?.otherEducation.filter(
                      (data) => data.programTitleId === "-"
                    )}
                    tableHead={this.columns}
                    showPegination={false}
                    isActionColActive={true}
                    showHeadDelete={true}
                    showHeadEdit={true}
                    rowEdit={this.onClickEditOther}
                    rowDelete={this.onClickDelete}
                  />
                  {/* {
                    showFormOtherUni &&
                  <> */}
                  <br />
                  <Grid
                    rowSpacing={2}
                    container
                    columnSpacing={1}
                    justifyContent="right"
                  >
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back"
                        onClick={this.backToEducationalDetails}
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        onClick={this.goToAchievements}
                        name="Save and Proceed"
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  {/* </>
                  } */}
                </Grid>
              </TabPanel>
              <TabPanel value="7" sx={{ padding: 0 }}>
                <AluminiAchivments
                  onCancel={this.backToOtherDetails}
                  onSubmit={this.goToDetails}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
        {isLoading && <Loading1 />}
        {/* <pre>{JSON.stringify(fieldData, null, 2)}</pre> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentFillProfileList: state.studentFillProfile,
  studentDetails: state.studentFillProfile,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getWorkExperienceByAluminiId,
  getEducationByAluminiId,
  getProgramTitleNew,
  getAlumniRegistration,
  getBloodGroup,
  saveStudentFillProfile,
  getCountry,
  getState,
  getCity,
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicMainScreen);
