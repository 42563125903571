import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import AttachFile from "../../components/Comman/AttachFile";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiPost } from "../../utils/api_service";
import { openFileInNewTab } from "../UploadFile/file.slice";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";

let docs;
class AdminGalleryFolder extends Component {
  state = {
    id: 0,
    description: "",
    title: "",
    formErrors: [],
    documentName: "",
    fileName: "",
    upload: false,
    showIcon: false,
    detail: "",
    date: "",
    docType: "0",
    isActive: 1,
    detailsName: "",
    rows: [],
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      ...this.state,
      [dataKey]: date,
    });
  };

  // handleFormValidation() {
  //   const { title, description, date, fileName } = this.state;

  //   let formErrors = {};
  //   let formIsValid = true;

  //   if (title.toString().trim() === "" || title === null) {
  //     formIsValid = false;
  //     formErrors["titleError"] = myConstClass.titleMsg;
  //   }
  //   if (description.toString().trim() === "" || description === null) {
  //     formIsValid = false;
  //     formErrors["descriptionError"] = myConstClass.descriptionMsg;
  //   }

  //   if (date === "" || date === null) {
  //     formIsValid = false;
  //     formErrors["dateError"] = myConstClass.dateMsg;
  //   }

  //   this.setState({ ...this.state, formErrors: formErrors });
  //   return formIsValid;
  // }
  // handleDetailValidation() {
  //   const { detailsName, fileName, docType } = this.state;

  //   let formErrors = {};
  //   let formIsValid = true;

  //   if (detailsName.toString().trim() === "" || detailsName === null) {
  //     formIsValid = false;
  //     formErrors["detailsNameError"] = myConstClass.titleMsg;
  //   }
  //   if (fileName.toString().trim() === "" || fileName === null) {
  //     formIsValid = false;
  //     formErrors["fileNameError"] = myConstClass.fileName;
  //   }

  //   if (docType === "" || docType === null) {
  //     formIsValid = false;
  //     formErrors["docTypeError"] = myConstClass.docTypeMsg;
  //   }

  //   this.setState({ ...this.state, formErrors: formErrors });
  //   return formIsValid;
  // }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, detailsName, fileName } = this.state;
    if (Object.keys(this.props.fieldData).length !== 0) {
      docs = {
        id: id,
        docName: fileName,
        fileName: detailsName,
        isActive: 1,
      };
      console.log(docs);
      apiPost({
        url: endpoint.internshipDocument,
        postBody: docs,
      }).then(({ success }) => {
        if (success) {
          this.setState({
            ...this.state,
            id: 0,
            detailsName: "",
            fileName: "",
          });
          this.props.showNotification({ msg: "Data updated successfully" });
          this.props.onSave();
        } else {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    } else {
      const formData = new FormData();
      formData.append("file", fileName);
      apiPost({
        url: endpoint.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          docs = {
            docName: data,
            fileName: detailsName,
            isActive: 1,
          };
          console.log(docs);
          apiPost({
            url: endpoint.internshipDocument,
            postBody: docs,
          }).then(({ success }) => {
            if (success) {
              this.setState({
                ...this.state,
                id: 0,
                detailsName: "",
                fileName: "",
              });
              this.props.showNotification({ msg: "Data saved successfully" });
              this.props.onSave();
            }
          });
        } else {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  componentDidMount() {
    if (Object.keys(this.props.fieldData).length !== 0) {
      console.log(this.props.fieldData);

      this.setState({
        id: this.props.fieldData.id,
        isActive: this.props.fieldData.isActive,
        detailsName: this.props.fieldData.fileName,
        fileName: this.props.fieldData.docName,
        showIcon: this.props.fieldData.docName !== "" ? true : false,
      });
    }
  }

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
      showIcon: false,
    });
  };

  rowView = (e, rowData) => {
    this.props.openFileInNewTab({ file: rowData.docName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  cancleHandler = () => {
    this.setState({
      ...this.state,
      id: 0,
      detailsName: "",
      fileName: "",
    });
    this.props.onCancel();
  };

  render() {
    const { fileName, detailsName, showIcon } = this.state;
    const { detailsNameError, fileNameError } = this.state.formErrors;

    return (
      <>
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label=""
              />
            </Grid>
            <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back to List"
                onClick={this.cancleHandler}
              />
            </Grid>
          </Grid>
          <br />
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={1}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Document Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} />
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        color="primary"
                        name="detailsName"
                        size="small"
                        value={detailsName}
                        onChange={this.changeHandler}
                        fullWidth
                        error={detailsNameError ? true : false}
                        helperText={detailsNameError ? detailsNameError : " "}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={12} md={12}></Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        container
                        justifyContent="space-evenly"
                      >
                        {fileNameError ? (
                          <FormHelperText error>
                            {"*Please select image"}
                          </FormHelperText>
                        ) : (
                          <FormHelperText>{}</FormHelperText>
                        )}
                      </Grid>

                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-evenly"
                      >
                        <AttachFile name={"file"} fileName={this.changeH} />
                        &nbsp; {fileName !== "" ? fileName.name : ""}
                        {showIcon ? (
                          <>
                            &nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={VisibilityIcon}
                              color="primary"
                              fontSize="medium"
                              title="View"
                              onClick={(e) =>
                                this.rowView(e, this.props.fieldData)
                              }
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                sx={{ mr: 0.5 }}
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                m={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Cancel"
                    onClick={this.cancleHandler}
                    fullWidth={true}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="space-evenly"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fileData: state.file,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  openFileInNewTab,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminGalleryFolder);
