import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";
const initialState = {
  extendedActivity: [],
  isFetchActivity: false,
};
let URL = endpoints.extendedActivity;
const extendedActivitySlice = createSlice({
  name: "extendedActivity",
  initialState,
  reducers: {
    extendedSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        extendedActivity: row,
        isFetchActivity: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { extendedSuccess, resetSuccess } = extendedActivitySlice.actions;

export default extendedActivitySlice.reducer;

export const getExtendedActivities = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            employeeActivity: data1.employeeActivity.id,
            type: data1.employeeActivity.activityType,
            name: data1.employeeActivity.name,
            activityTitle: data1.activityTitle,
            activityType: data1.activityType,
            activityTypeName: data1.activityType,
            // == 0 ? "Attended" : data1.activityType == 1 ? "Organized" : data1.activityType == 2 ? "Resource Person" : data1.activityType == 3 ? "Other" : "",
            date: data1.date,
            level: data1.level,
            organizedBy: data1.organizedBy,
            isActive: data1.isActive,
            noOfParticipants: data1.noOfParticipants,
            extendedActivitiesDocuments: data1.extendedActivitiesDocuments.map(
              (folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }
            ),
          };
          row.push(bData);
          return data1;
        });
        dispatch(extendedSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminExtendedActivities =
  ({ userRegistration, level, activityType, activityId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-user-registration-id-or-level-or-activity-or-type?userRegistrationId=" +
          userRegistration +
          "&level=" +
          level +
          "&activity=" +
          activityId +
          "&type=" +
          activityType,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              employeeActivity: data1.employeeActivity.id,
              type: data1.employeeActivity.activityType,
              name: data1.employeeActivity.name,
              activityTitle: data1.activityTitle,
              activityType: data1.activityType,
              activityTypeName: data1.activityType,
              // == 0 ? "Attended" : data1.activityType == 1 ? "Organized" : data1.activityType == 2 ? "Resource Person" : data1.activityType == 3 ? "Other" : "",
              date: data1.date,
              level: data1.level,
              organizedBy: data1.organizedBy,
              isActive: data1.isActive,
              noOfParticipants: data1.noOfParticipants,
              userRegistration:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.id,
              departmentId:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.userRegistrationDepartment.id,
              extendedActivitiesDocuments:
                data1.extendedActivitiesDocuments.map((folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(extendedSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// http://localhost:8088/api/acdmc/v1/employee-extended-activities/by-user-registration-id-or-level-or-activity-or-type?userRegistrationId=b2a78ed0-5e06-4ce4-99a9-29591c7b166b&level=&activity=&type=

export const getExtendedActivityByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-extended-activities-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              employeeActivity: data1.employeeActivity.id,
              type: data1.employeeActivity.activityType,
              name: data1.employeeActivity.name,
              activityTitle: data1.activityTitle,
              activityType: data1.activityType,
              activityTypeName:
                data1.activityType == 0
                  ? "Attended"
                  : data1.activityType == 1
                  ? "Organized"
                  : data1.activityType == 2
                  ? "Resource Person"
                  : "",
              date: data1.date,
              level: data1.level,
              organizedBy: data1.organizedBy,
              isActive: data1.isActive,
              noOfParticipants: data1.noOfParticipants,
              userRegistration:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.id,
              departmentId:
                data1.userRegistration === null
                  ? ""
                  : data1.userRegistration.userRegistrationDepartment.id,
              extendedActivitiesDocuments:
                data1.extendedActivitiesDocuments.map((folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(extendedSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getExtendedActivityWithoutFilter = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-extended-activities-list-by-limit?limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            employeeActivity: data1.employeeActivity.id,
            type: data1.employeeActivity.activityType,
            name: data1.employeeActivity.name,
            activityTitle: data1.activityTitle,
            activityType: data1.activityType,
            activityTypeName:
              data1.activityType == 0
                ? "Attended"
                : data1.activityType == 1
                ? "Organized"
                : data1.activityType == 2
                ? "Resource Person"
                : "",
            date: data1.date,
            level: data1.level,
            organizedBy: data1.organizedBy,
            isActive: data1.isActive,
            noOfParticipants: data1.noOfParticipants,
            extendedActivitiesDocuments: data1.extendedActivitiesDocuments.map(
              (folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }
            ),
          };
          row.push(bData);
          return data1;
        });
        dispatch(extendedSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
