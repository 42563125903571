import { Grid, Paper, Link, TextField, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { getAlert } from "../../CommonActions/alert.slice";
import { LabelCompo } from "../../components/Comman/Label";
import AutoComplete from "../../components/Comman/AutoComplete";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { getCompanyByTypeAndDepartment } from "../Masters/Faculty/Company/company.slice";
import {
  getYearDetails,
  getDistinctDivision,
} from "./InternshipDeclataration.slice";
import { getDepartment } from "../Masters/Internship/Department/department.slice";
import * as myConstClass from "../../config/messageconstant";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import moment from "moment";
import { Link as RouterLink, useLocation } from "react-router-dom";
import TimePicker from "../../components/Comman/TimePicker";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { getCompanyById } from "../Masters/Faculty/Company/company.slice";
import { apiGet } from "../../utils/api_service";
import endpoints from "../../config/endpoints";
class Declaration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      companyNameId: "",
      division: [],
      internshipTypeId: "",
      yearDetailId: "",
      designation: "",
      stipend: "",
      durationFor: "",
      durationTo: "",
      venue: "",
      fromTime: null,
      toTime: null,
      fromDate: "",
      toDate: "",
      type: "",
      urlLink: "",
      remark: "",
      qualiCriteria: "0",
      interviewMode: "1",
      formErrors: [],
      divisionList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        let divisionData = [];
        divisionData = [
          { id: "all", name: "All" },
          ...nextProps.internshipDeclarationList.distinctDivision.filter(
            (data) => data.isActive == 1
          ),
        ];

        this.setState({
          divisionList: divisionData,
        });
      }
    }
  }

  componentDidMount() {
    const {
      getCompanyByTypeAndDepartment,
      getInternshipType,
      getYearDetails,
      value,
      getDepartment,
    } = this.props;
    getCompanyByTypeAndDepartment({ type: "1" }).then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getInternshipType().then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getYearDetails().then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getDepartment().then((response) => {
      if (response) {
      } else {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    if (Object.keys(value).length !== 0) {
      const durationForArr =
        value.durationFor !== "" ? value.durationFor.split("-") : "";
      const durationFor =
        durationForArr !== ""
          ? new Date(
              durationForArr[2],
              durationForArr[1] - 1,
              durationForArr[0]
            )
          : "";
      const durationToArr =
        value.durationTo !== "" ? value.durationTo.split("-") : "";
      const durationTo =
        durationToArr !== ""
          ? new Date(durationToArr[2], durationToArr[1] - 1, durationToArr[0])
          : "";
      const fromDateArr =
        value.fromDate !== "" ? value.fromDate.split("-") : "";
      const fromDate =
        fromDateArr !== ""
          ? new Date(fromDateArr[2], fromDateArr[1] - 1, fromDateArr[0])
          : "";
      const toDateArr = value.toDate !== "" ? value.toDate.split("-") : "";
      const toDate =
        toDateArr !== ""
          ? new Date(toDateArr[2], toDateArr[1] - 1, toDateArr[0])
          : "";

      //
      const fromtimeDate =
        value.fromTime !== ""
          ? moment(value.fromTime, ["h:mm A"]).format("HH:mm")
          : "";

      const fromTimeArr =
        fromtimeDate !== "" ? fromtimeDate.split(":", -1) : null;

      const fromTime =
        fromTimeArr !== null
          ? new Date(null, null, null, fromTimeArr[0], fromTimeArr[1])
          : null;

      const toTimeDate =
        value.toTime !== ""
          ? moment(value.toTime, ["h:mm A"]).format("HH:mm")
          : "";

      const toTimeArr = toTimeDate !== "" ? toTimeDate.split(":", -1) : null;

      var toTime =
        toTimeArr !== null
          ? new Date(null, null, null, toTimeArr[0], toTimeArr[1])
          : null;

      this.setState({
        companyNameId: value.companyNameId,
        division: value.division.split(","),
        internshipTypeId: value.internshipTypeId,
        type: value.internshipDeclareType,
        yearDetailId: value.yearDetailId,
        designation: value.designation,
        stipend: value.stipend,
        durationFor: durationFor,
        durationTo: durationTo,
        venue: value.venue,
        fromTime: fromTime,
        toTime: toTime,
        fromDate: fromDate,
        toDate: toDate,
        urlLink: value.urlLink,
        remark: value.remark,
        qualiCriteria: value.qualiCriteria,
        interviewMode: value.interviewMode,
      });

      this.props
        .getDistinctDivision({ yearDetail: value.yearDetailId })
        .then((response) => {
          if (response) {
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  }
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  changedHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  timeChangeHandler = (dataKey) => (time) => {
    this.setState({
      [dataKey]: time,
    });
  };
  ChangeHandlerSearch = (name, newValue) => {
    const { getDistinctDivision, companyList } = this.props;
    console.log(companyList);
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name == "yearDetailId") {
        this.setState({
          division: [],
        });
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (response) {
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
      if (name == "companyNameId") {
        apiGet({
          url: endpoints.company + "/" + newValue,
        }).then(({ data }) => {
          if (data !== null || data !== "") {
            this.setState({
              venue: data.data.address,
            });
          }
        });
      }
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation() {
    const {
      companyNameId,
      internshipTypeId,
      yearDetailId,
      division,
      venue,
      type,
      fromDate,
      toDate,
      durationFor,
      durationTo,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (companyNameId.toString().trim() === "" || companyNameId === null) {
      formIsValid = false;
      formErrors["companyNameIdError"] = myConstClass.companyNameMsg;
    }

    if (
      internshipTypeId.toString().trim() === "" ||
      internshipTypeId === null
    ) {
      formIsValid = false;
      formErrors["internshipTypeIdError"] = myConstClass.internTypeMsg;
    }

    if (type.toString().trim() === "") {
      formIsValid = false;
      formErrors["typeError"] = myConstClass.internshipDeclareTypeMsg;
    }

    if (!yearDetailId.length) {
      formIsValid = false;
      formErrors["yearDetailIdError"] = myConstClass.yearDetailMsg;
    }

    if (!division.length) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divisionNameMsg;
    }

    if (fromDate != "" && toDate != "" && fromDate > toDate) {
      formIsValid = false;
      formErrors["fromDateError"] = "To Date cannot be less than From Date";
    }

    if (durationFor != "" && durationTo != "" && durationFor > durationTo) {
      formIsValid = false;
      formErrors["fromDateError"] = "To Date cannot be less than From Date";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeHandlers = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changeHandler1 = (event) => {
    if (event.target.value === "1") {
    }
  };
  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  //   multiSelectHandler = ( value, name ) =>
  //   {
  //     if ( name == "division" )
  // {
  //       let divisionIds = this.props.internshipDeclarationList?.distinctDivision.map( ( data ) =>
  //       {
  //         return data.id;
  //       } );

  //       if ( value == "all" )
  //       {
  //         this.setState( {
  //           [name]: divisionIds
  //         } )
  //       }
  //       else {
  //         this.setState({
  //           ...this.state,
  //           [name]: typeof value === "string" ? value.split(",") : value,
  //         });
  //       }
  //     }
  //     else
  //     {
  //     this.setState({
  //       ...this.state,
  //       [name]: typeof value === "string" ? value.split(",") : value,
  //     });
  //     }
  //   };
  //

  multiSelectHandler = (value, name) => {
    const filterValue = value.filter((rowValue) => rowValue === "all");

    if (filterValue.length !== 0) {
      if (name === "division") {
        let divisionList = [];
        this.state.divisionList.map((data) => {
          if (data.id !== "all") {
            divisionList.push(data.id);
          }
        });
        this.setState({
          [name]: divisionList,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      companyNameId,
      division,
      internshipTypeId,
      type,
      yearDetailId,
      designation,
      stipend,
      durationFor,
      durationTo,
      venue,
      fromTime,
      toTime,
      fromDate,
      toDate,
      urlLink,
      remark,
      qualiCriteria,
      interviewMode,
    } = this.state;

    let changedDurationFromDate =
      durationFor === "" ? "" : moment(durationFor).format("DD-MM-YYYY");
    let changedDurationToDate =
      durationTo === "" ? "" : moment(durationTo).format("DD-MM-YYYY");
    let changedFromDate =
      fromDate === "" ? "" : moment(fromDate).format("DD-MM-YYYY");
    let changedToDate =
      toDate === "" ? "" : moment(toDate).format("DD-MM-YYYY");
    let changedFromTime =
      fromTime === null ? "" : moment(fromTime, "h:mm:ss A").format("HH:mm");
    let changedToTime =
      toTime === null ? "" : moment(toTime, "h:mm:ss A").format("HH:mm");

    let docs = {
      companyNameId: companyNameId,
      division: division.toString(),
      internshipTypeId: internshipTypeId,
      internshipDeclareType: type,
      yearDetailId: yearDetailId,
      designation: designation,
      stipend: stipend,
      durationFor: changedDurationFromDate,
      durationTo: changedDurationToDate,
      venue: venue,
      fromTime: changedFromTime,
      toTime: changedToTime,
      fromDate: changedFromDate,
      toDate: changedToDate,
      urlLink: urlLink,
      remark: remark,
      qualiCriteria: qualiCriteria,
      interviewMode: interviewMode,
    };
    if (this.handleFormValidation()) {
      this.props.OnSave(docs);
    }
  };
  dropDownChangeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  render() {
    const {
      companyNameIdError,
      internshipTypeIdError,
      venueError,
      yearDetailIdError,
      divisionError,
      typeError,
      fromDateError,
    } = this.state.formErrors;
    const {
      companyNameId,
      division,
      internshipTypeId,
      type,
      yearDetailId,
      designation,
      stipend,
      durationFor,
      durationTo,
      venue,
      fromTime,
      toTime,
      fromDate,
      toDate,
      urlLink,
      remark,
      qualiCriteria,
      interviewMode,
    } = this.state;
    const {
      companyList,
      internshipTypeList,
      onCancel,
      internshipDeclarationList,
      departments,
    } = this.props;

    return (
      <>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Declaration of Internship "
            />
          </Grid>
          <Grid container justifyContent="right" item xs={12} sm={6} md={6}>
            <Link
              color="primary"
              variant="body2"
              component={RouterLink}
              to="/list-of-internship-declaration"
              sx={{ textDecoration: "none" }}
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="outlined"
                name="Back To List"
                fullWidth={true}
              />
            </Link>
          </Grid>
        </Grid>
        <br />
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={1}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Company Name "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={companyNameId}
                    name="companyNameId"
                    options={companyList?.companyByDepartment.filter(
                      (row) => row.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                    isError={companyNameIdError ? true : false}
                    errorText={companyNameIdError ? companyNameIdError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              sx={{ marginTop: -1 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Type"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl color={"primary"} fullWidth={true}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className="text-black"
                      name="type"
                      id="type"
                      value={type}
                      onChange={this.dropDownChangeHandler}
                      size="small"
                      fullWidth={true}
                      error={typeError ? true : false}
                    >
                      <MenuItem value={0}>On Campus</MenuItem>
                      <MenuItem value={1}>Off Campus</MenuItem>
                      <MenuItem value={2}>Pool Campus</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    &ensp;&ensp; {typeError ? typeError : " "}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Intern Type"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={internshipTypeId}
                    name="internshipTypeId"
                    options={internshipTypeList?.internshipType.filter(
                      (data) => data.isActive == 1
                    )}
                    onChange={this.ChangeHandlerSearch}
                    isError={internshipTypeIdError ? true : false}
                    errorText={
                      internshipTypeIdError ? internshipTypeIdError : " "
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Interview Mode "
                  />
                </Grid>

                <Grid sx={{ mt: -3 }} item xs={12} sm={12} md={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="interviewMode"
                      id="interviewMode"
                      name="interviewMode"
                      value={interviewMode}
                      onChange={this.changeHandlers}
                      size="small"
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Online"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Offline"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Stipend( ₹ Per month )"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="stipend"
                      size="small"
                      value={stipend}
                      onChange={this.changedHandler}
                      fullWidth
                      helperText={" "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Designation "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="designation"
                      size="small"
                      value={designation}
                      onChange={this.changeHandler}
                      fullWidth
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Declaration Valid From Date "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="fromDate"
                      size="small"
                      value={fromDate}
                      fullWidth
                      onChange={this.onDateChange("fromDate")}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Declaration Valid To Date "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="toDate"
                      size="small"
                      value={toDate}
                      minDate={fromDate}
                      fullWidth
                      onChange={this.onDateChange("toDate")}
                      isError={fromDateError ? true : false}
                      errorText={fromDateError ? fromDateError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Detail "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetailId}
                      name="yearDetailId"
                      options={internshipDeclarationList?.yearDetails.filter(
                        (data) => data.isActive == 1
                      )}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailIdError ? true : false}
                      errorText={yearDetailIdError ? yearDetailIdError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Division"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSelect
                      er={divisionError}
                      name1="division"
                      value1={division}
                      // names={internshipDeclarationList?.distinctDivision.filter(
                      //   (data) => data.isActive == 1
                      // )}

                      names={this.state.divisionList}
                      onChange={this.multiSelectHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Company Address"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      color="primary"
                      name="venue"
                      size="small"
                      value={venue}
                      onChange={this.changeHandler}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                sx={{ marginTop: 2 }}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Internship Duration From Date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="durationFor"
                      size="small"
                      value={durationFor}
                      fullWidth
                      minDate={toDate}
                      onChange={this.onDateChange("durationFor")}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Internship Duration To Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <BasicDatePicker
                      color="primary"
                      name="durationTo"
                      size="small"
                      value={durationTo}
                      minDate={durationFor}
                      fullWidth
                      onChange={this.onDateChange("durationTo")}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="From Time "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TimePicker
                      value={fromTime}
                      onChange={this.timeChangeHandler("fromTime")}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="To Time "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TimePicker
                      value={toTime}
                      onChange={this.timeChangeHandler("toTime")}
                      helperText={" "}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid tem xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="URL Link "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="urlLink"
                    size="small"
                    value={urlLink}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              columnSpacing={1}
              container
              sx={{ marginTop: -2 }}
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Qualification Criteria"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="qualiCriteria"
                      id="qualiCriteria"
                      name="qualiCriteria"
                      value={qualiCriteria}
                      onChange={this.changeHandlers}
                      size="small"
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Yes"
                        // onClick={handleClickOpen}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            >
              <Link
                color="primary"
                variant="body2"
                component={RouterLink}
                to="/list-of-internship-declaration"
                sx={{ textDecoration: "none" }}
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="outlined"
                  name="Cancel"
                  fullWidth={true}
                />
              </Link>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={2.2}
              lg={2.2}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save and Proceed"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  companyList: state.company,
  internshipTypeList: state.internshipType,
  departments: state.department,
  internshipDeclarationList: state.internshipDeclaration,
  companyList: state.company,
});
const mapDispatchToProps = {
  getCompanyByTypeAndDepartment,
  getInternshipType,
  getYearDetails,
  getAlert,
  getDepartment,
  getDistinctDivision,
  getCompanyById,
};
export default connect(mapStateToProps, mapDispatchToProps)(Declaration);
