import { FormHelperText, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import DocumentVerificationForm from "../DocumentVerification/DocumentVerificationForm";
import {
  getDocumentVerificationDataList,
  getDocumentVerificationUpdated,
  resetValues,
} from "./documentVerification.slice";
import DocumentVerificationTable from "./DocumentVerificationTable";
import { getStudentByApplicationId } from "../BasicDetailsPost/basicDetails.slice";
import StudentPreview from "../StudentPreview1/index";
import { LabelCompo } from "../../components/Comman/Label";
import NewLandingScreenHeader from "../../components/Comman/NewLandingScreenHeader";
import {AllocatedStudentListJson} from "../../DynamicFormsJson/AllocatedStudentListJson"
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
const DocumentVerification = ({
  resetValues,
  documentVerificationList,
  getDocumentVerificationUpdated,
  getDocumentVerificationDataList,
  getAlert,
  getStudentByApplicationId,
  showNotification,
}) => {
  const [saveData, setSaveData] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [allotedStatus, setAllotedStatus] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [listErrorMsg, setListErrorMsg] = React.useState(false);

  const allotmentStatusList = [
    { id: "0", name: "Waiting" },
    { id: "1", name: "Allocated" },
    { id: "2", name: "Confirm Admission" },
    { id: "3", name: "Rejected Admission" },
    { id: "4", name: "Cancelled Admission" },
    { id: "5", name: "Cancelled Waiting List" },
    { id: "6", name: "System Cancelled (institute_status)" },
  ];

  const rowStatus = () => {
    setShowLoader(true)
    if (list.length !== 0) {
      setListErrorMsg(false);
      swal({
        title: "Are you sure?",
        text: "You want to change Status for this student",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((newValue) => {
        if (newValue) {
          getDocumentVerificationUpdated({
            appId: list,
            status: allotedStatus,
          }).then((success) => {
            if (success) {
              showNotification({
                msg: "Status Updated successfully",
              });
              window.location.replace("/document-verification");
            } else {
              setShowLoader(false)
              getAlert({ message: "Something went wrong" });
            }
          });
        }
      });
    } else {
      setListErrorMsg(true);
    }
  };

  useEffect(() => {
    resetValues();
  }, []);

  const changeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      setAllotedStatus(newValue);
    } else {
      setAllotedStatus('');  
    }
  };

  const getRow = (e) => {
    setList(e);
  };
  const verificationData = (data) => {
    setSaveData(data);
  };
  const rowView = (row) => {
    getStudentByApplicationId({
      userApplicationId: row.userApplicationId,
    }).then((response) => {
      if (response) {
        setOpenPreview(true);
      } else {
        getAlert({ message: "Something went wrong" });
      }
    });
  };
  const GoBack = () => {
    setOpenPreview(false);
  };
  // const {documentVerificationList}=this.props;
  return (
    <>
    <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
        <TitleAndBreadCrum title={AllocatedStudentListJson.screenTitle} />
        </Paper>
        <br/>
      {!openPreview && (
        <Paper sx={{borderRadius: 2 }} elevation={0}>
          <DocumentVerificationForm onSave={verificationData} />
          {listErrorMsg && (
            <FormHelperText error>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{"*Please select minimum 1 student"}{" "}
            </FormHelperText>
          )}
          <Grid item sx={{ p: 2 }} xs={12} sm={12} md={12}>
            <DocumentVerificationTable
              onSelectionChange={getRow}
              rowView={rowView}
              selection={list}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={1}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} sm={6} md={6}>
              <Grid sx={{ mt: 2, pl: 2 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Change Status of student"
                />
              </Grid>
              <Grid sx={{ mt: 1, pl: 2 }} item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={allotedStatus}
                  name={"allotedStatus"}
                  options={allotmentStatusList}
                  onChange={changeHandlerSearch}
                />
              </Grid>
            </Grid>
            <Grid sx={{ mt: 6 }} item xs={12} sm={6} md={6}>
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Update"
                onClick={rowStatus}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      {openPreview && <StudentPreview GoBack={GoBack} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  documentVerificationList: state.documentVerification,
});

const mapDispatchToProps = {
  getDocumentVerificationUpdated,
  getDocumentVerificationDataList,
  resetValues,
  getAlert,
  showNotification,
  getStudentByApplicationId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentVerification);
