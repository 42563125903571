export const ExternalStudentJson = {
  screenTitle: "External Student Event",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  marginTop: 1,
  fieldMeta: [
    // {
    //   label: "Academic Year",
    //   controlType: "autocomplete",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "acadamicMaster",
    //   getListId: "acadamicYear,yearDetail",
    //   dataKey: "acadamicYear",
    //   isMandatory: true,
    // },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "yearMaster",
      getListId: "yearDetail,acadamicYear",
      dataKey: "yearDetail",
      isMandatory: true,
    },

    {
      label: "Division",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "divisionMaster",
      getListFrom: "yearDetail",
      dataKey: "division",
      isMandatory: true,
    },
  ],
};
