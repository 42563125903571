import { Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getBatchBySemesterList } from "../../Batches/BatchList/batchlist.slice";
import { getEmployeeByDeptId } from "../../EmployeeDetails/employee.slice";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { getSemisterInfo } from "../../Masters/Admission/Semister Info/semisterInfo.slice";
import {
  getSubjectByDepartment,
  getSubjectDetailBySubject,
} from "../../Masters/Admission/Subject Info/subjectInfo.slice";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getRollNoList } from "../../RollNoList/GenrateRollNo/GenerateRollNo.slice";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice"; // import AccountFees from "../AccountFees/index";

const rows = [
  {
    index: 1,
    id: 1,
    studentName: "TCS",
    prnNo: "NskRd",
    rollNo: "1",
    reportingDate: "",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 2,
    id: 2,
    studentName: "Wipro",
    prnNo: "",
    rollNo: "2",
    reportingDate: "12-06-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 3,
    id: 3,
    studentName: "ATS",
    prnNo: "123",
    rollNo: "3",
    reportingDate: "1-01-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
];
class WorkloadAllocationForm extends React.Component {
  state = {
    id: 0,
    yearDetail: "",
    semester: "",
    division: "",
    formErrors: [],
    batch: "",
    department: "",
    subject: "",
    subjectScheme: "",
    employee: "",
    credit: "",
    noOfLectures: "",
  };

  componentDidMount() {
    const {
      getYearDetails,
      getEmployeeByDeptId,
      getSubjectByDepartment,
      getSubjectDetailBySubject,
      getDepartment,
      data,
    } = this.props;
    console.log(data);
    if (data.id !== undefined) {
      this.setState({
        id: data.id,
        yearDetail: data.yearDetailId,
        semester: data.semesterDetailId,
        division: data.divisionId,
        batch: data.batchId,
        department: data.department,
        subjectScheme: data.subjectSchemeId,
        employee: data.userRegistrationId,
        credit: data.credit,
        noOfLectures: data.noOfLectures,
        subject: data.subjectId,
      });
      getEmployeeByDeptId({
        departmentId: data.department,
      }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
      getSubjectByDepartment({
        departmentId: data.department,
      }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
      this.props
        .getSubjectDetailBySubject({
          subjectId: data.subjectId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    const {
      getDistinctDivision,
      getSemesterDetailsByYearAndAcademic,
      getEmployeeByDeptId,
    } = this.props;
    if (newValue !== null) {
      this.setState({ [name]: newValue });

      if (name == "yearDetail") {
        getDistinctDivision({ yearDetail: newValue }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        getSemesterDetailsByYearAndAcademic({
          yearId: newValue,
          academicId: "",
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
      if (name == "department") {
        getEmployeeByDeptId({
          departmentId: newValue,
        }).then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        this.props
          .getSubjectByDepartment({
            departmentId: newValue,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.setState({ [name]: "" });
      }
    }

    if (name == "semester") {
      this.props
        .getBatchBySemesterList({
          semisterID: newValue,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }

    if (name == "subject") {
      this.props
        .getSubjectDetailBySubject({
          subjectId: newValue,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  handleFormValidation() {
    console.log("handleFormValidation()");
    const {
      academicYear,
      yearDetail,
      division,
      semester,
      batch,
      department,
      subjectScheme,
      employee,
      subject,
      credit,
      noOfLectures,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (academicYear === "" || academicYear === null) {
      formIsValid = false;
      formErrors["academicYearError"] = myConstClass.acYearEmptyMsg;
    }

    if (department === "" || department === null) {
      formIsValid = false;
      formErrors["departmentErr"] = myConstClass.departmentMsg;
    }

    if (yearDetail === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (division === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }

    if (semester === "" || semester === null) {
      formIsValid = false;
      formErrors["semesterError"] = myConstClass.semisterNameMsg;
    }

    if (batch === "" || batch === null) {
      formIsValid = false;
      formErrors["batchError"] = myConstClass.batchMsg;
    }

    if (subject === "" || subject === null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectNameMsg;
    }

    if (subjectScheme === "" || subjectScheme === null) {
      formIsValid = false;
      formErrors["subjectSchemeError"] = myConstClass.subjectSchemeMSg;
    }

    if (employee === "" || employee === null) {
      formIsValid = false;
      formErrors["employeeError"] = myConstClass.employeeTypeMsg;
    }

    if (credit === "" || credit === null) {
      formIsValid = false;
      formErrors["creditError"] = myConstClass.CreditMsg;
    }

    if (noOfLectures === "" || noOfLectures === null) {
      formIsValid = false;
      formErrors["noOfLecturesError"] = myConstClass.noOfLecturesMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  //   searchData = () => {
  //     const { fromDate, toDate, internType } = this.state;
  //     const { getCompanyList } = this.props;
  //     let fromDt = moment(fromDate).format("DD-MM-YYYY");
  //     let toDt = moment(toDate).format("DD-MM-YYYY");
  //     console.log(fromDt + " fromDate");
  //     console.log(toDt + " toDate");
  //     console.log(internType + " internType");
  //     if (this.handleFormValidation()) {
  //       // getCompanyList({ fromDate: fromDate, toDate: toDate, internType: internType });
  //     }
  //   };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };

  rowAppliedList = (data) => {
    console.log(data);
    const { comanyData } = this.state;
    this.setState({
      interestedStudent: true,
      listOfComp: false,
      companyData: data,
    });
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };
  submitHandler = () => {
    const { getRollNoList, onSave, showNotification } = this.props;
    const {
      division,
      semester,
      yearDetail,
      batch,
      department,
      subject,
      subjectScheme,
      employee,
      credit,
      noOfLectures,
      id,
    } = this.state;

    let workloadAllocate = {
      userRegistration: {
        id: employee,
      },
      semisterDetail: {
        id: semester,
      },
      subjectDetail: {
        id: subjectScheme,
      },
      division: {
        id: division,
      },
      batch: {
        id: batch,
      },
      department: {
        id: department,
      },
      credit: credit,
      noOfLectures: noOfLectures,
      isActive: 1,
    };
    if (id != 0) {
      workloadAllocate = {
        ...workloadAllocate,
        id: id,
      };
    }
    console.log(workloadAllocate);
    if (this.handleFormValidation()) {
      console.log("handleFormValidation()");
      apiPost({
        url: endpoints.employeeWorkload,
        postBody: workloadAllocate,
      }).then(({ data, success }) => {
        this.props.showNotification({ msg: "Data Saved successfully" });
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props.goToList();
        }
      });
    }
  };
  render() {
    const {
      division,
      semester,
      yearDetail,
      batch,
      department,
      subject,
      subjectScheme,
      employee,
      credit,
      noOfLectures,
    } = this.state;
    const {
      yearDetailError,
      divisionError,
      semesterError,
      batchError,
      departmentErr,
      subjectError,
      subjectSchemeError,
      employeeError,
      creditError,
      noOfLecturesError,
    } = this.state.formErrors;
    const {
      internshipDeclarationList,
      rollNoData,
      departmentList,
      employeeDetails,
      batchList,
      subjectList,
    } = this.props;
    console.log(batchList?.batchListBySemester);
    console.log(subjectList?.subjectByDepartment);
    console.log(subjectList?.subjectDetailBySubject);
    return (
      <>
        <div>
          <Paper sx={{ borderRadius: 2 }} elevation={0}>
            <>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid item sx={{ ml: 2 }} xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label="Add Employee Workload"
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={internshipDeclarationList?.yearDetails}
                      onChange={this.ChangeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Semister Details"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={semester}
                      name={"semester"}
                      options={rollNoData?.semisterDetailsList}
                      onChange={this.ChangeHandlerSearch}
                      isError={semesterError ? true : false}
                      errorText={semesterError ? semesterError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Division"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={division}
                      name={"division"}
                      options={internshipDeclarationList?.distinctDivision}
                      onChange={this.ChangeHandlerSearch}
                      isError={divisionError ? true : false}
                      errorText={divisionError ? divisionError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Batch"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={batch}
                      name={"batch"}
                      options={batchList?.batchListBySemester}
                      onChange={this.ChangeHandlerSearch}
                      isError={batchError ? true : false}
                      errorText={batchError ? batchError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Department"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={department}
                      name={"department"}
                      options={departmentList?.department}
                      onChange={this.ChangeHandlerSearch}
                      isError={departmentErr ? true : false}
                      errorText={departmentErr ? departmentErr : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Subject"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={subject}
                      name={"subject"}
                      options={subjectList?.subjectByDepartment}
                      onChange={this.ChangeHandlerSearch}
                      isError={subjectError ? true : false}
                      errorText={subjectError ? subjectError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Subject Scheme"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={subjectScheme}
                      name={"subjectScheme"}
                      options={subjectList?.subjectDetailBySubject}
                      onChange={this.ChangeHandlerSearch}
                      isError={subjectSchemeError ? true : false}
                      errorText={subjectSchemeError ? subjectSchemeError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Employee"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={employee}
                      name={"employee"}
                      options={employeeDetails?.employeeList}
                      onChange={this.ChangeHandlerSearch}
                      isError={employeeError ? true : false}
                      errorText={employeeError ? employeeError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Credit"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="credit"
                      id="credit"
                      value={credit}
                      onChange={this.changeHandler}
                      error={creditError ? true : false}
                      helperText={creditError ? creditError : " "}
                      fullWidth
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Number Of Lectures"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="noOfLectures"
                      id="noOfLectures"
                      value={noOfLectures}
                      onChange={this.changeHandler}
                      fullWidth
                      error={noOfLecturesError ? true : false}
                      helperText={noOfLecturesError ? noOfLecturesError : " "}
                    ></TextField>
                  </Grid>
                </Grid>

                {/* <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>

                  </Grid>

                  <Grid item xs={12} sm={12} md={12}></Grid>
                </Grid> */}
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="right"
                  m={1}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={this.props.goToList}
                  />
                  &nbsp;&nbsp;
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="contained"
                    name="Save"
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  studentRollNoList: state.rollNoList,
  semisterList: state.semister,
  rollNoData: state.rollNo,
  departmentList: state.department,
  employeeDetails: state.employeeList,
  batchList: state.batch,
  subjectList: state.subjectInfo,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getRollNoList,
  getSemisterInfo,
  getSemesterDetailsByYearAndAcademic,
  getDepartment,
  getEmployeeByDeptId,
  getBatchBySemesterList,
  getSubjectByDepartment,
  getSubjectDetailBySubject,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkloadAllocationForm);
