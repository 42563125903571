import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { decodeData } from "../../components/Comman/Util/Base64";
import endpoint from "../../config/endpoints";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";

const initialState = {
  file: "",
  fileData: [],
  isFetch: false,
  filePath: "",
  isDownload: false,
  fileDataView: [],
  isFetchFile: false,
};
let URL = endpoints.fileUpload;
let videoURL = endpoints.videoDownload;
let DownloadURL = endpoints.fileDownload;
let DeleteURL = endpoints.fileDelete;
let DeleteAllURL = endpoints.fileAllDelete;

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    fileSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        file: data,
        isFetch: true,
      };
    },
    fileMulitipleSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { fileData } = action.payload;
      return {
        ...state,
        fileData: [...state.fileData, fileData],
        isFetch: true,
      };
    },
    fileMulitipleSaveResetSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { fileData } = action.payload;
      return {
        ...state,
        fileData: fileData,
        isFetch: true,
      };
    },
    MultifileDownloadSuccess: (state = cloneDeep(initialState), action) => {
      const { fileData } = action.payload;
      return {
        ...state,
        fileDataView: [...state.fileDataView, fileData],
        isFetchFile: true,
      };
    },
    fileFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        fileData: [],
        fileDataView: [],
        file: "",
        isFetch: false,
      };
    },
    fileDownloadSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        filePath: data,
        isDownload: true,
      };
    },
    fileDownloadFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        filePath: "",
        isDownload: false,
      };
    },
    videoSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        video: data,
        isFetchVideo: true,
      };
    },
  },
});

export const {
  fileDownloadFailure,
  fileDownloadSuccess,
  fileSaveSuccess,
  MultifileDownloadSuccess,
  fileMulitipleSaveResetSuccess,
  fileMulitipleSaveSuccess,
  fileFailure,
  videoSaveSuccess,
} = fileSlice.actions;

export default fileSlice.reducer;

export const saveFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = apiPost({
        url: URL,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          console.log("Success Save file  ========>", data);
          console.log(data);
          dispatch(fileSaveSuccess({ data }));
          return true;
        }
        return false;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveMultipleFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      console.log(file);
      const formData = new FormData();
      formData.append("file", file.fileName);

      const success = apiPost({
        url: URL,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;

          console.log("Success Save file  ========>", data);
          console.log(data);
          let fileData = {
            index: index + 1,
            // id: id,
            fileName: data,
            name: file.name,
          };
          dispatch(fileMulitipleSaveSuccess({ fileData }));
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const downloadFile =
  ({ file }) =>
  async (dispatch) => {
    const response = apiGet({
      url: DownloadURL + "/" + file,
    }).then(({ data, success }) => {
      if (success) {
        let fileRes = data.data;
        dispatch(fileDownloadSuccess({ data: fileRes }));
      }

      return success;
    });
    return response;
  };
export const downloadFileinMap =
  ({ file }) =>
  async (dispatch) => {
    const response = apiGet({
      url: DownloadURL + "/" + file,
    }).then(({ data, success }) => {
      if (success) {
        let fileRes = data.data;
        dispatch(fileDownloadSuccess({ fileRes }));
      }

      return success;
    });
    return response;
  };

export const deleteFile =
  ({ file }) =>
  async (dispatch) => {
    const response = apiGet({
      url: DeleteURL + "/" + file,
    }).then(({ success }) => {
      return success;
    });
    return response;
  };

export const deleteAllFile =
  ({ fileNames }) =>
  async (dispatch) => {
    const response = apiGet({
      url: DeleteAllURL + "?fileNames=" + fileNames,
    }).then(({ success }) => {
      return success;
    });
    return response;
  };

export const viewMultipleFile =
  ({ file }) =>
  async (dispatch) => {
    try {
      let filename = file.fileName;
      const doc = apiGet({
        url: DownloadURL + "/" + filename,
      }).then(({ data, success }) => {
        if (success) {
          let fileRes = data.data;
          let fileData = {
            fileName: fileRes,
            name: file.name,
          };
          dispatch(MultifileDownloadSuccess({ fileData }));
          return fileData;
        }
        return success;
      });
      return doc;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const deleteFileData = () => async (dispatch) => {
  try {
    dispatch(fileFailure());
  } catch (e) {
    return console.error(e.message);
  }
};
export const fileMulitipleSave =
  ({ fileData }) =>
  async (dispatch) => {
    try {
      dispatch(fileMulitipleSaveResetSuccess({ fileData }));
    } catch (e) {
      return console.error(e.message);
    }
  };
export const openFileInNewTab =
  ({ file }) =>
  async (dispatch) => {
    try {
      const success = apiGet({
        url: DownloadURL + "/" + file,
      }).then(({ data, success }) => {
        if (success) {
          let fileRes = data.data;
          window.open(fileRes, "_blank");
        }
        return success;
      });
      return success;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const saveVideo =
  ({ file }) =>
  async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = apiPost({
        url: URL + "-video",
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          console.log("Success Save file  ========>", data);
          console.log(data);
          dispatch(videoSaveSuccess({ data }));
          return { data, success };
        } else {
          return { data, success };
        }
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const downloadVideo =
  ({ url }) =>
  async (dispatch) => {
    try {
      let videoURL = endpoint.videoDownload;
      const name = localStorage.getItem("token");
      const token = decodeData(name);
      axios
        .get(videoURL + "/" + url, {
          headers: {
            Authorization: token,
            shouldrefresh: "true",
          },
          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], {
            type: "application/mp4",
          });
          const fileURL = URL.createObjectURL(file);
          console.log(fileURL);
          dispatch(fileDownloadSuccess({ data: fileURL }));
          // const pdfWindow = window.open();
          // pdfWindow.location.href = fileURL;
        });
    } catch (e) {}
  };

// =>
// async (dispatch) => {
//   const response = apiGet({
//     url: videoURL + "/" + url,
//   }).then(({ data, success }) => {
//     if (success) {
//       console.log(data);
//       dispatch(fileDownloadSuccess({ data }));
//     }

//     return success;
//   });
//   return response;
// };
