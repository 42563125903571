import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { FromFeesMasterJson } from '../../../../DynamicFormsJson/FromFees.js';
import { FormFeesMasterColumns } from "../../../../tableColumns/table-columns";
import { getFromFees } from './fromFees.slice'
import { getAlert } from "../../../../CommonActions/alert.slice";
class FormFeesMaster extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.props.getFromFees().then((response) => {
            if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
            }
        });
    }

    render() {
        const { fromFeesList } = this.props
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={FromFeesMasterJson.screenTitle}
                        fieldMeta={FromFeesMasterJson.fieldMeta}
                        showPdfDownload={FromFeesMasterJson.showPdfDownload}
                        showExcelDownload={FromFeesMasterJson.showExcelDownload}
                        pdfFileName={FromFeesMasterJson.pdfFileName}
                        excelFileName={FromFeesMasterJson.excelFileName}
                        showAddButton={FromFeesMasterJson.showAddButton}
                        tableColumnsToFilter={FromFeesMasterJson.tableColumnsToFilter}
                        //dynamicMasterData={dynamicMasterData}
                        tableColumns={FormFeesMasterColumns}
                        tableData={fromFeesList.fromFees}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={fromFeesList.isFetch}
                        getTableData={this.props.getFromFees}
                        baseIdColumn={FromFeesMasterJson.baseIdColumn}
                        apiBaseURL={FromFeesMasterJson.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    fromFeesList: state.fromFees
});
const mapDispatchToProps = {
    getFromFees, getAlert
}
export default connect(mapStateToProps, mapDispatchToProps)(FormFeesMaster);