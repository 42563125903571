import endpoint from "../config/endpoints";
export const scholarshipTypeMaster = {
  apiBaseURL: endpoint.scholarship,
  screenTitle: "Scholarship Type",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Scholarship Type",
  showExcelDownload: true,
  excelFileName: "Scholarship Type",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      // disable:true,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
  ],
};
