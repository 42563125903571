import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { getAlert } from "../../../CommonActions/alert.slice";
import endpoint from "../../../config/endpoints";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getDeemedMeetingById } from "../Meeting/meeting.slice";
function StudentAttendanceForm({
  backToList,
  backToDetails,
  goToAgenda,
  saveFormData,
  deemedCommitteeList,
  deemedMeetingList,
  getAlert,
  onSave,
  showView,
  getDeemedMeetingById,
}) {
  const [present, setPresent] = React.useState("");
  const [values1Error, setValues1Error] = React.useState(false);
  const [values1, setValues1] = React.useState([]);
  const [dataToSave, setDataToSave] = React.useState([]);
  const [formData, setFormData] = React.useState(saveFormData);

  useEffect(() => {
    if (showView) {
      setValues1(deemedMeetingList.deemedMeetingById.attendance);
    } else {
      if (values1.length === 0) {
        let dataToSave = [];
        let dataToSave1 = [];
        if (deemedMeetingList.deemedMeetingById.attendance.length !== 0) {
          if (
            deemedCommitteeList.deemedCommitteeMemberWithStatus.length !== 0
          ) {
            deemedCommitteeList.deemedCommitteeMemberWithStatus.map((data) => {
              deemedMeetingList.deemedMeetingById.attendance.map((data2) => {
                if (data.id === data2.memberId) {
                  dataToSave.push({
                    ...data,
                    attendance: data2.status,
                    answer: data2.remark,
                    tableId: data2.id,
                  });
                }
              });
            });
            let dataStud2 =
              deemedCommitteeList.deemedCommitteeMemberWithStatus.map(
                (data2) => {
                  let rowList1 =
                    deemedMeetingList.deemedMeetingById.attendance.filter(
                      (row) => row.memberId === data2.id
                    );

                  if (rowList1.length === 0) {
                    dataToSave.push(data2);
                  }
                }
              );
            setValues1(dataToSave);
          } else {
            setValues1(deemedMeetingList.deemedMeetingById.attendance);
          }
        } else {
          setValues1(deemedCommitteeList.deemedCommitteeMemberWithStatus);
        }
      }
    }
  }, []);

  const backToForm = () => {
    backToList();
  };

  const saveHandler = () => {
    if (values1.length !== 0) {
      onSave(values1);
    }
  };

  const onSaveTable = () => {
    if (showView === true) {
      goToAgenda();
    } else {
      let dataToSave = values1.map((data1) => {
        if (data1.tableId !== "") {
          let docs = {
            id: data1.tableId,
            scheduleCommitteeMeeting: {
              id: formData.id,
            },
            committeeMember: {
              id: data1.id,
            },
            status: data1.attendance === true ? 1 : 0,
            remark: data1.answer,
          };
          return docs;
        } else {
          let docs = {
            scheduleCommitteeMeeting: {
              id: formData.id,
            },
            committeeMember: {
              id: data1.id,
            },
            status: data1.attendance === true ? 1 : 0,
            remark: data1.answer,
          };
          return docs;
        }
      });

      apiPost({
        url: endpoint.deemedAttendance + "/save-all",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showNotification({ msg: "Data saved successfully" });
          getDeemedMeetingById({ id: formData.id }).then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
          goToAgenda();
        } else {
          showNotification({ msg: "Failed to save" });
        }
      });
    }
  };

  const changeHandler1 = (e, id) => {
    setValues1((data) =>
      data?.map((list, index) =>
        list.id === id
          ? { ...list, attendance: e.target.checked, answer: "" }
          : list
      )
    );
  };

  const changeHandler3 = (e, id) => {
    const { value } = e.target;
    setValues1((data) =>
      data?.map((list, index) =>
        list.id === id ? { ...list, answer: value } : list
      )
    );
  };

  const attendanceChangeHandler = (e) => {
    setPresent(e.target.checked);

    const val = values1.map((data) => {
      let bData = {
        ...data,
        attendance: e.target.checked,
        excuse: e.target.checked ? !e.target.checked : data.excuse,
        answer: e.target.checked ? "" : data.answer,
      };
      return bData;
    });
    setValues1(val);
  };

  return (
    <>
      <Paper sx={{ borderRadius: 2 }} elevation={0}>
        <FormHelperText error>
          {values1Error ? "Select atleast 1 member" : " "}
        </FormHelperText>

        <TableContainer>
          <Table sx={{ minWidth: 600 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Sr. No.</TableCell>
                <TableCell align="center">Member Name</TableCell>
                <TableCell align="center">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"Course"}
                          onChange={(e) => attendanceChangeHandler(e)}
                          checked={present}
                          sx={{
                            color: pink[800],
                            "&.Mui-checked": {
                              color: pink[600],
                            },
                            padding: "0px",
                          }}
                        />
                      }
                      label={"Present"}
                      labelPlacement="top"
                      disabled={showView ? true : false}
                    />
                  </FormGroup>
                </TableCell>
                <TableCell align="center">Remark</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {console.log(values1)}
              {values1.map((data) => {
                return (
                  <TableRow sx={{}}>
                    <TableCell align="center">{data.index}</TableCell>
                    <TableCell align="left">{data.memberName}</TableCell>
                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"attendance"}
                              checked={showView ? data.status : data.attendance}
                            />
                          }
                          onChange={(e) => changeHandler1(e, data.id)}
                          value={data.attendance}
                          labelPlacement="bottom"
                          disabled={showView ? true : false}
                        />
                      </FormGroup>
                    </TableCell>

                    <TableCell>
                      <FormGroup>
                        <TextField
                          color="primary"
                          name={"Percentage"}
                          size="small"
                          value={data.answer}
                          disabled={data.attendance}
                          onChange={(e) => changeHandler3(e, data.id)}
                          fullWidth
                          disabled={showView ? true : false}
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Grid
          sx={{ mr: 0.5 }}
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          m={2}
          columnSpacing={{ xs: 12, sm: 6, md: 1 }}
          container
          justifyContent="right"
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name={showView ? "Back" : "Cancel"}
              onClick={backToDetails}
            />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={1}
            lg={1}
            container
            justifyContent="space-evenly"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name={showView ? "Next" : "Save"}
              onClick={onSaveTable}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
  deemedCommitteeList: state.deemedCommittee,
  deemedMeetingList: state.deemedMeeting,
  teachingPlanList: state.teachingPlan,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getDeemedMeetingById,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentAttendanceForm);
