import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  promotionalAdmisionAcademicYear: {},
  isFetchPromotionalAdmisionAcademicYear: false,
  promotionalAdmsionStudentList: [],
  isFetchPromotionalAdmsionStudentList: false,
  paymentTransactionPojo: {},
  isFetchPaymentTransactionPojo: false,
};
let URL = endpoints.promotionalAdmission;
let URL1 = endpoints.promotionalAdmissionStudent;
const promotionalAdmmisionSlice = createSlice({
  name: "promotionalAdmmision",
  initialState,
  reducers: {
    promotionalAdmisionAcademicYearSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { bData } = action.payload;
      return {
        ...state,
        promotionalAdmisionAcademicYear: bData,
        isFetchPromotionalAdmisionAcademicYear: true,
      };
    },
    promotionalAdmsionStudentListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        promotionalAdmsionStudentList: row,
        isFetchPromotionalAdmsionStudentList: true,
      };
    },
    savePaymentTransactionPojoSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { dataToSave } = action.payload;
      return {
        ...state,
        paymentTransactionPojo: dataToSave,
        isFetchPaymentTransactionPojo: true,
      };
    },
  },
});

export const {
  promotionalAdmisionAcademicYearSuccess,
  promotionalAdmsionStudentListSuccess,
  savePaymentTransactionPojoSuccess,
} = promotionalAdmmisionSlice.actions;

export default promotionalAdmmisionSlice.reducer;

export const getPromotionalAdmisionAcademicYear = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/get-next-year-admission-academic-year-by-institute-id",
    }).then(({ data, success }) => {
      if (success) {
        if (data !== null) {
          let bData = {
            admissionOpen: data.data.admissionOpen,
            delStatus: data.data.delStatus,
            fromDate: data.data.fromDate,
            id: data.data.id,
            isActive: data.data.isActive,
            isLocked: data.data.isLocked,
            name: data.data.name,
            toDate: data.data.toDate,
            tsId: data.data.tsId,
          };
          dispatch(promotionalAdmisionAcademicYearSuccess({ bData }));
        }
      }
      return { success, data };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

// changes
export const getPromotionalAdmsionStudentList =
  ({ yearDetailId, academicYearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-next-year-admission-for-year-detail-and-academic-year?yearDetailId=" +
          yearDetailId +
          "&academicYearId=" +
          academicYearId,
      }).then(({ data, success }) => {
        if (success) {
          let row = [];
          let index = 0;
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentName:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                (data1.userRegistration.fatherName == null ||
                data1.userRegistration.fatherName == ""
                  ? " - "
                  : data1.userRegistration.fatherName) +
                " " +
                (data1.userRegistration.motherName == null ||
                data1.userRegistration.motherName == ""
                  ? " - "
                  : data1.userRegistration.motherName ),
              
                  lastName: data1.userRegistration.lastName,
              firstName:   data1.userRegistration.firstName ,
              fatherName: data1.userRegistration.fatherName,
              motherName:   data1.userRegistration.motherName,
              
              feesStructure: data1.feesStructureHeader.name,
              amount: data1.feesStructureHeader.totalFees,
              paidStatus: data1.paymentStatus === 0 ? "No" : "Yes",
              approvedStatus: data1.approvedStatus === 0 ? "No" : "Yes",
              studentRegistrationId: data1.userRegistration.id,
              isChecked: false,
              isDisabled: data1.approvedStatus === 1 ? true : false,
            };
            row.push(bData);
            return data1;
          });
          dispatch(promotionalAdmsionStudentListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const savePaymentTransactionPojo =
  ({ dataToSave }) =>
  async (dispatch) => {
    try {
      dispatch(savePaymentTransactionPojoSuccess({ dataToSave }));
    } catch (e) {
      return console.error(e.message);
    }
  };
