import endpoint from "../config/endpoints";

export const timeSlotJson = {
  apiBaseURL: endpoint.timeSlot,
  screenTitle: "Time Slot",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Time Slot",
  showExcelDownload: true,
  excelFileName: "Time Slot",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "slotName",
      isChecked: true,
    },
    {
      columnDisplayName: "From Time",
      columnKeyName: "fromTime",
      isChecked: true,
    },
    {
      columnDisplayName: "To Time",
      columnKeyName: "toTime",
      isChecked: true,
    },
    {
      columnDisplayName: "Time Slot Type",
      columnKeyName: "isRecessesName",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Program Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "programType",
      dataKey: "programType",
      defaultValue: "all",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMeta: [
    {
      label: "Program Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "programTypeId",
      masterName: "programTypeList",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Time",
      controlType: "timePicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromTime",
      isMandatory: true,
    },
    {
      label: "To Time",
      controlType: "timePicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toTime",
      isMandatory: true,
    },
    {
      label: "Slot Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "slotName",
      tableDataKey: "slotName",
      isMandatory: true,
    },
    {
      label: "Sort Number",
      controlType: "textfield",
      inputType: "number",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortNo",
      isMandatory: true,
    },
    {
      label: "Lecture",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "isRecesses",
      defaultValue: 1,
      radioGroupItems: [
        {
          label: "Yes",
          value: 0,
        },
        {
          label: "No",
          value: 1,
        },
      ],
      isMandatory: true,
    },
  ],
};
