import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Loading1 } from "../../components/Loading1";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InfoIcon from "@mui/icons-material/Info";
import Select from "@mui/material/Select";
// import { getCertificateList } from "./studentCertificate.slice"
import { resetReducList } from "../../CommonActions/User.action";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { FilterSave } from "../../components/FilterSave";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiDelete } from "../../utils/api_service";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getSubjectInfoByYearDetailId } from "../Masters/Admission/Subject Info/subjectInfo.slice";
import { openFileInNewTab } from "../UploadFile/file.slice";
import {
  getEmaterialByAcYearAndYearDet,
  getEmaterialId,
  getMaterialReview,
  getOtherSubjectList,
  getSubjectListByYearDetail,
  getStudentListByEmaterial,
  getEmaterialList,
} from "./ematerial.slice";
import EmaterialCommentDialogue from "./EmaterialCommentDialogue";
import EmaterialStudentList from "./EmaterialStudentList";
import EmaterialForm from "./EmaterialForm";

class EmaterialTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      formErrors: [],
      toDate: "",
      academicYear: "",
      yearDetail: "",
      subject: "",
      showForm: false,
      showLoader: false,
      showTable: true,
      showStudentTable: false,
      showComment: false,
      editData: {},
      commentData: {},
      searchValue: "",
      subjectsList: [],
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
      },
    };
  }

  componentDidMount() {
    const ay = localStorage.getItem("acadamicYearId");
    const yearDetailId = localStorage.getItem("yearDetailId");

    this.props.resetReducList();
    this.setState({
      showLoader: true,
    });

    this.props.getEmaterialList().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    if (yearDetailId) {
      const { getSubjectListByYearDetail, getAlert } = this.props;
      getSubjectListByYearDetail({ yearDetail: yearDetailId }).then(
        (response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
          } else {
            let departmentData = [];
            departmentData = [
              ...this.props.ematerialList.subjectListByYearDetail,
              { id: "0", name: "All" },
            ];
            this.setState({
              subjectsList: departmentData,
            });
          }
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList.yearDetails !==
      nextProps.internshipDeclarationList.yearDetails
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          yearMaster: nextProps.internshipDeclarationList.yearDetails,
        },
      });
    }

    if (this.props.ematerialList !== nextProps.ematerialList) {
      if (
        this.props.ematerialList.subjectListByYearDetail !==
        nextProps.ematerialList.subjectListByYearDetail
      ) {
        let departmentData = [];
        departmentData = [
          ...nextProps.ematerialList.subjectListByYearDetail,
          { id: "0", name: "All" },
        ];
        this.setState({
          subjectsList: departmentData,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {}

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "subject",
      title: "Subject",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
    {
      name: "topic",
      title: "Topic",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
    {
      name: "documentType",
      title: "Content Type",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "5%",
    },
  ];

  handleFormValidation() {
    const { yearDetail, subject } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (subject.toString().trim() === "" || subject === null) {
      formIsValid = false;
      formErrors["subjectError"] = myConstClass.subjectMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (data) => {
    if (this.handleFormValidation()) {
      const { yearDetail, subject } = this.state;
      let subjectName = this.state.subjectsList.filter(
        (row) => row.id == subject
      );
      const ay = localStorage.getItem("acadamicYearId");
      localStorage.setItem("semisterDetailId", "");
      localStorage.setItem("yearDetailId", yearDetail);
      localStorage.setItem("divisionId", "");
      localStorage.setItem("divisionIds", "");
      localStorage.setItem("acadamicYearId", ay);
      FilterSave();
      // if (this.handleFormValidation()) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getEmaterialByAcYearAndYearDet({
          yearDetail: yearDetail,
          subject: subjectName[0].name,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      // }
    }
  };
  onAddButtonClick = () => {
    this.setState({
      // fieldData: fieldData,
      // currentOperationMode: OperationMode.Add,
    });
  };

  onDateChange = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
  };

  OpenForm = () => {
    this.setState({
      showForm: true,
      showTable: false,
      editData: {},
    });
  };

  onCancel = () => {
    this.setState({
      showForm: false,
      showTable: true,
    });
  };

  backToList = () => {
    this.setState({
      showForm: false,
      showTable: true,
      showComment: false,
    });
  };

  backToTable = () => {
    this.setState({
      showStudentTable: false,
      showTable: true,
      showForm: false,
    });
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  changeHandlerSearch = (name, newValue) => {
    let subjectDataList = [];
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "yearDetail") {
        const { getSubjectListByYearDetail, getAlert } = this.props;

        getSubjectListByYearDetail({ yearDetail: newValue }).then(
          (response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            } else {
              let departmentData = [];
              departmentData = [
                ...this.props.ematerialList.subjectListByYearDetail,
                { id: "0", name: "All" },
              ];
              this.setState({
                subjectsList: departmentData,
              });
            }
          }
        );
      }
    } else {
      this.setState({ [name]: "" });
    }
  };
  onClickComment = (row) => {
    //
    this.props.getMaterialReview({ ematerialId: row.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showTable: true,
          showForm: false,
          showComment: true,
          commentData: row,
        });
      }
    });
  };

  onClickView = (row) => {
    if (row.docType === "1" || row.docType === "2") {
      window.open(row.docUrl);
    } else {
      this.props.openFileInNewTab({ file: row.docUrl }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  OnClickEdit = (data) => {
    //
    this.props.getEmaterialId({ id: data.id }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showTable: false,
          showForm: true,
          editData: this.props.ematerialList?.getAllEmaterial,
        });
      }
    });
  };

  OnClickDetails = (data) => {
    let academicYearId = localStorage.getItem("acadamicYearId");
    this.props
      .getStudentListByEmaterial({
        ematerial: data.id,
        academicYear: academicYearId,
      })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showStudentTable: true,
            showTable: true,
            showForm: false,
          });
        }
      });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: endpoint.ematerial + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          showLoader: true,
        });
        this.props
          .getEmaterialByAcYearAndYearDet({
            yearDetail: this.state.yearDetail,
            subject: this.state.subject,
          })
          .then((response) => {
            if (!response) {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });

        this.props.showNotification({ msg: "Data deleted successfully" });
        const { getAlert } = this.props;
      } else {
        getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  getFilteredTableData = () => {
    const { searchValue } = this.state;
    const { ematerialList } = this.props;
    return ematerialList?.ematerial.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["subject"] &&
          currentRow["subject"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["topic"] &&
          currentRow["topic"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["documentType"] &&
          currentRow["documentType"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  render() {
    const {
      academicYear,
      yearDetail,
      showForm,
      showTable,
      editData,
      dynamicMasterData,
      showLoader,
      showComment,
      commentData,
      subject,
      showStudentTable,
    } = this.state;

    const { subjectError, yearDetailError } = this.state.formErrors;
    return (
      <>
        {showTable && (
          <>
            {this.state.showLoader && <Loading1 />}
            <LandingScreenHeader
              tableColumnsToFilter={[]}
              // onRTSelectMultipleChange={this.onRTSelectMultipleChange}
              onAddButtonClick={this.OpenForm}
              onSearch={this.submitHandler}
              screenTitle={"E-material"}
              showPdfDownload={false}
              generatePDF={this.generatePDFDownload}
              showExcelDownload={false}
              generateExcel={this.generateExcel}
              showAddButton={true}
              showTable={true}
              showSearch={true}
            />
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                spacing={1}
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={5.5}
                  lg={5.5}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Details"
                    />
                  </Grid>

                  <Grid sx={{ marginBottom: 3 }} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetail}
                      name={"yearDetail"}
                      options={
                        this.props.internshipDeclarationList?.yearDetails
                      }
                      onChange={this.changeHandlerSearch}
                      isError={yearDetailError ? true : false}
                      errorText={yearDetailError ? yearDetailError : " "}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={5.5}
                  lg={5.5}
                  container
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Subject"
                    />
                  </Grid>

                  <Grid sx={{ marginBottom: 3 }} item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={subject}
                      name={"subject"}
                      options={this.state.subjectsList}
                      onChange={this.changeHandlerSearch}
                      isError={subjectError ? true : false}
                      errorText={subjectError ? subjectError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={1}
                  lg={1}
                  container
                  justifyContent="flex-start"
                >
                  <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={2}>
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="contained"
                      name="Search"
                      onClick={this.submitHandler}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <TableContainer component={Paper} variant="scrollable">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="100" align="center">
                        Sr. No.
                      </TableCell>
                      <TableCell width="200" align="center">
                        Subject Name
                      </TableCell>
                      <TableCell width="200" align="center">
                        Module{" "}
                      </TableCell>
                      <TableCell width="200" align="center">
                        Date
                      </TableCell>
                      <TableCell width="200" align="center">
                        Uploaded By
                      </TableCell>

                      <TableCell width="150" align="center">
                        Document Type
                      </TableCell>
                      <TableCell width="150" align="center">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.ematerialList?.ematerial.map((row, index) => (
                      <>
                        <TableRow>
                          <TableCell align="center">{index + 1}</TableCell>

                          <TableCell component="th" scope="row">
                            {row.subject}
                          </TableCell>
                          <TableCell>{row.topic}</TableCell>
                          <TableCell align="center">{row.date}</TableCell>
                          <TableCell align="left">{row.uploadedBy}</TableCell>
                          <TableCell>{row.documentType}</TableCell>
                          <TableCell align="center" width="15%">
                            <MyComponentWithIconProps
                              statusImage={CommentIcon}
                              color="primary"
                              fontSize="small"
                              // sx={{ marginLeft: 35 }}
                              title="Comment"
                              onClick={(e) => this.onClickComment(row)}
                            />
                            &nbsp;
                            <MyComponentWithIconProps
                              statusImage={VisibilityIcon}
                              color="primary"
                              fontSize="small"
                              // sx={{ marginLeft: 35 }}
                              title="View"
                              onClick={(e) => this.onClickView(row)}
                            />
                            &nbsp;
                            {row.flag === "0" && (
                              <MyComponentWithIconProps
                                statusImage={EditIcon}
                                color="primary"
                                fontSize="small"
                                title="Edit"
                                // sx={{ marginLeft: 3 }}
                                onClick={(e) => this.OnClickEdit(row)}
                              />
                            )}
                            &nbsp;
                            {row.flag === "0" && (
                              <MyComponentWithIconProps
                                statusImage={DeleteIcon}
                                color="error"
                                fontSize="small"
                                // sx={{ marginLeft: 35 }}
                                title="Delete"
                                onClick={(e) => this.rowDelete(row)}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}

        {showForm && (
          <>
            <EmaterialForm editData={editData} onCancel={this.onCancel} />
          </>
        )}
        {showComment && (
          <>
            <EmaterialCommentDialogue
              commentData={commentData}
              onClose={this.backToList}
            />
          </>
        )}
        {showStudentTable && (
          <>
            <EmaterialStudentList
              open={showStudentTable}
              handleClose={this.backToTable}
              ematerialStudentList={
                this.props.ematerialList?.studentListByEmaterial
              }
            />
          </>
        )}
      </>
    );
  }
}

// commentData={commentData} backToList={this.backToList}
const mapStateToProps = (state) => ({
  certificateList: state.studentCertificate,
  academicYearList: state.academicYear,
  internshipDeclarationList: state.internshipDeclaration,
  ematerialList: state.ematerial,
  subjectsList: state.subjectInfo,
});
const mapDispatchToProps = {
  getEmaterialByAcYearAndYearDet,
  showNotification,
  getAlert,
  getYearDetails,
  getEmaterialId,
  resetReducList,
  getMaterialReview,
  openFileInNewTab,
  getOtherSubjectList,
  getSubjectInfoByYearDetailId,
  getSubjectListByYearDetail,
  getStudentListByEmaterial,
  getEmaterialList,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmaterialTable);
