import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreen";
import { activityJson } from "../../../../../DynamicFormsJson/Activity";
import { activityColumns } from "../../../../../tableColumns/table-columns";
import { getActivity } from "./activity.slice";
import { Loading1 } from "../../../../../components/Loading1";

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      dynamicMasterData: {
        type: [
          { id: "0", name: "Co-curricular Activity" },
          { id: "1", name: "Innovative Teaching Learning Strategies" },
          { id: "2", name: "Seminar/Webinar Activity" },
          { id: "3", name: "Courses Attended" },
          { id: "4", name: "Participation Details" },
          { id: "5", name: "Extended Activity" },
          { id: "6", name: "Committee Handle" },
        ],
      },
    };
  }

  componentDidMount() {
    const { getActivity } = this.props;
    this.setState({ showLoader: true });
    getActivity().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  render() {
    const { dynamicMasterData } = this.state;
    const { activityList, getActivity } = this.props;
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <div>
          <DynamicMainScreen
            screenTitle={activityJson.screenTitle}
            fieldMeta={activityJson.fieldMeta}
            showPdfDownload={activityJson.showPdfDownload}
            showExcelDownload={activityJson.showExcelDownload}
            pdfFileName={activityJson.pdfFileName}
            excelFileName={activityJson.excelFileName}
            showAddButton={activityJson.showAddButton}
            tableColumnsToFilter={activityJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            tableColumns={activityColumns}
            tableData={activityList?.activity}
            getTableData={this.props.getActivity}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={activityList.isActivityFetch}
            apiBaseURL={activityJson.apiBaseURL}
            baseIdColumn={activityJson.baseIdColumn}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
});
const mapDispatchToProps = {
  getActivity,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(Activity);
