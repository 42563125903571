import { Checkbox, Divider, FormGroup, Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { getDist, getTal } from "../Student/student.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
// import { ButtonCompo } from "../../components/Comman/Buttton";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import { createFilterOptions } from "@mui/material/Autocomplete";
let docs;
class StudAddress extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    id: 0,
    perAddress1: "",
    perAddress2: "",
    perAddress3: "",
    pin: "",
    state: "",
    district: "",
    taluka: "",
    city: "",

    curAddress1: "",
    curAddress2: "",
    curAddress3: "",
    curpin: "",
    curstate: "",
    curdistrict: "",
    curtaluka: "",
    curcity: "",
    checkForm: 0,
    flag: 0,
    formErrors: [],
  };

  filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  changeHandler = (event) => {
    const { name, value, checked } = event.target;

    if (name === "checkForm") {
      const {
        id,
        perAddress1,
        perAddress2,
        perAddress3,
        taluka,
        district,
        city,
        state,
        pin,
      } = this.state;

      if (checked === true) {
        this.setState({
          ...this.state,
          [name]: 1,
          id: id,
          curAddress1: perAddress1,
          curAddress2: perAddress2,
          curAddress3: perAddress3,
          curtaluka: taluka,
          curcity: city,
          curstate: state,
          curpin: pin,
          curdistrict: district,
        });
      } else {
        this.setState({
          ...this.state,
          [name]: 0,
          id: id,
          curAddress1: "",
          curAddress2: "",
          curAddress3: "",
          curtaluka: "",
          curcity: "",
          curstate: "",
          curpin: "",
          curdistrict: "",
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };
  componentDidMount() {
    const { studentList } = this.props;
    if (studentList.isSave) {
      this.setState({
        id: studentList.applicant.id,
        perAddress1: studentList.applicant.permanentAddressLine1,
        perAddress2: studentList.applicant.permanentAddressLine2,
        perAddress3: studentList.applicant.permanentAddressLine3,
        pin: studentList.applicant.permanentPin,
        state: studentList.applicant.permanentState,
        district: studentList.applicant.permanentDistrict,
        taluka: studentList.applicant.permanentTaluka,
        city: studentList.applicant.permanentCity,
        curAddress1: studentList.applicant.temporaryAddressLine1,
        curAddress2: studentList.applicant.temporaryAddressLine2,
        curAddress3: studentList.applicant.temporaryAddressLine3,
        curpin: studentList.applicant.temporaryPin,
        curstate: studentList.applicant.temporaryState,
        curdistrict: studentList.applicant.temporaryDistrict,
        curtaluka: studentList.applicant.temporaryTaluka,
        curcity: studentList.applicant.temporaryCity,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.applicant !== nextProps.studentList.applicant
      ) {
        this.setState({
          id: nextProps.studentList.applicant.id,
          perAddress1: nextProps.studentList.applicant.permanentAddressLine1,
          perAddress2: nextProps.studentList.applicant.permanentAddressLine2,
          perAddress3: nextProps.studentList.applicant.permanentAddressLine3,
          pin: nextProps.studentList.applicant.permanentPin,
          state: nextProps.studentList.applicant.permanentState,
          district: nextProps.studentList.applicant.permanentDistrict,
          taluka: nextProps.studentList.applicant.permanentTaluka,
          city: nextProps.studentList.applicant.permanentCity,
          curAddress1: nextProps.studentList.applicant.temporaryAddressLine1,
          curAddress2: nextProps.studentList.applicant.temporaryAddressLine2,
          curAddress3: nextProps.studentList.applicant.temporaryAddressLine3,
          curpin: nextProps.studentList.applicant.temporaryPin,
          curstate: nextProps.studentList.applicant.temporaryState,
          curdistrict: nextProps.studentList.applicant.temporaryDistrict,
          curtaluka: nextProps.studentList.applicant.temporaryTaluka,
          curcity: nextProps.studentList.applicant.temporaryCity,
        });
      } else if (
        this.props.studentList.applicant !== nextProps.studentList.applicant
      ) {
        this.setState({
          id: nextProps.studentList.applicant.id,
        });
      }
    }
  }

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  handleFormValidation() {
    const {
      perAddress1,
      curAddress1,
      taluka,
      district,
      state,
      city,
      pin,
      curpin,
      curcity,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (perAddress1.toString().trim() === "") {
      formIsValid = false;
      formErrors["perAddressErr"] = myConstClass.perAddressMsg;
    }

    if (city.toString().trim() === "" || city === null) {
      formIsValid = false;
      formErrors["cityErr"] = myConstClass.cityMsg;
    }

    if (curcity.toString().trim() === "" || curcity === null) {
      formIsValid = false;
      formErrors["curcityErr"] = myConstClass.cityMsg;
    }

    if (taluka.toString().trim() === "" || taluka === null) {
      formIsValid = false;
      formErrors["talukaErr"] = myConstClass.talukaMsg;
    }
    if (district.toString().trim() === "" || district === null) {
      formIsValid = false;
      formErrors["districtErr"] = myConstClass.districtMsg;
    }

    if (state.toString().trim() === "" || state === null) {
      formIsValid = false;
      formErrors["stateErr"] = myConstClass.stateMsg;
    }

    if (pin.toString().trim() === "" || pin === null) {
      formIsValid = false;
      formErrors["pinErr"] = myConstClass.pinMsg;
    }

    if (curpin.toString().trim() === "" || curpin === null) {
      formIsValid = false;
      formErrors["currpinErr"] = myConstClass.pinMsg;
    }

    if (curAddress1.toString().trim() === "" || curAddress1 === null) {
      formIsValid = false;
      formErrors["curAddressErr"] = myConstClass.localAddMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const {
      id,
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      curtaluka,
      curdistrict,
      curcity,
      curstate,
      curpin,
      taluka,
      district,
      city,
      state,
      pin,
    } = this.state;

    docs = {
      id: id,
      permanentAddressLine1: perAddress1,
      permanentAddressLine2: perAddress2,
      permanentAddressLine3: perAddress3,
      permanentPin: pin,
      permanentState: state,
      permanentDistrict: district,
      permanentTaluka: taluka,
      permanentCity: city,
      temporaryAddressLine1: curAddress1,
      temporaryAddressLine2: curAddress2,
      temporaryAddressLine3: curAddress3,
      temporaryPin: curpin,
      temporaryState: curstate,
      temporaryDistrict: curdistrict,
      temporaryTaluka: curtaluka,
      temporaryCity: curcity,
      type: 2,
    };
    // if (this.handleFormValidation()) {
    this.props.studAddressData(docs);
    this.setState({ flag: 1 });
    // }
  };
  render() {
    const {
      perAddress1,
      perAddress2,
      perAddress3,
      curAddress1,
      curAddress2,
      curAddress3,
      taluka,
      city,
      district,
      state,
      pin,
      curtaluka,
      curcity,
      curdistrict,
      curstate,
      curpin,
    } = this.state;

    const { onCancel, studentList, editForm } = this.props;

    return (
      <>
        <form
          onSubmit={this.submitHandler}
          autoComplete="off"
          noValidate={true}
        >
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            spacing={2}
          >
            <Grid columnSpacing={1} container justifyContent="space-evenly">
              <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label="Permanent Address"
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid> */}

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ mt: 1 }}
                justifyContent="space-evenly"
              >
                {" "}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Address Line"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="perAddress1"
                    size="small"
                    value={perAddress1}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                    disabled={editForm ? true : false}
                    // {...(perAddressErr
                    //   ? { error: true, helperText: perAddressErr }
                    //   : "")}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Road"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="perAddress2"
                    size="small"
                    value={perAddress2}
                    onChange={this.changeHandler}
                    disabled={editForm ? true : false}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                {" "}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Landmark"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="perAddress3"
                    size="small"
                    value={perAddress3}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs    :12, sm    :6, md    :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="pin"
                    size="small"
                    value={pin}
                    disabled={editForm ? true : false}
                    inputProps={{ maxLength: 6 }}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    helperText={" "}
                    // {...(pinErr ? { error: true, helperText: pinErr } : "")}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs    :12, sm    :6, md    :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={state}
                    disabled={editForm ? true : false}
                    name={"state"}
                    options={studentList?.state.filter(
                      (data) => data.isActive == 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={district}
                    name={"district"}
                    disabled={editForm ? true : false}
                    options={studentList?.district.filter(
                      (district) =>
                        district.stid === state && district.isActive === 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Taluka"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={taluka}
                    name={"taluka"}
                    disabled={editForm ? true : false}
                    options={studentList?.taluka.filter(
                      (taluka) =>
                        taluka.distid === district && taluka.isActive === 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="City/Village"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="city"
                    size="small"
                    value={city}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                    // {...(cityErr ? { error: true, helperText: cityErr } : "")}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4} md={6}></Grid>
              {/* 
              <Grid item xs={12} sm={6} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid> */}

              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkForm"
                        id="checkForm"
                        value={1}
                        onChange={this.changeHandler}
                      />
                    }
                    label="Same as of Permanent Address"
                    // labelPlacement="right"
                  />
                </FormGroup>
              </Grid>
              <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18 }}
                  label="Correspondence Address"
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}>
                <Divider textAlign="left"></Divider>
              </Grid> */}
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ mt: 1 }}
                justifyContent="space-evenly"
              >
                {" "}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Address Line"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curAddress1"
                    size="small"
                    value={curAddress1}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                    // {...(curAddressErr
                    //   ? { error: true, helperText: curAddressErr }
                    //   : "")}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                {" "}
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Road"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curAddress2"
                    size="small"
                    value={curAddress2}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" Landmark"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curAddress3"
                    size="small"
                    value={curAddress3}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Pincode"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curpin"
                    size="small"
                    value={curpin}
                    disabled={editForm ? true : false}
                    inputProps={{ maxLength: 6 }}
                    onChange={this.changeHandlerNumber}
                    fullWidth
                    helperText={" "}
                    // {...(currpinErr
                    //   ? { error: true, helperText: currpinErr }
                    //   : "")}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="State"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curstate}
                    name={"curstate"}
                    disabled={editForm ? true : false}
                    options={studentList?.state.filter(
                      (data) => data.isActive == 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              {/* new code */}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                // columnSpacing={{xs    :12, sm    :6, md    :1}}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="District"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curdistrict}
                    disabled={editForm ? true : false}
                    name={"curdistrict"}
                    options={studentList?.district.filter(
                      (curdistrict) =>
                        curdistrict.stid === curstate &&
                        curdistrict.isActive === 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Taluka"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <AutoComplete
                    keyColName={"id"}
                    value={curtaluka}
                    name={"curtaluka"}
                    disabled={editForm ? true : false}
                    options={studentList?.taluka.filter(
                      (taluka) =>
                        taluka.distid === curdistrict && taluka.isActive === 1
                    )}
                    filterOptions={this.filterOptions}
                    onChange={this.ChangeHandlerSearch}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="City/Village"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="curcity"
                    size="small"
                    value={curcity}
                    disabled={editForm ? true : false}
                    onChange={this.changeHandler}
                    fullWidth
                    helperText={" "}
                    //   {...(curcityErr
                    //     ? { error: true, helperText: curcityErr }
                    //     : "")}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>

              <Grid
                sx={{ mt: 1 }}
                rowSpacing={2}
                container
                columnSpacing={1}
                justifyContent="right"
              >
                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back"
                    onClick={onCancel}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item justifyContent="space-evenly">
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save and Proceed"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const Navigation = (props) => {
  let navigate = useNavigate();
  return <StudAddress {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => ({
  studentList: state.studentList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
