import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MarkAllocationPopUp({ handleCloses, onClose, onSave }) {
  const [open, setOpen] = React.useState(true);
  const [open1, setOpen1] = React.useState(false);
  const [internalMarks, setInternalMarks] = React.useState("");
  const [outOfInternal, setOutOfInternal] = React.useState("");
  const [externalMarks, setExternalMarks] = React.useState(0);
  const [outOfExternal, setOutOfExternal] = React.useState(0);
  const [formErrors, setFormErrors] = React.useState({});

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (internalMarks === "" || internalMarks === null) {
      formIsValid = false;
      formErrors["internalMarksError"] = myConstClass.internalMarksMsg;
    }

    if (outOfInternal === "" || outOfInternal === null) {
      formIsValid = false;
      formErrors["outOfInternalError"] = myConstClass.outOfInternalMsg;
    }

    if (+internalMarks > +outOfInternal) {
      formIsValid = false;
      formErrors["internalMarksError"] = myConstClass.obtainedMarksEr;
    }

    // if (externalMarks === "" || externalMarks === null) {
    //   formIsValid = false;
    //   formErrors["externalMarksError"] = myConstClass.externalMarksMsg;
    // }
    // if (outOfExternal === "" || outOfExternal === null) {
    //   formIsValid = false;
    //   formErrors["outOfExternalError"] = myConstClass.outOfExternalMsg;
    // }
    if (+externalMarks > +outOfExternal) {
      formIsValid = false;
      formErrors["externalMarksError"] = myConstClass.obtainedMarksEr;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const handleClose = () => {
    handleCloses();
  };
  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name == "internalMarks") {
      setInternalMarks(value);
    } else if (name == "outOfInternal") {
      setOutOfInternal(value);
    } else if (name == "externalMarks") {
      setExternalMarks(value);
    } else if (name == "outOfExternal") {
      setOutOfExternal(value);
    }
  };

  const submitHandler = () => {
    let bData = {
      internalMarks: internalMarks,
      outOfInternal: outOfInternal,
      externalMarks: externalMarks,
      outOfExternal: outOfExternal,
    };
    if (checkValidation()) {
      onSave(bData);
    }
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Marks For Interview
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={2}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Mark Assigning  "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}></Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Internal Marks "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="internalMarks"
                      size="small"
                      type="number"
                      value={internalMarks}
                      onChange={changedHandler}
                      fullWidth
                      helperText={
                        formErrors.internalMarksError
                          ? formErrors.internalMarksError
                          : " "
                      }
                      error={formErrors.internalMarksError ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Out of Internal Marks  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="outOfInternal"
                      type="number"
                      size="small"
                      value={outOfInternal}
                      onChange={changedHandler}
                      fullWidth
                      helperText={
                        formErrors.outOfInternalError
                          ? formErrors.outOfInternalError
                          : " "
                      }
                      error={formErrors.outOfInternalError ? true : false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="External Marks  "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="externalMarks"
                      type="number"
                      size="small"
                      value={externalMarks}
                      onChange={changedHandler}
                      fullWidth
                      helperText={
                        formErrors.externalMarksError
                          ? formErrors.externalMarksError
                          : " "
                      }
                      error={formErrors.externalMarksError ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Out of External Marks "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      color="primary"
                      name="outOfExternal"
                      type="number"
                      size="small"
                      value={outOfExternal}
                      onChange={changedHandler}
                      fullWidth
                      helperText={
                        formErrors.outOfExternalError
                          ? formErrors.outOfExternalError
                          : " "
                      }
                      error={formErrors.outOfExternalError ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}></Grid>

                  <Grid item xs={12} sm={12} md={4}></Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="right"
                    m={1}
                  >
                    <ButtonCompo
                      size="medium"
                      type="Submit"
                      variant="contained"
                      name="Submit"
                      onClick={submitHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </Paper>
    </>
  );
}
