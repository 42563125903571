import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, ListItemButton, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import config from "../config/config";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "./Comman/IconButton";
import Search from "../components/SearchAll";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { current } from "@reduxjs/toolkit";

const styles = (theme) => ({
  drawer: {
    color: theme.palette.primary.white,
    overflow: "hidden",
    // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    cursor: "pointer",
    height: "auto",
    overflow: "auto",
    // borderTop: `1px solid ${alpha(theme.palette.primary.black, 0.16)}`,
    "& li": {
      color: theme.palette.primary.black,

      "text-decoration": "none",
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      borderRadius: "5px",
      // marginBottom: theme.spacing(2),
      // marginTop: theme.spacing(2)
    },
    "& li:hover": {
      "text-decoration": "none",
    },
    "& li:active": {
      color: alpha(theme.palette.primary.white, 3),
      backgroundColor: theme.palette.primary.main,
      "text-decoration": "none",
    },
  },
  paper: {
    // backgroundColor: theme.palette.primary.smokeGrey,
  },
  itemList: {
    "& .MuiTypography-root": {
      fontSize: "14px",
      margin: "0px 0px 0px 10px",
    },
  },
  itemList1: {
    "& .MuiTypography-root": {
      fontSize: "15px",
    },
  },
  navMenu: {
    color: alpha(theme.palette.primary.black, 0.9),

    cursor: "pointer",
    "text-decoration": "none",
    marginLeft: "16px !important",
    // marginRight: theme.spacing(1),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    border: "1px",
    display: "block !important",
    "& :hover": {
      // color: theme.palette.primary.main,
      borderRadius: theme.spacing(2),
      "text-decoration": "none",
      width: "100%",
      display: "block",
    },
    "& :active": {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.white, 3),
      "text-decoration": "none",
      borderRadius: theme.spacing(2),
    },
    "& .MuiListItemButton-root.Mui-selected": {
      //  backgroundColor: theme.palette.primary.smokeGrey,
      borderRadius: theme.spacing(2),
      width: "100%",
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.main,
      // padding: `${theme.spacing(1)} ${theme.spacing(2)} !important`,
    },
    "& .MuiListItemButton-root": {
      padding: "2px 0px",
    },

    "& .MuiListItemButton-root.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.white,
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: theme.palette.primary.white,
    },
  },

  "MuiButtonBase-root": {
    border: "1px !important",
    cursor: "pointer",
    display: "inline-flex",
    position: "relative",
    "align-items": "center",
    "user-select": "none",
    "border-radius": "1px !important",
    "vertical-align": "middle",
    "justify-content": "center",
    "text-decoration": "none",
    "background-color": "transparent",
  },
});

const DrawerNav = ({ classes, toggle, common }) => {
  const [role, setRole] = React.useState("");
  const [userStatus, setUserStatus] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");

  const navigate = useNavigate();
  const [menuList, setMenuList] = React.useState([]);
  const [menuUpdatedList, setMenuUpdatedList] = React.useState([]);
  const [subMenuList, setSubMenuList] = React.useState([]);
  const handleClick = (itemName, toggle) => {
    const rows = menuList.map((module) => {
      if (module.moduleName === itemName) {
        if (toggle) {
          if (module.moduleSubs.length !== 0) {
            navigate(module.moduleSubs[0].subModuleMapping);
          }
        } else {
          module = {
            ...module,
            checked: module.checked ? false : true,
            moduleSubs: module.moduleSubs.map((subModuleName) => {
              subModuleName = {
                ...subModuleName,
                checked: false,
              };
              return subModuleName;
            }),
          };
        }

        return module;
      } else {
        module = {
          ...module,
          checked: false,
          moduleSubs: module.moduleSubs.map((subModuleName) => {
            subModuleName = {
              ...subModuleName,
              checked: false,
            };
            return subModuleName;
          }),
        };
        return module;
      }
    });
    setMenuList(rows);
  };
  const uniqueBy = (arr, prop) => {
    return arr.reduce((a, d) => {
      if (!a.includes(d[prop])) {
        a.push(d[prop]);
      }
      return a;
    }, []);
  };

  const changePath = (submenu, menu) => {
    if (submenu === "") {
    } else {
      const rows = menuList.map((module) => {
        if (module.moduleName === menu) {
          module = {
            ...module,
            moduleSubs: module.moduleSubs.map((subModuleName) => {
              if (subModuleName.subModuleMapping === submenu) {
                subModuleName = {
                  ...subModuleName,
                  checked: true,
                };
                return subModuleName;
              } else {
                subModuleName = {
                  ...subModuleName,
                  checked: false,
                };
                return subModuleName;
              }
            }),
          };
          return module;
        } else {
          return module;
        }
      });
      setMenuList(rows);
      navigate(submenu);
    }
  };
  React.useEffect(() => {
    const moduleSubModuleList = localStorage.getItem("moduleSubModule");
    let row = [];
    if (moduleSubModuleList) {
      const valuesArray = JSON.parse(moduleSubModuleList);
      // setMenuList(valuesArray);
      let row = [];
      valuesArray.map((data1) => {
        let bData = {
          baseClassName: data1.baseClassName,
          checked: data1.checked,
          className: data1.className,
          color: data1.color,
          delStatus: data1.delStatus,
          iconDiv: data1.iconDiv,
          moduleDesc: data1.moduleDesc,
          moduleId: data1.moduleId,
          moduleName: data1.moduleName,
          orderBy: data1.orderBy,
          moduleSubs: data1.moduleSubs.map((data2) => {
            let bData = {
              moduleId: data1.moduleId,
              addApproveConfig: data2.addApproveConfig,
              checked: data2.checked,
              deleteRejectApprove: data2.deleteRejectApprove,
              editReject: data2.editReject,
              isDelete: data2.isDelete,
              orderBy: data2.orderBy,
              subModuleDesc: data2.subModuleDesc,
              subModuleId: data2.subModuleId,
              subModuleMapping: data2.subModuleMapping,
              subModuleName: data2.subModuleName,
              type: data2.type,
              view: data2.view,
            };
            return bData;
          }),
        };
        row.push(bData);
        return data1;
      });
      setMenuList(row);
      setMenuUpdatedList(row);
    }

    const userRole = localStorage.getItem("role");
    if (userRole) {
      if (userRole === "alumni") {
        const aluminiStatus = localStorage.getItem("aluminiStatus");
        setUserStatus(aluminiStatus);
      }

      setRole(userRole);
    }
  }, []);

  const onSearch = (searchData) => {
    if (searchData !== "") {
      setSearchValue(searchData);
      const list = menuList.filter((currentRow) => {
        let isValid = false;
        if (
          currentRow["moduleName"] &&
          currentRow["moduleName"]
            .toString()
            .toLowerCase()
            .includes(searchData.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      });
      // let list3 = [];
      // const list = menuList.filter((currentRow) => {
      //   const list2 = currentRow.moduleSubs.filter((row) => {
      //     let isValid = false;
      //     if (
      //       row["subModuleName"] &&
      //       row["subModuleName"]
      //         .toString()
      //         .toLowerCase()
      //         .includes(searchData.toLowerCase())
      //     ) {
      //       isValid = true;
      //       return true;
      //     }
      //     return isValid;
      //   });
      //   if (list2.length !== 0) {
      //     list2.map((data) => {
      //       list3.push(data);
      //     });
      //   }
      // });

      // let list5 = [];
      // const list4 = menuList.filter((currentRow) => {
      //   list3.map((data) => {
      //     if (currentRow.moduleId === data.moduleId) {
      //       list5.push(currentRow);
      //     }
      //   });
      // });
      // let ages = uniqueBy(list5, "moduleId");
      // let list7 = [];
      // ages.map((data) => {
      //   menuList.map((row) => {
      //     if (row.moduleId === data) {
      //       list7.push(row);
      //     }
      //   });
      // });
      // let list11 = [];

      // let res = list7.map((el1) => ({
      //   moduleId: el1.moduleId,
      //   match: list9.some((el2) => el2.moduleId === el1.moduleId),
      // }));
      // res.map((data) => {
      //   menuList.map((row) => {
      //     if (row.moduleId === data.moduleId) {
      //       list11.push(row);
      //     }
      //   });
      // });

      let list8 = list.map((data) => {
        data = {
          ...data,
          checked: true,
        };
        return data;
      });
      setMenuList(list8);
    } else {
      setSearchValue("");
      const moduleSubModuleList = localStorage.getItem("moduleSubModule");
      if (moduleSubModuleList) {
        const valuesArray = JSON.parse(moduleSubModuleList);
        setMenuList(menuUpdatedList);
      }
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        anchor="left"
        open={common.toggleNav}
        onClose={toggle}
        variant="persistent"
        sx={{
          width: config.drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: config.drawerWidth,
            boxSizing: "border-box",
            bgcolor: "primary.white",
            border: "none",
            cursor: "pointer",
          },
          [`& .MuiListItem-root.Mui-selected`]: {
            color: "primary.white",
            bgcolor: "primary.main",
            border: "none",
          },
          [`& .MuiListItem-root`]: {
            color: "primary.black",
            bgcolor: "primary.white",
            border: "none",
            padding: "2px 16px",
          },
          [`& .MuiList-root`]: {
            padding: "0px 8px",
          },
        }}
      >
        <Toolbar />

        <Search
          placeHolder="Search Module…"
          inputProps={{ "aria-label": "search" }}
          searchValue={searchValue}
          filteredRows={onSearch}
        ></Search>
        <List>
          {menuList &&
            menuList.map((item) => {
              return item?.moduleSubs && item.moduleSubs.length > 1 ? (
                <>
                  <ListItem
                    selected={item.checked}
                    key={item.moduleName}
                    onClick={() => handleClick(item.moduleName, false)}
                  >
                    <FontAwesomeIconCompo
                      color={!item.checked ? item.color : "primary.white"}
                      fontSize="small"
                      title=""
                      baseClassName={item.baseClassName}
                      className={item.className}
                    />
                    &nbsp;&nbsp;
                    <ListItemText
                      primary={item.moduleName}
                      onClick={() => handleClick(item.moduleName, false)}
                    />
                    {item.checked ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={item.checked} timeout="auto" unmountOnExit>
                    {item.moduleSubs.map((nav) => {
                      if (
                        userStatus != 0 ||
                        (userStatus == 0 &&
                          nav.subModuleName !== "Engage" &&
                          nav.subModuleName !== "Members")
                      )
                        return (
                          <List
                            component="div"
                            disablePadding
                            className={classes.navMenu}
                          >
                            <ListItemButton
                              selected={nav.checked}
                              name={nav.subModuleName}
                              onClick={() =>
                                changePath(
                                  nav.subModuleMapping,
                                  item.moduleName
                                )
                              }
                            >
                              <ListItemText
                                className={classes.itemList}
                                primary={" - " + nav.subModuleName}
                              />
                            </ListItemButton>
                          </List>
                        );
                    })}
                  </Collapse>
                </>
              ) : (
                <>
                  {/* <ListItem
                    key={item.moduleName}
                    selected={item.checked}
                    name={item.moduleName}
                    onClick={() => handleClick(item.moduleName)}
                  >
                    <ListItemText primary={item.moduleName} />
                  </ListItem> */}
                  <ListItem
                    selected={item.checked}
                    key={item.moduleName}
                    onClick={() => handleClick(item.moduleName, true)}
                  >
                    <FontAwesomeIconCompo
                      color={!item.checked ? item.color : "primary.white"}
                      fontSize="small"
                      title=""
                      baseClassName={item.baseClassName}
                      className={item.className}
                    />
                    &nbsp;&nbsp;
                    <ListItemText
                      primary={item.moduleName}
                      onClick={() => handleClick(item.moduleName, true)}
                    />
                  </ListItem>
                </>
              );
            })}
        </List>
      </Drawer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  student: state.student,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DrawerNav));
