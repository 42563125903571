
import endpoint from "../config/endpoints";
  
  export const SchemeHeadMasterJson = {
  "apiBaseURL": endpoint.schemeHead,
  "screenTitle": "Scheme Head",
  "showAddButton": true,
  "showPdfDownload": true,
  "pdfFileName": "Scheme Head",
  "showExcelDownload": true,
  "excelFileName": "Scheme Head",
  "tableColumnsToFilter": [
    {
      "columnDisplayName": "Name",
      "columnKeyName": "name",
      "isChecked": true
    },
    {
      "columnDisplayName": "Short Name",
      "columnKeyName": "shortName",
      "isChecked": true
    },
    {
      "columnDisplayName": "Scheme",
      "columnKeyName": "schemeName",
      "isChecked": true
    },

    {
      "columnDisplayName": "Sort Order No",
      "columnKeyName": "sortOrderNo",
      "isChecked": true,
    },
    {
      "columnDisplayName": "Status",
      "columnKeyName": "isActive",
      "isChecked": true
    },
 
  ],
  "baseIdColumn": "id",
  "fieldMeta": [
    {
      "label": "Scheme",
      "controlType": "autocomplete",
      "placeHolder": "",
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "masterName": "schemeList",
      "dataKey": "schemeId",
      "isMandatory": true
    },
    {
      "label": "Name",
      "controlType": "textfield",
      "placeHolder": "",
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "dataKey": "name",
      "isMandatory": true
    },
    {
      "label": "Short Name",
      "controlType": "textfield",
      "placeHolder": "",
      "maxLength":2,
      "md": 6,
      "lg": 6,
      "sm": 6,
      "xs": 12,
      "dataKey": "shortName",
      "isMandatory": true
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrderNo",
      inputType:'number',
      isMandatory: true,
    },
  ]
}
