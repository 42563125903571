import { Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import LandingScreenHeader from "../../../../components/Comman/LandingScreenHeader";
import SubjectTable from "./SubjectTable";
import DynamicForm from "../../../../components/dynamicscreens/DynamicForm";
import { Loading1 } from "../../../../components/Loading1";
import * as msgConstant from "../../../../config/messageconstant";
import { showNotification } from "../../../Landing/Landing.slice";
import { apiDelete, apiPost } from "../../../../utils/api_service";
import { generateExcel } from "../../../../components/Comman/GenerateExcel";
import { generatePDF } from "../../../../components/Comman/GeneratePDF";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { getSubjectTypeDetails } from "./subjectTypeDetails.slice";
// import { Loading1 } from "../Loading1";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class SubjectTypeDetailsNew extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      dynamicMasterData: {
        subjectTypeDetails: [
          { id: 1, feeHeadTitle: "(From Fee) Student" },

          { id: 2, feeHeadTitle: "Government" },
        ],
      },
    };
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    this.setState({
      fieldData: {},
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    const { fieldMeta, dynamicMasterData } = this.props;
    let fieldData = {};

    fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;
    console.log(fieldData);
    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave);
  };
  onFormSaveAndNext = () => {
    const { fieldData, currentOperationMode } = this.state;
    const { fieldMeta } = this.props;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });

    this.onSave(dataToSave, false);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.props.getTableData();
        if (isToggle) {
          this.toggleFormTableVisibility();
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        }
        if (!isToggle) {
          let fieldData = {};
          const { fieldMeta } = this.props;
          fieldMeta.map((currentField) => {
            if (currentField.defaultValue) {
              fieldData[currentField.dataKey] = currentField.defaultValue;
            }
          });

          this.setState({
            fieldData: fieldData,
            currentOperationMode: OperationMode.Add,
          });
        }
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (rowdata) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: apiBaseURL + "/" + rowdata.id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getTableData();
        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
        this.setState({
          currentOperationMode: "",
        });
      }
    });
  };

  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  onChangeStatus = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const { fieldMeta } = this.props;

        const dataToSave = {
          // delStatus: 1,
          // trust: {
          //   id: "1",
          // },
        };

        dataToSave["id"] = rowData["id"];

        const { tableColumns } = this.props;
        let fieldData = {};

        tableColumns.forEach((currentCol) => {
          const formDataKey = currentCol.formDataKey
            ? currentCol.formDataKey
            : currentCol.name;
          const tableDataKey = currentCol.tableDataKey
            ? currentCol.tableDataKey
            : currentCol.name;
          fieldData[formDataKey] = rowData[tableDataKey];
        });

        fieldMeta.forEach((currentField) => {
          if (
            currentField.controlType === "autocomplete" &&
            !currentField.isRootLevelKey
          ) {
            dataToSave[currentField.dataKey] = {};
            dataToSave[currentField.dataKey]["id"] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          } else {
            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        });

        dataToSave["isActive"] = rowData.isActive === 1 ? "0" : "1";

        this.onSave(dataToSave, false);
      }
    });
  };

  componentDidMount() {
    const { getSubjectTypeDetails } = this.props;
    getSubjectTypeDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  generateExcel = () => {
    const { excelFileName = "Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  generatePDF = () => {
    const { excelFileName = "PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();
    const { tableColumns } = this.props;

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      tableColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    tableColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };
  render() {
    const {
      screenTitle = "",
      showSaveNextBtn = true,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      pdfFileName = "Pdf Report",
      excelFileName = "Excel Report",
      fieldMeta,
      dynamicMasterData = {},
      tableColumns = [],
      // tableData = [],
      // baseIdColumn,
      subjectTypeDetailsList,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      fieldData,
      showTable,
      showForm,
      isLoading,
    } = this.state;
    return (
      <>
        <LandingScreenHeader
          tableColumnsToFilter={tableColumnsToFilter}
          onRTSelectMultipleChange={this.onRTSelectMultipleChange}
          screenTitle={screenTitle}
          showPdfDownload={showPdfDownload}
          showExcelDownload={showExcelDownload}
          showAddButton={showAddButton}
          pdfFileName={pdfFileName}
          excelFileName={excelFileName}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          generatePDF={this.generatePDF}
          generateExcel={this.generateExcel}
          showTable={showTable}
        />

        <br />

        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          {showForm && (
            <>
              <DynamicForm
                fieldMeta={fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showSaveNextBtn={showSaveNextBtn}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave}
                onSaveAndNext={this.onFormSaveAndNext}
              />
              <br />
            </>
          )}
          {showTable && (
            <SubjectTable
              hiddenColumnNames={tableColumnsToFilter.map((item) => {
                return !item.isChecked ? item.columnKeyName : "";
              })}
              rowEdit={this.rowEdit}
              rowDelete={this.rowDelete}
              onChangeStatus={this.onChangeStatus}
              rows={this.getFilteredTableData()}
              //tableData={this.getFilteredTableData()}
              columns={tableColumns}
            />
          )}
        </Paper>
        {isLoading && <Loading1 />}
        {!subjectTypeDetailsList?.isSubjectTypeDetailsFetch && <Loading1 />}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subjectTypeDetailsList: state.subjectTypeDetails,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getSubjectTypeDetails,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectTypeDetailsNew);
