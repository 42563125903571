import { Grid, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import * as myConstClass from "../../config/messageconstant";
import { getFinancial } from "../Masters/Admission/FinancialYear/financialYear.slice";
import { getReceiptType } from "../Masters/Admission/ReceiptType/receiptType.slice";
import { getReceiptList } from "./FeesCollection.slice";

class StudentFeesDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    receiptType: "",
    financialType: "",
    typeOfAdmission: "",
    formErrors: [],
    setList: [],
    columns: [
      { name: "index", title: "Sr. No.", minWidth: 90 },
      { name: "transactionNo", title: "Transaction Number", minWidth: 200 },
      { name: "transactionDate", title: "Transaction Date", minWidth: 200 },
      { name: "receiptType", title: "Receipt Type", minWidth: 150 },
      { name: "payType", title: "Payment Type", minWidth: 150 },
      { name: "amount", title: "Amount", minWidth: 90 },
    ],
  };

  componentDidMount() {
    const { getFinancial, getReceiptType } = this.props;
    getFinancial().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getReceiptType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }
  handleFormValidation() {
    const { receiptType, financialType } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (receiptType.toString().trim() === "" || receiptType === null) {
      formIsValid = false;
      formErrors["receiptTypeError"] = myConstClass.receiptNameMsg;
    }

    if (financialType.toString().trim() === "" || financialType === null) {
      formIsValid = false;
      formErrors["financialTypeError"] = myConstClass.financeYearNameMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  searchData = () => {
    const { receiptType, financialType } = this.state;
    const { getReceiptList, studentFeeData } = this.props;
    if (this.handleFormValidation()) {
      getReceiptList({
        id: studentFeeData.userRegistrationId,
        financeYearId: financialType,
        receiptTypeId: receiptType,
      }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }
  };

  downloadReceipt = () => {
    const { setList } = this.state;
  };

  getRow = (e) => {
    this.setState({ setList: e });
  };

  render() {
    const { financialType, receiptType } = this.state;
    const {
      receiptTypeList,
      financialList,
      feesCollectionList,
      studentFeeData,
    } = this.props;
    const { financialTypeError, receiptTypeError } = this.state.formErrors;

    return (
      <>
        <div>
          {/* <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}> */}
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={5}
            md={5}
            columnSpacing={3}
          >
            <Grid item xs={12} sm={12} md={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 22 }}
                label="Student Payment Details "
              />
            </Grid>
          </Grid>
          <br></br>
          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
            columnSpacing={2}
            rowSpacing={2}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={2} md={2}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="Student Name  :"
                />
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                <TextField
                  color="primary"
                  name="domicileof"
                  size="small"
                  value={studentFeeData.applicantName}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Program :"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <TextField
                    color="primary"
                    name="domicileof"
                    size="small"
                    value={studentFeeData.program}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={0.5} md={0.5} />
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Program Title  :"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <TextField
                    color="primary"
                    name="domicileof"
                    size="small"
                    value={studentFeeData.progTitle}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={4} md={4}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Financial Year :"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <AutoComplete
                    keyColName={"id"}
                    value={financialType}
                    name={"financialType"}
                    options={financialList?.financial}
                    onChange={this.ChangeHandlerSearch}
                    isError={financialTypeError ? true : false}
                    errorText={financialTypeError ? financialTypeError : " "}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={0.5} md={0.5} />
                <Grid item xs={12} sm={3.5} md={3.5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Receipt Type :"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <AutoComplete
                    keyColName={"id"}
                    value={receiptType}
                    name={"receiptType"}
                    options={receiptTypeList?.receiptType}
                    onChange={this.ChangeHandlerSearch}
                    isError={receiptTypeError ? true : false}
                    errorText={receiptTypeError ? receiptTypeError : " "}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={1}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={1.3}
                lg={1.3}
                container
                justifyContent="space-evenly"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  fullWidth={true}
                  name="Search"
                  onClick={this.searchData}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
            Print Receipt:{" "}
          </Grid>

          <Grid item sx={{ pr: 2 }} xs={12} sm={12} md={12}>
            <DynamicSelectTable
              rows={feesCollectionList.receiptListStatus}
              columns={this.state.columns}
              selection={this.state.setList}
              onSelectionChange={this.getRow}
              isActionColActive={false}
              // columnExtensions={this.columnExtensions}
            />
            {/* <PaymentDetailsTable onSelectionChange={this.getRow} receiptList={feesCollectionList.receiptListStatus} /> */}
          </Grid>
          {/* {!documentList.isServerFail&&(!documentList?.isDataFetch ? (
<Loading1 size={50} />
) : status && !documentList?.isSave ? (
<Loading1 size={50} />
) : save && !documentList?.isSave ? (
<Loading1 size={50} />
) : del ? (
<Loading1 size={50} />
) : (
""
))} */}
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1.5}
              lg={1.5}
              container
              justifyContent="space-evenly"
            >
              <ButtonCompo
                size="medium"
                fullWidth={true}
                type="button"
                variant="contained"
                name="Download"
                onClick={this.downloadReceipt}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
              sx={{ pr: 2 }}
            >
              <ButtonCompo
                size="medium"
                fullWidth={true}
                type="button"
                variant="outlined"
                name="Back"
                onClick={this.props.onCancel}
              />
            </Grid>
          </Grid>
          {/* </Paper> */}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  feesCollectionList: state.feesCollection,
  financialList: state.financial,
  receiptTypeList: state.receiptType,
});
const mapDispatchToProps = {
  getReceiptList,
  getFinancial,
  getReceiptType,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentFeesDetails);
