import { Grid, Paper } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import { eMaterialStudentJson } from "../../DynamicFormsJson/EmaterialStudentTable";
import SearchStudentEmaterialList from "../../components/SearchTable";
import { FilterSave } from "../../components/FilterSave";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getStudentMaterial,
  getStudentMaterialByYearDetail,
  getSubjectListByYearDetail,
  getMaterialStudentReview,
} from "./ematerial.slice";
import { getYearDetailByUserId } from "../Masters/OnlineModule/Class/class.slice";
import { openFileInNewTab } from "../UploadFile/file.slice";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import EmaterialCommentDialogue from "./EmaterialStudentDialog";

import { Loading1 } from "../../components/Loading1";
class EmaterialTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      formErrors: [],
      toDate: "",
      commentData: {},
      academicYear: "",
      yearDetail: "",
      showForm: false,
      showTable: true,
      showLoader: false,
      showComment: false,
      searchValue: "",
      dynamicMasterData: {
        yearMaster: this.props.classList?.yearDetailByUserId,
        subjectMaster: this.props.ematerialList.subjectListByYearDetail,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.classList.yearDetailByUserId !==
      nextProps.classList.yearDetailByUserId
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          yearMaster: nextProps.classList.yearDetailByUserId,
        },
      });
    }

    if (
      this.props.ematerialList.subjectListByYearDetail !==
      nextProps.ematerialList.subjectListByYearDetail
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          subjectMaster: nextProps.ematerialList.subjectListByYearDetail,
        },
      });
    }
  }

  componentDidMount() {
    const yearDetailId = localStorage.getItem("yearDetailId");

    this.setState({
      showLoader: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    this.props
      .getYearDetailByUserId({
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    this.props
      .getSubjectListByYearDetail({
        yearDetail: yearDetailId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  }

  rowComment = (data) => {
    this.props
      .getMaterialStudentReview({ ematerialId: data.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showComment: true,
            commentData: data,
          });
        }
      });
  };

  rowViewData = (data) => {
    if (data.docType == 1 || data.docType == 2) {
      window.open(data.downloadUrl, "_blank");
      let docs = {
        ematerial: {
          id: data.id,
        },
      };
      apiPost({
        url: "/api/acdmc/v1/ematerial-user-view",
        postBody: docs,
      }).then(({ success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    } else {
      this.props
        .openFileInNewTab({ file: data.downloadUrl })
        .then((success) => {
          if (success) {
            let docs = {
              ematerial: {
                id: data.id,
              },
            };
            apiPost({
              url: endpoint.newsRoom,
              postBody: docs,
            }).then(({ success }) => {
              if (!success) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  getListById = (data) => {
    if (data.yearDetail) {
      this.props
        .getSubjectListByYearDetail({
          yearDetail: data.yearDetail,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "subject",
      title: "Subject",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "topic",
      title: "Topic",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "date",
      title: "Date",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "uploadedBy",
      title: "Uploded By",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "action",
      title: "Download",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  submitHandler = (data) => {
    let subjectName = this.props.ematerialList.subjectListByYearDetail.filter(
      (row) => row.id == data.subject
    );
    const ay = localStorage.getItem("acadamicYearId");
    localStorage.setItem("semisterDetailId", "");
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("divisionIds", "");
    localStorage.setItem("acadamicYearId", ay);
    FilterSave();
    // if (this.handleFormValidation()) {
    this.setState({
      showLoader: true,
    });
    this.props
      .getStudentMaterialByYearDetail({
        yearDetailId: data.yearDetail,
        subject: subjectName[0].name,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    const { ematerialList } = this.props;
    return ematerialList?.studentMaterial.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["subject"] &&
          currentRow["subject"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["topic"] &&
          currentRow["topic"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["documentType"] &&
          currentRow["documentType"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  backToList = () => {
    this.setState({
      showComment: false,
    });
  };

  render() {
    const {
      academicYear,
      yearDetail,
      dynamicMasterData,
      showComment,
      commentData,
    } = this.state;

    const { academicYearError, yearDetailError } = this.state.formErrors;

    return (
      <>
        <DynamicMainScreen
          screenTitle={eMaterialStudentJson.screenTitle}
          fieldMeta={eMaterialStudentJson.fieldMeta}
          buttonCenter={eMaterialStudentJson.buttonCenter}
          showPdfDownload={eMaterialStudentJson.showPdfDownload}
          showExcelDownload={eMaterialStudentJson.showExcelDownload}
          pdfFileName={eMaterialStudentJson.pdfFileName}
          excelFileName={eMaterialStudentJson.excelFileName}
          tableColumnsToFilter={[]}
          showAddButton={eMaterialStudentJson.showAddButton}
          dynamicMasterData={dynamicMasterData}
          showLoader={this.state.showLoader}
          tableColumns={this.columns}
          tableData={this.props.ematerialList?.studentMaterial}
          getListById={this.getListById}
          showPegination={false}
          showHeadEdit={false}
          showHeadDelete={false}
          searchButton={true}
          showView={true}
          rowViewData={this.rowViewData}
          showComment={true}
          rowComment={this.rowComment}
          onSearchData={this.submitHandler}
          baseIdColumn={eMaterialStudentJson.baseIdColumn}
          apiBaseURL={eMaterialStudentJson.apiBaseURL}
        />
        {this.state.showLoader && <Loading1 />}
        {showComment && (
          <>
            <EmaterialCommentDialogue
              commentData={commentData}
              onClose={this.backToList}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  classList: state.class,
  ematerialList: state.ematerial,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getAcademicYear,
  getYearDetails,
  getStudentMaterial,
  openFileInNewTab,
  getYearDetailByUserId,
  getStudentMaterialByYearDetail,
  FilterSave,
  getSubjectListByYearDetail,
  getMaterialStudentReview,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmaterialTableView);
