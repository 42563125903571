import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { ComplaintReportJson } from "../../DynamicFormsJson/ComplaintsReport";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getAlert } from "../../CommonActions/alert.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { resetReducList } from "../../CommonActions/User.action";

class ComplaintsReport extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        statusMaster: [
          {
            id: "0",
            name: "Pending",
          },
          {
            id: "1",
            name: "In-Progress",
          },
          {
            id: "2",
            name: "Completed",
          },
          {
            id: "3",
            name: "Rejected",
          },
        ],
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            acadamicMaster: nextProps.academicYearList.academicYear,
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
        });
      }
    }
  }
  componentDidMount() {
    const { resetReducList, getYearDetails, getAcademicYear, getAlert } =
      this.props;
    resetReducList();

    getYearDetails().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });

    getAcademicYear().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  rowData = [
    {
      index: 1,
      reportName: "Complaint Report (D0, D1, D2)",
      filter: "fromDate,toDate,status",
      url: "/api/report/v1/complaint-report?fromDate={fromDate}&toDate={toDate}&status={status}",
    },
    {
      index: 2,
      reportName: "Complaint Summary Report (D0, D1)",
      filter: "fromDate,toDate",
      url: "/api/report/v1/complaint-summary-report?fromDate={fromDate}&toDate={toDate}",
    },
  ];
  getListById = (data) => {
    if (data.yearDetail && data.acadamicYear) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: data.acadamicYear,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    if (data.yearDetail) {
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={ComplaintReportJson.screenTitle}
          fieldMeta={ComplaintReportJson.fieldMeta}
          buttonCenter={ComplaintReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={ComplaintReportJson.searchButton}
          PDFButton={ComplaintReportJson.PDFButton}
          EXCELButton={ComplaintReportJson.EXCELButton}
          baseIdColumn={ComplaintReportJson.baseIdColumn}
          apiBaseURL={ComplaintReportJson.apiBaseURL}
          marginTop={ComplaintReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  getSemesterDetailsByYearAndAcademic,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintsReport);
