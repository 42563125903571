import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import * as React from "react";
import { LabelCompo } from "../../../components/Comman/Label";

const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, classes }) => {
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Permanent Address"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Address
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.permanentAddressLine1 === ""
                ? "-"
                : data.permanentAddressLine1 === null
                ? "-"
                : data.permanentAddressLine1}
              {data.permanentAddressLine2 !== null &&
                data.permanentAddressLine2}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Pin Code
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.permanentPin === ""
                ? "-"
                : data.permanentPin === null
                ? "-"
                : data.permanentPin}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
           
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Country
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.country === ""
                ? "-"
                : data.country === null
                ? "-"
                : data.country}
            </td>

         
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; State
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.permanentState === ""
                ? "-"
                : data.permanentState === null
                ? "-"
                : data.permanentState}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
           

            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; City
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.permanentCity === ""
                ? "-"
                : data.permanentCity === null
                ? "-"
                : data.permanentCity}
            </td>

           
             <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; District
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.permanentDistrict === ""
                ? "-"
                : data.permanentDistrict === null
                ? "-"
                : data.permanentDistrict}
            </td>
          </tr>
        </table>
        <Grid>&nbsp;&nbsp;</Grid>
        <LabelCompo
          className="text-black"
          style={{ fontSize: 20 }}
          label="Corresponding Address"
        />
        <Grid>&nbsp;&nbsp;</Grid>

        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Address{" "}
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.temporaryAddressLine1 === ""
                ? "-"
                : data.temporaryAddressLine1 === null
                ? "-"
                : data.temporaryAddressLine1}
              {data.temporaryAddressLine2 !== null &&
                data.temporaryAddressLine2}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Pin Code
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;{" "}
              {data.temporaryPin === ""
                ? "-"
                : data.temporaryPin === null
                ? "-"
                : data.temporaryPin}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            

            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Country
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.temporaryCountry === ""
                ? "-"
                : data.temporaryCountry === null
                ? "-"
                : data.temporaryCountry}
            </td>

          
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; State
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.temporaryState === ""
                ? "-"
                : data.temporaryState === null
                ? "-"
                : data.temporaryState}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
           
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; City
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.temporaryCity === ""
                ? "-"
                : data.temporaryCity === null
                ? "-"
                : data.temporaryCity}
            </td>

       

             <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; District
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{" "}
              {data.temporaryDistrict === ""
                ? "-"
                : data.temporaryDistrict === null
                ? "-"
                : data.temporaryDistrict}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default withStyles(styles)(BasicTable);
