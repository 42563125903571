import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import { StudentTransactionDetailsDynamicJson } from "../../DynamicFormsJson/StudentTransactionDetailsDynamicJson";
import {
  getStudentTransactionList,
  getYears,
} from "../ApplicantAdmission/applicantAdmission.slice";
import { downloadF } from "../PDF/PDFDownload";
import { resetReducList } from "../../CommonActions/User.action";

class TransactionDetails extends Component {
  state = {
    dynamicMasterData: {
      yearDetailMaster: this.props.yearList?.year,
    },
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "transactionNo",
      title: "Transaction Number",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "transactionDate",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "payType",
      title: "Payment Type",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "amount",
      title: "Paid Fees",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
    {
      name: "refund",
      title: "Refund",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  state = {
    id: 0,
    year: "",
    showLoader: false,
  };

  componentDidMount() {
    this.props.resetReducList();
    const parentChildId = localStorage.getItem("parentChildId");
    console.log(parentChildId);
    this.setState({
      showLoader: true,
    });
    this.props
      .getYears({
        flag: false,
        userRegistrationId: parentChildId === null ? "" : parentChildId,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({
            message: "Something went wrong",
          });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.yearList !== nextProps.yearList) {
      if (this.props.yearList?.year !== nextProps.yearList?.year) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.yearList?.year,
          },
        });
      }
    }
  }

  pdfDetailsId = async (rowData) => {
    downloadF({
      url:
        "/api/report/v1/fee-receipt-report/" + rowData.id + "/get-fee-receipt",
    });
  };

  pdfLedgerDownload = () => {
    const { year } = this.state;
    downloadF({
      url:
        "/api/report/v1/leisure-report?admissionRegistrationDetailId=" +
        year +
        "&documentType=1",
    });
  };

  searchData = (data) => {
    const parentChildId = localStorage.getItem("parentChildId");
    const { getStudentTransactionList } = this.props;
    this.setState({
      showLoader: true,
    });
    getStudentTransactionList({
      yearId: data.admissionDetail,
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
    this.setState({
      year: data.admissionDetail,
    });
  };

  // changeHandlerSearch = ( name, newValue ) =>
  // {
  //     if ( newValue !== null )
  //     {
  //         this.setState( { [ name ]: newValue } );
  //         const { getStudentTransactionList, getAlert } = this.props;

  //     } else
  //     {
  //         this.setState( { [ name ]: "" } );
  //     }
  // };

  render() {
    const { year } = this.state;
    const { yearList } = this.props;

    return (
      <>
        <DynamicMainScreen
          // onAddButtonClick={this.onAddButtonClick}
          screenTitle={StudentTransactionDetailsDynamicJson.screenTitle}
          fieldMeta={StudentTransactionDetailsDynamicJson.fieldMeta}
          buttonCenter={StudentTransactionDetailsDynamicJson.buttonCenter}
          showPdfDownload={true}
          downLoadPdfFromApi={true}
          pdfDownload={this.pdfLedgerDownload}
          filter={"admissionDetail"}
          //   generatePDF={this.pdfLedgerDownload}
          showExcelDownload={false}
          tableColumnsToFilter={
            StudentTransactionDetailsDynamicJson.tableColumnsToFilter
          }
          showLoader={this.state.showLoader}
          showAddButton={false}
          dynamicMasterData={this.state.dynamicMasterData}
          tableColumns={this.columns}
          tableData={this.props.yearList?.studentTransactionList}
          showPegination={false}
          showView={false}
          showHeadPdf={true}
          pdfDetailsId={this.pdfDetailsId}
          onSearchData={this.searchData}
          baseIdColumn={StudentTransactionDetailsDynamicJson.baseIdColumn}
          apiBaseURL={StudentTransactionDetailsDynamicJson.apiBaseURL}
        />
        {/* <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>

                    <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: 4 }}>
                        <LabelCompo
                            className="text-black"
                            style={{ fontSize: 22 }}
                            label="Student Transaction Details"
                        />
                    </Grid>


                    <Grid container justifyContent="space-evenly" direction="row">
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            rowSpacing={2}
                            columnSpacing={1}
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={6}
                                lg={6}
                                container
                                justifyContent="space-evenly"
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16 }}
                                        label="Year"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <AutoComplete
                                        keyColName={"id"}
                                        value={year}
                                        name={"year"}
                                        fullWidth
                                        options={yearList?.year}
                                        onChange={this.changeHandlerSearch}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={6}
                                lg={6}
                                container
                                justifyContent="space-evenly"
                            >
                                <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={12}>
                                    <FontAwesomeIconCompo
                                        color="error"
                                        fontSize="large"
                                        title="Ledger Report"
                                        baseClassName="fas"
                                        className="fa-file-pdf"
                                        onClick={this.pdfLedgerDownload}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                        <DynamicTable
                            data={yearList?.studentTransactionList}
                            tableHead={this.columns}
                            showHeadDelete={false}
                            showHeadEdit={false}
                            showPegination={false}
                            rowFeesPaid={this.paymentDetails}
                            rowStatus={this.onChangeStatus}
                            rowPaymentDetails={this.rowFeesPaid}
                            showPayFees={false}
                            // showPaymentDetails={true}
                            showHeadPdf={true}
                            // showHeadEdit={true}
                            pdfDetailsId={this.pdfDetailsId}
                        />
                    </Grid>
                </Paper> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearList: state.applicantAdmission,
});
const mapDispatchToProps = {
  getYears,
  getStudentTransactionList,
  getAlert,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
