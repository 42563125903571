import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MessageIcon from "@mui/icons-material/Message";
import InfoIcon from "@mui/icons-material/Info";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PercentIcon from "@mui/icons-material/Percent";
import {
  Alert,
  Avatar,
  Paper,
  Link,
  Switch,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Grid,
} from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { onToggle } from "../../CommonActions/common.slice";
// import BarChart from "../../components/Charts/BarChart";
import AutoComplete from "../../components/Comman/AutoComplete";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import Tooltip from "@mui/material/Tooltip";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from "recharts";
import user1 from "../../Images/serverFailure.jpg";
import { Loading1 } from "../../components/Loading1";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import certificatePic from "../../Images/certificate-pic.jpg";
import certificate from "../../Images/certificate.png";
import studyImg from "../../Images/studyImage.png";
import trophy from "../../Images/trophy.png";
import user from "../../Images/user.jpeg";
import serverFailure from "../../Images/serverFailure.jpg";
import { getYears } from "../ApplicantAdmission/applicantAdmission.slice";
import {
  getProfile,
  getStudentInfoByPRNNo,
} from "../BasicDetailsPost/basicDetails.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import StudentPreview1 from "../StudentPreviewById/index";
import EventPopUp from "./PopUps/EventPopUp";
import FeedbackPopup from "./PopUps/FeedbackPopup";
import FeesPopup from "./PopUps/FeesPopup";
import {
  getStudentDashboardInfo,
  getMonthAttendanceDataBySemester,
  getSubjectAttendanceDataBySemester,
} from "./Studentdashboard.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import swal from "sweetalert";
import endpoints from "../../config/endpoints";
import CompanyPreviewPopup from "./PopUps/CompanyPreviewPopup";
import InternshipCompanyPreviewPopup from "./PopUps/InternshipCompanyPreviewPopup";
import { showNotification } from "../Landing/Landing.slice";
import * as myConstClass from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";

class MainDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showPreview: false,
    showDashBoard: false,
    role: "",
    semister: "",
    yearDetailId: "",
    showFeedback: false,
    showEvent: false,
    showFee: false,
    dob: "",
    curDOB: "",
    name: "",
    birthday: false,
    showLoader: false,
    showFailure: false,
    barChartFlag: false,
    studentData: [],
    subjectAttendance: [],
    profile: "",
    nameFirstLetter: "",
    openCompanyPreview: false,
    openInternshipCompanyPreview: false,
    interestedInPlacement: 0,
    higherStudies: 1,
    mathsCriteria: 1,
    gapAllowed: 0,
    gapInYears: "",
    previewData: {},
    internshipPreviewData: {},
    formErrors: [],
  };

  componentDidMount() {
    let role = localStorage.getItem("role");
    const person = localStorage.getItem("person");
    this.setState({
      nameFirstLetter: person !== null ? person.toUpperCase() : "",
    });
    let profilePic = localStorage.getItem("profilePic");
    if (profilePic && profilePic !== null) {
      console.log("profilePic" + profilePic);
      apiGet({
        url: endpoints.fileDownload + "/" + profilePic,
      }).then(({ data, success }) => {
        if (success) {
          let fileRes = data.data;
          this.setState({ profile: fileRes });
        }

        return success;
      });
    }

    const curDOB = new Date();
    let fd = curDOB === "" ? "" : moment(curDOB).format("DD-MM-YYYY");

    this.setState({
      showLoader: true,
    });
    const parentChildId = localStorage.getItem("parentChildId");
    if (role === "student" || role === "parent") {
      this.props
        .getStudentDashboardInfo({
          userRegistrationId: parentChildId === null ? "" : parentChildId,
        })
        .then(({ response, success }) => {
          if (!success) {
            // this.props.getAlert({
            //   message: "Something went wrong",
            // });
            this.setState({
              showLoader: false,
              showFailure: true,
            });
          } else {
            console.log(response);
            if (response.bData.studentDetails !== null) {
              if (response.bData.studentDetails.profilePic !== "") {
                apiGet({
                  url:
                    endpoints.fileDownload +
                    "/" +
                    response.bData.studentDetails.profilePic,
                }).then(({ data, success }) => {
                  if (success) {
                    let fileRes = data.data;
                    this.setState({ profile: fileRes });
                  }

                  return success;
                });
              } else {
                this.setState({
                  profile: "",
                });
              }
            } else {
              this.setState({
                profile: "",
              });
            }
            this.setState({
              showDashBoard: true,
              showLoader: false,
              showPreview: false,
              showFailure: false,
            });
            if (
              fd ===
              this.props.studentDashboardData.studentDashboardInfo
                .studentDetails.dateOfBirth
            ) {
              this.setState({
                birthday: true,
              });
            }
            localStorage.setItem(
              "pendingFees",
              this.props.studentDashboardData.studentDashboardInfo.pendingfees
            );
          }
        });
    } else {
      apiGet({
        url: endpoints.studentList + "/by-prnno?prnno=" + localStorage.prnNo,
      }).then(({ data, success }) => {
        if (!success) {
          this.setState({
            showLoader: false,
            showFailure: true,
          });
        } else {
          console.log(data);
          if (data.data.length !== 0) {
            this.props
              .getStudentDashboardInfo({
                userRegistrationId:
                  parentChildId === null ? data.data[0].id : parentChildId,
              })
              .then(({ response, success }) => {
                if (!success) {
                  this.setState({
                    showLoader: false,
                    showFailure: true,
                  });
                } else {
                  console.log(response);
                  if (response.bData.studentDetails.profilePic !== "") {
                    apiGet({
                      url:
                        endpoints.fileDownload +
                        "/" +
                        response.bData.studentDetails.profilePic,
                    }).then(({ data, success }) => {
                      if (success) {
                        let fileRes = data.data;
                        this.setState({ profile: fileRes });
                      }

                      return success;
                    });
                  } else {
                    this.setState({
                      profile: "",
                    });
                  }
                  this.setState({
                    showDashBoard: true,
                    showLoader: false,
                    showPreview: false,
                    showFailure: false,
                  });
                }
              });
            apiGet({
              url: endpoints.fileDownload + "/" + data.data[0].profilePic,
            }).then(({ data, success }) => {
              if (success) {
                let fileRes = data.data;
                this.setState({ profile: fileRes });
              }

              return success;
            });
          } else {
            window.location.replace("/errorPage");
          }
        }
      });
    }

    this.props.getYears({ flag: true }).then((response) => {
      if (!response) {
        this.props.getAlert({
          message: "Something went wrong",
        });
      }
    });
    this.setState({ role: role, curDOB: fd });
    if (role === "student") {
      this.props.getProfile().then((resp) => {
        if (!resp) {
          this.props.getAlert({
            message: "Something went wrong",
          });
        }
      });
    }
  }
  viewProfile = () => {
    let role = localStorage.getItem("role");
    if (role === "student") {
      window.location.replace("/studentPreview1");
    }
  };

  handleFormValidation() {
    const { gapAllowed, gapInYears } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (gapAllowed == 1 && gapInYears == "") {
      formIsValid = false;
      formErrors["gapInYearsError"] = myConstClass.gapInYearsMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  //

  applyInternship = (e, row) => {
    swal({
      title: "Are you sure?",
      text: "Register on the given link, If already register ignore the msg!",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiGet({
          url:
            endpoints.internshipIntrest +
            "/update-intrest?userRegistrationId=" +
            localStorage.userRegistrationId +
            "&internshipDeclareId=" +
            row.internshipId,
        }).then(({ data, success }) => {
          if (!success) {
            this.props.getAlert({
              message: "Something went wrong",
            });
          } else {
            window.location.replace("/newDashboard");
          }
        });
      }
    });
  };

  applyPlacement = (e, row) => {
    swal({
      title: "Are you sure?",
      text: "Register on the given link, If already register ignore the msg!",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        apiGet({
          url:
            endpoints.placementIntrest +
            "/update-intrest?userRegistrationId=" +
            localStorage.userRegistrationId +
            "&placementDeclareId=" +
            row.placementId,
        }).then(({ data, success }) => {
          if (!success) {
            this.props.getAlert({
              message: "Something went wrong",
            });
          } else {
            window.location.replace("/newDashboard");
          }
        });
      }
    });
  };

  openCompanyPreview = (e, row) => {
    this.setState({
      openCompanyPreview: true,
      previewData: row,
    });
  };
  onClickOpenInternshipCompanyPreview = (e, row) => {
    this.setState({
      openInternshipCompanyPreview: true,
      internshipPreviewData: row,
    });
  };

  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };

  certificate = () => {
    if (this.state.role === "student") {
      window.location.replace("/list-of-certificate");
    }
  };

  feesPending = () => {
    if (this.state.role === "student") {
      window.location.replace("/student-payment");
    }
  };

  eventPending = () => {
    if (this.state.role === "student") {
      window.location.replace("/student-apply-event");
    }
  };

  feedBackPending = () => {
    if (this.state.role === "student") {
      window.location.replace("/student-feedback-details");
    }
  };

  complaintPending = () => {
    if (this.state.role === "student") {
      window.location.replace("/complaint");
    }
  };

  suggestionPending = () => {
    if (this.state.role === "student") {
      window.location.replace("/suggestions-form");
    }
  };

  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };
  handleCloses = () => {
    this.setState({
      showFeedback: false,
      showEvent: false,
      showFee: false,
      openCompanyPreview: false,
      openInternshipCompanyPreview: false,
    });
  };
  openEvent = (e) => {
    e.preventDefault();
    this.setState({
      showEvent: true,
    });
  };
  openFees = (e) => {
    e.preventDefault();
    this.setState({
      showFee: true,
    });
  };
  feedbackColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "feedbackName",
      title: "Feedback name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "fromTo",
      title: "From date - To date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "isSubject",
      title: "Is Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  libraryColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "title",
      title: "Book Title",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "mediaType",
      title: "Media Type",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "status",
      title: "Status",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "returnDate",
      title: "Return Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
  ];

  subjectTeacherColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "subject",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "teacherName",
      title: "Teacher Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "emailId",
      title: "E-Mail Id",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "mobNo",
      title: "Mobile Number",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
  ];
  eventColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "eventName",
      title: "Event name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "fromTo",
      title: "From date - To date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "instruction",
      title: "instruction",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];
  feesColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "admissionDetails",
      title: "Admission Details",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "total",
      title: "Total applicable",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "paid",
      title: "Paid",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "refund",
      title: "Refund ",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  subjectData = [
    { name: "Math", students: 50 },
    { name: "Java", students: 40 },
    { name: "C++", students: 80 },
    { name: "PHP", students: 70 },
  ];

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
      if (name === "yearDetailId") {
        const ay = localStorage.getItem("acadamicYearId");
        this.props
          .getSemesterDetailsByYearAndAcademic({
            yearId: newValue,
            academicId: ay,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }

      if (name === "semister") {
        this.props
          .getMonthAttendanceDataBySemester({
            semester: newValue,
            userRegistrationId: "",
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                barChartFlag: true,

                studentData:
                  this.props.studentDashboardData.monthAttendanceDataBySemester,
              });
            }
          });

        this.props
          .getSubjectAttendanceDataBySemester({
            semester: newValue,
            userRegistrationId: "",
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            } else {
              this.setState({
                barChartFlag: true,
                subjectAttendance:
                  this.props.studentDashboardData
                    .subjectAttendanceDataBySemester,
              });
            }
          });
      }
    } else {
      this.setState({ barChartFlag: false, [name]: "" });
    }
  };

  changeHandlers = (event) => {
    const { name, value } = event.target;
    if (name == "interestedInPlacement") {
      this.setState({
        interestedInPlacement: value,
        higherStudies: value == "0" ? "1" : "0",
      });
    } else if (name == "higherStudies") {
      this.setState({
        higherStudies: value,
        interestedInPlacement: value == "0" ? "1" : "0",
      });
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onSaveCardDetail = () => {
    const {
      interestedInPlacement,
      mathsCriteria,
      gapInYears,
      gapAllowed,
      higherStudies,
    } = this.state;
    const ay = localStorage.getItem("acadamicYearId");
    let docs = {
      userRegistrationId:
        this.props.studentDashboardData.studentDashboardInfo.studentDetails.id,
      academicYearId: ay,
      yearDetailId:
        this.props.studentDashboardData.studentDashboardInfo.studentDetails
          .yearDetailId,
      gapInYears: gapAllowed == "0" ? "0" : gapInYears,
      placementHigherStudies: interestedInPlacement,
      mathsCriteria: mathsCriteria,
    };
    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Once Submitted cannot, information cannot be changed!",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.setState({
            showLoader: true,
          });
          apiPost({
            url: endpoints.placementConfig,
            postBody: docs,
          }).then(({ data, success }) => {
            if (success) {
              this.setState({
                showLoader: false,
              });
              this.props.showNotification({ msg: "Data Saved" });
              const parentChildId = localStorage.getItem("parentChildId");
              if (role === "student" || role === "parent") {
                this.props
                  .getStudentDashboardInfo({
                    userRegistrationId:
                      parentChildId === null ? "" : parentChildId,
                  })
                  .then(({ response, success }) => {
                    if (!success) {
                      // this.props.getAlert({
                      //   message: "Something went wrong",
                      // });
                      this.setState({
                        showLoader: false,
                        showFailure: true,
                      });
                    } else {
                    }
                  });
              }
            } else {
              this.setState({
                showLoader: false,
              });
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      });
    }
  };

  render() {
    const { studentDashboardData } = this.props;
    const { gapInYearsError } = this.state.formErrors;
    const {
      semister,
      yearDetailId,
      name,
      barChartFlag,
      openCompanyPreview,
      interestedInPlacement,
      higherStudies,
      mathsCriteria,
      gapAllowed,
      gapInYears,
    } = this.state;
    console.log(this.props.studentDashboardData.studentDashboardInfo);
    return (
      <>
        {this.state.showDashBoard && (
          // {!openCompanyPreview ? (
          //   <>

          //   </>
          // ):(
          //   <>
          //   </>
          // )}
          <div id="layout-wrapper">
            {/* ========== App Menu ========== */}

            {/* Left Sidebar End */}
            {/* Vertical Overlay*/}
            <div className="vertical-overlay" />
            {/* ============================================================== */}
            {/* Start right Content here */}
            {/* ============================================================== */}
            <div className="main-content">
              <div className="page-content">
                <div className="container-fluid">
                  {/* start page title */}
                  {/* <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-sm-0">Student Dashboard </h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div> */}
                  {/* end page title */}
                  {/*profile-row*/}

                  {/* {this.state.role === "student"&& (
                    Object.keys(student.applicant).length !== 0
                      && this.state.curDOB===student.applicant.dateOfBirth
                      && */}
                  {this.state.role === "student" && this.state.birthday && (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">
                              <div className="birthday-bg">
                                <span className="element">
                                  Wish you Happy Birthday{" "}
                                  {
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .firstName
                                  }{" "}
                                  ..!!!
                                </span>
                              </div>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {this.state.role === "student" &&
                      this.props.studentDashboardData.studentDashboardInfo
                        .cardFlag == 1 && (
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header align-items-center d-flex">
                              <div className="a">
                                <h4 className="comp_nm">
                                  <p className="text-primary mb-1">
                                    Interested In Placement ?{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        row
                                        aria-label="interestedInPlacement"
                                        id="interestedInPlacement"
                                        name="interestedInPlacement"
                                        value={interestedInPlacement}
                                        onChange={this.changeHandlers}
                                        size="small"
                                        sx={{ m: -1 }}
                                      >
                                        <FormControlLabel
                                          value="0"
                                          control={<Radio />}
                                          label="Yes"
                                          // onClick={handleClickOpen}
                                        />
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </p>
                                  <br />
                                  <p className="text-primary mb-1">
                                    Interested In Higher Studies ?{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        row
                                        aria-label="higherStudies"
                                        id="higherStudies"
                                        name="higherStudies"
                                        value={higherStudies}
                                        onChange={this.changeHandlers}
                                        size="small"
                                        sx={{ m: -1 }}
                                      >
                                        <FormControlLabel
                                          value="0"
                                          control={<Radio />}
                                          label="Yes"
                                          // onClick={handleClickOpen}
                                        />
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </p>
                                  <br />
                                  {/* <p className="text-primary mb-1"> */}
                                  <div className="col-lg-12">
                                    Education Gap ? &nbsp;&nbsp;&nbsp;&nbsp;
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        row
                                        aria-label="gapAllowed"
                                        id="gapAllowed"
                                        name="gapAllowed"
                                        value={gapAllowed}
                                        onChange={this.changeHandlers}
                                        size="small"
                                        sx={{ m: -1 }}
                                      >
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="Yes"
                                          // onClick={handleClickOpen}
                                        />
                                        <FormControlLabel
                                          value="0"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {/* <br /> */}
                                    <div className="row">
                                      {gapAllowed == 1 && (
                                        <div className="col-lg-12">
                                          <>
                                            {/* <LabelCompo
                                              className="text-black"
                                              style={{ fontSize: 16 }}
                                              label="Gap In Years"
                                            /> */}
                                            <TextField
                                              color="primary"
                                              name="gapInYears"
                                              label="Gap In Years"
                                              size="small"
                                              value={gapInYears}
                                              onChange={this.changeHandler}
                                              fullWidth
                                              type="number"
                                              error={
                                                gapInYearsError ? true : false
                                              }
                                              helperText={
                                                gapInYearsError
                                                  ? gapInYearsError
                                                  : " "
                                              }
                                              sx={{ mt: 1 }}
                                            />
                                          </>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {/* </p> */}
                                  {gapAllowed == 0 ? <br /> : ""}
                                  <p className="text-primary mb-1">
                                    Maths Criteria ? &nbsp;&nbsp;&nbsp;&nbsp;
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        row
                                        aria-label="mathsCriteria"
                                        id="mathsCriteria"
                                        name="mathsCriteria"
                                        value={mathsCriteria}
                                        onChange={this.changeHandlers}
                                        size="small"
                                        sx={{ m: -1 }}
                                      >
                                        <FormControlLabel
                                          value="1"
                                          control={<Radio />}
                                          label="Maths"
                                        />
                                        <FormControlLabel
                                          value="2"
                                          control={<Radio />}
                                          label="Non-Maths"
                                          // onClick={handleClickOpen}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </p>
                                </h4>
                                <button
                                  onClick={this.onSaveCardDetail}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light btn-sm"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="row">
                    {this.state.role === "student" &&
                      this.props.studentDashboardData.studentDashboardInfo.placementCompanyList.map(
                        (data1) => {
                          return (
                            <>
                              <div className="col-lg-6">
                                <div className="card">
                                  <div className="card-header align-items-center d-flex">
                                    <div className="a">
                                      <h4 className="comp_nm">
                                        {data1.companyName}
                                        <p className="text-primary mb-1">
                                          Declare Date :{" "}
                                          <h5 style={{ display: "inline" }}>
                                            {data1.declareDate}
                                          </h5>
                                        </p>

                                        <p className="text-primary mb-1">
                                          Designation :{" "}
                                          <h5 style={{ display: "inline" }}>
                                            {data1.designation}
                                          </h5>
                                        </p>

                                        <p className="text-primary mb-1">
                                          Venue :{" "}
                                          <h5 style={{ display: "inline" }}>
                                            {data1.venue}
                                          </h5>
                                        </p>
                                        <h5>
                                          <Link
                                            underline="hover"
                                            color="inherit"
                                            href={data1.urlLink}
                                          >
                                            {data1.urlLink}{" "}
                                            <Tooltip
                                              placement="top"
                                              title="Please register before applying at the provided link."
                                            >
                                              <InfoIcon color="disabled" />
                                            </Tooltip>
                                          </Link>
                                        </h5>
                                      </h4>
                                      <button
                                        onClick={(e) =>
                                          this.applyPlacement(e, data1)
                                        }
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light btn-sm"
                                      >
                                        Apply Now
                                      </button>
                                      &nbsp;&nbsp;&nbsp;
                                      <button
                                        onClick={(e) =>
                                          this.openCompanyPreview(e, data1)
                                        }
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light btn-sm"
                                      >
                                        More
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="row">
                    {this.state.role === "student" &&
                      this.props.studentDashboardData.studentDashboardInfo.internshipCompanyList.map(
                        (data1) => {
                          return (
                            <>
                              <div className="col-lg-6">
                                <div className="card">
                                  <div className="card-header align-items-center d-flex">
                                    <div className="a">
                                      <h4 className="comp_nm">
                                        {data1.companyName}

                                        <p className="text-primary mb-1">
                                          Declare Date :{" "}
                                          <h5 style={{ display: "inline" }}>
                                            {data1.declareDate}
                                          </h5>
                                        </p>

                                        <p className="text-primary mb-1">
                                          Designation :{" "}
                                          <h5 style={{ display: "inline" }}>
                                            {data1.designation}
                                          </h5>
                                        </p>

                                        <p className="text-primary mb-1">
                                          Venue :{" "}
                                          <h5 style={{ display: "inline" }}>
                                            {data1.venue}
                                          </h5>
                                        </p>

                                        <span>
                                          Link :
                                          <Link
                                            underline="hover"
                                            color="inherit"
                                            href={data1.urlLink}
                                          >
                                            {data1.urlLink}{" "}
                                            <Tooltip
                                              placement="top"
                                              title="Please register before applying at the provided link."
                                            >
                                              <InfoIcon color="disabled" />
                                            </Tooltip>
                                          </Link>
                                        </span>
                                      </h4>
                                      <button
                                        onClick={(e) =>
                                          this.applyInternship(e, data1)
                                        }
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light btn-sm"
                                      >
                                        Apply Now
                                      </button>
                                      &nbsp;&nbsp;&nbsp;
                                      <button
                                        onClick={(e) =>
                                          this.onClickOpenInternshipCompanyPreview(
                                            e,
                                            data1
                                          )
                                        }
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light btn-sm"
                                      >
                                        More
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  {/* Dear {Student Name}, New exam has been scheduled for {Subject Name} on 10-April-2017. */}
                  <div className="row">
                    {this.state.role === "student" &&
                      this.props.studentDashboardData.studentDashboardInfo.studentVivaDetailsList.map(
                        (data1) => {
                          return (
                            <>
                              <div className="col-lg-6">
                                <div className="card">
                                  <div className="card-header align-items-center d-flex">
                                    <div className="a">
                                      <h4 className="comp_nm">
                                        New viva exam has been assigned to you
                                      </h4>

                                      <p className="text-primary mb-1">
                                        Scheduled Date :{" "}
                                        <h5 style={{ display: "inline" }}>
                                          {data1.vivaDate}
                                        </h5>
                                      </p>
                                      <p className="text-primary mb-1">
                                        Scheduled Time :{" "}
                                        <h5 style={{ display: "inline" }}>
                                          {data1.time}
                                        </h5>
                                      </p>
                                      <p className="text-primary mb-1">
                                        Examiner Name :{" "}
                                        <h5 style={{ display: "inline" }}>
                                          {data1.facultyName}
                                        </h5>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="row">
                    {this.state.role === "student" &&
                      this.props.studentDashboardData.studentDashboardInfo.internshipAlertList.map(
                        (data1) => {
                          return (
                            <>
                              <div className="col-lg-6">
                                <div className="card">
                                  <div className="card-header align-items-center d-flex">
                                    <div className="a">
                                      <h4 className="comp_nm">
                                        {data1.companyName}
                                        <span>
                                          From Date : {data1.fromDate}
                                        </span>
                                        <span>
                                          Designation : {data1.designationId}
                                        </span>
                                        <span>
                                          Venue : {data1.companyAddress}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "18px",
                                            color: "rgb(223 225 30 / 0%)",
                                            textAlign: "center",
                                            animation:
                                              "effect 2s ease-in infinite",
                                            letterSpacing: "0.5px",
                                          }}
                                        >
                                          Process will start after{" "}
                                          {data1.noOfDaysLeft} days.
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>

                  {/* )} */}
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card">
                        <div className="bg-primary bg-soft">
                          <div className="row">
                            <div className="col-7">
                              <div className="text-primary p-3 ">
                                <h5 className="text-primary mb-1">
                                  Welcome Back !
                                </h5>
                                <p> </p>
                              </div>
                            </div>
                            <div className="col-5 align-self-end">
                              <img
                                src={studyImg}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="profile-user-wid mb-2">
                                {/*avatar-md*/}
                                {this.state.profile !== "" ? (
                                  <Avatar
                                    sx={{
                                      height: 130,
                                      width: 130,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                    }}
                                    src={this.state.profile}
                                  ></Avatar>
                                ) : (
                                  <Avatar
                                    sx={{
                                      height: 130,
                                      width: 130,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                      bgcolor: "red",
                                      fontSize: 110,
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    {this.state.nameFirstLetter.charAt(0)}
                                  </Avatar>
                                )}
                                {/* <img
                                  src={
                                    this.state.profile === ""
                                      ? name.charAt(0)
                                      : this.state.profile
                                  }
                                  alt=""
                                  style={{
                                    height: 130,
                                    width: 130,
                                    marginLeft: -11,
                                    borderRadius: "50%",
                                    padding: "0.25rem",
                                    backgroundColor: "#ff0000",
                                    border: "1px solid #e9ebec",
                                    color: "#ffffff",
                                  }}
                                /> */}
                              </div>
                              {/* <h5 className="font-size-15 text-truncate">
                              Ekant
                            </h5>
                            <p className="text-muted mb-0 text-truncate">
                              Student
                            </p> */}
                            </div>
                            {/* <div className="col-sm-8">
                              <div className="pt-4">
                                <div className="a mb-4">
                                  <h5 className="font-size-15 text-truncate mb-1">
                                    {Object.keys(student.applicant).length !== 0
                                      ? student.applicant.firstName +
                                        " " +
                                        student.applicant.middleName +
                                        " " +
                                        student.applicant.lastName
                                      : ""}
                                  </h5>
                                  <p className="text-muted mb-0 text-truncate">
                                    Student{" "}
                                  </p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    {Object.keys(student.applicant).length !== 0
                                      ? student.applicant.studentEmail
                                      : ""}
                                  </h5>
                                  <p className="text-muted">Email Address</p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    {Object.keys(student.applicant).length !== 0
                                      ? student.applicant.studentMobile
                                      : ""}
                                  </h5>
                                  <p className="text-muted">Mobile Number</p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    {Object.keys(student.applicant).length !==
                                      0 && student.applicant.prnNo !== null
                                      ? student.applicant.prnNo
                                      : " - "}
                                  </h5>
                                  <p className="text-muted">PRN Number</p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">D</h5>
                                  <p className="text-muted">Division</p>
                                </div>
                                <div className="a mb-4">
                                  <h5 className="font-size-15 mb-1">
                                    BSC - Home science - FY ( Sem )
                                  </h5>
                                  <p className="text-muted">Year</p>
                                </div>
                          
                                <div
                                  className="mt-4"
                                  style={{ textAlign: "right" }}
                                >
                                  <button
                                    onClick={this.viewProfile}
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light btn-sm"
                                  >
                                    View Profile
                                    <i className="mdi mdi-arrow-right ms-1" />
                                  </button>
                                
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div class="a mb-3">
                                <h5 class="font-size-15 text-truncate mb-1">
                                  {this.props.studentDashboardData
                                    .studentDashboardInfo.studentDetails
                                    .lastName +
                                    " " +
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .firstName +
                                    " " +
                                    (this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .fatherName == null ||
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .fatherName == ""
                                      ? " - "
                                      : this.props.studentDashboardData
                                          .studentDashboardInfo.studentDetails
                                          .fatherName) +
                                    " " +
                                    (this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .motherName == null ||
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .motherName == ""
                                      ? " - "
                                      : this.props.studentDashboardData
                                          .studentDashboardInfo.studentDetails
                                          .motherName)}
                                </h5>
                                <p class="text-muted mb-0 text-truncate">
                                  Student{" "}
                                </p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .studentEmail
                                  }
                                </h5>
                                <p class="text-muted">Email Address</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {" "}
                                  {
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .studentMobile
                                  }
                                </h5>
                                <p class="text-muted">Mobile Number</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {" "}
                                  {
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails.prnNo
                                  }
                                </h5>
                                <p class="text-muted">PRN Number</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {" "}
                                  {
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .division
                                  }
                                </h5>
                                <p class="text-muted">Division</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.yearDetails
                                  }{" "}
                                  (
                                  {
                                    this.props.studentDashboardData
                                      .studentDashboardInfo.studentDetails
                                      .semister
                                  }
                                  )
                                </h5>
                              </div>
                              {this.state.role === "student" && (
                                <div
                                  className="mt-4"
                                  style={{ textAlign: "right" }}
                                >
                                  <button
                                    onClick={this.viewProfile}
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light btn-sm"
                                  >
                                    View Profile
                                    <i className="mdi mdi-arrow-right ms-1" />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Overall Attendance
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    {/* $ */}
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .overallAttendancePercentage
                                      }
                                      %
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    You have done{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo
                                        .overallAttendancePercentage
                                    }
                                    % attendance.
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-success rounded fs-3">
                                    {/* <i className="bx fa fa-user-circle-o text-success" /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={AccountCircleOutlinedIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.certificate}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Live/Completed Certificates
                              </p>

                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .certificateCountLive
                                      }
                                      /
                                      {
                                        this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .certificateCountHistory
                                      }
                                    </span>
                                  </h4>

                                  <small className="d-block mb-0 mn-ht">
                                    There are{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo
                                        .certificateCountLive
                                    }{" "}
                                    certificates live &{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo
                                        .certificateCountHistory
                                    }{" "}
                                    completed
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    {/* <i
                                    className="fa fa-trophy text-warning"
                                    aria-hidden="true"
                                  /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={EmojiEventsIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.feesPending}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Total Fees{" "}
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {this.state.role === "student" ? (
                                        <a
                                          href="/student-payment"
                                          className="counter-value"
                                        >
                                          {
                                            this.props.studentDashboardData
                                              .studentDashboardInfo.totalFees
                                          }
                                        </a>
                                      ) : (
                                        <a
                                          href={window.location.host}
                                          className="fw-medium link-primary"
                                          onClick={this.openFees}
                                        >
                                          {
                                            this.props.studentDashboardData
                                              .studentDashboardInfo.totalFees
                                          }
                                        </a>
                                      )}
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total pending fees will be{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo.pendingfees
                                    }
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    {/* <i
                                    className="fa fa-percent text-primary"
                                    aria-hidden="true"
                                    style={{ fontSize: 18 }}
                                  /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={PercentIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.eventPending}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Events Participated
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {this.state.role === "student" ? (
                                        <a
                                          href="/student-apply-event"
                                          className="fw-medium link-primary"
                                        >
                                          {
                                            this.props.studentDashboardData
                                              .studentDashboardInfo.eventCount
                                          }
                                        </a>
                                      ) : (
                                        <a
                                          href=""
                                          className="fw-medium link-primary"
                                        >
                                          {
                                            this.props.studentDashboardData
                                              .studentDashboardInfo.eventCount
                                          }
                                        </a>
                                      )}
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo.eventCount
                                    }{" "}
                                    events pending to apply
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    {/* <i
                                    className="fa fa-calendar text-warning"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={CalendarMonthOutlinedIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.feedBackPending}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Feedbacks
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {this.state.role === "student" ? (
                                        <a
                                          href="/student-feedback-details"
                                          className="fw-medium link-primary"
                                        >
                                          {
                                            this.props.studentDashboardData
                                              .studentDashboardInfo
                                              .pendingFeedbackCount
                                          }
                                        </a>
                                      ) : (
                                        <a
                                          href=""
                                          className="fw-medium link-primary"
                                        >
                                          {
                                            this.props.studentDashboardData
                                              .studentDashboardInfo
                                              .pendingFeedbackCount
                                          }
                                        </a>
                                      )}
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo
                                        .pendingFeedbackCount
                                    }{" "}
                                    feedback remain
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    {/* <i
                                    className="fa fa-comments-o text-primary"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={MessageIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.complaintPending}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Live/Completed Complaints
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .complaintCountLive
                                      }
                                      /
                                      {
                                        this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .complaintCountHistory
                                      }
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    There are{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo.complaintCountLive
                                    }{" "}
                                    complaints live &{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo
                                        .complaintCountHistory
                                    }{" "}
                                    completed
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-success rounded fs-3">
                                    {/* <i
                                    className="fa fa-file-text text-success"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={ContentPasteOffIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div
                              className="card-body"
                              onClick={this.suggestionPending}
                            >
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Suggestions
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.studentDashboardData
                                          .studentDashboardInfo.suggestionsCount
                                      }
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    There are{" "}
                                    {
                                      this.props.studentDashboardData
                                        .studentDashboardInfo.suggestionsCount
                                    }{" "}
                                    suggestions
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-success rounded fs-3">
                                    {/* <i
                                    className="fa fa-file-text text-success"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={ContentPasteOffIcon}
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.props.studentDashboardData.studentDashboardInfo
                    .libraryResponseList !== "" ? (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="text-primary p-3 ">
                            <p className="text-uppercase fw-medium text-muted text-truncate">
                              Library Details
                            </p>
                            <div className="col-lg-12">
                              <div className="d-flex align-items-end justify-content-between ">
                                <div>
                                  <h5 class="font-size-15">
                                    {"Borrower ID : "}{" "}
                                    {this.props.studentDashboardData
                                      .studentDashboardInfo
                                      .libraryResponseList !== ""
                                      ? this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .libraryResponseList.borrid
                                      : "-"}
                                  </h5>
                                </div>
                                <div>
                                  <h5 class="font-size-15">
                                    {"Membership Expiry Date : "}{" "}
                                    {this.props.studentDashboardData
                                      .studentDashboardInfo
                                      .libraryResponseList !== ""
                                      ? this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .libraryResponseList.validUntil
                                      : "-"}
                                  </h5>
                                </div>
                                <div>
                                  <h5 class="font-size-15">
                                    {"Pending Fees : "}{" "}
                                    {this.props.studentDashboardData
                                      .studentDashboardInfo
                                      .libraryResponseList != ""
                                      ? this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .libraryResponseList.pendingFees
                                      : "-"}
                                  </h5>
                                </div>
                              </div>
                            </div>

                            {this.props.studentDashboardData
                              .studentDashboardInfo.libraryResponseList
                              .warningMessage !== "" ? (
                              <div className="col-lg-12">
                                <br />

                                <div className="d-flex align-items-end justify-content-between ">
                                  <div>
                                    <h5 class="font-size-15">
                                      {"Message : "}{" "}
                                      {
                                        this.props.studentDashboardData
                                          .studentDashboardInfo
                                          .libraryResponseList.warningMessage
                                      }
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {this.props.studentDashboardData.studentDashboardInfo
                            .libraryResponseList.lentItems.length !== 0 && (
                            <div className="card-body">
                              <DynamicTable
                                data={
                                  this.props.studentDashboardData
                                    .studentDashboardInfo.libraryResponseList
                                    .lentItems
                                }
                                tableHead={this.libraryColumns}
                                showPegination={false}
                                isActionColActive={false}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Subject And Teacher Table
                          </h4>
                        </div>
                        {/* end card header */}
                        <div className="card-body">
                          <DynamicTable
                            data={
                              this.props.studentDashboardData
                                .studentDashboardInfo.timetableList
                            }
                            tableHead={this.subjectTeacherColumns}
                            showPegination={false}
                            isActionColActive={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.role === "student" ||
                    (this.state.role === "parent" && (
                      <div className="row">
                        {/*latest-update*/}

                        {/*table*/}

                        <div
                          className={
                            this.state.role === "student"
                              ? "col-lg-12"
                              : "col-lg-12"
                          }
                        >
                          <div className="card">
                            <div className="card-header border-bottom-dashed align-items-center d-flex">
                              <h4 className="card-title mb-0 flex-grow-1">
                                Overall Attendance
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-6">
                                  <AutoComplete
                                    keyColName={"id"}
                                    value={yearDetailId}
                                    placeHolder="Year Details"
                                    name={"yearDetailId"}
                                    options={this.props.yearList?.year}
                                    onChange={this.ChangeHandlerSearch}
                                    // isError={yearDetailIdError ? true : false}
                                    // errorText={yearDetailIdError ? yearDetailIdError : " "}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <AutoComplete
                                    keyColName={"id"}
                                    value={semister}
                                    placeHolder="Semister Details"
                                    name={"semister"}
                                    options={
                                      this.props.rollNoData.semisterDetailsList
                                    }
                                    onChange={this.ChangeHandlerSearch}
                                    // isError={yearDetailIdError ? true : false}
                                    // errorText={yearDetailIdError ? yearDetailIdError : " "}
                                  />
                                </div>
                              </div>
                              {/* <img src={exammarks} alt="" /> */}
                              {barChartFlag && (
                                //  {this.state.role === "student" && (
                                <div className="row">
                                  <div className="col-lg-6">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                      Month-wise
                                    </h4>
                                    <br />
                                    <BarChart
                                      width={500}
                                      height={400}
                                      data={this.state.studentData}
                                      label="Student-wise"
                                    >
                                      <Bar
                                        dataKey="students"
                                        fill="green"
                                        // radius={8}
                                        barSize={40}
                                      />
                                      {/* <CartesianGrid stroke="#ccc" /> */}
                                      <XAxis dataKey="name" />
                                      <YAxis dataKey="students" />
                                    </BarChart>
                                  </div>
                                  <div className="col-lg-6">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                      Subject-wise
                                    </h4>
                                    <br />
                                    <BarChart
                                      width={500}
                                      height={400}
                                      data={this.state.subjectAttendance}
                                    >
                                      <Bar
                                        dataKey="students"
                                        fill="green"
                                        // radius={8}
                                        barSize={40}
                                      />
                                      {/* <CartesianGrid stroke="#ccc" /> */}
                                      <XAxis dataKey="name" />
                                      <YAxis dataKey="students" />
                                    </BarChart>
                                  </div>
                                </div>
                                // )}
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/*activity-row*/}
                  {/* <div className="row"> */}
                  {/*recent-activities*/}
                  {/* <div className="col-lg-4">
                      <div className="card card-height-100">
                        <div className="card-header border-bottom-dashed align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Recent Activity
                          </h4>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              className="btn btn-soft-primary btn-sm"
                            >
                              View All Activity
                            </button>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div
                            data-simplebar=""
                            style={{ maxHeight: 364 }}
                            className="p-3"
                          >
                            <div className="acitivity-timeline acitivity-main">
                              <div className="acitivity-item d-flex">
                                <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                  <div className="avatar-title bg-soft-success text-success rounded-circle">
                                    <i
                                      className="fa fa-shopping-cart"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">
                                    Purchase by James Price
                                  </h6>
                                  <p className="text-muted mb-1">
                                    Product noise evolve smartwatch
                                  </p>
                                  <small className="mb-0 text-muted">
                                    02:14 PM Today
                                  </small>
                                </div>
                              </div>
                              <div className="acitivity-item py-3 d-flex">
                                <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                  <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                    <i
                                      className="fa fa-slack"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">
                                    Added new{" "}
                                    <span className="fw-semibold">
                                      style collection
                                    </span>
                                  </h6>
                                  <p className="text-muted mb-1">
                                    By Nesta Technologies
                                  </p>
                                  <div className="d-inline-flex gap-2 border border-dashed p-2 mb-2 w-75">
                                    <a
                                      href="#"
                                      className="bg-light rounded p-1"
                                    >
                                      <img
                                        src="images/img-8.png"
                                        alt=""
                                        className="img-fluid d-block"
                                      />
                                    </a>
                                    <a
                                      href="#"
                                      className="bg-light rounded p-1"
                                    >
                                      <img
                                        src="images/img-8.png"
                                        alt=""
                                        className="img-fluid d-block"
                                      />
                                    </a>
                                    <a
                                      href="#"
                                      className="bg-light rounded p-1"
                                    >
                                      <img
                                        src="images/img-8.png"
                                        alt=""
                                        className="img-fluid d-block"
                                      />
                                    </a>
                                  </div>
                                  <p className="mb-0 text-muted">
                                    <small>9:47 PM Yesterday</small>
                                  </p>
                                </div>
                              </div>
                              <div className="acitivity-item py-3 d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src="images/avatar-2.jpg"
                                    alt=""
                                    className="avatar-xs rounded-circle acitivity-avatar"
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">
                                    Natasha Carey have liked the products
                                  </h6>
                                  <p className="text-muted mb-1">
                                    Allow users to like products in your
                                    WooCommerce store.
                                  </p>
                                  <small className="mb-0 text-muted">
                                    25 Dec, 2021
                                  </small>
                                </div>
                              </div>
                              <div className="acitivity-item py-3 d-flex">
                                <div className="flex-shrink-0">
                                  <div className="avatar-xs acitivity-avatar">
                                    <div className="avatar-title rounded-circle bg-secondary">
                                      <i
                                        className="fa fa-percent"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">
                                    Today offers by{" "}
                                    <a
                                      href="apps-ecommerce-seller-details.html"
                                      className="link-secondary"
                                    >
                                      Digitech Galaxy
                                    </a>
                                  </h6>
                                  <p className="text-muted mb-2">
                                    Offer is valid on orders of Rs.500 Or above
                                    for selected products only.
                                  </p>
                                  <small className="mb-0 text-muted">
                                    12 Dec, 2021
                                  </small>
                                </div>
                              </div>
                              <div className="acitivity-item py-3 d-flex">
                                <div className="flex-shrink-0">
                                  <div className="avatar-xs acitivity-avatar">
                                    <div className="avatar-title rounded-circle bg-soft-danger text-danger">
                                      <i
                                        className="fa fa-bookmark"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">Favoried Product</h6>
                                  <p className="text-muted mb-2">
                                    Esther James have favorited product.
                                  </p>
                                  <small className="mb-0 text-muted">
                                    25 Nov, 2021
                                  </small>
                                </div>
                              </div>
                              <div className="acitivity-item py-3 d-flex">
                                <div className="flex-shrink-0">
                                  <div className="avatar-xs acitivity-avatar">
                                    <div className="avatar-title rounded-circle bg-secondary">
                                      <i
                                        className="fa fa-percent"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">
                                    Flash sale starting{" "}
                                    <span className="text-primary">
                                      Tomorrow.
                                    </span>
                                  </h6>
                                  <p className="text-muted mb-0">
                                    Flash sale by{" "}
                                    <a
                                      href="javascript:void(0);"
                                      className="link-secondary fw-medium"
                                    >
                                      Zoetic Fashion
                                    </a>
                                  </p>
                                  <small className="mb-0 text-muted">
                                    22 Oct, 2021
                                  </small>
                                </div>
                              </div>
                              <div className="acitivity-item py-3 d-flex">
                                <div className="flex-shrink-0">
                                  <div className="avatar-xs acitivity-avatar">
                                    <div className="avatar-title rounded-circle bg-soft-info text-info">
                                      <i
                                        className="fa fa-line-chart"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">Monthly sales report</h6>
                                  <p className="text-muted mb-2">
                                    <span className="text-danger">
                                      2 days left
                                    </span>{" "}
                                    notification to submit the monthly sales
                                    report.{" "}
                                    <a
                                      href="#"
                                      className="link-warning text-decoration-underline"
                                    >
                                      Reports Builder
                                    </a>
                                  </p>
                                  <small className="mb-0 text-muted">
                                    15 Oct
                                  </small>
                                </div>
                              </div>
                              <div className="acitivity-item d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src="images/avatar-3.jpg"
                                    alt=""
                                    className="avatar-xs rounded-circle acitivity-avatar"
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="mb-1">Frank Hook Commented</h6>
                                  <p className="text-muted mb-2 fst-italic">
                                    " A product that has reviews is more likable
                                    to be sold than a product. "
                                  </p>
                                  <small className="mb-0 text-muted">
                                    26 Aug, 2021
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  {/* </div> */}

                  {/* <div className="row"> */}
                  {/* <div className="col-lg-4">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Subjectwise Attendance
                          </h4>
                        </div>
                        <div className="card-body">
                          <PieChart />
                        </div>
                      </div>
                    </div> */}
                  {/* <div className="col-lg-8"> */}
                  {/* <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Exam Marks
                          </h4>
                        </div>
                        <div className="card-body">
                          <LineChart />
                        </div>
                      </div> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Achievements{" "}
                          </h4>
                        </div>
                        <div className="card-body">
                          <section className="regular slider">
                            <div>
                              <div className="crousol_one">
                                <div className="achiement_one">
                                  <img
                                    src={trophy}
                                    alt=""
                                    className="mt-2 mb-3"
                                  />
                                  <h4 className="card-title mb-0 flex-grow-1 mb-2">
                                    Digital Innovation Award
                                  </h4>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="crousol_one">
                                <div className="achiement_one">
                                  <img
                                    src={certificate}
                                    alt=""
                                    className="mt-2 mb-3"
                                  />
                                  <h4 className="card-title mb-0 flex-grow-1 mb-2">
                                    Digital Innovation Award
                                  </h4>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="crousol_one">
                                <div className="achiement_one">
                                  <img
                                    src={certificatePic}
                                    alt=""
                                    className="mt-2 mb-3"
                                  />
                                  <h4 className="card-title mb-0 flex-grow-1 mb-2">
                                    Digital Innovation Award
                                  </h4>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="crousol_one">
                                <div className="achiement_one">
                                  <img
                                    src={certificate}
                                    alt=""
                                    className="mt-2 mb-3"
                                  />
                                  <h4 className="card-title mb-0 flex-grow-1 mb-2">
                                    Digital Innovation Award
                                  </h4>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* container-fluid */}
                </div>
                {/* End Page-content */}
                {/* <footer className="footer">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">2022 © Byte Elephants. </div>
                    <div className="col-sm-6">
                      <div className="text-sm-end d-none d-sm-block">
                        {" "}
                        Design &amp; Develop by Byteelephants.com{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </footer> */}
              </div>
              {/* end main content*/}
            </div>
          </div>
        )}
        {this.state.showPreview && <StudentPreview1 GoBack={this.GoBack} />}

        {this.state.openCompanyPreview && (
          <CompanyPreviewPopup
            previewData={this.state.previewData}
            open={this.state.openCompanyPreview}
            handleCloses={this.handleCloses}
          />
        )}
        {this.state.openInternshipCompanyPreview && (
          <InternshipCompanyPreviewPopup
            previewData={this.state.internshipPreviewData}
            open={this.state.openInternshipCompanyPreview}
            handleCloses={this.handleCloses}
          />
        )}
        {this.state.showFeedback && (
          <FeedbackPopup
            rows={[]}
            columns={this.feedbackColumns}
            open={this.state.showFeedback}
            handleCloses={this.handleCloses}
          />
        )}
        {this.state.showFee && (
          <FeesPopup
            rows={[]}
            columns={this.feesColumns}
            open={this.state.showFee}
            handleCloses={this.handleCloses}
          />
        )}
        {this.state.showEvent && (
          <EventPopUp
            rows={[]}
            columns={this.eventColumns}
            open={this.state.showEvent}
            handleCloses={this.handleCloses}
          />
        )}

        {this.state.showLoader && (
          <div>
            <Paper sx={{ p: 40, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <span className="counter-value" data-target="559.25">
                  Please wait...!!!
                </span>
              </h4>
              <Loading1 size={25} />
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}

        {this.state.showFailure && (
          <div>
            <Paper sx={{ p: 11, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <Avatar
                  sx={{
                    width: 350,
                    height: 350,
                    mt: -10,
                    ml: 30,
                  }}
                  // onClick={handleClick}
                  id="basic-button"
                  // aria-controls={open ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                  src={user1}
                ></Avatar>
                <span
                  style={{
                    fontSize: "75px",
                    textAlign: "center",
                    letterSpacing: "1px",
                    marginLeft: "300px",
                  }}
                >
                  Oops !
                </span>
                <br />
                <span
                  style={{
                    fontSize: "35px",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                    marginLeft: "210px",
                  }}
                >
                  Something went wrong.
                </span>
              </h4>
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  student: state.studentList,
  yearList: state.applicantAdmission,
  rollNoData: state.rollNo,
  studentDashboardData: state.studentDashboard,
});

const mapDispatchToProps = {
  onToggle,
  getProfile,
  getAlert,
  getStudentInfoByPRNNo,
  getYears,
  getSemesterDetailsByYearAndAcademic,
  getStudentDashboardInfo,
  getMonthAttendanceDataBySemester,
  getSubjectAttendanceDataBySemester,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
