import { Checkbox, Link, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import swal from "sweetalert";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            width={headCell.minWidth}
          >
            {headCell.title}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  selection,
  divisionList,
  OnSave,
}) {
  const [values, setValue] = React.useState([]);
  React.useEffect(() => {
    if (values.length === 0) {
      let rowList = [];
      rows.map((rowData) => {
        const filterData = selection.filter(
          (item, index) => item === rowData.id
        );
        if (filterData.length !== 0) {
          rowData = {
            ...rowData,
            rollNo: rowList.length + 1,
            index: rowList.length + 1,
          };
          rowList.push(rowData);
        }
      });
      setValue(rowList);
    }
  });
  const columns = [
    { name: "index", title: "Sr. No.", minWidth: "10%" },
    { name: "studentName", title: "Student Name" },
    { name: "prnNo", title: "PRN Number", minWidth: "20%" },
    { name: "rollNo", title: "Roll Number", minWidth: "15%" },
    {
      name: "reportingDate",
      title: "Reporting Date",
      minWidth: "20%",
      getCellValue: (params, index1) => {
        return <div></div>;
      },
    },
  ];
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [division, setdivision] = useState("");
  const [divisionError, setdivisionError] = useState("");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = values.map((n) => n.id);

      // setSelected(newSelecteds);
      //   setSelection(newSelecteds);
      const newSelectList = values.map((n) => n);
      //   setList(newSelectList);
      return;
    }
    // setSelection([]);
    // setList([]);
    // setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selection.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selection, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelected = newSelected.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1)
      );
    }

    // setSelection(newSelected);
    // const newSelectList = values.filter((n) => n.id === name);
    // setList((listData) => [
    //   // spread existing state
    //   ...listData,
    //   newSelectList[0],
    // ]);
    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selection.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - values.length) : 0;
  const ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      setdivision(newValue);
    } else {
      this.setState({ [name]: "" });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let RollNoList = [];
    if (division === "") {
      setdivisionError("Select division");
    }

    values.map((RollNoData) => {
      const a = selection.filter((item, index) => item === RollNoData.id);
      if (a.length !== 0) {
        let error = false;
        if (RollNoData.rollNo === "") {
          error = true;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === RollNoData.id ? { ...list, rollNoValid: 1 } : list
            )
          );
        }
        if (RollNoData.reportingDate === "") {
          error = true;
          setValue((data) =>
            data?.map((list, index) =>
              list.id === RollNoData.id
                ? { ...list, reportingDateValid: 1 }
                : list
            )
          );
        }
        if (!error) {
          let saveData = {
            getAdmissionRegistrationDetail:
              RollNoData.admissionRegistrationDetailId,
            rollNo: RollNoData.rollNo,
            dateOfReporting: RollNoData.reportingDate,
            isActive: "1",
            division: { id: division },
          };
          RollNoList.push(saveData);
        }
      }
    });
    if (RollNoList.length === selection.length && division !== "") {
      swal("Do you want to generate roll numbers", {
        buttons: ["Cancel", true],
      }).then((willDelete) => {
        if (willDelete) {
          OnSave(RollNoList);
        }
      });
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              headCells={columns}
              numSelected={selection.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={values.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 values.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(values, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const changeHandler = (id, e) => {
                    const { value } = e.target;
                    setValue((data) =>
                      data?.map((valueData, index) =>
                        valueData.id === id
                          ? { ...valueData, rollNo: value }
                          : valueData
                      )
                    );
                  };
                  const onDateChange = (id) => (date) => {
                    if (date !== null) {
                      const valueData = values.map((valueData) => {
                        if (valueData.id === id) {
                          return { ...valueData, reportingDate: date };
                        } else {
                          return valueData;
                        }
                      });
                      setValue(valueData);
                    }
                  };
                  //  if (isItemSelected) {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      changeHandler={changeHandler}
                      onDateChange={onDateChange}
                      handleClick={handleClick}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                    />
                  );
                  // }
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <div>
        <div>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16 }}
            label="*Division"
          />

          <AutoComplete
            keyColName={"id"}
            value={division}
            name={"division"}
            options={divisionList}
            onChange={ChangeHandlerSearch}
            isError={divisionError ? true : false}
            errorText={divisionError ? divisionError : " "}
          />
        </div>
        <div align="right">
          <Link
            color="primary"
            variant="body2"
            component={RouterLink}
            to="/roll-list"
            sx={{ textDecoration: "none" }}
          >
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
            />
          </Link>
          &nbsp;&nbsp;
          <ButtonCompo
            size="button"
            type="button"
            variant="contained"
            name="Submit"
            onClick={submitHandler}
          />
        </div>
      </div>
    </>
  );
}

function Row({
  row,
  changeHandler,
  onDateChange,
  labelId,
  isItemSelected,
  handleClick,
}) {
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onClick={(event) => handleClick(event, row.id)}
          checked={isItemSelected}
          disabled
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>

      <TableCell align={"center"}>{row.index}</TableCell>
      <TableCell>{row.studentName}</TableCell>
      <TableCell>{row.prnNo}</TableCell>
      <TableCell>
        <TextField
          color="primary"
          name={"rollNo" + row.id}
          size="small"
          value={row.rollNo}
          error={row.rollNoValid == 1 ? true : false}
          helperText={row.rollNoValid == 1 ? "Roll number required" : " "}
          onChange={(e) => changeHandler(row.id, e)}
          fullWidth={true}
        />
      </TableCell>
      <TableCell>
        <BasicDatePicker
          color="primary"
          name="reportingDate"
          size="small"
          value={row.reportingDate}
          fullWidth
          onChange={onDateChange(row.id)}
          errorText={row.reportingDateValid === 1 ? "Select date" : " "}
          isError={row.reportingDateValid === 1 ? true : false}
        />
      </TableCell>
    </TableRow>
  );
}
