import endpoint from "../config/endpoints";

export const AddSuggestionsJson = {
    apiBaseURL: endpoint.grievance,
    screenTitle: "Suggestion List",
    showAddButton: true,
    baseIdColumn: "id",
    fieldMeta: [
        // {
        //     label: "Date",
        //     controlType: "datepicker",
        //     placeHolder: "",
        //     md: 6,
        //     lg: 6,
        //     sm: 6,
        //     xs: 12,
        //     dataKey: "date",
        //     isMAxDate: false,
        //     maxDate: new Date(),
        //     // isMinDate: true,
        //     // minDate: new Date(),
        //     isMandatory: true,
        // },
        {
            label: "Suggestion Details",
            controlType: "textarea",
            placeHolder: "",
            md: 12,
            lg: 12,
            sm: 12,
            xs: 12,
            dataKey: "complaintDetail",
            isMandatory: true,
        },
        {
            "label": "Department",
            "controlType": "autocomplete",
            "placeHolder": "",
            "md": 6,
            "lg": 6,
            "sm": 6,
            "xs": 12,
            "dataKey": "department",
            masterName: "department",
            isRootLevelKey: false,
            "isMandatory": true
        },
    ],
};
