import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import {
  ChipCompo,
  MyComponentWithIconProps,
} from "../../../components/Comman/IconButton";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

class MemberTable extends React.Component {
  columnExtensions = [
    { columnName: "action", width: 100 },
    { columnName: "index", width: 100 },
  ];
  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "memberName",
      title: "Member Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "designation",
      title: "Designation",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "memberDesignation",
      title: "Committee Designation",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "emailId",
      title: "Email Id",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },

    {
      name: "contactNo",
      title: "Contact No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "joiningDate",
      title: "Joining Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "leavingDate",
      title: "Leaving Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "sequenceNo",
      title: "Sequence No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "status",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];

  column = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "memberName",
      title: "Member Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "15%",
    },
    {
      name: "designation",
      title: "Designation",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "memberDesignation",
      title: "Committee Designation",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "emailId",
      title: "Email Id",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
    {
      name: "contactNo",
      title: "Contact No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "joiningDate",
      title: "Joining Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "leavingDate",
      title: "Leaving Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "sequenceNo",
      title: "Sequence No",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
    {
      name: "status",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
    },
  ];

  componentDidMount() {
    console.log(this.props.committeeData);
    console.log(this.props.row);
  }

  rowDelete = (params) => {};

  render() {
    return (
      <>
        <div>
          <Table aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Sr. No.</TableCell>
                <TableCell align="center">Member Name</TableCell>
                <TableCell align="center">Designation</TableCell>
                <TableCell align="center">Committee Designation</TableCell>
                <TableCell align="center">Email Id</TableCell>
                <TableCell align="center">Contact No</TableCell>
                <TableCell align="center">Joining Date</TableCell>
                <TableCell align="center">Leaving Date</TableCell>
                <TableCell align="center">Sequence No</TableCell>
                <TableCell align="center">Status</TableCell>
                {this.props.committeeData.flag === 1 && (
                  <TableCell align="center">Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.row.map((data) => {
                {
                  console.log(data);
                }
                return (
                  <TableRow>
                    <TableCell align="center">{data.index}</TableCell>
                    <TableCell>{data.memberName}</TableCell>
                    <TableCell>{data.designation}</TableCell>
                    <TableCell>{data.memberDesignation}</TableCell>
                    <TableCell>{data.emailId}</TableCell>
                    <TableCell align="center">{data.contactNo}</TableCell>
                    <TableCell align="center">{data.joiningDate}</TableCell>
                    <TableCell align="center">{data.leavingDate}</TableCell>
                    <TableCell align="center">{data.sequenceNo}</TableCell>
                    <TableCell>
                      {data.status == 1 ? (
                        <center>
                          <ChipCompo
                            label="Active"
                            variant="outlined"
                            size="small"
                            color="success"
                            onClick={(e) => this.props.changeStatus(data)}
                          />
                        </center>
                      ) : (
                        <center>
                          <ChipCompo
                            label="Inactive"
                            variant="outlined"
                            size="small"
                            color="error"
                            onClick={(e) => this.props.changeStatus(data)}
                          />
                        </center>
                      )}
                    </TableCell>
                    {this.props.committeeData.flag === 1 && (
                      <TableCell align="center">
                        {data.memberDesignation !== "Chairperson" ? (
                          <>
                            <MyComponentWithIconProps
                              statusImage={EditIcon}
                              color="gray"
                              fontSize="small"
                              // sx={{ marginLeft: 35 }}
                              title="Edit"
                              onClick={(e) => this.props.rowEdit(data)}
                            />
                            &nbsp;&nbsp;
                            <MyComponentWithIconProps
                              statusImage={DeleteIcon}
                              color="error"
                              fontSize="small"
                              // sx={{ marginLeft: 35 }}
                              title="Delete"
                              onClick={(e) => this.props.rowDelete(data)}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MemberTable);
