import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import VideoEx from "../AluminiGallery/VideoEx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AdminGallaryPopup({ open, handleClose, urlData }) {
  const [size, setSize] = useState("sm");

  useEffect(() => {}, []);
  const handleFullScreenMode = () => {
    if (size === "sm") {
      setSize("lg");
    } else {
      setSize("sm");
    }
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={size}
      >
        <DialogContent>
          <Grid sx={{ marginTop: 1 }} item xs={12} sm={12} md={12}>
            <video width="570" height="280" controls>
              <source src={urlData} type="video/mp4" />
            </video>
          </Grid>

          {/* <VideoEx url={urlData} handleFullScreenMode={handleFullScreenMode} /> */}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AdminGallaryPopup);
