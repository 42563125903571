import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import LinkIcon from "@mui/icons-material/Link";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";

import PaidIcon from "@mui/icons-material/Paid";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SchoolIcon from "@mui/icons-material/School";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";

function createData( name, calories, fat, carbs, protein )
{
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator( a, b, orderBy )
{
  if ( b[ orderBy ] < a[ orderBy ] )
  {
    return -1;
  }
  if ( b[ orderBy ] > a[ orderBy ] )
  {
    return 1;
  }
  return 0;
}

function getComparator( order, orderBy )
{
  return order === "desc"
    ? ( a, b ) => descendingComparator( a, b, orderBy )
    : ( a, b ) => -descendingComparator( a, b, orderBy );
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort( array, comparator )
{
  const stabilizedThis = array.map( ( el, index ) => [ el, index ] );
  stabilizedThis.sort( ( a, b ) =>
  {
    const order = comparator( a[ 0 ], b[ 0 ] );
    if ( order !== 0 )
    {
      return order;
    }
    return a[ 1 ] - b[ 1 ];
  } );
  return stabilizedThis.map( ( el ) => el[ 0 ] );
}

function EnhancedTableHead( props )
{
  const {
    onSelectAllClick,
    order,
    tableHead,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = ( property ) => ( event ) =>
  {
    onRequestSort( event, property );
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.map( ( headCell ) =>
        {
          if ( headCell.showInscreen )
          {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                
              >
               
                  {headCell.title}
                  {orderBy === headCell.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
              </TableCell>
            );
          }
        } )}
      </TableRow>
    </TableHead>
  );
}
function Row( { key, row, tableHead, rowEdit, rowDelete, rowView } )
{
  const [ open, setOpen ] = React.useState( false );

  const onClickDelete = ( e, id ) =>
  {
    e.stopPropagation();
    rowDelete( id );
  };

  const onClickEdit = ( e, id ) =>
  {
    e.stopPropagation();
    rowEdit( id );
  };

  const onClickApply = ( e, id ) =>
  {
    e.stopPropagation();
    rowView( id );
  };

  React.useEffect( () => { } );
  return (
    <TableRow key={row.name}>
      {Object.keys( row ).map( function ( key, index )
      {
        const filteredList = tableHead.filter(
          ( row ) => row.name === key && row.showInscreen
        );

        if ( filteredList.length !== 0 )
        {
          if (
            filteredList[ 0 ].positionCenter &&
            key !== "isActive" &&
            key !== "isStatus"
          )
            return (
              <TableCell>
                <center>{row[ key ]}</center>
              </TableCell>
            );
          else
            return (
              <TableCell align={filteredList[ 0 ].alignCenter}>
                {row[ key ]}
              </TableCell>
            );
        }
      } )}

      <TableCell align={"center"}>
        {row.remark == "1" && (
          <>
            <MyComponentWithIconProps
              statusImage={EditIcon}
              color="primary"
              fontSize="medium"
              title="Edit"
              onClick={( e ) => onClickEdit( e, row )}
            />
            &nbsp;&nbsp;
            <MyComponentWithIconProps
              statusImage={DeleteIcon}
              color="error"
              fontSize="medium"
              title="Delete"
              onClick={( e ) => onClickDelete( e, row )}
            />
            &nbsp;&nbsp;
          </>
        )}

        <MyComponentWithIconProps
          statusImage={VisibilityIcon}
          color="primary"
          fontSize="medium"
          title="View"
          onClick={( e ) => onClickApply( e, row )}
        />
      </TableCell>
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf( [ "asc", "desc" ] ).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable( {
  tableHead,
  rowDelete,
  rowEdit,
  data,
  rowView,
  showPegination = true,


} )
{
  const [ order, setOrder ] = React.useState( "asc" );
  const [ orderBy, setOrderBy ] = React.useState( "calories" );
  const [ selected, setSelected ] = React.useState( [] );
  const [ page, setPage ] = React.useState( 0 );
  const [ dense, setDense ] = React.useState( false );
  const [ rowsPerPage, setRowsPerPage ] = React.useState( 10 );

  const handleRequestSort = ( event, property ) =>
  {
    const isAsc = orderBy === property && order === "asc";
    setOrder( isAsc ? "desc" : "asc" );
    setOrderBy( property );
  };

  const handleSelectAllClick = ( event ) =>
  {
    if ( event.target.checked )
    {
      const newSelecteds = data.map( ( n ) => n.name );
      setSelected( newSelecteds );
      return;
    }
    setSelected( [] );
  };

  const handleClick = ( event, name ) =>
  {
    const selectedIndex = selected.indexOf( name );
    let newSelected = [];

    if ( selectedIndex === -1 )
    {
      newSelected = newSelected.concat( selected, name );
    } else if ( selectedIndex === 0 )
    {
      newSelected = newSelected.concat( selected.slice( 1 ) );
    } else if ( selectedIndex === selected.length - 1 )
    {
      newSelected = newSelected.concat( selected.slice( 0, -1 ) );
    } else if ( selectedIndex > 0 )
    {
      newSelected = newSelected.concat(
        selected.slice( 0, selectedIndex ),
        selected.slice( selectedIndex + 1 )
      );
    }

    setSelected( newSelected );
  };

  const handleChangePage = ( event, newPage ) =>
  {
    setPage( newPage );
  };

  const handleChangeRowsPerPage = ( event ) =>
  {
    setRowsPerPage( parseInt( event.target.value, 10 ) );
    setPage( 0 );
  };

  const handleChangeDense = ( event ) =>
  {
    setDense( event.target.checked );
  };

  const isSelected = ( name ) => selected.indexOf( name ) !== -1;

  const emptyRows =
    page > 0 ? Math.max( 0, ( 1 + page ) * rowsPerPage - data.length ) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {showPegination
              ? stableSort( data, getComparator( order, orderBy ) )
                .slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage )
                .map( ( row, index ) =>
                {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      tableHead={tableHead}
                      rowEdit={rowEdit}
                      rowDelete={rowDelete}
                      rowView={rowView}
                    />
                  );
                } )
              : stableSort( data, getComparator( order, orderBy ) ).map(
                ( row, index ) =>
                {
                  return (
                    <Row
                      key={row.id}
                      row={row}
                      tableHead={tableHead}
                      rowEdit={rowEdit}
                      rowDelete={rowDelete}
                      rowView={rowView}
                    />
                  );
                }
              )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: ( dense ? 33 : 53 ) * emptyRows,
                }}
              >
                <TableCell colSpan={tableHead.length} />
              </TableRow>
            )}
            {data.length === 0 && (
              <TableRow
                style={{
                  height: ( dense ? 33 : 53 ) * 1,
                }}
              >
                <TableCell align="center" colSpan={tableHead.length}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPegination && (
        <TablePagination
          rowsPerPageOptions={[ 5, 10, 25 ]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
