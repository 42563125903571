import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";
const initialState = {
    seminarWebinar: [],
    isFetch: false,
};
let URL = endpoints.resourcePerson;
const seminarWebinarSlice = createSlice( {
    name: "seminarWebinar",
    initialState,
    reducers: {
        seminarWebinarSuccess: ( state = cloneDeep( initialState ), action ) =>
        {
            const { row } = action.payload;
            return {
                ...state,
                seminarWebinar: row,
                isFetch: true,
            };
        }


    },
} );

export const { seminarWebinarSuccess } = seminarWebinarSlice.actions;

export default seminarWebinarSlice.reducer;


export const getSeminarWebinarActivity = () => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL + '?filters={"formType":"' + "3" + '","_mode" : "EXACT"}'
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const { content } = data.data
                let index = 0;
                let row = [];
                content.map( ( data1 ) =>
                {
                    index = index + 1;
                    let bData = {
                        index: index,
                        id: data1.id,
                        employeeActivity: data1.employeeActivity.id,
                        employeeActivityName: data1.employeeActivity.name,
                        pressDate: data1.pressDate === null ? "" : data1.pressDate,
                        publication: data1.publication,
                        numberOfBeneficiaries: data1.numberOfBeneficiaries === null ? "" : data1.numberOfBeneficiaries,
                        isActive: data1.isActive === null ? "" : data1.isActive,

                    };
                    row.push( bData );
                    return data1;
                } );
                dispatch( seminarWebinarSuccess( { row } ) );
            }
            return success
        } );
        return response
    } catch ( e )
    {

        return console.error( e.message );
    }
};



export const getSeminarWebinarByDate = ( { fromDate, toDate } ) => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL + "/get-employee-publication-list-by-fromDate-toDate?fromDate=" + fromDate + "&toDate=" + toDate + "&formType=3"
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const content = data.data
                let index = 0;
                let row = [];
                content.map( ( data1 ) =>
                {
                    index = index + 1;
                    let bData = {
                        index: index,
                        id: data1.id,
                        employeeActivity: data1.employeeActivity.id,
                        employeeActivityName: data1.employeeActivity.name,
                        pressDate: data1.pressDate === null ? "" : data1.pressDate,
                        publication: data1.publication,
                        numberOfBeneficiaries: data1.numberOfBeneficiaries === null ? "" : data1.numberOfBeneficiaries,
                        isActive: data1.isActive === null ? "" : data1.isActive,

                    };
                    row.push( bData );
                    return data1;
                } );
                dispatch( seminarWebinarSuccess( { row } ) );
            }
            return success
        } );
        return response
    } catch ( e )
    {

        return console.error( e.message );
    }
};

export const getSeminarWebinarWithoutFilter = () => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL + "/get-employee-publication-list-by-limit?limit=20&formType=3"
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const content = data.data
                let index = 0;
                let row = [];
                content.map( ( data1 ) =>
                {
                    index = index + 1;
                    let bData = {
                        index: index,
                        id: data1.id,
                        employeeActivity: data1.employeeActivity.id,
                        employeeActivityName: data1.employeeActivity.name,
                        pressDate: data1.pressDate === null ? "" : data1.pressDate,
                        publication: data1.publication,
                        numberOfBeneficiaries: data1.numberOfBeneficiaries === null ? "" : data1.numberOfBeneficiaries,
                        isActive: data1.isActive === null ? "" : data1.isActive,

                    };
                    row.push( bData );
                    return data1;
                } );
                dispatch( seminarWebinarSuccess( { row } ) );
            }
            return success
        } );
        return response
    } catch ( e )
    {

        return console.error( e.message );
    }
};
