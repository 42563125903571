import endpoint from "../config/endpoints";

export const DepartmentMasterJson = {
  apiBaseURL: endpoint.department,
  screenTitle: "Department",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Department",
  showExcelDownload: true,
  excelFileName: "Department",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Department",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Year Detail",
      columnKeyName: "yearDetailId",
      isChecked: true,
    },

    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Department",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Year Detail",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearDetailList",
      dataKey: "yearDetailId",
      isMandatory: false,
    },
    {
      label: "Department Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "showForSelection",
      defaultValue: 1,
      note: "Note: @1. Teaching departments will be reflected in Academic Module.@2. Non-Teaching department will be reflected in Admin Module.",
      radioGroupItems: [
        {
          label: "Teaching",
          value: 1,
        },
        {
          label: "Non-Teaching",
          value: 0,
        },
      ],
      isMandatory: true,
    },
   
  ],
};
