
import endpoint from "../config/endpoints";

export const floorJson = {
 apiBaseURL: endpoint.floor,
  screenTitle: "Floor",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Floor",
  showExcelDownload: true,
  excelFileName: "Floor",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Floor",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    }
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true
    },
  ],
};
