import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";
const initialState = {
  prnNumber: [],
  isFetch: false,

  prnNumberNull: [],
  isFetchPrnNumberNull: false,

  prnNumberGenerate: [],
  isFetchPrnNumberGenerate: false,
};
let URL = endpoints.prnNumber;
const prnNumberSlice = createSlice({
  name: "prnNumber",
  initialState,
  reducers: {
    prnNumberSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        prnNumber: row,
        isFetch: true,
      };
    },

    prnNumberNullSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        prnNumberNull: row,
        isFetchPrnNumberNull: true,
      };
    },

    prnNumberGenerateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        prnNumberGenerate: row,
        isFetchPrnNumberGenerate: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  prnNumberSuccess,
  prnNumberNullSuccess,
  prnNumberGenerateSuccess,
  resetSuccess,
} = prnNumberSlice.actions;

export default prnNumberSlice.reducer;

export const getPrnNumber =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-user-registration-by-academic-year-with-prnno?academicYearId=" +
          id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];

          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              prnNo: data1.prnNo,
              firstName: data1.firstName,
              lastName: data1.lastName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              name:
                data1.firstName === null
                  ? ""
                  : data1.lastName +
                    " " +
                    data1.firstName +
                    " " +
                    (data1.fatherName == null || data1.fatherName == " "
                      ? " - "
                      : data1.fatherName) +
                    " - " +
                    (data1.motherName == null || data1.motherName == " "
                      ? " - "
                      : data1.motherName),
              yearDetails:
                data1.getYearDetail === null
                  ? ""
                  : data1.getYearDetail.year.className +
                    " - " +
                    data1.getYearDetail.programTitle.brName +
                    " - " +
                    data1.getYearDetail.programTitle.programType.name,
            };
            row.push(bData);
            return data1;
          });
          dispatch(prnNumberSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getPrnNumberNull =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL + "/get-user-registration-by-academic-year?academicYearId=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let motherName = data1.motherName !== null ? data1.motherName : "";
            let name =
              data1.lastName + " " + data1.firstName + " " + data1.middleName;

            let bData = {
              index: index,
              id: data1.id,
              prnNo: data1.prnNo,
              firstName: data1.firstName,
              lastName: data1.lastName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              name: motherName === "" ? name : name + " - " + motherName,
              yearDetails:
                data1.getYearDetail === null
                  ? ""
                  : data1.getYearDetail.year.className +
                    " " +
                    data1.getYearDetail.programTitle.brName +
                    " " +
                    data1.getYearDetail.programTitle.programType.name,
              division: data1.role === null ? "" : data1.role,
            };
            row.push(bData);
            return data1;
          });
          dispatch(prnNumberNullSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPrnNumberGenerate =
  ({ ids }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-prnno?userRegistrationIds=" + ids,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];

          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name:
                data1.firstName === null
                  ? ""
                  : data1.lastName +
                    " " +
                    data1.firstName +
                    " " +
                    data1.middleName +
                    " - " +
                    data1.motherName,
              prnNumber: data1.prnNo,
            };
            row.push(bData);
            return data1;
          });
          dispatch(prnNumberGenerateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
