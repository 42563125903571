import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../CommonActions/alert.slice";
import { ButtonCompo } from "../../../../components/Comman/Button";
import BasicDatePicker from "../../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../../components/Comman/Label";
import TimePicker from "../../../../components/Comman/TimePicker";
import endpoint from "../../../../config/endpoints";
import { showNotification } from "../../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../../utils/api_service";

class ExamSchedule extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    checkedValues: [],
    newList: [],
    newList1: [],
    dateChange: "",
    fromTime: "",
    toTime: "",
    startDate: "",
    isChecked: false,
    isSelectionError: false,
    isErrorDate: false,
    courseBranch: "COMPUTER SCIENCE",
  };

  changeHandler = (event, subName) => {
    const { name } = event.target;
    this.setState((previousState) => {
      const checkedValues = { ...previousState.checkedValues };
      checkedValues[name] = !checkedValues[name];
      return { checkedValues };
    });
  };

  componentDidMount() {
    const { scheduleSaveData, newList } = this.props;
    console.log(this.props.examList.examDeclareGetSubject);
    console.log(this.props.examList.examDeclareSubject);

    let dataStud2 = this.props.examList.examDeclareGetSubject.map((data2) => {
      const subjectList = this.props.examList?.examDeclareSubject.filter(
        (row) => row.id === data2.id
      );

      if (subjectList.length !== 0) {
        return subjectList[0];
      } else {
        data2 = {
          ...data2,
          scheduleDate: "",
          startTime: "",
          endTime: "",
          isChecked: false,
          scheduleDateErr: 0,
          startTimeErr: 0,
          endTimeErr: 0,
        };
        return data2;
      }
    });
    this.setState({
      newList1: dataStud2,
    });

    if (this.props.examList?.examDeclareSubject.length !== 0) {
      if (
        this.props.examList?.examDeclareSubject.length ===
        this.props.examList?.examDeclareSubject.filter(
          (row) => row.isChecked === true
        ).length
      ) {
        this.setState({
          isChecked: true,
        });
      }
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state.newList1);

    let doc = [];
    // if (
    //   this.state.newList1.filter((row) => row.isChecked === true).length !== 0
    // ) {
    //   this.setState({
    //     isSelectionError: false,
    //   });
    let error = false;
    let document = this.state.newList1.map((rowData) => {
      // if (rowData.isChecked === true) {
      // if (
      //   rowData.scheduleDate !== null &&
      //   rowData.startTime !== null &&
      //   rowData.endTime !== null &&
      //   rowData.scheduleDate !== "" &&
      //   rowData.startTime !== "" &&
      //   rowData.endTime !== ""
      // ) {
      let document1 = {
        examFormDeclare: {
          id: this.props.scheduleSaveData.id,
        },
        subjectDetail: {
          id: rowData.id,
        },
        scheduleDate: moment(rowData.scheduleDate).format("DD-MM-YYYY"),
        startTime: moment(rowData.startTime, ["h:mm A"]).format("HH:mm"),
        endTime: moment(rowData.endTime, ["h:mm A"]).format("HH:mm"),
        isActive: 1,
      };

      if (rowData.examFormScheduleId !== "") {
        document1 = {
          ...document1,
          id: rowData.examFormScheduleId,
        };
        doc.push(document1);

        return document1;
      }
      console.log(document1);
      // } else {
      //   error = true;
      //   scheduleDateErr: 0,
      //   startTimeErr: 0,
      //   endTimeErr:0,
      //   let document = this.state.newList1.map((rowData) => {
      //     if (rowData.id === rowData.id) {
      //       if (
      //         rowData.scheduleDate === null ||
      //         rowData.scheduleDate === ""
      //       ) {
      //         rowData = {
      //           ...rowData,
      //           scheduleDateErr: 1,
      //         };
      //       } else {
      //         rowData = {
      //           ...rowData,
      //           scheduleDateErr: 0,
      //         };
      //       }
      //       if (rowData.endTime === null || rowData.endTime === "") {
      //         rowData = {
      //           ...rowData,
      //           endTimeErr: 1,
      //         };
      //       } else {
      //         rowData = {
      //           ...rowData,
      //           endTimeErr: 0,
      //         };
      //       }
      //       if (rowData.startTime === null || rowData.startTime === "") {
      //         rowData = {
      //           ...rowData,
      //           startTimeErr: 1,
      //         };
      //       } else {
      //         rowData = {
      //           ...rowData,
      //           startTimeErr: 0,
      //         };
      //       }
      //       return rowData;
      //     }
      //     return rowData;
      //   });
      //   console.log(document);
      //   this.setState({
      //     newList1: document,
      //   });
      // }
      // }
    });
    if (!error) {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit data?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          apiPost({
            url: endpoint.examSubject,
            postBody: doc,
          }).then(({ data, success }) => {
            if (success) {
              this.props.showNotification({
                msg: "Data saved successfully",
              });
              this.props.onClose();
            } else {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        }
      });
    } else {
      console.log("error");
    }
    // } else {
    //   this.setState({
    //     isSelectionError: true,
    //   });
    // }
  };

  timeChangeHandler1 = (row) => (time) => {
    let changeStartTime = moment(time).format("hh:mm");

    console.log(time);
    console.log(row);
    console.log(this.props.examList?.examDeclareSubject);

    let document = this.state.newList1.map((rowData) => {
      if (rowData.id === row.id) {
        rowData = {
          ...rowData,
          startTime: time,
        };
        return rowData;
      }
      return rowData;
    });
    console.log(document);
    this.setState({
      newList1: document,
    });
  };

  timeChangeHandler2 = (row) => (time) => {
    let changetoTime = moment(time).format("hh:mm");

    console.log(time);
    console.log(row);
    console.log(this.props.examList?.examDeclareSubject);

    let document = this.state.newList1.map((rowData) => {
      if (rowData.id === row.id) {
        rowData = {
          ...rowData,
          endTime: time,
        };
        return rowData;
      }
      return rowData;
    });
    console.log(document);
    this.setState({
      newList1: document,
    });
  };

  changeCasteDate = (row) => (date) => {
    const { newList1 } = this.state;

    let changeStartDate = moment(date).format("DD-MM-YYYY");
    console.log(date);
    console.log(row);
    console.log(this.props.examList?.examDeclareSubject);

    let document = this.state.newList1.map((rowData) => {
      if (rowData.id === row.id) {
        rowData = {
          ...rowData,
          scheduleDate: date,
        };
        return rowData;
      }
      return rowData;
    });
    console.log(document);
    this.setState({
      newList1: document,
    });
  };

  changeCheckedValue = (e, row) => {
    console.log(e.target.checked);
    console.log(row);
    let document = this.state.newList1.map((rowData) => {
      if (rowData.id === row.id) {
        rowData = {
          ...rowData,
          isChecked: e.target.checked,
        };
        return rowData;
      }
      return rowData;
    });
    console.log(document);
    this.setState({
      newList1: document,
    });
    console.log(this.props.examList?.examDeclareSubject.length);
    console.log(document.filter((row) => row.isChecked === true).length);

    if (
      this.props.examList?.examDeclareSubject.length !==
      document.filter((row) => row.isChecked === true).length
    ) {
      this.setState({
        isChecked: false,
      });
    } else {
      this.setState({
        isChecked: e.target.checked,
      });
    }
  };

  allCheckedChangeHandler = (e) => {
    console.log(e.target.checked);

    this.setState({
      isChecked: e.target.checked,
    });
    // console.log(studentAttendanceList?.studentAttendanceList);
    const val = this.state.newList1.map((data) => {
      let bData = {
        ...data,
        isChecked: e.target.checked,
      };
      return bData;
    });
    console.log(val);
    this.setState({
      newList1: val,
    });
  };

  render() {
    const {
      dateChange,
      fromTime,
      toTime,
      startDate,
      newList,
      newList1,
      isChecked,
      isErrorDate,
      isSelectionError,
    } = this.state;
    const { onClose, scheduleSaveData, examList } = this.props;

    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid item xs={6} sm={6} md={12}>
              <form
                onSubmit={this.submitHandler}
                autoComplete="off"
                noValidate={true}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={2}
                  columnSpacing={2}
                  rowSpacing={2}
                >
                  <Grid item xs={12} sm={10} md={10}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 25 }}
                      label="Exam Schedule"
                    />
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    item
                    xs={12}
                    sm={2}
                    md={2}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back"
                      onClick={onClose}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}></Grid>
                  <br />
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={"Examination : " + scheduleSaveData.examTitle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={"Semester : " + scheduleSaveData.semesterName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={"Start Date : " + scheduleSaveData.startDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 18 }}
                      label={"End Date : " + scheduleSaveData.endDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Paper
                      mt={1}
                      sx={{ p: 1, borderRadius: 0 }}
                      elevation={1}
                      variant="outlined"
                      square
                    >
                      <Grid item xs={12} sm={12} md={12} />
                      {isSelectionError ? (
                        <FormHelperText error>
                          {"Select atleast 1 subject"}
                        </FormHelperText>
                      ) : (
                        " "
                      )}
                      <Grid item xs={12} sm={12} md={12}>
                        <List component="nav" aria-label="mailbox folders" />
                        <Grid item xs={12} sm={12} md={12}>
                          {/* <ExamScheduleHeadTable
                                data={value.dummyData}
                                rowTData={rowDataHandler}
                              /> */}
                          <>
                            <div align="center">
                              <TableContainer>
                                <Table
                                  sx={{ minWidth: 600 }}
                                  aria-label="simple table"
                                  size="small"
                                >
                                  <TableHead>
                                    <TableRow>
                                      {/* <TableCell align="center">
                                        <FormGroup>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                name={"Course"}
                                                onChange={(e) =>
                                                  this.allCheckedChangeHandler(
                                                    e
                                                  )
                                                }
                                                checked={isChecked}
                                                sx={{
                                                  color: pink[800],
                                                  "&.Mui-checked": {
                                                    color: pink[600],
                                                  },
                                                  padding: "0px",
                                                }}
                                              />
                                            }
                                            label={""}
                                            labelPlacement="top"
                                          />
                                        </FormGroup>
                                      </TableCell> */}
                                      <TableCell align="center">
                                        Sr. No.
                                      </TableCell>
                                      <TableCell align="center">
                                        Subject Name
                                      </TableCell>
                                      <TableCell align="center">
                                        Scheme Name
                                      </TableCell>
                                      <TableCell align="center">Date</TableCell>
                                      <TableCell align="center">
                                        From Time
                                      </TableCell>
                                      <TableCell align="center">
                                        To Time
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {console.log(newList1)}
                                    {newList1.map((rowData) => {
                                      return (
                                        <TableRow>
                                          {/* <TableCell align="center">
                                            <FormGroup>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    name={"Course"}
                                                    checked={rowData.isChecked}
                                                  />
                                                }
                                                onChange={(e) =>
                                                  this.changeCheckedValue(
                                                    e,
                                                    rowData
                                                  )
                                                }
                                                value={rowData.isChecked}
                                                labelPlacement="bottom"
                                                // changeCheckedValue={data.excuse}
                                              />
                                            </FormGroup>
                                          </TableCell> */}
                                          <TableCell
                                            align="center"
                                            style={{ width: "10%" }}
                                          >
                                            {rowData.index}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{ width: "20%" }}
                                          >
                                            {rowData.subject}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{ width: "20%" }}
                                          >
                                            {rowData.subjectDetails}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{ width: "20%" }}
                                            padding="6px 24px 6px 16px"
                                            height="30"
                                          >
                                            <BasicDatePicker
                                              name="scheduleDate"
                                              value={rowData.scheduleDate}
                                              onChange={this.changeCasteDate(
                                                rowData
                                              )}
                                              isError={
                                                rowData.scheduleDateErr !== 0
                                                  ? true
                                                  : false
                                              }
                                              errorText={
                                                rowData.scheduleDateErr !== 0
                                                  ? "Select date"
                                                  : " "
                                              }
                                            />
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{ width: "15%" }}
                                          >
                                            <TimePicker
                                              value={rowData.startTime}
                                              onChange={this.timeChangeHandler1(
                                                rowData
                                              )}
                                              error={
                                                rowData.startTimeErr !== 0
                                                  ? "Select start time"
                                                  : " "
                                              }
                                            />
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{ width: "15%" }}
                                          >
                                            <TimePicker
                                              value={rowData.endTime}
                                              onChange={this.timeChangeHandler2(
                                                rowData
                                              )}
                                              error={
                                                rowData.endTimeErr !== 0
                                                  ? "Select end time"
                                                  : " "
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <br />
                            </div>
                          </>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid m={2} container justifyContent="right" spacing={1}>
                    <Grid
                      container
                      justifyContent="right"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Submit"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentList: state.admissionForm,
  examList: state.examDeclare,
});
const mapDispatchToProps = {
  getAlert,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ExamSchedule);
//examList?.examDeclareSubject
