
import endpoint from "../config/endpoints";

export const SubjectTypeDetailsJson = {
  apiBaseURL: endpoint.subjectTypeDetails,
  screenTitle: "Subject Type Detail",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Subject Type Detail",
  showExcelDownload: true,
  excelFileName: "Subject Type Detail",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Short Name",
      columnKeyName: "shortName",
      isChecked: true,
    },
    {
      columnDisplayName: "Show Name",
      columnKeyName: "showName",
      isChecked: true,
    },
    {
      columnDisplayName: "Max Subject",
      columnKeyName: "maxSub",
      isChecked: true,
    },
    {
      columnDisplayName: "Min Subject",
      columnKeyName: "minSub",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "name",
      "isMandatory": true
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      "isMandatory": true
    },
    {
      label: "Show Name",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "showName",
      "isMandatory": true
    },
    {
      label: "Minimum Subject",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "minSub",
      "isMandatory": true,
    
    },
    {
      label: "Maximum Subject",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "maxSub",
      "isMandatory": true,
     
    },
  ],
};
