
import endpoint from "../config/endpoints";

export const AttendedSeminarMasterJson = {
  apiBaseURL: endpoint.resourcePerson,
  "screenTitle": "Attended Seminar",
  "showAddButton": true,
  baseIdColumn: "id",
  "fieldMeta": [
    
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "type",
      dataKey: "type",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "pressDate",
      isMAxDate: false,
      maxDate: new Date(),
      isMinDate: true,
      minDate: new Date(),
      "isMandatory": true
    },
    {
        label: "Details",
        controlType: "textfield",
        placeHolder: "",
        md: 12,
        lg: 12,
        sm: 12,
        xs: 12,
        dataKey: "publication",
        isMandatory: true
      },
 
  ]
}
