import { Checkbox, FormGroup, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { withStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

let columns;
let validation;
const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
  },
})(DataGrid);
const DynamicTable = ({ studentAttendanceList, rowData, data }) => {
  const [values, setValues] = useState(
    studentAttendanceList?.studentAttendanceList
  );
  const [checkedValue, setCheckedValue] = useState(false);

  rowData(values);
  useEffect(() => {
    if (values.length === 0) {
      setValues(data);
    }
  });
  columns = [
    { field: "index", headerName: "Sr. No.", minWidth: 100, sortable: false },
    { field: "name", headerName: "Student Name", minWidth: 200 },
    { field: "rollNo", headerName: "Roll No", minWidth: 100 },
    { field: "prnNo", headerName: "PRN Number", minWidth: 200 },
    {
      field: "attendance",
      headerName: "Present",
      sortable: false,
      minWidth: 100,
      align: "center",

      renderCell: (params) => {
        const changeHandler = (e, id) => {
          console.log(e.target.checked);
          setCheckedValue(e.target.checked);

          if (e.target.checked === true) {
            setValues((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, attendance: e.target.checked }
                  : list
              )
            );
          } else {
            setValues((data) =>
              data?.map((list, index) =>
                list.id === id
                  ? { ...list, attendance: e.target.checked }
                  : list
              )
            );
          }
        };

        return (
          <div>
            <>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name="attendance" id="attendance" />}
                  value={params.row.attendance}
                  onChange={(e) => changeHandler(e, params.row.id)}
                />
              </FormGroup>
            </>
          </div>
        );
      },
    },
    {
      field: "excuse",
      headerName: "Excuse",
      sortable: false,
      minWidth: 100,
      align: "center",
      renderCell: (params) => {
        const changeHandler = (e, id) => {
          console.log(e.target.checked);
          setValues((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, excuse: e.target.checked } : list
            )
          );
        };
        if (params.row.attendance === false) {
          return (
            <div>
              <>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="excuse" id="excuse" />}
                    value={params.row.excuse}
                    onChange={(e) => changeHandler(e, params.row.id)}
                  />
                </FormGroup>
              </>
            </div>
          );
        } else {
          return (
            <div>
              <>
                <FormGroup>
                  <FormControlLabel
                    disabled
                    control={<Checkbox name="excuse" id="excuse" />}
                    value={checkedValue}
                    onChange={(e) => changeHandler(e, params.row.id)}
                  />
                </FormGroup>
              </>
            </div>
          );
        }
      },
    },
    {
      field: "answer",
      headerName: "Remark",
      sortable: false,
      minWidth: 280,
      renderCell: (params) => {
        const changedHandler = (id, e) => {
          console.log(id);
          const { value } = e.target;
          console.log(value);
          setValues((data) =>
            data?.map((list, index) =>
              list.id === id ? { ...list, answer: value } : list
            )
          );
        };
        return (
          <div>
            <>
              <TextField
                color="primary"
                name={"Percentage"}
                size="small"
                value={params.row.answer}
                onChange={(e) => changedHandler(params.row.id, e)}
                sx={{ width: 250 }}
              />
            </>
          </div>
        );
      },
    },
  ];

  return (
    <div align="center">
      <StyledDataGrid
        autoHeight={true}
        rows={values}
        columns={columns}
        disableColumnMenu
        hideFooter
        options={{
          paging: false,
        }}
      />
      <br />
    </div>
  );
};

const mapStateToProps = (state) => ({
  studentAttendanceList: state.studentAttendance,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicTable);
