import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  financial: [],
  isFinancialFetch: false,
};
let URL = endpoints.financial;
const financialYearSlice = createSlice({
  name: "financial",
  initialState,
  reducers: {
    financialYearSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        financial: row,
        isFinancialFetch: true,
      };
    },
  },
});

export const { financialYearSuccess } = financialYearSlice.actions;

export default financialYearSlice.reducer;

export const getFinancial = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+'?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            fromDate: data1.fromDate,
            isLocked: data1.isLocked,
            toDate: data1.toDate,
            isActive: data1.isActive,
           
  
          };
          row.push(bData);
          return data1;
        });
        dispatch(financialYearSuccess({ row }));
      }
      return success
    });
    return response
  } catch (e) {
    return console.error(e.message);
  }
};
