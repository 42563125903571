import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../../../components/dynamicscreens/DynamicMainScreenWithImageUpload";
import { ExtendedActivitiesJson } from "../../../../../DynamicFormsJson/ExtendedActivities";
import { extendedActivitiesColumns } from "../../../../../tableColumns/table-columns";
import { getActivityByExtendedActivity } from "../Activity/activity.slice";
import {
  getExtendedActivities,
  getExtendedActivityByDate,
  getExtendedActivityWithoutFilter,
} from "./extended.slice";
import { showNotification } from "../../../../../Pages/Landing/Landing.slice";
import { Grid } from "@mui/material";
import { LabelCompo } from "../../../../../components/Comman/Label";
import BasicDatePicker from "../../../../../components/Comman/DatePickerEx";
import { ButtonCompo } from "../../../../../components/Comman/Button";

class ExtendedActivitieMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        LevelMaster: [
          { id: "International", name: "International" },
          { id: "National", name: "National" },
          { id: "State", name: "State" },
          { id: "University", name: "University" },
          { id: "Inhouse", name: "Inhouse" },
          { id: "Intercollegiate", name: "Intercollegiate" },
          { id: "other", name: "Other" },
        ],
        typeMaster: [
          { id: "0", name: "Personal" },
          { id: "1", name: "Faculty" },
        ],
        teacherMaster: [{ id: "", name: "" }],
        type: [
          { id: "Attended", name: "Attended" },
          { id: "Organized", name: "Organized" },
          { id: "Resource Person", name: "Resource Person" },
          // new Added
          { id: "presented", name: "Presented" },
          { id: "supervision", name: "Supervision" },
          { id: "invited", name: "Invited" },
          { id: "jury", name: "Jury" },
          { id: "judge", name: "Judge" },
          { id: "other", name: "Other" },
        ],
        activity: this.props.activityList?.extendedActivity,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.extendedActivity !==
        nextProps.activityList.extendedActivity
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: nextProps.activityList.extendedActivity.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }

  componentDidMount() {
    this.props.getExtendedActivityWithoutFilter().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getActivityByExtendedActivity().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  submitHandler = (fromDt, toDt) => {
    this.setState({ showLoader: true });
    this.props
      .getExtendedActivityByDate({ fromDate: fromDt, toDate: toDt })
      .then((response) => {
        if (!response) {
          this.setState({ showLoader: false });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({ showLoader: false });
        }
      });
  };

  render() {
    const { dynamicMasterData } = this.state;
    const { extendedActivityList, getActivity } = this.props;

    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={ExtendedActivitiesJson.screenTitle}
            fieldMeta={ExtendedActivitiesJson.fieldMeta}
            showPdfDownload={ExtendedActivitiesJson.showPdfDownload}
            showExcelDownload={ExtendedActivitiesJson.showExcelDownload}
            pdfFileName={ExtendedActivitiesJson.pdfFileName}
            excelFileName={ExtendedActivitiesJson.excelFileName}
            showAddButton={ExtendedActivitiesJson.showAddButton}
            tableColumnsToFilter={ExtendedActivitiesJson.tableColumnsToFilter}
            dynamicMasterData={dynamicMasterData}
            showSaveNextBtn={false}
            tableColumns={extendedActivitiesColumns}
            tableData={extendedActivityList?.extendedActivity}
            getTableData={this.props.getExtendedActivityWithoutFilter}
            showLoader={extendedActivityList.isFetch}
            apiBaseURL={ExtendedActivitiesJson.apiBaseURL}
            baseIdColumn={ExtendedActivitiesJson.baseIdColumn}
            type={1}
            deleteUrl={ExtendedActivitiesJson.deleteUrl}
            DocumentUpload={ExtendedActivitiesJson.DocumentUpload}
            sendEmptyId={true}
            isFilter={true}
            getSearchData={this.submitHandler}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  activityList: state.activity,
  extendedActivityList: state.extendedActivity,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getExtendedActivityByDate,
  getActivityByExtendedActivity,
  getExtendedActivities,
  getExtendedActivityWithoutFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtendedActivitieMaster);
