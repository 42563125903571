import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { onlyNumber } from "../../../components/Comman/Util/Validations";
import { getAlert } from "../../../CommonActions/alert.slice";
import AttachFile from "../../../components/Comman/AttachFile";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../../components/Comman/Label";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiDelete, apiPost } from "../../../utils/api_service";
// import { getInternshipType } from "../../Masters/Internship/InternshipType/IntershipType.slice";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getClass } from "../../Masters/OnlineModule/Class/class.slice";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
  saveFile,
  saveMultipleFile,
} from "../../UploadFile/file.slice";
import {
  getStudentList,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { showNotification } from "../../Landing/Landing.slice";

class StudentDeclareInternshipListTable extends React.Component {
  details = (params) => {
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      title: "Document Name",
      name: "name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "60%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  state = {
    id: 0,
    academicYear: "",
    yearDetail: "",
    typeOfAdmission: "",
    applicationId: "",
    formErrors: [],
    interestedStudent: false,
    accountFees: false,
    listOfComp: true,
    fromDate: new Date(),
    toDate: new Date(),
    placementType: "1",
    companyName: "",
    companyAddress: "",
    designation: "",
    placementPackage: "",
    remark: "",
    number: "0",
    fileName: "",
    documentListLocal: [
      { id: "Offer Letter", name: "Offer Letter" },
      {
        id: "Appointment Letter",
        name: "Appointment Letter",
      },
      {
        id: "Experience Letter",
        name: "Experience Letter",
      },
      {
        id: "1",
        name: "Other",
      },
    ],
    documentName: "",
    fileNameData: [],
    upload: false,
    uploadError: false,
    companyMail: "",
    companyNo: "",
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentData?.getInterestedStudByIdStatus !==
        nextProps.studentData?.getInterestedStudByIdStatus &&
      this.props.fileData.fileData.length === 0
    ) {
      console.log(nextProps.studentData?.getInterestedStudByIdStatus);
      const durationForsArr =
        nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate !==
          "" &&
        nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate !==
          null
          ? nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate.split(
              "-"
            )
          : "";
      const frDt =
        durationForsArr !== ""
          ? new Date(
              durationForsArr[2],
              durationForsArr[1] - 1,
              durationForsArr[0]
            )
          : "";
      this.setState({
        placementType:
          nextProps.studentData?.getInterestedStudByIdStatus.placementType,
        id: nextProps.studentData?.getInterestedStudByIdStatus.id,
        companyAddress:
          nextProps.studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName:
          nextProps.studentData?.getInterestedStudByIdStatus.companyName,
        companyMail:
          nextProps.studentData?.getInterestedStudByIdStatus.companyEmail,
        companyNo: nextProps.studentData?.getInterestedStudByIdStatus.contactNo,
        placementPackage:
          nextProps.studentData?.getInterestedStudByIdStatus.placementPackage,
        remark: nextProps.studentData?.getInterestedStudByIdStatus.remark,
        designation:
          nextProps.studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .lastName,
        studUserRegistrationId:
          nextProps.studentData?.getInterestedStudByIdStatus.userRegistrationId,
        fromDate: frDt,
        durationToDate:
          nextProps.studentData?.getInterestedStudByIdStatus.durationToDate,
      });
      const docList =
        nextProps.studentData?.getInterestedStudByIdStatus.placementIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              index: index + 1,

              id: fileData.id,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }
  }

  componentDidMount() {
    const { studentData } = this.props;

    console.log(studentData);

    if (Object.keys(studentData?.getInterestedStudByIdStatus).length != 0) {
      const durationForsArr =
        studentData?.getInterestedStudByIdStatus.durationFromDate !== "" &&
        studentData?.getInterestedStudByIdStatus.durationFromDate !== null
          ? studentData?.getInterestedStudByIdStatus.durationFromDate.split("-")
          : "";
      const frDt =
        durationForsArr !== ""
          ? new Date(
              durationForsArr[2],
              durationForsArr[1] - 1,
              durationForsArr[0]
            )
          : "";
      this.setState({
        placementType: studentData?.getInterestedStudByIdStatus.placementType,
        id: studentData?.getInterestedStudByIdStatus.id,
        companyAddress: studentData?.getInterestedStudByIdStatus.companyAdd,
        companyName: studentData?.getInterestedStudByIdStatus.companyName,
        companyMail:
          nextProps.studentData?.getInterestedStudByIdStatus.companyEmail,
        companyNo: nextProps.studentData?.getInterestedStudByIdStatus.contactNo,
        placementPackage:
          studentData?.getInterestedStudByIdStatus.placementPackage,
        remark: studentData?.getInterestedStudByIdStatus.remark,
        designation: studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration.lastName,
        studUserRegistrationId:
          studentData?.getInterestedStudByIdStatus.userRegistrationId,
        fromDate: frDt,
        durationToDate: studentData?.getInterestedStudByIdStatus.durationToDate,
      });
      const docList =
        studentData?.getInterestedStudByIdStatus.placementIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              index: index + 1,
              id: fileData.id,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }
    //     getStudentList({yearDetails:data.yearDetailId,qualification:[]}).then((response)=>{
    //   if(!response){
    //     getAlert({ message: "Something went wrong" });
    //   }
    // });
  }

  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };

  changeToDate = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === "contactNo") {
      if (onlyNumber.test(value)) {
        this.setState({
          ...this.state,
          [name]: value,
        });
      }
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleFormValidation(name) {
    const {
      companyName,
      placementType,
      designation,
      academicYear,
      fromDate,
      toDate,
      companyAddress,
      placementPackage,
      companyNo,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (placementType.toString().trim() === "") {
      formIsValid = false;
      formErrors["placementTypeError"] = myConstClass.placementTypeMsg;
    }

    if (companyName === "") {
      formIsValid = false;
      formErrors["companyNameError"] = myConstClass.companyNameMsg;
    }

    if (designation.toString().trim() === "") {
      formIsValid = false;
      formErrors["designationError"] = myConstClass.designationMsg;
    }

    if (fromDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (companyAddress === "") {
      formIsValid = false;
      formErrors["companyAddressErr"] = myConstClass.companyAddressMsg;
    }

    if (placementPackage.toString().trim() === "") {
      formIsValid = false;
      formErrors["packageError"] = myConstClass.packageMsg;
    }

    if (toDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }
    if (companyNo != "") {
      if (!onlyNumber.test(companyNo)) {
        formIsValid = false;
        formErrors["contactNoError"] = myConstClass.contactNumberMsg;
      } else if (companyNo.length > 0 && companyNo.length != 10) {
        formIsValid = false;
        formErrors["contactNoError"] = myConstClass.contactNoLengthMsg;
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchData = () => {
    const { fromDate, toDate, academicYear, placementType } = this.state;
    const {} = this.props;
    console.log(fromDate + " fromDate");
    console.log(toDate + " toDate");
    // getAdmissionList({ academicId: academicYear, yearDetailId: yearDetail });
  };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };

  // rowAppliedList = (data) => {
  //   this.setState({
  //     accountFees: true,
  //     interestedStudent: false,
  //     listOfComp: false,
  //     applicationId:data.id
  //   });
  // };

  changeH = (fileName, name, index) => {
    console.log(fileName);
    if (fileName === undefined) {
      if (this.state.fileName === "") {
        this.setState({
          ...this.state,
          fileName: "",
        });
      } else {
        this.setState({
          ...this.state,
          fileName: this.state.fileName,
        });
      }
    } else {
      this.setState({
        ...this.state,
        fileName: fileName,
      });
    }
    console.log(this.state.fileName.name);
  };

  uploadImage = () => {
    const { fileName, documentName, docNameDD } = this.state;
    if (fileName !== "") {
      this.setState({
        upload: true,

        uploadError: false,
      });

      const formData = new FormData();
      formData.append("file", this.state.fileName);
      apiPost({
        url: endpoints.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          let docList = [
            ...this.props.fileData.fileData,
            {
              index: this.props.fileData.fileData.length + 1,
              name: docNameDD === "1" ? documentName : docNameDD,
              fileName: data,
            },
          ];
          this.setState({
            documentName: "",
            docNameDD: "",
            fileName: "",
            upload: false,
            uploadError: false,
          });
          this.props.fileMulitipleSave({ fileData: docList });
        }
      });
    } else {
      this.setState({
        upload: false,
        uploadError: true,
      });
    }
  };
  onClickDelete = (params) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        if (params.id !== undefined) {
          apiDelete({
            url: endpoints.placementIntrestedDoc + "/" + params.id,
          }).then(({ data, success }) => {
            if (success) {
              this.props.deleteFile({ file: params.fileName }).then(() => {});
              const docList = this.props.fileData.fileData.filter(
                (row) => row.fileName !== params.fileName
              );
              const rowData = docList.map((docData, index) => {
                return { ...docData, index: index + 1 };
              });
              this.props.fileMulitipleSave({ fileData: rowData });
            }
          });
        } else {
          this.props.deleteFile({ file: params.fileName }).then(() => {});
          const docList = this.props.fileData.fileData.filter(
            (row) => row.fileName !== params.fileName
          );
          const rowData = docList.map((docData, index) => {
            return { ...docData, index: index + 1 };
          });
          this.props.fileMulitipleSave({ fileData: rowData });
        }
      }
    });
  };
  // uploadImage = () => {
  //   const { saveMultipleFile } = this.props;
  //   const { fileName, documentName, number } = this.state;

  //   if (fileName !== "") {
  //     this.setState({
  //       upload: true,
  //       uploadError: false,
  //     });
  //     let fileData = {
  //       fileName: fileName,
  //       name: documentName,
  //     };
  //     saveMultipleFile({ file: fileData }).then((response) => {
  //       if (!response) {
  //         this.props.getAlert({ message: "Something went wrong" });
  //         this.setState({
  //           documentName: "",
  //           fileName: "",
  //           upload: false,
  //           uploadError: false,
  //           number: number + 1,
  //         });
  //       } else {
  //         this.setState({
  //           documentName: "",
  //           fileName: "",
  //           docNameDD:"",
  //           upload: false,
  //           uploadError: false,
  //           number: number + 1,
  //         });
  //       }
  //     });
  //   } else {
  //     this.setState({
  //       uploadError: true,
  //     });
  //   }
  // };
  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  closeStudentFeesDetails = () => {
    console.log("Back from interested Student");
    this.setState({ interestedStudent: false, listOfComp: true });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  onSave = () => {
    const {
      id,
      companyName,
      companyAddress,
      designation,
      placementType,
      placementPackage,
      remark,
      fromDate,
      toDate,
      fileName,
      documentName,
      companyMail,
      companyNo,
    } = this.state;
    const { data, studentData } = this.props;
    let fromDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    console.log(fromDate + " to " + fromDt);
    console.log(toDate + " to " + toDt);
    const documentList = this.props.fileData.fileData.map((docData) => {
      let docs = {
        docName: docData.name,
        fileName: docData.fileName,
      };
      if (docData.id) {
        docs = {
          ...docs,
          id: docData.id,
        };
      }
      return docs;
    });

    if (this.handleFormValidation()) {
      let studentInfo = {
        placementDeclare: null,
        companyName: companyName,
        companyAdd: companyAddress,
        rollNo: "",
        userRegistrationId: "",
        userRegistrationName: "",
        collegeName: "",
        selectedStatus: 1,
        designation: designation,
        placementPackage: placementPackage,
        durationFromDate: fromDt,
        durationToDate: null,
        placementType: placementType,
        vivaStatus: 0,
        approvedStatus: 0,
        intrestStatus: 1,
        remark: remark,
        placementIntrestedStudentDocument: documentList,
        companyEmail: companyMail,
        contactNo: companyNo,
      };
      swal({
        title: "Are you sure?",
        text: "Do you want to save the data?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          if (id !== 0) {
            let student = studentData?.getInterestedStudByIdStatus;
            student = {
              ...student,
              designation: designation,
              placementPackage: placementPackage,
              durationFromDate: fromDt,
              durationToDate: null,
              placementIntrestedStudentDocument: documentList,
            };
            apiPost({
              url: endpoints.placementIntrest + "/edit",
              postBody: student,
            }).then(({ data, success }) => {
              if (!success) {
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.props.goBack();
                this.props.showNotification({
                  msg: "Data updated successfully",
                });
                this.props.deleteFileData();
              }
            });
          } else {
            apiPost({
              url: endpoints.placementIntrest,
              postBody: studentInfo,
            }).then(({ data, success }) => {
              if (!success) {
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.props.goBack();
                this.props.showNotification({
                  msg: "Data saved successfully",
                });
                this.props.deleteFileData();
              }
            });
          }
        }
      });
    }
  };
  render() {
    const {
      listOfComp,
      fromDate,
      toDate,
      placementType,
      companyName,
      companyAddress,
      remark,
      designation,
      placementPackage,
      documentName,
      docNameDD,
      documentListLocal,
      uploadError,
      upload,
      fileName,
      companyMail,
      companyNo,
    } = this.state;
    const {
      fromDateError,
      toDateError,
      studentNameError,
      designationError,
      companyNameError,
      placementTypeError,
      packageError,
      companyAddressErr,
      contactNoError,
    } = this.state.formErrors;
    const {
      goBack,
      intrestedStudList,
      studentData,
      fileData,
      internshipTypeList,
    } = this.props;
    console.log(intrestedStudList);
    return (
      <>
        <div>
          <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
            {listOfComp && (
              <>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Add Company By Student"
                    />
                  </Grid>

                  <Grid
                    sx={{ paddingTop: 2, paddingRight: 2 }}
                    container
                    justifyContent="right"
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back"
                      onClick={goBack}
                    />
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Company Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyName"
                        id="companyName"
                        value={companyName}
                        onChange={this.changeHandler}
                        fullWidth
                        error={companyNameError ? true : false}
                        helperText={companyNameError ? companyNameError : " "}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Company Address"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyAddress"
                        id="companyAddress"
                        value={companyAddress}
                        onChange={this.changeHandler}
                        fullWidth
                        error={companyAddressErr ? true : false}
                        helperText={companyAddressErr ? companyAddressErr : " "}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company Email"
                      />
                    </Grid>

                    <Grid sx={{ mt: -3 }} item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyMail"
                        id="companyMail"
                        value={companyMail}
                        onChange={this.changeHandler}
                        fullWidth
                        // error={companyNameError ? true : false}
                        // helperText={companyNameError ? companyNameError : " "}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company No."
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyNo"
                        id="companyNo"
                        value={companyNo}
                        onChange={this.changeHandler}
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        error={contactNoError ? true : false}
                        helperText={contactNoError ? contactNoError : " "}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Designation"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="designation"
                        id="designation"
                        value={designation}
                        onChange={this.changeHandler}
                        fullWidth
                        error={designationError ? true : false}
                        helperText={designationError ? designationError : " "}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Package(₹ Lakh per annum )"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="placementPackage"
                        id="placementPackage"
                        value={placementPackage}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                        error={packageError ? true : false}
                        helperText={packageError ? packageError : " "}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={6}
                    md={6}
                    // sx={{ marginTop: 2 }}
                    columnSpacing={3}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Placement Type"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl color={"primary"} fullWidth={true}>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className="text-black"
                          name="placementType"
                          id="placementType"
                          value={placementType}
                          onChange={this.changedHandler}
                          size="small"
                          fullWidth={true}
                          {...(Object.keys(
                            studentData?.getInterestedStudByIdStatus
                          ).length != 0
                            ? { disabled: true }
                            : { disabled: false })}
                        >
                          <MenuItem value="0">On Campus</MenuItem>
                          <MenuItem value="1">Off Campus</MenuItem>
                          <MenuItem value="2">Pool Campus</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {placementTypeError ? placementTypeError : " "}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={6}
                    md={6}
                    // sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Joining Date"
                      />
                    </Grid>

                    <Grid sx={{ ml: 0.5 }} item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.changeFromDate}
                        isError={fromDateError ? true : false}
                        errorText={fromDateError ? fromDateError : " "}
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={4}
                    md={4}
                    // sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Document Upload"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={docNameDD}
                        name={"docNameDD"}
                        options={documentListLocal}
                        onChange={this.ChangeHandlerSearch}
                      />
                    </Grid>
                  </Grid>
                  {docNameDD === "1" ? (
                    <>
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        columnSpacing={1}
                        xs={12}
                        sm={4}
                        md={4}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Enter Document"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            size="small"
                            color="primary"
                            name="documentName"
                            id="documentName"
                            value={documentName}
                            onChange={this.changeHandler}
                            fullWidth
                            helperText=" "
                          ></TextField>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        columnSpacing={1}
                        rowSpacing={2}
                        xs={12}
                        sm={1}
                        md={1}
                        sx={{ marginTop: 1 }}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <AttachFile name="" fileName={this.changeH} />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        columnSpacing={1}
                        rowSpacing={2}
                        xs={12}
                        sm={2}
                        md={2}
                        sx={{ marginTop: 1 }}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          &nbsp; {fileName !== "" ? fileName.name : ""}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={1}
                      rowSpacing={2}
                      xs={12}
                      sm={7}
                      md={7}
                      sx={{ marginTop: 1 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <AttachFile name="" fileName={this.changeH} />
                        &nbsp; {fileName !== "" ? fileName.name : ""}
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={1}
                    md={1}
                    sx={{ marginTop: 1 }}
                  >
                    <Grid sx={{ mt: 4 }} item xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        fullWidth={true}
                        {...(documentName !== "" &&
                          upload && { disable: true })}
                        name="Upload"
                        onClick={this.uploadImage}
                      />
                      {/* {docName.length !== 0 &&
                        docName[0].uploadFile == true &&
                        "uploaded"} */}
                    </Grid>
                  </Grid>
                  <Grid sx={{ ml: 3 }} item xs={12} sm={12} md={12}>
                    {uploadError && (
                      <FormHelperText error>
                        {"*please select file and Document Name"}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid p={2} sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                    <DynamicTable
                      data={fileData.fileData}
                      tableHead={this.columns}
                      showHeadDelete={true}
                      rowDelete={this.onClickDelete}
                      showPegination={false}
                      rowViewData={this.details}
                      showView={true}
                    />
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={3}
                    xs={12}
                    sm={12}
                    md={12}
                    // sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Remark"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="remark"
                        id="remark"
                        value={remark}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(Object.keys(
                          studentData?.getInterestedStudByIdStatus
                        ).length != 0
                          ? { disabled: true }
                          : { disabled: false })}
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid
              container
              justifyContent="right"
              direction="row"
              columnSpacing={1}
              sx={{ mt: 2, p: 2 }}
            >
              <Grid item>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentData: state.companyPlacementList,
  // internshipTypeList: state.internshipType,
  intrestedStudList: state.internshipDeclaration,
  fileData: state.file,
});
const mapDispatchToProps = {
  getClass,
  getAcademicYear,
  // getInternshipType,
  // getAdmissionList,
  getYearDetails,
  getStudentList,
  getAlert,
  saveMultipleFile,
  fileMulitipleSave,
  saveFile,
  deleteFile,
  openFileInNewTab,
  deleteFileData,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentDeclareInternshipListTable);
