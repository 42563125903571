export const ExternalInternshipDynamicJson = {
    screenTitle: "External Student Internship List",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    getAcadamicYear: true,
    getListId: 'yearDetail',
    fieldMeta: [
        {
            label: "Year Detail",
            controlType: "autocomplete",
            placeHolder: "",
            md: 3.6,
            lg: 3.6,
            sm: 3.6,
            xs: 12,
            masterName: "yearDetailMaster",
            getListId: 'yearDetail',
            dataKey: "yearDetail",
            isMandatory: true,
        },
        {
            label: "Division",
            controlType: "multiSelect",
            placeHolder: "",
            md: 3.6,
            lg: 3.6,
            sm: 3.6,
            xs: 12,
            masterName: "divisionMaster",
            getListFrom: "yearDetail",
            dataKey: "divisionId",
            isMandatory: true,
        },
        {
            label: "Internship Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 3.6,
            lg: 3.6,
            sm: 3.6,
            xs: 12,
            masterName: "internTypeMaster",
            dataKey: "internship",
            isMandatory: true,
        },

    ],
};
