import endpoint from "../config/endpoints";

export const semisterDetailsMasterJson = {
  apiBaseURL: endpoint.semisterDetails,
  screenTitle: "Semister Details",
  showAddButton: true,
  showSaveNextBtn: false,
  showPdfDownload: true,
  pdfFileName: "Semister Details",
  showExcelDownload: true,
  excelFileName: "Semister Details",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Semister",
      columnKeyName: "semisterName",
      isChecked: true,
    },
    {
      columnDisplayName: "Academic Year",
      columnKeyName: "academicYearName",
      isChecked: true,
    },
    {
      columnDisplayName: "From Date",
      columnKeyName: "fromDate",
      isChecked: true,
    },
    {
      columnDisplayName: "To Date",
      columnKeyName: "toDate",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Academic Year",
      controlType: "autocomplete",
      placeHolder: "",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      masterName: "academicYearData",
      dataKey: "academicYearId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "semisterStartDate",

      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "semisterEndDate",
      onSubmit: "compare",
      onSubmitParameter: "semisterStartDate-g",
      isMandatory: true,
    },
  ],
};
