import { Grid, Paper, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../CommonActions/alert.slice";

import AutoComplete from "../../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../../components/Comman/Button";
import { LabelCompo } from "../../../../components/Comman/Label";
import { onlyNumber } from "../../../../components/Comman/Util/Validations";
import * as myConstClass from "../../../../config/messageconstant";

import { getAcademicYear } from "../../OnlineModule/AcademicYear/academicYear.slice";
import { getProgramTitle } from "../../OnlineModule/ProgramTitle/programTitle.slice";
import { getCategory } from "../../OnlineModule/Category/category.slice";
import { getClass } from "../../OnlineModule/Class/class.slice";
import MatrixForm from "./MatrixForm";

let docs;

class FeeStructureHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      yearDetailId: "",
      academicYearId: "",
      category: "",
      name: "",
      scholarship: "0",
      minPayment: "",
      totalFee: "",
      isActive: "1",
      totalFees: "",
      error: false,
      matrix: [],
      formErrors: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.feeStructuredList !== nextProps.feeStructuredList) {
      if (
        this.props.feeStructuredList.feesStructureHeaderById !==
          nextProps.feeStructuredList.feesStructureHeaderById &&
        Object.keys(nextProps.feeStructuredList.feesStructureHeaderById)
          .length !== 0
      ) {
        this.setDataToForm(nextProps.feeStructuredList.feesStructureHeaderById);
      }
    }
  }
  componentDidMount() {
    const {
      getProgramTitle,
      getClass,
      getAcademicYear,
      getCategory,
      feeStructuredList,
    } = this.props;
    getProgramTitle().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getCategory().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getClass().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    if (feeStructuredList.isFeesStructureHeaderFetchById === true) {
      this.setDataToForm(feeStructuredList.feesStructureHeaderById);
    }
  }
  setDataToForm = (data) => {
    this.setState({
      id: data.id,
      yearDetailId: data.yearDetailId,
      academicYearId:
        data.getAcademicYearId === null ? "" : data.getAcademicYearId.id,
      category: data.getCategoryId === null ? "" : data.getCategoryId.id,
      name: data.name,
      scholarship: data.scholarship,
      minPayment: data.minimumFees,
      totalFee: data.totalFees,
      isActive: data.isActive,
      matrix: data.feesStructureDetails.map((feesStructureDetailData) => {
        let feesStuctureDetail = {
          id: feesStructureDetailData.id,
          value: feesStructureDetailData.amt,
          feeHeadId: feesStructureDetailData.feesHead.id,
          feeHeadName: feesStructureDetailData.feesHead.name,
          fromFeesId: feesStructureDetailData.fromFees.id,
          fromFeesName: feesStructureDetailData.fromFees.name,
        };
        return feesStuctureDetail;
      }),
    });
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  changeHandlerNumber = (event) => {
    const { name, value } = event.target;
    if (event.target.value === "" || onlyNumber.test(event.target.value)) {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  handleFormValidation() {
    const {
      yearDetailId,
      academicYearId,
      category,
      name,
      totalFee,
      minPayment,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetailId.toString().trim() === "") {
      formIsValid = false;
      formErrors["programTitleError"] = myConstClass.classNameMsg;
    }
    if (name.toString().trim() === "") {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.nameMsgRequired;
    }

    if (category.toString().trim() === "") {
      formIsValid = false;
      formErrors["categoryError"] = myConstClass.catNameMsg;
    }

    if (academicYearId.toString().trim() === "") {
      formIsValid = false;
      formErrors["academicError"] = myConstClass.academicYearMessage;
    }

    if (totalFee.toString().trim() === "" || totalFee == "0") {
      formIsValid = false;
      formErrors["totalFeesError"] = myConstClass.totalFeesRequiredMsg;
    }

    if (minPayment.toString().trim() === "") {
      formIsValid = false;
      formErrors["minPaymentError"] = myConstClass.minFeesRequiredMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  saveHandler = (data) => {
    const {
      yearDetailId,
      name,
      category,
      scholarship,
      academicYearId,
      totalFee,
      minPayment,
      isActive,
    } = this.state;
    let matrixData = [];
    let total = 0;
    data.map((feesHead) => {
      feesHead.fromFeesList.map((fromFees) => {
        if (fromFees.fromFeesId == 1) {
          total = +fromFees.value + total;
        }

        let matData = {
          amt: fromFees.value === "" ? 0 : +fromFees.value,
          isActive: 1,
          feesHead: {
            id: feesHead.feeHeadId,
          },
          fromFees: {
            id: fromFees.fromFeesId,
          },
        };
        matrixData.push(matData);
      });
    });
    docs = {
      name: name,
      yearDetailId: yearDetailId,
      totalFees: totalFee,
      scholarship: scholarship,
      minimumFees: minPayment,
      academicYearId: academicYearId,
      categoryId: category,
      isActive: isActive,
      feesStructureDetails: matrixData,
    };

    if (this.handleFormValidation() && total == totalFee) {
      this.props.saveAndNext(docs);
      this.setState({
        yearDetailId: "",
        academicYearId: "",
        category: "",
        name: "",
        scholarship: "0",
        minPayment: "",
        totalFee: "",
        isActive: "1",
        error: false,
        formErrors: [],
      });
      return true;
    } else {
      if (total != totalFee) {
        this.setState({
          error: true,
        });
      } else {
        this.setState({
          error: false,
        });
      }
    }
    return false;
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = (data) => {
    const {
      id,
      yearDetailId,
      name,
      category,
      scholarship,
      academicYearId,
      totalFee,
      minPayment,
      isActive,
    } = this.state;
    let matrixData = [];
    let total = 0;
    data.map((feesHead) => {
      feesHead.fromFeesList.map((fromFees) => {
        if (fromFees.fromFeesId==1) {
          total = +fromFees.value + total;
        }

        let matData = {
          amt: fromFees.value === "" ? 0 : +fromFees.value,
          isActive: 1,
          feesHead: {
            id: feesHead.feeHeadId,
          },
          fromFees: {
            id: fromFees.fromFeesId,
          },
        };
        if (fromFees.id) {
          matData = {
            ...matData,
            id: fromFees.id,
          };
        }
        matrixData.push(matData);
      });
    });
    console.log(total);
    docs = {
      name: name,
      yearDetailId: yearDetailId,
      totalFees: totalFee,
      scholarship: scholarship,
      minimumFees: minPayment,
      academicYearId: academicYearId,
      categoryId: category,
      isActive: isActive,
      feesStructureDetails: matrixData,
    };
    console.log(docs);
    if (id != 0) {
      docs = {
        ...docs,
        id: id,
      };
    }
    if (this.handleFormValidation() && total == totalFee) {
      this.props.onSave(docs);
    } else {
      if (total != totalFee) {
        this.setState({
          error: true,
        });
      } else {
        this.setState({
          error: false,
        });
      }
    }
  };

  render() {
    const {
      programTitleError,
      nameError,
      categoryError,
      academicError,
      totalFeesError,
      minPaymentError,
    } = this.state.formErrors;
    const {
      yearDetailId,
      academicYearId,
      category,
      scholarship,
      name,
      minPayment,
      totalFee,
      matrix = [],
    } = this.state;
    const {
      categoryList,
      // programTitleList,
      academicYearList,
      onCancel,
      feeHeadList,
      fromFeesList,
      yearDetailsList,
    } = this.props;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <form
            onSubmit={this.submitHandler}
            autoComplete="off"
            noValidate={true}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={1}
            >
              <Grid
                sx={{ m: -1 }}
                item
                xs={12}
                md={12}
                size="small"
                container
                justifyContent="flex-end"
              >
                <ButtonCompo
                  size="small"
                  type="button"
                  variant="outlined"
                  name=" Back to List"
                  onClick={onCancel}
                />
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Year Details "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={yearDetailId}
                      name={"yearDetailId"}
                      options={yearDetailsList.class}
                      onChange={this.ChangeHandlerSearch}
                      isError={programTitleError ? true : false}
                      errorText={programTitleError ? programTitleError : " "}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Academic Year "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={academicYearId}
                      name={"academicYearId"}
                      options={academicYearList.academicYear}
                      onChange={this.ChangeHandlerSearch}
                      isError={academicError ? true : false}
                      errorText={academicError ? academicError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Category "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={category}
                      name={"category"}
                      options={categoryList.category}
                      onChange={this.ChangeHandlerSearch}
                      isError={categoryError ? true : false}
                      errorText={categoryError ? categoryError : " "}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Name  "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="name"
                      id="name"
                      value={name}
                      onChange={this.changeHandler}
                      fullWidth
                      helperText={nameError ? nameError : " "}
                      error={nameError ? true : false}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Partially Minimum Payment "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="minPayment"
                      id="minPayment"
                      value={minPayment}
                      onChange={this.changeHandlerNumber}
                      fullWidth
                      helperText={minPaymentError ? minPaymentError : " "}
                      error={minPaymentError ? true : false}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="*Total Fee "
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      size="small"
                      color="primary"
                      name="totalFee"
                      id="totalFee"
                      value={totalFee}
                      onChange={this.changeHandlerNumber}
                      fullWidth
                      helperText={totalFeesError ? totalFeesError : " "}
                      error={totalFeesError ? true : false}
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid m={1} item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Scholarship  "
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="scholarship"
                          id="scholarship"
                          name="scholarship"
                          value={scholarship}
                          onChange={this.changeHandler}
                          size="small"
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {fromFeesList.isFetch && feeHeadList.isFetch && (
              <>
                <FormHelperText error>
                  {this.state.error
                    ? "Total of matrix should be equal to :" + totalFee
                    : ""}
                </FormHelperText>
                <MatrixForm
                  fromFeesList={fromFeesList?.fromFees}
                  feeHeadList={feeHeadList?.feesHeadDetail}
                  onSaveAndNext={this.saveHandler}
                  onSave={this.submitHandler}
                  onCancel={onCancel}
                  matrix={matrix}
                  error={this.state.error}
                />
              </>
            )}
          </form>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  academicYearList: state.academicYear,
  programTitleList: state.programTitle,
  feeStructuredList: state.feesStructureHead,
  fromFeesList: state.fromFees,
  feeHeadList: state.feesHeadDetail,
  yearDetailsList: state.class,
});
const mapDispatchToProps = {
  getProgramTitle,
  getAcademicYear,
  getCategory,
  getClass,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(FeeStructureHead);
