import CallIcon from "@mui/icons-material/Call";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import * as React from "react";
import { connect } from "react-redux";
import { onToggle } from "../CommonActions/common.slice";

const Header = ({ common, onToggle }) => {
  // const toggleDrawer = () => {
  //   onToggle();
  // };
  return (
    <>
      <div className="logo_area">
        <div className="institute_logo">
          <img src="https://i.pinimg.com/236x/cd/e9/65/cde96542dd9ed201a68a4ee171b90f28.jpg" />
          {/* <img src="https://www.bmncollege.com/wp-content/uploads/2017/11/1-4.png" /> */}
        </div>
        <div className="institute_name">
          Dr. Bhanuben Mahendra Nanavati College of Home Science (Autonomous)
        </div>
        <div className="helpline">
          <h3 className="helpline_title">Technical Helpline Number</h3>
          <p className="help_time">
            <WatchLaterIcon /> (09:00 AM to 07:00 PM)
          </p>
          <p className="help_time">
            <CallIcon /> +911 255 46, 1800 2091 777
          </p>
        </div>
        <div className="clr"></div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = { onToggle };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
