import InfoIcon from "@mui/icons-material/Info";
import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import BasicDatePicker from "../../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import { LabelCompo } from "../../../components/Comman/Label";
import MultipleSelect from "../../../components/Comman/MultipleSelect";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import { getDepartment } from "../../Masters/Internship/Department/department.slice";
import { getFeedBack } from "../AddAndViewFeedBack/addFeedback.slice";
import { getEmployeeByDeptId } from "../../EmployeeDetails/employee.slice";
import EmployeeFeedbackPreview from "./EmployeeFeedbackPreview";
import { resetValue, getFeedbackFromDtToDtFilter } from "./feedback.slice";
import {
  getfeedBackDetailsByFeedBackIdEmpPreview,
  getfeedBackId,
  resetValues,
} from "../AddAndViewFeedBack/addFeedback.slice";
import { TitleAndBreadCrum } from "../../../components/Comman/TitleAndBreadCrum";
let docs;
class EmployeeFeedbackDeclare extends Component {
  state = {
    id: 0,
    feedbackName: "",
    employee: [],
    description: "",
    department: [],
    role: [],
    fromDate: "",
    toDate: "",
    highlightLabel: 0,
    label: "",
    formErrors: [],
    dept: [],
    showPreview: false,
    showForm: true,
  };

  multiSelectHandler = (value, name) => {
    console.log(value);
    console.log(name);
    if (value !== null) {
      this.setState({ [name]: value });
      if (name === "department") {
        this.setState({ employee: [] });
        this.props
          .getEmployeeByDeptId({ departmentId: value })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      }
    }
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  multiSelectHandle = (value, name) => {
    console.log(value);
    console.log(name);
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  toTimeChangeHandler = (time) => {
    this.setState({
      time: time,
    });
  };

  handleFormValidation() {
    const {
      feedbackName,
      description,
      fromDate,
      toDate,
      department,
      employee,
      dept,
    } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (feedbackName == "" || feedbackName == null) {
      formIsValid = false;
      formErrors["feedbackError"] = myConstClass.feedbackMsg;
    }
    if (employee == "") {
      formIsValid = false;
      formErrors["employeeError"] = myConstClass.employeemsg;
    }

    if (department.length === 0) {
      formIsValid = false;
      formErrors["departmentListError"] = myConstClass.departmentMsg;
    }

    if (dept.length === 0) {
      formIsValid = false;
      formErrors["departmentError"] = myConstClass.departmentMsg;
    }

    if (fromDate === "" || fromDate === null) {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate === "" || toDate === null) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }

    if (fromDate > toDate) {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.displaytoMsg;
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  componentDidMount() {
    const { eventDataForEmpFeedback } = this.props;
    console.log(eventDataForEmpFeedback);
    this.props.getFeedBack().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.props.getDepartment().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    // for Edit Dataa

    const { editData } = this.props;
    console.log(editData);
    if (editData != undefined || editData != null) {
      if (Object.keys(editData).length !== 0) {
        this.props
          .getEmployeeByDeptId({ departmentId: editData.deptAuthorityToView })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });

        const fromDateARR =
          editData.fromDate == null ? "" : editData.fromDate.split("-");
        const fromDt =
          fromDateARR == ""
            ? ""
            : new Date(fromDateARR[2], fromDateARR[1] - 1, fromDateARR[0]);
        const toDateARR =
          editData.toDate == null ? "" : editData.toDate.split("-");
        const toDt =
          toDateARR == ""
            ? ""
            : new Date(toDateARR[2], toDateARR[1] - 1, toDateARR[0]);
        this.setState({
          ...this.state,
          id: editData.id,
          feedbackName: editData.feedbackNameId,
          description: editData.description,
          fromDate: fromDt,
          toDate: toDt,
          department: editData.deptAuthorityToView.split(","),
          employee: editData.empAuthorityToView.split(","),
          dept: editData.departmentIds.split(","),
        });
      }
    }
    if (this.props.editEventData) {
      this.setState({
        ...this.state,
        eventId: this.props.editEventData,
      });
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { eventDataForEmpFeedback } = this.props;
    console.log(eventDataForEmpFeedback);
    const {
      feedbackName,
      description,
      fromDate,
      toDate,
      department,
      applicationLastDate,
      employee,
      dept,
      id,
    } = this.state;
    let frmDt = moment(fromDate).format("DD-MM-YYYY");
    let toDt = moment(toDate).format("DD-MM-YYYY");
    let applidate = moment(applicationLastDate).format("DD-MM-YYYY");
    if (eventDataForEmpFeedback != undefined) {
      docs = {
        eventId: eventDataForEmpFeedback.id,
        feedbackHead: { id: feedbackName },
        feedbackDetails: description,
        deptAuthorityToView: department.join(),
        empAuthorityToView: employee.join(),
        departmentIds: dept.join(),
        fromDate: frmDt,
        toDate: toDt,
        type: 1,
        isActive: 1,
      };
    } else {
      docs = {
        feedbackHead: { id: feedbackName },
        feedbackDetails: description,
        deptAuthorityToView: department.join(),
        empAuthorityToView: employee.join(),
        departmentIds: dept.join(),
        fromDate: frmDt,
        toDate: toDt,
        type: 0,
        isActive: 1,
      };
      console.log(docs);
      if (id !== 0) {
        docs = {
          ...docs,
          id: id,
        };
      }
    }
    if (this.handleFormValidation()) {
      swal({
        title: "Do you want to submit the information?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((response) => {
        if (response) {
          console.log(docs);
          this.onSave(docs);
          this.props.onCancel();
        }
      });
    }
  };

  onSave = (dataToSave) => {
    console.log(dataToSave);
    apiPost({
      url: endpoint.employeeFeedback,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });
        // this.props.getFeedBack().then( ( response ) =>
        // {
        //   if ( !response )
        //   {
        //     this.props.getAlert( { message: "Something went wrong" } );
        //   }
        // } );

        this.props.getFeedbackFromDtToDtFilter().then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  backToForm = () => {
    this.setState({
      showPreview: false,
      showForm: true,
    });
  };

  showEmployeePreview = () => {
    this.props.resetValues();
    const { feedbackName } = this.state;
    if (feedbackName != null || feedbackName != "") {
      this.props
        .getfeedBackId({
          feedbackId: feedbackName,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      this.props
        .getfeedBackDetailsByFeedBackIdEmpPreview({
          feedBackId: feedbackName,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showPreview: true,
              showForm: false,
            });
          }
        });
    } else {
      this.props.getAlert({ message: "Something went wrong" });
    }
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  render() {
    const {
      feedbackName,
      fileName,
      upload,
      documentName,
      description,
      uploadError,
      fromDate,
      toDate,
      department,
      employee,
      dept,
    } = this.state;
    const { onCancel, internshipDeclarationList } = this.props;
    const {
      feedbackError,
      detailsError,
      departmentError,
      departmentListError,
      roleError,
      fromDateError,
      toDateError,
      toDateGreaterError,
      fromDateLessError,
      venueError,
      timeError,
      applicationLastDateError,
      labelError,
      employeeError,
    } = this.state.formErrors;

    return (
      <>
        {this.state.showForm && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="Feedback Declare Form (For Employee)" />
            </Paper>
            <br />
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <form
                onSubmit={this.submitHandler}
                autoComplete="off"
                noValidate={true}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      sx={{ mb: 3, ml: -1, mt: -1 }}
                      item
                      xs={12}
                      sm={10.5}
                      md={10.5}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 22 }}
                        label=""
                      />
                    </Grid>
                    <Grid sx={{ mb: 2, mt: -1 }} item xs={12} sm={1.5} md={1.5}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back to List"
                        onClick={onCancel}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={9.5}
                      sm={11.5}
                      md={11.5}
                      lg={11.5}
                      container
                      sx={{ ml: -1 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Feedback"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={feedbackName}
                          name={"feedbackName"}
                          options={this.props.feedbackList?.feedBack.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.ChangeHandlerSearch}
                          isError={feedbackError ? true : false}
                          errorText={feedbackError ? feedbackError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2.5}
                      sm={0.5}
                      md={0.5}
                      lg={0.5}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      {/* <Grid sx={{mb:-5}} item xs={12} sm={12} md={12}>
                   &nbsp;
                  </Grid> */}
                      {this.state.feedbackName !== "" && (
                        <>
                          <Grid sx={{ mt: 3 }} item xs={12} sm={12} md={12}>
                            <MyComponentWithIconProps
                              statusImage={InfoIcon}
                              color="primary"
                              fontSize="large"
                              title="Details"
                              onClick={this.showEmployeePreview}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    sx={{ ml: -1 }}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      container
                      // sx={{mt:-3}}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Instructions"
                        />
                      </Grid>

                      <Grid sx={{ mb: 3 }} item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="description"
                          size="small"
                          value={description}
                          onChange={this.changeHandler}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    sx={{ ml: -1 }}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      columnSpacing={1}
                      container
                      sx={{ ml: -1 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Department (Authority to view the response)"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={departmentListError}
                          name1="department"
                          value1={department}
                          names={this.props.departmentList?.department.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.multiSelectHandler}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      columnSpacing={1}
                      container
                      sx={{ ml: -1 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Employee(Authority to view the response)"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={employeeError}
                          name1="employee"
                          value1={employee}
                          names={this.props.employeeList?.employeeList.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.multiSelectHandle}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    sx={{ ml: -1 }}
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Department"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <MultipleSelect
                          er={departmentError}
                          name1="dept"
                          value1={dept}
                          names={this.props.departmentList?.department.filter(
                            (data) => data.isActive == 1
                          )}
                          onChange={this.multiSelectHandler}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      container
                      sx={{ ml: -1 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*From Date "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="fromDate"
                          size="small"
                          value={fromDate}
                          fullWidth
                          maxDate={toDate}
                          onChange={this.onDateChange("fromDate")}
                          isError={fromDateError ? true : false}
                          errorText={
                            fromDateError
                              ? fromDateError
                              : !fromDateError
                              ? fromDateLessError
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      container
                      sx={{ ml: -1 }}
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*To Date "
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="toDate"
                          size="small"
                          value={toDate}
                          fullWidth
                          minDate={fromDate}
                          onChange={this.onDateChange("toDate")}
                          isError={toDateError ? true : false}
                          errorText={
                            toDateError
                              ? toDateError
                              : !toDateError
                              ? toDateGreaterError
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{ mt: 2 }}
                    rowSpacing={2}
                    container
                    columnSpacing={1}
                    justifyContent="right"
                  >
                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                        onClick={onCancel}
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item justifyContent="space-evenly">
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save"
                        onClick={this.submitHandler}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </>
        )}

        {this.state.showPreview && (
          <>
            <EmployeeFeedbackPreview backToForm={this.backToForm} />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  // academicYear: state.academicYear,
  internshipDeclarationList: state.internshipDeclaration,
  departmentList: state.department,
  feedbackList: state.feedBack,
  employeeList: state.employeeList,
  preview: state.empFeedback,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  resetValue,
  getDepartment,
  getFeedBack,
  getEmployeeByDeptId,
  resetValues,
  getfeedBackDetailsByFeedBackIdEmpPreview,
  getfeedBackId,
  getFeedbackFromDtToDtFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeFeedbackDeclare);
