export const NaacReportJson = {
  apiBaseURL: "",
  screenTitle: "Academic Details Report",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
  searchButton: false,
  PDFButton: true,
  EXCELButton: true,
  marginTop: 3,
  fieldMeta: [
    {
      label: "Academic Year",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "academicYear",
      dataKey: "academicYearId",
      isMandatory: true,
    },
    {
      label: "Year Detail",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearDetails",
      dataKey: "yearDetailId",
      isMandatory: true,
      getListId: "yearDetailId,academicYearId",
    },
    {
      label: "Year Detail ( Multiselect )",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearDetails",
      dataKey: "yearDetailIds",
      isMandatory: true,
      getListId: "yearDetailIds",
    },
    {
      label: "Division ( Multiselect )",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "division",
      dataKey: "divisionIds",
      isMandatory: true,
    },
    {
      label: "Program Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "programType",
      dataKey: "programType",
      getListId: "programType",
      isMandatory: true,
    },
    {
      label: "Program Title",
      controlType: "multiSelect",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "programTitle",
      dataKey: "programTitle",
      isMandatory: true,
    },

    {
      label: "Activity",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "activity",
      dataKey: "activityId",
      isMandatory: true,
      getListId: "activityId",
    },
    {
      label: "Teacher Name",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "teacher",
      dataKey: "teacherId",
      isMandatory: true,
      getListId: "teacherId",
    },
    {
      label: "Student Status",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "studentStatus",
      dataKey: "studentStatus",
      isMandatory: true,
    },
    {
      label: "Company",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "company",
      dataKey: "companyId",
      isMandatory: true,
    },
  ],
};
