import { numberMsg } from "../config/messageconstant";

export const GovPaymentTransactionJson = {
  screenTitle: "Government Fees",
  showAddButton: false,
  showPdfDownload: false,
  pdfFileName: "",
  showExcelDownload: false,
  excelFileName: "",
  tableColumnsToFilter: [],
  buttonCenter: false,
};
