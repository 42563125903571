import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  adminSubject: [],
  isFetchAdminSubject: false,

  adminSchemeHead: [],
  isFetchAdminSchemeHead: false,

  adminSubjectSchemeHead: [],
  isFetchAdminSubjectSchemeHead: false,
};
let URL = endpoints.examFormDeclare;
let URL1 = endpoints.studentApply;
const applyStudentSlice = createSlice({
  name: "applyStudentSlice",
  initialState,
  reducers: {
    adminSubjectSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        adminSubject: row,
        isFetchAdminSubject: true,
      };
    },
    adminSchemeHeadSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        adminSchemeHead: row,
        isFetchAdminSchemeHead: true,
      };
    },
    AdminSubjectSchemeHeadSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        adminSubjectSchemeHead: row,
        isFetchAdminSubjectSchemeHead: true,
      };
    },
  },
});

export const {
  adminSubjectSuccess,
  adminSchemeHeadSuccess,
  AdminSubjectSchemeHeadSuccess,
} = applyStudentSlice.actions;

export default applyStudentSlice.reducer;

export const getAdminSubject =
  ({ userRegistrationId, semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-for-admin?userRegistrationId=" +
          userRegistrationId +
          "&semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              // index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectId: data1.subjectId,
              subjectName: data1.subjectName,
              shortName: data1.shortName,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(adminSubjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdminSubjectDeclare =
  ({ userRegistrationId, semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-subject-for-admin?userRegistrationId=" +
          userRegistrationId +
          "&semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              // index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectId: data1.subjectId,
              subjectName: data1.subjectName,
              shortName: data1.shortName,
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(adminSubjectSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdminSchemeHead =
  ({ userRegistrationId, semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-scheme-head-for-admin?userRegistrationId=" +
          userRegistrationId +
          "&semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              shortName: data1.shortName,
              sortOrderNo: data1.sortOrderNo,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(adminSchemeHeadSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdminSchemeHeadDeclare =
  ({ userRegistrationId, semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-scheme-head-for-admin?userRegistrationId=" +
          userRegistrationId +
          "&semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              name: data1.name,
              shortName: data1.shortName,
              sortOrderNo: data1.sortOrderNo,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(adminSchemeHeadSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdminSubjectSchemeHead =
  ({ userRegistrationId, semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-subject-scheme-for-admin?userRegistrationId=" +
          userRegistrationId +
          "&semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(AdminSubjectSchemeHeadSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAdminSubjectSchemeHeadDeclare =
  ({ userRegistrationId, semisterDetailId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-subject-scheme-for-admin?userRegistrationId=" +
          userRegistrationId +
          "&semisterDetailId=" +
          semisterDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            let bData = {
              index: index,
              id: data1.id,
              schemeHeadId: data1.schemeHeadId,
              subjectName: data1.subjectName,
              subjectId: data1.subjectId,
            };
            row.push(bData);
            return data1;
          });
          console.log(row);
          dispatch(AdminSubjectSchemeHeadSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
