import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormHelperText, Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../../CommonActions/alert.slice";
import AttachFile from "../../../../components/Comman/AttachFile";
import { ButtonCompo } from "../../../../components/Comman/Button";
import BasicDatePicker from "../../../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../../../components/Comman/IconButton";
import { LabelCompo } from "../../../../components/Comman/Label";
import { TitleAndBreadCrum } from "../../../../components/Comman/TitleAndBreadCrum";
import endpoint from "../../../../config/endpoints";
import * as myConstClass from "../../../../config/messageconstant";
import { showNotification } from "../../../../Pages/Landing/Landing.slice";
import { apiPost } from "../../../../utils/api_service";
import { openFileInNewTab } from "../../../UploadFile/file.slice";
import { getNewRoomsList } from "./adminNews.slice";
import { Loading1 } from "../../../../components/Loading1";

let docs;
class AdminAddNews extends Component {
  state = {
    id: 0,
    details: "",
    formErrors: [],
    documentName: "",
    fileName: "",
    file: "",
    uploadError: false,
    upload: false,
    fileNameFlag: false,
    title: "",
    description: "",
    date: new Date(),
    link: "",
    insertDate: "",
    showForm: true,
    showLoader: false,
    isDisable: false,
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  onDateChange = (dataKey) => (date) => {
    this.setState({
      [dataKey]: date,
    });
  };

  handleFormValidation() {
    const { title, description, date, fileName } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (title.toString().trim() === "" || title === null) {
      formIsValid = false;
      formErrors["titleError"] = myConstClass.titleMsg;
    }
    if (description.toString().trim() === "" || description === null) {
      formIsValid = false;
      formErrors["descriptionError"] = myConstClass.descriptionMsg;
    }

    // if (date === "" || date === null) {
    //   formIsValid = false;
    //   formErrors["dateError"] = myConstClass.dateMsg;
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, link, title, description, date, insertDate } = this.state;
    let addNewsDate = moment(date).format("DD-MM-YYYY");
    docs = {
      title: title,
      description: description,
      link: link,
      eventDate: addNewsDate,
    };
    console.log(docs);

    if (id != 0) {
      docs = {
        ...docs,
        id: id,
        date: insertDate,
      };
    }

    if (this.handleFormValidation()) {
      this.onSave(docs);
      this.props.onCancel();
    }
  };
  componentDidMount() {
    const { editData } = this.props;
    if (Object.keys(editData).length !== 0) {
      const durationForArr =
        editData.eventDate !== "" ? editData.eventDate.split("-") : "";
      const date =
        durationForArr !== ""
          ? new Date(
              durationForArr[2],
              durationForArr[1] - 1,
              durationForArr[0]
            )
          : "";

      this.setState({
        ...this.state,
        id: editData.id,
        title: editData.title,
        description: editData.description,
        details: editData.image,
        link: editData.link,
        file: editData.image != null ? editData.image : "",
        date: date,
        insertDate: editData.date,
      });
    }
  }

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
    if (fileName !== "") {
      const formData = new FormData();
      formData.append("file", fileName);
      this.fileSaveData(formData);
    }
  };

  fileSaveData = (formData) => {
    this.setState({
      showLoader: true,
      isDisable: true,
    });
    apiPost({
      url: endpoint.fileUpload,
      postBody: formData,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        this.setState({
          showLoader: false,
          isDisable: false,
          file: data,
        });
      } else {
        this.props.getAlert({
          message: "Failed to upload",
        });
        this.setState({
          showLoader: false,
          isDisable: false,
        });
      }
    });
  };

  onSave = (dataToSave) => {
    if (this.state.file !== "") {
      dataToSave = {
        ...dataToSave,
        image: this.state.file,
      };
    }
    apiPost({
      url: endpoint.newsRoom,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.props.showNotification({
          msg: "Data saved successfully",
        });

        this.props.getNewRoomsList().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  onClickViewPreview = (fileName) => {
    this.props.openFileInNewTab({ file: fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };

  render() {
    const {
      fileName,
      upload,
      documentName,
      uploadError,
      fileNameFlag,
      title,
      description,
      date,
      link,
      isDisable,
    } = this.state;
    const { onCancel } = this.props;
    const { fileNameError, titleError, descriptionError, dateError } =
      this.state.formErrors;

    return (
      <>
        {this.state.showLoader && <Loading1 />}
        {this.state.showForm && (
          <>
            <Paper sx={{ p: 1, borderRadius: 2 }} elevation={0}>
              <TitleAndBreadCrum title="Admin News Room" />
            </Paper>
            <br></br>
            <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 22 }}
                    label=""
                  />
                </Grid>
                <Grid
                  container
                  justifyContent="right"
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <ButtonCompo
                    size="medium"
                    type="button"
                    variant="outlined"
                    name="Back to List"
                    onClick={onCancel}
                  />
                </Grid>
              </Grid>
              <br />
              <form
                onSubmit={this.submitHandler}
                autoComplete="off"
                noValidate={true}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={1}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Title"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="title"
                          size="small"
                          value={title}
                          onChange={this.changeHandler}
                          fullWidth
                          error={titleError ? true : false}
                          helperText={titleError ? titleError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Event Date"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <BasicDatePicker
                          color="primary"
                          name="date"
                          size="small"
                          value={date}
                          fullWidth
                          onChange={this.onDateChange("date")}
                          isError={dateError ? true : false}
                          errorText={dateError ? dateError : " "}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={1}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Description"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="description"
                          size="small"
                          value={description}
                          onChange={this.changeHandler}
                          fullWidth
                          error={descriptionError ? true : false}
                          helperText={descriptionError ? descriptionError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="link"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          color="primary"
                          name="link"
                          size="small"
                          value={link}
                          onChange={this.changeHandler}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={2}
                        lg={2}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="Document Name "
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={1}
                        lg={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          {this.state.file !== "" && (
                            <MyComponentWithIconProps
                              statusImage={VisibilityIcon}
                              color="primary"
                              fontSize="medium"
                              title="View"
                              onClick={(e) =>
                                this.onClickViewPreview(this.state.file)
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={9}
                        lg={9}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <AttachFile
                            name="documentName"
                            fileName={this.changeH}
                          />
                          &nbsp; {fileName !== "" ? fileName.name : ""}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    sx={{ mr: 0.5 }}
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    m={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1}
                      lg={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Cancel"
                        onClick={onCancel}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={1}
                      lg={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save"
                        onClick={this.submitHandler}
                        fullWidth={true}
                        disable={isDisable}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fileData: state.file,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  openFileInNewTab,
  getNewRoomsList,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminAddNews);
