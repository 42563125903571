import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import moment from "moment";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";

const initialState = {
  ematerial: [],
  isFetch: false,
  getAllEmaterial: {},
  isFetchAllEmaterial: false,
  getOtherPatternList: [],
  isFetchOtherPatternList: false,
  getOtherSubjectList: [],
  isFetchOtherSubject: false,
  studentMaterial: [],
  isFetchStudentmaterial: false,
  materialReview: [],
  isFetchMaterialReview: false,
  otherSubject: [],
  isFetchOtherSubject: false,
  subjectListByYearDetail: [],
  isFetchSubjectListByYearDetail: false,
  studentListByEmaterial: [],
  isFetchStudentListByEmaterial: false,
};
let URL = endpoints.ematerial;
let URL1 = endpoints.ematerialReview;
const ematerialSlice = createSlice({
  name: "ematerial",
  initialState,
  reducers: {
    ematerialSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        ematerial: row,
        isFetch: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },

    getAllEmaterialSuccess: (state = cloneDeep(initialState), action) => {
      const { bData } = action.payload;
      return {
        ...state,
        getAllEmaterial: bData,
        isFetchAllEmaterial: true,
      };
    },

    studentMaterialSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentMaterial: row,
        isFetchStudentmaterial: true,
      };
    },

    getOtherPatternListSuccess: (state = cloneDeep(initialState), action) => {
      const { content } = action.payload;
      return {
        ...state,
        getOtherPatternList: content,
        isFetchOtherPatternList: true,
      };
    },

    getOtherSubjectListSuccess: (state = cloneDeep(initialState), action) => {
      const { content } = action.payload;
      return {
        ...state,
        getOtherSubjectList: content,
        isFetchOtherSubjectList: true,
      };
    },

    otherSubjectListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        otherSubject: row,
        isFetchOtherSubject: true,
      };
    },

    materialReviewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        materialReview: row,
        isFetchMaterialReview: true,
      };
    },

    getSubjectListByYearDetailSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        subjectListByYearDetail: row,
        isFetchSubjectListByYearDetail: true,
      };
    },

    getStudentListByEmaterialSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        studentListByEmaterial: row,
        isFetchStudentListByEmaterial: true,
      };
    },
  },
});

export const {
  ematerialSuccess,
  getAllEmaterialSuccess,
  getOtherPatternListSuccess,
  getOtherSubjectListSuccess,
  resetSuccess,
  studentMaterialSuccess,
  materialReviewSuccess,
  otherSubjectListSuccess,
  getSubjectListByYearDetailSuccess,
  getStudentListByEmaterialSuccess,
} = ematerialSlice.actions;

export default ematerialSlice.reducer;

export const getEmaterialId =
  ({ id }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/api/acdmc/v1/ematerial/get-ematerial-by-yearDetail-academic-year?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c
      const response = apiGet({
        url: URL + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let bData = {
            id: content.id,
            subject: content.subject,
            topic: content.topic,
            pattern: content.pattern,
            docUrl: content.docUrl,
            docType: content.docType,
            documentType:
              content.docType == "0"
                ? "Dcument"
                : content.docType == "1"
                ? "Video"
                : "Audio",
            yearDetailId: content.yearDetailId,
            academicYearId: content.academicYearId,
            uploadedBy:
              content.uploadedBy === null
                ? ""
                : content.uploadedBy.firstName +
                  " " +
                  content.uploadedBy.middleName +
                  " " +
                  content.uploadedBy.lastName,
            userRegistaration:
              content.uploadedBy === null ? "" : content.uploadedBy.id,
          };
          dispatch(getAllEmaterialSuccess({ bData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOtherPatternList = () => async (dispatch) => {
  try {
    // http://localhost:8088/api/acdmc/v1/ematerial/get-ematerial-by-yearDetail-academic-year?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c
    const response = apiGet({
      url: URL + "/get-other-pattern-list",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        dispatch(getOtherPatternListSuccess({ content }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOtherSubjectList = () => async (dispatch) => {
  try {
    // http://localhost:8088/api/acdmc/v1/ematerial/get-ematerial-by-yearDetail-academic-year?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c
    const response = apiGet({
      url: URL + "/get-other-subject-list",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let row = [];
        let index = 0;
        content.map((data1) => {
          let bData = {
            id: index,
            name: data1,
          };
          index = index + 1;
          row.push(bData);
          return data1;
        });
        dispatch(otherSubjectListSuccess({ row }));
        dispatch(getOtherSubjectListSuccess({ content }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

//ematerial/get-ematerial-for-teacher?yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c
export const getEmaterialByAcYearAndYearDet =
  ({ yearDetail, subject }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/api/acdmc/v1/ematerial/get-ematerial-by-yearDetail-academic-year?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c
      const response = apiGet({
        url:
          URL +
          "/get-ematerial-for-teacher?yearDetailId=" +
          yearDetail +
          "&subject=" +
          subject,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              subject: data1.subject,
              topic: data1.topic,
              date: data1.date,
              pattern: data1.pattern,
              docUrl: data1.docUrl,
              docType: data1.docType,
              documentType:
                data1.docType == "0"
                  ? "Dcument"
                  : data1.docType == "1"
                  ? "Video"
                  : "Audio",
              uploadedBy:
                data1.uploadedBy === null
                  ? ""
                  : data1.uploadedBy.firstName +
                    " " +
                    data1.uploadedBy.middleName +
                    " " +
                    data1.uploadedBy.lastName,
              userRegistaration:
                data1.uploadedBy === null ? "" : data1.uploadedBy.id,
              flag: data1.flag,
            };
            row.push(bData);
            return data1;
          });
          dispatch(ematerialSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

// Get Ematerial List

export const getEmaterialList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all-ematerial-for-teacher",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            subject: data1.subject,
            topic: data1.topic,
            date: data1.date,
            pattern: data1.pattern,
            docUrl: data1.docUrl,
            docType: data1.docType,
            documentType:
              data1.docType == "0"
                ? "Dcument"
                : data1.docType == "1"
                ? "Video"
                : "Audio",
            uploadedBy:
              data1.uploadedBy === null
                ? ""
                : data1.uploadedBy.firstName +
                  " " +
                  data1.uploadedBy.middleName +
                  " " +
                  data1.uploadedBy.lastName,

            lastName: data1.uploadedBy.lastName,
            firstName: data1.uploadedBy.firstName,
            middleName: data1.uploadedBy.middleName,

            userRegistaration:
              data1.uploadedBy === null ? "" : data1.uploadedBy.id,
            flag: data1.flag,
          };
          row.push(bData);
          return data1;
        });
        dispatch(ematerialSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStudentMaterial = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-ematerial-for-student",
    }).then(({ data, success }) => {
      if (success) {
        const list = data.data;
        let index = 0;
        let row = [];
        list.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            subject: data1.subject,
            topic: data1.topic,
            uploadedBy:
              data1.uploadedBy === null
                ? ""
                : data1.uploadedBy.firstName +
                  " " +
                  data1.uploadedBy.middleName +
                  " " +
                  data1.uploadedBy.lastName,
            downloadUrl: data1.docUrl,
            docType: data1.docType,
          };
          row.push(bData);
          return data1;
        });
        dispatch(studentMaterialSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStudentMaterialByYearDetail =
  ({ yearDetailId, subject }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-year-detail-and-subject?yearDetailId=" +
          yearDetailId +
          "&subject=" +
          subject,
      }).then(({ data, success }) => {
        if (success) {
          const list = data.data;
          let index = 0;
          let row = [];
          list.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              subject: data1.subject,
              topic: data1.topic,
              date: data1.date,
              uploadedBy:
                data1.uploadedBy === null
                  ? ""
                  : data1.uploadedBy.firstName +
                    " " +
                    data1.uploadedBy.middleName +
                    " " +
                    data1.uploadedBy.lastName,
              downloadUrl: data1.docUrl,
              docType: data1.docType,
              flag: data1.flag,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentMaterialSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getMaterialReview =
  ({ ematerialId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/by-ematerial?ematerialId=" + ematerialId,
      }).then(({ data, success }) => {
        if (success) {
          const list = data.data;
          let index = 0;
          let row = [];
          list.map((data1) => {
            const dt = data1.dateTime == null ? "" : data1.dateTime.split(" ");
            const DOBArr = dt == "" ? "" : dt[0].split("-");
            const time =
              dt == "" ? "" : moment(dt[1], "HH:mm:ss").format("hh:mm A");
            const DOB =
              DOBArr === ""
                ? ""
                : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              comment: data1.comment,
              dateTimeFormat:
                moment(DOB).format("dddd, MMMM D, Y") + " " + time,
              dateTime: data1.dateTime,
              name:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                (data1.userRegistration.fatherName == null ||
                data1.userRegistration.fatherName == ""
                  ? " - "
                  : data1.userRegistration.fatherName) +
                " " +
                (data1.userRegistration.motherName == null ||
                data1.userRegistration.motherName == ""
                  ? " - "
                  : data1.userRegistration.motherName),
              showText: false,
              showComment: false,
              ematerialReviewDetail: data1.ematerialReviewDetail.map(
                (data2) => {
                  const dt =
                    data2.dateTime == null ? "" : data2.dateTime.split(" ");
                  const DOBArr = dt == "" ? "" : dt[0].split("-");
                  const time =
                    dt == "" ? "" : moment(dt[1], "HH:mm:ss").format("hh:mm A");
                  const DOB =
                    DOBArr === ""
                      ? ""
                      : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

                  index = index + 1;
                  let bData1 = {
                    index: index,
                    id: data2.id,
                    reply: data2.reply,
                    dateTimeFormat:
                      moment(DOB).format("dddd, MMMM D, Y") + " " + time,
                    dateTime: data2.dateTime,
                    name:
                      data2.userRegistration.lastName +
                      " " +
                      data2.userRegistration.firstName +
                      " " +
                      (data2.userRegistration.fatherName == null ||
                      data2.userRegistration.fatherName == ""
                        ? " - "
                        : data2.userRegistration.fatherName) +
                      " " +
                      (data2.userRegistration.motherName == null ||
                      data2.userRegistration.motherName == ""
                        ? " - "
                        : data2.userRegistration.motherName),
                    showText: false,
                    showComment: false,
                  };
                  return bData1;
                }
              ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(materialReviewSuccess({ row }));
        }
        return { data, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectListByYearDetail =
  ({ yearDetail }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-subject-by-year-detail?yearDetailId=" + yearDetail,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let row = [];
          let index = 0;
          content.map((data1) => {
            index = index + 1;
            let bData = {
              id: data1.id,
              name: data1.subject,
            };

            row.push(bData);
            return data1;
          });
          dispatch(getSubjectListByYearDetailSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByEmaterial =
  ({ ematerial, academicYear }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          "/api/acdmc/v1/ematerial-user-view/get-ematerial-view-count?ematerialId=" +
          ematerial +
          "&academicYearId=" +
          academicYear,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let row = [];
          let index = 0;
          content.map((data1) => {
            index = index + 1;
            let bData = {
              id: data1.id,
              index: index,
              studentName: data1.studentName,
              count: data1.viewCount,
            };
            row.push(bData);
            return data1;
          });
          dispatch(getStudentListByEmaterialSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getMaterialStudentReview =
  ({ ematerialId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/by-ematerial?ematerialId=" + ematerialId,
      }).then(({ data, success }) => {
        if (success) {
          const list = data.data;
          let index = 0;
          let row = [];
          list.map((data1) => {
            const dt = data1.dateTime == null ? "" : data1.dateTime.split(" ");
            const DOBArr = dt == "" ? "" : dt[0].split("-");
            const time =
              dt == "" ? "" : moment(dt[1], "HH:mm:ss").format("hh:mm A");
            const DOB =
              DOBArr === ""
                ? ""
                : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              comment: data1.comment,
              dateTimeFormat:
                moment(DOB).format("dddd, MMMM D, Y") + " " + time,
              dateTime: data1.dateTime,
              name:
                data1.userRegistration.lastName +
                " " +
                data1.userRegistration.firstName +
                " " +
                (data1.userRegistration.fatherName == null ||
                data1.userRegistration.fatherName == ""
                  ? " - "
                  : data1.userRegistration.fatherName) +
                " " +
                (data1.userRegistration.motherName == null ||
                data1.userRegistration.motherName == ""
                  ? " - "
                  : data1.userRegistration.motherName),
              showText: false,
              showComment: false,
              ematerialReviewDetail: data1.ematerialReviewDetail.map(
                (data2) => {
                  const dt1 =
                    data2.dateTime == null ? "" : data2.dateTime.split(" ");
                  const DOBArr = dt1 == "" ? "" : dt1[0].split("-");
                  const time1 =
                    dt1 == ""
                      ? ""
                      : moment(dt1[1], "HH:mm:ss").format("hh:mm A");
                  const DOB =
                    DOBArr === ""
                      ? ""
                      : new Date(DOBArr[2], DOBArr[1] - 1, DOBArr[0]);

                  index = index + 1;
                  let bData1 = {
                    index: index,
                    id: data2.id,
                    reply: data2.reply,
                    dateTimeFormat:
                      moment(DOB).format("dddd, MMMM D, Y") + " " + time1,
                    dateTime: data2.dateTime,
                    name:
                      data2.userRegistration.lastName +
                      " " +
                      data2.userRegistration.firstName +
                      " " +
                      (data2.userRegistration.fatherName == null ||
                      data2.userRegistration.fatherName == ""
                        ? " - "
                        : data2.userRegistration.fatherName) +
                      " " +
                      (data2.userRegistration.motherName == null ||
                      data2.userRegistration.motherName == ""
                        ? " - "
                        : data2.userRegistration.motherName),
                    showText: false,
                    showComment: false,
                  };
                  return bData1;
                }
              ),
            };
            row.push(bData);
            return data1;
          });
          dispatch(materialReviewSuccess({ row }));
        }
        return { success, data };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
