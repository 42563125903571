import {
  Checkbox,
  Divider,
  FormGroup,
  Grid,
  List,
  Paper,
  TextField,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";

class SubjectSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    checkedValues: {},
    programName: "BSC",
    courseBranch: "COMPUTER SCIENCE",
  };

  changeHandler = (event, subName) => {
    const { name } = event.target;
    this.setState((previousState) => {
      const checkedValues = { ...previousState.checkedValues };
      checkedValues[name] = !checkedValues[name];
      return { checkedValues };
    });
  };

  componentDidMount() {
    const { selectedSubject, studentList } = this.props;
    if (studentList.isFetch) {
      this.setDataToTextField(studentList.admissionForm);
    }
    if (selectedSubject !== "") {
      this.setState({
        checkedValues: selectedSubject.checkedValues,
      });
    } else {
      let data = {};
      studentList?.subjectSelection.map((subject) => {
        subject.subjectList.map((subjectD) => {
          if (subjectD.subjectShortName.toLowerCase() === "comp") {
            data = {
              ...data,
              [subjectD.subjectName]: true,
            };
          } else {
            data = {
              ...data,
              [subjectD.subjectName]: false,
            };
          }
        });
      });
      this.setState({
        checkedValues: data,
      });
    }
  }

  setDataToTextField(data) {
    if (data.length !== 0) {
      this.setState({
        programName: data.program,
        courseBranch: data.programTitle,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.studentList !== nextProps.studentList) {
      if (
        this.props.studentList.admissionForm !==
        nextProps.studentList.admissionForm
      ) {
        this.setDataToTextField(nextProps.studentList.admissionForm);
      }
    }
  }

  submitHandler = (e) => {
    e.preventDefault();
    const { id, checkedValues, programName, courseBranch } = this.state;
    const { onSave } = this.props;
    let selectedSubject = {
      id: id,
      checkedValues: checkedValues,
      programName: programName,
      courseBranch: courseBranch,
    };
    onSave(selectedSubject);
  };

  render() {
    const { programName, courseBranch, checkedValues } = this.state;
    const { onClose, studentList } = this.props;
    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <Grid item xs={6} sm={6} md={12}>
              <form
                onSubmit={this.submitHandler}
                autoComplete="off"
                noValidate={true}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  spacing={2}
                  columnSpacing={2}
                  rowSpacing={2}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Subject Selection"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} />

                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Program Name"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={3}>
                    <TextField
                      size="small"
                      color="primary"
                      name="programName"
                      id="programName"
                      value={programName}
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Program Title"
                    />
                  </Grid>

                  <Grid m={-1} item xs={12} sm={12} md={3}>
                    <TextField
                      size="small"
                      color="primary"
                      name="courseBranch"
                      id="courseBranch"
                      value={courseBranch}
                      disabled
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid m={3} item xs={12} sm={12} md={12}>
                    {studentList.subjectSelection.map((value) => {
                      return (
                        <Paper
                          mt={1}
                          sx={{ p: 1, borderRadius: 0 }}
                          elevation={1}
                          variant="outlined"
                          square
                        >
                          <Grid item xs={12} sm={12} md={12} />
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 25 }}
                              label={value.semisterName}
                            />
                            <Grid m={1} item xs={12} sm={12} md={12}>
                              <Divider />
                            </Grid>
                            <List
                              component="nav"
                              aria-label="mailbox folders"
                            />
                            {value.subjectList.map((data) => {
                              if (data.subjectShortName.toLowerCase() == "comp") {
                                return (
                                  <Grid item xs={12} sm={12} md={12}>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name={data.subjectName}
                                            // id={data.id}
                                            disabled={
                                              data.subjectShortName.toLowerCase() === "comp"
                                            }
                                            checked={
                                              Object.keys(checkedValues)
                                                .length !== 0 &&
                                              checkedValues[data.subjectName]
                                            }
                                          />
                                        }
                                        label={data.subjectName}
                                        checked={
                                          Object.keys(checkedValues).length !==
                                            0 && checkedValues[data.subjectName]
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                );
                              }
                            })}

                            {value.subjectType.map((data) => {
                              return (
                                <>
                                  <Grid m={1} item xs={12} sm={12} md={12}>
                                    <Divider />
                                  </Grid>{" "}
                                  <Grid item xs={12} sm={12} md={12}>
                                    <LabelCompo
                                      className="text-black"
                                      style={{ fontSize: 21 }}
                                      label={data.subjectTypeName}
                                    />
                                  </Grid>
                                  {value.subjectList.map((data1) => {
                                    if (data1.subjectTypeId === data.id) {
                                      return (
                                        <Grid item xs={12} sm={12} md={12}>
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  name={data1.subjectName}
                                                  id={data.id}
                                                  disabled={
                                                    data.subjectShortName ===
                                                    "comp"
                                                  }
                                                  checked={
                                                    Object.keys(checkedValues)
                                                      .length !== 0 &&
                                                    checkedValues[
                                                      data.subjectName
                                                    ]
                                                  }
                                                />
                                              }
                                              label={data1.subjectName}
                                              value={
                                                Object.keys(checkedValues)
                                                  .length !== 0 &&
                                                checkedValues[data1.subjectName]
                                              }
                                              onChange={(e) =>
                                                this.changeHandler(
                                                  e,
                                                  data.subjectName
                                                )
                                              }
                                              checked={
                                                Object.keys(checkedValues)
                                                  .length !== 0 &&
                                                checkedValues[data1.subjectName]
                                              }
                                            />
                                          </FormGroup>
                                        </Grid>
                                      );
                                    }
                                  })}
                                </>
                              );
                            })}
                          </Grid>
                        </Paper>
                      );
                    })}
                  </Grid>

                  <Grid m={2} container justifyContent="right" spacing={1}>
                    <Grid item>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="outlined"
                        name="Back"
                        onClick={onClose}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save and Proceed"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  studentList: state.admissionForm,
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(SubjectSelection);
//if(Object.keys(selectedSubject).length !== 0&&selectedSubject.checkedValues[data.subName]){
