import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../../../CommonActions/alert.slice";
import AutoComplete from "../../../../../components/Comman/AutoComplete";
import { LabelCompo } from "../../../../../components/Comman/Label";
import NewLandingScreenHeader from "../../../../../components/Comman/NewLandingScreenHeader";
import RTTable from "../../../../../components/Comman/RT/RTTable";
import DynamicForm from "../../../../../components/dynamicscreens/DynamicForm";
import { courseAttendedJson } from "../../../../../DynamicFormsJson/CourseAttended";
import { CourseAttendedColumns } from "../../../../../tableColumns/table-columns";
import { apiDelete, apiPost } from "../../../../../utils/api_service";
import { showNotification } from "../../../../Landing/Landing.slice";
import LandingScreenHeader from "../../../../../components/Comman/LandingScreenHeader";
import { generatePDF } from "../../../../../components/Comman/GeneratePDF";
import { generateExcel } from "../../../../../components/Comman/GenerateExcel";
import {
  getCourseAttended,
  getCourseAttendedByAcademicYear,
} from "./courseAttended.slice";
import { Loading1 } from "../../../../../components/Loading1";

import { getActivityByCourseAttended } from "../Activity/activity.slice";
// import { Loading1 } from "../Loading1";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class CourseAttended extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      showTable: true,
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      isLoading: false,
      academicYear: "",
      showLoader: false,
      dynamicMasterData: {
        activity: this.props.activityList?.activityByCourseAttended,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activityList !== nextProps.activityList) {
      if (
        this.props.activityList.activityByCourseAttended !==
        nextProps.activityList.activityByCourseAttended
      ) {
        let activityList = [
          { id: "other", name: "Other" },
          ...nextProps.activityList.activityByCourseAttended.filter(
            (data) => data.isActive == 1
          ),
        ];
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            activity: activityList,
          },
        });
      }
    }
  }

  toggleFormTableVisibility = () => {
    const { showForm, showTable } = this.state;
    this.setState({
      showForm: !showForm,
      showTable: !showTable,
      openSnackbar: true,
    });
  };

  onDataChange = (fieldName, newValue) => {
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  onAddButtonClick = () => {
    let fieldData = {};

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Add,
    });
    this.toggleFormTableVisibility();
  };

  rowAdd = (rowData) => {
    this.props.rowData(rowData);
  };
  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    let fieldData = {};

    courseAttendedJson.fieldMeta.forEach((currentField) => {
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey].split(",");
      } else if (currentField.controlType === "timePicker") {
        const timeArr = rowData[tableDataKey]?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          const dateArr = rowData[tableDataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });
    fieldData["id"] = rowData.id;
    fieldData["isActive"] =
      rowData.isActive === "Active" || rowData.isActive === 1 ? 1 : 0;

    this.setState({
      fieldData: fieldData,
      currentOperationMode: OperationMode.Edit,
    });
  };

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getActivityByCourseAttended().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });

    this.props.getCourseAttended().then((response) => {
      if (!response) {
        this.setState({ showLoader: false });

        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({ showLoader: false });
      }
    });
  }

  componentWillMount() {
    const ay = localStorage.getItem("acadamicYearId");
    if (ay) {
      this.props
        .getCourseAttendedByAcademicYear({ academicYearId: ay })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  }

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData.id);
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;

    const dataToSave = {
      isActive:
        currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      formType: 0,
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }

    courseAttendedJson.fieldMeta.forEach((currentField) => {
      if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        dataToSave[currentField.dataKey] = {};
        dataToSave[currentField.dataKey]["id"] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else if (currentField.controlType === "datepicker") {
        let date = new Date(fieldData[currentField.dataKey]);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        fieldData[currentField.dataKey] = day + "-" + month + "-" + year;

        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      } else {
        dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
          ? fieldData[currentField.dataKey]
          : "";
      }
    });
    console.log(dataToSave);
    this.onSave(dataToSave, isToggle);
  };

  onSave = (dataToSave, isToggle = true) => {
    const { apiBaseURL } = this.props;
    this.setState({
      isLoading: true,
    });
    apiPost({
      url: courseAttendedJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        this.props.getCourseAttended().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  onDelete = (id) => {
    this.setState({
      isLoading: true,
    });
    apiDelete({
      url: courseAttendedJson.apiBaseURL + "/" + id,
    }).then(({ success }) => {
      if (success) {
        this.setState({
          isLoading: false,
        });
        this.props.showNotification({ msg: "Data deleted successfully" });
        this.props.getCourseAttended().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });

        this.setState({
          currentOperationMode: "",
        });
      } else {
        this.props.getAlert({
          message: "Failed to delete",
        });
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  generatePDF = () => {
    const { excelFileName = "Course Attended PDF Report" } = this.props;
    const tableData = this.getFilteredTableData();

    let dataToDownload = [];
    let columnToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      CourseAttendedColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });

    CourseAttendedColumns.map((column) => {
      if (column.showInExcel) {
        columnToDownload.push(column.title);
      }
    });
    generatePDF(dataToDownload, columnToDownload, excelFileName);
  };

  generateExcel = () => {
    const { excelFileName = "Course Attended Excel Report" } = this.props;
    const tableData = this.getFilteredTableData();
    let dataToDownload = [];
    tableData.map((tableRow) => {
      let curretRowData = {};
      CourseAttendedColumns.map((column) => {
        if (column.showInExcel && column.name === "isActive") {
          curretRowData[column.title] =
            tableRow[column.name] === 1 ? "Active" : "Inactive";
        } else if (column.showInExcel) {
          curretRowData[column.title] = tableRow[column.name];
        }
      });
      dataToDownload.push(curretRowData);
    });
    generateExcel(dataToDownload, excelFileName);
  };

  getFilteredTableData = () => {
    // tableData = { this.props.courseAttendedList?.courseAttended }
    // import { CourseAttendedColumns } from "../../../../../tableColumns/table-columns";

    const { courseAttendedList } = this.props;
    const { searchValue } = this.state;
    const columsFil = CourseAttendedColumns.filter((item) => {
      return item.canSearch;
    });
    return courseAttendedList?.courseAttended.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
      });
      return isValid;
    });
  };

  render() {
    const {
      showAddButtonInTable = false,
      showExcelDownload = true,
      showAddButton = true,
    } = this.props;
    const {
      tableColumnsToFilter = [],
      showTable,
      showForm,
      fieldData,
      dynamicMasterData,
      academicYear,
    } = this.state;
    return (
      <>
        {this.state.showLoader && <Loading1 />}

        <LandingScreenHeader
          tableColumnsToFilter={[]}
          screenTitle={courseAttendedJson.screenTitle}
          showPdfDownload={courseAttendedJson.showPdfDownload}
          generatePDF={this.generatePDF}
          showExcelDownload={courseAttendedJson.showExcelDownload}
          generateExcel={this.generateExcel}
          showAddButton={showAddButton}
          onAddButtonClick={this.onAddButtonClick}
          onSearch={this.onSearch}
          showTable={showTable}
        />
        <br />
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {/* <NewLandingScreenHeader
            screenTitle={courseAttendedJson.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={showAddButton}
            onAddButtonClick={this.onAddButtonClick}
            showTable={showTable}
          /> */}
          {showForm && (
            <>
              <DynamicForm
                fieldMeta={courseAttendedJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showSaveNextBtn={false}
                onCancel={this.onFormCancel}
                onSave={this.onFormSave(true)}
                onSaveAndNext={this.onFormSave(false)}
              />
            </>
          )}

          {showTable && (
            <>
              <Grid item sx={{ marginTop: 1 }}>
                <RTTable
                  columns={CourseAttendedColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  showAddButtonInTable={showAddButtonInTable}
                  rowEdit={this.rowEdit}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                  rowAdd={this.rowAdd}
                  // rows={this.props.courseAttendedList?.courseAttended}
                  // tableData={this.props.courseAttendedList?.courseAttended}
                  tableData={this.getFilteredTableData()}
                  rows={this.getFilteredTableData()}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  researchProjectList: state.researchProject,
  courseAttendedList: state.courseAttended,
  activityList: state.activity,
});
const mapDispatchToProps = {
  showNotification,
  getAlert,
  getCourseAttended,
  getCourseAttendedByAcademicYear,
  getActivityByCourseAttended,
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseAttended);
