import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TextField } from "@mui/material";
import { getAlert } from "../../CommonActions/alert.slice";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import * as myConstClass from "../../config/messageconstant";
import { LabelCompo } from "../../components/Comman/Label";
import moment from "moment";
import AutoComplete from "../../components/Comman/AutoComplete";
import { apiDelete, apiPost } from "../../utils/api_service";
// import { getStudentList } from "../ViewOfRollList/rollno.slice";
import {
  getYearDetails,
  getDistinctDivision,
  getStudentList,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import {
  getIntrestedStudentList,
  getNotIntrestedStudentList,
} from "./CompanyList.slice";

import { connect } from "react-redux";
import endpoint from "../../config/endpoints";
import { isAsyncThunkAction } from "@reduxjs/toolkit";
import MultipleSelect from "../../components/Comman/MultipleSelect";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class AddIntrestedStudents extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    studUserRegistrationId: [],
    rowListDetails: [],
    formErrors: [],
  };

  componentDidMount() {
    const { data, intrestedStudList, companyPlacementList } = this.props;
    console.log(data);
    let rowList1 = [];
    let studList = [];
    let dataStud2 = this.props.intrestedStudList?.studentList.map((data2) => {
      let rowList1 =
        companyPlacementList?.notInterestedStudentListSuccess.filter(
          (row) => row.userRegistrationId === data2.id
        );

      let rowList2 = companyPlacementList?.interestedStudentListSuccess.filter(
        (row) => row.userRegistrationId === data2.id
      );

      if (rowList1.length === 0 && rowList2.length === 0) {
        studList.push(data2);
      }
    });

    this.setState({
      rowListDetails: studList,
    });
  }

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = (e) => {
    if (this.handleFormValidation()) {
      const { studUserRegistrationId, id } = this.state;
      console.log(studUserRegistrationId);
      e.preventDefault();
      const { getAlert, data } = this.props;
      let row = [];
      studUserRegistrationId.map((data1) => {
        let viewRow = this.props.intrestedStudList?.studentList.filter(
          (row) => row.id === data1
        );
        console.log(viewRow);
        if (viewRow.length !== 0) {
          let docs = {
            internshipDeclare: { id: data.internshipDeclareId },
            companyName: data.companyName,
            companyAdd: data.companyAddress,
            rollNo: "",
            userRegistrationId: data1,
            userRegistrationName: viewRow[0].name,
            selectedStatus: 0,
            // designation: designation,
            // stipend: stipend,
            internshipType: {
              id: data.internshipType,
            },
            vivaStatus: 0,
            approvedStatus: 0,
            intrestStatus: 1,
          };
          row.push(docs);
        }
      });
      console.log(row);

      swal({
        title: "Are you sure?",
        text: "Do you want to Save Information?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.onSave(row);
        }
      });
      //
    }
  };

  onSave = (dataToSave) => {
    apiPost({
      url: endpoint.internshipIntrest + "/save-all",
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        this.handleClose();
        this.props
          .getIntrestedStudentList({ id: this.props.data.internshipDeclareId })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
        this.props
          .getNotIntrestedStudentList({
            id: this.props.data.internshipDeclareId,
          })
          .then((response) => {
            if (!response) {
              this.props.getAlert({ message: "Something went wrong" });
            }
          });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  handleFormValidation() {
    const { studUserRegistrationId } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!studUserRegistrationId.length) {
      formIsValid = false;
      formErrors["studUserRegistrationError"] = myConstClass.studentNameMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  multiSelectHandler = (value, name) => {
    this.setState({
      ...this.state,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { internshipDeclarationList, onClickEdit, intrestedStudList } =
      this.props;
    const { studUserRegistrationId, rowListDetails } = this.state;
    console.log(onClickEdit);
    console.log(intrestedStudList?.studentList);
    const { studUserRegistrationError } = this.state.formErrors;
    return (
      <>
        <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.open}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Add Student
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={2}
                    xs={12}
                    sm={8}
                    md={8}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="*Student Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        name1="studUserRegistrationId"
                        value1={studUserRegistrationId}
                        er={studUserRegistrationError}
                        names={rowListDetails ? rowListDetails : []}
                        onChange={this.multiSelectHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={2}
                    md={2}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label=""
                      />
                    </Grid>
                    <Grid
                      sx={{ marginBottom: 3, marginRight: 3 }}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Save"
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  intrestedStudList: state.internshipDeclaration,
  companyPlacementList: state.companyList,
});
const mapDispatchToProps = {
  getDistinctDivision,
  getAlert,
  getStudentList,
  getIntrestedStudentList,
  getNotIntrestedStudentList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddIntrestedStudents);
