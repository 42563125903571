import { Button } from "@mui/material";

export const ButtonCompo = ({
  disable,
  onClick,
  sx,
  className,
  type,
  variant,
  name,
  fullWidth,
  size,
  shape,
}) => {
  return (
    <Button
      type={type}
      disabled={disable}
      size={size}
      sx={sx}
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      shape={shape}
    >
      {name}
    </Button>
  );
};
ButtonCompo.defaultProps = {
  variant: "contained",
  type: "button",
};
