
import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from '../../../components/dynamicscreens/DynamicMainScreen'
import { UnitTestMasterColumns } from "../../../tableColumns/table-columns";
import { UnitTestMasterJson } from "../../../DynamicFormsJson/UnitTestMaster";
import { getUnitTest } from "./unitTestMaster.slice";
import { getAlert } from "../../../CommonActions/alert.slice";
class UnitTestMaster extends React.Component
{
    constructor( props )
    {
        super( props );
    }

    componentDidMount()
    {
        const { getUnitTest, getAlert } = this.props;
        getUnitTest().then( ( response ) =>
        {
            if ( !response )
            {
                getAlert( { message: "Something went wrong" } );
            }
        } );
    }

    render()
    {

        const { unitTestList } = this.props;
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={UnitTestMasterJson.screenTitle}
                        fieldMeta={UnitTestMasterJson.fieldMeta}
                        showPdfDownload={UnitTestMasterJson.showPdfDownload}
                        showExcelDownload={UnitTestMasterJson.showExcelDownload}
                        pdfFileName={UnitTestMasterJson.pdfFileName}
                        excelFileName={UnitTestMasterJson.excelFileName}
                        showAddButton={UnitTestMasterJson.showAddButton}
                        tableColumnsToFilter={UnitTestMasterJson.tableColumnsToFilter}
                        // dynamicMasterData={dynamicMasterData}
                        tableColumns={UnitTestMasterColumns}
                        tableData={unitTestList?.unitTest}
                        getTableData={this.props.getUnitTest}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={unitTestList.isFetch}
                        apiBaseURL={UnitTestMasterJson.apiBaseURL}
                        baseIdColumn={UnitTestMasterJson.baseIdColumn}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = ( state ) => ( {
    unitTestList: state.unitTestStore
} );
const mapDispatchToProps = {
    getUnitTest,
    getAlert
};
export default connect( mapStateToProps, mapDispatchToProps )( UnitTestMaster );

