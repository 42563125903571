import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { scholarshipTypeMaster } from "../../../../DynamicFormsJson/ScholarshipTypeMaster.js";
import { ScholarshipTypeMasterColumns } from "../../../../tableColumns/table-columns";
import {
  getScholarship
} from "./ScholarshipType.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
class ScholarshipTypeMaster extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        scholarshipMaster: this.props.scholarshipList?.scholarshipList,
      },
    };
  }

  componentDidMount() {
    const { getScholarship } = this.props;
    getScholarship().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  onSave = (dataToSave) => {
    this.props
      .saveScholarship({ scholarshipData: dataToSave })
      .then((response) => {
        this.props.getScholarship().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      });
  };

  render() {
    const { scholarshipList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={scholarshipTypeMaster.screenTitle}
            fieldMeta={scholarshipTypeMaster.fieldMeta}
            showPdfDownload={scholarshipTypeMaster.showPdfDownload}
            showExcelDownload={scholarshipTypeMaster.showExcelDownload}
            pdfFileName={scholarshipTypeMaster.pdfFileName}
            excelFileName={scholarshipTypeMaster.excelFileName}
            showAddButton={scholarshipTypeMaster.showAddButton}
            tableColumnsToFilter={scholarshipTypeMaster.tableColumnsToFilter}
            baseIdColumn={scholarshipTypeMaster.baseIdColumn}
            getTableData={this.props.getScholarship}
            tableColumns={ScholarshipTypeMasterColumns}
            tableData={scholarshipList.scholarshipList}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={scholarshipList.isFetchScholarship}
            apiBaseURL={scholarshipTypeMaster.apiBaseURL}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  scholarshipList: state.scholarship,
});
const mapDispatchToProps = {
  getScholarship, getAlert
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScholarshipTypeMaster);
