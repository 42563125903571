import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import { FilterSave } from "../../../components/FilterSave";
import endpoint from "../../../config/endpoints";
import * as msgConstant from "../../../config/messageconstant";
import endpoints from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { BatchListDynamicJson } from "../../../DynamicFormsJson/BatchListDynamicJson";
import { showNotification } from "../../../Pages/Landing/Landing.slice";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";
import { downloadF } from "../../PDF/PDFDownload";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getBatchByID,
  getStudentListByBatchId,
} from "../BatchList/batchlist.slice";
import GenerationOfBatchesForm from "../GenerationOfBatches/GenerationOfBatchesForm";
import { getBatchList, resetTableListValues } from "./batchlist.slice";
import { resetReducList } from "../../../CommonActions/User.action";
import StudentView from "./StudentView";
class BatchListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      division: "",
      yearDetail: "",
      semisterDetails: "",
      academicYear: "",
      popUpValue: false,
      open: false,
      generateBatch: false,
      showView: false,
      showForm: true,
      formErrors: {},
      studentData: {},
      batchData: {},
      searchValue: "",
      showLoader: false,
      dynamicMasterData: {
        yearDetailMaster: this.props.internshipDeclarationList?.yearDetails,
        semesterDetailMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList?.yearDetails !==
        nextProps.internshipDeclarationList?.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetailMaster: nextProps.internshipDeclarationList?.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList?.distinctDivision !==
        nextProps.internshipDeclarationList?.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList?.distinctDivision,
          },
        });
      }
    }
    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData?.semisterDetailsList !==
        nextProps.rollNoData?.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semesterDetailMaster: nextProps.rollNoData?.semisterDetailsList,
          },
        });
      }
    }
  }

  CancelHandler = () => {
    this.props.resetReducList();
    const { getYearDetails, getAlert, getAcademicYear, resetTableListValues } =
      this.props;
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const divisionId = localStorage.getItem("divisionId");

    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId && divisionId && semisterDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatchList({
          divisionId: divisionId,
          yearId: yearDetailId,
          semisterID: semisterDetailId,
          academicID: ay,
        })
        .then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
            this.setState({
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    this.setState({
      generateBatch: false,
      showForm: true,
      showView: false,
    });
  };

  generateBatches = () => {
    this.props.resetReducList();
    this.setState({
      batchData: {},
      generateBatch: true,
      showForm: false,
      showView: false,
    });
  };

  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      width: 100,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "name",
      title: "Batch Name",
      width: 300,
      positionCenter: false,
      showInscreen: true,
      canSearch: true,
      alignCenter: "left",
    },
    {
      name: "studentCount",
      title: "Number of Students",
      positionCenter: false,
      width: 120,
      showInscreen: true,
      canSearch: true,
      alignCenter: "right",
    },
    {
      name: "isActive",
      title: "Status",
      positionCenter: true,
      width: 100,
      showInscreen: true,

      alignCenter: "center",
    },
    {
      name: "action",
      title: "Action",
      width: 120,
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  onDelete = (rowdata) => {
    const ay = localStorage.getItem("acadamicYearId");
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({
          showLoader: true,
        });
        const { division, yearDetail, semisterDetails, academicYear } =
          this.state;
        const { getBatchList } = this.props;
        apiDelete({
          url: endpoint.batch + "/" + rowdata.id,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({ msg: "Data deleted successfully" });
            getBatchList({
              divisionId: division,
              yearId: yearDetail,
              semisterID: semisterDetails,
              academicID: ay,
            }).then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
                this.setState({
                  showLoader: false,
                });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          } else {
            this.props.getAlert({
              message: "Failed to delete",
            });
          }
        });
      }
    });
  };

  rowEdit = (data) => {
    console.log(data);
    this.setState({
      batchData: data,
      generateBatch: true,
      showForm: false,
      showView: false,
    });
  };

  rowView = (rowData) => {
    console.log(rowData);
    console.log(rowData.id);

    apiGet({
      url: endpoint.yearDetailId + "/" + rowData.yearDetailId,
    }).then(({ data, success }) => {
      rowData = {
        ...rowData,
        yearDetail:
          data.data.year.programType.name +
          " - " +
          data.data.programTitle.brName +
          " - " +
          data.data.year.className,
      };
      this.setState({
        generateBatch: false,
        showForm: false,
        showView: true,
        batchData: rowData,
      });
    });
    this.props
      .getStudentListByBatchId({ batchId: rowData.id })
      .then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
  };

  onChangeStatus = (rowData) => {
    const ay = localStorage.getItem("acadamicYearId");
    const { division, yearDetail, semisterDetails } = this.state;
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        this.setState({
          isLoading: true,
        });
        let activeStatus = rowData.isActive === 1 ? 0 : 1;
        apiGet({
          url:
            endpoint.batch +
            "/update-batch-active-status?id=" +
            rowData.id +
            "&status=" +
            activeStatus,
        }).then(({ success }) => {
          if (success) {
            this.setState({
              showLoader: true,
            });
            this.props.showNotification({ msg: "Status Updated" });
            const { getBatchList } = this.props;

            getBatchList({
              divisionId: division,
              yearId: yearDetail,
              semisterID: semisterDetails,
              academicID: ay,
            }).then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
                this.setState({
                  showLoader: false,
                });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
          }
        });
      }
    });
  };

  componentDidMount() {
    const { getYearDetails, getAlert, getAcademicYear, resetTableListValues } =
      this.props;
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const divisionId = localStorage.getItem("divisionId");

    if (yearDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (yearDetailId && divisionId && semisterDetailId && ay) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getBatchList({
          divisionId: divisionId,
          yearId: yearDetailId,
          semisterID: semisterDetailId,
          academicID: ay,
        })
        .then((response) => {
          if (!response) {
            getAlert({ message: "Something went wrong" });
            this.setState({
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    this.setState({
      showLoader: true,
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.setState({
          showLoader: false,
        });
        getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });

    resetTableListValues();
  }

  handleFormValidation() {
    const { division, yearDetail, semisterDetails, academicYear } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (yearDetail.toString().trim() === "" || yearDetail === null) {
      formIsValid = false;
      formErrors["yearDetailError"] = myConstClass.yearDetailMsg;
    }

    if (semisterDetails.toString().trim() === "" || semisterDetails === null) {
      formIsValid = false;
      formErrors["semisterDetailsError"] = myConstClass.semisterNameMsg;
    }

    if (division.toString().trim() === "" || division === null) {
      formIsValid = false;
      formErrors["divisionError"] = myConstClass.divEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    const { getAlert, getBatchList } = this.props;

    localStorage.setItem("semisterDetailId", data.semister);
    localStorage.setItem("yearDetailId", data.yearDetail);
    localStorage.setItem("divisionId", data.division);
    localStorage.setItem("divisionIds", "");
    localStorage.setItem("acadamicYearId", ay);
    FilterSave();
    this.setState({
      showLoader: true,
    });
    this.setState({
      division: data.division,
      yearDetail: data.yearDetail,
      semisterDetails: data.semister,
    });
    getBatchList({
      divisionId: data.division,
      yearId: data.yearDetail,
      semisterID: data.semister,
      academicID: ay,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
        this.setState({
          showLoader: false,
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  };

  downloadPdf = () => {
    const { division, semisterDetails } = this.state;
    if (this.handleFormValidation()) {
      downloadF({
        // http://localhost:8090/v1/get-batch-report?semisterDetailId=673e30fc-777c-42fb-8fe7-e211f5f1a279&divisionId=d910e283-b645-426e-865e-040440651afc&documentType=1
        url:
          "/api/report/v1/get-batch-report?semisterDetailId=" +
          semisterDetails +
          "&divisionId=" +
          division +
          "&documentType=1",
        ext: "pdf",
        openNewTab: true,
      });
    }
  };

  downloadExcel = () => {
    const { division, semisterDetails } = this.state;
    if (this.handleFormValidation()) {
      downloadF({
        // v1/batch-report?response=&semisterDetailId=673e30fc-777c-42fb-8fe7-e211f5f1a279&divisionId=d910e283-b645-426e-865e-040440651afc
        url:
          "/api/exportexcel/v1/batch-report?response=&semisterDetailId=" +
          semisterDetails +
          "&divisionId=" +
          division,
        ext: "xls",
        openNewTab: true,
      });
    }
  };

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.batchList?.batchList.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["studentCount"] &&
          currentRow["studentCount"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (value) => {
    this.setState({
      searchValue: value,
    });
  };
  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    if (data.yearDetail) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getDistinctDivision({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };
  render() {
    const {
      division,
      yearDetail,
      semisterDetails,
      showForm,
      generateBatch,
      academicYear,
      batchData,
      showView,
    } = this.state;
    const {
      divisionError,
      yearDetailError,
      semisterDetailsError,
      academicYearError,
    } = this.state.formErrors;
    const {
      internshipDeclarationList,
      academicYearList,
      rollNoData,
      batchList,
    } = this.props;
    console.log(batchList?.batchList);
    return (
      <>
        {showForm && (
          <>
            <DynamicMainScreen
              onAddButtonClick={this.generateBatches}
              screenTitle={BatchListDynamicJson.screenTitle}
              fieldMeta={BatchListDynamicJson.fieldMeta}
              buttonCenter={BatchListDynamicJson.buttonCenter}
              showPdfDownload={false}
              showLoader={this.state.showLoader}
              showExcelDownload={false}
              tableColumnsToFilter={BatchListDynamicJson.tableColumnsToFilter}
              showAddButton={true}
              dynamicMasterData={this.state.dynamicMasterData}
              tableColumns={this.columns}
              getListById={this.getListById}
              tableData={this.props.batchList?.batchList}
              showPegination={false}
              showHeadEdit={true}
              showHeadDelete={true}
              showView={true}
              rowStatus={this.onChangeStatus}
              downLoadPdfFromApi={true}
              pdfDownload={this.downloadPdf}
              rowEdit={this.rowEdit}
              rowDelete={this.onDelete}
              rowViewData={this.rowView}
              // onDelete={this.onDelete}
              onSearchData={this.submitHandler}
              baseIdColumn={BatchListDynamicJson.baseIdColumn}
              apiBaseURL={BatchListDynamicJson.apiBaseURL}
            />
          </>
        )}
        <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
          {showView && (
            <StudentView
              CancelHandler={this.CancelHandler}
              studentData={batchList?.studentData}
              batchListData={batchData}
            />
          )}

          {generateBatch && (
            <>
              <GenerationOfBatchesForm
                CancelHandler={this.CancelHandler}
                batchData={batchData}
                division={division}
                semisterDetails={semisterDetails}
                yearDetail={yearDetail}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  rollNoData: state.rollNo,
  batchList: state.batch,
});
const mapDispatchToProps = {
  getYearDetails,
  getDistinctDivision,
  getBatchList,
  resetTableListValues,
  getSemesterDetailsByYearAndAcademic,
  showNotification,
  getAlert,
  resetReducList,
  getBatchByID,
  getStudentListByBatchId,
};
export default connect(mapStateToProps, mapDispatchToProps)(BatchListForm);
