import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EmaterialStudentList({
  open,
  handleClose,
  ematerialStudentList,
}) {
  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "studentName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "count",
      title: "Count",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "40%",
    },
  ];

  const columnExtensions = [{ columnName: "index", width: 100 }];
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Total number of student : {ematerialStudentList.length}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <DynamicTable
              data={ematerialStudentList}
              tableHead={columns}
              showPegination={false}
              isActionColActive={false}
            />
            <br />
          </DialogContent>
        </BootstrapDialog>
      </Paper>{" "}
    </>
  );
}
