import endpoint from "../config/endpoints";

export const adminPaperPosterJson = {
  apiBaseURL: endpoint.resourcePerson,
  screenTitle: "Paper Poster Presented",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Paper Poster Presented",
  showExcelDownload: true,
  excelFileName: "Paper Poster Presented",
  baseIdColumn: "id",
  DocumentUpload: "employeePublicationDocument",
  dataSetToAdd: [
    {
      label: "Department",
      value: "departmentId",
      masterName: "departmentMaster",
    },
    {
      label: "Teacher",
      value: "userRegistration",
      masterName: "teacherMaster",
      isRootLevelKey: true,
    },
  ],
  searchList: [
    {
      label: "Department",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "departmentMaster",
      dataKey: "departmentId",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "departmentId",
    },
    {
      label: "Teacher",
      controlType: "autocomplete",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "teacherMaster",
      dataKey: "userRegistration",
      isRootLevelKey: false,
      isMandatory: true,
      getListFrom: "departmentId",
    },
  ],
  fieldMeta: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "typeMaster",
      dataKey: "pressTitle",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Details of conference",
      controlType: "textfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "publication",
      isMandatory: true,
    },
    // {
    //   label: "Level",
    //   controlType: "textfield",
    //   placeHolder: "International/National/State/University",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "level",
    //   isMandatory: true,
    // },
    {
      label: "Level",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "LevelMaster",
      dataKey: "level",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "LevelMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "levelData",
      tableDataKey: "level",
      open: "other",
      error: "Level",
    },
  ],
};
