import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from '../../../../../config/endpoints';
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
    notice: [],
    isFetch: false,
    noticeByDate: [],
    isFetchNoticeByDate: false,
    noticeByEvent: [],
    isFetchNoticeByEvent: false,
};
let URL = endpoints.employeeNotice;
const noticeSlice = createSlice( {
    name: "notice",
    initialState,
    reducers: {
        noticeSuccess: ( state = cloneDeep( initialState ), action ) =>
        {
            const { row } = action.payload;
            return {
                ...state,
                notice: row,
                isFetch: true,
            };
        },
        noticeByDateSuccess: ( state = cloneDeep( initialState ), action ) =>
        {
            const { row } = action.payload;
            return {
                ...state,
                noticeByDate: row,
                isFetchNoticeByDate: true,
            };
        },
        noticeByEventSuccess: ( state = cloneDeep( initialState ), action ) =>
        {
            const { row } = action.payload;
            return {
                ...state,
                noticeByEvent: row,
                isFetchNoticeByEvent: true,
            };
        },
        resetSuccess: ( state = cloneDeep( initialState ), action ) =>
        {
            return {
                ...initialState,
            };
        },

    },
} );

export const {
    noticeSuccess,
    noticeByDateSuccess,
    resetSuccess,
    noticeByEventSuccess
} = noticeSlice.actions;

export default noticeSlice.reducer;

export const getNotice = () => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL + "/for-employee"
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const content = data.data
                let index = 0;
                let row = [];
                content.map( ( data1 ) =>
                {
                    index = index + 1
                    let bData = {
                        index: index,
                        id: data1.id,
                        title: data1.title,
                        noticeFor: data1.noticeFor,
                        fromDate: data1.fromDate,
                        toDate: data1.toDate,
                        labelText: data1.labelText,
                        highlightLabel: data1.highlightLabel,
                        isActive: data1.isActive,
                        departmentIds: data1.departmentIds,
                        description: data1.description,
                        documentUpload:data1.documentUpload? data1.documentUpload :""
                    };
                    row.push( bData );
                    return data1;
                } );
                dispatch( noticeSuccess( { row } ) );
            }
            return success
        } );
        return response
    } catch ( e )
    {

        return console.error( e.message );
    }
};
export const setNoticeData = (row) => async ( dispatch ) =>
{
                dispatch( noticeSuccess( { row } ) );
     
};
export const getNoticeFromEventId = ({id}) => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL + "/"+id+"/by-event-id"
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const content = data.data
                let index = 0;
                let row = [];
                content.map( ( data1 ) =>
                {
                    index = index + 1
                    let bData = {
                        index: index,
                        id: data1.id,
                        title: data1.title,
                        noticeFor: data1.noticeFor,
                        fromDate: data1.fromDate,
                        toDate: data1.toDate,
                        labelText: data1.labelText,
                        highlightLabel: data1.highlightLabel,
                        isActive: data1.isActive,
                        departmentIds: data1.departmentIds,
                        description: data1.description,
                        documentUpload:data1.documentUpload? data1.documentUpload :""
                    };
                    row.push( bData );
                    return data1;
                } );
                dispatch( noticeByEventSuccess( { row } ) );
            }
            return success
        } );
        return response
    } catch ( e )
    {

        return console.error( e.message );
    }
};

export const getNoticeFromDtToDt = ( { fromDate, toDate } ) => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL + "/by-date?fromDate=" + fromDate + "&toDate=" + toDate
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const content = data.data
                let index = 0;
                let row = [];
                content.map( ( data1 ) =>
                {
                    index = index + 1
                    let bData = {
                        index: index,
                        id: data1.id,
                        title: data1.title,
                        noticeFor: data1.noticeFor,
                        fromDate: data1.fromDate,
                        toDate: data1.toDate,
                        labelText: data1.labelText,
                        highlightLabel: data1.highlightLabel,
                        isActive: data1.isActive,
                        fromTo: data1.fromDate + " -" + data1.toDate,
                        departmentIds: data1.departmentIds,
                        eventDetails: data1.eventDetails,
                        description: data1.description,
                        documentUpload:data1.documentUpload? data1.documentUpload :""
                    };
                    row.push( bData );
                    return data1;
                } );
                dispatch( noticeByDateSuccess( { row } ) );
            }
            return success
        } );
        return response
    } catch ( e )
    {

        return console.error( e.message );
    }
};

export const getNoticeList = () => async ( dispatch ) =>
{
    try
    {
        const response = apiGet( {
            url: URL+'?offset=0&limit=20'
        } ).then( ( { data, success } ) =>
        {
            if ( success )
            {
                const content = data.data.content
                let index = 0;
                let row = [];
                content.map( ( data1 ) =>
                {
                    index = index + 1
                    let bData = {
                        index: index,
                        id: data1.id,
                        title: data1.title,
                        noticeFor: data1.noticeFor,
                        fromDate: data1.fromDate,
                        toDate: data1.toDate,
                        labelText: data1.labelText,
                        highlightLabel: data1.highlightLabel,
                        isActive: data1.isActive,
                        fromTo: data1.fromDate + " -" + data1.toDate,
                        departmentIds: data1.departmentIds,
                        eventDetails: data1.eventDetails,
                        description: data1.description,
                        documentUpload:data1.documentUpload
                    };
                    row.push( bData );
                    return data1;
                } );
                dispatch( noticeByDateSuccess( { row } ) );
            }
            return success
        } );
        return response
    } catch ( e )
    {

        return console.error( e.message );
    }
};

export const resetValues = () => async ( dispatch ) =>
{
    try
    {
        dispatch( resetSuccess() );
    } catch ( e )
    {
        return console.error( e.message );
    }
};
