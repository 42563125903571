import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../../config/endpoints";
import { apiGet } from "../../../../../utils/api_service";

const initialState = {
  resourcePerson: [],
  isFetch: false,

  paperPosterPresented: [],
  isFetchPaperPoster: false,
};
let URL = endpoints.resourcePerson;

const resourcePersonSlice = createSlice({
  name: "resourcePerson",
  initialState,
  reducers: {
    resourcePersonSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        resourcePerson: row,
        isFetch: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
    paperPosterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paperPosterPresented: row,
        isFetchPaperPoster: true,
      };
    },
  },
});

export const { resourcePersonSuccess, paperPosterSuccess, resetSuccess } =
  resourcePersonSlice.actions;

export default resourcePersonSlice.reducer;

export const getResourcePerson = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            confVenue: data1.confVenue,
            pressDate: data1.pressDate,
            level: data1.level,
            pressTitle: data1.pressTitle,
            pressDate: data1.pressDate,
            publication: data1.publication,
            type: data1.type === null ? "" : data1.type,
          };
          row.push(bData);
          return data1;
        });
        dispatch(resourcePersonSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getResourcePersonByType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL +
        "/all" +
        '?filters={"formType":"' +
        "1" +
        '","_mode" : "EXACT"}&&sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            pressTitle: data1.pressTitle,
            confVenue: data1.confVenue,
            pressDate: data1.pressDate,
            pressDate: data1.pressDate,
            publication: data1.publication,
            level: data1.level,
            type: data1.type === null ? "" : data1.type,
            employeePublicationDocument: data1.employeePublicationDocument.map(
              (folder, index) => {
                folder = {
                  index: index + 1,
                  ...folder,
                };
                return folder;
              }
            ),
          };
          row.push(bData);
          return data1;
        });
        dispatch(resourcePersonSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

//
export const getAdminResourcePersonByType =
  ({ userRegistration }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-user-registration?userRegistrationId=" +
          userRegistration +
          "&formType=1",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              pressTitle: data1.pressTitle,
              confVenue: data1.confVenue,
              pressDate: data1.pressDate,
              pressDate: data1.pressDate,
              publication: data1.publication,
              level: data1.level,
              type: data1.type === null ? "" : data1.type,
              employeePublicationDocument:
                data1.employeePublicationDocument.map((folder, index) => {
                  folder = {
                    index: index + 1,
                    ...folder,
                  };
                  return folder;
                }),
            };
            row.push(bData);
            return data1;
          });
          dispatch(resourcePersonSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetData = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPublicationByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-publication-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          '?filters={"formType":"' +
          "1" +
          '","_mode" : "EXACT"}',
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              confVenue: data1.confVenue,
              level: data1.level,
              pressTitle: data1.pressTitle,
              pressDate: data1.pressDate,
              publication: data1.publication,
              type: data1.type === null ? "" : data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(resourcePersonSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setPublicationData = (row) => async (dispatch) => {
  dispatch(resourcePersonSuccess({ row }));
};

export const getResourcePerByType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"formType":"' + "0" + '","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            pressTitle: data1.pressTitle,
            publication: data1.publication,
            pressDate: data1.pressDate,
            confVenue: data1.confVenue,
            level: data1.level,
            type: data1.type === null ? "" : data1.type,
          };
          row.push(bData);
          return data1;
        });
        dispatch(paperPosterSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

//

export const getResourcePersonByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-employee-publication-list-by-fromDate-toDate?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&formType=0",
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;
          let row = [];
          content.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              pressTitle: data1.pressTitle,
              publication: data1.publication,
              pressDate: data1.pressDate,
              confVenue: data1.confVenue,
              level: data1.level,
              type: data1.type === null ? "" : data1.type,
            };
            row.push(bData);
            return data1;
          });
          dispatch(paperPosterSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getResourcePersonWithoutDate = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-employee-publication-list-by-limit?formType=0&limit=20",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            pressTitle: data1.pressTitle,
            publication: data1.publication,
            pressDate: data1.pressDate,
            confVenue: data1.confVenue,
            level: data1.level,
            type: data1.type === null ? "" : data1.type,
          };
          row.push(bData);
          return data1;
        });
        dispatch(paperPosterSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setResourcePerson = (row) => async (dispatch) => {
  dispatch(paperPosterSuccess({ row }));
};
