import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  batchList: [],
  batchListBySemester: [],
  isFetchBatchListBySemester: false,
  isFetchBatchList: false,
  studentData: [],
  isFetchStudentData: false,
  batchData: [],
  isFetchBatchData: false,
  teacher: [],
  isFetchTeacher: false,
  batchListSemesterDiv: [],
  isFetchBatchListSemesterDiv: false,
  studentDataOrderBy: [],
  isFetchStudentDataOrderBy: false,
};
let URL = endpoints.batch;
let URL1 = endpoints.employeeWorkload;

const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    batchListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        batchList: row,
        isFetchBatchList: true,
      };
    },

    batchListBySemesterDivSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        batchListSemesterDiv: row,
        isFetchBatchListSemesterDiv: true,
      };
    },

    teacherListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        teacher: row,
        isFetchTeacher: true,
      };
    },
    batchListBySemesterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        batchListBySemester: row,
        isFetchBatchListBySemester: true,
      };
    },

    batchDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        batchData: row,
        isFetchBatchData: true,
      };
    },

    studentDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentData: row,
        isFetchStudentData: true,
      };
    },

    studentDataOrderBySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        studentDataOrderBy: row,
        isFetchStudentDataOrderBy: true,
      };
    },

    resetTableListSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  batchListSuccess,
  batchListBySemesterSuccess,
  batchDataSuccess,
  studentDataSuccess,
  resetTableListSuccess,
  teacherListSuccess,
  studentDataOrderBySuccess,
  batchListBySemesterDivSuccess,
} = batchSlice.actions;

export default batchSlice.reducer;

export const getBatchList =
  ({ academicID, yearId, semisterID, divisionId }) =>
  async (dispatch) => {
    try {
      console.log(academicID);
      console.log(yearId);
      console.log(divisionId);
      console.log(semisterID);
      const response = apiGet({
        url:
          URL +
          "/get-batch-list-by-academic-year-semister-detail-year-detail-division?academicYearId=" +
          academicID +
          "&yearDetailId=" +
          yearId +
          "&semisterDetailId=" +
          semisterID +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const batchList = data.data;
          console.log(batchList);
          let index = 0;
          let row = [];
          batchList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.batchName,
              studentCount: data1.studentCount,
              shortName: data1.shortName,
              isActive: data1.isActive,
              divisionId: data1.division.id,
              division: data1.division.name,
              semisterId: data1.semisterDetail.id,
              semisterName: data1.semisterDetail.semister.name,
              reportingDetailId: data1.reportingDetailId,
              yearDetailId: data1.division.yearDetailId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(batchListSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getBatchListBySemesterDivision =
  ({ semisterID, divisionId }) =>
  async (dispatch) => {
    try {
      console.log(divisionId);
      console.log(semisterID);
      const response = apiGet({
        url:
          URL +
          "/get-batch-list-by-semister-detail-division?semisterDetailId=" +
          semisterID +
          "&divisionId=" +
          divisionId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const batchList = data.data;
          console.log(batchList);
          let index = 0;
          let row = [];
          batchList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              name: data1.batchName,
              studentCount: data1.studentCount,
              shortName: data1.shortName,
              isActive: data1.isActive,
              divisionId: data1.division.id,
              division: data1.division.name,
              semisterId: data1.semisterDetail.id,
              semisterName: data1.semisterDetail.semister.name,
              reportingDetailId: data1.reportingDetailId,
              yearDetailId: data1.division.yearDetailId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(batchListBySemesterDivSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getBatchBySemesterList =
  ({ semisterID }) =>
  async (dispatch) => {
    try {
      console.log(semisterID);
      const response = apiGet({
        url: URL + "/by-semister-detail?semisterDetailId=" + semisterID,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const batchList = data.data;
          console.log(batchList);
          let index = 0;
          let row = [];
          batchList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              studentCount: data1.studentCount,
              name: data1.batchName,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(batchListBySemesterSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getBatchByID =
  ({ batchId }) =>
  async (dispatch) => {
    try {
      console.log(batchId);
      const response = apiGet({
        url: URL + "/" + batchId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const batchList = data.data;
          console.log(batchList);
          let index = 0;
          let row = [];
          let bdata = {
            id: batchList.id,
            divisionId: batchList.division.id,
          };
          row.push(bdata);
          // batchList.map( ( data1 ) =>
          // {
          //   index = index + 1;
          //   let bData = {
          //     index: index,
          //     id: data1.id,
          //     studentCount: data1.studentCount,
          //     batchName: data1.batchName,
          //     isActive: data1.isActive,
          //   };
          //   row.push( bData );
          //   return data1;
          // } );
          dispatch(batchDataSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getStudentListByBatchId =
  ({ batchId }) =>
  async (dispatch) => {
    try {
      console.log(batchId);
      const response = apiGet({
        url: URL + "/get-student-list-by-batch-id?batchId=" + batchId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const studentList = data.data;
          console.log(studentList);
          let index = 0;
          let row = [];
          studentList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              rollNo: data1.rollNo,
              lastName: data1.lastName,
              firstName: data1.firstName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              studentName:
                data1.lastName +
                " " +
                data1.firstName +
                " " +
                (data1.fatherName == null || data1.fatherName == ""
                  ? " - "
                  : data1.fatherName) +
                (data1.motherName == null || data1.motherName == ""
                  ? " - "
                  : data1.motherName),
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              dateOfReporting: data1.dateOfReporting,
              prnNo: data1.prnNo,
              divisionId: data1.divisionId,
              userRegistrationId: data1.userRegistrationId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentDataSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentListByBatchIdOrderBy =
  ({ batchId }) =>
  async (dispatch) => {
    try {
      console.log(batchId);
      const response = apiGet({
        url: URL + "/get-student-list-by-batch-id-by-order?batchId=" + batchId,
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const studentList = data.data;
          console.log(studentList);
          let index = 0;
          let row = [];
          studentList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              rollNo: data1.rollNo,
              lastName: data1.lastName,
              firstName: data1.firstName,
              fatherName: data1.fatherName,
              motherName: data1.motherName,
              middleName: data1.middleName,
              studentName:
                data1.lastName +
                " " +
                data1.firstName +
                " " +
                (data1.fatherName == null || data1.fatherName == ""
                  ? " - "
                  : data1.fatherName) +
                (data1.motherName == null || data1.motherName == ""
                  ? " - "
                  : data1.motherName),
              admissionRegistrationDetailId:
                data1.admissionRegistrationDetailId,
              dateOfReporting: data1.dateOfReporting,
              prnNo: data1.prnNo,
              divisionId: data1.divisionId,
              userRegistrationId: data1.userRegistrationId,
            };
            row.push(bData);
            return data1;
          });
          dispatch(studentDataOrderBySuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetTableListValues = () => async (dispatch) => {
  try {
    dispatch(resetTableListSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTeacherByBatchId =
  ({ batchId }) =>
  async (dispatch) => {
    try {
      console.log(batchId);
      const response = apiGet({
        url: URL1 + "/" + batchId + "/get-by-batch-id",
      }).then(({ data, success }) => {
        console.log(data);
        if (success) {
          const studentList = data.data;
          console.log(studentList);
          let index = 0;
          let row = [];
          studentList.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.userRegistration.id,
              lastName:
                data1.userRegistration === null
                  ? " "
                  : data1.userRegistration.lastName,
              firstName:
                data1.userRegistration === null
                  ? " "
                  : data1.userRegistration.firstName,
              middleName:
                data1.userRegistration === null
                  ? " "
                  : data1.userRegistration.middleName,
              name:
                data1.userRegistration === null
                  ? " "
                  : data1.userRegistration.firstName +
                    " " +
                    data1.userRegistration.middleName +
                    " " +
                    data1.userRegistration.lastName,
            };
            row.push(bData);
            return data1;
          });
          dispatch(teacherListSuccess({ row }));
          return row;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
