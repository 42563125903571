import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { semisterDetailsMasterJson } from "../../../../DynamicFormsJson/SemisterDetails.js";
import { semisterInfoMasterJson } from "../../../../DynamicFormsJson/SemisterInfo";
import { SemisterInfoColumns } from "../../../../tableColumns/table-columns";
import {
  getSemesterInfoByYD,
  getSemisterDetailsBySemisterInfoId,
} from "./semisterInfo.slice";
import { getClass } from "../../OnlineModule/Class/class.slice";
import SemisterDetails from "./SemisterDetails";
import { FilterSave } from "../../../../components/FilterSave";

import { SemisterDetailsColumns } from "../../../../tableColumns/table-columns";
import { getAcademicYear } from "../../OnlineModule/AcademicYear/academicYear.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
class SemisterInfo extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        // programMaster: this.props.programMaster?.program,
        yearDetail: this.props.classList?.class,
      },
      semisterInfoId: "",
      semisterDetails: false,
    };
  }

  componentDidMount() {
    const { getClass } = this.props;
    const year = localStorage.getItem("yearDetailId");
    if (year) {
      this.props.getSemesterInfoByYD({ yearDetail: year }).then((response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      });
    }

    getClass().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.classList !== nextProps.classList) {
      if (this.props.classList.class !== nextProps.classList.class) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.classList.class,
          },
        });
      }
    }

    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYearData: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
  }

  rowData = (rowData) => {
    const { getSemisterDetailsBySemisterInfoId } = this.props;

    getSemisterDetailsBySemisterInfoId({ semisterId: rowData.id }).then(
      (response) => {
        if (!response) {
          this.props.getAlert({ message: "Something went wrong" });
        }
      }
    );

    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });

    this.setState({
      dynamicMasterData: {
        ...this.state.dynamicMasterData,
        academicYearData: this.props.academicYearList?.academicYear,
      },
    });
    this.setState({
      semisterDetails: true,
      semisterInfoId: rowData.id,
    });
  };
  onCancel = () => {
    this.setState({
      semisterDetails: false,
      semisterInfoId: "",
    });
  };
  onSearchData = (data) => {
    if (data.yearDetail) {
      localStorage.setItem("semisterDetailId", "");
      localStorage.setItem("yearDetailId", data.yearDetail);
      localStorage.setItem("divisionId", "");
      localStorage.setItem("divisionIds", "");
      FilterSave();
      this.props
        .getSemesterInfoByYD({ yearDetail: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    console.log(data);
  };
  render() {
    const { dynamicMasterData, semisterDetails, semisterInfoId } = this.state;
    const { semisterInfoList } = this.props;
    return (
      <>
        <div>
          {!semisterDetails && (
            <DynamicMainScreen
              screenTitle={semisterInfoMasterJson.screenTitle}
              fieldMeta={semisterInfoMasterJson.fieldMeta}
              showPdfDownload={semisterInfoMasterJson.showPdfDownload}
              showExcelDownload={semisterInfoMasterJson.showExcelDownload}
              pdfFileName={semisterInfoMasterJson.pdfFileName}
              excelFileName={semisterInfoMasterJson.excelFileName}
              showAddButton={semisterInfoMasterJson.showAddButton}
              tableColumnsToFilter={semisterInfoMasterJson.tableColumnsToFilter}
              baseIdColumn={semisterInfoMasterJson.baseIdColumn}
              dynamicMasterData={dynamicMasterData}
              getTableDataById={true}
              onSearchData={this.onSearchData}
              searchList={semisterInfoMasterJson.searchList}
              getTableData={this.props.getSemesterInfoByYD}
              tableColumns={SemisterInfoColumns}
              tableData={semisterInfoList.semister}
              onDelete={this.onDelete}
              onSave={this.onSave}
              showLoader={semisterInfoList.isFetch ? true : true}
              apiBaseURL={semisterInfoMasterJson.apiBaseURL}
              showAddButtonInTable={true}
              rowData={this.rowData}
            />
          )}
          {semisterDetails && (
            <SemisterDetails
              screenTitle={semisterDetailsMasterJson.screenTitle}
              fieldMeta={semisterDetailsMasterJson.fieldMeta}
              showPdfDownload={semisterDetailsMasterJson.showPdfDownload}
              showExcelDownload={semisterDetailsMasterJson.showExcelDownload}
              pdfFileName={semisterDetailsMasterJson.pdfFileName}
              excelFileName={semisterDetailsMasterJson.excelFileName}
              showAddButton={semisterDetailsMasterJson.showAddButton}
              tableColumnsToFilter={
                semisterDetailsMasterJson.tableColumnsToFilter
              }
              dynamicMasterData={dynamicMasterData}
              showSaveNextBtn={semisterDetailsMasterJson.showSaveNextBtn}
              // getTableData={this.props.getSemisterDetailsBySemisterInfoId({semisterId:semisterInfoId})}
              tableColumns={SemisterDetailsColumns}
              tableData={semisterInfoList.semisterDetails}
              onDelete={this.onDelete}
              onSave={this.onSave}
              showLoader={semisterInfoList.isFetchSemisterDetail}
              baseIdColumn={semisterDetailsMasterJson.baseIdColumn}
              apiBaseURL={semisterDetailsMasterJson.apiBaseURL}
              semisterInfoId={semisterInfoId}
              onCancel={this.onCancel}
            />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  semisterInfoList: state.semister,
  classList: state.class,
  academicYearList: state.academicYear,
});
const mapDispatchToProps = {
  getSemesterInfoByYD,
  getAcademicYear,
  getClass,
  getSemisterDetailsBySemisterInfoId,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(SemisterInfo);
