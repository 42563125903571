import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MessageIcon from "@mui/icons-material/Message";
import endpoints from "../../config/endpoints";
import PercentIcon from "@mui/icons-material/Percent";
import { Alert, Avatar, Grid, Paper, Stack, Switch } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { onToggle } from "../../CommonActions/common.slice";
// import BarChart from "../../components/Charts/BarChart";
import AutoComplete from "../../components/Comman/AutoComplete";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis } from "recharts";
import user1 from "../../Images/serverFailure.jpg";
import news from "../../Images/news.png";
import { Loading1 } from "../../components/Loading1";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import ShareIcon from "@mui/icons-material/Share";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import GroupsIcon from "@mui/icons-material/Groups";
import FeedIcon from "@mui/icons-material/Feed";
import certificatePic from "../../Images/certificate-pic.jpg";
import certificate from "../../Images/certificate.png";
import studyImg from "../../Images/studyImage.png";
import trophy from "../../Images/trophy.png";
import user from "../../Images/user.jpeg";
import serverFailure from "../../Images/serverFailure.jpg";
import { getYears } from "../ApplicantAdmission/applicantAdmission.slice";
import {
  getProfile,
  getStudentInfoByPRNNo,
} from "../BasicDetailsPost/basicDetails.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import StudentPreview1 from "../StudentPreviewById/index";
import EventPopUp from "./PopUps/EventPopUp";
import FeedbackPopup from "./PopUps/FeedbackPopup";
import FeesPopup from "./PopUps/FeesPopup";
import { getAlumniDashboardInfo } from "./AlumniDashboard.slice";
import { apiGet } from "../../utils/api_service";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";

const newsroomData = [
  {
    id: "1",
    title: "Amazon",
    date: "18th Oct 2022",
    image: news,
    description:
      "The Paragraphs module in Drupal provides editors with a component driven architecture for building pages. Morpht has been developing Paragraph approaches to site building since 2015.",
  },
  {
    id: "2",
    title: "Amazon",
    date: "18th Oct 2022",
    image: news,
    description:
      "The Paragraphs module in Drupal provides editors with a component driven architecture for building pages. Morpht has been developing Paragraph approaches to site building since 2015.",
  },
];

class AlumniDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showPreview: false,
    showDashBoard: false,
    role: "",
    dob: "",
    curDOB: "",
    name: "",
    birthday: false,
    showLoader: false,
    showFailure: false,
    galleryList: [],
    profile: "",
    nameFirstLetter: "",
  };

  readMore = () => {
    window.location.replace("/alumini-newsroom");
  };

  componentDidMount() {
    let role = localStorage.getItem("role");
    const person = localStorage.getItem("person");
    this.setState({
      nameFirstLetter: person !== null ? person.toUpperCase() : "",
    });
    let profilePic = localStorage.getItem("profilePic");
    if (profilePic !== "null" || profilePic !== "") {
      console.log("profilePic" + profilePic);
      apiGet({
        url: endpoints.fileDownload + "/" + profilePic,
      }).then(({ data, success }) => {
        if (success) {
          let fileRes = data.data;
          this.setState({ profile: fileRes });
        }

        return success;
      });
    } else {
      this.setState({
        profile: "",
      });
    }

    this.setState({
      role: localStorage.getItem("role"),
    });
    const curDOB = new Date();
    let fd = curDOB === "" ? "" : moment(curDOB).format("DD-MM-YYYY");

    this.setState({
      showLoader: true,
    });
    if (role == "alumni") {
      this.props
        .getAlumniDashboardInfo({ alumniRegistrationId: "" })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
              showFailure: true,
            });
          } else {
            this.setState({
              showDashBoard: true,
              showLoader: false,
              showPreview: false,
              showFailure: false,
            });
          }
        });
    }
    this.setState({
      showLoader: false,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.alumniDashboardData.alumniDashboardInfo.latestNews !==
      nextProps.alumniDashboardData.alumniDashboardInfo.latestNews
    ) {
      this.setState({
        galleryList: [],
      });
      nextProps.alumniDashboardData.alumniDashboardInfo.latestNews.map(
        (folderData) => {
          console.log(folderData.image);
          if (folderData.image !== null) {
            apiGet({
              url: endpoint.fileDownload + "/" + folderData.image,
            }).then(({ data, success }) => {
              if (success) {
                folderData = {
                  ...folderData,
                  profile: data.data,
                };
                this.setState({
                  galleryList: [...this.state.galleryList, folderData],
                });
              } else {
                folderData = {
                  ...folderData,
                };
                this.setState({
                  galleryList: [...this.state.galleryList, folderData],
                });
              }
            });
          }
        }
      );
    }
  }
  viewProfile = () => {
    let role = localStorage.getItem("role");
    if (role == "alumni") {
      window.location.replace("/alumini-profile");
    }
  };

  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };

  aluminiAchievements = () => {
    if (this.state.role == "alumni") {
      window.location.replace("/student-fill-profile");
    }
  };

  aluminiInternship = () => {
    if (this.state.role == "alumni") {
      window.location.replace("/alumni-engagement");
    }
  };

  aluminiMentor = () => {
    if (this.state.role == "alumni") {
      window.location.replace("/alumni-engagement");
    }
  };

  aluminiVolunteer = () => {
    if (this.state.role == "alumni") {
      window.location.replace("/alumni-engagement");
    }
  };

  inviteFriends = () => {
    if (this.state.role == "alumni") {
      window.location.replace("/alumini-invite-friends");
    }
  };

  complaintPending = () => {
    if (this.state.role == "alumni") {
      window.location.replace("/complaint");
    }
  };

  aluminiEvents = () => {
    if (this.state.role == "alumni") {
      window.location.replace("/alumini-events");
    }
  };

  libraryColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "title",
      title: "Book Title",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "mediaType",
      title: "Media Type",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "status",
      title: "Status",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "returnDate",
      title: "Return Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
  ];

  render() {
    const { alumniDashboardData } = this.props;
    const { galleryList } = this.state;
    return (
      <>
        {this.state.showDashBoard && (
          <div id="layout-wrapper">
            {/* ========== App Menu ========== */}

            {/* Left Sidebar End */}
            {/* Vertical Overlay*/}
            <div className="vertical-overlay" />
            {/* ============================================================== */}
            {/* Start right Content here */}
            {/* ============================================================== */}
            <div className="main-content">
              <div className="page-content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card">
                        <div className="bg-primary bg-soft">
                          <div className="row">
                            <div className="col-7">
                              <div className="text-primary p-3 ">
                                <h5 className="text-primary mb-1">
                                  Welcome Back !
                                </h5>
                                <p> </p>
                              </div>
                            </div>
                            <div className="col-5 align-self-end">
                              <img
                                src={studyImg}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="profile-user-wid mb-2">
                                {/*avatar-md*/}
                                {this.state.profile !== "" ? (
                                  <Avatar
                                    sx={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                    }}
                                    src={this.state.profile}
                                  ></Avatar>
                                ) : (
                                  <Avatar
                                    sx={{
                                      height: 100,
                                      width: 100,
                                      borderRadius: "50%",
                                      padding: "0.25rem",
                                      bgcolor: "red",
                                      fontSize: 100,
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    {this.state.nameFirstLetter.charAt(0)}
                                  </Avatar>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div class="a mb-3">
                                <h5
                                  style={{ overflow: "visible" }}
                                  class="font-size-15 text-truncate mb-1"
                                >
                                  {
                                    this.props.alumniDashboardData
                                      .alumniDashboardInfo.fullName
                                  }
                                </h5>
                                <p class="text-muted mb-0 text-truncate">
                                  Alumna{" "}
                                </p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {
                                    this.props.alumniDashboardData
                                      .alumniDashboardInfo.email
                                  }
                                </h5>
                                <p class="text-muted">Email Address</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {" "}
                                  {
                                    this.props.alumniDashboardData
                                      .alumniDashboardInfo.mobileNumber
                                  }
                                </h5>
                                <p class="text-muted">Mobile Number</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {" "}
                                  {
                                    this.props.alumniDashboardData
                                      .alumniDashboardInfo.passingYear
                                  }
                                </h5>
                                <p class="text-muted">Passing Year</p>
                              </div>

                              {/* <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {" "}
                                  {
                                    this.props.alumniDashboardData
                               .alumniDashboardInfo.studentDetails
                                      .division
                                  }
                                </h5>
                                <p class="text-muted">Division</p>
                              </div>

                              <div class="a mb-3">
                                <h5 class="font-size-15 mb-1">
                                  {
                                    this.props.alumniDashboardData
                               .alumniDashboardInfo.yearDetails
                                  }{" "}
                                  (
                                  {
                                    this.props.alumniDashboardData
                               .alumniDashboardInfo.studentDetails
                                      .semister
                                  }
                                  )
                                </h5>
                              </div> */}
                              {/* {this.state.role == "alumni" && ( */}
                              <div
                                className="mt-4"
                                style={{ textAlign: "right" }}
                              >
                                <button
                                  onClick={this.viewProfile}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light btn-sm"
                                >
                                  View Profile
                                  <i className="mdi mdi-arrow-right ms-1" />
                                </button>
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Invite Friends
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    {/* $ */}
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    ></span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Invite your friends to be alumnae and become
                                    connected members.
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-success rounded fs-3">
                                    {/* <i className="bx fa fa-user-circle-o text-success" /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={ShareIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.inviteFriends}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Achievements
                              </p>

                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.alumniDashboardData
                                          .alumniDashboardInfo.achievements
                                          .length
                                      }
                                    </span>
                                  </h4>

                                  <small className="d-block mb-0 mn-ht">
                                    Achievements you have shared{" "}
                                    {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.achievements.length
                                    }
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    {/* <i
                                    className="fa fa-trophy text-warning"
                                    aria-hidden="true"
                                  /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={EmojiEventsIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.aluminiAchievements}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Share Opportunities{" "}
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.alumniDashboardData
                                          .alumniDashboardInfo.postAninternship
                                          .length
                                      }
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    You have shared{" "}
                                    {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.postAninternship
                                        .length
                                    }{" "}
                                    opportunities
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    {/* <i
                                    className="fa fa-percent text-primary"
                                    aria-hidden="true"
                                    style={{ fontSize: 18 }}
                                  /> */}
                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={FeedIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.aluminiInternship}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Mentorship
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.alumniDashboardData
                                          .alumniDashboardInfo.mentors.length
                                      }
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total{" "}
                                    {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.mentors.length
                                    }{" "}
                                    times you have mentored
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-warning rounded fs-3">
                                    {/* <i
                                    className="fa fa-calendar text-warning"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={CalendarMonthOutlinedIcon}
                                      color="primary"
                                      fontSize="small"
                                      onClick={this.aluminiMentor}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                {" "}
                                Volunteer
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.alumniDashboardData
                                          .alumniDashboardInfo.volunteers.length
                                      }
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    Total{" "}
                                    {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.volunteers.length
                                    }{" "}
                                    times you volunteered
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-primary rounded fs-3">
                                    {/* <i
                                    className="fa fa-comments-o text-primary"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={EmojiPeopleIcon}
                                      color="primary"
                                      fontSize="medium"
                                      onClick={this.aluminiVolunteer}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card card-animate">
                            <div className="card-body">
                              <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                Members Connected
                              </p>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                    <span
                                      className="counter-value"
                                      data-target="559.25"
                                    >
                                      {
                                        this.props.alumniDashboardData
                                          .alumniDashboardInfo.membersConnected
                                          .length
                                      }
                                    </span>
                                  </h4>
                                  <small className="d-block mb-0 mn-ht">
                                    There are total{" "}
                                    {
                                      this.props.alumniDashboardData
                                        .alumniDashboardInfo.membersConnected
                                        .length
                                    }{" "}
                                    alumnae connected
                                  </small>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-soft-success rounded fs-3">
                                    {/* <i
                                    className="fa fa-file-text text-success"
                                    aria-hidden="true"
                                  /> */}

                                    <MyComponentWithIconProps
                                      title=""
                                      statusImage={GroupsIcon}
                                      color="primary"
                                      fontSize="medium"
                                      onClick={this.complaintPending}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.props.alumniDashboardData.alumniDashboardInfo
                      .libraryResponseList !== "" ? (
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card">
                            <div className="text-primary p-3 ">
                              <p className="text-uppercase fw-medium text-muted text-truncate">
                                Library Details
                              </p>
                              <div className="col-lg-12">
                                <div className="d-flex align-items-end justify-content-between ">
                                  <div>
                                    <h5 class="font-size-15">
                                      {"Borrower ID : "}{" "}
                                      {this.props.alumniDashboardData
                                        .alumniDashboardInfo
                                        .libraryResponseList !== ""
                                        ? this.props.alumniDashboardData
                                            .alumniDashboardInfo
                                            .libraryResponseList.borrid
                                        : "-"}
                                    </h5>
                                  </div>
                                  <div>
                                    <h5 class="font-size-15">
                                      {"Membership Expiry Date : "}{" "}
                                      {this.props.alumniDashboardData
                                        .alumniDashboardInfo
                                        .libraryResponseList !== ""
                                        ? this.props.alumniDashboardData
                                            .alumniDashboardInfo
                                            .libraryResponseList.validUntil
                                        : "-"}
                                    </h5>
                                  </div>
                                  <div>
                                    <h5 class="font-size-15">
                                      {"Pending Fees : "}{" "}
                                      {this.props.alumniDashboardData
                                        .alumniDashboardInfo
                                        .libraryResponseList !== ""
                                        ? this.props.alumniDashboardData
                                            .alumniDashboardInfo
                                            .libraryResponseList.pendingFees
                                        : "-"}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              {this.props.alumniDashboardData
                                .alumniDashboardInfo.libraryResponseList
                                .warningMessage !== "" ? (
                                <div className="col-lg-12">
                                  <br />

                                  <div className="d-flex align-items-end justify-content-between ">
                                    <div>
                                      <h5 class="font-size-15">
                                        {"Message : "}{" "}
                                        {
                                          this.props.alumniDashboardData
                                            .alumniDashboardInfo
                                            .libraryResponseList.warningMessage
                                        }
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {this.props.alumniDashboardData.alumniDashboardInfo
                              .libraryResponseList.lentItems.length !== 0 && (
                              <div className="card-body">
                                <DynamicTable
                                  data={
                                    this.props.alumniDashboardData
                                      .alumniDashboardInfo.libraryResponseList
                                      .lentItems
                                  }
                                  tableHead={this.libraryColumns}
                                  showPegination={false}
                                  isActionColActive={false}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="card"
                        style={{
                          maxHeight: "435px",
                        }}
                      >
                        <div className="card-header align-items-center d-flex">
                          <h4
                            onClick={this.readMore}
                            className="card-title mb-0 flex-grow-1"
                            style={{ cursor: "pointer" }}
                          >
                            Latest News
                          </h4>
                        </div>
                        {/* end card header */}
                        <div
                          style={{
                            overflow: "scroll",
                            scrollBehavior: "smooth",
                          }}
                        >
                          <div
                            className="card-body"
                            // style={{ overflowY: "hidden" }}
                          >
                            {galleryList.map((data) => {
                              return (
                                <div
                                  className="news_detail"
                                  style={{ overflow: "hidden" }}
                                >
                                  <div className="det_news_pic pic">
                                    <img
                                      src={
                                        data.profile
                                        // data.documentName === "" ? news : data.documentName
                                      }
                                    ></img>
                                  </div>
                                  <div className="admission_cont detil">
                                    <h6 className="event_sub_nm">
                                      {data.title}
                                      {/* <span>{data.date}</span> */}
                                    </h6>

                                    <h6 class="news_time latest">
                                      <span class="blue">Posted on </span>
                                      {data.date}
                                    </h6>
                                  </div>
                                  <p className="admission_txt">
                                    {data.description}
                                  </p>
                                  {/* <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                container
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                  sm={10.4}
                  md={10.4}
                  lg={10.4}
                  container
                  justifyContent="flex-end"
                ></Grid> */}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    container
                                    justifyContent="flex-end"
                                  >
                                    <Grid item xs={12} sm={12} md={12}>
                                      <ButtonCompo
                                        size="medium"
                                        type="button"
                                        variant="contained"
                                        name="Read more"
                                        onClick={this.readMore}
                                        // fullWidth={true}
                                      />
                                    </Grid>
                                  </Grid>
                                  {/* </Grid> */}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.role == "alumni" && (
                      <div
                        className={
                          this.state.role == "alumni" ? "col-lg-6" : "col-lg-6"
                        }
                      >
                        <div
                          className="card"
                          style={{
                            maxHeight: "435px",
                          }}
                        >
                          {/* end card header */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.showPreview && <StudentPreview1 GoBack={this.GoBack} />}
        {/* {this.state.showEvent && (
          <EventPopUp
            rows={[]}
            columns={this.eventColumns}
            open={this.state.showEvent}
            handleCloses={this.handleCloses}
          />
        )} */}

        {this.state.showLoader && (
          <div>
            <Paper sx={{ p: 40, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <span className="counter-value" data-target="559.25">
                  Please wait...!!!
                </span>
              </h4>
              <Loading1 size={25} />
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}

        {this.state.showFailure && (
          <div>
            <Paper sx={{ p: 11, borderRadius: 2 }}>
              <h4 className="text-primary mb-1">
                <Avatar
                  sx={{
                    width: 350,
                    height: 350,
                    mt: -10,
                    ml: 30,
                  }}
                  // onClick={handleClick}
                  id="basic-button"
                  // aria-controls={open ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                  src={user1}
                ></Avatar>
                <span
                  style={{
                    fontSize: "75px",
                    textAlign: "center",
                    letterSpacing: "1px",
                    marginLeft: "300px",
                  }}
                >
                  Oops !
                </span>
                <br />
                <span
                  style={{
                    fontSize: "35px",
                    textAlign: "center",
                    letterSpacing: "0.5px",
                    marginLeft: "210px",
                  }}
                >
                  Something went wrong.
                </span>
              </h4>
            </Paper>
          </div>
          // <Loading1 size={100} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  student: state.studentList,
  yearList: state.applicantAdmission,
  rollNoData: state.rollNo,
  alumniDashboardData: state.alumniDashboard,
});

const mapDispatchToProps = {
  onToggle,
  getProfile,
  getAlert,
  // getStudentInfoByPRNNo,
  getYears,
  // getSemesterDetailsByYearAndAcademic,
  getAlumniDashboardInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlumniDashboard);
