import { Grid, Paper, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect } from "react";
import { connect } from "react-redux";
// import { getNewsDocNotification } from "../NewsDocNotification/newsDocNotification.slice";
import { getAlert } from "../../../Alert/alert.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import * as myConstClass from "../../../config/messageconstant";
import { showNotification } from "../../Landing/Landing.slice";
import { downloadFile } from "../../UploadFile/file.slice";
import { alumniInviteFriends } from "../AdminSideAlimini/AlumniVolunteerStatus/AdminVolunteerStatus.slice";


const InviteFriends = ({
  alumniInviteFriends,
  getAlert,
  showNotification,
}) => {
  const [inviteFriends, setInviteFriends] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (inviteFriends.toString().trim() === "" || inviteFriends === null) {
      formIsValid = false;
      formErrors["inviteFriendsError"] = myConstClass.emailIdMsg;
    }

    setFormErrors(formErrors);
    return formIsValid;
  };
  useEffect(() => {}, []);
  const friendsInvite = () => {
    console.log(inviteFriends);
    if (checkValidation()) {
      alumniInviteFriends({ emailId: inviteFriends }).then((response) => {
        if (!response) {
          getAlert({ message: myConstClass.serverMsg });
        } else {
          showNotification({ msg: "Invitation sent successfully" });
          setInviteFriends("");
        }
      });
    }
  };
  const changedHandler = (event) => {
    const { name, value } = event.target;
    if (name == "inviteFriends") {
      setInviteFriends(value);
    }
  };
  return (
    <>
      <Paper sx={{ p: 0, borderRadius: 2 }} elevation={0}>
        <div align="center">
          <div className="new_content">
            <h3 className="event_title1">
              <span>Invite</span> Friends
            </h3>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=""
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    color="primary"
                    name="inviteFriends"
                    size="small"
                    value={inviteFriends}
                    placeholder="Enter the emails you want to invite"
                    onChange={changedHandler}
                    error={formErrors.inviteFriendsError ? true : false}
                    helperText={
                      formErrors.inviteFriendsError
                        ? formErrors.inviteFriendsError
                        : " "
                    }
                    fullWidth
                  />
                </Grid>
                <Grid sx={{ pt: 1 }} container justifyContent="flex-start">
                  <h6>*Emails are seperated with commas</h6>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              md={1}
              lg={1}
              container
              sx={{ p: 2 }}
              justifyContent="flex-end"
            >
              <Grid item xs={12} sm={12} md={12}>
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Invite"
                  onClick={friendsInvite}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            {/* </Grid> */}
          </div>
        </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({
  latestnews: state.newsDocNotification,
});

const mapDispatchToProps = {
  alumniInviteFriends,
  getAlert,
  downloadFile,
  showNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteFriends);
