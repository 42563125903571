import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  employeeFeedbackDetails: [],
  isFetchEmployeeFeedbackDetails: false,

  employeeFeedbackQuestions: [],
  isFetchEmployeeFeedbackQuestions: false,

  employeeFeedbackTeacher: [],
  isFetchEmployeeFeedbackTeacher: false,

  employeeFeedbackTheyApplied: [],
  isFetchEmployeeFeedbackTheyApplied: false,
};
let URL = endpoints.employeeFeedbackDetails;
let URL1 = endpoints.feedBack;
let URL2 = endpoints.employeeFeedbackTransaction;

const employeeFeedbackDetailsSlice = createSlice( {
  name: "employeeFeedbackDetails",
  initialState,
  reducers: {
    employeeFeedbackDetailsSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        employeeFeedbackDetails: row,
        isFetchEmployeeFeedbackDetails: true,
      };
    },

    employeeFeedbackQuestionsSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        employeeFeedbackQuestions: row,
        isFetchEmployeeFeedbackQuestions: true,
      };
    },

    employeeFeedbackTeacherSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        employeeFeedbackTeacher: row,
        isFetchEmployeeFeedbackTeacher: true,
      };
    },


    resetSuccess: ( state = cloneDeep( initialState ), action ) =>
    {
      return {
        ...initialState,
      };
    },


    employeeFeedbackTheyAppliedSuccess: (
      state = cloneDeep( initialState ),
      action
    ) =>
    {
      const { row } = action.payload;
      return {
        ...state,
        employeeFeedbackTheyApplied: row,
        isFetchEmployeeFeedbackTheyApplied: true,
      };
    },
  },
} );

export const {
  employeeFeedbackDetailsSuccess,
  employeeFeedbackQuestionsSuccess,
  employeeFeedbackTeacherSuccess,
  employeeFeedbackTheyAppliedSuccess, resetSuccess
} = employeeFeedbackDetailsSlice.actions;

export default employeeFeedbackDetailsSlice.reducer;

export const getAppliedListFromEventId = ({id}) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2 + "/"+id+"/by-event-id",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        console.log(content);
        let index = 0;
        let row = [];
        content.map((data1) => {
          index = index + 1;
          const departments =
            data1.departments === null
              ? []
              : data1.departments.map((data) => {
                  return data.name;
                });
          let bData = {
            index: index,
            id: data1.id,
            feedbackHead: data1.feedbackHead.name,
            feedbackHeadId: data1.feedbackHead.id,
            department: departments.join(),

            // isSubject: data1.isSubject
            // venue: data1.venue,
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            details: data1.feedbackDetails,
            // applicationLastDate: data1.applicationLastDate,
            // time: data1.time,
            // amount: data1.amount,
            // amountApplicable: data1.amountApplicable,
            // isActive: data1.isActive === 1 ? "Active" : "Inactive",
            // fromTo: data1.fromDate + " -" + data1.toDate,
            // yearDetailIds: data1.yearDetailIds,
            // documentUpload: data1.documentUpload,
          };
          row.push(bData);
          return data1;
        });
        dispatch(employeeFeedbackDetailsSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getEmployeeFeedbackDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/for-teacher",
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const content = data.data;
        console.log( content );
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          const departments =
            data1.departments === null
              ? []
              : data1.departments.map( ( data ) =>
              {
                return data.name;
              } );
          let bData = {
            index: index,
            id: data1.id,
            feedbackHead: data1.feedbackHead.name,
            feedbackHeadId: data1.feedbackHead.id,
            department: departments.join(),

            // isSubject: data1.isSubject
            // venue: data1.venue,
            fromDate: data1.fromDate,
            toDate: data1.toDate,
            details: data1.feedbackDetails,
            // applicationLastDate: data1.applicationLastDate,
            // time: data1.time,
            // amount: data1.amount,
            // amountApplicable: data1.amountApplicable,
            // isActive: data1.isActive === 1 ? "Active" : "Inactive",
            // fromTo: data1.fromDate + " -" + data1.toDate,
            // yearDetailIds: data1.yearDetailIds,
            // documentUpload: data1.documentUpload,
          };
          row.push( bData );
          return data1;
        } );
        dispatch( employeeFeedbackDetailsSuccess( { row } ) );
      }
      return success;
    } );
    return response;
  } catch ( e )
  {
    return console.error( e.message );
  }
};

export const getEmployeeFeedbackTheyApplied = ( { fromDate, toDate } ) => async ( dispatch ) =>
{
  try
  {
    const response = apiGet( {
      url: URL2 + "/view-response?fromDate=" + fromDate + "&toDate=" + toDate,
    } ).then( ( { data, success } ) =>
    {
      if ( success )
      {
        const content = data.data;
        console.log( content );
        let index = 0;
        let row = [];
        content.map( ( data1 ) =>
        {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            feedbackHead: data1.employeeFeedback.feedbackHead.name,
            feedbackHeadId: data1.employeeFeedback.feedbackHead.id,
            details: data1.employeeFeedback.feedbackDetails,
            employeeName:
              data1.userRegistration === null
                ? ""
                : data1.userRegistration.firstName +
                " " +
                data1.userRegistration.middleName +
                " " +
                data1.userRegistration.lastName,

            employeeFeedbackTransactionDetails:
              data1.employeeFeedbackTransactionDetails.map( ( employeeFeedbackTransactionDetailData, empIndex ) =>
              {
                let feedBack = {
                  index: empIndex + 1,
                  id: employeeFeedbackTransactionDetailData.id,
                  question: employeeFeedbackTransactionDetailData.feedbackHeadDetails.question,
                  optionType: employeeFeedbackTransactionDetailData.feedbackHeadDetails.optionType,
                  answer: employeeFeedbackTransactionDetailData.value,
                  answerValid: 0,
                  isActive: employeeFeedbackTransactionDetailData.feedbackHeadDetails.isActive,
                  feedbackHeadDetailsOptions: employeeFeedbackTransactionDetailData.feedbackHeadDetails.feedbackHeadDetailsOptions
                }
                return feedBack
              } )
          };
          row.push( bData );
          return data1;
        } );
        dispatch( employeeFeedbackTheyAppliedSuccess( { row } ) );
      }
      return success;
    } );
    return response;
  } catch ( e )
  {
    return console.error( e.message );
  }
};

///{id}/get-employee-feedback-transaction

export const getEmployeeFeedbackQuestions =
  ( { id } ) =>
    async ( dispatch ) =>
    {
      try
      {
        const response = apiGet( {
          url: URL1 + "/" + id + "/get-feedback-head-details",
        } ).then( ( { data, success } ) =>
        {
          if ( success )
          {
            const content = data.data;
            console.log( content );
            let index = 0;
            let row = [];
            content.map( ( data1 ) =>
            {
              index = index + 1;
              let bData = {
                index: index,
                id: data1.id,
                question: data1.question,
                optionType: data1.optionType,
                answer: "",
                answerValid: 0,
                isActive: data1.isActive,
                feedbackHeadDetailsOptions: data1.feedbackHeadDetailsOptions,
              };
              row.push( bData );
              return data1;
            } );
            dispatch( employeeFeedbackQuestionsSuccess( { row } ) );
          }
          return success;
        } );
        return response;
      } catch ( e )
      {
        return console.error( e.message );
      }
    };
export const resetValues = () => async ( dispatch ) =>
{
  try
  {
    dispatch( resetSuccess() );
  } catch ( e )
  {
    return console.error( e.message );
  }
};
