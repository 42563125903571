import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
import { getFeeStructureHeader } from "../Masters/Admission/FeeStructureHead/feeStructureHead.slice";
import { getAddFeesStructureHeader } from "../StudentFeesCollection/FeesCollection.slice";
import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class AddFeesStructure extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    id: 0,
    feesStructureHeader: "",
    rowListDetails: [],
    formErrors: [],
    applicationIdData: {},
  };

  componentDidMount() {
    const { feesStructureHeaderList, applicationData, docsToAddStructure } =
      this.props;

    this.props.getFeeStructureHeader().then((response) => {});
    let amount = 0;
  }

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  submitHandler = () => {
    const { applicationIdData, feesStructureHeader } = this.state;
    if (this.handleFormValidation()) {
      swal({
        title: "Are you sure?",
        text: "Do you want to change fees structure?",
        icon: "warning",
        buttons: ["Cancel", true],
      }).then((res) => {
        if (res) {
          this.props.onSuccess(feesStructureHeader);
          this.setState({ feesStructureHeader: "" });
        }
      });
    }
  };

  handleFormValidation() {
    const { feesStructureHeader } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (
      feesStructureHeader.toString().trim() === "" ||
      feesStructureHeader === null
    ) {
      formIsValid = false;
      formErrors["feesStructureHeaderError"] =
        myConstClass.feesStructureHeaderMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleClose = (event, reason) => {
    this.props.onClose();
  };

  cancelHandler = () => {
    this.props.onCloseTab();
  };

  changeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  render() {
    const { feesStructureHeaderList, applicationData, docsToAddStructure } =
      this.props;
    const { feesStructureHeaderError } = this.state.formErrors;
    const { feesStructureHeader, rowListDetails } = this.state;

    return (
      <>
        {/* <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth
            maxWidth="md"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            >
              Add Fees Structure
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                spacing={2}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={2}
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ margin: 2 }}
                >
                  {Object.keys(feesStructureHeaderList?.feesStructureHeaderById)
                    .length !== 0 && (
                    <>
                      <Grid item xs={12} sm={6} md={6}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Current Structure Name : "
                        />
                        {" " + docsToAddStructure.name}
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Applicable Fees : "
                        />
                        {" " + docsToAddStructure.fees}
                      </Grid>
                    </>
                  )}

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={8}
                    md={8}
                    sx={{ margin: 5 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Select new fee structure"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <AutoComplete
                        keyColName={"id"}
                        value={feesStructureHeader}
                        name={"feesStructureHeader"}
                        options={feesStructureHeaderList?.feesStructureHeader.filter((data)=>data.isActive==1)}
                        onChange={this.changeHandlerSearch}
                        isError={feesStructureHeaderError ? true : false}
                        errorText={
                          feesStructureHeaderError
                            ? feesStructureHeaderError
                            : " "
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    direction="row"
                    xs={12}
                    sm={2}
                    md={2}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label=""
                      />
                    </Grid>
                    <Grid item sx={{ mb: 3 }} xs={12} sm={12} md={12}>
                      <ButtonCompo
                        size="medium"
                        type="button"
                        variant="contained"
                        name="Save"
                        onClick={this.submitHandler}
                      />
                    </Grid>
                  </Grid>

                  {Object.keys(feesStructureHeaderList?.feesStructureHeaderById)
                    .length == 0 && (
                    <>
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label=""
                          />
                        </Grid>
                        <Grid
                          container
                          justifyContent="right"
                          item
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="outlined"
                            name="Cancel"
                            onClick={this.cancelHandler}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </BootstrapDialog>
        </Paper> */}
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xs"
          open={this.props.open}
        >
          <DialogTitle> Add Fees Structure</DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                columnSpacing={2}
                xs={12}
                sm={12}
                md={12}
                sx={{ margin: 2 }}
              >
                {Object.keys(feesStructureHeaderList?.feesStructureHeaderById)
                  .length !== 0 && (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Current Structure Name : "
                      />
                      {" " + docsToAddStructure.name}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Applicable Fees : "
                      />
                      {" " + docsToAddStructure.fees}
                    </Grid>
                  </>
                )}

                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={8}
                  md={8}
                  sx={{ margin: 5 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Select new fee structure"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <AutoComplete
                      keyColName={"id"}
                      value={feesStructureHeader}
                      name={"feesStructureHeader"}
                      options={feesStructureHeaderList?.feesStructureHeader.filter(
                        (data) => data.isActive == 1
                      )}
                      onChange={this.changeHandlerSearch}
                      isError={feesStructureHeaderError ? true : false}
                      errorText={
                        feesStructureHeaderError
                          ? feesStructureHeaderError
                          : " "
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonCompo
              size="medium"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={
                Object.keys(feesStructureHeaderList?.feesStructureHeaderById)
                  .length === 0
                  ? this.cancelHandler
                  : this.handleClose
              }
            />

            <ButtonCompo
              size="medium"
              type="button"
              variant="contained"
              name="Save"
              onClick={this.submitHandler}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feesStructureHeaderList: state.feesStructureHead,
});
const mapDispatchToProps = {
  getFeeStructureHeader,
  getAddFeesStructureHeader,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddFeesStructure);
