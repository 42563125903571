import React from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import DynamicSelectTable from "../../components/Comman/RT/CheckBoxSelectionTable";
const StudentSelectionTable = ({
  isActionColActive,
  onSelectionChange,
  selection,
  students,
  goToSubSelection,
  generatePrn,
  onCancel,
  rows,
}) => {
  const columns = [
    { name: "index", title: "Sr. No.", minWidth: 100 },
    { name: "rollNo", title: "Roll No", minWidth: 100 },
    { name: "name", title: "Student Name", minWidth: 400 },
  ];
  const rowPrint = (row) => {};
  return (
    <>
      <DynamicSelectTable
        rows={rows}
        columns={columns}
        selection={selection}
        isActionColActive={isActionColActive}
        onSelectionChange={onSelectionChange}
      />
      <br />

      <Grid container columnSpacing={1} justifyContent="right">
        <Grid item justifyContent="space-evenly">
          <ButtonCompo
            size="medium"
            type="button"
            variant="outlined"
            name="Cancel"
            onClick={onCancel}
            fullWidth={true}
          />
        </Grid>

        <Grid item justifyContent="space-evenly">
          <ButtonCompo
            size="medium"
            type="submit"
            variant="contained"
            name="Save and Next"
            fullWidth={true}
            onClick={goToSubSelection}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  prnNumberList: state.prnNumber,
  students: state.subjectSelection,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentSelectionTable);
