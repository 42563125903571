import endpoint from "../config/endpoints";

export const AlumniTeacherJson = {
  apiBaseURL: endpoint.volunteerUpdateStatus,
  screenTitle: "Be a Teacher",
  showAddButton: true,
  baseIdColumn: "id",
  fieldMeta: [
    {
      label: "Highest Academic Qualification",
      controlType: "autocomplete",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "QualificationMaster",
      dataKey: "expertise",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      masterName: "QualificationMaster",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "qualificationNew",
      tableDataKey: "expertise",
      open: "other",
      error: "expertise",
    },
    {
      label: "UGC NET",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "message",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
      isMandatory: true,
    },
    {
      label: "UGC SET",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
      isMandatory: true,
    },
  ],
};
