import { FormHelperText, Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import AttachFile from "../../components/Comman/AttachFile";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import "../../CSS/View.css";
import profile from "../../Images/user.jpeg";
import { getAlert } from "../../CommonActions/alert.slice";
import sign from "../../Images/signature.png";
import endpoints from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import * as myConstClass from "../../config/messageconstant";

let docs;
class ViewPage extends React.Component {
  state = {
    id: 0,
    signatureFile: "",
    profilePicFile: "",
    profilePic: profile,
    isUpdateProfile: false,
    isSubmit: false,
    isUpdateSign: false,
    signature: sign,
    file: "",
    formErrors: [],
    error: false,
  };

  changeH = (fileName, name, index) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      if (name === "profilePicFile") {
        this.setState({ profilePic: e.target.result });
      } else {
        this.setState({ signature: e.target.result });
      }
    };
    reader.readAsDataURL(fileName);
    this.setState({
      ...this.state,
      [name]: fileName,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const { id, profilePicFile, signatureFile } = this.state;
    let profile = "";
    let sign = "";
    const { employeeDetails } = this.props;
    if (profilePicFile !== "" && signatureFile !== "") {
      const formData = new FormData();
      formData.append("file", profilePicFile);
      apiPost({
        url: endpoints.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          console.log(data);
          profile = data;
          if (signatureFile !== "") {
            const formData = new FormData();
            formData.append("file", signatureFile);
            apiPost({
              url: endpoints.fileUpload,
              postBody: formData,
            }).then(({ data, success }) => {
              if (success) {
                console.log(data);
                sign = data;
                docs = {
                  id: id,
                  profilePic: profile,
                  signature: sign,
                  type: 4,
                };
                this.props.uploadphotoandsign(docs);
              }
            });
          }
        }
      });
    } else if (profilePicFile !== "" || signatureFile !== "") {
      if (profilePicFile !== "") {
        const formData = new FormData();
        formData.append("file", profilePicFile);
        apiPost({
          url: endpoints.fileUpload,
          postBody: formData,
        }).then(({ data, success }) => {
          if (success) {
            console.log(data);
            profile = data;
            docs = {
              id: id,
              profilePic: profile,
              signature:  employeeDetails.employee.signature,
              type: 4,
            };
            this.props.uploadphotoandsign(docs);
          }
        });
      }
      if (signatureFile !== "") {
        const formData = new FormData();
        formData.append("file", signatureFile);
        apiPost({
          url: endpoints.fileUpload,
          postBody: formData,
        }).then(({ data, success }) => {
          if (success) {
            console.log(data);
            sign = data;
            docs = {
              id: id,
              profilePic: employeeDetails.employee.profilePic,
              signature: sign,
              type: 4,
            };
            this.props.uploadphotoandsign(docs);
          }
        });
      }
    } else {
      docs = {
        id: id,
        profilePic:  employeeDetails.employee.profilePic,
        signature:  employeeDetails.employee.signature,
        type: 4,
      };
      this.props.uploadphotoandsign(docs);
    }
  };

  uploadImage = () => {
    const { saveFile } = this.props;
    const { file, label } = this.state;

    if (file !== "") {
      this.setState({
        upload: true,
      });
      let saveFileData = {
        file: file,
        name: label,
      };
      saveFile({ file: saveFileData });
    } else {
      this.setState({
        uploadError: true,
      });
    }
  };

  componentDidMount() {
    const {  employeeDetails } = this.props;
  

    if (Object.keys(employeeDetails.employee).length != 0) {
      this.setState({
        id: employeeDetails.employee.id,
        profilePic:
          employeeDetails.employee.profilePic !== null
            ? employeeDetails.employee.profilePic
            : "",
        signature:
          employeeDetails.employee.signature !== null
            ? employeeDetails.employee.signature
            : "",
      });
      if (
        employeeDetails.employee.signature !== null &&
        employeeDetails.employee.signature !== ""
      ) {
        apiGet({
          url:
            endpoints.fileDownload + "/" + employeeDetails.employee.signature,
        }).then(({ data, success }) => {
          if (success) {
            let fileRes = data.data;
            this.setState({
              signature: fileRes,
            });
          } else {
            this.props.getAlert({
              message: myConstClass.serverMsg,
            });
          }
        });
      }
      if (
        employeeDetails.employee.profilePic !== null &&
        employeeDetails.employee.profilePic !== ""
      ) {
        apiGet({
          url:
            endpoints.fileDownload + "/" + employeeDetails.employee.profilePic,
        }).then(({ data, success }) => {
          if (success) {
            let fileRes = data.data;
            this.setState({
              profilePic: fileRes,
            });
          } else {
            this.props.getAlert({
              message: myConstClass.serverMsg,
            });
          }
        });
      }
    }
  }

  render() {
    const { onCancel } = this.props;
    return (
      <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
        <Grid container justifyContent="space-evenly" spacing={2}>
          <Grid container justifyContent="space-evenly" m={2}>
            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              rowSpacing={3}
              justifyContent="space-evenly"
            >
              <Grid item xs={6} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 14 }}
                  label="Scanned Photo"
                />
              </Grid>

              <Grid item xs={6} sm={6} md={12}>
                <Grid m={2} item xs={6} sm={6} md={12}>
                  <AttachFile name="profilePicFile" fileName={this.changeH} />
                </Grid>

                <Grid item xs={6} sm={6} md={12}>
                  <img
                    alt=""
                    id="target"
                    src={this.state.profilePic}
                    height="100px"
                    width="100"
                    align="left"
                    style={{ display: "block", margin: " 0 auto" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              md={4}
              lg={4}
              rowSpacing={3}
              justifyContent="space-evenly"
            >
              <Grid item xs={6} sm={6} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 14 }}
                  label="Scanned Sign"
                />
              </Grid>

              <Grid item xs={6} sm={6} md={12}>
                <Grid m={2} item xs={6} sm={6} md={12}>
                  <AttachFile name="signatureFile" fileName={this.changeH} />
                </Grid>

                <Grid item xs={6} sm={6} md={12}>
                  <img
                    alt=""
                    id="target"
                    src={this.state.signature}
                    height="100px"
                    width="100"
                    align="left"
                    style={{
                      display: "block",
                      margin: " 0 auto",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            {this.state.error && (
              <FormHelperText error>
                {"Please select photo and sign"}{" "}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} />

          <Grid
            rowSpacing={2}
            // columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            columnSpacing={1}
            justifyContent="right"
          >
            <Grid item justifyContent="space-evenly">
              <ButtonCompo
                size="medium"
                type="button"
                variant="outlined"
                name="Back"
                onClick={onCancel}
                fullWidth={true}
              />
            </Grid>

            <Grid item justifyContent="space-evenly">
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Save and Proceed"
                onClick={this.submitHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  employeeDetails: state.employeeList,
});

const mapDispatchToProps = {
  getAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPage);
