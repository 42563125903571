import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
const initialState = {
  aplliedEventStatus: [],
  participatedEventStatus: [],
  isFetchStatus: false,
  participatedEventFetch: false,
};

let URL = endpoints.eventParticipant;
let URL1 = endpoints.event;

const studentApplySlice = createSlice({
  name: "studentApplyList",
  initialState,
  reducers: {
    appliedEventSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        aplliedEventStatus: row,
        isFetchStatus: true,
      };
    },

    participatedEventListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        participatedEventStatus: row,
        participatedEventFetch: true,
      };
    },
    admissionListFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        documentVerificationDataList: [],
        isFetchDocumentVerificationDataList: false,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  appliedEventSuccess,
  participatedEventListSuccess,
  resetSuccess,
} = studentApplySlice.actions;

export default studentApplySlice.reducer;

//getCompanyList
export const getAppliedEventList =
  ({ userRegistrationId }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url: URL1 + "/for-student?userRegistrationId=" + userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const appliedEventList = data.data;
          let row = [];
          let index = 0;
          row = appliedEventList.map((data) => {
            index = index + 1;
            let appliedEventListData = {
              index: index,
              academicYearId: data.academicYearId,
              amount: data.amount,
              amountApplicable: data.amountApplicable,
              applicationLastDate: data.applicationLastDate,
              documentUpload: data.documentUpload,
              eventDetails: data.eventDetails,
              name: data.name,
              fromDate: data.fromDate,
              id: data.id,
              isActive: data.isActive,
              time: data.time,
              toDate: data.toDate,
              venue: data.venue,
              yearDetailIds: data.yearDetailIds,
            };
            return appliedEventListData;
          });
          dispatch(appliedEventSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getParticipatedEventList =
  ({ academicId, yearDetailId, userRegistrationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-academic-year-for-student?academicYearId=" +
          academicId +
          "&userRegistrationId=" +
          userRegistrationId,
      }).then(({ data, success }) => {
        if (success) {
          const participatedList = data.data;
          // let index = 0;
          let row = [];
          let index = 0;
          row = participatedList.map((data) => {
            index = index + 1;
            let participatedListData = {
              index: index,
              id: data.id,
              eventId: data.event.id,
              name: data.event.name,
              fromDate: data.event.fromDate,
              toDate: data.event.toDate,
              eventStatus: data.isCompleted === 0 ? "Pending" : "Completed",
            };
            return participatedListData;
          });
          dispatch(participatedEventListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
