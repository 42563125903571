import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import TimePickerCompo from "../../components/Comman/TimePicker";
import { setAllocatedListOfStudentAndTeacher } from "./AllocationOfStudent.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TeacherStudentList = ({
  open,
  allocationList,
  handleClickOpen,
  submitHandler,
  submit,
  rowViewData,
  actionVariable,
  allocationStudentList,
  setAllocatedListOfStudentAndTeacher,
  markData,
}) => {
  const [date, setDate] = React.useState(null);
  const [remark, setRemark] = React.useState("");
  const [time, setTime] = React.useState(null);

  const handleClose = () => {
    handleClickOpen();
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,
    },
    {
      name: "division",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "40%",
    },
    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "40%",
    },
    {
      name: "internalMarks",
      title: "Internal Marks",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "40%",
    },
    {
      name: "outOfInternal",
      title: "Out Off Internal Marks",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "40%",
    },
    {
      name: "externalMarks",
      title: "External Marks",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "40%",
    },
    {
      name: "outOfExternal",
      title: "Out Off External Marks",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "40%",
    },
    // {
    //   name: "action",
    //   title: "Actions",
    //   positionCenter: false,
    //   showInscreen: true,
    //   alignCenter: "right",
    //   width: "10%",
    // },
  ];

  const column = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,
    },
    {
      name: "division",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "rollNo",
      title: "Roll No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "40%",
    },
  ];

  const changeHandler = (id) => (event) => {
    console.log(id);
    console.log(event.target.value);
    const rows = allocationList.map((allocate) => {
      if (allocate.id === id) {
        allocate = {
          ...allocate,
          remark: event.target.value,
        };
        return allocate;
      }
      return allocate;
    });
    console.log(allocationList);
    setAllocatedListOfStudentAndTeacher(rows);
  };
  const onDateChange = (id) => (date) => {
    console.log(id);
    console.log(date);

    const rows = allocationList.map((allocate) => {
      if (allocate.id === id) {
        allocate = {
          ...allocate,
          vivaDate: date,
        };
        return allocate;
      }
      return allocate;
    });
    setAllocatedListOfStudentAndTeacher(rows);
  };
  const timeChangeHandler = (id) => (time) => {
    console.log(id);
    console.log(date);

    const rows = allocationList.map((allocate) => {
      if (allocate.id === id) {
        allocate = {
          ...allocate,
          time: time,
        };
        return allocate;
      }
      return allocate;
    });
    setAllocatedListOfStudentAndTeacher(rows);
  };
  const confirmData = () => {
    let error = false;
    const rows = allocationList.map((allocate) => {
      if (allocate.vivaDate === null) {
        allocate = {
          ...allocate,
          vivaDateValid: 1,
        };
        error = true;
      } else {
        allocate = {
          ...allocate,
          vivaDateValid: 0,
        };
        error = false;
      }
      if (allocate.time === null) {
        allocate = {
          ...allocate,
          timeValid: 1,
        };
        error = true;
      } else {
        allocate = {
          ...allocate,
          timeValid: 0,
        };
        error = false;
      }
      return allocate;
    });
    setAllocatedListOfStudentAndTeacher(rows);
    if (!error) {
      submitHandler();
    }
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Allocation
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {allocationList.map((allocationData) => {
              let dataToSave = [];

              if (allocationStudentList !== undefined) {
                let dataStud2 = allocationStudentList.map((data2) => {
                  let rowList1 =
                    allocationData.internshipVivaDeclareStudent.filter(
                      (row) =>
                        row.userRegistrationId === data2.userRegistrationId
                    );

                  if (rowList1.length !== 0) {
                    dataToSave.push(data2);
                  }
                });
              }

              return (
                <>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      rowSpacing={2}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={8}
                        lg={8}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid item xs={2} sm={2} md={2}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, fontWeight: "bold" }}
                            label={"Teacher Name :"}
                          />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label={allocationData.teacherName.toUpperCase()}
                          />
                        </Grid>
                      </Grid>
                      {submit ? (
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={2}
                          lg={2}
                          container
                          justifyContent="space-evenly"
                        />
                      ) : (
                        <>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={2}
                            lg={2}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={4} sm={4} md={4}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, fontWeight: "bold" }}
                                label={"Date : "}
                              />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label={allocationData.vivaDate}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            md={2}
                            lg={2}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={4} sm={4} md={4}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, fontWeight: "bold" }}
                                label={"Time : "}
                              />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label={allocationData.time}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {submit ? (
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={"Date :"}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <BasicDatePicker
                              color="primary"
                              name="date"
                              size="small"
                              isError={
                                allocationData.vivaDateValid == 0 ? false : true
                              }
                              errorText={
                                allocationData.vivaDateValid == 0
                                  ? " "
                                  : "*Date should be selected"
                              }
                              value={allocationData.vivaDate}
                              fullWidth
                              onChange={onDateChange(allocationData.id)}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={"Time :"}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TimePickerCompo
                              value={allocationData.time}
                              onChange={timeChangeHandler(allocationData.id)}
                              helperText={" "}
                              error={
                                allocationData.timeValid == 0
                                  ? " "
                                  : "*Time should be selected"
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid tem xs={12} sm={12} md={12}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={"Remark :"}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              color="primary"
                              name="remark"
                              size="small"
                              value={allocationData.remark}
                              onChange={changeHandler(allocationData.id)}
                              fullWidth
                              helperText={" "}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        columnSpacing={1}
                        container
                        justifyContent="space-evenly"
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          container
                          justifyContent="space-evenly"
                        >
                          <Grid tem xs={1.3} sm={1.3} md={1.3}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16, fontWeight: "bold" }}
                              label={"Remark : "}
                            />
                          </Grid>
                          <Grid tem xs={10.7} sm={10.7} md={10.7}>
                            <LabelCompo
                              className="text-black"
                              style={{ fontSize: 16 }}
                              label={allocationData.remark}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {actionVariable ? (
                      <>
                        <DynamicTable
                          data={allocationData.internshipVivaDeclareStudent}
                          tableHead={columns}
                          showView={true}
                          rowViewData={rowViewData}
                          showPegination={false}
                          isActionColActive={false}
                        />
                      </>
                    ) : (
                      <>
                        <DynamicTable
                          data={dataToSave}
                          tableHead={column}
                          showView={true}
                          rowViewData={rowViewData}
                          showPegination={false}
                          isActionColActive={actionVariable}
                        />
                      </>
                    )}
                  </Grid>
                  <br />
                </>
              );
            })}
            <br />
            {submit && (
              <Grid
                container
                justifyContent="right"
                direction="row"
                xs={12}
                sm={12}
                md={12}
                sx={{ marginTop: -3 }}
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Confirm"
                  onClick={confirmData}
                />
              </Grid>
            )}
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  setAllocatedListOfStudentAndTeacher,
};
export default connect(mapStateToProps, mapDispatchToProps)(TeacherStudentList);
