import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { LabelCompo } from "../../components/Comman/Label";
import { apiGet } from "../../utils/api_service";
import endpoints from "../../config/endpoints";
import { getAlert } from "../../CommonActions/alert.slice";
import { connect } from "react-redux";
const styles = (theme) => ({
  MuiTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  MuiTableHeadRoot: {
    textAlign: "left",
    padding: "8px",
    border: "1px solid #ddd",
  },
});

const BasicTable = ({ data, getAlert, classes }) => {
  const [bloodGroup, setBloodGroup] = useState("");
  const [religion, setReligion] = useState("");
  const [category, setCategory] = useState("");
  const [annualIncome, setAnnualIncome] = useState("");
  useEffect(() => {
    if (Object.keys(data).length != 0) {
      if (bloodGroup === "" && bloodGroup !== null) {
        if (data.bloodGroup !== null && data.bloodGroup !== "") {
          setBloodGroup(null);
          apiGet({
            url: endpoints.bloodGroup + "/" + data.bloodGroup,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setBloodGroup(data.data.name);
            }
          });
        } else if (data.bloodGroup === null || data.bloodGroup === "") {
          setBloodGroup(" - ");
        }
      }
      if (category === "" && category !== null) {
        if (data.categoryId !== null && data.categoryId !== "") {
          setCategory(null);
          apiGet({
            url: endpoints.category + "/" + data.categoryId,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setCategory(data.data.name);
            }
          });
        } else if (data.categoryId === null || data.categoryId === "") {
          setCategory(" - ");
        }
      }
      if (religion === "" && religion !== null) {
        if (data.religionId !== null && data.religionId !== "") {
          setReligion(null);
          apiGet({
            url: endpoints.religion + "/" + data.religionId,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setReligion(data.data.name);
            }
          });
        } else if (data.religionId === null || data.religionId === "") {
          setReligion(" - ");
        }
      }
      if (annualIncome === "" && annualIncome !== null) {
        if (data.annualIncome !== null && data.annualIncome !== "") {
          setAnnualIncome(null);
          apiGet({
            url: endpoints.annualIncome + "/" + data.annualIncome,
          }).then(({ data, success }) => {
            if (!success) {
              getAlert({ message: "Something went wrong" });
            } else {
              setAnnualIncome(data.data.name);
            }
          });
        } else if (data.accessRole === null || data.accessRole === "") {
          setAnnualIncome(" - ");
        }
      }
    }
  });
  return (
    <>
      <LabelCompo
        className="text-black"
        style={{ fontSize: 20 }}
        label="Personal Information"
      />
      <Grid>&nbsp;&nbsp;</Grid>
      <div style={{ overflow: "auto" }}>
        <table className={classes.MuiTable}>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Candidate Name
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.firstName +
                "    " +
                data.middleName +
                "      " +
                data.lastName}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; PRN Number
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.prnNo}
            </td>
            {/* <td className={classes.MuiTableHeadRoot}>&nbsp;{data.userRegistration.fatherName}</td> */}
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Library ID
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.libraryNo}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Gender
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.gender === "m"
                ? "Male"
                : data.gender === "f"
                ? "Female"
                : "Transgender"}
            </td>
            {/* <td className={classes.MuiTableHeadRoot}>&nbsp;{data.userRegistration.fatherName}</td> */}
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Date of Birth
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.dateOfBirth === ""
                ? "-"
                : data.dateOfBirth === null
                ? "-"
                : data.dateOfBirth}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Birth Place
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.birthPlace === ""
                ? "-"
                : data.birthPlace === null
                ? "-"
                : data.birthPlace}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Blood Group
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {bloodGroup === "" ? "-" : bloodGroup === null ? "-" : bloodGroup}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Medium ( Language in previous college )
            </td>
            <td className={classes.MuiTableHeadRoot} colSpan={3}>
              &nbsp;
              {data.medium === ""
                ? "-"
                : data.medium === null
                ? "-"
                : data.medium}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Student Contact No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.studentMobile === ""
                ? "-"
                : data.studentMobile === null
                ? "-"
                : data.studentMobile}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp;Student Email
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.studentEmail === ""
                ? "-"
                : data.studentEmail === null
                ? "-"
                : data.studentEmail}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Father's Name
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.fatherName === ""
                ? "-"
                : data.fatherName === null
                ? "-"
                : data.fatherName}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Mother's Name
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.motherName === ""
                ? "-"
                : data.motherName === null
                ? "-"
                : data.motherName}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Marital Status
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.martialStatus === 1 ? "Yes" : "No"}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Mother Tougue{" "}
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.motherTongue === ""
                ? "-"
                : data.motherTongue === null
                ? "-"
                : data.motherTongue}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Father's Occupation
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.fatherOccupation === ""
                ? "-"
                : data.fatherOccupation === null
                ? "-"
                : data.fatherOccupation}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Annual Income
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {annualIncome === ""
                ? "-"
                : annualIncome === null
                ? "-"
                : annualIncome}
            </td>
          </tr>
          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Other Than Maharashtra
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.oms === 1 ? "Yes" : "No"}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; NRI/Foreign National
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.isStudentNriForeignNational === 1 ? "Yes" : "No"}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Aadhar No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.aadhaarNo === ""
                ? "-"
                : data.aadhaarNo === null
                ? "-"
                : data.aadhaarNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Nationality
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.nationality === ""
                ? "-"
                : data.nationality === null
                ? "-"
                : data.nationality}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Religion
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{religion === "" ? "-" : religion === null ? "-" : religion}{" "}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              &nbsp; Category
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{category === "" ? "-" : category === null ? "-" : category}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Caste
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.cast === "" ? "-" : data.cast === null ? "-" : data.cast}
            </td>

            {/* <td className={classes.MuiTableHeadRoot}>
            &nbsp;{data.cast}
          </td> */}
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Sub-Caste
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.subCast === ""
                ? "-"
                : data.subCast === null
                ? "-"
                : data.subCast}
            </td>
          </tr>
          {/* <tr style={{ background: "#f5f5f5" }}>
          <td style={{ color: "#5a5a5a" }} className={classes.MuiTableHeadRoot}>
            {" "}
            &nbsp; Is Scholarship{" "}
          </td>
          <td className={classes.MuiTableHeadRoot} colSpan={3}>
            &nbsp;{data.userRegistration.remark}
          </td>
        </tr>
 */}

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Is Scholarship
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.scholarship === 1 ? "Yes" : "No"}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Domicile State
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.domicileof === ""
                ? "-"
                : data.domicileof === null
                ? "-"
                : data.domicileof}
            </td>
          </tr>
          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Caste Certificate No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;
              {data.castCertificateNo === ""
                ? "-"
                : data.castCertificateNo === null
                ? "-"
                : data.castCertificateNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Caste Certificate Date
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.castCertificateDate}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Validity Certificate No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.validityCdNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Validity Certificate Date
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.validityCdDate}
            </td>
          </tr>

          <tr>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Parent's Contact
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.parentMobile}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Parent's Email
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.parentEmail}
            </td>
          </tr>

          <tr style={{ background: "#f5f5f5" }}>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Landline No
            </td>
            <td className={classes.MuiTableHeadRoot}>
              &nbsp;{data.landlineNo}
            </td>
            <td
              style={{ color: "#5a5a5a" }}
              className={classes.MuiTableHeadRoot}
            >
              {" "}
              &nbsp; Remarks
            </td>
            <td className={classes.MuiTableHeadRoot}>&nbsp;{data.remark}</td>
          </tr>
        </table>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BasicTable));
