import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, FormControl, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAlert } from "../CommonActions/alert.slice";
import { onToggle } from "../CommonActions/common.slice";
import { FilterSave } from "../components/FilterSave";
import endpoints from "../config/endpoints";
import * as myConstClass from "../config/messageconstant";
import "../CSS/NewLanding.css";
import user from "../Images/user.jpeg";
import { logOut } from "../Pages/Login/login.slice";
import { getDepartmentByuserId } from "../Pages/Masters/Internship/Department/department.slice";
import {
  getAcademicYear,
  getCurrentAcademicYear,
} from "../Pages/Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getParentChild } from "../Pages/Dashboard/parentDashboard.slice";
import { getStudentDashboardInfo } from "../Pages/Dashboard/Studentdashboard.slice";
import { apiGet } from "../utils/api_service";
import { getResponse } from "./api.slice";
import { FontAwesomeIconCompo } from "./Comman/IconButton";
import { downloadFile } from "../Pages/UploadFile/file.slice";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(9),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "21px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "26ch",
    },
    // border: "1px solid #ccc",
    borderRadius: "5px",
  },
}));

const Navbar1 = ({
  logOut,
  getAlert,
  academicYearList,
  getAcademicYear,
  getCurrentAcademicYear,
  onToggle,
  getResponse,
  hrData,
  getDepartmentByuserId,
  departmentList,
  getStudentDashboardInfo,
  getParentChild,
  parentChildList,
  downloadFile,
}) => {
  const toggleDrawer = () => {
    onToggle();
  };
  const [name, setName] = React.useState("");
  const [acadamicYear, setAcadamicList] = React.useState([]);
  const [department, setDepartmentList] = React.useState([]);
  const [parentChild, setParentChild] = React.useState([]);
  const [role, setRole] = React.useState("");
  const [profile, setProfile] = React.useState("");

  const [searchValue, setSearchValue] = React.useState("");
  React.useEffect(() => {
    const person = localStorage.getItem("person");
    let role = localStorage.getItem("role");
    let profilePic = localStorage.getItem("profilePic");
    if (profilePic && profilePic !== null) {
      console.log("profilePic" + profilePic);
      apiGet({
        url: endpoints.fileDownload + "/" + profilePic,
      }).then(({ data, success }) => {
        if (success) {
          let fileRes = data.data;
          setProfile(fileRes);
        }

        return success;
      });
    }
    setRole(role);
    setName(person !== null ? person.toUpperCase() : "");
    getCurrentAcademicYear().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getAcademicYear().then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    if (role === "parent") {
      getParentChild().then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    }

    if (role !== "student" && role !== "alumni" && role !== "parent") {
      getDepartmentByuserId().then((response) => {
        if (!response) {
          getAlert({ message: "Something went wrong" });
        }
      });
    }
  }, []);
  React.useEffect(() => {
    if (
      Object.keys(academicYearList.currentAcademicYear).length != 0 &&
      !localStorage.getItem("acadamicYearId")
    ) {
      localStorage.setItem(
        "acadamicYearId",
        academicYearList.currentAcademicYear.id
      );
      localStorage.setItem(
        "currentAcadamicYearId",
        academicYearList.currentAcademicYear.name
      );
    }
    const acadamicYearList = localStorage.getItem("acadamicYearList");
    if (acadamicYearList && acadamicYear.length === 0) {
      const valuesArray = JSON.parse(acadamicYearList);
      setAcadamicList(valuesArray);
    }
    const departmentList = localStorage.getItem("departmentList");
    if (departmentList && department.length === 0) {
      const departmentData = JSON.parse(departmentList);
      setDepartmentList(departmentData);
    }
    if (role === "parent") {
      const parentChildList = localStorage.getItem("parentChildList");
      if (parentChildList && parentChild.length === 0) {
        const parentChildData = JSON.parse(parentChildList);

        setParentChild(parentChildData);
      }
    }
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getprofile = () => {
    let role = localStorage.getItem("role");
    if (role === "student") {
      window.location.replace("/studentPreview");
    } else if (role === "alumni") {
      window.location.replace("/alumini-profile");
    } else {
      window.location.replace("/empPreview");
    }
  };
  const logOutHandler = () => {
    localStorage.clear();
    logOut();
    window.location.replace("/login");
  };

  const goToDashboard = () => {
    if (role === "student") {
      window.location.replace("/newDashboard");
    } else if (role === "teacher") {
      window.location.replace("/teacherDashboard");
    } else if (role === "trust") {
      window.location.replace("/teacherDashboard");
    } else if (role === "parent") {
      window.location.replace("/parent-dashboard");
    } else if (role === "alumni") {
      window.location.replace("/alumini-dashboard");
    }
  };

  const forgotPasswordLink = () => {
    window.location.replace("/forgot-password-link");
  };

  const onClickButton = () => {
    getResponse().then((data) => {
      if (data) {
        let response = data.data;
        window.open(
          response.hreasyLink +
            "?userId=" +
            response.hrCredentialResponse.username +
            "&token=" +
            response.hrCredentialResponse.token,
          "_blank"
        );
      } else {
        getAlert({
          message: "Failed to login",
        });
      }
    });
  };
  const filteredRows = (searchvalue) => {
    setSearchValue(searchvalue);

    // window.location.replace("/newDashboard");
  };
  const showDashBoard = (searchvalue) => {
    if (searchvalue !== "") {
      localStorage.setItem("prnNo", searchvalue);
      window.location.replace("/newDashboard");
    }
  };
  const ChangeHandlerSearch = (e) => {
    if (e.target.name === "department") {
      localStorage.setItem("departmentId", e.target.value);
      localStorage.setItem("semisterDetailId", "");
      localStorage.setItem("yearDetailId", "");
      localStorage.setItem("divisionId", "");
      localStorage.setItem("divisionIds", "");
      FilterSave();
    } else if (e.target.name === "childId") {
      localStorage.setItem("parentChildId", e.target.value);
      FilterSave();
    } else {
      localStorage.setItem("semisterDetailId", "");
      localStorage.setItem("yearDetailId", "");
      localStorage.setItem("divisionId", "");
      localStorage.setItem("divisionIds", "");
      localStorage.setItem("acadamicYearId", e.target.value);
      FilterSave();
    }

    window.location.replace(location.pathname);
  };
  return (
    <>
      <div className="navbar">
        <div className="main_menu">
          <ul>
            <li>
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={toggleDrawer}
              >
                <MenuIcon color="primary-white" />
              </IconButton>
            </li>
          </ul>
        </div>
        <p className="welcomelabel">BMN &emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;</p>

        {/* <LabelCompo
          className="text-black"
          style={{ fontSize: 16 }}
          label="Current Academic Year"
        />
 */}
        {role !== "student" && role !== "alumni" && role !== "parent" && (
          <div className="prnSearch">
            <Search>
              {/* <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper> */}

              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={(searchVal) => filteredRows(searchVal.target.value)}
                onBlur={(searchVal) => showDashBoard(searchVal.target.value)}
                value={searchValue}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    showDashBoard(event.target.value);
                  }
                }}
              />
            </Search>
          </div>
        )}
        {/* <div className="currentAcadamicYear">
          <p className="acadamicYearName">
            {localStorage.getItem("currentAcadamicYearId")
              ? "AY - " + localStorage.getItem("currentAcadamicYearId")
              : ""}
          </p>
        </div> */}

        <div className="login_area">
          {role === "parent" && (
            <div className="acadamicYear">
              <FormControl color={"primary"} fullWidth={true}>
                <Select
                  labelId="demo-simple-select-helper-label"
                  className="text-black"
                  name="childId"
                  id="childId"
                  sx={{
                    textAlign: "left",
                    backgroundColor: "#FFF",
                    fontSize: "small",
                    [`& .MuiInputBase-input`]: {
                      padding: "8px",
                    },
                  }}
                  value={
                    localStorage.getItem("parentChildId")
                      ? localStorage.getItem("parentChildId")
                      : ""
                  }
                  onChange={ChangeHandlerSearch}
                  size="small"
                  fullWidth={true}
                >
                  {parentChild.map((childData) => {
                    return (
                      <MenuItem value={childData.id}>{childData.name}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          {role !== "student" && role !== "alumni" && role !== "parent" && (
            <div className="acadamicYear">
              <FormControl color={"primary"} fullWidth={true}>
                <Select
                  labelId="demo-simple-select-helper-label"
                  className="text-black"
                  name="department"
                  id="department"
                  sx={{
                    textAlign: "left",
                    backgroundColor: "#FFF",
                    fontSize: "small",
                    [`& .MuiInputBase-input`]: {
                      padding: "8px",
                    },
                  }}
                  value={
                    localStorage.getItem("departmentId")
                      ? localStorage.getItem("departmentId")
                      : ""
                  }
                  onChange={ChangeHandlerSearch}
                  size="small"
                  fullWidth={true}
                >
                  {department.map((departmentData) => {
                    return (
                      <MenuItem value={departmentData.id}>
                        {departmentData.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          {role !== "alumni" && role !== "parent" && (
            <div className="acadamicYear">
              <FormControl color={"primary"} sx={{}} fullWidth={true}>
                <Select
                  labelId="demo-simple-select-helper-label"
                  className="text-black"
                  name="acadamicYear"
                  id="acadamicYear"
                  sx={{
                    textAlign: "left",
                    backgroundColor: "#FFF",
                    fontSize: "small",
                    [`& .MuiInputBase-input`]: {
                      padding: "7px",
                    },
                  }}
                  value={
                    localStorage.getItem("acadamicYearId")
                      ? localStorage.getItem("acadamicYearId")
                      : ""
                  }
                  onChange={ChangeHandlerSearch}
                  size="small"
                  fullWidth={true}
                >
                  {acadamicYear.map((acadamicYearData) => {
                    return (
                      <MenuItem value={acadamicYearData.id}>
                        {acadamicYearData.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="login_r">
            <p className="userName">Welcome {name}</p>

            <Avatar
              sx={{ width: 35, height: 35, mt: 0.7, bgcolor: "red" ,    cursor: "pointer"}}
              onClick={handleClick}
              id="basic-button"
              aria-controls={open ? "basic-menu1" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              src={profile}
              
            >
              {name.charAt(0)}
            </Avatar>
            <Menu
              id="basic-menu1"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {role !== "parent" && (
                <MenuItem onClick={getprofile}>Profile</MenuItem>
              )}
              {role !== "student" && role !== "alumni" && role !== "parent" && (
                <MenuItem onClick={onClickButton}>HR Easy</MenuItem>
              )}
              {
                <MenuItem onClick={forgotPasswordLink}>
                  Change Password
                </MenuItem>
              }
              <MenuItem onClick={logOutHandler}>Logout</MenuItem>
            </Menu>
          </div>
          <FontAwesomeIconCompo
            color="white"
            fontSize="large"
            title="Home"
            baseClassName="fas"
            className="fa-home"
            sx={{ mt: 0.7 }}
            onClick={goToDashboard}
          />
        </div>
        <div className="clr"></div>
      </div>

      {/* 
<header id="page-topbar" className="topbar-shadow">
                        <div className="layout-width">
                            <div className="navbar-header">
                                <div className="d-flex">
                                    <div className="navbar-brand-box horizontal-logo">
                                        <a href="#" className="logo logo-dark">
                                            <span className="logo-sm"> <img src="images/logo-sm.png" alt="" height="50" /> </span>
                                            <span className="logo-lg"> <img src="images/logo-dark.png" alt="" /> </span>
                                        </a>
                                        <a href="#" className="logo logo-light">
                                            <span className="logo-sm">
                                                {" "}
                                                <img src={user} alt="" height={50} />{" "}
                                            </span>
                                            <span className="logo-lg">
                                                {" "}
                                                <img src="images/logo-light.png" alt="" />{" "}
                                            </span>
                                        </a>
                                    </div>
                                  


                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        sx={{ mr: 2 }}
                                        onClick={toggleDrawer}
                                    >
                                        <MenuIcon color="primary.white" />
                                    </IconButton>

                                    <Grid sx={{ marginTop: 3 }} item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 18,color:'#ffffff' }}
                  label="BMN COLLEGE"
                />
              </Grid>

                           
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="dropdown ms-sm-3 header-item">
                                        <button
                                            type="button"
                                            className="btn"
                                            id="page-header-user-dropdown"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {" "}
                                            <span className="d-flex align-items-center">
                                                {" "}
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                  
                                                    src={user}

                                                    alt="Header Avatar"
                                                />{" "}
                                                <span className="text-start ms-xl-2">
                                                    {" "}
                                                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                                        Ekant Visave
                                                    </span>{" "}
                                                    <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                                                        Student
                                                    </span>{" "}
                                                </span>{" "}
                                            </span>{" "}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-end">
                                          
                                            <h6 className="dropdown-header">Welcome Ekant!</h6>
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />{" "}
                                                <span className="align-middle">Profile</span>
                                            </a>
                                  
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1" />{" "}
                                                <span className="align-middle">Help</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                           
                                            <a className="dropdown-item" href="#">
                                                <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />{" "}
                                                <span className="align-middle" data-key="t-logout">
                                                    Logout
                                                </span>
                                            </a>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  common: state.common,
  hrData: state.hrDetails,
  academicYearList: state.academicYear,
  departmentList: state.department,
  parentChildList: state.parentProfile,
});

const mapDispatchToProps = {
  onToggle,
  logOut,
  getAcademicYear,
  getCurrentAcademicYear,
  getResponse,
  getAlert,
  getDepartmentByuserId,
  getStudentDashboardInfo,
  getParentChild,
  downloadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar1);
