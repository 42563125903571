import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../Alert/alert.slice";
import { resetReducList } from "../../CommonActions/User.action";
import DynamicForm from "../../components/dynamicscreens/DynamicForm";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicTable";
import { FilterSave } from "../../components/FilterSave";
import endpoints from "../../config/endpoints";
import { TeachingPlanJson } from "../../DynamicFormsJson/TeachingPlan";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import { getYearDetails } from "../InternshipDeclaration/InternshipDeclataration.slice";
import { showNotification } from "../Landing/Landing.slice";
import {
  getSubjectByDepartment,
  getSubjectBySemisterAndDept,
  getSubjectSchemeBySubjectId,
} from "../Masters/Admission/Subject Info/subjectInfo.slice";
import { getDepartmentByuserId } from "../Masters/Internship/Department/department.slice";
import { getClassByProgramTitle } from "../Masters/OnlineModule/Class/class.slice";
import {
  getProgramTitleByProgTypeId,
  getProgramType,
} from "../Masters/OnlineModule/ProgramType/programType.slice";
import { getTimeSlot } from "../Masters/TimeTable/Time-Slot/timeSlot.slice";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { getTeachingPlanBySemister, resetValues } from "./teachingPlan.slice";

// import GenerateTimeTableForm from "../GenerateTimeTable/index";
// import ViewOfTimeTable from './ViewOfTimeTable';
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};
class TeachingPlan extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr.no ",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "departmentName",
      title: "Department Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "subject",
      title: "Subject",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "date",
      title: "Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: "15%",
    },
    {
      name: "remark",
      title: "Remark",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
      width: "15%",
    },
    {
      name: "topicTought",
      title: "Topic Tought",
      positionCenter: false,
      showInscreen: true,
      canSearch: true,
      alignCenter: "center",
      width: "15%",
    },

    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.internshipDeclarationList?.yearDetails,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        divisionMaster: this.props.internshipDeclarationList?.distinctDivision,
      },
      dynamicMasterFormData: {
        prograTypeMaster: this.props.programType?.programType,
        programTitleMaster: this.props.programType?.progTitleByProgType,
        yearMaster: this.props.yearDetails?.yearDetailsByProgramTitle,
        semisterMaster: this.props.rollNoData?.semisterDetailsList,
        subjectSchemeMaster: this.props.subjectList?.subjectById.subjectDetails
          ? this.props.subjectList?.subjectById.subjectDetails
          : [],
        subjectMaster: this.props.subjectList.subjectByDepartment,
        DepartmentMaster: this.props.departmentList.departmentByUserId,
      },
      open: false,
      showLoader: false,
      timeTableHeaderData: {},
      fieldData: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.internshipDeclarationList !==
      nextProps.internshipDeclarationList
    ) {
      if (
        this.props.internshipDeclarationList.yearDetails !==
        nextProps.internshipDeclarationList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.internshipDeclarationList.yearDetails,
          },
        });
      }
      if (
        this.props.internshipDeclarationList.distinctDivision !==
        nextProps.internshipDeclarationList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster:
              nextProps.internshipDeclarationList.distinctDivision,
          },
        });
      }
    }
    if (this.props.programType !== nextProps.programType) {
      if (
        this.props.programType.programType !== nextProps.programType.programType
      ) {
        this.setState({
          dynamicMasterFormData: {
            ...this.state.dynamicMasterFormData,
            prograTypeMaster: nextProps.programType.programType.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
      if (
        this.props.programType.progTitleByProgType !==
        nextProps.programType.progTitleByProgType
      ) {
        this.setState({
          dynamicMasterFormData: {
            ...this.state.dynamicMasterFormData,
            programTitleMaster:
              nextProps.programType.progTitleByProgType.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }
    if (this.props.yearDetails !== nextProps.yearDetails) {
      if (
        this.props.yearDetails.yearDetailsByProgramTitle !==
        nextProps.yearDetails.yearDetailsByProgramTitle
      ) {
        this.setState({
          dynamicMasterFormData: {
            ...this.state.dynamicMasterFormData,
            yearMaster: nextProps.yearDetails.yearDetailsByProgramTitle.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
    if (this.props.subjectList !== nextProps.subjectList) {
      if (
        this.props.subjectList.subjectByDepartment !==
        nextProps.subjectList.subjectByDepartment
      ) {
        this.setState({
          dynamicMasterFormData: {
            ...this.state.dynamicMasterFormData,
            subjectMaster: nextProps.subjectList.subjectByDepartment.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
      if (
        this.props.subjectList.subjectById !== nextProps.subjectList.subjectById
      ) {
        this.setState({
          dynamicMasterFormData: {
            ...this.state.dynamicMasterFormData,
            subjectSchemeMaster:
              nextProps.subjectList.subjectById.subjectDetails,
          },
        });
      }
    }
    if (this.props.departmentList !== nextProps.departmentList) {
      if (
        this.props.departmentList.departmentByUserId !==
        nextProps.departmentList.departmentByUserId
      ) {
        this.setState({
          dynamicMasterFormData: {
            ...this.state.dynamicMasterFormData,
            DepartmentMaster:
              nextProps.departmentList.departmentByUserId.filter(
                (data) => data.isActive == 1
              ),
          },
        });
      }
    }

    if (this.props.rollNoData !== nextProps.rollNoData) {
      if (
        this.props.rollNoData.semisterDetailsList !==
        nextProps.rollNoData.semisterDetailsList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList,
          },
          dynamicMasterFormData: {
            ...this.state.dynamicMasterFormData,
            semisterMaster: nextProps.rollNoData.semisterDetailsList.filter(
              (data) => data.isActive == 1
            ),
          },
        });
      }
    }
  }
  componentDidMount() {
    const {
      getYearDetails,
      getProgramType,
      getTimeSlot,
      getDepartmentByuserId,
    } = this.props;

    this.props.resetReducList();
    const ay = localStorage.getItem("acadamicYearId");
    const yearDetailId = localStorage.getItem("yearDetailId");

    if (yearDetailId) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: yearDetailId,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
    const semisterDetailId = localStorage.getItem("semisterDetailId");

    if (semisterDetailId) {
      this.setState({
        showLoader: true,
      });
      this.props
        .getTeachingPlanBySemister({
          semister: semisterDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
            this.setState({
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
    getTimeSlot().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getDepartmentByuserId().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    getProgramType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }
  getListById = (data) => {
    const ay = localStorage.getItem("acadamicYearId");
    if (data.yearDetail) {
      this.props
        .getSemesterDetailsByYearAndAcademic({
          yearId: data.yearDetail,
          academicId: ay,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};

    this.setState({
      showLoader: true,
    });
    TeachingPlanJson.fieldMeta.map((currentField) => {
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
        });
        if (dataToSearch.programType) {
          this.props
            .getProgramTitleByProgTypeId({
              progTypeId: dataToSearch.programType,
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }
        if (dataToSearch.programTitle) {
          this.props
            .getClassByProgramTitle({
              programTitle: dataToSearch.programTitle,
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }
        if (dataToSearch.yearDetail) {
          this.props
            .getSemesterDetailsByYearAndAcademic({
              yearId: dataToSearch.yearDetail,
              academicId: "",
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }
        if (dataToSearch.department) {
          this.props
            .getSubjectByDepartment({
              departmentId: dataToSearch.department,
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }
        if (dataToSearch.subject) {
          this.props
            .getSubjectSchemeBySubjectId({
              subjectId: dataToSearch.subject,
            })
            .then((response) => {
              if (!response) {
                this.setState({
                  showLoader: false,
                });
                this.props.getAlert({ message: "Something went wrong" });
              } else {
                this.setState({
                  showLoader: false,
                });
              }
            });
        }
      }
    });
    this.setState({
      fieldData: { ...this.state.fieldData, [fieldName]: newValue },
    });
  };

  onSearchData = (dataToSearch) => {
    this.setState({
      showLoader: false,
    });
    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", "");
    localStorage.setItem("divisionIds", "");
    FilterSave();
    this.props
      .getTeachingPlanBySemister({
        semister: dataToSearch.semister,
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };
  onAddButtonClick = () => {
    this.setState({
      open: true,
      currentOperationMode: OperationMode.Add,
      fieldData: {},
    });
    // window.location.replace("/genrate-time-table");
  };

  rowEdit = (rowData) => {
    this.toggleFormTableVisibility();
    const response = apiGet({
      url: TeachingPlanJson.apiBaseURL + "/" + rowData.id,
    }).then(({ data, success }) => {
      if (success) {
        console.log(data);
        let row = data.data;
        let fieldData = {};
        console.log(rowData);
        console.log(row);
        fieldData["id"] = row.id;
        fieldData["yearDetail"] = row.semisterDetail.semister.yearDetailId;
        fieldData["programType"] = row.yearDetail.programTitle.programType.id;
        fieldData["programTitle"] = row.yearDetail.programTitle.id;
        fieldData["semister"] = row.semisterDetail.id;
        fieldData["remark"] = row.remark;
        fieldData["department"] = row.department.id;
        fieldData["topicTought"] = row.topicTaught;
        fieldData["subject"] = row.subject.id;
        fieldData["scheme"] = row.subjectDetail.id;
        if (row.date) {
          const dateArr = row.date?.split("-");

          fieldData["date"] = new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
        }
        if (row.toDate) {
          const dateArr = row.toDate?.split("-");

          fieldData["toDate"] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }

        fieldData["isActive"] =
          row.isActive === "Active" || row.isActive === 1 ? 1 : 0;
        this.props.getDepartmentByuserId().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        this.props.getProgramType().then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
        if (row.yearDetail.programTitle.programType.id) {
          this.props
            .getProgramTitleByProgTypeId({
              progTypeId: row.yearDetail.programTitle.programType.id,
            })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
        }
        if (row.department.id) {
          this.props
            .getSubjectByDepartment({
              departmentId: row.department.id,
            })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
        }

        if (row.yearDetail.programTitle.id) {
          this.props
            .getClassByProgramTitle({
              programTitle: row.yearDetail.programTitle.id,
            })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
        }
        if (row.subject.id) {
          this.props
            .getSubjectSchemeBySubjectId({
              subjectId: row.subject.id,
            })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
        }

        if (row.semisterDetail.semister.yearDetailId) {
          this.props
            .getSemesterDetailsByYearAndAcademic({
              yearId: fieldData.yearDetail,
              academicId: "",
            })
            .then((response) => {
              if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
              }
            });
        }
        this.setState({
          fieldData: fieldData,
          currentOperationMode: OperationMode.Edit,
          date: rowData.date,
          userRegistration: rowData.userRegistration,
        });
      }
    });
  };

  onFormCancel = () => {
    this.toggleFormTableVisibility();
    this.props.resetValues();
  };

  toggleFormTableVisibility = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      openSnackbar: true,
    });
  };

  onFormSave = (isToggle) => () => {
    const { fieldData, currentOperationMode } = this.state;

    let dataToSave = {
      isActive: fieldData.isActive ? fieldData.isActive : "1",
    };

    if (currentOperationMode === OperationMode.Edit) {
      dataToSave["id"] = fieldData["id"];
    }
    let date = new Date(fieldData.date);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    dataToSave = {
      ...dataToSave,
      semisterDetail: {
        id: fieldData.semister,
      },
      subjectDetail: {
        id: fieldData.scheme,
      },
      topicTaught: fieldData.topicTought,
      department: {
        id: fieldData.department,
      },
      date: day + "-" + month + "-" + year,
      remark: fieldData.remark,
    };

    console.log(dataToSave);
    swal({
      title: "Are you sure?",
      text: "Once Submitted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((response) => {
      if (response) {
        this.onSave(dataToSave, isToggle);
      }
    });
  };

  onSave = (dataToSave, isToggle = true) => {
    apiPost({
      url: TeachingPlanJson.apiBaseURL,
      postBody: dataToSave,
    }).then(({ success }) => {
      if (success) {
        const { currentOperationMode } = this.state;
        this.props.showNotification({
          msg:
            currentOperationMode === OperationMode.Add
              ? "Data saved successfully"
              : currentOperationMode === OperationMode.Status
              ? "Status updated"
              : "Data updated successfully",
        });
        if (isToggle) {
          this.toggleFormTableVisibility();
          this.props.resetValues();
        }
        this.setState({
          currentOperationMode: "",
          fieldData: {},
        });
      } else {
        this.props.getAlert({
          message: "Failed to save",
        });
      }
    });
  };

  onClickEdit = (row) => {
    this.setState({
      open: true,
    });
    this.rowEdit(row);
  };
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiDelete({
          url: endpoints.teachingPlan + "/" + rowData.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props
              .getTeachingPlanBySemister({
                semister: rowData.semister,
              })
              .then((response) => {
                if (!response) {
                  this.props.getAlert({ message: "Something went wrong" });
                }
              });
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  changeStatus = (row) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const tableHeader = {
          id: row.id,
          semisterDetail: {
            id: row.semisterDetail,
          },
          division: {
            id: row.division,
          },
          fromDate: row.fromDate,
          toDate: row.toDate,
          remark: row.remark,
          isActive: row.isActive === 1 ? 0 : 1,
        };
        console.log(tableHeader);
        apiPost({
          url: endpoints.timeTableHeader,
          postBody: tableHeader,
        }).then(({ data, success }) => {
          if (success) {
          } else {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
      }
    });
  };
  handleClickOpen = () => {
    window.location.replace("/view-time-table");
  };

  render() {
    const { teachingPlan } = this.props;
    const {
      dynamicMasterData,
      showLoader,
      dynamicMasterFormData,
      open,
      fieldData,
    } = this.state;
    return (
      <>
        {!open && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={TeachingPlanJson.screenTitle}
            fieldMeta={TeachingPlanJson.fieldMetaForTable}
            buttonCenter={TeachingPlanJson.buttonCenter}
            showPdfDownload={TeachingPlanJson.showPdfDownload}
            showExcelDownload={TeachingPlanJson.showExcelDownload}
            pdfFileName={TeachingPlanJson.pdfFileName}
            showLoader={showLoader}
            excelFileName={TeachingPlanJson.excelFileName}
            tableColumnsToFilter={TeachingPlanJson.tableColumnsToFilter}
            showAddButton={TeachingPlanJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={this.columns}
            tableData={teachingPlan?.subject}
            getListById={this.getListById}
            showPegination={false}
            // showView={true}
            showHeadEdit={true}
            showHeadDelete={true}
            // showPegination={false}
            rowEdit={this.onClickEdit}
            rowDelete={this.rowDelete}
            onSearchData={this.onSearchData}
            baseIdColumn={TeachingPlanJson.baseIdColumn}
            apiBaseURL={TeachingPlanJson.apiBaseURL}
          />
        )}
        {open && (
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            <DynamicForm
              fieldMeta={TeachingPlanJson.fieldMeta}
              dynamicMasterData={dynamicMasterFormData}
              fieldData={fieldData}
              onDataChange={this.onDataChange}
              showSaveNextBtn={false}
              onCancel={this.onFormCancel}
              onSave={this.onFormSave(true)}
              showTitle={true}
              screenTitle={TeachingPlanJson.screenTitle}
            />
          </Paper>
        )}
        {/* {openView && (
          <ViewOfTimeTable
            onCancle={this.handleClickOpen}
            timeTableHeaderData={this.state.timeTableHeaderData}
          />
        )} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  programType: state.programType,
  yearDetails: state.class,
  teachingPlan: state.teachingPlan,
  departmentList: state.department,
  subjectList: state.subjectInfo,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getTimeSlot,
  getSemesterDetailsByYearAndAcademic,
  getProgramType,
  getProgramTitleByProgTypeId,
  getClassByProgramTitle,
  getTeachingPlanBySemister,
  getSubjectBySemisterAndDept,
  getDepartmentByuserId,
  getSubjectByDepartment,
  getSubjectSchemeBySubjectId,
  showNotification,
  resetValues,
  resetReducList,
};
export default connect(mapStateToProps, mapDispatchToProps)(TeachingPlan);
