import { Paper } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../config/endpoints";
import { showNotification } from "../../Pages/Landing/Landing.slice";
import { apiPost } from "../../utils/api_service";
// import ListOfCertificatePopUp from "./ListOfCertificatePopUp";
// import { getCertificateListOfStudent } from "./studentCertificate.slice";
import ListOfCertificatePopUp from "./LetterDetailsPopup";
const InternshipLetter = ({
  //   getCertificateListOfStudent,
  studentCertificateList,
  getAlert,
  showNotification,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [showTable, setShowTable] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    // setShowLoader(true);
    // getCertificateListOfStudent().then((response) => {
    //   if (!response) {
    //     setShowLoader(false);
    //     getAlert({ message: "Something went wrong" });
    //   } else {
    //     setShowLoader(false);
    //   }
    // });
  }, []);

  const rowData = [
    {
      index: 1,
      certificateList: "Internship Letter",
    },
  ];

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "certificateList",
      title: "Certificate",
      positionCenter: false,
      canSearch: true,
      showInscreen: true,
      alignCenter: "left",
      width: "40%",
    },
    {
      name: "action",
      title: "Actions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];

  const rowView = (data) => {
    setDetailId(data.id);
    setType(data.type);
    setShowTable(true);
    setShowPopUp(true);
  };

  const handleCloses = () => {
    setShowTable(true);
    setShowPopUp(false);
  };

  const OnSave = (datatoSave) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        const formData = new FormData();
        formData.append("file", datatoSave.file);
        apiPost({
          url: endpoints.fileUpload,
          postBody: formData,
        }).then(({ data, success }) => {
          let docs = {
            certificateApplicationHeader: {
              id: detailId,
            },
            detail: datatoSave.details,
            userRemark: datatoSave.userRemark,
            type: type,
            documentUpload: Object.keys(data).length === 0 ? "" : data,
            is_paid: 0,
            payDetail: "",
            amount: 0,
            status: 0,
            deliveryDate: "",
          };
          apiPost({
            url: endpoints.certificate,
            postBody: docs,
          }).then(({ success }) => {
            if (success) {
              showNotification({
                msg: "Applied Successfully",
              });
              setShowTable(true);
              setShowPopUp(false);
            } else {
              getAlert({
                message: "Failed to save",
              });
              setShowTable(true);
              setShowPopUp(false);
            }
          });
        });
      }
    });
  };
  const onSearch = (value) => {
    setSearchValue(value);
  };
  const getFilteredTableData = () => {
    return studentCertificateList?.certificateListOfStudent.filter(
      (currentRow) => {
        let isValid = false;
        if (
          (currentRow["certificateList"] &&
            currentRow["certificateList"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["payment"] &&
            currentRow["payment"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (currentRow["index"] &&
            currentRow["index"]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
        ) {
          isValid = true;
          return true;
        }
        return isValid;
      }
    );
  };
  console.log(rowData);
  return (
    <>
      {showTable && (
        <>
          <Paper sx={{ p: 1, pt: 2, borderRadius: 2 }} elevation={0}>
            <DynamicTable
              data={rowData}
              tableHead={columns}
              showPegination={false}
              showApply={true}
              rowView={rowView}
            />
          </Paper>
        </>
      )}

      {showPopUp && (
        <>
          <ListOfCertificatePopUp onSave={OnSave} handleCloses={handleCloses} />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  studentCertificateList: state.studentCertificate,
});
const mapDispatchToProps = {
  //   getCertificateListOfStudent,
  getAlert,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(InternshipLetter);
