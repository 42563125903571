import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../../config/endpoints";
import { apiGet } from "../../../../utils/api_service";
const initialState = {
  room: [],
  isFetch: false,
  roomByDate: [],
  isFetchRoomByDate: false,
};
let URL = endpoints.room;
const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    roomSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        room: row,
        isFetch: true,
      };
    },
    roomByDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        roomByDate: row,
        isFetchRoomByDate: true,
      };
    },
  },
});

export const { roomSuccess, roomByDateSuccess } = roomSlice.actions;

export default roomSlice.reducer;

export const getRoom = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertdatetime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        let index = 0;

        const row = content.map((data1) => {
          index = index + 1;

          let bData = {
            index: index,
            id: data1.id,
            name: data1.name,
            floorName: data1.floor.name,
            floor: data1.floor.id,
            roomCapacity: data1.roomCapacity,
            examCapacity: data1.examCapacity,
            isActive: data1.isActive,
          };
          return bData;
        });
        dispatch(roomSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getRoomForExamConduct =
  ({ scheduleDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-room-avability-for-exam?scheduleDate=" + scheduleDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.name + " - " + data1.examCapacity,
              floorName: data1.floor.name,
              floor: data1.floor.id,
              roomCapacity: data1.roomCapacity,
              examCapacity: data1.examCapacity,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(roomSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getRoomByScheduleDate =
  ({ scheduleDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-room-by-schedule-date?scheduleDate=" + scheduleDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let index = 0;

          const row = content.map((data1) => {
            index = index + 1;

            let bData = {
              index: index,
              id: data1.id,
              name: data1.name + " - " + data1.examCapacity,
              floorName: data1.floor.name,
              floor: data1.floor.id,
              roomCapacity: data1.roomCapacity,
              examCapacity: data1.examCapacity,
              isActive: data1.isActive,
            };
            return bData;
          });
          dispatch(roomByDateSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
