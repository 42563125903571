import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
const initialState = {
  admissionListStatus: [],
  receiptListStatus: [],
  isFetchStatus: false,
  receiptIsFetchStatus: false,
  // addFeesStructureHeader: [],
  isFetchAddFeesStructureHeader: false,
};

let URL = endpoints.admissionList;
let URL1 = endpoints.receiptList;

const feesCollectionSlice = createSlice({
  name: "admissionList",
  initialState,
  reducers: {
    admissionListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        admissionListStatus: row,
        isFetchStatus: true,
      };
    },

    receiptListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        receiptListStatus: row,
        receiptIsFetchStatus: true,
      };
    },
    admissionListFail: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        documentVerificationDataList: [],
        isFetchDocumentVerificationDataList: false,
      };
    },

    addFeesStructureHeaderSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      // const { row } = action.payload;
      return {
        ...state,
        // addFeesStructureHeader: row,
        isFetchAddFeesStructureHeader: true,
      };
    },

    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        admissionListStatus: [],
        receiptListStatus: [],
        isFetchStatus: false,
        receiptIsFetchStatus: false,
        // addFeesStructureHeader: [],
        isFetchAddFeesStructureHeader: false,
      };
    },
  },
});

export const {
  admissionListSuccess,
  receiptListSuccess,
  resetSuccess,
  addFeesStructureHeaderSuccess,
} = feesCollectionSlice.actions;

export default feesCollectionSlice.reducer;

// export const getAdmissionList =
//   ({ academicId, yearDetailId }) =>
//   async (dispatch) => {
//     try {
//       // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
//       // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
//       const response = apiGet({
//         url:
//           URL +
//           "/get-admission-list-by-academic-and-yearDetail-id?academicYearId=" +
//           academicId +
//           "&yearDetailId=" +
//           yearDetailId,
//       }).then(({ data, success }) => {
//         if (success) {
//           const programtitleList = data.data;
//           // let index = 0;
//           let row = [];
//           let index = 0;
//           row = programtitleList.map((data) => {
//             index = index + 1;
//             let feesCollectionData = {
//               index: index,
//               id: data.id,
//               userRegistrationId:
//                 data.admissionRegistration.userRegistrationId === null
//                   ? ""
//                   : data.admissionRegistration.userRegistrationId,
//               applicantName:
//                 data.admissionRegistration.getUserRegistration === null
//                   ? ""
//                   : data.admissionRegistration.getUserRegistration.firstName +
//                     " " +
//                     data.admissionRegistration.getUserRegistration.middleName +
//                     " " +
//                     data.admissionRegistration.getUserRegistration.lastName,
//               studentMobile:
//                 data.admissionRegistration.getUserRegistration !== null
//                   ? data.admissionRegistration.getUserRegistration.studentMobile
//                   : "",
//               studentEmail:
//                 data.admissionRegistration.getUserRegistration !== null
//                   ? data.admissionRegistration.getUserRegistration.studentEmail
//                   : "",
//               progTitle:
//                 data.getYearDetailId.programTitle === null
//                   ? ""
//                   : data.getYearDetailId.programTitle.brName,
//               category:
//                 data.admissionRegistration.getUserRegistration === null
//                   ? ""
//                   : data.admissionRegistration.getUserRegistration.categoryId,
//               progName:
//                 data.getYearDetailId.programTitle.programType === null
//                   ? ""
//                   : data.getYearDetailId.programTitle.programType.program.name,
//               className:
//                 data.getYearDetailId.year === null
//                   ? ""
//                   : data.getYearDetailId.year.className,
//               totalFees:
//                 data.totalApplicableFees === null
//                   ? ""
//                   : data.totalApplicableFees,

//               program:
//                 data.getYearDetailId.year.programType.program.name === null
//                   ? ""
//                   : data.getYearDetailId.year.programType.program.name,
//               paidStatus:
//                 data.paidStatus === 1
//                   ? "Partial"
//                   : data.paidStatus === 2
//                   ? "Paid"
//                   : "Unpaid",
//               yearDetailId: data.yearDetailId,
//               academicYearId: data.admissionRegistration.academicYearId,
//               academicYearName: data.admissionRegistration.getAcademicYear.name,
//               admissionRegId: data.admissionRegistration.id,
//             };
//             return feesCollectionData;
//           });
//           dispatch(admissionListSuccess({ row }));
//           return success;
//         }
//         return success;
//       });
//       return response;
//     } catch (e) {
//       return console.error(e.message);
//     }
//   };

// changes
export const getAdmissionList =
  ({ academicId, yearDetailId }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = await apiGet({
        url:
          URL +
          "/get-admission-list-by-academic-and-yearDetail-id-new?academicYearId=" +
          academicId +
          "&yearDetailId=" +
          yearDetailId,
      }).then(({ data, success }) => {
        if (success) {
          const programtitleList = data.data;
          // let index = 0;
          let row = [];
          let index = 0;
          row = programtitleList.map((data) => {
            index = index + 1;

            let feesCollectionData = {
              index: index,
              id: data.id,
              
              userRegistrationId:
                data.admissionRegistration.userRegistrationId === null
                  ? ""
                  : data.admissionRegistration.userRegistrationId,
              applicantName:
                data.admissionRegistration.getUserRegistration === null
                  ? ""
                  : data.admissionRegistration.getUserRegistration.lastName +
                    " " +
                    data.admissionRegistration.getUserRegistration.firstName +
                    " " +
                    (data.admissionRegistration.getUserRegistration
                      .fatherName == null ||
                    data.admissionRegistration.getUserRegistration.fatherName ==
                      ""
                      ? " - "
                      : data.admissionRegistration.getUserRegistration
                          .fatherName) +
                    " " +
                    (data.admissionRegistration.getUserRegistration
                      .motherName == null ||
                    data.admissionRegistration.getUserRegistration.motherName ==
                      ""
                      ? " - "
                      : data.admissionRegistration.getUserRegistration
                          .motherName),

              lastName:
                data.admissionRegistration != null
                  ? data.admissionRegistration.getUserRegistration.lastName
                  : null,
              firstName:
                data.admissionRegistration != null
                  ? data.admissionRegistration.getUserRegistration.firstName
                  : null,
              fatherName:
                data.admissionRegistration != null
                  ? data.admissionRegistration.getUserRegistration.fatherName
                  : null,
              motherName:
                data.admissionRegistration != null
                  ? data.admissionRegistration.getUserRegistration.motherName
                  : null,

              studentMobile:
                data.admissionRegistration.getUserRegistration !== null
                  ? data.admissionRegistration.getUserRegistration.studentMobile
                  : "",
              studentEmail:
                data.admissionRegistration.getUserRegistration !== null
                  ? data.admissionRegistration.getUserRegistration.studentEmail
                  : "",
              progTitle:
                data.getYearDetailId === null
                  ? ""
                  : data.getYearDetailId.programTitle.brName,
              category:
                data.admissionRegistration.getUserRegistration === null
                  ? ""
                  : data.admissionRegistration.getUserRegistration.categoryId,
              progName:
                data.getYearDetailId === null
                  ? ""
                  : data.getYearDetailId.programTitle.programType.program.name,
              className:
                data.getYearDetailId === null
                  ? ""
                  : data.getYearDetailId.year.className,
              totalFees:
                data.totalApplicableFees === null
                  ? ""
                  : data.totalApplicableFees,

              program:
                data.getYearDetailId === null
                  ? ""
                  : data.getYearDetailId.year.programType.program.name,
              paidStatus:
                data.paidStatus === 1
                  ? "Partial"
                  : data.paidStatus === 2
                  ? "Paid"
                    : "Unpaid",
              claimNo: data.flag == 0 ? "Link Not Generated" : "Generated",
               flag:data.flag,
              yearDetailId: data.yearDetailId,
              // admissionStatus: data.admissionStatus == 1 ? "Confirmed" : data.admissionStatus == 2 ? "Year Drop" : data.admissionStatus == 3 ? "Cancelled" : data.admissionStatus == 4 ? "Detained" : data.admissionStatus == 5 ? "Provisional" : " ",
              admissionStatus: data.admissionStatus,
              academicYearId: data.admissionRegistration.academicYearId,
              academicYearName: data.admissionRegistration.getAcademicYear.name,
              admissionRegId: data.admissionRegistration.id,
            };
            return feesCollectionData;
          });
          dispatch(admissionListSuccess({ row }));
          return success;
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
    };
  
  //   export const getAdmissionList =
  // ({ academicId, yearDetailId }) =>
  // async (dispatch) => {
  //   try {
  //     // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
  //     // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
  //     const response = await apiGet({
  //       url:
  //         URL +
  //         "/get-admission-list-by-academic-and-yearDetail-id?academicYearId=" +
  //         academicId +
  //         "&yearDetailId=" +
  //         yearDetailId,
  //     }).then(({ data, success }) => {
  //       if (success) {
  //         const programtitleList = data.data;
  //         // let index = 0;
  //         let row = [];
  //         let index = 0;
  //         row = programtitleList.map((data) => {
  //           index = index + 1;

  //           let feesCollectionData = {
  //             index: index,
  //             id: data.id,
              
  //             userRegistrationId:
  //               data.admissionRegistration.userRegistrationId === null
  //                 ? ""
  //                 : data.admissionRegistration.userRegistrationId,
  //             applicantName:
  //               data.admissionRegistration.getUserRegistration === null
  //                 ? ""
  //                 : data.admissionRegistration.getUserRegistration.lastName +
  //                   " " +
  //                   data.admissionRegistration.getUserRegistration.firstName +
  //                   " " +
  //                   (data.admissionRegistration.getUserRegistration
  //                     .fatherName == null ||
  //                   data.admissionRegistration.getUserRegistration.fatherName ==
  //                     ""
  //                     ? " - "
  //                     : data.admissionRegistration.getUserRegistration
  //                         .fatherName) +
  //                   " " +
  //                   (data.admissionRegistration.getUserRegistration
  //                     .motherName == null ||
  //                   data.admissionRegistration.getUserRegistration.motherName ==
  //                     ""
  //                     ? " - "
  //                     : data.admissionRegistration.getUserRegistration
  //                         .motherName),

  //             lastName:
  //               data.admissionRegistration != null
  //                 ? data.admissionRegistration.getUserRegistration.lastName
  //                 : null,
  //             firstName:
  //               data.admissionRegistration != null
  //                 ? data.admissionRegistration.getUserRegistration.firstName
  //                 : null,
  //             fatherName:
  //               data.admissionRegistration != null
  //                 ? data.admissionRegistration.getUserRegistration.fatherName
  //                 : null,
  //             motherName:
  //               data.admissionRegistration != null
  //                 ? data.admissionRegistration.getUserRegistration.motherName
  //                 : null,

  //             studentMobile:
  //               data.admissionRegistration.getUserRegistration !== null
  //                 ? data.admissionRegistration.getUserRegistration.studentMobile
  //                 : "",
  //             studentEmail:
  //               data.admissionRegistration.getUserRegistration !== null
  //                 ? data.admissionRegistration.getUserRegistration.studentEmail
  //                 : "",
  //             progTitle:
  //               data.getYearDetailId === null
  //                 ? ""
  //                 : data.getYearDetailId.programTitle.brName,
  //             category:
  //               data.admissionRegistration.getUserRegistration === null
  //                 ? ""
  //                 : data.admissionRegistration.getUserRegistration.categoryId,
  //             progName:
  //               data.getYearDetailId === null
  //                 ? ""
  //                 : data.getYearDetailId.programTitle.programType.program.name,
  //             className:
  //               data.getYearDetailId === null
  //                 ? ""
  //                 : data.getYearDetailId.year.className,
  //             totalFees:
  //               data.totalApplicableFees === null
  //                 ? ""
  //                 : data.totalApplicableFees,

  //             program:
  //               data.getYearDetailId === null
  //                 ? ""
  //                 : data.getYearDetailId.year.programType.program.name,
  //             paidStatus:
  //               data.paidStatus === 1
  //                 ? "Partial"
  //                 : data.paidStatus === 2
  //                 ? "Paid"
  //                   : "Unpaid",
  //                   claimNo:data.claimNo==0 ? "Link Not Generated" : "Generated",
  //             yearDetailId: data.yearDetailId,
  //             // admissionStatus: data.admissionStatus == 1 ? "Confirmed" : data.admissionStatus == 2 ? "Year Drop" : data.admissionStatus == 3 ? "Cancelled" : data.admissionStatus == 4 ? "Detained" : data.admissionStatus == 5 ? "Provisional" : " ",
  //             admissionStatus: data.admissionStatus,
  //             academicYearId: data.admissionRegistration.academicYearId,
  //             academicYearName: data.admissionRegistration.getAcademicYear.name,
  //             admissionRegId: data.admissionRegistration.id,
  //           };
  //           return feesCollectionData;
  //         });
  //         dispatch(admissionListSuccess({ row }));
  //         return success;
  //       }
  //       return success;
  //     });
  //     return response;
  //   } catch (e) {
  //     return console.error(e.message);
  //   }
  // };

export const getReceiptList =
  ({ id, financeYearId, receiptTypeId }) =>
  async (dispatch) => {
    try {
      // http://localhost:8088/application/v1/user-round-allocation/update-document-verification-status?id=8225c734-eb9d-40ed-9510-2c91a7757ea6&verifyDocumentStatus=1&remark=yesss
      // http://localhost:8088/admission/v1/admission-registration/get-admission-ist-by-academic-and-yearDetail-id?academicYearId=1e4ff192-6086-4a23-afd2-279601e129d5&yearDetailId=73f7d54d-8302-4292-b34e-81201088df8f
      const response = apiGet({
        url:
          URL1 +
          "/get-transaction-list-by-user-finance-year-and receipt-id?userRegistrationId=" +
          id +
          "&financeYearId=" +
          financeYearId +
          "&receiptTypeId=" +
          receiptTypeId,
      }).then(({ data, success }) => {
        if (success) {
          const receiptList = data.data;
          // let index = 0;
          let row = [];
          row = receiptList.map((data, index) => {
            let feesCollectionData = {
              index: index + 1,
              id: data.id,
              transactionNo: data.transactionNo,
              transactionDate: data.transactionDate,
              receiptType: data.receiptType.name,
              payType: data.payType === 0 ? "Offline" : "Online",
              amount: data.amt,
            };
            return feesCollectionData;
          });
          dispatch(receiptListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAddFeesStructureHeader =
  ({ admissionRegistrationDetailId, feesStructureId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/apply-fees-structure?admissionRegistrationDetailId=" +
          admissionRegistrationDetailId +
          "&feesStructureId=" +
          feesStructureId,
      }).then(({ data, success }) => {
        if (success) {
          dispatch(addFeesStructureHeaderSuccess());
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
