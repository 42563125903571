import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { PlacementReportJson } from "../../DynamicFormsJson/PlacementReport";
import {
  getDistinctDivision,
  getYearDetails,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getCompany } from "../Masters/Faculty/Company/company.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { getQualification } from "../QualificationMaster/qualification.slice";

class PlacementReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        placementType: [
          {
            id: "0",
            name: "On Campus",
            isActive: "Active",
          },
          {
            id: "1",
            name: "Off Campus",
            isActive: "Active",
          },
          {
            id: "2",
            name: "Pool Campus",
            isActive: "Active",
          },
        ],
        academicYear: this.props.academicYearList?.academicYear,
        yearDetails: this.props.yearDetailList?.yearDetails,
        division: this.props.yearDetailList?.distinctDivision,
        divisionIds: this.props.yearDetailList?.distinctDivision,
        company: this.props.companyList?.company,
        qualification: this.props.qualificationList?.qualification,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getCompany().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getQualification().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.yearDetails !==
        nextProps.yearDetailList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.yearDetailList.yearDetails,
          },
        });
      }
    }

    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.distinctDivision !==
        nextProps.yearDetailList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            division: nextProps.yearDetailList.distinctDivision,
            divisionIds: nextProps.yearDetailList.distinctDivision,
          },
        });
      }
    }

    if (this.props.companyList !== nextProps.companyList) {
      if (this.props.companyList.company !== nextProps.companyList.company) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            company: nextProps.companyList.company,
          },
        });
      }
    }

    if (this.props.qualificationList !== nextProps.qualificationList) {
      if (
        this.props.qualificationList.qualification !==
        nextProps.qualificationList.qualification
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            qualification: nextProps.qualificationList.qualification,
          },
        });
      }
    }
  }

  getListById = (data) => {
    if (data.yearDetailId) {
      this.props
        .getDistinctDivision({
          yearDetail: data.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  rowData = [
    {
      index: 1,
      reportName: "Placement Company Details",
      filter: "",
      url: "/api/report/v1/company-details-report?type=0",
    },
    {
      index: 2,
      reportName: "Placement List by Company (D0, D1, D3, D4)",
      filter: "academicYearId,yearDetailId,divisionIds,companyId",
      url: "/api/report/v1/placement-by-company-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionIds={divisionIds}&companyId={companyId}",
    },
    {
      index: 3,
      reportName: "External Placement (D0, D1, D2, D5)",
      filter: "academicYearId,yearDetailId,division,placementType",
      url: "/api/report/v1/student-external/placement-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionId={division}&placementType={placementType}",
    },
    {
      index: 4,
      reportName: "Student List division-wise (D0, D1, D3)",
      filter: "academicYearId,yearDetailId,divisionIds",
      excelUrl:
        "/api/exportexcel/v1/division-student-excel-report?response&yearDetailId={yearDetailId}&academicYearId={academicYearId}&divisionIds={divisionIds}",
      url: "/api/report/v1/division-student-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionIds={divisionIds}",
    },
    {
      index: 5,
      reportName:
        "Report To Display Details Of Student Interested In Placement Classwise (D0, D1)",
      filter: "academicYearId,yearDetailId",
      url: "/api/report/v1/get-placement-intrested-class-wise?yearDetailId={yearDetailId}&intrestStatus=1&documentType=0",
    },
    {
      index: 6,
      reportName:
        "Report To Display Details Of Students Who Are Not Placed Class Wise (D0, D1)",
      filter: "academicYearId,yearDetailId",
      url: "/api/report/v1/get-not-placed-students-class-wise?yearDetailId={yearDetailId}&selectedStatus=0&documentType=0",
    },
    {
      index: 7,
      reportName:
        "Report To Display Details Of Students Who Are Not Placed Year Wise (D0)",
      filter: "academicYearId",
      url: "/api/report/v1/get-not-placed-students-academic-year-wise?academicYearId={academicYearId}&selectedStatus=0&documentType=0",
    },
    // {
    //   index: 8,
    //   reportName:
    //     "Report To Display Details Of Students Who Are Eligible For Placement Criteria Wise & Class Wise (D1, D2, D6, D7)",
    //   filter: "division,yearDetailId,qualificationId,cutoff",
    //   url: "/api/exportexcel/v1/student-criteria/get-eligible-student-classwise?qualificationIds={qualificationId}&yearDetailId={yearDetailId}&divisionId={division}&cutoff={cutoff}&documentType=0",
    // },
    // {
    //   index: 9,
    //   reportName:
    //     "Report To Display Details Of Students Who Are Eligible For Placement Criteria Wise & Year Wise (D0, D6, D7)",
    //   filter: "yearDetailId,academicYearId,qualificationId,cutoff",
    //   url: "/api/exportexcel/v1/student-criteria/get-eligible-student-yearwise?acadamicYearId={academicYearId}&qualificationIds={qualificationId}&cutoff={cutoff}&yearDetailId={yearDetailId}",
    // },
    {
      index: 8,
      reportName:
        "Report To Display Details Of Student Who Are Placed Classwise (Placement Type wise) (D1, D2, D5)",
      filter: "yearDetailId,division,placementType",
      url: "/api/report/v1/placement-report?yearDetailId={yearDetailId}&divisionId={division}&placementType={placementType}&selectedStatus=1&documentType=1",
    },
    {
      index: 9,
      reportName:
        "Report To Display Details Of Student Who Are Placed Classwise (On Campus, Off Campus & Pool Campus) (D1, D2)",
      filter: "yearDetailId,division",
      url: "/api/report/v1/placement-report/get-all-placed-student-class-wise?yearDetailId={yearDetailId}&divisionId={division}&selectedStatus=1&documentType=1",
    },
    {
      index: 10,
      reportName:
        "Report To Display Details Of Student Who Are Placed Yearwise (Placement Type wise) (D0, D5)",
      filter: "academicYearId,placementType",
      url: "/api/report/v1/placement-report/get-placed-student-year-wise?acadamicYearId={academicYearId}&placementType={placementType}&selectedStatus=1&documentType=1",
    },
    {
      index: 11,
      reportName:
        "Report To Display Details Of Student Who Are Placed Yearwise (On Campus, Off Campus & Pool Campus) (D0)",
      filter: "academicYearId",
      url: "/api/report/v1/placement-report/get-all-placed-student-year-wise?acadamicYearId={academicYearId}&selectedStatus=1&documentType=1",
    },
  ];
  condition = [
    {
      title: "index",
      value: 8,
      iconHide: "showHeadPdf",
    },
    {
      title: "index",
      value: 9,
      iconHide: "showHeadPdf",
    },
    {
      title: "index",
      value: 10,
      iconHide: "showHeadExcel",
    },
    {
      title: "index",
      value: 11,
      iconHide: "showHeadExcel",
    },
    {
      title: "index",
      value: 12,
      iconHide: "showHeadExcel",
    },
    {
      title: "index",
      value: 13,
      iconHide: "showHeadExcel",
    },
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={PlacementReportJson.screenTitle}
          fieldMeta={PlacementReportJson.fieldMeta}
          buttonCenter={PlacementReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={PlacementReportJson.searchButton}
          PDFButton={PlacementReportJson.PDFButton}
          EXCELButton={PlacementReportJson.EXCELButton}
          baseIdColumn={PlacementReportJson.baseIdColumn}
          apiBaseURL={PlacementReportJson.apiBaseURL}
          marginTop={PlacementReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
          condition={this.condition}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  companyList: state.company,
  yearDetailList: state.internshipDeclaration,
  qualificationList: state.qualification,
});
const mapDispatchToProps = {
  getAcademicYear,
  getYearDetails,
  getDistinctDivision,
  getCompany,
  getQualification,
  getAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(PlacementReport);
