import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from '../../../../components/dynamicscreens/DynamicMainScreen'
import { designationColumns } from "../../../../tableColumns/table-columns";
import { designationJson } from "../../../../DynamicFormsJson/Designation";
import { getDesignation } from "./designation.slice";
class Designation extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
   
  }

  componentDidMount() {
    const { getDesignation } = this.props;
    getDesignation();
  }

  render() {
   
    const { designationList } = this.props;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={designationJson.screenTitle}
            fieldMeta={designationJson.fieldMeta}
            showPdfDownload={designationJson.showPdfDownload}
            showExcelDownload={designationJson.showExcelDownload}
            pdfFileName={designationJson.pdfFileName}
            excelFileName={designationJson.excelFileName}
            showAddButton={designationJson.showAddButton}
            tableColumnsToFilter={designationJson.tableColumnsToFilter}
            // dynamicMasterData={dynamicMasterData}
            tableColumns={designationColumns}
            tableData={designationList?.designation}
            getTableData={this.props.getDesignation}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={designationList.isFetch}
            apiBaseURL={designationJson.apiBaseURL}
            baseIdColumn={designationJson.baseIdColumn}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  designationList: state.designation,
});
const mapDispatchToProps = {
  getDesignation,
};
export default connect(mapStateToProps, mapDispatchToProps)(Designation);
