import React, { Component } from "react";
import { connect } from "react-redux";
import "../../CSS/paymentSuccess.css";
import { apiPostWithoutHeader } from "../../utils/api_service";
import {showNotification} from "../../Pages/Landing/Landing.slice";
import endpoint from "../../config/endpoints";
class PaymentSuccessPagePrelogin extends Component
{
  constructor( props )
  {
    super( props );
    this.state = { isError: false };
  }

  componentDidMount()
  {
    let response = window.location.search;
    console.log( "response" + response )
    const urlParams = new URLSearchParams( response );
    const returnResponse = urlParams.get( "returndata" );
    console.log( "returnResponse :" + returnResponse )
    let decodedResponse = atob( returnResponse );
    console.log( "converted data" + decodedResponse )
    if ( decodedResponse)
    {
      const response = JSON.parse( decodedResponse );
      if ( response.responseCode === '0000' )
      {
        apiPostWithoutHeader({
          url:endpoint.payTransaction +"/update-status?baseUrl="+window.location.host +"&transactionId="+response.invoiceNo+"&status="+"success"+"&data="+returnResponse+"&mihpayId="+response.merchantId,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Transaction successfull",
            } );
          } else {
            this.props.showNotification({
              msg: "Transaction failed",
              severity: "error",
            });
          }
        });
        console.log( 'Success' );
        this.setState( {
          isError:false
        })
      } else
      {
        console.log( 'Error' );
        apiPostWithoutHeader({
          url:endpoint.payTransaction +"/update-status?baseUrl="+window.location.host +"&transactionId="+response.invoiceNo+"&status="+"fail"+"&data="+returnResponse+"&mihpayId="+response.merchantId,
        }).then(({ success }) => {
          if (success) {
            this.props.showNotification({
              msg: "Transaction successfull",
            } );
          } else {
            this.props.showNotification({
              msg: "Transaction failed",
              severity: "error",
            });
          }
        });
        this.setState( {
          isError:true
        })
      }
    }
    else
    {
      console.log( 'Empty response' );
    }
  }

  render()
  {
    const { isError } = this.state;
    if ( isError )
    {
      return (
        <div className="payment-failed-section">
          <h1 className="payment-failed-heading">Payment Failed</h1>
          <p className="payment-failed-message">Oops! Something went wrong with your payment.</p>
        </div>
      );
    } else
    {
      return (
        <body className="payment-success-body">
        <div className="payment-success-card">
          <div
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#F8FAF5",
              margin: "0 auto",
            }}
          >
            <i className="payment-success-i">
              <span className="success-checkmark">✓</span>
            </i>
          </div>
          <h1 className="payment-success-h1">Success</h1>
          <p className="payment-success-p">
            Payment successfully done
            <br />
          </p>
        </div>
      </body>
      );
    }
  }
}
const mapStateToProps = ( state ) => ( {
} );
const mapDispatchToProps = {
  showNotification
};
export default connect( mapStateToProps, mapDispatchToProps )( PaymentSuccessPagePrelogin );
