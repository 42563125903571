import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";

const initialState = {
  deemedMeeting: [],
  isFetchDeemedMeeting: false,

  deemedMeetingHistory: [],
  isFetchDeemedMeetingHistory: false,

  deemedCommitteeMember: [],
  isFetchDeemedCommitteeMember: false,

  deemedMeetingAggenda: [],
  isFetchDeemedMeetingAggenda: false,

  meetingDocument: [],
  isFetchMeetingDocument: false,

  deemedMeetingById: {},
  isFetchDeemedMeetingById: false,

  bodySubData: {},
  isFetchBodySub: false,
};
let URL1 = endpoints.deemedMeeting;
let URL2 = endpoints.deemedAggenda;
const deemedMeetingSlice = createSlice({
  name: "deemedMeeting",
  initialState,
  reducers: {
    deemedMeetingSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        deemedMeeting: row,
        isFetchDeemedMeeting: true,
      };
    },

    getBodySubSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        bodySubData: row,
        isFetchBodySub: true,
      };
    },

    deemedMeetingHistorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        deemedMeetingHistory: row,
        isFetchDeemedMeetingHistory: true,
      };
    },

    deemedCommitteeMemberSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        deemedCommitteeMember: row,
        isFetchDeemedCommitteeMember: true,
      };
    },
    deemedMeetingAggendaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        deemedMeetingAggenda: row,
        isFetchDeemedMeetingAggenda: true,
      };
    },
    meetingDocumentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        meetingDocument: row,
        isFetchMeetingDocument: true,
      };
    },

    deemedMeetingByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { bData } = action.payload;
      return {
        ...state,
        deemedMeetingById: bData,
        isFetchDeemedMeetingById: true,
      };
    },
    resetSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        deemedMeeting: [],
        isFetchDeemedMeeting: false,
      };
    },
  },
});

export const {
  deemedMeetingSuccess,
  resetSuccess,
  deemedCommitteeMemberSuccess,
  deemedMeetingAggendaSuccess,
  deemedMeetingHistorySuccess,
  meetingDocumentSuccess,
  deemedMeetingByIdSuccess,
  getBodySubSuccess,
} = deemedMeetingSlice.actions;

export default deemedMeetingSlice.reducer;

export const getDeemedMeetingSchedule = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/get-scheduled",
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        let row = [];
        data.data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            details: data1.details,
            title: data1.title,
            name: data1.committee ? data1.committee.name : "",
            date: data1.date,
            time: data1.time,
            urlLink: data1.urlLink,
            venue: data1.venue,
            committee: data1.committee,
            closingRemark: data1.closingRemark,
            openingRemark: data1.openingRemark,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        console.log(row);
        dispatch(deemedMeetingSuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getDeemedMeeting =
  ({ committee, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-scheduled-and-date?committeeId=" +
          committee +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              details: data1.details,
              title: data1.title,
              name: data1.committee ? data1.committee.name : "",
              date: data1.date,
              time: data1.time,
              urlLink: data1.urlLink,
              venue: data1.venue,
              committee: data1.committee,
              closingRemark: data1.closingRemark,
              openingRemark: data1.openingRemark,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(deemedMeetingSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getMailBodyAndSub =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/get-mail-body-for-schedule-meeting",
      }).then(({ data, success }) => {
        if (success) {
          dispatch(getBodySubSuccess({ row: data.data }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

//""""""""

export const getDeemedMeetingHistory = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/get-history",
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        let row = [];
        data.data.map((data1) => {
          index = index + 1;
          let bData = {
            index: index,
            id: data1.id,
            details: data1.details,
            title: data1.title,
            name: data1.committee ? data1.committee.name : "",
            date: data1.date,
            time: data1.time,
            urlLink: data1.urlLink,
            venue: data1.venue,
            committee: data1.committee,
            closingRemark: data1.closingRemark,
            openingRemark: data1.openingRemark,
            isActive: data1.isActive,
          };
          row.push(bData);
          return data1;
        });
        dispatch(deemedMeetingHistorySuccess({ row }));
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getDeemedMeetingHistoryByFilter =
  ({ committee, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-history-and-date?committeeId=" +
          committee +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              details: data1.details,
              title: data1.title,
              name: data1.committee ? data1.committee.name : "",
              date: data1.date,
              time: data1.time,
              urlLink: data1.urlLink,
              venue: data1.venue,
              committee: data1.committee,
              closingRemark: data1.closingRemark,
              openingRemark: data1.openingRemark,
              isActive: data1.isActive,
            };
            row.push(bData);
            return data1;
          });
          dispatch(deemedMeetingHistorySuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDeemedAttendanceList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/get-meeting-attendance",
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              memberId: data1.committeeMember.id,
              memberName: data1.committeeMember.memberName,
              status: data1.status === 1 ? true : false,
              remark: data1.remark,
              attendance: false,
              answer: "",
              tableId: "",
            };
            row.push(bData);
            return data1;
          });
          dispatch(deemedCommitteeMemberSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDeemedAggendaList =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/get-meeting-agenda",
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              title: data1.title,
              details: data1.details,
            };
            row.push(bData);
            return data1;
          });
          dispatch(deemedMeetingAggendaSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getMeetingDocument =
  ({ id, ext }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id + "/get-meeting-documents?ext=" + ext,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let row = [];
          data.data.map((data1) => {
            index = index + 1;
            let bData = {
              index: index,
              id: data1.id,
              docDesc: data1.name,
              documentPath: data1.path,
              ext: data1.ext,
              deleteId: 0,
            };
            row.push(bData);
            return data1;
          });
          dispatch(meetingDocumentSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getDeemedMeetingById =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/" + id,
      }).then(({ data, success }) => {
        if (success) {
          let index = 0;
          let bData = {
            id: data.data.id,
            closingRemark: data.data.closingRemark,
            openingRemark: data.data.openingRemark,
            attendance:
              data.data.meetingAttendances !== null
                ? data.data.meetingAttendances.map((data1) => {
                    let row = [];
                    index = index + 1;
                    let bData = {
                      index: index,
                      id: data1.id,
                      memberId: data1.committeeMember.id,
                      memberName: data1.committeeMember.memberName,
                      status: data1.status === 1 ? true : false,
                      remark: data1.remark,
                      attendance: false,
                      answer: "",
                      tableId: "",
                    };
                    row.push(bData);
                    return bData;
                  })
                : [],
          };
          dispatch(deemedMeetingByIdSuccess({ bData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const resetValues = () => async (dispatch) => {
  try {
    dispatch(resetSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
