import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { financialMasterJson } from "../../../../DynamicFormsJson/FinancialYear";
import { FinancialYearColumns } from "../../../../tableColumns/table-columns";
import { getFinancial } from "./financialYear.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";

class FinancialYear extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    // this.setState={
    //   fd:fromDate      
    // }
  }

  componentDidMount() {
    const { getFinancial } = this.props;
    getFinancial().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  render() {
    const { financialList } = this.props;

    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={financialMasterJson.screenTitle}
            fieldMeta={financialMasterJson.fieldMeta}
            showPdfDownload={financialMasterJson.showPdfDownload}
            showExcelDownload={financialMasterJson.showExcelDownload}
            pdfFileName={financialMasterJson.pdfFileName}
            excelFileName={financialMasterJson.excelFileName}
            showAddButton={financialMasterJson.showAddButton}
            tableColumnsToFilter={financialMasterJson.tableColumnsToFilter}
            //dynamicMasterData={dynamicMasterData}
            tableColumns={FinancialYearColumns}
            getTableData={this.props.getFinancial}
            tableData={financialList?.financial}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={financialList.isFinancialFetch}
            apiBaseURL={financialMasterJson.apiBaseURL}
            baseIdColumn={financialMasterJson.baseIdColumn}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  financialList: state.financial,
});
const mapDispatchToProps = {
  getFinancial, getAlert
};
export default connect(mapStateToProps, mapDispatchToProps)(FinancialYear);
