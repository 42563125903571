import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { InternshipListReportJson } from "../../DynamicFormsJson/InternshipListReport";
import { getQualification } from "../QualificationMaster/qualification.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getYearDetails,
  getDistinctDivision,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { getAlert } from "../../CommonActions/alert.slice";
class InternshipListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        studentStatus: [
          {
            id: "1",
            name: "Selected Student List",
            isActive: "Active",
          },
          {
            id: "2",
            name: "Completed Student List",
            isActive: "Active",
          },
        ],

        internType: [
          {
            id: "0",
            name: "On Campus",
            isActive: "Active",
          },
          {
            id: "1",
            name: "Off Campus",
            isActive: "Active",
          },
        ],
        academicYear: this.props.academicYearList?.academicYear,
        yearDetails: this.props.yearDetailList?.yearDetails,
        division: this.props.yearDetailList?.distinctDivision,
        internshipType: this.props.internshipTypeList?.internshipType,
        qualification: this.props.qualificationList?.qualification,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getInternshipType().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getQualification().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.yearDetails !==
        nextProps.yearDetailList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.yearDetailList.yearDetails,
          },
        });
      }
    }
    if (this.props.internshipTypeList !== nextProps.internshipTypeList) {
      if (
        this.props.internshipTypeList.internshipType !==
        nextProps.internshipTypeList.internshipType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            internshipType: nextProps.internshipTypeList.internshipType,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.distinctDivision !==
        nextProps.yearDetailList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            division: nextProps.yearDetailList.distinctDivision,
          },
        });
      }
    }
    if (this.props.qualificationList !== nextProps.qualificationList) {
      if (
        this.props.qualificationList.qualification !==
        nextProps.qualificationList.qualification
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            qualification: nextProps.qualificationList.qualification,
          },
        });
      }
    }
  }

  getListById = (data) => {
    if (data.yearDetailId) {
      this.props
        .getDistinctDivision({
          yearDetail: data.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  rowData = [
    {
      index: 1,
      reportName: "Internship List by company (D0, D1, D6)",
      filter: "academicYearId,yearDetailId,studentStatus",
      url: "/api/report/v1/internship-list-with-company-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&status={studentStatus}",
    },
    {
      index: 2,
      reportName: "Internship Company Details",
      filter: "",
      url: "/api/report/v1/company-details-report?type=1",
    },
    {
      index: 3,
      reportName: "Internship External Student Details (D0, D1, D2 ,D3)",
      filter: "academicYearId,yearDetailId,division,internshipType",
      url: "/api/report/v1/student-external/internship-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionId={division}&internshipType={internshipType}",
    },
    {
      index: 4,
      reportName: "Eligible Student List (D1, D2, D4, D5)",
      filter: "qualificationId,yearDetailId,division,cutoff",
      excelUrl:
        "/api/exportexcel/v1/eligible-student-report?qualificationIds={qualificationId}&yearDetailId={yearDetailId}&divisionId={division}&cutoff={cutoff}",
    },
    {
      index: 5,
      reportName: "Internship student list status-wise (D0, D1, D6)",
      filter: "academicYearId,yearDetailId,studentStatus",
      url: "/api/report/v1/internship-list-with-company-report?academicYearId={academicYearId}&yearDetailId={yearDetailId}&status={studentStatus}",
    },

    {
      index: 6,
      reportName:
        "Internship Letter (D7, D8, D9, D10, D11, D12, D13, D14, D15)",
      filter:
        "to,date,studentName,subject,duration,placementIncharge,placementOfficer,yearDetailName,rejoiningDate",
      url: "/api/report/v1/internship-letter?to={to}&date={date}&studentName={studentName}&duration={duration}&placementIncharge={placementIncharge}&placementOfficer={placementOfficer}&yearDetailName={yearDetailName}&rejoiningDate={rejoiningDate}&subject={subject}",
    },
    {
      index: 7,
      reportName:
        "Report to display details of Students who are ON CAMPUS/OFF CAMPUS Internship Class-Wise  (D1, D2, D16)",
      filter: "yearDetailId,internType,division",
      url: "/api/report/v1/placement-report/get-internship-placed-student-class-wise?yearDetailId={yearDetailId}&divisionId={division}&placementType={internType}&selectedStatus=1",
    },
    {
      index: 8,
      reportName:
        "Report to display details of Students who are ON CAMPUS/OFF CAMPUS Internship Year-Wise (D0, D16)",
      filter: "academicYearId,internType",
      url: "/api/report/v1/placement-report/get-internship-placed-student-year-wise?acadamicYearId={academicYearId}&placementType={internType}&selectedStatus=1",
    },
  ];
  condition = [
    {
      title: "index",
      value: 4,
      iconHide: "showHeadPdf",
    },
    {
      title: "index",
      value: 7,
      iconHide: "showHeadExcel",
    },
    {
      title: "index",
      value: 8,
      iconHide: "showHeadExcel",
    },
    {
      title: "index",
      value: 6,
      iconHide: "showHeadExcel",
    },
  ];
  //http://localhost:8088/api/report/v1/internship-list-with-company-report?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=83bd6686-cd24-4559-80ac-8029a337b9a3&documentType=1
  //localhost:8088/api/report/v1/internship-intrested-not-intrested-report?internshipDeclareId=00407253-2660-4545-9f43-e2f5d81f89cf&intrestStatus=0&documentType=1
  //api/report/v1/student-external/internship-report?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c&divisionId=d910e283-b645-426e-865e-040440651afc&internshipType=221c6765-fb39-4eab-97fa-ed8797375fb0&documentType=1
  //http://localhost:8090/v1/eligible-student?qualificationIds=&yearDetailId=&divisionId=&cutoff=60
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={InternshipListReportJson.screenTitle}
          fieldMeta={InternshipListReportJson.fieldMeta}
          buttonCenter={InternshipListReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={InternshipListReportJson.searchButton}
          PDFButton={InternshipListReportJson.PDFButton}
          EXCELButton={InternshipListReportJson.EXCELButton}
          baseIdColumn={InternshipListReportJson.baseIdColumn}
          apiBaseURL={InternshipListReportJson.apiBaseURL}
          marginTop={InternshipListReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
          condition={this.condition}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  yearDetailList: state.internshipDeclaration,
  internshipTypeList: state.internshipType,
  qualificationList: state.qualification,
});
const mapDispatchToProps = {
  getAlert,
  getAcademicYear,
  getYearDetails,
  getInternshipType,
  getDistinctDivision,
  getQualification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternshipListReport);
