export const InstituteReportJson = {
    apiBaseURL:
        "/api/report/v1/application-fees-collection-summary-report/{status}/get-application-fees-collection-summary",
    screenTitle: "Placement List Report",
    showAddButton: false,
    showPdfDownload: false,
    pdfFileName: "",
    showExcelDownload: false,
    excelFileName: "",
    tableColumnsToFilter: [],
    buttonCenter: false,
    searchButton: false,
    PDFButton: true,
    EXCELButton: true,
    marginTop: 3,
    fieldMeta: [
        {
            label: "Program Type",
            controlType: "autocomplete",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "programType",
            dataKey: "programType",
            getListId: "programType",
            isMandatory: true,
        },
        {
            label: "Program Title",
            controlType: "multiSelect",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "programTitle",
            dataKey: "programTitle",
            isMandatory: true,
        },
      
    ],
};
