import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { ReceiptTypeMasterJson } from '../../../../DynamicFormsJson/ReceiptType.js';
import { ReceiptTypeMasterColumns } from "../../../../tableColumns/table-columns";
import { getReceiptType } from "./receiptType.slice"
import { getAlert } from "../../../../CommonActions/alert.slice";
class ReceiptTypeMaster extends React.Component {

    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: { programMaster: this.props.programMaster?.program, instituteList: this.props.instituteList?.institute }
        }
    }

    componentDidMount() {
        this.props.getReceiptType().then((response) => {
            if (!response) {
                this.props.getAlert({ message: "Something went wrong" });
            }
        });
    }

    render() {
        const { receiptTypeList } = this.props
        return (
            <>
                <div>
                    <DynamicMainScreen
                        screenTitle={ReceiptTypeMasterJson.screenTitle}
                        fieldMeta={ReceiptTypeMasterJson.fieldMeta}
                        showPdfDownload={ReceiptTypeMasterJson.showPdfDownload}
                        showExcelDownload={ReceiptTypeMasterJson.showExcelDownload}
                        pdfFileName={ReceiptTypeMasterJson.pdfFileName}
                        excelFileName={ReceiptTypeMasterJson.excelFileName}
                        showAddButton={ReceiptTypeMasterJson.showAddButton}
                        tableColumnsToFilter={ReceiptTypeMasterJson.tableColumnsToFilter}
                        //dynamicMasterData={dynamicMasterData}
                        tableColumns={ReceiptTypeMasterColumns}
                        tableData={receiptTypeList.receiptType}
                        getTableData={this.props.getReceiptType}
                        onDelete={this.onDelete}
                        onSave={this.onSave}
                        showLoader={receiptTypeList.isFetch}
                        baseIdColumn={ReceiptTypeMasterJson.baseIdColumn}
                        apiBaseURL={ReceiptTypeMasterJson.apiBaseURL}
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    receiptTypeList: state.receiptType
});
const mapDispatchToProps = {
    getReceiptType, getAlert
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptTypeMaster);