import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { StudentFeesCollectionReportJson } from "../../DynamicFormsJson/StudentFeesCollectionReport";
import { getQualification } from "../QualificationMaster/qualification.slice";
import { getAcademicYear } from "../Masters/OnlineModule/AcademicYear/academicYear.slice";
import {
  getYearDetails,
  getDistinctDivision,
} from "../InternshipDeclaration/InternshipDeclataration.slice";
import { getInternshipType } from "../Masters/Internship/InternshipType/IntershipType.slice";
import { getAlert } from "../../CommonActions/alert.slice";
class StudentFeesCollectionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        academicYear: this.props.academicYearList?.academicYear,
        yearDetails: this.props.yearDetailList?.yearDetails,
        division: this.props.yearDetailList?.distinctDivision,
      },
    };
  }

  componentDidMount() {
    this.props.getAcademicYear().then((response) => {
      if (!response) {
        // this.props.getAlert({ message: "Something went wrong" });
      }
    });
    this.props.getYearDetails().then((response) => {
      if (!response) {
        // this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.academicYearList !== nextProps.academicYearList) {
      if (
        this.props.academicYearList.academicYear !==
        nextProps.academicYearList.academicYear
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            academicYear: nextProps.academicYearList.academicYear,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.yearDetails !==
        nextProps.yearDetailList.yearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetails: nextProps.yearDetailList.yearDetails,
          },
        });
      }
    }
    if (this.props.yearDetailList !== nextProps.yearDetailList) {
      if (
        this.props.yearDetailList.distinctDivision !==
        nextProps.yearDetailList.distinctDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            division: nextProps.yearDetailList.distinctDivision,
          },
        });
      }
    }
  }

  getListById = (data) => {
    if (data.yearDetailId) {
      this.props
        .getDistinctDivision({
          yearDetail: data.yearDetailId,
        })
        .then((response) => {
          if (!response) {
            this.props.getAlert({ message: "Something went wrong" });
          }
        });
    }
  };
  rowData = [
    {
      // v1/student-fees-detail?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c&divisionIds=d910e283-b645-426e-865e-040440651afc,c38d9cd6-3ade-4e34-8d4d-6deab307332f,bfcd24ff-e29e-4f3b-9010-03f5c1105e18&type=1
      index: 1,
      reportName:
        "Fees collectiontion Report - Division Wise (D0, D1, D2, D3) ",
      filter: "academicYearId,yearDetailId,division,feesType",
      excelUrl:
        "/api/exportexcel/v1/student-fees-detail?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionIds={division}&type={feesType}",
      url: "/api/report/v1/student-fees-detail-report-by-divisions?academicYearId={academicYearId}&yearDetailId={yearDetailId}&divisionIds={division}&type={feesType}",
    },
  ];

  //http://localhost:8088/api/report/v1/internship-list-with-company-report?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=83bd6686-cd24-4559-80ac-8029a337b9a3&documentType=1
  //localhost:8088/api/report/v1/internship-intrested-not-intrested-report?internshipDeclareId=00407253-2660-4545-9f43-e2f5d81f89cf&intrestStatus=0&documentType=1
  //api/report/v1/student-external/internship-report?academicYearId=1b61d1ee-1ba5-418a-b727-b62706f94dd0&yearDetailId=71993caf-e0a6-4edc-8f53-4251ee2b6e7c&divisionId=d910e283-b645-426e-865e-040440651afc&internshipType=221c6765-fb39-4eab-97fa-ed8797375fb0&documentType=1
  //http://localhost:8090/v1/eligible-student?qualificationIds=&yearDetailId=&divisionId=&cutoff=60
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={StudentFeesCollectionReportJson.screenTitle}
          fieldMeta={StudentFeesCollectionReportJson.fieldMeta}
          buttonCenter={StudentFeesCollectionReportJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={StudentFeesCollectionReportJson.searchButton}
          PDFButton={StudentFeesCollectionReportJson.PDFButton}
          EXCELButton={StudentFeesCollectionReportJson.EXCELButton}
          baseIdColumn={StudentFeesCollectionReportJson.baseIdColumn}
          apiBaseURL={StudentFeesCollectionReportJson.apiBaseURL}
          marginTop={StudentFeesCollectionReportJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  academicYearList: state.academicYear,
  yearDetailList: state.internshipDeclaration,
  internshipTypeList: state.internshipType,
  qualificationList: state.qualification,
});
const mapDispatchToProps = {
  getAlert,
  getAcademicYear,
  getYearDetails,
  getInternshipType,
  getDistinctDivision,
  getQualification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentFeesCollectionReport);
