import React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicTable";
import {
  getDistinctDivision,
  getYearDetails,
} from "../../InternshipDeclaration/InternshipDeclataration.slice";

import { resetReducList } from "../../../CommonActions/User.action";
import { classCoOrdinatorJson } from "../../../DynamicFormsJson/ClassCoOrdinatorJson";
import { getAcademicYear } from "../../Masters/OnlineModule/AcademicYear/academicYear.slice";
import { downloadF } from "../../PDF/PDFDownload";
import { getSemesterDetailsByYearAndAcademic } from "../../RollNoList/ViewOfRollList/rollno.slice";
import {
  getBatchClassCoordinatorView,
  getClassCoordinatorYearDetails,
  getDivisionForClasscoordinator,
  getSemesterByYearDetailClassCoordinator,
  getSubjectClassCoordinator,
  getTimeTable,
  getTimeTableClassCoordinatorView,
  getTimeTableDetailByHeaderId,
} from "../../TimeTable/GenerateTimeTable/generateTimeTable.slice";
import { getRecordsByFilters, resetValues } from "../teachingPlan.slice";
import ClassCoordinatorActionView from "./ClassCoordinatorActionView";
class ClassCoOrdinatorView extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
      canSearch: true,
    },
    {
      name: "firstName",
      // name: "name",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
      canSearch: true,
      keyName:"firstName,lastName,middleName",
      isFormat:true,
      type:1
    },
    {
      name: "date",
      title: "Date",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "20%",
      canSearch: true,
    },
    {
      name: "topicTaught",
      title: "Topic Taught",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
      canSearch: true,
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "10%",
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        yearMaster: this.props.timeTableList?.classCoordinatorYearDetails,
        semisterMaster: this.props.timeTableList?.classCoordinatorSemester,
        divisionMaster: this.props.timeTableList?.classCoordinatorDivision,
        batchMaster: this.props.timeTableList?.batcList,
        subjectMaster: this.props.timeTableList?.subjectList,
      },
      showLoader: false,
      open: false,
      openView: false,
      timeTableHeaderData: {},
      tableData: {},
      showTableView: true,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList.classCoordinatorYearDetails !==
        nextProps.timeTableList.classCoordinatorYearDetails
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearMaster: nextProps.timeTableList.classCoordinatorYearDetails,
          },
        });
      }
      if (
        this.props.timeTableList.classCoordinatorDivision !==
        nextProps.timeTableList.classCoordinatorDivision
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            divisionMaster: nextProps.timeTableList.classCoordinatorDivision,
          },
        });
      }
    }

    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList.classCoordinatorSemester !==
        nextProps.timeTableList.classCoordinatorSemester
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            semisterMaster: nextProps.timeTableList.classCoordinatorSemester,
          },
        });
      }
    }

    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList.batcList !== nextProps.timeTableList.batcList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchMaster: nextProps.timeTableList.batcList,
          },
        });
      }
    }

    if (this.props.timeTableList !== nextProps.timeTableList) {
      if (
        this.props.timeTableList.subjectList !==
        nextProps.timeTableList.subjectList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subjectMaster: nextProps.timeTableList.subjectList,
          },
        });
      }
    }
  }

  getFilteredTableData = () => {
    const { searchValue } = this.state;
    return this.props.teachingPlan?.teacherRecords.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["year"] &&
          currentRow["year"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["division"] &&
          currentRow["division"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["remark"] &&
          currentRow["remark"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (currentRow["status"] &&
          currentRow["status"]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  componentDidMount() {
    const { resetReducList, getClassCoordinatorYearDetails } = this.props;
    resetReducList();
    const semisterDetailId = localStorage.getItem("semisterDetailId");
    const yearDetailId = localStorage.getItem("yearDetailId");
    const ay = localStorage.getItem("acadamicYearId");
    const divisionId = localStorage.getItem("divisionId");

    this.setState({
      showLoader: true,
    });
    if (divisionId && semisterDetailId) {
      this.props
        .getBatchClassCoordinatorView({
          semisterDetailId: semisterDetailId,
          divisionId: divisionId,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (yearDetailId) {
      this.props
        .getSemesterByYearDetailClassCoordinator({ yearDetailId: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });

      this.props
        .getDivisionForClasscoordinator({ yearDetailId: yearDetailId })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    getClassCoordinatorYearDetails().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    });
  }
  getListById = (data) => {
    this.setState({
      showLoader: true,
    });
    if (data.yearDetail) {
      this.props
        .getSemesterByYearDetailClassCoordinator({
          yearDetailId: data.yearDetail,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });

      this.props
        .getDivisionForClasscoordinator({ yearDetailId: data.yearDetail })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.semister && data.division) {
      this.props
        .getBatchClassCoordinatorView({
          semisterDetailId: data.semister,
          divisionId: data.division,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }

    if (data.semister && data.division && data.batch) {
      this.props
        .getSubjectClassCoordinator({
          semisterDetailId: data.semister,
          divisionId: data.division,
          batchId: data.batch,
        })
        .then((response) => {
          if (!response) {
            this.setState({
              showLoader: false,
            });
            this.props.getAlert({ message: "Something went wrong" });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        });
    }
  };

  onSearchData = (dataToSearch) => {
    localStorage.setItem("semisterDetailId", dataToSearch.semister);
    localStorage.setItem("yearDetailId", dataToSearch.yearDetail);
    localStorage.setItem("divisionId", dataToSearch.division);
    localStorage.setItem("divisionIds", "");
    this.setState({
      yearDetail: dataToSearch.yearDetail,
      semister: dataToSearch.semister,
      division: dataToSearch.division,
      batch: dataToSearch.batch,
      subject: dataToSearch.subject,
      showLoader: true,
    });
    const { getAlert } = this.props;
    this.props
      .getRecordsByFilters({
        semesterDetailId: dataToSearch.semister,
        subjectDetailId: dataToSearch.subject,
        batchId: dataToSearch.batch,
        divisionId: dataToSearch.division,
        fromDate: "",
        toDate: "",
      })
      .then((response) => {
        if (!response) {
          this.setState({
            showLoader: false,
          });
          getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
  };

  onClickView = (rowData) => {
    console.log(rowData);
    this.setState({
      openView: true,
      tableData: rowData,
      showTableView: false,
    });
  };

  onCancelView = () => {
    // this.props.resetValues();
    this.setState({
      showTableView: true,
      openView: false,
    });
  };

  render() {
    const { dynamicMasterData, showLoader, open, openView, showTableView } =
      this.state;
    return (
      <>
        {showTableView && (
          <DynamicMainScreen
            onAddButtonClick={this.onAddButtonClick}
            screenTitle={classCoOrdinatorJson.screenTitle}
            searchAlign={classCoOrdinatorJson.searchAlign}
            fieldMeta={classCoOrdinatorJson.fieldMeta}
            buttonCenter={classCoOrdinatorJson.buttonCenter}
            showPdfDownload={classCoOrdinatorJson.showPdfDownload}
            showExcelDownload={classCoOrdinatorJson.showExcelDownload}
            pdfFileName={classCoOrdinatorJson.pdfFileName}
            excelFileName={classCoOrdinatorJson.excelFileName}
            tableColumnsToFilter={classCoOrdinatorJson.tableColumnsToFilter}
            showAddButton={classCoOrdinatorJson.showAddButton}
            dynamicMasterData={dynamicMasterData}
            tableColumns={this.columns}
            tableData={this.props.teachingPlan?.teacherRecords}
            getListById={this.getListById}
            showPegination={false}
            showView={true}
            showLoader={showLoader}
            showHeadEdit={false}
            showHeadDelete={false}
            showHeadPdf={false}
            pdfDetailsId={this.pdfDetails}
            rowViewData={this.onClickView}
            rowDelete={this.rowDelete}
            rowEdit={this.onClickEdit}
            rowStatus={this.changeStatus}
            onDelete={this.onDelete}
            onSearchData={this.onSearchData}
            baseIdColumn={classCoOrdinatorJson.baseIdColumn}
            apiBaseURL={classCoOrdinatorJson.apiBaseURL}
          />
        )}
        {openView && (
          <ClassCoordinatorActionView
            rows={
              Object.keys(this.props.teachingPlan?.syllabus).length !== 0
                ? this.props.teachingPlan?.syllabus.details
                : []
            }
            tableData={this.state.tableData}
            onCancel={this.onCancelView}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  internshipDeclarationList: state.internshipDeclaration,
  academicYearList: state.academicYear,
  rollNoData: state.rollNo,
  timeTableList: state.timeTable,
  teachingPlan: state.teachingPlan,
});
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getAcademicYear,
  getSemesterDetailsByYearAndAcademic,
  getTimeTable,
  getTimeTableDetailByHeaderId,
  resetReducList,
  getClassCoordinatorYearDetails,
  downloadF,
  getDivisionForClasscoordinator,
  getSubjectClassCoordinator,
  getSemesterByYearDetailClassCoordinator,
  getTimeTableClassCoordinatorView,
  getBatchClassCoordinatorView,
  getRecordsByFilters,
  resetValues,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassCoOrdinatorView);
