import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAlert } from "../../CommonActions/alert.slice";
import AttachFile from "../../components/Comman/AttachFile";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { apiDelete, apiPost } from "../../utils/api_service";
import { Loading1 } from "../../components/Loading1";
import { getStudentList } from "../InternshipDeclaration/InternshipDeclataration.slice";
import {
  deleteFile,
  deleteFileData,
  fileMulitipleSave,
  openFileInNewTab,
} from "../UploadFile/file.slice";

class UpdateInfo extends React.Component {
  columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "name",
      title: "Document Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "60%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "20%",
    },
  ];

  state = {
    id: 0,
    formErrors: [],
    interestedStudent: false,
    accountFees: false,
    listOfComp: true,
    fromDate: "",
    toDate: "",
    studentName: "",
    studUserRegistrationId: "",
    companyName: "",
    designation: "",
    stipend: "",
    remark: "",
    fileName: "",
    documentName: "",
    label: "",
    fileNameData: [],
    upload: false,
    uploadError: false,
    showLoader: false,
    selectedStatus: "",
    docNameDD: "",
    documentListLocal: [
      { id: "Offer Letter", name: "Offer Letter" },
      {
        id: "Appointment Letter",
        name: "Appointment Letter",
      },
      {
        id: "Experience Letter",
        name: "Experience Letter",
      },
      {
        id: "1",
        name: "Other",
      },
    ],
    intrestedStatus: "",
  };

  onClickDelete = (params) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete the file?",
      icon: "warning",
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        if (params.id !== undefined) {
          apiDelete({
            url: endpoints.internshipIntrestedDoc + "/" + params.id,
          }).then(({ data, success }) => {
            if (success) {
              this.props.deleteFile({ file: params.fileName }).then(() => {});
              const docList = this.props.fileData.fileData.filter(
                (row) => row.fileName !== params.fileName
              );
              const rowData = docList.map((docData, index) => {
                return { ...docData, index: index + 1 };
              });
              this.props.fileMulitipleSave({ fileData: rowData });
            }
          });
        } else {
          this.props.deleteFile({ file: params.fileName }).then(() => {});
          const docList = this.props.fileData.fileData.filter(
            (row) => row.fileName !== params.fileName
          );
          const rowData = docList.map((docData, index) => {
            return { ...docData, index: index + 1 };
          });
          this.props.fileMulitipleSave({ fileData: rowData });
        }
      }
    });
  };

  details = (params) => {
    this.props.openFileInNewTab({ file: params.fileName }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  };
  componentWillReceiveProps(nextProps) {
    if (
      this.props.studentData?.getInterestedStudByIdStatus !==
        nextProps.studentData?.getInterestedStudByIdStatus &&
      this.props.fileData.fileData.length === 0 &&
      Object.keys(nextProps.studentData?.getInterestedStudByIdStatus).length !=
        0
    ) {
      this.setState({
        id: nextProps.studentData?.getInterestedStudByIdStatus.id,
        companyName:
          nextProps.studentData?.getInterestedStudByIdStatus.companyName,
        stipend: nextProps.studentData?.getInterestedStudByIdStatus.stipend,
        remark: nextProps.studentData?.getInterestedStudByIdStatus.remark,
        designation:
          nextProps.studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          nextProps.studentData?.getInterestedStudByIdStatus.getUserRegistration
            .lastName,
        studUserRegistrationId:
          nextProps.studentData?.getInterestedStudByIdStatus.userRegistrationId,
        durationFromDate:
          nextProps.studentData?.getInterestedStudByIdStatus.durationFromDate,
        durationToDate:
          nextProps.studentData?.getInterestedStudByIdStatus.durationToDate,
        selectedStatus:
          nextProps.studentData?.getInterestedStudByIdStatus.selectedStatus,
        intrestedStatus:
          nextProps.studentData?.getInterestedStudByIdStatus.intrestStatus,
      });
      const docList =
        nextProps.studentData?.getInterestedStudByIdStatus.internshipIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              id: fileData.id,
              index: index + 1,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }
  }
  columnExtensions = [
    { columnName: "index", width: 100 },
    { columnName: "action", width: 130 },
  ];
  componentDidMount() {
    const { getStudentList, data, studentData } = this.props;

    const changeDateArr =
      data.durationFromDate === null ? "" : data.durationFromDate.split("-");
    const durFrom =
      changeDateArr === ""
        ? ""
        : new Date(changeDateArr[2], changeDateArr[1] - 1, changeDateArr[0]);

    const changeDateArrNew =
      data.durationToDate === null ? "" : data.durationToDate.split("-");
    const durTo =
      changeDateArrNew === ""
        ? ""
        : new Date(
            changeDateArrNew[2],
            changeDateArrNew[1] - 1,
            changeDateArrNew[0]
          );

    this.setState({
      companyName: data.companyName,
      stipend: data.stipend,
      remark: data.remark,
      designation: data.designation,
      // studentName: data.studentName,
      fromDate: durFrom,
      toDate: durTo,
    });
    if (Object.keys(studentData?.getInterestedStudByIdStatus).length != 0) {
      const changeDateArr =
        studentData?.getInterestedStudByIdStatus.durationFromDate === null
          ? ""
          : studentData?.getInterestedStudByIdStatus.durationFromDate.split(
              "-"
            );
      const durFrom =
        changeDateArr === ""
          ? ""
          : new Date(changeDateArr[2], changeDateArr[1] - 1, changeDateArr[0]);

      const changeDateArrNew =
        studentData?.getInterestedStudByIdStatus.durationToDate === null
          ? ""
          : studentData?.getInterestedStudByIdStatus.durationToDate.split("-");
      const durTo =
        changeDateArrNew === ""
          ? ""
          : new Date(
              changeDateArrNew[2],
              changeDateArrNew[1] - 1,
              changeDateArrNew[0]
            );

      this.setState({
        id: studentData?.getInterestedStudByIdStatus.id,
        companyName: studentData?.getInterestedStudByIdStatus.companyName,
        stipend: studentData?.getInterestedStudByIdStatus.stipend,
        remark: studentData?.getInterestedStudByIdStatus.remark,
        designation: studentData?.getInterestedStudByIdStatus.designation,
        studentName:
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .firstName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration
            .middleName +
          " " +
          studentData?.getInterestedStudByIdStatus.getUserRegistration.lastName,
        studUserRegistrationId:
          studentData?.getInterestedStudByIdStatus.userRegistrationId,
        fromDate: durFrom ? durFrom : "",
        toDate: durTo ? durTo : "",
        selectedStatus: studentData?.getInterestedStudByIdStatus.selectedStatus,
        intrestedStatus: studentData?.getInterestedStudByIdStatus.intrestStatus,
      });
      const docList =
        studentData?.getInterestedStudByIdStatus.internshipIntrestedStudentDocument.map(
          (fileData, index) => {
            let document = {
              index: index + 1,
              id: fileData.id,
              name: fileData.docName,
              fileName: fileData.fileName,
            };
            return document;
          }
        );
      this.props.fileMulitipleSave({ fileData: docList });
    }

    getStudentList({
      yearDetails: data.yearDetailId,
      qualification: [],
      divisionIds: data.divisionId,
    }).then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  changeFromDate = (date) => {
    if (date !== null) {
      this.setState({
        fromDate: date,
      });
    }
  };

  changeToDate = (date) => {
    if (date !== null) {
      this.setState({
        toDate: date,
      });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  handleFormValidation(name) {
    const { companyName, studentName, designation, fromDate, toDate } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    if (studentName.toString().trim() === "") {
      formIsValid = false;
      formErrors["studentNameError"] = myConstClass.studentNameMsg;
    }

    if (companyName === "") {
      formIsValid = false;
      formErrors["companyNameError"] = myConstClass.companyNameMsg;
    }

    if (designation.toString().trim() === "") {
      formIsValid = false;
      formErrors["designationError"] = myConstClass.designationMsg;
    }

    if (fromDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["fromDateError"] = myConstClass.fromDateMsg;
    }

    if (toDate.toString().trim() === "") {
      formIsValid = false;
      formErrors["toDateError"] = myConstClass.toDateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  changeH = (fileName, name, index) => {
    this.setState({
      ...this.state,
      fileName: fileName,
    });
  };

  uploadImage = () => {
    const { fileName, documentName, docNameDD } = this.state;
    if (fileName !== "") {
      this.setState({
        upload: true,
        showLoader: true,
        uploadError: false,
      });

      const formData = new FormData();
      formData.append("file", this.state.fileName);
      apiPost({
        url: endpoints.fileUpload,
        postBody: formData,
      }).then(({ data, success }) => {
        if (success) {
          let docList = [
            ...this.props.fileData.fileData,
            {
              index: this.props.fileData.fileData.length + 1,
              name: docNameDD === "1" ? documentName : docNameDD,
              fileName: data,
            },
          ];
          this.setState({
            documentName: "",
            docNameDD: "",
            fileName: "",
            upload: false,
            uploadError: false,
            showLoader: false,
          });
          this.props.fileMulitipleSave({ fileData: docList });
        } else {
          this.setState({
            showLoader: false,
          });
        }
      });
    } else {
      this.setState({
        upload: false,
        uploadError: true,
      });
    }
  };
  closeStudentFeesDetails = () => {
    this.setState({ interestedStudent: false, listOfComp: true });
  };
  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  onSave = () => {
    const {
      id,
      studentName,
      companyName,
      designation,
      stipend,
      remark,
      fromDate,
      toDate,
      fileName,
      documentName,
      studUserRegistrationId,
      selectedStatus,
      intrestedStatus,
    } = this.state;
    const { data, studentData, intrestedStudList } = this.props;

    let fromDt = fromDate !== "" ? moment(fromDate).format("DD-MM-YYYY") : "";
    let toDt = toDate !== "" ? moment(toDate).format("DD-MM-YYYY") : "";

    const documentList = this.props.fileData.fileData.map((docData) => {
      let docs = {
        docName: docData.name,
        fileName: docData.fileName,
      };
      if (docData.id) {
        docs = {
          ...docs,
          id: docData.id,
        };
      }
      return docs;
    });
    let studentInfo = {
      internshipDeclare: { id: data.internshipDeclareId },
      companyName: data.companyName,
      companyAdd: data.companyAddress,
      rollNo: "",
      userRegistrationId: studUserRegistrationId,
      selectedStatus: selectedStatus,
      designation: designation,
      stipend: stipend,
      durationFromDate: fromDt,
      durationToDate: toDt,
      internshipType: {
        id: data.internshipType,
      },
      vivaStatus: 0,
      approvedStatus: 0,
      intrestStatus: intrestedStatus,
      remark: remark,
      internshipIntrestedStudentDocument: documentList,
    };
    if (id !== 0) {
      let student = studentData?.getInterestedStudByIdStatus;
      student = {
        ...student,
        designation: designation,
        stipend: stipend,
        durationFromDate: fromDt,
        durationToDate: toDt,
        remark: remark,
        internshipIntrestedStudentDocument: documentList,
        intrestStatus: intrestedStatus,
        selectedStatus: selectedStatus,
      };

      apiPost({
        url: endpoints.internshipIntrest + "/edit",
        postBody: student,
      }).then(({ data, success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.setState({
            selectedStatus: "",
            intrestedStatus: "",
          });
          this.props.goBack();
          this.props.deleteFileData();
        }
      });
    } else {
      apiPost({
        url: endpoints.internshipIntrest,
        postBody: studentInfo,
      }).then(({ data, success }) => {
        if (!success) {
          this.props.getAlert({ message: "Something went wrong" });
        } else {
          this.props.goBack();
          this.props.deleteFileData();
        }
      });
    }
  };

  render() {
    const {
      listOfComp,
      fromDate,
      toDate,
      studentName,
      studUserRegistrationId,
      companyName,
      remark,
      designation,
      stipend,
      documentName,
      fileName,
      upload,
      uploadError,
      selectedStatus,
      intrestedStatus,
      showLoader,
      docNameDD,
      documentListLocal,
    } = this.state;
    const {
      fromDateError,
      toDateError,
      studentNameError,
      designationError,
      companyNameError,
      docName,
    } = this.state.formErrors;
    const { goBack, intrestedStudList, studentData, flag, fileData } =
      this.props;

    return (
      <>
        <div>
          <Paper sx={{ p: 2, borderRadius: 2 }} elevation={0}>
            {showLoader && <Loading1 />}
            {listOfComp && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={1}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 22 }}
                      label="Student Information "
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    container
                    justifyContent="right"
                  >
                    <ButtonCompo
                      size="medium"
                      type="button"
                      variant="outlined"
                      name="Back To List"
                      onClick={goBack}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={1}
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Student Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      {Object.keys(studentData?.getInterestedStudByIdStatus)
                        .length === 0 && (
                        <AutoComplete
                          keyColName={"id"}
                          value={studUserRegistrationId}
                          name={"studUserRegistrationId"}
                          options={intrestedStudList?.studentList}
                          onChange={this.ChangeHandlerSearch}
                          disabled={false}
                        />
                      )}
                      {Object.keys(studentData?.getInterestedStudByIdStatus)
                        .length !== 0 && (
                        <TextField
                          size="small"
                          color="primary"
                          name="studentName"
                          id="studentName"
                          value={studentName.toUpperCase()}
                          onChange={this.changeHandler}
                          fullWidth
                          disabled
                        ></TextField>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={1}
                    xs={12}
                    sm={12}
                    sx={{ marginTop: 2 }}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Company Name"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="companyName"
                        id="companyName"
                        value={companyName}
                        onChange={this.changeHandler}
                        disabled
                        fullWidth
                        {...(companyNameError
                          ? { error: true, helperText: companyNameError }
                          : "")}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={1}
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Designation"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="designation"
                        id="designation"
                        value={designation}
                        onChange={this.changeHandler}
                        fullWidth
                        {...(designationError
                          ? { error: true, helperText: designationError }
                          : "")}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={1}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Internship Duration From Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="fromDate"
                        size="small"
                        value={fromDate}
                        fullWidth
                        onChange={this.changeFromDate}
                        {...(fromDateError
                          ? { error: true, helperText: fromDateError }
                          : "")}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={1}
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ marginTop: 2 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Internship Duration To Date"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <BasicDatePicker
                        color="primary"
                        name="toDate"
                        size="small"
                        value={toDate}
                        fullWidth
                        onChange={this.changeToDate}
                        {...(toDateError
                          ? { error: true, helperText: toDateError }
                          : "")}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={1}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Stipend( ₹ Per month )"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="stipend"
                        id="stipend"
                        value={stipend}
                        onChange={this.changeHandler}
                        fullWidth
                        //   {...(codeErr ? { error: true, helperText: codeErr }:"")}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{ marginTop: 2 }}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Selected status"
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={12} md={12}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="selectedStatus"
                            id="selectedStatus"
                            name="selectedStatus"
                            value={selectedStatus}
                            onChange={this.changeHandler}
                            size="small"
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid> */}

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl color={"primary"} fullWidth={true}>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            className="text-black"
                            name="selectedStatus"
                            id="selectedStatus"
                            value={selectedStatus}
                            onChange={this.changedHandler}
                            size="small"
                            fullWidth={true}
                          >
                            <MenuItem value={0}>Not Selected</MenuItem>
                            <MenuItem value={1}>Selected</MenuItem>
                            <MenuItem value={2}>Completed</MenuItem>
                          </Select>
                        </FormControl>
                        <FormHelperText> </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{ marginTop: 2 }}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="Applied status"
                        />
                      </Grid>

                      <Grid sx={{ marginTop: -1 }} item xs={12} sm={12} md={12}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="intrestedStatus"
                            id="intrestedStatus"
                            name="intrestedStatus"
                            value={intrestedStatus}
                            onChange={this.changeHandler}
                            size="small"
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Yes"
                              // onClick={handleClickOpen}
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    columnSpacing={1}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Remark"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        size="small"
                        color="primary"
                        name="remark"
                        id="remark"
                        value={remark}
                        onChange={this.changeHandler}
                        fullWidth
                        //   {...(codeErr ? { error: true, helperText: codeErr }:"")}
                      ></TextField>
                    </Grid>
                  </Grid>

                  {flag == 1 && (
                    <>
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        columnSpacing={1}
                        sx={{ marginTop: 2 }}
                        xs={12}
                        sm={4}
                        md={4}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label="*Document Upload"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <AutoComplete
                            keyColName={"id"}
                            value={docNameDD}
                            name={"docNameDD"}
                            options={documentListLocal}
                            onChange={this.ChangeHandlerSearch}
                          />
                        </Grid>
                      </Grid>
                      {docNameDD === "1" ? (
                        <>
                          <Grid
                            container
                            justifyContent="space-evenly"
                            direction="row"
                            columnSpacing={1}
                            sx={{ marginTop: 2 }}
                            xs={12}
                            sm={4}
                            md={4}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16 }}
                                label="Enter Document"
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                size="small"
                                color="primary"
                                name="documentName"
                                id="documentName"
                                value={documentName}
                                onChange={this.changeHandler}
                                fullWidth
                                helperText=" "
                              ></TextField>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            justifyContent="space-evenly"
                            direction="row"
                            columnSpacing={1}
                            rowSpacing={2}
                            xs={12}
                            sm={1}
                            md={1}
                            sx={{ marginTop: 3 }}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <AttachFile name="" fileName={this.changeH} />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justifyContent="space-evenly"
                            direction="row"
                            columnSpacing={1}
                            rowSpacing={2}
                            xs={12}
                            sm={2}
                            md={2}
                            sx={{ marginTop: 3 }}
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              &nbsp; {fileName !== "" ? fileName.name : ""}
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          container
                          justifyContent="space-evenly"
                          direction="row"
                          columnSpacing={1}
                          rowSpacing={2}
                          xs={12}
                          sm={7}
                          md={7}
                          sx={{ marginTop: 3 }}
                        >
                          <Grid item xs={12} sm={12} md={12}>
                            <AttachFile name="" fileName={this.changeH} />
                            &nbsp; {fileName !== "" ? fileName.name : ""}
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        justifyContent="space-evenly"
                        direction="row"
                        columnSpacing={1}
                        rowSpacing={2}
                        xs={12}
                        sm={1}
                        md={1}
                        sx={{ marginTop: 3 }}
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <ButtonCompo
                            size="medium"
                            type="button"
                            variant="contained"
                            fullWidth={true}
                            {...(((docNameDD == "1" && documentName !== "") ||
                              docNameDD !== "") &&
                              upload && { disable: true })}
                            name="Upload"
                            onClick={this.uploadImage}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        {uploadError && (
                          <FormHelperText error>
                            {"please select file and Document Name"}
                          </FormHelperText>
                        )}
                      </Grid>

                      <Grid
                        sx={{ mt: 2, mr: 0.7 }}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <DynamicTable
                          data={fileData.fileData}
                          tableHead={this.columns}
                          showHeadDelete={true}
                          rowDelete={this.onClickDelete}
                          showPegination={false}
                          rowViewData={this.details}
                          showView={true}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
            <br />
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                item
                xs={12}
                sm={9}
                md={9}
                lg={9}
                container
                justifyContent="right"
              />
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                container
                justifyContent="right"
                m={1}
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="outlined"
                  name="Cancel"
                  fullWidth={true}
                  onClick={goBack}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                md={1}
                lg={1}
                rowSpacing={2}
                container
                justifyContent="right"
                m={1}
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Save"
                  fullWidth={true}
                  onClick={this.onSave}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  classList: state.class,
  studentData: state.companyList,
  feesCollectionList: state.feesCollection,
  internshipTypeList: state.internshipType,
  intrestedStudList: state.internshipDeclaration,
  fileData: state.file,
});
const mapDispatchToProps = {
  fileMulitipleSave,
  getStudentList,
  getAlert,
  deleteFile,
  openFileInNewTab,
  deleteFileData,
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateInfo);
