import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
const initialState = {
  allocationList: [],
  isFetch: false,
  allocationListById: [],
  isFetchById: false,
  intrestedStudentId: [],
  isFetchIntrestedStudentId: false,
  StudentVivaMarks: {},
  isFetchStudentVivaMarks: false,
  StudentVivaMarksById: {},
  isFetchStudentVivaMarksById: false,
};

let URL = endpoints.vivaDeclare;
let URL1 = endpoints.internshipIntrest;
let URL2 = endpoints.internshipStudentMarks;

const allocationListSlice = createSlice({
  name: "allocationList",
  initialState,
  reducers: {
    allocationListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        allocationList: row,
        isFetch: true,
      };
    },
    allocationListByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        allocationListById: row,
        isFetchById: true,
      };
    },
    intrestedStudentIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        intrestedStudentId: row,
        isFetchIntrestedStudentId: true,
      };
    },
    studentVivaMarksSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        StudentVivaMarks: row,
        isFetchStudentVivaMarks: true,
      };
    },
    studentVivaMarksByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { companyData } = action.payload;
      return {
        ...state,
        StudentVivaMarksById: companyData,
        isFetchStudentVivaMarksById: true,
      };
    },
  },
});

export const {
  allocationListSuccess,
  allocationListByIdSuccess,
  intrestedStudentIdSuccess,
  studentVivaMarksByIdSuccess,
} = allocationListSlice.actions;

// Studentdata.getDivisions.map((division) => {
//   return division.name;
// })

export default allocationListSlice.reducer;
export const getAllocationList =
  ({ academicId, internType }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-internship-viva-declare-and-faculty-by-academic-year-and-intern-type?academicYearId=" +
          academicId +
          "&internType=" +
          internType,
      }).then(({ data, success }) => {
        if (success) {
          const companyList = data.data;
          // let index = 0;
          let row = [];
          let index = 0;

          row = companyList.map((data) => {
            let divisionId =
              data.getDivisions === null
                ? ""
                : data.getDivisions.map((division) => {
                    return division.name;
                  });

            index = index + 1;
            let companyData = {
              index: index,
              id: data.id,
              academicYear:
                data.academicYearId === null ? "" : data.academicYearId,
              yearDetails:
                data.getYearDetail === null
                  ? ""
                  : data.getYearDetail.programTitle.programType.name +
                    " - " +
                    data.getYearDetail.programTitle.brName +
                    " - " +
                    data.getYearDetail.year.className,
              division: divisionId === "" ? "" : divisionId.join(),
              remark:
                data.remark === null || data.remark === "" ? "-" : data.remark,
              status:
                data.facultyVivaStatus === null
                  ? ""
                  : data.facultyVivaStatus == 1
                  ? "Complete"
                  : "Pending",
            };
            return companyData;
          });
          dispatch(allocationListSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAllotedStudentListByid =
  ({ academicId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-internship-viva-declare-faculty-by-user-id-and-viva-declare-id?internshipVivaDeclareId=" +
          academicId,
      }).then(({ data, success }) => {
        if (success) {
          const companyList = data.data.internshipVivaDeclareStudent;
          // let index = 0;
          let row = [];
          let index = 0;
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              id: data.id,
              lastName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.lastName.toUpperCase(),
              firstName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.firstName.toUpperCase(),
              middleName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.middleName.toUpperCase(),
              fatherName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.fatherName.toUpperCase(),
              motherName:
                data.getUserRegistration !== null &&
                data.getUserRegistration.motherName.toUpperCase(),
              name:
                data.getUserRegistration !== null
                  ? data.getUserRegistration.lastName.toUpperCase() +
                    " " +
                    data.getUserRegistration.firstName.toUpperCase() +
                    " " +
                    (data.getUserRegistration.fatherName == null ||
                    data.getUserRegistration.fatherName == ""
                      ? " - "
                      : data.getUserRegistration.fatherName.toUpperCase()) +
                    " " +
                    (data.getUserRegistration.motherName == null ||
                    data.getUserRegistration.motherName == ""
                      ? " - "
                      : data.getUserRegistration.motherName.toUpperCase())
                  : " ",
              division:
                data.getReportingDetail === null ||
                data.getReportingDetail === ""
                  ? "-"
                  : data.getReportingDetail.division.name,
              rollNo:
                data.getReportingDetail === null ||
                data.getReportingDetail === ""
                  ? "-"
                  : data.getReportingDetail.rollNo,
              status: data.status === "1" ? "Complete" : "Pending",
              internshipIntrestedStudentIds: data.internshipIntrestedStudentIds,
            };
            return companyData;
          });
          dispatch(allocationListByIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getInterestedStudentByid =
  ({ intrestedStudentIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-intrested-student-by-intrested-student-ids?intrestedStudentIds=" +
          intrestedStudentIds,
      }).then(({ data, success }) => {
        if (success) {
          const companyList = data.data;
          // let index = 0;
          let row = [];
          let index = 0;
          row = companyList.map((data) => {
            index = index + 1;
            let companyData = {
              index: index,
              id: data.id,
              internship: data.companyName,
              designation: data.designation,
              fromDate: data.durationFromDate,
              toDate: data.durationToDate,
              stipend: data.stipend,
            };
            return companyData;
          });
          dispatch(intrestedStudentIdSuccess({ row }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const updateStudentVivaMarks =
  ({ studentVivaData }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL2 +
          "/set-internship-viva-declare-student-marks?internshipVivaDeclareId=" +
          studentVivaData.headerId +
          "&internshipVivaDeclareStudentId=" +
          studentVivaData.studentId +
          "&internalMarks=" +
          studentVivaData.internalMarks +
          "&outOfInternal=" +
          studentVivaData.outOfInternal +
          "&externalMarks=" +
          studentVivaData.externalMarks +
          "&outOfExternal=" +
          studentVivaData.outOfExternal,
      }).then(({ data, success }) => {
        if (success) {
          const companyList = data.data;
          let row = [];
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStudentVivaMarksById =
  ({ studentId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL2 + "/" + studentId,
      }).then(({ data, success }) => {
        if (success) {
          const companyList = data.data;
          let row = [];
          let index = 0;

          let companyData = {
            externalMarks: companyList.externalMarks,
            internalMarks: companyList.internalMarks,
            outOfExternal: companyList.outOfExternal,
            outOfInternal: companyList.outOfInternal,
          };
          dispatch(studentVivaMarksByIdSuccess({ companyData }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
