import { Grid, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { getAlert } from "../../CommonActions/alert.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import InternshipDetailPopUp from "./InternshipDetailPopUp";
import InternshipStudentMarkPopUp from "./InternshipStudentMarkPopUp";
import MarkAllocationPopUp from "./MarkAllocationPopUp";
import {
  getInterestedStudentByid,
  getStudentVivaMarksById,
} from "./TeacherViva.slice";

function MarkSubMission({
  cancelMarkSubmission,
  getStudentVivaMarksById,
  dataToSave,
  allocationList,
  getInterestedStudentByid,
  getAlert,
  headerId,
}) {
  const [box, setBox] = React.useState(false);
  const [marks, setMarks] = React.useState(false);
  const [marksContain, setMarksContain] = React.useState({});
  const [markPopUp, setMarkPopUp] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  console.log(headerId);
  const handleClickOpen = (params) => {
    getInterestedStudentByid({
      intrestedStudentIds: params.internshipIntrestedStudentIds,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        setOpen(true);
        setBox(true);
      }
    });
  };

  const pdfDetailsId = (id) => {
    console.log(id);
  };

  const handleClickOpenMarks = (params) => {
    getStudentVivaMarksById({ studentId: params.id }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      } else {
        setMarks(true);
        setMarkPopUp(false);
        setOpen(true);
      }
    });
  };
  const handleClosesMarks = () => {
    setMarks(false);
    setMarkPopUp(false);
    setOpen(false);
  };

  const handleClickOpen1 = (params) => {
    setMarksContain(params);
    setMarkPopUp(true);
    setOpen(false);
  };

  const handleCloses = () => {
    setBox(false);
    setMarkPopUp(false);
  };

  const onCancel = () => {
    cancelMarkSubmission();
  };
  const OnSave = (data) => {
    let mdata = {
      externalMarks: data.externalMarks,
      internalMarks: data.internalMarks,
      outOfExternal: data.outOfExternal,
      outOfInternal: data.outOfInternal,
      studentId: marksContain.id,
    };

    dataToSave(mdata);
    setMarkPopUp(false);

    // setOpen(true);
    // setBox(true);
  };

  const columns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "10%",
    },
    {
      name: "firstName",
      title: "Student Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "30%",
      keyName: "firstName,lastName,fatherName,motherName,middleName",
      isFormat: true,
      type: 0,

    },
    {
      name: "division",
      title: "Division",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "20%",
    },
    {
      name: "rollNo",
      title: "Roll No.",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      width: "10%",
    },
    {
      name: "status",
      title: "Status",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
      width: "15%",
    },
    {
      name: "action",
      title: "Action",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "right",
      width: "20%",
    },
  ];
  return (
    <>
      <Paper sx={{ p: 4, borderRadius: 2 }} elevation={0}>
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Student List"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} container justifyContent="flex-end">
            <ButtonCompo
              size="medium"
              type="submit"
              variant="outlined"
              name="Back"
              onClick={onCancel}
            />
          </Grid>
        </Grid>
        <br />
        <Grid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          rowSpacing={2}
          columnSpacing={1}
          container
          justifyContent="space-evenly"
        >
          <Grid item xs={12} sm={6} md={6}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={"Year Details : " + headerId.yearDetails}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={"Division : " + headerId.division}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 16 }}
              label={"Remark : " + headerId.remark}
            />
          </Grid>
        </Grid>
        <br />

        <Grid item xs={12} sm={12} md={12}>
          <DynamicTable
            data={allocationList?.allocationListById}
            tableHead={columns}
            showView={true}
            rowViewData={handleClickOpenMarks}
            showAppliedList={true}
            rowAppliedList={handleClickOpen}
            showSchoolIcon={true}
            rowSchoolDetails={handleClickOpen1}
            showPegination={false}
          />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            m={2}
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={1}
              lg={1}
              container
              justifyContent="space-evenly"
            ></Grid>
          </Grid>
        </Grid>

        {box && (
          <InternshipDetailPopUp open={open} handleCloses={handleCloses} />
        )}

        {marks && (
          <InternshipStudentMarkPopUp
            open={open}
            handleCloses={handleClosesMarks}
          />
        )}

        {markPopUp && (
          <MarkAllocationPopUp
            open={markPopUp}
            handleCloses={handleCloses}
            onSave={OnSave}
          />
        )}
      </Paper>
    </>
  );
}
const mapStateToProps = (state) => ({
  allocationList: state.allocationList,
});
const mapDispatchToProps = {
  getInterestedStudentByid,
  getAlert,
  getStudentVivaMarksById,
};
export default connect(mapStateToProps, mapDispatchToProps)(MarkSubMission);
