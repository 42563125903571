import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../CommonActions/alert.slice";
import { getTimeSlotByProgramType } from "../../Masters/TimeTable/Time-Slot/timeSlot.slice";
import MatrixView from "./MatrixView";
import {
  getStudentTimeTable,
  getStudentYearDetail,
} from "../GenerateTimeTable/generateTimeTable.slice";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { LabelCompo } from "../../../components/Comman/Label";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
};

class FeeStructureHeadIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      open: false,
      year: "",
      timeTableDetailForStudentData: {},
    };
  }
  componentDidMount() {
    const { getStudentTimeTable, getAlert, getStudentYearDetail } = this.props;
    const parentChildId = localStorage.getItem("parentChildId");
    getStudentTimeTable({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
    getStudentYearDetail({
      userRegistrationId: parentChildId === null ? "" : parentChildId,
    }).then((response) => {
      if (!response) {
        getAlert({ message: "Something went wrong" });
      }
    });
  }
  saveHandler = () => {
    this.setState({
      open: true,
    });
  };
  cancleHandler = () => {
    this.setState({
      open: false,
    });
  };
  submitHandler = (data) => {
    this.setState({
      open: false,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      const filterList =
        this.props.timeTableList.timeTableDetailForStudent.filter(
          (row) => row.id === newValue
        );
      if (filterList.length !== 0 && filterList[0].programType) {
        this.props
          .getTimeSlotByProgramType({
            programTypeId: filterList[0].programType,
          })
          .then((response) => {
            if (!response) {
              getAlert({ message: "Something went wrong" });
            }
          });
      }
      this.setState({
        [name]: newValue,
        timeTableDetailForStudentData:
          filterList.length !== 0 ? filterList[0] : {},
      });
    } else {
      this.setState({ [name]: "" });
    }
  };
  render() {
    const { isLoading, timeTableDetailForStudentData, year } = this.state;
    return (
      <>
        <Paper sx={{ p: 2, pt: 2, borderRadius: 2 }} elevation={0}>
          <Grid item xs={12} sm={12} md={12} rowSpacing={2}>
            <LabelCompo
              className="text-black"
              style={{ fontSize: 22 }}
              label="Time Table"
            />
          </Grid>

          <Grid
            container
            justifyContent="space-evenly"
            direction="row"
            xs={12}
            sm={12}
            md={12}
          >
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              columnSpacing={3}
              sx={{ marginTop: 2 }}
              xs={12}
              sm={6}
              md={6}
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label="*Year Details"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  value={year}
                  name={"year"}
                  options={this.props.timeTableList?.studentYearDetail}
                  onChange={this.ChangeHandlerSearch}
                  // isError={semisterErr ? true : false}
                  // errorText={semisterErr ? semisterErr : " "}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              columnSpacing={3}
              xs={12}
              sm={6}
              md={6}
            />
          </Grid>

          {Object.keys(timeTableDetailForStudentData).length !== 0 && (
            <>
              <Grid
                container
                justifyContent="space-evenly"
                direction="row"
                xs={12}
                sm={12}
                md={12}
              >
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  columnSpacing={3}
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                >
                  <Grid item xs={12} sm={3} md={2.3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="Division : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={9} md={9.7}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        Object.keys(timeTableDetailForStudentData).length !==
                          0 && timeTableDetailForStudentData.divisionName
                          ? timeTableDetailForStudentData.divisionName
                          : "-"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-evenly"
                  direction="row"
                  xs={12}
                  sm={6}
                  md={6}
                  columnSpacing={3}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                >
                  <Grid item xs={12} sm={9} md={1.8}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label="year : "
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={10.2}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        Object.keys(timeTableDetailForStudentData).length !==
                          0 &&
                        timeTableDetailForStudentData.yearDetailName +
                          " ( " +
                          timeTableDetailForStudentData.semisterDetailName +
                          " )"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {this.props.timeSlotList.isFetch && (
                <MatrixView
                  dayList={[
                    { id: "0", name: "Monday" },
                    { id: "1", name: "Tuesday" },
                    { id: "2", name: "Wednesday" },
                    { id: "3", name: "Thursday" },
                    { id: "4", name: "Friday" },
                    { id: "5", name: "Saturday" },
                    { id: "6", name: "Sunday" },
                  ]}
                  timeSlotList={this.props.timeSlotList?.timeSlot}
                  onCancel={this.cancleHandler}
                  timeTableDetails={
                    Object.keys(timeTableDetailForStudentData).length !== 0
                      ? timeTableDetailForStudentData.timeTableDetails
                      : []
                  }
                />
              )}
            </>
          )}
        </Paper>
        {/* {isLoading && <Loading1 />}
        {!feeStructuredList.isFeesStructureHeaderFetch && <Loading1 />} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  timeSlotList: state.timeSlot,
  timeTableList: state.timeTable,
});
const mapDispatchToProps = {
  getTimeSlotByProgramType,
  getStudentTimeTable,
  getAlert,
  getStudentYearDetail,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeeStructureHeadIndex);
