import
{
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { LabelCompo } from "../../components/Comman/Label";
import * as myConstClass from "../../config/messageconstant";
// import { getRollNoList } from "./GenerateRollNo.slice";
import PrnNumberTable from "../Masters/Faculty/AcademicDetails/PrnNumber/PrnNumberTable";
import { getSemisterInfo } from "../Masters/Admission/Semister Info/semisterInfo.slice";
import
{
  getYearDetails,
  getDistinctDivision,
} from "../InternshipDeclaration/InternshipDeclataration.slice"; // import AccountFees from "../AccountFees/index";
import { getAlert } from "../../CommonActions/alert.slice";
import GenrateRollNoTable from "../RollNoList/GenrateRollNo/GenrateRollNoTable";
import StudentSelectionTable from "./StudentSelectionTable"
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { getSemesterDetailsByYearAndAcademic } from "../RollNoList/ViewOfRollList/rollno.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { getStudentForSelection } from "./SubjectSelection.slice"
import SelectSubjectIndex from "./SelectSubjectIndex";

const rows = [
  {
    index: 1,
    id: 1,
    studentName: "TCS",
    prnNo: "NskRd",
    rollNo: "1",
    reportingDate: "",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 2,
    id: 2,
    studentName: "Wipro",
    prnNo: "",
    rollNo: "2",
    reportingDate: "12-06-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
  {
    index: 3,
    id: 3,
    studentName: "ATS",
    prnNo: "123",
    rollNo: "3",
    reportingDate: "1-01-2022",
    rollNoValid: 0,
    reportingDateValid: 0,
  },
];
class StudentSelectionForm extends React.Component
{
  state = {
    id: 0,
    programTitle: "",
    semisterDetail: "",
    yearDetail: "",
    sortOrder: "",
    divisionId: "",
    formErrors: [],
    selection: [],
    studentData: [],
    formErrors: [],
    showForm1: true,
    showForm2: false,
    hideTable: false,
  };


  handleFormValidation1()
  {
    const { selection } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( selection.length === 0 )
    {
      formIsValid = false;
      formErrors[ "emptyListError" ] = "*Please select Atleast One Student";
    }
    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }


  goToForm = () =>
  {
    if ( this.handleFormValidation && this.handleFormValidation1() )
    {
      const { selection } = this.state;
      const { student } = this.props;
      let row = [];
      let docs = selection.map( ( rowData ) =>
      {
        const rowList = student.studentList.filter(
          ( row ) => row.id === rowData
        );
        console.log( rowList )
        if ( rowList.length !== 0 )
        {
          let rowData = {
            userRegistration: {
              id: rowList[ 0 ].id,
            },
            reportingDetail: {
              id: rowList[ 0 ].reportingDetail,
            },
            admissionRegistrationDetailId:
              rowList[ 0 ].admissionRegistrationDetailId,
            semisterDetail: {
              id: this.state.semisterDetail,
            },
          };
          row.push( rowList[ 0 ] );
          return rowData;
        }
      } );
      this.setState( {
        studentData: row
      } )
      console.log( row )
      console.log( selection )
      this.setState( {
        showForm2: true,
        showForm1: false,
      } );
    }
  };

  backToForm = () =>
  {
    console.log( "in Form" );
    this.setState( {
      showForm2: false,
      showForm1: true,
    } );
  };

  componentDidMount()
  {
    const { getYearDetails, getAlert, getSemisterInfo } = this.props;
    getYearDetails().then( ( response ) =>
    {
      if ( !response )
      {
        getAlert( { message: "Something went wrong" } );
      }
    } );
    getSemisterInfo().then( ( response ) =>
    {
      if ( !response )
      {
        getAlert( { message: "Something went wrong" } );
      }
    } );
  }

  onDateChange = ( dataKey ) => ( date ) =>
  {
    this.setState( {
      [ dataKey ]: date,
    } );
  };

  changeHandler = ( event ) =>
  {
    const { name, value } = event.target;
    this.setState( {
      ...this.state,
      [ name ]: value,
    } );
  };

  ChangeHandlerSearch = ( name, newValue ) =>
  {
    const { getDistinctDivision, getSemesterDetailsByYearAndAcademic } =
      this.props;
    this.setState( {
      hideTable: false
    } )
    if ( newValue !== null )
    {
      this.setState( { [ name ]: newValue } );

      if ( name == "yearDetail" )
      {
        this.setState( { semisterDetail: "" } );

        getDistinctDivision( { yearDetail: newValue } ).then( ( response ) =>
        {
          if ( !response )
          {
            this.props.getAlert( { message: "Something went wrong" } );
          }
        } );
        getSemesterDetailsByYearAndAcademic( {
          yearId: newValue,
          academicId: "",
        } ).then( ( response ) =>
        {
          if ( !response )
          {
            this.props.getAlert( { message: "Something went wrong" } );
          }
        } );
      }
    } else
    {
      this.setState( { [ name ]: "" } );
    }
  };

  handleFormValidation()
  {
    const { programTitle, semisterDetail, yearDetail, sortOrder, divisionId } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    if ( semisterDetail.toString().trim() === "" || semisterDetail === null )
    {
      formIsValid = false;
      formErrors[ "semisterDetailError" ] = myConstClass.semisterDetailMsg;
    }

    if ( divisionId.toString().trim() === "" || divisionId == null )
    {
      formIsValid = false;
      formErrors[ "divisionError" ] = myConstClass.divEmptyMsg;
    }

    if ( yearDetail.toString().trim() === "" || yearDetail === null )
    {
      formIsValid = false;
      formErrors[ "yearDetailError" ] = myConstClass.yearDetailMsg;
    }

    this.setState( { formErrors: formErrors } );
    return formIsValid;
  }

  searchData = () =>
  {
    const { fromDate, toDate, internType } = this.state;
    const { getCompanyList } = this.props;
    let fromDt = moment( fromDate ).format( "DD-MM-YYYY" );
    let toDt = moment( toDate ).format( "DD-MM-YYYY" );
    if ( this.handleFormValidation() )
    {
      // getCompanyList({ fromDate: fromDate, toDate: toDate, internType: internType });
    }
  };

  // rowDetails = (data) => {
  //   this.setState({
  //     accountFees: false,
  //     interestedStudent: true,
  //     listOfComp: false,
  //     paymentDetailsData: data,
  //   });
  // };

  getRow = ( e ) =>
  {
    let selectedList = [];
    this.setState( {
      selection: e,
    } );
    console.log( e )
    e.forEach( ( data ) =>
    {
      const selRow = this.props.student?.studentList.filter( ( row, index ) =>
      {
        return row.id === data;
      } );
      selectedList = [ ...selectedList, selRow[ 0 ] ];
    } );
  };

  rowAppliedList = ( data ) =>
  {
    const { comanyData } = this.state;
    this.setState( {
      interestedStudent: true,
      listOfComp: false,
      companyData: data,
    } );
  };

  saveHandler = ( RollNoList ) =>
  {
    const { saveRollNoList } = this.props;
    const { semisterDetail } = this.state;
    const saveToData = RollNoList.map( ( data ) =>
    {
      let saveData = {
        ...data,
        semisterDetail: {
          id: semisterDetail,
        },
        dateOfReporting: moment( data.dateOfReporting ).format( "DD-MM-YYYY" ),
      };
      return saveData;
    } );
    apiPost( {
      url: endpoint.studentRollList,
      postBody: saveToData,
    } ).then( ( { success } ) =>
    {
      if ( success )
      {
        window.location.replace( "/roll-list" );
      } else
      {
        this.props.getAlert( {
          message: "Failed to save",
        } );
      }
    } );
    // saveRollNoList({rollNoList:saveToData})
  };

  closeStudentFeesDetails = () =>
  {
    this.setState( { interestedStudent: false, listOfComp: true } );
  };

  submitHandler = () =>
  {
    const { getAlert, getStudentForSelection } = this.props;
    const { semisterDetail, divisionId, yearDetail } = this.state;
    console.log( semisterDetail );
    console.log( yearDetail );
    console.log( divisionId );
    if ( this.handleFormValidation() )
    {
      getStudentForSelection( {
        semisterDetailId: semisterDetail,
        divisionId: divisionId,
      } ).then( ( response ) =>
      {
        if ( !response )
        {
          getAlert( { message: "Something went wrong" } );
        }
        else
        {
          this.setState( {
            hideTable: true
          } )
        }
      } );
    }
  };
  render()
  {
    const {
      programTitle,
      semisterDetail,
      yearDetail,
      sortOrder,
      divisionId,
      selection,
    } = this.state;
    const {
      semisterDetailError,
      emptyListError,
      divisionError,
      yearDetailError,
    } = this.state.formErrors;
    const {
      internshipDeclarationList,
      rollNoData,
      studentRollNoList,
      onClickBack,
    } = this.props;
    console.log( this.props.student?.studentList );
    return (
      <>
        <div>
          <Paper sx={{ p: 3, borderRadius: 2 }} elevation={0}>
            <>
              {this.state.showForm1 && (
                <>
                  <Grid container justifyContent="space-evenly" direction="row">
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      columnSpacing={1}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid sx={{ mb: 3 }} item xs={12} sm={10.3} md={10.3}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 22 }}
                          label="Student Selection"
                        />
                      </Grid>
                      <Grid sx={{ mb: 2, mt: -1 }} item xs={12} sm={1.7} md={1.7}>
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="outlined"
                          name="Back to List"
                          onClick={this.props.onCancel}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="space-evenly"
                    direction="row"
                    spacing={2}
                    sx={{ pl: 1 }}
                  >
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ marginTop: 2 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Year details"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={yearDetail}
                          name={"yearDetail"}
                          options={internshipDeclarationList?.yearDetails}
                          onChange={this.ChangeHandlerSearch}
                          isError={yearDetailError ? true : false}
                          errorText={yearDetailError ? yearDetailError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ marginTop: 2 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Semester Details"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={semisterDetail}
                          name={"semisterDetail"}
                          options={rollNoData?.semisterDetailsList}
                          onChange={this.ChangeHandlerSearch}
                          isError={semisterDetailError ? true : false}
                          errorText={
                            semisterDetailError ? semisterDetailError : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      columnSpacing={3}
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16 }}
                          label="*Division"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <AutoComplete
                          keyColName={"id"}
                          value={divisionId}
                          name={"divisionId"}
                          options={internshipDeclarationList?.distinctDivision}
                          onChange={this.ChangeHandlerSearch}
                          isError={divisionError ? true : false}
                          errorText={divisionError ? divisionError : " "}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-evenly"
                      direction="row"
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ marginTop: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <ButtonCompo
                          size="medium"
                          type="button"
                          variant="contained"
                          name="Search"
                          onClick={this.submitHandler}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.hideTable &&
                    <>
                      <Grid item>
                        <FormHelperText error>
                          {emptyListError ? emptyListError : " "}
                        </FormHelperText>
                        <StudentSelectionTable
                          rows={this.props.student?.studentList}
                          onSelectionChange={this.getRow}
                          selection={this.state.selection}
                          isActionColActive={false}
                          generatePrn={this.generatePrn}
                          onCancel={this.props.onCancel}
                          goToSubSelection={this.goToForm}
                        />
                      </Grid>
                    </>
                  }

                </>
              )}
              {this.state.showForm2 && (
                <SelectSubjectIndex
                  studentData={this.state.studentData}
                  semisterDetailId={this.state.semisterDetail}
                  onCancel={this.backToForm}
                  onCloseMat={this.props.onCancel}

                />
              )}
            </>
          </Paper>
        </div>
      </>
    );
  }
}
const mapStateToProps = ( state ) => ( {
  internshipDeclarationList: state.internshipDeclaration,
  studentRollNoList: state.rollNoList,
  semisterList: state.semister,
  rollNoData: state.rollNo,
  student: state.subjectSelection,
} );
const mapDispatchToProps = {
  getAlert,
  getYearDetails,
  getDistinctDivision,
  getStudentForSelection,
  getSemisterInfo,
  getSemesterDetailsByYearAndAcademic,
};
export default connect( mapStateToProps, mapDispatchToProps )( StudentSelectionForm );
