import { Grid, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import swal from 'sweetalert';
import { ButtonCompo } from '../../../components/Comman/Button';
import EventCommitteeForm from "../../Event/Event Management/EventCommitteeForm"
import { showNotification } from "../../../Pages/Landing/Landing.slice";

const EventCommitteeMainForm = ( { onCancel, committeeData } ) =>
{
    const [ quaRow, setQuaRow ] = React.useState( [] );
    const [ editRowData, setEditRowData ] = React.useState( [] );

    const setQuaData = ( data ) =>
    {
        if ( data.index === 0 || data.index === undefined )
        {
            data = {
                ...data,
                index: quaRow.length + 1,
            };
            setQuaRow( ( prevData ) =>
            {
                return [ ...prevData, data ];
            } );
        } else
        {
            let viewRow = quaRow.filter( ( row ) => row.index !== data.index );
            viewRow = [ ...viewRow, data ];
            setQuaRow( viewRow );
        }
        setEditRowData( [] );
    };


    useEffect( () =>
    {
    }, [] )


    const onDataSave = () =>
    {
        this.OnSave( quaRow )
    };


    const rowDeleteCommittee = ( id ) =>
    {
        swal( {
            title: "Are you sure?",
            text: "Do you want to delete the file?",
            icon: "warning",
            buttons: [ "Cancel", true ],
            dangerMode: true,
        } ).then( ( willDelete ) =>
        {
            if ( willDelete )
            {
                let eventCommitteeList = quaRow.filter( ( row ) => row.id !== id );
                setQuaRow( eventCommitteeList );
            }
        } );
    };

    return (
        <>
            <EventCommitteeForm
                row={quaRow}
                rowData={setQuaData}
                editRows={editRowData}
                rowDelete={rowDeleteCommittee}
                committeeData={committeeData}
                onCancel={onCancel}
            />
        </>
    )
}

export default EventCommitteeMainForm