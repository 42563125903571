import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../../components/dynamicscreens/DynamicMainScreen";
import { AdmissionStatusMasterJson } from "../../../../DynamicFormsJson/AdmissionStatus.js";
import { AdmissionStatusMasterColumns } from "../../../../tableColumns/table-columns";
import { getAdmissionStatus } from "./admissionStatus.slice";
import { getAlert } from "../../../../CommonActions/alert.slice";
class AdmissionStatusMaster extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        programMaster: this.props.programMaster?.program,
        instituteList: this.props.instituteList?.institute,
      },
    };
  }

  componentDidMount() {
    this.props.getAdmissionStatus().then((response) => {
      if (!response) {
        this.props.getAlert({ message: "Something went wrong" });
      }
    });
  }

  render() {
    const { admissionStatusList } = this.props;
    // const { dynamicMasterData } = this.state;
    return (
      <>
        <div>
          <DynamicMainScreen
            screenTitle={AdmissionStatusMasterJson.screenTitle}
            fieldMeta={AdmissionStatusMasterJson.fieldMeta}
            showPdfDownload={AdmissionStatusMasterJson.showPdfDownload}
            showExcelDownload={AdmissionStatusMasterJson.showExcelDownload}
            pdfFileName={AdmissionStatusMasterJson.pdfFileName}
            excelFileName={AdmissionStatusMasterJson.excelFileName}
            tableColumnsToFilter={
              AdmissionStatusMasterJson.tableColumnsToFilter
            }
            showAddButton={AdmissionStatusMasterJson.showAddButton}
            //dynamicMasterData={dynamicMasterData}
            tableColumns={AdmissionStatusMasterColumns}
            tableData={admissionStatusList.admissionStatus}
            getTableData={this.props.getAdmissionStatus}
            onDelete={this.onDelete}
            onSave={this.onSave}
            showLoader={admissionStatusList.isFetch}
            baseIdColumn={AdmissionStatusMasterJson.baseIdColumn}
            apiBaseURL={AdmissionStatusMasterJson.apiBaseURL}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  admissionStatusList: state.admissionStatus,
});
const mapDispatchToProps = {
  getAdmissionStatus, getAlert
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdmissionStatusMaster);
